import {
  ActionButton,
  ContextualMenu,
  FontWeights,
  getTheme,
  IconButton,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Modal,
  Separator,
  Stack,
  Toggle,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../../../theme';
import React, { useEffect, useState } from 'react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { useTranslation } from 'react-i18next';
// import TaxonomyField from '../../../certification/taxonomy/taxonomy';
import { formatDateTimeToISOString } from '../../../../shared/util';
import { Field, Form } from 'react-final-form';
import { DropDownAdaptater } from '../../../../shared/fluentFinalForm';
import PeoplePicker from '../../../../shared/fluentFinalForm/PeoplePicker';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import { AbsenceModalDTO, LabourPersonDTO } from '../../../../types/humanResource';
import DatePickerRequiredAdapter from '../../../../shared/fluentFinalForm/DatePickerRequiredAdapter';

const theme = getTheme();

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
  divMarginTop: { marginTop: '20px' },
  divMarginBottom: { marginBottom: '10px' },
});

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '24px 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '40vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const stackStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    flexBasis: '50%',
    height: 50,
    padding: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 0,
};

const stackItemStyles: IStackItemStyles = {
  root: {
    padding: 0,
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const AbsenceModal = (props: {
  isOpen: boolean;
  isEdit: boolean;
  openAbsenceModal: () => void;
  title: string;
  labourHistorySelectionDetails: AbsenceModalDTO[];
  onChangeStartTime?: (index: number, value: any) => void;
  onChangeEndTime?: (index: number, value: any) => void;
  createAbsence: (absence: any) => any;
  labourPerson: LabourPersonDTO;
  leaveTypes: any[];
}) => {
  const { t } = useTranslation();
  const [isDraggable] = useBoolean(false);
  const titleId = useId('title');
  const [absence, setAbesence] = useState<AbsenceModalDTO>({
    id: '',
    person: '',
    leaveType: [],
    allDay: false,
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
  });

  const getInitialFormValues = (formData: any) => {
    if (formData && formData?.id) {
      return {
        id: formData.id,
        person: formData.person,
        leaveType: formData.leaveType,
        allDay: formData?.allDay,
        startDate: formData.startDate ? new Date(formData.startDate) : '',
        endDate: formData.endDate ? new Date(formData.endDate) : '',
        startTime: formData.startTime,
        endTime: formData.endTime,
        personName: formData?.personName,
      };
    }
    if (props.labourPerson && props.labourPerson?.key) {
      return {
        ...formData,
        person: props.labourPerson?.key,
        personName: props.labourPerson?.text,

      };
    }
    return formData;
  };

  const onSubmit = async (values: any) => {
    props.createAbsence(values);
    props.openAbsenceModal();
  };

  useEffect(() => {
    setAbesence(props.labourHistorySelectionDetails[0]);
  }, [props.labourHistorySelectionDetails]);

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          props.openAbsenceModal();
        }}
        isBlocking={true}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>
            {props.isEdit
              ? t('edit') + ' ' + props.title
              : t('add') + ' ' + props.title}
          </span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              props.openAbsenceModal();
              setAbesence({
                id: '',
                person: '',
                leaveType: [],
                allDay: false,
                startDate: '',
                endDate: '',
                startTime: '',
                endTime: '',
              });
            }}
          />
        </div>

        <div className={contentStyles.body}>
          <div className="proj-detail-block">
            <Form
              onSubmit={onSubmit}
              initialValues={getInitialFormValues(absence)}
              mutators={{
                setEndDate: (args, state, utils) => {
                  const field = state.fields['endDate'];
                  field.change(args[0]);
                },
              }}
              validate={values => {
                const errors: any = {};
                if (!values?.leaveType) {
                  errors.leaveType = t('selectLeaveType');
                }
                if (!values?.startDate) {
                  errors.startDate = t('startDateRequired');
                }
                if (!values?.endDate) {
                  errors.endDate = t('endDateRequired');
                }
                if (!values?.startTime && !values?.allDay) {
                  errors.startTime = t('invalidTime');
                }
                if (!values?.endTime && !values?.allDay) {
                  errors.endTime = t('invalidTime');
                }
                return errors;
              }}
              render={({
                         handleSubmit,
                         form,
                         submitting,
                         pristine,
                         errors,
                         values,
                       }) => {
                return (
                  <form onSubmit={handleSubmit} noValidate>
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field name="person">
                            {({ input, meta }) => (
                              <PeoplePicker
                                label={t('person')}
                                isDisabled={true}
                                selectedItem={
                                  values?.person || props.labourPerson
                                    ? [
                                      {
                                        key: values?.person ? values?.person : props.labourPerson?.key,
                                        text: values?.personName ? values?.personName : props.labourPerson?.text,
                                      },
                                    ]
                                    : []
                                }
                                onChange={(items: any) => {
                                  input.onChange;
                                  return items;
                                }}
                                onPersonSelected={(item: any) => {
                                  input.onChange(item.key);
                                  // props.readHumanResourceTaxonomyIdByPersonId(item.key);
                                  return item;
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="leaveType"
                            component={DropDownAdaptater}
                            lable={t('leaveType')}
                            options={props.leaveTypes}
                            placeholder={t('leaveType')}
                            required={true}
                            errorMessage={
                              form.getState()?.errors?.leaveType
                            }
                          />
                        </div>
                      </Stack.Item>
                    </Stack>

                    <Separator/>
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <div className={`${classNames.divMarginBottom}`}>
                        <Field name="allDay">
                          {({ input, meta }) => (
                            <Toggle
                              label={t('allDay')}
                              checked={values?.allDay}
                              onText="On"
                              offText="Off"
                              onChange={(
                                ev: React.MouseEvent<HTMLElement>,
                                checked?: boolean,
                              ) => {
                                if (checked) {
                                  setAbesence({
                                    ...absence,
                                    leaveType: values?.leaveType,
                                    allDay: true,
                                    startDate: values?.startDate,
                                    endDate: values?.endDate,
                                    startTime: '',
                                    endTime: '',
                                  });
                                }
                                input.onChange(checked);
                              }}
                            />)}
                        </Field>
                      </div>
                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="startDate"
                            component={DatePickerRequiredAdapter}
                            lable={t('startDate')}
                            parse={(value) => {
                              if (value) {
                                if (values?.allDay) {
                                  form.mutators.setEndDate(formatDateTimeToISOString(value));
                                  return formatDateTimeToISOString(value);
                                } else {
                                  return formatDateTimeToISOString(value);
                                }
                              }
                            }
                            }
                            format={(value) => (value ? new Date(value) : null)}
                            errorMessage={
                              form.getState()?.errors?.startDate
                            }
                          />
                        </div>
                      </Stack.Item>

                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="endDate"
                            component={DatePickerRequiredAdapter}
                            lable={t('endDate')}
                            parse={(value) =>
                              value && formatDateTimeToISOString(value)
                            }
                            format={(value) => (value ? new Date(value) : null)}
                            minDate={
                              new Date(form.getFieldState('startDate')?.value)
                            }
                            disabled={false}
                            errorMessage={
                              form.getState()?.errors?.startDate
                            }
                          />
                        </div>
                      </Stack.Item>
                    </Stack>

                    <div className={`${classNames.divMarginTop}`}>
                      <Stack
                        horizontal
                        wrap
                        styles={stackStyles}
                        tokens={stackTokens}
                      >
                        <Stack.Item grow={6} styles={stackItemStyles}>
                          <div className={`${classNames.fullWidth}`}>
                            <Label>{t('startTime')}</Label>
                            <Field name="startTime" errorMessage={
                              form.getState()?.errors?.startTime
                            }>
                              {({ input, meta }) => (
                                <div>
                                  <MaskedInput
                                    value={values?.startTime}
                                    style={{ width: '375px' }}
                                    mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                                    placeholder={'HH:mm'}
                                    onChange={(e: any, newValue?: string) => {
                                      const timeValidation: boolean = moment(e.target.value, 'HH:mm', true).isValid();
                                      if (e.target.value) {
                                        if (timeValidation) {
                                          input.onChange(e.target.value);
                                          // return e.target.value;
                                        } else {
                                          input.onChange('');
                                        }
                                      }
                                    }}
                                    // value={'item[column.fieldName]'}
                                    keepCharPositions={true}
                                    disabled={values?.allDay}
                                  />
                                  {((meta.submitError &&
                                    !meta.dirtySinceLastSubmit) || meta.error) &&
                                  meta.touched &&
                                  <Label style={{
                                    marginTop: '0px',
                                    color: '#a4262c',
                                    fontSize: '12px',
                                    fontWeight: '-moz-initial',
                                  }}>
                                    {meta.error}
                                  </Label>}
                                </div>)}
                            </Field>
                          </div>
                        </Stack.Item>

                        <Stack.Item grow={6} styles={stackItemStyles}>
                          <div className={`${classNames.fullWidth}`}>
                            <Label>{t('endTime')}</Label>
                            <Field name="endTime" errorMessage={
                              form.getState()?.errors?.endTime
                            }>
                              {({ input, meta }) => (
                                <div><MaskedInput
                                  value={values?.endTime}
                                  style={{ width: '375px' }}
                                  mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                                  placeholder={'HH:mm'}
                                  onChange={(e: any, newValue?: string) => {
                                    const timeValidation: boolean = moment(e.target.value, 'HH:mm', true).isValid();
                                    if (e.target.value && timeValidation) {
                                      input.onChange(e.target.value);
                                      // return e.target.value;
                                    } else {
                                      input.onChange('');
                                    }
                                  }}
                                  // value={'item[column.fieldName]'}
                                  keepCharPositions={true}
                                  disabled={values?.allDay}
                                />
                                  {((meta.submitError &&
                                    !meta.dirtySinceLastSubmit) || meta.error) &&
                                  meta.touched &&
                                  <Label style={{
                                    marginTop: '0px',
                                    color: '#a4262c',
                                    fontSize: '12px',
                                    fontWeight: '-moz-initial',
                                  }}>
                                    {meta.error}
                                  </Label>}
                                </div>)}
                            </Field>
                          </div>
                        </Stack.Item>
                      </Stack>
                    </div>

                    <div className={'card-footer'}>
                      <Separator/>
                      <Stack
                        styles={stackStyles}
                        tokens={itemAlignmentsStackTokens}
                      >
                        <Stack.Item
                          align="end"
                          styles={stackFooterBarItemStyles}
                        >
                          <ActionButton
                            className={classNames.actionButton}
                            iconProps={saveIcon}
                            allowDisabledFocus
                            onClick={() => {
                              form.submit();
                              errors == null ? form.reset() : undefined;
                            }}
                            // disabled={ (props.dropDownOptions.isApproved) }
                          >
                            <Label className={classNames.actionButton}>
                              {t('save')}
                            </Label>
                          </ActionButton>
                        </Stack.Item>
                      </Stack>
                    </div>
                    {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                  </form>
                );
              }}
            />
          </div>
        </div>

      </Modal>
    </div>
  );
};

export default AbsenceModal;

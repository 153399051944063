import React, { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  Input,
  Label,
  Option,
} from '@fluentui/react-components';
import {
  getSharepointContentTypes,
  updateContentTypeValues,
} from '../../../../reducers/wbsCreateReducer';
import { useDispatch, useSelector } from 'react-redux';
import ProgressBar from '../../../../shared/progressBar/progressBar';
// import ProgressBar from '../../shared/progressBar/progressBar';

function SharePointContentTypeForm({ fields, columns }: any) {
  const [formData, setFormData] = useState<any>({});
  const [cTypes, setCTypeList] = useState([]);
  const [cTypesLoding, setCTypeLoading] = useState(true);
  const [selectedCType, setSelectedCType] = useState(null);
  const dispatch = useDispatch();
  const taskData = useSelector((state: any) => state.wbsTask.formData);
  const isDocumentMetaUpdating = useSelector(
    (state: any) => state.wbsCreate.isDocumentMetaUpdating
  );

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    if (name === 'ContentType') {
      setFormData({
        ...formData,
        [name]: getSelectedcontentTypName(value),
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const extractRelavantColumnValues = (fields: any, columns: any) => {
    let fieldValues: any = [];
    let contenType = null;
    for (let key in fields) {
      if (fields.hasOwnProperty(key)) {
        const filteredData = columns?.filter((item: any) => {
          const typeCondition =
            (item.type === 'unknownFutureValue' || item.type === 18) &&
            item.columnGroup == 'Custom Columns';
          const readonlyCondition = !item.readOnly;
          const nameCondition = item.name === key;
          const passedConditions =
            typeCondition && readonlyCondition && nameCondition;

          if (passedConditions) {
            console.log('Item passed conditions:', item);

            fieldValues = [...fieldValues, { [key]: fields[key] }];
          }

          return passedConditions;
        });
        contenType = getSelectedcontentType(fields.ContentType);
      }
    }
    setFormData(convertArrayToObject(fieldValues));

    if (!selectedCType && selectedCType !== contenType) {
      setSelectedCType(contenType);
    }
  };

  const convertArrayToObject = (array: any) => {
    return array?.reduce((result: any, obj: any) => {
      const key = Object.keys(obj)[0];
      result[key] = obj[key];
      return result;
    }, {});
  };

  useEffect(() => {
    extractRelavantColumnValues(fields, columns);
    Promise.all([
      dispatch(
        getSharepointContentTypes({ projectTitle: taskData?.projectTitle })
      ),
    ]).then((res: any) => {
      if (Array.isArray(res) && res?.length > 0) {
        setCTypeList(res[0]?.payload?.data?.result?.value);
      }
    });
  }, [fields, columns]);

  const getSelectedcontentType = (text: string) => {
    if (text) {
      const filterVal: any = cTypes?.filter((item: any) => {
        return item?.base?.name === text;
      });

      if (Array.isArray(filterVal) && filterVal.length > 0) {
        return filterVal[0]?.id;
      }
    }

    return selectedCType;
  };

  const getSelectedcontentTypName = (id: string) => {
    const filterVal: any = cTypes?.filter((item: any) => {
      return item?.id === id;
    });

    if (Array.isArray(filterVal) && filterVal.length > 0) {
      return filterVal[0]?.name;
    }
    return null;
  };

  const generateForm = () => {
    console.log('column', columns);
    return columns?.map((column: any) => {
      if (
        (column.type === 'unknownFutureValue' || column.type === 18) &&
        column.name != 'ContentType' &&
        column.columnGroup == 'Custom Columns' &&
        !column.readOnly
      ) {
        return (
          <div
            key={column.id}
            style={{ display: 'flex', flexDirection: 'column', marginTop: 5 }}
          >
            <Label style={{ marginTop: 10 }} size="large">
              {column.displayName}
            </Label>
            <Input
              name={column.name}
              placeholder={column.displayName}
              onChange={handleInputChange}
              value={formData[column.name] || ''}
            />
          </div>
        );
      } else if (
        column.type == 2 &&
        column.name != 'ContentType' &&
        column.columnGroup == 'Custom Columns' &&
        !column.readOnly
      ) {
        return (
          <div
            key={column.id}
            style={{ display: 'flex', flexDirection: 'column', marginTop: 5 }}
          >
            <Label style={{ marginTop: 10 }} size="large">
              {column.displayName}
            </Label>
            <Dropdown
              aria-labelledby={column.displayName}
              placeholder={column.displayName}
              value={formData[column.name]}
              onOptionSelect={(_event: any, data: any) => {
                setFormData({
                  ...formData,
                  [column.name]: data?.optionValue,
                });
              }}
            >
              {column?.choice?.choices?.map((option: any) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Dropdown>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const { ContentType, ...remainingObject } = formData as any;
  };

  return (
    <>
      <ProgressBar show={isDocumentMetaUpdating} />
      {
        <form onSubmit={handleSubmit}>
          <div style={{ backgroundColor: '#ffffff' }}>
            <div
              key={'connetType'}
              style={{ display: 'flex', flexDirection: 'column', marginTop: 5 }}
            >
              <Label style={{ marginTop: 10 }} size="large">
                {'Content Type'}
              </Label>
              <Dropdown
                aria-labelledby={'ConnetType'}
                placeholder="Connet Type"
                value={getSelectedcontentTypName(selectedCType!!)}
                onOptionSelect={(_event: any, data: any) => {
                  setSelectedCType(data?.optionValue);
                }}
              >
                {cTypes?.map((option: any) => (
                  <Option key={option.name} value={option?.id}>
                    {option.name}
                  </Option>
                ))}
              </Dropdown>
            </div>
            {generateForm()}
            <Button
              onClick={() => {
                const { FileLeafRef, otherFormData } = formData;
                const payload = {
                  contentType: {
                    id: selectedCType,
                  },
                  fields: {
                    additionalData: {
                      FileLeafRef: FileLeafRef,
                    },
                    ...otherFormData,
                  },
                };
                dispatch(updateContentTypeValues(payload));
              }}
              style={{ width: '100%', marginTop: 20 }}
            >
              Save
            </Button>
          </div>
        </form>
      }
    </>
  );
}

export default SharePointContentTypeForm;

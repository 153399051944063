import { ITextFieldStyleProps, ITextFieldStyles, TextField } from '@fluentui/react/lib/TextField';
import { ILabelStyleProps, ILabelStyles } from '@fluentui/react/lib/Label';
import * as React from 'react';
import { iconColors } from '../../../theme';


const NumberFieldWrapper: React.FC<any> = ({ input: { name, onChange, value, ...restInput }, meta, lable, ...rest }) => {
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;
  const handleFocus = (event: { target: { select: () => any; }; }) => event.target.select();
  return (
    <TextField
      {...rest}
      name={name}
      label={lable}
      helperText={showError ? meta.error || meta.submitError : undefined}
      errorMessage={showError ? meta.error : ''}
      inputProps={restInput}
      onChange={onChange}
      value={value}
      type={'number'}
      style={{ textAlign: 'right', color: rest.hasChanged && iconColors.iconActiveColor }}
      styles={rest.hasChanged && getStyles}
      onFocus={handleFocus}
    />
  );
};

function getStyles(props: ITextFieldStyleProps): Partial<ITextFieldStyles> {
  return {
    fieldGroup: [
      { borderColor: iconColors.iconActiveColor },
    ],
    subComponentStyles: { label: getLabelStyles },
  };
}

function getLabelStyles(props: ILabelStyleProps): ILabelStyles {
  return { root: { color: iconColors.iconActiveColor } };
}


export default NumberFieldWrapper;

import React from 'react';
import DefaultCustomModal from "../../../../shared/customeModal/defaultCustomModal";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

interface Props {
    isJSONOpen: boolean
    handleClose: () => void
}

function DisplayJSONModal(props: Props) {
    const {t} = useTranslation();

    const {
        isJSONOpen,
        handleClose,
    } = props;

    const {
        formData,
    } = useSelector(
      (state: any) => state.ciaw
    );

    return <>
        <DefaultCustomModal
          isModalOpen={!isJSONOpen}
          title={t('ciawResponse')}
          onRenderFooter={() => {
          }}
          handleClose={handleClose}
        >
            <span style={{display: 'block', maxWidth: 200, minWidth: 150}}>
                    {formData?.jsonString ?? t('jsonStringNotAvailable')}
            </span>
        </DefaultCustomModal>
    </>
}

export default DisplayJSONModal;
import { createForm } from 'final-form';
import _ from 'lodash';
import {
  ActionButton,
  getTheme,
  IBasePickerSuggestionsProps,
  IIconProps,
  IPersona,
  IPersonaProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  NormalPeoplePicker,
  Separator,
  Stack,
} from '@fluentui/react';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { uPrinceTheme } from '../../../../theme';
import client from '../../../api';
import DropDownAdaptater from '../../../shared/fluentFinalForm/DropDown';
import TextFieldFF from '../../../shared/fluentFinalForm/TextField';
import HistoryBar from '../../../shared/historyBar/historyBar';
import AddressLocationComponent from '../../../shared/location/address/component';
import ProjectHeader from '../../../shared/projectHeader/projectHeader';
import { getProject } from '../../../shared/util';
import { LocationAddress, Position } from '../../../types/projectDefinition';
import { TYPE_MOBILE_ID, Warehouse, warehouseValidationMessage } from '../../../types/warehouse';
import TaxonomyField from './taxonomy/taxonomy';
import FixedDecimalNumberFormatField from '../../../shared/fluentFinalForm/FixedDecimalNumberFormatField';
import SearchableDropdown from '../../../shared/searchableDropdown/searchableDropdown';
import { READ_CPC_VEHICLES } from '../../../shared/endpoints';


const theme = getTheme();

const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};
const stackItemStylesLabel: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 'fit-content',
    maxWidth: 'fit-content',
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
});


export const DocumentPaneComponent = (props: {
  dropDownOptions: any;
  formData: Warehouse;
  validationMessages: warehouseValidationMessage;
  isEdit: boolean;
  isDisabled: boolean;
  position: Position;
  setLocationAddress: (position: Position, address: LocationAddress) => void;
  openMapModal: () => void;
  address: any;
  saveFormData: any;
  onFilterTaxonomyNodeChanged: any;
  formValues: any;
  setForm: (form: any) => void;
  submit: any,
  projectHeader: any,
  showProgress: boolean;
  uid: any,
  isClear: boolean;
}) => {
  const { t } = useTranslation();

  const onSubmit = async (values: any) => {
    const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
    await sleep(300);
    if (!values.header.id) {
      values.header.id = props.uid;
    }
    const formData = { ...values?.header };
    props.saveFormData(formData);
  };
  const formRef = React.useRef(createForm({ onSubmit: onSubmit }));
  const [vehicaleNo, setVehicle] = useState(null);
  const [vehicalNoErrorMsg, setVehicalNoErrorMsg] = useState(null);

  const getInitialFormValues = (formData: Warehouse, formValue: any) => {
    if (location.pathname.split('/').pop() === 'new' && props.isClear) {
      return { header: { statusId: null } };
    } else if (formData && formData?.sequenceId) {
      return {
        header: {
          id: formData?.id,
          title: formData?.title,
          sequenceId: formData?.sequenceId,
          name: formData?.name,
          locationId: formData?.locationId,
          statusId: formData?.status?.key,
          typeId: formData?.type?.key,
          area: formData?.area,
          openingHoursFrom: formData?.openingHoursFrom,
          openingHoursTo: formData?.openingHoursTo,
          warehouseManager: formData?.managerName,
          managerId: formData?.managerId,
          vehicleNumber: formData?.vehicleNumber,
        },
      };
    }
    return formValue;
  };

  const getPosition = (position: any) => {
    if (typeof position?.lat === 'string') {
      return { lat: parseFloat(position?.lat), lon: parseFloat(position?.lon) };
    }
    return position;
  };


  const onWarehouseManagerFilterPersonChanged = (
    filterText: string, selectedItems?: IPersonaProps[] | undefined,
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return getPersonByNameForWarehouseManager(filterText);
    }
    return [];
  };

  const getPersonByNameForWarehouseManager = async (name: string) => {
    let data: { key: string; text: string }[] = [];
    const response = await client.post(
      'CentralAddressBook/PersonFilterByName', { fullName: name },
    );
    if (response && response.data && _.isArray(response.data.result)) {
      data = formatWarehouseManagerResponse(response);
    }
    return data;
  };

  const formatWarehouseManagerResponse = (response: any) => {
    if (response && response.data) {
      if (response.data.result && _.isArray(response.data.result)) {
        const data = response.data.result.map((item: any) => {
          return {
            key: item.key,
            text: item.text,
          };
        });
        return data;
      }
      return [];
    }
    return [];
  };


  const selectedWarehouseManagerItems = () => {
    let item: IPersona[] = [];
    const formData = formRef.current.getState().values;
    const warehouseManagerId = formData && formData.header ? formData.header.managerId : null;
    const warehouseManager = formData && formData.header ? formData.header.warehouseManager : null;
    if (warehouseManagerId) {
      const warehouseManagerItem = { key: warehouseManagerId, text: warehouseManager };
      item = [warehouseManagerItem];
    }

    return item;
  };

  const onWarehouseManagerSelected = (item: any) => {
    if (item) {
      const warehouseManager = {
        id: item.key,
        name: item.text,
      };
      formRef.current.mutators.setWarehouseManager(warehouseManager);
    }
    return item;
  };

  const limitedSearchAdditionalProps: IBasePickerSuggestionsProps = {
    resultsMaximumNumber: 10,
    searchingText: 'Searching...',
  };


  const formatVehicleResponse = (response: any) => {
    let data: { value: string; label: string }[] = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: any) => {
        return {
          value: item.key,
          label: item.text,
          sequenceCode: item.key,
        };
      });
    }
    return data;
  };

  const searchVehicles = async (name: string) => {
    if (name) {
      const response = await client.get(READ_CPC_VEHICLES + name);
      return formatVehicleResponse(response);
    } else {
      return [];
    }
  };

  const cpcPromiseOptions = (inputValue: any) =>
    new Promise((resolve) => {
      // setTimeout(() => {
      resolve(searchVehicles(inputValue));
      // }, 1000);
    });

  return (
    <div style={{ width: '100%' }} id={'warehouseForm'}>
      <div>
        <Form
          form={formRef.current}
          onSubmit={onSubmit}
          keepDirtyOnReinitialize
          // initialValuesEqual={() => undefined}
          validate={values => {
            const errors: any = { header: {} };
            if (!values?.header?.name && !props.showProgress) {
              errors.header.name = t('nameRequired');
            }
            if (!values?.header?.typeId && !props.showProgress) {
              errors.header.typeId = t('typeRequired');
            }
            if (!values?.header?.statusId && !props.showProgress) {
              errors.header.statusId = t('statusRequired');
            }
            return errors;
          }}
          initialValues={getInitialFormValues(props.formData, props.formValues)}
          mutators={{
            setWarehouseManager: (args, state: any, utils) => {
              const warehouseManager = state.fields['header.warehouseManager'];
              const warehouseManagerId = state.fields['header.managerId'];
              if (args && args[0] && args[0].name) {
                warehouseManager.change(args[0].name);
                warehouseManagerId.change(args[0].id);
              } else {
                warehouseManager.change(null);
                warehouseManagerId.change(null);
              }
            },
          }}
          render={({
                     handleSubmit,
                     form,
                     submitting,
                     pristine,
                     values,
                   }) => {
            props.setForm(form);
            props.submit(handleSubmit);

            return (
              <form onSubmit={handleSubmit} noValidate>

                <div className="proj-detail-block">
                  {getProject() && <ProjectHeader projectDetails={props.projectHeader}/>}
                </div>

                <div className="proj-detail-block" id="1">
                  <div className="document-pane-card">
                    <div className={'card-header'}>
                      <Link href="#" id="warehouse-header">
                        <Label>1. {t('warehouseHeader')}</Label>
                      </Link>
                    </div>
                    {props.formData?.sequenceId && (
                      <Stack
                        horizontal
                        wrap
                        styles={stackStyles}
                        tokens={stackTokens}
                      >
                        <Stack.Item grow={6} styles={stackItemStyles}>
                          <div className={`${classNames.fullWidth}`}>
                            <Field
                              name="header.title"
                              component={TextFieldFF}
                              lable={t('warehouseTitle')}
                              disabled={true}
                            />
                          </div>
                        </Stack.Item>
                        <Stack.Item grow={6} styles={stackItemStyles}>
                          <div className={`${classNames.fullWidth}`}>
                            <Field
                              name="header.sequenceId"
                              component={TextFieldFF}
                              lable={t('warehouseId')}
                              disabled={true}
                            />
                          </div>
                        </Stack.Item>
                      </Stack>
                    )}


                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="header.name"
                            component={TextFieldFF}
                            lable={t('name')}
                            required={true}
                            disabled={props.isDisabled}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>

                        </div>
                      </Stack.Item>
                    </Stack>


                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="header.statusId"
                            component={DropDownAdaptater}
                            options={props.dropDownOptions.status}
                            lable={t('status')}
                            placeholder={t('status')}
                            disabled={false}
                            required={true}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="header.typeId"
                            component={DropDownAdaptater}
                            options={props.dropDownOptions.types}
                            lable={t('type')}
                            placeholder={t('type')}
                            disabled={props.isDisabled}
                            required={true}
                            parse={value => {
                              // Do what you want with `value`
                              return value;
                            }}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>


                    {values?.header?.typeId === TYPE_MOBILE_ID ? <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Label required={false}>{t('vehicleNumber')}</Label>
                          <Field name="header.vehicleNumber">
                            {({ input, meta }: any) => (
                              <SearchableDropdown
                                onChange={(item: any) => {
                                  if (item) {
                                    const selectedItem = {
                                      value: item.value,
                                      label: item.label,
                                    };
                                    input.onChange(selectedItem);
                                  } else {
                                    input.onChange(null);
                                  }
                                }}
                                selectedOption={values?.header?.vehicleNumber}
                                promiseOptions={cpcPromiseOptions}
                                validationMessage={vehicalNoErrorMsg ? vehicalNoErrorMsg : ''}
                                required={false}
                                disabled={props.isDisabled}
                              />)}
                          </Field>
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                        </div>
                      </Stack.Item>
                    </Stack> : <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <div className={`${classNames.fullWidth}`}>
                        <div style={{ zIndex: 10000000 }}>
                          <AddressLocationComponent
                            setLocationAddress={(address) => {
                              props.setLocationAddress(address.position, address);
                            }}
                            position={getPosition(props.position)}
                            openMapModal={() => props.openMapModal()}
                            address={props.address}
                            disabled={props.isDisabled}
                            lable={t('coordinates')}
                            addressLable={t('address')}
                            required={false}
                            errorMessage={form.getState()?.errors?.header?.delivery}
                          />
                          <Field
                            name="header.address"
                            component={'input'}
                            type={'hidden'}
                            disabled={false}
                            hidden
                          />
                        </div>
                      </div>
                    </Stack>}


                    <div style={{ marginTop: 10, marginLeft: 10 }}>
                      <Label>{t('openingHours')}</Label>
                      <Stack
                        horizontal
                        wrap
                        styles={stackStyles}
                        tokens={stackTokens}
                      >


                        <Stack.Item grow={1} styles={stackItemStylesLabel}>
                          <div>
                            <Label>{t('from')}</Label>
                          </div>
                        </Stack.Item>
                        <Stack.Item grow={4} styles={stackItemStyles}>
                          <div>
                            <Field
                              name="header.openingHoursFrom"
                              component={TextFieldFF}
                              required={false}
                              disabled={props.isDisabled}
                            />
                            {/*<Field name="header.openingHoursFrom" >*/}
                            {/*{ ({ input, meta }:any) => (*/}
                            {/*<TimePickerField*/}
                            {/*value={ values?.header?.openingHoursFrom }*/}
                            {/*onChange={ (value:string|null) => {*/}
                            {/*input.onChange(value);*/}
                            {/*} }*/}
                            {/*required={ false }*/}
                            {/*disabled={ props.isDisabled }*/}
                            {/*/>*/}
                            {/*) }*/}
                            {/*</Field>*/}
                          </div>
                        </Stack.Item>
                        <Stack.Item grow={1} styles={stackItemStylesLabel}>
                          <div>
                            <Label>{t('to')}</Label>
                          </div>
                        </Stack.Item>
                        <Stack.Item grow={4} styles={stackItemStyles}>
                          <div>
                            <Field
                              name="header.openingHoursTo"
                              component={TextFieldFF}
                              required={false}
                              disabled={props.isDisabled}
                            />
                          </div>
                        </Stack.Item>
                        <Stack.Item grow={6} styles={stackItemStyles}>
                          <div className={`${classNames.fullWidth}`}>
                            <Label>{''}</Label>
                          </div>
                        </Stack.Item>
                      </Stack>
                    </div>


                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>

                          <Field
                            name="header.warehouseManager"
                            component={'input'}
                            type={'text'}
                            disabled={false}
                            hidden
                          />
                          <Field
                            name="header.managerId"
                            component={'input'}
                            type={'text'}
                            disabled={false}
                            hidden
                          />
                          <Label>{t('warehouseManager')}</Label>
                          <NormalPeoplePicker
                            onChange={(items) => {
                              if (items && _.isArray(items) && items.length === 0) {
                                form.mutators.setWarehouseManager(null);
                              }
                            }}
                            selectedItems={selectedWarehouseManagerItems()}
                            onResolveSuggestions={onWarehouseManagerFilterPersonChanged}
                            onItemSelected={onWarehouseManagerSelected}
                            pickerSuggestionsProps={limitedSearchAdditionalProps}
                            className={'ms-PeoplePicker'}
                            key={'warehouseManager'}
                            itemLimit={1}
                            removeButtonAriaLabel={'Remove'}
                            resolveDelay={300}
                            disabled={props.isDisabled}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="header.area"
                            component={FixedDecimalNumberFormatField}
                            lable={t('areaSquareMeter')}
                            // validate={ titleRequired }
                            required={false}
                            disabled={props.isDisabled}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>


                    <div className={`${classNames.fullWidth}`}>
                      <div style={{ zIndex: 10000000 }}>
                        <TaxonomyField
                          label={t('warehouseTaxonomy')}
                          treeData={props.dropDownOptions.warehouseTaxonomy}
                          selectItemId={props.formData.warehouseTaxonomyId
                            ? props.formData.warehouseTaxonomyId
                            : '88bc4819-qnmp-4c38-8a59-20da60ecb89e'}
                          onSelectItem={(id: string) => {
                            if (id) {
                              if (id === props.formData.warehouseTaxonomyId) {
                                props.formData.warehouseTaxonomyId = null;
                              } else {
                                props.formData.warehouseTaxonomyId = id;
                              }
                            } else {
                              props.formData.warehouseTaxonomyId = null;
                            }
                          }}
                          treeLevel={props.dropDownOptions.whTaxonomyLevel}
                          onFilterTaxonomyNodeChanged={props.onFilterTaxonomyNodeChanged}
                          disabled={!props.formData.sequenceId}
                        />
                      </div>
                    </div>


                    <div className={'card-footer'}>
                      <Separator/>
                      <Stack
                        styles={stackStyles}
                        tokens={itemAlignmentsStackTokens}
                      >
                        <Stack.Item
                          align="end"
                          styles={stackFooterBarItemStyles}
                        >
                          <ActionButton
                            className={classNames.actionButton}
                            iconProps={saveIcon}
                            allowDisabledFocus
                            onClick={() => {
                              // alert(JSON.stringify(values.purchaseOrderType, 0, 2));
                              form.submit();
                            }}
                            disabled={props.isDisabled}
                          >
                            <Label className={classNames.actionButton}>
                              {t('save')}
                            </Label>
                          </ActionButton>
                        </Stack.Item>
                      </Stack>
                    </div>
                  </div>
                </div>


                {
                  props.formData.history &&
                  props.formData.history.createdDate && (
                    <div className="proj-detail-block" id="2">
                      <div className="document-pane-card">
                        <div className="marginTop marginBottom">
                          <Link href="#" id="history">
                            <Label>2. {t('history')} </Label>
                          </Link>

                          <HistoryBar
                            createdByUser={
                              props.formData.history.createdBy
                                ? props.formData.history.createdBy
                                : ''
                            }
                            updatedByUser={
                              props.formData.history.modifiedBy
                                ? props.formData.history.modifiedBy
                                : ''
                            }
                            createdDateTime={
                              props.formData.history.createdDate
                                ? props.formData.history.createdDate
                                : ''
                            }
                            updatedDateTime={
                              props.formData.history.modifiedDate
                                ? props.formData.history.modifiedDate
                                : ''
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}


                { /* <pre>{ JSON.stringify(values, 0, 2) }</pre> */}

                { /* <pre>{ JSON.stringify(values, 0, 2) }</pre> */}
              </form>
            );
          }}
        />
      </div>


    </div>

  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentPaneComponent);

import {
  CREATE_ORG_SETTING_TAXONOMY_EP,
  DELETE_ORG_SETTING_TAXONOMY_EP,
  ORG_SETTING_SAVE_EP,
  READ_ORG_PERSON_TYPE_DROPDOWN_EP,
  READ_ORG_SETTING_BY_ID_EP,
  READ_ORG_SETTING_LIST_EP,
  READ_ORG_SETTING_SHORTCUT_PANE_EP,
  READ_ORG_SETTING_TAXONOMY_LEVEL_EP,
  SET_ORG_BU_IS_DEFAULT_EP,
} from '../shared/endpoints';
import {
  CLEAR_ORG_SETTING_FORM_DATA,
  CLEAR_ORG_SETTINGUID,
  CREATE_ORG_TAXONOMY_LEVELS,
  CREATE_ORG_TAXONOMY_LEVELS_FAIL,
  CREATE_ORG_TAXONOMY_LEVELS_SUCCESS,
  DELETE_ORG_TAXONOMY_LEVELS,
  DELETE_ORG_TAXONOMY_LEVELS_FAIL,
  DELETE_ORG_TAXONOMY_LEVELS_SUCCESS,
  LOAD_ORG_SETTING_BY_ID,
  LOAD_ORG_SETTING_BY_ID_FAIL,
  LOAD_ORG_SETTING_BY_ID_SUCCESS,
  LOAD_ORG_SETTING_DROPDOWN,
  LOAD_ORG_SETTING_DROPDOWN_FAIL,
  LOAD_ORG_SETTING_DROPDOWN_SUCCESS,
  LOAD_ORG_SETTING_LSITPANE,
  LOAD_ORG_SETTING_LSITPANE_FAIL,
  LOAD_ORG_SETTING_LSITPANE_SUCCESS,
  LOAD_ORG_SETTING_SHORTCUTPANE,
  LOAD_ORG_SETTING_SHORTCUTPANE_FAIL,
  LOAD_ORG_SETTING_SHORTCUTPANE_SUCCESS,
  LOAD_ORG_SETTING_TAXONOMY,
  LOAD_ORG_SETTING_TAXONOMY_FAIL,
  LOAD_ORG_SETTING_TAXONOMY_SUCCESS,
  READ_ORG_PERSON_TYPE_DROPDOWN,
  READ_ORG_PERSON_TYPE_DROPDOWN_FAIL,
  READ_ORG_PERSON_TYPE_DROPDOWN_SUCCESS,
  READ_ORG_TAXONOMY_LEVELS,
  READ_ORG_TAXONOMY_LEVELS_FAIL,
  READ_ORG_TAXONOMY_LEVELS_SUCCESS,
  RESET_ORG_SETTING_IS_CHANGE,
  SAVE_ORG_SETTING,
  SAVE_ORG_SETTING_FAIL,
  SAVE_ORG_SETTING_SUCCESS,
  SAVE_OS_STATE_ATTR,
  SET_ORG_BU_IS_DEFAULT,
  SET_ORG_BU_IS_DEFAULT_FAIL,
  SET_ORG_BU_IS_DEFAULT_SUCCESS,
  SET_ORG_SETTING_UID,
} from '../actionTypes/organisationSettingActionTypes';
import { v4 as uuidv4 } from 'uuid';
import { OrganisationSettingListPaneFilter } from '../types/organisationSetting';


const defaultState: any = {
  osList: [],
  taxonomyList: [],
  isOSListLoaded: false,
  isNew: false,
  isChange: false,
  isEdit: false,
  showProgressBar: false,
  osDropDowns: {},
  formData: {},
  uid: null,
  loadMsg: '',
  osShortCutPaneData: {
    organisation: [],
    competencies: [],
    certifications: [],
    corporateSchedule: [],
  },
  reloadListPane: false,
  borResources: {},
  isLoadingOrganisationSetting: false,
  selectedShortCutItem: null,
  orgTaxonomyLevels: [],
  osId: null,
  personTypeDropdowns: {},
};

// eslint-disable-next-line consistent-return
export default function organisationSettingReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {
      case LOAD_ORG_SETTING_SHORTCUTPANE:
        return { ...state };
      case LOAD_ORG_SETTING_SHORTCUTPANE_SUCCESS:
        return {
          ...state,
          // eslint-disable-next-line no-use-before-define
          osShortCutPaneData: formatShorCutPaneData(action.payload.data.result),
          // osShortCutPaneData: {
          //     'organisation': [
          //         {
          //             'id': 'c46c3a26-39a5-42cc-n7k1-89655304eh2',
          //             'name': 'Organisation',
          //             'languageCode': 'en',
          //             'displayOrder': 1,
          //             'typeId': null
          //         },
          //         {
          //             'id': 'c46c3a26-39a5-42cc-n7k1-89655304eh6',
          //             'name': 'CU8888',
          //             'languageCode': 'en',
          //             'displayOrder': 2,
          //             'typeId': null
          //         },
          //         {
          //             'id': 'c46c3a26-39a5-42cc-n9wn-89655304eh6',
          //             'name': 'BU',
          //             'languageCode': 'en',
          //             'displayOrder': 3,
          //             'typeId': null
          //         },
          //         {
          //             'id': 'c46c3a26-39a5-42cc-m06g-89655304eh6',
          //             'name': 'Department',
          //             'languageCode': 'en',
          //             'displayOrder': 4,
          //             'typeId': null
          //         },
          //         {
          //             'id': 'c46c3a26-39a5-42cc-m06g-89655304eh7',
          //             'name': 'Team',
          //             'languageCode': 'en',
          //             'displayOrder': 5,
          //             'typeId': null
          //         },
          //         {
          //             'id': 'c46c3a26-39a5-42cc-m06g-89655304eh8',
          //             'name': 'Person',
          //             'languageCode': 'en',
          //             'displayOrder': 6,
          //             'typeId': null
          //         }
          //     ],
          //     'competencies': [ ],
          //     'certifications': [],
          //     'corporateSchedule': []
          // }
          // osShortCutPaneData: action.payload.data.result
        };
      case LOAD_ORG_SETTING_SHORTCUTPANE_FAIL:
        return { ...state };
      case LOAD_ORG_SETTING_LSITPANE:
        return { ...state, isOSListLoaded: false };
      case LOAD_ORG_SETTING_LSITPANE_SUCCESS:
        return {
          ...state,
          isOSListLoaded: true,
          osList: action.payload.data.result,
          loadMsg: !action.payload.data.status
            ? action.payload.data.message
            : '',
        };
      case LOAD_ORG_SETTING_LSITPANE_FAIL:
        return {
          ...state,
          isOSListLoaded: true,
          osList: [],
          loadMsg: action.error.response.data.message,
        };
      case LOAD_ORG_SETTING_TAXONOMY:
        return { ...state };
      case LOAD_ORG_SETTING_TAXONOMY_SUCCESS:
        return {
          ...state,
          taxonomyList: action.payload.data.result,
        };
      case LOAD_ORG_SETTING_TAXONOMY_FAIL:
        return {
          ...state,
          taxonomyList: [],
        };
      case RESET_ORG_SETTING_IS_CHANGE:
        return {
          ...state,
          isChange: false,
        };

      case LOAD_ORG_SETTING_DROPDOWN:
        return { ...state, osDropDowns: {} };
      case LOAD_ORG_SETTING_DROPDOWN_SUCCESS:
        return { ...state, osDropDowns: action.payload.data.result };
      case LOAD_ORG_SETTING_DROPDOWN_FAIL:
        return {
          ...state,
          osDropDowns: {},
        };
      case LOAD_ORG_SETTING_BY_ID:
        return { ...state, formData: {}, showProgressBar: true, isLoadingOrganisationSetting: true };
      case LOAD_ORG_SETTING_BY_ID_SUCCESS:
        return {
          ...state,
          isEdit: true,
          formData: action.payload.data.result,
          showProgressBar: false,
          isLoadingOrganisationSetting: false,
        };
      case LOAD_ORG_SETTING_BY_ID_FAIL:
        return {
          ...state,
          formData: {},
          showProgressBar: false,
          isLoadingOrganisationSetting: false,
        };

      case SAVE_ORG_SETTING:
        return { ...state, showProgressBar: true, reloadListPane: false, osId: null };
      case SAVE_ORG_SETTING_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          reloadListPane: true,
          osId: action.payload.data.result,
        };
      case SAVE_ORG_SETTING_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
          osId: null,
        };

      case SET_ORG_SETTING_UID:
        return {
          ...state,
          isEdit: false,
          uid: uuidv4(),
        };
      case CLEAR_ORG_SETTINGUID:
        return {
          ...state,
          uid: null,
          isChange: false,
        };
      case CLEAR_ORG_SETTING_FORM_DATA:
        return {
          ...state,
          uid: null,
          isNew: true,
          isChange: false,
          formData: {},
        };
      // eslint-disable-next-line no-case-declarations
      case SAVE_OS_STATE_ATTR:
        const attr = Object.keys(action)[1];
        const value = Object.values(action)[1];
        return {
          ...state,
          [attr]: value,
        };
      case READ_ORG_TAXONOMY_LEVELS:
        return {
          ...state,
          orgTaxonomyLevels: [],
        };
      case READ_ORG_TAXONOMY_LEVELS_SUCCESS:
        return {
          ...state,
          orgTaxonomyLevels: action.payload.data.result,
        };
      case READ_ORG_TAXONOMY_LEVELS_FAIL:
        return {
          ...state,
          orgTaxonomyLevels: [],
        };
      case DELETE_ORG_TAXONOMY_LEVELS:
        return { ...state, showProgressBar: true };
      case DELETE_ORG_TAXONOMY_LEVELS_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
          // reloadListPane: true,
        };
      case DELETE_ORG_TAXONOMY_LEVELS_FAIL:
        return {
          ...state,
          showProgressBar: false,
          // reloadListPane: false,
        };
      case CREATE_ORG_TAXONOMY_LEVELS:
        return { ...state, reloadListPane: false, osId: null };
      case CREATE_ORG_TAXONOMY_LEVELS_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
          // reloadListPane: true,
          osId: action.payload.data.result,
        };
      case CREATE_ORG_TAXONOMY_LEVELS_FAIL:
        return {
          ...state,
          showProgressBar: false,
          // reloadListPane: false,
          osId: null,
        };
      case READ_ORG_PERSON_TYPE_DROPDOWN:
        return {
          ...state,
          personRoleDropdowns: [],
        };
      case READ_ORG_PERSON_TYPE_DROPDOWN_SUCCESS:
        return {
          ...state,
          personRoleDropdowns: action.payload.data.result.organizationTeamRole,
        };
      case READ_ORG_PERSON_TYPE_DROPDOWN_FAIL:
        return {
          ...state,
          personRoleDropdowns: [],
        };

      case SET_ORG_BU_IS_DEFAULT:
        return {
          ...state,
        };
      case SET_ORG_BU_IS_DEFAULT_SUCCESS:
        return {
          ...state,
        };
      case SET_ORG_BU_IS_DEFAULT_FAIL:
        return {
          ...state,
        };
      default:
        return state;
    }
  }
}

export const readOrganisationSettingShortCutPaneData = () => {
  return {
    types: [
      LOAD_ORG_SETTING_SHORTCUTPANE,
      LOAD_ORG_SETTING_SHORTCUTPANE_SUCCESS,
      LOAD_ORG_SETTING_SHORTCUTPANE_FAIL,
    ],
    payload: { request: { url: READ_ORG_SETTING_SHORTCUT_PANE_EP } },
  };
};

// export const readOrganisationSettingDropDownData = () => {
//     return {
//         types: [
//             LOAD_ORG_SETTING_DROPDOWN,
//             LOAD_ORG_SETTING_DROPDOWN_SUCCESS,
//             LOAD_ORG_SETTING_DROPDOWN_FAIL
//         ],
//         payload: { request: { method: 'GET', url: READ_ORG_SETTING_DROPDOWN_EP } }
//     };
// };


export const filterOrganisationSettingListPaneData = (filter: OrganisationSettingListPaneFilter) => {
  return {
    types: [
      LOAD_ORG_SETTING_LSITPANE,
      LOAD_ORG_SETTING_LSITPANE_SUCCESS,
      LOAD_ORG_SETTING_LSITPANE_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_ORG_SETTING_LIST_EP,
        data: filter,
      },
    },
  };
};


export const getTaxonomyList = (filter: OrganisationSettingListPaneFilter) => {
  return {
    types: [
      LOAD_ORG_SETTING_TAXONOMY,
      LOAD_ORG_SETTING_TAXONOMY_SUCCESS,
      LOAD_ORG_SETTING_TAXONOMY_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_ORG_SETTING_LIST_EP,
        data: filter,
      },
    },
  };
};


export const readByOrganisationSettingId = (id: string) => {
  return {
    types: [
      LOAD_ORG_SETTING_BY_ID,
      LOAD_ORG_SETTING_BY_ID_SUCCESS,
      LOAD_ORG_SETTING_BY_ID_FAIL],
    payload: { request: { url: READ_ORG_SETTING_BY_ID_EP + id } },
  };
};

export const readOrganizationSettingTaxonomyLevels = () => {
  return {
    types: [
      READ_ORG_TAXONOMY_LEVELS,
      READ_ORG_TAXONOMY_LEVELS_SUCCESS,
      READ_ORG_TAXONOMY_LEVELS_FAIL],
    payload: { request: { url: READ_ORG_SETTING_TAXONOMY_LEVEL_EP } },
  };
};

export const resetIsChange = () => {
  return { type: RESET_ORG_SETTING_IS_CHANGE };
};

export const saveFormData = (formData: any) => {
  return {
    types: [
      SAVE_ORG_SETTING,
      SAVE_ORG_SETTING_SUCCESS,
      SAVE_ORG_SETTING_FAIL],
    payload: {
      request: {
        url: ORG_SETTING_SAVE_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const createOrgTaxonomy = (data: any) => {
  return {
    types: [
      CREATE_ORG_TAXONOMY_LEVELS,
      CREATE_ORG_TAXONOMY_LEVELS_SUCCESS,
      CREATE_ORG_TAXONOMY_LEVELS_FAIL],
    payload: {
      request: {
        url: CREATE_ORG_SETTING_TAXONOMY_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const deleteOrgTaxonomy = (id: any) => {
  return {
    types: [
      DELETE_ORG_TAXONOMY_LEVELS,
      DELETE_ORG_TAXONOMY_LEVELS_SUCCESS,
      DELETE_ORG_TAXONOMY_LEVELS_FAIL],
    payload: {
      request: {
        url: DELETE_ORG_SETTING_TAXONOMY_EP + id,
        method: 'DELETE',
      },
    },
  };
};

export const setUid = () => {
  return { type: SET_ORG_SETTING_UID };
};

export const clearFormData = () => {
  return { type: CLEAR_ORG_SETTING_FORM_DATA };
};

export function saveOSStateAttr(attr: string, value: any) {
  return {
    type: SAVE_OS_STATE_ATTR,
    [attr]: value,
  };
}

export const readOrgPersonTypeDropdown = () => {
  return {
    types: [
      READ_ORG_PERSON_TYPE_DROPDOWN,
      READ_ORG_PERSON_TYPE_DROPDOWN_SUCCESS,
      READ_ORG_PERSON_TYPE_DROPDOWN_FAIL],
    payload: { request: { url: READ_ORG_PERSON_TYPE_DROPDOWN_EP } },
  };
};
export const setDefaultBu = (data:any) => {
  return {
    types: [
      SET_ORG_BU_IS_DEFAULT,
      SET_ORG_BU_IS_DEFAULT_SUCCESS,
      SET_ORG_BU_IS_DEFAULT_FAIL],
      payload: {
        request: {
          url: SET_ORG_BU_IS_DEFAULT_EP,
          method: 'POST',
          data: data,
        },
      },
  };
};

const formatShorCutPaneData = (data: any) => {
  return {
    organisation: data.organisation,
    competencies: [],
    certifications: [],
    corporateSchedule: [],
  };
};

import React, { useEffect, useState } from 'react';
import {
  ContextualMenu,
  DefaultButton,
  FontWeights,
  getTheme,
  IconButton,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../theme';
import { useBoolean, useId } from '@uifabric/react-hooks';
import CustomDatePicker from '../customDatePicker/customDatePicker';
import moment from 'moment';

const theme = getTheme();


const DateRangePicker = (
  props: {
    placeholder?: string,
    label?: string,
    errorMessage?: string,
    required?: boolean,
    value?: { toDate: any, fromDate: any },
    onSelected: (item: any) => void
  },
) => {
  // model////////
  const { t } = useTranslation();
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(true);
  const [isOpen, setIsOpen] = useState(false);
  const [fromDate, setFromDate]: any = useState(null);
  const [toDate, setToDate]: any = useState(null);

  useEffect(() => {
    if (props.value) {
      if (!props.value.toDate && !props.value.fromDate) {
        setFromDate(null);
        setToDate(null);
      }
    }
  }, [props.value]);

  const titleId = useId('title');
  const theme = getTheme();
  const dragOptions: IDragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
  };
  const cancelIcon: IIconProps = { iconName: 'Cancel' };
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
    header: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '2px 2px 2px 10px',
        backgroundColor: uPrinceTheme.palette.themePrimary,
        fontSize: 18,
        color: 'white',
      },
    ],
    footer: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '0px 24px 14px 24px',
        textAlign: 'end',
      },
    ],
    body: {
      // flex: '4 4 auto',
      padding: '0 24px 0px 24px',
      overflowY: 'hidden',
      paddingTop: 20,
      minWidth: screen.width > 1280 ? '30vw' : '40vw',
      // minHeight: screen.width > 1280?"60vh":"75vh",
      // height: screen.width > 1280?"80vh":"75vh",
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
    subHeader: {
      flex: '1 1 auto',
      display: 'none',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      fontSize: 14,
      color: uPrinceTheme.palette.themePrimary,
      backgroundColor: '#FFF7F4',
      height: 40,
      marginLeft: 'auto',
      marginRight: '2px',
    },
  });
  const cancelIconButtonStyles = {
    root: {
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
      color: 'white',
    },
    rootHovered: { color: theme.palette.neutralDark },
  };
  const iconButtonStyles = {
    root: { color: uPrinceTheme.palette.themePrimary },
    rootHovered: { color: theme.palette.neutralDark },
  };
  const whiteIconButtonStyles = {
    root: { color: uPrinceTheme.palette.white },
    rootHovered: { color: theme.palette.neutralDark },
  };
  const cancelIconWhite: IIconProps = {
    iconName: 'Cancel',
    styles: whiteIconButtonStyles,
  };
  const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };
  const editIcon: IIconProps = { iconName: 'Edit', styles: iconButtonStyles };
  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
    fullWidth: { width: '100%' },
  });
  const addWhiteIconButtonStyles = {
    root: {
      color: uPrinceTheme.palette.white,
      width: 17,
      minWidth: 17,
      height: 15,
      paddingRight: 1,
      paddingLeft: 1,
      paddingTop: 1,
      paddingBottom: 1,
    },
    rootHovered: { color: theme.palette.neutralDark },
  };
  const addIconWhite: IIconProps = {
    iconName: 'Save',
    styles: addWhiteIconButtonStyles,
  };
  const stackStyles: IStackStyles = { root: { padding: 0 } };
  const stackItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 50,
      paddingBottom: 10,
      flexGrow: 1,
      width: 0,
    },
  };
  const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
  };


  const resetValues = () => {

  };

  const getDisplayDate = () => {
    if (toDate && fromDate) {
      const toDateString = moment.utc(toDate).local().format('DD/MM');
      const fromDateString = moment.utc(fromDate).local().format('DD/MM');
      return fromDateString + ' - ' + toDateString;
    }
    return props.placeholder ? t(props.placeholder) : '';
  };


  return (
    <div>
      <DefaultButton onClick={() => {
        setIsOpen(true);
      }} text={getDisplayDate()} style={{ width: 150 }}/>
      <Modal
        titleAriaId={titleId}
        isOpen={isOpen}
        onDismiss={() => {
          setIsOpen(false);
          resetValues();
        }}
        isBlocking={true}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>{props.label ? t(props.label) : ''} </span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              setIsOpen(false);
              resetValues();
            }}
          />
        </div>
        <div className={contentStyles.body}>
          <div className={'ms-Grid'} style={{ width: '100%', height: '100%' }}>
            <div className="ms-Grid-row">
              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <Label>{t('From')}</Label>
                    <CustomDatePicker
                      firstDayOfWeek={1}
                      setMinDate={null}
                      setMaxDate={null}
                      setValue={fromDate}
                      getValue={(date: Date) => {
                        setFromDate(date);
                        setToDate(date);
                        // onChangeEndDateTime(formatDate(date, 2));
                      }}
                    />
                  </div>
                </Stack.Item>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <Label>{t('To')}</Label>
                    <CustomDatePicker
                      firstDayOfWeek={1}
                      setMinDate={fromDate}
                      setMaxDate={null}
                      setValue={toDate}
                      getValue={(date: Date) => {
                        setToDate(date);
                        // onChangeEndDateTime(formatDate(date, 2));
                      }}
                    />
                  </div>
                </Stack.Item>
              </Stack>
            </div>
          </div>
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={addIconWhite}
            text={t('confirm')}
            disabled={fromDate ? false : true}
            style={{ marginTop: 10, marginBottom: 10, marginRight: 10 }}
            onClick={() => {
              props.onSelected({ from: fromDate, to: toDate });
              setIsOpen(false);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default DateRangePicker;

import { Configuration, PopupRequest } from "@azure/msal-browser";
import ENV from "../../env.json";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId:  ENV.CLIEND_ID,
        authority: ENV.AUTHORITY,
        redirectUri: ENV.REDIRECR_URI,
        postLogoutRedirectUri: '/',
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ENV.GRAPGH_SCOPE
};


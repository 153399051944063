import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';

const CABDocumentPaneCommandBar = () => {
  const newClick = () => {
    messageService.sendMessage({
      isNewCAB: true,
    });
  };
  const saveClick = () => {
    messageService.sendMessage({
      saveCab: true,
    });
  };
  const { t } = useTranslation();
  const getItems = () => {
    return [
      {
        key: 'newItem',
        text: t('new'),
        cacheKey: 'myCacheKey',
        iconProps: { iconName: 'Add' },
        onClick: newClick,
      },
      {
        key: 'saveItem',
        text: t('save'),
        cacheKey: 'myCacheKey',
        iconProps: { iconName: 'Save' },
        onClick: saveClick,
      },
      // {
      //   key: 'cloneItem',
      //   text: t('clone'),
      //   cacheKey: 'myCacheKey',
      //   iconProps: { iconName: 'Copy' },
      // },
      // {
      //   key: 'removeItem',
      //   text: t('remove'),
      //   cacheKey: 'myCacheKey',
      //   iconProps: { iconName: 'Cancel' },
      // },
      // {
      //   key: 'printItem',
      //   text: t('print'),
      //   cacheKey: 'myCacheKey',
      //   iconProps: { iconName: 'Print' },
      // }
    ];
  };

  return (
    <CommandBar
      items={getItems()}
      overflowButtonProps={{ ariaLabel: 'More commands' }}
      className="common-commandbar"
      ariaLabel={'Use left and right arrow keys to navigate between commands'}
    />
  );
};

export default CABDocumentPaneCommandBar;

import React from 'react';
import { connect } from 'react-redux';
import { DirectionalHint, Label, Link, Stack, Text, TooltipHost } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { messageService } from '../../../services/messageService';
import { iconColors, uPrinceTheme } from '../../../../theme';

const classNames = mergeStyleSets({
  wrapper: {
    // height: 'calc(100vh - 14vh)!important',
    // position: 'relative',
    position: 'relative',
    // height: 'calc(90vh) !important',
  },
  selected: {
    fontWeight: 'bold',
    color: '#854114!important',
  },
});

export const CpcShortCutPaneComponent = (props: {
  toggleOverlay: () => void;
  toggleOverlayFwd: () => void;
  divClass: string;
  shortCutPaneFilters: [];
  currentActiveSection: string | null
  handelCpcFilter: (id: string | null) => void;
  resetCpcFilter: () => void;
}) => {
  const { t } = useTranslation();

  const setFilterOption = (id: string | null, attribute: string) => {
    if (attribute === 'All') {
      props.resetCpcFilter();
    } else {
      props.handelCpcFilter(id);
    }
    messageService.sendMessage({
      cpcListPaneTitle: attribute,
    });
  };
  const renderFilterItems = () => {
    if (props.shortCutPaneFilters.length > 0) {
      return props.shortCutPaneFilters.map((item: any) => {
        return <Link
          key={item.id + 'link'}
          className={`${
            props.currentActiveSection == item.id
              ? classNames.selected
              : ''
          }`}
          href="#"
          onClick={(event) => {
            event.preventDefault();
            setFilterOption(item.id, item.name);
          }}
        >
          <TooltipHost
            key={item.id + 't'}
            content={item.name}
            id="filter-opt-1"
            calloutProps={{ gapSpace: 0 }}
            directionalHint={DirectionalHint.rightCenter}
          >
            <i
              key={item.id + 'i'}
              className={`ms-Icon ${item.icon}`}
              aria-labelledby="filter-opt-1"
              aria-hidden="true"
              style={getIconActiveStyle(item.id)}
            ></i>
          </TooltipHost>
          <i
            key={item.id + 'i2'}
            className={`filter-icon ms-Icon ${item.icon} filter-icon`}
            aria-hidden="true"
            style={getIconActiveStyle(item.id)}
          ></i>
          <Text key={item.id}>{item.name}</Text>
        </Link>;
      });

    }
  };

  const getIconActiveStyle = (type: string | null) => {
    return props.currentActiveSection === type ? { color: iconColors.iconActiveColor } : { color: uPrinceTheme.palette.themePrimary };
  };

  return (
    <div className={` shortCutPaneWrapper ${props.divClass} ${classNames.wrapper}`} onMouseOver={() => {
      if (window.innerWidth > 1100 && window.innerWidth < 1600)
        messageService.sendMessage({ shortCutPaneExpanded: true });
    }} onMouseLeave={() => {
      if (window.innerWidth > 1100 && window.innerWidth < 1600)
        messageService.sendMessage({ shortCutPaneExpanded: false });
    }}>
      <div className="inner-container">
        <i
          onClick={props.toggleOverlay}
          className="ms-Icon ms-Icon--Back back-arrow"
          aria-hidden="true"
        ></i>
        <i
          onClick={props.toggleOverlayFwd}
          className="ms-Icon ms-Icon--Forward fwd-arrow"
          aria-hidden="true"
        ></i>

        <div className="filter-data">
          <Label>{t('productCatalogue')}</Label>
        </div>

        <div className="filter-option-list">
          <Stack
            gap={15}
            verticalFill
            styles={{
              root: {
                width: '100%',
                verticalAlign: 'center',
              },
            }}
          >

            <Link
              key={'All-link'}
              className={`${
                props.currentActiveSection == null
                  ? classNames.selected
                  : ''
              }`}
              href="#"
              onClick={() => {
                setFilterOption(null, 'All');
              }}
            >
              <TooltipHost
                key={'All-link1'}
                content={t('all')}
                id="filter-opt-1"
                calloutProps={{ gapSpace: 0 }}
                directionalHint={DirectionalHint.rightCenter}
              >
                <i
                  key={'All-linki'}
                  className="ms-Icon ms-Icon--ViewAll"
                  aria-labelledby="filter-opt-1"
                  aria-hidden="true"
                  style={getIconActiveStyle(null)}
                ></i>
              </TooltipHost>
              <i
                key={'All-linki2'}
                className="filter-icon ms-Icon ms-Icon--ViewAll filter-icon"
                aria-hidden="true"
                style={getIconActiveStyle(null)}
              ></i>
              <Text key="all">{t('all')}</Text>
            </Link>
            {renderFilterItems()}
          </Stack>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CpcShortCutPaneComponent);

import React from 'react';
import { connect } from 'react-redux';
import { DocumentPaneComponent } from './component';
import { Selection } from '@fluentui/react/lib/DetailsList';
import UprinceLogger from '../../../shared/Logger/logger';
import { withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import _ from 'lodash';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import history from '../../../history';
import { IDropdownOption } from '@fluentui/react';
import ConfirmationDialog from '../../../shared/confirmationDialog/confirmationDialog';
import { messageService } from '../../../services/messageService';
import { getProject } from '../../../shared/util';
import {
  Cost,
  ProgressStatement,
  ProgressStatementValidationMessage,
  PSListPaneFilter,
} from '../../../types/progressStatement';
import {
  clearFormData as clearPsData,
  createPSResource,
  readByPSId,
  saveFormData,
} from '../../../reducers/progressStatementReducer';

import { getAllProjectDropdowns, getProjectHeaderDetails } from '../../../reducers/projectReducer';
import {
  ActionButtonState,
  LangKeysValidationMessage,
  Language,
  LanguageKeys,
  Translation,
  UprinceLanguage,
} from '../../../types/language';
import {
  addLanguage,
  clearLanguageData,
  createTranslation,
  getKeyWord,
  updateLanguage,
} from '../../../reducers/languageReducer';
import { LanguageKeyModel } from './modal/component';
import { getCountryList } from '../../../reducers/addressBookReducer';
//import * as countryImg from "../../organization/location/country.json";
import countries from '../../organization/location/country.json';
import {
  CreateDropdownTranslation,
  DropdownTranslation,
  DropdownType,
  FeatureType,
} from '../../../types/dropdownTranslation';
import { getDropdownData } from '../../../reducers/productCatalogueReducer';
import { readDropdownData } from '../../../reducers/projectBreakdownReducer';
import { readPMOLDropDownData } from '../../../reducers/pmolReducer';
import { borDropdownData } from '../../../reducers/billOfResourcesReducer';
import {
  createBorStatusDropdown,
  createCpcBrandDropdown,
  createCpcMaterialDropdown,
  createCpcMouDropdownKeys,
  createCpcPressureDropdown,
  createCpcResourceFamilyDropdown,
  createCpcResourceType,
  createCpcUnitOfSizeMeasureDropdown,
  createDropdownTranslation,
  createPbsExperienceDropdown,
  createPbsItemTypeDropdown,
  createPbsSkillsDropdown,
  createPbsStatusDropdown,
  createPbsToleranceDropdown,
  createPmolStatusDropdown,
  createPmolTypeDropdown,
  createProjectCurrenciesDropdown,
  createProjectLedgerDropdown,
  createProjectManagementLevelsDropdown,
  createProjectStatesDropdown,
  createProjectTemplatesDropdown,
  createProjectTypesDropdown,
  deleteDropdownTranslation,
  getBorStatusDropdownByCode,
  getCountryDistinct,
  getCpcBrandDropdownByCode,
  getCpcMaterialDropdownByCode,
  getCpcPressureDropdownByCode,
  getCpcResourceFamilyDropdownByCode,
  getCpcResourceTypesByCode,
  getCpcUnitOfSizeMeasureDropdownByCode,
  getDropdownKeyByCode,
  getDropdownLanguageCode,
  getDropDownTranslationByCode,
  getLanguageByCode,
  getLanguageDistinct,
  getPbsExperienceDropdownByCode,
  getPbsItemTypeDropdownByCode,
  getPbsSkillsDropdownByCode,
  getPbsStatusDropdownByCode,
  getPbsToleranceDropdownByCode,
  getPmolStatusDropdownByCode,
  getPmolTypeDropdownByCode,
  getProjectCurrenciesDropdownByCode,
  getProjectLedgerDropdownByCode,
  getProjectManagementLevelsDropdownByCode,
  getProjectStatesDropdownByCode,
  getProjectTemplatesDropdownByCode,
  getProjectTypesDropdownByCode,
  getTeamRoalsDropDownByCode,
  updateBorStatusDropdown,
  updateCpcBrandDropdown,
  updateCpcMaterialDropdown,
  updateCpcMouDropdownKeys,
  updateCpcPressureDropdown,
  updateCpcResourceFamilyDropdown,
  updateCpcResourceType,
  updateCpcUnitOfSizeMeasureDropdown,
  updatePbsExperienceDropdown,
  updatePbsItemTypeDropdown,
  updatePbsSkillsDropdown,
  updatePbsStatusDropdown,
  updatePbsToleranceDropdown,
  updatePmolStatusDropdown,
  updatePmolTypeDropdown,
  updateProjectCurrenciesDropdown,
  updateProjectLedgerDropdown,
  updateProjectManagementLevelsDropdown,
  updateProjectStatesDropdown,
  updateProjectTemplatesDropdown,
  updateProjectTypesDropdown,
} from '../../../reducers/dropdownTranslationReducer';
import {
  CPC_BRAND_DROPDOWN_DELETE_EP,
  CPC_MATERIAL_DELETE_EP,
  CPC_MOU_DROPDOWN_DELETE_EP,
  PROJECT_TEAMROLE_CREATE_EP,
  PROJECT_TEAMROLE_DELETE_EP,
  STOCK_RESOURCETYPE_DROPDOWN_BY_CODE_EP,
  STOCK_RESOURCETYPE_DROPDOWN_CREATE_EP,
  STOCK_RESOURCETYPE_DROPDOWN_DELETE_EP,
  STOCK_STATUS_DROPDOWN_READ_EP,
  STOCK_TYPE_DROPDOWN_READ_EP,
  STOCK_TYPES_DROPDOWN_CREATE_EP,
  STOCK_TYPES_DROPDOWN_DELETE_EP,
  WF_STATUS_DROPDOWN_BY_CODE_EP,
  WF_STATUS_DROPDOWN_CREATE_EP,
  WF_STATUS_DROPDOWN_DELETE_EP,
  WF_TYPES_DROPDOWN_BY_CODE_EP,
  WF_TYPES_DROPDOWN_CREATE_EP,
  WF_TYPES_DROPDOWN_DELETE_EP,
  WH_STATUS_DROPDOWN_BY_CODE_EP,
  WH_STATUS_DROPDOWN_CREATE_EP,
  WH_STATUS_DROPDOWN_DELETE_EP,
  WH_TAXONOMY_LEVEL_DROPDOWN_BY_CODE_EP,
  WH_TAXONOMY_LEVEL_DROPDOWN_CREATE_EP,
  WH_TAXONOMY_LEVEL_DROPDOWN_DELETE_EP,
  WH_TYPE_DROPDOWN_BY_CODE_EP,
  WH_TYPE_DROPDOWN_CREATE_EP,
  WH_TYPE_DROPDOWN_DELETE_EP,
} from '../../../shared/endpoints';
import { readStockDropDownData } from '../../../reducers/stockReducer';
import { readWorkFlowDropDownData } from '../../../reducers/workFlowReducer';
import { readWarehouseDropDownData } from '../../../reducers/warehouseReducer';

//import { getTeamRoalsDropDownByCode } from '../../../reducers/cuProjectReducer';

export interface State {
  formData: any;
  isDirty: boolean;
  redirect: boolean;
  loading: boolean;
  filter: PSListPaneFilter;
  isEdit: boolean;
  validationMessages: ProgressStatementValidationMessage;
  isCostModalOpen: boolean;
  showPrint: boolean;
  projectCostData: Cost[];
  langKeyActionButtonState: ActionButtonState;
  isOpenLanguageKeyModel: boolean;
  langKeys: LanguageKeys;
  langKeysIsEdit: boolean;
  nickNameValidationMessages: LangKeysValidationMessage;
  keyData: any;
  langSelectionDetails: DropdownTranslation;
  editTranslation: DropdownTranslation;
  countries: { key: string; text: string }[];
  lang: Language;
  countryCode: string;
  dropdownItems: any;
  cpcDropdownList: any;
  projectDropdownList: any;
  cpcBasicUnitOfMeasure: any;
  dropdownKeysList: any;
  resourceTypes: any;
  cpcMaterial: any;
  selectedDropdown: any;
  selectedCountry: any;
  selectedLanguage: any;
  selectedCode: any;
  isCreating: boolean;
  isDeleting: boolean;
  hiddenRemoveDialog: boolean;
}

interface Props extends WithTranslation {
  formData: any;
  psDropDowns: any;
  projectHeader: [] | null;
  getProjectHeaderDetails: (id: string) => void;
  clearPsData: () => void;
  showProgressBar: boolean;
  autoSave: boolean;
  isEdit: boolean;
  projectId: string | null;
  isNew: boolean;
  uid: string | null;
  saveFormData: (formData: ProgressStatement) => any;
  readByPSId: any;
  createPSResource: (data: any) => void;
  isExpand: boolean;
  getKeyWord: (id: any) => void;
  keyData: any;
  createTranslation: any;
  getCountryList?: any;
  countries: { key: string; text: string }[] | [];
  updateLanguage: (lang: Language) => void;
  addLanguage: (lang: Language) => void;
  clearLanguageData: () => void;
  cpcDropdownList: any;
  getDropdownData: any;
  getAllProjectDropdowns: any;
  projectDropdownList: any;
  cpcBasicUnitOfMeasure: any;
  resourceTypes: any;
  cpcMaterial: any;
  cpcPressureClass: any;
  cpcBrand: any;
  cpcUnitOfSizeMeasure: any;
  projectManagementLevel: any;
  projectToleranceState: any;
  projectCurrency: any;
  projectState: any;
  projectType: any;
  projectTemplate: any;
  ledgerDropDowns: any;
  pbsDropdownList: any;
  readDropdownData: any;
  toleranceStates: any;
  productStates: any;
  itemTypes: any;
  readPMOLDropDownData: any;
  pmolDropdownList: any;
  pmolStatus: any;
  pmolType: any;
  pmolDropDowns: any;
  borDropdownData: any;
  borDropdownList: any;
  borStatus: any;
  getCountryDistinct: any;
  getLanguageDistinct: any;
  getLanguageByCode: any;
  getCountryDropdown: any;
  getLanguageList: any;
  getDropdownByCode: any;
  getDropdownKeyByCode: any;
  selectedCountry: any;
  getDropdownLanguageCode: any;
  updateCpcMouDropdownKeys: any;
  createCpcMouDropdownKeys: any;
  getCpcResourceTypesByCode: any;
  updateCpcResourceType: any;
  createCpcResourceType: any;
  getCpcMaterialDropdownByCode: any;
  updateCpcMaterialDropdown: any;
  createCpcMaterialDropdown: any;
  getCpcPressureDropdownByCode: any;
  updateCpcPressureDropdown: any;
  createCpcPressureDropdown: any;
  getCpcBrandDropdownByCode: any;
  updateCpcBrandDropdown: any;
  createCpcBrandDropdown: any;
  getCpcResourceFamilyDropdownByCode: any;
  updateCpcResourceFamilyDropdown: any;
  createCpcResourceFamilyDropdown: any;
  getCpcUnitOfSizeMeasureDropdownByCode: any;
  updateCpcUnitOfSizeMeasureDropdown: any;
  createCpcUnitOfSizeMeasureDropdown: any;
  getPbsToleranceDropdownByCode: any;
  updatePbsToleranceDropdown: any;
  createPbsToleranceDropdown: any;
  getPbsStatusDropdownByCode: any;
  updatePbsStatusDropdown: any;
  createPbsStatusDropdown: any;
  getPbsItemTypeDropdownByCode: any;
  updatePbsItemTypeDropdown: any;
  createPbsItemTypeDropdown: any;
  getPbsExperienceDropdownByCode: any;
  updatePbsExperienceDropdown: any;
  createPbsExperienceDropdown: any;
  getPbsSkillsDropdownByCode: any;
  updatePbsSkillsDropdown: any;
  createPbsSkillsDropdown: any;
  getProjectManagementLevelsDropdownByCode: any;
  updateProjectManagementLevelsDropdown: any;
  createProjectManagementLevelsDropdown: any;
  getProjectCurrenciesDropdownByCode: any;
  updateProjectCurrenciesDropdown: any;
  createProjectCurrenciesDropdown: any;
  getProjectStatesDropdownByCode: any;
  updateProjectStatesDropdown: any;
  createProjectStatesDropdown: any;
  getProjectTypesDropdownByCode: any;
  updateProjectTypesDropdown: any;
  createProjectTypesDropdown: any;
  getProjectTemplatesDropdownByCode: any;
  updateProjectTemplatesDropdown: any;
  createProjectTemplatesDropdown: any;
  getProjectLedgerDropdownByCode: any;
  updateProjectLedgerDropdown: any;
  createProjectLedgerDropdown: any;
  getPmolStatusDropdownByCode: any;
  updatePmolStatusDropdown: any;
  createPmolStatusDropdown: any;
  getPmolTypeDropdownByCode: any;
  updatePmolTypeDropdown: any;
  createPmolTypeDropdown: any;
  getBorStatusDropdownByCode: any;
  updateBorStatusDropdown: any;
  createBorStatusDropdown: any;
  getTeamRoalsDropDownByCode: any;
  createDropdownTranslation: any;
  readStockDropDownData: any;
  stockDropDownsList: any;
  readWorkFlowDropDownData: any;
  wfDropDownList: any;
  readWarehouseDropDownData: any;
  whDropDownList: any;
  getDropDownTranslationByCode: any;
  deleteDropdownTranslation: any;
  setProgressVisibility: any
}

class DocumentPane extends React.Component<Props, State> {
  private _langSelection: Selection;
  subscription: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      isDirty: false,
      isEdit: false,
      redirect: false,
      loading: false,
      formData: null,
      filter: new PSListPaneFilter(),
      validationMessages: {
        nameErrorMsg: '',
        typeErrorMsg: '',
        borErrorMsg: '',
        statusErrorMsg: '',
        ledgerValidationMsg: '',
      },
      isCostModalOpen: false,
      showPrint: false,
      projectCostData: [],
      langKeyActionButtonState: { add: false, edit: true, remove: true },
      isOpenLanguageKeyModel: false,
      langKeys: {
        id: null,
        key: null,
        value: null,
        languageId: null,
        language: {},
      },
      langKeysIsEdit: false,
      nickNameValidationMessages: {
        keyErrorMsg: '',
        valueErrorMsg: '',
      },
      keyData: null,
      langSelectionDetails: new DropdownTranslation(),
      countries: [],
      lang: {
        id: null,
        country: null,
        lang: null,
        code: null,
      },
      editTranslation: new DropdownTranslation(),
      countryCode: '',
      dropdownItems: [
        {
          // key:null,
          // text:null,
        },
      ],
      cpcDropdownList: null,
      projectDropdownList: null,
      cpcBasicUnitOfMeasure: null,
      dropdownKeysList: [{}],
      resourceTypes: null,
      cpcMaterial: null,
      selectedDropdown: null,
      selectedCountry: null,
      selectedLanguage: null,
      selectedCode: null,
      isCreating: false,
      isDeleting: false,
      hiddenRemoveDialog: true,
    };

    this._langSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log('_langSelection', this._getLangSelectionDetails());
        this.setState(
          {
            langSelectionDetails: this._getLangSelectionDetails(),
            langKeyActionButtonState: this._getLangActionButtonState(),
            editTranslation: this._translationEditDetails(),
          },
          () => {
          },
        );
      },
    });
  }


  private _translationEditDetails() {
    const selectionCount = this._langSelection.getSelectedCount();
    let editTranslation = this.state.langSelectionDetails;

    if (selectionCount == 1) {
      this.setState({
        isEdit: true,
      });
      UprinceLogger.log(
        '_internetContactSelection',
        this._langSelection.getSelection(),
      );
      editTranslation = this._langSelection.getSelection()[0] as DropdownTranslation;
    } else {
      this.setState({
        isEdit: false,
      });
    }
    return editTranslation;
  }

  private _getLangSelectionDetails(): any {
    return this._langSelection.getSelection();
  }

  private _getLangActionButtonState(): ActionButtonState {
    const selectionCount = this._langSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    UprinceLogger.log('_vendorSelection', disabled);
    return disabled;
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevProps.isNew != this.props.isNew ||
      prevProps.uid != this.props.uid
    ) {
      if (this.props.isNew) {
        let formData = this.state.formData;
        formData.id = this.props.uid;
        this.setState({ formData });
      }
    } else {
    }
    // if(this.state.selectedCountry){
    // this.countryImageFilter(this.state.selectedCountry);
    // }
    if (prevProps.formData != this.props.formData) {
      this.setState(
        {
          lang: {
            id: this.props.formData.id,
            country: this.props.formData.country,
            lang: this.props.formData.lang,
            code: this.props.formData.code,
          },
        },
        () => {
          console.log('supun', this.state.lang);
        },
      );

      if (!this.state.isDirty) {
        if (!_.isEmpty(this.props.formData)) {
          let langId = this.props.formData.id;
          //let formData = this.props.formData;
          this.props.getKeyWord(langId);
          this.setState({
            formData: this.props.formData,
            //projectCostData:this.props.formData.resources
            //position:{lat:0.0,lon:0.0}, location:null
          });
        }
      } else {
        if (this.props.isNew && _.isEmpty(this.props.formData)) {
          let formData = this.state.formData;
          this.setState({
            formData: formData,
          });
        }
      }
      // this.resetValidation();
    }

    if (prevState.formData !== this.state.formData) {
      if (!_.isEmpty(this.state.formData) && this.state.formData.id === null) {
        let formData = this.state.formData;
        formData.id = this.props.uid;
        //this.props.readMaterialsData(this.props.uid!!);
        this.setState(
          {
            formData,
          },
          //this.getPsData
        );
      }
    }
  }

  reloadTranslationKeywords = (code: any) => {
    if (!_.isEmpty(code)) {
      this.props.getDropdownByCode(code);
    }
  };

  componentDidMount() {
    //this.props.getLanguageByCode('en');
    this.props.getLanguageDistinct();
    this.props.getCountryDistinct();
    //this.countryImageFilter;(
    this.props.borDropdownData();
    this.props.readPMOLDropDownData();
    this.props.readDropdownData();
    this.props.getDropdownData();
    if (this.props.projectState?.length < 0) {
      this.props.getAllProjectDropdowns();
    }
    this.props.readStockDropDownData();
    this.props.readWorkFlowDropDownData();
    this.props.readWarehouseDropDownData();
    this.props.getCountryList();
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewPs) {
          //this.resetValidation();
          this.props.clearPsData();
          this.props.clearLanguageData();
          //cancelAllRequest()
          //this.clearPsData();
        }
        if (data.data.savePsFormData) {
          this.saveFormData();
        }
        if (data.data.psFilter) {
          this.setState({
            filter: data.data.psFilter,
          });
        }
        if (data.data.pdfGenerate) {
          this.setState({
            showPrint: true,
          });
        }
      }
    });
    if (getProject()) {
      let projectId = getProject();
      if (projectId) {
        this.props.getProjectHeaderDetails(projectId);
      }
    }
    this.props.getKeyWord('');
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  countryImageFilter = (country: string) => {
    const countryCode: any = countries.filter((img) => img.name === country);

    if (_.isArray(countryCode) && !_.isEmpty(countryCode)) {
      this.setState({ countryCode: countryCode[0].code }, () => {
      });
    } else {
      this.setState({ countryCode: '' }, () => {
      });
    }
  };

  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { psFilter: this.state.filter },
    });
  };

  openCostModal = () => {
    this.setState({ isCostModalOpen: !this.state.isCostModalOpen });
  };

  render() {
    const { t } = this.props;

    return (
      <div style={{ width: '100%' }}>
        <RouteLeavingGuard
          when={this.state.isDirty}
          navigate={(path) => this.handleDiscardChangesNavigate(path)}
          shouldBlockNavigation={(location) => {
            return true;
          }}
          handleConfirmation={(isConfirm: boolean) => {
            this.setState({
              isDirty: !isConfirm,
            });
          }}
        />
        <DocumentPaneComponent
          dropDownOptions={{
            types:
              this.props.psDropDowns && this.props.psDropDowns.type
                ? this.props.psDropDowns.type
                : [],
            status:
              this.props.psDropDowns && this.props.psDropDowns.status
                ? this.props.psDropDowns.status
                : [],
            customer:
              this.props.psDropDowns && this.props.psDropDowns.customer
                ? this.props.psDropDowns.customer
                : null,
            projectCompletionDate:
              this.props.psDropDowns &&
              this.props.psDropDowns.projectCompletionDate
                ? this.props.psDropDowns.projectCompletionDate
                : null,
          }}
          formData={this.state.formData}
          projectHeader={this.props.projectHeader}
          handleFormChange={(
            progressStatement: UprinceLanguage,
            save: boolean,
          ) => {
            this.handleFormChange(progressStatement, save);
          }}
          saveFormData={() => this.saveFormData()}
          isEdit={this.props.isEdit}
          validationMessages={this.state.validationMessages}
          isLoading={this.props.showProgressBar}
          projectCostData={this.state.projectCostData}
          openCostModal={this.openCostModal}
          createPSResource={(data: any) =>
            this.props.createPSResource({
              psId: this.state.formData.id,
              resources: data.data,
              grandTotal: data.total,
            })
          }
          isExpand={this.props.isExpand}
          openLanguageKeyModel={() => {
            this.openLanguageKeyModel();
          }}
          isOpenLanguageKeyModel={this.state.isOpenLanguageKeyModel}
          handleTranslationDelete={() => {
            this._handleRemove();
          }}
          editNickName={(id: string) => {
          }}
          nickNameSelection={''}
          nickNameSelectionDetails={''}
          langKeyActionButtonState={this.state.langKeyActionButtonState}
          keyData={this.props.keyData}
          langSelection={this._langSelection}
          createTranslation={(translation: Translation) =>
            this.props.createTranslation(translation)
          }
          countries={this.state.countries}
          updateLanguage={(lang: Language) => this.props.updateLanguage(lang)}
          lang={this.state.lang}
          onInputChange={(field: string, value: string) =>
            this.onInputChange(field, value)
          }
          countryCode={this.state.countryCode}
          onFeatureTypeChange={(e, v) => {
            this.onFeatureTypeChange(e, v);
          }}
          dropdownItems={this.state.dropdownItems}
          onDropdownTypeChange={(e, v) => {
            this.onDropdownTypeChange(e, v);
          }}
          dropdownKeysList={this.state.dropdownKeysList}
          selectedDropdown={this.state.selectedDropdown}
          getCountryDropdown={this.props.getCountryDropdown}
          getLanguageList={this.props.getLanguageList}
          selectedCountry={this.state.selectedCountry}
          selectedLanguage={this.state.selectedLanguage}
          onCountryTypeChange={(e, v) => {
            this.onCountryTypeChange(e, v);
          }}
          onLanguageTypeChange={(e, v) => {
            this.onLanguageTypeChange(e, v);
          }}
          selectedCode={this.state.selectedCode}
        />

        <LanguageKeyModel
          isOpen={this.state.isOpenLanguageKeyModel}
          isEdit={this.state.isEdit}
          openLanguageKeyModel={this.openLanguageKeyModel}
          handleNickNameValidation={(
            nickNameValidationMessage: LangKeysValidationMessage,
          ) => {
            //this.handleNickNameValidation(nickNameValidationMessage);
          }}
          nickNameValidationMessages={this.state.nickNameValidationMessages}
          createTranslation={(translation: DropdownTranslation) =>
            this.props.createTranslation(translation)
          }
          languageId={this.props.formData?.id!!}
          reloadTranslationKeywords={(code: any) =>
            this.reloadTranslationKeywords(code)
          }
          translation={this.state.editTranslation}
          updateDropdownKeys={(translation: DropdownTranslation) =>
            this.updateDropdownKeys(translation)
          }
          onInputChange={(field: string, value: string | null) =>
            this.onInputChange(field, value!!)
          }
          createDropdownKeys={(translation: CreateDropdownTranslation) =>
            this.createDropdownKeys(translation)
          }
          selectedCode={this.state.selectedCode}
          isCreating={this.state.isCreating}
        />

        <ConfirmationDialog
          hidden={this.state.hiddenRemoveDialog}
          title={t('areyousure')}
          subText={t('youWontBeAbleToReversThis')}
          onClickConfirmDialog={this.onRemoveConfirm}
          onClickCancelDialog={this.onRemoveCancel}
          cancelButtonText={t('noIwonT')}
          confirmButtonText={t('yesDeleteit')}
        />
      </div>
    );
  }

  private _handleRemove = () => {
    this.setState({
      hiddenRemoveDialog: false,
    });
  };

  private onRemoveConfirm = () => {
    this.setState({
      hiddenRemoveDialog: true,
    });
    this.deleteTraslation(this.state.editTranslation);
  };

  private onRemoveCancel = () => {
    this.setState({
      hiddenRemoveDialog: true,
    });
  };

  private onFeatureTypeChange = (
    event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption,
  ) => {
    this.setState({
      dropdownKeysList: [],
      dropdownItems: [],
      selectedDropdown: null,
      selectedCountry: null,
      selectedLanguage: null,
      selectedCode: null,
      countryCode: '',
    });
    switch (item.key) {
      case FeatureType.CPC:
        this.setState(
          {
            dropdownItems: this.props.cpcDropdownList,
          },
        );
        break;

      case FeatureType.PROJECT:
        this.setState({
          dropdownItems: this.props.projectDropdownList,
        });
        break;

      case FeatureType.PBS:
        this.setState({
          dropdownItems: this.props.pbsDropdownList,
        });
        break;

      case FeatureType.PMOL:
        this.setState({
          dropdownItems: this.props.pmolDropdownList,
        });

        break;

      case FeatureType.BOR:
        this.setState({
          dropdownItems: this.props.borDropdownList,
        });
        break;
      case FeatureType.STOCK:
        this.setState({
          dropdownItems: this.props.stockDropDownsList,
        });
        break;
      case FeatureType.WF:
        this.setState({
          dropdownItems: this.props.wfDropDownList,
        });
        break;
      case FeatureType.WH:
        this.setState({
          dropdownItems: this.props.whDropDownList,
        });
        break;

      default:
        return [];
    }
  };

  private onDropdownTypeChange = (
    event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption,
  ) => {
    this.setState({
      selectedCountry: null,
      selectedLanguage: null,
      selectedCode: null,
      countryCode: '',
      dropdownKeysList: null,
      selectedDropdown: item.key,
    });
  };

  // private onDropdownTypeChange = (
  //   event: React.FormEvent<HTMLDivElement>,
  //   item: IDropdownOption
  // ) => {
  //   this.setState({
  //     selectedCountry: null,
  //     selectedLanguage: null,
  //     selectedCode: null,
  //     countryCode: '',
  //     dropdownKeysList: null,
  //   });

  //   switch (item.key) {
  //     case DropdownType.CPC_MOU:
  //       this.setState(
  //         {
  //           //dropdownKeysList:this.props.cpcBasicUnitOfMeasure,
  //           selectedDropdown: item.key,
  //         },
  //         () => {}
  //       );
  //       break;

  //     case DropdownType.CPC_RESOURCE_FAMILY:
  //       this.setState(
  //         {
  //           //dropdownKeysList:this.props.resourceTypes,
  //           selectedDropdown: item.key,
  //         },
  //         () => {}
  //       );
  //       break;

  //     case DropdownType.CPC_RESOURCE:
  //       this.setState(
  //         {
  //           //dropdownKeysList:this.props.resourceTypes,
  //           selectedDropdown: item.key,
  //         },
  //         () => {
  //           console.log('yghgyyg', this.state.dropdownKeysList);
  //           console.log('ssswswws', this.props.resourceTypes);
  //         }
  //       );
  //       break;

  //     case DropdownType.CPC_MATERIAL:
  //       this.setState({
  //         //dropdownKeysList:this.props.cpcMaterial,
  //         selectedDropdown: item.key,
  //       });
  //       break;

  //     case DropdownType.CPC_PRESSURE:
  //       this.setState({
  //         //dropdownKeysList:this.props.cpcPressureClass,
  //         selectedDropdown: item.key,
  //       });
  //       break;

  //     case DropdownType.CPC_UNIT_SIZE_MEASURE:
  //       this.setState({
  //         //dropdownKeysList:this.props.cpcUnitOfSizeMeasure,
  //         selectedDropdown: item.key,
  //       });
  //       break;

  //     case DropdownType.CPC_BRAND:
  //       this.setState({
  //         //dropdownKeysList:this.props.cpcBrand,
  //         selectedDropdown: item.key,
  //       });
  //       break;

  //     case DropdownType.PROJECT_MANAGMENT:
  //       this.setState({
  //         //dropdownKeysList:this.props.projectManagementLevel,
  //         selectedDropdown: item.key,
  //       });
  //       break;

  //     case DropdownType.PROJECT_CURRENCY:
  //       this.setState({
  //         //dropdownKeysList:this.props.projectCurrency,
  //         selectedDropdown: item.key,
  //       });
  //       break;

  //     case DropdownType.PROJECT_TOLERANCE:
  //       this.setState({
  //         //dropdownKeysList:this.props.projectToleranceState,
  //         selectedDropdown: item.key,
  //       });
  //       break;

  //     case DropdownType.PROJECT_TYPE:
  //       this.setState({
  //         //dropdownKeysList:this.props.projectType,
  //         selectedDropdown: item.key,
  //       });
  //       break;

  //     case DropdownType.PROJECT_TEMPLATE:
  //       this.setState({
  //         //dropdownKeysList:this.props.projectTemplate,
  //         selectedDropdown: item.key,
  //       });
  //       break;

  //     case DropdownType.PROJECT_LEDGER:
  //       this.setState({
  //         //dropdownKeysList:this.props.ledgerDropDowns,
  //         selectedDropdown: item.key,
  //       });
  //       break;

  //     case DropdownType.PROJECT_STATE:
  //       this.setState({
  //         //dropdownKeysList:this.props.projectState,
  //         selectedDropdown: item.key,
  //       });
  //       break;

  //     case DropdownType.PBS_TOLERANCE_STATE:
  //       this.setState({
  //         // dropdownKeysList:this.props.toleranceStates,
  //         selectedDropdown: item.key,
  //       });
  //       break;

  //     case DropdownType.PBS_PRODUCT_STATE:
  //       this.setState({
  //         //dropdownKeysList:this.props.productStates,
  //         selectedDropdown: item.key,
  //       });
  //       break;
  //     case DropdownType.PROJECT_ROLE:
  //       this.setState({
  //         //dropdownKeysList:this.props.productStates,
  //         selectedDropdown: item.key,
  //       });
  //       break;

  //     case DropdownType.PBS_ITEM_TYPES:
  //       this.setState({
  //         //dropdownKeysList:this.props.itemTypes,
  //         selectedDropdown: item.key,
  //       });
  //       break;

  //     case DropdownType.PBS_EXPERIENCE:
  //       this.setState({
  //         //dropdownKeysList:this.props.itemTypes,
  //         selectedDropdown: item.key,
  //       });
  //       break;

  //     case DropdownType.PBS_SKILLS:
  //       this.setState({
  //         //dropdownKeysList:this.props.itemTypes,
  //         selectedDropdown: item.key,
  //       });
  //       break;

  //     case DropdownType.PMOL_TYPE:
  //       this.setState({
  //         //dropdownKeysList:this.props.pmolType,
  //         selectedDropdown: item.key,
  //       });
  //       break;

  //     case DropdownType.PMOL_STATUS:
  //       this.setState({
  //         // dropdownKeysList:this.props.pmolStatus,
  //         selectedDropdown: item.key,
  //       });
  //       break;

  //     case DropdownType.BOR_STATUS:
  //       this.setState({
  //         //dropdownKeysList:this.props.borStatus,
  //         selectedDropdown: item.key,
  //       });
  //       break;

  //     default:
  //       return [];
  //   }
  // };

  private onCountryTypeChange = (
    event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption,
  ) => {
    this.setState({
      dropdownKeysList: [],
      selectedCode: null,
      selectedLanguage: null,
    });
    if (item.key) {
      this.setState(
        {
          selectedCountry: item.key,
        },
        () => {
          this.countryImageFilter(this.state.selectedCountry);
        },
      );
    }
  };

  private onLanguageTypeChange = (
    event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption,
  ) => {
    if (item.key) {
      this.setState(
        {
          selectedLanguage: item.key,
        },
        () => {
          this.props
            .getDropdownLanguageCode(
              this.state.selectedLanguage,
              this.state.selectedCountry,
            )
            .then((res: any) => {
              this.setState(
                {
                  selectedCode: res.payload.data.result.code,
                },
                () => {
                  this.onInputChange('code', this.state.selectedCode);
                },
              );
              //this.setCountrylang;
            });
        },
      );
    }
  };

  private onInputChange = (field: string, value: string) => {
    if (value.length >= 2) {

      switch (this.state.selectedDropdown) {
        case DropdownType.CPC_MOU:
          this.props.getDropdownKeyByCode(value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;

        case DropdownType.CPC_RESOURCE:
          this.props.getCpcResourceTypesByCode(value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;

        case DropdownType.CPC_MATERIAL:
          this.props.getCpcMaterialDropdownByCode(value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;

        case DropdownType.CPC_PRESSURE:
          this.props.getCpcPressureDropdownByCode(value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;

        case DropdownType.CPC_UNIT_SIZE_MEASURE:
          this.props
            .getCpcUnitOfSizeMeasureDropdownByCode(value)
            .then((res: any) => {
              this.setState({
                dropdownKeysList: res.payload.data.result,
              });
            });
          break;

        case DropdownType.CPC_BRAND:
          this.props.getCpcBrandDropdownByCode(value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;

        case DropdownType.CPC_RESOURCE_FAMILY:
          this.props
            .getCpcResourceFamilyDropdownByCode(value)
            .then((res: any) => {
              this.setState({
                dropdownKeysList: res.payload.data.result,
              });
            });
          break;

        case DropdownType.PBS_TOLERANCE_STATE:
        case DropdownType.PROJECT_TOLERANCE:
          this.props.getPbsToleranceDropdownByCode(value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;

        case DropdownType.PBS_PRODUCT_STATE:
          this.props.getPbsStatusDropdownByCode(value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;

        case DropdownType.PBS_ITEM_TYPES:
          this.props.getPbsItemTypeDropdownByCode(value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;
        case DropdownType.PBS_EXPERIENCE:
          this.props.getPbsExperienceDropdownByCode(value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;

        case DropdownType.PBS_SKILLS:
          this.props.getPbsSkillsDropdownByCode(value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;

        case DropdownType.PROJECT_MANAGMENT:
          this.props
            .getProjectManagementLevelsDropdownByCode(value)
            .then((res: any) => {
              this.setState({
                dropdownKeysList: res.payload.data.result,
              });
            });
          break;

        case DropdownType.PROJECT_CURRENCY:
          this.props
            .getProjectCurrenciesDropdownByCode(value)
            .then((res: any) => {
              this.setState({
                dropdownKeysList: res.payload.data.result,
              });
            });
          break;

        case DropdownType.PROJECT_STATE:
          this.props.getProjectStatesDropdownByCode(value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;

        case DropdownType.PROJECT_TYPE:
          this.props.getProjectTypesDropdownByCode(value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;

        case DropdownType.PROJECT_TEMPLATE:
          this.props
            .getProjectTemplatesDropdownByCode(value)
            .then((res: any) => {
              this.setState({
                dropdownKeysList: res.payload.data.result,
              });
            });
          break;

        case DropdownType.PROJECT_LEDGER:
          this.props.getProjectLedgerDropdownByCode(value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;

        case DropdownType.PMOL_STATUS:
          this.props.getPmolStatusDropdownByCode(value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;

        case DropdownType.PMOL_TYPE:
          this.props.getPmolTypeDropdownByCode(value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;

        // case DropdownType.BOR_STATUS:
        //   this.props.getBorStatusDropdownByCode(value).then((res: any) => {
        //     this.setState({
        //       dropdownKeysList: res.payload.data.result,
        //     });
        //   });
        case DropdownType.PROJECT_ROLE:
          this.props.getTeamRoalsDropDownByCode(value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;
        case DropdownType.STOCK_RESOURCE_TYPE:
          this.props.getDropDownTranslationByCode(STOCK_RESOURCETYPE_DROPDOWN_BY_CODE_EP, value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;
        case DropdownType.STOCK_TYPES:
          this.props.getDropDownTranslationByCode(STOCK_TYPE_DROPDOWN_READ_EP, value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;
        case DropdownType.STOCK_STATUS:
          this.props.getDropDownTranslationByCode(STOCK_STATUS_DROPDOWN_READ_EP, value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;
        case DropdownType.WF_STATUS:
          this.props.getDropDownTranslationByCode(WF_STATUS_DROPDOWN_BY_CODE_EP, value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;
        case DropdownType.WF_TYPES:
          this.props.getDropDownTranslationByCode(WF_TYPES_DROPDOWN_BY_CODE_EP, value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;
        case DropdownType.WH_TYPES:
          this.props.getDropDownTranslationByCode(WH_TYPE_DROPDOWN_BY_CODE_EP, value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;
        case DropdownType.WH_STATUS:
          this.props.getDropDownTranslationByCode(WH_STATUS_DROPDOWN_BY_CODE_EP, value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;
        case DropdownType.WH_TAXONOMY_LEVEL:
          this.props.getDropDownTranslationByCode(WH_TAXONOMY_LEVEL_DROPDOWN_BY_CODE_EP, value).then((res: any) => {
            this.setState({
              dropdownKeysList: res.payload.data.result,
            });
          });
          break;
        default:
          return [];
      }
    } else {
      this.setState({
        selectedCountry: null,
        selectedLanguage: null,
      });
    }
  };
  //this wil  tell modal to open close
  private openLanguageKeyModel = (): void => {
    if (this.state.langKeysIsEdit) {
      this.setState({
        isOpenLanguageKeyModel: !this.state.isOpenLanguageKeyModel,
        // cpcNickName: this._nickNameEditDetails(),
      });
    } else {
      this.setState({
        isOpenLanguageKeyModel: !this.state.isOpenLanguageKeyModel,
        langKeys: {
          id: null,
          key: null,
          value: null,
          languageId: null,
          language: {},
        },
      });
    }
  };

  onTranslationCreateSuccess = (res: any) => {
    if (res.payload.status === 200) {
      this.openLanguageKeyModel();
      this.onInputChange('', this.state.selectedCode);
      this.setState({ isCreating: false });
    }
  };

  private updateDropdownKeys = (translation: DropdownTranslation) => {
    this.setState({ isCreating: true });
    switch (this.state.selectedDropdown) {
      case DropdownType.CPC_MOU:
        this.props.updateCpcMouDropdownKeys(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.CPC_RESOURCE:
        this.props.updateCpcResourceType(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.CPC_MATERIAL:
        this.props.updateCpcMaterialDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.CPC_PRESSURE:
        this.props.updateCpcPressureDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.CPC_UNIT_SIZE_MEASURE:
        this.props
          .updateCpcUnitOfSizeMeasureDropdown(translation)
          .then((res: any) => {
            this.onTranslationCreateSuccess(res);
          });
        break;

      case DropdownType.CPC_BRAND:
        this.props.updateCpcBrandDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.CPC_RESOURCE_FAMILY:
        this.props
          .updateCpcResourceFamilyDropdown(translation)
          .then((res: any) => {
            this.onTranslationCreateSuccess(res);
          });
        break;

      case DropdownType.PBS_TOLERANCE_STATE:
      case DropdownType.PROJECT_TOLERANCE:
        this.props.updatePbsToleranceDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.PBS_PRODUCT_STATE:
        this.props.updatePbsStatusDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.PBS_ITEM_TYPES:
        this.props.updatePbsItemTypeDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.PBS_EXPERIENCE:
        this.props.updatePbsExperienceDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.PBS_SKILLS:
        this.props.updatePbsSkillsDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.PROJECT_MANAGMENT:
        this.props
          .updateProjectManagementLevelsDropdown(translation)
          .then((res: any) => {
            this.onTranslationCreateSuccess(res);
          });
        break;

      case DropdownType.PROJECT_CURRENCY:
        this.props
          .updateProjectCurrenciesDropdown(translation)
          .then((res: any) => {
            this.onTranslationCreateSuccess(res);
          });
        break;

      case DropdownType.PROJECT_STATE:
        this.props.updateProjectStatesDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.PROJECT_TYPE:
        this.props.updateProjectTypesDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.PROJECT_TEMPLATE:
        this.props
          .updateProjectTemplatesDropdown(translation)
          .then((res: any) => {
            this.onTranslationCreateSuccess(res);
          });
        break;

      case DropdownType.PROJECT_LEDGER:
        this.props.updateProjectLedgerDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.PMOL_STATUS:
        this.props.updatePmolStatusDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.PMOL_TYPE:
        this.props.updatePmolTypeDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      // case DropdownType.BOR_STATUS:
      //   this.props.updateBorStatusDropdown(translation).then((res: any) => {
      //     this.onTranslationCreateSuccess(res);
      //   });
      case DropdownType.PROJECT_ROLE:
        this.props.createDropdownTranslation(translation, PROJECT_TEAMROLE_CREATE_EP).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;
      case DropdownType.STOCK_TYPES:
        this.props.createDropdownTranslation(translation, STOCK_TYPES_DROPDOWN_CREATE_EP).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;
      case DropdownType.WF_STATUS:
        this.props.createDropdownTranslation(translation, WF_STATUS_DROPDOWN_CREATE_EP).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;
      case DropdownType.WF_TYPES:
        this.props.createDropdownTranslation(translation, WF_TYPES_DROPDOWN_CREATE_EP).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.WH_TYPES:
        this.props.createDropdownTranslation(translation, WH_TYPE_DROPDOWN_CREATE_EP).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.WH_STATUS:
        this.props.createDropdownTranslation(translation, WH_STATUS_DROPDOWN_CREATE_EP).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.WH_TAXONOMY_LEVEL:
        this.props.createDropdownTranslation(translation, WH_TAXONOMY_LEVEL_DROPDOWN_CREATE_EP).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      default:
        return [];
    }
  };

  private createDropdownKeys = (translation: CreateDropdownTranslation) => {
    this.setState({ isCreating: true });
    switch (this.state.selectedDropdown) {
      case DropdownType.CPC_MOU:
        this.props.createCpcMouDropdownKeys(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.CPC_RESOURCE:
        this.props.createCpcResourceType(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.CPC_MATERIAL:
        this.props.createCpcMaterialDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.CPC_PRESSURE:
        this.props.createCpcPressureDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.CPC_UNIT_SIZE_MEASURE:
        this.props
          .createCpcUnitOfSizeMeasureDropdown(translation)
          .then((res: any) => {
            this.onTranslationCreateSuccess(res);
          });
        break;

      case DropdownType.CPC_RESOURCE_FAMILY:
        this.props
          .createCpcResourceFamilyDropdown(translation)
          .then((res: any) => {
            this.onTranslationCreateSuccess(res);
          });
        break;

      case DropdownType.CPC_BRAND:
        this.props
          .createCpcBrandDropdown(translation)
          .then((res: any) => {
            this.onTranslationCreateSuccess(res);
          });
        break;

      case DropdownType.PBS_TOLERANCE_STATE:
      case DropdownType.PROJECT_TOLERANCE:
        this.props.createPbsToleranceDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.PBS_PRODUCT_STATE:
        this.props.createPbsStatusDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.PBS_ITEM_TYPES:
        this.props.createPbsItemTypeDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.PBS_EXPERIENCE:
        this.props.createPbsExperienceDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.PBS_SKILLS:
        this.props.createPbsSkillsDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.PROJECT_MANAGMENT:
        this.props
          .createProjectManagementLevelsDropdown(translation)
          .then((res: any) => {
            this.onTranslationCreateSuccess(res);
          });
        break;

      case DropdownType.PROJECT_CURRENCY:
        this.props
          .createProjectCurrenciesDropdown(translation)
          .then((res: any) => {
            this.onTranslationCreateSuccess(res);
          });
        break;

      case DropdownType.PROJECT_STATE:
        this.props.createProjectStatesDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.PROJECT_TYPE:
        this.props.createProjectTypesDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.PROJECT_TEMPLATE:
        this.props
          .createProjectTemplatesDropdown(translation)
          .then((res: any) => {
            this.onTranslationCreateSuccess(res);
          });
        break;

      case DropdownType.PROJECT_LEDGER:
        this.props.createProjectLedgerDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.PMOL_STATUS:
        this.props.createPmolStatusDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.PMOL_TYPE:
        this.props.createPmolTypeDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.BOR_STATUS:
        this.props.createBorStatusDropdown(translation).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;
      case DropdownType.PROJECT_ROLE:
        this.props.createDropdownTranslation(translation, PROJECT_TEAMROLE_CREATE_EP).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;
      case DropdownType.STOCK_RESOURCE_TYPE:
        this.props.createDropdownTranslation(translation, STOCK_RESOURCETYPE_DROPDOWN_CREATE_EP).then((res: any) => {
          this.onTranslationCreateSuccess(res);

        });
        break;

      case DropdownType.STOCK_TYPES:
        this.props.createDropdownTranslation(translation, STOCK_TYPES_DROPDOWN_CREATE_EP).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.WF_STATUS:
        this.props.createDropdownTranslation(translation, WF_STATUS_DROPDOWN_CREATE_EP).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.WF_TYPES:
        this.props.createDropdownTranslation(translation, WF_TYPES_DROPDOWN_CREATE_EP).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;
      case DropdownType.WH_TYPES:
        this.props.createDropdownTranslation(translation, WH_TYPE_DROPDOWN_CREATE_EP).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.WH_STATUS:
        this.props.createDropdownTranslation(translation, WH_STATUS_DROPDOWN_CREATE_EP).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      case DropdownType.WH_TAXONOMY_LEVEL:
        this.props.createDropdownTranslation(translation, WH_TAXONOMY_LEVEL_DROPDOWN_CREATE_EP).then((res: any) => {
          this.onTranslationCreateSuccess(res);
        });
        break;

      default:
        return [];
    }
  };

  onTranslationKeyDeleteSuccess = (res: any) => {
    if (res.payload.status === 200) {
      this.onInputChange('', this.state.selectedCode);
      this.props.setProgressVisibility(false);
    }
  };

  deleteTraslation = (translations: DropdownTranslation) => {
    this.props.setProgressVisibility(true);
    switch (this.state.selectedDropdown) {

      case DropdownType.CPC_MOU:
        this.props.deleteDropdownTranslation(translations, CPC_MOU_DROPDOWN_DELETE_EP).then((res: any) => {
          this.onTranslationKeyDeleteSuccess(res);
        });
        break;
      case DropdownType.CPC_MATERIAL:
        this.props.deleteDropdownTranslation(translations, CPC_MATERIAL_DELETE_EP).then((res: any) => {
          this.onTranslationKeyDeleteSuccess(res);
        });
        break;
      case DropdownType.CPC_BRAND:
        this.props.deleteDropdownTranslation(translations, CPC_BRAND_DROPDOWN_DELETE_EP).then((res: any) => {
          this.onTranslationKeyDeleteSuccess(res);
        });
        break;
      case DropdownType.PROJECT_ROLE:
        this.props.deleteDropdownTranslation(translations, PROJECT_TEAMROLE_DELETE_EP).then((res: any) => {
          this.onTranslationKeyDeleteSuccess(res);
        });
        break;
      case DropdownType.STOCK_RESOURCE_TYPE:
        this.props.deleteDropdownTranslation(translations, STOCK_RESOURCETYPE_DROPDOWN_DELETE_EP).then((res: any) => {
          if (res.payload.status === 200) {
            this.onTranslationKeyDeleteSuccess(res);
          }
        });
        break;
      case DropdownType.STOCK_TYPES:
        this.props.deleteDropdownTranslation(translations, STOCK_TYPES_DROPDOWN_DELETE_EP).then((res: any) => {
          if (res.payload.status === 200) {
            this.onTranslationKeyDeleteSuccess(res);
          }
        });
        break;
      case DropdownType.WF_STATUS:
        this.props.deleteDropdownTranslation(translations, WF_STATUS_DROPDOWN_DELETE_EP).then((res: any) => {
          if (res.payload.status === 200) {
            this.onTranslationKeyDeleteSuccess(res);
          }
        });
        break;
      case DropdownType.WF_TYPES:
        this.props.deleteDropdownTranslation(translations, WF_TYPES_DROPDOWN_DELETE_EP).then((res: any) => {
          if (res.payload.status === 200) {
            this.onTranslationKeyDeleteSuccess(res);
          }
        });
        break;

      case DropdownType.WH_TYPES:
        this.props.deleteDropdownTranslation(translations, WH_TYPE_DROPDOWN_DELETE_EP).then((res: any) => {
          if (res.payload.status === 200) {
            this.onTranslationKeyDeleteSuccess(res);
          }
        });
        break;
      case DropdownType.WH_STATUS:
        this.props.deleteDropdownTranslation(translations, WH_STATUS_DROPDOWN_DELETE_EP).then((res: any) => {
          if (res.payload.status === 200) {
            this.onTranslationKeyDeleteSuccess(res);
          }
        });
        break;
      case DropdownType.WH_TAXONOMY_LEVEL:
        this.props.deleteDropdownTranslation(translations, WH_TAXONOMY_LEVEL_DROPDOWN_DELETE_EP).then((res: any) => {
          if (res.payload.status === 200) {
            this.onTranslationKeyDeleteSuccess(res);
          }
        });
        break;

      default:
    }
  };

  private handleFormChange = (formData: UprinceLanguage, save: boolean) => {
    this.setState({
      isDirty: true,
    });
    this.setState({
      formData: formData,
    });
  };

  private saveFormData = () => {
    if (this.state.lang.id) {
      this.props.updateLanguage(this.state.lang);
      this.setState({
        isDirty: false,
      });
    } else {
      let lang = this.state.lang;
      lang.id = 0;
      this.props.addLanguage(lang);
    }
    messageService.sendMessage({
      newListItem: true,
    });
  };

}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    psDropDowns: state.ps.psDropDowns,
    isEdit: state.ps.isEdit,
    isNew: state.ps.isNew,
    uid: state.ps.uid,
    projectHeader: state.project.projectHeader,
    showProgressBar: state.ps.showProgressBar,
    keyData: state.lang.keyData,
    countries: state.addressBook.countries,
    formData: state.lang.formData,
    cpcDropdownList: state.productCatalogue.cpcDropdownList,
    projectDropdownList: state.project.projectDropdownList,
    cpcBasicUnitOfMeasure: state.productCatalogue.cpcBasicUnitOfMeasure,
    resourceTypes: state.productCatalogue.resourceTypes,
    cpcMaterial: state.productCatalogue.cpcMaterial,
    cpcPressureClass: state.productCatalogue.cpcPressureClass,
    cpcUnitOfSizeMeasure: state.productCatalogue.cpcUnitOfSizeMeasure,
    cpcBrand: state.productCatalogue.cpcBrand,
    projectManagementLevel: state.project.projectManagementLevel,
    projectToleranceState: state.project.projectToleranceState,
    projectCurrency: state.project.projectCurrency,
    projectType: state.project.projectType,
    projectTemplate: state.project.projectTemplate,
    ledgerDropDowns: state.project.ledgerDropDowns,
    projectState: state.project.projectState,
    pbsDropdownList: state.projectBreakdown.pbsDropdownList,
    toleranceStates: state.projectBreakdown.toleranceStates,
    productStates: state.projectBreakdown.productStates,
    itemTypes: state.projectBreakdown.itemTypes,
    pmolDropdownList: state.pmol.pmolDropdownList,
    pmolType: state.pmol.pmolType,
    pmolStatus: state.pmol.pmolStatus,
    pmolDropDowns: state.pmol.pmolDropDowns,
    borDropdownList: state.billOfResource.borDropdownList,
    borStatus: state.billOfResource.borStatus,
    getCountryDropdown: state.dropdownTranslation?.getCountryDropdown,
    getLanguageList: state.dropdownTranslation.getLanguageList,
    getDropdownByCode: state.dropdownTranslation.getDropdownByCode,
    stockDropDownsList: state.stock.stockDropDownsList,
    wfDropDownList: state.wf.wfDropDownList,
    whDropDownList: state.warehouse.whDropDownList,
  };
};

const mapDispatchToProps = {
  saveFormData,
  readByPSId,
  clearPsData,
  getProjectHeaderDetails,
  createPSResource,
  getKeyWord,
  createTranslation,
  getCountryList,
  updateLanguage,
  addLanguage,
  clearLanguageData,
  getDropdownData,
  getAllProjectDropdowns,
  readDropdownData,
  readPMOLDropDownData,
  borDropdownData,
  getCountryDistinct,
  getLanguageDistinct,
  getLanguageByCode,
  getDropdownKeyByCode,
  getDropdownLanguageCode,
  updateCpcMouDropdownKeys,
  createCpcMouDropdownKeys,
  getCpcResourceTypesByCode,
  updateCpcResourceType,
  createCpcResourceType,
  getCpcMaterialDropdownByCode,
  updateCpcMaterialDropdown,
  createCpcMaterialDropdown,
  getCpcPressureDropdownByCode,
  updateCpcPressureDropdown,
  createCpcPressureDropdown,
  getCpcBrandDropdownByCode,
  updateCpcBrandDropdown,
  createCpcBrandDropdown,
  getCpcResourceFamilyDropdownByCode,
  updateCpcResourceFamilyDropdown,
  createCpcResourceFamilyDropdown,
  getCpcUnitOfSizeMeasureDropdownByCode,
  updateCpcUnitOfSizeMeasureDropdown,
  createCpcUnitOfSizeMeasureDropdown,
  getPbsToleranceDropdownByCode,
  updatePbsToleranceDropdown,
  createPbsToleranceDropdown,
  getPbsStatusDropdownByCode,
  updatePbsStatusDropdown,
  createPbsStatusDropdown,
  getPbsItemTypeDropdownByCode,
  updatePbsItemTypeDropdown,
  createPbsItemTypeDropdown,
  getPbsExperienceDropdownByCode,
  updatePbsExperienceDropdown,
  createPbsExperienceDropdown,
  getPbsSkillsDropdownByCode,
  updatePbsSkillsDropdown,
  createPbsSkillsDropdown,
  getProjectManagementLevelsDropdownByCode,
  updateProjectManagementLevelsDropdown,
  createProjectManagementLevelsDropdown,
  getProjectCurrenciesDropdownByCode,
  updateProjectCurrenciesDropdown,
  createProjectCurrenciesDropdown,
  getProjectStatesDropdownByCode,
  updateProjectStatesDropdown,
  createProjectStatesDropdown,
  getProjectTypesDropdownByCode,
  updateProjectTypesDropdown,
  createProjectTypesDropdown,
  getProjectTemplatesDropdownByCode,
  updateProjectTemplatesDropdown,
  createProjectTemplatesDropdown,
  getProjectLedgerDropdownByCode,
  updateProjectLedgerDropdown,
  createProjectLedgerDropdown,
  getPmolStatusDropdownByCode,
  updatePmolStatusDropdown,
  createPmolStatusDropdown,
  getPmolTypeDropdownByCode,
  updatePmolTypeDropdown,
  createPmolTypeDropdown,
  getBorStatusDropdownByCode,
  updateBorStatusDropdown,
  createBorStatusDropdown,
  getTeamRoalsDropDownByCode,
  createDropdownTranslation,
  readStockDropDownData,
  readWorkFlowDropDownData,
  readWarehouseDropDownData,
  getDropDownTranslationByCode,
  deleteDropdownTranslation,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DocumentPane)),
);

import React, {Component} from 'react';
import UprinceNavigation from '../../shared/uprinceNavigation/cuProjectNavigation/component';
import {getContractingUnit, getProject} from '../../shared/util';
import MeNavBar from '../../shared/uprinceNavigation/meNavigation/component';
import MyDayPlanningMainLayout from './myDayPlanningMainLayout';
import {v4 as uuidv4} from 'uuid';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';


interface Props {
    currentUser: any
}

interface State {
    isContractUnit: boolean;
    isProject: boolean;
}

const dndId = uuidv4();

export class MyDayPlanningNew extends Component<Props, State> {
    render() {
        return <DndProvider key={dndId} backend={HTML5Backend}>
            {getProject() ?
                <UprinceNavigation
                    currentUser={this.props.currentUser}
                    selectedNavigationIndex={`/CU/${getContractingUnit()}/project/${getProject()}/day-planning-new`}
                >
                    <MyDayPlanningMainLayout/>
                </UprinceNavigation>
                : <MeNavBar
                    currentUser={this.props.currentUser}
                    selectedNavigationIndex={'/me/day-planning-new'}
                >
                    <MyDayPlanningMainLayout/>
                </MeNavBar>
            }
        </DndProvider>
    }
}

export default (MyDayPlanningNew);

import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  ContextualMenu,
  Dropdown,
  FontWeights,
  getTheme,
  IBasePickerSuggestionsProps,
  Icon,
  IconButton,
  IDragOptions,
  IIconProps,
  IPersona,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  ITextField,
  Label,
  mergeStyleSets,
  Modal,
  NormalPeoplePicker,
  PrimaryButton,
  Stack,
  TextField,
  ValidationState,
} from '@fluentui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import _ from 'lodash';
import { uPrinceTheme } from '../../../../../theme';
import { RiskDropDown } from '../../../../types/projectBreakdownStructure';
import { useSelector } from 'react-redux';
import client from '../../../../api';

const theme = getTheme();


const classNames = mergeStyleSets({
  wrapper: {
    //height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: {
    maxWidth: 400,
  },
  textContent: {
    padding: '15px 10px',
  },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: {
    width: '100%',
  },
  editor: {
    width: '100%',
    height: '100%',
  },
});

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '40vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

class MemberValidationMessages {
  nameValidationMsg: string = '';
  roleCalidationMsg: string = '';
}

export class TeamMember {
  id: string | null = null;
  projectTeamId: string | null = null;
  cabPersonId: string | null = null;
  roleId: string | null = null;
  status: string | null = '1';
  cabPersonName: string | null = null;
  roleName: string | null = null;
  isAccessGranted: boolean = false;
  message: string | null = null;
  email: string | null = null;
}

const TeamModal = (props: {
  isOpen: boolean;
  openModal: () => void;
  isEdit: boolean;
  onFilterPersonChanged: any;
  createTeam: (risk: TeamMember) => void;
  teamMember: TeamMember;
  userRoles: any[];
}) => {
  const { t } = useTranslation();
  const [isDraggable] = useBoolean(false);
  const titleId = useId('title');
  const dropdowns: RiskDropDown = useSelector(
    (state: any) => state.projectBreakdown.dropdowns,
  );
  const [member, setMember] = useState(new TeamMember());
  const [person, setPerson] = useState<any[]>([]);
  const [] = useState(false);
  const [memberValidationMessages, setRiskValidationMsg] = useState<MemberValidationMessages>(new MemberValidationMessages());
  const [, setDisableSave] = useState(true);
  const messageRef = useRef<ITextField>(null);

  const onChangeInput = (field: string) => (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setMember((prevState) => ({
      ...prevState,
      [field]: newValue,
    }));
  };

  const limitedSearchAdditionalProps: IBasePickerSuggestionsProps = {
    resultsMaximumNumber: 10,
    searchingText: 'Searching...',
  };

  let selectedPerson: IPersona[] = [];

  useEffect(() => {
    if (props.isEdit) {
      if (props.teamMember && props.teamMember.cabPersonId) {
        client
          .get(
            `CentralAddressBook/ReadBusinessEmail/${props.teamMember.cabPersonId}`,
          )
          .then((response) => {
            setMember((prevState) => ({
              ...prevState,
              email: response.data.result,
            }));
          });
        setPerson([
          {
            key:
              props.teamMember && props.teamMember.cabPersonId
                ? props.teamMember.cabPersonId
                : '',
            text:
              props.teamMember && props.teamMember.cabPersonName
                ? props.teamMember.cabPersonName
                : '',
          },
        ]);
      }
      selectedPerson.push(person);
      setMember({
        id: props.teamMember.id,
        projectTeamId: props.teamMember.projectTeamId,
        cabPersonId: props.teamMember.cabPersonId,
        roleId: props.teamMember.roleId,
        status: props.teamMember.status,
        cabPersonName: props.teamMember.cabPersonName,
        roleName: props.teamMember.roleName,
        isAccessGranted: props.teamMember.hasOwnProperty('isAccessGranted')
          ? props.teamMember.isAccessGranted
          : props.teamMember.status === '2'
            ? true
            : false,
        message: props.teamMember.message,
        email: props.teamMember.email ? props.teamMember.email : member.email,
      });
      setDisableSave(false);
    } else {
      setPerson([]);
      setMember(new TeamMember());
      setDisableSave(true);
    }
  }, [props.teamMember]);

  const validateMember = (member: TeamMember) => {
    let isValidName = validateMemberId(member);
    let isValidRole = validateMemberRole(member);
    return isValidName && isValidRole;
  };

  const _onChange = (ev: any, isChecked: boolean) => {
    setMember((prevState) => ({
      ...prevState,
      isAccessGranted: isChecked,
      status: isChecked ? '3' : '1',
    }));
  };

  const validateMemberId = (member: TeamMember) => {
    let isValidName = false;
    if (member && member.cabPersonId) {
      isValidName = true;
      setMemberValidationState('nameValidationMsg', '');
    } else {
      isValidName = false;
      setMemberValidationState('nameValidationMsg', t('employeeRequired'));
    }
    return isValidName;
  };

  const validateMemberRole = (member: TeamMember) => {
    let isValidName = false;
    if (member && member.roleId) {
      isValidName = true;
      setMemberValidationState('roleCalidationMsg', '');
    } else {
      isValidName = false;
      setMemberValidationState('roleCalidationMsg', t('roleRequired'));
    }
    return isValidName;
  };

  const setMemberValidationState = (key: string, msg: string) => {
    setRiskValidationMsg((prevState) => ({
      ...prevState,
      [key]: msg,
    }));
  };


  const validateInput = (input: string): ValidationState => {
    if (input.indexOf('@') !== -1) {
      return ValidationState.valid;
    } else if (input.length > 1) {
      return ValidationState.warning;
    } else {
      return ValidationState.invalid;
    }
  };

  const onPersonSelected = (item: any) => {
    if (item && item.person) {
      selectedPerson = [
        {
          key: item.person.id,
          text: item.person.fullName,
          email: item.personCompany.email,
        },
      ];
      setPerson(selectedPerson);
      setMember((prevState) => ({
        ...prevState,
        cabPersonId: item.person.id,
        cabPersonName: item.person.fullName,
        email: item.personCompany.email,
      }));
    }
    return item;
  };

  const onInputChange = (input: string): string => {
    const outlookRegEx = /<.*>/g;
    const emailAddress = outlookRegEx.exec(input);

    if (emailAddress && emailAddress[0]) {
      return emailAddress[0].substring(1, emailAddress[0].length - 1);
    }

    return input;
  };

  const onRenderCaretDown = (): JSX.Element => {
    return (
      <Icon
        iconName="ChevronDown"
        onClick={() => {
          //alert('test');
        }}
      />
    );
  };
  //let email = person && person[0] && person[0].email ? person[0].email : '';
  //member.email= email;
  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          setRiskValidationMsg(new MemberValidationMessages());
          props.openModal();
        }}
        scrollableContentClassName="body"
        isBlocking={false}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>
            {props.isEdit ? t('editTeamMember') : t('addTeamMemmber')}
          </span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              setRiskValidationMsg(new MemberValidationMessages());
              props.openModal();
            }}
          />
        </div>

        <div className={contentStyles.body}>
          <div className="proj-detail-content inner">
            <div className="ms-Grid-row">
              {/* {props.isEdit && (
                <Stack
                  horizontal
                  wrap
                  styles={stackStyles}
                  tokens={stackTokens}
                >
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        label={t('riskID')}
                        readOnly={true}
                        value={
                          member && member.status ? member.status : ''
                        }
                      />
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        label={t('riskTitle')}
                        value={member && member.status ? member.status : ''}
                        errorMessage={''}
                        //onChange={onChangeInput('vat')}
                      />
                    </div>
                  </Stack.Item>
                </Stack>
              )} */}
              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <Label required={false}>{t('employee')}</Label>
                    <NormalPeoplePicker
                      ref={(input: any) => input && input.focus()}
                      onChange={(items) => {
                        if (items && _.isArray(items) && items.length === 0) {
                          setPerson([]);
                          setMember((prevState) => ({
                            ...prevState,
                            cabPersonId: null,
                          }));
                        }
                      }}
                      selectedItems={
                        person && person[0] && person[0] ? person : []
                      }
                      onResolveSuggestions={props.onFilterPersonChanged}
                      onItemSelected={onPersonSelected}
                      pickerSuggestionsProps={limitedSearchAdditionalProps}
                      className={'ms-PeoplePicker'}
                      key={'normal'}
                      itemLimit={1}
                      onValidateInput={validateInput}
                      removeButtonAriaLabel={'Remove'}
                      inputProps={{
                        onBlur: () => {
                        },
                        onFocus: () => {
                        },
                        'aria-label': 'People Picker',
                      }}
                      onInputChange={onInputChange}
                      resolveDelay={300}
                      disabled={false}
                    />
                  </div>
                </Stack.Item>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <Dropdown
                      placeholder={t('selectRole')}
                      label={t('role')}
                      options={props.userRoles}
                      selectedKey={member && member.roleId ? member.roleId : -1}
                      errorMessage={memberValidationMessages.roleCalidationMsg}
                      onRenderCaretDown={onRenderCaretDown}
                      onChange={(
                        event: React.FormEvent<HTMLDivElement>,
                        item: any,
                      ) => {
                        setMember((prevState) => ({
                          ...prevState,
                          roleId: item.key,
                          roleName: item.text,
                          // riskType: {
                          //   key: item.key,
                          //   text: item.text,
                          // },
                        }));
                        memberValidationMessages.roleCalidationMsg = '';
                      }}
                    />
                  </div>
                </Stack.Item>
              </Stack>
              <Stack wrap styles={stackStyles} tokens={stackTokens}>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                    <Checkbox
                      label={t('projectAccess')}
                      checked={member.isAccessGranted}
                      onChange={(ev: any, checked: boolean | undefined) =>
                        _onChange(ev!!, checked!!)
                      }
                    />
                  </div>
                </div>
                {person[0] && member.isAccessGranted && (
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                      <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                          <TextField value={t('email')} borderless={true}/>
                        </div>
                        <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8">
                          <TextField
                            value={member.email!!}
                            onChange={() => onChangeInput('email')}
                          />
                        </div>
                      </div>
                      <TextField
                        label={t('message')}
                        multiline
                        rows={3}
                        //value={member.message?member.message:''}
                        value={member.message ? member.message!! : messageRef.current?.value}
                        componentRef={messageRef}
                      />
                    </div>
                  </div>
                )}
              </Stack>
            </div>
          </div>
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={addIconWhite}
            text={t('save')}
            //disabled={disableSave}
            style={{ marginTop: 20, marginBottom: 30 }}
            onClick={() => {
              if (validateMember(member)) {
                member.message = messageRef.current?.value!!;
                props.createTeam(member);
                props.openModal();
              }
            }}
          />

          {/* {!props.isEdit && (
            <PrimaryButton
              iconProps={addIconWhite}
              text={t('saveandNew')}
              style={{ marginTop: 20, marginBottom: 10, marginLeft: 10 }}
              onClick={() => {
                if (validateMember(member)) {
                  //props.createRisk(member);
                  //setMember(new ReadRisk())
                  //setPerson([]);
                  //setDisableSave(true)
                }
              }}
              //disabled={disableSave}
            />
          )} */}
        </div>
      </Modal>
    </div>
  );
};

export default TeamModal;

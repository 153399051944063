import React, { Component } from 'react';
import UprinceNavigation from '../../shared/uprinceNavigation/cuProjectNavigation/component';
import DayPlanningMainLayout from './dayPlanningMainLayout';
import { getContractingUnit, getProject } from '../../shared/util';


interface Props {
  currentUser: any
}

interface State {
  isContractUnit: boolean;
  isProject: boolean;
}

export class DayPlanning extends Component<Props, State> {
  render() {
    return (
      <UprinceNavigation
        currentUser={this.props.currentUser}
        selectedNavigationIndex={getProject() ? `/CU/${getContractingUnit()}/project/${getProject()}/day-planning` : `/CU/${getContractingUnit()}/day-planning`}
      >
        <DayPlanningMainLayout/>
      </UprinceNavigation>
    );
  }
}

export default (DayPlanning);

import {
  CREATE_CAB,
  CREATE_CAB_CERTIFICATION,
  CREATE_CAB_CERTIFICATION_FAIL,
  CREATE_CAB_CERTIFICATION_SUCCESS,
  CREATE_CAB_COMPETENCIES,
  CREATE_CAB_COMPETENCIES_FAIL,
  CREATE_CAB_COMPETENCIES_SUCCESS,
  CREATE_CAB_FAIL,
  CREATE_CAB_SUCCESS,
  CREATE_COMPANY,
  CREATE_COMPANY_FAIL,
  CREATE_COMPANY_SUCCESS,
  DELETE_CAB,
  DELETE_CAB_CERTIFICATION,
  DELETE_CAB_CERTIFICATION_FAIL,
  DELETE_CAB_CERTIFICATION_SUCCESS,
  DELETE_CAB_COMPETENCIES,
  DELETE_CAB_COMPETENCIES_FAIL,
  DELETE_CAB_COMPETENCIES_SUCCESS,
  DELETE_CAB_FAIL,
  DELETE_CAB_SUCCESS,
  GET_CAB_BY_ID,
  GET_CAB_BY_ID_FAIL,
  GET_CAB_BY_ID_SUCCESS,
  GET_CONTRACTORS_DATA,
  GET_CONTRACTORS_DATA_FAIL,
  GET_CONTRACTORS_DATA_SUCCESS,
  LOAD_CAB,
  LOAD_CAB_FAIL,
  LOAD_CAB_SUCCESS,
  LOAD_COUNTRIES,
  LOAD_COUNTRIES_FAIL,
  LOAD_COUNTRIES_SUCCESS,
  LOAD_EXPORT_CABS,
  LOAD_EXPORT_CABS_FAIL,
  LOAD_EXPORT_CABS_SUCCESS,
  LOAD_ISEXIST_CONTACT,
  LOAD_ISEXIST_CONTACT_FAIL,
  LOAD_ISEXIST_CONTACT_SUCCESS,
  LOAD_COUNTRY,
  LOAD_COUNTRY_FAIL,
  LOAD_COUNTRY_SUCCESS,
  LOAD_NOCAB_COMPANIES,
  LOAD_NOCAB_COMPANIES_FAIL,
  LOAD_NOCAB_COMPANIES_SUCCESS,
  LOAD_UNIQ_CONTACT,
  LOAD_UNIQ_CONTACT_FAIL,
  LOAD_UNIQ_CONTACT_SUCCESS,
  READ_CAB_CERTIFICATION,
  READ_CAB_CERTIFICATION_FAIL,
  READ_CAB_CERTIFICATION_SUCCESS,
  READ_CAB_COMPETENCIES,
  READ_CAB_COMPETENCIES_FAIL,
  READ_CAB_COMPETENCIES_SUCCESS,
  UPLOAD_CAB_IMAGE,
  UPLOAD_CAB_IMAGE_FAIL,
  UPLOAD_CAB_IMAGE_SUCCESS,
} from '../actionTypes/addressBookActionTypes';
import UprinceLogger from '../shared/Logger/logger';
import _ from 'lodash';
import {
  CAB_DELETE_EP,
  CAB_EXPORT_EP,
  CAB_FILTER_EP,
  CAB_IMAGE_UPLOAD_EP,
  CAB_UNIQ_CONTACT_EP,
  COUNTRIES,
  CREATE_CAB_CERTIFICATION_EP,
  CREATE_CAB_COMPETENCIES_EP,
  CREATE_CAB_EP,
  CREATE_COMPANY_EP,
  DELETE_CAB_CERTIFICATION_EP,
  DELETE_CAB_COMPETENCIES_EP,
  GET_CONTRACTORS_DATA_EP,
  getNoCabCompaniesURL,
  IS_CAB_EXIST_EP,
  IS_COUNTRY_EP,
  READ_CAB_CERTIFICATION_EP,
  READ_CAB_COMPETENCIES_EP,
} from '../shared/endpoints';
import {AddressBookItem, CABFilter, Company, Person, PersonCompany, UniqContact} from '../types/addressBookItem';
import {SAVE_CAB_STATE_ATTRIBUTE} from '../actionTypes/certificationActionTypes';

const defaultState: any = {
  countries: [],
  isLoading: false,
  isCABLoading: false,
  isCabItemLoading: false,
  status: true,
  createCabStatus: false,
  message: null,
  company: null,
  addressBook: [],
  uploadImageStatus: false,
  profileImageUrl: null,
  personId: null,
  personCompanyId: null,
  deleteCABSuccess: false,
  addressBookFromId: null,
  loadCABMessage: '',
  loadCABStatus: true,
  isUniqContact: 0,
  isCheckingUniqContact: false,
  createCompanyStatus: false,
  isCabExist: false,
  isCountry: [],
  noCabCompanies: [],
  noCabCompanyStatus: false,
  exportCabs: [],
  exportCabStatus: false,
  competence: [],
  certificate: [],
  contractors: [], //*BmeRequirement*/
};

type Country = {
  id: string;
  countryName: string;
  localeCode: string;
};

type Countrylist = {
  countryId: string;
  countryName: string;
};

const addressBookReducer = (state = defaultState, action: any) => {
  if (action) {
    switch (action.type) {
      case LOAD_COUNTRIES:
        UprinceLogger.log('LOAD_COUNTRIES', action);
        return { ...state, countries: [], isLoading: true };
      case LOAD_COUNTRIES_SUCCESS:
        UprinceLogger.log('LOAD_COUNTRIES_SUCCESS', action);
        return {
          ...state,
          isLoading: false,
          status: action.payload.data.status,
          message:
            action.payload && action.payload.data
              ? action.payload.data.message
              : null,
          countries:
            action.payload && action.payload.data
              ? formatCountryList(action.payload.data.result)
              : [],
        };
      case LOAD_COUNTRIES_FAIL:
        UprinceLogger.log(
          'LOAD_COUNTRIES_FAIL',
          action.error.response.data.message,
        );
        return {
          ...state,
          isLoading: false,
          message: null,
          error: action.error.response.data.message,
        };

      case CREATE_COMPANY:
        return {
          ...state,
          createCompanyStatus: false,
        };
      case CREATE_CAB:
        return {
          ...state,
          isLoading: true,
          company: null,
          createCabStatus: false,
          personId: null,
          personCompanyId: null,
        };

      case CREATE_COMPANY_SUCCESS:
        UprinceLogger.log('CREATE_COMPANY_SUCCESS', action.payload.data.result);
        return {
          ...state,
          isLoading: false,
          createCompanyStatus: true,
          message: action.payload.data.message,
          companyId: action.payload.data.result,
        };
      case CREATE_COMPANY_FAIL:
        UprinceLogger.log('CREATE_COMPANY_FAIL', action.error.response);
        return {
          ...state,
          isLoading: false,
          createCompanyStatus: false,
          message: action.payload.data.message,
        };

      case CREATE_CAB_SUCCESS:
        UprinceLogger.log('CREATE_CAB_SUCCESS', action.payload.data.result);
        return {
          ...state,
          isLoading: false,
          createCabStatus: action.payload.data.status,
          message: action.payload.data.message,
          personId:
            action.payload.data && action.payload.data.result
              ? action.payload.data.result.personId
              : null,
          personCompanyId: action.payload.data.result
            ? action.payload.data.result.personCompanyId
            : null,
        };

      case CREATE_CAB_FAIL:
        UprinceLogger.log('CREATE_CAB_FAIL', action);
        return {
          ...state,
          isLoading: false,
          createCabStatus: action.payload.data.status,
          message: action.payload.data.message,
        };

      case LOAD_CAB:
        UprinceLogger.log('LOAD_CAB', action);
        return { ...state, addressBook: [], isCABLoading: true, loadCABMessage: null };

      case LOAD_CAB_SUCCESS:
        UprinceLogger.log('LOAD_CAB_SUCCESS', action);
        return {
          ...state,
          isCABLoading: false,
          loadCABStatus: action.payload.data.status,
          message: action.payload.data.message,
          addressBook: action.payload.data.result,
          loadCABMessage: null,
        };

      case LOAD_CAB_FAIL:
        UprinceLogger.log('LOAD_CAB_FAIL', action.error.response.data.message);
        return {
          ...state,
          isCABLoading: false,
          message: null,
          loadCABStatus: action.payload.data.status,
          createCabStatus: false,
          loadCABMessage: action.error.response.data.message,
        };

      case UPLOAD_CAB_IMAGE:
        UprinceLogger.log('UPLOAD_CAB_IMAGE', action);
        return {
          ...state,
          isLoading: true,
          uploadImageStatus: false,
          profileImageUrl: '',
        };

      case UPLOAD_CAB_IMAGE_SUCCESS:
        UprinceLogger.log('UPLOAD_CAB_IMAGE_SUCCESS', action);
        return {
          ...state,
          isLoading: false,
          uploadImageStatus: action.payload.data.status,
          message: action.payload.data.message,
          profileImageUrl: action.payload.data.result,
        };

      case UPLOAD_CAB_IMAGE_FAIL:
        UprinceLogger.log(
          'UPLOAD_CAB_IMAGE_FAIL',
          action.error.response.data.message,
        );
        return {
          ...state,
          isLoading: false,
          uploadImageStatus: action.payload.data.status,
          message: null,
          createCabStatus: false,
          error: action.error.response.data.message,
          profileImageUrl: '',
        };
      case DELETE_CAB:
        UprinceLogger.log('DELETE_CAB');
        return { ...state, deleteCABSuccess: false };
      case DELETE_CAB_SUCCESS:
        UprinceLogger.log('DELETE_CAB_SUCCESS');
        return { ...state, deleteCABSuccess: true };
      case DELETE_CAB_FAIL:
        UprinceLogger.log('DELETE_CAB_FAIL');
        return { ...state, deleteCABSuccess: false };
      case GET_CAB_BY_ID:
        UprinceLogger.log('GET_CAB_BY_ID');

        return {
          ...state,
          isCabItemLoading: true,
          addressBookFromId: new AddressBookItem(
            new Person(),
            null,
            new PersonCompany(),
          ),
        };
      case GET_CAB_BY_ID_SUCCESS:
        UprinceLogger.log('GET_CAB_BY_ID_SUCCESS');
        // console.log('GET_CAB_BY_ID_SUCCESS');
        return {
          ...state,
          isCabItemLoading: false,
          addressBookFromId: action.payload.data.result,
        };
      case GET_CAB_BY_ID_FAIL:
        UprinceLogger.log('GET_CAB_BY_ID_FAIL');
        return {
          ...state,
          isCabItemLoading: false,
          addressBookFromId: null,
        };
      case LOAD_UNIQ_CONTACT:
        UprinceLogger.log('LOAD_UNIQ_CONTACT');

        return {
          ...state,
          isCheckingUniqContact: true,
          isUniqContact: 0,
        };
      case LOAD_UNIQ_CONTACT_SUCCESS:
        // console.log('LOAD_UNIQ_CONTACT_SUCCESS', action);
        return {
          ...state,
          isCheckingUniqContact: false,
          isUniqContact: action.payload.data.result ? 1 : 2,
        };
      case LOAD_UNIQ_CONTACT_FAIL:
        UprinceLogger.log('LOAD_UNIQ_CONTACT_FAIL');
        return {
          ...state,
          isCheckingUniqContact: false,
          isUniqContact: 0,
        };
      case LOAD_ISEXIST_CONTACT:
        UprinceLogger.log('LOAD_ISEXIST_CONTACT');

        return {
          ...state,
          isCabExist: false,
        };
      case LOAD_ISEXIST_CONTACT_SUCCESS:
        // console.log('LOAD_ISEXIST_CONTACT_SUCCESS', action);
        return {
          ...state,
          isCabExist: action.payload.data.result,
        };
      case LOAD_ISEXIST_CONTACT_FAIL:
        UprinceLogger.log('LOAD_ISEXIST_CONTACT_FAIL');
        return {
          ...state,
          isCabExist: false,
        };
        case LOAD_COUNTRY:
          UprinceLogger.log('LOAD_ISEXIST_CONTACT');
  
          return {
            ...state,
            isCountry: [],
          };
        case LOAD_COUNTRY_SUCCESS:
          // console.log('LOAD_COUNTRY_SUCCESS', action);
          return {
            ...state,
            isLoading: false,
            status: action.payload.data.status,
            message:
              action.payload && action.payload.data
                ? action.payload.data.message
                : null,
                isCountry:
              action.payload && action.payload.data
                ? action.payload.data
                : [],
          };
        case LOAD_COUNTRY_FAIL:
          UprinceLogger.log('LOAD_COUNTRY_FAIL');
          return {
            ...state,
            isCountry: [],
          };
      case LOAD_NOCAB_COMPANIES:
        UprinceLogger.log('LOAD_NOCAB_COMPANIES');

        return {
          ...state,
          noCabCompanyStatus: false,
          noCabCompanies: [],
        };
      case LOAD_NOCAB_COMPANIES_SUCCESS:
        // console.log('LOAD_NOCAB_COMPANIES_SUCCESS', action)
        return {
          ...state,
          noCabCompanyStatus: true,
          noCabCompanies: action.payload.data.result,
        };
      case LOAD_NOCAB_COMPANIES_FAIL:
        UprinceLogger.log('LOAD_NOCAB_COMPANIES_FAIL');
        return {
          ...state,
          noCabCompanyStatus: false,
          noCabCompanies: [],
        };

      case LOAD_EXPORT_CABS:
        UprinceLogger.log('LOAD_EXPORT_CABS');

        return {
          ...state,
          exportCabStatus: false,
          exportCabs: [],
        };
      case LOAD_EXPORT_CABS_SUCCESS:
        // console.log('LOAD_EXPORT_CABS_SUCCESS', action);
        return {
          ...state,
          exportCabStatus: true,
          exportCabs: action.payload.data.result,
        };
      case LOAD_EXPORT_CABS_FAIL:
        UprinceLogger.log('LOAD_EXPORT_CABS_FAIL');
        return {
          ...state,
          exportCabStatus: false,
          exportCabs: [],
        };


      case READ_CAB_COMPETENCIES:
        return {
          ...state,
          competence: [],
        };
      case READ_CAB_COMPETENCIES_SUCCESS:
        return {
          ...state,
          competence: action.payload.data.result,
        };
      case READ_CAB_COMPETENCIES_FAIL:
        return {
          ...state,
          competence: [],
        };

      case CREATE_CAB_COMPETENCIES:
        return {
          ...state,
        };
      case CREATE_CAB_COMPETENCIES_SUCCESS:
        return {
          ...state,
          competence: action.payload.data.result,
        };
      case CREATE_CAB_COMPETENCIES_FAIL:
        return {
          ...state,
        };

      case READ_CAB_CERTIFICATION:
        return {
          ...state,
          certificate: [],
        };
      case READ_CAB_CERTIFICATION_SUCCESS:
        return {
          ...state,
          certificate: action.payload.data.result,
        };
      case READ_CAB_CERTIFICATION_FAIL:
        return {
          ...state,
          certificate: [],
        };

      case CREATE_CAB_CERTIFICATION:
        return {
          ...state,
          showProgressBar: true,
        };
      case CREATE_CAB_CERTIFICATION_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
        };
      case CREATE_CAB_CERTIFICATION_FAIL:
        return {
          ...state,
          showProgressBar: false,
        };

      case SAVE_CAB_STATE_ATTRIBUTE:
        const attr = Object.keys(action)[1];
        const value = Object.values(action)[1];
        return {
          ...state,
          [attr]: value,
        };

      {/*BmeRequirement*/
      }
      case GET_CONTRACTORS_DATA:
        return {
          ...state,
        };
      case GET_CONTRACTORS_DATA_SUCCESS:
        return {
          ...state,
          contractors: action.payload.data.result,
          // contractors: testData
        };
      case GET_CONTRACTORS_DATA_FAIL:
        return {
          ...state,
        };
      {/*BmeRequirement*/
      }

      default:
        return state;
    }
  }
};

export const getCountryList = () => {
  return {
    types: [LOAD_COUNTRIES, LOAD_COUNTRIES_SUCCESS, LOAD_COUNTRIES_FAIL],
    payload: { request: { url: COUNTRIES } },
  };
};

export const createCompany = (company: Company) => {
  return {
    types: [CREATE_COMPANY, CREATE_COMPANY_SUCCESS, CREATE_COMPANY_FAIL],
    payload: {
      request: {
        url: CREATE_COMPANY_EP,
        method: 'POST',
        data: company,
      },
    },
  };
};

export const createCABEntry = (addressBookItem: AddressBookItem) => {
  return {
    types: [CREATE_CAB, CREATE_CAB_SUCCESS, CREATE_CAB_FAIL],
    payload: {
      request: {
        url: CREATE_CAB_EP,
        method: 'POST',
        data: addressBookItem,
      },
    },
  };
};

export const uploadCABImage = (formData: any) => {
  return {
    types: [UPLOAD_CAB_IMAGE, UPLOAD_CAB_IMAGE_SUCCESS, UPLOAD_CAB_IMAGE_FAIL],
    payload: {
      request: {
        url: CAB_IMAGE_UPLOAD_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const getAddressBookList = (filterData: CABFilter) => {
  return {
    types: [LOAD_CAB, LOAD_CAB_SUCCESS, LOAD_CAB_FAIL],
    payload: {
      request: {
        url: CAB_FILTER_EP,
        method: 'POST',
        data: filterData,
      },
    },
  };
};

export const getAddressBookByID = (id: string) => {
  // console.log('getAddressBookByID >>> ',id)
  return {
    types: [GET_CAB_BY_ID, GET_CAB_BY_ID_SUCCESS, GET_CAB_BY_ID_FAIL],
    payload: { request: { url: `CentralAddressBook/ReadCabPerson/${id}` } },
  };
};

export const deleteAddressBook = (idList: string[]) => {
  return {
    types: [DELETE_CAB, DELETE_CAB_SUCCESS, DELETE_CAB_FAIL],
    payload: {
      request: {
        url: CAB_DELETE_EP,
        method: 'DELETE',
        data: idList,
      },
    },
  };
};

export const isQniqContact = (contact: UniqContact) => {
  return {
    types: [
      LOAD_UNIQ_CONTACT,
      LOAD_UNIQ_CONTACT_SUCCESS,
      LOAD_UNIQ_CONTACT_FAIL,
    ],
    payload: {
      request: {
        url: CAB_UNIQ_CONTACT_EP,
        method: 'POST',
        data: contact,
      },
    },
  };
};

export const getIsCabExist = () => {
  return {
    types: [
      LOAD_ISEXIST_CONTACT,
      LOAD_ISEXIST_CONTACT_SUCCESS,
      LOAD_ISEXIST_CONTACT_FAIL,
    ],
    payload: { request: { url: IS_CAB_EXIST_EP } },
  };
};
export const getcountry = () => {
  return {
    types: [
      LOAD_COUNTRY,
      LOAD_COUNTRY_SUCCESS,
      LOAD_COUNTRY_FAIL,
    ],
    payload: { request: { url: IS_COUNTRY_EP } },
  };
};
export const getNoCabCopmanies = (name: string) => {
  return {
    types: [
      LOAD_NOCAB_COMPANIES,
      LOAD_NOCAB_COMPANIES_SUCCESS,
      LOAD_NOCAB_COMPANIES_FAIL,
    ],
    payload: { request: { url: getNoCabCompaniesURL(name) } },
  };
};

export const getExportCABs = () => {
  return {
    types: [LOAD_EXPORT_CABS, LOAD_EXPORT_CABS_SUCCESS, LOAD_EXPORT_CABS_FAIL],
    payload: { request: { url: CAB_EXPORT_EP } },
  };
};

export const createCabCompetencies = (competence: any) => {
  return {
    types: [
      CREATE_CAB_COMPETENCIES,
      CREATE_CAB_COMPETENCIES_SUCCESS,
      CREATE_CAB_COMPETENCIES_FAIL,
    ],
    payload: {
      request: {
        url: CREATE_CAB_COMPETENCIES_EP,
        method: 'POST',
        data: competence,
      },
    },
  };
};

export const readCabCompetencies = (personId: string) => {
  return {
    types: [
      READ_CAB_COMPETENCIES,
      READ_CAB_COMPETENCIES_SUCCESS,
      READ_CAB_COMPETENCIES_FAIL,
    ],
    payload: {
      request: {
        url: READ_CAB_COMPETENCIES_EP + personId,
      },
    },
  };
};

export const deleteCabCompetencies = (data: string[]) => {
  return {
    types: [
      DELETE_CAB_COMPETENCIES,
      DELETE_CAB_COMPETENCIES_SUCCESS,
      DELETE_CAB_COMPETENCIES_FAIL,
    ],
    payload: {
      request: {
        url: DELETE_CAB_COMPETENCIES_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const createCabCertification = (certificate: any) => {
  return {
    types: [
      CREATE_CAB_CERTIFICATION,
      CREATE_CAB_CERTIFICATION_SUCCESS,
      CREATE_CAB_CERTIFICATION_FAIL,
    ],
    payload: {
      request: {
        url: CREATE_CAB_CERTIFICATION_EP,
        method: 'POST',
        data: certificate,
      },
    },
  };
};

export const readCabCertification = (personId: string) => {
  return {
    types: [
      READ_CAB_CERTIFICATION,
      READ_CAB_CERTIFICATION_SUCCESS,
      READ_CAB_CERTIFICATION_FAIL,
    ],
    payload: {
      request: {
        url: READ_CAB_CERTIFICATION_EP + personId,
      },
    },
  };
};

export const deleteCabCertification = (data: string[]) => {
  return {
    types: [
      DELETE_CAB_CERTIFICATION,
      DELETE_CAB_CERTIFICATION_SUCCESS,
      DELETE_CAB_CERTIFICATION_FAIL,
    ],
    payload: {
      request: {
        url: DELETE_CAB_CERTIFICATION_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

const formatCountryList = (countries: Country[]) => {
  let data: { key: string; text: string }[] = [];
  if (_.isArray(countries)) {
    data = countries.map((item: Country) => {
      return {
        key: item.id,
        text: item.countryName,
      };
    });
  }
  return data;
};

const formatList = (countries: Countrylist[]) => {
  let data: { key: string; text: string }[] = [];
  if (_.isArray(countries)) {
    data = countries.map((item: Countrylist) => {
      return {
        key: item.countryId,
        text: item.countryName,
      };
    });
  }
  return data;
};


export const saveCabAtrr = (attr: string, value: any) => {
  return {
    type: SAVE_CAB_STATE_ATTRIBUTE,
    [attr]: value,
  };
};


{/*BmeRequirement*/
}
export const getContractorsData = () => {
  return {
    types: [
      GET_CONTRACTORS_DATA,
      GET_CONTRACTORS_DATA_SUCCESS,
      GET_CONTRACTORS_DATA_FAIL,
    ],
    payload: {
      request: {
        url: GET_CONTRACTORS_DATA_EP,
      },
    },
  };
};

const testData = [
  {
    id: '88bc4819-5968-4c38-8a59-20da60ecb89e',
    title: 'Resource Family 1',
    localeCode: null,
    parentId: null,
    displayOrder: 0,
  },
  {
    id: '88bc4819-b78m-4c38-8a59-20da60ecb89e',
    title: 'Pathum',
    localeCode: null,
    parentId: '88bc4819-hn12-4c38-8a59-20da60ecb89e',
    displayOrder: 0,
    expanded: true,
  },
  {
    id: '88bc4819-dn12-4c38-8a59-20da60ecb89e',
    title: 'Mesith',
    localeCode: null,
    parentId: null,
    displayOrder: 0,
  },
  {
    id: '88bc4819-hn12-4c38-8a59-20da60ecb89e',
    title: 'Sahan',
    localeCode: null,
    parentId: '88bc4819-dn12-4c38-8a59-20da60ecb89e',
    displayOrder: 0,
  },
  {
    id: '88bc4819-qn12-4c38-8a59-20da60ecb89e',
    title: 'Shanuka',
    localeCode: null,
    parentId: '88bc4819-qnmp-4c38-8a59-20da60ecb89e',
    displayOrder: 0,
  },
  {
    id: '88bc4819-qnmp-4c38-8a59-20da60ecb89e',
    title: 'Kasun',
    localeCode: null,
    parentId: '88bc4819-wbnk-4c38-8a59-20da60ecb89e',
    displayOrder: 0,
  },
  {
    id: '88bc4819-wbnk-4c38-8a59-20da60ecb89e',
    title: 'Sampath',
    localeCode: null,
    parentId: null,
    displayOrder: 0,
  },
];
{/*BmeRequirement*/
}

export default addressBookReducer;

import { Breadcrumb, DirectionalHint, IColumn, TooltipHost } from '@fluentui/react';
import React from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import DefaultNumberFormat from '../fluentFinalForm/defaultNumberFormat';
import { breadcrumbStyles, CURRENCY_PREFIX, getBreadcrumbItemFromData, getProject } from '../util';
import { WorkFlowDropDowns } from '../../types/workFlow';

const listItemStyle = {
  marginTop: 3,
};
const listItemQStyle = {
  marginTop: 3,
  listStyleType: 'none',
};
const listItemPStyle = {
  marginTop: 3,
  listStyleType: 'none',
  textAlign: 'right' as const,
};
const listItemSPStyle = {
  marginTop: 0,
  listStyleType: 'none',
  textAlign: 'right' as const,
};
const listItemEditStyle = {
  marginTop: 10,
  listStyleType: 'none',
};
const listItemPEditStyle = {
  marginTop: 10,
  listStyleType: 'none',
  textAlign: 'right' as const,
};
const resourceListStyle = {
  marginTop: 50,
  listStyleType: 'none',
};
const listItemTotalSalesStyle = {
  marginLeft: 20,
};
const editTextStyle = {
  paddingTop: 0,
};
const ulStyle = {
  paddingLeft: 0,
  marginTop: 0,
};
const borderStyle = { borderBottom: `0.25px solid #e5e5e5` };
const titleBorderStyle = {
  borderBottom: `0.25px solid #e5e5e5`,
  borderTop: `0.25px solid #e5e5e5`,
};
export const WorkFlowTaskColumn = (
  item: any,
  index?: number,
  column?: IColumn,
  isEditable?: boolean,
  onChangePickedQuantity?: (index: number, value: any) => void,
  onChangeComment?: (index: number, value: any) => void,
  onChangeTotalPrice?: (index: number, value: any) => void,
  onChangeStockAvailability?: (index: number, value: any) => void,
  taxanomyData?: WorkFlowDropDowns,
  source?: string,
  disabled?: boolean,
) => {
  if (column && column.fieldName && column.fieldName === 'source') {
    // let cp = item?.stock?.wareHouseTaxonomyId
    let msource;
    if (getProject()) {
      msource = source;
    } else {
      msource = item?.stock?.wareHouseTaxonomyId;
    }

    return (
      <div style={{ paddingTop: 10 }}>
        <Breadcrumb
          items={getBreadcrumbItemFromData(taxanomyData?.warehouseTaxonomy, msource)}
          ariaLabel="With last item rendered as heading"
          overflowAriaLabel="More links"
          styles={breadcrumbStyles}
        />
      </div>
    );
  } else if (column && column.fieldName && column.fieldName === 'mou') {
    return (
      <div style={{ paddingTop: 10 }}>
        <span>{item[column.fieldName]}</span>
      </div>
    );
  } else if (column && column.fieldName && column.fieldName === 'quantity') {
    let quantity = item?.quantity;
    return (
      <div style={{ paddingTop: 10 }}>
        <span>{quantity}</span>
      </div>
    );
  } else if (column && column.fieldName && column.fieldName === 'corporateProductCatalog') {
    let cp = item[column.fieldName];
    return (
      <div style={{ paddingTop: 10 }}>
        <TooltipHost
          key={item[column.fieldName] + 't'}
          content={cp?.text}
          id="filter-opt-1"
          calloutProps={{ gapSpace: 0 }}
          directionalHint={DirectionalHint.bottomCenter}
        >
          <span>{cp?.text}</span>
        </TooltipHost>

      </div>
    );
  } else if (column && column.fieldName && column.fieldName === 'destination') {
    let cp = item?.destination;
    return (
      <div style={{ paddingTop: 10 }}>
        <Breadcrumb
          items={getBreadcrumbItemFromData(taxanomyData?.warehouseTaxonomy, item?.stock?.wareHouseTaxonomyId)}
          ariaLabel="With last item rendered as heading"
          overflowAriaLabel="More links"
          styles={breadcrumbStyles}
        />
      </div>
    );
  } else if (
    column &&
    column.fieldName &&
    column.fieldName === 'pickedQuantity'
  ) {
    return (
      <div>
        <DefaultNumberFormat
          disabled={disabled}
          value={item[column.fieldName] ? item[column.fieldName] : 0.00}
          onChange={(newValue?: any) => {
            onChangePickedQuantity && newValue ? onChangePickedQuantity(index!!, newValue) : null;
          }}
        />
      </div>
    );
  } else if (column && column.fieldName && column.fieldName === 'totalPrize') {
    return (
      <div>
        <DefaultNumberFormat
          disabled={false}
          readOnly={true}
          prefix={CURRENCY_PREFIX}
          value={item[column.fieldName] ? item[column.fieldName] : 0.00}
          onChange={(newValue?: string) => {
            onChangeTotalPrice ? onChangeTotalPrice(index!!, newValue) : null;
          }}
        />
      </div>
    );
  } else if (column && column.fieldName && column.fieldName === 'comment') {
    return (
      <div>
        <TooltipHost
          key={item[column.fieldName] + 't'}
          content={item[column.fieldName]}
          id="filter-opt-1"
          calloutProps={{ gapSpace: 0 }}
          directionalHint={DirectionalHint.bottomCenter}
        >
          <TextField
            disabled={disabled}
            value={item[column.fieldName]}
            onChange={(e: any, newValue?: string) => {
              e.preventDefault();
              onChangeComment ? onChangeComment(index!!, newValue) : null;
            }}
          />
        </TooltipHost>
      </div>
    );
  } else if (
    column &&
    column.fieldName &&
    column.fieldName === 'stockAvailability'
  ) {
    let quantity = item?.stock?.availableQuantity;
    return (
      <div>
        <DefaultNumberFormat
          disabled={disabled}
          readOnly={true}
          value={quantity ? quantity : 0.00}
          onChange={(newValue?: string) => {
            onChangeStockAvailability
              ? onChangeStockAvailability(index!!, newValue)
              : null;
          }}
        />
      </div>
    );
  } else if (column && column.fieldName) {
    const fieldContent = item[column.fieldName];
    return fieldContent ? (
      <span style={{ paddingLeft: 9 }} onClick={() => {
      }}>
        {fieldContent}
      </span>
    ) : (
      <span style={{ paddingLeft: 9 }}>-</span>
    );
  } else {
    return <span style={{ paddingLeft: 9 }}>-</span>;
  }
};

export const LOAD_CERTIFICATION_SHORTCUTPANE = 'uprince/LOAD_CERTIFICATION_SHORTCUTPANE';
export const LOAD_CERTIFICATION_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_CERTIFICATION_SHORTCUTPANE_SUCCESS';
export const LOAD_CERTIFICATION_SHORTCUTPANE_FAIL = 'uprince/LOAD_CERTIFICATION_SHORTCUTPANE_FAIL';

export const LOAD_CERTIFICATION_LSITPANE = 'uprince/LOAD_CERTIFICATION_LSITPANE';
export const LOAD_CERTIFICATION_LSITPANE_SUCCESS = 'uprince/LOAD_CERTIFICATION_LSITPANE_SUCCESS';
export const LOAD_CERTIFICATION_LSITPANE_FAIL = 'uprince/LOAD_CERTIFICATION_LSITPANE_FAIL';

export const LOAD_CERTIFICATION_DROPDOWN = 'uprince/LOAD_CERTIFICATION_DROPDOWN';
export const LOAD_CERTIFICATION_DROPDOWN_SUCCESS = 'uprince/LOAD_CERTIFICATION_DROPDOWN_SUCCESS';
export const LOAD_CERTIFICATION_DROPDOWN_FAIL = 'uprince/LOAD_CERTIFICATION_DROPDOWN_FAIL';

export const SAVE_CERTIFICATION = 'uprince/SAVE_CERTIFICATION';
export const SAVE_CERTIFICATION_SUCCESS = 'uprince/SAVE_CERTIFICATION_SUCCESS';
export const SAVE_CERTIFICATION_FAIL = 'uprince/SAVE_CERTIFICATION_FAIL';

export const LOAD_CERTIFICATION_BY_ID = 'uprince/LOAD_CERTIFICATION_BY_ID';
export const LOAD_CERTIFICATION_BY_ID_SUCCESS = 'uprince/LOAD_CERTIFICATION_BY_ID_SUCCESS';
export const LOAD_CERTIFICATION_BY_ID_FAIL = 'uprince/LOAD_CERTIFICATION_BY_ID_FAIL';

export const LOAD_BOR_RESOURCES_BY_ID = 'uprince/LOAD_BOR_RESOURCES_BY_ID';
export const LOAD_BOR_RESOURCES_BY_ID_SUCCESS = 'uprince/LOAD_BOR_RESOURCES_BY_ID_SUCCESS';
export const LOAD_BOR_RESOURCES_BY_ID_FAIL = 'uprince/LOAD_BOR_RESOURCES_BY_ID_FAIL';

export const READ_CERTIFICATION_TAXONOMY = 'uprince/READ_CERTIFICATION_TAXONOMY';
export const READ_CERTIFICATION_TAXONOMY_SUCCESS = 'uprince/READ_CERTIFICATION_TAXONOMY_SUCCESS';
export const READ_CERTIFICATION_TAXONOMY_FAIL = 'uprince/READ_CERTIFICATION_TAXONOMY_FAIL';

export const CREATE_CERTIFICATION_TAXONOMY = 'uprince/CREATE_CERTIFICATION_TAXONOMY';
export const CREATE_CERTIFICATION_TAXONOMY_SUCCESS = 'uprince/CREATE_CERTIFICATION_TAXONOMY_SUCCESS';
export const CREATE_CERTIFICATION_TAXONOMY_FAIL = 'uprince/CREATE_CERTIFICATION_TAXONOMY_FAIL';

export const SET_CERTIFICATION_UID = 'uprince/SET_CERTIFICATION_UID';
export const CLEAR_CERTIFICATION_UID = 'uprince/CLEAR_CERTIFICATION_UID';
export const CLEAR_CERTIFICATION_FORM_DATA = 'uprince/CLEAR_CERTIFICATION_FORM_DATA';
export const RESET_CERTIFICATION_IS_CHANGE = 'uprice/RESET_CERTIFICATION_IS_CHANGE';


export const CLEAR_CERTIFICATIONUID = 'uprice/CLEAR_CERTIFICATIONUID';
export const SAVE_CERT_STATE_ATTR = 'uprince/SAVE_CERT_STATE_ATTR';

export const SAVE_CAB_STATE_ATTRIBUTE = 'uprince/SAVE_CAB_STATE_ATTRIBUTE';

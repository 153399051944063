import { Label, Link } from '@fluentui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactVisibilitySensor from 'react-visibility-sensor'
import ProjectMoleculerJournal from '../../journal/projectMoleculerJournal'
import { Journal, ProjectMolecule } from '../../../../types/projectMolecule'

const ProjectMoleculeJournal = (props: {
  onVisibilityChangePlannedWorks: (isVisible: boolean) => void;
  PMOLSaveJournal: () => void;
  setJournal: (feild: any, value: any) => void;
  setJournalImage: (type: string, response: any) => void;
  journal: Journal;
  readOnly: boolean;
  formData: ProjectMolecule;
  saveFormData: () => void;
  handleJournalChange: (journal: any[]) => void;
}) => {
  const {t} = useTranslation()
  return (
    <div className="document-pane-card">
      <div className={'card-header'}>
        <div className="proj-detail-block" id="19">
          <Link href="#" id="journal-5-1">
            <Label>6. {t('projectMoleculerJournal')} </Label>
          </Link>
        </div>
      </div>

      <div className={'card-body'}>
        <ReactVisibilitySensor scrollCheck onChange={props.onVisibilityChangePlannedWorks}>
          <ProjectMoleculerJournal
            PMOLSaveJournal={() => props.PMOLSaveJournal()}
            setJournal={(
              feild: any,
              value: any,
            ) => props.setJournal(feild, value)}
            setJournalImage={(
              type: string,
              response: any,
            ) => props.setJournalImage(type, response)}
            journal={props.journal}
            readOnly={props.readOnly}
            productId={props.formData.productId}
            pmolId ={props?.formData?.projectMoleculeId!!}
            renameSuccess={() => {
              props.saveFormData();
            }}
            handleJournalChange={(item: any[]) => {
              props.handleJournalChange(item);
            }}
          />
        </ReactVisibilitySensor>
      </div>
    </div>
  )
}

export default ProjectMoleculeJournal
import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import ConfirmDialog from '../../components/cuProjects/dialog';
import { messageService } from '../../services/messageService';

const RouteLeavingGuard = (props: {
  when: any;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
  handleConfirmation: (isConfirm: boolean) => void;
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);


  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (location.pathname !== nextLocation.pathname) {
      if (confirmedNavigation && props.when) {
        setConfirmedNavigation(false);
        setModalVisible(true);
        setLastLocation(nextLocation);
        return false;
      }
      if (!confirmedNavigation && props.shouldBlockNavigation(nextLocation)) {
        setModalVisible(true);

        setLastLocation(nextLocation);

        return false;
      }
      // alert(JSON.stringify(modalVisible));
    }

    return true;
  };

  const closeModal = () => {
    setModalVisible(false);
    props.handleConfirmation(false);
    messageService.sendMessage({ resetListPaneSelection: true });
  };

  const handleConfirmNavigationClick = () => {
    props.handleConfirmation(true);
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      props.navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);
  return (

    <>
      <Prompt
        when={props.when}
        message={handleBlockedNavigation}
      />
      {props.when && <ConfirmDialog
        show={!modalVisible}
        onClickCancelDialog={closeModal}
        onClickConfirmDialog={handleConfirmNavigationClick}
      />}
    </>
  );
};
export default RouteLeavingGuard;

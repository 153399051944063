import React, { Component } from 'react';
import { connect } from 'react-redux';
import Brand from '../../shared/brand/brand';
import ProjectBar from '../../shared/projectBar/projectBar';
import NavBar from '../../shared/navBar/navBar';
import ProjectNavBar from '../../shared/navBar/projectNavBar';
import CUNavBar from '../../shared/navBar/cuNavBar';
import ProductCatalogueMainLayout from './projectBreakdownMainLayout';
import { getContractingUnit, getProject } from '../../shared/util';


interface Props {
  currentUser: any
}

interface State {
  isContractUnit: boolean
}


export class ProjectBreakdownStructure extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isContractUnit: getContractingUnit() ? true : false };
  }

  getSelectedKey = () => {
    if (!getContractingUnit()) {
      return '/organisation-cbc';
    } else if (getContractingUnit() && !getProject()) {
      return '';
    } else if (getContractingUnit() && getProject()) {
      return `/CU/${getContractingUnit()}/project/${getProject()}/product/pbs`;
    }
  };

  render() {
    return (
      <div style={{ height: '100%' }}>
        <Brand/>
        <ProjectBar
          contractingUnit={this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null}/>
        {this.state.isContractUnit && getProject() &&
        <ProjectNavBar selectedKey={this.getSelectedKey()} currentUser={this.props.currentUser}/>
        }
        {this.state.isContractUnit && !getProject() &&
        <CUNavBar selectedKey={`/CU/${getContractingUnit()}/organisation-cbc`}
                  contractingUnit={this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null}
                  currentUser={this.props.currentUser}/>
        }
        {!this.state.isContractUnit &&
        <NavBar selectedKey={this.getSelectedKey()}
                contractingUnit={this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null}
                currentUser={this.props.currentUser}/>
        }
        <ProductCatalogueMainLayout/>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectBreakdownStructure);

import React, {useEffect} from 'react';

import './loading.css';
import {Icon} from "@fluentui/react";
import {useDispatch, useSelector} from "react-redux";
import AuthService from "../../services/auth.service";
import api from "../../api";
import {saveStateAttr} from "../../reducers/uprinceReducer";


const CustomLoading = (props:{loadingColor:string|null}) => {
    const dispatch = useDispatch();
    const authService=new AuthService();
    const {currentUser}: any = useSelector(
        (state: any) => state.uprince
    );


    const getUserData=async ()=>{
        if(!currentUser && localStorage.getItem('accessToken')){
            await api.post('User/login', ).then((res)=>{
              dispatch(saveStateAttr('currentUser', res.data.result));
            }).catch((error:any)=>{
                console.warn({error});
                authService.logout();
            });

        }
    };

    useEffect(()=>{
        authService.getToken().then(()=>{
            getUserData().then();
        })
    },[])

    return (
        <div>
            <div id="loader-wrapper" style={{backgroundColor:(props.loadingColor)?props.loadingColor:"#f4681d"}}>
                <div id="loader"></div>

                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>

            </div>
        </div>
    );
};

export default CustomLoading;

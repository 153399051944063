import {
  ActionButton,
  DetailsList,
  DetailsListLayoutMode,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  PrimaryButton,
  Selection,
  SelectionMode,
  Separator,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import _ from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import {Field, Form} from 'react-final-form';
import {uPrinceTheme} from '../../../../theme';
import {DatePickerAdapter, DropDownAdaptater, TextFieldFF} from '../../../shared/fluentFinalForm';
import {
  CURRENCY_PREFIX,
  formatDateTimeToISOString,
  getBreadcrumbItemFromData,
  getContractingUnit,
  getProject,
} from '../../../shared/util';
import {
  awardColumns,
  ContractDropDowns,
  contractInitialFormData,
  ContractListPaneFilter,
  documentationColumns,
  publishTenderColumns,
  Status,
  teamsColumns,
  timeTableColumns,
} from '../../../types/contract';
import HistoryBar from '../../../shared/historyBar/historyBar';
import {useTranslation} from 'react-i18next';
import ProjectHeader from '../../../shared/projectHeader/projectHeader';
import LotContractorsTaxonomy from './lotContractorsTaxonomy/component';
import {useDispatch, useSelector} from 'react-redux';
import {AddressBookItem} from '../../../types/addressBookItem';
import FixedDecimalNumberFormatField from '../../../shared/fluentFinalForm/FixedDecimalNumberFormatField';
import DataGridWithModal, {ModalGridContext} from '../../../shared/dataGridModal/component';
import TeamsModalContent from './contractorList/component';
import LotDocumentationCard from './documentation/component';
import DocumentationModalContent from './documentation/modal/component';
import {
  deleteConstructor,
  filterContractListPaneData,
  getConstructorsData,
  lotAwardTender,
  readByContractId,
  saveContractStateAttr,
  saveFormData,
  sendLotInvitation,
} from '../../../reducers/contractReducer';
import {rendercardFooter} from '../../../types/uPrince';
import LotTaxonomy from './lotContractorsTaxonomy/lotTaxonomy';
import SearchableDropdown from '../../../shared/searchableDropdown/searchableDropdown';
import client from '../../../api';
import {READ_STANDARD_MAIL_LIST_EP} from '../../../shared/endpoints';

const theme = getTheme();

const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: '4px 10px',
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: '4px 10px',
};

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };

const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
  marginLeft: { marginLeft: '10px' },

});

// const required = (value: any) => (value ? undefined : 'Required');

const DocumentPaneComponent = (props: {
  submit: any,
  dropDowns: ContractDropDowns,
  saveFormData: any,
  uid: any,
  showProgress: boolean,
  formValues: any,
  formData?: any,
  isExpand: boolean,
  setForm: (form: any) => void,
  isClear: boolean,
  projectHeader: any,
  openContractorTeamModal: () => void,
  openDocumentationModal: () => void,
  addressBookItem: AddressBookItem,
  handleContractorsTaxonomyChange: any,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const [state, setstate] = useState<Contract | null>(null);
  // const [contractorList, setContractorList] = useState<Contract | null>(null);
  const id = location.pathname.split('/').pop();

  const {
    formData,
    isClear,
    contractDropDowns,
    constructorFormData,
    showProgressBar,
    contractId,
    contractors,
    taxonomyData,
    sequenceId,
    tenderAwarded,
    tenderSelection,
    readById,
  }: any = useSelector(
    (state: any) => state.contract,
  );

  const contractorsData = useSelector(
    (state: any) => state.addressBook.contractors,
  );

  const [enableAwardShimmer, setEnableAwardShimmer] = useState(false);
  const [contractTaxonomyId, setContractTaxonomyId] = useState(formData.contractTaxonomyId);
  const { contractingUnits }: any = useSelector((cuProjectState: any) => cuProjectState.cuProject);

  const onSubmit = async (values: any) => {
    const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
    await sleep(300);
    // window.alert(JSON.stringify(values, 0, 2));
    values.projectSequenceCode = getProject();
    if (!values.id) {
      values.id = props.uid;
    }
    // values.standardMailId=values?.standardMail?.value?values?.standardMail?.value:null;
    // values.IsDeleted = false;
    dispatch(saveFormData(values));
  };

  const ref: any = useRef(null);

  const getSelectedContractingUnit = (): any[] => {
    if (contractingUnits && Array.isArray(contractingUnits)) {
      const cu = contractingUnits.filter((unit) => unit.sequenceCode === getContractingUnit());
      return cu;
    }
    return [];
  };

  const getInitialFormValues = (formValue: any) => {
    if (location.pathname.split('/').pop() === 'new' && isClear) {
      // setContractInitialFormData(new ContractInitialFormData());
      return {
        ...contractInitialFormData,
        division: Array.isArray(getSelectedContractingUnit()) && getSelectedContractingUnit().length > 0 ? getSelectedContractingUnit()[0]?.name : null,
        contractorTeamList: {
          contractorTeamList: [],
        },
      };
    } else if (formData && formData?.id && !!constructorFormData) {
      return {
        ...formData,
        productItemTypeId: formData?.productItemType?.key,
        statusId: formData?.status?.key,
        standardMailId: formData?.standardMail?.value,
      };
    }
    return formValue;
  };

  // const handlePublishTenderBtnTxt = () => {
  //     if (formData?.status?.key == Status.In_Tendering) {
  //         return t('publishTender')
  //     }
  //     return t('sendInvitations')
  // };

  const handlePublishTenderBtnClick = (values: any) => {
    let items: any[] = [];

    // if (formData?.status?.key == Status.In_Tendering) {
    items.push({
      lotId: values?.id,
      sequenceId: values?.sequenceId,
      contractorTeamList: values?.contractorList?.contractorTeamList,
    });

    Promise.all([
      dispatch(sendLotInvitation(items[0])),
    ]).then(() => {
      dispatch(filterContractListPaneData(new ContractListPaneFilter()));
    });
    // }
    //  else {
    //     items.push({
    //         lotId: values?.id,
    //         sequenceId: values?.sequenceId,
    //         contractorTeamList: values?.contractorList?.contractorTeamList,
    //     });

    //     Promise.all([
    //         dispatch(sendLotInvitation(items[0]))
    //     ]).then(() => {
    //         dispatch(filterContractListPaneData(new ContractListPaneFilter()));
    //     });
    // }
  };

  const handlePublishTenderBtnDisabled = (isInviteSend: boolean) => {
    if (!!formData?.sequenceId) {
      if (!isInviteSend) {
        if (formData?.status?.key == Status.In_Tendering) {
          return false;
        }
        return true;
      }
      return true;
    }
    ;
  };

  let selection: any[] = [];

  const _tenderSelection = new Selection({
    onSelectionChanged: () => {
      selection = _tenderSelection.getSelection();
      dispatch(saveContractStateAttr('tenderSelection', selection));
    },
  });

  const handleAwardTenderBtn = () => {
    const tenderAwarding = tenderSelection?.map((item: any) => {
      setEnableAwardShimmer(true);
      return { ...item, isWinner: true, lotId: formData?.sequenceId };
    });
    Promise.all([
      dispatch(lotAwardTender(tenderAwarding[0])),
    ]).then(() => {
      setEnableAwardShimmer(false);
    });
  };

  const handleAwardTenderBtnDisabled = () => {
    if (!formData?.sequenceId) {
      return true;
    } else {
      if (!tenderSelection[0]?.companyId) {
        return true;
      }
    }

    return false;
  };

  const generateContractNameByTaxonomy = (selectedNode: any) => {
    if (selectedNode?.id) {
      let parentArray = getBreadcrumbItemFromData(
        contractorsData,
        selectedNode?.id,
      );
      let nameArray = parentArray.map((item: any) => {
        return item.text;
      });
      return nameArray.join(' > ');
    }
    return selectedNode?.title;
  };

  const customeProjectHeader = {
    ...props.projectHeader,
    // company: null,
    // id: null,
    image: props.projectHeader?.image,
    // mobileNumber: null,
    pmName: props.projectHeader?.pmName,
    // projectFinanceStatusId: null,
    // projectScopeStatusId: null,
    // roleName: null,
    // sequenceCode: null,
    title: props.projectHeader ? `${props.projectHeader?.title} ${formData?.name ? ` > ${formData?.name}` : ''}` : '',
  };

  // let subscription: any = null;

  // useEffect(() => {
  //     subscription = messageService.getMessage().subscribe((data: any) => {
  //         if (data) {
  //             if (data.data.isNewCONTRACT) {
  //                 setContractTaxonomyId(null);
  //             }
  //         }
  //     }
  // }, []);

  useEffect(() => {
    if (contractors && !_.isEmpty(contractors)) {
      ref.current.mutators.setContractorList(contractors);
    } else {
      ref.current.mutators.setContractorList([]);
    }
  }, [contractors]);

  useEffect(() => {
    if (contractId) {
      dispatch(readByContractId(contractId));
      dispatch(saveContractStateAttr('excelUploaded', false));
      dispatch(saveContractStateAttr('contractId', null));
      // dispatch(filterContractListPaneData(new ContractListPaneFilter()));
    }
  }, [contractId]);

  useEffect(() => {
    if (sequenceId) {
      dispatch(readByContractId(sequenceId));      //invitation is the sequenceId
      dispatch(saveContractStateAttr('sequenceId', null));
    }
  }, [sequenceId]);

  useEffect(() => {
    if (tenderAwarded) {
      dispatch(readByContractId(formData?.sequenceId));
    }
    dispatch(saveContractStateAttr('tenderAwarded', false));
  }, [tenderAwarded]);

  useEffect(() => {
    if (formData?.id && readById) {

    }
  }, [readById]);

  // useEffect(() => {
  //     if (contractTaxonomyId) {
  //         let parentArray = getBreadcrumbItemFromData(
  //             contractorsData,
  //             contractTaxonomyId
  //         );

  //         let nameArray = parentArray.map((item: any) => {
  //             return item.text
  //         });

  //         ref.current.mutators.setContractName(nameArray.join(" > "));
  //     };

  // }, [contractTaxonomyId]);

  // useEffect(() => {
  //     if (formData.contractTaxonomyId) {
  //         setContractTaxonomyId(formData.contractTaxonomyId);
  //     }
  // }, [formData.contractTaxonomyId]);


  const formatStandardMailResponse = (response: any) => {
    let data: { value: string; label: string }[] = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: any) => {
        return {
          value: item.id,
          label: item.title,
          sequenceId: item.sequenceId,
        };
      });
    }
    return data;
  };

  const searchStandardMail = async (name: string) => {
    let filter = {
      title: name?name:null,
      sorter: {
        attribute: 'title',
        order: 'asc',
      },
    };
    const response = await client.post(READ_STANDARD_MAIL_LIST_EP, filter);
    return formatStandardMailResponse(response);
  };

  const standardMailPromiseOptions = (inputValue: any) =>
    new Promise((resolve) => {
      // setTimeout(() => {
      resolve(searchStandardMail(inputValue));
      // }, 1000);
    });

  return (
    <div className={classNames.fullWidth}>
      <div className="proj-detail-block">
        <Form
          onSubmit={onSubmit}
          initialValues={getInitialFormValues(props.formValues)}
          mutators={{
            setContractorList: (args, state, utils) => {
              const field = state.fields['contractorList'];
              let y: any = state.formState.values;
              let y1 = y?.contractorList;
              let x = y1?.contractorTeamList;
              // let z = x.concat(args[0])
              let z = args[0];

              x?.map((item: any) => {
                if (item?.isManual && taxonomyData) {
                  z.push(item);
                }
              });
              let c = {
                ...y1,
                contractorTeamList: z,
              };
              field.change(c);
              if (taxonomyData && y?.id) {
                dispatch(saveFormData({ ...y, contractorList: c }));
                dispatch(saveContractStateAttr('taxonomyData', false));
              }
            },
            setContractName: (args, state, utils) => {
              const field = state.fields.name;
              field.change(args[0]);
            },
            setStandardMailId: (args, state, utils) => {
              const field = state.fields.standardMailId;
              field.change(args[0]);
            },
          }}
          validate={(values) => {
            const errors: any = {};

            if (!values?.productItemTypeId && !showProgressBar) {
              errors.productItemTypeId = t('productItemTypeRequired');
            }
            if (!values?.statusId && !showProgressBar) {
              errors.statusId = t('statusRequired');
            }
            if (!values?.name && !showProgressBar) {
              errors.name = t('nameRequired');
            }
            return errors;
          }}
          render={({
                     handleSubmit,
                     form,
                     submitting,
                     pristine,
                     values,
                   }) => {
            ref.current = form;
            props.submit(handleSubmit);
            props.setForm(form);

            let headerFiles = values?.files;
            return (
              <form onSubmit={handleSubmit} noValidate>
                <ProjectHeader projectDetails={customeProjectHeader}/>
                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="lot">
                      <Label>1. {t('lot')}</Label>
                    </Link>
                  </div>
                  {values?.sequenceId && (
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="title"
                            component={TextFieldFF}
                            lable={t('lotTitle')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="sequenceId"
                            component={TextFieldFF}
                            lable={t('lotId')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>
                  )}

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="name"
                          component={TextFieldFF}
                          lable={t('contractName')}
                          disabled={true}
                          required={true}
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="productItemTypeId"
                          component={DropDownAdaptater}
                          options={contractDropDowns.productItemType}
                          lable={t('productItemType')}
                          placeholder={t('productItemType')}
                          disabled={true}
                          required={true}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  <div className={classNames.marginLeft}>
                    <div className="proj-detail-content inner" style={{ paddingTop: 4}}>
                      <Field name="contractTaxonomyId">
                        {({ input, meta }) => (
                          <LotContractorsTaxonomy
                            label={t('contractorsSelect')}
                            selectItemId={values?.contractTaxonomyId}
                            onChange={(selectedNode: any) => {
                              input.onChange(selectedNode?.id);
                              form.mutators.setContractName(generateContractNameByTaxonomy(selectedNode));
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </div>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="statusId"
                          component={DropDownAdaptater}
                          options={contractDropDowns.status}
                          lable={t('lotStatus')}
                          placeholder={t('status')}
                          disabled={true}
                          required={true}
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="division"
                          component={TextFieldFF}
                          lable={t('division')}
                          disabled={true}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="startDate"
                          component={DatePickerAdapter}
                          lable={t('startDate')}
                          disabled={false}
                          parse={value => value && formatDateTimeToISOString(value)}
                          format={value => (value ? new Date(value) : null)}
                          maxDate={
                            values?.endDate ? new Date(values?.endDate) : null
                            // new Date(form.getFieldState('endDate')?.value)
                          }
                          minDate={null}
                          isClearable={true}
                          onDateClear={() => {
                            form.change('startDate', null);
                          }}
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="endDate"
                          component={DatePickerAdapter}
                          lable={t('endDate')}
                          disabled={false}
                          parse={value => value && formatDateTimeToISOString(value)}
                          format={value => (value ? new Date(value) : null)}
                          maxDate={null}
                          minDate={
                            values?.startDate ? new Date(values?.startDate) : null
                            // new Date(form.getFieldState('startDate')?.value)
                          }
                          isClearable={true}
                          onDateClear={() => {
                            form.change('endDate', null);
                          }}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.halfWidth}`}>
                        <Field
                          name="expectedNumberOfQuotes"
                          component={TextFieldFF}
                          lable={t('expectedNumberOfQuotes')}
                          disabled={false}
                          type={'number'}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  <div className={'card-footer'}>
                    <Separator/>
                    <Stack
                      styles={stackStyles}
                      tokens={itemAlignmentsStackTokens}
                    >
                      <Stack.Item
                        align="end"
                        styles={stackFooterBarItemStyles}
                      >
                        <ActionButton
                          className={classNames.actionButton}
                          iconProps={saveIcon}
                          allowDisabledFocus
                          onClick={() => {
                            // alert(JSON.stringify(values.purchaseOrderType, 0, 2));
                            form.submit();
                          }}
                          // disabled={ (props.dropDownOptions.isApproved) }
                        >
                          <Label className={classNames.actionButton}>
                            {t('save')}
                          </Label>
                        </ActionButton>
                      </Stack.Item>
                    </Stack>
                  </div>
                </div>

                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="lot-budget">
                      <Label>2. {t('lotBudget')}</Label>
                    </Link>
                  </div>
                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="tenderBudget"
                          component={FixedDecimalNumberFormatField}
                          lable={t('tenderBudget')}
                          prefix={CURRENCY_PREFIX}
                          disabled={true}
                          readOnly={true}

                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="customerBudget"
                          component={FixedDecimalNumberFormatField}
                          lable={t('customerBudget')}
                          prefix={CURRENCY_PREFIX}
                          disabled={false}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  <div className={'card-footer'}>
                    <Separator/>
                    <Stack
                      styles={stackStyles}
                      tokens={itemAlignmentsStackTokens}
                    >
                      <Stack.Item
                        align="end"
                        styles={stackFooterBarItemStyles}
                      >
                      </Stack.Item>
                    </Stack>
                  </div>
                </div>

                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="contractor-list">
                      <Label>3. {t('contractorList')}</Label>
                    </Link>
                  </div>
                  <div className={classNames.marginLeft}>
                    <div className="proj-detail-content inner">
                      <Field name="contractorList.contractorLot">
                        {({ input, meta }) => (
                          <LotTaxonomy
                            label={t('contractorLot')}
                            selectItemIds={Array.isArray(values?.contractorList?.contractorLot) ? values?.contractorList?.contractorLot : values?.contractorList?.contractorLot}
                            onChange={(selectedNode: any) => {
                              input.onChange(selectedNode);
                              dispatch(getConstructorsData(selectedNode));
                              dispatch(saveContractStateAttr('taxonomyData', true));
                              // props.handleContractorsTaxonomyChange(selectedNode?.id)
                            }}
                            disabled={!!!values?.sequenceId}
                          />
                        )}
                      </Field>
                    </div>
                  </div>

                  <Field name="contractorList">
                    {({ input, meta }) => (
                      <DataGridWithModal
                        //dataList={contractors}// ? contractors : values.contractorList?.contractorTeamList} //values.contractorList?.contractorTeamList && Array.isArray(values.contractorList.contractorTeamList) ? values.contractorList.contractorTeamList : []}
                        dataList={values.contractorList?.contractorTeamList && Array.isArray(values.contractorList.contractorTeamList) ? values.contractorList.contractorTeamList : []}
                        modalTitle={t('contractors')}
                        deleteDataGrid={async (selection: any) => {
                          let contractorList = [];
                          const selectedList = selection.map((item: any) => {
                            return item;
                          });
                          const selectedCompanyList = selection.map((item: any) => {
                            return item.companyId;
                          });
                          const currentList: any[] = values.contractorList?.contractorTeamList;

                          const filtered: any[] = currentList.filter((e: any) => {
                            return selectedList.indexOf(e) < 0;
                          });
                          contractorList = { ...values.contractorList, contractorTeamList: filtered };
                          input.onChange(contractorList);
                          dispatch(deleteConstructor({
                            lot: values?.id,
                            cabCompanyId: selectedCompanyList ? selectedCompanyList : [],
                          }));
                          dispatch(saveFormData({ ...values, contractorList: contractorList }));
                        }}
                        title={`3.1 ${t('contractors')}`}
                        readOnly={!!!values?.sequenceId}
                        columns={teamsColumns()}
                        isLoaded={false}
                        keyProp={'cabPersonId'}
                        onDismiss={() => {
                          dispatch(saveContractStateAttr('uploadedInstructionLink', null));
                          setTimeout(() => {
                            dispatch(filterContractListPaneData(new ContractListPaneFilter()));
                          }, 1000);
                        }}
                      >
                        <ModalGridContext.Consumer>
                          {(modalProps: any) => (
                            <TeamsModalContent
                              teamMember={modalProps.editItem}
                              dataList={modalProps.dataList}
                              lotContractorId={values?.id}
                              disabled={false}
                              onChange={(item: any) => {
                                if (values.contractorList && values.contractorList?.contractorTeamList && Array.isArray(values.contractorList?.contractorTeamList)) {
                                  const contractorTeamList: any[] = [];
                                  const currentList: any[] = values.contractorList?.contractorTeamList;
                                  let contractorList = [];
                                  if (item.id) {
                                    const index = currentList.findIndex(object => {
                                      return object.id === item.id;
                                    });
                                    if (index < currentList.length) {
                                      currentList[index] = item;
                                    }
                                    contractorList = { ...values.contractorList, contractorTeamList: currentList };
                                    input.onChange(contractorList);
                                    modalProps.updateEditItem(item);
                                  } else {
                                    contractorTeamList.push(item);
                                    currentList.forEach((listItems: any) => {
                                      contractorTeamList.push(listItems);
                                    });
                                    contractorList = {
                                      ...values.contractorList,
                                      contractorTeamList: contractorTeamList,
                                    };
                                    input.onChange(contractorList);
                                  }
                                  if (values.id) {
                                    dispatch(saveFormData({ ...values, contractorList: contractorList }));
                                    setTimeout(() => {
                                      dispatch(filterContractListPaneData(new ContractListPaneFilter()));
                                    }, 1000);
                                  }
                                }
                                modalProps.closeModal();
                              }}
                            />
                          )}
                        </ModalGridContext.Consumer>
                      </DataGridWithModal>
                    )}
                  </Field>


                  <div className="sub-header-title">
                    <Label>3.2 {t('standardmail')}</Label>
                  </div>
                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Label required={false}>{t('standardmail')}</Label>
                        <Field name="standardMail">
                          {({ input, meta }: any) => (
                            <SearchableDropdown
                              onChange={(item: any) => {
                                if (item) {
                                  const selectedItem = {
                                    value: item.value,
                                    label: item.label,
                                  };
                                  input.onChange(selectedItem);
                                  form.mutators.setStandardMailId(item.value);
                                } else {
                                  input.onChange(null);
                                  form.mutators.setStandardMailId(null);
                                }
                              }}
                              selectedOption={values?.standardMail}
                              promiseOptions={standardMailPromiseOptions}
                              validationMessage={''}
                              required={false}
                              disabled={false}
                            />
                          )}
                        </Field>
                        <Field
                          name="standardMailId"
                          component={'input'}
                          type={'text'}
                          disabled={true}
                          hidden
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>

                      </div>
                    </Stack.Item>
                  </Stack>
                  {rendercardFooter(form)}
                </div>

                <Field name="lotDocumentation">
                  {({ input, meta }) => (
                    <LotDocumentationCard
                      disabled={false}
                      documentation={values.lotDocumentation}
                      onChange={(item: any) => {
                        input.onChange(item);
                      }}
                      values={values}
                      footer={rendercardFooter(form)}
                      readOnly={!values?.sequenceId}
                    />
                  )}
                </Field>

                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="technical-documentation">
                      <Label>5. {t('technicalDocumentation')}</Label>
                    </Link>
                  </div>
                  <Field name="technicalDocumentation">
                    {({ input }) => (
                      <DataGridWithModal
                        dataList={values.technicalDocumentation?.technicalDocList && Array.isArray(values.technicalDocumentation.technicalDocList) ? values.technicalDocumentation.technicalDocList : []}
                        modalTitle={t('technicalDocumentation')}
                        deleteDataGrid={(selection: any) => {
                          let technicalDocumentation = [];
                          const selectedList = selection.map((item: any) => {
                            return item;
                          });
                          const currentList: any[] = values.technicalDocumentation?.technicalDocList;

                          const filtered: any[] = currentList.filter((e: any) => {
                            return selectedList.indexOf(e) < 0;
                          });
                          technicalDocumentation = { ...values.technicalDocumentation, technicalDocList: filtered };
                          input.onChange(technicalDocumentation);
                          dispatch(saveFormData({ ...values, technicalDocumentation: technicalDocumentation }));
                        }}
                        title={`5.1 ${t('technicalDocumentation')}`}
                        readOnly={!!!values?.sequenceId}
                        columns={documentationColumns()}
                        isLoaded={false}
                        keyProp={'id'}
                        onDismiss={() => dispatch(saveContractStateAttr('uploadedInstructionLink', null))}
                      >
                        <ModalGridContext.Consumer>
                          {(modalProps: any) => (
                            <DocumentationModalContent
                              documentCategory={'technicalDocumentation'}
                              documentation={modalProps.editItem}
                              disabled={false}
                              onChange={(item: any) => {
                                if (values.technicalDocumentation && values.technicalDocumentation?.technicalDocList && Array.isArray(values.technicalDocumentation?.technicalDocList)) {
                                  const technicalDocList: any[] = [];
                                  const currentList: any[] = values.technicalDocumentation?.technicalDocList;
                                  let technicalDocumentation = [];
                                  if (item.lotId) {
                                    const index = currentList.findIndex(object => {
                                      return object.id === item.id;
                                    });
                                    if (index < currentList.length) {
                                      currentList[index] = item;
                                    }
                                    technicalDocumentation = {
                                      ...values.technicalDocumentation,
                                      technicalDocList: currentList,
                                    };
                                    input.onChange(technicalDocumentation);
                                    modalProps.updateEditItem(item);
                                  } else {
                                    technicalDocList.push(item);
                                    currentList.forEach((listItems: any) => {
                                      technicalDocList.push(listItems);
                                    });
                                    technicalDocumentation = {
                                      ...values.technicalDocumentation,
                                      technicalDocList: technicalDocList,
                                    };
                                    input.onChange(technicalDocumentation);
                                  }
                                  if (values.id) {
                                    dispatch(saveFormData({
                                      ...values,
                                      technicalDocumentation: technicalDocumentation,
                                    }));
                                  }
                                }
                                modalProps.closeModal();
                              }}
                            />
                          )}
                        </ModalGridContext.Consumer>
                      </DataGridWithModal>
                    )}
                  </Field>
                </div>

                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="publish-tender">
                      <Label>6. {t('publishTender')}</Label>
                    </Link>
                  </div>

                  <Field name="publishTender">
                    {({ input, meta }) => (
                      <DetailsList
                        items={values?.publishTender}
                        columns={publishTenderColumns()}
                        isHeaderVisible={false}
                        checkboxVisibility={2}
                        disableSelectionZone={true}
                      />
                    )}
                  </Field>


                  <div className={'card-footer'}>
                    <Stack
                      styles={stackStyles}
                      tokens={itemAlignmentsStackTokens}
                    >
                      <Stack.Item
                        align="end"
                        styles={stackFooterBarItemStyles}
                      >
                        <PrimaryButton
                          text={t('publishTender')}
                          allowDisabledFocus
                          onClick={() => {
                            handlePublishTenderBtnClick(values);
                          }}
                          disabled={handlePublishTenderBtnDisabled(values?.isInviteSend)}
                        />
                      </Stack.Item>
                    </Stack>
                  </div>
                </div>

                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="tender-awarding">
                      <Label>7. {t('tenderAwarding')}</Label>
                    </Link>
                  </div>

                  <Field name="tenderAwarding">
                    {({ input, meta }) => (
                      <ShimmeredDetailsList
                        items={values.tenderAwarding && Array.isArray(values.tenderAwarding) ? values.tenderAwarding : []}
                        columns={awardColumns(values?.statusId)}
                        setKey="set"
                        selectionMode={values?.statusId === Status.In_Execution ? SelectionMode.none : SelectionMode.single}
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionPreservedOnEmptyClick={false}
                        checkboxVisibility={0}
                        selection={_tenderSelection}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="Row checkbox"
                        enableShimmer={enableAwardShimmer}
                      />
                    )}
                  </Field>

                  <div className={'card-footer'}>
                    <Separator/>
                    <Stack
                      styles={stackStyles}
                      tokens={itemAlignmentsStackTokens}
                    >
                      <Stack.Item
                        align="end"
                        styles={stackFooterBarItemStyles}
                      >
                        <PrimaryButton
                          text={t('awardTender')}
                          allowDisabledFocus
                          onClick={() => {
                            handleAwardTenderBtn();
                          }}
                          disabled={handleAwardTenderBtnDisabled()}
                        />
                      </Stack.Item>
                    </Stack>
                  </div>
                </div>

                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="timetable-lot">
                      <Label>8. {t('timetableLot')}</Label>
                    </Link>
                  </div>
                  <Field name="timetableLot">
                    {({ input, meta }) => (
                      <DetailsList
                        items={formData?.timeTable ? formData.timeTable : []}
                        columns={timeTableColumns()}
                        isHeaderVisible={true}
                        checkboxVisibility={2}
                        disableSelectionZone={true}
                      />
                    )}
                  </Field>
                </div>

                {
                  formData.historyLog &&
                  formData.historyLog.createdDateTime && (
                    <div className="proj-detail-block" id="3">
                      <div className="document-pane-card">
                        <div className="marginTop marginBottom">
                          <Link href="#" id="history">
                            <Label>9. {t('history')} </Label>
                          </Link>

                          <HistoryBar
                            createdByUser={
                              formData.historyLog.createdBy
                                ? formData.historyLog.createdBy
                                : ''
                            }
                            updatedByUser={
                              formData.historyLog.modifiedBy
                                ? formData.historyLog.modifiedBy
                                : ''
                            }
                            createdDateTime={
                              formData.historyLog.createdDateTime
                                ? formData.historyLog.createdDateTime
                                : ''
                            }
                            updatedDateTime={
                              formData.historyLog.modifiedDateTime
                                ? formData.historyLog.modifiedDateTime
                                : ''
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )
                }
                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};
export default DocumentPaneComponent;


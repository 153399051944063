import React, {Component} from 'react';
import {connect} from 'react-redux';
import history from '../../../history';
import ListPaneComponent from './component';
import {withRouter} from 'react-router';
import {WithTranslation, withTranslation} from 'react-i18next';
import {readDayPlanningDropDownData, setSelectedGridType,} from '../../../reducers/dayPlanningReducer';
import {messageService} from '../../../services/messageService';
import {
  DayPlanning,
  DayPlanningListItem,
  DPLANDropDowns,
  formData,
  SelectedGridType,
} from '../../../types/dayPlanning';
import {
  formatDateTimeToISOString,
  formatEndDateTimeToISOString,
  getContractingUnit,
  getProject,
  getTimeZone,
} from '../../../shared/util';
import moment from 'moment';
import {MyDayPlanningListPaneFilter} from '../../../types/myDayPlanning';

export interface State {
  redirect: boolean;
  isChange: boolean;
  loading: boolean;
  filter: MyDayPlanningListPaneFilter;
  announcedMessage: string;
  selectedListItemIds: [] | string[];
  selectedListItemSequenceCode: [] | string[];
  formData: DayPlanning;
  selectedListItems: DayPlanningListItem[];
}

export interface Props extends WithTranslation {
  listPaneItems: DayPlanningListItem[];
  reloadListPaneItem: () => void;
  handelFilter: (filter: MyDayPlanningListPaneFilter) => void;
  isDataLoaded?: boolean;
  isChange: boolean;
  filter: MyDayPlanningListPaneFilter;
  loadMsg: string;
  handleSelectedListPaneItem: (selectedItemIds: [] | string[]) => void;
  formData: DayPlanning;
  dplanDropDowns: DPLANDropDowns;
  readDayPlanningDropDownData: () => void;
  teamList: any
  reloadListPane: boolean;
  selectedGridType: SelectedGridType;
  setSelectedGridType: any;
  horizontalReSizer?: any;
}

class ListPane extends Component<Props, State> {

  subscription: any;
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedListItems: [],
      selectedListItemIds: [],
      selectedListItemSequenceCode: [],
      redirect: false,
      isChange: false,
      loading: false,
      announcedMessage: '',
      filter: new MyDayPlanningListPaneFilter(),
      formData: formData,
    };
  };

  componentWillUnmount() {
    this.subscription.unsubscribe();
  };

  componentDidMount() {
    this.props.setSelectedGridType(SelectedGridType.DAY)
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewPbs) {

        }
        if (data.data.resetListPaneSelection) {
          this.resetSelection();
        }
        if (data.data.reloadDayPlan) {

        }
      }
    });
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.isDataLoaded != this.props.isDataLoaded) {
      if (this.props.isDataLoaded) {
        this.resetSelection();
      }
    }
    if (prevProps.reloadListPane != this.props.reloadListPane) {
      if (this.props.reloadListPane) {

      }
    }
    if (prevProps.selectedGridType != this.props.selectedGridType) {
      if (this.props.selectedGridType) {

      }
    }
  };

  getFilterDate = (didMount?: boolean) => {
    var currentDate = moment();
    if (didMount) {
      return {
        startDate: formatDateTimeToISOString(new Date()),
        endDate: formatEndDateTimeToISOString(new Date()),
        localDate: moment().toISOString(),
        offSet: getTimeZone(),
        type: SelectedGridType.DAY
      }
    } else {
      return {
        startDate: this.props.selectedGridType === SelectedGridType.DAY ? formatDateTimeToISOString(new Date()) : currentDate.clone().startOf('week').isoWeekday(1),
        endDate: this.props.selectedGridType === SelectedGridType.DAY ? formatEndDateTimeToISOString(new Date()) : currentDate.clone().endOf('week'),
        localDate: moment().toISOString(),
        offSet: getTimeZone(),
        type: this.props.selectedGridType
      }
    }
  };

  handleListPaneItemClick = (id: string, project: string) => {
    //this.props.getById(id);
    if (id) {
      if (getProject()) {
        history.push(`/CU/${getContractingUnit()}/project/${getProject()}/day-planning/pmol/` + id);
      } else {
        history.push({
          pathname: `/CU/${getContractingUnit()}/day-planning/pmol/` + id,
          state: { selectedProject: project },
        });
      }
    }
  };

  onPmolClick = (pmolId: string | null) => {

  };

  handleSelectedItem = () => {
    this.props.handleSelectedListPaneItem(this.state.selectedListItemIds);
    if (this.state.selectedListItemSequenceCode) {
      let lastId = this.state.selectedListItemSequenceCode.pop();
      if (lastId) {
        this.handleListPaneItemClick(lastId, '');
      }
    }
  };

  handleFilterChange = (filter: MyDayPlanningListPaneFilter) => {
    this.props.handelFilter(filter);
  };

  handleFilterSortChange = () => {
    this.props.handelFilter(this.state.filter);
  };

  resetSelection = () => {
    if (
      this.state.formData &&
      this.state.formData.id &&
      this.props.isDataLoaded
    ) {

    } else {

    }
  };

  render() {
    return (
      <div>
        <ListPaneComponent
            listPaneItems={this.props.listPaneItems ? this.props.listPaneItems : []}
            handleListPaneItemClick={(id: string, project: string) => this.handleListPaneItemClick(id, project)}
            // formData={this.state.formData}
            filter={this.props.filter}
            loadMsg={this.props.loadMsg}
            handleFilterChange={(filter: MyDayPlanningListPaneFilter) => this.handleFilterChange(filter)}
            isDataLoaded={this.props.isDataLoaded}
            dropDownOptions={this.props.dplanDropDowns}
            horizontalReSizer={this.props.horizontalReSizer} selection={undefined} columns={[]}
            resetSelection={function (): void {
              throw new Error('Function not implemented.');
            }}        />
      </div>
    );
  }

}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    // toleranceStatesFilter:state.projectBreakdown.toleranceStatesFilter,
    // productStatesFilter: state.projectBreakdown.productStatesFilter,
    // itemTypesFilter: state.projectBreakdown.itemTypesFilter,
    isChange: state.dplan.isChange,
    listPaneItems: state.dplan.pmolListPaneItems,
    dplanDropDowns: state.dplan.dplanDropDowns,
    // teamList: state.dplan.teamList,
    reloadListPane: state.dplan.reloadListPane,
    selectedGridType: state.dplan.selectedGridType,
  };
};

const mapDispatchToProps = {
  readDayPlanningDropDownData,
  setSelectedGridType
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ListPane))
);

import * as React from 'react';
import { useEffect, useState } from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { messageService } from '../../../services/messageService';
import i18n from 'i18next';
import { useSelector } from 'react-redux';
import history from '../../../history';
import { getContractingUnit, getProject } from '../../../shared/util';
import { useSingleAndDoubleClick } from '../../../hooks/useSingleAndDoubleClick';

const WbsIssueDocumentPaneCommandBar = () => {
  const saveIssueFormData = useSelector((state: any) => state.wbsIssue.saveIssueFormData);

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    setIsDisabled(saveIssueFormData);
  }, [saveIssueFormData]);

  const getItems = () => {
    return [
      {
        key: 'new',
        text: i18n.t('new'),
        iconProps: { iconName: 'Add' },
        onClick: () => {
          history.push(`/CU/${getContractingUnit()}/project/${getProject()}/wbs/issue/new`);
        },
        disabled: false,
      },
      {
        key: 'save',
        text: i18n.t('save'),
        iconProps: { iconName: 'Save' },
        onClick: useSingleAndDoubleClick(
          () => messageService.sendMessage({ saveWbsIssue: true }),
          () => messageService.sendMessage({ saveWbsIssue: true })),
        disabled: false,
      },
    ];
  };

  return <div>
    <CommandBar
      styles={{ root: { height: 26 } }}
      items={getItems()}
      ariaLabel='Use left and right arrow keys to navigate between commands'
    />
  </div>;
};

export default WbsIssueDocumentPaneCommandBar;

import {
  ActionButton,
  Checkbox,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Separator,
  Stack,
} from '@fluentui/react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../theme';
import CustomFileUploader from '../../../shared/fileUploader/customFileUploader';
import { CKEditorWrapper, DatePickerAdapter, DropDownAdaptater, TextFieldFF } from '../../../shared/fluentFinalForm';
import PeoplePicker from '../../../shared/fluentFinalForm/PeoplePicker';
import { formatDateTimeToISOString, getImages, getProject } from '../../../shared/util';
import { WorkFlow, WorkFlowDropDowns } from '../../../types/workFlow';
import TaxonomyField from '../../warehouse/documentPane/taxonomy/taxonomy';
import { createForm } from 'final-form';
import QrCodeBar from '../../../shared/qrCode/component';
import HistoryBar from '../../../shared/historyBar/historyBar';
import ProjectHeader from '../../../shared/projectHeader/projectHeader';
import WorkFlowTasksGrid from './tasks/component';
import { IN_REVIEW_STATUS_ID, PENDING_DEVELOPMENT_STATUS_ID } from '../../../types/projectMolecule';
import FixedDecimalNumberFormatField from '../../../shared/fluentFinalForm/FixedDecimalNumberFormatField';

const theme = getTheme();

const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };

const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
});

const DocumentPaneComponent = (props: {
  projectHeader: any,
  submit: any,
  dropDowns: WorkFlowDropDowns,
  saveFormData: any,
  uid: any,
  formData: any,
  activityTaskSelection: any,
  isExpand: boolean,
  showProgress: boolean,
  formValues: any,
  isClear: boolean,
  setForm: (form: any) => void;
  setFormClearState: (isClear: boolean) => void;
  onChangePickedQuantity: (index: number, value: string | number) => void;
  onChangeComment: (index: number, value: string | number) => void;
  onChangeTotalPrice: (index: number, value: string | number) => void;
  onChangeStockAvailability: (index: number, value: string | number) => void;
  onChangeDestination: (destination: string) => void;
  onChangeSource: (destination: string) => void;
  isDisabled: boolean;
  tasks: any[];
  hasChangeIntialFromData: boolean
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      key: 'column1',
      name: t('cpcItem'),
      fieldName: 'date',
      minWidth: props.isExpand ? 200 : 100,
      maxWidth: props.isExpand ? 200 : 100,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('quantity'),
      fieldName: 'type',
      minWidth: props.isExpand ? 200 : 100,
      maxWidth: props.isExpand ? 200 : 100,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('mou'),
      fieldName: 'quantity',
      minWidth: props.isExpand ? 200 : 100,
      maxWidth: props.isExpand ? 200 : 100,
      isResizable: true,
    },
    {
      key: 'column4',
      name: t('destination'),
      fieldName: 'mou',
      minWidth: props.isExpand ? 100 : 100,
      maxWidth: props.isExpand ? 100 : 100,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('totalPrice'),
      fieldName: 'price',
      minWidth: props.isExpand ? 200 : 100,
      maxWidth: props.isExpand ? 200 : 100,
      isResizable: true,
    },
  ];


  const [state, setstate] = useState<WorkFlow | null>(null);
  const onSubmit = async (values: any) => {
    if (!values.id) {
      values.id = props.uid;
    }
    // if (!values.requiredDateAndTime) {
    //     values.requiredDateAndTime = new Date();
    // }
    // if (!values.executedDateAndTime) {
    //     values.executedDateAndTime = new Date();
    // }
    values.executedDateAndTime = formatDateTimeToISOString(values.executedDateAndTime);
    values.requiredDateAndTime = formatDateTimeToISOString(values.requiredDateAndTime);
    props.setFormClearState(false);
    const data = values;
    data.tasks = props.formData.tasks;
    props.saveFormData(data);
  };

  const getInitialFormValues = (formData: any, formValue: any) => {
    if (location.pathname.split('/').pop() === 'new' && props.isClear) {
      return {
        requiredDateAndTime: new Date(),
        executedDateAndTime: new Date(),
        isFinished: false,
      };
    } else if (formData && formData?.id && !props.hasChangeIntialFromData) {
      return {
        id: formData?.id,
        sequenceId: formData?.sequenceId,
        title: formData?.title,
        name: formData?.name,
        typeId: formData?.type?.key,
        resourceType: formData?.cpcType?.key,
        requesterId: formData?.requesterId,
        requester: formData?.requester,
        executorId: formData?.executorId,
        executor: formData?.executor,
        requiredDateAndTime: new Date(formData?.requestedDateAndTime),
        executedDateAndTime: formData?.executedDateAndTime ? new Date(formData?.executedDateAndTime) : null,
        effortEstimate: formData?.effortEstimate,
        effortCompleted: formData?.effortCompleted,
        status: formData?.status?.key,
        files: formData?.files,
        task: formData?.task,
        isFinished: formData?.isFinish,
        destination: formData?.destination,
        source: formData?.source,
        comment: formData?.comment,
      };
    }
    return formValue;
  };

  const isFinishedDisabled = (formValue: any) => {
    if (props.formData?.status?.key !== PENDING_DEVELOPMENT_STATUS_ID) {
      return true;
    }
    if (formValue?.executedDateAndTime && formValue?.executorId) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setstate(props.formData);
  }, [props.formData]);
  const formRef = React.useRef(createForm({ onSubmit: onSubmit }));
  return (
    <div style={{ width: '100%' }}>
      <div className="proj-detail-block">
        <Form
          form={formRef.current}
          onSubmit={onSubmit}
          initialValues={getInitialFormValues(props.formData, props.formValues)}


          mutators={{
            setSourceTaxonomyId: async (args, state, utils) => {
              const source = state.fields.source;
              if (args && args[0] && args[0].id) {
                source.change(args[0].id);
              } else {
                source.change(null);
              }
            },
            setDestinationTaxonomyId: async (args, state, utils) => {
              const destination = state.fields.destination;
              if (args && args[0] && args[0].id) {
                destination.change(args[0].id);
              } else {
                destination.change(null);
              }
            },
            setActivityStatus: async (args, state, utils) => {
              const status = state.fields.status;
              const executedDateAndTime = state.fields.executedDateAndTime;

              if (args && args[0]) {
                executedDateAndTime.change(new Date());
                status.change(IN_REVIEW_STATUS_ID);
              } else if (props.formData?.status?.key) {
                status.change(props.formData?.status?.key);
              } else {
                executedDateAndTime.change(new Date(props.formData?.executedDateAndTime));
                status.change(null);
              }
            },
          }}
          validate={values => {
            const errors: any = {};
            if (!values?.name && !props.showProgress) {
              errors.name = t('nameRequired');
            }
            if (!values?.typeId && !props.showProgress) {
              errors.typeId = t('typeRequired');
            }
            if (!values?.resourceType && !props.showProgress) {
              errors.resourceType = t('resourceTypeRequired');
            }
            return errors;
          }}
          render={({
                     handleSubmit,
                     form,
                     submitting,
                     pristine,
                     values,
                   }) => {
            props.submit(handleSubmit);
            props.setForm(form);
            return (
              <form onSubmit={handleSubmit} noValidate>

                <div className="proj-detail-block">
                  {getProject() && <ProjectHeader projectDetails={props.projectHeader}/>}
                </div>

                <div className="proj-detail-block" id="1">
                  <div className="document-pane-card">
                    <div className={'card-header'}>
                      <Link href="#" id="wf-header">
                        <Label>1. {t('workflowHeader')}</Label>
                      </Link>
                    </div>
                    {state?.sequenceId && (
                      <Stack
                        horizontal
                        wrap
                        styles={stackStyles}
                        tokens={stackTokens}
                      >
                        <Stack.Item grow={6} styles={stackItemStyles}>
                          <div className={`${classNames.fullWidth}`}>
                            <Field
                              name="title"
                              component={TextFieldFF}
                              lable={t('workflowTitle')}
                              disabled={true}
                            />
                          </div>
                        </Stack.Item>
                        <Stack.Item grow={6} styles={stackItemStyles}>
                          <div className={`${classNames.fullWidth}`}>
                            <Field
                              name="sequenceId"
                              component={TextFieldFF}
                              lable={t('workflowID')}
                              disabled={true}
                            />
                          </div>
                        </Stack.Item>
                      </Stack>
                    )}

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.halfWidth}`}>
                          <Field
                            name="name"
                            component={TextFieldFF}
                            lable={t('name')}
                            required={true}
                            disabled={props.isDisabled}
                          />
                        </div>
                      </Stack.Item>

                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="typeId"
                            component={DropDownAdaptater}
                            options={props.dropDowns.types}
                            lable={t('type')}
                            placeholder="Type"
                            disabled={true}
                            required={true}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="resourceType"
                            component={DropDownAdaptater}
                            options={props.dropDowns.resourceType}
                            lable="Resource Type"
                            placeholder="Type"
                            disabled={true}
                            required={true}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={{
                        childrenGap: 0,
                        padding: 0,
                      }}

                    >
                      <Stack.Item grow={6} styles={{
                        root: {
                          display: 'flex',
                          height: 100,
                          width: 0,
                          flexGrow: 1,
                        },
                      }}>
                        <div className={`${classNames.fullWidth}`}>

                          <div style={{ zIndex: 10000000 }}>
                            <Field
                              name="source"
                              component={'input'}
                              type={'hidden'}
                              disabled={props.isDisabled}
                              hidden
                            />
                            <TaxonomyField
                              label={t('source')}
                              treeData={props.dropDowns.warehouseTaxonomy}
                              selectItemId={formRef.current.getState().values?.source ? formRef.current.getState().values?.source : null}
                              onSelectItem={(id: string) => {
                                if (id) {
                                  if (id === formRef.current.getState().values?.source) {
                                    form.mutators.setSourceTaxonomyId(null);
                                  } else {
                                    form.mutators.setSourceTaxonomyId({ id: id });
                                    props.onChangeSource(id);
                                  }
                                } else {
                                  form.mutators.setSourceTaxonomyId(null);
                                }
                              }}
                              treeLevel={props.dropDowns.whTaxonomyLevel}
                              onFilterTaxonomyNodeChanged={() => {
                              }}
                              disabled={props.isDisabled}
                              isStock={true}
                            />
                          </div>
                        </div>
                      </Stack.Item>
                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={{ root: { ...stackStyles, marginTop: 20, marginBottom: 50 } }}
                      tokens={{
                        childrenGap: 0,
                        padding: 0,
                      }}
                    >
                      <Stack.Item grow={6} styles={{
                        root: {
                          display: 'flex',
                          height: 100,
                          width: 0,
                          flexGrow: 1,
                        },
                      }}>
                        <div className={`${classNames.fullWidth}`}>

                          <div style={{ zIndex: 10000000 }}>
                            <Field
                              name="destination"
                              component={'input'}
                              type={'hidden'}
                              disabled={props.isDisabled}
                              hidden
                            />
                            <TaxonomyField
                              label={t('destination')}
                              treeData={props.dropDowns.warehouseTaxonomy}
                              selectItemId={formRef.current.getState().values?.destination ? formRef.current.getState().values?.destination : null}
                              onSelectItem={(id: string) => {
                                if (id) {
                                  if (id === formRef.current.getState().values?.destination) {
                                    form.mutators.setDestinationTaxonomyId(null);
                                  } else {
                                    form.mutators.setDestinationTaxonomyId({ id: id });
                                    props.onChangeDestination(id);
                                  }
                                } else {
                                  form.mutators.setDestinationTaxonomyId(null);
                                }
                              }}
                              treeLevel={props.dropDowns.whTaxonomyLevel}
                              onFilterTaxonomyNodeChanged={() => {
                              }}
                              disabled={props.isDisabled}
                              isStock={true}
                            />
                          </div>
                        </div>
                      </Stack.Item>
                    </Stack>

                    {values.sequenceId &&
                    <QrCodeBar
                      name={values.sequenceId}
                      value={values.sequenceId}
                    />
                    }

                    <div className={`${classNames.fullWidth}`} style={{ marginTop: 30 }}>
                      <Field name="files">
                        {({ input, meta }) => (
                          <CustomFileUploader
                            uploadedImages={values?.files ? getImages(values?.files) : []}
                            label={t('documents')}
                            uploadUrl={'PO/UploadPoDocuments'}
                            onSaveSuccess={(response: any) => {
                              if (values?.files && _.isArray(values.files)) {
                                const files = values.files;
                                files.push(response);
                                input.onChange(files);
                              } else {
                                const files = [];
                                files.push(response);
                                input.onChange(files);
                              }
                            }}
                            disable={props.isDisabled}
                          />
                        )}
                      </Field>
                    </div>


                    <div className={'card-footer'}>
                      <Separator/>
                      <Stack
                        styles={stackStyles}
                        tokens={itemAlignmentsStackTokens}
                      >
                        <Stack.Item
                          align="end"
                          styles={stackFooterBarItemStyles}
                        >
                          <ActionButton
                            className={classNames.actionButton}
                            iconProps={saveIcon}
                            allowDisabledFocus
                            onClick={() => {
                              form.submit();
                            }}
                            disabled={props.isDisabled}
                          >
                            <Label className={classNames.actionButton}>
                              {t('save')}
                            </Label>
                          </ActionButton>
                        </Stack.Item>
                      </Stack>
                    </div>
                  </div>
                </div>

                <div className="proj-detail-block" id="2">
                  <div className="document-pane-card">
                    <div className={'card-header'}>
                      <Link href="#" id="wf-activity">
                        <Label>2. {t('workflowActivities')}</Label>
                      </Link>

                    </div>
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}
                             style={{ maxHeight: 100, position: 'relative', height: 100 }}>
                          <Field name="requesterId">
                            {({ input, meta }) => (
                              <PeoplePicker
                                label={t('requester')}
                                isDisabled={props.isDisabled}
                                selectedItem={values?.requesterId ? [{
                                  key: values?.requesterId,
                                  text: values?.requester,
                                }] : []}
                                onChange={(items: any) => {
                                  input.onChange;
                                  return items;
                                }}
                                onPersonSelected={(item: any) => {
                                  input.onChange(item.key);
                                  return item;
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}
                             style={{ maxHeight: 100, position: 'relative', height: 100 }}>
                          <Field name="executorId">
                            {({ input, meta }) => (
                              <PeoplePicker
                                label={t('executor')}
                                isDisabled={props.isDisabled}
                                selectedItem={values?.executorId ? [{
                                  key: values?.executorId,
                                  text: values?.executor,
                                }] : []}
                                onChange={(items: any) => {
                                  if (!items) {
                                    input.onChange(null);
                                  }
                                  input.onChange;
                                  return items;
                                }}
                                onPersonSelected={(item: any) => {
                                  input.onChange(item.key);
                                  return item;
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      </Stack.Item>
                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="requiredDateAndTime"
                            component={DatePickerAdapter}
                            lable={t('requestedDateTime')}
                            // disabled={ projectDisabled }
                            parse={value => value && formatDateTimeToISOString(value)}
                            format={value => (value ? new Date(value) : null)}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="executedDateAndTime"
                            component={DatePickerAdapter}
                            options={props.dropDowns.types}
                            lable={t('executeDateTime')}
                            disabled={props.isDisabled}
                            parse={value => value && formatDateTimeToISOString(value)}
                            format={value => (value ? new Date(value) : null)}
                          />
                        </div>
                      </Stack.Item>

                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="effortEstimate"
                            component={FixedDecimalNumberFormatField}
                            lable={t('effortEstimated')}
                            // disabled={ projectDisabled }
                            disabled={props.isDisabled}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="effortCompleted"
                            component={FixedDecimalNumberFormatField}
                            lable={t('effortCompleted')}
                            disabled={props.isDisabled}
                          />
                        </div>
                      </Stack.Item>

                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="status"
                            component={DropDownAdaptater}
                            options={props.dropDowns.activityStatus}
                            lable={t('status')}
                            placeholder={t('status')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                      { /* <Stack.Item grow={ 6 } styles={ stackItemStyles }>
                                                <div className={ `${classNames.fullWidth}` } style={ { marginTop: 30 } }>
                                                    <Field
                                                        disabled={ props.formData?.status?.key !== 'd60aad0b-2e84-482b-ad25-618d80d49477' }
                                                        name="isFinished"
                                                        component={ CheckBoxWrapper }
                                                        label={ t('finished') }
                                                        checked={ props.formData.isFinish }
                                                        type="checkbox"
                                                        value={ formRef.current.getState().values?.isFinished }
                                                    />
                                                    <OnChange name="isFinished">
                                                        { (value, previous) => {
                                                            form.mutators.setActivityStatus(value);
                                                        } }
                                                    </OnChange>
                                                </div>
                                            </Stack.Item> */}
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`} style={{ marginTop: 30 }}>
                          <Field name="isFinished">
                            {({ input, meta }) => (
                              <Checkbox
                                label={t('finished')}
                                disabled={isFinishedDisabled(formRef.current.getState().values)}
                                checked={formRef.current.getState().values?.isFinished}
                                onChange={(event, value) => {
                                  input.onChange(value);
                                }}
                              />
                            )}
                          </Field>
                          <OnChange name="isFinished">
                            {(value, previous) => {
                              form.mutators.setActivityStatus(value);
                            }}
                          </OnChange>
                        </div>
                      </Stack.Item>

                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Label>{t('comments')}</Label>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="comment"
                          component={CKEditorWrapper}
                          lable={t('comment')}
                          disabled={false}
                        />
                      </div>
                    </Stack>
                  </div>

                </div>

                <div className="proj-detail-block" id="3">
                  <div className={`wrapper-holder ${classNames.wrapper}`}>
                    <WorkFlowTasksGrid
                      dataList={props.tasks}
                      title={t('workflowTasks')}
                      link={'wf-grid'}
                      id={'3'}
                      isLoaded={false}
                      selection={props.activityTaskSelection}
                      isExpand={props.isExpand}
                      onChangePickedQuantity={
                        props.onChangePickedQuantity
                      }
                      onChangeComment={
                        props.onChangeComment
                      }
                      onChangeTotalPrice={
                        props.onChangeTotalPrice
                      }
                      onChangeStockAvailability={
                        props.onChangeStockAvailability
                      }
                      taxanomyData={props.dropDowns}
                      source={props.formData?.source}
                      disabled={props.isDisabled}
                      typeId={props.formData.type?.key}
                    />
                  </div>
                </div>


                {
                  props.formData.history &&
                  props.formData.history.createdDate && (
                    <div className="proj-detail-block" id="3">
                      <div className="document-pane-card">
                        <div className="marginTop marginBottom">
                          <Link href="#" id="history">
                            <Label>4. {t('history')} </Label>
                          </Link>

                          <HistoryBar
                            createdByUser={
                              props.formData.history.createdBy
                                ? props.formData.history.createdBy
                                : ''
                            }
                            updatedByUser={
                              props.formData.history.modifiedBy
                                ? props.formData.history.modifiedBy
                                : ''
                            }
                            createdDateTime={
                              props.formData.history.createdDate
                                ? props.formData.history.createdDate
                                : ''
                            }
                            updatedDateTime={
                              props.formData.history.modifiedDate
                                ? props.formData.history.modifiedDate
                                : ''
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}

                { /* <pre>{ JSON.stringify(values, 0, 2) }</pre> */}

                { /* <pre>{ JSON.stringify(values, 0, 2) }</pre> */}
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};
export default DocumentPaneComponent;


import { initializeIcons } from '@uifabric/icons';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';
import { ProjectListPaneFilter, ProjectShortCutPaneItem } from '../../../types/project';
import { ShortCutPaneComponent } from './component';

initializeIcons();

interface Props extends WithTranslation {
  shortCutPaneFilters: ProjectShortCutPaneItem[];
  handelShortCutPaneFilter: (selectedItem: ProjectShortCutPaneItem) => void;
  filter: ProjectListPaneFilter;
  projectId: string | null;
  toggleOverlay: boolean;
  resetLayout: boolean
  //isExpand: boolean;
}

interface State {
  divClass: string;
  selectedCodeType: number;
  currentActiveSection: string | null;
  isCollapse: boolean;
  showNewButton: boolean;
}

class ShortCutPane extends React.Component<Props, State> {
  subscription: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      divClass: 'filter-panel-left',
      selectedCodeType: -1,
      currentActiveSection: null,
      isCollapse: false,
      showNewButton: true,
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.toggleOverlay !== prevProps.toggleOverlay) {
      if (this.props.toggleOverlay) {
        this.setState({ divClass: 'filter-panel-left panel-move' });
      } else {
        this.setState({ divClass: 'filter-panel-left' });
      }
    }
  }


  private handelShortCutPaneFilter = (selectedItem: ProjectShortCutPaneItem) => {
    this.setState({
      currentActiveSection: selectedItem.key,
    });
  };

  private resetFilter = () => {
    let filterItem: ProjectShortCutPaneItem = {
      key: null,
      id: null,
      type: null,
      name: 'All',
      value: null,
    };
    this.setState({
      currentActiveSection: null,
    });

    this.props.handelShortCutPaneFilter(filterItem);
  };

//   componentDidMount() {
//     this.subscription = messageService.getMessage().subscribe((data: any) => {
//         if (data) {
//             if (data && data.data && data.data.shortCutPaneExpanded) {
//                 this.setState({ showNewButton: true });
//             } else {
//                 this.setState({ showNewButton: false });
//             }
//         }
//     });
// }

  componentDidMount() {
    if (this.state.divClass === 'filter-panel-left') {
      if (window.innerWidth > 1300) {
        messageService.sendMessage({ shortCutPaneExpanded: true });
      }
    } else {
      messageService.sendMessage({ shortCutPaneExpanded: false });
    }
  }

  public render(): JSX.Element {
    return (
      <ShortCutPaneComponent
        currentActiveSection={this.state.currentActiveSection}
        toggleOverlay={this._toggleOverlay}
        toggleOverlayFwd={this._toggleOverlayFwd}
        handelShortCutPaneFilter={(selectedItem: ProjectShortCutPaneItem) =>
          this.handelShortCutPaneFilter(selectedItem)
        }
        resetFilter={() => this.resetFilter()}
        divClass={this.state.divClass}
      />
    );
  }

  private _toggleOverlay = (): void => {
    this.setState({ divClass: 'filter-panel-left panel-move', isCollapse: true });
    messageService.sendMessage({ shortCutPaneExpanded: false });
  };
  private _toggleOverlayFwd = (): void => {
    this.setState({ divClass: 'filter-panel-left', isCollapse: false });
    messageService.sendMessage({ shortCutPaneExpanded: true });
  };
}

export default withTranslation()(ShortCutPane);

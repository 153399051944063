import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import {array, string} from "prop-types";
// if using cdn version, 'Msal' will be available in the global scope

import ENV from "../../env.json";
import {msalInstance} from "../../index";
import {loginRequest} from "./authConfig";

export interface  AccountInfo{
  homeAccountId: string;
  environment: string;
  tenantId: string;
  username: string;
  localAccountId: string;
  name?: string;
  idTokenClaims?: object;
};

export default class AuthService {

    login = () => {
          return false
    };


    getUser = () => {
        let account=msalInstance.getActiveAccount()as AccountInfo;
        console.log({account});
        return account
    };

    checkIsLogged = () => {
      const allAccounts = msalInstance.getAllAccounts();
        if (allAccounts.length > 0) {
            //localStorage.setItem('user_logged', 'true');
            return true;
        } else {
            return false;
        }
    };

    clrLocalStorageExceptSomeItems = () => {
        const teamPlanboardTimeZoom = localStorage.getItem('teamPlanboardTimeZoom');
        const projectPlanboardTimeZoom = localStorage.getItem('projectPlanboardTimeZoom');
        const TpPmolDefaultProject = localStorage.getItem('TpPmolDefaultProject');
        const PpPmolDefaultProject = localStorage.getItem('PpPmolDefaultProject');
        localStorage.clear();
        localStorage.setItem('teamPlanboardTimeZoom', teamPlanboardTimeZoom?teamPlanboardTimeZoom:'');
        localStorage.setItem('projectPlanboardTimeZoom', projectPlanboardTimeZoom?projectPlanboardTimeZoom:'');
        localStorage.setItem('TpPmolDefaultProject', TpPmolDefaultProject?TpPmolDefaultProject:'');
        localStorage.setItem('PpPmolDefaultProject', PpPmolDefaultProject?PpPmolDefaultProject:'');
    }    

    isLogoutPopupOpen = false;
    logout = () => {
        if(!this.isLogoutPopupOpen) {
            this.isLogoutPopupOpen = true;
            this.clrLocalStorageExceptSomeItems();
            msalInstance.logoutPopup().catch(e => {
                console.error(e);
            }).finally(() => {
                this.isLogoutPopupOpen = false;
            })
        }
    };


    getToken = async () => {

        const account = msalInstance.getActiveAccount();
        if(account){
            await msalInstance.acquireTokenSilent({
                ...loginRequest,
                account: account
            }).then((response)=>{
                localStorage.setItem("accessToken", response.accessToken);
                return response.accessToken
            }).catch(()=>{
              this.logout();
            });
        }else{
            this.logout();
        }
        return null
    };
}

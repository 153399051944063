import {useTranslation} from 'react-i18next';
import {
  ContextualMenu,
  FontWeights,
  getTheme,
  IconButton,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  ITextField,
  Label,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';
import React, {useEffect, useRef, useState} from 'react';
import {useBoolean, useId} from '@uifabric/react-hooks';
import {Resource} from '../../../../../types/projectBreakdownStructure';
import {uPrinceTheme} from '../../../../../../theme';
import i18n from '../../../../../../i18n';
import GroupDropdown from '../../../../../shared/groupDropdown/groupDropdown';

const theme = getTheme();

const classNames = mergeStyleSets({
  wrapper: {
    //height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: {
    maxWidth: 400,
  },
  textContent: {
    padding: '15px 10px',
  },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: {
    width: '100%',
    paddingBottom:10
  },
});

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 20,
    flexGrow: 1,
    width: 0,
  },
};


const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '32vw',
    minHeight: screen.width > 1280?'42vh':'60vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

class ResourcesValidationMessages {
  cpcValidationMsg: string = '';
  quantityValidationMsg: string = '';
}

const ResourceModal = (props: {
  isOpen: boolean;
  openResourceModal: () => void;
  clearCpcData: () => void;
  isEdit: boolean;
  onFilterResourceChanged: any;
  createResource: (resource: Resource, projectSequenceCode? : any) => void;
  resource: Resource;
  title: string;
  pbsID: string | null;
  cpcOptions:any;
  feild:string|null;
  projectSequenceCode?: string | null;
}) => {
  const { t } = useTranslation();
  const [isDraggable] = useBoolean(false);
  const titleId = useId('title');
  const quantityRef = useRef<ITextField>(null);

  const [resource, setResource] = useState(new Resource());
  const [ cpc, setCpc ]: any = useState(props.resource && props.resource.cpcKey ? { key: props.resource.cpcKey, name: props.resource.cpcText, environment: props.resource.environment } : null);
  // const [cpc, setCPC] = useState<ITag[]>([]);
  const [, setHasEdit] = useState(false);
  const [resourceValidationMessages, setResourceValidationMsg] = useState<
    ResourcesValidationMessages
  >(new ResourcesValidationMessages());
  const [disableSave, setDisableSave] = useState(true);

  const onChangeInput = (field: string) => (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if(newValue){
      setResource((prevState) => ({
        ...prevState,
        [field]: newValue,
      }));
    }else{
      setResource((prevState) => ({
        ...prevState,
        [field]: "0",
      }));
    }
    setHasEdit(true);
    validaErrorOnvalueChange(field );
    if(field === 'quantity' && resource.pbsProductId && newValue){
      setDisableSave(false);
    }else if(!resource.quantity || !resource.resourceId){
      setDisableSave(true);
    }
  };

  const handleSaveBtnDisable=(isDisable=false) => {
    if(resource.pbsProductId && resource.quantity){
      setDisableSave(false);
    }else if(!resource.quantity || !resource.resourceId){
      setDisableSave(true);
    }
    if(isDisable){
      setDisableSave(true);
    }
  };
  i18n.t('suggestedCPC');
  i18n.t('suggestedCPC');
  i18n.t('noresultsfound');
  i18n.t('loading');
  i18n.t('cpcSuggestionsavailable');
  i18n.t('suggestedCPC');
// let selectedPerson: ITag[] = [];

  // if (props.resource && props.resource) {
  //   selectedPerson = [
  //     {
  //       key: props.resource.cpcKey ? props.resource.cpcKey : '',
  //       name: props.resource.cpcText ? props.resource.cpcText : '',
  //     },
  //   ];
  // }

  const validaErrorOnvalueChange = (key: string) => {
    switch (key) {
      case 'name':
        validateResourceName(resource);
        break;
      case 'quantity':
        validateResourceQuantity(resource);
        break;
    }
  };

  useEffect(() => {
    if(!props.isEdit){
      setCpc(null);
    }
  },[props.isEdit]);

  useEffect(() => {
    if (props.isEdit) {
      if (props.resource && props.resource.cpcKey) {
        const resource = { key: props.resource.cpcKey, name: props.resource.cpcText, environment: props.resource.environment };
        setCpc(resource);
      }else if(props.resource.resourceId){
        const resource = { key: props.resource.resourceId, name: props.resource.cpcText, environment: props.resource.environment };
        setCpc(resource);
      }
      // console.log("IIIIIII",props.resource)
      setResource({
        resourceId: props.resource.resourceId,
        cpcTitle: props.resource.cpcText ? props.resource.cpcText : null,
        cpcKey: props.resource.cpcKey,
        cpcText:
          props.resource && props.resource.cpcText
            ? props.resource.cpcText
            : null,
        resourceFamilyTitle: props.resource.resourceFamilyTitle
          ? props.resource.resourceFamilyTitle
          : null,
        quantity: props.resource.quantity ? props.resource.quantity : 0,
        unit: props.resource.unit,
        sequenceCode: '',
        pbsProductId: props.pbsID,
        coperateProductCatalogId: props.resource.cpcKey,
        environment: null,
        consolidatedQuantity: props.resource.consolidatedQuantity
      });
      handleSaveBtnDisable(false)
      quantityRef.current?.focus()

    } else {
      // setCPC([]);
      setCpc(null);
      setResource(new Resource());
      setResourceValidationMsg(new ResourcesValidationMessages())
      handleSaveBtnDisable(true)
    }
  }, [props.resource]);

  // useEffect(() => {
  //   if (resource.quantity >= 0) {
  //     setRiskValidationState('quantityValidationMsg', '');
  //   }
  // },[resource])

  const validateResource = (resource: Resource) => {
    let isValidName = validateResourceName(resource);
    let isValidQuantity = validateResourceQuantity(resource)
    return isValidName && isValidQuantity;
  };

  const validateResourceQuantity = (resource: Resource) => {   //TODO: not validating for initial value
    let isValidName: boolean;
    // if (resource && resource.quantity && resource.quantity >= 0) {
      isValidName = true;
      setRiskValidationState('quantityValidationMsg', '');
    // } else {
    //   isValidName = false;
    //   setRiskValidationState('quantityValidationMsg', t('addValidQuantity'));
    // }
    return isValidName;
  };

  const validateResourceName = (resource: Resource) => {
    let isValidName: boolean;
    if (resource && resource.coperateProductCatalogId) {
      isValidName = true;
      setRiskValidationState('cpcValidationMsg', '');
    } else {
      isValidName = false;
      setRiskValidationState('cpcValidationMsg', t('nameRequired'));
    }
    return isValidName;
  };

  const setRiskValidationState = (key: string, msg: string) => {
    setResourceValidationMsg((prevState) => ({
      ...prevState,
      [key]: msg,
    }));
  };

  const onCpcSelected = (item: any) => {
    // console.log()
    if (item) {
      setResource((prevState) => ({
        ...prevState,
        coperateProductCatalogId: item.key,
        pbsProductId: props.pbsID,
        environment: item.environment,
      }));
      if(item){
        setDisableSave(false);
      }else if(!resource.quantity || !resource.resourceId){
        setDisableSave(true);
      }
    }
  };

  const onModalClose = () => {
    if(!props.isEdit){
      setCpc(null);
    }
    setResourceValidationMsg(new ResourcesValidationMessages());
    props.openResourceModal();
  }

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          onModalClose();
        }}
        scrollableContentClassName="body"
        isBlocking={true}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>{props.title}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              onModalClose();
            }}
          />
        </div>

        <div className={contentStyles.body}>
          <div className="proj-detail-content inner">
            <div className="ms-Grid-row">

              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <Label required={false}>{t('CPC')}</Label>
                    <GroupDropdown
                      autoFocus={props.feild === 'cpcTitle'}
                      onChange={(key: string, text: string,environment:string, item:any) => {
                        if(key){
                          //alert(JSON.stringify(key));
                          const resource={key:key,name:text,environment:environment, item}
                          setCpc(resource);
                          onCpcSelected(resource)
                        }else{
                          props.clearCpcData();
                          setCpc(null);
                          const resource={key:null,name:null,environment:null}
                          onCpcSelected(resource)
                        }
                        // alert(key);
                      }}
                      selectedOption={cpc ?{
                        value: cpc.key ,
                        label: cpc.name ,}:null}
                      promiseOptions={props.cpcOptions}
                      validationMessage={resourceValidationMessages.cpcValidationMsg}
                      required={true}
                      reference={1}
                    />
                  </div>
                </Stack.Item>
              </Stack>

              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      autoFocus={props.feild === 'quantity'}
                      label={t('quantity')}
                      value={
                        resource && resource.quantity
                          ? resource.quantity.toString()
                          : '0'
                      }
                      componentRef={quantityRef}
                      errorMessage={resourceValidationMessages.quantityValidationMsg}
                      type={'number'}
                      step={1}
                      min={0}
                      onChange={onChangeInput('quantity')}
                    />
                  </div>
                </Stack.Item>
              </Stack>

              { props.isEdit &&
              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                     disabled={true}
                      label={t('consolidatedQuantity')}
                      value={
                        resource && resource.consolidatedQuantity
                          ? resource.consolidatedQuantity.toString()
                          : '0'
                      }
                    />
                  </div>
                </Stack.Item>
              </Stack>
              }
            </div>
          </div>
            {/* <pre>{JSON.stringify(resource, 0, 2)}</pre> */}
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={addIconWhite}
            text={ t('save')}
            disabled={disableSave}
            style={{ marginTop: 20, marginBottom: 30 }}
            onClick={() => {
              if (validateResource(resource)) {
                props.createResource(resource, props?.projectSequenceCode);
                props.openResourceModal()
              }
            }}
          />

          {!props.isEdit && <PrimaryButton
              iconProps={addIconWhite}
              text={t('saveandNew')}
              style={{ marginTop: 20, marginBottom: 10, marginLeft:10}}
              onClick={() => {
                if (validateResource(resource)) {
                  props.createResource(resource, props?.projectSequenceCode);
                  setResource(new Resource());
                  // setCPC([]);
                  setCpc(null);
                  setDisableSave(true)

                }
              }}
              disabled={disableSave}
          />}
        </div>
      </Modal>
    </div>
  );
};

export default ResourceModal;

import * as React from 'react';
import { Shimmer, ShimmerElementType } from '@fluentui/react/lib/Shimmer';
import { Fabric } from '@fluentui/react/lib/Fabric';
import { mergeStyles } from '@fluentui/react/lib/Styling';

const wrapperClass = mergeStyles({
  padding: 2,
  selectors: { '& > .ms-Shimmer-container': { margin: '10px 0' } },
});

const shimmerTextArea = [
  { type: ShimmerElementType.line, height: 180, width: 60 },
];

const oneColumn = [
  { type: ShimmerElementType.line, height: 30, width: '49%' },
];

const twoColumn = [
  { type: ShimmerElementType.line, height: 30, width: '49%' },
  { type: ShimmerElementType.gap, width: '2%' },
  { type: ShimmerElementType.line, height: 30, width: '49%' },
];


export const QRCodeShimmer: React.FunctionComponent = () => {
  return (
    <Fabric className={wrapperClass}>
      <p style={{ height: 10 }}>&nbsp;</p>
      <Shimmer shimmerElements={oneColumn}/>
      <p style={{ height: 10 }}>&nbsp;</p>
      <p style={{ height: 10 }}>&nbsp;</p>
      <Shimmer shimmerElements={oneColumn}/>
      <p style={{ height: 10 }}>&nbsp;</p>
      <Shimmer shimmerElements={shimmerTextArea} style={{ width: 150, height: 150 }}/>
      <p style={{ height: 10 }}>&nbsp;</p>
      <Shimmer shimmerElements={twoColumn}/>
      <p style={{ height: 10 }}>&nbsp;</p>
      <Shimmer shimmerElements={twoColumn}/>


    </Fabric>
  );
};

import * as React from 'react';
import { useEffect, useState } from 'react';
import { createForm } from 'final-form';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Input from '../../../shared/fluentUI9/Input/Input';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { messageService } from '../../../services/messageService';
import { readWbsTaxonomyByTemId, saveNewWbsNode, saveWbsCreateStateAttr } from '../../../reducers/wbsCreateReducer';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import ProgressBar from '../../../shared/progressBar/progressBar';
import { useSingleAndDoubleClick } from '../../../hooks/useSingleAndDoubleClick';
import { getProject } from '../../../shared/util';
import { ParamTypes } from '../../../types/uPrince';
import { useParams } from 'react-router';

const CreateWbsDocumentPane = (props: { selectedItem: any, onSaveWBs?: any }) => {
  const { selectedItem } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { featureId }: ParamTypes = useParams();

  const showWbsProgressBar = useSelector((state: any) => state.wbsCreate.showWbsProgressBar);
  const isNodeCreated = useSelector((state: any) => state.wbsCreate.isNodeCreated);

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    setIsDisabled(false);

    return () => {
      dispatch(saveWbsCreateStateAttr('isNodeCreated', false));
    };
  }, []);

  useEffect(() => {
    const setDisabled = async () => {
      setIsDisabled(isNodeCreated);
    };

    setDisabled().then();
  }, [isNodeCreated]);

  const onSubmit = async (values: any) => {
    const formData = {
      ...values,
      id: uuidv4(),
    };

    Promise.all([dispatch(saveNewWbsNode(formData))]).then((res: any) => {
      // console.log({ res });
      if (res[0]?.payload?.data?.result) {
        props?.onSaveWBs(values);
        if (getProject()) {
          messageService.sendMessage({ reloadWbsListPane: true });
        } else {
          dispatch(readWbsTaxonomyByTemId(featureId));
        }
      }
    });
  };

  const getInitialFormValues = () => {
    return {
      id: null,
      templateId: selectedItem?.templateId,
      title: null,
      wbsTaxonomyLevelId: selectedItem?.wbsTaxonomyLevelId,
      parentId: selectedItem?.id,
      isDefault: false,

    };
  };

  const formRef = React.useRef(createForm({ onSubmit: onSubmit }));

  useEffect(() => {
    formRef.current.reset(getInitialFormValues());
  }, [selectedItem]);

  const getItems = () => {
    return [{
      key: 'save',
      text: t('save'),
      iconProps: { iconName: 'Save' },
      onClick: useSingleAndDoubleClick(
        () => formRef.current.submit(),
        () => alert('double'),
      ),
      disabled: isDisabled,
    }];
  };

  return (
    <div style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
      <div className={'wbs__command__bar'}>
        <CommandBar
          styles={{ root: { height: 26 } }}
          items={getItems()}
          ariaLabel='Use left and right arrow keys to navigate between commands'
        />
        <ProgressBar show={showWbsProgressBar} />
      </div>
      <div className={'wbs__form-container'}>
        <Form
          onSubmit={onSubmit}
          initialValues={getInitialFormValues()}
          validate={values => {
            const errors: any = {};
            if (!values?.title) {
              errors.title = t('titleRequired');
            }
            return errors;
          }}
          render={({
                     handleSubmit,
                     form,
                     submitting,
                     pristine,
                     values,
                   }) => {
            formRef.current = form;
            return (
              <form style={{ height: '100%', width: '100%' }} onSubmit={handleSubmit} noValidate>
                <div className={'wbs__card'} style={{ paddingBottom: 30 }}>
                  <Field name='title'>
                    {({ input, meta }) => (
                      <Input
                        onChange={(value: any) => {
                          console.log('onChange title value >>> ', value);
                          input.onChange(value);
                        }}
                        placeholder={t('title')}
                        appearance='underline'
                        value={values?.title}
                        errorMessage={meta.touched && meta.error && !meta.submitting ? meta.error : ''}
                        focus={true}
                      />
                    )}
                  </Field>
                  {/*<pre> {JSON.stringify(values, null, 2)}</pre>*/}
                </div>
              </form>
            );
          }}
        />
      </div>

    </div>
  );

};

export default CreateWbsDocumentPane;

import React, { createContext, useState, useEffect } from 'react';

const ExpandContext = createContext<any>({});

export const ExpandProvider: any = ({ children }: any) => {
  const [isExpand, setExpand] = useState(true);
  const [isApplyExpandContext, setIsApplyExpandContext] = useState(false);

  return (
    <ExpandContext.Provider value={{ isExpand, setExpand, isApplyExpandContext, setIsApplyExpandContext }}>
      {children}
    </ExpandContext.Provider>
  );
};

export default ExpandContext;

import React from 'react';
import { DatePicker, DayOfWeek } from '@fluentui/react';
import { withTranslation } from 'react-i18next';
import { iconColors } from '../../../theme';
import { Icon } from '@fluentui/react/lib/Icon';

class CustomDatePicker extends React.Component<any, any> {
  dayPickerStrings: any = {
    fr: {
      months: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ],
      shortMonths: [
        'Jan',
        'Fév',
        'Mar',
        'Avr',
        'Mai',
        'Jun',
        'Jul',
        'Aoû',
        'Sep',
        'Oct',
        'Nov',
        'Déc',
      ],
      days: [
        'Dimanche',
        'Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi',
      ],
      shortDays: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      goToToday: "Aujourd'hui",
      prevMonthAriaLabel: 'Mois précédent',
      nextMonthAriaLabel: 'Mois suivant',
      prevYearAriaLabel: 'Année précédente',
      nextYearAriaLabel: 'Année suivante',
      closeButtonAriaLabel: 'Fermer le sélecteur de date',
    },
    nl: {
      months: [
        'Januari',
        'Februari',
        'Maart',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Augustus',
        'September',
        'Oktober',
        'November',
        'December',
      ],
      shortMonths: [
        'Jan',
        'Feb',
        'Mrt',
        'Apr',
        'Mei',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dec',
      ],
      days: [
        'Zondag',
        'Maandag',
        'Dinsdag',
        'Woensdag',
        'Donderdag',
        'Vrijdag',
        'Zaterdag',
      ],
      shortDays: ['Z', 'M', 'D', 'W', 'D', 'V', 'Z'],
      goToToday: 'Vandaag',
      prevMonthAriaLabel: 'Vorige maand',
      nextMonthAriaLabel: 'Volgende maand',
      prevYearAriaLabel: 'Vorig jaar',
      nextYearAriaLabel: 'Volgend jaar',
      closeButtonAriaLabel: 'Sluit de datumkiezer',
    },
    tr:{
      months: [
        'ocak',
        'şubat',
        'mart',
        'nisan',
        'mayıs',
        'haziran',
        'temmuz',
        'ağustos',
        'eylül',
        'ekim',
        'kasım',
        'aralık',
      ],
      shortMonths: [
        'oca',
        'şub',
        'mar',
        'nis',
        'may',
        'haz',
        'tem',
        'ağu',
        'eyl',
        'eki',
        'kas',
        'ara',
      ],
      days: [
        'pazar',
        'pazartesi',
        'salı',
        'çarşamba',
        'perşembe',
        'cuma',
        'cumartesi',
      ],
      shortDays: ['P', 'Pz', 'S', 'Ç', 'P', 'C', 'Ct'],
      goToToday: 'Bugüne git',
      prevMonthAriaLabel: 'Önceki aya git',
      nextMonthAriaLabel: 'Sonraki aya git',
      prevYearAriaLabel: 'Önceki yıla git',
      nextYearAriaLabel: 'Sonraki yıla git',
    }
  };

  lang: any;

  public render(): JSX.Element {
    const { t } = this.props;
    const language = window.navigator.language;
    this.lang = localStorage.getItem('lang');
    //  const selectedDateFormat
    // let today=new Date();
    return (
      <DatePicker
        firstDayOfWeek={this.firstDayOfWeek(this.lang)}
        minDate={this.props.setMinDate}
        maxDate={this.props.setMaxDate}
        label={this.props.label}
        placeholder={t('selectadate')}
        ariaLabel={this.props.label}
        value={this.props.setValue ? this.props.setValue : null}
        onSelectDate={this.onSelectDate}
        disabled={this.props.disabled}
        textField={
          this.props.isClearable
            ? {
                required: false,
                inputClassName: {
                  paddingLeft: '34px',
                  paddingRight: '8px',
                } as any,
                onRenderSuffix: () => (
                  <Icon
                    onClick={() => {
                      this.props.onDateClear();
                      this.onClearDate();
                    }}
                    iconName="ChromeClose"
                    style={{ paddingRight: 4 }}
                  />
                ),
                styles: {
                  suffix: {
                    padding: '0 4px',
                    backgroundColor: 'white',
                    cursor: 'pointer',
                  },
                },
              }
            : undefined
        }
        styles={
          this.props.hasChanged
            ? {
                icon: { color: iconColors.iconActiveColor },
                root: { label: { color: iconColors.iconActiveColor } },
                textField: { color: iconColors.iconActiveColor },
              }
            : this.props.isClearable
            ? {
                icon: { left: '5px', right: 'unset' },
              }
            : {}
        }
        formatDate={this.onFormatDate}
        strings={this.dayPickerStrings[this.lang]}
      />
    );
  }
  private onFormatDate = (date?: Date): string => {
    return !date
      ? ''
      : date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  };

  private onSelectDate = (date: Date | null | undefined): void => {
    if (date) {
      let month = (date.getMonth() < 10 ? '0' : '') + (date.getMonth() + 1);
      let day = (date.getDate() < 10 ? '0' : '') + date.getDate();
      let formatDate =
        date.getFullYear() + '-' + month + '-' + day + 'T00:00:00.000Z';

      this.props.getValue(date);
    }
  };

  private onClearDate() {
    this.props.getValue(null);
  }

  private firstDayOfWeek(language: string): DayOfWeek | undefined {
    switch (language) {
      case 'en':
        return 0;
      case 'nl':
        return 1;
      case 'fr':
        return 1;
      default:
        return 0;
    }
  }
}

export default withTranslation()(CustomDatePicker);

import {
  READ_STOCK_BY_ID_EP,
  READ_STOCK_DROPDOWN_EP,
  READ_STOCK_LIST_EP,
  READ_STOCK_SHORTCUT_PANE_EP,
  STOCK_SAVE_EP,
} from '../shared/endpoints';
import {
  CLEAR_STOCK_FORM_DATA,
  CLEAR_STOCKUID,
  LOAD_STOCK_BY_ID,
  LOAD_STOCK_BY_ID_FAIL,
  LOAD_STOCK_BY_ID_SUCCESS,
  LOAD_STOCK_DROPDOWN,
  LOAD_STOCK_DROPDOWN_FAIL,
  LOAD_STOCK_DROPDOWN_SUCCESS,
  LOAD_STOCK_LSITPANE,
  LOAD_STOCK_LSITPANE_FAIL,
  LOAD_STOCK_LSITPANE_SUCCESS,
  LOAD_STOCK_SHORTCUTPANE,
  LOAD_STOCK_SHORTCUTPANE_FAIL,
  LOAD_STOCK_SHORTCUTPANE_SUCCESS,
  RESET_STOCK_IS_CHANGE,
  SAVE_STOCK,
  SAVE_STOCK_FAIL,
  SAVE_STOCK_SUCCESS,
  SET_STOCK_UID,
} from '../actionTypes/stockActionTypes';
import { v4 as uuidv4 } from 'uuid';
import { POFilter, PurchaseOrder } from '../types/purchaseOrder';
import { formattedPayloadToDropdownList } from '../shared/util';


const defaultState: any = {
  stockList: [],
  isSTOCKListLoaded: false,
  isNew: false,
  isChange: false,
  isEdit: false,
  showProgressBar: false,
  stockDropDowns: {},
  stockDropDownsList: [],
  formData: {},
  uid: null,
  loadMsg: '',
  stockShortCutPaneData: [],
  reloadListPane: false,
  borResources: {},
  isLoadingStock: false,
  stockID: null,
  isDataLoaded: false,
};

export default function stockReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {
      case LOAD_STOCK_SHORTCUTPANE:
        return { ...state };
      case LOAD_STOCK_SHORTCUTPANE_SUCCESS:
        return {
          ...state,
          stockShortCutPaneData: action.payload.data.result,
        };
      case LOAD_STOCK_SHORTCUTPANE_FAIL:
        return { ...state, stockShortCutPaneData: [] };
      case LOAD_STOCK_LSITPANE:
        return { ...state, isSTOCKListLoaded: false, stockList: [] };
      case LOAD_STOCK_LSITPANE_SUCCESS:
        return {
          ...state,
          isSTOCKListLoaded: true,
          stockList: action.payload.data.result,
          loadMsg: !action.payload.data.status
            ? action.payload.data.message
            : '',
        };
      case LOAD_STOCK_LSITPANE_FAIL:
        return {
          ...state,
          isSTOCKListLoaded: true,
          stockList: [],
          loadMsg: action.error.response.data.message,
        };
      case RESET_STOCK_IS_CHANGE:
        return {
          ...state,
          isChange: false,
        };

      case LOAD_STOCK_DROPDOWN:
        return { ...state, stockDropDowns: {} };
      case LOAD_STOCK_DROPDOWN_SUCCESS:
        return {
          ...state,
          stockDropDowns: action.payload.data.result,
          stockDropDownsList: formattedPayloadToDropdownList(action.payload.data.result, 'stock'),
        };
      case LOAD_STOCK_DROPDOWN_FAIL:
        return {
          ...state,
          stockDropDowns: {},
          stockDropDownsList: [],
        };
      case LOAD_STOCK_BY_ID:
        return { ...state, formData: {}, showProgressBar: true, isLoadingStock: true };
      case LOAD_STOCK_BY_ID_SUCCESS:
        return {
          ...state,
          isEdit: true,
          formData: action.payload.data.result,
          showProgressBar: false,
          isLoadingStock: false,
        };
      case LOAD_STOCK_BY_ID_FAIL:
        return {
          ...state,
          formData: {},
          showProgressBar: false,
          isLoadingStock: false,
        };

      case SAVE_STOCK:
        return { ...state, showProgressBar: true, reloadListPane: false, stockID: null };
      case SAVE_STOCK_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          reloadListPane: true,
          stockID: action.payload.data.result,
        };
      case SAVE_STOCK_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
          stockID: null,
        };

      case SET_STOCK_UID:
        return {
          ...state,
          isEdit: false,
          uid: uuidv4(),
        };
      case CLEAR_STOCKUID:
        return {
          ...state,
          uid: null,
          isChange: false,
        };
      case CLEAR_STOCK_FORM_DATA:
        return {
          ...state,
          uid: null,
          isNew: true,
          isEdit: false,
          isChange: false,
          formData: {},
        };

      default:
        return state;
    }
  }
}

export const readStockShortCutPaneData = () => {
  return {
    types: [
      LOAD_STOCK_SHORTCUTPANE,
      LOAD_STOCK_SHORTCUTPANE_SUCCESS,
      LOAD_STOCK_SHORTCUTPANE_FAIL,
    ],
    payload: { request: { url: READ_STOCK_SHORTCUT_PANE_EP } },
  };
};

export const readStockDropDownData = () => {
  return {
    types: [
      LOAD_STOCK_DROPDOWN,
      LOAD_STOCK_DROPDOWN_SUCCESS,
      LOAD_STOCK_DROPDOWN_FAIL,
    ],
    payload: { request: { method: 'GET', url: READ_STOCK_DROPDOWN_EP } },
  };
};


export const filterStockListPaneData = (filter: POFilter) => {
  return {
    types: [
      LOAD_STOCK_LSITPANE,
      LOAD_STOCK_LSITPANE_SUCCESS,
      LOAD_STOCK_LSITPANE_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_STOCK_LIST_EP,
        data: filter,
      },
    },
  };
};


export const readByStockId = (id: string) => {
  return {
    types: [
      LOAD_STOCK_BY_ID,
      LOAD_STOCK_BY_ID_SUCCESS,
      LOAD_STOCK_BY_ID_FAIL],
    payload: { request: { url: READ_STOCK_BY_ID_EP + id } },
  };
};

export const resetIsChange = () => {
  return { type: RESET_STOCK_IS_CHANGE };
};

export const saveFormData = (formData: PurchaseOrder) => {
  return {
    types: [
      SAVE_STOCK,
      SAVE_STOCK_SUCCESS,
      SAVE_STOCK_FAIL],
    payload: {
      request: {
        url: STOCK_SAVE_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const setUid = () => {
  return { type: SET_STOCK_UID };
};

export const clearFormData = () => {
  return { type: CLEAR_STOCK_FORM_DATA };
};

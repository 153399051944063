import { Label, Link } from '@fluentui/react';
import React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { saveContractStateAttr, updateContractorWorkflow } from '../../../../reducers/contractReducer';
import DataGridWithModal, { ModalGridContext } from '../../../../shared/dataGridModal/component';
import { accreditationsColumns, supplierListColumns } from '../../../../types/contract';
import AccreditationsModal from './modal/component';

const AccreditationAndSupplier = (props: {
  accAndSup: any,
  disabled: boolean,
  onChange: any,
  footer: any,
  readOnly: boolean,
  formValues: any,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { instructionUploadStatus }: any = useSelector((state: any) => state.contract);

  const onSubmit = async (values: any) => {
    const sleep = (ms: number | undefined) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);
    props.onChange(values);
  };

  const getInitialValues = (values: any) => {
    return {
      ...values,
      // technicalDocumentation: {
      //     typeId: values.technicalDocumentation.type.key,
      //     typeName: values.technicalDocumentation.type.text,
      // }
    };
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={getInitialValues(props.accAndSup)}
      render={({ values }) => (
        // <form onSubmit={ handleSubmit }>
        <div>
          <FormSpy
            onChange={(spyProps: any) => {
              if (props.accAndSup !== spyProps.values && props.onChange) {
                props.onChange(spyProps.values);
              }
            }}
          />
          <div className="document-pane-card">
            <div className={'card-header'}>
              <Link href="#" id="accreditations-and-suppliers-list">
                <Label>4. {t('accreditationsAndSuppliersList')}</Label>
              </Link>
            </div>

            <Field name="contractorAccreditation">
              {({ input }) => (
                <DataGridWithModal
                  dataList={values?.contractorAccreditation && Array.isArray(values.contractorAccreditation) ? values.contractorAccreditation : []}
                  modalTitle={t('accreditations')}
                  deleteDataGrid={async (selection: any) => {
                    const selectedList = selection.map((item: any) => {
                      return item;
                    });
                    const currentList: any[] = values.contractorAccreditation;

                    const filtered: any[] = currentList.filter((e: any) => {
                      return selectedList.indexOf(e) < 0;
                    });
                    input.onChange(filtered);
                    const accreditationAndSupplier: any[] = {
                      ...props.formValues.accreditationAndSupplier,
                      contractorAccreditation: filtered,
                    };
                    const formData: any[] = { ...props.formValues, accreditationAndSupplier: accreditationAndSupplier };
                    dispatch(updateContractorWorkflow(formData));
                  }}
                  title={`4.1 ${t('accreditations')}`}
                  readOnly={props.readOnly}
                  keyProp={'cabPersonId'}
                  columns={accreditationsColumns()}
                  isLoaded={false}
                  onDismiss={() => dispatch(saveContractStateAttr('uploadedInstructionLink', null))}
                >
                  <ModalGridContext.Consumer>
                    {(modalProps: any) => (
                      <AccreditationsModal
                        isAcc={true}
                        dataList={modalProps.dataList}
                        values={modalProps.editItem}
                        disabled={false}
                        onChange={(item: any) => {
                          if (values && values.contractorAccreditation && Array.isArray(values.contractorAccreditation)) {
                            const contractorAccreditation: any[] = [];
                            const currentList: any[] = values.contractorAccreditation;
                            let valueList = [];
                            if (item.id) {
                              const index = currentList.findIndex(object => {
                                return object.id === item.id;
                              });
                              if (index < currentList.length) {
                                currentList[index] = item;
                              }
                              valueList = currentList;
                              input.onChange(valueList);
                              modalProps.updateEditItem(item);
                            } else {
                              contractorAccreditation.push(item);
                              currentList.forEach((listItems: any) => {
                                contractorAccreditation.push(listItems);
                              });
                              valueList = contractorAccreditation;
                              input.onChange(valueList);
                            }
                            const accreditationAndSupplier: any[] = {
                              ...props.formValues.accreditationAndSupplier,
                              contractorAccreditation: valueList,
                            };
                            const formData: any[] = {
                              ...props.formValues,
                              accreditationAndSupplier: accreditationAndSupplier,
                            };
                            if (props.formValues.id) {
                              dispatch(updateContractorWorkflow(formData));
                            }
                          }
                          modalProps.closeModal();
                        }}
                      />
                    )}
                  </ModalGridContext.Consumer>
                </DataGridWithModal>
              )}
            </Field>

            <Field name="contractorSupplierList">
              {({ input }) => (
                <DataGridWithModal
                  dataList={values?.contractorSupplierList && Array.isArray(values.contractorSupplierList) ? values.contractorSupplierList : []}
                  modalTitle={t('supplierList')}
                  deleteDataGrid={async (selection: any) => {
                    const selectedList = selection.map((item: any) => {
                      return item;
                    });
                    const currentList: any[] = values.contractorSupplierList;

                    const filtered: any[] = currentList.filter((e: any) => {
                      return selectedList.indexOf(e) < 0;
                    });
                    input.onChange(filtered);
                    const accreditationAndSupplier: any[] = {
                      ...props.formValues.accreditationAndSupplier,
                      contractorSupplierList: filtered,
                    };
                    const formData: any[] = { ...props.formValues, accreditationAndSupplier: accreditationAndSupplier };
                    dispatch(updateContractorWorkflow(formData));
                  }}
                  title={`4.2 ${t('supplierList')}`}
                  readOnly={props.readOnly}
                  keyProp={'cabPersonId'}
                  columns={supplierListColumns()}
                  isLoaded={false}
                  onDismiss={() => dispatch(saveContractStateAttr('uploadedInstructionLink', null))}
                  showProgress={!instructionUploadStatus}
                >
                  <ModalGridContext.Consumer>
                    {(modalProps: any) => (
                      <AccreditationsModal
                        isAcc={false}
                        dataList={modalProps.dataList}
                        values={modalProps.editItem}
                        disabled={false}
                        onChange={(item: any) => {
                          if (values && values?.contractorSupplierList && Array.isArray(values?.contractorSupplierList)) {
                            const contractorSupplierList: any[] = [];
                            const currentList: any[] = values.contractorSupplierList;
                            let valueList = [];
                            if (item.id) {
                              const index = currentList.findIndex(object => {
                                return object.id === item.id;
                              });
                              if (index < currentList.length) {
                                currentList[index] = item;
                              }
                              valueList = currentList;
                              input.onChange(valueList);
                              modalProps.updateEditItem(item);
                            } else {
                              contractorSupplierList.push(item);
                              currentList.forEach((listItems: any) => {
                                contractorSupplierList.push(listItems);
                              });
                              valueList = contractorSupplierList;
                              input.onChange(valueList);
                            }
                            const accreditationAndSupplier: any[] = {
                              ...props.formValues.accreditationAndSupplier,
                              contractorSupplierList: valueList,
                            };
                            const formData: any[] = {
                              ...props.formValues,
                              accreditationAndSupplier: accreditationAndSupplier,
                            };
                            if (props.formValues.id) {
                              dispatch(updateContractorWorkflow(formData));
                            }
                          }
                          modalProps.closeModal();
                        }}
                      />
                    )}
                  </ModalGridContext.Consumer>
                </DataGridWithModal>
              )}
            </Field>

            {props.footer}
          </div>
        </div>
      )}
    />
  );
};

export default AccreditationAndSupplier;

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ICommandBarItemProps, Panel, PanelType, ScrollablePane, ScrollbarVisibility, Text} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import {SelectedGridType} from '../../../types/dayPlanning';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import '../../../shared/kanban/kanban.css';
import CustomToolbar from '../../../shared/kanban/customToolbar';
import {saveDayPlanningStateAttr} from "../../../reducers/dayPlanningReducer";
import * as TdDropBox from "../../../shared/dnd/dropBox";
import {AcceptType} from "../../../types/myDayPlanning";
import EventBody from "../../../shared/dndCalcander/eventBody";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import {
    formatDateTimeToISOString,
    formatEndDateTimeToISOString,
    handleCalendarEndTime,
    tooltipAccessor
} from "../../../shared/util";
import {updateMyDayPlanningPmol} from "../../../reducers/myDayPlanningReducer";
import EventWrapper from "../../myDayPlanning/listPane/eventWrapper";
import {updatePlanBoardsPmolStatus} from "../../../reducers/projectDayPlanningReducer";
import CustomWeek from '../../dayPlanning/pmolListPane/customWeek';
import MyCalendarNewProject from '../../dayPlanning/pmolListPane/newProject';
import CreatePmolModal from "../../dayPlanning/pmolListPane/component/newPmol";
import {filterMyCalendarCuLevelListData, saveCuMyCalendarStateAttr} from "../../../reducers/cuMyCalendarReducer";
import {DayPlanningCuList, ORGANIZATION_TAXONOMY_LEVEL_TYPES} from "../../../types/cuMyCalendar";
import {messageService} from "../../../services/messageService";

moment.locale('en-GB');
const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar as any);

interface Props {

}

const MyCalTopListPane = (props: Props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const {
        dayPlanningDate,
        dayPlanningWeek,
        selectedGridType,
        isDPLANListLoaded,
        dPlanFullScreen,
        reloadDPListPane
    } = useSelector((state: any) => state.dplan);

    const {userInfo} = useSelector((state: any) => state.uprince);

    const {teamList, loadMsg, myCalSelectedOrgSetting, nodeValues} = useSelector((state: any) => state.cuMyCal);

    const nodeInit: DayPlanningCuList = {
        startDate: selectedGridType === SelectedGridType.DAY
            ? moment(formatDateTimeToISOString(dayPlanningDate)).format('YYYY-MM-DD')
            : moment(dayPlanningWeek?.startDate).format('YYYY-MM-DD'),
        endDate: selectedGridType === SelectedGridType.DAY
            ? moment(formatEndDateTimeToISOString(dayPlanningDate)).format('YYYY-MM-DD')
            : moment(dayPlanningWeek.endDate).format('YYYY-MM-DD'),
        buId: null,
        cuId: null,
        id: null,
        type: null,
    }

    const [teamListState, setTeamListState]: any = useState(null);
    const [pmolList, setPmolList]: any = useState(null);
    // prevState
    const [dayPlanningDateState, setDayPlanningDateState] = useState<any>(dayPlanningDate);
    const [selectedGridTypeState, setSelectedGridTypeState] = useState<SelectedGridType>(SelectedGridType.DAY);
    const [dayPlanningWeekState, setDayPlanningWeekState] = useState<any>(dayPlanningWeek); //data: {startDate, endDate, toDate}
    // const [myCalNode, setMyCalNode] = useState<DayPlanningCuList>(nodeInit);

    useEffect(() => {
        dispatch(saveDayPlanningStateAttr("selectedGridType", SelectedGridType.DAY));
        // reloadListPane();

        const subscription = messageService.getMessage().subscribe((data: any) => {
            if (data) {
                if (data.data.reloadList) {
                    reloadListPane(nodeValues);
                }
                if (data.data.fullScreen) {
                    dispatch(saveDayPlanningStateAttr("dPlanFullScreen", true));
                }
            }
        });

        return () => {
            subscription.unsubscribe();
            dispatch(saveCuMyCalendarStateAttr("myCalSelectedOrgSetting", null));
        }
    }, [])

    useEffect(() => {
        getTeam(teamList);
    }, [teamList]);

    // dayPlanningDate
    useEffect(() => {
        if (dayPlanningDate) {
            if (moment(dayPlanningDateState).format('YYYY/MM/DD') != moment(dayPlanningDate).format('YYYY/MM/DD')) {
                Promise.all([
                    reloadListPane(nodeInit)
                ]).then(() => {
                    setDayPlanningDateState(dayPlanningDate);
                });
            }
        }
    }, [dayPlanningDate]);

    // dayPlanningWeek
    useEffect(() => {
        if (dayPlanningWeekState) {
            if (moment(dayPlanningWeekState.startDate).format('YYYY/MM/DD') != moment(dayPlanningWeek.startDate).format('YYYY/MM/DD')) {
                Promise.all([
                    reloadListPane(nodeInit)
                ]).then(() => {
                    setDayPlanningWeekState(dayPlanningWeek);
                });
            }
        }
    }, [dayPlanningWeek]);

    // selectedGridType
    useEffect(() => {
        if (selectedGridTypeState != selectedGridType) {
            if (selectedGridType) {
                Promise.all([
                    reloadListPane(nodeInit)
                ]).then(() => {
                    setSelectedGridTypeState(selectedGridType);
                });
            }
        }
    }, [selectedGridType]);

    useEffect(() => {
        if (myCalSelectedOrgSetting && myCalSelectedOrgSetting.id) {
            const nodeValues = {
                startDate: selectedGridType === SelectedGridType.DAY
                    ? moment(formatDateTimeToISOString(dayPlanningDate)).format('YYYY-MM-DD')
                    : moment(dayPlanningWeek?.startDate).format('YYYY-MM-DD'),
                endDate: selectedGridType === SelectedGridType.DAY
                    ? moment(formatEndDateTimeToISOString(dayPlanningDate)).format('YYYY-MM-DD')
                    : moment(dayPlanningWeek.endDate).format('YYYY-MM-DD'),
                buId: myCalSelectedOrgSetting.buId ?? null,
                cuId: null,
                type: getOrgTaxonomyType(myCalSelectedOrgSetting.organizationTaxonomyLevelId),
                id: getOrgTaxonomyType(myCalSelectedOrgSetting.organizationTaxonomyLevelId) === '1' ? myCalSelectedOrgSetting.personId : myCalSelectedOrgSetting.id
            }
            dispatch(saveCuMyCalendarStateAttr("nodeValues", nodeValues));
            dispatch(saveCuMyCalendarStateAttr("myCalSelectedOrgSetting", null));
        }
    }, [myCalSelectedOrgSetting]);

    useEffect(() => {
        if (reloadDPListPane) {
            reloadListPane(nodeValues);
        }
    }, [reloadDPListPane]);


    useEffect(() => {
        if (nodeValues && nodeValues.id) {
            reloadListPane(nodeValues);
        }
    }, [nodeValues]);

    const reloadListPane = (filters: any) => {
        dispatch(filterMyCalendarCuLevelListData(filters));

        return null;
    }

    const formatTimeForPmol = (date: string, time: any) => {
        let formatted = moment(date).format('YYYY/MM/DD');
        let dateonly = (formatted === 'Invalid date') ? '' : formatted.toString();
        if (dateonly && time) {
            let t = time ? time : '';
            let dateTime = dateonly + ' ' + t;
            return moment(dateTime).toDate();
        } else {
            return moment.utc(date).local().toDate();
        }
    };

    const getTeam = (teamList: any) => {
        if (teamList && teamList.length > 0) {
            let teams = teamList.map((team: any, index: any) => {
                // console.log('team >',team);
                let teamName = team?.team?.map((emp: any) => {
                    return emp.name;
                });
                return {
                    resourceId: 1 + index,
                    resourceTitle: teamName?.join(','),
                    team: team.team,
                    teamId: team.teamId,
                    teamTitle: team.teamTitle
                };
            });
            setTeamListState(teams);
            getPmol(teamList);
        } else {
            setTeamListState(null);
            setPmolList([]);
        }
    };

    const getPmol = (teamList: any) => {
        let pmolList: any[] = [];
        teamList.map((team: any, index: any) => {
            if (team && team?.pmol && team.pmol.length > 0) {
                team?.pmol?.map((pmol: any) => {
                    pmolList.push({
                        id: pmol.id,
                        teamId: pmol?.teamId,
                        projectMoleculeId: pmol.projectMoleculeId,
                        projectSequenceCode: pmol.projectSequenceCode,
                        contractingUinit: pmol?.contractingUinit,
                        title: pmol.title,
                        vehicles: pmol.pomlVehical,
                        start: formatTimeForPmol(pmol.executionDate, pmol.executionStartTime),
                        end: formatTimeForPmol(pmol.executionDate, pmol.executionEndTime),
                        resourceId: 1 + index,
                        typeId: pmol.typeId,
                        pmolVehical: pmol?.pomlVehical,
                        pmolTool: pmol?.pomlTool,
                        statusId: pmol?.statusId,
                        projectTitle: pmol?.projectTitle,
                        forecast: pmol?.forecast,
                        executionStartTime: pmol?.executionStartTime,
                        executionEndTime: pmol?.executionEndTime,
                        productTaxonomy: pmol?.productTaxonomy,
                        productId: pmol?.productId,
                        comment: pmol?.comment,
                    });
                });
            }
            return pmolList;
        });
        setPmolList(pmolList);
    };

    const handlePmolStatusChange = (data: any) => {
        Promise.all([
            dispatch(updatePlanBoardsPmolStatus(data))
        ]).then(() => {
            reloadListPane(nodeValues)
        });
    };

    const getOrgTaxonomyType = (organizationTaxonomyLevelId: string) => {
        switch (organizationTaxonomyLevelId) {
            case ORGANIZATION_TAXONOMY_LEVEL_TYPES.ORGANIZATION:
                return '0';

            case ORGANIZATION_TAXONOMY_LEVEL_TYPES.CU:
                return '4';

            case ORGANIZATION_TAXONOMY_LEVEL_TYPES.BU:
                return '3';

            case ORGANIZATION_TAXONOMY_LEVEL_TYPES.TEAM:
                return '2';

            case ORGANIZATION_TAXONOMY_LEVEL_TYPES.PERSON:
                return '1';

            default:
                return '0';
        }
    }

    const displayMessage = () => {
        return (
            <div style={{paddingTop: 36, paddingLeft: 20}}>
                <Text>{t(loadMsg)}</Text>
            </div>
        );
    };

    const CustomEvent = ({event}: any) => {
        const _overflowItems: ICommandBarItemProps[] = [
            {
                key: 'openPbsKey',
                text: t('openPbs'),
                onClick: () => onClickPbs(event),
                iconProps: {iconName: 'MoveToFolder'}
            }
        ];

        return <TdDropBox.DropBox
            item={event}
            day={dayPlanningDateState}
            type={[AcceptType.TEAM, AcceptType.VEHICLE, AcceptType.TOOL]}
            isPmol={true}
        >
            <EventBody
                pmol={event}
                overflowItems={_overflowItems}
                isMyCal={true}
                handlePmolStatusChange={(data: any) => handlePmolStatusChange(data)}
            />
        </TdDropBox.DropBox>
    };

    const renderCalendar = () => {
        // return <div
        //     style={{width: 'auto', height: `${dPlanFullScreen ? 'calc(100vh - 50px)' : 'calc(100vh - 290px)'}`}}>
        return <div style={{height: '100%', width: '100%'}}>
            <DragAndDropCalendar
                events={pmolList ? pmolList : []}
                localizer={localizer}
                view={selectedGridType}
                views={{day: true, week: CustomWeek}}
                onView={(view) => {
                    dispatch(saveDayPlanningStateAttr('selectedGridType', view));
                }}
                step={30}
                date={selectedGridType === SelectedGridType.DAY
                    ? dayPlanningDateState
                    : selectedGridType === SelectedGridType.WEEK ? dayPlanningWeekState?.toDate : moment().toDate()}
                onNavigate={(date) => {
                    dispatch(saveDayPlanningStateAttr('dayPlanningDate', date))
                }}
                resources={teamListState}
                resourceIdAccessor={(resources: any) => resources.resourceId}
                resourceTitleAccessor={(resources: any) => resources.resourceTitle}
                components={{
                    event: CustomEvent,
                    eventWrapper: EventWrapper,
                    resourceHeader: resourceHeader,
                    toolbar: CustomToolbar,
                }}
                onEventDrop={moveEvent}
                onEventResize={resizeEvent}
                onSelectSlot={selectSlotEvent}
                resizable={true}
                formats={{
                    eventTimeRangeFormat: ({start, end}, culture: any, localizer: any) =>
                        localizer.format(start, 'HH:mm', culture) +
                        ' - ' +
                        localizer.format(end, 'HH:mm', culture),
                    timeGutterFormat: (date, culture: any, localizer: any) =>
                        localizer.format(date, 'HH:mm', culture),
                }}
                dayLayoutAlgorithm="no-overlap"
                tooltipAccessor={tooltipAccessor}
                selectable
            />
        </div>
    }

    const resourceHeader = ({label}: any) => (
        <div key={JSON.stringify(label)} style={{width: '100%', minHeight: 40}}>
            {addLineBrake(label)}
        </div>
    );

    const addLineBrake = (htmlString: any) => {
        const array = htmlString.split(',');
        return array.map((text: any) => {
            return <React.Fragment>{text}<br/></React.Fragment>;
        });
    };

    const onClickPbs = (event: any) => {
        if (event && event.productId) {
            const openUrl = `/CU/${event.contractingUinit}/project/${event.projectSequenceCode}/product/pbs/${event.productId}`;
            window.open(openUrl);
        }
    };

    const resizeEvent = ({event, start, end}: any) => {
        let list = teamListState.find((item: any) => item.resourceId === event.resourceId);

        let data = {
            teamId: event?.teamId,
            team: list?.team,
            id: event?.id,
            executionDate: moment(event?.start).format('YYYY/MM/DD'),
            executionStartTime: moment(start).format('HH:mm'),
            executionEndTime: moment(end).format('HH:mm'),
            projectSequenceCode: event?.projectSequenceCode,
            contractingUnit: event?.contractingUinit
        }

        Promise.all([
            updateEvent(event, start, end, event?.resourceId)
        ]).then(() => {
            Promise.all([
                dispatch(updateMyDayPlanningPmol(data))
            ]).then(() => {
                dispatch(saveDayPlanningStateAttr("reloadListPane", true));
            });
        });
    };

    const moveEvent = ({event, start, end, resourceId, isAllDay: droppedOnAllDaySlot = false}: any) => {
        if (resourceId) {
            let list = teamListState.find((item: any) => item.resourceId === resourceId);
            let data = {
                teamId: event?.teamId,
                team: list?.team,
                id: event?.id,
                executionDate: moment(event?.start).format('YYYY/MM/DD'),
                executionStartTime: moment(start).format('HH:mm'),
                executionEndTime: handleCalendarEndTime(event?.start, end)[0],
                projectSequenceCode: event?.projectSequenceCode,
                contractingUnit: event?.contractingUinit
            };

            Promise.all([
                updateEvent(event, start, handleCalendarEndTime(event?.start, end)[1], resourceId)
            ]).then(() => {
                Promise.all([
                    dispatch(updateMyDayPlanningPmol(data))
                ]).then(() => {
                    dispatch(saveDayPlanningStateAttr("reloadListPane", true));
                });
            });
        }
    };

    const selectSlotEvent = ({start, end, resourceId, slots, action}: any) => {
        let resourceSelector
        if (teamListState) {
            resourceSelector = teamListState.find((item: any) => item.resourceId === resourceId);
        } else {
            resourceSelector = {
                resourceId: 0,
                resourceTitle: userInfo?.userName,
                team: [{id: userInfo?.userId, name: userInfo?.userName}],
                teamId: null,
                teamTitle: null,
            }
        }

        dispatch(saveDayPlanningStateAttr("myCalendarSlotData", {start, end, resourceId, slots, action}));
        dispatch(saveDayPlanningStateAttr("resourceSelectorData", resourceSelector));
    };

    const updateEvent = (event: any, start: any, end: any, resourceId: any) => {
        const idx = pmolList.indexOf(event);
        const updatedEvent = {...event, start, end, resourceId};

        const nextEvents = [...pmolList];
        nextEvents.splice(idx, 1, updatedEvent);

        setPmolList(nextEvents)

        return null;
    };

    return (
        // <div className={`wrapper-holder ${dPlanFullScreen ? 'full-screen-wrapper' : 'wrapper'}`}>
        <div>
            {/* don't remove the ScrollablePane */}
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} style={{marginLeft: 255, marginTop: 80}}>
                {renderCalendar()}
                {/*{isDPLANListLoaded && displayMessage()}*/}
            </ScrollablePane>
            <Panel
                isOpen={dPlanFullScreen}
                type={PanelType.custom}
                customWidth="100vw"
                onDismiss={() => {
                    dispatch(saveDayPlanningStateAttr('dPlanFullScreen', false))
                }}
                isLightDismiss={true}
                headerText={t('myCalendar')}
                className="custom-detail-panel pdp"
                closeButtonAriaLabel={t('close')}
            >
                {renderCalendar()}
            </Panel>

            <MyCalendarNewProject/>
            <CreatePmolModal fCode={"cu-my-calendar"}/>
        </div>
    );
};

export default MyCalTopListPane

import React, {CSSProperties, useRef} from 'react';
import {useDrag} from 'react-dnd';
import moment from 'moment';

const style: CSSProperties = {
  border: '1px dashed gray',
  backgroundColor: 'white',
  padding: '0.5rem 1rem',
  marginRight: '1.5rem',
  marginBottom: '1.5rem',
  cursor: 'move',
  float: 'left',
};

export interface BoxProps {
  name: string
  dropItem: any
}

interface DropResult {
  name: string
  dropItem: any
}

export const ItemTypes = {
  BOX: 'box',
};


const DragBox = (props: {
  name: string | null;
  item: any
  icon?: any
  onClick?: any
  disabled?: boolean
}) => {
  const [dropped, setDropped] = React.useState(false);
  const nonDragRef = useRef<HTMLDivElement>(null);

  const formatDate = (item: any) => {
    return moment(item?.day).format('DD/MM/YYYY');
  };
  const [collectedProps, dragSourceRef] = useDrag({
    type: formatDate(props.item),
    item: { type: formatDate(props.item), dragItem: props.item },
    end: (item: any, monitor: { getDropResult: () => any; }) => {   // end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (item && dropResult) {
        setDropped(true);
      }
    },
  });

  return (
    dropped ? <div></div> : <div
      onClick={() => {
        props?.onClick(props.item);
      }}
      className={props.disabled ? 'noneDragBox' : 'dragBox'}
      ref={props.disabled ? nonDragRef : dragSourceRef}
      key={props.name ? props.name + Math.random() : Math.random()}
    >
      {props.icon}
      {props.name}
    </div>
  );
};

export default DragBox;

import * as React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { FontWeights, getTheme, IIconProps, mergeStyleSets, PrimaryButton, Stack } from '@fluentui/react';
import { DropDownAdaptater, TextFieldFF } from '../../../../shared/fluentFinalForm';
import { OnBlur, OnFocus } from 'react-final-form-listeners';
import { UPLOAD_URL } from '../../../../types/contract';
import { v4 as uuid4 } from 'uuid';
import { saveInstructionStateAttr, uploadInstructionDoc } from '../../../../reducers/instructionRegisterReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import { uPrinceTheme } from '../../../../../theme';
import { getFileType } from '../../../../shared/util';

const theme = getTheme();
const contentStyles = mergeStyleSets({
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end'
    }
  ],
});
const addWhiteIconButtonStyles = {
  root: { color: uPrinceTheme.palette.white },
  rootHovered: { color: theme.palette.neutralDark }
};
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles
};
const uploadIconWhite: IIconProps = {
  iconName: 'Upload',
  styles: addWhiteIconButtonStyles
};

const AddLink = (props:{selectedLink:any,onChange:any}) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ref: any = useRef(null);
  const inputFile: any = useRef(null);
  const classNames = mergeStyleSets({
    fullWidth: { width: '100%',marginTop:20 },
    halfWidth: { width: '49%' },
  });

  const [dragging, setDragging] = React.useState<boolean>(false);

  const { uploadedInstructionLink, showProgressBar }: any = useSelector(
    (state: any) => state.ir
  );
  const onSubmit = async (values: any) => {
    console.log({values});
    const sleep = (ms: number | undefined) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);
    if (!values.id) {
      values.id=uuid4();
    }
    values.value=values.link;
    props.onChange(values);
  };



  // const formRef = React.useRef(createForm({ onSubmit: onSubmit }));

  const getInitialValues = () => {
    if(props?.selectedLink){
      return {...props?.selectedLink}
    }else{
      return {
        id: null,
        title: null,
        // type: null,
        link: null
      };
    }
  };

  useEffect(() => {
    if (uploadedInstructionLink && ref?.current) {
      ref.current.mutators.setDocumentLink(uploadedInstructionLink);
      dispatch(saveInstructionStateAttr('uploadedInstructionLink', null));
    }
  }, [uploadedInstructionLink]);

  // Functions for drag and drop
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy
    setDragging(true);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>, form: any) => {
    e.preventDefault();
    setDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    const formData = new FormData();
    if (e?.dataTransfer?.files[0]) {
      formData.append('file', e?.dataTransfer?.files[0]);
      formData.append('id', uuid4());
      dispatch(uploadInstructionDoc(formData));
      // console.log("TARGET", e?.dataTransfer?.files[0].type);
      form.mutators.setDocumentType(getFileType(e?.dataTransfer?.files[0].type));

    }
  };

 return (
  <>
    <div style={{width:"100%"}}>
      <Form
        //form={formRef.current}
        onSubmit={onSubmit}
        initialValues={getInitialValues()}
        mutators={{
          // setTypeName: (args, state, utils) => {
          //     const field = state.fields.typeName;
          //     field.change(args[0]);
          // },
          setDocumentLink: (args, state, utils) => {
            const field = state.fields.link;
            field.change(args[0]);
          },
          setDocumentType: (args, state, utils) => {
            const field = state.fields.type;
            field.change(args[0]);
          }
        }}
        validate={(values) => {
          const errors: any = {};
          if (!values?.title) {
            errors.title = t('titleRequired');
          }
          if (!values?.type) {
            errors.typeId = t('typeRequired');
          }
          if (!values?.link) {
            errors.link = t('linkRequired');
          }
          return errors;
        }}

        render={({ handleSubmit, form, submitting, pristine, values }) => {

          ref.current = form;
          return (<div>
              <FormSpy

                onChange={(spyProps: any) => { }}
              />

              <div style={{display:"flex",flexDirection:"column",minWidth:"50vw",padding:10}}>
                <div style={{display:"flex",gap:5}}>
                  <div className={`${classNames.halfWidth}`}>
                    <Field
                      name="title"
                      component={TextFieldFF}
                      lable={t('title')}
                      required={true}
                    />
                  </div>
                  <div className={`${classNames.halfWidth}`}>
                    <Field
                      name="type"
                      component={DropDownAdaptater}
                      options={[
                        { key: 'PDF', text: 'PDF' },
                        { key: 'FileImage', text: 'Image' },
                        { key: 'Website', text: 'URL' },
                        { key: 'WordDocument', text: 'Word Document' },
                        { key: 'ExcelDocument', text: 'Excel Document' },
                        { key: 'PowerPointDocument', text: 'Power Point Document' },
                      ]}
                      lable={t('type')}
                      placeholder={t('type')}
                      required={true}
                      disabled={true}
                    />
                  </div>
                </div>
                <div style={{display:"flex"}}>
                  <div className={`${classNames.fullWidth}`}>
                    <Field
                      name="link"
                      component={TextFieldFF}
                      lable={t('link')}
                      disabled={false}
                      placeholder={t('copyURLhere')}
                      required={true}
                    />
                    <OnFocus name="link">
                      {() => {
                        const sub = values?.link?.substring(0, 30)
                        if (sub && sub !== UPLOAD_URL) {
                          form.mutators.setDocumentType(getFileType(''));
                        }
                      }}
                    </OnFocus>
                    <OnBlur name="link">
                      {() => {
                        const sub = values?.link?.substring(0, 30)
                        if (sub && sub !== UPLOAD_URL) {
                          form.mutators.setDocumentType(getFileType(''));
                        }
                      }}
                    </OnBlur>
                  </div>
                </div>
              </div>
              <div className={`dropzone ${dragging ? 'dragging' : ''}`}
                  onDragEnter={handleDragEnter}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={(e) => handleDrop(e, form)}>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  hidden
                  onChange={(event: any) => {
                    const formData = new FormData();
                    if (event?.target?.files[0]) {
                      formData.append('file', event.target.files[0]);
                      formData.append('id', uuid4());
                      dispatch(uploadInstructionDoc(formData));
                      // console.log("TARGET", event.target.files[0].type);
                      form.mutators.setDocumentType(getFileType(event.target.files[0].type));

                    }
                  }}
                />
                <PrimaryButton
                  iconProps={uploadIconWhite}
                  text={t('upload')}
                  disabled={false}
                  style={{ marginTop: 32, marginLeft: 10 }}
                  onClick={() => {
                    inputFile?.current?.click();
                  }}
                />
              </div>
              <div className={contentStyles.footer}>
                <PrimaryButton
                  iconProps={addIconWhite}
                  text={t('save')}
                  disabled={submitting || pristine}
                  style={{ marginTop: 38, marginBottom: 10 }}
                  onClick={() => {

                    form.submit();
                  }}
                />
              </div>
            </div>
          )
        }}
      />
    </div>
  </>
 );

};

export default AddLink

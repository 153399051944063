export const LOAD_WORK_FLOW_SHORTCUTPANE = 'uprince/LOAD_WORK_FLOW_SHORTCUTPANE';
export const LOAD_WORK_FLOW_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_WORK_FLOW_SHORTCUTPANE_SUCCESS';
export const LOAD_WORK_FLOW_SHORTCUTPANE_FAIL = 'uprince/LOAD_WORK_FLOW_SHORTCUTPANE_FAIL';

export const LOAD_WORK_FLOW_LSITPANE = 'uprince/LOAD_WORK_FLOW_LSITPANE';
export const LOAD_WORK_FLOW_LSITPANE_SUCCESS = 'uprince/LOAD_WORK_FLOW_LSITPANE_SUCCESS';
export const LOAD_WORK_FLOW_LSITPANE_FAIL = 'uprince/LOAD_WORK_FLOW_LSITPANE_FAIL';

export const LOAD_WORK_FLOW_DROPDOWN = 'uprince/LOAD_WORK_FLOW_DROPDOWN';
export const LOAD_WORK_FLOW_DROPDOWN_SUCCESS = 'uprince/LOAD_WORK_FLOW_DROPDOWN_SUCCESS';
export const LOAD_WORK_FLOW_DROPDOWN_FAIL = 'uprince/LOAD_WORK_FLOW_DROPDOWN_FAIL';

export const SAVE_WORK_FLOW = 'uprince/SAVE_WORK_FLOW';
export const SAVE_WORK_FLOW_SUCCESS = 'uprince/SAVE_WORK_FLOW_SUCCESS';
export const SAVE_WORK_FLOW_FAIL = 'uprince/SAVE_WORK_FLOW_FAIL';

export const LOAD_WORK_FLOW_BY_ID = 'uprince/LOAD_WORK_FLOW_BY_ID';
export const LOAD_WORK_FLOW_BY_ID_SUCCESS = 'uprince/LOAD_WORK_FLOW_BY_ID_SUCCESS';
export const LOAD_WORK_FLOW_BY_ID_FAIL = 'uprince/LOAD_WORK_FLOW_BY_ID_FAIL';

export const LOAD_BOR_RESOURCES_BY_ID = 'uprince/LOAD_BOR_RESOURCES_BY_ID';
export const LOAD_BOR_RESOURCES_BY_ID_SUCCESS = 'uprince/LOAD_BOR_RESOURCES_BY_ID_SUCCESS';
export const LOAD_BOR_RESOURCES_BY_ID_FAIL = 'uprince/LOAD_BOR_RESOURCES_BY_ID_FAIL';

export const SET_WORK_FLOW_UID = 'uprince/SET_WORK_FLOW_UID';
export const CLEAR_WORK_FLOW_UID = 'uprince/CLEAR_WORK_FLOW_UID';
export const CLEAR_WORK_FLOW_FORM_DATA = 'uprince/CLEAR_WORK_FLOW_FORM_DATA';
export const RESET_WORK_FLOW_IS_CHANGE = 'uprice/RESET_WORK_FLOW_IS_CHANGE';


export const CLEAR_WORK_FLOWUID = 'uprice/CLEAR_WORK_FLOWUID';


export const APPROVE_WORK_FLOW = 'uprince/APPROVE_WORK_FLOW';
export const APPROVE_WORK_FLOW_SUCCESS = 'uprince/APPROVE_WORK_FLOW_SUCCESS';
export const APPROVE_WORK_FLOW_FAIL = 'uprince/APPROVE_WORK_FLOW_FAIL';

import {
  INSTRUCTION_REGISTER_SAVE_EP,
  READ_INSTRUCTION_REGISTER_BY_ID_EP,
  READ_INSTRUCTION_REGISTER_DROPDOWN_EP,
  READ_INSTRUCTION_REGISTER_LIST_EP,
  READ_INSTRUCTION_REGISTER_SHORTCUT_PANE_EP,
  UPLOAD_INSTRUCTION_REGISTER_DOC_EP,
  UPLOAD_WBS_SHAREPOINT_DOC_EP,
} from '../shared/endpoints';
import {
  CLEAR_INSTRUCTION_REGISTER_FORM_DATA,
  CLEAR_INSTRUCTION_REGISTERUID,
  LOAD_INSTRUCTION_REGISTER_BY_ID,
  LOAD_INSTRUCTION_REGISTER_BY_ID_FAIL,
  LOAD_INSTRUCTION_REGISTER_BY_ID_SUCCESS,
  LOAD_INSTRUCTION_REGISTER_DROPDOWN,
  LOAD_INSTRUCTION_REGISTER_DROPDOWN_FAIL,
  LOAD_INSTRUCTION_REGISTER_DROPDOWN_SUCCESS,
  LOAD_INSTRUCTION_REGISTER_LSITPANE,
  LOAD_INSTRUCTION_REGISTER_LSITPANE_FAIL,
  LOAD_INSTRUCTION_REGISTER_LSITPANE_SUCCESS,
  LOAD_INSTRUCTION_REGISTER_SHORTCUTPANE,
  LOAD_INSTRUCTION_REGISTER_SHORTCUTPANE_FAIL,
  LOAD_INSTRUCTION_REGISTER_SHORTCUTPANE_SUCCESS,
  RESET_INSTRUCTION_REGISTER_IS_CHANGE,
  SAVE_INSTRUCTION_REGISTER,
  SAVE_INSTRUCTION_REGISTER_FAIL,
  SAVE_INSTRUCTION_REGISTER_SUCCESS,
  SAVE_INSTRUCTION_STATE_ATTR,
  SET_INSTRUCTION_REGISTER_UID,
  UPLOAD_INSTRUCTION_REGISTER_DOC,
  UPLOAD_INSTRUCTION_REGISTER_DOC_FAIL,
  UPLOAD_INSTRUCTION_REGISTER_DOC_SUCCESS,
  UPLOAD_WBS_SHAREPOINT_DOC,
  UPLOAD_WBS_SHAREPOINT_DOC_FAIL,
  UPLOAD_WBS_SHAREPOINT_DOC_SUCCESS,
} from '../actionTypes/instructionRegisterActionTypes';

import i18n from '../../i18n';
import { v4 as uuidv4 } from 'uuid';
import {
  Instruction,
  InstructionRegisterListPaneFilter,
} from '../types/instructionRegister';

const defaultState: any = {
  irList: [],
  isIRListLoaded: false,
  isNew: false,
  isChange: false,
  isEdit: false,
  showProgressBar: false,
  irDropDowns: {},
  formData: {},
  uid: null,
  loadMsg: '',
  irShortCutPaneData: [],
  reloadListPane: false,
  borResources: {},
  isLoadingInstructionRegister: false,
  irId: null,
  sharepointDocUploadStatus: false,
};

export default function instructionRegisterReducer(
  state = defaultState,
  action: any
) {
  if (action) {
    switch (action.type) {
      case LOAD_INSTRUCTION_REGISTER_SHORTCUTPANE:
        return { ...state };
      case LOAD_INSTRUCTION_REGISTER_SHORTCUTPANE_SUCCESS:
        return {
          ...state,
          irShortCutPaneData: action.payload.data.result,
        };
      case LOAD_INSTRUCTION_REGISTER_SHORTCUTPANE_FAIL:
        return { ...state, irShortCutPaneData: [] };
      case LOAD_INSTRUCTION_REGISTER_LSITPANE:
        return { ...state, isIRListLoaded: false };
      case LOAD_INSTRUCTION_REGISTER_LSITPANE_SUCCESS:
        return {
          ...state,
          reloadListPane: false,
          isIRListLoaded: true,
          irList: formattedPayloadToListPaneTable(action.payload.data.result),
          loadMsg: !action.payload.data.status
            ? action.payload.data.message
            : '',
        };
      case LOAD_INSTRUCTION_REGISTER_LSITPANE_FAIL:
        return {
          ...state,
          isIRListLoaded: true,
          irList: [],
          loadMsg: action.error.response.data.message,
        };
      case RESET_INSTRUCTION_REGISTER_IS_CHANGE:
        return {
          ...state,
          isChange: false,
        };

      case LOAD_INSTRUCTION_REGISTER_DROPDOWN:
        return { ...state, irDropDowns: {} };
      case LOAD_INSTRUCTION_REGISTER_DROPDOWN_SUCCESS:
        return { ...state, irDropDowns: action.payload.data.result };
      case LOAD_INSTRUCTION_REGISTER_DROPDOWN_FAIL:
        return {
          ...state,
          irDropDowns: {},
        };
      case LOAD_INSTRUCTION_REGISTER_BY_ID:
        return {
          ...state,
          formData: {},
          showProgressBar: true,
          isLoadingInstructionRegister: true,
        };
      case LOAD_INSTRUCTION_REGISTER_BY_ID_SUCCESS:
        return {
          ...state,
          isEdit: true,
          formData: action.payload.data.result,
          showProgressBar: false,
          isLoadingInstructionRegister: false,
        };
      case LOAD_INSTRUCTION_REGISTER_BY_ID_FAIL:
        return {
          ...state,
          formData: {},
          showProgressBar: false,
          isLoadingInstructionRegister: false,
        };

      case SAVE_INSTRUCTION_REGISTER:
        return {
          ...state,
          showProgressBar: true,
          reloadListPane: false,
          irId: null,
        };
      case SAVE_INSTRUCTION_REGISTER_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          reloadListPane: true,
          irId: action.payload.data.result?.sequenceCode,
        };
      case SAVE_INSTRUCTION_REGISTER_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
          irId: null,
        };

      case SET_INSTRUCTION_REGISTER_UID:
        return {
          ...state,
          isEdit: false,
          uid: uuidv4(),
        };
      case CLEAR_INSTRUCTION_REGISTERUID:
        return {
          ...state,
          uid: null,
          isChange: false,
        };
      case CLEAR_INSTRUCTION_REGISTER_FORM_DATA:
        return {
          ...state,
          uid: null,
          isNew: true,
          isEdit: false,
          isChange: false,
          formData: {},
        };

      case UPLOAD_INSTRUCTION_REGISTER_DOC:
        return {
          ...state,
          uploadedInstructionLink: null,
          instructionUploadStatus: false,
          showProgressBar: true,
        };
      case UPLOAD_INSTRUCTION_REGISTER_DOC_SUCCESS:
        return {
          ...state,
          instructionUploadStatus: true,
          uploadedInstructionLink: action.payload.data.result,
          showProgressBar: false,
        };
      case UPLOAD_WBS_SHAREPOINT_DOC_SUCCESS:
        console.log(
          'UPLOAD_INSTRUCTION_REGISTER_DOC_SUCCESS',
          action.payload.data.result
        );
        return {
          ...state,
          sharepointDocUploadStatus: true,
          uploadedSharePointLink: action.payload.data.result,
          showProgressBar: false,
        };
      case UPLOAD_INSTRUCTION_REGISTER_DOC_FAIL:
        return {
          ...state,
          instructionUploadStatus: false,
          uploadedInstructionLink: null,
          showProgressBar: false,
        };

      case SAVE_INSTRUCTION_STATE_ATTR:
        const attr = Object.keys(action)[1];
        const value = Object.values(action)[1];
        return {
          ...state,
          [attr]: value,
        };

      default:
        return state;
    }
  }
}

export const uploadInstructionDoc = (formData: any) => {
  return {
    types: [
      UPLOAD_INSTRUCTION_REGISTER_DOC,
      UPLOAD_INSTRUCTION_REGISTER_DOC_SUCCESS,
      UPLOAD_INSTRUCTION_REGISTER_DOC_FAIL,
    ],
    payload: {
      request: {
        url: UPLOAD_INSTRUCTION_REGISTER_DOC_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const uploadWBSharePointDoc = (formData: any) => {
  return {
    types: [
      UPLOAD_WBS_SHAREPOINT_DOC,
      UPLOAD_WBS_SHAREPOINT_DOC_SUCCESS,
      UPLOAD_WBS_SHAREPOINT_DOC_FAIL,
    ],
    payload: {
      request: {
        url: UPLOAD_WBS_SHAREPOINT_DOC_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const readInstructionRegisterShortCutPaneData = () => {
  return {
    types: [
      LOAD_INSTRUCTION_REGISTER_SHORTCUTPANE,
      LOAD_INSTRUCTION_REGISTER_SHORTCUTPANE_SUCCESS,
      LOAD_INSTRUCTION_REGISTER_SHORTCUTPANE_FAIL,
    ],
    payload: { request: { url: READ_INSTRUCTION_REGISTER_SHORTCUT_PANE_EP } },
  };
};

export const readInstructionRegisterDropDownData = () => {
  return {
    types: [
      LOAD_INSTRUCTION_REGISTER_DROPDOWN,
      LOAD_INSTRUCTION_REGISTER_DROPDOWN_SUCCESS,
      LOAD_INSTRUCTION_REGISTER_DROPDOWN_FAIL,
    ],
    payload: {
      request: { method: 'GET', url: READ_INSTRUCTION_REGISTER_DROPDOWN_EP },
    },
  };
};

export const filterInstructionRegisterListPaneData = (
  filter: InstructionRegisterListPaneFilter
) => {
  return {
    types: [
      LOAD_INSTRUCTION_REGISTER_LSITPANE,
      LOAD_INSTRUCTION_REGISTER_LSITPANE_SUCCESS,
      LOAD_INSTRUCTION_REGISTER_LSITPANE_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_INSTRUCTION_REGISTER_LIST_EP,
        data: filter,
      },
    },
  };
};

export const readByInstructionRegisterId = (id: string) => {
  return {
    types: [
      LOAD_INSTRUCTION_REGISTER_BY_ID,
      LOAD_INSTRUCTION_REGISTER_BY_ID_SUCCESS,
      LOAD_INSTRUCTION_REGISTER_BY_ID_FAIL,
    ],
    payload: { request: { url: READ_INSTRUCTION_REGISTER_BY_ID_EP + id } },
  };
};

export const resetIsChange = () => {
  return { type: RESET_INSTRUCTION_REGISTER_IS_CHANGE };
};

export const saveFormData = (formData: Instruction) => {
  return {
    types: [
      SAVE_INSTRUCTION_REGISTER,
      SAVE_INSTRUCTION_REGISTER_SUCCESS,
      SAVE_INSTRUCTION_REGISTER_FAIL,
    ],
    payload: {
      request: {
        url: INSTRUCTION_REGISTER_SAVE_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const setUid = () => {
  return { type: SET_INSTRUCTION_REGISTER_UID };
};

export const clearFormData = () => {
  return { type: CLEAR_INSTRUCTION_REGISTER_FORM_DATA };
};

export function saveInstructionStateAttr(attr: string, value: any) {
  return {
    type: SAVE_INSTRUCTION_STATE_ATTR,
    [attr]: value,
  };
}

const getInstructionType = (type: any) => {
  let instype = null;
  switch (type) {
    case '100':
      instype = i18n.t('technical');
      break;
    case '200':
      instype = i18n.t('environment');
      break;
    case '300':
      instype = i18n.t('safety');
      break;
    case '400':
      instype = i18n.t('health');
      break;
  }
  return instype;
};

const formattedPayloadToListPaneTable = (response: any) => {
  if (response) {
    const data = response.map((item: any) => {
      return {
        id: item.sequenceCode,
        sequenceCode: item.sequenceCode,
        name: item.name,
        family: item.pbsInstructionFamily?.family,
        instructionType: getInstructionType(item.instructionType),
        title: item.title,
        // links: item.pbsInstructionLink.link
      };
    });
    return data;
  }
  return [];
};

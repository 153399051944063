import React from 'react';
import {
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Stack,
  TextField,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../../theme';
import { Invoice, InvoiceValidationMessage } from '../../../../types/invoice';


const theme = getTheme();

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

// Styles definition
const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};


const classNames = mergeStyleSets({
  fullWidth: { width: '100%' },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  bigScreenTree: {
    marginBottom: 30,
    height: '30vh',
  },
  smallScreenTree: {
    marginBottom: 30,
    height: '56vh',
  },
  textEditor: { height: window.innerWidth <= 1280 ? '50vh' : '25vh' },
});

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };


const InvoiceAmount = (props: {
  isEdit: boolean,
  dropDownOptions: any,
  formData: Invoice;
  saveFormData: () => void;
  handleFormChange: (
    invoice: Invoice,
    save: boolean,
  ) => void;
  validationMessages: InvoiceValidationMessage;
}) => {
  const { t } = useTranslation();
  // const options: IChoiceGroupOption[] = [
  //     { key: '0', text: t('inScope"'), checked: true },
  //     { key: '1', text: t('outofScope"'), styles: { field: { marginLeft: '15px' } } }
  // ];
  // const [contractIsRequired, setcontractIsRequired] = useState(false);

  return (
    <div className={'document-pane-card'}>

      <div className={'card-header'}>
        <Link href="#" id="invoice-invoice-amount">
          <Label>3. {t('Invoice')} </Label>
        </Link>
      </div>

      <div className={'card-body'}>
        <div className="proj-detail-content inner">


          <div className="marginTop">
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    style={{ textAlign: 'right' }}
                    label={t('totalAmountexVat')}
                    value={
                      props.formData.totalExcludingTax
                        ? props.formData.totalExcludingTax.toFixed(2).toString()
                        : ''
                    }
                    errorMessage={
                      props.validationMessages.nameErrorMsg
                        ? props.validationMessages.nameErrorMsg
                        : ''
                    }
                    required={true}
                    onChange={(event, value) => {
                      if (value) {
                        props.formData.name = value;
                        props.validationMessages.nameErrorMsg = '';
                      } else {
                        props.formData.name = null;
                      }
                      props.handleFormChange(
                        props.formData,
                        true,
                      );
                    }}
                    disabled={true}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    style={{ textAlign: 'right' }}
                    label={t('totalAmountInVat')}
                    value={
                      props.formData.totalIncludingTax
                        ? props.formData.totalIncludingTax.toFixed(2).toString()
                        : ''
                    }
                    errorMessage={
                      props.validationMessages.nameErrorMsg
                        ? props.validationMessages.nameErrorMsg
                        : ''
                    }
                    required={true}
                    onChange={(event, value) => {
                      if (value) {
                        props.formData.name = value;
                        props.validationMessages.nameErrorMsg = '';
                      } else {
                        props.formData.name = null;
                      }
                      props.handleFormChange(
                        props.formData,
                        true,
                      );
                    }}
                    disabled={true}
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>


        </div>
      </div>

      <div className={'card-footer'}>
        <br/>
      </div>

    </div>
  );
};


export default InvoiceAmount;

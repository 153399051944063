import React, { Component } from 'react';
import UprinceNavigation from '../../shared/uprinceNavigation/cuProjectNavigation/component';
import { getContractingUnit, getProject } from '../../shared/util';
import PurchaseOrderMainLayout from './purchaseOrderMainLayout';


interface Props {
  currentUser: any
}

interface State {
  isContractUnit: boolean;
  isProject: boolean;
}

export class PurchaseOrder extends Component<Props, State> {
  render() {
    return (
      <UprinceNavigation
        currentUser={this.props.currentUser}
        selectedNavigationIndex={getProject() ? `/CU/${getContractingUnit()}/project/${getProject()}/purchase-order` : `/CU/${getContractingUnit()}/purchase-order`}
      >
        <PurchaseOrderMainLayout/>
      </UprinceNavigation>
    );
  }
}

export default (PurchaseOrder);

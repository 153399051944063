export const SAVE_TIME_REGISTRATION_STATE_ATTR = 'uprince/SAVE_TIME_REGISTRATION_STATE_ATTR';

export const CREATE_PMOL_TIME_REGISTRATION_COMMENT = 'uprince/CREATE_PMOL_TIME_REGISTRATION_COMMENT';
export const CREATE_PMOL_TIME_REGISTRATION_COMMENT_SUCCESS = 'uprince/CREATE_PMOL_TIME_REGISTRATION_COMMENT_SUCCESS';
export const CREATE_PMOL_TIME_REGISTRATION_COMMENT_FAIL = 'uprince/CREATE_PMOL_TIME_REGISTRATION_COMMENT_FAIL';

export const READ_MAP_DATA_VISUALIZATION = 'uprince/READ_MAP_DATA_VISUALIZATION';
export const READ_MAP_DATA_VISUALIZATION_SUCCESS = 'uprince/READ_MAP_DATA_VISUALIZATION_SUCCESS';
export const READ_MAP_DATA_VISUALIZATION_FAIL = 'uprince/READ_MAP_DATA_VISUALIZATION_FAIL';

export const READ_PMOL_PERSON_LIST = 'uprince/READ_PMOL_PERSON_LIST';
export const READ_PMOL_PERSON_LIST_SUCCESS = 'uprince/READ_PMOL_PERSON_LIST_SUCCESS';
export const READ_PMOL_PERSON_LIST_FAIL = 'uprince/READ_PMOL_PERSON_LIST_FAIL';

export const READ_PMOL_BY_PERSON = 'uprince/READ_PMOL_BY_PERSON';
export const READ_PMOL_BY_PERSON_SUCCESS = 'uprince/READ_PMOL_BY_PERSON_SUCCESS';
export const READ_PMOL_BY_PERSON_FAIL = 'uprince/READ_PMOL_BY_PERSON_FAIL';

export const READ_TR_APP_DATA_BY_PERSON = 'uprince/READ_TR_APP_DATA_BY_PERSON';
export const READ_TR_APP_DATA_BY_PERSON_SUCCESS = 'uprince/READ_TR_APP_DATA_BY_PERSON_SUCCESS';
export const READ_TR_APP_DATA_BY_PERSON_FAIL = 'uprince/READ_TR_APP_DATA_BY_PERSON_FAIL';

export const READ_PMOL_VEHICLE_POSITIONS = 'uprince/READ_PMOL_VEHICLE_POSITIONS';
export const READ_PMOL_VEHICLE_POSITIONS_SUCCESS = 'uprince/READ_PMOL_VEHICLE_POSITIONS_SUCCESS';
export const READ_PMOL_VEHICLE_POSITIONS_FAIL = 'uprince/READ_PMOL_VEHICLE_POSITIONS_FAIL';

export const CLEAR_TR_FORM_DATA = 'uprince/CLEAR_TR_FORM_DATA';

export const READ_VTS_DATA_BY_PERSON = 'uprince/READ_VTS_DATA_BY_PERSON';
export const READ_VTS_DATA_BY_PERSON_SUCCESS = 'uprince/READ_VTS_DATA_BY_PERSON_SUCCESS';
export const READ_VTS_DATA_BY_PERSON_FAIL = 'uprince/READ_VTS_DATA_BY_PERSON_FAIL';

export const READ_TOTALS_FOR_TR_MODAL = 'uprince/READ_TOTALS_FOR_TR_MODAL';
export const READ_TOTALS_FOR_TR_MODAL_SUCCESS = 'uprince/READ_TOTALS_FOR_TR_MODAL_SUCCESS';
export const READ_TOTALS_FOR_TR_MODAL_FAIL = 'uprince/READ_TOTALS_FOR_TR_MODAL_FAIL';

export const READ_PERSON_COMMENT_CARD = 'uprince/READ_PERSON_COMMENT_CARD';
export const READ_PERSON_COMMENT_CARD_SUCCESS = 'uprince/READ_PERSON_COMMENT_CARD_SUCCESS';
export const READ_PERSON_COMMENT_CARD_FAIL = 'uprince/READ_PERSON_COMMENT_CARD_FAIL';

export const UPDATE_PMOL_TIME_REGISTRATION_COMMENT = 'uprince/UPDATE_PMOL_TIME_REGISTRATION_COMMENT';
export const UPDATE_PMOL_TIME_REGISTRATION_COMMENT_SUCCESS = 'uprince/UPDATE_PMOL_TIME_REGISTRATION_COMMENT_SUCCESS';
export const UPDATE_PMOL_TIME_REGISTRATION_COMMENT_FAIL = 'uprince/UPDATE_PMOL_TIME_REGISTRATION_COMMENT_FAIL';

export const READ_PMOL_COMMENT_CARD = 'uprince/READ_PMOL_COMMENT_CARD';
export const READ_PMOL_COMMENT_CARD_SUCCESS = 'uprince/READ_PMOL_COMMENT_CARD_SUCCESS';
export const READ_PMOL_COMMENT_CARD_FAIL = 'uprince/READ_PMOL_COMMENT_CARD_FAIL';
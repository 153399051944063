import React from 'react';
import {
    DefaultButton,
    Dropdown,
    IStackItemStyles,
    IStackStyles,
    IStackTokens,
    mergeStyleSets,
    PrimaryButton,
    Stack,
    TextField
} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import {Field, Form} from 'react-final-form';
import PeoplePicker from '../../../../../shared/fluentFinalForm/PeoplePicker';
import moment from 'moment';
import {CommentType, personCommentData} from "../../../../../types/timeRegistration";
import {
    readPersonCommentCard,
    readPmolCommentCard,
    updateComment
} from "../../../../../reducers/timeRegistrationReducer";
import {useDispatch, useSelector} from "react-redux";
import MultiLineTextFieldWrapper from "../../../../../shared/fluentFinalForm/MultilineTextField";

const stackStyles: IStackStyles = {
    root: {
        padding: 0,
    },
};

const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
};

const stackItemStyles: IStackItemStyles = {
    root: {
        display: 'flex',
        flex: '1 1 auto',
        height: 50,
        flexGrow: 1,
        width: 0,
        margin: 5,
        textOverflow: 'ellipsis',
        maxWidth: 'calc(100% - 10px)',
    },
};

const classNames = mergeStyleSets({
    dateTime: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    fullWidth: {width: '100%'},
    halfWidth: {width: '49%'},
});

interface Props {
    cardItem: any,
    commentType: string,
    values?: any,
}

const CommentContent = (props: Props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {themeColor} = useSelector((state: any) => state.uprince);

    const onSubmit = async (values: any) => {
        const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
        await sleep(300);

        Promise.all([
            dispatch(updateComment({
                commentCardId: values.commentCardId,
                comment: values.comment,
                commentTo: props.commentType === CommentType.PERSON ? values.personId : null,
                isAccept: values.isAccept
            }))
        ]).then((response: any) => {
            if (response) {
                console.log(response)
                if (props.commentType === CommentType.PERSON) {
                    dispatch(readPersonCommentCard({pmolId: response?.payload?.data?.result}));
                } else {
                    dispatch(readPmolCommentCard({pmolId: response[0]?.payload?.data?.result}));
                }
            }
        });
    };

    const getInitialFormValues = (formData: any, formValue: any) => {
        if (formData) {
            // console.log('formData >', formData);
            return {
                activityName: props?.values?.activityName,
                activityType: props?.values?.activityType,
                id: formData.id,
                commentCardId: formData?.commentCardId,
                comment: formData?.comment,
                creatorID: formData.commentBy,
                creatorName: formData.commentByName,
                personId: formData?.commentBy,
                personName: formData?.commentToName,
                date: formData?.date,
                isAccept: formData.isAccept ? formData.isAccept : "0"
            };
        }

        return formValue;
    };

    return <div>
        <Form
          onSubmit={onSubmit}
          initialValues={getInitialFormValues(props?.cardItem, personCommentData)}
          mutators={{
              setIsAccept: (args, state, utils) => {
                  const field = state.fields.isAccept;
                  field.change(args[0]);
              },
          }}
          render={({
                       handleSubmit,
                       form,
                       submitting,
                       pristine,
                       values
                   }) => {
              return (
                <form onSubmit={handleSubmit} noValidate>
                    <div>
                        {props.commentType === CommentType.PMOL && <Stack
                            horizontal
                            wrap
                            styles={stackStyles}
                            tokens={stackTokens}
                        >
                            <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className={`${classNames.fullWidth}`}>
                                    <Field name="activityType">
                                        {({input, meta}) => (
                                          <Dropdown
                                            options={[{key: 'regular', text: `${t('regular')}`}, {
                                                key: 'sub',
                                                text: `${t('sub')}`
                                            }]}
                                            label={t('activityType')}
                                            onChange={(e, value) => {
                                            }}
                                            placeholder={t('activityType')}
                                            disabled={false}
                                            selectedKey={values?.activityType}
                                            style={{marginRight: '1rem', width: '100%'}}
                                          />
                                        )}
                                    </Field>
                                </div>
                            </Stack.Item>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className={`${classNames.fullWidth}`}>
                                    <Field name="activityName">
                                        {({input, meta}) => (
                                          <TextField
                                            value={values?.activityName}
                                            label={t('activityName')}
                                            onChange={(e, value) => {
                                            }}
                                            disabled={false}
                                          />
                                        )}
                                    </Field>
                                </div>
                            </Stack.Item>
                        </Stack>}

                        <div style={{margin: '10px 10px 0px 10px'}}>
                            <Field
                              name="comment"
                              component={MultiLineTextFieldWrapper}
                              // label={t('comment')}
                              disabled={true}
                              rows={5}
                            />
                        </div>

                        <Stack
                          horizontal
                          wrap
                          styles={stackStyles}
                          tokens={stackTokens}
                        >
                            <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className={`${classNames.fullWidth}`}>
                                    <Field name="creatorID">
                                        {({input, meta}) => (
                                          <PeoplePicker
                                            //name="producer"
                                            label={t('')}
                                            isDisabled={true}
                                            selectedItem={
                                                [
                                                    {
                                                        key: values?.creatorID,
                                                        text: values?.creatorName,
                                                    }
                                                ]
                                            }
                                            onChange={(items: any) => {
                                                input.onChange(null);
                                                return items;
                                            }}
                                            onPersonSelected={(item: any) => {
                                                input.onChange({id: item?.cabPersonId, name: item?.text});
                                                return item;
                                            }}
                                          />
                                        )}
                                    </Field>
                                </div>
                            </Stack.Item>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className={`${classNames.dateTime}`}>
                                    <span>{moment(values?.date).format('DD/MM')} {moment(values?.date).format('HH:mm')}</span>
                                </div>
                            </Stack.Item>
                        </Stack>

                        {<Stack horizontal>
                            <Field
                              name="isAccept"
                              component={'input'}
                              type={'text'}
                              disabled={true}
                              hidden
                            />
                            <DefaultButton
                              text={t('reject')}
                              disabled={values.isAccept != "0"}
                              style={{width: '100%', border: '0.25px solid rgba(0, 0, 0, 0.25)'}}
                              onClick={() => {
                                  form.mutators.setIsAccept("2");
                                  form.submit();
                              }}
                            />
                            <PrimaryButton
                              text={t('accept')}
                              disabled={values.isAccept != "0"}
                              style={{width: '100%'}}
                              onClick={() => {
                                  form.mutators.setIsAccept("1");
                                  form.submit();
                              }}
                            />
                        </Stack>}
                    </div>

                    {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                </form>
              )
          }}
        />
    </div>;
};

export default CommentContent;
import {
  DefaultPalette,
  getTheme,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  ITheme,
  Panel,
  PanelType,
  Stack,
} from '@fluentui/react';
import moment from 'moment';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import SplitPane, { Pane } from 'react-split-pane';
import history from '../../history';
import { getDropdownData, getProductCatalogueById } from '../../reducers/productCatalogueReducer';
import { readByPurchaseOrderId, readPurchaseOrderDropDownData } from '../../reducers/purchaseOrderReducer';
import {
  clearFormData,
  filterVisualPlanListPaneData,
  GetVPFilterDropdownData,
  readByVisualPlanId,
  readCUHRbyPersonId,
  readVisualPlanDropDownData,
  readVisualPlanShortCutPaneData,
  readVPOrganizationTaxanomyData,
  readVPWareHouseTaxanomyData,
  saveVPStateAttr,
  readPurchaseAndDeliveryTaxonomyDataCU,
  readCpcResourceFamilyforVp,
  readResourceTaxonmyDataVP
} from '../../reducers/visualPlanReducer';
import { messageService } from '../../services/messageService';
import ConfirmationDialog from '../../shared/confirmationDialog/confirmationDialog';
import DocumentPaneSection from '../../shared/mainUI/documentPaneSection/documentPaneSection';
import DocumentPaneSectionVp from '../../shared/mainUI/documentPaneSection/documentPaneSectionVp';
import ListPaneSection from '../../shared/mainUI/listPaneSection/component';
import withMainUI from '../../shared/mainUI/withMainUI';
import TitlePane from '../../shared/titlePane/titlePane';
import { formatDateTimeToISOString, getContractingUnit, RESOURCE_TYPE_IDS } from '../../shared/util';
import { CorporateProductCatalogue } from '../../types/corporateProductCatalogue';
import { OrganisationSettingListPaneFilter } from '../../types/organisationSetting';
import { pmolFormData, ProjectMolecule } from '../../types/projectMolecule';
import { GOODDEERIS_PRODUCT_TYPE_ID, PRODUCT_TYPE_ID, RESOURCE_TYPE_ID } from '../../types/purchaseOrder';
import { BookMarkItem } from '../../types/template';
import { VisualPlanListItem, VisualPlanListPaneFilter, VPShortCutPaneItem } from '../../types/visualPlan';
import CpcDocumentPane from '../dayPlanning/documentPane/cpcContainer';
import DocumentPane from '../purchaseOrder/documentPane/container';
import '../style/style.css';
import CommandBarLeft from './commandBar/commandBarLeft';
import CommandBarRight from './commandBar/commandBarRight';
import ListPane from './listPane/container';
import OrgListPane from './orgListPane/container';
import PurchaseAndDeliveryComponentCU from './purchaseAndDeliveryBor/component';
import ShortCutPane from './shortCutPane/container';
import ShortCutPaneTaxonomy from './shortCutPaneTaxonomy/container';


// Styles definition
const stackStyles: IStackStyles = {
  root: {
    background: DefaultPalette.themeTertiary,
    height: '100%',
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    alignItems: 'center',
    background: DefaultPalette.themePrimary,
    color: DefaultPalette.white,
    display: 'flex',
    height: 50,
    justifyContent: 'center',
  },
};

// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 5,
  padding: 10,
};

export interface State {
  selectedShortCutItem: VPShortCutPaneItem;
  filter: VisualPlanListPaneFilter;
  formData: ProjectMolecule;
  loading: boolean;
  showPanel: boolean;
  width: number;
  activeClass: string;
  bookmarkDivClass: string;
  showDetails: boolean;
  autoSave: boolean;
  isEdit: boolean;
  isDisableNewButton: boolean;
  isDrag: boolean;
  resetLayout: boolean;
  collapseBookmarkPane: boolean;
  topReSizer: number;
  bottomReSizer: number;
  horizontalReSizer: number;
  projectId: null | string;
  isMinimize: boolean;
  collapseBottomBookmarkPane: boolean;
}

interface Props extends WithTranslation {
  match: any;
  resetFormData: () => void;
  showProgressBar: boolean;
  shortCutPaneFilters: VPShortCutPaneItem[];
  formData: any;
  setUid: () => void;
  clearUid: () => void;
  resetIsChange: () => void;
  uid: string | null;
  autoSave: boolean;
  isChange: boolean;
  isEdit: boolean;
  isDataLoaded?: boolean;
  loadMsg: string;
  location: any;
  loading: boolean;
  handleShowDocumentPane: () => void;
  topReSizer: number;
  bottomReSizer: number;
  horizontalReSizer: number;
  showDetails: boolean;
  showPanel: boolean;
  handleScroll: () => void;
  currentActiveSection: string;
  animation: any;
  readVisualPlanShortCutPaneData: () => void;
  filterVisualPlanListPaneData: (filter: VisualPlanListPaneFilter) => any;
  readVPWareHouseTaxanomyData: (data: { wareHouseId: null }) => void;
  // readVPWareHouseToolsTaxanomyData: (data: { wareHouseId: null }) => void;
  readCpcResourceFamilyforVp: (data: { wareHouseId: null }) => void;
  readVisualPlanDropDownData: () => void;
  readByVisualPlanId: (id: string) => void;
  clearFormData: () => void;
  shortcutPaneData: VPShortCutPaneItem[];
  readPurchaseOrderDropDownData: any;
  readVPOrganizationTaxanomyData: (data: any) => void;

  vpList: VisualPlanListItem[];
  vpOrgTaxonomyList: any[];
  isVPListLoaded: boolean;
  vpDropDowns: any;
  reloadListPane: boolean;
  purchaseOrderDropDowns: any;
  readByPurchaseOrderId: (id: string) => void;
  getDropdownData: any;
  reloadRequired: boolean;
  poSelectedResources: any;
  showToolsOrLabourDocumentPane: boolean;
  showPODocumentPane: boolean;
  selectedResourceTypeOrg: string;
  saveVPStateAttr: any;
  selectedLabour: string;
  cuhrFormData: any;
  selectedTool: string;
  getProductCatalogueById: any;
  selectedPO: string;
  productCatalogue: any;
  poSelectedTypes: any;
  poFilterFromDate: string;
  poFilterToDate: string;
  readCUHRbyPersonId: any;
  selectedStartDateCu: string;
  selectedEndDateCu: string,
  selectedPOResourceTypes: any[];
  selectedPOProject: string;
  selectedPOVPshortcutpaneItems: any[];
  GetVPFilterDropdownData: any;
  cpcFormData: CorporateProductCatalogue;
  vpShortCutPaneData:any;
  vpCUSelectedShortcutpaneType:any;
  readPurchaseAndDeliveryTaxonomyDataCU:any;
  readResourceTaxonmyDataVP:any
}

class VisualPlanMainLayout extends React.Component<Props, State> {
  _isMounted = false;
  theme: ITheme = getTheme();
  subscription: any;

  bookmarkList: BookMarkItem[] = [
    {
      id: 1,
      link: 'po-header',
      label: this.props.t('purchaseOrderHeader'),
    },
    {
      id: 2,
      link: 'po-delivery-details',
      label: this.props.t('deliveryDetails'),
    },
    {
      id: 3,
      link: 'po-involved-parties',
      label: this.props.t('involvedParties'),
    },
    {
      id: 4,
      link: 'history',
      label: this.props.t('history'),
    },
  ];
  bookmarkListProduct: BookMarkItem[] = [
    {
      id: 1,
      link: 'po-header',
      label: this.props.t('purchaseOrderHeader'),
    },
    {
      id: 2,
      link: 'po-delivery-details',
      label: this.props.t('deliveryDetails'),
    },
    {
      id: 3,
      link: 'po-involved-parties',
      label: this.props.t('involvedParties'),
    },
    { id: 4, link: 'po-external-products', label: this.props.t('externalProducts') },
    {
      id: 5,
      link: 'history',
      label: this.props.t('history'),
    },
  ];
  bookmarkListResource: BookMarkItem[] = [
    {
      id: 1,
      link: 'po-header',
      label: this.props.t('purchaseOrderHeader'),
    },
    {
      id: 2,
      link: 'po-delivery-details',
      label: this.props.t('deliveryDetails'),
    },
    {
      id: 3,
      link: 'po-involved-parties',
      label: this.props.t('involvedParties'),
    },
    { id: 4, link: 'po-resources', label: this.props.t('resources') },
    {
      id: 5,
      link: 'history',
      label: this.props.t('history'),
    },
  ];
  cuBookmarkList: BookMarkItem[] = [
    {
      id: 1,
      link: 'labour',
      label: this.props.t('labour'),
    },
    {
      id: 2,
      link: 'competencies',
      label: this.props.t('competencies'),
    },
    {
      id: 3,
      link: 'certifications',
      label: this.props.t('certifications'),
    },
    {
      id: 4,
      link: 'labourHistory',
      label: this.props.t('labourHistory'),
    },
    {
      id: 5,
      link: 'workSchedule',
      label: this.props.t('workSchedule'),
    },
    {
      id: 6,
      link: 'history',
      label: this.props.t('history'),
    },
  ];
  cpcBookmarkList: BookMarkItem[] = [
    {
      id: 1,
      link: 'corporate-product-header',
      label: this.props.t('corporateProductHeader'),
    },
    { id: 2, link: 'vendor', label: this.props.t('vendor') },
    {
      id: 3,
      link: 'history',
      label: this.props.t('history'),
    },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      filter: new VisualPlanListPaneFilter(),
      selectedShortCutItem: {
        id: null,
        key: null,
        name: null,
        type: null,
        value: null,
      },
      isEdit: false,
      autoSave: false,
      loading: false,
      width: window.innerWidth,
      showPanel: false,
      activeClass: 'grid-righter column-filter-activated',
      bookmarkDivClass: 'bookmark-panel-vp parent',
      showDetails: false,
      formData: pmolFormData,
      isDisableNewButton: false,
      isDrag: false,
      resetLayout: false,
      collapseBookmarkPane: false,
      topReSizer: screen.width,
      bottomReSizer: screen.width,
      horizontalReSizer: screen.height - 340,
      projectId: null,
      isMinimize: true,
      collapseBottomBookmarkPane: false,
    };
  }

  componentDidMount() {
    this.handleUrlChange();
    //this.props.readVisualPlanDropDownData()
    this.props.readVisualPlanShortCutPaneData()
    this.props.readPurchaseOrderDropDownData();
    this.props.getDropdownData();
    this.props.GetVPFilterDropdownData();
    this.props.filterVisualPlanListPaneData({
      requestType:  [],
      resourceType: [],
      fromDate: moment().startOf('month').format('YYYY-MM-DD hh:mm'),
      toDate: moment().endOf('month').format('YYYY-MM-DD hh:mm'),
      projectSequenceCode: null,
    }).then(() => {
      this.setGanttChartHeight();
    });
    this.props.readVPOrganizationTaxanomyData(new OrganisationSettingListPaneFilter());
    this.props.readVPWareHouseTaxanomyData({ wareHouseId: null });
    // this.props.readVPWareHouseToolsTaxanomyData({ wareHouseId: null });
    this.props.readCpcResourceFamilyforVp({ wareHouseId: null });

    this.props.saveVPStateAttr('selectedPOVPshortcutpaneItems', []);
    this.props.saveVPStateAttr('selectedStartDateCu', null);
    this.props.saveVPStateAttr('selectedEndDateCu', null);
    this.props.saveVPStateAttr('selectedPOResourceTypes', []);
    this.props.saveVPStateAttr('selectedPOProject', null);

    if (
      location.pathname.split('/').pop() === 'new' &&
      this.state.formData &&
      this.state.formData.id === null
    ) {
      this.createNew();
    }
    this.setState(
      {
        formData: this.props.formData,
      },
      this.props.handleShowDocumentPane,
    );
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.hideDocumentPane) {
          this.hideDocumentPane(data.data.documentPaneType);
        }

        if (data.data.isNewVP) {
          this.createNew();
        }

        if (data.data.collapseDocumentPane) {
          this.setState({
            topReSizer: data.data.documentPaneType === 1 ? this.state.topReSizer === 300 ? screen.width / 2 : 300 : this.state.topReSizer,
            bottomReSizer: data.data.documentPaneType === 2 ? this.state.bottomReSizer === 300 ? screen.width / 2 : 300 : this.state.bottomReSizer,
            isDrag: !this.state.isDrag,
            collapseBookmarkPane: data.data.documentPaneType === 1 ? !this.state.collapseBookmarkPane : this.state.collapseBookmarkPane,
            collapseBottomBookmarkPane: data.data.documentPaneType === 2 ? !this.state.collapseBottomBookmarkPane : this.state.collapseBottomBookmarkPane,
            resetLayout: !this.state.resetLayout,
          });
        }
        if (data.data.minimizeBottomPane) {
          this.setState({
            horizontalReSizer: data.data.isMinimize ? screen.height - 330 : screen.height / 2 - 140,
            isMinimize: data.data.isMinimize,
          });
        }

        if (
          data &&
          data.data &&
          data.data.hasOwnProperty('isLoadingPMOLData') &&
          data.data.isLoadingPMOLData
        ) {
          this.setState({ isDisableNewButton: true });
        } else if (
          data.data.hasOwnProperty('isLoadingPMOLData') &&
          !data.data.isLoadingPMOLData
        ) {
          this.setState({ isDisableNewButton: false });
        }
      }
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.reloadListPane !== prevProps.reloadListPane && this.props.reloadListPane) {
      this.reloadListPaneItem();
    }
    if (this.props.match.url !== prevProps.match.url) {
      this.handleUrlChange();
    }
    if (this.props.formData !== prevProps.formData) {
      this.setState({
        formData: this.props.formData,
        topReSizer: this.props.showPODocumentPane ? screen.width / 2 : screen.width,
      });
    }
    if (
      this.props.location &&
      this.props.location.state &&
      this.state.filter != this.props.location.state.poFilter
    ) {
      if (this.props.location.state.poFilter) {
        this.setState(
          {
            filter: this.props.location.state.poFilter,
          },
          this.reloadListPaneItem,
        );
      }
    }

    if (this.props.reloadRequired) {
      // this.props.filterVisualPlanListPaneData({
      //   requestType: this.props.selectedPOVPshortcutpaneItems,
      //   resourceType: this.props.selectedPOResourceTypes,
      //   fromDate: formatDateTimeToISOString(this.props.selectedStartDateCu),
      //   toDate: formatDateTimeToISOString(this.props.selectedEndDateCu),
      //   projectSequenceCode: this.props.selectedPOProject,
      // }).then(() => {
      //   this.setGanttChartHeight();
      // });
      //console.log("111111111")
      // this.props.readVPOrganizationTaxanomyData(new OrganisationSettingListPaneFilter());
      // this.props.readVPWareHouseTaxanomyData({ wareHouseId: null });
      // this.props.readVPWareHouseToolsTaxanomyData({ wareHouseId: null });
      this.props.readCpcResourceFamilyforVp({ wareHouseId: null });
    }

    if (this.props.selectedLabour && this.props.selectedLabour !== prevProps.selectedLabour) {
      this.props.readCUHRbyPersonId(this.props.selectedLabour);
    }

    if (this.props.selectedTool && this.props.selectedTool !== prevProps.selectedTool) {
      this.props.getProductCatalogueById(this.props.selectedTool);
    }

    if (this.props.selectedPO && this.props.selectedPO !== prevProps.selectedPO) {
      this.props.readByPurchaseOrderId(this.props.selectedPO);
    }
    if (this.props.showPODocumentPane !== prevProps.showPODocumentPane) {
      this.setState({
        topReSizer: this.props.showPODocumentPane ? screen.width / 2 : screen.width,
      });
    }
    if (this.props.showToolsOrLabourDocumentPane !== prevProps.showToolsOrLabourDocumentPane) {
      //alert(JSON.stringify(this.props.showToolsOrLabourDocumentPane))
      this.setState({
        bottomReSizer: this.props.showToolsOrLabourDocumentPane ? screen.width / 2 : screen.width,
      });
    }
    if (this.props.cuhrFormData !== prevProps.cuhrFormData) {
      this.setState({
        bottomReSizer: this.props.showToolsOrLabourDocumentPane ? screen.width / 2 : screen.width,
      });
    }

    if (this.state.horizontalReSizer !== prevState.horizontalReSizer) {
      this.setGanttChartHeight();
    }
  }

  handleUrlChange() {
    let id = this.props.match.params.featureId ? this.props.match.params.featureId : null;

    if (id) {
      this.props.readByPurchaseOrderId(id);
    }

    this.setState(
      {
        isEdit: !!this.props.match.params.featureId,
        isDisableNewButton: id ? true : false,
      },
      //this.props.handleShowDocumentPane
    );
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  // 2. clear states in this method and save to state
  // 3. update redirect url
  createNew = (): void => {
    this.props.clearFormData();
    //this.props.setUid();
    let formData: any = {
      title: null,
      // ....
    };
    this.setState({
      formData: formData,
    });
    history.push(`/CU/${getContractingUnit()}/visual-plan/new`);
  };

  reloadListPaneItem = () => {

  };

  renderPOShortCutPane = () => {
    return (
      <ShortCutPane
        projectId={this.state.projectId}
        shortCutPaneFilters={this.props.vpShortCutPaneData}
        filter={this.state.filter}
        handelShortCutPaneFilter={(selectedItem: VPShortCutPaneItem) =>
          this.handelPOShortCutPaneFilter(selectedItem)
        }
        toggleOverlay={this.state.collapseBookmarkPane}
        resetLayout={this.state.resetLayout}
      />
    );
  };

  renderTaxanomyShortCutPane = () => {
    return (
      <ShortCutPaneTaxonomy
        projectId={this.state.projectId}
        shortCutPaneFilters={[]}
        filter={this.state.filter}
        handelShortCutPaneFilter={(selectedItem: VPShortCutPaneItem) =>
          this.handelShortCutPaneFilter(selectedItem)
        }
        toggleOverlay={this.state.collapseBottomBookmarkPane}
        resetLayout={this.state.resetLayout}
      />
    );
  };

  renderPoBookmarkPane = () => {
    if (this.props.formData) {
      if (this.props.formData?.type?.key === RESOURCE_TYPE_ID) {
        return this.bookmarkListResource;
      } else if (this.props.formData?.type?.key === PRODUCT_TYPE_ID) {
        return this.bookmarkListProduct;
      } else if (this.props.formData?.type?.key === GOODDEERIS_PRODUCT_TYPE_ID) {
        return this.bookmarkListResource;
      } else {
        return this.bookmarkList;
      }
    } else {
      return this.bookmarkList;
    }

  };

  getDocumentPaneViewOld = () => {
    return (
      <DocumentPaneSection
        collapseBookmarkPane={this.state.collapseBookmarkPane}
        showProgress={this.props.showProgressBar}
        handleScroll={this.props.handleScroll}
        isEdit={this.props.isEdit}
        formData={this.state.formData}
        bookmarkList={this.bookmarkList}
        currentActiveSection={this.props.currentActiveSection}
        renderCommandBar={() => <CommandBarRight/>}
      >
        <DocumentPane
          formData={this.props.formData}
          isExpand={false}
          vpDropDowns={[]}
          isNewVP={false}
          isLoadingVisualPlan={false}
        />
      </DocumentPaneSection>
    );
  };

  private getPurchaseAndDeliveryListPaneView = (isFirst?: boolean) => {
    const { t } = this.props;
    return (
      <div>
        <TitlePane
          //headerTitle={this.state.horizontalReSizer}
          headerTitle={t('purchaseOrder')}
        />
        <div style={{
          height: isFirst ? this.state.horizontalReSizer - 45 : screen.height - (this.state.horizontalReSizer - 45),
          //backgroundColor:'#0f561a',
          overflow: 'auto',
        }}>
          <Stack horizontal>
            {this.renderPOShortCutPane()}

            <div className={this.state.activeClass + (this.props.showPODocumentPane ? ' vpWithDoc' : ' vp')}>

              <Stack style={{ alignSelf: 'center' }}>
                <PurchaseAndDeliveryComponentCU
                  
                />
              </Stack>
            </div>
          </Stack>
        </div>
      </div>
    );
  };

  private getListPaneView = (isFirst?: boolean) => {
    const { t } = this.props;
    return (
      <div>
        <TitlePane
          //headerTitle={this.state.horizontalReSizer}
          headerTitle={t('purchaseOrder')}
        />
        <div style={{
          height: isFirst ? this.state.horizontalReSizer - 45 : screen.height - (this.state.horizontalReSizer - 45),
          //backgroundColor:'#0f561a',
          overflow: 'auto',
        }}>
          <Stack horizontal>
            {this.renderPOShortCutPane()}

            <div className={this.state.activeClass + (this.props.showPODocumentPane ? ' vpWithDoc' : ' vp')}>
              <Stack style={{ alignSelf: 'center' }}>
                <ListPane
                  projectId={this.state.projectId}
                  loadMsg={this.props.loadMsg}
                  reloadListPaneItem={() => this.reloadListPaneItem()}
                  filter={this.state.filter}
                  listPaneItems={this.props.vpList}
                  isDataLoaded={this.props.isVPListLoaded}
                  isChange={this.props.isChange}
                  formData={this.state.formData}
                  handelFilter={(filter: VisualPlanListPaneFilter) => {
                    //this.handleFilter(filter)
                  }}
                  handleSelectedListPaneItem={(
                    selectedListItemIds: [] | string[],
                  ) => {
                    //this.handleSelectedListPaneItem(selectedListItemIds);
                  }}
                  reSizer={this.state.topReSizer}
                />
              </Stack>
            </div>
          </Stack>
        </div>
      </div>
    );
  };

  private getOrgTaxonomyListPaneView = (isFirst?: boolean) => {
    const { t } = this.props;
    return (
      <div>
        <TitlePane
          headerTitle={t('cuRecources')}
          minimize={true}
          isMinimize={this.state.isMinimize}
        />
        <div style={{ height: (this.getListPaneHeight() - 45), overflow: 'auto' }}>
          <Stack horizontal className="mmmm" styles={{root:{height:'100%'}}}>
            {this.renderTaxanomyShortCutPane()}

            <div className={this.state.activeClass + (this.props.showPODocumentPane ? ' vpWithDoc' : ' vp')}>

              <Stack style={{ alignSelf: 'center' }}>
                <OrgListPane
                  projectId={this.state.projectId}
                  loadMsg={this.props.loadMsg}
                  reloadListPaneItem={() => this.reloadListPaneItem()}
                  filter={this.state.filter}
                  listPaneItems={this.props.vpOrgTaxonomyList}
                  isDataLoaded={this.props.isVPListLoaded}
                  isChange={this.props.isChange}
                  formData={this.state.formData}
                  handelFilter={(filter: VisualPlanListPaneFilter) => {
                    //this.handleFilter(filter)
                  }}
                  handleSelectedListPaneItem={(
                    selectedListItemIds: [] | string[],
                  ) => {
                    //this.handleSelectedListPaneItem(selectedListItemIds);
                  }}
                  reSizer={this.state.topReSizer}
                />
              </Stack>
            </div>
          </Stack>
        </div>
      </div>
    );
  };

  private getPODocumentPaneView = (isFirst?: boolean) => {
    const PODocumentPane = require('../purchaseOrder/documentPane/container').default;
    return (
      <div>
        <DocumentPaneSectionVp
          collapseBookmarkPane={this.state.collapseBookmarkPane}
          showProgress={this.props.showProgressBar}
          handleScroll={this.props.handleScroll}
          isEdit={true}
          formData={this.state.formData}
          bookmarkList={this.renderPoBookmarkPane()}
          currentActiveSection={this.props.currentActiveSection}
          renderCommandBar={() => false}
          type={1}
        >
          <div style={{
            height: `${(this.state.horizontalReSizer - 50)}px`,
            //backgroundColor:'#0f561a',
            overflowY: 'auto', overflowX: 'hidden', width: '100%',
          }}>
            <PODocumentPane
              formData={this.props.formData}
              vpDropDowns={[]}
              isExpand={false}
              isNewVP={false}
              isLoadingVisualPlan={false}
            />
          </div>
        </DocumentPaneSectionVp>

      </div>
    );
  };

  private getCPCDocumentPaneView = (isFirst?: boolean) => {

    return (
      <div>
        <DocumentPaneSectionVp
          collapseBookmarkPane={this.state.collapseBottomBookmarkPane}
          showProgress={this.props.showProgressBar}
          handleScroll={this.props.handleScroll}
          isEdit={true}
          formData={this.props.cpcFormData ? this.props.cpcFormData : {}}
          bookmarkList={this.cpcBookmarkList}
          currentActiveSection={this.props.currentActiveSection}
          renderCommandBar={() => false}
          type={2}
        >
          <div style={{
            height: screen.height - (this.state.horizontalReSizer + 340),
            overflowY: 'auto',
            overflowX: 'hidden',
            width: '100%',
            backgroundColor: '#edebe9',
          }}>
            <CpcDocumentPane cpcId={this.props.selectedTool}/>
          </div>
        </DocumentPaneSectionVp>

      </div>
    );
  };

  private getCUHRDocumentPaneView = (isFirst?: boolean) => {
    const CUHRDocumentPane = require('../humanResource/documentPane/container').default;
    return (
      <div>
        <DocumentPaneSectionVp
          collapseBookmarkPane={this.state.collapseBottomBookmarkPane}
          showProgress={this.props.showProgressBar}
          handleScroll={this.props.handleScroll}
          isEdit={true}
          formData={this.props.cuhrFormData ? this.props.cuhrFormData : {}}
          bookmarkList={this.cuBookmarkList}
          currentActiveSection={this.props.currentActiveSection}
          renderCommandBar={() => false}
          type={2}
        >
          <div style={{
            height: screen.height - (this.state.horizontalReSizer + 340),
            overflowY: 'auto',
            overflowX: 'hidden',
            width: '100%',
            backgroundColor: '#edebe9',
          }}>

            <CUHRDocumentPane
              formData={this.props.cuhrFormData}
              vpDropDowns={[]}
              isExpand={false}
              isNewVP={false}
              isLoadingVisualPlan={false}
            />
          </div>
        </DocumentPaneSectionVp>

      </div>
    );
  };


  getListPaneHeight = (isTopPane?: boolean): number => {
    if (screen.width <= 1920) {
      return screen.height - (this.state.horizontalReSizer + 240);
    } else if (screen.width > 1920 && screen.width <= 2048) {
      return screen.height - (this.state.horizontalReSizer + 150);
    } else if (screen.width > 2048) {
      return screen.height - (this.state.horizontalReSizer + 200);
    } else {
      return screen.height - (this.state.horizontalReSizer + 285);
    }
  };

  setGanttChartHeight = () => {
    // const nodeContents: any = Array.from(document.getElementsByClassName('gantt-module_horizontalContainer_1Ns26'));
    // if (nodeContents && nodeContents[0]) {
    //   nodeContents[0].style.setProperty('height', `${this.state.horizontalReSizer - 30}px`, 'important');
    // }
    // if (nodeContents && nodeContents[1]) {
    //   nodeContents[1].style.setProperty('height', `${this.state.horizontalReSizer - 30}px`, 'important');
    // }
    // if (nodeContents && nodeContents[2]) {
    //   nodeContents[2].style.setProperty('height', `${screen.height - (this.state.horizontalReSizer - 30)}px`, 'important');
    // }
    // if (nodeContents && nodeContents[3]) {
    //   nodeContents[3].style.setProperty('height', `${screen.height - (this.state.horizontalReSizer - 30)}px`, 'important');
    // }
    // nodeContents.forEach((element: any) => {

    // });
  };

  handelPOShortCutPaneFilter = (selectedItem: VPShortCutPaneItem) => {
    if (selectedItem.value !== 6 && selectedItem.value !== 7 && selectedItem.value !== 8 && selectedItem.value !== 9 ) {
      this.props.filterVisualPlanListPaneData({
        requestType: selectedItem.id ? [selectedItem.id] : [],
        resourceType: [],
        fromDate: moment().startOf('month').format('YYYY-MM-DD hh:mm'),
        toDate: moment().endOf('month').format('YYYY-MM-DD hh:mm'),
        projectSequenceCode: null,
      }).then(() => {
        this.setGanttChartHeight();
      });
    } if (selectedItem.value === 9 ) {
      this.props.readResourceTaxonmyDataVP({
        type: ['9'],
        startDate: null,
        endDate: null,
      }).then(()=>{
          this.setGanttChartHeight()
      });
    } else {
        this.props.readPurchaseAndDeliveryTaxonomyDataCU({}).then(() => {
          this.setGanttChartHeight();
        });
    }

  };

  handelShortCutPaneFilter = (selectedItem: VPShortCutPaneItem) => {
    this.props.filterVisualPlanListPaneData({
      requestType: selectedItem.key ? [selectedItem.key] : [],
      resourceType: [],
      fromDate: null,
      toDate: null,
      projectSequenceCode: null,
    }).then(() => {
      this.setGanttChartHeight();
    });

  };


  getVPListPane = () => {
    switch (this.props.vpCUSelectedShortcutpaneType) {
      case 1: 
          return this.getListPaneView(true)
      case 6:
      case 7:
      case 8:
      case 9:
        return this.getPurchaseAndDeliveryListPaneView(true)
      default:
        return this.getListPaneView(true)
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <SplitPane defaultSize={screen.height - 340} onChange={(size: any) => {
          this.setState({ horizontalReSizer: size });
          console.log('size', size);
        }} maxSize={screen.height - 310} minSize={40} style={{ position: 'inherit' }} split="horizontal"
                   size={this.state.horizontalReSizer}>
          <div style={{ width: '100%', height: '75vh !important' }} className={'ms-Grid'}>
            <SplitPane minSize={(screen.width / 3)}
                       maxSize={(screen.width / 3) * 2}
                       defaultSize={
                         this.props.showPODocumentPane ? screen.width / 2 : screen.width
                       }
                       split="vertical"
                       onChange={(size: any) => {
                         this.setState({ topReSizer: size });
                       }}
                       size={this.state.topReSizer}
            >
              <Pane className={'bortax'} style={{ width: '100%', height: '100%' }}>{this.getVPListPane()}</Pane>
              <Pane style={{
                width: '100%',
                height: '100%',
              }}> {this.props.showPODocumentPane && this.getPODocumentPaneView(true)}</Pane>
            </SplitPane>
            <div></div>
          </div>
          <div style={{ width: '100%', height: '100%' }} className={'ms-Grid'}>
            <div id={'bottomPane'} style={{ height: this.getListPaneHeight() }}>
              <SplitPane minSize={(screen.width / 3)}
                         maxSize={(screen.width / 3) * 2}
                         defaultSize={
                           this.props.showToolsOrLabourDocumentPane ? screen.width / 2 : screen.width
                         } split="vertical"
                         onChange={(size: any) => {
                           this.setState({ bottomReSizer: size });
                         }}
                         size={this.state.bottomReSizer}>
                <Pane style={{ width: '100%', height: '100%' }}>
                  {this.getOrgTaxonomyListPaneView()}
                  </Pane>
                <Pane style={{
                  width: '100%',
                  height: '100%',
                }}> {this.props.showToolsOrLabourDocumentPane ? this.props.selectedResourceTypeOrg === RESOURCE_TYPE_IDS.TOOLS ? this.getCPCDocumentPaneView() : this.getCUHRDocumentPaneView() : null}</Pane>

              </SplitPane>
            </div>
          </div>

        </SplitPane>


        <Panel
          isOpen={this.props.showPanel}
          type={PanelType.smallFixedFar}
          onDismiss={this._hidePanel}
          isLightDismiss={true}
          headerText={t('corporateProductCatalogue')}
          className="custom-detail-panel"
          closeButtonAriaLabel={t('close')}
        >
          {this.getPODocumentPaneView()}
        </Panel>

        <ConfirmationDialog
          hidden={true}
          title={t('areyousure')}
          subText={t('youwonTbeabletorevertthis')}
          onClickConfirmDialog={() => {
          }}
          onClickCancelDialog={() => {
          }}
          cancelButtonText={t('noIwonT')}
          confirmButtonText={t('yesDeleteit')}
        />
      </div>
    );
  }

  private _hidePanel = () => {
    this.setState({ showPanel: false, showDetails: false });
    history.push(`/projects/project/${this.state.projectId}/product/pbs`);
  };

  private hideDocumentPane = (type: number) => {
    if (type === 1) {
      this.props.saveVPStateAttr('showPODocumentPane', false);
      this.setState({ topReSizer: screen.width });
    } else if (type === 2) {
      this.setState({ bottomReSizer: screen.width });
      this.props.saveVPStateAttr('showToolsOrLabourDocumentPane', false);
    }
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  // console.log("VP DATA",state.vp?.vpShortCutPaneData)
  return {
    shortcutPaneData: state.vp?.vpShortCutPaneData,
    isVPListLoaded: state.vp.isVPListLoaded,
    vpList: state.vp.vpList,
    vpDropDowns: state.vp.vpDropDowns,
    reloadListPane: state.vp.reloadListPane,
    formData: state.po.formData,
    purchaseOrderDropDowns: state.po.purchaseOrderDropDowns,
    vpOrgTaxonomyList: state.vp.vpOrgTaxonomyList,
    reloadRequired: state.vp.reloadRequired,
    poSelectedResources: state.vp.poSelectedResources,
    showToolsOrLabourDocumentPane: state.vp.showToolsOrLabourDocumentPane,
    showPODocumentPane: state.vp.showPODocumentPane,
    selectedResourceTypeOrg: state.vp.selectedResourceTypeOrg,
    cuhrFormData: state.vp.cuhrFormData,
    selectedLabour: state.vp.selectedLabour,
    selectedTool: state.vp.selectedTool,
    productCatalogue: state.productCatalogue.productCatalogue,
    selectedPO: state.vp.selectedPO,
    poSelectedTypes: state.vp.poSelectedTypes,
    poFilterToDate: state.vp.poFilterToDate,
    poFilterFromDate: state.vp.poFilterFromDate,
    selectedStartDateCu: state.vp.selectedStartDateCu,
    selectedEndDateCu: state.vp.selectedEndDateCu,
    selectedPOResourceTypes: state.vp.selectedPOResourceTypes,
    selectedPOProject: state.vp.selectedPOProject,
    selectedPOVPshortcutpaneItems: state.vp.selectedPOVPshortcutpaneItems,
    cpcFormData: state.productCatalogue.productCatalogue,
    vpCUSelectedShortcutpaneType: state.vp.vpCUSelectedShortcutpaneType
  };
};

const mapDispatchToProps = {
  readVisualPlanShortCutPaneData,
  filterVisualPlanListPaneData,
  readVisualPlanDropDownData,
  readByVisualPlanId,
  clearFormData,
  readPurchaseOrderDropDownData,
  readByPurchaseOrderId,
  readVPOrganizationTaxanomyData,
  getDropdownData,
  readVPWareHouseTaxanomyData,
  saveVPStateAttr,
  getProductCatalogueById,
  readCUHRbyPersonId,
  GetVPFilterDropdownData,
  readPurchaseAndDeliveryTaxonomyDataCU,
  readCpcResourceFamilyforVp,
  readResourceTaxonmyDataVP
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(withMainUI((VisualPlanMainLayout))),
));

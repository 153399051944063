import React from 'react';
import { connect } from 'react-redux';
import DocumentPaneComponent from './component';
import { WithTranslation } from 'react-i18next';
import _ from 'lodash';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import history from '../../../history';
import { messageService } from '../../../services/messageService';
import { getProject } from '../../../shared/util';
import { qrCode } from '../../../types/qrCode';
import { Selection } from '@fluentui/react';

import {
  getAllProjectDropdowns,
  getProjectDefinition,
  getProjectHeaderDetails,
} from '../../../reducers/projectReducer';
import { approveWorkflow, readByWorkFlowId, saveFormData } from '../../../reducers/workFlowReducer';
import { v4 as uuidv4 } from 'uuid';
import { WarehouseDropDowns } from '../../../types/warehouse';
import { readWarehouseDropDownData, readWarehouseTaxonomy } from '../../../reducers/warehouseReducer';
import PdfModal from './pdf/pdfModal';
import { APPROVED_STATUS_ID, HANDED_OVER_STATUS_ID } from '../../../types/projectMolecule';

export const GOOD_RECEPTION_ID = '4010e768-3e06-4702-b337-ee367a82addb';
export const GOOD_PICKING_ID = '94282458-0b40-40a3-b0f9-c2e40344c8f1';

export interface State {
  isDirty: boolean;
  redirect: boolean;
  loading: boolean;
  isClear: boolean;
  isEdit: boolean;
  isCostModalOpen: boolean;
  showPrint: boolean;
  isApproved: boolean;
  ledgerDropDowns: { key: string; text: string }[];
  generalLedger: string;
  resourceData: any;
  openPOResourceModal: boolean;
  isBorModalVisible: boolean;
  uid: string | null;
  tasks: any[];
  hasChangeIntialFromData: boolean;
}

interface Props extends WithTranslation {
  projectHeader: any,
  wfDropDowns: any;
  saveFormData: (data: any) => void;
  getAllProjectDropdowns: () => void;
  formData: any;
  isLoadingWorkFlow: boolean;
  warehouseDropDowns: WarehouseDropDowns;
  warehouseTaxonomy: any;
  readWarehouseDropDownData: () => void;
  readWarehouseTaxonomy: (data: any) => void;
  readByWorkFlowId: any;
  approveWorkflow: any;
  wfId: string;
  isNew: boolean;
  uid: boolean;
  isNewWf: boolean;
  getProjectHeaderDetails: any;
  psDropDowns: any;
  projectWarehouseTaxonomy: any;
  isExpand: boolean;
  dropDowns: any
}

class DocumentPane extends React.Component<any, any> {
  subscription: any;
  submitExternal: any;
  form: any;
  private _activityTaskSelection: Selection;

  constructor(props: any) {
    super(props);
    this.state = {
      isDirty: false,
      isEdit: false,
      redirect: false,
      loading: false,
      formData: qrCode,
      showPrint: false,
      isApproved: false,
      isBorModalVisible: false,
      uid: null,
      isClear: false,
      tasks: [],
      hasChangeIntialFromData: false,
    };

    this._activityTaskSelection = new Selection({
      onSelectionChanged: () => {

      },
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevProps.isNew !== this.props.isNew ||
      prevProps.uid !== this.props.uid
    ) {
      if (this.props.isNew) {
        const formData = this.state.formData;
        formData.id = this.props.uid;
        this.setState({ formData, isClear: false, hasChangeIntialFromData: false });
      }
    }

    if (prevProps.wfId !== this.props.wfId) {
      if (this.props.wfId) {
        this.props.readByWorkFlowId(this.props.wfId);
      }
    }

    if (prevProps.formData !== this.props.formData) {
      if (!this.state.isDirty) {
        if (!_.isEmpty(this.props.formData)) {
          const formData = this.props.formData;
          this.setState({ formData: formData, tasks: formData?.tasks, isClear: false, hasChangeIntialFromData: false });
        }
      } else {
        if (this.props.isNew && _.isEmpty(this.props.formData)) {
          const formData = this.state.formData;
          this.setState({ formData: formData, tasks: formData?.tasks, isClear: false, hasChangeIntialFromData: false });
        }
      }
    }
  }

  scrollIntoTop = () => {
    const topDiv = document.getElementById('topDiv');
    if (topDiv) {
      topDiv.scrollIntoView();
    }
  };

  componentDidMount() {
    // get document pane dropdownsP
    if(this.props.ledgerDropDowns?.length < 0) {
      this.props.getAllProjectDropdowns();
    }
   
    this.props.readWarehouseDropDownData();
    this.props.readWarehouseTaxonomy({ wareHouseId: null });
    this.setState({ uid: uuidv4(), isClear: this.props.isNewWf });

    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewWF) {
          if (getProject()) {
            this.setState({ uid: uuidv4(), isClear: true, hasChangeIntialFromData: false });
          } else {
            this.setState({ uid: uuidv4(), isClear: true, hasChangeIntialFromData: false });
          }

          if (this.form) {
            this.form.reset();
            this.form.resetFieldState('name');
            this.form.resetFieldState('typeId');
            this.form.resetFieldState('resourceType');
          }
          this.scrollIntoTop();
        }
        if (data.data.wFPdfGenerate) {
          this.getProjectTitle();
        }
        if (data.data.workflowApprove) {
          this.handleWorkflowApprove();
        }

        if (data.data.saveFormData) {
          this.setState({ isClear: false, hasChangeIntialFromData: false });
          this.submitExternal();
        }

      }
    });
    if (getProject()) {
      const projectId = getProject();
      if (projectId) {
        this.props.getProjectHeaderDetails(projectId);
      }
    } else if (this.props.formData?.id) {
      this.props.getProjectHeaderDetails(this.props.formData?.id);
    }
    if (this.props.formData?.id) {

    }
  }


  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { psFilter: this.state.filter },
    });
  };

  setSubmitExternal = (submit: any) => {
    this.submitExternal = submit;
  };

  setForm = (form: any) => {
    this.form = form;
  };

  setFormClearState = (isClear: boolean) => {
    this.setState({ isClear: isClear, hasChangeIntialFromData: false });
  };

  onChangeDestination = (destination: string) => {
    if (this.state.formData.type?.key !== GOOD_PICKING_ID) {
      if (getProject()) {
        if (destination && this.state.formData && this.state.formData?.tasks) {
          let tasks = this.state.formData?.tasks.map((item: any) => {
            if (item.destination) {
              return item;
            } else {
              item.destination = destination;
              return item;
            }
          });
        }
      } else {
        if (destination && this.state.formData && this.state.formData?.tasks) {
          let tasks = this.state.formData?.tasks.map((item: any) => {
            return item.destination = destination;
          });
        }
      }
    }

  };

  onChangeSource = (source: string) => {
    if (this.state.formData.type?.key !== GOOD_RECEPTION_ID) {
      if (source && this.state.formData && this.state.formData?.tasks && getProject() && this.state.formData?.type?.key === GOOD_RECEPTION_ID) {
        // let tasks = this.state.formData?.tasks.map((item:any) => {return item.source = source })

        this.setState({
          formData: { ...this.state.formData, source: source },
          isClear: false,
          hasChangeIntialFromData: true,
        });
      }
    }
  };

  onChangePickedQuantity = (index: number, value: any) => {
    if (this.state.formData && this.state.formData?.tasks && !_.isEmpty(this.state.formData?.tasks) && this.state.formData?.tasks.length > index) {
      let tasks = this.state.formData?.tasks;
      let task = tasks[index];
      task.pickedQuantity = value;
      //task.totalPrize = task?.stock?.averagePrice * value;
      this.setState((prevState: any) => ({
        formData: {
          ...prevState.formData,
          tasks: tasks,

        },
        isClear: false,
        hasChangeIntialFromData: true,
      }), () => {

      });
    }
  };

  onChangeComment = (index: number, value: string | number) => {
    if (this.state.formData && this.state.formData?.tasks && !_.isEmpty(this.state.formData?.tasks) && this.state.formData?.tasks.length > index) {
      let tasks = this.state.formData?.tasks;
      let task = tasks[index];
      task.comment = value;
      this.setState((prevState: any) => ({
        formData: {
          ...prevState.formData,
          tasks: tasks,
        },
        isClear: false,
        hasChangeIntialFromData: true,
      }));
    }
  };

  onChangeTotalPrice = (index: number, value: string | number) => {
    if (this.state.formData && this.state.formData?.tasks && !_.isEmpty(this.state.formData?.tasks) && this.state.formData?.tasks.length > index) {
      let tasks = this.state.formData?.tasks;
      let task = tasks[index];
      task.totalPrize = value;

      this.setState((prevState: any) => ({
        formData: {
          ...prevState.formData,
          tasks: tasks,

        },
        isClear: false,
        hasChangeIntialFromData: true,
      }));
    }
  };

  onChangeStockAvailability = (index: number, value: string | number) => {
    if (this.state.formData && this.state.formData?.tasks && !_.isEmpty(this.state.formData?.tasks) && this.state.formData?.tasks.length > index) {
      let tasks = this.state.formData?.tasks;
      let task = tasks[index];
      task.stockAvailability = value;

      this.setState((prevState: any) => ({
        formData: {
          ...prevState.formData,
          tasks: tasks,
          isClear: false,
          hasChangeIntialFromData: true,
        },
      }));
    }
  };
  handleWorkflowApprove = () => {
    let formData = this.form.getState().values;
    this.props.approveWorkflow(formData).then(() => {
      if (this.state.formData.sequenceId) {
        this.props.readByWorkFlowId(this.state.formData.sequenceId);
      }
    });
  };


  getProjectTitle = () => {
    if (this.props.formData?.project) {

      this.props.getProjectHeaderDetails(this.props.formData?.project).then(() => {
        this.setState({ showPrint: true });
      });
    } else if (getProject()) {
      if (this.props.projectHeader) {
        this.setState({ showPrint: true });
      } else {
        this.props.getProjectHeaderDetails(getProject()).then(() => {
          this.setState({ showPrint: true });
        });
      }
    }
    // this.props.getProjectHeaderDetails(this.props.formData?.project);
  };


  render() {
    return (
      <div style={{ width: '100%' }}>
        <RouteLeavingGuard
          when={this.state.isDirty}
          navigate={(path) => this.handleDiscardChangesNavigate(path)}
          shouldBlockNavigation={() => {
            return true;
          }}
          handleConfirmation={(isConfirm: boolean) => {
            this.setState({ isDirty: !isConfirm });
          }}
        />
        <DocumentPaneComponent
          submit={(submit: any) => {
            this.setSubmitExternal(submit);
          }}
          dropDowns={{
            ...this.props.dropDowns,
            warehouseTaxonomy: this.props.warehouseTaxonomy?.length > 0 ? this.props.warehouseTaxonomy : [],
            projectWarehouseTaxonomy: this.props.projectWarehouseTaxonomy?.length > 0 ? this.props.projectWarehouseTaxonomy : [],
            whTaxonomyLevel: this.props.warehouseDropDowns?.whTaxonomyLevel ? this.props.warehouseDropDowns?.whTaxonomyLevel : [],
          }}
          projectHeader={this.props.projectHeader}
          saveFormData={this.props.saveFormData}
          uid={this.state.uid}
          formData={this.state.formData}
          activityTaskSelection={this._activityTaskSelection}
          isExpand={this.props.isExpand}
          showProgress={this.props.isLoadingWorkFlow}
          formValues={this.form?.getState()?.values}
          isClear={this.state.isClear}
          setForm={(form: any) => {
            this.setForm(form);
          }}
          setFormClearState={(isClear: boolean) => this.setFormClearState(isClear)}
          onChangePickedQuantity={(index: number, value: string | number) => this.onChangePickedQuantity(index, value)}
          // onChangeComment={(index:number,value:string|number)=>{ this.onChangeComment(index,value)}}
          onChangeComment={(index: number, value: string | number) => this.onChangeComment(index, value)}
          onChangeTotalPrice={(index: number, value: string | number) => this.onChangeTotalPrice(index, value)}
          onChangeStockAvailability={(index: number, value: string | number) => this.onChangeStockAvailability(index, value)}
          onChangeDestination={(value: string) => this.onChangeDestination(value)}
          onChangeSource={(value: string) => this.onChangeSource(value)}
          isDisabled={this.state.formData?.status?.key === APPROVED_STATUS_ID || this.state.formData?.status?.key === HANDED_OVER_STATUS_ID}
          tasks={this.state.tasks}
          hasChangeIntialFromData={this.state.hasChangeIntialFromData}
        />

        <PdfModal
          hideModal={() => {
            this.setState({ showPrint: false });
          }}
          isModalOpen={this.state.showPrint}
          wfData={this.state.formData}
          customer={
            this.props.psDropDowns && this.props.psDropDowns.customer
              ? this.props.psDropDowns.customer
              : null
          }
          project={this.props.projectHeader}
        />

      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isEdit: state.ps.isEdit,
    isNew: state.ps.isNew,
    projectHeader: state.project.projectHeader,
    showProgressBar: state.wf.showProgressBar,
    ledgerDropDowns: state.project.ledgerDropDowns,
    selectedProject: state.project.selectedProject,
    dropDowns: state.wf.wfDropDowns,
    isLoadingWorkFlow: state.wf.isLoadingWorkFlow,
    warehouseDropDowns: state.warehouse.warehouseDropDowns,
    warehouseTaxonomy: state.warehouse.warehouseTaxonomy,
    projectWarehouseTaxonomy: state.warehouse.projectWarehouseTaxonomy,
    wfId: state.wf.wfId,
  };
};

const mapDispatchToProps = {
  readByWorkFlowId,
  saveFormData,
  getProjectHeaderDetails,
  getAllProjectDropdowns,
  getProjectDefinition,
  readWarehouseTaxonomy,
  readWarehouseDropDownData,
  approveWorkflow,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentPane);

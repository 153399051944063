import React, {
    useEffect, useState
} from 'react';
import { connect } from 'react-redux';
import {
    DetailsRow,
    Fabric,
    IColumn,
    IDetailsFooterProps,
    IDetailsListProps,
    IDetailsRowStyles,
    ScrollablePane,
    ScrollbarVisibility,
    TextField,
    Text, Stack, IStackStyles, IStackItemStyles, IStackTokens, DefaultPalette
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import {
    CompetencyListPaneFilter, CompetencyListItem
} from '../../../types/competency';
import SortableTreeListView from '../../../shared/sortableTreeListView/sortableTreeListView';


// Styles definition
const stackStyles: IStackStyles = { root: {} };
const stackItemStyles: IStackItemStyles = {
    root: {
        alignItems: 'center',
        background: DefaultPalette.themePrimary,
        color: DefaultPalette.white,
        display: 'flex',
        height: 50,
        justifyContent: 'center'
    }
};

// Tokens definition
const stackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 10
};


export const classNames = mergeStyleSets({
    wrapper: {
        height: 'calc(100vh - 25vh)!important',
        position: 'relative'
    },
    filter: {
        paddingBottom: 20,
        maxWidth: 100
    },
    header: { margin: 0 },
    row: { display: 'inline-block' }
});

const ListPaneComponent = (props: {
  filter: CompetencyListPaneFilter;
  selection: any;
  columns: IColumn[];
  listPaneItems: CompetencyListItem[];
  handleListPaneItemClick: (id: string) => void;
  handleFilterChange: (filter: CompetencyListPaneFilter) => void;
  isDataLoaded?: boolean;
  loadMsg: string;
  resetSelection: () => void;
  dropDownOptions: any;
  onItemClick: (id: string) => void;
  isExpand:boolean;
}) => {
    const { t } = useTranslation();
    const [ filter, setFilter ] = useState<CompetencyListPaneFilter>(props.filter);
    const getDropdownWithDefualt = (options: any) => {
        if (options) {
            const defualtOption = [
                {
                    key: '0',
                    text: t('all'),
                    isDefault: true
                }
            ];
            Array.prototype.push.apply(defualtOption, options);
            return defualtOption;
        }
        return [];
    };

    useEffect(() => {
        setFilter(props.filter);
    }, [ props.filter ]);

    const _onRenderRow: IDetailsListProps['onRenderRow'] = (row) => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (row) {
            return (
                <span
                    style={ { textDecoration: 'none' } }
                    onClick={ () => {
                        // chnage item id name
                        props.handleListPaneItemClick(row.item.id);
                    } }
                >
                    <DetailsRow { ...row } styles={ customStyles } />
                </span>
            );
        }
        return null;
    };

    const _onRenderDetailsFooter = (
        detailsFooterProps: IDetailsFooterProps | undefined
    ) => {
        return (
            <DetailsRow
                className="footer-as-filter"
                { ...detailsFooterProps }
                columns={ detailsFooterProps?.columns }
                item={ {} }
                itemIndex={ -1 }
                groupNestingDepth={ detailsFooterProps?.groupNestingDepth }
                selection={ detailsFooterProps?.selection }
                onRenderItemColumn={ _renderFilterItemColumn }
                // onRenderCheck={_onRenderCheckForFooterRow}
            />
        );
    };


    const _renderFilterItemColumn = (
        item?: CompetencyListItem,
        index?: number,
        column?: IColumn
    ) => {
        switch (column?.key) {
        case 'column1': {
            return (
                <div>
                    <TextField
                        value={ filter?.title ? filter?.title.toString() : '' }
                        onChange={ (event, value) => {
                            if (value) {
                                setFilter((prevState) => ({
                                    ...prevState,
                                    title: value
                                }));
                            } else {
                                // props.filter.projectSequenceCode = null;
                            }
                            props.handleFilterChange(filter);
                        } }
                    />
                </div>
            );
            break;
        }
        default: {
            break;
        }
        }
    };


    const customNodeRender = (node:any) => {
        return <div style={ { cursor: 'pointer' } } onClick={ () => {props.onItemClick(node?.competence?.sequenceId);} } id={ node.id } key={ node.id } className={ 'lastNode' }>
            <table style={ { fontWeight: 'bolder' } } key={ node.id + 'table' } > <tr><td style={ { width: 250, textAlign: 'left' } }><li>{ node.title }</li></td><td style={ { width: 250, textAlign: 'left' } }>{ node.competence?.title }</td><td style={ { width: 250, textAlign: 'left' } }>{ node.competence?.experienceLevelName }</td></tr></table>
        </div>;
    };


    const renderTreeHeader = () => {
        return (
            !props.isExpand ? <table > <tr><td style={ { width: 250 } }>{ t('skillTaxonomy') }</td><td style={ { width: 250 } }>{ t('skillTitle') }</td><td style={ { width: 250 } }>{ t('experienceLevel') }</td></tr></table> : false
        );
    };

    const displayMessage = () => {
        return (
            <div style={ { paddingTop: 36, paddingLeft: 20 } }>
                <Text>{ t(props.loadMsg) }</Text>
            </div>
        );
    };
    return (
        <Fabric>
            <Stack tokens={ stackTokens }>
                <Stack styles={ stackStyles }>
                    <div className={ `org wrapper-holder ${classNames.wrapper} orgSetting` }>
                        <ScrollablePane scrollbarVisibility={ ScrollbarVisibility.auto }>
                            { !props.loadMsg && <SortableTreeListView
                                treeData={ props.listPaneItems ? props.listPaneItems : [] }
                                customNodeLevel={ 3 }
                                customNodeRender={ (node:any) => customNodeRender(node) }
                                // treeHeader={renderTreeHeader()}
                            />
                            }
                            { props.isDataLoaded && displayMessage() }
                        </ScrollablePane>
                    </div>
                </Stack>
            </Stack>
        </Fabric>
    );
};

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        toleranceStatesFilter: state.projectBreakdown.toleranceStatesFilter,
        productStatesFilter: state.projectBreakdown.productStatesFilter,
        itemTypesFilter: state.projectBreakdown.itemTypesFilter
    };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ListPaneComponent);

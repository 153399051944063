import {useTranslation} from 'react-i18next';
import {
  ContextualMenu,
  FontWeights,
  getTheme,
  IconButton,
  IDragOptions,
  IIconProps,
  IStackStyles,
  IStackTokens,
  Label,
  MaskedTextField,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import {useBoolean, useId} from '@uifabric/react-hooks';
import {uPrinceTheme} from '../../../../../../theme';
import {EndDateAndTime} from '../../../../../types/projectMolecule';
import CustomDatePicker from '../../../../../shared/customDatePicker/customDatePicker';
import React, {useEffect, useState} from 'react';
import {formatDateAndTimeToISOString} from '../../../../../shared/util';
import moment from 'moment';
import {savePMOLStateAttr, updatePmolLabourEndTime} from '../../../../../reducers/pmolReducer';
import {useDispatch} from 'react-redux';

const theme = getTheme();

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const classNames = mergeStyleSets({
  wrapper: {
    //height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: {
    maxWidth: 400,
  },
  textContent: {
    padding: '15px 10px',
  },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: {
    width: '100%',
  },
});

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
  },
};

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const cancelIcon: IIconProps = {iconName: 'Cancel'};
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const addIcon: IIconProps = {iconName: 'Add', styles: iconButtonStyles};
const addIconWhite: IIconProps = {
  iconName: 'Save',
  styles: addWhiteIconButtonStyles,
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    paddingTop: 20,
    overflowY: 'hidden',
    minWidth: '20vw',
    //minHeight: '32vh',
    minHeight: screen.width > 1280 ? '5vh' : '60vh',
    selectors: {
      p: {margin: '14px 0'},
      'p:first-child': {marginTop: 0},
      'p:last-child': {marginBottom: 0},
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'none',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const toggleStyles = {root: {marginBottom: '20px'}};
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const concatDateAndTime = (date: any, time: any) => {
  const dateStr = moment(date).format('YYYY-MM-DD');
  const timeStr = moment(time, 'h:mm:ss').format('HH:mm');
  return formatDateAndTimeToISOString(dateStr,timeStr);
};

const maskFormat: { [key: string]: RegExp } = {H: /[0-2]/, h: /[0-9]/, m: /[0-5]/, M: /[0-9]/};

const EmployeeEditEndTimeModal = (props: {
  isOpen: boolean;
  openModal: any
  title: string
  pmolID: any,
  teamMember: any;
  isEndTimeUpdated: boolean;
  closeModal: any

}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [isDraggable] = useBoolean(false);
  const titleId = useId('title');
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<string | null>(null);
  const [endDateTime, setEndDateTime] = useState(new EndDateAndTime());
  const [isValidDate, setIsValidDate] = useState(true);
  const [isValidTime, setIsValidTime] = useState(true);
  const [isEndTimeUpdated, setIsEndTimeUpdated] = useState(true);

  const columns = [
    {
      key: 'column1',
      name: t('name'),
      fieldName: 'name',
      minWidth: 200,
      maxWidth: 0,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('endDate'),
      fieldName: 'endDate',
      minWidth: 180,
      maxWidth: 0,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('endTime'),
      fieldName: 'endTime',
      minWidth: 180,
      maxWidth: 0,
      isResizable: true,
    },
  ];

  const resetFields = () => {
    setEndDateTime(new EndDateAndTime())
    setIsValidDate(true);
    setIsValidTime(true);
    setEndTime(null);
    setEndDate(null);
    setIsEndTimeUpdated(true);
  };

  const setValidationMessages = () => {
    endDate === null ? setIsValidDate(false) : setIsValidDate(true);
    endTime === null ? setIsValidTime(false) : setIsValidTime(true);
  }

  useEffect(() => {
    if (!props.isEndTimeUpdated) {
      setIsEndTimeUpdated(false);
    } else if(endDate && endTime) {
      onModalClose()
    }
  }, [props.isEndTimeUpdated]);

  useEffect(() => {
    if (endDate) {
      setIsValidDate(true);
    }

    if (endTime) {
      setIsValidTime(true);
    }
  }, [endTime, endDate]);


  useEffect(() => {
    setEndDateTime(
      {
        pmolId: props.pmolID,
        labourId: props.teamMember?.id,
        endDateTime: concatDateAndTime(endDate, endTime),
      }
    )
  }, [endTime, endDate]);

  const onModalClose = () => {
    resetFields();
    props.openModal()
    dispatch(savePMOLStateAttr('isEndTimeUpdated', true));
    props.closeModal()
  };

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          onModalClose();
        }}
        scrollableContentClassName="body"
        isBlocking={false}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>{props.title}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              onModalClose();
            }}
          />
        </div>
        <div className={contentStyles.subHeader}>

        </div>
        <div className={contentStyles.body}>

          <div className="marginTop">
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6}>
                <div className={`${classNames.fullWidth}`}>
                  <Label style={{marginLeft: 0, marginRight: 0}} required>
                    {t('endDate')}
                  </Label>
                  <CustomDatePicker
                    setMinDate={null}
                    setMaxDate={null}
                    getValue={(date: Date) => {
                      setEndDate(date)
                    }}
                  />
                  {!isValidDate && <span style={{marginLeft: 0, marginRight: 5, color: "#A4262C"}}>{t('endDateRequired')}</span>}
                </div>
              </Stack.Item>
              <Stack.Item grow={6}>
                <div style={{width: 100, marginLeft: 47}}>
                  <Label style={{marginLeft: 0, marginRight: 0}} required>
                    {t('endTime')}
                  </Label>
                  <MaskedTextField
                    onChange={(e: any, newValue?: string) => {
                      const timeValidation: boolean = moment(newValue, 'HH:mm', true).isValid();
                      if (timeValidation && newValue) {
                        setEndTime(newValue)
                      } else {
                        setEndTime(null)
                      }
                    }}
                    mask="Hh:mM"
                    maskFormat={maskFormat}
                    maskChar="_"
                  />
                  {!isValidTime && <span style={{marginLeft: 0, marginRight: 5, color: "#A4262C"}}>{t('endTimeRequired')}</span>}
                </div>
              </Stack.Item>
            </Stack>
            {!isEndTimeUpdated && <span style={{marginLeft: 8, marginRight: 0, color: "#A4262C"}}>{t('somethingWentWrong')}</span>}
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
            </Stack>
          </div>

        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={addIconWhite}
            text={t('save')}
            style={{marginTop: 20, marginBottom: 10}}
            onClick={() => {
              setValidationMessages();
              if (endTime && endDate) {
                dispatch( updatePmolLabourEndTime(endDateTime));
                }
              }
            }
          />
        </div>
        {/*<pre>{ JSON.stringify(endDateTime) }</pre>*/}
      </Modal>

    </div>
  );
};

export default EmployeeEditEndTimeModal;

import React from 'react';
import { DirectionalHint, FontIcon, Label, Link, Stack, Text, TooltipHost } from '@fluentui/react';
import { initializeIcons } from '@uifabric/icons';
import './../../components/component.css';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { withTranslation, WithTranslation } from 'react-i18next';
import { iconColors, uPrinceTheme } from '../../../theme';
import { messageService } from '../../services/messageService';

initializeIcons();

const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 129px)!important',
    position: 'relative',
  },
  selected: {
    fontWeight: 'bold',
    //color: '#ff4d4d!important',
  },
});

interface Props extends WithTranslation {
  handleFilterShortcutPane: any;
}

class OverlayLightExample extends React.Component<Props, any> {

  constructor(props: Props) {
    super(props);

    this.state = {
      divClass: 'filter-panel-left',
      filterByActiveProject: true,
      filterByToleranceLimit: false,
      filterByOutOfTolerance: false,
      filterToleranceStateId: null,
      currentActiveSection: 1,
      filterByHistory: false,
    };
  }

  setFilterOption(filterType: string) {
    switch (filterType) {
      case 'filterByActiveProject':
        return this.setState(
          {
            filterByActiveProject: true,
            filterByToleranceLimit: false,
            filterByOutOfTolerance: false,
            currentActiveSection: 1,
            filterToleranceStateId: null,
            filterByHistory: false,
            loading: true,
          },
          () => {
            this.props.handleFilterShortcutPane(
              this.state.filterToleranceStateId,
              this.state.filterByActiveProject,
              1,
              this.state.filterByHistory,
            );
          },
        );
      case 'filterByToleranceLimit':
        return this.setState(
          {
            filterByActiveProject: false,
            filterByToleranceLimit: true,
            filterByOutOfTolerance: false,
            currentActiveSection: 2,
            filterToleranceStateId: 'd9712fb3-02b6-4c2a-991c-ee904c87d8a8',
            filterByHistory: false,
            loading: true,
          },
          () => {
            this.props.handleFilterShortcutPane(
              this.state.filterToleranceStateId,
              this.state.filterByActiveProject,
              2,
              this.state.filterByHistory,
            );
          },
        );
      case 'filterByOutOfTolerance':
        return this.setState(
          {
            filterByActiveProject: false,
            filterByToleranceLimit: false,
            filterByOutOfTolerance: true,
            currentActiveSection: 3,
            filterToleranceStateId: '8f33bdf6-7600-4ad7-b558-c98899c1e5b2',
            filterByHistory: false,
            loading: true,
          },
          () => {
            this.props.handleFilterShortcutPane(
              this.state.filterToleranceStateId,
              this.state.filterByActiveProject,
              3,
              this.state.filterByHistory,
            );
          },
        );
      case 'filterByHistory':
        return this.setState(
          {
            filterByActiveProject: false,
            filterByToleranceLimit: false,
            filterByOutOfTolerance: false,
            currentActiveSection: 4,
            filterToleranceStateId: null,
            filterByHistory: true,
            loading: true,
          },
          () => {
            this.props.handleFilterShortcutPane(
              this.state.filterToleranceStateId,
              this.state.filterByActiveProject,
              4,
              this.state.filterByHistory,
            );
          },
        );
      default:
        return this.setState(
          {
            filterByActiveProject: true,
            loading: true,
            filterToleranceStateId: null,
          },
          () => {
            this.props.handleFilterShortcutPane(
              this.state.filterToleranceStateId,
              this.state.filterByActiveProject,
            );
          },
        );
    }
  }

  componentDidMount() {
    if (this.state.divClass === 'filter-panel-left') {
      if (window.innerWidth > 1300) {
        messageService.sendMessage({ shortCutPaneExpanded: true });
      }
    } else {
      messageService.sendMessage({ shortCutPaneExpanded: false });
    }
  }

  getIconStyle = (activeSection: string) => {
    return this.state.currentActiveSection == activeSection ? { color: iconColors.iconActiveColor } : { color: uPrinceTheme.palette.themePrimary };
  };

  public render(): JSX.Element {
    const { t } = this.props;

    return (
      <div className={`${this.state.divClass} ${classNames.wrapper}`}
           onMouseOver={() => {
             if (window.innerWidth > 1100 && window.innerWidth < 1600)
               messageService.sendMessage({ shortCutPaneExpanded: true });
           }} onMouseLeave={() => {
        if (window.innerWidth > 1100 && window.innerWidth < 1600)
          messageService.sendMessage({ shortCutPaneExpanded: false });
      }}
      >
        <div className="inner-container" style={{ marginTop: 5 }}>
          <i
            onClick={this._toggleOverlay}
            className="ms-Icon ms-Icon--Back back-arrow"
            aria-hidden="true"
          ></i>
          <i
            onClick={this._toggleOverlayFwd}
            className="ms-Icon ms-Icon--Forward fwd-arrow"
            aria-hidden="true"
          ></i>

          <div className="filter-data">
            <Label>{t('project')}</Label>
          </div>

          <div className="filter-option-list">
            <Stack
              gap={15}
              verticalFill
              styles={{
                root: {
                  width: '100%',
                  verticalAlign: 'center',
                },
              }}
            >
              <Link
                className={`${
                  this.state.currentActiveSection == '1'
                    ? classNames.selected
                    : ''
                }`}
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  this.setFilterOption('filterByActiveProject');
                }}
              >
                <TooltipHost
                  content={t('activeProject')}
                  id="filter-opt-1"
                  calloutProps={{ gapSpace: 0 }}
                  directionalHint={DirectionalHint.rightCenter}
                >
                  <FontIcon iconName="PageList" className="ms-Icon" style={this.getIconStyle('1')}/>

                </TooltipHost>
                <FontIcon iconName="PageList" className="filter-icon ms-Icon filter-icon"
                          style={this.getIconStyle('1')}/>

                <Text>{t('activeProject')}</Text>
              </Link>

              <Link
                className={`${
                  this.state.currentActiveSection == '2'
                    ? classNames.selected
                    : ''
                }`}
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  this.setFilterOption('filterByToleranceLimit');
                }}
              >
                <TooltipHost
                  content={t('toleranceLimit')}
                  id="filter-opt-2"
                  calloutProps={{ gapSpace: 0 }}
                  directionalHint={DirectionalHint.rightCenter}
                >
                  <FontIcon iconName="Warning" className="ms-Icon" style={this.getIconStyle('2')}/>
                </TooltipHost>
                <FontIcon iconName="Warning" className="filter-icon ms-Icon filter-icon"
                          style={this.getIconStyle('2')}/>
                <Text>{t('toleranceLimit')}</Text>
              </Link>

              <Link
                className={`${
                  this.state.currentActiveSection == '3'
                    ? classNames.selected
                    : ''
                }`}
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  this.setFilterOption('filterByOutOfTolerance');
                }}
              >
                <TooltipHost
                  content={t('outOfTolerance')}
                  id="filter-opt-3"
                  calloutProps={{ gapSpace: 0 }}
                  directionalHint={DirectionalHint.rightCenter}
                >
                  <FontIcon iconName="Error" className="ms-Icon" style={this.getIconStyle('3')}/>
                </TooltipHost>
                <FontIcon iconName="Error" className="filter-icon ms-Icon filter-icon" style={this.getIconStyle('3')}/>
                <Text>{t('outOfTolerance')}</Text>
              </Link>

              <Link
                className={`${
                  this.state.currentActiveSection == '4'
                    ? classNames.selected
                    : ''
                }`}
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  this.setFilterOption('filterByHistory');
                }}
              >
                <TooltipHost
                  content={t('history')}
                  id="filter-opt-4"
                  calloutProps={{ gapSpace: 0 }}
                  directionalHint={DirectionalHint.rightCenter}
                >
                  <FontIcon iconName="History" className="ms-Icon" style={this.getIconStyle('4')}/>
                </TooltipHost>
                <FontIcon iconName="History" className="filter-icon ms-Icon filter-icon"
                          style={this.getIconStyle('4')}/>
                <Text>{t('history')}</Text>
              </Link>
            </Stack>
          </div>
        </div>
      </div>
    );
  }

  private _toggleOverlay = (): void => {
    this.setState({ divClass: 'filter-panel-left panel-move' });
    messageService.sendMessage({ shortCutPaneExpanded: false });

  };
  private _toggleOverlayFwd = (): void => {
    this.setState({ divClass: 'filter-panel-left' });
    messageService.sendMessage({ shortCutPaneExpanded: true });
  };

}

export default withTranslation()(OverlayLightExample);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import UprinceOrganizationNavigation from '../../shared/uprinceNavigation/organizationNavigation/component';
import AddressBookMainLayout from './addressBookMainLayout';

interface Props {
  currentUser: any
}

interface State {

}

export class AddressBook extends Component<Props, State> {
  render() {
    return (
      <div style={{ height: '100%' }}>
        {/* <Brand />
                <ProjectBar contractingUnit={ this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null } />
                <NavBar selectedKey={ 1 } currentUser={ this.props.currentUser } />
                <AddressBookMainLayout /> */}
        <UprinceOrganizationNavigation
          currentUser={this.props.currentUser}
          selectedNavigationIndex={'/address-book'}
        >
          <AddressBookMainLayout/>
        </UprinceOrganizationNavigation>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddressBook);

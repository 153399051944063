export enum ThAutomationResourceType {
  TRUCK = 'TRUCK',
  PUMP = 'PUMP',
}

export enum ThAutomationDisplayTypes {
  LOADING = '0',
  TRAVEL1 = '1',
  UNLOADING = '2',
  TRAVEL2 = '3',
  PRODUCT = '10',
  CONCRETE_MEETER_UNLOADING = '12',
  METER = '13',
  CONCRETE_MEETER_NOT_UNLOADING = '14',
  PUMP_PRODUCT = '15',
}

export const getPmolTypeIcon = (type: string) => {
  switch (type) {
    case ThAutomationDisplayTypes.TRAVEL1:
      return 'th-travel';
    case ThAutomationDisplayTypes.TRAVEL2:
      return 'th-travel';
    case ThAutomationDisplayTypes.UNLOADING:
      return 'th-unload';
    case ThAutomationDisplayTypes.LOADING:
      return 'th-unload';
    default:
      return '';
  }
};


export enum ThAutomationDeleteTypes {
    TRUCK_DELETE_TOP = '1',
    PUMP_DELETE_TOP = '2',
    TRUCK_DELETE_BOTTOM = '3',
    PUMP_DELETE_BOTTOM = '4',
}

import {
  GET_WBS_ISSUE_BY_ID,
  GET_WBS_ISSUE_BY_ID_FAIL,
  GET_WBS_ISSUE_BY_ID_SUCCESS,
  GET_WBS_ISSUE_DROPDOWN_DATA,
  GET_WBS_ISSUE_DROPDOWN_DATA_FAIL,
  GET_WBS_ISSUE_DROPDOWN_DATA_SUCCESS,
  READ_WBS_ISSUE,
  READ_WBS_ISSUE_FAIL,
  READ_WBS_ISSUE_SUCCESS,
  SAVE_WBS_ISSUE,
  SAVE_WBS_ISSUE_FAIL,
  SAVE_WBS_ISSUE_STATE_ATTR,
  SAVE_WBS_ISSUE_SUCCESS,
} from '../actionTypes/wbsIssueActionTypes';
import {
  GET_WBS_ISSUE_BY_ID_EP,
  GET_WBS_ISSUE_DROPDOWN_DATA_EP,
  READ_WBS_ISSUE_LIST_EP,
  SAVE_WBS_ISSUE_EP,
} from '../shared/endpoints';
import { WbsIssueListPaneFilter } from '../types/wbsIssue';

const defaultState: any = {
  formData: null,
  showWbsIssueProgressBar: false,
  reloadListPane: false,
  wbsIssueList: [],
  wbsIssueDropdownData: [],
  saveIssueFormData: false,
  wbsIssueFilter: new WbsIssueListPaneFilter(),
};

export default function wbsIssueReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {

      case SAVE_WBS_ISSUE_STATE_ATTR:
        const attr = Object.keys(action)[1];
        const value = Object.values(action)[1];
        return {
          ...state,
          [attr]: value,
        };

      case READ_WBS_ISSUE:
        return {
          ...state,
          reloadWbsIssue: false,
          wbsIssueList: [],
        };
      case READ_WBS_ISSUE_SUCCESS:
        return {
          ...state,
          reloadWbsIssue: true,
          wbsIssueList: action.payload.data.result,
        };
      case READ_WBS_ISSUE_FAIL:
        return {
          ...state,
          reloadWbsIssue: true,
          wbsIssueList: [],
        };

      case GET_WBS_ISSUE_BY_ID:
        return {
          ...state,
          showWbsIssueProgressBar: true,
          // formData: {},
        };
      case GET_WBS_ISSUE_BY_ID_SUCCESS:
        return {
          ...state,
          showWbsIssueProgressBar: false,
          formData: action.payload.data.result,
        };
      case GET_WBS_ISSUE_BY_ID_FAIL:
        return {
          ...state,
          showWbsIssueProgressBar: false,
        };

      case GET_WBS_ISSUE_DROPDOWN_DATA:
        return {
          ...state,
          wbsIssueDropdownData: [],
        };
      case GET_WBS_ISSUE_DROPDOWN_DATA_SUCCESS:
        return {
          ...state,
          wbsIssueDropdownData: action.payload.data.result,
        };
      case GET_WBS_ISSUE_DROPDOWN_DATA_FAIL:
        return {
          ...state,
          wbsIssueDropdownData: [],
        };

      case SAVE_WBS_ISSUE:
        return {
          ...state,
          showWbsIssueProgressBar: true,
          reloadListPane: false,
          saveIssueFormData: true,
        };
      case SAVE_WBS_ISSUE_SUCCESS:
        return {
          ...state,
          // showWbsIssueProgressBar: false,    NOTE: because the save API is not called alone, this is not an issue. GetById is always fallowed by the Save
          reloadListPane: true,
          saveIssueFormData: false,
        };
      case SAVE_WBS_ISSUE_FAIL:
        return {
          ...state,
          showWbsIssueProgressBar: false,
          saveIssueFormData: false,
        };

      default:
        return state;
    }
  }
}

export function saveWbsIssueStateAttr(attr: string, value: any) {
  return {
    type: SAVE_WBS_ISSUE_STATE_ATTR,
    [attr]: value,
  };
}

export const readWbsIssueList = (filters: any) => {
  return {
    types: [
      READ_WBS_ISSUE,
      READ_WBS_ISSUE_SUCCESS,
      READ_WBS_ISSUE_FAIL,
    ],
    payload: {
      request: {
        url: READ_WBS_ISSUE_LIST_EP,
        method: 'POST',
        data: filters,
      },
    },
  };
};

export const getWbsIssueById = (id: string) => {
  return {
    types: [
      GET_WBS_ISSUE_BY_ID,
      GET_WBS_ISSUE_BY_ID_SUCCESS,
      GET_WBS_ISSUE_BY_ID_FAIL,
    ],
    payload: {
      request: {
        url: GET_WBS_ISSUE_BY_ID_EP + id,
        method: 'GET',
      },
    },
  };
};

export const getWbsIssueDropdowns = () => {
  return {
    types: [
      GET_WBS_ISSUE_DROPDOWN_DATA,
      GET_WBS_ISSUE_DROPDOWN_DATA_SUCCESS,
      GET_WBS_ISSUE_DROPDOWN_DATA_FAIL,
    ],
    payload: {
      request: {
        url: GET_WBS_ISSUE_DROPDOWN_DATA_EP,
        method: 'GET',
      },
    },
  };
};

export const createWbsIssue = (data: any) => {
  return {
    types: [
      SAVE_WBS_ISSUE,
      SAVE_WBS_ISSUE_SUCCESS,
      SAVE_WBS_ISSUE_FAIL,
    ],
    payload: {
      request: {
        url: SAVE_WBS_ISSUE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};
import moment from 'moment';
import {getTimeZone} from '../shared/util';
import {BorResources} from './billOfResources';

export class PMOLListPaneFilter {
  title: string | null = null;
  typeId: string | null = null;
  statusId: string | null = null;
  foreman: string | null = null;
  executionDate: string | Date | null = null;
  date: number | null = null;
  status: number | null = null;
  localDate: string = moment().toISOString();
  offSet: string = getTimeZone();
  sorter: Sorter = new Sorter();
}

export interface PMOLShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
  value: number | null
}

class Sorter {
  attribute: string | null = null;
  order: string | null = null;
}

export interface PMOLShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
}

export class ListPaneItem {
  id: string | null = null;
  projectMoleculeId: string | null = null;
  name: string | null = null;
  title: string | null = null;
  executionDate: string | null = null;
  type: string | null = null;
  status: string | null = null;
  typeId: string | null = null;
  statusId: string | null = null;
  foreman: string | null = null;
}

export interface PMOLDropDown {
  status: DropDown[];
  type: DropDown[];
}

export interface DropDown {
  key: string;
  text: string;
}


export interface History {
  createdByUser: string | null;
  createdDateTime: string | null;
  revisionNumber: number | string | null;
  updatedByUser: string | null;
  updatedDateTime: string | null;
}

export interface BillOfResource {
  id: string | null;
  headerTitle: string | null;
  itemId: string | null;
  name: string | null;
  borTitle: string | null;
  pbsProductId: string | null;
  productItemType: string | null;
  utilityParentId: string | null;
  locationParentId: string | null;
  historyLog: History | null;
  product: Product;
  borResources: BorResources;
  borStatusId: string | null;
}

export class Product {
  id: string | null = null;
  title: string | null = null;
  productId: string | null = null;
  pbsProductItemTypeId: string | null = null;
  pbsProductItemType: string | null = null;
  locationTaxonomyParentId: string | null = null;
  utilityTaxonomyParentId: string | null = null;
  utilityParentId: string | null = null;
  locationParentId: string | null = null;
}

export interface ProjectMolecule {
  id: string | null;
  projectMoleculeId: string | null;
  title: string | null;
  headerTitle: string | null;
  productId: string | null;
  productTitle: string | null;
  projectId: string | null;
  name: string | null;
  typeId: string | null;
  statusId: string | null;
  foremanMobileNumber: string | null;
  executionDate: string | Date | null;
  executionStartTime: string | null;
  executionEndTime: string | null;
  foreman: string | null;
  foremanId: string | null;
  comment: string | null;
  bor: BillOfResource,
  locationId: string | null,
  typeNo: number,
  historyLog: History | null;
  isSaved: boolean;
  isFinished: boolean;
  pmolType: PMOL_TYPE;
  projectDefinition: any
  pmolLotId?: any
}

export interface Journal {
  doneWork: string | null;
  encounteredProblem: string | null;
  lessonsLearned: string | null;
  reportedThings: string | null;
  whatsLeftToDo: string | null;
  id: string | null;
  pmolId: string | null;
  pictureList: JournalImage[];
}

export interface JournalImage {
  id: string | null;
  link: string | null;
  type: string | null;
}


export const BorFormData = {

  id: null,
  borStatusId: null,
  itemId: null,
  borTitle: null,
  headerTitle: null,
  name: null,
  pbsProductId: null,
  locationParentId: null,
  utilityParentId: null,
  productItemType: null,
  historyLog: null,
  product: {
    id: null,
    productId: null,
    title: null,
    utilityParentId: null,
    locationParentId: null,
    locationTaxonomyParentId: null,
    utilityTaxonomyParentId: null,
    pbsProductItemType: null,
    pbsProductItemTypeId: null,
  },
  borResources: {
    labour: [],
    consumable: [],
    tools: [],
    materials: [],
  },
};

export enum PMOL_TYPE {
  REGULAR = 'regular',
  SUB = 'sub'
}


export const
  pmolFormData = {
    id: null,
    projectMoleculeId: null,
    title: null,
    headerTitle: null,
    productId: null,
    productTitle: null,
    projectId: null,
    name: null,
    typeId: null,
    statusId: null,
    foremanMobileNumber: null,
    executionDate: null,
    executionStartTime: null,
    executionEndTime: null,
    foreman: null,
    foremanId: null,
    comment: null,
    bor: BorFormData,
    locationId: null,
    historyLog: null,
    isSaved: false,
    typeNo: 0,
    isFinished: false,
    pmolType: PMOL_TYPE.REGULAR,
    projectDefinition: null,
  };

export interface ProjectMoleculeValidationMessage {
  nameErrorMsg: string | null;
  typeErrorMsg: string | null;
  borErrorMsg: string | null;
  statusErrorMsg: string | null;
  productTitleErrorMsg: string | null;
  mouErrorMsg: string | null;
}


export class Resource {
  id: string | null = null;
  pmolId: string | null = null;
  borId: string | null = null;
  corporateProductCatalogId: string | null = null;
  resourceNumber: string | null = null;
  required: number | string = 0;
  consumedQuantity: number | string = 0;
  cpcBasicUnitOfMeasureId: string | null = null;
  unit: string | null = null;
  title: string | null = null;
  environment: string | null = null;
  type: 'Planned' | 'Extra' = 'Planned';
}


export interface ExtraWork {
  id: string | null
  pmolId: string | number | null
  description: string | null
  imageList: [] | any,
  audioList: [],
  extraWorkFiles: [] | any,
}


export class LabourTeamMember {
  id: string | null = null;
  uid: string | null = null;
  cabPersonId: string | null = null;
  cabPerson: string | null = null;
  roleId: string | null = null;
  roleName: string | null = null;
  startDateTime: string | null = null;
  endDateTime: string | null = null;
  requiredQuantity: string | null = null;
  consumedQuantity: string | null = null;
}

export class EndDateAndTime {
  pmolId: string | null = null;
  labourId: string | null = null;
  endDateTime: string | null = null;
}

export interface PDFSignature {
  name: string | null;
  link: string | null;
  pmolId: string | null;
}

export interface PDFLabour {
  date: string | null;
  technician: string | null;
  from: string | null;
  till: string | null;
}

export interface PDFMaterial {
  resourceNumber: string | null;
  description: string | null;
  quantity: string | null;
  mou: string | null;
}

export class PmolPDFData {
  id: string | null = null;
  customerName: string | null = null;
  customerAddress: string | null = null;
  customerContact: string | null = null;
  contactPersonEmail: string | null = null;
  project: string | null = null;
  title: string | null = null;
  work: string | null = null;
  labour: PDFLabour[] | null = null;
  material: PDFMaterial[] | null = null;
  remarks: string | null = null;
  signatureCustomer: PDFSignature[] | null = null;
}

export interface PMOLService {
  projectTitle: string | null;
  productTitle: string | null;
  quantity: string | null;
  mou: string | null;
  unitPrice: string | null;
  totalPrice: string | null;
  documents: string[] | null;
  comments: string | null;
}


export const PENDING_DEVELOPMENT_STATUS_ID = 'd60aad0b-2e84-482b-ad25-618d80d49477';
export const IN_REVIEW_STATUS_ID = '7143ff01-d173-4a20-8c17-cacdfecdb84c';
export const IN_REVIEW_FEEDBACK_STATUS_ID = '7143ff01-d173-4a20-8c17-cacdfecdb84c-feedback';
export const IN_REVIEW_ACCEPT_STATUS_ID = '7143ff01-d173-4a20-8c17-cacdfecdb84c-accept';
export const APPROVED_STATUS_ID = '7bcb4e8d-8e8c-487d-8170-6b91c89fc3da';
export const HANDED_OVER_STATUS_ID = '4010e768-3e06-4702-b337-ee367a82addb';
export const IN_DEVELOPMENT_STATUS_ID = '94282458-0b40-40a3-b0f9-c2e40344c8f1';
export const EXPORT_NL = 'nl';
export const EXPORT_EN = 'en';

export const APPROVE_STATUS_ID_INVOICE = '7bcb4e8d-8e8c-487d-8170-6b91c89fc3da';


export enum PMOL_ACTIVITY_TYPES {
  TRAVEL='3f8ce-f268-4ce3-9f12-fa6b3adad2cf9d1',
  WORK='5bb656-f708-4a0d-9973-3d834ffe757d01',
  LOAD='848e5e-622d-4783-95e6-4092004eb5eaff',
}
export enum DEFAULT_FOREMAN {
  ID='05bc929c-e07a-46a9-844a-fd5d3b488f7b',
  RESOURCE_TITLE='default Foreman',
}

export interface PMOLCloneMultiDays {
    pmolId: string | null;
    contractingUinit: string | null;
    projectSequenceCode: string | null;
    typeId: string | null;
    executionDate: Date[] | [];
}

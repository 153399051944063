import React, {useEffect} from 'react';
import ProgressBar from '../../shared/progressBar/progressBar';
import InvitationContentBody from './content/component';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {readByLotInvitationId, saveLotInvitationStateAttr} from '../../reducers/lotInvitationReducer';
import i18n from "i18next";

const LotMainLayout = () => {
	const dispatch = useDispatch();
	const id: any = useParams();

	const {
		showProgressBar
	}: any = useSelector(
		(state: any) => state.lotInvitation,
	);

	useEffect(() => {
		// const currentLan = localStorage.getItem('lang') ?? window.navigator.language

		// if(currentLan != id?.languageId){
			i18n.changeLanguage(id?.languageId).then();
		// }
		dispatch(readByLotInvitationId(id?.invitationId));
		dispatch(saveLotInvitationStateAttr('invitationId', id?.invitationId));

		return () => {

		}
	}, []);

	return <div>
		{showProgressBar ?
			<ProgressBar show={true} /> :
			<InvitationContentBody />
		}
	</div>
};

export default LotMainLayout;
import * as React from 'react';
import {
    Dropdown,
    ILabelStyleProps,
    ILabelStyles,
    IDropdownStyleProps,
    IDropdownStyles,
    IDropdownOption
} from '@fluentui/react';
import {uPrinceTheme} from '../../../theme';

const MultiSelectDropDown = ({
                                 input: {name, value, onChange, ...restInput},
                                 meta,
                                 label,
                                 placeholder,
                                 data,
                                 options,
                                 disabled,
                                 onSelect,
                                 selectedKeys,
                                 ...rest
                             }: any) => {
    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;
    return (
        <Dropdown
            placeholder={placeholder}
            label={label}
            selectedKeys={selectedKeys}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={onSelect}
            multiSelect
            options={options}
            style={{marginRight: '1rem', width: '100%'}}
            styles={disabled && getDisabledStyles}
            disabled={disabled}
            errorMessage={showError ? meta.error : ''}
        />
    );
};

function getDisabledStyles(props: IDropdownStyleProps): Partial<IDropdownStyles> {
    const {disabled} = props;
    return {
        title: disabled && {
            color: uPrinceTheme.palette.themePrimary,
        },
        label: {color: 'black'}
    };
}

export default MultiSelectDropDown;

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getContractorsData} from '../../../reducers/addressBookReducer';
import TaxonomyGrid from '../../../shared/taxonomyField/taxonomyGrid';
import {Checkbox} from 'office-ui-fabric-react';
import TaxonomyActionGrid from '../../../shared/taxonomyGrid/taxonomyActionGrid';
import {IColumn} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import {getFlatDataFromTree} from '@nosferatu500/react-sortable-tree';

const ContractorsTaxonomy = (props: {
  label?: string
  selectItemIds: any[]
  onChange?: any
  disabled: boolean
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // const selectedIds=['lmdfkfe-po57-4088-82a9-d27008dfgasdk','qcfj9fe-po57-4088-82a9-d27008dfoiy'];

  const contractorsData = useSelector(
    (state: any) => state.addressBook.contractors,
  );
  const [selectedParentIds, setSelectedParentIds]: any = useState([]);
  const [selectItemIds, setselectItemId]: any[] = useState(props.selectItemIds);
  const [dataList, setDataList] = useState<any[]>([]);
  // const [selectItemId, setselectItemId]: any = useState("88bc4819-hn12-4c38-8a59-20da60ecb89e");

  useEffect(() => {
    console.log('cabUseEffect');
    dispatch(getContractorsData());
  }, []);

  useEffect(() => {
    if (selectItemIds) {

      // setSelectedParentIds(
      //     getPerentFromTreeData(contractorsData, selectItemId)
      // );
    } else {
      setSelectedParentIds([]);
    }
  }, [contractorsData, selectItemIds]);

  useEffect(() => {
    if (props.selectItemIds && _.isArray(props.selectItemIds) && props.selectItemIds.length > 0) {
      let list = props.selectItemIds.map((item: any) => {
        return { 'contractorTaxonomyId': item };
      });
      setDataList(list);
    } else {
      setDataList([]);
    }
    setselectItemId(props.selectItemIds);
  }, [props.selectItemIds]);

  let tableColumns: IColumn[] = [
    {
      key: 'column1',
      name: t('contractorsTaxonomy'),
      fieldName: 'contractorTaxonomyId',
      minWidth: 120,
      maxWidth: 300,
      isResizable: true,
    }];

  const renderButtons = (rowInfo: any) => {
    // const isParent =false;
    const isParent = selectedParentIds.includes(rowInfo.node.id);
    const isSelected = selectItemIds.includes(rowInfo.node.id);
    if (isParent) {
      return (
        <div
          key={rowInfo.node.id}
          className={'Indeterminate'}
          style={{ paddingTop: 6 }}
        >
          <span
            style={{ display: 'none' }}>{JSON.stringify(isParent)}</span> {/* if u remove this part parent selection not working properly   */}
          <Checkbox
            className="btn btn-outline-success"
            style={{ verticalAlign: 'middle' }}
            defaultIndeterminate
            onChange={() => handleNodeCheckboxClick(rowInfo)}
          />
        </div>
      );
    } else {
      return (
        <div
          key={rowInfo.node.id}
          className={'Indeterminate'}
          style={{ paddingTop: 6 }}
        >
          <Checkbox
            className="btn btn-outline-success"
            style={{ verticalAlign: 'middle' }}
            checked={isSelected}
            onChange={() => handleNodeCheckboxClick(rowInfo)}
          />
        </div>
      );
    }

  };

  const handleNodeCheckboxClick = (rowInfo: any) => {
    const fData = getFlatDataFromTree({
      treeData: rowInfo?.node.children,
      getNodeKey: (node: any) => node.id,
      ignoreCollapsed: false,
    });

    let ffData = fData.map((item: any) => {
      return {
        ...item?.node,
      };
    });
    ffData.push(rowInfo?.node);
    props.onChange(ffData, rowInfo?.node);
  };


  return (
    <div>
      {/*<TaxonomyField label={props.label} treeData={contractorsData} selectItemId={selectItemId}>*/}
      {/*<TaxonomyGrid*/}
      {/*treeData={contractorsData}*/}
      {/*renderButtons={renderButtons}*/}
      {/*/>*/}
      {/*</TaxonomyField>*/}
      <TaxonomyActionGrid label={props.label} treeData={contractorsData} dataList={dataList} columns={tableColumns}
                          breadcrumbColumn={'contractorTaxonomyId'} isLoaded={true} disabled={props.disabled}>
        <TaxonomyGrid
          treeData={contractorsData}
          renderButtons={renderButtons}
        />
      </TaxonomyActionGrid>
    </div>
  );
};


export default ContractorsTaxonomy;

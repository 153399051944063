import React, { useEffect, useRef, useState } from 'react';
import {ViewMode} from '../../../shared/ganttv2';
import {Label} from 'office-ui-fabric-react';
import {ViewSwitcher} from './view-switcher';
import {ViewModeContextLTP} from '../productTaxonomyListPane/component';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import { getResourceMatrixData } from '../../../reducers/visualPlanReducer';
import {FontIcon, IComboBoxOption} from '@fluentui/react';
import moment from 'moment/moment';
import { getBuDropdownData } from '../../../reducers/projectDayPlanningReducer';
import { store } from '../../../../index';
import { getBuGroups } from '../../../types/myDayPlanning';

const jsonData = {
  Dates: ['May1', 'May2', 'May3', 'May4'],
  Developers: {
    parentData: [92, 63, 35, 53],
    childs: {Mesith: [20, 15, 76, 13], Pradeep: [20, 15, 76, 13]},
  },
  QA: {
    parentData: [94, 11, 30, 37],
    childs: {Achini: [20, 15, 76, 13], Kavisha: [20, 15, 76, 13]},
  },
  BackEnds: {
    parentData: [25, 79, 18, 60],
    childs: {Supun: [20, 15, 76, 13], Nimesh: [20, 15, 76, 13]},
  },
};

const ROW_HEIGHT = 36

function TableRow({key, keyName, mainValues}: any) {
  const [expanded, setExpnaded] = useState(false);
  const getBackoundColor = (percentage: number) => {
    if (percentage <= 80) {
      return '#EC6981';
    } else if (percentage >= 81 && percentage < 110) {
      return '#A1DAA4';
    } else if (percentage >= 111 && percentage < 121) {
      return '#EFD047'
    } else {
      return '#EC6981'
    }
  };
  return (
    <>
      <tr
        style={{height: ROW_HEIGHT, border: '1px solid #BFC9CA'}}
        key={key}
        onClick={() => {
          setExpnaded(!expanded);
        }}
      >
        <th style={{
          minWidth: '30vw',
          backgroundColor: '#F3F2F1',
          height: ROW_HEIGHT,
          borderRight: '1px solid #BFC9CA'
        }}>
          <div style={{display: 'flex', flexDirection: 'row', marginLeft: 5}}>
            <FontIcon
              aria-label="ChevronRight"
              style={{marginTop: 4, cursor: 'pointer'}}
              iconName={expanded ? 'ChevronDown' : 'ChevronRight'}
            />
            <Label style={{marginLeft: 10, fontSize: 14}}>{keyName}</Label>
          </div>
        </th>

        {Object.entries(mainValues.parentData)?.map(([key, value]: any, index: number) => (
          <td
            key={index}
            style={{
              background: getBackoundColor(value?.percentage),
              minWidth: 60,
              height: ROW_HEIGHT,
              textAlignLast: 'end',
              paddingRight: 10,
              border: '1px solid #BFC9CA',
              fontFamily: 'Segoe UI',
              fontSize: 14,
            }}
          >
            {value?.hours}
          </td>
        ))}
      </tr>
      {expanded && (
        <>
          {Object.entries(mainValues.childs)?.map(([key, values]: any) => (
            <tr
              className='expandRowMT'
              style={{minWidth: 150, height: ROW_HEIGHT, border: '1px solid #BFC9CA'}}
              key={key}
            >
              <td style={{
                minWidth: 60,
                backgroundColor: 'white',
                height: ROW_HEIGHT,
                borderRight: '1px solid #BFC9CA'
              }}>
                <Label style={{marginTop: 5, marginLeft: 50, fontSize: 14}}>- {key}</Label>
              </td>
              {values?.map((value: any, index: number) => (
                <td
                  key={index}
                  style={{
                    background: getBackoundColor(value?.percentage),
                    textAlignLast: 'end',
                    paddingRight: 10,
                    border: '1px solid #BFC9CA',
                    maxHeight: ROW_HEIGHT
                  }}
                >
                  <Label style={{marginLeft: 10, fontSize: 14}}>
                    {value?.hours} {` (${value?.percentage}%)`}

                  </Label>

                </td>
              ))}
            </tr>
          ))}
        </>
      )}
    </>
  );
}

function MatrixTable(props: { matrixType: string, height: number }) {
  const matrixData: any = useSelector((state: any) => state.vp.matrixData);

  const [isChecked, setIsChecked] = React.useState(true);
  const [showGanttChart, setShowGanttChart] = React.useState(true);
  const [view, setView] = React.useState<ViewMode>(ViewMode.Week);
  const [weeks, setWeeks] = React.useState<any[]>([]);
  const [resource, setResource] = React.useState<any>([]);
  const { selectedBuInCuProjectPlanning, projectLevelReloadRequired, productTaxonomyReloadRequired } = useSelector((state: any) => state.vp);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const matrixTableRef = useRef(null);

  const optionsForMePP: IComboBoxOption[] = [
    // {
    //     key: ViewMode.QuarterDay,
    //     text: i18n.t('quaterOfDay'),
    // },
    // {
    //     key: ViewMode.HalfDay,
    //     text: i18n.t('halfOfDay'),
    // },
    // {
    //     key: ViewMode.Day,
    //     text: i18n.t('day'),
    // },
    {
      key: ViewMode.Week,
      text: t('week'),
    },
    {
      key: ViewMode.Month,
      text: t('month'),
    },
    {
      key: ViewMode.QuaterYear,
      text: t('quater'),
    },
  ];

  const onViewModeChange = (viewMode: ViewMode) => {
    if (viewMode === ViewMode.QuaterYear) {
      setView('quarter' as ViewMode);
    } else {
      setView(viewMode);
    }

  };

  const filterData = {
    title: null,
    contractingUnit: store.getState().uprince?.contractingUinit,
    businessUnit: getBuGroups(),
  };

  useEffect(() => {
    const matrixType = props.matrixType === '200' ? 'week' : 'all';
    const filter = {
      viewMode: view.toLowerCase()
    };

    dispatch(getResourceMatrixData({
      type: matrixType,
      filter: filter
    }));

    return () => {
    };
  }, [props.matrixType, view, selectedBuInCuProjectPlanning, projectLevelReloadRequired, productTaxonomyReloadRequired]);

  useEffect(() => {
    if (matrixData) {
      const { week, resource } = matrixData;
      setWeeks(week);
      setResource(resource);

      // Scrolling table to current week
      const currentWeekWithW = `W${moment().week()} `;
      const currentWeekColumnIndex = week.findIndex((element: string | string[]) => element.includes(currentWeekWithW));

      const table: any = matrixTableRef.current;
      const scrollValue = (currentWeekColumnIndex - 1) * 260;

      Promise.all([
        table.scrollLeft += -(52 * 260),
      ]).then(() => {
        table.scrollLeft += scrollValue;
      });
    }
  }, [matrixData]);


  return (
    <div
      className={'matrixTable'}
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <ViewModeContextLTP.Provider
        value={{
          onViewModeChange: (viewMode: ViewMode) => onViewModeChange(viewMode),
          onViewListChange: (isCheck: boolean) => setIsChecked(isCheck),
          isCheck: isChecked,
          onShowGanttChartChangeLTP: (showGanttChart: boolean) => {},
          showGanttChartLTP: showGanttChart
        }}
      >
        <ViewSwitcher
          onViewModeChange={(viewMode: ViewMode) => {
            onViewModeChange(viewMode)
          }}

          onViewListChange={setIsChecked}
          isChecked={isChecked}
          renderFilterComponent={() => <Label></Label>}
          option={optionsForMePP}
        />
        <div ref={matrixTableRef} id={'matrix-table-table'} style={{width: '90vw', height: props.height - 96, overflow: 'overlay'}}>
          <table
            style={{
              borderCollapse: 'collapse',
              width: 'min-content',
              padding: 20,
              tableLayout: 'auto',
            }}
          >
            <thead>
            <tr style={{height: 45, border: '1px solid #BFC9CA'}}>
              <th style={{minWidth: '28vw'}}>
                <div id={'matrix-table-table-header'}
                     style={{width: '100%', background: 'white', height: 45,}}></div>
              </th>
              {weeks?.map((date: any, i: number) => (
                <th key={date + i} style={{
                  background: 'white',
                  minWidth: 254,
                  width: '28vw',
                  verticalAlign: 'middle'
                }}>
                  <Label style={{fontSize: 14}}>
                    {date}
                  </Label>
                </th>
              ))}
            </tr>
            </thead>
            <tbody>
            {resource &&
              Object.entries(resource)?.map(([key, values], i) => (
                <TableRow key={key + i} keyName={key} mainValues={values}/>
              ))}
            </tbody>
          </table>
        </div>
      </ViewModeContextLTP.Provider>
    </div>
  );
}

export default MatrixTable;

import {IStackItemStyles, IStackStyles, IStackTokens} from "@fluentui/react";

export const stackStyles: IStackStyles = {root: {padding: 0, marginBottom: 5}};

export const stackTokens: IStackTokens = {
    childrenGap: 3,
    padding: 10,
};

export const stackItemStyles: IStackItemStyles = {
    root: {
        display: 'flex',
        height: 50,
        width: 0,
        flexGrow: 1,
    },
};
import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';
import { saveContractStateAttr } from '../../../reducers/contractReducer';
import { useDispatch, useSelector } from 'react-redux';
import { CONTRACT } from '../../../types/contract';

const CommandBarLeft = (props: { disable: boolean }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedLotType }: any = useSelector(
    (state: any) => state.contract,
  );

  const newClick = () => {
    messageService.sendMessage({ isNewCONTRACT: true, resetListPaneSelection: true });
    dispatch(saveContractStateAttr('isClear', true));
    //if(selectedLotType === CONTRACTOR){
    dispatch(saveContractStateAttr('selectedLotType', CONTRACT));
    //}
    // props.createNew(true);
  };
  const handleRemove = () => {
    // props.handleRemove();
  };

  const getItems = () => {
    return [
      {
        key: 'new',
        text: t('new'),
        iconProps: { iconName: 'Add' },
        onClick: () => {
          newClick();
        },
        disabled: false,
      },
      // {
      //     key: 'save',
      //     text: t('save'),
      //     iconProps: { iconName: 'Save' },
      //     onClick: () => { saveFormData(); },
      //     disabled: false
      // },
      {
        key: 'Remove',
        onClick: () => {
          handleRemove();
        },
        name: t('remove'),
        iconProps: { iconName: 'Delete' },
        disabled: true,
      },
      // {
      //     key: 'upload',
      //     onClick: () => { handleRemove(); },
      //     name: t('upload'),
      //     iconProps: { iconName: 'Upload' },
      //     disabled: true
      // },
      // {
      //     key: 'download',
      //     onClick: () => { handleRemove(); },
      //     name: t('download'),
      //     iconProps: { iconName: 'Download' },
      //     disabled: true
      // },
    ];
  };

  return (
    <div className="find me">
      <CommandBar
        styles={{root:{ height: 26}}}
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );
};

export default CommandBarLeft;

import * as React from 'react';
import {
  DetailsListLayoutMode,
  DetailsRow,
  IColumn,
  IDetailsListProps,
  IDetailsRowStyles,
  Selection,
} from '@fluentui/react/lib/DetailsList';
import { Fabric } from '@fluentui/react/lib/Fabric';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { ScrollablePane, ShimmeredDetailsList, Sticky, StickyPositionType } from '@fluentui/react';
import { Link } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import UprinceLogger from '../../shared/Logger/logger';
import { connect } from 'react-redux';
import _ from 'lodash';


const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 230px)!important',
    position: 'relative',
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: {
    margin: 0,
  },
  row: {
    display: 'inline-block',
  },
});

export interface ProjectListItem {
  id: string;
  value: string;
  sequenceCode: string;
  title: string;
  projectTypeName: string;
  projectManagementLevelName: string;
  projectTemplateName: string;
  projectToleranceStateName: string;
}

export interface State {
  items: ProjectListItem[];
  redirect: boolean,
  selectionDetails: any,
  selectedProjectId: any
  loadMsg: any,
  announcedMessage?: string;
  filterTitle: any;
  filterType: any;
  filterManagementLevel: any;
  filterTemplate: any;
  filterToleranceState: any;
  isDataLoaded?: boolean;
}

interface Props extends WithTranslation {
  handleProjectUpdateOpen: any;
  projectFormChange: any;
  filterToleranceStateId: any;
  resetFilter: boolean;
  handleResetFilter: any;
  resetToleranceState: any;
  allProjects: any;
  handleProjectListUpdate: any;
  handleProjectListFiltering: any;
  handleProjectListSorting: any;
  updateProjectList: any;
  loadMsg: any;
  projectTypes: any,
  projectManagementLevels: any,
  projectTemplates: any,
  projectToleranceStates: any,
  isDataLoaded?: boolean;
}

class ContractingUnitList extends React.Component<Props, State> {
  private _selection: Selection;
  private _allItems: ProjectListItem[];
  private _columns: IColumn[];

  constructor(props: Props) {
    super(props);
    this.handleRedirect = this.handleRedirect.bind(this, false);
    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails('all'),
          selectedProjectId: this._getSelectionDetails('value'),
        });
      },
    });
    const { t } = this.props;

    this._allItems = [];

    this._columns = [
      {
        key: 'column2',
        name: t('name'),
        fieldName: 'name',
        minWidth: 100,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        //onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      },

    ];

    this.state = {
      items: this._allItems,
      redirect: false,
      selectionDetails: {},
      selectedProjectId: null,
      loadMsg: '',
      filterTitle: null,
      filterType: null,
      filterManagementLevel: null,
      filterTemplate: null,
      filterToleranceState: null,
      isDataLoaded: false,
    };
  }

  handleRedirect() {
    this.setState({ redirect: false });
  }

  componentDidMount() {
    if (_.isArray(this.props.allProjects) && this.props.allProjects && this.props.allProjects.length > 0 && Object.values(this.props.allProjects.map(function(val: any) {
      if (Object.values(val).includes(undefined)) {
        return true;
      }
    })).includes(true)) {
      this.props.handleProjectListUpdate();
      UprinceLogger.log('allProjects-CU', this.props.allProjects);
    }
    UprinceLogger.log('allProjects-CU', this.props.allProjects);
  }

  static getDerivedStateFromProps(nextProps: any) {
    if (nextProps.updateProjectList) {
      nextProps.handleProjectListUpdate();
    }
    if (nextProps.resetFilter) {
      nextProps.handleResetFilter(false);
      return {
        filterTitle: null,
        filterType: null,
        filterManagementLevel: null,
        filterTemplate: null,
        filterToleranceState: null,
        loadMsg: nextProps.loadMsg,
        loading: false,
        //isDataLoaded: nextProps.isDataLoaded,
      };
    } else {
      if (nextProps.filterToleranceStateId) {
        UprinceLogger.log('filterToleranceState', nextProps.filterToleranceStateId);
        return {
          filterToleranceState: (nextProps.filterToleranceStateId) ? nextProps.filterToleranceStateId : false,
          loadMsg: nextProps.loadMsg,
          loading: false,
          //isDataLoaded: nextProps.isDataLoaded,
        };
      } else {
        return {
          loadMsg: nextProps.loadMsg,
          loading: false,
          //isDataLoaded: nextProps.isDataLoaded,
        };
      }

    }

  }

  /** Start filter Functions **/
  onChangeTitle = (value: string | null) => {
    this.setState({
      filterTitle: value,
    }, this.filterProject);
  };

  onChangeType = (value: string | null) => {
    this.setState({
      filterType: value,
    }, this.filterProject);
  };

  onChangeManagementLevel = (value: string | null) => {
    this.setState({
      filterManagementLevel: value,
    }, this.filterProject);
  };

  onChangeTemplate = (value: string | null) => {
    this.setState({
      filterTemplate: value,
    }, this.filterProject);
  };

  onChangeToleranceState = (value: string | null) => {
    this.props.resetToleranceState();
    this.setState({
      filterToleranceState: value,
    }, this.filterProject);

  };

  filterProject = () => {
    if (this.state.filterTitle != null && this.state.filterTitle.length >= 3) {
      let data = {
        filterTitle: this.state.filterTitle,
        filterProjectTypeId:
          this.state.filterType == '0' ? null : this.state.filterType,
        filterManagementLevelId:
          this.state.filterManagementLevel == '0'
            ? null
            : this.state.filterManagementLevel,
        filterToleranceStateId:
          this.state.filterToleranceState == '0'
            ? null
            : this.state.filterToleranceState,
        filterTemplateId:
          this.state.filterTemplate == '0'
            ? null
            : this.state.filterTemplate,
        toleranceState: null,
      };
      this.props.handleProjectListFiltering(data);
    } else if (this.state.filterTitle == null || this.state.filterTitle == '') {
      let data = {
        filterTitle: null,
        filterProjectTypeId:
          this.state.filterType == '0' ? null : this.state.filterType,
        filterManagementLevelId:
          this.state.filterManagementLevel == '0'
            ? null
            : this.state.filterManagementLevel,
        filterToleranceStateId:
          this.state.filterToleranceState == '0'
            ? null
            : this.state.filterToleranceState,
        filterTemplateId:
          this.state.filterTemplate == '0'
            ? null
            : this.state.filterTemplate,
        toleranceState: null,
      };
      this.props.handleProjectListFiltering(data);
    }
  };

  /** End filter Functions**/

  private renderDataList() {
    if (this.props.allProjects && this.props.allProjects.length === 0 && this.state.loadMsg != 'loading') {
      return (<div style={{ paddingTop: 36, paddingLeft: 20 }}>{this.state.loadMsg}</div>);
    } else if (this.state.loadMsg == 'loading') {
      return (
        <div style={{ marginTop: 50 }}>
          {/*<Spinner size={SpinnerSize.large}/>*/}
        </div>);
    } else {
      return (
        <div></div>
      );
    }
  }

  public render() {
    return (
      <Fabric>
        <div className="ms-Grid-row marginTop">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 contract-unit-grid">
            <div className={classNames.wrapper}>
              <ScrollablePane>
                <ShimmeredDetailsList
                  items={this.props.allProjects ? this.props.allProjects : []}
                  columns={this._columns}
                  setKey="set"
                  enableShimmer={!this.props.isDataLoaded}
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionPreservedOnEmptyClick={true}
                  checkboxVisibility={2}
                  onRenderRow={this._onRenderRow}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                  onRenderDetailsHeader={(headerProps, defaultRender) => {
                    if (defaultRender !== undefined) {
                      return (
                        <Sticky
                          stickyPosition={StickyPositionType.Header}
                          isScrollSynced={true}
                          stickyBackgroundColor="transparent"
                        >
                          <div>{defaultRender(headerProps)}</div>
                        </Sticky>
                      );
                    } else {
                      return <span></span>;
                    }
                  }}
                />
              </ScrollablePane>
            </div>
          </div>
        </div>

      </Fabric>
    );

  }

  private _getSelectionDetails(item: string): any {
    const selectionCount = this._selection.getSelectedCount();

    switch (item) {
      case 'value':
        return (this._selection.getSelection().length > 0) ? (this._selection.getSelection()[0] as ProjectListItem).value : '';
      case 'sequenceCode':
        return (this._selection.getSelection().length > 0) ? (this._selection.getSelection()[0] as ProjectListItem).sequenceCode : '';
      case 'all':
        return (this._selection.getSelection().length > 0) ? (this._selection.getSelection()[0] as ProjectListItem) : '';
      default:
        return `${selectionCount} items selected`;
    }
  }

  private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      return <Link style={{ textDecoration: 'none' }} onClick={() => {
        this.props.handleProjectUpdateOpen(props.item.sequenceCode);
      }} to={'#'}><DetailsRow {...props} styles={customStyles}/></Link>;
    }
    return null;
  };

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { items } = this.state;
    const newColumns: IColumn[] = this._columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        this.setState({
          announcedMessage: `${currColumn.name} is sorted ${
            currColumn.isSortedDescending ? 'descending' : 'ascending'
          }`,
        });
        let fieldName = currColumn.fieldName;
        // if(currColumn.fieldName==="projectManagementLevelName"){
        //   //fieldName ="managementLevelListingOrder";
        // }else if(currColumn.fieldName==="projectToleranceStateName"){
        //   //fieldName ="toleranceStateListingOrder";
        // }else{
        //   fieldName =currColumn.fieldName;
        // }
        let sortby = currColumn.isSortedDescending ? 'desc' : 'asc';
        //this.props.handleProjectListSorting(fieldName, sortby);
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    projectManagementLevels: state.cuProject.filterProjectManagementLevel,
    projectToleranceStates: state.cuProject.filterProjectToleranceState,
    projectTypes: state.cuProject.filterProjectType,
    projectStates: state.cuProject.filterProjectState,
    projectTemplates: state.cuProject.filterProjectTemplate,
  };
};

export default withTranslation()(connect(mapStateToProps)(ContractingUnitList));


import React, { FormEvent, useEffect, useState } from 'react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  ChoiceGroup,
  ContextualMenu,
  DetailsListLayoutMode,
  DetailsRow,
  FontWeights,
  getTheme,
  IChoiceGroupOption,
  IColumn,
  IconButton,
  IDetailsListProps,
  IDetailsRowStyles,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  Text,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../../../theme';
import { connect } from 'react-redux';
import { formatDate } from '../../../../shared/util';
import DefaultNumberFormat from '../../../../shared/fluentFinalForm/defaultNumberFormat';
import CustomDatePicker from '../../../../shared/customDatePicker/customDatePicker';
import { APPROVED_STATUS_ID, HANDED_OVER_STATUS_ID, IN_DEVELOPMENT_STATUS_ID } from '../../../../types/projectMolecule';

const theme = getTheme();

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const classNames = mergeStyleSets({
  wrapper: {
    height: '80vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: {
    maxWidth: 400,
  },
  textContent: {
    padding: '15px 10px',
  },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: {
    width: '100%',
  },
});

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};


const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    paddingTop: 20,
    overflowY: 'hidden',
    minWidth: '70vw',
    Height: '81vh',
    //minHeight: screen.width > 1280?'42vh':'60vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'none',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };


const ProductListPaneComponent = (props: {
  isOpen: boolean;
  openModal: () => void;
  resetSelection: () => void;
  onConfirmSelection: () => void;
  loadMsg: string;
  columns: IColumn[];
  listPaneItem: any;
  isDataLoaded?: boolean;
  IsDisabled?: boolean;
  IsDisabledTotalPrice?: boolean;
  selection: any;
  _onChangeProduct: any;
  isCu: boolean;
  formData: any;
}) => {

  const { t } = useTranslation();
  const options: IChoiceGroupOption[] = [
    { key: '0', text: t('stock') },
    { key: '1', text: t('po'), styles: { field: { marginLeft: '15px' } } },
  ];
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(true);
  const titleId = useId('title');
  const theme = getTheme();
  const [isConfirm, setIsConfirm]: any = useState(false);
  useEffect(() => {
    if (checkIsConfirm()) {
      setIsConfirm(true);
    } else {
      setIsConfirm(false);
    }
  }, [props.listPaneItem]);

  const _onChange = (ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined): void => {
    alert(JSON.stringify(option));
    if (option) {
      if (option.key === '0') {
        // setcontractIsRequired(true);
        alert(option.id);
      } else {
        // setcontractIsRequired(false);
        alert(option.id);
      }
    } else {
      // props.projectBreakdown.scope = null;
      // setcontractIsRequired(false);
    }
  };
  const _onChangeProduct = (type: any, index?: number) => {
    let listPaneItem = props.listPaneItem.slice();
    if (index || index === 0) {
      let product = listPaneItem[index];
      if (type === '1') {
        product.isPo = false;
        product.isStock = true;
      } else if (type === '2') {
        product.isPo = true;
        product.isStock = false;
      }

      props._onChangeProduct(listPaneItem);
    } else {
      //alert(JSON.stringify(index))
    }
  };


  const _onChangeTotalPrice = (value: any, index?: number) => {
    let listPaneItem = props.listPaneItem.slice();
    if (index || index === 0) {
      let product = listPaneItem[index];
      product.cTotalPrice = value;

      props._onChangeProduct(listPaneItem);
    } else {
      //alert(JSON.stringify(index))
    }
  };

  const _onChangeRequestedDate = (date: any, index?: number) => {
    let listPaneItem = props.listPaneItem.slice();
    if (index || index === 0) {
      let product = listPaneItem[index];
      product.requestedDeliveryDate = date;
      props._onChangeProduct(listPaneItem);
    } else {
      // alert(JSON.stringify(index))
    }
  };
  const _onChangeExpectedDate = (date: any, index?: number) => {
    let listPaneItem = props.listPaneItem.slice();
    if (index || index === 0) {
      let product = listPaneItem[index];
      product.expectedDeliveryDate = date;
      props._onChangeProduct(listPaneItem);
    } else {
      // alert(JSON.stringify(index))
    }
  };

  const onModalClose = () => {
    props.openModal();
  };
  const _onRenderRow: IDetailsListProps['onRenderRow'] = (row) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (row) {
      return (
        <div
          style={{ textDecoration: 'none' }}
          onClick={() => {
            // props.handleListPaneItemClick(row.item.itemId);
          }}
        >
          <DetailsRow {...row} styles={customStyles}/>
        </div>
      );
    }
    return null;
  };


  const displayMessage = () => {
    return (
      <div style={{ paddingTop: 36, paddingLeft: 20 }}>
        <Text>{t(props.loadMsg)}</Text>
      </div>
    );
  };

  const onRenderCheckbox = (props: any) => {
    return (
      <div style={{ pointerEvents: 'none' }}>
        <Checkbox checked={props.checked} checkmarkIconProps={{ iconName: 'Add' }}/>
      </div>
    );
  };

  const PODeliveryPlanResourceColumn = (item: any, index?: number, column?: IColumn, _onChange?: (ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => void, _onChangeProduct?: any, _onChangeTotalPrice?: any, _onChangeRequestedDate?: any, _onChangeExpectedDate?: any, IsDisabled?: boolean, isCu?: boolean, IsDisabledTotalPrice?: boolean) => {
    if (column && column.fieldName && column.fieldName === 'action') {
      return renderChoiceGroup(item, _onChangeProduct, index, IsDisabled);
    } else if (column && column.fieldName && column.fieldName === 'cTotalPrice') {
      return <div style={{ padding: 5 }} onClick={(e) => {
        e.preventDefault();
      }}>{renderTotalPrice(item, _onChangeTotalPrice, index, (IsDisabled || IsDisabledTotalPrice))}</div>;
    } else if (column && column.fieldName && column.fieldName === 'requestedDeliveryDate') {
      return <div style={{ padding: 5 }} onClick={(e) => {
        e.preventDefault();
      }}>{renderRequestedDeliveryDate(item, _onChangeRequestedDate, index, IsDisabled)}</div>;
    } else if (column && column.fieldName && column.fieldName === 'expectedDeliveryDate') {
      return <div style={{ padding: 5 }} onClick={(e) => {
        e.preventDefault();
      }}>{renderExpectedDeliveryDate(item, _onChangeExpectedDate, index, isCu, IsDisabled)}</div>;
    } else if (column && column.fieldName) {
      const fieldContent = item[column.fieldName];
      return fieldContent ? <span style={{ paddingLeft: 9 }} onClick={() => {

      }}>{fieldContent}</span> : <span style={{ paddingLeft: 9 }}>-</span>;
    } else {
      return <span style={{ paddingLeft: 9 }}>-</span>;
    }
  };


  const renderTotalPrice = (item: any, _onChangeTotalPrice?: any, index?: number, IsDisabled?: boolean) => {
    let resourceTitle: any = [];
    if (item) {
      const resourceDateSpan = <div className={'PoUnitPrice'} style={{ display: 'flex' }} key={'unitPrice' + useId()}>
        <DefaultNumberFormat
          disabled={IsDisabled}
          value={item?.cTotalPrice ? item?.cTotalPrice : 0.00}
          onChange={(newValue?: any) => {
            if (newValue) {
              _onChangeTotalPrice(newValue, index!!);
            } else {
              _onChangeTotalPrice(null, index!!);
            }
          }}
        />
      </div>;
      return resourceDateSpan;
    }
    return '-';
  };


  const renderChoiceGroup = (item: any, _onChangeProduct?: any, index?: number, IsDisabled?: boolean) => {
    if (item) {
      const resourceTitleSpan = <div className={'PoChoiceGroupProduct'} style={{ display: 'flex' }}
                                     key={'ChoiceGroup' + useId()}>
        <ChoiceGroup disabled={isConfirm ? true : IsDisabled} key={'ChoiceGroupBtn' + useId()}
                     onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
                       _onChangeProduct(option?.key, index!!);
                     }} styles={{ root: { marginTop: 0 } }} className={'PoChoiceGroupProduct'}
                     selectedKey={item.isStock ? '1' : item.isPo ? '2' : undefined} options={[
          { key: '1', text: t('stock') },
          { key: '2', text: t('po'), styles: { field: { marginLeft: '15px' } } },
        ]}/>
      </div>;
      return resourceTitleSpan;
    }
    return '-';
  };

  const renderRequestedDeliveryDate = (item: any, _onChangeTest?: any, index?: number, IsDisabled?: boolean) => {
    let resourceTitle: any = [];
    if (item) {
      const resourceDateSpan = <div className={'PoDatePicker'} style={{ display: 'flex' }}
                                    key={'RequestedDatePicker' + useId()}>
        <CustomDatePicker
          setMinDate={null}
          setMaxDate={null}
          disabled={true}
          setValue={formatDate(item.requestedDeliveryDate, 1)}
          getValue={(date: Date) => {
            // onChangeEndDateTime(formatDate(date, 2));
            if (date) {
              _onChangeTest(formatDate(date, 2), index!!);
            } else {
              _onChangeTest(null, index!!);
            }

          }}
        />
      </div>;
      return resourceDateSpan;
    }
    return '-';
  };

  const renderExpectedDeliveryDate = (item: any, _onChangeExpectedDate?: any, index?: number, isCu?: boolean, IsDisabled?: boolean) => {
    let resourceTitle: any = [];
    if (item) {
      const resourceDateSpan = <div className={'PoDatePicker'} style={{ display: 'block', maxWidth: 200 }}
                                    key={'ExpectedDatePicker' + useId()}>
        <CustomDatePicker
          setMinDate={null}
          setMaxDate={null}
          disabled={disabledExpectedDeliveryDate(item, IsDisabled, isCu)}
          setValue={formatDate(item.expectedDeliveryDate, 1)}
          getValue={(date: Date) => {
            // onChangeEndDateTime(formatDate(date, 2));
            if (date) {
              _onChangeExpectedDate(formatDate(date, 2), index!!);
            } else {
              _onChangeExpectedDate(null, index!!);
            }

          }}
        />
      </div>;
      return resourceDateSpan;
    }
    return '-';
  };
  const disabledExpectedDeliveryDate = (resourceItem: any, IsDisabled?: boolean, isCu?: boolean): boolean => {
    if (isCu) {
      if (props.formData?.status?.key === IN_DEVELOPMENT_STATUS_ID) {
        return false;
      } else if (props.formData?.status?.key === APPROVED_STATUS_ID || props.formData?.status?.key === HANDED_OVER_STATUS_ID) {
        return true;
      } else {
        return IsDisabled ? true : false;
      }
    } else if (resourceItem.isPo) {
      return true;
    } else if (props.formData?.status?.key === APPROVED_STATUS_ID || props.formData?.status?.key === HANDED_OVER_STATUS_ID) {
      return true;
    } else {
      return IsDisabled ? true : false;
    }
  };


  const onConfirmSelection = () => {
    if (checkIsChecked()) {
      props.onConfirmSelection();
    } else {

    }

  };

  const checkIsChecked = () => {
    let isChecked: boolean = true;
    props.listPaneItem.map((resourceItem: any, rindex: number) => {
      if (resourceItem.resources) {
        resourceItem.resources.map((resourceItem: any, rindex: number) => {
          if (!resourceItem.isStock && !resourceItem.isPo) {
            isChecked = false;
          }
        });
      }
    });
    return isChecked;
  };

  const checkIsConfirm = () => {
    let obj = props.listPaneItem.find((item: any) => item.action === null);
    return !obj;
  };

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          onModalClose();
        }}
        scrollableContentClassName="body"
        isBlocking={false}
        dragOptions={isDraggable ? dragOptions : undefined}
        className={'grid-righter ms-Grid'}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>{t('deliveryPlan')}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              onModalClose();
            }}
          />
        </div>
        <div className={contentStyles.subHeader}>

        </div>

        <div className={contentStyles.body}>
          <div className={'proj-detail-content inner'}>
            <div className={`wrapper-holder ${classNames.wrapper}`}>
              <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <ShimmeredDetailsList
                  items={props.listPaneItem ? props.listPaneItem : []}
                  columns={props.columns}
                  setKey="set"
                  enableShimmer={!props.isDataLoaded}
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionPreservedOnEmptyClick={true}
                  checkboxVisibility={2}
                  onRenderRow={_onRenderRow}
                  onRenderItemColumn={(
                    item: any,
                    index?: number,
                    column?: IColumn,
                  ) =>
                    PODeliveryPlanResourceColumn(
                      item,
                      index,
                      column,
                      _onChange,
                      _onChangeProduct,
                      _onChangeTotalPrice,
                      _onChangeRequestedDate,
                      _onChangeExpectedDate,
                      props.IsDisabled,
                      props.isCu,
                      props.IsDisabledTotalPrice,
                    )
                  }
                  selection={props.selection}
                  selectionMode={SelectionMode.none}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                />
                {props.isDataLoaded && displayMessage()}
              </ScrollablePane>
            </div>
          </div>
        </div>
        <div className={contentStyles.footer}>
          {props.isCu && <PrimaryButton
            disabled={props.IsDisabled}
            text={t('reset')}
            style={{ marginTop: 20, marginBottom: 30, marginRight: 10 }}
            onClick={() => props.resetSelection()}
          />}
          <PrimaryButton

            text={t('confirm')}
            disabled={props.IsDisabled ? true : !checkIsChecked()}
            style={{ marginTop: 20, marginBottom: 30 }}
            onClick={() => onConfirmSelection()}
          />
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    toleranceStatesFilter: state.billOfResource.toleranceStatesFilter,
    productStatesFilter: state.billOfResource.productStatesFilter,
    itemTypesFilter: state.billOfResource.itemTypesFilter,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProductListPaneComponent);

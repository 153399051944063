import React from 'react';
import './project.css';
import { AnimationStyles, css, mergeStyles, Stack } from '@fluentui/react';
import OverlayLightExample from './filterPanel';
import ProjectList from './contractingUnitList';
import { connect } from 'react-redux';
import {
  addUserProject,
  deleteTeamMembers,
  getAllProjectDropdowns,
  getAllProjects,
  getContractingUnits,
  getFilterProjectListV2,
  getProjectCurrency,
  getProjectDefinition,
  getProjectManagementLevel,
  getProjectState,
  getProjectTemplate,
  getProjectToleranceState,
  getProjectType,
  getUserRoles,
  saveProjectDefinition,
  updateIsDetailsLoadedState,
  updateProjectDefinition,
} from '../../reducers/cuProjectReducer';
import { Redirect, withRouter } from 'react-router';
import client from '../../api';
import history from '../../history';
import ConfirmDialog from './dialog';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getContractingUnit } from '../../shared/util';

export interface State {
  showDocumentPane: boolean;
  display: string;
  dialogHidden: boolean;
  projectFormChange: boolean;
  nextProjectId: any;
  resetForm: boolean;
  redirect: boolean;
  selectedProject: [];
  loading: boolean;
  showPanel: boolean;
  width: number;
  activeClass: string;
  headerFilter: boolean;
  selectedProjectId: any;
  loadProject: any;
  showProjectDetails: any;
  projectManagementLevel: any;
  projectCurrency: any;
  showMsg: boolean;
  alertMsg: string;
  alertMsgType: string;
  sortingAttribute: any;
  sortingOrder: any;
  filterTitle: any;
  filterProjectTypeId: any;
  filterManagementLevelId: any;
  filterToleranceStateId: any;
  filterTemplateId: any;
  filterToleranceState: any;
  resetFilter: boolean;
  activeProject: boolean;
  submitProject: boolean;
  saveBtnDisabled: boolean;
  userRoles: any[];
}

interface Props extends WithTranslation {
  updateIsDetailsLoadedState: any;
  getAllProjectDropdowns: any;
  getFilterProjectListV2: any;
  getProjectManagementLevel: any;
  getProjectCurrency: any;
  projectManagementLevel: any;
  getProjectToleranceState: any;
  projectToleranceState: any;
  projectCurrency: any;
  getProjectState: any;
  projectState: any;
  getProjectType: any;
  projectType: any;
  getProjectTemplate: any;
  projectTemplate: any;
  getAllProjects: any;
  contractingUnits: any;
  getProjectDefinition: any;
  selectedProject: any;
  lastInsertProjectId: any;
  saveProjectDefinition: any;
  updateProjectDefinition: any;
  updateProjectList: boolean;
  saveMsgType: string;
  saveMsg: string;
  loadMsg: string;
  alertMsg: string;
  alertMsgType: string;
  resetForm: boolean;
  isDataLoaded?: boolean;
  isDetailsLoaded?: boolean;
  getUserRoles: any;
  userRoles: any[];
  deleteTeamMembers: any;
  addUserProject: any;
  getContractingUnits: any;
}

class ContractingUnitMainLayout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      display: 'none',
      showDocumentPane: false,
      dialogHidden: true,
      nextProjectId: '',
      projectFormChange: false,
      resetForm: false,
      activeProject: true,
      resetFilter: false,
      redirect: false,
      loadProject: false,
      selectedProjectId: 0,
      projectManagementLevel: '',
      projectCurrency: '',
      loading: false,
      selectedProject: [],
      width: window.innerWidth,
      showPanel: false,
      headerFilter: false,
      activeClass: 'grid-righter',
      showProjectDetails: false,
      showMsg: false,
      alertMsgType: '',
      alertMsg: '',
      sortingAttribute: null,
      sortingOrder: null,
      filterTitle: null,
      filterProjectTypeId: null,
      filterTemplateId: null,
      filterManagementLevelId: null,
      filterToleranceStateId: null,
      filterToleranceState: null,
      submitProject: false,
      saveBtnDisabled: false,
      userRoles: [],
    };
    this.updateDimensions = this.updateDimensions.bind(this);
    this._handleClick = this._handleClick.bind(this);
  }

  componentDidMount() {
    this.props.getContractingUnits({
      title: null,
      projectTypeId: null,
      managementLevelId: null,
      toleranceStateId: null,
      toleranceState: null,
      sorter: {
        attribute: null,
        order: null,
      },
    });
    if (this.props.projectState?.length < 0) {
      this.props.getAllProjectDropdowns();
    }
    this.props.getUserRoles();
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('load', this.updateDimensions);
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    const id = nextProps.match.params.id;
    const prevId = prevState.selectedProjectId;
    const showDocumentPane = id ? true : prevState.showDocumentPane;
    const display = showDocumentPane ? 'block' : 'none';
    if (id && prevId != id) {
      return {
        selectedProjectId: nextProps.match.params.id,
        loadProject: true,
        display: display,
      };
    } else {
      return {
        selectedProjectId: nextProps.match.params.id,
        loadProject: false,
        display: display,
      };
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.userRoles !== this.props.userRoles) {
      this.setState({ userRoles: this.props.userRoles });
    }
  }

  updateDimensions() {
    this.setState({
      width: window.innerWidth,
    });
    if (this.state.width > 1100) {
      if (this.state.selectedProjectId) {
        this.setState({ showPanel: false, showProjectDetails: false });
      } else {
        this.setState({ showPanel: false, showProjectDetails: false });
      }
    } else {
      if (this.state.selectedProjectId) {
        this.setState({ showPanel: true, showProjectDetails: false });
      } else {
        this.setState({ showPanel: false, showProjectDetails: false });
      }
    }

    this.setState({ headerFilter: false });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  private handleProjectLoad = (id: {}): void => {
    if (this.state.width <= 1100) {
      this.setState({ showPanel: true, showProjectDetails: false });
    } else {
      this.setState({
        showProjectDetails: false,
        showPanel: false,
      });
    }
    this.props.getProjectDefinition(id).then(() => {
    });
  };

  private handelShowDocumentPane = (showDocumentPane: boolean): void => {
    if (showDocumentPane) {
      this.props.updateIsDetailsLoadedState();
      if (this.state.width > 1100) {
        this.setState({
          display: 'block',
          showDocumentPane: showDocumentPane,
          resetForm: true,
          selectedProjectId: 0,
          showPanel: false,
          showProjectDetails: false,
        });
      } else {
        this.setState({
          display: 'block',
          showDocumentPane: showDocumentPane,
          resetForm: true,
          selectedProjectId: 0,
          showPanel: true,
          showProjectDetails: false,
        });
      }
    } else {
      this.setState({
        display: 'none',
        showPanel: false,
        showDocumentPane: showDocumentPane,
      });
    }
    history.push(`/CU/${getContractingUnit()}`);
  };

  private handleProjectUpdateOpen = (projectId: any): void => {
    history.push(`/CU/${projectId}`);

  };

  private handleProjectListUpdate = (): void => {
    if (this.state.selectedProjectId) {
      this.props.getProjectDefinition(this.state.selectedProjectId);
    }
  };

  private handleProjectListSorting = (
    attribute: string,
    order: string,
  ): void => {
    this.setState(
      {
        sortingAttribute: attribute,
        sortingOrder: order,
      }
    );
  };

  private resetToleranceState = () => {
    this.setState({
      filterToleranceStateId: null,
    });
  };

  private handleProjectListFiltering = (data: {
    filterTitle: null;
    filterProjectTypeId: null;
    filterManagementLevelId: null;
    filterToleranceStateId: null;
    filterTemplateId: null;
    filterToleranceState: null;
  }): void => {
    this.setState(
      {
        filterTitle: data.filterTitle,
        filterProjectTypeId: data.filterProjectTypeId,
        filterManagementLevelId: data.filterManagementLevelId,
        filterToleranceStateId: data.filterToleranceStateId,
        filterToleranceState: data.filterToleranceState,
        filterTemplateId: data.filterTemplateId,
      }
    );
  };

  private handleResetFilter = (resetFilter: boolean): void => {
    this.setState({
      resetFilter: resetFilter,
    });
  };

  private handleFilterShortcutPane = (
    filterToleranceStateId: any,
    activeProject: boolean,
  ): void => {
    this.setState(
      {
        showProjectDetails: false,
        showPanel: false,
        resetFilter: true,
        filterTitle: null,
        filterManagementLevelId: null,
        filterProjectTypeId: null,
        filterTemplateId: null,
        filterToleranceStateId: filterToleranceStateId,
        activeProject: activeProject,
      }
    );
  };

  private _handleClick(): void {
    if (this.state.headerFilter == false) {
      this.setState({ activeClass: 'grid-righter', headerFilter: true });
    } else {
      this.setState({ activeClass: 'grid-righter', headerFilter: false });
    }
  }

  onClickConfirmDialog = () => {
    this.setState({ dialogHidden: true, projectFormChange: false });
    history.push(`/project/${this.state.nextProjectId}`);
  };

  onClickCancelDialog = () => {
    this.setState({ dialogHidden: true });
  };

  renderListPane = () => {
    return (
      <div>
        <Stack horizontal>
          <OverlayLightExample
            handleFilterShortcutPane={(
              filterToleranceStateId: any,
              activeProject: boolean,
            ) =>
              this.handleFilterShortcutPane(
                filterToleranceStateId,
                activeProject,
              )
            }
          />

          <div className={this.state.activeClass}>

            <Stack className="project-list-grid">
              <ProjectList
                projectFormChange={this.state.projectFormChange}
                resetFilter={this.state.resetFilter}
                filterToleranceStateId={this.state.filterToleranceStateId}
                allProjects={this.props.contractingUnits}
                isDataLoaded={this.props.isDataLoaded}
                loadMsg={this.props.loadMsg}
                updateProjectList={this.props.updateProjectList}
                resetToleranceState={() => this.resetToleranceState()}
                handleProjectListUpdate={() => this.handleProjectListUpdate()}
                handleProjectListSorting={(attribute: string, order: string) => {
                  this.handleProjectListSorting(attribute, order);
                }
                }
                handleProjectListFiltering={(data: any) =>
                  this.handleProjectListFiltering(data)
                }
                handleResetFilter={(resetFilter: boolean) =>
                  this.handleResetFilter(resetFilter)
                }
                handleProjectUpdateOpen={(projectId: any) =>
                  this.handleProjectUpdateOpen(projectId)
                }
              />
              <ConfirmDialog
                show={this.state.dialogHidden}
                onClickCancelDialog={this.onClickCancelDialog}
                onClickConfirmDialog={this.onClickConfirmDialog}
              />
            </Stack>
          </div>
        </Stack>
      </div>
    );
  };

  render() {
    const animation = mergeStyles(
      this.state.showProjectDetails
        ? AnimationStyles.slideLeftIn400
        : AnimationStyles.slideRightIn400,
    );
    if (this.state.redirect) {
      return <Redirect to="/project"/>;
    }
    const { t } = this.props;
    return (
      <div className={'ms-Grid'} dir="ltr">
        <div className={'ms-Grid'} style={{ width: '100%' }}>
          <div className="ms-Grid-row">
            <div className={css('ms-Grid')} style={{ width: '100%' }}>
              <div className="ms-Grid-row">
                <div
                  className={
                    this.state.showProjectDetails
                      ? 'ms-Grid-col ms-sm6 ms-md8 ms-lg6 padding-0 '
                      : 'ms-Grid-col ms-sm12 ms-md12 ms-lg12 padding-0 '
                  }
                >
                  {this.renderListPane()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    projectManagementLevel: state.cuProject.projectManagementLevel,
    projectToleranceState: state.cuProject.projectToleranceState,
    projectType: state.cuProject.projectType,
    projectCurrency: state.cuProject.projectCurrency,
    projectState: state.cuProject.projectState,
    projectTemplate: state.cuProject.projectTemplate,
    selectedProject: state.cuProject.selectedProject,
    contractingUnits: state.cuProject.contractingUnits,
    lastInsertProjectId: state.cuProject.lastInsertProjectId,
    updateProjectList: state.cuProject.updateProjectList,
    saveMsgType: state.cuProject.saveMsgType,
    saveMsg: state.cuProject.saveMsg,
    loadMsg: state.cuProject.loadMsg,
    alertMsgType: state.cuProject.alertMsgType,
    alertMsg: state.cuProject.alertMsg,
    resetForm: state.cuProject.resetForm,
    isDataLoaded: state.cuProject.isDataLoaded,
    isDetailsLoaded: state.cuProject.isDetailsLoaded,
    userRoles: state.cuProject.userRoles,

  };
};

const mapDispatchToProps = {
  getProjectManagementLevel,
  getProjectToleranceState,
  getProjectCurrency,
  getProjectState,
  getProjectType,
  getProjectTemplate,
  getAllProjects,
  getProjectDefinition,
  saveProjectDefinition,
  updateProjectDefinition,
  getAllProjectDropdowns,
  getFilterProjectListV2,
  updateIsDetailsLoadedState,
  getUserRoles,
  deleteTeamMembers,
  addUserProject,
  getContractingUnits,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(ContractingUnitMainLayout)),
);

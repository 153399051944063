import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  ContextualMenu,
  FontWeights,
  getTheme,
  IconButton,
  IDragOptions,
  IIconProps,
  Label,
  mergeStyleSets,
  Modal,
  PrimaryButton, Text,
} from '@fluentui/react';
import {uPrinceTheme} from '../../../../theme';
import {useId} from '@uifabric/react-hooks';
import BreadcrumbBar from '../../../shared/breadcrumbBar/breadcrumbBar';
import {getBreadcrumbItemFromData} from '../../../shared/util';
import {messageService} from '../../../services/messageService';
import CustomSortableTreeProduct from '../../../shared/treeStructureDropdown/customSortableTreeProduct';
import {ProjectBreakdown} from '../../../types/projectBreakdownStructure';
import ProgressBar from "../../../shared/progressBar/progressBar";
import { customDropdownErrorMessage } from '../../../shared/customDropdown/customDropdown';
import client from '../../../api';

const ProductTaxonomyField = (props: {
  treeData: any;
  onSelectItem: (id: string) => void;
  selectItemId: string | null;
  label?: string | null;
  treeLevel: any;
  onFilterTaxonomyNodeChanged: any;
  projectBreakdown?: ProjectBreakdown;
  disabled?: boolean;
  showProgressBar?: boolean;
  isModal?: boolean;
  validationMessage?: string | null;
  cu?: string | null;
  project?: string | null;
  forCertifications?: boolean | undefined
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [productStatusList, setProductStatusList] = useState<any[]>([]);
  const titleId = useId('title');
  const theme = getTheme();
  const dragOptions: IDragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
  };
  const cancelIcon: IIconProps = { iconName: 'Cancel' };
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
    header: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '2px 2px 2px 10px',
        backgroundColor: uPrinceTheme.palette.themePrimary,
        fontSize: 18,
        color: 'white',
      },
    ],
    footer: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '0px 24px 14px 24px',
        textAlign: 'end',
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 0px 24px',
      // overflowY: 'hidden',
      paddingTop: 20,
      minWidth: screen.width > 1280 ? '40vw' : '50vw',
      minHeight: screen.width > 1280 ? '60vh' : '75vh',
      height: screen.width > 1280 ? '80vh' : '75vh',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
    subHeader: {
      flex: '1 1 auto',
      display: 'none',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      fontSize: 14,
      color: uPrinceTheme.palette.themePrimary,
      backgroundColor: '#FFF7F4',
      height: 40,
      marginLeft: 'auto',
      marginRight: '2px',
    },
  });
  const cancelIconButtonStyles = {
    root: {
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
      color: 'white',
    },
    rootHovered: { color: theme.palette.neutralDark },
  };
  const whiteIconButtonStyles = {
    root: { color: uPrinceTheme.palette.white },
    rootHovered: { color: theme.palette.neutralDark },
  };
  const cancelIconWhite: IIconProps = {
    iconName: 'Cancel',
    styles: whiteIconButtonStyles,
  };
  const onCreateNode = (newNode: any) => {
    messageService.sendMessage({ createNewNode: newNode });
  };

  const onAddNode = (selectedLevel: any) => {
    messageService.sendMessage({ selectedNodeLevel: selectedLevel });
  };

  const openTaxonomyModal = () => {
    messageService.sendMessage({ openTaxonomyModal: true });
  };

  const closeTaxonomyModal = () => {
    messageService.sendMessage({ closeTaxonomyModal: true });
  };

  const transformData = (data: []) => {
    let dataValues: { key: any; text: any; }[] = [];
    dataValues.push({ key: '1', text: 'All' });
    if (Array.isArray(data)) {
      const keySet = new Set<string>();      
      data.forEach(({id, name})=>{
          if (!keySet.has(id)){
              keySet.add(id);
              dataValues.push({ key: id, text: name });
            }
      })}
      return dataValues;
  }

  useEffect(() => {
    getProductStatusOptions()
  },[])

 const getProductStatusOptions = async () => {
  try {
    await client.get('Pbs/GetPbsDropdownData').then((res: any) => {
      const productStatus =  res?.data?.result?.productStates;
      if (productStatus && productStatus.length>0) {
        const filteredProductStatusList = productStatus.filter((item : any) => 
          item.name.toLowerCase() === 'pending development'.toLowerCase() || 
          item.name.toLowerCase() === 'in development'.toLowerCase() || 
          item.name.toLowerCase() === 'to do'.toLowerCase());
        setProductStatusList(transformData(filteredProductStatusList?filteredProductStatusList:[]));
      }
    })
  } catch (error) {
    console.log('error', error);
    setProductStatusList([])
  }
 }

  return (
    <div className={''} style={{ paddingTop: props.forCertifications === true ? 0 : 10 }}>
      <div className={`ms-Grid tree-view-field${props.isModal && '-in-modal'}`}>
        {props.label ? 
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
              <Label>{props.label}</Label>
            </div>
          </div>
        : null}
        <div className="ms-Grid-row" style={{display:'flex'}}>
          <div className="ms-Grid-col ms-sm12 ms-md11 ms-lg11" style={props.forCertifications === true ? {display: 'none'} : {}}>
            {/* <span>{props.selectItemId}</span>     this is the parentId */}
            <BreadcrumbBar validation={props.validationMessage} items={getBreadcrumbItemFromData(props.treeData, props.selectItemId)}/>
          </div>
          <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg1">
            <PrimaryButton
              text={t('help')}
              onClick={() => {
                setIsOpen(true);
                openTaxonomyModal();
              }}
              disabled={!!props.disabled}
            />
          </div>
        </div>
        {props.validationMessage && (
          <Text className={customDropdownErrorMessage}>{props.validationMessage}</Text>
        )}
      </div>

      <Modal
        titleAriaId={titleId}
        isOpen={isOpen}
        onDismiss={() => {
          setIsOpen(false);
          closeTaxonomyModal();
        }}
        isBlocking={true}
        dragOptions={dragOptions}
      >
        <div className={contentStyles.header}>
          <span id={titleId}> {props.label} </span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              setIsOpen(false);
              closeTaxonomyModal();
            }}
          />
        </div>
        <ProgressBar show={props?.showProgressBar ?? false}/>
        <div className={contentStyles.body}>
          <div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                <CustomSortableTreeProduct
                  label={''}
                  selectItemId={props.selectItemId}
                  forCertifications={props.forCertifications}
                  treeData={props.treeData}
                  onSelectItem={(id: string) => {
                    props.onSelectItem(id);
                  }}
                  treeLevel={props.treeLevel}
                  onCreateNode={(newNode: any) => onCreateNode(newNode)}
                  onAddNode={(selectedLevel: any) => onAddNode(selectedLevel)}
                  onFilterTaxonomyNodeChanged={
                    props.onFilterTaxonomyNodeChanged
                  }
                  projectBreakdown={props.projectBreakdown}
                  productStatusList={productStatusList}
                  cu={props.cu}
                  project={props.project}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={cancelIconWhite}
            text={t('close')}
            style={{ marginTop: 10, marginBottom: 10, marginRight: 10 }}
            onClick={() => {
              setIsOpen(false);
              closeTaxonomyModal();
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ProductTaxonomyField;

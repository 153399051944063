import {
  Checkbox,
  DetailsListLayoutMode,
  DetailsRow,
  Fabric,
  IColumn,
  IDetailsFooterProps,
  IDetailsListProps,
  IDetailsRowStyles,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
  Text,
  TextField,
} from '@fluentui/react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { messageService } from '../../../../../services/messageService';
import { BorListPaneItem, FilterItem } from '../../../../../types/billOfResources';

export const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 25vh)!important',
    position: 'relative',
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: {
    margin: 0,
  },
  row: {
    display: 'inline-block',
  },
});

const BorListComponent = (props: {
  filter: FilterItem;
  selection: any;
  columns: IColumn[];
  borListPaneItem: BorListPaneItem[];
  handleListPaneItemClick: (id: string) => void;
  handleFilterChange: (filter: FilterItem) => void;
  isDataLoaded?: boolean;
  loadMsg: string;
  resetSelection: () => void;
  dropDownOptions: any;
}) => {
  const reloadListPane = (value: any) => {
    if ((value && value.length >= 3) || value === null) {
      messageService.sendMessage({
        reloadBorListPaneItem: true, //use on listPane/container
      });
    }
  };

  const _onRenderRow: IDetailsListProps['onRenderRow'] = (row) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (row) {
      return (
        <span
          style={{ textDecoration: 'none' }}
          onClick={() => {
            props.handleListPaneItemClick(row.item.itemId);
          }}
        >
          <DetailsRow {...row} styles={customStyles}/>
        </span>
      );
    }
    return null;
  };

  const _onRenderDetailsFooter = (
    detailsFooterProps: IDetailsFooterProps | undefined,
  ) => {
    return (
      <DetailsRow
        className="footer-as-filter"
        {...detailsFooterProps}
        columns={detailsFooterProps?.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps?.groupNestingDepth}
        selection={detailsFooterProps?.selection}
        onRenderItemColumn={_renderFilterItemColumn}
      />
    );
  };

  const _renderFilterItemColumn = (
    item?: BorListPaneItem,
    index?: number,
    column?: IColumn,
  ) => {
    switch (column?.key) {
      case 'column1': {
        return (
          <div>
            <TextField
              value={props.filter.borTitle ? props.filter.borTitle : ''}
              onChange={(event, value) => {
                if (value) {
                  props.filter.borTitle = value;
                } else {
                  props.filter.borTitle = null;
                }
                props.handleFilterChange(props.filter);
                reloadListPane(props.filter.borTitle);
              }}
            />
          </div>
        );
        break;
      }
      case 'column2': {
        return (
          <div>
            <TextField
              value={props.filter.product ? props.filter.product : ''}
              onChange={(event, value) => {
                if (value) {
                  props.filter.product = value;
                } else {
                  props.filter.product = null;
                }
                props.handleFilterChange(props.filter);
                reloadListPane(props.filter.product);
              }}
            />
          </div>
        );
        break;
      }
      case 'column3': {
        return (
          <div>
            <TextField
              value={
                props.filter.utilityParent ? props.filter.utilityParent : ''
              }
              onChange={(event, value) => {
                if (value) {
                  props.filter.utilityParent = value;
                } else {
                  props.filter.utilityParent = null;
                }
                props.handleFilterChange(props.filter);
                reloadListPane(props.filter.utilityParent);
              }}
            />
          </div>
        );
        break;
      }
      case 'column4': {
        return (
          <div>
            <TextField
              value={
                props.filter.locationParent ? props.filter.locationParent : ''
              }
              onChange={(event, value) => {
                if (value) {
                  props.filter.locationParent = value;
                } else {
                  props.filter.locationParent = null;
                }
                props.handleFilterChange(props.filter);
                reloadListPane(props.filter.locationParent);
              }}
            />
          </div>
        );
        break;
      }
      default: {
        break;
      }
    }
  };

  const { t } = useTranslation();
  const displayMessage = () => {
    return (
      <div style={{ paddingTop: 36, paddingLeft: 20 }}>
        <Text>{t(props.loadMsg)}</Text>
      </div>
    );
  };

  const onRenderCheckbox = (props: any) => {
    return (
      <div style={{ pointerEvents: 'none' }}>
        <Checkbox checked={props.checked} checkmarkIconProps={{ iconName: 'Add' }}/>
      </div>
    );
  };
  return (
    <Fabric>
      <div className={`wrapper-holder ${classNames.wrapper}`}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <ShimmeredDetailsList
            items={props.borListPaneItem ? props.borListPaneItem : []}
            columns={props.columns}
            setKey="set"
            enableShimmer={!props.isDataLoaded}
            layoutMode={DetailsListLayoutMode.justified}
            selectionPreservedOnEmptyClick={true}
            checkboxVisibility={1}
            onRenderRow={_onRenderRow}
            onRenderDetailsFooter={_onRenderDetailsFooter}
            selection={props.selection}
            selectionMode={SelectionMode.multiple}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            onRenderDetailsHeader={(headerProps, defaultRender) => {
              if (defaultRender !== undefined) {
                return (
                  <Sticky
                    stickyPosition={StickyPositionType.Header}
                    isScrollSynced={true}
                    stickyBackgroundColor="transparent"
                  >
                    <div>{defaultRender(headerProps)}</div>
                  </Sticky>
                );
              } else {
                return <span></span>;
              }
            }}
            onRenderCheckbox={onRenderCheckbox}
          />
          {props.isDataLoaded && displayMessage()}
        </ScrollablePane>
      </div>
    </Fabric>
  );
};

const mapStateToProps = (state: any) => {
  return {
    toleranceStatesFilter: state.billOfResource.toleranceStatesFilter,
    productStatesFilter: state.billOfResource.productStatesFilter,
    itemTypesFilter: state.billOfResource.itemTypesFilter,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BorListComponent);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {IColumn, Icon, Selection} from '@fluentui/react';
import history from '../../../history';

import ListPaneComponent from './component';
import {withRouter} from 'react-router';
import {WithTranslation, withTranslation} from 'react-i18next';
import {readContractDropDownData, saveContractStateAttr} from '../../../reducers/contractReducer';
import {messageService} from '../../../services/messageService';
import {
    AWARDED_STATUS_ID,
    Contract,
    ContractDropDowns,
    ContractListItem,
    ContractListPaneFilter,
    CONTRACTOR,
    formData,
} from '../../../types/contract';
import {getContractingUnit, getProject} from '../../../shared/util';
import {getFlatDataFromTree, getTreeFromFlatData} from '@nosferatu500/react-sortable-tree';
import {iconColors} from '../../../../theme';

export interface State {
  redirect: boolean;
  isChange: boolean;
  loading: boolean;
  filter: ContractListPaneFilter;
  announcedMessage: string;
  selectedListItemIds: [] | string[];
  selectedListItemSequenceCode: [] | string[];
  selectedListItemType: [] | string[];
  formData: Contract;
  selectedListItems: ContractListItem[];
}

export interface Props extends WithTranslation {
  listPaneItems: ContractListItem[];
  reloadListPaneItem: () => void;
  handelFilter: (filter: ContractListPaneFilter) => void;
  isDataLoaded?: boolean;
  isChange: boolean;
  filter: ContractListPaneFilter;
  resourceTypes: [];
  loadMsg: string;
  toleranceStatesFilter: [];
  productStatesFilter: [];
  itemTypesFilter: [];
  handleSelectedListPaneItem: (selectedItemIds: [] | string[]) => void;
  formData: Contract;
  projectId: string | null;
  contractDropDowns: ContractDropDowns;
  reSizer: number;
  readContractDropDownData: () => void;
  saveContractStateAttr: any;
}

class ListPane extends Component<Props, State> {
  private _Selection: Selection;
  private _columns: IColumn[];
  private _columnsReSizer: IColumn[];
  subscription: any;

  constructor(props: Props) {
    super(props);
    this._Selection = new Selection({
      onSelectionChanged: () => {
        this._getselectedType();
        this.setState(
          {
            selectedListItemIds: this._getSelectedIds(),
            selectedListItemSequenceCode: this._getselectedSequenceCode(),
            selectedListItems: this._getSelectedListItems(),
            //selectedListItemType: this._getselectedType(),
          },
          this.handleSelectedItem,
        );
      },
    });
    this.state = {
      selectedListItems: [],
      selectedListItemIds: [],
      selectedListItemSequenceCode: [],
      selectedListItemType: [],
      redirect: false,
      isChange: false,
      loading: false,
      announcedMessage: '',
      filter: new ContractListPaneFilter(),
      formData: formData,
    };

    this._columns = [
      {
        key: 'column1',
        name: this.props.t('lot/contractor'),
        fieldName: 'title',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column2',
        name: this.props.t('dateOfStatusChange'),
        fieldName: 'statusChangeDate',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        // onColumnClick: this._onColumnClick,
      },
      {
        key: 'column3',
        name: '',
        fieldName: 'isInviteSend',
        minWidth: 50,
        maxWidth: 50,
        isResizable: true,
        onRender: (item: any) => {
          if (item.sequenceId && !item?.isInviteSend) {
            return <span><Icon style={{
              fontSize: 16,
              color: iconColors.warningIconColor,
            }} iconName="WarningSolid"/> </span>;
          } else {
            return <span></span>;
          }
        },
        // onColumnClick: this._onColumnClick,
      },
      {
        key: 'column4',
        name: this.props.t('status'),
        fieldName: 'status',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        // onColumnClick: this._onColumnClick,
      },
      { //this price is tender budget
        key: 'column5',
        name: this.props.t('price'),
        fieldName: 'price',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onRender: (item: any) => {
          let formatter = new Intl.NumberFormat(window.navigator.language, {
            style: 'currency',
            currency: 'EUR',
          });
          return <div style={{ maxWidth: 200, display: 'flex', justifyContent: 'flex-end',color:item?.type === CONTRACTOR && item?.statusId!=AWARDED_STATUS_ID?'#D3D3D3':'inherit' }}>
            {item?.price && <span>{formatter.format(item?.price)}</span>}
          </div>
        }
        // onColumnClick: this._onColumnClick,
      },
    ];
    this._columnsReSizer = [
      {
        key: 'column1',
        name: this.props.t('lot/contractor'),
        fieldName: 'title',
        minWidth: 165,
        maxWidth: 200,
        isResizable: true,
        // onColumnClick: this._onColumnClick,
      },
    ];
  }

  // static getDerivedStateFromProps(nextProps: any) {
  //   if (nextProps.isChange) {
  //     nextProps.reloadListPaneItem();
  //   }
  //   return {
  //     loading: false,
  //     filter: nextProps.filter,
  //     formData: nextProps.formData,
  //   };
  // }

  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewPbs) {
          this._Selection.selectToKey('', true);
        }
        if (data.data.resetListPaneSelection) {
          this._Selection.setAllSelected(false);
          this.resetSelection();
        }
      }
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.isDataLoaded != this.props.isDataLoaded) {
      if (this.props.isDataLoaded) {
        this.resetSelection();
      }
    }
  }

  handleListPaneItemClick = (id: string) => {
    //this.props.getById(id);
    if (getContractingUnit() && !getProject()) {
      history.push(`/CU/${getContractingUnit()}/contract/${id}`);
    } else {
      history.push(`/CU/${getContractingUnit()}/project/${getProject()}/contract/${id}`);
    }
  };

  handleSelectedItem = () => {
    this.props.handleSelectedListPaneItem(this.state.selectedListItemIds);
    if (this.state.selectedListItemSequenceCode) {
      let lastId = this.state.selectedListItemSequenceCode.pop();
      if (lastId) {
        this.handleListPaneItemClick(lastId);
      }
    }
  };

  handleFilterChange = (filter: ContractListPaneFilter) => {
    this.props.handelFilter(filter);
  };

  handleFilterSortChange = () => {
    this.props.handelFilter(this.state.filter);
  };

  resetSelection = () => {
    if (
      this.state.formData &&
      this.state.formData.id &&
      this.props.isDataLoaded
    ) {
      this._Selection.setKeySelected(
        this.state.formData.id,
        true,
        false,
      );
    } else {
      this._Selection.selectToKey('', true);
    }
  };

  getContractTreeData = () => {

    const fData = this.props.listPaneItems.map((item: any) => {
      return {
        ...item,
        parentId: item.contractId === 'ContractId' ? 0 : item.contractId,
      };
    });

    const tData = getTreeFromFlatData({
      flatData: fData,
      getKey: (node: any) => node.id,
      getParentKey: (node: any) => node.parentId,
      // @ts-ignore
      // rootKey: null
    });

    const ffData = getFlatDataFromTree({
      treeData: tData,
      getNodeKey: (node: any) => node.id,
      ignoreCollapsed: false,
    });

    let flatData = ffData.map((item: any) => {
      return {
        contractId: item?.node?.contractId,
        contractSequenceId: item?.node?.contractSequenceId,
        id: item?.node?.id,
        price: item?.node?.price,
        sequenceId: item?.node?.sequenceId,
        status: item?.node?.status,
        statusChangeDate: item?.node?.statusChangeDate,
        statusId: item?.node?.statusId,
        title: item?.node?.title,
        type: item?.node?.type,
        isInviteSend: item?.node?.isInviteSend,

      };
    });
    return flatData;

  };

  render() {
    this.getContractTreeData();
    return (
      <div>
        <ListPaneComponent
          listPaneItems={
            this.props.listPaneItems ? this.getContractTreeData() : []
          }
          selection={this._Selection}
          handleListPaneItemClick={(id: string) => {
          }
            //this.handleListPaneItemClick(id)
          }
          resetSelection={() => this.resetSelection()}
          formData={this.state.formData}
          columns={(this.props.reSizer === 300) ? this._columnsReSizer : this._columns}
          filter={this.props.filter}
          loadMsg={this.props.loadMsg}
          handleFilterChange={(filter: ContractListPaneFilter) =>
            this.handleFilterChange(filter)
          }
          isDataLoaded={this.props.isDataLoaded}
          dropDownOptions={this.props.contractDropDowns}
        />
      </div>
    );
  }

  private _getSelectedIds() {
    let deleteList: any = [];
    let SelectedItem = this._Selection.getSelection() as ContractListItem[];
    if (SelectedItem) {
      deleteList = SelectedItem.map((item: any) => {
        return item ? item.id : null;
      });
    }
    return deleteList;
  }

  private _getselectedSequenceCode() {
    let selectedList: any = [];
    let selectedItem = this._Selection.getSelection() as ContractListItem[];
    if (selectedItem) {
      selectedList = selectedItem.map((item: any) => {
        return item ? item.sequenceId : null;
      });
    }
    return selectedList;
  }

  private _getselectedType() {
    let selectedType: any = [];
    let selectedItem = this._Selection.getSelection() as ContractListItem[];
    if (selectedItem) {
      selectedType = selectedItem.map((item: any) => {
        return item ? item.type : null;
      });
    }
    if (selectedType[0] !== undefined) {
      this.props.saveContractStateAttr('selectedLotType', selectedType[0]);
    }

    return selectedType[0];
  }

  private _getSelectedListItems = () => {
    let SelectedListItems = this._Selection.getSelection() as ContractListItem[];

    return SelectedListItems;
  };

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn,
  ): void => {
    const newColumns: IColumn[] = this._columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key,
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        let filter = this.state.filter;

        let fieldName = currColumn.fieldName;

        let sortby = currColumn.isSortedDescending ? 'desc' : 'asc';
        filter.sorter.attribute = fieldName ? fieldName : null;
        filter.sorter.order = sortby ? sortby : null;
        this.setState(
          {
            filter: filter,
          },
          this.handleFilterSortChange,
        );
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    // toleranceStatesFilter:state.projectBreakdown.toleranceStatesFilter,
    // productStatesFilter: state.projectBreakdown.productStatesFilter,
    // itemTypesFilter: state.projectBreakdown.itemTypesFilter,
    isChange: state.contract.isChange,
    contractDropDowns: state.contract.contractDropDowns,
  };
};

const mapDispatchToProps = {
  readContractDropDownData,
  saveContractStateAttr,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ListPane)),
);

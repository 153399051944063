import ConfirmationDialog from '../../../shared/confirmationDialog/confirmationDialog';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';
import {
  deleteTruckFromDay, loadBottomPumpWithProduct,
  loadBottomTruckWithProduct, loadProductList,
  saveThAutomationStateAttr,
} from '../../../reducers/thAutomationReducer';
import { formatDateTimeToISOString } from '../../../shared/util';
import { useDispatch, useStore } from 'react-redux';

const ThConfirmation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useStore();
  const [title, setTitle] = useState(t('areyousure'));
  const [subText, setSubText] = useState(t('areYouSureDeleteIt'));
  const [cancelButtonText, setCancelButtonText] = useState(t('notRemove'));
  const [confirmButtonText, setConfirmButtonText] = useState(t('remove'));
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);


  useEffect(() => {

    return () => {
      onRemoveCancel();
    };
  }, []);

  useEffect(() => {
    if (store.getState()?.thAutomation?.dataToDelete) {
      setData(store.getState()?.thAutomation?.dataToDelete);
    }
  }, [store.getState()?.thAutomation?.dataToDelete]);

  const onRemoveConfirm = () => {
    if (data && store.getState()?.thAutomation?.isConfirmDeleteModalOpen) {
      handleTruckDelete(data);
    }
  };

  const onRemoveCancel = () => {
    setIsLoading(false)
    dispatch(saveThAutomationStateAttr('isConfirmDeleteModalOpen', false));
    dispatch(saveThAutomationStateAttr('dataToDelete', null));
  };


  const handleTruckDelete = (resource: any) => {
    let data = {
      id: resource?.cpcId,   //cpcId
      date: formatDateTimeToISOString(store.getState()?.thAutomation?.selectedDate),
    };
    let date = formatDateTimeToISOString(store.getState()?.thAutomation?.selectedDate);
    setIsLoading(true)
    Promise.all([
      dispatch(deleteTruckFromDay(data)),
    ]).then(() => {
      onRemoveCancel();
      if (resource?.isTruck) {
        dispatch(loadBottomTruckWithProduct({ date: date }));
      } else {
        dispatch(loadBottomPumpWithProduct({ date: date }));
      }
      dispatch(loadProductList({ date: date }));

    });
  };


  return <>
    <ConfirmationDialog
      hidden={!store.getState()?.thAutomation?.isConfirmDeleteModalOpen}
      title={title}
      subText={subText}
      onClickConfirmDialog={onRemoveConfirm}
      onClickCancelDialog={onRemoveCancel}
      cancelButtonText={cancelButtonText}
      confirmButtonText={confirmButtonText}
    />
  </>;
};

export default ThConfirmation;

import React, { useEffect, useState } from 'react'
import { ProjectMolecule, ExtraWork as ExtraWorkTypes, ProjectMoleculeValidationMessage, } from '../../../../types/projectMolecule';
import ExtraWork from '../../extraWork/extraWork';

const ExtraWorkComponent = (props: {
  dropDownOptions: any;
  isEdit: boolean;
  formData: ProjectMolecule;
  extraWork: ExtraWorkTypes;
  saveFormData: () => void;
  handleFormChange: (projectMolecule: ProjectMolecule, save: boolean) => void;
  validationMessages: ProjectMoleculeValidationMessage;
  readOnly: boolean;
  readExtraWork: boolean;
  handleExtraChange: (extra: any[]) => void;
}) => {
  const [extraWorkDetails, setExtraWorkDetails] = useState<any>([]);
  useEffect(() => {
    setExtraWorkDetails(props.extraWork)
  }, [props.extraWork])

  return (
    <ExtraWork
      dropDownOptions={props.dropDownOptions}
      isEdit={props.isEdit}
      formData={props.formData}
      extraWork={extraWorkDetails}
      saveFormData={() => props.saveFormData()}
      handleFormChange={(projectMolecule: ProjectMolecule, save: boolean) =>
        props.handleFormChange(projectMolecule, save)
      }
      validationMessages={props.validationMessages}
      readOnly={props.readOnly}
      readExtraWork={props.readExtraWork}
      renameSuccess={() => props.saveFormData()}
      handleExtraChange={(item: any[]) => {
        props.handleExtraChange(item);
      }}
    />
  )
}

export default ExtraWorkComponent
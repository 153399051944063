import React, { FormEvent } from 'react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  ChoiceGroup,
  ContextualMenu,
  DetailsListLayoutMode,
  DetailsRow,
  FontWeights,
  getTheme,
  IChoiceGroupOption,
  IColumn,
  IconButton,
  IDetailsListProps,
  IDetailsRowStyles,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  Link,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  Text,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../../../theme';
import { connect } from 'react-redux';
import {
  DeliveryPlanListPaneItem,
  WF_IN_APPROVED_STATUS_ID,
  WF_IN_HANDED_OVER_STATUS_ID,
  WF_IN_REVIEW_STATUS_ID,
} from '../../../../types/purchaseOrder';
import { format24hDateTime, formatDate } from '../../../../shared/util';
import CustomDatePicker from '../../../../shared/customDatePicker/customDatePicker';
import DefaultNumberFormat from '../../../../shared/fluentFinalForm/defaultNumberFormat';

const theme = getTheme();

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const classNames = mergeStyleSets({
  wrapper: {
    height: '80vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: {
    maxWidth: 400,
  },
  textContent: {
    padding: '15px 10px',
  },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: {
    width: '100%',
  },
});

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};


const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    paddingTop: 20,
    overflowY: 'hidden',
    minWidth: '80vw',
    Height: '81vh',
    //minHeight: screen.width > 1280?'42vh':'60vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'none',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };


const ListPaneComponent = (props: {
  isOpen: boolean;
  openModal: () => void;
  resetSelection: () => void;
  onConfirmSelection: () => void;
  loadMsg: string;
  columns: IColumn[];
  listPaneItem: DeliveryPlanListPaneItem[];
  isDataLoaded?: boolean;
  IsDisabled?: boolean;
  IsDisabledUnitPrice?: boolean;
  IsDisabledExpectedDelivery?: boolean;
  selection: any;
  _onChangeTest: any;
  isCu: boolean;
  requestedDate: any;
}) => {

  const { t } = useTranslation();
  const options: IChoiceGroupOption[] = [
    { key: '0', text: t('stock') },
    { key: '1', text: t('po'), styles: { field: { marginLeft: '15px' } } },
  ];
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(true);
  const titleId = useId('title');
  const theme = getTheme();

  const _onChange = (ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined): void => {
    //alert(JSON.stringify(option));
    if (option) {
      if (option.key === '0') {
        // setcontractIsRequired(true);
        //alert(option.id);
      } else {
        // setcontractIsRequired(false);
        //alert(option.id);
      }
    } else {
      // props.projectBreakdown.scope = null;
      // setcontractIsRequired(false);
    }
  };
  const _onChangeTest = (type: any, index?: number, rindex?: number) => {
    let listPaneItem = props.listPaneItem.slice();
    if (index || index === 0) {
      let borResources = listPaneItem[index]?.resources;

      if (rindex || rindex === 0) {
        let borResourceItem = listPaneItem[index]?.resources[rindex];
        if (type === '1') {
          borResourceItem.isPo = false;
          borResourceItem.isStock = true;
          borResourceItem.expectedDeliveryDate = borResourceItem.expectedDeliveryDate ? borResourceItem.expectedDeliveryDate : props.requestedDate;
        } else if (type === '2') {
          borResourceItem.isPo = true;
          borResourceItem.isStock = false;
          borResourceItem.expectedDeliveryDate = null;
        }
      }
      props._onChangeTest(listPaneItem);
    } else {
      // alert(JSON.stringify(index))
    }
  };

  const _onChangeRequestedDate = (date: any, index?: number, rindex?: number) => {
    let listPaneItem = props.listPaneItem.slice();
    if (index || index === 0) {
      let borResources = listPaneItem[index]?.resources;

      if (rindex || rindex === 0) {
        let borResourceItem = listPaneItem[index]?.resources[rindex];
        borResourceItem.requestedDeliveryDate = date;
      }
      props._onChangeTest(listPaneItem);
    } else {
      // alert(JSON.stringify(index))
    }
  };

  const _onChangeExpectedDate = (date: any, index?: number, rindex?: number) => {
    let listPaneItem = props.listPaneItem.slice();
    if (index || index === 0) {
      let borResources = listPaneItem[index]?.resources;

      if (rindex || rindex === 0) {
        let borResourceItem = listPaneItem[index]?.resources[rindex];
        borResourceItem.expectedDeliveryDate = date;
      }
      props._onChangeTest(listPaneItem);
    } else {
      // alert(JSON.stringify(index))
    }
  };


  const _onChangeUnitPrice = (value: any, index?: number, rindex?: number) => {
    let listPaneItem = props.listPaneItem.slice();
    if (index || index === 0) {
      let borResources = listPaneItem[index]?.resources;

      if (rindex || rindex === 0) {
        let borResourceItem = listPaneItem[index]?.resources[rindex];
        replaceSameCpcUnitPrice(borResourceItem, value);
      } else {
        props._onChangeTest(listPaneItem);
      }
    } else {
      //alert(JSON.stringify(index))
    }
  };

  const replaceSameCpcUnitPrice = (selectedResource: any, value: any) => {
    let listPaneItem = props.listPaneItem.slice();
    let updatePriceList = listPaneItem.map((bor: any) => {
      bor?.resources?.map((resource: any) => {
        if (resource?.corporateProductCatalogId === selectedResource?.corporateProductCatalogId) {
          let qty: string = resource.cQuantity ? resource.cQuantity.toString() : '0';
          let cTotalPrice = (parseFloat(qty) * value);
          resource.cUnitPrice = value;
          resource.cTotalPrice = cTotalPrice;
        }
        return resource;
      });
      return bor;
    });
    console.log('updatePriceList', updatePriceList);
    props._onChangeTest(updatePriceList);
  };

  const onModalClose = () => {
    props.openModal();
  };
  const _onRenderRow: IDetailsListProps['onRenderRow'] = (row) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (row) {
      return (
        <div
          style={{ textDecoration: 'none' }}
          onClick={() => {
            // props.handleListPaneItemClick(row.item.itemId);
          }}
        >
          <DetailsRow {...row} styles={customStyles}/>
        </div>
      );
    }
    return null;
  };


  const displayMessage = () => {
    return (
      <div style={{ paddingTop: 36, paddingLeft: 20 }}>
        <Text>{t(props.loadMsg)}</Text>
      </div>
    );
  };

  const onRenderCheckbox = (props: any) => {
    return (
      <div style={{ pointerEvents: 'none' }}>
        <Checkbox checked={props.checked} checkmarkIconProps={{ iconName: 'Add' }}/>
      </div>
    );
  };

  const PODeliveryPlanResourceColumn = (
    item: any,
    index?: number,
    column?: IColumn,
    _onChange?: (ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined,
                 option?: IChoiceGroupOption | undefined) => void,
    _onChangeTest?: any,
    IsDisabled?: boolean,
    IsDisabledUnitPrice?: boolean,
    IsDisabledExpectedDelivery?: boolean,
    _onChangeRequestedDate?: any,
    _onChangeExpectedDate?: any,
    _onChangeUnitPrice?: any,
    isCu?: boolean) => {
    if (column && column.fieldName && column.fieldName === 'borTitle') {
      return <Link onClick={(e) => {
        e.preventDefault();

      }}>{item[column.fieldName]}</Link>;
    } else if (column && column.fieldName && column.fieldName === 'action') {
      return <div style={{ padding: 5 }} onClick={(e) => {
        e.preventDefault();
      }}>{renderChoiceGroup(item, _onChangeTest, index, IsDisabled)}</div>;
    } else if (column && column.fieldName && column.fieldName === 'requestedDeliveryDate') {
      return <div style={{ padding: 5 }} onClick={(e) => {
        e.preventDefault();
      }}>{renderRequestedDeliveryDate(item, _onChangeRequestedDate, index, IsDisabled)}</div>;
    } else if (column && column.fieldName && column.fieldName === 'expectedDeliveryDate') {
      return <div style={{ padding: 5 }} onClick={(e) => {
        e.preventDefault();
      }}>{renderExpectedDeliveryDate(item, _onChangeExpectedDate, index, isCu, IsDisabledExpectedDelivery)}</div>;
    } else if (column && column.fieldName && column.fieldName === 'cUnitPrice') {
      return <div style={{ padding: 5 }} onClick={(e) => {
        e.preventDefault();
      }}>{renderUnitPrice(item, _onChangeUnitPrice, index, (IsDisabled || IsDisabledUnitPrice))}</div>;
    } else if (column && column.fieldName) {
      return <div onClick={(e) => {
        e.preventDefault();
      }}>{getPoDeliveryRowItem(item, column.fieldName)}</div>;
    } else {
      return <span style={{ paddingLeft: 9 }}>-</span>;
    }
  };

  const getPoDeliveryRowItem = (item: any, fieldName: string) => {
    let resourceTitle: any = [];
    if (fieldName === 'deliveryDate') {
      if (item && item['resources']) {
        const resourceTitleSpan = item['resources'].map((resourceItem: any, resourceIndex: number) =>
          <div className={'poDeliveryRowItem'} key={fieldName + 'div' + useId()}>
            <span
              key={fieldName + 'span' + useId()}>{resourceItem[fieldName] ? format24hDateTime(resourceItem[fieldName]) : '-'}</span>
          </div>,
        );
        return resourceTitleSpan;
      }
    } else {
      if (item && item['resources']) {
        const resourceTitleSpan = item['resources'].map((resourceItem: any, resourceIndex: number) =>
          <div className={'poDeliveryRowItem'} key={fieldName + 'div' + useId()}>
            <span key={fieldName + 'span' + useId()}>{resourceItem[fieldName] ? resourceItem[fieldName] : '-'}</span>
          </div>,
        );
        return resourceTitleSpan;
      }
    }
    return '-';
  };


  const renderChoiceGroup = (item: any, _onChangeTest?: any, index?: number, IsDisabled?: boolean) => {
    let resourceTitle: any = [];
    let obj = item['resources'].find((item: any) => item.action === null);
    if (item && item['resources']) {
      const resourceTitleSpan = item['resources'].map((resourceItem: any, rindex: number) =>
        <div className={'PoChoiceGroup'} style={{ display: 'flex' }} key={'ChoiceGroup' + useId()}>
          {/*<Toggle  onText="Stock" offText="Stock" checked={resourceItem.isStock} onChange={()=>{_onChangeTest(1,index!!, rindex!!)}} />*/}
          {/*<Toggle  onText="PO" offText="PO" checked={resourceItem.isPo} onChange={()=>{_onChangeTest(2,index!!, rindex!!)}} />*/}
          <ChoiceGroup disabled={obj ? IsDisabled ? IsDisabled : false : true} key={'ChoiceGroupBtn' + useId()}
                       onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
                         _onChangeTest(option?.key, index!!, rindex!!);
                       }} styles={{ root: { marginTop: 0 } }} className={'PoChoiceGroup'}
                       selectedKey={resourceItem.isStock ? '1' : resourceItem.isPo ? '2' : undefined} options={[
            { key: '1', text: t('stock') },
            { key: '2', text: t('po'), styles: { field: { marginLeft: '15px' } } },
          ]}/>

          {/*<Label style={{marginRight:10}}  onClick={()=>{_onChangeTest(1,index!!, rindex!!)}} htmlFor={resourceItem.id}><input onClick={()=>{_onChangeTest(1,index!!, rindex!!)}}  type="radio" id={resourceItem.id} checked={resourceItem.isStock} name={resourceItem.id} value="1"/>*/}
          {/*Stock</Label>*/}
          {/*<Label onClick={()=>{_onChangeTest(2,index!!, rindex!!)}} htmlFor={resourceItem.id}><input onClick={()=>{_onChangeTest(2,index!!, rindex!!)}}  checked={resourceItem.isPo} type="radio" id={resourceItem.id} name={resourceItem.id} value="2"/>*/}
          {/*PO</Label>*/}

        </div>,
      );
      return resourceTitleSpan;
    }
    return '-';
  };

  const checkIsActionNull = async () => {
    let isNull: boolean = false;
    await props.listPaneItem.map(async (resourceItem: any, rindex: number) => {
      if (resourceItem.resources) {
        await resourceItem.resources.map((resourceItem: any, rindex: number) => {


          if (!resourceItem.isStock && !resourceItem.isPo) {
            isNull = true;
          }
        });
      }
    });
    return isNull;
  };

  const renderRequestedDeliveryDate = (item: any, _onChangeTest?: any, index?: number, IsDisabled?: boolean) => {
    let resourceTitle: any = [];
    if (item && item['resources']) {
      const resourceDateSpan = item['resources'].map((resourceItem: any, rindex: number) =>
        <div className={'PoDatePicker'} style={{ display: 'flex' }} key={'RequestedDatePicker' + useId()}>
          <CustomDatePicker
            setMinDate={null}
            setMaxDate={null}
            disabled={true}
            setValue={formatDate(resourceItem.requestedDeliveryDate, 1)}
            getValue={(date: Date) => {
              // onChangeEndDateTime(formatDate(date, 2));
              if (date) {
                _onChangeTest(formatDate(date, 2), index!!, rindex!!);
              } else {
                _onChangeTest(null, index!!, rindex!!);
              }

            }}
          />
        </div>,
      );
      return resourceDateSpan;
    }
    return '-';
  };

  const renderExpectedDeliveryDate = (item: any, _onChangeExpectedDate?: any, index?: number, isCu?: boolean, IsDisabled?: boolean) => {
    let resourceTitle: any = [];
    if (item && item['resources']) {
      const resourceDateSpan = item['resources'].map((resourceItem: any, rindex: number) =>
        <div className={'PoDatePicker'} style={{ display: 'block' }} key={'ExpectedDatePicker' + useId()}>
          <CustomDatePicker
            setMinDate={null}
            setMaxDate={null}
            disabled={disabledExpectedDeliveryDate(resourceItem, isCu, IsDisabled)}
            setValue={formatDate(resourceItem.expectedDeliveryDate, 1)}
            getValue={(date: Date) => {
              // onChangeEndDateTime(formatDate(date, 2));
              if (date) {
                _onChangeExpectedDate(formatDate(date, 2), index!!, rindex!!);
              } else {
                _onChangeExpectedDate(null, index!!, rindex!!);
              }

            }}
          />
        </div>,
      );
      return resourceDateSpan;
    }
    return '-';
  };
  const disabledExpectedDeliveryDate = (resourceItem: any, isCu?: boolean, IsDisabled?: boolean): boolean => {
    if (resourceItem.wfStatus === WF_IN_REVIEW_STATUS_ID) {
      return true;
    } else if (resourceItem.wfStatus === WF_IN_APPROVED_STATUS_ID) {
      return true;
    } else if (resourceItem.wfStatus === WF_IN_HANDED_OVER_STATUS_ID) {
      return true;
    } else if (!isCu && resourceItem.isPo) {
      return true;
    } else {
      return IsDisabled ? true : false;
    }
  };
  const renderUnitPrice = (item: any, _onChangeUnitPrice?: any, index?: number, IsDisabled?: boolean) => {
    let resourceTitle: any = [];
    if (item && item['resources']) {
      const resourceDateSpan = item['resources'].map((resourceItem: any, rindex: number) =>
        <div className={'PoUnitPrice'} style={{ display: 'flex' }} key={'unitPrice' + useId()}>
          <DefaultNumberFormat
            disabled={IsDisabled}
            value={resourceItem?.cUnitPrice ? resourceItem?.cUnitPrice : 0.00}
            onChange={(newValue?: any) => {
              if (newValue) {
                _onChangeUnitPrice(newValue, index!!, rindex!!);
              } else {
                _onChangeUnitPrice(null, index!!, rindex!!);
              }
            }}
          />
        </div>,
      );
      return resourceDateSpan;
    }
    return '-';
  };


  const onConfirmSelection = () => {
    if (checkIsChecked()) {
      props.onConfirmSelection();
    } else {

    }

  };
  const onCuConfirmSelection = () => {
    props.onConfirmSelection();
  };

  const checkIsChecked = () => {
    let isChecked: boolean = true;
    props.listPaneItem.map((resourceItem: any, rindex: number) => {
      if (resourceItem.resources) {
        resourceItem.resources.map((resourceItem: any, rindex: number) => {
          if (!resourceItem.isStock && !resourceItem.isPo) {
            isChecked = false;
          }
        });
      }
    });
    return isChecked;
  };

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          onModalClose();
        }}
        scrollableContentClassName="body"
        isBlocking={false}
        dragOptions={isDraggable ? dragOptions : undefined}
        className={'grid-righter ms-Grid'}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>{t('deliveryPlan')}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              onModalClose();
            }}
          />
        </div>
        <div className={contentStyles.subHeader}>

        </div>

        <div className={contentStyles.body}>
          <div className={'proj-detail-content inner'}>
            <div className={`wrapper-holder ${classNames.wrapper}`}>
              <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <ShimmeredDetailsList
                  items={props.listPaneItem ? props.listPaneItem : []}
                  columns={props.columns}
                  setKey="set"
                  enableShimmer={!props.isDataLoaded}
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionPreservedOnEmptyClick={true}
                  checkboxVisibility={2}
                  onRenderRow={_onRenderRow}
                  onRenderItemColumn={(
                    item: any,
                    index?: number,
                    column?: IColumn,
                  ) =>
                    PODeliveryPlanResourceColumn(
                      item,
                      index,
                      column,
                      _onChange,
                      _onChangeTest,
                      props.IsDisabled,
                      props.IsDisabledUnitPrice,
                      props.IsDisabledExpectedDelivery,
                      _onChangeRequestedDate,
                      _onChangeExpectedDate,
                      _onChangeUnitPrice,
                      props.isCu,
                    )
                  }
                  selection={props.selection}
                  selectionMode={SelectionMode.none}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                />
                {props.isDataLoaded && displayMessage()}
              </ScrollablePane>
            </div>
          </div>
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            disabled={props.IsDisabled}
            text={t('reset')}
            style={{ marginTop: 20, marginBottom: 30, marginRight: 10 }}
            onClick={() => props.resetSelection()}
          />
          {props.isCu ? <PrimaryButton
            text={t('confirm')}
            disabled={false}
            style={{ marginTop: 20, marginBottom: 30 }}
            onClick={() => onCuConfirmSelection()}
          /> : <PrimaryButton
            text={t('confirm')}
            disabled={props.IsDisabled ? true : !checkIsChecked()}
            style={{ marginTop: 20, marginBottom: 30 }}
            onClick={() => onConfirmSelection()}
          />}
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    toleranceStatesFilter: state.billOfResource.toleranceStatesFilter,
    productStatesFilter: state.billOfResource.productStatesFilter,
    itemTypesFilter: state.billOfResource.itemTypesFilter,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ListPaneComponent);

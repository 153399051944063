import { Dropdown, IColumn, TextField } from '@fluentui/react';
import React from 'react';
import UprinceLogger from '../../shared/Logger/logger';
import CustomDatePicker from '../../shared/customDatePicker/customDatePicker';

export const _renderFilterItemColumn = (
  timeClockState: string | number | null,
  timeClockStates: [],
  onChangeState: (value: string | number | null) => void,
  employee: string | null,
  onChangeEmployee: (value: string | null) => void,
  startDateTime: string | Date | null,
  onChangeStartDateTime: (value: string | Date | null) => void,
  endDateTime: string | Date | null,
  onChangeEndDateTime: (value: string | Date | null) => void,
  item?: any,
  index?: number,
  column?: IColumn) => {
  UprinceLogger.log('column', column);
  switch (column?.key) {
    case 'column1': {
      return (
        <div>
          <CustomDatePicker
            setMinDate={null}
            setMaxDate={null}
            setValue={formatDate(startDateTime, 1)}
            getValue={(date: Date) => {
              onChangeStartDateTime(formatDate(date, 2));
              onChangeEndDateTime(formatDate(date, 2));
            }}
          />
        </div>
      );
      break;
    }
    case 'column2': {
      return (
        <div>
          <CustomDatePicker
            setMinDate={formatDate(startDateTime, 1)}
            setMaxDate={null}
            setValue={formatDate(endDateTime, 1)}
            getValue={(date: Date) => {
              onChangeEndDateTime(formatDate(date, 2));
              if (!startDateTime) {
                onChangeStartDateTime(formatDate(date, 2));
              }
            }}
          />
        </div>
      );
      break;
    }
    case 'column3': {
      return (
        <div>
          <TextField
            value={(employee) ? employee : ''}
            onChange={(event, value) => {
              onChangeEmployee(value ? value : null);
            }}
          />
        </div>
      );
      break;
    }
    case 'column4': {
      return (
        <div>
          <Dropdown
            placeholder=""
            defaultSelectedKey={'0'}
            options={timeClockStates}
            selectedKey={
              timeClockState ? timeClockState : '0'
            }
            onChange={(event, value) => {
              onChangeState(value ? value.key : null);
            }}
            styles={{}}
          />
        </div>
      );
      break;
    }
    default: {
      break;
    }
  }
};

const formatDate = (dateString: Date | string | null, type: number) => {
  if (type == 1) { //for set date
    if (dateString) {
      let date = new Date(dateString);
      return date;
    } else {
      return null;
    }

  } else { //get date
    UprinceLogger.log('formatDate', 'else');
    if (dateString) {
      let date = new Date(dateString);
      if (date) {
        let month = (date.getMonth() + 1 <= 9 ? '0' : '') + (date.getMonth() + 1);
        let day = (date.getDate() <= 9 ? '0' : '') + date.getDate();
        let formatDate = null;
        if (month !== 'NaN') {
          formatDate = date.getFullYear() + '-' + month + '-' + day + 'T00:00:00.000Z';
        }
        UprinceLogger.log('formatDate', formatDate);
        return formatDate;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

};

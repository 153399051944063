export class WarehouseListPaneFilter {
  title: string | null = null;
  type: string | null = null;
  status: string | null = null;
  warehouseTaxonomy: string | null = null;
  sorter: Sorter = new Sorter();
}

export interface WarehouseShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
  value: number | null
}

class Sorter {
  attribute: string | null = null;
  order: string | null = null;
}

export interface WarehouseShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
}

export class WarehouseListPaneItem {
  id: string | null = null;
  warehouseId: string | null = null;
  name: string | null = null;
  title: string | null = null;
  type: string | null = null;
  status: string | null = null;
  typeId: string | null = null;
  statusId: string | null = null;
  warehouseTaxonomy: string | null = null;
}

export interface WarehouseDropDowns {
  status: DropDown[];
  type: DropDown[];
  whTaxonomyLevel: any;
}

export interface DropDown {
  key: string;
  text: string;
}


export interface History {
  createdBy: string | null;
  createdDate: string | null;
  revisionNumber: number | string | null;
  modifiedBy: string | null;
  modifiedDate: string | null;
}

export class DropDown {
  key: string = '';
  value: string = '';
}

export class VehicleDropDown {
  label: string = '';
  value: string = '';
}


export interface Warehouse {
  id: string | null;
  sequenceId: string | null;
  name: string | null;
  title: string | null;
  headerTitle: string | null;
  statusId: string | null;
  status: DropDown;
  typeId: string | null;
  type: DropDown;
  vehicleNumber: VehicleDropDown;
  address: string | null;
  locationId: string | null;
  openingHoursFrom: string | null;
  openingHoursTo: string | null;
  managerId: string | null;
  managerName: string | null;
  area: string | null;
  history: History | null;
  mapLocation: any;
  isSaved: boolean;
  warehouseTaxonomyId: string | null;
  projectSequenceCode: string | null;
}


export const warehouseFormData = {
  id: null,
  sequenceId: null,
  name: null,
  title: null,
  headerTitle: null,
  statusId: null,
  status: new DropDown(),
  typeId: null,
  type: new DropDown(),
  vehicleNumber: new VehicleDropDown(),
  address: null,
  locationId: null,
  openingHoursFrom: null,
  openingHoursTo: null,
  managerId: null,
  managerName: null,
  area: null,
  history: null,
  isSaved: false,
  mapLocation: null,
  warehouseTaxonomyId: null,
  projectSequenceCode: null,
};

export const warehouseFilter = {
  id: null,
  invoiceId: null,
  invoiceStatus: null,
  invoiceStatusId: null,
  name: null,
  psId: null,
  purchaseOrderNumber: null,
  title: null,
  totalExcludingTax: null,
  totalIncludingTax: null,
  workPeriodFrom: null,
  workPeriodTo: null,
  sorter: {
    attribute: null,
    order: null,
  },
};

export interface warehouseValidationMessage {
  nameErrorMsg: string | null;
  typeErrorMsg: string | null;
  borErrorMsg: string | null;
  statusErrorMsg: string | null;
}

export interface Cost {
  id: string | null;
  psId: string | null;
  cpcId?: any;
  cpcResourceNumber: string;
  cpcTitle: string;
  consumedQuantity: number;
  costToMou?: any;
  totalCost: any;
  soldQuantity: number;
  spToMou?: any;
  consumedQuantityMou?: any;
  mouId?: any;
  mou?: any;
  status?: any;
  cpcResourceTypeId: string;
  cpcResourceType: string;
  projectCostId: string;
  resourceCategory: string;
  productTitle: string;
}


export const TYPE_MOBILE_ID = '4010e768-3e06-4702-b337-ee367a82addb';
export const SHORTCUTPANE_ALL = null;
export const SHORTCUTPANE_WAREHOUSE_ID = 3;

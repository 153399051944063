export const LOAD_MILESTONES_SHORTCUTPANE = 'uprince/LOAD_MILESTONES_SHORTCUTPANE';
export const LOAD_MILESTONES_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_MILESTONES_SHORTCUTPANE_SUCCESS';
export const LOAD_MILESTONES_SHORTCUTPANE_FAIL = 'uprince/LOAD_MILESTONES_SHORTCUTPANE_FAIL';

export const LOAD_MILESTONES_LSITPANE = 'uprince/LOAD_MILESTONES_LSITPANE';
export const LOAD_MILESTONES_LSITPANE_SUCCESS = 'uprince/LOAD_MILESTONES_LSITPANE_SUCCESS';
export const LOAD_MILESTONES_LSITPANE_FAIL = 'uprince/LOAD_MILESTONES_LSITPANE_FAIL';

export const LOAD_MILESTONES_DROPDOWN = 'uprince/LOAD_MILESTONES_DROPDOWN';
export const LOAD_MILESTONES_DROPDOWN_SUCCESS = 'uprince/LOAD_MILESTONES_DROPDOWN_SUCCESS';
export const LOAD_MILESTONES_DROPDOWN_FAIL = 'uprince/LOAD_MILESTONES_DROPDOWN_FAIL';

export const SAVE_MILESTONES = 'uprince/SAVE_MILESTONES';
export const SAVE_MILESTONES_SUCCESS = 'uprince/SAVE_MILESTONES_SUCCESS';
export const SAVE_MILESTONES_FAIL = 'uprince/SAVE_MILESTONES_FAIL';

export const LOAD_MILESTONES_BY_ID = 'uprince/LOAD_MILESTONES_BY_ID';
export const LOAD_MILESTONES_BY_ID_SUCCESS = 'uprince/LOAD_MILESTONES_BY_ID_SUCCESS';
export const LOAD_MILESTONES_BY_ID_FAIL = 'uprince/LOAD_MILESTONES_BY_ID_FAIL';

export const LOAD_BOR_RESOURCES_BY_ID = 'uprince/LOAD_BOR_RESOURCES_BY_ID';
export const LOAD_BOR_RESOURCES_BY_ID_SUCCESS = 'uprince/LOAD_BOR_RESOURCES_BY_ID_SUCCESS';
export const LOAD_BOR_RESOURCES_BY_ID_FAIL = 'uprince/LOAD_BOR_RESOURCES_BY_ID_FAIL';

export const SET_MILESTONES_UID = 'uprince/SET_MILESTONES_UID';
export const CLEAR_MILESTONES_UID = 'uprince/CLEAR_MILESTONES_UID';
export const CLEAR_MILESTONES_FORM_DATA = 'uprince/CLEAR_MILESTONES_FORM_DATA';
export const RESET_MILESTONES_IS_CHANGE = 'uprice/RESET_MILESTONES_IS_CHANGE';

export const CREATE_MACHINE_TAXONOMY_NODE = 'uprince/CREATE_MACHINE_TAXONOMY_NODE';
export const CREATE_MACHINE_TAXONOMY_NODE_SUCCESS = 'uprince/CREATE_MACHINE_TAXONOMY_NODE_SUCCESS';
export const CREATE_MACHINE_TAXONOMY_NODE_FAIL = 'uprice/CREATE_MACHINE_TAXONOMY_NODE_FAIL';

export const CLEAR_MILESTONESUID = 'uprice/CLEAR_MILESTONESUID';

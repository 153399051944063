import { DirectionalHint, Label, Link, Stack, Text, TooltipHost } from '@fluentui/react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { iconColors, uPrinceTheme } from '../../../../theme';
import { ShortCutPaneItem } from '../../../types/projectBreakdownStructure';
import { PMOLShortCutPaneItem } from '../../../types/projectMolecule';

const classNames = mergeStyleSets({
  wrapper: {
    // height: 'calc(100vh - 14vh)!important',
    position: 'relative',
  },
  selected: {
    fontWeight: 'bold',
    color: '#854114!important',
  },
});

const allFilterItem: PMOLShortCutPaneItem = {
  key: 'all',
  id: null,
  type: 'all',
  name: 'All',
  value: null,
};
export const ShortCutPaneComponent = (props: {
  toggleOverlay: () => void;
  toggleOverlayFwd: () => void;
  divClass: string;
  shortCutPaneFilters: PMOLShortCutPaneItem[];
  currentActiveSection: string | null;
  handelShortCutPaneFilter: (selectedItem: PMOLShortCutPaneItem) => void;
  resetFilter: () => void;
}) => {
  const { t } = useTranslation();

  const setFilterOption = (selectedItem: PMOLShortCutPaneItem) => {
    if (selectedItem.type === 'all') {
      props.resetFilter();
    }
    props.handelShortCutPaneFilter(selectedItem);
  };
  const renderFilterItems = () => {
    if (props.shortCutPaneFilters) {
      return props.shortCutPaneFilters.map((item: any, index) => {
        let divider = false;
        if (index > 0) {
          const previousItem: ShortCutPaneItem =
            props.shortCutPaneFilters[index - 1];
          if (previousItem.type != item.type) {
            divider = true;
          } else {
            divider = false;
          }
        } else {
          divider = true;
        }

        return (
          <div key={item.id + 'div'}>
            {divider && (
              <hr
                key={item.id + 'hr'}
                style={{ marginBottom: 15, borderTop: '1px solid #d2d2d2' }}
              />
            )}
            <Link
              key={item.id + 'link'}
              className={`${
                props.currentActiveSection === item.id ? classNames.selected : ''
              }`}
              href="#"
              onClick={() => {
                setFilterOption(item);
              }}
            >
              <TooltipHost
                key={item.id + 't'}
                content={item.name}
                id="filter-opt-1"
                calloutProps={{ gapSpace: 0 }}
                directionalHint={DirectionalHint.rightCenter}
              >
                <i
                  key={item.id + 'i'}
                  className="ms-Icon ms-Icon--GotoToday"
                  aria-labelledby="filter-opt-1"
                  aria-hidden="true"
                  style={props.currentActiveSection === item.id ? { color: iconColors.iconActiveColor } : { color: uPrinceTheme.palette.themePrimary }}
                ></i>
              </TooltipHost>
              <i
                key={item.id + 'i2'}
                className="filter-icon ms-Icon ms-Icon--GotoToday filter-icon"
                aria-hidden="true"
                style={props.currentActiveSection === item.id ? { color: iconColors.iconActiveColor } : { color: uPrinceTheme.palette.themePrimary }}
              ></i>
              <Text key={item.id}>{item.name} </Text>
            </Link>
          </div>
        );
      });
    }
  };

  return (
    <div className={` shortCutPaneWrapper ${props.divClass} ${classNames.wrapper}`}>
      <div className="inner-container">
        <i
          onClick={props.toggleOverlay}
          className="ms-Icon ms-Icon--Back back-arrow"
          aria-hidden="true"
        ></i>
        <i
          onClick={props.toggleOverlayFwd}
          className="ms-Icon ms-Icon--Forward fwd-arrow"
          aria-hidden="true"
        ></i>

        <div className="filter-data filter-title">
          <TooltipHost
            content={t('pojectMolecule')}
            // This is the important part!
            id="filter-opt-title"
            calloutProps={{ gapSpace: 0 }}
            directionalHint={DirectionalHint.rightCenter}
          >
            <Label
              aria-describedby={'filter-opt-title'}
              className={'short-cut-pane-label'}
            >
              {t('pojectMolecule')}
            </Label>
          </TooltipHost>
        </div>

        <div className="filter-option-list">
          <Stack
            gap={15}
            verticalFill
            styles={{
              root: {
                width: '100%',
                verticalAlign: 'center',
              },
            }}
          >
            <Link
              key={'All-link'}
              className={`${
                props.currentActiveSection === null ? classNames.selected : ''
              }`}
              href="#"
              onClick={() => {
                setFilterOption(allFilterItem);
              }}
            >
              <TooltipHost
                key={'All-link1'}
                content={t('all')}
                id="filter-opt-1"
                calloutProps={{ gapSpace: 0 }}
                directionalHint={DirectionalHint.rightCenter}
              >
                <i
                  key={'All-linki'}
                  className="ms-Icon ms-Icon--ViewAll"
                  aria-labelledby="filter-opt-1"
                  aria-hidden="true"
                  style={props.currentActiveSection === null ? { color: iconColors.iconActiveColor } : { color: uPrinceTheme.palette.themePrimary }}
                ></i>
              </TooltipHost>
              <i
                key={'All-linki2'}
                className="filter-icon ms-Icon ms-Icon--ViewAll filter-icon"
                aria-hidden="true"
                style={props.currentActiveSection === null ? { color: iconColors.iconActiveColor } : { color: uPrinceTheme.palette.themePrimary }}
              ></i>
              <Text key="all"> {t('all')}</Text>
            </Link>
            {renderFilterItems()}
          </Stack>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShortCutPaneComponent);

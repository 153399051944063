import * as React from 'react';
import { useEffect, useState } from 'react';
import { messageService } from '../../../../services/messageService';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, DropdownProps, FluentProvider, Option, useId } from '@fluentui/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { copyWbsToProject } from '../../../../reducers/wbsReducer';
import { Save24Regular } from '@fluentui/react-icons';
import { getWbsTaxonomy } from '../../../../reducers/wbsTaskReducer';
import { getProjectDefinition } from '../../../../reducers/projectReducer';
import { getProject } from '../../../../shared/util';
import { uPrinceFluentLightTheme } from '../../../../../fluentTheme';
import { cancelIconButtonStyles, contentStyles } from '../../../wbsCreate/documentPane/WbsCreateDocument';
import { IconButton } from '@fluentui/react';
import { Modal } from 'office-ui-fabric-react';

const GenerateTemplateModal = (props: Partial<DropdownProps>) => {
  const { t } = useTranslation();
  const dropdownId = useId('dropdown-template-selection');
  const dispatch = useDispatch();
  const project = getProject();

  const wbsListData = useSelector((state: any) => state.wbsCreate.wbsListData);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [dropdownSelection, setDropdownSelection] = useState<any>(null);

  useEffect(() => {

    const subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.generateNew) {
          setIsModalOpen((prevState: boolean) => !prevState);
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const handleClose = () => {
    setIsModalOpen((prevState: boolean) => !prevState);
    setDropdownSelection(null);
  };

  const handleSave = () => {
    // console.log(dropdownSelection);
    if (dropdownSelection) {
      Promise.all([dispatch(copyWbsToProject(dropdownSelection.id))]).then((res: any) => {
        // console.log(res);
        if (res[0]?.payload?.data?.result) {
          handleClose();
          dispatch(getWbsTaxonomy());
          dispatch(getProjectDefinition(project));
        }
      });
    }
  };

  return (
    <Modal
      titleAriaId={'titleId'}
      isOpen={isModalOpen}
      onDismiss={() => handleClose()}
      isBlocking={true}
      dragOptions={undefined}
    >
      <FluentProvider theme={uPrinceFluentLightTheme}>
        <div className={contentStyles.header}>
          <span id={'titleId'}>{t('selectWbsTaxonomy')} </span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel='Close popup modal'
            onClick={() => handleClose()}
          />
        </div>

        <div className={contentStyles.body}>
          <div>
            <div className='ms-Grid-row'>
              <div className='ms-Grid-col ms-sm12 ms-md12 ms-lg12'>
                <Dropdown
                  aria-labelledby={dropdownId}
                  placeholder={t('selectWbsTaxonomy')}
                  appearance='outline'
                  onOptionSelect={(e, data) => setDropdownSelection(data.optionValue)}
                  positioning={{ position: 'below' }}
                  {...props}
                >
                  {wbsListData?.map((option: any) => (
                    <Option key={option.id} value={option}>
                      {option.title}
                    </Option>
                  ))}
                </Dropdown>
              </div>
            </div>
          </div>
        </div>

        <div className={contentStyles.footer} style={{ marginTop: 10 }}>
          <Button onClick={() => handleSave()} appearance='primary' icon={<Save24Regular />} shape='square'>
            {t('save')}
          </Button>
        </div>
      </FluentProvider>
    </Modal>
  );
};

export default GenerateTemplateModal;
import {
  ActionButton,
  ContextualMenu,
  FontWeights,
  getTheme,
  IconButton,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Modal,
  Separator,
  Stack,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../../../theme';
import React, { useEffect, useState } from 'react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { PickListItem } from '../../../../types/pickListItem';
import { useTranslation } from 'react-i18next';
import { CompetenceTaxonomy, CompetencyDropDowns } from '../../../../types/competency';
import TaxonomyField from '../../competenciesTaxonomy/taxonomy';
import { Field, Form } from 'react-final-form';
import { DropDownAdaptater } from '../../../../shared/fluentFinalForm';
import { formatDateTimeToISOString, getProject } from '../../../../shared/util';
import DatePickerRequiredAdapter from '../../../../shared/fluentFinalForm/DatePickerRequiredAdapter';

const theme = getTheme();

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };
const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
});

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '24px 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '50vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 0,
};

const stackItemStyles: IStackItemStyles = {
  root: {
    padding: 0,
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackStyles: IStackStyles = { root: { padding: 0 } };

export type CompetenceModalDTO = {
  date: string | null;
  competenciesTaxonomyId: string | null;
  id: string | null;
  personId: string | undefined;
  experienceLevelId: any;
};

const CompetenceModal = (props: {
  isOpen: boolean;
  isEdit: boolean;
  openCompetenceModal: () => void;
  options?: PickListItem[];
  allOptions?: PickListItem[];
  onAddCompetency?: (contact: any, saveAndNew: boolean) => void;
  title: string;
  valueLable: string;
  organisationSelectionDetails: CompetenceModalDTO[];
  isChecking: boolean;
  isUniq: number;
  isQniqContact: any;
  dropDowns: CompetencyDropDowns;
  competencyTaxonomyList: CompetenceTaxonomy[];
  competenciesTaxonomyId: string | null;
  setCompetenciesTaxonomyId: (competenciesTaxonomyId: string | null) => void;
  createCabCompetencies: (competence: any) => void;
}) => {
  const { t } = useTranslation();
  const [isDraggable] = useBoolean(false);
  const titleId = useId('title');
  // const [isValid, setIsValid] = useState<boolean>(false);
  const [competence, setCompetence] = useState<CompetenceModalDTO>({
    date: '',
    competenciesTaxonomyId: '',
    id: '',
    personId: '',
    experienceLevelId: '',
  });

  const getInitialFormValues = (formData: any) => {
    if (formData && formData?.id) {
      return {
        date: formData.date ? new Date(formData.date) : null,
        competenciesTaxonomyId: formData.competenciesTaxonomyId,
        id: formData.id,
        personId: formData.personId,
        experienceLevelId: formData.experienceLevelId,
      };
    }
    return null;
  };

  const onSubmit = async (values: any) => {
    values.projectSequenceCode = getProject();
    props.createCabCompetencies(values);
    props.openCompetenceModal();
    // setIsValid(false);
    // if (!values.id) {
    //     values.id = props.uid;
    // }
    //props.saveFormData(values);
  };


  useEffect(() => {
    setCompetence(props.organisationSelectionDetails[0]);
  }, [props.organisationSelectionDetails]);

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          props.openCompetenceModal();
        }}
        isBlocking={true}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>
            {props.isEdit
              ? t('edit') + ' ' + props.title
              : t('add') + ' ' + props.title}
          </span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              props.openCompetenceModal();
              if (!props.organisationSelectionDetails[0]) {

              }
            }}
          />
        </div>

        <div className={contentStyles.body}>
          <div className="proj-detail-block">
            <Form
              onSubmit={onSubmit}
              initialValues={getInitialFormValues(competence)}
              validate={values => {
                const errors: any = {};
                if (!values?.date) {
                  errors.date = t('dateRequired');
                }
                if (!values?.experienceLevelId) {
                  errors.experienceLevelId = t('experienceLevelRequired');
                }
                if (!values?.competenciesTaxonomyId) {
                  errors.competenciesTaxonomyId = t('skillTypeCannotBeEmpty');
                }
                return errors;
              }}
              render={({
                         handleSubmit,
                         form,
                         submitting,
                         pristine,
                         errors,
                         values,
                       }) => {
                return (
                  <form onSubmit={handleSubmit} noValidate>
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <div className={`${classNames.halfWidth}`}>
                        <Field
                          name="date"
                          component={DatePickerRequiredAdapter}
                          lable={t('date')}
                          parse={(value) =>
                            value && formatDateTimeToISOString(value)
                          }
                          format={(value) => (value ? new Date(value) : null)}
                          errorMessage={
                            form.getState()?.errors?.date
                          }
                        />
                      </div>
                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={{
                        childrenGap: 5,
                        padding: 0,
                      }}
                    >
                      <div className={`${classNames.fullWidth}`} style={{ zIndex: '10000000' }}>
                        <Field name="competenciesTaxonomyId" errorMessage={
                          form.getState()?.errors?.competenciesTaxonomyId
                        }>
                          {({ input, meta }) => (
                            <div>
                              <TaxonomyField
                                label={t('skillType')}
                                treeData={props.competencyTaxonomyList}
                                selectItemId={
                                  values && values.competenciesTaxonomyId
                                    ? values.competenciesTaxonomyId
                                    : null
                                }
                                onSelectItem={(id: string) => {
                                  if (id) {
                                    if (props.organisationSelectionDetails[0] && id === props.organisationSelectionDetails[0].competenciesTaxonomyId) {
                                      input.onChange(null);
                                      setCompetence({
                                        ...competence,
                                        competenciesTaxonomyId: null,
                                      });
                                    } else {
                                      input.onChange(id);
                                      setCompetence({
                                        ...competence,
                                        competenciesTaxonomyId: id,
                                      });
                                    }
                                  } else {
                                    input.onChange(null);
                                    setCompetence({
                                      ...competence,
                                      competenciesTaxonomyId: null,
                                    });
                                  }
                                }}
                                treeLevel={
                                  props.dropDowns.competenciesTaxonomyLevel
                                }
                                onFilterTaxonomyNodeChanged={() => {
                                }}
                                // disabled={ true }
                              />
                              {((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched &&
                              <Label style={{ marginTop: '-18px', color: '#a4262c', fontSize: '12px' }}>
                                {t('skillTypeCannotBeEmpty')}
                              </Label>}
                            </div>
                          )}
                        </Field>
                      </div>
                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <div className={`${classNames.halfWidth}`}>
                        <Field
                          name="experienceLevelId"
                          component={DropDownAdaptater}
                          options={props.dropDowns.experience}
                          lable={t('experinceLevel')}
                          placeholder="Level"
                          required={true}
                          errorMessage={
                            form.getState()?.errors?.experienceLevelId
                          }
                        />
                      </div>
                    </Stack>

                    <div className={'card-footer'}>
                      <Separator/>
                      <Stack
                        styles={stackStyles}
                        tokens={itemAlignmentsStackTokens}
                      >
                        <Stack.Item
                          align="end"
                          styles={stackFooterBarItemStyles}
                        >
                          <ActionButton
                            className={classNames.actionButton}
                            iconProps={saveIcon}
                            allowDisabledFocus
                            onClick={() => {
                              form.submit();
                              errors == null ? form.reset() : undefined;

                            }}
                          >
                            <Label className={classNames.actionButton}>
                              {t('save')}
                            </Label>
                          </ActionButton>
                        </Stack.Item>
                      </Stack>
                    </div>
                    {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                  </form>
                );
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CompetenceModal;

import {
  Checkbox,
  Dropdown,
  FontWeights,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  PrimaryButton,
  Stack,
  Text,
} from '@fluentui/react';
import React, { useEffect } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { uPrinceTheme } from '../../../../../theme';
import client from '../../../../api';
import {
  CKEditorWrapper,
  DropDownAdaptater,
  TextFieldFF,
} from '../../../../shared/fluentFinalForm';
import CheckBoxWrapper from '../../../../shared/fluentFinalForm/CheckBoxFF';
import PeoplePicker from '../../../../shared/fluentFinalForm/PeoplePicker';
import { useDispatch } from 'react-redux';
import { dispatch } from 'rxjs/internal/observable/pairs';
import { getUserRoles } from '../../../../reducers/cuProjectReducer';

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '40vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const addWhiteIconButtonStyles = {
  root: { color: uPrinceTheme.palette.white },
  rootHovered: { color: theme.palette.neutralDark },
};
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};

const Condition = ({ when, is, children }: any) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

const TeamsModalContent = (props: {
  teamMember: any;
  disabled: boolean;
  onChange: any;
  dataList: any[];
}) => {
  const { t } = useTranslation();
  const { projectScopeStatus, userRoles }: any = useSelector(
    (state: any) => state.project
  );

  const stackItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 50,
      width: 0,
      flexGrow: 1,
    },
  };

  const stackItemCheckStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 50,
      width: 0,
      flexGrow: 1,
      marginLeft:10,
      marginTop:20,
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
  };

  const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
    fullWidth: { width: '100%' },
    halfWidth: { width: '49%' },
  });

  const onSubmit = async (values: any) => {
    props.onChange(values);
  };

  const getInitialvalues = (teamMember: any) => {
    if (teamMember) {
      return teamMember;
    }
    return {
      id: null,
      cabPersonId: null,
      roleId: null,
      status: '1',
      message: null,
      email: null,
      companyName: null,
      isAccessGranted: false,
    };
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if(userRoles==null || userRoles==undefined || userRoles.length==0){
      dispatch(getUserRoles({}));
    }
  },[])

  return (
    <div className={contentStyles.body}>
      <div className="proj-detail-content inner">
        <div className="ms-Grid-row">
          <Form
            onSubmit={onSubmit}
            initialValues={getInitialvalues(props.teamMember)}
            mutators={{
              setCabPersonName: (args, state, utils) => {
                const field = state.fields.cabPersonName;
                field.change(args[0]);
              },
              setTempEmail: (args, state, utils) => {
                const fieldTempemail = state.fields.tempEmail;
                fieldTempemail.change(args[0]);
              },
              setBussinessEmail: async (args, state, utils) => {
                const field = state.fields.email;
                field.change(args[0]);
              },
              setRoleName: (args, state, utils) => {
                const field = state.fields.roleName;
                field.change(args[0]);
              },
              setCabCompany: (args, state, utils) => {
                const field = state.fields.companyName;
                field.change(args[0]);
              },
            }}
            validate={(values) => {
              const errors: any = {};
              if (!values?.cabPersonId) {
                errors.cabPersonId = t('personRequired');
              }

              if (!values?.roleId) {
                errors.roleId = t('roleRequired');
              }

              if (Array.isArray(props.dataList) && !values.id) {
                const index = props.dataList.findIndex((object) => {
                  return object.cabPersonId === values.cabPersonId;
                });

                if (index !== -1) {
                  errors.cabPersonId = t('alreadyAdded');
                }
              }
              return errors;
            }}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              errors,
            }) => (
              <div>
                <FormSpy
                  onChange={(spyProps: any) => {
                    if (
                      props.teamMember?.isAccessGranted !==
                        spyProps.values?.isAccessGranted &&
                      props.onChange &&
                      spyProps.values?.isAccessGranted
                    ) {
                      // form.mutators.setBussinessEmail(values.tempEmail);
                    }
                  }}
                />

                <Stack
                  horizontal
                  wrap
                  styles={stackStyles}
                  tokens={stackTokens}
                >
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.halfWidth}`}>
                      <Field name="cabPersonId">
                        {({ input, meta }) => (
                          <>
                            <PeoplePicker
                              label={t('name')}
                              isDisabled={props.disabled}
                              selectedItem={
                                values?.cabPersonId
                                  ? [
                                      {
                                        key: values?.cabPersonName,
                                        text: values?.cabPersonName,
                                      },
                                    ]
                                  : []
                              }
                              onChange={(items: any) => {
                                form.resetFieldState('cabPersonId');
                                input.onChange;
                                return items;
                              }}
                              autoComplete={'disable'}
                              onPersonSelected={(item: any) => {
                                input.onChange(item.cabPersonId);
                                form.mutators.setCabPersonName(item.text);
                                form.mutators.setTempEmail(item?.email);
                                form.mutators.setCabCompany(item?.Organisation);
                                return item;
                              }}
                              key={ 'normal' }
                              endpoint={'CentralAddressBook/PersonFilter'}
                              isCheckCompany={true}
                            />
                            {(meta.error || meta.submitError) &&
                              meta.touched && (
                                <Text
                                  styles={{
                                    root: { color: '#a4262c', fontSize: 12 },
                                  }}
                                >
                                  {meta.error || meta.submitError}
                                </Text>
                              )}
                          </>
                        )}
                      </Field>
                      <Field
                        name="cabPersonName"
                        component={'input'}
                        type={'text'}
                        disabled={true}
                        hidden
                      />
                      <Field
                        name="tempEmail"
                        component={'input'}
                        type={'text'}
                        disabled={true}
                        hidden
                      />
                    </div>
                  </Stack.Item>
                </Stack>

                <Stack
                  horizontal
                  wrap
                  styles={stackStyles}
                  tokens={stackTokens}
                >
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <Field name="roleId">
                        {({ input, meta }:any) => (
                          <Dropdown
                            placeholder={t('role')}
                            label={t('role')}
                            required={true}
                            selectedKey={values.roleId}
                            options={userRoles}
                            onChange={(
                              event: React.FormEvent<HTMLDivElement>,
                              item: any
                            ) => {
                              input.onChange(item.key);
                              form.mutators.setRoleName(item.text);
                            }}
                            errorMessage={ ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched ? errors?.roleId : ''}
                          />
                        )}
                      </Field>
                      <Field
                        name="roleName"
                        component={'input'}
                        type={'text'}
                        disabled={true}
                        hidden
                      />
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <Field
                        name="companyName"
                        component={TextFieldFF}
                        lable={t('company')}
                        disabled={true}
                        initialValue={values.companyName}
                      />
                    </div>
                  </Stack.Item>
                </Stack>

                <Stack.Item grow={6} styles={stackItemCheckStyles}>
                  <div className={`${classNames.halfWidth} projectAccess`}>
                    <Field name="isAccessGranted">
                      {({ input, meta }) => (
                        <Checkbox
                          checked={values.isAccessGranted}
                          label={t('projectAccess')}
                          onChange={(event, value) => {
                            input.onChange(value);

                          }}
                        />
                      )}
                    </Field>
                  </div>
                </Stack.Item>

                <Condition when="isAccessGranted" is={true}>
                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.halfWidth}`}>
                        <Field
                          name="email"
                          component={TextFieldFF}
                          lable={t('email')}
                          disabled={true}
                          initialValue={values.tempEmail}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="message"
                          component={TextFieldFF}
                          multiline
                          lable={t('message')}
                          disabled={false}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>
                </Condition>

                <div className={contentStyles.footer}>
                  <PrimaryButton
                    iconProps={addIconWhite}
                    text={t('save')}
                    style={{ marginTop: 40, marginBottom: 10 }}
                    onClick={() => {
                      form.submit();
                    }}
                  />
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamsModalContent;

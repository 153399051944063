import groupArray from 'group-array';

export const getVPPOFromBarTask = (task: any) => {
  return {
    id: task.id,
    parentId: task.parentId,
    title: task.title,
    startDate: task.start,
    endDate: task.end,
    status: null,
    type: task.type,
    linkId: task.linkId,
    project: task.project,
    borId: null,
    ccpcId: task.cpcId,
    poId: task.poId,
    children: [
      null,
    ],
  };
};

export const getCPCTeams = (teams: any, cpcId: string) => {
  const groupTeamArray: any = groupArray(teams, 'cpcId');
  if (groupTeamArray.hasOwnProperty(cpcId)) {
    return groupTeamArray[cpcId];
  }
  return [];
};

export const getPersonIDArray = (team: any) => {
  const ids = team.map((member: any) => {
    return member.personId;
  });

  return ids;
};

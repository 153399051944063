import { createForm } from 'final-form';
import {
  Dropdown,
  FontWeights,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  mergeStyleSets,
  PrimaryButton,
  Stack,
  Text,
} from '@fluentui/react';
import React, { useEffect, useRef } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { uPrinceTheme } from '../../../../../../theme';
import { uploadInstructionDoc } from '../../../../../reducers/projectBreakdownReducer';
import { TextFieldFF } from '../../../../../shared/fluentFinalForm';
import PeoplePicker from '../../../../../shared/fluentFinalForm/PeoplePicker';
import { v4 as uuid4 } from 'uuid';
import { saveContractStateAttr } from '../../../../../reducers/contractReducer';

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '40vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const addWhiteIconButtonStyles = {
  root: { color: uPrinceTheme.palette.white },
  rootHovered: { color: theme.palette.neutralDark },
};
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};
const uploadIconWhite: IIconProps = {
  iconName: 'Upload',
  styles: addWhiteIconButtonStyles,
};

const AccreditationsModal = (props: {
  values: any;
  disabled: boolean;
  onChange: any;
  isAcc: boolean;
  dataList: any[];
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pbsExperience }: any = useSelector(
    (state: any) => state.projectBreakdown,
  );
  const { uploadedInstructionLink, instructionUploadStatus }: any = useSelector(
    (state: any) => state.contract,
  );
  const inputFile: any = useRef(null);
  const ref: any = useRef(null);

  const stackItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 50,
      width: 0,
      flexGrow: 1,
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
  };

  const stackStyles: IStackStyles = { root: { padding: 0 } };

  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
    fullWidth: { width: '100%' },
    halfWidth: { width: '49%' },
  });

  const onSubmit = async (values: any) => {
    const sleep = (ms: number | undefined) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);
    props.onChange(values);
  };

  const formRef = React.useRef(createForm({ onSubmit: onSubmit }));

  const getInitialvalues = (values: any) => {
    if (values) {
      return values;
    }
    return {
      id: null,
      cabPersonId: null,
      cabPersonName: null,
      skill: null,
      experienceId: null,
      experienceName: null,
      fileName: null,
    };
  };

  useEffect(() => {
    if (uploadedInstructionLink) {
      ref.current.mutators.setDocumentLink(uploadedInstructionLink);
    }
  }, [uploadedInstructionLink]);

  return (
    <div className={contentStyles.body}>
      <div className="proj-detail-content inner">
        <div className="ms-Grid-row">
          <Form
            onSubmit={onSubmit}
            initialValues={getInitialvalues(props.values)}
            mutators={{
              setCabPersonName: (args, state) => {
                const field = state.fields.cabPersonName;
                field.change(args[0]);
              },
              setCabCompany: (args, state) => {
                const field = state.fields.company;
                field.change(args[0]);
              },
              setExperienceName: (args, state) => {
                const field = state.fields.experienceName;
                field.change(args[0]);
              },
              setDocumentLink: (args, state) => {
                const field = state.fields.fileName;
                field.change(args[0]);
              },
            }}
            validate={(values) => {
              const errors: any = {};
              if (!values?.cabPersonId) {
                errors.cabPersonId = t('personRequired');
              }
              if (Array.isArray(props.dataList) && !values.id) {
                const index = props.dataList.findIndex((object) => {
                  return object.cabPersonId === values.cabPersonId;
                });
                if (index !== -1) {
                  errors.cabPersonId = t('alreadyAdded');
                }
              }
              if (!values?.fileName && !props.isAcc) {
                errors.fileName = t('fileRequired');
              }
              return errors;
            }}
            render={({ form, submitting, pristine, values }) => {
              // <form onSubmit={ handleSubmit }>
              ref.current = form;
              return (
                <div>
                  <FormSpy
                    onChange={() => {
                    }}
                  />
                  {props.isAcc && <div>
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field name="cabPersonId">
                            {({ input, meta }) => (
                              <>
                                <PeoplePicker
                                  label={t('name')}
                                  isDisabled={props.disabled}
                                  selectedItem={
                                    values?.cabPersonId
                                      ? [
                                        {
                                          key: values?.cabPersonName,
                                          text: values?.cabPersonName,
                                        },
                                      ]
                                      : []
                                  }
                                  onChange={(items: any) => {
                                    form.resetFieldState('cabPersonId');
                                    input.onChange;
                                    return items;
                                  }}
                                  autoComplete={'off'}
                                  onPersonSelected={(item: any) => {
                                    input.onChange(item.cabPersonId);
                                    form.mutators.setCabPersonName(item.text);
                                    form.mutators.setCabCompany(item.Organisation);
                                    return item;
                                  }}
                                />
                                {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <Text
                                    styles={{
                                      root: { color: '#a4262c', fontSize: 12 },
                                    }}
                                  >
                                    {meta.error || meta.submitError}
                                  </Text>
                                )}
                              </>
                            )}
                          </Field>
                          <Field
                            name="cabPersonName"
                            component={'input'}
                            type={'text'}
                            disabled={true}
                            hidden
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="company"
                            component={TextFieldFF}
                            lable={t('company')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="skill"
                            component={TextFieldFF}
                            lable={t('skill')}
                            disabled={false}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field name="experienceId">
                            {({ input }) => (
                              <Dropdown
                                placeholder={t('experience')}
                                label={t('experience')}
                                selectedKey={values.experienceId}
                                options={pbsExperience}
                                onChange={(
                                  event: React.FormEvent<HTMLDivElement>,
                                  item: any,
                                ) => {
                                  input.onChange(item.key);
                                  form.mutators.setExperienceName(item.text);
                                }}
                              />
                            )}
                          </Field>
                          <Field
                            name="experienceName"
                            component={'input'}
                            type={'text'}
                            disabled={true}
                            hidden
                          />
                        </div>
                      </Stack.Item>
                    </Stack>
                  </div>
                  }

                  {!props.isAcc && <div>
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field name="cabPersonId">
                            {({ input, meta }) => (
                              <>
                                <PeoplePicker
                                  label={t('name')}
                                  isDisabled={props.disabled}
                                  selectedItem={
                                    values?.cabPersonId
                                      ? [
                                        {
                                          key: values?.cabPersonName,
                                          text: values?.cabPersonName,
                                        },
                                      ]
                                      : []
                                  }
                                  onChange={(items: any) => {
                                    input.onChange;
                                    return items;
                                  }}
                                  onPersonSelected={(item: any) => {
                                    input.onChange(item.cabPersonId);
                                    form.mutators.setCabPersonName(item.text);
                                    form.mutators.setCabCompany(item.Organisation);
                                    return item;
                                  }}
                                />
                                {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <Text
                                    styles={{
                                      root: { color: '#a4262c', fontSize: 12 },
                                    }}
                                  >
                                    {meta.error || meta.submitError}
                                  </Text>
                                )}</>
                            )}
                          </Field>
                          <Field
                            name="cabPersonName"
                            component={'input'}
                            type={'text'}
                            disabled={true}
                            hidden
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="company"
                            component={TextFieldFF}
                            lable={t('company')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="fileName"
                            component={TextFieldFF}
                            lable={t('fileName')}
                            disabled={false}
                            // placeholder={t('copyURLhere')}
                            required={true}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>
                    <input
                      type="file"
                      id="file"
                      ref={inputFile}
                      hidden
                      onChange={(event: any) => {
                        const formData = new FormData();
                        if (event?.target?.files[0]) {
                          formData.append('file', event.target.files[0]);
                          formData.append('id', uuid4());
                          dispatch(uploadInstructionDoc(formData));
                        }
                      }}
                    />
                    <PrimaryButton
                      iconProps={uploadIconWhite}
                      text={t('upload')}
                      disabled={false}
                      style={{ marginTop: 24, marginLeft: 10, marginBottom: 10 }}
                      onClick={() => {
                        inputFile?.current?.click();
                      }}
                    />
                  </div>
                  }
                  <div className={contentStyles.footer}>
                    <PrimaryButton
                      iconProps={addIconWhite}
                      text={t('save')}
                      disabled={submitting || pristine}
                      style={{ marginTop: 36, marginBottom: 10 }}
                      onClick={() => {
                        // props.onChange(values);
                        form.submit();
                        dispatch(saveContractStateAttr('uploadedInstructionLink', null));
                      }}
                    />
                  </div>
                  {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AccreditationsModal;

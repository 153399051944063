export const GET_LANGUAGE_DISTINCT = 'uprince/GET_LANGUAGE_DISTINCT';
export const GET_LANGUAGE_DISTINCT_SUCCESS =
  'uprince/GET_LANGUAGE_DISTINCT_SUCCESS';
export const GET_LANGUAGE_DISTINCT_FAIL = 'uprince/GET_LANGUAGE_DISTINCT_FAIL';

export const GET_COUNTRY_DISTINCT = 'uprince/GET_COUNTRY_DISTINCT';
export const GET_COUNTRY_DISTINCT_SUCCESS =
  'uprince/GET_COUNTRY_DISTINCT_SUCCESS';
export const GET_COUNTRY_DISTINCT_FAIL = 'uprince/GET_COUNTRY_DISTINCT_FAIL';

export const GET_LANGUAGE_BY_CODE = 'uprince/GET_LANGUAGE_BY_CODE';
export const GET_LANGUAGE_BY_CODE_SUCCESS =
  'uprince/GET_LANGUAGE_BY_CODE_SUCCESS';
export const GET_LANGUAGE_BY_CODE_FAIL = 'uprince/GET_LANGUAGE_BY_CODE_FAIL';

export const GET_DROPDOWN_KEYS_BY_CODE = 'uprince/GET_DROPDOWN_KEYS_BY_CODE';
export const GET_DROPDOWN_KEYS_BY_CODE_SUCCESS =
  'uprince/GET_DROPDOWN_KEYS_BY_CODE_SUCCESS';
export const GET_DROPDOWN_KEYS_BY_CODE_FAIL =
  'uprince/GET_DROPDOWN_KEYS_BY_CODE_FAIL';

export const DROPDOWN_KEYS_UPDATE = 'uprince/DROPDOWN_KEYS_UPDATE';
export const DROPDOWN_KEYS_UPDATE_SUCCESS =
  'uprince/DROPDOWN_KEYS_UPDATE_SUCCESS';
export const DROPDOWN_KEYS_UPDATE_FAIL = 'uprince/DROPDOWN_KEYS_UPDATE_FAIL';

export const CPC_MOU_DROPDOWN_KEYS_CREATE =
  'uprince/CPC_MOU_DROPDOWN_KEYS_CREATE';
export const CPC_MOU_DROPDOWN_KEYS_CREATE_SUCCESS =
  'uprince/CPC_MOU_DROPDOWN_KEYS_CREATE_SUCCESS';
export const CPC_MOU_DROPDOWN_KEYS_CREATE_FAIL =
  'uprince/CPC_MOU_DROPDOWN_KEYS_CREATE_FAIL';

export const GET_DROPDOWN_LANGUAGE_CODE = 'uprince/GET_DROPDOWN_LANGUAGE_CODE';
export const GET_DROPDOWN_LANGUAGE_CODE_SUCCESS =
  'uprince/GET_DROPDOWN_LANGUAGE_CODE_SUCCESS';
export const GET_DROPDOWN_LANGUAGE_CODE_FAIL =
  'uprince/GET_DROPDOWN_LANGUAGE_CODE_FAIL';

export const GET_CPC_RESOURCE_TYPES_BY_CODE =
  'uprince/GET_CPC_RESOURCE_TYPES_BY_CODE';
export const GET_CPC_RESOURCE_TYPES_BY_CODE_SUCCESS =
  'uprince/GET_CPC_RESOURCE_TYPES_BY_CODE_SUCCESS';
export const GET_CPC_RESOURCE_TYPES_BY_CODE_FAIL =
  'uprince/GET_CPC_RESOURCE_TYPES_BY_CODE_FAIL';

export const CPC_RESOURCE_TYPE_UPDATE = 'uprince/CPC_RESOURCE_TYPE_UPDATE';
export const CPC_RESOURCE_TYPE_UPDATE_SUCCESS =
  'uprince/CPC_RESOURCE_TYPE_UPDATE_SUCCESS';
export const CPC_RESOURCE_TYPE_UPDATE_FAIL =
  'uprince/CPC_RESOURCE_TYPE_UPDATE_FAIL';

export const CPC_RESOURCE_TYPE_CREATE = 'uprince/CPC_RESOURCE_TYPE_CREATE';
export const CPC_RESOURCE_TYPE_CREATE_SUCCESS =
  'uprince/CPC_RESOURCE_TYPE_CREATE_SUCCESS';
export const CPC_RESOURCE_TYPE_CREATE_FAIL =
  'uprince/CPC_RESOURCE_TYPE_CREATE_FAIL';

export const GET_CPC_MATERIAL_BY_CODE = 'uprince/GET_CPC_MATERIAL_BY_CODE';
export const GET_CPC_MATERIAL_BY_CODE_SUCCESS =
  'uprince/GET_CPC_MATERIAL_BY_CODE_SUCCESS';
export const GET_CPC_MATERIAL_BY_CODE_FAIL =
  'uprince/GET_CPC_MATERIAL_BY_CODE_FAIL';

export const CPC_MATERIAL_UPDATE = 'uprince/CPC_MATERIAL_UPDATE';
export const CPC_MATERIAL_UPDATE_SUCCESS =
  'uprince/CPC_MATERIAL_UPDATE_SUCCESS';
export const CPC_MATERIAL_UPDATE_FAIL = 'uprince/CPC_MATERIAL_UPDATE_FAIL';

export const CPC_MATERIAL_CREATE = 'uprince/CPC_MATERIAL_CREATE';
export const CPC_MATERIAL_CREATE_SUCCESS =
  'uprince/CPC_MATERIAL_CREATE_SUCCESS';
export const CPC_MATERIAL_CREATE_FAIL = 'uprince/CPC_MATERIAL_CREATE_FAIL';

export const GET_CPC_PRESSURE_DROPDOWN_BY_CODE =
  'uprince/GET_CPC_PRESSURE_DROPDOWN_BY_CODE';
export const GET_CPC_PRESSURE_DROPDOWN_BY_CODE_SUCCESS =
  'uprince/GET_CPC_PRESSURE_DROPDOWN_BY_CODE_SUCCESS';
export const GET_CPC_PRESSURE_DROPDOWN_BY_CODE_FAIL =
  'uprince/GET_CPC_PRESSURE_DROPDOWN_BY_CODE_FAIL';

export const CPC_PRESSURE_DROPDOWN_UPDATE =
  'uprince/CPC_PRESSURE_DROPDOWN_UPDATE';
export const CPC_PRESSURE_DROPDOWN_UPDATE_SUCCESS =
  'uprince/CPC_PRESSURE_DROPDOWN_UPDATE_SUCCESS';
export const CPC_PRESSURE_DROPDOWN_UPDATE_FAIL =
  'uprince/CPC_PRESSURE_DROPDOWN_UPDATE_FAIL';

export const CPC_PRESSURE_DROPDOWN_CREATE =
  'uprince/CPC_PRESSURE_DROPDOWN_CREATE';
export const CPC_PRESSURE_DROPDOWN_CREATE_SUCCESS =
  'uprince/CPC_PRESSURE_DROPDOWN_CREATE_SUCCESS';
export const CPC_PRESSURE_DROPDOWN_CREATE_FAIL =
  'uprince/CPC_PRESSURE_DROPDOWN_CREATE_FAIL';

export const GET_CPC_BRAND_DROPDOWN_BY_CODE =
  'uprince/GET_CPC_BRAND_DROPDOWN_BY_CODE';
export const GET_CPC_BRAND_DROPDOWN_BY_CODE_SUCCESS =
  'uprince/GET_CPC_BRAND_DROPDOWN_BY_CODE_SUCCESS';
export const GET_CPC_BRAND_DROPDOWN_BY_CODE_FAIL =
  'uprince/GET_CPC_BRAND_DROPDOWN_BY_CODE_FAIL';

export const CPC_BRAND_DROPDOWN_UPDATE = 'uprince/CPC_BRAND_DROPDOWN_UPDATE';
export const CPC_BRAND_DROPDOWN_UPDATE_SUCCESS =
  'uprince/CPC_BRAND_DROPDOWN_UPDATE_SUCCESS';
export const CPC_BRAND_DROPDOWN_UPDATE_FAIL =
  'uprince/CPC_BRAND_DROPDOWN_UPDATE_FAIL';

export const CPC_BRAND_DROPDOWN_CREATE = 'uprince/CPC_BRAND_DROPDOWN_CREATE';
export const CPC_BRAND_DROPDOWN_CREATE_SUCCESS =
  'uprince/CPC_BRAND_DROPDOWN_CREATE_SUCCESS';
export const CPC_BRAND_DROPDOWN_CREATE_FAIL =
  'uprince/CPC_BRAND_DROPDOWN_CREATE_FAIL';

export const GET_CPC_RESOURCE_FAMILY_DROPDOWN_BY_CODE =
  'uprince/GET_CPC_RESOURCE_FAMILY_DROPDOWN_BY_CODE';
export const GET_CPC_RESOURCE_FAMILY_DROPDOWN_BY_CODE_SUCCESS =
  'uprince/GET_CPC_RESOURCE_FAMILY_DROPDOWN_BY_CODE_SUCCESS';
export const GET_CPC_RESOURCE_FAMILY_DROPDOWN_BY_CODE_FAIL =
  'uprince/GET_CPC_RESOURCE_FAMILY_DROPDOWN_BY_CODE_FAIL';

export const CPC_RESOURCE_FAMILY_DROPDOWN_UPDATE =
  'uprince/CPC_RESOURCE_FAMILY_DROPDOWN_UPDATE';
export const CPC_RESOURCE_FAMILY_DROPDOWN_UPDATE_SUCCESS =
  'uprince/CPC_RESOURCE_FAMILY_DROPDOWN_UPDATE_SUCCESS';
export const CPC_RESOURCE_FAMILY_DROPDOWN_UPDATE_FAIL =
  'uprince/CPC_RESOURCE_FAMILY_DROPDOWN_UPDATE_FAIL';

export const CPC_RESOURCE_FAMILY_DROPDOWN_CREATE =
  'uprince/CPC_RESOURCE_FAMILY_DROPDOWN_CREATE';
export const CPC_RESOURCE_FAMILY_DROPDOWN_CREATE_SUCCESS =
  'uprince/CPC_RESOURCE_FAMILY_DROPDOWN_CREATE_SUCCESS';
export const CPC_RESOURCE_FAMILY_DROPDOWN_CREATE_FAIL =
  'uprince/CPC_RESOURCE_FAMILY_DROPDOWN_CREATE_FAIL';

export const GET_CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_BY_CODE =
  'uprince/GET_CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_BY_CODE';
export const GET_CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_BY_CODE_SUCCESS =
  'uprince/GET_CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_BY_CODE_SUCCESS';
export const GET_CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_BY_CODE_FAIL =
  'uprince/GET_CPC_CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_BY_CODE_FAIL';

export const CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_UPDATE =
  'uprince/CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_UPDATE';
export const CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_UPDATE_SUCCESS =
  'uprince/CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_UPDATE_SUCCESS';
export const CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_UPDATE_FAIL =
  'uprince/CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_UPDATE_FAIL';

export const CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_CREATE =
  'uprince/CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_CREATE';
export const CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_CREATE_SUCCESS =
  'uprince/CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_CREATE_SUCCESS';
export const CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_CREATE_FAIL =
  'uprince/CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_CREATE_FAIL';

// PBS

export const GET_PBS_TOLERANCE_DROPDOWN_BY_CODE =
  'uprince/GET_PBS_TOLERANCE_DROPDOWN_BY_CODE';
export const GET_PBS_TOLERANCE_DROPDOWN_BY_CODE_SUCCESS =
  'uprince/GET_PBS_TOLERANCE_DROPDOWN_BY_CODE_SUCCESS';
export const GET_PBS_TOLERANCE_DROPDOWN_BY_CODE_FAIL =
  'uprince/GET_CPC_PBS_TOLERANCE_DROPDOWN_BY_CODE_FAIL';

export const PBS_TOLERANCE_DROPDOWN_UPDATE =
  'uprince/PBS_TOLERANCE_DROPDOWN_UPDATE';
export const PBS_TOLERANCE_DROPDOWN_UPDATE_SUCCESS =
  'uprince/PBS_TOLERANCE_DROPDOWN_UPDATE_SUCCESS';
export const PBS_TOLERANCE_DROPDOWN_UPDATE_FAIL =
  'uprince/PBS_TOLERANCE_DROPDOWN_UPDATE_FAIL';

export const PBS_TOLERANCE_DROPDOWN_CREATE =
  'uprince/PBS_TOLERANCE_DROPDOWN_CREATE';
export const PBS_TOLERANCE_DROPDOWN_CREATE_SUCCESS =
  'uprince/PBS_TOLERANCE_DROPDOWN_CREATE_SUCCESS';
export const PBS_TOLERANCE_DROPDOWN_CREATE_FAIL =
  'uprince/PBS_TOLERANCE_DROPDOWN_CREATE_FAIL';

export const GET_PBS_STATUS_DROPDOWN_BY_CODE =
  'uprince/GET_PBS_STATUS_DROPDOWN_BY_CODE';
export const GET_PBS_STATUS_DROPDOWN_BY_CODE_SUCCESS =
  'uprince/GET_PBS_STATUS_DROPDOWN_BY_CODE_SUCCESS';
export const GET_PBS_STATUS_DROPDOWN_BY_CODE_FAIL =
  'uprince/GET_CPC_PBS_STATUS_DROPDOWN_BY_CODE_FAIL';

export const PBS_STATUS_DROPDOWN_UPDATE = 'uprince/PBS_STATUS_DROPDOWN_UPDATE';
export const PBS_STATUS_DROPDOWN_UPDATE_SUCCESS =
  'uprince/PBS_STATUS_DROPDOWN_UPDATE_SUCCESS';
export const PBS_STATUS_DROPDOWN_UPDATE_FAIL =
  'uprince/PBS_STATUS_DROPDOWN_UPDATE_FAIL';

export const PBS_STATUS_DROPDOWN_CREATE = 'uprince/PBS_STATUS_DROPDOWN_CREATE';
export const PBS_STATUS_DROPDOWN_CREATE_SUCCESS =
  'uprince/PBS_STATUS_DROPDOWN_CREATE_SUCCESS';
export const PBS_STATUS_DROPDOWN_CREATE_FAIL =
  'uprince/PBS_STATUS_DROPDOWN_CREATE_FAIL';

export const GET_PBS_ITEM_TYPE_DROPDOWN_BY_CODE =
  'uprince/GET_PBS_ITEM_TYPE_DROPDOWN_BY_CODE';
export const GET_PBS_ITEM_TYPE_DROPDOWN_BY_CODE_SUCCESS =
  'uprince/GET_PBS_ITEM_TYPE_DROPDOWN_BY_CODE_SUCCESS';
export const GET_PBS_ITEM_TYPE_DROPDOWN_BY_CODE_FAIL =
  'uprince/GET_CPC_PBS_ITEM_TYPE_DROPDOWN_BY_CODE_FAIL';

export const PBS_ITEM_TYPE_DROPDOWN_UPDATE =
  'uprince/PBS_ITEM_TYPE_DROPDOWN_UPDATE';
export const PBS_ITEM_TYPE_DROPDOWN_UPDATE_SUCCESS =
  'uprince/PBS_ITEM_TYPE_DROPDOWN_UPDATE_SUCCESS';
export const PBS_ITEM_TYPE_DROPDOWN_UPDATE_FAIL =
  'uprince/PBS_ITEM_TYPE_DROPDOWN_UPDATE_FAIL';

export const PBS_ITEM_TYPE_DROPDOWN_CREATE =
  'uprince/PBS_ITEM_TYPE_DROPDOWN_CREATE';
export const PBS_ITEM_TYPE_DROPDOWN_CREATE_SUCCESS =
  'uprince/PBS_ITEM_TYPE_DROPDOWN_CREATE_SUCCESS';
export const PBS_ITEM_TYPE_DROPDOWN_CREATE_FAIL =
  'uprince/PBS_ITEM_TYPE_DROPDOWN_CREATE_FAIL';

export const GET_PBS_EXPERIENCE_DROPDOWN_BY_CODE =
  'uprince/GET_PBS_EXPERIENCE_DROPDOWN_BY_CODE';
export const GET_PBS_EXPERIENCE_DROPDOWN_BY_CODE_SUCCESS =
  'uprince/GET_PBS_EXPERIENCE_DROPDOWN_BY_CODE_SUCCESS';
export const GET_PBS_EXPERIENCE_DROPDOWN_BY_CODE_FAIL =
  'uprince/GET_CPC_PBS_EXPERIENCE_DROPDOWN_BY_CODE_FAIL';

export const PBS_EXPERIENCE_DROPDOWN_UPDATE =
  'uprince/PBS_EXPERIENCE_DROPDOWN_UPDATE';
export const PBS_EXPERIENCE_DROPDOWN_UPDATE_SUCCESS =
  'uprince/PBS_EXPERIENCE_DROPDOWN_UPDATE_SUCCESS';
export const PBS_EXPERIENCE_DROPDOWN_UPDATE_FAIL =
  'uprince/PBS_EXPERIENCE_DROPDOWN_UPDATE_FAIL';

export const PBS_EXPERIENCE_DROPDOWN_CREATE =
  'uprince/PBS_EXPERIENCE_DROPDOWN_CREATE';
export const PBS_EXPERIENCE_DROPDOWN_CREATE_SUCCESS =
  'uprince/PBS_EXPERIENCE_DROPDOWN_CREATE_SUCCESS';
export const PBS_EXPERIENCE_DROPDOWN_CREATE_FAIL =
  'uprince/PBS_EXPERIENCE_DROPDOWN_CREATE_FAIL';

export const GET_PBS_SKILLS_DROPDOWN_BY_CODE =
  'uprince/GET_PBS_SKILLS_DROPDOWN_BY_CODE';
export const GET_PBS_SKILLS_DROPDOWN_BY_CODE_SUCCESS =
  'uprince/GET_PBS_SKILLS_DROPDOWN_BY_CODE_SUCCESS';
export const GET_PBS_SKILLS_DROPDOWN_BY_CODE_FAIL =
  'uprince/GET_CPC_PBS_SKILLS_DROPDOWN_BY_CODE_FAIL';

export const PBS_SKILLS_DROPDOWN_UPDATE = 'uprince/PBS_SKILLS_DROPDOWN_UPDATE';
export const PBS_SKILLS_DROPDOWN_UPDATE_SUCCESS =
  'uprince/PBS_SKILLS_DROPDOWN_UPDATE_SUCCESS';
export const PBS_SKILLS_DROPDOWN_UPDATE_FAIL =
  'uprince/PBS_SKILLS_DROPDOWN_UPDATE_FAIL';

export const PBS_SKILLS_DROPDOWN_CREATE = 'uprince/PBS_SKILLS_DROPDOWN_CREATE';
export const PBS_SKILLS_DROPDOWN_CREATE_SUCCESS =
  'uprince/PBS_SKILLS_DROPDOWN_CREATE_SUCCESS';
export const PBS_SKILLS_DROPDOWN_CREATE_FAIL =
  'uprince/PBS_SKILLS_DROPDOWN_CREATE_FAIL';

// Project

export const GET_PROJECT_MANAGEMENT_LEVELS_DROPDOWN_BY_CODE =
  'uprince/GET_PROJECT_MANAGEMENT_LEVELS_DROPDOWN_BY_CODE';
export const GET_PROJECT_MANAGEMENT_LEVELS_DROPDOWN_BY_CODE_SUCCESS =
  'uprince/GET_PROJECT_MANAGEMENT_LEVELS_DROPDOWN_BY_CODE_SUCCESS';
export const GET_PROJECT_MANAGEMENT_LEVELS_DROPDOWN_BY_CODE_FAIL =
  'uprince/GET_CPC_PROJECT_MANAGEMENT_LEVELS_DROPDOWN_BY_CODE_FAIL';

export const PROJECT_MANAGEMENT_LEVELS_DROPDOWN_UPDATE =
  'uprince/PROJECT_MANAGEMENT_LEVELS_DROPDOWN_UPDATE';
export const PROJECT_MANAGEMENT_LEVELS_DROPDOWN_UPDATE_SUCCESS =
  'uprince/PROJECT_MANAGEMENT_LEVELS_DROPDOWN_UPDATE_SUCCESS';
export const PROJECT_MANAGEMENT_LEVELS_DROPDOWN_UPDATE_FAIL =
  'uprince/PROJECT_MANAGEMENT_LEVELS_DROPDOWN_UPDATE_FAIL';

export const PROJECT_MANAGEMENT_LEVELS_DROPDOWN_CREATE =
  'uprince/PROJECT_MANAGEMENT_LEVELS_DROPDOWN_CREATE';
export const PROJECT_MANAGEMENT_LEVELS_DROPDOWN_CREATE_SUCCESS =
  'uprince/PROJECT_MANAGEMENT_LEVELS_DROPDOWN_CREATE_SUCCESS';
export const PROJECT_MANAGEMENT_LEVELS_DROPDOWN_CREATE_FAIL =
  'uprince/PROJECT_MANAGEMENT_LEVELS_DROPDOWN_CREATE_FAIL';

export const GET_PROJECT_CURRENCIES_DROPDOWN_BY_CODE =
  'uprince/GET_PROJECT_CURRENCIES_DROPDOWN_BY_CODE';
export const GET_PROJECT_CURRENCIES_DROPDOWN_BY_CODE_SUCCESS =
  'uprince/GET_PROJECT_CURRENCIES_DROPDOWN_BY_CODE_SUCCESS';
export const GET_PROJECT_CURRENCIES_DROPDOWN_BY_CODE_FAIL =
  'uprince/GET_CPC_PROJECT_CURRENCIES_DROPDOWN_BY_CODE_FAIL';

export const PROJECT_CURRENCIES_DROPDOWN_UPDATE =
  'uprince/PROJECT_CURRENCIES_DROPDOWN_UPDATE';
export const PROJECT_CURRENCIES_DROPDOWN_UPDATE_SUCCESS =
  'uprince/PROJECT_CURRENCIES_DROPDOWN_UPDATE_SUCCESS';
export const PROJECT_CURRENCIES_DROPDOWN_UPDATE_FAIL =
  'uprince/PROJECT_CURRENCIES_DROPDOWN_UPDATE_FAIL';

export const PROJECT_CURRENCIES_DROPDOWN_CREATE =
  'uprince/PROJECT_CURRENCIES_DROPDOWN_CREATE';
export const PROJECT_CURRENCIES_DROPDOWN_CREATE_SUCCESS =
  'uprince/PROJECT_CURRENCIES_DROPDOWN_CREATE_SUCCESS';
export const PROJECT_CURRENCIES_DROPDOWN_CREATE_FAIL =
  'uprince/PROJECT_CURRENCIES_DROPDOWN_CREATE_FAIL';

export const GET_PROJECT_STATES_DROPDOWN_BY_CODE =
  'uprince/GET_PROJECT_STATES_DROPDOWN_BY_CODE';
export const GET_PROJECT_STATES_DROPDOWN_BY_CODE_SUCCESS =
  'uprince/GET_PROJECT_STATES_DROPDOWN_BY_CODE_SUCCESS';
export const GET_PROJECT_STATES_DROPDOWN_BY_CODE_FAIL =
  'uprince/GET_CPC_PROJECT_STATES_DROPDOWN_BY_CODE_FAIL';

export const PROJECT_STATES_DROPDOWN_UPDATE =
  'uprince/PROJECT_STATES_DROPDOWN_UPDATE';
export const PROJECT_STATES_DROPDOWN_UPDATE_SUCCESS =
  'uprince/PROJECT_STATES_DROPDOWN_UPDATE_SUCCESS';
export const PROJECT_STATES_DROPDOWN_UPDATE_FAIL =
  'uprince/PROJECT_STATES_DROPDOWN_UPDATE_FAIL';

export const PROJECT_STATES_DROPDOWN_CREATE =
  'uprince/PROJECT_STATES_DROPDOWN_CREATE';
export const PROJECT_STATES_DROPDOWN_CREATE_SUCCESS =
  'uprince/PROJECT_STATES_DROPDOWN_CREATE_SUCCESS';
export const PROJECT_STATES_DROPDOWN_CREATE_FAIL =
  'uprince/PROJECT_STATES_DROPDOWN_CREATE_FAIL';

export const GET_PROJECT_TYPES_DROPDOWN_BY_CODE =
  'uprince/GET_PROJECT_TYPES_DROPDOWN_BY_CODE';
export const GET_PROJECT_TYPES_DROPDOWN_BY_CODE_SUCCESS =
  'uprince/GET_PROJECT_TYPES_DROPDOWN_BY_CODE_SUCCESS';
export const GET_PROJECT_TYPES_DROPDOWN_BY_CODE_FAIL =
  'uprince/GET_CPC_PROJECT_TYPES_DROPDOWN_BY_CODE_FAIL';

export const PROJECT_TYPES_DROPDOWN_UPDATE =
  'uprince/PROJECT_TYPES_DROPDOWN_UPDATE';
export const PROJECT_TYPES_DROPDOWN_UPDATE_SUCCESS =
  'uprince/PROJECT_TYPES_DROPDOWN_UPDATE_SUCCESS';
export const PROJECT_TYPES_DROPDOWN_UPDATE_FAIL =
  'uprince/PROJECT_TYPES_DROPDOWN_UPDATE_FAIL';

export const PROJECT_TYPES_DROPDOWN_CREATE =
  'uprince/PROJECT_TYPES_DROPDOWN_CREATE';
export const PROJECT_TYPES_DROPDOWN_CREATE_SUCCESS =
  'uprince/PROJECT_TYPES_DROPDOWN_CREATE_SUCCESS';
export const PROJECT_TYPES_DROPDOWN_CREATE_FAIL =
  'uprince/PROJECT_TYPES_DROPDOWN_CREATE_FAIL';

export const GET_PROJECT_TEMPLATES_DROPDOWN_BY_CODE =
  'uprince/GET_PROJECT_TEMPLATES_DROPDOWN_BY_CODE';
export const GET_PROJECT_TEMPLATES_DROPDOWN_BY_CODE_SUCCESS =
  'uprince/GET_PROJECT_TEMPLATES_DROPDOWN_BY_CODE_SUCCESS';
export const GET_PROJECT_TEMPLATES_DROPDOWN_BY_CODE_FAIL =
  'uprince/GET_CPC_PROJECT_TEMPLATES_DROPDOWN_BY_CODE_FAIL';

export const PROJECT_TEMPLATES_DROPDOWN_UPDATE =
  'uprince/PROJECT_TEMPLATES_DROPDOWN_UPDATE';
export const PROJECT_TEMPLATES_DROPDOWN_UPDATE_SUCCESS =
  'uprince/PROJECT_TEMPLATES_DROPDOWN_UPDATE_SUCCESS';
export const PROJECT_TEMPLATES_DROPDOWN_UPDATE_FAIL =
  'uprince/PROJECT_TEMPLATES_DROPDOWN_UPDATE_FAIL';

export const PROJECT_TEMPLATES_DROPDOWN_CREATE =
  'uprince/PROJECT_TEMPLATES_DROPDOWN_CREATE';
export const PROJECT_TEMPLATES_DROPDOWN_CREATE_SUCCESS =
  'uprince/PROJECT_TEMPLATES_DROPDOWN_CREATE_SUCCESS';
export const PROJECT_TEMPLATES_DROPDOWN_CREATE_FAIL =
  'uprince/PROJECT_TEMPLATES_DROPDOWN_CREATE_FAIL';

export const GET_PROJECT_LEDGER_DROPDOWN_BY_CODE =
  'uprince/GET_PROJECT_LEDGER_DROPDOWN_BY_CODE';
export const GET_PROJECT_LEDGER_DROPDOWN_BY_CODE_SUCCESS =
  'uprince/GET_PROJECT_LEDGER_DROPDOWN_BY_CODE_SUCCESS';
export const GET_PROJECT_LEDGER_DROPDOWN_BY_CODE_FAIL =
  'uprince/GET_CPC_PROJECT_LEDGER_DROPDOWN_BY_CODE_FAIL';

export const PROJECT_LEDGER_DROPDOWN_UPDATE =
  'uprince/PROJECT_LEDGER_DROPDOWN_UPDATE';
export const PROJECT_LEDGER_DROPDOWN_UPDATE_SUCCESS =
  'uprince/PROJECT_LEDGER_DROPDOWN_UPDATE_SUCCESS';
export const PROJECT_LEDGER_DROPDOWN_UPDATE_FAIL =
  'uprince/PROJECT_LEDGER_DROPDOWN_UPDATE_FAIL';

export const PROJECT_LEDGER_DROPDOWN_CREATE =
  'uprince/PROJECT_LEDGER_DROPDOWN_CREATE';
export const PROJECT_LEDGER_DROPDOWN_CREATE_SUCCESS =
  'uprince/PROJECT_LEDGER_DROPDOWN_CREATE_SUCCESS';
export const PROJECT_LEDGER_DROPDOWN_CREATE_FAIL =
  'uprince/PROJECT_LEDGER_DROPDOWN_CREATE_FAIL';

// PMOL

export const GET_PMOL_STATUS_DROPDOWN_BY_CODE =
  'uprince/GET_PMOL_STATUS_DROPDOWN_BY_CODE';
export const GET_PMOL_STATUS_DROPDOWN_BY_CODE_SUCCESS =
  'uprince/GET_PMOL_STATUS_DROPDOWN_BY_CODE_SUCCESS';
export const GET_PMOL_STATUS_DROPDOWN_BY_CODE_FAIL =
  'uprince/GET_CPC_PMOL_STATUS_DROPDOWN_BY_CODE_FAIL';

export const PMOL_STATUS_DROPDOWN_UPDATE =
  'uprince/PMOL_STATUS_DROPDOWN_UPDATE';
export const PMOL_STATUS_DROPDOWN_UPDATE_SUCCESS =
  'uprince/PMOL_STATUS_DROPDOWN_UPDATE_SUCCESS';
export const PMOL_STATUS_DROPDOWN_UPDATE_FAIL =
  'uprince/PMOL_STATUS_DROPDOWN_UPDATE_FAIL';

export const PMOL_STATUS_DROPDOWN_CREATE =
  'uprince/PMOL_STATUS_DROPDOWN_CREATE';
export const PMOL_STATUS_DROPDOWN_CREATE_SUCCESS =
  'uprince/PMOL_STATUS_DROPDOWN_CREATE_SUCCESS';
export const PMOL_STATUS_DROPDOWN_CREATE_FAIL =
  'uprince/PMOL_STATUS_DROPDOWN_CREATE_FAIL';

export const GET_PMOL_TYPE_DROPDOWN_BY_CODE =
  'uprince/GET_PMOL_TYPE_DROPDOWN_BY_CODE';
export const GET_PMOL_TYPE_DROPDOWN_BY_CODE_SUCCESS =
  'uprince/GET_PMOL_TYPE_DROPDOWN_BY_CODE_SUCCESS';
export const GET_PMOL_TYPE_DROPDOWN_BY_CODE_FAIL =
  'uprince/GET_CPC_PMOL_TYPE_DROPDOWN_BY_CODE_FAIL';

export const PMOL_TYPE_DROPDOWN_UPDATE = 'uprince/PMOL_TYPE_DROPDOWN_UPDATE';
export const PMOL_TYPE_DROPDOWN_UPDATE_SUCCESS =
  'uprince/PMOL_TYPE_DROPDOWN_UPDATE_SUCCESS';
export const PMOL_TYPE_DROPDOWN_UPDATE_FAIL =
  'uprince/PMOL_TYPE_DROPDOWN_UPDATE_FAIL';

export const PMOL_TYPE_DROPDOWN_CREATE = 'uprince/PMOL_TYPE_DROPDOWN_CREATE';
export const PMOL_TYPE_DROPDOWN_CREATE_SUCCESS =
  'uprince/PMOL_TYPE_DROPDOWN_CREATE_SUCCESS';
export const PMOL_TYPE_DROPDOWN_CREATE_FAIL =
  'uprince/PMOL_TYPE_DROPDOWN_CREATE_FAIL';

// BOR

export const GET_BOR_STATUS_DROPDOWN_BY_CODE =
  'uprince/GET_BOR_STATUS_DROPDOWN_BY_CODE';
export const GET_BOR_STATUS_DROPDOWN_BY_CODE_SUCCESS =
  'uprince/GET_BOR_STATUS_DROPDOWN_BY_CODE_SUCCESS';
export const GET_BOR_STATUS_DROPDOWN_BY_CODE_FAIL =
  'uprince/GET_CPC_BOR_STATUS_DROPDOWN_BY_CODE_FAIL';

export const BOR_STATUS_DROPDOWN_UPDATE = 'uprince/BOR_STATUS_DROPDOWN_UPDATE';
export const BOR_STATUS_DROPDOWN_UPDATE_SUCCESS =
  'uprince/BOR_STATUS_DROPDOWN_UPDATE_SUCCESS';
export const BOR_STATUS_DROPDOWN_UPDATE_FAIL =
  'uprince/BOR_STATUS_DROPDOWN_UPDATE_FAIL';

export const BOR_STATUS_DROPDOWN_CREATE = 'uprince/BOR_STATUS_DROPDOWN_CREATE';
export const BOR_STATUS_DROPDOWN_CREATE_SUCCESS =
  'uprince/BOR_STATUS_DROPDOWN_CREATE_SUCCESS';
export const BOR_STATUS_DROPDOWN_CREATE_FAIL =
  'uprince/BOR_STATUS_DROPDOWN_CREATE_FAIL';

// project
export const PROJECT_TEAM_ROLE_DROPDOWN_READ = 'uprince/PROJECT_TEAM_ROLE_DROPDOWN_READ';
export const PROJECT_TEAM_ROLE_DROPDOWN_READ_SUCCESS = 'uprince/PROJECT_TEAM_ROLE_DROPDOWN_READ_SUCCESS';
export const PROJECT_TEAM_ROLE_DROPDOWN_READ_FAIL = 'uprince/PROJECT_TEAM_ROLE_DROPDOWN_READ_FAIL';


// common
export const DROPDOWN_TRANSLATION_READ = 'uprince/DROPDOWN_TRANSLATION_READ';
export const DROPDOWN_TRANSLATION_READ_SUCCESS = 'uprince/DROPDOWN_TRANSLATION_READ_SUCCESS';
export const DROPDOWN_TRANSLATION_READ_FAIL = 'uprince/DROPDOWN_TRANSLATION_READ_FAIL';

export const DROPDOWN_TRANSLATION_CREATE = 'uprince/DROPDOWN_TRANSLATION_CREATE';
export const DROPDOWN_TRANSLATION_CREATE_SUCCESS = 'uprince/DROPDOWN_TRANSLATION_CREATE_SUCCESS';
export const DROPDOWN_TRANSLATION_CREATE_FAIL = 'uprince/DROPDOWN_TRANSLATION_CREATE_FAIL';

export const DROPDOWN_TRANSLATION_DELETE = 'uprince/DROPDOWN_TRANSLATION_DELETE';
export const DROPDOWN_TRANSLATION_DELETE_SUCCESS = 'uprince/DROPDOWN_TRANSLATION_DELETE_SUCCESS';
export const DROPDOWN_TRANSLATION_DELETE_FAIL = 'uprince/DROPDOWN_TRANSLATION_DELETE_FAIL';



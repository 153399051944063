import React from 'react';
import {
  DefaultPalette,
  DetailsListLayoutMode,
  Fabric,
  FontIcon,
  IColumn,
  IStackStyles,
  IStackTokens,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Sticky,
  StickyPositionType,
  Text,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { mergeStyles, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { getProject } from '../../../shared/util';
import { Selection } from '@fluentui/react/lib/DetailsList';
import '../../../shared/kanban/kanban.css';
import DragBox from '../../../shared/kanban/dragBox';
import moment from 'moment';

const iconClass = mergeStyles({
  fontSize: 14,
  height: 14,
  width: 14,
  margin: '0 5px',
});
export const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 25vh)!important',
    position: 'relative',
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: {
    margin: 0,
  },
  row: {
    display: 'inline-block',
  },
  deepSkyBlue: [{ color: 'deepskyblue' }, iconClass],
  red: [{ color: 'red' }, iconClass],
});

const wrapStackTokens: IStackTokens = { childrenGap: 2 };
const itemStyles: React.CSSProperties = {
  alignItems: 'center',
  background: DefaultPalette.themePrimary,
  color: DefaultPalette.white,
  display: 'flex',
  height: 50,
  justifyContent: 'center',
  width: 50,
};

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
  },
};
const ListPaneComponent = (props: {
  listPaneItems: any;
  handleListPaneCpcItemClick: (id: string) => void;
  handleListPaneHrItemClick: (id: string) => void;
  isDataLoaded?: boolean;
  loadMsg: string;
  dropDownOptions: any;
}) => {
  const { t } = useTranslation();
  const [selection, setSelection] = React.useState<Selection | undefined>();
  const columns = [
    {
      key: 'column1',
      name: t('date'),
      fieldName: 'day',
      minWidth: 100,
      maxWidth: 125,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('workers'),
      fieldName: 'workers',
      minWidth: 200,
      maxWidth: 1200,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('vehicles'),
      fieldName: 'vehicle',
      minWidth: 100,
      maxWidth: 400,
      isResizable: true,
    },
  ];

  const getDropdownWithDefualt = (options: any) => {
    if (options) {
      let defualtOption = [
        {
          key: '0',
          text: t('all'),
          isDefault: true,
        },
      ];
      Array.prototype.push.apply(defualtOption, options);
      return defualtOption;
    } else {
      return [];
    }
  };

  // useEffect(() => {
  //   setFilter(props.filter)
  // }, [props.filter])


  const resourceList = [
    {
      day: '01/11',
      workers: [
        { id: '1232', name: 'Pradeep Madusanka' },
        { id: '1234', name: 'Shanuka gayashan' },
      ],
      vehicles: [
        { id: '1235', cpcId: 'PAr01-0364', name: 'vehicle 1' },
        { id: '1236', cpcId: 'PAr01-0413', name: 'vehicle 2' },
      ],
    },
    {
      day: '02/11',
      workers: [
        { id: '1237', name: 'Mesith nalaka' },
        { id: '1238', name: 'supun costha' },
      ],
      vehicles: [
        { id: '1239', cpcId: 'PAr01-0683', name: 'vehicle 3' },
        { id: '12310', cpcId: 'PAr01-0212', name: 'vehicle 4' },
      ],
    },
  ];

  const onClickItem = (item: any) => {
    if (item.resourceNumber) {
      props.handleListPaneCpcItemClick(item.resourceNumber);
    } else if (item.sequenceId) {
      props.handleListPaneHrItemClick(item.sequenceId);
    }
  };

  const renderTableRow = (resourceList: any) => {
    return resourceList.map((list: any) => {
      return <tr>
        <td>{list.day}</td>
        <td>
          <div>
            {/*{list.workers.map((worker:any)=>{*/}
            {/*return <DragBox item={worker} name={worker?.name} icon={ <FontIcon aria-label="Compass" iconName="Contact" className={classNames.deepSkyBlue} />}/>*/}
            {/*})}*/}
          </div>
        </td>
        <td>
          {/*{list.vehicle.map((vehicles:any)=>{*/}
          {/*return <DragBox  onClick={(item:any)=>onClickItem(item)} item={vehicles} name={vehicles?.name} icon={ <FontIcon aria-label="Compass" iconName="DeliveryTruck" className={classNames.deepSkyBlue} />}></DragBox>*/}
          {/*})}*/}
        </td>
      </tr>;
    });
  };

  const formatwithoutTime = (date: moment.MomentInput) => {
    const formatted = moment.utc(date).local().format('dddd MMM DD');
    return formatted === 'Invalid date' ? '' : formatted;
  };
  const resourceListColumn = (item: any, index?: number, column?: IColumn) => {
    if (column && column.fieldName && (column.fieldName === 'workers')) {
      const fieldContent = item[column.fieldName];
      return fieldContent ? <span style={{ paddingLeft: 9 }} onClick={() => {

      }}>
           <Stack horizontal wrap styles={stackStyles} tokens={wrapStackTokens}>
            {fieldContent.map((worker: any) => {
              return <div key={Math.random()}><DragBox disabled={worker.absence ? true : !getProject()}
                                                       onClick={(item: any) => onClickItem(item)}
                                                       item={{ ...worker, day: item['day'] }} name={worker?.name}
                                                       icon={<FontIcon aria-label="Compass" iconName="Contact"
                                                                       className={worker.absence ? classNames.red : classNames.deepSkyBlue}/>}/>
              </div>;
            })}
           </Stack>
        </span> : <span style={{ paddingLeft: 9 }}>-</span>;
    } else if (column && column.fieldName && (column.fieldName === 'vehicle')) {
      const fieldContent = item[column.fieldName];
      return fieldContent ? <span style={{ paddingLeft: 9 }} onClick={() => {

      }}> <Stack horizontal wrap styles={stackStyles} tokens={wrapStackTokens}>
            {fieldContent.map((vehicle: any) => {
              return <div key={Math.random()}><DragBox disabled={!getProject()}
                                                       onClick={(item: any) => onClickItem(item)}
                                                       item={{ ...vehicle, day: item['day'] }} name={vehicle?.title}
                                                       icon={<FontIcon aria-label="Compass" iconName="DeliveryTruck"
                                                                       className={classNames.deepSkyBlue}/>}/></div>;
            })}
           </Stack></span> : <span style={{ paddingLeft: 9 }}>-</span>;
    } else if (column && column.fieldName && (column.fieldName === 'day')) {
      const fieldContent = item[column.fieldName];
      return fieldContent ? <span style={{ paddingLeft: 9 }} onClick={() => {

      }}>{formatwithoutTime(fieldContent)}</span> : <span style={{ paddingLeft: 9 }}>-</span>;
    } else if (column && column.fieldName) {
      const fieldContent = item[column.fieldName];
      return fieldContent ? <span style={{ paddingLeft: 9 }} onClick={() => {

      }}>{fieldContent}</span> : <span style={{ paddingLeft: 9 }}>-</span>;
    }
    return <span style={{ paddingLeft: 9 }}>-</span>;
  };

  const displayMessage = () => {
    return (
      <div style={{ paddingTop: 36, paddingLeft: 20 }}>
        <Text>{t(props.loadMsg)}</Text>
      </div>
    );
  };
  return (
    <Fabric>
      <div className={`wrapper-holder ${classNames.wrapper}`}>
        {/*<pre>  {JSON.stringify(props.listPaneItems,0,2)}</pre>*/}
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <ShimmeredDetailsList
            items={props.listPaneItems ? props.listPaneItems : []}
            columns={columns}
            setKey="set"
            enableShimmer={!props.isDataLoaded}
            layoutMode={DetailsListLayoutMode.justified}
            selectionPreservedOnEmptyClick={true}
            checkboxVisibility={1}
            selection={selection}
            selectionMode={SelectionMode.none}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            onRenderDetailsHeader={(headerProps, defaultRender) => {
              if (defaultRender !== undefined) {
                return (
                  <Sticky
                    stickyPosition={StickyPositionType.Header}
                    isScrollSynced={true}
                    stickyBackgroundColor="transparent"
                  >
                    <div>{defaultRender(headerProps)}</div>
                  </Sticky>
                );
              } else {
                return <span></span>;
              }
            }}
            onRenderItemColumn={(
              item: any,
              index?: number,
              column?: IColumn,
            ) =>
              resourceListColumn(
                item,
                index,
                column,
              )
            }
          />
          {/*<table className={'kanbanTable'} style={{width:'100%'}} >*/}
          {/*<tr>*/}
          {/*<td>date</td>*/}
          {/*<td>Workers</td>*/}
          {/*<td>Vehicles</td>*/}
          {/*</tr>*/}
          {/*<tbody>{renderTableRow(props.listPaneItems)}</tbody>*/}
          {/*</table>*/}
          {props.isDataLoaded && displayMessage()}
        </ScrollablePane>
      </div>
    </Fabric>
  );
};

export default ListPaneComponent;

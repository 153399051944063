import React, { useEffect } from 'react';
import { IImageProps, Image, ImageFit, mergeStyleSets, MessageBar, MessageBarType } from '@fluentui/react';
import { uPrinceTheme } from '../../../theme';
import ENV from '../../../env.json';
import RfqHeader from './rfqHeader';
import ProgressBar from '../../shared/progressBar/progressBar';
import RfqSupplier from './rfqSupplier';
import RfqDelivery from './rfqDelivery';
import RfqDocumentSign from './rfqDocumentSign';
import { getRfqInvitationDetails, savePoAtrr } from '../../reducers/purchaseOrderReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { store } from '../../../index';
import i18n from '../../../i18n';
import RfqExternalProduct from './rfqExternalProduct';
import { WF_IN_APPROVED_STATUS_ID } from '../../types/purchaseOrder';
import RfqMobileView from './rfqMobileView';

const classNames = mergeStyleSets({
  body: {
    height:'100%',minHeight:'100vh',backgroundColor:'#e9e9e9',width: '100%'
  },
 navBar:{
   height:40,width: '100%'
 },
 container:{
   height:'auto',minHeight:'calc(100vh - 142px)',width:'60%',margin:'auto',padding:10
 },
 logo: {
    display: 'flex',
    backgroundColor:'#ffffff',
    paddingBottom:25,
  },
  logoImg: {
   marginTop: '20px',
   marginLeft: 'auto',
   marginRight: 'auto',
  },
});

const RfqInvitation=()=>{
  const dispatch = useDispatch();
  const params: any = useParams();

  const imagePropsNav: Partial<IImageProps> = {
    imageFit: ImageFit.centerContain,
    width: 40,
    height: 40,
  };
  const imageProps: Partial<IImageProps> = {
    width: '40%',
  };

  const [primaryColor, setPrimaryColor] = React.useState<any>(uPrinceTheme.palette.themePrimary);
  const [navLogo, setNavLogo] = React.useState<any>(ENV.IMG_LOGO);
  const [isLoading, setIsLoading] = React.useState<any>(false);

  const {
    showRfqAlert,
  } = useSelector(
    (state: any) => state.po
  );

  useEffect(()=>{
    dispatch(savePoAtrr('showRfqAlert',false))
    setIsLoading(true)
    setTimeout(()=>{
      setIsLoading(false)
    },2000)
    dispatch(getRfqInvitationDetails(params?.featureId))

    return ()=>{
      dispatch(savePoAtrr('rfqFormData',null))
      dispatch(savePoAtrr('showRfqAlert',false))
    }
  },[])

  const navBar=()=>{
    return <div className={classNames.navBar} style={{backgroundColor:primaryColor}}>
      <Image
        {...imagePropsNav}
        className="brandicon"
        src={
          navLogo
            ? navLogo
            : ''
        }
        // style={styles.hoverText}
        alt='Example of the image fit value "centerContain" on an image wider than the frame.'
      />
    </div>
  }

  const isMobile = screen.width < 768;

  const container=()=>{
    if(store.getState().po?.rfqFormData?.isLinkExpired){
      return <div className={classNames.container} style={isMobile ? {paddingTop: 30} : {}}>
        <div className={'document-pane-card'}>
          <div  className={classNames.logo}>
            <span style={isMobile ? { fontSize: 30, margin: 'auto', paddingTop: 30} : {margin:'auto',paddingTop:20}}> {i18n.t('linkExpired')}</span>
          </div>
        </div>
      </div>
    }else if(store.getState().po?.rfqFormData?.status?.key===WF_IN_APPROVED_STATUS_ID){
      return  <div className={classNames.container} style={isMobile ? {paddingTop: 30} : {}}>
        <div className={'document-pane-card'}>
          <div  className={classNames.logo}>
            <span style={isMobile ? { fontSize: 30, margin: 'auto', paddingTop: 30} : {margin:'auto',paddingTop:20}}> {i18n.t('alreadyRespondedCheckMail')}</span>
          </div>
        </div>
      </div>
    }else{

      if (isMobile) {
        return <div><RfqMobileView/></div>
      } else {
      return <div className={classNames.container}

    style={
        {width: '99%', maxWidth: '1024px', alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', display: 'flex', flexDirection: 'column'}
      }
      >
        <div className={'document-pane-card'}>
          <div  className={classNames.logo}>
            <Image
              {...imageProps}
              className={classNames.logoImg}
              src={
                ENV.IMG_PDF
                  ? ENV.IMG_PDF
                  : ''
              }
              style={{marginRight:'auto',marginLeft:'auto'}}
              alt='Example of the image fit value "centerContain" on an image wider than the frame.'
            />
          </div>
        </div>
        <div className={'document-pane-card'}>
          <RfqHeader/>
        </div>
        <div className={'document-pane-card'}>
          <RfqSupplier/>
        </div>
        <div className={'document-pane-card'}>
          <RfqDelivery/>
        </div>
        <div className={'document-pane-card'}>
          <RfqExternalProduct/>
        </div>
        <div className={'document-pane-card'}>
          <RfqDocumentSign/>
        </div>
      </div>
    }}
    }


  return <div className={classNames.body}>
    {navBar()}
    {isLoading?<div><ProgressBar show={true} /></div>:<div>
      <div style={{position:'sticky',top:0}}><ProgressBar show={store.getState().po?.showProgressBar} /></div>
      <div style={{position:'sticky',top: 0,zIndex: 1000}}>
        {showRfqAlert && <MessageBar
          style={isMobile ? {fontSize: 30, padding: 10} : undefined}
          messageBarType={MessageBarType.success}
          isMultiline={false}
          dismissButtonAriaLabel="Close"
          styles={isMobile ? {icon : {fontSize: 25, padding: 10}} : undefined}
        >
          {i18n.t('mailSendSuccess')}
        </MessageBar>}
      </div>
    {store.getState().po?.rfqFormData && container()}
      </div>}
  </div>
}

export default RfqInvitation
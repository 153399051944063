import { BorListPaneItem, FilterItem, ShortCutPaneItem } from '../../../../types/billOfResources';
import { withTranslation, WithTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { IColumn, Selection } from '@fluentui/react/lib/DetailsList';
import { getProject } from '../../../../shared/util';
import ListPaneComponent from './component';
import { getBorPoListPaneData, getBorPoResourcesListPaneData } from '../../../../reducers/purchaseOrderReducer';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getShortcutPaneFilterList } from '../../../../reducers/billOfResourcesReducer';
import groupArray from 'group-array';
import { PoResourceFilterItem, PoResourceListPaneItem } from '../../../../types/purchaseOrder';


export interface State {
  filter: FilterItem;
  announcedMessage: string;
  selectedListItemIds: [] | string[];
  selectedListItemSequenceCode: [] | string[];
  selectedListItems: BorListPaneItem[];
  borSelectedShortCutPaneItem: ShortCutPaneItem;
  currentActiveSection: string | null;
  resourceFilter: PoResourceFilterItem;
  selectedResourceListItems: PoResourceListPaneItem[];
  borResourceListPaneItem: PoResourceListPaneItem[];
}

export interface Props extends WithTranslation {
  isDataLoaded: boolean;
  isOpen: boolean;
  isChange: boolean;
  openModal: () => void;
  getBorPoListPaneData: (filterItem: FilterItem) => any;
  getBorPoResourcesListPaneData: (filterItem: PoResourceFilterItem) => any;
  borListPaneItem: BorListPaneItem[];
  borResourceListPaneItem: PoResourceListPaneItem[];
  loadMsgBor: string;
  setSelectedBORIds: (ids: string[]) => void;
  selectedBORIds: [];
  selectedProject: any;
  getShortcutPaneFilterList: () => void;
  shortCutPaneFilters: ShortCutPaneItem[];
  setSelectedResources: (borResources: any, resourcesItem: any) => void
}

class CuBorModal extends Component<Props, State> {
  private _borSelection: Selection;
  private _resourceSelection: Selection;
  private _borColumns: IColumn[];
  private _resourceColumns: IColumn[];
  subscription: any;

  constructor(props: Props) {
    super(props);
    const { t } = this.props;
    this._borSelection = new Selection({
      onSelectionChanged: () => {
        this.setState(
          {
            selectedListItemIds: this._getSelectedIds(),
            selectedListItemSequenceCode: this._getselectedSequenceCode(),
            selectedListItems: this._getSelectedListItems(),
          },
          this.handleSelectedItem,
        );
      },

      //canSelectItem:this.canSelectItem.bind(this)
    });


    this._resourceSelection = new Selection({
      onSelectionChanged: () => {
        this.setState(
          {
            selectedListItemIds: this._getResourceSelectedIds(),
            selectedListItemSequenceCode: this._getselectedSequenceCode(),
            selectedResourceListItems: this._getResourceSelectedListItems(),
          },
          this.handleSelectedItem,
        );
      },

      //canSelectItem:this.canSelectItem.bind(this)
    });

    this.state = {
      borSelectedShortCutPaneItem: { key: null, id: null, name: 'Resource', type: 'Resource' },
      selectedResourceListItems: [],
      selectedListItems: [],
      selectedListItemIds: [],
      selectedListItemSequenceCode: [],
      borResourceListPaneItem: [],
      announcedMessage: '',
      currentActiveSection: '0',
      filter: {
        borTitle: null,
        product: null,
        locationParent: null,
        utilityParent: null,
        sorter: {
          attribute: null,
          order: null,
        },
      },

      resourceFilter: {
        borTitle: null,
        pbsTitle: null,
        resourceTitle: null,
        resourceFamily: null,
        resourceTypeId: null,
        sorter: {
          attribute: null,
          order: null,
        },
      },
    };

    this._borColumns = [
      {
        key: 'column1',
        name: t('borTitle'),
        fieldName: 'borTitle',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        onColumnClick: this._borOnColumnClick,
      },
      {
        key: 'column2',
        name: t('product'),
        fieldName: 'product',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._borOnColumnClick,
      },
      {
        key: 'column3',
        name: t('utilityTaxonomy'),
        fieldName: 'utilityParent',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._borOnColumnClick,
      },
      {
        key: 'column4',
        name: t('location'),
        fieldName: 'locationParent',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._borOnColumnClick,
      },
    ];

    this._resourceColumns = [
      {
        key: 'column1',
        name: t('borTitle'),
        fieldName: 'borTitle',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        onColumnClick: this._resourceOnColumnClick,
      },
      {
        key: 'column2',
        name: t('resourceType'),
        fieldName: 'resourceType',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._resourceOnColumnClick,
      },
      {
        key: 'column3',
        name: t('resourceFamily'),
        fieldName: 'resourceFamily',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._resourceOnColumnClick,
      },
      {
        key: 'column4',
        name: t('resourceTitle'),
        fieldName: 'cTitle',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._resourceOnColumnClick,
      },
      {
        key: 'column5',
        name: t('pbsTitle'),
        fieldName: 'pbsTitle',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._resourceOnColumnClick,
      },
    ];

  }

  componentDidMount() {
    // this.getListPaneData();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.isChange != this.props.isChange) {
      if (this.props.isChange) {
        this.getListPaneData();
      }
    }
    if (prevProps.isOpen != this.props.isOpen) {
      if (this.props.isOpen) {
        this.props.getShortcutPaneFilterList();
        this.getListPaneData();
      }
    }
    if (prevProps.borResourceListPaneItem != this.props.borResourceListPaneItem) {
      this.setState({
        borResourceListPaneItem: this.props.borResourceListPaneItem,
      });
    }
  }

  private canSelectItem(item: any): boolean {
    return item.id !== '503363cb-c5e3-43c9-af04-f6258a17a543';
  }

  formatSelectedResourceAsGroup = (resources: any[]) => {

    let borResources: any = resources.map((item) => {
      return {
        ...item,
        date: '2021-06-11T05:25:32.9048237',
        cTitle: item?.cucpcTitle ? item?.cucpcTitle : item?.cTitle,
        isUsed: true,
      };
    });
    borResources = groupArray(borResources, 'resourceName');
    borResources = {
      materials: borResources.Materials ? borResources.Materials : [],
      tools: borResources.Tools ? borResources.Tools : [],
      consumable: borResources.Consumables ? borResources.Consumables : [],
      labour: borResources['Human Resources'] ? borResources['Human Resources'] : [],
    };
    return borResources;
  };

  handleResourceFilterChange = (resourceFilter: PoResourceFilterItem) => {
    //this.props.handelResourceFilter(filter);
    this.setState({
      resourceFilter,
    }, this.getListPaneData);
  };

  render() {
    return (
      <div>
        <ListPaneComponent
          isOpen={this.props.isOpen}
          openModal={this.props.openModal}
          resetSelection={this.resetSelection}
          onConfirmSelection={this.onConfirmSelection}
          isDataLoaded={this.props.isDataLoaded}
          borColumns={this._borColumns}
          resourceColumns={this._resourceColumns}
          borListPaneItem={this.props.borListPaneItem}
          filter={this.state.filter}
          resourceFilter={this.state.resourceFilter}
          loadMsg={this.props.loadMsgBor}
          handleFilterChange={(filter: FilterItem) => {
            this.handleFilterChange(filter);
          }}
          handelShortCutPaneFilter={(selectedItem: ShortCutPaneItem) => {
            this.handelShortCutPaneFilter(selectedItem);
          }}
          currentActiveSection={this.state.currentActiveSection}
          shortCutPaneFilters={this.props.shortCutPaneFilters}
          dropDownOptions={[]}
          handleResourceFilterChange={(filter: PoResourceFilterItem) =>
            this.handleResourceFilterChange(filter)
          }
          selection={this._borSelection}
          resourceSelection={this._resourceSelection}
          resourceListPaneItem={this.state.borResourceListPaneItem}
          selectedShortCutItem={this.state.borSelectedShortCutPaneItem}
        />
      </div>
    );
  }


  private handelShortCutPaneFilter = (selectedItem: ShortCutPaneItem) => {
    if (selectedItem.type === 'Resource') {
      this.setState({
        resourceFilter: { ...this.state.resourceFilter, resourceTypeId: selectedItem.id },
      });
    } else if (selectedItem.type === 'AllResource') {
      this.setState({
        resourceFilter: { ...this.state.resourceFilter, resourceTypeId: null },
      });
    }
    this.setState({
      currentActiveSection: selectedItem.id,
      borSelectedShortCutPaneItem: selectedItem,
    }, this.getListPaneData);

  };


  private getListPaneData = () => {
    let filter: any = this.state.filter;
    let resourceFilter: any = this.state.resourceFilter;
    if (this.props.selectedProject) {
      filter.project = getProject() ? getProject() : this.props.selectedProject;
      resourceFilter.project = getProject() ? getProject() : this.props.selectedProject;
    }
    if (this.state.borSelectedShortCutPaneItem.type === 'Bor') {
      this.props.getBorPoListPaneData(filter).then(() => {
        if (this.props.selectedBORIds && this.props.selectedBORIds.length > 0) {
          this.props.selectedBORIds.forEach((item) => {
            this._borSelection.setKeySelected(item, true, false);
          });
        }
      });
    } else {
      this.props.getBorPoResourcesListPaneData(resourceFilter).then(() => {
        if (this.props.selectedBORIds && this.props.selectedBORIds.length > 0) {
          this.props.selectedBORIds.forEach((item: any) => {
            this._resourceSelection.setKeySelected(item?.id, true, false);
          });
        }
      });
    }

  };


  private handleSelectedItem = () => {

  };


  private resetSelection = () => {
    this.setState({
      filter: {
        borTitle: null,
        product: null,
        locationParent: null,
        utilityParent: null,
        sorter: {
          attribute: null,
          order: null,
        },
      },
      resourceFilter: {
        borTitle: null,
        pbsTitle: null,
        resourceTitle: null,
        resourceFamily: null,
        resourceTypeId: null,
        sorter: {
          attribute: null,
          order: null,
        },
      },
    }, this.getListPaneData);
    this._borSelection.setAllSelected(false);
    this.props.setSelectedBORIds([]);
  };

  private onConfirmSelection = () => {
    if (this.state.borSelectedShortCutPaneItem.type === 'Bor') {
      console.log('_getSelectedIds', this._getSelectedIds());
      console.log('_getselectedSequenceCode', this._getselectedSequenceCode());
      this.props.setSelectedBORIds(this._getSelectedIds());
    } else {
      console.log('_getResourceSelectedListItems', this._getResourceSelectedListItems());

      this.props.setSelectedResources(this.formatSelectedResourceAsGroup(this._getResourceSelectedListItems()), this._getResourceSelectedListItems());
    }
    this.props.openModal();
  };

  private handleListPaneItemClick = (id: string) => {

  };


  private handleFilterSortChange = () => {
    this.getListPaneData();
  };

  handleFilterChange = (filter: FilterItem) => {
    this.setState({
      filter,
    }, this.getListPaneData);
  };


  private _getSelectedIds() {
    let deleteList: any = [];
    let SelectedItem = this._borSelection.getSelection() as BorListPaneItem[];
    if (SelectedItem) {
      deleteList = SelectedItem.map((item: any) => {
        return item ? item.id : null;
      });
    }
    return deleteList;
  }

  private _getResourceSelectedIds() {
    let deleteList: any = [];
    let SelectedItem = this._resourceSelection.getSelection() as BorListPaneItem[];
    if (SelectedItem) {
      deleteList = SelectedItem.map((item: any) => {
        return item ? item.id : null;
      });
    }
    return deleteList;
  }

  private _getselectedSequenceCode() {
    let deleteList: any = [];
    let SelectedItem = this._borSelection.getSelection() as BorListPaneItem[];
    if (SelectedItem) {
      deleteList = SelectedItem.map((item: any) => {
        return item ? item.itemId : null;
      });
    }
    return deleteList;
  }

  private _getSelectedListItems = () => {
    let SelectedListItems = this._borSelection.getSelection() as BorListPaneItem[];

    return SelectedListItems;
  };


  private _getResourceSelectedListItems = () => {
    let SelectedListItems = this._resourceSelection.getSelection() as PoResourceListPaneItem[];

    return SelectedListItems;
  };

  private _borOnColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn,
  ): void => {
    const newColumns: IColumn[] = this._borColumns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key,
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        let filter = this.state.filter;

        let fieldName = currColumn.fieldName;

        let sortby = currColumn.isSortedDescending ? 'desc' : 'asc';
        filter.sorter.attribute = fieldName ? fieldName : null;
        filter.sorter.order = sortby ? sortby : null;
        this.setState(
          {
            filter: filter,
          },
          this.handleFilterSortChange,
        );
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
  };

  private _resourceOnColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn,
  ): void => {
    const newColumns: IColumn[] = this._resourceColumns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key,
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        let filter = this.state.resourceFilter;

        let fieldName = currColumn.fieldName;

        let sortby = currColumn.isSortedDescending ? 'desc' : 'asc';
        filter.sorter.attribute = fieldName ? fieldName : null;
        filter.sorter.order = sortby ? sortby : null;
        this.setState(
          {
            resourceFilter: filter,
          },
          this.handleFilterSortChange,
        );
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
  };

}


const mapStateToProps = (state: any, props: any) => {
  return {
    toleranceStatesFilter: state.billOfResource.toleranceStatesFilter,
    productStatesFilter: state.billOfResource.productStatesFilter,
    itemTypesFilter: state.billOfResource.itemTypesFilter,
    borListPaneItem: state.po.borListPaneItem,
    borResourceListPaneItem: state.po.borResourceListPaneItem,
    loadMsgBor: state.po.loadMsgBor,
    isDataLoaded: state.po.isDataLoadedBor,
    isChange: state.po.isChange,
    shortCutPaneFilters: state.billOfResource.shortCutPaneFilters,
  };
};

const mapDispatchToProps = {
  getBorPoListPaneData,
  getShortcutPaneFilterList,
  getBorPoResourcesListPaneData,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CuBorModal)),
);

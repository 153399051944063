import * as React from 'react';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  CheckboxOnChangeData,
} from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import DefaultCustomModal from '../../../../shared/customeModal/defaultCustomModal';
import { Field, Form, FormSpy } from 'react-final-form';
import { TextFieldFF } from '../../../../shared/fluentFinalForm';
import { OnBlur, OnFocus } from 'react-final-form-listeners';
import { UPLOAD_URL } from '../../../../types/contract';
import { v4 as uuid4 } from 'uuid';
import {
  saveInstructionStateAttr,
  uploadInstructionDoc,
  uploadWBSharePointDoc,
} from '../../../../reducers/instructionRegisterReducer';
import {
  FontWeights,
  getTheme,
  IIconProps,
  mergeStyleSets,
  PrimaryButton,
} from '@fluentui/react';
import { getFileType } from '../../../../shared/util';
import { useDispatch, useSelector } from 'react-redux';
import { uPrinceTheme } from '../../../../../theme';

const theme = getTheme();
const contentStyles = mergeStyleSets({
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
});
const addWhiteIconButtonStyles = {
  root: { color: uPrinceTheme.palette.white },
  rootHovered: { color: theme.palette.neutralDark },
};
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};
const uploadIconWhite: IIconProps = {
  iconName: 'Upload',
  styles: addWhiteIconButtonStyles,
};

const AddDocument = (props: { onChange: any }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [dragging, setDragging] = useState(false)
  const ref: any = useRef(null);
  const inputFile: any = useRef(null);
  const [folderId, setFolderid] = useState(null);
  const classNames = mergeStyleSets({
    fullWidth: { width: '100%', marginTop: 20 },
    halfWidth: { width: '49%' },
  });

  const taskData = useSelector((state: any) => state.wbsTask.formData);

  const {
    uploadedInstructionLink,
    showProgressBar,
    uploadedSharePointLink,
  }: any = useSelector((state: any) => state.ir);

  const onSubmit = async (values: any) => {
    console.log({ values });
    const sleep = (ms: number | undefined) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);
    if (!values.id) {
      values.id = uuid4();
    }
    values.value = values.link;
    values.sharepointId = folderId;
    props.onChange(values);
    setIsOpen(false);
  };

  const onRenderFooter = () => {};

  useEffect(() => {
    if (uploadedInstructionLink && ref?.current) {
      ref?.current.mutators.setDocumentLink(uploadedInstructionLink);
      dispatch(saveInstructionStateAttr('uploadedInstructionLink', null));
    }
  }, [uploadedInstructionLink]);

  useEffect(() => {
    if (uploadedSharePointLink && ref?.current) {
      ref?.current.mutators.setDocumentLink(uploadedSharePointLink?.link);
      dispatch(saveInstructionStateAttr('uploadedSharePointLink', null));
    }
  }, [uploadedSharePointLink]);

  const getInitialValues = () => {
    return {
      id: null,
      title: null,
      // type: null,
      link: null,
      isSharepoint: false,
      sharepointId: null,
    };
  };

  // Functions for drag and drop
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy
    setDragging(true);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>, values: any) => {
    e.preventDefault();
    setDragging(false);
    // const droppedFiles = Array.from(e.dataTransfer.files);
    const formData = new FormData();
    if (e?.dataTransfer?.files[0]) {
      if (values?.isSharepoint) {
        formData.append('file', e?.dataTransfer?.files[0]);
        formData.append(
          'uploadWbsDocumentDto',
          JSON.stringify({
            projectTitle: taskData?.projectTitle,
            Product: taskData?.productId,
            WBS: taskData?.wbs,
          })
        );
        //dispatch(uploadWBSharePointDoc(formData));
        Promise.all([
          dispatch(uploadWBSharePointDoc(formData)),
        ]).then((res: any) => {
          if (Array.isArray(res) && res?.length > 0) {
            setFolderid(
              res[0]?.payload?.data?.result?.folderId
            );
          }
        });
      } else {
        formData.append('file', e?.dataTransfer?.files[0]);
        formData.append('id', uuid4());
        dispatch(uploadInstructionDoc(formData));
        setFolderid(null);
      }

      // console.log("TARGET", event.target.files[0].type);
      // form.mutators.setDocumentType(getFileType(event.target.files[0].type));
    }
  };

  return (
    <>
      <DefaultCustomModal
        onRenderFooter={onRenderFooter}
        isModalOpen={isOpen}
        title={t('document')}
        handleClose={() => setIsOpen(false)}
      >
        <>
          <div style={{ width: '100%' }}>
            <Form
              //form={formRef.current}
              onSubmit={onSubmit}
              initialValues={getInitialValues()}
              mutators={{
                // setTypeName: (args, state, utils) => {
                //     const field = state.fields.typeName;
                //     field.change(args[0]);
                // },
                setDocumentLink: (args, state, utils) => {
                  const field = state.fields.link;
                  field.change(args[0]);
                },
                setDocumentType: (args, state, utils) => {
                  const field = state.fields.type;
                  field.change(args[0]);
                },
              }}
              validate={(values) => {
                const errors: any = {};
                if (!values?.link) {
                  errors.link = t('linkRequired');
                }
                return errors;
              }}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
              }) => {
                ref.current = form;
                return (
                  <div>
                    <FormSpy onChange={(spyProps: any) => {}} />

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '30vw',
                        padding: 10,
                      }}
                    >
                      <div style={{ display: 'flex', gap: 5 }}>
                        {/*<div className={`${classNames.halfWidth}`}>*/}
                        {/*  <Field*/}
                        {/*    name="title"*/}
                        {/*    component={TextFieldFF}*/}
                        {/*    lable={t('title')}*/}
                        {/*    required={true}*/}
                        {/*  />*/}
                        {/*</div>*/}
                        {/*<div className={`${classNames.halfWidth}`}>*/}
                        {/*  <Field*/}
                        {/*    name="type"*/}
                        {/*    component={DropDownAdaptater}*/}
                        {/*    options={[*/}
                        {/*      { key: 'PDF', text: 'PDF' },*/}
                        {/*      { key: 'FileImage', text: 'Image' },*/}
                        {/*      { key: 'Website', text: 'URL' },*/}
                        {/*      { key: 'WordDocument', text: 'Word Document' },*/}
                        {/*      { key: 'ExcelDocument', text: 'Excel Document' },*/}
                        {/*      { key: 'PowerPointDocument', text: 'Power Point Document' },*/}
                        {/*    ]}*/}
                        {/*    lable={t('type')}*/}
                        {/*    placeholder={t('type')}*/}
                        {/*    required={true}*/}
                        {/*    disabled={true}*/}
                        {/*  />*/}
                        {/*</div>*/}
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="link"
                            component={TextFieldFF}
                            lable={t('link')}
                            disabled={false}
                            placeholder={t('copyURLhere')}
                            required={true}
                          />
                          <OnFocus name="link">
                            {() => {
                              const sub = values?.link?.substring(0, 30);
                              if (sub && sub !== UPLOAD_URL) {
                                form.mutators.setDocumentType(getFileType(''));
                              }
                            }}
                          </OnFocus>
                          <OnBlur name="link">
                            {() => {
                              const sub = values?.link?.substring(0, 30);
                              if (sub && sub !== UPLOAD_URL) {
                                form.mutators.setDocumentType(getFileType(''));
                              }
                            }}
                          </OnBlur>
                        </div>
                      </div>
                      <Field name="isSharepoint">
                        {({ input, meta }) => (
                          <Checkbox
                            checked={values?.isSharepoint}
                            style={{ marginTop: 12, marginLeft: 0 }}
                            onChange={(
                              ev: ChangeEvent<HTMLInputElement>,
                              data: CheckboxOnChangeData
                            ) => {
                              input.onChange(data?.checked);
                            }}
                            label={t('saveToSharePoint')}
                          />
                        )}
                      </Field>
                    </div>
                    <div className={`dropzone ${dragging ? 'dragging' : ''}`}
                  onDragEnter={handleDragEnter}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={(e) => handleDrop(e, values)}>
                      <input
                        type="file"
                        id="file"
                        ref={inputFile}
                        hidden
                        onChange={(event: any) => {
                          const formData = new FormData();
                          if (event?.target?.files[0]) {
                            if (values?.isSharepoint) {
                              formData.append('file', event.target.files[0]);
                              formData.append(
                                'uploadWbsDocumentDto',
                                JSON.stringify({
                                  projectTitle: taskData?.projectTitle,
                                  Product: taskData?.productId,
                                  WBS: taskData?.wbs,
                                })
                              );
                              //dispatch(uploadWBSharePointDoc(formData));
                              Promise.all([
                                dispatch(uploadWBSharePointDoc(formData)),
                              ]).then((res: any) => {
                                if (Array.isArray(res) && res?.length > 0) {
                                  setFolderid(
                                    res[0]?.payload?.data?.result?.folderId
                                  );
                                }
                              });
                            } else {
                              formData.append('file', event.target.files[0]);
                              formData.append('id', uuid4());
                              dispatch(uploadInstructionDoc(formData));
                              setFolderid(null);
                            }

                            // console.log("TARGET", event.target.files[0].type);
                            // form.mutators.setDocumentType(getFileType(event.target.files[0].type));
                          }
                        }}
                      />
                      <PrimaryButton
                        iconProps={uploadIconWhite}
                        text={t('upload')}
                        disabled={false}
                        style={{ marginTop: 22, marginLeft: 10 }}
                        onClick={() => {
                          inputFile?.current?.click();
                        }}
                      />
                    </div>

                    <div className={contentStyles.footer}>
                      <PrimaryButton
                        iconProps={addIconWhite}
                        text={t('save')}
                        disabled={submitting || pristine}
                        style={{ marginTop: 38, marginBottom: 10 }}
                        onClick={() => {
                          form.submit();
                        }}
                      />
                    </div>
                    {/*<div style={{width:500,height:600,overflow:'auto'}}>*/}
                    {/*  <pre>  {JSON.stringify(values,null,2)}</pre>*/}
                    {/*</div>*/}
                  </div>
                );
              }}
            />
          </div>
        </>
      </DefaultCustomModal>
      <Button
        onClick={() => {
          // messageService.sendMessage({ saveWbsTask: true });   NOTE: put it here due to the issue with tags. commented because it interfere with task model
          setIsOpen(true);
        }}
        size="small"
      >
        {t('document')}
      </Button>
    </>
  );
};

export default AddDocument;

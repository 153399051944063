import {
  READ_RISK_REGISTER_BY_ID_EP,
  READ_RISK_REGISTER_DROPDOWN_EP,
  READ_RISK_REGISTER_LIST_EP,
  READ_RISK_REGISTER_SHORTCUT_PANE_EP,
  RISK_REGISTER_SAVE_EP,
} from '../shared/endpoints';
import {
  CLEAR_RISK_REGISTER_FORM_DATA,
  CLEAR_RISK_REGISTERUID,
  LOAD_RISK_REGISTER_BY_ID,
  LOAD_RISK_REGISTER_BY_ID_FAIL,
  LOAD_RISK_REGISTER_BY_ID_SUCCESS,
  LOAD_RISK_REGISTER_DROPDOWN,
  LOAD_RISK_REGISTER_DROPDOWN_FAIL,
  LOAD_RISK_REGISTER_DROPDOWN_SUCCESS,
  LOAD_RISK_REGISTER_LSITPANE,
  LOAD_RISK_REGISTER_LSITPANE_FAIL,
  LOAD_RISK_REGISTER_LSITPANE_SUCCESS,
  LOAD_RISK_REGISTER_SHORTCUTPANE,
  LOAD_RISK_REGISTER_SHORTCUTPANE_FAIL,
  LOAD_RISK_REGISTER_SHORTCUTPANE_SUCCESS,
  RESET_RISK_REGISTER_IS_CHANGE,
  SAVE_RISK_REGISTER,
  SAVE_RISK_REGISTER_FAIL,
  SAVE_RISK_REGISTER_SUCCESS,
  SET_RISK_REGISTER_UID,
} from '../actionTypes/riskRegisterActionTypes';
import { v4 as uuidv4 } from 'uuid';
import { Risk, RiskRegisterListPaneFilter } from '../types/riskRegister';


const defaultState: any = {
  rrList: [],
  isRRListLoaded: false,
  isNew: false,
  isChange: false,
  isEdit: false,
  showProgressBar: false,
  rrDropDowns: {},
  formData: {},
  uid: null,
  loadMsg: '',
  rrShortCutPaneData: [],
  reloadListPane: false,
  borResources: {},
  isLoadingRiskRegister: false,
  rrId: null,
  listPaneItem: [],
};

export default function riskRegisterReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {
      case LOAD_RISK_REGISTER_SHORTCUTPANE:
        return { ...state };
      case LOAD_RISK_REGISTER_SHORTCUTPANE_SUCCESS:
        return {
          ...state,
          rrShortCutPaneData: action.payload.data.result,
        };
      case LOAD_RISK_REGISTER_SHORTCUTPANE_FAIL:
        return { ...state, rrShortCutPaneData: [] };
      case LOAD_RISK_REGISTER_LSITPANE:
        return {
          ...state,
          loading: true,
          listPaneItem: [],
          isRRListLoaded: false,
          loadMsg: '',
        };
      case LOAD_RISK_REGISTER_LSITPANE_SUCCESS:
        return {
          ...state,
          loading: false,
          isChange: false,
          isRRListLoaded: true,
          reloadListPane: false,
          rrList: action.payload.data.result,
          listPaneItem: formattedPayloadToListPaneTable(action.payload),
          loadMsg: !action.payload.data.status ? action.payload.data.message : '',
          createNewNode: false,
          // ...state,
          // loading: false,
          // isChange: false,
          // listPaneItem: formattedPayloadToListPaneTable(action.payload),
          // loadMsg: !action.payload.data.status ? action.payload.data.message : '',
          // isDataLoaded: true,
          // createNewNode: false
        };
      case LOAD_RISK_REGISTER_LSITPANE_FAIL:
        return {
          ...state,
          isRRListLoaded: true,
          rrList: [],
          loadMsg: action.error.response.data.message,
        };
      case RESET_RISK_REGISTER_IS_CHANGE:
        return {
          ...state,
          isChange: false,
        };

      case LOAD_RISK_REGISTER_DROPDOWN:
        return { ...state, rrDropDowns: {} };
      case LOAD_RISK_REGISTER_DROPDOWN_SUCCESS:
        return {
          ...state, rrDropDowns: formattedPayloadToDropdownList(action.payload.data.result),
        };
      case LOAD_RISK_REGISTER_DROPDOWN_FAIL:
        return {
          ...state,
          rrDropDowns: {},
        };
      case LOAD_RISK_REGISTER_BY_ID:
        return { ...state, formData: {}, showProgressBar: true, isLoadingRiskRegister: true };
      case LOAD_RISK_REGISTER_BY_ID_SUCCESS:
        return {
          ...state,
          isEdit: true,
          formData: action.payload.data.result,
          showProgressBar: false,
          isLoadingRiskRegister: false,
        };
      case LOAD_RISK_REGISTER_BY_ID_FAIL:
        return {
          ...state,
          formData: {},
          showProgressBar: false,
          isLoadingRiskRegister: false,
        };
      //             case CREATE_RISK:
      //     return { ...state, createRiskStatus: false };
      // case CREATE_RISK_SUCCESS:
      //     return {
      //         ...state,
      //         createRiskStatus: true,
      //         saveRiskStatus: false,
      //         newRiskId: action.payload.data.result
      //             ? action.payload.data.result.id
      //             : null
      //     };
      // case CREATE_RISK_FAIL:
      //     return {
      //         ...state,
      //         createRiskStatus: false
      //     };

      case SAVE_RISK_REGISTER:
        return {
          ...state, showProgressBar: true, reloadListPane: false, rrId: null,
          //createRiskStatus: false
        };
      case SAVE_RISK_REGISTER_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          //createRiskStatus: true,
          reloadListPane: true,
          rrId: action.payload.data.result?.sequenceCode,
        };
      case SAVE_RISK_REGISTER_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
          //createRiskStatus: false
          //rrId: action.payload.data.result
        };

      case SET_RISK_REGISTER_UID:
        return {
          ...state,
          isEdit: false,
          uid: uuidv4(),
        };
      case CLEAR_RISK_REGISTERUID:
        return {
          ...state,
          uid: null,
          isChange: false,
        };
      case CLEAR_RISK_REGISTER_FORM_DATA:
        return {
          ...state,
          uid: null,
          isNew: true,
          isEdit: false,
          isChange: false,
          formData: {},
        };

      default:
        return state;
    }
  }
}

export const readRiskRegisterShortCutPaneData = () => {
  return {
    types: [
      LOAD_RISK_REGISTER_SHORTCUTPANE,
      LOAD_RISK_REGISTER_SHORTCUTPANE_SUCCESS,
      LOAD_RISK_REGISTER_SHORTCUTPANE_FAIL,
    ],
    payload: { request: { url: READ_RISK_REGISTER_SHORTCUT_PANE_EP } },
  };
};

export const readRiskRegisterDropDownData = () => {
  return {
    types: [
      LOAD_RISK_REGISTER_DROPDOWN,
      LOAD_RISK_REGISTER_DROPDOWN_SUCCESS,
      LOAD_RISK_REGISTER_DROPDOWN_FAIL,
    ],
    payload: { request: { method: 'GET', url: READ_RISK_REGISTER_DROPDOWN_EP } },
  };
};

// load risk list to list pane
export const filterRiskRegisterListPaneData = (filter: RiskRegisterListPaneFilter) => {
  return {
    types: [
      LOAD_RISK_REGISTER_LSITPANE,
      LOAD_RISK_REGISTER_LSITPANE_SUCCESS,
      LOAD_RISK_REGISTER_LSITPANE_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_RISK_REGISTER_LIST_EP,
        data: filter,
      },
    },
  };
};


export const readByRiskRegisterId = (id: string) => {
  return {
    types: [
      LOAD_RISK_REGISTER_BY_ID,
      LOAD_RISK_REGISTER_BY_ID_SUCCESS,
      LOAD_RISK_REGISTER_BY_ID_FAIL],
    payload: { request: { url: READ_RISK_REGISTER_BY_ID_EP + id } },
  };
};

export const resetIsChange = () => {
  return { type: RESET_RISK_REGISTER_IS_CHANGE };
};

export const createRisk = (data: Risk) => {
  return {
    types: [
      SAVE_RISK_REGISTER,
      SAVE_RISK_REGISTER_SUCCESS,
      SAVE_RISK_REGISTER_FAIL],
    payload: {
      request: {
        url: RISK_REGISTER_SAVE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const setUid = () => {
  return { type: SET_RISK_REGISTER_UID };
};

export const clearFormData = () => {
  return { type: CLEAR_RISK_REGISTER_FORM_DATA };
};

const formattedPayloadToDropdownList = (response: any) => {

  if (response) {

    let type = response.riskTypes.map((item: any) => {
      return {
        key: item.id,
        text: item.type,

      };
    });
    let status = response.riskStatus.map((item: any) => {
      return {
        key: item.id,
        text: item.status,

      };
    });

    return {
      riskTypes: type,
      riskStatus: status,
    };

  } else {
    return {
      riskStatus: [],
      riskTypes: [],
    };
  }
};


const formattedPayloadToListPaneTable = (response: any) => {
  //if (response.data.result && response.data.result.length > 0) {
  if (response) {
    //const data = response.data.result.map((item: any) => {
    const data = response.data.result.map((item: any) => {
      return {

        //key: item.productId,
        //id: item.sequenceCode ? item.sequenceCode : '-',
        id: item.sequenceCode,
        //parentId: item?.parentId,
        //productId: item.productId ? item.productId : '-',
        name: item.name ? item.name : '-',
        title: item.title ? item.title : '-',
        headerTitle: item.headerTitle ? item.headerTitle : '-',
        sequenceCode: item.sequenceCode ? item.sequenceCode : '-',
        person: item.person ? item.person?.fullName : '-',
        riskStatus: item?.riskStatus?.status ? item.riskStatus.status : '-',
        riskType: item?.riskType ? item.riskType.type : '-',
        riskDetails: item.riskDetails ? item.riskDetails : '-',


      };
    });
    return data;
  }
  return [];
};


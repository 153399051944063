import React from 'react';
import {
  FontWeights,
  IButtonStyles,
  IconButton,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useId } from '@uifabric/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import DefaultNumberFormat from '../../../../shared/fluentFinalForm/defaultNumberFormat';
import { MOU_SQUARE_METER } from '../../../../types/projectBreakdownStructure';
import { uPrinceTheme } from '../../../../../theme';
import { getTheme, ITheme } from '@fluentui/react/lib/Styling';
import { saveProjectStateAttr } from '../../../../reducers/projectReducer';
import {addProjectConfiguration}  from '../../../../reducers/cuProjectReducer';

const theme: ITheme = getTheme();

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: { color: theme.palette.neutralDark },
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    minWidth: '20vw',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  fullWidth: { width: '100%' },
  footer: {
    flex: '1 1 auto',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '0px 24px 0px 12px',
    textAlign: 'end',
  },
});

const stackStyles: IStackStyles = { root: { padding: 10, marginBottom: 10 } };

const stackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const ProjectSquareMetersModal = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const titleId = useId('title');

  const { isProjectSqModelOpen } = useSelector(
    (state: any) => state.project,
  );

  const { selectedProject } = useSelector(
    (state: any) => state.project,
  );

  const {
    rfqUnitPrice,
  } = useSelector(
    (state: any) => state.cuProject,
  );

  const onSubmit = async (values: any) => {
    Promise.all([
      dispatch(addProjectConfiguration({ id: null, projectId: selectedProject?.id, unitPrice: values?.rfqUnitPrice })),
    ]).then(() => {
      dispatch(saveProjectStateAttr('isProjectSqModelOpen', !isProjectSqModelOpen))
    });
  };


  const getInitialFormValues = () => {
    return rfqUnitPrice ? { rfqUnitPrice: rfqUnitPrice } : null
  };

  return <div>
    <Modal
      titleAriaId={titleId}
      isOpen={isProjectSqModelOpen}
      onDismiss={() => dispatch(saveProjectStateAttr('isProjectSqModelOpen', !isProjectSqModelOpen))}
      isBlocking={false}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <span id={titleId}>{t('rfqUnitPrice')}</span>
        <IconButton
          styles={cancelIconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel='Close popup modal'
          onClick={() => dispatch(saveProjectStateAttr('isProjectSqModelOpen', !isProjectSqModelOpen))}

        />
      </div>
      <div className={contentStyles.body}>
        <Form
          onSubmit={onSubmit}
          initialValues={getInitialFormValues()}
          // validate={values => {
          //   const errors: any = {};
          //   if (!values?.rfqUnitPrice) {
          //     errors.mou = t('basicUnitOfMeasureRequired');
          //   }
          //   return errors;
          // }}
          render={({ handleSubmit, form, values }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Field
                  name='id'
                  component={'input'}
                  type={'text'}
                  disabled={true}
                  hidden
                />
                <Stack
                  horizontal
                  wrap
                  styles={stackStyles}
                  tokens={stackTokens}
                >
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${contentStyles.fullWidth}`}>
                      <Field name='rfqUnitPrice'>
                        {({ input }) => (
                          <DefaultNumberFormat
                            label={t('rfqUnitPrice') + ' (€/m²)'}
                            value={values?.rfqUnitPrice ? values?.rfqUnitPrice : null}
                            disabled={false}
                            onChange={(value) => {
                              if (value) {
                                input.onChange(value);
                              } else {
                                input.onChange(null);
                              }
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </Stack.Item>

                  <Stack.Item grow={6} styles={stackItemStyles}>
                  </Stack.Item>

                </Stack>

                <div className={contentStyles.footer}>
                  <PrimaryButton
                    iconProps={saveIcon}
                    text={t('save')}
                    style={{ marginBottom: 10 }}
                    onClick={() => {
                      form.submit();
                    }}
                    disabled={false}
                  />
                </div>
              </form>
            );
          }}
        />
      </div>
    </Modal>
  </div>;
};

export default ProjectSquareMetersModal;
export const LOAD_SHORTCUT_PANE_FILTER_LIST = 'uprince/LOAD_SHORTCUT_PANE_FILTER_LIST';
export const LOAD_SHORTCUT_PANE_FILTER_LIST_SUCCESS = 'uprince/LOAD_SHORTCUT_PANE_FILTER_LIST_SUCCESS';
export const LOAD_SHORTCUT_PANE_FILTER_LIST_FAIL = 'uprice/LOAD_SHORTCUT_PANE_FILTER_LIST_FAIL';

export const LOAD_LIST_PANE_LIST = 'uprince/LOAD_LIST_PANE_LIST';
export const LOAD_LIST_PANE_LIST_SUCCESS = 'uprince/LOAD_LIST_PANE_LIST_SUCCESS';
export const LOAD_LIST_PANE_LIST_FAIL = 'uprice/LOAD_LIST_PANE_LIST_FAIL';

export const LOAD_RESOURCE_LIST_PANE_LIST = 'uprince/LOAD_RESOURCE_LIST_PANE_LIST';
export const LOAD_RESOURCE_LIST_PANE_LIST_SUCCESS = 'uprince/LOAD_RESOURCE_LIST_PANE_LIST_SUCCESS';
export const LOAD_RESOURCE_LIST_PANE_LIST_FAIL = 'uprice/LOAD_RESOURCE_LIST_PANE_LIST_FAIL';

export const LOAD_RISK_DROPDOWN = 'uprince/LOAD_RISK_DROPDOWN';
export const LOAD_RISK_DROPDOWN_SUCCESS = 'uprince/LOAD_RISK_DROPDOWN_SUCCESS';
export const LOAD_RISK_DROPDOWN_FAIL = 'uprice/LOAD_RISK_DROPDOWN_FAIL';

export const GET_BOR_BY_ID = 'uprince/GET_BOR_BY_ID';
export const GET_BOR_BY_ID_SUCCESS = 'uprince/GET_BOR_BY_ID_SUCCESS';
export const GET_BOR_BY_ID_FAIL = 'uprice/GET_BOR_BY_ID_FAIL';

export const SAVE_BOR_STATE_ATTR = 'uprince/SAVE_BOR_STATE_ATTR';

export const GET_PRODUCT_BY_ID = 'uprince/GET_PRODUCT_BY_ID';
export const GET_PRODUCT_BY_ID_SUCCESS = 'uprince/GET_PRODUCT_BY_ID_SUCCESS';
export const GET_PRODUCT_BY_ID_FAIL = 'uprice/GET_PRODUCT_BY_ID_FAIL';

export const GET_CPC_BY_ID = 'uprince/GET_CPC_BY_ID';
export const GET_CPC_BY_ID_SUCCESS = 'uprince/GET_CPC_BY_ID_SUCCESS';
export const GET_CPC_BY_ID_FAIL = 'uprice/GET_CPC_BY_ID_FAIL';

export const SAVE_BOR = 'uprince/SAVE_BOR';
export const SAVE_BOR_SUCCESS = 'uprince/SAVE_BOR_SUCCESS';
export const SAVE_BOR_FAIL = 'uprice/SAVE_BOR_FAIL';

export const SET_PBS_UID = 'uprice/SET_PBS_UID';
export const CLEAR_PBS_UID = 'uprice/CLEAR_PBS_UID';
export const CLEAR_CPC_DATA = 'uprice/CLEAR_CPC_DATA';
export const CLEAR_PRODUCT_DATA = 'uprice/CLEAR_CPC_DATA';

export const CLEAR_PBS_FORM_DATA = 'uprice/CLEAR_PBS_FORM_DATA';
export const RESET_IS_CHANGE = 'uprice/RESET_IS_CHANGE';
export const RESET_RISK_GRID_LIST = 'uprice/RESET_RISK_GRID_LIST';

export const SAVE_PBS_RISK = 'uprince/SAVE_PBS_RISK';
export const SAVE_PBS_RISK_SUCCESS = 'uprince/SAVE_PBS_RISK_SUCCESS';
export const SAVE_PBS_RISK_FAIL = 'uprice/SAVE_PBS_RISK_FAIL';

export const CREATE_RISK = 'uprince/CREATE_RISK';
export const CREATE_RISK_SUCCESS = 'uprince/CREATE_RISK_SUCCESS';
export const CREATE_RISK_FAIL = 'uprice/CREATE_RISK_FAIL';

export const READ_RISK = 'uprince/READ_RISK';
export const READ_RISK_SUCCESS = 'uprince/READ_RISK_SUCCESS';
export const READ_RISK_FAIL = 'uprice/READ_RISK_FAIL';

export const DELETE_RISK = 'uprince/DELETE_RISK';
export const DELETE_RISK_SUCCESS = 'uprince/DELETE_RISK_SUCCESS';
export const DELETE_RISK_FAIL = 'uprice/DELETE_RISK_FAIL';


export const LOAD_DROPDOWN_DATA = 'uprince/LOAD_BOR_DROPDOWN_DATA';
export const LOAD_DROPDOWN_DATA_SUCCESS = 'uprince/LOAD_BOR_DROPDOWN_DATA_SUCCESS';
export const LOAD_DROPDOWN_DATA_FAIL = 'uprice/LOAD_BOR_DROPDOWN_DATA_FAIL';

export const LOAD_QUALITY = 'uprince/LOAD_QUALITY';
export const LOAD_QUALITY_SUCCESS = 'uprince/LOAD_QUALITY_SUCCESS';
export const LOAD_QUALITY_FAIL = 'uprice/LOAD_QUALITY_FAIL';

export const CREATE_QUALITY = 'uprince/CREATE_QUALITY';
export const CREATE_QUALITY_SUCCESS = 'uprince/CREATE_QUALITY_SUCCESS';
export const CREATE_QUALITY_FAIL = 'uprice/CREATE_QUALITY_FAIL';

export const SAVE_PBS_QUALITY = 'uprince/SAVE_PBS_QUALITY';
export const SAVE_PBS_QUALITY_SUCCESS = 'uprince/SAVE_PBS_QUALITY_SUCCESS';
export const SAVE_PBS_QUALITY_FAIL = 'uprice/SAVE_PBS_QUALITY_FAIL';

export const DELETE_QUALITY = 'uprince/DELETE_QUALITY';
export const DELETE_QUALITY_SUCCESS = 'uprince/DELETE_QUALITY_SUCCESS';
export const DELETE_QUALITY_FAIL = 'uprice/DELETE_QUALITY_FAIL';

export const LOAD_MATERIALS = 'uprince/LOAD_MATERIALS';
export const LOAD_MATERIALS_SUCCESS = 'uprince/LOAD_MATERIALS_SUCCESS';
export const LOAD_MATERIALS_FAIL = 'uprice/LOAD_MATERIALS_FAIL';

export const LOAD_PBS_MATERIALS = 'uprince/LOAD_PBS_MATERIALS';
export const LOAD_PBS_MATERIALS_SUCCESS = 'uprince/LOAD_PBS_MATERIALS_SUCCESS';
export const LOAD_PBS_MATERIALS_FAIL = 'uprice/LOAD_PBS_MATERIALS_FAIL';

export const CREATE_RESOURCE = 'uprince/CREATE_RESOURCE';
export const CREATE_RESOURCE_SUCCESS = 'uprince/CREATE_RESOURCE_SUCCESS';
export const CREATE_RESOURCE_FAIL = 'uprice/CREATE_RESOURCE_FAIL';

export const CREATE_TOOLS = 'uprince/CREATE_TOOLS';
export const CREATE_TOOLS_SUCCESS = 'uprince/CREATE_TOOLS_SUCCESS';
export const CREATE_TOOLS_FAIL = 'uprice/CREATE_TOOLS_FAIL';

export const READ_TOOLS = 'uprince/READ_TOOLS';
export const READ_TOOLS_SUCCESS = 'uprince/READ_TOOLS_SUCCESS';
export const READ_TOOLS_FAIL = 'uprice/READ_TOOLS_FAIL';

export const READ_PBS_TOOLS = 'uprince/READ_PBS_TOOLS';
export const READ_PBS_TOOLS_SUCCESS = 'uprince/READ_PBS_TOOLS_SUCCESS';
export const READ_PBS_TOOLS_FAIL = 'uprice/READ_PBS_TOOLS_FAIL';


export const DELETE_MATERIALS = 'uprince/DELETE_BOR_MATERIALS';
export const DELETE_MATERIALS_SUCCESS = 'uprince/DELETE_BOR_MATERIALS_SUCCESS';
export const DELETE_MATERIALS_FAIL = 'uprice/DELETE_BOR_MATERIALS_FAIL';

export const DELETE_TOOLS = 'uprince/DELETE_BOR_TOOLS';
export const DELETE_TOOLS_SUCCESS = 'uprince/DELETE_BOR_TOOLS_SUCCESS';
export const DELETE_TOOLS_FAIL = 'uprice/DELETE_BOR_TOOLS_FAIL';

export const CREATE_LABOUR = 'uprince/CREATE_LABOUR';
export const CREATE_LABOUR_SUCCESS = 'uprince/CREATE_LABOUR_SUCCESS';
export const CREATE_LABOUR_FAIL = 'uprice/CREATE_LABOUR_FAIL';

export const READ_LABOURS = 'uprince/READ_LABOURS';
export const READ_LABOURS_SUCCESS = 'uprince/READ_LABOURS_SUCCESS';
export const READ_LABOURS_FAIL = 'uprice/READ_LABOURS_FAIL';

export const READ_PBS_LABOURS = 'uprince/READ_PBS_LABOURS';
export const READ_PBS_LABOURS_SUCCESS = 'uprince/READ_PBS_LABOURS_SUCCESS';
export const READ_PBS_LABOURS_FAIL = 'uprice/READ_PBS_LABOURS_FAIL';

export const DELETE_LABOURS = 'uprince/DELETE_BOR_LABOURS';
export const DELETE_LABOURS_SUCCESS = 'uprince/DELETE_BOR_LABOURS_SUCCESS';
export const DELETE_LABOURS_FAIL = 'uprice/DELETE_BOR_LABOURS_FAIL';

export const READ_CONSUMABLE = 'uprince/READ_CONSUMABLE';
export const READ_CONSUMABLE_SUCCESS = 'uprince/READ_CONSUMABLE_SUCCESS';
export const READ_CONSUMABLE_FAIL = 'uprice/READ_CONSUMABLE_FAIL';

export const READ_PBS_CONSUMABLE = 'uprince/READ_PBS_CONSUMABLE';
export const READ_PBS_CONSUMABLE_SUCCESS = 'uprince/READ_PBS_CONSUMABLE_SUCCESS';
export const READ_PBS_CONSUMABLE_FAIL = 'uprice/READ_PBS_CONSUMABLE_FAIL';

export const CREATE_CONSUMABLE = 'uprince/CREATE_CONSUMABLE';
export const CREATE_CONSUMABLE_SUCCESS = 'uprince/CREATE_CONSUMABLE_SUCCESS';
export const CREATE_CONSUMABLE_FAIL = 'uprice/CREATE_CONSUMABLE_FAIL';

export const DELETE_CONSUMABLE = 'uprince/DELETE_BOR_CONSUMABLE';
export const DELETE_CONSUMABLE_SUCCESS = 'uprince/DELETE_BOR_CONSUMABLE_SUCCESS';
export const DELETE_CONSUMABLE_FAIL = 'uprice/DELETE_BOR_CONSUMABLE_FAIL';

export const LOAD_INSTRUCTION_DROPDOWN = 'uprince/LOAD_INSTRUCTION_DROPDOWN';
export const LOAD_INSTRUCTION_DROPDOWN_SUCCESS = 'uprince/LOAD_INSTRUCTION_DROPDOWN_SUCCESS';
export const LOAD_INSTRUCTION_DROPDOWN_FAIL = 'uprice/LOAD_INSTRUCTION_DROPDOWN_FAIL';

export const LOAD_TECH_INSTRUCTION = 'uprince/LOAD_TECH_INSTRUCTION';
export const LOAD_TECH_INSTRUCTION_SUCCESS = 'uprince/LOAD_TECH_INSTRUCTION_SUCCESS';
export const LOAD_TECH_INSTRUCTION_FAIL = 'uprice/LOAD_TECH_INSTRUCTION_FAIL';

export const UPLOAD_INSTRUCTION_DOC = 'uprince/UPLOAD_INSTRUCTION_DOC';
export const UPLOAD_INSTRUCTION_DOC_SUCCESS = 'uprince/UPLOAD_INSTRUCTION_DOC_SUCCESS';
export const UPLOAD_INSTRUCTION_DOC_FAIL = 'uprice/UPLOAD_INSTRUCTION_DOC_FAIL';

export const LOAD_TAXONOMY = 'uprince/LOAD_TAXONOMY';
export const LOAD_TAXONOMY_SUCCESS = 'uprince/LOAD_TAXONOMY_SUCCESS';
export const LOAD_TAXONOMY_FAIL = 'uprice/LOAD_TAXONOMY_FAIL';

export const CREATE_TECH_INSTRUCTION = 'uprince/CREATE_TECH_INSTRUCTION';
export const CREATE_TECH_INSTRUCTION_SUCCESS = 'uprince/CREATE_TECH_INSTRUCTION_SUCCESS';
export const CREATE_TECH_INSTRUCTION_FAIL = 'uprice/CREATE_TECH_INSTRUCTION_FAIL';

export const LOAD_COMPETENCE = 'uprince/LOAD_COMPETENCE';
export const LOAD_COMPETENCE_SUCCESS = 'uprince/LOAD_COMPETENCE_SUCCESS';
export const LOAD_COMPETENCE_FAIL = 'uprice/LOAD_COMPETENCE_FAIL';

export const LOAD_COMPETENCE_DROPDOWN = 'uprince/LOAD_COMPETENCE_DROPDOWN';
export const LOAD_COMPETENCE_DROPDOWN_SUCCESS = 'uprince/LOAD_COMPETENCE_DROPDOWN_SUCCESS';
export const LOAD_COMPETENCE_DROPDOWN_FAIL = 'uprice/LOAD_COMPETENCE_DROPDOWN_FAIL';

export const CREATE_COMPETENCE = 'uprince/CREATE_COMPETENCE';
export const CREATE_COMPETENCE_SUCCESS = 'uprince/CREATE_COMPETENCE_SUCCESS';
export const CREATE_COMPETENCE_FAIL = 'uprice/CREATE_COMPETENCE_FAIL';

export const DELETE_COMPETENCE = 'uprince/DELETE_COMPETENCE';
export const DELETE_COMPETENCE_SUCCESS = 'uprince/DELETE_COMPETENCE_SUCCESS';
export const DELETE_COMPETENCE_FAIL = 'uprice/DELETE_COMPETENCE_FAIL';

export const DELETE_TECH_INSTRUCTION = 'uprince/DELETE_TECH_INSTRUCTION';
export const DELETE_TECH_INSTRUCTION_SUCCESS = 'uprince/DELETE_TECH_INSTRUCTION_SUCCESS';
export const DELETE_TECH_INSTRUCTION_FAIL = 'uprice/DELETE_TECH_INSTRUCTION_FAIL';

export const UPDATE_BOR_STATUS = 'uprince/UPDATE_BOR_STATUS';
export const UPDATE_BOR_STATUS_SUCCESS = 'uprince/UPDATE_BOR_STATUS_SUCCESS';
export const UPDATE_BOR_STATUS_FAIL = 'uprice/UPDATE_BOR_STATUS_FAIL';

export const DELETE_PBS = 'uprince/DELETE_PBS';
export const DELETE_PBS_SUCCESS = 'uprince/DELETE_PBS_SUCCESS';
export const DELETE_PBS_FAIL = 'uprice/DELETE_PBS_FAIL';

export const DELETE_BOR = 'uprince/DELETE_BOR';
export const DELETE_BOR_SUCCESS = 'uprince/DELETE_BOR_SUCCESS';
export const DELETE_BOR_FAIL = 'uprice/DELETE_BOR_FAIL';

export const RESET_FORM_DATA = 'uprice/RESET_PBS';
export const RESET_BOR_FORM_DATA = 'uprice/RESET_BOR_FORM_DATA';


export const GET_BY_BOR = 'uprince/GET_BY_BOR';
export const GET_BY_PBS = 'uprince/GET_BY_PBS';

export const UPDATE_BOR_MATERIAL = 'uprince/UPDATE_BOR_MATERIAL';
export const UPDATE_BOR_MATERIAL_SUCCESS = 'uprince/UPDATE_BOR_MATERIAL_SUCCESS';
export const UPDATE_BOR_MATERIAL_FAIL = 'uprice/UPDATE_BOR_MATERIAL_FAIL';

export const UPDATE_BOR_TOOLS = 'uprince/UPDATE_BOR_TOOLS';
export const UPDATE_BOR_TOOLS_SUCCESS = 'uprince/UPDATE_BOR_TOOLS_SUCCESS';
export const UPDATE_BOR_TOOLS_FAIL = 'uprice/UPDATE_BOR_TOOLS_FAIL';

export const UPDATE_BOR_CONSUMABLE = 'uprince/UPDATE_BOR_CONSUMABLE';
export const UPDATE_BOR_CONSUMABLE_SUCCESS = 'uprince/UPDATE_BOR_CONSUMABLE_SUCCESS';
export const UPDATE_BOR_CONSUMABLE_FAIL = 'uprice/UPDATE_BOR_CONSUMABLE_FAIL';

export const UPDATE_BOR_LABOURS = 'uprince/UPDATE_BOR_LABOURS';
export const UPDATE_BOR_LABOURS_SUCCESS = 'uprince/UPDATE_BOR_LABOURS_SUCCESS';
export const UPDATE_BOR_LABOURS_FAIL = 'uprice/UPDATE_BOR_LABOURS_FAIL';


export const SEND_BOR_RETURN_RES = 'uprice/SEND_BOR_RETURN_RES';

import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '@fluentui/react';
import { messageService } from '../../../../services/messageService';
import { addIconWhite, editIconWhite } from '../WbsCreateDocument';
import { renderIconBefore, WBS_TAXONOMY_LEVEL } from '../../../../types/wbs';
import {
  readWbsTaxonomyByTemId,
  saveWbsCreateStateAttr,
  updateWbsTaxonomyParent,
} from '../../../../reducers/wbsCreateReducer';
import AddTopNodeModel from './AddTopNodeModel';
import DndTaxonomyGrid from '../../../../shared/taxonomyField/dndTaxonomyGrid';

const WbsCreateTaxonomy = ({ featureId }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const wbsTemplateTaxonomyData = useSelector((state: any) => state.wbsCreate.wbsTemplateTaxonomyData);
  const selectedNodeId = useSelector((state: any) => state.wbsCreate.selectedNodeId);
  // const wbsTaxonomy = useSelector((state: any) => state.wbsTask.wbsTaxonomy);

  useEffect(() => {
    featureId && dispatch(readWbsTaxonomyByTemId(featureId));

    return () => {
      console.log('cleaned up WbsCreateTaxonomy');
      dispatch(saveWbsCreateStateAttr('selectedNodeId', null));
    };
  }, []);

  const handleNodeAdd = (rowInfo: any) => {
    messageService.sendMessage({
      wbsTaxonomyRowInfo: {
        ...rowInfo,
        isEditWbsNode: false,
        isAddNewWbsChildNode: true,
      },
    });
  };

  const handleNodeEdit = (rowInfo: any) => {
    messageService.sendMessage({
      wbsTaxonomyRowInfo: {
        ...rowInfo,
        isEditWbsNode: true,
        isAddNewWbsChildNode: false,
      },
    });
  };

  const renderButtons = (rowInfo: any) => {
    // const isParent =false;
    const wbsTaxonomyLevelId = rowInfo.node.wbsTaxonomyLevelId;
    // const isSelected = selectItemIds.includes(rowInfo.node.id);

    switch (wbsTaxonomyLevelId) {
      case WBS_TAXONOMY_LEVEL.TASK:
        return ([]);
      case WBS_TAXONOMY_LEVEL.PRODUCT:
        return ([
          <div>
            <PrimaryButton
              style={{
                width: 22,
                minWidth: 22,
                height: 22,
                padding: 5,
                marginTop: 5,
                marginRight: 3,
              }}
              iconProps={addIconWhite}
              ariaLabel='Close popup modal'
              onClick={() => handleNodeAdd(rowInfo)}
            >
            </PrimaryButton>
          </div>,
        ]);
      case WBS_TAXONOMY_LEVEL.WBS:
        return ([

          <div>
            <PrimaryButton
              style={{
                width: 22,
                minWidth: 22,
                height: 22,
                padding: 5,
                marginTop: 5,
                marginRight: 3,
              }}
              iconProps={editIconWhite}
              ariaLabel='Close popup modal'
              onClick={() => handleNodeEdit(rowInfo)}
            >
            </PrimaryButton>
          </div>,
          <div>
            <PrimaryButton
              style={{
                width: 22,
                minWidth: 22,
                height: 22,
                padding: 5,
                marginTop: 5,
                marginRight: 3,
              }}
              iconProps={addIconWhite}
              ariaLabel='Close popup modal'
              onClick={() => handleNodeAdd(rowInfo)}
            >
            </PrimaryButton>
          </div>,
        ]);
      default:
        return ([]);
    }
  };

  const canDrag = ({ node }: any) => {
    return (node?.wbsTaxonomyLevelId != WBS_TAXONOMY_LEVEL.TASK && node?.wbsTaxonomyLevelId != WBS_TAXONOMY_LEVEL.ISSUE);
  };


  const onMoveNodeChange = (node: any) => {
    Promise.all([dispatch(updateWbsTaxonomyParent({
      id: node?.node?.id,
      parentId: node?.nextParentNode?.id,
    }))]).then((res: any) => {
      if (res) {
        dispatch(readWbsTaxonomyByTemId(featureId));
        dispatch(saveWbsCreateStateAttr('selectedNodeId', node?.node?.id));
      }
    });
  };

  const handleNodeIcon = (rowInfo: any) => {
    return (
      <div style={{ display: 'flex', gap: 5 }}>
        {renderIconBefore(rowInfo.node)}
        {rowInfo.node.title}
      </div>
    );
  };

  return (
    <>
      <div className='ms-Grid-row full-height'>
        <div className='ms-Grid-col ms-sm12 ms-md12 ms-lg12' style={{ height: '73vh' }}>
          {featureId ? <DndTaxonomyGrid
            treeData={wbsTemplateTaxonomyData ?? []}
            renderButtons={renderButtons}
            canDrag={canDrag}
            onMoveNode={onMoveNodeChange}
            selectedId={selectedNodeId}
            onRenderTitle={handleNodeIcon}
          /> : <span>{t('error')}</span>}
        </div>
      </div>

      <AddTopNodeModel />
    </>
  );

};
export default WbsCreateTaxonomy;

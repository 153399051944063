import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { withTranslation, WithTranslation } from 'react-i18next';
import { messageService } from '../../services/messageService';

interface Props extends WithTranslation {
  onClickNewButton: () => void;
}

class NewCommandBar extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
  }

  onClickNewButton = () => {
    messageService.sendMessage({ isNewQrCode: true });
    this.props.onClickNewButton();
  };

  public render(): JSX.Element {
    return (
      <CommandBar
        items={this.getItems()}
        overflowButtonProps={{ ariaLabel: 'More commands' }}
        className="common-commandbar"
        ariaLabel={'Use left and right arrow keys to navigate between commands'}
      />
    );
  }

  private getItems = () => {
    const { t } = this.props;
    return [
      {
        key: 'newItem',
        text: t('new'),
        cacheKey: 'myCacheKey',
        iconProps: { iconName: 'Add' },
        onClick: this.onClickNewButton,
      },
    ];
  };
}

export default withTranslation()(NewCommandBar);

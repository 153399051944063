import {
  ADD_LANGUAGE,
  ADD_LANGUAGE_FAIL,
  ADD_LANGUAGE_SUCCESS,
  CLEAR_LANGUAGE_DATA,
  CREATE_LANGUAGE,
  CREATE_LANGUAGE_FAIL,
  CREATE_LANGUAGE_SUCCESS,
  CREATE_TRANSLATION,
  CREATE_TRANSLATION_FAIL,
  CREATE_TRANSLATION_SUCCESS,
  GET_KEY_WORD,
  GET_KEY_WORD_FAIL,
  GET_KEY_WORD_SUCCESS,
  GET_LANGUAGES,
  GET_LANGUAGES_FAIL,
  GET_LANGUAGES_SUCCESS,
  LANGUAGE_GET_BY_ID,
  LANGUAGE_GET_BY_ID_FAIL,
  LANGUAGE_GET_BY_ID_SUCCESS,
  UPDATE_LANGUAGE,
  UPDATE_LANGUAGE_FAIL,
  UPDATE_LANGUAGE_SUCCESS,
  UPDATE_TRANSLATION,
  UPDATE_TRANSLATION_FAIL,
  UPDATE_TRANSLATION_SUCCESS,
} from '../actionTypes/languageActionTypes';
import { Language, LanguageKeys, Translation } from '../types/language';
import {
  ADD_LANGUAGE_EP,
  CREATE_LANGUAGE_EP,
  CREATE_TRANSLATION_EP,
  GET_KEY_WORD_EP,
  GET_LANGUAGES_EP,
  LANGUAGE_GET_BY_ID_EP,
  UPDATE_LANGUAGE_EP,
  UPDATE_TRANSLATION_EP,
} from '../shared/endpoints';


const defaultState: any = {
  createLanguageStatus: false,
  getLanguageStatus: false,
  langList: [],
  formData: {},
  keyData: [],
  createTranslationStatus: false,
  updateLanguageStatus: false,
  addLanguageStatus: false,

};

const languageReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case CREATE_LANGUAGE:
      return { ...state };
    case CREATE_LANGUAGE_SUCCESS:
      return {
        ...state,
        createLanguageStatus: true,

      };
    case CREATE_LANGUAGE_FAIL:
      return { ...state };

    case GET_LANGUAGES:
      return { ...state };
    case GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        getLanguageStatus: true,
        langList: action.payload.data.result,
      };
    case GET_LANGUAGES_FAIL:
      return { ...state };

    case LANGUAGE_GET_BY_ID:
      return { ...state };
    case LANGUAGE_GET_BY_ID_SUCCESS:
      return {
        ...state,
        formData: action.payload.data.result,

      };
    case LANGUAGE_GET_BY_ID_FAIL:
      return { ...state };

    case GET_KEY_WORD:
      return { ...state };
    case GET_KEY_WORD_SUCCESS:
      return {
        ...state,
        keyData: action.payload.data.result,
      };
    case GET_KEY_WORD_FAIL:
      return { ...state };

    case CREATE_TRANSLATION:
      return { ...state };
    case CREATE_TRANSLATION_SUCCESS:
      return {
        ...state,
        createTranslationStatus: true,

      };
    case CREATE_TRANSLATION_FAIL:
      return { ...state };

    case UPDATE_LANGUAGE:
      return {
        ...state,
        updateLanguageStatus: false,
      };
    case UPDATE_LANGUAGE_SUCCESS:
      return {
        ...state,
        updateLanguageStatus: true,

      };
    case UPDATE_LANGUAGE_FAIL:
      return { ...state };

    case ADD_LANGUAGE:
      return {
        ...state,
        addLanguageStatus: false,
      };
    case ADD_LANGUAGE_SUCCESS:
      return {
        ...state,
        addLanguageStatus: true,

      };
    case ADD_LANGUAGE_FAIL:
      return { ...state };
    case CLEAR_LANGUAGE_DATA:
      return {
        ...state,
        keyData: [],
        formData: {},

      };

    default:
      return { ...state };
  }
};

export const createLanguage = (data: LanguageKeys) => {
  return {
    types: [CREATE_LANGUAGE, CREATE_LANGUAGE_SUCCESS, CREATE_LANGUAGE_FAIL],
    payload: {
      request: {
        url: CREATE_LANGUAGE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getLanguages = () => {
  return {
    types: [GET_LANGUAGES, GET_LANGUAGES_SUCCESS, GET_LANGUAGES_FAIL],
    payload: {
      request: {
        url: GET_LANGUAGES_EP,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const LanguageGetById = (id: string) => {
  return {
    types: [LANGUAGE_GET_BY_ID, LANGUAGE_GET_BY_ID_SUCCESS, LANGUAGE_GET_BY_ID_FAIL],
    payload: {
      request: {
        url: LANGUAGE_GET_BY_ID_EP + id,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const getKeyWord = (id: any) => {
  return {
    types: [GET_KEY_WORD, GET_KEY_WORD_SUCCESS, GET_KEY_WORD_FAIL],
    payload: {
      request: {
        url: GET_KEY_WORD_EP + id,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const createTranslation = (data: Translation) => {
  return {
    types: [CREATE_TRANSLATION, CREATE_TRANSLATION_SUCCESS, CREATE_TRANSLATION_FAIL],
    payload: {
      request: {
        url: CREATE_TRANSLATION_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const updateTranslation = (data: Translation) => {
  return {
    types: [UPDATE_TRANSLATION, UPDATE_TRANSLATION_SUCCESS, UPDATE_TRANSLATION_FAIL],
    payload: {
      request: {
        url: UPDATE_TRANSLATION_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const updateLanguage = (data: Language) => {
  return {
    types: [UPDATE_LANGUAGE, UPDATE_LANGUAGE_SUCCESS, UPDATE_LANGUAGE_FAIL],
    payload: {
      request: {
        url: UPDATE_LANGUAGE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const addLanguage = (data: Language) => {
  return {
    types: [ADD_LANGUAGE, ADD_LANGUAGE_SUCCESS, ADD_LANGUAGE_FAIL],
    payload: {
      request: {
        url: ADD_LANGUAGE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const clearLanguageData = () => {
  return { type: CLEAR_LANGUAGE_DATA };
};
export default languageReducer;

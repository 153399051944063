import { Person } from './addressBookItem';
import { RiskStatus, RiskType } from './projectBreakdownStructure';

class SortingModel {
  attribute: string | null = null;
  order: string | null = null;
}

export class RiskRegisterListPaneFilter {


  // {
  //   "title": "string",
  //   "typeId": "string",
  //   "stateId": "string",
  //   "sortingModel": {
  //     "attribute": "string",
  //     "order": "string"
  //   }
  // }


  title: string | null = null;
  //  sequenceCode: string|null = null;
  //  name: string|null = null;
  //  person: string|null = null;
  typeId: string | null = null;
  stateId: string | null = null;
  //  riskDetails: string|null = null;
  sortingModel: SortingModel = new SortingModel();
}

export interface RiskRegisterShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
  value: number | null
}


class Sorter {
  attribute: string | null = null;
  order: string | null = null;
}

export class RiskRegisterListItem {
  id: string | null = null;
  name: string | null = null;
  sequenceCode: string | null = null;
  title: string | null = null;
  person: string | null = null;
}

export interface DropDown {
  key: string;
  text: string;
}

export interface RRDropDowns {
  //riskTypes: any,
  riskStatus: DropDown[];
  riskTypes: DropDown[];
}

export interface RiskRegister {
  id: string | null;
  title: string;
  sequenceCode: string;
  name: string | null;
  person: string | null;
  riskType: string | null;
  riskStatus: string | null;
  riskDetails: string | null;

}

export class Risk {
  id: string | null = null;
  name: string | null = null;
  riskDetails: string | null = null;
  riskTypeId: string | null = null;
  cabPersonId: string | null = null;
  riskStatusId: string | null = null;
  sequenceCode?: string | null = null;
  person?: Person | null = null;
  title: string | null = null;
  riskType?: RiskType | null = null;
  riskStatus?: RiskStatus | null = null;
  pbsRiskId?: string | null = null;
}

export interface RiskRegisterDropDowns {
  //riskTypes: any,
  riskStatus: DropDown[];
  riskTypes: DropDown[];
  dropDownItem: DropDown[];
}

export interface RRDetails {
  id: string | null;
  sequenceId: string | null;
  title: string | null;
  name: string | null;
}

export interface History {
  createdByUser: string | null;
  createdDateTime: string | null;
  revisionNumber: number | string | null;
  updatedByUser: string | null;
  updatedDateTime: string | null;
}

export const formData = {
  id: null,
  title: 'string',
  sequenceCode: 'string',
  sequenceId: 'string',
  name: 'string',
  person: 'string',
  riskType: 'string',
  riskStatus: 'string',
  riskDetails: 'string',

  // id: string | null;
  // title: string;
  // sequenceId: string;

  // person: string | null;

  // riskStatus: string | null;

};


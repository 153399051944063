import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { FluentProvider } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { FontWeights, getTheme, IconButton, IIconProps, mergeStyleSets, Modal } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { uPrinceTheme } from '../../../../../theme';
import { Edit24Filled } from '@fluentui/react-icons';
import { uPrinceFluentLightTheme } from '../../../../../fluentTheme';
import { IImageProps, Image, ImageFit } from 'office-ui-fabric-react';
import { getWBSDocumentFieldsAndcolumns } from '../../../../reducers/wbsCreateReducer';
import SharePointContentTypeForm from './ContentTypeForm';

const theme = getTheme();

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xxLarge,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xxLarge,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 16px 14px 16px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    // minWidth: '30vw',
    // minHeight: '10vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
    // maxHeight: '80vh',
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const EditDocumentContentType = (props: { file: any }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const ref: any = useRef(null);
  const inputFile: any = useRef(null);
  const classNames = mergeStyleSets({
    fullWidth: { width: '100%', marginTop: 20 },
    halfWidth: { width: '49%' },
  });

  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      width: '100%',
      height: '90%',
    },
    header: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
      },
    ],
    heading: {
      color: theme.palette.neutralPrimary,
      fontWeight: FontWeights.semibold,
      fontSize: 'inherit',
      margin: '0',
    },
    body: {
      // flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      height: '80vh',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
  });

  const { uploadedInstructionLink, showProgressBar }: any = useSelector(
    (state: any) => state.ir
  );
  const metaData = useSelector((state: any) => state.wbsCreate.wbsDocumentMeta);

  useEffect(() => {
    if (props.file && isOpen) {
      dispatch(
        getWBSDocumentFieldsAndcolumns({
          taskId: props.file.taskId,
          url: props.file.url,
        })
      );
    }
  }, [props.file, isOpen]);

  const headerStyle = contentStyles.header;
  const bodyStyle = contentStyles.body;

  const imageProps: IImageProps = {
    src: props.file.url,
    imageFit: ImageFit.cover,
    width: '100%',
    height: 600,
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onDismiss={() => {}}
        isBlocking={false}
        containerClassName={contentStyles.container}
      >
        <FluentProvider theme={uPrinceFluentLightTheme}>
          <div className={headerStyle}>
            <span>{metaData?.fields?.additionalData?.LinkFilename}</span>
            <IconButton
              styles={cancelIconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel="Close popup modal"
              onClick={() => setIsOpen(false)}
            />
          </div>

          {/* <ProgressBar show={showProgressBar} /> */}
          <div className={bodyStyle}>
            <div>
              <div
                style={{ borderTop: '1px solid #CCD1D1' }}
                className="ms-Grid-row"
              >
                <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8">
                  <Image {...imageProps} />
                </div>

                <div
                  style={{ height: '80vh', borderLeft: '1px solid #CCD1D1' }}
                  className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"
                >
                  <SharePointContentTypeForm
                    columns={metaData?.columns?.value}
                    fields={metaData?.fields?.additionalData}
                  />
                </div>
              </div>
            </div>
          </div>
        </FluentProvider>
      </Modal>

      <Edit24Filled
        style={{ width: 20, height: 20, marginBottom: 4 }}
        onClick={() => {
          setIsOpen(true);
        }}
      />
    </>
  );
};

export default EditDocumentContentType;

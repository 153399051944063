import * as React from 'react';
import { Label } from '@fluentui/react-components';

interface Props {
  errMsg: string;
}

const ErrorLabel = (props: Props) => {
  return (
    <>
      <Label required size='small' style={{ color: '#bc2f32' }}>{props.errMsg}</Label>
    </>
  );
};

export default ErrorLabel;
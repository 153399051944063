export class StockListPaneFilter {
  sorter: Sorter = new Sorter();
}

// export interface StockShortCutPaneItem {
//   key: string | null;
//   id: string | null;
//   name: string | null;
//   type: string | null;
//   value:number | null
// }

export class Sorter {
  attribute: string | null = null;
  order: string | null = null;
}

export class StockListItem {
  id: string | null = null;
  name: string | null = null;
  sequenceId: string | null = null;
  title: string | null = null;
  typeId: string | null = null;
  type: string | null = null;
  statusId: string | null = null;
  status: string | null = null;
  availableQuantity: string | null = null;
  mouId: string | null = null;
  averagePrice: string | null = null;
  quantityToBeDelivered: string | null = null;
  wareHouseTaxonomyId: string | null = null;
}

export interface DropDown {
  key: string;
  text: string;
}

export interface StockDropDowns {
  status: DropDown[];
  type: DropDown[];
}

export interface History {
  createdByUser: string | null;
  createdDateTime: string | null;
  revisionNumber: number | string | null;
  updatedByUser: string | null;
  updatedDateTime: string | null;
}

export interface StockShortCutPaneItem {
  id: string | null | number;
  name: string | null;
  languageCode: string;
  displayOrder: number;
  type: string | null;
  isMain: boolean;
  typeId: string | null;
}

export class StockShotcutPaneFilter {
  id: string | null | number = null;
  name: string | null = null;
  title: string | null = null;
  type: any = null;
  status: string | null = null;
  wharehouseTaxonomy: string | null = null;
  wareHouse: string | null = null;
  sorter: Sorter = new Sorter();
  resourceType: string | null | number = null;
  typeId: string | null = null;
}

export interface StockShortCutPaneMain {
  stock: StockShortCutPaneItem[];
  workFlow: StockShortCutPaneItem[];
  warehouse: StockShortCutPaneItem[];
}

export interface StockDetails {

}



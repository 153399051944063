import { mergeStyles, Text } from '@fluentui/react';
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { uPrinceTheme } from '../../../theme';

const customStyles = {
  control: (_: any, { selectProps: { width } }: any) => ({
    ..._,
    width: width,
    height: 34,
    fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
    minHeight: 34,
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 1000001 }),
  groupHeading: (base: any) => ({
    ...base,
    flex: '1 1',
    margin: 0,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: uPrinceTheme.palette.neutralLight,
    textTransform: 'capitalize',
  }),
};

const errorMessage = mergeStyles({
  fontSize: 12,
  fontWeight: 400,
  color: 'rgb(164, 38, 44)',
  animationDuration: '0.367s',
  animationFillMode: 'both',
});

const DropdownIndicator = (props: any) => {
  // eslint-disable-next-line no-unused-vars
  const { className, cx, getStyles, innerProps } = props;
  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...innerProps}
      style={{ fontSize: 12, paddingRight: 10, paddingTop: 4 }}
    >
      <i
        className={'filter-icon ms-Icon ms-Icon--ChevronDown filter-icon'}
        aria-hidden="true"
      ></i>
    </div>
  );
};

const GroupDropdown = (props: {
  selectedOption: any;
  onChange: (key: string, text: string, environment: string, item: any) => void;
  promiseOptions: any;
  // eslint-disable-next-line react/no-unused-prop-types
  defaultInputValue?: string | null;
  required?: boolean;
  validationMessage: string | null;
  reference: any;
  autoFocus?: boolean;
}) => {
  const [height, setHeight] = useState(50);
  const [reference, setReference] = useState(props.reference);
  const onMenuOpen = () => {
    setHeight(350);
  };
  const onMenuClose = () => {
    setHeight(50);
    setReference('');
  };
  const handleChange = (selectedOption: any, actionMeta: any) => {
    if (actionMeta.action === 'clear') {
      props.onChange('', '', '', '');
    } else {
      props.onChange(selectedOption.value, selectedOption.label, selectedOption.environment, selectedOption.item);
    }
    onMenuClose();
  };


  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 13,
    padding: 5,
  };
  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };

  const formatGroupLabel = (data: any) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
    </div>
  );

  return (
    <div style={{ height: height, transition: 'all 0.4s linear' }}>
      { /* <Select*/}
      { /* value={props.selectedOption}*/}
      { /* components={{ DropdownIndicator }}*/}
      { /* styles={customStyles}*/}
      { /* className="reactSelectContainer"*/}
      { /* classNamePrefix="reactSelect"*/}
      { /* onChange={handleChange}*/}
      { /* options={props.options}*/}
      { /* theme={theme => ({*/}
      { /* ...theme,*/}
      { /* borderRadius: 0,*/}
      { /* height: 34,*/}
      { /* minHeight: 34,*/}
      { /* colors: {*/}
      { /* ...theme.colors,*/}
      { /* primary25: uPrinceTheme.palette.themeLight,*/}
      { /* primary: uPrinceTheme.palette.themePrimary,*/}
      { /* neutral20: uPrinceTheme.palette.neutralSecondary,*/}
      { /* neutral30: uPrinceTheme.palette.neutralSecondary,*/}
      { /* },*/}
      { /* })}*/}
      { /* />*/}
      <AsyncSelect
        autoFocus={props.autoFocus}
        value={(props.selectedOption) ? props.selectedOption : { value: '', label: '' }}
        // defaultInputValue={
        //   props.defaultInputValue ? props.defaultInputValue : ''
        // }
        components={{ DropdownIndicator }}
        className="reactSelectContainer"
        classNamePrefix="reactSelect"
        styles={customStyles}
        onChange={handleChange}
        // menuPortalTarget={document.body}
        // menuPosition={'fixed'}
        // menuPosition={isFixed ? 'fixed' : 'absolute'}
        required={props.required}
        isClearable={!!(props.selectedOption)}
        formatGroupLabel={formatGroupLabel}
        menuPlacement={'auto'}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          height: 34,
          minHeight: 34,
          colors: {
            ...theme.colors,
            primary25: uPrinceTheme.palette.themeLight,
            primary: uPrinceTheme.palette.themePrimary,
            neutral20: props.validationMessage
              ? 'rgb(164, 38, 44)'
              : uPrinceTheme.palette.neutralSecondary,
            neutral30: uPrinceTheme.palette.neutralSecondary,
          },
        })}
        defaultOptions
        loadOptions={props.promiseOptions}
        onMenuOpen={() => onMenuOpen()}
        onMenuClose={() => onMenuClose()}
        onBlur={() => onMenuClose()}
        ref={reference == 1 ? input => input && input.focus() : input => {
        }}
      />
      {props.validationMessage && (
        <Text className={errorMessage}>{props.validationMessage}</Text>
      )}
    </div>
  );
};

export default GroupDropdown;

import { Dropdown, IColumn, TextField } from '@fluentui/react';
import React from 'react';
import { CABFilter } from '../../types/addressBookItem';
import i18n from '../../../i18n';

export const _renderCABDetailsFooterItemColumn = (
  cabFilter: CABFilter,
  onChangeFilterInput: any,
  onChangeCABFilterStatus: any,
  item?: any,
  index?: number,
  column?: IColumn,
) => {
  switch (column!.key) {
    case 'column1': {
      return (
        <div>
          <div>
            <TextField
              value={cabFilter && cabFilter.fullName ? cabFilter.fullName : ''}
              onChange={onChangeFilterInput('fullName')}
            />
          </div>
        </div>
      );
      break;
    }
    case 'column2': {
      return (
        <div>
          <TextField
            value={cabFilter && cabFilter.jobTitle ? cabFilter.jobTitle : ''}
            onChange={onChangeFilterInput('jobTitle')}
          />
        </div>
      );
      break;
    }
    case 'column3': {
      return (
        <div>
          <TextField
            value={
              cabFilter && cabFilter.organisation ? cabFilter.organisation : ''
            }
            onChange={onChangeFilterInput('organisation')}
          />
        </div>
      );
      break;
    }

    case 'column4': {
      return (
        <div>
          <TextField
            value={cabFilter && cabFilter.email ? cabFilter.email : ''}
            onChange={onChangeFilterInput('email')}
          />
        </div>
      );
      break;
    }
    case 'column5': {
      return (
        <div>
          <TextField
            value={
              cabFilter && cabFilter.mobileNumber ? cabFilter.mobileNumber : ''
            }
            onChange={onChangeFilterInput('mobileNumber')}
          />
        </div>
      );
      break;
    }
    case 'column6': {
      return (
        <div>
          <Dropdown
            placeholder={i18n.t('status')}
            options={[{ key: 2, text: i18n.t('all') }, { key: 1, text: i18n.t('active') }, {
              key: 0,
              text: i18n.t('draft'),
            }]}
            onChange={onChangeCABFilterStatus('isSaved')}
          />
        </div>
      );
      break;
    }

    default: {
      break;
    }
  }
};

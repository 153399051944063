import * as React from 'react';
import WbsCreateListPane from './wbsCreateListPane';
import { ListComponent } from './ListComponent';

interface Props {
  isTemplate: boolean;
  showDocumentPane: boolean;
}

const ListPaneWrapper = (props: Props) => {

  const renderListPane = () => {
    if (props?.isTemplate) {
      // Return Template with the tree
      return <WbsCreateListPane showDocumentPane ={props?.showDocumentPane}/>;
    } else {
      // Return Template ListPane
      return <ListComponent />;
    }
  };
  return (
    <>
      {renderListPane()}
    </>
  );

};

export default ListPaneWrapper;
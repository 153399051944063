import { ChoiceGroup } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { saveWbsTaskStateAttr, wbsTaskStatusUpdate } from '../../../../reducers/wbsTaskReducer';

const WbsStatusPopover = (props: {
  visible: boolean,
  props: any,
  close: any,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const wbsTaskDropdowns = useSelector((state: any) => state.wbsTask.wbsTaskDropdowns);

  function _onChange(ev: any, option: any): void {
    if (option) {
      props.close();
      Promise.all([
        dispatch(wbsTaskStatusUpdate(
          {
            id: props?.props?.id,   //  task id
            statusId: option?.key,
          },
        )),
      ]).then(() => {
        dispatch(saveWbsTaskStateAttr('reloadListPane', true));
      });
    }
  }

  return (
    <div style={{ width: 200, height: 'auto' }}>
      {props.visible && <ChoiceGroup
        selectedKey={props?.props?.status}
        options={wbsTaskDropdowns?.status}
        onChange={_onChange}
        label={t('WbsTaskStatus')}
      />}
    </div>
  );
};

export default WbsStatusPopover;
import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';
import { BOR_TYPE, ShortCutPaneItem } from '../../../types/billOfResources';

const CommandBarLeft = (props: {
  createNew: (create: boolean, type: string) => void,
  selectedShortCutItem: ShortCutPaneItem
}) => {
  const { t } = useTranslation();

  const newClick = () => {
    // setTimeout(() => messageService.sendMessage({ isNewBor: true }), 2000);
    messageService.sendMessage({ isNewBor: true });
    props.createNew(true, BOR_TYPE.REGULAR);
  };
  const borReturn = () => {
    // setTimeout(() => messageService.sendMessage({ isBorReturn: true }), 2000);
    messageService.sendMessage({ isBorReturn: true });
    // messageService.sendMessage({ isBorReturn: true });
    props.createNew(true, BOR_TYPE.RETURN);
  };
  const exportBorResource = () => {
    messageService.sendMessage({ exportBorResource: true });
  };

  const getItems = () => {
    const items = [];

    const newBtn = {
      key: 'new',
      text: t('new'),
      iconProps: { iconName: 'Add' },
      onClick: () => newClick(),
      split: true,
      subMenuProps: {
        items: [
          { key: 'regular', text: t('regular'), onClick: () => newClick() },
          { key: 'return', text: t('return'), onClick: () => borReturn() },
        ],
      },
    };
    const exportBtn = {
      key: 'export',
      text: t('export'),
      iconProps: { iconName: 'download' },
      onClick: () => exportBorResource(),
    };

    items.push(newBtn);
    if (props.selectedShortCutItem.id == '1') {
      items.push(exportBtn);
    }

    return items;
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );
};

export default CommandBarLeft;

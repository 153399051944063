import { getTheme, Icon, IconButton, IIconProps, Label, mergeStyleSets, Modal, PrimaryButton, Spinner, SpinnerSize, TeachingBubble } from '@fluentui/react';
import React, { useEffect, useState } from 'react'
import {AuthenticationType} from 'azure-maps-control';
import { IAzureMapOptions, AzureMapsProvider, AzureMap, AzureMapHtmlMarker, IAzureMapHtmlMarker, IAzureMapHtmlMarkerEvent, ControlOptions } from 'react-azure-maps';
import ENV from '../../../../../env.json'
import { uPrinceTheme } from '../../../../../theme';
import { useTranslation } from 'react-i18next';
import '../../../style/style.css';
import 'azure-maps-control/dist/atlas.min.css'

const classNames = mergeStyleSets({
  modalContainer: {
    width: '85%',
    height: '85%'
  },
  scrollableContent: {
    width: '100%',
    height: '100%'
  },
  header: {
    background: uPrinceTheme.palette.themePrimary, 
    color: '#fff', 
    paddingRight: '16px',
    paddingLeft: '16px',
    paddingTop: '10px',
    paddingBottom: '10px',
    width: 'calc(100% - 32px)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  detailBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
    padding: '16px',
    position: 'absolute',
    top: '60px',
    zIndex: 2,
    backgroundColor: '#00000070',
    marginLeft: '16px'
  },
  mapContainer: {
    width: '100%', 
    height: 'calc(100% - 52px)', // 100% - (header + detailBar),
  },
  detailBarFlex: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  htmlMarkerIcon: {
    fontSize: 30,
    color: uPrinceTheme.palette.themePrimary,
  },
  label: {
    fontWeight: 'bold',
    color: '#fff'
  },
  position: {
    color: '#fff'
  },
});

const theme = getTheme()

const cancelIcon: IIconProps = { iconName: 'Cancel' };
const cancelIconButtonStyles = {
  root: {
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const ReactAzureMap = (props: {
  openModal: boolean;
  setOpenModal: any;
  markedLocation: (position: any, address: any) => void;
  position: number[]
}) => {
  const option: IAzureMapOptions = {
    authOptions: {
      authType: AuthenticationType.subscriptionKey,
      subscriptionKey: ENV.MAP_KEY,
    },
    zoom: 8,
    view: "Auto",
    center: props.position,
  };
  const {t} = useTranslation();
  const fetchAddressUrl = 'https://atlas.microsoft.com/search/address/reverse/json';

  const [markerPosition, setMarkerPosition] = useState<number[]>(props.position);
  const [address, setAddress] = useState<any>(null);
  const [mapOption, setMapOption] = useState<IAzureMapOptions>(option);
  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    if (props.position) {
      setMarkerPosition(props.position)
      setMapOption({
        ...option,
        center: props.position
      })
    }
  }, [props.position])

  const onDragEnd = (e: any) => {
    setMarkerPosition(e.target.getOptions()?.position);
    getPostionAddress(e.target.getOptions()?.position);
  }
  
  const eventToMarker: Array<IAzureMapHtmlMarkerEvent> = [
    { eventName: 'dragend', callback: onDragEnd },
  ];

  const getPostionAddress = async (position: any) => {
    try {
      const response = await fetch(`${fetchAddressUrl}?api-version=1.0&subscription-key=${ENV.MAP_KEY}&query=${position[1]},${position[0]}`);
      const data = await response.json();
      setAddress(data)
      setDisabled(false)
    } catch (error) {
      // console.log('error', error);
      alert('Something went wrong while fetching location details');
      setAddress(null)
    }
  }

  const onPickLocation = () => {
    props.markedLocation(markerPosition, address)
    setDisabled(true)
  }

  return (
    <Modal 
      isOpen={props.openModal} 
      onDismiss={() => props.setOpenModal(false)}
      containerClassName={classNames.modalContainer}
      styles={{
        scrollableContent: classNames.scrollableContent
      }}
      className={'react_map_controller_modal'}
    >
      <div className={classNames.header}>
        <span>{t('map')}</span>
        <IconButton
          styles={cancelIconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={() => {
            props.setOpenModal(false);
          }}
        />
      </div>
      <div className={classNames.detailBar}>
        <div className={classNames.detailBarFlex}>
          <Label className={classNames.label}>{t('Latitude')}:</Label>
          <div className={classNames.position}>{markerPosition?.[0]}</div>
        </div>
        
        <div className={classNames.detailBarFlex}>
          <Label className={classNames.label}>{t('Longitude')}:</Label>
          <div className={classNames.position}>{markerPosition?.[1]}</div>
        </div>

        <div>
          <PrimaryButton onClick={onPickLocation} disabled={disabled}>
            {t('PickThisLocation')}
          </PrimaryButton>
        </div>
      </div>
      <div className={classNames.mapContainer}>
        <AzureMapsProvider>
          <AzureMap
            options={{...mapOption, center: mapOption?.center }}
            // styles={{width: '100%', height: '100%'}}
            LoaderComponent={() => <Spinner size={SpinnerSize.large}/>}
            containerClassName={`azureMapComponent`}
          >
            <AzureMapHtmlMarker
              markerContent={
                <Icon
                  iconName="PinnedSolid"
                  // id='marker'
                  className={classNames.htmlMarkerIcon}
                />
              }
              options={{ 
                position: markerPosition, 
                draggable: true, 
              }}
              events={eventToMarker}
            />
          </AzureMap>
        </AzureMapsProvider>
      </div>
    </Modal>
  )
}

export default ReactAzureMap
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContractorsData } from '../../../../reducers/addressBookReducer';
import TaxonomyGrid from '../../../../shared/taxonomyField/taxonomyGrid';
import { getPerentFromTreeData } from '../../../../shared/util';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import TaxonomyActionGrid from '../../../../shared/taxonomyGrid/taxonomyActionGrid';
import { IColumn } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const LotTaxonomy = (props: {
  label?: string
  selectItemIds: string[] | null
  onChange?: any
  disabled: boolean;
}) => {
  const { t } = useTranslation();
  let tableColumns: IColumn[] = [
    {
      key: 'column1',
      name: t('contractors'),
      fieldName: 'contractorTaxonomyId',
      minWidth: 120,
      maxWidth: 300,
      isResizable: true,
    }];

  const dispatch = useDispatch();

  const contractorsData = useSelector(
    (state: any) => state.addressBook.contractors,
  );
  const [selectedParentIds, setSelectedParentIds]: any = useState([]);
  const [selectItemIds, setselectItemId]: any = useState(props.selectItemIds);
  //const [tempSelectItemIds, setTempselectItemId]: any = useState(props.selectItemIds);
  const [dataList, setDataList] = useState<any[]>([]);
  //const [ tempDataList, setTempDataList] = useState<any[]>([]);
  // const [selectItemId, setselectItemId]: any = useState("88bc4819-hn12-4c38-8a59-20da60ecb89e");

  useEffect(() => {
    dispatch(getContractorsData());
  }, []);

  useEffect(() => {
    if (selectItemIds) {
      if (selectItemIds && _.isArray(selectItemIds) && selectItemIds.length > 0) {
        let list = selectItemIds.map((item: any) => {
          return { 'contractorTaxonomyId': item };
        });
        setDataList(list);
      }
      setSelectedParentIds(
        getPerentFromTreeData(contractorsData, selectItemIds),
      );
    } else {
      setDataList([]);
      setSelectedParentIds([]);
    }
  }, [contractorsData, selectItemIds]);

  useEffect(() => {
    // setDataList(props.selectItemIds!!);
    setselectItemId(props.selectItemIds);
  }, [props.selectItemIds]);

  const renderButtons = (rowInfo: any) => {
    const isParent = selectedParentIds?.includes(rowInfo.node.id);
    const isSelected = selectItemIds?.includes(rowInfo.node.id);

    return (
      <div
        key={rowInfo.node.id}
        className={'Indeterminate'}
        style={{ paddingTop: 6 }}
      ><span
        style={{ display: 'none' }}>{JSON.stringify(isParent)}</span> {/* if u remove this part parent selection not working properly   */}
        <Checkbox
          className="btn btn-outline-success"
          styles={{root: { verticalAlign: 'middle'} }}
          checked={isSelected}
          onChange={(ev: any, checked: boolean | undefined) =>
            handleNodeCheckboxClick(rowInfo, checked)
          }
        />
      </div>
    );


  };

  const handleNodeCheckboxClick = (rowInfo: any, checked: boolean | undefined) => {
    const currentList: any[] = Array.isArray(selectItemIds) ? selectItemIds : [];
    const ids: any[] = [];
    if (checked) {
      ids.push(rowInfo?.node?.id);
      currentList.forEach((teamItem: any) => {
        ids.push(teamItem);
      });
      const results = ids.filter(element => {
        return element !== '';
      });
      let list = results.map((item: any) => {
        return { 'contractorTaxonomyId': item };
      });
      setDataList(list);
      setselectItemId(results);
    } else {
      let filteredArray = currentList.filter(e => e !== rowInfo?.node?.id);
      const results = filteredArray.filter(element => {
        return element !== '';
      });
      let list = results.map((item: any) => {
        return { 'contractorTaxonomyId': item };
      });
      setDataList(list);
      setselectItemId(results);
    }
  };

  return (
    <div>

      <TaxonomyActionGrid
        label={props.label}
        treeData={contractorsData}
        dataList={Array.isArray(dataList) ? dataList : []}
        columns={tableColumns}
        breadcrumbColumn={'contractorTaxonomyId'}
        isLoaded={true}
        disabled={props.disabled}
        onCloseClick={() => {
          props.onChange(selectItemIds);
        }}
      >
        <TaxonomyGrid
          treeData={contractorsData}
          renderButtons={renderButtons}
        />
      </TaxonomyActionGrid>

    </div>
  );
};


export default LotTaxonomy;

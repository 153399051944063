interface TreeNode {
    id: string;
    articleNo: string;
    title: string;
    key1: string;
    value1: string;
    key2: string;
    value2: string;
    key3: string;
    value3: string;
    key4: string;
    value4: string;
    key5: string;
    value5: string;
    measurementCode: string;
    quantity: string;
    mou: string;
    unitPrice: string;
    totalPrice: string;
    children: TreeNode[];
}

const sortData = (data: string[][]): string[][] => {
    return data.sort((a, b) => {
        const aParts = a[0].split('.').map(parseFloat);
        const bParts = b[0].split('.').map(parseFloat);

        for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
            const partA = aParts[i] || 0;
            const partB = bParts[i] || 0;

            if (partA < partB) return -1;
            if (partA > partB) return 1;
        }

        return 0;
    });
};

const createTree = (data: string[][]): TreeNode => {
    const sortedData = sortData(data);

    const root: TreeNode = {
        id: '',
        articleNo: '',
        title: 'Root',
        key1: '',
        value1: '',
        key2: '',
        value2: '',
        key3: '',
        value3: '',
        key4: '',
        value4: '',
        key5: '',
        value5: '',
        measurementCode: '',
        quantity: '',
        mou: '',
        unitPrice: '',
        totalPrice: '',
        children: []
    };
    const stack: TreeNode[] = [root];

    sortedData.forEach((item:any) => {

        let id = item[0] ?? null;
        let articleNo = item[0] ?? null;
        let title = item[1] ?? null;
        let key1 = item?.dynamicData?.key1 ?? null;
        let value1 = item?.dynamicData?.value1 ?? null;
        let key2 =item?.dynamicData?.key2 ?? null;
        let value2 = item?.dynamicData?.value2 ?? null;
        let key3 = item?.dynamicData?.key3 ?? null;
        let value3 =item?.dynamicData?.value3 ?? null;
        let key4 = item?.dynamicData?.key4 ?? null;
        let value4 = item?.dynamicData?.value4 ?? null;
        let key5 = item?.dynamicData?.key5 ?? null;
        let value5 = item?.dynamicData?.value5 ?? null;
        let measurementCode = item[7] ?? null
        let quantity = item[8] ?? null;
        let mou = item[9] ?? null
        let unitPrice = item[10] ?? null;
        let totalPrice = item[11] ?? null;

        const newNode: TreeNode = {
            id,
            articleNo,
            title,
            key1,
            value1,
            key2,
            value2,
            key3,
            value3,
            key4,
            value4,
            key5,
            value5,
            measurementCode,
            quantity,
            mou,
            unitPrice,
            totalPrice
            , children: []
        };
        let parent = stack[stack.length - 1];

        while (parent.articleNo !== '' && !articleNo.startsWith(parent.articleNo + '.')) {
            stack.pop();
            parent = stack[stack.length - 1];
        }

        parent.children.push(newNode);
        stack.push(newNode);
    });

    return root;
};

const SortedDataComponent = (dataSet: any) => {
    const root = createTree(dataSet);

    return root.children;
};

export default SortedDataComponent;

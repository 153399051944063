export const LOAD_INVOICE_SHORTCUTPANE = 'uprince/LOAD_INVOICE_SHORTCUTPANE';
export const LOAD_INVOICE_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_INVOICE_SHORTCUTPANE_SUCCESS';
export const LOAD_INVOICE_SHORTCUTPANE_FAIL = 'uprince/LOAD_INVOICE_SHORTCUTPANE_FAIL';

export const LOAD_INVOICE_LSITPANE = 'uprince/LOAD_INVOICE_LSITPANE';
export const LOAD_INVOICE_LSITPANE_SUCCESS = 'uprince/LOAD_INVOICE_LSITPANE_SUCCESS';
export const LOAD_INVOICE_LSITPANE_FAIL = 'uprince/LOAD_INVOICE_LSITPANE_FAIL';

export const LOAD_INVOICE_DROPDOWN = 'uprince/LOAD_INVOICE_DROPDOWN';
export const LOAD_INVOICE_DROPDOWN_SUCCESS = 'uprince/LOAD_INVOICE_DROPDOWN_SUCCESS';
export const LOAD_INVOICE_DROPDOWN_FAIL = 'uprince/LOAD_INVOICE_DROPDOWN_FAIL';

export const SAVE_INVOICE = 'uprince/SAVE_INVOICE';
export const SAVE_INVOICE_SUCCESS = 'uprince/SAVE_INVOICE_SUCCESS';
export const SAVE_INVOICE_FAIL = 'uprince/SAVE_INVOICE_FAIL';

export const LOAD_INVOICE_BY_ID = 'uprince/LOAD_INVOICE_BY_ID';
export const LOAD_INVOICE_BY_ID_SUCCESS = 'uprince/LOAD_INVOICE_BY_ID_SUCCESS';
export const LOAD_INVOICE_BY_ID_FAIL = 'uprince/LOAD_INVOICE_BY_ID_FAIL';

export const SET_INVOICE_UID = 'uprince/SET_INVOICE_UID';
export const CLEAR_INVOICE_UID = 'uprince/CLEAR_INVOICE_UID';
export const CLEAR_INVOICE_FORM_DATA = 'uprince/CLEAR_INVOICE_FORM_DATA';
export const RESET_INVOICE_IS_CHANGE = 'uprice/RESET_INVOICE_IS_CHANGE';


export const APPROVED_INVOICE_CU = 'uprince/APPROVED_INVOICE_CU';
export const APPROVED_INVOICE_CU_SUCCESS = 'uprince/APPROVED_INVOICE_CU_SUCCESS';
export const APPROVED_INVOICE_CU_FAIL = 'uprince/APPROVED_INVOICE_CU_FAIL';

export const REVIEW_INVOICE_CU = 'uprince/REVIEW_INVOICE_CU';
export const REVIEW_INVOICE_CU_SUCCESS = 'uprince/REVIEW_INVOICE_CU_SUCCESS';
export const REVIEW_INVOICE_CU_FAIL = 'uprince/REVIEW_INVOICE_CU_FAIL';

import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';
import { PBS_TYPE, ProjectBreakdown } from '../../../types/projectBreakdownStructure';
import { isCBC } from '../../../shared/util';
import { savePbsStateAttr } from '../../../reducers/projectBreakdownReducer';
import { useDispatch, useSelector } from 'react-redux';
import { ICommandBarItemProps } from '@fluentui/react';

const CommandBarRight = (props: {
  createNew: (create: boolean, type: PBS_TYPE) => void;
  saveProductCatalogue: (save: boolean) => void;
  handleRemove: () => void;
  formData: ProjectBreakdown
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();


  const {
    isPbsSqModalOpen,
    formData
  } = useSelector(
    (state: any) => state.projectBreakdown
  );
  // const newClick = () => {
  //     messageService.sendMessage({ isNewPbs: true });
  //     props.createNew(true);
  // };

  const saveFormData = () => {
    messageService.sendMessage({ saveFormData: true });
  };

  const handleRemove = () => {
    props.handleRemove();
  };

  const handleRegular = () => {
    messageService.sendMessage({ isNewPbs: true, pbsType: PBS_TYPE.REGULAR });
    props.createNew(true, PBS_TYPE.REGULAR);
  };
  const handleSubContract = () => {
    messageService.sendMessage({ isNewPbs: true, pbsType: PBS_TYPE.SUB });
    props.createNew(true, PBS_TYPE.SUB);
  };


  const squareMeters=()=>{
    dispatch(savePbsStateAttr('isPbsSqModalOpen',!isPbsSqModalOpen))
  };

  const getItems = () => {
    const pbsItems:ICommandBarItemProps[] = [
      {
        key: 'new',
        text: t('new'),
        iconProps: { iconName: 'Add' },
        split: true,
        subMenuProps: {
          items: [
            { key: 'regular', text: t('clientName'), iconProps: { iconName: 'Add' }, onClick: () => handleRegular() },
            {
              key: 'subContract',
              text: t('subcontractor'),
              iconProps: { iconName: 'Add' },
              onClick: () => handleSubContract(),
            },
          ],
        },
        disabled: (false),
        onClick: () => handleRegular(),
      },
      {
        key: 'save',
        text: t('save'),
        iconProps: { iconName: 'Save' },
        onClick: () => {
          saveFormData();
        },
      },
      {
        key: 'Remove',
        onClick: () => {
          handleRemove();
        },
        name: t('remove'),
        iconProps: { iconName: 'Delete' },
        disabled: (!(props.formData.productId)),
      },
      {
        key: 'SquareMeters',
        onClick: () => {
          squareMeters();
        },
        name: formData?.pbsScopeOfWork?.quantity?formData?.pbsScopeOfWork?.quantity+' m'+'²':''+'m'+'²',
        iconProps: { iconName: 'Section' },
        disabled: ( !(props.formData.productId && formData.pbsType == PBS_TYPE.REGULAR)),
      },
    ];

    const cbcItems = [
      {
        key: 'new',
        text: t('new'),
        iconProps: { iconName: 'Add' },
        disabled: (false),
        onClick: () => handleSubContract(),
      },
      {
        key: 'save',
        text: t('save'),
        iconProps: { iconName: 'Save' },
        onClick: () => {
          saveFormData();
        },
      },
      // {
      //     key: 'Remove',
      //     onClick: () => {handleRemove();},
      //     name: t('remove'),
      //     iconProps: { iconName: 'Delete' },
      //     disabled: (!(props.formData.productId) )
      // }
    ];

    if (isCBC()) {
      return cbcItems;
    } else {
      return pbsItems;
    }
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );
};

export default CommandBarRight;

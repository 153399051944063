import React, {
    Component, useEffect, useState
} from 'react';
import { connect } from 'react-redux';
import {
    Stack,
    DetailsList,
    DetailsListLayoutMode,
    DetailsRow,
    Dropdown,
    Fabric,
    IColumn,
    IDetailsFooterProps,
    IDetailsListProps,
    IDetailsRowStyles,
    Label,
    ScrollablePane,
    ScrollbarVisibility,
    ShimmeredDetailsList,
    Sticky,
    StickyPositionType,
    TextField,
    Text,
    SelectionMode, IStackTokens, IStackStyles, IStackItemStyles, DefaultPalette
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import {
    OrganisationSettingListPaneFilter, OSListPaneItem
} from '../../../types/organisationSetting';
import { GroupedListBasicExample } from './groupedListBasicExample';
import SortableTreeListView from '../../../shared/sortableTreeListView/sortableTreeListView';
import { messageService } from '../../../services/messageService';


// Styles definition
const stackStyles: IStackStyles = { root: {} };
const stackItemStyles: IStackItemStyles = {
    root: {
        alignItems: 'center',
        background: DefaultPalette.themePrimary,
        color: DefaultPalette.white,
        display: 'flex',
        height: 50,
        justifyContent: 'center'
    }
};

// Tokens definition
const stackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 10
};

export const classNames = mergeStyleSets({
    wrapper: {
        height: 'calc(100vh - 25vh)!important',
        position: 'relative'
    },
    filter: {
        paddingBottom: 20,
        maxWidth: 100
    },
    header: { margin: 0 },
    row: { display: 'inline-block' }
});

const ListPaneComponent = (props: {
  filter: OrganisationSettingListPaneFilter;
  selection: any;
  columns: IColumn[];
  listPaneItems: OSListPaneItem[];
  handleListPaneItemClick: (id: string) => void;
  handleFilterChange: (filter: OrganisationSettingListPaneFilter) => void;
  isDataLoaded?: boolean;
  loadMsg: string;
  resetSelection: () => void;
  dropDownOptions: any;
}) => {
    const { t } = useTranslation();
    const [ filter, setFilter ] = useState<OrganisationSettingListPaneFilter>(props.filter);
    const [ listPaneItems, setListPaneItems ]: any = useState<OSListPaneItem[]>(props.listPaneItems);

    const customNodeRender = (node:any) => {
        return <div style={ { cursor: 'pointer' } } onClick={ () => {handleTitleClick(node);} } id={ node.id } key={ node.id } className={ 'lastNode' }>
            <table key={ node.id + 'table' } style={ { width: '100%' } }> <tr><td>{ node.organization?.title }</td></tr></table>
        </div>;
    };

    const removeParentIdFromSelectedLevel = async() => {
        const list = props.listPaneItems.map((item:any) => {
            return {
                ...item,
                parentId: item.organizationTaxonomyLevelId === filter.organizationTaxonomyLevelId ? null : item.parentId
            };
        });
        return list;
    };

    const handleTitleClick = (node:any) => {
        messageService.sendMessage({ selectedOrgSetting: node });
        props.handleListPaneItemClick('new');
    };

    useEffect(() => {
        setFilter(props.filter);
    }, [ props.filter ]);

    useEffect(() => {
        removeParentIdFromSelectedLevel().then((list:any) => {
            setListPaneItems(list);
        });
    }, [ props.listPaneItems ]);

    const displayMessage = () => {
        return (
            <div style={ { paddingTop: 36, paddingLeft: 20 } }>
                <Text>{ t(props.loadMsg) }</Text>
            </div>
        );
    };
    useEffect(() => {
        const divs = document.querySelectorAll('.rstcustom__node');

        divs.forEach(el => el.addEventListener('click', event => {
            props.handleListPaneItemClick('new');
        }));
    }, [ props.listPaneItems ]);

    const setActiveRow = async(id:any) => {
        alert('click');
        const nodeContents = Array.from(document.getElementsByClassName('rstcustom__node'));
        await nodeContents.forEach((element:any) => {
            element.style.background = 'rgb(255, 255, 255)';
        });
        const cild = document.getElementById(id);
        if(cild) {
            const r1 = cild.closest('.rstcustom__node');
            if(r1) {
                r1.setAttribute('id', id);
                const rstcustom__node = document.getElementById(r1.id);
                if(rstcustom__node) {
                    console.log('r1', rstcustom__node);
                    rstcustom__node.style.background = 'rgb(243 242 241)';
                }
            }
        }
        // let rstcustom_nodeContent=document.getElementById(id)?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement;
        // if(rstcustom_nodeContent){
        //   console.log(rstcustom_nodeContent)
        //   rstcustom_nodeContent.style.background='rgb(243 242 241)';
        // }
    };

    return (
        <div>
            <Stack tokens={ stackTokens }>
                { /* <Stack horizontal horizontalAlign="start" styles={stackStyles}>*/ }
                { /* <Stack.Item grow={3} styles={stackItemStyles}>*/ }
                { /* Grow is 3*/ }
                { /* </Stack.Item>*/ }
                { /* <Stack.Item grow={2} styles={stackItemStyles}>*/ }
                { /* Grow is 2*/ }
                { /* </Stack.Item>*/ }
                { /* <Stack.Item grow styles={stackItemStyles}>*/ }
                { /* Grow is 1*/ }
                { /* </Stack.Item>*/ }
                { /* </Stack>*/ }

                <Stack styles={ stackStyles }>
                    <div >

                        <div className={ `org wrapper-holder ${classNames.wrapper} orgSetting` }>
                            { /* <GroupedListBasicExample/>*/ }
                            { !props.loadMsg && <SortableTreeListView treeData={ listPaneItems }
                                customNodeLevel={ 7 }
                                customNodeRender={ (node:any) => customNodeRender(node) }
                                handleTitleClick={ (node:any) => {handleTitleClick(node);} }
                            /> }
                            { props.isDataLoaded && displayMessage() }
                        </div>


                    </div>
                </Stack>
            </Stack>

        </div>
    );
};

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        toleranceStatesFilter: state.projectBreakdown.toleranceStatesFilter,
        productStatesFilter: state.projectBreakdown.productStatesFilter,
        itemTypesFilter: state.projectBreakdown.itemTypesFilter
    };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ListPaneComponent);

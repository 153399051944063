import createDecorator from 'final-form-calculate';
import {
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Stack,
} from '@fluentui/react';
import React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { uPrinceTheme } from '../../../../../theme';
import { DropDownAdaptater } from '../../../../shared/fluentFinalForm';
import FixedDecimalNumberFormatField from '../../../../shared/fluentFinalForm/FixedDecimalNumberFormatField';
import { CURRENCY_PREFIX } from '../../../../shared/util';

const ProjectFinanceCard = (props: { finance: any, disabled: boolean, onChange: any, footer: any }) => {
  const { t } = useTranslation();
  const { projectScopeStatus, projectCurrency }: any = useSelector((state: any) => state.project);


  const stackItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 50,
      width: 0,
      flexGrow: 1,
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
  };

  const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
    fullWidth: { width: '100%' },
    halfWidth: { width: '49%' },
  });

  const onSubmit = async (values: any) => {
    props.onChange(values);
  };

  const isFloat = (n: any) => {
    return n === +n && n !== (n | 0);
  };

  const calculateTotalcost = (allValues: any) => {
    let spent: any = Number(parseFloat(allValues.customerBudgetSpent));
    let tobe: any = Number(parseFloat(allValues.toBeInvoiced));
    let minwork: any = Number(parseFloat(allValues.minWork));
    let extraWork: any = Number(parseFloat(allValues.extraWork));
    let vals = [];
    if (!isNaN(spent)) {
      vals.push(spent);
    }
    if (!isNaN(tobe)) {
      vals.push(tobe);
    }
    if (!isNaN(minwork)) {
      vals.push(minwork);
    }
    if (!isNaN(extraWork)) {
      vals.push(extraWork);
    }
    return (vals || []).reduce((sum: any, value: any) => sum + value, 0);
  };

  const calculatorFinance = createDecorator(
    {
      field: 'tenderBudget',
      updates: {
        differenceTenderAndCustomer: (requiredValue, allValues: any) => {
          let customerBudgetval: any = Number(parseFloat(allValues.customerBudget));
          if (!isNaN(customerBudgetval)) {
            return (requiredValue - customerBudgetval).toFixed(2);
          } else {
            return 0.00;
          }
        },
        differenceEstimatedCostAndTenderBudget: (requiredValue, allValues: any) => {
          let totalCost: any = Number(parseFloat(allValues.expectedTotalProjectCost));
          if (!isNaN(totalCost)) {
            return (requiredValue - totalCost).toFixed(2);
          } else {
            return 0.00;
          }
        },
      },
    },
    {
      field: 'customerBudget',
      updates: {
        differenceTenderAndCustomer: (requiredValue, allValues: any) => {
          let tenderBudgetval: any = Number(parseFloat(allValues.tenderBudget));
          if (!isNaN(tenderBudgetval)) {
            return (tenderBudgetval - requiredValue).toFixed(2);
          } else {
            return 0.00;
          }
        },
      },
    },
    {
      field: 'customerBudgetSpent',
      updates: {
        expectedTotalProjectCost: (requiredValue, allValues: any) => {
          return calculateTotalcost(allValues);
        },
      },
    },
    {
      field: 'toBeInvoiced',
      updates: {
        expectedTotalProjectCost: (requiredValue, allValues: any) => {
          return calculateTotalcost(allValues);
        },
      },
    },
    {
      field: 'minWork',
      updates: {
        expectedTotalProjectCost: (requiredValue, allValues: any) => {
          return calculateTotalcost(allValues);
        },
      },
    },
    {
      field: 'extraWork',
      updates: {
        expectedTotalProjectCost: (requiredValue, allValues: any) => {
          return calculateTotalcost(allValues);
        },
      },
    },
  );

  const getInitialValues = (fin: any) => {
    return {
      ...fin,
      totalBudget: fin?.totalBudget ? fin?.totalBudget : 0.00,
      budgetLabour: fin?.budgetLabour ? fin?.budgetLabour : 0.00,
      budgetMaterial: fin?.budgetMaterial ? fin?.budgetMaterial : 0.00,
      invoiced: fin?.invoiced ? fin?.invoiced : 0.00,
      paid: fin?.paid ? fin?.paid : 0.00,
      tenderBudget: fin?.tenderBudget ? fin?.tenderBudget : 0.00,
      customerBudget: fin?.customerBudget ? fin?.customerBudget : 0.00,
      differenceTenderAndCustomer: fin?.differenceTenderAndCustomer ? fin?.differenceTenderAndCustomer : 0.00,
      customerBudgetSpent: fin?.customerBudgetSpent ? fin?.customerBudgetSpent : 0.00,
      toBeInvoiced: fin?.toBeInvoiced ? fin?.toBeInvoiced : 0.00,
      minWork: fin?.minWork ? fin?.minWork : 0.00,
      extraWork: fin?.extraWork ? fin?.extraWork : 0.00,
      minAndExtraWork: fin?.minAndExtraWork ? fin?.minAndExtraWork : 0.00,
      expectedTotalProjectCost: fin?.expectedTotalProjectCost ? fin?.expectedTotalProjectCost : 0.00,
      differenceEstimatedCostAndTenderBudget: fin?.differenceEstimatedCostAndTenderBudget ? fin?.differenceEstimatedCostAndTenderBudget : 0.00,
    };
  };


  return (
    <Form
      onSubmit={onSubmit}
      initialValues={getInitialValues(props.finance)}
      decorators={[calculatorFinance]}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        // <form onSubmit={ handleSubmit }>
        <div>
          <FormSpy
            onChange={(spyProps: any) => {
              if (props.finance !== spyProps.values && props.onChange) {
                props.onChange(spyProps.values);
              }
            }}
          />
          <div className="document-pane-card">
            <div className={'card-header'}>
              <Link href="#" id="project-finance">
                <Label>5. {t('finance')}</Label>
              </Link>

            </div>

            <Label className={'sub-header-title'}>{5.1 + ' ' + t('projectFinanceStatus')}</Label>
            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >

              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="paid"
                    component={FixedDecimalNumberFormatField}
                    lable={t('paid')}
                    prefix={CURRENCY_PREFIX}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  {/*<Field*/}
                  {/*name="invoiced"*/}
                  {/*component={ FixedDecimalNumberFormatField }*/}
                  {/*lable={ t('invoiced') }*/}
                  {/*prefix={ CURRENCY_PREFIX }*/}
                  {/*disabled={ false }*/}

                  {/*/>*/}
                </div>
              </Stack.Item>
            </Stack>
            <Label className={'sub-header-title'}
                   style={{ marginTop: 10 }}>{5.2 + ' ' + t('projectFinanceBudget')}</Label>
            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="totalBudget"
                    component={FixedDecimalNumberFormatField}
                    lable={t('totalBudget')}
                    prefix={CURRENCY_PREFIX}
                    disabled={false}

                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="currencyId"
                    options={projectCurrency}
                    component={DropDownAdaptater}
                    lable={t('currency')}

                  />
                </div>
              </Stack.Item>
            </Stack>
            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="budgetMaterial"
                    component={FixedDecimalNumberFormatField}
                    lable={t('budgetMaterial')}
                    prefix={CURRENCY_PREFIX}
                    disabled={false}

                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="budgetLabour"
                    component={FixedDecimalNumberFormatField}
                    lable={t('budgetLabour')}
                    prefix={CURRENCY_PREFIX}
                  />
                </div>
              </Stack.Item>
            </Stack>
            <Label className={'sub-header-title'}
                   style={{ marginTop: 10 }}>{5.3 + ' ' + t('projectTenderFinance')}</Label>
            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="tenderBudget"
                    component={FixedDecimalNumberFormatField}
                    lable={t('tenderBudget')}
                    prefix={CURRENCY_PREFIX}
                    disabled={false}

                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="customerBudget"
                    component={FixedDecimalNumberFormatField}
                    lable={t('customerBudget')}
                    prefix={CURRENCY_PREFIX}
                  />
                </div>
              </Stack.Item>
            </Stack>
            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.halfWidth}`}>
                  <Field
                    name="differenceTenderAndCustomer"
                    component={FixedDecimalNumberFormatField}
                    lable={t('differenceTenderAndCustomer')}
                    prefix={CURRENCY_PREFIX}
                    disabled={true}
                    hasChanged={(props?.finance?.differenceTenderAndCustomer < 0)}

                  />
                </div>
              </Stack.Item>

            </Stack>
            <Label className={'sub-header-title'}
                   style={{ marginTop: 10 }}>{5.4 + ' ' + t('projectProgressFinance')}</Label>
            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="customerBudgetSpent"
                    component={FixedDecimalNumberFormatField}
                    lable={t('customerBudgetSpent')}
                    prefix={CURRENCY_PREFIX}
                    disabled={true}

                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="toBeInvoiced"
                    component={FixedDecimalNumberFormatField}
                    lable={t('toBeInvoiced')}
                    prefix={CURRENCY_PREFIX}
                    disabled={true}
                  />
                </div>
              </Stack.Item>
            </Stack>

            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="minWork"
                    component={FixedDecimalNumberFormatField}
                    lable={t('minWork')}
                    prefix={CURRENCY_PREFIX}
                    disabled={true}

                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="extraWork"
                    component={FixedDecimalNumberFormatField}
                    lable={t('extraWork')}
                    prefix={CURRENCY_PREFIX}
                    disabled={true}
                  />
                </div>
              </Stack.Item>
            </Stack>
            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.halfWidth}`}>
                  <Field
                    name="minAndExtraWork"
                    component={FixedDecimalNumberFormatField}
                    lable={t('minAndExtraWork')}
                    prefix={CURRENCY_PREFIX}
                    disabled={true}

                  />
                </div>
              </Stack.Item>

            </Stack>

            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="expectedTotalProjectCost"
                    component={FixedDecimalNumberFormatField}
                    lable={t('expectedTotalProjectCost')}
                    prefix={CURRENCY_PREFIX}
                    disabled={true}

                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="differenceEstimatedCostAndTenderBudget"
                    component={FixedDecimalNumberFormatField}
                    lable={t('differenceEstimatedCostAndTenderBudget')}
                    prefix={CURRENCY_PREFIX}
                    disabled={true}
                    hasChanged={(props?.finance?.differenceEstimatedCostAndTenderBudget < 0)}
                  />
                </div>
              </Stack.Item>
            </Stack>
            {props.footer}
          </div>
        </div>
      )}
    />


  );
};

export default ProjectFinanceCard;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IColumn, Selection } from '@fluentui/react/lib/DetailsList';
import history from '../../../history';

import ListPaneComponent from './component';
import { withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { getById, getRiskDropDownsList } from '../../../reducers/projectBreakdownReducer';
import { messageService } from '../../../services/messageService';
import { ITooltipHostStyles, mergeStyles } from '@fluentui/react';
import { ProgressStatement, PSDropDown, psFormData } from '../../../types/progressStatement';
import { getContractingUnit, getProject } from '../../../shared/util';
import { POFilter, POListItem } from '../../../types/purchaseOrder';
import { savePoAtrr } from '../../../reducers/purchaseOrderReducer';
import { store } from '../../../../index';

export interface State {
  redirect: boolean;
  isChange: boolean;
  loading: boolean;
  filter: POFilter;
  announcedMessage: string;
  selectedListItemIds: [] | string[];
  selectedListItemSequenceCode: [] | string[];
  formData: ProgressStatement;
  selectedListItems: POListItem[];
}

export interface Props extends WithTranslation {
  listPaneItems: POListItem[];
  reloadListPaneItem: () => void;
  handelFilter: (filter: POFilter) => void;
  isDataLoaded?: boolean;
  isChange: boolean;
  filter: POFilter;
  resourceTypes: [];
  loadMsg: string;
  toleranceStatesFilter: [];
  productStatesFilter: [];
  itemTypesFilter: [];
  handleSelectedListPaneItem: (selectedItemIds: [] | string[]) => void;
  formData: ProgressStatement;
  getById: (id: string) => void;
  projectId: string | null;
  psDropDowns: PSDropDown;
  reSizer: number;
  psDropDowns2: any
  savePoAtrr: any
}

const iconClass = mergeStyles({
  fontSize: 20,
  height: 20,
  width: 20,
  margin: 0,
  padding: 0,
});

const calloutProps = { gapSpace: 0 };
// The TooltipHost root uses display: inline by default.
// If that's causing sizing issues or tooltip positioning issues, try overriding to inline-block.
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

class ListPane extends Component<Props, State> {
  private _Selection: Selection;
  private _columns: IColumn[];
  private _columnsReSizer: IColumn[];
  private _columnsCu: IColumn[];
  subscription: any;

  constructor(props: Props) {
    super(props);
    this._Selection = new Selection({
      onSelectionChanged: () => {
        this.setState(
          {
            selectedListItemIds: this._getSelectedIds(),
            selectedListItemSequenceCode: this._getselectedSequenceCode(),
            selectedListItems: this._getSelectedListItems(),
          },
          this.handleSelectedItem,
        );
      },
    });
    this.state = {
      selectedListItems: [],
      selectedListItemIds: [],
      selectedListItemSequenceCode: [],
      redirect: false,
      isChange: false,
      loading: false,
      announcedMessage: '',
      filter: new POFilter(),
      formData: psFormData,
    };

    this._columns = [
      {
        key: 'column0',
        name: this.props.t(''),
        fieldName: 'resourcesIcon',
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
      },
      {
        key: 'column2',
        name: this.props.t('purchaseOrderTitle'),
        fieldName: 'title',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column3',
        name: this.props.t('type'),
        fieldName: 'requestType',
        minWidth: 150,
        maxWidth: 250,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column4',
        name: this.props.t('customer'),
        fieldName: 'customer',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column5',
        name: this.props.t('supplier'),
        fieldName: 'supplier',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column6',
        name: this.props.t('status'),
        fieldName: 'status',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column7',
        name: this.props.t('amount'),
        fieldName: 'totalAmount',
        minWidth: 165,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        className: 'totalAmountCol',
      },
      {
        key: 'column8',
        name: this.props.t('lastModifiedDate'),
        fieldName: 'lastModifiedDate',
        minWidth: 165,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column9',
        name: this.props.t('modifiedPerson'),
        fieldName: 'modifiedBy',
        minWidth: 165,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        className: 'modifiedPerson',
      },
    ];
    this._columnsCu = [
      {
        key: 'column0',
        name: this.props.t(''),
        fieldName: 'resourcesIcon',
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
      },
      {
        key: 'column1',
        name: this.props.t('projectTitle'),
        fieldName: 'projectSequenceCode',
        minWidth: 100,
        maxWidth: 120,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column2',
        name: this.props.t('purchaseOrderTitle'),
        fieldName: 'title',
        minWidth: 150,
        maxWidth: 250,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column3',
        name: this.props.t('type'),
        fieldName: 'requestType',
        minWidth: 150,
        maxWidth: 250,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column4',
        name: this.props.t('customer'),
        fieldName: 'customer',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column5',
        name: this.props.t('supplier'),
        fieldName: 'supplier',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column6',
        name: this.props.t('status'),
        fieldName: 'status',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column7',
        name: this.props.t('amount'),
        fieldName: 'totalAmount',
        minWidth: 165,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        className: 'totalAmountCol',
      },
      {
        key: 'column8',
        name: this.props.t('lastModifiedDate'),
        fieldName: 'lastModifiedDate',
        minWidth: 165,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column9',
        name: this.props.t('modifiedPerson'),
        fieldName: 'modifiedBy',
        minWidth: 165,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        className: 'modifiedPerson',
      },
    ];
    this._columnsReSizer = [
      {
        key: 'column2',
        name: this.props.t('purchaseOrderTitle'),
        fieldName: 'title',
        minWidth: 165,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
    ];
  }

  static getDerivedStateFromProps(nextProps: any) {
    if (nextProps.isChange) {
      //nextProps.reloadListPaneItem();
    }
    return {
      loading: false,
      filter: nextProps.filter,
      formData: nextProps.formData,
    };
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
    this.props.savePoAtrr("selectedPoList",[]);
  }

  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewPO) {
          this._Selection.selectToKey('', true);
        }
        if (data.data.resetListPaneSelection) {
          this._Selection.setAllSelected(false);
          this.resetSelection();
        }
      }
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.isDataLoaded != this.props.isDataLoaded) {
      if (this.props.isDataLoaded) {
        this.resetSelection();
      }
    }
    if (prevProps.isChange != this.props.isChange) {
      if (this.props.isChange) {
        this.props.reloadListPaneItem();
      }
    }
    if (prevState.selectedListItems != this.state.selectedListItems) {
      this.props.savePoAtrr("selectedPoList",this.state.selectedListItems);
    }
  }

  handleListPaneItemClick = (id: string) => {
    console.log({id});
    //this.props.getById(id);
    if (getContractingUnit() && getProject()) {
      history.push(`/CU/${getContractingUnit()}/project/${getProject()}/purchase-order/${id}`);
    } else {
      history.push(`/CU/${getContractingUnit()}/purchase-order/${id}`);
    }
  };

  handleSelectedItem = () => {
    this.props.handleSelectedListPaneItem(this.state.selectedListItemIds);
    if (this.state.selectedListItemSequenceCode) {
      let lastId = this.state.selectedListItemSequenceCode.pop();
      if (lastId) {
        this.handleListPaneItemClick(lastId);
      }
    }
  };

  handleFilterChange = (filter: POFilter) => {
    this.props.handelFilter(filter);
  };

  handleFilterSortChange = () => {
    this.props.handelFilter(this.state.filter);
  };

  resetSelection = () => {
    if (
      this.state.formData &&
      this.state.formData.progressStatementId &&
      this.props.isDataLoaded
    ) {
      this._Selection.setKeySelected(
        this.state.formData.progressStatementId,
        true,
        false,
      );
    } else {
      this._Selection.selectToKey('', true);
    }
  };

  getsequenceIds=()=>{
    const sqid=store.getState().po?.selectedPoList.map((item:any)=>{
      return item?.sequenceId
    });

    return sqid;
  }

  render() {
    return (
      <div>
        <ListPaneComponent
          listPaneItems={
            this.props.listPaneItems ? this.props.listPaneItems : []
          }
          selection={this._Selection}
          handleListPaneItemClick={(id: string) =>
            this.handleListPaneItemClick(id)
          }
          resetSelection={() => this.resetSelection()}
          formData={this.state.formData}
          columns={(getProject()) ? (this.props.reSizer === 300) ? this._columnsReSizer : this._columns : (this.props.reSizer === 300) ? this._columnsReSizer : this._columnsCu}
          //columns={this._columns}
          filter={this.props.filter}
          loadMsg={this.props.loadMsg}
          resourceTypes={this.props.resourceTypes}
          handleFilterChange={(filter: POFilter) =>
            this.handleFilterChange(filter)
          }
          isDataLoaded={this.props.isDataLoaded}
          dropDownOptions={this.props.psDropDowns2}
          filterPOByResourceType={(resourceType: string) => this.filterPOByResourceType(resourceType)}
        />
      </div>
    );
  }

  private _getSelectedIds() {
    let deleteList: any = [];
    let SelectedItem = this._Selection.getSelection() as POListItem[];
    if (SelectedItem) {
      deleteList = SelectedItem.map((item: any) => {
        return item ? item.id : null;
      });
    }
    return deleteList;
  }

  private _getselectedSequenceCode() {
    let selectedList: any = [];
    let selectedItem = this._Selection.getSelection() as POListItem[];
    if (selectedItem) {
      selectedList = selectedItem.map((item: any) => {
        return item ? item.progressStatementId : null;
      });
    }
    return selectedList;
  }

  private _getSelectedListItems = () => {
    let SelectedListItems = this._Selection.getSelection() as POListItem[];

    return SelectedListItems;
  };

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn,
  ): void => {
    const newColumns: IColumn[] = (getProject()) ? this._columns.slice() : this._columnsCu.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key,
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        let filter = this.state.filter;

        let fieldName = currColumn.fieldName;

        let sortby = currColumn.isSortedDescending ? 'desc' : 'asc';
        filter.sorter.attribute = fieldName ? fieldName : null;
        filter.sorter.order = sortby ? sortby : null;
        this.setState(
          {
            filter: filter,
          },
          this.handleFilterSortChange,
        );
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
  };


  filterPOByResourceType = (resourceType: string) => {
    let filter = this.state.filter;
    filter.resource = resourceType;
    this.setState(
      {
        filter: filter,
      },
      this.handleFilterSortChange,
    );
  };
}

const mapStateToProps = (state: any, ownProps: any) => {

  return {
    loadMsg: state.po.loadMsg,
    isChange: state.po.isChange,
    psDropDowns: state.ps.psDropDowns,
    psDropDowns2: state.po.purchaseOrderDropDowns,
  };
};

const mapDispatchToProps = {
  getById,
  getRiskDropDownsList,
  savePoAtrr
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ListPane)),
);

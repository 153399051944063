import {
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Stack,
} from '@fluentui/react';
import React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { uPrinceTheme } from '../../../../../theme';
import { DropDownAdaptater } from '../../../../shared/fluentFinalForm';

const ProjectClassificationCard = (props: { classification: any, disabled: boolean, lable: string, onChange: any, footer: any }) => {
  const { t } = useTranslation();
  const { projectScopeStatus, projectClassificationSize, projectClassificationSector, projectClassificationConstructionType, projectClassificationBuisnessUnit }: any = useSelector((state: any) => state.project);


  console.log('pro', props.classification);

  const stackItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 50,
      width: 0,
      flexGrow: 1,
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
  };

  const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
    fullWidth: { width: '100%' },
    halfWidth: { width: '49%' },
  });

  const onSubmit = async (values: any) => {
    props.onChange(values);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={props.classification ? props.classification : {
        projectClassificationSectorId: null,
        projectClassificationConstructionTypeId: null,
        projectClassificationSizeId: null,
        projectClassificationBuisnessUnit: null,
      }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        // <form onSubmit={ handleSubmit }>
        <div>
          <FormSpy
            onChange={(spyProps: any) => {
              if (props.classification !== spyProps.values && props.onChange) {
                props.onChange(spyProps.values);
              }
            }}
          />
          <div className="document-pane-card">
            <div className={'card-header'}>
              <Link href="#" id="project-classification">
                <Label>{props.lable}</Label>
              </Link>

            </div>
            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="projectClassificationSectorId"
                    component={DropDownAdaptater}
                    options={projectClassificationSector}
                    lable={t('sector')}
                    placeholder={t('sector')}
                    disabled={false}

                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="projectClassificationConstructionTypeId"
                    component={DropDownAdaptater}
                    options={projectClassificationConstructionType}
                    lable={t('constructionType')}
                    placeholder={t('constructionType')}
                  />
                </div>
              </Stack.Item>
            </Stack>

            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="projectClassificationSizeId"
                    component={DropDownAdaptater}
                    options={projectClassificationSize}
                    lable={t('size')}
                    placeholder={t('size')}
                    disabled={false}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="projectClassificationBuisnessUnit"
                    component={DropDownAdaptater}
                    options={projectClassificationBuisnessUnit}
                    lable={t('businessUnit')}
                  />
                </div>
              </Stack.Item>
            </Stack>
            {props.footer}
          </div>
        </div>
      )}
    />


  );
};

export default ProjectClassificationCard;

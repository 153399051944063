import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { CorporateProductCatalogueListItem } from '../../../types/corporateProductCatalogue';
import { messageService } from '../../../services/messageService';

const CpcCommandBarLeft = (props: {
  createNewProductCatalogue: (create: boolean) => void,
  exportExcel: () => void
  handleRemoveCpc: () => void
  handleCloneCpcItem: () => void
  isClone: boolean;
  selectedCpcItemIds: [] | string[],
  cpcListItem: CorporateProductCatalogueListItem[],
  excelData: [],
}) => {
  const { t } = useTranslation();
  const newClick = () => {
    messageService.sendMessage({
      resetCpcListSelection: true,
      isNewCpc: true,
    });
    props.createNewProductCatalogue(true);
  };
  const getItems = () => {
    return [
      {
        key: 'new',
        text: t('new'),
        iconProps: { iconName: 'Add' },
        onClick: () => {
          newClick();
        },
      },
      {
        key: 'Clone',
        name: t('clone'),
        disabled: (props.selectedCpcItemIds.length != 1 && !props.isClone),
        iconProps: { iconName: 'Copy' },
        onClick: () => {
          props.handleCloneCpcItem();
        },
      },
      {
        key: 'Edit',
        disabled: (props.selectedCpcItemIds.length != 1 && !props.isClone),
        name: t('edit'),
        iconProps: { iconName: 'Edit' },
      },
      {
        key: 'Remove',
        onClick: () => {
          props.handleRemoveCpc();
        },
        name: t('remove'),
        iconProps: { iconName: 'Delete' },
        disabled: (!(props.cpcListItem.length > 0 && props.selectedCpcItemIds.length > 0)),
      },
      {
        key: 'Export',
        onClick: () => {
          props.exportExcel();
        },
        name: t('export'),
        iconProps: { iconName: 'Export' },
        //disabled: (props.excelData.length ===0),
      },
      {
        key: 'Print',
        name: t('print'),
        disabled: true,
        iconProps: { iconName: 'Print' },
      },
    ];
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );
};

export default CpcCommandBarLeft;

import {getTheme, ITheme, Panel, PanelType} from '@fluentui/react';
import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import SplitPane, {Pane} from 'react-split-pane';
import history from '../../history';
import {messageService} from '../../services/messageService';
import ConfirmationDialog from '../../shared/confirmationDialog/confirmationDialog';
import {excelExport, excelFormatedExport, formatDateTimeToISOString, getContractingUnit, getProject} from '../../shared/util';
import {BookMarkItem} from '../../types/template';
import '../style/style.css';
import CommandBarLeft from './commandBar/commandBarLeft';
import CommandBarRight from './commandBar/commandBarRight';
import DocumentPane from './documentPane/container';
import DocumentPaneSection from '../../shared/mainUI/documentPaneSection/documentPaneSection';
import withMainUI from '../../shared/mainUI/withMainUI';
import ListPane from './listPane/container';
import ListPaneSection from '../../shared/mainUI/listPaneSection/component';
import ShortCutPane from './shortCutPane/container';
import {getFilterQRCodes} from './../../reducers/templateReducer';
import {
  clearFormData,
  filterPurchaseOrderListPaneData,
  readByPurchaseOrderId,
  readPurchaseOrderDropDownData,
  readPurchaseOrderShortCutPaneData,
} from '../../reducers/purchaseOrderReducer';
import {
  GOODDEERIS_PRODUCT_TYPE_ID,
  POFilter,
  poFormData,
  POListItem,
  POShortCutPaneItem,
  PRODUCT_TYPE_ID,
  RESOURCE_TYPE_ID,
} from '../../types/purchaseOrder';
import _ from 'lodash';
import moment from 'moment';
import {resizerStyle} from "../../shared/customStyle/commonStyles";
import { store } from '../../..';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfTemplate from '../rfqInvitation/pdf/pdfTemplate';
import { PURCHASE_ORDER_SAVE_EP } from '../../shared/endpoints';
import client from '../../api';

export interface State {
  selectedShortCutItem: POShortCutPaneItem;
  filter: POFilter;
  formData: any;
  loading: boolean;
  showPanel: boolean;
  width: number;
  activeClass: string;
  bookmarkDivClass: string;
  showDetails: boolean;
  autoSave: boolean;
  isEdit: boolean;
  isDisableNewButton: boolean;
  isDrag: boolean;
  resetLayout: boolean;
  collapseBookmarkPane: boolean;
  reSizer: number;
  projectId: null | string;
  purchaseOrderType: null | string;
  selectedProject: null | string;
  isNewPO: boolean;
  showProgressBar: boolean;
  poRequestType: null | string;
}

interface Props extends WithTranslation {
  match: any;
  resetFormData: () => void;
  showProgressBar: boolean;
  shortCutPaneFilters: POShortCutPaneItem[];
  formData: any;
  setUid: () => void;
  clearUid: () => void;
  resetIsChange: () => void;
  uid: string | null;
  autoSave: boolean;
  isChange: boolean;
  isEdit: boolean;
  isDataLoaded?: boolean;
  loadMsg: string;
  location: any;
  loading: boolean;
  handleShowDocumentPane: (collapseBookmarkPane?: boolean) => void;
  reSizer: number;
  collapseBookmarkPane: boolean;
  showDetails: boolean;
  showPanel: boolean;
  handleScroll: () => void;
  currentActiveSection: string;
  animation: any;
  getFilterQRCodes: (filter: any) => void;
  readPurchaseOrderShortCutPaneData: () => void;
  filterPurchaseOrderListPaneData: (filter: POFilter) => void;
  readPurchaseOrderDropDownData: () => void;
  readByPurchaseOrderId: (id: string) => void;
  clearFormData: () => void;
  shortcutPaneData: POShortCutPaneItem[];

  poList: POListItem[];
  isPOListLoaded: boolean;
  purchaseOrderDropDowns: any;
  reloadListPane: boolean;
  isLoadingPO: boolean;
  projectHeader: any;
}

class PurchaseOrderMainLayout extends React.Component<Props, State> {
  _isMounted = false;
  theme: ITheme = getTheme();
  subscription: any;

  bookmarkList: BookMarkItem[] = [
    {
      id: 1,
      link: 'po-header',
      label: this.props.t('header'),
    },
    {
      id: 2,
      link: 'po-delivery-details',
      label: this.props.t('deliveryDetails'),
    },
    {
      id: 3,
      link: 'po-involved-parties',
      label: this.props.t('involvedParties'),
    },
    {
      id: 4,
      link: 'history',
      label: this.props.t('history'),
    },
  ];
  bookmarkListProduct: BookMarkItem[] = [
    {
      id: 1,
      link: 'po-header',
      label: this.props.t('header'),
    },
    {
      id: 2,
      link: 'po-delivery-details',
      label: this.props.t('deliveryDetails'),
    },
    {
      id: 3,
      link: 'po-involved-parties',
      label: this.props.t('involvedParties'),
    },
    { id: 4, link: 'po-external-products', label: this.props.t('externalProducts') },
    {
      id: 5,
      link: 'history',
      label: this.props.t('history'),
    },
  ];
  bookmarkListResource: BookMarkItem[] = [
    {
      id: 1,
      link: 'po-header',
      label: this.props.t('header'),
    },
    {
      id: 2,
      link: 'po-delivery-details',
      label: this.props.t('deliveryDetails'),
    },
    {
      id: 3,
      link: 'po-involved-parties',
      label: this.props.t('involvedParties'),
    },
    { id: 4, link: 'po-resources', label: this.props.t('resources') },
    {
      id: 5,
      link: 'history',
      label: this.props.t('history'),
    },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      filter: new POFilter(),
      selectedShortCutItem: {
        id: null,
        name: null,
        type: null,
        value: null,
      },
      isEdit: false,
      autoSave: false,
      loading: false,
      width: window.innerWidth,
      showPanel: false,
      activeClass: 'grid-righter column-filter-activated',
      bookmarkDivClass: 'bookmark-panel parent',
      showDetails: false,
      formData: poFormData,
      isDisableNewButton: false,
      isDrag: false,
      resetLayout: false,
      collapseBookmarkPane: false,
      reSizer: screen.width,
      projectId: null,
      purchaseOrderType: null,
      selectedProject: null,
      isNewPO: false,
      showProgressBar: false,
      poRequestType: null,
    };
  }

  componentDidMount() {
    this.handleUrlChange();
    this.props.readPurchaseOrderDropDownData();
    this.props.readPurchaseOrderShortCutPaneData();
    this.props.filterPurchaseOrderListPaneData(new POFilter());

    if (
      location.pathname.split('/').pop() === 'new' &&
      this.state.formData &&
      this.state.formData.id === null
    ) {
      this.createNew();
    }
    this.setState(
      {
        formData: this.props.formData,
      },
      this.props.handleShowDocumentPane,
    );
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.hideDocumentPane) {
          this.hideDocumentPane();
        }

        if (data.data.isNewPO) {
          this.createNew();
          if (data.data.poRequestType) {
            this.setState({
              poRequestType: data.data.poRequestType,
            });
          } else {
            this.setState({
              poRequestType: null,
            });
          }
        }
        if (data.data.purchaseOrderType) {
          this.setState({ purchaseOrderType: data.data.purchaseOrderType });
        }
        if (data.data.getProjectHeader) {
          this.setState({ selectedProject: data.data.getProjectHeader });
        }

        // if (
        //   data &&
        //   data.data &&
        //   data.data.hasOwnProperty('isLoadingPMOLData') &&
        //   data.data.isLoadingPMOLData
        // ) {
        //   this.setState({ isDisableNewButton: true });
        // } else if (
        //   data.data.hasOwnProperty('isLoadingPMOLData') &&
        //   !data.data.isLoadingPMOLData
        // ) {
        //   this.setState({ isDisableNewButton: false });
        // }

        if (data.data.exportPoExcel) {
          this.exportPoExcel();
        }
        if (data.data.exportPricePoExcel) {
          this.exportPricePoExcel();
        }
      }
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.reloadListPane !== prevProps.reloadListPane && this.props.reloadListPane) {
      this.reloadListPaneItem();
    }
    if (this.props.match.url !== prevProps.match.url) {
      this.handleUrlChange(prevState.collapseBookmarkPane);
    }
    if (this.props.showProgressBar !== prevProps.showProgressBar) {
      this.setState({
        showProgressBar: this.props.showProgressBar,
      });
    }
    if (this.props.collapseBookmarkPane !== prevProps.collapseBookmarkPane) {
      this.setState({
        collapseBookmarkPane: this.props.collapseBookmarkPane,
      });
    }
    if (this.props.formData !== prevProps.formData) {
      this.setState({
        formData: this.props.formData,
      });
      if (this.props.formData?.type?.key) {
        this.setState({
          purchaseOrderType: this.props.formData?.type?.key,
        });
      }
      if (this.props.formData?.requestType?.key) {
        this.setState({
          poRequestType: this.props.formData?.requestType?.key,
        });
      }
      if (getProject()) {
        this.setState({
          selectedProject: getProject(),
        });
      } else if (this.props.formData?.projectSequenceCode) {
        this.setState({
          selectedProject: this.props.formData?.projectSequenceCode,
        });
      }
    }
    if (
      this.props.location &&
      this.props.location.state &&
      this.state.filter != this.props.location.state.poFilter
    ) {
      if (this.props.location.state.poFilter) {
        this.setState(
          {
            filter: this.props.location.state.poFilter,
          },
          this.reloadListPaneItem,
        );
      }
    }
  }

  handleUrlChange(collapseBookmarkPane?: boolean) {
    let id = this.props.match.params.featureId ? this.props.match.params.featureId : null;
    let isNew = location.pathname.split('/').pop() === 'new';
    if (id) {
      this.setState(
        {
          isEdit: !!this.props.match.params.id,
          isDisableNewButton: id ? true : false,
          isDrag: false,
          resetLayout: collapseBookmarkPane ? false : true,
          collapseBookmarkPane: collapseBookmarkPane ? true : false,
        }, () => {
          this.props.handleShowDocumentPane(collapseBookmarkPane);
        });
      this.props.readByPurchaseOrderId(id);
    } else {
      this.setState(
        {
          isEdit: false,
          isDisableNewButton: false,
          isDrag: false,
          resetLayout: true,
          collapseBookmarkPane: false,
          reSizer: isNew ? screen.width / 2 : screen.width,
        }, () => {
          this.props.handleShowDocumentPane();
        });
    }


  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  exportPoExcel = (): void => {
    let projectNumber = this.props.formData?.projectSequenceCode ? this.props.formData?.projectSequenceCode : ' ';
    let supplierName = this.props.formData?.supplierOrganisation ? `- ${this.props.formData?.supplierOrganisation}` : ' ';
    let poTitle = this.props.formData?.title ? `- ${this.props.formData?.title}` : ' ';
    this.setState({
      showProgressBar: true,
    });
    this.formatDataToExcel().then((excelData) => {
      if (excelData) {
        excelFormatedExport(
          excelData,
          // this.props.t('purchaseOrder'),
          //file name:  yyyymmdd - projectNumber - supplierName - PO title
          `${projectNumber}${supplierName}${poTitle}`,
          this.props.t('purchaseOrder'),
        );
      }
      setTimeout(() => {
        this.setState({
          showProgressBar: false,
        });
      }, 3000);
    });
    setTimeout(() => {
      this.setState({
        showProgressBar: false,
      });
    }, 3000);
  };

  exportPricePoExcel = (): void => {
    let projectNumber = this.props.formData?.projectSequenceCode ? this.props.formData?.projectSequenceCode : ' ';
    let supplierName = this.props.formData?.supplierOrganisation ? `- ${this.props.formData?.supplierOrganisation}` : ' ';
    let poTitle = this.props.formData?.title ? `- ${this.props.formData?.title}` : ' ';
    this.setState({
      showProgressBar: true,
    });
    this.formatPriceDataToExcel().then((excelData) => {
      if (excelData) {
        excelExport(
          excelData,
          // this.props.t('pricePurchaseOrder'),
          //file name:  yyyymmdd - projectNumber - supplierName - PO title
          `${projectNumber}${supplierName}${poTitle}`,
          this.props.t('pricePurchaseOrder'),
        );
      }
      setTimeout(() => {
        this.setState({
          showProgressBar: false,
        });
      }, 3000);
    });
    setTimeout(() => {
      this.setState({
        showProgressBar: false,
      });
    }, 3000);


  };

  removeTags = (str: string) => {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();
    return str.replace(/(<([^>]+)>)/ig, '');
  };

  formatDataToExcel = async () => {

    if (this.props.formData?.type?.key === PRODUCT_TYPE_ID) {
      let externalProduct = this.props.formData.externalProduct;
      let data = externalProduct.map((item: any) => {
        return {
          [this.props.t('projectTitle')]: this.props.formData?.projectSequenceCode,
          [this.props.t('productTitle')]: item.pbsTitle,
          [this.props.t('projectManagerName')]: this.props.projectHeader?.pmName,
          [this.props.t('purchaseOrderType')]: this.props.formData?.type?.text,
          [this.props.t('purchaseOrderId')]: this.props.formData?.sequenceId,
          [this.props.t('purchaseOrderTitle')]: this.props.formData?.title,
          [this.props.t('customerCompany')]: this.props.formData?.customerOrganisation,
          [this.props.t('customerOrderDate')]: this.props.formData?.customerOrderDate,
          [this.props.t('supplierOrderDate')]: this.props.formData?.supplierOrderDate,
          [this.props.t('customerEmail')]: this.props.formData?.customerContact?.customerEmail,
          [this.props.t('customerPhoneNumber')]: this.props.formData?.customerContact?.customerMobile?.toString(),
          [this.props.t('customerContactPersonFullName')]: this.props.formData?.customerContact?.customerContact,
          [this.props.t('customerAddress')]: this.getAddress(this.props.formData?.customerContact?.cabAddress),
          [this.props.t('supplierCompany')]: this.props.formData?.supplierOrganisation,
          [this.props.t('salutation')]: '',
          [this.props.t('supplierContactPersonFullName')]: this.props.formData?.supplierContact?.customerContact,
          [this.props.t('supplierAddress')]: this.getAddress(this.props.formData?.supplierContact?.cabAddress),
          [this.props.t('supplierPoEmail')]: this.props.formData?.supplierContact?.customerEmail,
          [this.props.t('supplierPhoneNumber')]: this.props.formData?.supplierContact?.customerMobile?.toString(),
          [this.props.t('vatNumber')]: this.props.formData?.supplierContact?.vat,
          [this.props.t('purchaseOrderDate')]: this.props.formData?.history?.createdDate ? moment.utc(this.props.formData?.history?.createdDate).local().format('DD/MM/YYYY') : '',
          [this.props.t('deliveryAddress')]: this.props.formData?.mapLocation?.address?.freeformAddress,
          [this.props.t('generalComment')]: this.removeTags(this.props.formData?.comments),
          [this.props.t('paymentTerms')]: '',
          [this.props.t('borTitle')]: item.borTitle,
          [this.props.t('resourceType')]: item.resourceType,
          [this.props.t('borResourceTitle')]: item.cTitle,
          [this.props.t('unitPriceExVAT')]: item.cUnitPrice ? parseFloat(item.cUnitPrice) : '',
          [this.props.t('quantity')]: item.cQuantity ? parseFloat(item.cQuantity) : '',
          [this.props.t('mou')]: item.cMou ? (item.cMou).toString() : '',
          [this.props.t('totalPrice')]: item.cTotalPrice ? parseFloat(item.cTotalPrice) : '',
          [this.props.t('devices')]: item.cdevices,
          [this.props.t('FTE')]: item.cFullTimeEmployee,
          [this.props.t('startDate')]: item.cStartDate ? moment.utc(item.cStartDate).local().format('DD/MM/YYYY') : '',
          [this.props.t('stopDate')]: item.cStopDate ? moment.utc(item.cStopDate).local().format('DD/MM/YYYY') : '',
          [this.props.t('numberofDays')]: item.cNumberOfDate ? (item.cNumberOfDate).toString() : '',
        };
      });
      return data;
    } else {
      let borResource = this.props.formData.poResources;
      let materials = borResource?.materials.map((obj: any) => ({ ...obj, resourceType: this.props.t('material') }));
      let tools = borResource?.tools.map((obj: any) => ({ ...obj, resourceType: this.props.t('tool') }));
      let consumable = borResource?.consumable.map((obj: any) => ({
        ...obj,
        resourceType: this.props.t('consumable'),
      }));
      let labour = borResource?.labour.map((obj: any) => ({ ...obj, resourceType: this.props.t('labour') }));
      let allResources = _.concat(materials, tools, consumable, labour);
      //let allResources = _.concat(borResource?.materials, borResource?.tools, borResource?.consumable, borResource?.labour);
      let data = allResources.map((item: any, index) => {
        return {
          [this.props.t('projectTitle')]: this.props.formData?.projectSequenceCode,
          [this.props.t('productTitle')]: item.pbsTitle,
          [this.props.t('projectManagerName')]: this.props.projectHeader?.pmName,
          [this.props.t('purchaseOrderType')]: this.props.formData?.type?.text,
          [this.props.t('purchaseOrderId')]: this.props.formData?.sequenceId,
          [this.props.t('purchaseOrderTitle')]: this.props.formData?.title,
          [this.props.t('customerCompany')]: this.props.formData?.customerOrganisation,
          [this.props.t('customerOrderDate')]: this.props.formData?.customerOrderDate,
          [this.props.t('supplierOrderDate')]: this.props.formData?.supplierOrderDate,
          [this.props.t('customerEmail')]: this.props.formData?.customerContact?.customerEmail,
          [this.props.t('customerPhoneNumber')]: this.props.formData?.customerContact?.customerMobile?.toString(),
          [this.props.t('customerContactPersonFullName')]: this.props.formData?.customerContact?.customerContact,
          [this.props.t('customerAddress')]: this.getAddress(this.props.formData?.customerContact?.cabAddress),
          [this.props.t('supplierCompany')]: this.props.formData?.supplierOrganisation,
          [this.props.t('salutation')]: '',
          [this.props.t('supplierContactPersonFullName')]: this.props.formData?.supplierContact?.customerContact,
          [this.props.t('supplierAddress')]: this.getAddress(this.props.formData?.supplierContact?.cabAddress),
          [this.props.t('supplierPoEmail')]: this.props.formData?.supplierContact?.customerEmail,
          [this.props.t('supplierPhoneNumber')]: this.props.formData?.supplierContact?.customerMobile?.toString(),
          [this.props.t('vatNumber')]: this.props.formData?.supplierContact?.vat,
          [this.props.t('purchaseOrderDate')]: this.props.formData?.history?.createdDate ? moment.utc(this.props.formData?.history?.createdDate).local().format('DD/MM/YYYY') : '',
          [this.props.t('deliveryAddress')]: this.props.formData?.mapLocation?.address?.freeformAddress,
          [this.props.t('generalComment')]: this.removeTags(this.props.formData?.comments),
          [this.props.t('paymentTerms')]: '',
          [this.props.t('borTitle')]: item.borTitle,
          [this.props.t('resourceType')]: item.resourceType,
          [this.props.t('borResourceTitle')]: item.cTitle,
          [this.props.t('unitPriceExVAT')]: item.cUnitPrice ? parseFloat(item.cUnitPrice) : '',
          [this.props.t('quantity')]: item.cQuantity ? parseFloat(item.cQuantity) : '',
          [this.props.t('mou')]: item.cMou ? (item.cMou).toString() : '',
          [this.props.t('totalPrice')]: item.cTotalPrice ? parseFloat(item.cTotalPrice) : '',
          [this.props.t('devices')]: item.cdevices,
          [this.props.t('FTE')]: item.cFullTimeEmployee,
          [this.props.t('startDate')]: item.cStartDate ? moment.utc(item.cStartDate).local().format('DD/MM/YYYY') : '',
          [this.props.t('stopDate')]: item.cStopDate ? moment.utc(item.cStopDate).local().format('DD/MM/YYYY') : '',
          [this.props.t('numberofDays')]: item.cNumberOfDate ? (item.cNumberOfDate).toString() : '',
        };
      });
      return data;
    }

  };

  formatPriceDataToExcel = async () => {

    if (this.props.formData?.type?.key === PRODUCT_TYPE_ID) {
      let externalProduct = this.props.formData.externalProduct;
      let data = externalProduct.map((item: any) => {
        return {
          [this.props.t('projectNumber')]: this.props.formData?.projectSequenceCode,
          [this.props.t('resourceType')]: item.resourceType,
          [this.props.t('borResourceTitle')]: item.cTitle,
          [this.props.t('quantity')]: item.cQuantity ? (item.cQuantity).toString() : '',
          [this.props.t('mou')]: item.cMou ? (item.cMou).toString() : '',
          [this.props.t('vendorReference')]: this.props.formData?.supplierReference,
          [this.props.t('vendorMou')]: '',
          [this.props.t('vendorPrice')]: '',
          [this.props.t('deliveryPeriod')]: '',
        };
      });
      return data;
    } else {
      let borResource = this.props.formData.poResources;
      let materials = borResource?.materials.map((obj: any) => ({ ...obj, resourceType: this.props.t('material') }));
      let tools = borResource?.tools.map((obj: any) => ({ ...obj, resourceType: this.props.t('tool') }));
      let consumable = borResource?.consumable.map((obj: any) => ({
        ...obj,
        resourceType: this.props.t('consumable'),
      }));
      let labour = borResource?.labour.map((obj: any) => ({ ...obj, resourceType: this.props.t('labour') }));
      let allResources = _.concat(materials, tools, consumable, labour);
      //let allResources = _.concat(borResource?.materials, borResource?.tools, borResource?.consumable, borResource?.labour);
      let data = allResources.map((item: any, index) => {
        return {
          [this.props.t('projectNumber')]: this.props.formData?.projectSequenceCode,
          [this.props.t('resourceType')]: item.resourceType,
          [this.props.t('borResourceTitle')]: item.cTitle,
          [this.props.t('quantity')]: item.cQuantity ? (item.cQuantity).toString() : '',
          [this.props.t('mou')]: item.cMou ? (item.cMou).toString() : '',
          [this.props.t('vendorReference')]: this.props.formData?.supplierReference,
          [this.props.t('vendorMou')]: '',
          [this.props.t('vendorPrice')]: '',
          [this.props.t('deliveryPeriod')]: '',
        };
      });
      return data;
    }
  };

  getAddress = (address: any) => {
    let fullAddress = '';
    if (address) {
      fullAddress += address?.street ? address.street + ' ' : '';
      fullAddress += address?.streetNumber ? address.streetNumber + ', ' : '';
      fullAddress += address?.postalCode ? address.postalCode + ' ' : '';
      fullAddress += address?.city ? address.city + ', ' : '';
      fullAddress += address?.region ? address.region + ', ' : '';
      fullAddress += address?.country ? address.country?.countryName + ', ' : '';
      return fullAddress;
    } else {
      return '';
    }
  };

  // 2. clear states in this method and save to state
  // 3. update redirect url
  createNew = (): void => {
    this.props.clearFormData();
    //this.props.setUid();
    let formData: any = {
      title: null,
      // ....
    };
    this.setState({
      formData: formData,
      isNewPO: true,
      purchaseOrderType: null,
      selectedProject: getProject() ? getProject() : null,
    });
    if (getContractingUnit() && !getProject()) {
      history.push(`/CU/${getContractingUnit()}/purchase-order/new`);
    } else {
      history.push(`/CU/${getContractingUnit()}/project/${getProject()}/purchase-order/new`);
    }
  };

  reloadListPaneItem = () => {
    //
    //this.props.resetIsChange();
    setTimeout(() => {
      this.props.filterPurchaseOrderListPaneData(this.state.filter);
    }, 100);
  };


  renderShortCutPane = () => {
    return (
      <ShortCutPane
        projectId={this.state.projectId}
        shortCutPaneFilters={this.props.shortcutPaneData}
        filter={this.state.filter}
        handelShortCutPaneFilter={(selectedItem: POShortCutPaneItem) =>
          this.handelShortCutPaneFilter(selectedItem)
        }
        toggleOverlay={this.state.collapseBookmarkPane}
        resetLayout={this.state.resetLayout}
      />
    );
  };

  renderBookmarkPane = () => {
    if (this.state.purchaseOrderType === RESOURCE_TYPE_ID) {
      return this.bookmarkListResource;
    } else if (this.state.purchaseOrderType === PRODUCT_TYPE_ID) {
      return this.bookmarkListProduct;
    } else if (this.state.purchaseOrderType === GOODDEERIS_PRODUCT_TYPE_ID) {
      return this.bookmarkListResource;
    } else {
      return this.bookmarkList;
    }
  };

  submitRfqData = async (values: any) => {
    await client.post(PURCHASE_ORDER_SAVE_EP, values).then((res) => {
      this.setState({
        showProgressBar: true,
      })
      this.reloadListPaneItem();
    }).catch((err) => console.log('err', err)).finally(() => {
      setTimeout(() => {
        this.setState({
          showProgressBar: false
        })
      }, 1000);
    })
  }

  getDocumentPaneView = () => {
    return (
      <DocumentPaneSection
        collapseBookmarkPane={this.state.collapseBookmarkPane}
        showProgress={this.state.showProgressBar}
        handleScroll={this.props.handleScroll}
        isEdit={this.props.isEdit}
        formData={this.state.formData}
        bookmarkList={this.renderBookmarkPane()}
        currentActiveSection={this.props.currentActiveSection}
        renderCommandBar={() => <CommandBarRight formData={this.state.formData}
                          purchaseOrderType={this.state.purchaseOrderType}
                          selectedProject={this.state.selectedProject}/>
                        }

      >
        <DocumentPane
          isExpand={this.state.collapseBookmarkPane}
          formData={this.props.formData}
          submitRfqEdit={this.submitRfqData}
          // isLoadingPO={this.props.isLoadingPO}
          isNewPO={this.state.isNewPO}
          poRequestType={this.state.poRequestType}
        />
      </DocumentPaneSection>
    );
  };

  private getListPaneView = () => {
    return (
      <ListPaneSection
        activeClass={this.state.activeClass}
        titlePaneHeader={
          (this.state.selectedShortCutItem && this.state.selectedShortCutItem.name)
            ? this.state.selectedShortCutItem.name!!
            : 'All'
        }
        showProgress={this.state.showProgressBar}
        renderCommandBar={() => 
          <CommandBarLeft disable={true} filter={this.state.filter}/>
        }
        renderShotcutPane={() => this.renderShortCutPane()}
      >
        <ListPane
          projectId={this.state.projectId}
          loadMsg={this.props.loadMsg}
          reloadListPaneItem={() => this.reloadListPaneItem()}
          filter={this.state.filter}
          listPaneItems={this.props.poList}
          isDataLoaded={this.props.isPOListLoaded}
          isChange={this.props.isChange}
          formData={this.state.formData}
          handelFilter={(filter: POFilter) =>
            this.handleFilter(filter)
          }
          handleSelectedListPaneItem={(
            selectedListItemIds: [] | string[],
          ) => {
            //this.handleSelectedListPaneItem(selectedListItemIds);
          }}
          reSizer={this.props.reSizer}
        />
      </ListPaneSection>
    );
  };

  handleFilter = (filter: POFilter) => {
    if ((filter.title && filter.title.length >= 3) || filter.title === null) {
      this.setState({
        filter: filter,
      }, this.reloadListPaneItem);
    } else {
      this.setState({
        filter: filter,
      });
    }
  };

  handelShortCutPaneFilter = (selectedItem: POShortCutPaneItem) => {
    this.setState({
      selectedShortCutItem: selectedItem,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className={'ms-Grid'}>
          <SplitPane
            className={'SplitPane'}
            split="vertical"
            minSize={300}
            maxSize={screen.width / 2}
            defaultSize={
              this.props.showDetails ? screen.width / 2 : screen.width
            }
            resizerStyle={resizerStyle}
            style={{ height: 'calc(100vh - 129px)' }}
            size={this.props.reSizer}
            onDragStarted={() => {
              this.setState({
                // isDrag:true,
                // collapseBookmarkPane:true,
                // resetLayout:false,
              });
            }}
            onResizerDoubleClick={() => {
              this.setState({
                reSizer: this.props.reSizer === 300 ? screen.width / 2 : 300,
                isDrag: !this.state.isDrag,
                collapseBookmarkPane: !this.state.collapseBookmarkPane,
                resetLayout: !this.state.resetLayout,
              });
            }}
            onDragFinished={(newSize: number) => {
              this.setState({
                // isDrag:false,
                // resetLayout:false,
                reSizer: newSize,
                collapseBookmarkPane: newSize < screen.width / 2,
              });
            }}
            onChange={(newSize: number) => {
              if (newSize === screen.width / 2) {
                this.setState({
                  resetLayout: true,
                  collapseBookmarkPane: false,
                });
              }
            }}
          >
            <Pane className={'SplitPane'}>{this.getListPaneView()}</Pane>
            <Pane className={'SplitPane'}>
              {this.props.showDetails && (
                <div
                  className={`${this.props.animation(this.props.showDetails)}`}
                  style={{
                    display: this.props.showDetails ? 'block' : 'none',
                    transition: 'all 0.9s linear',
                  }}
                >
                  {!this.props.showPanel && (
                    <div>{this.getDocumentPaneView()}</div>
                  )}
                </div>
              )}
            </Pane>
          </SplitPane>
        </div>
        <Panel
          isOpen={this.props.showPanel}
          type={PanelType.smallFixedFar}
          onDismiss={this._hidePanel}
          isLightDismiss={true}
          headerText={t('corporateProductCatalogue')}
          className="custom-detail-panel"
          closeButtonAriaLabel={t('close')}
        >
          {this.getDocumentPaneView()}
        </Panel>

        <ConfirmationDialog
          hidden={true}
          title={t('areyousure')}
          subText={t('youwonTbeabletorevertthis')}
          onClickConfirmDialog={() => {
          }}
          onClickCancelDialog={() => {
          }}
          cancelButtonText={t('noIwonT')}
          confirmButtonText={t('yesDeleteit')}
        />
      </div>
    );
  }

  private _hidePanel = () => {
    this.setState({ showPanel: false, showDetails: false });
    if (getProject()) {
      history.push(`/CU/${getContractingUnit()}/project/${getProject()}/purchase-order`);
    } else if (getContractingUnit()) {
      //history.push(`/template`);
      history.push(`/CU/${getContractingUnit()}/purchase-order`);
    }
  };

  private hideDocumentPane = () => {
    if (getProject()) {
      history.push(`/CU/${getContractingUnit()}/project/${getProject()}/purchase-order`);
    } else if (getContractingUnit()) {
      //history.push(`/template`);
      history.push(`/CU/${getContractingUnit()}/purchase-order`);
    }
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    shortcutPaneData: state.po?.poShortCutPaneData,
    isPOListLoaded: state.po.isPOListLoaded,
    poList: state.po.poList,
    purchaseOrderDropDowns: state.po.purchaseOrderDropDowns,
    reloadListPane: state.po.reloadListPane,
    formData: state.po.formData,
    showProgressBar: state.po.showProgressBar,
    isEdit: state.po.isEdit,
    isLoadingPO: state.po.isLoadingPO,
    projectHeader: state.project.projectHeader,

  };
};

const mapDispatchToProps = {
  getFilterQRCodes,
  readPurchaseOrderShortCutPaneData,
  filterPurchaseOrderListPaneData,
  readPurchaseOrderDropDownData,
  readByPurchaseOrderId,
  clearFormData,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(withMainUI(PurchaseOrderMainLayout))),
);

import React from 'react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { useTranslation } from 'react-i18next';
import {
  ContextualMenu,
  FontWeights,
  getTheme,
  IColumn,
  IconButton,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../../../theme';
import { connect } from 'react-redux';
import { BorListPaneItem, FilterItem, ShortCutPaneItem } from '../../../../types/billOfResources';
import { ShortCutPaneComponent } from './shortCutPane';
import BorListComponent from './borList/component';
import ResourceListComponent from './resourceList/component';
import { PoResourceFilterItem, PoResourceListPaneItem } from '../../../../types/purchaseOrder';

const theme = getTheme();

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const classNames = mergeStyleSets({
  wrapper: {
    height: '70vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: {
    maxWidth: 400,
  },
  textContent: {
    padding: '15px 10px',
  },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: {
    width: '100%',
  },
});

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};


const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 0px 0px 0px',
    overflowY: 'hidden',
    minWidth: '80vw',
    Height: '80vh',
    maxHeight: '80vh',
    //minHeight: screen.width > 1280?'42vh':'60vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'none',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };

const ListPaneComponent = (props: {
  isOpen: boolean;
  openModal: () => void;
  resetSelection: () => void;
  onConfirmSelection: () => void;
  handleFilterChange: (filter: FilterItem) => void;
  filter: FilterItem;
  resourceFilter: PoResourceFilterItem;
  loadMsg: string;
  borColumns: IColumn[];
  resourceColumns: IColumn[];
  borListPaneItem: BorListPaneItem[];
  resourceListPaneItem: PoResourceListPaneItem[];
  isDataLoaded?: boolean;
  selection: any;
  resourceSelection: any;
  currentActiveSection: any;
  shortCutPaneFilters: ShortCutPaneItem[];
  handelShortCutPaneFilter: (selectedItem: ShortCutPaneItem) => void;
  selectedShortCutItem: ShortCutPaneItem;
  dropDownOptions: any;
  handleResourceFilterChange: (filter: PoResourceFilterItem) => void;
}) => {

  const { t } = useTranslation();
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(true);
  const titleId = useId('title');
  const theme = getTheme();

  const onModalClose = () => {
    props.openModal();
  };


  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          onModalClose();
        }}
        scrollableContentClassName="body"
        isBlocking={false}
        dragOptions={isDraggable ? dragOptions : undefined}
        className={'grid-righter column-filter-activated ms-Grid'}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>CU -{t('billOfResources')}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              onModalClose();
            }}
          />
        </div>
        <div className={contentStyles.subHeader}>

        </div>

        <div className={contentStyles.body}>
          <Stack horizontal>
            <ShortCutPaneComponent
              currentActiveSection={props.currentActiveSection}
              shortCutPaneFilters={props.shortCutPaneFilters}
              handelShortCutPaneFilter={(selectedItem: ShortCutPaneItem) =>
                props.handelShortCutPaneFilter(selectedItem)
              }
              resetFilter={() => props.resetSelection()}
            />

            <div className={'grid-righter column-filter-activated'}>
              {/*{JSON.stringify(this.props.loadMsg)}*/}
              <Stack style={{ alignSelf: 'center' }}>
                <div className={`wrapper-holder ${classNames.wrapper}`}>
                  {props.selectedShortCutItem && props.selectedShortCutItem.type === 'Bor' ? (
                    <BorListComponent
                      borListPaneItem={props.borListPaneItem}
                      selection={props.selection}
                      handleListPaneItemClick={(id: string) => {
                      }
                      }
                      resetSelection={() => props.resetSelection()}
                      columns={props.borColumns}
                      filter={props.filter}
                      loadMsg={props.loadMsg}
                      handleFilterChange={(filter: FilterItem) =>
                        props.handleFilterChange(filter)
                      }
                      isDataLoaded={props.isDataLoaded}
                      dropDownOptions={props.dropDownOptions}
                    />
                  ) : (
                    <ResourceListComponent
                      resourceListPaneItem={props.resourceListPaneItem}
                      selection={props.resourceSelection}
                      handleListPaneItemClick={(id: string) =>
                        console.log(id)
                      }
                      resetSelection={() => props.resetSelection()}
                      columns={props.resourceColumns}
                      filter={props.resourceFilter}
                      loadMsg={props.loadMsg}
                      handleFilterChange={(filter: PoResourceFilterItem) =>
                        props.handleResourceFilterChange(filter)
                      }
                      isDataLoaded={props.isDataLoaded}
                      dropDownOptions={props.dropDownOptions}
                    />
                  )}
                </div>
                <div className={contentStyles.footer}>
                  <PrimaryButton

                    text={t('reset')}
                    style={{ marginTop: 20, marginBottom: 30, marginRight: 10 }}
                    onClick={() => props.resetSelection()}
                  />
                  <PrimaryButton

                    text={t('confirm')}
                    //disabled={this.state.selectedCostList.length===0? true:false}
                    style={{ marginTop: 20, marginBottom: 30 }}
                    onClick={() => props.onConfirmSelection()}
                  />
                </div>
                {/*LIST PANE*/}
              </Stack>
            </div>
          </Stack>
        </div>

      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    toleranceStatesFilter: state.billOfResource.toleranceStatesFilter,
    productStatesFilter: state.billOfResource.productStatesFilter,
    itemTypesFilter: state.billOfResource.itemTypesFilter,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ListPaneComponent);

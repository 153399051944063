import { useTranslation } from 'react-i18next';
import {
  ActionButton,
  ContextualMenu,
  FontWeights,
  getTheme,
  IconButton,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { v4 as uuidv4 } from 'uuid';
import { uPrinceTheme } from '../../../../../theme';
import {
  Competence,
  Milestone,
} from '../../../../types/projectBreakdownStructure';
import ClearableDropdown from '../../../../shared/clearableDropdown/clearableDropdown';
import CustomDatePicker from '../../../../shared/customDatePicker/customDatePicker';
import { formatDateTimeToISOString } from '../../../../shared/util';
import ConfirmationDialog from '../../../../shared/confirmationDialog/confirmationDialog';

const theme = getTheme();
const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const classNames = mergeStyleSets({
  wrapper: {
    //height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: {
    maxWidth: 400,
  },
  textContent: {
    padding: '15px 10px',
  },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: {
    width: '100%',
  },
});

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };

const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    paddingTop: 20,
    minWidth: '45vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'none',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const MilestoneModal = (props: {
  isOpen: boolean;
  openCompetenceModal: () => void;
  isEdit: boolean;
  createMilestone: (milestone: Milestone) => any;
  milestone: Milestone;
  dropDownOptions: any;
  pbsId: string | null;
  pbsContract: string | null;
  existingPbsMilestones: Milestone[];
}) => {
  const { t } = useTranslation();
  const [isDraggable] = useBoolean(false);
  const titleId = useId('title');
  const [milestone, setMilestone] = useState(new Milestone());
  const [disableSave, setDisableSave] = useState(true);
  const [isMilestoneCautionModalOpen, setIsMilestoneCautionModalOpen] =
    useState(false);

  const onChangeInput =
    (field: string) =>
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: any
    ) => {
      if (newValue) {
        setMilestone((prevState) => ({
          ...prevState,
          [field]: newValue.key,
        }));
      } else {
        setMilestone((prevState) => ({
          ...prevState,
          [field]: null,
        }));
        handleSaveBtnDisable(milestone, true);
      }
    };

  const handleSaveBtnDisable = (item: any, isDisable = false) => {
    if (item.status && item.expectedStartDate) {
      setDisableSave(false);
    } else if (!item.status && !item.expectedStartDate) {
      setDisableSave(true);
    }
    if (isDisable) {
      setDisableSave(true);
    }
  };

  useEffect(() => {
    const mappedMilestone = {
      ...props.milestone,
      expectedStartDate: !props.isEdit
        ? formatDateTimeToISOString(new Date())
        : props.milestone?.expectedStartDate,
    };

    setMilestone(mappedMilestone);
    handleSaveBtnDisable(mappedMilestone);
  }, [props.milestone, props.isEdit]);

  useEffect(() => {
    handleSaveBtnDisable(milestone);
  }, [milestone]);

  const createMilestone = () => {
    if (!props.isEdit) {
      milestone.id = uuidv4();
      milestone.productTaxonomy = props.pbsId;
      milestone.type = 'lkkab9fe-msms-4088-82a9-d27008688lkk';
    }

    const milestoneStatus: {
      key: string;
      text: string;
      displayOrder: number;
    }[] = props.dropDownOptions?.milestoneStatuses;

    const selectedMilestoneStatus = milestoneStatus?.find(
      (status) => status.key === milestone.status
    );

    if (!selectedMilestoneStatus) {
      return;
    }

    milestone.name = `${props.pbsContract} - ${selectedMilestoneStatus?.text}`;

    const maxExistingMilestoneStatusOrder = Math.max(
      ...props.existingPbsMilestones.map(
        (status) => status.statusDisplayOrder ?? 0
      ),
      0
    );

    const maxExistingMilestoneStartDate = Math.max(
      ...props.existingPbsMilestones.map(
        (status) => new Date(status.expectedStartDate as string)?.getTime() ?? 0
      ),
      0
    );

    const currentMilestoneStartDate = new Date(
      milestone.expectedStartDate as string
    )?.getTime();

    if (
      maxExistingMilestoneStatusOrder >=
        selectedMilestoneStatus?.displayOrder ||
      maxExistingMilestoneStartDate > currentMilestoneStartDate
    ) {
      setIsMilestoneCautionModalOpen(true);
      return;
    }

    props.createMilestone(milestone);
    if (!props.isEdit) {
      setMilestone(props.milestone);
    }

    props.openCompetenceModal();
  };

  const resetMilestone = () => {
    setMilestone(props.milestone);
  };

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          props.openCompetenceModal();
          setTimeout(() => {
            setMilestone(props.milestone);
          }, 1000);
        }}
        scrollableContentClassName="body"
        isBlocking={true}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>
            {' '}
            {!props.isEdit ? t('add') : t('edit')} {t('milestones')}
          </span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              props.openCompetenceModal();
              setMilestone(props.milestone);
            }}
          />
        </div>
        <div className={contentStyles.subHeader}>
          <ActionButton iconProps={addIcon} allowDisabledFocus>
            <Label className={contentStyles.actionButtonLabel}>
              {!props.isEdit ? t('add') : t('save')}
            </Label>
          </ActionButton>
        </div>
        <div className={contentStyles.body}>
          <div className="proj-detail-content inner">
            <div className="ms-Grid-row">
              <Label>01. {t('milestones')} </Label>
            </div>
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <ClearableDropdown
                    placeholder={t('milestoneStatusRequired')}
                    label={t('milestone')}
                    options={props.dropDownOptions.milestoneStatuses}
                    onChange={onChangeInput('status')}
                    selectedKey={milestone.status ? milestone.status : ''}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <CustomDatePicker
                    setMinDate={null}
                    setMaxDate={null}
                    label={t('expectedStartDate')}
                    setValue={
                      milestone.expectedStartDate
                        ? new Date(milestone.expectedStartDate)
                        : null
                    }
                    getValue={(date: Date) => {
                      setMilestone((prevState) => ({
                        ...prevState,
                        pbsProductId: props.pbsId,
                        expectedStartDate: formatDateTimeToISOString(date),
                      }));
                    }}
                    disabled={false}
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={addIconWhite}
            text={t('save')}
            style={{ marginTop: 20, marginBottom: 30 }}
            onClick={() => {
              createMilestone();
            }}
            disabled={disableSave}
          />
        </div>
      </Modal>

      <ConfirmationDialog
        hidden={!isMilestoneCautionModalOpen}
        title={t('cannotProceed')}
        subText={t('milestoneIncorrectedOrder')}
        onClickConfirmDialog={() => setIsMilestoneCautionModalOpen(false)}
        onClickCancelDialog={() => setIsMilestoneCautionModalOpen(false)}
      />
    </div>
  );
};

export default MilestoneModal;

import { ITextFieldStyleProps, ITextFieldStyles, TextField } from '@fluentui/react/lib/TextField';
import { ILabelStyleProps, ILabelStyles } from '@fluentui/react/lib/Label';
import * as React from 'react';
import { iconColors, uPrinceTheme } from '../../../theme';
import NumberFormat from 'react-number-format';

const CustomInput = (inputProps: any) => {
  const handleFocus = (event: { target: { select: () => any; }; }) => event.target.select();
  return <TextField type="number" {...inputProps} onFocus={handleFocus}/>;
};

const getDecimalSeparator = () => {
  const numberWithDecimalSeparator = 1.1;
  const formatter = new Intl.NumberFormat(window.navigator.language);
  if (formatter !== undefined) {
    return formatter.formatToParts(numberWithDecimalSeparator).find(part => part.type === 'decimal')?.value;
  }
  return '.';
};


const FixedDecimalNumberFormatField: React.FC<any> = ({ input: { name, onChange, value, ...restInput }, meta, lable, disabled, ...rest }) => {
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;


  return (
    <NumberFormat
      {...rest}
      value={value}
      label={lable}
      name={name}
      onValueChange={({ value: v }) => onChange({ target: { name, value: v } })}
      style={{ textAlign: 'right', color: rest.hasChanged && iconColors.iconActiveColor }}
      styles={disabled ? getDisabledStyles : rest.hasChanged && getStyles}
      errorMessage={showError ? meta.error : ''}
      inputProps={restInput}
      isNumericString={true}
      thousandSeparator={getDecimalSeparator() === ',' ? '.' : ','}
      decimalSeparator={getDecimalSeparator()}
      decimalScale={2}
      fixedDecimalScale={true}
      helperText={showError ? meta.error || meta.submitError : undefined}
      customInput={
        CustomInput
      }
      disabled={disabled}
    />
  );
};

function getStyles(props: ITextFieldStyleProps): Partial<ITextFieldStyles> {
  return {
    fieldGroup: [
      { borderColor: iconColors.iconActiveColor },
    ],
    subComponentStyles: { label: getLabelStyles },
  };
}

function getLabelStyles(props: ILabelStyleProps): ILabelStyles {
  return { root: { color: iconColors.iconActiveColor } };
}

function getDisabledStyles(props: ITextFieldStyleProps): Partial<ITextFieldStyles>  {
  return {
    field: {
      color: uPrinceTheme.palette.themePrimary,
    },
    subComponentStyles: { label: getDisabledLabelStyles },
  };
}

function getDisabledLabelStyles(props: ILabelStyleProps): ILabelStyles {
  const { disabled } = props;
  return {
    root: disabled && {
      color: 'black',
    },
  };
}

export default FixedDecimalNumberFormatField;

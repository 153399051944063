import {
  ActionButton,
  ChoiceGroup,
  getTheme,
  IChoiceGroupOption,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  PrimaryButton,
  Separator,
  Stack,
  TextField,
} from '@fluentui/react';
import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../theme';
import { messageService } from '../../../services/messageService';
import ClearableDropdown from '../../../shared/clearableDropdown/clearableDropdown';
import CustomDatePicker from '../../../shared/customDatePicker/customDatePicker';
import { formatDateTimeToISOString } from '../../../shared/util';
import {
  PBS_TYPE,
  ProjectBreakdown,
  ProjectBreakdownValidationMessage,
} from '../../../types/projectBreakdownStructure';
import MachineTaxonomyField from '../../mileStones/documentPane/taxonomy/taxonomy';
import ProductTaxonomyField from '../taxonomy/productTaxonomy';
import TaxonomyField from '../taxonomy/taxonomy';
import { LocationAddress, Position } from '../../../types/projectDefinition';
import { Dropdown } from 'office-ui-fabric-react';
import client from '../../../api';
import QualityResponsible from '../quality/qualityResponsible';
import i18n from '../../../../i18n';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import ReactAzureMap from './reactAzureMap/ReactAzureMap';

const theme = getTheme();
const mapWhiteIconButtonStyles = {
  root: { color: uPrinceTheme.palette.white },
  rootHovered: { color: theme.palette.neutralDark },
};
const mapIconWhite: IIconProps = {
  iconName: 'Nav2DMapView',
  styles: mapWhiteIconButtonStyles,
};

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

// Styles definition
const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const stackItemStylesColThree: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
  },
};

const classNames = mergeStyleSets({
  fullWidth: { width: '100%' },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  bigScreenTree: {
    marginBottom: 30,
    height: '30vh',
  },
  smallScreenTree: {
    marginBottom: 30,
    height: '56vh',
  },
  textEditor: { height: window.innerWidth <= 1280 ? '50vh' : '25vh' },
  borderNone: { border: 'none !important'},
  subHeaderStyle: { fontSize: '14px !important' }
});

const transformData = (data: any) => {
  if (Array.isArray(data)) {
    const keySet = new Set<string>();
    let dataValues: { key: any; text: any }[] = [];
    //    data.map(({ key, value }) => ({
    //     key,
    //     text: value, // Transform 'value' to 'text'
    //   }));
    data.forEach(({ key, value }) => {
      if (!keySet.has(key)) {
        keySet.add(key);
        dataValues.push({ key, text: value });
      }
    });
    return dataValues;
  }
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const ProductHeader = (props: {
  isCollapsed: boolean;
  isEdit?: boolean;
  dropDownOptions: any;
  projectBreakdown: ProjectBreakdown;
  saveFormData: () => void;
  handleFormChange: (projectBreakdown: ProjectBreakdown, save: boolean) => void;
  validationMessages: ProjectBreakdownValidationMessage;
  onFilterTaxonomyNodeChanged: any;
  pbsType?: PBS_TYPE;
  hasBorCreatedForThisProduct: boolean;
  openMapModal: () => void;
  setLocationAddress: (position: Position, address: LocationAddress) => void;
  onQualityFilterPersonChanged: any;
  readOnly: boolean;
}) => {
  const [selectedAppointmentStatus, setAppointmentStatus] = useState<string>(
    props.projectBreakdown.appointmentStatusId
      ? props.projectBreakdown.appointmentStatusId
      : ''
  );
  const [appointmentStatusDropDown, setappointmentStatusDropDown] = useState<
    any[]
  >([]);

  const [showMap, setShowMap] = useState<any>(false)

  const [productStatusOptions, setProductStatusOptions] = useState<[]>(props.dropDownOptions.productStates);
  const [errMsg, setErrMsg] = useState<{
    departureStartTime: boolean,
    yardStartTime: boolean,
    yardEndTime: boolean
  }>({
    departureStartTime: false,
    yardStartTime: false,
    yardEndTime: false
  })

  useEffect(() => {
    if(props.dropDownOptions.productStates){
      const productStatusOptions = props.dropDownOptions.productStates?.filter((data: any) => data.key!=='7a5d2a4a-0cbe-4575-8f1e-a2ba605e75ea');
      setProductStatusOptions(productStatusOptions);
    }
  },[props.dropDownOptions.productStates])

  useEffect(() => {
    if (appointmentStatusDropDown.length == 0) {
      let appointmentStatusDropDownList = client
        .get(`Pbs/GetPbsAppointmentStatus`)
        .then((res: any) => {
          if (res != null && res.data.result.length > 0) {
            const list = transformData(res.data.result);
            setappointmentStatusDropDown(list ? list : []);
            console.log(appointmentStatusDropDownList);
          }
        })
        .catch((error: any) => {});
    }
  }, [appointmentStatusDropDown]);


  const { t } = useTranslation();
  const options: IChoiceGroupOption[] = [
    { key: '0', text: t('inScope') },
    {
      key: '1',
      text: t('outofScope'),
      styles: { field: { marginLeft: '15px' } },
    },
  ];
  // const [contractIsRequired, setcontractIsRequired] = useState(false);
  const _onChange = (
    ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined,
    option?: IChoiceGroupOption | undefined
  ): void => {
    // console.dir(option);
    if (option) {
      props.projectBreakdown.scope = option.key;
      if (option.key === '0') {
        // setcontractIsRequired(true);
      } else {
        // setcontractIsRequired(false);
      }
    } else {
      props.projectBreakdown.scope = null;
      // setcontractIsRequired(false);
    }
    props.handleFormChange(props.projectBreakdown, true);
  };

  const onTimePickerChange = (newtime: any, field: 'migration' | 'startTime' | 'endTime') => {
    const time: any = newtime.toISOString()
    if (field === 'migration') {
      props.projectBreakdown.departureStartTime = time
    }

    if (field === 'startTime') {
      props.projectBreakdown.yardStartTime = time
    }

    if (field === 'endTime') {
      props.projectBreakdown.yardEndTime = time
    }
  }

  const handleLocationPicked = async (pos: any, address: any) => {
    const addressInfo = address?.addresses?.[0]?.address
    const boundingBox = address?.addresses?.[0]?.address?.boundingBox
    const payload: any = {
      type: "Country",
      id: address?.addresses?.[0]?.id,
      score: '1',
      address: addressInfo,
      position: {
        lat: pos?.[0],
        lon: pos?.[1]
      },
      boundingBox: boundingBox,
      viewport: null
    }
    props.setLocationAddress(payload?.position, payload);
    setShowMap(false)
  }

  const getPosition = (lat: any, lon: any) => {
    if (lat && lon) {
      if (lat !== '0' && lon !== '0') {
        return [Number(lat), Number(lon)]
      }
    }

    return [4.3452, 50.8373]
  }

  const getPositionValues = (pos: any) => {
    if (pos) {
      if (pos !== '0') {
        return pos
      }
    }
    return null
  }

  return (
    <div className={'document-pane-card'}>
      <div className={'card-header'}>
        <Link href="#" id="pbs-product-header">
          <Label>1. {t('productHeader')} </Label>
        </Link>
      </div>

      <div className={'card-body'}>
        <div>
          {/* 1.1 Product Header */}
          {/* <div className={'document-pane-card'}> */}
            <div className={'card-body'} style={{paddingLeft: 12, marginTop:20}}>
              <div className="sub-header-title">
              <Link className={classNames.borderNone} href='#' id='pbs-product-header-1-1'>
                <Label className={classNames.subHeaderStyle}> 1.1 {t('product')}</Label>
              </Link>
              </div>
              <div className="proj-detail-content inner">
              <div className="marginTop">
                  {props.projectBreakdown.productId && (
                    <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <TextField
                            label={t('productTitle')}
                            value={
                              props.projectBreakdown.title
                                ? props.projectBreakdown.title
                                : ''
                            }
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <TextField
                            label={t('productId')}
                            value={
                              props.projectBreakdown.productId
                                ? props.projectBreakdown.productId
                                : ''
                            }
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>
                  )}
                </div>

                <div className="marginTop">
                  <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <TextField
                          label={t('name')}
                          value={
                            props.projectBreakdown.name
                              ? props.projectBreakdown.name
                              : ''
                          }
                          errorMessage={
                            props.validationMessages.nameErrorMsg
                              ? props.validationMessages.nameErrorMsg
                              : ''
                          }
                          required={true}
                          onChange={(event, value) => {
                            if (value) {
                              props.projectBreakdown.name = value;
                              props.validationMessages.nameErrorMsg = '';
                            } else {
                              props.projectBreakdown.name = null;
                            }
                            props.handleFormChange(props.projectBreakdown, true);
                          }}
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <ClearableDropdown
                          placeholder={t('productItemType')}
                          label={t('productItemType')}
                          options={props.dropDownOptions.itemTypes}
                          errorMessage={
                            props.validationMessages.ItemTypeErrorMsg
                              ? props.validationMessages.ItemTypeErrorMsg
                              : ''
                          }
                          required={true}
                          onChange={(event, value) => {
                            if (value) {
                              props.projectBreakdown.pbsProductItemTypeId =
                                value.key.toString();
                              messageService.sendMessage({
                                pbsTypeFromRead:
                                  value.text === t('subcontractor')
                                    ? PBS_TYPE.SUB
                                    : PBS_TYPE.REGULAR,
                              });
                            } else {
                              props.projectBreakdown.pbsProductItemTypeId = null;
                            }
                            props.validationMessages.ItemTypeErrorMsg = '';
                            props.handleFormChange(props.projectBreakdown, true);
                          }}
                          disabled={props.hasBorCreatedForThisProduct}
                          selectedKey={
                            props.projectBreakdown.pbsProductItemTypeId
                              ? props.projectBreakdown.pbsProductItemTypeId
                              : ''
                          }
                        />
                      </div>
                    </Stack.Item>
                  </Stack>
                </div>

                <div className="marginTop">
                  <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                    <Stack.Item grow={2} styles={stackItemStylesColThree}>
                      <div
                        className={`${classNames.fullWidth}`}
                        style={{ paddingTop: 26 }}
                      >
                        <ChoiceGroup
                          styles={{ flexContainer: { display: 'flex' } }}
                          selectedKey={
                            props.projectBreakdown.scope
                              ? props.projectBreakdown.scope
                              : '0'
                          }
                          options={options}
                          onChange={_onChange}
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={1} styles={stackItemStylesColThree}>
                      <div style={{ paddingTop: 30 }}>
                        <Label>{t('of')}</Label>
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={2} align="end" styles={stackItemStylesColThree}>
                      <div className={`${classNames.fullWidth}`}>
                        <TextField
                          label={i18n.t('prodLevelContract')}
                          value={
                            props.projectBreakdown.contract
                              ? props.projectBreakdown.contract
                              : ''
                          }
                          errorMessage={
                            props.validationMessages.contractErrorMsg
                              ? props.validationMessages.contractErrorMsg
                              : ''
                          }
                          required={true}
                          onChange={(event, value) => {
                            if (value) {
                              props.projectBreakdown.contract = value;
                              props.validationMessages.contractErrorMsg = '';
                            } else {
                              props.projectBreakdown.contract = null;
                            }
                            props.handleFormChange(props.projectBreakdown, true);
                          }}
                        />
                      </div>
                    </Stack.Item>
                    {props.isCollapsed && (
                      <Stack.Item grow={13} styles={stackItemStylesColThree}>
                        <div className={`${classNames.fullWidth}`}>
                          <Label> </Label>
                        </div>
                      </Stack.Item>
                    )}
                  </Stack>
                </div>

                <div className="marginTop">
                  <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <ClearableDropdown
                          placeholder={t('productstate')}
                          label={t('productstate')}
                          options={productStatusOptions}
                          required={true}
                          errorMessage={
                            props.validationMessages.statusErrorMsg
                              ? props.validationMessages.statusErrorMsg
                              : ''
                          }
                          onChange={(event, value) => {
                            if (value) {
                              props.projectBreakdown.pbsProductStatusId =
                                value.key.toString();
                            } else {
                              props.projectBreakdown.pbsProductStatusId = null;
                            }
                            props.validationMessages.statusErrorMsg = '';
                            props.handleFormChange(props.projectBreakdown, true);
                          }}
                          selectedKey={
                            props.projectBreakdown.pbsProductStatusId
                              ? props.projectBreakdown.pbsProductStatusId
                              : ''
                          }
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <ClearableDropdown
                          placeholder={t('productToleranceStates')}
                          label={t('productToleranceStates')}
                          options={props.dropDownOptions.toleranceStates}
                          required={true}
                          errorMessage={
                            props.validationMessages.toleranceStateErrorMsg
                              ? props.validationMessages.toleranceStateErrorMsg
                              : ''
                          }
                          onChange={(event, value) => {
                            if (value) {
                              props.projectBreakdown.pbsToleranceStateId =
                                value.key.toString();
                            } else {
                              props.projectBreakdown.pbsToleranceStateId = null;
                            }
                            props.validationMessages.toleranceStateErrorMsg = '';
                            props.handleFormChange(props.projectBreakdown, true);
                          }}
                          selectedKey={
                            props.projectBreakdown.pbsToleranceStateId
                              ? props.projectBreakdown.pbsToleranceStateId
                              : ''
                          }
                        />
                      </div>
                    </Stack.Item>
                  </Stack>
                </div>

                <div
                  className="marginTop"
                  style={{ marginLeft: '1.5%', marginRight: '1.5%' }}
                >
                  <Stack>
                    <Stack.Item grow={6}>
                      <div className={`${classNames.fullWidth}`}>
                        <Dropdown
                          placeholder={t('appointmentStatus')}
                          label={t('Appointment Status')}
                          options={appointmentStatusDropDown}
                          onChange={(event, value) => {
                            if (value) {
                              // setAppointmentStatus(value.key as string);
                              props.projectBreakdown.AppointmentStatusId = value.key;
                            } else {
                              props.projectBreakdown.AppointmentStatusId = null;
                              // setAppointmentStatus('');
                            }
                            props.validationMessages.statusErrorMsg = '';
                            props.handleFormChange(props.projectBreakdown, true);
                          }}
                          selectedKey={
                            // selectedAppointmentStatus
                            props.projectBreakdown.AppointmentStatusId
                              ? props.projectBreakdown.AppointmentStatusId
                              : props.projectBreakdown.appointmentStatusId
                          }
                        />
                      </div>
                    </Stack.Item>
                  </Stack>
                </div>

                <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <CustomDatePicker
                        setMinDate={null}
                        setMaxDate={
                          props.projectBreakdown.endDate
                            ? new Date(props.projectBreakdown.endDate)
                            : null
                        }
                        label={t('startDate')}
                        setValue={
                          props.projectBreakdown.startDate
                            ? new Date(props.projectBreakdown.startDate)
                            : new Date()
                        }
                        getValue={(date: Date) => {
                          props.projectBreakdown.startDate =
                            formatDateTimeToISOString(date);
                          if (!props.projectBreakdown.endDate) {
                            props.projectBreakdown.endDate =
                              formatDateTimeToISOString(date);
                          }
                          props.handleFormChange(props.projectBreakdown, true);
                        }}
                        disabled={false}
                      />
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <CustomDatePicker
                        setMinDate={
                          props.projectBreakdown.startDate
                            ? new Date(props.projectBreakdown.startDate)
                            : null
                        }
                        setMaxDate={null}
                        label={t('endDate')}
                        setValue={ (props.projectBreakdown.endDate) ? new Date(props.projectBreakdown.endDate) : (props.projectBreakdown.startDate) ? new Date(props.projectBreakdown.startDate) : new Date() }
                        getValue={(date: Date) => {
                          props.projectBreakdown.endDate =
                            formatDateTimeToISOString(date);
                          props.handleFormChange(props.projectBreakdown, true);
                        }}
                        disabled={false}
                      />
                    </div>
                  </Stack.Item>
                </Stack>

                <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <Label>{t('departureStartTime')}</Label>
                      <MaskedInput
                        value={props.projectBreakdown?.departureStartTime}
                        // defaultValue={props.projectBreakdown?.departureStartTime || undefined}
                        mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                        placeholder={'HH:mm'}
                        style={{width: '100%', height: '30px', outline: 'none', border: errMsg?.departureStartTime === true ? '1px solid red' : '1px solid #bebebe'}}
                        onChange={(e: any, newValue?: string) => {
                          const timeValidation: boolean = moment(e.target.value, 'HH:mm', true).isValid();
                          if (timeValidation) {
                            setErrMsg((prev) => ({
                              ...prev,
                              departureStartTime: false
                            }));
                          } else {
                            setErrMsg((prev) => ({
                              ...prev,
                              departureStartTime: true
                            }));
                            // props.projectBreakdown.departureStartTime = null
                          }
                          if (e.target.value) {
                            props.projectBreakdown.departureStartTime = e.target.value
                          } else {
                            setErrMsg((prev) => ({
                              ...prev,
                              departureStartTime: false
                            }));
                            props.projectBreakdown.departureStartTime = null
                          }
                        }}
                        keepCharPositions={true}
                        disabled={false}
                        
                      />
                      {errMsg?.departureStartTime === true && <p style={{color: 'red'}}>{t('invalidTime')}</p>}
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <Label>{t('yardStartTime')}</Label>
                      <MaskedInput
                        value={props.projectBreakdown.yardStartTime}
                        // defaultValue={props.projectBreakdown?.yardStartTime || undefined}
                        mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                        placeholder={'HH:mm'}
                        style={{width: '100%', height: '30px', outline: 'none', border: errMsg?.yardStartTime === true ? '1px solid red' : '1px solid #bebebe'}}
                        onChange={(e: any, newValue?: string) => {
                          const timeValidation: boolean = moment(e.target.value, 'HH:mm', true).isValid();
                          if (timeValidation) {
                            setErrMsg((prev) => ({
                              ...prev,
                              yardStartTime: false
                            }));
                          } else {
                            setErrMsg((prev) => ({
                              ...prev,
                              yardStartTime: true
                            }));
                            props.projectBreakdown.yardStartTime = null
                          }
                          
                          if (e.target.value) {
                            props.projectBreakdown.yardStartTime = e.target.value
                          } else {
                            setErrMsg((prev) => ({
                              ...prev,
                              yardStartTime: false
                            }));
                            // props.projectBreakdown.yardStartTime = null
                          }
                        }}
                        keepCharPositions={true}
                        disabled={false}
                      />
                      {errMsg?.yardStartTime === true && <p style={{color: 'red'}}>{t('invalidTime')}</p>}
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <Label>{t('yardEndTime')}</Label>
                      <MaskedInput
                        value={props.projectBreakdown?.yardEndTime}
                        // defaultValue={props.projectBreakdown?.yardEndTime || undefined}
                        mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                        placeholder={'HH:mm'}
                        style={{width: '100%', height: '30px', outline: 'none', border: errMsg?.yardEndTime === true ? '1px solid red' : '1px solid #bebebe'}}
                        onChange={(e: any, newValue?: string) => {
                          const timeValidation: boolean = moment(e.target.value, 'HH:mm', true).isValid();
                          if (timeValidation) {
                            setErrMsg((prev) => ({
                              ...prev,
                              yardEndTime: false
                            }));
                          } else {
                            setErrMsg((prev) => ({
                              ...prev,
                              yardEndTime: true
                            }));
                            // props.projectBreakdown.yardEndTime = null
                          }
                          if (e.target.value) {
                            props.projectBreakdown.yardEndTime = e.target.value
                          } else {
                            setErrMsg((prev) => ({
                              ...prev,
                              yardEndTime: false
                            }));
                            props.projectBreakdown.yardEndTime = null
                          }
                        }}
                        keepCharPositions={true}
                        disabled={false}
                      />
                      {errMsg?.yardEndTime === true && <p style={{color: 'red'}}>{t('invalidTime')}</p>}
                    </div>
                  </Stack.Item>
                </Stack>
              </div>
              <div className={'card-footer'}>
                <Separator />
                <Stack styles={stackStyles} tokens={itemAlignmentsStackTokens}>
                  <Stack.Item align="end" styles={stackFooterBarItemStyles}>
                    <ActionButton
                      className={classNames.actionButton}
                      iconProps={saveIcon}
                      allowDisabledFocus
                      onClick={() => {
                        props.saveFormData();
                      }}
                    >
                      <Label className={classNames.actionButton}>{t('save')} </Label>
                    </ActionButton>
                  </Stack.Item>
                </Stack>
              </div>
            </div>
          {/* </div>           */}
          <Separator />
          
          {/* 1.2 Quality responsible */}
          <div className={'card-body marginTop'} style={{paddingLeft: 12, marginTop:20}}>
            <QualityResponsible
                  onQualityFilterPersonChanged={
                    props.onQualityFilterPersonChanged
                  }
                  isEdit={false}
                  projectBreakdown={props.projectBreakdown}
                  saveFormData={() => props.saveFormData()}
                  handleFormChange={(
                    projectBreakdown: ProjectBreakdown,
                    save: boolean
                  ) => props.handleFormChange(projectBreakdown, save)}
                  validationMessages={props.validationMessages}
                  readOnly={props.readOnly}
              />
          </div>
          <Separator />
           {/* 1.3 Taxonomy */}
          <div className={'card-body marginTop'} style={{paddingLeft: 12, marginTop:20}}>
            <div className="sub-header-title">
            <Link className={classNames.borderNone} href='#' id='pbs-product-header-1-3'>
                <Label className={classNames.subHeaderStyle}>1.3 {t('Taxonomy')} </Label>
            </Link>
            </div>
            <div
              className="marginTop"
              style={{ paddingLeft: 0, paddingRight: 10 }}
            >
              <TaxonomyField
                label={t('utilityTaxonomy')}
                treeData={props.dropDownOptions.utilityTaxonomy}
                selectItemId={
                  props.projectBreakdown.utilityTaxonomyParentId
                    ? props.projectBreakdown.utilityTaxonomyParentId
                    : ''
                }
                onSelectItem={(id: string) => {
                  if (id) {
                    if (id === props.projectBreakdown.utilityTaxonomyParentId) {
                      props.projectBreakdown.utilityTaxonomyParentId = null;
                    } else {
                      props.projectBreakdown.utilityTaxonomyParentId = id;
                    }
                  } else {
                    props.projectBreakdown.utilityTaxonomyParentId = null;
                  }
                  props.handleFormChange(props.projectBreakdown, true);
                }}
                treeLevel={props.dropDownOptions.utilityTaxonomyLevel}
                onFilterTaxonomyNodeChanged={props.onFilterTaxonomyNodeChanged}
              />
            </div>

            <div
              className="marginTop"
              style={{ paddingLeft: 0, paddingRight: 10 }}
            >
              <TaxonomyField
                label={t('locationTaxonomy')}
                treeData={props.dropDownOptions.locationTaxonomy}
                selectItemId={
                  props.projectBreakdown.locationTaxonomyParentId
                    ? props.projectBreakdown.locationTaxonomyParentId
                    : ''
                }
                onSelectItem={(id: string) => {
                  if (id) {
                    if (id === props.projectBreakdown.locationTaxonomyParentId) {
                      props.projectBreakdown.locationTaxonomyParentId = null;
                    } else {
                      props.projectBreakdown.locationTaxonomyParentId = id;
                    }
                  } else {
                    props.projectBreakdown.locationTaxonomyParentId = null;
                  }
                  props.handleFormChange(props.projectBreakdown, true);
                }}
                treeLevel={props.dropDownOptions.locationTaxonomyLevel}
                onFilterTaxonomyNodeChanged={props.onFilterTaxonomyNodeChanged}
              />
            </div>

            <div
              className="marginTop"
              style={{ paddingLeft: 0, paddingRight: 10, width: '91%' }}
            >
              <ProductTaxonomyField
                label={t('productTaxonomy')}
                treeData={props.dropDownOptions.productTaxonomy}
                selectItemId={
                  props.projectBreakdown.parentId
                    ? props.projectBreakdown.parentId
                    : ''
                }
                onSelectItem={(id: string) => {
                  if (id) {
                    if (id === props.projectBreakdown.parentId) {
                      props.projectBreakdown.parentId = null;
                    } else {
                      props.projectBreakdown.parentId = id;
                    }
                  } else {
                    props.projectBreakdown.parentId = null;
                  }
                  props.handleFormChange(props.projectBreakdown, true);
                }}
                treeLevel={[]}
                onFilterTaxonomyNodeChanged={null}
                projectBreakdown={props.projectBreakdown}
              />
            </div>

            <div
              className="marginTop"
              style={{ paddingLeft: 0, paddingRight: 10 }}
            >
              <MachineTaxonomyField
                label={t('machineTaxonomy')}
                treeData={props.dropDownOptions.machineTaxonomy}
                selectItemId={
                  props.projectBreakdown.machineTaxonomyParentId
                    ? props.projectBreakdown.machineTaxonomyParentId
                    : ''
                }
                onSelectItem={(id: string) => {
                  if (id) {
                    if (id === props.projectBreakdown.machineTaxonomyParentId) {
                      props.projectBreakdown.machineTaxonomyParentId = null;
                    } else {
                      props.projectBreakdown.machineTaxonomyParentId = id;
                    }
                  } else {
                    props.projectBreakdown.machineTaxonomyParentId = null;
                  }
                  props.handleFormChange(props.projectBreakdown, true);
                }}
                treeLevel={props.dropDownOptions.machineTaxonomyLevel}
                onFilterTaxonomyNodeChanged={null}
              />
            </div>

            <div className={'card-footer'}>
              <Separator />
              <Stack styles={stackStyles} tokens={itemAlignmentsStackTokens}>
                <Stack.Item align="end" styles={stackFooterBarItemStyles}>
                  <ActionButton
                    className={classNames.actionButton}
                    iconProps={saveIcon}
                    allowDisabledFocus
                    onClick={() => {
                      props.saveFormData();
                    }}
                  >
                    <Label className={classNames.actionButton}>{t('save')} </Label>
                  </ActionButton>
                </Stack.Item>
              </Stack>
            </div>
          </div>

        </div>
      </div>

      <Separator />
      
      {/* 1.4 Pbs Address  */}
      <div className={'card-body marginTop'} style={{paddingLeft: 12, marginTop:20}}>
        <div className="sub-header-title">
        <Link className={classNames.borderNone} href='#' id='pbs-product-header-1-4'>
            <Label className={classNames.subHeaderStyle}>1.4 {t('pbs Address')} </Label>
        </Link>            
        </div>
        <div style={{ zIndex: 10000000, paddingLeft: 8 }} className={'marginTop'}>
          <div>
            <TextField 
              label={t('pbsAddress')} 
              placeholder={t('address')}
              readOnly
              value={props.projectBreakdown?.mapLocation?.address?.freeformAddress || ""}
            />
          </div>

          <div style={{marginTop: 8}}>
            <Label>{t('pbsCoordinates')}</Label>
            <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
              <TextField 
                placeholder={t('Latitude')}
                value={getPositionValues(props.projectBreakdown?.mapLocation?.position?.lat)}
                readOnly
              />
              <TextField 
                placeholder={t('Longitude')}
                value={getPositionValues(props.projectBreakdown?.mapLocation?.position?.lon)}
                readOnly
              />
              <PrimaryButton iconProps={mapIconWhite} onClick={() => setShowMap(true)}>Map</PrimaryButton>
            </div>
          </div>

          <ReactAzureMap 
            openModal={showMap} 
            setOpenModal={setShowMap}
            position={getPosition(props.projectBreakdown?.mapLocation?.position?.lat, props.projectBreakdown?.mapLocation?.position?.lon)}
            markedLocation={(position: any, address: any) => handleLocationPicked(position, address)}
          />
          {/* <AzureMapReact /> */}
        </div>

        <div className={'card-footer'}>
          <Separator />
          <Stack styles={stackStyles} tokens={itemAlignmentsStackTokens}>
            <Stack.Item align="end" styles={stackFooterBarItemStyles}>
              <ActionButton
                className={classNames.actionButton}
                iconProps={saveIcon}
                allowDisabledFocus
                onClick={() => {
                  props.saveFormData();
                }}
              >
                <Label className={classNames.actionButton}>{t('save')} </Label>
              </ActionButton>
            </Stack.Item>
          </Stack>
      </div>
      </div>      
    </div>
  );
};

export default ProductHeader;

import ENV from '../../../env.json';

class UprinceLogger {
  static isEnable: boolean = ENV.LOG_ENABLED;

  constructor() {
  }

  static log = (msg: string, arg?: any) => {
    if (UprinceLogger.isEnable) {
      if (arg) {
      } else {
        console.log(msg);
      }
    }
  };

  static error = (msg: string, arg?: any) => {
    if (UprinceLogger.isEnable) {
      if (arg) {
        console.error(msg, arg);
      } else {
        console.error(msg);
      }
    }
  };

  static table = (msg: string, arg: any) => {
    if (UprinceLogger.isEnable) {
      if (arg) {
        console.table(msg, arg);
      }
    }
  };
}

export default UprinceLogger;

export const CREATE_LANGUAGE = 'uprince/CREATE_LANGUAGE';
export const CREATE_LANGUAGE_SUCCESS = 'uprince/CREATE_LANGUAGE_SUCCESS';
export const CREATE_LANGUAGE_FAIL = 'uprice/CREATE_LANGUAGE_FAIL';

export const GET_LANGUAGES = 'uprince/GET_LANGUAGES';
export const GET_LANGUAGES_SUCCESS = 'uprince/GET_LANGUAGES_SUCCESS';
export const GET_LANGUAGES_FAIL = 'uprice/GET_LANGUAGES_FAIL';

export const LANGUAGE_GET_BY_ID = 'uprince/LANGUAGE_GET_BY_ID';
export const LANGUAGE_GET_BY_ID_SUCCESS = 'uprince/LANGUAGE_GET_BY_ID_SUCCESS';
export const LANGUAGE_GET_BY_ID_FAIL = 'uprice/LANGUAGE_GET_BY_ID_FAIL';

export const GET_KEY_WORD = 'uprince/GET_KEY_WORD';
export const GET_KEY_WORD_SUCCESS = 'uprince/GET_KEY_WORD_SUCCESS';
export const GET_KEY_WORD_FAIL = 'uprice/GET_KEY_WORD_FAIL';

export const CREATE_TRANSLATION = 'uprince/CREATE_TRANSLATION';
export const CREATE_TRANSLATION_SUCCESS = 'uprince/CREATE_TRANSLATION_SUCCESS';
export const CREATE_TRANSLATION_FAIL = 'uprice/CREATE_TRANSLATION_FAIL';

export const UPDATE_LANGUAGE = 'uprince/UPDATE_LANGUAGE';
export const UPDATE_LANGUAGE_SUCCESS = 'uprince/UPDATE_LANGUAGE_SUCCESS';
export const UPDATE_LANGUAGE_FAIL = 'uprice/UPDATE_LANGUAGE_FAIL';

export const ADD_LANGUAGE = 'uprince/ADD_LANGUAGE';
export const ADD_LANGUAGE_SUCCESS = 'uprince/ADD_LANGUAGE_SUCCESS';
export const ADD_LANGUAGE_FAIL = 'uprice/ADD_LANGUAGE_FAIL';
export const UPDATE_TRANSLATION = 'uprince/UPDATE_TRANSLATION';
export const UPDATE_TRANSLATION_SUCCESS = 'uprince/UPDATE_TRANSLATION_SUCCESS';
export const UPDATE_TRANSLATION_FAIL = 'uprice/UPDATE_TRANSLATION_FAIL';
export const CLEAR_LANGUAGE_DATA = 'CLEAR_LANGUAGE_DATA';

import { Image, PrimaryButton } from '@fluentui/react';
import React, { useEffect, useState } from 'react'

const PreviewComponent = (props: {
  selectedImageForPreview: any,
  setSelectedImageForPreview: any,
  uploadedImages: any,
  rotation: any,
  setRotation: any
}) => {
  const rotateImage = () => {
    props.setRotation((prevRotation: any) => prevRotation + 90); // Increment rotation by 90 degrees
  };

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === 'ArrowRight') {
        props.setSelectedImageForPreview((prev: number) => 
          Math.min(prev + 1, (props.uploadedImages?.length || 1) - 1)
        );
        props.setRotation(0);
      } else if (event.key === 'ArrowLeft') {
        props.setSelectedImageForPreview((prev: number) => Math.max(prev - 1, 0));
        props.setRotation(0);
      }
    };

    // Attach event listener
    window.addEventListener('keydown', handleKeyDown);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  
  return (
    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div style={{width: '10%', textAlign: 'center'}}>
        <PrimaryButton 
          onClick={() => {
            props.setSelectedImageForPreview((prev: number) => Math.max(prev - 1, 0));
            props.setRotation(0);
          }}
          style={{backgroundColor: 'transparent', border: 0, fontSize: 60, fontWeight: 'bold', color: '#fff'}}
          disabled={props.selectedImageForPreview === 0}
        >
          {"<"}
        </PrimaryButton>
      </div>
      <div style={{width: '80%'}}>
        <Image
          src={props.uploadedImages?.[props.selectedImageForPreview]?.image}
          style={{ maxWidth: '70%', maxHeight: '100%', margin: '0 auto', transform: `rotate(${props.rotation}deg)` }} // Apply rotation
        />
      </div>
      <div style={{ width: '10%', textAlign: 'center' }}>
        <PrimaryButton
          onClick={rotateImage}
          style={{ backgroundColor: 'transparent', border: 0, fontSize: 60, fontWeight: 'bold', color: '#fff' }}
        >
          {'⟳'}
        </PrimaryButton>
      </div>
      <div style={{width: '10%', textAlign: 'center'}}>
        <PrimaryButton
          onClick={() => {
            props.setSelectedImageForPreview((prev: number) => 
              Math.min(prev + 1, (props.uploadedImages?.length || 1) - 1)
            );
            props.setRotation(0);
          }}
          style={{backgroundColor: 'transparent', border: 0, fontSize: 60, fontWeight: 'bold', color: '#fff'}}
          disabled={props.selectedImageForPreview === (props.uploadedImages?.length || 1) - 1} // Disable if at the last image
        >
          {'>'}
        </PrimaryButton>
      </div>
    </div>
  )
}

export default PreviewComponent
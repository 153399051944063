import React from 'react';
import {
  Dropdown,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Stack,
  TextField,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../../theme';
import { ProgressStatement, ProgressStatementValidationMessage } from '../../../../types/progressStatement';
import { Language } from '../../../../types/language';

const theme = getTheme();

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

// Styles definition
const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const classNames = mergeStyleSets({
  fullWidth: { width: '100%' },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  bigScreenTree: {
    marginBottom: 30,
    height: '30vh',
  },
  smallScreenTree: {
    marginBottom: 30,
    height: '56vh',
  },
  textEditor: { height: window.innerWidth <= 1280 ? '50vh' : '25vh' },
});

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const AddDropdownLanguage = (props: {
  isEdit: boolean;
  dropDownOptions: any;
  formData: any;
  saveFormData: () => void;
  handleFormChange: (
    progressStatement: ProgressStatement,
    save: boolean,
  ) => void;
  validationMessages: ProgressStatementValidationMessage;
  countries: { key: string; text: string }[];
  updateLanguage: (lang: Language) => void;
  lang: Language;
  onInputChange: (field: string, value: string) => void;
  onFeatureTypeChange: (e: any, v: any) => void;
  dropdownItems: any;
  onDropdownTypeChange: (e: any, v: any) => void;
  selectedDropdown: any;
  getLanguageList: any;
  getCountryDropdown: any;
  selectedCountry: any;
  selectedLanguage: any;
  onCountryTypeChange: (e: any, v: any) => void;
  onLanguageTypeChange: (e: any, v: any) => void;
  selectedCode: any;
}) => {
  const { t } = useTranslation();
  // const options: IChoiceGroupOption[] = [
  //     { key: '0', text: t('inScope'), checked: true },
  //     {
  //         key: '1',
  //         text: t('outofScope'),
  //         styles: { field: { marginLeft: '15px' } }
  //     }
  // ];
  const featureCodeTypes = [
    { key: 1, text: t('corporateProductCatalogue') },
    { key: 2, text: t('project') },
    { key: 3, text: t('projectBreakdownStructure') },
    { key: 4, text: t('pojectMolecule') },
    { key: 5, text: t('billOfResources') },
    { key: 6, text: t('stock') },
    { key: 7, text: t('workFlow') },
    { key: 8, text: t('warehouse') },
  ];
  return (
    <div className={''}>
      <div className={'card-header'}>
        <Link href="#" id="ps-header">
          <Label>1. {t('dropdownLanguage')} </Label>
        </Link>
      </div>

      <div className={'card-body'}>
        <div className="proj-detail-content inner">
          <div className="marginTop">
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Dropdown
                    label={t('application')}
                    placeholder={t('application')}
                    required={true}
                    // value={
                    //   // props.lang.lang
                    //   //   ? props.lang.lang
                    //   //   : ''
                    // }
                    errorMessage={
                      props.validationMessages.typeErrorMsg
                        ? props.validationMessages.typeErrorMsg
                        : ''
                    }
                    options={[]}
                    onChange={(event, value) => {
                      // props.onInputChange('lang',value!!);
                    }}
                    // selectedKey={
                    //   props.formData.projectTypeId
                    //     ? props.formData.projectTypeId
                    //     : ''
                    // }
                  />
                </div>
              </Stack.Item>

              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Dropdown
                    label={t('feature')}
                    placeholder={t('feature')}
                    required={true}
                    // value={
                    //   // props.lang.lang
                    //   //   ? props.lang.lang
                    //   //   : ''
                    // }
                    errorMessage={
                      props.validationMessages.typeErrorMsg
                        ? props.validationMessages.typeErrorMsg
                        : ''
                    }
                    options={featureCodeTypes}
                    onChange={(e, v) => props.onFeatureTypeChange(e, v)}

                    // onChange={props.onInputChange('lang','1')}

                    // selectedKey={
                    //   props.formData.projectTypeId
                    //     ? props.formData.projectTypeId
                    //     : ''
                    // }
                  />
                </div>
              </Stack.Item>
            </Stack>

            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  { /* <TextField

                    label={t('Country')}
                    required={true}
                    value={
                      props.lang && props.lang.country
                        ? props.lang.country
                        : ''
                    }
                    // errorMessage={
                    //   props.validationMessages.typeErrorMsg
                    //     ? props.validationMessages.typeErrorMsg
                    //     : ''
                    // }
                    onChange={(event, value) => {

                        props.onInputChange('country',value!!)


                    //     props.lang.country = value;
                    //   } else {
                    //     props.formData.projectTypeId = null;
                    //   }
                    //  console.log('iiiiiii',props.lang);
                    }}
                    // selectedKey={
                    //   props.formData.projectTypeId
                    //     ? props.formData.projectTypeId
                    //     : ''
                    // }
                  />  */}
                  <Dropdown
                    label={t('dropDown')}
                    placeholder={t('dropDown')}
                    required={true}
                    // value={
                    //   // props.lang.lang
                    //   //   ? props.lang.lang
                    //   //   : ''
                    // }
                    errorMessage={
                      props.validationMessages.typeErrorMsg
                        ? props.validationMessages.typeErrorMsg
                        : ''
                    }
                    selectedKey={props.selectedDropdown}
                    options={props.dropdownItems}
                    onChange={(e, v) => props.onDropdownTypeChange(e, v)}
                    // props.onInputChange('lang',value!!);

                    // selectedKey={
                    //   props.formData.projectTypeId
                    //     ? props.formData.projectTypeId
                    //     : ''
                    // }
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  { /* <TextField
                    label={t('Language')}
                    required={true}
                    value={
                      props.lang.lang
                        ? props.lang.lang
                        : ''
                    }
                    errorMessage={
                      props.validationMessages.typeErrorMsg
                        ? props.validationMessages.typeErrorMsg
                        : ''
                    }
                    onChange={(event, value) => {

                        props.onInputChange('lang',value!!);


                    }}
                    // selectedKey={
                    //   props.formData.projectTypeId
                    //     ? props.formData.projectTypeId
                    //     : ''
                    // }
                  /> */}

                  <Dropdown
                    label={t('country')}
                    placeholder={t('country')}
                    required={true}
                    // value={
                    //   // props.lang.lang
                    //   //   ? props.lang.lang
                    //   //   : ''
                    // }
                    errorMessage={
                      props.validationMessages.typeErrorMsg
                        ? props.validationMessages.typeErrorMsg
                        : ''
                    }
                    selectedKey={props.selectedCountry}
                    options={props.getCountryDropdown}
                    onChange={(e, v) => {
                      props.onCountryTypeChange(e, v);
                    }}
                    // selectedKey={
                    //   props.formData.projectTypeId
                    //     ? props.formData.projectTypeId
                    //     : ''
                    // }
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>

          <div className="marginTop">
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Dropdown
                    label={t('language')}
                    placeholder={t('language')}
                    // width='300px'
                    // value={
                    //   props.lang.code
                    //     ? props.lang.code
                    //     : ''
                    // }
                    onChange={(e, v) => {
                      props.onLanguageTypeChange(e, v);
                    }}
                    selectedKey={props.selectedLanguage}
                    options={props.getLanguageList}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    label={t('Code')}
                    width="300px"
                    value={props.selectedCode}
                    autoComplete="off"
                    // onChange={() => {

                    //     props.onInputChange('code',props.selectedCode);

                    // }}
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDropdownLanguage;

import React, { ReactChild } from 'react';
import { ChartType, Task } from '../../types/public-types';
import { addToDate } from '../../helpers/date-helper';
import styles from './grid.module.css';
import moment from 'moment';

const isWeekend = (date : Date) => {
  return moment(date).weekday() === 0 || moment(date).weekday() === 6;
};

export type GridBodyProps = {
  tasks: Task[];
  listTasks: Task[];
  isInitialCollaps: boolean;
  canDrag: (dragProps: { node: any, path: number[] | string[], treeIndex: number, lowerSiblingCounts: number[], isSearchMatch: boolean, isSearchFocus: boolean }) => boolean;
  canDrop: (dropProp: { node: any, prevPath: number[] | string[], prevParent: any, prevTreeIndex: number, nextPath: number[] | string[], nextParent: any, nextTreeIndex: number }) => boolean;
  onMoveNode: (treeData:any, node:any, nextParentNode:any, prevPath:any, prevTreeIndex:any, nextPath:any, nextTreeIndex:any, nodeTwo:any) => void;
  dates: Date[];
  svgWidth: number;
  rowHeight: number;
  columnWidth: number;
  todayColor: string;
  rtl: boolean;
  chartType: ChartType;
  onTreeDataChange: (treeData: any) => void;
  viewMode: string;
};
export const GridBody: React.FC<GridBodyProps> = ({
                                                    tasks,
                                                    dates,
                                                    rowHeight,
                                                    svgWidth,
                                                    columnWidth,
                                                    todayColor,
                                                    rtl,
                                                    viewMode,
                                                  }) => {
  let y = 0;
  const gridRows: ReactChild[] = [];
  const rowLines: ReactChild[] = [
    <line
      key="RowLineFirst"
      x="0"
      y1={0}
      x2={svgWidth}
      y2={0}
      className={styles.gridRowLine}
    />,
  ];
  for (const task of tasks) {
    gridRows.push(
      <rect
        key={'Row' + task.id}
        x="0"
        y={y}
        width={svgWidth}
        height={rowHeight}
        className={styles.gridRow}
      />,
    );
    rowLines.push(
      <line
        key={'RowLine' + task.id}
        x="0"
        y1={y + rowHeight}
        x2={svgWidth}
        y2={y + rowHeight}
        className={styles.gridRowLine}
      />,
    );
    y += rowHeight;
  }

  // const emptyrows:any = Array(4).fill('').map((_, i) => 
  // { 
  //   y+20
  //   return <rect
  //         key={'Row' +i}
  //         x="0"
  //         y={y}
  //         width={svgWidth}
  //         height={rowHeight}
  //         className={styles.gridRow}
  //       />
  //     })

//gridRows.push(emptyrows)

  const now = new Date();
  let tickX = 0;
  const ticks: ReactChild[] = [];
  let today: ReactChild = <rect/>;
  const weekends: ReactChild[] = [];
  for (let i = 0; i < dates.length; i++) {
    const date = dates[i];
    ticks.push(
      <line
        key={date.getTime()}
        x1={tickX}
        y1={0}
        x2={tickX}
        y2={y}
        className={styles.gridTick}
      />,
    );
    if (
      (i + 1 !== dates.length &&
        date.getTime() < now.getTime() &&
        dates[i + 1].getTime() >= now.getTime()) ||
      // if current date is last
      (i !== 0 &&
        i + 1 === dates.length &&
        date.getTime() < now.getTime() &&
        addToDate(
          date,
          date.getTime() - dates[i - 1].getTime(),
          'millisecond',
        ).getTime() >= now.getTime())
    ) {
      today = (
        <rect
          x={tickX}
          y={0}
          width={columnWidth}
          height={y}
          fill={todayColor}
        />
      );
    }
    // rtl for today

      // weekends
    if ( viewMode === 'Day' && isWeekend(dates[i+1])) {
      weekends.push (
        <rect
          x={tickX}
          y={0}
          width={columnWidth+1}
          height={y}
          fill={'#dde5ff80'}
        />
      );
    }

    if (
      rtl &&
      i + 1 !== dates.length &&
      date.getTime() >= now.getTime() &&
      dates[i + 1].getTime() < now.getTime()
    ) {
      today = (
        <rect
          x={tickX + columnWidth}
          y={0}
          width={columnWidth}
          height={y}
          fill={todayColor}
        />
      );
    }
    tickX += columnWidth;
  }
  return (
    <g className="gridBody">
      <g className="rows">{gridRows}</g>
      <g className="rowLines">{rowLines}</g>
      <g className="ticks">{ticks}</g>
      <g className="today">{today}</g>
      <g className="weekends">{weekends}</g>
    </g>
  );
};

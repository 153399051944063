import * as React from 'react';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { hiddenContentStyle, mergeStyles } from '@fluentui/react/lib/Styling';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { useTranslation } from 'react-i18next';

const dialogStyles = { main: { maxWidth: 450 } };
const dragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const screenReaderOnly = mergeStyles(hiddenContentStyle);

const DiscardDialog: React.FunctionComponent<any> = ({
                                                       show,
                                                       onClickCancelDialog,
                                                       onClickConfirmDialog,
                                                       onClickSaveDialog
                                                     }) => {
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
  const labelId: string = useId('dialogLabel');
  const subTextId: string = useId('subTextLabel');

  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
      dragOptions: isDraggable ? dragOptions : undefined,
    }),
    [isDraggable],
  );
  const { t } = useTranslation();
  const dialogContentProps = {
    type: DialogType.normal,
    title: t('Discard Changes'),
    closeButtonAriaLabel: 'Close',
    subText: t('areyousureyouwanttodiscardyourchanges'),
  };

  return (
    <>
      <Dialog
        hidden={show}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <DefaultButton onClick={onClickCancelDialog} text={t('Cancel')}/>
          <PrimaryButton onClick={onClickConfirmDialog} text={t('OK')}/>
          <PrimaryButton onClick={onClickSaveDialog} text={t('save')}/>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default DiscardDialog;

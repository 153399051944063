import _ from 'lodash';
import {
    GET_WBS_GROUP_TASK_LIST,
    GET_WBS_GROUP_TASK_LIST_FAIL,
    GET_WBS_GROUP_TASK_LIST_SUCCESS,
    GET_WBS_TASK_BY_ID,
    GET_WBS_TASK_BY_ID_FAIL,
    GET_WBS_TASK_BY_ID_SUCCESS,
    GET_WBS_TASK_DROPDOWN,
    GET_WBS_TASK_DROPDOWN_FAIL,
    GET_WBS_TASK_DROPDOWN_SUCCESS,
    GET_WBS_TASK_INSTRUCTION_LIST,
    GET_WBS_TASK_INSTRUCTION_LIST_FAIL,
    GET_WBS_TASK_INSTRUCTION_LIST_SUCCESS,
    GET_WBS_TASK_LIST,
    GET_WBS_TASK_LIST_FAIL,
    GET_WBS_TASK_LIST_SUCCESS,
    GET_WBS_TAXONOMY,
    GET_WBS_TAXONOMY_FAIL,
    GET_WBS_TAXONOMY_SUCCESS,
    SAVE_TASK_MODAL_DATE,
    SAVE_TASK_MODAL_DATE_FAIL,
    SAVE_TASK_MODAL_DATE_SUCCESS,
    SAVE_WBS_TASK,
    SAVE_WBS_TASK_FAIL,
    SAVE_WBS_TASK_IS_FAV,
    SAVE_WBS_TASK_IS_FAV_FAIL,
    SAVE_WBS_TASK_IS_FAV_SUCCESS,
    SAVE_WBS_TASK_STATE_ATTR,
    SAVE_WBS_TASK_SUCCESS,
    WBS_TASK_STATUS_UPDATE,
    WBS_TASK_STATUS_UPDATE_FAIL,
    WBS_TASK_STATUS_UPDATE_SUCCESS,
} from '../actionTypes/wbsTaskActionTypes';
import {
    GET_WBS_GROUP_TASK_LIST_EP,
    GET_WBS_TASK_BY_ID_EP,
    GET_WBS_TASK_DROPDOWN_EP,
    GET_WBS_TASK_INSTRUCTION_LIST_EP,
    GET_WBS_TASK_IS_FAV_EP,
    GET_WBS_TASK_LIST_EP,
    GET_WBS_TAXONOMY_EP,
    SAVE_TASK_MODAL_DATE_EP,
    SAVE_WBS_TASK_EP,
    WBS_TASK_STATUS_UPDATE_EP,
} from '../shared/endpoints';
import { WbsTaskInstructionList } from '../types/wbs';

const { state }: any = location;

// const wbsTaskGroupFiltersInit = {
//       name: null,
//       deliveryStatusId: null,
//       statusId: null,
//       groupItem: null,
//       shownBy: null,
//       sorter: {
//           attribute: null,
//           order: null,
//       },
//   };

const defaultState: any = {
    showWbsProgressBar: false,
    wbsTaskList:[],
    wbsTaxonomy:[],
    wbsTaskGroupList:[],
    wbsTaskDropdowns:[],
    reloadListPane:false,
    isLoadingWbsFilter: false,
    isLoadingDocPane: false,
    showWbsTaskProgressBar: false,
    formData:{},
    reloadDocPane:false,
    wbsTaskInstructionList:[],
    wbsListPaneMsg: null,
    newWbsTaskId: null,
    wbsTaskGroupFilters: {
        name: state?.name ? state?.name : null,
        deliveryStatusId: state?.deliveryStatusId ? state?.deliveryStatusId : null,
        statusId: state?.statusId ? state?.statusId : null,
        groupItem: state?.groupBy ? state?.groupBy : null,
        shownBy: state?.shownBy ? state?.shownBy : null,
        sorter: {
            attribute: state?.sortBy ? state?.sortBy : null,
            order: null,
        },
    },
    wbsActiveSelection: { id: 'wbs' },
}

export default function wbsTaskReducer(state = defaultState, action: any) {
    if (action) {
        switch (action.type) {

            case SAVE_WBS_TASK_STATE_ATTR:
                const attr = Object.keys(action)[1];
                const value = Object.values(action)[1];
                return {
                    ...state,
                    [attr]: value
                };
            case GET_WBS_TASK_LIST:
                return {
                    ...state,
                    isLoadingWbsFilter: true,
                    // wbsTaskList:[]
                };
            case GET_WBS_TASK_LIST_SUCCESS:
                return {
                    ...state,
                    reloadListPane: false,
                    isLoadingWbsFilter: false,
                    // wbsTaskList: flatTreeItems,
                    wbsTaskList: formatTreeData(action.payload.data.result),
                };
            case GET_WBS_TASK_LIST_FAIL:
                return {
                    ...state,
                    reloadListPane: false,
                    isLoadingWbsFilter: false,
                };

            case GET_WBS_GROUP_TASK_LIST:
                return {
                    ...state,
                    isLoadingWbsFilter: true,
                    wbsTaskGroupList:[],
                    // wbsTaskGroupFilters: wbsTaskGroupFiltersInit,
                };
            case GET_WBS_GROUP_TASK_LIST_SUCCESS:
                return {
                    ...state,
                    reloadListPane: false,
                    isLoadingWbsFilter: false,
                    // wbsTaskGroupList: groupList,
                    wbsTaskGroupList: action.payload.data.result,
                };
            case GET_WBS_GROUP_TASK_LIST_FAIL:
                return {
                    ...state,
                    reloadListPane: false,
                    isLoadingWbsFilter: false,
                };

            case SAVE_WBS_TASK_IS_FAV:
                return {
                    ...state,
                    // wbsTaskList:[]
                };
            case SAVE_WBS_TASK_IS_FAV_SUCCESS:
                return {
                    ...state,
                    reloadListPane:true,
                };
            case SAVE_WBS_TASK_IS_FAV_FAIL:
                return {
                    ...state,
                };

            case GET_WBS_TASK_BY_ID:
                return {
                    ...state,
                    formData:{},
                    isLoadingDocPane:true,
                    reloadDocPane:false
                };
            case GET_WBS_TASK_BY_ID_SUCCESS:
                return {
                    ...state,
                    isLoadingDocPane:false,
                    // formData:formData
                    formData:action.payload.data.result,
                    newWbsTaskId:null
                };
            case GET_WBS_TASK_BY_ID_FAIL:
                return {
                    ...state,
                    formData:formData,
                    isLoadingDocPane:false,
                    newWbsTaskId:null,
                };

            case SAVE_WBS_TASK:
                return {
                    ...state,
                    isLoadingDocPane:true,
                    newWbsTaskId:null,
                };
            case SAVE_WBS_TASK_SUCCESS:
                return {
                    ...state,
                    isLoadingDocPane:false,
                    reloadListPane:true,
                    reloadDocPane:true,
                    newWbsTaskId:action.payload.data.result
                };
            case SAVE_WBS_TASK_FAIL:
                return {
                    ...state,
                    isLoadingDocPane:true
                };

            case GET_WBS_TAXONOMY:
                return {
                    ...state,
                    wbsTaxonomy:[],
                    wbsListPaneMsg: 'loading',
                };
            case GET_WBS_TAXONOMY_SUCCESS:
                return {
                    ...state,
                    wbsTaxonomy:action.payload.data.result,
                    wbsListPaneMsg: _.isEmpty(action.payload.data.result) ? 'templateNotFound' : null,
                };
            case GET_WBS_TAXONOMY_FAIL:
                return {
                    ...state,
                    wbsTaxonomy: [],
                    wbsListPaneMsg: 'error',
                };

            case GET_WBS_TASK_DROPDOWN:
                return {
                    ...state,
                };
            case GET_WBS_TASK_DROPDOWN_SUCCESS:
                return {
                    ...state,
                    wbsTaskDropdowns:action.payload.data.result
                };
            case GET_WBS_TASK_DROPDOWN_FAIL:
                return {
                    ...state,
                };

            case GET_WBS_TASK_INSTRUCTION_LIST:
                return {
                    ...state,
                };
            case GET_WBS_TASK_INSTRUCTION_LIST_SUCCESS:
                return {
                    ...state,
                    wbsTaskInstructionList:action.payload.data.result
                };
            case GET_WBS_TASK_INSTRUCTION_LIST_FAIL:
                return {
                    ...state,
                };

            case WBS_TASK_STATUS_UPDATE:
                return {
                    ...state,
                };
            case WBS_TASK_STATUS_UPDATE_SUCCESS:
                return {
                    ...state,
                };
            case WBS_TASK_STATUS_UPDATE_FAIL:
                return {
                    ...state,
                };

            case SAVE_TASK_MODAL_DATE:
                return {
                    ...state,
                    reloadListPane: false,
                };
            case SAVE_TASK_MODAL_DATE_SUCCESS:
                return {
                    ...state,
                    reloadListPane: true,
                };
            case SAVE_TASK_MODAL_DATE_FAIL:
                return {
                    ...state,
                    reloadListPane: false,
                };

            default:
                return state;
        }
    }
}

export function saveWbsTaskStateAttr(attr: string, value: any) {
    return {
        type: SAVE_WBS_TASK_STATE_ATTR,
        [attr]: value
    };
}

export const getWbsTaskList = (data: any) => {
    return {
        types: [GET_WBS_TASK_LIST, GET_WBS_TASK_LIST_SUCCESS, GET_WBS_TASK_LIST_FAIL],
        payload: {
            request: {
                url: GET_WBS_TASK_LIST_EP,
                method: 'POST',
                data: data,
            },
        },
    };
};


export const getWbsGroupTaskList = (data: any) => {
    return {
        types: [GET_WBS_GROUP_TASK_LIST, GET_WBS_GROUP_TASK_LIST_SUCCESS, GET_WBS_GROUP_TASK_LIST_FAIL],
        payload: {
            request: {
                url: GET_WBS_GROUP_TASK_LIST_EP,
                method: 'POST',
                data: data,
            },
        },
    };
};

export const getWbsTaskById = (data: {id: string}) => {
    return {
        types: [GET_WBS_TASK_BY_ID, GET_WBS_TASK_BY_ID_SUCCESS, GET_WBS_TASK_BY_ID_FAIL],
        payload: {
            request: {
                url: GET_WBS_TASK_BY_ID_EP,
                method: 'POST',
                data: data
            },
        },
    };
};

export const saveTask = (data: any) => {
    return {
        types: [SAVE_WBS_TASK, SAVE_WBS_TASK_SUCCESS, SAVE_WBS_TASK_FAIL],
        payload: {
            request: {
                url: SAVE_WBS_TASK_EP,
                method: 'POST',
                data: data,
            },
        },
    };
};



export const saveWbsTasIsFavorite = (data: { id: string, isFavourite: boolean }) => {
    return {
        types: [SAVE_WBS_TASK_IS_FAV, SAVE_WBS_TASK_IS_FAV_SUCCESS, SAVE_WBS_TASK_IS_FAV_FAIL],
        payload: {
            request: {
                url: GET_WBS_TASK_IS_FAV_EP,
                method: 'POST',
                data: data,
            },
        },
    };
};

export const getWbsTaxonomy = () => {
    return {
        types: [GET_WBS_TAXONOMY, GET_WBS_TAXONOMY_SUCCESS, GET_WBS_TAXONOMY_FAIL],
        payload: {
            request: {
                url: GET_WBS_TAXONOMY_EP,
                method: 'GET',
            },
        },
    };
};

export const getWbsTaskDropdown = () => {
    return {
        types: [GET_WBS_TASK_DROPDOWN, GET_WBS_TASK_DROPDOWN_SUCCESS, GET_WBS_TASK_DROPDOWN_FAIL],
        payload: {
            request: {
                url: GET_WBS_TASK_DROPDOWN_EP,
                method: 'GET',
            },
        },
    };
};

export const getWbsTaskInstructionList = (data: WbsTaskInstructionList) => {
    return {
        types: [GET_WBS_TASK_INSTRUCTION_LIST, GET_WBS_TASK_INSTRUCTION_LIST_SUCCESS, GET_WBS_TASK_INSTRUCTION_LIST_FAIL],
        payload: {
            request: {
                url: GET_WBS_TASK_INSTRUCTION_LIST_EP,
                method: 'POST',
                data: data,
            },
        },
    };
};

export const wbsTaskStatusUpdate = (data: any) => {
    return {
        types: [WBS_TASK_STATUS_UPDATE, WBS_TASK_STATUS_UPDATE_SUCCESS, WBS_TASK_STATUS_UPDATE_FAIL],
        payload: {
            request: {
                url: WBS_TASK_STATUS_UPDATE_EP,
                method: 'POST',
                data: data,
            },
        },
    };
};

export const formatTreeData=(filterList:any)=>{
    if(filterList && filterList?.length>0){
        return filterList.map((item:any)=>{
            return   {
                id: item?.id,
                title: item?.name,
                personName:item?.personName,
                toPerson:item?.toPerson,
                isFavorite:item?.isFavourite,
                date:item?.date,
                completedTask:item?.completedTask,
                totalTask:item?.totalTask,
                totalAttachment:item?.totalAttachment,
                totalMail:item?.totalMail,
                status:item?.statusId,
                deliveryStatus:item?.deliveryStatusId,
                totalCheckedInstructions: item?.totalCheckedInstructions,
                totalInstructions: item?.totalInstructions,
            }
        })
    }else{
        return []
    }
}


export const saveTaskModalDate = (data: { 'taskId': string, 'date': string }) => {
    return {
        types: [
            SAVE_TASK_MODAL_DATE,
            SAVE_TASK_MODAL_DATE_SUCCESS,
            SAVE_TASK_MODAL_DATE_FAIL,
        ],
        payload: {
            request: {
                url: SAVE_TASK_MODAL_DATE_EP,
                method: 'POST',
                data: data,
            },
        },
    };
};

// const flatTreeItems = [
//     { id: "1", title: "Level 1, item 1",name: "Level 1, item 1",isFavorite:true,date:'2023/09/25',completedTask:10,totalTask:14,totalAttachment:3,totalMail:4,status:WBS_TASK_STATUS.PENDING_DEVELOPMENT,deliveryStatus:WBS_TASK_DELIVERY_STATUS.MORE_THEN_7_DAY },
//     { id: "1-1",parentValue: "1",  title: "Level 2, item 1",name: "Level 2, item 1",isFavorite:true,date:'2023/09/25',completedTask:5,totalTask:6,totalAttachment:5,totalMail:5,status:WBS_TASK_STATUS.IN_DEVELOPMENT,deliveryStatus:WBS_TASK_DELIVERY_STATUS.OVERDUE   },
//     { id: "1-2",parentValue: "1",  title: "Level 2, item 2",name: "Level 2, item 2",isFavorite:true,date:'2023/09/28',completedTask:2,totalTask:8,totalAttachment:7,totalMail:3,status:WBS_TASK_STATUS.APPROVED,deliveryStatus:WBS_TASK_DELIVERY_STATUS.OVERDUE   },
//     { id: "1-3",parentValue: "1",  title: "Level 2, item 3",name: "Level 2, item 3",isFavorite:false,date:'2023/10/15',completedTask:3,totalTask:4,totalAttachment:2,totalMail:7,status:WBS_TASK_STATUS.IN_REVIEW,deliveryStatus:WBS_TASK_DELIVERY_STATUS.WITHIN_7_DAY   },
//     { id: "2", title: "Level 1, item 2",name: "Level 1, item 2",isFavorite:false,date:'2023/09/25',completedTask:3,totalTask:3,totalAttachment:3,totalMail:2,status:WBS_TASK_STATUS.HAND_OVER,deliveryStatus:WBS_TASK_DELIVERY_STATUS.MORE_THEN_7_DAY   },
//     { id: "2-1", parentValue: "2",  title: "Level 2, item 1" ,name: "Level 2, item 1" ,isFavorite:true,date:'2023/09/13',completedTask:3,totalTask:4,totalAttachment:1,totalMail:8,status:WBS_TASK_STATUS.PENDING_DEVELOPMENT,deliveryStatus:WBS_TASK_DELIVERY_STATUS.WITHIN_7_DAY  },
//     { id: "2-1-1", parentValue: "2-1", title: "Level 3, item 1",name: "Level 3, item 1",isFavorite:false,date:'2023/08/20',completedTask:10,totalTask:10,totalAttachment:1,totalMail:3,status:WBS_TASK_STATUS.IN_DEVELOPMENT,deliveryStatus:WBS_TASK_DELIVERY_STATUS.OVERDUE   },
//     { id: "2-1-1-1", parentValue: "2-1", title: "Level 4, item 1",name: "Level 4, item 1",isFavorite:true,date:'2023/09/25',completedTask:11,totalTask:12,totalAttachment:2,totalMail:5,status:WBS_TASK_STATUS.APPROVED,deliveryStatus:WBS_TASK_DELIVERY_STATUS.MORE_THEN_7_DAY  },
// ];

// const groupList=[
//     {id:1,date:'10/06/2023',name:"tag 1",project:"Project 1",filterList:flatTreeItems},
//     {id:2,date:'10/05/2023',name:"tag 2",project:"Project 2",filterList:flatTreeItems},
//     {id:3,date:'10/04/2023',name:"tag 3",project:"Project 3",filterList:flatTreeItems},
// ]


const formData={
    id: "1234",
    taskName: "test",
    note: "test",
    siteManagerName:null,
    wbsId: "88bc4819-qnmp-4c38-8a59-20da60ecb89e",
    tags:[{id:"1001",name:"tag1"},{id:"1002",name:"tag2"}],
    to:[
        {
            id: "40b63ddf-ee4c-4242-8b44-8e3ef5f70139",
            personId: "3f58c0ac-1ee4-4bb2-98a4-c3d70d23888c",
            personName: "Duminda Kuruppu"
        }
    ],
    cc:[
        {
            id: "40b63ddf-ee4c-4242-8b44-8e3ef5f70134",
            personId: "3f58c0ac-1ee4-4bb2-98a4-c3d70d23884c",
            personName: "Pradeep Madhusanka"
        },
        {
            id: "40b63ddf-ee4c-4242-8b44-8e3ef5f70135",
            personId: "3f58c0ac-1ee4-4bb2-98a4-c3d70d23886c",
            personName: "Supun Costha"
        }
    ],
    instructions:[
        {id:"1",title:"Option 1",isChecked:true},
        {id:"2",title:"Option 2",isChecked:false},
        {id:"3",title:"Option 3",isChecked:false},
        {id:"4",title:"Option 4",isChecked:true},
    ],
    documents:[
        {id:"1",link:"https://uprince.blob.core.windows.net/uprince/Instruction_2b1536a7-6af1-4f3c-aa2d-cc14454de0c7/9/8/2023%205%3A53%3A08%20AMTFEAD320230808102231575000.pdf"},
        {id:"2",link:"https://uprince.blob.core.windows.net/uprince/Instruction_9effb563-24f1-4c68-8375-c843711c540d/9/8/2023%205%3A57%3A22%20AMElec_Drawing.jpeg"},
        {id:"3",link:"https://uprince.blob.core.windows.net/uprince/Instruction_0c88c2ae-4db8-4c63-8bbf-163d3ab37cf1/10/10/2023%2011%3A23%3A39%20AM08_09_2023%20%28Release%20Note%29.docx"},
        {id:"4",link:"https://uprince.blob.core.windows.net/uprince/Instruction_c1423115-d6fb-4efe-a5a7-0286645b7d2f/9/8/2023%205%3A53%3A26%20AMlot-mesi1.png"},
    ]
}

import {
  COMPETENCY_SAVE_EP,
  CREATE_COMPETANCY_TAXONOMY_EP,
  READ_COMPETENCY_BY_ID_EP,
  READ_COMPETENCY_DROPDOWN_EP,
  READ_COMPETENCY_LIST_EP,
  READ_COMPETENCY_TAXONOMY_LIST_EP,
} from '../shared/endpoints';
import {
  CLEAR_COMPETENCY_FORM_DATA,
  CLEAR_COMPETENCYUID,
  CREATE_COMPETANCY_TAXONOMY,
  CREATE_COMPETANCY_TAXONOMY_FAIL,
  CREATE_COMPETANCY_TAXONOMY_SUCCESS,
  LOAD_COMPETENCY_BY_ID,
  LOAD_COMPETENCY_BY_ID_FAIL,
  LOAD_COMPETENCY_BY_ID_SUCCESS,
  LOAD_COMPETENCY_DROPDOWN,
  LOAD_COMPETENCY_DROPDOWN_FAIL,
  LOAD_COMPETENCY_DROPDOWN_SUCCESS,
  LOAD_COMPETENCY_LSITPANE,
  LOAD_COMPETENCY_LSITPANE_FAIL,
  LOAD_COMPETENCY_LSITPANE_SUCCESS,
  LOAD_COMPETENCY_SHORTCUTPANE,
  LOAD_COMPETENCY_SHORTCUTPANE_FAIL,
  LOAD_COMPETENCY_SHORTCUTPANE_SUCCESS,
  LOAD_COMPETENCY_TAXANOMY_LIST,
  LOAD_COMPETENCY_TAXANOMY_LIST_FAIL,
  LOAD_COMPETENCY_TAXANOMY_LIST_SUCCESS,
  RESET_COMPETENCY_IS_CHANGE,
  SAVE_COMPETENCY,
  SAVE_COMPETENCY_FAIL,
  SAVE_COMPETENCY_SUCCESS,
  SET_COMPETENCY_UID,
} from '../actionTypes/competencyActionTypes';
import { v4 as uuidv4 } from 'uuid';
import { CompetenceTaxonomy } from '../types/competency';


const defaultState: any = {
  compList: [],
  isCOMPListLoaded: false,
  isNew: false,
  isChange: false,
  isEdit: false,
  showProgressBar: false,
  compDropDowns: {},
  formData: {},
  uid: null,
  loadMsg: '',
  compShortCutPaneData: [],
  reloadListPane: false,
  borResources: {},
  isLoadingCompetency: false,
  competencyTaxonomyList: [],
  compID: null,
};

// eslint-disable-next-line consistent-return
export default function competencyReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {
      case LOAD_COMPETENCY_SHORTCUTPANE:
        return { ...state };
      case LOAD_COMPETENCY_SHORTCUTPANE_SUCCESS:
        return {
          ...state,
          compShortCutPaneData: action.payload.data.result,
        };
      case LOAD_COMPETENCY_SHORTCUTPANE_FAIL:
        return { ...state, compShortCutPaneData: [] };
      case LOAD_COMPETENCY_LSITPANE:
        return { ...state, isCOMPListLoaded: false };
      case LOAD_COMPETENCY_LSITPANE_SUCCESS:
        return {
          ...state,
          isCOMPListLoaded: true,
          compList: action.payload.data.result,
          loadMsg: !action.payload.data.status
            ? action.payload.data.message
            : '',
        };
      case LOAD_COMPETENCY_LSITPANE_FAIL:
        return {
          ...state,
          isCOMPListLoaded: true,
          compList: [],
          loadMsg: action.error.response.data.message,
        };
      case RESET_COMPETENCY_IS_CHANGE:
        return {
          ...state,
          isChange: false,
        };

      case LOAD_COMPETENCY_DROPDOWN:
        return { ...state, compDropDowns: {} };
      case LOAD_COMPETENCY_DROPDOWN_SUCCESS:
        return { ...state, compDropDowns: action.payload.data.result };
      case LOAD_COMPETENCY_DROPDOWN_FAIL:
        return {
          ...state,
          compDropDowns: {},
        };
      case LOAD_COMPETENCY_BY_ID:
        return { ...state, formData: {}, showProgressBar: true, isLoadingCompetency: true };
      case LOAD_COMPETENCY_BY_ID_SUCCESS:
        return {
          ...state,
          isEdit: true,
          formData: action.payload.data.result,
          showProgressBar: false,
          isLoadingCompetency: false,
        };
      case LOAD_COMPETENCY_BY_ID_FAIL:
        return {
          ...state,
          isEdit: false,
          formData: {},
          showProgressBar: false,
          isLoadingCompetency: false,
        };

      case SAVE_COMPETENCY:
        return { ...state, showProgressBar: true, reloadListPane: false, compID: null };
      case SAVE_COMPETENCY_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          reloadListPane: true,
          compID: action.payload.data.result,
        };
      case SAVE_COMPETENCY_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
          compID: null,
        };

      case SET_COMPETENCY_UID:
        return {
          ...state,
          isEdit: false,
          uid: uuidv4(),
        };
      case CLEAR_COMPETENCYUID:
        return {
          ...state,
          uid: null,
          isChange: false,
        };
      case CLEAR_COMPETENCY_FORM_DATA:
        return {
          ...state,
          uid: null,
          isEdit: false,
          isNew: true,
          isChange: false,
          formData: {},
        };
      case LOAD_COMPETENCY_TAXANOMY_LIST:
        return { ...state, competencyTaxonomyList: [], isCOMPListLoaded: false };
      case LOAD_COMPETENCY_TAXANOMY_LIST_SUCCESS:
        return { ...state, competencyTaxonomyList: action.payload.data.result, isCOMPListLoaded: true };
      case LOAD_COMPETENCY_TAXANOMY_LIST_FAIL:
        return {
          ...state,
          competencyTaxonomyList: [], isCOMPListLoaded: false,
        };

      default:
        return state;
    }
  }
}

// export const readCompetencyShortCutPaneData = () => {
//     return {
//         types: [
//             LOAD_COMPETENCY_SHORTCUTPANE,
//             LOAD_COMPETENCY_SHORTCUTPANE_SUCCESS,
//             LOAD_COMPETENCY_SHORTCUTPANE_FAIL
//         ],
//         payload: { request: { url: READ_COMPETENCY_SHORTCUT_PANE_EP } }
//     };
// };

export const readCompetencyDropDownData = () => {
  return {
    types: [
      LOAD_COMPETENCY_DROPDOWN,
      LOAD_COMPETENCY_DROPDOWN_SUCCESS,
      LOAD_COMPETENCY_DROPDOWN_FAIL,
    ],
    payload: { request: { method: 'GET', url: READ_COMPETENCY_DROPDOWN_EP } },
  };
};


export const filterCompetencyListPaneData = () => {
  return {
    types: [
      LOAD_COMPETENCY_LSITPANE,
      LOAD_COMPETENCY_LSITPANE_SUCCESS,
      LOAD_COMPETENCY_LSITPANE_FAIL,
    ],
    payload: {
      request: {
        // method: 'POST',
        url: READ_COMPETENCY_LIST_EP,
        // data: filter
      },
    },
  };
};

export const readCompetancyTaxonomyList = () => {
  return {
    types: [
      LOAD_COMPETENCY_TAXANOMY_LIST,
      LOAD_COMPETENCY_TAXANOMY_LIST_SUCCESS,
      LOAD_COMPETENCY_TAXANOMY_LIST_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_COMPETENCY_TAXONOMY_LIST_EP,
        data: {
          competenciesId: null,
          certificationId: null,
        },
      },
    },
  };
};


export const readByCompetencyId = (id: string) => {
  return {
    types: [
      LOAD_COMPETENCY_BY_ID,
      LOAD_COMPETENCY_BY_ID_SUCCESS,
      LOAD_COMPETENCY_BY_ID_FAIL],
    payload: { request: { url: READ_COMPETENCY_BY_ID_EP + id } },
  };
};

export const resetIsChange = () => {
  return { type: RESET_COMPETENCY_IS_CHANGE };
};

export const saveFormData = (formData: any) => {
  return {
    types: [
      SAVE_COMPETENCY,
      SAVE_COMPETENCY_SUCCESS,
      SAVE_COMPETENCY_FAIL],
    payload: {
      request: {
        url: COMPETENCY_SAVE_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const createCompetancyTaxonomy = (taxonomyData: CompetenceTaxonomy) => {
  return {
    types: [
      CREATE_COMPETANCY_TAXONOMY,
      CREATE_COMPETANCY_TAXONOMY_SUCCESS,
      CREATE_COMPETANCY_TAXONOMY_FAIL],
    payload: {
      request: {
        url: CREATE_COMPETANCY_TAXONOMY_EP,
        method: 'POST',
        data: taxonomyData,
      },
    },
  };
};

export const setUid = () => {
  return { type: SET_COMPETENCY_UID };
};

export const clearFormData = () => {
  return { type: CLEAR_COMPETENCY_FORM_DATA };
};

import React from 'react';
import { Label, Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { format24hDateTime } from '../../../shared/util';
import moment from 'moment';

interface Props {
  createdByUser: string,
  updatedByUser: string,
  createdDateTime: string,
  updatedDateTime: string,
}

const QRHistoryBar = ({ createdByUser, updatedByUser, createdDateTime, updatedDateTime }: Props) => {
  const { t } = useTranslation();
  const time = moment.utc(createdDateTime).format('DD/MM HH:mm (ddd) ');
  return (
    <div>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
          <Label>{t('createdBy')}:<Text> {createdByUser}</Text></Label>
          <Label style={{ width: '100%' }}>{t('createdDateTime')}:<Text> {time}</Text></Label>
        </div>
        {updatedByUser && <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
          <Label>{t('modifiedBy')}:<Text> {updatedByUser}</Text></Label>
          <Label>{t('modifiedDateTime')}: <Text>{format24hDateTime(updatedDateTime)}</Text></Label>
        </div>}
      </div>
    </div>
  );
};
export default QRHistoryBar;

import {
    ASSIGN_VEHICLE_TO_TEAM,
    CLONE_PMOL_DAY_PLANNING_EP,
    CREATE_NEW_PROJECT_MY_ENV_EP,
    GENERATE_RFQ_EP,
    READ_MY_DAY_PLANNING_BY_ID_EP,
    READ_MY_DAY_PLANNING_DROPDOWN_EP,
    READ_MY_DAY_PLANNING_LIST_EP,
    READ_MY_DAY_PLANNING_SHORTCUT_PANE_EP,
    READ_MY_DAY_PLANNINNG_REOSURCE_LIST_EP,
    READ_MY_DAY_PLANNINNG_TEAMS_LIST_EP,
    REMOVE_LABOUR_DAY_PLANNING_EP,
    TOOL_ASSIGN_MY_DAY_PLANNING_EP,
    UPDATE_MY_DAY_PLANNING_PBS_EP,
    UPDATE_MY_DAY_PLANNING_PMOL_EP,
    UPDATE_MY_DAY_PLANNING_PMOL_PERSON_EP, UPDATE_MY_DAY_PLANNING_PROJECT_EP,
    UPDATE_MY_DAY_PLANNINNG_TEAM_LIST_EP,
    UPDATE_MY_DAY_PLANNINNG_VEHICLE_EP,
} from '../shared/endpoints';
import {
    ASSIGN_VEHICLE_TO_TEAM_CALL,
    ASSIGN_VEHICLE_TO_TEAM_FAIL,
    ASSIGN_VEHICLE_TO_TEAM_SUCCESS,
    CLEAR_MY_DAY_PLANNING_FORM_DATA,
    CLEAR_MY_DAY_PLANNINGUID,
    CLONE_PMOL_DAY_PLANNING,
    CLONE_PMOL_DAY_PLANNING_FAIL,
    CLONE_PMOL_DAY_PLANNING_SUCCESS,
    CREATE_NEW_PROJECT_MY_ENV,
    CREATE_NEW_PROJECT_MY_ENV_FAIL,
    CREATE_NEW_PROJECT_MY_ENV_SUCCESS,
    GENERATE_DP_RFQ,
    GENERATE_DP_RFQ_FAIL,
    GENERATE_DP_RFQ_SUCCESS,
    LOAD_MY_DAY_PLANNING_BY_ID,
    LOAD_MY_DAY_PLANNING_BY_ID_FAIL,
    LOAD_MY_DAY_PLANNING_BY_ID_SUCCESS,
    LOAD_MY_DAY_PLANNING_DROPDOWN,
    LOAD_MY_DAY_PLANNING_DROPDOWN_FAIL,
    LOAD_MY_DAY_PLANNING_DROPDOWN_SUCCESS,
    LOAD_MY_DAY_PLANNING_LSITPANE,
    LOAD_MY_DAY_PLANNING_LSITPANE_FAIL,
    LOAD_MY_DAY_PLANNING_LSITPANE_SUCCESS,
    LOAD_MY_DAY_PLANNING_SHORTCUTPANE,
    LOAD_MY_DAY_PLANNING_SHORTCUTPANE_FAIL,
    LOAD_MY_DAY_PLANNING_SHORTCUTPANE_SUCCESS,
    LOAD_MY_DAY_PLANNINNG_RESOURCE_LSITPANE,
    LOAD_MY_DAY_PLANNINNG_RESOURCE_LSITPANE_FAIL,
    LOAD_MY_DAY_PLANNINNG_RESOURCE_LSITPANE_SUCCESS,
    LOAD_MY_DAY_PLANNINNG_TEAMS_LIST,
    LOAD_MY_DAY_PLANNINNG_TEAMS_LIST_FAIL,
    LOAD_MY_DAY_PLANNINNG_TEAMS_LIST_SUCCESS,
    REMOVE_LABOUR_DAY_PLANNING,
    REMOVE_LABOUR_DAY_PLANNING_FAIL,
    REMOVE_LABOUR_DAY_PLANNING_SUCCESS,
    RESET_MY_DAY_PLANNING_IS_CHANGE,
    SAVE_MY_DAY_PLANNING_STATE_ATTR,
    SET_MY_DAY_PLANNING_UID,
    TOOL_ASSIGN_MY_DAY_PLANNING,
    TOOL_ASSIGN_MY_DAY_PLANNING_FAIL,
    TOOL_ASSIGN_MY_DAY_PLANNING_SUCCESS,
    UPDATE_MY_DAY_PLANNING_PBS,
    UPDATE_MY_DAY_PLANNING_PBS_FAIL,
    UPDATE_MY_DAY_PLANNING_PBS_SUCCESS,
    UPDATE_MY_DAY_PLANNING_PMOL,
    UPDATE_MY_DAY_PLANNING_PMOL_FAIL,
    UPDATE_MY_DAY_PLANNING_PMOL_PERSON,
    UPDATE_MY_DAY_PLANNING_PMOL_PERSON_FAIL,
    UPDATE_MY_DAY_PLANNING_PMOL_PERSON_SUCCESS,
    UPDATE_MY_DAY_PLANNING_PMOL_SUCCESS,
    UPDATE_MY_DAY_PLANNING_PROJECT,
    UPDATE_MY_DAY_PLANNING_PROJECT_FAIL,
    UPDATE_MY_DAY_PLANNING_PROJECT_SUCCESS,
    UPDATE_MY_DAY_PLANNINNG_TEAM_LIST,
    UPDATE_MY_DAY_PLANNINNG_TEAM_LIST_FAIL,
    UPDATE_MY_DAY_PLANNINNG_TEAM_LIST_SUCCESS,
    UPDATE_MY_DAY_PLANNINNG_VEHICLE,
    UPDATE_MY_DAY_PLANNINNG_VEHICLE_FAIL,
    UPDATE_MY_DAY_PLANNINNG_VEHICLE_SUCCESS,
} from '../actionTypes/myDayPlanningActionTypes';

import {v4 as uuidv4} from 'uuid';
import {POFilter} from '../types/purchaseOrder';
import {DpViewMode} from '../types/myDayPlanning';


const defaultState: any = {
    mydpList: [],
    isMYDPListLoaded: false,
    isNew: false,
    isChange: false,
    isEdit: false,
    showProgressBar: false,
    mydpDropDowns: {},
    formData: {},
    uid: null,
    loadMsg: '',
    mydpShortCutPaneData: [],
    reloadListPane: false,
    borResources: {},
    isLoadingMyDayPlanning: false,
    pmolId: null,
    view: 'day',
    selectedDate: new Date(),
    selectedWeek: {},
    isResourceDataLoaded: false,
    resourceList: [],
    projects: [],
    selectedMyDpPmol: null,
    mydPlanResourceType: 'worker',
    reloadBottomListPane: false,
    horizontalReSizer: screen.height - 330,
    myDpViewMode: DpViewMode.Default,
    selectedCu: null,   //remove if not in use
    selectedBu: null,   //remove if not in use
    reloadFilters: false,
    showFullScreen: false,
    loadMsgRfq: '',
    planboardPersonId: null,
    planboardPersonName: null,
    isNewProjectCreated: null,
    isPBSAssignSuccess: false,
    isUpdateTeamSuccess: false,
    isRemovePersonSuccess: false,
    isUpdatePmolPersonSuccess: false,
    pmolCopyState: {event: {}, isCopyModalOpen: false},
    isUpdatePmolDayPlanningSuccess: false,
    createdTransportPmol: false,
};

export default function myDayPlanningReducer(state = defaultState, action: any) {
    if (action) {
        switch (action.type) {
            case LOAD_MY_DAY_PLANNING_SHORTCUTPANE:
                return {
                    ...state,
                    mydpShortCutPaneData: []
                };
            case LOAD_MY_DAY_PLANNING_SHORTCUTPANE_SUCCESS:
                return {
                    ...state,
                    mydpShortCutPaneData: action.payload.data.result
                };
            case LOAD_MY_DAY_PLANNING_SHORTCUTPANE_FAIL:
                return {...state, mydpShortCutPaneData: []};
            case LOAD_MY_DAY_PLANNING_LSITPANE:
                return {...state, isMYDPListLoaded: false};
            case LOAD_MY_DAY_PLANNING_LSITPANE_SUCCESS:
                return {
                    ...state,
                    isMYDPListLoaded: true,
                    mydpList: action.payload.data.result,
                    loadMsg: !action.payload.data.status
                        ? action.payload.data.message
                        : ''
                };
            case LOAD_MY_DAY_PLANNING_LSITPANE_FAIL:
                return {
                    ...state,
                    isMYDPListLoaded: true,
                    mydpList: [],
                    loadMsg: action.error.response.data.message
                };
            case RESET_MY_DAY_PLANNING_IS_CHANGE:
                return {
                    ...state,
                    isChange: false
                };

            case LOAD_MY_DAY_PLANNING_DROPDOWN:
                return {...state, mydpDropDowns: {}};
            case LOAD_MY_DAY_PLANNING_DROPDOWN_SUCCESS:
                return {...state, mydpDropDowns: action.payload.data.result};
            case LOAD_MY_DAY_PLANNING_DROPDOWN_FAIL:
                return {
                    ...state,
                    mydpDropDowns: {}
                };
            case LOAD_MY_DAY_PLANNING_BY_ID:
                return {...state, formData: {}, showProgressBar: true, isLoadingMyDayPlanning: true};
            case LOAD_MY_DAY_PLANNING_BY_ID_SUCCESS:
                return {
                    ...state,
                    isEdit: true,
                    formData: action.payload.data.result,
                    showProgressBar: false,
                    isLoadingMyDayPlanning: false
                };
            case LOAD_MY_DAY_PLANNING_BY_ID_FAIL:
                return {
                    ...state,
                    formData: {},
                    showProgressBar: false,
                    isLoadingMyDayPlanning: false
                };

            case UPDATE_MY_DAY_PLANNING_PMOL:
                return {
                    ...state,
                    showProgressBar: true,
                    reloadListPane: false,
                    reloadBottomListPane: false,
                    pmolId: null,
                    isUpdatePmolDayPlanningSuccess: false
                };
            case UPDATE_MY_DAY_PLANNING_PMOL_SUCCESS:
                return {
                    ...state,
                    isChange: true,
                    isNew: false,
                    showProgressBar: false,
                    reloadListPane: true,
                    reloadBottomListPane: true,
                    pmolId: action.payload.data.result,
                    isUpdatePmolDayPlanningSuccess: true
                };
            case UPDATE_MY_DAY_PLANNING_PMOL_FAIL:
                return {
                    ...state,
                    showProgressBar: false,
                    reloadListPane: true,
                    reloadBottomListPane: true,
                    pmolId: null,
                    isUpdatePmolDayPlanningSuccess: false
                };
            case UPDATE_MY_DAY_PLANNING_PBS:
                return {
                    ...state,
                    showProgressBar: true,
                    reloadListPane: false,
                    reloadBottomListPane: false,
                    pbsId: null,
                    isPBSAssignSuccess: false
                };
            case UPDATE_MY_DAY_PLANNING_PBS_SUCCESS:
                return {
                    ...state,
                    isChange: true,
                    isNew: false,
                    showProgressBar: false,
                    reloadListPane: true,
                    reloadBottomListPane: true,
                    pbsId: action.payload.data.result,
                    isPBSAssignSuccess: true
                };
            case UPDATE_MY_DAY_PLANNING_PBS_FAIL:
                return {
                    ...state,
                    showProgressBar: false,
                    reloadListPane: true,
                    reloadBottomListPane: true,
                    pbsId: null,
                    isPBSAssignSuccess: false
                };

            case SET_MY_DAY_PLANNING_UID:
                return {
                    ...state,
                    isEdit: false,
                    uid: uuidv4()
                };
            case CLEAR_MY_DAY_PLANNINGUID:
                return {
                    ...state,
                    uid: null,
                    isChange: false
                };
            case CLEAR_MY_DAY_PLANNING_FORM_DATA:
                return {
                    ...state,
                    uid: null,
                    isNew: true,
                    isChange: false,
                    formData: {}
                };

            case SAVE_MY_DAY_PLANNING_STATE_ATTR:
                const attr = Object.keys(action)[1];
                const value = Object.values(action)[1];
                return {
                    ...state,
                    [attr]: value
                };

            case LOAD_MY_DAY_PLANNINNG_TEAMS_LIST:
                return {
                    ...state,
                    showProgressBar: true
                };
            case LOAD_MY_DAY_PLANNINNG_TEAMS_LIST_SUCCESS:
                return {
                    ...state,
                    teamList: action.payload.data.result,
                    loadMsg: !action.payload.data.status
                        ? action.payload.data.message
                        : '',
                    showProgressBar: false
                };
            case LOAD_MY_DAY_PLANNINNG_TEAMS_LIST_FAIL:
                return {
                    ...state,
                    teamList: [],
                    loadMsg: action?.error?.response?.data?.message,
                    showProgressBar: false
                };

            case LOAD_MY_DAY_PLANNINNG_RESOURCE_LSITPANE:
                return {...state, isResourceDataLoaded: false};
            case LOAD_MY_DAY_PLANNINNG_RESOURCE_LSITPANE_SUCCESS:
                return {
                    ...state,
                    isResourceDataLoaded: true,
                    resourceList: action.payload.data.result,
                    reloadBottomListPane: false,
                    loadMsg: !action.payload.data.status
                        ? action.payload.data.message
                        : ''
                };
            case LOAD_MY_DAY_PLANNINNG_RESOURCE_LSITPANE_FAIL:
                return {
                    ...state,
                    isResourceDataLoaded: true,
                    reloadBottomListPane: false,
                    resourceList: [],
                    loadMsg: action?.error?.response?.data?.message
                };

            case UPDATE_MY_DAY_PLANNING_PMOL_PERSON:
                return {
                    ...state,
                    showProgressBar: true,
                    isUpdatePmolPersonSuccess: false,
                };
            case UPDATE_MY_DAY_PLANNING_PMOL_PERSON_SUCCESS:
                return {
                    ...state,
                    isChange: true,
                    isNew: false,
                    showProgressBar: false,
                    isUpdatePmolPersonSuccess: true,
                };
            case UPDATE_MY_DAY_PLANNING_PMOL_PERSON_FAIL:
                return {
                    ...state,
                    showProgressBar: false,
                    isUpdatePmolPersonSuccess: false,
                };

            case UPDATE_MY_DAY_PLANNINNG_TEAM_LIST:
                return {
                    ...state,
                    showProgressBar: true,
                    isUpdateTeamSuccess: false,
                };
            case UPDATE_MY_DAY_PLANNINNG_TEAM_LIST_SUCCESS:
                return {
                    ...state,
                    showProgressBar: false,
                    isUpdateTeamSuccess: true,
                };
            case UPDATE_MY_DAY_PLANNINNG_TEAM_LIST_FAIL:
                return {
                    ...state,
                    showProgressBar: false,
                    isUpdateTeamSuccess: false,
                };

            case UPDATE_MY_DAY_PLANNINNG_VEHICLE:
                return {
                    ...state,
                    showProgressBar: true,
                };
            case UPDATE_MY_DAY_PLANNINNG_VEHICLE_SUCCESS:
                return {
                    ...state,
                    showProgressBar: false,
                };
            case UPDATE_MY_DAY_PLANNINNG_VEHICLE_FAIL:
                return {
                    ...state,
                    showProgressBar: false,
                };
            case ASSIGN_VEHICLE_TO_TEAM_CALL:
                return {
                    ...state,
                    showProgressBar: true,
                }
            case ASSIGN_VEHICLE_TO_TEAM_SUCCESS:
                return {
                    ...state,
                    showProgressBar: false,
                    // reloadListPane: true,
                    // reloadBottomListPane: true,
                    // isUpdatePmolDayPlanningSuccess: true
                }
            case ASSIGN_VEHICLE_TO_TEAM_FAIL:
                return {
                    ...state,
                    showProgressBar: false,
                    // reloadListPane: true,
                    // reloadBottomListPane: true,
                    // isUpdatePmolDayPlanningSuccess: false
                }
            case CLONE_PMOL_DAY_PLANNING:
                return {
                    ...state,
                    showProgressBar: true,
                    reloadListPane: false,
                    createdTransportPmol: false,
                };
            case CLONE_PMOL_DAY_PLANNING_SUCCESS:
                return {
                    ...state,
                    showProgressBar: false,
                    reloadListPane: true,
                    createdTransportPmol: true,
                };
            case CLONE_PMOL_DAY_PLANNING_FAIL:
                return {
                    ...state,
                    showProgressBar: false,
                    reloadListPane: true,
                    createdTransportPmol: false,
                };

            case TOOL_ASSIGN_MY_DAY_PLANNING:
                return {
                    ...state,
                    showProgressBar: true,
                };
            case TOOL_ASSIGN_MY_DAY_PLANNING_SUCCESS:
                return {
                    ...state,
                    showProgressBar: false,
                };
            case TOOL_ASSIGN_MY_DAY_PLANNING_FAIL:
                return {
                    ...state,
                    showProgressBar: false,
                };

            case GENERATE_DP_RFQ:
                return {
                    ...state,
                    showProgressBar: true,
                    loadMsgRfq: ''
                };
            case GENERATE_DP_RFQ_SUCCESS:
                return {
                    ...state,
                    showProgressBar: false,
                    loadMsgRfq: !action.payload.data.status ? action.payload.data.message : '',
                };
            case GENERATE_DP_RFQ_FAIL:
                return {
                    ...state,
                    showProgressBar: false,
                    loadMsgRfq: action.error.response.data.message
                };

            case REMOVE_LABOUR_DAY_PLANNING:
                return {
                    ...state,
                    showProgressBar: true,
                    isRemovePersonSuccess: false,
                };
            case REMOVE_LABOUR_DAY_PLANNING_SUCCESS:
                return {
                    ...state,
                    showProgressBar: false,
                    isRemovePersonSuccess: true,
                };
            case REMOVE_LABOUR_DAY_PLANNING_FAIL:
                return {
                    ...state,
                    showProgressBar: false,
                    isRemovePersonSuccess: false,
                };
            case CREATE_NEW_PROJECT_MY_ENV:
                return {
                    ...state,
                    showProgressBar: true,
                    isNewProjectCreated: false,
                };
            case CREATE_NEW_PROJECT_MY_ENV_SUCCESS:
                return {
                    ...state,
                    showProgressBar: false,
                    isNewProjectCreated: true,
                };
            case CREATE_NEW_PROJECT_MY_ENV_FAIL:
                return {
                    ...state,
                    showProgressBar: false,
                    isNewProjectCreated: false,
                };

            default:
                return state;
        }
    }
}

export const filterMyDayPlanningShortCutPaneData = (filter: any) => {
    return {
        types: [
            LOAD_MY_DAY_PLANNING_SHORTCUTPANE,
            LOAD_MY_DAY_PLANNING_SHORTCUTPANE_SUCCESS,
            LOAD_MY_DAY_PLANNING_SHORTCUTPANE_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: READ_MY_DAY_PLANNING_SHORTCUT_PANE_EP,
                data: filter
            }
        }
    };
};

export const readMyDayPlanningDropDownData = () => {
    return {
        types: [
            LOAD_MY_DAY_PLANNING_DROPDOWN,
            LOAD_MY_DAY_PLANNING_DROPDOWN_SUCCESS,
            LOAD_MY_DAY_PLANNING_DROPDOWN_FAIL
        ],
        payload: {request: {method: 'GET', url: READ_MY_DAY_PLANNING_DROPDOWN_EP}}
    };
};


export const filterMyDayPlanningListPaneData = (filter: POFilter) => {
    return {
        types: [
            LOAD_MY_DAY_PLANNING_LSITPANE,
            LOAD_MY_DAY_PLANNING_LSITPANE_SUCCESS,
            LOAD_MY_DAY_PLANNING_LSITPANE_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: READ_MY_DAY_PLANNING_LIST_EP,
                data: filter
            }
        }
    };
};


export const readByMyDayPlanningId = (id: string) => {
    return {
        types: [
            LOAD_MY_DAY_PLANNING_BY_ID,
            LOAD_MY_DAY_PLANNING_BY_ID_SUCCESS,
            LOAD_MY_DAY_PLANNING_BY_ID_FAIL],
        payload: {request: {url: READ_MY_DAY_PLANNING_BY_ID_EP + id}}
    };
};

export const resetIsChange = () => {
    return {type: RESET_MY_DAY_PLANNING_IS_CHANGE};
};

export const updateMyDayPlanningPmol = (pmol: any) => {
    return {
        types: [
            UPDATE_MY_DAY_PLANNING_PMOL,
            UPDATE_MY_DAY_PLANNING_PMOL_SUCCESS,
            UPDATE_MY_DAY_PLANNING_PMOL_FAIL
        ],
        payload: {
            request: {
                url: UPDATE_MY_DAY_PLANNING_PMOL_EP,
                method: 'POST',
                data: pmol
            }
        }
    };
};

export const assignPlanningPbs = (pbs: any) => {
    return {
        types: [
            UPDATE_MY_DAY_PLANNING_PBS,
            UPDATE_MY_DAY_PLANNING_PBS_SUCCESS,
            UPDATE_MY_DAY_PLANNING_PBS_FAIL
        ],
        payload: {
            request: {
                url: UPDATE_MY_DAY_PLANNING_PBS_EP,
                method: 'POST',
                data: pbs
            }
        }
    };
};

export const assignPlanningProject = (project: any) => {
    return {
        types: [
            UPDATE_MY_DAY_PLANNING_PROJECT,
            UPDATE_MY_DAY_PLANNING_PROJECT_SUCCESS,
            UPDATE_MY_DAY_PLANNING_PROJECT_FAIL
        ],
        payload: {
            request: {
                url: UPDATE_MY_DAY_PLANNING_PROJECT_EP,
                method: 'POST',
                data: project
            }
        }
    };
};

export const setUid = () => {
    return {type: SET_MY_DAY_PLANNING_UID};
};

export const clearFormData = () => {
    return {type: CLEAR_MY_DAY_PLANNING_FORM_DATA};
};

export function saveMyDayPlanningStateAttr(attr: string, value: any) {
    return {
        type: SAVE_MY_DAY_PLANNING_STATE_ATTR,
        [attr]: value
    };
}

export const filterMyDayPlanningTeamsData = (filter: any) => {
    return {
        types: [
            LOAD_MY_DAY_PLANNINNG_TEAMS_LIST,
            LOAD_MY_DAY_PLANNINNG_TEAMS_LIST_SUCCESS,
            LOAD_MY_DAY_PLANNINNG_TEAMS_LIST_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: READ_MY_DAY_PLANNINNG_TEAMS_LIST_EP,
                data: filter
            }
        }
    };
};
//GetAvailableTeams
export const filterMyDayPlanningResourceListPaneData = (filter: any) => {  
    return {
        types: [
            LOAD_MY_DAY_PLANNINNG_RESOURCE_LSITPANE,
            LOAD_MY_DAY_PLANNINNG_RESOURCE_LSITPANE_SUCCESS,
            LOAD_MY_DAY_PLANNINNG_RESOURCE_LSITPANE_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: READ_MY_DAY_PLANNINNG_REOSURCE_LIST_EP,
                data: filter
            }
        }
    };
};

export const updateMyDayPlanningPmolPerson = (pmol: any) => {
    return {
        types: [
            UPDATE_MY_DAY_PLANNING_PMOL_PERSON,
            UPDATE_MY_DAY_PLANNING_PMOL_PERSON_SUCCESS,
            UPDATE_MY_DAY_PLANNING_PMOL_PERSON_FAIL
        ],
        payload: {
            request: {
                url: UPDATE_MY_DAY_PLANNING_PMOL_PERSON_EP,
                method: 'POST',
                data: pmol
            }
        }
    };
};

export const createNewTeamDayPlanning = (pmol: any) => {
    return {
        types: [
            UPDATE_MY_DAY_PLANNINNG_TEAM_LIST,
            UPDATE_MY_DAY_PLANNINNG_TEAM_LIST_SUCCESS,
            UPDATE_MY_DAY_PLANNINNG_TEAM_LIST_FAIL
        ],
        payload: {
            request: {
                url: UPDATE_MY_DAY_PLANNINNG_TEAM_LIST_EP,
                method: 'POST',
                data: pmol
            }
        }
    };
};

export const updateMyDayPlanningVehicle = (team: any) => {
    return {
        types: [
            UPDATE_MY_DAY_PLANNINNG_VEHICLE,
            UPDATE_MY_DAY_PLANNINNG_VEHICLE_SUCCESS,
            UPDATE_MY_DAY_PLANNINNG_VEHICLE_FAIL
        ],
        payload: {
            request: {
                url: UPDATE_MY_DAY_PLANNINNG_VEHICLE_EP,
                method: 'POST',
                data: team
            }
        }
    };
};

export const assignVehicleToTeam = (teamData : any) => {
    return{
        types: [
            ASSIGN_VEHICLE_TO_TEAM_CALL,
            ASSIGN_VEHICLE_TO_TEAM_SUCCESS,
            ASSIGN_VEHICLE_TO_TEAM_FAIL
        ],
        payload: {
            request: {
                url: ASSIGN_VEHICLE_TO_TEAM,
                method: 'POST',
                data: teamData
            }
        }
    };
}

export const clonePmolDayPlanning = (data: any) => {
    return {
        types: [
            CLONE_PMOL_DAY_PLANNING,
            CLONE_PMOL_DAY_PLANNING_SUCCESS,
            CLONE_PMOL_DAY_PLANNING_FAIL
        ],
        payload: {
            request: {
                url: CLONE_PMOL_DAY_PLANNING_EP,
                method: 'POST',
                data: data
            }
        }
    };
};

export const assignMyDayPlanningTool = (data: any) => {
    return {
        types: [
            TOOL_ASSIGN_MY_DAY_PLANNING,
            TOOL_ASSIGN_MY_DAY_PLANNING_SUCCESS,
            TOOL_ASSIGN_MY_DAY_PLANNING_FAIL
        ],
        payload: {
            request: {
                url: TOOL_ASSIGN_MY_DAY_PLANNING_EP,
                method: 'POST',
                data: data
            }
        }
    };
};

export const generateDpRFQ = (data: any) => {
    return {
        types: [
            GENERATE_DP_RFQ,
            GENERATE_DP_RFQ_SUCCESS,
            GENERATE_DP_RFQ_FAIL
        ],
        payload: {
            request: {
                url: GENERATE_RFQ_EP,
                method: 'POST',
                data: data
            }
        }
    };
};

export const updateMyDayPlanningLabour = (data: any) => {
    return {
        types: [
            REMOVE_LABOUR_DAY_PLANNING,
            REMOVE_LABOUR_DAY_PLANNING_SUCCESS,
            REMOVE_LABOUR_DAY_PLANNING_FAIL
        ],
        payload: {
            request: {
                url: REMOVE_LABOUR_DAY_PLANNING_EP,
                method: 'POST',
                data: data
            }
        }
    };
};

export const createNewProject = (data: any) => {
    return {
        types: [
            CREATE_NEW_PROJECT_MY_ENV,
            CREATE_NEW_PROJECT_MY_ENV_SUCCESS,
            CREATE_NEW_PROJECT_MY_ENV_FAIL
        ],
        payload: {
            request: {
                url: CREATE_NEW_PROJECT_MY_ENV_EP,
                method: 'POST',
                data: data
            }
        }
    };
}

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {IColumn, Selection} from '@fluentui/react/lib/DetailsList';
import history from '../../../history';
import ListPaneComponent from './component';
import {withRouter} from 'react-router';
import {WithTranslation, withTranslation} from 'react-i18next';
import {readVisualPlanDropDownData} from '../../../reducers/visualPlanReducer';
import {messageService} from '../../../services/messageService';
import {
    ListPaneItem,
    ProgressStatement,
    PSDropDown,
    psFormData,
    PSListPaneFilter,
} from '../../../types/progressStatement';
import {getContractingUnit, getProject} from '../../../shared/util';

interface State {
    redirect: boolean;
    isChange: boolean;
    loading: boolean;
    filter: PSListPaneFilter;
    announcedMessage: string;
    selectedListItemIds: [] | string[];
    selectedListItemSequenceCode: [] | string[];
    formData: ProgressStatement;
    selectedListItems: ListPaneItem[];
    vpOrgList: any[];
    vpBarList: any[]
}

interface Props extends WithTranslation {
    listPaneItems: ListPaneItem[];
    reloadListPaneItem: () => void;
    handelFilter: (filter: PSListPaneFilter) => void;
    isDataLoaded: boolean;
    isChange: boolean;
    filter: PSListPaneFilter;
    resourceTypes: [];
    loadMsg: string;
    toleranceStatesFilter: [];
    productStatesFilter: [];
    itemTypesFilter: [];
    handleSelectedListPaneItem: (selectedItemIds: [] | string[]) => void;
    formData: ProgressStatement;
    projectId: string | null;
    psDropDowns: PSDropDown;
    reSizer: number;
    readVisualPlanDropDownData: () => void;
    vpOrgList: any;
    vpBarList: any;
    mtpOrgTaxonomyList: any[];
    uid: string;
    vpWhToolsList: any[];
    isToolsDataLoaded: boolean

}

class TaxonomyPane extends Component<Props, State> {
    private _Selection: Selection;
    private _columns: IColumn[];
    subscription: any;

    constructor(props: Props) {
        super(props);
        this._Selection = new Selection({
            onSelectionChanged: () => {
                this.setState(
                    {
                        selectedListItemIds: this._getSelectedIds(),
                        selectedListItemSequenceCode: this._getselectedSequenceCode(),
                        selectedListItems: this._getSelectedListItems(),
                    },
                );
            },
        });
        this.state = {
            selectedListItems: [],
            selectedListItemIds: [],
            selectedListItemSequenceCode: [],
            redirect: false,
            isChange: false,
            loading: false,
            announcedMessage: '',
            filter: new PSListPaneFilter(),
            formData: psFormData,
            vpOrgList: [],
            vpBarList: [],
        };

        this._columns = [
            {
                key: 'column1',
                name: this.props.t('type'),
                fieldName: 'type',
                minWidth: 165,
                maxWidth: 200,
                isResizable: true,
                onColumnClick: this._onColumnClick,
            },
            {
                key: 'column2',
                name: this.props.t('totalAmountexVat'),
                fieldName: 'totalAmount',
                minWidth: 100,
                maxWidth: 200,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                className: 'totalAmountCol',
            },
            {
                key: 'column3',
                name: this.props.t('date'),
                fieldName: 'date',
                minWidth: 100,
                maxWidth: 200,
                isResizable: true,
                onColumnClick: this._onColumnClick,
            },
            {
                key: 'column4',
                name: this.props.t('status'),
                fieldName: 'status',
                minWidth: 100,
                maxWidth: 200,
                isResizable: true,
                onColumnClick: this._onColumnClick,
            },
        ];
    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    componentDidMount() {
        this.subscription = messageService.getMessage().subscribe((data: any) => {
            if (data) {
                if (data.data.isNewPbs) {
                    this._Selection.selectToKey('', true);
                }
                if (data.data.resetListPaneSelection) {
                    this._Selection.setAllSelected(false);
                    this.resetSelection();
                }
            }
        });
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.isDataLoaded != this.props.isDataLoaded) {
            if (this.props.isDataLoaded) {
                this.resetSelection();
            }
        }

        if (prevProps.mtpOrgTaxonomyList !== this.props.mtpOrgTaxonomyList) {
            this.setState({vpOrgList: this.props.mtpOrgTaxonomyList, vpBarList: this.props.mtpOrgTaxonomyList});
        }
    }

    handleBarTaskItemClick = (id: string) => {
        if (getContractingUnit() && !getProject()) {
            history.push(`/CU/${getContractingUnit()}/visual-plan/${id}`);
        } else {
            history.push(`/CU/${getContractingUnit()}/visual-plan/${id}`);
        }
    };

    handleFilterChange = (filter: PSListPaneFilter) => {
        this.props.handelFilter(filter);
    };

    handleFilterSortChange = () => {
        this.props.handelFilter(this.state.filter);
    };

    resetSelection = () => {
        if (
            this.state.formData &&
            this.state.formData.progressStatementId &&
            this.props.isDataLoaded
        ) {
            this._Selection.setKeySelected(
                this.state.formData.progressStatementId,
                true,
                false,
            );
        } else {
            this._Selection.selectToKey('', true);
        }
    };

    render() {
        return (
            <div style={{height: 'inherit'}}>
                <ListPaneComponent
                    vpOrgList={this.state.vpOrgList}
                    isDataLoaded={this.props.isDataLoaded}
                    //handleBarTaskItemClick={(id:string) => this.handleBarTaskItemClick(id)}
                    isToolsDataLoaded={this.props.isToolsDataLoaded}
                    vpWhToolsList={this.props.vpWhToolsList}
                />
            </div>
        );
    }

    private _getSelectedIds() {
        let deleteList: any = [];
        let SelectedItem = this._Selection.getSelection() as ListPaneItem[];
        if (SelectedItem) {
            deleteList = SelectedItem.map((item: any) => {
                return item ? item.id : null;
            });
        }
        return deleteList;
    }

    private _getselectedSequenceCode() {
        let selectedList: any = [];
        let selectedItem = this._Selection.getSelection() as ListPaneItem[];
        if (selectedItem) {
            selectedList = selectedItem.map((item: any) => {
                return item ? item.progressStatementId : null;
            });
        }
        return selectedList;
    }

    private _getSelectedListItems = () => {
        let SelectedListItems = this._Selection.getSelection() as ListPaneItem[];

        return SelectedListItems;
    };

    private _onColumnClick = (
        ev: React.MouseEvent<HTMLElement>,
        column: IColumn,
    ): void => {
        const newColumns: IColumn[] = this._columns.slice();
        const currColumn: IColumn = newColumns.filter(
            (currCol) => column.key === currCol.key,
        )[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
                let filter = this.state.filter;

                let fieldName = currColumn.fieldName;

                let sortby = currColumn.isSortedDescending ? 'desc' : 'asc';
                filter.sorter.attribute = fieldName ? fieldName : null;
                filter.sorter.order = sortby ? sortby : null;
                this.setState(
                    {
                        filter: filter,
                    },
                    this.handleFilterSortChange,
                );
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
    };
}

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        isChange: state.ps.isChange,
        psDropDowns: state.ps.psDropDowns,
        vpOrgList: state.vp.mtpOrgTaxonomyList,
        mtpOrgBarList: state.vp.mtpOrgBarList,
        mtpOrgTaxonomyList: state.vp.mtpOrgTaxonomyList,
        // isDataLoaded: state.vp.isVPOrgListLoaded,
        isToolsDataLoaded: state.vp.whToolsListLoaded,
        vpWhToolsList: state.vp.whTaxonomyList,
        isDataLoaded: state.vp.isMTPOrgListLoaded,
    };
};

const mapDispatchToProps = {
    readVisualPlanDropDownData,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TaxonomyPane)),
);

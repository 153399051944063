import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';
import { mergeStyles, Stack } from '@fluentui/react';
import DocumentPane from '../../projectMolecule/documentPane/container';
import {
  ListPaneItem,
  PMOL_TYPE,
  pmolFormData,
  PMOLListPaneFilter,
  PMOLShortCutPaneItem,
  ProjectMolecule,
} from '../../../types/projectMolecule';
import { CorporateProductCatalogue, CorporateProductCatalogueListItem } from '../../../types/corporateProductCatalogue';
import {
  clearFormData,
  readByPMOLId,
  readPMOLByID,
  readPmolData,
  readPMOLDropDownData,
  resetIsChange,
  setUid,
} from '../../../reducers/pmolReducer';
import { getRiskDropDownsList } from '../../../reducers/projectBreakdownReducer';
import { addUserProject } from '../../../reducers/cuProjectReducer';
import { getProjectDefinition, getProjectHeaderDetails } from '../../../reducers/projectReducer';

const listStyle = mergeStyles({
  flex: '1 1 auto',
  width: '100%',
  height: 'auto',
  overflowX: 'hidden',
  overflowY: 'auto',
  paddingBottom: '30px',
  marginLeft: '0',
});

export interface State {
  selectedShortCutItem: PMOLShortCutPaneItem;
  pmolFilter: PMOLListPaneFilter;
  projectId: string | null;
  pbsId: string | null;
  formData: ProjectMolecule;
  isClone: boolean;
  submitCpc: boolean;
  redirect: boolean;
  loading: boolean;
  showPanel: boolean;
  width: number;
  activeClass: string;
  bookmarkDivClass: string;
  headerFilter: boolean;
  showDetails: boolean;
  currentActiveSection: string;
  isOpenNickNameModel: boolean;
  allProductCatalogue: [];
  uploadedImages: string | null;
  autoSave: boolean;
  isEdit: boolean;
  selectedCpcId: string | null | undefined;
  cpcListItem: CorporateProductCatalogueListItem[];
  cpcPressureClass: [];
  selectedListPaneItemIds: [] | string[];
  //headerTitle:string|null,
  hiddenRemoveDialog: boolean;
  isDisableNewButton: boolean;
  isDrag: boolean;
  resetLayout: boolean;
  collapseBookmarkPane: boolean;
  reSizer: number;
  pmolType: PMOL_TYPE;
}

interface Props extends WithTranslation {
  pmolId: string | null,
  projectId: string | null,
  match: any;
  resetFormData: () => void;
  readPMOLShortCutPaneData: () => void;
  showProgressBar: boolean;
  //readDropdownData: () => void;
  shortCutPaneFilters: PMOLShortCutPaneItem[];
  filterPMOLListPaneData: (filterItem: PMOLListPaneFilter) => void;
  listPaneItems: ListPaneItem[];
  formData: ProjectMolecule;
  setUid: () => void;
  clearUid: () => void;
  clearFormData: () => void;
  resetIsChange: () => void;
  uid: string | null;
  readByPMOLId: (id: string) => void;
  getExcelData: () => void;
  newProductCatalogue: () => void;
  getOrganizationByName: (name: string) => void;
  getCpcResourceFamily: () => void;
  deleteProduct: (idList: [] | string[]) => void;
  deleteProductCatalogueNickName: (idList: [] | string[]) => void;
  deleteProductCatalogueVendor: (idList: [] | string[]) => void;
  deleteProductCatalogueImage: (idList: [] | string[]) => void;
  deleteProductCatalogue: (idList: [] | string[]) => void;
  saveProductCatalogue: (productCatalogue: CorporateProductCatalogue) => void;
  getProductCatalogueById: (id: string) => void;
  readPMOLDropDownData: () => void;
  savePicturesOfTheInstallation: (files: FileList | null) => void;
  getRiskDropDownsList: () => void;
  cpcListItem: CorporateProductCatalogueListItem[];
  uploadedImages: string | null;
  resourceTypes: [];
  resourceTypeFilter: [];
  resourceFamily: [];
  cpcBasicUnitOfMeasure: [];
  cpcPressureClass: [];
  cpcMaterial: [];
  cpcBrand: [];
  languageCodes: [];
  organizations: [];
  cpcUnitOfSizeMeasure: [];
  excelData: [];
  autoSave: boolean;
  isChange: boolean;
  isEdit: boolean;
  isDataLoaded?: boolean;
  loadMsg: string;
  location: any;
  addUserProject: any;
  getProjectDefinition: any,
  selectedProject: any,
  readPmolData: any,
  readPMOLByID: any;
  getProjectHeaderDetails: any;
  selectedPmol: any;
}

class PmolDocumentPane extends React.Component<Props, State> {
  subscription: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedShortCutItem: { key: null, id: null, name: this.props.t('all'), type: 'all', value: null },
      pmolFilter: new PMOLListPaneFilter(),
      projectId: null,
      pbsId: null,

      hiddenRemoveDialog: true,
      isClone: false,
      submitCpc: false,
      selectedListPaneItemIds: [],
      cpcListItem: [],
      selectedCpcId: null,
      isOpenNickNameModel: false,
      isEdit: false,
      autoSave: false,
      redirect: false,
      loading: false,
      width: window.innerWidth,
      showPanel: false,
      headerFilter: false,
      activeClass: 'grid-righter column-filter-activated',
      bookmarkDivClass: 'bookmark-panel parent',
      currentActiveSection: '1',
      showDetails: false,
      allProductCatalogue: [],
      uploadedImages: null,
      formData: pmolFormData,
      cpcPressureClass: [],
      isDisableNewButton: false,
      isDrag: false,
      resetLayout: false,
      collapseBookmarkPane: false,
      reSizer: window.innerWidth,
      pmolType: PMOL_TYPE.REGULAR,
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.selectedPmol !== prevProps.selectedPmol) {
      if (this.props.selectedPmol && this.props.selectedPmol?.projectMoleculeId) {
        this.props.readPMOLByID(this.props.selectedPmol?.projectMoleculeId, this.props.selectedPmol?.projectSequenceCode, this.props.selectedPmol?.contractingUinit);
      }
    }
    if (this.props.projectId && this.props.projectId !== prevProps.projectId) {
      this.props.getProjectHeaderDetails(this.props.projectId);
      this.setState({
        projectId: this.props.projectId,
      });
    }
    if (this.props.formData !== prevProps.formData) {
      this.setState({
        formData: this.props.formData,
      });
    }
    if (this.props.isChange !== prevProps.isChange) {
      if (this.props.isChange) {
        messageService.sendMessage({ reloadDayPlan: true });
      }
    }
  }


  componentDidMount() {
    this.props.readPmolData();
    this.props.readPMOLDropDownData();
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.saveMYDPData) {
          messageService.sendMessage({ savePmolFormData: true });
        }
      }
    });
    if (this.props.selectedPmol && this.props.selectedPmol?.projectMoleculeId) {
      this.props.readPMOLByID(this.props.selectedPmol?.projectMoleculeId, this.props.selectedPmol?.projectSequenceCode, this.props.selectedPmol?.contractingUinit);
    }
    if (this.props.formData) {
      this.setState({
        formData: this.props.formData,
      });
    }
  }

  componentWillUnmount() {
    this.props.clearFormData();
    this.subscription.unsubscribe();
  }

  private handleScroll = (e: any): void => {
    let element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
    }

    let h2Headings = document.getElementsByClassName('proj-detail-block');
    let item = h2Headings[0];
    let tt = 0;

    for (let i = 0; i < h2Headings.length; i++) {
      if (h2Headings[i].getBoundingClientRect().top <= 300) {
        if (tt <= h2Headings[i].getBoundingClientRect().top) {
          tt = h2Headings[i].getBoundingClientRect().top;
          item = h2Headings[i];

          var c = item.id;
          this.setState({ currentActiveSection: c });
        }
      }
    }
  };

  savePicturesOfTheInstallation = (files: FileList | null) => {
    if (files) {
      this.props.savePicturesOfTheInstallation(files);
    }
  };

  handelGetOrganizationByName = (name: string) => {
    this.props.getOrganizationByName(name);
  };

  setSelectedPmolProjectHeader = (pmol: any) => {
    return {
      title: pmol?.projectTitle,
      sequenceCode: pmol?.projectSequenceCode,
      pmName: null,
      mobileNumber: null,
      company: null,
      image: null,
      roleName: null,
      id: null,
      projectScopeStatusId: null,
      projectFinanceStatusId: null,
    }
  };

  render() {
    return (
      <div style={{ width: '100%' }}>
        {/* {this.props.projectId ?  */}
          <Stack
            className={listStyle}
            styles={{
              root: {
                width: '100%',
                height: 'auto',
              },
            }}
            verticalFill
            gap={50}
            horizontalAlign="start"
            verticalAlign="start"
            onScroll={this.handleScroll}
          >
            <DocumentPane
              projectId={this.state.projectId}
              submitCpc={this.state.submitCpc}
              isEdit={this.state.isEdit}
              selectedCpcId={this.state.selectedCpcId}
              formData={this.state.formData}
              savePicturesOfTheInstallation={(files: FileList | null) =>
                this.savePicturesOfTheInstallation(files)
              }
              saveProductCatalogue={(
                productCatalogue: CorporateProductCatalogue,
              ) => {
                this.setState({
                  submitCpc: false,
                });
                this.props.saveProductCatalogue(productCatalogue);
              }}
              deleteProductCatalogueNickName={(deleteList: [] | string[]) => {
                this.props.deleteProductCatalogueNickName(deleteList);
              }}
              deleteProductCatalogueVendor={(deleteList: [] | string[]) => {
                this.props.deleteProductCatalogueVendor(deleteList);
              }}
              deleteProductCatalogueImage={(deleteList: [] | string[]) => {
                this.props.deleteProductCatalogueImage(deleteList);
              }}
              autoSave={this.props.autoSave}
              uploadedImages={this.props.uploadedImages}
              resourceTypes={this.props.resourceTypes}
              resourceFamily={this.props.resourceFamily}
              languageCodes={this.props.languageCodes}
              cpcBasicUnitOfMeasure={this.props.cpcBasicUnitOfMeasure}
              cpcPressureClass={this.props.cpcPressureClass}
              cpcMaterial={this.props.cpcMaterial}
              cpcBrand={this.props.cpcBrand}
              organizations={this.props.organizations}
              cpcUnitOfSizeMeasure={this.props.cpcUnitOfSizeMeasure}
              handelGetOrganizationByName={(name: string) =>
                this.handelGetOrganizationByName(name)
              }
              selectedPmolProjectHeader={this.setSelectedPmolProjectHeader(this.props.selectedPmol)}
            />
          </Stack>
        {/* // : null} */}
      </div>
    );
  }

}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    shortCutPaneFilters: state.pmol.pmolShortCutdata,
    listPaneItems: state.pmol.listPaneItems,
    isDataLoaded: state.pmol.isPMOLListLoaded,
    loadMsg: state.pmol.loadMsg,
    uid: state.pmol.uid,
    formData: state.pmol.formData,
    showProgressBar: state.pmol.showProgressBar,
    isEdit: state.pmol.isEdit,
    isChange: state.pmol.newPMOLCreated,

    cpcListItem: state.productCatalogue.cpcListItem,
    uploadedImages: state.productCatalogue.uploadedImages,
    resourceTypes: state.productCatalogue.resourceTypes,
    resourceTypeFilter: state.productCatalogue.resourceTypeFilter,
    resourceFamily: state.productCatalogue.resourceFamily,
    cpcBasicUnitOfMeasure: state.productCatalogue.cpcBasicUnitOfMeasure,
    cpcPressureClass: state.productCatalogue.cpcPressureClass,
    cpcMaterial: state.productCatalogue.cpcMaterial,
    cpcBrand: state.productCatalogue.cpcBrand,
    languageCodes: state.productCatalogue.languageCodes,
    autoSave: state.productCatalogue.autoSave,
    organizations: state.productCatalogue.organizations,
    cpcUnitOfSizeMeasure: state.productCatalogue.cpcUnitOfSizeMeasure,
    excelData: state.productCatalogue.excelData,

    selectedProject: state.project.selectedProject,

    selectedPmol: state.mydPlan.selectedMyDpPmol,
  };
};

const mapDispatchToProps = {
  resetIsChange,
  readPMOLDropDownData,
  readByPMOLId,
  setUid,
  clearFormData,
  getRiskDropDownsList,
  addUserProject,
  getProjectDefinition,
  readPmolData,
  readPMOLByID,
  getProjectHeaderDetails,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PmolDocumentPane)),
);

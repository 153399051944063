export const LOAD_RISK_REGISTER_SHORTCUTPANE = 'uprince/LOAD_RISK_REGISTER_SHORTCUTPANE';
export const LOAD_RISK_REGISTER_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_RISK_REGISTER_SHORTCUTPANE_SUCCESS';
export const LOAD_RISK_REGISTER_SHORTCUTPANE_FAIL = 'uprince/LOAD_RISK_REGISTER_SHORTCUTPANE_FAIL';

export const LOAD_RISK_REGISTER_LSITPANE = 'uprince/LOAD_RISK_REGISTER_LSITPANE';
export const LOAD_RISK_REGISTER_LSITPANE_SUCCESS = 'uprince/LOAD_RISK_REGISTER_LSITPANE_SUCCESS';
export const LOAD_RISK_REGISTER_LSITPANE_FAIL = 'uprince/LOAD_RISK_REGISTER_LSITPANE_FAIL';

export const LOAD_RISK_REGISTER_DROPDOWN = 'uprince/LOAD_RISK_REGISTER_DROPDOWN';
export const LOAD_RISK_REGISTER_DROPDOWN_SUCCESS = 'uprince/LOAD_RISK_REGISTER_DROPDOWN_SUCCESS';
export const LOAD_RISK_REGISTER_DROPDOWN_FAIL = 'uprince/LOAD_RISK_REGISTER_DROPDOWN_FAIL';

export const SAVE_RISK_REGISTER = 'uprince/SAVE_RISK_REGISTER';
export const SAVE_RISK_REGISTER_SUCCESS = 'uprince/SAVE_RISK_REGISTER_SUCCESS';
export const SAVE_RISK_REGISTER_FAIL = 'uprince/SAVE_RISK_REGISTER_FAIL';

export const LOAD_RISK_REGISTER_BY_ID = 'uprince/LOAD_RISK_REGISTER_BY_ID';
export const LOAD_RISK_REGISTER_BY_ID_SUCCESS = 'uprince/LOAD_RISK_REGISTER_BY_ID_SUCCESS';
export const LOAD_RISK_REGISTER_BY_ID_FAIL = 'uprince/LOAD_RISK_REGISTER_BY_ID_FAIL';

export const LOAD_BOR_RESOURCES_BY_ID = 'uprince/LOAD_BOR_RESOURCES_BY_ID';
export const LOAD_BOR_RESOURCES_BY_ID_SUCCESS = 'uprince/LOAD_BOR_RESOURCES_BY_ID_SUCCESS';
export const LOAD_BOR_RESOURCES_BY_ID_FAIL = 'uprince/LOAD_BOR_RESOURCES_BY_ID_FAIL';

export const SET_RISK_REGISTER_UID = 'uprince/SET_RISK_REGISTER_UID';
export const CLEAR_RISK_REGISTER_UID = 'uprince/CLEAR_RISK_REGISTER_UID';
export const CLEAR_RISK_REGISTER_FORM_DATA = 'uprince/CLEAR_RISK_REGISTER_FORM_DATA';
export const RESET_RISK_REGISTER_IS_CHANGE = 'uprice/RESET_RISK_REGISTER_IS_CHANGE';


export const CLEAR_RISK_REGISTERUID = 'uprice/CLEAR_RISK_REGISTERUID';

import moment from 'moment';
import { IColumn } from '@fluentui/react';
import React from 'react';
import MaskedInput from 'react-text-mask';

export const CorporateScheduleColumns = (
  item: any,
  index?: number,
  column?: IColumn,
  isEditable?: boolean,
  onChangeStartTime?: (index: number, value: any) => void,
  onChangeEndTime?: (index: number, value: any) => void,
  disabled?: boolean,
) => {
  let maskFormat: { [key: string]: RegExp } = { H: /[0-9]/, m: /[0-5]/, M: /[0-9]/ };
  if (column && column.fieldName && column.fieldName === 'day') {
    return (
      <div style={{ paddingTop: 10 }}>
        <span>{item[column.fieldName]}</span>
      </div>
    );
  } else if (column && column.fieldName && column.fieldName === 'startTime') {
    return (
      <div>
        <MaskedInput
          style={{ width: '50px' }}
          mask={[/^([0-2])/, /([0-9])/, ':', /[0-5]/, /[0-9]/]}
          placeholder={'hh:mm'}
          onChange={(e: any, newValue?: string) => {
            const timeValidation: boolean = moment(e.target.value, 'HH:mm', true).isValid();
            onChangeStartTime && e.target.value && timeValidation
              ? onChangeStartTime(index!!, e.target.value)
              : null;
          }}
          value={item[column.fieldName]}
          keepCharPositions={true}
        />
        {/* <MaskedTextField
          value={item[column.fieldName]}
          onChange={(e: any, newValue?: string) => {
            const timeValidation: boolean = moment(newValue, 'HH:mm', true).isValid()
            onChangeStartTime && newValue && timeValidation
              ? onChangeStartTime(index!!, newValue)
              : null;
          }}
          mask="HH:mM"
          maskFormat={maskFormat}
          maskChar="_"
          /> */}
      </div>
    );
  } else if (column && column.fieldName && column.fieldName === 'endTime') {
    return (
      <div>
        <MaskedInput
          style={{ width: '50px' }}
          mask={[/\d/, /\d/, ':', /\d/, /\d/]}
          placeholder={'hh:mm'}
          onChange={(e: any, newValue?: string) => {
            const timeValidation: boolean = moment(e.target.value, 'HH:mm', true).isValid();
            onChangeEndTime && e.target.value && timeValidation
              ? onChangeEndTime(index!!, e.target.value)
              : null;
          }}
          value={item[column.fieldName]}
          keepCharPositions={true}
        />
        {/* <MaskedTextField
          value={item[column.fieldName]}
          onChange={(e: any, newValue?: string) => {
            const timeValidation: boolean = moment(newValue, 'HH:mm', true).isValid();
            onChangeEndTime && newValue && timeValidation
              ? onChangeEndTime(index!!, newValue)
              : null;
            }}
          mask="HH:mM"
          maskFormat={maskFormat}
          maskChar="_"
        /> */}
      </div>
    );
  }
};

import React from 'react';
import { mergeStyleSets, registerIcons } from '@fluentui/react';
import i18n from '../../../../i18n';
import { SvgIcons } from '../../../shared/svgIcons/svgIcons';
import { useSelector } from 'react-redux';
import { formatwithoutTime } from '../../../shared/util';
import { uPrinceTheme } from '../../../../theme';

registerIcons(SvgIcons);

const classNames = mergeStyleSets({
  body: {
    hight: '100%',
    display: 'block',
    marginLeft: '20%',
    marginRight: '20%',
    backgroundColor: 'white',
    padding: '40px',
    minHeight: 'calc(100vh - 120px)',
  },
  spacing: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  bmLogo: {
    display: 'flex',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    border: '0',
    borderSpacing: '0',
  },
  content: {
    padding: '0 62px 0px 160px',
  },
  boldFont: {
    margin: '0 0 25px 0',
    fontSize: '16px',
    lineHeight: '24px',
    // fontFamily: 'Arial,sans-serif',
    color: uPrinceTheme.palette.themePrimary,
    fontWeight: 'bold',
  },
  detailFont: {
    margin: '0 0 25px 0',
    fontSize: '16px',
    lineHeight: '24px',
    // fontFamily: 'Arial,sans-serif'
  },
  h3: {
    margin: '0 0 25px 0',
    fontSize: '16px',
    lineHeight: '24px',
    // fontFamily: 'Arial,sans-serif'
  },
  icon: {
    padding: '0',
    verticalAlign: 'top',
    color: uPrinceTheme.palette.themePrimary,
  },
  tdIcon: {
    width: '40px',
    padding: '0,0,1px,0',
    verticalAlign: 'top',
    color: uPrinceTheme.palette.neutralPrimary,
  },
});

const MailTemplate = (props: {
  formData: any
}) => {
  // const dispatch = useDispatch();

  const {
    formData,
  }: any = useSelector(
    (state: any) => state.lotInvitation,
  );

  return (
    <div>
      <table role="presentation" className={classNames.table}>
        <tr>
          <td className={classNames.content}>
            <table role="presentation" className={classNames.table}>
              <tr>
                {/* project code */}
                <td className={classNames.tdIcon}>
                  <i className={`ms-Icon ms-Icon--Tag ${classNames.icon}`} aria-hidden="true"></i>
                </td>
                <td style={{
                  width: '150px',
                  padding: '0',
                  verticalAlign: 'top',
                  color: uPrinceTheme.palette.neutralPrimary,
                }}>
                  <p className={`${classNames.boldFont} mail-template-fonts`}>{i18n.t('project')}:</p>
                </td>
                <td style={{
                  width: '260px',
                  padding: '0',
                  verticalAlign: 'top',
                  color: uPrinceTheme.palette.neutralPrimary,
                }}>
                  <p className={`${classNames.detailFont} mail-template-fonts`}>{props.formData?.projectName}</p>
                </td>
                <td style={{ width: '20px', padding: '0', fontSize: '0', lineHeight: '0' }}>&nbsp;</td>
                {/* lot title */}
                <td className={classNames.tdIcon}>
                  <i className={`ms-Icon ms-Icon--PageHeaderEdit ${classNames.icon}`} aria-hidden="true"></i>
                </td>
                <td style={{
                  width: '260px',
                  padding: '0',
                  verticalAlign: 'top',
                  color: uPrinceTheme.palette.neutralPrimary,
                }}>
                  <p className={`${classNames.boldFont} mail-template-fonts`}>{i18n.t('lot')}:</p>
                </td>
                <td style={{
                  width: '260px',
                  padding: '0',
                  verticalAlign: 'top',
                  color: uPrinceTheme.palette.neutralPrimary,
                }}>
                  <p className={`${classNames.detailFont} mail-template-fonts`}>{props.formData?.lotTitle}</p>
                </td>
              </tr>
              <tr>
                {/* start date */}
                <td className={classNames.tdIcon}>
                  <i className={`ms-Icon ms-Icon--CalendarWeek ${classNames.icon}`} aria-hidden="true"></i>
                </td>
                <td style={{
                  width: '150px',
                  padding: '0',
                  verticalAlign: 'top',
                  color: uPrinceTheme.palette.neutralPrimary,
                }}>
                  <p className={`${classNames.boldFont} mail-template-fonts`}>{i18n.t('startDate')}:</p>
                </td>
                <td style={{
                  width: '260px',
                  padding: '0',
                  verticalAlign: 'top',
                  color: uPrinceTheme.palette.neutralPrimary,
                }}>
                  <p
                    className={`${classNames.detailFont} mail-template-fonts`}>{formatwithoutTime(props.formData?.lotStartDate)}</p>
                </td>
                <td style={{ width: '20px', padding: '0', fontSize: '0', lineHeight: '0' }}>&nbsp;</td>
                {/* end date */}
                <td className={classNames.tdIcon}>
                  <i className={`ms-Icon ms-Icon--CalendarWeek ${classNames.icon}`} aria-hidden="true"></i>
                </td>
                <td style={{
                  width: '260px',
                  padding: '0',
                  verticalAlign: 'top',
                  color: uPrinceTheme.palette.neutralPrimary,
                }}>
                  <p className={`${classNames.boldFont} mail-template-fonts`}>{i18n.t('endDate')}:</p>
                </td>
                <td style={{
                  width: '260px',
                  padding: '0',
                  verticalAlign: 'top',
                  color: uPrinceTheme.palette.neutralPrimary,
                }}>
                  <p
                    className={`${classNames.detailFont} mail-template-fonts`}>{formatwithoutTime(props.formData?.lotEndDate)}</p>
                </td>
              </tr>
              <tr>
                {/* contracting unit who launched the project */}
                <td className={classNames.tdIcon}>
                  <i className={`ms-Icon ms-Icon--CRMServices ${classNames.icon}`} aria-hidden="true"></i>
                </td>
                <td style={{
                  width: '150px',
                  padding: '0',
                  verticalAlign: 'top',
                  color: uPrinceTheme.palette.neutralPrimary,
                }}>
                  <p className={`${classNames.boldFont} mail-template-fonts`}>{i18n.t('contractingUnit')}:</p>
                </td>
                <td style={{
                  width: '260px',
                  padding: '0',
                  verticalAlign: 'top',
                  color: uPrinceTheme.palette.neutralPrimary,
                }}>
                  <p className={`${classNames.detailFont} mail-template-fonts`}>{props.formData?.contractingUnit}</p>
                </td>
                <td style={{ width: '20px', padding: '0', fontSize: '0', lineHeight: '0' }}>&nbsp;</td>
                {/* sector */}
                <td className={classNames.tdIcon}>
                  <i className={`ms-Icon ms-Icon--Section ${classNames.icon}`} aria-hidden="true"></i>
                </td>
                <td style={{
                  width: '260px',
                  padding: '0',
                  verticalAlign: 'top',
                  color: uPrinceTheme.palette.neutralPrimary,
                }}>
                  <p className={`${classNames.boldFont} mail-template-fonts`}>{i18n.t('sector')}:</p>
                </td>
                <td style={{
                  width: '260px',
                  padding: '0',
                  verticalAlign: 'top',
                  color: uPrinceTheme.palette.neutralPrimary,
                }}>
                  <p className={`${classNames.detailFont} mail-template-fonts`}>{props.formData?.sector}</p>
                </td>
              </tr>
              <tr>
                {/* architect */}
                <td className={classNames.tdIcon}>
                  <i className={`ms-Icon ms-Icon--FieldNotChanged ${classNames.icon}`} aria-hidden="true"></i>
                </td>
                <td style={{
                  width: '150px',
                  padding: '0',
                  verticalAlign: 'top',
                  color: uPrinceTheme.palette.neutralPrimary,
                }}>
                  <p className={`${classNames.boldFont} mail-template-fonts`}>{i18n.t('architect')}:</p>
                </td>
                <td style={{
                  width: '260px',
                  padding: '0',
                  verticalAlign: 'top',
                  color: uPrinceTheme.palette.neutralPrimary,
                }}>
                  <p className={`${classNames.detailFont} mail-template-fonts`}>{props.formData?.architect}</p>
                </td>
                <td style={{ width: '20px', padding: '0', fontSize: '0', lineHeight: '0' }}>&nbsp;</td>
                {/* customer */}
                <td className={classNames.tdIcon}>
                  <i className={`ms-Icon ms-Icon--ExternalBuild ${classNames.icon}`} aria-hidden="true"></i>
                </td>
                <td style={{
                  width: '260px',
                  padding: '0',
                  verticalAlign: 'top',
                  color: uPrinceTheme.palette.neutralPrimary,
                }}>
                  <p className={`${classNames.boldFont} mail-template-fonts`}>{i18n.t('customer')}:</p>
                </td>
                <td style={{
                  width: '260px',
                  padding: '0',
                  verticalAlign: 'top',
                  color: uPrinceTheme.palette.neutralPrimary,
                }}>
                  <p className={`${classNames.detailFont} mail-template-fonts`}>{props.formData?.customer}</p>
                </td>
              </tr>

              {/* project description */}
              <tr>
                <td colSpan={7}>
                  <p className={`${classNames.boldFont} mail-template-fonts`}>
                    <i className={`ms-Icon ms-Icon--ExternalBuild ${classNames.icon}`} aria-hidden="true" style={{
                      padding: '0 10px 0 0',
                      verticalAlign: 'top',
                      color: uPrinceTheme.palette.themePrimary,
                    }}></i>
                    {i18n.t('projectDescription')}:
                  </p>
                  <p className={`${classNames.detailFont} mail-template-fonts`}>{props.formData?.description}</p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default MailTemplate;

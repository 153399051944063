export const LOAD_ORANISATION_CBC_SHORTCUTPANE = 'uprince/LOAD_ORANISATION_CBC_SHORTCUTPANE';
export const LOAD_ORANISATION_CBC_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_ORANISATION_CBC_SHORTCUTPANE_SUCCESS';
export const LOAD_ORANISATION_CBC_SHORTCUTPANE_FAIL = 'uprince/LOAD_ORANISATION_CBC_SHORTCUTPANE_FAIL';

export const LOAD_ORANISATION_CBC_LSITPANE = 'uprince/LOAD_ORANISATION_CBC_LSITPANE';
export const LOAD_ORANISATION_CBC_LSITPANE_SUCCESS = 'uprince/LOAD_ORANISATION_CBC_LSITPANE_SUCCESS';
export const LOAD_ORANISATION_CBC_LSITPANE_FAIL = 'uprince/LOAD_ORANISATION_CBC_LSITPANE_FAIL';

export const LOAD_ORANISATION_CBC_DROPDOWN = 'uprince/LOAD_ORANISATION_CBC_DROPDOWN';
export const LOAD_ORANISATION_CBC_DROPDOWN_SUCCESS = 'uprince/LOAD_ORANISATION_CBC_DROPDOWN_SUCCESS';
export const LOAD_ORANISATION_CBC_DROPDOWN_FAIL = 'uprince/LOAD_ORANISATION_CBC_DROPDOWN_FAIL';

export const SAVE_ORANISATION_CBC = 'uprince/SAVE_ORANISATION_CBC';
export const SAVE_ORANISATION_CBC_SUCCESS = 'uprince/SAVE_ORANISATION_CBC_SUCCESS';
export const SAVE_ORANISATION_CBC_FAIL = 'uprince/SAVE_ORANISATION_CBC_FAIL';

export const LOAD_ORANISATION_CBC_BY_ID = 'uprince/LOAD_ORANISATION_CBC_BY_ID';
export const LOAD_ORANISATION_CBC_BY_ID_SUCCESS = 'uprince/LOAD_ORANISATION_CBC_BY_ID_SUCCESS';
export const LOAD_ORANISATION_CBC_BY_ID_FAIL = 'uprince/LOAD_ORANISATION_CBC_BY_ID_FAIL';

export const LOAD_BOR_RESOURCES_BY_ID = 'uprince/LOAD_BOR_RESOURCES_BY_ID';
export const LOAD_BOR_RESOURCES_BY_ID_SUCCESS = 'uprince/LOAD_BOR_RESOURCES_BY_ID_SUCCESS';
export const LOAD_BOR_RESOURCES_BY_ID_FAIL = 'uprince/LOAD_BOR_RESOURCES_BY_ID_FAIL';

export const SET_ORANISATION_CBC_UID = 'uprince/SET_ORANISATION_CBC_UID';
export const CLEAR_ORANISATION_CBC_UID = 'uprince/CLEAR_ORANISATION_CBC_UID';
export const CLEAR_ORANISATION_CBC_FORM_DATA = 'uprince/CLEAR_ORANISATION_CBC_FORM_DATA';
export const RESET_ORANISATION_CBC_IS_CHANGE = 'uprice/RESET_ORANISATION_CBC_IS_CHANGE';


export const CLEAR_ORANISATION_CBCUID = 'uprice/CLEAR_ORANISATION_CBCUID';

export const LOAD_COMPETENCY_SHORTCUTPANE = 'uprince/LOAD_COMPETENCY_SHORTCUTPANE';
export const LOAD_COMPETENCY_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_COMPETENCY_SHORTCUTPANE_SUCCESS';
export const LOAD_COMPETENCY_SHORTCUTPANE_FAIL = 'uprince/LOAD_COMPETENCY_SHORTCUTPANE_FAIL';

export const LOAD_COMPETENCY_LSITPANE = 'uprince/LOAD_COMPETENCY_LSITPANE';
export const LOAD_COMPETENCY_LSITPANE_SUCCESS = 'uprince/LOAD_COMPETENCY_LSITPANE_SUCCESS';
export const LOAD_COMPETENCY_LSITPANE_FAIL = 'uprince/LOAD_COMPETENCY_LSITPANE_FAIL';

export const LOAD_COMPETENCY_DROPDOWN = 'uprince/LOAD_COMPETENCY_DROPDOWN';
export const LOAD_COMPETENCY_DROPDOWN_SUCCESS = 'uprince/LOAD_COMPETENCY_DROPDOWN_SUCCESS';
export const LOAD_COMPETENCY_DROPDOWN_FAIL = 'uprince/LOAD_COMPETENCY_DROPDOWN_FAIL';

export const SAVE_COMPETENCY = 'uprince/SAVE_COMPETENCY';
export const SAVE_COMPETENCY_SUCCESS = 'uprince/SAVE_COMPETENCY_SUCCESS';
export const SAVE_COMPETENCY_FAIL = 'uprince/SAVE_COMPETENCY_FAIL';

export const LOAD_COMPETENCY_BY_ID = 'uprince/LOAD_COMPETENCY_BY_ID';
export const LOAD_COMPETENCY_BY_ID_SUCCESS = 'uprince/LOAD_COMPETENCY_BY_ID_SUCCESS';
export const LOAD_COMPETENCY_BY_ID_FAIL = 'uprince/LOAD_COMPETENCY_BY_ID_FAIL';

export const LOAD_BOR_RESOURCES_BY_ID = 'uprince/LOAD_BOR_RESOURCES_BY_ID';
export const LOAD_BOR_RESOURCES_BY_ID_SUCCESS = 'uprince/LOAD_BOR_RESOURCES_BY_ID_SUCCESS';
export const LOAD_BOR_RESOURCES_BY_ID_FAIL = 'uprince/LOAD_BOR_RESOURCES_BY_ID_FAIL';

export const LOAD_COMPETENCY_TAXANOMY_LIST = 'uprince/LOAD_COMPETENCY_TAXANOMY_LIST';
export const LOAD_COMPETENCY_TAXANOMY_LIST_SUCCESS = 'uprince/LOAD_COMPETENCY_TAXANOMY_LIST_SUCCESS';
export const LOAD_COMPETENCY_TAXANOMY_LIST_FAIL = 'uprince/LOAD_COMPETENCY_TAXANOMY_LIST_FAIL';

export const CREATE_COMPETANCY_TAXONOMY = 'uprince/CREATE_COMPETANCY_TAXONOMY';
export const CREATE_COMPETANCY_TAXONOMY_SUCCESS = 'uprince/CREATE_COMPETANCY_TAXONOMY_SUCCESS';
export const CREATE_COMPETANCY_TAXONOMY_FAIL = 'uprince/CREATE_COMPETANCY_TAXONOMY_FAIL';

export const SET_COMPETENCY_UID = 'uprince/SET_COMPETENCY_UID';
export const CLEAR_COMPETENCY_UID = 'uprince/CLEAR_COMPETENCY_UID';
export const CLEAR_COMPETENCY_FORM_DATA = 'uprince/CLEAR_COMPETENCY_FORM_DATA';
export const RESET_COMPETENCY_IS_CHANGE = 'uprice/RESET_COMPETENCY_IS_CHANGE';


export const CLEAR_COMPETENCYUID = 'uprice/CLEAR_COMPETENCYUID';

import { BorListPaneItem, FilterItem } from '../../../../types/billOfResources';
import { withTranslation, WithTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { IColumn, Selection } from '@fluentui/react/lib/DetailsList';
import ListPaneComponent from './component';
import { getBorPoListPaneData, getBorReturnPoListPaneData } from '../../../../reducers/purchaseOrderReducer';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { RETURN_REQUEST } from '../../../../types/purchaseOrder';


export interface State {
  filter: FilterItem;
  announcedMessage: string;
  selectedListItemIds: [] | string[];
  selectedListItemSequenceCode: [] | string[];
  selectedListItems: BorListPaneItem[];
}

export interface Props extends WithTranslation {
  isDataLoaded: boolean;
  isOpen: boolean;
  isChange: boolean;
  openModal: () => void;
  getBorPoListPaneData: (filterItem: FilterItem) => any;
  getBorReturnPoListPaneData: (filterItem: FilterItem) => any;
  borListPaneItem: BorListPaneItem[];
  loadMsgBor: string;
  setSelectedBORIds: (ids: string[]) => void
  selectedBORIds: [];
  formData: any,
}

class BorModal extends Component<Props, State> {
  private _borSelection: Selection;
  private _borColumns: IColumn[];
  subscription: any;

  constructor(props: Props) {
    super(props);
    const { t } = this.props;
    this._borSelection = new Selection({
      onSelectionChanged: () => {
        this.setState(
          {
            selectedListItemIds: this._getSelectedIds(),
            selectedListItemSequenceCode: this._getselectedSequenceCode(),
            selectedListItems: this._getSelectedListItems(),
          },
          this.handleSelectedItem,
        );
      },

      //canSelectItem:this.canSelectItem.bind(this)
    });

    this.state = {
      selectedListItems: [],
      selectedListItemIds: [],
      selectedListItemSequenceCode: [],
      announcedMessage: '',
      filter: {
        borTitle: null,
        product: null,
        locationParent: null,
        utilityParent: null,
        sorter: {
          attribute: null,
          order: null,
        },
      },
    };

    this._borColumns = [
      {
        key: 'column1',
        name: t('borTitle'),
        fieldName: 'borTitle',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        onColumnClick: this._borOnColumnClick,
      },
      {
        key: 'column2',
        name: t('product'),
        fieldName: 'product',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._borOnColumnClick,
      },
      {
        key: 'column3',
        name: t('utilityTaxonomy'),
        fieldName: 'utilityParent',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._borOnColumnClick,
      },
      {
        key: 'column4',
        name: t('location'),
        fieldName: 'locationParent',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._borOnColumnClick,
      },
    ];

  }

  componentDidMount() {
    // this.getListPaneData();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.isChange != this.props.isChange) {
      if (this.props.isChange) {
        this.getListPaneData();
      }
    }
    if (prevProps.isOpen != this.props.isOpen) {
      if (this.props.isOpen) {
        this.getListPaneData();
      }
    }
  }

  private canSelectItem(item: any): boolean {
    return item.id !== '503363cb-c5e3-43c9-af04-f6258a17a543';
  }

  render() {
    return (
      <div>
        <ListPaneComponent
          isOpen={this.props.isOpen}
          openModal={this.props.openModal}
          resetSelection={this.resetSelection}
          onConfirmSelection={this.onConfirmSelection}
          isDataLoaded={this.props.isDataLoaded}
          selection={this._borSelection}
          columns={this._borColumns}
          borListPaneItem={this.props.borListPaneItem}
          filter={this.state.filter}
          loadMsg={this.props.loadMsgBor}
          handleFilterChange={(filter: FilterItem) => {
            this.handleFilterChange(filter);
          }}
        />
      </div>
    );
  }


  private getListPaneData = () => {
    if (this.props.formData?.header?.poRequestTypeId === RETURN_REQUEST) {
      this.props.getBorReturnPoListPaneData(this.state.filter).then(() => {
        if (this.props.selectedBORIds && this.props.selectedBORIds.length > 0) {
          this.props.selectedBORIds.forEach((item) => {
            this._borSelection.setKeySelected(item, true, false);
          });
        }
      });
    } else {
      this.props.getBorPoListPaneData(this.state.filter).then(() => {
        if (this.props.selectedBORIds && this.props.selectedBORIds.length > 0) {
          this.props.selectedBORIds.forEach((item) => {
            this._borSelection.setKeySelected(item, true, false);
          });
        }
      });
    }

  };


  private handleSelectedItem = () => {

  };


  private resetSelection = () => {
    this.setState({
      filter: {
        borTitle: null,
        product: null,
        locationParent: null,
        utilityParent: null,
        sorter: {
          attribute: null,
          order: null,
        },
      },
    }, this.getListPaneData);
    this._borSelection.setAllSelected(false);
    this.props.setSelectedBORIds([]);
  };

  private onConfirmSelection = () => {
    // console.log('_getSelectedIds', this._getSelectedIds());
    // console.log('_getselectedSequenceCode', this._getselectedSequenceCode());
    this.props.setSelectedBORIds(this._getSelectedIds());
    this.props.openModal();
  };

  private handleListPaneItemClick = (id: string) => {

  };


  private handleFilterSortChange = () => {
    this.getListPaneData();
  };

  handleFilterChange = (filter: FilterItem) => {
    this.setState({
      filter,
    }, this.getListPaneData);
  };


  private _getSelectedIds() {
    let deleteList: any = [];
    let SelectedItem = this._borSelection.getSelection() as BorListPaneItem[];
    if (SelectedItem) {
      deleteList = SelectedItem.map((item: any) => {
        return item ? item.id : null;
      });
    }
    return deleteList;
  }

  private _getselectedSequenceCode() {
    let deleteList: any = [];
    let SelectedItem = this._borSelection.getSelection() as BorListPaneItem[];
    if (SelectedItem) {
      deleteList = SelectedItem.map((item: any) => {
        return item ? item.itemId : null;
      });
    }
    return deleteList;
  }

  private _getSelectedListItems = () => {
    let SelectedListItems = this._borSelection.getSelection() as BorListPaneItem[];

    return SelectedListItems;
  };

  private _borOnColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn,
  ): void => {
    const newColumns: IColumn[] = this._borColumns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key,
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        let filter = this.state.filter;

        let fieldName = currColumn.fieldName;

        let sortby = currColumn.isSortedDescending ? 'desc' : 'asc';
        filter.sorter.attribute = fieldName ? fieldName : null;
        filter.sorter.order = sortby ? sortby : null;
        this.setState(
          {
            filter: filter,
          },
          this.handleFilterSortChange,
        );
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
  };

}


const mapStateToProps = (state: any, props: any) => {
  return {
    toleranceStatesFilter: state.billOfResource.toleranceStatesFilter,
    productStatesFilter: state.billOfResource.productStatesFilter,
    itemTypesFilter: state.billOfResource.itemTypesFilter,
    borListPaneItem: state.po.borListPaneItem,
    loadMsgBor: state.po.loadMsgBor,
    isDataLoaded: state.po.isDataLoadedBor,
    isChange: state.po.isChange,
  };
};

const mapDispatchToProps = {
  getBorPoListPaneData,
  getBorReturnPoListPaneData,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BorModal)),
);

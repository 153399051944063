import {mergeStyleSets} from '@fluentui/react';
import React from 'react';

;

const classNames = mergeStyleSets({
  toolbarLabel: {
    textAlign: 'start',
    fontWeight: 500,
    flexGrow: 1,
    padding: '0 4px',
  }
});

const CalenderToolBar = (props: any) => {

  return (
    <div className="rbc-toolbar">
      <span className={classNames.toolbarLabel}>
        {props.label}
      </span>
    </div>
  );
};

export default CalenderToolBar;
import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';
import { getProject } from '../../../shared/util';
import {
  CAPACITY_REQUEST,
  GOODDEERIS_PRODUCT_TYPE_ID,
  POFilter,
  PURCHASE_REQUEST,
  RESOURCE_TYPE_ID,
  RETURN_REQUEST,
  RFQ_REQUEST,
  SUPPLIER_PURCHASE_REQUEST,
} from '../../../types/purchaseOrder';
import { store } from '../../../../index';
import { readByPurchaseOrderId, sendRfqEmail } from '../../../reducers/purchaseOrderReducer';
import { useDispatch, useSelector } from 'react-redux';

const CommandBarLeft = (props: { disable: boolean,filter?:POFilter }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const newClick = (type?: string) => {
    messageService.sendMessage({ isNewPO: true, poRequestType: type });
    if (type === RETURN_REQUEST) {
      messageService.sendMessage({ purchaseOrderType: RESOURCE_TYPE_ID });
    } else if (type === CAPACITY_REQUEST) {
      messageService.sendMessage({ purchaseOrderType: GOODDEERIS_PRODUCT_TYPE_ID });
    }
    // props.createNew(true);
  };


  const { selectedPoList } = useSelector(
    (state: any) => state.po
  );


  const handleSendRfq=()=>{
      let rfqIds:any[]=[];
      new Promise((resolve) => {
        rfqIds=selectedPoList.map((item:any)=>{
          return item?.sequenceId
        });
        //console.log({rfqIds});
        resolve(dispatch(sendRfqEmail(rfqIds)));
    }).then(()=>{
      if(store.getState().po?.formData?.sequenceId){
        dispatch(readByPurchaseOrderId(store.getState().po?.formData?.sequenceId))
      }
    });
  }

  const handleSendButtonDisabled=()=>{
    if(store.getState().po?.selectedPoList && store.getState().po?.selectedPoList.length>0){
       return (store.getState().po?.formData?.requestType?.key !== RFQ_REQUEST)
    }else{
      return  true
    }

  }

  const getItems = () => {
    const items = [];

    const newBtnCu = {
      key: 'new',
      text: t('new'),
      iconProps: { iconName: 'Add' },
      onClick: () => {
        newClick(SUPPLIER_PURCHASE_REQUEST);
      },
      disabled: false,
    };
    const newBtn = {
      key: 'new',
      text: t('new'),
      iconProps: { iconName: 'Add' },
      onClick: () => {
        newClick(PURCHASE_REQUEST);
      },
      disabled: false,
      split: true,
      subMenuProps: {
        items: [
          { key: 'purchaseRequest', text: t('purchaseRequest'), onClick: () => newClick(PURCHASE_REQUEST) },
          { key: 'returnRequest', text: t('returnRequest'), onClick: () => newClick(RETURN_REQUEST) },
          { key: 'capacityRequest', text: t('capacityRequest'), onClick: () => newClick(CAPACITY_REQUEST) },
        ],
      },
    };

    const sendBtn = {
      key: 'send',
      name: t('send'),
      disabled: handleSendButtonDisabled(),
      iconProps: { iconName: 'send'},
      onClick: () => handleSendRfq(),
    };

    // {
    //     key: 'print',
    //     name: t('print'),
    //     disabled: props.disable,
    //     iconProps: { iconName: 'Print' },
    //     onClick: () => {}
    // },
    // {
    //     key: 'send',
    //     name: t('send'),
    //     disabled: props.disable,
    //     iconProps: { iconName: 'Send' },
    //     onClick: () => {}
    // },
    // {
    //     key: 'save',
    //     name: t('save'),
    //     disabled: props.disable,
    //     iconProps: { iconName: 'Save' },
    //     onClick: () => {}
    // }

    if (!getProject()) {
      items.push(newBtnCu);
    } else {
      items.push(newBtn);
    }
    if(props.filter?.requestTypeId===RFQ_REQUEST){
   // if(store.getState().po?.formData?.requestType?.key===RFQ_REQUEST){
        items.push(sendBtn);
    }

    return items;
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );
};

export default CommandBarLeft;

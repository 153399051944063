import React from 'react';
import { Breadcrumb, IBreadcrumbItem, IStackItemStyles, IStackStyles } from '@fluentui/react';

// Styles definition
const stackStyles: IStackStyles = {
  root: {
    height: 22,
    width: '100%',
    //height:"4.9vh",
    //marginRight:-9
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    padding: 5,
  },
};
const BreadcrumbBar = (props: {
  items: any;
  validation?: string | null;
}) => {

  const _onBreadcrumbItemClicked = (ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
                                    item?: IBreadcrumbItem | undefined): void => {
    if (item) {
      //console.log(`Breadcrumb item with key "${item.key}" has been clicked.`);
    }
  };

  const breadcrumbStyles = {
    root: {
      fontSize: 14,
      marginTop: -1,
    },
    item: {
      fontSize: 14,
    },
    list: {
      fontSize: 14,
    },
    listItem: {
      fontSize: 14,
    },
    chevron: {
      fontSize: 14,
    },
    overflow: {
      fontSize: 14,
    },
    overflowButton: {
      fontSize: 14,
    },
    itemLink: {
      fontSize: 14,
    },
  };

  const itemsWithHeading: IBreadcrumbItem[] = props.items;


  return <div style={props.validation ? {border: '1px solid #a4262c'} : {}} className={'breadcrumb-bar'}>
    {itemsWithHeading && <Breadcrumb
      items={itemsWithHeading}
      ariaLabel="With last item rendered as heading"
      overflowAriaLabel="More links"
      styles={breadcrumbStyles}
      onClick={_onBreadcrumbItemClicked}
    />}
  </div>;
};

export default BreadcrumbBar;

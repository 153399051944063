import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';

const CABListPaneCommandBar = (props: { enableRemoveButton: boolean, isCabExist: boolean }) => {
  const newClick = () => {
    messageService.sendMessage({ isNewCAB: true });
  };

  const exportClick = () => {
    messageService.sendMessage({ addressBookExport: true });
  };

  const removeClick = () => {
    messageService.sendMessage({ removeAddress: true });
  };

  const { t } = useTranslation();

  const getItems = () => {
    return [
      {
        key: 'newItem',
        text: t('new'),
        cacheKey: 'myCacheKey',
        iconProps: { iconName: 'Add' },
        onClick: newClick,
      },
      {
        key: 'editItem',
        text: t('export'),
        cacheKey: 'myCacheKey',
        iconProps: { iconName: 'Export' },
        disabled: !props.isCabExist,
        onClick: exportClick,
      },
      {
        key: 'removeItem',
        text: t('remove'),
        cacheKey: 'myCacheKey',
        disabled: !props.enableRemoveButton,
        iconProps: { iconName: 'Delete' },
        onClick: removeClick,
      },
    ];
  };

  return (
    <CommandBar
      items={getItems()}
      overflowButtonProps={{ ariaLabel: 'More commands' }}
      className="common-commandbar"
      ariaLabel={'Use left and right arrow keys to navigate between commands'}
    />
  );
};

export default CABListPaneCommandBar;

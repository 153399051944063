export const SAVE_ORG_PRIMARY_CONTACT = 'uprince/SAVE_ORG_PRIMARY_CONTACT';
export const SAVE_ORG_PRIMARY_CONTACT_SUCCESS =
  'uprince/SAVE_ORG_PRIMARY_CONTACT_SUCCESS';
export const SAVE_ORG_PRIMARY_CONTACT_FAIL =
  'uprince/SAVE_ORG_PRIMARY_CONTACT_FAIL';

export const LOAD_SERVICE = 'uprince/LOAD';
export const LOAD_SCCUESS = 'uprince/LOAD_SUCCESS';
export const LOAD_FAIL = 'uprice/LOAD_FAIL';

export const LOAD_JOB_ROLES = 'uprince/LOAD_JOB_ROLES';
export const LOAD_JOB_ROLES_SUCCESS = 'uprince/LOAD_JOB_ROLES_SUCCESS';
export const LOAD_JOB_ROLES_FAIL = 'uprince/LOAD_JOB_ROLES_FAIL';

export const LOAD_CONTACT_PREF = 'uprince/LOAD_CONTACT_PREF';
export const LOAD_CONTACT_PREF_SUCCESS = 'uprince/LOAD_CONTACT_PREF_SUCCESS';
export const LOAD_CONTACT_PREF_FAIL = 'uprince/LOAD_CONTACT_PREF_FAIL';

import {
  ORANISATION_CBC_SAVE_EP,
  READ_ORANISATION_CBC_BY_ID_EP,
  READ_ORANISATION_CBC_DROPDOWN_EP,
  READ_ORANISATION_CBC_LIST_EP,
  READ_ORANISATION_CBC_SHORTCUT_PANE_EP,
} from '../shared/endpoints';
import {
  CLEAR_ORANISATION_CBC_FORM_DATA,
  CLEAR_ORANISATION_CBCUID,
  LOAD_ORANISATION_CBC_BY_ID,
  LOAD_ORANISATION_CBC_BY_ID_FAIL,
  LOAD_ORANISATION_CBC_BY_ID_SUCCESS,
  LOAD_ORANISATION_CBC_DROPDOWN,
  LOAD_ORANISATION_CBC_DROPDOWN_FAIL,
  LOAD_ORANISATION_CBC_DROPDOWN_SUCCESS,
  LOAD_ORANISATION_CBC_LSITPANE,
  LOAD_ORANISATION_CBC_LSITPANE_FAIL,
  LOAD_ORANISATION_CBC_LSITPANE_SUCCESS,
  LOAD_ORANISATION_CBC_SHORTCUTPANE,
  LOAD_ORANISATION_CBC_SHORTCUTPANE_FAIL,
  LOAD_ORANISATION_CBC_SHORTCUTPANE_SUCCESS,
  RESET_ORANISATION_CBC_IS_CHANGE,
  SAVE_ORANISATION_CBC,
  SAVE_ORANISATION_CBC_FAIL,
  SAVE_ORANISATION_CBC_SUCCESS,
  SET_ORANISATION_CBC_UID,
} from '../actionTypes/organisationCBCActionTypes';
import { v4 as uuidv4 } from 'uuid';
import { POFilter, PurchaseOrder } from '../types/purchaseOrder';


const defaultState: any = {
  cbcList: [],
  isCBCListLoaded: false,
  isNew: false,
  isChange: false,
  isEdit: false,
  showProgressBar: false,
  cbcDropDowns: {},
  formData: {},
  uid: null,
  loadMsg: '',
  cbcShortCutPaneData: [],
  reloadListPane: false,
  borResources: {},
  isLoadingOrganisationCBC: false,
  cbcId: null,
};

export default function organisationCBCReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {
      case LOAD_ORANISATION_CBC_SHORTCUTPANE:
        return { ...state };
      case LOAD_ORANISATION_CBC_SHORTCUTPANE_SUCCESS:
        return {
          ...state,
          cbcShortCutPaneData: action.payload.data.result,
        };
      case LOAD_ORANISATION_CBC_SHORTCUTPANE_FAIL:
        return { ...state, cbcShortCutPaneData: [] };
      case LOAD_ORANISATION_CBC_LSITPANE:
        return { ...state, isCBCListLoaded: false };
      case LOAD_ORANISATION_CBC_LSITPANE_SUCCESS:
        return {
          ...state,
          isCBCListLoaded: true,
          cbcList: action.payload.data.result,
          loadMsg: !action.payload.data.status
            ? action.payload.data.message
            : '',
        };
      case LOAD_ORANISATION_CBC_LSITPANE_FAIL:
        return {
          ...state,
          isCBCListLoaded: true,
          cbcList: [],
          loadMsg: action.error.response.data.message,
        };
      case RESET_ORANISATION_CBC_IS_CHANGE:
        return {
          ...state,
          isChange: false,
        };

      case LOAD_ORANISATION_CBC_DROPDOWN:
        return { ...state, cbcDropDowns: {} };
      case LOAD_ORANISATION_CBC_DROPDOWN_SUCCESS:
        return { ...state, cbcDropDowns: action.payload.data.result };
      case LOAD_ORANISATION_CBC_DROPDOWN_FAIL:
        return {
          ...state,
          cbcDropDowns: {},
        };
      case LOAD_ORANISATION_CBC_BY_ID:
        return { ...state, formData: {}, showProgressBar: true, isLoadingOrganisationCBC: true };
      case LOAD_ORANISATION_CBC_BY_ID_SUCCESS:
        return {
          ...state,
          isEdit: true,
          formData: action.payload.data.result,
          showProgressBar: false,
          isLoadingOrganisationCBC: false,
        };
      case LOAD_ORANISATION_CBC_BY_ID_FAIL:
        return {
          ...state,
          formData: {},
          showProgressBar: false,
          isLoadingOrganisationCBC: false,
        };

      case SAVE_ORANISATION_CBC:
        return { ...state, showProgressBar: true, reloadListPane: false, cbcId: null };
      case SAVE_ORANISATION_CBC_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          reloadListPane: true,
          cbcId: action.payload.data.result,
        };
      case SAVE_ORANISATION_CBC_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
          cbcId: action.payload.data.result,
        };

      case SET_ORANISATION_CBC_UID:
        return {
          ...state,
          isEdit: false,
          uid: uuidv4(),
        };
      case CLEAR_ORANISATION_CBCUID:
        return {
          ...state,
          uid: null,
          isChange: false,
        };
      case CLEAR_ORANISATION_CBC_FORM_DATA:
        return {
          ...state,
          uid: null,
          isNew: true,
          isChange: false,
          formData: {},
        };

      default:
        return state;
    }
  }
}

export const readOrganisationCBCShortCutPaneData = () => {
  return {
    types: [
      LOAD_ORANISATION_CBC_SHORTCUTPANE,
      LOAD_ORANISATION_CBC_SHORTCUTPANE_SUCCESS,
      LOAD_ORANISATION_CBC_SHORTCUTPANE_FAIL,
    ],
    payload: { request: { url: READ_ORANISATION_CBC_SHORTCUT_PANE_EP } },
  };
};

export const readOrganisationCBCDropDownData = () => {
  return {
    types: [
      LOAD_ORANISATION_CBC_DROPDOWN,
      LOAD_ORANISATION_CBC_DROPDOWN_SUCCESS,
      LOAD_ORANISATION_CBC_DROPDOWN_FAIL,
    ],
    payload: { request: { method: 'GET', url: READ_ORANISATION_CBC_DROPDOWN_EP } },
  };
};


export const filterOrganisationCBCListPaneData = (filter: POFilter) => {
  return {
    types: [
      LOAD_ORANISATION_CBC_LSITPANE,
      LOAD_ORANISATION_CBC_LSITPANE_SUCCESS,
      LOAD_ORANISATION_CBC_LSITPANE_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_ORANISATION_CBC_LIST_EP,
        data: filter,
      },
    },
  };
};


export const readByOrganisationCBCId = (id: string) => {
  return {
    types: [
      LOAD_ORANISATION_CBC_BY_ID,
      LOAD_ORANISATION_CBC_BY_ID_SUCCESS,
      LOAD_ORANISATION_CBC_BY_ID_FAIL],
    payload: { request: { url: READ_ORANISATION_CBC_BY_ID_EP + id } },
  };
};

export const resetIsChange = () => {
  return { type: RESET_ORANISATION_CBC_IS_CHANGE };
};

export const saveFormData = (formData: PurchaseOrder) => {
  return {
    types: [
      SAVE_ORANISATION_CBC,
      SAVE_ORANISATION_CBC_SUCCESS,
      SAVE_ORANISATION_CBC_FAIL],
    payload: {
      request: {
        url: ORANISATION_CBC_SAVE_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const setUid = () => {
  return { type: SET_ORANISATION_CBC_UID };
};

export const clearFormData = () => {
  return { type: CLEAR_ORANISATION_CBC_FORM_DATA };
};

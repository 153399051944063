import {useBoolean, useId} from '@uifabric/react-hooks';
import _ from 'lodash';
import {
    Checkbox,
    ContextualMenu,
    Dropdown,
    FontWeights,
    getTheme,
    IconButton,
    IDragOptions,
    IIconProps,
    IStackItemStyles,
    IStackStyles,
    IStackTokens,
    Label,
    mergeStyleSets,
    Modal,
    PrimaryButton,
    Stack,
    TextField,
} from '@fluentui/react';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import SortableTree, {
    addNodeUnderParent,
    changeNodeAtPath,
    getFlatDataFromTree,
    getTreeFromFlatData,
    toggleExpandedForAll,
} from '@nosferatu500/react-sortable-tree';
import {v4 as uuidv4} from 'uuid';
import i18n from '../../../../i18n';
// import '../../../../shared/treeStructureDropdown/react-sortable-tree.css';
import '../../../shared/treeStructureDropdown/react-sortable-tree.css';
import {uPrinceTheme} from '../../../../theme';
import CustomTagPicker from '../../../shared/tagPicker/customTagPicker';
import {getPerentFromTreeData} from '../../../shared/util';

const theme = getTheme();
// Styles definition
const stackStyles: IStackStyles = { root: { padding: 0 } };
const COMPETANCY_TAXONOMY_TYPE_ID = '88282458-0b40-poa3-b0f9-c2e40344c888';
const COMPETANCY_TAXONOMY_LEVEL_ID = '6610e768-3e06-po02-b337-ee367a82ad66';
const COMPETANCY_TAXONOMY_COMPETANCY_ID = '4010e768-3e06-po02-b337-ee367a82addb';

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
  },
};

// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
    width: 17,
    minWidth: 17,
    height: 15,
    paddingRight: 1,
    paddingLeft: 1,
    paddingTop: 1,
    paddingBottom: 1,
  },
  rootHovered: { color: theme.palette.neutralDark },
};
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};

const editWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
    width: 17,
    minWidth: 17,
    height: 15,
    paddingRight: 1,
    paddingLeft: 1,
    paddingTop: 1,
    paddingBottom: 1,
  },
  rootHovered: { color: theme.palette.neutralDark },
};
const editIconWhite: IIconProps = {
  iconName: 'Edit',
  styles: editWhiteIconButtonStyles,
};

const CustomSortableTree = (props: {
  treeData: any;
  onSelectItem: (id: string) => void;
  onAddNode: (selectedLevel: any) => void;
  onCreateNode: (newNode: any) => void;
  selectItemId: string | null;
  label?: string | null;
  treeLevel: any;
  onFilterTaxonomyNodeChanged: any;
}) => {
  const [treeData, setTreeData]: any = useState([]);
  const [, setFlatTreeData]: any = useState([]);
  const [searchString, setSearchString]: any = useState('');
  const [searchFocusIndex, setSearchFocusIndex]: any = useState(0);
  const [searchFoundCount, setSearchFoundCount]: any = useState(null);
  const [selectItemId, setSelectItemId]: any = useState(null);
  const [selectedParentIds, setSelectedParentIds]: any = useState([]);
  const [selectedPath, setSelectedPath]: any = useState(null);
  const [selectedNode, setSelectedNode]: any = useState(null);
  const [newNodeName, setNewNodeName]: any = useState('');
  const [newNodeKey, setNewNodeKey]: any = useState('');
  const [nodeLevel, setNodeLevel]: any = useState(0);
  const [isEdit, setIsEdit]: any = useState(false);
  const [selectedLevel, setSelectedLevel]: any = useState(null);
  const [editNodeName, setEditNodeName]: any = useState('');

  useEffect(() => {
    const tData = getTreeFromFlatData({
      flatData: props.treeData,
      getKey: (node: any) => node.id,
      getParentKey: (node: any) => node.parentId,
      // @ts-ignore
      rootKey: null,
    });
    // console.log("treeData row data",props.treeData);
    // console.log("treeData tree data",tData);
    setTreeData(tData);
    // setSelectedItem(selectItemId);
    setSelectedParentIds(
      getPerentFromTreeData(props.treeData, props.selectItemId),
    );
  }, [props.treeData]);

  useEffect(() => {
    setSelectItemId(props.selectItemId);
    setSelectedParentIds(
      getPerentFromTreeData(props.treeData, props.selectItemId),
    );
    // if(props.selectItemId){
    // setSelectedItem(props.selectItemId);
    // }
  }, [props.selectItemId]);

  useEffect(() => {
    const fData = getFlatDataFromTree({
      treeData: treeData,
      getNodeKey: (node: any) => node.id,
    });
    setFlatTreeData(fData);
  }, [treeData]);

  const expand = (expanded: any) => {
    setTreeData(
      toggleExpandedForAll({
        treeData: treeData,
        expanded,
      }),
    );
  };

  const expandAll = () => {
    expand(true);
  };

  const collapseAll = () => {
    setSearchString(null);
    expand(false);
  };

  // Case insensitive search of `node.title`
  const customSearchMethod = ({ node, searchQuery }: any) => {
    return (
      searchQuery &&
      node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  };

  const handleAddClick = (rowInfo: any) => {
    const { node, path } = rowInfo;
    const depth = path.length;
    const level = depth + 1;
    setIsOpen(true);
    setSelectedPath(path);
    setSelectedNode(node);
    setNodeLevel(node.displayOrder);
  };

  const handleSave = () => {
    if (validate()) {
      addNewNode();
      resetValues();
      setIsOpen(false);
    }
  };

  const resetValues = () => {
    resetValidation();
    setIsEdit(false);
    setNewNodeName('');
    setNewNodeKey('');
    setSelectedNode('');
    setSelectedPath('');
  };

  const addNewNode = () => {
    if (selectedPath) {
      const newNode = {
        title: newNodeName,
        key: newNodeKey,
        id: uuidv4(),
        competenciesTaxonomyLevelId: selectedLevel
          ? selectedLevel.levelId
          : null,
        parentId: selectedNode.id,
        displayOrder: selectedLevel ? selectedLevel.displayOrder : null,
        children: [],
      };
      setTreeData(
        addNodeUnderParent({
          treeData: treeData,
          parentKey: selectedPath[selectedPath.length - 1],
          expandParent: true,
          newNode: newNode,
          getNodeKey: ({ treeIndex }) => treeIndex,
        }).treeData,
      );
      props.onCreateNode(newNode);
    }
  };

  const handleEditClick = (rowInfo: any) => {
    const { node, path } = rowInfo;

    setIsOpen(true);
    setSelectedPath(path);
    setSelectedNode(node);
    setEditNodeName(node.title);
    setIsEdit(true);
  };
  const handleEdit = () => {
    if (validate()) {
      updateNode();
      resetValues();
      setIsOpen(false);
    }
  };

  const updateNode = () => {
    const node = selectedNode;
    const path = selectedPath;
    const { children } = node;
    const editNode = {
      ...selectedNode,
      competenciesTaxonomyLevelId: selectedNode?.competenciesTaxonomyLevelId,
      displayOrder: selectedNode?.displayOrder,
      title: editNodeName,
      key: newNodeKey,
      id: selectedNode.id,
      isEdit: true,
    };
    const value = editNodeName;

    const newTree = changeNodeAtPath({
      treeData,
      path,
      getNodeKey: ({ treeIndex }) => treeIndex,
      newNode: {
        children,
        title: value,
        id: selectedNode.id,
        competenciesTaxonomyLevelId: selectedNode?.competenciesTaxonomyLevelId,
        displayOrder: selectedNode?.displayOrder,
      },
    });

    setTreeData(newTree);
    props.onCreateNode(editNode);
  };

  const handleNodeCheckboxClick = (rowInfo: any) => {
    const { node, path } = rowInfo;
    setSelectedPath(path);
    setSelectedNode(node);
    props.onSelectItem(node.id);
  };

  const handleTreeOnSearch = (searchString: string) => {
    setSearchString(searchString);
  };

  const selectPrevMatch = () => {
    let searchFoundCountVal = 0;
    let searchFocusIndexValue = 0;
    if (typeof searchFoundCount === 'number') {
      searchFoundCountVal = searchFoundCount;
    }
    if (typeof searchFocusIndex === 'number') {
      searchFocusIndexValue = searchFocusIndex;
    }

    setSearchFocusIndex(
      searchFocusIndex !== null
        ? (searchFoundCountVal + searchFocusIndexValue - 1) %
        searchFoundCountVal
        : searchFoundCountVal - 1,
    );
  };

  const selectNextMatch = () => {
    let searchFoundCountVal = 0;
    if (typeof searchFoundCount === 'number') {
      searchFoundCountVal = searchFoundCount;
    }

    setSearchFocusIndex(
      searchFocusIndex !== null
        ? (searchFocusIndex + 1) % searchFoundCountVal
        : 0,
    );
  };

  // model////////
  const { t } = useTranslation();
  const [isDraggable] = useBoolean(true);
  const [isOpen, setIsOpen] = useState(false);
  const titleId = useId('title');
  const theme = getTheme();
  const dragOptions: IDragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
  };
  const cancelIcon: IIconProps = { iconName: 'Cancel' };
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
    header: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '2px 2px 2px 10px',
        backgroundColor: uPrinceTheme.palette.themePrimary,
        fontSize: 18,
        color: 'white',
      },
    ],
    footer: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '0px 24px 14px 24px',
        textAlign: 'end',
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 0px 24px',
      overflowY: 'hidden',
      paddingTop: 20,
      // minWidth: screen.width > 1280?"40vw":"50vw",
      // minHeight: screen.width > 1280?"60vh":"75vh",
      // height: screen.width > 1280?"80vh":"75vh",
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
    subHeader: {
      flex: '1 1 auto',
      display: 'none',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      fontSize: 14,
      color: uPrinceTheme.palette.themePrimary,
      backgroundColor: '#FFF7F4',
      height: 40,
      marginLeft: 'auto',
      marginRight: '2px',
    },
  });
  const cancelIconButtonStyles = {
    root: {
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
      color: 'white',
    },
    rootHovered: { color: theme.palette.neutralDark },
  };

  const [nameErrorMsg, setNameErrorMsg]: any = useState('');
  const [levelErrorMsg, setLevelErrorMsg]: any = useState('');
  const validate = (): boolean => {
    let isValid = true;
    if (isEdit) {
      if (_.isNil(editNodeName) || _.isEmpty(editNodeName)) {
        setNameErrorMsg(t('nameRequired'));
        isValid = false;
      } else {
        setNameErrorMsg('');
      }
    } else {
      if (_.isNil(newNodeName) || _.isEmpty(newNodeName)) {
        setNameErrorMsg(t('nameRequired'));
        isValid = false;
      } else {
        setNameErrorMsg('');
      }
      if (_.isNil(selectedLevel) || _.isEmpty(selectedLevel)) {
        setLevelErrorMsg(t('levelRequired'));
        isValid = false;
      } else {
        setLevelErrorMsg('');
      }
    }
    return isValid;
  };

  const resetValidation = () => {
    setNameErrorMsg('');
    setLevelErrorMsg('');
  };

  const treeLevelFormat = () => {
    const data = props.treeLevel.map((item: any) => {
      return {
        ...item,
        key: item.id,
        text: item.name,
        level: item.displayOrder,
      };
    });
    const filterData = data.filter((item: any) => {
      return item.level > nodeLevel;
    });
    return filterData;
  };

  const renderCheckBox = (rowInfo: any) => {
    const isParent = selectedParentIds.includes(rowInfo.node.id);
    const isLastLevel = rowInfo.path.length === Object.keys(props.treeLevel).length - 1;
    if (true) {
      if (selectedParentIds.length > 0 && isParent) {
        return (
          <div
            key={rowInfo.node.id}
            className={'Indeterminate'}
            style={{ paddingTop: 6 }}
          >
            <Checkbox
              className="btn btn-outline-success"
              styles={{ root: { verticalAlign: 'middle' } }}
              checked={rowInfo.node.id === selectItemId}
              onChange={() => handleNodeCheckboxClick(rowInfo)}
            ></Checkbox>
          </div>
        );
      }
      return (
        <div className={'default'} style={{ paddingTop: 0 }}>
          <Checkbox
            className="btn btn-outline-success"
            styles={{
              root: {
                verticalAlign: 'middle',
                paddingTop: 6,
              },
            }}
            checked={rowInfo.node.id === selectItemId}
            onChange={() => handleNodeCheckboxClick(rowInfo)}
          ></Checkbox>
        </div>
      );
    }
    if (selectedParentIds.length > 0 && isParent) {
      return (
        <div
          key={rowInfo.node.id}
          className={'Indeterminate'}
          style={{ paddingTop: 6 }}
        >
          <Checkbox
            className="btn btn-outline-success"
            styles={{ root: { verticalAlign: 'middle' } }}
            defaultIndeterminate
            indeterminate={true}
          ></Checkbox>
        </div>
      );
    }
  };

  const renderNameField = () => {
    if (nodeLevel && nodeLevel.isSearchable) {
      return (
        <CustomTagPicker
          validationMessage={nameErrorMsg}
          required={true}
          label={t('name')}
          onFilterTagChanged={props.onFilterTaxonomyNodeChanged}
          onItemSelected={(item: any) => {
            if (item) {
              setNewNodeName(item.name);
              setNewNodeKey(item.key ? item.key : null);
            }
            resetValidation();
          }}
        />
      );
    }
    return (
      <TextField
        autoComplete="off"
        label={t('name')}
        value={newNodeName}
        required={true}
        onChange={(event, value) => {
          setNewNodeName(value);
          setNewNodeKey(null);
          resetValidation();
        }}
        errorMessage={nameErrorMsg ? nameErrorMsg : ''}
      />
    );
  };

  const renderEditNameField = () => {
    if (selectedNode) {
      return (
        <div>
          <TextField
            autoComplete="off"
            label={t('name')}
            value={editNodeName}
            required={true}
            onChange={(event, value) => {
              setEditNodeName(value);
              resetValidation();
            }}
            errorMessage={nameErrorMsg ? nameErrorMsg : ''}
          />
        </div>
      );
    }
  };

  return (
    <div
      style={{
        height: screen.width > 1280 ? '73vh' : '64vh',
        marginBottom: 10,
      }}
      className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
    >
      <Label>{props.label}</Label>
      <div className="row">
        <Stack horizontal styles={stackStyles} tokens={stackTokens}>
          <Stack.Item grow={1} styles={stackItemStyles}>
            <PrimaryButton onClick={expandAll}>
              {t('expandAll')}
            </PrimaryButton>
          </Stack.Item>
          <Stack.Item grow={1} styles={stackItemStyles}>
            <PrimaryButton onClick={collapseAll}>
              {t('collapseAll')}
            </PrimaryButton>
          </Stack.Item>
          <Stack.Item grow={3} styles={stackItemStyles}>
            <TextField
              value={searchString}
              placeholder={t('search')}
              onChange={(event, value) => {
                if (value) {
                  handleTreeOnSearch(value);
                } else {
                  handleTreeOnSearch('');
                }
              }}
            />
          </Stack.Item>
          <Stack.Item grow={1} styles={stackItemStyles}>
            <PrimaryButton
              style={{ minWidth: 25 }}
              disabled={!searchFoundCount}
              onClick={selectPrevMatch}
            >
              {' '}
              &lt;
            </PrimaryButton>
          </Stack.Item>
          <Stack.Item grow={1} styles={stackItemStyles}>
            <PrimaryButton
              style={{ minWidth: 25 }}
              disabled={!searchFoundCount}
              onClick={selectNextMatch}
            >
              &gt;
            </PrimaryButton>
          </Stack.Item>
          <Stack.Item grow={1} styles={stackItemStyles}>
            <span style={{ marginTop: 6 }}>
              &nbsp;
              {searchFoundCount
                ? searchFoundCount > 0
                  ? searchFocusIndex + 1
                  : 0
                : 0}
              &nbsp;/&nbsp;
              {searchFoundCount || 0}
            </span>
          </Stack.Item>
        </Stack>
      </div>
      <label htmlFor="find-box"></label>
      <SortableTree
        canDrag={({}) => false}
        canDrop={() => false}
        searchQuery={searchString}
        searchMethod={customSearchMethod}
        searchFocusOffset={searchFocusIndex}
        searchFinishCallback={(matches) => {
          setSearchFoundCount(matches.length);
          setSearchFocusIndex(
            matches.length > 0 ? searchFocusIndex % matches.length : 0,
          );
        }}
        // isVirtualized={true}
        treeData={treeData}
        onChange={(treeData2) => setTreeData(treeData2)}
        onlyExpandSearchedNodes={true}
        generateNodeProps={(rowInfo: any) => {
          const { path } = rowInfo;
          return {
            buttons: [
              // eslint-disable-next-line react/jsx-key
              <div>
                {rowInfo?.node?.competenciesTaxonomyLevelId ==
                COMPETANCY_TAXONOMY_COMPETANCY_ID && renderCheckBox(rowInfo)}
              </div>,
              // eslint-disable-next-line react/jsx-key
              //   <div>
              //     {rowInfo?.node?.parentId !== null ? (
              //       <PrimaryButton
              //         style={{
              //           width: 22,
              //           minWidth: 22,
              //           height: 22,
              //           padding: 5,
              //           marginTop: 5,
              //           marginRight: 3,
              //         }}
              //         iconProps={editIconWhite}
              //         ariaLabel="Close popup modal"
              //         onClick={() => {
              //           handleEditClick(rowInfo);
              //         }}
              //       ></PrimaryButton>
              //     ) : (
              //       false
              //     )}
              //   </div>,
              //   /** check is lastLevel**/
              //   rowInfo?.node?.competenciesTaxonomyLevelId ===
              //   COMPETANCY_TAXONOMY_TYPE_ID ? (
              //     <PrimaryButton
              //       style={{
              //         width: 22,
              //         minWidth: 22,
              //         height: 22,
              //         padding: 5,
              //         marginTop: 5,
              //       }}
              //       iconProps={addIconWhite}
              //       ariaLabel="Close popup modal"
              //       onClick={() => {
              //         handleAddClick(rowInfo);
              //       }}
              //     ></PrimaryButton>
              //   ) : (
              //     false
              //   ),
            ],
            onClick: () => {
              // handleNodeClick(rowInfo);
            },
          };
        }}
      />

      <Modal
        titleAriaId={titleId}
        isOpen={isOpen}
        onDismiss={() => {
          setIsOpen(false);
          resetValues();
        }}
        isBlocking={true}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>
            {isEdit ? t('editNode') : t('addNode')}{' '}
          </span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              setIsOpen(false);
              resetValues();
            }}
          />
        </div>
        <div className={contentStyles.body}>
          <div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                {!isEdit && (
                  <Dropdown
                    errorMessage={levelErrorMsg}
                    required={true}
                    label={i18n.t('level')}
                    placeholder={i18n.t('level')}
                    options={treeLevelFormat()}
                    onChange={(event, value) => {
                      setLevelErrorMsg('');
                      if (value) {
                        setSelectedLevel(value);
                      } else {
                        setSelectedLevel(null);
                      }
                    }}
                  />
                )}

                {isEdit ? renderEditNameField() : renderNameField()}
              </div>
            </div>
          </div>
        </div>
        <div className={contentStyles.footer}>
          {isEdit ? (
            <PrimaryButton
              iconProps={addIconWhite}
              text={t('save')}
              style={{ marginTop: 10, marginBottom: 10, marginRight: 10 }}
              onClick={() => {
                handleEdit();
              }}
            />
          ) : (
            <PrimaryButton
              iconProps={addIconWhite}
              text={t('add')}
              style={{ marginTop: 10, marginBottom: 10, marginRight: 10 }}
              onClick={() => {
                handleSave();
              }}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default CustomSortableTree;

export const NEW_PRODUCT_CATALOGUE = 'uprince/NEW_PRODUCT_CATALOGUE';

export const LOAD_PRODUCT_CATALOGUE = 'uprince/LOAD_PRODUCT_CATALOGUE';
export const LOAD_PRODUCT_CATALOGUE_SUCCESS = 'uprince/LOAD_PRODUCT_CATALOGUE_SUCCESS';
export const LOAD_PRODUCT_CATALOGUE_FAIL = 'uprice/LOAD_PRODUCT_CATALOGUE_FAIL';

export const LOAD_PRODUCT_CATALOGUE_BY_ID = 'uprince/LOAD_PRODUCT_CATALOGUE_BY_ID';
export const LOAD_PRODUCT_CATALOGUE_BY_ID_SUCCESS = 'uprince/LOAD_PRODUCT_CATALOGUE_BY_ID_SUCCESS';
export const LOAD_PRODUCT_CATALOGUE_BY_ID_FAIL = 'uprice/LOAD_PRODUCT_CATALOGUE_BY_ID_FAIL';

export const LOAD_PRODUCT_CATALOGUE_UPLOAD_IMAGE = 'uprince/LOAD_PRODUCT_CATALOGUE_UPLOAD_IMAGE';
export const LOAD_PRODUCT_CATALOGUE_UPLOAD_IMAGE_SUCCESS = 'uprince/LOAD_PRODUCT_CATALOGUE_UPLOAD_IMAGE_SUCCESS';
export const LOAD_PRODUCT_CATALOGUE_UPLOAD_IMAGE_FAIL = 'uprice/LOAD_PRODUCT_CATALOGUE_UPLOAD_IMAGE_FAIL';

export const LOAD_PRODUCT_CATALOGUE_DROPDOWN_DATA = 'uprince/LOAD_PRODUCT_CATALOGUE_DROPDOWN_DATA';
export const LOAD_PRODUCT_CATALOGUE_DROPDOWN_DATA_SUCCESS = 'uprince/LOAD_PRODUCT_CATALOGUE_DROPDOWN_DATA_SUCCESS';
export const LOAD_PRODUCT_CATALOGUE_DROPDOWN_DATA_FAIL = 'uprice/LOAD_PRODUCT_CATALOGUE_DROPDOWN_DATA_FAIL';

export const SAVE_PRODUCT_CATALOGUE = 'uprince/SAVE_PRODUCT_CATALOGUE';
export const SAVE_PRODUCT_CATALOGUE_SUCCESS = 'uprince/SAVE_PRODUCT_CATALOGUE_SUCCESS';
export const SAVE_PRODUCT_CATALOGUE_FAIL = 'uprice/SAVE_PRODUCT_CATALOGUE_FAIL';

export const DELETE_PRODUCT_CATALOGUE_NICK_NAME = 'uprince/DELETE_PRODUCT_CATALOGUE_NICK_NAME';
export const DELETE_PRODUCT_CATALOGUE_NICK_NAME_SUCCESS = 'uprince/DELETE_PRODUCT_CATALOGUE_NICK_NAME_SUCCESS';
export const DELETE_PRODUCT_CATALOGUE_NICK_NAME_FAIL = 'uprice/DELETE_PRODUCT_CATALOGUE_NICK_NAME_FAIL';

export const DELETE_PRODUCT_CATALOGUE_VENDOR = 'uprince/DELETE_PRODUCT_CATALOGUE_VENDOR';
export const DELETE_PRODUCT_CATALOGUE_VENDOR_SUCCESS = 'uprince/DELETE_PRODUCT_CATALOGUE_VENDOR_SUCCESS';
export const DELETE_PRODUCT_CATALOGUE_VENDOR_FAIL = 'uprice/DELETE_PRODUCT_CATALOGUE_VENDOR_FAIL';

export const DELETE_PRODUCT_CATALOGUE_IMAGE = 'uprince/DELETE_PRODUCT_CATALOGUE_IMAGE';
export const DELETE_PRODUCT_CATALOGUE_IMAGE_SUCCESS = 'uprince/DELETE_PRODUCT_CATALOGUE_IMAGE_SUCCESS';
export const DELETE_PRODUCT_CATALOGUE_IMAGE_FAIL = 'uprice/DELETE_PRODUCT_CATALOGUE_IMAGE_FAIL';


export const LOAD_PRODUCT_CATALOGUE_SHORTCUT_PANE_FILTER_LIST = 'uprince/LOAD_PRODUCT_CATALOGUE_SHORTCUT_PANE_FILTER_LIST';
export const LOAD_PRODUCT_CATALOGUE_SHORTCUT_PANE_FILTER_LIST_SUCCESS = 'uprince/LOAD_PRODUCT_CATALOGUE_SHORTCUT_PANE_FILTER_LIST_SUCCESS';
export const LOAD_PRODUCT_CATALOGUE_SHORTCUT_PANE_FILTER_LIST_FAIL = 'uprice/LOAD_PRODUCT_CATALOGUE_SHORTCUT_PANE_FILTER_LIST_FAIL';


export const LOAD_ORGANIZATION_LIST = 'uprince/LOAD_ORGANIZATION_LIST';
export const LOAD_ORGANIZATION_LIST_SUCCESS = 'uprince/LOAD_ORGANIZATION_LIST_SUCCESS';
export const LOAD_ORGANIZATION_LIST_FAIL = 'uprice/LOAD_ORGANIZATION_LIST_FAIL';


export const LOAD_CPC_RESOURCE_FAMILY = 'uprince/LOAD_CPC_RESOURCE_FAMILY';
export const LOAD_CPC_RESOURCE_FAMILY_SUCCESS = 'uprince/LOAD_CPC_RESOURCE_FAMILY_SUCCESS';
export const LOAD_CPC_RESOURCE_FAMILY_FAIL = 'uprice/LOAD_CPC_RESOURCE_FAMILY_FAIL';

export const LOAD_CPC_RESOURCE_EXCEL_DATA = 'uprince/LOAD_CPC_RESOURCE_EXCEL_DATA';
export const LOAD_CPC_RESOURCE_EXCEL_DATA_SUCCESS = 'uprince/LOAD_CPC_RESOURCE_EXCEL_DATA_SUCCESS';
export const LOAD_CPC_RESOURCE_EXCEL_DATA_FAIL = 'uprice/LOAD_CPC_RESOURCE_EXCEL_DATA_FAIL';


export const DELETE_PRODUCT_CATALOGUE = 'uprince/DELETE_PRODUCT_CATALOGUE';
export const DELETE_PRODUCT_CATALOGUE_SUCCESS = 'uprince/DELETE_PRODUCT_CATALOGUE_SUCCESS';
export const DELETE_PRODUCT_CATALOGUE_FAIL = 'uprice/DELETE_PRODUCT_CATALOGUE_FAIL';

export const CREATE_CPC_RESOURCE_FAMILY = 'uprince/CREATE_CPC_RESOURCE_FAMILY';
export const CREATE_CPC_RESOURCE_FAMILY_SUCCESS = 'uprince/CREATE_CPC_RESOURCE_FAMILY_SUCCESS';
export const CREATE_CPC_RESOURCE_FAMILY_FAIL = 'uprice/CREATE_CPC_RESOURCE_FAMILY_FAIL';

export const LOAD_VEHICLE_TRACKING_LIST_DATA = 'uprince/LOAD_VEHICLE_TRACKING_LIST_DATA';
export const LOAD_VEHICLE_TRACKING_LIST_DATA_SUCCESS = 'uprince/LOAD_VEHICLE_TRACKING_LIST_DATA_SUCCESS';
export const LOAD_VEHICLE_TRACKING_LIST_DATA_FAIL = 'uprice/LOAD_VEHICLE_TRACKING_LIST_DATA_FAIL';

import { Label, Link } from '@fluentui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactVisibilitySensor from 'react-visibility-sensor'
import DataGrid from '../../../../shared/dataGrid/component'
import { BorResources } from '../../../../types/billOfResources'
import { ActionButtonState } from '../../../../types/corporateProductCatalogue'
import { Resource } from '../../../../types/projectMolecule'

const PlannedWork = (props: {
  onVisibilityChangePlannedWorks: (isVisible: boolean) => void;
  borResources: BorResources;
  openMaterialModal: () => void;
  materialActionButtonState: ActionButtonState;
  materialSelection: any;
  handleMaterialDelete: () => void;
  readOnly: boolean;
  materialLoading: boolean;
  //
  tools: Resource[];
  toolsActionButtonState: ActionButtonState;
  openToolsModal: () => void;
  toolSelection: any;
  handleToolsDelete: () => void;
  toolsLoading: boolean
  //
  openConsumableModal: () => void;
  consumableSelection: any;
  consumableActionButtonState: ActionButtonState;
  consumables: Resource[];
  consumalbeLoading: boolean;
  handleConsumableDelete: () => void;
  //
  labours: Resource[];
  labourActionButtonState: ActionButtonState;
  openLabourModal: () => void;
  labourSelection: any;
  labourLoading: boolean;
  handleLabourDelete: () => void;
}) => {
  const {t} = useTranslation()
  const plannedWorkColumns = [
    {
      key: 'column1',
      name: t('title'),
      fieldName: 'title',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('plannedQuantity'),
      fieldName: 'required',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('consumedquantity'),
      fieldName: 'consumedQuantity',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column5',
      name: t('unit'),
      fieldName: 'unit',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];
  return (
    <div className="document-pane-card">
      <div className={'card-header'}>
        <Link href="#" id="planned-work">
          <Label>4. {t('plannedwork')} </Label>
        </Link>
      </div>

      <div className={'card-body'}>
        <div>
          <div className="proj-detail-block" id="8">
            <div id={'planned-work-id-3-1'}>
              <ReactVisibilitySensor scrollCheck onChange={props.onVisibilityChangePlannedWorks}>
                <DataGrid
                  dataList={
                    props.borResources.materials
                      ? props.borResources.materials
                      : []
                  }
                  editDataGrid={() => props.openMaterialModal()}
                  openModel={() => {
                    props.openMaterialModal();
                  }}
                  selection={props.materialSelection}
                  actionButtonState={props.materialActionButtonState}
                  title={'4.1 ' + t('material')}
                  deleteDataGrid={() => {
                    props.handleMaterialDelete();
                  }}
                  readOnly={props.readOnly}
                  columns={plannedWorkColumns}
                  isLoaded={props.materialLoading}
                />
              </ReactVisibilitySensor>
            </div>
          </div>

          <div className="proj-detail-block" id="9">
            <div id={'planned-work-id-3-2'}>
              <ReactVisibilitySensor scrollCheck onChange={props.onVisibilityChangePlannedWorks}>
                <DataGrid
                  dataList={
                    props.borResources.tools ? props.borResources.tools : []
                  }
                  editDataGrid={() => props.openToolsModal()}
                  openModel={() => {
                    props.openToolsModal();
                  }}
                  selection={props.toolSelection}
                  actionButtonState={props.toolsActionButtonState}
                  title={'4.2 ' + t('tools')}
                  deleteDataGrid={() => {
                    props.handleToolsDelete();
                  }}
                  readOnly={props.readOnly}
                  columns={plannedWorkColumns}
                  isLoaded={props.toolsLoading}
                />
              </ReactVisibilitySensor>
            </div>
          </div>

          <div className="proj-detail-block" id="10">
            <div id={'planned-work-id-3-3'}>
              <ReactVisibilitySensor scrollCheck onChange={props.onVisibilityChangePlannedWorks}>
                <DataGrid
                  dataList={
                    props.borResources.consumable
                      ? props.borResources.consumable
                      : []
                  }
                  editDataGrid={() => props.openConsumableModal()}
                  openModel={() => {
                    props.openConsumableModal();
                  }}
                  selection={props.consumableSelection}
                  actionButtonState={props.consumableActionButtonState}
                  title={'4.3 ' + t('consumables')}
                  deleteDataGrid={() => {
                    props.handleConsumableDelete();
                  }}
                  readOnly={props.readOnly}
                  columns={plannedWorkColumns}
                  isLoaded={props.consumalbeLoading}
                />
              </ReactVisibilitySensor>
            </div>
          </div>

          <div className="proj-detail-block" id="11">
            <div id={'planned-work-id-3-4'}>
              <ReactVisibilitySensor scrollCheck onChange={props.onVisibilityChangePlannedWorks}>
                <DataGrid
                  dataList={
                    props.borResources.labour ? props.borResources.labour : []
                  }
                  editDataGrid={() => props.openLabourModal()}
                  openModel={() => {
                    props.openLabourModal();
                  }}
                  selection={props.labourSelection}
                  actionButtonState={props.labourActionButtonState}
                  title={'4.4 ' + t('labour')}
                  deleteDataGrid={() => {
                    props.handleLabourDelete();
                  }}
                  readOnly={props.readOnly}
                  columns={plannedWorkColumns}
                  isLoaded={props.labourLoading}
                />
              </ReactVisibilitySensor>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default PlannedWork
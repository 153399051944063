import {
  ContextualMenu,
  Dropdown,
  FontWeights,
  getTheme,
  Icon,
  IconButton,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  TextField,
} from '@fluentui/react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../theme';
import { Company } from '../../../types/addressBookItem';
import Contact from '../../../types/contact';
import { ActionButtonState } from '../../../types/corporateProductCatalogue';
import ContactGrid from '../contacts/component';
import ContractorsTaxonomy from '../contractorsTaxonomy/contractorsTaxonomy';

const theme = getTheme();

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
});

const stackStyles: IStackStyles = { root: { padding: 0 } };
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 20,
  },
};

const bankItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 20,
  },
};

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: { color: uPrinceTheme.palette.white },
  rootHovered: { color: theme.palette.neutralDark },
};
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '10px 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '40vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 10 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: { color: theme.palette.neutralDark },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};


class CompanyValidationMessages {
  nameValidationMsg: string = '';
  vatValidationMsg: string = '';
  bankAccountValidationMsg: string = '';
  streetValidationMsg: string = '';
  streetNumbervalidationMsg: string = '';
}

const CompanyModal = (props: {
  isOpen: boolean;
  companyName: string;
  openComapnyModal: () => void;
  onAddCompany: (contact: Company) => void;
  countries: { key: string; text: string }[];
  organisationInternetContactSelection: any;
  organisationalContact: Contact[];
  editOrganisationalContact: () => void;
  organisationPhoneContactSelectionDetails: any;
  organisationInternetContactActionButtonState: ActionButtonState;
  openOrganisatinalInternetContactModel: () => void;
  organisationPhoneContactSelection: any;
  organisationalPhoneContact: Contact[];
  editOrganisationPhoneContacts: () => void;
  organisationPhoneContactActionButtonState: ActionButtonState;
  openOrganisatinalPhoneContactModel: () => void;
  company: Company;
  deleteOrganisationContact: () => void;
  deleteOrganisationPhoneContact: () => void;
  isNewCompanyCreate: boolean;
  isDeleteContact: boolean;
  isChecking: boolean;
  isUniq: number;
  isQniqContact: any;
  handleContractorsTaxonomyChange: any
  addressBookItem: any
}) => {
  const { t } = useTranslation();
  const [isDraggable] = useBoolean(true);
  const titleId = useId('title');

  const [company, setCompany] = useState(new Company());
  const [hasEdit, setHasEdit] = useState(false);
  const [companyValidationMessages, setCompanyValidationMsg] = useState<CompanyValidationMessages>(new CompanyValidationMessages());
  const [isProgress, setProgress] = useState(false);
  const [companySaveClicked, setSaveClicked] = useState(false);
  const [companyName, setCompanyName] = useState<string | null>(null);
  const [, setPropsCompanyName] = useState<string | null>(null);
  // const [alreadyUsedOccure, setAlreadyUsed ] = useState<boolean>(false)


  useEffect(() => {
    const comName = props.companyName;
    const key = 'name';
    if (comName) {
      setCompany((prevState) => ({
        ...prevState,
        [key]: comName,
      }));
      setCompanyName(comName);
      setPropsCompanyName(comName);
    }
  }, [props.companyName]);

  const onChangeInput = (field: string) => (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    // UprinceLogger.log(JSON.stringify(field));
    // UprinceLogger.log(JSON.stringify(newValue));
    setCompany((prevState) => ({
      ...prevState,
      [field]: newValue,
    }));

    setHasEdit(true);
    setCompanyValidationState('nameValidationMsg', '');
    // validaErrorOnvalueChange(field);
    // console.log('Company', JSON.stringify(company));
  };


  useEffect(() => {
    if (props.company && !props.company.name && !hasEdit && !companySaveClicked && companyValidationMessages.nameValidationMsg === '') {
      if (props.companyName) {
        const newCom = new Company();
        newCom.name = props.companyName;
        setCompany(newCom);
        setCompanyName(props.companyName);
      } else {
        setCompany(new Company());
        setCompanyName(null);
      }
      setProgress(false);
      // setAlreadyUsed(false)
    }

    // if (props.isUniq === 2 && company.name && props.isOpen && companySaveClicked) {
    //   setProgress(false);
    //   setHasEdit(false);
    //   setSaveClicked(false);
    //   //setAlreadyUsed(false)
    //   props.onAddCompany(company);
    //   //setCompany(new Company());
    // } else if (props.isUniq === 1 && props.isOpen && companySaveClicked && company.name !== '' ) {
    //   setProgress(false);
    //   setSaveClicked(false);
    //   //setAlreadyUsed(true)
    //   setCompanyValidationState('nameValidationMsg', t('alreadyinuse'));
    //   setHasEdit(false);
    //   //props.onAddCompany(company);
    // }
    // if (props.isNewCompanyCreate && !hasEdit && !companySaveClicked && props.isUniq === 0) {

    //   setCompanyValidationState('nameValidationMsg', '');
    // }

    if (!props.isNewCompanyCreate && !props.isDeleteContact && !hasEdit && props.company && props.company.name != companyName) {
      setProgress(false);
      setCompany(() => ({ ...props.company }));
      // setAlreadyUsed(false)
      setCompanyName(props.company.name);
    }

    if (
      _.isArray(props.organisationalContact) &&
      props.organisationalContact.length > 0 &&
      !props.isDeleteContact
    ) {
      props.organisationalContact.map((item: Contact) => {
        if (item.type === t('email')) {
          setCompanyState('email', item.value);
        }
        if (item.type === t('skype')) {
          setCompanyState('skype', item.value);
        }
        if (item.type === t('whatsApp')) {
          setCompanyState('whatsApp', item.value);
        }
      });
    }

    if (
      _.isArray(props.organisationalPhoneContact) &&
      props.organisationalPhoneContact.length > 0 &&
      !props.isDeleteContact
    ) {
      props.organisationalPhoneContact.map((item: Contact) => {
        if (item.type === t('phone')) {
          setCompanyState('landPhone', item.value);
        }
        if (item.type === t('mobile')) {
          setCompanyState('mobilePhone', item.value);
        }
      });
    }

    if (_.isArray(props.organisationalContact) && props.isDeleteContact) {
      if (!props.organisationalContact.some(checkContactEmailType)) {
        setCompanyState(t('email'), null);
      }

      if (!props.organisationalContact.some(checkContactSkypeType)) {
        setCompanyState(t('skype'), null);
      }

      if (!props.organisationalContact.some(checkContactWhatsAppType)) {
        setCompanyState(t('whatsApp'), null);
      }
    }

    if (_.isArray(props.organisationalPhoneContact) && props.isDeleteContact) {
      if (!props.organisationalPhoneContact.some(checkContactMobileType)) {
        setCompanyState(t('mobilePhone'), null);
      }

      if (!props.organisationalPhoneContact.some(checkContactPhoneType)) {
        setCompanyState(t('landPhone'), null);
      }
    }
  }, [
    props.organisationalContact,
    props.organisationalPhoneContact,
    props.company,
  ]);

  useEffect(() => {
    if (props.isUniq === 2 && company.name && props.isOpen && companySaveClicked) {
      setProgress(false);
      setHasEdit(false);
      setSaveClicked(false);
      // setAlreadyUsed(false)
      props.onAddCompany(company);
      // setCompany(new Company());
    } else if (props.isUniq === 1 && props.isOpen && companySaveClicked && company.name !== '') {
      setProgress(false);
      setSaveClicked(false);
      // setAlreadyUsed(true)
      setCompanyValidationState('nameValidationMsg', t('alreadyinuse'));
      setHasEdit(false);
      // props.onAddCompany(company);
    }
  }, [props.isUniq]);

  const checkContactEmailType = (contact: Contact) => {
    return contact.type === t('email');
  };

  const checkContactSkypeType = (contact: Contact) => {
    return contact.type === t('skype');
  };

  const checkContactWhatsAppType = (contact: Contact) => {
    return contact.type === t('whatsApp');
  };

  const checkContactMobileType = (contact: Contact) => {
    return contact.type === t('mobile');
  };

  const checkContactPhoneType = (contact: Contact) => {
    return contact.type === t('phone');
  };

  const setCompanyState = (key: string, value: string | null) => {
    setCompany((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const validateCompany = (company: Company) => {
    const isValidName = validateCompanyName(company);
    return isValidName;
  };

  const validateCompanyName = (company: Company) => {
    let isValidName = false;
    if (company && company.name) {
      isValidName = true;
      setCompanyValidationState('nameValidationMsg', '');
    } else {
      isValidName = false;
      setSaveClicked(false);
      setCompanyValidationState(
        'nameValidationMsg',
        t('companyNameRequired'),
      );
    }
    return isValidName;
  };


  const setCompanyValidationState = (key: string, msg: string) => {
    setCompanyValidationMsg((prevState) => ({
      ...prevState,
      [key]: msg,
    }));
  };

  const onChangeAddressInput = (field: string) => (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    // UprinceLogger.log(JSON.stringify(field));
    // UprinceLogger.log(JSON.stringify(newValue));
    setHasEdit(true);
    setCompany((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        [field]: newValue,
      },
    }));
    // console.log('Company', JSON.stringify(company));
  };

  const onRenderCaretDown = (): JSX.Element => {
    return (
      <Icon
        iconName="ChevronDown"
        onClick={() => {
          // alert('test');
        }}
      />
    );
  };

  const handleContractorsTaxonomyChange = (value: string[], node: any) => {
    let contractorTaxonomyId = company.contractorTaxonomyId ? company?.contractorTaxonomyId?.slice() : [];
    let isExists = contractorTaxonomyId.includes(node.id);
    value.forEach((element: any) => {
      if (isExists) {
        contractorTaxonomyId = contractorTaxonomyId.filter((item: any) => item !== element.id);
      } else {
        contractorTaxonomyId.push(element.id);
      }
    });
    setCompany((prevState) => ({
      ...prevState,
      contractorTaxonomyId: contractorTaxonomyId,
    }));
  };

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          setCompanyName(null);
          setCompanyValidationMsg(new CompanyValidationMessages());
          props.openComapnyModal();
        }}
        scrollableContentClassName="body"
        isBlocking={true}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
                    <span id={titleId}>
                        {props.isNewCompanyCreate
                          ? t('add') + ' ' + t('organisation')
                          : t('edit') + ' ' + t('organisation')}
                    </span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              setHasEdit(false);
              setCompanyValidationMsg(new CompanyValidationMessages());
              setCompanyName(null);
              props.openComapnyModal();
            }}
          />
        </div>
        { /* <div className={contentStyles.subHeader}>
          <ActionButton iconProps={addIcon} allowDisabledFocus>
            <Label className={contentStyles.actionButtonLabel}>

              {props.isNewCompanyCreate ? t('add') : t('save')}
            </Label>
          </ActionButton>
        </div> */}
        {isProgress && (
          <ProgressIndicator styles={{ root: { marginTop: -10 } }}/>
        )}
        <div className={contentStyles.body}>
          <div className="proj-detail-content inner">
            <div className="ms-Grid-row">
              <Label>1. {t('organisationDetails')} </Label>
              <Label>1.1 {t('general')} </Label>
              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('name')}
                      required={true}
                      value={
                        company && company.name ? company.name : ''
                      }
                      errorMessage={companyValidationMessages.nameValidationMsg
                      }
                      onChange={onChangeInput('name')}
                    />
                  </div>
                </Stack.Item>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('vat')}
                      value={
                        company && company && company.vat ? company.vat : ''
                      }
                      errorMessage={companyValidationMessages.vatValidationMsg}
                      onChange={onChangeInput('vat')}
                    />
                  </div>
                </Stack.Item>
              </Stack>
              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={bankItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('bankAccount')}
                      value={
                        company && company && company.bankAccount
                          ? company.bankAccount
                          : ''
                      }
                      errorMessage={
                        companyValidationMessages.bankAccountValidationMsg
                      }
                      onChange={onChangeInput('bankAccount')}
                    />
                  </div>
                </Stack.Item>
                <Stack.Item grow={6} styles={bankItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('accountancyCompanyNumber')}
                      value={
                        company && company && company.accountingNumber
                          ? company.accountingNumber
                          : ''
                      }
                      // errorMessage={
                      //     companyValidationMessages.
                      // }
                      onChange={onChangeInput('accountingNumber')}
                    />
                  </div>
                </Stack.Item>
              </Stack>
              <Label>1.2 {t('address')} </Label>

              <div className="proj-detail-content inner">
                <div className="marginTop">
                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <TextField
                          label={t('street')}
                          value={
                            company && company.address && company.address.street
                              ? company.address.street
                              : ''
                          }
                          onChange={onChangeAddressInput('street')}
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <TextField
                          label={t('streetNumber')}
                          value={
                            company &&
                            company.address &&
                            company.address.streetNumber
                              ? company.address.streetNumber
                              : ''
                          }
                          onChange={onChangeAddressInput('streetNumber')}
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <TextField
                          label={t('mailBox')}
                          value={
                            company &&
                            company.address &&
                            company.address.mailBox
                              ? company.address.mailBox
                              : ''
                          }
                          onChange={onChangeAddressInput('mailBox')}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <TextField
                          label={t('postalCode')}
                          value={
                            company &&
                            company.address &&
                            company.address.postalCode
                              ? company.address.postalCode
                              : ''
                          }
                          onChange={onChangeAddressInput('postalCode')}
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <TextField
                          label={t('city')}
                          value={
                            company && company.address && company.address.city
                              ? company.address.city
                              : ''
                          }
                          onChange={onChangeAddressInput('city')}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <TextField
                          label={t('region')}
                          onChange={onChangeAddressInput('region')}
                          value={
                            company && company.address && company.address.region
                              ? company.address.region
                              : ''
                          }
                        />
                      </div>
                    </Stack.Item>

                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Dropdown
                          placeholder={t('country')}
                          label={t('country')}
                          options={props.countries}
                          selectedKey={
                            company &&
                            company.address &&
                            company.address.countryId
                              ? company.address.countryId
                              : -1
                          }
                          onRenderCaretDown={onRenderCaretDown}
                          onChange={(
                            event: React.FormEvent<HTMLDivElement>,
                            item: any,
                          ) => {
                            setCompany((prevState) => ({
                              ...prevState,
                              address: {
                                ...prevState.address,
                                countryId: item.key,
                              },
                            }));
                          }}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>
                </div>

                <div className="marginTop">
                  <ContactGrid
                    contactSelection={
                      props.organisationInternetContactSelection
                    }
                    contacts={props.organisationalContact}
                    editContact={() =>
                      props.openOrganisatinalInternetContactModel()
                    }
                    contactSelectionDetails={
                      props.organisationPhoneContactSelectionDetails
                    }
                    contactActionButtonState={
                      props.organisationInternetContactActionButtonState
                    }
                    openModel={() => {
                      props.openOrganisatinalInternetContactModel();
                    }}
                    title={1.3 + ' ' + t('internet')}
                    deleteContact={() => {
                      props.deleteOrganisationContact();
                    }}
                    readOnly={false}
                    valueHeader={t('accounts')}
                  />
                </div>
                <div className="marginTop">
                  <ContactGrid
                    contactSelection={props.organisationPhoneContactSelection}
                    contacts={props.organisationalPhoneContact}
                    editContact={() =>
                      props.openOrganisatinalPhoneContactModel()
                    }
                    contactSelectionDetails={
                      props.organisationPhoneContactSelectionDetails
                    }
                    contactActionButtonState={
                      props.organisationPhoneContactActionButtonState
                    }
                    openModel={() => {
                      props.openOrganisatinalPhoneContactModel();
                    }}
                    title={1.4 + ' ' + t('phoneNumbers')}
                    deleteContact={() => {
                      props.deleteOrganisationPhoneContact();
                    }}
                    readOnly={false}
                    valueHeader={t('numbers')}
                  />
                </div>

                <div className="marginTop">
                  <div className="proj-detail-content inner">
                    <Label>5.5. {t('lots')} </Label>
                    <ContractorsTaxonomy
                      disabled={false}
                      label={t('contractorsTaxonomy')}
                      selectItemIds={Array.isArray(company?.contractorTaxonomyId) ? company?.contractorTaxonomyId : []}
                      onChange={(selectedNode: any, node: any) => {
                        handleContractorsTaxonomyChange(selectedNode, node);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={addIconWhite}
            text={t('save')}
            style={{ marginTop: 20, marginBottom: 30 }}
            onClick={() => {
              setSaveClicked(true);
              if (validateCompany(company)) {
                if (!props.isNewCompanyCreate && company.name === companyName) {
                  props.onAddCompany(company);
                  setSaveClicked(false);
                } else {
                  setProgress(true);
                  props.isQniqContact({ companyName: company.name });
                }
              }
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CompanyModal;

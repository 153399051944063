import React from 'react';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';

const CommandBarRight = () => {
    const { t } = useTranslation();
    const newClick = () => {
        messageService.sendMessage({ isNewMYDP: true });
        // props.createNew(true);
    };
    const saveFormData = () => {
        messageService.sendMessage({ savePmolFormData: true });
    };
    const handleRemove = () => {
        // props.handleRemove();
    };

    const getItems = () => {
        return [
            {
                key: 'new',
                text: t('new'),
                iconProps: { iconName: 'Add' },
                onClick: () => { newClick(); },
                disabled: true
            },
            {
                key: 'save',
                text: t('save'),
                iconProps: { iconName: 'Save' },
                onClick: () => { saveFormData(); },
                disabled: false
            },
            {
                key: 'Remove',
                onClick: () => { handleRemove(); },
                name: t('remove'),
                iconProps: { iconName: 'Delete' },
                disabled: true
            },
        ];
    };

    return (
        <div>
            <CommandBar
                items={getItems()}
                ariaLabel="Use left and right arrow keys to navigate between commands"
            />
        </div>
    );
};

export default CommandBarRight;

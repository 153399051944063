import React, { useEffect, useRef, useState } from 'react';
import {
  ActionButton,
  getTheme,
  IIconProps,
  Image,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  PrimaryButton,
  registerIcons,
  Separator,
  Spinner,
  SpinnerSize,
  Stack,
  TextField,
} from '@fluentui/react';
import i18n from '../../../i18n';
import { SvgIcons } from '../../shared/svgIcons/svgIcons';
import SignatureCanvas from 'react-signature-canvas';
import { useParams } from 'react-router';
import {
  getRfqInvitationDetails,
  savePoAtrr,
  saveSignature,
  uploadRfqSignature,
} from '../../reducers/purchaseOrderReducer';
import { useDispatch, useSelector } from 'react-redux';
import { uPrinceTheme } from '../../../theme';
import _ from 'lodash';
import { store } from '../../../index';
import { GeneratePdf } from './pdf/generatePdf';
import PdfModal from './pdf/pdfModal';


const theme = getTheme();
const isMobile = screen.width < 768;

registerIcons(SvgIcons);

export class Signature {
  fullName: string | null = null;
  signature: string | null = null;
  sequenceId: string | null = null;
  cu: string | null = null;
  isDraw: boolean = false;
}

const classNames = mergeStyleSets({

  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
});

const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const signPadItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 250,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};
const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const styles = {
  textField: {
    fontSize: 30,
    height: 65,
  },

  mobileFooterText: {
    fontSize: 30,
    textAlign: 'center',
    fontWeight: 'normal',
  },

  textFieldLabel: {
    marginRight: 5,
    fontSize: 30,
    paddingBottom: 10,
  },

  textFieldDiv: {
    height: 130,
  },

  mobileFontSize: {
    fontSize: 30,
  },

  acceptButton: {
    padding: 30,
    fontSize: 30,
    textAlign: 'center',
  }
};

const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };

const  RfqDocumentSign=()=>{
  const dispatch = useDispatch();
  const signPad: any = useRef(null);
  const [signature, setSignature] = useState<Signature>(new Signature());
  const [signatureBase64, setSignatureBase64]:any = useState('');
  const [clearDisable, setClearDisable] = useState(true);
  const [disableSave, setDisableSave] = useState(true);
  const [isProgress, setProgress] = useState(false);
  const [isOpenPdfModal, setIsOpenPdfModal] = useState(false);
  const inputFile: any = useRef(null);
  const params: any = useParams();
  const acceptedTypes: string[] = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/webp',
  ];

  const {
    showRfqAlert,
  } = useSelector(
    (state: any) => state.po
  );

  useEffect(()=>{
    //contratingUnitId
    //featureId
    setSignature((prevState) => ({
      ...prevState,
      sequenceId: params?.featureId,
      cu: params?.contratingUnitId,
    }));
    dispatch(getRfqInvitationDetails(params?.featureId))
    return () => { dispatch(savePoAtrr('signatureLink',''))}
  },[]);

  const saveInvitation=(pdf:any)=>{
    const formData = new FormData();
    // let data={fullName:signature.fullName,cu:signature.cu,sequenceId:signature.sequenceId,signature:signature.signature}
    // formData.append('test',JSON.stringify(data))
    formData.append('pdf', pdf,'contractor-agreement.pdf');
    formData.append('rfqAccept', JSON.stringify(signature));
    console.log("saveInvitation");
    dispatch(saveSignature(formData))
    dispatch(savePoAtrr('showRfqAlert',true))

  }

  const getPdf=async ()=>{
    console.log({signatureBase64})
    if(signatureBase64){
      let file=await  GeneratePdf(signatureBase64,signature.fullName);
      if(file){
        // var blob = new Blob([file], {type: 'application/pdf'});
        // var blobURL = URL.createObjectURL(blob);
        // window.open(blobURL);
        return new Blob([file], {type: 'application/pdf'});
      }
      return file
    }
  }

  const getFooterText=()=>{
    const language =  window.navigator.language;
    if(language){
      switch (language) {
        case "en-US":
          return "By selecting Accept and Sign, I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial";
        case "en":
          return "By selecting Accept and Sign, I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial";
        case "nl":
          return "By selecting Accept and Sign, I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial nl";
        case "nl-BE":
          return "By selecting Accept and Sign, I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial nl";
        case "de":
          return "By selecting Accept and Sign, I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial nl";
        default:
          return "By selecting Accept and Sign, I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial nl";
      }

    }else{
      return "By selecting Accept and Sign, I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial";
    }
  };

  const isValidFileType = (fileType: string): boolean => {
    return acceptedTypes.includes(fileType);
  };

  const onLinkFileChange=(event:any)=>{
    if (event && event.target && event.target.files && event.target.files[0]) {
      if (!isValidFileType(event.target.files[0].type)) {
        return;
      }
      const formData = new FormData();

      if (!_.isEmpty(params?.featureId,) && params?.featureId !== null) {
        formData.append('image', event.target.files[0]);
        formData.append('id', params?.featureId);
        dispatch(uploadRfqSignature(formData));
      }
      setBase64Image(event.target.files[0]).then(()=>{
        setTimeout(()=>{ setProgress(false);},2000)

      })
      event.target.value = null;
    }
  }

  const setBase64Image=async (file:any)=>{
    const base64 = await convertBase64(file);
    setSignatureBase64(base64);
  }

  const convertBase64 = (file:any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(()=>{
    if(store.getState().po?.signatureLink){
      console.log(store.getState().po?.signatureLink);
      setSignature((prevState) => ({
        ...prevState,
        signature: store.getState().po?.signatureLink,
        isDraw:false
      }));
    }
  },[store.getState().po?.signatureLink])


  return (
    <div>
      {!isMobile && <div className={'card-header'}>
        <Link href='#' id='invoice-invoice-amount'>
          <Label>5. {i18n.t('signature')} </Label>
        </Link>
      </div>}
      <div>
        <div>
          <div>
            <Stack
              horizontal={!isMobile}
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <div className={`${classNames.fullWidth}`}>
                  <TextField
                    className={isMobile ? "rfq-mobile" : undefined}
                    value={signature?.fullName?signature?.fullName:''}
                    label={i18n.t('fullName')}
                    disabled={false}
                    onRenderLabel={() => {
                      return (
                        <Label style={isMobile ? styles.mobileFontSize : undefined} >{i18n.t('fullName')}</Label>
                      );
                    }}
                    onChange={(event, newValue)=>{
                      if(newValue){
                        setSignature((prevState) => ({
                          ...prevState,
                          fullName: newValue,
                        }));
                      }else{
                        setSignature((prevState) => ({
                          ...prevState,
                          fullName: null,
                        }));
                      }
                    }}
                    style={isMobile ? styles.textField : undefined}
                  />
                </div>
            </Stack>
          </div>

          <div style={{margin:10}}>
            <div style={{display:'flex',justifyContent: 'space-between'}}>
              <Label style={isMobile ? styles.mobileFontSize : undefined} >{i18n.t('drawYourSignatureHere')}</Label>
              <ActionButton
                allowDisabledFocus
                disabled={clearDisable}
                checked={false}
                onClick={() => {
                  signPad?.current?.clear();
                  setClearDisable(true);
                  setSignature((prevState) => ({
                    ...prevState,
                    signature: null,
                  }));
                  setDisableSave(true);
                  setSignatureBase64('')
                }}
                style={isMobile ? styles.mobileFontSize : undefined}
              >
                {i18n.t('clear')}
              </ActionButton>
            </div>
            <div style={{ border:'1px solid #333333'}}>
            <SignatureCanvas
              penColor="black"
              canvasProps={{
                width: 750,
                height: 200,
                className: 'sigCanvas',
              }}
              dotSize={1}
              ref={signPad}
              onEnd={() => {
                if (!signPad?.current?.isEmpty()) {
                  setClearDisable(false);
                  if (signature.fullName) {
                    setDisableSave(false);
                  }
                  setSignatureBase64(signPad?.current
                    ?.getTrimmedCanvas()
                    .toDataURL('image/png'))
                  setSignature((prevState) => ({
                    ...prevState,
                    signature: signPad?.current
                      ?.getTrimmedCanvas()
                      .toDataURL('image/png'),
                    isDraw:true
                  }));
                }
              }}
            />
            </div>
          </div>

          <Separator styles={{root : {fontSize: isMobile ? 30 : 'initial'}}} alignContent="start">{i18n.t('or')}</Separator>

          <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
            <input
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: 'none' }}
              onChange={(event) => {
                if (
                  event &&
                  event.target &&
                  event.target.files &&
                  event.target.files[0]
                ) {
                  onLinkFileChange(event);
                }
                setProgress(true);
              }}
            />

            <PrimaryButton
              iconProps={addIcon}
              text={i18n.t('upload')}
              disabled={!clearDisable}
              style={isMobile ? styles.acceptButton as any : { marginTop: 0, marginBottom: 30 }}
              onClick={() => {
                inputFile?.current?.click();
              }}
            />

            {!signature?.isDraw && <Image
              src={signature.signature ? signature.signature : ''}
              alt=""
              width={100}
              height={100}
            />}
            {isProgress && (
              <Spinner
                size={SpinnerSize.large}
                style={{ alignSelf: 'flex-start', marginLeft: -60 }}
              />
            )}
          </Stack>
          <div style={{margin:10}}>
            <Label style={isMobile ? styles.mobileFooterText as any : { fontWeight: 'normal' }}>
                {getFooterText()}
            </Label>
          </div>

          <div style={isMobile ? styles.acceptButton as any : {margin:10}}>
            <PrimaryButton
             text={i18n.t('acceptAndSend')}
             onClick={()=>{
               // Removed PDF preview from both mobile and desktop view
               getPdf().then((pdf) => {
                 saveInvitation(pdf);
               })
             }}
             disabled={(!(signature?.signature && signature.fullName && !showRfqAlert))}
             style={isMobile ? styles.acceptButton as any : undefined}
            />
          </div>
          {/*{JSON.stringify(rfqFormData)}*/}
          <PdfModal fullName={signature.fullName} signature={signatureBase64} isOpenPdfModal={isOpenPdfModal} onCloseModal={()=>{setIsOpenPdfModal(false)}} onSave={()=>{
            console.log("PdfModal Save");
            getPdf().then((pdf)=>{
              saveInvitation(pdf)
            })
          }}/>
        </div>
      </div>

      <div className={'card-footer'}>
        <br/>
      </div>

      {/*<PDFViewer style={{ width: '100%', height: '90vh' }}>*/}
      {/*  <PdfTemplate />*/}
      {/*</PDFViewer>*/}

    </div>
  );
};

export default RfqDocumentSign;

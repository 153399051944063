import React, {useEffect} from 'react';
import {
  CommandBarButton,
  FontIcon,
  IButtonStyles,
  IImageProps,
  Image,
  ImageFit,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  registerIcons,
  Stack,
  TextField,
} from '@fluentui/react';
import {getPmolType} from '../../../types/myDayPlanning';
import {SvgIcons} from '../../../shared/svgIcons/svgIcons';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

registerIcons(SvgIcons);
// Non-mutating styles definition
const stackItemStylesStatus: IStackItemStyles = {
  root: {
    alignItems: 'center',
    display: 'flex',
    height: 40,
    minWidth: 30,
    maxWidth: 10,
    marginLeft: 5,
    overflow: 'hidden',
    justifyContent: 'start',
  },
};
const stackItemStylesDot: IStackItemStyles = {
  root: {
    alignItems: 'center',
    display: 'flex',
    height: 40,
    justifyContent: 'end',
    paddingRight: 10,
    paddingBottom: 16
  },
};
const nonShrinkingStackItemStyles: IStackItemStyles = {
  root: {
    alignItems: 'center',
    display: 'flex',
    height: 40,
    justifyContent: 'start',
    overflow: 'hidden',
    width: 500,
  },
};

// Tokens definition
const innerStackTokens: IStackTokens = {
  childrenGap: 1,
};
const styles = {
  logo: {
    marginLeft: 0,
    marginRight: 0,
    width: 20,
    height: 20,
    fontSize: 20,
    color: '#000000'
  },
  dot: {
    width: 10,
    height: 10
  },
  whetherLogo: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 20,
    height: 20,
    fontSize: 14,
    fontWeight: 300,
    marginTop: 9
  },
  resourceLogo: {
    marginLeft: 4,
    marginRight: 'auto',
    width: 20,
    height: 20,
    fontSize: 14,
    fontWeight: 300
  },
  whetherCol: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 30
  },
  letterIcon: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 4,
    color: 'black'
  },
};

const buttonStyles: Partial<IButtonStyles> = {
  root: {
    minWidth: 0,
    padding: '0 4px',
    alignSelf: 'stretch',
    height: 'auto',
  },
};

const iconProps = { iconName: 'MapPin' };

const stackStyles: IStackStyles = {
  root: {
    overflow: 'hidden',
    width: `100%`,
  },
};
const stackStylesResource: IStackStyles = {
  root: {
    overflow: 'auto',
    width: `100%`,
    padding: 10,
  },
};

const imageProps: Partial<IImageProps> = {
  imageFit: ImageFit.centerContain,
  width: 24,
  height: 24,
};

const TREventBody = (props: { pmol: any, overflowItems: any, isVTS?: boolean }) => {
  const { pmol, overflowItems, isVTS } = props;
  // Mutating styles definition
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    // isVTS && console.log(pmol);
  },[]);

  return <div style={{ backgroundColor: '#ffffff', width: '100%', height: 'calc(100% - 25px)', overflow: 'hidden' }}>
    <Stack horizontal styles={stackStyles} tokens={innerStackTokens}>
      <Stack.Item grow styles={stackItemStylesStatus}>
        {isVTS
          ? <FontIcon
            iconName="Nav2DMapView"
            className="ms-Icon"
            style={styles.logo}
          />
          : <div>
            <Image
              {...imageProps}
              style={{ borderRadius: 15 }}
              src={
                localStorage.getItem('logoUrl')
                  ? localStorage.getItem('logoUrl')!!
                  : ''
              }
            />
          </div>}
      </Stack.Item>
      <Stack.Item grow styles={nonShrinkingStackItemStyles}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Label style={{ cursor: 'pointer' }} className={'pmol-title'} onClick={() => { }}>{pmol.title}<br />
            {/* {isDp && <span style={{ fontSize: 9 }}>{pmol?.projectTitle}</span>} */}
          </Label>
        </div>
      </Stack.Item>
      {!isVTS && <Stack.Item grow styles={stackItemStylesStatus}>
        <span style={styles.letterIcon}>{pmol.isDriver ? 'D' : 'P'}</span>
      </Stack.Item>
      }
      {!isVTS && <Stack.Item grow styles={stackItemStylesStatus}>
        <FontIcon
          iconName="DeliveryTruck"
          className="ms-Icon"
          style={styles.logo}
        />
      </Stack.Item>}
      <Stack.Item grow styles={stackItemStylesDot}>
        <CommandBarButton
          ariaLabel="More items"
          styles={buttonStyles}
          onRenderMenuIcon={() => {
            return <FontIcon
              iconName={'dot-menu-' + getPmolType(pmol)}
              className="ms-Icon"
              style={styles.dot}
            />
          }}
          menuProps={{ items: overflowItems! }}
        />
      </Stack.Item>
    </Stack>
    <div style={{ height: 1, backgroundColor: '#f3ede6' }}></div>

    <Stack styles={stackStylesResource} >
      <div style={{ overflow: 'auto', gap: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <TextField
          placeholder={t('startPoint')}
          iconProps={iconProps}
        />
        <TextField
          placeholder={t('destination')}
          iconProps={iconProps}
        />
      </div>
    </Stack>
  </div>
};

export default TREventBody;
import React, {CSSProperties, useRef} from 'react';
import {useDrag} from 'react-dnd';
import moment from 'moment';
import {AcceptType} from '../../types/myDayPlanning';
import {messageService} from '../../services/messageService';

const style: CSSProperties = {
  border: '1px dashed gray',
  backgroundColor: 'white',
  padding: '0.5rem 1rem',
  marginRight: '1.5rem',
  marginBottom: '1.5rem',
  cursor: 'move',
  float: 'left',
}

export interface BoxProps {
  name: string
  dropItem: any
}

interface DropResult {
  name: string
  dropItem: any
}
export const ItemTypes = {
  BOX: 'box',
}

const DragBox = (props: {
  name: string | null;
  item: any;
  type?: any;
  icon?: any;
  onClick?: any;
  disabled?: boolean;
  droppedLabel?: string;
  style?: any;
}) => {
  const [dropped, setDropped] = React.useState(false)
  const nonDragRef = useRef<HTMLDivElement>(null);

  const formatDate = (item: any) => {
    return moment(item?.day).format('DD/MM/YYYY')
  };

  const handleNullValue = (value: any) => {
    return value ? value : 0
  };

  const [collectedProps, dragSourceRef] = useDrag({
    type: props.type ?? formatDate(props.item),
    item: {
      type: props.type ?? formatDate(props.item),
      dragItem: props.item
    },
    end: (item: any, monitor: {didDrop: () => any; getDropResult: () => any; }) => {   // end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      const didDrop = monitor.didDrop();

      if(item.dragItem.fromTeamId && item.type ==  AcceptType.VEHICLE && dropResult === null && !didDrop){
          messageService.sendMessage({ didDrop: didDrop, item: item, isDeleteVehicle: true});
      } else {
          messageService.sendMessage({ didDrop: didDrop});
      }      

      if (item && dropResult) {
        if (item.type == AcceptType.PBS || item.type == AcceptType.PROJECT) {
          setDropped(false);
        } else {
          setDropped(true);
        }
      } else {
        messageService.sendMessage({ didDrop: didDrop, draggedItem: item});
      }
    }
  });

  return (
    dropped ? <div>{props?.droppedLabel?props?.droppedLabel:''}</div> : <div
      onClick={() => { props?.onClick(props?.item) }}
      className={props.disabled ? 'noneDragBox' : 'dragBox'}
      ref={props.disabled ? nonDragRef : dragSourceRef}
      key={props.name ? props.name + Math.random() : Math.random()}
      style={props.style}
    >
      {props?.icon}
      {props.name}
      {props.type == AcceptType.TOOL && `  ${handleNullValue(parseInt(props.item.consumeQuantity))}/${handleNullValue(parseInt(props.item.availableQuantity))}`}
    </div>
  )
}

export default DragBox;

import {
  ActionButton,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Separator,
  Stack,
} from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../theme';

export interface ActionButtonState {
  add: boolean;
  edit: boolean;
  remove: boolean;
}

export const rendercardFooter = (form: any) => {
  const theme = getTheme();

  const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

  const itemAlignmentsStackTokens: IStackTokens = {
    childrenGap: 3,
    padding: 10,
  };

  const iconButtonStyles = {
    root: { color: uPrinceTheme.palette.themePrimary },
    rootHovered: { color: theme.palette.neutralDark },
  };

  const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };

  const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
    fullWidth: { width: '100%' },
    halfWidth: { width: '49%' },
  });

  const { t } = useTranslation();

  return (
    <div className={'card-footer'}>
      <Separator/>
      <Stack
        styles={stackStyles}
        tokens={itemAlignmentsStackTokens}
      >
        <Stack.Item align="end" styles={stackFooterBarItemStyles}>
          <ActionButton
            className={classNames.actionButton}
            iconProps={saveIcon}
            allowDisabledFocus
            onClick={() => {
              // alert(JSON.stringify(values.purchaseOrderType, 0, 2));
              form.submit();
            }}
            // disabled={ (props.dropDownOptions.isApproved) }
          >
            <Label className={classNames.actionButton}>
              {t('save')}
            </Label>
          </ActionButton>
        </Stack.Item>
      </Stack>
    </div>
  );
};

export interface ParamTypes {
  contratingUnitId?: string | undefined;
  featureId?: string | undefined;
  templateId?: string | undefined;
}


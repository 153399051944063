import { useTranslation } from 'react-i18next';
import {
  ContextualMenu,
  FontWeights,
  getTheme,
  IconButton,
  IDragOptions,
  IIconProps,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  TextField,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { uPrinceTheme } from '../../../../theme';
import { Person } from '../../../types/addressBookItem';

const theme = getTheme();
const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: { color: uPrinceTheme.palette.white },
  rootHovered: { color: theme.palette.neutralDark },
};
const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    // minWidth: "30vw",
    // minHeight: "20vh",
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const toggleStyles = { root: { marginBottom: '20px' } };
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: { color: theme.palette.neutralDark },
};

const dropdownStyles = { dropdown: { width: 300, marginTop: 20 } };

const PersonModal = (props: {
  isOpen: boolean;
  personName: string;
  openPersonModel: () => void;
  onAddPerson: (person: Person) => void;
  countries: { key: string; text: string }[];
}) => {
  const { t } = useTranslation();
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
  const titleId = useId('title');
  const theme = getTheme();

  const [person, setPerson] = useState(new Person());
  const [firstNameValidationMsg, setFirstNameValidation] = useState('');
  const [surtNameValidationMsg, setSurNameValidation] = useState('');
  const [surtFullNameValidationMsg, setFullNameValidation] = useState('');

  // personName
  useEffect(() => {
    const firstName = props.personName;
    if (firstName) {
      setPerson((prevState) => ({
        ...prevState,
        firstName: firstName,
      }));
    }
  }, [props.personName]);

  const onChangeNameInput = (field: string) => (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    // UprinceLogger.log(JSON.stringify(field));
    // UprinceLogger.log(JSON.stringify(newValue));
    setPerson((prevState) => ({
      ...prevState,
      [field]: newValue ? newValue : null,
    }));
    if (field === 'firstName') {
      setFirstNameValidation('');
    }
    if (field === 'surname') {
      setSurNameValidation('');
    }
    if (field === 'fullName') {
      setFullNameValidation('');
    }
    // UprinceLogger.log('Company', JSON.stringify(person));
  };

  const validatePerson = (person: Person) => {
    const fistnameValid = validateFistName(person);
    const surNameValid = validateLastName(person);
    const fullNameValid = validateFullName(person);

    return fistnameValid && surNameValid && fullNameValid;
  };

  const validateFistName = (person: Person) => {
    let isFirstNameValid = false;
    if (person && person.firstName && person.firstName !== '') {
      isFirstNameValid = true;
      setFirstNameValidation('');
    } else {
      isFirstNameValid = false;
      setFirstNameValidation(t('firstNameRequired'));
    }

    return isFirstNameValid;
  };

  const validateFullName = (person: Person) => {
    let isFirstNameValid = false;
    if (person && person.fullName && person.fullName !== '') {
      isFirstNameValid = true;
      setFullNameValidation('');
    } else {
      isFirstNameValid = false;
      setFullNameValidation(t('fullNameRequired'));
    }

    return isFirstNameValid;
  };

  const validateLastName = (person: Person) => {
    let isSurNameValid = false;
    if (person && person.surname && person.surname !== '') {
      isSurNameValid = true;
      setSurNameValidation('');
    } else {
      isSurNameValid = false;
      setSurNameValidation(t('surnameRequired'));
    }
    return isSurNameValid;
  };

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          // props.openPersonModel();
        }}
        isBlocking={true}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>{t('addName')}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              setFirstNameValidation('');
              setSurNameValidation('');
              props.openPersonModel();
            }}
          />
        </div>
        { /* <div className={contentStyles.subHeader}>
          <ActionButton iconProps={addIcon} allowDisabledFocus>
            <Label className={contentStyles.actionButtonLabel}>
              {' '}
              {t('add')}
            </Label>
          </ActionButton>
        </div> */}
        <div className={contentStyles.body}>
          <div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                <TextField
                  label={t('firstName')}
                  value={person.firstName}
                  required={true}
                  errorMessage={firstNameValidationMsg}
                  onChange={onChangeNameInput('firstName')}
                />
              </div>
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                <TextField
                  label={t('surname')}
                  required={true}
                  errorMessage={surtNameValidationMsg}
                  onChange={onChangeNameInput('surname')}
                />
              </div>
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                <TextField
                  label={t('fullName')}
                  required={true}
                  errorMessage={surtFullNameValidationMsg}
                  onChange={onChangeNameInput('fullName')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={addIconWhite}
            text={t('save')}
            style={{ marginTop: 20, marginBottom: 10 }}
            onClick={() => {
              if (validatePerson(person)) {
                props.onAddPerson(person);
                setPerson(new Person());
              }
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default PersonModal;

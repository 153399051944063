import { useTranslation } from 'react-i18next';
import {
  CheckboxVisibility, DetailsList, FontWeights,
  getTheme, Icon,
  IconButton, IGroup,
  IIconProps, IStackItemStyles,
  IStackStyles,
  IStackTokens, mergeStyleSets,
  Modal,
  PrimaryButton, ScrollablePane, ScrollbarVisibility, Stack,
  Sticky, StickyPositionType,
} from '@fluentui/react';
import React, { useEffect } from 'react';
import { useId } from '@uifabric/react-hooks';
import { uPrinceTheme } from '../../../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import {
  deleteHumanResource,
  loadAssignedHumanResources,
  saveHRStateAttr,
} from '../../../../reducers/humanResourceReducer';
import { messageService } from '../../../../services/messageService';

const theme = getTheme();

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const addIconWhite: IIconProps = {
  iconName: 'Delete',
  styles: addWhiteIconButtonStyles,
};
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '40vw',
    minHeight: '40vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const WorkerRemoveModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const titleId = useId('title');

  const { isHumanDeleteModelOpen }: any = useSelector((state: any) => state.hr);
  const { assignedHumanResources }: any = useSelector((state: any) => state.hr);
  const workerId: any = useSelector((state: any) => state.hr.formData?.id);
  const personId: any = useSelector((state: any) => state.hr.formData?.personId);
  const personName: any = useSelector((state: any) => state.hr.formData?.personName);

  useEffect(() => {
    if (workerId) {
      dispatch(loadAssignedHumanResources(personId)); // Use personId here
    }
  }, [isHumanDeleteModelOpen]);

  const columns = [
    {
      key: 'pmolTitle',
      name: t('project/pmolTitle'),
      fieldName: 'pmolTitle',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
    },
    { key: 'prTitle', name: t('project/prTitle'), fieldName: 'prTitle', minWidth: 50, maxWidth: 50, isResizable: true },
  ];

  const [pmolGroups, setPmolGroups] = React.useState<IGroup[]>([]);
  const [pmolItems, setPmolItems] = React.useState<any>([]);

  const [prGroups, setPrGroups] = React.useState<IGroup[]>([]);
  const [prItems, setPrItems] = React.useState<any>([]);

  const handleClose = () => {
    dispatch(saveHRStateAttr('isHumanDeleteModelOpen', false));
  };

  useEffect(() => {
    if (assignedHumanResources) {

      let pmolStartIndex = 0;
      const pmolGroups = assignedHumanResources.flatMap((item: any) => {
        if (item.pmol.length > 0) {
          const count = item.pmol.length;
          const group = {
            key: item.project,
            name: item.project,
            startIndex: pmolStartIndex,
            count: count,
            level: 0,
            isCollapsed: true,
          };
          pmolStartIndex += count;
          return group;
        }
        return [];
      });


      let prStartIndex = 0;
      const prGroups = assignedHumanResources.flatMap((item: any) => {
        if (item.pr.length > 0) {
          const count = item.pr.length;
          const group = {
            key: item.project,
            name: item.project,
            startIndex: prStartIndex,
            count: count,
            level: 0,
            isCollapsed: true,
          };
          prStartIndex += count;
          return group;
        }
        return [];
      });

      const newPmolItems = assignedHumanResources.map((item: any) => {
        return item.pmol.map((pmol: any) => {
          return {
            key: pmol.id,
            pmolTitle: pmol.title,
          };
        });
      });

      const newPrItems = assignedHumanResources.map((item: any) => {
        return item.pr.map((pr: any) => {
          return {
            key: pr.id,
            prTitle: pr.title,
          };
        });
      });

      setPmolGroups(pmolGroups);
      setPmolItems(newPmolItems.flat());
      setPrGroups(prGroups);
      setPrItems(newPrItems.flat());
    }
  }, [assignedHumanResources]);

  return (
    <div>
      <Form
        onSubmit={() => {
        }}
        render={({}) => {
          return (
            <form>
              <div>
                <Modal
                  titleAriaId={titleId}
                  isOpen={isHumanDeleteModelOpen}
                  onDismiss={() => {
                    handleClose();
                  }}
                  scrollableContentClassName='body'
                  isBlocking={false}
                  dragOptions={undefined}
                >
                  <div className={contentStyles.header}>
                    <Icon style={{ marginRight: 5 }} iconName={'warning'}></Icon>
                    <span id={titleId}>{t('warning!')}</span>
                    <IconButton
                      styles={cancelIconButtonStyles}
                      iconProps={cancelIcon}
                      ariaLabel='Close popup modal'
                      onClick={() => {
                        handleClose();
                      }}
                    />
                  </div>

                  <div className={contentStyles.body}>
                    <div className='proj-detail-content inner'>
                      <div className='ms-Grid-row'>
                        <Stack
                          horizontal
                          wrap
                          styles={stackStyles}
                          tokens={stackTokens}
                          hidden={true}
                        >
                          {(pmolItems.length > 0 || prItems.length > 0) ?
                            <span style={{
                              color: 'red',
                              paddingTop: 8,
                              fontWeight: 600,
                            }}>
                              "{personName}" {t('isAssignedToPmolsAndPrs')}
                            </span>
                            : <span style={{
                              color: 'red',
                              paddingTop: 8,
                              fontWeight: 600,
                            }}>
                            {t('doYouWantToRemove')} "{personName}" ?
                            </span>}
                        </Stack>

                        <Stack
                          horizontal
                          wrap
                          styles={stackStyles}
                          tokens={stackTokens}
                        >
                          <Stack.Item grow={6} styles={stackItemStyles}>

                            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}
                                            style={{ left: 20, top: 100, bottom: 40, width: '20vw', height: 350 }}>
                              <DetailsList
                                items={pmolItems}
                                checkboxVisibility={CheckboxVisibility.hidden}
                                groups={pmolGroups}
                                columns={columns.slice(0, 1)}
                                ariaLabelForSelectAllCheckbox='Toggle selection for all items'
                                ariaLabelForSelectionColumn='Toggle selection'
                                checkButtonAriaLabel='select row'
                                checkButtonGroupAriaLabel='select section'
                                // onRenderDetailsHeader={this._onRenderDetailsHeader}
                                groupProps={{
                                  showEmptyGroups: false,
                                }}
                                compact={true}
                                onRenderDetailsHeader={(headerProps, defaultRender) => {
                                  if (defaultRender !== undefined) {
                                    return (
                                      <Sticky
                                        stickyPosition={StickyPositionType.Header}
                                        isScrollSynced={true}
                                        stickyBackgroundColor='transparent'
                                      >
                                        <div>{defaultRender(headerProps)}</div>
                                      </Sticky>
                                    );
                                  } else {
                                    return <span></span>;
                                  }
                                }}

                              />
                            </ScrollablePane>

                            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}
                                            style={{ left: 'auto', top: 100, bottom: 40, width: '20vw', height: 350 }}>
                              <DetailsList
                                items={prItems}
                                columns={columns.slice(1, 2)}
                                checkboxVisibility={CheckboxVisibility.hidden}
                                groups={prGroups}
                                ariaLabelForSelectAllCheckbox='Toggle selection for all items'
                                ariaLabelForSelectionColumn='Toggle selection'
                                checkButtonAriaLabel='select row'
                                checkButtonGroupAriaLabel='select section'
                                groupProps={{
                                  showEmptyGroups: true,
                                }}
                                compact={true}
                                onRenderDetailsHeader={(headerProps, defaultRender) => {
                                  if (defaultRender !== undefined) {
                                    return (
                                      <Sticky
                                        stickyPosition={StickyPositionType.Header}
                                        isScrollSynced={true}
                                        stickyBackgroundColor='transparent'
                                      >
                                        <div>{defaultRender(headerProps)}</div>
                                      </Sticky>
                                    );
                                  } else {
                                    return <span></span>;
                                  }
                                }}
                              />
                            </ScrollablePane>

                          </Stack.Item>
                        </Stack>
                      </div>
                    </div>
                  </div>

                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 20,
                    marginTop: 15,
                  }}>
                    <PrimaryButton
                      iconProps={addIconWhite}
                      text={t('remove')}
                      disabled={false}
                      onClick={() => {
                        Promise.all([
                          dispatch(deleteHumanResource(workerId)), // User workerId here
                        ]).then(() => {
                          handleClose();
                          messageService.sendMessage({ hideDocumentPane: true });
                        });
                      }}
                    />

                    <PrimaryButton
                      iconProps={cancelIcon}
                      text={t('cancel')}
                      disabled={false}
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        handleClose();
                      }}
                    />
                  </div>

                </Modal>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};
export default WorkerRemoveModal;
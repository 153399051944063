export class CompetencyListPaneFilter {
  title: string | null = null;
  sorter: Sorter = new Sorter();
}

export interface CompetencyShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
  value: number | null
}

class Sorter {
  attribute: string | null = null;
  order: string | null = null;
}

export class CompetencyListItem {
  id: string | null = null;
  name: string | null = null;
  sequenceId: string | null = null;
  title: string | null = null;
}

export interface DropDown {
  key: string;
  text: string;
}

export interface CompetencyDropDowns {
  competenciesTaxonomyLevel: DropDown[];
  experience: DropDown[];
}

export interface Competency {
  id: string | null;
  title: string;
  sequenceId: string
}

export interface History {
  createdByUser: string | null;
  createdDateTime: string | null;
  revisionNumber: number | string | null;
  updatedByUser: string | null;
  updatedDateTime: string | null;
}

export const formData = {
  id: null,
  title: 'string',
  sequenceId: 'string',
};

export interface CompetenceTaxonomy {
  id: string;
  title: string;
  competenciesId: string;
  parentId?: any;
  competenciesTaxonomyLevelId: string;
  displayOrder: number;
  isChildren: boolean;
  parentCompetenciesId: string;
}

export interface CompetenceTaxonomyDTO {
  id: string;
  title: string;
  competenciesId: string;
  parentId: string | null;
  competenciesTaxonomyLevelId: string;
  parentCompetenciesId?: string;
}

export default interface Competence {
  type: string,
  value: string,
}

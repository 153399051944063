import { Dropdown, IDropdownOption, Label } from '@fluentui/react';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { filterMileStonesListPaneData } from '../../../reducers/mileStonesReducer';
import { saveVPStateAttr } from '../../../reducers/visualPlanReducer';
import CustomDatePicker from '../../../shared/customDatePicker/customDatePicker';
import { ViewMode } from '../../../shared/ganttv2';
import ModeSwitcher from '../../../shared/ganttViewSwitcher/viewSwitcher';
import { formatDateTimeToISOString, getSelectedFilterDates } from '../../../shared/util';

type ViewSwitcherProps = {
  isChecked: boolean;
  onViewListChange: (isChecked: boolean) => void;
  onViewModeChange: (viewMode: ViewMode) => void;
  renderFilterComponent: any;
};
export const ViewSwitcher: React.FC<ViewSwitcherProps> = ({
                                                            isChecked,
                                                            onViewListChange,
                                                            onViewModeChange,
                                                            renderFilterComponent,
                                                          }) => {
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const [startDate, setStartDate] = React.useState<Date | null>(
    null
  );
  const [endDate, setEndDate] = React.useState<Date | null>(
    null
  );
  const { t } = useTranslation();
  const msDropDowns: any = useSelector((state: any) => state.ms.msDropDowns);
  const dispatch = useDispatch();
  const [selectedMode, setSelectedViewMode] = React.useState<ViewMode>(ViewMode.QuaterYear);
  const selectedVPRequestType: any = useSelector(
    (state: any) => state.vp.selectedPOVPshortcutpaneItems,
  );
  const onSubmit = async (values: any) => {
  };

  const standerdFilterOptions = useSelector(
    (state: any) => state.vp.vpFilterDropdown,
  );

  const reloadMSList: any[] = useSelector(
    (state: any) => state.ms.reloadListPane,
  );

  useEffect(() => {
  }, [selectedKeys]);

  useEffect(() => {
    if (reloadMSList) {
      dispatch(filterMileStonesListPaneData({
        typeId:  !_.isEmpty(selectedKeys) ? selectedKeys : [],
        title: null,
        sorter: {
          attribute: null,
          order: null,
        },
        startDate: formatDateTimeToISOString(
          startDate!!,
        ),
        endDate: formatDateTimeToISOString(
          endDate!!,
        ),
      }),);
    }
  }, [reloadMSList]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        height: 70,
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          flexGrow: 0.2,
        }}
      >
        <Form
          onSubmit={onSubmit}
          initialValues={{
            startDate: startDate
              ? startDate
              : null,
            endDate: endDate
              ? endDate
              : null,
          }}
          mutators={{
            setHeaderDocs: (args, state, utils) => {
              const field = state.fields['header.files'];
              field.change(args[0]);
            },
            setDateRange: (args, state, utils) => {
              const from = state.fields['startDate'];
              const to = state.fields['endDate'];
              from.change(getSelectedFilterDates(args)[0]);
              to.change(getSelectedFilterDates(args)[1]);
              dispatch(
                saveVPStateAttr(
                  'scrollPosDateMS',
                  getSelectedFilterDates(args)[0],
                ),
              );

              dispatch(
                filterMileStonesListPaneData({
                  typeId: !_.isEmpty(selectedKeys) ? selectedKeys : [],
                  title: null,
                  sorter: {
                    attribute: null,
                    order: null,
                  },
                  startDate: getSelectedFilterDates(args)[0],
                  endDate: getSelectedFilterDates(args)[1],
                })
              );
            },
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    height: 70,
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                      height: 70,
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        height: 70,
                        width: '100%',
                      }}
                    >
                      <div style={{ width: 180, marginLeft: 20 }}>
                        <Field name="milestoneType">
                          {({ input, meta }) => (
                            <Dropdown
                              placeholder={t('milestoneType')}
                              label={t('milestoneType')}
                              selectedKeys={selectedKeys}
                              // eslint-disable-next-line react/jsx-no-bind
                              onChange={(
                                event: React.FormEvent<HTMLDivElement>,
                                item: IDropdownOption | undefined,
                              ) => {
                                if (item) {
                                  setSelectedKeys(
                                    item.selected
                                      ? [...selectedKeys, item.key as string]
                                      : selectedKeys.filter(
                                      (key) => key !== item.key,
                                      ),
                                  );
                                  let x = values.poType ? values.poType : [];
                                  let y:any[] = [];
                                  if (selectedKeys && item.selected) {
                                    y = [...selectedKeys, item.key];
                                  } else {
                                    y = selectedKeys.filter(
                                      (key: any) => key !== item.key,
                                    );
                                  }

                                  dispatch(
                                    filterMileStonesListPaneData({
                                      typeId: Array.isArray(y) ? y :[],
                                      title: null,
                                      sorter: {
                                        attribute: null,
                                        order: null,
                                      },
                                      startDate: formatDateTimeToISOString(
                                        startDate!!,
                                      ),
                                      endDate: formatDateTimeToISOString(
                                        endDate!!,
                                      ),
                                    }),
                                  );
                                  input.onChange(
                                    item.selected
                                      ? [...selectedKeys, item.key as string]
                                      : selectedKeys.filter(
                                      (key: any) => key !== item.key,
                                      ),
                                  );
                                }
                              }}
                              multiSelect
                              options={
                                msDropDowns?.types ? msDropDowns.types : []
                              }
                            />
                          )}
                        </Field>
                      </div>

                      <div style={{ width: 180, marginLeft: 15 }}>
                        <Field name="date">
                          {({ input, meta }) => (
                            <Dropdown
                              placeholder={t('standardFilter')}
                              label={t('standardFilter')}
                              selectedKey={values.date ? values.date : '5'}
                              // eslint-disable-next-line react/jsx-no-bind
                              onChange={(event, value) => {
                                let date = null;
                                if (value) {
                                  date = value.key;
                                  input.onChange(value.key);
                                } else {
                                  date = null;
                                  input.onChange(null);
                                }
                                form.mutators.setDateRange(value?.key);
                                if (value?.key === '5'){
                                  onViewModeChange(ViewMode.QuaterYear)
                                  setSelectedViewMode(ViewMode.QuaterYear)
                                } else {
                                  onViewModeChange(selectedMode)
                                  setSelectedViewMode(selectedMode)
                                }
                              }}
                              options={
                                standerdFilterOptions
                                  ? standerdFilterOptions
                                  : []
                              }
                            />
                          )}
                        </Field>
                      </div>


                      <div
                        className={'vpDatePicker180'}
                        style={{ width: 180, marginLeft: 15 }}
                      >
                        <Label style={{ width: 180 }}>{t('fromDate')}:</Label>
                        <Field name="startDate">
                          {({ input, meta }) => (
                            <CustomDatePicker
                              setValue={values.startDate}
                              getValue={(date: Date) => {
                                input.onChange(date);
                                setStartDate(date);
                                input.onChange(date);
                                let clDate = new Date(date);
                                let newDate = new Date(
                                  clDate.setDate(clDate.getDate() + 30),
                                );
                                setEndDate(newDate);

                                dispatch(
                                  filterMileStonesListPaneData({
                                    typeId: !_.isEmpty(selectedKeys) ? selectedKeys : [],
                                    title: null,
                                    sorter: {
                                      attribute: null,
                                      order: null,
                                    },
                                    startDate: formatDateTimeToISOString(date),
                                    endDate: formatDateTimeToISOString(newDate),
                                  }),
                                );
                              }}
                            />
                          )}
                        </Field>
                      </div>
                      <div
                        className={'vpDatePicker180'}
                        style={{ width: 180, marginLeft: 15 }}
                      >
                        <Label style={{ width: 180 }}>{t('toDate')}:</Label>
                        <Field name="endDate">
                          {({ input, meta }) => (
                            <CustomDatePicker
                              setMinDate={
                                startDate
                                  ? startDate
                                  : new Date(
                                  form.getFieldState('startDate')?.value,
                                  )
                              }
                              setValue={values.endDate}
                              getValue={(date: Date) => {
                                input.onChange(date);
                                setEndDate(date);
                                dispatch(
                                  filterMileStonesListPaneData({
                                    typeId: !_.isEmpty(selectedKeys) ? selectedKeys : [],
                                    title: null,
                                    sorter: {
                                      attribute: null,
                                      order: null,
                                    },
                                    startDate: formatDateTimeToISOString(
                                      startDate!!,
                                    ),
                                    endDate: formatDateTimeToISOString(
                                      date!!,
                                    ),
                                  }),
                                );
                              }}
                            />
                          )}
                        </Field>
                      </div>

                      <ModeSwitcher
                         onViewModeChange={(t:any)=>{
                          onViewModeChange(t)
                          setSelectedViewMode(t)
                        }}
                        onViewListChange={onViewListChange}
                        isCheck={isChecked}
                        selectedViewMode={selectedMode}
                      />
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};

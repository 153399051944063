import React from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import Login from './components/login';
import {connect} from 'react-redux';
import history from './history';
import PrivateRoute from './privateRoute';
import PermissionDeniedPage from './components/noAccessPage';
import LotInvitation from './components/lotInvitation/lotInvitation';
import LotSubcribe from './components/lotSubcribe/lotSubcribe';
import LotDownload from './components/lotDownload/lotDownload';
import RfqInvitation from './components/rfqInvitation/rfqInvitation';


export interface LoginState {
  user_logged: any;
  user: any;
}

class UnauthenticatedRoutes extends React.Component<any, any> {
  _isMounted = false;
  constructor(props: {}) {
    super(props);
    let user: any;
    user = localStorage.getItem('user') || null;
    // localStorage.setItem('user_logged', 'false');
    this.state = {
      user_logged: localStorage.getItem('user_logged'),
      user: JSON.parse(user) || null,
      languageSelected:
        localStorage.getItem('languageSelected') === 'true' ? true : false
    };
  }

  handlerLogin(user: {}) {
    localStorage.setItem('user_logged', 'true');
    localStorage.setItem('user', JSON.stringify(user));
    if (this._isMounted) {
      this.setState({
        user_logged: 'true',
        user: user
      });
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // handleLanguageSelect = () => {
  //   localStorage.setItem('languageSelected', 'true');
  //   this.setState({
  //     languageSelected: true,
  //   });
  // };

  render() {
    return (
      <main>
        <Router history={ history }>
          <Switch>
            { /* <Route
              path="/"
              exact
              strict
              render={({}) =>
                this.state.user_logged === 'true' ? (
                  <Home />
                ) : this.state.languageSelected === false ? (
                  <LanguageSelect
                    handleLanguageSelect={() => this.handleLanguageSelect()}
                  />
                ) : (
                  <Login handlerLogin={(user: {}) => this.handlerLogin(user)} />
                )
              }
            /> */ }

            { /* <Route path="/" component={Home} exact />*/ }
            <Route
              path="/"
              exact
              render={ ({}) => (
                <Login />
              ) }
            />

            <Route
              path="/login"
              exact
              render={ ({}) => (
                <Login />
              ) }
            />

          <PrivateRoute roles={ [ 'Admin', 'User' ] }
              path="/permission-denied"
              component={ PermissionDeniedPage }
              currentUser={ this.props.currentUser } />

          <Route
              path="/permission--denied"
              component={ PermissionDeniedPage } />

          <Route
              path="/CU/:contratingUnitId/project/:id/lot-invitation/:featureId/:invitationId/:languageId"
              exact
              component={LotInvitation}
            />

            <Route
              path="/CU/:contratingUnitId/project/:id/lot-subscribe/:featureId/:invitationId/:languageId"
              exact
              component={LotSubcribe}
            />

            <Route
              path="/CU/:contratingUnitId/project/:id/download-documents/:featureId/:invitationId/:languageId"
              exact
              component={LotDownload}
            />
            <Route
              path="/CU/:contratingUnitId/rfq-invitation/:featureId"
              exact
              component={RfqInvitation}
            />

          <Route path="*" component={ Login } />

          </Switch>
        </Router>
      </main>
    );
  }
}

const RouteComponent = () => {
  return <Route component={ UnauthenticatedRoutes } />;
};

const mapStateToProps = (state: any) => {
  const { currentUser } = state.uprince;
  return { currentUser: currentUser };
};

export default connect(mapStateToProps, null)(UnauthenticatedRoutes);

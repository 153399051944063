import {
  READ_STANDARD_MAIL_BY_ID_EP,
  READ_STANDARD_MAIL_DROPDOWN_EP,
  READ_STANDARD_MAIL_LIST_EP,
  READ_STANDARD_MAIL_SHORTCUT_PANE_EP,
  STANDARD_MAIL_SAVE_EP,
} from '../shared/endpoints';
import {
  CLEAR_STANDARD_MAIL_FORM_DATA,
  CLEAR_STANDARD_MAILUID,
  LOAD_STANDARD_MAIL_BY_ID,
  LOAD_STANDARD_MAIL_BY_ID_FAIL,
  LOAD_STANDARD_MAIL_BY_ID_SUCCESS,
  LOAD_STANDARD_MAIL_DROPDOWN,
  LOAD_STANDARD_MAIL_DROPDOWN_FAIL,
  LOAD_STANDARD_MAIL_DROPDOWN_SUCCESS,
  LOAD_STANDARD_MAIL_LSITPANE,
  LOAD_STANDARD_MAIL_LSITPANE_FAIL,
  LOAD_STANDARD_MAIL_LSITPANE_SUCCESS,
  LOAD_STANDARD_MAIL_SHORTCUTPANE,
  LOAD_STANDARD_MAIL_SHORTCUTPANE_FAIL,
  LOAD_STANDARD_MAIL_SHORTCUTPANE_SUCCESS,
  RESET_STANDARD_MAIL_IS_CHANGE,
  SAVE_SM_STATE_ATTR,
  SAVE_STANDARD_MAIL,
  SAVE_STANDARD_MAIL_FAIL,
  SAVE_STANDARD_MAIL_SUCCESS,
  SET_STANDARD_MAIL_UID,
} from '../actionTypes/standardMailActionTypes';
import { v4 as uuidv4 } from 'uuid';
import { POFilter, PurchaseOrder } from '../types/purchaseOrder';


const defaultState: any = {
  smList: [],
  isSMListLoaded: false,
  isNew: false,
  isChange: false,
  isEdit: false,
  showProgressBar: false,
  smDropDowns: {},
  formData: {},
  uid: null,
  loadMsg: '',
  smShortCutPaneData: [],
  reloadListPane: false,
  borResources: {},
  isLoadingStandardMail: false,
  smId: null,
};

export default function standardMailReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {
      case LOAD_STANDARD_MAIL_SHORTCUTPANE:
        return { ...state };
      case LOAD_STANDARD_MAIL_SHORTCUTPANE_SUCCESS:
        return {
          ...state,
          smShortCutPaneData: action.payload.data.result,
        };
      case LOAD_STANDARD_MAIL_SHORTCUTPANE_FAIL:
        return { ...state, smShortCutPaneData: [] };
      case LOAD_STANDARD_MAIL_LSITPANE:
        return { ...state, isSMListLoaded: false };
      case LOAD_STANDARD_MAIL_LSITPANE_SUCCESS:
        return {
          ...state,
          isSMListLoaded: true,
          smList: action.payload.data.result,
          reloadListPane: false,
          loadMsg: !action.payload.data.status
            ? action.payload.data.message
            : '',
        };
      case LOAD_STANDARD_MAIL_LSITPANE_FAIL:
        return {
          ...state,
          isSMListLoaded: true,
          smList: [],
          loadMsg: action.error.response.data.message,
        };
      case RESET_STANDARD_MAIL_IS_CHANGE:
        return {
          ...state,
          isChange: false,
        };

      case LOAD_STANDARD_MAIL_DROPDOWN:
        return { ...state, smDropDowns: {} };
      case LOAD_STANDARD_MAIL_DROPDOWN_SUCCESS:
        return { ...state, smDropDowns: action.payload.data.result };
      case LOAD_STANDARD_MAIL_DROPDOWN_FAIL:
        return {
          ...state,
          smDropDowns: {},
        };
      case LOAD_STANDARD_MAIL_BY_ID:
        return { ...state, formData: {}, showProgressBar: true, isLoadingStandardMail: true };
      case LOAD_STANDARD_MAIL_BY_ID_SUCCESS:
        return {
          ...state,
          isEdit: true,
          isClear: false,
          formData: action.payload.data.result,
          showProgressBar: false,
          isLoadingStandardMail: false,
        };
      case LOAD_STANDARD_MAIL_BY_ID_FAIL:
        return {
          ...state,
          formData: {},
          showProgressBar: false,
          isLoadingStandardMail: false,
        };

      case SAVE_STANDARD_MAIL:
        return { ...state, showProgressBar: true, reloadListPane: false, smId: null };
      case SAVE_STANDARD_MAIL_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          reloadListPane: true,
          smId: action.payload.data.result,
        };
      case SAVE_STANDARD_MAIL_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
          smId: null,
        };

      case SET_STANDARD_MAIL_UID:
        return {
          ...state,
          isEdit: false,
          uid: uuidv4(),
        };
      case CLEAR_STANDARD_MAILUID:
        return {
          ...state,
          uid: null,
          isChange: false,
        };
      case CLEAR_STANDARD_MAIL_FORM_DATA:
        return {
          ...state,
          uid: null,
          isEdit: false,
          isNew: true,
          isChange: false,
          formData: {},
        };
      case SAVE_SM_STATE_ATTR:
        const attr = Object.keys(action)[1];
        const value = Object.values(action)[1];
        return {
          ...state,
          [attr]: value,
        };

      default:
        return state;
    }
  }
}

export function saveSMStateAttr(attr: string, value: any) {
  return {
    type: SAVE_SM_STATE_ATTR,
    [attr]: value,
  };
}

export const readStandardMailShortCutPaneData = () => {
  return {
    types: [
      LOAD_STANDARD_MAIL_SHORTCUTPANE,
      LOAD_STANDARD_MAIL_SHORTCUTPANE_SUCCESS,
      LOAD_STANDARD_MAIL_SHORTCUTPANE_FAIL,
    ],
    payload: { request: { url: READ_STANDARD_MAIL_SHORTCUT_PANE_EP } },
  };
};

export const readStandardMailDropDownData = () => {
  return {
    types: [
      LOAD_STANDARD_MAIL_DROPDOWN,
      LOAD_STANDARD_MAIL_DROPDOWN_SUCCESS,
      LOAD_STANDARD_MAIL_DROPDOWN_FAIL,
    ],
    payload: { request: { method: 'GET', url: READ_STANDARD_MAIL_DROPDOWN_EP } },
  };
};


export const filterStandardMailListPaneData = (filter: POFilter) => {
  return {
    types: [
      LOAD_STANDARD_MAIL_LSITPANE,
      LOAD_STANDARD_MAIL_LSITPANE_SUCCESS,
      LOAD_STANDARD_MAIL_LSITPANE_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_STANDARD_MAIL_LIST_EP,
        data: filter,
      },
    },
  };
};


export const readByStandardMailId = (id: string) => {
  return {
    types: [
      LOAD_STANDARD_MAIL_BY_ID,
      LOAD_STANDARD_MAIL_BY_ID_SUCCESS,
      LOAD_STANDARD_MAIL_BY_ID_FAIL],
    payload: { request: { url: READ_STANDARD_MAIL_BY_ID_EP + id } },
  };
};

export const resetIsChange = () => {
  return { type: RESET_STANDARD_MAIL_IS_CHANGE };
};

export const saveFormData = (formData: PurchaseOrder) => {
  return {
    types: [
      SAVE_STANDARD_MAIL,
      SAVE_STANDARD_MAIL_SUCCESS,
      SAVE_STANDARD_MAIL_FAIL],
    payload: {
      request: {
        url: STANDARD_MAIL_SAVE_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const setUid = () => {
  return { type: SET_STANDARD_MAIL_UID };
};

export const clearFormData = () => {
  return { type: CLEAR_STANDARD_MAIL_FORM_DATA };
};

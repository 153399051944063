import React from 'react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  ContextualMenu,
  DetailsListLayoutMode,
  DetailsRow,
  Dropdown,
  FontWeights,
  getTheme,
  IColumn,
  IconButton,
  IDetailsFooterProps,
  IDetailsListProps,
  IDetailsRowStyles,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
  Text,
  TextField,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../../../theme';
import { connect } from 'react-redux';
import {
  FilterItem,
  ListPaneItem,
  PRODUCT_ITEM_TYPE_REG_CONTRACT_ID,
} from '../../../../types/projectBreakdownStructure';

const theme = getTheme();

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const classNames = mergeStyleSets({
  wrapper: {
    height: '80vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: {
    maxWidth: 400,
  },
  textContent: {
    padding: '15px 10px',
  },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: {
    width: '100%',
  },
});

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};


const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    paddingTop: 20,
    overflowY: 'hidden',
    minWidth: '70vw',
    Height: '81vh',
    //minHeight: screen.width > 1280?'42vh':'60vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'none',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };

const ListPaneComponent = (props: {
  isOpen: boolean;
  openModal: () => void;
  resetSelection: () => void;
  onConfirmSelection: () => void;
  handleFilterChange: (filter: FilterItem) => void;
  filter: FilterItem;
  loadMsg: string;
  columns: IColumn[];
  listPaneItem: ListPaneItem[];
  isDataLoaded?: boolean;
  selection: any;
  dropDownOptions: any;
}) => {

  const { t } = useTranslation();
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(true);
  const titleId = useId('title');
  const theme = getTheme();

  const onModalClose = () => {
    props.openModal();
  };
  const _onRenderRow: IDetailsListProps['onRenderRow'] = (row) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (row) {
      return (
        <span
          style={{ textDecoration: 'none' }}
          onClick={() => {
            // props.handleListPaneItemClick(row.item.itemId);
          }}
        >
          <DetailsRow {...row} styles={customStyles}/>
        </span>
      );
    }
    return null;
  };

  const _onRenderDetailsFooter = (
    detailsFooterProps: IDetailsFooterProps | undefined,
  ) => {
    return (
      <DetailsRow
        className="footer-as-filter"
        {...detailsFooterProps}
        columns={detailsFooterProps?.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps?.groupNestingDepth}
        selection={detailsFooterProps?.selection}
        onRenderItemColumn={_renderFilterItemColumn}
      />
    );
  };

  const _renderFilterItemColumn = (
    item?: ListPaneItem,
    index?: number,
    column?: IColumn,
  ) => {
    switch (column?.key) {
      case 'column1': {
        return <div>
          <TextField
            value={(props.filter.title) ? props.filter.title : ''}
            onChange={(event, value) => {
              if (value) {
                props.filter.title = value;
              } else {
                props.filter.title = null;
              }
              props.handleFilterChange(props.filter);

            }}
          />
        </div>;
        break;
      }
      case 'column2': {
        return <div>
          <Dropdown
            isDisabled={true}
            placeholder={t('type')}
            options={props.dropDownOptions.itemTypesFilter}
            selectedKey={
              props.filter.pbsProductItemTypeId
                ? props.filter.pbsProductItemTypeId
                : '0'
            }
            onChange={(event, value) => {
              if (value) {
                props.filter.pbsProductItemTypeId = (value.key != 0) ? value.key.toString() : null;
              } else {
                props.filter.pbsProductItemTypeId = null;
              }
              props.handleFilterChange(props.filter);

            }}
          />
        </div>;
        break;
      }
      case 'column3': {
        return (
          <div>
            <Dropdown
              placeholder={t('projectStatus')}
              options={props.dropDownOptions.productStatesFilter}
              selectedKey={
                props.filter.pbsProductStatusId
                  ? props.filter.pbsProductStatusId
                  : '0'
              }
              onChange={(event, value) => {
                if (value) {
                  props.filter.pbsProductStatusId = (value.key != 0) ? value.key.toString() : null;
                } else {
                  props.filter.pbsProductStatusId = null;
                }
                props.handleFilterChange(props.filter);

              }}
            />
          </div>
        );
        break;
      }
      case 'column4': {
        return (
          <div>
            <Dropdown
              placeholder={t('status')}
              options={props.dropDownOptions.toleranceStatesFilter}
              selectedKey={
                props.filter.pbsToleranceStateId
                  ? props.filter.pbsToleranceStateId
                  : '0'
              }
              onChange={(event, value) => {
                if (value) {
                  props.filter.pbsToleranceStateId = (value.key != 0) ? value.key.toString() : null;
                } else {
                  props.filter.pbsToleranceStateId = null;
                }
                props.handleFilterChange(props.filter);

              }}
            />
          </div>
        );
        break;
      }
      default: {
        break;
      }
    }
  };

  const displayMessage = () => {
    return (
      <div style={{ paddingTop: 36, paddingLeft: 20 }}>
        <Text>{t(props.loadMsg)}</Text>
      </div>
    );
  };

  const onRenderCheckbox = (props: any) => {
    return (
      <div style={{ pointerEvents: 'none' }}>
        <Checkbox checked={props.checked} checkmarkIconProps={{ iconName: 'Add' }}/>
      </div>
    );
  };


  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          onModalClose();
        }}
        scrollableContentClassName="body"
        isBlocking={false}
        dragOptions={isDraggable ? dragOptions : undefined}
        className={'grid-righter column-filter-activated ms-Grid'}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>{t('projectBreakdownStructure')}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              onModalClose();
            }}
          />
        </div>
        <div className={contentStyles.subHeader}>

        </div>

        <div className={contentStyles.body}>
          <div className="proj-detail-content inner">
            <div className={`wrapper-holder ${classNames.wrapper}`}>
              <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <ShimmeredDetailsList
                  items={props.listPaneItem ? props.listPaneItem : []}
                  columns={props.columns}
                  setKey="set"
                  enableShimmer={!props.isDataLoaded}
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionPreservedOnEmptyClick={true}
                  checkboxVisibility={1}
                  onRenderRow={_onRenderRow}
                  onRenderDetailsFooter={_onRenderDetailsFooter}
                  selection={props.selection}
                  selectionMode={props.filter.pbsProductItemTypeId === PRODUCT_ITEM_TYPE_REG_CONTRACT_ID ? SelectionMode.single : SelectionMode.multiple}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                  onRenderDetailsHeader={(headerProps, defaultRender) => {
                    if (defaultRender !== undefined) {
                      return (
                        <Sticky
                          stickyPosition={StickyPositionType.Header}
                          isScrollSynced={true}
                          stickyBackgroundColor="transparent"
                        >
                          <div>{defaultRender(headerProps)}</div>
                        </Sticky>
                      );
                    } else {
                      return <span></span>;
                    }
                  }}
                  onRenderCheckbox={onRenderCheckbox}
                />
                {props.isDataLoaded && displayMessage()}
              </ScrollablePane>
            </div>
          </div>
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton

            text={t('reset')}
            style={{ marginTop: 20, marginBottom: 30, marginRight: 10 }}
            onClick={() => props.resetSelection()}
          />
          <PrimaryButton

            text={t('confirm')}
            //disabled={this.state.selectedCostList.length===0? true:false}
            style={{ marginTop: 20, marginBottom: 30 }}
            onClick={() => props.onConfirmSelection()}
          />
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    toleranceStatesFilter: state.billOfResource.toleranceStatesFilter,
    productStatesFilter: state.billOfResource.productStatesFilter,
    itemTypesFilter: state.billOfResource.itemTypesFilter,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ListPaneComponent);

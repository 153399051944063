import * as React from 'react';
import PersonPicker from '../../../../shared/fluentUI9/personPicker/PersonPicker';
import { CAB_FILTER_EP } from '../../../../shared/endpoints';
import { Button } from '@fluentui/react-components';
import { Field, Form } from 'react-final-form';
import i18n from 'i18next';

interface Props {
  onAddPerson: any;
  formData: any;
  btnLabel: any;
}

const PopoverBody = (props: Props) => {
  const { onAddPerson, formData, btnLabel } = props;

  const onSubmit = async (values: any) => {
    if (values && values?.to && values?.to?.length > 0) {
      console.log(values?.to);
      const newData = values?.to?.map((person: any) => ({ ...person, key: person?.personId }));
      onAddPerson({ key: 'to' }, values?.to);
    }
    if (values && values?.cc && values?.cc?.length > 0) {
      onAddPerson({ key: 'cc' }, values?.cc);
    }
  };

  const getInitialFormValues = () => {

    return { to: [], cc: [] };
  };

  const validate = (values: any) => {
    const errors: any = {};

    if (values?.to?.some((ar1: any) => formData?.to?.some((ar2: any) => ar1.key === ar2.key))) {
      errors.to = i18n.t('alreadyAssigned');
    }

    if (values?.cc?.some((ar1: any) => formData?.cc?.some((ar2: any) => ar1.key === ar2.key))) {
      errors.cc = i18n.t('alreadyAssigned');
    }

    return errors;
  };

  const getSelectedKeyForPerson = (selectedPersons: any) => {
    return selectedPersons.map((person: any) => {
      return person?.key;
    });
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={getInitialFormValues()}
        validate={validate}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Field name='to'>
                {({ input, meta }) => (
                  <PersonPicker
                    endpoint={CAB_FILTER_EP}
                    placeholder={i18n.t('to')}
                    value={getSelectedKeyForPerson(values?.to)}
                    onChange={(value: any) => {
                      input.onChange(value);
                    }}
                    errorMessage={meta.error}
                  />
                )}
              </Field>

              <Field name='cc'>
                {({ input, meta }) => (
                  <PersonPicker
                    endpoint={CAB_FILTER_EP}
                    placeholder={i18n.t('cc')}
                    value={getSelectedKeyForPerson(values?.cc)}
                    onChange={(value: any) => {
                      input.onChange(value);
                    }}
                    errorMessage={meta.error}
                  />
                )}
              </Field>
              <div style={{ marginTop: 12, float: 'right' }}>
                <Button onClick={() => form.submit()}>
                  {btnLabel}
                </Button>
              </div>

              {/*<div style={{ width: '100%', height: 250, overflow: 'auto' }}>*/}
              {/*  <pre>  {JSON.stringify(values, null, 2)} </pre>*/}
              {/*</div>*/}

            </form>
          );
        }}
      />
    </>
  );
};

export default PopoverBody;
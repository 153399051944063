import * as React from 'react';
import { useEffect, useState } from 'react';
import TitlePane from '../../../shared/titlePane/titlePane';
import { WBS_ADD_ICON_MENU } from '../../../types/wbs';
import { useTranslation } from 'react-i18next';
import CreateWbsDocumentPane from './CreateWbsDocumentPane';
import CreateProductDocumentPane from './CreateProductDocumentPane';
import CreateTaskDocumentPane from './CreateTaskDocumentPane';
import { useDispatch, useSelector } from 'react-redux';
import CreateIssueDocumentPane from './CreateIssueDocumentPane';

interface Props {
  isAddNew: any;
}

const AddNewDocumentPaneWrapper = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const wbsProductFormData = useSelector((state: any) => state.wbsCreate.wbsProductFormData);
  const formData = useSelector((state: any) => state.wbsTask.formData);
  const issueFormData = useSelector((state: any) => state.wbsIssue.formData);

  const [wbsData, setWbsData]: any = useState(null);

  useEffect(() => {
    setWbsData(null);
  }, [props?.isAddNew]);

  const renderDocumentPaneTitle = () => {
    if (props?.isAddNew?.createNew === WBS_ADD_ICON_MENU.WBS) {
      return wbsData ? wbsData?.title : t('addNewWbs');
    } else if (props?.isAddNew?.createNew === WBS_ADD_ICON_MENU.PRODUCT) {
      return wbsProductFormData?.productId ? `${wbsProductFormData?.productId} - ${wbsProductFormData?.name}` : t('addNewProduct');
    } else if (props?.isAddNew?.createNew === WBS_ADD_ICON_MENU.TASK) {
      return formData?.name ? formData?.name : t('addNewTask');
    } else if (props?.isAddNew?.createNew === WBS_ADD_ICON_MENU.ISSUE) {
      return issueFormData?.title ?? t('addNewIssue');
    }
  };

  const renderDocumentPane = () => {
    if (props?.isAddNew?.createNew === WBS_ADD_ICON_MENU.WBS) {
      return <CreateWbsDocumentPane selectedItem={props?.isAddNew} onSaveWBs={(value: any) => {
        setWbsData(value);
      }} />;
    } else if (props?.isAddNew?.createNew === WBS_ADD_ICON_MENU.PRODUCT) {
      return <CreateProductDocumentPane isAddNew={props?.isAddNew} />;
    } else if (props?.isAddNew?.createNew === WBS_ADD_ICON_MENU.TASK) {
      return <CreateTaskDocumentPane isAddNew={props?.isAddNew} />;
    } else if (props?.isAddNew?.createNew === WBS_ADD_ICON_MENU.ISSUE) {
      return <CreateIssueDocumentPane isAddNew={props?.isAddNew} />;
    }
  };

  return (
    <>
      <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
        <TitlePane
          headerTitle={renderDocumentPaneTitle()}
          type={1}
          close={true}
          isFullScreen={true}
        />
        <div style={{ height: 'calc(100% - 30px)', width: '100%', display: 'flex', overflow: 'hidden' }}>
          {renderDocumentPane()}
        </div>
      </div>
    </>
  );
};

export default AddNewDocumentPaneWrapper;

import React from 'react';

const MyCalBottomShortcutPane = () => {
    return <div>
        <div className={`projectDayPlanning  filter-panel-left wrapper`}>
            <div className="inner-container">

            </div>
        </div>
    </div>
};

export default MyCalBottomShortcutPane;
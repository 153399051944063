export const SAVE_PROJECT_DAY_PLANNING_STATE_ATTR = 'uprice/SAVE_PROJECT_DAY_PLANNING_STATE_ATTR';

export const LOAD_PROJECT_DAY_PLANNING_SHORTCUTPANE = 'uprince/LOAD_PROJECT_DAY_PLANNING_SHORTCUTPANE';
export const LOAD_PROJECT_DAY_PLANNING_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_PROJECT_DAY_PLANNING_SHORTCUTPANE_SUCCESS';
export const LOAD_PROJECT_DAY_PLANNING_SHORTCUTPANE_FAIL = 'uprince/LOAD_PROJECT_DAY_PLANNING_SHORTCUTPANE_FAIL';

export const LOAD_PROJECT_DAY_PLANNINNG_PROJECT_LIST = 'uprince/LOAD_PROJECT_DAY_PLANNINNG_PROJECT_LIST';
export const LOAD_PROJECT_DAY_PLANNINNG_PROJECT_LIST_SUCCESS = 'uprince/LOAD_PROJECT_DAY_PLANNINNG_PROJECT_LIST_SUCCESS';
export const LOAD_PROJECT_DAY_PLANNINNG_PROJECT_LIST_FAIL = 'uprince/LOAD_PROJECT_DAY_PLANNINNG_PROJECT_LIST_FAIL';

export const LOAD_PROJECT_DAY_PLANNINNG_PMOL_LIST = 'uprince/LOAD_PROJECT_DAY_PLANNINNG_PMOL_LIST';
export const LOAD_PROJECT_DAY_PLANNINNG_PMOL_LIST_SUCCESS = 'uprince/LOAD_PROJECT_DAY_PLANNINNG_PMOL_LIST_SUCCESS';
export const LOAD_PROJECT_DAY_PLANNINNG_PMOL_LIST_FAIL = 'uprince/LOAD_PROJECT_DAY_PLANNINNG_PMOL_LIST_FAIL';

export const LOAD_PROJECT_DAY_PLANNINNG_RESOURCE_LIST = 'uprince/LOAD_PROJECT_DAY_PLANNINNG_RESOURCE_LIST';
export const LOAD_PROJECT_DAY_PLANNINNG_RESOURCE_LIST_SUCCESS = 'uprince/LOAD_PROJECT_DAY_PLANNINNG_RESOURCE_LIST_SUCCESS';
export const LOAD_PROJECT_DAY_PLANNINNG_RESOURCE_LIST_FAIL = 'uprince/LOAD_PROJECT_DAY_PLANNINNG_RESOURCE_LIST_FAIL';

export const ASSIGN_PMOL_TO_PROJECT = 'uprince/ASSIGN_PMOL_TO_PROJECT';
export const ASSIGN_PMOL_TO_PROJECT_SUCCESS = 'uprince/ASSIGN_PMOL_TO_PROJECT_SUCCESS';
export const ASSIGN_PMOL_TO_PROJECT_FAIL = 'uprince/ASSIGN_PMOL_TO_PROJECT_FAIL';

export const RESIZE_PMOL_ON_PROJECT = 'uprince/RESIZE_PMOL_ON_PROJECT';
export const RESIZE_PMOL_ON_PROJECT_SUCCESS = 'uprince/RESIZE_PMOL_ON_PROJECT_SUCCESS';
export const RESIZE_PMOL_ON_PROJECT_FAIL = 'uprince/RESIZE_PMOL_ON_PROJECT_FAIL';

export const ASSIGN_TEAM_TO_PROJECT = 'uprince/ASSIGN_TEAM_TO_PROJECT';
export const ASSIGN_TEAM_TO_PROJECT_SUCCESS = 'uprince/ASSIGN_TEAM_TO_PROJECT_SUCCESS';
export const ASSIGN_TEAM_TO_PROJECT_FAIL = 'uprince/ASSIGN_TEAM_TO_PROJECT_FAIL';

export const ASSIGN_TEAM_TO_PMOL = 'uprince/ASSIGN_TEAM_TO_PMOL';
export const ASSIGN_TEAM_TO_PMOL_SUCCESS = 'uprince/ASSIGN_TEAM_TO_PMOL_SUCCESS';
export const ASSIGN_TEAM_TO_PMOL_FAIL = 'uprince/ASSIGN_TEAM_TO_PMOL_FAIL';

export const ASSIGN_VEHICLE_TO_PROJECT = 'uprince/ASSIGN_VEHICLE_TO_PROJECT';
export const ASSIGN_VEHICLE_TO_PROJECT_SUCCESS = 'uprince/ASSIGN_VEHICLE_TO_PROJECT_SUCCESS';
export const ASSIGN_VEHICLE_TO_PROJECT_FAIL = 'uprince/ASSIGN_VEHICLE_TO_PROJECT_FAIL';

export const ASSIGN_VEHICLE_TO_PMOL = 'uprince/ASSIGN_VEHICLE_TO_PMOL';
export const ASSIGN_VEHICLE_TO_PMOL_SUCCESS = 'uprince/ASSIGN_VEHICLE_TO_PMOL_SUCCESS';
export const ASSIGN_VEHICLE_TO_PMOL_FAIL = 'uprince/ASSIGN_VEHICLE_TO_PMOL_FAIL';

export const CLONE_PMOL_PROJECT_DAY_PLANNING = 'uprince/CLONE_PMOL_PROJECT_DAY_PLANNING';
export const CLONE_PMOL_PROJECT_DAY_PLANNING_SUCCESS = 'uprince/CLONE_PMOL_PROJECT_DAY_PLANNING_SUCCESS';
export const CLONE_PMOL_PROJECT_DAY_PLANNING_FAIL = 'uprince/CLONE_PMOL_PROJECT_DAY_PLANNING_FAIL';

export const LOAD_BU_DROPDOWN_DATA = 'uprince/LOAD_BU_DROPDOWN_DATA';
export const LOAD_BU_DROPDOWN_DATA_SUCCESS = 'uprince/LOAD_BU_DROPDOWN_DATA_SUCCESS';
export const LOAD_BU_DROPDOWN_DATA_FAIL = 'uprince/LOAD_BU_DROPDOWN_DATA_FAIL';

export const GENERATE_RFQ = 'uprince/GENERATE_RFQ';
export const GENERATE_RFQ_SUCCESS = 'uprince/GENERATE_RFQ_SUCCESS';
export const GENERATE_RFQ_FAIL = 'uprince/GENERATE_RFQ_FAIL';

export const GENERATE_CIAW = 'uprince/GENERATE_CIAW';
export const GENERATE_CIAW_SUCCESS = 'uprince/GENERATE_CIAW_SUCCESS';
export const GENERATE_CIAW_FAIL = 'uprince/GENERATE_CIAW_FAIL';

export const DELETE_LABOUR_PROJECT_DAY_PLANNING = 'uprince/DELETE_LABOUR_PROJECT_DAY_PLANNING';
export const DELETE_LABOUR_PROJECT_DAY_PLANNING_SUCCESS = 'uprince/DELETE_LABOUR_PROJECT_DAY_PLANNING_SUCCESS';
export const DELETE_LABOUR_PROJECT_DAY_PLANNING_FAIL = 'uprince/DELETE_LABOUR_PROJECT_DAY_PLANNING_FAIL';

export const UPDATE_PLAN_BOARDS_PMOL_STATUS = 'uprince/UPDATE_PLAN_BOARDS_PMOL_STATUS';
export const UPDATE_PLAN_BOARDS_PMOL_STATUS_SUCCESS = 'uprince/UPDATE_PLAN_BOARDS_PMOL_STATUS_SUCCESS';
export const UPDATE_PLAN_BOARDS_PMOL_STATUS_FAIL = 'uprince/UPDATE_PLAN_BOARDS_PMOL_STATUS_FAIL';

export const GET_ORG_TEAMS_TAXONOMY = 'uprince/GET_ORG_TEAMS_TAXONOMY';
export const GET_ORG_TEAMS_TAXONOMY_SUCCESS = 'uprince/GET_ORG_TEAMS_TAXONOMY_SUCCESS';
export const GET_ORG_TEAMS_TAXONOMY_FAIL = 'uprince/GET_ORG_TEAMS_TAXONOMY_FAIL';

export const GET_BU_PERSON_WITH_COMPETENCE = 'uprince/GET_BU_PERSON_WITH_COMPETENCE';
export const GET_BU_PERSON_WITH_COMPETENCE_SUCCESS = 'uprince/GET_BU_PERSON_WITH_COMPETENCE_SUCCESS';
export const GET_BU_PERSON_WITH_COMPETENCE_FAIL = 'uprince/GET_BU_PERSON_WITH_COMPETENCE_FAIL';

export const ADD_MULTIPLE_MEMBERS_TO_PMOL = 'uprince/ADD_MULTIPLE_MEMBERS_TO_PMOL';
export const ADD_MULTIPLE_MEMBERS_TO_PMOL_SUCCESS = 'uprince/ADD_MULTIPLE_MEMBERS_TO_PMOL_SUCCESS';
export const ADD_MULTIPLE_MEMBERS_TO_PMOL_FAIL = 'uprince/ADD_MULTIPLE_MEMBERS_TO_PMOL_FAIL';

export const UPDATE_PLAN_BOARDS_PMOL_TITLE = 'uprince/UPDATE_PLAN_BOARDS_PMOL_TITLE';
export const UPDATE_PLAN_BOARDS_PMOL_TITLE_SUCCESS = 'uprince/UPDATE_PLAN_BOARDS_PMOL_TITLE_SUCCESS';
export const UPDATE_PLAN_BOARDS_PMOL_TITLE_FAIL = 'uprince/UPDATE_PLAN_BOARDS_PMOL_TITLE_FAIL';

export const GET_VEHICLE_WITH_RESOURCE = 'uprince/GET_VEHICLE_WITH_RESOURCE';
export const GET_VEHICLE_WITH_RESOURCE_SUCCESS = 'uprince/GET_VEHICLE_WITH_RESOURCE_SUCCESS';

export const GET_HTML_PRINT_DATA = 'uprince/GET_HTML_PRINT_DATA';
export const GET_HTML_PRINT_DATA_SUCCESS = 'uprince/GET_HTML_PRINT_DATA_SUCCESS';
export const GET_HTML_PRINT_DATA_FAIL = 'uprince/GET_HTML_PRINT_DATA_FAIL';
export const GET_VEHICLE_WITH_RESOURCE_FAIL = 'uprince/GET_VEHICLE_WITH_RESOURCE_FAIL';

export const GET_TOOL_WITH_RESOURCE = 'uprince/GET_TOOL_WITH_RESOURCE';
export const GET_TOOL_WITH_RESOURCE_SUCCESS = 'uprince/GET_TOOL_WITH_RESOURCE_SUCCESS';
export const GET_TOOL_WITH_RESOURCE_FAIL = 'uprince/GET_TOOL_WITH_RESOURCE_FAIL';
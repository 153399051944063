import * as React from 'react';
import { useEffect } from 'react';
import {
  createTableColumn,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  Link,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  TableColumnDefinition,
} from '@fluentui/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import history from '../../../history';
import { getContractingUnit } from '../../../shared/util';
import { readWbsCreateListData, saveWbsCreateStateAttr } from '../../../reducers/wbsCreateReducer';
import { WbsCreateListPaneFilter } from '../../../types/wbs';
import { ParamTypes } from '../../../types/uPrince';
import { useParams } from 'react-router';

// type FileCell = {
//   label: string;
//   icon: JSX.Element;
// };

type Item = {
  id: string,
  title: string,
  name: string,
  sequenceCode: string,
  isDelete: boolean,
  isDefault: boolean,
  createdDateTime: string,
  updatedDateTime: string,
  createdBy: string,
  updatedBy: string,
};

const columnSizingOptions = {
  file: {
    minWidth: 80,
    defaultWidth: 120,
  },
  author: {
    defaultWidth: 180,
    minWidth: 120,
    idealWidth: 180,
  },
};

export const ListComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { featureId }: ParamTypes = useParams();
  const refMap = React.useRef<Record<string, HTMLElement | null>>({});

  const wbsListData = useSelector((state: any) => state.wbsCreate.wbsListData);
  const isTemplateCreated = useSelector((state: any) => state.wbsCreate.isTemplateCreated);
  const templateId = useSelector((state: any) => state.wbsCreate.templateId);

  useEffect(() => {

    return () => {
      console.log('unmounting wbs list pane');
    };
  }, []);

  useEffect(() => {
    if (isTemplateCreated && templateId) {
      refreshListPane();
      history.push(`/CU/${getContractingUnit()}/wbs-create/${templateId}`);
    }

    return () => {
      dispatch(saveWbsCreateStateAttr('isTemplateCreated', false));
    };
  }, [isTemplateCreated]);

  const handleListTitleClick = (item: any) => {
    // console.log('title click data>>> ', item);
    history.push(`/CU/${getContractingUnit()}/wbs-create/${item.id}/template`);
  };

  /* This runs also on handleListTitleClick
     e.target.nodeName logic is to control this */
  const handleSelectionChange = (e: any, data: any) => {
    if (e.target.nodeName === 'DIV') {
      const featureId = Array.from(data.selectedItems)[0];

      history.push(`/CU/${getContractingUnit()}/wbs-create/${featureId}`);
    }
  };

  // const handleFilterChange = (filter: WbsCreateListPaneFilter) => {
  //   // console.log(filter)
  //   if (filter.title !== '') {
  //     setFilter((prevState: any) => ({
  //       ...prevState,
  //       title: filter.title ?? null,
  //     }));
  //     dispatch(readWbsCreateListData(filter));
  //   }
  // };

  const refreshListPane = () => {
    dispatch(readWbsCreateListData(new WbsCreateListPaneFilter()));
  };

  const columns: TableColumnDefinition<Item>[] = [
    createTableColumn<Item>({
      columnId: 'title',
      compare: (a, b) => {
        return a?.title?.localeCompare(b?.title);
      },
      renderHeaderCell: () => {
        return `${t('title')}`;
      },
      renderCell: (item) => {
        return (
          <Link appearance='subtle' onClick={() => handleListTitleClick(item)}>{item?.title}</Link>
        );
      },
    }),
  ];

  return (
    <div style={{ height: 'calc(100vh - 184px)' }}>
      <DataGrid
        items={wbsListData ?? []}
        columns={columns}
        sortable
        getRowId={(item) => item?.id}
        selectionMode='single'
        resizableColumns
        columnSizingOptions={columnSizingOptions}
        size='small'
        onSelectionChange={handleSelectionChange}
        selectedItems={featureId ? [featureId] : []}
      >
        <DataGridHeader>
          <DataGridRow selectionCell={{ 'aria-label': 'Select all rows' }}>
            {({ renderHeaderCell, columnId }, dataGrid) =>
              dataGrid.resizableColumns ? (
                <Menu openOnContext>
                  <MenuTrigger>
                    <DataGridHeaderCell
                      ref={(el) => (refMap.current[columnId] = el)}
                    >
                      {renderHeaderCell()}
                    </DataGridHeaderCell>
                  </MenuTrigger>
                  <MenuPopover>
                    <MenuList>
                      <MenuItem
                        onClick={dataGrid.columnSizing_unstable.enableKeyboardMode(
                          columnId,
                        )}
                      >
                        Keyboard Column Resizing
                      </MenuItem>
                    </MenuList>
                  </MenuPopover>
                </Menu>
              ) : (
                <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
              )
            }
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody<Item>>
          {({ item, rowId }) => (
            <DataGridRow<Item>
              key={rowId}
              selectionCell={{ 'aria-label': 'Select row' }}
            >
              {({ renderCell }) => (
                <DataGridCell>{renderCell(item)}</DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
    </div>
  );
};

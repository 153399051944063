import React, { Component } from 'react';
import UprinceNavigation from '../../shared/uprinceNavigation/cuProjectNavigation/component';
import QualityMainLayout from './qualityMainLayout';
import { getContractingUnit, getProject } from '../../shared/util';
//import Brand from '../../shared/brand/brand';
//import ProjectBar from '../../shared/projectBar/projectBar';
//import ProjectNavBar from '../../shared/navBar/projectNavBar';


interface Props {
  currentUser: any
}

interface State {
  isContractUnit: boolean;
  isProject: boolean;
}

export class Quality extends Component<Props, State> {
  render() {
    return (

      <UprinceNavigation

        currentUser={this.props.currentUser}

        selectedNavigationIndex={`/CU/${getContractingUnit()}/project/${getProject()}/quality`}

      >

        <QualityMainLayout/>

      </UprinceNavigation>

    );


  }
}

export default (Quality);

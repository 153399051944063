import moment from 'moment';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import {Text} from "@fluentui/react";

interface Props {
    data: any,
}

export default function BurnDownChart(props: Props) {
    const {t} = useTranslation();

    return (
      <div>
          {props?.data?.title && <Text>{t('week')} {moment().isoWeek()} - {props.data.title}</Text>}
          <ResponsiveContainer height={250} width={400}>
              <LineChart data={props?.data?.data} margin={{right: 25, top: 10}}>
                  <CartesianGrid vertical={false} strokeDasharray="0"/>
                  <XAxis dataKey="name" type="category" allowDuplicatedCategory={false}
                         padding={{left: 50, right: 25}}/>
                  <YAxis/>
                  <ReferenceLine x={`Day ${new Date().getDay()}`} stroke="red" label=""/>
                  <Tooltip/>
                  <Legend/>
                  <Line type="monotone" dataKey="planned" name="Remaining Capacity" stroke="#82ca9d" fill="#82ca9d" strokeDasharray="5 5"/>
                  <Line type="monotone" dataKey="consumed" name="Planned Capacity" stroke="#8884d8" fill="#8884d8"/>
              </LineChart>
          </ResponsiveContainer>
      </div>
    );
}
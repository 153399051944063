import React from 'react';
import { TaskItemProps } from '../task-item';
import styles from './milestone.module.css';

export const MS_SCOPE = 'lkkab9fe-msms-4088-82a9-d27008688lkk'
export const MS_FINACE = 'fg10e768-msms-po02-b337-ee367a82afff'
export const MS_QUALITY = 'oo10e768-msms-po02-b337-ee367a82adooo'
export const MS_RISK = '2210e768-msms-po02-b337-ee367a82ad22'

export const Milestone: React.FC<TaskItemProps> = ({
                                                     task,
                                                     isDateChangeable,
                                                     onEventStart,
                                                     isSelected,
                                                   }) => {
  const transform = `rotate(45 ${task.x1 + task.height * 0.356} 
    ${task.y + task.height * 0.85})`;
  const getBarColor = () => {
    return isSelected
      ? task.styles.backgroundSelectedColor
      : task.styles.backgroundColor;
  };

  const getMSIcon = (task:any) => {
      switch(task?.msTypeId){
        case MS_SCOPE:
          return "https://uprincev4uat.blob.core.windows.net/uprincev4uat/env/scope.png";
        case MS_FINACE:
          return "https://uprincev4uat.blob.core.windows.net/uprincev4uat/env/euro.png";
        case MS_QUALITY:
          return "https://uprincev4uat.blob.core.windows.net/uprincev4uat/env/shield.png";
        case MS_RISK:
          return "https://uprincev4uat.blob.core.windows.net/uprincev4uat/env/risk.png";
      }
  }


  return (
    <g tabIndex={0} className={styles.milestoneWrapper}>
      {/* <rect
        fill={getBarColor()}
        x={task.x1}
        points="100,100 150,25 150,75 200,0"
        width={task.height}
        y={task.y}
        height={task.height}
        rx={task.barCornerRadius}
        ry={task.barCornerRadius}
        transform={transform}
        className={styles.milestoneBackground}
        onMouseDown={e => {
          isDateChangeable && onEventStart('move', task, e);
        }}
      />  */}

      <image href={getMSIcon(task)}  x={task.x1} y={task.y} height="20" width="20"/>
      
    </g>
  );
};

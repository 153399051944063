import React from 'react';
import { connect } from 'react-redux';
import {
  DetailsListLayoutMode,
  DetailsRow,
  Fabric,
  IColumn,
  IDetailsGroupRenderProps,
  IDetailsListProps,
  IDetailsRowStyles,
  IGroup,
  MarqueeSelection,
  mergeStyleSets,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
  Text,
} from '@fluentui/react';
import { CABTableColumn } from '../../../shared/tableColumn/tableColumn';
import { _renderCABDetailsFooterItemColumn } from '../filterHeader';
import { AddressBookItem } from '../../../types/addressBookItem';
import { uPrinceTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const ROW_HEIGHT: number = 42; // from DEFAULT_ROW_HEIGHTS in DetailsRow.styles.ts
const GROUP_HEADER_AND_FOOTER_SPACING: number = 8;
const GROUP_HEADER_AND_FOOTER_BORDER_WIDTH: number = 1;
const GROUP_HEADER_HEIGHT: number = 95;
const GROUP_FOOTER_HEIGHT: number =
  GROUP_HEADER_AND_FOOTER_SPACING * 4 +
  GROUP_HEADER_AND_FOOTER_BORDER_WIDTH * 2;

const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 264px)!important',
    position: 'relative',
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: {
    margin: 0,
  },
  row: {
    display: 'inline-block',
  },
  headerAndFooter: {
    borderTop: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${uPrinceTheme.palette.neutralQuaternary}`,
    borderBottom: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${uPrinceTheme.palette.neutralQuaternary}`,
    padding: GROUP_HEADER_AND_FOOTER_SPACING,
    margin: 0,//`${GROUP_HEADER_AND_FOOTER_SPACING}px 0`,
    background: uPrinceTheme.palette.neutralLighterAlt,
    // Overlay the sizer bars
    position: 'relative',
    zIndex: 100,
  },
  headerTitle: [
    uPrinceTheme.fonts.xLarge,
    {
      padding: '4px 0',
    },
  ],
  headerLinkSet: {
    margin: '4px -8px',
  },
  headerLink: {
    margin: '0 8px',
  },
});

export const ListPaneComponent = ({
                                    columns,
                                    selection,
                                    addressList,
                                    onAddressItemClick,
                                    isLoading,
                                    cabFilter,
                                    onChangeCABFilter,
                                    onChangeCABFilterStatus,
                                    status,
                                    message,
                                    cabType,
                                    groups,
                                  }: any) => {
  const _onRenderGroupFooter: IDetailsGroupRenderProps['onRenderFooter'] = (
    props,
  ) => {
    if (props) {
      return (
        <div className={classNames.headerAndFooter}>
          <em>{`Custom footer for ${props.group!.name}`}</em>
        </div>
      );
    }

    return null;
  };

  const { t } = useTranslation();

  const _onRenderGroupHeader: IDetailsGroupRenderProps['onRenderHeader'] = (
    props,
  ) => {
    if (props) {
      return (
        <div className={classNames.headerAndFooter}>
          <div className={classNames.headerTitle}>{` ${
            props.group!.name
          }`}</div>
          <div className={classNames.headerLinkSet}>
            {/* <Link className={classNames.headerLink} onClick={() => {}}>
              {props.selected ? 'Remove selection' : 'Select group'}
            </Link>
            <Link className={classNames.headerLink} onClick={() => {}}>
              {props.group!.isCollapsed ? 'Expand group' : 'Collapse group'}
            </Link> */}
          </div>
        </div>
      );
    }

    return null;
  };

  const _getGroupTotalRowHeight = (group: IGroup): number => {
    return group.isCollapsed ? 0 : ROW_HEIGHT * group.count;
  };

  const _getGroupHeight = (group: IGroup, _groupIndex: number): number => {
    return (
      GROUP_HEADER_HEIGHT + GROUP_FOOTER_HEIGHT + _getGroupTotalRowHeight(group)
    );
  };

  const _onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      return (
        <span
          style={{ textDecoration: 'none' }}
          onClick={() => {
            onAddressItemClick(props.item);
          }}
        >
          <DetailsRow {...props} styles={customStyles}/>
        </span>
      );
    }
    return null;
  };

  return (
    <Fabric>
      <div className={`wrapper-holder ${classNames.wrapper}`}>
        {cabType === 'organisation' && (
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
            <MarqueeSelection selection={selection}>
              <ShimmeredDetailsList
                items={addressList}
                columns={columns}
                groups={groups}
                groupProps={{
                  onRenderHeader: _onRenderGroupHeader,
                  //onRenderFooter: _onRenderGroupFooter,
                }}
                getGroupHeight={_getGroupHeight}
                layoutMode={DetailsListLayoutMode.justified}
                enableShimmer={isLoading}
                selection={selection}
                selectionPreservedOnEmptyClick={true}
                checkboxVisibility={1}
                selectionMode={SelectionMode.multiple}
                onRenderItemColumn={(
                  item: AddressBookItem,
                  index?: number,
                  column?: IColumn,
                ) => CABTableColumn(item, index, column)}
                onRenderDetailsFooter={(detailsFooterProps, defaultRender) => {
                  if (defaultRender !== undefined) {
                    return (
                      <DetailsRow
                        className="footer-as-filter"
                        {...detailsFooterProps}
                        columns={detailsFooterProps?.columns}
                        item={{}}
                        itemIndex={-1}
                        checkboxVisibility={1}
                        groupNestingDepth={
                          detailsFooterProps?.groupNestingDepth
                        }
                        selectionMode={SelectionMode.single}
                        selection={detailsFooterProps?.selection}
                        onRenderItemColumn={(
                          item?: any,
                          index?: number,
                          column?: IColumn,
                        ) =>
                          _renderCABDetailsFooterItemColumn(
                            cabFilter,
                            onChangeCABFilter,
                            onChangeCABFilterStatus,
                            item,
                            index,
                            column,
                          )
                        }
                      />
                    );
                  } else {
                    return <span></span>;
                  }
                }}
                onRenderDetailsHeader={(headerProps, defaultRender) => {
                  if (defaultRender !== undefined) {
                    return (
                      <Sticky
                        stickyPosition={StickyPositionType.Header}
                        isScrollSynced={true}
                        stickyBackgroundColor="transparent"
                      >
                        <div>{defaultRender(headerProps)}</div>
                      </Sticky>
                    );
                  } else {
                    return <span></span>;
                  }
                }}
                onItemInvoked={() => {
                }}
                onRenderRow={_onRenderRow}
              />
              {/* <ConfirmDialog
                show={dialogHidden}
                onClickCancelDialog={onClickCancelDialog}
                onClickConfirmDialog={onClickConfirmDialog}
              /> */}
            </MarqueeSelection>
          </ScrollablePane>
        )}

        {cabType !== 'organisation' && (
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
            <MarqueeSelection selection={selection}>
              <ShimmeredDetailsList
                items={addressList}
                columns={columns}
                setKey="set"

                layoutMode={DetailsListLayoutMode.justified}
                enableShimmer={isLoading}
                selection={selection}
                selectionPreservedOnEmptyClick={false}
                checkboxVisibility={1}
                selectionMode={SelectionMode.multiple}
                onRenderItemColumn={(
                  item: AddressBookItem,
                  index?: number,
                  column?: IColumn,
                ) => CABTableColumn(item, index, column)}
                onRenderDetailsFooter={(detailsFooterProps, defaultRender) => {
                  if (defaultRender !== undefined) {
                    return (
                      <DetailsRow
                        className="footer-as-filter"
                        {...detailsFooterProps}
                        columns={detailsFooterProps?.columns}
                        item={{}}
                        itemIndex={-1}
                        checkboxVisibility={1}
                        groupNestingDepth={
                          detailsFooterProps?.groupNestingDepth
                        }
                        selectionMode={SelectionMode.single}
                        selection={detailsFooterProps?.selection}
                        onRenderItemColumn={(
                          item?: any,
                          index?: number,
                          column?: IColumn,
                        ) =>
                          _renderCABDetailsFooterItemColumn(
                            cabFilter,
                            onChangeCABFilter,
                            onChangeCABFilterStatus,
                            item,
                            index,
                            column,
                          )
                        }
                      />
                    );
                  } else {
                    return <span></span>;
                  }
                }}
                onRenderDetailsHeader={(headerProps, defaultRender) => {
                  if (defaultRender !== undefined) {
                    return (
                      <Sticky
                        stickyPosition={StickyPositionType.Header}
                        isScrollSynced={true}
                        stickyBackgroundColor="transparent"
                      >
                        <div>{defaultRender(headerProps)}</div>
                      </Sticky>
                    );
                  } else {
                    return <span></span>;
                  }
                }}
                onItemInvoked={() => {
                }}
                onRenderRow={_onRenderRow}
              />
              {/* <ConfirmDialog
        show={dialogHidden}
        onClickCancelDialog={onClickCancelDialog}
        onClickConfirmDialog={onClickConfirmDialog}
      /> */}
            </MarqueeSelection>
          </ScrollablePane>
        )}

        {(!_.isArray(addressList) || _.isEmpty()) && (
          <div style={{ padding: 20, paddingTop: 120 }}>
            <Text>{t(message)}</Text>
          </div>
        )}
      </div>
    </Fabric>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ListPaneComponent);

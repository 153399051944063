import {
  DELETE_QUALITYBY_EP,
  QUALITY_SAVE_EP,
  READ_QUALITY_BY_ID_EP,
  READ_QUALITY_DROPDOWN_EP,
  READ_QUALITY_LIST_EP,
  READ_QUALITY_SHORTCUT_PANE_EP,
} from '../shared/endpoints';
import {
  CLEAR_QUALITY_FORM_DATA,
  CLEAR_QUALITYUID,
  DELETE_QUALITY,
  DELETE_QUALITY_FAIL,
  DELETE_QUALITY_SUCCESS,
  LOAD_QUALITY_BY_ID,
  LOAD_QUALITY_BY_ID_FAIL,
  LOAD_QUALITY_BY_ID_SUCCESS,
  LOAD_QUALITY_DROPDOWN,
  LOAD_QUALITY_DROPDOWN_FAIL,
  LOAD_QUALITY_DROPDOWN_SUCCESS,
  LOAD_QUALITY_LSITPANE,
  LOAD_QUALITY_LSITPANE_FAIL,
  LOAD_QUALITY_LSITPANE_SUCCESS,
  LOAD_QUALITY_SHORTCUTPANE,
  LOAD_QUALITY_SHORTCUTPANE_FAIL,
  LOAD_QUALITY_SHORTCUTPANE_SUCCESS,
  RESET_QUALITY_IS_CHANGE,
  SAVE_QUALITY,
  SAVE_QUALITY_FAIL,
  SAVE_QUALITY_SUCCESS,
  SET_QUALITY_UID,
} from '../actionTypes/qualityActionTypes';
import { v4 as uuidv4 } from 'uuid';
import { Quality, QualityListPaneFilter } from '../types/quality';

const defaultState: any = {
  qlList: [],
  isQLListLoaded: false,
  isNew: false,
  isChange: false,
  isEdit: false,
  showProgressBar: false,
  qlDropDowns: {},
  formData: {},
  uid: null,
  loadMsg: '',
  qlShortCutPaneData: [],
  reloadListPane: false,
  borResources: {},
  isLoadingQuality: false,
  qlId: null,
};

export default function qualityReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {
      case LOAD_QUALITY_SHORTCUTPANE:
        return { ...state };
      case LOAD_QUALITY_SHORTCUTPANE_SUCCESS:
        return {
          ...state,
          qlShortCutPaneData: action.payload.data.result,
        };
      case LOAD_QUALITY_SHORTCUTPANE_FAIL:
        return {
          ...state,
          qlShortCutPaneData: [],
        };
      case LOAD_QUALITY_LSITPANE:
        return {
          ...state,
          loading: true,
          listPaneItem: [],
          isQLListLoaded: false,
          loadMsg: '',
        };
      case LOAD_QUALITY_LSITPANE_SUCCESS:
        return {
          // ...state,
          // loading: false,
          // isChange: false,
          // isQLListLoaded: true,
          // qlList: action.payload.data.result,
          // loadMsg: !action.payload.data.status ? action.payload.data.message : '',

          ...state,
          loading: false,
          isChange: false,
          listPaneItem: formattedPayloadToListPaneTable(action.payload),
          loadMsg: !action.payload.data.status ? action.payload.data.message : '',
          isDataLoaded: true,
          createNewNode: false,
        };
      case LOAD_QUALITY_LSITPANE_FAIL:
        return {
          ...state,
          isQLListLoaded: true,
          qlList: [],
          loadMsg: action.error.response.data.message,
        };
      case RESET_QUALITY_IS_CHANGE:
        return {
          ...state,
          isChange: false,
        };

      case LOAD_QUALITY_DROPDOWN:
        return { ...state, qlDropDowns: {} };
      case LOAD_QUALITY_DROPDOWN_SUCCESS:
        return { ...state, qlDropDowns: action.payload.data.result };
      case LOAD_QUALITY_DROPDOWN_FAIL:
        return {
          ...state,
          qlDropDowns: {},
        };
      case LOAD_QUALITY_BY_ID:
        return { ...state, formData: {}, showProgressBar: true, isLoadingQuality: true };
      case LOAD_QUALITY_BY_ID_SUCCESS:
        return {
          ...state,
          isEdit: true,
          formData: action.payload.data.result,
          showProgressBar: false,
          isLoadingQuality: false,
        };
      case LOAD_QUALITY_BY_ID_FAIL:
        return {
          ...state,
          formData: {},
          showProgressBar: false,
          isLoadingQuality: false,
        };

      case SAVE_QUALITY:
        return { ...state, showProgressBar: true, reloadListPane: false, qlId: null };
      case SAVE_QUALITY_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          reloadListPane: true,
          qlId: action.payload.data.result?.sequenceCode,

        };
      case SAVE_QUALITY_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
          //qlId: action.payload.data.result
        };

      case SET_QUALITY_UID:
        return {
          ...state,
          isEdit: false,
          uid: uuidv4(),
        };
      case CLEAR_QUALITYUID:
        return {
          ...state,
          uid: null,
          isChange: false,
        };
      case CLEAR_QUALITY_FORM_DATA:
        return {
          ...state,
          uid: null,
          isNew: true,
          isEdit: false,
          isChange: false,
          formData: {},
        };

      default:
        return state;
    }
  }
}

export const readQualityShortCutPaneData = () => {
  return {
    types: [
      LOAD_QUALITY_SHORTCUTPANE,
      LOAD_QUALITY_SHORTCUTPANE_SUCCESS,
      LOAD_QUALITY_SHORTCUTPANE_FAIL,
    ],
    payload: { request: { url: READ_QUALITY_SHORTCUT_PANE_EP } },
  };
};

export const readQualityDropDownData = () => {
  return {
    types: [
      LOAD_QUALITY_DROPDOWN,
      LOAD_QUALITY_DROPDOWN_SUCCESS,
      LOAD_QUALITY_DROPDOWN_FAIL,
    ],
    payload: { request: { method: 'GET', url: READ_QUALITY_DROPDOWN_EP } },
  };
};


//-------Get list pane data to table-------//

export const filterQualityListPaneData = (filter: QualityListPaneFilter) => {
  return {
    types: [
      LOAD_QUALITY_LSITPANE,
      LOAD_QUALITY_LSITPANE_SUCCESS,
      LOAD_QUALITY_LSITPANE_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_QUALITY_LIST_EP,
        data: filter,
      },
    },
  };
};

//--------Delete Quality-------//

export const deleteProduct = (data: string[]) => {
  return {
    types: [
      DELETE_QUALITY,
      DELETE_QUALITY_SUCCESS,
      DELETE_QUALITY_FAIL,
    ],
    payload: {
      request: {
        url: DELETE_QUALITYBY_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};


export const readByQualityId = (id: string) => {
  return {
    types: [
      LOAD_QUALITY_BY_ID,
      LOAD_QUALITY_BY_ID_SUCCESS,
      LOAD_QUALITY_BY_ID_FAIL],
    payload: { request: { url: READ_QUALITY_BY_ID_EP + id } },
  };
};

export const resetIsChange = () => {
  return { type: RESET_QUALITY_IS_CHANGE };
};

export const saveFormData = (formData: Quality) => {
  return {
    types: [
      SAVE_QUALITY,
      SAVE_QUALITY_SUCCESS,
      SAVE_QUALITY_FAIL],
    payload: {
      request: {
        url: QUALITY_SAVE_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const setUid = () => {
  return { type: SET_QUALITY_UID };
};

export const clearFormData = () => {
  return { type: CLEAR_QUALITY_FORM_DATA };
};


//-------Get list pane data to table-------//

const removeHtmlTag = (type: any) => {

  let cleanText = type!!.replace(/<\/?[^>]+(>|$)/g, '');

  return cleanText;
};

const formattedPayloadToListPaneTable = (response: any) => {
  if (response.data.result && response.data.result.length > 0) {
    const data = response.data.result.map((item: any) => {
      return {
        key: item.productId,
        id: item.sequenceCode ? item.sequenceCode : '-',
        //id: item.id,
        parentId: item?.parentId,
        productId: item.productId ? item.productId : '-',
        name: item.name ? item.name : '-',
        title: item.title ? item.title : '-',
        headerTitle: item.headerTitle ? item.headerTitle : '-',

        tolerance: removeHtmlTag(item.tolerance ? item.tolerance : '-'),
        sequenceCode: item.sequenceCode ? item.sequenceCode : '-',
        criteria: removeHtmlTag(item.criteria ? item.criteria : '-'),

        method: removeHtmlTag(item.method ? item.method : '-'),
        skills: removeHtmlTag(item.skills ? item.skills : '-'),


      };
    });
    return data;
  }
  return [];
};

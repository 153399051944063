import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { ITooltipHostStyles } from '@fluentui/react';
import { messageService } from '../../services/messageService';
import moment from 'moment';
import i18n from '../../../i18n';

export interface BoxProps {
  name: string
}

interface DropResult {
  name: string
}
export const ItemTypes = {
  BOX: 'box',
}

const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };


export const DropBox = (props: { type: any, children: any, item: any, day?: any, isTd?: boolean, isPmol?: boolean }) => {
  const nonDropRef = useRef<HTMLDivElement>(null);
  const formatDate = (item: any) => {
    // console.log("start",moment(item.start).format('DD/MM/YYYY'))
    return moment(item.start).format('DD/MM/YYYY')
  }
  const [{ canDrop, isOver }, dropRef] = useDrop({
    accept: props.type ? props.type : formatDate(props.item),
    collect: (monitor: { canDrop: () => any; isOver: () => any; }) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver()
    }),
    drop(item: any, monitor:any) {
      // const didDrop = monitor.didDrop();
      const  offset = monitor.getClientOffset();
      let date = moment(props.day);
      messageService.sendMessage({ dndItem: { ...item, dropItem: { ...props.item, date: date },isPmol:props.isPmol,offset }});
    },
  })

  const isActive = canDrop && isOver

  //(props.week && isActive)?getParentWidth(props.item.id+"dropbox"):'100%'
  return (
    props?.isTd?<td
      //className={"dropBoxTdActive"}
      className={isActive ? "dropBoxTdActive" : "dropBox"}
      ref={dropRef}
    >
      {props.children}
      {
        isActive ?
          <div id="dropOverlay">
            <div id="text">{i18n.t('releaseToDrop')}</div>
          </div>
          : false
      }
      {/*{isActive ? 'Release to Drop'+ props.children : props.children}*/}
    </td>:<div
      //className={"dropBoxTdActive"}
      className={isActive ? "dropBoxTdActive" : "pmol-dropBox"}
      style={{width:'100%',height:"100%"}}
      ref={dropRef}
    >
      {props.children}
      {
        isActive ?
          <div id="dropOverlay">
            <div id="text">{i18n.t('releaseToDrop')}</div>
          </div>
          : false
      }
      {/*{isActive ? 'Release to Drop'+ props.children : props.children}*/}
    </div>

  )
}


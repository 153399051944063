import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';

const CpcCommandBarRight = (props: {
  createNewProductCatalogue: (create: boolean) => void;
  saveProductCatalogue: (save: boolean) => void;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  const newClick = () => {
    messageService.sendMessage({
      resetCpcListSelection: true,
      isNewCpc: true,
    });
    props.createNewProductCatalogue(true);
  };
  const getItems = () => {
    return [
      {
        key: 'new',
        text: t('new'),
        iconProps: { iconName: 'Add' },
        onClick: () => {
          newClick();
        },
        disabled: props.isLoading,
      },
      {
        key: 'save',
        text: t('save'),
        iconProps: { iconName: 'Save' },
        onClick: () => {
          props.saveProductCatalogue(true);
        },
        disabled: props.isLoading,
      },
    ];
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );
};

export default CpcCommandBarRight;

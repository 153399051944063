export const LOAD_MY_DAY_PLANNING_SHORTCUTPANE = 'uprince/LOAD_MY_DAY_PLANNING_SHORTCUTPANE';
export const LOAD_MY_DAY_PLANNING_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_MY_DAY_PLANNING_SHORTCUTPANE_SUCCESS';
export const LOAD_MY_DAY_PLANNING_SHORTCUTPANE_FAIL = 'uprince/LOAD_MY_DAY_PLANNING_SHORTCUTPANE_FAIL';

export const LOAD_MY_DAY_PLANNING_LSITPANE = 'uprince/LOAD_MY_DAY_PLANNING_LSITPANE';
export const LOAD_MY_DAY_PLANNING_LSITPANE_SUCCESS = 'uprince/LOAD_MY_DAY_PLANNING_LSITPANE_SUCCESS';
export const LOAD_MY_DAY_PLANNING_LSITPANE_FAIL = 'uprince/LOAD_MY_DAY_PLANNING_LSITPANE_FAIL';

export const LOAD_MY_DAY_PLANNING_DROPDOWN = 'uprince/LOAD_MY_DAY_PLANNING_DROPDOWN';
export const LOAD_MY_DAY_PLANNING_DROPDOWN_SUCCESS = 'uprince/LOAD_MY_DAY_PLANNING_DROPDOWN_SUCCESS';
export const LOAD_MY_DAY_PLANNING_DROPDOWN_FAIL = 'uprince/LOAD_MY_DAY_PLANNING_DROPDOWN_FAIL';

export const UPDATE_MY_DAY_PLANNING_PMOL = 'uprince/UPDATE_MY_DAY_PLANNING_PMOL';
export const UPDATE_MY_DAY_PLANNING_PMOL_SUCCESS = 'uprince/UPDATE_MY_DAY_PLANNING_PMOL_SUCCESS';
export const UPDATE_MY_DAY_PLANNING_PMOL_FAIL = 'uprince/UPDATE_MY_DAY_PLANNING_PMOL_FAIL';

export const UPDATE_MY_DAY_PLANNING_PBS = 'uprince/UPDATE_MY_DAY_PLANNING_PBS';
export const UPDATE_MY_DAY_PLANNING_PBS_SUCCESS = 'uprince/UPDATE_MY_DAY_PLANNING_PBS_SUCCESS';
export const UPDATE_MY_DAY_PLANNING_PBS_FAIL = 'uprince/UPDATE_MY_DAY_PLANNING_PBS_FAIL';

export const UPDATE_MY_DAY_PLANNING_PROJECT = 'uprince/UPDATE_MY_DAY_PLANNING_PROJECT';
export const UPDATE_MY_DAY_PLANNING_PROJECT_SUCCESS = 'uprince/UPDATE_MY_DAY_PLANNING_PROJECT_SUCCESS';
export const UPDATE_MY_DAY_PLANNING_PROJECT_FAIL = 'uprince/UPDATE_MY_DAY_PLANNING_PROJECT_FAIL';

export const LOAD_MY_DAY_PLANNING_BY_ID = 'uprince/LOAD_MY_DAY_PLANNING_BY_ID';
export const LOAD_MY_DAY_PLANNING_BY_ID_SUCCESS = 'uprince/LOAD_MY_DAY_PLANNING_BY_ID_SUCCESS';
export const LOAD_MY_DAY_PLANNING_BY_ID_FAIL = 'uprince/LOAD_MY_DAY_PLANNING_BY_ID_FAIL';

export const LOAD_BOR_RESOURCES_BY_ID = 'uprince/LOAD_BOR_RESOURCES_BY_ID';
export const LOAD_BOR_RESOURCES_BY_ID_SUCCESS = 'uprince/LOAD_BOR_RESOURCES_BY_ID_SUCCESS';
export const LOAD_BOR_RESOURCES_BY_ID_FAIL = 'uprince/LOAD_BOR_RESOURCES_BY_ID_FAIL';

export const SET_MY_DAY_PLANNING_UID = 'uprince/SET_MY_DAY_PLANNING_UID';
export const CLEAR_MY_DAY_PLANNING_UID = 'uprince/CLEAR_MY_DAY_PLANNING_UID';
export const CLEAR_MY_DAY_PLANNING_FORM_DATA = 'uprince/CLEAR_MY_DAY_PLANNING_FORM_DATA';
export const RESET_MY_DAY_PLANNING_IS_CHANGE = 'uprice/RESET_MY_DAY_PLANNING_IS_CHANGE';

export const CLEAR_MY_DAY_PLANNINGUID = 'uprice/CLEAR_MY_DAY_PLANNINGUID';

export const LOAD_CONTRACTING_UNITS = 'uprince/LOAD_CONTRACTING_UNITS';
export const LOAD_CONTRACTING_UNITS_SUCCESS = 'uprince/LOAD_CONTRACTING_UNITS_SUCCESS';
export const LOAD_CONTRACTING_UNITS_FAIL = 'uprince/LOAD_CONTRACTING_UNITS_FAIL';

export const SAVE_MY_DAY_PLANNING_STATE_ATTR = 'uprice/SAVE_MY_DAY_PLANNING_STATE_ATTR';

export const LOAD_MY_DAY_PLANNINNG_TEAMS_LIST = 'uprince/LOAD_MY_DAY_PLANNINNG_TEAMS_LIST';
export const LOAD_MY_DAY_PLANNINNG_TEAMS_LIST_SUCCESS = 'uprince/LOAD_MY_DAY_PLANNINNG_TEAMS_LIST_SUCCESS';
export const LOAD_MY_DAY_PLANNINNG_TEAMS_LIST_FAIL = 'uprince/LOAD_MY_DAY_PLANNINNG_TEAMS_LIST_FAIL';

export const LOAD_MY_DAY_PLANNINNG_RESOURCE_LSITPANE = 'uprince/LOAD_MY_DAY_PLANNINNG_RESOURCE_LSITPANE';
export const LOAD_MY_DAY_PLANNINNG_RESOURCE_LSITPANE_SUCCESS = 'uprince/LOAD_MY_DAY_PLANNINNG_RESOURCE_LSITPANE_SUCCESS';
export const LOAD_MY_DAY_PLANNINNG_RESOURCE_LSITPANE_FAIL = 'uprince/LOAD_MY_DAY_PLANNINNG_RESOURCE_LSITPANE_FAIL';

export const UPDATE_MY_DAY_PLANNING_PMOL_PERSON = 'uprince/UPDATE_MY_DAY_PLANNING_PMOL_PERSON';
export const UPDATE_MY_DAY_PLANNING_PMOL_PERSON_SUCCESS = 'uprince/UPDATE_MY_DAY_PLANNING_PMOL_PERSON_SUCCESS';
export const UPDATE_MY_DAY_PLANNING_PMOL_PERSON_FAIL = 'uprince/UPDATE_MY_DAY_PLANNING_PMOL_PERSON_FAIL';

export const UPDATE_MY_DAY_PLANNINNG_TEAM_LIST = 'uprince/UPDATE_MY_DAY_PLANNINNG_TEAM_LIST';
export const UPDATE_MY_DAY_PLANNINNG_TEAM_LIST_SUCCESS = 'uprince/UPDATE_MY_DAY_PLANNINNG_TEAM_LIST_SUCCESS';
export const UPDATE_MY_DAY_PLANNINNG_TEAM_LIST_FAIL = 'uprince/UPDATE_MY_DAY_PLANNINNG_TEAM_LIST_FAIL';

export const UPDATE_MY_DAY_PLANNINNG_VEHICLE = 'uprince/UPDATE_MY_DAY_PLANNINNG_VEHICLE';
export const UPDATE_MY_DAY_PLANNINNG_VEHICLE_SUCCESS = 'uprince/UPDATE_MY_DAY_PLANNINNG_VEHICLE_SUCCESS';
export const UPDATE_MY_DAY_PLANNINNG_VEHICLE_FAIL = 'uprince/UPDATE_MY_DAY_PLANNINNG_VEHICLE_FAIL';

export const ASSIGN_VEHICLE_TO_TEAM_CALL = 'uprince/ASSIGN_VEHICLE_TO_TEAM_CALL';
export const ASSIGN_VEHICLE_TO_TEAM_SUCCESS = 'uprince/ASSIGN_VEHICLE_TO_TEAM_SUCCESS';
export const ASSIGN_VEHICLE_TO_TEAM_FAIL = 'uprince/ASSIGN_VEHICLE_TO_TEAM_CALL_FAIL';

export const CLONE_PMOL_DAY_PLANNING = 'uprince/CLONE_PMOL_DAY_PLANNING';
export const CLONE_PMOL_DAY_PLANNING_SUCCESS = 'uprince/CLONE_PMOL_DAY_PLANNING_SUCCESS';
export const CLONE_PMOL_DAY_PLANNING_FAIL = 'uprince/CLONE_PMOL_DAY_PLANNING_FAIL';

export const TOOL_ASSIGN_MY_DAY_PLANNING = 'uprince/TOOL_ASSIGN_MY_DAY_PLANNING';
export const TOOL_ASSIGN_MY_DAY_PLANNING_SUCCESS = 'uprince/TOOL_ASSIGN_MY_DAY_PLANNING_SUCCESS';
export const TOOL_ASSIGN_MY_DAY_PLANNING_FAIL = 'uprince/TOOL_ASSIGN_MY_DAY_PLANNING_FAIL';

export const GENERATE_DP_RFQ = 'uprince/GENERATE_DP_RFQ';
export const GENERATE_DP_RFQ_SUCCESS = 'uprince/GENERATE_DP_RFQ_SUCCESS';
export const GENERATE_DP_RFQ_FAIL = 'uprince/GENERATE_DP_RFQ_FAIL';

export const REMOVE_LABOUR_DAY_PLANNING = 'uprince/REMOVE_LABOUR_DAY_PLANNING';
export const REMOVE_LABOUR_DAY_PLANNING_SUCCESS = 'uprince/REMOVE_LABOUR_DAY_PLANNING_SUCCESS';
export const REMOVE_LABOUR_DAY_PLANNING_FAIL = 'uprince/REMOVE_LABOUR_DAY_PLANNING_FAIL';

export const CREATE_NEW_PROJECT_MY_ENV = 'uprince/CREATE_NEW_PROJECT_MY_ENV';
export const CREATE_NEW_PROJECT_MY_ENV_SUCCESS = 'uprince/CREATE_NEW_PROJECT_MY_ENV_SUCCESS';
export const CREATE_NEW_PROJECT_MY_ENV_FAIL = 'uprince/CREATE_NEW_PROJECT_MY_ENV_FAIL';
import * as React from 'react';
import {Dropdown, IDropdownStyleProps, IDropdownStyles} from '@fluentui/react';
import {uPrinceTheme} from '../../../theme';

const SelectedKeyDropDownAdaptater = ({ input: { name, value, onChange, ...restInput }, selectedKey,meta, lable, placeholder, data, options, disabled, onSelected,...rest }: any) => {
    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;
    /* Must passdown the selectedKey */
    return (
        <Dropdown
            {...restInput}
            {...rest}
            value={-1}
            options={options}
            label={lable}
            onChange={onSelected}
            errorMessage={showError ? meta.error : ''}
            optionLabel="optionLabel"
            placeholder={placeholder}
            disabled={disabled}
            selectedKey={selectedKey ?? value}
            style={{ marginRight: '1rem', width: '100%' }}
            styles={disabled && getDisabledStyles}
        />
    );
};

function getDisabledStyles(props: IDropdownStyleProps): Partial<IDropdownStyles>  {
    const { disabled } = props;
    return {
        title: disabled && {
            color: uPrinceTheme.palette.themePrimary,
        },
        label: {color: 'black'}
    };
}

export default SelectedKeyDropDownAdaptater;

export const riskAnalysisPoints: string[] = [
  "Do I know what can happen to me and how I can rule out those scenarios? ",
  "Do I know exactly what the possible dangers are and how I can prevent them? ",
  "Do I know exactly what to do and can I do them? ",
  "Have I read the Risk Analysis and the construction permit? ",
  "Do I know what I need to accomplish the job safely and is that also available (eg. Tools and protections)?",
  "Have I verified if the work conditions have or haven’t changed? ",
  "It is very clear to me what I have to do to prevent incidents. "
]

export interface RiskTableFactor {
  probability: string, 
  exposure: string, 
  gravity: string, 
  id: number
}

export const riskTableFactor: RiskTableFactor[] = [
  {probability: "0,1   almost inconceivable ", exposure: "0,5   very rare ( 1/y) ", gravity: "1   slight injuries without default ", id: 1},
  {probability: "0,2   practically impossible ", exposure: "1   rare ( a few times a year ) ", gravity: "3   important, injuries and default ", id: 2},
  {probability: "0,5   conceivable, but unlikely ", exposure: "2   sometimes ( monthly ) ", gravity: "7   serious, permanent disability, partially unfit for work ", id: 3},
  {probability: "1   unlikely, borderline case ", exposure: "3   occasionally ( weekly ) ", gravity: "15   very serious, 1 dead ", id: 4},
  {probability: "3   unusual but possible ", exposure: "6   regularly ( daily ) ", gravity: "40   disaster, several dead ", id: 5},
  {probability: "6   quite possible ", exposure: "10   constantly ", gravity: "", id: 6},
  {probability: "10   to be expected ", exposure: "", gravity: "", id: 7},
]

export interface RiskActivityData {
  id: number,
  riskActive: string,
  risk: string,
  cause: string,
  evaluation: { G: string, P: string, E: string, R: string },
  prevention: string[],
  prevEval: { G: string, P: string, E: string, R: string }
}

export const riskActivityData: RiskActivityData[] = [
  {
    id: 1,
    riskActive: '',
    risk: 'Charge on the body ( back – arm ) ',
    cause: 'Chaos / lack of cleaning plan ',
    evaluation: { G: '3', P: '6', E: '6', R: '108' },
    prevention: ['Use of approved equipment for lifting operations - adopt an ergonomic position'],
    prevEval: { G: '2', P: '3', E: '6', R: '36' }
  },
  {
    id: 2,
    riskActive: '',
    risk: 'Contact with mobile objects, dust',
    cause: 'No supervision by the hierarchy',
    evaluation: { G: '3', P: '6', E: '6', R: '108' },
    prevention: ['Set up appropriate PPE’s'],
    prevEval: { G: '1', P: '6', E: '6', R: '36' }
  },
  {
    id: 3,
    riskActive: '',
    risk: 'jamming of the hands and fingers and physical overload',
    cause: '',
    evaluation: { G: '7', P: '6', E: '6', R: '252' },
    prevention: ['Delimit or secure the work area - making the workplace obstacle free.'],
    prevEval: { G: '1', P: '6', E: '3', R: '18' }
  },
  {
    id: 4,
    riskActive: '',
    risk: 'Cutting yourself at sharp edges – stumbling',
    cause: '',
    evaluation: { G: '3', P: '6', E: '6', R: '108' },
    prevention: ['Ask for help, give help, PPE : wearing the appropriate protective gloves'],
    prevEval: { G: '1', P: '6', E: '6', R: '36' }
  },
  {
    id: 5,
    riskActive: '',
    risk: 'bumping into immobile objects',
    cause: '',
    evaluation: { G: '7', P: '1', E: '1', R: '7' },
    prevention: ['Protective shoes – warning for wires and other objects lying on the floor '],
    prevEval: { G: '1', P: '6', E: '6', R: '36' }
  },
  {
    id: 6,
    riskActive: '',
    risk: 'Fall through an opening in the floor or a hole in the ground ',
    cause: '',
    evaluation: { G: '7', P: '3', E: '6', R: '126' },
    prevention: ['Delimit the work area and areas with danger of falling '],
    prevEval: { G: '1', P: '3', E: '6', R: '18' }
  },
  {
    id: 7,
    riskActive: '',
    risk: 'Person falling on the ground floor',
    cause: '',
    evaluation: { G: '3', P: '6', E: '6', R: '108' },
    prevention: ['Ideal light in the work area - discipline and neatness in the work environment & Access to the building site is forbidden for unauthorized staff'],
    prevEval: { G: '2', P: '3', E: '6', R: '36' }
  },
  {
    id: 8,
    riskActive: '',
    risk: 'Person falling from a higher level  ',
    cause: '',
    evaluation: { G: '15', P: '3', E: '6', R: '270' },
    prevention: ['Construction of a scaffold by qualified staff – closing holes  placing railings – using the right angle of inclination for the ladder'],
    prevEval: { G: '1', P: '1', E: '6', R: '6' }
  },
  {
    id: 9,
    riskActive: '',
    risk: 'Falling objects',
    cause: '',
    evaluation: { G: '7', P: '3', E: '6', R: '126' },
    prevention: ['Set CPE’s -  periodic inspection hoist – prohibit the use of staff that are under treatment because of the loads '],
    prevEval: { G: '1', P: '1', E: '6', R: '6' }
  },
  {
    id: 10,
    riskActive: '',
    risk: 'Wrong movements when lifting ',
    cause: '',
    evaluation: { G: '3', P: '6', E: '2', R: '36' },
    prevention: ['Use of auxiliary tools and/or qualified people operating hoist & Use of correct lift technique – ask for help'],
    prevEval: { G: '1', P: '3', E: '2', R: '6' }
  },
  {
    id: 11,
    riskActive: '',
    risk: '',
    cause: '',
    evaluation: { G: '', P: '', E: '', R: '' },
    prevention: ['General: carry out a meeting at the beginning of the job and/or LMRA, Last Minute Risk Analysis '],
    prevEval: { G: '1', P: '1', E: '10', R: '10' }
  }
];


export const riskNoiseNuisance = [
  {
    id: 1,
    riskActive: 'Noise nuisance',
    risk: 'Noise and vibrations ',
    cause: '',
    evaluation: { G: '3', P: '6', E: '6', R: '108' },
    prevention: ['Adapted instructions – adapted PPE’s: hearing protection – encapsulation machines –   Regular maintenance of equipment by qualified and trained staff – acquisition low-noise equipment '],
    prevEval: { G: '1', P: '6', E: '6', R: '36' }
  },
];

export const riskDangerFactors: RiskActivityData[] = [
  {
    id: 1,
    riskActive: '',
    risk: 'Exposure to toxic gases ',
    cause: 'Lack of clear labels  ',
    evaluation: { G: '7', P: '3', E: '2', R: '42' },
    prevention: ['Provide necessary information to the executors – check ventilation - wearing the appropriate PPE’s '],
    prevEval: { G: '1', P: '3', E: '3', R: '9' }
  },
  {
    id: 2,
    riskActive: '',
    risk: 'Increase of vapor, gas and dust',
    cause: '',
    evaluation: { G: '3', P: '6', E: '6', R: '108' },
    prevention: ['Make sure that  exhaust gases are discharged with permission – provide a natural ventilation – dust extraction at the source connected with a vacuum cleaner (in combination of appropriate PPE’s ) '],
    prevEval: { G: '1', P: '3', E: '6', R: '18' }
  },
  {
    id: 3,
    riskActive: '',
    risk: 'Irritation, burns, eye injuries, intoxication ',
    cause: '',
    evaluation: { G: '7', P: '6', E: '3', R: '126' },
    // need to check
    prevention: ['Garbage ( dangerous products ) have to be in a closed package in the right container – dangerous products have to be labelled and have also to be in the right container '],
    prevEval: { G: '1', P: '3', E: '3', R: '9' }
  },
]


export const personalHygiene: RiskActivityData[] = [
  {
    id: 1,
    riskActive: 'Lunch use rest periods and toilets',
    risk: 'Infections and diseases ',
    cause: 'Lack of clear labels  ',
    evaluation: { G: '3', P: '6', E: '3', R: '54' },
    prevention: ['Lunch in the designated lunch areas ', 'Leave the toilet clean after using them ', 'Clear away your waste and deposit it in the appropriate bins if there are no dustbins you have to take your waste back home ', 'Wash your hands with soap; in absence of soap wash them with water  '],
    prevEval: { G: '0', P: '0', E: '0', R: '0' }
  },
]

export const movingPeopleOnConstructionSite: RiskActivityData[] = [
  {
    id: 1,
    riskActive: 'Moving people on construction site ',
    risk: 'Contact with mobile objects ',
    cause: '',
    evaluation: { G: '15', P: '3', E: '6', R: '270' },
    prevention: ['inspection of the work method – training and formation of the staff – obligatory use of PPE and periodic inspection by qualified staff ', 'Clear away your waste and deposit it in the appropriate bins if there are no dustbins you have to take your waste back home ', 'Wash your hands with soap; in absence of soap wash them with water '],
    prevEval: { G: '1', P: '3', E: '6', R: '18' }
  },
  {
    id: 2,
    riskActive: '',
    risk: 'Contact with immobile objects ',
    cause: '',
    evaluation: { G: '7', P: '3', E: '6', R: '126' },
    prevention: ['Wear of appropriate PPE’s – information and training of staff – supervision on work method '],
    prevEval: { G: '1', P: '3', E: '6', R: '18' }
  },
  {
    id: 3,
    riskActive: '',
    risk: 'Effort, wrong movements ',
    cause: '',
    evaluation: { G: '3', P: '6', E: '6', R: '108' },
    prevention: ['Training of correct lift-technique – repartition of tasks and weight – move heavy and cumbersome materials with hoisting equipment'],
    prevEval: { G: '1', P: '0,5', E: '6', R: '3' }
  },
  {
    id: 4,
    riskActive: '',
    risk: 'Steps on objects ',
    cause: '',
    evaluation: { G: '3', P: '6', E: '6', R: '108' },
    prevention: ['Clearing items – LMRA’s - preserve enough space in between working areas '],
    prevEval: { G: '1', P: '0,5', E: '6', R: '3' }
  },
  {
    id: 5,
    riskActive: '',
    risk: 'People falling on the ground floor ',
    cause: '',
    evaluation: { G: '7', P: '3', E: '3', R: '63' },
    prevention: ['Grouped stacking of material – respect safety distances '],
    prevEval: { G: '1', P: '3', E: '6', R: '18' }
  },
  {
    id: 6,
    riskActive: '',
    risk: 'People falling from a higher ground ',
    cause: '',
    evaluation: { G: '7', P: '3', E: '3', R: '63' },
    prevention: ['Placing CPE’s – use of PPE’s ( lifeline – safety harness) – qualified and informed staff   inspection of trestles, ladders, mobile scaffolding '],
    prevEval: { G: '1', P: '0,5', E: '3', R: '1,5' }
  },
  {
    id: 7,
    riskActive: '',
    risk: 'Fall of objects during use ',
    cause: '',
    evaluation: { G: '7', P: '3', E: '3', R: '63' },
    prevention: ['Use of the right tools in function of the task ', 'Periodic inspection by qualified staff '],
    prevEval: { G: '1', P: '1', E: '3', R: '3' }
  },
]

export const placingInteriorDoorsAndWindows: RiskActivityData[] = [
  {
    id: 1,
    riskActive: 'Placing interior doors and – windows ',
    risk: 'Effort, wrong movement',
    cause: '',
    evaluation: { G: '7', P: '6', E: '6', R: '252' },
    prevention: ['Limitation of the load – carrying with 2 people or using auxiliary hoist tools'],
    prevEval: { G: '1', P: '3', E: '6', R: '18' }
  }, 
  {
    id: 2,
    riskActive: '',
    risk: 'Exposure to electric current ',
    cause: '',
    evaluation: { G: '15', P: '3', E: '6', R: '270' },
    prevention: ['Connect to residual-current device ( RCD ) '],
    prevEval: { G: '1', P: '0,2', E: '6', R: '1,2' }
  },
  {
    id: 3,
    riskActive: '',
    risk: 'Contact with mobile objects (planer, radial saw, pneumatic tool )',
    cause: '',
    evaluation: { G: '3', P: '6', E: '6', R: '108' },
    prevention: ['Wear of appropriate PPE’s - verification proper functioning and shielding of the appliance – good and stable fixing method. Preferably, working in the opposite direction of your body'],
    prevEval: { G: '1', P: '3', E: '6', R: '18' }
  }, 
]

export const weightOnHeights: RiskActivityData[] = [
  {
    id: 1,
    riskActive: 'Working on heights with a LADDER ',
    risk: 'Damage to the ladder ',
    cause: '',
    evaluation: { G: '7', P: '0,5', E: '6', R: '21' },
    prevention: ['Inspection before use'],
    prevEval: { G: '1', P: '0,5', E: '6', R: '3' }
  },
  {
    id: 2,
    riskActive: '',
    risk: 'Flexion ladder',
    cause: '',
    evaluation: { G: '7', P: '3', E: '6', R: '126' },
    prevention: ['inspection before use – right inclination angle of ladder – Overlapping of minimum 3 steps with double ladders ', 'Check brace ladder '],
    prevEval: { G: '1', P: '0,5', E: '6', R: '3' }
  },
  {
    id: 3,
    riskActive: '',
    risk: 'Electrocution ',
    cause: '',
    evaluation: { G: '15', P: '3', E: '2', R: '90' },
    prevention: ['Metal ladders have to stay at minimum 2,5 m from electrical parts under current '],
    prevEval: { G: '1', P: '3', E: '1', R: '3' }
  },
  {
    id: 4,
    riskActive: '',
    risk: 'Falling from a ladder ',
    cause: '',
    evaluation: { G: '15', P: '3', E: '6', R: '270' },
    prevention: ['Anti-slip pad(+ stabilizer) Presence roll bar and keep both feet on step + position ladder to avoid reaching next to the ladder. Limit climbing height to 4m. The ladders cannot be moved if there is someone on it. ', 'The ladders cannot be positioned too steep (ca70 °) and the ladders have to reach at least 1m above the standing height '],
    prevEval: { G: '1', P: '3', E: '6', R: '18' }
  },
  {
    id: 5,
    riskActive: '',
    risk: 'Falling objects',
    cause: '',
    evaluation: { G: '3', P: '6', E: '6', R: '108' },
    prevention: ['positioning: not in front of doorways or passageways ', 'PPE: compulsory use of helmet', 'Provide regular railings with plinth on the hand-rails where the ladders are placed against '],
    prevEval: { G: '1', P: '6', E: '6', R: '36' }
  },
  {
    id: 6,
    riskActive: '',
    risk: 'Slipping ladder',
    cause: '',
    evaluation: { G: '7', P: '3', E: '6', R: '126' },
    prevention: ['Anti-slip pad ( rotating )    inspection before use -           Check the inspection labels       Ladders with more than 25 steps have to be fixed              Do not use on scaffold floor    Steady surface on stable ground'],
    prevEval: { G: '1', P: '3', E: '6', R: '18' }
  },
  {
    id: 7,
    riskActive: 'Work in height with a MOBILE SCAFFOLD ',
    risk: 'Collapsing or bending scaffold ',
    cause: '',
    evaluation: { G: '15', P: '3', E: '6', R: '270' },
    prevention: ['Placing a good scaffold – commission with signed commissioning card – constructed by qualified people and according to good workmanship - Periodic inspection by qualified staff - construction according to expected load '],
    prevEval: { G: '1', P: '3', E: '6', R: '18' }
  },
  {
    id: 8,
    riskActive: '',
    risk: 'loosening ( scaffold ) ',
    cause: '',
    evaluation: { G: '15', P: '1', E: '3', R: '45' },
    prevention: ['The scaffold has to be fixed whatever the height '],
    prevEval: { G: '1', P: '0,2', E: '3', R: '0,6' }
  },
  {
    id: 9,
    riskActive: '',
    risk: 'Bump/collision ( scaffold ) ',
    cause: '',
    evaluation: { G: '7', P: '6', E: '3', R: '126' },
    prevention: ['The scaffolds cannot be placed in temporary or permanent passageways unless the necessary security measures have been made Danger of collision: place warning signs, fences or notifications '],
    prevEval: { G: '1', P: '1', E: '3', R: '3' }
  },
  {
    id: 10,
    riskActive: '',
    risk: 'Falling from scaffold ',
    cause: '',
    evaluation: { G: '15', P: '3', E: '6', R: '270' },
    prevention: ['Climb the scaffold from a ladder - Side plank of 15cm, railing at 40-50 cm and at 1m  Opening of maximum 30cm between the ground and the wall – construction by qualified staff and according to good workmanship – daily inspection by a qualified person '],
    prevEval: { G: '1', P: '1', E: '6', R: '6' }
  },
  {
    id: 11,
    riskActive: '',
    risk: 'Falling',
    cause: '',
    evaluation: { G: '15', P: '3', E: '6', R: '270' },
    prevention: ['Move scaffold very slowly, preferably lengthwise and over a surface free from any obstacle ', 'Construction and demolition by qualified and trained staff ', 'When moving the scaffold no one is allowed to be standing on it –          lock the wheels and if necessary use stabilizers. '],
    prevEval: { G: '1', P: '1', E: '6', R: '6' }
  },
  {
    id: 12,
    riskActive: '',
    risk: 'Falling objects ',
    cause: '',
    evaluation: { G: '3', P: '3', E: '6', R: '54' },
    prevention: ['Provide workplace of side plank – lift light material and equipment with hoisting rope and not in bags', 'The surface has to be flat and load bearing ', 'PPE : wear of helmet, security shoes '],
    prevEval: { G: '1', P: '1', E: '6', R: '6' }
  },
  {
    id: 13,
    riskActive: '',
    risk: 'Rolling scaffold ',
    cause: '',
    evaluation: { G: '', P: '', E: '', R: '' },
    prevention: ['Block the wheels of the mobile scaffold – check before use of scaffold '],
    prevEval: { G: '', P: '', E: '', R: '' }
  },
]

export const placingPartitionAndWainscoting: RiskActivityData[] = [
  {
    id: 1,
    riskActive: 'Placing partition and wainscoting ',
    risk: 'Exposure to or contact with electric current ',
    cause: '',
    evaluation: { G: '15', P: '3', E: '2', R: '90' },
    prevention: ['Connect to residual-current device ( RCD ) – switch off current installation before the beginning of the job ( lock the installation with clear communication ) '],
    prevEval: { G: '1', P: '3', E: '2', R: '6' }
  },
  {
    id: 2,
    riskActive: '',
    risk: 'Contact with mobile objects/tools ',
    cause: '',
    evaluation: { G: '7', P: '3', E: '6', R: '126' },
    prevention: ['Wear of appropriate PPE’s – maintain tools in good condition and use them correctly. Inspection of the work method '],
    prevEval: { G: '1', P: '3', E: '6', R: '18' }
  },
  {
    id: 3,
    riskActive: '',
    risk: 'Contact with immobile objects ',
    cause: '',
    evaluation: { G: '7', P: '3', E: '6', R: '126' },
    prevention: ['PPE:                                safety gloves ( resistant to cuts ) '],
    prevEval: { G: '1', P: '3', E: '6', R: '18' }
  },
  {
    id: 4,
    riskActive: '',
    risk: 'Effort, wrong movements - slipping ',
    cause: '',
    evaluation: { G: '3', P: '6', E: '6', R: '108' },
    prevention: ['Handle loads/weights correctly ', 'Training and information executive staff  '],
    prevEval: { G: '1', P: '3', E: '6', R: '18' }
  },
]

export const interiorJourney: RiskActivityData[] = [
  {
    id: 1,
    riskActive: 'Installation interior joinery ',
    risk: 'Contact with mobile objects ',
    cause: '',
    evaluation: { G: '15', P: '3', E: '6', R: '270' },
    prevention: ['Training and formation staff – inspection work method – wear of PPE – periodic inspection by qualified staff '],
    prevEval: { G: '1', P: '6', E: '6', R: '36' }
  },
  {
    id: 2,
    riskActive: '',
    risk: 'Contact with immobile objects ',
    cause: '',
    evaluation: { G: '7', P: '3', E: '6', R: '126' },
    prevention: ['Information and training staff – supervision on work method – wear of appropriate PPE’s '],
    prevEval: { G: '1', P: '6', E: '6', R: '36' }
  },
  {
    id: 3,
    riskActive: '',
    risk: 'Effort, wrong movements ',
    cause: '',
    evaluation: { G: '3', P: '6', E: '6', R: '108' },
    prevention: ['Training right lifting technique – task repartition -  auxiliary tools or use hoisting equipment when moving cumbersome and heavy materials '],
    prevEval: { G: '1', P: '6', E: '6', R: '36' }
  },
  {
    id: 4,
    riskActive: '',
    risk: 'Steps on objects ',
    cause: '',
    evaluation: { G: '3', P: '6', E: '6', R: '108' },
    prevention: ['LMRA, clearing passages, guarantee sufficient space in between workspaces '],
    prevEval: { G: '1', P: '3', E: '6', R: '18' }
  },
  {
    id: 5,
    riskActive: '',
    risk: 'People falling on ground floor ',
    cause: '',
    evaluation: { G: '7', P: '3', E: '3', R: '63' },
    prevention: ['Keep workspace clear of obstacles – wear PPE’s  - grouped stacking of material'],
    prevEval: { G: '1', P: '3', E: '6', R: '18' }
  },
  {
    id: 6,
    riskActive: '',
    risk: 'People falling form a higher level',
    cause: '',
    evaluation: { G: '7', P: '3', E: '3', R: '63' },
    prevention: ['Placing PPE, use of security harness and lifeline, close openings with sufficiently strong material – use of qualified and informed staff – inspection construction before commission '],
    prevEval: { G: '1', P: '6', E: '6', R: '36' }
  },
  {
    id: 7,
    riskActive: '',
    risk: 'Falling object during use ',
    cause: '',
    evaluation: { G: '7', P: '3', E: '3', R: '63' },
    prevention: ['Use of the right tools according to the  task – periodic inspection by qualified staff '],
    prevEval: { G: '1', P: '6', E: '6', R: '36' }
  },
]

export const placingLoweredCeilings: RiskActivityData[] = [
  {
    id: 1,
    riskActive: 'Placing lowered ceilings ',
    risk: 'Contact with mobile object/tools ',
    cause: '',
    evaluation: { G: '7', P: '3', E: '6', R: '126' },
    prevention: ['The devices have to be provided with the necessary protection. Wear of PPE’s. Information and training employees. '],
    prevEval: { G: '1', P: '3', E: '6', R: '18' }
  },
  {
    id: 2,
    riskActive: '',
    risk: 'Contact with immobile objects ',
    cause: '',
    evaluation: { G: '7', P: '3', E: '6', R: '126' },
    prevention: ['Wear of appropriate PPE’s '],
    prevEval: { G: '1', P: '3', E: '6', R: '18' }
  },
  {
    id: 3,
    riskActive: '',
    risk: 'Effort, wrong movement ',
    cause: '',
    evaluation: { G: '3', P: '6', E: '6', R: '108' },
    prevention: ['Use of appropriate hoist equipment – handling loads/weights correctly ', 'Training and information of executive staff '],
    prevEval: { G: '1', P: '3', E: '6', R: '18' }
  },
]

export const placingBasebardsAndParquet: RiskActivityData[] = [
  {
    id: 1,
    riskActive: 'Placing baseboards and parquet',
    risk: 'Knee injury – effort and wrong movements ',
    cause: '',
    evaluation: { G: '3', P: '6', E: '6', R: '108' },
    prevention: ['Wear of decent kneepads, carrying loads with 2 people ', 'Fixing the items you have to work on '],
    prevEval: { G: '1', P: '3', E: '6', R: '18' }
  },
]

export const electricalWorks: RiskActivityData[] = [
  {
    id: 1,
    riskActive: 'Placing interior wiring ',
    risk: 'Exposure to electric current ',
    cause: '',
    evaluation: { G: '15', P: '3', E: '6', R: '270' },
    prevention: ['Adjusted security method (by using BA5 ) ', 'Earthening tools or double isolation ', 'Making tension free where possible and necessary '],
    prevEval: { G: '1', P: '1', E: '3', R: '3' } 
  },
  {
    id: 2,
    riskActive: 'Placing earthening system ',
    risk: 'Electrocution while working on an earthening point after placing main switch ',
    cause: '',
    evaluation: { G: '15', P: '3', E: '3', R: '135' },
    prevention: ['Use of auxiliary tools, appropriate PPE’ (face shield) taking an isolating posture when opening the measuring tape ', '  Making tension free where necessary and possible.'],
    prevEval: { G: '1', P: '1', E: '3', R: '9' } 
  },
  {
    id: 3,
    riskActive: 'Placing sockets, switches and lights ',
    risk: 'Exposure to electric curren',
    cause: '',
    evaluation: { G: '15', P: '3', E: '3', R: '135' },
    prevention: ['Detection and/or signalization live parts ', 'Use of separate current circuit and residual-current device ( RCD ) ', 'Wear of appropriate PPE’s '],
    prevEval: { G: '1', P: '3', E: '3', R: '9' } 
  }
]

import { Label } from '@fluentui/react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import client from '../../api';
import CustomDropdown from '../../shared/customDropdown/customDropdown';

const OrganizationPicker: React.FC<any> = (props: { selectedOption: any, disabled: boolean, lable: string, onChange: any }) => {
  const [state, setstate] = useState<{ key: string, text: string }[]>([]);
  const [isCleared, setIsClear] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  useEffect(() => {
    if (_.isArray(props.selectedOption) && !_.isEmpty(props.selectedOption) && !isCleared) {
      if (!_.isEmpty(state) && state[0]?.text !== props.selectedOption[0]?.text) {
        if (!isSelected) {
          setstate(props.selectedOption);
        }
      } else {
        setstate(props.selectedOption);
      }
    } else {
      setstate([]);
    }
  }, [props.selectedOption]);

  const formatOrganizationResponse = (response: any) => {
    if (response && response.data) {
      if (response.data.result && _.isArray(response.data.result)) {
        const titleNotEmptyList = response.data.result.filter(
          (item: { name: string | null }) => item.name !== null,
        );
        const data = titleNotEmptyList.map((item: any) => {
          return {
            value: item,
            label: item.name,
          };
        });
        return data;
      }
      return [];
    }
    return [];
  };

  const organisationOptions = (inputValue: any) =>
    new Promise((resolve) => {
      // eslint-disable-next-line no-use-before-define
      resolve(getOrganisationByName(inputValue));
    });

  const getOrganisationByName = async (searchName: string) => {
    if (searchName.length >= 2) {
      const response = await client.get(
        `CentralAddressBook/ReadCabOrganizationList/${searchName}`,
      );
      return formatOrganizationResponse(response);
    } else if (searchName === '') {
      const response = await client.get(
        `CentralAddressBook/ReadCabOrganizationList/${searchName}`,
      );
      return formatOrganizationResponse(response);
    }
  };

  return (
    <div>
      {props.lable &&
      <Label disabled={props.disabled}>{props.lable}</Label>
      }
      <CustomDropdown
        reference={''}
        onChange={(
          key: string, text: string,
        ) => {
          if (key) {
            props.onChange({ value: key, label: text });
          } else {
            props.onChange(null);
          }
        }}
        selectedOption={props.selectedOption}
        promiseOptions={organisationOptions}
        validationMessage={''}
        required={true}
      />
    </div>
  );
};

export default OrganizationPicker;

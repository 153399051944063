import { Dropdown, IColumn, TextField } from '@fluentui/react';
import _ from 'lodash';
import React, { useState } from 'react';
import PeoplePicker from '../../shared/fluentFinalForm/PeoplePicker';
import UprinceLogger from '../../shared/Logger/logger';

export const _renderFilterItemColumn = (
  filterTitle: string,
  onChangeTitle: any,
  filterType: string,
  projectTypes: any,
  onChangeType: any,
  filterManagementLevel: string,
  projectManagementLevels: any,
  onChangeManagementLevel: any,
  filterTemplate: string,
  projectTemplates: any,
  onChangeTemplate: any,
  filterToleranceState: string,
  projectToleranceStates: any,
  onChangeToleranceState: any,
  filterProjectManager: string,
  onChangeProjectManager: any,
  item?: any,
  index?: number,
  column?: IColumn) => {
  UprinceLogger.log('column', column);
  const [projectManager, setProjectManager] = useState<any>(null);
  switch (column?.key) {
    case 'column2': {
      return (
        <div>
          <TextField
            value={(filterTitle) ? filterTitle : ''}
            onChange={(event, value) => {
              onChangeTitle(value ? value : null);
            }}
          />
        </div>
      );
      break;
    }
    case 'column3': {
      return (
        <div>
          <Dropdown
            placeholder=""
            defaultSelectedKey={'0'}
            selectedKey={
              filterType ? filterType : '0'
            }
            options={projectTypes}
            onChange={(event, value) => {
              onChangeType(value ? value.key : null);
            }}
            styles={{}}
          />
        </div>
      );
      break;
    }
    case 'column4': {
      return (
        <div>
          <Dropdown
            placeholder=""
            defaultSelectedKey={'0'}
            selectedKey={
              filterManagementLevel ? filterManagementLevel : '0'
            }
            options={projectManagementLevels}
            onChange={(event, value) => {
              onChangeManagementLevel(value ? value.key : null);
            }}
            styles={{}}
          />
        </div>
      );
      break;
    }
    case 'column5': {
      return (
        <div>
          <Dropdown
            placeholder=""
            defaultSelectedKey={'0'}
            selectedKey={
              filterTemplate ? filterTemplate : '0'
            }
            options={projectTemplates}
            onChange={(event, value) => {
              onChangeTemplate(value ? value.key : null);
            }}
            styles={{}}
          />
        </div>
      );
      break;
    }
    case 'column6': {
      return (
        <div>
          <Dropdown
            placeholder=""
            defaultSelectedKey={'0'}
            selectedKey={
              filterToleranceState ? filterToleranceState : '0'
            }
            options={projectToleranceStates}
            onChange={(event, value) => {
              onChangeToleranceState(value ? value.key : null);
            }}
            styles={{}}
          />
        </div>
      );
      break;
    }
    case 'column7': {
      return (
        <div>
          <PeoplePicker
            label={null}
            isDisabled={false}
            selectedItem={projectManager}
            onChange={(items: any) => {
              if (items && _.isArray(items) && items.length === 0) {
                setProjectManager([]);
              } else {
                setProjectManager([]);
              }
              onChangeProjectManager(null);
            }}
            onPersonSelected={(items: any) => {
              if (items) {
                setProjectManager([{ key: items.key, text: items.text }]);
              } else {
                setProjectManager([]);
              }
              onChangeProjectManager(items.cabPersonId);
              return items;
            }}

          />
        </div>
      );
      break;
    }
    default: {
      break;
    }
  }
};

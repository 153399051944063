import React, {useEffect, useState} from 'react';
import {
    DefaultPalette,
    FontIcon,
    IconButton,
    IIconProps,
    IStackStyles,
    Label,
    mergeStyleSets,
    Pivot,
    PivotItem,
    Stack,
    TextField
} from "@fluentui/react";
import {Accordion} from "../../../../shared/accordion/accordion";
import CommentContent from "./commentContent/component";
import {useTranslation} from "react-i18next";
import {CommentType, PersonComment, personCommentData, personKey, pmolKey} from "../../../../types/timeRegistration";
import {useDispatch, useSelector} from "react-redux";
import {
    readPersonCommentCard,
    readPmolCommentCard,
    saveTimeRegistrationStateAttr,
    updateComment
} from "../../../../reducers/timeRegistrationReducer";
import _ from 'lodash';

const classNames = mergeStyleSets({
    fullWidth: {
        width: '100%',
    },
    azureMap: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    iconClass: {
        fontSize: 16,
        height: 16,
        width: 16,
        margin: '0 8px',
    },
    label: {
        display: 'flex',
        alignItems: 'center',
    }
});

interface Props {
    values: any,
    formData: any,
}

const sendIcon: IIconProps = {iconName: 'Send'};

const stackStyles: IStackStyles = {root: {margin: '0px 0px 14px 0px', display: 'flex', alignItems: 'flex-end'}};

const CommentComponent = (props: Props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {personComments, pmolComments, commentId} = useSelector(
      (state: any) => state.timeRegistration
    );

    const [selectedPivotItem, setSelectedPivotItem] = useState<any>(personKey);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [accordionId, setAccordionId] = useState<any>(null);
    const [isCommentExpanded, setIsCommentExpanded] = useState<boolean>(false);
    const [commentAccordionId, setCommentAccordionId] = useState<any>(null);
    const [personComment, setPersonComment] = useState<PersonComment[]>(personCommentData);
    const [pmolComment, setPmolComment] = useState<PersonComment[]>(personCommentData);
    const [comment, setComment] = useState<string>("");

    useEffect(() => {
        dispatch(readPersonCommentCard({pmolId: props.formData.id}));
        dispatch(readPmolCommentCard({pmolId: props.formData.id}));
    }, []);

    useEffect(() => {
        if (personComments && personComments.length > 0) {
            // console.log('personComments >> ', personComments);
            setPersonComment(personComments);
        } else {
            setPersonComment([]);
        }
    }, [personComments]);

    useEffect(() => {
        if (pmolComments && pmolComments.length > 0) {
            // console.log('personComments >> ', personComments);
            setPmolComment(pmolComments);
        } else {
            setPmolComment([]);
        }
    }, [pmolComments]);

    useEffect(() => {
        if (!_.isNull(commentId)) {
            if (!_.isNull(props.values.worker) && props.values.worker != 0) {
                dispatch(readPersonCommentCard({pmolId: props.formData.id}));

            } else {
                dispatch(readPmolCommentCard({pmolId: props.formData.id}));
            }
            dispatch(saveTimeRegistrationStateAttr('commentId', null));
        }
    }, [commentId])

    const handleIsExpand = (id: any) => {
        return id === accordionId && isExpanded;
    };

    const handleCommentIsExpand = (id: any) => {
        return id === commentAccordionId && isCommentExpanded;
    };

    return <>
        <div className={classNames.label} style={{margin: '8px 0 -8px 0'}}>
            <FontIcon
              aria-label="Comment"
              iconName="Comment"
              className={classNames.iconClass}
            />
            <Label>{t('comments')}</Label>
        </div>
        <div>
            <Pivot
              selectedKey={selectedPivotItem}
              onLinkClick={(item?: PivotItem) => {
                  if (item) {
                      if (item.props.itemKey! === pmolKey) {
                          setSelectedPivotItem(pmolKey);
                      } else if (item.props.itemKey! === personKey) {
                          setSelectedPivotItem(personKey);
                      }
                  }
              }}
              style={{width: '100%'}}
            >
                <PivotItem
                  headerText={t('pmolComments')}
                  itemKey={pmolKey}
                >
                    <div>
                        {pmolComment && pmolComment.length > 0 ? <ul style={{
                            paddingInlineStart: '4px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            padding: 8,
                        }}>
                            {pmolComment?.map((item: any) => (
                              <Accordion
                                key={item.id}
                                backgroundColor={DefaultPalette.white}
                                gap={'12px'}
                                accordianId={item.id}
                                isExpaned={handleIsExpand(item.id)}
                                header={item.activityName}
                                onChange={(accordionId: any, isExpanded: boolean) => {
                                    setIsExpanded(isExpanded);
                                    setAccordionId(accordionId);
                                }}
                              >
                                  <Stack horizontal styles={stackStyles}>
                                      <TextField
                                        value={comment}
                                        onChange={(e, value) => {
                                            value && setComment(value);
                                        }}
                                        disabled={false}
                                        multiline
                                        styles={{
                                            root: {margin: '10px 10px 0px 10px', width: '100%'},
                                            fieldGroup: {border: '1px solid rgba(0, 0, 0, 0.25)'}
                                        }}
                                        rows={5}
                                      />
                                      <IconButton
                                        iconProps={sendIcon}
                                        title="Send"
                                        ariaLabel="Send"
                                        disabled={false}
                                        onClick={() => {
                                            Promise.all([
                                                dispatch(updateComment({
                                                    commentCardId: item.id,
                                                    comment: comment,
                                                    commentTo: null,
                                                    isAccept: "0"
                                                }))
                                            ]).then((response: any) => {
                                                if (response) {
                                                    setComment("");
                                                    dispatch(readPmolCommentCard({pmolId: item.pmolId}));
                                                }
                                            });
                                        }}
                                      />
                                  </Stack>
                                  {item.personComment && item.personComment.length > 0 && <ul style={{
                                      paddingInlineStart: '4px',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      alignItems: 'flex-start',
                                      padding: 8,
                                  }}>
                                      {item.personComment.map((comments: any) => (
                                        <Accordion
                                          header={comments.comment
                                            ? comments.comment.toString().length > 20
                                              ? comments.comment.substring(0, 20).concat('...')
                                              : comments.comment
                                            : 'comment 01'}
                                          accordianId={comments.id}
                                          isExpaned={handleCommentIsExpand(comments.id)}
                                          onChange={(accordionId: string, isExpanded: boolean) => {
                                              setIsCommentExpanded(isExpanded);
                                              setCommentAccordionId(accordionId);
                                          }}
                                          gap={'12px'}
                                          backgroundColor={DefaultPalette.white}
                                        >
                                            <CommentContent
                                              cardItem={comments}
                                              commentType={CommentType.PMOL}
                                              values={item}
                                            />
                                        </Accordion>
                                      ))}
                                  </ul>}
                              </Accordion>
                            ))}
                        </ul> : <span style={{
                            paddingInlineStart: '4px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            padding: 8,
                            marginBottom: 16,
                        }}>{t('noPmolCommentsAvailable')}</span>}
                    </div>
                </PivotItem>
                <PivotItem
                  headerText={t('personComments')}
                  itemKey={personKey}
                >
                    <div>
                        {personComment && personComment.length > 0 ? <ul style={{
                            paddingInlineStart: '4px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            padding: 8,
                        }}>
                            {personComment?.map((item: any) => (
                              <Accordion
                                key={item.id}
                                backgroundColor={DefaultPalette.white}
                                gap={'12px'}
                                accordianId={item.id}
                                isExpaned={handleIsExpand(item.id)}
                                header={item.cabPersonName}
                                onChange={(accordionId: any, isExpanded: boolean) => {
                                    setIsExpanded(isExpanded);
                                    setAccordionId(accordionId);
                                }}
                              >
                                  <Stack horizontal styles={stackStyles}>
                                      <TextField
                                        onChange={(e, value) => {
                                            value && setComment(value);
                                        }}
                                        disabled={false}
                                        multiline
                                        styles={{
                                            root: {margin: '10px 10px 0px 10px', width: '100%'},
                                            fieldGroup: {border: '1px solid rgba(0, 0, 0, 0.25)'}
                                        }}
                                        rows={5}
                                      />
                                      <IconButton
                                        iconProps={sendIcon}
                                        title="Send"
                                        ariaLabel="Send"
                                        disabled={false}
                                        onClick={() => {
                                            Promise.all([
                                                dispatch(updateComment({
                                                    commentCardId: item.id,
                                                    comment: comment,
                                                    commentTo: item.cabPersonId,
                                                    isAccept: "0"
                                                }))
                                            ]).then((response: any) => {
                                                setComment("");
                                                if (response) {
                                                    dispatch(readPersonCommentCard({pmolId: item.pmolId}));
                                                }
                                            });
                                        }}
                                      />
                                  </Stack>
                                  {item.personComment && item.personComment.length > 0 && <ul style={{
                                      paddingInlineStart: '4px',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      alignItems: 'flex-start',
                                      padding: 8,
                                  }}>
                                      {item.personComment.map((comments: any) => (
                                        <Accordion
                                          header={comments.comment
                                            ? comments.comment.toString().length > 20
                                              ? comments.comment.substring(0, 20).concat('...')
                                              : comments.comment
                                            : 'comment 01'}
                                          accordianId={comments.id}
                                          isExpaned={handleCommentIsExpand(comments.id)}
                                          onChange={(accordionId: string, isExpanded: boolean) => {
                                              setIsCommentExpanded(isExpanded);
                                              setCommentAccordionId(accordionId);
                                          }}
                                          gap={'12px'}
                                          backgroundColor={DefaultPalette.white}
                                        >
                                            <CommentContent
                                              cardItem={comments}
                                              commentType={CommentType.PERSON}
                                            />
                                        </Accordion>
                                      ))}
                                  </ul>}
                              </Accordion>
                            ))}
                        </ul> : <span style={{
                            paddingInlineStart: '4px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            padding: 8,
                            marginBottom: 16,
                        }}>{t('noCommentsAvailable')}</span>}
                    </div>
                </PivotItem>
            </Pivot>
        </div>
    </>
}

export default CommentComponent;
import {
  DetailsRow,
  Fabric,
  IColumn,
  IDetailsListProps,
  IDetailsRowStyles,
  Link,
  MarqueeSelection,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
  Text,
} from '@fluentui/react';
import { getTheme, ITheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import * as React from 'react';
import TableColumn from '../../../shared/tableColumn/tableColumn';
import { QRCodeLite } from '../../../types/qrCode';
import ConfirmDialog from '../../cuProjects/dialog';
import { _renderDetailsFooterItemColumn } from './filterHeader';
import { useTranslation } from 'react-i18next';

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;
const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 264px)!important',
    position: 'relative',
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: {
    margin: 0,
  },
  row: {
    display: 'inline-block',
  },
});

const QRCodeListComponent = ({
                               qrCodes,
                               columns,
                               _selection,
                               isLoading,
                               dialogHidden,
                               onClickConfirmDialog,
                               onClickCancelDialog,
                               status,
                               message,
                               type,
                               onChangeType,
                               projects,
                               project,
                               onChangeProject,
                               vehicleNo,
                               onChangeVehicleNo,
                               location,
                               onChangeLocation,
                               tt,
                               onQRItemClick,
                             }: any) => {
  const _onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      return (
        <Link
          style={{ textDecoration: 'none' }}
          to={'/time_clock/' + props.item.value}
          onClick={() => {
            onQRItemClick(props.item);
          }}
        >
          <DetailsRow {...props} styles={customStyles}/>
        </Link>
      );
    }
    return null;
  };

  const { t } = useTranslation();

  return (
    <div>
      <Fabric>
        <div className={`wrapper-holder ${classNames.wrapper}`}>
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
            <MarqueeSelection selection={_selection}>
              <ShimmeredDetailsList
                items={qrCodes}
                columns={columns}
                setKey="set"
                compact={false}
                layoutMode={1}
                enableShimmer={isLoading}
                selection={_selection}
                selectionPreservedOnEmptyClick={true}
                checkboxVisibility={2}
                onRenderItemColumn={(
                  item: QRCodeLite,
                  index?: number,
                  column?: IColumn,
                ) => TableColumn(item, index, column)}
                onRenderDetailsFooter={(detailsFooterProps, defaultRender) => {
                  if (defaultRender !== undefined) {
                    return (
                      <DetailsRow
                        className="footer-as-filter"
                        {...detailsFooterProps}
                        columns={detailsFooterProps?.columns}
                        item={{}}
                        itemIndex={-1}
                        groupNestingDepth={
                          detailsFooterProps?.groupNestingDepth
                        }
                        selectionMode={SelectionMode.single}
                        selection={detailsFooterProps?.selection}
                        onRenderItemColumn={(
                          item?: any,
                          index?: number,
                          column?: IColumn,
                        ) =>
                          _renderDetailsFooterItemColumn(
                            type,
                            onChangeType,
                            projects,
                            project,
                            onChangeProject,
                            vehicleNo,
                            onChangeVehicleNo,
                            location,
                            onChangeLocation,
                            t,
                            item,
                            index,
                            column,
                          )
                        }
                      />
                    );
                  } else {
                    return <span></span>;
                  }
                }}
                onRenderDetailsHeader={(headerProps, defaultRender) => {
                  if (defaultRender !== undefined) {
                    return (
                      <Sticky
                        stickyPosition={StickyPositionType.Header}
                        isScrollSynced={true}
                        stickyBackgroundColor="transparent"
                      >
                        <div>{defaultRender(headerProps)}</div>
                      </Sticky>
                    );
                  } else {
                    return <span></span>;
                  }
                }}
                onItemInvoked={() => {
                }}
                onRenderRow={_onRenderRow}
              />
              <ConfirmDialog
                show={dialogHidden}
                onClickCancelDialog={onClickCancelDialog}
                onClickConfirmDialog={onClickConfirmDialog}
              />
            </MarqueeSelection>
          </ScrollablePane>
          {!status && (
            <div style={{ padding: 20, paddingTop: 150 }}>
              <Text>{t(message)}</Text>
            </div>
          )}
        </div>
      </Fabric>
    </div>
  );
};

export default QRCodeListComponent;

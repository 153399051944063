import React from 'react';
import { Avatar, Tooltip } from '@fluentui/react-components';
import {
  Attach20Filled,
  CalendarArrowCounterclockwise20Regular,
  CheckboxChecked20Regular,
  CheckboxUnchecked20Filled,
  Mail20Regular,
  Star20Filled,
  Star20Regular,
} from '@fluentui/react-icons';
import moment from 'moment';

interface Props {
  toPerson: any;
  isFavorite: boolean;
  date: string;
  completedTask: number;
  totalTask: number;
  totalAttachment: number;
  totalMail: number;
  onClickIsFav: any;
  totalInstructions: number;
  totalCheckedInstructions: number;
}

const renderAsideContent = (props: Props) => {
  const {
    toPerson,
    isFavorite,
    date,
    completedTask,
    totalTask,
    totalAttachment,
    totalMail,
    onClickIsFav,
    totalInstructions,
    totalCheckedInstructions,
  } = props;

  return (
    <>
      {renderAvatar(toPerson)}
      {renderStarIcon(isFavorite, (e: any) => onClickIsFav(e, props, !isFavorite))}
      {totalInstructions != 0 && <div className={'tree-aside-content task'}>
        <CheckboxUnchecked20Filled /> {`${totalCheckedInstructions}/${totalInstructions}`}
      </div>}
      {date && <div className={'tree-aside-content date'}>
        <CalendarArrowCounterclockwise20Regular />{moment(date).format('D/MM')}
      </div>}
      {totalTask && <div className={'tree-aside-content task'}>
        <CheckboxChecked20Regular /> {`${completedTask}/${totalTask}`}
      </div>}
      {renderIconWithText(<Attach20Filled />, totalAttachment, 40)}
      {renderIconWithText(<Mail20Regular />, totalMail, 40)}
    </>
  );
};

const renderAvatar = (toPerson: any) => (
  toPerson && toPerson.length > 0 &&
  <Tooltip content={renderToPersons(toPerson)} relationship='label'>
    <div className={'tree-aside-content'} style={{ minWidth: 30 }}>
      <Avatar size={20} aria-label='Guest' />
    </div>
  </Tooltip>
);

const renderToPersons = (toPersons: any) => {
  return <>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {toPersons?.map((person: any) => {
        return <span>{person?.personName}</span>;
      })}
    </div>
  </>;
};

const renderStarIcon = (isFavorite: boolean, onClick: any) => (
  <div className={'tree-aside-content'} style={{ minWidth: 30 }}>
    {isFavorite ? <Star20Filled onClick={onClick} /> : <Star20Regular onClick={onClick} />}
  </div>
);

const renderIconWithText = (icon: any, text: any, minWidth: number) => (
  <div className={'tree-aside-content'} style={{ minWidth: minWidth }}>
    {icon} {text}
  </div>
);

export default renderAsideContent;

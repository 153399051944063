import {
  CREATE_CPC_RESOURCE_FAMILY,
  CREATE_CPC_RESOURCE_FAMILY_FAIL,
  CREATE_CPC_RESOURCE_FAMILY_SUCCESS,
  DELETE_PRODUCT_CATALOGUE,
  DELETE_PRODUCT_CATALOGUE_FAIL,
  DELETE_PRODUCT_CATALOGUE_IMAGE,
  DELETE_PRODUCT_CATALOGUE_IMAGE_FAIL,
  DELETE_PRODUCT_CATALOGUE_IMAGE_SUCCESS,
  DELETE_PRODUCT_CATALOGUE_NICK_NAME,
  DELETE_PRODUCT_CATALOGUE_NICK_NAME_FAIL,
  DELETE_PRODUCT_CATALOGUE_NICK_NAME_SUCCESS,
  DELETE_PRODUCT_CATALOGUE_SUCCESS,
  DELETE_PRODUCT_CATALOGUE_VENDOR,
  DELETE_PRODUCT_CATALOGUE_VENDOR_FAIL,
  DELETE_PRODUCT_CATALOGUE_VENDOR_SUCCESS,
  LOAD_CPC_RESOURCE_EXCEL_DATA,
  LOAD_CPC_RESOURCE_EXCEL_DATA_FAIL,
  LOAD_CPC_RESOURCE_EXCEL_DATA_SUCCESS,
  LOAD_CPC_RESOURCE_FAMILY,
  LOAD_CPC_RESOURCE_FAMILY_FAIL,
  LOAD_CPC_RESOURCE_FAMILY_SUCCESS,
  LOAD_ORGANIZATION_LIST,
  LOAD_ORGANIZATION_LIST_FAIL,
  LOAD_ORGANIZATION_LIST_SUCCESS,
  LOAD_PRODUCT_CATALOGUE,
  LOAD_PRODUCT_CATALOGUE_BY_ID,
  LOAD_PRODUCT_CATALOGUE_BY_ID_FAIL,
  LOAD_PRODUCT_CATALOGUE_BY_ID_SUCCESS,
  LOAD_PRODUCT_CATALOGUE_DROPDOWN_DATA,
  LOAD_PRODUCT_CATALOGUE_DROPDOWN_DATA_FAIL,
  LOAD_PRODUCT_CATALOGUE_DROPDOWN_DATA_SUCCESS,
  LOAD_PRODUCT_CATALOGUE_FAIL,
  LOAD_PRODUCT_CATALOGUE_SHORTCUT_PANE_FILTER_LIST,
  LOAD_PRODUCT_CATALOGUE_SHORTCUT_PANE_FILTER_LIST_FAIL,
  LOAD_PRODUCT_CATALOGUE_SHORTCUT_PANE_FILTER_LIST_SUCCESS,
  LOAD_PRODUCT_CATALOGUE_SUCCESS,
  LOAD_PRODUCT_CATALOGUE_UPLOAD_IMAGE,
  LOAD_PRODUCT_CATALOGUE_UPLOAD_IMAGE_FAIL,
  LOAD_PRODUCT_CATALOGUE_UPLOAD_IMAGE_SUCCESS,
  LOAD_VEHICLE_TRACKING_LIST_DATA,
  LOAD_VEHICLE_TRACKING_LIST_DATA_FAIL,
  LOAD_VEHICLE_TRACKING_LIST_DATA_SUCCESS,
  NEW_PRODUCT_CATALOGUE,
  SAVE_PRODUCT_CATALOGUE,
  SAVE_PRODUCT_CATALOGUE_FAIL,
  SAVE_PRODUCT_CATALOGUE_SUCCESS,
} from '../actionTypes/productCatalogueType';
import UprinceLogger from '../shared/Logger/logger';
import {CorporateProductCatalogue, ProductCatalogueFilter} from '../types/corporateProductCatalogue';
import LocaleList from './../components/productCatalogue/localeList.json';
import i18n from '../../i18n';
import {
  CPC_CREATE_EP,
  CPC_READ_DROPDOWN_DATA_EP,
  CPC_READ_SHORTCUT_PANE_DATA,
  CREATE_CPC_RESOURCE_FAMILY_EP,
  LOAD_CPC_RESOURCE_FAMILY_EP,
  VEHICLE_TRACKING_LIST_EP
} from '../shared/endpoints';

// let uploadedImages:ProductCatalogueImage[];
//     uploadedImages=[];
const defaultState: any = {
  productCatalogue: [],
  cpcListItem: [],
  shortCutPaneFilters: [],
  uploadedImages: null,
  resourceTypes: [],
  resourceTypeFilter: [],
  resourceFamily: [],
  cpcBasicUnitOfMeasure: [],
  cpcUnitOfSizeMeasure: [],
  cpcPressureClass: [],
  cpcMaterial: [],
  cpcBrand: [],
  languageCodes: [],
  organizations: [],
  excelData: [],
  loadMsgType: '',
  loadMsg: '',
  alertMsg: '',
  alertMsgType: '',
  loading: false,
  isDataLoaded: false,
  isDetailsLoaded: true,
  isChange: false,
  autoSave: false,
  isCreateNewCpc: false,
  showProgressBar: false,
  dropdownList: [],
};

const productCatalogueReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case NEW_PRODUCT_CATALOGUE:
      UprinceLogger.log('newProductCatalogue', '1' + state.productCatalogue);
      return { ...state, productCatalogue: [] };

    case LOAD_PRODUCT_CATALOGUE:
      return { ...state, loading: true, isChange: false, cpcListItem: [], isDataLoaded: false };
    case LOAD_PRODUCT_CATALOGUE_SUCCESS:
      return {
        ...state,
        isChange: false,
        loading: false,
        isDataLoaded: true,
        excelData: [],
        cpcListItem: formattedPayloadToListPaneTable(action.payload),
        loadMsg: (!action.payload.data.status) ? action.payload.data.message : '',
        alertMsg: '',
        alertMsgType: 'success',
      };
    case LOAD_PRODUCT_CATALOGUE_FAIL:
      return {
        ...state,
        cpcListItem: [],
        isChange: false,
        isDataLoaded: true,
        loadMsg: (action.error.response) ? action.error.response.data.message : '',
        loadMsgType: 'error',
        loading: false,
        error: 'Error while fetching Time Clock',
      };

    case LOAD_PRODUCT_CATALOGUE_SHORTCUT_PANE_FILTER_LIST:
      return { ...state, loading: true };
    case LOAD_PRODUCT_CATALOGUE_SHORTCUT_PANE_FILTER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        shortCutPaneFilters: action.payload.data.result,
      };
    case LOAD_PRODUCT_CATALOGUE_SHORTCUT_PANE_FILTER_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: 'Error while fetching Time Clock',
      };

    case SAVE_PRODUCT_CATALOGUE:
      return { ...state, loading: true, autoSave: false, showProgressBar: true };
    case SAVE_PRODUCT_CATALOGUE_SUCCESS:
      UprinceLogger.log('uploadedImages defaultState', action.payload.data.result);
      return {
        ...state,
        isChange: true,
        autoSave: false,
        showProgressBar: false,
        loading: false,
        uploadedImages: null,
        //isCreateNewCpc:true,
        productCatalogue: formattedPayloadToFormData(action.payload.data.result),
        loadMsg: (!action.payload.data.status) ? action.payload.data.message : '',
        alertMsg: '',
        alertMsgType: 'success',
      };
    case SAVE_PRODUCT_CATALOGUE_FAIL:
      return {
        ...state,
        autoSave: false,
        showProgressBar: false,
        loadMsg: action.error.response.data.message,
        loadMsgType: 'error',
        loading: false,
        error: 'Error while fetching Time Clock',
      };


    case DELETE_PRODUCT_CATALOGUE_NICK_NAME:
      return { ...state, loading: true, autoSave: false };
    case DELETE_PRODUCT_CATALOGUE_NICK_NAME_SUCCESS:
      UprinceLogger.log('uploadedImages defaultState', action.payload.data.result);
      return {
        ...state,
        autoSave: true,
        loading: false,
        alertMsg: '',
        alertMsgType: 'success',
      };
    case DELETE_PRODUCT_CATALOGUE_NICK_NAME_FAIL:
      return {
        ...state,
        autoSave: false,
        loadMsg: action.error.response.data.message,
        loadMsgType: 'error',
        loading: false,
        error: 'Error while fetching Time Clock',
      };


    case DELETE_PRODUCT_CATALOGUE_VENDOR:
      return { ...state, loading: true, autoSave: false };
    case DELETE_PRODUCT_CATALOGUE_VENDOR_SUCCESS:
      UprinceLogger.log('uploadedImages defaultState', action.payload.data.result);
      return {
        ...state,
        autoSave: true,
        loading: false,
        loadMsg: (!action.payload.data.status) ? action.payload.data.message : '',
        alertMsg: '',
        alertMsgType: 'success',
      };
    case DELETE_PRODUCT_CATALOGUE_VENDOR_FAIL:
      return {
        ...state,
        autoSave: false,
        loadMsg: action.error.response.data.message,
        loadMsgType: 'error',
        loading: false,
        error: 'Error while fetching Time Clock',
      };

    case DELETE_PRODUCT_CATALOGUE_IMAGE:
      return { ...state, loading: true, autoSave: false };
    case DELETE_PRODUCT_CATALOGUE_IMAGE_SUCCESS:
      UprinceLogger.log('uploadedImages defaultState', action.payload.data.result);
      return {
        ...state,
        autoSave: true,
        loading: false,
        loadMsg: (!action.payload.data.status) ? action.payload.data.message : '',
        alertMsg: '',
        alertMsgType: 'success',
      };
    case DELETE_PRODUCT_CATALOGUE_IMAGE_FAIL:
      return {
        ...state,
        autoSave: false,
        loadMsg: action.error.response.data.message,
        loadMsgType: 'error',
        loading: false,
        error: 'Error while fetching Time Clock',
      };


    case DELETE_PRODUCT_CATALOGUE:
      return { ...state, loading: true, autoSave: false };
    case DELETE_PRODUCT_CATALOGUE_SUCCESS:
      return {
        ...state,
        isChange: true,
        loading: false,
        alertMsg: '',
        alertMsgType: 'success',
      };
    case DELETE_PRODUCT_CATALOGUE_FAIL:
      return {
        ...state,
        autoSave: false,
        loadMsg: action.error.response.data.message,
        loadMsgType: 'error',
        loading: false,
        error: 'Error while fetching CPC',
      };


    case LOAD_PRODUCT_CATALOGUE_BY_ID:
      return { ...state, loading: true, autoSave: false, isChange: false, productCatalogue: [], isCreateNewCpc: false };
    case LOAD_PRODUCT_CATALOGUE_BY_ID_SUCCESS:
      return {
        ...state,
        autoSave: false,
        loading: false,
        isCreateNewCpc: false,
        uploadedImages: null,
        productCatalogue: formattedPayloadToFormData(action.payload.data.result),
        //productCatalogue:action.payload.data.result,
        //loadMsg: (!action.payload.data.status)?action.payload.data.message:"",
        alertMsg: '',
        alertMsgType: 'success',
      };
    case LOAD_PRODUCT_CATALOGUE_BY_ID_FAIL:
      return {
        ...state,
        autoSave: false,
        isCreateNewCpc: false,
        productCatalogue: [],
        //loadMsg: action.error.response.data.message,
        loadMsgType: 'error',
        loading: false,
        error: 'Error while fetching Time Clock',
      };


    case LOAD_PRODUCT_CATALOGUE_UPLOAD_IMAGE:
      return { ...state, loading: true, uploadedImages: null, autoSave: false };
    case LOAD_PRODUCT_CATALOGUE_UPLOAD_IMAGE_SUCCESS:
      UprinceLogger.log('uploadedImages defaultState', defaultState);
      return {
        ...state,
        loading: false,
        autoSave: true,
        uploadedImages: (action.payload.data.status) ? action.payload.data.result : null,
        //uploadedImages:getImagePath(defaultState,action.payload),
        //uploadedImages:(action.payload.data.status)? state.uploadedImages.push({ thumbnail: action.payload.data.result }):false,
        loadMsg: (!action.payload.data.status) ? action.payload.data.message : '',
        alertMsg: '',
        alertMsgType: 'success',
      };
    case LOAD_PRODUCT_CATALOGUE_UPLOAD_IMAGE_FAIL:
      return {
        ...state,
        autoSave: false,
        loadMsg: action.error.response.data.message,
        loadMsgType: 'error',
        loading: false,
        error: 'Error while fetching Time Clock',
      };

    case LOAD_PRODUCT_CATALOGUE_DROPDOWN_DATA:
      return {
        ...state,
        loading: true,
        isChange: false,
        uploadedImages: null,
        resourceTypes: [],
        cpcBasicUnitOfMeasure: [],
        cpcPressureClass: [],
        cpcMaterial: [],
        cpcBrand: [],
        productCatalogue: [],
        languageCodes: [],
        dropdownList: [],
      };

    case LOAD_PRODUCT_CATALOGUE_DROPDOWN_DATA_SUCCESS:
      UprinceLogger.log('DROPDOWN_DATA', action.payload);
      return {
        ...state,
        loading: false,
        resourceTypes: formattedPayloadToDropdown(action.payload.data.result.cpcResourceType),
        //resourceFamily: formattedPayloadToDropdown(action.payload.data.result.cpcResourceFamily),
        cpcBasicUnitOfMeasure: formattedPayloadToDropdown(action.payload.data.result.cpcBasicUnitOfMeasure),
        languageCodes: formattedLocaleListToDropdown(),
        resourceTypeFilter: formattedPayloadToFilterDropdown(action.payload.data.result.cpcResourceType),
        cpcPressureClass: formattedPayloadToDropdown(action.payload.data.result.cpcPressureClass),
        cpcMaterial: formattedPayloadToDropdown(action.payload.data.result.cpcMaterial),
        cpcBrand: formattedPayloadToDropdown(action.payload.data.result.cpcBrand),
        cpcUnitOfSizeMeasure: formattedPayloadToDropdown(action.payload.data.result.cpcUnitOfSizeMeasure),
        cpcDropdownList: formattedPayloadToDropdownList(action.payload.data.result),

      };
    case LOAD_PRODUCT_CATALOGUE_DROPDOWN_DATA_FAIL:
      return {
        ...state,
        resourceTypes: [],
        cpcBasicUnitOfMeasure: [],
        languageCodes: [],
        cpcPressureClass: [],
        cpcMaterial: [],
        cpcBrand: [],
        loadMsg: action.error.response.data.message,
        loadMsgType: 'error',
        loading: false,
        error: 'Error while fetching Time Clock',
      };

    case LOAD_ORGANIZATION_LIST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_ORGANIZATION_LIST_SUCCESS:
      UprinceLogger.log('DROPDOWN_DATA', action.payload);
      return {
        ...state,
        loading: false,
        organizations: formattedPayloadToSearchDropdown(action.payload.data.result),
      };
    case LOAD_ORGANIZATION_LIST_FAIL:
      return {
        ...state,
        organizations: [],
        loadMsgType: 'error',
        loading: false,
        error: 'Error while fetching Time Clock',
      };

    case LOAD_CPC_RESOURCE_FAMILY:
      return {
        ...state,
        loading: true,
        resourceFamily: [],
      };
    case LOAD_CPC_RESOURCE_FAMILY_SUCCESS:
      return {
        ...state,
        loading: false,
        //resourceFamily: testTreeData(),
        resourceFamily: action.payload.data.result,
      };
    case LOAD_CPC_RESOURCE_FAMILY_FAIL:
      return {
        ...state,
        resourceFamily: [],
        loadMsgType: 'error',
        loading: false,
        error: 'Error while fetching Time Clock',
      };

    case LOAD_CPC_RESOURCE_EXCEL_DATA:
      return {
        ...state,
        loading: true,
        excelData: [],
      };
    case LOAD_CPC_RESOURCE_EXCEL_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        excelData: formattedPayloadToExcel(action.payload),
      };
    case LOAD_CPC_RESOURCE_EXCEL_DATA_FAIL:
      return {
        ...state,
        excelData: [],
        loadMsgType: 'error',
        loading: false,
        error: 'Error while fetching Time Clock',
      };

    case CREATE_CPC_RESOURCE_FAMILY:
      return {
        ...state,
      };
    case CREATE_CPC_RESOURCE_FAMILY_SUCCESS:
      return {
        ...state,
      };
    case CREATE_CPC_RESOURCE_FAMILY_FAIL:
      return {
        ...state,
      };

    case LOAD_VEHICLE_TRACKING_LIST_DATA:
      return {
        ...state,
        loading: true,
      };
    case LOAD_VEHICLE_TRACKING_LIST_DATA_SUCCESS:
      UprinceLogger.log('DROPDOWN_DATA', action.payload);
      return {
        ...state,
        loading: false,
        vehicleTrackingNumberList: formattedVehicleTrackingNumberDropDown(action.payload.data.result),
      };
    case LOAD_VEHICLE_TRACKING_LIST_DATA_FAIL:
      return {
        ...state,
        loading: false,
        vehicleTrackingNumberList: [],
      };

    default:
      return state;
  }
};

export const newProductCatalogue = () => {
  return {
    type: NEW_PRODUCT_CATALOGUE,
  };
};

export const getAllProductCatalogue = (filterData: ProductCatalogueFilter) => {
  return {
    types: [LOAD_PRODUCT_CATALOGUE, LOAD_PRODUCT_CATALOGUE_SUCCESS, LOAD_PRODUCT_CATALOGUE_FAIL],
    payload: {
      request: {
        url: 'CorporateProductCatalog/CpcFilter',
        method: 'POST',
        data: filterData,
      },
    },
  };
};

export const getCpcShortcutPaneFilterList = () => {
  return {
    types: [LOAD_PRODUCT_CATALOGUE_SHORTCUT_PANE_FILTER_LIST, LOAD_PRODUCT_CATALOGUE_SHORTCUT_PANE_FILTER_LIST_SUCCESS, LOAD_PRODUCT_CATALOGUE_SHORTCUT_PANE_FILTER_LIST_FAIL],
    payload: {
      request: {
        url: CPC_READ_SHORTCUT_PANE_DATA,
        method: 'GET',
      },
    },
  };
};

export const getOrganizationByName = (name: string) => {
  return {
    types: [LOAD_ORGANIZATION_LIST, LOAD_ORGANIZATION_LIST_SUCCESS, LOAD_ORGANIZATION_LIST_FAIL],
    payload: {
      request: {
        url: `CentralAddressBook/ReadCabOrganizationList/${name}`,
        method: 'GET',
      },
    },
  };
};

export const deleteProductCatalogueNickName = (idList: [] | string[]) => {
  return {
    types: [DELETE_PRODUCT_CATALOGUE_NICK_NAME, DELETE_PRODUCT_CATALOGUE_NICK_NAME_SUCCESS, DELETE_PRODUCT_CATALOGUE_NICK_NAME_FAIL],
    payload: {
      request: {
        url: 'CorporateProductCatalog/DeleteNickName',
        method: 'DELETE',
        data: idList,
      },
    },
  };
};

export const deleteProductCatalogueVendor = (idList: [] | string[]) => {
  return {
    types: [DELETE_PRODUCT_CATALOGUE_VENDOR, DELETE_PRODUCT_CATALOGUE_VENDOR_SUCCESS, DELETE_PRODUCT_CATALOGUE_VENDOR_FAIL],
    payload: {
      request: {
        url: 'CorporateProductCatalog/DeleteVendor',
        method: 'DELETE',
        data: idList,
      },
    },
  };
};

export const deleteProductCatalogueImage = (idList: [] | string[]) => {
  return {
    types: [DELETE_PRODUCT_CATALOGUE_IMAGE, DELETE_PRODUCT_CATALOGUE_IMAGE_SUCCESS, DELETE_PRODUCT_CATALOGUE_IMAGE_FAIL],
    payload: {
      request: {
        url: 'CorporateProductCatalog/DeleteImage',
        method: 'DELETE',
        data: idList,
      },
    },
  };
};


export const deleteProductCatalogue = (idList: [] | string[]) => {
  return {
    types: [DELETE_PRODUCT_CATALOGUE, DELETE_PRODUCT_CATALOGUE_SUCCESS, DELETE_PRODUCT_CATALOGUE_FAIL],
    payload: {
      request: {
        url: 'CorporateProductCatalog/DeleteCorporateProductCatalog',
        method: 'DELETE',
        data: idList,
      },
    },
  };
};

export const getProductCatalogueById = (id: string) => {
  return {
    types: [LOAD_PRODUCT_CATALOGUE_BY_ID, LOAD_PRODUCT_CATALOGUE_BY_ID_SUCCESS, LOAD_PRODUCT_CATALOGUE_BY_ID_FAIL],
    payload: {
      request: {
        url: 'CorporateProductCatalog/Read/' + id,
        method: 'GET',
      },
    },
  };
};

export const getDropdownData = () => {
  return {
    types: [LOAD_PRODUCT_CATALOGUE_DROPDOWN_DATA, LOAD_PRODUCT_CATALOGUE_DROPDOWN_DATA_SUCCESS, LOAD_PRODUCT_CATALOGUE_DROPDOWN_DATA_FAIL],
    payload: {
      request: {
        //url: 'CorporateProductCatalog/ReadDropdownData',
        url: CPC_READ_DROPDOWN_DATA_EP,
        method: 'GET',
      },
    },
  };
};

export const getVehicleList = () => {
  return {
    types: [
      LOAD_VEHICLE_TRACKING_LIST_DATA,
      LOAD_VEHICLE_TRACKING_LIST_DATA_SUCCESS,
      LOAD_VEHICLE_TRACKING_LIST_DATA_FAIL
    ],
    payload: {
      request: {
        url: VEHICLE_TRACKING_LIST_EP,
        method: 'GET',
      },
    },
  };
};

export const getCpcResourceFamily = () => {
  return {
    types: [LOAD_CPC_RESOURCE_FAMILY, LOAD_CPC_RESOURCE_FAMILY_SUCCESS, LOAD_CPC_RESOURCE_FAMILY_FAIL],
    payload: {
      request: {
        url: LOAD_CPC_RESOURCE_FAMILY_EP,
        method: 'GET',
      },
    },
  };
};

export const savePicturesOfTheInstallation = (selectedFiles: FileList | null) => {
  const data = new FormData();
  if (selectedFiles) {
    for (const file of selectedFiles) {
      data.append('image', file);
    }
  }
  return {
    types: [LOAD_PRODUCT_CATALOGUE_UPLOAD_IMAGE, LOAD_PRODUCT_CATALOGUE_UPLOAD_IMAGE_SUCCESS, LOAD_PRODUCT_CATALOGUE_UPLOAD_IMAGE_FAIL],
    payload: {
      request: {
        url: 'CorporateProductCatalog/UploadImage',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: data,
      },
    },
  };
};

export const saveProductCatalogue = (productCatalogue: CorporateProductCatalogue) => {
  return {
    types: [SAVE_PRODUCT_CATALOGUE, SAVE_PRODUCT_CATALOGUE_SUCCESS, SAVE_PRODUCT_CATALOGUE_FAIL],
    payload: {
      request: {
        url: CPC_CREATE_EP,
        method: 'POST',
        data: productCatalogue,
      },
    },
  };
};

export const getExcelData = () => {
  return {
    types: [LOAD_CPC_RESOURCE_EXCEL_DATA, LOAD_CPC_RESOURCE_EXCEL_DATA_SUCCESS, LOAD_CPC_RESOURCE_EXCEL_DATA_FAIL],
    payload: {
      request: {
        url: 'CorporateProductCatalog/GetCpcToExport',
        method: 'GET',
      },
    },
  };
};

export const createCpcResourceFamily = (data: any) => {
  return {
    types: [CREATE_CPC_RESOURCE_FAMILY, CREATE_CPC_RESOURCE_FAMILY_SUCCESS, CREATE_CPC_RESOURCE_FAMILY_FAIL],
    payload: {
      request: {
        url: CREATE_CPC_RESOURCE_FAMILY_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

const getImagePath = (state = defaultState, response: any) => {
  if (response && response.data.status) {
    UprinceLogger.log('uploadedImages defaultState', state.uploadedImages);
    let currentImages = state.uploadedImages;
    currentImages.push({ id: null, image: response.data.result });
    UprinceLogger.log('uploadedImages currentImages', currentImages);
    return currentImages;
  }
};

const formattedPayloadToFormData = (response: any) => {
  if (response) {
    let data = response;
    if (response.resourceType) {
      data['resourceTypeId'] = response.resourceType.key;
    }
    if (response.cpcBasicUnitOfMeasure) {
      data['cpcBasicUnitOfMeasureId'] = response.cpcBasicUnitOfMeasure.key;
    }
    if (response.resourceFamily) {
      data['resourceFamilyId'] = response.resourceFamily.key;
    }
    if (response.cpcPressureClass) {
      data['cpcPressureClassId'] = response.cpcPressureClass.key;
    }
    if (response.cpcBrand) {
      data['cpcBrandId'] = response.cpcBrand.key;
    }
    if (response.cpcUnitOfSizeMeasure) {
      data['cpcUnitOfSizeMeasureId'] = response.cpcUnitOfSizeMeasure.key;
    }
    return data;
  } else {
    return [];
  }
};
const formattedPayloadToDropdown = (response: any) => {
  if (response) {
    let data = response.map((item: any) => {
      return {
        key: item.id,
        text: item.name,
        isDefault: item.isDefault,
      };
    });
    return data;
  } else {
    return [];
  }
};

const formattedPayloadToDropdownList = (response: any) => {
  if (response) {
    let keys = Object.keys(response);
    let data = keys.map((item: any) => {
      return {
        key: item,
        text: item,

      };
    });
    return data;
  } else {
    return [];
  }
};


const formattedPayloadToSearchDropdown = (response: any) => {
  if (response) {
    let data = response.map((item: any) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    return data;
  } else {
    return [];
  }
};

const formattedPayloadToFilterDropdown = (response: any) => {
  if (response) {
    let data = response.map((item: any) => {
      return {
        key: item.id,
        text: item.name,
        isDefault: item.isDefault,
      };
    });
    let defualtOption = [
      {
        key: '0',
        text: i18n.t('all'),
        isDefault: true,
      },
    ];
    Array.prototype.push.apply(
      defualtOption,
      data,
    );
    return defualtOption;
  } else {
    return [];
  }
};

const formattedLocaleListToDropdown = () => {
  if (LocaleList) {
    let data = LocaleList.map((item: any) => {
      // return {
      //   key: item.localeCode,
      //   text: item.description,
      //   isDefault: false,
      // };
      return {
        value: item.localeCode,
        label: item.description,
      };
    });
    return data;
  } else {
    return [];
  }
};


const formattedPayloadToListPaneTable = (response: any) => {
  if (response.data.result) {
    let data = response.data.result.map((item: any) => {
      return {
        key: item.resourceNumber,
        id: item.resourceNumber,
        resourceNumber: item.resourceNumber,
        resourceTitle: item.resourceTitle,
        resourceType: (item.resourceType) ? item.resourceType : '',
        resourceFamily: item.resourceFamily,
        title: item.title,
        headerTitle: item.headerTitle,
        status: (item.status == 1) ? i18n.t('active') : i18n.t('draft'),
      };
    });
    return data;
  } else {
    return [];
  }

};


const formattedPayloadToExcel = (response: any) => {
  if (response.data.result) {
    let data = response.data.result.map((item: any) => {
      return {
        'CPC Title': item.title,
        'CPC Resource Type': item.resourceType,
        'CPC Resource Family': item.resourceFamily,
        'CPC Basic Unit Of Measure': item.cpcBasicUnitOfMeasure,
        'CPC Material': item.cpcMaterial,
        'CPC Pressure Class': item.cpcPressureClass,
        'CPC size': item.size,
        'CPC Unit Of Size Measure': item.cpcUnitOfSizeMeasure,
        'CPC Wall Thickness': item.wallThickness,
        'CPC Inventory Price': item.inventoryPrice,
        'CPC Min Order Quantity': item.minOrderQuantity,
        'CPC Max Order Quantity': item.maxOrderQuantity,
        'CPC Weight': item.weight,
      };
    });
    return data;
  } else {
    return [];
  }

};

const formattedVehicleTrackingNumberDropDown = (response: any) => {
  if (response) {
    return response.map((item: any) => {
      return {
        key: item.id,
        text: item.name,
      };
    });
  } else {
    return [];
  }
}

const testTreeData = () => {
  // let data=[
  //   {"id":"88bc4819-5968-4c38-8a59-20da60ecb89e","title":"title1","localeCode":null,"parentId":null,"displayOrder":0},
  //   {"id":"88bc4819-b78m-4c38-8a59-20da60ecb89e","title":"title2","localeCode":null,"parentId":"88bc4819-hn12-4c38-8a59-20da60ecb89e","displayOrder":0},
  //   {"id":"88bc4819-dn12-4c38-8a59-20da60ecb89e","title":"title3","localeCode":null,"parentId":null,"displayOrder":0},
  //   {"id":"88bc4819-hn12-4c38-8a59-20da60ecb89e","title":"title3 1","localeCode":null,"parentId":"88bc4819-dn12-4c38-8a59-20da60ecb89e","displayOrder":0},
  //   {"id":"88bc4819-qn12-4c38-8a59-20da60ecb89e","title":"title5","localeCode":null,"parentId":"88bc4819-qnmp-4c38-8a59-20da60ecb89e","displayOrder":0},
  //   {"id":"88bc4819-qnmp-4c38-8a59-20da60ecb89e","title":"title6","localeCode":null,"parentId":"88bc4819-wbnk-4c38-8a59-20da60ecb89e","displayOrder":0},
  //   {"id":"88bc4819-wbnk-4c38-8a59-20da60ecb89e","title":"title7","localeCode":null,"parentId":null,"displayOrder":0}]

  const testData = [
    {
      id: '88bc4819-5968-4c38-8a59-20da60ecb89e',
      title: 'Resource Family 1',
      localeCode: null,
      parentId: null,
      displayOrder: 0,
    },
    {
      id: '88bc4819-b78m-4c38-8a59-20da60ecb89e',
      title: 'Pathum',
      localeCode: null,
      parentId: '88bc4819-hn12-4c38-8a59-20da60ecb89e',
      displayOrder: 0,
      expanded: true,
    },
    {
      id: '88bc4819-dn12-4c38-8a59-20da60ecb89e',
      title: 'Mesith',
      localeCode: null,
      parentId: null,
      displayOrder: 0,
    },
    {
      id: '88bc4819-hn12-4c38-8a59-20da60ecb89e',
      title: 'Sahan',
      localeCode: null,
      parentId: '88bc4819-dn12-4c38-8a59-20da60ecb89e',
      displayOrder: 0,
    },
    {
      id: '88bc4819-qn12-4c38-8a59-20da60ecb89e',
      title: 'Shanuka',
      localeCode: null,
      parentId: '88bc4819-qnmp-4c38-8a59-20da60ecb89e',
      displayOrder: 0,
    },
    {
      id: '88bc4819-qnmp-4c38-8a59-20da60ecb89e',
      title: 'Kasun',
      localeCode: null,
      parentId: '88bc4819-wbnk-4c38-8a59-20da60ecb89e',
      displayOrder: 0,
    },
    {
      id: '88bc4819-wbnk-4c38-8a59-20da60ecb89e',
      title: 'Sampath',
      localeCode: null,
      parentId: null,
      displayOrder: 0,
    },
  ];
  return testData;
};
export default productCatalogueReducer;

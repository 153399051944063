import * as React from 'react';
import { Button, Divider, Label } from '@fluentui/react-components';
import FilesList from './FilesList';
import { useEffect, useState } from 'react';
import AddDocument from './AddDocument';
import AddExtraDocument from './EditDocumentContentType';

const Document = ({ documents, onChange }: any) => {
  const selectedAttachments: any = [
    {
      name: 'Attachment.doc',
      attachmentType: 'doc',
      contentType: 'test',
      isInline: true,
      id: '11',
      size: 20,
    },
    {
      name: 'Attachment2.xls',
      attachmentType: 'xls',
      contentType: 'test',
      isInline: true,
      id: '11',
      size: 20,
    },
    {
      name: 'Attachment3.doc',
      attachmentType: 'doc',
      contentType: 'test',
      isInline: true,
      id: '11',
      size: 20,
    },
    {
      name: 'Attachment4.xls',
      attachmentType: 'xls',
      contentType: 'test',
      isInline: true,
      id: '11',
      size: 20,
    },
  ];
  const [attachments, setAttachments]: any = useState([]);

  useEffect(() => {
    setDocuments();
  }, [documents]);

  function getFileNameAndExtensionFromLink(link: any) {
    if (link) {
      const urlParts = link.split('/');
      const fileNameWithExtension = urlParts[urlParts.length - 1];

      // Extract the file extension and file name
      const parts = fileNameWithExtension.split('.');
      const extension = parts.pop();
      const fileName = decodeURIComponent(parts.join('.'));

      return { fileName, extension };
    } else {
      return { fileName: null, extension: null };
    }
  }
  const formatDocuments = () => {
    return documents.map((doc: any) => {
      let { fileName, extension } = getFileNameAndExtensionFromLink(doc?.link);
      return {
        name: fileName,
        attachmentType: extension,
        contentType: null,
        isInline: true,
        id: doc?.id,
        size: null,
        url: doc?.link,
        taskId: doc?.taskId,
      };
    });
  };
  const setDocuments = () => {
    setAttachments(formatDocuments());
  };

  const onRemoveItem = (dismissedAttachment: any) => {
    let propsDoc = [...documents].filter(
      (item: any) => item.id !== dismissedAttachment?.id
    );
    let docs = [...attachments].filter(
      (item: any) => item.id !== dismissedAttachment?.id
    );
    setAttachments(docs);
    onChange(propsDoc);
  };

  const onAddItem = (item: any) => {
    let { fileName, extension } = getFileNameAndExtensionFromLink(item?.link);
    let newItem = {
      name: fileName,
      attachmentType: extension,
      contentType: null,
      isInline: true,
      id: item?.id,
      size: null,
    };
    setAttachments([...attachments, { ...newItem }]);
    onChange([...documents, { ...item }]);
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end',
          }}
        ></div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end',
          }}
        >
          <Label style={{ marginTop: 20 }} size="large">
            Documents
          </Label>

          <AddDocument onChange={onAddItem} />
        </div>
        <div style={{ marginTop: 5, marginBottom: 5 }}>
          {' '}
          <Divider />{' '}
        </div>
        <FilesList
          files={attachments}
          onEditClick={() => {
            // navigate("/attachment");
          }}
          onDeleteClick={(attachment: any) => {
            onRemoveItem(attachment);
          }}
        />
      </div>
    </>
  );
};

export default Document;

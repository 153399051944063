import React, {
    useEffect, useState, CSSProperties, useRef,
} from 'react';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';
import { ITooltipHostStyles, TooltipHost } from '@fluentui/react';
import { messageService } from '../../services/messageService';
import moment from 'moment';
import i18n from '../../../i18n';

export interface BoxProps {
    name: string
}

interface DropResult {
    name: string
}
export const ItemTypes = {
    BOX: 'box',
}

const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };


const PmolDropBox = (props: {
    type: any,
    children: any,
    name?: string,
    item: any,
    td: boolean,
    onClick?: any,
    subItem?: any,
    week?: boolean,
    disabled?: boolean
}) => {
    const nonDropRef = useRef<HTMLDivElement>(null);
    const formatDate = (item: any) => {
        // console.log("start",moment(item.start).format('DD/MM/YYYY'))
        return moment(item.start).format('DD/MM/YYYY')
    };

    const canDropItem = (item: any) => {
        if (moment(props.item?.start).format('DD/MM/YYYY') === moment(item?.dragItem?.day).format('DD/MM/YYYY')) {
            return true;
        }

        return false;
    };

    const [{ canDrop, isOver }, dropRef] = useDrop({
        accept: props.type,
        canDrop: (item: any) => canDropItem(item),
        collect: (monitor: { canDrop: () => any; isOver: () => any; }) => ({
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver()
        }),
        drop(item: any, monitor: { didDrop: () => any; }) {
            const didDrop = monitor.didDrop()
            messageService.sendMessage({ pmolDndItem: { ...item, dropItem: props.item } });
        },
    })

    const isActive = canDrop && isOver

    const renderSubItem = (subItem: any[]) => {
        if (props.subItem && props.subItem.length > 0) {
            return subItem.map((item: any) => {
                return <div className={'dropBoxSubItem'}>
                    <TooltipHost
                        content={item.title}
                        // This id is used on the tooltip itself, not the host
                        // (so an element with this id only exists when the tooltip is shown)
                        id={item.coperateProductCatalogId}
                        calloutProps={calloutProps}
                        styles={hostStyles}
                    >
                        {item.title}
                    </TooltipHost>
                </div>
            })
        } else {

        }
    };

    const getParentWidth = (element: string) => {
        let elem = document.getElementById(element)
        if (elem && elem.parentElement) {
            //return elem.parentElement.offsetWidth;
            return "90%"
        } else {
            return "90%"
        }
    }

    //(props.week && isActive)?getParentWidth(props.item.id+"dropbox"):'100%'
    return (
        props.td ? <td
            className={isActive ? "dropBoxActive" : "dropBox"}
            ref={dropRef}
        >
            {props.children}
            {
                isActive ?
                    <div id="dropOverlay">
                        <div id="text">{i18n.t('releaseToDrop')}</div>
                    </div>
                    : false
            }
        </td> : <div
            //className={"dropBoxTdActive"}
            className={isActive ? "dropBoxTdActive" : "pmol-dropBox"}
            style={{ width: '100%', height: "100%" }}
            ref={dropRef}
        >
            {props.children}
            {
                isActive ?
                    <div id="dropOverlay">
                        <div id="text">{i18n.t('releaseToDrop')}</div>
                    </div>
                    : false
            }
            {/* {isActive ? '' : renderSubItem(props.subItem)} */}
        </div>
    );
}

export default PmolDropBox;

import * as React from 'react';
import { useEffect } from 'react';
import {
  FlatTree,
  FlatTreeItem,
  Menu,
  MenuList,
  MenuPopover,
  TreeItemLayout,
  useHeadlessFlatTree_unstable,
  useRestoreFocusTarget,
} from '@fluentui/react-components';
import './fluent-tree-style-sheet.css';
import { uPrinceTheme } from '../../../theme';
import { mergeStyleSets } from '@fluentui/react';
import { saveWbsStateAttr } from '../../reducers/wbsReducer';
import { useDispatch, useSelector } from 'react-redux';

export const Tree = (props: {
  flatTreeItems: any;
  asideContent?: any;
  mediaContent?: any;
  onClickTreeItem?: any;
  iconBefore?: any;
  onClickTitle?: any;
  stopPropagation?: boolean;
  actions?: any;
  defaultOpenItems?: any;
  onOpenChange?: any;
}) => {
  const treeItemStyles = mergeStyleSets({
    selectedItem: {
      background: uPrinceTheme.palette.themePrimary,
      color: uPrinceTheme.palette.themeLighterAlt,
      '&:hover': {
        background: `${uPrinceTheme.palette.themePrimary} !important`,
        color: `${uPrinceTheme.palette.themeLighterAlt} !important`,
      },
    },
  });

  const dispatch = useDispatch();
  const selectedTreeItem: any = useSelector(
    (state: any) => state.wbs.selectedTreeItem
  );

  useEffect(() => {
    dispatch(saveWbsStateAttr('selectedTreeItem', null));
  }, []);

  const otherProps = (flatTreeItem: any) => {
    if (props?.actions) {
      return {
        actions: props?.actions(getFlatTreeItems(flatTreeItem)),
      };
    }
  };

  const transformedFlatTreeItems = () => {
    if (props?.flatTreeItems && props?.flatTreeItems?.length > 0) {
      return props?.flatTreeItems.map((item: any) => {
        return {
          ...item,
          value: item?.id,
          parentValue: item?.parentId ?? undefined,
        };
      });
    } else {
      return [];
    }
  };

  const getFlatTreeItems = (flatTreeItem: any) => {
    if (
      flatTreeItem &&
      flatTreeItem?.value &&
      props?.flatTreeItems &&
      props?.flatTreeItems?.length > 0
    ) {
      let selectedItem = props?.flatTreeItems.find((item: any) => {
        return item?.id === flatTreeItem?.value;
      });

      return { ...flatTreeItem, ...selectedItem };
    } else {
      return [];
    }
  };

  // const flatTree = useHeadlessFlatTree_unstable(transformedFlatTreeItems());
  const focusTargetAttribute = useRestoreFocusTarget();

  const flatTree = useHeadlessFlatTree_unstable(transformedFlatTreeItems(), {
    defaultOpenItems:
      props?.defaultOpenItems && Array.isArray(props?.defaultOpenItems)
        ? props?.defaultOpenItems
        : [],
    onOpenChange: props?.onOpenChange,
  });
  return (
    <>
      <FlatTree {...flatTree.getTreeProps()} aria-label="Flat Tree">
        {Array.from(flatTree.items(), (flatTreeItem) => {
          const { title, ...treeItemProps } = flatTreeItem.getTreeItemProps();
          return (
            <div key={flatTreeItem?.value}>
              <Menu
                key={flatTreeItem?.value + 'Menu'}
                positioning="below-end"
                openOnContext={false}
              >
                {/*<MenuTrigger key={flatTreeItem?.value + 'MenuTrigger'} disableButtonEnhancement>*/}
                <FlatTreeItem
                  style={{ borderBottom: '1px solid #f3f2f1' }}
                  key={flatTreeItem?.value + 'FlatTreeItem'}
                  aria-description="has actions"
                  {...focusTargetAttribute}
                  {...treeItemProps}
                >
                  <TreeItemLayout
                    key={flatTreeItem?.value + 'TreeItemLayout'}
                    onClick={() => {
                      props?.onClickTreeItem(getFlatTreeItems(flatTreeItem));
                      dispatch(
                        saveWbsStateAttr(
                          'selectedTreeItem',
                          flatTreeItem?.value
                        )
                      );
                    }}
                    aside={
                      props?.asideContent ? (
                        props?.asideContent(getFlatTreeItems(flatTreeItem))
                      ) : (
                        <></>
                      )
                    }
                    iconBefore={
                      props?.iconBefore ? (
                        props?.iconBefore(getFlatTreeItems(flatTreeItem))
                      ) : (
                        <></>
                      )
                    }
                    {...otherProps(flatTreeItem)}
                    className={
                      selectedTreeItem == flatTreeItem?.value
                        ? treeItemStyles.selectedItem
                        : ''
                    }
                  >
                    {props?.stopPropagation ? (
                      <div
                        onClick={(event) => {
                          event.stopPropagation();
                          props?.onClickTitle(getFlatTreeItems(flatTreeItem));
                          dispatch(
                            saveWbsStateAttr(
                              'selectedTreeItem',
                              flatTreeItem?.value
                            )
                          );
                        }}
                        style={{ display: 'flex', gap: 5 }}
                      >
                        {props?.mediaContent ? (
                          props?.mediaContent(getFlatTreeItems(flatTreeItem))
                        ) : (
                          <></>
                        )}
                        {title}
                      </div>
                    ) : (
                      <div style={{ display: 'flex', gap: 5 }}>
                        <div
                          onClick={(event) => {
                            event.stopPropagation(); // This stops previous popover from closing !
                          }}
                        >
                          {props?.mediaContent ? (
                            props?.mediaContent(getFlatTreeItems(flatTreeItem))
                          ) : (
                            <></>
                          )}
                        </div>
                        {title}
                      </div>
                    )}
                  </TreeItemLayout>
                </FlatTreeItem>
                {/*</MenuTrigger>*/}
                <MenuPopover>
                  <MenuList key={flatTreeItem?.value + 'MenuList'}>
                    {/*<MenuItem>Edit</MenuItem>*/}
                    {/*<MenuItem>New</MenuItem>*/}
                    {/*<MenuItem>New Window</MenuItem>*/}
                    {/*<MenuItem disabled>Open File</MenuItem>*/}
                    {/*<MenuItem>Open Folder</MenuItem>*/}
                  </MenuList>
                </MenuPopover>
              </Menu>
            </div>
          );
        })}
      </FlatTree>
    </>
  );
};

export default Tree;

import React from 'react';
import { initializeIcons } from '@uifabric/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ShortCutPaneComponent } from './component';
import { FilterItem, ShortCutPaneItem } from '../../../types/billOfResources';
import history from '../../../history';
import { messageService } from '../../../services/messageService';
import { getContractingUnit, getProject } from '../../../shared/util';

initializeIcons();

interface Props extends WithTranslation {
  shortCutPaneFilters: ShortCutPaneItem[];
  handelShortCutPaneFilter: (selectedItem: ShortCutPaneItem) => void;
  filter: FilterItem;
  projectId: string | null;
  toggleOverlay: boolean;
  resetLayout: boolean
}

interface State {
  divClass: string;
  selectedCodeType: number;
  currentActiveSection: string | null;
}

class ShortCutPane extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      divClass: 'filter-panel-left',
      selectedCodeType: -1,
      currentActiveSection: '0',
    };
  }

  componentDidMount() {
    if (this.state.divClass === 'filter-panel-left') {
      if (window.innerWidth > 1300) {
        messageService.sendMessage({ shortCutPaneExpanded: true });
      }
    } else {
      messageService.sendMessage({ shortCutPaneExpanded: false });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.toggleOverlay !== prevProps.toggleOverlay) {
      if (this.props.toggleOverlay) {
        this.setState({ divClass: 'filter-panel-left panel-move' });
      } else {
        this.setState({ divClass: 'filter-panel-left' });
      }
    }
    // if(this.props.resetLayout !==prevProps.resetLayout){
    //   if(this.props.resetLayout){
    //     this.setState({ divClass: 'filter-panel-left' });
    //   }
    // }
  }

  private handelShortCutPaneFilter = (selectedItem: ShortCutPaneItem) => {
    this.setState({
      currentActiveSection: selectedItem.id,
    });

    if (getContractingUnit() && getProject()) {
      history.push({
        pathname: `/CU/${getContractingUnit()}/project/${getProject()}/bill-Of-resources`,
        state: { borSelectedShortCutPaneItem: selectedItem },
      });
    }
    messageService.sendMessage({
      borSelectedShortCutPaneItem: selectedItem,
      resetBorFilter: true,
    });
  };

  private resetFilter = () => {
    let filterItem: ShortCutPaneItem = {
      key: null,
      id: null,
      type: null,
      name: 'All',
    };
    this.setState({
      currentActiveSection: null,
    });

    this.props.handelShortCutPaneFilter(filterItem);
  };

  public render(): JSX.Element {
    return (
      <ShortCutPaneComponent
        currentActiveSection={this.state.currentActiveSection}
        toggleOverlay={this._toggleOverlay}
        shortCutPaneFilters={this.props.shortCutPaneFilters}
        toggleOverlayFwd={this._toggleOverlayFwd}
        handelShortCutPaneFilter={(selectedItem: ShortCutPaneItem) =>
          this.handelShortCutPaneFilter(selectedItem)
        }
        resetFilter={() => this.resetFilter()}
        divClass={this.state.divClass}
      />
    );
  }

  private _toggleOverlay = (): void => {
    this.setState({ divClass: 'filter-panel-left panel-move' });
    messageService.sendMessage({ shortCutPaneExpanded: false });
  };

  private _toggleOverlayFwd = (): void => {
    this.setState({ divClass: 'filter-panel-left' });
    messageService.sendMessage({ shortCutPaneExpanded: true });
  };
}

export default withTranslation()(ShortCutPane);

import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { APPROVED_STATUS_ID, HANDED_OVER_STATUS_ID, IN_REVIEW_STATUS_ID } from '../../../types/projectMolecule';
import { PBS_TYPE } from '../../../types/projectBreakdownStructure';
import { deleteProduct, readPbsRelations, savePbsStateAttr } from '../../../reducers/projectBreakdownReducer';
import ConfirmationDialog from '../../../shared/confirmationDialog/confirmationDialog';
import { isPlanning } from '../../../shared/util';
import { saveVPStateAttr } from '../../../reducers/visualPlanReducer';
import SquareMetersModal from '../../ProjectBreakdownStructure/squareMeters/squareMetersModal';
import DeleteConfirmationDialogWithData from '../../../shared/confirmationDialog/deleteConfirmationDialogWithData';

const CommandBarRight = (props: { type: string, disable: boolean, vpProjectSelectedShortcutpaneType?:string, documentPaneType?:number }) => {

  const store = useStore();
  const isApprovedPmol = (store.getState()?.pmol?.formData?.statusId == APPROVED_STATUS_ID || store.getState()?.pmol?.formData?.statusId == HANDED_OVER_STATUS_ID)
  const isInReviewPmol = (store.getState()?.pmol?.formData?.statusId == IN_REVIEW_STATUS_ID)
  const isLoading = (store.getState()?.pmol?.showProgressBar)

  const [isConfirmDialogHidden, setIsConfirmDialogHidden] = React.useState(true);

  const { updatedVPPBS } = useSelector(
    (state: any) => state.projectBreakdown
  );
  const pbsRelations = store.getState()?.projectBreakdown?.pbsRelations

  const { isPbsSqModalOpen } = useSelector((state: any) => state.projectBreakdown);
  const squareMeters=()=>{
    dispatch(savePbsStateAttr('isPbsSqModalOpen',!isPbsSqModalOpen))
  };

  const dispatch = useDispatch();

  const handlePBSRemove = () => {
      setIsConfirmDialogHidden(true);
      Promise.all([
        dispatch(deleteProduct([updatedVPPBS.id]))
      ]).then(() => {
        if (isPlanning()) {
          dispatch(saveVPStateAttr('showvpProjectDocumentPane', false));
        } else {
          messageService.sendMessage({ hideDocumentPane: true, documentPaneType: props.documentPaneType });
        }
      })
  };

  const { t } = useTranslation();
  const saveFormData = () => {
    if (props.type === 'pmol') {
      messageService.sendMessage({ savePmolFormData: true });
    }

    if (props.type === 'bor') {
      messageService.sendMessage({ saveFormDataBor: true });
    }

    if (props.type === 'pbs') {
      messageService.sendMessage({ saveFormDataPBS: true });
    }
    if (props.type === 'milestone') {
      messageService.sendMessage({ saveMSTONEData: true });
    }

    if (props.type === 'pr') {
      messageService.sendMessage({ savePOData: true });
    }

    if (props.type === 'project') {
      messageService.sendMessage({ savePROJECTData: true });
    }
  };

  const handleRegular = () => {
    messageService.sendMessage({ isNewPbs: true, pbsType: PBS_TYPE.REGULAR });
  };
  const handleSubContract = () => {
    messageService.sendMessage({ isNewPbs: true, pbsType: PBS_TYPE.SUB });
  };

  const commandBarButtons = [
    {
      key: 'new',
      text: t('new'),
      iconProps: { iconName: 'Add' },
      split: true,
      subMenuProps: {
        items: [
          { key: 'regular',
            text: t('clientName'),
            iconProps: { iconName: 'Add' },
            onClick: () => {
              if (props.type === 'pbs') {
                handleRegular();
              }
            },
          },
          {
            key: 'subContract',
            text: t('subContract'),
            iconProps: { iconName: 'Add' },
            onClick: () => {
              if (props.type === 'pbs') {
                handleSubContract();
              }
            },
          },
        ],
      },
      disabled: false,
      onClick: () => {
        if (props.type === 'pbs') {
          handleRegular();
        }
      },
    },
    {
      key: 'save',
      text: t('save'),
      iconProps: { iconName: 'Save' },
      onClick: () => {
        saveFormData();
      },
      disabled: isApprovedPmol || isLoading,
    },
    {
      key: 'saveAndClose',
      text: t('saveAndClose'),
      iconProps: { iconName: 'SaveAndClose' },
      onClick: () => {
        saveFormData();
        dispatch(saveVPStateAttr('showvpProjectDocumentPane', false));
      },
      disabled: isApprovedPmol || isLoading,
    },
    {
      key: 'Remove',
      onClick: () => {
        setIsConfirmDialogHidden(false)
        dispatch(readPbsRelations(updatedVPPBS?.productId))
      },
      name: t('remove'),
      iconProps: { iconName: 'Delete' },
      disabled: (props.type != 'pbs'), // Currently disabled for PMOL
    },
    {
      key: 'approve',
      text: t('approve'),
      iconProps: { iconName: 'ActivateOrders' },
      onClick: () => {
        messageService.sendMessage({ approvePmol: true });
      },
      disabled: !isInReviewPmol || isLoading,
    },
    {
      key: 'export',
      text: t('export'),
      iconProps: { iconName: 'Download' },
      split: true,
      subMenuProps: {
        items: [
          {
            key: 'exportNl',
            text: t('exportDutch'),
            iconProps: { iconName: 'Download' },
            onClick: () => {
              messageService.sendMessage({ exportPmolNL: true });
            },
          },
          {
            key: 'exportEn',
            text: t('exportEnglish'),
            iconProps: { iconName: 'Download' },
            onClick: () => {
              messageService.sendMessage({ exportPmolEN: true });
            },
          },
        ],
      },
      onClick: () => {
        messageService.sendMessage({ exportPmolNL: true });
      },
    },
    {
      key: 'SquareMeters',
      onClick: () => {
        squareMeters();
      },
      name: updatedVPPBS?.pbsScopeOfWork?.quantity ? updatedVPPBS?.pbsScopeOfWork?.quantity+' m'+'²' : ''+'m'+'²',
      iconProps: { iconName: 'Section' },
      disabled: ( !(updatedVPPBS?.productId && updatedVPPBS?.pbsType == PBS_TYPE.REGULAR)),
    },
  ];

  const getItems = () => {
    switch (props.type) {
      case 'pmol':
        return commandBarButtons.slice(0, 6);
      case 'bor':
        return commandBarButtons.slice(1, 2);
      case 'pbs':
        return commandBarButtons.filter(button => button.key !== 'approve' && button.key !== 'export');
      default:
        return commandBarButtons.filter(item => item.key === 'save' || item.key === 'saveAndClose');
    }
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />

      <DeleteConfirmationDialogWithData
        hidden={isConfirmDialogHidden}
        title={t('areyousure')}
        subText={t("You won't be able to revert this!")}
        onClickConfirmDialog={() => {
          handlePBSRemove();
          }
        }
        onClickCancelDialog={() => {
          setIsConfirmDialogHidden(true)
        }}
        cancelButtonText={t("No, I won't!")}
        confirmButtonText={t('Yes, delete it!')}
        relationsData={pbsRelations}
      />

      <SquareMetersModal/>
    </div>
  );
};

export default CommandBarRight;

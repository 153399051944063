import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getPerentFromTreeData} from '../../../../../shared/util';
import {IColumn} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import ThAutomationTaxonomyGrid from "./thAutomationTaxonomyGrid";
import { getThResourceFamilies } from '../../../../../reducers/thAutomationReducer';

const ThAutomationTaxonomy = (props: {
    label?: string
    selectItemIds: string[] | null
    onChange?: any
    disabled: boolean;
}) => {
    const {t} = useTranslation();
    let tableColumns: IColumn[] = [
        {
            key: 'column1',
            name: t('contractors'),
            fieldName: 'contractorTaxonomyId',
            minWidth: 120,
            maxWidth: 300,
            isResizable: true,
        }];

    const dispatch = useDispatch();

    // const contractorsData = useSelector(
    //   (state: any) => state.addressBook.contractors,
    // );

    const {resourceFamily,isTruckViewMode} = useSelector(
      (state: any) => state.thAutomation,
    );

    const [selectedParentIds, setSelectedParentIds]: any = useState([]);
    const [selectItemIds, setselectItemId]: any = useState(props.selectItemIds);
    const [dataList, setDataList] = useState<any[]>([]);

    useEffect(() => {
        dispatch(getThResourceFamilies({isTruck:isTruckViewMode}));
    }, []);

    useEffect(() => {
        dispatch(getThResourceFamilies({isTruck:isTruckViewMode}));
    }, [isTruckViewMode]);

    useEffect(() => {
        if (selectItemIds) {
            if (selectItemIds && _.isArray(selectItemIds) && selectItemIds.length > 0) {
                let list = selectItemIds.map((item: any) => {
                    return {'contractorTaxonomyId': item};
                });
                setDataList(list);
            }
            setSelectedParentIds(
              getPerentFromTreeData(resourceFamily, selectItemIds),
            );
        } else {
            setDataList([]);
            setSelectedParentIds([]);
        }
    }, [resourceFamily, selectItemIds]);

    useEffect(() => {
        // setDataList(props.selectItemIds!!);
        setselectItemId(props.selectItemIds);
    }, [props.selectItemIds]);

    const renderButtons = (rowInfo: any) => {
        const isParent = selectedParentIds?.includes(rowInfo.node.id);
        const isSelected = selectItemIds?.includes(rowInfo.node.id);

        return (
          <div
            key={rowInfo.node.id}
            className={'Indeterminate'}
            style={{paddingTop: 6}}
          ><span
            style={{display: 'none'}}>{JSON.stringify(isParent)}</span> {/* if u remove this part parent selection not working properly   */}
              {/*<Checkbox*/}
              {/*  className="btn btn-outline-success"*/}
              {/*  styles={{root: {verticalAlign: 'middle'}}}*/}
              {/*  checked={isSelected}*/}
              {/*  onChange={(ev: any, checked: boolean | undefined) =>*/}
              {/*    handleNodeCheckboxClick(rowInfo, checked)*/}
              {/*  }*/}
              {/*/>*/}
          </div>
        );
    };

    const handleNodeCheckboxClick = (rowInfo: any, checked: boolean | undefined) => {
        const currentList: any[] = Array.isArray(selectItemIds) ? selectItemIds : [];
        const ids: any[] = [];
        if (checked) {
            ids.push(rowInfo?.node?.id);
            currentList.forEach((teamItem: any) => {
                ids.push(teamItem);
            });
            const results = ids.filter(element => {
                return element !== '';
            });
            let list = results.map((item: any) => {
                return {'contractorTaxonomyId': item};
            });
            setDataList(list);
            setselectItemId(results);
        } else {
            let filteredArray = currentList.filter(e => e !== rowInfo?.node?.id);
            const results = filteredArray.filter(element => {
                return element !== '';
            });
            let list = results.map((item: any) => {
                return {'contractorTaxonomyId': item};
            });
            setDataList(list);
            setselectItemId(results);
        }
    };

    return (
      <div>
          <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  <ThAutomationTaxonomyGrid
                    treeData={resourceFamily}
                    renderButtons={renderButtons}
                  />
              </div>
          </div>
      </div>
    );
};


export default ThAutomationTaxonomy;

import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { withRouter } from 'react-router';
import history from '../../history';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getContractingUnit, getQRName } from '../../shared/util';
import { messageService } from '../../services/messageService';
import { connect } from 'react-redux';
import { deleteQRCode } from '../../reducers/qrCodeReducer';
import ConfirmationDialog from '../../shared/confirmationDialog/confirmationDialog';


interface Props extends WithTranslation {
  selectedQRCode: any;
  deleteQRCode: (id: string) => any
}

class QRCommandBar extends React.Component<Props, any> {
  subscription: any;

  constructor(props: Props) {
    super(props);
    this.state = { qrCode: null, downloadable: false, hiddenRemoveDialog: true };
  }

  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data && data.data.qrCode) {
          this.setState({ qrCode: data.data.qrCode, downloadable: true });
        }
      }
    });
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  downloadQR = () => {
    const codeName = getQRName(this.state.qrCode);
    const canvas: any = document.getElementById(codeName);
    if (canvas) {
      const pngUrl = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');
      let downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = `${codeName}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  deleteQRCode = () => {
    this.setState({ hiddenRemoveDialog: false });
  };

  onDeleteConfirm = () => {
    if (this.state.qrCode) {
      this.setState({ hiddenRemoveDialog: true, qrCode: null, downloadable: false });
      messageService.sendMessage({ isDeleteQR: true });
      this.props.deleteQRCode(this.state.qrCode.id).then((response: any) => {
        messageService.sendMessage({ isDeleteQR: false });
        this.qrCodeRedirect();
      });
    }
  };
  updateQRCode = () => {
    messageService.sendMessage({ isUpdateQR: true });
  };

  onCancel = () => {
    this.setState({ hiddenRemoveDialog: true });
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <CommandBar
          items={this.getItems()}
          overflowButtonProps={{ ariaLabel: 'More commands' }}
          // farItems={this.getFarItems()}
          className="common-commandbar"
          ariaLabel={'Use left and right arrow keys to navigate between commands'}
        />
        <ConfirmationDialog
          hidden={this.state.hiddenRemoveDialog}
          title={t('areyousure')}
          subText={t('You won\'t be able to revert this!')}
          onClickConfirmDialog={this.onDeleteConfirm}
          onClickCancelDialog={this.onCancel}
          cancelButtonText={t('No, I won\'t!')}
          confirmButtonText={t('Yes, delete it!')}
        />
      </div>
    );
  }

  qrCodeRedirect = () => {
    let pathname = '';
    if (getContractingUnit()) {
      pathname = `/CU/${getContractingUnit()}/qr-code`;
    } else {
      pathname = '/qr-code';
    }
    history.push({
      pathname: pathname,
      state: { refresh: true },
    });
    this.setState({ downloadable: false });
    messageService.sendMessage({ isNewQrCode: true });
  };
  // Data for CommandBar
  private getItems = () => {
    const { t } = this.props;
    return [
      {
        key: 'newItem',
        text: t('new'),
        cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
        iconProps: { iconName: 'Add' },
        onClick: this.qrCodeRedirect,
      },
      {
        key: 'save',
        text: t('save'),
        cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
        iconProps: { iconName: 'Save' },
        onClick: this.updateQRCode,


      },
      {
        key: 'download',
        text: t('download'),
        iconProps: { iconName: 'Download' },
        onClick: this.downloadQR,
        disabled: !this.state.downloadable,
      },
      {
        key: 'delete',
        text: t('remove'),
        iconProps: { iconName: 'Delete' },
        onClick: this.deleteQRCode,
        disabled: !this.state.downloadable,
      },
    ];
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {};
};

const mapDispatchToProps = {
  deleteQRCode,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(QRCommandBar)),
);
//export default withTranslation()(QRCommandBar);

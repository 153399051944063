import React from 'react';
import {Calendar, DateRangeType, DayOfWeek, FirstWeekOfYear, Pivot, PivotItem} from "@fluentui/react";
import {saveMyDayPlanningStateAttr} from "../../../../reducers/myDayPlanningReducer";
import {getCalenderString, isTeamPlanboardMyEnv} from "../../../../shared/util";
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {messageService} from "../../../../services/messageService";
import {checkDateIsValid, dayButtonId, weekButtonId} from '../../../../types/myDayPlanning';
import moment from "moment/moment";

type Props = {};

const TeamPbCalendar: React.FC<Props> = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const {
        selectedDate,
        selectedWeek,
        view,
    } = useSelector(
        (state: any) => state.mydPlan
    );

    const className = isTeamPlanboardMyEnv() ? 'planboard-calender TPBResizeShortcutPane' : 'planboard-calender';

    return (
        <Pivot
            selectedKey={view}
            onLinkClick={(item?: PivotItem) => {
                messageService.sendMessage({hideDocumentPane: true, documentPaneType: 1});
                if (item) {
                    if (item.props.itemKey! === dayButtonId) {
                        dispatch(saveMyDayPlanningStateAttr('view', 'day'));
                    } else if (item.props.itemKey! === weekButtonId) {
                        dispatch(saveMyDayPlanningStateAttr('view', 'week'));
                    }
                }
            }}
        >
            <PivotItem headerText={t('day')} itemKey={`${dayButtonId}`}>
                <div className={className}>
                    < Calendar
                        onSelectDate={(date: Date, selectedDateRangeArray: Date[] | undefined) => {
                            dispatch(saveMyDayPlanningStateAttr('selectedDate', date));

                            // This sets the selected week to the week of the selected date
                            let setSelectedWeek = {};
                            if (selectedDateRangeArray) {
                                setSelectedWeek = {
                                    startDate: moment(date).startOf('isoWeek').format('YYYY-MM-DD'),
                                    endDate: moment(date).endOf('isoWeek').format('YYYY-MM-DD'),
                                    toDate: moment(date).format('YYYY-MM-DD'),
                                }
                            }
                            dispatch(saveMyDayPlanningStateAttr('selectedWeek', setSelectedWeek));

                        }}
                        value={checkDateIsValid(selectedDate)}
                        firstDayOfWeek={DayOfWeek.Monday}
                        // Calendar uses English strings by default. For localized apps, you must override this prop.
                        strings={getCalenderString()}
                        showWeekNumbers
                        showMonthPickerAsOverlay
                        highlightSelectedMonth
                        showGoToToday={false}
                        firstWeekOfYear={FirstWeekOfYear.FirstFourDayWeek}                        
                        styles={{ root: {width: '100%'} }}
                    />
                </div>
            </PivotItem>
            <PivotItem headerText={t('week')} itemKey={`${weekButtonId}`}>
                <div className={className}>
                    <Calendar
                        dateRangeType={DateRangeType.Week}
                        onSelectDate={(date: Date, selectedDateRangeArray: Date[] | undefined) => {
                            let setSelectedWeek = {};
                            if (selectedDateRangeArray) {
                                setSelectedWeek = {
                                    startDate: moment(selectedDateRangeArray[0]).format('YYYY-MM-DD'),
                                    endDate: moment(selectedDateRangeArray[selectedDateRangeArray.length - 1]).format('YYYY-MM-DD'),
                                    toDate: moment(date).format('YYYY-MM-DD'),
                                }
                            }
                            dispatch(saveMyDayPlanningStateAttr('selectedWeek', setSelectedWeek));
                        }}
                        value={checkDateIsValid(selectedWeek.toDate)}
                        firstDayOfWeek={DayOfWeek.Monday}
                        // Calendar uses English strings by default. For localized apps, you must override this prop.
                        strings={getCalenderString()}
                        showWeekNumbers
                        showMonthPickerAsOverlay
                        highlightSelectedMonth
                        showGoToToday={false}
                        firstWeekOfYear={FirstWeekOfYear.FirstFourDayWeek}
                        styles={{ root: {width: '100%'} }}
                    />
                </div>
            </PivotItem>
        </Pivot>
    );
};

export default TeamPbCalendar;

import React, { useEffect, useState } from 'react';
import SplitPane, { Pane } from 'react-split-pane';
import TitlePane from '../../shared/titlePane/titlePane';
import { Stack } from '@fluentui/react';
import ProgressBar from '../../shared/progressBar/progressBar';
import { useParams } from 'react-router';
import { ParamTypes } from '../../types/uPrince';
import { messageService } from '../../services/messageService';
import WbsShortcutPane from '../wbs/shortcutPane/component';
import WbsIssueDocumentPaneWrapper from './documentPane/WbsIssueDocumentPaneWrapper';
import './wbs-issue-style-sheet.css';
import { WbsIssueListComponent } from './listPane/WbsIssueListPane';
import history from '../../history';
import { getContractingUnit, getProject } from '../../shared/util';
import { useDispatch, useSelector } from 'react-redux';
import WbsIssueListPaneCommandBar from './commandBar/ListPaneCommandBar';
import { getWbsIssueDropdowns } from '../../reducers/wbsIssueReducer';
import _ from 'lodash';

const WbsIssueMainLayout = () => {
  const params = useParams();
  const { featureId }: ParamTypes = params;
  const dispatch = useDispatch();

  const reloadWbsIssue = useSelector((state: any) => state.wbsIssue.reloadWbsIssue);
  const wbsIssueDropdownData = useSelector((state: any) => state.wbsIssue.wbsIssueDropdownData);

  const [reSizer, setReSizer] = useState<number>(screen.width);
  const [showDocumentPane, setShowDocumentPane] = useState<boolean>(false);

  useEffect(() => {
    _.isEmpty(wbsIssueDropdownData) && dispatch(getWbsIssueDropdowns());

    const subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.hideDocumentPane) {
          history.push(`/CU/${getContractingUnit()}/project/${getProject()}/wbs/issue`);
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (location.pathname.split('/').pop() === 'new') {
      handleDocumentPaneCollapse(false);
    } else if (featureId) {
      handleDocumentPaneCollapse(false);
    } else {
      handleDocumentPaneCollapse(true);
    }
  }, [params]);

  const handleDocumentPaneCollapse = (isCollapsed: boolean) => {
    if (isCollapsed) {
      setReSizer(screen.width);
      setShowDocumentPane(false);
    } else {
      setReSizer(screen.width / 2);
      setShowDocumentPane(true);
    }
  };

  const getListPaneView = () => {
    return <>
      <TitlePane
        headerTitle={'All'}
        type={1}
        isFullScreen={false}
      />
      <div style={{
        height: 'calc(100vh - 134px)',
        overflow: 'overlay',
      }}>
        <Stack horizontal>
          {renderShortCutPane()}
          <div className={'grid-righter full-height'} style={{ width: '50%' }}>
            <Stack
              className='project-list-header new-design'
              style={{
                backgroundColor: 'rgb(255, 255, 255)',
                paddingRight: 0,
              }}
              styles={{ root: { width: '100%', paddingRight: 0 } }}
            >
              <WbsIssueListPaneCommandBar />
              <ProgressBar show={!reloadWbsIssue} />
            </Stack>
            {/*<Stack>*/}
            {/*  <WbsIssueFilters isCollapsed={!showDocumentPane} />*/}
            {/*</Stack>*/}
            <Stack style={{ height: 'calc(100vh - 249px)', alignSelf: 'center' }}>
              <WbsIssueListComponent isCollapsed={!showDocumentPane} />
            </Stack>
          </div>
        </Stack>
      </div>
    </>;
  };

  const renderShortCutPane = () => {
    return <WbsShortcutPane />;
  };

  const getDocumentPaneView = () => {
    return <WbsIssueDocumentPaneWrapper />;
  };

  return (
    <div>
      <SplitPane
        className={'SplitPane'}
        minSize={(screen.width / 3)}
        maxSize={(screen.width / 3) * 2}
        defaultSize={screen.width}
        split='vertical'
        onChange={(size: any) => {
          setReSizer(size);
        }}
        size={reSizer}
        style={{ height: '85.5%' }}
      >
        <Pane className={''}
              style={{
                width: reSizer,
                height: '100%',
              }}>{getListPaneView()} </Pane>
        <Pane className={''}
              style={{
                marginLeft: 6,
                width: screen.width - reSizer,
                height: '100%',
              }}> {showDocumentPane && getDocumentPaneView()}</Pane>
      </SplitPane>
    </div>
  );
};

export default WbsIssueMainLayout;
import { Dropdown, IDropdownOption, Label } from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// import 'style.css';
import { ViewMode } from '../../../shared/ganttv2';
import { RESOURCE_TYPE_IDS } from '../../../shared/util';
import { readVPOrganizationTaxanomyData, saveVPStateAttr } from '../../../reducers/visualPlanReducer';
import ModeSwitcher from '../../../shared/ganttViewSwitcher/viewSwitcher';
import { ViewModeContext } from '../../projectVisualPlan/productTaxonomyListPane/component';
import PeoplePicker from '../../../shared/fluentFinalForm/PeoplePicker';
import { Sorter } from '../../../types/purchaseOrder';
import _ from 'lodash';

type ViewSwitcherProps = {
  isChecked: boolean;
  onViewListChange: (isChecked: boolean) => void;
  onViewModeChange: (viewMode: ViewMode) => void;
  renderFilterComponent: any
};
export const ViewSwitcher: React.FC<ViewSwitcherProps> = () => {
  const [selectedKey, setSelectedKey] = React.useState<string>(RESOURCE_TYPE_IDS.LABOURS);
  const [selectedMode, setSelectedViewMode] = React.useState<ViewMode>(ViewMode.Day);
  const [customer, setCustomer] = useState<any>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { onViewModeChange, onViewListChange, isCheck } =
  useContext(ViewModeContext);

  const resourceTypeDropDowns: any = useSelector(
    (state: any) => state.po.purchaseOrderDropDowns,
  );

  const cpcDropDowns: any = useSelector(
    (state: any) => state.productCatalogue.resourceTypes,
  );

  const selectedResourceTypeOrg: string = useSelector(
    (state: any) => state.vp.selectedResourceTypeOrg,
  );

  const onSubmit = async (values: any) => {
  };

  useEffect(() => {
    setSelectedKey(selectedResourceTypeOrg);
  }, [selectedResourceTypeOrg]);

  const sortOption = [
    { key: 1, text: t('department') },
    { key: 2, text: t('jobRole') },
  ];

  const getResourceTypeOptions = () => {
    let renameToolToVehicle = cpcDropDowns.map((item: any) => {
      if(item.key === RESOURCE_TYPE_IDS.TOOLS){
        return {...item, text: t('vehicle')}
      }
      return item;
    });
    let filterOptions = renameToolToVehicle.filter((el: any) => {
      return el.key === RESOURCE_TYPE_IDS.LABOURS || el.key === RESOURCE_TYPE_IDS.TOOLS;
    });
    let newTool = { key: 'c46c3a26-39a5-42cc-newToolkey', text: t('tools') };
    filterOptions.push(newTool);
    // console.log(" ~ filterOptions ~ ", filterOptions);

    return filterOptions;
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        height: 110,
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          flexGrow: 0.2,
        }}
      >
        <Form
          onSubmit={onSubmit}
          initialValues={{}}
          mutators={{
            setHeaderDocs: (args, state, utils) => {
              const field = state.fields['header.files'];
              field.change(args[0]);
            },
          }}
          // validate={ validate }
          render={({
                     handleSubmit,
                     form,
                     submitting,
                     pristine,
                     values,
                   }) => {
            // props.submit(handleSubmit);
            return (
              <form onSubmit={handleSubmit} noValidate>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    height: 70,
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                      height: 70,
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        height: 70,
                        width: '100%',
                      }}
                    >

                      <div style={{ width: 180, marginLeft: 15 }}>
                        <Field name="resourceType">
                          {({ input, meta }) => (
                            <Dropdown
                              placeholder={t('resourceType')}
                              label={t('resourceType')}
                              selectedKey={selectedKey}
                              // eslint-disable-next-line react/jsx-no-bind
                              onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined) => {
                                if (item) {
                                  setSelectedKey(item.key as string);
                                  input.onChange( item.key as string);
                                  dispatch(saveVPStateAttr('selectedResourceTypeOrg', item.key));
                                  dispatch(saveVPStateAttr('showToolsOrLabourDocumentPane', false));
                                }
                              }}
                              //multiSelect
                              options={resourceTypeDropDowns?.requestTypes ? getResourceTypeOptions() : []}
                            />
                          )}
                        </Field>
                      </div>


                      {selectedKey === RESOURCE_TYPE_IDS.LABOURS && <div style={{ width: 180, marginLeft: 15 }}>
                      <Label style={{ width: 200 }}>{t('employee')}:</Label>
                        <Field name="personId">
                          {({ input, meta }) => (
                            <PeoplePicker
                            label={null}
                            isDisabled={false}
                            selectedItem={customer}
                            onChange={(items: any) => {
                              if (items && _.isArray(items) && items.length === 0) {
                                setCustomer([]);
                                dispatch(readVPOrganizationTaxanomyData({
                                  organizationTaxonomyLevelId: null,
                                  personId: null,
                                  sorter: new Sorter()
                                }))
                              } else {
                                setCustomer([]);
                                dispatch(readVPOrganizationTaxanomyData({
                                  organizationTaxonomyLevelId: null,
                                  personId: null,
                                  sorter: new Sorter()
                                }))
                              }
                            }}
                            onPersonSelected={(items: any) => {
                              if (items) {
                                setCustomer([{ key: items.key, text: items.text }]);
                                dispatch(readVPOrganizationTaxanomyData({
                                  organizationTaxonomyLevelId: null,
                                  personId: items.key,
                                  sorter: new Sorter()
                                }))
                              } else {
                                dispatch(readVPOrganizationTaxanomyData({
                                  organizationTaxonomyLevelId: null,
                                  personId: null,
                                  sorter: new Sorter()
                                }))
                                setCustomer([]);
                              }
                              return items;
                            }}
            
                          />
                          )}
                        </Field>
                      </div>}

                      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: 100, width: '90%' }}>
                        <table style={{
                          borderWidth: 1,
                          borderColor: '#aaaaaa',
                          borderStyle: 'solid',
                          width: '65%',
                          marginLeft: 20,
                          height: 90,
                          marginTop: 10,
                        }}>
                          <tr style={{ borderWidth: 1, borderColor: '#F280A2', borderStyle: 'solid' }}>
                            <th colSpan={3} style={{ fontSize: 10 }}>{t('weightCapacityGap')}</th>
                          </tr>
                          <tr style={{ borderWidth: 1, borderColor: '#aaaaaa', borderStyle: 'solid' }}>
                            <td style={{
                              fontSize: 10,
                              backgroundColor: '#EC6981',
                              borderWidth: 1,
                              borderColor: '#0E180F',
                              borderStyle: 'solid',
                            }}>85
                            </td>
                            <td style={{
                              fontSize: 10,
                              borderWidth: 1,
                              borderColor: '#0E180F',
                              borderStyle: 'solid',
                            }}>0-80
                            </td>

                          </tr>
                          <tr style={{ borderWidth: 1, borderColor: '#aaaaaa', borderStyle: 'solid' }}>
                            <td style={{
                              fontSize: 10,
                              backgroundColor: '#A1DAA4',
                              borderWidth: 1,
                              borderColor: '#0E180F',
                              borderStyle: 'solid',
                            }}>95
                            </td>
                            <td style={{
                              fontSize: 10,
                              borderWidth: 1,
                              borderColor: '#0E180F',
                              borderStyle: 'solid',
                            }}>81-110
                            </td>

                          </tr>
                          <tr>
                            <td style={{
                              fontSize: 10,
                              backgroundColor: '#EDCF28',
                              borderWidth: 1,
                              borderColor: '#0E180F',
                              borderStyle: 'solid',
                            }}>115
                            </td>
                            <td style={{
                              fontSize: 10,
                              borderWidth: 1,
                              borderColor: '#0E180F',
                              borderStyle: 'solid',
                            }}>111-120
                            </td>
                          </tr>
                          <tr>
                            <td style={{
                              fontSize: 10,
                              backgroundColor: '#EC6981',
                              borderWidth: 1,
                              borderColor: '#0E180F',
                              borderStyle: 'solid',
                            }}>125
                            </td>
                            <td style={{
                              fontSize: 10,
                              borderWidth: 1,
                              borderColor: '#0E180F',
                              borderStyle: 'solid',
                            }}>121+
                            </td>

                          </tr>
                         
                        </table>
                      </div>

                    
                      <ModeSwitcher
                        onViewModeChange={(t:any)=>{
                          onViewModeChange(t)
                          setSelectedViewMode(t)
                        }}
                        onViewListChange={onViewListChange}
                        isCheck={isCheck}
                        selectedViewMode={selectedMode}
                      />
                    </div>
                  </div>

                </div>

                {/* <pre>{ JSON.stringify(values, 0, 2) }</pre> */}
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};

import * as React from 'react';
import { useEffect, useState } from 'react';
import type { ComboboxProps } from '@fluentui/react-components';
import { Button, Combobox, makeStyles, Option, Persona, shorthands, tokens, useId } from '@fluentui/react-components';
import { Dismiss12Regular } from '@fluentui/react-icons';
import _ from 'lodash';
import { AddressBookItem, CABFilter } from '../../../types/addressBookItem';
import client from '../../../api';

const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: 'grid',
    gridTemplateRows: 'repeat(1fr)',
    justifyItems: 'start',
    ...shorthands.gap('2px'),
    maxWidth: '400px',
  },
  tagsList: {
    listStyleType: 'none',
    marginBottom: tokens.spacingVerticalXXS,
    marginTop: 0,
    paddingLeft: 0,
    display: 'flex',
    gridGap: tokens.spacingHorizontalXXS,
  },
});

const PersonPicker = (
  {
    onChange,
    placeholder,
    value,
    disabled,
    errorMessage,
    label,
    required,
    endpoint,
    ...rest
  }: any) => {
  const comboId = useId('combo-multi');
  const selectedListId = `${comboId}-selection`;

  useEffect(() => {
    console.log('useEffect');
  }, []);

  // refs for managing focus when removing tags
  const selectedListRef = React.useRef<HTMLUListElement>(null);
  const comboboxInputRef = React.useRef<HTMLInputElement>(null);
  const [options, setOptions] = useState([]);

  const getOptions = async () => {
    const cabFilter = new CABFilter();
    const response = await client.post(endpoint, cabFilter);
    setOptions(formatCustomerContactResponse(response));
  };

  const formatCustomerContactResponse = (response: any) => {
    if (response && response.data) {
      if (response.data.result && _.isArray(response.data.result)) {
        const data = response.data.result.map((item: AddressBookItem) => {
          return {
            key: item?.person?.id,
            text: item?.person?.fullName,
            Organisation: item?.company?.name,
            email: item?.personCompany?.email,
            mobile: item?.personCompany?.mobilePhone,
            secondaryText: item?.personCompany ? item?.personCompany?.jobRole : '',
          };
        });
        console.log('options', data);
        return data;
      }
      return [];
    }
    return [];
  };

  // const options = [
  //   {key:1,text:"Pradeep Madusanka",secondaryText:"Developer"},
  //   {key:2,text:"Umesh Madusanka",secondaryText:"Developer"},
  //   {key:3,text:"Sanath Madusanka",secondaryText:"Developer"},
  //   {key:4,text:"Chanka Maduranga",secondaryText:"Developer"},
  //   {key:5,text:"Arosha Maduwantha",secondaryText:"Developer"},
  //   {key:6,text:"Supun Costha",secondaryText:"Developer"},
  //   {key:7,text:"Nimesh Priyankara",secondaryText:"Developer"},
  //   {key:8,text:"Shanuka Gayashan",secondaryText:"Developer"},
  //   {key:9,text:"Dashun Kavinda",secondaryText:"Developer"},
  //   {key:10,text:"Duminda Kurruppu",secondaryText:"Developer"},
  // ];

  useEffect(() => {
    if (endpoint) {
      console.log({ endpoint }, { options });
      getOptions().then();
    }
  }, [endpoint]);

  const styles = useStyles();

  // Handle selectedOptions both when an option is selected or deselected in the Combobox,
  // and when an option is removed by clicking on a tag
  // const [selectedOptions, setSelectedOptions] = React.useState<string[]>([]);

  const onSelect: ComboboxProps['onOptionSelect'] = (event, data) => {
    // setSelectedOptions(data.selectedOptions);
    let selected = options.filter((item: any) => data.selectedOptions.includes(item?.key));
    console.log({ selected });
    onChange(selected);
  };

  const onTagClick = (option: string, index: number) => {
    // remove selected option
    let filtered = value?.filter((o: any) => o !== option);
    let selected = options.filter((item: any) => filtered.includes(item?.key));
    console.log({ selected });
    onChange(selected);

    // focus previous or next option, defaulting to focusing back to the combo input
    const indexToFocus = index === 0 ? 1 : index - 1;
    const optionToFocus = selectedListRef.current?.querySelector(
      `#${comboId}-remove-${indexToFocus}`,
    );
    if (optionToFocus) {
      (optionToFocus as HTMLButtonElement).focus();
    } else {
      comboboxInputRef.current?.focus();
    }
  };

  const labelledBy =
    value.length > 0 ? `${comboId} ${selectedListId}` : comboId;

  const renderName = (key: any) => {
    let selected: any = options?.find((opt: any) => opt?.key === key);
    if (selected) {
      return selected?.text;
    } else {
      return '';
    }
  };

  return (
    <div className={styles.root}>
      <label id={comboId}>{label}</label>
      {value.length ? (
        <div style={{ width: '100%', overflow: 'auto' }}>
          <ul
            id={selectedListId}
            className={styles.tagsList}
            ref={selectedListRef}
          >
            {/* The "Remove" span is used for naming the buttons without affecting the Combobox name */}
            <span id={`${comboId}-remove`} hidden>
            Remove
          </span>
            {value.map((option: any, i: number) => (
              <li key={option}>
                <Button
                  size='small'
                  shape='circular'
                  appearance='primary'
                  icon={<Dismiss12Regular />}
                  iconPosition='after'
                  onClick={() => onTagClick(option, i)}
                  id={`${comboId}-remove-${i}`}
                  aria-labelledby={`${comboId}-remove ${comboId}-remove-${i}`}
                >
                  {renderName(option)}
                </Button>
              </li>
            ))}
          </ul>
        </div>
      ) : null}

      {options && options?.length > 0 ?
        <Combobox
          aria-labelledby={labelledBy}
          multiselect={true}
          placeholder={placeholder}
          selectedOptions={value}
          onOptionSelect={onSelect}
          ref={comboboxInputRef}
          {...rest}
        >
          {options.map((option: any) => (
            // max-width is set because the Options render an additional width on opening. This gives the limit to  that additional width.
            <Option text={option?.text} value={option?.key} disabled={false} style={{maxWidth: 209}}>
              <Persona
                avatar={{ color: 'colorful', 'aria-hidden': true }}
                name={option?.text}
                secondaryText={option?.secondaryText}
              />
            </Option>
          ))}
        </Combobox> :
        <div
          style={{ height: 30, width: '100%' }}
          className={'loading-shimmer'}
        ></div>}

      <span style={{ color: '#a4262c', fontSize: '12px' }}>{errorMessage}</span>
    </div>
  );

};

export default PersonPicker;

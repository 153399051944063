export class WorkFlowListPaneFilter {
  title: string | null = null;
  type: string | null = null;
  status: string | null = null;
  executer: string | null = null;
  requester: string | null = null;
  targetDateTime: any = null;
  executedDateTime: any = null;
  sorter: Sorter = new Sorter();
}

export interface WorkFlowShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
  value: number | null
}

class Sorter {
  attribute: string | null = null;
  order: string | null = null;
}

export class WorkFlowListItem {
  id: string | null = null;
  name: string | null = null;
}

export interface DropDown {
  key: string;
  text: string;
}

export interface WorkFlowDropDowns {
  activityStatus: DropDown[];
  types: DropDown[];
  resourceType: DropDown[];
  warehouseTaxonomy: any;
  whTaxonomyLevel: any;
  projectWarehouseTaxonomy: any
}

interface Task {
  source: string;
  cpcItemId: string;
  quantity: string;
  mouId: string;
  pickedQuantity: string;
  destination: string;
  totalPrize: string;
  comment: string;
  stockAvailability: string;
}

export interface WorkFlow {
  id: string;
  sequenceId: string;
  name: string;
  title: string;
  typeId: string;
  resourceType: string;
  requesterId: string;
  executorId: string;
  requiredDateAndTime: string;
  executedDateAndTime: string;
  effortEstimate: string;
  effortCompleted: string;
  status: string;
  source: string;
  destination: string;
  files: string[];
  task: Task[];
}

export interface History {
  createdByUser: string | null;
  createdDateTime: string | null;
  revisionNumber: number | string | null;
  updatedByUser: string | null;
  updatedDateTime: string | null;
}

export const TYPE_ALL_SC = 'a35ab9fe-df57-4088-82a9-d27008688bae1';
export const GOOD_PICKING_SC = 'bdd9e479-75b3-40c6-ad61-e40dbe6a51ac2';
export const GOOD_RECEPTION_SC = 'a35ab9fe-df57-4088-82a9-d27008688bae3';

export const TYPE_ALL = null;
export const GOOD_PICKING = '94282458-0b40-40a3-b0f9-c2e40344c8f1';
export const GOOD_RECEPTION = '4010e768-3e06-4702-b337-ee367a82addb';


import {
    ASSIGN_PUMP_TO_PRODUCT,
    ASSIGN_PUMP_TO_PRODUCT_FAIL,
    ASSIGN_PUMP_TO_PRODUCT_SUCCESS,
    ASSIGN_TRUCK_TO_PRODUCT,
    ASSIGN_TRUCK_TO_PRODUCT_FAIL,
    ASSIGN_TRUCK_TO_PRODUCT_SUCCESS,
    DELETE_DELIVERY_PRODUCT_FROM_DAY,
    DELETE_DELIVERY_PRODUCT_FROM_DAY_FAIL,
    DELETE_DELIVERY_PRODUCT_FROM_DAY_SUCCESS,
    DELETE_TRUCK_FROM_DAY,
    DELETE_TRUCK_FROM_DAY_FAIL,
    DELETE_TRUCK_FROM_DAY_SUCCESS,
    GET_SMART_BOARD_COLOR_CODES,
    GET_SMART_BOARD_COLOR_CODES_FAIL,
    GET_SMART_BOARD_COLOR_CODES_SUCCESS,
    LOAD_BOTTOM_PUMP_WITH_PRODUCT,
    LOAD_BOTTOM_PUMP_WITH_PRODUCT_FAIL,
    LOAD_BOTTOM_PUMP_WITH_PRODUCT_SUCCESS,
    LOAD_BOTTOM_TRUCK_WITH_PRODUCT,
    LOAD_BOTTOM_TRUCK_WITH_PRODUCT_FAIL,
    LOAD_BOTTOM_TRUCK_WITH_PRODUCT_SUCCESS,
    LOAD_PRODUCT_LIST,
    LOAD_PRODUCT_LIST_FAIL,
    LOAD_PRODUCT_LIST_SUCCESS,
    LOAD_TH_RESOURCE_FAMILY,
    LOAD_TH_RESOURCE_FAMILY_FAIL,
    LOAD_TH_RESOURCE_FAMILY_SUCCESS,
    SAVE_TH_AUTOMATION_STATE_ATTR,
    UPDATE_PRODUCT_TIME,
    UPDATE_PRODUCT_TIME_FAIL,
    UPDATE_PRODUCT_TIME_SUCCESS,
} from '../actionTypes/thAutomationActionTypes';
import {
    ASSIGN_PUMP_TO_PRODUCT_THA_EP,
    ASSIGN_TRUCK_TO_PRODUCT_THA_EP,
    DELETE_DELIVERY_PRODUCT_FROM_DAY_EP,
    DELETE_TRUCK_FROM_DAY_EP,
    GET_SMART_BOARD_COLOR_CODES_EP,
    LOAD_BOTTOM_PUMP_WITH_PRODUCT_EP,
    LOAD_BOTTOM_TRUCK_WITH_PRODUCT_EP,
    LOAD_PRODUCT_LIST_THA,
    LOAD_TH_RESOURCE_FAMILY_EP,
    UPDATE_PRODUCT_TIME_EP,
} from '../shared/endpoints';
import {DpViewMode, ProjectPlanResourceType} from '../types/myDayPlanning';


const defaultState: any = {
    loadMsg: '',
    reloadListPane: false,
    selectedDate: undefined,
    shortCutPaneData: [],
    horizontalReSizer: screen.height - 330,
    draggedEvent: null,
    productList: [],
    collapseBookmarkPane: false,
    selectedResourceType: ProjectPlanResourceType.TEAM,
    resourceList: {},
    isResourceDataLoaded: false,
    thAutomationViewMode: DpViewMode.OneHour,
    isConfirmDeleteModalOpen: false,
    selectedWorker: null,
    btmTruckData: [],
    isPmolViewMode: false,
    truckResourceViewMode: DpViewMode.OneHour,
    isTruckViewMode: false,
    btmPumpData: [],
    dataToDelete: null,
    resourceFamily: [],
    colorCodes: [],
    thaStartTime: null,
    thaEndTime: null,
    columnDataState: [],
};

export default function thAutomationReducer(state = defaultState, action: any) {
    if (action) {
        switch (action.type) {

            case SAVE_TH_AUTOMATION_STATE_ATTR:
                const attr = Object.keys(action)[1];
                const value = Object.values(action)[1];
                return {
                    ...state,
                    [attr]: value
                };
            case LOAD_PRODUCT_LIST:
                return {
                    ...state,
                }
            case LOAD_PRODUCT_LIST_SUCCESS:
                return {
                    ...state,
                    productList: action.payload.data.result?.length > 0 ? action.payload.data.result : [],
                }
            case LOAD_PRODUCT_LIST_FAIL:
                return {
                    ...state,
                    productList: []
                }

            case LOAD_BOTTOM_TRUCK_WITH_PRODUCT:
                return {
                    ...state,
                }
            case LOAD_BOTTOM_TRUCK_WITH_PRODUCT_SUCCESS:
                return {
                    ...state,
                    btmTruckData: action.payload.data.result?.length > 0 ? action.payload.data.result : [],
                    // btmTruckData: dummyTrucks.result,
                }
            case LOAD_BOTTOM_TRUCK_WITH_PRODUCT_FAIL:
                return {
                    ...state,
                    btmTruckData: []
                }

            case DELETE_TRUCK_FROM_DAY:
                return {
                    ...state,
                }
            case DELETE_TRUCK_FROM_DAY_SUCCESS:
                return {
                    ...state,
                }
            case DELETE_TRUCK_FROM_DAY_FAIL:
                return {
                    ...state,
                }

            case UPDATE_PRODUCT_TIME:
                return {
                    ...state,
                }
            case UPDATE_PRODUCT_TIME_SUCCESS:
                return {
                    ...state,
                }
            case UPDATE_PRODUCT_TIME_FAIL:
                return {
                    ...state,
                }

            case LOAD_BOTTOM_PUMP_WITH_PRODUCT:
                return {
                    ...state,
                }
            case LOAD_BOTTOM_PUMP_WITH_PRODUCT_SUCCESS:
                return {
                    ...state,
                    btmPumpData: action.payload.data.result?.length > 0 ? action.payload.data.result : [],
                    // btmPumpData: dummyTrucks.result,
                }
            case LOAD_BOTTOM_PUMP_WITH_PRODUCT_FAIL:
                return {
                    ...state,
                    btmPumpData: []
                    // btmPumpData: dummyTrucks.result,
                }

            case LOAD_TH_RESOURCE_FAMILY:
                return {
                    ...state,
                }
            case LOAD_TH_RESOURCE_FAMILY_SUCCESS:
                return {
                    ...state,
                    resourceFamily: action.payload.data.result,
                }
            case LOAD_TH_RESOURCE_FAMILY_FAIL:
                return {
                    ...state,
                    resourceFamily: []
                }

            case GET_SMART_BOARD_COLOR_CODES:
                return {
                    ...state,
                }
            case GET_SMART_BOARD_COLOR_CODES_SUCCESS:
                return {
                    ...state,
                    colorCodes: action.payload.data.result,
                }
            case GET_SMART_BOARD_COLOR_CODES_FAIL:
                return {
                    ...state,
                    colorCodes: []
                }

            default:
                return state;
        }
    }
}


export function saveThAutomationStateAttr(attr: string, value: any) {
    return {
        type: SAVE_TH_AUTOMATION_STATE_ATTR,
        [attr]: value
    };
}

export const loadProductList = (data: any) => {
    return {
        types: [
            LOAD_PRODUCT_LIST,
            LOAD_PRODUCT_LIST_SUCCESS,
            LOAD_PRODUCT_LIST_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: LOAD_PRODUCT_LIST_THA,
                data: data
            }
        }
    };
};


export const assignTruckTOProduct = (data: any) => {
    return {
        types: [
            ASSIGN_TRUCK_TO_PRODUCT,
            ASSIGN_TRUCK_TO_PRODUCT_SUCCESS,
            ASSIGN_TRUCK_TO_PRODUCT_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: ASSIGN_TRUCK_TO_PRODUCT_THA_EP,
                data: data
            }
        }
    };
};

export const assignPumpTOProduct = (data: any) => {
    return {
        types: [
            ASSIGN_PUMP_TO_PRODUCT,
            ASSIGN_PUMP_TO_PRODUCT_SUCCESS,
            ASSIGN_PUMP_TO_PRODUCT_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: ASSIGN_PUMP_TO_PRODUCT_THA_EP,
                data: data
            }
        }
    };
};

export const loadBottomTruckWithProduct = (data: any) => {
    return {
        types: [
            LOAD_BOTTOM_TRUCK_WITH_PRODUCT,
            LOAD_BOTTOM_TRUCK_WITH_PRODUCT_SUCCESS,
            LOAD_BOTTOM_TRUCK_WITH_PRODUCT_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: LOAD_BOTTOM_TRUCK_WITH_PRODUCT_EP,
                data: data
            }
        }
    };
};

export const loadBottomPumpWithProduct = (data: any) => {
    return {
        types: [
            LOAD_BOTTOM_PUMP_WITH_PRODUCT,
            LOAD_BOTTOM_PUMP_WITH_PRODUCT_SUCCESS,
            LOAD_BOTTOM_PUMP_WITH_PRODUCT_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: LOAD_BOTTOM_PUMP_WITH_PRODUCT_EP,
                data: data
            }
        }
    };
};

export const deleteTruckFromDay = (data: any) => {
    return {
        types: [
            DELETE_TRUCK_FROM_DAY,
            DELETE_TRUCK_FROM_DAY_SUCCESS,
            DELETE_TRUCK_FROM_DAY_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: DELETE_TRUCK_FROM_DAY_EP,
                data: data
            }
        }
    };
};

export const deleteDeliveryProductFromDay = (data: any) => {
    return {
        types: [
            DELETE_DELIVERY_PRODUCT_FROM_DAY,
            DELETE_DELIVERY_PRODUCT_FROM_DAY_SUCCESS,
            DELETE_DELIVERY_PRODUCT_FROM_DAY_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: DELETE_DELIVERY_PRODUCT_FROM_DAY_EP,
                data: data
            }
        }
    };
};

export const updateVirtualProduct = (data: any) => {
    return {
        types: [
            UPDATE_PRODUCT_TIME,
            UPDATE_PRODUCT_TIME_SUCCESS,
            UPDATE_PRODUCT_TIME_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: UPDATE_PRODUCT_TIME_EP,
                data: data
            }
        }
    };
};

export const getThResourceFamilies = (data: any) => {
    return {
        types: [
            LOAD_TH_RESOURCE_FAMILY,
            LOAD_TH_RESOURCE_FAMILY_SUCCESS,
            LOAD_TH_RESOURCE_FAMILY_FAIL
        ],
        payload: {
            request: {
                method: 'POST',
                url: LOAD_TH_RESOURCE_FAMILY_EP,
                data: data
            }
        }
    };
};

export const getColorCodes = () => {
    return {
        types: [
            GET_SMART_BOARD_COLOR_CODES,
            GET_SMART_BOARD_COLOR_CODES_SUCCESS,
            GET_SMART_BOARD_COLOR_CODES_FAIL
        ],
        payload: {
            request: {
                url: GET_SMART_BOARD_COLOR_CODES_EP,
            }
        }
    };
};


const formatProjectListResponse = (projectList: any) => {
    // let name = ["Pradeep Madusanka","Shanuka Gayashan","Wasana Madushani","Supun costha"];
    if (projectList && projectList.length > 0) {
        return projectList.map((project: any) => {
            // let random = Math.floor(Math.random() * name.length);
            return {
                ...project,
                resourceId: project?.sequenceCode,
                resourceTitle: project?.title,
                color: "#fff",
                //color:projectUniqueColor(project?.sequenceCode),
                siteLeader: project?.siteManager?.name
            }
        })
    } else {
        return []
    }
};


const apiResult = [
    {
        id: 1,
        truckTitle: 'T1',
        product: [
            {
                id: '4d7srt582da',
                startTime: new Date(2023, 0, 20, 5, 45, 0),
                endTime: new Date(2023, 0, 20, 7, 0, 0),
                productTitle: 'Product3',
            },
            {
                id: '12asdn542da',
                startTime: new Date(2023, 0, 20, 7, 0, 0),
                endTime: new Date(2023, 0, 20, 10, 30, 0),
                productTitle: 'Product2',
            }
        ]
    },
    {
        id: 2,
        truckTitle: 'T2',
        product: []
    },
    {
        id: 3,
        truckTitle: 'T3',
        product: []
    },
    {
        id: 4,
        truckTitle: 'T4',
        product: []
    },
    {
        id: 5,
        truckTitle: 'T5',
        product: []
    },
    {
        id: 6,
        truckTitle: 'T6',
        product: []
    },
    {
        id: 7,
        truckTitle: 'T7',
        product: []
    }
];

const dummyProject =
  [
      {
          title: 'Product 01',
          id: '1',
          details1: '40m3',
          details2: '10m3/h',
          trucks: [
              {id: '22222', title: ""},
              {id: 2, title: "T1"},
              {id: 3, title: "T2"},
              {id: 4, title: "T3"},
              {id: 5, title: "T4"},
              {id: 6, title: "T5"},
              {id: 7, title: "T6"},
              {id: 8, title: "T7"},
              {id: 9, title: "T8"},
              {id: 10, title: "T9"},
              {id: 11, title: "T10"}]
      },
      {
          title: 'Product 02',
          productId: '2',
          details1: '40m3',
          details2: '10m3/h',
          trucks: [
              {
                  id: '22222',
                  title: '',
              },
              {
                  id: 1,
                  title: 'T1',
              },
              {
                  id: 9,
                  title: 'T2',
              },
              {
                  id: 10,
                  title: 'T3',
              },
              {
                  id: 11,
                  title: 'T4',
              },
              {
                  id: 12,
                  title: 'T5',
              },
              {
                  id: 13,
                  title: 'T6',
              },
              {
                  id: 14,
                  title: 'T7',
              },
              {
                  id: 15,
                  title: 'T8',
              },
              {
                  id: 16,
                  title: 'T9',
              },
              {
                  id: 17,
                  title: 'T10',
              },

          ]
      },
      {
          title: 'Product 03',
          productId: '3',
          details1: '40m3',
          details2: '10m3/h',
          trucks: [
              {
                  id: 0,
                  title: '',
              },
              {
                  id: 1,
                  title: 'T10',
              },
              {
                  id: 20,
                  title: 'T12',
              },

          ]
      },

      {
          title: 'Product 04',
          productId: '4',
          details1: '40m3',
          details2: '10m3/h',
          trucks: [
              {
                  id: 0,
                  title: '',
              },
              {
                  id: 1,
                  title: 'T20',
              },
              {
                  id: 23,
                  title: 'T22',
              },

          ]
      },

  ];


const dummyTrucks = {
    "result": [
        {
            "id": "4a32d8bd-02f4-4a64-9a2a-a5740a4b2fc6",
            "truckTitle": "CAr01-0006 - Truck 1",
            "truckCapacity": "100",
            "product": [],
            "timeSlots": [
                {'sTime': '01:00', 'eTime': '02:00'},
                {'sTime': '03:00', 'eTime': '04:00'},
                {'sTime': '05:00', 'eTime': '06:00'},
                {'sTime': '07:00', 'eTime': '08:00'},
                {'sTime': '09:00', 'eTime': '10:00'},
                {'sTime': '10:10', 'eTime': '11:00'},
                {'sTime': '11:00', 'eTime': '12:00'},
                {'sTime': '13:00', 'eTime': '14:00'},
                {'sTime': '15:00', 'eTime': '16:00'},
                {'sTime': '17:00', 'eTime': '18:00'},
                {'sTime': '19:00', 'eTime': '20:00'},
                {'sTime': '20:00', 'eTime': '21:00'},
                {'sTime': '22:00', 'eTime': '23:00'},
                {'sTime': '24:00', 'eTime': '00:00'},


            ]
        },
        {
            "id": "7feff3ff-ca29-415e-97e9-48bd42d2bdf7",
            "truckTitle": "CAr01-0009 - truck 4",
            "truckCapacity": "14",
            "product": [],
            "timeSlots": [
                {'sTime': '01:00', 'eTime': '02:00'},
                {'sTime': '03:00', 'eTime': '04:00'},
                {'sTime': '05:00', 'eTime': '06:00'},
                {'sTime': '07:00', 'eTime': '08:00'},
                {'sTime': '09:00', 'eTime': '10:00'},
                {'sTime': '10:10', 'eTime': '11:00'},
                {'sTime': '11:00', 'eTime': '12:00'},
                {'sTime': '13:00', 'eTime': '14:00'},
                {'sTime': '15:00', 'eTime': '16:00'},
                {'sTime': '17:00', 'eTime': '18:00'},


            ]
        },
        {
            "id": "e582790b-0eed-41df-bf35-49439054460c",
            "truckTitle": "CAr01-0010 - truck 5",
            "truckCapacity": "16",
            "product": [],
            "timeSlots": [
                {'sTime': '07:00', 'eTime': '08:00'},
                {'sTime': '09:00', 'eTime': '10:00'},
                {'sTime': '10:10', 'eTime': '11:00'},
                {'sTime': '11:00', 'eTime': '12:00'},
                {'sTime': '13:00', 'eTime': '14:00'},
                {'sTime': '15:00', 'eTime': '16:00'},
                {'sTime': '17:00', 'eTime': '18:00'},
                {'sTime': '19:00', 'eTime': '20:00'},


            ]
        },
        {
            "id": "2ebbc7cf-d45a-4238-b3be-336eacded504",
            "truckTitle": "CAr01-0011 - truck6",
            "truckCapacity": "12",
            "product": [],
            "timeSlots": [
                {'sTime': '11:00', 'eTime': '12:00'},
                {'sTime': '13:00', 'eTime': '14:00'},
                {'sTime': '15:00', 'eTime': '16:00'},
                {'sTime': '17:00', 'eTime': '18:00'},
                {'sTime': '19:00', 'eTime': '20:00'},
                {'sTime': '20:00', 'eTime': '21:00'},
                {'sTime': '22:00', 'eTime': '23:00'},
                {'sTime': '24:00', 'eTime': '00:00'},


            ]
        },
        {
            "id": "556abad3-fb5e-4d94-bbbe-6c89601b055f",
            "truckTitle": "CAr01-0012 - truck 7",
            "truckCapacity": "20",
            "product": [],
            "timeSlots": [
                {'sTime': '01:00', 'eTime': '02:00'},
                {'sTime': '03:00', 'eTime': '04:00'},
                {'sTime': '05:00', 'eTime': '06:00'},
                {'sTime': '07:00', 'eTime': '08:00'},
                {'sTime': '09:00', 'eTime': '10:00'},
                {'sTime': '10:10', 'eTime': '11:00'},
                {'sTime': '11:00', 'eTime': '12:00'},
                {'sTime': '13:00', 'eTime': '14:00'},


            ]
        },
        {
            "id": "73e5de50-8104-43e4-a29f-444efbf29f21",
            "truckTitle": "CAr01-0013 - truck 8",
            "truckCapacity": "15",
            "product": [],
            "timeSlots": [
                {'sTime': '01:00', 'eTime': '02:00'},
                {'sTime': '03:00', 'eTime': '04:00'},
                {'sTime': '05:00', 'eTime': '06:00'},
                {'sTime': '07:00', 'eTime': '08:00'},
                {'sTime': '09:00', 'eTime': '10:00'},
                {'sTime': '10:10', 'eTime': '11:00'},
                {'sTime': '11:00', 'eTime': '12:00'},
                {'sTime': '13:00', 'eTime': '14:00'},
                {'sTime': '15:00', 'eTime': '16:00'},
                {'sTime': '17:00', 'eTime': '18:00'},
                {'sTime': '19:00', 'eTime': '20:00'},


            ]
        },
        {
            "id": "9916a5d2-e929-40e9-af2a-6ab7e1b455de",
            "truckTitle": "CAr01-0015 - truck 9 ",
            "truckCapacity": "20",
            "product": [],
            "timeSlots": [
                {'sTime': '11:00', 'eTime': '12:00'},
                {'sTime': '13:00', 'eTime': '14:00'},
                {'sTime': '15:00', 'eTime': '16:00'},
                {'sTime': '17:00', 'eTime': '18:00'},


            ]
        },
    ],
    "statusCode": 200,
    "message": "Ok",
    "status": true,
    "errors": null
};

import {
  DetailsListLayoutMode,
  DetailsRowCheck,
  getTheme,
  IColumn,
  IDetailsRowBaseProps,
  IDetailsRowCheckStyles,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../../theme';
import { WorkFlowTaskColumn } from '../../../../shared/tableColumn/workflowColumns';
import { WorkFlowDropDowns } from '../../../../types/workFlow';
import { GOOD_PICKING_ID } from '../container';

const theme = getTheme();

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const removeIcon: IIconProps = {
  iconName: 'Delete',
  styles: iconButtonStyles,
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const WorkFlowTasksGrid = (props: {
  dataList: any[];
  title: string;
  link: string;
  id: string;
  isLoaded: boolean;
  isExpand: boolean;
  selection: any;
  onChangePickedQuantity: (index: number, value: string | number) => void;
  onChangeComment: (index: number, value: string | number) => void;
  onChangeTotalPrice: (index: number, value: string | number) => void;
  onChangeStockAvailability: (index: number, value: string | number) => void;
  taxanomyData: WorkFlowDropDowns;
  source: string;
  disabled: boolean;
  typeId: string;
}) => {
  const { t } = useTranslation();
  const columns = [
    {
      key: 'column1',
      name: t('source'),
      fieldName: 'source',
      minWidth: props.isExpand ? 200 : 100,
      maxWidth: props.isExpand ? 200 : 100,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('cpcItem'),
      fieldName: 'corporateProductCatalog',
      minWidth: props.isExpand ? 300 : 200,
      maxWidth: props.isExpand ? 500 : 300,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('quantity'),
      fieldName: 'quantity',
      minWidth: props.isExpand ? 150 : 100,
      maxWidth: props.isExpand ? 150 : 100,
      isResizable: true,
    },
    {
      key: 'column4',
      name: t('mou'),
      fieldName: 'mou',
      minWidth: props.isExpand ? 100 : 100,
      maxWidth: props.isExpand ? 100 : 100,
      isResizable: true,
    },
    {
      key: 'column5',
      name: t('pickedQuantity'),
      fieldName: 'pickedQuantity',
      minWidth: props.isExpand ? 150 : 100,
      maxWidth: props.isExpand ? 150 : 100,
      isResizable: true,
    },
    {
      key: 'column6',
      name: t('destination'),
      fieldName: 'destination',
      minWidth: props.isExpand ? 300 : 200,
      maxWidth: props.isExpand ? 300 : 300,
      isResizable: true,
    },
    // {
    //     key: 'column7',
    //     name: t('totalPrice'),
    //     fieldName: 'totalPrize',
    //     minWidth: props.isExpand ? 150 : 100,
    //     maxWidth: props.isExpand ? 150 : 100,
    //     isResizable: true
    // },
    {
      key: 'column9',
      name: t('stockAvailability'),
      fieldName: 'stockAvailability',
      minWidth: props.isExpand ? 100 : 100,
      maxWidth: props.isExpand ? 100 : 100,
      isResizable: true,
    },
    {
      key: 'column8',
      name: t('Comments'),
      fieldName: 'comment',
      minWidth: props.isExpand ? 300 : 300,
      maxWidth: props.isExpand ? 300 : 300,
      isResizable: true,
    },
  ];


  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
      minHeight: (props.dataList && props.dataList.length > 1) ? 200 : 110,
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
  });

  useEffect(() => {
    changeColor();
  });

  const stackStyles: IStackStyles = { root: { padding: 0 } };
  const stackItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 40,
      alignItems: 'flex-start',
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 15,
    padding: 10,
  };

  const detailsRowCheckStyles: Partial<IDetailsRowCheckStyles> = { root: { visibility: 'hidden' } };

  const _onRenderCheckForFooterRow: IDetailsRowBaseProps['onRenderCheck'] = (props): JSX.Element => {
    return <DetailsRowCheck {...props} styles={detailsRowCheckStyles} selected={true}/>;
  };

  const _onRenderCheckForHeaderRow: IDetailsRowBaseProps['onRenderCheck'] = (props): JSX.Element => {
    return <DetailsRowCheck {...props} styles={detailsRowCheckStyles} selected={true}/>;
  };


  const changeColor = () => {
    const cquantityClass = document.getElementsByClassName('mcomments');
    for (let i = 0; i < cquantityClass.length; i++) {
      const parentElement = cquantityClass[i].parentElement;
      if (parentElement) {
        parentElement.style.borderRight = '1px solid rgb(243, 242, 241)';
      }
    }

    const totalCost1Class = document.getElementsByClassName('mmou');
    for (let i = 0; i < totalCost1Class.length; i++) {
      const parentElement = totalCost1Class[i].parentElement;
      if (parentElement) {
        parentElement.style.borderRight = '1px solid rgb(243, 242, 241)';
      }
    }
  };

  const getColumns = () => {
    let key: any;
    if (props.typeId === GOOD_PICKING_ID) {
      key = 'destination';
    } else {
      key = 'source';
    }
    const cols = columns.filter((obj) => {
      return obj.fieldName !== key;
    });

    return cols;
  };

  return (
    <div className={'document-pane-card purchase-order'}>
      <div className={'card-header'}>
        <div className="sub-header-title">
          <Stack
            horizontal
            horizontalAlign="space-between"
            styles={stackStyles}
            tokens={stackTokens}
          >
            <Stack.Item styles={stackItemStyles}>
              <Label
                id={props.link}
                style={{ marginTop: 10, fontSize: '1.1rem' }}
              >
                {props.id}. {props.title}
              </Label>
            </Stack.Item>

          </Stack>
        </div>
      </div>

      <div className={'card-body'}>
        <div className="proj-detail-block" id={props.id}>
          <div className="marginTop">
            <div style={{ padding: 12 }}>
              <div className="ms-Grid-row marginTop">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  <div className={classNames.wrapper}>
                    <div className={'scrollablePane'}>
                      <ShimmeredDetailsList
                        items={props.dataList ? props.dataList : []}
                        columns={getColumns()}
                        setKey="set"
                        enableShimmer={props.isLoaded}
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionPreservedOnEmptyClick={true}
                        checkboxVisibility={1}
                        onRenderItemColumn={(
                          item: any,
                          index?: number,
                          column?: IColumn,
                        ) =>
                          WorkFlowTaskColumn(
                            item,
                            index,
                            column,
                            true,
                            props.onChangePickedQuantity,
                            props.onChangeComment,
                            props.onChangeTotalPrice,
                            props.onChangeStockAvailability,
                            props.taxanomyData,
                            props.source,
                            props.disabled,
                          )
                        }
                        // onRowDidMount={()=>{
                        //   changeColor()
                        // }}
                        selection={props.selection}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="Row checkbox"
                        // onRenderDetailsHeader={(props)=>_onRenderDetailsHeader(props!!)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'card-footer'}>
      </div>
    </div>
  );
};

export default WorkFlowTasksGrid;

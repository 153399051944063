import React, {useEffect, useMemo, useState} from 'react';
import {Calendar as Cal, momentLocalizer, Views} from 'react-big-calendar';
import moment from 'moment';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import {formatDateTimeToISOString, getCalenderLocalizer} from '../../../../shared/util';
import {ProjectPlanResourceType} from '../../../../types/myDayPlanning';
import * as TdDropBox from '../../../../shared/dnd/dropBox';
import {useId} from '@uifabric/react-hooks';
import {
    getPmolTypeIcon,
    ThAutomationDeleteTypes,
    ThAutomationDisplayTypes,
    ThAutomationResourceType,
} from '../../../../types/thAutomation';
import client from '../../../../api';
import {LOAD_TRUCK_PRODUCT_LIST_THA} from '../../../../shared/endpoints';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {
    DirectionalHint,
    FontIcon,
    ITooltipHostStyles,
    registerIcons,
    TooltipDelay,
    TooltipHost,
} from '@fluentui/react';
import {
    deleteDeliveryProductFromDay,
    loadBottomPumpWithProduct,
    loadBottomTruckWithProduct,
    loadProductList,
    saveThAutomationStateAttr,
    updateVirtualProduct,
} from '../../../../reducers/thAutomationReducer';
import {SvgIcons} from '../../../../shared/svgIcons/svgIcons';
import {useTranslation} from 'react-i18next';
import {v4 as uuidv4} from 'uuid';

const DragAndDropCalendar = withDragAndDrop(Cal as any);
const now = () => new Date(2019, 1, 4);
let selectTimeout: any;
registerIcons(SvgIcons);

const CustomCalender = (props: { item: any, index: number, isLast: boolean }) => {
    const dispatch = useDispatch();
    const store = useStore();
    const {t} = useTranslation();
    const {
        productList,
        selectedDate,
        thAutomationViewMode,
        isPmolViewMode,
        colorCodes,
        thaStartTime,
        thaEndTime,
        columnDataState
    } = useSelector(
        (state: any) => state.thAutomation
    );

    const localizer: any = momentLocalizer(moment);
    const {defaultDate, views} = useMemo(
        () => ({
            defaultDate: moment(selectedDate).toDate(),
            views: {
                day: true,
                // week: MyWeek,
            },
        }),
        []
    )
    const colors = useMemo(() => colorCodes, [colorCodes]);

    const [eventList, setEventList]: any = useState([])
    const [isDeliveryProductDeleting, setIsDeliveryProductDeleting]: any = useState(false)
    const [deliveryProductDeletingId, setDeliveryProductDeletingId]: any = useState(null)

    useEffect(() => {
        getCalenderLocalizer();

        return () => {
            // dispatch(saveThAutomationStateAttr("columnDataState", []));
        }
    }, [])

    useEffect(() => {
        let columnData = {pbsId: props.item.id, color: colors[props.index % colors.length].bgColor}

        if(columnDataState){
            let index = columnDataState.findIndex((item: any) => item.pbsId === props.item.id);
            if(index > -1){
                columnDataState[index] = columnData;
            }else{
                columnDataState.push(columnData);
            }
        }
        dispatch(saveThAutomationStateAttr("columnDataState", columnDataState));

        loadTruckProductList().then((response) => {
            setEventList(formatTruckResponse(response?.data?.result))
        });
    }, [props.item]);

    useEffect(() => {
        loadTruckProductList().then((response) => {
            // console.log({response})
            setEventList(formatTruckResponse(response?.data?.result))
        });
    }, [isPmolViewMode]);

    const loadTruckProductList = async () => {
        if (props.item?.id) {
            // const response = await client.get(
            //   LOAD_TRUCK_PRODUCT_LIST_THA+props.item?.id
            // );

            return await client.post(
                LOAD_TRUCK_PRODUCT_LIST_THA, {
                    id: props.item?.id,
                    isPmol: isPmolViewMode,
                    projectSequenceCode: props.item?.projectSequenceCode
                }
            )

        }
        return null
    };

    const formatTruckResponse = (result: any) => {
        if (result && result.length > 0) {

            let res = result.map((item: any) => {
                // return {
                //   id: item?.id,
                //   resourceId: '22222',
                //   // start: formatTimeForEvent(item?.startTime, item?.sTime),
                //   // end: formatTimeForEvent(item?.endTime, item?.eTime),
                //   start: new Date(2021, 5, 8, 7, 0, 0),
                //   end: new Date(2021, 5, 8, 10, 0, 0),
                // }
                return {
                    ...item,
                    id: item?.id,
                    title: item?.productTruckId === '22222' ? item?.type === '13' ? item?.size + '/' + item?.capacity : '' : '',
                    type: item?.type,
                    turnNumber: item?.turnNumber ? item?.turnNumber : '',
                    // start: new Date(2023, 1, 20, 8, 0, 0),
                    // end: new Date(2023, 1, 20, 10, 0, 0),
                    start: formatTimeForEvent(item?.startTime, item?.sTime),
                    end: formatTimeForEvent(item?.endTime, item?.eTime),
                    resourceId: item?.productTruckId,
                }
            })
            const startOfDay = new Date(2023, 1, 20, 7, 0, 0);
            const endOfDay = new Date(2023, 1, 20, 18, 0, 0);
            const modifiedEvents: any = [...res];
            let previousEventEnd = startOfDay;
            // for (const event of modifiedEvents) {
            //   if(event.type!='13'){
            //     if (previousEventEnd < event.start) {
            //       const newEvent = {
            //         id: modifiedEvents.length + 1,
            //         title: "",
            //         type: '14',
            //         start: previousEventEnd,
            //         end: event.start,
            //         resourceId: '22222'
            //       };
            //       modifiedEvents.push(newEvent);
            //     }
            //     previousEventEnd = event.end;
            //   }
            //
            // }

            // Check for missing time frame after the last event
            // if (previousEventEnd < endOfDay) {
            //   // console.log({previousEventEnd});
            //   const newEvent = {
            //     id: modifiedEvents.length + 1,
            //     title: "",
            //     type: '14',
            //     start: previousEventEnd,
            //     end: endOfDay
            //   };
            //   modifiedEvents.push(newEvent);
            // }

//defaultDate
//         const newEvent = {
//           id: modifiedEvents.length + 1,
//           title: "",
//           start:  formatTimeForEvent('2023-01-20T12:17:52.3466667','07:00 AM'),
//           type:2,
//           end:  formatTimeForEvent('2023-01-20T12:17:52.3466667','08:17 PM'),
//           resourceId: '22222'
//         };
//         modifiedEvents.push(newEvent);

            // console.log({modifiedEvents})

            return res;
        } else {
            return []
        }

    }

    const formatTimeForEvent = (date: string, time: any) => {
        let formatted = moment(date).format('YYYY/MM/DD');
        let dateonly = (formatted === 'Invalid date') ? '' : formatted.toString();
        if (dateonly && time) {
            let t = time ? time : '';
            let dateTime = dateonly + ' ' + t;
            return moment(dateTime).toDate();
        } else {
            return moment.utc(date).local().toDate();
        }
    };


    const handleTruckDelete = (resource: any) => {
        // console.log('truck resource> ', resource);
        // {
        //   id: '794c8554-6b89-4d11-9bbe-c75f44a8a5cd',
        //   cpcId: '4a32d8bd-02f4-4a64-9a2a-a5740a4b2fc6',
        //   productId: '23366cd9-daf6-483c-89f9-d90f12c1ac09',
        //   date: '2023-01-20T00:00:00',
        //   title: 'Truck 1'
        // }
        dispatch(saveThAutomationStateAttr('isConfirmDeleteModalOpen', true));
        dispatch(saveThAutomationStateAttr('dataToDelete', {
            ...resource,
            deleteType: resource?.isTruck ? ThAutomationDeleteTypes.TRUCK_DELETE_TOP : ThAutomationDeleteTypes.PUMP_DELETE_TOP
        }));
    };


    const handleDeliveryProductDelete = (DeliveryProduct: any) => {
        setDeliveryProductDeletingId(DeliveryProduct?.id,)
        let data = {
            id: DeliveryProduct?.id,
            cpcId: DeliveryProduct?.cpcId,
            productTruckId: DeliveryProduct?.productTruckId,
            turnNumber: DeliveryProduct?.turnNumber,
            projectSequenceCode: props.item?.projectSequenceCode,
            date: formatDateTimeToISOString(store.getState()?.thAutomation?.selectedDate)
        };
        let date = formatDateTimeToISOString(store.getState()?.thAutomation?.selectedDate);

        Promise.all([
            setIsDeliveryProductDeleting(true),
            dispatch(deleteDeliveryProductFromDay(data))
        ]).then(() => {
            if (DeliveryProduct?.type === ThAutomationDisplayTypes.PUMP_PRODUCT) {
                dispatch(loadBottomPumpWithProduct({date: date}));
            } else {
                dispatch(loadBottomTruckWithProduct({date: date}));
            }
            dispatch(loadProductList({date: date}));
            setTimeout(() => {
                setIsDeliveryProductDeleting(false)
                setDeliveryProductDeletingId(null)
            }, 2000)
        });
    };


    const getClassNameForType = (type: any) => {
        switch (type) {
            case ThAutomationDisplayTypes.METER:
                return "meter";
            case ThAutomationDisplayTypes.CONCRETE_MEETER_UNLOADING:
                return "loading";
            case ThAutomationDisplayTypes.CONCRETE_MEETER_NOT_UNLOADING:
                return "notLanding";
            case ThAutomationDisplayTypes.PRODUCT:
                return "virtual_product";
            case ThAutomationDisplayTypes.TRAVEL1:
                return "pmol_travel1";
            case ThAutomationDisplayTypes.LOADING:
                return "pmol_loading";
            case ThAutomationDisplayTypes.UNLOADING:
                return "pmol_unloading";
            case ThAutomationDisplayTypes.TRAVEL2:
                return "pmol_travel2";
            case ThAutomationDisplayTypes.PUMP_PRODUCT:
                return "pump_product";
            default:
                return "";
        }
    };

    const toolbar = () => {
        return <div key={uuidv4()} className={'custom-toolbar'}
                    style={{
                        backgroundColor: colors[props.index % colors.length].bgColor,
                        color: colors[props.index % colors.length].font
                    }}>
            <TdDropBox.DropBox
                key={uuidv4()}
                item={props.item}
                day={new Date}
                type={[ThAutomationResourceType.TRUCK, ThAutomationResourceType.PUMP]}
            >
                <div
                    key={uuidv4()}
                    style={{
                        width: '100%',
                        minHeight: '70px',
                        whiteSpace: 'nowrap'
                    }}>
                    {props.item.title}<br/>
                    {props.item?.pbsDynamicAttributes ?
                        <div className={'pbsDynamicAttributes'}>
                            {props.item.pbsDynamicAttributes.map((item: any) => {
                                return <span
                                    key={uuidv4()}>{t(item.key.charAt(0).toLowerCase() + item.key.slice(1))} : {item.value}<br/></span>
                                // return <span>{item.key} : {(item.key==='EndTime' ||item.key==='StartTime')?format24hDateTime(item.value):item.value}<br/></span>
                            })}
                        </div>
                        : false}
                </div>
            </TdDropBox.DropBox>
        </div>
    }

    const EventWrapper = ({event, children}: any) => {
        const newChildren = {...children};
        const newChildrenProps = {...newChildren.props};
        newChildrenProps.className = `${newChildrenProps.className}`;
        newChildren.props = {...newChildrenProps};
        return <div key={event?.resourceId + props.item?.id} id={event?.resourceId + props.item?.id}
                    className={`thaEventWrapper ${event?.resourceId === '22222' ? 'concrete-mixer' : ''} ${event?.isTruck ? 'truck' : 'pump-car'}  ${getClassNameForType(event?.type)}`}>
            {newChildren}
        </div>;
    };


    const renderTimeSlotAlert = (event: any) => {

        if (event?.event?.isError) {
            const hostStyles: Partial<ITooltipHostStyles> = {root: {display: 'inline-block'}};
            const tooltipId = useId('tooltip');
            return <div style={{alignSelf: 'center'}}>
                <TooltipHost
                    tooltipProps={{
                        onRenderContent: () => (
                            <div style={{margin: 10, padding: 0}}>{event?.event?.errorMessage} </div>
                        ),
                    }}
                    delay={TooltipDelay.zero}
                    id={tooltipId}
                    directionalHint={DirectionalHint.bottomCenter}
                    styles={hostStyles}
                >
                    <FontIcon
                        aria-label="IncidentTriangle"
                        iconName="IncidentTriangle"
                        className={'delete-button'}
                        style={{color: 'red', fontSize: 16, cursor: 'pointer'}}
                    />
                </TooltipHost>
            </div>
        } else {
            return <></>
        }
    }

    const renderTitle = (event: any) => {
        if (event?.title) {
            return <div style={{color: 'black', fontSize: 11, marginTop: 5}}> {event?.title}</div>
        } else {
            return <></>
        }
    }
    const renderDeleteIcon = (event: any) => {
        if (event?.event?.type === ThAutomationDisplayTypes.PRODUCT || event?.event?.type === ThAutomationDisplayTypes.PUMP_PRODUCT) {
            return <div style={{alignSelf: 'flex-end'}}><FontIcon
                aria-label="delete-truck"
                iconName="Delete"
                className={'delete-button'}
                // style={{fontSize: 12}}
                onClick={() => handleDeliveryProductDelete(event?.event)}
            />
            </div>
        } else {
            return <></>
        }
    }
    const renderPmolIcon = (event: any) => {
        let iconName = getPmolTypeIcon(event?.event?.type);
        if (iconName) {
            return <>
                <FontIcon
                    aria-label="delete-truck"
                    iconName={iconName}
                    className={''}
                    style={{height: 20, width: 20}}
                />
            </>
        } else {
            return <></>
        }
    }

    const customEvent = (event: any) => {
        // console.log('event > ', event);
        if (event?.event?.type === ThAutomationDisplayTypes.CONCRETE_MEETER_NOT_UNLOADING) {
            return <TdDropBox.DropBox
                item={{...event, pbsId: props.item?.id, projectSequenceCode: props.item?.projectSequenceCode}}
                day={new Date}
                type={[ThAutomationResourceType.TRUCK]}
                isPmol={true}
                key={event?.event?.id + "eventDropBoxCONCRETE"}
            >
                <div style={{marginTop: event?.event?.type === '13' ? 5 : 0, minWidth: 20}}>{event?.title}</div>
            </TdDropBox.DropBox>;
        } else {
            return <TdDropBox.DropBox
                item={event}
                day={new Date}
                type={[ProjectPlanResourceType.TEAM, ProjectPlanResourceType.VEHICLE, ProjectPlanResourceType.TOOL]}
                isPmol={true}
                key={event?.event?.id + "eventDropBox"}
            >
                <div id={event?.event?.id + "eventBox"} title={`${event?.event?.sTime}-${event?.event?.eTime}`}
                     style={{width: '100%', height: '100%'}}>
                    {renderTitle(event)}
                    <div style={{display: 'flex', justifyContent: 'left'}}>
                        {renderPmolIcon(event)}
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{width: '20%'}}></div>
                        {renderTimeSlotAlert(event)}
                        {renderDeleteIcon(event)}
                    </div>
                </div>
            </TdDropBox.DropBox>;
        }
    };

    const resourceHeader = ({label, index, resource}: any) => (
        <div key={uuidv4()} style={{width: '100%', height: 40}}>
            {
                label
                    ? <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <span style={{width: '100%'}}>{label}</span>
                        <FontIcon
                            aria-label="delete-truck"
                            iconName="Delete"
                            className={'delete-button'}
                            // style={{fontSize: 12}}
                            onClick={() => handleTruckDelete(resource)}
                        />
                    </div>
                    : <div key={uuidv4()}>&nbsp;</div>
            }
        </div>
    );

    const resizeEvent = ({event, start, end}: any) => {


        Promise.all([
            updateEvent(event, start, end, event?.resourceId)
        ]).then(() => {
            updateTimeOfVirtualProduct(event, start, end)
        });
    };

    const onEventDrop = ({event, start, end, resourceId}: any) => {
        if (event?.resourceId === resourceId) {
            Promise.all([
                updateEvent(event, start, end, resourceId)
            ]).then(() => {
                updateTimeOfVirtualProduct(event, start, end)
            });
        }
    };

    const updateEvent = async (event: any, start: any, end: any, resourceId: any) => {
        const idx = eventList.indexOf(event);
        const updatedEvent = {...event, start, end, resourceId};

        const nextEvents = [...eventList];
        nextEvents.splice(idx, 1, updatedEvent);

        setEventList(nextEvents)
    };


    const updateTimeOfVirtualProduct = (event: any, start: any, end: any) => {
        let data = {
            ...event,
            sTime: moment(start).format('LT'),
            eTime: moment(end).format('LT'),
            startTime: moment(start).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            endTime: moment(end).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            projectSequenceCode: props.item?.projectSequenceCode,
        }
        Promise.all([
            dispatch(updateVirtualProduct(data))
        ]).then(() => {
            loadTruckProductList().then((response) => {
                setEventList(formatTruckResponse(response?.data?.result))
            });
        });
    }

    return (
        <div key={uuidv4()} className={props.index === 0 ? 'isFirst' : 'hide-time'} style={{width: '30%'}}>
            {/*{JSON.stringify(eventList)}*/}
            <div className={`custom-calender ${thAutomationViewMode > 30 ? 'set-height' : ''}`} key={uuidv4()}
                 style={{width: props.isLast ? '96%' : '100%'}}>      {/* height:'100%'*/}
                <DragAndDropCalendar
                    key={uuidv4()}
                    defaultDate={defaultDate}
                    defaultView={Views.DAY}
                    events={eventList}
                    localizer={localizer}
                    views={views}
                    selectable="ignoreEvents"
                    resources={props.item?.trucks ? props.item?.trucks : []}
                    // resourceIdAccessor="id"
                    resourceIdAccessor={(trucks: any) => trucks.id}
                    resourceTitleAccessor={(trucks: any) => trucks.title}
                    onEventDrop={onEventDrop}
                    onEventResize={resizeEvent}
                    draggableAccessor={(event: any) => {
                        return (event?.type === ThAutomationDisplayTypes.PRODUCT)
                    }}
                    components={{
                        toolbar: toolbar,
                        resourceHeader: resourceHeader,
                        eventWrapper: EventWrapper,
                        event: customEvent,
                    }}
                    formats={{
                        eventTimeRangeFormat: ({start, end}, culture: any, localizer: any) =>
                            localizer.format(start, 'HH:mm', culture) +
                            ' - ' +
                            localizer.format(end, 'HH:mm', culture),
                        timeGutterFormat: (date, culture: any, localizer: any) =>
                            localizer.format(date, 'HH:mm', culture),
                    }}
                    step={2}
                    timeslots={thAutomationViewMode ?? 30}
                    min={
                        new Date(
                            new Date(defaultDate).getFullYear(),
                            new Date(defaultDate).getMonth(),
                            new Date(defaultDate).getDate(),
                            thaStartTime ?? 0
                        )
                    }
                    max={
                        new Date(
                            new Date(defaultDate).getFullYear(),
                            new Date(defaultDate).getMonth(),
                            new Date(defaultDate).getDate(),
                            thaEndTime ?? 23
                        )
                    }
                />
            </div>

        </div>
    );
};

export default CustomCalender;

import React from 'react';
import {
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  registerIcons,
  Stack,
  TextField,
} from '@fluentui/react';
import i18n from '../../../i18n';
import { SvgIcons } from '../../shared/svgIcons/svgIcons';
import { store } from '../../../index';
import { uPrinceTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';

registerIcons(SvgIcons);

const classNames = mergeStyleSets({

  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
});

const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const styles = {
  textField: {
    color: uPrinceTheme.palette.themePrimary,
  },
};

const  RfqSupplier=()=>{
  const {rfqFormData}=store.getState()?.po;
  const supplierContact=rfqFormData?.supplierContact;
  

  return (
    <div>

      <div className={'card-header'}>
        <Link href="#" id="invoice-invoice-amount">
          <Label>2. {i18n.t('rfqSupplier')} </Label>
        </Link>
      </div>
      <div className={'card-body'}>
        <div className="proj-detail-content inner">

          <div className="marginTop">
            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    label={i18n.t('supplierOrganisation')}
                    disabled={true}
                    style={styles.textField}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    value={supplierContact?.customerContact?supplierContact?.customerContact:''}
                    label={i18n.t('supplierContact')}
                    disabled={true}
                    style={styles.textField}
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>

          <div className="marginTop">
            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    value={supplierContact?.customerMobile?supplierContact?.customerMobile:''}
                    label={i18n.t('supplierMobileNumber')}
                    disabled={true}
                    style={styles.textField}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    value={supplierContact?.customerEmail?supplierContact?.customerEmail:''}
                    label={i18n.t('supplierEmail')}
                    disabled={true}
                    style={styles.textField}
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>

          <div className="marginTop">
            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    value={rfqFormData?.supplierReference?rfqFormData?.supplierReference:''}
                    label={i18n.t('supplierReference')}
                    disabled={true}
                    style={styles.textField}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    value={rfqFormData?.supplierOrderDate ? rfqFormData?.supplierOrderDate?.split('T')[0] : ''}
                    label={i18n.t('supplierOrderDate')}
                    disabled={true}
                    style={styles.textField}
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>
        </div>
      </div>

      <div className={'card-footer'}>
        <br/>
      </div>

    </div>
  );
};

export default RfqSupplier;

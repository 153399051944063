import { Dropdown, Label, TextField } from '@fluentui/react';
import React, { useContext, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useDebouncedCallback from 'use-debounce/lib/useDebouncedCallback';
import {
  GetVPFilterDropdownData,
  readPurchaseAndDeliveryTaxonomyData,
  readVPPBSTaxonomyData,
  readVPPBSTaxonomyDataWithoutRefreshing,
  saveVPStateAttr,
} from '../../../reducers/visualPlanReducer';
import CustomDatePicker from '../../../shared/customDatePicker/customDatePicker';
// import 'style.css';
import { ViewMode } from '../../../shared/ganttv2';
import ModeSwitcher from '../../../shared/ganttViewSwitcher/viewSwitcher';
import { formatDateTimeToISOString, getSelectedFilterDates } from '../../../shared/util';
import { ViewModeContext } from './component';

export const ViewSwitcher: React.FC = () => {
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [searchTriggered, setSearchTriggered] = React.useState<boolean>(false);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [selectedMode, setSelectedViewMode] = React.useState<ViewMode>(ViewMode.QuaterYear);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { onViewModeChange, onViewListChange, isCheck } =
    useContext(ViewModeContext);

  const standerdFilterOptions = useSelector(
    (state: any) => state.vp.vpFilterDropdown,
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        height: 70,
        width: '100%',
        position: 'sticky',
        top: 0,
        zIndex: 100,
        borderBottom: '0.5px solid gray',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          flexGrow: 0.2,
          width: '100%',
        }}
      >
        <Form
          onSubmit={() => {
          }}
          initialValues={{
            startDate: startDate ? startDate : null,
            endDate: endDate ? endDate : null,
          }}
          mutators={{
            setDateRange: (args, state, utils) => {
              // const from = state.fields['startDate'];
              // const to = state.fields['endDate'];
              // from.change(getSelectedFilterDates(args)[0]);
              // to.change(getSelectedFilterDates(args)[1]);
              dispatch(
                saveVPStateAttr(
                  'scrollPosDate',
                  getSelectedFilterDates(args)[0],
                ),
              );

              dispatch(readPurchaseAndDeliveryTaxonomyData({
                fromDate: formatDateTimeToISOString(
                  getSelectedFilterDates(args)[0],
                ),
                toDate: formatDateTimeToISOString(
                  getSelectedFilterDates(args)[1],
                ),
                filter: args[0],
               
            }));
            },
          }}
          // validate={ validate }
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            // props.submit(handleSubmit);

            return (
              <form onSubmit={handleSubmit} noValidate>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    height: 70,
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                      height: 70,
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        height: 70,
                        width: '100%',
                      }}
                    >
                      

                      <div style={{ width: 180, marginLeft: 15 }}>
                        <Field name="date">
                          {({ input, meta }) => (
                            <Dropdown
                              placeholder={t('standardFilter')}
                              label={t('standardFilter')}
                              selectedKey={values.date ? values.date : '5'}
                              // eslint-disable-next-line react/jsx-no-bind
                              onChange={(event, value) => {
                                let date = null;
                                if (value) {
                                  date = value.key;
                                  input.onChange(value.key);
                                } else {
                                  date = null;
                                  input.onChange(null);
                                }
                                form.mutators.setDateRange(value?.key);
                                if (value?.key === '5'){
                                  onViewModeChange(ViewMode.QuaterYear)
                                  // setIsProjectSeleted(true)
                                  setSelectedViewMode(ViewMode.QuaterYear)
                                } else {
                                  onViewModeChange(ViewMode.Week)
                                  // setIsProjectSeleted(false)
                                  setSelectedViewMode(ViewMode.Week)
                                }
                              }}
                              options={
                                standerdFilterOptions
                                  ? standerdFilterOptions
                                  : []
                              }
                            />
                          )}
                        </Field>
                      </div>

                      {/* <div
                        className={'vpDatePicker180'}
                        style={{ width: 180, marginLeft: 15 }}
                      >
                        <Label style={{ width: 180 }}>{t('fromDate')}:</Label>
                        <Field name="startDate">
                          {({ input, meta }) => (
                            <CustomDatePicker
                              setValue={values.startDate}
                              getValue={(date: Date) => {
                                input.onChange(date);
                                setStartDate(date);
                                input.onChange(date);
                                const clDate = new Date(date);
                                const newDate = new Date(
                                  clDate.setDate(clDate.getDate() + 30),
                                );
                                setEndDate(newDate);

                                dispatch(
                                  readVPPBSTaxonomyData({
                                    fromDate: formatDateTimeToISOString(date),
                                    toDate: formatDateTimeToISOString(newDate),
                                  }),
                                );
                              }}
                            />
                          )}
                        </Field>
                      </div> */}
                      {/* <div
                        className={'vpDatePicker180'}
                        style={{ width: 180, marginLeft: 15 }}
                      >
                        <Label style={{ width: 180 }}>{t('toDate')}:</Label>
                        <Field name="endDate">
                          {({ input, meta }) => (
                            <CustomDatePicker
                              setMinDate={
                                startDate
                                  ? startDate
                                  : new Date(
                                  form.getFieldState('startDate')?.value,
                                  )
                              }
                              setValue={values.endDate}
                              getValue={(date: Date) => {
                                input.onChange(date);
                                setEndDate(date);
                                dispatch(
                                  readVPPBSTaxonomyData({
                                    fromDate: formatDateTimeToISOString(
                                      startDate!!,
                                    ),
                                    toDate: formatDateTimeToISOString(date),
                                  }),
                                );
                              }}
                            />
                          )}
                        </Field>
                      </div> */}

                      <ModeSwitcher
                        onViewModeChange={(t:any)=>{
                          onViewModeChange(t)
                          setSelectedViewMode(t)
                        }}
                        onViewListChange={onViewListChange}
                        isCheck={isCheck}
                        selectedViewMode={selectedMode}
                      />
                    </div>
                  </div>
                </div>

                {/* <pre>{ JSON.stringify(values, 0, 2) }</pre> */}
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};

export enum CommentType {
    PMOL = 'PMOL',
    PERSON = 'PERSON',
};

export interface MapRouteData {
    locations: any,
    color: string,
};

export interface PersonComment {
    id: string,
    commentCardId: string,
    comment: string,
    commentBy: string,
    commentTo: string,
    commentByName: string,
    commentToName: string,
    date: Date,
    isAccept: string,
}

export const personCommentData = [{
    id: "",
    commentCardId: "",
    comment: "",
    commentBy: "",
    commentTo: "",
    commentByName: "",
    commentToName: "",
    date: new Date(),
    isAccept: "0",
}];

export const pmolKey = "pmolCommentKey123";
export const personKey = "personCommentKey123";
import { useTranslation } from 'react-i18next';
import {
  ContextualMenu,
  FontWeights,
  getTheme,
  IconButton,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { uPrinceTheme } from '../../../../../theme';
import { Quality } from '../../../../types/projectBreakdownStructure';
import TextEditor from '../../../../shared/textEditor/textEditor';

const theme = getTheme();
const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const classNames = mergeStyleSets({
  wrapper: {
    //height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: {
    maxWidth: 400,
  },
  textContent: {
    padding: '15px 10px',
  },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: {
    width: '100%',
  },
});

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };

const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '40vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};


class QualityValidationMessages {
  criteriaValidationMsg: string = '';
}

const QualityModal = (props: {
  isOpen: boolean;
  openQualityModal: () => void;
  isEdit: boolean;
  createQuality: (qualityk: Quality) => void;
  quality: Quality;
}) => {

  const { t } = useTranslation();
  const [isDraggable] = useBoolean(false);
  const titleId = useId('title');
  const ref: any = useRef();
  const [quality, setQuality] = useState(new Quality());
  const [qualityValidationMessages, setQualityValidationMsg] = useState<QualityValidationMessages>(new QualityValidationMessages());

  // const onChangeInput = (field: string) => (
  //   event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  //   newValue?: string
  // ) => {
  //   setQuality((prevState) => ({
  //     ...prevState,
  //     [field]: newValue,
  //   }));
  //   setQualityValidationMsg(new QualityValidationMessages())
  // };

  // const validaErrorOnvalueChange = (key: string) => {
  //   switch (key) {
  //     case 'criteria':
  //       validateQualityCriteria(quality);
  //       break;
  //   }
  // };

  useEffect(() => {
    if (props.isEdit) {
      setQuality(props.quality);
    } else {
      setQuality(new Quality());
      setQualityValidationMsg(new QualityValidationMessages());
    }
  }, [props.quality]);

  useEffect(() => {
    if (quality && ref.current && ref.current.criteria != quality.criteria) {
      //validaErrorOnvalueChange('criteria');
    }
    ref.current = quality;
  }, [quality]);

  const validateQuality = (risk: Quality) => {
    let isValidName = validateQualityCriteria(risk);
    return isValidName;
  };

  const validateQualityCriteria = (quality: Quality) => {
    let isValidName = false;
    if (quality && quality.criteria) {
      isValidName = true;
      setRiskValidationState('criteriaValidationMsg', '');
    } else {
      isValidName = false;
      setRiskValidationState('criteriaValidationMsg', t('criteriaRequired'));
    }
    return isValidName;
  };

  const setRiskValidationState = (key: string, msg: string) => {
    setQualityValidationMsg((prevState) => ({
      ...prevState,
      [key]: msg,
    }));
  };

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          props.openQualityModal();
          setTimeout(() => {
            setQualityValidationMsg(new QualityValidationMessages());
          }, 1000);
        }}
        scrollableContentClassName="body"
        isBlocking={false}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>{t('quality')}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              props.openQualityModal();
              setTimeout(() => {
                setQualityValidationMsg(new QualityValidationMessages());
              }, 1000);
            }}
          />
        </div>
        {/* <div className={contentStyles.subHeader}>
          <ActionButton iconProps={addIcon} allowDisabledFocus>
            <Label className={contentStyles.actionButtonLabel}>
              {!props.isEdit ? t('add') : t('save')}
            </Label>
          </ActionButton>
        </div> */}
        <div className={contentStyles.body}>
          <div className="proj-detail-content inner">
            <div className="ms-Grid-row">
              <Label>1. {t('qualityDetails')} </Label>
              {props.isEdit && (
                <Stack
                  horizontal
                  wrap
                  styles={stackStyles}
                  tokens={stackTokens}
                >
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        label={t('qualityID')}
                        readOnly={true}
                        value={
                          quality && quality.sequenceCode
                            ? quality.sequenceCode
                            : ''
                        }
                      />
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        label={t('qualityTitle')}
                        value={quality && quality.title ? quality.title : ''}
                        errorMessage={''}
                        readOnly={true}
                      />
                    </div>
                  </Stack.Item>
                </Stack>
              )}

              <Label style={{ marginLeft: 10, marginRight: 10 }}>
                {t('qualityCriteria')}
              </Label>
              <div style={{ marginLeft: 10, marginRight: 10 }}>

                <TextEditor
                  value={quality && quality.criteria ? quality.criteria : ''}
                  onChange={(evt: any) => {
                    setQuality((prevState) => ({
                      ...prevState,
                      criteria: evt,
                    }));
                    setQualityValidationMsg(new QualityValidationMessages());
                  }}
                />
              </div>

              <Label style={{ marginLeft: 10, marginRight: 10 }}>
                {t('qualityTolerance')}
              </Label>
              <div style={{ marginLeft: 10, marginRight: 10 }}>

                <TextEditor
                  value={
                    quality && quality.tolerance ? quality.tolerance : ''
                  }
                  onChange={(evt: any) => {
                    setQuality((prevState) => ({
                      ...prevState,
                      tolerance: evt,
                    }));

                  }}
                />
              </div>

              <Label style={{ marginLeft: 10, marginRight: 10 }}>
                {t('qualityMethod')}
              </Label>
              <div style={{ marginLeft: 10, marginRight: 10 }}>

                <TextEditor
                  value={quality && quality.method ? quality.method : ''}
                  onChange={(evt: any) => {
                    setQuality((prevState) => ({
                      ...prevState,
                      method: evt,
                    }));

                  }}
                />
              </div>
              <Label style={{ marginLeft: 10, marginRight: 10 }}>
                {t('qualitySkillRequired')}
              </Label>
              <div style={{ marginLeft: 10, marginRight: 10 }}>

                <TextEditor
                  value={quality && quality.skills ? quality.skills : ''}
                  onChange={(evt: any) => {
                    setQuality((prevState) => ({
                      ...prevState,
                      skills: evt,
                    }));

                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={addIconWhite}
            text={t('save')}
            style={{ marginTop: 20, marginBottom: 30 }}
            onClick={() => {
              if (validateQuality(quality)) {
                props.createQuality(quality);
                props.openQualityModal();
              }
            }}
          />
          {!props.isEdit && <PrimaryButton
            iconProps={addIconWhite}
            text={t('saveandNew')}
            style={{ marginTop: 20, marginBottom: 10, marginLeft: 10 }}
            onClick={() => {
              if (validateQuality(quality)) {
                props.createQuality(quality);
                setQuality(new Quality());
              }
            }}
            //disabled={disableSave}
          />}
        </div>
      </Modal>
    </div>
  );
};

export default QualityModal;

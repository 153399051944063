import {
  ActionButton,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Separator,
  Stack,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { uPrinceTheme } from '../../../../theme';
import { CKEditorWrapper, DropDownAdaptater, TextFieldFF } from '../../../shared/fluentFinalForm';
import { RiskRegister, RiskRegisterDropDowns } from '../../../types/riskRegister';
import HistoryBar from '../../../shared/historyBar/historyBar';
import { useTranslation } from 'react-i18next';
// import{
//     createRisk,deleteRisk
// }from '../../../reducers/projectBreakdownReducer';
import PeoplePicker from '../../../shared/fluentFinalForm/PeoplePicker';

{/* for saving risk data */
}

const theme = getTheme();

const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };

const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
});

// const required = (value: any) => (value ? undefined : 'Required');

// const validate = (values: { company: any; gender: any; email: any; }) => {
//     const errors: { company: string, gender: string, email: string } = { company: '', gender: '', email: '' };
//     if (!values.company) {
//         errors.company = 'Company Required';
//     }
//     if (!values.gender) {
//         errors.gender = 'Gender Required';
//     }
//     if (!values.email) {
//         errors.email = 'Email Required';
//     }
//     return errors;
// };

const validate = (values: any) => {

  const errors: any = {};
  if (!values.name) {
    errors.name = 'Name Required';
  }
  return errors;
};

const DocumentPaneComponent = (props: {
  submit: any,
  dropDowns: RiskRegisterDropDowns,
  //saveFormData: any,
  createRisk: any, /* for saving risk data */
  uid: any,
  showProgress: boolean,
  formValues: any,
  formData: any,
  isExpand: boolean,
  setForm: (form: any) => void;
  isClear: boolean
  //hasChangeIntialFromData: boolean
}) => {
  const { t } = useTranslation();

  const [state, setstate] = useState<RiskRegister | null>(null);
  const onSubmit = async (values: any) => {
    // const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
    // await sleep(300);
    // // window.alert(JSON.stringify(values.header, 0, 2));
    // values.header.projectSequenceCode = getProject();
    // if (!values.header.id) {
    //     values.header.id = props.uid;
    // }
    // values.IsDeleted = false;
    props.createRisk(values);
    {/* for saving risk data */
    }
  };

  useEffect(() => {
    setstate(props.formData);
  }, [props.formData]);

  //take data to document pane
  const getInitialFormValues = (formData: any, formValue: any) => {
    if (location.pathname.split('/').pop() === 'new' && props.isClear) {
      return {
        // requiredDateAndTime: new Date(),
        // executedDateAndTime: new Date(),
        // isFinished: false
      };
    } else if (formData && formData?.id) {
      return {
        id: formData?.id,
        sequenceCode: formData?.sequenceCode,
        title: formData?.title,
        name: formData?.name,
        typeId: formData?.type?.key,
        resourceType: formData?.cpcType?.key,
        requesterId: formData?.requesterId,
        requester: formData?.requester,
        executorId: formData?.executorId,
        executor: formData?.executor,
        requiredDateAndTime: new Date(formData?.requestedDateAndTime),
        executedDateAndTime: formData?.executedDateAndTime ? new Date(formData?.executedDateAndTime) : null,
        effortEstimate: formData?.effortEstimate,
        effortCompleted: formData?.effortCompleted,
        riskStatusId: formData?.riskStatus?.id,
        riskTypeId: formData?.riskType?.id,
        cabPersonId: formData?.personId,
        cabPersonName: formData?.person?.fullName,
        riskDetails: formData?.riskDetails,
        files: formData?.files,
        task: formData?.task,
        isFinished: formData?.isFinish,
        destination: formData?.destination,
        source: formData?.source,
        comment: formData?.comment,
        person: formData?.person,
      };
    }
    return formValue;
  };

  return (
    <div style={{ width: '100%' }}>
      <div className="proj-detail-block">
        <Form
          onSubmit={onSubmit}
          initialValues={getInitialFormValues(props.formData, props.formValues)}
          mutators={{
            setHeaderDocs: (args, state, utils) => {
              const field = state.fields['files'];
              {/* for saving risk data */
              }
              field.change(args[0]);
            },
            setCabPersonName: (args, state, utils) => {
              const field = state.fields['cabPersonName'];
              {/* for saving risk data */
              }
              field.change(args[0]);
            },
          }}
          validate={validate}
          render={({
                     handleSubmit,
                     form,
                     submitting,
                     pristine,
                     values,
                   }) => {
            props.submit(handleSubmit);
            props.setForm(form);

            let headerFiles = values.header?.files;
            return (
              <form onSubmit={handleSubmit} noValidate>
                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="header">
                      <Label>1. {t('riskRegisterHeader')}</Label>
                    </Link>
                  </div>
                  {state?.sequenceCode && (
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="title"
                            component={TextFieldFF}
                            lable={t('riskTitle')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="sequenceCode" //for saving risk data  header. removed from all name variables
                            component={TextFieldFF}
                            lable={t('riskID')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>
                  )}

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="name"
                          component={TextFieldFF}
                          lable={t('name')}
                          required={true}
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="riskStatusId"
                          component={DropDownAdaptater}
                          options={props.dropDowns.riskStatus}
                          lable={t('riskStatus')}
                          placeholder=""
                          disabled={false}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="riskTypeId"
                          component={DropDownAdaptater}
                          options={props.dropDowns.riskTypes}
                          lable={t('riskType')}
                          placeholder=""
                          disabled={false}
                        />
                      </div>
                    </Stack.Item>

                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        {/* {JSON.stringify(values?.cabPersonId)} */}
                        <Field name="cabPersonId">
                          {({ input, meta }) => (
                            <PeoplePicker
                              //name="producer"
                              label={t('riskOwner')}
                              isDisabled={false}
                              selectedItem={values?.personId ?
                                [
                                  {
                                    key: values?.personId,
                                    text: values?.person?.fullName,
                                  },
                                ]
                                : values?.cabPersonId ?
                                  [
                                    {
                                      key: values?.cabPersonId,
                                      text: values?.cabPersonName,
                                    },
                                  ]
                                  : []
                              }
                              onChange={(items: any) => {
                                // console.log('bbb', items);
                                form.resetFieldState('cabPersonId');
                                input.onChange('');
                                return items;
                              }}
                              onPersonSelected={(item: any) => {
                                // console.log('bbb2', item);
                                input.onChange(item.cabPersonId);
                                form.mutators.setCabPersonName(
                                  item.text,
                                );
                                return item;
                              }}
                            />
                          )}
                        </Field>
                        <Field
                          name="cabPersonName"
                          component={'input'}
                          type={'text'}
                          disabled={true}
                          hidden
                        />
                      </div>
                    </Stack.Item>
                    {/* <Stack.Item grow={6} styles={stackItemStyles}>
                                            <div className={`${classNames.fullWidth}`}>
                                                <Field
                                                    name="riskCategory"
                                                    component={DropDownAdaptater}
                                                    options={props.dropDowns.dropDownItem}
                                                    lable="Risk Category"
                                                    placeholder=""
                                                    disabled={false}
                                                />
                                            </div>
                                        </Stack.Item> */}
                  </Stack>

                  {/* <Stack
                                        horizontal
                                        wrap
                                        styles={stackStyles}
                                        tokens={stackTokens}
                                    >
                                        <Stack.Item grow={6} styles={stackItemStyles}>
                                            <div className={`${classNames.fullWidth}`}>
                                                <Field
                                                    name="proximity"
                                                    component={DropDownAdaptater}
                                                    options={props.dropDowns.dropDownItem}
                                                    lable="Proximity"
                                                    placeholder=""
                                                    disabled={false}
                                                />
                                            </div>
                                        </Stack.Item>
                                        <Stack.Item grow={6} styles={stackItemStyles}>
                                            <div className={`${classNames.fullWidth}`}>
                                                <Field
                                                    name="author"
                                                    component={DropDownAdaptater}
                                                    options={props.dropDowns.dropDownItem}
                                                    lable="Author"
                                                    placeholder=""
                                                    disabled={false}
                                                />
                                            </div>
                                        </Stack.Item>
                                    </Stack> */}

                  {/* <Stack
                                        horizontal
                                        wrap
                                        styles={stackStyles}
                                        tokens={stackTokens}
                                    >

                                        <Stack.Item grow={6} styles={stackItemStyles}>
                                            <div className={`${classNames.fullWidth}`}>
                                                <Field
                                                    name="version"
                                                    component={TextFieldFF}
                                                    lable="Version"
                                                />
                                            </div>
                                        </Stack.Item>
                                    </Stack> */}

                  {/* <Stack
                                horizontal
                                wrap
                                styles={stackStyles}
                                tokens={stackTokens}
                              >
                                <Stack.Item grow={6} styles={stackItemStyles}>
                                            <div className={`${classNames.fullWidth}`}>
                                                <Field
                                                    name="workflowStatus"
                                                    component={DropDownAdaptater}
                                                    options={props.dropDowns.dropDownItem}
                                                    lable="Workflow Status"
                                                    placeholder=""
                                                    disabled={false}
                                                />
                                            </div>
                                        </Stack.Item>
                                        <Stack.Item grow={6} styles={stackItemStyles}>
                                            <div className={`${classNames.fullWidth}`}>
                                                <Field
                                                    name="date"
                                                    component={DropDownAdaptater}
                                                    options={props.dropDowns.dropDownItem}
                                                    lable="Date"
                                                    placeholder=""
                                                    disabled={false}
                                                />
                                            </div>
                                        </Stack.Item>
                              </Stack> */}

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <div className={`${classNames.fullWidth}`}>
                      <Label>{t('riskDetails')}</Label>

                      <Field
                        name="riskDetails"
                        component={CKEditorWrapper}
                        lable={t('skills')}
                        placeholder="Enter Risk Details."
                        disabled={false}
                      />
                    </div>
                  </Stack>

                  <div className={'card-footer'}>
                    <Separator/>
                    <Stack
                      styles={stackStyles}
                      tokens={itemAlignmentsStackTokens}
                    >
                      <Stack.Item
                        align="end"
                        styles={stackFooterBarItemStyles}
                      >
                        <ActionButton
                          className={classNames.actionButton}
                          iconProps={saveIcon}
                          allowDisabledFocus
                          onClick={() => {
                            // alert(JSON.stringify(values.purchaseOrderType, 0, 2));
                            form.submit();
                          }}
                          // disabled={ (props.dropDownOptions.isApproved) }
                        >
                          <Label
                            className={classNames.actionButton}
                          >
                            {t('save')}
                          </Label>
                        </ActionButton>
                      </Stack.Item>
                    </Stack>
                  </div>
                </div>

                {props.formData?.riskHistoryLog &&
                props.formData?.riskHistoryLog.createdDateTime && (
                  <div className="proj-detail-block" id="3">
                    <div className="document-pane-card">
                      <div className="marginTop marginBottom">
                        <Link href="#" id="history">
                          <Label>2. {t('history')} </Label>
                        </Link>

                        <HistoryBar
                          createdByUser={
                            props.formData.riskHistoryLog.createdByUser
                              ? props.formData.riskHistoryLog.createdByUser
                              : ''
                          }
                          updatedByUser={
                            props.formData.riskHistoryLog.updatedByUser
                              ? props.formData.riskHistoryLog.updatedByUser
                              : ''
                          }
                          createdDateTime={
                            props.formData.riskHistoryLog.createdDateTime
                              ? props.formData.riskHistoryLog.createdDateTime
                              : ''
                          }
                          updatedDateTime={
                            props.formData.riskHistoryLog.updatedDateTime
                              ? props.formData.riskHistoryLog
                                .updatedDateTime
                              : ''
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* <pre>{JSON.stringify(props.formData, 0, 2)}</pre> */}
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};
export default DocumentPaneComponent;


import React from 'react';
import { AccessLevel } from '../../../types/permissions';
import {
  ApplicationMenuStratergy,
  CUMenuStratergy,
  OrganizationMenuStratergy,
  PMOLMenuStratergy,
  UnderConstructMenuStratergy,
} from './menuStratergy';

const MenuPane = (props: { stratergy: string, accessLevel: number, currentUser: any, organization: string }) => {
  const renderSwitch = (stratergy: string) => {
    switch (stratergy) {
      case 'PMOL':
        switch (props.accessLevel) {
          case AccessLevel.ORGANIZATION:
            return new OrganizationMenuStratergy(props.currentUser, props.organization).renderMenu();
          case AccessLevel.CONTRACTING_UNIT:
            return new CUMenuStratergy(props.currentUser, props.organization).renderMenu();
          case AccessLevel.PROJECT:
            return new PMOLMenuStratergy(props.currentUser, props.organization).renderMenu();
          default:
            return new UnderConstructMenuStratergy().renderMenu();
        }

      case 'two':
        return new UnderConstructMenuStratergy().renderMenu();
      case 'three':
        return new UnderConstructMenuStratergy().renderMenu();
      case 'APP':
        return new ApplicationMenuStratergy(props.currentUser, props.organization).renderMenu();
      default:
        return new UnderConstructMenuStratergy().renderMenu();
    }
  };

  return (
    <div>
      {renderSwitch(props.stratergy)}
    </div>
  );
};


export default MenuPane;

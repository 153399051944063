import { IColumn, Selection } from '@fluentui/react/lib/DetailsList';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFilterQRCodes, getQRDropDownsData } from '../../../reducers/qrCodeReducer';
import { saveStateAttr } from '../../../reducers/uprinceReducer';
import { getContractingUnit } from '../../../shared/util';
import { QRCode } from '../../../types/qrCode';
import history from './../../../history';
import QRCodeListComponent from './component';
import i18n from '../../../../i18n';
import _ from 'lodash';

export interface State {
  dialogHidden: boolean;
  clickItem: any;
  announcedMessage?: string;
  fieldName: string | null;
  sortby: string | null;
  type: number | string | null;
  project: number | string | null;
  vehicleNo: string | null;
  location: string | null;
  resetFilter: boolean;
  date: string | null;
  errorMsg: string;
  isDataLoaded: boolean;
  columns: IColumn[];
  width: number;
  refresh: boolean;
}

interface Props {
  history?: any;
  match?: any;
  onQRCodeClick: any;
  location?: any;
  selectedQRCodeType: number;
  setCodeCount: (count: number) => void;
  getAllProjects?: any;
  needRefresh: boolean;
  qrDetailsEnterd: boolean;
  onChangeQRInputs: any;
  getFilterQRCodes?: any;
  projects?: any;
  qrCodes?: QRCode[];
  isLoading?: boolean;
  status?: number;
  message?: string;
  getQRDropDownsData?: any;
}

class QRCodeList extends Component<Props, State> {
  private _selection: Selection;
  private _columns: IColumn[];
  _isMounted = false;

  constructor(props: Props) {
    super(props);
    this._selection = new Selection();
    //const { t } = this.props;
    this._columns = [
      {
        key: 'column1',
        name: i18n.t('activityType'),
        fieldName: 'type',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        isRowHeader: true,
        data: 'string',
        isSortedDescending: false,
        isPadded: true,
        isSorted: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column2',
        name: i18n.t('project'),
        fieldName: 'projectId',
        minWidth: 150,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column3',
        name: i18n.t('vehicleNumber'),
        fieldName: 'vehicleNo',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        isRowHeader: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column4',
        name: i18n.t('location'),
        fieldName: 'location',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        isRowHeader: true,
        onColumnClick: this._onColumnClick,
      },
    ];
    this.state = {
      dialogHidden: true,
      clickItem: null,
      fieldName: null,
      sortby: null,
      type: null,
      project: null,
      vehicleNo: null,
      location: null,
      resetFilter: false,
      date: null,
      errorMsg: '',
      isDataLoaded: false,
      width: 1000,
      refresh: false,
      columns: this.getColumns(-1),
    };
  }

  componentDidMount() {
    this.props.getQRDropDownsData();
  }

  componentWillMount() {
    this._isMounted = true;

    this.props.getFilterQRCodes();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.needRefresh != this.props.needRefresh ||
      (nextProps.selectedQRCodeType != -1 &&
        nextProps.selectedQRCodeType != this.props.selectedQRCodeType)
    ) {
      this.setState({
        type: nextProps.selectedQRCodeType,
        refresh: false,
        project: null,
        vehicleNo: null,
        location: null,
        resetFilter: true,
        columns: this.getColumns(nextProps.selectedQRCodeType),
      });
      this.props.getFilterQRCodes(
        nextProps.selectedQRCodeType,
        null,
        null,
        null,
        null,
        null,
      );
    } else if (
      nextProps.selectedQRCodeType == -1 &&
      nextProps.selectedQRCodeType != this.props.selectedQRCodeType
    ) {
      this.setState({
        type: nextProps.selectedQRCodeType,
        project: null,
        vehicleNo: null,
        location: null,
        resetFilter: true,
        columns: this.getColumns(nextProps.selectedQRCodeType),
      });
      this.props.getFilterQRCodes(null, null, null, null, null, null);
    }
  }

  getColumns = (type: any): IColumn[] => {
    //const { t } = this.props;
    switch (type) {
      case 0:
        return [
          {
            key: 'column1',
            name: i18n.t('activityType'),
            fieldName: 'type',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            isRowHeader: true,
            isSorted: true,
            onColumnClick: this._onColumnClick,
          },
          {
            key: 'column2',
            name: i18n.t('project'),
            fieldName: 'projectId',
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            onColumnClick: this._onColumnClick,
          },
          {
            key: 'column3',
            name: i18n.t('vehicleNumber'),
            fieldName: 'vehicleNo',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
            isRowHeader: true,
            onColumnClick: this._onColumnClick,
          },
        ];
      case 1:
      case 6:
        return [
          {
            key: 'column1',
            name: i18n.t('activityType'),
            fieldName: 'type',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            isRowHeader: true,
            data: 'string',
            isSortedDescending: false,
            isPadded: true,
            onColumnClick: this._onColumnClick,
          },
          {
            key: 'column2',
            name: i18n.t('project'),
            fieldName: 'projectId',
            minWidth: 150,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            onColumnClick: this._onColumnClick,
          },
        ];
      case 4:
      case 2:
      case 5:
        return [
          {
            key: 'column1',
            name: i18n.t('activityType'),
            fieldName: 'type',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            isRowHeader: true,
            isSorted: true,
            onColumnClick: this._onColumnClick,
          },
          {
            key: 'column2',
            name: i18n.t('project'),
            fieldName: 'projectId',
            minWidth: 150,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            onColumnClick: this._onColumnClick,
          },
          {
            key: 'column4',
            name: i18n.t('location'),
            fieldName: 'location',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
            isRowHeader: true,
            onColumnClick: this._onColumnClick,
          },
        ];
      case -1:
        return [
          {
            key: 'column1',
            name: i18n.t('activityType'),
            fieldName: 'type',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            isRowHeader: true,
            data: 'string',
            isSortedDescending: false,
            isPadded: true,
            isSorted: true,
            onColumnClick: this._onColumnClick,
          },
          {
            key: 'column2',
            name: i18n.t('project'),
            fieldName: 'projectId',
            minWidth: 80,
            maxWidth: 180,
            isRowHeader: true,
            isResizable: true,
            onColumnClick: this._onColumnClick,
          },
          {
            key: 'column3',
            name: i18n.t('vehicleNumber'),
            fieldName: 'vehicleNo',
            minWidth: 80,
            maxWidth: 180,
            isResizable: true,
            isRowHeader: true,
            onColumnClick: this._onColumnClick,
          },
          {
            key: 'column4',
            name: i18n.t('location'),
            fieldName: 'location',
            minWidth: 80,
            maxWidth: 180,
            isResizable: true,
            isRowHeader: true,
            onColumnClick: this._onColumnClick,
          },
        ];
      default:
        return [];
    }
  };

  onClickConfirmDialog = () => {
    let pathname = '';
    if (getContractingUnit()) {
      pathname = `/CU/${getContractingUnit()}/qr-code`;
    } else {
      pathname = '/qr-code';
    }
    this.props.onChangeQRInputs(false);
    this.setState({ dialogHidden: true });
    this.props.onQRCodeClick(this.state.clickItem);
    history.push(`${pathname}/${this.state.clickItem.id}`);
  };

  onClickCancelDialog = () => {
    this.setState({ dialogHidden: true });
  };

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn,
  ): void => {
    const newColumns: IColumn[] = this.state.columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key,
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        this.setState({
          announcedMessage: `${currColumn.name} is sorted ${
            currColumn.isSortedDescending ? 'descending' : 'ascending'
          }`,
        });
        let fieldName = currColumn.fieldName ? currColumn.fieldName : null;
        let sortby = currColumn.isSortedDescending ? 'desc' : 'asc';
        this.props.getFilterQRCodes(
          this.state.type,
          this.state.project,
          this.state.vehicleNo,
          this.state.location,
          fieldName,
          sortby,
        );
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
  };

  onChangeType = (value: number | string | null) => {
    this.setState({ type: value, columns: this.getColumns(value) });
    this.props.getFilterQRCodes(
      value,
      this.state.project,
      this.state.vehicleNo,
      this.state.location,
      null,
      null,
    );
  };

  onChangeProject = (value: number | string | null) => {
    this.setState({ project: value });
    this.props.getFilterQRCodes(
      this.state.type,
      value,
      this.state.vehicleNo,
      this.state.location,
      null,
      null,
    );
  };

  onChangeLocation = (value: string | null) => {
    this.setState({ location: value });
    if ((value && value.length >= 3) || value === '') {
      this.props.getFilterQRCodes(
        this.state.type,
        this.state.project,
        this.state.vehicleNo,
        this.state.location,
        null,
        null,
      );
    }
  };

  onChangeVehicleNo = (value: string | null) => {
    this.setState({ vehicleNo: value });
    if ((value && value.length >= 3) || value === '') {
      this.setState({ vehicleNo: value });
      this.props.getFilterQRCodes(
        this.state.type,
        this.state.project,
        value,
        this.state.location,
        null,
        null,
      );
    }
  };

  private onQRItemClick = (item: any) => {
    this.setState({ clickItem: item });
    let pathname = '';
    if (getContractingUnit()) {
      pathname = `/CU/${getContractingUnit()}/qr-code`;
    } else {
      pathname = '/qr-code';
    }
    if (!this.props.qrDetailsEnterd) {
      this.props.onQRCodeClick(item);
      history.push(`${pathname}/${item.id}`);
    } else {
      this.setState({ dialogHidden: false });
    }
  };

  getProjects = () => {
    let projects = [];
    projects = this.props.projects;
    if (projects) {
      let isEx: boolean = false;
      if (_.isArray(projects) && projects.filter((e: { key: string }) => e.key === '-1').length > 0) {
        isEx = true;
      }
      if (!isEx && _.isArray(projects)) {
        projects.unshift({ key: '-1', text: i18n.t('all') });
      }
    }
    return projects;
  };

  render() {
    const projects = this.getProjects();
    //const { t } = this.props;
    return (
      <QRCodeListComponent
        qrCodes={this.props.qrCodes && _.isArray(this.props.qrCodes) ? this.props.qrCodes : []}
        columns={this.state.columns}
        selection={this._selection}
        isLoading={this.props.isLoading}
        dialogHidden={this.state.dialogHidden}
        onClickConfirmDialog={this.onClickConfirmDialog}
        onClickCancelDialog={this.onClickCancelDialog}
        status={this.props.status}
        message={this.props.message}
        type={this.state.type}
        onChangeType={this.onChangeType}
        projects={projects}
        project={this.state.project}
        onChangeProject={this.onChangeProject}
        vehicleNo={this.state.vehicleNo}
        onChangeVehicleNo={this.onChangeVehicleNo}
        location={this.state.location}
        onChangeLocation={this.onChangeLocation}
        t={i18n.t}
        onQRItemClick={this.onQRItemClick}
      />
    );
  }
}

const mapStateToProps = (state: any, props: Props) => {
  return {
    projects: state.qrCode.dropDownsData,
    qrCodes: state.qrCode.qrCodes,
    status: state.qrCode.status,
    message: state.qrCode.message,
    isLoading: state.qrCode.isLoading,
    //allProjects: state.qrCode.dropDownsData,
  };
};

const mapDispatchToProps = {
  saveStateAttr,
  getFilterQRCodes,
  getQRDropDownsData,
};

export default (
  connect(mapStateToProps, mapDispatchToProps)(QRCodeList)
);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import QRCodeListComponent from './component';
import { getQRCodeByID } from '../../../reducers/qrCodeReducer';
import { QRCode } from '../../../types/qrCode';
import { QRCodeShimmer } from './shimmer';
import { messageService } from '../../../services/messageService';
import moment from 'moment';
import i18n from '../../../../i18n';
import client from '../../../api';
import { IChoiceGroupOption, IDropdownOption } from '@fluentui/react';

export interface State {
  divClass: string;
  currentActiveSection: any;
  isLoading: boolean;
  qrCode: QRCode | null;
  resetValue: boolean;
  vehicleNo: { value: string | number | null, label: string | number | null };
  vehicalNoErrorMsg: string;
  roleValidationMsg: string;
  role: string | null;
  location: string | undefined | null;
  locationErrorMsg: string;
  selectedCodeType: any;
  project: string | number | null;
  projectErrorMsg: string;
  selectedProject: { value: string | number | null, label: string | number | null }
}

interface Props {
  selectedQRCode?: any;
  id: string;
  getQRCodeByID?: any;
  isLoading?: boolean;
  qrCode: QRCode | null;
  onQRCodeGenerated: any
}

class QRCodeDetailsContainer extends Component<Props, State> {
  subscription: any;

  constructor(props: any) {
    super(props);
    this.state = {
      resetValue: false,
      divClass: 'bookmark-panel parent',
      currentActiveSection: 1,
      isLoading: false,
      qrCode: null,
      vehicleNo: { value: null, label: null },
      vehicalNoErrorMsg: '',
      roleValidationMsg: '',
      role: null,
      location: null,
      locationErrorMsg: '',
      selectedCodeType: -1,
      project: '',
      projectErrorMsg: '',
      selectedProject: { value: null, label: null },
    };
  }

  private toggleBookmark = (): void => {
    this.setState({ divClass: 'bookmark-panel parent panel-on' });
  };

  private toggleBookmarkOut = (): void => {
    this.setState({ divClass: 'bookmark-panel parent' });
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.isLoading != this.props.isLoading) {
      this.setState({ isLoading: this.props.isLoading!! });
    }

    if (prevProps.qrCode != this.props.qrCode && this.props.qrCode) {
      this.setState({
        qrCode: this.props.qrCode,
        vehicleNo: { value: this.props.qrCode.vehicleCpcId, label: this.props.qrCode.vehicleNo!! },
        selectedCodeType: this.props.qrCode.type,
        project: this.props.qrCode.projectDefinition ? this.props.qrCode.projectDefinition.id : null,
        location: this.props.qrCode.location,
        role: this.props.qrCode.travellerType,
        vehicalNoErrorMsg: '',
        selectedProject: {
          value: this.props.qrCode && this.props.qrCode.projectDefinition ? this.props.qrCode.projectDefinition.id : null,
          label: this.props.qrCode && this.props.qrCode.projectDefinition ? this.props.qrCode.projectDefinition.name : null,
        },
      });
      messageService.sendMessage({ qrCode: this.props.qrCode });
    }
    if (prevProps.id != this.props.id) {
      this.props.getQRCodeByID(this.props.id);
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  componentDidMount() {
    if (this.props.id) {
      this.props.getQRCodeByID(this.props.id);
    }

    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data && data.data && data.data.isUpdateQR) {
          // this.setState({ needRefresh: false})
          this.createQRCode();
        } else if (data && data.data && data.data.isUpdateQR === false) {
          //this.setState({ needRefresh: true})
        }
      }
    });
  }

  renderShimmer() {
    return (
      <div>
        <QRCodeShimmer/>
      </div>
    );
  }

  setLocation = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    this.setState({ locationErrorMsg: '', location: newValue });
  };

  onProjectChange = (
    event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption,
  ) => {
    this.setState({ project: item.key, selectedProject: { value: item.key, label: item.text } });
  };

  _onChange = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    option?: IChoiceGroupOption | undefined,
  ): void => {
    this.setState({ roleValidationMsg: '', role: option?.key!! });
  };

  setVehicalNo = (
    event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption,
  ) => {
    this.setState({ vehicleNo: { value: item.key, label: item.text }, vehicalNoErrorMsg: '' });
  };


  validate = (): boolean => {
    let projectValid = false;
    let locationValid = false;
    let vehicaleNoValid = false;
    // if (!this.state.project) {
    //   this.setState({
    //     projectErrorMsg: t('Please Select project'),
    //     vehicalNoErrorMsg: '',
    //   });
    //   projectValid = false;
    // } else {
    //   this.setState({
    //     projectErrorMsg: '',
    //   });
    //   projectValid = true;
    // }
    if (this.state.qrCode?.type === 0 && !this.state.vehicleNo.value) {
      this.setState({ vehicalNoErrorMsg: i18n.t('pleaseentervehiclenumber') });
      if (this.state.qrCode?.type === 0 && !this.state.vehicleNo.value && !this.state.role) {
        this.setState({
          vehicalNoErrorMsg: i18n.t('pleaseentervehiclenumber'),
          roleValidationMsg: i18n.t('pleaseSelectRole'),
        });
      }
      vehicaleNoValid = false;
    } else if (this.state.qrCode?.type === 0 && !this.state.role) {
      this.setState({ roleValidationMsg: i18n.t('pleaseSelectRole') });
      vehicaleNoValid = false;
    } else {
      this.setState({ vehicalNoErrorMsg: '' });
      vehicaleNoValid = true;
      locationValid = true;
    }
    if (
      (this.state.qrCode?.type === 2 ||
        this.state.qrCode?.type === 5 ||
        this.state.qrCode?.type === 4) &&
      !this.state.location
    ) {
      this.setState({ locationErrorMsg: i18n.t('pleaseenterlocation') });
      locationValid = false;
      //return false;
    } else {
      this.setState({ locationErrorMsg: '' });
      locationValid = true;
    }

    return locationValid && vehicaleNoValid;
  };

  createQRCode = () => {
    if (this.validate()) {
      this.setState({
        isLoading: true,
        // qrCode: {
        //   date: moment().format('YYYY-MM-DD'),
        //   activityType: {id:0, type:getCodeType(this.state.selectedCodeType), typeId:0, localeCode:'', isDeleted:false},
        //   projectDefinition: { title:this.state.projectTitle},
        //   vehicleNo: this.state.vehicleNo,
        //   location: this.state.location,
        //   personalId: null,
        // },
      });
      // this.props.onQRCodeClick({
      //   date: moment().format('YYYY-MM-DD'),
      //   type: getCodeType(this.state.selectedCodeType),
      //   projectId: this.state.projectTitle,
      //   vehicleNo: this.state.vehicleNo,
      //   location: this.state.location,
      //   personalId: null,
      // });
      client
        .post('/QRCode/Create', {
          id: this.state.qrCode?.id,
          date: moment().format('YYYY-MM-DD hh:mm a'),
          type: this.state.selectedCodeType,
          projectId: this.state.project,
          vehicleNo: this.state.vehicleNo.value,
          location: this.state.location,
          personalId: null,
          travellerType: this.state.role,
        })
        .then(
          (response: any) => {
            if (response.status === 200) {
              this.setState({
                isLoading: false,
              });
              this.props.onQRCodeGenerated(this.state.selectedCodeType);
              // this.props.onChangeQRInputs(false);
              // messageService.sendMessage({qrCode:this.state.qrCode})
            }
          },

          (error: any) => {
            //this.props.onChangeQRInputs(false);
            this.setState({ isLoading: false });
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              //alert(error.response.data.message);
            }
          },
        );
    }
  };


  render() {
    if (this.state.isLoading) {
      return <div style={{ width: '100%' }}>{this.renderShimmer()}</div>;
    } else {
      return (
        <div>
          <QRCodeListComponent
            selectedQRCode={this.state.qrCode}
            toggleBookmarkOut={this.toggleBookmarkOut}
            toggleBookmark={this.toggleBookmark}
            divClass={this.state.divClass}
            currentActiveSection={this.state.currentActiveSection}
            resetValue={this.state.resetValue}
            setResetValue={(resetValue: boolean) => {
              this.setState({ resetValue });
            }}
            createQRCode={this.createQRCode}
            setLocation={this.setLocation}
            location={this.state.location}
            locationErrorMsg={this.state.locationErrorMsg}
            onProjectChange={this.onProjectChange}
            selectedProject={this.state.selectedProject}
            role={this.state.role}
            onChangeRole={this._onChange}
            vehicalNo={this.state.vehicleNo}
            setVehicalNo={this.setVehicalNo}
            vehicalNoErrorMsg={this.state.vehicalNoErrorMsg}
          />
        </div>
      );
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    isLoading: state.qrCode.isLoadingQRCode,
    qrCode: state.qrCode.qrCode,
    dropDownsData: state.qrCode.dropDownsData,
  };
};

const mapDispatchToProps = {
  getQRCodeByID,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QRCodeDetailsContainer);

import * as React from 'react';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { hiddenContentStyle, mergeStyles } from '@fluentui/react/lib/Styling';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { useTranslation } from 'react-i18next';
import { DialogContent } from 'office-ui-fabric-react';
import './confirmation-dialog.css';

const dialogStyles = { main: { maxWidth: 450 } };
const htmlContentStyles = { marginBottom: 24 };
const dragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const screenReaderOnly = mergeStyles(hiddenContentStyle);

const ConfirmationDialog: React.FunctionComponent<any> = ({
  hidden,
  onClickCancelDialog,
  onClickConfirmDialog,
  title,
  subText,
  htmlContent,
  cancelButtonText,
  confirmButtonText,
}) => {
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
  const labelId: string = useId('dialogLabel');
  const subTextId: string = useId('subTextLabel');

  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
      dragOptions: isDraggable ? dragOptions : undefined,
    }),
    [isDraggable]
  );
  const { t } = useTranslation();
  const dialogContentProps = {
    type: DialogType.normal,
    title: title,
    closeButtonAriaLabel: 'Close',
    subText: subText,
  };

  return (
    <>
      <Dialog
        hidden={hidden}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        {htmlContent && (
          <div
            dangerouslySetInnerHTML={{ __html: htmlContent }}
            style={htmlContentStyles}
            className="dialog-custom-html-content"
          />
        )}
        <DialogFooter>
          <DefaultButton
            onClick={onClickCancelDialog}
            text={cancelButtonText ? cancelButtonText : t('Cancel')}
          />
          <PrimaryButton
            onClick={onClickConfirmDialog}
            text={confirmButtonText ? confirmButtonText : t('OK')}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;

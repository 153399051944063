import React from 'react';
import AsyncSelect from 'react-select/async';
import {uPrinceTheme} from "../../../theme";
import {Text} from "@fluentui/react";
import {customDropdownErrorMessage, DropdownIndicator} from "./customDropdown";

const customDropdownStyles = {
    control: (_: any, { selectProps: { width } }: any) => ({
        ..._,
        width: width,
        height: 32,
        fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
        minHeight: 32,
        alignContent: 'center',
        fontSize: 14,
        fontWeight: 400,
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 1000001 }),
};

interface Props {
    promiseOptions: any
    onChange: (values: any) => void;
    validationMessage?: string | null
    placeHolder?: string | null
    required?: boolean
    disabled?: boolean
    values?: any
}

export default function CustomDropdownWithPlaceholder(props: Props) {

    const handleChange = (selectedOption: any, actionMeta: any) => {
        if (actionMeta.action === 'clear') {
            props.onChange(null);
        } else {
            props.onChange(selectedOption);
        }
    };

    return (
      <div style={{ height: "100%", transition: 'all 0.4s linear' }}>
          <AsyncSelect
            menuPortalTarget={document.body}  // This is important to prevent the menu to be cut by the overflow: hidden of the parent
            menuPosition={'fixed'} // This is important to prevent the menu to be cut by the overflow: hidden of the parent
            cacheOptions
            defaultOptions
            defaultInputValue={props?.values?.label ?? null}
            loadOptions={props.promiseOptions}
            components={{ DropdownIndicator }}
            isClearable={true}
            isDisabled={props.disabled}
            required={props.required}
            placeholder={props.placeHolder}
            onChange={handleChange}
            theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                height: 34,
                minHeight: 34,
                colors: {
                    ...theme.colors,
                    primary25: uPrinceTheme.palette.themeLight,
                    primary: uPrinceTheme.palette.themePrimary,
                    neutral20: props.validationMessage
                      ? 'rgb(164, 38, 44)'
                      : uPrinceTheme.palette.neutralSecondary,
                    neutral30: uPrinceTheme.palette.neutralSecondary,
                },
            })}
            className="reactSelectContainer"
            classNamePrefix="reactSelect"
            styles={customDropdownStyles}
          />

          {props.validationMessage && (
            <Text className={customDropdownErrorMessage}>{props.validationMessage}</Text>
          )}
      </div>
    );
}

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  CorporateProductCatalogue,
  CorporateProductCatalogueListItem,
  ProductCatalogueFilter,
  SelectedCpcItem,
} from '../../../types/corporateProductCatalogue';
import {IColumn, Selection} from '@fluentui/react/lib/DetailsList';
import {CpcListPaneComponent} from './component';
import history from '../../../history';
import i18n from '../../../../i18n';
import {messageService} from '../../../services/messageService';
import {getContractingUnit, getProject} from '../../../shared/util';

export interface State {
  redirect: boolean;
  isChange: boolean;
  loading: boolean;
  cpcFilter: ProductCatalogueFilter;
  announcedMessage: string;
  selectedCpcItemIds: [] | string[];
  productCatalogue: CorporateProductCatalogue;
}

export interface Props {
  cpcListItem: CorporateProductCatalogueListItem[];
  reloadCpcListItem: () => void;
  handelCpcFilter: (cpcFilter: ProductCatalogueFilter) => void;
  isDataLoaded?: boolean;
  isChange?: boolean;
  cpcFilter: ProductCatalogueFilter;
  resourceTypes: [];
  loadMsg: string;
  handleSelectedCpcItem: (selectedCpcItemIds: [] | string[]) => void;
  productCatalogue: CorporateProductCatalogue;
}

export class ProductCatalogueListPane extends Component<Props, State> {
  private _productCatalogueSelection: Selection;
  private _columns: IColumn[];
  subscription: any;

  constructor(props: Props) {
    super(props);
    this._productCatalogueSelection = new Selection({
      onSelectionChanged: () => {
        this.setState(
          {
            selectedCpcItemIds: this._getSelectedCpcIds(),
          },
          this.handleSelectedCpcItem,
        );
      },
    });
    this.state = {
      selectedCpcItemIds: [],
      redirect: false,
      isChange: false,
      loading: false,
      announcedMessage: '',
      cpcFilter: {
        title: null,
        resourceTitle: null,
        resourceTypeId: null,
        resourceFamilyId: null,
        resourceNumber: null,
        status: null,
        sorter: {
          attribute: null,
          order: null,
        },
      },
      productCatalogue: {
        id: null,
        headerTitle: null,
        title: null,
        resourceNumber: null,
        resourceTitle: null,
        resourceTypeId: null,
        resourceFamilyId: null,
        cpcBasicUnitOfMeasureId: null,
        inventoryPrice: null,
        minOrderQuantity: null,
        maxOrderQuantity: null,
        size: null,
        cpcUnitOfSizeMeasureId: null,
        wallThickness: null,
        cpcPressureClassId: null,
        cpcMaterialId: null,
        weight: null,
        status: 0,
        cpcImage: [],
        cpcResourceNickname: [],
        cpcVendor: [],
        cpcHistoryLog: null,
        cpcBrandId: null,
        resourceId: null,
        trackingNo: null,
      },
    };

    this._columns = [
      // {
      //   key: 'column1',
      //   //name: t('CPC Resource number'),
      //   name: i18n.t('CPC Resource number'),
      //   fieldName: 'resourceNumber',
      //   minWidth: 100,
      //   maxWidth: 200,
      //   isResizable: true,
      //   onColumnClick: this._onColumnClick,
      // },
      {
        key: 'column2',
        name: i18n.t('cPCTitle'),
        fieldName: 'title',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column3',
        name: i18n.t('type'),
        fieldName: 'resourceType',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column4',
        name: i18n.t('status'),
        fieldName: 'status',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
    ];
  }

  static getDerivedStateFromProps(nextProps: any) {
    if (nextProps.isChange) {
      nextProps.reloadCpcListItem();
    }
    return {
      loading: false,
      cpcFilter: nextProps.cpcFilter,
      productCatalogue: nextProps?.productCatalogue,
    };
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.resetCpcListSelection) {
          this._productCatalogueSelection.setAllSelected(false);
          //this.resetSelection()
        }
      }
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.isDataLoaded != this.props.isDataLoaded) {
      if (this.props.isDataLoaded) {
        this.resetSelection();
      }
    }

  };

  handleCpcUpdateOpen = (id: string) => {
    if (getContractingUnit() && !getProject()) {
      history.push(`/CU/${getContractingUnit()}/product-catalogue/${id}`);
    } else if (getContractingUnit() && getProject()) {
      history.push(`/CU/${getContractingUnit()}/project/${getProject()}/product-catalogue/${id}`);
    } else {
      history.push('/product-catalogue/' + id);
    }

  };

  handleSelectedCpcItem = () => {
    this.props.handleSelectedCpcItem(this.state.selectedCpcItemIds);
    if (this.state.selectedCpcItemIds) {
      let lastId = this.state.selectedCpcItemIds.pop();
      if (lastId) {
        if (getContractingUnit() && !getProject()) {
          history.push(`/CU/${getContractingUnit()}/product-catalogue/${lastId}`);
        } else if (getContractingUnit() && getProject()) {
          history.push(`/CU/${getContractingUnit()}/project/${getProject()}/product-catalogue/${lastId}`);
        } else {
          history.push('/product-catalogue/' + lastId);
        }

      }
    }
  };

  handleCpcFilterChange = (cpcFilter: ProductCatalogueFilter) => {
    this.props.handelCpcFilter(cpcFilter);
  };

  handleCpcFilterSortChange = () => {
    this.props.handelCpcFilter(this.state.cpcFilter);
  };

  resetSelection = () => {
    if (this.state.productCatalogue.resourceNumber) {
      this._productCatalogueSelection.setKeySelected(
        this.state.productCatalogue.resourceNumber,
        true,
        false,
      );
    } else {
      this._productCatalogueSelection.selectToKey('', true);
    }
  };

  render() {
    return (
      <div>
        <CpcListPaneComponent
          resetSelection={() => this.resetSelection()}
          productCatalogue={this.state.productCatalogue}
          columns={this._columns}
          cpcFilter={this.props.cpcFilter}
          cpcList={this.props.cpcListItem}
          loadMsg={this.props.loadMsg}
          resourceTypes={this.props.resourceTypes}
          cpcSelection={this._productCatalogueSelection}
          handleCpcUpdateOpen={(id: string) => this.handleCpcUpdateOpen(id)}
          handleCpcFilterChange={(cpcFilter: ProductCatalogueFilter) =>
            this.handleCpcFilterChange(cpcFilter)
          }
          isDataLoaded={this.props.isDataLoaded}
        />
      </div>
    );
  }

  private _getSelectedCpcIds() {
    let deleteList: any = [];
    let SelectedCpcItem = this._productCatalogueSelection.getSelection() as SelectedCpcItem[];
    if (SelectedCpcItem) {
      deleteList = SelectedCpcItem.map((item: any) => {
        return (item) ? item.id : null;
      });
    }
    return deleteList;
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn,
  ): void => {
    const newColumns: IColumn[] = this._columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key,
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        let cpcFilter = this.state.cpcFilter;

        let fieldName =
          currColumn.fieldName === 'resourceType'
            ? 'resourceTypeId'
            : currColumn.fieldName;
        if (currColumn.fieldName === 'resourceType') {
          fieldName = 'resourceTypeId';
        } else if (currColumn.fieldName === 'title') {
          fieldName = 'resourceNumber';
        }
        let sortby = currColumn.isSortedDescending ? 'desc' : 'asc';
        cpcFilter.sorter.attribute = fieldName ? fieldName : null;
        cpcFilter.sorter.order = sortby ? sortby : null;
        this.setState(
          {
            cpcFilter: cpcFilter,
          },
          this.handleCpcFilterSortChange,
        );
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
};

const mapDispatchToProps = {};

export default connect(mapStateToProps)(ProductCatalogueListPane);

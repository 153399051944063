import _ from 'lodash';
import {
  DetailsListLayoutMode,
  DetailsRow,
  Dropdown,
  Fabric,
  IColumn,
  IDetailsFooterProps,
  IDetailsListProps,
  IDetailsRowStyles,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
  Text,
  TextField,
} from '@fluentui/react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CustomDatePicker from '../../../shared/customDatePicker/customDatePicker';
import PeoplePicker from '../../../shared/fluentFinalForm/PeoplePicker';
import { formatDate, formatwithoutTime } from '../../../shared/util';
import { ListPaneItem } from '../../../types/projectBreakdownStructure';
import { WorkFlowListPaneFilter } from '../../../types/workFlow';

export const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 25vh)!important',
    position: 'relative',
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: { margin: 0 },
  row: { display: 'inline-block' },
});

const ListPaneComponent = (props: {
  filter: WorkFlowListPaneFilter;
  selection: any;
  columns: IColumn[];
  listPaneItems: ListPaneItem[];
  handleListPaneItemClick: (id: string) => void;
  handleFilterChange: (filter: WorkFlowListPaneFilter) => void;
  isDataLoaded?: boolean;
  loadMsg: string;
  resetSelection: () => void;
  dropDownOptions: any;
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<WorkFlowListPaneFilter>(props.filter);
  const [requester, setRequester] = useState<any>(null);
  const [executer, setExecuter] = useState<any>(null);
  const getDropdownWithDefualt = (options: any) => {
    if (options) {
      const defualtOption = [
        {
          key: '0',
          text: t('all'),
          isDefault: true,
        },
      ];
      Array.prototype.push.apply(defualtOption, options);
      return defualtOption;
    }
    return [];
  };

  useEffect(() => {
    setFilter(props.filter);
    if (!props.filter.requester) {
      setRequester([]);
    }
    if (!props.filter.executer) {
      setExecuter([]);
    }
  }, [props.filter]);


  const _onRenderRow: IDetailsListProps['onRenderRow'] = (row) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (row) {
      return (
        <span
          style={{ textDecoration: 'none' }}
          onClick={() => {
            // chnage item id name
            props.handleListPaneItemClick(row.item.sequenceId);
          }}
        >
                    <DetailsRow {...row} styles={customStyles}/>
                </span>
      );
    }
    return null;
  };

  const _onRenderDetailsFooter = (
    detailsFooterProps: IDetailsFooterProps | undefined,
  ) => {
    return (
      <DetailsRow
        className="footer-as-filter"
        {...detailsFooterProps}
        columns={detailsFooterProps?.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps?.groupNestingDepth}
        selection={detailsFooterProps?.selection}
        onRenderItemColumn={_renderFilterItemColumn}
        // onRenderCheck={_onRenderCheckForFooterRow}
      />
    );
  };


  const _renderFilterItemColumn = (
    item?: ListPaneItem,
    index?: number,
    column?: IColumn,
  ) => {
    switch (column?.key) {
      case 'column1': {
        return (
          <div>
            <TextField
              value={filter?.title ? filter?.title.toString() : ''}
              onChange={(event, value) => {
                if (value) {
                  props.filter.title = value;
                } else {
                  props.filter.title = null;
                }
                props.handleFilterChange(filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column2': {
        return (
          <div>
            <CustomDatePicker
              setMinDate={null}
              setMaxDate={null}
              setValue={formatDate(props.filter?.targetDateTime, 1)}
              getValue={(date: Date) => {
                // onChangeEndDateTime(formatDate(date, 2));
                if (date) {
                  props.filter.targetDateTime = formatDate(date, 2);
                } else {
                  props.filter.targetDateTime = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column3': {
        return (
          <div>
            <CustomDatePicker
              setMinDate={null}
              setMaxDate={null}
              setValue={formatDate(props.filter?.executedDateTime, 1)}
              getValue={(date: Date) => {
                // onChangeEndDateTime(formatDate(date, 2));
                if (date) {
                  props.filter.executedDateTime = formatDate(date, 2);
                } else {
                  props.filter.executedDateTime = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column4': {
        return (
          <div>
            <PeoplePicker
              label={null}
              isDisabled={false}
              selectedItem={requester}
              onChange={(items: any) => {
                if (items && _.isArray(items) && items.length === 0) {
                  setRequester([]);
                  props.filter.requester = null;
                  props.handleFilterChange(props.filter);
                } else {
                  setRequester([]);
                  props.filter.requester = null;
                  props.handleFilterChange(props.filter);
                }
              }}
              onPersonSelected={(items: any) => {
                if (items) {
                  props.filter.requester = items.key;
                  setRequester([{ key: items.key, text: items.text }]);
                } else {
                  props.filter.requester = null;
                  setRequester([]);
                }
                props.handleFilterChange(props.filter);
                return items;
              }}

            />
          </div>
        );
        break;
      }
      case 'column5': {
        return (
          <div>
            <PeoplePicker
              label={null}
              isDisabled={false}
              selectedItem={executer}
              onChange={(items: any) => {
                if (items && _.isArray(items) && items.length === 0) {
                  setExecuter([]);
                  props.filter.executer = null;
                  props.handleFilterChange(props.filter);
                } else {
                  setExecuter([]);
                  props.filter.executer = null;
                  props.handleFilterChange(props.filter);
                }
              }}
              onPersonSelected={(items: any) => {
                if (items) {
                  props.filter.executer = items.key;
                  setExecuter([{ key: items.key, text: items.text }]);
                } else {
                  props.filter.executer = null;
                  setExecuter([]);
                }
                props.handleFilterChange(props.filter);
                return items;
              }}

            />
          </div>
        );
        break;
      }
      case 'column6': {
        return (
          <div>
            <Dropdown
              placeholder={t('status')}
              options={getDropdownWithDefualt(props.dropDownOptions?.activityStatus)}
              selectedKey={props.filter?.status ? props.filter?.status : '0'}
              onChange={(event, value) => {
                if (value) {
                  props.filter.status =
                    value.key !== 0 ? value.key.toString() : null;
                } else {
                  props.filter.status = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column7': {
        return (
          <div>
            <Dropdown
              placeholder={t('type')}
              style={{ marginRight: 20 }}
              options={getDropdownWithDefualt(props.dropDownOptions?.types)}
              selectedKey={props.filter?.type ? props.filter?.type : '0'}
              onChange={(event, value) => {
                if (value) {
                  props.filter.type =
                    value.key !== 0 ? value.key.toString() : null;
                } else {
                  props.filter.type = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      default: {
        break;
      }
    }
  };


  const displayMessage = () => {
    return (
      <div style={{ paddingTop: 36, paddingLeft: 20 }}>
        <Text>{t(props.loadMsg)}</Text>
      </div>
    );
  };
  return (
    <Fabric>
      <div className={`wrapper-holder ${classNames.wrapper}`}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <ShimmeredDetailsList
            items={props.listPaneItems}
            columns={props.columns}
            setKey="set"
            enableShimmer={!props.isDataLoaded}
            layoutMode={DetailsListLayoutMode.justified}
            selectionPreservedOnEmptyClick={true}
            checkboxVisibility={1}
            onRenderRow={_onRenderRow}
            onRenderDetailsFooter={_onRenderDetailsFooter}
            selection={props.selection}
            selectionMode={SelectionMode.single}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            onRenderDetailsHeader={(headerProps, defaultRender) => {
              if (defaultRender !== undefined) {
                return (
                  <Sticky
                    stickyPosition={StickyPositionType.Header}
                    isScrollSynced={true}
                    stickyBackgroundColor="transparent"
                  >
                    <div>{defaultRender(headerProps)}</div>
                  </Sticky>
                );
              }
              return <span></span>;
            }}
            onRenderItemColumn={(
              item: any,
              index?: number,
              column?: IColumn,
            ) =>
              WFListColumn(
                item,
                index,
                column,
              )
            }
          />
          {props.isDataLoaded && displayMessage()}
        </ScrollablePane>
      </div>
    </Fabric>
  );
};

const WFListColumn = (item: any, index?: number, column?: IColumn) => {
  if (column && column.fieldName && ((column.fieldName === 'requiredDateAndTime') || (column.fieldName === 'executedDateAndTime'))) {
    const fieldContent = item[column.fieldName];
    return <span>{formatwithoutTime(fieldContent)}</span>;
  } else if (column && column.fieldName) {
    const fieldContent = item[column.fieldName];
    return fieldContent ? <span style={{ paddingLeft: 9 }} onClick={() => {

    }}>{fieldContent}</span> : <span style={{ paddingLeft: 9 }}>-</span>;
  }
  return <span style={{ paddingLeft: 9 }}>-</span>;
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    toleranceStatesFilter: state.projectBreakdown.toleranceStatesFilter,
    productStatesFilter: state.projectBreakdown.productStatesFilter,
    itemTypesFilter: state.projectBreakdown.itemTypesFilter,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ListPaneComponent);

import moment from 'moment';
import { getTimeZone } from '../shared/util';

export interface POShortCutPaneItem {
  id: string | null;
  name: string | null;
  type: string | null;
  value: string | null;
}

export class POFilter {
  title: string | null = null;
  statusId: string | null = null;
  projectSequenceCode: string | null = null;
  purchaseOrderTitle: string | null = null;
  thirdParty: string | null = null;
  totalAmount: number | null = null;
  customer: string | null = null;
  supplier: string | null = null;
  modifiedBy: string | null = null;
  lastModifiedDate: string | Date | null = null;
  sorter: Sorter = new Sorter();
  offSet: string = getTimeZone();
  localDate: string = moment().toISOString();
  resource: string | null = null;
  requestTypeId: string | null = null;
}

export class Sorter {
  attribute: string | null = null;
  order: string | null = null;
}


export interface POListItem {
  id: string;
  projectMoleculeId: string;
  name: string;
  title: string;
  executionDate: string;
  type: string;
  status: string;
  typeId: string;
  statusId: string;
  foreman: string;
  foremanId: string;
  customer: string;
  supplier: string;
  lastModifiedDate: string;
  noOfMaterials: string;
  noOfTools: string;
  noOfConsumables: string;
  noOfLabours: string;
  requestType: string;
  requestTypeId: string;
}

export interface PurchaseOrder {
  id: string;
  sequenceId: string;
  title: string;
  name: string;
  poTypeId: string;
  poStatusId: string;
  comments: string;
  projectSequenceCode: string;
  isDeleted: string;
}

export interface DropDown {
  key: string;
  value: string;
}

export interface PODetails {
  id: string | null;
  sequenceId: string | null;
  title: string | null;
  name: string | null;
  comments: string | null;
  requestType: DropDown;
  type: DropDown;
  status: DropDown;
  files: string[];
  customerOrganisation: string | null;
  customerOrganisationId: string | null;
  customerContactId: string | null;
  customerReference: string | null;
  supplierOrganisation: string | null;
  supplierOrganisationId: string | null;
  supplierContactId: string | null;
  supplierReference: string | null;
  supplierOrderDate: string | null;
  customerOrderDate: string | null;
  customerContact: {
    customerEmail: string | null,
    customerContact: string | null,
    customerMobile: string | null,
  };
  supplierContact: {
    customerEmail: string | null,
    customerContact: string | null,
    customerMobile: string | null,
  },
  acceptanceLevel: number | null,
  isCu: boolean,
  history: History | null;
}

export enum RESOURCE_TYPE {
  CBC = 'cbc',
  MATERIALS = 'materials',
  TOOLS = 'tools',
  LABOUR = 'labour',
  CONSUMABLE = 'consumable',
}

export class PurchaseOrderListPaneItem {
  id: string | null = null;
  name: string | null = null;
}


export interface PODropDowns {
  requestTypes: DropDown[];
  types: DropDown[];
  states: DropDown[];
  projects: DropDown[];
  warehouseTaxonomy: any;
  whTaxonomyLevel: any;
}


export interface History {
  createdBy: string | null;
  createdDate: string | null;
  revisionNumber: number | string | null;
  modifiedBy: string | null;
  modifiedDate: string | null;
}


export interface Invoice {
  id: string | null;
  invoiceId: string | null;
  title: string | null;
  headerTitle: string | null;
  name: string | null;
  projectTypeId: string | null;
  projectType: string | null;
  invoiceStatusId: string | null;
  projectStatus: string | null;
  projectCostId: string | null;
  workPeriodFrom: string | Date | null;
  workPeriodTo: string | Date | null;
  projectCompletionDate: string | Date | null;
  purchaseOrderNumber: string | null;
  projectSequenceCode: string | null;
  totalIncludingTax: number | null;
  totalExcludingTax: number | null;
  psId: string | null;
  historyLog: History | null;
  isSaved: boolean;
  date: string | null;
  isProjectFinished: boolean;
}

export const poFormData = {
  id: null,
  sequenceId: null,
  title: null,
  name: null,
  comments: null,
  requestType: { key: '', value: '' },
  type: { key: '', value: '' },
  status: { key: '', value: '' },
  customerOrganisation: null,
  customerOrganisationId: null,
  customerContactId: null,
  supplierOrganisation: null,
  supplierOrganisationId: null,
  supplierContactId: null,
  customerOrderDate: null,
  supplierOrderDate: null,
  customerContact: {
    customerEmail: null,
    customerContact: null,
    customerMobile: null,
    customerReference: null,
    // customerOrderDate: null,
  },
  supplierContact: {
    customerEmail: null,
    customerContact: null,
    customerMobile: null,
    customerReference: null,
    // supplierOrderDate: null
  },
  isCu: true,
};

export const invoiceFilter = {
  id: null,
  invoiceId: null,
  invoiceStatus: null,
  invoiceStatusId: null,
  name: null,
  psId: null,
  purchaseOrderNumber: null,
  title: null,
  totalExcludingTax: null,
  totalIncludingTax: null,
  workPeriodFrom: null,
  workPeriodTo: null,
  sorter: {
    attribute: null,
    order: null,
  },
};

export interface InvoiceValidationMessage {
  nameErrorMsg: string | null;
  typeErrorMsg: string | null;
  borErrorMsg: string | null;
  statusErrorMsg: string | null;
}

export interface Cost {
  id: string | null;
  psId: string | null;
  cpcId?: any;
  cpcResourceNumber: string;
  cpcTitle: string;
  consumedQuantity: number;
  costToMou?: any;
  totalCost: any;
  soldQuantity: number;
  spToMou?: any;
  consumedQuantityMou?: any;
  mouId?: any;
  mou?: any;
  status?: any;
  cpcResourceTypeId: string;
  cpcResourceType: string;
  projectCostId: string;
  resourceCategory: string;
  productTitle: string;
}


export interface DeliveryPlanListPaneItem {
  id: string | null;
  borId: string | null;
  itemId: string | null;
  borTitle: string | null;
  resources: DeliveryPlanResourceItem[]
}

export interface DeliveryPlanResourceItem {
  id: string | null;
  borId: string | null;
  corporateProductCatalogId: string | null;
  ccpcId: string | null;
  resourceNumber: string | null;
  resourceTitle: string | null;
  resourcesType: string | null;
  mou: string | null;
  quantity: string | null;
  requestedDeliveryDate: string | Date | null;
  expectedDeliveryDate: string | Date | null;
  stockAvailability: string | number | null;
  cUnitPrice: string | number | null;
  cTotalPrice: string | number | null;
  cQuantity: string | number | null;
  wfStatus: string | null;
  isStock: boolean | null;
  action: boolean | null;
  isPo: boolean | null;
}

export interface PoResourceListPaneItem {
  key: string | null;
  id: string | null;
  title: string | null;
  resourceNumber: string | null;
  resourceType: string | null;
  corporateProductCatalogId: string | null;
  required: string | null;
  consumed: string | null;
  purchased: string | null;
  deliveryRequested: string | null;
  warf: string | null;
  invoiced: string | null;
  resourceTitle: string | null;
  borTitle: string | null;
  borId: string | null;
  mou: string | null;
  inventoryPrice: string | null;
  pbsTitle: string | null;
  resourceFamily: string | null;
  date: string | Date | null;

}

export interface PoResourceFilterItem {
  resourceTypeId: string | null;
  resourceFamily: string | null;
  resourceTitle: string | null;
  borTitle: string | null;
  pbsTitle: string | null;
  sorter: Sorter;
}


export const RESOURCE_TYPE_ID = '94282458-0b40-40a3-b0f9-c2e40344c8f1';
export const PRODUCT_TYPE_ID = '4010e768-3e06-4702-b337-ee367a82addb';
export const GOODDEERIS_PRODUCT_TYPE_ID = '94282458-0b40-capa-b0f9-c2e40344c8f1';


export const PURCHASE_REQUEST = '94282458-0b40-poa3-b0f9-c2e40344c8f1';
export const RETURN_REQUEST = '4010e768-3e06-po02-b337-ee367a82addb';
export const CAPACITY_REQUEST = 'lll82458-0b40-poa3-b0f9-c2e40344clll';
export const SUPPLIER_PURCHASE_REQUEST = '343482458-0spr-poa3-b0f9-c2e40344clll';
export const RFQ_REQUEST= 'f4d6ba08-3937-44ca-a0a1-7cf33c03e290';

export const WF_IN_REVIEW_STATUS_ID = '7143ff01-d173-4a20-8c17-cacdfecdb84c';
export const WF_IN_APPROVED_STATUS_ID = '7bcb4e8d-8e8c-487d-8170-6b91c89fc3da';
export const WF_IN_HANDED_OVER_STATUS_ID = '4010e768-3e06-4702-b337-ee367a82addb';

export enum POAccessLevel {
  PROJECT = 'project',
  CU = 'cu',
}


export enum PO_PBS_TYPE {
  SUB = '48a7dd9c-55ac-4e7c-a2f3-653811c0eb14',
  GOODDEERIS = 'aa0c8e3c-f716-4f92-afee-851d485164da'
}

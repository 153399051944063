import { AnimationStyles, getTheme, ITheme, mergeStyles, Stack } from '@fluentui/react';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import history from '../../../history';
import {
  deleteProductCatalogue,
  deleteProductCatalogueImage,
  deleteProductCatalogueNickName,
  deleteProductCatalogueVendor,
  getCpcResourceFamily,
  getDropdownData,
  getExcelData,
  getOrganizationByName,
  getProductCatalogueById,
  newProductCatalogue,
  savePicturesOfTheInstallation,
  saveProductCatalogue,
} from '../../../reducers/productCatalogueReducer';
import {
  clearFormData,
  clearUid,
  clonePbs,
  deleteProduct,
  getById,
  getListPaneData,
  getShortcutPaneFilterList,
  readDropdownData,
  resetFormData,
  resetIsChange,
  setUid,
} from '../../../reducers/projectBreakdownReducer';
import { messageService } from '../../../services/messageService';
import { excelExport, getContractingUnit, getProject } from '../../../shared/util';
import {
  CorporateProductCatalogue,
  CorporateProductCatalogueListItem,
} from '../../../types/corporateProductCatalogue';
import {
  FilterItem,
  ListPaneItem,
  PBS_TYPE,
  ProjectBreakdown,
  ShortCutPaneItem,
} from '../../../types/projectBreakdownStructure';
import '../../style/style.css';
import DocumentPane from '../../ProjectBreakdownStructure/documentPane/container';


export interface State {
  selectedShortCutItem: ShortCutPaneItem,
  filter: FilterItem,
  projectId: string | null,
  pbsId: string | null,
  formData: ProjectBreakdown,

  isClone: boolean;
  submitCpc: boolean;
  redirect: boolean;
  loading: boolean;
  showPanel: boolean;
  width: number;
  activeClass: string;
  bookmarkDivClass: string;
  headerFilter: boolean;
  showDetails: boolean;
  currentActiveSection: string;
  isOpenNickNameModel: boolean;
  allProductCatalogue: [];
  uploadedImages: string | null;
  autoSave: boolean;
  isEdit: boolean;
  selectedCpcId: string | null | undefined;
  cpcListItem: CorporateProductCatalogueListItem[];
  cpcPressureClass: [],
  selectedListPaneItemIds: [] | string[];
  //headerTitle:string|null,
  hiddenRemoveDialog: boolean;
  isDrag: boolean;
  resetLayout: boolean;
  collapseBookmarkPane: boolean;
  reSizer: number;
  pbsType: PBS_TYPE;
}

interface Props extends WithTranslation {
  match: any;
  resetFormData: () => void;
  getShortcutPaneFilterList: () => void;
  readDropdownData: () => void;
  shortCutPaneFilters: ShortCutPaneItem[];
  getListPaneData: (filterItem: FilterItem) => void;
  listPaneItem: ListPaneItem[];
  formData: ProjectBreakdown;
  setUid: () => void;
  clearUid: () => void;
  clearFormData: () => void;
  resetIsChange: () => void;
  uid: string | null;
  getById: (id: string) => void;
  getExcelData: () => void;
  newProductCatalogue: () => void;
  getOrganizationByName: (name: string) => void;
  getCpcResourceFamily: () => void;
  deleteProduct: (idList: [] | string[]) => void;
  deleteProductCatalogueNickName: (idList: [] | string[]) => void;
  deleteProductCatalogueVendor: (idList: [] | string[]) => void;
  deleteProductCatalogueImage: (idList: [] | string[]) => void;
  deleteProductCatalogue: (idList: [] | string[]) => void;
  saveProductCatalogue: (productCatalogue: CorporateProductCatalogue) => void;
  getProductCatalogueById: (id: string) => void;
  getDropdownData: () => void;
  savePicturesOfTheInstallation: (files: FileList | null) => void;
  cpcListItem: CorporateProductCatalogueListItem[];
  uploadedImages: string | null;
  resourceTypes: [];
  resourceTypeFilter: [];
  resourceFamily: [];
  cpcBasicUnitOfMeasure: [];
  cpcPressureClass: [],
  cpcMaterial: [],
  cpcBrand: [],
  languageCodes: [];
  organizations: [];
  cpcUnitOfSizeMeasure: [];
  excelData: [];
  autoSave: boolean;
  isChange: boolean;
  isEdit: boolean;
  isDataLoaded?: boolean;
  loadMsg: string;
  location: any;
  createNewNode: boolean;
  clonePbs: any;
}

const contentStyle = mergeStyles({
  height: 'calc(100vh - 250px)!important',
  display: 'flex',
  flexDirection: 'column',
  selectors: {
    '@media(max-width: 1100px)': {
      width: '97.4%',
      height: 'calc(100vh)',
      position: 'absolute',
    },
  },
});

const listStyle = mergeStyles({
  flex: '1 1 auto',
  width: '100%',
  height: 'auto',
  overflowX: 'hidden',
  overflowY: 'auto',
  paddingBottom: '30px',
  marginLeft: '0',
});


const resizerStyle = {
  width: '2px',
  cursor: 'col-resize',
  margin: '0px',
  height: 'calc(100vh - 129px)!important',
};

class VPPBSDocumentPane extends React.Component<Props, State> {
  _isMounted = false;
  isEdit = false;
  theme: ITheme = getTheme();
  subscription: any;

//this.props.t


  constructor(props: Props) {
    super(props);
    this.state = {
      selectedShortCutItem: { key: null, id: null, name: this.props.t('all'), type: 'all' },
      filter: {
        title: null,
        pbsProductItemTypeId: null,
        pbsProductStatusId: null,
        pbsToleranceStateId: null,
        scope: null,
        taxonomy: null,
        sorter: {
          attribute: null,
          order: null,
        },
      },
      projectId: null,
      pbsId: null,

      hiddenRemoveDialog: true,
      isClone: false,
      submitCpc: false,
      selectedListPaneItemIds: [],
      cpcListItem: [],
      selectedCpcId: null,
      isOpenNickNameModel: false,
      isEdit: false,
      autoSave: false,
      redirect: false,
      loading: false,
      width: window.innerWidth,
      showPanel: false,
      headerFilter: false,
      activeClass: 'grid-righter column-filter-activated',
      bookmarkDivClass: 'bookmark-panel parent',
      currentActiveSection: '1',
      showDetails: false,
      allProductCatalogue: [],
      uploadedImages: null,
      formData: {
        id: null,
        title: null,
        headerTitle: null,
        contract: null,
        name: null,
        pbsProductItemTypeId: null,
        pbsProductStatusId: null,
        pbsToleranceStateId: null,
        productComposition: null,
        productDerivation: null,
        productFormatPresentation: null,
        productId: null,
        projectId: this.props.match.params.id,
        productPurpose: null,
        scope: null,
        historyLog: null,
        locationTaxonomyParentId: null,
        utilityTaxonomyParentId: null,
        pbsQualityResponsibilityId: null,
        machineTaxonomyParentId: null,
        qualityProducer: null,
        qualityProducerId: null,
        qualityProducerCompany: null,
        qualityReviewer: null,
        qualityReviewerId: null,
        qualityReviewerCompany: null,
        qualityApprover: null,
        qualityApproverId: null,
        qualityApproverCompany: null,
        isSaved: false,
        pbsType: PBS_TYPE.REGULAR,
        startDate: null,
        endDate: null,
        parentId: null,
      },
      cpcPressureClass: [],
      isDrag: false,
      resetLayout: false,
      collapseBookmarkPane: false,
      reSizer: window.innerWidth,
      pbsType: PBS_TYPE.REGULAR,
    };

    this.updateDimensions = this.updateDimensions.bind(this);
    this._handleClick = this._handleClick.bind(this);
  }

  componentDidMount() {
    this.props.getShortcutPaneFilterList();
    this.props.readDropdownData();
    this.getListPaneData();
    this.handleUrlChange();
    //
    this.props.getDropdownData();
    // this.props.getCpcResourceFamily();
    // this.props.getExcelData();
    this._isMounted = true;
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('load', this.updateDimensions);
    if (location.pathname.split('/').pop() === 'new' && this.state.formData && this.state.formData.id === null) {
      this.createNew(true);
    }
    this.setState({
      formData: this.props.formData,
    }, this.handleShowDocumentPane);

    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.hideDocumentPane) {
          //this.hideDocumentPane();
        }
        if (data.data.collapseDocumentPane) {
          this.setState({
            reSizer: (this.state.reSizer === 300) ? window.innerWidth / 2 : 300,
            isDrag: (!this.state.isDrag),
            collapseBookmarkPane: (!this.state.collapseBookmarkPane),
            resetLayout: (!this.state.resetLayout),
          });
        }
        if (data.data.shortCutPaneExpanded) {
          if (this.state.showDetails) {
            this.setState({
              reSizer: window.innerWidth / 2,
              isDrag: false,
              collapseBookmarkPane: false,
              resetLayout: true,
            });
          }
        }

        if (data.data.pbsTypeFromRead) {
          this.setState({ pbsType: data.data.pbsTypeFromRead });
        }

        if (data.data.isNewPbs) {
          this.createNew(data.data.createNew, data.data.pbsType);
        }
      }
    });
  }

  getBookMarkItems = () => {
    let bookmarkList = [
      {
        id: 1,
        link: 'pbs-product-header',
        label: this.props.t('productHeader'),
      },
      { id: 2, link: 'pbs-product-detail', label: this.props.t('productDetails') },
      { id: 3, link: 'pbs-competency', label: this.props.t('competencies') },
      {
        id: 4,
        link: 'pbs-resources',
        label: this.state.pbsType === PBS_TYPE.REGULAR ? this.props.t('resources') : this.props.t('service'),
      },
      { id: 5, link: 'pbs-instructions', label: this.props.t('instructions') },
      { id: 6, link: 'pbs-risks', label: this.props.t('risks') },
      { id: 7, link: 'pbs-quality', label: this.props.t('quality') },
      {
        id: 8,
        link: 'history',
        label: this.props.t('history'),
      },
    ];
    return bookmarkList;
  };


  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.match.url !== prevProps.match.url) {
      this.handleUrlChange(prevState.collapseBookmarkPane);
    }
    if (this.props.formData !== prevProps.formData) {
      this.setState({
        formData: this.props.formData,
      });
    }
    if (this.props.location && this.props.location.state && this.state.filter != this.props.location.state.pbsFilter) {
      if (this.props.location.state.pbsFilter) {
        this.setState({
          filter: this.props.location.state.pbsFilter,
        }, this.reloadListPaneItem);
      }
    }

    if (prevProps.createNewNode != this.props.createNewNode) {
      if (this.props.createNewNode) {
        this.reloadListPaneItem();
      }
    }
  }

  handleUrlChange(collapseBookmarkPane?: boolean) {
    let lastParam = this.props.match.url.split('/').pop();
    let projectId = this.props.match.params.id;
    let pbsId = (this.props.match.params.pbsId) ? this.props.match.params.pbsId : null;
    this.setState({
      isEdit: !!(this.props.match.params.id),
      projectId: projectId,
      pbsId: pbsId,
      resetLayout: collapseBookmarkPane ? false : true,
      collapseBookmarkPane: collapseBookmarkPane ? true : false,
    }, () => {
      this.handleShowDocumentPane(collapseBookmarkPane);
    });
    if (pbsId) {
      this.props.getById(pbsId);
    }
  }

  updateDimensions() {
    if (this._isMounted) {
      this.setState({
        width: window.innerWidth,
      });
      if (this.state.showDetails) {
        if (this.state.width > 1100) {
          this.setState({ showPanel: false });
        } else {
          this.setState({ showPanel: true });
        }
      }
    }
  }

  private handleShowDocumentPane = (collapseBookmarkPane?: boolean) => {
    let pbsId = (this.props.match.params.pbsId) ? this.props.match.params.pbsId : null;
    if (pbsId) {
      if (this.state.width > 1100) {
        this.setState({
          showPanel: false,
          showDetails: true,
          reSizer: collapseBookmarkPane ? 300 : window.innerWidth / 2,
        });
      } else {
        this.setState({
          showPanel: true,
          showDetails: true,
          reSizer: collapseBookmarkPane ? 300 : window.innerWidth / 2,
        });
      }
    } else if (location.pathname.split('/').pop() === 'new') {
      if (this.state.width > 1100) {
        this.setState({ showPanel: false, showDetails: true, reSizer: window.innerWidth / 2 });
      } else {
        this.setState({ showPanel: true, showDetails: true, reSizer: window.innerWidth / 2 });
      }
    } else {
      this.props.resetFormData();
      this.setState({ showPanel: false, showDetails: false, reSizer: window.innerWidth });
    }
  };

  private handleScroll = (e: any): void => {
    let element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
    }

    let h2Headings = document.getElementsByClassName('proj-detail-block');
    let item = h2Headings[0];
    let tt = 0;

    for (let i = 0; i < h2Headings.length; i++) {
      if (h2Headings[i].getBoundingClientRect().top <= 300) {
        if (tt <= h2Headings[i].getBoundingClientRect().top) {
          tt = h2Headings[i].getBoundingClientRect().top;
          item = h2Headings[i];

          var c = item.id;
          console.log(c);
          this.setState({ currentActiveSection: c });
        }
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.updateDimensions);
    this.props.resetFormData();
    this.subscription.unsubscribe();
  }

  getListPaneData = () => {
    this.props.getListPaneData(this.state.filter);
  };


  private handleSelectedListPaneItem = (selectedListPaneItemIds: [] | string[]) => {
    this.setState({
      selectedListPaneItemIds: selectedListPaneItemIds,
      isClone: false,
      submitCpc: false,
    });
  };

  private handleCloneItem = () => {
    let formData = this.state.formData;

    this.setState({
      isClone: true,
    });
    if (this.state.selectedListPaneItemIds.length >= 1 && this.state.formData.productId) {
      this.props.clonePbs(this.state.formData.productId).then(() => {
        this.setState({
          // isClone: false,
        });
      });
    }
  };

  savePicturesOfTheInstallation = (files: FileList | null) => {
    if (files) {
      this.props.savePicturesOfTheInstallation(files);
    }
  };

  private createNew = (create: boolean, type?: PBS_TYPE): void => {
    this.props.clearFormData();
    this.props.setUid();
    let formData = {
      id: (this.props.uid) ? this.props.uid : null,
      title: null,
      headerTitle: null,
      contract: null,
      name: null,
      pbsProductItemTypeId: null,
      pbsProductStatusId: null,
      pbsToleranceStateId: null,
      productComposition: null,
      productDerivation: null,
      productFormatPresentation: null,
      productId: null,
      projectId: null,
      productPurpose: null,
      scope: null,
      historyLog: null,
      locationTaxonomyParentId: null,
      utilityTaxonomyParentId: null,
      pbsQualityResponsibilityId: null,
      machineTaxonomyParentId: null,
      qualityProducer: null,
      qualityProducerId: null,
      qualityProducerCompany: null,
      qualityReviewer: null,
      qualityReviewerId: null,
      qualityReviewerCompany: null,
      qualityApprover: null,
      qualityApproverId: null,
      qualityApproverCompany: null,
      isSaved: false,
      pbsType: type ? type : PBS_TYPE.REGULAR,
      startDate: null,
      endDate: null,
      parentId: null,
    };
    this.setState({
      formData: formData,
    });
    // if (getContractingUnit()) {
    //
    //   if (type) {
    //     // history.push(`/CU/${getContractingUnit()}/project/${getProject()}/product/pbs/new`);
    //     history.push({
    //       pathname: `/CU/${getContractingUnit()}/project/${getProject()}/product/pbs/new`,
    //       state: { type: type },
    //     });
    //   } else {
    //     history.push(`/CU/${getContractingUnit()}/project/${getProject()}/product/pbs/new`);
    //   }
    // } else {
    //   history.push(`/project/${this.state.projectId}/product/pbs/new`);
    // }
    //history.push(`/projects/project/${this.state.projectId}/product/pbs/new`);
  };


  reloadListPaneItem = () => {
    //
    this.props.resetIsChange();
    setTimeout(() => {
      this.getListPaneData();
    }, 100);
    //this.props.getExcelData();
  };

  private handleActionBarSave = (save: boolean): void => {
    // alert("save start"+JSON.stringify(save));
    this.setState({
      submitCpc: save,
    });
  };

  private getDocumentPaneView = () => {
    return (
      <div>


        <Stack
          //className={`project-detail-list ${(this.state.collapseBookmarkPane && window.innerWidth>1440)?'full-inner-container':'inner-container'} cpc  card-container`}
          styles={{
            root: {
              width: '100%',
              height: '76vh',
            },
          }}
        >
          {/*doc*/}
          <Stack
            className={contentStyle}
            horizontalAlign="start"
            verticalAlign="start"
            padding={0}
            gap={0}
            styles={{
              root: {
                width: '100%',
                height: '100%',
              },
            }}
          >
            <Stack
              className={listStyle}
              styles={{
                root: {
                  width: '100%',
                  height: 'auto',
                },
              }}
              verticalFill
              gap={50}
              horizontalAlign="start"
              verticalAlign="start"
              onScroll={this.handleScroll}
            >
              <DocumentPane
                projectId={this.state.projectId}
                submitCpc={this.state.submitCpc}
                isEdit={this.state.isEdit}
                selectedCpcId={this.state.selectedCpcId}
                formData={this.state.formData}
                savePicturesOfTheInstallation={(files: FileList | null) =>
                  this.savePicturesOfTheInstallation(files)
                }
                saveProductCatalogue={(
                  productCatalogue: CorporateProductCatalogue,
                ) => {
                  //alert("save final");
                  this.setState({
                    submitCpc: false,
                  });
                  this.props.saveProductCatalogue(productCatalogue);
                }}
                deleteProductCatalogueNickName={(
                  deleteList: [] | string[],
                ) => {
                  this.props.deleteProductCatalogueNickName(deleteList);
                }}
                deleteProductCatalogueVendor={(
                  deleteList: [] | string[],
                ) => {
                  this.props.deleteProductCatalogueVendor(deleteList);
                }}
                deleteProductCatalogueImage={(
                  deleteList: [] | string[],
                ) => {
                  this.props.deleteProductCatalogueImage(deleteList);
                }}
                autoSave={this.props.autoSave}
                uploadedImages={this.props.uploadedImages}
                resourceTypes={this.props.resourceTypes}
                resourceFamily={this.props.resourceFamily}
                languageCodes={this.props.languageCodes}
                cpcBasicUnitOfMeasure={this.props.cpcBasicUnitOfMeasure}
                cpcPressureClass={this.props.cpcPressureClass}
                cpcMaterial={this.props.cpcMaterial}
                cpcBrand={this.props.cpcBrand}
                organizations={this.props.organizations}
                cpcUnitOfSizeMeasure={this.props.cpcUnitOfSizeMeasure}
                handelGetOrganizationByName={(name: string) => this.handelGetOrganizationByName(name)}
              />
            </Stack>
            {/*{this.state.collapseBookmarkPane && window.innerWidth>1440?<CollapseBookMarkPane*/}
            {/*isEdit={this.props.isEdit}*/}
            {/*divClass={this.state.bookmarkDivClass}*/}
            {/*currentActiveSection={this.state.currentActiveSection}*/}
            {/*list={this.getBookMarkItems()}*/}
            {/*toggleBookmark={this._toggleBookmark}*/}
            {/*toggleBookmarkOut={this._toggleBookmarkOut}*/}
            {/*pmolType={PMOL_TYPE.REGULAR}*/}
            {/*/>:<BookMarkPane*/}
            {/*isEdit={this.props.isEdit}*/}
            {/*divClass={this.state.bookmarkDivClass}*/}
            {/*currentActiveSection={this.state.currentActiveSection}*/}
            {/*list={this.getBookMarkItems()}*/}
            {/*toggleBookmark={this._toggleBookmark}*/}
            {/*toggleBookmarkOut={this._toggleBookmarkOut}*/}
            {/*/>}*/}
          </Stack>
        </Stack>
      </div>
    );
  };

  handelFilter = (filter: FilterItem) => {
    if ((filter.title && filter.title.length >= 3) || filter.title === null) {
      this.setState({
        filter: filter,
      }, this.reloadListPaneItem);
    } else {
      this.setState({
        filter: filter,
      });
    }

  };

  handelGetOrganizationByName = (name: string) => {
    this.props.getOrganizationByName(name);
  };


  handelShortCutPaneFilter = (selectedItem: ShortCutPaneItem) => {
    this.setState({
      selectedShortCutItem: selectedItem,
    });

  };

  render() {
    console.timeEnd();
    const { t } = this.props;
    const animation = mergeStyles(
      this.state.showDetails
        ? AnimationStyles.slideLeftIn400
        : AnimationStyles.slideRightIn400,
    );
    return (
      <div>
        {this.getDocumentPaneView()}
      </div>
    );
  }

  private _showPanel = () => {
    if (this.state.width <= 1100) {
      this.setState({ showPanel: true });
    }
  };

  private _hidePanel = () => {
    this.setState({ showPanel: false, showDetails: false });
    if (getContractingUnit()) {
      history.push(`/CU/${getContractingUnit()}/project/${getProject()}/product/pbs`);
    } else {
      history.push(`/project/${this.state.projectId}/product/pbs/`);
    }
  };

  private _handleClick(): void {
    if (this.state.headerFilter == false) {
      this.setState({
        activeClass: 'grid-righter column-filter-activated',
        headerFilter: true,
      });
    } else {
      this.setState({ activeClass: 'grid-righter', headerFilter: false });
    }
  }

  private _toggleBookmark = (): void => {
    this.setState({ bookmarkDivClass: 'bookmark-panel parent panel-on' });
  };

  private _toggleBookmarkOut = (): void => {
    this.setState({ bookmarkDivClass: 'bookmark-panel parent' });
  };

  private openNickNameModel = (): void => {
    this.setState({ isOpenNickNameModel: !this.state.isOpenNickNameModel });
  };

  private _handleRemove = () => {
    this.setState({
      hiddenRemoveDialog: false,
    });
  };

  private onRemoveConfirm = () => {
    this.setState({
      hiddenRemoveDialog: true,
    });
    if (this.state.formData.productId && this.state.formData.id) {
      this.props.deleteProduct([this.state.formData.id]);
      this.createNew(true);
      if (getContractingUnit()) {
        history.push(`/CU/${getContractingUnit()}/project/${getProject()}/product/pbs/new`);
      } else {
        history.push(`/project/${this.state.projectId}/product/pbs/new`);
      }
    }
  };

  private hideDocumentPane = () => {

    if (getContractingUnit()) {
      history.push(`/CU/${getContractingUnit()}/project/${getProject()}/product/pbs`);
    } else {
      history.push(`/project/${this.state.projectId}/product/pbs/`);
    }

  };

  private onRemoveCancel = () => {
    this.setState({
      hiddenRemoveDialog: true,
    });
  };

  private exportExcel = (): void => {
    if (this.props.excelData) {
      excelExport(
        this.props.excelData,
        this.props.t('productCatalogue'),
        this.props.t('productCatalogue'),
      );
    }
  };

}

const mapStateToProps = (state: any, ownProps: any) => {
  return {

    shortCutPaneFilters: state.projectBreakdown.shortCutPaneFilters,
    listPaneItem: state.projectBreakdown.listPaneItem,
    loadMsg: state.projectBreakdown.loadMsg,
    isDataLoaded: state.projectBreakdown.isDataLoaded,
    formData: state.projectBreakdown.formData,
    isChange: state.projectBreakdown.isChange,
    isEdit: state.projectBreakdown.isEdit,

    cpcListItem: state.productCatalogue.cpcListItem,
    uploadedImages: state.productCatalogue.uploadedImages,
    resourceTypes: state.productCatalogue.resourceTypes,
    resourceTypeFilter: state.productCatalogue.resourceTypeFilter,
    resourceFamily: state.productCatalogue.resourceFamily,
    cpcBasicUnitOfMeasure: state.productCatalogue.cpcBasicUnitOfMeasure,
    cpcPressureClass: state.productCatalogue.cpcPressureClass,
    cpcMaterial: state.productCatalogue.cpcMaterial,
    cpcBrand: state.productCatalogue.cpcBrand,
    languageCodes: state.productCatalogue.languageCodes,
    autoSave: state.productCatalogue.autoSave,
    organizations: state.productCatalogue.organizations,
    cpcUnitOfSizeMeasure: state.productCatalogue.cpcUnitOfSizeMeasure,
    excelData: state.productCatalogue.excelData,
    createNewNode: state.projectBreakdown.createNewNode,
  };
};

const mapDispatchToProps = {

  getShortcutPaneFilterList,
  getListPaneData,
  readDropdownData,
  setUid,
  clearUid,
  clearFormData,
  resetIsChange,

  savePicturesOfTheInstallation,
  getDropdownData,
  saveProductCatalogue,
  getProductCatalogueById,
  newProductCatalogue,
  deleteProductCatalogueNickName,
  deleteProductCatalogueVendor,
  deleteProductCatalogueImage,
  getOrganizationByName,
  getCpcResourceFamily,
  getExcelData,
  deleteProductCatalogue,
  getById,
  deleteProduct,
  resetFormData,
  clonePbs,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(VPPBSDocumentPane)),
);



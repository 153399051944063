import {
  assign,
  ContextualMenu,
  FontWeights,
  getTheme,
  IBasePickerSuggestionsProps,
  IconButton,
  IDragOptions,
  IIconProps,
  IPersona,
  IPersonaProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Modal,
  NormalPeoplePicker,
  PrimaryButton,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../../../i18n';
import { uPrinceTheme } from '../../../../../../theme';
import client from '../../../../../api';
import { messageService } from '../../../../../services/messageService';
import ClearableDropdown from '../../../../../shared/clearableDropdown/clearableDropdown';
import { GET_PROJECT_TEAM_BY_NAME_EP } from '../../../../../shared/endpoints';
import { LabourTeamMember } from '../../../../../types/projectMolecule';

const theme = getTheme();

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
});

const stackStyles: IStackStyles = { root: { padding: 0 } };
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};

const bankItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 0,
    flexGrow: 1,
    width: 0,
  },
};

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: { color: uPrinceTheme.palette.white },
  rootHovered: { color: theme.palette.neutralDark },
};
const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };
const addIconWhite: IIconProps = {
  iconName: 'Save',
  styles: addWhiteIconButtonStyles,
};
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    paddingTop: 20,
    overflowY: 'hidden',
    minWidth: '40vw',
    // minHeight: '32vh',
    minHeight: screen.width > 1280 ? '42vh' : '60vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'none',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const toggleStyles = { root: { marginBottom: '20px' } };
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: { color: theme.palette.neutralDark },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const dropdownStyles = { dropdown: { width: 300, marginTop: 20 } };

const tagSearchAdditionalProps: IBasePickerSuggestionsProps = {
  suggestionsContainerAriaLabel: i18n.t('suggestedOrganisation'),
  searchForMoreText: i18n.t('loadallResults'),
  resultsMaximumNumber: 10,
  searchingText: i18n.t('searching') + '...',
};

const tagSuggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: i18n.t('suggestedOrganisation'),
  mostRecentlyUsedHeaderText: i18n.t('suggestedOrganisation'),
  noResultsFoundText: i18n.t('noresultsfound'),
  loadingText: i18n.t('loading'),
  showRemoveButtons: true,
  suggestionsAvailableAlertText: i18n.t('peoplePickerSuggestionsavailable'),
  suggestionsContainerAriaLabel: i18n.t('suggestedOrganisation'),
};

const tagSearchSuggestionProps: IBasePickerSuggestionsProps = assign(
  tagSearchAdditionalProps,
  tagSuggestionProps,
);

class MaterialValidationMessages {
  cpcValidationMsg: string = '';
  quantityValidationMsg: string = '';
}

class PickerPerson {
  key: string | null = '';
  text: string | null = '';
}

const EmployeeModal = (props: {
  isOpen: boolean;
  openResourceModal: () => void;
  handleResourceSave: (quantity: any) => void;
  handleCpcChange: (selectedCpc: any) => void;
  title: string;
  cpcOptions: any;
  cpcData: any;
  clearCpcData: () => void;
  isEdit: boolean;
  teamMember: LabourTeamMember | null;
  pmolID: string | null;
  type: 'Planned' | 'Extra';
  userRoles: [];
}) => {
  const { t } = useTranslation();
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
  const titleId = useId('title');
  const theme = getTheme();
  const limitedSearchAdditionalProps: IBasePickerSuggestionsProps = {
    resultsMaximumNumber: 10,
    searchingText: 'Searching...',
  };

  const [plannedQuantity, setPlannedQuantity]: any = useState('');
  const [consumedQuantity, setConsumedQuantity]: any = useState('');
  const [cpc, setCpc]: any = useState(null);
  const [unit, setUnit]: any = useState(null);
  const [nameErrorMsg, setNameErrorMsg]: any = useState(null);
  const [roleErrorMsg, setRoleErrorMsg]: any = useState(null);
  const [plannedQuantityErrorMsg, setPlannedQuantityErrorMsg]: any = useState(null);
  const [consumedQuantityErrorMsg, setConsumedQuantityErrorMsg]: any = useState(null);
  const [labour, setLabour]: any = useState<IPersona[]>([]);
  const [role, setRole]: any = useState(null);
  const [startTime, setStartTime]: any = useState(null);
  const [endTime, setEndTime]: any = useState(null);

  const onChangeInput = (field: string) => (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    if (field === 'plannedQuantity') {
      setPlannedQuantity(newValue);
      setPlannedQuantityErrorMsg(null);
    } else if (field === 'consumedQuantity') {
      setConsumedQuantity(newValue);
      setConsumedQuantityErrorMsg(null);
    }
  };


  const onLabourSelected = (item: any) => {
    if (item) {
      // let foremanMobileNumber:string=(mobileNo)?mobileNo:"";
      setLabour([item]);
      setNameErrorMsg(null);
      // props.formData.foremanId=item.key;
      // props.formData.foreman=item.text;
      // props.handleFormChange(
      //   props.formData,
      //   true
      // );
    }
    return item;
  };

  const onLabourFilterPersonChanged = (
    filterText: string, selectedItems?: IPersonaProps[] | undefined,
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return getPersonByNameForForeman(filterText);
    }
    return [];
  };

  const getPersonByNameForForeman = async (name: string) => {
    let data: { key: string; text: string }[] = [];
    const response = await client.post(
      GET_PROJECT_TEAM_BY_NAME_EP, { fullName: name },
    );
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result;
    }
    return data;
  };

  const onSave = () => {
    if (validate()) {
      const teamMember: LabourTeamMember = {
        consumedQuantity: consumedQuantity,
        id: props.teamMember ? props.teamMember.id : null,
        cabPersonId: labour && labour[0] ? labour[0].key : null,
        cabPerson: labour && labour[0] ? labour[0].text : null,
        requiredQuantity: plannedQuantity,
        roleId: role ? role.key : null,
        roleName: role ? role.text : null,
        uid: props.teamMember && props.teamMember.uid ? props.teamMember.uid : Date.now().toString(),
        startDateTime: startTime ? startTime : null,
        endDateTime: endTime ? endTime : null,
      };
      if (props.isEdit) {
        messageService.sendMessage({ saveTeamMember: { isEdit: true, teamMember: teamMember } });
        onModalClose();
      } else {
        messageService.sendMessage({ saveTeamMember: { isEdit: false, teamMember: teamMember } });
        onModalClose();
      }
    } else {

    }
  };


  const validate = (): boolean => {
    let isValid = true;
    if (_.isNil(labour) || _.isEmpty(labour)) {
      setNameErrorMsg(t('employeeRequired'));
      isValid = false;
    } else {
      setNameErrorMsg('');
    }
    if (_.isNil(role) || _.isEmpty(role)) {
      setRoleErrorMsg(t('roleRequired'));
      isValid = false;
    } else {
      setRoleErrorMsg('');
    }
    if (_.isNil(plannedQuantity) || _.isEmpty(plannedQuantity)) {
      if (!plannedQuantity && plannedQuantity !== 0) {
        setPlannedQuantityErrorMsg(t('quantityrequired'));
        isValid = false;
      }
    } else {
      setPlannedQuantityErrorMsg('');
    }
    if (_.isNil(consumedQuantity) || _.isEmpty(consumedQuantity)) {
      if (!consumedQuantity && consumedQuantity !== 0) {
        setConsumedQuantityErrorMsg(t('quantityrequired'));
        isValid = false;
      }
    } else {
      setConsumedQuantityErrorMsg('');
    }
    return isValid;
  };

  const resetValidation = () => {
    setNameErrorMsg(null);
    setPlannedQuantityErrorMsg(null);
    setConsumedQuantityErrorMsg(null);
  };


  useEffect(() => {
    if (cpc) {
      props.handleCpcChange(cpc);
    }
  }, [cpc]);

  useEffect(() => {
    if (props.teamMember) {
      setDataToEdit();
    }
  }, [props.teamMember]);

  useEffect(() => {
    if (props.cpcData) {
      setUnit({ cpcBasicUnitOfMeasureId: props.cpcData.basicUnitOfMeasureId, unit: props.cpcData.basicUnitOfMeasure });
    }
  }, [props.cpcData]);


  const setDataToEdit = () => {
    if (props.teamMember && props.teamMember.cabPersonId) {
      setPlannedQuantity(props.teamMember.requiredQuantity);
      setConsumedQuantity(props.teamMember.consumedQuantity);
      setRole({
        key: props.teamMember.roleId,
        text: props.teamMember.roleName,
      });
      setLabour([{
        key: props.teamMember.cabPersonId,
        text: props.teamMember.cabPerson,
      }]);
    } else {
      setPlannedQuantity('');
      setConsumedQuantity('');
      setRole(null);
      setLabour(null);
    }
  };

  const onModalClose = () => {
    if (props.teamMember) {
      if (!props.teamMember.cabPersonId) {
        setPlannedQuantity('');
        setConsumedQuantity('');
        setLabour(null);
        setRole(null);
      }
    }
    props.openResourceModal();
    resetValidation();
  };

  return (

    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          onModalClose();
        }}
        scrollableContentClassName="body"
        isBlocking={false}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>{props.title}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              onModalClose();
            }}
          />
        </div>
        <div className={contentStyles.subHeader}>

        </div>
        <div className={contentStyles.body}>
          <div className="proj-detail-content inner">
            <div className="ms-Grid-row">
              { /* {JSON.stringify(props.teamMember)}*/}
              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <Label>{t('labour')}</Label>
                    <NormalPeoplePicker
                      onChange={(items) => {
                        if (items && _.isArray(items) && items.length === 0) {
                          setLabour([]);
                          setNameErrorMsg(null);
                        }
                      }}
                      selectedItems={
                        labour && labour[0] && labour[0] ? labour : []
                      }
                      onResolveSuggestions={onLabourFilterPersonChanged}
                      onItemSelected={onLabourSelected}
                      pickerSuggestionsProps={limitedSearchAdditionalProps}
                      className={'ms-PeoplePicker'}
                      key={'Labour'}
                      itemLimit={1}
                      removeButtonAriaLabel={'Remove'}
                      resolveDelay={300}
                      disabled={false}
                    />
                    {nameErrorMsg && (
                      <Text styles={{ root: { color: '#a4262c', fontSize: 12 } }}>
                        {nameErrorMsg}
                      </Text>
                    )}
                  </div>
                </Stack.Item>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <ClearableDropdown
                      placeholder={t('selectRole')}
                      label={t('role')}
                      options={props.userRoles}
                      selectedKey={role && role.key ? role.key : ''}
                      errorMessage={roleErrorMsg}
                      onChange={(
                        event: React.FormEvent<HTMLDivElement>,
                        item: any,
                      ) => {
                        setRole(item);
                        setRoleErrorMsg(null);
                      }}
                    />
                  </div>
                </Stack.Item>
              </Stack>

              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('plannedQuantity')}
                      value={plannedQuantity}
                      errorMessage={plannedQuantityErrorMsg}
                      type={'number'}
                      onChange={onChangeInput('plannedQuantity')}
                    />
                  </div>
                </Stack.Item>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('consumedquantity')}
                      value={consumedQuantity}

                      errorMessage={consumedQuantityErrorMsg}
                      type={'number'}
                      step={1}
                      min={0}
                      onChange={onChangeInput('consumedQuantity')}
                    />
                  </div>
                </Stack.Item>
              </Stack>
            </div>
          </div>
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={addIconWhite}
            text={t('save')}
            style={{ marginTop: 20, marginBottom: 30 }}
            onClick={() => {
              onSave();
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default EmployeeModal;

import React from 'react';
import { Label, Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { format24hDateTime } from '../util';

interface Props {
  createdByUser: string,
  updatedByUser: string,
  createdDateTime: string,
  updatedDateTime: string,
}

const HistoryBar = ({ createdByUser, updatedByUser, createdDateTime, updatedDateTime }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={'ms-Grid'} dir="ltr" style={{ marginBottom: 10 }}>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
          <Label>{t('createdBy')}:<Text> {createdByUser}</Text></Label>
          <Label
            style={{ width: '100%' }}>{t('createdDateTime')}:<Text> {format24hDateTime(createdDateTime)}</Text></Label>
        </div>
        {updatedByUser && <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
          <Label>{t('modifiedBy')}:<Text> {updatedByUser}</Text></Label>
          <Label>{t('modifiedDateTime')}: <Text>{format24hDateTime(updatedDateTime)}</Text></Label>
        </div>}
      </div>
    </div>
  );
};
export default HistoryBar;

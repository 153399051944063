import {
    CLEAR_TR_FORM_DATA,
    CREATE_PMOL_TIME_REGISTRATION_COMMENT,
    CREATE_PMOL_TIME_REGISTRATION_COMMENT_FAIL,
    CREATE_PMOL_TIME_REGISTRATION_COMMENT_SUCCESS,
    READ_MAP_DATA_VISUALIZATION,
    READ_MAP_DATA_VISUALIZATION_FAIL,
    READ_MAP_DATA_VISUALIZATION_SUCCESS,
    READ_PERSON_COMMENT_CARD,
    READ_PERSON_COMMENT_CARD_FAIL,
    READ_PERSON_COMMENT_CARD_SUCCESS,
    READ_PMOL_BY_PERSON,
    READ_PMOL_BY_PERSON_FAIL,
    READ_PMOL_BY_PERSON_SUCCESS,
    READ_PMOL_COMMENT_CARD,
    READ_PMOL_COMMENT_CARD_FAIL,
    READ_PMOL_COMMENT_CARD_SUCCESS,
    READ_PMOL_PERSON_LIST,
    READ_PMOL_PERSON_LIST_FAIL,
    READ_PMOL_PERSON_LIST_SUCCESS,
    READ_PMOL_VEHICLE_POSITIONS,
    READ_PMOL_VEHICLE_POSITIONS_FAIL,
    READ_PMOL_VEHICLE_POSITIONS_SUCCESS,
    READ_TOTALS_FOR_TR_MODAL,
    READ_TOTALS_FOR_TR_MODAL_FAIL,
    READ_TOTALS_FOR_TR_MODAL_SUCCESS,
    READ_TR_APP_DATA_BY_PERSON,
    READ_TR_APP_DATA_BY_PERSON_FAIL,
    READ_TR_APP_DATA_BY_PERSON_SUCCESS,
    READ_VTS_DATA_BY_PERSON,
    READ_VTS_DATA_BY_PERSON_FAIL,
    READ_VTS_DATA_BY_PERSON_SUCCESS,
    SAVE_TIME_REGISTRATION_STATE_ATTR,
    UPDATE_PMOL_TIME_REGISTRATION_COMMENT,
    UPDATE_PMOL_TIME_REGISTRATION_COMMENT_FAIL,
    UPDATE_PMOL_TIME_REGISTRATION_COMMENT_SUCCESS,
} from '../actionTypes/timeRegistrationActionTypes';
import {
    CREATE_PMOL_TIME_REGISTRATION_COMMENT_EP,
    GET_MAP_DATA_VISUALIZATION_EP,
    GET_PMOL_PERSON_LIST_EP,
    READ_PERSON_COMMENT_CARD_EP,
    READ_PMOL_COMMENT_CARD_EP,
    READ_PMOL_VEHICLE_POSITIONS_EP,
    READ_TOTALS_FOR_TR_MODAL_EP,
    READ_TR_APP_DATA_BY_PERSON_EP,
    READ_TR_PMOL_BY_PERSON_EP,
    READ_VTS_DATA_BY_PERSON_EP,
    UPDATE_PMOL_TIME_REGISTRATION_COMMENT_EP
} from '../shared/endpoints';
import i18n from "../../i18n";
import React from "react";

const defaultState: any = {
    openTRMapModal: false,
    mapModalData: null,
    commentId: null,
    personDropdownData: null,
    trPmolList: null,
    trAppListData: null,
    isTrLoading: false,
    directionData: null,
    vtsListData: null,
    totalsData: null,
    personComments: null,
    pmolComments: null,
}

export default function timeRegistrationReducer(state = defaultState, action: any) {
    if (action) {
        switch (action.type) {
            case SAVE_TIME_REGISTRATION_STATE_ATTR:
                const attr = Object.keys(action)[1];
                const value = Object.values(action)[1];
                return {
                    ...state,
                    [attr]: value
                };

            case CREATE_PMOL_TIME_REGISTRATION_COMMENT:
                return {
                    ...state,
                    commentId: null,
                    isTrLoading: true,
                };
            case CREATE_PMOL_TIME_REGISTRATION_COMMENT_SUCCESS:
                return {
                    ...state,
                    commentId: action?.payload?.data?.result,   //for now this id is pmolId
                    isTrLoading: false,
                };
            case CREATE_PMOL_TIME_REGISTRATION_COMMENT_FAIL:
                return {
                    ...state,
                    commentId: null,
                    isTrLoading: false,
                };

            case READ_MAP_DATA_VISUALIZATION:
                return {
                    ...state,
                };
            case READ_MAP_DATA_VISUALIZATION_SUCCESS:
                return {
                    ...state,
                    mapModalData: sampleFormData,
                };
            case READ_MAP_DATA_VISUALIZATION_FAIL:
                return {
                    ...state,
                    mapModalData: null,
                };

            case READ_PMOL_PERSON_LIST:
                return {
                    ...state,
                };
            case READ_PMOL_PERSON_LIST_SUCCESS:
                return {
                    ...state,
                    personDropdownData: formatToDropDownList(action?.payload?.data?.result),
                };
            case READ_PMOL_PERSON_LIST_FAIL:
                return {
                    ...state,
                    personDropdownData: null,
                };

            case READ_PMOL_BY_PERSON:
                return {
                    ...state,
                    isTrLoading: true,
                };
            case READ_PMOL_BY_PERSON_SUCCESS:
                return {
                    ...state,
                    trPmolList: action?.payload?.data?.result,
                    isTrLoading: false,
                };
            case READ_PMOL_BY_PERSON_FAIL:
                return {
                    ...state,
                    trPmolList: null,
                    isTrLoading: false,
                };

            case READ_TR_APP_DATA_BY_PERSON:
                return {
                    ...state,
                    isTrLoading: true,
                };
            case READ_TR_APP_DATA_BY_PERSON_SUCCESS:
                return {
                    ...state,
                    trAppListData: action?.payload?.data?.result,
                    isTrLoading: false,
                };
            case READ_TR_APP_DATA_BY_PERSON_FAIL:
                return {
                    ...state,
                    trAppListData: null,
                    isTrLoading: false,
                };

            case CLEAR_TR_FORM_DATA:
                return {
                    ...state,
                    openTRMapModal: false,
                    trPmolList: null,
                    trAppListData: null,
                    vtsListData: null,
                    directionData: null,
                };

            case READ_PMOL_VEHICLE_POSITIONS:
                return {
                    ...state,
                    isTrLoading: true,
                };
            case READ_PMOL_VEHICLE_POSITIONS_SUCCESS:
                return {
                    ...state,
                    isTrLoading: false,
                    directionData: formatRouteData(action?.payload?.data?.result),
                };
            case READ_PMOL_VEHICLE_POSITIONS_FAIL:
                return {
                    ...state,
                    isTrLoading: false,
                };

            case READ_VTS_DATA_BY_PERSON:
                return {
                    ...state,
                    isTrLoading: true,
                };
            case READ_VTS_DATA_BY_PERSON_SUCCESS:
                return {
                    ...state,
                    vtsListData: action?.payload?.data?.result,
                    isTrLoading: false,
                };
            case READ_VTS_DATA_BY_PERSON_FAIL:
                return {
                    ...state,
                    vtsListData: null,
                    isTrLoading: false,
                };

            case READ_TOTALS_FOR_TR_MODAL:
                return {
                    ...state,
                    isTrLoading: true,
                    totalsData: null,
                };
            case READ_TOTALS_FOR_TR_MODAL_SUCCESS:
                return {
                    ...state,
                    isTrLoading: false,
                    totalsData: action?.payload?.data?.result,
                };
            case READ_TOTALS_FOR_TR_MODAL_FAIL:
                return {
                    ...state,
                    isTrLoading: false,
                    totalsData: null,
                };

            case READ_PERSON_COMMENT_CARD:
                return {
                    ...state,
                    isTrLoading: true,
                };
            case READ_PERSON_COMMENT_CARD_SUCCESS:
                return {
                    ...state,
                    isTrLoading: false,
                    personComments: action?.payload?.data?.result,
                };
            case READ_PERSON_COMMENT_CARD_FAIL:
                return {
                    ...state,
                    isTrLoading: false,
                };

            case UPDATE_PMOL_TIME_REGISTRATION_COMMENT:
                return {
                    ...state,
                    isTrLoading: true,
                };
            case UPDATE_PMOL_TIME_REGISTRATION_COMMENT_SUCCESS:
                return {
                    ...state,
                    isTrLoading: false,
                    // personComments: action?.payload?.data?.result,
                };
            case UPDATE_PMOL_TIME_REGISTRATION_COMMENT_FAIL:
                return {
                    ...state,
                    isTrLoading: false,
                };

            case READ_PMOL_COMMENT_CARD:
                return {
                    ...state,
                    isTrLoading: true,
                };
            case READ_PMOL_COMMENT_CARD_SUCCESS:
                return {
                    ...state,
                    isTrLoading: false,
                    pmolComments: action?.payload?.data?.result,
                };
            case READ_PMOL_COMMENT_CARD_FAIL:
                return {
                    ...state,
                    isTrLoading: false,
                };

            default:
                return state;
        }
    }
}

export function saveTimeRegistrationStateAttr(attr: string, value: any) {
    return {
        type: SAVE_TIME_REGISTRATION_STATE_ATTR,
        [attr]: value
    };
}

/* create new comment card */
export const createNewComment = (data: any) => {
    return {
        types: [
            CREATE_PMOL_TIME_REGISTRATION_COMMENT,
            CREATE_PMOL_TIME_REGISTRATION_COMMENT_SUCCESS,
            CREATE_PMOL_TIME_REGISTRATION_COMMENT_FAIL
        ],
        payload: {
            request: {
                url: CREATE_PMOL_TIME_REGISTRATION_COMMENT_EP,
                method: 'POST',
                data: data
            }
        }
    };
};

export const getMapDataVisualization = (id: any) => {
    return {
        types: [
            READ_MAP_DATA_VISUALIZATION,
            READ_MAP_DATA_VISUALIZATION_SUCCESS,
            READ_MAP_DATA_VISUALIZATION_FAIL
        ],
        payload: {
            request: {
                url: GET_MAP_DATA_VISUALIZATION_EP + id,    //TODO: API integration
                method: 'GET'
            }
        }
    };
};

export const getPmolPersonList = (id: any) => {
    return {
        types: [
            READ_PMOL_PERSON_LIST,
            READ_PMOL_PERSON_LIST_SUCCESS,
            READ_PMOL_PERSON_LIST_FAIL
        ],
        payload: {
            request: {
                url: GET_PMOL_PERSON_LIST_EP + id,
                method: 'GET'
            }
        }
    };
};

export const readPmolByPerson = (data: any) => {
    return {
        types: [
            READ_PMOL_BY_PERSON,
            READ_PMOL_BY_PERSON_SUCCESS,
            READ_PMOL_BY_PERSON_FAIL
        ],
        payload: {
            request: {
                url: READ_TR_PMOL_BY_PERSON_EP,
                method: 'POST',
                data: data
            }
        }
    };
};

export const readTrAppDataByPerson = (data: any) => {
    return {
        types: [
            READ_TR_APP_DATA_BY_PERSON,
            READ_TR_APP_DATA_BY_PERSON_SUCCESS,
            READ_TR_APP_DATA_BY_PERSON_FAIL
        ],
        payload: {
            request: {
                url: READ_TR_APP_DATA_BY_PERSON_EP,
                method: 'POST',
                data: data
            }
        }
    };
};

export const clearTrFormData = () => {
    return {type: CLEAR_TR_FORM_DATA};
};

export const readPmolVehiclePosition = (data: any) => {
    return {
        types: [
            READ_PMOL_VEHICLE_POSITIONS,
            READ_PMOL_VEHICLE_POSITIONS_SUCCESS,
            READ_PMOL_VEHICLE_POSITIONS_FAIL
        ],
        payload: {
            request: {
                url: READ_PMOL_VEHICLE_POSITIONS_EP,
                method: 'POST',
                data: data
            }
        }
    };
};

export const readVTSDataByPerson = (data: any) => {
    return {
        types: [
            READ_VTS_DATA_BY_PERSON,
            READ_VTS_DATA_BY_PERSON_SUCCESS,
            READ_VTS_DATA_BY_PERSON_FAIL
        ],
        payload: {
            request: {
                url: READ_VTS_DATA_BY_PERSON_EP,
                method: 'POST',
                data: data
            }
        }
    };
};

export const readTotalsForTrModal = (data: any) => {
    return {
        types: [
            READ_TOTALS_FOR_TR_MODAL,
            READ_TOTALS_FOR_TR_MODAL_SUCCESS,
            READ_TOTALS_FOR_TR_MODAL_FAIL
        ],
        payload: {
            request: {
                url: READ_TOTALS_FOR_TR_MODAL_EP,
                method: 'POST',
                data: data
            }
        }
    };
};

export const readPersonCommentCard = (data: any) => {
    return {
        types: [
            READ_PERSON_COMMENT_CARD,
            READ_PERSON_COMMENT_CARD_SUCCESS,
            READ_PERSON_COMMENT_CARD_FAIL
        ],
        payload: {
            request: {
                url: READ_PERSON_COMMENT_CARD_EP,
                method: 'POST',
                data: data
            }
        }
    };
};

export const updateComment = (data: any) => {
    return {
        types: [
            UPDATE_PMOL_TIME_REGISTRATION_COMMENT,
            UPDATE_PMOL_TIME_REGISTRATION_COMMENT_SUCCESS,
            UPDATE_PMOL_TIME_REGISTRATION_COMMENT_FAIL
        ],
        payload: {
            request: {
                url: UPDATE_PMOL_TIME_REGISTRATION_COMMENT_EP,
                method: 'POST',
                data: data
            }
        }
    };
};

export const readPmolCommentCard = (data: any) => {
    return {
        types: [
            READ_PMOL_COMMENT_CARD,
            READ_PMOL_COMMENT_CARD_SUCCESS,
            READ_PMOL_COMMENT_CARD_FAIL
        ],
        payload: {
            request: {
                url: READ_PMOL_COMMENT_CARD_EP,
                method: 'POST',
                data: data
            }
        }
    };
};

const formatToDropDownList = (result: any) => {
    let dropDown: any = [];
    const emptyObj = [{key: 0, text: i18n.t('selectNone')}];

    if (result) {
        dropDown = result.map((item: any) => {
            return {
                key: item?.id,
                text: item?.name,
            };
        });

        return emptyObj.concat(dropDown);
    }
    return [];
};

const sampleFormData = {
    map: {lon: '0', lat: '0'},
    worker: null,
    pmol: {},
    personComments: [
        {
            id: '156456aabcoes',
            creator: 'Dasun Kavinda',
            header: 'Dasun Kavinda',
            comments: 'abcdef',
            date: new Date(),
            time: '10:30'
        },
        {
            id: '123456abcdef',
            creator: 'Supun Costa',
            header: 'Supun Costa',
            comments: '123344',
            date: new Date(),
            time: '10:30'
        },
        {
            id: '1234as5scdef',
            creator: 'Nimesh Priyankara',
            header: 'Nimesh Priyankara',
            comments: '123344',
            date: new Date(),
            time: '10:30'
        },

    ],
    pmolComments: [
        {
            id: '1564kl785bcoes',
            creator: 'Dasun Kavinda',
            header: 'PMOL-11587',
            activityType: '1',
            activityName: 'sample activityName 01',
            comments: 'abcdef',
            date: new Date(),
            time: '10:30'
        }
    ],
};

const sampleTrMapData = [
    {
        pmolId: "9ee5d40b-b73b-47a2-a306-f7e7636b",
        endTime: "2023-02-15T05:38:49",
        startTime: "2023-02-15T05:35:06",
        typeId: "3f8ce-f268-4ce3-9f12-fa6b3adad2cf9d1", //work type
        executionDate: "02/01/2023 00:00:00",
        startPoint: "Mickiesoft",
        endPoint: "Mickiesoft",
        cpcId: "f36afdbc-90a2-4d2b-808f-669a4ebd0d2c",
        cpcTitle: "37. Ford - Custum",
        isDriver: false,
    }
];

const routeData = {  //ef2fc088-a215-4fdb-8620-b05038f1776c
    startPoint: [-122.130137, 47.644702],
    endPoint: [-122.3352, 47.61397],
};

const formatRouteData = (response: any) => {
    if (response) {
        return response.map((item: any) => {
            return {
                pmolId: item.id,
                pmolVehicle: item.pmolVehical,
            }
        });
    } else {
        return [];
    }
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckboxVisibility,
  DetailsListLayoutMode,
  FontIcon,
  mergeStyleSets,
  ShimmeredDetailsList,
} from '@fluentui/react';
import { useSelector } from 'react-redux';


const RfqPmolList=()=>{
  const { t } = useTranslation();
  const columns = [
    {
      key: 'column1',
      name: t('pmolTitle'),
      fieldName: 'pmolTitle',
      minWidth: 150,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('status'),
      fieldName: 'status',
      minWidth: 50,
      maxWidth: 50,
      isResizable: false,
      onRender: (item: any) => {
        return item && <FontIcon
          aria-label="status"
          iconName={item?.status?'Accept':'Cancel'}
          style={{ margin: 5, cursor: 'pointer' }}
        />
      }
    },
    {
      key: 'column3',
      name: t('message'),
      fieldName: 'message',
      minWidth: 300,
      isResizable: true,
    },
  ];
  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
      minHeight:  210,
    },
  });
  const { isRfqGenerated, generatedRfqData } = useSelector(
    (state: any) => state.projectDPlan
  );

  return  <div className={classNames.wrapper}>
    <div className={'scrollablePane'}>
      <ShimmeredDetailsList
        items={generatedRfqData?.pmolResults?generatedRfqData?.pmolResults:[]}
        columns={columns}
        compact={true}
        setKey="pmolList"
        enableShimmer={false}
        checkboxVisibility={CheckboxVisibility.hidden}
        ariaLabelForSelectionColumn="Toggle selection"
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        checkButtonAriaLabel="Row checkbox"
        layoutMode={DetailsListLayoutMode.fixedColumns}

      />
    </div>
    <div className={'card-footer'}></div>
  </div>
}

export default RfqPmolList
import React from 'react';
import CustomCalender from './customCalender';
import {useSelector} from 'react-redux';

const MultipleCalendar = () => {
    const {
        productList,
    } = useSelector(
      (state: any) => state.thAutomation
    );

    return <div style={{display: 'flex', height: "100vh"}}>
        {
          productList && productList.map((item: any, index: any) => {
              return <CustomCalender key={'MultipleCalendar'+index} index={index} isLast={(index + 1 === productList.length)} item={item}/>
          })
        }
    </div>
};

export default MultipleCalendar

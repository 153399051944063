import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Dropdown,
    FontIcon,
    FontWeights,
    getTheme,
    ICommandBarItemProps,
    IconButton,
    IIconProps,
    IStackItemStyles,
    IStackStyles,
    IStackTokens,
    Label,
    mergeStyleSets,
    Modal,
    PrimaryButton,
    Stack,
} from '@fluentui/react';
import {useId} from '@uifabric/react-hooks';
import {uPrinceTheme} from '../../../../theme';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {getContractingUnit, getProject, stringToColour} from '../../../shared/util';
import {Field, Form} from 'react-final-form';
import history from '../../../history';
import ProgressBar from '../../../shared/progressBar/progressBar';
import {
    clearTrFormData,
    createNewComment,
    readPmolByPerson,
    readPmolVehiclePosition,
    readTotalsForTrModal,
    readTrAppDataByPerson,
    readVTSDataByPerson
} from '../../../reducers/timeRegistrationReducer';
import moment from 'moment';
import {ProjectMolecule} from '../../../types/projectMolecule';
import HistoryBar from '../../../shared/historyBar/historyBar';
import CalendarComponent from "./trCalendar/component";
import DirectionalMap from '../../../shared/location/DirectionMap/component';
import EventBody from '../../projectDayPlanning/listPane/eventBody';
import _ from "lodash";
import CommentComponent from "./trComments/component";
import {MapRouteData} from "../../../types/timeRegistration";

const theme = getTheme();

const classNames = mergeStyleSets({
    fullWidth: {
        width: '100%',
    },
    azureMap: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    iconClass: {
        fontSize: 16,
        height: 16,
        width: 16,
        margin: '0 8px',
    },
    label: {
        display: 'flex',
        alignItems: 'center',
    }
});

const stackStyles: IStackStyles = {
    root: {
        padding: 0,
    },
};

const stackItemStyles: IStackItemStyles = {
    root: {
        display: 'flex',
        height: 50,
        paddingBottom: 10,
        flexGrow: 1,
        width: 0,
    },
};
const cancelIcon: IIconProps = {iconName: 'Cancel'};

const addWhiteIconButtonStyles = {
    root: {
        color: uPrinceTheme.palette.white,
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const addIconWhite: IIconProps = {
    iconName: '',
    styles: addWhiteIconButtonStyles,
};

const contentStyles = mergeStyleSets({
    header: [
        // eslint-disable-next-line deprecation/deprecation
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '2px 2px 2px 10px',
            backgroundColor: uPrinceTheme.palette.themePrimary,
            fontSize: 18,
            color: 'white',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 0px 24px',
        overflowY: 'overlay',
        height: '100%',
        minWidth: '40vw',
        minHeight: '20vh',
        maxHeight: '90vh',
        selectors: {
            p: {margin: '14px 0'},
            'p:first-child': {marginTop: 0},
            'p:last-child': {marginBottom: 0},
        },
        footer: [
            // eslint-disable-next-line deprecation/deprecation
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '0px 24px 14px 24px',
                textAlign: 'end',
            },
        ]
    }
});

const cancelIconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
        color: 'white',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
};

const TRMapModal = (props: { formData: ProjectMolecule }) => {
    const {formData} = props;
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const titleId = useId('title');
    const store = useStore();

    const [eventList, setEventList] = useState<any>([]);
    const [defaultPoint, setDefaultPoint] = useState<any>({lat: 4.54, lon: 50.80});
    const [totals, setTotals] = useState<any>([]);
    const [mapRouteData, setMapRouteData] = useState<MapRouteData>({locations: [], color: '#2272B9'});

    const {contractingUnits} = useSelector((state: any) => state.cuProject);

    const {userInfo} = useSelector((state: any) => state.uprince);

    const {selectedCu} = useSelector((state: any) => state.projectDPlan);

    const {
        openTRMapModal,
        mapModalData,
        personDropdownData,
        trPmolList,
        trAppListData,
        isTrLoading,
        directionData,
        vtsListData,
    } = useSelector((state: any) => state.timeRegistration);

    const getCu = (item: any) => {
        let cu: any = contractingUnits.find(
          (element: any) => element.sequenceCode === item
        );

        if (cu) {
            return cu;
        } else {
            return null;
        }
    };

    useEffect(() => {
        // console.log("~ file: component.tsx:248 ~ useMount ~ formData", JSON.stringify(formData));
    }, [formData]);

    useEffect(() => {
        const pmolList = trPmolList?.map((list: any) => {
            const resourceId = 1;
            return {
                id: list?.id,
                title: list?.title,
                start: formatTimeForPmol(list.executionDate, list.executionStartTime),
                end: formatTimeForPmol(list.executionDate, list.executionEndTime),
                resourceId,
                teamId: list?.teamId,
                projectMoleculeId: list?.projectMoleculeId,
                projectSequenceCode: list?.projectSequenceCode,
                contractingUinit: list?.contractingUinit,
                executionDate: moment(list?.executionDate).format('YYYY/MM/DD'),
                typeId: list?.typeId,
                pmolVehical: list?.pomlVehical,
                pmolTool: list?.pomlTool,
                statusId: list?.statusId,
                projectTitle: list?.projectTitle,
                forecast: list?.forecast,
                executionStartTime: list?.executionStartTime,
                executionEndTime: list?.executionEndTime,
                productTaxonomy: list?.productTaxonomy
            };
        });

        const vtsList = vtsListData?.map((list: any) => {
            const resourceId = 2;
            // console.log('list>>>',list);
            return {
                id: list?.pmolId,
                title: list?.cpcTitle ? list?.cpcTitle : "",
                start: formatTimeForPmol(list.executionDate, moment(list.startTime).format('HH:mm').toString()),
                end: formatTimeForPmol(list.executionDate, moment(list.endTime).format('HH:mm').toString()),
                resourceId,
                executionDate: moment(list?.executionDate).format('YYYY/MM/DD'),
                isDriver: list?.isDriver,
                cpcId: list?.cpcId,
                typeId: list?.typeId,
                // startPoint: {lat, lon}, destination: {lat, lon}
            };
        });

        const trAppList = trAppListData?.map((list: any) => {
            const resourceId = 3;
            // console.log('stringToColour>>>',stringToColour(list.cpcTitle));
            return {
                id: list?.pmolId,
                title: list?.cpcTitle ? list?.cpcTitle : "",
                start: formatTimeForPmol(list.executionDate, moment(list.startTime).format('HH:mm').toString()),
                end: formatTimeForPmol(list.executionDate, moment(list.endTime).format('HH:mm').toString()),
                resourceId,
                executionDate: moment(list?.executionDate).format('YYYY/MM/DD'),
                isDriver: list?.isDriver,
                cpcId: list?.cpcId,
                typeId: list?.typeId,
                // startPoint: {lat, lon}, destination: {lat, lon}
            };
        });

        const list = [...(pmolList || []), ...(vtsList || []), ...(trAppList || [])];

        // const fullList = pmolList?.concat(trAppList);
        // const list = fullList?.concat(vtsList);

        // console.log('fullList>>> ',list);
        setEventList(list);
    }, [trPmolList, trAppListData, vtsListData,]);

    useEffect(() => {
        if (directionData && directionData.length > 0) {
            directionData.map((item: any) => {
                item.pmolVehicle.map((vehicle: any) => {
                    if (vehicle) {
                        // console.log(vehicle.positions[0]);

                        const start = [vehicle.positions[0].longitude, vehicle.positions[0].latitude];
                        const last = vehicle.positions.slice(-1);
                        const end = [last[0].longitude, last[0].latitude];

                        const locations = vehicle.positions.map((pos: any) => [pos.longitude, pos.latitude]);
                        // console.log({locations});
                        setMapRouteData({
                            locations: locations,
                            color: stringToColour(vehicle.coperateProductCatalogId)
                        });
                    }
                });
            });
        }
    }, [directionData])

    const onSubmit = async (values: any) => {
        const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
        await sleep(300);

        let cu: any = null;
        if (getProject()) {
            cu = getContractingUnit() ? getCu(getContractingUnit()) : selectedCu ? selectedCu : {
                ...userInfo?.contractingUnit,
                sequenceCode: userInfo?.contractingUnit?.sequnceCode
            };
        } else {
            cu = selectedCu ? selectedCu : {
                ...userInfo?.contractingUnit,
                sequenceCode: userInfo?.contractingUnit?.sequnceCode
            };
        }

        setTimeout(() => {
            // setShowProgressBar(false);
            // history.push(`/CU/${cu?.sequenceCode}/ciaw`);
        }, 1000)
    };

    const getInitialFormValues = (formData: any, formValue: any) => {
        if (formData) {
            return {
                map: formData?.map,
                worker: formData?.worker,
                personComments: formData?.personComments,
            };
        }

        return formValue;
    };

    const getDefaultPosition = (position: any) => {
        return {
            lat: position?.lat == '0' ? '50.8024632' : position?.lat,
            lon: position?.lon == '0' ? '4.54833984' : position?.lon,
        };
    };

    const customResourceHeader = ({label, index, resource}: any) => {
        return <div
          style={{
              width: '100%',
              minHeight: '70px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
          }}>{resource?.resourceTitle}</div>
    };

    const customEvent = ({event}: any) => {
        const _overflowItems: ICommandBarItemProps[] = [
            {
                key: 'open', text: t('open'), onClick: () => {
                }, iconProps: {iconName: 'MoveToFolder'}
            },
            {
                key: 'create', text: t('createTransportPMOL'), onClick: () => {
                }, iconProps: {iconName: 'Add'}
            },
        ];

        return (
          // <TdDropBox.DropBox
          //   item={event}
          //   day={selectedDate}
          //   type={[AcceptType.TEAM, AcceptType.VEHICLE, AcceptType.TOOL]}
          //   isPmol={true}
          // >
          <EventBody
            pmol={event}
            overflowItems={_overflowItems}
            handlePmolStatusChange={(data: any) => {
            }}  //handlePmolStatusChange(data)}
          />
          // </TdDropBox.DropBox>
        );
    };

    const handleIsExpaned = () => {

        return false;
    };

    const formatTimeForPmol = (date: string, time: any) => {
        let formatted = moment(date).format('YYYY/MM/DD')
        let dateOnly = (formatted === 'Invalid date') ? '' : formatted.toString();
        if (dateOnly && time) {
            let t = time ? time : '';
            let dateTime = dateOnly + " " + t;
            return moment(dateTime).toDate();
        } else {
            return moment.utc(date).local().toDate();
        }
    };

    const onModalClose = () => {
        dispatch(clearTrFormData());
    };

    const onChangeDropdown = (value: any) => {
        let executionDate: any;
        let cabPersonId: any;

        if (value?.key === 0) {
            executionDate = undefined;
            cabPersonId = null;
        } else {
            executionDate = formData?.executionDate;
            cabPersonId = value?.key;
        }

        dispatch(readPmolByPerson({
            executionDate: executionDate,
            cabPersonId: cabPersonId
        }));
        dispatch(readTrAppDataByPerson({
            executionDate: executionDate,
            cabPersonId: cabPersonId
        }));
        dispatch(readVTSDataByPerson({
            executionDate: executionDate,
            cabPersonId: cabPersonId
        }));

        if (!_.isNull(cabPersonId)) {
            dispatch(readPmolVehiclePosition({
                executionDate: executionDate,
                personId: cabPersonId
            }));
        } else {
            setDefaultPoint({lat: 4.54, lon: 50.80});
            setMapRouteData({locations: [], color: '#2272B9'});
        }

        Promise.all([
            dispatch(readTotalsForTrModal({
                executionDate: executionDate,
                cabPersonId: cabPersonId
            }))
        ]).then((response: any) => {
            if (response && response[0].payload?.data?.result.length > 0) {
                const totalData = {
                    total: 8,
                    totalVTS: 2,
                    totalTrApp: 6,
                    totalHr: 0,
                };
                setTotals(totalData);
            } else {
                setTotals(null);
            }
        });
    };

    return (
      <div>
          <Form
            onSubmit={onSubmit}
            initialValues={getInitialFormValues(mapModalData ? mapModalData : null, null)}
            mutators={{
                setNewComment: (args, state, utils) => {
                    const field = state.fields.comments;
                    // console.log("rgs", args[0]);
                    field.change(args[0]);
                },
            }}
            render={({
                         handleSubmit,
                         form,
                         submitting,
                         pristine,
                         values
                     }) => {
                // @ts-ignore
                const {executionDate} = props.formData;
                return (
                  <form onSubmit={handleSubmit} noValidate>
                      <div>
                          <Modal
                            styles={{scrollableContent: {maxHeight: '100%'}}}
                            titleAriaId={titleId}
                            isOpen={openTRMapModal}
                            onDismiss={() => {
                                onModalClose();
                                form.reset();
                            }}
                            // scrollableContentClassName="body"
                            isBlocking={false}
                            dragOptions={undefined}
                          >
                              <div className={contentStyles.header}>
                                  <span id={titleId}>{t('geoTrackingDataVisualization')}</span>
                                  <IconButton
                                    styles={cancelIconButtonStyles}
                                    iconProps={cancelIcon}
                                    ariaLabel="Close popup modal"
                                    onClick={() => {
                                        onModalClose();
                                        form.reset();
                                    }}
                                  />
                              </div>
                              <ProgressBar show={isTrLoading}/>

                              <div className={contentStyles.body}>
                                  <div className="proj-detail-content inner">
                                      <div className="ms-Grid-row">
                                          <Stack
                                            horizontal
                                            wrap
                                            styles={stackStyles}
                                            tokens={stackTokens}
                                          >
                                              <div className={classNames.azureMap}>
                                                  <DirectionalMap
                                                    defaultPoint={defaultPoint}
                                                    mapRouteData={mapRouteData}
                                                  />
                                              </div>
                                          </Stack>

                                          <Stack
                                            horizontal
                                            wrap
                                            styles={stackStyles}
                                            tokens={stackTokens}
                                          >
                                              <Stack.Item grow={6} styles={stackItemStyles}>
                                                  <div className={`${classNames.fullWidth}`}>
                                                      <Field name="worker">
                                                          {({input, meta}) => (
                                                            <Dropdown
                                                              options={personDropdownData}
                                                              // label={t('activityType')}
                                                              onChange={(e, value) => {
                                                                  onChangeDropdown(value);
                                                                  input.onChange(value?.key);
                                                              }}
                                                              placeholder={t('selectWorker')}
                                                              disabled={false}
                                                              selectedKey={values?.worker}
                                                              style={{marginRight: '1rem', width: '100%'}}
                                                            />
                                                          )}
                                                      </Field>
                                                  </div>
                                              </Stack.Item>
                                              <Stack.Item grow={6} styles={stackItemStyles}>
                                                  <div className={`${classNames.fullWidth}`}>
                                                      <PrimaryButton
                                                        iconProps={addIconWhite}
                                                        text={t('addComment')}
                                                        disabled={false}
                                                        style={{width: '100%'}}
                                                        onClick={() => {
                                                            // console.log(values.worker);
                                                            if (!_.isNull(values.worker) && values.worker != 0) {
                                                                dispatch(createNewComment(
                                                                  {
                                                                      pmolId: formData?.id,
                                                                      cabPersonId: values.worker
                                                                  }));
                                                            } else {
                                                                dispatch(createNewComment(
                                                                  {
                                                                      pmolId: formData?.id,
                                                                      cabPersonId: null
                                                                  }));
                                                            }
                                                        }}
                                                      />
                                                  </div>
                                              </Stack.Item>
                                          </Stack>

                                          <div style={{marginRight: 8}}>
                                              <CalendarComponent
                                                executionDate={executionDate}
                                                eventList={eventList}
                                                totalsData={totals}
                                              />
                                          </div>

                                          <div>
                                              <CommentComponent
                                                values={values}
                                                formData={formData}
                                              />
                                          </div>

                                          <div>
                                              <div className={classNames.label}>
                                                  <FontIcon aria-label="History" iconName="History"
                                                            className={classNames.iconClass}/>
                                                  <Label>{t('history')}</Label>
                                              </div>
                                              <HistoryBar
                                                createdByUser={
                                                  formData?.historyLog?.createdByUser || ''
                                                }
                                                updatedByUser={
                                                  formData?.historyLog?.updatedByUser || ''
                                                }
                                                createdDateTime={
                                                  formData?.historyLog?.createdDateTime || ''
                                                }
                                                updatedDateTime={
                                                  formData?.historyLog?.updatedDateTime || ''
                                                }
                                              />
                                          </div>
                                      </div>
                                  </div>

                                  {/*<pre>{JSON.stringify(props.formData)}</pre>*/}
                              </div>
                          </Modal>
                      </div>
                  </form>
                );
            }}
          />
      </div>
    );
};

export default TRMapModal;
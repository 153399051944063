import {v4 as uuidv4} from 'uuid';
import {
  AWARD_TENDER_EP,
  CONTRACT_SAVE_EP,
  CONTRACTOR_LOT_EXCEL_UPLOAD_EP,
  CONTRACTOR_PUBLISH_TENDER_EP,
  CONTRACTOR_SEND_INVITATION_EP,
  DELETE_CONSTRUCTOR_EP,
  GET_CONSTRUCTORS_DATA_EP,
  LOT_CBC_UPLOAD_EP,
  LOT_FILE_UPLOAD_EP,
  READ_CONSTRUCTOR_BY_ID_EP,
  READ_CONTRACT_BY_ID_EP,
  READ_CONTRACT_DROPDOWN_EP,
  READ_CONTRACT_LIST_EP,
  READ_CONTRACT_SHORTCUT_PANE_EP,
  UPDATE_CONTRACTOR_WORKFLOW_EP,
  UPLOAD_CONTRACTOR_PDF_EP,
  UPLOAD_INSTRUCTION_DOC_EP,
} from '../shared/endpoints';
import {
  CLEAR_CONTRACT_FORM_DATA,
  CLEAR_CONTRACTUID,
  CONTRACTOR_AWARD_TENDER,
  CONTRACTOR_AWARD_TENDER_FAIL,
  CONTRACTOR_AWARD_TENDER_SUCCESS,
  CONTRACTOR_LOT_EXCEL_UPLOAD,
  CONTRACTOR_LOT_EXCEL_UPLOAD_FAIL,
  CONTRACTOR_LOT_EXCEL_UPLOAD_SUCCESS,
  CONTRACTOR_PUBLISH_TENDER,
  CONTRACTOR_PUBLISH_TENDER_FAIL,
  CONTRACTOR_PUBLISH_TENDER_SUCCESS,
  CONTRACTOR_SEND_INVITATION,
  CONTRACTOR_SEND_INVITATION_FAIL,
  CONTRACTOR_SEND_INVITATION_SUCCESS,
  DELETE_CONSTRUCTOR,
  DELETE_CONSTRUCTOR_FAIL,
  DELETE_CONSTRUCTOR_SUCCESS,
  GET_CONSTRUCTOR_DATA,
  GET_CONSTRUCTOR_DATA_FAIL,
  GET_CONSTRUCTOR_DATA_SUCCESS,
  LOAD_CONSTRUCTOR_BY_ID,
  LOAD_CONSTRUCTOR_BY_ID_FAIL,
  LOAD_CONSTRUCTOR_BY_ID_SUCCESS,
  LOAD_CONTRACT_BY_ID,
  LOAD_CONTRACT_BY_ID_FAIL,
  LOAD_CONTRACT_BY_ID_SUCCESS,
  LOAD_CONTRACT_DROPDOWN,
  LOAD_CONTRACT_DROPDOWN_FAIL,
  LOAD_CONTRACT_DROPDOWN_SUCCESS,
  LOAD_CONTRACT_LSITPANE,
  LOAD_CONTRACT_LSITPANE_FAIL,
  LOAD_CONTRACT_LSITPANE_SUCCESS,
  LOAD_CONTRACT_SHORTCUTPANE,
  LOAD_CONTRACT_SHORTCUTPANE_FAIL,
  LOAD_CONTRACT_SHORTCUTPANE_SUCCESS,
  LOAD_CONTRACTOR_LIST,
  LOAD_CONTRACTOR_LIST_FAIL,
  LOAD_CONTRACTOR_LIST_SUCCESS,
  LOT_FILE_UPLOAD,
  LOT_FILE_UPLOAD_FAIL,
  LOT_FILE_UPLOAD_SUCCESS,
  RESET_CONTRACT_IS_CHANGE,
  SAVE_CONTRACT,
  SAVE_CONTRACT_FAIL,
  SAVE_CONTRACT_STATE_ATTR,
  SAVE_CONTRACT_SUCCESS,
  SAVE_CONTRACTOR_WORKFLOW,
  SAVE_CONTRACTOR_WORKFLOW_FAIL,
  SAVE_CONTRACTOR_WORKFLOW_SUCCESS,
  SEND_INVITATION_TO_CONTRACTOR,
  SEND_INVITATION_TO_CONTRACTOR_FAIL,
  SEND_INVITATION_TO_CONTRACTOR_SUCCESS,
  SET_CONTRACT_UID,
} from '../actionTypes/contractActionTypes';
import {format24hDateTime} from '../shared/util';
import {
  CBC_EXCEL_UPLOAD,
  CBC_EXCEL_UPLOAD_FAIL,
  CBC_EXCEL_UPLOAD_SUCCESS,
  CBC_PDF_UPLOAD,
  CBC_PDF_UPLOAD_FAIL,
  CBC_PDF_UPLOAD_SUCCESS,
  UPLOAD_INSTRUCTION_DOC,
  UPLOAD_INSTRUCTION_DOC_FAIL,
  UPLOAD_INSTRUCTION_DOC_SUCCESS,
} from '../actionTypes/projectBreakdownType';
import {CONTRACT, CONTRACTOR} from '../types/contract';
import i18n from '../../i18n';


const defaultState: any = {
  isContractListLoaded: false,
  isNew: false,
  isChange: false,
  isEdit: false,
  isClear: false,
  isLoadingContract: false,
  isLoadingConstructor: false,
  showProgressBar: false,
  contractDropDowns: {},
  formData: {},
  uid: null,
  loadMsg: '',
  contractShortCutPaneData: [],
  reloadListPane: false,
  borResources: {},
  contractId: null,
  contractorList: {},
  accreditationsList: {},
  instructionUploadStatus: true,
  uploadedInstructionLink: null,
  selectedLotType: CONTRACT,
  contractList: [],
  constructorFormData: {},
  contractors: [],
  lotExcelUploaded: false,
  excelUploaded: false,
  pdfUploaded: false,
  taxonomyData: false,
  sequenceId: null,
  tenderPublished: false,
  tenderSelection: [],
  tenderAwarded: false,
  readById: false,
  isInviteSend: false,
};

export default function contractReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {
      case LOAD_CONTRACT_SHORTCUTPANE:
        return { ...state };
      case LOAD_CONTRACT_SHORTCUTPANE_SUCCESS:
        return {
          ...state,
          contractShortCutPaneData: action.payload.data.result,
        };
      case LOAD_CONTRACT_SHORTCUTPANE_FAIL:
        return { ...state, contractShortCutPaneData: [] };
      case LOAD_CONTRACT_LSITPANE:
        return { ...state, isContractListLoaded: false };
      case LOAD_CONTRACT_LSITPANE_SUCCESS:
        return {
          ...state,
          isContractListLoaded: true,
          contractList: formattedPayloadToList(action.payload.data.result),
          loadMsg: !action.payload.data.status
            ? action.payload.data.message
            : '',
        };
      case LOAD_CONTRACT_LSITPANE_FAIL:
        return {
          ...state,
          isContractListLoaded: true,
          contractList: [],
          loadMsg: action.error.response.data.message,
        };
      case RESET_CONTRACT_IS_CHANGE:
        return {
          ...state,
          isChange: false,
        };

      case LOAD_CONTRACT_DROPDOWN:
        return { ...state, contractDropDowns: {} };
      case LOAD_CONTRACT_DROPDOWN_SUCCESS:
        return { ...state, contractDropDowns: action.payload.data.result };
      case LOAD_CONTRACT_DROPDOWN_FAIL:
        return {
          ...state,
          contractDropDowns: {},
        };

      case LOAD_CONTRACT_BY_ID:
        return {
          ...state,
          formData: {},
          showProgressBar: true,
          isLoadingContract: true,
          pdfUploaded: false,
          isPdfDataLoaded: false,
          excelUploaded: false,
          isDataLoaded: false,
          lotExcelUploaded: false,
        };
      case LOAD_CONTRACT_BY_ID_SUCCESS:
        return {
          ...state,
          isClear: false,
          isEdit: true,
          formData: formattedPayload(action.payload.data.result),
          showProgressBar: false,
          isLoadingContract: false,
          isInviteSend: false,
        };
      case LOAD_CONTRACT_BY_ID_FAIL:
        return {
          ...state,
          formData: {},
          showProgressBar: false,
          isLoadingContract: false,
        };

      case LOAD_CONSTRUCTOR_BY_ID:
        return {
          ...state,
          constructorFormData: {},
          showProgressBar: true,
          isLoadingConstructor: true,
          pdfUploaded: false,
          isPdfDataLoaded: false,
          excelUploaded: false,
          isDataLoaded: false,
          lotExcelUploaded: false,
        };
      case LOAD_CONSTRUCTOR_BY_ID_SUCCESS:
        return {
          ...state,
          isEdit: true,
          constructorFormData: action.payload.data.result,
          showProgressBar: false,
          isLoadingConstructor: false,
          isInviteSend: false,
        };
      case LOAD_CONSTRUCTOR_BY_ID_FAIL:
        return {
          ...state,
          constructorFormData: {},
          showProgressBar: false,
          isLoadingConstructor: false,
        };

      case SAVE_CONTRACT:
        return {
          ...state,
          showProgressBar: true,
          reloadListPane: false,
          contractId: null,
        };
      case SAVE_CONTRACT_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          reloadListPane: true,
          contractId: action.payload.data.result,
        };
      case SAVE_CONTRACT_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
          contractId: action.payload.data.result,
        };

      case SAVE_CONTRACTOR_WORKFLOW:
        return {
          ...state,
          showProgressBar: true,
          reloadListPane: false,
          contractorId: null,
        };
      case SAVE_CONTRACTOR_WORKFLOW_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          reloadListPane: true,
          contractorId: action.payload.data.result,
        };
      case SAVE_CONTRACTOR_WORKFLOW_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
          contractorId: action.payload.data.result,
        };

      case LOAD_CONTRACTOR_LIST:
        return { ...state, showProgressBar: true, reloadListPane: false };
      case LOAD_CONTRACTOR_LIST_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          reloadListPane: true,
          contractorList: action.payload.data.result,
        };
      case LOAD_CONTRACTOR_LIST_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
          contractorList: {},
        };

      case SET_CONTRACT_UID:
        return {
          ...state,
          isEdit: false,
          uid: uuidv4(),
        };
      case CLEAR_CONTRACTUID:
        return {
          ...state,
          uid: null,
          isChange: false,
        };
      case CLEAR_CONTRACT_FORM_DATA:
        return {
          ...state,
          contractId: null,
          isEdit: false,
          uid: null,
          isNew: true,
          isChange: false,
          formData: {},
          pdfUploaded: false,
          isPdfDataLoaded: false,
          excelUploaded: false,
          isDataLoaded: false,
          lotExcelUploaded: false,
        };

      case UPLOAD_INSTRUCTION_DOC:
        return { ...state, uploadedInstructionLink: null, instructionUploadStatus: false, showProgressBar: true };
      case UPLOAD_INSTRUCTION_DOC_SUCCESS:
        return {
          ...state,
          instructionUploadStatus: true,
          uploadedInstructionLink: action.payload.data.result,
          showProgressBar: false,
        };
      case UPLOAD_INSTRUCTION_DOC_FAIL:
        return {
          ...state,
          instructionUploadStatus: true,
          uploadedInstructionLink: null,
          showProgressBar: false,
        };

      case CBC_EXCEL_UPLOAD:
        return {
          ...state,
          showProgressBar: true,
          lotExcelUploaded: false,
          isDataLoaded: false,
        };
      case CBC_EXCEL_UPLOAD_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
          lotExcelUploaded: true,
          isDataLoaded: true,
        };
      case CBC_EXCEL_UPLOAD_FAIL:
        return {
          ...state,
          showProgressBar: false,
          lotExcelUploaded: true,
          isDataLoaded: true,
        };

      // eslint-disable-next-line no-case-declarations
      case SAVE_CONTRACT_STATE_ATTR:
        const attr = Object.keys(action)[1];
        const value = Object.values(action)[1];
        return {
          ...state,
          [attr]: value,
        };

      case GET_CONSTRUCTOR_DATA:
        return {
          ...state,
        };
      case GET_CONSTRUCTOR_DATA_SUCCESS:
        return {
          ...state,
          contractors: action.payload.data.result,
        };
      case GET_CONSTRUCTOR_DATA_FAIL:
        return {
          ...state,
        };

      case DELETE_CONSTRUCTOR:
        return {
          ...state,
        };
      case DELETE_CONSTRUCTOR_SUCCESS:
        return {
          ...state,
          contractors: action.payload.data.result,
        };
      case DELETE_CONSTRUCTOR_FAIL:
        return {
          ...state,
        };

      case CONTRACTOR_LOT_EXCEL_UPLOAD:
        return {
          ...state,
          showProgressBar: true,
          excelUploaded: false,
          isDataLoaded: false,
        };
      case CONTRACTOR_LOT_EXCEL_UPLOAD_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
          excelUploaded: true,
          isDataLoaded: true,
        };
      case CONTRACTOR_LOT_EXCEL_UPLOAD_FAIL:
        return {
          ...state,
          showProgressBar: false,
          excelUploaded: true,
          isDataLoaded: true,
        };

      case CBC_PDF_UPLOAD:
        return {
          ...state,
          showProgressBar: true,
          pdfUploaded: false,
          isPdfDataLoaded: false,
        };
      case CBC_PDF_UPLOAD_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
          pdfUploaded: true,
          isPdfDataLoaded: true,
        };
      case CBC_PDF_UPLOAD_FAIL:
        return {
          ...state,
          showProgressBar: false,
          pdfUploaded: true,
          isPdfDataLoaded: true,
        };

      case CONTRACTOR_SEND_INVITATION:
        return {
          ...state,
          showProgressBar: true,
          sequenceId: null,
        };
      case CONTRACTOR_SEND_INVITATION_SUCCESS:
        return {
          ...state,
          sequenceId: action.payload.data.result,       //used in a useEffect in contract>documentPane>component
          showProgressBar: false,
        };
      case CONTRACTOR_SEND_INVITATION_FAIL:
        return {
          ...state,
          showProgressBar: false,
          sequenceId: null,
        };

      case CONTRACTOR_PUBLISH_TENDER:
        return {
          ...state,
          showProgressBar: true,
        };
      case CONTRACTOR_PUBLISH_TENDER_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
          tenderPublished: true,
        };
      case CONTRACTOR_PUBLISH_TENDER_FAIL:
        return {
          ...state,
          showProgressBar: false,
          tenderPublished: true,
        };

      case CONTRACTOR_AWARD_TENDER:
        return {
          ...state,
          showProgressBar: true,
        };
      case CONTRACTOR_AWARD_TENDER_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
          tenderAwarded: true,
        };
      case CONTRACTOR_AWARD_TENDER_FAIL:
        return {
          ...state,
          showProgressBar: false,
        };

      case SEND_INVITATION_TO_CONTRACTOR:
        return {
          ...state,
          showProgressBar: true,
          isInviteSend: false,
        };
      case SEND_INVITATION_TO_CONTRACTOR_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
          isInviteSend: true,
        };
      case SEND_INVITATION_TO_CONTRACTOR_FAIL:
        return {
          ...state,
          showProgressBar: false,
          isInviteSend: false,
        };

      case LOT_FILE_UPLOAD:
        return {
          ...state,
          uploadedInstructionLink: null,
          instructionUploadStatus: false,
          showProgressBar: true
        };
      case LOT_FILE_UPLOAD_SUCCESS:
        return {
          ...state,
          instructionUploadStatus: true,
          uploadedInstructionLink: action.payload.data.result,
          showProgressBar: false,
        };
      case LOT_FILE_UPLOAD_FAIL:
        return {
          ...state,
          instructionUploadStatus: true,
          uploadedInstructionLink: null,
          showProgressBar: false,
        };

      default:
        return state;
    }
  }
}

export const readContractShortCutPaneData = () => {
  return {
    types: [
      LOAD_CONTRACT_SHORTCUTPANE,
      LOAD_CONTRACT_SHORTCUTPANE_SUCCESS,
      LOAD_CONTRACT_SHORTCUTPANE_FAIL,
    ],
    payload: { request: { url: READ_CONTRACT_SHORTCUT_PANE_EP } },
  };
};

export const readContractDropDownData = () => {
  return {
    types: [
      LOAD_CONTRACT_DROPDOWN,
      LOAD_CONTRACT_DROPDOWN_SUCCESS,
      LOAD_CONTRACT_DROPDOWN_FAIL,
    ],
    payload: { request: { method: 'GET', url: READ_CONTRACT_DROPDOWN_EP } },
  };
};


export const filterContractListPaneData = (filter: any) => {
  return {
    types: [
      LOAD_CONTRACT_LSITPANE,
      LOAD_CONTRACT_LSITPANE_SUCCESS,
      LOAD_CONTRACT_LSITPANE_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_CONTRACT_LIST_EP,
        data: filter,
      },
    },
  };
};

export const readByContractId = (id: string) => {
  return {
    types: [
      LOAD_CONTRACT_BY_ID,
      LOAD_CONTRACT_BY_ID_SUCCESS,
      LOAD_CONTRACT_BY_ID_FAIL],
    payload: { request: { url: READ_CONTRACT_BY_ID_EP + id } },
  };
};

export const readByConstructorId = (id: string) => {
  return {
    types: [
      LOAD_CONSTRUCTOR_BY_ID,
      LOAD_CONSTRUCTOR_BY_ID_SUCCESS,
      LOAD_CONSTRUCTOR_BY_ID_FAIL],
    payload: { request: { url: READ_CONSTRUCTOR_BY_ID_EP + id } },
  };
};

export const uploadCBCExcelFile = (data: any) => {
  return {
    types: [CBC_EXCEL_UPLOAD, CBC_EXCEL_UPLOAD_SUCCESS, CBC_EXCEL_UPLOAD_FAIL],
    payload: {
      request: {
        url: LOT_CBC_UPLOAD_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const uploadCBCPdfFile = (data: any) => {
  return {
    types: [CBC_PDF_UPLOAD, CBC_PDF_UPLOAD_SUCCESS, CBC_PDF_UPLOAD_FAIL],
    payload: {
      request: {
        url: UPLOAD_CONTRACTOR_PDF_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const resetIsChange = () => {
  return { type: RESET_CONTRACT_IS_CHANGE };
};

export const saveFormData = (formData: any) => {
  return {
    types: [
      SAVE_CONTRACT,
      SAVE_CONTRACT_SUCCESS,
      SAVE_CONTRACT_FAIL],
    payload: {
      request: {
        url: CONTRACT_SAVE_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const updateContractorWorkflow = (constructorFormData: any) => {
  return {
    types: [
      SAVE_CONTRACTOR_WORKFLOW,
      SAVE_CONTRACTOR_WORKFLOW_SUCCESS,
      SAVE_CONTRACTOR_WORKFLOW_FAIL],
    payload: {
      request: {
        url: UPDATE_CONTRACTOR_WORKFLOW_EP,
        method: 'POST',
        data: constructorFormData,
      },
    },
  };
};

export const setUid = () => {
  return { type: SET_CONTRACT_UID };
};

export const clearFormData = () => {
  return { type: CLEAR_CONTRACT_FORM_DATA };
};

export function saveContractStateAttr(attr: string, value: any) {
  return {
    type: SAVE_CONTRACT_STATE_ATTR,
    [attr]: value,
  };
}

export const uploadInstructionDoc = (formData: any) => {  //
  return {
    types: [UPLOAD_INSTRUCTION_DOC, UPLOAD_INSTRUCTION_DOC_SUCCESS, UPLOAD_INSTRUCTION_DOC_FAIL],
    payload: {
      request: {
        url: UPLOAD_INSTRUCTION_DOC_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const getConstructorsData = (taxonomies: string[]) => {
  return {
    types: [
      GET_CONSTRUCTOR_DATA,
      GET_CONSTRUCTOR_DATA_SUCCESS,
      GET_CONSTRUCTOR_DATA_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: GET_CONSTRUCTORS_DATA_EP,
        data: taxonomies,
      },
    },
  };
};

export const deleteConstructor = (data: any) => {
  return {
    types: [
      DELETE_CONSTRUCTOR,
      DELETE_CONSTRUCTOR_SUCCESS,
      DELETE_CONSTRUCTOR_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: DELETE_CONSTRUCTOR_EP,
        data: data,
      },
    },
  };
};

export const contractorLotExcelUpload = (data: any) => {
  return {
    types: [
      CONTRACTOR_LOT_EXCEL_UPLOAD,
      CONTRACTOR_LOT_EXCEL_UPLOAD_SUCCESS,
      CONTRACTOR_LOT_EXCEL_UPLOAD_FAIL,
    ],
    payload: {
      request: {
        url: CONTRACTOR_LOT_EXCEL_UPLOAD_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const sendLotInvitation = (data: any) => {
  return {
    types: [
      CONTRACTOR_SEND_INVITATION,
      CONTRACTOR_SEND_INVITATION_SUCCESS,
      CONTRACTOR_SEND_INVITATION_FAIL,
    ],
    payload: {
      request: {
        url: CONTRACTOR_SEND_INVITATION_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const lotPublishTender = (data: any) => {
  return {
    types: [
      CONTRACTOR_PUBLISH_TENDER,
      CONTRACTOR_PUBLISH_TENDER_SUCCESS,
      CONTRACTOR_PUBLISH_TENDER_FAIL,
    ],
    payload: {
      request: {
        url: CONTRACTOR_PUBLISH_TENDER_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const lotAwardTender = (data: any) => {
  return {
    types: [
      CONTRACTOR_AWARD_TENDER,
      CONTRACTOR_AWARD_TENDER_SUCCESS,
      CONTRACTOR_AWARD_TENDER_FAIL,
    ],
    payload: {
      request: {
        url: AWARD_TENDER_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const sendContractorInvitation = (data: any) => {
  return {
    types: [
      SEND_INVITATION_TO_CONTRACTOR,
      SEND_INVITATION_TO_CONTRACTOR_SUCCESS,
      SEND_INVITATION_TO_CONTRACTOR_FAIL,
    ],
    payload: {
      request: {
        url: CONTRACTOR_SEND_INVITATION_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const lotFileUpload = (formData: any) => {
  return {
    types: [LOT_FILE_UPLOAD, LOT_FILE_UPLOAD_SUCCESS, LOT_FILE_UPLOAD_FAIL],
    payload: {
      request: {
        url: LOT_FILE_UPLOAD_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

const formattedPayload = (response: any) => {
  const obj: any = response.publishTender;
  const result = Object.keys(obj).map((key) => {
    let x = i18n.t(key);
    if (key === 'measuringStatus') {
      obj[key] = obj[key] === '0.0' ? i18n.t('notPublished') : `V${obj[key]}`;
    }
    if (key === 'invitationMail') {
      obj[key] = response.isInviteSend;
      obj[key] = obj[key] ? i18n.t('OK') : i18n.t('false');
    }
    return { 'tenderText': x, 'tenderStatus': obj[key] };
  });

  return {
    ...response,
    publishTender: result,
  };
};

const formattedPayloadToList = (response: any) => {
  if (response) {
    let data = response.map((item: any) => {
      if (item?.type == CONTRACTOR) {
        return {
          ...item,
          title: `\xa0\xa0\xa0${item.title}`,
          statusChangeDate: format24hDateTime(item.statusChangeDate),
          // price: item.price ? numberToLocaleString(item.price): item.price
        };
      } else {
        return {
          ...item,
          statusChangeDate: format24hDateTime(item.statusChangeDate),
          // price: item.price ? numberToLocaleString(item.price): item.price
        };
      }
    });
    return data;
  } else {
    return [];
  }
};

import React from 'react';
import { FontWeights, IconButton, IIconProps, Modal } from '@fluentui/react';
import { getTheme, ITheme, mergeStyleSets, registerIcons } from '@fluentui/react/lib/Styling';
import { uPrinceTheme } from '../../../theme';
import { SvgIcons } from '../svgIcons/svgIcons';

registerIcons(SvgIcons);

const theme: ITheme = getTheme();
const { palette, fonts } = theme;


const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      minWidth: '75vw',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '75vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#C4C4C4',
    height: 40,
    marginLeft: 'auto',
  },
  subHeaderBtn: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    borderBottom: '1px solid #C4C4C4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: { color: theme.palette.neutralDark },
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const DocumentPreviewModal = (props: {
  openImageModal: () => any,
  isOpen: boolean,
  image: string | null
  type: string
}) => {
  // const [transition, setTransition]: any = useState(false);
  // useEffect(() => {
  //     setTransition(props.transition);
  // }, [props.transition]);


  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        onDismiss={() => {
          props.openImageModal();
        }}
        scrollableContentClassName="body"
        isBlocking={false}
        // className={ 'grid-righter column-filter-activated' }
      >

        {props.type === 'image' ?
          <div id="myModal" style={{ display: props.isOpen ? 'block' : 'none' }} className={'imageModal'}>
            <span className="imageModalClose" onClick={() => {
              props.openImageModal();
            }}>&times;</span>
            {props.image && <img className="imageModal-content" id="img01" src={props.image ? props.image : ''}/>}
          </div> : <div className="ms-Grid" dir="ltr" style={{ width: '95vw', height: '95vh' }}>
            <div className={contentStyles.header}>
              <IconButton
                styles={cancelIconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel="Close popup modal"
                onClick={() => {
                  props.openImageModal();
                }}
              />
            </div>
            <iframe style={{ width: '94vw', height: '89vh' }} width={'94vw'} height={'89vh'}
                    src={props.image ? props.image : ''}/>
          </div>}
      </Modal>
    </div>
  );

};

export default DocumentPreviewModal;

import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Label,
  Popover,
  PopoverProps,
  PopoverSurface,
  PopoverTrigger,
  PopoverTriggerChildProps,
} from '@fluentui/react-components';
import { AddCircle24Regular } from '@fluentui/react-icons/lib/fonts';
import { Tag, TagGroup, TagGroupProps } from '@fluentui/react-tags-preview';
import PopoverBody from './PopoverBody';
import i18n from 'i18next';

interface PeopleTagProps {
  to: any;
  cc: any;
  onToChange: any;
  onCCChange: any;
}

const PeopleTags = (props: PeopleTagProps) => {
  const { to, cc, onToChange, onCCChange } = props;
  const { t } = useTranslation();

  const [visibleTags, setVisibleTags]: any = React.useState([]);
  const [visibleCCTags, setVisibleCCTags]: any = React.useState([]);
  const [visible, setVisible] = React.useState(false);

  const formatValueForTag = (value: any) => {
    if (value && value?.length > 0) {
      return value?.map((tag: any) => {
        return { ...tag, key: tag?.id ? tag?.id : tag?.personName, name: tag?.personName };
      });
    } else {
      return [];
    }
  };
  const formatTagForValue = (tags: any) => {
    if (tags && tags?.length > 0) {
      return tags?.map((tag: any) => {
        return { id: tag?.id, personName: tag?.name, personId: tag?.personId };
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    setVisibleTags(formatValueForTag(to));
    setVisibleCCTags(formatValueForTag(cc));
  }, [to, cc]);

  // useEffect(() => {
  //   onToChange(formatTagForValue(visibleTags))
  // }, [visibleTags]);
  //
  // useEffect(() => {
  //   onCCChange(formatTagForValue(visibleCCTags))
  // }, [visibleCCTags]);

  const removeItem: TagGroupProps['onDismiss'] = (_e, { dismissedTagValue }) => {
    console.log({ dismissedTagValue });
    setVisibleTags([...visibleTags].filter((tag: any) => tag.key !== dismissedTagValue));
    onToChange(formatTagForValue([...visibleTags].filter((tag: any) => tag.key !== dismissedTagValue)));
  };

  const removeCCItem: TagGroupProps['onDismiss'] = (_e, { dismissedTagValue }) => {
    setVisibleCCTags([...visibleCCTags].filter((tag: any) => tag.key !== dismissedTagValue));
    onCCChange(formatTagForValue([...visibleCCTags].filter((tag: any) => tag.key !== dismissedTagValue)));
  };

  const CustomPopoverTrigger = React.forwardRef<
    HTMLButtonElement,
    Partial<PopoverTriggerChildProps>
  >((props, ref) => {
    return (
      <span style={{ cursor: 'default' }} {...props} ref={ref}>
         <AddCircle24Regular style={{ marginTop: 10, marginLeft: 10 }} />
      </span>
    );
  });

  const getPersonDetails = (persons: any, tagType: any) => {
    if (persons && persons?.length >= 0) {
      let selected = persons.map((person: any) => {
        return { id: person?.id ?? person?.key, personId: person?.key, personName: person?.text };
      });
      if (tagType?.key === 'to') {
        console.log([...to, ...selected]);
        return [...to, ...selected];
      } else {
        return [...cc, ...selected];
      }
    } else {
      return [];
    }
  };

  const handleOpenChange: PopoverProps['onOpenChange'] = (e, data) => setVisible(data.open || false);

  const onAddPerson = (tagType: any, persons: any) => {
    if (persons && persons?.length) {
      if (tagType?.key === 'to') {
        onToChange(getPersonDetails(persons, tagType));
      } else {
        onCCChange(getPersonDetails(persons, tagType));
      }
      setVisible(false);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ marginTop: 10, marginRight: 10 }}>
          <Label size='medium' weight='semibold'> {t('people')} </Label>
        </div>
        <Popover open={visible} onOpenChange={handleOpenChange}>
          <PopoverTrigger disableButtonEnhancement>
            <CustomPopoverTrigger />
          </PopoverTrigger>

          <PopoverSurface>
            <>
              <PopoverBody
                onAddPerson={(tagType: any, persons: any) => {
                  onAddPerson(tagType, persons);
                }}
                formData={{
                  to: visibleTags.map((tag: any) => ({ ...tag, key: tag?.personId })),
                  cc: visibleCCTags.map((tag: any) => ({ ...tag, key: tag?.personId })),
                }}
                btnLabel={i18n.t('add')}
              />
            </>
          </PopoverSurface>
        </Popover>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ marginTop: 10, marginRight: 10 }}><Label> {t('to')} </Label></div>
        <TagGroup style={{ marginTop: 5, width: '100%', overflow: 'overlay' }} onDismiss={removeItem}
                  aria-label='Dismiss example'>
          {visibleTags.map((tag: any) => (
            <Tag dismissible dismissIcon={{ 'aria-label': 'remove' }} value={tag.key} key={tag.key}>
              {tag?.name}
            </Tag>
          ))}
        </TagGroup>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ marginTop: 10, marginRight: 10 }}><Label> {t('cc')} </Label></div>
        <TagGroup style={{ marginTop: 5, width: '100%', overflow: 'overlay' }} onDismiss={removeCCItem}
                  aria-label='Dismiss example'>
          {visibleCCTags.map((tag: any) => (
            <Tag dismissible dismissIcon={{ 'aria-label': 'remove' }} value={tag.key} key={tag.key}>
              {tag?.name}
            </Tag>
          ))}
        </TagGroup>
      </div>
    </>
  );
};

export default PeopleTags;
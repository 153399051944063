import { IColumn, Selection } from '@fluentui/react';
import _ from 'lodash';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  deleteAddressBook,
  getAddressBookList,
  getExportCABs,
  getNoCabCopmanies,
} from '../../../reducers/addressBookReducer';
import { messageService } from '../../../services/messageService';
import UprinceLogger from '../../../shared/Logger/logger';
import { excelExport } from '../../../shared/util';
import { AddressBookItem, CABFilter, Person, PersonCompany } from '../../../types/addressBookItem';
import history from './../../../history';
import { ListPaneComponent } from './component';
import ConfirmationDialog from '../../../shared/confirmationDialog/confirmationDialog';
import groupArray from 'group-array';

interface State {
  cabFilter: CABFilter;
  announcedMessage?: string;
  columns: IColumn[];
  deleteCABSuccess: boolean;
  confimationDialogVisibility: boolean;
  cabType: string;
  noCabCompanies: [];
}

interface Props extends WithTranslation {
  addressBook: AddressBookItem[];
  getAddressBookList: any;
  isCABLoading: boolean;
  deleteCABSuccess: boolean;
  deleteAddressBook: any;
  cabType: string;
  loadCABStatus: boolean;
  loadCABMessage: string;
  history: any;
  getNoCabCopmanies: any;
  noCabCompanyStatus: boolean;
  noCabCompanies: [];
  getExportCABs: any;
  exportCabs: AddressBookItem[];
  exportCabStatus: boolean;
}

class AddressBookListPane extends Component<Props, State> {
  private _columns: IColumn[];
  subscription: any;
  private _selection: Selection;

  constructor(props: Props) {
    super(props);
    const { t } = this.props;
    this._selection = new Selection({
      onSelectionChanged: () => {
        if (this._selection.getSelection().length > 0) {
          if (this._selection.getSelection().length === 1) {
            let addressBook = this._selection.getSelection()[0] as AddressBookItem;
            history.push(`/address-book/${addressBook.person.id}`);
          }
        } else {
          messageService.sendMessage({ enableRemoveButton: false });
        }
        UprinceLogger.log(
          '_internetContactSelectiononSelectionChanged',
          this._selection.getSelection(),
        );
      },
    });
    this._columns = [
      {
        key: 'column1',
        name: t('fullName'),
        fieldName: 'fullName',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        isRowHeader: true,
        data: 'string',
        isSortedDescending: false,
        isSorted: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column2',
        name: t('jobTitle'),
        fieldName: 'jobRole',
        minWidth: 150,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column3',
        name: t('organisation'),
        fieldName: 'organisation',
        minWidth: 150,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column4',
        name: t('email'),
        fieldName: 'email',
        minWidth: 150,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column5',
        name: t('mobile'),
        fieldName: 'mobile',
        minWidth: 150,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column6',
        name: t('status'),
        fieldName: 'status',
        minWidth: 150,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
    ];
    this.state = {
      cabFilter: new CABFilter(),
      announcedMessage: '',
      columns: this._columns,
      deleteCABSuccess: false,
      confimationDialogVisibility: true,
      cabType: '',
      noCabCompanies: [],
    };
  }

  componentDidMount() {
    this.props.getNoCabCopmanies(null);
    this.setState(
      (prevState) => ({
        cabFilter: {
          ...prevState.cabFilter,
          cabPersonSortingModel: {
            attribute: 'fullName',
            order: 'asc',
          },
        },
      }),
      () => {
        this.props.getAddressBookList(this.state.cabFilter);
      },
    );
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data && data.data && data.data.isReloadCabList) {
          this.props.getAddressBookList(this.state.cabFilter);
        }

        if (data && data.data && data.data.addressBookExport) {
          this.props.getExportCABs();
          // excelExport(
          //   this.formatCABList(this.props.addressBook),
          //   this.props.t('addressBook'),
          //   this.props.t('addressBook')
          // );
        }
        if (data && data.data && data.data.removeAddress) {
          this.setState({ confimationDialogVisibility: false });
        }
        if (data && data.data && data.data.isNewCAB) {
          this._selection.setAllSelected(false);
        }
        if (data && data.data && data.data.removedPerson) {
          this._selection.setAllSelected(false);
        }
      } else {
      }
    });
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  private getAddressBookItemToBeDeleted = () => {
    return this._selection.getSelection().map((item: any) => {
      return item.person.id;
    });
  };

  static getDerivedStateFromProps(
    nextProps: Props,
    prevState: {
      deleteCABSuccess: boolean;
    },
  ) {
    return {
      deleteCABSuccess: nextProps.deleteCABSuccess,
      cabType: nextProps.cabType,
      loadCABMessage: nextProps.loadCABMessage,
      loadCABStatus: nextProps.loadCABStatus,
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.deleteCABSuccess !== this.props.deleteCABSuccess) {
      messageService.sendMessage({
        isNewCAB: true,
      });
      this.props.getAddressBookList(this.state.cabFilter);
    }

    if (prevProps.cabType != this.props.cabType) {
      this.setState(
        (prevState) => ({
          cabFilter: {
            ...new CABFilter(),
            cabPersonSortingModel: {
              attribute:
                this.props.cabType === 'organisation'
                  ? this.props.cabType
                  : 'fullName',
              order: 'asc',
            },
          },
          cabType:
            this.props.cabType === 'organisation' ? this.props.cabType : '',
        }),
        () => {
          if (this.state.cabType === 'organisation') {
            this._columns[2].isSorted = true;
            this._columns[0].isSorted = false;
          } else {
            this._columns[2].isSorted = false;
            this._columns[0].isSorted = true;
          }

          this._selection.setAllSelected(false);
          this.props.getAddressBookList(this.state.cabFilter);
        },
      );
    }

    if (prevProps.noCabCompanyStatus != this.props.noCabCompanyStatus) {
      this.setState({ noCabCompanies: this.props.noCabCompanies });
    }

    if (
      prevProps.exportCabStatus != this.props.exportCabStatus &&
      this.props.exportCabStatus
    ) {
      excelExport(
        this.formatCABList(this.props.exportCabs),
        this.props.t('addressBook'),
        this.props.t('addressBook'),
      );
    }
  }

  private onChangeCABFilter = (field: string) => (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    this.setState(
      (prevState) => ({
        cabFilter: {
          ...prevState.cabFilter,
          [field]: newValue ? newValue : null,
        },
      }),
      () => {
        if (newValue != null && newValue!!.length >= 3) {
          this.props.getAddressBookList(this.state.cabFilter);
        } else if (newValue === '') {
          this.props.getAddressBookList(this.state.cabFilter);
        }
        if (this.props.cabType === 'organisation') {
          if (field === 'organisation' && newValue != null && newValue!!.length >= 3) {
            this.props.getNoCabCopmanies(newValue);
          } else if (field === 'organisation' && newValue === '') {
            this.props.getNoCabCopmanies(newValue);
          }
        }
      },
    );
  };

  checkProperties(cabFilter: any) {
    const { isSaved, cabPersonSortingModel, ...result } = cabFilter;
    let isEmpty = !Object.values(result).some((x) => x !== null && x !== '');

    return isEmpty;
  }

  private onChangeCABFilterStatus = (field: string) => (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    item?: any,
  ) => {
    this.setState(
      (prevState) => ({
        cabFilter: {
          ...prevState.cabFilter,
          isSaved: item
            ? item.key === 2
              ? null
              : item.key === 0
                ? false
                : true
            : null,
        },
      }),
      () => {
        this.props.getAddressBookList(this.state.cabFilter);
      },
    );
  };

  private onAddressItemClick = (item: AddressBookItem) => {
    //history.push(`/address-book/${item.person.id}`);
    let title: string | null = '';
    if (item && item.company && item.company.name) {
      title = item.person.fullName + ' - ' + item.company.name;
    } else {
      title = item.person.fullName;
    }
    messageService.sendMessage({
      showdocumentPane: true,
      cabId: item.person.id,
      title: title,
      enableRemoveButton: true,
    });
  };

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn,
  ): void => {
    if (this.state.cabType !== 'organisation') {
      const newColumns: IColumn[] = this.state.columns.slice();
      const currColumn: IColumn = newColumns.filter(
        (currCol) => column.key === currCol.key,
      )[0];
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
          this.setState({
            announcedMessage: `${currColumn.name} is sorted ${
              currColumn.isSortedDescending ? 'descending' : 'ascending'
            }`,
          });
          let fieldName = currColumn.fieldName ? currColumn.fieldName : null;
          fieldName = fieldName === 'jobRole' ? 'jobTitle' : fieldName;
          fieldName = fieldName === 'mobile' ? 'mobileNumber' : fieldName;
          fieldName = fieldName === 'status' ? 'isSaved' : fieldName;
          let sortby = currColumn.isSortedDescending ? 'desc' : 'asc';
          this.setState(
            (prevState) => ({
              cabFilter: {
                ...prevState.cabFilter,
                cabPersonSortingModel: {
                  attribute: fieldName,
                  order: sortby,
                },
              },
            }),
            () => {
              this.props.getAddressBookList(this.state.cabFilter);
            },
          );
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });
    }
  };

  onRemoveConfirm = () => {
    this.setState({ confimationDialogVisibility: true });
    this.props.deleteAddressBook(this.getAddressBookItemToBeDeleted());
  };

  onRemoveCancel = () => {
    this.setState({ confimationDialogVisibility: true });
  };

  filterSaveedItems = (item: AddressBookItem) => {
    return item.isSaved;
  };

  private formatCABList = (cabEntries: AddressBookItem[]) => {
    let data: any[] = [];
    if (_.isArray(cabEntries)) {
      data = cabEntries.filter(this.filterSaveedItems);
      data = data.map((item: AddressBookItem) => {
        return {
          'Full Name':
            item.person && item.person.fullName ? item.person.fullName : '-',
          'Job Title':
            item.personCompany && item.personCompany.jobRole
              ? item.personCompany.jobRole
              : '-',
          Organisation:
            item.company && item.company.name ? item.company.name : '-',
          Email:
            item.personCompany && item.personCompany.email
              ? item.personCompany.email
              : '-',
          Mobile:
            item.personCompany && item.personCompany.mobileNumber
              ? item.personCompany.mobileNumber
              : '-',
        };
      });
    }
    return data;
  };

  getCompanyCABs = () => {
    let data: AddressBookItem[] = [];
    if (this.props.addressBook) {
      data = this.props.addressBook.filter((item) => {
        if (item.company && item.company.name) {
          item.company.name = this.capitalizeFirstLetter(item.company.name);
          item.company.id = item.personCompany.companyId;
          return item;
        }
      });
    }

    return data;
  };

  capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  getNoCabCompaniesGrops = () => {
    let groups: any[] = [];
    if (
      !_.isNil(this.state.noCabCompanies) &&
      _.isArray(this.state.noCabCompanies)
    ) {
      groups = this.state.noCabCompanies.map((item: any, index: number) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
    }
    return groups;
  };

  getGroups = () => {
    let groups,
      groups2: {
        count: any;
        key: any;
        name: any;
        startIndex: number;
        level: number;
        children: never[];
      }[] = [];
    if (this.props.addressBook && this.state.cabType === 'organisation') {
      let gp: any = groupArray(this.getCompanyCABs(), 'company.name');
      let gp2: any = groupArray(this.getNoCabCompaniesGrops(), 'name');
      let companyNames = Object.keys(gp).sort(function(a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });

      let companyNames2 = Object.keys(gp2).sort(function(a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });

      let x = 0;
      groups = companyNames.map((item: any, index: number) => {
        if (index != 0) {
          x = x + gp[companyNames[index - 1]].length;
        }
        return {
          count: gp[item].length,
          key: item + index,
          name: item,
          startIndex: x,
          level: 0,
          children: [],
        };
      });
      let isOrgNameAvailable = this.state.cabFilter && this.state.cabFilter.organisation && this.state.cabFilter.organisation.length > 0;
      if (this.checkProperties(this.state.cabFilter) || isOrgNameAvailable) {
        let y = 0;
        groups2 = companyNames2.map((item: any, index: number) => {
          y = x;
          this.props.addressBook.push(
            new AddressBookItem(new Person(), null, new PersonCompany()),
          );
          let c = {
            count: 1,
            key: item + index,
            name: item,
            startIndex: y + 1,
            level: 0,
            children: [],
          };

          groups.push(c);
          return c;
        });
      }
    }
    return groups;
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <ListPaneComponent
          columns={this._columns}
          selection={this._selection}
          //addressList={this.props.addressBook ? this.props.addressBook : []}
          addressList={
            this.state.cabType === 'organisation'
              ? this.getCompanyCABs()
              : this.props.addressBook
              ? this.props.addressBook
              : []
          }
          onAddressItemClick={this.onAddressItemClick}
          isLoading={this.props.isCABLoading}
          cabFilter={this.state.cabFilter}
          onChangeCABFilter={this.onChangeCABFilter}
          onChangeCABFilterStatus={this.onChangeCABFilterStatus}
          status={this.props.loadCABStatus}
          message={this.props.loadCABMessage}
          cabType={this.props.cabType}
          groups={this.getGroups()}
        />

        <ConfirmationDialog
          hidden={this.state.confimationDialogVisibility}
          title={t('areyousure')}
          subText={t('youwonTbeabletorevertthis')}
          onClickConfirmDialog={this.onRemoveConfirm}
          onClickCancelDialog={this.onRemoveCancel}
          confirmButtonText={t('yesDeleteit')}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any, props: any) => {
  return {
    status: state.addressBook.status,
    message: state.addressBook.message,
    isCABLoading: state.addressBook.isCABLoading,
    addressBook: state.addressBook.addressBook,
    deleteCABSuccess: state.addressBook.deleteCABSuccess,
    loadCABMessage: state.addressBook.loadCABMessage,
    loadCABStatus: state.addressBook.loadCABStatus,
    noCabCompanies: state.addressBook.noCabCompanies,
    noCabCompanyStatus: state.addressBook.noCabCompanyStatus,
    exportCabs: state.addressBook.exportCabs,
    exportCabStatus: state.addressBook.exportCabStatus,
  };
};

const mapDispatchToProps = {
  getAddressBookList,
  deleteAddressBook,
  getNoCabCopmanies,
  getExportCABs,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AddressBookListPane),
);

import React from 'react';
import {
  getTheme,
  IIconProps,
  IImageProps,
  Image,
  ImageFit,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../theme';

const theme = getTheme();

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};


const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

// Styles definition
const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const stackItemStylesColThree: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
  },
};

const classNames = mergeStyleSets({
  fullWidth: {
    width: '100%',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  bigScreenTree: {
    marginBottom: 30,
    height: '30vh',
  },
  smallScreenTree: {
    marginBottom: 30,
    height: '56vh',
  },
  textEditor: {
    height: window.innerWidth <= 1280 ? '50vh' : '25vh',
  },
});

const stackFooterBarItemStyles: IStackItemStyles = {
  root: {
    height: 45,
  },
};
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };


const ProjectHeader = (props: {
  projectDetails: any;
}) => {
  const { t } = useTranslation();
  const imageProps: IImageProps = {
    src: (props.projectDetails && props.projectDetails.image) ? props.projectDetails.image : 'http://placehold.it/500x250',
    imageFit: ImageFit.none,
    width: 50,
    height: 50,
  };
  //const [contractIsRequired, setcontractIsRequired] = useState(false);
  return (
    <div className={'document-pane-card'}>
      <div className={'card-body'}>
        <div className="proj-detail-content inner">
          <div className={'ms-Grid'} style={{ width: '100%', height: '100%' }}>
            <div className="ms-Grid-row" style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
              <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 ">
                <Image
                  {...imageProps}
                  //src="http://placehold.it/100x100"
                  style={{ width: 50, height: 50, borderRadius: 50 / 2 }}
                />
              </div>
              <div style={{ marginLeft: 20 }} className="ms-Grid-col ms-sm10 ms-md10 ms-lg10 ">
                <Label
                  style={{ fontSize: 17, color: uPrinceTheme.palette.themePrimary }}
                  disabled={true}
                >
                  {props.projectDetails && props.projectDetails.title} {props.projectDetails && props.projectDetails.company && ' - ' + props.projectDetails.company}
                </Label>
                <Label
                  style={{ fontSize: 14 }}
                  disabled={true}
                >
                  {props.projectDetails && props.projectDetails.pmName} {props.projectDetails && props.projectDetails.mobileNumber && ' - ' + props.projectDetails.mobileNumber}
                </Label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default ProjectHeader;

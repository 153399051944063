import {AzureMapsProvider} from 'react-azure-maps';
import React from 'react';
import MapController from './mapController';
import {MapRouteData} from "../../../types/timeRegistration";

interface Props {
    defaultPoint: any,
    mapRouteData: MapRouteData,
};

const DirectionalMap = (props: Props) => {
    const {defaultPoint, mapRouteData} = props;

    return (
      <AzureMapsProvider>
          <>
              <MapController defaultPoint={defaultPoint} mapRouteData={mapRouteData}/>
          </>
      </AzureMapsProvider>
    );
}

export default DirectionalMap;
import { DirectionalHint, Label, Link, Stack, Text, TooltipHost } from '@fluentui/react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { iconColors, uPrinceTheme } from '../../../../theme';
import { VPShortCutPaneItem } from '../../../types/visualPlan';
import { filterVisualPlanListPaneData, saveVPStateAttr } from '../../../reducers/visualPlanReducer';

const classNames = mergeStyleSets({
  wrapper: {
    height: 'auto !important',
    position: 'relative',
    minHeight: '400px !important',
  },
  selected: {
    fontWeight: 'bold',
    color: '#854114!important',
  },
});


export const ShortCutPaneComponent = (props: {
  toggleOverlay: () => void;
  toggleOverlayFwd: () => void;
  divClass: string;
  shortCutPaneFilters: any;
  currentActiveSection: string | null;
  handelShortCutPaneFilter: (selectedItem: VPShortCutPaneItem) => void;
  resetFilter: () => void;
}) => {


  const shortCutPaneData = useSelector((state: any) => state.vp?.vpShortCutPaneData)
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentActiveSection, setCurrentActiveSection] =  useState<string|null>(null);
  const setFilterOption = (selectedItem: VPShortCutPaneItem) => {
    if (selectedItem.type === 'all') {
      props.resetFilter();
    }
    dispatch(saveVPStateAttr('selectedPOVPshortcutpaneItems', [selectedItem.id]));
    dispatch(saveVPStateAttr('selectedStartDateCu', null));
    dispatch(saveVPStateAttr('selectedEndDateCu', null));
    dispatch(saveVPStateAttr('selectedPOResourceTypes', []));
    dispatch(saveVPStateAttr('selectedPOProject', null));
    dispatch(saveVPStateAttr('vpCUSelectedShortcutpaneType', selectedItem.value));
    
    if(selectedItem.id === '07') {
      setCurrentActiveSection('08')
    } else {
      setCurrentActiveSection(selectedItem.id)
    }
    props.handelShortCutPaneFilter(selectedItem);
  };


  const getItemIcon = (item: any) => {
    let icon = 'GotoToday';
    switch (item) {
      case 1:
        icon = 'Org';
        break;
      case 2:
        icon = 'Calculator';
        break;
      case 3:
        icon = 'ReturnKey';
        break;
      case 4:
        icon = 'ActivateOrders';
        break;
      case 5:
        icon = 'RowsChild';
        break;
      case 6:
        icon = 'DeliveryTruck';
        break;
      case 7:
        icon = 'RowsChild';
        break;
      case 8:
        icon = 'RowsChild';
        break;

    }

    return icon;
  };
  const renderFilterItems = () => {
    
    if (shortCutPaneData && Array.isArray(shortCutPaneData)) {
      return shortCutPaneData?.map((item: any, index: number) => {
        let isSub = false;
        let divider = false;
        if (index === 5 || index === 6 ) {
          isSub = true;
        }
        return (
          <div key={item.id + 'div'}>
            {divider && (
              <hr
                key={item.id + 'hr'}
                style={{ marginBottom: 15, borderTop: '1px solid #d2d2d2' }}
              />
            )}
            <Link
              key={item.id + 'link'}
              className={`${currentActiveSection === item.id
                ? classNames.selected
                : ''
              }`}
              href="#"
              onClick={() => {
                setFilterOption(item);
              }}
              style={{marginLeft: isSub ? 20 : 0 }}
            >
              <TooltipHost
                key={item.id + 't'}
                content={item.name}
                id="filter-opt-1"
                calloutProps={{ gapSpace: 0 }}
                directionalHint={DirectionalHint.rightCenter}
              >
                <i
                  key={item.id + 'i'}
                  // className={`ms-Icon ms-Icon--${getItemIcon(item.value)}`}
                  aria-labelledby="filter-opt-1"
                  aria-hidden="true"
                  style={
                    currentActiveSection === item.id
                      ? { color: iconColors.iconActiveColor }
                      : { color: uPrinceTheme.palette.themePrimary }
                  }
                ></i>
              </TooltipHost>
              <i
                key={item.id + 'i2'}
                className={`ms-Icon ms-Icon--${getItemIcon(item.value)}`}
                aria-hidden="true"
                style={
                  currentActiveSection === item.id
                    ? { color: iconColors.iconActiveColor }
                    : { color: uPrinceTheme.palette.themePrimary }
                }
              ></i>
              <Text  style={
                currentActiveSection === item.id
                  ? { color: iconColors.iconActiveColor }
                  : { color: uPrinceTheme.palette.black }
              } key={item.id}>{t(item.name)} </Text>
            </Link>
          </div>
        );
      });
    }
    return <div></div>;
  };

  return (
    <div className={`${props.divClass} ${classNames.wrapper}`}>
      <div className="inner-container" style={{ position: 'sticky', top: 0 }}>
        <i
          onClick={props.toggleOverlay}
          className="ms-Icon ms-Icon--Back back-arrow"
          aria-hidden="true"
        ></i>
        <i
          onClick={props.toggleOverlayFwd}
          className="ms-Icon ms-Icon--Forward fwd-arrow"
          aria-hidden="true"
        ></i>

        <div className="filter-data filter-title">
          <TooltipHost
            content={t('tax')}
            // This is the important part!
            id="filter-opt-title"
            calloutProps={{ gapSpace: 0 }}
            directionalHint={DirectionalHint.rightCenter}
          >
            <Label
              aria-describedby={'filter-opt-title'}
              className={'short-cut-pane-label'}
            >
              {t('purchaseOrder')}
            </Label>
          </TooltipHost>
        </div>

        <div className="filter-option-list">
          <Stack
            gap={15}
            verticalFill
            styles={{
              root: {
                width: '100%',
                verticalAlign: 'center',
              },
            }}
          >
            <Link
              key={'All-link'}
              className={`${currentActiveSection === null ? classNames.selected : ''
              }`}
              href="#"
              onClick={() => {
                props.handelShortCutPaneFilter({
                  key: null,
                  id: null,
                  name: null,
                  type: null,
                  value: null,
                });

                dispatch(saveVPStateAttr('selectedPOVPshortcutpaneItems', []));
                dispatch(saveVPStateAttr('selectedStartDateCu', null));
                dispatch(saveVPStateAttr('selectedEndDateCu', null));
                dispatch(saveVPStateAttr('selectedPOResourceTypes', []));
                dispatch(saveVPStateAttr('selectedPOProject', null));
                dispatch(saveVPStateAttr('vpCUSelectedShortcutpaneType', null));
                setCurrentActiveSection(null)
                dispatch(filterVisualPlanListPaneData({
                  requestType: [],
                  resourceType: [],
                  fromDate: null,
                  toDate: null,
                }));
                //setFilterOption([]);
              }}
            >
              <TooltipHost
                key={'All-link1'}
                content={t('PO')}
                id="filter-opt-1"
                calloutProps={{ gapSpace: 0 }}
                directionalHint={DirectionalHint.rightCenter}
              >
                <i
                  key={'All-linki'}
                  className="ms-Icon ms-Icon--ViewAll"
                  aria-labelledby="filter-opt-1"
                  aria-hidden="true"
                  style={
                    currentActiveSection === null
                      ? { color: iconColors.iconActiveColor }
                      : { color: uPrinceTheme.palette.themePrimary }
                  }
                ></i>
              </TooltipHost>
              <i
                key={'All-linki2'}
                className="filter-icon ms-Icon ms-Icon--ViewAll filter-icon"
                aria-hidden="true"
                style={
                  currentActiveSection === null
                    ? { color: iconColors.iconActiveColor }
                    : { color: uPrinceTheme.palette.themePrimary }
                }
              ></i>
              <Text style={
                currentActiveSection === null
                  ? { color: iconColors.iconActiveColor }
                  : { color: uPrinceTheme.palette.black }
              } key="all"> {t('all')}</Text>
            </Link>
            {renderFilterItems()}
          </Stack>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShortCutPaneComponent);

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { messageService } from '../../../../services/messageService';

const WbsCuTaskDocumentPaneCommandBar = () => {
  const {t} = useTranslation();

  const getItems = () => {
    return [
      {
        key: 'save',
        text: t('save'),
        iconProps: {iconName: 'Save'},
        onClick: () => {
          //saveWbsTask
          messageService.sendMessage({
            saveWbsTask: true,
          });
        },
        disabled: false
      },
      // {
      //   key: 'saveAndSend',
      //   text: t('saveAndSend'),
      //   iconProps: {iconName: 'Save'},
      //   onClick: () => {
      //
      //   },
      //   disabled: false,
      // },
    ];
  };

  return <div>
    <CommandBar
      styles={{root: {height: 26}}}
      items={getItems()}
      ariaLabel="Use left and right arrow keys to navigate between commands"
    />
  </div>
};

export default WbsCuTaskDocumentPaneCommandBar

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  DetailsListLayoutMode,
  DetailsRow,
  Dropdown,
  Fabric,
  IColumn,
  IDetailsFooterProps,
  IDetailsListProps,
  IDetailsRowStyles,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  Sticky,
  Text,
  TextField,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { RiskRegisterListItem, RiskRegisterListPaneFilter } from '../../../types/riskRegister';

export const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 25vh)!important',
    position: 'relative',
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: {
    margin: 0,
  },
  row: {
    display: 'inline-block',
  },
});

const ListPaneComponent = (props: {
  filter: RiskRegisterListPaneFilter;
  selection: any;
  columns: IColumn[];
  listPaneItems: RiskRegisterListItem[];
  handleListPaneItemClick: (id: string) => void;
  handleFilterChange: (filter: RiskRegisterListPaneFilter) => void;
  isDataLoaded?: boolean;
  loadMsg: string;
  resetSelection: () => void;
  dropDownOptions: any;
}) => {

  const { t } = useTranslation();
  const [filter, setFilter] = useState<RiskRegisterListPaneFilter>(props.filter);

  const getDropdownWithDefualt = (options: any) => {
    if (options) {
      let defualtOption = [
        {
          key: '0',
          text: t('all'),
          isDefault: true,
        },
      ];
      Array.prototype.push.apply(defualtOption, options);
      return defualtOption;
    } else {
      return [];
    }
  };

  useEffect(() => {
    setFilter(props.filter);
  }, [props.filter]);

  useEffect(() => {
    // console.log("listPaneItems", props.listPaneItems);
  }, [props.listPaneItems]);

  const _onRenderRow: IDetailsListProps['onRenderRow'] = (row) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (row) {
      return (
        <span
          style={{ textDecoration: 'none' }}
          onClick={() => {
            // chnage item id name
            // console.log("YYYYY", row.item)//change
            props.handleListPaneItemClick(row.item.sequenceCode); //change to load document pane
          }}
        >
          <DetailsRow {...row} styles={customStyles}/>
        </span>
      );
    }
    return null;
  };

  const _onRenderDetailsFooter = (
    detailsFooterProps: IDetailsFooterProps | undefined,
  ) => {

    return (
      <DetailsRow
        className="footer-as-filter"
        {...detailsFooterProps}
        columns={detailsFooterProps?.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps?.groupNestingDepth}
        selection={detailsFooterProps?.selection}
        onRenderItemColumn={_renderFilterItemColumn}
        // onRenderCheck={_onRenderCheckForFooterRow}
      />
    );

  };


  const _renderFilterItemColumn = (
    item?: RiskRegisterListItem,
    index?: number,
    column?: IColumn,
  ) => {
    switch (column?.key) {
      case 'column1': {
        return (
          <div>
            <TextField
              value={props.filter.title ? props.filter.title : ''}
              onChange={(event, value) => {
                if (value) {
                  props.filter.title = value;
                } else {
                  props.filter.title = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column2': {
        return (
          <div>
            <TextField
              // value={props.filter.sequenceCode ? props.filter.sequenceCode : ''}
              // onChange={(event, value) => {
              //   if (value) {
              //     props.filter.sequenceCode = value;
              //   } else {
              //     props.filter.sequenceCode = null;
              //   }
              //   props.handleFilterChange(props.filter);
              // }}
            />
          </div>
        );
        break;
      }
      case 'column3': {
        return (
          <div>
            <Dropdown
              placeholder={t('all')}
              options={getDropdownWithDefualt(props.dropDownOptions.riskStatus)}
              selectedKey={props.filter.stateId ? props.filter.stateId : '0'}
              onChange={(event, value) => {
                if (value) {
                  props.filter.stateId =
                    value.key != 0 ? value.key.toString() : null;
                } else {
                  props.filter.stateId = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column4': {
        return (
          <div>
            <Dropdown
              placeholder={t('all')}
              options={getDropdownWithDefualt(props.dropDownOptions.riskTypes)}
              selectedKey={props.filter.typeId ? props.filter.typeId : '0'}
              onChange={(event, value) => {
                if (value) {
                  props.filter.typeId =
                    value.key != 0 ? value.key.toString() : null;
                } else {
                  props.filter.typeId = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      // case 'column5': {
      //   return (
      //     <div>
      //       <CustomDatePicker
      //       //   value={filter?.title ? filter?.title.toString() : ''}
      //       //   onChange={(event, value) => {
      //       //     if (value) {

      //       //       setFilter((prevState) => ({
      //       //     ...prevState,
      //       //     title: value
      //       // }));
      //       //     } else {
      //       //       //props.filter.projectSequenceCode = null;
      //       //     }
      //       //   props.handleFilterChange(filter);
      //       //   }}
      //       />
      //     </div>
      //   );
      //   break;
      // }
      case 'column5': {
        return (
          <div>
            {/* <TextField
            // value={filter?.title ? filter?.title.toString() : ''}
            // onChange={(event, value) => {
            //   if (value) {

            //     setFilter((prevState) => ({
            //       ...prevState,
            //       title: value
            //     }));
            //   } else {
            //     //props.filter.projectSequenceCode = null;
            //   }
            //   props.handleFilterChange(filter);
            // }}
            /> */}
          </div>
        );
        break;
      }

      default: {
        break;
      }
    }
  };


  const displayMessage = () => {
    return (
      <div style={{ paddingTop: 36, paddingLeft: 20 }}>
        <Text>{t(props.loadMsg)}</Text>
      </div>
    );
  };
  return (
    <Fabric>
      <div className={`wrapper-holder ${classNames.wrapper}`}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <ShimmeredDetailsList
            items={props.listPaneItems}
            columns={props.columns}
            setKey="set"
            enableShimmer={!props.isDataLoaded}
            layoutMode={DetailsListLayoutMode.justified}
            selectionPreservedOnEmptyClick={true}
            checkboxVisibility={1}
            onRenderRow={_onRenderRow}
            onRenderDetailsFooter={_onRenderDetailsFooter}
            selection={props.selection}
            selectionMode={SelectionMode.single}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            onRenderDetailsHeader={(headerProps, defaultRender) => {
              if (defaultRender !== undefined) {
                return (
                  <Sticky
                    // stickyPosition={StickyPositionType.Header}
                    // isScrollSynced={true}
                    // stickyBackgroundColor="transparent"
                  >
                    <div>{defaultRender(headerProps)}</div>
                  </Sticky>
                );
              } else {
                return <span></span>;
              }
            }}
          />
          {props.isDataLoaded && displayMessage()}
        </ScrollablePane>
      </div>
    </Fabric>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    toleranceStatesFilter: state.projectBreakdown.toleranceStatesFilter,
    productStatesFilter: state.projectBreakdown.productStatesFilter,
    itemTypesFilter: state.projectBreakdown.itemTypesFilter,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ListPaneComponent);

import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import "../../../myDayPlanning/shortCutPane/datepicker.css";
import "./my-cal-pmol-shortcut-pane.css";
import {MyEnvShortcutPane} from "./myEnvShortcutPane";
import {CuShortcutPane} from "../../../cuMyCalendar/shortCutPane/topShortcutPane";

interface Props {
    divClass: string;
    horizontalReSizer: any;
}

export const PMOLShortcutPane = (props: Props) => {
    // const {contractingUnits} = useSelector(
    //   (state: any) => state.cuProject
    // );

    const {isMyEnv} = useSelector(
      (state: any) => state.dplan
    );

    useEffect(() => {

        return () => {

        }
    }, []);

    if (isMyEnv) {
        return <MyEnvShortcutPane
          divClass={props.divClass}
          horizontalReSizer={props.horizontalReSizer}
        />
    } else {
        return <CuShortcutPane
          divClass={props.divClass}
          horizontalReSizer={props.horizontalReSizer}
        />
    }
};
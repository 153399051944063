import {
  Checkbox,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Stack,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../theme';
import { TextFieldFF } from '../../../shared/fluentFinalForm';
import HistoryBar from '../../../shared/historyBar/historyBar';
import { CorporateSchedule, CorporateScheduleTime } from '../../../types/corporateSchedule';
import CSWeeklyGrid from '../../../shared/csWeekGrid/component';

const theme = getTheme();

const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };

const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
});

// const required = (value: any) => (value ? undefined : 'Required');

const validate = (values: { company: any; gender: any; email: any }) => {
  const errors: { company: string; gender: string; email: string } = {
    company: '',
    gender: '',
    email: '',
  };
  if (!values.company) {
    errors.company = 'Company Required';
  }
  if (!values.gender) {
    errors.gender = 'Gender Required';
  }
  if (!values.email) {
    errors.email = 'Email Required';
  }
  return errors;
};

const DocumentPaneComponent = (props: {
  activityTaskSelection: any;
  submit: any;
  dropDowns: any;
  saveFormData: any;
  uid: any;
  showProgress: boolean;
  formValues: any;
  formData: any;
  isExpand: boolean;
  setForm: (form: any) => void;
  onChangeStartTime: (index: number, value: string | number) => void;
  onChangeEndTime: (index: number, value: string | number) => void;
  isClear: boolean;
  corporateSheduleTime: CorporateScheduleTime[];
}) => {
  const { t } = useTranslation();
  const [state, setstate] = useState<CorporateSchedule | null>(null);
  const onSubmit = async (values: any) => {
    if (!values.id) {
      values.id = props.uid;
    }
    const corporateSheduleTime = props.corporateSheduleTime.map((item) => {
      return {
        ...item,
        corporateSheduleId: values.id,
      };
    });
    const formData = { ...values, corporateSheduleTime: corporateSheduleTime };
    props.saveFormData(formData);
  };

  useEffect(() => {
    setstate(props.formData);
  }, [props.formData]);

  const getInitialFormValues = (formData: any, formValue: any) => {
    if (location.pathname.split('/').pop() === 'new' && props.isClear) {
      return { name: null };
    } else if (formData && formData?.id) {
      return {
        id: formData?.id,
        name: formData?.name,
        sequenceId: formData?.sequenceId,
        title: formData?.title,
        isDefault: formData?.isDefault,
      };
    }
    return formValue;
  };

  return (
    <div style={{ width: '100%' }}>
      <div className="proj-detail-block">
        <Form
          onSubmit={onSubmit}
          initialValues={getInitialFormValues(props.formData, props.formValues)}
          validate={values => {
            const errors: any = { header: {} };
            if (!values?.name && !props.showProgress) {
              errors.name = t('nameRequired');
            }
            // if (!values?.header?.cpcResourceTitle && !props.showProgress) {
            //     errors.myField = t('resouceRequired');
            // }
            return errors;
          }}
          render={({ handleSubmit, form, values }) => {
            props.submit(handleSubmit);
            props.setForm(form);
            return (
              <form onSubmit={handleSubmit} noValidate>
                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="corporate-schedule-header">
                      <Label>1. {t('corporateScheduleHeader')}</Label>
                    </Link>
                  </div>
                  {state?.sequenceId && (
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="title"
                            component={TextFieldFF}
                            lable={t('corporateScheduleTitle')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="sequenceId"
                            component={TextFieldFF}
                            lable={t('corporateScheduleID')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>
                  )}

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="name"
                          component={TextFieldFF}
                          lable={t('corporateScheduleName')}
                          required={true}
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div style={{ marginTop: 35, paddingLeft: 3 }} className={`${classNames.fullWidth}`}>
                        <Field name="isDefault">
                          {({ input, meta }) => (
                            <Checkbox
                              label={t('isDefault')}
                              disabled={false}
                              checked={!!values.isDefault}
                              onChange={(event, value) => {
                                if (value) {
                                  input.onChange(true);
                                } else {
                                  input.onChange(false);
                                }
                              }}
                            />
                          )}
                        </Field>
                      </div>
                    </Stack.Item>
                  </Stack>
                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <div className={`${classNames.fullWidth}`}>
                      <CSWeeklyGrid
                        dataList={props.corporateSheduleTime}
                        link={'cs-grid'}
                        isLoaded={false}
                        selection={props.activityTaskSelection}
                        isExpand={true}
                        disabled={false}
                        onChangeStartTime={props.onChangeStartTime}
                        onChangeEndTime={props.onChangeEndTime}
                        typeId={props.formData.corporateSheduleTime?.id}
                      />
                    </div>
                  </Stack>

                </div>

                {props.formData.history && props.formData.history.createdDate && (
                  <div className="proj-detail-block" id="2">
                    <div className="document-pane-card">
                      <div className="marginTop marginBottom">
                        <Link href="#" id="history">
                          <Label>2. {t('history')} </Label>
                        </Link>

                        <HistoryBar
                          createdByUser={
                            props.formData.history.createdBy
                              ? props.formData.history.createdBy
                              : ''
                          }
                          updatedByUser={
                            props.formData.history.modifiedBy
                              ? props.formData.history.modifiedBy
                              : ''
                          }
                          createdDateTime={
                            props.formData.history.createdDate
                              ? props.formData.history.createdDate
                              : ''
                          }
                          updatedDateTime={
                            props.formData.history.modifiedDate
                              ? props.formData.history.modifiedDate
                              : ''
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
                {/* <pre>{ JSON.stringify(values, 0, 2) }</pre>  */}
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};
export default DocumentPaneComponent;

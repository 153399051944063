import React, {Suspense} from 'react';
import {App} from './App';
import {Customizations, mergeStyles} from 'office-ui-fabric-react';
import {initializeIcons} from '@uifabric/icons';
import {uPrinceTheme} from './theme';
import {Provider} from 'react-redux';
import {applyMiddleware, compose, createStore} from 'redux';
import axiosMiddleware from 'redux-axios-middleware';
import rootReducer from './app/reducers/index';
import {BrowserRouter} from 'react-router-dom';
import client from './app/api';
// import {axiosConfig} from './app/api';
import {persistStore} from 'redux-persist';
import './i18n';
import CustomLoading from './app/shared/loading/customLoading';
import ErrorBoundary from './app/components/ErrorBoundary';
import ENV from './env.json'
import {createRoot} from 'react-dom/client';

// MSAL imports
import {AuthenticationResult, EventMessage, EventType, PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./app/services/authConfig";
import history from "./app/history";

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
        localStorage.setItem("accessToken", payload.accessToken);
    }else if(event.eventType === EventType.LOGOUT_SUCCESS){
    }else if(event.eventType === EventType.LOGOUT_END){
        sessionStorage.clear();
        history.push('/login');
    }
});

if (process.env.NODE_ENV === 'production') {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
}
declare global {
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
  }

// Inject some global styles
mergeStyles({
    selectors: {
        ':global(body), :global(html), :global(#app)': {
            margin: 0,
            padding: 0,
            height: '100vh'
        }
    }
});
initializeIcons();

// Sentry.init({
//   dsn: "https://50646c625d814dbdaf5a4afb80bdc8cf@o484979.ingest.sentry.io/5538919",
//   integrations: [
//     new Integrations.BrowserTracing(),
//   ],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

// const store = createStore(rootReducer, composeWithDevTools(
//     applyMiddleware(axiosMiddleware(client)),
//     // other store enhancers if any
// ));

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store: any = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(axiosMiddleware(client)))
);

Customizations.applySettings({ theme: uPrinceTheme });

const persistor = persistStore(store);
const favicon:any = document.getElementById('favicon');
favicon.href = ENV.IMG_LOGO

const UprinceApp = () => (
    <Provider store={ store }>
         {/* <PersistGate loading={ null } persistor={ persistor }> */}
            <BrowserRouter>
                <Suspense fallback={ <CustomLoading loadingColor={ uPrinceTheme.palette.themePrimary } /> }>
                    <ErrorBoundary>
                        <App pca={msalInstance} />
                    </ErrorBoundary>
                </Suspense>
            </BrowserRouter>
         {/* </PersistGate> */}
    </Provider>
);

const container =document.getElementById('app');
const root = createRoot(container!);
root.render(<UprinceApp />);

//-------Quality Filter------//

class SortingModel {
  attribute: string | null = null;
  order: string | null = null;
}

export class QualityListPaneFilter {
  title: string | null = null;
  criteria: string | null = null;
  tolerance: string | null = null;
  method: string | null = null;
  skills: string | null = null;
  sequenceCode: string | null = null;
  sortingModel: SortingModel = new SortingModel();


}

export interface QualityShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
  value: number | null;
  // sequenceCode: string | null;
}

class Sorter {
  attribute: string | null = null;
  order: string | null = null;
}

export class QualityListItem {
  id: string | null = null;
  name: string | null = null;
  sequenceId: string | null = null;
  title: string | null = null;
  criteria: string | null = null;
  tolerance: string | null = null;
  sequenceCode: string | null = null;
}

export interface DropDown {
  key: string;
  text: string;
}

export interface QLDropDowns {
  status: DropDown[];
  types: DropDown[];
}

export interface Quality {
  id: string | null;
  criteria: string | null;
  tolerance: string | null;
  method: string | null;
  skills: string | null;
  sequenceCode: string | null;
  title: string | null;
  headerTitle: string | null;
  name: string | null;
  isDeleted: boolean;
  isSaved: boolean;
  uid: string | null;
}

export interface QLDetails {
  id: string | null;
  sequenceId: string | null;
  title: string | null;
  name: string | null
  sequenceCode: string | null;
}

export interface History {
  createdByUser: string | null;
  createdDateTime: string | null;
  revisionNumber: number | string | null;
  updatedByUser: string | null;
  updatedDateTime: string | null;
}

export const formData = {
  id: null,
  title: 'string',
  sequenceId: 'string',

  criteria: 'string',
  tolerance: 'string',
  method: 'string',
  skills: 'string',
  sequenceCode: 'string',
  headerTitle: 'string',
  name: 'string',
  isDeleted: false,
  isSaved: false,
  uid: 'string',
};

import {
  ContextualMenu,
  FontWeights,
  getTheme,
  IconButton,
  IDragOptions,
  IIconProps,
  Label,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  TextField,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../../theme';
import { LangKeysValidationMessage, Translation } from '../../../../types/language';
import { useBoolean, useId } from '@uifabric/react-hooks';

export const LanguageKeyModel = (props: {
  isOpen: boolean;
  isEdit: boolean;
  openLanguageKeyModel: () => void;
  handleNickNameValidation: (
    nickNameValidationMessage: LangKeysValidationMessage,
  ) => void;
  nickNameValidationMessages: LangKeysValidationMessage;
  createTranslation: (translation: Translation) => any;
  languageId: string;
  reloadTranslationKeywords: () => void;
  translation: Translation;
  updateTranslation: (translation: Translation) => any
}) => {
  const { t } = useTranslation();
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
  const [langKey, setTransKey] = useState<string | null>(null);
  const [langValue, setTransValue] = useState<string | null>(null);
  const [keyId, setKeyId] = useState<string | null>(null);
  const [translation, setTranslation] = useState(new Translation());
  const titleId = useId('title');
  const theme = getTheme();
  const dragOptions: IDragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
  };
  const iconButtonStyles = {
    root: { color: uPrinceTheme.palette.themePrimary },
    rootHovered: { color: theme.palette.neutralDark },
  };
  const cancelIcon: IIconProps = { iconName: 'Cancel' };
  const addWhiteIconButtonStyles = {
    root: { color: uPrinceTheme.palette.white },
    rootHovered: { color: theme.palette.neutralDark },
  };
  const addIconWhite: IIconProps = {
    iconName: 'Add',
    styles: addWhiteIconButtonStyles,
  };
  const saveIconWhite: IIconProps = {
    iconName: 'Save',
    styles: addWhiteIconButtonStyles,
  };

  useEffect(() => {
    if (props.isEdit) {
      setTranslation(props.translation);
      setTransKey(props.translation.key);
      setTransValue(props.translation.value);
      setKeyId(props.translation.id);
    } else {
      setTranslation({ languageId: null, key: null, value: null, id: null });
      setTransKey(null);
      setTransValue(null);
      setKeyId(null);
    }
  }, [props.translation]);

  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
    header: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '2px 2px 2px 10px',
        backgroundColor: uPrinceTheme.palette.themePrimary,
        fontSize: 18,
        color: 'white',
      },
    ],
    footer: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '0px 24px 14px 24px',
        textAlign: 'end',
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 0px 24px',
      overflowY: 'hidden',
      paddingTop: 20,
      // minWidth: "30vw",
      minHeight: screen.width > 1280 ? '20vh' : '30vh',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
    subHeader: {
      flex: '1 1 auto',
      display: 'none',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      fontSize: 14,
      color: uPrinceTheme.palette.themePrimary,
      backgroundColor: '#FFF7F4',
      height: 40,
      marginLeft: 'auto',
      marginRight: '2px',
    },
  });
  const cancelIconButtonStyles = {
    root: {
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
      color: 'white',
    },
    rootHovered: { color: theme.palette.neutralDark },
  };
  // const onRenderCaretDownResourceLanguage = (): JSX.Element => {
  //   return (
  //     <Icon
  //       style={{ fontSize: props.langKey.localeCode ? 9 : 14 }}
  //       iconName={props.langKey.localeCode ? 'Clear' : 'ChevronDown'}
  //       onClick={() => {
  //         props.langKey.localeCode = null;
  //         props.handleNickNameChange(props.langKey);
  //       }}
  //     />
  //   );
  // };
  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          const nickNameValidationMessages = props.nickNameValidationMessages;
          nickNameValidationMessages.keyErrorMsg = '';
          nickNameValidationMessages.valueErrorMsg = '';
          props.handleNickNameValidation(
            nickNameValidationMessages,
          );
          props.openLanguageKeyModel();
          // props.resetSelection();
        }}
        isBlocking={true}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>  {props.isEdit ? t('Edit') : t('Add')} {t('Language Keys')}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              const nickNameValidationMessages = props.nickNameValidationMessages;
              nickNameValidationMessages.keyErrorMsg = '';
              nickNameValidationMessages.valueErrorMsg = '';
              props.handleNickNameValidation(
                nickNameValidationMessages,
              );
              props.openLanguageKeyModel();
              // props.resetSelection();
            }}
          />
        </div>

        <div className={contentStyles.body}>
          <div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">

                <Label required={true}>{t('key')}</Label>
                <TextField
                  required={true}
                  value={langKey!!}
                  onChange={(event, value) => {
                    if (value) {
                      setTransKey(value);

                    } else {
                      setTransKey('');
                    }
                  }}
                />
              </div>
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                <TextField
                  label={t('value')}
                  required={true}
                  value={langValue!!}
                  // errorMessage={
                  //   props.nickNameValidationMessages.nickNameErrorMsg
                  //     ? props.nickNameValidationMessages.nickNameErrorMsg
                  //     : ''
                  // }
                  // value={props.nickName.nickName ? props.nickName.nickName : ''}
                  onChange={(event, value) => {
                    if (value) {
                      setTransValue(value);
                    } else {
                      setTransValue('');
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={props.isEdit ? saveIconWhite : addIconWhite}
            text={props.isEdit ? t('save') : t('save')}
            style={{ marginTop: 20, marginBottom: 10 }}
            onClick={() => {
              const newTranslation = { languageId: props.languageId, key: langKey, value: langValue, id: keyId };
              if (props.isEdit) {
                props.updateTranslation(newTranslation).then((res: any) => {
                  if (res?.payload?.status === 200) {
                    props.reloadTranslationKeywords();
                    props.openLanguageKeyModel();
                  }
                });
              } else {
                props.createTranslation(newTranslation).then((res: any) => {
                  if (res?.payload?.status === 200) {
                    props.reloadTranslationKeywords();
                    props.openLanguageKeyModel();
                  }
                });
              }
            }}
          />
          {!props.isEdit && <PrimaryButton
            iconProps={props.isEdit ? saveIconWhite : addIconWhite}
            text={t('saveandNew')}
            style={{ marginTop: 20, marginBottom: 10, marginLeft: 10 }}
            onClick={() => {
              const newTranslation = { languageId: props.languageId, key: langKey, value: langValue, id: keyId };
              if (props.isEdit) {
                props.updateTranslation(newTranslation).then((res: any) => {
                  if (res.payload.status === 200) {
                    props.reloadTranslationKeywords();
                    props.openLanguageKeyModel();
                  }
                });
              } else {
                props.createTranslation(newTranslation).then((res: any) => {
                  if (res.payload.status === 200) {
                    props.reloadTranslationKeywords();
                    props.openLanguageKeyModel();
                  }
                });
              }

            }}
          />}
        </div>
      </Modal>
    </div>
  );
};

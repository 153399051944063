import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';

const TimeClockCommandBarLeft = (props: { handelLoadExcelExportData: any, shiftExcelExportData: any, handleDBOptimize: () => void }) => {

  const { t } = useTranslation();

  const getItems = () => {
    return [
      {
        key: 'export',
        text: t('export'),
        iconProps: { iconName: 'Export' },
        onClick: () => props.handelLoadExcelExportData(),
        //onClick: () => excelExport(props.shiftExcelExportData),
        disabled: (props.shiftExcelExportData.length <= 0),

      },
      {
        key: 'delete',
        text: t('optimiseDatabase'),
        iconProps: { iconName: 'Database' },
        onClick: () => props.handleDBOptimize(),
        //onClick: () => excelExport(props.shiftExcelExportData),

      },
    ];
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );

};

export default TimeClockCommandBarLeft;



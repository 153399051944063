import React from 'react';
import {
    DetailsList,
    FontIcon, Icon,
    IStackTokens,
    mergeStyleSets,
    PrimaryButton,
    registerIcons,
    Spinner,
    SpinnerSize,
    Stack,
} from '@fluentui/react';
import i18n from '../../../../i18n';
import {SvgIcons} from '../../../shared/svgIcons/svgIcons';
import {useSelector} from 'react-redux';
import {uPrinceTheme} from '../../../../theme';
import {DownloadLink} from '../../../types/lotInvitation';
import MailTemplate from '../../lotInvitation/template/component';
// import { useTranslation } from 'react-i18next';

registerIcons(SvgIcons);

const stackTokens: IStackTokens = {childrenGap: 40};

const styles = {
    logo: {
        marginTop: '20px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
};

const classNames = mergeStyleSets({
    body: {
        hight: '100%',
        display: 'block',
        marginLeft: '20%',
        marginRight: '20%',
        backgroundColor: 'white',
        padding: '40px',
        minHeight: 'calc(100vh - 120px)',
    },
    spacing: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    justifyCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        border: '0',
        borderSpacing: '0',
    },
    content: {
        padding: '0 62px 0px 160px',
    },
    boldFont: {
        margin: '0 0 25px 0',
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'Arial,sans-serif',
        color: uPrinceTheme.palette.themePrimary,
        fontWeight: 'bold',
    },
    detailFont: {
        margin: '0 0 25px 0',
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'Arial,sans-serif',
    },
    subText: {
        marginLeft: 'auto',
        marginRight: 'auto',
        fontFamily: 'Arial,sans-serif',
        display: 'flex',
        justifyContent: 'center',
        fontSize: '2.8em',
        color: uPrinceTheme.palette.neutralPrimary,
    },
    icon: {
        padding: '0',
        verticalAlign: 'top',
        color: uPrinceTheme.palette.themePrimary,
    },
    tdIcon: {
        width: '40px',
        padding: '0,0,1px,0',
        verticalAlign: 'top',
        color: uPrinceTheme.palette.neutralPrimary,
    },
    actionBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
    },
    iconContainer: {
        textAlign: 'right',
    },
});

const InvitationContentBody = (
  props: {
      handleDownloadLot: (approve: string) => void,
  }) => {
    // const { t } = useTranslation();

    const {
        formData,
        showProgressBar,
    }: any = useSelector(
      (state: any) => state.lotInvitation,
    );

    const columns = [
        {
            key: 'column1',
            name: i18n.t('title'),
            fieldName: 'title',
            minWidth: 165,
            maxWidth: 200,
            isResizable: true,
            isSorted: true,
        },
        {
            key: 'column2',
            name: i18n.t('link'),
            fieldName: 'link',
            minWidth: 10,
            maxWidth: 10,
            isResizable: true,
            isSorted: true,
            onRender: (item: DownloadLink) => {
                return (
                  <div className={classNames.iconContainer}>
                      {/*<Icon*/}
                      {/*  style={{cursor: 'pointer', color: uPrinceTheme.palette.themePrimary}}*/}
                      {/*  iconName={'Download'}*/}
                      {/*  onClick={(event) => {*/}
                      {/*      event.preventDefault();*/}
                      {/*      window.open(item.link!!);*/}
                      {/*  }}*/}
                      {/*/>*/}
                  </div>
                );
            },
        },
    ];

    const spinner = () => (
      <Spinner size={SpinnerSize.large}/>
    );

    return (
      <div className={classNames.body}>
          <div className={classNames.justifyCenter}>
              <FontIcon
                iconName={'bm-engineering-invitation-logo'}
                className="ms-Icon"
                style={styles.logo}
              />
          </div>
          <div className={classNames.spacing}>
              <Stack horizontal tokens={stackTokens} className={classNames.spacing}
                     style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                  <MailTemplate formData={formData}/>
              </Stack>
          </div>

          <Stack horizontal tokens={stackTokens} className={classNames.spacing}
                 style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <div className={classNames.actionBody}>
                  <h3 className={`${classNames.justifyCenter} mail-template-fonts`}>{i18n.t('pleaseDownload')}!</h3>
                  <DetailsList
                    items={formData?.tenderDocs ? formData?.tenderDocs : []}
                    columns={columns}
                    isHeaderVisible={false}
                    checkboxVisibility={2}
                    disableSelectionZone={true}
                  />
                  {!formData?.isDownloded ? <PrimaryButton
                      // text={i18n.t('download')}
                      onClick={() => {
                          props.handleDownloadLot('1');
                          // dispatch(saveLotInvitationStateAttr('download', false));
                      }}
                      allowDisabledFocus
                      disabled={showProgressBar}
                      style={{width: 'auto', height: '50px', borderRadius: '5px'}}
                    >{showProgressBar ? spinner() : i18n.t('downloadAllDocs')}
                    </PrimaryButton> :
                    <h3 className={classNames.justifyCenter}>{i18n.t('thankYouForDownloading')}!</h3>
                  }
              </div>
          </Stack>
      </div>
    );
};

export default InvitationContentBody;

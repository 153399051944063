import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  DetailsListLayoutMode,
  DetailsRow,
  Dropdown,
  Fabric,
  IColumn,
  IDetailsFooterProps,
  IDetailsListProps,
  IDetailsRowStyles,
  Label,
  ScrollablePane,
  ScrollbarVisibility,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
  TextField,
} from '@fluentui/react';
import {
  CorporateProductCatalogue,
  CorporateProductCatalogueListItem,
  ProductCatalogueFilter,
} from '../../../types/corporateProductCatalogue';
import { useTranslation } from 'react-i18next';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';


const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 280px)!important',
    position: 'relative',
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: {
    margin: 0,
  },
  row: {
    display: 'inline-block',
  },
});


export const CpcListPaneComponent = (props: {
  cpcFilter: ProductCatalogueFilter;
  cpcSelection: any;
  columns: IColumn[];
  cpcList: CorporateProductCatalogueListItem[];
  resourceTypes: [];
  handleCpcUpdateOpen: (id: string) => void;
  handleCpcFilterChange: (cpcFilter: ProductCatalogueFilter) => void;
  isDataLoaded?: boolean;
  loadMsg: string;
  resetSelection: () => void
  productCatalogue: CorporateProductCatalogue;
}) => {
  useEffect(() => {
    props.resetSelection();
  }, [props.productCatalogue.id && props.isDataLoaded]);
  const _onRenderRow: IDetailsListProps['onRenderRow'] = (row) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (row) {
      return (
        <span
          style={{ textDecoration: 'none' }}
          onClick={() => {
            props.handleCpcUpdateOpen(row.item.id);
          }}
        >
          <DetailsRow {...row} styles={customStyles}/>
        </span>
      );
    }
    return null;
  };

  const _onRenderDetailsFooter = (
    detailsFooterProps: IDetailsFooterProps | undefined,
  ) => {
    return (
      <DetailsRow
        className="footer-as-filter"
        {...detailsFooterProps}
        columns={detailsFooterProps?.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps?.groupNestingDepth}
        selection={detailsFooterProps?.selection}
        onRenderItemColumn={_renderFilterItemColumn}
        // onRenderCheck={_onRenderCheckForFooterRow}
      />
    );
  };

  const _renderFilterItemColumn = (
    item?: CorporateProductCatalogueListItem,
    index?: number,
    column?: IColumn,
  ) => {
    switch (column?.key) {
      case 'column1': {
        return <div>
          <TextField
            value={(props.cpcFilter.resourceNumber) ? props.cpcFilter.resourceNumber : ''}
            onChange={(event, value) => {
              if (value) {
                props.cpcFilter.resourceNumber = value;
              } else {
                props.cpcFilter.resourceNumber = null;
              }
              props.handleCpcFilterChange(props.cpcFilter);

            }}
          />
        </div>;
        break;
      }
      case 'column2': {
        return <div>
          <TextField
            value={(props.cpcFilter.title) ? props.cpcFilter.title : ''}
            onChange={(event, value) => {
              if (value) {
                props.cpcFilter.title = value;
                props.cpcFilter.resourceNumber = value;
              } else {
                props.cpcFilter.title = null;
                props.cpcFilter.resourceNumber = null;
              }
              props.handleCpcFilterChange(props.cpcFilter);

            }}
          />
        </div>;
        break;
      }
      case 'column3': {
        return (
          <div>
            <Dropdown
              placeholder={t('cPCResourcetype')}
              options={props.resourceTypes}
              selectedKey={
                props.cpcFilter.resourceTypeId
                  ? props.cpcFilter.resourceTypeId
                  : '0'
              }
              onChange={(event, value) => {
                if (value) {
                  props.cpcFilter.resourceTypeId = (value.key != 0) ? value.key.toString() : null;
                } else {
                  props.cpcFilter.resourceTypeId = null;
                }
                props.handleCpcFilterChange(props.cpcFilter);

              }}
            />
          </div>
        );
        break;
      }
      case 'column4': {
        return (
          <div>
            <Dropdown
              placeholder={t('status')}
              options={[{ key: '', text: t('all') }, { key: 1, text: t('active') }, { key: 0, text: t('Draft') }]}
              selectedKey={
                props.cpcFilter.status
                  ? props.cpcFilter.status
                  : (props.cpcFilter.status === 0) ? 0 : ''
              }
              onChange={(event, value) => {
                if (value) {
                  props.cpcFilter.status = value.key;
                } else {
                  props.cpcFilter.status = null;
                }
                props.handleCpcFilterChange(props.cpcFilter);

              }}
            />
          </div>
        );
        break;
      }
      default: {
        break;
      }
    }
  };
  const { t } = useTranslation();

  const displayMessage = () => {
    return <div style={{ paddingTop: 36, paddingLeft: 20 }}><Label>{t(props.loadMsg)}</Label></div>;
  };
  return (
    <Fabric>
      <div className={`wrapper-holder ${classNames.wrapper}`}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <ShimmeredDetailsList
            items={props.cpcList}
            columns={props.columns}
            setKey="set"
            enableShimmer={!props.isDataLoaded}
            layoutMode={DetailsListLayoutMode.justified}
            selectionPreservedOnEmptyClick={true}
            checkboxVisibility={1}
            onRenderRow={_onRenderRow}
            onRenderDetailsFooter={_onRenderDetailsFooter}
            selection={props.cpcSelection}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            onRenderDetailsHeader={(headerProps, defaultRender) => {
              if (defaultRender !== undefined) {
                return (
                  <Sticky
                    stickyPosition={StickyPositionType.Header}
                    isScrollSynced={true}
                    stickyBackgroundColor="transparent"
                  >
                    <div>{defaultRender(headerProps)}</div>
                  </Sticky>
                );
              } else {
                return <span></span>;
              }
            }}
          />
          {props.isDataLoaded && displayMessage()}
        </ScrollablePane>

      </div>
    </Fabric>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CpcListPaneComponent);

import React from 'react';
import {
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  registerIcons,
  Stack,
  TextField,
} from '@fluentui/react';
import i18n from '../../../i18n';
import { SvgIcons } from '../../shared/svgIcons/svgIcons';
import TextEditor from '../../shared/textEditor/textEditor';
import { store } from '../../../index';
import { uPrinceTheme } from '../../../theme';

registerIcons(SvgIcons);

const classNames = mergeStyleSets({

  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
});

const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const styles = {
  textField: {
    color: uPrinceTheme.palette.themePrimary,
  },
};

const  RfqHeader= () => {
  // const dispatch = useDispatch();
  const {rfqFormData}=store.getState()?.po;

  return (
    <div>

      <div className={'card-header'}>
        <Link href="#" id="invoice-invoice-amount">
          <Label>1. {i18n.t('rfqHeader')} </Label>
        </Link>
      </div>
      <div className={'card-body'}>
        <div className="proj-detail-content inner">

          <div className="marginTop">
            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    value={rfqFormData?.title?rfqFormData?.title:''}
                    label={i18n.t('rfqTitle')}
                    disabled={true}
                    style={styles.textField}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    value={rfqFormData?.sequenceId?rfqFormData?.sequenceId:''}
                    label={i18n.t('rfqId')}
                    disabled={true}
                    style={styles.textField}
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>

          <div className="marginTop">
            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    value={rfqFormData?.name?rfqFormData?.name:''}
                    label={i18n.t('rfqName')}
                    disabled={true}
                    style={styles.textField}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    value={rfqFormData?.type?rfqFormData?.type?.text:''}
                    label={i18n.t('rfqType')}
                    disabled={true}
                    style={styles.textField}
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>

          <div className="marginTop">
            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    value={rfqFormData?.status?rfqFormData?.status?.text:''}
                    label={i18n.t('rfqStatus')}
                    disabled={true}
                    style={styles.textField}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>

                </div>
              </Stack.Item>
            </Stack>
          </div>

          <div className="marginTop" style={{padding:10}}>
            <TextEditor
              value={ rfqFormData?.comments?rfqFormData?.comments:''}
              onChange={()=>{}}
              readOnly={true}
              //}
            />
          </div>

        </div>
      </div>

      <div className={'card-footer'}>
        <br/>
      </div>
      {/*{JSON.stringify(rfqFormData)}*/}
    </div>
  );
};

export default RfqHeader;

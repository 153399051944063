import React, {useEffect} from 'react';
import ProgressBar from '../../shared/progressBar/progressBar';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {
    downloadLotDocuments,
    getDownloadAllDocumentDownload,
    readByLotInvitationId
} from '../../reducers/lotInvitationReducer';
import InvitationContentBody from './content/component';
import {getThemeColor} from '../../reducers/uprinceReducer';
import i18n from "i18next";

const LotDownMainLayout = () => {
    const dispatch = useDispatch();
    const id: any = useParams();
    const [isLoading, setIsLoading] = React.useState<any>(false);


    const {
        formData
    }: any = useSelector(
        (state: any) => state.lotInvitation,
    );

    useEffect(() => {
        // const currentLan = localStorage.getItem('lang') ?? window.navigator.language

        // if(currentLan != id?.languageId){
            i18n.changeLanguage(id?.languageId).then();
        // }

        dispatch(readByLotInvitationId(id?.invitationId));

        if (!localStorage.getItem('themeColor')) {
            Promise.all([
                dispatch(getThemeColor())
            ]).then(() => {
                window.location.reload();
            });
        };

        setIsLoading(true)
        setTimeout(()=>{
            setIsLoading(false)
        },2000)

        return () => {

        }
    }, []);

    const handleDownloadLot = (approve: string) => {
        let items: any = [];
        const invitationId = id?.invitationId ? id?.invitationId : null;
        const lotSequenceCode = formData?.lotId ? formData?.lotId : null;
        const lotId = formData?.id ? formData?.id : null;

        items.push({ lotId, invitationId, approve });

        const data = new FormData();
        data.append('lotId', lotSequenceCode);

        if (lotId) {
            Promise.all([
                dispatch(getDownloadAllDocumentDownload(data)),
            ]).then((response: any) => {
                // console.log(response[0].payload.data);
                if (response[0].payload.data) {
                    window.open(response[0].payload.data);
                }
                dispatch(downloadLotDocuments(items[0]));
                setTimeout(() => {
                    dispatch(readByLotInvitationId(invitationId));
                }, 1000);
            });
        }
    };

    return <div>
        {isLoading?<div><ProgressBar show={true} /></div>: <InvitationContentBody
            handleDownloadLot={(approve: string) => handleDownloadLot(approve)}
        />}
    </div>
};

export default LotDownMainLayout;
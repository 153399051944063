import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IColumn, Selection } from '@fluentui/react/lib/DetailsList';
import history from '../../../history';

import ListPaneComponent from './component';
import { withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { readCertificationDropDownData } from '../../../reducers/certificationReducer';
import { messageService } from '../../../services/messageService';
import {
  Certification,
  CertificationDropDowns,
  CertificationListItem,
  CertificationListPaneFilter,
  formData,
} from '../../../types/certification';
import { getContractingUnit, getProject } from '../../../shared/util';

export interface State {
  redirect: boolean;
  isChange: boolean;
  loading: boolean;
  filter: CertificationListPaneFilter;
  announcedMessage: string;
  selectedListItemIds: [] | string[];
  selectedListItemSequenceCode: [] | string[];
  formData: Certification;
  selectedListItems: CertificationListItem[];
}

export interface Props extends WithTranslation {
  listPaneItems: CertificationListItem[];
  reloadListPaneItem: () => void;
  handelFilter: (filter: CertificationListPaneFilter) => void;
  isDataLoaded?: boolean;
  isChange: boolean;
  filter: CertificationListPaneFilter;
  resourceTypes: [];
  loadMsg: string;
  toleranceStatesFilter: [];
  productStatesFilter: [];
  itemTypesFilter: [];
  handleSelectedListPaneItem: (selectedItemIds: [] | string[]) => void;
  formData: Certification;
  projectId: string | null;
  certDropDowns: CertificationDropDowns;
  reSizer: number;
  readCertificationDropDownData: () => void;
  isExpand: boolean;
}

class ListPane extends Component<Props, State> {
  private _Selection: Selection;
  private _columns: IColumn[];
  private _columnsReSizer: IColumn[];
  private _columnsCu: IColumn[];
  subscription: any;

  constructor(props: Props) {
    super(props);
    this._Selection = new Selection({
      onSelectionChanged: () => {
        this.setState(
          {
            selectedListItemIds: this._getSelectedIds(),
            selectedListItemSequenceCode: this._getselectedSequenceCode(),
            selectedListItems: this._getSelectedListItems(),
          },
          this.handleSelectedItem,
        );
      },
    });
    this.state = {
      selectedListItems: [],
      selectedListItemIds: [],
      selectedListItemSequenceCode: [],
      redirect: false,
      isChange: false,
      loading: false,
      announcedMessage: '',
      filter: new CertificationListPaneFilter(),
      formData: formData,
    };

    this._columns = [
      {
        key: 'column1',
        name: this.props.t('type'),
        fieldName: 'type',
        minWidth: 165,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column2',
        name: this.props.t('totalAmountexVat'),
        fieldName: 'totalAmount',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        className: 'totalAmountCol',
      },
      {
        key: 'column3',
        name: this.props.t('date'),
        fieldName: 'date',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column4',
        name: this.props.t('status'),
        fieldName: 'status',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
    ];
    this._columnsReSizer = [
      {
        key: 'column1',
        name: this.props.t('title'),
        fieldName: 'title',
        minWidth: 165,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
    ];
    this._columnsCu = [
      {
        key: 'column1',
        name: this.props.t('type'),
        fieldName: 'type',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column2',
        name: this.props.t('title'),
        fieldName: 'title',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column3',
        name: this.props.t('totalAmountexVat'),
        fieldName: 'totalAmount',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        className: 'totalAmountCol',
      },
      {
        key: 'column4',
        name: this.props.t('date'),
        fieldName: 'date',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column5',
        name: this.props.t('status'),
        fieldName: 'status',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
    ];
  }

  // static getDerivedStateFromProps(nextProps: any) {
  //   if (nextProps.isChange) {
  //     nextProps.reloadListPaneItem();
  //   }
  //   return {
  //     loading: false,
  //     filter: nextProps.filter,
  //     formData: nextProps.formData,
  //   };
  // }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewPbs) {
          this._Selection.selectToKey('', true);
        }
        if (data.data.resetListPaneSelection) {
          this._Selection.setAllSelected(false);
          this.resetSelection();
        }
      }
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.isDataLoaded != this.props.isDataLoaded) {
      if (this.props.isDataLoaded) {
        this.resetSelection();
      }
    }
  }

  handleListPaneItemClick = (id: string) => {
    if (getContractingUnit() && !getProject()) {
      history.push(`/CU/${getContractingUnit()}/template/${id}`);
    } else {
      history.push(`/organisation-settings/certifications/${id}`);
    }
  };

  handleSelectedItem = () => {
    this.props.handleSelectedListPaneItem(this.state.selectedListItemIds);
    if (this.state.selectedListItemSequenceCode) {
      let lastId = this.state.selectedListItemSequenceCode.pop();
      if (lastId) {
        this.handleListPaneItemClick(lastId);
      }
    }
  };

  handleFilterChange = (filter: CertificationListPaneFilter) => {
    this.props.handelFilter(filter);
  };

  handleFilterSortChange = () => {
    this.props.handelFilter(this.state.filter);
  };

  resetSelection = () => {
    if (
      this.state.formData &&
      this.state.formData.id &&
      this.props.isDataLoaded
    ) {
      this._Selection.setKeySelected(
        this.state.formData.id,
        true,
        false,
      );
    } else {
      this._Selection.selectToKey('', true);
    }
  };

  render() {
    return (
      <div>
        <ListPaneComponent
          listPaneItems={
            this.props.listPaneItems ? this.props.listPaneItems : []
          }
          selection={this._Selection}
          handleListPaneItemClick={(id: string) =>
            this.handleListPaneItemClick(id)
          }
          resetSelection={() => this.resetSelection()}
          formData={this.state.formData}
          columns={(getProject()) ? (this.props.reSizer === 300) ? this._columnsReSizer : this._columns : (this.props.reSizer === 300) ? this._columnsReSizer : this._columnsCu}
          filter={this.props.filter}
          loadMsg={this.props.loadMsg}
          handleFilterChange={(filter: CertificationListPaneFilter) =>
            this.handleFilterChange(filter)
          }
          isDataLoaded={this.props.isDataLoaded}
          dropDownOptions={this.props.certDropDowns}
          isExpand={this.props.isExpand}
        />
      </div>
    );
  }

  private _getSelectedIds() {
    let deleteList: any = [];
    let SelectedItem = this._Selection.getSelection() as CertificationListItem[];
    if (SelectedItem) {
      deleteList = SelectedItem.map((item: any) => {
        return item ? item.id : null;
      });
    }
    return deleteList;
  }

  private _getselectedSequenceCode() {
    let selectedList: any = [];
    let selectedItem = this._Selection.getSelection() as CertificationListItem[];
    if (selectedItem) {
      selectedList = selectedItem.map((item: any) => {
        return item ? item.progressStatementId : null;
      });
    }
    return selectedList;
  }

  private _getSelectedListItems = () => {
    let SelectedListItems = this._Selection.getSelection() as CertificationListItem[];

    return SelectedListItems;
  };

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn,
  ): void => {
    const newColumns: IColumn[] = this._columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key,
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        let filter = this.state.filter;

        let fieldName = currColumn.fieldName;

        let sortby = currColumn.isSortedDescending ? 'desc' : 'asc';
        filter.sorter.attribute = fieldName ? fieldName : null;
        filter.sorter.order = sortby ? sortby : null;
        this.setState(
          {
            filter: filter,
          },
          this.handleFilterSortChange,
        );
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    // toleranceStatesFilter:state.projectBreakdown.toleranceStatesFilter,
    // productStatesFilter: state.projectBreakdown.productStatesFilter,
    // itemTypesFilter: state.projectBreakdown.itemTypesFilter,
    isChange: state.cert.isChange,
    certDropDowns: state.cert.certDropDowns,
    loadMsg: state.cert.loadMsg,
  };
};

const mapDispatchToProps = {
  readCertificationDropDownData,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ListPane)),
);

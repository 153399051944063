import axios from 'axios';
import AuthService from './services/auth.service';
import 'axios-response-logger';
import _ from 'lodash';
import ENV from '../env.json';
import { getContractingUnit, getProject, isCuProjectPlanning, isMyEnvironment, isCustomHeaderAPI, isOrganisationSetting } from './shared/util';
import axiosRetry from 'axios-retry';
import { store } from '..';

const { ConcurrencyManager } = require('axios-concurrency');
const http = require('http');
const https = require('https');

const authService = new AuthService();

// let cancelSource = axios.CancelToken.source();

http.globalAgent.maxSockets = 20;
https.globalAgent.maxSockets = 20;

const httpAgent = new http.Agent({ keepAlive: true });
const httpsAgent = new https.Agent({ keepAlive: true });


const client = axios.create({ baseURL: ENV.BASE_URL, httpAgent, httpsAgent });
axiosRetry(client, { retries: 2 });



// export const cancelAllRequest = () =>{
//     cancelSource.cancel()
// }

const manager = ConcurrencyManager(client, 50);

if (ENV.LOG_ENABLED) {
  // client.interceptors.request.use(AxiosLogger.requestLogger);
  // client.interceptors.response.use(
  //     AxiosLogger.responseLogger,
  //     AxiosLogger.errorLogger
  // );
}
client.interceptors.request.use((config) => {
  //  let token = sessionStorage.getItem('msal.idtoken');

  const browserLanguagePropertyKeys = [
    'languages',
    'language',
    'browserLanguage',
    'userLanguage',
    'systemLanguage',
  ];

  // config.cancelToken = cancelSource.token;

  const availableLanguages = ['de', 'ar'];

  const detectedLocale = _.chain(window.navigator)
    // Get only language properties
    .values() // Get values of the properties
    .flatten() // flatten all arrays
    .compact() // Remove undefined values
    .value();
  let selectedlang = localStorage.getItem('lang');
  const language = selectedlang ? selectedlang : window.navigator.language;
  const locale = detectedLocale || 'en'; // If no locale is detected, fallback to 'en'
  const token = localStorage.getItem('accessToken');
  config.headers.Authorization = 'Bearer ' + token;
  config.headers.lang = language;
  config.headers['Cache-Control'] = 'no-cache';
  if (getContractingUnit()) {
    config.headers.cu = getContractingUnit();
  }
  if (getProject()) {
    config.headers.project = getProject();
  }

  const projectSequenceCode = store.getState().uprince?.projectSequenceCode;
  const contractingUinit = store.getState().uprince?.contractingUinit;
  const selectedProjectInCu = store.getState().vp?.selectedProject;

  if (isMyEnvironment()) {
    config.headers.isMyEnv = true;
    if(!isCustomHeaderAPI(config.url?config.url:"")){
        config.headers.cu = contractingUinit?contractingUinit:'COM-0001';
        // config.headers.cu = contractingUinit;
        config.headers.project = projectSequenceCode;
    }
  }

  if(isOrganisationSetting()){
       config.headers.cu = contractingUinit?contractingUinit:'COM-0001';
      //  config.headers.cu = contractingUinit;
  }

  if (isCuProjectPlanning()) {
    config.headers.isCu = true;
    if (selectedProjectInCu) {
      config.headers.project = selectedProjectInCu;
    }
  }

  // config.headers['Access-Control-Allow-Origin'] = 'http://localhost:8080';
  // config.headers['Content-Encoding'] = 'gzip';
  // config.headers['Accept-Encoding'] = 'gzip, deflate, br';


  return config;
});

const reqIntercepter = client.interceptors.response.use(
  (response) => {
    // console.log('RESPONSE >>', response);
    return response;
  },
  async (error) => {
    // console.log('ERROR >>', error.response.data.statusCode);
    const originalRequest = error?.response?.config;
    if (!error.message.includes('401')) {
      return Promise.reject(error);
    }
    try {
      if (error.response && error.response.data && error.response.data.statusCode === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const token = await authService.getToken();
        if (token) {
          error.response.config.headers.Authorization = 'Bearer ' + token;
          return client(originalRequest);
        }
      }
    } catch (err) {
      // authService.logout();
    } finally {
    }
  },
);

export default client;

import moment from 'moment';
import React from 'react';
import { ChartType, Task } from '../../../types/public-types';
import style from './bar.module.css';

type BarDisplayProps = {
  x: number;
  y: number;
  task:Task;
  width: number;
  height: number;
  isSelected: boolean;
  /* progress start point */
  progressX: number;
  progressWidth: number;
  barCornerRadius: number;
  chartType:ChartType;
  styles: {
    backgroundColor: string;
    backgroundSelectedColor: string;
    progressColor: string;
    progressSelectedColor: string;
  };
  onMouseDown: (event: React.MouseEvent<SVGPolygonElement, MouseEvent>) => void;
};
export const BarDisplay: React.FC<BarDisplayProps> = ({
                                                        x,
                                                        y,
                                                        width,
                                                        height,
                                                        isSelected,
                                                        progressX,
                                                        progressWidth,
                                                        barCornerRadius,
                                                        styles,
                                                        chartType,
                                                        task,
                                                        onMouseDown,
                                                      }) => {

  const getProcessColor = () => {
    return isSelected ? styles.progressSelectedColor : styles.progressColor;
  };


  const getBarColor = () => {
    return isSelected ? styles.backgroundSelectedColor : styles.backgroundColor;
  };

  const getBorderWidth = (task: any) => {
   if (task?.typeInternal === 'resourceitem') {
      return '3'
   }
   else if(task?.appointmentStatus=="Confirmed"){
    return '2'
   }
   else {
    return '0'
   }
  } 

  return (
    <g onMouseDown={onMouseDown}>
      <rect
        x={x}
        width={width}
        y={y}
        height={height}
        ry={barCornerRadius}
        rx={barCornerRadius}
        fill={getBarColor()}
        stroke={moment(task?.end).diff(moment(task?.deliveryDate, 'YYYY-MM-DD').toDate(), 'days') <= 1 ? '#3BC4A8' : '#D42361'}
        strokeWidth={getBorderWidth(task)}
      />
      <rect
        x={progressX}
        width={progressWidth}
        y={y}
        height={height}
        ry={barCornerRadius}
        rx={barCornerRadius}
        fill={getProcessColor()}
      />
    </g>
  );
};

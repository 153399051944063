import React from 'react';
import {connect} from 'react-redux';
import {CpcDocumentPaneComponent, NickNameModel, VendorModel} from './component';
import {
  ActionButtonState,
  CorporateProductCatalogue,
  NickName,
  NickNameValidationMessage,
  ProductCatalogueFilter,
  ProductCatalogueValidationMessage,
  SelectedNickNameItem,
  SelectedVendorItem,
  Vendor,
  VendorValidationMessage,
} from '../../../types/corporateProductCatalogue';
import {Selection} from '@fluentui/react/lib/DetailsList';
import UprinceLogger from '../../../shared/Logger/logger';
import {saveProductCatalogue} from '../../../reducers/productCatalogueReducer';
import {withRouter} from 'react-router';
import _ from 'lodash';
import client from '../../../api';
import i18n from '../../../../i18n';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import history from '../../../history';
import {messageService} from '../../../services/messageService';
import {v4 as uuidv4} from 'uuid';

export interface State {
  isDirty: boolean;
  redirect: boolean;
  loading: boolean;
  uploadedImages: string | null;
  resourceFamily: any;
  isOpenNickNameModel: boolean;
  nickNameSelectionDetails: {} | string;
  nickNameActionButtonState: ActionButtonState;
  isOpenVendorModel: boolean;
  vendorSelectionDetails: {} | string;
  vendorActionButtonState: ActionButtonState;
  filesToUpLoad: File | null;
  productCatalogue: CorporateProductCatalogue;
  vendor: Vendor;
  cpcNickName: NickName;
  isEdit: boolean;
  selectedCpcId: string | null | undefined;
  cpcNickNameIsEdit: boolean;
  cpcVendorIsEdit: boolean;
  cpcNickNameDeleteItems: [] | string[];
  cpcNickNameDeleteUnSavedItems: any;
  cpcVendorDeleteItems: [] | string[];
  cpcVendorDeleteUnSavedItems: any;
  cpcImageDeleteItems: [] | string[];
  validationMessages: ProductCatalogueValidationMessage;
  nickNameValidationMessages: NickNameValidationMessage;
  vendorValidationMessages: VendorValidationMessage;
  resourceFamilySearchString: string;
  submitCpc: boolean;
  cpcFilter: ProductCatalogueFilter;
  uid: string | null;
}

interface Props {
  saveProductCatalogue: (productCatalogue: CorporateProductCatalogue) => void;
  savePicturesOfTheInstallation: (files: FileList | null) => void;
  getProductCatalogueById: (id: string) => void;
  deleteProductCatalogueNickName: (idList: [] | string[]) => void;
  deleteProductCatalogueVendor: (idList: [] | string[]) => void;
  deleteProductCatalogueImage: (idList: [] | string[]) => void;
  uploadedImages: string | null;
  resourceTypes: [];
  resourceFamily: [];
  languageCodes: [];
  cpcBasicUnitOfMeasure: [];
  cpcPressureClass: [];
  cpcMaterial: [];
  cpcBrand: [];
  cpcUnitOfSizeMeasure: [];
  productCatalogue: CorporateProductCatalogue;
  autoSave: boolean;
  isEdit: boolean;
  selectedCpcId: string | null | undefined;
  organizations: [];
  handelGetOrganizationByName: (name: string) => void;
  submitCpc: boolean;
  changeSubmitCpc: any;
  disabled?: boolean;
  vehicleTrackingNumberList?: [];
}

export class CpcDocumentPane extends React.Component<Props, State> {
  private nickName: NickName;
  private _nickNameSelection: Selection;
  private vendor: Vendor;
  private _vendorSelection: Selection;
  subscription: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      submitCpc: false,
      isDirty: false,
      selectedCpcId: null,
      isEdit: false,
      uploadedImages: null,
      isOpenNickNameModel: false,
      isOpenVendorModel: false,
      resourceFamily: [],
      redirect: false,
      loading: false,
      nickNameSelectionDetails: {},
      nickNameActionButtonState: { add: false, edit: true, remove: true },
      vendorSelectionDetails: {},
      vendorActionButtonState: { add: false, edit: true, remove: true },
      filesToUpLoad: null,
      productCatalogue: {
        id: null,
        headerTitle: null,
        title: null,
        resourceNumber: null,
        resourceTitle: null,
        resourceTypeId: null,
        resourceFamilyId: null,
        cpcBasicUnitOfMeasureId: null,
        inventoryPrice: null,
        minOrderQuantity: null,
        maxOrderQuantity: null,
        size: null,
        cpcUnitOfSizeMeasureId: null,
        wallThickness: null,
        cpcPressureClassId: null,
        cpcMaterialId: null,
        weight: null,
        status: 0,
        cpcImage: [],
        cpcResourceNickname: [],
        cpcVendor: [],
        cpcHistoryLog: null,
        cpcBrandId: null,
        resourceId: null,
        trackingNo: null,
      },
      vendor: {
        id: null,
        uid: null,
        company: {
          id: null,
          name: null,
        },
        companyId: null,
        companyName: null,
        maxOrderQuantity: null,
        minOrderQuantity: null,
        preferredParty: false,
        purchasingUnit: null,
        resourceLeadTime: null,
        resourceNumber: null,
        resourcePrice: null,
        resourceTitle: null,
        roundingValue: null,
      },
      cpcNickName: {
        id: null,
        uid: null,
        language: null,
        nickName: null,
        localeCode: null,
      },
      cpcNickNameIsEdit: false,
      cpcVendorIsEdit: false,
      cpcNickNameDeleteItems: [],
      cpcNickNameDeleteUnSavedItems: [{ nickName: '' }],
      cpcVendorDeleteUnSavedItems: [{ companyName: '' }],
      cpcVendorDeleteItems: [],
      cpcImageDeleteItems: [],
      validationMessages: {
        resourceTitleErrorMsg: '',
        resourceTypeErrorMsg: '',
        basicUnitOfMeasureErrorMsg: '',
      },
      nickNameValidationMessages: {
        languageErrorMsg: '',
        nickNameErrorMsg: '',
      },
      vendorValidationMessages: {
        companyNameErrorMsg: '',
        purchasingUnitErrorMsg: '',
        resourceNumberErrorMsg: '',
        resourcePriceErrorMsg: '',
        resourceTitleErrorMsg: '',
      },
      resourceFamilySearchString: '',
      cpcFilter: {
        title: null,
        resourceTitle: null,
        resourceTypeId: null,
        resourceFamilyId: null,
        resourceNumber: null,
        status: null,
        sorter: {
          attribute: null,
          order: null,
        },
      },
      uid: null,
    };

    // Populate with items for demos.
    this.nickName = {
      id: null,
      uid: null,
      language: null,
      nickName: null,
      localeCode: null,
    };

    this.vendor = {
      companyId: null,
      company: {
        id: null,
        name: null,
      },
      companyName: null,
      uid: null,
      id: null,
      maxOrderQuantity: null,
      minOrderQuantity: null,
      preferredParty: false,
      purchasingUnit: null,
      resourceLeadTime: null,
      resourceNumber: null,
      resourcePrice: null,
      resourceTitle: null,
      roundingValue: null,
    };

    this._nickNameSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_nickNameSelection',
          this._getNickNameSelectionDetails(),
        );
        this.setState({
          nickNameSelectionDetails: this._getNickNameSelectionDetails(),
          nickNameActionButtonState: this._getNickNameActionButtonState(),
          cpcNickName: this._nickNameEditDetails(),
          cpcNickNameDeleteItems: this._nickNameDeleteDetails(),
          cpcNickNameDeleteUnSavedItems: this._nickNameDeleteUnSavedDetails(),
        });
      },
    });

    this._vendorSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_vendorSelection',
          this._getVendorSelectionDetails(),
        );
        this.setState({
          vendorSelectionDetails: this._getVendorSelectionDetails(),
          vendorActionButtonState: this._getVendorActionButtonState(),
          vendor: this._vendorEditDetails(),
          cpcVendorDeleteItems: this._vendorDeleteDetails(),
          cpcVendorDeleteUnSavedItems: this._vendorDeleteUnSavedDetails(),
        });
      },
    });
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    UprinceLogger.log('selectedCpcId', nextProps.selectedCpcId);
    const id = nextProps.selectedCpcId;
    const prevId = prevState.selectedCpcId;
    let validationMessages = prevState.validationMessages;
    if (id && prevId != id) {
      nextProps.getProductCatalogueById(nextProps.selectedCpcId);
      validationMessages = {
        resourceTitleErrorMsg: '',
        resourceTypeErrorMsg: '',
        basicUnitOfMeasureErrorMsg: '',
      };
    }
    if (nextProps.autoSave) {
      nextProps.saveProductCatalogue(nextProps?.productCatalogue);
    }

    if (nextProps?.productCatalogue && Object?.keys(nextProps?.productCatalogue).length === 0) {
      return {
        validationMessages: validationMessages,
        uploadedImages: nextProps.uploadedImages,
        isEdit: !!nextProps.isEdit,
        selectedCpcId: nextProps.selectedCpcId,
        resourceFamily: nextProps.resourceFamily,
        submitCpc: nextProps.submitCpc,
      };
    } else {
      if (
        nextProps.uploadedImages &&
        nextProps.uploadedImages != prevState.uploadedImages
      ) {
        nextProps?.productCatalogue.cpcImage.push({
          id: null,
          image: nextProps.uploadedImages,
        });
      }
      return {
        validationMessages: validationMessages,
        productCatalogue: nextProps?.productCatalogue,
        uploadedImages: nextProps.uploadedImages,
        isEdit: !!nextProps.isEdit,
        selectedCpcId: nextProps.selectedCpcId,
        resourceFamily: nextProps.resourceFamily,
        submitCpc: nextProps.submitCpc,
      };
    }
  }

  resetSelection = () => {
    this._vendorSelection.selectToKey('', true);
    this._nickNameSelection.selectToKey('', true);
  };

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  componentDidMount() {
    this.setState({
      resourceFamily: this.props.resourceFamily,
    });

    if (this.props.cpcUnitOfSizeMeasure) {
      let productCatalogue = this.state.productCatalogue;
      let cpcUnitOfSizeMeasure: any = { id: null };
      cpcUnitOfSizeMeasure = this.props.cpcUnitOfSizeMeasure.filter(
        (item: any) => item.isDefault == true,
      );
      productCatalogue.cpcUnitOfSizeMeasureId = cpcUnitOfSizeMeasure.id;
      this.setState({
        productCatalogue: productCatalogue,
      });
    }

    if (this.props.productCatalogue && !this.props.productCatalogue.id) {
      this.setState({
        uid: uuidv4(),
      });
    }
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.cpcFilter) {
          this.setState({
            cpcFilter: data.data.cpcFilter,
          });
        }
        if (data.data.resetCpcListSelection) {
          this.resetValidation();
        }
        if (data.data.isNewCpc) {
          this.setState({
            uid: uuidv4(),
          });
        }
      }
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.productCatalogue != this.props.productCatalogue) {
      if (this.props.productCatalogue && this.props.productCatalogue.id) {
        this.setState({
          uid: this.props.productCatalogue.id,
        });
      }
    }
  }

  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { cpcFilter: this.state.cpcFilter },
    });
  };

  resetValidation = () => {
    let validation = {
      resourceTitleErrorMsg: '',
      resourceTypeErrorMsg: '',
      basicUnitOfMeasureErrorMsg: '',
    };
    this.setState({
      validationMessages: validation,
    });
  };

  render() {
    return (
      <div style={{ width: '100%' }}>
        <RouteLeavingGuard
          when={this.state.isDirty}
          navigate={(path) => this.handleDiscardChangesNavigate(path)}
          shouldBlockNavigation={(location) => {
            return true;
          }}
          handleConfirmation={(isConfirm: boolean) => {
            this.setState({
              isDirty: !isConfirm,
            });
          }}
        />
        <CpcDocumentPaneComponent
          resetSelection={() => this.resetSelection()}
          submitCpc={this.state.submitCpc}
          isEdit={this.props.isEdit}
          openNickNameModel={() => {
            this.openNickNameModel();
          }}
          handleNickNameDelete={() => {
            this.handleNickNameDelete();
          }}
          handleVendorDelete={() => {
            this.handleVendorDelete();
          }}
          handleImageDelete={(id: string) => {
            this.handleImageDelete(id);
          }}
          isOpenNickNameModel={this.state.isOpenNickNameModel}
          //nickNames={this.nickName}
          editNickName={(id: string) => this.editNickName(id)}
          nickNameSelectionDetails={this.state.nickNameSelectionDetails}
          nickNameActionButtonState={this.state.nickNameActionButtonState}
          nickNameSelection={this._nickNameSelection}
          selectedResourceFamily={this.state.resourceFamily}
          onChangeResourceFamily={(id: string | undefined) =>
            this.onChangeResourceFamily(id)
          }
          openVendorModel={() => {
            this.openVendorModel();
          }}
          isOpenVendorModel={this.state.isOpenNickNameModel}
          // vendors={this.vendorList}
          editVendor={(id: string) => this.editNickName(id)}
          vendorSelectionDetails={this.state.nickNameSelectionDetails}
          vendorActionButtonState={this.state.vendorActionButtonState}
          vendorSelection={this._vendorSelection}
          cpcBasicUnitOfMeasure={this.props.cpcBasicUnitOfMeasure}
          cpcPressureClass={this.props.cpcPressureClass}
          cpcMaterial={this.props.cpcMaterial}
          cpcBrand={this.props.cpcBrand}
          resourceTypes={this.props.resourceTypes}
          resourceFamily={this.state.resourceFamily}
          languageCodes={this.props.languageCodes}
          cpcUnitOfSizeMeasure={this.props.cpcUnitOfSizeMeasure}
          savePicturesOfTheInstallation={(files: FileList | null) =>
            this.props.savePicturesOfTheInstallation(files)
          }
          productCatalogue={this.state.productCatalogue}
          handleProductCatalogueChange={(
            productCatalogue: CorporateProductCatalogue,
            save: boolean,
          ) => {
            this.handleProductCatalogueChange(productCatalogue, save);
          }}
          autoSaveProductCatalogue={() => this.autoSaveProductCatalogue()}
          saveProductCatalogue={() => this.saveProductCatalogue()}
          validationMessages={this.state.validationMessages}
          disabled={this.props.disabled}
          vehicleTrackingNumberList={this.props.vehicleTrackingNumberList ? this.props.vehicleTrackingNumberList : []}
        />
        <NickNameModel
          resetSelection={() => this.resetSelection()}
          promiseOptions={this.promiseOptionsNickName}
          isOpen={this.state.isOpenNickNameModel}
          cpcNickNameIsEdit={this.state.cpcNickNameIsEdit}
          languageCodes={this.props.languageCodes}
          openNickNameModel={this.openNickNameModel}
          saveProductCatalogueNickName={(type: number) => {
            this.saveProductCatalogueNickName(type);
          }}
          handleNickNameChange={(cpcNickName: NickName) => {
            this.handleNickNameChange(cpcNickName);
          }}
          handleNickNameValidation={(
            nickNameValidationMessage: NickNameValidationMessage,
          ) => {
            this.handleNickNameValidation(nickNameValidationMessage);
          }}
          nickName={this.state.cpcNickName}
          nickNameValidationMessages={this.state.nickNameValidationMessages}
        />
        <VendorModel
          resetSelection={() => this.resetSelection()}
          promiseOptions={this.promiseOptions}
          organizations={this.props.organizations}
          handelGetOrganizationByName={(name: string) =>
            this.props.handelGetOrganizationByName(name)
          }
          cpcVendorIsEdit={this.state.cpcVendorIsEdit}
          isOpen={this.state.isOpenVendorModel}
          openVendorModel={this.openVendorModel}
          saveProductCatalogueVendor={(type: number) => {
            this.saveProductCatalogueVendor(type);
          }}
          handleVendorChange={(vendor: Vendor) => {
            this.handleVendorChange(vendor);
          }}
          vendor={this.state.vendor}
        />
      </div>
    );
  }

  private handleProductCatalogueChange = (
    productCatalogue: CorporateProductCatalogue,
    save: boolean,
  ) => {
    if (productCatalogue.status === 1) {
      this.setState({
        isDirty: true,
      });
    }
    if (save) {
      this.setState(
        {
          productCatalogue: productCatalogue,
        },
        this.autoSaveProductCatalogue,
      );
    } else {
      this.setState({
        productCatalogue: productCatalogue,
      });
    }
  };

  private handleVendorChange = (vendor: Vendor) => {
    UprinceLogger.log('vendorChange1', vendor);
    this.setState({
      vendor: vendor,
    });
  };

  private saveProductCatalogueVendor = (type: number) => {
    let productCatalogue = this.state.productCatalogue;
    let cpcVendor = this.state.productCatalogue.cpcVendor;
    let vendor = this.state.vendor;
    let updatedItem = [this.state.vendor];
    if (productCatalogue.cpcVendor != null) {
      if (cpcVendor && this.state.cpcVendorIsEdit) {
        //cpcVendor = cpcVendor.filter((item) => item.id !== this.state.vendor.id);
        //cpcVendor.push(this.state.vendor);
        if (productCatalogue.status === 1 && vendor.uid && vendor.id === null) {
          cpcVendor = cpcVendor.map(
            (obj) => updatedItem.find((o) => o.uid === obj.uid) || obj,
          );
        } else {
          cpcVendor = cpcVendor.map(
            (obj) => updatedItem.find((o) => o.id === obj.id) || obj,
          );
        }
        productCatalogue.cpcVendor = cpcVendor;

        this.setState(
          {
            productCatalogue: productCatalogue,
            isOpenVendorModel: false,
          },
          this.autoSaveProductCatalogue,
        );
      } else {
        if (productCatalogue.status === 1 && !vendor.id) {
          vendor.uid = Date.now().toString();
        }
        productCatalogue.cpcVendor.push(this.state.vendor);
        this.setState(
          {
            productCatalogue: productCatalogue,
            isOpenVendorModel: type !== 1,
            vendor: {
              id: null,
              uid: null,
              company: {
                id: null,
                name: null,
              },
              companyId: null,
              companyName: null,
              maxOrderQuantity: null,
              minOrderQuantity: null,
              preferredParty: false,
              purchasingUnit: null,
              resourceLeadTime: null,
              resourceNumber: null,
              resourcePrice: null,
              resourceTitle: null,
              roundingValue: null,
            },
          },
          this.autoSaveProductCatalogue,
        );
      }
      if (productCatalogue.status === 1) {
        this.setState({
          isDirty: true,
        });
      }
    }
  };

  private handleNickNameChange = (cpcNickName: NickName) => {
    UprinceLogger.log('cpcNickName', cpcNickName);
    this.setState({
      cpcNickName: cpcNickName,
    });
  };

  private handleNickNameValidation = (
    nickNameValidationMessage: NickNameValidationMessage,
  ) => {
    this.setState({
      nickNameValidationMessages: nickNameValidationMessage,
    });
  };

  private saveProductCatalogueNickName = (type: number) => {
    if (this.nickNameValidate()) {
      let productCatalogue = this.state.productCatalogue;
      let cpcResourceNickname = this.state.productCatalogue.cpcResourceNickname;
      let updatedItem = [this.state.cpcNickName];
      let cpcNickName = this.state.cpcNickName;
      if (productCatalogue.cpcResourceNickname != null) {
        if (cpcResourceNickname && this.state.cpcNickNameIsEdit) {
          //cpcResourceNickname = cpcResourceNickname.filter((item) => item.id !== this.state.cpcNickName.id);
          //cpcResourceNickname.push(this.state.cpcNickName);
          if (
            productCatalogue.status === 1 &&
            cpcNickName.uid &&
            cpcNickName.id === null
          ) {
            cpcResourceNickname = cpcResourceNickname.map(
              (obj) => updatedItem.find((o) => o.uid === obj.uid) || obj,
            );
          } else {
            cpcResourceNickname = cpcResourceNickname.map(
              (obj) => updatedItem.find((o) => o.id === obj.id) || obj,
            );
          }

          productCatalogue.cpcResourceNickname = cpcResourceNickname;

          this.setState(
            {
              productCatalogue: productCatalogue,
              isOpenNickNameModel: false,
            },
            this.autoSaveProductCatalogue,
          );
        } else {
          if (productCatalogue.status === 1 && !cpcNickName.id) {
            cpcNickName.uid = Date.now().toString();
          }
          productCatalogue.cpcResourceNickname.push(this.state.cpcNickName);
          this.setState(
            {
              productCatalogue: productCatalogue,
              isOpenNickNameModel: type !== 1,
              cpcNickName: {
                id: null,
                uid: null,
                language: null,
                nickName: null,
                localeCode: null,
              },
            },
            this.autoSaveProductCatalogue,
          );
        }
        if (productCatalogue.status === 1) {
          this.setState({
            isDirty: true,
          });
        }
      }
    }
  };

  private nickNameValidate = (): boolean => {
    let nickNamevalidationMessages = this.state.nickNameValidationMessages;
    let language = this.state.cpcNickName.language;
    let nickName = this.state.cpcNickName.nickName;
    let isValid = true;
    if (_.isNil(language) || _.isEmpty(language)) {
      nickNamevalidationMessages.languageErrorMsg = i18n.t('Language Required');
      isValid = false;
    } else {
      nickNamevalidationMessages.languageErrorMsg = '';
    }
    if (_.isNil(nickName) || _.isEmpty(nickName)) {
      nickNamevalidationMessages.nickNameErrorMsg = i18n.t(
        'Nick Name Required',
      );
      isValid = false;
    } else {
      nickNamevalidationMessages.nickNameErrorMsg = '';
    }

    this.setState({ nickNameValidationMessages: nickNamevalidationMessages });
    return isValid;
  };

  private openNickNameModel = (): void => {
    if (this.state.cpcNickNameIsEdit) {
      this.setState({
        isOpenNickNameModel: !this.state.isOpenNickNameModel,
        cpcNickName: this._nickNameEditDetails(),
      });
    } else {
      this.setState({
        isOpenNickNameModel: !this.state.isOpenNickNameModel,
        cpcNickName: {
          id: null,
          uid: null,
          language: null,
          nickName: null,
          localeCode: null,
        },
      });
    }
  };

  private editNickName = (id: string): void => {
    this.setState({ isOpenNickNameModel: !this.state.isOpenNickNameModel });
  };

  private _getNickNameSelectionDetails(): string | number | {} | [] {
    return this._nickNameSelection.getSelection();
  }

  private _nickNameEditDetails() {
    const selectionCount = this._nickNameSelection.getSelectedCount();
    let nickName = this.state.cpcNickName;

    if (selectionCount == 1) {
      this.setState({
        cpcNickNameIsEdit: true,
      });
      UprinceLogger.log(
        '_nickNameSelection',
        this._nickNameSelection.getSelection(),
      );
      let selectedNickName = this._nickNameSelection.getSelection()[0] as SelectedNickNameItem;
      nickName = {
        id: selectedNickName.id,
        uid: selectedNickName.uid,
        language: selectedNickName.language,
        nickName: selectedNickName.nickName,
        localeCode: selectedNickName.localeCode,
      };
    } else {
      this.setState({
        cpcNickNameIsEdit: false,
      });
    }
    return nickName;
  }

  private handleNickNameDelete = () => {
    this._nickNameSelection.selectToKey('', true);
    if (
      this.state.cpcNickNameDeleteItems &&
      this.state.cpcNickNameDeleteItems.length > 0
    ) {
      UprinceLogger.log(
        'handleNickNameDelete',
        typeof this.state.cpcNickNameDeleteItems,
      );
      this.props.deleteProductCatalogueNickName(
        this.state.cpcNickNameDeleteItems,
      );
    }
    if (this.state.cpcNickNameDeleteUnSavedItems) {
      let productCatalogue = this.state.productCatalogue;
      let cpcResourceNickname = this.state.productCatalogue.cpcResourceNickname;
      if (productCatalogue.cpcResourceNickname != null) {
        if (cpcResourceNickname) {
          let toRemove = [{ nickName: '' }];
          toRemove = this.state.cpcNickNameDeleteUnSavedItems;

          for (var i = cpcResourceNickname.length - 1; i >= 0; i--) {
            for (var j = 0; j < toRemove.length; j++) {
              if (
                cpcResourceNickname[i] &&
                cpcResourceNickname[i].nickName === toRemove[j].nickName &&
                cpcResourceNickname[i].id === null
              ) {
                cpcResourceNickname.splice(i, 1);
              }
            }
          }
          productCatalogue.cpcResourceNickname = cpcResourceNickname;

          this.setState({
            productCatalogue: productCatalogue,
            nickNameActionButtonState: {
              add: false,
              edit: true,
              remove: true,
            },
            cpcNickName: {
              id: null,
              uid: null,
              language: null,
              nickName: null,
              localeCode: null,
            },
            cpcNickNameIsEdit: false,
          });
        }
      }
    }
    if (this.state.productCatalogue.status === 1) {
      this.setState({
        isDirty: true,
      });
    }
  };

  private _nickNameDeleteDetails() {
    let deleteList = [];
    let savedDeleteList = [];
    let selectedNickName = this._nickNameSelection.getSelection() as SelectedNickNameItem[];
    savedDeleteList = selectedNickName.filter((item: any) => {
      return item.id != null;
    });
    deleteList = savedDeleteList.map((item: { id: string }) => {
      return item.id;
    });
    return deleteList;
  }

  private _nickNameDeleteUnSavedDetails() {
    let deleteList = [];
    let unSavedDeleteList = [];
    let selectedNickName = this._nickNameSelection.getSelection() as SelectedNickNameItem[];
    unSavedDeleteList = selectedNickName.filter((item: any) => {
      return item.id === null;
    });
    deleteList = unSavedDeleteList.map((item: { nickName: string }) => {
      return { nickName: item.nickName };
    });
    return deleteList;
  }

  private _getNickNameActionButtonState(): ActionButtonState {
    const selectionCount = this._nickNameSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }

    return disabled;
  }

  private autoSaveProductCatalogue = () => {
    if (this.validate()) {
      if (
        this.state.productCatalogue.resourceTitle &&
        this.state.productCatalogue.resourceTitle.length > 2 &&
        this.state.productCatalogue.status == 0
      ) {
        let productCatalogue = this.state.productCatalogue;
        if (!productCatalogue.id) {
          productCatalogue.id = this.state.uid;
        }
        this.props.saveProductCatalogue(productCatalogue);
      }
    } else {
      this.props.changeSubmitCpc(false);
      this.setState({ submitCpc: false });
    }
  };

  private saveProductCatalogue = () => {
    let productCatalogue = this.state.productCatalogue;
    if (this.validate()) {
      if (
        this.state.productCatalogue.resourceTitle &&
        this.state.productCatalogue.resourceTitle.length > 2
      ) {
        if (!productCatalogue.id) {
          productCatalogue.id = this.state.uid;
        }
        productCatalogue.status = 1;
        this.setState(
          {
            productCatalogue: productCatalogue,
          },
          this.saveActiveProductCatalogue,
        );
      }
      if (productCatalogue.status === 1) {
        this.setState({
          isDirty: false,
        });
      }
    } else {
      this.props.changeSubmitCpc(false);
      this.setState({ submitCpc: false });
    }
  };

  private saveActiveProductCatalogue = () => {
    if (this.validate()) {
      if (
        this.state.productCatalogue.resourceTitle &&
        this.state.productCatalogue.resourceTitle.length > 2 &&
        this.state.productCatalogue.status == 1
      ) {
        let productCatalogue = this.state.productCatalogue;
        if (!productCatalogue.id) {
          productCatalogue.id = this.state.uid;
        }
        this.props.saveProductCatalogue(productCatalogue);
      }
      if (this.state.productCatalogue.status === 1) {
        this.setState({
          isDirty: false,
        });
      }
    } else {
      this.props.changeSubmitCpc(false);
      this.setState({ submitCpc: false });
    }
  };

  private validate = (): boolean => {
    let validationMessages = this.state.validationMessages;
    let title = this.state.productCatalogue.resourceTitle;
    let resourceTypeId = this.state.productCatalogue.resourceTypeId;
    let basicUnitOfMeasure = this.state.productCatalogue.cpcBasicUnitOfMeasureId;
    let isValid = true;
    if (_.isNil(title) || _.isEmpty(title)) {
      validationMessages.resourceTitleErrorMsg = i18n.t('nameRequired');
      isValid = true;
    } else {
      validationMessages.resourceTitleErrorMsg = '';
    }
    if (_.isNil(resourceTypeId) || _.isEmpty(resourceTypeId)) {
      validationMessages.resourceTypeErrorMsg = i18n.t('typeRequired');
      isValid = false;
    } else {
      validationMessages.resourceTypeErrorMsg = '';
    }
    if (_.isNil(basicUnitOfMeasure) || _.isEmpty(basicUnitOfMeasure)) {
      validationMessages.basicUnitOfMeasureErrorMsg = i18n.t('basicUnitOfMeasureRequired');
      isValid = false;
    } else {
      validationMessages.basicUnitOfMeasureErrorMsg = '';
    }

    this.setState({ validationMessages: validationMessages });
    return isValid;
  };

  private openVendorModel = (): void => {
    if (this.state.cpcVendorIsEdit) {
      this.setState({
        isOpenVendorModel: !this.state.isOpenVendorModel,
        vendor: this._vendorEditDetails(),
      });
    } else {
      this.setState({
        isOpenVendorModel: !this.state.isOpenVendorModel,
        vendor: {
          id: null,
          uid: null,
          company: {
            id: null,
            name: null,
          },
          companyId: null,
          companyName: null,
          maxOrderQuantity: null,
          minOrderQuantity: null,
          preferredParty: false,
          purchasingUnit: null,
          resourceLeadTime: null,
          resourceNumber: null,
          resourcePrice: null,
          resourceTitle: null,
          roundingValue: null,
        },
      });
    }
  };

  private onChangeResourceFamily = (treeData: any): void => {
    if (treeData) {
      this.setState({ resourceFamily: treeData });
    }
    if (this.state.productCatalogue.status === 1) {
      this.setState({
        isDirty: true,
      });
    }
  };

  private _vendorDeleteUnSavedDetails() {
    let deleteList = [];
    let unSavedDeleteList = [];
    let selectedVendor = this._vendorSelection.getSelection() as SelectedVendorItem[];
    unSavedDeleteList = selectedVendor.filter((item: any) => {
      return item.id === null;
    });
    deleteList = unSavedDeleteList.map((item: any) => {
      return {
        id: null,
        companyName: item.companyName,
        maxOrderQuantity: item.maxOrderQuantity,
        minOrderQuantity: item.minOrderQuantity,
        preferredParty: item.preferredParty,
        purchasingUnit: item.purchasingUnit,
        resourceLeadTime: item.resourceLeadTime,
        resourceNumber: item.resourceNumber,
        resourcePrice: item.resourcePrice,
        resourceTitle: item.resourceTitle,
        roundingValue: item.roundingValue,
      };
    });
    return deleteList;
  }

  private _vendorDeleteDetails() {
    let deleteList = [];
    let savedDeleteList = [];
    let selectedVendor = this._vendorSelection.getSelection() as SelectedVendorItem[];
    savedDeleteList = selectedVendor.filter((item: any) => {
      return item.id != null;
    });
    deleteList = savedDeleteList.map((item: { id: any }) => {
      return item.id;
    });
    return deleteList;
  }

  private handleVendorDelete = () => {
    this._vendorSelection.selectToKey('', true);
    // this.props.deleteProductCatalogueVendor(this.state.cpcVendorDeleteItems);
    if (
      this.state.cpcVendorDeleteItems &&
      this.state.cpcVendorDeleteItems.length > 0
    ) {
      UprinceLogger.log(
        'handleNickNameDelete',
        typeof this.state.cpcVendorDeleteItems,
      );
      this.props.deleteProductCatalogueVendor(this.state.cpcVendorDeleteItems);
    }
    if (this.state.cpcVendorDeleteUnSavedItems) {
      let productCatalogue = this.state.productCatalogue;
      let cpcVendor = this.state.productCatalogue.cpcVendor;
      if (productCatalogue.cpcResourceNickname != null) {
        if (cpcVendor) {
          let toRemove = [
            {
              id: null,
              companyName: null,
              maxOrderQuantity: null,
              minOrderQuantity: null,
              preferredParty: false,
              purchasingUnit: null,
              resourceLeadTime: null,
              resourceNumber: null,
              resourcePrice: null,
              resourceTitle: null,
              roundingValue: null,
            },
          ];
          toRemove = this.state.cpcVendorDeleteUnSavedItems;
          for (var i = cpcVendor.length - 1; i >= 0; i--) {
            for (var j = 0; j < toRemove.length; j++) {
              if (
                cpcVendor[i] &&
                cpcVendor[i].companyName === toRemove[j].companyName &&
                cpcVendor[i].id === null &&
                cpcVendor[i].preferredParty === toRemove[j].preferredParty &&
                cpcVendor[i].resourceNumber === toRemove[j].resourceNumber &&
                cpcVendor[i].resourcePrice === toRemove[j].resourcePrice &&
                cpcVendor[i].resourceTitle === toRemove[j].resourceTitle &&
                cpcVendor[i].resourceLeadTime ===
                toRemove[j].resourceLeadTime &&
                cpcVendor[i].maxOrderQuantity ===
                toRemove[j].maxOrderQuantity &&
                cpcVendor[i].minOrderQuantity ===
                toRemove[j].minOrderQuantity &&
                cpcVendor[i].purchasingUnit === toRemove[j].purchasingUnit
              ) {
                cpcVendor.splice(i, 1);
              }
            }
          }
          productCatalogue.cpcVendor = cpcVendor;

          this.setState({
            productCatalogue: productCatalogue,
            vendorActionButtonState: {
              add: false,
              edit: true,
              remove: true,
            },
            cpcVendorIsEdit: false,
            vendor: {
              id: null,
              uid: null,
              company: {
                id: null,
                name: null,
              },
              companyId: null,
              companyName: null,
              maxOrderQuantity: null,
              minOrderQuantity: null,
              preferredParty: false,
              purchasingUnit: null,
              resourceLeadTime: null,
              resourceNumber: null,
              resourcePrice: null,
              resourceTitle: null,
              roundingValue: null,
            },
          });
        }
      }
    }
    if (this.state.productCatalogue.status === 1) {
      this.setState({
        isDirty: true,
      });
    }
  };

  private _getVendorSelectionDetails(): string | number | {} | [] {
    return this._vendorSelection.getSelection();
  }

  private _vendorEditDetails() {
    const selectionCount = this._vendorSelection.getSelectedCount();
    let vendor = this.state.vendor;

    if (selectionCount == 1) {
      this.setState({
        cpcVendorIsEdit: true,
      });

      let selectedVendor = this._vendorSelection.getSelection()[0] as SelectedVendorItem;
      vendor = {
        id: selectedVendor.id,
        uid: selectedVendor.uid,
        company: {
          id: (selectedVendor.company) ? selectedVendor.company.id : null,
          name: (selectedVendor.company) ? selectedVendor.company.name : null,
        },
        companyId: selectedVendor.companyId,
        companyName: selectedVendor.companyName,
        maxOrderQuantity: selectedVendor.maxOrderQuantity,
        minOrderQuantity: selectedVendor.minOrderQuantity,
        preferredParty: selectedVendor.preferredParty,
        purchasingUnit: selectedVendor.purchasingUnit,
        resourceLeadTime: selectedVendor.resourceLeadTime,
        resourceNumber: selectedVendor.resourceNumber,
        resourcePrice: selectedVendor.resourcePrice,
        resourceTitle: selectedVendor.resourceTitle,
        roundingValue: selectedVendor.roundingValue,
      };
    } else {
      this.setState({
        cpcVendorIsEdit: false,
      });
    }
    return vendor;
  }

  private _getVendorActionButtonState(): ActionButtonState {
    const selectionCount = this._vendorSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    UprinceLogger.log('_vendorSelection', disabled);
    return disabled;
  }

  private handleImageDelete = (id: string) => {
    this.props.deleteProductCatalogueImage([id]);
  };

  private getCompanyByName = async (name: string) => {
    let response = await client.get(
      `CentralAddressBook/ReadCabOrganizationList/${name}`,
    );

    return this.formatCompanyResponse(response);
    // if(name) {
    //   let response = await client.get(`CentralAddressBook/ReadCabOrganizationList/${name}`)
    //   console.log('COMPANY RES', JSON.stringify(response))
    //
    //   return this.formatCompanyResponse(response)
    // }else{
    //   let response = await client.get(`CentralAddressBook/ReadCabOrganizationList/a`)
    //   console.log('COMPANY RES', JSON.stringify(response))
    //
    //   return this.formatCompanyResponse(response)
    // }
  };

  private getLanguageByName = async (name: string) => {
    if (name) {
      return this.props.languageCodes.filter((item: any) =>
        item.label.toLowerCase().includes(name.toLowerCase()),
      );
    } else {
      return (
        this.props.languageCodes
          //.slice(0, 50)
          .filter((item: any) =>
            item.label.toLowerCase().includes(name.toLowerCase()),
          )
      );
    }
  };

  private formatCompanyResponse = (response: any) => {
    let data: { value: string; label: string }[] = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
    }
    return data;
  };

  private promiseOptions = (inputValue: any) =>
    new Promise((resolve) => {
      //setTimeout(() => {
      resolve(this.getCompanyByName(inputValue));
      // }, 1000);
    });

  private promiseOptionsNickName = (inputValue: any) =>
    new Promise((resolve) => {
      resolve(this.getLanguageByName(inputValue));
    });
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    allProductCatalogue: state.productCatalogue.allProductCatalogue,
  };
};

const mapDispatchToProps = {
  saveProductCatalogue,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CpcDocumentPane),
);

import React from 'react';
import { Label, Stack, Link, Text, ActionButton } from '@fluentui/react';
import { SearchBox, ISearchBox } from '@fluentui/react/lib/SearchBox';
// import { SearchBox } from '@fluentui/react/lib/SearchBox';
import {
  TooltipHost,
  DirectionalHint,
} from '@fluentui/react';
import { initializeIcons } from '@uifabric/icons';
import './../../components/component.css';
import history from './../../history';
import { Icon, mergeStyleSets } from '@fluentui/react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { iconColors, uPrinceTheme } from '../../../theme';
import { messageService } from '../../services/messageService';

initializeIcons();

interface Props extends WithTranslation {
  onFilterQRCodeType: (type: number) => void;
}

interface State {
  divClass: string;
  selectedCodeType: number;
}

const classNames = mergeStyleSets({
  wrapper: {
   // height: 'calc(110vh )!important',
    height: 'auto!important',
    position: 'relative',
    //height:screen.width > 1280 ?'calc(86.5vh )!important':'calc(100vh )!important'
  },
  selected: {
    fontWeight: 'bold',
    color: '#854114!important',
  },
});

class QRCodeSideBar extends React.Component<Props, State> {
  private focusSearch = React.createRef<ISearchBox>();

  constructor(props: Props) {
    super(props);
    this.state = { divClass: 'filter-panel-left', selectedCodeType: -1 };
  }

  getIconActiveStyle = (type:number) => {
    return this.state.selectedCodeType === type ? {color:iconColors.iconActiveColor}:{color:uPrinceTheme.palette.themePrimary}
  }

  componentDidMount(){
    if (this.state.divClass === 'filter-panel-left') {
      if (window.innerWidth > 1300) {
      messageService.sendMessage({shortCutPaneExpanded:true})
      }
    } else {
      messageService.sendMessage({shortCutPaneExpanded:false})
    }
  }

  public render(): JSX.Element {
    const { t } = this.props;
    return (
      <div className={`${this.state.divClass} ${classNames.wrapper}`}  onMouseOver={()=>{
        if (window.innerWidth > 1100 && window.innerWidth < 1600)
        messageService.sendMessage({shortCutPaneExpanded:true})
      }} onMouseLeave={()=>{
        if (window.innerWidth > 1100 && window.innerWidth < 1600)
        messageService.sendMessage({shortCutPaneExpanded:false})
      }}>
        <div className="inner-container">
          <i
            onClick={this._toggleOverlay}
            className="ms-Icon ms-Icon--Back back-arrow"
            aria-hidden="true"
          ></i>
          <i
            onClick={this._toggleOverlayFwd}
            className="ms-Icon ms-Icon--Forward fwd-arrow"
            aria-hidden="true"
          ></i>

          <div className="filter-data">
            <Label>{t('qRCode')}</Label>
            {/* <SearchBox
              componentRef={this.focusSearch}
              className="txt-filter-1"
              placeholder="Filter data"
              onSearch={(newValue) => //console.log('value is ' + newValue)}
              onFocus={() =>
                this.setState({ divClass: 'filter-panel-left filter-focus' })
              }
              onBlur={() => this.setState({ divClass: 'filter-panel-left' })}
              onChange={() => //console.log('onChange called')}
            />
            <TooltipHost
              content="Filter data"
              id="filter-search"
              calloutProps={{ gapSpace: 0 }}
              directionalHint={DirectionalHint.rightCenter}
            >
              <i
                className="ms-Icon ms-Icon--Search search-icon-only"
                aria-hidden="true"
                onClick={this._openFilterPanel}
                aria-labelledby="filter-search"
              ></i>
            </TooltipHost> */}
          </div>

          <div className="filter-option-list">
            <Stack
              gap={15}
              verticalFill
              styles={{
                root: {
                  width: '100%',
                  verticalAlign: 'center',
                },
              }}
            >
              <Link
                className={`${
                  this.state.selectedCodeType == -1 ? classNames.selected : ''
                }`}
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  this.setState({ selectedCodeType: -1 });
                  this.props.onFilterQRCodeType(-1);
                }}
              >
                <TooltipHost
                  content={t('all')}
                  id="filter-opt-3"
                  calloutProps={{ gapSpace: 0 }}
                  directionalHint={DirectionalHint.rightCenter}
                >
                  <i
                    className="ms-Icon ms-Icon--ViewAll"
                    aria-labelledby="filter-opt-3"
                    aria-hidden="true"
                    style={this.getIconActiveStyle(-1)}
                  ></i>
                </TooltipHost>
                <i
                  className="filter-icon ms-Icon ms-Icon--ViewAll filter-icon"
                  aria-hidden="true"
                  style={this.getIconActiveStyle(-1)}
                ></i>
                <Text>{t('all')}</Text>
              </Link>

              <Link
                className={`${
                  this.state.selectedCodeType == 4 ? classNames.selected : ''
                }`}
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  this.setState({ selectedCodeType: 4 });
                  this.props.onFilterQRCodeType(4);
                }}
              >
                <TooltipHost
                  content={t('start')}
                  id="filter-opt-3"
                  calloutProps={{ gapSpace: 0 }}
                  directionalHint={DirectionalHint.rightCenter}
                >
                  <i
                    className="ms-Icon ms-Icon--Clock"
                    aria-labelledby="filter-opt-3"
                    aria-hidden="true"
                    style={this.getIconActiveStyle(4)}
                  ></i>
                </TooltipHost>
                <i
                  className="filter-icon ms-Icon ms-Icon--Clock filter-icon"
                  aria-hidden="true"
                  style={this.getIconActiveStyle(4)}
                ></i>
                <Text>{t('start')}</Text>
              </Link>

              <Link
                className={`${
                  this.state.selectedCodeType == 0 ? classNames.selected : ''
                }`}
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  this.setState({ selectedCodeType: 0 });
                  this.props.onFilterQRCodeType(0);
                }}
              >
                <TooltipHost
                  content={t('travel')}
                  id="filter-opt-3"
                  calloutProps={{ gapSpace: 0 }}
                  directionalHint={DirectionalHint.rightCenter}
                >
                  <i
                    className="ms-Icon ms-Icon--BusSolid"
                    aria-labelledby="filter-opt-3"
                    aria-hidden="true"
                    style={this.getIconActiveStyle(0)}
                  ></i>
                </TooltipHost>
                <i
                  className="filter-icon ms-Icon ms-Icon--BusSolid filter-icon"
                  aria-hidden="true"
                  style={this.getIconActiveStyle(0)}
                ></i>

                <Text>{t('travel')}</Text>
              </Link>

              <Link
                className={`${
                  this.state.selectedCodeType == 1 ? classNames.selected : ''
                }`}
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  this.setState({ selectedCodeType: 1 });
                  this.props.onFilterQRCodeType(1);
                }}
              >
                <TooltipHost
                  content={t('work')}
                  id="filter-opt-3"
                  calloutProps={{ gapSpace: 0 }}
                  directionalHint={DirectionalHint.rightCenter}
                >
                  <i
                    className="ms-Icon ms-Icon--Work"
                    aria-labelledby="filter-opt-3"
                    aria-hidden="true"
                    style={this.getIconActiveStyle(1)}
                  ></i>
                </TooltipHost>
                <i
                  className="filter-icon ms-Icon ms-Icon--Work filter-icon"
                  aria-hidden="true"
                  style={this.getIconActiveStyle(1)}
                ></i>

                <Text>{t('work')}</Text>
              </Link>
              <Link
                className={`${
                  this.state.selectedCodeType == 6 ? classNames.selected : ''
                }`}
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  this.setState({ selectedCodeType: 6 });
                  this.props.onFilterQRCodeType(6);
                }}
              >
                <TooltipHost
                  content={t('break')}
                  id="filter-opt-3"
                  calloutProps={{ gapSpace: 0 }}
                  directionalHint={DirectionalHint.rightCenter}
                >
                  <i
                    className="ms-Icon ms-Icon--Timer"
                    aria-labelledby="filter-opt-3"
                    aria-hidden="true"
                    style={this.getIconActiveStyle(6)}
                  ></i>
                </TooltipHost>
                <i
                  className="filter-icon ms-Icon ms-Icon--Timer filter-icon"
                  aria-hidden="true"
                  style={this.getIconActiveStyle(6)}
                ></i>

                <Text>{t('break')}</Text>
              </Link>
              <Link
                className={`${
                  this.state.selectedCodeType == 2 ? classNames.selected : ''
                }`}
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  this.setState({ selectedCodeType: 2 });
                  this.props.onFilterQRCodeType(2);
                }}
              >
                <TooltipHost
                  content={t('unload')}
                  id="filter-opt-3"
                  calloutProps={{ gapSpace: 0 }}
                  directionalHint={DirectionalHint.rightCenter}
                >
                  <i
                    className="ms-Icon ms-Icon--Download"
                    aria-labelledby="filter-opt-3"
                    aria-hidden="true"
                    style={this.getIconActiveStyle(2)}
                  ></i>
                </TooltipHost>
                <i
                  className="filter-icon ms-Icon ms-Icon--Download filter-icon"
                  aria-hidden="true"
                  style={this.getIconActiveStyle(2)}
                ></i>
                <Text>{t('unload')}</Text>
              </Link>
              <Link
                className={`${
                  this.state.selectedCodeType == 5 ? classNames.selected : ''
                }`}
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  this.setState({ selectedCodeType: 5 });
                  this.props.onFilterQRCodeType(5);
                }}
              >
                <TooltipHost
                  content={t('stop')}
                  id="filter-opt-3"
                  calloutProps={{ gapSpace: 0 }}
                  directionalHint={DirectionalHint.rightCenter}
                >
                  <i
                    className="ms-Icon ms-Icon--Stop"
                    aria-labelledby="filter-opt-3"
                    aria-hidden="true"
                    style={this.getIconActiveStyle(5)}
                  ></i>
                </TooltipHost>
                <i
                  className="filter-icon ms-Icon ms-Icon--Stop filter-icon"
                  aria-hidden="true"
                  style={this.getIconActiveStyle(5)}
                ></i>

                <Text>{t('stop')}</Text>
              </Link>
            </Stack>
          </div>
        </div>
      </div>
    );
  }

  private _toggleOverlay = (): void => {
    this.setState({ divClass: 'filter-panel-left panel-move' });
    messageService.sendMessage({shortCutPaneExpanded:false})
    
  };
  private _toggleOverlayFwd = (): void => {
    this.setState({ divClass: 'filter-panel-left' });
    messageService.sendMessage({shortCutPaneExpanded:true})
  };
  private _openFilterPanel = (): void => {
    this.setState({ divClass: 'filter-panel-left xxx' });
    setTimeout(() => {
      if (this.focusSearch.current != null) {
        this.focusSearch.current.focus();
      }
    }, 150);
  };
}

export default withTranslation()(QRCodeSideBar);

import * as React from 'react';
import { useEffect } from 'react';
import Input from '../../../../shared/fluentUI9/Input/Input';
import { Icon, mergeStyleSets } from '@fluentui/react';
import { Field, Form } from 'react-final-form';
import { createForm } from 'final-form';
import TextEditor from '../../../../shared/textEditor/textEditor';
import { useTranslation } from 'react-i18next';
import { getWbsTaskById, getWbsTaxonomy, saveTask } from '../../../../reducers/wbsTaskReducer';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { messageService } from '../../../../services/messageService';
import history from '../../../../history';
import { formatDateTimeToISOString, getContractingUnit } from '../../../../shared/util';
import { ParamTypes } from '../../../../types/uPrince';
import { saveWbsStateAttr } from '../../../../reducers/wbsReducer';
import Dropdown from '../../../../shared/fluentUI9/Dropdown/Dropdown';
import i18n from '../../../../../i18n';
import { DatePickerAdapter } from '../../../../shared/fluentFinalForm';
import { readWbsTaxonomyByTemId, saveWbsCreateStateAttr } from '../../../../reducers/wbsCreateReducer';
import { Divider, Label } from '@fluentui/react-components';
import WbsTaxonomy from '../../../wbsTask/documentPane/components/WbsTaxonomy';
import Tags from '../../../wbsTask/documentPane/components/Tags';
import PeopleTags from '../../../wbsTask/documentPane/components/PeopleTags';
import InstructionsCheckList from '../../../wbsTask/documentPane/components/InstructionsCheckList';
import ConversationTaskComponent from '../../../wbsTask/documentPane/components/ConversationTaskComponent';
import TimeLine from '../../../wbsTask/documentPane/components/TimeLine';
import Document from '../../../wbsTask/documentPane/components/Document';
import { WBS_TAXONOMY_LEVEL } from '../../../../types/wbs';
import ErrorLabel from '../../../../shared/fluentUI9/Label/ErrorLabel';
import { TASK_STATUS } from '../../../../types/wbsTask';

const classNames = mergeStyleSets({
  fullWidth: { width: '100%', marginTop: 20 },
  halfWidth: { width: '49%' },
  addNewTaskForm: {
    height: '100%',
    width: '99%',
    overflow: 'auto',
  },
  taskForm: {
    width: 'auto',
  },
});

const WbsCuTaskDocumentPane = (props: { isNewTask?: any }) => {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const { featureId }: ParamTypes = params;
  // const paths = window.location.pathname.split('/');
  // const [isSaved, setIsSaved] = useState(false);

  const { reloadDocPane, newWbsTaskId, wbsTaskDropdowns } = useSelector(
    (state: any) => state.wbsTask,
  );
  const formData = useSelector((state: any) => state.wbsTask.formData);
  const wbsTaskId = useSelector((state: any) => state.wbs.wbsTaskId);
  const taxonomyFeatureId = useSelector((state: any) => state.wbs.taxonomyFeatureId);

  useEffect(() => {
    featureId && getFormData(featureId);
    taxonomyFeatureId && dispatch(readWbsTaxonomyByTemId(taxonomyFeatureId));

    const subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.saveWbsTask) {
          formRef.current.submit();
        }
        if (data.data.resetWbsTaskForm) {
          formRef.current.restart(getInitialFormValues());
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);


  useEffect(() => {
    // console.log({ params });
    featureId && getFormData(featureId);

  }, [params]);

  useEffect(() => {
    if (reloadDocPane) {
      // console.log({ reloadDocPane });
      featureId && getFormData(featureId);
    }
  }, [reloadDocPane]);

  useEffect(() => {
    if (newWbsTaskId) {
      history.push({
        pathname: `/CU/${getContractingUnit()}/wbs-cu-task/${newWbsTaskId}`,
      });
    }
  }, [newWbsTaskId]);

  useEffect(() => {
    if (wbsTaskId) {
      // console.log({ wbsTaskId });
      getFormData(wbsTaskId);
      dispatch(saveWbsStateAttr('wbsTaskId', null));
    }
  }, [wbsTaskId]);

  const getFormData = (id: string) => {
    dispatch(saveWbsCreateStateAttr('reloadWbsProjectListPane', false));
    if (id) {
      Promise.all([dispatch(getWbsTaskById({ id: id }))]).then((res: any) => {
        dispatch(saveWbsCreateStateAttr('reloadWbsProjectListPane', true));
        if (res[0]?.payload?.data?.result?.templateId) dispatch(readWbsTaxonomyByTemId(res[0]?.payload?.data?.result?.templateId));
      });
      dispatch(saveWbsCreateStateAttr('reloadWbsProjectListPane', false));
    }
  };

  const getPayloadId = (response: any) => {
    if (response && response[0]) {
      let payload = response[0]?.payload;

      return payload?.data?.result;
    }
    return null;
  };

  const onSubmit = async (values: any) => {
    const sleep = (ms: number | undefined) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);

    Promise.all([dispatch(saveTask(values))]).then((res) => {
      // if (getProject()) {
      dispatch(getWbsTaskById({ id: values?.id ? values?.id : getPayloadId(res) }));
      dispatch(getWbsTaxonomy());
      // } else {
      dispatch(readWbsTaxonomyByTemId(featureId));
      // }
    });
  };

  const getInitialFormValues = () => {
    // console.log('task form data >', formData);
    // console.log('props?.isNewTask', props?.isNewTask);
    if (props?.isNewTask && props?.isNewTask?.pbsId && !formData?.id) {
      return {
        id: null,
        name: null,
        note: null,
        wbsTaxonomyId: props?.isNewTask?.pbsId,
        statusId: TASK_STATUS.PENDING_DEVELOPMENT,
        productId: props?.isNewTask?.productId,
        pbsId: props?.isNewTask?.pbsId,
        targetDate: '2023-11-15T18:30:00.000Z',
        tags: [],
        toPerson: [],
        cc: [],
        instructions: [],
        instructionsTest: [],
        documents: [],
      };
    } else if (formData && formData?.id) {
      if (formRef?.current?.getState().values?.id) {
        return { ...formRef?.current?.getState().values };
      }
      return { ...formData };
    } else {
      return {
        id: null,
        name: null,
        note: null,
        wbsTaxonomyId: null,
        statusId: TASK_STATUS.PENDING_DEVELOPMENT,
        productId: null,
        pbsId: null,
        targetDate: '2023-11-15T18:30:00.000Z',
        tags: [],
        toPerson: [],
        cc: [],
        instructions: [],
        documents: [],
      };
    }

  };

  const formRef = React.useRef(createForm({ onSubmit: onSubmit }));

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={getInitialFormValues()}
        mutators={{
          setToPersons: (args, state) => {
            const field = state.fields.toPerson;
            field.change(args[0]);
          },
          setCcPersons: (args, state) => {
            const field = state.fields.cc;
            field.change(args[0]);
          },
          setPbsIdPersons: (args, state) => {
            const field = state.fields.pbsId;
            field.change(args[0]);
          },
          setProductIdPersons: (args, state) => {
            const field = state.fields.productId;
            field.change(args[0]);
          },
        }}
        validate={values => {
          const errors: any = {};
          if (!values?.name) {
            errors.name = t('nameRequired');
          }
          if (!values?.wbsTaxonomyId) {
            errors.wbsTaxonomyId = t('productRequired');
          }
          return errors;
        }}
        render={({
                   handleSubmit,
                   form,
                   // submitting,
                   // pristine,
                   values,
                 }) => {
          formRef.current = form;
          return (
            <form onSubmit={handleSubmit} noValidate
                  className={props?.isNewTask ? classNames?.addNewTaskForm : classNames?.taskForm}>
              <div className='document-pane-card'>
                <Field
                  name='toPerson'
                  component={'input'}
                  type={'text'}
                  disabled={true}
                  hidden
                />
                <Field
                  name='cc'
                  component={'input'}
                  type={'text'}
                  disabled={true}
                  hidden
                />
                <Field
                  name='pbsId'
                  component={'input'}
                  type={'text'}
                  disabled={true}
                  hidden
                />
                <Field
                  name='productId'
                  component={'input'}
                  type={'text'}
                  disabled={true}
                  hidden
                />

                <div className={`${classNames.fullWidth}`}>
                  <Field name='wbsTaxonomyId'>
                    {({ input, meta }) => (
                      <>
                        <WbsTaxonomy
                          disabled={false}
                          value={values?.wbsTaxonomyId ?? ''}
                          onChange={(node: any) => {
                            input.onChange(node?.id);
                            form.mutators.setPbsIdPersons(node?.pbsId);
                            form.mutators.setProductIdPersons(node?.productId);
                          }}
                          checkboxHandler={(wbsTaxonomyLevelId: string) => (wbsTaxonomyLevelId === WBS_TAXONOMY_LEVEL.PRODUCT || wbsTaxonomyLevelId === WBS_TAXONOMY_LEVEL.ISSUE)}
                        />
                        {meta.touched && meta.error && !meta.submitting && <ErrorLabel errMsg={meta.error} />}
                      </>
                    )}
                  </Field>
                </div>

                <Field name='name'>
                  {({ input, meta }) => (
                    <Input onChange={(value: any) => {
                      input.onChange(value);
                    }}
                           placeholder={t('taskName')}
                           contentBefore={<Icon iconName={'AlarmClock'} />}
                           appearance='underline'
                           value={values?.name}
                           errorMessage={meta.touched && meta.error && !meta.submitting ? meta.error : ''}
                           focus={true}
                    />
                  )}
                </Field>

                <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                  <div className={`${classNames.fullWidth}`}>
                    <Field
                      name='date'
                      component={DatePickerAdapter}
                      lable={t('dueDate')}
                      parse={value => value && formatDateTimeToISOString(value)}
                      format={value => (value ? new Date(value) : null)}
                      // minDate={new Date()}
                    />
                    {/*<Field name="targetDate">*/}
                    {/*  {({ input, meta }) => (*/}
                    {/*    <DatePickerF9*/}
                    {/*      selectedDate={values?.targetDate?new Date(values?.targetDate):null}*/}
                    {/*      onChange={(date:any)=>{*/}
                    {/*        input.onChange(formatDateTimeToISOString(date))*/}
                    {/*      }}*/}
                    {/*      label={t('targetDate')}*/}
                    {/*    />*/}
                    {/*  )}*/}
                    {/*</Field>*/}
                  </div>

                  <div className={`${classNames.fullWidth}`} style={{ paddingTop: 5 }}>
                    <Field name='statusId'>
                      {({ input }) => (
                        <Dropdown
                          label={i18n.t('status')}
                          placeholder={i18n.t('select')}
                          options={wbsTaskDropdowns?.status ? wbsTaskDropdowns?.status : []}
                          value={values?.statusId}
                          onChange={(value: any) => {
                            if (value?.key) {
                              input.onChange(value?.key);
                            }
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className={`${classNames.fullWidth} ck-small-toolbar`}>
                  <Label>{`${t('note')}`}</Label>
                  <Field name='note'>
                    {({ input }) => (
                      <TextEditor
                        value={values?.note ? values?.note : ''}
                        onChange={(value: any) => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  </Field>
                </div>

                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name='tags'
                  >
                    {({ input }) => (
                      <Tags
                        value={values?.tags ? values?.tags : []}
                        onChange={(value: any) => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  </Field>
                </div>

                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name='peopleTags'
                  >
                    {({ input }) => (
                      <PeopleTags
                        to={values?.toPerson ? values?.toPerson : []}
                        cc={values?.cc ? values?.cc : []}
                        onToChange={(value: any) => {
                          if (value) {
                            // console.log("To",{value});
                            form.mutators.setToPersons(value);
                          }
                        }}
                        onCCChange={(value: any) => {
                          if (value) {
                            // console.log('cc', { value });
                            form.mutators.setCcPersons(value);
                          }
                        }}
                      />
                    )}
                  </Field>
                </div>

                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name='instructions'
                  >
                    {({ input }) => (
                      <InstructionsCheckList
                        instructions={values?.instructions ? values?.instructions : []}
                        onChange={(value: any) => {
                          // console.log({value});
                          input.onChange(value);
                        }}
                        formData={values}
                      />
                    )}
                  </Field>
                </div>

                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name='documents'
                  >
                    {({ input }) => (
                      <Document
                        documents={values?.documents ? values?.documents : []}
                        onChange={(value: any) => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  </Field>
                </div>

                <div className={`${classNames.fullWidth}`}>
                  <Field name='conversations'>
                    {({ input }) => (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex' }}>
                          <Label style={{ marginTop: 10 }} size='large'> {t('conversations')} </Label>
                        </div>
                        <div style={{ marginTop: 5, marginBottom: 10 }}><Divider /></div>
                        <div style={{ marginBottom: 10 }}>
                          <ConversationTaskComponent
                            conversations={values.conversations ?? []}
                          />
                        </div>
                      </div>
                    )}
                  </Field>
                </div>


                <div className={`${classNames.fullWidth}`}>
                  <Field name='timeLine'>
                    {({ input }) => (
                      <TimeLine
                        value={values?.timeLine}
                        onChange={(value: any) => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  </Field>
                </div>
              </div>

              {/*<div style={{width:500,height:600,overflow:'auto'}}>*/}
              {/*  <pre>  {JSON.stringify(values,null,2)}</pre>*/}
              {/*</div>*/}

            </form>
          );
        }}
      />
    </>
  );
};

export default WbsCuTaskDocumentPane;

import {
  FontWeights,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  mergeStyleSets,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import React, { useRef } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { uPrinceTheme } from '../../../../../theme';
import { DropDownAdaptater, TextFieldFF } from '../../../../shared/fluentFinalForm';
import { v4 as uuidv4 } from 'uuid';
import { uploadInstructionDoc } from '../../../../reducers/instructionRegisterReducer';
import { OnBlur, OnFocus } from 'react-final-form-listeners';
import { UPLOAD_URL } from '../../../../types/contract';
import ProgressBar from '../../../../shared/progressBar/progressBar';
import DragAndDropFileUpload from '../../../../shared/Dropzone/FileDropZone';

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '40vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const addWhiteIconButtonStyles = {
  root: { color: uPrinceTheme.palette.white },
  rootHovered: { color: theme.palette.neutralDark },
};
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};
const uploadIconWhite: IIconProps = {
  iconName: 'Upload',
  styles: addWhiteIconButtonStyles,
};

const DocumentationModalContent = (props: {
  documentation: any;
  disabled: boolean;
  onChange: any;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { showProgressBar }: any = useSelector(
    (state: any) => state.ir,
  );

  const inputFile: any = useRef(null);
  const ref: any = useRef(null);

  const stackItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 50,
      width: 0,
      flexGrow: 1,
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
  };

  const stackStyles: IStackStyles = { root: { padding: 0 } };

  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
    fullWidth: { width: '100%' },
    halfWidth: { width: '49%' },
    marginTop: { marginTop: 8 },
  });

  const getInitialValues = (documentation: any) => {
    if (documentation) {
      return documentation;
    }
    return {
      id: null,
      title: null,
      link: null,
    };
  };

  const getFileType = (type: string) => {
    let typeId;
    // console.log("FILE TYPE: ", type);

    switch (type) {
      case 'image/jpeg':
        // return typeId = 'oo10e768-msms-po02-Lot2-ee367a82adooo';  //Image
        return typeId = 'FileImage';  //Image

      case 'image/png':
        // return typeId = 'oo10e768-msms-po02-Lot2-ee367a82adooo';  //Image
        return typeId = 'FileImage';  //Image

      case 'image/webp':
        // return typeId = 'oo10e768-msms-po02-Lot2-ee367a82adooo';  //Image
        return typeId = 'FileImage';  //Image

      case 'application/pdf':
        // return typeId = 'oo10e768-msms-po02-Lot1-ee367a82adooo';  //PDF
        return typeId = 'PDF';  //PDF

      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        // return typeId = '2210e768-msms-po02-Lot4-ee367a82ad22';  //Word Document
        return typeId = 'WordDocument';  //Word Document

      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        // return typeId = '2210e768-msms-po02-Lot4-ee367a82ad22';  //Word Document
        return typeId = 'ExcelDocument';  //Word Document

      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        // return typeId = '2210e768-msms-po02-Lot4-ee367a82ad22';  //Word Document
        return typeId = 'PowerPointDocument';  //Word Document

      default:
        return typeId = 'Website';   //URL
    }
  };
  const handleFileDrop = (acceptedFiles: File[], form: any) => {
    const formData = new FormData();
    if (acceptedFiles.length > 0) {
      formData.append('file', acceptedFiles[0]);
      formData.append('id', uuidv4());

      // Dispatch the file upload action
      Promise.all([
        dispatch(uploadInstructionDoc(formData)),
        form.mutators.setDocumentType(getFileType(acceptedFiles[0].type)),
      ]).then((response: any) => {
        if (response[0]?.payload?.data?.result) {
          form.mutators.setDocumentLink(response[0]?.payload?.data?.result);
        } else {
          form.mutators.setDocumentLink(null);
        }
        // if (response[0]?.payload?.data?.result) {
        //   // Set the certification URL in the form
        //   form.mutators.setCertificationUrl(response[0]?.payload?.data?.result);
        // } else {
        //   form.mutators.setCertificationUrl(null);
        // }
      });
    }
  };
  return (
    <>
      <ProgressBar show={showProgressBar} />
      <div className={contentStyles.body}>
        <div className='proj-detail-content inner'>
          <div className='ms-Grid-row'>
            <Form
              //form={formRef.current}
              onSubmit={(values) => {
                if (values?.id) {
                  props.onChange({ ...values, value: values?.link });
                } else {
                  props.onChange({ ...values, id: uuidv4(), value: values?.link });
                }
              }}
              initialValues={getInitialValues(props.documentation)}
              mutators={{
                setDocumentLink: (args, state, utils) => {
                  const field = state.fields.link;
                  field.change(args[0]);
                },
                setDocumentType: (args, state, utils) => {
                  const field = state.fields.type;
                  field.change(args[0]);
                },
              }}
              validate={(values) => {
                const errors: any = {};

                if (!values?.title) {
                  errors.title = t('titleRequired');
                }
                if (!values?.type && !showProgressBar) {
                  errors.typeId = t('typeRequired');
                }
                if (!values?.link && !showProgressBar) {
                  errors.link = t('linkRequired');
                }
                return errors;
              }}

              render={({ handleSubmit, form, submitting, pristine, values }) => {

                ref.current = form;
                return (<div>
                    <FormSpy onChange={(spyProps: any) => {
                    }} />
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name='title'
                            component={TextFieldFF}
                            lable={t('title')}
                            required={true}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name='type'
                            component={DropDownAdaptater}
                            options={[
                              { key: 'PDF', text: 'PDF' },
                              { key: 'FileImage', text: 'Image' },
                              { key: 'Website', text: 'URL' },
                              { key: 'WordDocument', text: 'Word Document' },
                              { key: 'ExcelDocument', text: 'Excel Document' },
                              { key: 'PowerPointDocument', text: 'Power Point Document' },
                            ]}
                            lable={t('type')}
                            placeholder={t('type')}
                            required={true}
                            disabled={true}
                          />

                        </div>
                      </Stack.Item>
                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth} ${classNames.marginTop}`}>
                          <Field
                            name='link'
                            component={TextFieldFF}
                            lable={t('link')}
                            disabled={false}
                            placeholder={t('copyURLhere')}
                            required={true}
                          />
                          <OnFocus name='link'>
                            {() => {
                              const sub = values?.link?.substring(0, 30);
                              if (sub && sub !== UPLOAD_URL) {
                                form.mutators.setDocumentType(getFileType(''));
                              }
                            }}
                          </OnFocus>
                          <OnBlur name='link'>
                            {() => {
                              const sub = values?.link?.substring(0, 30);
                              if (sub && sub !== UPLOAD_URL) {
                                form.mutators.setDocumentType(getFileType(''));
                              }
                            }}
                          </OnBlur>
                        </div>
                      </Stack.Item>
                    </Stack>
                    <div style={{marginTop:'23px',width:'18%',marginLeft:'9px'}}>
                    <DragAndDropFileUpload multiple={false} getUploadedFile={(files) => handleFileDrop(files, form)} disabled={false} />
                      </div>
                    
                    {/* <input
                      type='file'
                      id='file'
                      ref={inputFile}
                      hidden
                      onChange={(event: any) => {
                        const formData = new FormData();
                        if (event?.target?.files[0]) {
                          formData.append('file', event.target.files[0]);
                          formData.append('id', uuidv4());
                          Promise.all([
                            dispatch(uploadInstructionDoc(formData)),
                            form.mutators.setDocumentType(getFileType(event.target.files[0].type)),
                          ]).then((response: any) => {
                            if (response[0]?.payload?.data?.result) {
                              form.mutators.setDocumentLink(response[0]?.payload?.data?.result);
                            } else {
                              form.mutators.setDocumentLink(null);
                            }
                          });
                        }
                      }}
                    />
                    <PrimaryButton
                      iconProps={uploadIconWhite}
                      text={t('upload')}
                      disabled={false}
                      style={{ marginTop: 32, marginLeft: 10 }}
                      onClick={() => {
                        inputFile?.current?.click();
                      }}
                    /> */}

                    <div className={contentStyles.footer}>
                      <PrimaryButton
                        iconProps={addIconWhite}
                        text={t('save')}
                        disabled={submitting || pristine}
                        style={{ marginTop: 38, marginBottom: 10 }}
                        onClick={() => {
                          form.submit();
                        }}
                      />
                      {/* {true && <PrimaryButton
                                        iconProps={addIconWhite}
                                        disabled={submitting || pristine}
                                        text={t('saveandNew')}
                                        style={{ marginTop: 20, marginBottom: 10, marginLeft: 10 }}
                                        onClick={() => {
                                                props.onChange(handleSubmit(values));
                                                form.submit();
                                                props.setSaveAndNew(true);
                                                dispatch(saveInstructionStateAttr('uploadedInstructionLink', null));
                                                form.reset();
                                            }
                                        }
                                        // disabled={disableSave}
          />} */}
                    </div>
                    {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentationModalContent;
import {
    CREATE_MACHINE_TAXONOMY_NODE_EP,
    MILESTONES_SAVE_EP,
    READ_MILESTONES_BY_ID_EP,
    READ_MILESTONES_DROPDOWN_EP,
    READ_MILESTONES_LIST_EP,
    READ_MILESTONES_SHORTCUT_PANE_EP,
} from '../shared/endpoints';
import {
    CLEAR_MILESTONES_FORM_DATA,
    CLEAR_MILESTONESUID,
    CREATE_MACHINE_TAXONOMY_NODE,
    CREATE_MACHINE_TAXONOMY_NODE_FAIL,
    CREATE_MACHINE_TAXONOMY_NODE_SUCCESS,
    LOAD_MILESTONES_BY_ID,
    LOAD_MILESTONES_BY_ID_FAIL,
    LOAD_MILESTONES_BY_ID_SUCCESS,
    LOAD_MILESTONES_DROPDOWN,
    LOAD_MILESTONES_DROPDOWN_FAIL,
    LOAD_MILESTONES_DROPDOWN_SUCCESS,
    LOAD_MILESTONES_LSITPANE,
    LOAD_MILESTONES_LSITPANE_FAIL,
    LOAD_MILESTONES_LSITPANE_SUCCESS,
    LOAD_MILESTONES_SHORTCUTPANE,
    LOAD_MILESTONES_SHORTCUTPANE_FAIL,
    LOAD_MILESTONES_SHORTCUTPANE_SUCCESS,
    RESET_MILESTONES_IS_CHANGE,
    SAVE_MILESTONES,
    SAVE_MILESTONES_FAIL,
    SAVE_MILESTONES_SUCCESS,
    SET_MILESTONES_UID,
} from '../actionTypes/mileStonesActionTypes';

import i18n from '../../i18n';
import {v4 as uuidv4} from 'uuid';
import {PurchaseOrder} from '../types/purchaseOrder';
import {getFlatDataFromTree, getTreeFromFlatData} from '@nosferatu500/react-sortable-tree';
import {MileStonesListPaneFilter} from '../types/mileStones';


const defaultState: any = {
  msList: [],
  isMSTONEListLoaded: false,
  isNew: false,
  isChange: false,
  isEdit: false,
  showProgressBar: false,
  msDropDowns: {},
  formData: {},
  uid: null,
  loadMsg: '',
  msShortCutPaneData: [],
  reloadListPane: false,
  borResources: {},
  isLoadingMileStones: false,
  msId: null,
  mileStoneTaxonomyList: [],
  mileStoneBarList: [],
  milestoneInteractBarList: [],
  createNewNode: false,
};

export default function mileStonesReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {
      case LOAD_MILESTONES_SHORTCUTPANE:
        return { ...state };
      case LOAD_MILESTONES_SHORTCUTPANE_SUCCESS:
        return {
          ...state,
          msShortCutPaneData: action.payload.data.result,
        };
      case LOAD_MILESTONES_SHORTCUTPANE_FAIL:
        return { ...state, msShortCutPaneData: [] };
      case LOAD_MILESTONES_LSITPANE:
        return { ...state, isMSTONEListLoaded: false };
      case LOAD_MILESTONES_LSITPANE_SUCCESS:
        const data = formatVpMileStoneTaxonomyData(action.payload.data.result);
        return {
          ...state,
          isMSTONEListLoaded: true,
          msList: action.payload.data.result,
          mileStoneTaxonomyList: data,
          mileStoneBarList: formatVpMilestoneBarData(data),
          milestoneInteractBarList: formatVpMilestoneBarData(data),
          loadMsg: !action.payload.data.status
            ? action.payload.data.message
            : '',
        };
      case LOAD_MILESTONES_LSITPANE_FAIL:
        return {
          ...state,
          isMSTONEListLoaded: true,
          msList: [],
          loadMsg: action.error.response.data.message,
        };
      case RESET_MILESTONES_IS_CHANGE:
        return {
          ...state,
          isChange: false,
        };

      case LOAD_MILESTONES_DROPDOWN:
        return { ...state, msDropDowns: {} };
      case LOAD_MILESTONES_DROPDOWN_SUCCESS:
        return { ...state, msDropDowns: action.payload.data.result };
      case LOAD_MILESTONES_DROPDOWN_FAIL:
        return {
          ...state,
          msDropDowns: {},
        };
      case LOAD_MILESTONES_BY_ID:
        return { ...state, formData: {}, showProgressBar: true, isLoadingMileStones: true };
      case LOAD_MILESTONES_BY_ID_SUCCESS:
        return {
          ...state,
          isEdit: true,
          formData: action.payload.data.result,
          showProgressBar: false,
          isLoadingMileStones: false,
        };
      case LOAD_MILESTONES_BY_ID_FAIL:
        return {
          ...state,
          formData: {},
          showProgressBar: false,
          isLoadingMileStones: false,
        };

      case SAVE_MILESTONES:
        return { ...state, showProgressBar: true, reloadListPane: false, msId: null };
      case SAVE_MILESTONES_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          reloadListPane: true,
          msId: action.payload.data.result,
        };
      case SAVE_MILESTONES_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
          msId: action.payload.data.result,
        };

      case SET_MILESTONES_UID:
        return {
          ...state,
          isEdit: false,
          uid: uuidv4(),
        };
      case CLEAR_MILESTONESUID:
        return {
          ...state,
          uid: null,
          isChange: false,
        };
      case CLEAR_MILESTONES_FORM_DATA:
        return {
          ...state,
          uid: null,
          isNew: true,
          isChange: false,
          isEdit: false,
          formData: {},
        };

      case CREATE_MACHINE_TAXONOMY_NODE:
        return { ...state };
      case CREATE_MACHINE_TAXONOMY_NODE_SUCCESS:
        return {
          ...state,
          createNewNode: true,
        };
      case CREATE_MACHINE_TAXONOMY_NODE_FAIL:
        return { ...state };

      default:
        return state;
    }
  }
}

export const readMileStonesShortCutPaneData = () => {
  return {
    types: [
      LOAD_MILESTONES_SHORTCUTPANE,
      LOAD_MILESTONES_SHORTCUTPANE_SUCCESS,
      LOAD_MILESTONES_SHORTCUTPANE_FAIL,
    ],
    payload: { request: { url: READ_MILESTONES_SHORTCUT_PANE_EP } },
  };
};

export const readMileStonesDropDownData = () => {
  return {
    types: [
      LOAD_MILESTONES_DROPDOWN,
      LOAD_MILESTONES_DROPDOWN_SUCCESS,
      LOAD_MILESTONES_DROPDOWN_FAIL,
    ],
    payload: {
      request: {
        method: 'GET',
        url: READ_MILESTONES_DROPDOWN_EP,
      },
    },
  };
};


export const filterMileStonesListPaneData = (filter: MileStonesListPaneFilter) => {
  return {
    types: [
      LOAD_MILESTONES_LSITPANE,
      LOAD_MILESTONES_LSITPANE_SUCCESS,
      LOAD_MILESTONES_LSITPANE_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_MILESTONES_LIST_EP,
        data: filter,
      },
    },
  };
};


export const readByMileStonesId = (id: string) => {
  return {
    types: [
      LOAD_MILESTONES_BY_ID,
      LOAD_MILESTONES_BY_ID_SUCCESS,
      LOAD_MILESTONES_BY_ID_FAIL],
    payload: {
      request: {
        url: READ_MILESTONES_BY_ID_EP + id,
      },
    },
  };
};

export const resetIsChange = () => {
  return { type: RESET_MILESTONES_IS_CHANGE };
};

export const saveFormData = (formData: PurchaseOrder) => {
  return {
    types: [
      SAVE_MILESTONES,
      SAVE_MILESTONES_SUCCESS,
      SAVE_MILESTONES_FAIL],
    payload: {
      request: {
        url: MILESTONES_SAVE_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const setUid = () => {
  return { type: SET_MILESTONES_UID };
};

export const clearFormData = () => {
  return { type: CLEAR_MILESTONES_FORM_DATA };
};

export const formatVpMileStoneTaxonomyData = (data: any[]) => {
  const currentDate = new Date();
  const newData = data.map((item: any, index: number) => {
    return {
      ...item,
      id: (item.id + index),
      name: item.name,
      title: item.name,
      type: 'milestone',
      milestoneSequanceId: item.sequenceId,
      // start: new Date(Date.parse(item.startDate)),
      start: item.expectedStartDate ? new Date(Date.parse(item.expectedStartDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
      end: item.expectedEndDate ? new Date(Date.parse(item.expectedEndDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
      // end: new Date(Date.parse(item.endDate)),
      progress: 0,
    };
  });
  return newData;
};


export const formatVpMilestoneBarData = (data: any[]) => {
  const tData = getTreeFromFlatData({
    flatData: data,
    getKey: (node: any) => node.id,
    getParentKey: (node: any) => node.parentId,
    // @ts-ignore
    rootKey: null,
  });

  const fData = getFlatDataFromTree({
    treeData: tData,
    getNodeKey: (node: any) => node.id,
    ignoreCollapsed: false,

  });


  const finalresult = fData.map((item: any) => {
    const currentDate = new Date();
    return {
      start: item.node.start, // new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
      end: item.node.end, // new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
      name: item.node.name,
      title: item.node.name,
      id: item.node.id,
      progress: 0,
      type: item.node.type.toLowerCase(),
      hideChildren: false,
      parentId: item.node.parentId,
      styles: getResourceBarStyle(item.node),
      teams: item.node.teams,
      toolsPool: item.node.toolsPool,
      cpcId: item.node.ccpcId,
      project: item.node.project,
      milestoneSequanceId: item.node.milestoneSequanceId,
      msTypeId: item?.node?.msTypeId
    };
  });
  return finalresult;

};

const getResourceBarStyle = (node: any) => {
  const materiaslStyle = { progressColor: '#ffbb54', backgroundColor: '#6C3483' };
  const consumableStyle = { progressColor: '#ffbb54', backgroundColor: '#D68910' };
  const labourStyle = { progressColor: '#ffbb54', backgroundColor: '#B0185B' };
  const toolsStyle = { progressColor: '#ffbb54', backgroundColor: '#6DC20E' };
  const taskStyle = { progressColor: '#ffbb54', backgroundColor: '#CCD1D1' };
  const projectStyle = { progressColor: '#ffbb54', backgroundColor: '#B7950B' };
  const poStyle = { progressColor: '#ffbb54', backgroundColor: '#EC7063' };
  if (node.type === 'resourcetype' && node.title.toLowerCase() === i18n.t('materials').toLowerCase()) {
    return materiaslStyle;
  } else if (node.type === 'resourcetype' && node.title.toLowerCase() === i18n.t('consumables').toLowerCase()) {
    return consumableStyle;
  } else if (node.type === 'resourcetype' && node.title.toLowerCase() === i18n.t('labour').toLowerCase()) {
    return labourStyle;
  } else if (node.type === 'resourcetype' && node.title.toLowerCase() === i18n.t('tools').toLowerCase()) {
    return toolsStyle;
  } else if (node.type === 'po') {
    return projectStyle;
  } else if (node.type === 'milestone') {
    return poStyle;
  }

  return taskStyle;
};
export const createMSTaxonomyNode = (newNode: any) => {
  return {
    types: [
      CREATE_MACHINE_TAXONOMY_NODE,
      CREATE_MACHINE_TAXONOMY_NODE_SUCCESS,
      CREATE_MACHINE_TAXONOMY_NODE_FAIL,
    ],
    payload: {
      request: {
        url: CREATE_MACHINE_TAXONOMY_NODE_EP,
        method: 'POST',
        data: newNode,
      },
    },
  };
};

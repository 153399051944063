import {IComboBoxOption} from '@fluentui/react';
import React, {useContext, useEffect} from 'react';
import {Form} from 'react-final-form';
import {useSelector} from 'react-redux';
// import 'style.css';
import {ViewMode} from '../../../shared/ganttv2';
import {RESOURCE_TYPE_IDS} from '../../../shared/util';
import ModeSwitcher from '../../../shared/ganttViewSwitcher/viewSwitcher';
import {ViewModeContext} from '../../projectVisualPlan/productTaxonomyListPane/component';
import i18n from '../../../../i18n';

type ViewSwitcherProps = {
    isChecked: boolean;
    onViewListChange: (isChecked: boolean) => void;
    onViewModeChange: (viewMode: ViewMode) => void;
    renderFilterComponent: any
};
const options: IComboBoxOption[] = [
    // {
    //     key: ViewMode.QuarterDay,
    //     text: i18n.t('quaterOfDay'),
    // },
    // {
    //     key: ViewMode.HalfDay,
    //     text: i18n.t('halfOfDay'),
    // },
    // {
    //     key: ViewMode.Day,
    //     text: i18n.t('day'),
    // },
    {
        key: ViewMode.Week,
        text: i18n.t('week'),
    },
    {
        key: ViewMode.Month,
        text: i18n.t('month'),
    },
    {
        key: ViewMode.QuaterYear,
        text: i18n.t('quater'),
    },
];

export const ViewSwitcher: React.FC<ViewSwitcherProps> = () => {
    const [selectedKey, setSelectedKey] = React.useState<string>(RESOURCE_TYPE_IDS.LABOURS);
    const [selectedMode, setSelectedViewMode] = React.useState<ViewMode>(ViewMode.Day);

    const {onViewModeChange, onViewListChange, isCheck} =
        useContext(ViewModeContext);

    const selectedResourceTypeOrg: string = useSelector((state: any) => state.vp.selectedResourceTypeOrg);

    const onSubmit = async (values: any) => {
    };

    useEffect(() => {
        setSelectedKey(selectedResourceTypeOrg);
    }, [selectedResourceTypeOrg]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
                height: 70,
                width: '100%',
            }}
        >
            <Form
                onSubmit={onSubmit}
                initialValues={{}}
                mutators={{
                    setHeaderDocs: (args, state, utils) => {
                        const field = state.fields['header.files'];
                        field.change(args[0]);
                    },
                }}
                // validate={ validate }
                render={({
                             handleSubmit,
                             form,
                             submitting,
                             pristine,
                             values,
                         }) => {
                    // props.submit(handleSubmit);
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <ModeSwitcher
                                viewSwitcherOptions={options}
                                onViewModeChange={(t: any) => {
                                    onViewModeChange(t)
                                    setSelectedViewMode(t)
                                }}
                                onViewListChange={onViewListChange}
                                isCheck={isCheck}
                                selectedViewMode={selectedMode}
                                expandToggleEnabled={true} // due to collapse issue
                            />
                            {/* <pre>{ JSON.stringify(values, 0, 2) }</pre> */}
                        </form>
                    );
                }}
            />
        </div>
    );
};

import {
  ActionButton,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Separator,
  Stack,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../theme';
import { CKEditorWrapper, DropDownAdaptater, TextFieldFF } from '../../../shared/fluentFinalForm';
import HistoryBar from '../../../shared/historyBar/historyBar';
import { getProject } from '../../../shared/util';
import { CompetenceTaxonomy, Competency, CompetencyDropDowns } from '../../../types/competency';
import TaxonomyField from '../taxonomy/taxonomy';


const theme = getTheme();

const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };

const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
});

// const required = (value: any) => (value ? undefined : 'Required');


const DocumentPaneComponent = (props: {
  submit: any,
  dropDowns: CompetencyDropDowns,
  saveFormData: any,
  uid: any,
  showProgress: boolean,
  formValues: any,
  formData: any,
  isExpand: boolean,
  setForm: (form: any) => void;
  isClear: boolean;
  competencyTaxonomyList: CompetenceTaxonomy[];
  // selectedShortCutItem:any
}) => {
  const [state, setstate] = useState<Competency | null>(null);
  const { t } = useTranslation();
  const onSubmit = async (values: any) => {
    values.projectSequenceCode = getProject();
    if (!values.id) {
      values.id = props.uid;
    }
    props.saveFormData(values);
  };

  useEffect(() => {
    setstate(props.formData);
  }, [props.formData]);

  const getInitialFormValues = (formData: any, formValue: any) => {
    if (location.pathname.split('/').pop() === 'new' && props.isClear) {
      return { name: null };
    } else if (formData && formData?.id) {
      return {
        id: formData?.id,
        name: formData?.name,
        sequenceId: formData?.sequenceId,
        title: formData?.title,
        skillTypeId: formData?.skillTypeId,
        experienceLevelId: formData?.experienceLevelId,
        occupation: formData?.occupation,
        qualification: formData?.qualification,
        description: formData?.description,
      };
    }
    return formValue;
  };


  return (
    <div style={{ width: '100%' }}>
      <div className="proj-detail-block">
        <Form
          onSubmit={onSubmit}
          initialValues={getInitialFormValues(props.formData, props.formValues)}
          // mutators={ {
          //     setHeaderDocs: (args, state, utils) => {
          //         const field = state.fields.files;
          //         field.change(args[0]);
          //     }
          // } }
          validate={values => {
            const errors: any = {};
            if (!values?.name && !props.showProgress) {
              errors.name = t('nameRequired');
            }
            return errors;
          }}
          render={({
                     handleSubmit,
                     form,
                     submitting,
                     pristine,
                     values,
                   }) => {
            props.submit(handleSubmit);
            props.setForm(form);
            // const headerFiles = values.files;
            return (
              <form onSubmit={handleSubmit} noValidate>
                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="competancy-header">
                      <Label>1. {t('competenctHeader')}</Label>
                    </Link>
                  </div>
                  {state?.sequenceId && (
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="title"
                            component={TextFieldFF}
                            lable={t('competancyTitle')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="sequenceId"
                            component={TextFieldFF}
                            lable={t('competancyID')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>
                  )}

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="name"
                          component={TextFieldFF}
                          lable={t('competencyName')}
                        />
                      </div>
                    </Stack.Item>
                    { /* <Stack.Item grow={ 6 } styles={ stackItemStyles }>
                                            <div className={ `${classNames.fullWidth}` }>
                                                <Field
                                                    name="header.poTypeId"
                                                    component={ DropDownAdaptater }
                                                    options={ props.dropDowns.types }
                                                    lable="Purchase Order type"
                                                    placeholder="Purchase Order type"
                                                    disabled={ false }
                                                />
                                            </div>
                                        </Stack.Item> */}
                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={{
                      childrenGap: 5,
                      padding: 0,
                    }}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <div style={{ zIndex: 10000000 }}>
                          <Field name="skillTypeId">
                            {({ input, meta }) => (
                              <TaxonomyField
                                label={t('skillType')}
                                treeData={props.competencyTaxonomyList}
                                selectItemId={values && values.skillTypeId ? values.skillTypeId : null}
                                onSelectItem={(id: string) => {
                                  if (id) {
                                    if (id === props.formData.skillTypeId) {
                                      input.onChange(null);
                                    } else {
                                      input.onChange(id);
                                    }
                                  } else {
                                    input.onChange(null);
                                  }
                                }}
                                treeLevel={props.dropDowns.competenciesTaxonomyLevel}
                                onFilterTaxonomyNodeChanged={() => {
                                }}

                                // disabled={ false }
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                    </Stack.Item>
                  </Stack>
                  <Stack
                    horizontal
                    wrap
                    styles={{ root: { padding: 0, marginBottom: 10, marginTop: 40 } }}
                    tokens={stackTokens}
                  >
                    <div className={`${classNames.fullWidth}`}>
                      <Label>{t('skillDescription')}</Label>

                      <Field
                        name="description"
                        component={CKEditorWrapper}
                        lable={t('skillDescription')}
                        disabled={false}
                      />
                    </div>
                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >

                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="experienceLevelId"
                          component={DropDownAdaptater}
                          options={props.dropDowns.experience}
                          lable={t('experinceLevel')}
                          placeholder="Level"
                          disabled={false}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="occupation"
                          component={TextFieldFF}
                          lable={t('occupation')}
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="qualification"
                          component={TextFieldFF}
                          lable={t('qualification')}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>


                  <div className={'card-footer'}>
                    <Separator/>
                    <Stack
                      styles={stackStyles}
                      tokens={itemAlignmentsStackTokens}
                    >
                      <Stack.Item
                        align="end"
                        styles={stackFooterBarItemStyles}
                      >
                        <ActionButton
                          className={classNames.actionButton}
                          iconProps={saveIcon}
                          allowDisabledFocus
                          onClick={() => {
                            // alert(JSON.stringify(values.purchaseOrderType, 0, 2));
                            form.submit();
                          }}
                          // disabled={ (props.dropDownOptions.isApproved) }
                        >
                          <Label className={classNames.actionButton}>
                            {t('save')}
                          </Label>
                        </ActionButton>
                      </Stack.Item>
                    </Stack>
                  </div>
                </div>

                { /* <div className="document-pane-card">
                                    <div className={ 'card-header' }>
                                        <Link href="#" id="project-moleculer-header">
                                            <Label>2. { 'Involved Parties' }</Label>
                                        </Link>
                                        <Label>2.1. { 'Customer' }</Label>
                                    </div>
                                    <Stack
                                        horizontal
                                        wrap
                                        styles={ stackStyles }
                                        tokens={ stackTokens }
                                    >
                                        <Stack.Item grow={ 6 } styles={ stackItemStyles }>
                                            <div className={ `${classNames.fullWidth}` }>
                                                <Field
                                                    name="header.name"
                                                    component={ TextFieldFF }
                                                    lable="Purchase Order Name"
                                                />
                                            </div>
                                        </Stack.Item>
                                        <Stack.Item grow={ 6 } styles={ stackItemStyles }>
                                            <div className={ `${classNames.fullWidth}` }>
                                                <Field
                                                    name="header.name"
                                                    component={ TextFieldFF }
                                                    lable="Purchase Order Name"
                                                />
                                            </div>
                                        </Stack.Item>
                                    </Stack>

                                </div> */}


                {
                  props.formData.history &&
                  props.formData.history.createdDate && (
                    <div className="proj-detail-block" id="3">
                      <div className="document-pane-card">
                        <div className="marginTop marginBottom">
                          <Link href="#" id="history">
                            <Label>3. {t('history')} </Label>
                          </Link>

                          <HistoryBar
                            createdByUser={
                              props.formData.history.createdBy
                                ? props.formData.history.createdBy
                                : ''
                            }
                            updatedByUser={
                              props.formData.history.modifiedBy
                                ? props.formData.history.modifiedBy
                                : ''
                            }
                            createdDateTime={
                              props.formData.history.createdDate
                                ? props.formData.history.createdDate
                                : ''
                            }
                            updatedDateTime={
                              props.formData.history.modifiedDate
                                ? props.formData.history.modifiedDate
                                : ''
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                {/* <pre>{ JSON.stringify(values, 0, 2) }</pre>  */}

              </form>
            );
          }}
        />
      </div>
    </div>
  );
};
export default DocumentPaneComponent;


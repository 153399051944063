import {
    ActionButton,
    DetailsListLayoutMode,
    FontIcon,
    getTheme,
    IIconProps,
    IStackItemStyles,
    IStackStyles,
    IStackTokens,
    Label,
    mergeStyleSets,
    SelectionMode,
    ShimmeredDetailsList,
    Stack,
} from '@fluentui/react';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {uPrinceTheme} from '../../../../../theme';
import {ActionButtonState} from '../../../../types/corporateProductCatalogue';
import {useSelector} from 'react-redux';
import {formatwithoutTime} from "../../../../shared/util";

const theme = getTheme();

const stackEditbarItemStyles: IStackItemStyles = {
    root: {
        display: 'flex',
        height: 45,
        alignItems: 'flex-end',
    },
};

const itemAlignmentsStackTokens: IStackTokens = {
    childrenGap: 3,
    padding: 10,
};

const iconButtonStyles = {
    root: { color: uPrinceTheme.palette.themePrimary },
    rootHovered: { color: theme.palette.neutralDark },
};

const removeIcon: IIconProps = {
    iconName: 'Delete',
    styles: iconButtonStyles,
};
const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };
const editIcon: IIconProps = { iconName: 'Edit', styles: iconButtonStyles };

const renderDownloadButton = (item: any) => {
    if (item?.url) {
        return <FontIcon
          aria-label='Download'
          iconName='Download'
          style={{ marginLeft: 10, cursor: 'pointer' }}
          onClick={() => {
              let link = document.createElement('a');
              link.href = item?.url;
              link.download = item?.url;
              link.click();
          }}
        />;
    } else {
        return '';
    }
};

const ContractListGrid = (props: {
    dataList: any[];
    title: string;
    link: string;
    id: string;
    columns: any[];
    isLoaded: boolean;
    isExpand: boolean;
    selection: any;
    readOnly?: boolean;
    openModel: () => void;
    editContract?: () => void;
    contractSelection?: any;
    contractSelectionDetails?: {};
    contractActionButtonState: ActionButtonState;
    deleteContract: () => void;
}) => {
    const { t } = useTranslation();
    const { contractTypes } = useSelector((state: any) => state.hr);

    const contractListColoumns = [
        {
            key: 'column1',
            name: t('startDate'),
            fieldName: 'startDate',
            minWidth: props.isExpand ? 200 : 120,
            maxWidth: props.isExpand ? 200 : 120,
            isResizable: true,
            onRender: (item: any) => {
                return <span>{formatwithoutTime(item.startDate)}</span>;
            }
        },
        {
            key: 'column2',
            name: t('endDate'),
            fieldName: 'endDate',
            minWidth: props.isExpand ? 200 : 120,
            maxWidth: props.isExpand ? 200 : 120,
            isResizable: true,
            onRender: (item: any) => {
                return item.endDate ? <span>{formatwithoutTime(item.endDate)}</span> : <span>-</span>;
            }
        },
        {
            key: 'column3',
            name: t('contractType'),
            fieldName: 'contractTypeId',
            minWidth: props.isExpand ? 200 : 100,
            maxWidth: props.isExpand ? 200 : 100,
            isResizable: true,
            onRender: (item: any) => {
                const contractType = contractTypes.find((x: any) => x.key === item.contractTypeId);
                return <span>{contractType?.text}</span>;
            }
        },
        {
            key: 'column4',
            name: t('uploadContractDocument'),
            fieldName: 'document',
            minWidth: props.isExpand ? 200 : 100,
            maxWidth: props.isExpand ? 200 : 100,
            isResizable: true,
            onRender: (item: any) => {
                return <div>
                    <FontIcon
                      aria-label='Upload'
                      iconName='Upload'
                      style={{ marginLeft: 60, cursor: 'pointer' }}
                      onClick={() => {
                          props.openModel();
                      }}
                    />
                    {renderDownloadButton(item)}
                </div>;
            },
        },
    ];

    const classNames = mergeStyleSets({
        wrapper: {
            // height: '100vh',
            position: 'relative',
            maxHeight: 'inherit',
            minHeight: props.dataList && props.dataList.length > 1 ? 200 : 110,
        },
        pane: { maxWidth: 400 },
        textContent: { padding: '15px 10px' },
        actionIcon: {
            padding: 5,
            cursor: 'pointer',
        },
        labelRightWrapper: {
            textAlign: 'end',
            selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
        },
        actionButton: {
            color: uPrinceTheme.palette.themePrimary,
            cursor: 'pointer',
        },
        labelRightIcon: {
            display: 'inline-block',
            verticalAlign: 'bottom',
            paddingBottom: 2,
            paddingRight: 5,
        },
    });

    useEffect(() => {
        changeColor();
    });

    const stackStyles: IStackStyles = { root: { padding: 0 } };
    const stackItemStyles: IStackItemStyles = {
        root: {
            display: 'flex',
            height: 40,
            alignItems: 'flex-start',
        },
    };

    const stackTokens: IStackTokens = {
        childrenGap: 15,
        padding: 10,
    };

    const changeColor = () => {
        const cquantityClass = document.getElementsByClassName('mcomments');
        for (let i = 0; i < cquantityClass.length; i++) {
            const parentElement = cquantityClass[i].parentElement;
            if (parentElement) {
                parentElement.style.borderRight = '1px solid rgb(243, 242, 241)';
            }
        }

        const totalCost1Class = document.getElementsByClassName('mmou');
        for (let i = 0; i < totalCost1Class.length; i++) {
            const parentElement = totalCost1Class[i].parentElement;
            if (parentElement) {
                parentElement.style.borderRight = '1px solid rgb(243, 242, 241)';
            }
        }
    };

    return (
      <div className='proj-detail-block' id={props.id}>
          <div className='marginTop'>
              <div style={{ padding: 12 }}>
                  <div className='ms-Grid-row'>
                      <Stack
                        horizontal
                        horizontalAlign='space-between'
                        styles={stackStyles}
                        tokens={stackTokens}
                      >
                          <Stack.Item styles={stackItemStyles}>
                              <Label style={{ marginTop: 10 }}>{props.title}</Label>
                          </Stack.Item>
                          {!props.readOnly && (
                            <Stack.Item styles={stackEditbarItemStyles}>
                                <ActionButton
                                  className={classNames.actionButton}
                                  disabled={props.contractActionButtonState.add}
                                  iconProps={addIcon}
                                  allowDisabledFocus
                                  onClick={() => {
                                      props.openModel();
                                  }}
                                >
                                    <Label className={classNames.actionButton}>{t('add')} </Label>
                                </ActionButton>
                                <ActionButton
                                  iconProps={editIcon}
                                  disabled={props.contractActionButtonState.edit}
                                  allowDisabledFocus
                                  onClick={() => {
                                      props.openModel();
                                  }}
                                >
                                    <Label className={classNames.actionButton}> {t('edit')}</Label>
                                </ActionButton>
                                <ActionButton
                                  iconProps={removeIcon}
                                  disabled={props.contractActionButtonState.remove}
                                  allowDisabledFocus
                                  onClick={() => props.deleteContract()}
                                >
                                    <Label className={classNames.actionButton}>
                                        {' '}
                                        {t('remove')}
                                    </Label>
                                </ActionButton>
                            </Stack.Item>
                          )}
                      </Stack>
                  </div>
                  <div className='ms-Grid-row marginTop'>
                      <div className='ms-Grid-col ms-sm12 ms-md12 ms-lg12'>
                          <div className={classNames.wrapper}>
                              <div className={'scrollablePane'}>
                                  <ShimmeredDetailsList
                                    items={props.dataList ? props.dataList : []}
                                    columns={contractListColoumns}
                                    setKey='set'
                                    selectionMode={
                                        props.readOnly ? SelectionMode.none : SelectionMode.multiple
                                    }
                                    enableShimmer={props.isLoaded}
                                    layoutMode={DetailsListLayoutMode.justified}
                                    selectionPreservedOnEmptyClick={true}
                                    checkboxVisibility={1}
                                    selection={props.contractSelection}
                                    ariaLabelForSelectionColumn='Toggle selection'
                                    ariaLabelForSelectAllCheckbox='Toggle selection for all items'
                                    checkButtonAriaLabel='Row checkbox'
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    );
};

export default ContractListGrid;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FocusZone } from '@fluentui/react/lib/FocusZone';
import { FontIcon, IRectangle, List } from '@fluentui/react';
import { getTheme, ITheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { uPrinceTheme } from '../../../theme';
import UprinceLogger from '../Logger/logger';

const theme: ITheme = getTheme();
const { palette, fonts } = theme;
const ROWS_PER_PAGE = 3;
const MAX_ROW_HEIGHT = 250;
const classNames = mergeStyleSets({
  listGridExample: {
    overflow: 'hidden',
    fontSize: 0,
    position: 'relative',
  },
  listGridExampleTile: {
    textAlign: 'center',
    outline: 'none',
    position: 'relative',
    // marginLeft:1,
    float: 'left',
    // background: palette.neutralLighter,
    selectors: {
      'focus:after': {
        content: '',
        position: 'absolute',
        left: 2,
        right: 2,
        top: 2,
        bottom: 2,
        boxSizing: 'border-box',
        border: `1px solid ${palette.white}`,
      },
    },
  },
  listGridExampleSizer: {
    paddingBottom: '100%',
  },
  listGridExamplePadder: {
    position: 'absolute',
    left: 2,
    top: 2,
    right: 2,
    bottom: 2,
  },
  listGridExampleLabel: {
    color: uPrinceTheme.palette.themePrimary,
    position: 'absolute',
    padding: 10,
    paddingRight: 5,
    bottom: '68%',
    textAlign: 'end',
    left: 0,
    width: '100%',
    fontSize: fonts.small.fontSize,
    boxSizing: 'border-box',
  },
  listGridExampleImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
});

export interface UploadedImage {
  id: string | null;
  image: string | null;
}

const ImageGrid = (props: { uploadedImages: any, handleImageDelete: (id: string) => void }) => {
  UprinceLogger.log('uploadedImages ImageGrid', props.uploadedImages);
  const { t } = useTranslation();
  const columnCount = React.useRef(0);
  const rowHeight = React.useRef(0);

  const getItemCountForPage = React.useCallback((itemIndex?: number | undefined, visibleRect?: IRectangle | undefined) => {
    if (itemIndex === 0 && visibleRect) {
      columnCount.current = Math.ceil(visibleRect.width / MAX_ROW_HEIGHT);
      rowHeight.current = Math.floor(visibleRect.width / columnCount.current);
    }
    return columnCount.current * ROWS_PER_PAGE;
  }, []);

  const onRenderCell = React.useCallback(
    (item?: { thumbnail: string, id: string } | undefined, index?: number | undefined, isScrolling?: boolean | undefined) => {
      return (
        <div
          className={classNames.listGridExampleTile}
          data-is-focusable
          style={{
            width: 50 / columnCount.current + '%',
          }}
        >
          <div className={classNames.listGridExampleSizer}>
            <div className={classNames.listGridExamplePadder}>
              <img src={item?.thumbnail} className={classNames.listGridExampleImage}/>
              <span className={classNames.listGridExampleLabel}>&nbsp;
                {(item?.id != null) ? <span onClick={() => {
                  props.handleImageDelete(item?.id);
                }} style={{ cursor: 'pointer' }}><FontIcon iconName="Delete" className="removeIcon"/></span> : false}
              </span>
            </div>
          </div>
        </div>
      );
    },
    [columnCount.current],
  );


  const getPageHeight = (): number => {
    return rowHeight.current * ROWS_PER_PAGE;
  };

  const getImages = () => {

    if (props.uploadedImages && props.uploadedImages.length > 0) {
      let images = props.uploadedImages.map((item: any) => {
        return { thumbnail: item.image, id: item.id };
      });
      return images;
    } else {
      return [];
    }

  };
  return (
    <div>
      <FocusZone>
        <List
          className={classNames.listGridExample}
          items={getImages()}
          getItemCountForPage={getItemCountForPage}
          // eslint-disable-next-line react/jsx-no-bind
          getPageHeight={getPageHeight}
          renderedWindowsAhead={4}
          onRenderCell={onRenderCell}
        />
      </FocusZone>
    </div>
  );

};

export default ImageGrid;



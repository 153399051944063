import React, {useEffect} from 'react';
import {momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {messageService} from '../../../../services/messageService';
import {filterProjectDayPlanningProjectList} from '../../../../reducers/projectDayPlanningReducer';
import {formatDateTimeToISOString} from '../../../../shared/util';
import {useTranslation} from 'react-i18next';
import {getTheme, Panel, PanelType, registerIcons} from '@fluentui/react';
import {SvgIcons} from '../../../../shared/svgIcons/svgIcons';
import CommandBarLeft from '../../commandBar/commandBarLeft';
import MultipleCalendar from './multipleCalendar';
import {
    assignPumpTOProduct,
    assignTruckTOProduct, loadBottomPumpWithProduct,
    loadBottomTruckWithProduct,
    loadProductList,
    saveThAutomationStateAttr,
} from '../../../../reducers/thAutomationReducer';
import {ThAutomationResourceType} from '../../../../types/thAutomation';
import {v4 as uuidv4} from 'uuid';

const theme = getTheme();

moment.locale('en-GB');
const localizer = momentLocalizer(moment);
registerIcons(SvgIcons);

const TopListPane = () => {
    const dispatch = useDispatch();
    const store = useStore();
    const {t} = useTranslation();
    const {
        selectedDate,
        horizontalReSizer,
        showFullScreen,
    } = useSelector(
      (state: any) => state.thAutomation
    );

    useEffect(() => {
        // subscribe to home component messages
        const subscription = messageService.getMessage().subscribe((data: any) => {
            if (data) {
                if (data.data.dndItem) {
                    if (data.data.dndItem && data.data.dndItem.type === ThAutomationResourceType.TRUCK) {
                        addTruckTOProduct(data.data.dndItem);
                    } else {
                        //  console.log('else',data.data.dndItem)
                    }
                    if (data.data.dndItem && data.data.dndItem.type === ThAutomationResourceType.PUMP) {
                        addPumpTOProduct(data.data.dndItem);
                    } else {
                        //  console.log('else',data.data.dndItem)
                    }
                }
                if (data.data.fullScreen) {
                    dispatch(saveThAutomationStateAttr('showFullScreen', true))
                }
            }
        });

        if (selectedDate) {
            reloadListPanes();
        } else {
            dispatch(saveThAutomationStateAttr('selectedDate', new Date()))
        }

        const interval = setInterval(() => {
            reloadFullScreen();
        }, 300000);

        // return unsubscribe method to execute when component unmounts
        return () => {
            subscription.unsubscribe();
            clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        if (selectedDate) {
            reloadListPanes();
        }
    }, [selectedDate])

    const reloadListPanes = () => {
        dispatch(loadProductList({
            date: formatDateTimeToISOString(store.getState()?.thAutomation?.selectedDate),
        }));
    };

    const reloadFullScreen = () => {
        if (store.getState()?.thAutomation?.showFullScreen) {
            dispatch(loadProductList({
                date: formatDateTimeToISOString(store.getState()?.thAutomation?.selectedDate),
            }));
        }
    };

    const addTruckTOProduct = (dndItem: any) => {
        // console.log({dndItem})
        let data = {
            id: uuidv4(),
            cpcId: dndItem?.dragItem?.id,
            productId: dndItem?.dropItem?.pbsId ? dndItem?.dropItem?.pbsId : dndItem?.dropItem?.id,
            projectSequenceCode: dndItem?.dropItem?.projectSequenceCode ? dndItem?.dropItem?.projectSequenceCode : '',
            eTime: moment(dndItem?.dropItem?.event?.endTime).format('HH:mm'),
            sTime: moment(dndItem?.dropItem?.event?.startTime).format('HH:mm'),
            type: dndItem?.dropItem?.event?.type,
            date: formatDateTimeToISOString(store.getState()?.thAutomation?.selectedDate)
        };
        // console.log({data})
        Promise.all([
            dispatch(assignTruckTOProduct(data))
        ]).then(() => {
            reloadListPanes();
            dispatch(loadBottomTruckWithProduct({
                date: formatDateTimeToISOString(store.getState()?.thAutomation?.selectedDate)
            }));
        });
    };


    const addPumpTOProduct = (dndItem: any) => {
        // console.log({dndItem})
        let data = {
            id: uuidv4(),
            cpcId: dndItem?.dragItem?.id,
            productId: dndItem?.dropItem?.pbsId ? dndItem?.dropItem?.pbsId : dndItem?.dropItem?.id,
            projectSequenceCode: dndItem?.dropItem?.projectSequenceCode ? dndItem?.dropItem?.projectSequenceCode : '',
            eTime: moment(dndItem?.dropItem?.event?.endTime).format('HH:mm'),
            sTime: moment(dndItem?.dropItem?.event?.startTime).format('HH:mm'),
            type: dndItem?.dropItem?.event?.type,
            date: formatDateTimeToISOString(store.getState()?.thAutomation?.selectedDate)
        };
        // console.log({data})
        Promise.all([
            dispatch(assignPumpTOProduct(data))
        ]).then(() => {
            reloadListPanes();
            dispatch(loadBottomPumpWithProduct({
                date: formatDateTimeToISOString(store.getState()?.thAutomation?.selectedDate)
            }));
        });
    };


    const onSelectDate = React.useCallback((date: Date | null | undefined): void => {
        Promise.all([
            dispatch(saveThAutomationStateAttr('selectedDate', date)),
        ]).then(() => {
            if (date) {
                dispatch(filterProjectDayPlanningProjectList({
                    executionDate: formatDateTimeToISOString(date),
                    contractingUnit: [],
                    buId: null,
                    cabPersonId: null,
                    projectSequenceCode: null,
                    pmolTypeId: null,
                    siteManagerId: null
                }));
            }
        });
    }, []);
    new Date(2021, 5, 8, 9, 0, 0);
    new Date(2021, 5, 8, 13, 0, 0);
    new Date(2021, 5, 8, 14, 0, 0);
    new Date(2021, 5, 8, 16, 30, 0);
    new Date(2021, 5, 8, 8, 30, 0);
    new Date(2021, 5, 8, 12, 30, 0);
    new Date(2021, 5, 8, 7, 0, 0);
    new Date(2021, 5, 8, 10, 30, 0);

    const renderListPane = () => {
        return <MultipleCalendar/>
        // return <CustomView/>
    }

    return <div style={{width: '100%', height: '100%'}}>
        <div className={'pdp'} style={{width: '100%', height: '100%'}}>
            <div className={'th-automation'}
                 style={{width: '100%', height: horizontalReSizer - 80, overflow: 'overlay'}}> {renderListPane()}</div>
            <Panel
              isOpen={showFullScreen}
              type={PanelType.custom}
              customWidth="100vw"
              onDismiss={() => {
                  dispatch(saveThAutomationStateAttr('showFullScreen', false))
              }}
              isLightDismiss={true}
              headerText={t('projectDayPlanning')}
              className="custom-detail-panel pdp"
              closeButtonAriaLabel={t('close')}
            >
                <div style={{display: 'flex'}}>
                    <CommandBarLeft onSelectDate={onSelectDate} disable={false} isTopPane={true} refresh={() => {
                        reloadFullScreen()
                    }}/>
                </div>

                <div className={'th-automation'} style={{
                    width: '100%',
                    height: screen.height - 250,
                    overflow: 'overlay',
                    paddingLeft: 5
                }}> {renderListPane()}</div>
            </Panel>
        </div>
    </div>
};

export default TopListPane

import {GET_BURN_DOWN_CHART_DATA_EP} from '../shared/endpoints';
import {
    LOAD_BURN_DOWN_CHART_DATA,
    LOAD_BURN_DOWN_CHART_DATA_FAIL,
    LOAD_BURN_DOWN_CHART_DATA_SUCCESS,
    SAVE_BDC_STATE_ATTR
} from '../actionTypes/bdcActionTypes';
import {chartData} from '../components/burnDownChart/data';

const defaultState: any = {
    isLoadingChart: false,
    chartData: [],
    showProgressBar: false,
}

export default function burnDownChartReducer(state = defaultState, action: any) {
    if (action) {
        switch (action.type) {
            case LOAD_BURN_DOWN_CHART_DATA:
                return {...state,
                    isLoadingChart: true,
                    showProgressBar: true,
                };
            case LOAD_BURN_DOWN_CHART_DATA_SUCCESS:
                return {
                    ...state,
                    isLoadingChart: false,
                    chartData: formatChartData(action.payload.data.result),
                    showProgressBar: false,
                };
            case LOAD_BURN_DOWN_CHART_DATA_FAIL:
                return {
                    ...state,
                    isLoadingChart: false,
                    showProgressBar: false,
                };

            default:
                return state;
        }
    }
}

export function saveBDCStateAttr(attr: string, value: any) {
    return {
        type: SAVE_BDC_STATE_ATTR,
        [attr]: value
    };
}

export const readBurnDownChartData = (data: any) => {
    return {
        types: [
            LOAD_BURN_DOWN_CHART_DATA,
            LOAD_BURN_DOWN_CHART_DATA_SUCCESS,
            LOAD_BURN_DOWN_CHART_DATA_FAIL
        ],
        payload: {
            request: {
                url: GET_BURN_DOWN_CHART_DATA_EP,
                method: 'POST',
                data: data
            }
        }
    };
};

const formatChartData = (result: any) => {
    if (result) {
        return result;
    }
    return [];
};
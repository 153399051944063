import {ChoiceGroup} from '@fluentui/react';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import client from '../../../../api';
import {savePbsStateAttr} from '../../../../reducers/projectBreakdownReducer';
import {saveVPStateAttr} from '../../../../reducers/visualPlanReducer';
import {readByPMOLId, readPMOLByID, saveFormData, savePMOLStateAttr} from "../../../../reducers/pmolReducer";

const PmolStatusPopOver = (props: { pmolSequenceId: any, visible: boolean, path: any, close: any, cu:any, project:any }) => {
    const dispatch = useDispatch();
    const [pmolData, setPmolData]: any = useState(null);
    const {t} = useTranslation();

    const options: any = useSelector(
        (state: any) => state.projectBreakdown.productStates
    );

    useEffect(() => {
        if (props.visible) {
            dispatch(saveVPStateAttr('expandPath', props.path));
            dispatch(savePMOLStateAttr('isPMOLOpenFromVP', false))
            const fetchPMOL = async () => {
                const response: any = await client.get(`Pmol/ReadPmolId/${props.pmolSequenceId}`);
                if (response?.data?.result) setPmolData(response?.data?.result?.header);
            };
            fetchPMOL()
        } else {

        }
    }, [props.pmolSequenceId, props.visible]);

    function _onChange(ev: any, option: any): void {
        if (option) {
            props.close()
            Promise.all([dispatch(saveFormData({
                ...pmolData,
                statusId: option?.key
            }))]).then(() => {
                dispatch(readByPMOLId(pmolData?.projectMoleculeId))
            })
        }
    }

    return (
        <div style={{width: 250, height: 220}}>
            {pmolData?.projectMoleculeId &&
                <ChoiceGroup
                    selectedKey={pmolData?.statusId}
                    options={options}
                    onChange={_onChange}
                    label={t('pmolStatus')}
                />
            }
        </div>
    );
};

export default PmolStatusPopOver;
import { useTranslation } from 'react-i18next';
import {
  assign,
  ContextualMenu,
  FontWeights,
  getTheme,
  IBasePickerSuggestionsProps,
  IconButton,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import _ from 'lodash';
import { Resource } from '../../../../../types/projectMolecule';
import { uPrinceTheme } from '../../../../../../theme';
import i18n from '../../../../../../i18n';
import GroupDropdown from '../../../../../shared/groupDropdown/groupDropdown';

const theme = getTheme();

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const classNames = mergeStyleSets({
  wrapper: {
    //height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: {
    maxWidth: 400,
  },
  textContent: {
    padding: '15px 10px',
  },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: {
    width: '100%',
  },
});

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};

const bankItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 0,
    flexGrow: 1,
    width: 0,
  },
};

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };
const addIconWhite: IIconProps = {
  iconName: 'Save',
  styles: addWhiteIconButtonStyles,
};
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    paddingTop: 20,
    overflowY: 'hidden',
    minWidth: '40vw',
    //minHeight: '32vh',
    minHeight: screen.width > 1280 ? '42vh' : '60vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'none',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const toggleStyles = { root: { marginBottom: '20px' } };
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const dropdownStyles = { dropdown: { width: 300, marginTop: 20 } };

const tagSearchAdditionalProps: IBasePickerSuggestionsProps = {
  suggestionsContainerAriaLabel: i18n.t('suggestedOrganisation'),
  searchForMoreText: i18n.t('loadallResults'),
  resultsMaximumNumber: 10,
  searchingText: i18n.t('searching') + '...',
};

const tagSuggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: i18n.t('suggestedOrganisation'),
  mostRecentlyUsedHeaderText: i18n.t('suggestedOrganisation'),
  noResultsFoundText: i18n.t('noresultsfound'),
  loadingText: i18n.t('loading'),
  showRemoveButtons: true,
  suggestionsAvailableAlertText: i18n.t('peoplePickerSuggestionsavailable'),
  suggestionsContainerAriaLabel: i18n.t('suggestedOrganisation'),
};

const tagSearchSuggestionProps: IBasePickerSuggestionsProps = assign(
  tagSearchAdditionalProps,
  tagSuggestionProps,
);

class MaterialValidationMessages {
  cpcValidationMsg: string = '';
  quantityValidationMsg: string = '';
}

class PickerPerson {
  key: string | null = '';
  text: string | null = '';
}

const ResourceModal = (props: {
  isOpen: boolean;
  openResourceModal: () => void;
  handleResourceSave: (quantity: any) => void;
  handleCpcChange: (selectedCpc: any) => void;
  title: string;
  cpcOptions: any;
  cpcData: any;
  clearCpcData: () => void;
  isEdit: boolean;
  resource: Resource;
  pmolID: string | null;
  type: 'Planned' | 'Extra';
}) => {
  const { t } = useTranslation();
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
  const titleId = useId('title');
  const theme = getTheme();

  const [plannedQuantity, setPlannedQuantity]: any = useState('');
  const [consumedQuantity, setConsumedQuantity]: any = useState('');
  const [cpc, setCpc]: any = useState(null);
  const [unit, setUnit]: any = useState(null);
  const [nameErrorMsg, setNameErrorMsg]: any = useState(null);
  const [plannedQuantityErrorMsg, setPlannedQuantityErrorMsg]: any = useState(null);
  const [consumedQuantityErrorMsg, setConsumedQuantityErrorMsg]: any = useState(null);


  const onChangeInput = (field: string) => (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    if (field === 'plannedQuantity') {
      setPlannedQuantity(newValue);
      setPlannedQuantityErrorMsg(null);
    } else if (field === 'consumedQuantity') {
      setConsumedQuantity(newValue);
      setConsumedQuantityErrorMsg(null);
    }

  };

  const onSave = () => {
    if (validate()) {
      if (props.isEdit) {
        props.resource.cpcBasicUnitOfMeasureId = (unit) ? unit.cpcBasicUnitOfMeasureId : null;
        props.resource.unit = (unit) ? unit.unit : null;
        props.resource.corporateProductCatalogId = (cpc && cpc.id === null) ? props.cpcData.id : props.resource.corporateProductCatalogId;
        props.resource.resourceNumber = (cpc && cpc.id === null) ? props.cpcData.resourceNumber : props.resource.resourceNumber;
        props.resource.required = plannedQuantity;
        props.resource.consumedQuantity = consumedQuantity;
        props.resource.title = (cpc) ? cpc.label : props.resource.title;
        props.resource.environment = (cpc) ? cpc.environment : props.resource.environment;
        props.resource.pmolId = props.pmolID;
        props.resource.type = props.type;
        props.handleResourceSave(props.resource);
        onModalClose();
        props.clearCpcData();
      } else {
        props.resource.cpcBasicUnitOfMeasureId = (unit) ? unit.cpcBasicUnitOfMeasureId : null;
        props.resource.unit = (unit) ? unit.unit : null;
        props.resource.corporateProductCatalogId = props.cpcData.id;
        props.resource.required = plannedQuantity;
        props.resource.consumedQuantity = consumedQuantity;
        props.resource.title = props.cpcData.resourceTitle;
        props.resource.environment = cpc.environment;
        props.resource.pmolId = props.pmolID;
        props.resource.type = props.type;
        props.handleResourceSave(props.resource);
        onModalClose();
        props.clearCpcData();
      }

    } else {

    }
  };


  const validate = (): boolean => {
    let isValid = true;
    if (_.isNil(cpc) || _.isEmpty(cpc)) {
      setNameErrorMsg(t('titleRequired'));
      isValid = false;
    } else {
      setNameErrorMsg('');
    }
    if (_.isNil(plannedQuantity) || _.isEmpty(plannedQuantity)) {
      if (!plannedQuantity && plannedQuantity !== 0) {
        setPlannedQuantityErrorMsg(t('quantityrequired'));
        isValid = false;
      }

    } else {
      setPlannedQuantityErrorMsg('');
    }
    if (_.isNil(consumedQuantity) || _.isEmpty(consumedQuantity)) {
      if (!consumedQuantity && consumedQuantity !== 0) {
        setConsumedQuantityErrorMsg(t('quantityrequired'));
        isValid = false;
      }
    } else {
      setConsumedQuantityErrorMsg('');
    }
    return isValid;
  };

  const resetValidation = () => {
    setNameErrorMsg(null);
    setPlannedQuantityErrorMsg(null);
    setConsumedQuantityErrorMsg(null);
  };

  const validateResourceName = (resource: Resource) => {
    let isValidName = false;
    if (resource && resource.corporateProductCatalogId) {
      isValidName = true;
      //setRiskValidationState('cpcValidationMsg', '');
    } else {
      isValidName = false;
      // setRiskValidationState('cpcValidationMsg', t('nameRequired'));
    }
    return isValidName;
  };


  useEffect(() => {
    if (cpc) {
      props.handleCpcChange(cpc);
    }
  }, [cpc]);

  useEffect(() => {
    if (props.resource) {
      setDataToEdit();
    }
  }, [props.resource]);
  useEffect(() => {
    if (props.cpcData) {
      setUnit({ cpcBasicUnitOfMeasureId: props.cpcData.basicUnitOfMeasureId, unit: props.cpcData.basicUnitOfMeasure });
    }
  }, [props.cpcData]);


  const setDataToEdit = () => {
    if (props.resource.id) {
      setPlannedQuantity(props.resource.required);
      setConsumedQuantity(props.resource.consumedQuantity);
      setCpc({
        id: props.resource.corporateProductCatalogId,
        value: props.resource.resourceNumber,
        label: props.resource.title,
      });
      setUnit({ cpcBasicUnitOfMeasureId: props.resource.cpcBasicUnitOfMeasureId, unit: props.resource.unit });
    } else {
      setPlannedQuantity('');
      setConsumedQuantity('');
      setCpc(null);
      setUnit(null);
    }

  };

  const onModalClose = () => {
    if (!props.resource.id) {
      setPlannedQuantity('');
      setConsumedQuantity('');
      setCpc(null);
    }
    props.openResourceModal();
    resetValidation();
  };

  return (

    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          onModalClose();
        }}
        scrollableContentClassName="body"
        isBlocking={false}
        dragOptions={undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>{props.title}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              onModalClose();
            }}
          />
        </div>
        <div className={contentStyles.subHeader}>

        </div>
        <div className={contentStyles.body}>
          <div className="proj-detail-content inner">
            <div className="ms-Grid-row">
              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <Label required={true}>{t('resourceTitle')} </Label>
                    <GroupDropdown
                      autoFocus={true}
                      onChange={(key: string, text: string, environment: string, item: any) => {
                        if (key) {
                          let resource = { id: null, value: key, label: text, environment: environment, item: item };
                          setCpc(resource);
                          setNameErrorMsg(null);
                        } else {
                          props.clearCpcData();
                          setCpc(null);
                        }
                        // alert(key);
                      }}
                      selectedOption={(cpc) ? {
                        value: cpc.value,
                        label: cpc.label,
                      } : null}
                      promiseOptions={props.cpcOptions}
                      validationMessage={nameErrorMsg}
                      required={true}
                      reference={''}
                    />
                  </div>
                </Stack.Item>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('basicunitofMeasureMOU')}
                      value={(unit) ? unit.unit : ''}
                      readOnly={true}
                    />
                  </div>
                </Stack.Item>
              </Stack>

              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('plannedQuantity')}
                      value={plannedQuantity}
                      errorMessage={plannedQuantityErrorMsg}
                      type={'number'}
                      onChange={onChangeInput('plannedQuantity')}
                    />
                  </div>
                </Stack.Item>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('consumedquantity')}
                      value={consumedQuantity}

                      errorMessage={consumedQuantityErrorMsg}
                      type={'number'}
                      step={1}
                      min={0}
                      onChange={onChangeInput('consumedQuantity')}
                    />
                  </div>
                </Stack.Item>
              </Stack>
            </div>
          </div>
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={addIconWhite}
            text={t('save')}
            style={{ marginTop: 20, marginBottom: 30 }}
            onClick={() => {
              onSave();
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ResourceModal;

import React from 'react';
import { MessageBar, MessageBarType } from '@fluentui/react';

export interface State {
  showMsg: boolean;
  msg: string;
  msgType: string;
}

interface Props {
  notificatonType: 'success' | 'error',
  message: string
}

const UprinceMessageBar = ({ notificatonType, message }: Props) => {
  return (
    <span style={{ width: '100%' }}>
            <MessageBar
              messageBarType={
                notificatonType == 'success' ? MessageBarType.success : MessageBarType.error
              }
              isMultiline={false}
              dismissButtonAriaLabel="Close"
            >
                {message}
            </MessageBar>

        </span>
  );
};

export default UprinceMessageBar;

import * as React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
// import { messageService } from '../../../services/messageService';
import i18n from 'i18next';
import { getContractingUnit, getProject } from '../../../shared/util';
import history from '../../../history';

const WbsIssueListPaneCommandBar = () => {

  const getItems = () => {
    return [
      {
        key: 'new',
        text: i18n.t('new'),
        iconProps: { iconName: 'Add' },
        onClick: () => {
          history.push(`/CU/${getContractingUnit()}/project/${getProject()}/wbs/issue/new`);
        },
        disabled: false,
      },
      {
        key: 'delete',
        text: i18n.t('delete'),
        iconProps: { iconName: 'Delete' },
        onClick: () => {
          // messageService.sendMessage({ saveWbsIssue: true });
        },
        disabled: true,
      },
    ];
  };

  return <div>
    <CommandBar
      styles={{ root: { height: 26 } }}
      items={getItems()}
      ariaLabel='Use left and right arrow keys to navigate between commands'
    />
  </div>;
};

export default WbsIssueListPaneCommandBar;

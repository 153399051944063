import React, {useEffect, useState} from 'react';
import {Pivot, Stack} from "office-ui-fabric-react";
import {Calendar, DateRangeType, DayOfWeek, defaultCalendarStrings, FirstWeekOfYear, PivotItem} from "@fluentui/react";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {OSListPaneItem} from '../../../types/organisationSetting';
import {saveDayPlanningStateAttr} from "../../../reducers/dayPlanningReducer";
import {SelectedGridType} from '../../../types/dayPlanning';
import {getCalenderString} from '../../../shared/util';
import MyCalSortableTree from "../../dayPlanning/shortCutPane/pmolShortcutPane/myCalSortableTree";
import {saveCuMyCalendarStateAttr} from '../../../reducers/cuMyCalendarReducer';

interface Props {
    divClass: string;
    horizontalReSizer: any;
}

export const CuShortcutPane = (props: Props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const dayButtonId = 'day';
    const weekButtonId = 'week';

    const {dayPlanningDate, dayPlanningWeek, selectedGridType, myCalOsList} = useSelector(
      (state: any) => state.dplan
    );

    const [listPaneItems, setListPaneItems] = useState<OSListPaneItem[]>(myCalOsList);

    useEffect(() => {
        if (myCalOsList) {
            setListPaneItems(myCalOsList);
        }
    }, [myCalOsList]);

    const checkDateIsValid = (date: any) => {
        if (typeof date === "string") {
            return new Date(date);
        } else {
            return date;
        }
    };

    const removeParentIdFromSelectedLevel = async () => {
        return myCalOsList?.map((item: any) => {
            return {
                ...item,
                // parentId: item.organizationTaxonomyLevelId === filter.organizationTaxonomyLevelId ? null : item.parentId
            };
        });
    };

    const handleTitleClick = (node: any) => {
        // alert(JSON.stringify(node))
        dispatch(saveCuMyCalendarStateAttr("myCalSelectedOrgSetting", node));
        // props.handleListPaneItemClick('new');
    };

    const customNodeRender = (node: any) => {
        return <div style={{cursor: 'pointer'}} onClick={() => {
            handleTitleClick(node);
        }} id={node.id} key={node.id} className={'lastNode'}>
            <table key={node.id + 'table'} style={{width: '100%'}}>
                <tr>
                    <td>{node.organization?.title}</td>
                </tr>
            </table>
        </div>;
    };

    return (
      <div className={`dayPlanning ${props.divClass} wrapper`}>
          <Stack
            horizontal
            wrap
            styles={{
                root: {
                    width: '100%',
                    verticalAlign: 'center'
                }
            }}
          >
              <Stack.Item>
                  <Pivot selectedKey={selectedGridType} onLinkClick={(item: any) => {
                      if (item) {
                          if (item.props.itemKey! === dayButtonId) {
                              dispatch(saveDayPlanningStateAttr('selectedGridType', SelectedGridType.DAY));
                          } else if (item.props.itemKey! === weekButtonId) {
                              dispatch(saveDayPlanningStateAttr('selectedGridType', SelectedGridType.WEEK));
                          }
                      }
                  }}>
                      <PivotItem headerText={t('day')} itemKey={dayButtonId}>
                          < Calendar
                            onSelectDate={(date: Date) => {
                                let selectedWeek: {};
                                dispatch(saveDayPlanningStateAttr('dayPlanningDate', date));
                                selectedWeek = {
                                    startDate: moment(date).clone().startOf('isoWeek').format('YYYY-MM-DD'),
                                    endDate: moment(date).clone().endOf('isoWeek').format('YYYY-MM-DD'),
                                    toDate: date
                                }
                                dispatch(saveDayPlanningStateAttr('dayPlanningWeek', selectedWeek));
                            }}
                            value={checkDateIsValid(dayPlanningDate)}
                            firstDayOfWeek={DayOfWeek.Monday}
                            // Calendar uses English strings by default. For localized apps, you must override this prop.
                            strings={getCalenderString()}
                            showWeekNumbers
                            showMonthPickerAsOverlay
                            highlightSelectedMonth
                            firstWeekOfYear={FirstWeekOfYear.FirstFourDayWeek}
                            showGoToToday={false}
                          />
                      </PivotItem>
                      <PivotItem headerText={t('week')} itemKey={weekButtonId}>
                          <Calendar
                            value={checkDateIsValid(dayPlanningWeek.toDate)}
                            dateRangeType={DateRangeType.Week}
                            onSelectDate={(date: Date, selectedDateRangeArray: Date[] | undefined) => {
                                let selectedWeek = {};
                                if (selectedDateRangeArray) {
                                    selectedWeek = {
                                        startDate: selectedDateRangeArray[0],
                                        endDate: selectedDateRangeArray[selectedDateRangeArray.length - 1],
                                        toDate: date
                                    }
                                }
                                dispatch(saveDayPlanningStateAttr('dayPlanningWeek', selectedWeek));
                            }}
                            firstDayOfWeek={DayOfWeek.Monday}
                            // Calendar uses English strings by default. For localized apps, you must override this prop.
                            strings={defaultCalendarStrings}
                            showWeekNumbers
                            showMonthPickerAsOverlay
                            highlightSelectedMonth
                            firstWeekOfYear={FirstWeekOfYear.FirstFourDayWeek}
                            showGoToToday={false}
                          />
                      </PivotItem>
                  </Pivot>
              </Stack.Item>
              <Stack.Item>
                  <div className={`org wrapper-holder orgSetting sortable-tree-wrapper`}>
                      <MyCalSortableTree
                        horizontalReSizer={props.horizontalReSizer}
                        treeData={listPaneItems}
                        customNodeLevel={7}
                        // customNodeRender={(node: any) => customNodeRender(node)}
                        handleTitleClick={(node: any) => {
                            handleTitleClick(node);
                        }}
                      />
                  </div>
              </Stack.Item>
          </Stack>
      </div>
    );
}

import {
    READ_MY_CALENDAR_CU_LEVEL_LIST_DATA,
    READ_MY_CALENDAR_CU_LEVEL_LIST_DATA_FAIL,
    READ_MY_CALENDAR_CU_LEVEL_LIST_DATA_SUCCESS,
    SAVE_CU_MY_CALENDAR_STATE_ATTR
} from "../actionTypes/cuMyCalendarActionTypes";
import {READ_MY_CALENDAR_CU_LEVEL_LIST_DATA_EP} from "../shared/endpoints";
import {DayPlanningCuList} from "../types/cuMyCalendar";

const defaultState: any = {
    horizontalReSizer: null,
    loadMsg: '',
    teamList: [],
    myCalSelectedOrgSetting: null,
}

export default function cuMyCalReducer(state = defaultState, action: any) {
    if (action) {
        switch (action.type) {

            case SAVE_CU_MY_CALENDAR_STATE_ATTR:
                const attr = Object.keys(action)[1];
                const value = Object.values(action)[1];
                return {
                    ...state,
                    [attr]: value
                };

            case READ_MY_CALENDAR_CU_LEVEL_LIST_DATA:
                return {
                    ...state,
                    teamList: [],
                };
            case READ_MY_CALENDAR_CU_LEVEL_LIST_DATA_SUCCESS:
                return {
                    ...state,
                    teamList: action.payload.data.result,
                    loadMsg: !action.payload.data.status
                      ? action.payload.data.message
                      : '',
                };
            case READ_MY_CALENDAR_CU_LEVEL_LIST_DATA_FAIL:
                return {
                    ...state,
                    teamList: [],
                    loadMsg: action.error.response.data.message,
                };

            default:
                return state;
        }
    }
}

export function saveCuMyCalendarStateAttr(attr: string, value: any) {
    return {
        type: SAVE_CU_MY_CALENDAR_STATE_ATTR,
        [attr]: value
    };
}

export const filterMyCalendarCuLevelListData = (filter: DayPlanningCuList) => {
    return {
        types: [
            READ_MY_CALENDAR_CU_LEVEL_LIST_DATA,
            READ_MY_CALENDAR_CU_LEVEL_LIST_DATA_SUCCESS,
            READ_MY_CALENDAR_CU_LEVEL_LIST_DATA_FAIL,
        ],
        payload: {
            request: {
                method: 'POST',
                url: READ_MY_CALENDAR_CU_LEVEL_LIST_DATA_EP,
                data: filter,
            },
        },
    };
};
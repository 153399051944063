import {
  BOR_STATUS_DROPDOWN_CREATE,
  BOR_STATUS_DROPDOWN_CREATE_FAIL,
  BOR_STATUS_DROPDOWN_CREATE_SUCCESS,
  BOR_STATUS_DROPDOWN_UPDATE,
  BOR_STATUS_DROPDOWN_UPDATE_FAIL,
  BOR_STATUS_DROPDOWN_UPDATE_SUCCESS,
  CPC_BRAND_DROPDOWN_CREATE,
  CPC_BRAND_DROPDOWN_CREATE_FAIL,
  CPC_BRAND_DROPDOWN_CREATE_SUCCESS,
  CPC_BRAND_DROPDOWN_UPDATE,
  CPC_BRAND_DROPDOWN_UPDATE_FAIL,
  CPC_BRAND_DROPDOWN_UPDATE_SUCCESS,
  CPC_MATERIAL_CREATE,
  CPC_MATERIAL_CREATE_FAIL,
  CPC_MATERIAL_CREATE_SUCCESS,
  CPC_MATERIAL_UPDATE,
  CPC_MATERIAL_UPDATE_FAIL,
  CPC_MATERIAL_UPDATE_SUCCESS,
  CPC_MOU_DROPDOWN_KEYS_CREATE,
  CPC_MOU_DROPDOWN_KEYS_CREATE_FAIL,
  CPC_MOU_DROPDOWN_KEYS_CREATE_SUCCESS,
  CPC_PRESSURE_DROPDOWN_CREATE,
  CPC_PRESSURE_DROPDOWN_CREATE_FAIL,
  CPC_PRESSURE_DROPDOWN_CREATE_SUCCESS,
  CPC_PRESSURE_DROPDOWN_UPDATE,
  CPC_PRESSURE_DROPDOWN_UPDATE_FAIL,
  CPC_PRESSURE_DROPDOWN_UPDATE_SUCCESS,
  CPC_RESOURCE_FAMILY_DROPDOWN_CREATE,
  CPC_RESOURCE_FAMILY_DROPDOWN_CREATE_FAIL,
  CPC_RESOURCE_FAMILY_DROPDOWN_CREATE_SUCCESS,
  CPC_RESOURCE_FAMILY_DROPDOWN_UPDATE,
  CPC_RESOURCE_FAMILY_DROPDOWN_UPDATE_FAIL,
  CPC_RESOURCE_FAMILY_DROPDOWN_UPDATE_SUCCESS,
  CPC_RESOURCE_TYPE_CREATE,
  CPC_RESOURCE_TYPE_CREATE_FAIL,
  CPC_RESOURCE_TYPE_CREATE_SUCCESS,
  CPC_RESOURCE_TYPE_UPDATE,
  CPC_RESOURCE_TYPE_UPDATE_FAIL,
  CPC_RESOURCE_TYPE_UPDATE_SUCCESS,
  CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_CREATE,
  CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_CREATE_FAIL,
  CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_CREATE_SUCCESS,
  CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_UPDATE,
  CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_UPDATE_FAIL,
  CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_UPDATE_SUCCESS,
  DROPDOWN_KEYS_UPDATE,
  DROPDOWN_KEYS_UPDATE_FAIL,
  DROPDOWN_KEYS_UPDATE_SUCCESS,
  DROPDOWN_TRANSLATION_CREATE,
  DROPDOWN_TRANSLATION_CREATE_FAIL,
  DROPDOWN_TRANSLATION_CREATE_SUCCESS,
  DROPDOWN_TRANSLATION_DELETE,
  DROPDOWN_TRANSLATION_DELETE_FAIL,
  DROPDOWN_TRANSLATION_DELETE_SUCCESS,
  DROPDOWN_TRANSLATION_READ,
  DROPDOWN_TRANSLATION_READ_FAIL,
  DROPDOWN_TRANSLATION_READ_SUCCESS,
  GET_BOR_STATUS_DROPDOWN_BY_CODE,
  GET_BOR_STATUS_DROPDOWN_BY_CODE_FAIL,
  GET_BOR_STATUS_DROPDOWN_BY_CODE_SUCCESS,
  GET_COUNTRY_DISTINCT,
  GET_COUNTRY_DISTINCT_FAIL,
  GET_COUNTRY_DISTINCT_SUCCESS,
  GET_CPC_BRAND_DROPDOWN_BY_CODE,
  GET_CPC_BRAND_DROPDOWN_BY_CODE_FAIL,
  GET_CPC_BRAND_DROPDOWN_BY_CODE_SUCCESS,
  GET_CPC_MATERIAL_BY_CODE,
  GET_CPC_MATERIAL_BY_CODE_FAIL,
  GET_CPC_MATERIAL_BY_CODE_SUCCESS,
  GET_CPC_PRESSURE_DROPDOWN_BY_CODE,
  GET_CPC_PRESSURE_DROPDOWN_BY_CODE_FAIL,
  GET_CPC_PRESSURE_DROPDOWN_BY_CODE_SUCCESS,
  GET_CPC_RESOURCE_FAMILY_DROPDOWN_BY_CODE,
  GET_CPC_RESOURCE_FAMILY_DROPDOWN_BY_CODE_FAIL,
  GET_CPC_RESOURCE_FAMILY_DROPDOWN_BY_CODE_SUCCESS,
  GET_CPC_RESOURCE_TYPES_BY_CODE,
  GET_CPC_RESOURCE_TYPES_BY_CODE_FAIL,
  GET_CPC_RESOURCE_TYPES_BY_CODE_SUCCESS,
  GET_CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_BY_CODE,
  GET_CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_BY_CODE_FAIL,
  GET_CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_BY_CODE_SUCCESS,
  GET_DROPDOWN_KEYS_BY_CODE,
  GET_DROPDOWN_KEYS_BY_CODE_FAIL,
  GET_DROPDOWN_KEYS_BY_CODE_SUCCESS,
  GET_DROPDOWN_LANGUAGE_CODE,
  GET_DROPDOWN_LANGUAGE_CODE_FAIL,
  GET_DROPDOWN_LANGUAGE_CODE_SUCCESS,
  GET_LANGUAGE_BY_CODE,
  GET_LANGUAGE_BY_CODE_FAIL,
  GET_LANGUAGE_BY_CODE_SUCCESS,
  GET_LANGUAGE_DISTINCT,
  GET_LANGUAGE_DISTINCT_FAIL,
  GET_LANGUAGE_DISTINCT_SUCCESS,
  GET_PBS_EXPERIENCE_DROPDOWN_BY_CODE,
  GET_PBS_EXPERIENCE_DROPDOWN_BY_CODE_FAIL,
  GET_PBS_EXPERIENCE_DROPDOWN_BY_CODE_SUCCESS,
  GET_PBS_ITEM_TYPE_DROPDOWN_BY_CODE,
  GET_PBS_ITEM_TYPE_DROPDOWN_BY_CODE_FAIL,
  GET_PBS_ITEM_TYPE_DROPDOWN_BY_CODE_SUCCESS,
  GET_PBS_SKILLS_DROPDOWN_BY_CODE,
  GET_PBS_SKILLS_DROPDOWN_BY_CODE_FAIL,
  GET_PBS_SKILLS_DROPDOWN_BY_CODE_SUCCESS,
  GET_PBS_STATUS_DROPDOWN_BY_CODE,
  GET_PBS_STATUS_DROPDOWN_BY_CODE_FAIL,
  GET_PBS_STATUS_DROPDOWN_BY_CODE_SUCCESS,
  GET_PBS_TOLERANCE_DROPDOWN_BY_CODE,
  GET_PBS_TOLERANCE_DROPDOWN_BY_CODE_FAIL,
  GET_PBS_TOLERANCE_DROPDOWN_BY_CODE_SUCCESS,
  GET_PMOL_STATUS_DROPDOWN_BY_CODE,
  GET_PMOL_STATUS_DROPDOWN_BY_CODE_FAIL,
  GET_PMOL_STATUS_DROPDOWN_BY_CODE_SUCCESS,
  GET_PMOL_TYPE_DROPDOWN_BY_CODE,
  GET_PMOL_TYPE_DROPDOWN_BY_CODE_FAIL,
  GET_PMOL_TYPE_DROPDOWN_BY_CODE_SUCCESS,
  GET_PROJECT_CURRENCIES_DROPDOWN_BY_CODE,
  GET_PROJECT_CURRENCIES_DROPDOWN_BY_CODE_FAIL,
  GET_PROJECT_CURRENCIES_DROPDOWN_BY_CODE_SUCCESS,
  GET_PROJECT_LEDGER_DROPDOWN_BY_CODE,
  GET_PROJECT_LEDGER_DROPDOWN_BY_CODE_FAIL,
  GET_PROJECT_LEDGER_DROPDOWN_BY_CODE_SUCCESS,
  GET_PROJECT_MANAGEMENT_LEVELS_DROPDOWN_BY_CODE,
  GET_PROJECT_MANAGEMENT_LEVELS_DROPDOWN_BY_CODE_FAIL,
  GET_PROJECT_MANAGEMENT_LEVELS_DROPDOWN_BY_CODE_SUCCESS,
  GET_PROJECT_STATES_DROPDOWN_BY_CODE,
  GET_PROJECT_STATES_DROPDOWN_BY_CODE_FAIL,
  GET_PROJECT_STATES_DROPDOWN_BY_CODE_SUCCESS,
  GET_PROJECT_TEMPLATES_DROPDOWN_BY_CODE,
  GET_PROJECT_TEMPLATES_DROPDOWN_BY_CODE_FAIL,
  GET_PROJECT_TEMPLATES_DROPDOWN_BY_CODE_SUCCESS,
  GET_PROJECT_TYPES_DROPDOWN_BY_CODE,
  GET_PROJECT_TYPES_DROPDOWN_BY_CODE_FAIL,
  GET_PROJECT_TYPES_DROPDOWN_BY_CODE_SUCCESS,
  PBS_EXPERIENCE_DROPDOWN_CREATE,
  PBS_EXPERIENCE_DROPDOWN_CREATE_FAIL,
  PBS_EXPERIENCE_DROPDOWN_CREATE_SUCCESS,
  PBS_EXPERIENCE_DROPDOWN_UPDATE,
  PBS_EXPERIENCE_DROPDOWN_UPDATE_FAIL,
  PBS_EXPERIENCE_DROPDOWN_UPDATE_SUCCESS,
  PBS_ITEM_TYPE_DROPDOWN_CREATE,
  PBS_ITEM_TYPE_DROPDOWN_CREATE_FAIL,
  PBS_ITEM_TYPE_DROPDOWN_CREATE_SUCCESS,
  PBS_ITEM_TYPE_DROPDOWN_UPDATE,
  PBS_ITEM_TYPE_DROPDOWN_UPDATE_FAIL,
  PBS_ITEM_TYPE_DROPDOWN_UPDATE_SUCCESS,
  PBS_SKILLS_DROPDOWN_CREATE,
  PBS_SKILLS_DROPDOWN_CREATE_FAIL,
  PBS_SKILLS_DROPDOWN_CREATE_SUCCESS,
  PBS_SKILLS_DROPDOWN_UPDATE,
  PBS_SKILLS_DROPDOWN_UPDATE_FAIL,
  PBS_SKILLS_DROPDOWN_UPDATE_SUCCESS,
  PBS_STATUS_DROPDOWN_CREATE,
  PBS_STATUS_DROPDOWN_CREATE_FAIL,
  PBS_STATUS_DROPDOWN_CREATE_SUCCESS,
  PBS_STATUS_DROPDOWN_UPDATE,
  PBS_STATUS_DROPDOWN_UPDATE_FAIL,
  PBS_STATUS_DROPDOWN_UPDATE_SUCCESS,
  PBS_TOLERANCE_DROPDOWN_CREATE,
  PBS_TOLERANCE_DROPDOWN_CREATE_FAIL,
  PBS_TOLERANCE_DROPDOWN_CREATE_SUCCESS,
  PBS_TOLERANCE_DROPDOWN_UPDATE,
  PBS_TOLERANCE_DROPDOWN_UPDATE_FAIL,
  PBS_TOLERANCE_DROPDOWN_UPDATE_SUCCESS,
  PMOL_STATUS_DROPDOWN_CREATE,
  PMOL_STATUS_DROPDOWN_CREATE_FAIL,
  PMOL_STATUS_DROPDOWN_CREATE_SUCCESS,
  PMOL_STATUS_DROPDOWN_UPDATE,
  PMOL_STATUS_DROPDOWN_UPDATE_FAIL,
  PMOL_STATUS_DROPDOWN_UPDATE_SUCCESS,
  PMOL_TYPE_DROPDOWN_CREATE,
  PMOL_TYPE_DROPDOWN_CREATE_FAIL,
  PMOL_TYPE_DROPDOWN_CREATE_SUCCESS,
  PMOL_TYPE_DROPDOWN_UPDATE,
  PMOL_TYPE_DROPDOWN_UPDATE_FAIL,
  PMOL_TYPE_DROPDOWN_UPDATE_SUCCESS,
  PROJECT_CURRENCIES_DROPDOWN_CREATE,
  PROJECT_CURRENCIES_DROPDOWN_CREATE_FAIL,
  PROJECT_CURRENCIES_DROPDOWN_CREATE_SUCCESS,
  PROJECT_CURRENCIES_DROPDOWN_UPDATE,
  PROJECT_CURRENCIES_DROPDOWN_UPDATE_FAIL,
  PROJECT_CURRENCIES_DROPDOWN_UPDATE_SUCCESS,
  PROJECT_LEDGER_DROPDOWN_CREATE,
  PROJECT_LEDGER_DROPDOWN_CREATE_FAIL,
  PROJECT_LEDGER_DROPDOWN_CREATE_SUCCESS,
  PROJECT_LEDGER_DROPDOWN_UPDATE,
  PROJECT_LEDGER_DROPDOWN_UPDATE_FAIL,
  PROJECT_LEDGER_DROPDOWN_UPDATE_SUCCESS,
  PROJECT_MANAGEMENT_LEVELS_DROPDOWN_CREATE,
  PROJECT_MANAGEMENT_LEVELS_DROPDOWN_CREATE_FAIL,
  PROJECT_MANAGEMENT_LEVELS_DROPDOWN_CREATE_SUCCESS,
  PROJECT_MANAGEMENT_LEVELS_DROPDOWN_UPDATE,
  PROJECT_MANAGEMENT_LEVELS_DROPDOWN_UPDATE_FAIL,
  PROJECT_MANAGEMENT_LEVELS_DROPDOWN_UPDATE_SUCCESS,
  PROJECT_STATES_DROPDOWN_CREATE,
  PROJECT_STATES_DROPDOWN_CREATE_FAIL,
  PROJECT_STATES_DROPDOWN_CREATE_SUCCESS,
  PROJECT_STATES_DROPDOWN_UPDATE,
  PROJECT_STATES_DROPDOWN_UPDATE_FAIL,
  PROJECT_STATES_DROPDOWN_UPDATE_SUCCESS,
  PROJECT_TEAM_ROLE_DROPDOWN_READ,
  PROJECT_TEAM_ROLE_DROPDOWN_READ_FAIL,
  PROJECT_TEAM_ROLE_DROPDOWN_READ_SUCCESS,
  PROJECT_TEMPLATES_DROPDOWN_CREATE,
  PROJECT_TEMPLATES_DROPDOWN_CREATE_FAIL,
  PROJECT_TEMPLATES_DROPDOWN_CREATE_SUCCESS,
  PROJECT_TEMPLATES_DROPDOWN_UPDATE,
  PROJECT_TEMPLATES_DROPDOWN_UPDATE_FAIL,
  PROJECT_TEMPLATES_DROPDOWN_UPDATE_SUCCESS,
  PROJECT_TYPES_DROPDOWN_CREATE,
  PROJECT_TYPES_DROPDOWN_CREATE_FAIL,
  PROJECT_TYPES_DROPDOWN_CREATE_SUCCESS,
  PROJECT_TYPES_DROPDOWN_UPDATE,
  PROJECT_TYPES_DROPDOWN_UPDATE_FAIL,
  PROJECT_TYPES_DROPDOWN_UPDATE_SUCCESS,
} from '../actionTypes/dropdownTranslationActionTypes';

import {
  BOR_STATUS_DROPDOWN_CREATE_EP,
  BOR_STATUS_DROPDOWN_UPDATE_EP,
  CPC_BRAND_DROPDOWN_CREATE_EP,
  CPC_BRAND_DROPDOWN_UPDATE_EP,
  CPC_MATERIAL_CREATE_EP,
  CPC_MATERIAL_UPDATE_EP,
  CPC_MOU_DROPDOWN_KEYS_CREATE_EP,
  CPC_PRESSURE_DROPDOWN_CREATE_EP,
  CPC_PRESSURE_DROPDOWN_UPDATE_EP,
  CPC_RESOURCE_FAMILY_DROPDOWN_CREATE_EP,
  CPC_RESOURCE_FAMILY_DROPDOWN_UPDATE_EP,
  CPC_RESOURCE_TYPE_CREATE_EP,
  CPC_RESOURCE_TYPE_UPDATE_EP,
  CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_CREATE_EP,
  CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_UPDATE_EP,
  DROPDOWN_KEYS_UPDATE_EP,
  GET_BOR_STATUS_DROPDOWN_BY_CODE_EP,
  GET_COUNTRY_DISTINCT_EP,
  GET_CPC_BRAND_DROPDOWN_BY_CODE_EP,
  GET_CPC_MATERIAL_BY_CODE_EP,
  GET_CPC_PRESSURE_DROPDOWN_BY_CODE_EP,
  GET_CPC_RESOURCE_FAMILY_DROPDOWN_BY_CODE_EP,
  GET_CPC_RESOURCE_TYPES_BY_CODE_EP,
  GET_CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_BY_CODE_EP,
  GET_DROPDOWN_KEYS_BY_CODE_EP,
  GET_LANGUAGE_BY_CODE_EP,
  GET_LANGUAGES_DISTINCT_EP,
  GET_PBS_EXPERIENCE_DROPDOWN_BY_CODE_EP,
  GET_PBS_ITEM_TYPE_DROPDOWN_BY_CODE_EP,
  GET_PBS_SKILLS_DROPDOWN_BY_CODE_EP,
  GET_PBS_STATUS_DROPDOWN_BY_CODE_EP,
  GET_PBS_TOLERANCE_DROPDOWN_BY_CODE_EP,
  GET_PMOL_STATUS_DROPDOWN_BY_CODE_EP,
  GET_PMOL_TYPE_DROPDOWN_BY_CODE_EP,
  GET_PROJECT_CURRENCIES_DROPDOWN_BY_CODE_EP,
  GET_PROJECT_LEDGER_DROPDOWN_BY_CODE_EP,
  GET_PROJECT_MANAGEMENT_LEVELS_DROPDOWN_BY_CODE_EP,
  GET_PROJECT_STATES_DROPDOWN_BY_CODE_EP,
  GET_PROJECT_TEMPLATES_DROPDOWN_BY_CODE_EP,
  GET_PROJECT_TYPES_DROPDOWN_BY_CODE_EP,
  PBS_EXPERIENCE_DROPDOWN_CREATE_EP,
  PBS_EXPERIENCE_DROPDOWN_UPDATE_EP,
  PBS_ITEM_TYPE_DROPDOWN_CREATE_EP,
  PBS_ITEM_TYPE_DROPDOWN_UPDATE_EP,
  PBS_SKILLS_DROPDOWN_CREATE_EP,
  PBS_SKILLS_DROPDOWN_UPDATE_EP,
  PBS_STATUS_DROPDOWN_CREATE_EP,
  PBS_STATUS_DROPDOWN_UPDATE_EP,
  PBS_TOLERANCE_DROPDOWN_CREATE_EP,
  PBS_TOLERANCE_DROPDOWN_UPDATE_EP,
  PMOL_STATUS_DROPDOWN_CREATE_EP,
  PMOL_STATUS_DROPDOWN_UPDATE_EP,
  PMOL_TYPE_DROPDOWN_CREATE_EP,
  PMOL_TYPE_DROPDOWN_UPDATE_EP,
  PROJECT_CURRENCIES_DROPDOWN_CREATE_EP,
  PROJECT_CURRENCIES_DROPDOWN_UPDATE_EP,
  PROJECT_LEDGER_DROPDOWN_CREATE_EP,
  PROJECT_LEDGER_DROPDOWN_UPDATE_EP,
  PROJECT_MANAGEMENT_LEVELS_DROPDOWN_CREATE_EP,
  PROJECT_MANAGEMENT_LEVELS_DROPDOWN_UPDATE_EP,
  PROJECT_ROLE_DROPDOWN_BY_CODE_EP,
  PROJECT_STATES_DROPDOWN_CREATE_EP,
  PROJECT_STATES_DROPDOWN_UPDATE_EP,
  PROJECT_TEMPLATES_DROPDOWN_CREATE_EP,
  PROJECT_TEMPLATES_DROPDOWN_UPDATE_EP,
  PROJECT_TYPES_DROPDOWN_CREATE_EP,
  PROJECT_TYPES_DROPDOWN_UPDATE_EP,
} from '../shared/endpoints';
import { CreateDropdownTranslation, DropdownTranslation } from '../types/dropdownTranslation';

const defaultState: any = {
  getCountryDropdown: [],
  getLanguageList: [],
  getDropdownByCode: [],
  getDropdownKeyList: [],
  dropdownUpdateStatus: false,
  getLanguageCode: [],
  dropdownKeyCreateStatus: false,
  teamRoles: [],
};

const dropdownTranslationReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case GET_COUNTRY_DISTINCT:
      return { ...state };

    case GET_COUNTRY_DISTINCT_SUCCESS:
      return {
        ...state,
        getCountryDropdown: formattedPayloadToCountryDropdown(
          action.payload.data.result,
        ),
      };

    case GET_COUNTRY_DISTINCT_FAIL:
      return { ...state };

    case GET_LANGUAGE_DISTINCT:
      return { ...state };

    case GET_LANGUAGE_DISTINCT_SUCCESS:
      return {
        ...state,
        getLanguageList: formattedPayloadToLanguageDropdown(
          action.payload.data.result,
        ),
      };

    case GET_LANGUAGE_DISTINCT_FAIL:
      return { ...state };

    case GET_LANGUAGE_BY_CODE:
      return { ...state };

    case GET_LANGUAGE_BY_CODE_SUCCESS:
      return {
        ...state,
        getDropdownByCode: action.payload.data.result,
      };

    case GET_LANGUAGE_BY_CODE_FAIL:
      return { ...state };

    case GET_DROPDOWN_KEYS_BY_CODE:
      return { ...state };

    case GET_DROPDOWN_KEYS_BY_CODE_SUCCESS:
      return {
        ...state,
        getDropdownKeyList: action.payload.data.result,
      };

    case GET_DROPDOWN_KEYS_BY_CODE_FAIL:
      return { ...state };

    case DROPDOWN_KEYS_UPDATE:
      return { ...state };

    case DROPDOWN_KEYS_UPDATE_SUCCESS:
      return {
        ...state,
        dropdownUpdateStatus: true,
      };

    case DROPDOWN_KEYS_UPDATE_FAIL:
      return { ...state };

    case GET_DROPDOWN_LANGUAGE_CODE:
      return { ...state };

    case GET_DROPDOWN_LANGUAGE_CODE_SUCCESS:
      return {
        ...state,
        getLanguageCode: true,
      };

    case GET_DROPDOWN_LANGUAGE_CODE_FAIL:
      return { ...state };

    case CPC_MOU_DROPDOWN_KEYS_CREATE:
      return { ...state };

    case CPC_MOU_DROPDOWN_KEYS_CREATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case CPC_MOU_DROPDOWN_KEYS_CREATE_FAIL:
      return { ...state };

    case GET_CPC_RESOURCE_TYPES_BY_CODE:
      return { ...state };

    case GET_CPC_RESOURCE_TYPES_BY_CODE_SUCCESS:
      return {
        ...state,
        getDropdownKeyList: action.payload.data.result,
      };

    case GET_CPC_RESOURCE_TYPES_BY_CODE_FAIL:
      return { ...state };

    case CPC_RESOURCE_TYPE_UPDATE:
      return { ...state };

    case CPC_RESOURCE_TYPE_UPDATE_SUCCESS:
      return {
        ...state,
        dropdownKeyUpdateStatus: true,
      };

    case CPC_RESOURCE_TYPE_UPDATE_FAIL:
      return { ...state };

    case CPC_RESOURCE_TYPE_CREATE:
      return { ...state };

    case CPC_RESOURCE_TYPE_CREATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case CPC_RESOURCE_TYPE_CREATE_FAIL:
      return { ...state };

    case GET_CPC_MATERIAL_BY_CODE:
      return { ...state };

    case GET_CPC_MATERIAL_BY_CODE_SUCCESS:
      return {
        ...state,
        getDropdownKeyList: action.payload.data.result,
      };

    case GET_CPC_MATERIAL_BY_CODE_FAIL:
      return { ...state };

    case CPC_MATERIAL_UPDATE:
      return { ...state };

    case CPC_MATERIAL_UPDATE_SUCCESS:
      return {
        ...state,
        dropdownKeyUpdateStatus: true,
      };

    case CPC_MATERIAL_UPDATE_FAIL:
      return { ...state };

    case CPC_MATERIAL_CREATE:
      return { ...state };

    case CPC_MATERIAL_CREATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case CPC_MATERIAL_CREATE_FAIL:
      return { ...state };

    case GET_CPC_PRESSURE_DROPDOWN_BY_CODE:
      return { ...state };

    case GET_CPC_PRESSURE_DROPDOWN_BY_CODE_SUCCESS:
      return {
        ...state,
        getDropdownKeyList: action.payload.data.result,
      };

    case GET_CPC_PRESSURE_DROPDOWN_BY_CODE_FAIL:
      return { ...state };

    case CPC_PRESSURE_DROPDOWN_UPDATE:
      return { ...state };

    case CPC_PRESSURE_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        dropdownKeyUpdateStatus: true,
      };

    case CPC_PRESSURE_DROPDOWN_UPDATE_FAIL:
      return { ...state };

    case CPC_PRESSURE_DROPDOWN_CREATE:
      return { ...state };

    case CPC_PRESSURE_DROPDOWN_CREATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case CPC_PRESSURE_DROPDOWN_CREATE_FAIL:
      return { ...state };

    case GET_CPC_BRAND_DROPDOWN_BY_CODE:
      return { ...state };

    case GET_CPC_BRAND_DROPDOWN_BY_CODE_SUCCESS:
      return {
        ...state,
        getDropdownKeyList: action.payload.data.result,
      };

    case GET_CPC_BRAND_DROPDOWN_BY_CODE_FAIL:
      return { ...state };

    case CPC_BRAND_DROPDOWN_UPDATE:
      return { ...state };

    case CPC_BRAND_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        dropdownKeyUpdateStatus: true,
      };

    case CPC_BRAND_DROPDOWN_UPDATE_FAIL:
      return { ...state };

    case CPC_BRAND_DROPDOWN_CREATE:
      return { ...state };

    case CPC_BRAND_DROPDOWN_CREATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case CPC_BRAND_DROPDOWN_CREATE_FAIL:
      return { ...state };

    case GET_CPC_RESOURCE_FAMILY_DROPDOWN_BY_CODE:
      return { ...state };

    case GET_CPC_RESOURCE_FAMILY_DROPDOWN_BY_CODE_SUCCESS:
      return {
        ...state,
        getDropdownKeyList: action.payload.data.result,
      };

    case GET_CPC_RESOURCE_FAMILY_DROPDOWN_BY_CODE_FAIL:
      return { ...state };

    case CPC_RESOURCE_FAMILY_DROPDOWN_UPDATE:
      return { ...state };

    case CPC_RESOURCE_FAMILY_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        dropdownKeyUpdateStatus: true,
      };

    case CPC_RESOURCE_FAMILY_DROPDOWN_UPDATE_FAIL:
      return { ...state };

    case CPC_RESOURCE_FAMILY_DROPDOWN_CREATE:
      return { ...state };

    case CPC_RESOURCE_FAMILY_DROPDOWN_CREATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case CPC_RESOURCE_FAMILY_DROPDOWN_CREATE_FAIL:
      return { ...state };

    case GET_CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_BY_CODE:
      return { ...state };

    case GET_CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_BY_CODE_SUCCESS:
      return {
        ...state,
        getDropdownKeyList: action.payload.data.result,
      };

    case GET_CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_BY_CODE_FAIL:
      return { ...state };

    case CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_UPDATE:
      return { ...state };

    case CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        dropdownKeyUpdateStatus: true,
      };

    case CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_UPDATE_FAIL:
      return { ...state };

    case CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_CREATE:
      return { ...state };

    case CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_CREATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_CREATE_FAIL:
      return { ...state };

    case GET_PROJECT_MANAGEMENT_LEVELS_DROPDOWN_BY_CODE:
      return { ...state };

    case GET_PROJECT_MANAGEMENT_LEVELS_DROPDOWN_BY_CODE_SUCCESS:
      return {
        ...state,
        getDropdownKeyList: action.payload.data.result,
      };

    case GET_PROJECT_MANAGEMENT_LEVELS_DROPDOWN_BY_CODE_FAIL:
      return { ...state };

    case PROJECT_MANAGEMENT_LEVELS_DROPDOWN_UPDATE:
      return { ...state };

    case PROJECT_MANAGEMENT_LEVELS_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        dropdownKeyUpdateStatus: true,
      };

    case PROJECT_MANAGEMENT_LEVELS_DROPDOWN_UPDATE_FAIL:
      return { ...state };

    case PROJECT_MANAGEMENT_LEVELS_DROPDOWN_CREATE:
      return { ...state };

    case PROJECT_MANAGEMENT_LEVELS_DROPDOWN_CREATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case PROJECT_MANAGEMENT_LEVELS_DROPDOWN_CREATE_FAIL:
      return { ...state };

    case GET_PBS_TOLERANCE_DROPDOWN_BY_CODE:
      return { ...state };

    case GET_PBS_TOLERANCE_DROPDOWN_BY_CODE_SUCCESS:
      return {
        ...state,
        getDropdownKeyList: action.payload.data.result,
      };

    case GET_PBS_TOLERANCE_DROPDOWN_BY_CODE_FAIL:
      return { ...state };

    case PBS_TOLERANCE_DROPDOWN_UPDATE:
      return { ...state };

    case PBS_TOLERANCE_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        dropdownKeyUpdateStatus: true,
      };

    case PBS_TOLERANCE_DROPDOWN_UPDATE_FAIL:
      return { ...state };

    case PBS_TOLERANCE_DROPDOWN_CREATE:
      return { ...state };

    case PBS_TOLERANCE_DROPDOWN_CREATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case PBS_TOLERANCE_DROPDOWN_CREATE_FAIL:
      return { ...state };

    case GET_PBS_STATUS_DROPDOWN_BY_CODE:
      return { ...state };

    case GET_PBS_STATUS_DROPDOWN_BY_CODE_SUCCESS:
      return {
        ...state,
        getDropdownKeyList: action.payload.data.result,
      };

    case GET_PBS_STATUS_DROPDOWN_BY_CODE_FAIL:
      return { ...state };

    case PBS_STATUS_DROPDOWN_UPDATE:
      return { ...state };

    case PBS_STATUS_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        dropdownKeyUpdateStatus: true,
      };

    case PBS_STATUS_DROPDOWN_UPDATE_FAIL:
      return { ...state };

    case PBS_STATUS_DROPDOWN_CREATE:
      return { ...state };

    case PBS_STATUS_DROPDOWN_CREATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case PBS_STATUS_DROPDOWN_CREATE_FAIL:
      return { ...state };

    case GET_PBS_ITEM_TYPE_DROPDOWN_BY_CODE:
      return { ...state };

    case GET_PBS_ITEM_TYPE_DROPDOWN_BY_CODE_SUCCESS:
      return {
        ...state,
        getDropdownKeyList: action.payload.data.result,
      };

    case GET_PBS_ITEM_TYPE_DROPDOWN_BY_CODE_FAIL:
      return { ...state };

    case PBS_ITEM_TYPE_DROPDOWN_UPDATE:
      return { ...state };

    case PBS_ITEM_TYPE_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case PBS_ITEM_TYPE_DROPDOWN_UPDATE_FAIL:
      return { ...state };

    case PBS_ITEM_TYPE_DROPDOWN_CREATE:
      return { ...state };

    case PBS_ITEM_TYPE_DROPDOWN_CREATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case PBS_ITEM_TYPE_DROPDOWN_CREATE_FAIL:
      return { ...state };
    //
    case GET_PBS_EXPERIENCE_DROPDOWN_BY_CODE:
      return { ...state };

    case GET_PBS_EXPERIENCE_DROPDOWN_BY_CODE_SUCCESS:
      return {
        ...state,
        getDropdownKeyList: action.payload.data.result,
      };

    case GET_PBS_EXPERIENCE_DROPDOWN_BY_CODE_FAIL:
      return { ...state };

    case PBS_EXPERIENCE_DROPDOWN_UPDATE:
      return { ...state };

    case PBS_EXPERIENCE_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case PBS_EXPERIENCE_DROPDOWN_UPDATE_FAIL:
      return { ...state };

    case PBS_EXPERIENCE_DROPDOWN_CREATE:
      return { ...state };

    case PBS_EXPERIENCE_DROPDOWN_CREATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case PBS_EXPERIENCE_DROPDOWN_CREATE_FAIL:
      return { ...state };
    //
    case GET_PBS_SKILLS_DROPDOWN_BY_CODE:
      return { ...state };

    case GET_PBS_SKILLS_DROPDOWN_BY_CODE_SUCCESS:
      return {
        ...state,
        getDropdownKeyList: action.payload.data.result,
      };

    case GET_PBS_SKILLS_DROPDOWN_BY_CODE_FAIL:
      return { ...state };

    case PBS_SKILLS_DROPDOWN_UPDATE:
      return { ...state };

    case PBS_SKILLS_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case PBS_SKILLS_DROPDOWN_UPDATE_FAIL:
      return { ...state };

    case PBS_SKILLS_DROPDOWN_CREATE:
      return { ...state };

    case PBS_SKILLS_DROPDOWN_CREATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case PBS_SKILLS_DROPDOWN_CREATE_FAIL:
      return { ...state };
    //
    case GET_PROJECT_CURRENCIES_DROPDOWN_BY_CODE:
      return { ...state };

    case GET_PROJECT_CURRENCIES_DROPDOWN_BY_CODE_SUCCESS:
      return {
        ...state,
        getDropdownKeyList: action.payload.data.result,
      };

    case GET_PROJECT_CURRENCIES_DROPDOWN_BY_CODE_FAIL:
      return { ...state };

    case PROJECT_CURRENCIES_DROPDOWN_UPDATE:
      return { ...state };

    case PROJECT_CURRENCIES_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case PROJECT_CURRENCIES_DROPDOWN_UPDATE_FAIL:
      return { ...state };

    case PROJECT_CURRENCIES_DROPDOWN_CREATE:
      return { ...state };

    case PROJECT_CURRENCIES_DROPDOWN_CREATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case PROJECT_CURRENCIES_DROPDOWN_CREATE_FAIL:
      return { ...state };
    //
    case GET_PROJECT_STATES_DROPDOWN_BY_CODE:
      return { ...state };

    case GET_PROJECT_STATES_DROPDOWN_BY_CODE_SUCCESS:
      return {
        ...state,
        getDropdownKeyList: action.payload.data.result,
      };

    case GET_PROJECT_STATES_DROPDOWN_BY_CODE_FAIL:
      return { ...state };

    case PROJECT_STATES_DROPDOWN_UPDATE:
      return { ...state };

    case PROJECT_STATES_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case PROJECT_STATES_DROPDOWN_UPDATE_FAIL:
      return { ...state };

    case PROJECT_STATES_DROPDOWN_CREATE:
      return { ...state };

    case PROJECT_STATES_DROPDOWN_CREATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case PROJECT_STATES_DROPDOWN_CREATE_FAIL:
      return { ...state };
    //
    case GET_PROJECT_TYPES_DROPDOWN_BY_CODE:
      return { ...state };

    case GET_PROJECT_TYPES_DROPDOWN_BY_CODE_SUCCESS:
      return {
        ...state,
        getDropdownKeyList: action.payload.data.result,
      };

    case GET_PROJECT_TYPES_DROPDOWN_BY_CODE_FAIL:
      return { ...state };

    case PROJECT_TYPES_DROPDOWN_UPDATE:
      return { ...state };

    case PROJECT_TYPES_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case PROJECT_TYPES_DROPDOWN_UPDATE_FAIL:
      return { ...state };

    case PROJECT_TYPES_DROPDOWN_CREATE:
      return { ...state };

    case PROJECT_TYPES_DROPDOWN_CREATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case PROJECT_TYPES_DROPDOWN_CREATE_FAIL:
      return { ...state };
    //
    case GET_PROJECT_TEMPLATES_DROPDOWN_BY_CODE:
      return { ...state };

    case GET_PROJECT_TEMPLATES_DROPDOWN_BY_CODE_SUCCESS:
      return {
        ...state,
        getDropdownKeyList: action.payload.data.result,
      };

    case GET_PROJECT_TEMPLATES_DROPDOWN_BY_CODE_FAIL:
      return { ...state };

    case PROJECT_TEMPLATES_DROPDOWN_UPDATE:
      return { ...state };

    case PROJECT_TEMPLATES_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case PROJECT_TEMPLATES_DROPDOWN_UPDATE_FAIL:
      return { ...state };

    case PROJECT_TEMPLATES_DROPDOWN_CREATE:
      return { ...state };

    case PROJECT_TEMPLATES_DROPDOWN_CREATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case PROJECT_TEMPLATES_DROPDOWN_CREATE_FAIL:
      return { ...state };
    //
    case GET_PROJECT_LEDGER_DROPDOWN_BY_CODE:
      return { ...state };

    case GET_PROJECT_LEDGER_DROPDOWN_BY_CODE_SUCCESS:
      return {
        ...state,
        getDropdownKeyList: action.payload.data.result,
      };

    case GET_PROJECT_LEDGER_DROPDOWN_BY_CODE_FAIL:
      return { ...state };

    case PROJECT_LEDGER_DROPDOWN_UPDATE:
      return { ...state };

    case PROJECT_LEDGER_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case PROJECT_LEDGER_DROPDOWN_UPDATE_FAIL:
      return { ...state };

    case PROJECT_LEDGER_DROPDOWN_CREATE:
      return { ...state };

    case PROJECT_LEDGER_DROPDOWN_CREATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case PROJECT_LEDGER_DROPDOWN_CREATE_FAIL:
      return { ...state };
    //
    case GET_PMOL_STATUS_DROPDOWN_BY_CODE:
      return { ...state };

    case GET_PMOL_STATUS_DROPDOWN_BY_CODE_SUCCESS:
      return {
        ...state,
        getDropdownKeyList: action.payload.data.result,
      };

    case GET_PMOL_STATUS_DROPDOWN_BY_CODE_FAIL:
      return { ...state };

    case PMOL_STATUS_DROPDOWN_UPDATE:
      return { ...state };

    case PMOL_STATUS_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case PMOL_STATUS_DROPDOWN_UPDATE_FAIL:
      return { ...state };

    case PMOL_STATUS_DROPDOWN_CREATE:
      return { ...state };

    case PMOL_STATUS_DROPDOWN_CREATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case PMOL_STATUS_DROPDOWN_CREATE_FAIL:
      return { ...state };
    //
    case GET_PMOL_TYPE_DROPDOWN_BY_CODE:
      return { ...state };

    case GET_PMOL_TYPE_DROPDOWN_BY_CODE_SUCCESS:
      return {
        ...state,
        getDropdownKeyList: action.payload.data.result,
      };

    case GET_PMOL_TYPE_DROPDOWN_BY_CODE_FAIL:
      return { ...state };

    case PMOL_TYPE_DROPDOWN_UPDATE:
      return { ...state };

    case PMOL_TYPE_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case PMOL_TYPE_DROPDOWN_UPDATE_FAIL:
      return { ...state };

    case PMOL_TYPE_DROPDOWN_CREATE:
      return { ...state };

    case PMOL_TYPE_DROPDOWN_CREATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case PMOL_TYPE_DROPDOWN_CREATE_FAIL:
      return { ...state };
    //
    case GET_BOR_STATUS_DROPDOWN_BY_CODE:
      return { ...state };

    case GET_BOR_STATUS_DROPDOWN_BY_CODE_SUCCESS:
      return {
        ...state,
        getDropdownKeyList: action.payload.data.result,
      };

    case GET_BOR_STATUS_DROPDOWN_BY_CODE_FAIL:
      return { ...state };

    case BOR_STATUS_DROPDOWN_UPDATE:
      return { ...state };

    case BOR_STATUS_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case BOR_STATUS_DROPDOWN_UPDATE_FAIL:
      return { ...state };

    case BOR_STATUS_DROPDOWN_CREATE:
      return { ...state };

    case BOR_STATUS_DROPDOWN_CREATE_SUCCESS:
      return {
        ...state,
        dropdownKeyCreateStatus: true,
      };

    case BOR_STATUS_DROPDOWN_CREATE_FAIL:
      return { ...state };
    case PROJECT_TEAM_ROLE_DROPDOWN_READ_SUCCESS:
      return { ...state, teamRoles: action.payload.data.result };
    //
    default:
      return { ...state };
  }
};

export const getCountryDistinct = () => {
  return {
    types: [
      GET_COUNTRY_DISTINCT,
      GET_COUNTRY_DISTINCT_SUCCESS,
      GET_COUNTRY_DISTINCT_FAIL,
    ],
    payload: {
      request: {
        url: GET_COUNTRY_DISTINCT_EP,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const getLanguageDistinct = () => {
  return {
    types: [
      GET_LANGUAGE_DISTINCT,
      GET_LANGUAGE_DISTINCT_SUCCESS,
      GET_LANGUAGE_DISTINCT_FAIL,
    ],
    payload: {
      request: {
        url: GET_LANGUAGES_DISTINCT_EP,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const getLanguageByCode = (code: string) => {
  return {
    types: [
      GET_LANGUAGE_BY_CODE,
      GET_LANGUAGE_BY_CODE_SUCCESS,
      GET_LANGUAGE_BY_CODE_FAIL,
    ],
    payload: {
      request: {
        url: GET_LANGUAGE_BY_CODE_EP + code,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const getDropdownKeyByCode = (code: string) => {
  return {
    types: [
      GET_DROPDOWN_KEYS_BY_CODE,
      GET_DROPDOWN_KEYS_BY_CODE_SUCCESS,
      GET_DROPDOWN_KEYS_BY_CODE_FAIL,
    ],
    payload: {
      request: {
        url: GET_DROPDOWN_KEYS_BY_CODE_EP + code,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const updateCpcMouDropdownKeys = (data: DropdownTranslation) => {
  return {
    types: [
      DROPDOWN_KEYS_UPDATE,
      DROPDOWN_KEYS_UPDATE_SUCCESS,
      DROPDOWN_KEYS_UPDATE_FAIL,
    ],
    payload: {
      request: {
        url: DROPDOWN_KEYS_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createCpcMouDropdownKeys = (data: CreateDropdownTranslation) => {
  return {
    types: [
      CPC_MOU_DROPDOWN_KEYS_CREATE,
      CPC_MOU_DROPDOWN_KEYS_CREATE_SUCCESS,
      CPC_MOU_DROPDOWN_KEYS_CREATE_FAIL,
    ],
    payload: {
      request: {
        url: CPC_MOU_DROPDOWN_KEYS_CREATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getDropdownLanguageCode = (language: string, country: string) => {
  return {
    types: [
      GET_DROPDOWN_LANGUAGE_CODE,
      GET_DROPDOWN_LANGUAGE_CODE_SUCCESS,
      GET_DROPDOWN_LANGUAGE_CODE_FAIL,
    ],
    payload: {
      request: {
        url: 'Language/GetCode?lan=' + language + '&country=' + country,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const getCpcResourceTypesByCode = (code: string) => {
  return {
    types: [
      GET_CPC_RESOURCE_TYPES_BY_CODE,
      GET_CPC_RESOURCE_TYPES_BY_CODE_SUCCESS,
      GET_CPC_RESOURCE_TYPES_BY_CODE_FAIL,
    ],
    payload: {
      request: {
        url: GET_CPC_RESOURCE_TYPES_BY_CODE_EP + code,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const updateCpcResourceType = (data: DropdownTranslation) => {
  return {
    types: [
      CPC_RESOURCE_TYPE_UPDATE,
      CPC_RESOURCE_TYPE_UPDATE_SUCCESS,
      CPC_RESOURCE_TYPE_UPDATE_FAIL,
    ],
    payload: {
      request: {
        url: CPC_RESOURCE_TYPE_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createCpcResourceType = (data: CreateDropdownTranslation) => {
  return {
    types: [
      CPC_RESOURCE_TYPE_CREATE,
      CPC_RESOURCE_TYPE_CREATE_SUCCESS,
      CPC_RESOURCE_TYPE_CREATE_FAIL,
    ],
    payload: {
      request: {
        url: CPC_RESOURCE_TYPE_CREATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getCpcMaterialDropdownByCode = (code: string) => {
  return {
    types: [
      GET_CPC_MATERIAL_BY_CODE,
      GET_CPC_MATERIAL_BY_CODE_SUCCESS,
      GET_CPC_MATERIAL_BY_CODE_FAIL,
    ],
    payload: {
      request: {
        url: GET_CPC_MATERIAL_BY_CODE_EP + code,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const updateCpcMaterialDropdown = (data: DropdownTranslation) => {
  return {
    types: [
      CPC_MATERIAL_UPDATE,
      CPC_MATERIAL_UPDATE_SUCCESS,
      CPC_MATERIAL_UPDATE_FAIL,
    ],
    payload: {
      request: {
        url: CPC_MATERIAL_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createCpcMaterialDropdown = (data: CreateDropdownTranslation) => {
  return {
    types: [
      CPC_MATERIAL_CREATE,
      CPC_MATERIAL_CREATE_SUCCESS,
      CPC_MATERIAL_CREATE_FAIL,
    ],
    payload: {
      request: {
        url: CPC_MATERIAL_CREATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getCpcResourceFamilyDropdownByCode = (code: string) => {
  return {
    types: [
      GET_CPC_RESOURCE_FAMILY_DROPDOWN_BY_CODE,
      GET_CPC_RESOURCE_FAMILY_DROPDOWN_BY_CODE_SUCCESS,
      GET_CPC_RESOURCE_FAMILY_DROPDOWN_BY_CODE_FAIL,
    ],
    payload: {
      request: {
        url: GET_CPC_RESOURCE_FAMILY_DROPDOWN_BY_CODE_EP + code,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const updateCpcResourceFamilyDropdown = (data: DropdownTranslation) => {
  return {
    types: [
      CPC_RESOURCE_FAMILY_DROPDOWN_UPDATE,
      CPC_RESOURCE_FAMILY_DROPDOWN_UPDATE_SUCCESS,
      CPC_RESOURCE_FAMILY_DROPDOWN_UPDATE_FAIL,
    ],
    payload: {
      request: {
        url: CPC_RESOURCE_FAMILY_DROPDOWN_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createCpcResourceFamilyDropdown = (
  data: CreateDropdownTranslation,
) => {
  return {
    types: [
      CPC_RESOURCE_FAMILY_DROPDOWN_CREATE,
      CPC_RESOURCE_FAMILY_DROPDOWN_CREATE_SUCCESS,
      CPC_RESOURCE_FAMILY_DROPDOWN_CREATE_FAIL,
    ],
    payload: {
      request: {
        url: CPC_RESOURCE_FAMILY_DROPDOWN_CREATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getCpcPressureDropdownByCode = (code: string) => {
  return {
    types: [
      GET_CPC_PRESSURE_DROPDOWN_BY_CODE,
      GET_CPC_PRESSURE_DROPDOWN_BY_CODE_SUCCESS,
      GET_CPC_PRESSURE_DROPDOWN_BY_CODE_FAIL,
    ],
    payload: {
      request: {
        url: GET_CPC_PRESSURE_DROPDOWN_BY_CODE_EP + code,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const updateCpcPressureDropdown = (data: DropdownTranslation) => {
  return {
    types: [
      CPC_PRESSURE_DROPDOWN_UPDATE,
      CPC_PRESSURE_DROPDOWN_UPDATE_SUCCESS,
      CPC_PRESSURE_DROPDOWN_UPDATE_FAIL,
    ],
    payload: {
      request: {
        url: CPC_PRESSURE_DROPDOWN_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createCpcPressureDropdown = (data: CreateDropdownTranslation) => {
  return {
    types: [
      CPC_PRESSURE_DROPDOWN_CREATE,
      CPC_PRESSURE_DROPDOWN_CREATE_SUCCESS,
      CPC_PRESSURE_DROPDOWN_CREATE_FAIL,
    ],
    payload: {
      request: {
        url: CPC_PRESSURE_DROPDOWN_CREATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getCpcUnitOfSizeMeasureDropdownByCode = (code: string) => {
  return {
    types: [
      GET_CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_BY_CODE,
      GET_CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_BY_CODE_SUCCESS,
      GET_CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_BY_CODE_FAIL,
    ],
    payload: {
      request: {
        url: GET_CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_BY_CODE_EP + code,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const updateCpcUnitOfSizeMeasureDropdown = (
  data: DropdownTranslation,
) => {
  return {
    types: [
      CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_UPDATE,
      CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_UPDATE_SUCCESS,
      CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_UPDATE_FAIL,
    ],
    payload: {
      request: {
        url: CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createCpcUnitOfSizeMeasureDropdown = (
  data: CreateDropdownTranslation,
) => {
  return {
    types: [
      CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_CREATE,
      CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_CREATE_SUCCESS,
      CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_CREATE_FAIL,
    ],
    payload: {
      request: {
        url: CPC_UNIT_OF_SIZE_MEASURE_DROPDOWN_CREATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getCpcBrandDropdownByCode = (code: string) => {
  return {
    types: [
      GET_CPC_BRAND_DROPDOWN_BY_CODE,
      GET_CPC_BRAND_DROPDOWN_BY_CODE_SUCCESS,
      GET_CPC_BRAND_DROPDOWN_BY_CODE_FAIL,
    ],
    payload: {
      request: {
        url: GET_CPC_BRAND_DROPDOWN_BY_CODE_EP + code,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const updateCpcBrandDropdown = (data: DropdownTranslation) => {
  return {
    types: [
      CPC_BRAND_DROPDOWN_UPDATE,
      CPC_BRAND_DROPDOWN_UPDATE_SUCCESS,
      CPC_BRAND_DROPDOWN_UPDATE_FAIL,
    ],
    payload: {
      request: {
        url: CPC_BRAND_DROPDOWN_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createCpcBrandDropdown = (data: CreateDropdownTranslation) => {
  return {
    types: [
      CPC_BRAND_DROPDOWN_CREATE,
      CPC_BRAND_DROPDOWN_CREATE_SUCCESS,
      CPC_BRAND_DROPDOWN_CREATE_FAIL,
    ],
    payload: {
      request: {
        url: CPC_BRAND_DROPDOWN_CREATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getPbsToleranceDropdownByCode = (code: string) => {
  return {
    types: [
      GET_PBS_TOLERANCE_DROPDOWN_BY_CODE,
      GET_PBS_TOLERANCE_DROPDOWN_BY_CODE_SUCCESS,
      GET_PBS_TOLERANCE_DROPDOWN_BY_CODE_FAIL,
    ],
    payload: {
      request: {
        url: GET_PBS_TOLERANCE_DROPDOWN_BY_CODE_EP + code,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const updatePbsToleranceDropdown = (data: DropdownTranslation) => {
  return {
    types: [
      PBS_TOLERANCE_DROPDOWN_UPDATE,
      PBS_TOLERANCE_DROPDOWN_UPDATE_SUCCESS,
      PBS_TOLERANCE_DROPDOWN_UPDATE_FAIL,
    ],
    payload: {
      request: {
        url: PBS_TOLERANCE_DROPDOWN_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createPbsToleranceDropdown = (data: CreateDropdownTranslation) => {
  return {
    types: [
      PBS_TOLERANCE_DROPDOWN_CREATE,
      PBS_TOLERANCE_DROPDOWN_CREATE_SUCCESS,
      PBS_TOLERANCE_DROPDOWN_CREATE_FAIL,
    ],
    payload: {
      request: {
        url: PBS_TOLERANCE_DROPDOWN_CREATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getPbsStatusDropdownByCode = (code: string) => {
  return {
    types: [
      GET_PBS_STATUS_DROPDOWN_BY_CODE,
      GET_PBS_STATUS_DROPDOWN_BY_CODE_SUCCESS,
      GET_PBS_STATUS_DROPDOWN_BY_CODE_FAIL,
    ],
    payload: {
      request: {
        url: GET_PBS_STATUS_DROPDOWN_BY_CODE_EP + code,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const updatePbsStatusDropdown = (data: DropdownTranslation) => {
  return {
    types: [
      PBS_STATUS_DROPDOWN_UPDATE,
      PBS_STATUS_DROPDOWN_UPDATE_SUCCESS,
      PBS_STATUS_DROPDOWN_UPDATE_FAIL,
    ],
    payload: {
      request: {
        url: PBS_STATUS_DROPDOWN_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createPbsStatusDropdown = (data: CreateDropdownTranslation) => {
  return {
    types: [
      PBS_STATUS_DROPDOWN_CREATE,
      PBS_STATUS_DROPDOWN_CREATE_SUCCESS,
      PBS_STATUS_DROPDOWN_CREATE_FAIL,
    ],
    payload: {
      request: {
        url: PBS_STATUS_DROPDOWN_CREATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getPbsItemTypeDropdownByCode = (code: string) => {
  return {
    types: [
      GET_PBS_ITEM_TYPE_DROPDOWN_BY_CODE,
      GET_PBS_ITEM_TYPE_DROPDOWN_BY_CODE_SUCCESS,
      GET_PBS_ITEM_TYPE_DROPDOWN_BY_CODE_FAIL,
    ],
    payload: {
      request: {
        url: GET_PBS_ITEM_TYPE_DROPDOWN_BY_CODE_EP + code,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const updatePbsItemTypeDropdown = (data: DropdownTranslation) => {
  return {
    types: [
      PBS_ITEM_TYPE_DROPDOWN_UPDATE,
      PBS_ITEM_TYPE_DROPDOWN_UPDATE_SUCCESS,
      PBS_ITEM_TYPE_DROPDOWN_UPDATE_FAIL,
    ],
    payload: {
      request: {
        url: PBS_ITEM_TYPE_DROPDOWN_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createPbsItemTypeDropdown = (data: CreateDropdownTranslation) => {
  return {
    types: [
      PBS_ITEM_TYPE_DROPDOWN_CREATE,
      PBS_ITEM_TYPE_DROPDOWN_CREATE_SUCCESS,
      PBS_ITEM_TYPE_DROPDOWN_CREATE_FAIL,
    ],
    payload: {
      request: {
        url: PBS_ITEM_TYPE_DROPDOWN_CREATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getPbsExperienceDropdownByCode = (code: string) => {
  return {
    types: [
      GET_PBS_EXPERIENCE_DROPDOWN_BY_CODE,
      GET_PBS_EXPERIENCE_DROPDOWN_BY_CODE_SUCCESS,
      GET_PBS_EXPERIENCE_DROPDOWN_BY_CODE_FAIL,
    ],
    payload: {
      request: {
        url: GET_PBS_EXPERIENCE_DROPDOWN_BY_CODE_EP + code,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const updatePbsExperienceDropdown = (data: DropdownTranslation) => {
  return {
    types: [
      PBS_EXPERIENCE_DROPDOWN_UPDATE,
      PBS_EXPERIENCE_DROPDOWN_UPDATE_SUCCESS,
      PBS_EXPERIENCE_DROPDOWN_UPDATE_FAIL,
    ],
    payload: {
      request: {
        url: PBS_EXPERIENCE_DROPDOWN_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createPbsExperienceDropdown = (
  data: CreateDropdownTranslation,
) => {
  return {
    types: [
      PBS_EXPERIENCE_DROPDOWN_CREATE,
      PBS_EXPERIENCE_DROPDOWN_CREATE_SUCCESS,
      PBS_EXPERIENCE_DROPDOWN_CREATE_FAIL,
    ],
    payload: {
      request: {
        url: PBS_EXPERIENCE_DROPDOWN_CREATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getPbsSkillsDropdownByCode = (code: string) => {
  return {
    types: [
      GET_PBS_SKILLS_DROPDOWN_BY_CODE,
      GET_PBS_SKILLS_DROPDOWN_BY_CODE_SUCCESS,
      GET_PBS_SKILLS_DROPDOWN_BY_CODE_FAIL,
    ],
    payload: {
      request: {
        url: GET_PBS_SKILLS_DROPDOWN_BY_CODE_EP + code,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const updatePbsSkillsDropdown = (data: DropdownTranslation) => {
  return {
    types: [
      PBS_SKILLS_DROPDOWN_UPDATE,
      PBS_SKILLS_DROPDOWN_UPDATE_SUCCESS,
      PBS_SKILLS_DROPDOWN_UPDATE_FAIL,
    ],
    payload: {
      request: {
        url: PBS_SKILLS_DROPDOWN_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createPbsSkillsDropdown = (data: CreateDropdownTranslation) => {
  return {
    types: [
      PBS_SKILLS_DROPDOWN_CREATE,
      PBS_SKILLS_DROPDOWN_CREATE_SUCCESS,
      PBS_SKILLS_DROPDOWN_CREATE_FAIL,
    ],
    payload: {
      request: {
        url: PBS_SKILLS_DROPDOWN_CREATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getProjectManagementLevelsDropdownByCode = (code: string) => {
  return {
    types: [
      GET_PROJECT_MANAGEMENT_LEVELS_DROPDOWN_BY_CODE,
      GET_PROJECT_MANAGEMENT_LEVELS_DROPDOWN_BY_CODE_SUCCESS,
      GET_PROJECT_MANAGEMENT_LEVELS_DROPDOWN_BY_CODE_FAIL,
    ],
    payload: {
      request: {
        url: GET_PROJECT_MANAGEMENT_LEVELS_DROPDOWN_BY_CODE_EP + code,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const updateProjectManagementLevelsDropdown = (
  data: DropdownTranslation,
) => {
  return {
    types: [
      PROJECT_MANAGEMENT_LEVELS_DROPDOWN_UPDATE,
      PROJECT_MANAGEMENT_LEVELS_DROPDOWN_UPDATE_SUCCESS,
      PROJECT_MANAGEMENT_LEVELS_DROPDOWN_UPDATE_FAIL,
    ],
    payload: {
      request: {
        url: PROJECT_MANAGEMENT_LEVELS_DROPDOWN_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createProjectManagementLevelsDropdown = (
  data: CreateDropdownTranslation,
) => {
  return {
    types: [
      PROJECT_MANAGEMENT_LEVELS_DROPDOWN_CREATE,
      PROJECT_MANAGEMENT_LEVELS_DROPDOWN_CREATE_SUCCESS,
      PROJECT_MANAGEMENT_LEVELS_DROPDOWN_CREATE_FAIL,
    ],
    payload: {
      request: {
        url: PROJECT_MANAGEMENT_LEVELS_DROPDOWN_CREATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getProjectCurrenciesDropdownByCode = (code: string) => {
  return {
    types: [
      GET_PROJECT_CURRENCIES_DROPDOWN_BY_CODE,
      GET_PROJECT_CURRENCIES_DROPDOWN_BY_CODE_SUCCESS,
      GET_PROJECT_CURRENCIES_DROPDOWN_BY_CODE_FAIL,
    ],
    payload: {
      request: {
        url: GET_PROJECT_CURRENCIES_DROPDOWN_BY_CODE_EP + code,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const updateProjectCurrenciesDropdown = (data: DropdownTranslation) => {
  return {
    types: [
      PROJECT_CURRENCIES_DROPDOWN_UPDATE,
      PROJECT_CURRENCIES_DROPDOWN_UPDATE_SUCCESS,
      PROJECT_CURRENCIES_DROPDOWN_UPDATE_FAIL,
    ],
    payload: {
      request: {
        url: PROJECT_CURRENCIES_DROPDOWN_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createProjectCurrenciesDropdown = (
  data: CreateDropdownTranslation,
) => {
  return {
    types: [
      PROJECT_CURRENCIES_DROPDOWN_CREATE,
      PROJECT_CURRENCIES_DROPDOWN_CREATE_SUCCESS,
      PROJECT_CURRENCIES_DROPDOWN_CREATE_FAIL,
    ],
    payload: {
      request: {
        url: PROJECT_CURRENCIES_DROPDOWN_CREATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getProjectStatesDropdownByCode = (code: string) => {
  return {
    types: [
      GET_PROJECT_STATES_DROPDOWN_BY_CODE,
      GET_PROJECT_STATES_DROPDOWN_BY_CODE_SUCCESS,
      GET_PROJECT_STATES_DROPDOWN_BY_CODE_FAIL,
    ],
    payload: {
      request: {
        url: GET_PROJECT_STATES_DROPDOWN_BY_CODE_EP + code,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const updateProjectStatesDropdown = (data: DropdownTranslation) => {
  return {
    types: [
      PROJECT_STATES_DROPDOWN_UPDATE,
      PROJECT_STATES_DROPDOWN_UPDATE_SUCCESS,
      PROJECT_STATES_DROPDOWN_UPDATE_FAIL,
    ],
    payload: {
      request: {
        url: PROJECT_STATES_DROPDOWN_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createProjectStatesDropdown = (
  data: CreateDropdownTranslation,
) => {
  return {
    types: [
      PROJECT_STATES_DROPDOWN_CREATE,
      PROJECT_STATES_DROPDOWN_CREATE_SUCCESS,
      PROJECT_STATES_DROPDOWN_CREATE_FAIL,
    ],
    payload: {
      request: {
        url: PROJECT_STATES_DROPDOWN_CREATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getProjectTypesDropdownByCode = (code: string) => {
  return {
    types: [
      GET_PROJECT_TYPES_DROPDOWN_BY_CODE,
      GET_PROJECT_TYPES_DROPDOWN_BY_CODE_SUCCESS,
      GET_PROJECT_TYPES_DROPDOWN_BY_CODE_FAIL,
    ],
    payload: {
      request: {
        url: GET_PROJECT_TYPES_DROPDOWN_BY_CODE_EP + code,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const updateProjectTypesDropdown = (data: DropdownTranslation) => {
  return {
    types: [
      PROJECT_TYPES_DROPDOWN_UPDATE,
      PROJECT_TYPES_DROPDOWN_UPDATE_SUCCESS,
      PROJECT_TYPES_DROPDOWN_UPDATE_FAIL,
    ],
    payload: {
      request: {
        url: PROJECT_TYPES_DROPDOWN_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createProjectTypesDropdown = (data: CreateDropdownTranslation) => {
  return {
    types: [
      PROJECT_TYPES_DROPDOWN_CREATE,
      PROJECT_TYPES_DROPDOWN_CREATE_SUCCESS,
      PROJECT_TYPES_DROPDOWN_CREATE_FAIL,
    ],
    payload: {
      request: {
        url: PROJECT_TYPES_DROPDOWN_CREATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getProjectTemplatesDropdownByCode = (code: string) => {
  return {
    types: [
      GET_PROJECT_TEMPLATES_DROPDOWN_BY_CODE,
      GET_PROJECT_TEMPLATES_DROPDOWN_BY_CODE_SUCCESS,
      GET_PROJECT_TEMPLATES_DROPDOWN_BY_CODE_FAIL,
    ],
    payload: {
      request: {
        url: GET_PROJECT_TEMPLATES_DROPDOWN_BY_CODE_EP + code,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const updateProjectTemplatesDropdown = (data: DropdownTranslation) => {
  return {
    types: [
      PROJECT_TEMPLATES_DROPDOWN_UPDATE,
      PROJECT_TEMPLATES_DROPDOWN_UPDATE_SUCCESS,
      PROJECT_TEMPLATES_DROPDOWN_UPDATE_FAIL,
    ],
    payload: {
      request: {
        url: PROJECT_TEMPLATES_DROPDOWN_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createProjectTemplatesDropdown = (
  data: CreateDropdownTranslation,
) => {
  return {
    types: [
      PROJECT_TEMPLATES_DROPDOWN_CREATE,
      PROJECT_TEMPLATES_DROPDOWN_CREATE_SUCCESS,
      PROJECT_TEMPLATES_DROPDOWN_CREATE_FAIL,
    ],
    payload: {
      request: {
        url: PROJECT_TEMPLATES_DROPDOWN_CREATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getProjectLedgerDropdownByCode = (code: string) => {
  return {
    types: [
      GET_PROJECT_LEDGER_DROPDOWN_BY_CODE,
      GET_PROJECT_LEDGER_DROPDOWN_BY_CODE_SUCCESS,
      GET_PROJECT_LEDGER_DROPDOWN_BY_CODE_FAIL,
    ],
    payload: {
      request: {
        url: GET_PROJECT_LEDGER_DROPDOWN_BY_CODE_EP + code,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const updateProjectLedgerDropdown = (data: DropdownTranslation) => {
  return {
    types: [
      PROJECT_LEDGER_DROPDOWN_UPDATE,
      PROJECT_LEDGER_DROPDOWN_UPDATE_SUCCESS,
      PROJECT_LEDGER_DROPDOWN_UPDATE_FAIL,
    ],
    payload: {
      request: {
        url: PROJECT_LEDGER_DROPDOWN_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createProjectLedgerDropdown = (
  data: CreateDropdownTranslation,
) => {
  return {
    types: [
      PROJECT_LEDGER_DROPDOWN_CREATE,
      PROJECT_LEDGER_DROPDOWN_CREATE_SUCCESS,
      PROJECT_LEDGER_DROPDOWN_CREATE_FAIL,
    ],
    payload: {
      request: {
        url: PROJECT_LEDGER_DROPDOWN_CREATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getPmolStatusDropdownByCode = (code: string) => {
  return {
    types: [
      GET_PMOL_STATUS_DROPDOWN_BY_CODE,
      GET_PMOL_STATUS_DROPDOWN_BY_CODE_SUCCESS,
      GET_PMOL_STATUS_DROPDOWN_BY_CODE_FAIL,
    ],
    payload: {
      request: {
        url: GET_PMOL_STATUS_DROPDOWN_BY_CODE_EP + code,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const updatePmolStatusDropdown = (data: DropdownTranslation) => {
  return {
    types: [
      PMOL_STATUS_DROPDOWN_UPDATE,
      PMOL_STATUS_DROPDOWN_UPDATE_SUCCESS,
      PMOL_STATUS_DROPDOWN_UPDATE_FAIL,
    ],
    payload: {
      request: {
        url: PMOL_STATUS_DROPDOWN_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createPmolStatusDropdown = (data: CreateDropdownTranslation) => {
  return {
    types: [
      PMOL_STATUS_DROPDOWN_CREATE,
      PMOL_STATUS_DROPDOWN_CREATE_SUCCESS,
      PMOL_STATUS_DROPDOWN_CREATE_FAIL,
    ],
    payload: {
      request: {
        url: PMOL_STATUS_DROPDOWN_CREATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getPmolTypeDropdownByCode = (code: string) => {
  return {
    types: [
      GET_PMOL_TYPE_DROPDOWN_BY_CODE,
      GET_PMOL_TYPE_DROPDOWN_BY_CODE_SUCCESS,
      GET_PMOL_TYPE_DROPDOWN_BY_CODE_FAIL,
    ],
    payload: {
      request: {
        url: GET_PMOL_TYPE_DROPDOWN_BY_CODE_EP + code,
        method: 'GET',
        // data: data,
      },
    },
  };
};

export const updatePmolTypeDropdown = (data: DropdownTranslation) => {
  return {
    types: [
      PMOL_TYPE_DROPDOWN_UPDATE,
      PMOL_TYPE_DROPDOWN_UPDATE_SUCCESS,
      PMOL_TYPE_DROPDOWN_UPDATE_FAIL,
    ],
    payload: {
      request: {
        url: PMOL_TYPE_DROPDOWN_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createPmolTypeDropdown = (data: CreateDropdownTranslation) => {
  return {
    types: [
      PMOL_TYPE_DROPDOWN_CREATE,
      PMOL_TYPE_DROPDOWN_CREATE_SUCCESS,
      PMOL_TYPE_DROPDOWN_CREATE_FAIL,
    ],
    payload: {
      request: {
        url: PMOL_TYPE_DROPDOWN_CREATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getBorStatusDropdownByCode = (code: string) => {
  return {
    types: [
      GET_BOR_STATUS_DROPDOWN_BY_CODE,
      GET_BOR_STATUS_DROPDOWN_BY_CODE_SUCCESS,
      GET_BOR_STATUS_DROPDOWN_BY_CODE_FAIL,
    ],
    payload: {
      request: {
        url: GET_BOR_STATUS_DROPDOWN_BY_CODE_EP + code,
        method: 'GET',
        // data: data,
      },
    },
  };
};

// export const getUserRolesDropDownByCode = (code:string) => {
//   return {
//       types: [ LOAD_USER_ROLES, LOAD_USER_ROLES_SUCCESS, LOAD_USER_ROLES_FAIL ],
//       payload: {
//           request: {
//               url: PROJECT_ROLE_EP,
//               method: 'GET',
//               data: data
//           }
//       }
//   };
// };

export const getTeamRoalsDropDownByCode = (code: string) => {
  return {
    types: [
      PROJECT_TEAM_ROLE_DROPDOWN_READ,
      PROJECT_TEAM_ROLE_DROPDOWN_READ_SUCCESS,
      PROJECT_TEAM_ROLE_DROPDOWN_READ_FAIL,
    ],
    payload: {
      request: {
        url: PROJECT_ROLE_DROPDOWN_BY_CODE_EP + code,
        method: 'GET',
      },
    },
  };
};

// This method is common for all dropdown reads
export const getDropDownTranslationByCode = (url: string, code: string) => {
  return {
    types: [
      DROPDOWN_TRANSLATION_READ,
      DROPDOWN_TRANSLATION_READ_SUCCESS,
      DROPDOWN_TRANSLATION_READ_FAIL,
    ],
    payload: {
      request: {
        url: url + code,
        method: 'GET',
      },
    },
  };
};

// this method is common for all trnaslation creates
export const createDropdownTranslation = (data: CreateDropdownTranslation, url: string) => {
  return {
    types: [
      DROPDOWN_TRANSLATION_CREATE,
      DROPDOWN_TRANSLATION_CREATE_SUCCESS,
      DROPDOWN_TRANSLATION_CREATE_FAIL,
    ],
    payload: {
      request: {
        url: url,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const deleteDropdownTranslation = (data: DropdownTranslation, url: string) => {
  return {
    types: [
      DROPDOWN_TRANSLATION_DELETE,
      DROPDOWN_TRANSLATION_DELETE_SUCCESS,
      DROPDOWN_TRANSLATION_DELETE_FAIL,
    ],
    payload: {
      request: {
        url: url + data?.id,
        method: 'DELETE',
      },
    },
  };
};

export const updateBorStatusDropdown = (data: DropdownTranslation) => {
  return {
    types: [
      BOR_STATUS_DROPDOWN_UPDATE,
      BOR_STATUS_DROPDOWN_UPDATE_SUCCESS,
      BOR_STATUS_DROPDOWN_UPDATE_FAIL,
    ],
    payload: {
      request: {
        url: BOR_STATUS_DROPDOWN_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createBorStatusDropdown = (data: CreateDropdownTranslation) => {
  return {
    types: [
      BOR_STATUS_DROPDOWN_CREATE,
      BOR_STATUS_DROPDOWN_CREATE_SUCCESS,
      BOR_STATUS_DROPDOWN_CREATE_FAIL,
    ],
    payload: {
      request: {
        url: BOR_STATUS_DROPDOWN_CREATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};


const formattedPayloadToCountryDropdown = (response: any) => {
  if (response) {
    let data = response.map((item: any) => {
      return {
        key: item.country,
        text: item.country,
      };
    });
    return data;
  } else {
    return [];
  }
};

const formattedPayloadToLanguageDropdown = (response: any) => {
  if (response) {
    let data = response.map((item: any) => {
      return {
        key: item.lang,
        text: item.lang,
      };
    });
    return data;
  } else {
    return [];
  }
};

export default dropdownTranslationReducer;

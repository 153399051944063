import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ViewMode } from '../../../shared/ganttv2';
import { RESOURCE_TYPE_IDS } from '../../../shared/util';
import ModeSwitcher from '../../../shared/ganttViewSwitcher/viewSwitcher';
import { Toggle } from '@fluentui/react';
import { useTableExpand } from '../../../hooks/useTableExpandContext';


type ViewSwitcherProps = {
  isChecked: boolean;
  onViewListChange: (isChecked: boolean) => void;
  onViewModeChange: (viewMode: ViewMode) => void;
  renderFilterComponent: any;
  option: any;
};
export const ViewSwitcher: React.FC<ViewSwitcherProps> = ({
                                                            isChecked,
                                                            onViewListChange,
                                                            onViewModeChange,
                                                            renderFilterComponent,
                                                            option,
                                                          }) => {
  const [selectedKeys, setSelectedKeys] = React.useState<any[]>([]);
  const [selectedMode, setSelectedViewMode] = React.useState<ViewMode>(
    ViewMode.Day,
  );
  const { t } = useTranslation();
  const cpcDropDowns: any = useSelector(
    (state: any) => state.productCatalogue.resourceTypes,
  );

  const onSubmit = async (values: any) => {};

  const { isAllExpanded, setIsAllExpanded } = useTableExpand();

  useEffect(() => {
    setSelectedKeys([getResourceTypeOptions()[0]]);
  }, []);
  t('department');
  t('jobRole');

  const getResourceTypeOptions = () => {
    return cpcDropDowns.filter((el: any) => {
      return (
        el.key === RESOURCE_TYPE_IDS.LABOURS ||
        el.key === RESOURCE_TYPE_IDS.TOOLS
      );
    });
  };


  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        height: 70,
        width: '100%',
        position: 'sticky',
        left: 0,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          flexGrow: 0.2,
        }}
      >
        <Form
          onSubmit={onSubmit}
          initialValues={{}}
          mutators={{
            setHeaderDocs: (args, state, utils) => {
              const field = state.fields['header.files'];
              field.change(args[0]);
            },
          }}
          // validate={ validate }
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            // props.submit(handleSubmit);
            return (
              <form onSubmit={handleSubmit} noValidate>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    height: 70,
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                      height: 70,
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        height: 70,
                        width: '100%',
                      }}
                    >

                      <ModeSwitcher
                        viewSwitcherOptions={option}
                        onViewModeChange={(t: any) => {
                          onViewModeChange(t);
                          setSelectedViewMode(t);
                        }}
                        onViewListChange={onViewListChange}
                        isCheck={isChecked}
                        selectedViewMode={selectedMode}
                        expandToggleEnabled={false}
                      />

                      <div style={{ marginTop: 34, marginLeft: 20 }}>
                        <Toggle
                          onText={t('collapse')}
                          offText={t('expand')}
                          onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                            setIsAllExpanded(!isAllExpanded);
                          }}
                        />
                      </div>

                    </div>
                  </div>
                </div>

                {/* <pre>{ JSON.stringify(values, 0, 2) }</pre> */}
              </form>
            );
          }}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          flexGrow: 0.6,
        }}
      >
        <div className='ViewContainer'></div>
      </div>
    </div>
  );
};

import React, { Component } from 'react';
import '../style/style.css';
import {
  Stack,
  Panel,
  PanelType,
  getTheme,
  ITheme,
  AnimationStyles,
  mergeStyles,
} from '@fluentui/react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import QRCodeSideBar from './qrSideBar';
import QRCommandBar from './qrCommandBar';
import QRCodeList from './qrCodeList';
import QRCodeDetailsContainer from './qrDetails/detailRoute';
import history from '../../history';
import { withTranslation, WithTranslation } from 'react-i18next';
import NewCommandBar from './newCommandBar';
import { getContractingUnit } from '../../shared/util';
import ProgressBar from '../../shared/progressBar/progressBar';
import { messageService } from '../../services/messageService';
import TitlePane from '../../shared/titlePane/titlePane';

export interface State {
  redirect: boolean;
  loading: boolean;
  showPanel: boolean;
  width: number;
  activeClass: string;
  headerFilter: boolean;
  selectedQRCode: any;
  selectedQRCodeType: number;
  codeCount: number;
  needRefresh: boolean;
  qrDetailsEnterd: boolean;
  showDetails: boolean;
  showProgress:boolean
}

export interface ProjectDetailsControl {
  showProjectDetails: boolean;
  projectManagementLevel: any;
}

interface Props extends WithTranslation {}
class QRCodeMainLayout extends React.Component<Props, State> {
  _isMounted = false;
  theme: ITheme = getTheme();
  subscription: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      redirect: false,
      loading: false,
      width: window.innerWidth,
      showPanel: false,
      headerFilter: false,
      activeClass: 'grid-righter column-filter-activated',
      selectedQRCode: null,
      selectedQRCodeType: -1,
      codeCount: 0,
      needRefresh: false,
      qrDetailsEnterd: false,
      showDetails: false,
      showProgress:false,
    };

    this.updateDimensions = this.updateDimensions.bind(this);
    this._handleClick = this._handleClick.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('load', this.updateDimensions);
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data && data.data && data.data.isDeleteQR) {
          this.setState({showProgress:true, needRefresh: false})
        } else if (data && data.data && data.data.isDeleteQR === false) {
          this.setState({showProgress:false, needRefresh: true})
        }

        if (data.data.hideDocumentPane) {
          this.setState({showDetails:false})
         }
         
      }
    });
  }

  updateDimensions() {
    if (this._isMounted) {
      this.setState({
        width: window.innerWidth,
      });
      if (this.state.width > 1100) {
        this.setState({ showPanel: false });
      } else if (this.state.showDetails) {
        this.setState({ showPanel: true });
      }
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.updateDimensions);
    this.subscription.unsubscribe();
  }

  private onChangeQRInputs = (value: boolean) => {
    this.setState({ qrDetailsEnterd: value });
  };

  private onQRCodeClick = (item: any) => {

    if (this.state.width <= 1100) {
      this.setState({
        selectedQRCode: item,
        showPanel: true,
        showDetails: false,
      });
    } else {
      this.setState({
        selectedQRCode: item,
        showDetails: true,
        showPanel: false,
      });
    }
  };

  private onFilterQRCodeType = (type: any) => {
    let pathname="";
    if(getContractingUnit()){
      pathname=`/CU/${getContractingUnit()}/qr-code`;
    } else {
      pathname="/qr-code";
    }
    if (this._isMounted) {
      history.push({
        pathname: pathname,
        state: { refresh: true },
      });

      this.setState({
        selectedQRCodeType: type,
        qrDetailsEnterd: false,
        showDetails: false,
      });
    }
  };

  getHeaderTitle = () => {
    let title;
    if (this.state?.selectedQRCodeType !== null) {
      if (this.state?.selectedQRCodeType === -1) {
        title = this.props.t('all');
      } else if (this.state?.selectedQRCodeType === 4) {
        title = this.props.t('start');
      } else if (this.state?.selectedQRCodeType === 0) {
        title = this.props.t('travel');
      } else if (this.state?.selectedQRCodeType === 1) {
        title = this.props.t('work');
      } else if (this.state?.selectedQRCodeType === 6) {
        title = this.props.t('break');
      } else if (this.state?.selectedQRCodeType === 2) {
        title = this.props.t('unload');
      } else if (this.state?.selectedQRCodeType === 5) {
        title = this.props.t('stop');
      }
    } else {
      title = this.props.t('all');
    }

    return title;
  };

  private onQRCodeGenerated = (type: any) => {
    this.setState({ selectedQRCodeType: type, needRefresh: !this.state.needRefresh });
  };

  private setCodeCount = (count: number) => {
    this.setState({ codeCount: count, needRefresh: false });
  };

  private getDocumentPaneView = () => {
    return (
      <div>
        <Stack
          className="project-list-header right-panel"

          style={{ backgroundColor: 'rgb(255, 255, 255)' }}
          styles={{ root: { width: '100%' } }}
        >
          <TitlePane
                        close={true}
                        headerTitle={
                         this.props.t('qRCode')
                        }
                        
                      />
          <QRCommandBar selectedQRCode={this.state.selectedQRCode} />
          <ProgressBar show={this.state.showProgress}/>
        </Stack>

        {/* <Stack className="project-detail-list inner-container withoutTitlePane" */}
        <Stack className="project-detail-list inner-container"
        styles={{
          root: {
            width: '100%',
            height: '78vh!important',
            minHeight: '78vh!important',
          },
        }}
        >
          <QRCodeDetailsContainer
            onQRCodeClick={this.onQRCodeClick}
            selectedQRCode={this.state.selectedQRCode}
            onQRCodeGenerated={this.onQRCodeGenerated}
            onChangeQRInputs={this.onChangeQRInputs}
          />
        </Stack>
      </div>
    );
  };

  render() {
    const { t } = this.props;
    const animation = mergeStyles(
      this.state.showDetails
        ? AnimationStyles.slideLeftIn400
        : AnimationStyles.slideRightIn400
    )
    return (
      <div>
        <div className={'ms-Grid'} dir="ltr">
          <div className={'ms-Grid'} style={{ width: '100%', height: '100%' }}>
            <div className="ms-Grid-row">
              <div
                className={
                  this.state.showDetails
                    ? `ms-Grid-col ms-sm6 ms-md8 ms-lg6 padding-0 `
                    : `ms-Grid-col ms-sm12 ms-md12 ms-lg12 padding-0 `
                }
              >
                <Stack horizontal className='qrListPane'>
                  <QRCodeSideBar onFilterQRCodeType={this.onFilterQRCodeType} />
                  <div className={this.state.activeClass}>
                    <Stack
                      className="project-list-header right-panel"
                      style={{
                        backgroundColor: 'rgb(255, 255, 255)',
                        paddingRight: 0,
                      }}
                      styles={{ root: { width: '100%', paddingRight: 0 } }}
                    >
                     <TitlePane
                        
                        headerTitle={
                          this.getHeaderTitle()
                        }
                        isOldItem={true}
                        
                      />
                      <NewCommandBar
                        onClickNewButton={() => {
                          let pathname="";
                          if(getContractingUnit()){
                            pathname=`/CU/${getContractingUnit()}/qr-code`;
                          } else {
                            pathname="/qr-code";
                          }
                          this.setState({ showDetails: true });
                          history.push({
                            pathname: pathname,
                            state: { refresh: true },
                          });
                        }}
                      />
                    </Stack>

                    <Stack
                      style={{ alignSelf: 'center' }}

                    >
                      <QRCodeList
                        onQRCodeClick={this.onQRCodeClick}
                        selectedQRCodeType={this.state.selectedQRCodeType}
                        setCodeCount={this.setCodeCount}
                        needRefresh={this.state.needRefresh}
                        qrDetailsEnterd={this.state.qrDetailsEnterd}
                        onChangeQRInputs={this.onChangeQRInputs}
                      />
                    </Stack>
                  </div>
                </Stack>
              </div>

              <div
                className={`ms-Grid-col ms-sm12 ms-md6 padding-left-0 ${animation}`}
                style={{
                  display: this.state.showDetails ? 'block' : 'none',
                  transition: 'all 0.9s linear'
                }}
              >
                <div className="card-container">{this.getDocumentPaneView()}</div>
              </div>
            </div>
          </div>
        </div>
        <Panel
          isOpen={this.state.showPanel}
          type={PanelType.smallFixedFar}
          onDismiss={this._hidePanel}
          isLightDismiss={true}
          headerText={t('qRCode')}
          className="custom-detail-panel"
          closeButtonAriaLabel={t('close')}
        >
          {this.getDocumentPaneView()}
        </Panel>
      </div>
    );
  }

  private _showPanel = () => {
    if (this.state.width <= 1100) {
      this.setState({ showPanel: true });
    }
  };

  private _hidePanel = () => {
    this.setState({ showPanel: false });
  };

  private _handleClick(): void {
    if (this.state.headerFilter == false) {
      this.setState({
        activeClass: 'grid-righter column-filter-activated',
        headerFilter: true,
      });
    } else {
      this.setState({ activeClass: 'grid-righter', headerFilter: false });
    }
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {};
};

const mapDispatchToProps = {
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(QRCodeMainLayout))
);

import React from 'react';
import {Gantt} from '../../../shared/ganttv2/components/gantt/gantt';


import {walk} from '@nosferatu500/react-sortable-tree';
import {Task, ViewMode} from '../../../shared/ganttv2';
import {getStartEndDateForProject, initTasks} from './helper';
import {ViewSwitcher} from './view-switcher';
import _ from 'lodash';
import {Label, Text} from '@fluentui/react';
import {useDispatch, useSelector} from 'react-redux';
import {saveVPStateAttr} from '../../../reducers/visualPlanReducer';
import {RESOURCE_TYPE_IDS} from '../../../shared/util';
import ProgressBar from '../../../shared/progressBar/progressBar';
import {useTranslation} from 'react-i18next';
import {ChartType} from '../../../shared/ganttv2/types/public-types';
import {ExpandProvider} from '../../../hooks/expandContext';
import {ViewModeContextLTP} from '../productTaxonomyListPane/component';

// Init
const ListPaneComponent = (props:{
    vpOrgList:any[],
    isDataLoaded:boolean,
    isToolsDataLoaded:boolean,
    vpWhToolsList:any[]
}) => {
    const [ view, setView ] = React.useState<ViewMode>(ViewMode.Day);
    const [ tasks, setTasks ] = React.useState<Task[]>(initTasks());
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const reduxOrgBarTasks: any[] = useSelector(
        (state: any) => state.vp.vpProjectLabourBarList
    );

    const listTasks: any[] = useSelector(
        (state: any) => state.vp.vpProjectLabourTaxonomyList
    );

    const interactOrgBarTasks: any[] = useSelector(
        (state: any) => state.vp.vpProjectLabourInteractBarList
    );

    const selectedResourceTypeOrg: string = useSelector(
        (state: any) => state.vp.selectedResourceTypeOrgProject
    );

    const reduxToolsBarTasks: any[] = useSelector(
        (state: any) => state.vp.vpProjectToolsBarList
    );

    const toolsListTasks: any[] = useSelector(
        (state: any) => state.vp.vpProjectToolsTaxonomyList
    );

    const interacttoolsBarTasks: any[] = useSelector(
        (state: any) => state.vp.vpProjectToolsInteractBarList
    );

    const { selectedProjectItemType }: any = useSelector(
        (state: any) => state.vp
    );

    const [ isChecked, setIsChecked ] = React.useState(true);
    const [showGanttChart, setShowGanttChart] = React.useState(true);
    let columnWidth = 60;
    if (view === ViewMode.Month) {
        columnWidth = 300;
    } else if (view === ViewMode.Week) {
        columnWidth = 250;
    } else if (view === ViewMode.QuaterYear) {
        columnWidth = 350;
    }

    const handleTaskToolsChange = (task: Task) => {
        let newTasks = interactOrgBarTasks.map(t => (t.id === task.id ? task : t));
        if (task.project) {
            const [ start, end ] = getStartEndDateForProject(newTasks, task.project);
            const project = newTasks[newTasks.findIndex(t => t.id === task.project)];
            if (
                project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
            ) {
                const changedProject = { ...project, start, end };
                newTasks = newTasks.map(t =>
                    t.id === task.project ? changedProject : t
                );
            }
        }
        dispatch(saveVPStateAttr('vpInteractBarList', newTasks));
        // setTasks(newTasks);
    };

    const handleToolsTaskDelete = (task: Task) => {
        const conf = window.confirm('Are you sure about ' + task.name + ' ?');
        if (conf) {
            setTasks(tasks.filter(t => t.id !== task.id));
        }
        return conf;
    };


    const handleTaskChange = (task: Task) => {
        let newTasks = interactOrgBarTasks.map(t => (t.id === task.id ? task : t));
        if (task.project) {
            const [ start, end ] = getStartEndDateForProject(newTasks, task.project);
            const project = newTasks[newTasks.findIndex(t => t.id === task.project)];
            if (
                project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
            ) {
                const changedProject = { ...project, start, end };
                newTasks = newTasks.map(t =>
                    t.id === task.project ? changedProject : t
                );
            }
        }
        dispatch(saveVPStateAttr('vpInteractBarList', newTasks));
        // setTasks(newTasks);
    };

    const handleTaskDelete = (task: Task) => {
        const conf = window.confirm('Are you sure about ' + task.name + ' ?');
        if (conf) {
            setTasks(tasks.filter(t => t.id !== task.id));
        }
        return conf;
    };

    const handleProgressChange = async(task: Task) => {
        setTasks(tasks.map(t => (t.id === task.id ? task : t)));
    };

    const handleToolsProgressChange = async(task: Task) => {
        setTasks(tasks.map(t => (t.id === task.id ? task : t)));
    };

    const handleDblClick = (task: any) => {
        dispatch(saveVPStateAttr('showToolsOrLabourDocumentPane', true));
        dispatch(saveVPStateAttr('selectedLabour', task.personId));
    };

    const handleToolsDblClick = (task: Task) => {
        dispatch(saveVPStateAttr('showToolsOrLabourDocumentPane', true));
        dispatch(saveVPStateAttr('selectedTool', task?.name.split(' ')[0]));
        // dispatch(saveVPStateAttr('selectedTool', true));
        // alert('On Double Click event Id:' + task.id);
    };

    const handleSelect = (task: Task, isSelected: boolean) => {
        // props.handleBarTaskItemClick(task.poId);
        // props.handleBarTaskItemClick(task.poId);
        dispatch(saveVPStateAttr('selectedLabour', null));
    };

    const handleToolsSelect = (task: Task, isSelected: boolean) => {
        dispatch(saveVPStateAttr('selectedTool', null));
        // props.handleBarTaskItemClick(task.poId);
    };

    const handleExpanderClick = (task: Task) => {
        setTasks(tasks.map(t => (t.id === task.id ? task : t)));
    };

    const handleToolsExpanderClick = (task: Task) => {
        setTasks(tasks.map(t => (t.id === task.id ? task : t)));
    };

    const stringToArr = (string1:any) => {
        if(string1) {
            const x = string1.trim().split(',');
            x.shift();
            return x;
        }
        return [];
    };

    const getNodeKey = ({ treeIndex }:any) => treeIndex;

    const sumNodeValues = (treeData:any) => {
        let indexss = '';
        const callback = ({ node }:any) => {
            indexss += ',' + node.id;
        }
     ;

        walk({
            treeData,
            getNodeKey,
            callback,
            ignoreCollapsed: true
        });


        return indexss;
    };

    const onVisibilityChanged = (data:any) => {
        const treeTotal1 = sumNodeValues(data);
        if (stringToArr(treeTotal1).length <= interactOrgBarTasks.length) {
            // const newTasks = reduxOrgBarTasks.filter(f => stringToArr(treeTotal1).some((item:any) => item === f.id));
            const newTasks = interactOrgBarTasks.filter(item => stringToArr(treeTotal1).includes(item.id));
            dispatch(saveVPStateAttr('vpProjectLabourInteractBarList', newTasks));
        } else {
            const newTasks = reduxOrgBarTasks.filter(item => stringToArr(treeTotal1).includes(item.id));
            dispatch(saveVPStateAttr('vpProjectLabourInteractBarList', newTasks));
        }
    };

    const onToolsVisibilityChanged = (data:any) => {
        const treeTotal1 = sumNodeValues(data);
        if (stringToArr(treeTotal1).length <= interacttoolsBarTasks.length) {
            // const newTasks = reduxOrgBarTasks.filter(f => stringToArr(treeTotal1).some((item:any) => item === f.id));
            const newTasks = interacttoolsBarTasks.filter(item => stringToArr(treeTotal1).includes(item.id));
            dispatch(saveVPStateAttr('vpProjectToolsInteractBarList', newTasks));
        } else {
            const newTasks = reduxToolsBarTasks.filter(item => stringToArr(treeTotal1).includes(item.id));
            dispatch(saveVPStateAttr('vpProjectToolsInteractBarList', newTasks));
        }
    };

    const getTasks = () => {
        if (selectedResourceTypeOrg === RESOURCE_TYPE_IDS.LABOURS){
            if ((props.vpOrgList && _.isArray(props.vpOrgList)&& !_.isEmpty(props.vpOrgList) && props.isDataLoaded) && !_.isEmpty(interactOrgBarTasks)){
                return [interactOrgBarTasks,listTasks]
            } else {
                return [tasks, tasks]
            }
        } else {
            if ((props.vpWhToolsList && _.isArray(props.vpWhToolsList) && !_.isEmpty(props.vpWhToolsList) && props.isToolsDataLoaded) && !_.isEmpty(interacttoolsBarTasks)){
                return [interacttoolsBarTasks, toolsListTasks]

            } else {
                return [tasks, tasks]
            }

        }
    }

    const onvisibilityToggled = (data:any) => {

    }

    const onViewModeChange = (viewMode:ViewMode) => {
        setView(viewMode);
      };

    return (
        <div>
             <ViewModeContextLTP.Provider value={ {
                onViewModeChange: (viewMode:ViewMode) => onViewModeChange(viewMode),
                onViewListChange: (isCheck:boolean) => setIsChecked(isCheck),
                isCheck: isChecked,
                onShowGanttChartChangeLTP: (showGanttChart: boolean) => {},
                showGanttChartLTP: showGanttChart
            } }>
                <ExpandProvider>
            <ViewSwitcher
                onViewModeChange={ (viewMode: React.SetStateAction<ViewMode>) => setView(viewMode) }
                onViewListChange={ setIsChecked }
                isChecked={ isChecked }
                renderFilterComponent={ () => <Label></Label> }
            />
            { !props.isDataLoaded &&
                <ProgressBar show={ true } />
            }
            { props.isDataLoaded && _.isEmpty(interactOrgBarTasks) &&
                <Text style={ { marginLeft: 20 } }>{ t('noData') }</Text>
            }
            { props.isDataLoaded && !_.isEmpty(interactOrgBarTasks) &&
            <Gantt
                        //tasks={ (props.vpOrgList && _.isArray(props.vpOrgList) && !_.isEmpty(props.vpOrgList) && props.isDataLoaded) && !_.isEmpty(interactOrgBarTasks) ? interactOrgBarTasks : tasks }
                        tasks={getTasks()[0]}
                        // listTasks={ (props.vpOrgList && _.isArray(props.vpOrgList) && !_.isEmpty(props.vpOrgList) && props.isDataLoaded) ? listTasks : tasks }
                        listTasks={getTasks()[1]}
                        viewMode={view}
                        searchQuery={''}
                        searchMethod={() => { } }
                        searchFocusOffset={0}
                        searchFinishCallback={(matches) => {
                        } }
                        onlyExpandSearchedNodes={true}
                        onDateChange={selectedResourceTypeOrg === RESOURCE_TYPE_IDS.LABOURS ? handleTaskChange : handleTaskToolsChange}
                        onDelete={selectedResourceTypeOrg === RESOURCE_TYPE_IDS.LABOURS ? handleTaskDelete : handleToolsTaskDelete}
                        onProgressChange={selectedResourceTypeOrg === RESOURCE_TYPE_IDS.LABOURS ? handleProgressChange : handleToolsProgressChange}
                        onDoubleClick={selectedResourceTypeOrg === RESOURCE_TYPE_IDS.LABOURS ? handleDblClick : handleToolsDblClick}
                        onSelect={selectedResourceTypeOrg === RESOURCE_TYPE_IDS.LABOURS ? handleSelect : handleToolsSelect}
                        onExpanderClick={selectedResourceTypeOrg === RESOURCE_TYPE_IDS.LABOURS ? handleExpanderClick : handleToolsExpanderClick}
                        listCellWidth={isChecked ? '155px' : ''}
                        columnWidth={columnWidth}
                        onVisibilityChanged={selectedResourceTypeOrg === RESOURCE_TYPE_IDS.LABOURS ? onVisibilityChanged : onToolsVisibilityChanged}
                        ganttHeight={360}
                        isInitialCollaps={true}
                        canDrag={(dropProps: any) => {
                            if (selectedProjectItemType === 4 || selectedProjectItemType === 5 || selectedProjectItemType === 6) {
                                return false;
                            }
                            return dropProps.node?.parentId ? false : true;
                        } }
                        canDrop={(dropProps: any) => {
                            return true;
                        } }
                        onMoveNode={(path, node) => {
                        } }
                        chartType={ChartType.RESOURCE}
                        onVisibilityToggle={onvisibilityToggled}
                        onTreeDataChange={(treeData) => {
                        } } selectedScreen={''}            />
            }

            {/* { (selectedResourceTypeOrg === RESOURCE_TYPE_IDS.TOOLS && typeof interacttoolsBarTasks[0]?.start !== 'string') && props.isDataLoaded && !_.isEmpty(interactOrgBarTasks) &&

                <Gantt
                    tasks={ (props.vpWhToolsList && _.isArray(props.vpWhToolsList) && !_.isEmpty(props.vpWhToolsList) && props.isDataLoaded) && !_.isEmpty(interacttoolsBarTasks) ? interacttoolsBarTasks : tasks }
                    // tasks={ tasks }
                    listTasks={ ( props.vpWhToolsList && _.isArray(props.vpWhToolsList) && !_.isEmpty(props.vpWhToolsList) && props.isDataLoaded) ? toolsListTasks : tasks }
                    viewMode={ view }
                    onDateChange={ handleTaskToolsChange }
                    onDelete={ handleToolsTaskDelete }
                    onProgressChange={ handleToolsProgressChange }
                    onDoubleClick={ handleToolsDblClick }
                    onSelect={ handleToolsSelect }
                    onExpanderClick={ handleToolsExpanderClick }
                    listCellWidth={ isChecked ? '155px' : '' }
                    columnWidth={ columnWidth }
                    isInitialCollaps={ false }
                    onVisibilityChanged={ onToolsVisibilityChanged }
                    ganttHeight={ 300 }
                    canDrag={ (dropProps: any) => {
                        if (selectedProjectItemType === 4 || selectedProjectItemType === 5 || selectedProjectItemType === 6) {
                            return false;
                        }
                        return dropProps.node.parentId ? false : true;
                    } }
                    canDrop={ (dropProps: any) => {
                        return true;
                    } }
                    onMoveNode={ (path, node) => {

                    } }
                />
            } */}
             </ExpandProvider>
      </ViewModeContextLTP.Provider>
        </div>
    );
};

export default ListPaneComponent;

import {DirectionalHint, Label, Link, Stack, Text, TooltipHost} from '@fluentui/react';
import {mergeStyleSets} from '@fluentui/react/lib/Styling';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect, useDispatch, useSelector} from 'react-redux';
import {iconColors, uPrinceTheme} from '../../../../theme';
import {VPShortCutPaneItem} from '../../../types/visualPlan';
import {saveVPStateAttr} from '../../../reducers/visualPlanReducer';
import {RESOURCE_TYPE_IDS, isMyEnvironment} from '../../../shared/util';
import {LinkComponent} from '../../../shared/linkComponent/component';

const classNames = mergeStyleSets({
    wrapper: {
        height: 'auto !important',
        position: 'relative',
        minHeight: '400px !important',
    },
    selected: {
        fontWeight: 'bold',
        color: '#854114!important',
    },
});

const allFilterItem: VPShortCutPaneItem = {
    id: null,
    name: null,
    type: null,
    key: null,
    value: '0',
};

const resourceTableFilterItem: VPShortCutPaneItem = {
    id: '100',
    name: null,
    type: 'table',
    key: '100',
    value: '100',
};

const resourceWeekTableFilterItem: VPShortCutPaneItem = {
    id: '200',
    name: null,
    type: 'weektable',
    key: '200',
    value: '200',
};

interface Props {
    toggleOverlay: () => void;
    toggleOverlayFwd: () => void;
    divClass: string;
    shortCutPaneFilters: any;
    currentActiveSection: string | null;
    handelShortCutPaneFilter: (selectedItem: VPShortCutPaneItem) => void;
    resetFilter: () => void;
}

export const ShortCutPaneComponent = (props: Props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [currentActiveSection, setCurrentActiveSection] = useState<string | null>(null);
    const {selectedVpResourceBarType} = useSelector(
        (state: any) => state.vp
    );

    useEffect(() => {
        setCurrentActiveSection(selectedVpResourceBarType);
    }, []);

    useEffect(() => {
        (selectedVpResourceBarType === "100" || selectedVpResourceBarType === "200") && setCurrentActiveSection(selectedVpResourceBarType);
    }, [selectedVpResourceBarType]);

    useEffect(() => {
        dispatch(saveVPStateAttr('selectedProjectItemType', '5'));
        dispatch(saveVPStateAttr('selectedVpResourceBarType', '100'));
        setFilterOption(resourceTableFilterItem);
    }, [])

    const setFilterOption = (selectedItem: VPShortCutPaneItem) => {
        if (selectedItem.type === 'all') {
            props.resetFilter();
        }
        dispatch(saveVPStateAttr('selectedPOVPshortcutpaneItems', selectedItem.key));
        dispatch(saveVPStateAttr('selectedVpResourceBarType', selectedItem.value));
        setCurrentActiveSection(selectedItem.key)
        props.handelShortCutPaneFilter(selectedItem);
    };

    return (
        <div className={`${props.divClass} ${classNames.wrapper}`}>
            <div className="inner-container">
                <i
                    onClick={props.toggleOverlay}
                    className="ms-Icon ms-Icon--Back back-arrow"
                    aria-hidden="true"
                ></i>
                <i
                    onClick={props.toggleOverlayFwd}
                    className="ms-Icon ms-Icon--Forward fwd-arrow"
                    aria-hidden="true"
                ></i>

                <div className="filter-option-list">
                    <Stack
                        gap={15}
                        verticalFill
                        styles={{
                            root: {
                                width: '100%',
                                verticalAlign: 'center',
                            },
                        }}
                    >
                        <LinkComponent
                            currentActiveSection={currentActiveSection}
                            setFilterOption={() => setFilterOption(allFilterItem)}
                            key={'all'}
                            label={t('all')}
                            selectedKey={null}
                        />

                        <LinkComponent
                            currentActiveSection={currentActiveSection}
                            setFilterOption={() => setFilterOption(resourceTableFilterItem)}
                            key={'table'}
                            label={t('resourceTable')}
                            selectedKey={'100'}
                        />

                        <LinkComponent
                            currentActiveSection={currentActiveSection}
                            setFilterOption={() => setFilterOption(resourceWeekTableFilterItem)}
                            key={'weekTable'}
                            label={t('weekTable')}
                            selectedKey={'200'}
                        />
                    </Stack>
                </div>
                {/*<div className="filter-option-list">*/}
                {/*  <Stack*/}
                {/*    gap={15}*/}
                {/*    verticalFill*/}
                {/*    styles={{*/}
                {/*      root: {*/}
                {/*        width: '100%',*/}
                {/*        verticalAlign: 'center',*/}
                {/*      },*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    <Link*/}
                {/*      key={'All-link'}*/}
                {/*      className={`${props.currentActiveSection === null ? classNames.selected : ''*/}
                {/*      }`}*/}
                {/*      href="#"*/}
                {/*      onClick={() => {*/}
                {/*        setFilterOption(allFilterItem);*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      <TooltipHost*/}
                {/*        key={'All-link1'}*/}
                {/*        content={t('products')}*/}
                {/*        id="filter-opt-1"*/}
                {/*        calloutProps={{ gapSpace: 0 }}*/}
                {/*        directionalHint={DirectionalHint.rightCenter}*/}
                {/*      >*/}
                {/*        <i*/}
                {/*          key={'All-linki'}*/}
                {/*          className="ms-Icon ms-Icon--ViewAll"*/}
                {/*          aria-labelledby="filter-opt-1"*/}
                {/*          aria-hidden="true"*/}
                {/*          style={*/}
                {/*            props.currentActiveSection === null*/}
                {/*              ? { color: iconColors.iconActiveColor }*/}
                {/*              : { color: uPrinceTheme.palette.themePrimary }*/}
                {/*          }*/}
                {/*        ></i>*/}
                {/*      </TooltipHost>*/}
                {/*      <i*/}
                {/*        key={'All-linki2'}*/}
                {/*        className="filter-icon ms-Icon ms-Icon--ViewAll filter-icon"*/}
                {/*        aria-hidden="true"*/}
                {/*        style={*/}
                {/*          props.currentActiveSection === null*/}
                {/*            ? { color: iconColors.iconActiveColor }*/}
                {/*            : { color: uPrinceTheme.palette.themePrimary }*/}
                {/*        }*/}
                {/*      ></i>*/}
                {/*      <Text style={*/}
                {/*        currentActiveSection === null*/}
                {/*          ? { color: iconColors.iconActiveColor, fontSize:13 }*/}
                {/*          : { color: uPrinceTheme.palette.black, fontSize:13 }*/}
                {/*         */}
                {/*      } key="all"> {t('resources')}</Text>*/}
                {/*    </Link>*/}
                {/*    { isMyEnvironment() &&*/}
                {/*    <Link*/}
                {/*      key={'table'}*/}
                {/*      className={`${props.currentActiveSection === '100' ? classNames.selected : ''*/}
                {/*      }`}*/}
                {/*      href="#"*/}
                {/*      onClick={() => {*/}
                {/*        setFilterOption(resourceTableFilterItem);*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      <TooltipHost*/}
                {/*        key={'resource_table'}*/}
                {/*        content={t('resourceMatrix')}*/}
                {/*        id="filter-opt-1"*/}
                {/*        calloutProps={{ gapSpace: 0 }}*/}
                {/*        directionalHint={DirectionalHint.rightCenter}*/}
                {/*      >*/}
                {/*        <i*/}
                {/*          key={'resource_table1'}*/}
                {/*          className="ms-Icon ms-Icon--ViewAll"*/}
                {/*          aria-labelledby="filter-opt-1"*/}
                {/*          aria-hidden="true"*/}
                {/*          style={*/}
                {/*            props.currentActiveSection === '100'*/}
                {/*              ? { color: iconColors.iconActiveColor }*/}
                {/*              : { color: uPrinceTheme.palette.themePrimary }*/}
                {/*          }*/}
                {/*        ></i>*/}
                {/*      </TooltipHost>*/}
                {/*      <i*/}
                {/*        key={'resource_table2'}*/}
                {/*        className="filter-icon ms-Icon ms-Icon--ViewAll filter-icon"*/}
                {/*        aria-hidden="true"*/}
                {/*        style={*/}
                {/*          props.currentActiveSection === '100'*/}
                {/*            ? { color: iconColors.iconActiveColor }*/}
                {/*            : { color: uPrinceTheme.palette.themePrimary }*/}
                {/*        }*/}
                {/*      ></i>*/}
                {/*      <Text style={*/}
                {/*        currentActiveSection === '100'*/}
                {/*          ? { color: iconColors.iconActiveColor, fontSize:13 }*/}
                {/*          : { color: uPrinceTheme.palette.black, fontSize:13 }*/}
                {/*         */}
                {/*      } key="table"> {t('table')}</Text>*/}
                {/*    </Link>*/}
                {/*    }*/}
                {/*    {renderFilterItems()}*/}
                {/*  </Stack>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShortCutPaneComponent);

import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { DocumentPaneComponent } from './component';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import {
  Warehouse,
  WarehouseDropDowns,
  warehouseFormData,
  WarehouseListPaneFilter,
  warehouseValidationMessage,
} from '../../../types/warehouse';
import history from '../../../history';
import { LocationAddress, Position } from '../../../types/projectDefinition';
import { ITag } from '@fluentui/react';
import client from '../../../api';
import _ from 'lodash';
import { messageService } from '../../../services/messageService';
import { getContractingUnit, getProject } from '../../../shared/util';
import { v4 as uuidv4 } from 'uuid';
import { readLocationsByProjectId } from '../../../reducers/pmolReducer';
import { getProjectHeaderDetails } from '../../../reducers/projectReducer';
import {
  createTaxonomyNode,
  createWarehouseLocation,
  readByWarehouseId,
  readWarehouseTaxonomy,
  saveFormData,
} from '../../../reducers/warehouseReducer';
import MapModal from '../../cuProjects/mapModal/component';


export interface State {
  isDirty: boolean;
  redirect: boolean;
  loading: boolean;
  isEdit: boolean;
  formData: Warehouse;
  filter: WarehouseListPaneFilter,
  validationMessages: warehouseValidationMessage;
  position: { lat: number; lon: number };
  isMapOpen: boolean;
  location: any;
  locationId: any;
  uid: string | null;
  isClear: boolean,
  isLocationChange: boolean,
}

interface Props extends WithTranslation {
  formData: any,
  isLoading: boolean,
  warehouseDropDowns: WarehouseDropDowns;
  warehouseTaxonomy: any;
  readLocationsByProjectId: any,
  getProjectHeaderDetails: any,
  projectHeader: any,
  pmolLocation: any,
  warehouseLocationId: any,
  saveFormData: any,
  readByWarehouseId: any,
  createWarehouseLocation: any,
  createTaxonomyNode: (newNode: any) => void;
  readWarehouseTaxonomy: (data: any) => void;
}

class DocumentPane extends React.Component<Props, State> {
  subscription: any;
  form: any;
  submitExternal: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      isDirty: false,
      redirect: false,
      loading: false,
      isEdit: false,
      isLocationChange: false,
      formData: warehouseFormData,
      filter: new WarehouseListPaneFilter(),
      validationMessages: {
        borErrorMsg: null,
        nameErrorMsg: null,
        statusErrorMsg: null,
        typeErrorMsg: null,
      },
      position: { lat: 0.0, lon: 0.0 },
      isMapOpen: false,
      location: null,
      uid: null,
      locationId: null,
      isClear: false,
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if ((prevProps.pmolLocation != this.props.pmolLocation) && !this.props.formData?.locationId) {
      if (
        this.props.pmolLocation &&
        this.props.pmolLocation.position &&
        !isNaN(parseFloat(this.props.pmolLocation.position.lat.toString()))
      ) {
        this.setState({
            isClear: false,
            locationId: this.props.pmolLocation?.id,
            location: this.props.pmolLocation,
            position: {
              lat: parseFloat(this.props.pmolLocation.position.lat.toString()),
              lon: parseFloat(this.props.pmolLocation.position.lon.toString()),
            },
          }, () => {
            if (!this.props.formData.locationId) {
              //this.props.createPODeliveryLocation(this.state.location);
            }
          },
        );
      }
    }

    if (prevProps.warehouseLocationId != this.props.warehouseLocationId) {
      this.setState({ locationId: this.props.warehouseLocationId, isClear: false });
    }

    if (prevProps.formData?.sequenceId !== this.props.formData?.sequenceId) {
      this.scrollIntoTop();
      this.props.readWarehouseTaxonomy({ wareHouseId: this.props.formData.id });
    }
    if (prevProps.formData !== this.props.formData) {
      if (!this.state.isDirty) {
        if (!_.isEmpty(this.props.formData)) {

          const formData :any= this.props.formData;
          this.setState({
            formData: formData,
            isClear: false,
            location: this.props.formData?.mapLocation,
            position: this.props.formData?.mapLocation?.position,
            locationId: formData.locationId,
          });
        }
      } else {
        if (_.isEmpty(this.props.formData)) {
          const formData :any = this.state.formData;
          this.setState({
            formData: formData,
            isClear: false,
            location: this.props.formData?.mapLocation,
            position: this.props.formData?.mapLocation?.position,
            locationId: formData.locationId,
          });
        }
      }

      if (this.props.formData?.projectSequenceCode) {
        this.props.getProjectHeaderDetails(
          this.props.formData.projectSequenceCode,
        );
      }
    }
  }


  componentDidMount() {
    if (this.props.formData.sequenceId) {
      this.props.readWarehouseTaxonomy({ wareHouseId: this.props.formData.id });
    }
    if (getProject()) {
      let projectId = getProject();
      if (projectId) {
        this.props.readLocationsByProjectId(projectId);
        this.props.getProjectHeaderDetails(projectId);
      }
    } else if (this.props.formData.projectSequenceCode) {
      this.props.getProjectHeaderDetails(
        this.props.formData.projectSequenceCode,
      );
    }
    if (location.pathname.split('/').pop() === 'new') {
      this.setState({ uid: uuidv4(), isClear: true });
    }
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewWarehouse) {
          if (getProject()) {
            this.props.readLocationsByProjectId(getProject());
            this.setState({ uid: uuidv4(), isClear: true });
          } else {
            this.setState({
              uid: uuidv4(), isClear: true, position: { lat: 0.0, lon: 0.0 },
              location: null,
              locationId: null,
            });
          }
          this.clearWarehouseData();
          if (this.form) {
            this.form.reset();
            this.form.resetFieldState('header.name');
            this.form.resetFieldState('header.typeId');
            this.form.resetFieldState('header.statusId');
          }

          this.scrollIntoTop();
        }

        if (data.data.createWarehouseNewNode) {
          this.createNewNode(data.data.createWarehouseNewNode);
        }
        if (data.data.closeWarehouseTaxonomyModal) {
          this.props.readWarehouseTaxonomy({ wareHouseId: this.props.formData.id });
        }

        if (data.data.saveWhFormData) {
          this.submitExternal();
        }
        if (data.data.cloneWarehouse) {
          this.cloneWarehouse();
        }
      }
    });
  }

  private clearWarehouseData = () => {
    let formData: Warehouse = { ...warehouseFormData, id: this.state.uid };
    this.setState({
      formData,
    });
  };

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  scrollIntoTop = () => {
    let topDiv = document.getElementById('topDiv');
    if (topDiv) {
      topDiv.scrollIntoView();
    }
  };

  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { psFilter: this.state.filter },
    });
  };

  private setLocationAddress = (
    position: Position,
    address?: LocationAddress,
  ) => {
    if (address) {
      this.setState(
        {
          position: { lat: position.lat, lon: position.lon },
          location: address,
          isClear: false,
          locationId: null,
          isLocationChange: true,
        },
      );
    } else {
      this.setState(
        (prevState: any) => ({
          isClear: false,
          isLocationChange: false,
          locationId: null,
          position: position,
          location: {
            ...prevState.location,
            position: position,
          },
        }),
      );
    }
  };

  setForm = (form: any) => {
    this.form = form;
  };

  setSubmitExternal = (submit: any) => {
    this.submitExternal = submit;
  };

  cloneWarehouse = () => {
    let values = this.form.getState().values;
    if (this.state.formData?.sequenceId) {
      let formData = {
        ...this.state.formData,
        id: uuidv4(),
        sequenceId: null,
        statusId: this.state.formData?.status?.key,
        typeId: this.state.formData?.type?.key,
        isClone: true,
      };
      this.props.saveFormData(formData).then((response: any) => {
        let whId = response?.payload?.data?.result;
        if (getContractingUnit() && getProject()) {
          history.push(`/CU/${getContractingUnit()}/project/${getProject()}/stock-warehouse/${whId}`);
        } else {
          history.push(`/CU/${getContractingUnit()}/stock-warehouse/${whId}`);
        }
      });
    }
  };

  saveFormData = () => {
    let values = this.form.getState().values;
    //values.header.projectSequenceCode = (getProject())?getProject():this.props?.formData?.projectSequenceCode;
    const formValues = { ...values.header, cpcIdVehicle: values.header?.vehicleNumber?.value };
    if (formValues.vehicleNumber) {
      delete formValues.vehicleNumber;
    }
    if (!formValues.id) {
      formValues.id = this.state.uid;
    }
    if (formValues.locationId && !this.state.isLocationChange) {
      this.props.saveFormData(formValues).then((response: any) => {
        this.props.readByWarehouseId(response.payload.data.result);
      });
    } else {
      if (this.state.location) {
        this.props.createWarehouseLocation(this.state.location).then((response: any) => {
          if (response && response?.payload?.data?.result) {
            this.setState({ locationId: response?.payload?.data?.result, isLocationChange: false }, () => {
              let data = {
                ...formValues,
                locationId: response?.payload?.data?.result,
              };
              this.props.saveFormData(data).then((response: any) => {
                this.props.readByWarehouseId(response.payload.data.result);
              });
            });
          }
        });
      } else {
        this.props.saveFormData(formValues).then((response: any) => {
          this.props.readByWarehouseId(response.payload.data.result);
        });
      }
    }

  };

  createNewNode = (newNode: any) => {
    if (newNode) {
      this.props.createTaxonomyNode({ ...newNode, warehouseId: this.state.formData.id });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <div id={'topDiv'}>
          <RouteLeavingGuard
            when={this.state.isDirty}
            navigate={(path) => this.handleDiscardChangesNavigate(path)}
            shouldBlockNavigation={(location) => {
              return true;
            }}
            handleConfirmation={(isConfirm: boolean) => {
              this.setState({
                isDirty: !isConfirm,
              });
            }}
          />
          {/*<pre>{JSON.stringify(this.props.warehouseTaxonomy.length>0?'ree':'null', 0, 2)}</pre>*/}
          <DocumentPaneComponent
            dropDownOptions={{
              ...this.props.warehouseDropDowns,
              // warehouseTaxonomy: [
              //   {
              //     id: "88bc4819-5968-4c38-8a59-20da60ecb89e",
              //     title: "Resource Family 1",
              //     localeCode: null,
              //     parentId: null,
              //     whTaxonomyLevelId:  "88bc4819-hn12-4c38-8a59-20da60ecb89e",
              //     displayOrder: 1,
              //   },
              //   {
              //     id: "88bc4819-b78m-4c38-8a59-20da60ecb89e",
              //     title: "rack1",
              //     localeCode: null,
              //     parentId: "88bc4819-hn12-4c38-8a59-20da60ecb89e",
              //     whTaxonomyLevelId: "88bc4819-hn12-4c38-8a59-20da60ecb89e",
              //     displayOrder: 3,
              //   },
              //   {
              //     id: "88bc4819-dn12-4c38-8a59-20da60ecb89e",
              //     title: "Warehouse 3",
              //     localeCode: null,
              //     parentId: null,
              //     whTaxonomyLevelId: "88bc4819-hn12-4c38-8a59-20da60ecb89e",
              //     displayOrder: 1,
              //   },
              //   {
              //     id: "88bc4819-hn12-4c38-8a59-20da60ecb89e",
              //     title: "zone1",
              //     localeCode: null,
              //     parentId: "88bc4819-dn12-4c38-8a59-20da60ecb89e",
              //     whTaxonomyLevelId: "88bc4819-hn12-4c38-8a59-20da60ecb89e",
              //     displayOrder: 2,
              //   },
              //   {
              //     id: "88bc4819-qn12-4c38-8a59-20da60ecb89e",
              //     title: "box1",
              //     localeCode: null,
              //     parentId: "88bc4819-dn12-4c38-8a59-20da60ecb89e",
              //     whTaxonomyLevelId: "88bc4819-hn12-4c38-8a59-20da60ecb89e",
              //     displayOrder: 5,
              //   },
              //   {
              //     id: "88bc4819-qnmp-4c38-8a59-20da60ecb89e",
              //     title: "Kasun",
              //     localeCode: null,
              //     parentId: "88bc4819-wbnk-4c38-8a59-20da60ecb89e",
              //     whTaxonomyLevelId: "88bc4819-hn12-4c38-8a59-20da60ecb89e",
              //     displayOrder: 0,
              //   },
              //   {
              //     id: "88bc4819-wbnk-4c38-8a59-20da60ecb89e",
              //     title: "Umesh",
              //     localeCode: null,
              //     parentId: null,
              //     whTaxonomyLevelId: "88bc4819-hn12-4c38-8a59-20da60ecb89e",
              //     displayOrder: 0,
              //   }
              // ],
              warehouseTaxonomy: this.props.warehouseTaxonomy?.length > 0 ? this.props.warehouseTaxonomy : [],
            }}
            formData={this.state.formData}
            formValues={this.form?.getState()?.values}
            validationMessages={this.state.validationMessages}
            isEdit={this.state.isEdit}
            position={this.state.position}
            setLocationAddress={(
              position: Position,
              address: LocationAddress,
            ) => {
              this.setLocationAddress(position, address);
            }}
            openMapModal={() => this.openMapModal()}
            address={
              this.state.location &&
              this.state.location?.address &&
              this.state.location.address.freeformAddress
                ? this.state.location.address.freeformAddress
                : ''
            }
            onFilterTaxonomyNodeChanged={this.onFilterTaxonomyNodeChanged}
            saveFormData={() => {
              this.saveFormData();
            }}
            setForm={(form: any) => {
              this.setForm(form);
            }}
            isDisabled={false}
            projectHeader={this.props.projectHeader}
            submit={(submit: any) => {
              this.setSubmitExternal(submit);
            }}
            showProgress={this.props.isLoading}
            uid={this.state.uid}
            isClear={this.state.isClear}
          />
          <MapModal
            isOpen={this.state.isMapOpen}
            openMapModal={() => this.openMapModal()}
            setLocationAddress={(pos: Position, address?: LocationAddress) => {
              this.setLocationAddress(pos);
            }}
            position={this.state.position}
          />
        </div>
      </div>
    );
  }

  private openMapModal = (): void => {
    this.setState({
      isMapOpen: !this.state.isMapOpen,
    });
  };


  private getTaxonomyByName = async (name: string) => {
    let filter = {
      title: name,
    };
    let response = await client.post(`Pbs/FilterProductByTaxonomyLevel`, filter);
    return this.formatTaxonomyResponse(response, name);

  };

  formatTaxonomyResponse = (response: any, name: string) => {
    let data: ITag[] | any = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: any) => {
        return {
          name: item.text,
          key: item.key,
          isNewItem: false,
        };
      });
      let obj = data.find((o: any) => o.name.toLowerCase() === name.toLowerCase());
      if (!obj) {
        data.unshift({ key: '', name: name, isNewItem: true });
      }
    } else {
      return [{ key: '', name: name, isNewItem: true }];
    }
    return data;
  };

  private onFilterTaxonomyNodeChanged = (
    filterText: string,
    currentPersonas: ITag[],
    limitResults?: number,
  ): ITag[] | Promise<ITag[]> => {
    if (filterText) {
      return this.getTaxonomyByName(filterText);
    } else {
      return [{ key: '', name: filterText }];
    }
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    isLoading: state.warehouse.isLoading,
    warehouseDropDowns: state.warehouse.warehouseDropDowns,
    warehouseTaxonomy: state.warehouse.warehouseTaxonomy,
    projectHeader: state.project.projectHeader,
    pmolLocation: state.pmol.pmolLocation,
    warehouseLocationId: state.warehouse.warehouseLocationId,
  };
};

const mapDispatchToProps = {
  readLocationsByProjectId,
  getProjectHeaderDetails,
  saveFormData,
  createWarehouseLocation,
  readByWarehouseId,
  createTaxonomyNode,
  readWarehouseTaxonomy,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DocumentPane)),
);

import React, { Component } from 'react';
import ProjectDefinitionMainLayout from './projectDefinitionMainLayout';
import { getContractingUnit } from '../../shared/util';
import UprinceNavigation from '../../shared/uprinceNavigation/cuProjectNavigation/component';


interface Props {
  currentUser: any
}

interface State {
  isContractUnit: boolean;
  isProject: boolean;
}

export class ProjectDefinition extends Component<Props, State> {
  render() {
    return (
      <UprinceNavigation
        currentUser={this.props.currentUser}
        selectedNavigationIndex={`/CU/${getContractingUnit()}`}
      >
        <ProjectDefinitionMainLayout/>
      </UprinceNavigation>
    );
  }
}

export default (ProjectDefinition);

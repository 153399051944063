import React from 'react';
import {
 Icon, Link
} from '@fluentui/react';
import history from './../history';
import i18n from '../../i18n';

i18n;

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error:"" };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    // Display fallback UI
    console.log(error)
    this.setState({ hasError: true , error: error});
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <p style={{ textAlign: 'center', color: '#f4681d', fontSize: 35 }}><Icon iconName={'Warning'}></Icon></p>
          <p style={{ textAlign: 'center', color: '#000000', fontSize: 24 }}><label>{i18n.t('Whoops!')}</label>
          </p>
          <p style={{ textAlign: 'center', color: '#000000', fontSize: 18, alignContent:'center' }}>
            <label>{i18n.t('somethingWentWrong')}</label></p>
            <p style={{ textAlign: 'center', color: '#000000', fontSize: 18, background:'#D5D8DC', width:400,  display: 'flex',
                        justifyContent: 'center',
                        marginLeft:'auto',
                        marginRight:'auto',
                        alignContent:'center',
                        paddingTop:30,
                        paddingBottom:30,
                        alignItems: 'center' }}>
            {this.state.error.toString().split('.')[0]}
            </p>
          <p style={{ textAlign: 'center' }}>
            <Link
              onClick={(event) => {
                event.preventDefault();
                history.goBack();
              }}>{i18n.t('Go back')} </Link>
          </p>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

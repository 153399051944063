import { Image, Label, Link } from '@fluentui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactVisibilitySensor from 'react-visibility-sensor'
import SignatureDataGrid from '../../handshake/component'
import { Signature } from '../../handshake/modal/component'
import CustomFileUploader from '../../../../shared/fileUploader/customFileUploader'
import { ProjectMolecule } from '../../../../types/projectMolecule'
import { PMOL_PBS_HEADER_SECTION_LIST } from '../../../../shared/util'

const StopHandshake = (props: {
  onVisibilityChangePlannedWorks: (isVisible: boolean) => void;
  signatures: Signature[];
  openTechInstuctionModal: () => void;
  openSignatureModal: () => void;
  handleDTechInstructionDelete: () => void;
  readOnly: boolean;
  readHandShakeStatus: boolean;
  handshakeDocs: any[]; 
  formData: ProjectMolecule;
  saveHandshakeDocuments: (data: {
    pmolId: string,
    docLinks: string[]
  }) => void;
  saveFormData: () => void;
  handleHandshakeChange: (handshakeDoc: any[]) => void;
}) => {
  const {t} = useTranslation();
  const [handShakeImages, setHandShakeImages] = useState<any>([]);

  useEffect(() => {
    setHandShakeImages(props.handshakeDocs)
  }, [props.handshakeDocs])

  const handShakeColumns = [
    {
      key: 'column1',
      name: t('name'),
      fieldName: 'name',
      minWidth: 250,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('signature'),
      fieldName: 'link',
      minWidth: 180,
      maxWidth: 180,
      isResizable: true,
      onRender: (item: Signature) => {
        return (
          <div>
            <div key={item.name}>
              <Image
                src={item.link ? item.link : ''}
                alt=""
                width={100}
                height={30}
              />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="document-pane-card">
      <div className={'card-header'}>
        <div className="proj-detail-block" id="21">
          <Link href="#" id="stop-handshake-6-1">
            <Label>7. {t('stopHandshake')} </Label>
          </Link>
        </div>
      </div>

      <div className={'card-body'}>
        <div>
          <ReactVisibilitySensor scrollCheck onChange={props.onVisibilityChangePlannedWorks}>
            <SignatureDataGrid
              dataList={props.signatures}
              editDataGrid={() => props.openTechInstuctionModal()}
              openModel={() => {
                props.openSignatureModal();
              }}
              selection={null}
              actionButtonState={{ add: false, edit: false, remove: false }}
              title={t('signatures')}
              deleteDataGrid={() => {
                props.handleDTechInstructionDelete();
              }}
              readOnly={props.readOnly}
              columns={handShakeColumns}
              isLoaded={!props.readHandShakeStatus}
            />
          </ReactVisibilitySensor>
        </div>

        <div style={{ marginTop: 20, marginLeft: 10 }}>
          <CustomFileUploader
            uploadedImages={handShakeImages}
            label={t('documents')}
            uploadUrl={'Pmol/UploadStopHandShakeFile'}
            showCheckbox={true}
            showDelete={true}
            showCopyToPbs={true}
            pmolId ={props?.formData?.projectMoleculeId!!}
            onSaveSuccess={(response: any) => {
              if (props.formData.id) {
                let newImage = {image: response, link: response}
                props.handshakeDocs.push(newImage);
                // setHandShakeImages(props.handshakeDocs);
                props.saveHandshakeDocuments({ pmolId: props.formData.id!!, docLinks: [response] });
                if (props.handshakeDocs) {
                  let newImage: any = [];
                  props.handshakeDocs.forEach((item: any) => {
                    newImage.push({
                      id:item.id,
                      image: item.image,
                      type: item.type,
                      isApproved: item.isApproved
                    });
                  });
                  setHandShakeImages(newImage);
                }
              }
            } }
            disable={props.readOnly} checkedItems={[]} productId={undefined}
            showRename={true}
            sectionHeading={PMOL_PBS_HEADER_SECTION_LIST.HANDSHAKE}
            renameSuccess={() =>{
              props.saveFormData();
            }}
            updateDocument={(item: any, isDelete: boolean) => {
              if(isDelete){
                setHandShakeImages(item);
                props.handleHandshakeChange(item);
              }
          }}
          />
        </div>
      </div>
    </div>
  )
}

export default StopHandshake
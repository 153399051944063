import {Moment} from "moment/moment";

export class DayPlanningListPaneFilter {
  title: string | null = null;
  sorter: Sorter = new Sorter();
}

export interface DayPlanningShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
  value: number | null
}

class Sorter {
  attribute: string | null = null;
  order: string | null = null;
}

export class DayPlanningListItem {
  id: string | null = null;
  name: string | null = null;
  sequenceId: string | null = null;
  title: string | null = null;
}

export interface DropDown {
  key: string;
  text: string;
}

export interface DPLANDropDowns {
  status: DropDown[];
  types: DropDown[];
}

export interface DayPlanning {
  id: string | null;
  title: string;
  sequenceId: string
}

export interface DPLANDetails {
  id: string | null;
  sequenceId: string | null;
  title: string | null;
  name: string | null;
}

export interface History {
  createdByUser: string | null;
  createdDateTime: string | null;
  revisionNumber: number | string | null;
  updatedByUser: string | null;
  updatedDateTime: string | null;
}

export const formData = {
  id: null,
  title: 'string',
  sequenceId: 'string',
};

export enum DocumentPaneType {
  PMOL = 'Pmol',
  CU_HR = 'CuHr',
  CPC = 'cpc',
}

export enum SelectedGridType {
  DAY = 'day',
  WEEK = 'week',
}

export class DayPlanningList {
  startDate: string | Moment | null = null;
  endDate: string | Moment | null = null;
  localDate: string | null = null;
  offSet: string | null = null;
  type: string | null = null;
}
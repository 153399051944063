import React from 'react';
import { Icon, Link } from '@fluentui/react';
import history from './../history';
import i18n from '../../i18n';

i18n;

interface Props {
  location: any
}

class PermissionDeniedPage extends React.Component<Props, any> {
  render() {
    return (
      <div>
        <p style={{ textAlign: 'center', color: '#f4681d', fontSize: 35 }}><Icon iconName={'Warning'}></Icon></p>
        <p style={{ textAlign: 'center', color: '#000000', fontSize: 24 }}><label>{i18n.t('Whoops!') as any}</label></p>
        {
          this.props.location.state.project &&
          <p style={{ textAlign: 'center', color: '#000000', fontSize: 18 }}>
            <label>{i18n.t(`Sorry, You don\'t have an access to project ${this.props.location.state.project}`) as any}</label>
          </p>
        }
        {!this.props.location.state.project &&
        <p style={{ textAlign: 'center', color: '#000000', fontSize: 18 }}>
          <label>{i18n.t('Sorry, You don\'t have an access to this page!') as any}</label></p>

        }

        <p style={{ textAlign: 'center' }}>
          <Link
            onClick={(event) => {
              event.preventDefault();
              history.goBack();
            }}>{i18n.t('Go back') as any} </Link>
        </p>
      </div>
    );
  }
}

export default PermissionDeniedPage;

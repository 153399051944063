export const LOAD_QUALITY_SHORTCUTPANE = 'uprince/LOAD_QUALITY_SHORTCUTPANE';
export const LOAD_QUALITY_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_QUALITY_SHORTCUTPANE_SUCCESS';
export const LOAD_QUALITY_SHORTCUTPANE_FAIL = 'uprince/LOAD_QUALITY_SHORTCUTPANE_FAIL';

export const LOAD_QUALITY_LSITPANE = 'uprince/LOAD_QUALITY_LSITPANE';
export const LOAD_QUALITY_LSITPANE_SUCCESS = 'uprince/LOAD_QUALITY_LSITPANE_SUCCESS';
export const LOAD_QUALITY_LSITPANE_FAIL = 'uprince/LOAD_QUALITY_LSITPANE_FAIL';

export const LOAD_QUALITY_DROPDOWN = 'uprince/LOAD_QUALITY_DROPDOWN';
export const LOAD_QUALITY_DROPDOWN_SUCCESS = 'uprince/LOAD_QUALITY_DROPDOWN_SUCCESS';
export const LOAD_QUALITY_DROPDOWN_FAIL = 'uprince/LOAD_QUALITY_DROPDOWN_FAIL';

export const SAVE_QUALITY = 'uprince/SAVE_QUALITY';
export const SAVE_QUALITY_SUCCESS = 'uprince/SAVE_QUALITY_SUCCESS';
export const SAVE_QUALITY_FAIL = 'uprince/SAVE_QUALITY_FAIL';

export const LOAD_QUALITY_BY_ID = 'uprince/LOAD_QUALITY_BY_ID';
export const LOAD_QUALITY_BY_ID_SUCCESS = 'uprince/LOAD_QUALITY_BY_ID_SUCCESS';
export const LOAD_QUALITY_BY_ID_FAIL = 'uprince/LOAD_QUALITY_BY_ID_FAIL';

export const LOAD_BOR_RESOURCES_BY_ID = 'uprince/LOAD_BOR_RESOURCES_BY_ID';
export const LOAD_BOR_RESOURCES_BY_ID_SUCCESS = 'uprince/LOAD_BOR_RESOURCES_BY_ID_SUCCESS';
export const LOAD_BOR_RESOURCES_BY_ID_FAIL = 'uprince/LOAD_BOR_RESOURCES_BY_ID_FAIL';

export const SET_QUALITY_UID = 'uprince/SET_QUALITY_UID';
export const CLEAR_QUALITY_UID = 'uprince/CLEAR_QUALITY_UID';
export const CLEAR_QUALITY_FORM_DATA = 'uprince/CLEAR_QUALITY_FORM_DATA';
export const RESET_QUALITY_IS_CHANGE = 'uprice/RESET_QUALITY_IS_CHANGE';


export const CLEAR_QUALITYUID = 'uprice/CLEAR_QUALITYUID';


export const DELETE_QUALITY = 'uprince/DELETE_QUALITY';
export const DELETE_QUALITY_SUCCESS = 'uprince/DELETE_QUALITY_SUCCESS';
export const DELETE_QUALITY_FAIL = 'uprice/DELETE_QUALITY_FAIL';

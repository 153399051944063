import React, { createContext, useState, useEffect } from 'react';

const MoreMenuContext = createContext<any>({});

export const MoreMenuProvider: any = ({ children }: any) => {
  const [isPmolCreate, setPmolCreate] = useState(false);
  const [product, setProduct] = useState(null);

  return (
    <MoreMenuContext.Provider value={{isPmolCreate, setPmolCreate, product,setProduct}}>
      {children}
    </MoreMenuContext.Provider>
  );
};

export default MoreMenuContext;

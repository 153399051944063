import {
  ChoiceGroup,
  DirectionalHint,
  Dropdown,
  IChoiceGroupOption,
  IStackStyles,
  Label,
  Link,
  mergeStyles,
  Stack,
  Text,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import { Depths } from '@fluentui/theme';
import _ from 'lodash';
import QRCode from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import client from '../../../api';
import { READ_CPC_VEHICLES } from '../../../shared/endpoints';
import SearchableDropdown from '../../../shared/searchableDropdown/searchableDropdown';
import { getQRName } from '../../../shared/util';
import QRHistoryBar from '../history/historyBar';

// const theme: ITheme = getTheme();
// const { palette, semanticColors, fonts } = theme;
const stackStyles: IStackStyles = { root: {} };

const dropdownStyles = { dropdown: { width: 300, marginTop: 20 } };

const contentStyle = mergeStyles({
  height: 'calc(100vh-200px)!important',

  display: 'flex',
  flexDirection: 'column',
  selectors: {
    '@media(max-width: 1100px)': {
      width: '97.4%',
      height: 'calc(100vh)',
      position: 'absolute',
    },
  },
});

const NewQRCodeComponent = ({
                              selectedQRCode,
                              toggleBookmarkOut,
                              toggleBookmark,
                              divClass,
                              resetValue,
                              setResetValue,
                              currentActiveSection,
                              createQRCode,
                              setLocation,
                              location,
                              locationErrorMsg,
                              onProjectChange,
                              selectedProject,
                              role,
                              onChangeRole,
                              vehicalNo,
                              setVehicalNo,
                              vehicalNoErrorMsg,
                            }: any) => {
  let qrValue: string = '';
  const [project, setProject] = useState<{ value: string | null, label: string | null }>({ value: null, label: null });
  // const [ vehicaleNo, setVehicle ] = useState<{value:string|null, label:string|null}>({ value: null, label: null });
  if (selectedQRCode) {
    qrValue = `{id:${selectedQRCode.id}}`;
  }
  const type = selectedQRCode && selectedQRCode.activityType && selectedQRCode.activityType.type ? selectedQRCode.activityType.type : '';
  const { t } = useTranslation();
  const codeTypes = [
    { key: 4, text: t('start') },
    { key: 0, text: t('travel') },
    { key: 1, text: t('work') },
    { key: 6, text: t('break') },
    { key: 2, text: t('unload') },
    { key: 5, text: t('stop') },
  ];

  const options: IChoiceGroupOption[] = [
    { key: '1', text: t('driver') },
    { key: '2', text: t('passenger') },
  ];


  useEffect(() => {
    if (resetValue) {
      setProject({ value: null, label: null });
      // setVehicle({ value: null, label: null });
      setResetValue(false);
    } else {
      // setProject({key:selectedQRCode.projectDefinition.id,text:selectedQRCode.projectDefinition.name})
    }
  }, [resetValue]);

  useEffect(() => {
    if (selectedQRCode) {
      setProject({
        value: selectedQRCode && selectedQRCode.projectDefinition ? selectedQRCode.projectDefinition.id : null,
        label: selectedQRCode && selectedQRCode.projectDefinition ? selectedQRCode.projectDefinition.name : null,
      });
      // setVehicle({ value: selectedQRCode.vehicleCpcId, label: selectedQRCode.vehicleNo });
    }
  }, [selectedQRCode]);

  const formatResponse = (response: any) => {
    let data: { value: string; label: string }[] = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: any) => {
        return {
          value: item.key,
          label: item.text,
          sequenceCode: item.sequenceCode,
        };
      });
    }
    return data;
  };

  const formatVehicleResponse = (response: any) => {
    let data: { value: string; label: string }[] = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: any) => {
        return {
          value: item.key,
          label: item.text,
          sequenceCode: item.key,
        };
      });
    }
    return data;
  };


  const getByName = async (name: string) => {
    let projects = [];
    const response = await client.post(
      'ContractingUnitTenants/GetProjectsByUserProjectFilter', {
        title: name,
        projectTypeId: null,
        managementLevelId: null,
        toleranceStateId: null,
        toleranceState: null,
        sorter: {
          attribute: null,
          order: null,
        },
      },
    );

    if (response && response.status === 200 && response.data && response.data.result) {
      projects = response.data.result.map((item: any) => {
        return {
          value: item.projectDefinitionId,
          label: item.title,
          sequenceCode: item.sequenceCode,
        };
      });
    }
    // const response = await client.get(
    //     `ProjectDefinition/ReadProjectsForQr/${name}`
    // );
    return projects;
    // return formatResponse(response);
  };

  const searchVehicles = async (name: string) => {
    if (name) {
      const response = await client.get(READ_CPC_VEHICLES + name);
      return formatVehicleResponse(response);
    } else {
      return [];
    }
  };

  const promiseOptions = (inputValue: any) =>
    new Promise((resolve) => {
      // setTimeout(() => {
      resolve(getByName(inputValue));
      // }, 1000);
    });

  const cpcPromiseOptions = (inputValue: any) =>
    new Promise((resolve) => {
      // setTimeout(() => {
      resolve(searchVehicles(inputValue));
      // }, 1000);
    });


  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Stack
        className={contentStyle}
        horizontalAlign="start"
        verticalAlign="start"
        padding={0}
        gap={0}
        styles={{
          root: {
            width: '100%',
            height: '100vh',
          },
        }}
      >

        <div className="document-pane-card" style={{ marginTop: '1rem' }}>
          <div className="proj-detail-block" id="5">
            <div className={'card-header'}>
              <Link href="#" id="qrCode" style={{ marginTop: 5 }}>
                <Label>{1 + '. ' + t('qRCode')}</Label>
              </Link>
            </div>
          </div>


          <Stack horizontal horizontalAlign="space-between" styles={stackStyles}>

            <Dropdown
              placeholder={t('activityType')}
              ariaLabel="Required"
              options={codeTypes}
              required={true}
              disabled={true}
              defaultSelectedKey={selectedQRCode ? selectedQRCode.type : null}
              onChange={() => {
              }}
              styles={dropdownStyles}
            />

          </Stack>


          {selectedQRCode && (selectedQRCode.type === 1 ||
            selectedQRCode.type === 0 ||
            selectedQRCode.type === 2 ||
            selectedQRCode.type === 4 ||
            selectedQRCode.type === 5 ||
            selectedQRCode.type === 6) && (
            <div style={{ width: 300 }}>
              <Label>{t('project')}</Label>
              <SearchableDropdown
                onChange={(item: any) => {
                  if (item) {
                    const selectedItem = { key: item.value, text: item.label };
                    onProjectChange(null, selectedItem);
                    setProject(item);
                  } else {
                    const selectedItem = { key: null, text: null };
                    onProjectChange(null, selectedItem);
                    setProject({ value: null, label: null });
                  }
                }}
                selectedOption={selectedProject}
                promiseOptions={promiseOptions}
                validationMessage={''}
                required={false}
                disabled={false}
              />
            </div>
          )}

          {selectedQRCode && selectedQRCode.type === 0 && (
            <div style={{ width: '80%' }} className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                <div style={{ width: 300 }}>
                  <Label required={true}>{t('vehicleNumber')}</Label>

                  <SearchableDropdown
                    onChange={(item: any) => {
                      if (item) {
                        const selectedItem = {
                          key: item.value,
                          text: item.label,
                        };
                        setVehicalNo(null, selectedItem);
                        // setVehicle(item);
                      } else {
                        const selectedItem = { key: null, text: null };
                        setVehicalNo(null, selectedItem);
                        // setVehicle({ value: null, label: null });
                      }
                    }}
                    selectedOption={vehicalNo}
                    promiseOptions={cpcPromiseOptions}
                    validationMessage={vehicalNoErrorMsg}
                    required={false}
                    disabled={false}
                  />
                </div>
              </div>
              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                <ChoiceGroup
                  selectedKey={role}
                  options={options}
                  onChange={onChangeRole}
                  label={t('role')}
                  required={true}
                  style={{ marginLeft: 120 }}
                  disabled={false}
                />
                {!_.isEmpty(false) && (
                  <Text
                    styles={{ root: { color: '#a4262c', fontSize: 12, marginLeft: 100 } }}
                  >
                    { /* { roleValidationMsg } */}
                  </Text>
                )}
              </div>
            </div>
          )}

          {selectedQRCode && (selectedQRCode.type === 4 ||
            selectedQRCode.type === 2 ||
            selectedQRCode.type === 5) && (
            <TextField
              label={t('location')}
              required={true}
              onChange={setLocation}
              value={location}
              errorMessage={locationErrorMsg}
              disabled={false}
              styles={{ root: { width: 300 } }}
            />
          )}

          {selectedQRCode && (
            <QRCode className="marginBottom"
                    id={getQRName(selectedQRCode)}
                    value={qrValue}
                    style={{ marginTop: 20, marginLeft: 5 }}
                    size={200}
            />
          )}

        </div>
        {selectedQRCode && selectedQRCode.history && (
          <div className="proj-detail-block" id="5">
            <div className="document-pane-card">
              <div className={'card-header'}>
                <Link href="#" id="history">
                  <Label>2. {t('history')} </Label>
                </Link>
              </div>
              <div className="proj-detail-content inner marginBottom">
                {selectedQRCode && selectedQRCode.history && (
                  <QRHistoryBar
                    createdByUser={selectedQRCode.history.createdByUser}
                    updatedByUser={selectedQRCode.history.updatedByUser}
                    createdDateTime={selectedQRCode.history.createdDateTime}
                    updatedDateTime={selectedQRCode.history.updatedDateTime}
                  />
                )}
              </div>

            </div>
          </div>
        )}


        { /* { selectedQRCode && selectedQRCode.type !== -1 && (
                    <PrimaryButton
                        text={ t('Update') }
                        allowDisabledFocus
                        style={ { marginTop: 40 } }
                        onClick={ () => {
                            createQRCode();
                            // setProject(null)
                            // setVehicle(null)
                        } }
                    />
                ) } */}

        { /* { isLoading && (
                    <Spinner
                        size={ SpinnerSize.large }
                        style={ {
                            flex: 1,
                            width: '100%',
                            height: '100%',
                            alignSelf: 'center',
                            marginTop: 0
                        } }
                    />
                ) } */}

        <div>
          <Stack
            className={divClass}
            verticalFill
            gap={30}
            styles={{ root: { selectors: { '@media(max-width: 1440px)': { boxShadow: Depths.depth8 } } } }}
          >
            <i
              title={t('close')}
              className="ms-Icon ms-Icon--ChromeClose"
              aria-hidden="true"
              onClick={toggleBookmarkOut}
            ></i>
            <Text className="title">{t('bookmark')}</Text>
            <div className="inner-container">
              <Label
                className={`${currentActiveSection === 1 ? 'selected' : ''}`}
              >
                <Link href="#qrCode">1. {t('qRCode')}</Link>
              </Label>
              <Label
                className={`${currentActiveSection === 2 ? 'selected' : ''}`}
              >
                <Link href="#history">2. {t('history')}</Link>
              </Label>
            </div>
          </Stack>

          <Stack
            className="bookmark-panel-responsive"
            verticalFill
            onClick={toggleBookmark}
            gap={30}
            styles={{ root: { boxShadow: Depths.depth8 } }}
          >
            <TooltipHost
              content={t('bookmark')}
              id="bmark"
              calloutProps={{ gapSpace: 0 }}
              directionalHint={DirectionalHint.rightCenter}
            >
              <i
                className="ms-Icon ms-Icon--SearchBookmark"
                aria-labelledby="bmark"
                aria-hidden="true"
              ></i>
            </TooltipHost>
          </Stack>
        </div>
      </Stack>
    </div>
  );
};


export default NewQRCodeComponent;

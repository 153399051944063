export const dataAmmo = {
    "team": [
        {
            "name": "Personeel",
            "id": "1",
            "competenceList": [{
                "id": "cd0b8482-5673-4354-a383-d268be9529a1",
                "name": "Android ",
                "worker": [{
                    "id": "711f90c3-b7d3-49d3-9d12-3e34d4f37393",
                    "name": "Mesith Wettasinghe",
                    "cabPersonCompanyId": "03f0008d-7098-4153-bac5-73c1c95cdf6e",
                    "cabPersonId": "711f90c3-b7d3-49d3-9d12-3e34d4f37393",
                    "companyId": "e0386eac-c9a0-4f93-8baf-d24948bedda9",
                    "competenciesTaxonomyTitle": "Android ",
                    "competenciesTaxonomyId": "cd0b8482-5673-4354-a383-d268be9529a1",
                    "organization": "1",
                    "companyName": "Goddeeris",
                    "isLabour": false
                }]
            }, {
                "id": "57b33e75-6e95-4809-b000-eafd4804e407",
                "name": "Android Development",
                "worker": [{
                    "id": "4426450a-62fd-4211-b918-c2a142b502cc",
                    "name": "Sama Willson",
                    "cabPersonCompanyId": "4be38f5b-821d-42e7-b72a-f8929420ee0d",
                    "cabPersonId": "4426450a-62fd-4211-b918-c2a142b502cc",
                    "companyId": "e0386eac-c9a0-4f93-8baf-d24948bedda9",
                    "competenciesTaxonomyTitle": "Android Development",
                    "competenciesTaxonomyId": "57b33e75-6e95-4809-b000-eafd4804e407",
                    "organization": null,
                    "companyName": "Goddeeris",
                    "isLabour": false
                }]
            }, {
                "id": "372b2224-23fa-4341-a768-b4eb97704e24",
                "name": "Chat GPT",
                "worker": [{
                    "id": "b1a1b4d7-3814-42d8-99dc-81d472858e58",
                    "name": "Pradeep Madusanka",
                    "cabPersonCompanyId": "5e557886-bef8-4261-b4e1-1611b5b9f0df",
                    "cabPersonId": "b1a1b4d7-3814-42d8-99dc-81d472858e58",
                    "companyId": "e0386eac-c9a0-4f93-8baf-d24948bedda9",
                    "competenciesTaxonomyTitle": "Chat GPT",
                    "competenciesTaxonomyId": "372b2224-23fa-4341-a768-b4eb97704e24",
                    "organization": "0",
                    "companyName": "Goddeeris",
                    "isLabour": false
                }]
            }, {
                "id": "7050ecc3-5d63-4820-9217-ebd85c158569",
                "name": "Cyber Security",
                "worker": [{
                    "id": "7f241ad7-2f3e-440f-a6a9-d4cfbd456b3d",
                    "name": "Kavisha Nawanjani",
                    "cabPersonCompanyId": "217ca444-72d9-4d53-9d26-838c76a0ac0b",
                    "cabPersonId": "7f241ad7-2f3e-440f-a6a9-d4cfbd456b3d",
                    "companyId": "e0386eac-c9a0-4f93-8baf-d24948bedda9",
                    "competenciesTaxonomyTitle": "Cyber Security",
                    "competenciesTaxonomyId": "7050ecc3-5d63-4820-9217-ebd85c158569",
                    "organization": "1",
                    "companyName": "Goddeeris",
                    "isLabour": false
                }]
            }, {
                "id": "30b736c7-339b-48ee-a2e9-6b8a81cfd237",
                "name": "Developer",
                "worker": [{
                    "id": "779b0f2d-ab45-406b-873e-0a01281e2f0f",
                    "name": "Elizabeth Francisco",
                    "cabPersonCompanyId": "f71699d9-e5aa-4b7f-924d-8bf53735108b",
                    "cabPersonId": "779b0f2d-ab45-406b-873e-0a01281e2f0f",
                    "companyId": "e0386eac-c9a0-4f93-8baf-d24948bedda9",
                    "competenciesTaxonomyTitle": "Developer",
                    "competenciesTaxonomyId": "30b736c7-339b-48ee-a2e9-6b8a81cfd237",
                    "organization": null,
                    "companyName": "Goddeeris",
                    "isLabour": false
                }]
            }, {
                "id": "738d9f61-6fe4-463a-bd66-e6744ed1f44b",
                "name": "Director",
                "worker": [{
                    "id": "64f3d0b4-158f-45c5-95e2-a78ca11ff6e1",
                    "name": "Shanuka Gayashan",
                    "cabPersonCompanyId": "bf0b6cdd-6aa7-4e24-9a59-29e001d26065",
                    "cabPersonId": "64f3d0b4-158f-45c5-95e2-a78ca11ff6e1",
                    "companyId": "e0386eac-c9a0-4f93-8baf-d24948bedda9",
                    "competenciesTaxonomyTitle": "Director",
                    "competenciesTaxonomyId": "738d9f61-6fe4-463a-bd66-e6744ed1f44b",
                    "organization": "0",
                    "companyName": "Goddeeris",
                    "isLabour": false
                }]
            }, {
                "id": "59358fa2-ed6e-4b0e-8b7b-0e9b82e9a229",
                "name": "IT Management",
                "worker": [{
                    "id": "859bcb51-8a4c-4132-b000-3f33dca923c3",
                    "name": "Dayan Vitarana",
                    "cabPersonCompanyId": "8cf8f30c-90cf-4816-8ce0-826cd82bb295",
                    "cabPersonId": "859bcb51-8a4c-4132-b000-3f33dca923c3",
                    "companyId": "e0386eac-c9a0-4f93-8baf-d24948bedda9",
                    "competenciesTaxonomyTitle": "IT Management",
                    "competenciesTaxonomyId": "59358fa2-ed6e-4b0e-8b7b-0e9b82e9a229",
                    "organization": null,
                    "companyName": "Goddeeris",
                    "isLabour": false
                }]
            }, {
                "id": "f4dcd6d3-df60-4085-b7dc-7363b716bdee",
                "name": "Network",
                "worker": [{
                    "id": "9d9f5a3c-0da0-41bb-bb06-3eaf28be6b97",
                    "name": "Dilanka Perera",
                    "cabPersonCompanyId": "b81c3ff9-e40f-4bc7-a30e-3f25935033c0",
                    "cabPersonId": "9d9f5a3c-0da0-41bb-bb06-3eaf28be6b97",
                    "companyId": "e0386eac-c9a0-4f93-8baf-d24948bedda9",
                    "competenciesTaxonomyTitle": "Network",
                    "competenciesTaxonomyId": "f4dcd6d3-df60-4085-b7dc-7363b716bdee",
                    "organization": "1",
                    "companyName": "Goddeeris",
                    "isLabour": false
                }]
            }, {
                "id": "3d18b48b-e9c7-4778-ab16-33f61c3d081d",
                "name": "System Administration",
                "worker": [{
                    "id": "25360304-3cda-495c-8ac5-1fd2b1aad40e",
                    "name": "Osama Billaden",
                    "cabPersonCompanyId": "0469c961-ecdc-4ff4-ae3d-12c1a29fd716",
                    "cabPersonId": "25360304-3cda-495c-8ac5-1fd2b1aad40e",
                    "companyId": "e0386eac-c9a0-4f93-8baf-d24948bedda9",
                    "competenciesTaxonomyTitle": "System Administration",
                    "competenciesTaxonomyId": "3d18b48b-e9c7-4778-ab16-33f61c3d081d",
                    "organization": null,
                    "companyName": "Goddeeris",
                    "isLabour": false
                }]
            }]
        },
        {
            "name": "Interim",
            "id": "2",
            "competenceList": []
        },
        {
            "name": "Onderaannemers",
            "id": "3",
            "competenceList": []
        }
    ]
}
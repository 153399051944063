import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';
import { Invoice } from '../../../types/invoice';
import { getProject } from '../../../shared/util';
import {
  APPROVE_STATUS_ID_INVOICE,
  IN_REVIEW_STATUS_ID,
  PENDING_DEVELOPMENT_STATUS_ID,
} from '../../../types/projectMolecule';

const CommandBarRight = (props: {
  handleRemove: () => void;
  formData: Invoice;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  const saveFormData = () => {
    messageService.sendMessage({ saveInvoiceFormData: true });
  };
  const handleRemove = () => {
    props.handleRemove();
  };

  const printClick = () => {
    if (!props.isLoading) {
      setTimeout(() => {
        messageService.sendMessage({ invoicePdfGenerate: true });
      }, 700);
    }
  };

  const handleProgressStatement = () => {
    messageService.sendMessage({ openProgressStatement: true });
  };

  const handleInvoiceApprove = () => {
    if (getProject()) {
      messageService.sendMessage({ approveInvoice: true });
    } else {
      messageService.sendMessage({ approveInvoiceCu: true });
    }
  };

  const handleInReviewClick = () => {
    if (!getProject()) {
      messageService.sendMessage({ reviewInvoiceCu: true });
    }
  };

  const isApproveButtonDisabled = () => {
    if (getProject()) {
      return true;
    } else if ((props.formData.invoiceStatusId === PENDING_DEVELOPMENT_STATUS_ID) || (props.formData.invoiceStatusId === IN_REVIEW_STATUS_ID)) {
      return false;
    }
    return true;
  };

  const getItems = () => {
    const items = [
      {
        key: 'save',
        text: t('save'),
        iconProps: { iconName: 'Save' },
        onClick: () => {
          saveFormData();
        },
        disabled: true,
        order: 1,
      },
      {
        key: 'Remove',
        onClick: () => {
          handleRemove();
        },
        name: t('remove'),
        iconProps: { iconName: 'Delete' },
        disabled: true,
        order: 2,
      },
      {
        key: 'progressStatement',
        onClick: () => {
          handleProgressStatement();
        },
        name: t('progressStatement'),
        iconProps: { iconName: 'Money' },
        disabled: props.isLoading,
        order: 3,
      },
      {
        key: 'print',
        text: t('print'),
        disabled: (!(!props.isLoading && props.formData.invoiceId)),
        iconProps: { iconName: 'Print' },
        onClick: () => printClick(),
        order: 4,
      },
      {
        key: 'approve',
        text: t('approve'),
        iconProps: { iconName: 'ActivateOrders' },
        // disabled: (!getProject() ? (props.formData.invoiceStatusId !== PENDING_DEVELOPMENT_STATUS_ID) : true),
        disabled: isApproveButtonDisabled(),
        onClick: () => handleInvoiceApprove(),
        order: 6,
      },
    ];

    const cuItem =
      {
        key: 'review',
        text: t('inReview'),
        // disabled: (!(!props.isLoading && props.formData.invoiceId)),
        iconProps: { iconName: 'FieldNotChanged' },
        onClick: () => handleInReviewClick(),
        order: 5,
        disabled: ((props.formData.invoiceStatusId === IN_REVIEW_STATUS_ID) || (props.formData.invoiceStatusId === APPROVE_STATUS_ID_INVOICE) || !props.formData.invoiceId || props.isLoading) ? true : false,
      };
    if (!getProject()) {
      items.push(cuItem);
      items.sort((a, b) => a.order > b.order ? 1 : -1);
    }

    return items;
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );
};

export default CommandBarRight;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../../history';

import ListPaneComponent from './component';
import { withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  filterDayPlanningResourceListPaneData,
  readDayPlanningDropDownData,
  setSelectedGridType,
} from '../../../reducers/dayPlanningReducer';
import { messageService } from '../../../services/messageService';
import { DPLANDropDowns, SelectedGridType } from '../../../types/dayPlanning';
import {
  formatDateTimeToISOString,
  formatEndDateTimeToISOString,
  getContractingUnit,
  getProject,
  getTimeZone,
} from '../../../shared/util';
import moment from 'moment';

export interface State {
  redirect: boolean;
  isChange: boolean;
  loading: boolean;
  resourceList: any;
}

export interface Props extends WithTranslation {
  isDataLoaded?: boolean;
  isChange: boolean;
  reSizer: boolean;
  loadMsg: string;
  reloadListPane: boolean,
  dplanDropDowns: DPLANDropDowns;
  readDayPlanningDropDownData: () => void;
  filterDayPlanningResourceListPaneData: any;
  resourceList: any;
  selectedGridType: SelectedGridType;
  setSelectedGridType: any
}

class ResourceListPane extends Component<Props, State> {

  subscription: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      redirect: false,
      isChange: false,
      loading: false,
      resourceList: null,
    };

  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  componentDidMount() {
    this.props.setSelectedGridType(SelectedGridType.DAY);
    this.props.filterDayPlanningResourceListPaneData(this.getFilterDate(true));
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {

      }
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.resourceList != this.props.resourceList) {
      if (this.props.isDataLoaded) {

      }
    }
    if (prevProps.reloadListPane != this.props.reloadListPane) {
      if (this.props.reloadListPane) {
        this.props.filterDayPlanningResourceListPaneData(this.getFilterDate());
      }
    }
    if (prevProps.selectedGridType != this.props.selectedGridType) {
      if (this.props.selectedGridType) {
        this.props.filterDayPlanningResourceListPaneData(this.getFilterDate());
      }
    }
  }

  getFilterDate = (didMount?: boolean) => {
    var currentDate = moment();
    if (didMount) {
      return {
        startDate: formatDateTimeToISOString(new Date()),
        endDate: formatEndDateTimeToISOString(new Date()),
        localDate: moment().toISOString(),
        offSet: getTimeZone(),
        type: SelectedGridType.DAY,
      };
    } else {
      return {
        startDate: this.props.selectedGridType === SelectedGridType.DAY ? formatDateTimeToISOString(new Date()) : moment(currentDate).startOf('week').isoWeekday(1).format('YYYY-MM-DD'),
        endDate: this.props.selectedGridType === SelectedGridType.DAY ? formatEndDateTimeToISOString(new Date()) : currentDate.clone().endOf('week'),
        localDate: moment().toISOString(),
        offSet: getTimeZone(),
        type: this.props.selectedGridType,
      };
    }
  };

  handleListPaneItemClick = (id: string) => {
    //this.props.getById(id);
    if (id) {
      if (getProject()) {
        history.push(`/CU/${getContractingUnit()}/project/${getProject()}/day-planning/hr/` + id);
      } else {
        history.push(`/CU/${getContractingUnit()}/day-planning/hr/` + id);
      }
    }
  };
  handleListPaneCpcItemClick = (id: string) => {
    if (id) {
      if (getProject()) {
        history.push(`/CU/${getContractingUnit()}/project/${getProject()}/day-planning/cpc/` + id);
      } else {
        history.push(`/CU/${getContractingUnit()}/day-planning/cpc/` + id);
      }
    }
  };


  render() {
    return (
      <div>
        <ListPaneComponent
          listPaneItems={
            this.props.resourceList ? this.props.resourceList : []
          }
          handleListPaneCpcItemClick={(id: string) =>
            this.handleListPaneCpcItemClick(id)
          }
          handleListPaneHrItemClick={(id: string) =>
            this.handleListPaneItemClick(id)
          }
          loadMsg={this.props.loadMsg}
          isDataLoaded={this.props.isDataLoaded}
          dropDownOptions={this.props.dplanDropDowns}
        />
      </div>
    );
  }

}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    // toleranceStatesFilter:state.projectBreakdown.toleranceStatesFilter,
    // productStatesFilter: state.projectBreakdown.productStatesFilter,
    // itemTypesFilter: state.projectBreakdown.itemTypesFilter,
    isChange: state.dplan.isChange,
    dplanDropDowns: state.dplan.dplanDropDowns,
    resourceList: state.dplan.resourceList,
    isDataLoaded: state.dplan.isResourceDataLoaded,
    reloadListPane: state.dplan.reloadListPane,
    selectedGridType: state.dplan.selectedGridType,
  };
};

const mapDispatchToProps = {
  readDayPlanningDropDownData,
  filterDayPlanningResourceListPaneData,
  setSelectedGridType,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ResourceListPane)),
);

import { FontWeights, getTheme, IconButton, IIconProps, mergeStyleSets, Modal } from '@fluentui/react';
import React from 'react';
import ProgressBar from '../progressBar/progressBar';
import { uPrinceTheme } from '../../../theme';
import { DefaultCustomModalProps } from '../../types/types';
import { FluentProvider } from '@fluentui/react-components';
import { uPrinceFluentLightTheme } from '../../../fluentTheme';

const theme = getTheme();

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xxLarge,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xxLarge,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 16px 14px 16px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    // minWidth: '30vw',
    // minHeight: '10vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
    // maxHeight: '80vh',
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const DefaultCustomModal = (props: DefaultCustomModalProps) => {
  const {
    children,
    onRenderFooter,
    isModalOpen,
    title,
    showProgressBar,
    handleClose,
    styles,
  } = props;

  const headerStyle = styles ? styles.header : contentStyles.header;
  const bodyStyle = styles ? styles.body : contentStyles.body;
  const footerStyle = styles ? styles.footer : contentStyles.footer;

  return <Modal
    titleAriaId={'titleId'}
    isOpen={isModalOpen}
    onDismiss={() => handleClose()}
    scrollableContentClassName='body'
    isBlocking={false}
    dragOptions={undefined}
  >
    <FluentProvider theme={uPrinceFluentLightTheme}>
      <div className={headerStyle}>
        <span>{title}</span>
        <IconButton
          styles={cancelIconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel='Close popup modal'
          onClick={() => handleClose()}
        />
      </div>

      <ProgressBar show={!!showProgressBar} />
      <div className={bodyStyle}>
        <div>
          <div className='ms-Grid-row'>
            <div className='ms-Grid-col ms-sm12 ms-md12 ms-lg12'>
            {children}
          </div>
        </div>
        </div>
      </div>

      <div className={footerStyle}>
        {onRenderFooter()}
      </div>
    </FluentProvider>
  </Modal>;
};

export default DefaultCustomModal;

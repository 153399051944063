export const LOAD_STOCK_SHORTCUTPANE = 'uprince/LOAD_STOCK_SHORTCUTPANE';
export const LOAD_STOCK_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_STOCK_SHORTCUTPANE_SUCCESS';
export const LOAD_STOCK_SHORTCUTPANE_FAIL = 'uprince/LOAD_STOCK_SHORTCUTPANE_FAIL';

export const LOAD_STOCK_LSITPANE = 'uprince/LOAD_STOCK_LSITPANE';
export const LOAD_STOCK_LSITPANE_SUCCESS = 'uprince/LOAD_STOCK_LSITPANE_SUCCESS';
export const LOAD_STOCK_LSITPANE_FAIL = 'uprince/LOAD_STOCK_LSITPANE_FAIL';

export const LOAD_STOCK_DROPDOWN = 'uprince/LOAD_STOCK_DROPDOWN';
export const LOAD_STOCK_DROPDOWN_SUCCESS = 'uprince/LOAD_STOCK_DROPDOWN_SUCCESS';
export const LOAD_STOCK_DROPDOWN_FAIL = 'uprince/LOAD_STOCK_DROPDOWN_FAIL';

export const SAVE_STOCK = 'uprince/SAVE_STOCK';
export const SAVE_STOCK_SUCCESS = 'uprince/SAVE_STOCK_SUCCESS';
export const SAVE_STOCK_FAIL = 'uprince/SAVE_STOCK_FAIL';

export const LOAD_STOCK_BY_ID = 'uprince/LOAD_STOCK_BY_ID';
export const LOAD_STOCK_BY_ID_SUCCESS = 'uprince/LOAD_STOCK_BY_ID_SUCCESS';
export const LOAD_STOCK_BY_ID_FAIL = 'uprince/LOAD_STOCK_BY_ID_FAIL';

export const LOAD_BOR_RESOURCES_BY_ID = 'uprince/LOAD_BOR_RESOURCES_BY_ID';
export const LOAD_BOR_RESOURCES_BY_ID_SUCCESS = 'uprince/LOAD_BOR_RESOURCES_BY_ID_SUCCESS';
export const LOAD_BOR_RESOURCES_BY_ID_FAIL = 'uprince/LOAD_BOR_RESOURCES_BY_ID_FAIL';

export const SET_STOCK_UID = 'uprince/SET_STOCK_UID';
export const CLEAR_STOCK_UID = 'uprince/CLEAR_STOCK_UID';
export const CLEAR_STOCK_FORM_DATA = 'uprince/CLEAR_STOCK_FORM_DATA';
export const RESET_STOCK_IS_CHANGE = 'uprice/RESET_STOCK_IS_CHANGE';


export const CLEAR_STOCKUID = 'uprice/CLEAR_STOCKUID';

import { IStackTokens, mergeStyleSets } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../../theme';
import { getProject } from '../../../../shared/util';
import { BorResourcesItem } from '../../../../types/billOfResources';
import {
  GOODDEERIS_PRODUCT_TYPE_ID,
  PRODUCT_TYPE_ID,
  RESOURCE_TYPE,
  RESOURCE_TYPE_ID,
  RFQ_REQUEST,
} from '../../../../types/purchaseOrder';
import MCDataGrid from './mcDataGrid';
import TLDataGrid from './tlDataGrid';
import EPDataGrid from './epDataGrid';
import { IN_DEVELOPMENT_STATUS_ID } from '../../../../types/projectMolecule';
import { store } from '../../../../../index';
import RFQDataGrid from './rfqDataGrid';

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const classNames = mergeStyleSets({
  wrapper: {
    minHeight: 230,
    position: 'relative',
  },
  fullWidth: { width: '100%' },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  bigScreenTree: {
    marginBottom: 30,
    height: '30vh',
  },
  smallScreenTree: {
    marginBottom: 30,
    height: '56vh',
  },
  textEditor: { height: window.innerWidth <= 1280 ? '50vh' : '25vh' },
});


const Resources = (props: {
  materialSelection: any;
  toolSelection: any;
  consumableSelection: any;
  labourSelection: any;
  borResources: { materials: any[], tools: any[], consumable: any[], labour: any[] };
  setOpenPOResourceModal: () => void;
  isExpand: boolean;
  externalProducts: any[];
  type: number | string,
  poStatusId: string
}) => {
  const { t } = useTranslation();
  const iconWidth = 30;
  const iconExpandWidth = 40;
  const resource: BorResourcesItem = {
    id: null,
    type: null,
    invoiced: null,
    warf: null,
    purchased: null,
    deliveryRequested: null,
    consumed: null,
    date: null,
    title: null,
    required: null,
    environment: null,
    isNew: false,
    resourceNumber: null,
  };
  const materialsColumns = [
    {
      key: 'column1',
      name: t('documents'),
      fieldName: 'pDocuments',
      minWidth: props.isExpand ? iconExpandWidth : iconWidth,
      maxWidth: props.isExpand ? iconExpandWidth : iconWidth,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('comments'),
      fieldName: 'pComments',
      minWidth: props.isExpand ? iconExpandWidth : iconWidth,
      maxWidth: props.isExpand ? iconExpandWidth : iconWidth,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('borTitle'),
      fieldName: 'borTitle',
      minWidth: props.isExpand ? 300 : 100,
      maxWidth: props.isExpand ? 300 : 100,
      isResizable: true,
    },
    {
      key: 'column4',
      name: t('resourceTitle'),
      fieldName: 'pTitle',
      minWidth: props.isExpand ? 400 : 200,
      maxWidth: props.isExpand ? 400 : 200,
      isResizable: true,
    },
    {
      key: 'column5',
      name: t('quantity'),
      fieldName: 'pQuantity',
      minWidth: props.isExpand ? 100 : 70,
      maxWidth: props.isExpand ? 100 : 70,
      isResizable: true,
    },
    {
      key: 'column6',
      name: t('mou'),
      fieldName: 'pMou',
      minWidth: props.isExpand ? 100 : 70,
      maxWidth: props.isExpand ? 100 : 70,
      isResizable: true,
    },
    {
      key: 'column7',
      name: t('unitPrice'),
      fieldName: 'pUnitPrice',
      minWidth: props.isExpand ? 80 : 100,
      maxWidth: props.isExpand ? 80 : 100,
      isResizable: true,
    },
    {
      key: 'column8',
      name: t('totalPrice'),
      fieldName: 'pTotalPrice',
      minWidth: props.isExpand ? 140 : 100,
      maxWidth: props.isExpand ? 140 : 100,
      isResizable: true,
    },
    // {
    //     key: 'column9',
    //     name: t('requestedDeliveryDate'),
    //     fieldName: 'requestedDate',
    //     minWidth: props.isExpand ? 160 : 120,
    //     maxWidth: props.isExpand ? 160 : 120,
    //     isResizable: true
    // }
  ];

  const materialsCUColumns = [
    {
      key: 'column1',
      name: t('documents'),
      fieldName: 'cDocuments',
      minWidth: props.isExpand ? iconExpandWidth : iconWidth,
      maxWidth: props.isExpand ? iconExpandWidth : iconWidth,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('comments'),
      fieldName: 'cComments',
      minWidth: props.isExpand ? iconExpandWidth : iconWidth,
      maxWidth: props.isExpand ? iconExpandWidth : iconWidth,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('borTitle'),
      fieldName: 'borTitle',
      minWidth: props.isExpand ? 300 : 100,
      maxWidth: props.isExpand ? 300 : 100,
      isResizable: true,
    },
    {
      key: 'column4',
      name: t('resourceTitle'),
      fieldName: 'cTitle',
      minWidth: props.isExpand ? 400 : 200,
      maxWidth: props.isExpand ? 400 : 200,
      isResizable: true,
    },
    {
      key: 'column5',
      name: t('quantity'),
      fieldName: 'cQuantity',
      minWidth: props.isExpand ? 100 : 70,
      maxWidth: props.isExpand ? 100 : 70,
      isResizable: true,
    },
    {
      key: 'column6',
      name: t('mou'),
      fieldName: 'cMou',
      minWidth: props.isExpand ? 100 : 70,
      maxWidth: props.isExpand ? 100 : 70,
      isResizable: true,
    },
    {
      key: 'column7',
      name: t('unitPrice'),
      fieldName: 'cUnitPrice',
      minWidth: props.isExpand ? 80 : 100,
      maxWidth: props.isExpand ? 80 : 100,
      isResizable: true,
    },
    {
      key: 'column8',
      name: t('totalPrice'),
      fieldName: 'cTotalPrice',
      minWidth: props.isExpand ? 140 : 100,
      maxWidth: props.isExpand ? 140 : 100,
      isResizable: true,
    },
    // {
    //     key: 'column9',
    //     name: t('requestedDeliveryDate'),
    //     fieldName: 'requestedDate',
    //     minWidth: props.isExpand ? 160 : 120,
    //     maxWidth: props.isExpand ? 160 : 120,s
    //     isResizable: true
    // }
  ];

  const toolsColumns = [
    {
      key: 'column1',
      name: t('documents'),
      fieldName: 'pDocuments',
      minWidth: props.isExpand ? iconExpandWidth : iconWidth,
      maxWidth: props.isExpand ? iconExpandWidth : iconWidth,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('comments'),
      fieldName: 'pComments',
      minWidth: props.isExpand ? iconExpandWidth : iconWidth,
      maxWidth: props.isExpand ? iconExpandWidth : iconWidth,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('borTitle'),
      fieldName: 'borTitle',
      minWidth: props.isExpand ? 180 : 200,
      maxWidth: props.isExpand ? 180 : 200,
      isResizable: true,
    },
    {
      key: 'column4',
      name: t('resourceTitle'),
      fieldName: 'pTitle',
      minWidth: props.isExpand ? 200 : 150,
      maxWidth: props.isExpand ? 200 : 150,
      isResizable: true,
    },
    {
      key: 'column5',
      name: t('devices'),
      fieldName: 'pdevices',
      minWidth: props.isExpand ? 60 : 70,
      maxWidth: props.isExpand ? 60 : 70,
      isResizable: true,
    },
    {
      key: 'column6',
      name: t('startDate'),
      fieldName: 'pStartDate',
      minWidth: props.isExpand ? 90 : 70,
      maxWidth: props.isExpand ? 90 : 70,
      isResizable: true,
    },
    {
      key: 'column7',
      name: t('stopDate'),
      fieldName: 'pStopDate',
      minWidth: props.isExpand ? 90 : 70,
      maxWidth: props.isExpand ? 90 : 70,
      isResizable: true,
    },
    {
      key: 'column8',
      name: t('noDays'),
      fieldName: 'pNumberOfDate',
      minWidth: props.isExpand ? 80 : 70,
      maxWidth: props.isExpand ? 80 : 70,
      isResizable: true,
    },
    {
      key: 'column9',
      name: t('quantity'),
      fieldName: 'pQuantity',
      minWidth: props.isExpand ? 60 : 70,
      maxWidth: props.isExpand ? 60 : 70,
      isResizable: true,
    },
    {
      key: 'column10',
      name: t('mou'),
      fieldName: 'pMou',
      minWidth: props.isExpand ? 60 : 70,
      maxWidth: props.isExpand ? 60 : 70,
      isResizable: true,
    },
    {
      key: 'column11',
      name: t('unitPrice'),
      fieldName: 'pUnitPrice',
      minWidth: props.isExpand ? 100 : 150,
      maxWidth: props.isExpand ? 100 : 150,
      isResizable: true,
    },
    {
      key: 'column12',
      name: t('totalPrice'),
      fieldName: 'pTotalPrice',
      minWidth: props.isExpand ? 140 : 100,
      maxWidth: props.isExpand ? 140 : 100,
      isResizable: true,
    },
    // {
    //     key: 'column13',
    //     name: t('requestedDeliveryDate'),
    //     fieldName: 'requestedDate',
    //     minWidth: props.isExpand ? 160 : 120,
    //     maxWidth: props.isExpand ? 160 : 120,
    //     isResizable: true
    // }
  ];

  const toolsCUColumns = [
    {
      key: 'column1',
      name: t('documents'),
      fieldName: 'cDocuments',
      minWidth: props.isExpand ? iconExpandWidth : iconWidth,
      maxWidth: props.isExpand ? iconExpandWidth : iconWidth,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('comments'),
      fieldName: 'cComments',
      minWidth: props.isExpand ? iconExpandWidth : iconWidth,
      maxWidth: props.isExpand ? iconExpandWidth : iconWidth,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('borTitle'),
      fieldName: 'borTitle',
      minWidth: props.isExpand ? 180 : 200,
      maxWidth: props.isExpand ? 180 : 200,
      isResizable: true,
    },
    {
      key: 'column4',
      name: t('resourceTitle'),
      fieldName: 'cTitle',
      minWidth: props.isExpand ? 200 : 150,
      maxWidth: props.isExpand ? 200 : 150,
      isResizable: true,
    },
    {
      key: 'column5',
      name: t('devices'),
      fieldName: 'cdevices',
      minWidth: props.isExpand ? 60 : 70,
      maxWidth: props.isExpand ? 60 : 70,
      isResizable: true,
    },
    {
      key: 'column6',
      name: t('startDate'),
      fieldName: 'cStartDate',
      minWidth: props.isExpand ? 90 : 70,
      maxWidth: props.isExpand ? 90 : 70,
      isResizable: true,
    },
    {
      key: 'column7',
      name: t('stopDate'),
      fieldName: 'cStopDate',
      minWidth: props.isExpand ? 90 : 70,
      maxWidth: props.isExpand ? 90 : 70,
      isResizable: true,
    },
    {
      key: 'column8',
      name: t('noDays'),
      fieldName: 'cNumberOfDate',
      minWidth: props.isExpand ? 80 : 70,
      maxWidth: props.isExpand ? 80 : 70,
      isResizable: true,
    },
    {
      key: 'column9',
      name: t('quantity'),
      fieldName: 'cQuantity',
      minWidth: props.isExpand ? 60 : 70,
      maxWidth: props.isExpand ? 60 : 70,
      isResizable: true,
    },
    {
      key: 'column10',
      name: t('mou'),
      fieldName: 'cMou',
      minWidth: props.isExpand ? 60 : 70,
      maxWidth: props.isExpand ? 60 : 70,
      isResizable: true,
    },
    {
      key: 'column11',
      name: t('unitPrice'),
      fieldName: 'cUnitPrice',
      minWidth: props.isExpand ? 100 : 150,
      maxWidth: props.isExpand ? 100 : 150,
      isResizable: true,
    },
    {
      key: 'column12',
      name: t('totalPrice'),
      fieldName: 'cTotalPrice',
      minWidth: props.isExpand ? 140 : 100,
      maxWidth: props.isExpand ? 140 : 100,
      isResizable: true,
    },
    // {
    //     key: 'column13',
    //     name: t('requestedDeliveryDate'),
    //     fieldName: 'requestedDate',
    //     minWidth: props.isExpand ? 160 : 120,
    //     maxWidth: props.isExpand ? 160 : 120,
    //     isResizable: true
    // }
  ];

  const consumableColumns = [
    {
      key: 'column1',
      name: t('document'),
      fieldName: 'pDocuments',
      minWidth: props.isExpand ? iconExpandWidth : iconWidth,
      maxWidth: props.isExpand ? iconExpandWidth : iconWidth,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('comment'),
      fieldName: 'pComments',
      minWidth: props.isExpand ? iconExpandWidth : iconWidth,
      maxWidth: props.isExpand ? iconExpandWidth : iconWidth,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('borTitle'),
      fieldName: 'borTitle',
      minWidth: props.isExpand ? 300 : 100,
      maxWidth: props.isExpand ? 300 : 100,
      isResizable: true,
    },
    {
      key: 'column4',
      name: t('resourceTitle'),
      fieldName: 'pTitle',
      minWidth: props.isExpand ? 400 : 200,
      maxWidth: props.isExpand ? 400 : 200,
      isResizable: true,
    },
    {
      key: 'column5',
      name: t('quantity'),
      fieldName: 'pQuantity',
      minWidth: props.isExpand ? 100 : 70,
      maxWidth: props.isExpand ? 100 : 70,
      isResizable: true,
    },
    {
      key: 'column6',
      name: t('mou'),
      fieldName: 'pMou',
      minWidth: props.isExpand ? 100 : 70,
      maxWidth: props.isExpand ? 100 : 70,
      isResizable: true,
    },
    {
      key: 'column7',
      name: t('unitPrice'),
      fieldName: 'pUnitPrice',
      minWidth: props.isExpand ? 80 : 100,
      maxWidth: props.isExpand ? 80 : 100,
      isResizable: true,
    },
    {
      key: 'column8',
      name: t('totalPrice'),
      fieldName: 'pTotalPrice',
      minWidth: props.isExpand ? 140 : 100,
      maxWidth: props.isExpand ? 140 : 100,
      isResizable: true,
    },
    // {
    //     key: 'column9',
    //     name: t('requestedDeliveryDate'),
    //     fieldName: 'requestedDate',
    //     minWidth: props.isExpand ? 160 : 120,
    //     maxWidth: props.isExpand ? 160 : 120,
    //     isResizable: true
    // }
  ];

  const consumableCUColumns = [
    {
      key: 'column1',
      name: t('document'),
      fieldName: 'cDocuments',
      minWidth: props.isExpand ? iconExpandWidth : iconWidth,
      maxWidth: props.isExpand ? iconExpandWidth : iconWidth,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('comment'),
      fieldName: 'cComments',
      minWidth: props.isExpand ? iconExpandWidth : iconWidth,
      maxWidth: props.isExpand ? iconExpandWidth : iconWidth,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('borTitle'),
      fieldName: 'borTitle',
      minWidth: props.isExpand ? 300 : 100,
      maxWidth: props.isExpand ? 300 : 100,
      isResizable: true,
    },
    {
      key: 'column4',
      name: t('resourceTitle'),
      fieldName: 'cTitle',
      minWidth: props.isExpand ? 400 : 200,
      maxWidth: props.isExpand ? 400 : 200,
      isResizable: true,
    },
    {
      key: 'column5',
      name: t('quantity'),
      fieldName: 'cQuantity',
      minWidth: props.isExpand ? 100 : 70,
      maxWidth: props.isExpand ? 100 : 70,
      isResizable: true,
    },
    {
      key: 'column6',
      name: t('mou'),
      fieldName: 'cMou',
      minWidth: props.isExpand ? 100 : 70,
      maxWidth: props.isExpand ? 100 : 70,
      isResizable: true,
    },
    {
      key: 'column7',
      name: t('unitPrice'),
      fieldName: 'cUnitPrice',
      minWidth: props.isExpand ? 80 : 100,
      maxWidth: props.isExpand ? 80 : 100,
      isResizable: true,
    },
    {
      key: 'column8',
      name: t('totalPrice'),
      fieldName: 'cTotalPrice',
      minWidth: props.isExpand ? 140 : 100,
      maxWidth: props.isExpand ? 140 : 100,
      isResizable: true,
    },
    // {
    //     key: 'column9',
    //     name: t('requestedDeliveryDate'),
    //     fieldName: 'requestedDate',
    //     minWidth: props.isExpand ? 160 : 120,
    //     maxWidth: props.isExpand ? 160 : 120,
    //     isResizable: true
    // }
  ];


  const labourColumns = [
    {
      key: 'column1',
      name: t('documents'),
      fieldName: 'pDocuments',
      minWidth: props.isExpand ? iconExpandWidth : iconWidth,
      maxWidth: props.isExpand ? iconExpandWidth : iconWidth,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('comments'),
      fieldName: 'pComments',
      minWidth: props.isExpand ? iconExpandWidth : iconWidth,
      maxWidth: props.isExpand ? iconExpandWidth : iconWidth,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('borTitle'),
      fieldName: 'borTitle',
      minWidth: props.isExpand ? 180 : 200,
      maxWidth: props.isExpand ? 180 : 200,
      isResizable: true,
    },
    {
      key: 'column4',
      name: t('resourceTitle'),
      fieldName: 'pTitle',
      minWidth: props.isExpand ? 200 : 150,
      maxWidth: props.isExpand ? 200 : 150,
      isResizable: true,
    },
    {
      key: 'column5',
      name: t('FTE'),
      fieldName: 'pFullTimeEmployee',
      minWidth: props.isExpand ? 60 : 70,
      maxWidth: props.isExpand ? 60 : 70,
      isResizable: true,
    },
    {
      key: 'column6',
      name: t('startDate'),
      fieldName: 'pStartDate',
      minWidth: props.isExpand ? 90 : 70,
      maxWidth: props.isExpand ? 90 : 70,
      isResizable: true,
    },
    {
      key: 'column7',
      name: t('stopDate'),
      fieldName: 'pStopDate',
      minWidth: props.isExpand ? 90 : 70,
      maxWidth: props.isExpand ? 90 : 70,
      isResizable: true,
    },
    {
      key: 'column8',
      name: t('noDays'),
      fieldName: 'pNumberOfDate',
      minWidth: props.isExpand ? 80 : 70,
      maxWidth: props.isExpand ? 80 : 70,
      isResizable: true,
    },
    {
      key: 'column9',
      name: t('quantity'),
      fieldName: 'pQuantity',
      minWidth: props.isExpand ? 60 : 70,
      maxWidth: props.isExpand ? 60 : 70,
      isResizable: true,
    },
    {
      key: 'column10',
      name: t('mou'),
      fieldName: 'pMou',
      minWidth: props.isExpand ? 60 : 70,
      maxWidth: props.isExpand ? 60 : 70,
      isResizable: true,
    },
    {
      key: 'column11',
      name: t('unitPrice'),
      fieldName: 'pUnitPrice',
      minWidth: props.isExpand ? 100 : 150,
      maxWidth: props.isExpand ? 100 : 150,
      isResizable: true,
    },
    {
      key: 'column12',
      name: t('totalPrice'),
      fieldName: 'pTotalPrice',
      minWidth: props.isExpand ? 140 : 100,
      maxWidth: props.isExpand ? 140 : 100,
      isResizable: true,
    },
    // {
    //     key: 'column13',
    //     name: t('requestedDeliveryDate'),
    //     fieldName: 'requestedDate',
    //     minWidth: props.isExpand ? 160 : 120,
    //     maxWidth: props.isExpand ? 160 : 120,
    //     isResizable: true
    // }
  ];

  const labourCUColumns = [
    {
      key: 'column1',
      name: t('documents'),
      fieldName: 'cDocuments',
      minWidth: props.isExpand ? iconExpandWidth : iconWidth,
      maxWidth: props.isExpand ? iconExpandWidth : iconWidth,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('comments'),
      fieldName: 'cComments',
      minWidth: props.isExpand ? iconExpandWidth : iconWidth,
      maxWidth: props.isExpand ? iconExpandWidth : iconWidth,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('borTitle'),
      fieldName: 'borTitle',
      minWidth: props.isExpand ? 180 : 200,
      maxWidth: props.isExpand ? 180 : 200,
      isResizable: true,
    },
    {
      key: 'column4',
      name: t('resourceTitle'),
      fieldName: 'cTitle',
      minWidth: props.isExpand ? 200 : 150,
      maxWidth: props.isExpand ? 200 : 150,
      isResizable: true,
    },
    {
      key: 'column5',
      name: t('FTE'),
      fieldName: 'cFullTimeEmployee',
      minWidth: props.isExpand ? 60 : 70,
      maxWidth: props.isExpand ? 60 : 70,
      isResizable: true,
    },
    {
      key: 'column6',
      name: t('startDate'),
      fieldName: 'cStartDate',
      minWidth: props.isExpand ? 90 : 70,
      maxWidth: props.isExpand ? 90 : 70,
      isResizable: true,
    },
    {
      key: 'column7',
      name: t('stopDate'),
      fieldName: 'cStopDate',
      minWidth: props.isExpand ? 90 : 70,
      maxWidth: props.isExpand ? 90 : 70,
      isResizable: true,
    },
    {
      key: 'column8',
      name: t('noDays'),
      fieldName: 'cNumberOfDate',
      minWidth: props.isExpand ? 80 : 70,
      maxWidth: props.isExpand ? 80 : 70,
      isResizable: true,
    },
    {
      key: 'column9',
      name: t('quantity'),
      fieldName: 'cQuantity',
      minWidth: props.isExpand ? 60 : 70,
      maxWidth: props.isExpand ? 60 : 70,
      isResizable: true,
    },
    {
      key: 'column10',
      name: t('mou'),
      fieldName: 'cMou',
      minWidth: props.isExpand ? 60 : 70,
      maxWidth: props.isExpand ? 60 : 70,
      isResizable: true,
    },
    {
      key: 'column11',
      name: t('unitPrice'),
      fieldName: 'cUnitPrice',
      minWidth: props.isExpand ? 100 : 150,
      maxWidth: props.isExpand ? 100 : 150,
      isResizable: true,
    },
    {
      key: 'column12',
      name: t('totalPrice'),
      fieldName: 'cTotalPrice',
      minWidth: props.isExpand ? 140 : 100,
      maxWidth: props.isExpand ? 140 : 100,
      isResizable: true,
    },
    // {
    //     key: 'column13',
    //     name: t('requestedDeliveryDate'),
    //     fieldName: 'requestedDate',
    //     minWidth: props.isExpand ? 160 : 120,
    //     maxWidth: props.isExpand ? 160 : 120,
    //     isResizable: true
    // }
  ];


  const externalProductColumns = [
    {
      key: 'column1',
      name: t('document'),
      fieldName: 'pDocuments',
      minWidth: props.isExpand ? iconExpandWidth : iconWidth,
      maxWidth: props.isExpand ? iconExpandWidth : iconWidth,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('comment'),
      fieldName: 'pComment',
      minWidth: props.isExpand ? iconExpandWidth : iconWidth,
      maxWidth: props.isExpand ? iconExpandWidth : iconWidth,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('productTitle'),
      fieldName: 'title',
      minWidth: props.isExpand ? 300 : 200,
      maxWidth: props.isExpand ? 300 : 200,
      isResizable: true,
    },
    {
      key: 'column4',
      name: t('totalPrice'),
      fieldName: 'pTotalPrice',
      minWidth: props.isExpand ? 100 : 100,
      maxWidth: props.isExpand ? 100 : 100,
      isResizable: true,
    },
    // {
    //     key: 'column9',
    //     name: t('requestedDeliveryDate'),
    //     fieldName: 'requestedDate',
    //     minWidth: props.isExpand ? 160 : 120,
    //     maxWidth: props.isExpand ? 160 : 120,
    //     isResizable: true
    // }
  ];

  const externalProductCUColumns = [
    {
      key: 'column1',
      name: t('document'),
      fieldName: 'cDocuments',
      minWidth: props.isExpand ? iconExpandWidth : iconWidth,
      maxWidth: props.isExpand ? iconExpandWidth : iconWidth,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('comment'),
      fieldName: 'cComment',
      minWidth: props.isExpand ? iconExpandWidth : iconWidth,
      maxWidth: props.isExpand ? iconExpandWidth : iconWidth,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('productTitle'),
      fieldName: 'title',
      minWidth: props.isExpand ? 300 : 200,
      maxWidth: props.isExpand ? 300 : 200,
      isResizable: true,
    },
    {
      key: 'column4',
      name: t('totalPrice'),
      fieldName: 'cTotalPrice',
      minWidth: props.isExpand ? 100 : 100,
      maxWidth: props.isExpand ? 100 : 100,
      isResizable: true,
    },
    // {
    //     key: 'column9',
    //     name: t('requestedDeliveryDate'),
    //     fieldName: 'requestedDate',
    //     minWidth: props.isExpand ? 160 : 120,
    //     maxWidth: props.isExpand ? 160 : 120,
    //     isResizable: true
    // }
  ];

  const isShowCUlevelData = () => {
    let isCuLevel = false;
    if (!getProject()) {
      isCuLevel = true;
    } else {
      if (!props.poStatusId || (props.poStatusId === IN_DEVELOPMENT_STATUS_ID)) {
        isCuLevel = false;
      } else {
        isCuLevel = true;
      }
    }
    return isCuLevel;
  };


  return (
    <div>
      <div className="proj-detail-block" id="4">
        <div id="po-resources">
          {props.type === RESOURCE_TYPE_ID &&
          <div className={`wrapper-holder ${classNames.wrapper}`}>
            <div>
              <MCDataGrid
                dataList={props.borResources?.materials ? props.borResources?.materials : []}
                title={t('materials')}
                link={'bor-material'}
                id={'4.1'}
                columns={isShowCUlevelData() ? materialsCUColumns : materialsColumns}
                isLoaded={false}
                selection={props.materialSelection}
                setOpenPOResourceModal={() => props.setOpenPOResourceModal()}
                resourceType={RESOURCE_TYPE.MATERIALS}
                isExpand={props.isExpand}
              />
            </div>
            <div className={`wrapper-holder ${classNames.wrapper}`}>
              <TLDataGrid
                dataList={props.borResources?.tools ? props.borResources?.tools : []}
                title={t('tools')}
                link={'bor-tools'}
                id={'4.2'}
                columns={isShowCUlevelData() ? toolsCUColumns : toolsColumns}
                isLoaded={false}
                selection={props.toolSelection}
                setOpenPOResourceModal={() => props.setOpenPOResourceModal()}
                resourceType={RESOURCE_TYPE.TOOLS}
                isExpand={props.isExpand}
              />
            </div>

            <div className={`wrapper-holder ${classNames.wrapper}`}>
              <MCDataGrid
                dataList={props.borResources?.consumable ? props.borResources?.consumable : []}
                title={t('consumables')}
                link={'bor-consumables'}
                id={'4.3'}
                columns={isShowCUlevelData() ? consumableCUColumns : consumableColumns}
                isLoaded={false}
                selection={props.consumableSelection}
                setOpenPOResourceModal={() => props.setOpenPOResourceModal()}
                resourceType={RESOURCE_TYPE.CONSUMABLE}
                isExpand={props.isExpand}
              />
            </div>


            <div className={`wrapper-holder ${classNames.wrapper}`}>
              <TLDataGrid
                dataList={props.borResources?.labour ? props.borResources?.labour : []}
                title={t('otherLabours')}
                link={'bor-labour'}
                id={'4.4'}
                columns={isShowCUlevelData() ? labourCUColumns : labourColumns}
                isLoaded={false}
                selection={props.labourSelection}
                setOpenPOResourceModal={() => props.setOpenPOResourceModal()}
                resourceType={RESOURCE_TYPE.LABOUR}
                isExpand={props.isExpand}
              />
            </div>
          </div>
          }
        </div>
      </div>

      {props.type === PRODUCT_TYPE_ID &&
      <div className="proj-detail-block" id="4">
        <div id="po-external-products">
          <div className={`wrapper-holder ${classNames.wrapper}`} style={{ marginTop: 30 }}>
            {store.getState().po?.formData?.requestType?.key===RFQ_REQUEST?
              <RFQDataGrid
                dataList={props.externalProducts ? props.externalProducts : []}
                title={t('externalProducts')}
                link={'external-products'}
                id={'4'}
                columns={isShowCUlevelData() ? externalProductCUColumns : externalProductColumns}
                isLoaded={false}
                selection={props.labourSelection}
                setOpenPOResourceModal={() => props.setOpenPOResourceModal()}
                resourceType={RESOURCE_TYPE.LABOUR}
                isExpand={props.isExpand}
              />:<EPDataGrid
              dataList={props.externalProducts ? props.externalProducts : []}
              title={t('externalProducts')}
              link={'external-products'}
              id={'4'}
              columns={isShowCUlevelData() ? externalProductCUColumns : externalProductColumns}
              isLoaded={false}
              selection={props.labourSelection}
              setOpenPOResourceModal={() => props.setOpenPOResourceModal()}
              resourceType={RESOURCE_TYPE.LABOUR}
              isExpand={props.isExpand}
            />}
          </div>
        </div>
      </div>
      }

      {props.type === GOODDEERIS_PRODUCT_TYPE_ID &&
      <div className="proj-detail-block" id="4">
        <div id="po-external-products">
          <div className={`wrapper-holder ${classNames.wrapper}`} style={{ marginTop: 30 }}>
            <div className={`wrapper-holder ${classNames.wrapper}`}>
              <div className={`wrapper-holder ${classNames.wrapper}`}>
                <TLDataGrid
                  dataList={props.borResources?.tools ? props.borResources?.tools : []}
                  title={t('tools')}
                  link={'bor-tools'}
                  id={'4.1'}
                  columns={isShowCUlevelData() ? toolsCUColumns : toolsColumns}
                  isLoaded={false}
                  selection={props.toolSelection}
                  setOpenPOResourceModal={() => props.setOpenPOResourceModal()}
                  resourceType={RESOURCE_TYPE.TOOLS}
                  isExpand={props.isExpand}
                />
              </div>
              <div className={`wrapper-holder ${classNames.wrapper}`}>
                <TLDataGrid
                  dataList={props.borResources?.labour ? props.borResources?.labour : []}
                  title={t('otherLabours')}
                  link={'bor-labour'}
                  id={'4.2'}
                  columns={isShowCUlevelData() ? labourCUColumns : labourColumns}
                  isLoaded={false}
                  selection={props.labourSelection}
                  setOpenPOResourceModal={() => props.setOpenPOResourceModal()}
                  resourceType={RESOURCE_TYPE.LABOUR}
                  isExpand={props.isExpand}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  );
};

export default Resources;

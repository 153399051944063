import { ProjectDefinition } from './projectDefinition';
import { History } from './history';

export interface QRCode {
  id: string;
  projectId: string;
  projectDefinition: ProjectDefinition;
  date: string;
  vehicleNo?: string | null;
  location?: string | null;
  personalId?: string | null;
  type: any;
  createdByUserId: string;
  activityTypeId: number;
  activityType: ActivityType;
  vehicleCpcId: string;
  history: History;
  travellerType: string
}

export interface QRCodeLite {
  id: string;
  projectId: string;
  vehicleNo: string;
  location: string;
  type: string | null | undefined;
  date: string;
  history: History;
}

interface ActivityType {
  id: number;
  type: string;
  typeId: number;
  localeCode: string;
  isDeleted: boolean;
}

export const qrCode = {
  id: '',
  projectId: '',
  projectDefinition: {
    id: '',
    description: '',
    projectTypeId: '',

    projectManagementLevelId: '',
    projectToleranceStateId: '',
    sequenceCode: '',
    revisionNumber: 0,
    name: '',
    title: '',
  },
  date: '',
  vehicleNo: null,
  location: '',
  personalId: '',
  type: '',
  createdByUserId: '',
  activityTypeId: -1,
  activityType: {
    id: 0,
    type: '',
    typeId: 0,
    localeCode: '',
    isDeleted: false,
  },
  vehicleCpcId: '',
  history: {
    createdByUser: '',
    createdDateTime: '',
    revisionNumber: 0,
  },
  travellerType: '',
};

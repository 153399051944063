import React from 'react';
import {AzureMap, IAzureMapOptions} from 'react-azure-maps';
import {AuthenticationType} from 'azure-maps-control';
import ENV from "../../../../env.json";

const option: IAzureMapOptions = {
    authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: ENV.MAP_KEY,
    },
    zoom: 6,
    view: "Auto",
};

const MapComponent: React.FC = () => {
    return (
      <div style={{height: '450px'}}>
          <AzureMap options={option}/>
      </div>
    );
};

export default MapComponent;
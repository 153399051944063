export const months:any = {
    'nl' : {
        
            January: "Januari",
            February: "Februari",
            March: "Maart",
            April: "April",
            May: "Mei",
            June: "Juni",
            July: "Juli",
            August: "Augustus",
            September: "September",
            October: "Oktober",
            November: "November",
            December: "December"
          
    },
    'fr' : {
           January:'Janvier',
           February:'Février',
           March:"Mars",
          April:'Avril',   
          May:"Mai",       
         June:'Juin',
          July:'Juillet',
           August:'Août',
          September:'Septembre',
           October:'Octobre',
           November:'Novembre',
           December:'Décembre'
    },
    'tr' : 
    {
        January: "Ocak",
        February: "Şubat",
        March: "Mart",
        April: "Nisan",
        May: "Mayıs",
        June: "Haziran",
        July: "Temmuz",
        August: "Ağustos",
        September: "Eylül",
        October: "Ekim",
        November: "Kasım",
        December: "Aralık"
      }
}
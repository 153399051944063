import {
  DetailsListLayoutMode,
  DetailsRowCheck,
  getTheme,
  IColumn,
  IDetailsRowBaseProps,
  IDetailsRowCheckStyles,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  mergeStyleSets,
  ShimmeredDetailsList,
} from '@fluentui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../theme';
import { CorporateScheduleColumns } from '../tableColumn/corporateScheduleColumns';

const theme = getTheme();

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const removeIcon: IIconProps = {
  iconName: 'Delete',
  styles: iconButtonStyles,
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const CSWeeklyGrid = (props: {
  dataList: any[];
  link: string;
  isLoaded: boolean;
  isExpand: boolean;
  selection: any;
  onChangeStartTime?: (index: number, value: string | number) => void;
  onChangeEndTime?: (index: number, value: string | number) => void;
  disabled: boolean;
  typeId: string;
}) => {
  const { t } = useTranslation();
  const columns = [
    {
      key: 'column1',
      name: t('day'),
      fieldName: 'day',
      minWidth: props.isExpand ? 200 : 150,
      maxWidth: props.isExpand ? 200 : 150,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('startTime'),
      fieldName: 'startTime',
      minWidth: props.isExpand ? 80 : 60,
      maxWidth: props.isExpand ? 80 : 60,
      isResizable: false,
    },
    {
      key: 'column3',
      name: t('endTime'),
      fieldName: 'endTime',
      minWidth: props.isExpand ? 80 : 60,
      maxWidth: props.isExpand ? 80 : 60,
      isResizable: false,
    },
  ];

  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
      minHeight: props.dataList && props.dataList.length > 1 ? 200 : 110,
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
  });

  useEffect(() => {
    changeColor();
  });

  const stackStyles: IStackStyles = { root: { padding: 0 } };
  const stackItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 40,
      alignItems: 'flex-start',
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 15,
    padding: 10,
  };

  const detailsRowCheckStyles: Partial<IDetailsRowCheckStyles> = { root: { visibility: 'hidden' } };

  const _onRenderCheckForFooterRow: IDetailsRowBaseProps['onRenderCheck'] = (
    props,
  ): JSX.Element => {
    return (
      <DetailsRowCheck
        {...props}
        styles={detailsRowCheckStyles}
        selected={true}
      />
    );
  };

  const _onRenderCheckForHeaderRow: IDetailsRowBaseProps['onRenderCheck'] = (
    props,
  ): JSX.Element => {
    return (
      <DetailsRowCheck
        {...props}
        styles={detailsRowCheckStyles}
        selected={true}
      />
    );
  };

  const changeColor = () => {
    const cquantityClass = document.getElementsByClassName('mcomments');
    for (let i = 0; i < cquantityClass.length; i++) {
      const parentElement = cquantityClass[i].parentElement;
      if (parentElement) {
        parentElement.style.borderRight = '1px solid rgb(243, 242, 241)';
      }
    }

    const totalCost1Class = document.getElementsByClassName('mmou');
    for (let i = 0; i < totalCost1Class.length; i++) {
      const parentElement = totalCost1Class[i].parentElement;
      if (parentElement) {
        parentElement.style.borderRight = '1px solid rgb(243, 242, 241)';
      }
    }
  };

  return (
    <div className={classNames.wrapper}>
      <div className={'scrollablePane'}>
        <ShimmeredDetailsList
          items={props.dataList}
          columns={columns}
          setKey="csDays"
          enableShimmer={props.isLoaded}
          layoutMode={DetailsListLayoutMode.justified}
          selectionPreservedOnEmptyClick={true}
          checkboxVisibility={2}
          onRenderItemColumn={(
            item: any,
            index?: number,
            column?: IColumn,
          ) =>
            CorporateScheduleColumns(
              item,
              index,
              column,
              true,
              props.onChangeStartTime,
              props.onChangeEndTime,
            )
          }
          // onRowDidMount={()=>{
          //   changeColor()
          // }}
          selection={props.selection}
          ariaLabelForSelectionColumn="Toggle selection"
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          checkButtonAriaLabel="Row checkbox"
          // onRenderDetailsHeader={(props)=>_onRenderDetailsHeader(props!!)}
        />
      </div>
      <div className={'card-footer'}></div>
    </div>
  );
};

export default CSWeeklyGrid;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import UprinceLogger from '../../../shared/Logger/logger';
import { IColumn, Selection } from '@fluentui/react';
import history from '../../../history';
import ListPaneComponent from './component';
import { withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { readInstructionRegisterDropDownData } from '../../../reducers/instructionRegisterReducer';
import { messageService } from '../../../services/messageService';
import { InstructionRegisterListPaneFilter, InstructionRegister, InstructionRegisterDropDowns, InstructionRegisterListItem, formData, pbsInstructionLink } from '../../../types/instructionRegister';
import { getContractingUnit, getProject } from '../../../shared/util';
import { Icon } from '@fluentui/react';
import { random } from 'lodash';
import { uPrinceTheme } from '../../../../theme';

export interface State {
  redirect: boolean;
  isChange: boolean;
  loading: boolean;
  filter: InstructionRegisterListPaneFilter;
  announcedMessage: string;
  selectedListItemIds: [] | string[];
  selectedListItemSequenceCode: [] | string[];
  formData: InstructionRegister;
  selectedListItems: InstructionRegisterListItem[];
}

export interface Props extends WithTranslation {
  listPaneItems: InstructionRegisterListItem[];
  reloadListPaneItem: () => void;
  handelFilter: (filter: InstructionRegisterListPaneFilter) => void;
  isDataLoaded?: boolean;
  isChange: boolean;
  filter: InstructionRegisterListPaneFilter;
  resourceTypes: [];
  loadMsg: string;
  toleranceStatesFilter: [];
  productStatesFilter: [];
  itemTypesFilter: [];
  handleSelectedListPaneItem: (selectedItemIds: [] | string[]) => void;
  formData: InstructionRegister;
  projectId: string | null;
  irDropDowns: InstructionRegisterDropDowns;
  reSizer:number;
  readInstructionRegisterDropDownData:() => void;
}

class ListPane extends Component<Props, State> {
  private _Selection: Selection;
  private _columns: IColumn[];
  private _columnsReSizer: IColumn[] | undefined;
  private _columnsCu: IColumn[] | undefined;
  subscription: any;
  constructor(props: Props) {
    super(props);
    this._Selection = new Selection({
      onSelectionChanged: () => {
        this.setState(
          {
            selectedListItemIds: this._getSelectedIds(),
            selectedListItemSequenceCode: this._getselectedSequenceCode(),
            selectedListItems: this._getSelectedListItems(),
          },
          this.handleSelectedItem
        );
      },
    });
    this.state = {
      selectedListItems: [],
      selectedListItemIds: [],
      selectedListItemSequenceCode: [],
      redirect: false,
      isChange: false,
      loading: false,
      announcedMessage: '',
      filter: new InstructionRegisterListPaneFilter(),
      formData:formData,
    };

    this._columns = [
      {
        key: 'column1',
        name: this.props.t('instructionTitle'),
        fieldName: 'title',
        minWidth: 165,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      // {
      //   key: 'column2',
      //   name: this.props.t('instructionName'),
      //   fieldName: 'name',
      //   minWidth: 165,
      //   maxWidth: 200,
      //   isResizable: true,
      //   onColumnClick: this._onColumnClick,
      // },
      {
        key: 'column3',
        name: this.props.t('instructionType'),
        fieldName: 'instructionType',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column4',
        name: this.props.t('family'),
        fieldName: 'family',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },

      // {
      //   key: 'column4',
      //   name: this.props.t('links'),
      //   fieldName: 'pbsInstructionLink',
      //   minWidth: 100,
      //   maxWidth: 200,
      //   isResizable: true,
      //   // onColumnClick: this._onColumnClick,
      //   onRender: (item: InstructionRegisterListItem) => {
      //     let array: pbsInstructionLink[] = item && item?.pbsInstructionLink ? item.pbsInstructionLink : [];
      //     return (
      //       <div
      //         style={{
      //           display: 'grid',
      //           gridTemplateColumns: 'repeat(3, 1fr)',
      //           gridGap: '2px',
      //           gridAutoRows: 'minMax(10px, auto)',
      //         }}
      //       >
      //         {array.map((link) => (
  
      //           <div key={(link.id)?link.id:random()}>
      //             <Icon
      //               style={{cursor:'pointer', color: uPrinceTheme.palette.themePrimary,}}
      //               iconName={link.type!!}
      //               key={(link.id)?link.id:random()}
      //               onClick={(event) => {
      //                 event.preventDefault();
      //                 window.open(link.link!!);
      //               }}
      //             />
      //           </div>
      //         ))}
      //       </div>
      //     );
      //   },
      // },
    ];

  }

  // static getDerivedStateFromProps(nextProps: any) {
  //   if (nextProps.isChange) {
  //     nextProps.reloadListPaneItem();
  //   }
  //   return {
  //     loading: false,
  //     filter: nextProps.filter,
  //     formData: nextProps.formData,
  //   };
  // }

  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewIR) {
          this._Selection.selectToKey('', true);
        }
        if (data.data.resetListPaneSelection) {
          this._Selection.setAllSelected(false);
          this.resetSelection();
        }
      }
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.isDataLoaded != this.props.isDataLoaded) {
      if (this.props.isDataLoaded) {
        this.resetSelection();
      }
    }
  }

  handleListPaneItemClick = (id: string) => {
    //this.props.getById(id);
    if (getContractingUnit() && !getProject()) {
      history.push(`/CU/${getContractingUnit()}/instruction-register/${id}`);
    }else{
      history.push(`/CU/${getContractingUnit()}/project/${getProject()}/instruction-register/${id}`);
    }
  };

  handleSelectedItem = () => {
    this.props.handleSelectedListPaneItem(this.state.selectedListItemIds);
    if (this.state.selectedListItemSequenceCode) {
      let lastId = this.state.selectedListItemSequenceCode.pop();
      if (lastId) {
        // this.handleListPaneItemClick(lastId);
        history.push(`/CU/${getContractingUnit()}/project/${getProject()}/instruction-register/${lastId}`);
      }
    }
  };

  handleFilterChange = (filter: InstructionRegisterListPaneFilter) => {
    this.props.handelFilter(filter);
  };

  handleFilterSortChange = () => {
    this.props.handelFilter(this.state.filter);
  };

  resetSelection = () => {
    if (
      this.state.formData &&
      this.state.formData.id &&
      this.props.isDataLoaded
    ) {
      this._Selection.setKeySelected(
        this.state.formData.id,
        true,
        false
      );
    } else {
      this._Selection.selectToKey('', true);
    }
  };

  render() {
    return (
      <div>
        <ListPaneComponent
          listPaneItems={
            this.props.listPaneItems ? this.props.listPaneItems : []
          }
          selection={this._Selection}
          handleListPaneItemClick={(id: string) =>
            this.handleListPaneItemClick(id)
          }
          resetSelection={() => this.resetSelection()}
          formData={this.state.formData}
          columns={(getProject())?(this.props.reSizer===300)?this._columnsReSizer:this._columns:(this.props.reSizer===300)?this._columnsReSizer:this._columnsCu}
          filter={this.props.filter}
          loadMsg={this.props.loadMsg}
          handleFilterChange={(filter: InstructionRegisterListPaneFilter) =>
            this.handleFilterChange(filter)
          }
          isDataLoaded={this.props.isDataLoaded}
          dropDownOptions={this.props.irDropDowns}
        />
      </div>
    );
  }
  private _getSelectedIds() {
    let deleteList: any = [];
    let SelectedItem = this._Selection.getSelection() as InstructionRegisterListItem[];
    if (SelectedItem) {
      deleteList = SelectedItem.map((item: any) => {
        return item ? item.id : null;
      });
    }
    return deleteList;
  }
  private _getselectedSequenceCode() {
    let selectedList: any = [];
    let selectedItem = this._Selection.getSelection() as InstructionRegisterListItem[];
    if (selectedItem) {
      selectedList = selectedItem.map((item: any) => {
        return item ? item.progressStatementId : null;
      });
    }
    return selectedList;
  }

  private _getSelectedListItems = () => {
    let SelectedListItems = this._Selection.getSelection() as InstructionRegisterListItem[];

    return SelectedListItems;
  };

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const newColumns: IColumn[] = this._columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        let filter = this.state.filter;

        let fieldName = currColumn.fieldName;

        let sortby = currColumn.isSortedDescending ? 'desc' : 'asc';
        filter.sorter.attribute = fieldName ? fieldName : null;
        filter.sorter.order = sortby ? sortby : null;
        this.setState(
          {
            filter: filter,
          },
          this.handleFilterSortChange
        );
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    isChange: state.ir.isChange,
    irDropDowns: state.ir.irDropDowns,
  };
};

const mapDispatchToProps = {
  readInstructionRegisterDropDownData
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ListPane))
);

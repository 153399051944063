

import React from 'react';
import {Document, Image, Link, Page, StyleSheet, Text, View} from '@react-pdf/renderer';
import {store} from '../../../../index';
import {formatwithoutTime} from '../../../shared/util';
import { electricalWorks, interiorJourney, movingPeopleOnConstructionSite, personalHygiene, placingBasebardsAndParquet, placingInteriorDoorsAndWindows, placingLoweredCeilings, placingPartitionAndWainscoting, riskActivityData, riskAnalysisPoints, riskDangerFactors, riskNoiseNuisance, riskTableFactor, weightOnHeights } from './pdfContentConstants';
import moment from 'moment';


const isMobile = screen.width < 768;
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  page2Body: {
    padding: 65
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'black',
    fontWeight: 'bold'
  },
  // table
  table: {
    display: 'table',
    width: 'auto',
    // borderStyle: 'solid',
    // borderWidth: 0.5,
    // borderColor: '#bfbfbf',
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    textAlign: 'center',
    padding: 8,
  },
  borderLess: {
    textAlign: 'center',
    padding: 8,
  },
  signatureChiefImage: {
    textAlign:'left',
    justifyContent:'flex-start',
    alignContent: 'flex-start',
    alignSelf:'flex-start',
    alignItems:'flex-start',
    width: '50%',
    marginTop:5
  },
  tick: {
    width: 12,
    height: 12,
    marginRight: 14,
  },
  //
  nestedHeader: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    // borderTopWidth: 1,
    // borderTopColor: '#000',
    // backgroundColor: '#f2f2f2',
    // width: '100%'
  },
  nestedCol: {
    borderRightWidth: 1,
    borderRightColor: '#bebebe',
    // padding: 5,
    fontSize: 10,
    textAlign: 'center',
    width: '20%',
  },
  nestedColLast: {
    borderRightWidth: 0,
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 8
  },
})

// const itemTableWidth = {
//   col: '30%',
//   col2: '25%',
//   col3: '20%',
//   col4: '25%',
//   col5: '20%',
// };

// const backgroundColor = {
//   color1: '#ffffff',
//   color2: '#ffffff',
// };

// const formatDateTimeToLocal = (date: moment.MomentInput) => {
//   const formatted = moment.utc(date).local().format('DD/MM/YYYY');
//   return formatted;
// };

// Table component
const Table = ({ children }: any) => (
  <View style={styles.table}>
    {children}
  </View>
);

// Table row component
const TableRow = ({ children }: any) => (
  <View style={styles.tableRow}>
    {children}
  </View>
);

// Table column component
const TableCol = ({ children, width, border }: any) => (
  <View style={[border ? styles.tableCol : styles.borderLess, { width: width }]}>
    {children}
  </View>
);

const RiskTableFactorsComponent = ({data, title}: any) => {
  return (
    <View style={{marginBottom: 16}} key={title}>
      <Text style={{borderWidth: 1, borderColor: '#000', fontSize: 10, fontWeight: 'bold', textAlign: 'center', padding: 5}}>{title}</Text>
      <Table>
        {/* Table Header */}
        <TableRow>
          <TableCol width="16.66666666666667%" border={true}>
            <Text style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 10 }}>Task - Activity</Text>
          </TableCol>
          <TableCol width="16.66666666666667%" border={true}>
            <Text style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 10 }}>Risk</Text>
          </TableCol>
          <TableCol width="16.66666666666667%" border={true}>
            <Text style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 10 }}>Cause</Text>
          </TableCol>
          <TableCol width="16.66666666666667%" border={true}>
            <Text style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 10, marginBottom: 12 }}>Evaluation</Text>
            <View style={styles.nestedHeader}>
              <View style={styles.nestedCol}>
                <Text>G</Text>
              </View>
              <View style={styles.nestedCol}>
                <Text>P</Text>
              </View>
              <View style={styles.nestedCol}>
                <Text>E</Text>
              </View>
              <View style={[styles.nestedCol, styles.nestedColLast]}>
                <Text>R</Text>
              </View>
            </View>
          </TableCol>
          <TableCol width="16.66666666666667%" border={true}>
            <Text style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 10 }}>Prevention Measures</Text>
          </TableCol>
          <TableCol width="16.66666666666667%" border={true}>
          <Text style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 10, marginBottom: 12 }}></Text>
            <View style={styles.nestedHeader}>
              <View style={styles.nestedCol}>
                <Text>G</Text>
              </View>
              <View style={styles.nestedCol}>
                <Text>P</Text>
              </View>
              <View style={styles.nestedCol}>
                <Text>E</Text>
              </View>
              <View style={[styles.nestedCol, styles.nestedColLast]}>
                <Text>R</Text>
              </View>
            </View>
          </TableCol>
        </TableRow>
        {/* Table Rows */}
        {data.map((item: any) => (
          <TableRow key={item.id}>
            <TableCol width="16.66666666666667%" border={true}>
              <Text style={{ fontSize: 10, textAlign: 'left' }}>{item.riskActive}</Text>
            </TableCol>
            <TableCol width="16.66666666666667%" border={true}>
              <Text style={{ fontSize: 10, textAlign: 'left' }}>{item.risk}</Text>
            </TableCol>
            <TableCol width="16.66666666666667%" border={true}>
              <Text style={{ fontSize: 10 }}>{item.cause}</Text>
            </TableCol>
            <TableCol width="16.66666666666667%" border={true}>
              <View style={styles.nestedHeader}>
                <View style={styles.nestedCol}>
                  <Text style={{ fontSize: 10 }}>{item.evaluation.G}</Text>
                </View>
                <View style={styles.nestedCol}>
                  <Text style={{ fontSize: 10 }}>{item.evaluation.P}</Text>
                </View>
                <View style={styles.nestedCol}>
                  <Text style={{ fontSize: 10 }}>{item.evaluation.E}</Text>
                </View>
                <View style={[styles.nestedCol, styles.nestedColLast]}>
                  <Text style={{ fontSize: 10 }}>{item.evaluation.R}</Text>
                </View>
              </View>
            </TableCol>
            <TableCol width="16.66666666666667%" border={true}>
              {/* <Text style={{ fontSize: 10, textAlign: 'left' }}>{item.prevention}</Text> */}
              {item.prevention.map((e: any, i: number) => {
                return (
                  <Text style={{ fontSize: 10, textAlign: 'left' }} key={`prevention-${i+1}`}>{e}</Text>
                )
              })}
            </TableCol>
            <TableCol width="16.66666666666667%" border={true}>
              <View style={styles.nestedHeader}>
                <View style={styles.nestedCol}>
                  <Text style={{ fontSize: 10 }}>{item.prevEval.G}</Text>
                </View>
                <View style={styles.nestedCol}>
                  <Text style={{ fontSize: 10 }}>{item.prevEval.P}</Text>
                </View>
                <View style={styles.nestedCol}>
                  <Text style={{ fontSize: 10 }}>{item.prevEval.E}</Text>
                </View>
                <View style={[styles.nestedCol, styles.nestedColLast]}>
                  <Text style={{ fontSize: 10 }}>{item.prevEval.R}</Text>
                </View>
              </View>
            </TableCol>
          </TableRow>
        ))}
      </Table>
    </View>
  )
}

const PdfTemplate=(signature:any, fullName:any)=>{
  const {rfqFormData} = store.getState()?.po;

  const formatDateTimeToLocal = (date: moment.MomentInput) => {
    const formatted = moment.utc(date).local().format('DD/MM/YYYY');
    return formatted;
  };

  const supplierContact = rfqFormData?.supplierContact;
  const contractorOrganisation = rfqFormData?.customerOrganisation;
  const contractorVat = supplierContact?.vatCol
  const contractorAddress = rfqFormData?.supplierContact?.cabAddress?.country?.countryName
  const contractManager = supplierContact?.customerContact
  const contractorEmail = supplierContact?.customerEmail
  const productAddress = rfqFormData?.externalProduct?.[0]?.productAddress
  const productCiaw = rfqFormData?.externalProduct?.[0]?.ciaw
  const supplierReference = rfqFormData?.supplierReference
  const productStartDate = rfqFormData?.externalProduct?.[0]?.startDate ? formatDateTimeToLocal(rfqFormData?.externalProduct?.[0]?.startDate) : ""
  const qualityApprover = rfqFormData?.externalProduct?.[0]?.qualityApproverName
  const productTitle = rfqFormData?.externalProduct?.[0]?.name
  const foremanName = rfqFormData?.externalProduct?.[0]?.foremanName
  const foremanContact = rfqFormData?.externalProduct?.[0]?.foremanNumber
  const orderDate = rfqFormData?.deliveryDate ? formatDateTimeToLocal(rfqFormData?.deliveryDate) : ""

  const data = [
    {
      description: "Installation of lab furniture with skirting-board system and ceramic working surface ",
      pq: "1",
      u: "pc",
      up: "100,00 €",
    },
    {
      description: "Installation of water tap + connection to structural water supply pipe ",
      pq: "1",
      u: "pc",
      up: "50,00 €"
    },
    {
      description: "Installation of water tap + connection to structural water supply pipe ",
      pq: "1",
      u: "pc",
      up: "50,00 €"
    },
  ]

  return (
    <Document>
      <Page wrap style={styles.body}>
        <View style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Image style={{width: 120, height: 50}} src={require(`../../../../images/logo.jpg`).default}/>
        </View>
        <Text style={{textAlign: 'center' ,fontSize: 14, fontWeight: 'bold', color: '#000', paddingTop: 10, paddingBottom: 10, paddingLeft: 14, paddingRight: 14}}>
          SUBCONTRACTING AGREEMENT: SPECIAL CONDITIONS
        </Text>
        <Text style={{textAlign: 'center', fontSize: 14, paddingBottom: 9, fontWeight: 'bold'}}>({rfqFormData?.name})</Text>

        <View style={{marginBottom: 16}}>
          <Text style={{fontSize: 10}}>Between:</Text>

          <View style={{marginHorizontal: 0, marginVertical: 10}}>
            <Text style={{fontSize: 10, fontWeight: 'bold'}}>NV POTTEAU LABO SA</Text>
            <Text style={{fontSize: 10}}>Located at Zuidstraat 24-32, B-8501 HEULE, BELGIUM </Text>
            <Text style={{fontSize: 10}}>VAT: BE 0405 360 921,</Text>
          </View>

          <Text style={{fontSize: 10}}>represented by Luc Clinckemaillie, managing director, hereinafter referred to as the “General contractor” with whom can be communicated in the context of the application of this agreement via the following email address: <Link src='info@potteau.com'>info@potteau.com</Link></Text>
        </View>

        <View style={{marginBottom: 16}}>
          <Text style={{fontSize: 10}}>and:</Text>

          <View style={{marginHorizontal: 0, marginVertical: 10}}>
            <Text style={{fontSize: 10, fontWeight: 'bold', color: 'orange'}}>{contractorOrganisation}</Text>
            <Text style={{fontSize: 10}}>Located at <Text style={{color: 'orange'}}>{contractorAddress}</Text></Text>
            <Text style={{fontSize: 10}}>VAT: <Text style={{color: 'orange'}}>{contractorVat}</Text></Text>
          </View>

          <Text style={{fontSize: 10}}>represented by {contractManager}, managing director, hereinafter referred to as the “subcontractor” with whom can be communicated in the context of the application of this agreement via the following email address: <Text style={{color: 'orange'}}>{contractorEmail}</Text></Text>
        </View>

        <Text style={{fontSize: 10, fontWeight: 'bold', marginBottom: 10}}>The following has been agreed:</Text>

        <View style={{marginBottom: 16}}>
          <Text style={{fontSize: 10, fontWeight: 'bold', color: '#000', textDecoration: 'underline', paddingVertical: 14, lineHeight: 1}}>ARTICLE 1: GENERAL SUBCONTRACTING CONDITIONS </Text>

          <Text style={{fontSize: 10, marginBottom: 10}}>This contract is signed as part of the general subcontracting conditions. The parties acknowledge that they are aware of this contract and undertake to comply with its provisions.</Text>
          <Text style={{fontSize: 10}}>The conditions set out below supplement or clarify the general subcontracting conditions and prevail over the general subcontracting conditions.</Text>
        </View>

        <View style={{marginBottom: 16}}>
          <Text style={{fontSize: 10, fontWeight: 'bold', color: '#000', textDecoration: 'underline', paddingVertical: 14, lineHeight: 1}}>ARTICLE 2: DESCRIPTION OF SUBCONTRACTING </Text>

          <Text style={{fontSize: 10, fontWeight: 'bold', paddingVertical: 6}}>2-1 The main contractor entrusts performance of the following work:  </Text>
          <Text style={{fontSize: 10}}>Project <Text style={{color: 'orange'}}>{productTitle}</Text> delivery and installation of lab furniture to the construction site mentioned under 2-2 Construction site. </Text>
        </View>

        <View style={{marginBottom: 16}}>
          <Table>
            {/* Table Header */}
            <TableRow>
              <TableCol width="60%">
                <Text style={{textAlign: 'left', fontSize: 10, fontWeight: 'bold'}}>Description</Text>
              </TableCol>
              <TableCol width="10%">
                <Text style={{textAlign: 'left', fontSize: 10, fontWeight: 'bold'}}>P.Q</Text>
              </TableCol>
              <TableCol width="10%">
                <Text style={{textAlign: 'left', fontSize: 10, fontWeight: 'bold'}}>U</Text>
              </TableCol>
              <TableCol width="20%">
                <Text style={{textAlign: 'left', fontSize: 10, fontWeight: 'bold'}}>U.P</Text>
              </TableCol>
            </TableRow>
            {/* Table Rows */}
            {data.map((item: any, index: number) => (
              <TableRow key={index}>
                <TableCol width="60%" border={true}>
                  <Text style={{textAlign: 'left', fontSize: 10}}>{item.description}</Text>
                </TableCol>
                <TableCol width="10%" border={true}>
                  <Text style={{textAlign: 'left', fontSize: 10}}>{item.pq}</Text>
                </TableCol>
                <TableCol width="10%" border={true}>
                  <Text style={{textAlign: 'left', fontSize: 10}}>{item.u}</Text>
                </TableCol>
                <TableCol width="20%" border={true}>
                  <Text style={{textAlign: 'left', fontSize: 10}}>{item.up}</Text>
                </TableCol>
              </TableRow>
            ))}
          </Table>

          <Text style={{fontSize: 12, marginTop: 12}}>The materials needed will be brought to the right floors by Potteau Labo.</Text>
        </View>

        <View style={{ marginBottom: 10}}>
          <Text style={{fontWeight: 'bold', fontSize: 10, paddingBottom: 4}}>2-2 Construction site</Text>
          <Text style={{fontSize: 10, color: 'orange'}}>{productAddress}</Text>
        </View>

        <View style={{ marginBottom: 10}}>
          <Text style={{fontWeight: 'bold', fontSize: 10, paddingBottom: 4}}>2-3 Principal </Text>
          <Text style={{fontSize: 10, color: 'orange'}}>{`${qualityApprover ? qualityApprover : ""}`}</Text>
        </View>

        <View style={{ marginBottom: 10}}>
          <Text style={{fontWeight: 'bold', fontSize: 10, paddingBottom: 4}}>2-4 NSSO-number construction site / Check-in@Work </Text>
          <Text style={{fontSize: 10, color: 'orange'}}>{productCiaw}</Text>
        </View>

        <View style={{ marginBottom: 10}}>
          <Text style={{fontWeight: 'bold', fontSize: 10, paddingBottom: 4}}>2-5 Order number </Text>
          <Text style={{fontSize: 10, color: 'orange'}}>{supplierReference}</Text>
        </View>

        <View style={{marginBottom: 16}}>
          <Text style={{fontSize: 10, fontWeight: 'bold', color: '#000', textDecoration: 'underline', paddingVertical: 14, lineHeight: 1}}>ARTICLE 3: SCHEDULE AND TURNAROUND TIME </Text>

          <Text style={{fontSize: 10, marginBottom: 10}}>Start of the project: <Text style={{color: 'orange'}}>{productStartDate}</Text> </Text>
          <Text style={{fontSize: 10}}>End of the project: to be agreed </Text>
        </View>

        <View style={{marginBottom: 16}}>
          <Text style={{fontSize: 10, fontWeight: 'bold', color: '#000', textDecoration: 'underline', paddingVertical: 14, lineHeight: 1}}>ARTICLE 4: EXECUTION </Text>

          <Text style={{fontSize: 10, marginBottom: 10}}>The subcontractor employs sufficient and only competent and experienced personnel on the construction site in order to realize the execution within the set terms. The works will be carried out according to the rules of the art, according to the terms and conditions quoted above, and the approved drawings and specifications applicable here. The contractor has the right at any time to stop or restart all work that is not in order, to refuse all materials that do not meet the prescribed quality and to have them removed from the construction site, without the subcontractor being able to refuse the performance of this assignment. or find in it a pretext to justify any delay. The same right will be exercised by the lead architect of the works or by the client's representative. </Text>
        </View>

        <View style={{marginBottom: 16}}>
          <Text style={{fontSize: 10, fontWeight: 'bold', color: '#000', textDecoration: 'underline', paddingVertical: 14, lineHeight: 1}}>ARTICLE 5: PRICE AND PAYMENT FOR SUBCONTRACTING </Text>

          <View style={{marginBottom: 8}}>
            <Text style={{fontWeight: 'bold', fontSize: 10, paddingBottom: 4}}>5-1 Price </Text>
            <Text style={{fontSize: 10}}>The work of this contract is performed at the unit prices mentioned in article 2: Description of subcontracting.</Text>
          </View>

          <View style={{marginBottom: 8}}>
            <Text style={{fontWeight: 'bold', fontSize: 10, paddingBottom: 4}}>5-2 Invoicing and payment methods </Text>
            <Text style={{fontSize: 10}}>Please make sure your invoices are mentioning the following mandatory information: </Text>
            <View style={{marginLeft: 20}}>
              <Text style={{fontSize: 10}}>-    Our purchase order number + date of any modifications </Text>
              <Text style={{fontSize: 10}}>-    Our file number </Text>
              <Text style={{fontSize: 10}}>-    Name and location of the construction site </Text>
              <Text style={{fontSize: 10}}>-    Work description </Text>
              <Text style={{fontSize: 10}}>-    Period for performing the work</Text>
            </View>

            <Text style={{fontSize: 10}}>Only invoices sent by e-mail to accounting@potteau.com will be accepted. </Text>
          </View>
        </View>

        <Text style={{fontSize: 10, marginBottom: 16}}>The invoices are paid within 14 days, only after receipt of all the signed and completed “contractual” documents (general and special subcontracting conditions, safety documents, all requested documents referred to in article 9, §9-5, and any other appendices). </Text>
        <Text style={{fontSize: 10, marginBottom: 16}}>Irrespective of any developments or discussions (including any disputes relating to an invoicing note, the price supplement for modifications or an invoice or payment of an invoice), the subcontractor undertakes to perform the work entrusted to them in accordance with this contract and in compliance with the agreed turnaround times. </Text>
        
        <Text style={{fontSize: 10, marginBottom: 16}}>Refer to the special subcontracting conditions for additional provisions concerning payment. </Text>

        <View style={{marginBottom: 16}}>
          <Text style={{fontSize: 10, fontWeight: 'bold', color: '#000', textDecoration: 'underline', paddingVertical: 14, lineHeight: 1}}>ARTICLE 6: GUARANTEE </Text>
          <Text style={{fontSize: 10}}>No guarantee has to be paid.  </Text>
        </View>

        <View style={{marginBottom: 16}}>
          <Text style={{fontSize: 10, fontWeight: 'bold', color: '#000', textDecoration: 'underline', paddingVertical: 14, lineHeight: 1}}>ARTICLE 7: MAINTENANCE CONSTRUCTION SITE </Text>
          <Text style={{fontSize: 10}}>All waste and packaging material resulting from the work carried out by the subcontractor will be regularly cleared and removed from the site by the care and/or at the expense of the subcontractor. If the subcontractor fails to do so, the contractor will make arrangements to ensure, after contracting, the clearance at the expense of the subcontractor. </Text>
        </View>

        <View style={{marginBottom: 16}}>
          <Text style={{fontSize: 10, fontWeight: 'bold', color: '#000', textDecoration: 'underline', paddingVertical: 14, lineHeight: 1}}>ARTICLE 8: PRO-RATA ACCOUNT </Text>
          <Text style={{fontSize: 10}}>Non applicable. </Text>
        </View>

        <View style={{marginBottom: 16}}>
          <Text style={{fontSize: 10, fontWeight: 'bold', color: '#000', textDecoration: 'underline', paddingVertical: 14, lineHeight: 1}}>ARTICLE 9: DESIGNATION OF A PERSON IN CHARGE OF COMMUNICATION, A (SITE) MANAGER AND A TEAM LEADER </Text>
          <Text style={{fontSize: 10, paddingBottom: 7}}>The subcontractor (or his authorized representative accepted by the contractor) commits himself to always be present at the site meetings and at all implementation meetings to which he is invited, or when his presence on the construction site is necessary for the proper course of events. </Text>

          <Text style={{fontSize: 10, paddingBottom: 7}}>The subcontractor designates a person in charge of communication. This person shall handle communication between the subcontractor and the main contractor.  </Text>
          <Text style={{fontSize: 10, paddingBottom: 7}}>The subcontractor shall ensure that at least 1 managerial worker or employee is always available to occupy the position of (site) manager. This person shall have a sufficient level of Dutch, French or English, both written and oral and has a sufficient knowledge of the legal and statutory prevention obligations.</Text>
          <Text style={{fontSize: 10, paddingBottom: 7}}>This (site) manager shall be at least qualified as a foreman and proficient enough to direct all the work and all the subcontractor’s workers or employees. They shall be responsible for giving all the instructions necessary for performing the mission successfully. </Text>
          <Text style={{fontSize: 10, paddingBottom: 7}}>Regarding safety and good communication in the workplace, the subcontractor shall ensure that this (site) manager has a sufficient knowledge of all the legal and statutory prevention obligations. </Text>
          <Text style={{fontSize: 10, paddingBottom: 7}}>In addition to this (site) manager, each team shall include a team leader who shall follow all communication on the worksite with the (site) manager </Text>

        </View>

        <Text style={{marginBottom: 16, fontSize: 10}}>Please inform the main contractor of the following data: </Text>

        <View style={{marginBottom: 16}}>
          <Text style={{fontSize: 10, paddingBottom: 8}}>Communication partner / team leader on the construction site </Text>
          <Text style={{fontSize: 10, paddingBottom: 8}}>Foreman Name: <Text style={{color: 'orange'}}>{foremanName}</Text></Text>
          <Text style={{fontSize: 10}}>Foreman Telephone number: <Text style={{color: 'orange'}}>{foremanContact}</Text></Text>
        </View>

        <View style={{marginBottom: 16}}>
          <Text style={{fontSize: 10, fontWeight: 'bold', color: '#000', textDecoration: 'underline', paddingVertical: 14, lineHeight: 1}}>ARTICLE 10: REGULATIONS OF THE COUNTRY WHERE THE WORKS ARE CARRIED OUT </Text>
          <Text style={{fontSize: 10}}>The subcontractor is expected to comply with all legislation and formalities that apply in the country where the subcontractor carries out the work. The main contractor cannot be held liable for failure to comply with these obligations. All costs involved will be recovered from the subcontractor. </Text>
        </View>

        <View style={{marginBottom: 16}}>
          <Text style={{fontSize: 10, fontWeight: 'bold', color: '#000', textDecoration: 'underline', paddingVertical: 14, lineHeight: 1}}>ARTICLE 11: COMPENSATION FOR NON-COMPLIANCE WITH THE ORDER TO START, THE DEADLINE SET AND THE SCHEDULE</Text>
          <Text style={{fontSize: 10, paddingVertical: 14}}>If the subcontractor does not complete their work within the agreed deadline, they automatically owe a fine to the main contractor as lump-sum compensation without a formal notice being required, simply because the deadline has been exceeded. The amount per calendar day of delay is set at € ……....... </Text>
          <Text style={{fontSize: 10, paddingVertical: 14}}>If the subcontractor’s delay impacts the turnaround time available to the main contractor, the fine amount due by the subcontractor to the main contractor is automatically increased to the fine amount that the main contractor shall owe their principal without a formal notice being required. </Text>
          <Text style={{fontSize: 10, paddingVertical: 14}}>The above-mentioned fines also apply in the event of non-compliant or defective performance leading to repair work. </Text>
          <Text style={{fontSize: 10, paddingVertical: 14}}>When the work must be performed in several implementation phases, the deadline for each individual phase must be met. Exceeding the deadline for an individual implementation phase also involves the application of fines.</Text>
          <Text style={{fontSize: 10, paddingVertical: 14}}>In all cases, the main contractor reserves the right to claim damages /full compensation for their actual damage instead of applying the above-mentioned lump-sum fines. This damage can be proven by any legal means. </Text>
          <Text style={{fontSize: 10}}>If a price revision has been agreed, it shall no longer apply after expiry of the contractual period considering any time extension(s) granted. </Text>
        </View>

        <View style={{marginBottom: 16}}>
          <Text style={{fontSize: 10, fontWeight: 'bold', color: '#000', textDecoration: 'underline', paddingVertical: 14, lineHeight: 1}}>ARTICLE 12: MISCELLANEOUS </Text>
          <Text style={{fontSize: 10}}>The subcontractor is not authorized to have all or part of the work accepted by him carried out by a third subcontractor. The subcontractor is obliged to provide the contractor before the start of the work: passport – Limosa L1 receipt – secondment form A1 – residence documents. </Text>
        </View>

        <View style={{marginBottom: 16}}>
          <Text style={{fontSize: 10, fontWeight: 'bold', color: '#000', textDecoration: 'underline', paddingVertical: 14, lineHeight: 1}}>ARTICLE 13: ADDITIONAL DOCUMENTS </Text>
          <Text style={{fontSize: 10, paddingBottom: 10}}>The following documents have been submitted by the main contractor to the subcontractor who acknowledges that they have received them</Text>

          <Text style={{fontSize: 10, paddingBottom: 14}}>In the event of inconsistencies between the documents specified below and the general and special subcontracting conditions, the general and special subcontracting conditions always prevail in accordance with the priority rule as laid down in Article 1 of the special subcontracting conditions. </Text>
          
          <Text style={{fontSize: 10}}>1.    Declaration on the joint and several liability of the general contractor for the payment of remuneration (page 6) </Text>
          <Text style={{fontSize: 10}}>2.    Last Minute Risk Analysis (page 7-13) </Text>
        </View>

        
      </Page>
      <Page wrap style={styles.body}>
        <View style={{marginBottom: 16}}>
          <Text style={{fontSize: 10, fontWeight: 'bold', color: '#000', textDecoration: 'underline', paddingVertical: 14, lineHeight: 1}}>ARTICLE 14: SIGNING OF THE CONTRACT </Text>
          <Text style={{fontSize: 10, paddingBottom: 14}}>The subcontractor must return the signed contract to the main contractor within 7 calendar days and no later than the date of starting work. </Text>
          <Text style={{fontSize: 10, paddingBottom: 14}}>Failing that, when the subcontractor starts the work, they are considered as having expressly accepted the contractual conditions laid down in this contract before starting the work. </Text>
          <Text style={{fontSize: 10, paddingBottom: 14}}>Drawn up in Heule on <Text style={{color: 'orange'}}>{orderDate}</Text> </Text>

          <Text style={{fontSize: 10}}>The general contractor:	</Text>
          <Image
            style={styles.signatureChiefImage}
            src={`https://uprincev4dev.blob.core.windows.net/uprincev4dev/careyeChiefSignature.png`}
          />

          <View style={{display: 'flex', flexDirection: 'column'}}>
            <Text style={{fontSize: 10, paddingVertical: 16}}>The subcontractor: </Text>
            <Image src={signature} style={{width: 100, height: 100, marginLeft: 24}}/>
            <Text style={{fontSize: 10, paddingVertical: 8}}>{fullName}</Text>
          </View>
        </View>
      </Page>
      <Page wrap style={styles.page2Body}>
        <View style={{paddingHorizontal: 24, paddingBottom: 16}}>
          <Text style={{fontSize: 10, textAlign: 'center'}}>ADDITIONAL DOCUMENT 1: </Text>
          <Text style={{fontSize: 10, textAlign: 'center'}}>DECLARATION ON THE JOINT AND SEVERAL LIABILITY OF THE DIRECT CONTRACTOR FOR THE PAYMENT OF REMUNERATION </Text>
        </View>
        <View style={{marginBottom: 10, borderWidth: 1, borderColor: '#000', borderStyle: 'solid'}}></View>
        <View style={{marginBottom: 16}}>
          <Text style={{fontSize: 10, paddingBottom: 10}}>In accordance with Article 35/6/3, § 2 of the Act of 12 April 1965 on the protection of workers' pay, inserted by the Act of 11 December 2016 laying down various provisions on the posting of workers, the undersigned prime contractor and subcontractor hereby declare that the prime contractor is relieved of joint and several liability for the payment of remuneration owed to workers employed by the subcontractor for work carried out by said workers on behalf of the prime contractor:</Text>
          <View style={{ flexDirection: 'row', marginBottom: 8 }}>
            <Text style={{ fontSize: 12, marginRight: 10 }}>•</Text>
            <Text style={{fontSize: 10}}>`{'The prime contractor declares that he has informed the subcontractor that information about applicable rates of remuneration can be found on the following website: http://www.emploi.belgique.be (under "Concertation sociale" > "Salaires minimum" (in French or Dutch) and, specifically for foreign subcontractors, under "Posting" > "Working conditions" (in English)). The subcontractor confirms that he has been informed by the prime contractor of the website containing information about applicable rates of remuneration. '}`</Text>
          </View>

          <View style={{ flexDirection: 'row', marginBottom: 8 }}>
            <Text style={{ fontSize: 12, marginRight: 10 }}>•</Text>
            <Text style={{fontSize: 10}}>The subcontractor certifies that he is meeting and will continue to meet his obligation to pay the remuneration owed to his workers on time. </Text>
          </View>

          <Text style={{fontSize: 10}}>Drawn up in Heule on <Text style={{color: 'orange'}}>{orderDate}</Text></Text>
        </View>

        <View>
          <Text style={{fontSize: 10}}>The general contractor:	</Text>
          <Image
            style={styles.signatureChiefImage}
            src={`https://uprincev4dev.blob.core.windows.net/uprincev4dev/careyeChiefSignature.png`}
          />

          <View style={{display: 'flex', flexDirection: 'column'}}>
            <Text style={{fontSize: 10, paddingVertical: 16}}>The subcontractor: </Text>
            <Image src={signature} style={{width: 100, height: 100, marginLeft: 24}}/>
            <Text style={{fontSize: 10, paddingVertical: 8}}>{fullName}</Text>
          </View>
        </View>
      </Page>
      <Page wrap style={styles.page2Body}>
        <View style={{paddingHorizontal: 24, paddingBottom: 16}}>
          <Text style={{fontSize: 10, textAlign: 'center'}}>ADDITIONAL DOCUMENT 2: </Text>
          <Text style={{fontSize: 10, textAlign: 'center'}}>LAST MINUTE RISK ANALYSIS (LMRA) </Text>
        </View>
        <View style={{marginBottom: 10, borderWidth: 1, borderColor: '#000', borderStyle: 'solid'}}></View>

        <View style={{paddingBottom: 10}}>
          <Text style={{fontSize: 10, marginBottom: 10}}>In order to be able to follow the steps of the LMRA process correctly, you have to ask yourself the following questions:</Text>
          <View style={styles.list}>
          <Text style={{fontSize: 12, color: '#000'}}>✓</Text>
            {riskAnalysisPoints?.map((item, index) => (
              <View key={index} style={{ flexDirection: 'row' }}>
                {/* <TickIcon /> */}
                <Text style={{fontSize: 10}}>{item}</Text>
              </View>
            ))}
          </View>
        </View>

        <View style={{borderWidth: 1, borderColor: '#000', borderStyle: 'solid', marginBottom: 10}}></View>
        <View>
          <Text style={{fontSize: 10, marginBottom: 10}}>Risk = R = P x E x G</Text>

          <View style={{paddingBottom: 10}}>
            <TableRow>
              <TableCol width="33.33%">
                <Text style={{textAlign: 'left', fontSize: 10, fontWeight: 'bold'}}>P = Probability </Text>
              </TableCol>
              <TableCol width="33.33%">
                <Text style={{textAlign: 'left', fontSize: 10, fontWeight: 'bold'}}>E = Exposure </Text>
              </TableCol>
              <TableCol width="33.33%">
                <Text style={{textAlign: 'left', fontSize: 10, fontWeight: 'bold'}}>G = Gravity </Text>
              </TableCol>
            </TableRow>
            {riskTableFactor.map((item) => (
              <TableRow key={item.id}>
                <TableCol width="33.33%" border={false}>
                  <Text style={{textAlign: 'left', fontSize: 10}}>{item.probability}</Text>
                </TableCol>
                <TableCol width="33.33%" border={false}>
                  <Text style={{textAlign: 'left', fontSize: 10}}>{item.exposure}</Text>
                </TableCol>
                <TableCol width="33.33%" border={false}>
                  <Text style={{textAlign: 'left', fontSize: 10}}>{item.gravity}</Text>
                </TableCol>
                
              </TableRow>
            ))}
          </View>

          <View style={{borderWidth: 1, borderColor: '#000', borderStyle: 'solid', marginBottom: 10}}></View>
          <View>
            <Image src={require('../../../../images/riskFactor.jpg').default} style={{ width: 100, height: 200}}/>
            <View style={{paddingBottom: 10, paddingTop: 10}}>
              <Text style={{fontSize: 10}}><Text style={{color: 'red', fontWeight: 'bold'}}>RATE</Text> the risk</Text>
              <View style={{display: 'flex', flexDirection: 'row', marginTop: 5}}> 
                {/* <ArrowIcon/> */}
                <Text style={{fontSize: 10}}>Don’t begin to work when, in your opinion, the risks of the work are unacceptable.</Text>
              </View>
            </View>

            <View style={{paddingBottom: 10}}>
              <Text style={{fontSize: 10}}><Text style={{color: 'orange', fontWeight: 'bold'}}>MEASURES </Text>to reduce the risk</Text>
              <View style={{display: 'flex', flexDirection: 'row', marginTop: 5}}> 
                {/* <ArrowIcon/> */}
                <Text style={{fontSize: 10}}> Decide which measures have to be made to eliminate the risk or make the risk acceptable. </Text>
              </View>
            </View>

            <View style={{paddingBottom: 10}}>
              <Text style={{fontSize: 10}}><Text style={{color: 'green', fontWeight: 'bold'}}>ACT </Text>to make a good execution of your work possible </Text>
              <View style={{display: 'flex', flexDirection: 'row', marginTop: 5}}> 
                {/* <ArrowIcon/> */}
                <Text style={{fontSize: 10}}>Implement the measures to make a safe execution of the job possible. Ask for help if needed! </Text>
              </View>
            </View>
          </View>

          <View style={{padding: 10, marginTop: 10, borderWidth: 1, borderColor: '#000', borderStyle: 'solid'}}>
            <Text style={{fontSize: 10, fontWeight: 'bold', textAlign: 'center'}}>WHEN IN DOUBT, DONT START AND CONSULT YOUR SUPERVISOR </Text>
            <Text style={{fontSize: 10, textAlign: 'center'}}>*** For everyone at any time of the day *** </Text>
          </View>
        </View>
      </Page>

      <Page size={'A3'} style={[styles.body, {display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}]}>
        <View style={{flex: 1}}>
          <View style={{width: '100%', paddingBottom: 16, display: 'flex', justifyContent: 'center', alignItems: 'center'}} fixed>
            <Image style={{width: 120, height: 50}} src={require(`../../../../images/logo.jpg`).default}/>
          </View>
          {/* RISK ANALYSIS   :   STUMBLING - FALLING - BUMPING */}
          <RiskTableFactorsComponent data={riskActivityData} title={'RISK ANALYSIS   :   STUMBLING - FALLING - BUMPING'}/>

          {/* RISK ANALYSIS   :   NOISE NUISANCE  */}
          <RiskTableFactorsComponent data={riskNoiseNuisance} title={'RISK ANALYSIS   :   NOISE NUISANCE'}/>
        
          {/* RISK ANALYSIS   :   DANGEROUS PRODUCTS – MATERIALS – GASES  */}
          <RiskTableFactorsComponent data={riskDangerFactors} title={'RISK ANALYSIS   :   DANGEROUS PRODUCTS – MATERIALS – GASES'}/>

          {/* RISK ANALYSIS   :   PERSONAL HYGIENE  */}
          <RiskTableFactorsComponent data={personalHygiene} title={'RISK ANALYSIS   :    PERSONAL HYGIENE '}/>
        
          {/* RISK ANALYSIS   :   MOVING PEOPLE ON CONSTRUCTION SITE  */}
          <RiskTableFactorsComponent data={movingPeopleOnConstructionSite} title={'RISK ANALYSIS   :    MOVING PEOPLE ON CONSTRUCTION SITE'}/>
        
          {/* RISK ANALYSIS   :   PLACING INTERIOR DOORS AND - WINDOWS  */}
          <RiskTableFactorsComponent data={placingInteriorDoorsAndWindows} title={'RISK ANALYSIS   :   PLACING INTERIOR DOORS AND - WINDOWS'}/>
          
          {/* RISK ANALYSIS   :   WORKING ON HEIGHTS */}
          <RiskTableFactorsComponent data={weightOnHeights} title={'RISK ANALYSIS   :   WORKING ON HEIGHTS'}/>
        
          {/* RISK ANALYSIS   :   PLACING PARTITION AND WAINSCOTING  */}
          <RiskTableFactorsComponent data={placingPartitionAndWainscoting} title={'RISK ANALYSIS   :   PLACING PARTITION AND WAINSCOTING'}/>
        
          {/* RISK ANALYSIS   :   INTERIOR JOINERY  */}
          <RiskTableFactorsComponent data={interiorJourney} title={'RISK ANALYSIS   :   INTERIOR JOINERY'}/>

          {/* RISK ANALYSIS   :   PLACING LOWERED CEILINGS   */}
          <RiskTableFactorsComponent data={placingLoweredCeilings} title={'RISK ANALYSIS   :   PLACING LOWERED CEILINGS'}/>

          {/* RISK ANALYSIS   :   PLACING BASEBOARDS AND PARQUET    */}
          <RiskTableFactorsComponent data={placingBasebardsAndParquet} title={'RISK ANALYSIS   :   PLACING BASEBOARDS AND PARQUET '}/>

          {/* RISK ANALYSIS   :   ELECTRICAL WORKS   */}
          <RiskTableFactorsComponent data={electricalWorks} title={'RISK ANALYSIS   :   ELECTRICAL WORKS'}/>
        </View>

        {/* <Text style={{fontSize: 10, fontWeight: 'bold', marginBottom: 12}}>The subcontractor ensures that its employees are aware of these safety regulations and that they are complied with. </Text>
        <View>
          <Text style={{fontSize: 10}}>The general contractor:	</Text>
          <Image
            style={styles.signatureChiefImage}
            src={require(`https://uprincev4dev.blob.core.windows.net/uprincev4dev/careyeChiefSignature.png`).default}
          />

          <View style={{display: 'flex', flexDirection: 'row'}}>
            <Text style={{fontSize: 10, paddingVertical: 16}}>The subcontractor: </Text>
            <Image src={signature} style={{width: 100, height: 100, marginLeft: 24}}/>
          </View>
        </View>
        <View style={styles.footer} fixed>
          <Image style={{width: 500, height: 70}} src={require('../../../../images/footer.png').default}/>
        </View> */}
        <View style={styles.footer} fixed>
          <Image style={{width: 500, height: 70}} src={require('../../../../images/footer.png').default}/>
        </View>
      </Page>
      <Page wrap style={styles.body}>
        <Text style={{fontSize: 10, fontWeight: 'bold', marginBottom: 12}}>The subcontractor ensures that its employees are aware of these safety regulations and that they are complied with. </Text>
        <View>
          <Text style={{fontSize: 10}}>The general contractor:	</Text>
          <Image
            style={styles.signatureChiefImage}
            src={`https://uprincev4dev.blob.core.windows.net/uprincev4dev/careyeChiefSignature.png`}
          />

          <View style={{display: 'flex', flexDirection: 'column'}}>
            <Text style={{fontSize: 10, paddingVertical: 16}}>The subcontractor: </Text>
            <Image src={signature} style={{width: 100, height: 100, marginLeft: 24}}/>
            <Text style={{fontSize: 10, paddingVertical: 8}}>{fullName}</Text>
          </View>
        </View>
        <View style={styles.footer} fixed>
          <Image style={{width: 500, height: 70}} src={require('../../../../images/footer.png').default}/>
        </View>
      </Page>
    </Document>
  )
}

// const getFooterText=()=>{
//   const language =  window.navigator.language;
//   if(language){
//     switch (language) {
//       case "en-US":
//         return "I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial";
//       case "en":
//         return "I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial";
//       case "nl":
//         return "I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial nl";
//       case "nl-BE":
//         return "I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial nl";
//       case "de":
//         return "I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial nl";
//       default:
//         return "I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial nl";
//     }

//   }else{
//     return "I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial";
//   }
// };

export default PdfTemplate
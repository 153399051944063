import React from 'react';
import {
  DetailsListLayoutMode,
  IImageProps,
  Image,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  registerIcons,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import i18n from '../../../i18n';
import {SvgIcons} from '../../shared/svgIcons/svgIcons';
import TextEditor from '../../shared/textEditor/textEditor';
import {store} from '../../../index';
import ENV from '../../../env.json';
import {formatPrice, formatwithoutTime} from '../../shared/util';
import RfqDocumentSign from './rfqDocumentSign';
import {uPrinceTheme} from '../../../theme';
import DisabledTextField from "../../shared/mobileComponents/disabledTextField";

registerIcons(SvgIcons);

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 0,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const imageProps: Partial<IImageProps> = {
  width: '40%',
};

const styles = {
  textField: {
    fontSize: 30,
    height: 65,
    color: uPrinceTheme.palette.themePrimary,
  },

  textFieldLabel: {
    marginRight: 5,
    fontSize: 30,
    paddingBottom: 10,
    fontWeight: 600,
  },

  textFieldDiv: {
    height: 130,
  },

  textDisabled:{
    fontSize: 30,
    height: 65,
    width: '100%',
    color: uPrinceTheme.palette.themePrimary,
    background: 'rgb(247, 247, 247)',
    display: 'flex',
    alignItems: 'center',
  }

};

const columns = [
  {
    key: 'column1',
    name: i18n.t('productTitle'),
    fieldName: 'title',
    isResizable: true,
    onRender: (item: any) => {
        return <span
          style={{
            display: 'block',
            textAlign: 'left',
          }}
        >
            {item.title}
        </span>
    },
    onRenderHeader: () => {
      return <span
        style={{
          display: 'block',
          textAlign: 'left',
        }}
      >
        {i18n.t('productTitle')}
      </span>
    }
  },
  {
    key: 'column2',
    name: i18n.t('unitPrice') + ' (m'+'²)',
    fieldName: 'cUnitPrice',
    isResizable: true,
    minWidth: 190,
    className: 'totalAmountCol',
    onRender: (item: any) => {
      return <div>
        {item?.cUnitPrice && <span
          style={{
            fontWeight: 'bold',
            paddingRight: 20,
          }}
        >{formatPrice(item?.cUnitPrice)}</span>}
      </div>;
    },
    onRenderHeader: () => {
      return <span
        style={{
          paddingLeft: 10,
        }}
      >
        {i18n.t('unitPrice') + ' (m'+'²)'}
      </span>
    }
  },
];

const RfqMobileView = () => {
  // const dispatch = useDispatch();
  const { rfqFormData } = store.getState()?.po;
  const supplierContact = rfqFormData?.supplierContact;

  const classNames = mergeStyleSets({

    fullWidth: { width: '100%' },
    halfWidth: { width: '49%' },

    body: {
      height: '100%', minHeight: '100vh', backgroundColor: '#e9e9e9', width: '100%',
    },
    navBar: {
      height: 40, width: '100%',
    },
    container: {
      height: 'auto', minHeight: 'calc(100vh - 142px)', width: '60%', margin: 'auto', padding: 10,
    },
    logo: {
      display: 'flex',
      backgroundColor: '#ffffff',
      paddingBottom: 25,
    },
    logoImg: {
      marginTop: '20px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    wrapper: {
      // height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
      minHeight: (rfqFormData.externalProduct && rfqFormData.externalProduct.length > 1) ? 200 : 110,
    },
  });

  return (
    <div>

      {/*Logo*/}
      <div style={{ width: 'auto', marginBottom: '10px' }} className={'document-pane-card'}>
        <div className={classNames.logo}>
          <Image
            {...imageProps}
            className={classNames.logoImg}
            src={
              ENV.IMG_PDF
                ? ENV.IMG_PDF
                : ''
            }
            style={{ marginRight: 'auto', marginLeft: 'auto' }}
            alt='Example of the image fit value "centerContain" on an image wider than the frame.'
          />
        </div>
      </div>

      {/*Header*/}
      <div style={{ width: 'auto', height: '1100px' }} className={'document-pane-card'}>

        <div className={'card-header'}>
          <Link href='#' id='invoice-invoice-amount'>
            <Label style={styles.textFieldLabel}>1. {i18n.t('rfqHeader')} </Label>
          </Link>
        </div>

        <div>

          <div className='marginTop'>
            <Stack
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <div style={styles.textFieldDiv} className={`${classNames.fullWidth}`}>
                <DisabledTextField
                  label={i18n.t('rfqTitle')}
                  value={rfqFormData?.title ?? ''}
                />
                {/*<TextField*/}
                {/*  value={rfqFormData?.title ? rfqFormData?.title : ''}*/}
                {/*  disabled={true}*/}
                {/*  style={styles.textField}*/}
                {/*  onRenderLabel={() => {*/}
                {/*    return (*/}
                {/*      <div style={styles.textFieldLabel}>{i18n.t('rfqTitle')}</div>*/}
                {/*    );*/}
                {/*  }}*/}
                {/*  onRenderInput={() => {*/}
                {/*    return (*/}
                {/*      <div style={styles.textDisabled}>{rfqFormData?.title ? `${rfqFormData?.title}` : ''}</div>*/}
                {/*    );*/}
                {/*  }}*/}
                {/*/>*/}
              </div>

              <div style={styles.textFieldDiv} className={`${classNames.fullWidth}`}>
                <DisabledTextField
                  label={i18n.t('rfqId')}
                  value={rfqFormData?.sequenceId ?? ''}
                />
                {/*<TextField*/}
                {/*  value={rfqFormData?.sequenceId ? rfqFormData?.sequenceId : ''}*/}
                {/*  disabled={true}*/}
                {/*  style={styles.textField}*/}
                {/*  onRenderLabel={() => {*/}
                {/*    return (*/}
                {/*      <div style={styles.textFieldLabel}>{i18n.t('rfqId')}</div>*/}
                {/*    );*/}
                {/*  }}*/}
                {/*/>*/}
              </div>

              <div style={styles.textFieldDiv} className={`${classNames.fullWidth}`}>
                <DisabledTextField
                  label={i18n.t('rfqName')}
                  value={rfqFormData?.name ?? ''}
                />
                {/*<TextField*/}
                {/*  value={rfqFormData?.name ? rfqFormData?.name : ''}*/}
                {/*  label={i18n.t('rfqName')}*/}
                {/*  disabled={true}*/}
                {/*  style={styles.textField}*/}
                {/*  onRenderLabel={() => {*/}
                {/*    return (*/}
                {/*      <div style={styles.textFieldLabel}>{i18n.t('rfqName')}</div>*/}
                {/*    );*/}
                {/*  }}*/}
                {/*/>*/}
              </div>

              <div style={styles.textFieldDiv} className={`${classNames.fullWidth}`}>
                <DisabledTextField
                  label={i18n.t('rfqType')}
                  value={rfqFormData?.type ? rfqFormData?.type?.text : ''}
                />
                {/*<TextField*/}
                {/*  value={rfqFormData?.type ? rfqFormData?.type?.text : ''}*/}
                {/*  label={i18n.t('rfqType')}*/}
                {/*  disabled={true}*/}
                {/*  style={styles.textField}*/}
                {/*  onRenderLabel={() => {*/}
                {/*    return (*/}
                {/*      <div style={styles.textFieldLabel}>{i18n.t('rfqType')}</div>*/}
                {/*    );*/}
                {/*  }}*/}
                {/*/>*/}
              </div>

              <div style={styles.textFieldDiv} className={`${classNames.fullWidth}`}>
                <DisabledTextField
                  label={i18n.t('rfqStatus')}
                  value={rfqFormData?.status ? rfqFormData?.status?.text : ''}
                />
                {/*<TextField*/}
                {/*  value={rfqFormData?.status ? rfqFormData?.status?.text : ''}*/}
                {/*  label={i18n.t('rfqStatus')}*/}
                {/*  disabled={true}*/}
                {/*  style={styles.textField}*/}
                {/*  onRenderLabel={() => {*/}
                {/*    return (*/}
                {/*      <div style={styles.textFieldLabel}>{i18n.t('rfqStatus')}</div>*/}
                {/*    );*/}
                {/*  }}*/}
                {/*/>*/}
              </div>
            </Stack>
          </div>

          <div style={styles.textFieldDiv} className={`${classNames.fullWidth}`}>

            <Label style={{ fontSize: 30, height: 65, paddingLeft: 10 }}>{i18n.t('comments')} </Label>

            <TextEditor
              value={rfqFormData?.comments ? rfqFormData?.comments : ''}
              onChange={() => {
              }}
              readOnly={true}
            />
          </div>

        </div>
      </div>

      {/*Supplier*/}
      <div style={{ width: 'auto', height: '800px' }} className={'document-pane-card'}>

        <div className={'card-header'}>
          <Link href='#' id='invoice-invoice-amount'>
            <Label style={styles.textFieldLabel}>2. {i18n.t('rfqSupplier')} </Label>
          </Link>
        </div>

        <div>

          <div className='marginTop'>
            <Stack
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <div style={styles.textFieldDiv} className={`${classNames.fullWidth}`}>
                <DisabledTextField
                  label={i18n.t('supplierOrganisation')}
                  value={rfqFormData?.supplierOrganisation ?? ''}
                />
                {/*<TextField*/}
                {/*  value={rfqFormData?.supplierOrganisation ? rfqFormData?.supplierOrganisation : ''}*/}
                {/*  label={i18n.t('supplierOrganisation')}*/}
                {/*  disabled={true}*/}
                {/*  style={styles.textField}*/}
                {/*  onRenderLabel={() => {*/}
                {/*    return (*/}
                {/*      <div style={styles.textFieldLabel}>{i18n.t('supplierOrganisation')}</div>*/}
                {/*    );*/}
                {/*  }}*/}
                {/*/>*/}
              </div>

              <div style={styles.textFieldDiv} className={`${classNames.fullWidth}`}>
                <DisabledTextField
                  label={i18n.t('supplierContact')}
                  value={supplierContact?.customerContact ?? ''}
                />
                {/*<TextField*/}
                {/*  value={supplierContact?.customerContact ? supplierContact?.customerContact : ''}*/}
                {/*  label={i18n.t('supplierContact')}*/}
                {/*  disabled={true}*/}
                {/*  style={styles.textField}*/}
                {/*  onRenderLabel={() => {*/}
                {/*    return (*/}
                {/*      <div style={styles.textFieldLabel}>{i18n.t('supplierContact')}</div>*/}
                {/*    );*/}
                {/*  }}*/}
                {/*/>*/}
              </div>

              <div style={styles.textFieldDiv} className={`${classNames.fullWidth}`}>
                <DisabledTextField
                  label={i18n.t('supplierMobileNumber')}
                  value={supplierContact?.customerMobile ?? ''}
                />
                {/*<TextField*/}
                {/*  value={supplierContact?.customerMobile ? supplierContact?.customerMobile : ''}*/}
                {/*  label={i18n.t('supplierMobileNumber')}*/}
                {/*  disabled={true}*/}
                {/*  style={styles.textField}*/}
                {/*  onRenderLabel={() => {*/}
                {/*    return (*/}
                {/*      <div style={styles.textFieldLabel}>{i18n.t('supplierMobileNumber')}</div>*/}
                {/*    );*/}
                {/*  }}*/}
                {/*/>*/}
              </div>

              <div style={styles.textFieldDiv} className={`${classNames.fullWidth}`}>
                <DisabledTextField
                  label={i18n.t('supplierEmail')}
                  value={supplierContact?.customerEmail ?? ''}
                />
                {/*<TextField*/}
                {/*  value={supplierContact?.customerEmail ? supplierContact?.customerEmail : ''}*/}
                {/*  label={i18n.t('supplierEmail')}*/}
                {/*  disabled={true}*/}
                {/*  style={styles.textField}*/}
                {/*  onRenderLabel={() => {*/}
                {/*    return (*/}
                {/*      <div style={styles.textFieldLabel}>{i18n.t('supplierEmail')}</div>*/}
                {/*    );*/}
                {/*  }}*/}
                {/*/>*/}
              </div>

              <div style={styles.textFieldDiv} className={`${classNames.fullWidth}`}>
                <DisabledTextField
                  label={i18n.t('supplierReference')}
                  value={rfqFormData?.supplierReference ?? ''}
                />
                {/*<TextField*/}
                {/*  value={rfqFormData?.supplierReference ? rfqFormData?.supplierReference : ''}*/}
                {/*  label={i18n.t('supplierReference')}*/}
                {/*  disabled={true}*/}
                {/*  style={styles.textField}*/}
                {/*  onRenderLabel={() => {*/}
                {/*    return (*/}
                {/*      <div style={styles.textFieldLabel}>{i18n.t('supplierReference')}</div>*/}
                {/*    );*/}
                {/*  }}*/}
                {/*/>*/}
              </div>
            </Stack>
          </div>

        </div>
      </div>

      {/*Delivery Details*/}
      <div style={{ width: 'auto', height: '400px' }} className={'document-pane-card'}>

        <div className={'card-header'}>
          <Link href='#' id='invoice-invoice-amount'>
            <Label style={styles.textFieldLabel}>3. {i18n.t('deliveryDetails')} </Label>
          </Link>
        </div>

        <div>

          <div className='marginTop'>
            <Stack
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <div style={styles.textFieldDiv} className={`${classNames.fullWidth}`}>
                <DisabledTextField
                  label={i18n.t('deliveryAddress')}
                  value={rfqFormData?.mapLocation ? rfqFormData?.mapLocation?.address?.freeformAddress : ''}
                />
                {/*<TextField*/}
                {/*  value={rfqFormData?.mapLocation ? rfqFormData?.mapLocation?.address?.freeformAddress : ''}*/}
                {/*  label={i18n.t('deliveryAddress')}*/}
                {/*  disabled={true}*/}
                {/*  style={styles.textField}*/}
                {/*  onRenderLabel={() => {*/}
                {/*    return (*/}
                {/*      <div style={styles.textFieldLabel}>{i18n.t('deliveryAddress')}</div>*/}
                {/*    );*/}
                {/*  }}*/}
                {/*/>*/}
              </div>

              <div style={styles.textFieldDiv} className={`${classNames.fullWidth}`}>
                <DisabledTextField
                  label={i18n.t('requestedDate')}
                  value={rfqFormData?.deliveryDate ? formatwithoutTime(rfqFormData?.deliveryDate) : ''}
                />
                {/*<TextField*/}
                {/*  value={rfqFormData?.deliveryDate ? formatwithoutTime(rfqFormData?.deliveryDate) : ''}*/}
                {/*  label={i18n.t('requestedDate')}*/}
                {/*  disabled={true}*/}
                {/*  style={styles.textField}*/}
                {/*  onRenderLabel={() => {*/}
                {/*    return (*/}
                {/*      <div style={styles.textFieldLabel}>{i18n.t('requestedDate')}</div>*/}
                {/*    );*/}
                {/*  }}*/}
                {/*/>*/}
              </div>
            </Stack>
          </div>

        </div>
      </div>

      {/*Items List*/}
      <div style={{ width: 'auto', height: '200px' }} className={'document-pane-card'}>

        <div className={'card-header'}>
          <Link href='#' id='invoice-invoice-amount'>
            <Label style={styles.textFieldLabel}>4. {i18n.t('requestedProductDetail')} </Label>
          </Link>
        </div>

        <div>

          <div className='marginTop'>
            <Stack
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <div className={'rfq-mobile-list'}>
                <ShimmeredDetailsList
                  items={rfqFormData.externalProduct ? rfqFormData.externalProduct : []}
                  columns={columns as any}
                  setKey='set'
                  enableShimmer={false}
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionPreservedOnEmptyClick={true}
                  checkboxVisibility={1}
                  selectionMode={SelectionMode.none}
                  ariaLabelForSelectionColumn='Toggle selection'
                  ariaLabelForSelectAllCheckbox='Toggle selection for all items'
                  checkButtonAriaLabel='Row checkbox'
                />
              </div>
            </Stack>
          </div>

        </div>
      </div>

      {/*Signature*/}
      <div style={{ width: 'auto', height: '1000px' }} className={'document-pane-card'}>
        <div className={'card-header'}>
          <Link href='#' id='invoice-invoice-amount'>
            <Label style={styles.textFieldLabel}>5. {i18n.t('signature')} </Label>
          </Link>
        </div>
        <RfqDocumentSign />
      </div>
      {/*{JSON.stringify(rfqFormData)}*/}
    </div>
  );
};

export default RfqMobileView;

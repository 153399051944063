import { Label, Link } from '@fluentui/react';
import React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { saveContractStateAttr, saveFormData } from '../../../../reducers/contractReducer';
import DataGridWithModal, { ModalGridContext } from '../../../../shared/dataGridModal/component';
import { documentationColumns } from '../../../../types/contract';
import DocumentationModalContent from './modal/component';

const LotDocumentationCard = (props: {
  documentation: any,
  disabled: boolean,
  onChange: any,
  footer: any,
  readOnly: boolean,
  values?: any,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { projectScopeStatus, projectCurrency }: any = useSelector((state: any) => state.project);

  const onSubmit = async (values: any) => {
    const sleep = (ms: number | undefined) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);
    props.onChange(values);
  };

  const getInitialValues = (values: any) => {
    return {
      ...values,
      // technicalDocumentation: {
      //     typeId: values.technicalDocumentation.type.key,
      //     typeName: values.technicalDocumentation.type.text,
      // }
    };
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={getInitialValues(props.documentation)}
      render={({ values }) => (
        // <form onSubmit={ handleSubmit }>
        <div>
          <FormSpy
            onChange={(spyProps: any) => {
              if (props.documentation !== spyProps.values && props.onChange) {
                props.onChange(spyProps.values);
              }
            }}
          />
          <div className="document-pane-card">
            <div className={'card-header'}>
              <Link href="#" id="documentation">
                <Label>4. {t('documentation')}</Label>
              </Link>
            </div>

            {/* <Label className={'sub-header-title'} >{5.1 + ' ' + t('projectFinanceStatus')}</Label> */}
            <Field name="tenderDocuments">
              {({ input }) => (
                <DataGridWithModal
                  dataList={values?.tenderDocuments && Array.isArray(values.tenderDocuments) ? values.tenderDocuments : []}
                  modalTitle={t('tenderDocuments')}
                  deleteDataGrid={async (selection: any) => {
                    // let technicalDocumentation = [];
                    const selectedList = selection.map((item: any) => {
                      return item;
                    });
                    const currentList: any[] = values.tenderDocuments;

                    const filtered: any[] = currentList.filter((e: any) => {
                      return selectedList.indexOf(e) < 0;
                    });
                    // technicalDocumentation = { ...values.technicalDocumentation, tenderDocuments: filtered };
                    input.onChange(filtered);
                    const lotDocumentation: any[] = { ...props.values.lotDocumentation, tenderDocuments: filtered };
                    const formData: any[] = { ...props.values, lotDocumentation: lotDocumentation };
                    dispatch(saveFormData(formData));
                  }}
                  title={`4.1 ${t('tenderDocuments')}`}
                  readOnly={props.readOnly}
                  keyProp={'id'}
                  columns={documentationColumns()}
                  isLoaded={false}
                  onDismiss={() => dispatch(saveContractStateAttr('uploadedInstructionLink', null))}
                >
                  <ModalGridContext.Consumer>
                    {(modalProps: any) => (
                      <DocumentationModalContent
                        documentCategory={'tenderDocuments'}
                        documentation={modalProps.editItem}
                        disabled={false}
                        onChange={(item: any) => {
                          if (values && values?.tenderDocuments && Array.isArray(values?.tenderDocuments)) {
                            const tenderDocuments: any[] = [];
                            const currentList: any[] = values.tenderDocuments;
                            let valueList = [];
                            if (item.lotId) {
                              const index = currentList.findIndex(object => {
                                return object.id === item.id;
                              });
                              if (index < currentList.length) {
                                currentList[index] = item;
                              }
                              valueList = currentList;
                              input.onChange(valueList);
                              modalProps.updateEditItem(item);
                            } else {
                              tenderDocuments.push(item);
                              currentList.forEach((listItems: any) => {
                                tenderDocuments.push(listItems);
                              });
                              valueList = tenderDocuments;
                              input.onChange(valueList);
                            }
                            const lotDocumentation: any[] = {
                              ...props.values.lotDocumentation,
                              tenderDocuments: valueList,
                            };
                            const formData: any[] = { ...props.values, lotDocumentation: lotDocumentation };
                            if (props.values.id) {
                              dispatch(saveFormData(formData));
                            }
                          }
                          modalProps.closeModal();
                        }}
                      />
                    )}
                  </ModalGridContext.Consumer>
                </DataGridWithModal>
              )}
            </Field>
            <Field name="technicalInstructions">
              {({ input }) => (
                <DataGridWithModal
                  dataList={values?.technicalInstructions && Array.isArray(values.technicalInstructions) ? values.technicalInstructions : []}
                  modalTitle={t('technicalInstructions')}
                  deleteDataGrid={async (selection: any) => {
                    // let technicalDocumentation = [];
                    const selectedList = selection.map((item: any) => {
                      return item;
                    });
                    const currentList: any[] = values.technicalInstructions;

                    const filtered: any[] = currentList.filter((e: any) => {
                      return selectedList.indexOf(e) < 0;
                    });
                    // technicalDocumentation = { ...values.technicalDocumentation, technicalInstructions: filtered };
                    input.onChange(filtered);
                    const lotDocumentation: any[] = {
                      ...props.values.lotDocumentation,
                      technicalInstructions: filtered,
                    };
                    const formData: any[] = { ...props.values, lotDocumentation: lotDocumentation };
                    dispatch(saveFormData(formData));
                  }}
                  title={`4.2 ${t('technicalInstructions')}`}
                  readOnly={props.readOnly}
                  keyProp={'id'}
                  columns={documentationColumns()}
                  isLoaded={false}
                  onDismiss={() => dispatch(saveContractStateAttr('uploadedInstructionLink', null))}
                >
                  <ModalGridContext.Consumer>
                    {(modalProps: any) => (
                      <DocumentationModalContent
                        documentCategory={'tenderDocuments'} // Uses the same document category as tenderDocuments
                        documentation={modalProps.editItem}
                        disabled={false}
                        onChange={(item: any) => {
                          if (values && values.technicalInstructions && Array.isArray(values.technicalInstructions)) {
                            const technicalInstructions: any[] = [];
                            const currentList: any[] = values.technicalInstructions;
                            let valueList = [];
                            if (item.lotId) {
                              const index = currentList.findIndex(object => {
                                return object.id === item.id;
                              });
                              if (index < currentList.length) {
                                currentList[index] = item;
                              }
                              valueList = currentList;
                              input.onChange(valueList);
                              modalProps.updateEditItem(item);
                            } else {
                              technicalInstructions.push(item);
                              currentList.forEach((listItems: any) => {
                                technicalInstructions.push(listItems);
                              });
                              valueList = technicalInstructions;
                              input.onChange(valueList);
                            }
                            const lotDocumentation: any[] = {
                              ...props.values.lotDocumentation,
                              technicalInstructions: valueList,
                            };
                            const formData: any[] = { ...props.values, lotDocumentation: lotDocumentation };
                            if (props.values.id) {
                              dispatch(saveFormData(formData));
                            }
                          }
                          modalProps.closeModal();
                        }}
                      />
                    )}
                  </ModalGridContext.Consumer>
                </DataGridWithModal>
              )}
            </Field>
            <Field name="provisionalAcceptance">
              {({ input }) => (
                <DataGridWithModal
                  dataList={values?.provisionalAcceptance && Array.isArray(values.provisionalAcceptance) ? values.provisionalAcceptance : []}
                  modalTitle={t('provisionalAcceptance')}
                  deleteDataGrid={async (selection: any) => {
                    // let technicalDocumentation = [];
                    const selectedList = selection.map((item: any) => {
                      return item;
                    });
                    const currentList: any[] = values.provisionalAcceptance;

                    const filtered: any[] = currentList.filter((e: any) => {
                      return selectedList.indexOf(e) < 0;
                    });
                    // technicalDocumentation = { ...values.technicalDocumentation, provisionalAcceptance: filtered };
                    input.onChange(filtered);
                    const lotDocumentation: any[] = {
                      ...props.values.lotDocumentation,
                      provisionalAcceptance: filtered,
                    };
                    const formData: any[] = { ...props.values, lotDocumentation: lotDocumentation };
                    dispatch(saveFormData(formData));
                  }}
                  title={`4.3 ${t('provisionalAcceptance')}`}
                  readOnly={props.readOnly}
                  keyProp={'id'}
                  columns={documentationColumns()}
                  isLoaded={false}
                  onDismiss={() => dispatch(saveContractStateAttr('uploadedInstructionLink', null))}
                >
                  <ModalGridContext.Consumer>
                    {(modalProps: any) => (
                      <DocumentationModalContent
                        documentCategory={'provisionalAcceptance'}
                        documentation={modalProps.editItem}
                        disabled={false}
                        onChange={(item: any) => {
                          if (values && values?.provisionalAcceptance && Array.isArray(values?.provisionalAcceptance)) {
                            const provisionalAcceptance: any[] = [];
                            const currentList: any[] = values.provisionalAcceptance;
                            let valueList = [];
                            if (item.lotId) {
                              const index = currentList.findIndex(object => {
                                return object.id === item.id;
                              });
                              if (index < currentList.length) {
                                currentList[index] = item;
                              }
                              valueList = currentList;
                              input.onChange(valueList);
                              modalProps.updateEditItem(item);
                            } else {
                              provisionalAcceptance.push(item);
                              currentList.forEach((listItems: any) => {
                                provisionalAcceptance.push(listItems);
                              });
                              valueList = provisionalAcceptance;
                              input.onChange(valueList);
                            }
                            const lotDocumentation: any[] = {
                              ...props.values.lotDocumentation,
                              provisionalAcceptance: valueList,
                            };
                            const formData: any[] = { ...props.values, lotDocumentation: lotDocumentation };
                            if (props.values.id) {
                              dispatch(saveFormData(formData));
                            }
                          }
                          modalProps.closeModal();
                        }}
                      />
                    )}
                  </ModalGridContext.Consumer>
                </DataGridWithModal>
              )}
            </Field>
            <Field name="finalDelivery">
              {({ input }) => (
                <DataGridWithModal
                  dataList={values?.finalDelivery && Array.isArray(values.finalDelivery) ? values.finalDelivery : []}
                  modalTitle={t('finalDelivery')}
                  deleteDataGrid={async (selection: any) => {
                    // let technicalDocumentation = [];
                    const selectedList = selection.map((item: any) => {
                      return item;
                    });
                    const currentList: any[] = values.finalDelivery;

                    const filtered: any[] = currentList.filter((e: any) => {
                      return selectedList.indexOf(e) < 0;
                    });
                    // technicalDocumentation = { ...values.technicalDocumentation, finalDelivery: filtered };
                    input.onChange(filtered);
                    const lotDocumentation: any[] = { ...props.values.lotDocumentation, finalDelivery: filtered };
                    const formData: any[] = { ...props.values, lotDocumentation: lotDocumentation };
                    dispatch(saveFormData(formData));
                  }}
                  title={`4.4 ${t('finalDelivery')}`}
                  readOnly={props.readOnly}
                  keyProp={'id'}
                  columns={documentationColumns()}
                  isLoaded={false}
                  onDismiss={() => dispatch(saveContractStateAttr('uploadedInstructionLink', null))}
                >
                  <ModalGridContext.Consumer>
                    {(modalProps: any) => (
                      <DocumentationModalContent
                        documentCategory={'finalDelivery'}
                        documentation={modalProps.editItem}
                        disabled={false}
                        onChange={(item: any) => {
                          if (values && values?.finalDelivery && Array.isArray(values?.finalDelivery)) {
                            const finalDelivery: any[] = [];
                            const currentList: any[] = values.finalDelivery;
                            let valueList = [];
                            if (item.lotId) {
                              const index = currentList.findIndex(object => {
                                return object.id === item.id;
                              });
                              if (index < currentList.length) {
                                currentList[index] = item;
                              }
                              valueList = currentList;
                              input.onChange(valueList);
                              modalProps.updateEditItem(item);
                            } else {
                              finalDelivery.push(item);
                              currentList.forEach((listItems: any) => {
                                finalDelivery.push(listItems);
                              });
                              valueList = finalDelivery;
                              input.onChange(valueList);
                            }
                            const lotDocumentation: any[] = {
                              ...props.values.lotDocumentation,
                              finalDelivery: valueList,
                            };
                            const formData: any[] = { ...props.values, lotDocumentation: lotDocumentation };
                            if (props.values.id) {
                              dispatch(saveFormData(formData));
                            }
                          }
                          modalProps.closeModal();
                        }}
                      />
                    )}
                  </ModalGridContext.Consumer>
                </DataGridWithModal>
              )}
            </Field>
            {props.footer}
          </div>
        </div>
      )}
    />
  );
};

export default LotDocumentationCard;

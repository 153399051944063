import * as React from 'react';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { hiddenContentStyle, mergeStyles } from '@fluentui/react/lib/Styling';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { useTranslation } from 'react-i18next';
import { Checkbox, Label, Stack } from '@fluentui/react';
import { stackTokens } from '../customStyle/fluentStyles';
import { useSelector } from 'react-redux';

const dialogStyles = { main: { maxWidth: 450 } };
const dragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const screenReaderOnly = mergeStyles(hiddenContentStyle);

const DeleteConfirmationDialogWithData: React.FunctionComponent<any> = ({
                                                                          hidden,
                                                                          onClickCancelDialog,
                                                                          onClickConfirmDialog,
                                                                          title,
                                                                          subText,
                                                                          cancelButtonText,
                                                                          confirmButtonText,
                                                                          relationsData,
                                                                          itemType,
                                                                        }) => {
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
  const labelId: string = useId('dialogLabel');
  const subTextId: string = useId('subTextLabel');

  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
      dragOptions: isDraggable ? dragOptions : undefined,
    }),
    [isDraggable],
  );
  const { t } = useTranslation();
  const dialogContentProps = {
    type: DialogType.normal,
    title: title,
    closeButtonAriaLabel: 'Close',
    subText: subText,
    styles: { subText: { marginBottom: 10 }, title: { paddingBottom: 10 } },
  };

  const loading: any = useSelector((state: any) => state.projectBreakdown.loading);

  return (
    <>
      <Dialog
        hidden={hidden}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
        styles={{ root: { padding: 0, margin: 0 } }}
      >
        <Stack style={{ padding: 0, marginBottom: 35 }} tokens={stackTokens}>
          {itemType == "cpc" ?  <Label>{t('thisCpcIs')}</Label> : <Label>{t('thisPbsIs')}</Label>}
          <Checkbox
            boxSide={'end'}
            label={t('borDependant')}
            disabled
            checked={relationsData?.isBorDependent === true}
            styles={{ text: { width: 130 } }}
          />
          <Checkbox
            boxSide={'end'}
            label={t('midTermDependant')}
            disabled
            checked={relationsData?.isMidTermDependent === true}
            styles={{ text: { width: 130 } }}
          />
        </Stack>

        <DialogFooter>
          <DefaultButton
            onClick={onClickCancelDialog}
            text={cancelButtonText ? cancelButtonText : t('Cancel')}
          />
          <PrimaryButton
            onClick={onClickConfirmDialog}
            text={confirmButtonText ? confirmButtonText : t('OK')}
            disabled={relationsData?.isBorDependent === true || relationsData?.isMidTermDependent === true || loading}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default DeleteConfirmationDialogWithData;

export const SAVE_WBS_ISSUE_STATE_ATTR = 'uprice/SAVE_WBS_ISSUE_STATE_ATTR';

export const READ_WBS_ISSUE = 'uprice/READ_WBS_ISSUE';
export const READ_WBS_ISSUE_SUCCESS = 'uprice/READ_WBS_ISSUE_SUCCESS';
export const READ_WBS_ISSUE_FAIL = 'uprice/READ_WBS_ISSUE_FAIL';

export const GET_WBS_ISSUE_BY_ID = 'uprice/GET_WBS_ISSUE_BY_ID';
export const GET_WBS_ISSUE_BY_ID_SUCCESS = 'uprice/GET_WBS_ISSUE_BY_ID_SUCCESS';
export const GET_WBS_ISSUE_BY_ID_FAIL = 'uprice/GET_WBS_ISSUE_BY_ID_FAIL';

export const GET_WBS_ISSUE_DROPDOWN_DATA = 'uprice/GET_WBS_ISSUE_DROPDOWN_DATA';
export const GET_WBS_ISSUE_DROPDOWN_DATA_SUCCESS = 'uprice/GET_WBS_ISSUE_DROPDOWN_DATA_SUCCESS';
export const GET_WBS_ISSUE_DROPDOWN_DATA_FAIL = 'uprice/GET_WBS_ISSUE_DROPDOWN_DATA_FAIL';

export const SAVE_WBS_ISSUE = 'uprice/SAVE_WBS_ISSUE';
export const SAVE_WBS_ISSUE_SUCCESS = 'uprice/SAVE_WBS_ISSUE_SUCCESS';
export const SAVE_WBS_ISSUE_FAIL = 'uprice/SAVE_WBS_ISSUE_FAIL';

export const LOAD_PURCHASE_ORDER_SHORTCUTPANE = 'uprince/LOAD_PURCHASE_ORDER_SHORTCUTPANE';
export const LOAD_PURCHASE_ORDER_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_PURCHASE_ORDER_SHORTCUTPANE_SUCCESS';
export const LOAD_PURCHASE_ORDER_SHORTCUTPANE_FAIL = 'uprince/LOAD_PURCHASE_ORDER_SHORTCUTPANE_FAIL';

export const LOAD_PURCHASE_ORDER_LSITPANE = 'uprince/LOAD_PURCHASE_ORDER_LSITPANE';
export const LOAD_PURCHASE_ORDER_LSITPANE_SUCCESS = 'uprince/LOAD_PURCHASE_ORDER_LSITPANE_SUCCESS';
export const LOAD_PURCHASE_ORDER_LSITPANE_FAIL = 'uprince/LOAD_PURCHASE_ORDER_LSITPANE_FAIL';

export const LOAD_PURCHASE_ORDER_DROPDOWN = 'uprince/LOAD_PURCHASE_ORDER_DROPDOWN';
export const LOAD_PURCHASE_ORDER_DROPDOWN_SUCCESS = 'uprince/LOAD_PURCHASE_ORDER_DROPDOWN_SUCCESS';
export const LOAD_PURCHASE_ORDER_DROPDOWN_FAIL = 'uprince/LOAD_PURCHASE_ORDER_DROPDOWN_FAIL';

export const SAVE_PURCHASE_ORDER = 'uprince/SAVE_PURCHASE_ORDER';
export const SAVE_PURCHASE_ORDER_SUCCESS = 'uprince/SAVE_PURCHASE_ORDER_SUCCESS';
export const SAVE_PURCHASE_ORDER_FAIL = 'uprince/SAVE_PURCHASE_ORDER_FAIL';

export const AUTO_SAVE_PURCHASE_ORDER = 'uprince/AUTO_SAVE_PURCHASE_ORDER';
export const AUTO_SAVE_PURCHASE_ORDER_SUCCESS = 'uprince/AUTO_SAVE_PURCHASE_ORDER_SUCCESS';
export const AUTO_SAVE_PURCHASE_ORDER_FAIL = 'uprince/AUTO_SAVE_PURCHASE_ORDER_FAIL';

export const SAVE_PURCHASE_ORDER_NOUPDATE = 'uprince/SAVE_PURCHASE_ORDER_NOUPDATE';
export const SAVE_PURCHASE_ORDER_SUCCESS_NOUPDATE = 'uprince/SAVE_PURCHASE_ORDER_SUCCESS_NOUPDATE';
export const SAVE_PURCHASE_ORDER_FAIL_NOUPDATE = 'uprince/SAVE_PURCHASE_ORDER_FAIL_NOUPDATE';

export const LOAD_PURCHASE_ORDER_BY_ID = 'uprince/LOAD_PURCHASE_ORDER_BY_ID';
export const LOAD_PURCHASE_ORDER_BY_ID_SUCCESS = 'uprince/LOAD_PURCHASE_ORDER_BY_ID_SUCCESS';
export const LOAD_PURCHASE_ORDER_BY_ID_FAIL = 'uprince/LOAD_PURCHASE_ORDER_BY_ID_FAIL';

export const LOAD_BOR_RESOURCES_BY_ID = 'uprince/LOAD_BOR_RESOURCES_BY_ID';
export const LOAD_BOR_RESOURCES_BY_ID_SUCCESS = 'uprince/LOAD_BOR_RESOURCES_BY_ID_SUCCESS';
export const LOAD_BOR_RESOURCES_BY_ID_FAIL = 'uprince/LOAD_BOR_RESOURCES_BY_ID_FAIL';

export const SET_PURCHASE_ORDER_UID = 'uprince/SET_PURCHASE_ORDER_UID';
export const CLEAR_PURCHASE_ORDER_UID = 'uprince/CLEAR_PURCHASE_ORDER_UID';
export const CLEAR_PURCHASE_ORDER_FORM_DATA = 'uprince/CLEAR_PURCHASE_ORDER_FORM_DATA';
export const PO_BOR_POPUP_STATE = 'uprince/PO_BOR_POPUP_STATE';
export const RESET_PURCHASE_ORDER_IS_CHANGE = 'uprice/RESET_PURCHASE_ORDER_IS_CHANGE';


export const CLEAR_PURCHASE_ORDERUID = 'uprice/CLEAR_PURCHASE_ORDERUID';

export const APPROVED_PO = 'uprince/APPROVED_PO';
export const APPROVED_PO_SUCCESS = 'uprince/APPROVED_PO_SUCCESS';
export const APPROVED_PO_FAIL = 'uprince/APPROVED_PO_FAIL';


export const APPROVED_PO_CU = 'uprince/APPROVED_PO_CU';
export const APPROVED_PO_CU_SUCCESS = 'uprince/APPROVED_PO_CU_SUCCESS';
export const APPROVED_PO_CU_FAIL = 'uprince/APPROVED_PO_CU_FAIL';

export const APPROVED_PO_CU_LEVEL = 'uprince/APPROVED_PO_CU_LEVEL';
export const APPROVED_PO_CU_LEVEL_SUCCESS = 'uprince/APPROVED_PO_CU_LEVEL_SUCCESS';
export const APPROVED_PO_CU_LEVEL_FAIL = 'uprince/APPROVED_PO_CU_LEVEL_FAIL';

export const CREATE_PO_DELIVERY_LOCATION = 'uprince/CREATE_PO_DELIVERY_LOCATION';
export const CREATE_PO_DELIVERY_LOCATION_SUCCESS = 'uprince/CREATE_PO_DELIVERY_LOCATION_SUCCESS';
export const CREATE_PO_DELIVERY_LOCATION_FAIL = 'uprince/CREATE_PO_DELIVERY_LOCATION_FAIL';

export const FEEDBACK_PO_CU = 'uprince/FEEDBACK_PO_CU';
export const FEEDBACK_PO_CU_SUCCESS = 'uprince/FEEDBACK_PO_CU_SUCCESS';
export const FEEDBACK_PO_CU_FAIL = 'uprince/FEEDBACK_PO_CU_FAIL';

export const PROJECT_SEND_PO = 'uprince/PROJECT_SEND_PO';
export const PROJECT_SEND_PO_SUCCESS = 'uprince/PROJECT_SEND_PO_SUCCESS';
export const PROJECT_SEND_PO_FAIL = 'uprince/PROJECT_SEND_PO_FAIL';


export const CU_SEND_PO = 'uprince/CU_SEND_PO';
export const CU_SEND_PO_SUCCESS = 'uprince/CU_SEND_PO_SUCCESS';
export const CU_SEND_PO_FAIL = 'uprince/CU_SEND_PO_FAIL';


export const LOAD_DELIVERY_PLAN = 'uprince/LOAD_DELIVERY_PO_PLAN';
export const LOAD_DELIVERY_PLAN_SUCCESS = 'uprince/LOAD_DELIVERY_PLAN_PO_SUCCESS';
export const LOAD_DELIVERY_PLAN_FAIL = 'uprince/LOAD_DELIVERY_PLAN_PO_FAIL';

export const LOAD_CU_DELIVERY_PLAN = 'uprince/LOAD_CU_DELIVERY_PO_PLAN';
export const LOAD_CU_DELIVERY_PLAN_SUCCESS = 'uprince/LOAD_CU_DELIVERY_PLAN_PO_SUCCESS';
export const LOAD_CU_DELIVERY_PLAN_FAIL = 'uprince/LOAD_CU_DELIVERY_PLAN_PO_FAIL';


export const LOAD_PRODUCT_DELIVERY_PLAN = 'uprince/LOAD_PRODUCT_DELIVERY_PO_PLAN';
export const LOAD_PRODUCT_DELIVERY_PLAN_SUCCESS = 'uprince/LOAD_PRODUCT_DELIVERY_PLAN_PO_SUCCESS';
export const LOAD_PRODUCT_DELIVERY_PLAN_FAIL = 'uprince/LOAD_PRODUCT_DELIVERY_PLAN_PO_FAIL';

//POResourceStockUpdate
export const LOAD_DELIVERY_PLAN_STOCK_UPDATE = 'uprince/LOAD_DELIVERY_PO_PLAN_STOCK_UPDATE';
export const LOAD_DELIVERY_PLAN_STOCK_UPDATE_SUCCESS = 'uprince/LOAD_DELIVERY_PLAN_PO_STOCK_UPDATE_SUCCESS';
export const LOAD_DELIVERY_PLAN_STOCK_UPDATE_FAIL = 'uprince/LOAD_DELIVERY_PLAN_PO_STOCK_UPDATE_FAIL';

export const LOAD_CU_DELIVERY_PLAN_STOCK_UPDATE = 'uprince/LOAD_CU_DELIVERY_PO_PLAN_STOCK_UPDATE';
export const LOAD_CU_DELIVERY_PLAN_STOCK_UPDATE_SUCCESS = 'uprince/LOAD_CU_DELIVERY_PLAN_PO_STOCK_UPDATE_SUCCESS';
export const LOAD_CU_DELIVERY_PLAN_STOCK_UPDATE_FAIL = 'uprince/LOAD_CU_DELIVERY_PLAN_PO_STOCK_UPDATE_FAIL';

export const LOAD_DELIVERY_PLAN_PRODUCT_STOCK_UPDATE = 'uprince/LOAD_DELIVERY_PO_PLAN_PRODUCT_STOCK_UPDATE';
export const LOAD_DELIVERY_PLAN_PRODUCT_STOCK_UPDATE_SUCCESS = 'uprince/LOAD_DELIVERY_PLAN_PRODUCT_PO_STOCK_UPDATE_SUCCESS';
export const LOAD_DELIVERY_PLAN_PRODUCT_STOCK_UPDATE_FAIL = 'uprince/LOAD_DELIVERY_PLAN_PRODUCT_PO_STOCK_UPDATE_FAIL';


export const LOAD_DELIVERY_PLAN_PRODUCT_CU_STOCK_UPDATE = 'uprince/LOAD_DELIVERY_PO_PLAN_PRODUCT_CU_STOCK_UPDATE';
export const LOAD_DELIVERY_PLAN_PRODUCT_CU_STOCK_UPDATE_SUCCESS = 'uprince/LOAD_DELIVERY_PLAN_PRODUCT_PO_STOCK_CU_UPDATE_SUCCESS';
export const LOAD_DELIVERY_PLAN_PRODUCT_CU_STOCK_UPDATE_FAIL = 'uprince/LOAD_DELIVERY_PLAN_PRODUCT_PO_STOCK_CU_UPDATE_FAIL';


export const LOAD_BOR_PO_LIST_PANE_LIST = 'uprince/LOAD_BOR_PO_LIST_PANE_LIST';
export const LOAD_BOR_PO_LIST_PANE_LIST_SUCCESS = 'uprince/LOAD_BOR_PO_LIST_PANE_LIST_SUCCESS';
export const LOAD_BOR_PO_LIST_PANE_LIST_FAIL = 'uprice/LOAD_BOR_PO_LIST_PANE_LIST_FAIL';

export const LOAD_BOR_RESOURCES_PO_LIST_PANE_LIST = 'uprince/LOAD_BOR_RESOURCES_PO_LIST_PANE_LIST';
export const LOAD_BOR_RESOURCES_PO_LIST_PANE_LIST_SUCCESS = 'uprince/LOAD_BOR_RESOURCES_PO_LIST_PANE_LIST_SUCCESS';
export const LOAD_BOR_RESOURCES_PO_LIST_PANE_LIST_FAIL = 'uprice/LOAD_BOR_RESOURCES_PO_LIST_PANE_LIST_FAIL';

export const LOAD_PRODUCT_PO_LIST_PANE_LIST = 'uprince/LOAD_PRODUCT_PO_LIST_PANE_LIST';
export const LOAD_PRODUCT_PO_LIST_PANE_LIST_SUCCESS = 'uprince/LOAD_PRODUCT_PO_LIST_PANE_LIST_SUCCESS';
export const LOAD_PRODUCT_PO_LIST_PANE_LIST_FAIL = 'uprice/LOAD_PRODUCT_PO_LIST_PANE_LIST_FAIL';


export const LOAD_PO_PROJECT_LIST = 'uprince/LOAD_PO_PROJECT_LIST';
export const LOAD_PO_PROJECT_LIST_SUCCESS = 'uprince/LOAD_PO_PROJECT_LIST_SUCCESS';
export const LOAD_PO_PROJECT_LIST_FAIL = 'uprice/LOAD_PO_PROJECT_LIST_FAIL';

export const LOAD_PO_BOR_UPDATE = 'uprince/LOAD_PO_BOR_UPDATE';
export const LOAD_PO_BOR_UPDATE_SUCCESS = 'uprince/LOAD_PO_BOR_UPDATE_SUCCESS';
export const LOAD_PO_BOR_UPDATE_FAIL = 'uprice/LOAD_PO_BOR_UPDATE_FAIL';

export const LOAD_PO_CU_BOR_UPDATE = 'uprince/LOAD_PO_CU_BOR_UPDATE';
export const LOAD_PO_CU_BOR_UPDATE_SUCCESS = 'uprince/LOAD_PO_CU_BOR_UPDATE_SUCCESS';
export const LOAD_PO_CU_BOR_UPDATE_FAIL = 'uprice/LOAD_PO_CU_BOR_UPDATE_FAIL';

export const LOAD_BOR_RETURN_PO_LIST_PANE_LIST = 'uprince/LOAD_BOR_RETURN_PO_LIST_PANE_LIST';
export const LOAD_BOR_RETURN_PO_LIST_PANE_LIST_SUCCESS = 'uprince/LOAD_BOR_RETURN_PO_LIST_PANE_LIST_SUCCESS';
export const LOAD_BOR_RETURN_PO_LIST_PANE_LIST_FAIL = 'uprice/LOAD_BOR_RETURN_PO_LIST_PANE_LIST_FAIL';

export const LOAD_PBS_RESOURCES_BY_ID = 'uprince/LOAD_PBS_RESOURCES_BY_ID';
export const LOAD_PBS_RESOURCES_BY_ID_SUCCESS = 'uprince/LOAD_PBS_RESOURCES_BY_ID_SUCCESS';
export const LOAD_PBS_RESOURCES_BY_ID_FAIL = 'uprince/LOAD_PBS_RESOURCES_BY_ID_FAIL';


export const CONVERT_CR_TO_PO = 'uprince/CONVERT_CR_TO_PO';
export const CONVERT_CR_TO_PO_SUCCESS = 'uprince/CONVERT_CR_TO_PO_SUCCESS';
export const CONVERT_CR_TO_PO_FAIL = 'uprince/CONVERT_CR_TO_PO_FAIL';

export const SAVE_PO_STATE_ATTRIBUTE = 'uprince/SAVE_PO_STATE_ATTRIBUTE';

export const SEND_RFQ_EMAIL = 'uprince/SEND_RFQ_EMAIL';
export const SEND_RFQ_EMAIL_SUCCESS = 'uprince/SEND_RFQ_EMAIL_SUCCESS';
export const SEND_RFQ_EMAIL_FAIL = 'uprince/SEND_RFQ_EMAIL_FAIL';

export const LOAD_RFQ_INVITATION_DETAILS = 'uprince/LOAD_RFQ_INVITATION_DETAILS';
export const LOAD_RFQ_INVITATION_DETAILS_SUCCESS = 'uprince/LOAD_RFQ_INVITATION_DETAILS_SUCCESS';
export const LOAD_RFQ_INVITATION_DETAILS_FAIL = 'uprince/LOAD_RFQ_INVITATION_DETAILS_FAIL';

export const SAVE_SIGNATURE = 'uprince/SAVE_SIGNATURE';
export const SAVE_SIGNATURE_SUCCESS = 'uprince/SAVE_SIGNATURE_SUCCESS';
export const SAVE_SIGNATURE_FAIL = 'uprince/SAVE_SIGNATURE_FAIL';

export const UPLOAD_RFQ_SIGNATURE = 'uprince/UPLOAD_RFQ_SIGNATURE';
export const UPLOAD_RFQ_SIGNATURE_SUCCESS = 'uprince/UPLOAD_RFQ_SIGNATURE_SUCCESS';
export const UPLOAD_RFQ_SIGNATURE_FAIL = 'uprince/UPLOAD_RFQ_SIGNATURE_FAIL';

export class ProjectListPaneFilter {
  title: string | null = null;
  projectTypeId: string | null = null;
  managementLevelId: string | null = null;
  toleranceStateId: string | null = null;
  projectManagerId: string | null = null;
  projectStatus: string | null = null;
  customerId: string | null = null;
  siteManagerId: string | null = null;
  templateId: string | null = null;
  projectClassificationSectorId: string | null = null;
  ciawNumber: string | null = null;
  projectAddress: string | null = null;
  history: boolean = false;
  sorter: Sorter = new Sorter();
  customerCompanyName: string | null = null;
  customerCompanyId: string | null = null;
}

export interface ProjectShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
  value: number | null
}

class Sorter {
  attribute: string | null = null;
  order: string | null = null;
}

export interface ProjectListItem {
  name: string | null;
  sequenceId: string | null;
  title: string | null;
  projectManagementLevelName: string;
  projectManager: string;
  projectManagerCabPersonId: string;
  projectTemplateName: string;
  projectToleranceStateName: string;
  type: string;
  sequenceCode: string;
  siteManagerName: string;
}


export interface DropDown {
  key: string;
  text: string;
}

export interface PROJECTDropDowns {
  status: DropDown[];
  types: DropDown[];
}

export interface Project {
  id: string | null;
  title: string;
  sequenceId: string
}

export interface PROJECTDetails {
  id: string | null;
  sequenceId: string | null;
  title: string | null;
  name: string | null;
}

export interface History {
  createdByUser: string | null;
  createdDateTime: string | null;
  revisionNumber: number | string | null;
  updatedByUser: string | null;
  updatedDateTime: string | null;
}

export const formData = {
  id: null,
  title: 'string',
  sequenceId: 'string',
};

export const projectInitialFormData = {
  id: 0,
  name: null,
  description: '',
  projectTypeId: 'a77e863a-d329-4f5b-ba1b-7dc3bf09b0b8',
  projectManagementLevelId: '4fb90b13-93e5-457c-908e-fea699ad23f1',
  projectToleranceStateId: '004eb795-8bba-47e8-9049-d14774ab0b18',
  projectScopeStatusId: '94282458-0b40-40a3-b0f9-c2e40344c8f1',
  projectTemplateId: null,
  kpi: {
    customLabelOne: 'risksPending',
    customPropOne: '0',
    customLabelTwo: 'correctiveActions',
    customPropTwo: '0',
    customLabelThree: 'issues',
    customPropThree: '0',
  },
  projectTime: {
    expectedEndDate: null,
    startDate: null,
    endDate: null,
    calendarTemplateId: null,
    projectId: 0,
  },
  projectFinance: {
    totalBudget: '0.00',
    invoiced: '0.00',
    paid: '0.00',
    budgetLabour: '0.00',
    budgetMaterial: '0.00',
    tenderBudget: '0.00',
    customerBudget: '0.00',
    differenceTenderAndCustomer: '0.00',
    customerBudgetSpent: '0.00',
    toBeInvoiced: '0.00',
    minWork: '0.00',
    extraWork: '0.00',
    minAndExtraWork: '0.00',
    expectedTotalProjectCost: '0.00',
    differenceEstimatedCostAndTenderBudget: '0.00',
    currencyId: 1,
    projectId: 0,
  },
  projectClassification: {
    projectClassificationSectorId: null,
    projectClassificationConstructionTypeId: null,
    projectClassificationSizeId: null,
    projectClassificationBuisnessUnit: null,
  },
  projectTeam: {
    id: null,
    contractingUnitId: 'e0386eac-c9a0-4f93-8baf-d24948bedda9',
    projectId: null,
    teamRoleList: [],
  },
  projectCostConversionCreateDto: {
    projectId: 0,
    travelConversionOption: null,
    loadingConversionOption: null,
  },
  location: {
    type: '',
    id: '',
    score: '',
    entityType: '',
    address: {
      municipality: '',
      countrySecondarySubdivision: '',
      countrySubdivision: '',
      countryCode: '',
      country: '',
      countryCodeISO3: '',
      freeformAddress: '',
    },
    position: {
      lat: 0,
      lon: 0,
    },
    viewport: {
      topLeftPoint: {
        lat: 0,
        lon: 0,
      },
      btmRightPoint: {
        lat: 0,
        lon: 0,
      },
    },
    boundingBox: {
      topLeftPoint: {
        lat: 0,
        lon: 0,
      },
      btmRightPoint: {
        lat: 0,
        lon: 0,
      },
    },
    dataSources: { geometry: { id: '' } },
  },
  genaralLederId: '',
};


export class WbsIssueListPaneFilter {
  sorter: Sorter = new Sorter();
  title: string | null = null;
  productTitle: string | null = null;
  status: string | null = null;
  priority: string | null = null;
  severity: string | null = null;
  responsible: string | null = null;
}

class Sorter {
  attribute: string | null = null;
  order: string | null = null;
}

export const ISSUE_STATUS = {
  PENDING_DEVELOPMENT: '0e1b34bc-f2c3-isis-8250-9666ee96ae59',
  IN_DEVELOPMENT: '3960193f-99e0-isis-a6cc-4919e5d345c5',
  IN_REVIEW: '40843898-54EE-isis-A661-194F1DA0CE48',
  APPROVED: '5684969c-f3e8-isis-9746-51e7e23f2782',
  HAND_OVER: 'vvvv969c-f3e8-isis-9746-51e7e23f2782',
};
import {
  DetailsList,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Stack,
} from '@fluentui/react';
import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { uPrinceTheme } from '../../../../theme';
import { constructorInitialFormData, ContractDropDowns, timeTableColumns } from '../../../types/contract';
import HistoryBar from '../../../shared/historyBar/historyBar';
import { useTranslation } from 'react-i18next';
import ProjectHeader from '../../../shared/projectHeader/projectHeader';
import { useDispatch, useSelector } from 'react-redux';
import { AddressBookItem } from '../../../types/addressBookItem';
import { createForm } from 'final-form';
import { rendercardFooter } from '../../../types/uPrince';
import { DropDownAdaptater, TextFieldFF } from '../../../shared/fluentFinalForm';
import FixedDecimalNumberFormatField from '../../../shared/fluentFinalForm/FixedDecimalNumberFormatField';
import { CURRENCY_PREFIX, getProject } from '../../../shared/util';
import DataGridWithModal, { ModalGridContext } from '../../../shared/dataGridModal/component';
import ContractorModal from './constructorLotInfo/modal/component';
import {
  readByConstructorId,
  saveContractStateAttr,
  updateContractorWorkflow,
} from '../../../reducers/contractReducer';
import history from '../../../history';
import AccreditationAndSupplier from './accreditationsAndSuppliersList/component';
import { readCompetenceDropDown } from '../../../reducers/projectBreakdownReducer';

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
});

// const required = (value: any) => (value ? undefined : 'Required');

const DocumentPaneComponent = (props: {
  submit: any,
  dropDowns: ContractDropDowns,
  saveFormData: any,
  uid: any,
  showProgress: boolean,
  formValues: any,
  formData?: any,
  isExpand: boolean,
  setForm: (form: any) => void,
  isClear: boolean,
  detailsListColumn: any[],
  projectHeader: any,
  openContractorTeamModal: () => void,
  openDocumentationModal: () => void,
  addressBookItem: AddressBookItem,
  handleContractorsTaxonomyChange: any,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const stackStyles: IStackStyles = { root: { marginLeft: 0 } };

  const stackItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 50,
      width: 0,
      flexGrow: 1,
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: '4px 10px',
  };

  // const timeTableColumns: IColumn[] = [
  //     {
  //         key: 'column1',
  //         name: t('dateTime'),
  //         fieldName: 'dateTime',
  //         minWidth: 165,
  //         maxWidth: 200,
  //         isResizable: true,
  //         isSorted: false,
  //         onRender: (item: any) => {
  //             let formatDate;
  //             if(item?.dateTime) {
  //                 formatDate =  moment.utc(item?.dateTime).local().format('DD/MM/YY  HH:SS');
  //             } else {
  //                 formatDate = "";
  //             }

  //             return <span>{ formatDate }</span>;
  //         }
  //     },
  //     {
  //         key: 'column2',
  //         name: t('status'),
  //         fieldName: 'statusName',
  //         minWidth: 165,
  //         maxWidth: 200,
  //         isResizable: true,
  //         isSorted: false,
  //     },
  // ];

  const columns = [
    {
      key: 'column1',
      name: t('contractor'),
      fieldName: 'cabPersonName',
      minWidth: 120,
      maxWidth: 300,
      isResizable: true,
    },
  ];

  const priceColumns = [
    {
      key: 'column1',
      name: t('contractor'),
      fieldName: 'title',
      minWidth: 120,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('price'),
      fieldName: 'price',
      minWidth: 120,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: any) => {
        let formatter = new Intl.NumberFormat(window.navigator.language, {
          style: 'currency',
          currency: 'EUR',
        });
        return <div className="find me" style={{ maxWidth: 200, display: 'flex', justifyContent: 'flex-end' }}>
          {item?.price && <span>{formatter.format(item?.price)}</span>}
        </div>
      }
    },
  ];

  const {
    isClear,
    constructorFormData,
    contractDropDowns,
    reloadListPane,
    contractorId,
  }: any = useSelector((state: any) => state.contract);

  // const {pbsExperience }: any = useSelector((state: any) => state.projectBreakdown);

  // const [state, setstate] = useState<Contract | null>(null);

  const onSubmit = async (values: any) => {
    const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
    await sleep(300);
    // window.alert(JSON.stringify(values, 0, 2));
    values.projectSequenceCode = getProject();
    if (!values.id) {
      values.id = props.uid;
    }
    // values.IsDeleted = false;
    dispatch(updateContractorWorkflow(values));
  };

  const formRef = React.useRef(createForm({ onSubmit: onSubmit }));

  const getInitialFormValues = (formValue: any) => {
    if (location.pathname.split('/').pop() === 'new' && isClear) {
      return { ...constructorInitialFormData };
    } else if (constructorFormData && constructorFormData?.id) {
      return { ...constructorFormData };
    }
    return formValue;
  };

  const customeProjectHeader = {
    // company: null,
    // id: null,
    // image: null,
    // mobileNumber: null,
    pmName: constructorFormData?.contractor,
    // projectFinanceStatusId: null,
    // projectScopeStatusId: null,
    // roleName: null,
    // sequenceCode: null,
    title: constructorFormData?.contractTitle,
  };

  // useEffect(() => {
  //     setstate(constructorFormData);
  // if (constructorFormData?.sequenceId) {
  //     if (getContractingUnit() && !getProject()) {
  //         history.push(`/CU/${getContractingUnit()}/contract/${constructorFormData.sequenceId}`);
  //     } else {
  //         history.push(`/CU/${getContractingUnit()}/project/${getProject()}/contract/${constructorFormData.sequenceId}`);
  //     }
  // }
  // }, [constructorFormData]);

  useEffect(() => {
    dispatch(readCompetenceDropDown());
  }, []);

  // useEffect(() => {
  //     if (reloadListPane) {
  //         dispatch(filterContractListPaneData(new ContractListPaneFilter()));
  //     }
  // }, [reloadListPane]);

  useEffect(() => {
    if (contractorId) {
      dispatch(readByConstructorId(contractorId));
      dispatch(saveContractStateAttr('contractorId', null));
    }
  }, [contractorId]);

  // useEffect(() => {
  //     formRef.current.resetFieldState('name');
  // }, [constructorFormData]);

  return (
    <div className={`${classNames.fullWidth}`}>
      <div className="proj-detail-block">
        <Form
          onSubmit={onSubmit}
          initialValues={getInitialFormValues(props.formValues)}
          mutators={{
            setContractorName: (args, state, utils) => {
              const field = state.fields.contractorName;
              field.change(args[0]);
            },
          }}
          // validate={ validate }
          render={({
                     handleSubmit,
                     form,
                     submitting,
                     pristine,
                     values,
                   }) => {
            props.submit(handleSubmit);
            props.setForm(form);

            let headerFiles = values?.files;
            return (
              <form onSubmit={handleSubmit} noValidate>
                <ProjectHeader projectDetails={customeProjectHeader}/>
                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="constructor-lot-info">
                      <Label>1. {t('constructorLotInfo')}</Label>
                    </Link>
                  </div>
                  {values?.sequenceId && (
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="title"
                            component={TextFieldFF}
                            lable={t('productTitle')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="sequenceId"
                            component={TextFieldFF}
                            lable={t('productId')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>
                  )}

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="division"
                          component={TextFieldFF}
                          lable={t('division')}
                          disabled={true}
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="typeId"
                          component={DropDownAdaptater}
                          options={contractDropDowns.productItemType}
                          lable={t('type')}
                          placeholder={t('type')}
                          disabled={true}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="contractor"
                          component={TextFieldFF}
                          lable={t('contractor')}
                          disabled={true}
                        />
                        {/* <Field name="contractorId">
                                                    {({ input, meta }) => (
                                                        <>
                                                            <PeoplePicker
                                                                label={t('contractor')}
                                                                isDisabled={false}
                                                                selectedItem={
                                                                    values?.contractorId
                                                                        ? [
                                                                            {
                                                                                key: values?.contractorId,
                                                                                text: values?.contractorName
                                                                            }
                                                                        ]
                                                                        : []
                                                                }
                                                                onChange={(items: any) => {
                                                                    form.resetFieldState('contractorId');
                                                                    input.onChange;
                                                                    return items;
                                                                }}
                                                                autoComplete={'off'}
                                                                onPersonSelected={(item: any) => {
                                                                    input.onChange(item.cabPersonId);
                                                                    form.mutators.setContractorName(item.text);
                                                                    // form.mutators.setCabCompany(item?.Organisation);
                                                                    // form.mutators.setCabCompanyId(item?.companyId);
                                                                    // form.mutators.setCabJobTitle(item?.jobTitle);
                                                                    return item;
                                                                }}
                                                                key={'normal'}
                                                                endpoint={'CentralAddressBook/ProjectPersonFilter'}
                                                            />
                                                            {(meta.error || meta.submitError) &&
                                                                meta.touched && (
                                                                    <Text
                                                                        styles={{
                                                                            root: { color: '#a4262c', fontSize: 12 },
                                                                        }}
                                                                    >
                                                                        {meta.error || meta.submitError}
                                                                    </Text>
                                                                )}
                                                        </>
                                                    )}
                                                </Field>
                                                <Field
                                                    name="contractorName"
                                                    component={'input'}
                                                    type={'text'}
                                                    disabled={true}
                                                    hidden
                                                /> */}
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="price"
                          component={FixedDecimalNumberFormatField}
                          lable={t('price')}
                          prefix={CURRENCY_PREFIX}
                          disabled={true}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>
                  <Field name={'constructorTeamList'}>
                    {({ input, meta }) => (
                      <DataGridWithModal
                        dataList={values?.constructorTeamList && Array.isArray(values.constructorTeamList) ? values.constructorTeamList : []}
                        modalTitle={t('team')}
                        deleteDataGrid={async (selection: any) => {
                          const selectedList = selection.map((item: any) => {
                            return item;
                          });
                          const currentList: any[] = values.constructorTeamList;

                          const filtered: any[] = currentList.filter((e: any) => {
                            return selectedList.indexOf(e) < 0;
                          });
                          input.onChange(filtered);
                          const formData: any[] = { ...values, constructorTeamList: filtered };
                          dispatch(updateContractorWorkflow(formData));
                        }}
                        title={`1.1 ${t('team')}`}
                        readOnly={false}
                        keyProp={'cabPersonId'}
                        columns={columns}
                        isLoaded={false}
                      >
                        <ModalGridContext.Consumer>
                          {(modalProps: any) => (
                            <ContractorModal
                              cabCompanyId={values.cabCompanyId}
                              constructorTeam={modalProps.editItem}
                              dataList={modalProps.dataList}
                              disabled={false}
                              onChange={(item: any) => {
                                if (values && values.constructorTeamList && Array.isArray(values.constructorTeamList)) {
                                  const constructorTeamList: any[] = [];
                                  const currentList: any[] = values.constructorTeamList;
                                  let valueList = [];
                                  if (item.id) {
                                    const index = currentList.findIndex(object => {
                                      return object.id === item.id;
                                    });
                                    if (index < currentList.length) {
                                      currentList[index] = item;
                                    }
                                    valueList = currentList;
                                    input.onChange(valueList);
                                    modalProps.updateEditItem(item);
                                  } else {
                                    constructorTeamList.push(item);
                                    currentList.forEach((listItems: any) => {
                                      constructorTeamList.push(listItems);
                                    });
                                    valueList = constructorTeamList;
                                    input.onChange(valueList);
                                  }
                                  // const accreditationAndSupplier: any[] = { ...props.formValues.accreditationAndSupplier, contractorAccreditation: valueList }
                                  const formData: any[] = { ...values, constructorTeamList: valueList };
                                  if (values.id) {
                                    dispatch(updateContractorWorkflow(formData));
                                  }
                                }
                                modalProps.closeModal();
                              }}
                            />
                          )}
                        </ModalGridContext.Consumer>
                      </DataGridWithModal>
                    )}
                  </Field>
                  {rendercardFooter(form)}
                </div>


                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="all-lots-contractor">
                      <Label>2. {t('allLotsContractor')}</Label>
                    </Link>
                  </div>
                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.halfWidth}`}>
                        <Field
                          name="totalPrice"
                          component={FixedDecimalNumberFormatField}
                          lable={t('totalPrice')}
                          prefix={CURRENCY_PREFIX}
                          disabled={true}
                          value={values.totalPrice}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>
                  <Field name={'contractList'}>
                    {({ input, meta }) => (
                      <DataGridWithModal
                        dataList={values.contractList && Array.isArray(values.contractList) ? values.contractList : []}
                        modalTitle={t('contracts')}
                        deleteDataGrid={async (selection: any) => {
                          const selectedList = selection.map((item: any) => {
                            return item.id;
                          });
                          // const res: any = await dispatch(deleteTeamMembers(selectedList));
                          // if (res.payload?.status === 200) {
                          //     dispatch(getProjectDefinition(getProject()));
                          // }
                        }}
                        title={`2.1 ${t('contracts')}`}
                        readOnly={true}
                        columns={priceColumns}
                        isLoaded={false}
                        onItemInvoked={true}
                      >
                        {/* <ModalGridContext.Consumer>
                                        {(modalProps: any) => (
                                            <ContractorTeamModal
                                                contractors={modalProps.editItem}
                                                disabled={false}
                                                onChange={(item: any) => {
                                                    if (values&& values.contractTeam && Array.isArray(values.contractTeam)) {
                                                        const contractor: any[] = [];
                                                        const currentList: any[] = values.contractTeam;
                                                        contractor.push(item);
                                                        currentList.forEach((listTeam: any) => {
                                                            contractor.push(listTeam);
                                                        });
                                                        // const constructorLotInfo = { ...values, contractorList: constructor };
                                                        input.onChange(contractor);
                                                        if (values.id) {
                                                            // dispatch(updateProjectDefinition({ ...values, documentation: documentation }));
                                                        }
                                                    }
                                                    modalProps.closeModal();
                                                }}
                                            />
                                        )}
                                    </ModalGridContext.Consumer> */}
                      </DataGridWithModal>
                    )}
                  </Field>
                </div>

                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="time-table-contractor">
                      <Label>3. {t('timeTableContractor')}</Label>
                    </Link>
                  </div>
                  <Field name="timetableLot">
                    {({ input, meta }) => (
                      <DetailsList
                        items={constructorFormData?.timeTable ? constructorFormData?.timeTable : []}
                        columns={timeTableColumns()}
                        isHeaderVisible={true}
                        checkboxVisibility={2}
                        disableSelectionZone={true}
                      />
                    )}
                  </Field>
                </div>

                <Field name="accreditationAndSupplier">
                  {({ input, meta }) => (
                    <AccreditationAndSupplier
                      disabled={false}
                      accAndSup={values.accreditationAndSupplier}
                      onChange={(item: any) => {
                        input.onChange(item);
                      }}
                      formValues={values}
                      footer={rendercardFooter(form)}
                      readOnly={false}//!!!state?.sequenceId}
                    />
                  )}
                </Field>

                {constructorFormData?.historyLog &&
                constructorFormData?.historyLog?.createdDateTime && (
                  <div className="proj-detail-block" id="3">
                    <div className="document-pane-card">
                      <div className="marginTop marginBottom">
                        <Link href="#" id="history">
                          <Label>5. {t('history')} </Label>
                        </Link>

                        <HistoryBar
                          createdByUser={
                            constructorFormData.historyLog.createdBy
                              ? constructorFormData.historyLog.createdBy
                              : ''
                          }
                          updatedByUser={
                            constructorFormData.historyLog.modifiedBy
                              ? constructorFormData.historyLog.modifiedBy
                              : ''
                          }
                          createdDateTime={
                            constructorFormData.historyLog.createdDateTime
                              ? constructorFormData.historyLog.createdDateTime
                              : ''
                          }
                          updatedDateTime={
                            constructorFormData.historyLog.modifiedDateTime
                              ? constructorFormData.historyLog.modifiedDateTime
                              : ''
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};
export default DocumentPaneComponent;


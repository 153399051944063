import * as React from "react";
import {
  makeStyles,
  tokens,
  MenuList,
  MenuItem,
  Menu,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";

import {
  bundleIcon,
  CutRegular,
  CutFilled,
  GroupList24Regular
} from "@fluentui/react-icons";
import { useTranslation } from 'react-i18next';
import { WBS_TASK_DELIVERY_STATUS, WBS_TASK_GROUP_BY } from '../../../types/wbs';
import { useLocation } from 'react-router-dom';
import { getPropParameterName } from '../../../shared/util';

const CutIcon = bundleIcon(CutFilled, CutRegular);
const useMenuListContainerStyles = makeStyles({
  container: {
    backgroundColor: '#fafafa',
    width: "100%",
  },
});

export const MenuItemList = (props:{title?:string,icon?:any;onMenuItemClick:any,menuList?:any}) => {
  const {t} = useTranslation();
  const styles = useMenuListContainerStyles();
  const [isActiveFilterItem, setIsActiveFilterItem] = React.useState('');
  const { state }: any = useLocation();

  const getSelectedMenuId=()=>{
    if(props?.menuList){
      let selectedStateKey= (state && Object.values(state)?.[0]);
      return props?.menuList.find((item:any) => item.key === selectedStateKey)
    }else {
      return null
    }


  }
  return (
    <div className={styles.container}>
      <MenuList>
        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <MenuItem icon={props?.icon ? props?.icon : <GroupList24Regular />} style={{
              backgroundColor: '#fafafa',
              fontWeight: getSelectedMenuId() ? 'bold' : 'normal',
            }}
            > <span className={'menu-item'}>{props?.title}</span>
            </MenuItem>
          </MenuTrigger>
          <MenuPopover>
            <MenuList>
              {props?.menuList && props?.menuList?.map((menu: any, index: number) => {
                return <MenuItem key={menu?.key + '' + index} onClick={() => {
                  props.onMenuItemClick(menu?.key);
                  setIsActiveFilterItem(getPropParameterName(props.onMenuItemClick));
                }}>{menu?.text}</MenuItem>;
              })}
              {/*<MenuItem onClick={()=>{props.onMenuItemClick(WBS_TASK_GROUP_BY.NO_GROUPING)}} >{t('noGrouping')}</MenuItem>*/}
              {/*<MenuItem onClick={()=>{props.onMenuItemClick(WBS_TASK_GROUP_BY.DUE_DATE)}} >{t('dueDate')}</MenuItem>*/}
              {/*/!*<MenuItem onClick={()=>{props.onMenuItemClick(WBS_TASK_GROUP_BY.PROJECT)}} >{t('project')}</MenuItem>*!/*/}
              {/*<MenuItem onClick={()=>{props.onMenuItemClick(WBS_TASK_GROUP_BY.TAG)}}>{t('tag')}</MenuItem>*/}
            </MenuList>
          </MenuPopover>
        </Menu>
      </MenuList>
    </div>
  );
};

export default MenuItemList

import { DirectionalHint, Label, Link, Stack, Text, TooltipHost } from '@fluentui/react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { ProjectShortCutPaneItem } from '../../../types/project';
import { getFilterProjectList } from '../../../reducers/projectReducer';
import { getContractingUnit } from '../../../shared/util';
import history from '../../../history';

const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 14vh)!important',
    position: 'relative',
  },
  selected: {
    fontWeight: 'bold',
    color: '#854114!important',
  },
});

const allFilterItem: ProjectShortCutPaneItem = {
  key: null,
  id: null,
  type: 'all',
  name: 'All',
  value: null,
};
export const ShortCutPaneComponent = (props: {
  toggleOverlay: () => void;
  toggleOverlayFwd: () => void;
  divClass: string;
  currentActiveSection: string | null;
  handelShortCutPaneFilter: (selectedItem: ProjectShortCutPaneItem) => void;
  resetFilter: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const projectShortCutPaneFilters: any[] = [{ key: 1, text: t('activeProject'), icon: 'WorkItem' }, {
    key: 4,
    text: t('history'),
    icon: 'History',
  }];
  // const projectShortCutPaneFilters:any[] = [ { key: 1, text: t('activeProject'), icon: 'WorkItem' }, { key: 2, text: t('toleranceLimit'), icon: 'Warning' }, { key: 3, text: t('outOfTolerance'), icon: 'Error' }, { key: 4, text: t('history'), icon: 'History' } ];

  const setFilterOption = (selectedItem: ProjectShortCutPaneItem) => {
    if (selectedItem.type === 'all') {
      props.resetFilter();
    }
    props.handelShortCutPaneFilter(selectedItem);
    const selectedKey = selectedItem ? selectedItem?.key!!.toString() : '-1';
    switch (selectedKey) {
      case '1':
        dispatch(getFilterProjectList({
          title: null,
          projectTypeId: null,
          managementLevelId: null,
          toleranceStateId: null,
          templateId: null,
          toleranceState: null,
          projectManager: null,
          history: false,
          sorter: {
            attribute: null,
            order: null,
          },
        }));
        break;
      case '2':
        dispatch(getFilterProjectList({
          title: null,
          projectTypeId: null,
          managementLevelId: null,
          toleranceStateId: 'd9712fb3-02b6-4c2a-991c-ee904c87d8a8',
          templateId: null,
          toleranceState: null,
          projectManager: null,
          history: false,
          sorter: {
            attribute: null,
            order: null,
          },
        }));
        break;
      case '3':
        dispatch(getFilterProjectList({
          title: null,
          projectTypeId: null,
          managementLevelId: null,
          toleranceStateId: '8f33bdf6-7600-4ad7-b558-c98899c1e5b2',
          templateId: null,
          toleranceState: null,
          projectManager: null,
          history: false,
          sorter: {
            attribute: null,
            order: null,
          },
        }));
        break;
      case '4':
        dispatch(getFilterProjectList({
          title: null,
          projectTypeId: null,
          managementLevelId: null,
          toleranceStateId: null,
          templateId: null,
          toleranceState: null,
          projectManager: null,
          history: true,
          sorter: {
            attribute: null,
            order: null,
          },
        }));
        break;
    }
    history.push(`/CU/${getContractingUnit()}`);

  };
  const renderFilterItems = () => {
    const paths = window.location.pathname.split('/');
    if (paths && Array.isArray(paths) && paths.includes('project-definition')) {
      return;
    }
    if (projectShortCutPaneFilters) {
      return projectShortCutPaneFilters.map((item: any, index) => {

        return (
          <div key={item.key + 'div'}>

            <Link
              key={item.id + 'link'}
              className={`${
                props.currentActiveSection === item.key ? classNames.selected : ''
              }`}
              href="#"
              onClick={() => {
                setFilterOption(item);
              }}
            >
              <TooltipHost
                key={item.id + 't'}
                content={item.text}
                id="filter-opt-1"
                calloutProps={{ gapSpace: 0 }}
                directionalHint={DirectionalHint.rightCenter}
              >
                <i
                  key={item.key + 'i'}
                  className={`ms-Icon ms-Icon--${item.icon}`}
                  aria-labelledby="filter-opt-1"
                  aria-hidden="true"
                ></i>
              </TooltipHost>
              <i
                key={item.key + 'i2'}
                className={`filter-icon ms-Icon ms-Icon--${item.icon} filter-icon`}
                aria-hidden="true"
              ></i>
              <Text key={item.key}>{item.text} </Text>
            </Link>
          </div>
        );
      });
    }
    return <div></div>;
  };

  return (
    <div className={`${props.divClass} ${classNames.wrapper}`}>
      <div className="inner-container">
        <i
          onClick={props.toggleOverlay}
          className="ms-Icon ms-Icon--Back back-arrow"
          aria-hidden="true"
        ></i>
        <i
          onClick={props.toggleOverlayFwd}
          className="ms-Icon ms-Icon--Forward fwd-arrow"
          aria-hidden="true"
        ></i>

        <div className="filter-data filter-title">
          <TooltipHost
            content={t('projectBreakdownStructure')}
            // This is the important part!
            id="filter-opt-title"
            calloutProps={{ gapSpace: 0 }}
            directionalHint={DirectionalHint.rightCenter}
          >
            <Label
              aria-describedby={'filter-opt-title'}
              className={'short-cut-pane-label'}
            >
              {t('project')}
            </Label>
          </TooltipHost>
        </div>

        <div className="filter-option-list">
          <Stack
            gap={15}
            verticalFill
            styles={{
              root: {
                width: '100%',
                verticalAlign: 'center',
              },
            }}
          >

            {renderFilterItems()}
          </Stack>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShortCutPaneComponent);

import React, {useEffect, useRef} from 'react';
import {Field, Form} from 'react-final-form';
import {IStackStyles, IStackTokens, mergeStyleSets} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import PeoplePicker from '../../../../shared/fluentFinalForm/PeoplePicker';
import {saveMyDayPlanningStateAttr} from '../../../../reducers/myDayPlanningReducer';
import {useDispatch, useSelector} from 'react-redux';
import { formatDateTimeToISOString, isMyEnvironment } from '../../../../shared/util';
import {store} from '../../../../../index';
import client from '../../../../api';
import _ from 'lodash';
import {
    filterProjectDayPlanningProjectList,
    saveProjectDayPlanningStateAttr,
} from '../../../../reducers/projectDayPlanningReducer';
import {DropDownAdaptater} from '../../../../shared/fluentFinalForm';
import CustomDropdownWithPlaceholder from '../../../../shared/customDropdown/customDropdownWithPlaceholder';

const classNames = mergeStyleSets({
  wrapper: {
    display: 'block',
    maxWidth: '200px',
    minWidth: '180px',
    fontFamily: 'Segoe UI',
  },
  aligment: {
    margin: '2px 0 0 5px',
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
  btnWrapper: {
    display: 'block',
    maxWidth: '200px',
  },
});

const PlanboardFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formRef: any = useRef();

  const {
    planboardPersonId,
    planboardPersonName,
    selectedDate,
  } = useSelector(
    (state: any) => state.mydPlan,
  );

  const {
    pmolType,
  } = useSelector(
    (state: any) => state.pmol,
  );

  const { selectedProject } = useSelector((state : any) => state.project);

  const formattedProject = {
    value: selectedProject?.id,
    label: selectedProject?.title,
  };

  useEffect(() => {
    if (selectedProject?.id && !isMyEnvironment()) {
      formRef.current.mutators.setProjectSequenceCode(formattedProject);
    } else {
      formRef.current.mutators.setProjectSequenceCode(null);
    }
  }, [selectedProject]);


  const appendAllDropdownOption = (pmolTypeOptions: any) => {
    let allOption = { 'key': 1, 'text': t('all') };
    return [allOption, ...pmolTypeOptions];
  };

  const jobStatusOptions = [
    {key:1, text:t('all')},
    {key:2, text:t('job Done')},
    {key:3, text:t('job Not Done')},
  ]

  const {
    selectedCu,
    selectedBu,
    ppbPmolTypeId,
    ppbSiteManagerId,
    ppbProjectSequenceCode,
  } = useSelector(
    (state: any) => state.projectDPlan,
  );

  const {
    userInfo,
  } = useSelector(
    (state: any) => state.uprince,
  );

  useEffect(() => {

    return () => {
      formRef.current.reset();
      dispatch(saveProjectDayPlanningStateAttr('ppbProjectSequenceCode', null));
      dispatch(saveProjectDayPlanningStateAttr('ppbPmolTypeId', null));
      dispatch(saveProjectDayPlanningStateAttr('ppbSiteManagerId', null));
      dispatch(saveMyDayPlanningStateAttr('planboardPersonId', null));
      dispatch(saveMyDayPlanningStateAttr('planboardPersonName', null));
    };
  }, [selectedDate, selectedBu, selectedCu]);

  const onSubmit = async (values: any) => {
    const contractingUnit = selectedCu ? selectedCu?.sequenceCode : userInfo?.contractingUnit?.sequnceCode;
    const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
    await sleep(300);

    dispatch(filterProjectDayPlanningProjectList({
      executionDate: formatDateTimeToISOString(selectedDate),
      contractingUnit: [contractingUnit],
      buId: selectedBu ?? null,
      cabPersonId: values?.cabPersonId,
      projectSequenceCode: values?.projectSequenceCode?.value,
      pmolTypeId: values?.pmolTypeId == 1 ? null : values?.pmolTypeId,
      siteManagerId: values?.siteManagerId,
      isJobDone: values?.jobStatus,
    }));
  };

  const projectOptions = (inputValue: any) =>
    new Promise((resolve) => {
      resolve(getProjectByName(inputValue));
    });

  const getProjectByName = async (name: string) => {
    let projects: any = [];
    const cu = store.getState()?.uprince?.userCu ? store.getState()?.uprince?.userCu?.sequenceCode : null;
    const bu = store.getState()?.projectDPlan?.selectedBu ? store.getState()?.projectDPlan?.selectedBu : null;

    if (name.length >= 2 || name === '') {
      const response = await client.post(
        'ContractingUnitTenants/GetProjectsByUserProjectFilter', {
          title: name,
          projectTypeId: null,
          managementLevelId: null,
          toleranceStateId: null,
          toleranceState: null,
          contractingUnit: cu ? cu : null,
          buId: bu ? bu : null,
          sorter: {
            attribute: null,
            order: null,
          },
        });
      return formatProjectResponse(response);
    }
    return projects;
  };

  const formatProjectResponse = (response: any) => {
    let options: { value: string; label: string }[] = [];
    if (response && response.data && response.data.result) {
      const data = response.data.result;

      if (data && _.isArray(data)) {
        options = data.map((item: any) => {
          return {
            value: item.sequenceCode,
            label: item.title,
            sequenceCode: item.sequenceCode,
          };
        });
      }
      return options;
    }
  };

  return <Form
    onSubmit={onSubmit}
    // initialValues={getInitialFormValues(null, null)}
    mutators={{
      setEmployeeName: (args, state, utils) => {
        const field = state.fields['siteManagerName'];
        field.change(args[0]);
      },
      setProjectSequenceCode: (args, state, utils) => {
        const field = state.fields['projectSequenceCode'];
        field.change(args[0]);
      },
      setPmolTypeId: (args, state, utils) => {
        const field = state.fields['pmolTypeId'];
        field.change(args[0]);
      },      
      setJobStatus: (args, state, utils) => {
        const field = state.fields['jobStatus'];
        field.change(args[0]);
      }
    }}
    render={({ handleSubmit, form, submitting, pristine, values }) => {
      formRef.current = form;
      return (
        <form style={{ display: 'flex', gap: 10 }} onSubmit={handleSubmit} noValidate>

          <div className={`${classNames.wrapper}`}>
            <Field name='cabPersonId'>
              {({ input, meta }) => (
                <PeoplePicker
                  label=''
                  placeHolder={t('name')}
                  isDisabled={false}
                  selectedItem={
                    planboardPersonId ? [{
                      key: planboardPersonId,
                      text: planboardPersonName,
                    }] : []}
                  onChange={(item: any) => {
                    input.onChange(null);
                    dispatch(saveMyDayPlanningStateAttr('planboardPersonId', null));
                    dispatch(saveMyDayPlanningStateAttr('planboardPersonName', null));
                    formRef.current.submit();
                    return item;
                  }}
                  onPersonSelected={(item: any) => {
                    dispatch(saveMyDayPlanningStateAttr('planboardPersonId', item ? item?.cabPersonId : null));
                    dispatch(saveMyDayPlanningStateAttr('planboardPersonName', item ? item?.text : null));
                    return item;
                  }}
                />
              )}
            </Field>
          </div>


          <div className={`${classNames.wrapper}`}>
            <Field name='siteManagerId'>
              {({ input, meta }) => (
                <PeoplePicker
                  label={t('')}
                  placeHolder={t('siteManager')}
                  isDisabled={false}
                  selectedItem={values?.siteManagerId ? [{
                    key: values?.siteManagerId,
                    text: values?.siteManagerName,
                  }] : null}
                  onChange={(items: any) => {
                    input.onChange(null);
                    form.mutators.setEmployeeName(null);
                    dispatch(saveProjectDayPlanningStateAttr('ppbSiteManagerId', null));
                    formRef.current.submit();
                    return items;
                  }}
                  onPersonSelected={(item: any) => {
                    if (item) {
                      input.onChange(item.cabPersonId);
                      form.mutators.setEmployeeName(item.text);
                      dispatch(saveProjectDayPlanningStateAttr('ppbSiteManagerId', item?.cabPersonId));
                    } else {
                      input.onChange(null);
                      form.mutators.setEmployeeName(null);
                      dispatch(saveProjectDayPlanningStateAttr('ppbSiteManagerId', null));
                    }

                    return item;
                  }}
                />
              )}
            </Field>
            <Field
              name='siteManagerName'
              component={'input'}
              type={'text'}
              disabled={true}
              hidden
            />
          </div>


          <div className={`${classNames.wrapper}`}>
            <Field
              name='pmolTypeId'
              component={DropDownAdaptater}
              options={appendAllDropdownOption(pmolType)}
              placeholder={t('selectPmolType')}
              disabled={false}
              parse={value => {
                dispatch(saveProjectDayPlanningStateAttr('ppbPmolTypeId', value == 1 ? null : value));
                if (value == 1) {
                  formRef.current.mutators.setPmolTypeId(null);
                  formRef.current.submit();
                }
                return value;
              }}
            />
          </div>

          <div className={`${classNames.wrapper}`}>
            <Field
              name='jobStatus'
              component={DropDownAdaptater}
              options={jobStatusOptions}
              placeholder={t('Job Status')}
              disabled={false}
              parse={value => {                
                if (value == 2) {
                  dispatch(saveProjectDayPlanningStateAttr('ppbJobStatus', true));
                  formRef.current.mutators.setJobStatus(true);
                  formRef.current.submit();
                } else if(value == 3){
                  dispatch(saveProjectDayPlanningStateAttr('ppbJobStatus', false));
                  formRef.current.mutators.setJobStatus(false);
                  formRef.current.submit();
                } else {
                  dispatch(saveProjectDayPlanningStateAttr('ppbJobStatus', null));
                  formRef.current.mutators.setJobStatus(null);
                  formRef.current.submit();
                }
                return value;
              }}
            />
          </div>

          <div className={`${classNames.wrapper}`}>
            <Field name='projectSequenceCode'>
              {({ input, meta }) => (
                <CustomDropdownWithPlaceholder
                  key={`${selectedDate}${selectedBu}${selectedCu}`} // This is important to force the component to re-render when the key changes
                  values={values.projectSequenceCode}
                  promiseOptions={projectOptions}
                  disabled={!isMyEnvironment()}
                  placeHolder={t('project')}
                  onChange={(values: any) => {
                    if (values?.value) {
                      input.onChange({ value: values?.value, label: values?.label });
                      dispatch(saveProjectDayPlanningStateAttr('ppbProjectSequenceCode', {
                        value: values?.value,
                        label: values?.label,
                      }));
                    } else {
                      input.onChange({ value: null, label: null });
                      dispatch(saveProjectDayPlanningStateAttr('ppbProjectSequenceCode', {
                        value: null,
                        label: null,
                      }));
                    }
                    // form.submit();
                  }}
                />
              )}
            </Field>
          </div>
          {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
        </form>
      )
        ;
    }}
  />;
};

export default PlanboardFilter;

import React from "react";
import {useTranslation} from "react-i18next";
import {DirectionalHint, Link, Text, TooltipHost} from "@fluentui/react";
import {iconColors, uPrinceTheme} from "../../../theme";
import './link-component-style.css';
import {VPShortCutPaneItem} from "../../types/visualPlan";

const resourceTableFilterItem: VPShortCutPaneItem = {
    id: '100',
    name: null,
    type: 'table',
    key: '100',
    value: '100',
};

export function LinkComponent({currentActiveSection, setFilterOption, label, key, selectedKey}: any) {
    const {t} = useTranslation();

    return (
        <Link
            key={key}
            className={`${currentActiveSection === selectedKey ? "selected" : ""
            }`}
            href="#"
            onClick={() => {
                setFilterOption(resourceTableFilterItem);
            }}
        >
            <TooltipHost
                content={t('table')}
                id="filter-opt-1"
                calloutProps={{gapSpace: 0}}
                directionalHint={DirectionalHint.rightCenter}
            >
                <i
                    className="ms-Icon ms-Icon--ViewAll"
                    aria-labelledby="filter-opt-1"
                    aria-hidden="true"
                    style={
                        currentActiveSection === selectedKey
                            ? {color: iconColors.iconActiveColor}
                            : {color: uPrinceTheme.palette.themePrimary}
                    }
                ></i>
            </TooltipHost>
            <i
                className="filter-icon ms-Icon ms-Icon--ViewAll filter-icon"
                aria-hidden="true"
                style={
                    currentActiveSection === selectedKey
                        ? {color: iconColors.iconActiveColor}
                        : {color: uPrinceTheme.palette.themePrimary}
                }
            ></i>
            <Text style={
                currentActiveSection === selectedKey
                    ? {color: iconColors.iconActiveColor, fontSize: 13}
                    : {color: uPrinceTheme.palette.black, fontSize: 13}

            } key="table"> {label}</Text>
        </Link>
    );
}

import {Moment} from "moment";

export class DayPlanningCuList {
    startDate: string | Moment | null = null;
    endDate: string | Moment | null = null;
    buId: string | null = null;
    cuId: string | null = null;
    type: string | null = null;
    id: string | null = null;
}

export enum ORGANIZATION_TAXONOMY_LEVEL_TYPES {
    ORGANIZATION = 'qq282458-0b40-poa3-b0f9-c2e40344c8kk',
    CU = '2210e768-3e06-po02-b337-ee367a82adjj',
    BU = 'oo10e768-3e06-po02-b337-ee367a82admn',
    TEAM = 'fg10e768-3e06-po02-b337-ee367a82adfg',
    PERSON = 'we10e768-3e06-po02-b337-ee367a82adwe',
}

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  DetailsListLayoutMode,
  DetailsRow,
  Fabric,
  IColumn,
  IDetailsFooterProps,
  IDetailsListProps,
  IDetailsRowStyles,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
  Text,
  TextField,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { QualityListItem, QualityListPaneFilter } from '../../../types/quality';

export const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 25vh)!important',
    position: 'relative',
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: {
    margin: 0,
  },
  row: {
    display: 'inline-block',
  },
});

const ListPaneComponent = (props: {
  filter: QualityListPaneFilter;
  selection: any;
  columns: IColumn[];
  listPaneItems: QualityListItem[];
  //----------Filter Quality-----------//
  handleListPaneItemClick: (id: string) => void;
  handleFilterChange: (filter: QualityListPaneFilter) => void;
  isDataLoaded?: boolean;
  loadMsg: string;
  resetSelection: () => void;
  dropDownOptions: any;
}) => {

  const { t } = useTranslation();
  const [filter, setFilter] = useState<QualityListPaneFilter>(props.filter);
  const getDropdownWithDefualt = (options: any) => {
    if (options) {
      let defualtOption = [
        {
          key: '0',
          text: t('all'),
          isDefault: true,
        },
      ];
      Array.prototype.push.apply(defualtOption, options);
      return defualtOption;
    } else {
      return [];
    }
  };

  useEffect(() => {
    setFilter(props.filter);
  }, [props.filter]);

  const _onRenderRow: IDetailsListProps['onRenderRow'] = (row) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (row) {
      return (
        <span
          style={{ textDecoration: 'none' }}
          onClick={() => {
            // chnage item id name
            props.handleListPaneItemClick(row.item.id);
          }}
        >
          <DetailsRow {...row} styles={customStyles}/>
        </span>
      );
    }
    return null;
  };

  const _onRenderDetailsFooter = (
    detailsFooterProps: IDetailsFooterProps | undefined,
  ) => {

    return (
      <DetailsRow
        className="footer-as-filter"
        {...detailsFooterProps}
        columns={detailsFooterProps?.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps?.groupNestingDepth}
        selection={detailsFooterProps?.selection}
        onRenderItemColumn={_renderFilterItemColumn}
        // onRenderCheck={_onRenderCheckForFooterRow}
      />
    );

  };


  const _renderFilterItemColumn = (
    item?: QualityListItem,
    index?: number,
    column?: IColumn,
  ) => {
    switch (column?.key) {
      //-------------Add filter fields--------------------//
      case 'column1': {
        return (
          <div>
            <TextField
              value={props.filter.title ? props.filter.title : ''}
              onChange={(event, value) => {
                if (value) {
                  props.filter.title = value;
                } else {
                  props.filter.title = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }


      case 'column2': {
        return (
          <div>
            {/* <TextField

            /> */}
          </div>
        );
        break;
      }

      case 'column3': {
        return (
          <div>
            {/* <TextField

            /> */}
          </div>
        );
        break;
      }

      case 'column4': {
        return (
          <div>
            {/* <TextField

            /> */}
          </div>
        );
        break;
      }

      case 'column5': {
        return (
          <div>
            {/* <TextField

            /> */}
          </div>
        );
        break;
      }


      default: {
        break;
      }
    }
  };


  const displayMessage = () => {
    return (
      <div style={{ paddingTop: 36, paddingLeft: 20 }}>
        <Text>{t(props.loadMsg)}</Text>
      </div>
    );
  };
  return (
    <Fabric>
      <div className={`wrapper-holder ${classNames.wrapper}`}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <ShimmeredDetailsList
            items={props.listPaneItems}
            columns={props.columns}
            setKey="set"
            enableShimmer={!props.isDataLoaded}
            layoutMode={DetailsListLayoutMode.justified}
            selectionPreservedOnEmptyClick={true}
            checkboxVisibility={1}
            onRenderRow={_onRenderRow}
            onRenderDetailsFooter={_onRenderDetailsFooter}
            selection={props.selection}
            selectionMode={SelectionMode.single}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            onRenderDetailsHeader={(headerProps, defaultRender) => {
              if (defaultRender !== undefined) {
                return (
                  <Sticky
                    stickyPosition={StickyPositionType.Header}
                    isScrollSynced={true}
                    stickyBackgroundColor="transparent"
                  >
                    <div>{defaultRender(headerProps)}</div>
                  </Sticky>
                );
              } else {
                return <span></span>;
              }
            }}
          />
          {props.isDataLoaded && displayMessage()}
        </ScrollablePane>
      </div>
    </Fabric>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    toleranceStatesFilter: state.projectBreakdown.toleranceStatesFilter,
    productStatesFilter: state.projectBreakdown.productStatesFilter,
    itemTypesFilter: state.projectBreakdown.itemTypesFilter,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ListPaneComponent);

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NewQRCode from './../createQR/index';
import QRDetails from './index';

const QRCodeDetailRoute = ({
                             onQRCodeClick,
                             onQRCodeGenerated,
                             onChangeQRInputs,
                           }: any): JSX.Element => {
  return (
    <div style={{ overflowY: 'scroll' }}>
      <Switch>
        <Route
          path="/qr-code"
          exact
          render={(props) => (
            <NewQRCode
              onQRCodeGenerated={onQRCodeGenerated}
              onChangeQRInputs={onChangeQRInputs}
              onQRCodeClick={onQRCodeClick}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/qr-code/:id"
          render={({ match }) => (
            <QRDetails
              id={match.params.id}
              onQRCodeGenerated={onQRCodeGenerated}
            />
          )}
        />

        <Route
          path="/CU/:contratingUnitId/qr-code"
          exact
          render={(props) => (
            <NewQRCode
              onQRCodeGenerated={onQRCodeGenerated}
              onChangeQRInputs={onChangeQRInputs}
              onQRCodeClick={onQRCodeClick}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/CU/:contratingUnitId/qr-code/:id"
          render={({ match }) => (
            <QRDetails
              id={match.params.id}
              onQRCodeGenerated={onQRCodeGenerated}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default QRCodeDetailRoute;

import React from 'react';
import {CommandBar} from '@fluentui/react/lib/CommandBar';
import {useTranslation} from 'react-i18next';
import {messageService} from '../../../services/messageService';
import {
  APPROVED_STATUS_ID,
  EXPORT_EN,
  EXPORT_NL, HANDED_OVER_STATUS_ID,
  IN_REVIEW_STATUS_ID,
  PMOL_TYPE,
  ProjectMolecule,
} from '../../../types/projectMolecule';
import {saveTimeRegistrationStateAttr} from '../../../reducers/timeRegistrationReducer';
import {useDispatch} from 'react-redux';

const CommandBarRight = (props: {
  createNew: (create: boolean, type: PMOL_TYPE) => void;
  // saveProductCatalogue: (save:boolean) => void;
  handleRemove: () => void;
  formData: ProjectMolecule;
  isLoading: boolean;
  isDisableNewButton: boolean
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const newClick = () => {
  //     messageService.sendMessage({ isNewPmol: true });
  //     props.createNew(true);
  // };
  const saveFormData = () => {
    messageService.sendMessage({ savePmolFormData: true });
  };
  const handleRemove = () => {
    props.handleRemove();
  };

  const handlePmolApprove = () => {
    messageService.sendMessage({ approvePmol: true });
  };

  const handleExport = (type: string) => {
    if (type === EXPORT_NL) {
      messageService.sendMessage({ exportPmolNL: true });
    } else if (type === EXPORT_EN) {
      messageService.sendMessage({ exportPmolEN: true });
    }
  };

  const handlePmolFinished = () => {
    messageService.sendMessage({ finishedPmol: true });
  };
  const handleRegular = () => {
    messageService.sendMessage({ isNewPmol: true, pmolType: PMOL_TYPE.REGULAR });
    props.createNew(true, PMOL_TYPE.REGULAR);
  };
  const handleSubContract = () => {
    messageService.sendMessage({ isNewPmol: true, pmolType: PMOL_TYPE.SUB });
    props.createNew(true, PMOL_TYPE.SUB);
  };

  const handleTRMapModal = () => {
    dispatch(saveTimeRegistrationStateAttr('openTRMapModal', true));
  };

  const getItems = () => {
    const items = [];
    const newBtn = {
      key: 'new',
      text: t('new'),
      iconProps: { iconName: 'Add' },
      split: true,
      subMenuProps: {
        items: [
          { key: 'regular', text: t('clientName'), iconProps: { iconName: 'Add' }, onClick: () => handleRegular() },
          {
            key: 'subContract',
            text: t('subContract'),
            iconProps: { iconName: 'Add' },
            onClick: () => handleSubContract(),
          },
        ],
      },
      disabled: (false),
      onClick: () => handleRegular(),
    };
    const saveBtn = {
      key: 'save',
      text: t('save'),
      iconProps: { iconName: 'Save' },
      onClick: () => {
        saveFormData();
      },
      // disabled: props.isLoading
      disabled: ((props.formData.statusId === APPROVED_STATUS_ID || props.formData.statusId===HANDED_OVER_STATUS_ID) || props.isLoading),
    };


    const removeBtn = {
      key: 'Remove',
      onClick: () => {
        handleRemove();
      },
      name: t('remove'),
      iconProps: { iconName: 'Delete' },
      disabled: true,
    };

    const approveBtn = {
      key: 'approve',
      text: t('approve'),
      iconProps: { iconName: 'ActivateOrders' },
      disabled: (props.formData.statusId !== IN_REVIEW_STATUS_ID),
      onClick: () => handlePmolApprove(),
    };

    const exportBtn = {
      key: 'export',
      text: t('export'),
      iconProps: { iconName: 'Download' },
      split: true,
      subMenuProps: {
        items: [
          {
            key: 'exportNl',
            text: t('exportDutch'),
            iconProps: { iconName: 'Download' },
            onClick: () => handleExport(EXPORT_NL),
          },
          {
            key: 'exportEn',
            text: t('exportEnglish'),
            iconProps: { iconName: 'Download' },
            onClick: () => handleExport(EXPORT_EN),
          },
        ],
      },
      disabled: (props.formData.pmolType === PMOL_TYPE.SUB),
      onClick: () => handleExport(EXPORT_NL),
    };

    const finishedBtn = {
      key: 'finished',
      text: t('finished'),
      iconProps: { iconName: 'accept' },
      disabled: props.formData.isFinished,
      onClick: () => handlePmolFinished(),
    };

    const mapBtn = {
      key: 'map',
      text: t('map'),
      iconProps: { iconName: 'Nav2DMapView' },
      disabled: false,
      onClick: () => handleTRMapModal(),
    };

    items.push(newBtn);
    items.push(saveBtn);
    items.push(removeBtn);
    items.push(approveBtn);
    items.push(exportBtn);
    if (props.formData.pmolType === PMOL_TYPE.SUB) {
      items.push(finishedBtn);
    }
    items.push(mapBtn); // DO NOT hide the map button

    return items;
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );
};

export default CommandBarRight;

export class CorporateScheduleListPaneFilter {
  title: string | null = null;
  sorter: Sorter = new Sorter();
}

export interface CorporateScheduleShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
  value: number | null
}

class Sorter {
  attribute: string | null = null;
  order: string | null = null;
}

export class CorporateScheduleListItem {
  id: string | null = null;
  name: string | null = null;
  sequenceId: string | null = null;
  title: string | null = null;
}

export interface DropDown {
  key: string;
  text: string;
}

export interface CorporateScheduleDropDowns {
  status: DropDown[];
  types: DropDown[];
}

export interface CorporateScheduleTime {
  id: string;
  day: string;
  startTime: string;
  endTime: string;
  displayOrder: string;
  corporateSheduleId: string | null;
}

export interface CorporateSchedule {
  id: string | null;
  name: string | null;
  sequenceId: string;
  title: string;
  corporateSheduleTime: CorporateScheduleTime[];
}

export interface History {
  createdByUser: string | null;
  createdDateTime: string | null;
  revisionNumber: number | string | null;
  updatedByUser: string | null;
  updatedDateTime: string | null;
}

export const formData = {
  id: null,
  name: null,
  sequenceId: null,
  title: null,
  corporateSheduleTime: [],
};


import { useTranslation } from 'react-i18next';
import {
  getTheme,
  IDragOptions,
  ContextualMenu,
  IIconProps,
  mergeStyleSets,
  FontWeights,
  Modal,
  IconButton,
  Label,
  TextField,
  PrimaryButton,
  Dropdown,
  Stack,
  IStackStyles,
  IStackItemStyles,
  IStackTokens,
  Icon,
  ProgressIndicator,
} from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import { useId, useBoolean } from '@uifabric/react-hooks';
import _ from 'lodash';
import { uPrinceTheme } from '../../../../../theme';
import {
  RiskDropDown,
  Instruction,
  InstructionDropDown,
  InstructionLink,
  PbsInstructionLink,
} from '../../../../types/projectBreakdownStructure';
import { useDispatch, useSelector } from 'react-redux';
import CKEditor from '@ckeditor/ckeditor5-react';
import DataGrid from '../../../../shared/dataGrid/component';
import { ActionButtonState } from '../../../../types/corporateProductCatalogue';
import UprinceLogger from '../../../../shared/Logger/logger';
import { INSTRUCTION_TYPE } from '../../../ProjectBreakdownStructure/documentPane/container';
import TextEditor from '../../../../shared/textEditor/textEditor';
import CustomDropdown from '../../../../shared/customDropdown/customDropdown';
import { readByInstructionRegisterId } from '../../../../reducers/instructionRegisterReducer';
import { getProject } from '../../../../shared/util';
import client from '../../../../api';
import { clearFormData } from '../../../../reducers/projectReducer';

const theme = getTheme();

const classNames = mergeStyleSets({
  wrapper: {
    //height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: {
    maxWidth: 400,
  },
  textContent: {
    padding: '15px 10px',
  },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: {
    width: '100%',
  },
  editor: {
    width: '100%',
    paddingBottom: 100,
  },
});

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '40vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

class InstructionValidationMessages {
  nameValidationMsg: string = '';
  instructionFamilyValidationMsg: string = '';
}

const InstructionModal = (props: {
  isOpen: boolean;
  openInstructionModal: () => void;
  isEdit: boolean;
  //onFilterPersonChanged: any;
  createInstruction: (instruction: Instruction) => void;
  instruction: Instruction;
  title: string;
  selection: any;
  linkActionButtonState: ActionButtonState;
  openLinkModal: () => void;
  //links: InstructionLink[];
  handleLinkDelete: () => void;
  type: string;
  pbsId: string | null;
  readByInstructionRegisterId: (id: string) => void;
}) => {
  const { t } = useTranslation();

  const linkColumns = [
    {
      key: 'column1',
      name: t('title'),
      fieldName: 'title',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('link'),
      fieldName: 'link',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];
  const dispatch = useDispatch();
  const [isDraggable] = useBoolean(false);
  const titleId = useId('title');
  const dropdowns: InstructionDropDown[] = useSelector(
    (state: any) => state.pmol.instructionDropDowns
  );
  const [instruction, setInstruction] = useState(new Instruction());
  //const [person, setPerson] = useState<IPersona[]>([]);
  const [, setHasEdit] = useState(false);
  const [isProgress, setProgress] = useState(false);
  const [instructionValidationMessages, setInstructionValidationMsg] = useState<
    InstructionValidationMessages
  >(new InstructionValidationMessages());

  const onChangeInput = (field: string) => (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setInstruction((prevState) => ({
      ...prevState,
      [field]: newValue,
    }));
    setHasEdit(true);
    if (field === 'name') {
      setInstructionValidationState('nameValidationMsg', '');
    }
  };

  useEffect(() => {
    if (props.isEdit) {
      setInstruction({
        id: props.instruction.id,
        name: props.instruction.name,
        instructionType: props.instruction.instructionType,
        // pbsProductId: props.pbsId?null:null,
        pbsInstructionFamilyId: props.instruction.pbsInstructionFamilyId,
        pbsInstructionLink: props.instruction.pbsInstructionLink,
        isDeleted: props.instruction.isDeleted,
        isSaved: props.instruction.isSaved,
        sequenceCode: props.instruction.sequenceCode,
        title: props.instruction.title,
        headerTitle: props.instruction.headerTitle,
        pbsInstructionFamily: props.instruction.pbsInstructionFamily,
        instructionsDetails: props.instruction.instructionsDetails,
        instructionId: props.instruction.instructionId
      });
    } else {
      setProgress(false);
      setInstruction(new Instruction());
    }
  }, [props.instruction]);

  const validateInstruction = (instruction: Instruction) => {
    let isValidName = validateInstructionName(instruction);
    let isValidFamily = validateInstructionFamily(instruction);

    return isValidName && isValidFamily;
  };

  const validateInstructionName = (instruction: Instruction) => {
    let isValidName = false;
    if (instruction && instruction.name) {
      isValidName = true;
      setInstructionValidationState('nameValidationMsg', '');
    } else {
      isValidName = false;
      setInstructionValidationState('nameValidationMsg', t('nameRequired'));
    }
    return isValidName;
  };

  const validateInstructionFamily = (instruction: Instruction) => {
    let isValidName = false;
    if (instruction && instruction.pbsInstructionFamilyId) {
      isValidName = true;
      setInstructionValidationState('instructionFamilyValidationMsg', '');
    } else {
      isValidName = false;
      setInstructionValidationState(
        'instructionFamilyValidationMsg',
        t('instructionFamilyRequired')
      );
    }
    return isValidName;
  };

  const setInstructionValidationState = (key: string, msg: string) => {
    setInstructionValidationMsg((prevState) => ({
      ...prevState,
      [key]: msg,
    }));
  };

  const getTypes = () => {
    let data: any[] = [];
    if (dropdowns && props.type === INSTRUCTION_TYPE.TECH) {
      data = dropdowns.filter((item:any) =>{
        return item.type === 'technical';
      });
    } else  if (props.type === INSTRUCTION_TYPE.ENVIRONMENT || props.type === INSTRUCTION_TYPE.HELTH || props.type === INSTRUCTION_TYPE.SAFTY) {
      data = dropdowns.filter((item:any) =>{
        return item.type === 'other';
      });
    }

    return data;
  };


  const onRenderCaretDown = (): JSX.Element => {
    return (
      <Icon
        iconName="ChevronDown"
        onClick={() => {
          //alert('test');
        }}
      />
    );
  };

  const instructionOptions = (inputValue: any) =>
    new Promise((resolve) => {
      resolve(getinstructionByName(inputValue));
    });

  const getinstructionByName = async (name: string) => {
    const filter = {
      title: name,
      pbsProductId: props.pbsId,
      instructionType: props.type,
      pbsInstructionFamilyId: null,
      sorter: {
        attribute: 'title',
        order: 'asc'
      }
    };
    const header = getProject() ? { headers: { project: getProject() } } : undefined;
    if (name.length >= 2) {
      const response = await client.post(
        'PbsInstruction/InstructionsFilter',
        filter, header
      );
      return formatInstructionResponse(response);
    }
  };
  const formatInstructionResponse = (response: any) => {
    let options: { value: string; label: string }[] = [];
    if (response && response.data && response.data.result) {
      const data = response.data.result;

      if (data && _.isArray(data)) {
        options = data.map((item: any) => {
          return {
            value: item.sequenceCode,
            label: item.title
          };
        });
      }
      return options;
    }
  };

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          setInstructionValidationMsg(new InstructionValidationMessages());
          props.openInstructionModal();
        }}
        containerClassName={contentStyles.container}
        isBlocking={false}
        dragOptions={undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>{props.title}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              setInstructionValidationMsg(new InstructionValidationMessages());
              props.openInstructionModal();
            }}
          />
        </div>
        {/* <div className={contentStyles.subHeader}>
          <ActionButton iconProps={addIcon} allowDisabledFocus>
            <Label className={contentStyles.actionButtonLabel}>
              {!props.isEdit ? t('add') : t('save')}
            </Label>
          </ActionButton>
        </div> */}
        {isProgress && (
          <ProgressIndicator styles={{ root: { marginTop: -10 } }} />
        )}
        <div className={contentStyles.body}>
          <div className="proj-detail-content inner">
            <div className="ms-Grid-row">
              <Label>1. {t('instructionDetails')} </Label>
              {/* <div className="document-pane-card"> */}
              {props.instruction.headerTitle && (
                <Stack
                  horizontal
                  wrap
                  styles={stackStyles}
                  tokens={stackTokens}
                >
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        disabled={true}
                        label={t('instructionID')}
                        readOnly={true}
                        value={
                          instruction && instruction.sequenceCode
                            ? instruction.sequenceCode
                            : ''
                        }
                      />
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        disabled={true}
                        label={t('instructionTitle')}
                        value={
                          instruction && instruction.title
                            ? instruction.title
                            : ''
                        }
                        errorMessage={''}
                        //onChange={onChangeInput('vat')}
                      />
                    </div>
                  </Stack.Item>
                </Stack>
              )}
              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    {/* <TextField
                      autoFocus={true}
                      label={t('instructionName')}
                      value={
                        instruction && instruction.name ? instruction.name : ''
                      }
                      required={true}
                      errorMessage={
                        instructionValidationMessages.nameValidationMsg
                      }
                      onChange={onChangeInput('name')}
                    /> */}

                    <Label required={true}>{t('instructionName')}</Label>
                    <CustomDropdown
                      reference={''}
                      onChange={(value: any, label: string) => {
                        if (value) {
                          setInstruction((prevState) => ({
                            ...prevState,
                            name: label,
                            id: value
                          }));
                          //call ins get by id  >>

                          dispatch(readByInstructionRegisterId(value));
                          // console.log("DUMI",setInstruction());

                        } else {
                          setInstruction((prevState) => ({
                            ...prevState,
                            name: null,
                            id: null
                          }));
                        }
                      }}
                      selectedOption={(instruction && instruction?.id ? {
                        value: instruction?.id,
                        label: instruction?.name
                      } : null)}
                      promiseOptions={instructionOptions}
                      validationMessage={instructionValidationMessages.nameValidationMsg}
                      required={true}
                    />
                  </div>
                </Stack.Item>

                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <Dropdown
                      placeholder={t('instructionFamily')}
                      label={t('instructionFamily')}
                      options={getTypes()}
                      required={true}
                      selectedKey={
                        instruction &&
                        instruction.pbsInstructionFamily &&
                        instruction.pbsInstructionFamily.key &&
                        props.isEdit
                          ? instruction.pbsInstructionFamily.key
                          : instruction.pbsInstructionFamilyId
                      }
                      onRenderCaretDown={onRenderCaretDown}
                      errorMessage={
                        instructionValidationMessages.instructionFamilyValidationMsg
                      }
                      onChange={(
                        _event: React.FormEvent<HTMLDivElement>,
                        item: any
                      ) => {
                        setInstructionValidationState(
                          'instructionFamilyValidationMsg',
                          ''
                        );
                        setInstruction((prevState) => ({
                          ...prevState,
                          pbsInstructionFamilyId: item.key,
                          pbsInstructionFamily: {
                            key: item.key,
                            text: item.text,
                          },
                        }));
                      }}
                    />
                  </div>
                </Stack.Item>
              </Stack>
              <br />
              <Label style={{ marginLeft: 10, marginRight: 10 }}>
                {t('instructionDetails')}
              </Label>
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                <TextEditor
                  value={instruction.instructionsDetails}
                  // config={{ height: 100, marginBottom: 20 }}
                  onChange={(evt: any) => {
                    setInstruction((prevState) => ({
                      ...prevState,
                      instructionsDetails: evt,
                    }));
                  }}
                />
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <DataGrid
                dataList={props.instruction && props.instruction.pbsInstructionLink
                  ? props.instruction.pbsInstructionLink
                  : []}
                editDataGrid={() => props.openLinkModal()}
                openModel={() => props.openLinkModal()}
                selection={props.selection}
                actionButtonState={props.linkActionButtonState}
                title={t('links')}
                deleteDataGrid={() => props.handleLinkDelete()}
                readOnly={false}
                columns={linkColumns}
                isLoaded={false}
              />
            </div>
          </div>
        </div>
        {/* <pre>{JSON.stringify(instruction, 0, 2)}</pre>  */}
        {/* </div> */}

        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={addIconWhite}
            text={t('save')}
            style={{ marginTop: 20, marginBottom: 30 }}
            onClick={() => {
              // instruction.pbsProductId = props.pbsId;
              instruction.instructionType = props.type;
              instruction.pbsInstructionLink = props.instruction && props.instruction.pbsInstructionLink
              ? props.instruction.pbsInstructionLink
              : []
              if (validateInstruction(instruction)) {
                setProgress(true);
                props.createInstruction(instruction);
                props.openInstructionModal()
              }
            }}
          />

          {!props.isEdit && <PrimaryButton
              iconProps={addIconWhite}
              text={t('saveandNew')}
              style={{ marginTop: 20, marginBottom: 10, marginLeft:10}}
              onClick={() => {
                // instruction.pbsProductId = props.pbsId;
                instruction.instructionType = props.type;
                instruction.pbsInstructionLink = props.instruction && props.instruction.pbsInstructionLink
                ? props.instruction.pbsInstructionLink
                : []
                if (validateInstruction(instruction)) {
                  setProgress(true);
                  props.createInstruction(instruction);
                  setInstruction(new Instruction());

                }
              }}
              //disabled={disableSave}
          />}

        </div>
      </Modal>
    </div>
  );
};

export default InstructionModal;

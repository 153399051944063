import React from 'react';
import { connect } from 'react-redux';
import DocumentPaneComponent from './component';
import { WithTranslation, withTranslation } from 'react-i18next';
import _ from 'lodash';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import history from '../../../history';
import { messageService } from '../../../services/messageService';
import { getProject } from '../../../shared/util';

import {
  getAllProjectDropdowns,
  getProjectDefinition,
  getProjectHeaderDetails,
} from '../../../reducers/projectReducer';
import { CompetenceTaxonomy, Competency, formData } from '../../../types/competency';
import {
  createCompetancyTaxonomy,
  readByCompetencyId,
  readCompetancyTaxonomyList,
  saveFormData,
} from '../../../reducers/competencyReducer';
import { v4 as uuidv4 } from 'uuid';

export interface State {
  isDirty: boolean;
  redirect: boolean;
  loading: boolean;
  isClear: boolean;
  isEdit: boolean;
  isCostModalOpen: boolean;
  showPrint: boolean;
  isApproved: boolean;
  ledgerDropDowns: { key: string; text: string }[];
  generalLedger: string;
  resourceData: any;
  openPOResourceModal: boolean;
  uid: string | null;
}

interface Props extends WithTranslation {
  compDropDowns: any;
  saveFormData: (data: Competency) => void;
  getAllProjectDropdowns: () => void;
  formData: any;
  selectedShortCutItem: any;
  competencyTaxonomyList: CompetenceTaxonomy[];
  createCompetancyTaxonomy: (taxonomy: CompetenceTaxonomy) => void;
  isNew: boolean;
  uid: string;
  isNewCOMP: boolean;
  dropDowns: any;
  isLoadingCompetency: boolean;
  isExpand: boolean;
  readCompetancyTaxonomyList: any;
  readByCompetencyId: (id: string) => void;
  compID: string
}

class DocumentPane extends React.Component<Props, any> {
  subscription: any;
  submitExternal: any;
  form: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      isDirty: false,
      isEdit: false,
      redirect: false,
      loading: false,
      formData: formData,
      showPrint: false,
      isApproved: false,
      uid: null,
      isClear: false,
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevProps.isNew !== this.props.isNew ||
      prevProps.uid !== this.props.uid
    ) {
      if (this.props.isNew) {
        const formData2 = this.state.formData;
        formData2.id = this.props.uid;
        this.setState({ formData, isClear: false });
      }
    }
    if (prevProps.formData !== this.props.formData) {
      if (!this.state.isDirty) {
        if (!_.isEmpty(this.props.formData)) {
          const formData3 = this.props.formData;
          this.setState({ formData: formData3, isClear: false });
        }
      } else {
        if (this.props.isNew && _.isEmpty(this.props.formData)) {
          const formData4 = this.state.formData;
          this.setState({ formData: formData4, isClear: false });
        }
      }
    }

    if (prevProps.compID !== this.props.compID) {
      if (this.props.compID) {
        this.props.readByCompetencyId(this.props.compID);
      }
    }
  }

  componentDidMount() {
    // get document pane dropdowns
    // this.props.getAllProjectDropdowns();
    this.setState({ uid: uuidv4(), isClear: this.props.isNewCOMP });
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewCOMP) {
          if (getProject()) {
            this.setState({ uid: uuidv4(), isClear: true });
          } else {
            this.setState({ uid: uuidv4(), isClear: true });
          }
          if (this.form) {
            this.form.reset();
            this.form.resetFieldState('name');
            // this.form.resetFieldState('header.cpcResourceTitle');
          }
        }
        if (data.data.saveCOMPData) {
          // this.saveFormData();
          this.submitExternal();
        }
        if (data.data.compFilter) {
          this.setState({ filter: data.data.comp });
        }
        if (data.data.pdfGenerate) {
          this.setState({ showPrint: true });
        }

        if (data.data.createNewNode) {
          this.props.createCompetancyTaxonomy(data.data.createNewNode);
        }

        if (data.data.closeTaxonomyModal) {
          this.props.readCompetancyTaxonomyList();
        }
      }
    });
    //   if (getProject()) {
    //       const projectId = getProject();
    //       if (projectId) {
    //           this.props.getProjectHeaderDetails(projectId);
    //       }
    //   } else if (this.props.formData?.id) {
    //       this.props.getProjectHeaderDetails(this.props.formData?.id);
    //   }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { psFilter: this.state.filter },
    });
  };

  setSubmitExternal = (submit: any) => {
    this.submitExternal = submit;
  };

  setForm = (form: any) => {
    this.form = form;
  };


  render() {
    return (
      <div style={{ width: '100%' }}>
        <RouteLeavingGuard
          when={this.state.isDirty}
          navigate={(path) => this.handleDiscardChangesNavigate(path)}
          shouldBlockNavigation={() => {
            return true;
          }}
          handleConfirmation={(isConfirm: boolean) => {
            this.setState({ isDirty: !isConfirm });
          }}
        />
        <DocumentPaneComponent
          submit={(submit: any) => {
            this.setSubmitExternal(submit);
          }}
          dropDowns={this.props.dropDowns}
          saveFormData={this.props.saveFormData}
          uid={this.state.uid}
          formData={this.props.formData}
          showProgress={this.props.isLoadingCompetency}
          formValues={this.form?.getState()?.values}
          isClear={this.state.isClear}
          isExpand={this.props.isExpand}
          setForm={(form: any) => {
            this.setForm(form);
          }}
          competencyTaxonomyList={this.props.competencyTaxonomyList}

          // selectedShortCutItem={this.props.selectedShortCutItem}
        />

      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isEdit: state.ps.isEdit,
    isNew: state.ps.isNew,
    uid: state.ps.uid,
    projectHeader: state.project.projectHeader,
    showProgressBar: state.comp.showProgressBar,
    selectedProject: state.project.selectedProject,
    dropDowns: state.comp.compDropDowns,
    selectedShortCutItem: state.os.selectedShortCutItem,
    competencyTaxonomyList: state.comp.competencyTaxonomyList,
    compID: state.comp.compID,
  };
};

const mapDispatchToProps = {
  saveFormData,
  getProjectHeaderDetails,
  getAllProjectDropdowns,
  getProjectDefinition,
  createCompetancyTaxonomy,
  readCompetancyTaxonomyList,
  readByCompetencyId,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DocumentPane));

import React from 'react';
import { connect } from 'react-redux';
import DocumentPaneComponent from './component';
import { WithTranslation, withTranslation } from 'react-i18next';
import _ from 'lodash';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import history from '../../../history';
import { messageService } from '../../../services/messageService';
import { getProject } from '../../../shared/util';

import {
  getAllProjectDropdowns,
  getProjectDefinition,
  getProjectHeaderDetails,
} from '../../../reducers/projectReducer';
import { Certification, formData } from '../../../types/certification';
import {
  createCertificationTaxonomy,
  readByCertificationId,
  readCertificationTaxanomyList,
  saveFormData,
} from '../../../reducers/certificationReducer';
import { v4 as uuidv4 } from 'uuid';

export interface State {
  isDirty: boolean;
  redirect: boolean;
  loading: boolean;
  isClear: boolean;
  isEdit: boolean;
  isCostModalOpen: boolean;
  showPrint: boolean;
  isApproved: boolean;
  ledgerDropDowns: { key: string; text: string }[];
  generalLedger: string;
  resourceData: any;
  openPOResourceModal: boolean;
  uid: string | null;
}

interface Props extends WithTranslation {
  certDropDowns: any;
  saveFormData: (data: Certification) => void;
  getAllProjectDropdowns: () => void;
  formData: Certification;
  readCertificationTaxanomyList: any;
  createCertificationTaxonomy: any;
  isNew: boolean;
  uid: string;
  isNewCERT: boolean;
  dropDowns: any;
  isLoadingCertification: boolean;
  isExpand: boolean;
  certificationTaxonomyList: any;
  certID: string | null;
  readByCertificationId: (id: string) => void
}

class DocumentPane extends React.Component<Props, any> {
  subscription: any;
  submitExternal: any;
  form: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      isDirty: false,
      isEdit: false,
      redirect: false,
      loading: false,
      formData: formData,
      showPrint: false,
      isApproved: false,
      uid: null,
      isClear: false,
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevProps.isNew !== this.props.isNew ||
      prevProps.uid !== this.props.uid
    ) {
      if (this.props.isNew) {
        const formData2 = this.state.formData;
        formData2.id = this.props.uid;
        this.setState({ formData, isClear: false });
      }
    }

    if (prevProps.certID !== this.props.certID) {
      if (this.props.certID) {
        this.props.readByCertificationId(this.props.certID);
      }
    }

    if (prevProps.formData !== this.props.formData) {
      if (!this.state.isDirty) {
        if (!_.isEmpty(this.props.formData)) {
          const formData3 = this.props.formData;
          this.setState({ formData: formData3, isClear: false });
        }
      } else {
        if (this.props.isNew && _.isEmpty(this.props.formData)) {
          const formData4 = this.state.formData;
          this.setState({ formData: formData4, isClear: false });
        }
      }
    }
  }

  componentDidMount() {
    // get document pane dropdowns
    // this.props.getAllProjectDropdowns();
    this.setState({ uid: uuidv4(), isClear: this.props.isNewCERT });

    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewCERT) {
          if (getProject()) {
            this.setState({ uid: uuidv4(), isClear: true });
          } else {
            this.setState({ uid: uuidv4(), isClear: true });
          }

          if (this.form) {
            this.form.reset();
            this.form.resetFieldState('name');
            // this.form.resetFieldState('header.cpcResourceTitle');
          }
        }
        if (data.data.saveCERTData) {
          // this.saveFormData();
          this.submitExternal();
        }
        if (data.data.certFilter) {
          this.setState({ filter: data.data.cert });
        }
        if (data.data.pdfGenerate) {
          this.setState({ showPrint: true });
        }

        if (data.data.createNewNode) {
          this.props.createCertificationTaxonomy(data.data.createNewNode);
        }

        if (data.data.closeCertTaxonomyModal) {
          this.props.readCertificationTaxanomyList();
        }
      }
    });
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { psFilter: this.state.filter },
    });
  };

  setSubmitExternal = (submit: any) => {
    this.submitExternal = submit;
  };

  setForm = (form: any) => {
    this.form = form;
  };


  render() {
    return (
      <div style={{ width: '100%' }}>
        <RouteLeavingGuard
          when={this.state.isDirty}
          navigate={(path) => this.handleDiscardChangesNavigate(path)}
          shouldBlockNavigation={() => {
            return true;
          }}
          handleConfirmation={(isConfirm: boolean) => {
            this.setState({ isDirty: !isConfirm });
          }}
        />
        <DocumentPaneComponent
          submit={(submit: any) => {
            this.setSubmitExternal(submit);
          }}
          dropDowns={this.props.dropDowns}
          saveFormData={this.props.saveFormData}
          uid={this.state.uid}
          formData={this.props.formData}
          showProgress={this.props.isLoadingCertification}
          formValues={this.form?.getState()?.values}
          isClear={this.state.isClear}
          isExpand={this.props.isExpand}
          setForm={(form: any) => {
            this.setForm(form);
          }}
          certificationTaxonomyList={this.props.certificationTaxonomyList}
        />

      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isEdit: state.ps.isEdit,
    isNew: state.ps.isNew,
    uid: state.ps.uid,
    projectHeader: state.project.projectHeader,
    showProgressBar: state.cert.showProgressBar,
    selectedProject: state.project.selectedProject,
    dropDowns: state.cert.certDropDowns,
    certificationTaxonomyList: state.cert.certificationTaxonomyList,
    certID: state.cert.certID,
  };
};

const mapDispatchToProps = {
  saveFormData,
  getProjectHeaderDetails,
  getAllProjectDropdowns,
  getProjectDefinition,
  readCertificationTaxanomyList,
  createCertificationTaxonomy,
  readByCertificationId,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DocumentPane));

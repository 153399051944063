import React, { useEffect, useState } from 'react';
import { Dropdown, IconButton, Modal, PrimaryButton, TextField } from '@fluentui/react';
import { readWbsTaxonomyByTemId, saveNewWbsNode, saveWbsCreateStateAttr } from '../../../../reducers/wbsCreateReducer';
import { NodeData, WBS_TAXONOMY_LEVEL } from '../../../../types/wbs';
import { messageService } from '../../../../services/messageService';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useParams } from 'react-router';
import { ParamTypes } from '../../../../types/uPrince';
import { v4 as uuidv4 } from 'uuid';
import { addIconWhite, cancelIconButtonStyles, contentStyles } from '../WbsCreateDocument';
import ProgressBar from '../../../../shared/progressBar/progressBar';
import { getProject } from '../../../../shared/util';
import { getWbsTaxonomy } from '../../../../reducers/wbsTaskReducer';

/**
 * AddTopNodeModel Component
 *
 * This component provides a modal for adding or editing WBS (Work Breakdown Structure) nodes.
 *
 * @returns {JSX.Element} - The rendered AddTopNodeModel component.
 */
const AddTopNodeModel = () => {
  const { t } = useTranslation();
  // const titleId = useId('title');
  const { featureId }: ParamTypes = useParams();
  const dispatch = useDispatch();

  /** Redux state selectors */
  const wbsLevelsData = useSelector((state: any) => state.wbsCreate.wbsLevelsData);
  const showWbsProgressBar = useSelector((state: any) => state.wbsCreate.showWbsProgressBar);
  const projectWbsTemplateId = useSelector((state: any) => state.wbs.projectWbsTemplateId);

  /** State variables */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // const [isNewChild, setIsNewChild] = useState<boolean>(false);
  const [addTopNode, setAddTopNode] = useState<boolean>(false);
  const [newNodeName, setNewNodeName] = useState<string | undefined>(undefined);
  const [editNodeName, setEditNodeName] = useState<string | undefined>(undefined);
  const [selectedLevel, setSelectedLevel] = useState<{ key: string, text: string }>({ key: '', text: '' });
  const [nameErrorMsg, setNameErrorMsg] = useState<string>('');
  const [levelErrorMsg, setLevelErrorMsg] = useState<string>('');
  const [wbsRowInfo, setWbsRowInfo] = useState<any>(null);

  /**
   * Validate the form inputs.
   *
   * @returns {boolean} - True if the inputs are valid; otherwise, false.
   */
  const validate = (): boolean => {
    let isValid = true;
    if (isEdit) {
      if (_.isNil(editNodeName) || _.isEmpty(editNodeName)) {
        setNameErrorMsg(t('nameRequired'));
        isValid = false;
      } else {
        setNameErrorMsg('');
      }
    } else {
      if (_.isNil(newNodeName) || _.isEmpty(newNodeName)) {
        setNameErrorMsg(t('nameRequired'));
        isValid = false;
      } else {
        setNameErrorMsg('');
      }
    }
    console.log(selectedLevel);
    if (_.isNil(selectedLevel.key) || _.isEmpty(selectedLevel.key)) {
      setLevelErrorMsg(t('levelRequired'));
      isValid = false;
    } else {
      setLevelErrorMsg('');
    }

    return isValid;
  };

  useEffect(() => {
    /** Subscribe to messages for opening the modal and editing nodes */
    const subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.addWbsTopNode) {
          setIsOpen((prevState: boolean) => !prevState);
          setAddTopNode(true);
          setSelectedLevel({ key: WBS_TAXONOMY_LEVEL.WBS, text: 'WBS' });
        }
        if (data.data.wbsTaxonomyRowInfo) {
          const { isEditWbsNode, isAddNewWbsChildNode, node } = data.data.wbsTaxonomyRowInfo;
          // console.log('wbsTaxonomyRowInfo >>>', data.data.wbsTaxonomyRowInfo);
          setIsOpen((prevState: boolean) => !prevState);
          setWbsRowInfo(data.data.wbsTaxonomyRowInfo);
          setAddTopNode(false);
          setIsEdit(isEditWbsNode);
          // setIsNewChild(isAddNewWbsChildNode);
          setEditNodeName(node.title);
          setSelectedLevel({
            key: isEditWbsNode ? node.wbsTaxonomyLevelId : '',
            text: isEditWbsNode ? node.wbsTaxonomyLevel : '',
          });
        }
      }
    });

    return () => {
      /** Unsubscribe when the component is unmounted */
      subscription.unsubscribe();
    };
  }, []);

  /**
   * Handle the save action for adding a new node.
   */
  const handleSave = () => {
    // console.log({ wbsRowInfo });
    let newId = uuidv4();
    const newNode: NodeData = {
      templateId: getProject() ? projectWbsTemplateId : featureId,
      id: newId,
      title: newNodeName,
      // key: newNodeKey,
      wbsTaxonomyLevelId: selectedLevel ? selectedLevel.key : null,
      parentId: addTopNode ? null : wbsRowInfo?.node?.id,
      isDefault: false,
      // displayOrder: selectedLevel ? selectedLevel.displayOrder : null,
      // children: [],
    };

    if (validate()) {
      Promise.all([dispatch(saveNewWbsNode(newNode))]).then((res: any) => {
        if (res) {
          dispatch(readWbsTaxonomyByTemId(getProject() ? projectWbsTemplateId : featureId));
          dispatch(getWbsTaxonomy());
          dispatch(saveWbsCreateStateAttr('selectedNodeId', newId));
          handleModalClose();
        }
      });
    }
  };

  /**
   * Handle the save action for editing an existing node.
   */
  const handleEdit = () => {
    const editNode: NodeData = {
      templateId: getProject() ? projectWbsTemplateId : featureId,
      id: wbsRowInfo?.node?.id,
      title: editNodeName,
      // key: newNodeKey,
      wbsTaxonomyLevelId: selectedLevel ? selectedLevel.key : null,
      parentId: wbsRowInfo?.node?.parentId,
      isDefault: false,
      // displayOrder: selectedLevel ? selectedLevel.displayOrder : null,
      // children: [],
    };

    if (validate()) {
      Promise.all([dispatch(saveNewWbsNode(editNode))]).then((res: any) => {
        if (res) {
          dispatch(readWbsTaxonomyByTemId(getProject() ? projectWbsTemplateId : featureId));
          dispatch(getWbsTaxonomy());
          dispatch(saveWbsCreateStateAttr('selectedNodeId', wbsRowInfo?.node?.id));
          handleModalClose();
        }
      });
    }
  };

  /**
   * Handle closing the modal and resetting state variables.
   */
  const handleModalClose = () => {
    setWbsRowInfo(null);
    setIsOpen(false);
    setAddTopNode(false);
    setNewNodeName('');
    setEditNodeName('');
    setSelectedLevel({ key: '', text: '' });
    setNameErrorMsg('');
    setLevelErrorMsg('');
  };

  /**
   * Format dropdown options based on the selected level.
   *
   * @param {any[]} options - The available dropdown options.
   * @returns {any[]} - The filtered dropdown options.
   */
  const formatDropdownOptions = (options: any[]) => {
    const wbsTaxonomyLevelId = wbsRowInfo?.node?.wbsTaxonomyLevelId;

    // const opt = [
    //   { 'key': '41ce52c0-058b-wbs-afbd-1d2d24105ebc', 'text': 'WBS' },
    //   { 'key': 'e1ce52c0-058b-prod-afbd-1d2d24105ebc', 'text': 'Product' },
    //   { 'key': 'i1ce52c0-058b-issu-afbd-1d2d24105ebc', 'text': 'Task' },
    //   { 'key': 'pouoe52c0-hvkhl-lbjm-jvhj-1d2d241ouyi', 'text': 'Issue' },
    // ];

    if (isEdit) {
      return options;
    } else {
      switch (wbsTaxonomyLevelId) {
        case WBS_TAXONOMY_LEVEL.WBS:
          return options.filter((item: any) => item.key !== WBS_TAXONOMY_LEVEL.TASK && item.key !== WBS_TAXONOMY_LEVEL.ISSUE);
        case WBS_TAXONOMY_LEVEL.PRODUCT:
          return options.filter((item: any) => item.key !== WBS_TAXONOMY_LEVEL.WBS && item.key !== WBS_TAXONOMY_LEVEL.PRODUCT);
        default:
          return options;
      }
    }
  };

  /**
   * Render the name field for adding a new node.
   *
   * @returns {JSX.Element} - The rendered name field.
   */
  const renderNameField = () => {
    return (
      <TextField
        autoComplete='off'
        label={t('name')}
        value={newNodeName}
        required={true}
        onChange={(event, value) => {
          setNewNodeName(value);
          // setNewNodeKey(null);
          // resetValidation();
        }}
        errorMessage={nameErrorMsg ?? ''}
        componentRef={(input: any) => input && addTopNode && input.focus()}
      />);
  };

  /**
   * Render the name field for editing an existing node.
   *
   * @returns {JSX.Element} - The rendered name field.
   */
  const renderEditNameField = () => {
    // if (selectedNode) {
    return (
      <div>
        <TextField
          autoComplete='off'
          label={t('name')}
          value={editNodeName}
          required={true}
          onChange={(event, value) => {
            setEditNodeName(value);
            // resetValidation();
          }}
          errorMessage={nameErrorMsg ?? ''}
          componentRef={(input: any) => input && input.focus()}
        /></div>
    );
    // }
  };

  return (
    <Modal
      titleAriaId={'titleId'}
      isOpen={isOpen}
      onDismiss={() => handleModalClose()}
      isBlocking={true}
      dragOptions={undefined}
    >
      <div className={contentStyles.header}>
        <span id={'titleId'}>{isEdit ? t('editNode') : t('addNode')} </span>
        <IconButton
          styles={cancelIconButtonStyles}
          iconProps={{ iconName: 'Cancel' }}
          ariaLabel='Close popup modal'
          onClick={() => handleModalClose()}
        />
      </div>
      <ProgressBar show={!!showWbsProgressBar} />
      <div className={contentStyles.body}>
        <div>
          <div className='ms-Grid-row'>
            <div className='ms-Grid-col ms-sm12 ms-md12 ms-lg12'>
              <Dropdown
                errorMessage={levelErrorMsg}
                required={true}
                label={t('level')}
                placeholder={t('level')}
                options={wbsLevelsData ? formatDropdownOptions(wbsLevelsData?.wbsTaxonomyLevel) : []}
                defaultSelectedKey={addTopNode ? WBS_TAXONOMY_LEVEL.WBS : selectedLevel?.key}
                onChange={(event, value) => {
                  setLevelErrorMsg('');
                  // {key, text}
                  console.log('setSelectedLevel', value);
                  if (value) {
                    setSelectedLevel({ key: value?.key.toString(), text: value?.text });
                  } else {
                    setSelectedLevel({ key: '', text: '' });
                  }
                }}
                disabled={addTopNode || isEdit}
              />
              {isEdit ? renderEditNameField() : renderNameField()}
            </div>
          </div>
        </div>
      </div>
      <div className={contentStyles.footer}>
        {isEdit ? <PrimaryButton
            iconProps={addIconWhite}
            text={t('save')}
            style={{ marginTop: 10, marginBottom: 10, marginRight: 10 }}
            onClick={() => {
              handleEdit();
            }}
          /> :
          <PrimaryButton
            iconProps={addIconWhite}
            text={t('add')}
            style={{ marginTop: 10, marginBottom: 10, marginRight: 10 }}
            onClick={() => {
              handleSave();
            }}
            disabled={showWbsProgressBar}
          />
        }
      </div>
      {/*<div>*/}
      {/*  {JSON.stringify(selectedLevel)}*/}
      {/*</div>*/}
    </Modal>
  );
};

export default AddTopNodeModel;

import * as React from 'react';
import { useEffect } from 'react';
import { Divider, Label } from '@fluentui/react-components';
import i18n from 'i18next';
import { Timer24Regular } from '@fluentui/react-icons/lib/fonts';
import { formatReadableDate } from '../../../../shared/util';
import moment from 'moment/moment';

interface Props {
  value: { creationDate: string | undefined };
  onChange: any;
  label?: string;
}

const TimeLine = (props: Props) => {
  const { value, onChange, label } = props;

  useEffect(() => {
    // console.log('time line mount', value);

    return () => {
      console.log('time line unmount');
    };
  }, []);

  return (
    <>
      <div className='time-line-wrapper'>
        <Label size='large'>{i18n.t('timeLine')}</Label>
        <Divider className='divider-styles' />
        {/** timeline table can be made dynamic if needed
         *   to do that, loop the  <tr></tr>
         */}
        <div className='time-line-item-wrapper'>
          <table style={{ borderSpacing: '8px 0' }}>
            <tbody>
            <tr>
              <td>
                <div className='time-line-date-style'>
                  <Label>{formatReadableDate(value?.creationDate)}</Label>
                  <Label>{moment.utc(value?.creationDate).local().format('HH:mm (ddd)')}</Label>
                </div>
              </td>

              <td>
                <Timer24Regular />
              </td>

              <td>
                <div className='time-line-title-style'>
                  <Label weight='semibold'>{label ?? i18n.t('Task Created')}</Label>
                  {/*<Label size='small'>{i18n.t('subtitle')}</Label>  // Don't remove this line. figma design has this subtitle*/}
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );

};

export default TimeLine;

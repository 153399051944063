import React, { useEffect, useState } from 'react';
import './docPreview.css';
import i18n from '../../../../i18n';
import { mergeStyleSets, Spinner, SpinnerSize } from '@fluentui/react';
import * as XLSX from 'xlsx';

const styles = mergeStyleSets({
  previewXlsCsv: {
    height: 'calc(100% - 20px)', 
    overflow: 'auto', 
    padding: 10
  },
  previewPptx: {
    overflow: 'hidden', 
    height: '100%', 
    width: '100%'
  },
  iframe: {
    width: '100%',
    height: '100%'
  },
  centered: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center',
    height: '100%'
  },
})

const DocumentPreview = ({ url }: {url: any}) => {
  const [isLoading, setisLoading] = useState<boolean>(false)
  const [fileType, setFileType] = useState<string | null>(null);
  const [data, setData] = useState<{csv: any, xls: any}>({ csv: null, xls: null });

  useEffect(() => {
    if (url) {
      const extension = url?.split('.').pop().toLowerCase();
      setFileType(extension);
    }
  }, [url]);

  useEffect(() => {
    if (fileType) {
      if (fileType === 'csv') {
        fetchCsv(url);
      } else if (fileType === 'xls' || fileType === 'xlsx') {
        fetchXls(url);
      }
    }
  }, [fileType, url]);

  const fetchCsv = async (url: string | null) => {
    if (url) {
      try {
        setisLoading(true)
        const response = await fetch(url);
        const text = await response.text();
        const rows = text.split('\n').map(row => row.split(','));
        setData({ ...data, csv: rows });
      } catch (error) {
        console.error("Error fetching CSV:", error);
      } finally {
        setisLoading(false)
      }
    }
  };

  const fetchXls = async (url: any) => {
    try {
      setisLoading(true)
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      setData({ ...data, xls: jsonData });
    } catch (error) {
      console.error("Error fetching Excel:", error);
    } finally {
      setisLoading(false)
    }
  };

  const renderCsvTable = (data: any) => (
    <div className={styles.previewXlsCsv}>
      {data?.length > 0 && (
        <table className="csv-table">
          <thead>
            <tr>
              {data[0]?.map((header: any, index: number) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.slice(1).map((row: any, index: number) => (
              <tr key={index}>
                {row.map((cell: any, idx: any) => (
                  <td key={idx}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );

  const renderXlsTable = (data: any) => (
    <div className={styles.previewXlsCsv}>
      {data?.length > 0 && (
        <table className="csv-table">
          <thead>
            <tr>
              {data[0]?.map((header: any, index: any) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.slice(1).map((row: any, index: any) => (
              <tr key={index}>
                {row.map((cell: any, idx: any) => (
                  <td key={idx}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );

  // const renderXlsTable = (url: string | null) => (
  //   url &&
  //   <div className={styles.previewPptx}>
  //     <iframe
  //       src={`https://docs.google.com/gview?url=${encodeURIComponent(url)}&embedded=true`}
  //       className={styles.iframe}
  //       allowFullScreen={false}
  //       title="XLS Preview"
  //     />
  //   </div>
  // )

  const renderPptx = (url: string | null) => (
    url &&
    <div className={styles.previewPptx}>
      <iframe
        src={`https://docs.google.com/gview?url=${encodeURIComponent(url)}&embedded=true`}
        className={styles.iframe}
        allowFullScreen={false}
        title="PPTX Preview"
      />
    </div>
  );

  const loader = () => <div className={styles.centered}><Spinner size={SpinnerSize.large} /></div>

  const renderContent = () => {
    switch (fileType) {
      case 'pdf':
        return <iframe src={url} title="PDF Preview" className={styles.iframe} />;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return <img src={url} alt="Preview" className={styles.iframe} />;
      case 'csv':
        return renderCsvTable(data.csv);
      case 'xls':
      case 'xlsx':
        return renderXlsTable(data.xls);
      case 'pptx':
        return renderPptx(url);
      default:
        return  <div className={styles.centered}>
                  <p>{i18n.t(`This file type .${fileType} cannot be viewed`)}</p>
                </div>;
    }
  };

  return (
    <div style={{ height: 'calc(100% - 46px)' }}>
      {isLoading ? loader() : renderContent()}
    </div>
  );
};

export default DocumentPreview;

import React, { Component } from 'react';
import './component.css';
import QRCodeMainLayout from './qrCode/qrCodeMainLayout';
import UprinceNavigation from './../shared/uprinceNavigation/cuProjectNavigation/component';

interface Props {
  currentUser: any;
  organization: any
}

export interface State {
  component: string;
}

class QRCodes extends Component<Props, State> {
  render() {
    return (
      <UprinceNavigation
        currentUser={this.props.currentUser}
        selectedNavigationIndex={2}
      >
        <QRCodeMainLayout/>
      </UprinceNavigation>

    );
  }
}

export default QRCodes;

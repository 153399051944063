import {
  ContextualMenuItemType,
  FontWeights,
  getTheme,
  Icon,
  IIconProps,
  mergeStyleSets,
  Pivot,
  PivotItem,
  PrimaryButton,
} from '@fluentui/react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import history from '../../history';
import { hasPermissions } from '../util';
import './navBar.css';
import { iconColors, uPrinceTheme } from '../../../theme';
import MenuCard from '../menuCard/menu/component';
import { AccessLevel, ORG_FEATURS } from '../../types/permissions';
import { messageService } from '../../services/messageService';
import ENV from '../../../env.json';

const theme = getTheme();

const pivotStyle = {
  root: {
    maxWidth: '91vw',
    float: 'left',
    selectors: { '& .is-selected i': { color: iconColors.iconActiveColor } },
  },
  icon: { color: uPrinceTheme.palette.themePrimary },
  linkIsSelected: { selectors: { ':before': { marginBottom: '0px' } } },
};

class NavBar extends React.Component<any, any> {
  subscription: any;
  addWhiteIconButtonStyles = {
    root: { color: uPrinceTheme.palette.white },
    rootHovered: { color: theme.palette.neutralDark },
  };
  addIconWhite: IIconProps = {
    iconName: 'Add',
    styles: this.addWhiteIconButtonStyles,
  };
  styles = mergeStyleSets({
    buttonArea: {
      textAlign: 'center',
      margin: '0 0px',
      minWidth: 32,
      height: 32,
    },
    callout: { maxWidth: 300 },
    header: { padding: '18px 24px 12px' },
    title: [
      uPrinceTheme.fonts.xLarge,
      {
        margin: 0,
        fontWeight: FontWeights.semilight,
      },
    ],
    inner: {
      height: '100%',
      padding: '0 24px 20px',
    },
    actions: {
      position: 'relative',
      marginTop: 20,
      width: '100%',
      whiteSpace: 'nowrap',
    },
    subtext: [
      uPrinceTheme.fonts.small,
      {
        margin: 0,
        fontWeight: FontWeights.semilight,
      },
    ],
    link: [
      uPrinceTheme.fonts.medium,
      { color: uPrinceTheme.palette.neutralPrimary },
    ],
  });

  state = {
    redirect: false,
    redirectTo: '',
    isCalloutVisible: false,
    showNewButton: false,
  };

  handelRedirect = (item: any) => {
    history.push(item);
  };

  toggleIsCalloutVisible = () => {
    this.setState({ isCalloutVisible: !this.state.isCalloutVisible });
  };

  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data && data.data && data.data.shortCutPaneExpanded) {
          this.setState({ showNewButton: true });
        } else {
          this.setState({ showNewButton: false });
        }
      }
    });
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  menuProps = () => ({
    shouldFocusOnMount: true,
    items: [
      {
        key: 'newItem', text: 'New', onClick: () => {
        },
      },
      { key: 'divider_1', itemType: ContextualMenuItemType.Divider },
      {
        key: 'rename', text: 'Rename', onClick: () => {
        },
      },
      {
        key: 'edit', text: 'Edit', onClick: () => {
        },
      },
      {
        key: 'properties', text: 'Properties', onClick: () => {
        },
      },
    ],
  });

  render() {
    const { t } = this.props;
    const hasOrganisationPermission = hasPermissions(
      this.props.currentUser?.groups,
      this.props.organization,
    ).hasOrganisationPermission;
    const isApplicationManager = hasPermissions(
      this.props.currentUser?.groups,
      this.props.organization,
    ).isApplicationManager;
    return (
      <div className="navBar">
        <div className={this.styles.buttonArea}>
          <Icon
            iconName="ViewAll"
            className="navicon"
            onClick={() => {
              if (!ENV.DISABLE_MENU_CARD) {
                this.toggleIsCalloutVisible();
              }
            }}
          />
        </div>
        {this.state.showNewButton && ENV.ENABLE_NAV_BAR_BUTTON && (
          <div>
            <PrimaryButton
              iconProps={this.addIconWhite}
              text={t('new')}
              menuProps={this.menuProps()}
              style={{
                marginTop: 5,
                marginBottom: 5,
                marginRight: 35,
                height: 30,
              }}
              onClick={() => {
              }}
            />
          </div>
        )}
        {!ENV.ENABLE_NAV_BAR_BUTTON && (
          <div
            style={{
              marginTop: 5,
              marginBottom: 5,
              marginRight: 140,
              height: 30,
            }}
          ></div>
        )}
        <div>
          <Pivot
            defaultSelectedKey={
              hasOrganisationPermission ? this.props.selectedKey : 0
            }
            selectedKey={this.props.selectedKey}
            styles={pivotStyle}
            onLinkClick={(item:any) => {
              item ? this.handelRedirect(item.props.itemKey) : null;
            }}
          >
            {ENV.ORG_FEATURES.includes(ORG_FEATURS.CU) && (
              <PivotItem
                itemKey={'/contracting-unit'}
                headerText={t('contractingUnit')}
                itemIcon="EMI"
              ></PivotItem>
            )}

            {hasOrganisationPermission &&
            ENV.ORG_FEATURES.includes(ORG_FEATURS.CAB) && (
              <PivotItem
                itemKey={'/address-book'}
                headerText={t('centralAddressBook')}
                itemIcon="ConnectContacts"
              ></PivotItem>
            )}
            {hasOrganisationPermission &&
            ENV.ORG_FEATURES.includes(ORG_FEATURS.CPC) && (
              <PivotItem
                itemKey={'/product-catalogue'}
                headerText={t('corporateProductCatalogue')}
                itemIcon="ProductCatalog"
              ></PivotItem>
            )}
            {hasOrganisationPermission &&
            ENV.ORG_FEATURES.includes(ORG_FEATURS.CBC) && (
              <PivotItem
                itemKey={'/organisation-cbc'}
                headerText={t('cbc')}
                itemIcon="ProductCatalog"
              ></PivotItem>
            )}
            {/* { hasOrganisationPermission && isApplicationManager &&
                     <PivotItem
                         itemKey={ '/stock' }
                         headerText={ t('stock') }
                         itemIcon="PageList"
                     ></PivotItem>
                      } */}
            {/*
            <PivotItem
                itemKey={ '/risk-register' }
                headerText={ t('risk') }
                itemIcon="PageList"
            ></PivotItem>

            <PivotItem
                itemKey={ '/quality' }
                headerText={ t('Quality') }
                itemIcon="PageList"
            ></PivotItem> */}

            {hasOrganisationPermission && isApplicationManager &&
            ENV.ORG_FEATURES.includes(ORG_FEATURS.ORG_SETTING) && (
              <PivotItem
                itemKey={'/organisation-settings'}
                headerText={t('organisationSetting')}
                itemIcon="Settings"
              ></PivotItem>
            )}
            {hasOrganisationPermission &&
            isApplicationManager &&
            ENV.ORG_FEATURES.includes(ORG_FEATURS.TRANSLATION) && (
              <PivotItem
                itemKey={'/translations-dashboard'}
                headerText={t('translation')}
                itemIcon="Translate"
              ></PivotItem>
            )}
            {hasOrganisationPermission &&
            isApplicationManager &&
            ENV.ORG_FEATURES.includes(ORG_FEATURS.DROPDOWN_TRANSLATION) && (
              <PivotItem
                itemKey={'/dropdown-translations-dashboard'}
                headerText={t('dropdownTranslation')}
                itemIcon="PageList"
              ></PivotItem>
            )}
          </Pivot>
        </div>
        <MenuCard
          isMenuCardVisible={this.state.isCalloutVisible}
          className={this.styles.callout}
          target={this.styles.buttonArea}
          onDissmissed={() => this.toggleIsCalloutVisible()}
          accessLevel={AccessLevel.ORGANIZATION}
          currentUser={this.props.currentUser}
          organization={this.props.organization}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return { organization: state.uprince.organization };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(NavBar));

import React from 'react';
import { connect } from 'react-redux';
import DocumentPaneComponent from './component';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import history from '../../../history';
import { messageService } from '../../../services/messageService';
import { getProject } from '../../../shared/util';

import {
  getAllProjectDropdowns,
  getProjectDefinition,
  getProjectHeaderDetails,
} from '../../../reducers/projectReducer';
import { formData } from '../../../types/contract';
import { readByContractId, saveContractStateAttr, saveFormData } from '../../../reducers/contractReducer';
import { v4 as uuidv4 } from 'uuid';
import i18n from '../../../../i18n';
import { AddressBookItem, Person, PersonCompany } from '../../../types/addressBookItem';
import CustomAlert from '../../../shared/alert/customAlert';

export interface State {
  isDirty: boolean;
  redirect: boolean;
  loading: boolean;
  isClear: boolean;
  isEdit: boolean;
  isCostModalOpen: boolean;
  showPrint: boolean;
  isApproved: boolean;
  ledgerDropDowns: { key: string; text: string }[];
  generalLedger: string;
  resourceData: any;
  openPOResourceModal: boolean;
  uid: string | null;
  isOpenContractorTeamModal: boolean;
  addressBookItem: AddressBookItem;
}

interface Props {
  contractDropDowns?: any;
  formData?: any;
}

class DocumentPane extends React.Component<any, any> {
  subscription: any;
  submitExternal: any;
  form: any;

  constructor(props: any) {
    super(props);
    this.state = {
      isDirty: false,
      isEdit: false,
      redirect: false,
      loading: false,
      formData: formData,
      showPrint: false,
      isApproved: false,
      uid: null,
      isClear: false,
      addressBookItem: new AddressBookItem(
        new Person(),
        null,
        new PersonCompany(),
      ),
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    //   if (
    //       prevProps.isNew !== this.props.isNew ||
    //   prevProps.uid !== this.props.uid
    //   ) {
    //       if (this.props.isNew) {
    //           const formData = this.state.formData;
    //           formData.id = this.props.uid;
    //           this.setState({ formData, isClear: false });
    //       }
    //   } else {
    //   }

    if (prevProps.contractId !== this.props.contractId) {
      this.props.saveContractStateAttr('lotExcelUploaded', false);
      this.props.saveContractStateAttr('isDataLoaded', false);
    }

    //     if (prevProps.formData !== this.props.formData) {
    //         if (!this.state.isDirty) {
    //             if (!_.isEmpty(this.props.formData)) {
    //                 const formData = this.props.formData;
    //                 this.setState({ formData });
    //             }
    //         } else {
    //             if (this.props.isNew && _.isEmpty(this.props.formData)) {
    //                 const formData = this.state.formData;
    //                 this.setState({ formData: formData });
    //             }
    //         }
    //     }
  }

  componentDidMount() {
    // get document pane dropdowns
    if (this.props.projectState?.length < 0) {
      this.props.getAllProjectDropdowns();
    }
    this.setState({ uid: uuidv4(), isClear: this.props.isNewCONTRACT });

    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {

        if (data.data.isNewCONTRACT) {
          if (getProject()) {
            this.setState({ uid: uuidv4(), isClear: true });
          } else {
            this.setState({ uid: uuidv4(), isClear: true });
          }

          if (this.form) {
            this.form.reset();
            //   this.form.resetFieldState('header.name');
            //   this.form.resetFieldState('header.cpcResourceTitle');
          }
        }
        if (data.data.saveCONTRACTData) {
          // this.saveFormData();
          this.submitExternal();
        }
        if (data.data.contractFilter) {
          this.setState({ filter: data.data.contract });
        }
        if (data.data.pdfGenerate) {
          this.setState({ showPrint: true });
        }
      }
    });
    if (getProject()) {
      const projectId = getProject();
      if (projectId) {
        this.props.getProjectHeaderDetails(projectId);
      }
    } else if (this.props.formData?.id) {
      this.props.getProjectHeaderDetails(this.props.formData?.id);
    }
    if (this.props.formData?.id) {

    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
    this.props.saveContractStateAttr('lotExcelUploaded', false);
    this.props.saveContractStateAttr('isDataLoaded', false);
  }

  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { psFilter: this.state.filter },
    });
  };

  setSubmitExternal = (submit: any) => {
    this.submitExternal = submit;
  };

  setForm = (form: any) => {
    this.form = form;
  };

  private handleContractorsTaxonomyChange = (value: string) => {
    this.setState(
      (prevState: { addressBookItem: AddressBookItem; }) => ({
        ////isEditCAB:true,
        isDirty: this.state.addressBookItem?.isSaved,
        addressBookItem: {
          ...prevState.addressBookItem,
          contractorTaxonomyId: value,
        },
      }),
      () => {
      },
    );
  };

  render() {
    return (
      <div style={{ width: '100%' }}>
        <RouteLeavingGuard
          when={this.state.isDirty}
          navigate={(path) => this.handleDiscardChangesNavigate(path)}
          shouldBlockNavigation={() => {
            return true;
          }}
          handleConfirmation={(isConfirm: boolean) => {
            this.setState({ isDirty: !isConfirm });
          }}
        />
        <div id={'topDiv'} style={{ position: 'absolute', width: '75%', zIndex: 1000 }}>
          <CustomAlert
            showMsg={this.props.lotExcelUploaded}
            msg={i18n.t('excelUploadSuccess')}
            msgType={'success'}
            handleCustomAlert={() => {
              this.props.saveContractStateAttr('lotExcelUploaded', false);
              this.props.saveContractStateAttr('isDataLoaded', false);
            }}
          />
          <CustomAlert
            showMsg={(!this.props.lotExcelUploaded) && this.props.isDataLoaded ? true : false}
            msg={i18n.t('excelUploadFailed')}
            msgType={'failed'}
            handleCustomAlert={() => {
              this.props.saveContractStateAttr('isDataLoaded', false);
            }}
          />
        </div>
        <DocumentPaneComponent
          submit={(submit: any) => {
            this.setSubmitExternal(submit);
          }}
          dropDowns={this.props.dropDowns}
          saveFormData={this.props.saveFormData}
          uid={this.state.uid}
          // formData={this.props.formData}
          showProgress={this.props.isLoadingContract}
          formValues={this.form?.getState()?.values}
          isClear={this.state.isClear}
          isExpand={this.props.isExpand}
          setForm={(form: any) => {
            this.setForm(form);
          }}
          projectHeader={this.props.projectHeader}
          openContractorTeamModal={() => this.openContractorTeamModal()}
          openDocumentationModal={function(): void {
            throw new Error('Function not implemented.');
          }}
          addressBookItem={this.state.addressBookItem}
          handleContractorsTaxonomyChange={this.handleContractorsTaxonomyChange}/>
      </div>
    );
  }

  private openContractorTeamModal = (): void => {
    this.setState({
      isOpenContractorTeamModal: !this.state.isOpenContractorTeamModal,
      //   editAbsence: this._absenceEditDetails(),
    });
  };

  private onCreateContractorTeam = (contractorTeam: any) => {
    if (!contractorTeam.id) {
      contractorTeam.id = uuidv4();
    }
    this.props.createAbsence(contractorTeam).then(() => {
      this.props.readAbsenceByPersonId(contractorTeam.person);
    });
  };
}

const mapStateToProps = (state: any) => {
  return {
    isEdit: state.contract.isEdit,
    isNew: state.contract.isNew,
    uid: state.contract.uid,
    projectHeader: state.project.projectHeader,
    showProgressBar: state.contract.showProgressBar,
    selectedProject: state.project.selectedProject,
    dropDowns: state.contract.contractDropDowns,
    lotExcelUploaded: state.contract.lotExcelUploaded,
    isDataLoaded: state.contract.isDataLoaded,
    projectState:state.project.projectState
  };
};

const mapDispatchToProps = {
  saveFormData,
  getProjectHeaderDetails,
  getAllProjectDropdowns,
  getProjectDefinition,
  readByContractId,
  saveContractStateAttr,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentPane);

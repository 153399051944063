import React, { useEffect, useState } from 'react';
import {
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  mergeStyleSets,
  PrimaryButton,
  Stack,
  Text,
  TextField,
} from 'office-ui-fabric-react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../theme';
import AzureAddressAutoComplete from '../../azureAddress/container';
import { LocationAddress, Position } from '../../../types/projectDefinition';
import MapModal from '../../../components/cuProjects/mapModal/component';

const theme = getTheme();

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

// Styles definition
const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
    marginRight: 25,
  },
};

// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 0,
};

const stackItemStylesColThree: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
  },
};

const classNames = mergeStyleSets({
  fullWidth: { width: '100%' },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  bigScreenTree: {
    marginBottom: 30,
    height: '30vh',
  },
  smallScreenTree: {
    marginBottom: 30,
    height: '56vh',
  },
  textEditor: { height: window.innerWidth <= 1280 ? '50vh' : '25vh' },
});

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };

const mapWhiteIconButtonStyles = {
  root: { color: uPrinceTheme.palette.white },
  rootHovered: { color: theme.palette.neutralDark },
};

const mapIconWhite: IIconProps = {
  iconName: 'Nav2DMapView',
  styles: mapWhiteIconButtonStyles,
};
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const AzureLocationComponent = (props: {
  setLocationAddress: (address: LocationAddress) => void,
  //position:Position,
  address: string,
  azureLocation: LocationAddress | null,
  disabled?: boolean,
  lable: string,
  addressLable: string,
  required?: boolean,
  errorMessage?: string
}) => {
  const { t } = useTranslation();
  const [isMapOpen, setIsMapOpen]: any = useState(false);
  const [azureLocation, setAzureLocation]: any = useState([]);

  useEffect(() => {
    setAzureLocation(props.azureLocation);
  }, [props.azureLocation]);

  const getPosition = (position: any) => {
    if (typeof position?.lat === 'string') {
      return { lat: parseFloat(position?.lat), lon: parseFloat(position?.lon) };
    }
    return position;
  };


  return (
    <div>
      <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
        <Stack.Item grow={6} styles={stackItemStyles}>
          <div className={`${classNames.fullWidth}`}>

            <AzureAddressAutoComplete
              onAddressSelected={(address) => {
                props.setLocationAddress(address);
                setAzureLocation(address);
              } }
              address={props.address}
              disabled={props.disabled}
              lable={props.addressLable}
              required={props?.required} 
              onChange={function (value: any): void {}}
              />
          </div>

        </Stack.Item>

      </Stack>
      {props.errorMessage &&
      <Text styles={{ root: { color: '#a4262c', fontSize: 12 } }}>
        {props.errorMessage}
      </Text>
      }
      <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
        <Stack.Item grow={6} styles={stackItemStyles}>
          <div className={`${classNames.fullWidth}`}>
            <TextField label={props.lable} disabled={props.disabled} placeholder={t('latitude')} readOnly={true}
                       value={azureLocation?.position?.lat === 0.00 ? undefined : getPosition(azureLocation?.position)?.lat.toFixed(6)}/>
          </div>
        </Stack.Item>

        <Stack.Item grow={6} styles={stackItemStyles}>
          <div className={`${classNames.fullWidth}`}>

            <TextField placeholder={t('longitude')} disabled={props.disabled} readOnly={true}
                       value={azureLocation?.position?.lon === 0.00 ? undefined : getPosition(azureLocation?.position)?.lon.toFixed(6)}
                       styles={{ root: { marginTop: 29 } }}/>
          </div>
        </Stack.Item>

        <Stack.Item grow={6} styles={stackItemStyles}>
          <div className={`${classNames.fullWidth}`}>
            <PrimaryButton
              iconProps={mapIconWhite}
              text={t('map')}
              style={{ marginTop: 29, marginBottom: 10, marginLeft: 10 }}
              onClick={() => {
                setIsMapOpen(!isMapOpen);
              }}
              disabled={props.disabled}
            />
          </div>
        </Stack.Item>

      </Stack>

      <MapModal
        isOpen={isMapOpen}
        openMapModal={() => {
          setIsMapOpen(!isMapOpen);
        }}
        setLocationAddress={(pos: Position, address?: LocationAddress) => {
          props.setLocationAddress({
            ...azureLocation,
            position: pos,
          });
          setAzureLocation({
            ...azureLocation,
            position: pos,
          });
        }}
        position={azureLocation?.position}
      />

    </div>
  );
};

AzureLocationComponent.getAddress = (location: any) => {
  return location &&
  location?.address &&
  location.address.freeformAddress
    ? location.address.freeformAddress
    : '';
};

export default AzureLocationComponent;

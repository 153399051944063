
import { Dropdown, IDropdownOption, Label } from '@fluentui/react';
import React, { useContext, useEffect } from 'react';

import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { filterVisualPlanListPaneData, saveVPStateAttr } from '../../../reducers/visualPlanReducer';
import CustomDatePicker from '../../../shared/customDatePicker/customDatePicker';
import {formatDateTimeToISOString, getSelectedFilterDates} from '../../../shared/util';
import CustomDropdown from '../../../shared/customDropdown/customDropdown';
import _ from 'lodash';
import ModeSwitcher from '../../../shared/ganttViewSwitcher/viewSwitcher';
import { ViewMode } from '../../../shared/ganttv2';
import { ViewModeContext } from '../../projectVisualPlan/productTaxonomyListPane/component';

export const ViewSwitcher: React.FC = ({}) => {
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const [selectedResourceKeys, setSelectedResourceTypeKeys] = React.useState<string[]>([]);
  const [startDate, setStartDate] = React.useState<Date | null>(getSelectedFilterDates(['4'])[0]);
  const [endDate, setEndDate] = React.useState<Date | null>(getSelectedFilterDates(['4'])[1]);
  const [project, setSelectedProject] = React.useState<any>(null);
  const [selectedMode, setSelectedViewMode] = React.useState<ViewMode>(ViewMode.Month);
  const [itemType, setItemType] = React.useState<string[]>([]);
  const [standeredFilter, setStanderedFilter] = React.useState<any>('4');
  const { t } = useTranslation();
  
  const { onViewModeChange, onViewListChange, isCheck } =
    useContext(ViewModeContext);
  
  const poDropDowns: any = useSelector(
    (state: any) => state.po.purchaseOrderDropDowns,
  );
  const cpcDropDowns: any = useSelector(
    (state: any) => state.productCatalogue.resourceTypes,
  );
  const vpFilterDropdown: any = useSelector(
    (state: any) => state.vp.vpFilterDropdown,
  );
  const dispatch = useDispatch();
  const { selectedPOVPshortcutpaneItems, selectedEndDateCu, selectedStartDateCu, selectedPOResourceTypes, selectedPOProject }: any = useSelector(
    (state: any) => state.vp,
  );
  const onSubmit = async (values: any) => {
  };

  useEffect(() => {
  }, [selectedKeys]);

  useEffect(() => {
    if (_.isArray(selectedPOVPshortcutpaneItems)) {
      setItemType(selectedPOVPshortcutpaneItems);
      setSelectedKeys(selectedPOVPshortcutpaneItems);
      setStartDate(selectedStartDateCu ? new Date(selectedStartDateCu) : startDate);
      setEndDate(selectedEndDateCu ? new Date(selectedEndDateCu) : endDate);
      setSelectedResourceTypeKeys(_.isArray(selectedPOResourceTypes) ? selectedPOResourceTypes : []);
      setSelectedProject(selectedPOProject);
    }
  }, [selectedPOVPshortcutpaneItems, selectedEndDateCu, selectedStartDateCu, selectedPOResourceTypes, selectedPOProject]);


  //project search

  const projectList: any = useSelector(
    (state: any) => state.po.poProjectList,
  );
  const getProjectByName = async (name: string) => {
    // if (name.length >= 2) {
    const response = projectList.filter(
      // (element: { text: string; value: string }) => element.text.includes(name)
      (element: { text: string; value: string }) => element.text.toLowerCase().includes(name.toLowerCase()),
    );
    return formatProjectResponse(response).sort();
  };

  const projectOptions = (inputValue: any) =>
    new Promise((resolve) => {
      // setTimeout(() => {
      resolve(getProjectByName(inputValue));
      // }, 1000);
    });

  const formatProjectResponse = (response: any) => {
    if (response) {
      if (_.isArray(response)) {
        const titleNotEmptyList = response.filter(
          (item: { name: string | null }) => item.name !== null,
        );
        const data = titleNotEmptyList.map((item: any) => {
          return {
            value: item.key,
            label: item.text,
          };
        });
        return data;
      }
      return [];
    }
    return [];
  };

  const getProjectTitle = (sequnceCode: string) => {
    let project: any = projectList.find(
      (element: { text: string; key: string }) => element.key === sequnceCode,
    );
    if (project) {
      return project?.title;
      return;
    } else {
      return null;
    }
  };


  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        height: 70,
        width: '100%',
        position: 'sticky',
        top: 0,
        zIndex: 100,
        borderBottom: '0.5px solid gray',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          flexGrow: 0.2,
          width: '100%',
        }}
      >
        <Form
          onSubmit={onSubmit}
          initialValues={{
            poType: selectedPOVPshortcutpaneItems,
            // fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            // toDate: new Date(new Date().getFullYear(), new Date().getMonth() +1, 0)
            fromDate: startDate,//? startDate :new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            toDate: endDate,//? endDate :new Date(new Date().getFullYear(), new Date().getMonth() +1, 0),
            project: selectedPOProject,
            date: null,
          }}
          mutators={{
            setHeaderDocs: (args, state, utils) => {
              const field = state.fields['header.files'];
              field.change(args[0]);
            },
            setDateRange: (args, state, utils) => {
              const from = state.fields['fromDate'];
              const to = state.fields['toDate'];
                from.change(getSelectedFilterDates(args)[0]);
                to.change(getSelectedFilterDates(args)[1]);

                dispatch(
                  filterVisualPlanListPaneData({
                      resourceType: selectedResourceKeys,
                      requestType: [],
                      fromDate: formatDateTimeToISOString(
                              getSelectedFilterDates(args)[0],
                            ),
                      toDate: formatDateTimeToISOString(
                              getSelectedFilterDates(args)[1],
                            ),
                      projectSequenceCode: null,
                      date: null,
                  }),
              );

                // dispatch(
                //   readProjectVPData({
                //     type: [itemType],
                //     startDate: formatDateTimeToISOString(
                //       getSelectedFilterDates(args)[0],
                //     ),
                //     endDate: formatDateTimeToISOString(
                //       getSelectedFilterDates(args)[1],
                //     ),
                //   }));

            },
            setStandardFilter: (args, state, utils) => {
              const date = state.fields['date'];
              date.change(null);
            },
          }}
          // validate={ validate }
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            // props.submit(handleSubmit);

            return (
              <form onSubmit={handleSubmit} noValidate>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    height: 70,
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                      height: 70,
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 0.9,
                        height: 50,
                        width: '100%',
                      }}
                    >
                      <div style={{ width: 180, marginLeft: 15 }}>
                        <Field name="poType">
                          {({ input, meta }) => (
                            <Dropdown
                              placeholder={t('poType')}
                              label={t('poType')}
                              selectedKeys={selectedKeys}
                              // eslint-disable-next-line react/jsx-no-bind
                              onChange={(
                                event: React.FormEvent<HTMLDivElement>,
                                item: IDropdownOption | undefined,
                              ) => {
                                if (item) {
                                  setSelectedKeys(
                                    item.selected
                                      ? [...selectedKeys, item.key as string]
                                      : selectedKeys.filter(
                                      (key) => key !== item.key,
                                      ),
                                  );
                                  let x = values.poType ? values.poType : [];
                                  let y = [];
                                  if (selectedKeys && item.selected) {
                                    y = [...selectedKeys, item.key];
                                  } else {
                                    y = selectedKeys.filter((key: any) => key !== item.key);
                                  }
                                  dispatch(saveVPStateAttr('selectedPOVPshortcutpaneItems', y));
                                  dispatch(
                                    filterVisualPlanListPaneData({
                                      resourceType: selectedResourceKeys,
                                      requestType: y,
                                      fromDate: formatDateTimeToISOString(startDate!!),
                                      toDate: formatDateTimeToISOString(endDate!!),
                                      projectSequenceCode: values.project,
                                      date: values.date,
                                    }),
                                  );
                                  input.onChange(
                                    y,
                                  );
                                  //   dispatch(
                                  //     saveVPStateAttr('poSelectedTypes', selectedKeys)
                                  //   );
                                }
                              }}
                              multiSelect
                              options={
                                poDropDowns?.requestTypes
                                  ? poDropDowns.requestTypes
                                  : []
                              }
                            />
                          )}
                        </Field>
                      </div>

                      <div style={{ width: 180, marginLeft: 15 }}>
                        <Field name="resourceType">
                          {({ input, meta }) => (
                            <Dropdown
                              placeholder={t('resourceType')}
                              label={t('resourceType')}
                              selectedKeys={selectedResourceKeys}
                              // eslint-disable-next-line react/jsx-no-bind
                              onChange={(
                                event: React.FormEvent<HTMLDivElement>,
                                item: IDropdownOption | undefined,
                              ) => {
                                if (item) {
                                  setSelectedResourceTypeKeys(
                                    item.selected
                                      ? [
                                        ...selectedResourceKeys,
                                        item.key as string,
                                      ]
                                      : selectedResourceKeys.filter(
                                      (key: any) => key !== item.key,
                                      ),
                                  );
                                  let x = values.resourceType
                                    ? values.resourceType
                                    : [];
                                  let y = [];
                                  if (selectedResourceKeys && item.selected) {
                                    y = [...selectedResourceKeys, item.key];
                                  } else {
                                    y = selectedResourceKeys.filter((key: any) => key !== item.key);
                                  }
                                  dispatch(saveVPStateAttr('selectedPOResourceTypes', y));
                                  dispatch(
                                    filterVisualPlanListPaneData({
                                      requestType: selectedKeys
                                        ? selectedKeys
                                        : [],
                                      resourceType: y,
                                      fromDate: formatDateTimeToISOString(startDate!!),
                                      toDate: formatDateTimeToISOString(endDate!!),
                                      projectSequenceCode: values.project,
                                      date: values.date,
                                    }),
                                  );
                                  input.onChange(
                                    item.selected
                                      ? y
                                      : x.filter((key: any) => key !== item.key),
                                  );

                                }
                              }}
                              multiSelect
                              options={cpcDropDowns ? cpcDropDowns : []}
                            />
                          )}
                        </Field>
                      </div>
                      <div style={{ width: 180, marginLeft: 15 }}>
                        <Label style={{ width: 200 }}>{t('project')}:</Label>
                        <Field name="project">
                          {({ input, meta }) => (
                            <CustomDropdown
                              reference={''}
                              onChange={(
                                key: string,
                                label: string,
                              ) => {
                                let project = null;
                                if (key) {
                                  input.onChange(key);
                                  project = key;
                                } else {
                                  input.onChange(null);
                                }
                                dispatch(saveVPStateAttr('selectedPOProject', project));
                                dispatch(
                                  filterVisualPlanListPaneData({
                                    requestType: values.poType
                                      ? values.poType
                                      : [],
                                    resourceType: values.resourceType
                                      ? values.resourceType
                                      : [],
                                    fromDate: formatDateTimeToISOString(startDate!!),
                                    toDate: formatDateTimeToISOString(endDate!!),
                                    projectSequenceCode: project,
                                    date: values.date,
                                  }),
                                );
                              }}
                              selectedOption={
                                values &&
                                values?.project ? {
                                    value:
                                    values?.project,
                                    label: getProjectTitle(
                                      values?.project,
                                    ),
                                  }
                                  : null
                              }
                              promiseOptions={projectOptions}
                              validationMessage={''}
                              required={true}
                            />
                          )}
                        </Field>
                      </div>

                      <div style={{ width: 180, marginLeft: 15 }}>
                            <Field name="date">
                                {({ input, meta }) => (
                                    <Dropdown
                                        placeholder={t('standardFilter')}
                                        label={t('standardFilter')}
                                        selectedKey={standeredFilter}
                                        // eslint-disable-next-line react/jsx-no-bind
                                        onChange={(event, value) => {
                                            let date = null;
                                            if (value) {
                                                date = value.key;
                                                input.onChange(value.key);
                                                setStanderedFilter(value.key)
                                            } else {
                                                date = null;
                                                input.onChange(null);
                                            }
                                            form.mutators.setDateRange(value?.key);
                                            if (value?.key === '5'){
                                                onViewModeChange(ViewMode.QuaterYear)
                                                // setIsProjectSeleted(true)
                                                setSelectedViewMode(ViewMode.QuaterYear)
                                            } else {
                                                onViewModeChange(ViewMode.Week)
                                                // setIsProjectSeleted(false)
                                                setSelectedViewMode(ViewMode.Week)
                                            }
                                            // dispatch(
                                            //     filterVisualPlanListPaneData({
                                            //         resourceType: selectedResourceKeys,
                                            //         requestType: values.requestType,
                                            //         fromDate: null,
                                            //         toDate: null,
                                            //         projectSequenceCode: values.project,
                                            //         date: date,
                                            //     }),
                                            // );
                                        }}
                                        options={
                                            vpFilterDropdown
                                                ? vpFilterDropdown
                                                : []
                                        }
                                    />
                                )}
                            </Field>
                        </div>

                      <div className={'vpDatePicker180'} style={{ width: 180, marginLeft: 15 }}>
                        <Label style={{ width: 180 }}>{t('fromDate')}:</Label>
                        <Field name="fromDate">
                          {({ input, meta }) => (
                            <CustomDatePicker
                                setValue={values.fromDate ? values.fromDate : startDate}
                              getValue={(date: Date) => {
                                form.mutators.setStandardFilter();
                                input.onChange(date);
                                setStartDate(date);
                                input.onChange(date);
                                let clDate = new Date(date);
                                let newDate = new Date(clDate.setDate(clDate.getDate() + 30));
                                setEndDate(newDate);
                                dispatch(saveVPStateAttr('selectedStartDateCu', date));
                                dispatch(saveVPStateAttr('selectedEndDateCu', newDate));
                                dispatch(
                                  filterVisualPlanListPaneData({
                                    requestType: values.poType
                                      ? values.poType
                                      : [],
                                    resourceType: selectedResourceKeys
                                      ? selectedResourceKeys
                                      : [],
                                    fromDate: formatDateTimeToISOString(date!!),
                                    toDate: formatDateTimeToISOString(newDate!!),
                                    projectSequenceCode: values.project,
                                    date: null,
                                  }),
                                );
                              }}
                            />
                          )}
                        </Field>
                      </div>

                      <div className={'vpDatePicker180'} style={{ width: 180, marginLeft: 15 }}>
                        <Label style={{ width: 180 }}>{t('toDate')}:</Label>
                        <Field name="toDate">
                          {({ input, meta }) => (
                            <CustomDatePicker
                              setMinDate={new Date(form.getFieldState('fromDate')?.value)}
                              //setMaxDate={ maxDate }

                              // setValue={ (props.formData.executionDate) ? new Date(props.formData.executionDate) : null }
                              setValue={values.toDate}
                              getValue={(date: Date) => {
                                form.mutators.setStandardFilter();
                                input.onChange(date);
                                setEndDate(date);
                                dispatch(
                                  filterVisualPlanListPaneData({
                                    requestType: values.poType
                                      ? values.poType
                                      : [],
                                    resourceType: selectedResourceKeys
                                      ? selectedResourceKeys
                                      : [],
                                    toDate: formatDateTimeToISOString(date),
                                    fromDate: formatDateTimeToISOString(startDate!!),
                                    projectSequenceCode: values.project,
                                    date: null,
                                  }),
                                );
                              }}
                            />
                          )}
                        </Field>
                      </div>

                      <ModeSwitcher
                         onViewModeChange={(t:any)=>{
                          onViewModeChange(t)
                          setSelectedViewMode(t)
                        }}
                        onViewListChange={onViewListChange}
                        isCheck={isCheck}
                        selectedViewMode={selectedMode}
                      />
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>
        <div className="ViewContainer">

        </div>

      </div>
    </div>
  );
};

import React, {Component} from 'react';
import ProjectVisualPlanMainLayout from './myProjectVisualPlanMainLayout';
import MeNavBar from '../../shared/uprinceNavigation/meNavigation/component';


interface Props {
  currentUser: any
}

interface State {
  isContractUnit: boolean;
  isProject: boolean;
}

class MyProjectVisualPlan extends Component<Props, State> {
  render() {
    return (
      <MeNavBar
        currentUser={this.props.currentUser}
        selectedNavigationIndex={`/me/project-planning`}
      >
        <ProjectVisualPlanMainLayout/>

      </MeNavBar>
    );
  }
}

export default MyProjectVisualPlan;

import React, { useEffect } from 'react';
import {
  ActionButton,
  assign, Checkbox,
  FontWeights, IBasePickerSuggestionsProps,
  IButtonStyles, IconButton, IIconProps, IStackItemStyles,
  IStackStyles,
  IStackTokens, ITag, Label, Link,
  mergeStyleSets,
  Modal, NormalPeoplePicker, Persona,
  PrimaryButton, Stack, TagPicker, Text, TextField,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useId } from '@uifabric/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { uPrinceTheme } from '../../../../../theme';
import { getTheme, ITheme, mergeStyles } from '@fluentui/react/lib/Styling';
import { saveProjectDayPlanningStateAttr } from '../../../../reducers/projectDayPlanningReducer';
import client from '../../../../api';
import _ from 'lodash';
import i18n from '../../../../../i18n';
import { messageService } from '../../../../services/messageService';
import { AddressBookItem } from '../../../../types/addressBookItem';
import { DropDownAdaptater, TextFieldFF } from '../../../../shared/fluentFinalForm';
import PeoplePicker from '../../../../shared/fluentFinalForm/PeoplePicker';
import AzureLocationComponent from '../../../../shared/location/Azure/component';
import { getProjectDefinition } from '../../../../reducers/projectReducer';
import { readCIAWSiteCodeById } from '../../../../reducers/ciawReducer';
import { LOAD_LABOUR_LIST_MY_ENV_EP } from '../../../../shared/endpoints';
import CustomDropdown from '../../../../shared/customDropdown/customDropdown';
import { formatDateTimeToISOString, isMyEnvironment } from '../../../../shared/util';
import { createNewProject } from '../../../../reducers/myDayPlanningReducer';
import ProgressBar from '../../../../shared/progressBar/progressBar';
const theme: ITheme = getTheme();

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: { color: theme.palette.neutralDark },
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  body: {
    border: '2px solid',
    borderColor: uPrinceTheme.palette.themePrimary,
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    minWidth: '35vw',
    minHeight: '5vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  fullWidth: { width: '100%' },
  footer: {
    flex: '1 1 auto',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '0px 24px 0px 12px',
    textAlign: 'end',
  },
});

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
});

const addIcon: IIconProps = { iconName: 'Add' };
const newItem = mergeStyles({ padding: '1px' });
const existingItem = mergeStyles({ padding: '10px' });
const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 5 } };

const stackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const NewProjectModal = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const titleId = useId('title');

  const { isNewProjectModalOpen } = useSelector((state: any) => state.projectDPlan);
  const [userSelectedProject, setUserSelectedProject] = React.useState<ITag[] | null>(null);
  const {
    projectScopeStatus,
    projectState,
    projectLanguage,
    selectedProject,
  }: any = useSelector((projectState: any) => projectState.project);

  const { ciawSiteCodeData } = useSelector((state: any) => state.ciaw);
  const { selectedBu } = useSelector((state: any) => state.projectDPlan);
  const { selectedCu } = useSelector((state: any) => state.projectDPlan);
  const { pmolType } = useSelector((state: any) => state.pmol);
  const { selectedDate } = useSelector((state: any) => state.mydPlan);

  const projectTime = {
      expectedEndDate: null,
      startDate: null,
      endDate: null,
      calendarTemplateId: null,
      projectId: 0
    }

  const onSubmit = async (values: any) => {
    if (values) {
      await Promise.all([
        dispatch(createNewProject({ ...values }))
      ]).then(() => {
        handleClose();
      });
    }
  };

  const handleClose = () => {
    dispatch(saveProjectDayPlanningStateAttr('isNewProjectModalOpen', !isNewProjectModalOpen));
    setUserSelectedProject(null);
  };

  const getInitialFormValues = () => {
    if (userSelectedProject) {
      return userSelectedProject[0].key ?
        {
          ...selectedProject,
          ciawSiteCode: ciawSiteCodeData?.ciawSiteCode,
          isCiawEnabled: ciawSiteCodeData?.isCiawEnabled,
          date: formatDateTimeToISOString(selectedDate),
          cu: selectedCu?.sequenceCode,

        } : {
          id: null,
          name: userSelectedProject[0]?.name,
          projectClassification: { projectClassificationBuisnessUnit: selectedBu },
          date: formatDateTimeToISOString(selectedDate),
          cu: selectedCu?.sequenceCode,
          projectTime: projectTime,
        };
    }
  };

  const tagSearchAdditionalProps: IBasePickerSuggestionsProps = {
    suggestionsContainerAriaLabel: i18n.t('suggestedProjects'),
    searchingText: i18n.t('Searching') + '...',
    resultsFooter: (props: any) => (
      <ActionButton
        className={classNames.actionButton}
        disabled={false}
        iconProps={addIcon}
        allowDisabledFocus
        onClick={() => {
        }}
      >
        <Label className={classNames.actionButton}>{i18n.t('New') as any}</Label>
      </ActionButton>
    ),
  };

  const tagSuggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: i18n.t('suggestedProjects'),
    mostRecentlyUsedHeaderText: i18n.t('suggestedProjects'),
    noResultsFoundText: i18n.t('noresultsfound'),
    loadingText: i18n.t('loading'),
    showRemoveButtons: true,
    suggestionsAvailableAlertText: i18n.t('projectSuggestionsavailable'),
    suggestionsContainerAriaLabel: i18n.t('suggestedProjects'),
  };

  const tagSearchSuggestionProps: IBasePickerSuggestionsProps = assign(
    tagSearchAdditionalProps,
    tagSuggestionProps,
  );

  const onFilterProjectChanged = (
    filterText: string,
  ): ITag[] | Promise<ITag[]> => {
    if (filterText) {
      return getProjectByName(filterText);
    } else {
      return [];
    }
  };

  const onRenderSuggestionsItemTagPicker = (props: any): JSX.Element => {
    if (props.isNewItem) {
      return <div className={props.isNewItem ? newItem : existingItem} key={props.key}>
        <ActionButton iconProps={addIcon}>
          {props.name}
        </ActionButton>
      </div>;
    }
    return <div className={props.isNewItem ? newItem : existingItem} key={props.key}>
      {props.name}
    </div>;
  };

  const getProjectByName = async (name: string) => {
    const filter = { name: name, buId: selectedBu };
    let response = await client.post(
      `VP/ProjectSearchMyEnv`,
      filter,
    );
    return formatCompanyResponse(response, name);
  };

  const onProjectSelected = React.useCallback((item: any | undefined): any => {
    if (item) {
      if (item.isNewItem) {
        setUserSelectedProject([{ key: '', name: item.name }]);
      } else {
        dispatch(getProjectDefinition(item.sequenceCode));
        dispatch(readCIAWSiteCodeById(item.key));
        setUserSelectedProject([{ key: item.key, name: item.name }]);
      }
    }
    return null;
  }, []);

  useEffect(() => {
    if (!isMyEnvironment() && selectedProject?.sequenceCode) {
      dispatch(getProjectDefinition(selectedProject?.sequenceCode));
      dispatch(readCIAWSiteCodeById(selectedProject?.id));
      setUserSelectedProject([{ key: selectedProject?.id, name: selectedProject?.title }]);
    }
  }, []);

  const formatCompanyResponse = (response: any, name: any) => {
    let data: { key: string; name: string; isNewItem: boolean }[] = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: any) => {
        return {
          ...item,
          id: item.key,
          name: item.text,
          isNewItem: false,
        };
      });
      let obj = data.find(
        (o: any) => o.name.toLowerCase() === name.toLowerCase(),
      );
      if (!obj) {
        data.unshift({ key: '', name: name, isNewItem: true });
      }
    } else {
      return [{ key: '', name: name, isNewItem: true }];
    }
    return data;
  };

  const onRemoveCompanySuggestion = (items: any[]): void => {
    if (items && _.isArray(items) && items.length === 0) {
      setUserSelectedProject(null);
    }
  };

  const onFilterPersonChanged = (filter: string) => {
    if (filter) {
      return getPersonByName(filter);
    } else {
      return [];
    }
  };

  const getPersonByName = async (name: string) => {
    let response = await client.get(
      `CentralAddressBook/ReadCabPersonList/${name}`,
    );
    return formatPersonResponse(response, name);
  };

  const formatPersonResponse = (response: any, name: any) => {
    let data: { key: string; text: string; isNewItem: boolean }[] = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: AddressBookItem) => {
        return {
          ...item,
          text: item.person ? item.person.fullName : '',
          secondaryText: item.personCompany ? item.personCompany.jobRole : '',
          key: item.id,
          isNewItem: false,
        };
      });
      let obj = data.find(
        (o: any) => o.person.fullName.toLowerCase() === name.toLowerCase(),
      );
      if (!obj) {
        data.unshift({ key: '', text: name, isNewItem: true });
      }
    } else {
      return [{ key: '', text: name, isNewItem: true }];
    }
    return data;
  };

  const limitedSearchAdditionalProps: IBasePickerSuggestionsProps = {
    resultsFooter: (props: any) => (
      <ActionButton
        className={classNames.actionButton}
        disabled={false}
        iconProps={addIcon}
        allowDisabledFocus
        onClick={(event) => {
          messageService.sendMessage({ isOpenNameModal: true });
        }}
      >
        <Label className={classNames.actionButton}>{i18n.t('new') as any}</Label>
      </ActionButton>
    ),
    showRemoveButtons: false,
    searchingText: 'Searching...',
  };

  const onRenderSuggestionsItem = (props: any): JSX.Element => {
    if (props.isNewItem) {
      return <div className={props.isNewItem ? newItem : existingItem} key={props.key}>
        <ActionButton iconProps={addIcon}>
          {props.text}
        </ActionButton>
      </div>;
    }
    return <div className={props.isNewItem ? newItem : existingItem} key={props.key}>
      <Persona
        {...props}
        size={1}
        hidePersonaDetails={false}
      />
    </div>;
  };

  const labourResourceOptions = (inputValue: any) =>
    new Promise((resolve) => {
      resolve(getLabourItemByName(inputValue));
    });

  const getLabourItemByName = async (name: string) => {
    let filter = {
      cu: selectedCu?.sequenceCode,
      title: name,
    };

    if (name) {
      const response = await client.post(LOAD_LABOUR_LIST_MY_ENV_EP, filter);
      return formatLabourResponse(response);
    }
  };

  const formatLabourResponse = (response: any) => {
    let data: { value: string; label: string }[] = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: any) => {
        return {
          value: item.id,
          label: item.title,
        };
      });
    }
    return data;
  };

  return <div>
    <Modal
      titleAriaId={titleId}
      isOpen={isNewProjectModalOpen}
      onDismiss={() => handleClose()}
      isBlocking={false}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <span id={titleId}>{t('scheduleAnewProject')}</span>
        <IconButton
          styles={cancelIconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel='Close popup modal'
          onClick={() => handleClose()}
        />
      </div>
      <div className={contentStyles.body}>
        <Form
          onSubmit={onSubmit}
          initialValues={getInitialFormValues()}
          validate={values => {
            const errors: any = {};
            if (!values?.name) {
              errors.pmolTitle = t('pmolNameRequired');
            }
            if (!values?.pmolType) {
              errors.pmolType = t('pmolTypeRequired');
            }
            if (!values?.labourResource) {
              errors.labourResource = t('labourResourceItemRequired');
            }
            if (!values?.name) {
              errors.name = t('projectNameRequired');
            }
            return errors;
          }}
          mutators={{
            setGeneralLedger: (args, state, utils) => {
              const field = state.fields.generalLedgerId;
              field.change(args[0]);
            },
            setPMName: (args, state, utils) => {
              const field = state.fields.projectManagerName;
              field.change(args[0]);
            },
            setSMName: (args, state, utils) => {
              const field = state.fields.siteManagerName;
              field.change(args[0]);
            },
            setCustomerName: (args, state, utils) => {
              const field = state.fields.customerName;
              field.change(args[0]);
            },
            setCiawEnabled: (args, state, utils) => {
              const field = state.fields.isCiawEnabled;
              field.change(args[0]);
            },
            setLabourResourceItem: (args, state, utils) => {
              const field = state.fields.labourResourceItem;
              field.change(args[0]);
            },

          }}
          render={({
                     handleSubmit,
                     form,
                     submitting,
                     pristine,
                     errors,
                     values,
          }) => {
            return (
              <form onSubmit={handleSubmit} noValidate >
                <div style={{marginRight: -30, marginLeft: -30}}>
                  <ProgressBar show={submitting} />
                </div>
                <Field
                  name='id'
                  component={'input'}
                  type={'text'}
                  disabled={true}
                  hidden
                />
                <Stack
                  horizontal
                  wrap
                  styles={stackStyles}
                  tokens={stackTokens}
                >
                  <div className={`${classNames.fullWidth}`}>
                    <div className={'card-header'}>
                      <Label>1. {t('projectDefinition')}</Label>
                    </div>
                    {!(userSelectedProject) || userSelectedProject[0]?.key && (
                      <Stack
                        horizontal
                        wrap
                        styles={stackStyles}
                        tokens={stackTokens}
                      >
                        <Stack.Item grow={6} styles={stackItemStyles}>
                          <div className={`${classNames.fullWidth}`}>
                            <Field
                              name='title'
                              component={TextFieldFF}
                              lable={t('title')}
                              disabled={true}
                            />
                          </div>
                        </Stack.Item>
                        <Stack.Item grow={6} styles={stackItemStyles}>
                          <div className={`${classNames.fullWidth}`}>
                            <Field
                              name='sequenceCode'
                              component={TextFieldFF}
                              lable={t('ID')}
                              disabled={true}
                            />
                          </div>
                        </Stack.Item>
                      </Stack>
                    )}

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${contentStyles.fullWidth}`}>
                          <Label required={true}>{t('name')}</Label>
                          <Field name='name'>
                            {({ input, meta }) => (
                              <>
                                <TagPicker
                                  selectedItems={userSelectedProject ? userSelectedProject : []}
                                  removeButtonAriaLabel='Remove'
                                  onResolveSuggestions={onFilterProjectChanged}
                                  onItemSelected={onProjectSelected}
                                  onChange={onRemoveCompanySuggestion as any}
                                  pickerSuggestionsProps={tagSearchSuggestionProps}
                                  itemLimit={1}
                                  disabled={!isMyEnvironment()}
                                  inputProps={{
                                    onBlur: (ev: React.FocusEvent<HTMLInputElement>) =>
                                      console.log(),
                                    onFocus: (ev: React.FocusEvent<HTMLInputElement>) =>
                                      console.log(),
                                    'aria-label': 'Tag Picker',
                                  }}
                                  onRenderSuggestionsItem={onRenderSuggestionsItemTagPicker}
                                />
                                {(meta.error || meta.submitError) &&
                                  meta.touched && (
                                    <Text
                                      styles={{
                                        root: { color: '#a4262c', fontSize: 12 },
                                      }}
                                    >
                                      {meta.error || meta.submitError}
                                    </Text>
                                  )}
                              </>
                            )}
                          </Field>
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field name='isCiawEnabled'>
                            {({ input }) => (
                              <Checkbox
                                checked={!!values?.isCiawEnabled}
                                label={t('enableCIAW')}
                                className='btn btn-outline-success'
                                styles={{
                                  root: {
                                    verticalAlign: 'middle',
                                    marginBottom: 9,
                                  },
                                }}
                                onChange={() => {
                                  form.mutators.setCiawEnabled(!input.value);
                                }}
                                boxSide={'end'}
                              />
                            )}
                          </Field>

                          <Field
                            name='ciawSiteCode'
                            component={TextFieldFF}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={{ root: { paddingLeft: 0, paddingRight: 0, marginBottom: 30, marginTop: 4 } }}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name='description'
                            component={TextFieldFF}
                            multiline
                            lable={t('description')}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name='projectScopeStatusId'
                            component={DropDownAdaptater}
                            options={projectScopeStatus}
                            lable={t('scopeStatus')}
                            placeholder={t('selectanoption')}
                            disabled={false}
                            required={true}
                            defaultValue={projectScopeStatus[0].key}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name='language'
                            component={DropDownAdaptater}
                            options={projectLanguage}
                            lable={t('language')}
                            placeholder={t('language')}
                            disabled={false}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name='projectStatus'
                            component={DropDownAdaptater}
                            options={projectState}
                            lable={t('projectStatus')}
                            placeholder={t('projectStatus')}
                            disabled={false}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field name='projectManagerId'>
                            {({ input, meta }) => (
                              <PeoplePicker
                                label={t('projectManager')}
                                isDisabled={false}
                                selectedItem={
                                  values?.projectManagerId
                                    ? [
                                      {
                                        key: values?.projectManagerId,
                                        text: values?.projectManagerName,
                                      },
                                    ]
                                    : []
                                }
                                onChange={(items: any) => {
                                  if (items && _.isArray(items) && items.length === 0) {
                                    input.onChange(null);
                                  }
                                  if (!items) {
                                    input.onChange(null);
                                  }

                                  return items ? items : [];
                                }}
                                onPersonSelected={(item: any) => {
                                  input.onChange(item.cabPersonId);
                                  form.mutators.setPMName(item?.text);
                                  return item;
                                }}
                              />
                            )}
                          </Field>
                          <Field
                            name='projectManagerName'
                            component={'input'}
                            type={'text'}
                            disabled={true}
                            hidden
                          />
                        </div>
                      </Stack.Item>
                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field name='customerId'>
                            {({ input, meta }) => (
                              <div>
                                <Label required={false}>{t('customer')}</Label>
                                <NormalPeoplePicker
                                  // eslint-disable-next-line react/jsx-no-bind
                                  selectedItems={values?.customerId
                                    ? [
                                      {
                                        key: values?.customerId,
                                        text: values?.customerName,
                                      },
                                    ]
                                    : []}
                                  onResolveSuggestions={onFilterPersonChanged}
                                  // eslint-disable-next-line react/jsx-no-bind
                                  onChange={(items: any) => {
                                    if (items && _.isArray(items) && items.length === 0) {
                                      input.onChange(null);
                                    }
                                    if (!items) {
                                      input.onChange(null);
                                    }
                                    return items ? items : [];
                                  }}
                                  onItemSelected={(item: any) => {
                                    if (item) {
                                      // const selectedKey = `/CU/${contractingUnit}/project/${resource?.resourceId}/project-planning`;
                                      if (item.isNewItem) {
                                        window.open('/address-book/new');
                                      } else {
                                        input.onChange(item.person.id);
                                        form.mutators.setCustomerName(item?.person.fullName);
                                      }
                                      return item;
                                    }
                                    return null;
                                  }}
                                  pickerSuggestionsProps={limitedSearchAdditionalProps}
                                  className={'ms-PeoplePicker'}
                                  key={'normal'}
                                  itemLimit={1}
                                  // eslint-disable-next-line react/jsx-no-bind
                                  // onValidateInput={validateInput}
                                  removeButtonAriaLabel={'Remove'}
                                  inputProps={{
                                    onBlur: () => console.log('onBlur called'),
                                    onFocus: () => console.log('onFocus called'),
                                    'aria-label': 'People Picker',
                                  }}
                                  onInputChange={(input: string) => {
                                    return input;
                                  }}
                                  resolveDelay={300}
                                  disabled={false}
                                  onRenderSuggestionsItem={onRenderSuggestionsItem}
                                />
                                {/*{meta.error && (*/}
                                {/*  <Text styles={{root: {color: '#a4262c', fontSize: 12}}}>*/}
                                {/*      {meta.error}*/}
                                {/*  </Text>*/}
                                {/*)}*/}
                              </div>
                            )}
                          </Field>
                          <Field
                            name='customerName'
                            component={'input'}
                            type={'text'}
                            disabled={true}
                            hidden
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field name='siteManagerId'>
                            {({ input, meta }) => (
                              <PeoplePicker
                                label={t('siteManager')}
                                isDisabled={false}
                                selectedItem={
                                  values?.siteManagerId
                                    ? [
                                      {
                                        key: values?.siteManagerId,
                                        text: values?.siteManagerName,
                                      },
                                    ]
                                    : []
                                }
                                onChange={(items: any) => {
                                  if (items && _.isArray(items) && items.length === 0) {
                                    input.onChange(null);
                                  }
                                  if (!items) {
                                    input.onChange(null);
                                  }

                                  return items ? items : [];
                                }}
                                onPersonSelected={(item: any) => {
                                  input.onChange(item.cabPersonId);
                                  form.mutators.setSMName(item?.text);
                                  return item;
                                }}
                              />
                            )}
                          </Field>
                          <Field
                            name='siteManagerName'
                            component={'input'}
                            type={'text'}
                            disabled={true}
                            hidden
                          />
                        </div>
                      </Stack.Item>
                    </Stack>


                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <div className={`${classNames.fullWidth}`}>
                        <div style={{ zIndex: 10000000 }}>
                          <Field name='location'>
                            {({ input, meta }) => (
                              <AzureLocationComponent
                                setLocationAddress={(address: any) => {
                                  // props.setLocationAddress(address.position, address);
                                  input.onChange(address);
                                }}
                                // position={ AzureLocationComponent.getPosition(values.location) }
                                address={AzureLocationComponent.getAddress(
                                  values?.location,
                                )}
                                disabled={false}
                                azureLocation={values?.location}
                                lable={t('coordinates')}
                                addressLable={t('address')}
                                required={false}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                    </Stack>
                  </div>

                  <div className={`${classNames.fullWidth}`}>
                    <div className={'card-header'}>
                      <Label>2. {t('pmol')}</Label>
                    </div>
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name='pmolTitle'
                            component={TextFieldFF}
                            lable={t('pmolName')}
                            required={true}
                            defaultValue={values?.name}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name='pmolType'
                            component={DropDownAdaptater}
                            options={pmolType}
                            lable={t('pmolType')}
                            required={true}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={'fullWidthWithControlledHeight'} style={{width: '100%'}}>
                          <Label required={true}>{t('labourResourceItem')} </Label>
                          <Field name="labourResource">
                            {({ input, meta}) => (
                              <CustomDropdown
                                reference={''}
                                onChange={(
                                  key: string,
                                  text: string,
                                ) => {
                                  if (key) {
                                    input.onChange({ value: key, label: text });
                                    form.mutators.setLabourResourceItem(key);
                                  } else {
                                    input.onChange(undefined);
                                    form.mutators.setLabourResourceItem(undefined);
                                  }
                                }}
                                selectedOption={values?.labourResource}
                                promiseOptions={labourResourceOptions}
                                required={true}
                                validationMessage={meta.touched && meta.error ? meta.error : null}
                              />
                            )}
                          </Field>
                          <Field // This hidden field is used to store the labourId for the form
                            name='labourResourceItem'
                            component={'input'}
                            type={'text'}
                            disabled={true}
                            hidden
                          />
                        </div>

                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name='labourResourceQuantity'
                            component={TextFieldFF}
                            lable={t('labourResourceQuantity')}
                            type={'number'}
                            step={1}
                            min={0}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>
                  </div>

                  <div className={contentStyles.footer}>
                    <PrimaryButton
                      iconProps={saveIcon}
                      text={t('save')}
                      style={{ marginBottom: 30, marginTop: 20 }}
                      onClick={() => { form.submit() }}
                      disabled={false}
                    />
                  </div>
                </Stack>
                {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
              </form>
            );
          }}
        />
      </div>
    </Modal>
  </div>;
};

export default NewProjectModal;
import React, { useEffect } from 'react';
import {
  DefaultButton,
  FontIcon,
  IStackTokens,
  mergeStyleSets,
  PrimaryButton,
  registerIcons,
  Stack,
} from '@fluentui/react';
import i18n from '../../../../i18n';
import { SvgIcons } from '../../../shared/svgIcons/svgIcons';
import { useDispatch, useSelector } from 'react-redux';
import { messageService } from '../../../services/messageService';
import { acceptLotInvitation, saveLotInvitationStateAttr } from '../../../reducers/lotInvitationReducer';
import { uPrinceTheme } from '../../../../theme';
import MailTemplate from '../template/component';
import { getThemeColor } from '../../../reducers/uprinceReducer';

registerIcons(SvgIcons);

const stackTokens: IStackTokens = { childrenGap: 40 };

const styles = {
  logo: {
    marginTop: '20px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
};

const classNames = mergeStyleSets({
  body: {
    hight: '100%',
    display: 'block',
    marginLeft: '20%',
    marginRight: '20%',
    backgroundColor: 'white',
    padding: '40px',
    minHeight: 'calc(100vh - 120px)',
  },
  spacing: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  bmLogo: {
    display: 'flex',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    border: '0',
    borderSpacing: '0',
  },
  content: {
    padding: '0 62px 0px 160px',
  },
  boldFont: {
    margin: '0 0 25px 0',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Arial,sans-serif',
    color: uPrinceTheme.palette.themePrimary,
    fontWeight: 'bold',
  },
  detailFont: {
    margin: '0 0 25px 0',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Arial,sans-serif',
  },
  h3: {
    margin: '0 0 25px 0',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Arial,sans-serif',
  },
  icon: {
    padding: '0',
    verticalAlign: 'top',
    color: uPrinceTheme.palette.themePrimary,
  },
  tdIcon: {
    width: '40px',
    padding: '0,0,1px,0',
    verticalAlign: 'top',
    color: uPrinceTheme.palette.neutralPrimary,
  },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const InvitationContentBody = () => {
  const dispatch = useDispatch();

  const {
    formData,
    acceptResult,
    invitationId
  }: any = useSelector(
    (state: any) => state.lotInvitation,
  );

  useEffect(() => {
    if (!localStorage.getItem('themeColor')) {
      Promise.all([
        dispatch(getThemeColor())
      ]).then(() => {
        window.location.reload();
      });
    };

    return () => {
      dispatch(saveLotInvitationStateAttr('acceptResult', null));
    }
  }, []);

  const handleAccept = () => {
    let items: any = {
      lotId: formData?.id ? formData?.id : null,
      invitationId: invitationId,
      approve: '1'
    };

    dispatch(acceptLotInvitation(items))
  };

  const handleReject = () => {
    let items: any = {
      lotId: formData?.id ? formData?.id : null,
      invitationId: invitationId,
      approve: '2'
    };

    dispatch(acceptLotInvitation(items))
  };

  return (
    <div className={classNames.body}>
      <div className={classNames.bmLogo}>
        <FontIcon
          iconName={'bm-engineering-invitation-logo'}
          className="ms-Icon"
          style={styles.logo}
        />
      </div>
      {formData?.approve != '0' ?
        <h2 style={{ marginTop: '60px' }}
          className={`${classNames.justifyCenter} mail-template-fonts`}>{i18n.t('alreadyResponded')}!</h2> :
        <div>
          <div className={classNames.spacing}>
            <Stack horizontal tokens={stackTokens} className={classNames.spacing}
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <MailTemplate formData={formData} />
            </Stack>
          </div>
          <Stack horizontal tokens={stackTokens} className={classNames.spacing}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <PrimaryButton
              text={i18n.t('accept')}
              onClick={() => { handleAccept() }}
              allowDisabledFocus
              disabled={acceptResult ? true : false}
              style={{ width: '200px', height: '50px', borderRadius: '5px' }}
            />
            <DefaultButton
              text={i18n.t('Reject')}
              onClick={() => { handleReject() }}
              allowDisabledFocus
              disabled={acceptResult ? true : false}
              style={{ width: '200px', height: '50px', borderRadius: '5px' }}
            />
          </Stack>
        </div>
      }
      {/* <pre>{JSON.stringify(acceptResult)}</pre> */}
    </div>
  );
};

export default InvitationContentBody;

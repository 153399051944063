import { format24hDateTime } from '../shared/util';
import {
  APPROVED_INVOICE_CU_EP,
  INVOICE_SAVE_EP,
  READ_INVOICE_BY_ID_EP,
  READ_INVOICE_DROPDOWN_EP,
  READ_INVOICE_LIST_EP,
  READ_INVOICE_SHORTCUT_PANE_EP,
  REVIEW_INVOICE_CU_EP,
} from '../shared/endpoints';
import {
  APPROVED_INVOICE_CU,
  APPROVED_INVOICE_CU_FAIL,
  APPROVED_INVOICE_CU_SUCCESS,
  CLEAR_INVOICE_FORM_DATA,
  CLEAR_INVOICE_UID,
  LOAD_INVOICE_BY_ID,
  LOAD_INVOICE_BY_ID_FAIL,
  LOAD_INVOICE_BY_ID_SUCCESS,
  LOAD_INVOICE_DROPDOWN,
  LOAD_INVOICE_DROPDOWN_FAIL,
  LOAD_INVOICE_DROPDOWN_SUCCESS,
  LOAD_INVOICE_LSITPANE,
  LOAD_INVOICE_LSITPANE_FAIL,
  LOAD_INVOICE_LSITPANE_SUCCESS,
  LOAD_INVOICE_SHORTCUTPANE,
  LOAD_INVOICE_SHORTCUTPANE_FAIL,
  LOAD_INVOICE_SHORTCUTPANE_SUCCESS,
  RESET_INVOICE_IS_CHANGE,
  REVIEW_INVOICE_CU,
  REVIEW_INVOICE_CU_FAIL,
  REVIEW_INVOICE_CU_SUCCESS,
  SAVE_INVOICE,
  SAVE_INVOICE_FAIL,
  SAVE_INVOICE_SUCCESS,
  SET_INVOICE_UID,
} from '../actionTypes/invoiceActionTypes';
import { Invoice, InvoiceListPaneFilter, ListPaneItem } from '../types/invoice';
import { v4 as uuidv4 } from 'uuid';


const defaultState: any = {
  invoiceShortCutdata: [],
  listPaneItems: [],
  isInvoiceListLoaded: false,
  isNew: false,
  isChange: false,
  isEdit: false,
  showProgressBar: false,
  invoiceDropDowns: {},
  formData: {},
  uid: null,
  loadMsg: '',
};

export default function invoiceReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {
      case LOAD_INVOICE_SHORTCUTPANE:
        // UprinceLogger.log('LOAD_INVOICE_SHORTCUTPANE', action);
        return { ...state };
      case LOAD_INVOICE_SHORTCUTPANE_SUCCESS:
        // UprinceLogger.log('LOAD_INVOICE_SHORTCUTPANE_SUCCESS', action);
        return {
          ...state,
          invoiceShortCutdata: action.payload.data.result,
        };
      case LOAD_INVOICE_SHORTCUTPANE_FAIL:
        // UprinceLogger.log(
        //   'LOAD_INVOICE_SHORTCUTPANE_FAIL',
        //   action.error.response.data.message
        // );
        return { ...state };

      case LOAD_INVOICE_LSITPANE:
        // UprinceLogger.log('LOAD_INVOICE_LSITPANE', action);
        return { ...state, isINVOICEListLoaded: false };
      case LOAD_INVOICE_LSITPANE_SUCCESS:
        // UprinceLogger.log('LOAD_INVOICE_LSITPANE_SUCCESS', action);
        return {
          ...state,
          isChange: false,
          isInvoiceListLoaded: true,
          listPaneItems: formattedInvoiceList(action.payload.data.result),
          loadMsg: !action.payload.data.status
            ? action.payload.data.message
            : '',
        };
      case LOAD_INVOICE_LSITPANE_FAIL:
        // UprinceLogger.log(
        //   'LOAD_INVOICE_LSITPANE_FAIL',
        //   action.error.response.data.message
        // );
        return {
          ...state,
          isInvoiceListLoaded: true,
          listPaneItems: [],
          loadMsg: action.error.response.data.message,
        };


      case RESET_INVOICE_IS_CHANGE:
        return {
          ...state,
          isChange: false,
        };

      case LOAD_INVOICE_DROPDOWN:
        // UprinceLogger.log('LOAD_INVOICE_DROPDOWN', action);
        return { ...state, invoiceDropDowns: {} };
      case LOAD_INVOICE_DROPDOWN_SUCCESS:
        // UprinceLogger.log('LOAD_INVOICE_DROPDOWN_SUCCESS', action);
        return {
          ...state,
          invoiceDropDowns: action.payload.data.result,
        };
      case LOAD_INVOICE_DROPDOWN_FAIL:
        // UprinceLogger.log(
        //   'LOAD_INVOICE_DROPDOWN_FAIL',
        //   action.error.response.data.message
        // );
        return {
          ...state,
          invoiceDropDowns: {},
        };

      case LOAD_INVOICE_BY_ID:
        // UprinceLogger.log('LOAD_INVOICE_BY_ID', action);
        return { ...state, formData: {}, showProgressBar: true };
      case LOAD_INVOICE_BY_ID_SUCCESS:
        // UprinceLogger.log('LOAD_INVOICE_BY_ID_SUCCESS', action);
        return {
          ...state,
          isEdit: true,
          formData: action.payload.data.result,
          showProgressBar: false,
        };
      case LOAD_INVOICE_BY_ID_FAIL:
        // UprinceLogger.log(
        //   'LOAD_INVOICE_BY_ID_FAIL',
        //   action.error.response.data.message
        // );
        return {
          ...state,
          formData: {},
          showProgressBar: false,
        };

      case SAVE_INVOICE:
        return { ...state, showProgressBar: true };
      case SAVE_INVOICE_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
        };
      case SAVE_INVOICE_FAIL:
        return {
          ...state,
          showProgressBar: false,
        };

      case SET_INVOICE_UID:
        return {
          ...state,
          isEdit: false,
          uid: uuidv4(),
        };
      case CLEAR_INVOICE_UID:
        return {
          ...state,
          uid: null,
          isChange: false,
        };
      case CLEAR_INVOICE_FORM_DATA:
        return {
          ...state,
          uid: null,
          isNew: true,
          isChange: false,
          formData: {},
        };

      case APPROVED_INVOICE_CU:
        return { ...state, showProgressBar: true };
      case APPROVED_INVOICE_CU_SUCCESS:
        return {
          ...state,
          isChange: true,
          showProgressBar: false,
        };
      case APPROVED_INVOICE_CU_FAIL:
        return {
          ...state,
          showProgressBar: false,
        };

      default:
        return state;
    }
  }
}

export const readInvoiceShortCutPaneData = () => {
  return {
    types: [
      LOAD_INVOICE_SHORTCUTPANE,
      LOAD_INVOICE_SHORTCUTPANE_SUCCESS,
      LOAD_INVOICE_SHORTCUTPANE_FAIL,
    ],
    payload: { request: { url: READ_INVOICE_SHORTCUT_PANE_EP } },
  };
};

export const readInvoiceDropDownData = (projectSequenceCode: string | null) => {
  return {
    types: [
      LOAD_INVOICE_DROPDOWN,
      LOAD_INVOICE_DROPDOWN_SUCCESS,
      LOAD_INVOICE_DROPDOWN_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        data: { projectSequenceCode: projectSequenceCode },
        url: READ_INVOICE_DROPDOWN_EP,
      },
    },
    // payload: { request: { url: READ_INVOICE_DROPDOWN_EP } }
  };
};


export const filterInvoiceListPaneData = (filter: InvoiceListPaneFilter) => {
  return {
    types: [
      LOAD_INVOICE_LSITPANE,
      LOAD_INVOICE_LSITPANE_SUCCESS,
      LOAD_INVOICE_LSITPANE_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_INVOICE_LIST_EP,
        data: filter,
      },
    },
  };
};


export const readByInvoiceId = (id: string) => {
  return {
    types: [LOAD_INVOICE_BY_ID, LOAD_INVOICE_BY_ID_SUCCESS, LOAD_INVOICE_BY_ID_FAIL],
    payload: { request: { url: READ_INVOICE_BY_ID_EP + id } },
  };
};

export const approvedInvoiceCu = (id: string) => {
  return {
    types: [APPROVED_INVOICE_CU, APPROVED_INVOICE_CU_SUCCESS, APPROVED_INVOICE_CU_FAIL],
    payload: {
      request: {
        url: APPROVED_INVOICE_CU_EP + id,
        method: 'PUT',
      },
    },
  };
};

export const inReviewInvoiceCu = (id: string) => {
  return {
    types: [REVIEW_INVOICE_CU, REVIEW_INVOICE_CU_SUCCESS, REVIEW_INVOICE_CU_FAIL],
    payload: {
      request: {
        url: REVIEW_INVOICE_CU_EP + id,
        method: 'PUT',
      },
    },
  };
};

export const resetIsChange = () => {
  return { type: RESET_INVOICE_IS_CHANGE };
};

export const saveFormData = (formData: Invoice) => {
  return {
    types: [SAVE_INVOICE, SAVE_INVOICE_SUCCESS, SAVE_INVOICE_FAIL],
    payload: {
      request: {
        url: INVOICE_SAVE_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const setUid = () => {
  return { type: SET_INVOICE_UID };
};

export const clearFormData = () => {
  return { type: CLEAR_INVOICE_FORM_DATA };
};


const formattedInvoiceList = (result: any) => {
  if (result) {
    const data = result.map((item: ListPaneItem) => {
      return {
        ...item,
        id: item.id ? item.id : '-',
        invoiceId: item.invoiceId ? item.invoiceId : '-',
        invoiceStatus: item.invoiceStatus ? item.invoiceStatus : '-',
        invoiceStatusId: item.invoiceStatusId ? item.invoiceStatusId : '-',
        name: item.name ? item.name : '-',
        psId: item.psId ? item.psId : '-',
        purchaseOrderNumber: item.purchaseOrderNumber ? item.purchaseOrderNumber : '-',
        title: item.title ? item.title : '-',
        totalExcludingTax: item.totalExcludingTax ? parseFloat(item.totalExcludingTax).toFixed(2) : '-',
        totalIncludingTax: item.totalIncludingTax ? parseFloat(item.totalIncludingTax).toFixed(2) : '-',
        workPeriodFrom: item.workPeriodFrom ? format24hDateTime(item.workPeriodFrom) : '-',
        workPeriodTo: item.workPeriodTo ? format24hDateTime(item.workPeriodTo) : '-',
        date: item.date ? format24hDateTime(item.date) : '-',
      };
    });
    return data;
  }
  return [];
};

import { DirectionalHint, Label, Link, Stack, Text, TooltipHost } from '@fluentui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StandardMailShortCutPaneItem } from '../../../types/standardMail';
import history from '../../../history';
import { getContractingUnit } from '../../../shared/util';

interface Props {
    shortCutPaneFilters?: StandardMailShortCutPaneItem[];
    currentActiveSection?: string | null;
    handelShortCutPaneFilter?: (selectedItem: StandardMailShortCutPaneItem) => void;
    resetFilter?: () => void;
}

const allFilterItem: StandardMailShortCutPaneItem = {
    key: 'all',
    id: null,
    type: 'all',
    name: 'All',
    value: null,
};

export const WbsCreateShortcutPane = (props: Props) => {
    const {t} = useTranslation();

    const [toggleOverlay, setToggleOverlay] = useState<boolean>(false);

    const setFilterOption = (selectedItem: StandardMailShortCutPaneItem) => {
        if (selectedItem.type === 'all') {
            history.push(`/CU/${getContractingUnit()}/wbs-create`);
        }
        // props.handelShortCutPaneFilter(selectedItem);
    };
    const renderFilterItems = () => {
        if (props.shortCutPaneFilters) {
            return props.shortCutPaneFilters.map((item: any, index) => {
                let divider = false;
                if (index > 0) {
                    // const previousItem: StandardMailShortCutPaneItem =
                    //     props.shortCutPaneFilters[index - 1];
                    // divider = previousItem.type !== item.type;
                } else {
                    divider = true;
                }

                return (
                    <div key={item.id + 'div'}>
                        {divider && (
                            <hr
                                key={item.id + 'hr'}
                                style={{marginBottom: 15, borderTop: '1px solid #d2d2d2'}}
                            />
                        )}
                        <Link
                            key={item.id + 'link'}
                            className={`${
                                props.currentActiveSection === item.id ? 'selected' : ''
                            }`}
                            href="#"
                            onClick={() => {
                                setFilterOption(item);
                            }}
                        >
                            <TooltipHost
                                key={item.id + 't'}
                                content={item.name}
                                id="filter-opt-1"
                                calloutProps={{gapSpace: 0}}
                                directionalHint={DirectionalHint.rightCenter}
                            >
                                <i
                                    key={item.id + 'i'}
                                    className="ms-Icon ms-Icon--GotoToday"
                                    aria-labelledby="filter-opt-1"
                                    aria-hidden="true"
                                ></i>
                            </TooltipHost>
                            <i
                                key={item.id + 'i2'}
                                className="filter-icon ms-Icon ms-Icon--GotoToday filter-icon"
                                aria-hidden="true"
                            ></i>
                            <Text key={item.id}>{item.name} </Text>
                        </Link>
                    </div>
                );
            });
        }
        return <div></div>;
    };

    return (
        <div className={`filter-panel-left wrapper ${toggleOverlay ? 'panel-move' : ''}`}>
            <div className="inner-container">
                <i
                    onClick={() => setToggleOverlay((prevState) => !prevState)}
                    className="ms-Icon ms-Icon--Back back-arrow"
                    aria-hidden="true"
                ></i>
                <i
                    onClick={() => setToggleOverlay((prevState) => !prevState)}
                    className="ms-Icon ms-Icon--Forward fwd-arrow"
                    aria-hidden="true"
                ></i>

                <div className="filter-data filter-title">
                    <TooltipHost
                        content={t('standardmail')}
                        // This is the important part!
                        id="filter-opt-title"
                        calloutProps={{gapSpace: 0}}
                        directionalHint={DirectionalHint.rightCenter}
                    >
                        <Label
                            aria-describedby={'filter-opt-title'}
                            className={'short-cut-pane-label'}
                        >
                            {t('standardmail')}
                        </Label>
                    </TooltipHost>
                </div>

                <div className="filter-option-list">
                    <Stack
                        verticalFill
                        styles={{
                            root: {
                                width: '100%',
                                verticalAlign: 'center',
                            },
                        }}
                    >
                        <Link
                            key={'All-link'}
                            className={`${
                                props.currentActiveSection === null ? 'selected' : ''
                            }`}
                            href="#"
                            onClick={() => {
                                setFilterOption(allFilterItem);
                            }}
                        >
                            <TooltipHost
                                key={'All-link1'}
                                content={t('all')}
                                id="filter-opt-1"
                                calloutProps={{gapSpace: 0}}
                                directionalHint={DirectionalHint.rightCenter}
                            >
                                <i
                                    key={'All-linki'}
                                    className="ms-Icon ms-Icon--ViewAll"
                                    aria-labelledby="filter-opt-1"
                                    aria-hidden="true"
                                ></i>
                            </TooltipHost>
                            <i
                                key={'All-linki2'}
                                className="filter-icon ms-Icon ms-Icon--ViewAll filter-icon"
                                aria-hidden="true"
                            ></i>
                            <Text key="all"> {t('all')}</Text>
                        </Link>
                        {renderFilterItems()}
                    </Stack>
                </div>
            </div>
        </div>
    );
};

export default WbsCreateShortcutPane;

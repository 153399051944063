import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {
  ActionButton,
  Checkbox,
  ContextualMenu,
  DetailsList,
  DetailsListLayoutMode,
  Dropdown,
  FontWeights,
  getTheme,
  ICheckboxProps,
  Icon,
  IconButton,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  Modal,
  PrimaryButton,
  Stack,
  Sticky,
  StickyPositionType,
  TextField,
} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import {mergeStyleSets} from '@fluentui/react/lib/Styling';
import {useBoolean, useId} from '@uifabric/react-hooks';
import UprinceLogger from '../../../shared/Logger/logger';
import {
  ActionButtonState,
  CorporateProductCatalogue,
  NickName,
  NickNameValidationMessage,
  ProductCatalogueValidationMessage,
  Vendor,
  VendorValidationMessage,
} from '../../../types/corporateProductCatalogue';
import {uPrinceTheme} from '../../../../theme';
import ImageGrid from '../../../shared/imageGrid/imageGrid';
import HistoryBar from '../../../shared/historyBar/historyBar';
import CustomDropdown from '../../../shared/customDropdown/customDropdown';
import _ from 'lodash';
import ClearableDropdown from '../../../shared/clearableDropdown/clearableDropdown';
import QrCodeBar from '../../../shared/qrCode/component';
import ResourceFamilyTaxonomy from '../resourceFamily/taxonomy';

const theme = getTheme();

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
// Styles definition
const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackEditbarItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 45,
    alignItems: 'flex-end',
  },
};

const stackItemStylesColThree: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
  },
};

const stackItemStylesCheckBox: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: '21%',

    selectors: {
      '@media(max-width: 1200px)': {
        width: '21%',
      },
    },
  },
};

// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

export const CpcDocumentPaneComponent = (props: {
  isEdit: boolean;
  openNickNameModel: () => void;
  handleNickNameDelete: () => void;
  handleVendorDelete: () => void;
  handleImageDelete: (id: string) => void;
  isOpenNickNameModel: boolean;
  //nickNames: NickName[];
  editNickName: (id: string) => void;
  nickNameSelection: any;
  nickNameSelectionDetails: {};
  nickNameActionButtonState: ActionButtonState;
  selectedResourceFamily: string;
  onChangeResourceFamily: (treeData: any) => void;
  openVendorModel: () => void;
  isOpenVendorModel: boolean;
  //vendors: Vendor[];
  editVendor: (id: string) => void;
  vendorSelection: any;
  vendorSelectionDetails: {};
  vendorActionButtonState: ActionButtonState;
  savePicturesOfTheInstallation: (files: FileList | null) => void;
  resourceTypes: [];
  resourceFamily: any;
  cpcBasicUnitOfMeasure: [];
  cpcPressureClass: [],
  cpcMaterial: [],
  cpcBrand: [],
  languageCodes: [];
  cpcUnitOfSizeMeasure: [];

  productCatalogue: CorporateProductCatalogue;
  handleProductCatalogueChange: (
    productCatalogue: CorporateProductCatalogue,
    save: boolean,
  ) => void;
  autoSaveProductCatalogue: () => void;
  saveProductCatalogue: () => void;
  validationMessages: ProductCatalogueValidationMessage;
  resetSelection: () => void;
  submitCpc: boolean;
  disabled?: boolean;
  vehicleTrackingNumberList: [];
}) => {
  const classNames = mergeStyleSets({
    fullWidth: {
      width: '100%',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: {
        '@media(max-width: 1100px)': {
          textAlign: 'start',
        },
      },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    bigScreenTree: {
      marginBottom: 1,
      height: '30vh',
      padding: 15,
    },
    smallScreenTree: {
      marginBottom: 1,
      height: '50vh',
      padding: 15,
    },
  });
  const { t } = useTranslation();
  const inputFileRef = useRef<HTMLInputElement>(null);

  const uploadFiles = (files: FileList | null) => {
    //files && context.uploadItems(files);
    if (files) {
      let size = files[0].size;
      //alert(size);
    }

    UprinceLogger.log('uploadFiles', files);
    props.savePicturesOfTheInstallation(files);
  };

  const showFileDialog = () => {
    inputFileRef.current && inputFileRef.current.click();
  };
  const uploadIcon: IIconProps = {
    iconName: 'Upload',
    styles: iconButtonStyles,
  };

  useEffect(() => {
    props.resetSelection();
  }, [props.productCatalogue.id]);

  useEffect(() => {
    //alert("save doc :"+JSON.stringify(props.submitCpc));
    if (props.submitCpc) {
      props.saveProductCatalogue();
    }
  }, [props.submitCpc]);

  return (
    <div style={{ width: '100%' }} id={'productCatalogueForm'} className="card-container">
      <div className="proj-detail-block " id="1">
        <div className="document-pane-card ">
          <div className={'card-header'}>
            <Link href="#" id="corporate-product-header">
              <Label>1. {t('corporateProductHeader')} </Label>
            </Link>
          </div>
          <div className="proj-detail-content inner" style={{ marginBottom: 50 }}>
            <div className="marginTop">
              <div className={'card-body'}>
                {props.isEdit && props.productCatalogue.title &&
                <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        label={t('cPCResourcetitle')}
                        value={
                          props.productCatalogue.title
                            ? props.productCatalogue.title
                            : ''
                        }
                        disabled={true}
                      />
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        label={t('resourceNumber')}
                        value={
                          props.productCatalogue.resourceNumber
                            ? props.productCatalogue.resourceNumber
                            : ''
                        }
                        disabled={true}
                      />
                    </div>
                  </Stack.Item>
                </Stack>}
              </div>

              <div className="marginTop">
                <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        label={t('resourceName')}
                        value={
                          props.productCatalogue.resourceTitle
                            ? props.productCatalogue.resourceTitle
                            : ''
                        }
                        errorMessage={
                          props.validationMessages.resourceTitleErrorMsg
                            ? props.validationMessages.resourceTitleErrorMsg
                            : ''
                        }
                        onBlur={() => {
                          props.handleProductCatalogueChange(
                            props.productCatalogue,
                            (!!props.productCatalogue.id),
                          );
                        }}
                        validateOnFocusOut={true}
                        required={true}
                        onChange={(event, value) => {
                          if (value) {
                            props.productCatalogue.resourceTitle = value;
                            props.validationMessages.resourceTitleErrorMsg = '';
                          } else {
                            props.productCatalogue.resourceTitle = null;
                          }
                          UprinceLogger.log(
                            'productCatalogue 1',
                            props.productCatalogue,
                          );
                          props.handleProductCatalogueChange(
                            props.productCatalogue,
                            false,
                          );
                        }}
                        disabled={props.disabled}
                      />
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <ClearableDropdown
                        placeholder={t('cPCResourcetype')}
                        label={t('cPCResourcetype')}
                        options={props.resourceTypes}
                        required={true}
                        errorMessage={
                          props.validationMessages.resourceTypeErrorMsg
                            ? props.validationMessages.resourceTypeErrorMsg
                            : ''
                        }
                        selectedKey={
                          props.productCatalogue.resourceTypeId
                            ? props.productCatalogue.resourceTypeId
                            : ''
                        }
                        onChange={(event, value) => {
                          if (value) {
                            props.productCatalogue.resourceTypeId = value.key.toString();
                          } else {
                            props.productCatalogue.resourceTypeId = null;
                          }
                          props.handleProductCatalogueChange(
                            props.productCatalogue,
                            true,
                          );
                        }}
                        disabled={props.disabled}
                      />
                    </div>
                  </Stack.Item>
                </Stack>
              </div>

              {props.productCatalogue.id && props.productCatalogue.status == 1 && (
                <QrCodeBar
                  value={props.productCatalogue.id}
                  name={props.productCatalogue.title}
                />
              )}

              <div className="marginTop">
                <NickNameGrid
                  nickNameSelection={props.nickNameSelection}
                  nickNames={props.productCatalogue.cpcResourceNickname}
                  nickNameSelectionDetails={props.nickNameSelectionDetails}
                  nickNameActionButtonState={props.nickNameActionButtonState}
                  editNickName={(id: string) => props.editNickName(id)}
                  openNickNameModel={() => {
                    props.openNickNameModel();
                  }}
                  handleNickNameDelete={() => {
                    props.handleNickNameDelete();
                  }}
                  disabled={props.disabled}
                />
              </div>

              <div className="marginTop" style={{ paddingRight: 10,paddingLeft: 10 }} dir="ltr">
                {props.resourceFamily && (
                  <ResourceFamilyTaxonomy
                    label={t('resourceFamily')}
                    selectItemId={props.productCatalogue.resourceFamilyId}
                    treeData={props.resourceFamily}
                    onChange={(selectedNode: any) => {
                      if (selectedNode) {
                        if (selectedNode.id == props.productCatalogue.resourceFamilyId) {
                          props.productCatalogue.resourceFamilyId = null;
                        } else {
                          props.productCatalogue.resourceFamilyId = selectedNode.id;
                        }
                      } else {
                        props.productCatalogue.resourceFamilyId = null;
                      }
                      props.handleProductCatalogueChange(
                        props.productCatalogue,
                        true,
                      );
                    }}
                    disabled={props.disabled}
                  />
                  // <ResourceFamily
                  //   label={t('resourceFamily')}
                  //   selectItemId={props.productCatalogue.resourceFamilyId}
                  //   treeData={props.resourceFamily}
                  //   onSelectItem={(id: string) => {
                  //     if (id) {
                  //       if (id == props.productCatalogue.resourceFamilyId) {
                  //         props.productCatalogue.resourceFamilyId = null;
                  //       } else {
                  //         props.productCatalogue.resourceFamilyId = id;
                  //       }
                  //     } else {
                  //       props.productCatalogue.resourceFamilyId = null;
                  //     }
                  //     props.handleProductCatalogueChange(
                  //       props.productCatalogue,
                  //       true,
                  //     );
                  //   }}
                  //   disabled={props.disabled}
                  // />
                )}
              </div>

              <div className="marginTop">
                <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <ClearableDropdown
                        placeholder={t('cpcBasicunitofMeasure')}
                        label={t('cpcBasicunitofMeasure')}
                        options={props.cpcBasicUnitOfMeasure}
                        errorMessage={
                          props.validationMessages.basicUnitOfMeasureErrorMsg
                            ? props.validationMessages.basicUnitOfMeasureErrorMsg
                            : ''
                        }
                        selectedKey={
                          props.productCatalogue.cpcBasicUnitOfMeasureId
                            ? props.productCatalogue.cpcBasicUnitOfMeasureId
                            : ''
                        }
                        onChange={(event, value) => {
                          if (value) {
                            props.productCatalogue.cpcBasicUnitOfMeasureId = value.key.toString();
                          } else {
                            props.productCatalogue.cpcBasicUnitOfMeasureId = null;
                          }
                          props.handleProductCatalogueChange(
                            props.productCatalogue,
                            true,
                          );
                        }}
                        required={true}
                        disabled={props.disabled}
                      />
                    </div>
                  </Stack.Item>
                </Stack>
              </div>


              <div className="marginTop">
                <Label style={{ paddingLeft: 9, paddingTop: 20 }}>
                  1.1 {t('technicalDetails')}
                </Label>
                <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                  <Stack.Item grow={6} styles={stackItemStylesColThree}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        type="number"
                        min={0}
                        label={t('size')}
                        value={
                          props.productCatalogue.size
                            ? props.productCatalogue.size
                            : ''
                        }
                        onChange={(event, value) => {
                          if (value) {
                            props.productCatalogue.size = value;
                          } else {
                            props.productCatalogue.size = null;
                          }
                          props.handleProductCatalogueChange(
                            props.productCatalogue,
                            false,
                          );
                        }}
                        onBlur={() => {
                          props.handleProductCatalogueChange(
                            props.productCatalogue,
                            true,
                          );
                        }}
                        disabled={props.disabled}
                      />
                    </div>
                    <Stack.Item grow={2} styles={stackItemStylesColThree}>
                      <Dropdown
                        placeholder="mm"
                        selectedKey={
                          props.productCatalogue.cpcUnitOfSizeMeasureId
                            ? props.productCatalogue.cpcUnitOfSizeMeasureId
                            : ''
                        }
                        label="&nbsp;"
                        options={props.cpcUnitOfSizeMeasure}
                        onChange={(event, value) => {
                          if (value) {
                            props.productCatalogue.cpcUnitOfSizeMeasureId = value.key.toString();
                          } else {
                            props.productCatalogue.cpcUnitOfSizeMeasureId = null;
                          }
                          props.handleProductCatalogueChange(
                            props.productCatalogue,
                            true,
                          );
                        }}
                        disabled={props.disabled}
                      />
                    </Stack.Item>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStylesColThree}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        type="number"
                        label={t('wallthickness')}
                        min={0}
                        suffix="mm"
                        value={
                          props.productCatalogue.wallThickness
                            ? props.productCatalogue.wallThickness
                            : ''
                        }
                        onChange={(event, value) => {
                          if (value) {
                            props.productCatalogue.wallThickness = value;
                          } else {
                            props.productCatalogue.wallThickness = null;
                          }
                          props.handleProductCatalogueChange(
                            props.productCatalogue,
                            false,
                          );
                        }}
                        onBlur={() => {
                          props.handleProductCatalogueChange(
                            props.productCatalogue,
                            true,
                          );
                        }}
                        disabled={props.disabled}
                      />
                    </div>
                  </Stack.Item>
                </Stack>

                <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <ClearableDropdown
                        placeholder={t('material')}
                        label={t('material')}
                        options={props.cpcMaterial}
                        selectedKey={
                          props.productCatalogue.cpcMaterialId
                            ? props.productCatalogue.cpcMaterialId
                            : ''
                        }
                        onChange={(event, value) => {
                          if (value) {
                            props.productCatalogue.cpcMaterialId = value.key.toString();
                          } else {
                            props.productCatalogue.cpcMaterialId = null;
                          }
                          props.handleProductCatalogueChange(
                            props.productCatalogue,
                            true,
                          );
                        }}
                        disabled={props.disabled}
                      />
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <ClearableDropdown
                        placeholder={t('brand')}
                        label={t('brand')}
                        options={props.cpcBrand}
                        selectedKey={
                          props.productCatalogue.cpcBrandId
                            ? props.productCatalogue.cpcBrandId
                            : ''
                        }
                        onChange={(event, value) => {
                          if (value) {
                            props.productCatalogue.cpcBrandId = value.key.toString();
                          } else {
                            props.productCatalogue.cpcBrandId = null;
                          }
                          props.handleProductCatalogueChange(
                            props.productCatalogue,
                            true,
                          );
                        }}
                        disabled={props.disabled}
                      />
                    </div>
                  </Stack.Item>
                </Stack>
                <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <ClearableDropdown
                        placeholder={t('pressureClass')}
                        label={t('pressureClass')}
                        selectedKey={
                          props.productCatalogue.cpcPressureClassId
                            ? props.productCatalogue.cpcPressureClassId
                            : ''
                        }
                        options={props.cpcPressureClass}
                        onChange={(event, value) => {
                          if (value) {
                            props.productCatalogue.cpcPressureClassId = value.key.toString();
                          } else {
                            props.productCatalogue.cpcPressureClassId = null;
                          }
                          props.handleProductCatalogueChange(
                            props.productCatalogue,
                            true,
                          );
                        }}
                        disabled={props.disabled}
                      />
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        type="number"
                        label={t('weight')}
                        min={0}
                        suffix="Kg"
                        value={
                          props.productCatalogue.weight
                            ? props.productCatalogue.weight
                            : ''
                        }
                        onChange={(event, value) => {
                          if (value) {
                            props.productCatalogue.weight = value;
                          } else {
                            props.productCatalogue.weight = null;
                          }
                          props.handleProductCatalogueChange(
                            props.productCatalogue,
                            false,
                          );
                        }}
                        onBlur={() => {
                          props.handleProductCatalogueChange(
                            props.productCatalogue,
                            true,
                          );
                        }}
                        disabled={props.disabled}
                      />
                    </div>
                  </Stack.Item>
                </Stack>

                <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        type="number"
                        label={t('velocity')}
                        min={0}
                        value={
                          props.productCatalogue.velocity
                            ? props.productCatalogue.velocity
                            : ''
                        }
                        onChange={(event, value) => {
                          if (value) {
                            props.productCatalogue.velocity = value;
                          } else {
                            props.productCatalogue.velocity = null;
                          }
                          props.handleProductCatalogueChange(
                            props.productCatalogue,
                            false,
                          );
                        }}
                        onBlur={() => {
                          props.handleProductCatalogueChange(
                            props.productCatalogue,
                            true,
                          );
                        }}
                        disabled={props.disabled}
                      />
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>

                    </div>
                  </Stack.Item>
                </Stack>
              </div>

              <div className="marginTop">
                <Label style={{ paddingLeft: 9 }}>
                  1.2 {t('inventoryContractingunit')}
                </Label>
                <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                  <Stack.Item grow={3} styles={stackItemStylesColThree}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        label={t('cpcResourceCorporateInventoryprice')}
                        value={
                          props.productCatalogue.inventoryPrice
                            ? props.productCatalogue.inventoryPrice
                            : ''
                        }
                        onBlur={() => {
                          props.handleProductCatalogueChange(
                            props.productCatalogue,
                            true,
                          );
                        }}
                        onChange={(event, value) => {
                          if (value) {
                            props.productCatalogue.inventoryPrice = value;
                          } else {
                            props.productCatalogue.inventoryPrice = null;
                          }
                          props.handleProductCatalogueChange(
                            props.productCatalogue,
                            false,
                          );
                        }}
                        disabled={props.disabled}
                      />
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={2} styles={stackItemStylesColThree}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        label={t('cpcMinorderquantity')}
                        value={
                          props.productCatalogue.minOrderQuantity
                            ? props.productCatalogue.minOrderQuantity
                            : ''
                        }
                        onBlur={() => {
                          props.handleProductCatalogueChange(
                            props.productCatalogue,
                            true,
                          );
                        }}
                        onChange={(event, value) => {
                          if (value) {
                            props.productCatalogue.minOrderQuantity = value;
                          } else {
                            props.productCatalogue.minOrderQuantity = null;
                          }
                          props.handleProductCatalogueChange(
                            props.productCatalogue,
                            false,
                          );
                        }}
                        disabled={props.disabled}
                      />
                    </div>
                  </Stack.Item>
                  <Stack.Item grow align="end" styles={stackItemStylesColThree}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        label={t('cPCMaxorderquantity')}
                        value={
                          props.productCatalogue.maxOrderQuantity
                            ? props.productCatalogue.maxOrderQuantity
                            : ''
                        }
                        onBlur={() => {
                          props.handleProductCatalogueChange(
                            props.productCatalogue,
                            true,
                          );
                        }}
                        onChange={(event, value) => {
                          if (value) {
                            props.productCatalogue.maxOrderQuantity = value;
                          } else {
                            props.productCatalogue.maxOrderQuantity = null;
                          }
                          props.handleProductCatalogueChange(
                            props.productCatalogue,
                            false,
                          );
                        }}
                        disabled={props.disabled}
                      />
                    </div>
                  </Stack.Item>
                </Stack>
              </div>

              <div className="marginTop">
                <div className="row">
                  <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                    <Label> {t('picturesoftheInstallation')} </Label>
                  </div>
                  <div
                    className={`ms-Grid-col ms-sm6 ms-md6 ms-lg6 ${classNames.labelRightWrapper}`}
                  >
                    <ActionButton
                      className={classNames.actionButton}
                      iconProps={uploadIcon}
                      allowDisabledFocus
                      onClick={() => {
                        showFileDialog();
                      }}
                      disabled={props.disabled}
                    >
                      <Label className={classNames.actionButton}>
                        {t('upload')}{' '}
                      </Label>
                    </ActionButton>
                  </div>
                </div>
                <Stack horizontal styles={stackStyles}>
                  <div className="input-file">
                    <input
                      style={{ display: 'none' }}
                      ref={inputFileRef}
                      type="file"
                      accept="image/*"
                      multiple={false}
                      onChange={(e) => uploadFiles(e.target.files)}
                    />
                  </div>
                </Stack>
                <div>
                  <ImageGrid
                    uploadedImages={props.productCatalogue.cpcImage}
                    handleImageDelete={(id: string) => {
                      props.handleImageDelete(id);
                    }}
                  />
                </div>
              </div>

              <div className="marginTop">
                <Label style={{paddingLeft: 9}}>
                  1.3 {t('geodynamics')}
                </Label>
                <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                  <Stack.Item grow={3} styles={stackItemStylesColThree}>
                    <div className={`${classNames.fullWidth}`}>
                      <ClearableDropdown
                        placeholder={t('trackingNumber')}
                        label={t('trackingNumber')}
                        options={props.vehicleTrackingNumberList}
                        // errorMessage={
                        //   props.validationMessages.basicUnitOfMeasureErrorMsg
                        //     ? props.validationMessages.basicUnitOfMeasureErrorMsg
                        //     : ''
                        // }
                        selectedKey={props.productCatalogue.resourceId || ''}
                        onChange={(event, value) => {
                          if (value) {
                            props.productCatalogue.resourceId = value.key.toString();
                            props.productCatalogue.trackingNo = value.text.toString();
                          } else {
                            props.productCatalogue.resourceId = null;
                            props.productCatalogue.trackingNo = null;
                          }
                          props.handleProductCatalogueChange(
                            props.productCatalogue,
                            true,
                          );
                        }}
                        required={false}
                        disabled={props.disabled}
                      />
                    </div>
                  </Stack.Item>
                </Stack>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="proj-detail-block" id="2">
        <div className="document-pane-card">
          <div className={'card-body'}>

            <div className="proj-detail-content inner marginBottom">
              <div className="marginTop">
                <VendorGrid
                  vendors={props.productCatalogue.cpcVendor}
                  editVendor={(id: string) => props.editVendor(id)}
                  openVendorModel={() => {
                    props.openVendorModel();
                  }}
                  vendorSelection={props.vendorSelection}
                  vendorSelectionDetails={props.vendorSelectionDetails}
                  vendorActionButtonState={props.vendorActionButtonState}
                  handleVendorDelete={() => {
                    props.handleVendorDelete();
                  }}
                  disabled={props.disabled}
                />
              </div>
            </div>

            {/* <div className="marginTop" style={{marginBottom:30}}>
                      <PrimaryButton
                        text={t('save')}
                        allowDisabledFocus
                        style={{ marginTop: 20, marginBottom: 20 }}
                        onClick={() => {
                          props.saveProductCatalogue();
                        }}
                      />

                    </div> */}

          </div>
        </div>
      </div>
      <div className="proj-detail-block" id="3">
        {props.isEdit &&
        props.productCatalogue.cpcHistoryLog &&
        props.productCatalogue.cpcHistoryLog.createdByUser && (
          <div className="document-pane-card">
            <div className="proj-detail-content inner">
              <div className={'card-body'}>
                <div className="marginTop" style={{ marginBottom: 30 }}>

                  <Link href="#" id="history">
                    <Label>3. {t('history')} </Label>
                  </Link>


                  <HistoryBar
                    createdByUser={
                      props.productCatalogue.cpcHistoryLog.createdByUser
                        ? props.productCatalogue.cpcHistoryLog.createdByUser
                        : ''
                    }
                    updatedByUser={
                      props.productCatalogue.cpcHistoryLog.updatedByUser
                        ? props.productCatalogue.cpcHistoryLog.updatedByUser
                        : ''
                    }
                    createdDateTime={
                      props.productCatalogue.cpcHistoryLog.createdDateTime
                        ? props.productCatalogue.cpcHistoryLog.createdDateTime
                        : ''
                    }
                    updatedDateTime={
                      props.productCatalogue.cpcHistoryLog.updatedDateTime
                        ? props.productCatalogue.cpcHistoryLog.updatedDateTime
                        : ''
                    }
                  />


                </div>
              </div>


            </div>

          </div>
        )}
      </div>
    </div>
  );
};

export const NickNameModel = (props: {
  isOpen: boolean;
  cpcNickNameIsEdit: boolean;
  openNickNameModel: () => void;
  languageCodes: [];
  handleNickNameChange: (nickName: NickName) => void;
  handleNickNameValidation: (
    nickNameValidationMessage: NickNameValidationMessage,
  ) => void;
  nickName: NickName;
  saveProductCatalogueNickName: (type: number) => void;
  promiseOptions: any;
  nickNameValidationMessages: NickNameValidationMessage;
  resetSelection: () => void;
  disabled?: boolean
}) => {
  const { t } = useTranslation();
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
  const titleId = useId('title');
  const theme = getTheme();
  const dragOptions: IDragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
  };
  const cancelIcon: IIconProps = { iconName: 'Cancel' };
  const addWhiteIconButtonStyles = {
    root: {
      color: uPrinceTheme.palette.white,
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };
  const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };
  const addIconWhite: IIconProps = {
    iconName: 'Add',
    styles: addWhiteIconButtonStyles,
  };
  const saveIcon: IIconProps = { iconName: 'Edit', styles: iconButtonStyles };
  const saveIconWhite: IIconProps = {
    iconName: 'Save',
    styles: addWhiteIconButtonStyles,
  };
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    actionButtonLabel: {
      color: uPrinceTheme.palette.themePrimary,
    },
    header: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '2px 2px 2px 10px',
        backgroundColor: uPrinceTheme.palette.themePrimary,
        fontSize: 18,
        color: 'white',
      },
    ],
    footer: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '0px 24px 14px 24px',
        textAlign: 'end',
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 0px 24px',
      overflowY: 'hidden',
      paddingTop: 20,
      //minWidth: "30vw",
      minHeight: screen.width > 1280 ? '20vh' : '30vh',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
    subHeader: {
      flex: '1 1 auto',
      display: 'none',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      fontSize: 14,
      color: uPrinceTheme.palette.themePrimary,
      backgroundColor: '#FFF7F4',
      height: 40,
      marginLeft: 'auto',
      marginRight: '2px',
    },
  });
  const cancelIconButtonStyles = {
    root: {
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
      color: 'white',
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };
  const onRenderCaretDownResourceLanguage = (): JSX.Element => {
    return (
      <Icon
        style={{ fontSize: props.nickName.localeCode ? 9 : 14 }}
        iconName={props.nickName.localeCode ? 'Clear' : 'ChevronDown'}
        onClick={() => {
          props.nickName.localeCode = null;
          props.handleNickNameChange(props.nickName);
        }}
      />
    );
  };
  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          let nickNameValidationMessages = props.nickNameValidationMessages;
          nickNameValidationMessages.languageErrorMsg = '';
          nickNameValidationMessages.nickNameErrorMsg = '';
          props.handleNickNameValidation(
            nickNameValidationMessages,
          );
          props.openNickNameModel();
          //props.resetSelection();
        }}
        isBlocking={true}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>  {props.cpcNickNameIsEdit ? t('edit') : t('add')} {t('nickNames')}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              let nickNameValidationMessages = props.nickNameValidationMessages;
              nickNameValidationMessages.languageErrorMsg = '';
              nickNameValidationMessages.nickNameErrorMsg = '';
              props.handleNickNameValidation(
                nickNameValidationMessages,
              );
              props.openNickNameModel();
              // props.resetSelection();
            }}
          />
        </div>
        <div className={contentStyles.subHeader}>
          <ActionButton
            onClick={() => {
              props.cpcNickNameIsEdit
                ? props.saveProductCatalogueNickName(1)
                : props.saveProductCatalogueNickName(1);
              // props.openNickNameModel();
            }}
            iconProps={props.cpcNickNameIsEdit ? saveIcon : addIcon}
            allowDisabledFocus
            disabled={props.disabled}
          >
            <Label className={contentStyles.actionButtonLabel}>
              {' '}
              {props.cpcNickNameIsEdit ? t('edit') : t('add')}
            </Label>
          </ActionButton>
        </div>
        <div className={contentStyles.body}>
          <div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                {/*<Dropdown*/}
                {/*placeholder={t('language')}*/}
                {/*label={t('language')}*/}
                {/*options={props.languageCodes}*/}
                {/*onRenderCaretDown={onRenderCaretDownResourceLanguage}*/}
                {/*selectedKey={*/}
                {/*props.nickName.localeCode ? props.nickName.localeCode : ''*/}
                {/*}*/}
                {/*onChange={(event, value) => {*/}
                {/*if (value) {*/}
                {/*props.nickName.localeCode = value.key.toString();*/}
                {/*props.nickName.language = value.text;*/}
                {/*} else {*/}
                {/*props.nickName.localeCode = null;*/}
                {/*props.nickName.language = null;*/}
                {/*}*/}
                {/*props.handleNickNameChange(props.nickName);*/}
                {/*}}*/}
                {/*/>*/}
                <Label required={true}>{t('language')}</Label>
                <CustomDropdown
                  reference={1}
                  required={true}
                  selectedOption={(props.nickName.localeCode) ? {
                    value: props.nickName.localeCode,
                    label: props.nickName.language,
                  } : null}
                  //onChange={()=> props.handleVendorChange(props.vendor)}
                  onChange={(key: string, text: string) => {
                    if (key) {
                      props.nickName.localeCode = key;
                      props.nickNameValidationMessages.languageErrorMsg = '';
                    } else {
                      props.nickName.localeCode = null;
                    }
                    if (text) {
                      props.nickName.language = text;
                    } else {
                      props.nickName.language = null;
                    }
                    props.handleNickNameValidation(
                      props.nickNameValidationMessages,
                    );
                    props.handleNickNameChange(props.nickName);
                  }}
                  defaultInputValue={props.nickName.language}
                  promiseOptions={props.promiseOptions}
                  validationMessage={
                    props.nickNameValidationMessages.languageErrorMsg
                  }
                  disabled={props.disabled}
                />
              </div>
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                <TextField
                  label={t('name')}
                  required={true}
                  errorMessage={
                    props.nickNameValidationMessages.nickNameErrorMsg
                      ? props.nickNameValidationMessages.nickNameErrorMsg
                      : ''
                  }
                  value={props.nickName.nickName ? props.nickName.nickName : ''}
                  onChange={(event, value) => {
                    if (value) {
                      props.nickName.nickName = value;
                      props.nickNameValidationMessages.nickNameErrorMsg = '';
                    } else {
                      props.nickName.nickName = null;
                    }
                    props.handleNickNameValidation(
                      props.nickNameValidationMessages,
                    );
                    props.handleNickNameChange(props.nickName);
                  }}
                  disabled={props.disabled}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={props.cpcNickNameIsEdit ? saveIconWhite : addIconWhite}
            text={props.cpcNickNameIsEdit ? t('save') : t('save')}
            style={{ marginTop: 20, marginBottom: 10 }}
            onClick={() => {
              props.cpcNickNameIsEdit
                ? props.saveProductCatalogueNickName(1)
                : props.saveProductCatalogueNickName(1);
              // props.openNickNameModel();
            }}
            disabled={props.disabled}
          />
          {!props.cpcNickNameIsEdit && <PrimaryButton
            iconProps={props.cpcNickNameIsEdit ? saveIconWhite : addIconWhite}
            text={t('saveandNew')}
            style={{ marginTop: 20, marginBottom: 10, marginLeft: 10 }}
            onClick={() => {
              props.cpcNickNameIsEdit
                ? props.saveProductCatalogueNickName(2)
                : props.saveProductCatalogueNickName(2);
              // props.openNickNameModel();
            }}
            disabled={props.disabled}
          />}
        </div>
      </Modal>
    </div>
  );
};

const NickNameGrid = (props: {
  nickNames: NickName[] | null;
  editNickName: (id: string) => void;
  openNickNameModel: () => void;
  handleNickNameDelete: () => void;
  nickNameSelection: any;
  nickNameSelectionDetails: {};
  nickNameActionButtonState: ActionButtonState;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const removeIcon: IIconProps = {
    iconName: 'Delete',
    styles: iconButtonStyles,
  };
  const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };
  const editIcon: IIconProps = { iconName: 'Edit', styles: iconButtonStyles };
  const classNames = mergeStyleSets({
    wrapper: {
      //height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
    },
    pane: {
      maxWidth: 400,
    },
    textContent: {
      padding: '15px 10px',
    },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: {
        '@media(max-width: 1100px)': {
          textAlign: 'start',
        },
      },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
  });
  const columns = [
    {
      key: 'column1',
      name: t('language'),
      fieldName: 'language',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('name'),
      fieldName: 'nickName',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    // { key: 'column3', name: '', fieldName: 'id', minWidth: 100, maxWidth: 200, isResizable: true,
    //   // onRender: (item: NickNameList) => {
    //   //   return <span><Icon className={classNames.actionIcon} iconName="Edit" onClick={() => {
    //   //     props.editNickName(item.id);
    //   //   }}
    //   //   /><Icon iconName="Delete"/></span>
    //   // },
    // },
  ];
  // const scrollablePaneStyles: Partial<IScrollablePaneStyles> = {
  //   root: classNames.pane,
  // };

  return (
    <div style={{ padding: 12 }}>
      <div className={'card-header'}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={stackStyles}
          tokens={stackTokens}
        >
          <Stack.Item styles={stackItemStyles}>
            {/*<Link style={{borderBottom:'none'}} href="#" id={props.link} >*/}
            {/*<Label>{props.id}. {props.title} </Label>*/}
            {/*</Link>*/}
            <Label>{t('resourceNicknames')}</Label>
          </Stack.Item>
          <Stack.Item styles={stackEditbarItemStyles}>
            <ActionButton
              className={classNames.actionButton}
              disabled={props.disabled ? true : props.nickNameActionButtonState.add}
              iconProps={addIcon}
              allowDisabledFocus
              onClick={() => {
                props.openNickNameModel();
              }}
            >
              <Label className={classNames.actionButton}>{t('add')} </Label>
            </ActionButton>
            <ActionButton
              iconProps={editIcon}
              disabled={props.disabled ? true : props.nickNameActionButtonState.edit}
              allowDisabledFocus
              onClick={() => {
                props.openNickNameModel();
              }}
            >
              <Label className={classNames.actionButton}> {t('edit')}</Label>
            </ActionButton>
            <ActionButton
              iconProps={removeIcon}
              disabled={props.disabled ? true : props.nickNameActionButtonState.remove}
              allowDisabledFocus
              onClick={() => {
                props.handleNickNameDelete();
              }}
            >
              <Label className={classNames.actionButton}>
                {' '}
                {t('remove')}
              </Label>
            </ActionButton>
          </Stack.Item>
        </Stack>
      </div>
      {/*
      <div className="ms-Grid-row marginTop">
        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
          <Label>{t('resourceNicknames')}</Label>
        </div>
        <div
          className={`ms-Grid-col ms-sm8 ms-md8 ms-lg8 ${classNames.labelRightWrapper}`}
        >
          <ActionButton
            className={classNames.actionButton}
            disabled={props.disabled?true:props.nickNameActionButtonState.add}
            iconProps={addIcon}
            allowDisabledFocus
            onClick={() => {
              props.openNickNameModel();
            }}

          >
            <Label className={classNames.actionButton}>{t('add')} </Label>
          </ActionButton>
          <ActionButton
            iconProps={editIcon}
            disabled={props.disabled?true:props.nickNameActionButtonState.edit}
            allowDisabledFocus
            onClick={() => {
              props.openNickNameModel();
            }}
          >
            <Label className={classNames.actionButton}> {t('edit')}</Label>
          </ActionButton>
          <ActionButton
            iconProps={removeIcon}
            disabled={props.disabled?true:props.nickNameActionButtonState.remove}
            allowDisabledFocus
            onClick={() => {
              props.handleNickNameDelete();
            }}
          >
            <Label className={classNames.actionButton}> {t('remove')}</Label>
          </ActionButton>
        </div>
      </div> */}

      <div className="ms-Grid-row marginTop">
        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
          <div className={classNames.wrapper}>
            {/*<ScrollablePane>*/}
            <div className={'scrollablePane'}>
              <DetailsList
                items={props.nickNames ? props.nickNames : []}
                columns={columns}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                selectionPreservedOnEmptyClick={true}
                checkboxVisibility={1}
                selection={props.nickNameSelection}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
                onRenderDetailsHeader={(headerProps, defaultRender) => {
                  if (defaultRender !== undefined) {
                    return (
                      <Sticky
                        stickyPosition={StickyPositionType.Header}
                        isScrollSynced={true}
                        stickyBackgroundColor="transparent"
                      >
                        <div>{defaultRender(headerProps)}</div>
                      </Sticky>
                    );
                  } else {
                    return <span></span>;
                  }
                }}
              />
            </div>
            {/*</ScrollablePane>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

const VendorGrid = (props: {
  vendors: Vendor[] | null;
  editVendor: (id: string) => void;
  openVendorModel: () => void;
  handleVendorDelete: () => void;
  vendorSelection: any;
  vendorSelectionDetails: {};
  vendorActionButtonState: ActionButtonState;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const removeIcon: IIconProps = {
    iconName: 'Delete',
    styles: iconButtonStyles,
  };
  const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };
  const editIcon: IIconProps = { iconName: 'Edit', styles: iconButtonStyles };
  const classNames = mergeStyleSets({
    wrapper: {
      //height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
    },
    pane: {
      maxWidth: 400,
    },
    textContent: {
      padding: '15px 10px',
    },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: {
        '@media(max-width: 1100px)': {
          textAlign: 'start',
        },
      },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
  });
  const columns = [
    {
      key: 'column1',
      name: t('companyName'),
      fieldName: 'companyName',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: Vendor) => {
        return (
          <span>
            <Label>{(item.company) ? item.company.name : ''}</Label>
          </span>
        );
      },
    },
    {
      key: 'column2',
      name: t('resourceNumber'),
      fieldName: 'resourceNumber',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('preferredParty'),
      fieldName: 'preferredParty',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: Vendor) => {
        return (
          <span style={{ textAlign: 'center' }}>
            <Checkbox
              onChange={() => {
                // alert('CheckBox Clicked');
              }}
              styles={{ label: { cursor: 'default' } }}
              checked={item.preferredParty}
              disabled={props.disabled ? true : !item.preferredParty}
            />
          </span>
        );
      },
    },
    {
      key: 'column4',
      name: t('resourceTitle'),
      fieldName: 'resourceTitle',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  return (
    <div>
      <div className={'card-header'}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={stackStyles}
          tokens={stackTokens}
        >
          <Stack.Item styles={stackItemStyles}>
            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
              <Link style={{ borderBottom: 'none' }} href="#" id="vendor">
                <Label>2. {t('vendor')} </Label>
              </Link>
            </div>
          </Stack.Item>
          <Stack.Item styles={stackEditbarItemStyles}>
            <ActionButton
              className={classNames.actionButton}
              disabled={props.disabled ? true : props.vendorActionButtonState.add}
              iconProps={addIcon}
              allowDisabledFocus
              onClick={() => {
                props.openVendorModel();
              }}
            >
              <Label className={classNames.actionButton}>{t('add')} </Label>
            </ActionButton>
            <ActionButton
              iconProps={editIcon}
              disabled={props.disabled ? true : props.vendorActionButtonState.edit}
              allowDisabledFocus
              onClick={() => {
                props.openVendorModel();
              }}
            >
              <Label className={classNames.actionButton}> {t('edit')}</Label>
            </ActionButton>
            <ActionButton
              iconProps={removeIcon}
              disabled={props.disabled ? true : props.vendorActionButtonState.remove}
              allowDisabledFocus
              onClick={() => {
                props.handleVendorDelete();
              }}
            >
              <Label className={classNames.actionButton}>
                {' '}
                {t('remove')}
              </Label>
            </ActionButton>
          </Stack.Item>
        </Stack>
      </div>

      {/* <div
          className={`ms-Grid-col ms-sm8 ms-md8 ms-lg8 ${classNames.labelRightWrapper}`}
        >
          <ActionButton
            className={classNames.actionButton}
            disabled={props.disabled?true:props.vendorActionButtonState.add}
            iconProps={addIcon}
            allowDisabledFocus
            onClick={() => {
              props.openVendorModel();
            }}
          >
            <Label className={classNames.actionButton}>{t('add')} </Label>
          </ActionButton>
          <ActionButton
            iconProps={editIcon}
            disabled={props.disabled?true:props.vendorActionButtonState.edit}
            allowDisabledFocus
            onClick={() => {
              props.openVendorModel();
            }}
          >
            <Label className={classNames.actionButton}> {t('edit')}</Label>
          </ActionButton>
          <ActionButton
            iconProps={removeIcon}
            disabled={props.disabled?true:props.vendorActionButtonState.remove}
            allowDisabledFocus
            onClick={() => {
              props.handleVendorDelete();
            }}
          >
            <Label className={classNames.actionButton}> {t('remove')}</Label>
          </ActionButton>
        </div> */}

      <div className="ms-Grid-row marginTop">
        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
          <div className={classNames.wrapper}>
            {/*<ScrollablePane>*/}
            <div className={'scrollablePane'}>
              <DetailsList
                items={props.vendors ? props.vendors : []}
                columns={columns}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                selectionPreservedOnEmptyClick={true}
                checkboxVisibility={1}
                selection={props.vendorSelection}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
                onRenderDetailsHeader={(headerProps, defaultRender) => {
                  if (defaultRender !== undefined) {
                    return (
                      <Sticky
                        stickyPosition={StickyPositionType.Header}
                        isScrollSynced={true}
                        stickyBackgroundColor="transparent"
                      >
                        <div>{defaultRender(headerProps)}</div>
                      </Sticky>
                    );
                  } else {
                    return <span> </span>;
                  }
                }}
              />
            </div>
            {/*</ScrollablePane>*/}
          </div>
        </div>
      </div>
    </div>

  );
};

export const VendorModel = (props: {
  cpcVendorIsEdit: boolean;
  isOpen: boolean;
  openVendorModel: () => void;
  handleVendorChange: (vendor: Vendor) => void;
  vendor: Vendor;
  saveProductCatalogueVendor: (type: number) => void;
  organizations: [];
  handelGetOrganizationByName: (name: string) => void;
  promiseOptions: any;
  resetSelection: () => void;
  disabled?: boolean;

}) => {
  const { t } = useTranslation();
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);

  let validationMessages = {
    companyNameErrorMsg: '',
    purchasingUnitErrorMsg: '',
    resourceNumberErrorMsg: '',
    resourcePriceErrorMsg: '',
    resourceTitleErrorMsg: '',
  };
  const [vendorValidationMessages, setVendorValidationMsg] = useState<VendorValidationMessage>(validationMessages);
  const titleId = useId('title');
  const theme = getTheme();
  const dragOptions: IDragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
  };
  const cancelIcon: IIconProps = { iconName: 'Cancel' };
  const addWhiteIconButtonStyles = {
    root: {
      color: uPrinceTheme.palette.white,
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };
  const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };
  const addIconWhite: IIconProps = {
    iconName: 'Add',
    styles: addWhiteIconButtonStyles,
  };
  const classNames = mergeStyleSets({
    fullWidth: {
      width: '100%',
    },
    halfWidth: {
      width: '49.5%',
    },
  });
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    actionButtonLabel: {
      color: uPrinceTheme.palette.themePrimary,
    },
    header: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '2px 2px 2px 10px',
        backgroundColor: uPrinceTheme.palette.themePrimary,
        fontSize: 18,
        color: 'white',
      },
    ],
    footer: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '0px 24px 14px 24px',
        textAlign: 'end',
        selectors: {
          '@media(min-width: 1100px)': {
            padding: '0px 24px 30px 24px',
          },
        },
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 0px 24px',
      overflowY: 'hidden',
      minWidth: '40vw',
      minHeight: '20vh',
      paddingTop: 20,
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
    subHeader: {
      flex: '1 1 auto',
      display: 'none',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      fontSize: 14,
      color: uPrinceTheme.palette.themePrimary,
      backgroundColor: '#FFF7F4',
      height: 40,
      marginLeft: 'auto',
      marginRight: '2px',
    },
  });
  const cancelIconButtonStyles = {
    root: {
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
      color: 'white',
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };
  const saveIcon: IIconProps = { iconName: 'Edit', styles: iconButtonStyles };
  const saveIconWhite: IIconProps = {
    iconName: 'Save',
    styles: addWhiteIconButtonStyles,
  };
  // Optional extra props to pass through to the input element
  const inputProps: ICheckboxProps['inputProps'] = {
    onFocus: () => {
    },
    onBlur: () => {
    },
  };
  const validateVendor = (): boolean => {
    let isValid = true;
    if (_.isNil(props.vendor.companyId) || _.isEmpty(props.vendor.companyId)) {
      isValid = false;
      setVendorValidationMsgState(
        'companyNameErrorMsg',
        t('companyNameRequired'),
      );
    } else {
      setVendorValidationMsgState('companyNameErrorMsg', '');
    }
    if (_.isNil(props.vendor.resourceNumber) || _.isEmpty(props.vendor.resourceNumber)) {
      isValid = false;
      setVendorValidationMsgState(
        'resourceNumberErrorMsg',
        t('Resource Number Required'),
      );
    } else {
      setVendorValidationMsgState('resourceNumberMsg', '');
    }
    if (_.isNil(props.vendor.purchasingUnit) || _.isEmpty(props.vendor.purchasingUnit)) {
      isValid = false;
      setVendorValidationMsgState(
        'purchasingUnitErrorMsg',
        t('Purchasing Unit Required'),
      );
    } else {
      setVendorValidationMsgState('purchasingUnitErrorMsg', '');
    }
    if (_.isNil(props.vendor.resourceTitle) || _.isEmpty(props.vendor.resourceTitle)) {
      isValid = false;
      setVendorValidationMsgState(
        'resourceTitleErrorMsg',
        t('Resource Title Required'),
      );
    } else {
      setVendorValidationMsgState('resourceTitleErrorMsg', '');
    }
    if (_.isNil(props.vendor.resourcePrice) || !props.vendor.resourcePrice) {
      isValid = false;
      setVendorValidationMsgState(
        'resourcePriceErrorMsg',
        t('Resource Price Required'),
      );
    } else {
      setVendorValidationMsgState('resourcePriceErrorMsg', '');
    }
    return isValid;
  };

  const setVendorValidationMsgState = (key: string, msg: string) => {
    setVendorValidationMsg((prevState) => ({
      ...prevState,
      [key]: msg,
    }));
  };
  const handleVendorChange = (field: string) => {
    setVendorValidationMsgState(field + 'ErrorMsg', '');
    props.handleVendorChange(props.vendor);
  };

  const resetValidation = () => {

    let resetValidationMessages = {
      companyNameErrorMsg: '',
      purchasingUnitErrorMsg: '',
      resourceNumberErrorMsg: '',
      resourcePriceErrorMsg: '',
      resourceTitleErrorMsg: '',
    };
    setVendorValidationMsg(resetValidationMessages);
  };
  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          props.openVendorModel();
          //props.resetSelection();
          resetValidation();
        }}
        scrollableContentClassName="body"
        isBlocking={true}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}> {props.cpcVendorIsEdit ? t('edit') : t('add')} {t('vendor')}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              props.openVendorModel();
              // props.resetSelection();
              resetValidation();
            }}
          />
        </div>
        <div className={contentStyles.subHeader}>
          <ActionButton
            iconProps={props.cpcVendorIsEdit ? saveIcon : addIcon}
            allowDisabledFocus
            onClick={() => {
              if (validateVendor()) {
                props.cpcVendorIsEdit
                  ? props.saveProductCatalogueVendor(1)
                  : props.saveProductCatalogueVendor(1);
              }

            }}
            disabled={props.disabled}
          >
            <Label className={contentStyles.actionButtonLabel}>
              {' '}
              {props.cpcVendorIsEdit ? t('edit') : t('add')}
            </Label>
          </ActionButton>
        </div>
        <div className={contentStyles.body}>
          <div style={{ marginBottom: 16 }}>
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStylesColThree}>
                <div className={`${classNames.fullWidth}`}>
                  {/*<TextField*/}
                  {/*label={t('_3rdpartyCompanyname')}*/}
                  {/*value={*/}
                  {/*props.vendor.companyName ? props.vendor.companyName : ''*/}
                  {/*}*/}
                  {/*onChange={(event, value) => {*/}
                  {/*if (value) {*/}
                  {/*props.vendor.companyName = value;*/}
                  {/*} else {*/}
                  {/*props.vendor.companyName = null;*/}
                  {/*}*/}
                  {/*props.handleVendorChange(props.vendor);*/}
                  {/*}}*/}
                  {/*/>*/}
                  <Label required={true}>{t('_3rdpartyCompanyname')}</Label>
                  <CustomDropdown
                    reference={1}
                    //onChange={()=> props.handleVendorChange(props.vendor)}
                    onChange={(key: string, text: string) => {
                      if (text) {
                        props.vendor.companyName = text;
                        props.vendor.company.name = text;

                      } else {
                        props.vendor.companyName = null;
                        props.vendor.company.name = null;
                      }
                      if (text) {
                        props.vendor.companyId = key;
                      } else {
                        props.vendor.companyId = null;
                      }
                      handleVendorChange('companyName');
                    }}
                    selectedOption={(props.vendor.companyId) ? {
                      value: props.vendor.companyId,
                      label: props.vendor.company.name,
                    } : null}
                    promiseOptions={props.promiseOptions}
                    validationMessage={vendorValidationMessages.companyNameErrorMsg}
                    required={true}
                    disabled={props.disabled}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStylesCheckBox}>
                <div
                  className={`${classNames.fullWidth}`}
                  style={{ paddingTop: 35 }}
                >
                  <Checkbox
                    label={t('_3rdpartyPreferredparty')}
                    inputProps={inputProps}
                    checked={props.vendor.preferredParty}
                    onChange={(event, value) => {
                      if (value) {
                        props.vendor.preferredParty = value;
                      } else {
                        props.vendor.preferredParty = false;
                      }
                      props.handleVendorChange(props.vendor);
                    }}
                    disabled={props.disabled}
                  />
                </div>
              </Stack.Item>
            </Stack>

            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    label={t('vendorReference')}
                    value={
                      props.vendor.resourceNumber
                        ? props.vendor.resourceNumber
                        : ''
                    }
                    required={true}
                    errorMessage={(vendorValidationMessages.resourceNumberErrorMsg) ? vendorValidationMessages.resourceNumberErrorMsg : ''}
                    onChange={(event, value) => {
                      if (value) {
                        props.vendor.resourceNumber = value;
                      } else {
                        props.vendor.resourceNumber = null;
                      }
                      handleVendorChange('resourceNumber');
                    }}
                    disabled={props.disabled}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    label={t('_3rdpartyResourcetitle')}
                    value={
                      props.vendor.resourceTitle
                        ? props.vendor.resourceTitle
                        : ''
                    }
                    required={true}
                    errorMessage={(vendorValidationMessages.resourceTitleErrorMsg) ? vendorValidationMessages.resourceTitleErrorMsg : ''}

                    onChange={(event, value) => {
                      if (value) {
                        props.vendor.resourceTitle = value;
                      } else {
                        props.vendor.resourceTitle = null;
                      }
                      handleVendorChange('resourceTitle');
                    }}
                    disabled={props.disabled}
                  />
                </div>
              </Stack.Item>
            </Stack>

            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>

              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    label={t('_3rdpartyResourceprice')}
                    type={'number'}
                    min={0}
                    value={
                      props.vendor.resourcePrice
                        ? props.vendor.resourcePrice
                        : ''
                    }
                    required={true}
                    errorMessage={(vendorValidationMessages.resourcePriceErrorMsg) ? vendorValidationMessages.resourcePriceErrorMsg : ''}

                    onChange={(event, value) => {
                      if (value) {
                        props.vendor.resourcePrice = value;
                      } else {
                        props.vendor.resourcePrice = null;
                      }
                      handleVendorChange('resourcePrice');
                    }}
                    disabled={props.disabled}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    label={t('_3rdpartyPurchaseunit')}
                    value={
                      props.vendor.purchasingUnit
                        ? props.vendor.purchasingUnit
                        : ''
                    }
                    required={true}
                    errorMessage={(vendorValidationMessages.purchasingUnitErrorMsg) ? vendorValidationMessages.purchasingUnitErrorMsg : ''}

                    onChange={(event, value) => {
                      if (value) {
                        props.vendor.purchasingUnit = value;
                      } else {
                        props.vendor.purchasingUnit = null;
                      }
                      handleVendorChange('purchasingUnit');
                    }}
                    disabled={props.disabled}
                  />
                </div>
              </Stack.Item>
            </Stack>

            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={12} styles={stackItemStyles}>
                <div className={`${classNames.halfWidth}`}>
                  <TextField
                    label={t('_3rdpartyResourceleadtime')}
                    value={
                      props.vendor.resourceLeadTime
                        ? props.vendor.resourceLeadTime
                        : ''
                    }
                    onChange={(event, value) => {
                      if (value) {
                        props.vendor.resourceLeadTime = value;
                      } else {
                        props.vendor.resourceLeadTime = null;
                      }
                      props.handleVendorChange(props.vendor);
                    }}
                    disabled={props.disabled}
                  />
                </div>
              </Stack.Item>
            </Stack>

            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    label={t('_3rdpartyMinorderquantity')}
                    type={'number'}
                    min={0}
                    value={
                      props.vendor.minOrderQuantity
                        ? props.vendor.minOrderQuantity
                        : ''
                    }
                    onChange={(event, value) => {
                      if (value) {
                        props.vendor.minOrderQuantity = value;
                      } else {
                        props.vendor.minOrderQuantity = null;
                      }
                      props.handleVendorChange(props.vendor);
                    }}
                    disabled={props.disabled}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    label={t('_3rdpartyMaxorderquantity')}
                    type={'number'}
                    min={0}
                    value={
                      props.vendor.maxOrderQuantity
                        ? props.vendor.maxOrderQuantity
                        : ''
                    }
                    onChange={(event, value) => {
                      if (value) {
                        props.vendor.maxOrderQuantity = value;
                      } else {
                        props.vendor.maxOrderQuantity = null;
                      }
                      props.handleVendorChange(props.vendor);
                    }}
                    disabled={props.disabled}
                  />
                </div>
              </Stack.Item>
            </Stack>

            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={12} styles={stackItemStyles}>
                <div className={`${classNames.halfWidth}`}>
                  <TextField
                    label={t('_3rdPartyroundingValue')}
                    type={'number'}
                    min={0}
                    value={
                      props.vendor.roundingValue
                        ? props.vendor.roundingValue
                        : ''
                    }
                    onChange={(event, value) => {
                      if (value) {
                        props.vendor.roundingValue = value;
                      } else {
                        props.vendor.roundingValue = null;
                      }
                      props.handleVendorChange(props.vendor);
                    }}
                    disabled={props.disabled}
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={props.cpcVendorIsEdit ? saveIconWhite : addIconWhite}
            text={props.cpcVendorIsEdit ? t('save') : t('save')}
            style={{ marginTop: 20, marginBottom: 10 }}
            onClick={() => {
              if (validateVendor()) {
                props.cpcVendorIsEdit
                  ? props.saveProductCatalogueVendor(1)
                  : props.saveProductCatalogueVendor(1);
              }
            }}
            disabled={props.disabled}
          />

          {!props.cpcVendorIsEdit && <PrimaryButton
            iconProps={props.cpcVendorIsEdit ? saveIconWhite : addIconWhite}
            text={t('saveandNew')}
            style={{ marginTop: 20, marginBottom: 10, marginLeft: 10 }}
            onClick={() => {
              if (validateVendor()) {
                props.cpcVendorIsEdit
                  ? props.saveProductCatalogueVendor(2)
                  : props.saveProductCatalogueVendor(2);
              }
            }}
            disabled={props.disabled}
          />}
        </div>
      </Modal>
    </div>
  );
};

// const QrCodeBar = (props: { value: string; name: string | null }) => {
//   const { t } = useTranslation();
//   const classNames = mergeStyleSets({
//     fullWidth: {
//       width: '100%',
//     },
//     wrapper: {
//       //height: '100vh',
//       position: 'relative',
//       maxHeight: 'inherit',
//     },
//     pane: {
//       maxWidth: 400,
//     },
//     textContent: {
//       padding: '15px 10px',
//     },
//     actionIcon: {
//       padding: 5,
//       cursor: 'pointer',
//     },
//     labelRightWrapper: {
//       textAlign: 'end',
//       selectors: {
//         '@media(max-width: 1100px)': {
//           textAlign: 'start',
//         },
//       },
//     },
//     actionButton: {
//       color: uPrinceTheme.palette.themePrimary,
//       cursor: 'pointer',
//     },
//     labelRightIcon: {
//       display: 'inline-block',
//       verticalAlign: 'bottom',
//       paddingBottom: 2,
//       paddingRight: 5,
//     },
//   });
//   const downloadIcon: IIconProps = {
//     iconName: 'Download',
//     styles: iconButtonStyles,
//   };
//   const downloadQR = () => {
//     if (props.name) {
//       const codeName = props.name;
//       const canvas: any = document.getElementById(codeName);
//       if (canvas) {
//         const pngUrl = canvas
//           .toDataURL('image/png')
//           .replace('image/png', 'image/octet-stream');
//         let downloadLink = document.createElement('a');
//         downloadLink.href = pngUrl;
//         downloadLink.download = `${codeName}.png`;
//         document.body.appendChild(downloadLink);
//         downloadLink.click();
//         document.body.removeChild(downloadLink);
//       }
//     }
//   };

//   return (
//     <div>
//       {/* <div style={{ paddingLeft: 10,paddingTop:10 }} className="ms-Grid-row marginTop">
//         <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
//           <Label>{t('qRCode')}</Label>
//         </div>
//         <div
//           className={`ms-Grid-col ms-sm6 ms-md6 ms-lg6 ${classNames.labelRightWrapper}`}
//         >
//           <ActionButton
//             className={classNames.actionButton}
//             iconProps={downloadIcon}
//             allowDisabledFocus
//             onClick={() => {
//               downloadQR();
//             }}
//           >
//             <Label className={classNames.actionButton}>{t('download')} </Label>
//           </ActionButton>
//         </div>
//       </div> */}
//       {/* <div style={{ paddingLeft: 10 }}>
//         <QRCode
//           id={props.name ? props.name : ''}
//           value={`{id:${props.value}}`}
//           style={{ marginTop: 1, marginLeft: 5 }}
//           size={75}
//         />
//       </div> */}
//     </div>
//   );
// };

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CpcDocumentPaneComponent);

export class InvoiceListPaneFilter {
  invoiceStatusId: string | number | null = null;
  name: string | null = null;
  title: string | null = null;
  projectSequenceCode: string | null = null;
  totalExcludingTax: string | number | null = null;
  totalIncludingTax: string | number | null = null;
  date: string | Date | null = null;
  sorter: Sorter = new Sorter();
}

export interface InvoiceShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
  value: number | null
}

class Sorter {
  attribute: string | null = null;
  order: string | null = null;
}

export interface InvoiceShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
}

export class ListPaneItem {
  id: string | null = null;
  invoiceId: string | null = null;
  invoiceStatus: string | number | null = null;
  invoiceStatusId: string | number | null = null;
  projectSequenceCode: string | null = null;
  name: string | null = null;
  psId: string | null = null;
  purchaseOrderNumber: string | number | null = null;
  title: string | null = null;
  totalExcludingTax: string | null = null;
  totalIncludingTax: string | null = null;
  workPeriodFrom: string | Date | null = null;
  workPeriodTo: string | Date | null = null;
  date: string | Date | null = null;
}

export interface InvoiceDropDowns {
  status: DropDown[];
  type: DropDown[];
}

export interface DropDown {
  key: string;
  text: string;
}


export interface History {
  createdByUser: string | null;
  createdDateTime: string | null;
  revisionNumber: number | string | null;
  updatedByUser: string | null;
  updatedDateTime: string | null;
}


export interface Invoice {
  id: string | null;
  invoiceId: string | null;
  title: string | null;
  headerTitle: string | null;
  name: string | null;
  projectTypeId: string | null;
  projectType: string | null;
  invoiceStatusId: string | null;
  projectStatus: string | null;
  projectCostId: string | null;
  workPeriodFrom: string | Date | null;
  workPeriodTo: string | Date | null;
  projectCompletionDate: string | Date | null;
  purchaseOrderNumber: string | null;
  projectSequenceCode: string | null;
  totalIncludingTax: number | null;
  totalExcludingTax: number | null;
  psId: string | null;
  historyLog: History | null;
  isSaved: boolean;
  date: string | null;
  isProjectFinished: boolean;
}


export const invoiceFormData = {
  id: null,
  invoiceId: null,
  title: null,
  headerTitle: null,
  name: null,
  projectTypeId: null,
  projectType: null,
  invoiceStatusId: null,
  projectStatus: null,
  projectCostId: null,
  workPeriodFrom: null,
  workPeriodTo: null,
  projectCompletionDate: null,
  purchaseOrderNumber: null,
  projectSequenceCode: null,
  totalIncludingTax: null,
  totalExcludingTax: null,
  psId: null,
  historyLog: null,
  isSaved: false,
  date: null,
  isProjectFinished: false,
};

export const invoiceFilter = {
  id: null,
  invoiceId: null,
  invoiceStatus: null,
  invoiceStatusId: null,
  name: null,
  psId: null,
  purchaseOrderNumber: null,
  title: null,
  totalExcludingTax: null,
  totalIncludingTax: null,
  workPeriodFrom: null,
  workPeriodTo: null,
  sorter: {
    attribute: null,
    order: null,
  },
};

export interface InvoiceValidationMessage {
  nameErrorMsg: string | null;
  typeErrorMsg: string | null;
  borErrorMsg: string | null;
  statusErrorMsg: string | null;
}

export interface Cost {
  id: string | null;
  psId: string | null;
  cpcId?: any;
  cpcResourceNumber: string;
  cpcTitle: string;
  consumedQuantity: number;
  costToMou?: any;
  totalCost: any;
  soldQuantity: number;
  spToMou?: any;
  consumedQuantityMou?: any;
  mouId?: any;
  mou?: any;
  status?: any;
  cpcResourceTypeId: string;
  cpcResourceType: string;
  projectCostId: string;
  resourceCategory: string;
  productTitle: string;
}

import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';


const TimeClockCommandBarRight = ({ handleShiftApprove, handleShiftReject, selectedTimeClock, selectedTimeClockId }: any) => {

  const { t } = useTranslation();

  const getItems = () => {
    return [
      {
        key: 'approve',
        text: t('approve'),
        iconProps: { iconName: 'ActivateOrders' },
        disabled: (!selectedTimeClock.isShiftEnded || selectedTimeClock.statusId === 4),
        onClick: () => handleShiftApprove(selectedTimeClockId),
      },
      {
        key: 'reject',
        text: t('reject'),
        disabled: (!selectedTimeClock.isShiftEnded || selectedTimeClock.statusId === 4),
        iconProps: { iconName: 'DeactivateOrders' },
        onClick: () => handleShiftReject(selectedTimeClockId),
      },
    ];
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );

};

export default TimeClockCommandBarRight;



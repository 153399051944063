import * as React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import ProgressBar from '../../../shared/progressBar/progressBar';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { messageService } from '../../../services/messageService';
import DocumentPane from '../../wbsTask/documentPane/DocumentPane';

const CreateTaskDocumentPane = (props:{isAddNew:any}) => {
  const { t } = useTranslation();
  const showWbsProgressBar = useSelector((state: any) => state.wbsCreate.showWbsProgressBar);
  const getItems=()=>{
    return [ {
      key: 'save',
      text: t('save'),
      iconProps: { iconName: 'Save' },
      onClick: () => {
        messageService.sendMessage({ saveWbsTask: true});
      },
      disabled: false,
    }];
  }

 return (
  <>
    <div style={{height:"100%",width:"100%",overflow:"hidden"}}>
    <div className={'wbs__command__bar'}>
      <CommandBar
        styles={{ root: { height: 26 } }}
        items={getItems()}
        ariaLabel='Use left and right arrow keys to navigate between commands'
      />
      <ProgressBar show={showWbsProgressBar}/>
    </div>
      <DocumentPane isNewTask={props?.isAddNew}/>
  </div>
  </>
 );

};

export default CreateTaskDocumentPane

import React from "react";
import './validation-label-stylessheet.css';

interface Props {
    validationMessage: string;
}

function ValidationLabel(props: Props) {
    const {validationMessage} = props;

    return (
        <>
            <span id={'validation-label-id'}>{validationMessage}</span>
        </>
    );
}

export default ValidationLabel;

import React from 'react';
import { connect } from 'react-redux';
import DocumentPaneComponent from './component';
import { WithTranslation, withTranslation } from 'react-i18next';
import _ from 'lodash';
import history from '../../../history';
import { messageService } from '../../../services/messageService';
import {
  formatDateTimeToISOString,
  formatDateTimeToLocal,
  getContractingUnit,
  getProject,
  MSG_AUTO_CLOSE_TIME,
} from '../../../shared/util';
import { Selection } from '@fluentui/react/lib/DetailsList';

import {
  getAllProjectDropdowns,
  getProjectDefinition,
  getProjectHeaderDetails,
} from '../../../reducers/projectReducer';
import POResourceModal from './approve/component';
import BorModal from './bor/container';
import CuBorModal from './cuBor/container';
import ProductModal from './product/container';
import DeliveryPlanModal from './deliveryPlan/container';
import {
  CAPACITY_REQUEST,
  GOODDEERIS_PRODUCT_TYPE_ID,
  POAccessLevel,
  PRODUCT_TYPE_ID,
  PURCHASE_REQUEST,
  PurchaseOrder,
  RESOURCE_TYPE_ID,
  RETURN_REQUEST,
  RFQ_REQUEST,
} from '../../../types/purchaseOrder';
import {
  approvedPo,
  approvedPoCu,
  autoSaveFormData,
  convertCrTOPo,
  createPODeliveryLocation,
  cuSendPo,
  feedbackPoCu,
  getCuDeliveryPlanListPaneData,
  getDeliveryPlanListPaneData,
  getPoProjectList,
  projectSendPo,
  readBORResourcesByIds,
  readByPurchaseOrderId,
  readPbsResourceByIds,
  saveFormData,
  saveFormDataWithOutFetching,
  setBORModalState,
} from '../../../reducers/purchaseOrderReducer';
import { v4 as uuidv4 } from 'uuid';
import UprinceLogger from '../../../shared/Logger/logger';
import { BorResourcesItem } from '../../../types/billOfResources';
import POProductModal from './approve/productApprove';
import MapModal from '../../cuProjects/mapModal/component';
import { LocationAddress, Position } from '../../../types/projectDefinition';
import { createPMOLLocation, readLocationsByProjectId } from '../../../reducers/pmolReducer';
import { APPROVED_STATUS_ID, HANDED_OVER_STATUS_ID, IN_DEVELOPMENT_STATUS_ID } from '../../../types/projectMolecule';
import CustomAlert from '../../../shared/alert/customAlert';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import {
  readWarehouseDropDownData,
  readWarehouseTaxonomy,
  readWarehouseTaxonomyForProject,
} from '../../../reducers/warehouseReducer';
import { WarehouseDropDowns } from '../../../types/warehouse';
import { withRouter } from 'react-router';

export interface State {
  isDirty: boolean;
  redirect: boolean;
  loading: boolean;

  isEdit: boolean;
  isCostModalOpen: boolean;
  showPrint: boolean;
  isApproved: boolean;
  ledgerDropDowns: { key: string; text: string }[];
  generalLedger: string;
  resourceData: any;
  openPOResourceModal: boolean;
  isBorModalVisible: boolean;
  isCuBorModalVisible: boolean;
  getDeliveryPlanModal: boolean;
  autoSave: boolean;
  isDeliveryPlanModalVisible: boolean;
  uid: string | null;
  borResources: { materials: any[], tools: any[], consumables: any[], labours: any[] };
  selectedBorResource: any;
  selectedResourceType: string;
  isClear: boolean;
  externalProducts: any[];
  selectedProduct: boolean;
  openPOProductModal: boolean;
  isOpenMap: boolean;
  position: { lat: number, lon: number };
  location: any;
  locationId: string;
  totalAmount: number;
  unitPriceNotDefine: boolean;
  feedbackMsgShow: boolean;
  resourcesNotFound: boolean;
  deliveryPlanNotComplete: boolean;
  selectedBORIds: any;
  selectedProductIds: any;
  selectedProject: any;
  warehouseTaxonomy: any;
  hasChangeIntialFromData: boolean;
  borResourcesDif: boolean;
  poRequestType: string | null;
  cuSendPo: boolean;
  showError: boolean,
  errorMsg: string
}

interface Props extends WithTranslation {
  purchaseOrderDropDowns: any;
  saveFormData: (data: PurchaseOrder) => any;
  autoSaveFormData: (data: PurchaseOrder) => any;
  getAllProjectDropdowns: () => void;
  readBORResourcesByIds: (ids: string[]) => void;
  readPbsResourceByIds: (ids: string[]) => void;
  borResourcesCount: number;
  formData: any;
  borResources: { materials: any[], tools: any[], consumables: any[], labours: any[] };
  isExpand: boolean;
  submitRfqEdit: (data: any) => any;
  setBORModalState: (state: boolean) => void;
  approvedPo: any;
  approvedPoCu: any;
  projectSendPo: any;
  cuSendPo: any;
  feedbackPoCu: any;
  readByPurchaseOrderId: (id: string) => any;
  isNewPO: boolean;
  purchaseOrderID: string;
  projectHeader: [] | null;
  getProjectHeaderDetails: (id: string) => void;
  isLoadingPO: boolean;
  poProjectList: [];
  getPoProjectList: any;
  saveFormDataWithOutFetching: any;
  warehouseDropDowns: WarehouseDropDowns;
  warehouseTaxonomy: any;
  projectWarehouseTaxonomy: any;
  readWarehouseDropDownData: () => void;
  readWarehouseTaxonomy: (data: any) => void;
  readWarehouseTaxonomyForProject: (data: any, project: string) => void;
  getDeliveryPlanListPaneData: (id: string) => any;
  getCuDeliveryPlanListPaneData: (id: string) => any;
  poRequestType: string | null
  convertCrTOPo: (id: string) => any;
  match?: any
}

class DocumentPane extends React.Component<any, any> {
  subscription: any;
  submitExternal: any;
  form: any;
  private _materialsSelection: Selection;
  private _toolsSelection: Selection;
  private _labourSelection: Selection;
  private _consumableSelection: Selection;

  constructor(props: any) {
    super(props);
    this.state = {
      isDirty: false,
      isEdit: false,
      redirect: false,
      loading: false,
      formData: {},
      showPrint: false,
      isApproved: false,
      isBorModalVisible: false,
      isCuBorModalVisible: false,
      getDeliveryPlanModal: false,
      autoSave: false,
      isDeliveryPlanModalVisible: false,
      uid: null,
      selectedBorResource: null,
      borResources: { materials: [], tools: [], consumable: [], labour: [] },
      isClear: false,
      externalProducts: [],
      openPOProductModal: false,
      locationId: null,
      totalAmount: 0,
      unitPriceNotDefine: false,
      feedbackMsgShow: false,
      resourcesNotFound: false,
      deliveryPlanNotComplete: false,
      selectedProject: null,
      hasChangeIntialFromData: false,
      borResourcesDif: false,
      hasSubmitForm: false,
      cuSendPo: false,
      showError: false,
      errorMsg: '',
    };
    this._materialsSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_materialsSelection',
          this._getMaterialSelectionDetails(),
        );
        this.setState({
          materialSelectionDetails: this._getMaterialSelectionDetails(),
          material: this._materialEditDetails(),

        });
      },
    });

    this._toolsSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log('_toolsSelection', this._getToolsSelectionDetails());
        this.setState({
          toolsSelectionDetails: this._getToolsSelectionDetails(),
          tool: this._toolsEditDetails(),
        });
      },
    });

    this._labourSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_labourSelection',
          this._getLabourSelectionDetails(),
        );
        this.setState({
          labourSelectionDetails: this._getLabourSelectionDetails(),
          labour: this._labourEditDetails(),
        });
      },
    });

    this._consumableSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_consumableSelection',
          this._getConsumableSelectionDetails(),
        );
        this.setState({
          consumableSelectionDetails: this._getConsumableSelectionDetails(),
          consumable: this._consumableEditDetails(),
        });
      },
    });

  }


  private _getMaterialSelectionDetails(): string | number | {} | [] {
    return this._materialsSelection.getSelection();
  }

  private _getToolsSelectionDetails(): string | number | {} | [] {
    return this._toolsSelection.getSelection();
  }

  private _getLabourSelectionDetails(): string | number | {} | [] {
    return this._labourSelection.getSelection();
  }

  private _getConsumableSelectionDetails(): string | number | {} | [] {
    return this._consumableSelection.getSelection();
  }

  private _materialEditDetails() {
    const selectionCount = this._materialsSelection.getSelectedCount();
    let material = new BorResourcesItem();

    if (selectionCount == 1) {
      this.setState({
        isMaterialEdit: true,
      });

      let selectedMaterial = this._materialsSelection.getSelection()[0] as BorResourcesItem;
      material = selectedMaterial;
      //assign
    } else {
      this.setState({
        isMaterialEdit: false,
      });
    }
    return material;
  }

  private _toolsEditDetails() {
    const selectionCount = this._toolsSelection.getSelectedCount();
    let tool = new BorResourcesItem();

    if (selectionCount == 1) {
      this.setState({
        isToolEdit: true,
      });

      let selectedTool = this._toolsSelection.getSelection()[0] as BorResourcesItem;
      tool = selectedTool;
      //assign
    } else {
      this.setState({
        isToolEdit: false,
      });
    }
    return tool;
  }

  private _labourEditDetails() {
    const selectionCount = this._labourSelection.getSelectedCount();
    let labour = new BorResourcesItem();

    if (selectionCount == 1) {
      this.setState({
        isLabourEdit: true,
      });

      let selectedLabour = this._labourSelection.getSelection()[0] as BorResourcesItem;
      labour = selectedLabour;
      //assign
    } else {
      this.setState({
        isLabourEdit: false,
      });
    }
    return labour;
  }

  private _consumableEditDetails() {
    const selectionCount = this._consumableSelection.getSelectedCount();
    let consumable = new BorResourcesItem();

    if (selectionCount == 1) {
      this.setState({
        isConsumableEdit: true,
      });

      let selectedConsumable = this._consumableSelection.getSelection()[0] as BorResourcesItem;
      consumable = selectedConsumable;
      //assign
    } else {
      this.setState({
        isConsumableEdit: false,
      });
    }
    return consumable;
  }

  formatBorData = (borResources: { materials: any[], tools: any[], consumable: any[], labour: any[] }) => {
    let materials: any[] = [];
    let tools: any[] = [];
    let consumables: any[] = [];
    let labours: any[] = [];

    if (borResources.materials && _.isArray(borResources.materials)) {
      materials = borResources.materials.map((item: any) => {
        let previousMaterial = this.state.borResources.materials.filter((resource: any) => resource.pcpcId === item.id);
        if (previousMaterial && _.isArray(previousMaterial) && previousMaterial.length > 0) {
          return previousMaterial[0];
        } else {
          return this.getFormattedResource(item);
        }
      });
    }

    if (borResources.tools && _.isArray(borResources.tools)) {
      tools = borResources.tools.map((item: any) => {
        let previousTool = this.state.borResources.tools.filter((resource: any) => resource.pcpcId === item.id);
        if (previousTool && _.isArray(previousTool) && previousTool.length > 0) {
          return previousTool[0];
        } else {
          return this.getFormattedResource(item);
        }

      });
    }

    if (borResources.consumable && _.isArray(borResources.consumable)) {
      consumables = borResources.consumable.map((item: any) => {
        let previousConsumables = this.state.borResources.consumable.filter((resource: any) => resource.pcpcId === item.id);
        if (previousConsumables && _.isArray(previousConsumables) && previousConsumables.length > 0) {
          return previousConsumables[0];
        } else {
          return this.getFormattedResource(item);
        }
      });
    }

    if (borResources.labour && _.isArray(borResources.labour)) {
      labours = borResources.labour.map((item: any) => {
        let previousLabour = this.state.borResources.labour.filter((resource: any) => resource.pcpcId === item.id);
        if (previousLabour && _.isArray(previousLabour) && previousLabour.length > 0) {
          return previousLabour[0];
        } else {
          return this.getFormattedResource(item);
        }
      });
    }

    let borResourcesx = { materials: materials, tools: tools, consumable: consumables, labours: labours };
    this.setState({
      borResources: { materials: materials, tools: tools, consumable: consumables, labour: labours },
      totalAmount: this.getResourcesTotal({
        materials: materials,
        tools: tools,
        consumable: consumables,
        labour: labours,
      }, POAccessLevel.PROJECT),
    });
  };


  getResourcesTotal = (resource: any, level: POAccessLevel) => {
    let materials = resource && resource.hasOwnProperty('materials') && resource.materials ? resource.materials : [];
    let tools = resource && resource.hasOwnProperty('tools') && resource.tools ? resource.tools : [];
    let consumables = resource && resource.hasOwnProperty('consumable') && resource.consumable ? resource.consumable : [];
    let labours = resource && resource.hasOwnProperty('labour') && resource.labour ? resource.labour : [];
    let allResources = _.concat(materials, tools, consumables, labours);
    let subTotal = 0;
    if (level === POAccessLevel.PROJECT) {
      subTotal = allResources.reduce((n, { pQuantity, pUnitPrice }) => n + (pQuantity * pUnitPrice), 0);
    } else {
      subTotal = allResources.reduce((n, { cQuantity, cUnitPrice }) => n + (cQuantity * cUnitPrice), 0);
    }
    if (!subTotal) {
      subTotal = 0;
    }
    return subTotal;
  };


  getSelectedProduct = () => {
    if (this.props.formData?.externalProduct) {
      let poExternalProduct = this.props.formData?.externalProduct;
      if (poExternalProduct) {
        return poExternalProduct;
      } else {
        return [];
      }
    } else {
      return [];
    }

  };

  formatProduct = (products: any) => {

    if (products && _.isArray(products)) {
      let externalProducts = products.map((item: any) => {
        let previousProduct = this.state.externalProducts.filter((product: any) => product.id === item.productId);
        if (previousProduct && _.isArray(previousProduct) && previousProduct.length > 0) {
          return previousProduct[0];
        } else {

          return this.getFormattedProducts(item);
        }
      });

      let poExternalProduct = this.getSelectedProduct();
      if (poExternalProduct && poExternalProduct.length > 0) {

        let allExternalProduct = externalProducts.concat(poExternalProduct);
        // products price comes as string. converting to double
        let total = allExternalProduct.reduce((n, { pTotalPrice }) => n + (pTotalPrice ? parseFloat(pTotalPrice) : 0.00), 0);
        this.setState({
          externalProducts: allExternalProduct,
          totalAmount: total,
          borResources: { materials: [], tools: [], consumable: [], labour: [] },
        }, () => {
        });
      } else {
        // products price comes as string. converting to double
        let total = externalProducts.reduce((n, { pTotalPrice }) => n + (pTotalPrice ? parseFloat(pTotalPrice) : 0.00), 0);
        this.setState({
          externalProducts: externalProducts,
          totalAmount: total,
          borResources: { materials: [], tools: [], consumable: [], labour: [] },
        }, () => {
        });
      }

    } else {
      return [];
    }
  };

  getFormattedResource = (item: any) => {
    let quantity = this.form?.getState()?.values?.header?.poRequestTypeId === RETURN_REQUEST ? item.returned : item.required;
    if (this.form?.getState()?.values?.header?.poRequestTypeId === CAPACITY_REQUEST) {
      quantity = item.quantity;
    }
    return {
      pcpcId: item.corporateProductCatalogId,
      ccpcId: item.corporateProductCatalogId,
      pTitle: item.title,
      cTitle: item.title,
      resourceNumber: item.resourceNumber,
      date: item.date,
      pQuantity: quantity,
      pPurchased: item.purchased,
      pDeliveryRequested: item.deliveryRequested,
      warf: item.warf,
      pConsumed: item.consumed,
      pInvoiced: item.invoiced,
      corporateProductCatalogId: item.corporateProductCatalogId,
      borTitle: item.borTitle,
      pbsTitle: item.pbsTitle,
      pbsProductId: item.pbsProductId,
      borId: item.borId,
      pMou: item.mou,
      cMou: item.mou,
      pDocuments: null,
      pComments: null,
      pUnitPrice: item.inventoryPrice,
      pTotalPrice: (quantity ? quantity : 0) * (item.inventoryPrice ? item.inventoryPrice : 0),
      pCrossReference: null,
      cDocuments: null,
      cComments: null,
      cUnitPrice: item.inventoryPrice,
      cTotalPrice: (quantity ? quantity : 0) * (item.inventoryPrice ? item.inventoryPrice : 0),
      cCrossReference: null,
      cQuantity: quantity,
      cPurchased: item.purchased,
      cDeliveryRequested: item.deliveryRequested,
      cStartDate: null,
      cStopDate: null,
      cNumberOfDate: null,
      cFullTimeEmployee: null,
      pStartDate: null,
      pStopDate: null,
      pNumberOfDate: null,
      pFullTimeEmployee: null,
      pdevices: null,
      cdevices: null,
      resourceFamily: item.resourceFamily,
      projectTitle: getProject() ? getProject() : this.form?.getState()?.values?.header?.projectSequenceCode,
      borResourceId: item.id,

    };
  };

  getFormattedProducts = (item: any) => {
    return {
      headerTitle: item.headerTitle,
      id: item.productId,
      key: item.key,
      name: item.name,
      pbsProductItemType: item.pbsProductItemType,
      pbsProductItemTypeId: item.pbsProductItemTypeId,
      pbsProductStatus: item.pbsProductStatus,
      pbsProductStatusId: item.pbsProductStatusId,
      pbsToleranceState: item.pbsToleranceState,
      pbsToleranceStateId: item.pbsToleranceStateId,
      productId: item.productId,
      title: item.title,
      pDocuments: null,
      cDocuments: null,
      pComment: '',
      cComment: '',
      pQuantity: 5,
      cQuantity: 0,
      mou: '',
      pUnitPrice: '',
      cUnitPrice: 0,
      pTotalPrice: getProject() ? item.totalPrice : item.pTotalPrice,
      cTotalPrice: getProject() ? item.totalPrice : item.cTotalPrice,
      cCrossReference: '',
      pCrossReference: '',
      projectTitle: getProject() ? getProject() : this.form?.getState()?.values?.header?.projectSequenceCode,
      isUsed: getProject() ? false : true,
    };
  };

  setResourceChangeProject = (resource: any, level: POAccessLevel) => {
    if (level === POAccessLevel.PROJECT) {
      resource = {
        ...resource,
        cCrossReference: resource.pCrossReference,
        cDocuments: resource.pDocuments,
        cStartDate: resource.pStartDate,
        cStopDate: resource.pStopDate,
        cdevices: resource.pdevices,
        cFullTimeEmployee: resource.pFullTimeEmployee,
        cNumberOfDate: resource.pNumberOfDate,
      };
    } else {
      resource = {
        ...resource,
        pDocuments: resource.cDocuments,
        hasChanged: resource?.pTitle !== resource?.cTitle,
      };
    }
    let borResources = this.state.borResources;
    
    //const index = borResources[this.state.selectedResourceType].map((e:any) => e.pcpcId).indexOf(resource.pcpcId);
    //borResources[this.state.selectedResourceType][index] = resource

    borResources[this.state.selectedResourceType].map((e: any, index: number) => {
      if (e.pcpcId === resource.pcpcId && e.borId === resource.borId) {
        borResources[this.state.selectedResourceType][index] = resource;
      }
      if (e.pcpcId === resource.pcpcId && e.borId !== resource.borId) {
        let res = {
          ...e,
          cUnitPrice: resource?.cUnitPrice,
          cTotalPrice: (parseFloat(e.cQuantity) * parseFloat(resource?.cUnitPrice))

        };
        borResources[this.state.selectedResourceType][index] = res;
      }
    });
    this.setState({ borResources: borResources, totalAmount: this.getResourcesTotal(borResources, level) });
  };

  setProductChangeProject = (product: any, level: POAccessLevel) => {
    if (level === POAccessLevel.PROJECT) {
      product = {
        ...product,
        cDocuments: product.pDocuments,
        cTotalPrice: product.pTotalPrice,
      };
    }
    let exProducts = this.state.externalProducts;
    const index = exProducts.map((e: any) => e.id).indexOf(product.id);
    exProducts[index] = product;
    let total = 0;
    if (level === POAccessLevel.PROJECT) {
      // total = exProducts.reduce((n: any, {pTotalPrice, cQuantity}: any) => n + (pTotalPrice*1),0)
      // total = exProducts.reduce((n:any, {pTotalPrice}:any) => n + pTotalPrice, 0)
      total = exProducts.reduce((n: any, { pTotalPrice }: any) => n + (pTotalPrice ? parseFloat(pTotalPrice) : 0.00), 0);
    } else {
      // total = exProducts.reduce((n: any, {cTotalPrice, cQuantity}: any) => n + (cTotalPrice*1),0)
      // total = exProducts.reduce((n:any, {cTotalPrice}:any) => n + cTotalPrice, 0)
      total = exProducts.reduce((n: any, { cTotalPrice }: any) => n + (cTotalPrice ? parseFloat(cTotalPrice) : 0.00), 0);
    }
    this.setState({ externalProducts: exProducts, totalAmount: total }, () => {
    });

  };


  savePOHeader = (formValues: any) => {
    if (this.state.autoSave) {
      if (this.props.formData.sequenceId) {
        if (this.props.formData?.status?.key === APPROVED_STATUS_ID) {
          this.setState({ autoSave: false, isClear: false, hasChangeIntialFromData: false });
          this.handleAutoSaveSuccess();
        } else if (this.props.formData?.status?.key === HANDED_OVER_STATUS_ID) {
          this.setState({ autoSave: false, isClear: false, hasChangeIntialFromData: false });
          this.handleAutoSaveSuccess();
        } else {
          if (formValues.locationId) {
            if (!getProject()) {
              formValues.isClone = true;
            }
            this.props.autoSaveFormData(formValues).then(() => {
              this.setState({ autoSave: false, isClear: false, hasChangeIntialFromData: false });
              if (this.state.formData?.type?.key === PRODUCT_TYPE_ID) {
                this.props.readByPurchaseOrderId(this.state.formData?.sequenceId).then(() => {
                  this.handleAutoSaveSuccess();
                });
              } else {
                this.handleAutoSaveSuccess();
              }
            });
          } else {
            this.props.createPODeliveryLocation(this.state.location).then((response: any) => {
              if (response && response?.payload?.data?.result) {
                this.setState({ locationId: response?.payload?.data?.result }, () => {
                  let data = {
                    ...formValues,
                    locationId: response?.payload?.data?.result,
                  };
                  if (!getProject()) {
                    data.isClone = true;
                  }
                  this.props.autoSaveFormData(data).then(() => {
                    this.setState({ autoSave: false, isClear: false, hasChangeIntialFromData: false });
                    if (this.state.formData?.type?.key === PRODUCT_TYPE_ID) {
                      this.props.readByPurchaseOrderId(this.state.formData?.sequenceId).then(() => {
                        this.handleAutoSaveSuccess();
                      });
                    } else {
                      this.handleAutoSaveSuccess();
                    }

                  });
                });
              }
            });
          }
        }
      }
    } else {
      if (formValues.locationId) {
        if (!getProject()) {
          formValues.isClone = true;
        }
        this.props.saveFormData(formValues);
      } else {
        this.props.createPODeliveryLocation(this.state.location).then((response: any) => {
          if (response && response?.payload?.data?.result) {
            this.setState({ locationId: response?.payload?.data?.result }, () => {
              let data = {
                ...formValues,
                locationId: response?.payload?.data?.result,
              };
              if (!getProject()) {
                data.isClone = true;
              }
              this.props.saveFormData(data);
            });
          }
        });
      }
    }
  };


  componentDidUpdate(prevProps: any, prevState: any) {

    if (
      prevProps.isNew !== this.props.isNew ||
      prevProps.uid !== this.props.uid
    ) {
      if (this.props.isNew) {
        const formData = this.state.formData;
        formData.id = this.props.uid;
        this.setState({ formData, isClear: false, hasChangeIntialFromData: false });
      }
    }

    if (prevProps.purchaseOrderID !== this.props.purchaseOrderID) {
      if (this.props.purchaseOrderID) {
        this.props.readByPurchaseOrderId(this.props.purchaseOrderID).then((response: any) => {
          if (response.payload.data.result?.type?.key === RESOURCE_TYPE_ID && response.payload.data.result?.requestType?.key === PURCHASE_REQUEST && getProject()) {
            this.checkResourcesCount(response.payload.data.result);
          } else if (response.payload.data.result?.requestType?.key === PURCHASE_REQUEST) {
            // this.checkResourcesCount(response.payload.data.result)
          }
        });
      }
    }
    if (prevState.unitPriceNotDefine !== this.state.unitPriceNotDefine) {
      if (this.state.unitPriceNotDefine) {
        setTimeout(() => {
          this.setState({ unitPriceNotDefine: false });
        }, MSG_AUTO_CLOSE_TIME);
      }
    }
    if (prevState.deliveryPlanNotComplete !== this.state.deliveryPlanNotComplete) {
      if (this.state.deliveryPlanNotComplete) {
        setTimeout(() => {
          this.setState({ deliveryPlanNotComplete: false });
        }, MSG_AUTO_CLOSE_TIME);
      }
    }
    if (prevState.feedbackMsgShow !== this.state.feedbackMsgShow) {
      if (this.state.feedbackMsgShow) {
        setTimeout(() => {
          this.setState({ feedbackMsgShow: false });
        }, MSG_AUTO_CLOSE_TIME);
      }
    }
    if (prevState.hasSubmitForm !== this.state.hasSubmitForm) {
      if (this.state.hasSubmitForm) {
        setTimeout(() => {
          this.setState({ hasSubmitForm: false });
        }, MSG_AUTO_CLOSE_TIME);
      }
    }
    if (prevState.resourcesNotFound !== this.state.resourcesNotFound) {
      if (this.state.resourcesNotFound) {
        setTimeout(() => {
          this.setState({ resourcesNotFound: false });
        }, MSG_AUTO_CLOSE_TIME);
      }
    }
    if (prevState.borResourcesDif !== this.state.borResourcesDif) {
      if (this.state.borResourcesDif) {
        setTimeout(() => {
          this.setState({ borResourcesDif: false });
        }, MSG_AUTO_CLOSE_TIME);
      }
    }
    if (prevProps.poRequestType !== this.props.poRequestType) {
      this.setState({ poRequestType: this.props.poRequestType });
    }
    if (prevProps.projectWarehouseTaxonomy !== this.props.projectWarehouseTaxonomy) {
      this.setState({
        warehouseTaxonomy: this.props.projectWarehouseTaxonomy,
      });
    }
    if (prevProps.warehouseTaxonomy !== this.props.warehouseTaxonomy) {
      this.setState({
        warehouseTaxonomy: this.props.warehouseTaxonomy,
      });
    }


    if (prevProps.formData !== this.props.formData) {
      if (!this.state.isDirty) {
        if (!_.isEmpty(this.props.formData)) {
          if (this.form) {
            if (this.state.poRequestType != CAPACITY_REQUEST) {
              if (this.form.getFieldState('header.deliveryDate')) {
                if (this.props.formData?.requestType?.key != CAPACITY_REQUEST) {
                  this.form.resetFieldState('header.deliveryDate');
                }
              }
            }
          }
          const formData = this.props.formData;
          this.setState({
            formData: formData,
            borResources: this.props.formData?.poResources,
            isClear: false,
            hasChangeIntialFromData: false,
            externalProducts: this.props.formData?.externalProduct,
            location: this.props.formData?.mapLocation,
            position: this.props.formData?.mapLocation?.position,
            locationId: formData.locationId,
            totalAmount: formData.totalAmount,
            hasSubmitForm: false,
          });

          if (formData.warehouseTaxonomyId && formData.deliveryLocation === '1') {
            this.props.readWarehouseTaxonomy({ wareHouseId: null });
          } else if (formData.warehouseTaxonomyId && formData.deliveryLocation === '2') {
            if (formData.projectSequenceCode) {
              this.props.readWarehouseTaxonomyForProject({ wareHouseId: null }, formData.projectSequenceCode);
            }
          }
        }
      } else {
        if (this.props.isNew && _.isEmpty(this.props.formData)) {
          const formData = this.state.formData;
          this.setState({
            formData: formData,
            borResources: this.props.formData?.poResources,
            externalProducts: this.props.formData?.externalProduct,
            location: this.props.formData?.mapLocation,
            position: this.props.formData?.mapLocation?.position,
            isClear: false,
            hasChangeIntialFromData: false,
            locationId: formData.locationId,
            totalAmount: formData.totalAmount,
            hasSubmitForm: false,
          });
        }
      }

      if (this.props.formData?.projectSequenceCode) {
        this.props.getProjectHeaderDetails(
          this.props.formData.projectSequenceCode,
        );
        this.setState({
          selectedProject: this.props.formData?.projectSequenceCode,
        });
      }
      this.setState({
        selectedBORIds: [],
        selectedProductIds: [],
      });
    }

    if (prevProps.borResources !== this.props.borResources) {
      // this.setState({borResources:this.props.borResources});
      if (!_.isEmpty(this.props.borResources)) {
        this.formatBorData(this.props.borResources);
      }
    }

    if ((prevProps.pmolLocation != this.props.pmolLocation) && !this.props.formData?.locationId) {
      if (
        this.props.pmolLocation &&
        this.props.pmolLocation.position &&
        !isNaN(parseFloat(this.props.pmolLocation.position.lat.toString()))
      ) {
        this.setState({
            isClear: false,
            locationId: this.props.pmolLocation?.id,
            location: this.props.pmolLocation,
            position: {
              lat: parseFloat(this.props.pmolLocation.position.lat.toString()),
              lon: parseFloat(this.props.pmolLocation.position.lon.toString()),
            },
          }, () => {
            if (!this.props.formData.locationId) {
              //this.props.createPODeliveryLocation(this.state.location);
            }
          },
        );
      }
    }

    if (
      prevProps.pmolLocationId != this.props.pmolLocationId
    ) {
      this.setState({ locationId: this.props.pmolLocationId, isClear: false }, () => {
        //this.submitExternal();
      });

    }

    if (prevProps.formData?.sequenceId !== this.props.formData?.sequenceId) {
      if (this.props.match.url && this.props.match.url.indexOf('visual-plan') === -1) {
        this.scrollIntoTop();
      }
    }
  }

  componentDidMount() {
    // get document pane dropdowns
    this.props.getAllProjectDropdowns();
    this.props.getPoProjectList();
    this.props.readWarehouseDropDownData();
    this.props.readWarehouseTaxonomy({ wareHouseId: null });
    this.setState({ uid: uuidv4(), isClear: this.props.isNewPO });

    if (getProject()) {
      let projectId = getProject();
      if (projectId) {
        this.props.readLocationsByProjectId(projectId);
        this.props.getProjectHeaderDetails(projectId);
      }
    } else if (this.props.formData.projectSequenceCode) {
      this.props.getProjectHeaderDetails(
        this.props.formData.projectSequenceCode,
      );
    }

    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isBorModalVisible) {
          if (getProject()) {
            this.setState({
              isBorModalVisible: !this.state.isBorModalVisible,
              isClear: false,
              hasChangeIntialFromData: false,
            });
          } else {
            this.setState({
              isCuBorModalVisible: !this.state.isCuBorModalVisible,
              isClear: false,
              hasChangeIntialFromData: false,
            });
          }

        }
        if (data.data.isProductModalVisible) {
          this.setState({
            isProductModalVisible: !this.state.isProductModalVisible,
            isClear: false,
            hasChangeIntialFromData: false,
          });
        }
        if (data.data.isDeliveryPlanModalVisible) {
          this.setState({
            isDeliveryPlanModalVisible: !this.state.isDeliveryPlanModalVisible,
            isClear: false,
            hasChangeIntialFromData: false,
          });
        }
        if (data.data.getDeliveryPlanModal) {
          this.setState({
            getDeliveryPlanModal: true,
            autoSave: true,
            isClear: false,
            hasChangeIntialFromData: false,
          }, () => {
            if (this.state.autoSave) {
              this.setHasSubmitForm(true);
              this.submitExternal();
            }
          });
        }
        if (data.data.isNewPO) {

          if (getProject()) {
            this.props.readLocationsByProjectId(getProject());
            this.props.getProjectHeaderDetails(getProject());
            this.setState({
              uid: uuidv4(),
              isClear: true,
              hasChangeIntialFromData: false,
              totalAmount: 0,
              externalProducts: [],
              borResources: { materials: [], tools: [], consumable: [], labour: [] },
              hasSubmitForm: false,
              locationId: null,
              location: null,
              position: { lat: 0.0, lon: 0.0 },
            });
          } else {
            this.setState({
              uid: uuidv4(),
              isClear: true,
              hasChangeIntialFromData: false,
              totalAmount: 0,
              externalProducts: [],
              borResources: { materials: [], tools: [], consumable: [], labour: [] },
              locationId: null,
              location: null,
              position: { lat: 0.0, lon: 0.0 },
              hasSubmitForm: false,
            });
          }
          if (this.form) {
            this.form.reset();
            this.form.resetFieldState('header.name');
            if (getProject()) {
              this.form.resetFieldState('header.poRequestTypeId');
            }
            //this.form.resetFieldState('header.deliveryDate');
            this.form.resetFieldState('header.poTypeId');
            if (!getProject()) {
              this.form.resetFieldState('header.projectSequenceCode');
            }

          }
          let projectId = getProject();
          if (projectId) {
            // this.props.readLocationsByProjectId(projectId);
          }
          this.scrollIntoTop();
          if (data.data.poRequestType) {
            this.setState({
              poRequestType: data.data.poRequestType,
            });
          } else {
            this.setState({
              poRequestType: null,
            });
          }
        }
        if (data.data.savePOData) {
          this.setHasSubmitForm(true);
          this.submitExternal();
        }
        if (data.data.psFilter) {
          this.setState({ filter: data.data.psFilter });
        }
        if (data.data.pdfGenerate) {
          this.setState({ showPrint: true });
        }
        if (data.data.resourceItem) {
          this.setState({
            selectedBorResource: data.data.resourceItem,
            openPOResourceModal: true,
            selectedResourceType: data.data.resourceType,
          });
        }

        if (data.data.externalProduct) {
          this.setState({ selectedProduct: data.data.externalProduct, openPOProductModal: true });
        }

        if (data.data.handlePMApprove) {
          this.handlePMApprove();
        }
        if (data.data.handleProjectSendPo) {
          this.setState({ projectSendPo: true, autoSave: true, isClear: false, hasChangeIntialFromData: false }, () => {
            if (this.state.autoSave) {
              this.setHasSubmitForm(true);
              this.submitExternal();
            }
          });
        }
        if (data.data.handleCuSendPo) {
          this.setState({ cuSendPo: true, autoSave: true, isClear: false, hasChangeIntialFromData: false }, () => {
            if (this.state.autoSave) {
              this.setHasSubmitForm(true);
              this.submitExternal();
            }
          });
        }
        if (data.data.handleCUApprove) {
          this.handleCUApprove();
        }
        if (data.data.handleCUFeedback) {
          this.handleCUFeedback();
        }
        if (data.data.getProjectHeader) {
          this.setState({ isClear: false });
          this.getProjectHeader(data.data.getProjectHeader);
        }
        if (data.data.clonePo) {
          this.createPoClone();
        }
        if (data.data.borResourcesDif) {
          this.setState({ borResourcesDif: true });
        }
        if (data.data.convertCrTOPo) {
          this.convertCrTOPo();
        }

      }
    });
    if (this.props.formData?.id) {

    }
    if (this.props.poRequestType) {
      this.setState({ poRequestType: this.props.poRequestType });
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  scrollIntoTop = () => {
    let topDiv = document.getElementById('topDiv');
    if (topDiv) {
      topDiv.scrollIntoView();
    }
  };


  checkResourcesCount = (formData: any) => {
    // console.log("getById",formData);
    if (formData?.poResources) {
      let isValid = true;
      let materialMissingCount = 0;
      let toolsMissingCount = 0;
      let consumableMissingCount = 0;
      let labourMissingCount = 0;
      let poResources = formData?.poResources;
      // console.log("getById materials",borResources.materials.length+"="+formData.materialCount);
      // console.log("getById tools",borResources.tools.length+"="+formData.toolsCount);
      // console.log("getById consumable",borResources.consumable.length+"="+formData.consumableCount);
      // console.log("getById labour",borResources.labour.length+"="+formData.labourCount);

      if (formData.materialCount && formData.materialCount != '0' && poResources.materials && poResources.materials.length != formData.materialCount) {
        materialMissingCount = formData.materialCount - poResources.materials.length;
        isValid = false;
      }
      if (formData.toolsCount && formData.toolsCount != '0' && poResources.tools && poResources.tools.length != formData.toolsCount) {
        toolsMissingCount = formData.toolsCount - poResources.tools.length;
        isValid = false;
      }
      if (formData.consumableCount && formData.consumableCount != '0' && poResources.consumable && poResources.consumable.length != formData.consumableCount) {

        consumableMissingCount = formData.consumableCount - poResources.consumable.length;
        isValid = false;
      }
      if (formData.labourCount && formData.labourCount != '0' && poResources.labour && poResources.labour.length != formData.labourCount) {
        labourMissingCount = formData.labourCount - poResources.labour.length;
        isValid = false;
      }

      if (!isValid) {
        this.setState({
          showError: true,
          errorMsg: ` ${this.props.t('resourcesMissingBORToPO')}
        ${materialMissingCount != 0 ? this.props.t('materials') + ': ' + materialMissingCount : ''} 
        ${toolsMissingCount != 0 ? this.props.t('tools') + ': ' + toolsMissingCount : ''} 
        ${consumableMissingCount != 0 ? this.props.t('consumable') + ': ' + consumableMissingCount : ''} 
        ${labourMissingCount != 0 ? this.props.t('labour') + ': ' + labourMissingCount : ''}`,
        });
        //setTimeout(()=>{this.setState({showError:false,errorMsg:''})},MSG_AUTO_CLOSE_TIME)
      } else {
      }

      // return {
      //   status:isValid,
      //   materialMissingCount:materialMissingCount,
      //   toolsMissingCount:toolsMissingCount,
      //   consumableMissingCount:consumableMissingCount,
      //   labourMissingCount:labourMissingCount
      // }
    } else {
      // alert('else')
    }

  };

  handleAutoSaveSuccess = () => {
    if (this.state.getDeliveryPlanModal) {
      this.setState({
        isDeliveryPlanModalVisible: true,
        getDeliveryPlanModal: false,
        isClear: false,
        hasChangeIntialFromData: false,
      });
    } else if (this.state.cuSendPo) {
      this.setState({ cuSendPo: false, isClear: false, hasChangeIntialFromData: false });
      this.handleCuSendPo();
    } else if (this.state.projectSendPo) {
      this.setState({ projectSendPo: false, isClear: false, hasChangeIntialFromData: false });
      this.handleProjectSendPo();
    }
  };

  getProjectHeader = (projectId: string) => {
    this.setState({
      selectedProject: projectId,
    });
    this.props.getProjectHeaderDetails(projectId);
    // this.props.readLocationsByProjectId(projectId);
  };

  convertCrTOPo = () => {
    if (this.state.formData?.sequenceId) {
      let values = this.form.getState().values;

      let poResources = this.replacePtitleToCtitle();

      values.header.deliveryLocation = this.getDeliveryLocationValue(values);
      values.header.projectSequenceCode = (getProject()) ? getProject() : this.props?.formData?.projectSequenceCode;
      const formData = {
        ...values.header,
        deliveryDate: formatDateTimeToISOString(values?.header?.deliveryDate),
        totalAmount: this.state.totalAmount,
        locationId: this.state.locationId, ...values.involvedParties.customer, ...values.involvedParties.supplier,
        poResources: poResources,
        externalProduct: this.state.externalProducts,
      };
      formData.id = uuidv4();
      // console.log("replacePtitleToCtitle",formData);
      this.props.convertCrTOPo(formData).then((responce: any) => {
        this.props.readByPurchaseOrderId(this.state.formData?.sequenceId);
      });
    }
  };

  replacePtitleToCtitle = () => {
    if (this.state.borResources) {
      let borResources = this.state.borResources;

      let tools = borResources?.tools.map((item: any) => {
        return this.replaceItem(item);
      });
      let labour = borResources?.labour.map((item: any) => {
        return this.replaceItem(item);
      });
      // console.log("replacePtitleToCtitle1",this.state.borResources);
      // console.log("replacePtitleToCtitle2",{materials:[], tools:tools, consumable:[], labour:labour});
      return { materials: [], tools: tools, consumable: [], labour: labour };
    } else {
      return this.state.borResources;
    }
  };

  replaceItem = (item: any) => {
    return {
      ...item,
      pTitle: item?.cTitle,
      pCrossReference: item?.cCrossReference,
      pDeliveryRequested: item?.cDeliveryRequested,
      pDocuments: item?.cDocuments,
      pFullTimeEmployee: item?.cFullTimeEmployee,
      pMou: item?.cMou,
      pNumberOfDate: item?.cNumberOfDate,
      pPurchased: item?.cPurchased,
      pQuantity: item?.cQuantity,
      pStartDate: item?.cStartDate,
      pStopDate: item?.cStopDate,
      pTotalPrice: item?.cTotalPrice,
      pUnitPrice: item?.cUnitPrice,
      pcpcId: item?.ccpcId,
    };
  };

  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { psFilter: this.state.filter },
    });
  };

  private openMapModal = (): void => {
    this.setState({
      isOpenMap: !this.state.isOpenMap,
    });
  };

  setSubmitExternal = (submit: any) => {
    this.submitExternal = submit;
  };

  createPoClone = () => {
    if (this.state.formData?.sequenceId) {
      let formData = {
        id: uuidv4(),
        name: this.props.formData.name + '-' + formatDateTimeToLocal(new Date()),
        poTypeId: this.props.formData?.type?.key,
        poStatusId: IN_DEVELOPMENT_STATUS_ID,
        comments: this.props.formData?.comments,
        projectSequenceCode: this.props.formData?.projectSequenceCode,
        files: this.props.formData?.files,
        locationId: this.props.formData?.locationId,
        deliveryDate: this.props.formData?.deliveryDate,
        expectedDate: this.props.formData?.expectedDate,
        deliveryLocation: this.props.formData?.deliveryLocation,
        totalAmount: 0,
        externalProducts: [],
        poResources: { materials: [], tools: [], consumable: [], labour: [] },
        isClone: true,
      };
      this.props.saveFormData(formData).then((response: any) => {
        let poId = response?.payload?.data?.result;
        if (getContractingUnit() && getProject()) {
          history.push(`/CU/${getContractingUnit()}/project/${getProject()}/purchase-order/${poId}`);
        } else {
          history.push(`/CU/${getContractingUnit()}/purchase-order/${poId}`);
        }
      });
    }
  };

  createAutoGeneratedPO = () => {
    let data = {
      id: uuidv4(),
      name: this.props.projectHeader.title + '-' + formatDateTimeToLocal(new Date()),
      poTypeId: this.props.formData?.type?.key,
      poStatusId: this.props?.purchaseOrderDropDowns?.states[0].key,
      comments: this.props.formData?.comments,
      files: this.props.formData?.files,
      locationId: this.props.formData?.locationId,
      deliveryDate: this.props.formData?.deliveryDate,
      expectedDate: this.props.formData?.expectedDate,
      projectSequenceCode: this.props?.formData?.projectSequenceCode,
      totalAmount: 0,
      externalProducts: [],
      poResources: { materials: [], tools: [], consumable: [], labour: [] },
      isClone: true,
      isDeliveryPlan: true,
    };
    this.props.saveFormDataWithOutFetching(data);
    //this.props.saveFormData(data);
  };

  getDeliveryLocationValue = (values: any) => {
    if (values.header.deliveryLocation) {
      if (values.header.deliveryLocation === '1') {
        return true;
      } else if (values.header.deliveryLocation === '2') {
        return false;
      } else {
        return null;
      }
    }
  };

  handleCUApprove = () => {
    if (this.state.formData?.sequenceId) {
      let values = this.form.getState().values;
      values.header.deliveryLocation = this.getDeliveryLocationValue(values);
      values.header.projectSequenceCode = (getProject()) ? getProject() : this.props?.formData?.projectSequenceCode;
      const formData = {
        ...values.header,
        deliveryDate: formatDateTimeToISOString(values?.header?.deliveryDate),
        expectedDate: formatDateTimeToISOString(values?.header?.expectedDate),
        totalAmount: this.state.totalAmount,
        locationId: this.state.locationId, ...values.involvedParties.customer, ...values.involvedParties.supplier,
        poResources: this.state.borResources,
        externalProduct: this.state.externalProducts,
      };

      this.props.approvedPoCu(formData).then(() => {
        this.props.readByPurchaseOrderId(this.state.formData?.sequenceId);
      });
    }
  };

  handleCUFeedback = () => {
    if (this.props?.formData?.requestType?.key === RETURN_REQUEST) {
      if (this.checkUnitPrice()) {
        if (this.state.formData?.sequenceId) {
          let values = this.form.getState().values;
          values.header.deliveryLocation = this.getDeliveryLocationValue(values);
          values.header.projectSequenceCode = (getProject()) ? getProject() : this.props?.formData?.projectSequenceCode;
          const formData = {
            ...values.header,
            deliveryDate: formatDateTimeToISOString(values?.header?.deliveryDate),
            expectedDate: formatDateTimeToISOString(values?.header?.expectedDate),
            totalAmount: this.state.totalAmount,
            locationId: this.state.locationId, ...values.involvedParties.customer, ...values.involvedParties.supplier,
            poResources: this.state.borResources,
            externalProduct: this.state.externalProducts,
          };

          this.props.feedbackPoCu(formData).then(() => {
            this.props.readByPurchaseOrderId(this.state.formData?.sequenceId);
            this.setState({ feedbackMsgShow: true, deliveryPlanNotComplete: false });
          });
        }
      } else {
        this.setState({ unitPriceNotDefine: true });
      }
    } else {
      this.checkDeliveryPlan().then((valid) => {
        if (!valid) {
          this.setState({ deliveryPlanNotComplete: true });
        } else {
          if (this.checkUnitPrice()) {
            if (this.state.formData?.sequenceId) {
              let values = this.form.getState().values;
              values.header.deliveryLocation = this.getDeliveryLocationValue(values);
              values.header.projectSequenceCode = (getProject()) ? getProject() : this.props?.formData?.projectSequenceCode;
              const formData = {
                ...values.header,
                deliveryDate: formatDateTimeToISOString(values?.header?.deliveryDate),
                expectedDate: formatDateTimeToISOString(values?.header?.expectedDate),
                totalAmount: this.state.totalAmount,
                locationId: this.state.locationId, ...values.involvedParties.customer, ...values.involvedParties.supplier,
                poResources: this.state.borResources,
                externalProduct: this.state.externalProducts,
              };

              this.props.feedbackPoCu(formData).then(() => {
                this.props.readByPurchaseOrderId(this.state.formData?.sequenceId);
                this.setState({ feedbackMsgShow: true, deliveryPlanNotComplete: false });
              });
            }
          } else {
            this.setState({ unitPriceNotDefine: true });
          }
        }
      });
    }

  };

  handlePMApprove = () => {
    if (this.state.formData?.sequenceId) {
      let values = this.form.getState().values;
      values.header.deliveryLocation = this.getDeliveryLocationValue(values);
      values.header.projectSequenceCode = (getProject()) ? getProject() : this.props?.formData?.projectSequenceCode;
      const formData = {
        ...values.header,
        deliveryDate: formatDateTimeToISOString(values?.header?.deliveryDate),
        expectedDate: formatDateTimeToISOString(values?.header?.expectedDate),
        totalAmount: this.state.totalAmount,
        locationId: this.state.locationId, ...values.involvedParties.customer, ...values.involvedParties.supplier,
        poResources: this.state.borResources,
        externalProduct: this.state.externalProducts,
      };
      this.props.approvedPo(formData).then(() => {
        this.props.readByPurchaseOrderId(this.state.formData?.sequenceId);
      });
    }
  };

  handleProjectSendPo = () => {
    if (this.checkPoResources()) {
      this.setState({ resourcesNotFound: false });
      if (this.state.formData?.sequenceId) {
        let values = this.form.getState().values;
        values.header.deliveryLocation = this.getDeliveryLocationValue(values);
        values.header.projectSequenceCode = (getProject()) ? getProject() : this.props?.formData?.projectSequenceCode;
        const formData = {
          ...values.header,
          deliveryDate: formatDateTimeToISOString(values?.header?.deliveryDate),
          expectedDate: formatDateTimeToISOString(values?.header?.expectedDate),
          totalAmount: this.state.totalAmount,
          locationId: this.state.locationId, ...values.involvedParties.customer, ...values.involvedParties.supplier,
          poResources: this.state.borResources,
          externalProduct: this.state.externalProducts,
        };
        this.props.projectSendPo(formData).then(() => {
          this.props.readByPurchaseOrderId(this.state.formData?.sequenceId);
        });
      }
    } else {
      this.setState({ resourcesNotFound: true });
    }

  };


  handleCuSendPo = () => {
    if (this.checkPoResources()) {
      this.setState({ resourcesNotFound: false });
      this.checkCuDeliveryPlan().then((valid) => {
        if (!valid) {
          this.setState({ deliveryPlanNotComplete: true });
        } else {
          if (this.checkUnitPrice()) {
            if (this.state.formData?.sequenceId) {
              let values = this.form.getState().values;
              values.header.deliveryLocation = this.getDeliveryLocationValue(values);
              values.header.projectSequenceCode = (getProject()) ? getProject() : this.props?.formData?.projectSequenceCode;
              const formData = {
                ...values.header,
                deliveryDate: formatDateTimeToISOString(values?.header?.deliveryDate),
                expectedDate: formatDateTimeToISOString(values?.header?.expectedDate),
                totalAmount: this.state.totalAmount,
                locationId: this.state.locationId, ...values.involvedParties.customer, ...values.involvedParties.supplier,
                poResources: this.state.borResources,
                externalProduct: this.state.externalProducts,
              };

              this.props.cuSendPo(formData).then(() => {
                this.props.readByPurchaseOrderId(this.state.formData?.sequenceId);
                this.setState({ deliveryPlanNotComplete: false });
              });
            }
          } else {
            this.setState({ unitPriceNotDefine: true });
          }
        }
      });
    } else {
      this.setState({ resourcesNotFound: true });
    }


  };

  checkUnitPrice = (): boolean => {
    let poResources = this.state.formData?.poResources;
    if (poResources) {
      let allpoResources = poResources.materials.concat(poResources.consumable, poResources.tools, poResources.labour);
      let hasNull = allpoResources.filter(function(el: any) {
        return el.cUnitPrice === null || el.cUnitPrice === '0.00';
      });
      if (hasNull && hasNull.length >= 1) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
    return true;
  };

  checkPoResources = (): boolean => {
    if (this.state.formData?.type?.key === RESOURCE_TYPE_ID) {
      let poResources = this.state.formData?.poResources;
      let allpoResources = poResources.materials.concat(poResources.consumable, poResources.tools, poResources.labour);
      let poborResources = this.state.borResources;
      let allpoborResources = poborResources.materials.concat(poborResources.consumable, poborResources.tools, poborResources.labour);
      if (allpoResources && allpoResources.length > 0) {
        return true;
      } else if (allpoborResources && allpoborResources.length > 0) {
        return true;
      } else {
        return false;
      }
    } else if (this.state.formData?.type?.key === PRODUCT_TYPE_ID) {
      if (this.state.formData?.externalProduct && this.state.formData?.externalProduct.length > 0) {
        return true;
      } else if (this.state.externalProducts && this.state.externalProducts.length > 0) {
        return true;
      } else {

        return false;
      }
    } else if (this.state.formData?.type?.key === GOODDEERIS_PRODUCT_TYPE_ID) {
      let poResources = this.state.formData?.poResources;
      let allpoResources = poResources.materials.concat(poResources.consumable, poResources.tools, poResources.labour);
      let poborResources = this.state.borResources;
      let allpoborResources = poborResources.materials.concat(poborResources.consumable, poborResources.tools, poborResources.labour);
      if (allpoResources && allpoResources.length > 0) {
        return true;
      } else if (allpoborResources && allpoborResources.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  checkCuDeliveryPlan = async () => {
    let valid = true;
    if (this.state.formData?.type?.key === RESOURCE_TYPE_ID) {
      if (this.state.formData && this.state.formData.id) {
        await this.props.getCuDeliveryPlanListPaneData(this.state.formData.id).then(async (response: any) => {
          let result = response.payload.data.result;
          if (result && result.length > 0) {
            await result.forEach(async (borItem: any) => {
              await borItem.resources.forEach((resourcesItem: any) => {
                if (!resourcesItem.cUnitPrice || resourcesItem.cUnitPrice === '0' || resourcesItem.cUnitPrice === '0.00') {
                  valid = false;
                }
                // if(resourcesItem.isStock ===null){
                //   valid= false
                // }
                if (!resourcesItem.expectedDeliveryDate) {
                  valid = false;
                }
              });
            });
          } else {
            valid = true;
          }
        });
      }
    } else if (this.state.formData?.type?.key === PRODUCT_TYPE_ID) {
      let formDataProdct = this.state.formData.externalProduct;
      if (formDataProdct) {
        await formDataProdct.forEach((resourcesItem: any) => {
          if (!resourcesItem.cTotalPrice || resourcesItem.cTotalPrice === '0' || resourcesItem.cTotalPrice === '0.00') {
            valid = false;
          }
          if (!resourcesItem.expectedDeliveryDate) {
            valid = false;
          }
        });
      } else {
        valid = true;
      }
    }
    console.log('response3', valid);
    return valid;
  };


  checkDeliveryPlan = async () => {
    let valid = true;
    if (this.state.formData?.type?.key === RESOURCE_TYPE_ID) {
      if (this.state.formData && this.state.formData.id) {
        await this.props.getDeliveryPlanListPaneData(this.state.formData.id).then(async (response: any) => {
          let result = response.payload.data.result;
          if (result && result.length > 0) {
            await result.forEach(async (borItem: any) => {
              await borItem.resources.forEach((resourcesItem: any) => {
                if (!resourcesItem.cUnitPrice || resourcesItem.cUnitPrice === '0' || resourcesItem.cUnitPrice === '0.00') {
                  valid = false;
                }
                if (resourcesItem.isStock === null) {
                  valid = false;
                }
                if (!resourcesItem.expectedDeliveryDate) {
                  valid = false;
                }
              });
            });
          } else {
            valid = true;
          }
        });
      }
    } else if (this.state.formData?.type?.key === PRODUCT_TYPE_ID) {
      let formDataProdct = this.state.formData.externalProduct;
      if (formDataProdct) {
        await formDataProdct.forEach((resourcesItem: any) => {
          if (!resourcesItem.cTotalPrice || resourcesItem.cTotalPrice === '0' || resourcesItem.cTotalPrice === '0.00') {
            valid = false;
          }
          if (!resourcesItem.expectedDeliveryDate) {
            valid = false;
          }
        });
      } else {
        valid = true;
      }
    }
    return valid;
  };

  private setLocationAddress = (
    position: Position,
    address?: LocationAddress,
  ) => {
    if (address) {
      this.setState(
        {
          position: { lat: position.lat, lon: position.lon },
          location: address,
          isClear: false,
          locationId: null,
        },
      );
    } else {
      this.setState(
        (prevState: any) => ({
          isClear: false,
          locationId: null,
          position: position,
          location: {
            ...prevState.location,
            position: position,
          },
        }),
      );
    }
  };

  setForm = (form: any) => {
    this.form = form;
  };

  setHasSubmitForm = (val: boolean) => {
    this.setState({ hasSubmitForm: val, isClear: false });
  };


  getResourceByProductIds = (products: any) => {

    if (products && products.length > 0) {
      let productIds = products.map((item: any) => {
        return item.id;
      });
      this.props.readPbsResourceByIds(productIds);
    }
  };

  getSelectedBorIds = () => {
    if (this.props.formData?.poResources) {
      let poborResources = this.props.formData?.poResources;
      let allpoborResources = poborResources.materials.concat(poborResources.consumable, poborResources.tools, poborResources.labour);
      if (allpoborResources) {
        let borIds = allpoborResources.map((resources: any) => {
          return resources.borId;
        });
        return borIds.reduce(function(a: any, b: any) {
          if (a.indexOf(b) < 0) a.push(b);
          return a;
        }, []);
      }
    } else {
      return [];
    }

  };

  render() {
    return (
      <div style={{ width: '100%' }}>
        <div id={'topDiv'} style={{ position: 'absolute', width: '75%', zIndex: 1000 }}>
          {(this.form?.getState().errors?.header?.deliveryDate && this.state.hasSubmitForm) &&
          // <UprinceMessageBar notificatonType={"error"} message={ this.props.t('deliveryLocationRequired')}/>
          <CustomAlert
            showMsg={true}
            msg={this.props.t('pleaseUpdateDeliveryAddress')}
            msgType={'error'}
            handleCustomAlert={() => this.setState({ hasSubmitForm: false })}
          />
          }
          <CustomAlert
            showMsg={this.state.unitPriceNotDefine || this.state.feedbackMsgShow}
            msg={this.state.unitPriceNotDefine ? this.props.t('pleaseDefineUnitPricesForAllTheResources') : this.props.t('feedbackSent')}
            msgType={this.state.unitPriceNotDefine ? 'error' : 'success'}
            handleCustomAlert={() => this.setState({ unitPriceNotDefine: false, feedbackMsgShow: false })}
          />
          <CustomAlert
            showMsg={this.state.deliveryPlanNotComplete}
            msg={this.props.t('pleaseCompleteTheDeliveryPlanDetails')}
            msgType={'error'}
            handleCustomAlert={() => this.setState({ deliveryPlanNotComplete: false })}
          />
          <CustomAlert
            showMsg={this.state.resourcesNotFound}
            msg={this.state.formData?.type?.key === PRODUCT_TYPE_ID ? this.props.t('pleaseSelectTheProduct') : this.props.t('pleaseSelectTheResources')}
            msgType={'error'}
            handleCustomAlert={() => this.setState({ resourcesNotFound: false })}
          />

          <CustomAlert
            showMsg={this.state.borResourcesDif}
            msg={this.props.t('resourcesCountAreNotSame')}
            msgType={'error'}
            handleCustomAlert={() => this.setState({ borResourcesDif: false })}
          />
          <CustomAlert
            showMsg={this.state.showError}
            msg={this.state.errorMsg}
            msgType={'error'}
            isMultiline={true}
            handleCustomAlert={() => this.setState({ showError: false })}
          />
        </div>
        <RouteLeavingGuard
          when={this.state.isDirty}
          navigate={(path) => this.handleDiscardChangesNavigate(path)}
          shouldBlockNavigation={() => {
            return true;
          }}
          handleConfirmation={(isConfirm: boolean) => {
            this.setState({ isDirty: !isConfirm });
          }}
        />
        <DocumentPaneComponent
          submit={(submit: any) => {
            this.setSubmitExternal(submit);
          }}
          purchaseOrderDropDowns={{
            ...this.props.purchaseOrderDropDowns,
            warehouseTaxonomy: this.state.warehouseTaxonomy?.length > 0 ? this.state.warehouseTaxonomy : [],
            whTaxonomyLevel: this.props.warehouseDropDowns?.whTaxonomyLevel ? this.props.warehouseDropDowns?.whTaxonomyLevel : [],
          }}
          saveFormData={this.props.saveFormData}
          uid={this.state.uid}
          formData={this.props.formData}
          materialSelection={this._materialsSelection}
          toolSelection={this._toolsSelection}
          consumableSelection={this._consumableSelection}
          labourSelection={this._labourSelection}
          borResources={this.state.borResources}
          setOpenPOResourceModal={() => this.setState({ openPOResourceModal: !this.state.openPOResourceModal })}
          isExpand={this.props.isExpand}
          submitRfqEdit={this.props.submitRfqEdit}
          setForm={(form: any) => {
            this.setForm(form);
          }}
          showProgress={this.props.isLoadingPO}
          formValues={this.form?.getState()?.values}
          isClear={this.state.isClear}
          externalProducts={this.state.externalProducts}
          openMapModal={() => this.openMapModal()}
          setLocationAddress={(position: Position,
                               address: LocationAddress) => {
            this.setLocationAddress(position, address);
          }}
          position={this.state.position}
          address={
            this.state.location &&
            this.state.location?.address &&
            this.state.location.address.freeformAddress
              ? this.state.location.address.freeformAddress
              : ''
          }
          locationId={this.state.locationId}
          isDisabled={this.props.formData?.requestType?.key === RFQ_REQUEST ?true:this.props.formData?.status?.key && this.props.formData?.status?.key !== IN_DEVELOPMENT_STATUS_ID}
          projectHeader={this.props.projectHeader}
          totalAmount={this.state.totalAmount}
          savePOHeader={(formValue: any) => this.savePOHeader(formValue)}
          projectList={this.props.poProjectList}
          getProjectAddressById={(projectSequanceId: string) => {
            // this.props.readLocationsByProjectId(projectSequanceId)
          }}
          getWarehouseTaxonomy={(onProjectLevel: boolean) => {
            this.setState({
              isClear: false,
              hasChangeIntialFromData: true,
            });
            if (onProjectLevel) {
              this.props.readWarehouseTaxonomyForProject({ wareHouseId: null }, this.form?.getState()?.values?.header?.projectSequenceCode);
            } else {
              this.props.readWarehouseTaxonomy({ wareHouseId: null });
            }
          }}
          hasChangeIntialFromData={this.state.hasChangeIntialFromData}
          borResourcesCount={this.props.borResourcesCount}
          setHasSubmitForm={(val) => this.setHasSubmitForm(val)}
          hasSubmit={this.state.hasSubmitForm}
          poRequestType={this.state.poRequestType}
        />

        <POResourceModal
          isOpen={this.state.openPOResourceModal}
          setOpenPOResourceModal={() => {
            this.setState({ openPOResourceModal: !this.state.openPOResourceModal });
          }}
          borResource={this.state.selectedBorResource}
          setResourceChangeProject={(resource, level) => {
            this.setResourceChangeProject(resource, level);
          }}
          resourceType={this.state.selectedResourceType}
          formData={this.props.formData}
          projectSequance={getProject() ? getProject() : this.form?.getState()?.values?.header?.projectSequenceCode}
        />

        <POProductModal
          isOpen={this.state.openPOProductModal}
          setOpenPOProductModal={() => {
            this.setState({ openPOProductModal: !this.state.openPOProductModal });
          }}
          setProductChangeProject={(product: any, level: any) => {
            this.setProductChangeProject(product, level);
          }}

          formData={this.props.formData}
          product={this.state.selectedProduct}
          projectSequance={getProject() ? getProject() : this.form?.getState()?.values?.header?.projectSequenceCode}
        />
        <BorModal
          isOpen={this.state.isBorModalVisible}
          openModal={() => {
            this.setState({ isBorModalVisible: !this.state.isBorModalVisible });
          }}
          setSelectedBORIds={(ids: string[]) => {
            let savedBorIds = this.getSelectedBorIds();
            if (savedBorIds && savedBorIds.length > 0) {
              // console.log('selectedBORIds savedBorIds',savedBorIds);
              const allBorIds = ids.concat(savedBorIds);
              // console.log('selectedBORIds',allBorIds);
              this.setState({
                selectedBORIds: allBorIds,
              });
              this.props.readBORResourcesByIds(allBorIds);
            } else {
              this.setState({
                selectedBORIds: ids,
              });
              this.props.readBORResourcesByIds(ids);
            }


          }}
          selectedBORIds={this.state.selectedBORIds}
          formData={this.form?.getState()?.values}
        />
        <CuBorModal
          isOpen={this.state.isCuBorModalVisible}
          openModal={() => {
            this.setState({ isCuBorModalVisible: !this.state.isCuBorModalVisible });
          }}
          setSelectedBORIds={(ids: string[]) => {
            this.setState({
              selectedBORIds: ids,
            });
            this.props.readBORResourcesByIds(ids, this.form.getState().values.header.projectSequenceCode);
          }}
          selectedBORIds={this.state.selectedBORIds}
          selectedProject={this.state.selectedProject}
          setSelectedResources={(borResources: any, resourcesItem: any) => {
            let poborResources = this.props.formData?.poResources;
            if (poborResources) {
              let allLabour = borResources.labour.concat(poborResources.labour);
              let allTools = borResources.tools.concat(poborResources.tools);
              let allConsumable = borResources.consumable.concat(poborResources.consumable);
              let allMaterials = borResources.materials.concat(poborResources.materials);
              let allBor = { labour: allLabour, tools: allTools, consumable: allConsumable, materials: allMaterials };
              this.setState({
                borResources: allBor,
                totalAmount: this.getResourcesTotal({
                  materials: allBor?.materials,
                  tools: allBor?.tools,
                  consumable: allBor?.consumable,
                  labour: allBor?.labour,
                }, POAccessLevel.CU),
              });
              this.setState({
                selectedBORIds: resourcesItem,
              });
              // this.formatBorData(allBor)
            } else {
              this.setState({
                borResources: borResources,
                totalAmount: this.getResourcesTotal({
                  materials: borResources?.materials,
                  tools: borResources?.tools,
                  consumable: borResources?.consumable,
                  labour: borResources?.labour,
                }, POAccessLevel.CU),
              });
              this.setState({
                selectedBORIds: resourcesItem,
              });
              // this.formatBorData(borResources)
            }
          }}
        />
        <ProductModal
          isOpen={this.state.isProductModalVisible}
          openModal={() => {
            this.setState({ isProductModalVisible: !this.state.isProductModalVisible });
          }}
          setSelectedProducts={(products: any) => {
            if (this.state.poRequestType === CAPACITY_REQUEST) {
              this.setState({
                selectedProductIds: products,
              });
              this.getResourceByProductIds(products);
            } else {

              this.setState({
                selectedProductIds: products,
              });
              this.formatProduct(products);
            }

          }}
          selectedProductIds={this.state.selectedProductIds}
          selectedProject={this.state.selectedProject}
          poRequestType={this.state.poRequestType}
        />
        <DeliveryPlanModal
          isOpen={this.state.isDeliveryPlanModalVisible}
          openModal={() => {
            this.setState({ isDeliveryPlanModalVisible: !this.state.isDeliveryPlanModalVisible });
          }}
          setSelectedBORIds={(ids: string[]) => {
            this.props.readBORResourcesByIds(ids);
          }}
          formData={this.props.formData}
          createAutoGeneratedPO={() => this.createAutoGeneratedPO()}
        />

        <MapModal
          isOpen={this.state.isOpenMap}
          openMapModal={() => this.openMapModal()}
          setLocationAddress={(pos: Position, address?: LocationAddress) => {
            this.setLocationAddress(pos);
          }}
          position={this.state.position ? this.state.position : { lat: 0.0, lon: 0.0 }}
        />
      </div>
    );
  }

}

const mapStateToProps = (state: any) => {
  return {
    psDropDowns: state.ps.psDropDowns,
    isEdit: state.ps.isEdit,
    isNew: state.ps.isNew,
    uid: state.ps.uid,
    projectHeader: state.project.projectHeader,
    showProgressBar: state.ps.showProgressBar,
    ledgerDropDowns: state.project.ledgerDropDowns,
    selectedProject: state.project.selectedProject,
    purchaseOrderDropDowns: state.po.purchaseOrderDropDowns,
    borResources: state.po.borResources,
    purchaseOrderID: state.po.purchaseOrderID,
    borResourcesCount: state.po.borResourcesCount,
    pmolLocation: state.pmol.pmolLocation,
    pmolLocationId: state.po.pmolLocationId,
    isLoadingPO: state.po.isLoadingPO,
    poProjectList: state.po.poProjectList,
    warehouseDropDowns: state.warehouse.warehouseDropDowns,
    warehouseTaxonomy: state.warehouse.warehouseTaxonomy,
    projectWarehouseTaxonomy: state.warehouse.projectWarehouseTaxonomy,
  };
};

const mapDispatchToProps = {
  saveFormData,
  autoSaveFormData,
  getProjectHeaderDetails,
  getAllProjectDropdowns,
  getProjectDefinition,
  readBORResourcesByIds,
  setBORModalState,
  approvedPo,
  approvedPoCu,
  projectSendPo,
  cuSendPo,
  readByPurchaseOrderId,
  readLocationsByProjectId,
  createPMOLLocation,
  createPODeliveryLocation,
  feedbackPoCu,
  getPoProjectList,
  saveFormDataWithOutFetching,
  readWarehouseTaxonomy,
  readWarehouseTaxonomyForProject,
  readWarehouseDropDownData,
  getDeliveryPlanListPaneData,
  getCuDeliveryPlanListPaneData,
  readPbsResourceByIds,
  convertCrTOPo,
};

export default (connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(DocumentPane))));

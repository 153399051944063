import _ from 'lodash';
import i18n from '../../i18n';
import UprinceLogger from '../shared/Logger/logger';
import {
  PROJECT_DEFINITION_ADD_PROJECT_CONFIGURATION_EP,
  PROJECT_DEFINITION_READ_EP,
  PROJECT_ROLE_EP,
  READ_LAST_SEEN_PROJECTS,
} from '../shared/endpoints';

export const LOAD_PROJECT_MANAGEMENT_LEVEL = 'uprince/LOAD_PROJECT_MANAGEMENT_LEVEL';
export const LOAD_PROJECT_MANAGEMENT_LEVEL_SUCCSESS = 'uprince/LOAD_PROJECT_MANAGEMENT_LEVEL_SUCCESS';
export const LOAD_PROJECT_MANAGEMENT_LEVEL_FAIL = 'uprice/LOAD_PROJECT_MANAGEMENT_LEVEL_FAIL';

export const LOAD_PROJECT_TOLERANCE_STATE = 'uprince/LOAD_PROJECT_TOLERANCE_STATE';
export const LOAD_PROJECT_TOLERANCE_STATE_SUCCESS = 'uprince/LOAD_PROJECT_TOLERANCE_STATE_SUCCESS';
export const LOAD_PROJECT_TOLERANCE_STATE_FAIL = 'uprince/LOAD_PROJECT_TOLERANCE_STATE_FAIL';

export const LOAD_PROJECT_CURRENCY = 'uprince/LOAD_PROJECT_CURRENCY';
export const LOAD_PROJECT_CURRENCY_SUCCESS = 'uprince/LOAD_PROJECT_CURRENCY_SUCCESS';
export const LOAD_PROJECT_CURRENCY_FAIL = 'uprince/LOAD_PROJECT_CURRENCY_FAIL';

export const LOAD_PROJECT_STATE = 'uprince/LOAD_PROJECT_STATE';
export const LOAD_PROJECT_STATE_SUCCESS = 'uprince/LOAD_PROJECT_STATE_SUCCESS';
export const LOAD_PROJECT_STATE_FAIL = 'uprince/LOAD_PROJECT_STATE_FAIL';

export const LOAD_PROJECT_TYPE = 'uprince/LOAD_PROJECT_TYPE';
export const LOAD_PROJECT_TYPE_SUCCESS = 'uprince/LOAD_PROJECT_TYPE_SUCCESS';
export const LOAD_PROJECT_TYPE_FAIL = 'uprince/LOAD_PROJECT_TYPE_FAIL';

export const LOAD_PROJECT_TEMPLATE = 'uprince/LOAD_PROJECT_TEMPLATE';
export const LOAD_PROJECT_TEMPLATE_SUCCESS = 'uprince/LOAD_PROJECT_TEMPLATE_SUCCESS';
export const LOAD_PROJECT_TEMPLATE_FAIL = 'uprince/LOAD_PROJECT_TEMPLATE_FAIL';

export const LOAD_PROJECT_DEFINITION = 'uprince/LOAD_PROJECT_DEFINITION';
export const LOAD_PROJECT_DEFINITION_SUCCESS = 'uprince/LOAD_PROJECT_DEFINITION_SUCCESS';
export const LOAD_PROJECT_DEFINITION_FAIL = 'uprice/LOAD_PROJECT_DEFINITION_FAIL';

export const LOAD_PROJECT_FILTER = 'uprince/LOAD__CUPROJECT_FILTER';
export const LOAD_PROJECT_FILTER_SUCCESS = 'uprince/LOAD_CUPROJECT_FILTER_SUCCESS';
export const LOAD_PROJECT_FILTER_FAIL = 'uprice/LOAD_CUPROJECT_FILTER_FAIL';

export const LOAD_PROJECT_DEFINITION_BY_ID = 'uprince/LOAD_PROJECT_DEFINITION_BY_ID';
export const LOAD_PROJECT_DEFINITION_BY_ID_SUCCESS = 'uprince/LOAD_PROJECT_DEFINITION_BY_ID_SUCCESS';
export const LOAD_PROJECT_DEFINITION_FAIL_BY_ID = 'uprice/LOAD_PROJECT_DEFINITION_FAIL_BY_ID';

export const SAVE_PROJECT_DEFINITION = 'uprince/SAVE_PROJECT_DEFINITION';
export const SAVE_PROJECT_DEFINITION_SUCCESS = 'uprince/SAVE_PROJECT_DEFINITION_SUCCESS';
export const SAVE_PROJECT_DEFINITION_FAIL = 'uprince/SAVE_PROJECT_DEFINITION_FAIL';

export const UPDATE_PROJECT_DEFINITION = 'uprince/UPDATE_PROJECT_DEFINITION';
export const UPDATE_PROJECT_DEFINITION_SUCCESS = 'uprince/UPDATE_PROJECT_DEFINITION_SUCCESS';
export const UPDATE_PROJECT_DEFINITION_FAIL = 'uprince/UPDATE_PROJECT_DEFINITION_FAIL';

export const LOAD_PROJECT_DROPDOWNS = 'uprince/LOAD_PROJECT_DROPDOWNS';
export const LOAD_PROJECT_DROPDOWNS_SUCCESS = 'uprince/LOAD_PROJECT_DROPDOWNS_SUCCESS';
export const LOAD_PROJECT_DROPDOWNS_FAIL = 'uprice/LOAD_PROJECT_DROPDOWNS_FAIL';

export const LOAD_PROJECT_WAYPOINTS = 'uprince/LOAD_PROJECT_WAYPOINTS';
export const LOAD_PROJECT_WAYPOINTS_SUCCESS = 'uprince/LOAD_PROJECT_WAYPOINTS_SUCCESS';
export const LOAD_PROJECT_WAYPOINTS_FAIL = 'uprince/LOAD_PROJECT_WAYPOINTS_FAIL';

export const LOAD_USER_ROLES = 'uprince/LOAD_USER_ROLES';
export const LOAD_USER_ROLES_SUCCESS = 'uprince/LOAD_USER_ROLES_SUCCESS';
export const LOAD_USER_ROLES_FAIL = 'uprince/LOAD_USER_ROLES_FAIL';

export const DELETE_USER_ROLES = 'uprince/DELETE_USER_ROLES';
export const DELETE_USER_ROLES_SUCCESS = 'uprince/DELETE_USER_ROLES_SUCCESS';
export const DELETE_USER_ROLES_FAIL = 'uprince/DELETE_USER_ROLES_FAIL';

export const ADD_USER_LAST_PROJECT = 'uprince/ADD_USER_LAST_PROJECT';
export const ADD_USER_LAST_PROJECT_SUCCESS = 'uprince/ADD_USER_LAST_PROJECT_SUCCESS';
export const ADD_USER_LAST_PROJECT_FAIL = 'uprince/ADD_USER_LAST_PROJECT_FAIL';

export const LOAD_USER_LAST_SEEN_PROJECT = 'uprince/LOAD_USER_LAST_SEEN_PROJECT';
export const LOAD_USER_LAST_SEEN_PROJECT_SUCCESS = 'uprince/LOAD_USER_LAST_SEEN_PROJECT_SUCCESS';
export const LOAD_USER_LAST_SEEN_PROJECT_FAIL = 'uprince/LOAD_USER_LAST_SEEN_PROJECT_FAIL';

export const LOAD_CONTRACTING_UNITS = 'uprince/LOAD_CONTRACTING_UNITS';
export const LOAD_CONTRACTING_UNITS_SUCCESS = 'uprince/LOAD_CONTRACTING_UNITS_SUCCESS';
export const LOAD_CONTRACTING_UNITS_FAIL = 'uprince/LOAD_CONTRACTING_UNITS_FAIL';


export const LOAD_UPDATE_DETAILS_IS_LOADED = 'uprince/LOAD_UPDATE_DETAILS_IS_LOADED';

export const SAVE_WAYPOINTS_STATUS = 'uprince/SAVE_WAYPOINTS_STATUS';
export const ADD_PROJECT_CONFIGURATION = 'uprince/ADD_PROJECT_CONFIGURATION';
export const ADD_PROJECT_CONFIGURATION_SUCCESS = 'uprince/ADD_PROJECT_CONFIGURATION_SUCCESS';
export const ADD_PROJECT_CONFIGURATION_FAIL = 'uprince/ADD_PROJECT_CONFIGURATION_FAIL';

const defaultState: any = {
  projectManagementLevel: [],
  projectToleranceState: [],
  projectCurrency: [],
  projectState: [],
  projectType: [],
  projectTemplate: [],
  allProjects: [],
  nonFilterProjects: [],
  wayPoints: [],
  selectedProject: [],
  updateProjectList: false,
  saveMsgType: '',
  saveMsg: '',
  loadMsg: '',
  alertMsg: '',
  alertMsgType: '',
  userRoles: [],
  filterProjectManagementLevel: [],
  filterProjectToleranceState: [],
  filterProjectCurrency: [],
  filterProjectState: [],
  filterProjectType: [],
  filterProjectTemplate: [],
  isDataLoaded: false,
  isDetailsLoaded: true,
  deleteTeamState: false,
  contractingUnits: [],
  contractingUnitsdropdown:[],
  wayPointsShouldUpdate: false,
  rfqUnitPrice: null,
};

const cuProjectReducer = (state = defaultState, action: any) => {
  if (action) {
    switch (action.type) {
      case LOAD_PROJECT_MANAGEMENT_LEVEL:
        return { ...state, loading: true };
      case LOAD_PROJECT_MANAGEMENT_LEVEL_SUCCSESS:
        return {
          ...state,
          loading: false,
          projectManagementLevel: formattedPayloadToDropdown(action.payload),
        };
      case LOAD_PROJECT_MANAGEMENT_LEVEL_FAIL:
        return {
          ...state,
          loading: false,
          error: 'Error while fetching projectManagementLevel',
        };

      // ///tolerance case////////
      case LOAD_PROJECT_TOLERANCE_STATE:
        return { ...state, loading: true };
      case LOAD_PROJECT_TOLERANCE_STATE_SUCCESS:
        return {
          ...state,
          loading: false,
          projectToleranceState: formattedPayloadToDropdown(action.payload),
        };
      case LOAD_PROJECT_TOLERANCE_STATE_FAIL:
        return {
          ...state,
          loading: false,
          error: 'Error while fetching projectToleranceState',
        };

      // ///State case////////
      case LOAD_PROJECT_STATE:
        return { ...state, loading: true };
      case LOAD_PROJECT_STATE_SUCCESS:
        return {
          ...state,
          loading: false,
          projectState: formattedPayloadToDropdown(action.payload),
        };
      case LOAD_PROJECT_STATE_FAIL:
        return {
          ...state,
          loading: false,
          error: 'Error while fetching projectState',
        };

      // ///Type case////////
      case LOAD_PROJECT_TYPE:
        return { ...state, loading: true };
      case LOAD_PROJECT_TYPE_SUCCESS:
        return {
          ...state,
          loading: false,
          projectType: formattedPayloadToDropdown(action.payload),
        };
      case LOAD_PROJECT_TYPE_FAIL:
        return {
          ...state,
          loading: false,
          error: 'Error while fetching projectType',
        };

      // ///TEMPLATE case////////
      case LOAD_PROJECT_TEMPLATE:
        return { ...state, loading: true };
      case LOAD_PROJECT_TEMPLATE_SUCCESS:
        return {
          ...state,
          loading: false,
          projectTemplate: formattedPayloadToDropdown(action.payload),
        };
      case LOAD_PROJECT_TEMPLATE_FAIL:
        return {
          ...state,
          loading: false,
          error: 'Error while fetching projectTemplate',
        };


      // ///CURRENCY case////////
      case LOAD_PROJECT_CURRENCY:
        return { ...state, loading: true };
      case LOAD_PROJECT_CURRENCY_SUCCESS:
        return {
          ...state,
          loading: false,
          projectCurrency: formattedPayloadToDropdown(action.payload),
        };
      case LOAD_PROJECT_CURRENCY_FAIL:
        return {
          ...state,
          loading: false,
          error: 'Error while fetching projectCurrency',
        };

      // ///Project Dropdowns case////////
      case LOAD_PROJECT_DROPDOWNS:
        return { ...state, loading: true };
      case LOAD_PROJECT_DROPDOWNS_SUCCESS:
        return {
          ...state,
          loading: false,
          projectManagementLevel: (action.payload.data.result) ? formattedPayloadToAllDropdown(action.payload.data.result.managementLevels) : [],
          projectToleranceState: (action.payload.data.result) ? formattedPayloadToAllDropdown(action.payload.data.result.toleranceStates) : [],
          projectCurrency: (action.payload.data.result) ? formattedPayloadToAllDropdown(action.payload.data.result.currencies) : [],
          projectState: (action.payload.data.result) ? formattedPayloadToAllDropdown(action.payload.data.result.states) : [],
          projectType: (action.payload.data.result) ? formattedPayloadToAllDropdown(action.payload.data.result.types) : [],
          projectTemplate: (action.payload.data.result) ? formattedPayloadToAllDropdown(action.payload.data.result.templates) : [],
          filterProjectManagementLevel: (action.payload.data.result) ? formattedPayloadToFilterDropdown(action.payload.data.result.managementLevels) : [],
          filterProjectToleranceState: (action.payload.data.result) ? formattedPayloadToFilterDropdown(action.payload.data.result.toleranceStates) : [],
          filterProjectCurrency: (action.payload.data.result) ? formattedPayloadToFilterDropdown(action.payload.data.result.currencies) : [],
          filterProjectState: (action.payload.data.result) ? formattedPayloadToFilterDropdown(action.payload.data.result.states) : [],
          filterProjectType: (action.payload.data.result) ? formattedPayloadToFilterDropdown(action.payload.data.result.types) : [],
          filterProjectTemplate: (action.payload.data.result) ? formattedPayloadToFilterDropdown(action.payload.data.result.templates) : [],
        };
      case LOAD_PROJECT_DROPDOWNS_FAIL:
        return {
          ...state,
          loading: false,
          error: 'Error while fetching projectCurrency',
        };

      // ///Get All Project case////////
      case LOAD_PROJECT_DEFINITION:
        return { ...state, loading: true };
      case LOAD_PROJECT_DEFINITION_SUCCESS:
        return {
          ...state,
          loading: false,
          updateProjectList: false,
          nonFilterProjects: formattedPayloadToListPaneTable(action.payload),
          // loadMsg: action.payload.data.message,
        };

      // ///Filter Project case////////
      case LOAD_PROJECT_FILTER:
        return { ...state, loading: true, allProjects: [], loadMsg: 'loading', isDataLoaded: false };
      case LOAD_PROJECT_FILTER_SUCCESS:
        return {
          ...state,
          loading: false,
          updateProjectList: false,
          allProjects: formattedPayloadToListPaneTable(action.payload),
          isDataLoaded: true,
          loadMsg: (!action.payload.data.status) ? action.payload.data.message : '',
        };
      case LOAD_PROJECT_FILTER_FAIL:
        return {
          ...state,
          allProjects: [],
          loading: false,
          isDataLoaded: false,
          error: 'Error while fetching Project',
          loadMsg: action.error.response.data.message,
        };

      // /// Project way points case////////
      case LOAD_PROJECT_WAYPOINTS:
        return { ...state, loading: true, wayPoints: [], loadMsg: 'loading' };
      case LOAD_PROJECT_WAYPOINTS_SUCCESS:
        return {
          ...state,
          loading: false,
          // wayPoints: map_data_to_table(action.payload),
          // loadMsg: action.payload.data.message,
        };
      case LOAD_PROJECT_WAYPOINTS_FAIL:
        return {
          ...state,
          wayPoints: [],
          loading: false,
          error: 'Error while fetching Project',
        };

      // ///Get By id Project case////////
      case LOAD_PROJECT_DEFINITION_BY_ID:
        return { ...state, loading: true, isDetailsLoaded: false };
      case LOAD_PROJECT_DEFINITION_BY_ID_SUCCESS:
        return {
          ...state,
          loading: false,
          isDetailsLoaded: true,
          selectedProject: action.payload.data.result,
        };
      case LOAD_PROJECT_DEFINITION_FAIL_BY_ID:
        return {
          ...state,
          loading: false,
          isDetailsLoaded: true,
          error: 'Error while fetching Project',
        };

      // ///Save Project DEFINITION ////////
      case SAVE_PROJECT_DEFINITION:
        return { ...state, loading: true };
      case SAVE_PROJECT_DEFINITION_SUCCESS:
        return {
          ...state,
          loading: false,
          updateProjectList: true,
          saveMsgType: 'success',
          saveMsg: action.payload.data.message,
        };
      case SAVE_PROJECT_DEFINITION_FAIL:
        return {
          ...state,
          alertMsg: action.error.response.data.message,
          alertMsgType: 'error',
          loading: false,
          error: 'Error while Saving Project Definition',
          saveMsgType: 'error',
          saveMsg: action.error.response.data.message,
        };

      // ///Update Project DEFINITION ////////
      case UPDATE_PROJECT_DEFINITION:
        return { ...state, loading: true };
      case UPDATE_PROJECT_DEFINITION_SUCCESS:
        return {
          ...state,
          loading: false,
          updateProjectList: true,
          saveMsgType: 'success',
          alertMsgType: 'success',
          saveMsg: action.payload.data.message,
          alertMsg: action.payload.data.message,
        };
      case UPDATE_PROJECT_DEFINITION_FAIL:
        return {
          ...state,
          alertMsg: action.error.response.data.message,
          alertMsgType: 'error',
          loading: false,
          error: 'Error while Saving Project Definition',
          saveMsgType: 'error',
          saveMsg: action.error.response.data.message,
        };

      case LOAD_UPDATE_DETAILS_IS_LOADED:
        return {
          ...state,
          isDetailsLoaded: true,
        };
      // /////// GET User roles ////////
      case LOAD_USER_ROLES:
        UprinceLogger.log('LOAD_USER_ROLES');
        return {
          ...state,
          userRoles: [],
        };
      case LOAD_USER_ROLES_SUCCESS:
        UprinceLogger.log('LOAD_USER_ROLES_SUCCESS', action.payload.data.result);
        return {
          ...state,
          userRoles: formatUserRoleResponse(action.payload.data.result),
        };
      case LOAD_USER_ROLES_FAIL:
        UprinceLogger.log('LOAD_USER_ROLES_FAIL');
        return {
          ...state,
          userRoles: [],
        };
      case DELETE_USER_ROLES:
        UprinceLogger.log('DELETE_USER_ROLES');
        return {
          ...state,
          deleteTeamState: false,
        };
      case DELETE_USER_ROLES_SUCCESS:
        UprinceLogger.log('DELETE_USER_ROLES_SUCCESS', action.payload.data.result);
        return {
          ...state,
          deleteTeamState: true,
        };
      case DELETE_USER_ROLES_FAIL:
        UprinceLogger.log('DELETE_USER_ROLES_FAIL');
        return {
          ...state,
          deleteTeamState: false,
        };
      case ADD_USER_LAST_PROJECT:
        UprinceLogger.log('ADD_USER_LAST_PROJECT');
        return {
          ...state,
          deleteTeamState: false,
        };
      case ADD_USER_LAST_PROJECT_SUCCESS:
        UprinceLogger.log('ADD_USER_LAST_PROJECT_SUCCESS', action.payload.data.result);
        return {
          ...state,
          wayPointsShouldUpdate: true,
          deleteTeamState: true,
        };
      case ADD_USER_LAST_PROJECT_FAIL:
        UprinceLogger.log('ADD_USER_LAST_PROJECT_FAIL');
        return {
          ...state,
          deleteTeamState: false,
        };
      case LOAD_USER_LAST_SEEN_PROJECT:
        UprinceLogger.log('LOAD_USER_LAST_SEEN_PROJECT');
        return { ...state };
      case LOAD_USER_LAST_SEEN_PROJECT_SUCCESS:
        UprinceLogger.log('LOAD_USER_LAST_SEEN_PROJECT_SUCCESS', action.payload.data.result);
        return {
          ...state,
          wayPoints: action.payload.data.result,
        };
      case LOAD_USER_LAST_SEEN_PROJECT_FAIL:
        UprinceLogger.log('LOAD_USER_LAST_SEEN_PROJECT_FAIL');
        return { ...state };
      case LOAD_CONTRACTING_UNITS:
        UprinceLogger.log('LOAD_CONTRACTING_UNITS');
        return { ...state, isDataLoaded: false };
      case LOAD_CONTRACTING_UNITS_SUCCESS:
        UprinceLogger.log('LOAD_CONTRACTING_UNITS_SUCCESS', action.payload.data.result);
        return {
          ...state,
          contractingUnits: action.payload.data.result,
          contractingUnitsdropdown: action.payload.data.result
            ? formattedPayloadToDropdownList(
                action.payload.data.result
              )
            : [],
          isDataLoaded: true,
        };
      case LOAD_CONTRACTING_UNITS_FAIL:
        UprinceLogger.log('LOAD_CONTRACTING_UNITS_FAIL');
        return {
          ...state,
          isDataLoaded: true,
        };

      case SAVE_WAYPOINTS_STATUS:
        return {
          ...state,
          wayPointsShouldUpdate: false,
        };

      case ADD_PROJECT_CONFIGURATION:
        return {
          ...state,
          loading: true,
          rfqUnitPrice: null,
        };

      case ADD_PROJECT_CONFIGURATION_SUCCESS:
        return {
          ...state,
          loading: false,
          rfqUnitPrice: action.payload.data.result,
        }

      case ADD_PROJECT_CONFIGURATION_FAIL:
        return {
          ...state,
          loading: false,
          rfqUnitPrice: null,
        }

      default:
        return state;
    }
  }
};

export const updateIsDetailsLoadedState = () => {
  return { type: LOAD_UPDATE_DETAILS_IS_LOADED };
};

export const getProjectManagementLevel = () => {
  return {
    type: LOAD_PROJECT_MANAGEMENT_LEVEL,
    payload: { request: { url: 'ProjectManagementLevel/Read' } },
  };
};

export const getProjectCurrency = () => {
  return {
    type: LOAD_PROJECT_CURRENCY,
    payload: { request: { url: 'Currency/Read' } },
  };
};

export const getProjectToleranceState = () => {
  return {
    type: LOAD_PROJECT_TOLERANCE_STATE,
    payload: { request: { url: 'ProjectToleranceState/Read' } },
  };
};

export const getProjectState = () => {
  return {
    type: LOAD_PROJECT_STATE,
    payload: { request: { url: 'ProjectState/Read' } },
  };
};

export const getProjectType = () => {
  return {
    type: LOAD_PROJECT_TYPE,
    payload: { request: { url: 'ProjectType/Read' } },
  };
};

export const getProjectTemplate = () => {
  return {
    type: LOAD_PROJECT_TEMPLATE,
    payload: { request: { url: 'ProjectTemplate/Read' } },
  };
};

export const getAllProjects = () => {
  return {
    type: LOAD_PROJECT_DEFINITION,
    payload: { request: { url: 'ProjectDefinition/Read' } },
  };
};

export const getAllProjectsWaypoints = () => {
  return {
    type: LOAD_PROJECT_WAYPOINTS,
    payload: { request: { url: 'ProjectDefinition/Read' } },
  };
};

export const getAllProjectDropdowns = () => {
  return {
    type: LOAD_PROJECT_DROPDOWNS,
    payload: { request: { url: 'ProjectDefinition/ReadProjectDropdowns' } },
  };
};

export const getProjectDefinition = (id: any) => {
  return {
    type: LOAD_PROJECT_DEFINITION_BY_ID,
    payload: {
      request: {
        // url: `ProjectDefinition/Read/` + id,
        url: PROJECT_DEFINITION_READ_EP + id,
      },
    },
  };
};

export const getFilterProjectListV2 = (data: {}) => {
  return {
    types: [LOAD_PROJECT_FILTER, LOAD_PROJECT_FILTER_SUCCESS, LOAD_PROJECT_FILTER_FAIL],
    payload: {
      request: {
        url: 'ContractingUnitTenants/GetProjectsByUserProjectFilter',
        // url: 'Shift/Filter',
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getContractingUnits = () => {
  return {
    types: [LOAD_CONTRACTING_UNITS, LOAD_CONTRACTING_UNITS_SUCCESS, LOAD_CONTRACTING_UNITS_FAIL],
    payload: {
      request: {
        url: 'ProjectTeam/ReadContractingUnit',
        method: 'GET',
      },
    },
  };
};

export const saveProjectDefinition = (data: {}) => {
  return {
    type: SAVE_PROJECT_DEFINITION,
    payload: {
      request: {
        url: 'ProjectDefinition/Create',
        method: 'POST',
        data: data,
      },
    },
  };
};

export const addProjectConfiguration = (data: {}) => {
  return {
    types: [ADD_PROJECT_CONFIGURATION, ADD_PROJECT_CONFIGURATION_SUCCESS, ADD_PROJECT_CONFIGURATION_FAIL],
    payload: {
      request: {
        url: PROJECT_DEFINITION_ADD_PROJECT_CONFIGURATION_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const updateProjectDefinition = (data: {}) => {
  return {
    type: [UPDATE_PROJECT_DEFINITION],
    payload: {
      request: {
        url: 'ProjectDefinition/Update',
        method: 'PUT',
        data: data,
      },
    },
  };
};

export const getUserRoles = (data: {}) => {
  return {
    types: [LOAD_USER_ROLES, LOAD_USER_ROLES_SUCCESS, LOAD_USER_ROLES_FAIL],
    payload: {
      request: {
        url: PROJECT_ROLE_EP,
        method: 'GET',
        data: data,
      },
    },
  };
};

export const addUserProject = (projectId: string) => {
  return {
    types: [ADD_USER_LAST_PROJECT, ADD_USER_LAST_PROJECT_SUCCESS, ADD_USER_LAST_PROJECT_FAIL],
    payload: {
      request: {
        url: `ProjectDefinition/UpdateProjectViewTime/${projectId}`,
        method: 'PUT',
      },
    },
  };
};

export const getLastSeenProjects = (projectId: string) => {
  return {
    types: [LOAD_USER_LAST_SEEN_PROJECT, LOAD_USER_LAST_SEEN_PROJECT_SUCCESS, LOAD_USER_LAST_SEEN_PROJECT_FAIL],
    payload: {
      request: {
        // url: `ProjectDefinition/ReadLastSeenProjects/${9}`,
        url: READ_LAST_SEEN_PROJECTS + 9,
        method: 'GET',
      },
    },
  };
};

// export const addUserProject=(projectId:string) => {
//   return {
//     types: [ADD_USER_LAST_PROJECT,ADD_USER_LAST_PROJECT_SUCCESS,ADD_USER_LAST_PROJECT_FAIL],
//     payload: {
//       request: {
//         url: `ProjectDefinition/UpdateProjectViewTime/${projectId}`,
//         method: 'PUT',
//       },
//     },
//   };
// };

const formatUserRoleResponse = (data: any[]) => {
  if (data && _.isArray(data)) {
    const formattedData = data.map((item: any) => {
      return {
        key: item.id,
        text: item.roleName,
      };
    });
    // console.log("map_data",data);
    return formattedData;
  }
  return [];
};

export const deleteTeamMembers = (data: string[]) => {
  return {
    types: [DELETE_USER_ROLES, DELETE_USER_ROLES_SUCCESS, DELETE_USER_ROLES_FAIL],
    payload: {
      request: {
        url: 'ProjectTeam/DeleteTeamRole',
        method: 'DELETE',
        data: data,
      },
    },
  };
};

// export const getTeamRoalsDropDownByCode = (code: string) => {
//     return {
//         types: [
//             PROJECT_TEAM_ROLE_DROPDOWN_READ,
//             PROJECT_TEAM_ROLE_DROPDOWN_READ_SUCCESS,
//             PROJECT_TEAM_ROLE_DROPDOWN_READ_FAIL
//         ],
//         payload: {
//             request: {
//                 url: PROJECT_ROLE_DROPDOWN_BY_CODE_EP + code,
//                 method: 'GET'
//             }
//         }
//     };
// };

const formattedPayloadToDropdown = (response: any) => {
  if (response.data.result) {
    const data = response.data.result.map((item: any) => {
      return {
        key: item.id,
        text: item.name,
        isDefault: item.isDefault,
      };
    });
    // console.log("map_data",data);
    return data;
  }
  return [];
};

const formattedPayloadToAllDropdown = (response: any) => {
  if (response) {
    const data = response.map((item: any) => {
      return {
        key: item.id,
        text: item.name,
        isDefault: item.isDefault,
      };
    });
    return data;
  }
  return [];
};

export function saveWayPointStatus(attr: string, value: any) {
  // console.log('save 11');
  // console.log(value);
  return {
    type: SAVE_WAYPOINTS_STATUS,
    value: value,
  };
}

const formattedPayloadToListPaneTable = (response: any) => {
  if (response.data) {
    const data = response.data.map((item: any) => {
      return {
        id: item.id,
        value: item.projectDefinitionId,
        sequenceCode: item.sequenceCode,
        title: item.title,
        projectTypeName: item.projectTypeName,
        projectManagementLevelName: item.projectManagementLevelName,
        projectTemplateName: (item.projectTemplateName) ? item.projectTemplateName : '-',
        projectToleranceStateName: item.projectToleranceStateName,
      };
    });
    return data;
  }
  return [];
};

const formattedPayloadToFilterDropdown = (response: any) => {
  // const t = i18n.t;
  if (response) {
    const data = response.map((item: any) => {
      return {
        key: item.id,
        text: item.name,
        isDefault: false,
      };
    });
    const defualtOption = [
      {
        key: '0',
        text: i18n.t('all'),
        isDefault: true,
      },
    ];
    Array.prototype.push.apply(
      defualtOption,
      data,
    );
    return defualtOption;
  }
  return [];
};
const formattedPayloadToDropdownList = (response: any) => {
  if (response) {
    const data = response.map((item: any) => {
      return {
        key: item.key,
        text: item.name,
      };
    });
    return data;
  }
  return [];
};
export default cuProjectReducer;

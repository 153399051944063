import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';

const CommandBarRight = () => {

  const newClick = () => {
    messageService.sendMessage({ isNewOS: true });
    // props.createNew(true);
  };
  const saveFormData = () => {
    messageService.sendMessage({ saveOSData: true });
  };

  const getItems = () => {
    const { t } = useTranslation();
    return [
      // {
      //     key: 'new',
      //     text: t('new'),
      //     iconProps: { iconName: 'Add' },
      //     onClick: () => {newClick();},
      //     disabled: false
      // },
      // {
      //     key: 'save',
      //     text: t('save'),
      //     iconProps: { iconName: 'Save' },
      //     onClick: () => {saveFormData();},
      //     disabled: false
      // }
    ];
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );
};

export default CommandBarRight;

import React from 'react';
// import { SearchBox } from '@fluentui/react/lib/SearchBox';
//import './../../components/component.css';
//import history from './../../history';
import { Icon, Label, Link, mergeStyleSets, Stack, Text } from '@fluentui/react';
import { ISearchBox } from '@fluentui/react/lib/SearchBox';
import { initializeIcons } from '@uifabric/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import history from '../../../history';
import { messageService } from '../../../services/messageService';
import { iconColors, uPrinceTheme } from '../../../../theme';

initializeIcons();

interface Props extends WithTranslation {
}

interface State {
  divClass: string;
  selectedCodeType: number;
}

const classNames = mergeStyleSets({
  wrapper: {
    // height: 'calc(100vh - 12vh)!important',
    position: 'relative',
  },
  selected: {
    fontWeight: 'bold',
    color: '#854114!important',
  },
});

class AddressBookShortCutPane extends React.Component<Props, State> {
  private focusSearch = React.createRef<ISearchBox>();

  constructor(props: Props) {
    super(props);
    this.state = { divClass: 'filter-panel-left', selectedCodeType: -1 };
  }

  componentDidMount() {
    if (this.state.divClass === 'filter-panel-left') {
      if (window.innerWidth > 1300) {
        messageService.sendMessage({ shortCutPaneExpanded: true });
      }
    } else {
      messageService.sendMessage({ shortCutPaneExpanded: false });
    }
  }

  public render(): JSX.Element {
    const { t } = this.props;
    return (
      <div className={` shortCutPaneWrapper ${this.state.divClass} ${classNames.wrapper}`} onMouseOver={() => {
        if (window.innerWidth > 1100 && window.innerWidth < 1600) {
          messageService.sendMessage({ shortCutPaneExpanded: true });
        }
      }} onMouseLeave={() => {
        if (window.innerWidth > 1100 && window.innerWidth < 1600) {
          messageService.sendMessage({ shortCutPaneExpanded: false });
        }
      }}>
        <div className="inner-container">
          <i
            onClick={this._toggleOverlay}
            className="ms-Icon ms-Icon--Back back-arrow"
            aria-hidden="true"
          ></i>
          <i
            onClick={this._toggleOverlayFwd}
            className="ms-Icon ms-Icon--Forward fwd-arrow"
            aria-hidden="true"
          ></i>

          <div className="filter-data">
            <Label>{t('centralAddressBook')}</Label>
          </div>

          <div className="filter-option-list">
            <Stack
              gap={15}
              verticalFill
              styles={{
                root: {
                  width: '100%',
                  verticalAlign: 'center',
                },
              }}
            >
              <Link
                className={`${
                  this.state.selectedCodeType == -1 ? classNames.selected : ''
                }`}
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  this.setState({ selectedCodeType: -1 });
                  messageService.sendMessage({ cabType: 'person' });
                  history.push({
                    pathname: '/address-book',
                    state: { type: 'person' },
                  });
                }}
              >
                <Icon
                  iconName="Contact"
                  className="ms-IconExample"
                  style={this.state.selectedCodeType === -1 ? { color: iconColors.iconActiveColor } : { color: uPrinceTheme.palette.themePrimary }}
                />
                <Text>{t('person')}</Text>
              </Link>

              <Link
                className={`${
                  this.state.selectedCodeType == 4 ? classNames.selected : ''
                }`}
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  this.setState({ selectedCodeType: 4 });
                  messageService.sendMessage({ cabType: 'organisation' });
                  history.push({
                    pathname: '/address-book',
                    state: { type: 'organisation' },
                  });
                }}
              >
                <Icon iconName="CompanyDirectory" className="ms-IconExample"
                      style={this.state.selectedCodeType === 4 ? { color: iconColors.iconActiveColor } : { color: uPrinceTheme.palette.themePrimary }}/>
                <Text>{t('organisation')}</Text>
              </Link>
            </Stack>
          </div>
        </div>
      </div>
    );
  }

  private _toggleOverlay = (): void => {
    this.setState({ divClass: 'filter-panel-left panel-move' });
    messageService.sendMessage({ shortCutPaneExpanded: false });

  };

  private _toggleOverlayFwd = (): void => {
    this.setState({ divClass: 'filter-panel-left' });
    messageService.sendMessage({ shortCutPaneExpanded: true });
  };
}

export default withTranslation()(AddressBookShortCutPane);

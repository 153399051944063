import React, {useEffect, useState} from 'react';
import {FontIcon, IStackTokens, mergeStyleSets, PrimaryButton, registerIcons, Stack, TextField} from '@fluentui/react';
import i18n from '../../../../i18n';
import {SvgIcons} from '../../../shared/svgIcons/svgIcons';
import {useDispatch, useSelector} from 'react-redux';
import {uPrinceTheme} from '../../../../theme';
import MailTemplate from '../../lotInvitation/template/component';
import {saveLotInvitationStateAttr, subscribeLot} from '../../../reducers/lotInvitationReducer';
import {getThemeColor} from '../../../reducers/uprinceReducer';
import {DefaultButton} from '@fluentui/react/lib/Button';

registerIcons(SvgIcons);

const stackTokens: IStackTokens = {childrenGap: 40};

const styles = {
    logo: {
        marginTop: '20px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
};

const classNames = mergeStyleSets({
    body: {
        hight: '100%',
        display: 'block',
        marginLeft: '20%',
        marginRight: '20%',
        backgroundColor: 'white',
        padding: '40px',
        minHeight: 'calc(100vh - 120px)',
    },
    spacing: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    bmLogo: {
        display: 'flex',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        border: '0',
        borderSpacing: '0',
    },
    content: {
        padding: '0 62px 0px 160px',
    },
    boldFont: {
        margin: '0 0 25px 0',
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'Arial,sans-serif',
        color: uPrinceTheme.palette.themePrimary,
        fontWeight: 'bold',
    },
    detailFont: {
        margin: '0 0 25px 0',
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'Arial,sans-serif',
    },
    h3: {
        margin: '0 0 25px 0',
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'Arial,sans-serif',
    },
    icon: {
        padding: '0',
        verticalAlign: 'top',
        color: uPrinceTheme.palette.themePrimary,
    },
    tdIcon: {
        width: '40px',
        padding: '0,0,1px,0',
        verticalAlign: 'top',
        color: uPrinceTheme.palette.neutralPrimary,
    },
    justifyCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
});

const SubcribeContentBody = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [comment, setComment] = React.useState<string>("");

    const [isUnsubscribe, setIsUnsubscribe] = useState<boolean>(false);

    const {
        subscribeFormData,
        subscribeResult,
        invitationId,
    }: any = useSelector(
        (state: any) => state.lotInvitation,
    );

    useEffect(() => {
        if (!localStorage.getItem('themeColor')) {
            Promise.all([
                dispatch(getThemeColor())
            ]).then(() => {
                window.location.reload();
            });
        }
        ;

        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
        }, 2000)

        return () => {
            dispatch(saveLotInvitationStateAttr('subscribeResult', null));
        }
    }, []);

    const handleSubcribe = () => {
        let items: any = {
            lotId: subscribeFormData?.id ? subscribeFormData?.id : null,
            invitationId: invitationId,
            approve: '1',
        };

        dispatch(subscribeLot(items));
    };
    const handleNotSubcribe = () => {
        setIsUnsubscribe((prevState: boolean) => !prevState);
    };
    const handleProceed = () => {
        let items: any = {
            lotId: subscribeFormData?.id ? subscribeFormData?.id : null,
            invitationId: invitationId,
            approve: '1',
            isNotSubscribe: true,
            comment: comment,
        };

        Promise.all([
            dispatch(subscribeLot(items))
        ]).then((res: any) => {
            if (res[0].payload.data.result) {
                setIsUnsubscribe((prevState: boolean) => !prevState);
            }
        })
    };

    const renderBody = () => {
        if (subscribeFormData?.isSubscribed) {
            return <h2 style={{marginTop: '60px'}}
                       className={`${classNames.justifyCenter} mail-template-fonts`}>{i18n.t('alreadySubscribed')}!</h2>
        } else if (subscribeFormData?.isNotSubscribe) {
            return <h2 style={{marginTop: '60px'}}
                       className={`${classNames.justifyCenter} mail-template-fonts`}>{i18n.t('alreadyResponded')}!</h2>
        } else {
            return <div>
                <div className={classNames.spacing}>
                    <Stack horizontal tokens={stackTokens} className={classNames.spacing}
                           style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <MailTemplate formData={subscribeFormData}/>
                    </Stack>
                </div>
                {!isUnsubscribe
                    ? <Stack horizontal tokens={stackTokens} className={classNames.spacing}
                             style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <PrimaryButton
                            text={i18n.t('subcribe')}
                            onClick={() => {
                                handleSubcribe()
                            }}
                            allowDisabledFocus
                            disabled={!!subscribeResult}
                            style={{width: '200px', height: '50px', borderRadius: '5px'}}
                        />

                        <DefaultButton
                            text={i18n.t('notSubscribe')}
                            onClick={() => {
                                handleNotSubcribe()
                            }}
                            allowDisabledFocus
                            disabled={!!subscribeResult}
                            style={{width: '200px', height: '50px', borderRadius: '5px'}}
                        />
                    </Stack>
                    : <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1vw',
                        alignItems: 'flex-end',
                        margin: '0 5vw'
                    }}>
                        <TextField
                            label={i18n.t('comments')}
                            placeholder={`(${i18n.t('optional')})`}
                            onChange={(event, value) => {
                                if (value) {
                                    setComment(value);
                                } else {
                                    setComment('');
                                }
                            }}
                            multiline={true}
                            resizable={false}
                            autoAdjustHeight={true}
                            styles={{root: {width: '100%'}}}
                        />
                        <PrimaryButton
                            text={i18n.t('proceed')}
                            onClick={() => {
                                handleProceed()
                            }}
                            allowDisabledFocus
                            disabled={!!subscribeResult}
                            style={{width: '200px', height: '50px', borderRadius: '5px'}}
                        />
                    </div>
                }
            </div>
        }
    }

    return (
        <div>
            {isLoading ? <div></div> : <div className={classNames.body}>
                <div className={classNames.bmLogo}>
                    <FontIcon
                        iconName={'bm-engineering-invitation-logo'}
                        className="ms-Icon"
                        style={styles.logo}
                    />
                </div>
                {renderBody()}
            </div>}
        </div>
    );
};

export default SubcribeContentBody;

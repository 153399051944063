import * as React from "react";
import {
  AnimationClassNames,
  DefaultPalette,
  IconButton,
  initializeIcons,
  IStackItemStyles,
  IStackStyles,
  Stack
} from "@fluentui/react";

initializeIcons();

export class Accordion extends React.Component<
  IAccordionProps & { gap: string | number; backgroundColor: string },
  {}
> {
  private accordionHeader: IStackItemStyles = {
    root: {
      background: this.props.backgroundColor,
      padding: 5,
      cursor: "pointer",
    },
  };
  private accordion: IStackStyles = {
    root: {
      width: '100%',
      borderWidth: 1,
      marginBottom: this.props.gap,
      borderColor: DefaultPalette.neutralTertiary,
      boxShadow: '0px 1.2px 3.6px rgb(0 0 0 / 10%), 0px 6.4px 14.4px rgb(0 0 0 / 13%)',
      borderRadius: 2,
    },
  };
  private accordionConent: IStackStyles = {
    root: {
      padding: 0,
    },
  };

  constructor(props: IAccordionProps & { gap: string | number; backgroundColor: string }, public state: any) {
    super(props);
    this.state = {
      content: this.props.isExpaned,
    };
  }

  private showContent = () => {
    this.props.onChange(this.props.accordianId, !this.state.content);
    this.setState({
      content: !this.state.content,
    });
  };

  public componentDidMount() { }

  public UNSAFE_componentWillReceiveProps(nextProps: IAccordionProps) {
    this.setState({ content: nextProps.isExpaned });
  }

  public render(): React.ReactElement<IAccordionProps> {
    return (
      <>
        <Stack horizontal={false} styles={this.accordion}>
          <Stack.Item styles={this.accordionHeader}>
            <Stack horizontal={true} onClick={this.showContent}>
              <Stack.Item>
                <IconButton
                  iconProps={{
                    iconName: this.state.content ? "ChevronDown" : "ChevronRight",
                  }}
                />
              </Stack.Item>
              <Stack.Item align="center">{this.props.header}</Stack.Item>
            </Stack>
          </Stack.Item>
          {this.state.content && (
            <Stack.Item className={AnimationClassNames.slideDownIn20} styles={this.accordionConent}>
              {this.props.children}
            </Stack.Item>
          )}
        </Stack>
      </>
    );
  }
}

interface IAccordionProps {
  header: string;
  accordianId: string;
  isExpaned: boolean;
  onChange: (accordianId: string, isExpanded: boolean) => void;
  children: any;
}

interface IAccordionGroupProps {
  items: Accordion[];
}
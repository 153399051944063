import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DirectionalHint, Label, Link, mergeStyleSets, Stack, TooltipHost } from '@fluentui/react';
import { getContractingUnit, getProject } from '../../../shared/util';
import history from '../../../history';
import MenuItemList from './menuList';
import {
  AppsList20Regular,
  ArrowSort24Regular,
  Calendar24Regular,
  Clover20Regular,
  Document20Regular,
  Mail20Regular,
  PeopleList24Regular,
  Status24Filled,
  Timer20Regular,
  Warning20Regular,
} from '@fluentui/react-icons';
import { WBS_TASK_GROUP_BY, WBS_TASK_PEOPLE, WBS_TASK_SHOWN_BY, WBS_TASK_SORT_BY } from '../../../types/wbs';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '@fluentui/react-components';
import i18n from 'i18next';
import { saveWbsTaskStateAttr } from '../../../reducers/wbsTaskReducer';

const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 14vh)!important',
    position: 'relative',
  },
  active: {
    fontWeight: 600,
    color: '#424242!important',
  },
  inactive: {
    color: '#424242!important',
  },
  disabled: {
    color: '#a19f9d!important',
  },
});

const filterItems: any = [
  {
    key: 'wbs',
    id: 'wbs',
    type: 'wbs',
    name: i18n.t('WBS'),
    value: null,
    subItem: false,
    icon: 'ms-Icon--Move',
  },
  {
    key: 'task',
    id: 'task',
    type: 'wbs',
    name: i18n.t('task'),
    value: null,
    subItem: false,
    icon: 'ms-Icon--Stopwatch',
  },
  {
    key: 'mail',
    id: 'mail',
    type: 'wbs',
    name: i18n.t('mail'),
    value: null,
    subItem: false,
    icon: 'ms-Icon--Mail',
    disabled: true,
  },
  {
    key: 'document',
    id: 'document',
    type: 'wbs',
    name: i18n.t('document'),
    value: null,
    subItem: false,
    icon: 'ms-Icon--Document',
    disabled: true,
  },
  {
    key: 'issue',
    id: 'issue',
    type: 'wbs',
    name: i18n.t('issue'),
    value: null,
    subItem: false,
    disabled: false,
  },
];

const WbsShortcutPane = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const wbsTaskDropdowns = useSelector((state: any) => state.wbsTask.wbsTaskDropdowns);
  const wbsActiveSelection = useSelector((state: any) => state.wbsTask.wbsActiveSelection);

  const [currentActiveSection, setCurrentActiveSection] = useState<any>();
  const [divClass, setDivClass] = useState<any>('filter-panel-left');

  useEffect(() => {
    if (wbsActiveSelection?.id != currentActiveSection?.id) {
      const setInitialActiveSection = async () => {
        setCurrentActiveSection(wbsActiveSelection);
      };

      setInitialActiveSection().then();
    }

    return () => {
      dispatch(saveWbsTaskStateAttr('wbsActiveSelection', filterItems[0]));
      console.log('unmount wbs shortcut pane');
    };
  }, []);

  const setFilterOption = (selectedItem: any) => {
    dispatch(saveWbsTaskStateAttr('wbsActiveSelection', selectedItem));

    switch (selectedItem?.key) {
      case 'task':
        history.push({
          pathname: `/CU/${getContractingUnit()}/project/${getProject()}/wbs/task`,
          state: { groupBy: null, people: null },
        });
        dispatch(saveWbsTaskStateAttr('wbsTaskGroupFilters', { name:null }));
        break;

      case 'wbs':
        history.push({
          pathname: `/CU/${getContractingUnit()}/project/${getProject()}/wbs`,
        });
        break;

      case 'issue':
        history.push({
          pathname: `/CU/${getContractingUnit()}/project/${getProject()}/wbs/issue`,
        });
        break;

      default:
        history.push({
          pathname: `/CU/${getContractingUnit()}/project/${getProject()}/wbs`,
        });
    }
  };

  const toggleOverlay = () => {
    setDivClass('filter-panel-left panel-move');
  };

  const toggleOverlayFwd = () => {
    setDivClass('filter-panel-left');
  };

  const onMenuItemClick = (groupBy: any) => {
    history.push({
      pathname: `/CU/${getContractingUnit()}/project/${getProject()}/wbs/task`,
      state: { groupBy },
    });
  };

  const onSortMenuItemClick = (sortBy: any) => {
    history.push({
      pathname: `/CU/${getContractingUnit()}/project/${getProject()}/wbs/task`,
      state: { sortBy },
    });
  };
  const onShownByMenuItemClick = (shownBy: any) => {
    history.push({
      pathname: `/CU/${getContractingUnit()}/project/${getProject()}/wbs/task`,
      state: { shownBy },
    });
  };
  const onPeopleMenuItemClick = (people: any) => {
    history.push({
      pathname: `/CU/${getContractingUnit()}/project/${getProject()}/wbs/task`,
      state: { people },
    });
  };

  const onStatusMenuItemClick = (statusId: any) => {
    history.push({
      pathname: `/CU/${getContractingUnit()}/project/${getProject()}/wbs/task`,
      state: { statusId },
    });
  };
  const onDueDateMenuItemClick = (deliveryStatusId: any) => {
    history.push({
      pathname: `/CU/${getContractingUnit()}/project/${getProject()}/wbs/task`,
      state: { deliveryStatusId },
    });
  };

  const renderFilterIcon = (key: string) => {
    switch (key) {
      case 'task':
        return <Timer20Regular />;
      case 'wbs':
        return <Clover20Regular />;
      case 'mail':
        return <Mail20Regular />;
      case 'document':
        return <Document20Regular />;
      case 'issue':
        return <Warning20Regular />;
      default:
        return <AppsList20Regular />;
    }
  };

  const shortcutPaneItemClass = (item: any) => {
    if (item.disabled) {
      return classNames.disabled;
    } else if (currentActiveSection?.id === item?.id) {
      return classNames.active;
    } else {
      return classNames.inactive;
    }
  };

  const renderFilterItems = (filters: any) => {
    if (filters) {
      return filters.map((item: any, index: number) => {
        let divider = false;
        if (index > 0) {
          const previousItem: any = filters[index - 1];
          divider = previousItem.type !== item.type;
        } else {
          divider = true;
        }

        return (
          <div key={item.id + 'div'}
               style={{ marginLeft: item?.subItem ? divClass === 'filter-panel-left' ? 20 : 0 : 0 }}>
            {divider && (
              <hr
                key={item.id + 'hr'}
                style={{ marginBottom: 15, borderTop: '1px solid #d2d2d2' }}
              />
            )}
            <Link
              key={item.id + 'link'}
              className={shortcutPaneItemClass(item)}
              href='#'
              onClick={() => setFilterOption(item)}
              disabled={item.disabled}
            >
              <TooltipHost
                key={item.id + 't'}
                content={item.name}
                id='filter-opt-1'
                calloutProps={{ gapSpace: 0 }}
                directionalHint={DirectionalHint.rightCenter}
              >
                {renderFilterIcon(item.key)}
              </TooltipHost>
              {renderFilterIcon(item.key)}
              <Text key={item.id} style={{ paddingLeft: 8, fontWeight: 'inherit' }}>{item.name} </Text>
            </Link>
          </div>
        );
      });
    }
    return <div></div>;
  };

  const groupByMenuList = [
    { key: WBS_TASK_GROUP_BY.NO_GROUPING, text: t('noGrouping') },
    { key: WBS_TASK_GROUP_BY.DUE_DATE, text: t('dueDate') },
    { key: WBS_TASK_GROUP_BY.TAG, text: t('tag') },
  ];
  const sortByMenuList = [
    // { key: WBS_TASK_SORT_BY.MANUAL, text: t('manual') },
    { key: WBS_TASK_SORT_BY.CREATION_DATE, text: t('creationDate') },
    // {key:WBS_TASK_SORT_BY.COMPLETION_DATE,text:t('completionDate')},
    { key: WBS_TASK_SORT_BY.DUE_DATE, text: t('dueDate') },
    { key: WBS_TASK_SORT_BY.STARRED, text: t('starred') },
  ];
  const shownByMenuList = [
    { key: WBS_TASK_SHOWN_BY.OPEN_TASK, text: t('openTasks') },
    { key: WBS_TASK_SHOWN_BY.COMPLETED_TASK, text: t('completedTask') },
  ];
  const peopleMenuList = [
    { key: WBS_TASK_PEOPLE.MY_TASKS, text: t('myTasks') },
    { key: WBS_TASK_PEOPLE.OTHER_TASKS, text: t('otherTasks') },
  ];

  const getStatusList = () => {
    if (wbsTaskDropdowns && wbsTaskDropdowns?.status) {
      return wbsTaskDropdowns?.status;
    }
    return [];
  };
  const getDueDateList = () => {
    if (wbsTaskDropdowns && wbsTaskDropdowns?.deliveryStatus) {
      return wbsTaskDropdowns?.deliveryStatus;
    }
    return [];
  };

  return (
    <>
      <div className={`${divClass} ${classNames.wrapper}`}>
        <div className='inner-container'>
          <i
            onClick={toggleOverlay}
            className='ms-Icon ms-Icon--Back back-arrow'
            aria-hidden='true'
          ></i>
          <i
            onClick={toggleOverlayFwd}
            className='ms-Icon ms-Icon--Forward fwd-arrow'
            aria-hidden='true'
          ></i>

          <div className='filter-option-list' style={{}}>
            <Stack
              gap={10}
              verticalFill
              styles={{
                root: {
                  width: '100%',
                  verticalAlign: 'center',
                },
              }}
            >
              {renderFilterItems(filterItems)}
            </Stack>
          </div>

          <div className='filter-option-list' style={{}}>
            <hr
              key={'hr12'}
              style={{ marginBottom: 15, borderTop: '1px solid #d2d2d2' }}
            />
            <div className='filter-data'>
              <Label>{`${t('filters')}`}</Label>
            </div>

            <Stack
              gap={10}
              verticalFill
              styles={{
                root: {
                  width: '100%',
                  verticalAlign: 'center',
                },
              }}
            >
              <MenuItemList icon={<Status24Filled />} title={t('status')} onMenuItemClick={onStatusMenuItemClick}
                            menuList={getStatusList()} />
              <MenuItemList icon={<Calendar24Regular />} title={t('dueDate')} onMenuItemClick={onDueDateMenuItemClick}
                            menuList={getDueDateList()} />
              <MenuItemList icon={<ArrowSort24Regular />} title={t('shownBy')} onMenuItemClick={onShownByMenuItemClick}
                            menuList={shownByMenuList} />
              <MenuItemList icon={<PeopleList24Regular />} title={t('people')} onMenuItemClick={onPeopleMenuItemClick}
                            menuList={peopleMenuList} />
            </Stack>
          </div>

          <div className='filter-option-list' style={{}}>
            <hr
              key={'hr12'}
              style={{ marginBottom: 15, borderTop: '1px solid #d2d2d2' }}
            />
            <div className='filter-data'>
              <Label>{`${t('groupBy')}`}</Label>
            </div>

            <Stack
              gap={10}
              verticalFill
              styles={{
                root: {
                  width: '100%',
                  verticalAlign: 'center',
                },
              }}
            >
              <MenuItemList title={t('groupBy')} onMenuItemClick={onMenuItemClick} menuList={groupByMenuList} />
              <MenuItemList icon={<ArrowSort24Regular />} title={t('sortBy')} onMenuItemClick={onSortMenuItemClick}
                            menuList={sortByMenuList} />
            </Stack>
          </div>

        </div>
      </div>
    </>
  );
};

export default WbsShortcutPane;

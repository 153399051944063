import React from 'react';
import { ProgressIndicator } from '@fluentui/react';

const progressIndicatorStyles = {
  root: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemProgress: {
    paddingTop: 0,
    paddingBottom: 0,
  },
};
const ProgressBar = (props: {
  show: boolean;
}) => {

  return <div className={'progress-bar-pane'}>
    {props.show && <ProgressIndicator styles={progressIndicatorStyles} barHeight={4}/>}
  </div>;
};
export default ProgressBar;

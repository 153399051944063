import { TextField } from '@fluentui/react/lib/TextField';
import * as React from 'react';
import NumberFormat from 'react-number-format';


const CustomInput = (inputProps: any) => {
  const handleFocus = (event: { target: { select: () => any; }; }) => event.target.select();
  return <TextField type="number" {...inputProps} onFocus={handleFocus}/>;
};

const ReadOnlyCustomInput = (inputProps: any) => {
  const handleFocus = (event: { target: { select: () => any; }; }) => event.target.select();
  return <TextField type="number" {...inputProps} borderless onFocus={handleFocus} readOnly/>;
};

const getDecimalSeparator = () => {
  const numberWithDecimalSeparator = 1.1;
  const formatter = new Intl.NumberFormat(window.navigator.language);
  if (formatter !== undefined) {
    return formatter.formatToParts(numberWithDecimalSeparator).find(part => part.type === 'decimal')?.value;
  }
  return '.';
};


const DefaultNumberFormat = (
  props: {
    placeholder?: string,
    value: string | number | undefined,
    onChange: (value: any) => void,
    label?: string,
    errorMessage?: string,
    required?: boolean,
    disabled?: boolean,
    readOnly?: boolean,
    prefix?: string
  },
) => {
  return (
    <NumberFormat
      value={props.value}
      label={props.label}
      onValueChange={({ value: v }) => props.onChange(v)}
      style={{ textAlign: 'right' }}
      errorMessage={props.errorMessage ? props.errorMessage : ''}
      isNumericString={true}
      thousandSeparator={getDecimalSeparator() === ',' ? '.' : ','}
      decimalSeparator={getDecimalSeparator()}
      decimalScale={2}
      fixedDecimalScale={true}
      disabled={props.disabled}
      required={props.required}
      prefix={props.prefix && props.prefix}
      customInput={
        !props.readOnly ? CustomInput : ReadOnlyCustomInput
      }/>
  );
};


export default DefaultNumberFormat;

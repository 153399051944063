import {getTheme, ITheme, Panel, PanelType} from '@fluentui/react';
import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import SplitPane, {Pane} from 'react-split-pane';
import history from '../../history';
import {messageService} from '../../services/messageService';
import ConfirmationDialog from '../../shared/confirmationDialog/confirmationDialog';
import {getContractingUnit, getProject} from '../../shared/util';
import '../style/style.css';
import CommandBarLeft from './commandBar/commandBarLeft';
import CommandBarRight from './commandBar/commandBarRight';
import DocumentPane from './documentPane/container';
import DocumentPaneSection from '../../shared/mainUI/documentPaneSection/documentPaneSection';
import withMainUI from '../../shared/mainUI/withMainUI';
import ListPane from './listPane/container';
import ListPaneSection from '../../shared/mainUI/listPaneSection/component';
import ShortCutPane from './shortCutPane/container';
import {
  clearFormData,
  filterInstructionRegisterListPaneData,
  readByInstructionRegisterId,
  readInstructionRegisterDropDownData,
} from '../../reducers/instructionRegisterReducer';
import {
  formData,
  InstructionRegister,
  InstructionRegisterListItem,
  InstructionRegisterListPaneFilter,
  InstructionRegisterShortCutPaneItem,
} from '../../types/instructionRegister';
import {BookMarkItem} from '../../types/template';
import i18n from '../../../i18n';
import {resizerStyle} from '../../shared/customStyle/commonStyles';

export interface State {
  selectedShortCutItem: InstructionRegisterShortCutPaneItem;
  filter: InstructionRegisterListPaneFilter;
  formData: InstructionRegister;
  loading: boolean;
  showPanel: boolean;
  width: number;
  activeClass: string;
  bookmarkDivClass: string;
  showDetails: boolean;
  autoSave: boolean;
  isEdit: boolean;
  isDisableNewButton: boolean;
  isDrag: boolean;
  resetLayout: boolean;
  collapseBookmarkPane: boolean;
  reSizer: number;
  projectId: null | string;
}

interface Props extends WithTranslation {
  match: any;
  resetFormData: () => void;
  showProgressBar: boolean;
  shortCutPaneFilters: InstructionRegisterShortCutPaneItem[];
  formData: any;
  setUid: () => void;
  clearUid: () => void;
  resetIsChange: () => void;
  uid: string | null;
  autoSave: boolean;
  isChange: boolean;
  isEdit: boolean;
  isDataLoaded?: boolean;
  loadMsg: string;
  location: any;
  loading: boolean;
  handleShowDocumentPane: (collapseBookmarkPane?: boolean) => void;
  reSizer: number;
  showDetails: boolean;
  showPanel: boolean;
  handleScroll: () => void;
  currentActiveSection: string;
  animation: any;
  readInstructionRegisterShortCutPaneData: () => void;
  filterInstructionRegisterListPaneData: (
    filter: InstructionRegisterListPaneFilter
  ) => void;
  readInstructionRegisterDropDownData: () => void;
  readByInstructionRegisterId: (id: string) => void;
  clearFormData: () => void;
  shortcutPaneData: InstructionRegisterShortCutPaneItem[];

  irList: InstructionRegisterListItem[];
  isIRListLoaded: boolean;
  irDropDowns: any;
  reloadListPane: boolean;
  collapseBookmarkPane: boolean;
}

class InstructionRegisterMainLayout extends React.Component<Props, State> {
  _isMounted = false;
  theme: ITheme = getTheme();
  subscription: any;

  bookmarkList: BookMarkItem[] = [
    {
      id: 1,
      link: 'header',
      label: this.props.t('instructionHeader'),
    },
    {
      id: 2,
      link: 'instruction-links',
      label: this.props.t('links'),
    },
    // {
    //   id: 3,
    //   link: 'history',
    //   label: this.props.t('History'),
    // },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      filter: new InstructionRegisterListPaneFilter(),
      selectedShortCutItem: {
        key: null,
        id: null,
        name: null,
        type: null,
        value: null,
      },
      isEdit: false,
      autoSave: false,
      loading: false,
      width: window.innerWidth,
      showPanel: false,
      activeClass: 'grid-righter column-filter-activated',
      bookmarkDivClass: 'bookmark-panel parent',
      showDetails: false,
      formData: formData,
      isDisableNewButton: false,
      isDrag: false,
      resetLayout: false,
      collapseBookmarkPane: false,
      reSizer: screen.width,
      projectId: null,
    };
  }

  componentDidMount() {
    this.handleUrlChange();
    this.props.readInstructionRegisterDropDownData();
    // this.props.readInstructionRegisterShortCutPaneData();
    this.props.filterInstructionRegisterListPaneData(
      new InstructionRegisterListPaneFilter()
    );

    if (
      location.pathname.split('/').pop() === 'new' &&
      this.state.formData &&
      this.state.formData.id === null
    ) {
      this.createNew();
    }
    this.setState(
      {
        formData: this.props.formData,
      },
      this.props.handleShowDocumentPane
    );
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.hideDocumentPane) {
          this.hideDocumentPane();
        }

        if (data.data.isNewIR) {
          this.createNew();
        }

        if (data.data.collapseDocumentPane) {
          this.setState({
            reSizer: this.props.reSizer === 300 ? screen.width / 2 : 300,
            isDrag: !this.state.isDrag,
            collapseBookmarkPane: !this.state.collapseBookmarkPane,
            resetLayout: !this.state.resetLayout,
          });
        }

        if (
          data &&
          data.data &&
          data.data.hasOwnProperty('isLoadingPMOLData') &&
          data.data.isLoadingPMOLData
        ) {
          this.setState({ isDisableNewButton: true });
        } else if (
          data.data.hasOwnProperty('isLoadingPMOLData') &&
          !data.data.isLoadingPMOLData
        ) {
          this.setState({ isDisableNewButton: false });
        }
      }
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.reloadListPane !== prevProps.reloadListPane &&
      this.props.reloadListPane
    ) {
      this.reloadListPaneItem();
    }
    if (this.props.match.url !== prevProps.match.url) {
      this.handleUrlChange(prevState.collapseBookmarkPane);
    }
    if (this.props.formData !== prevProps.formData) {
      this.setState({
        formData: this.props.formData,
      });
    }
    if (
      this.props.location &&
      this.props.location.state &&
      this.state.filter != this.props.location.state.irFilter
    ) {
      if (this.props.location.state.irFilter) {
        this.setState(
          {
            filter: this.props.location.state.irFilter,
          },
          this.reloadListPaneItem
        );
      }
    }
    if (this.props.collapseBookmarkPane !== prevProps.collapseBookmarkPane) {
      this.setState({
        collapseBookmarkPane: this.props.collapseBookmarkPane,
      });
    }
  }

  handleUrlChange(collapseBookmarkPane?: boolean) {
    let id = this.props.match.params.featureId
      ? this.props.match.params.featureId
      : null;
    let isNew = location.pathname.split('/').pop() === 'new';

    if (id && id !== 'new') {
      this.setState(
        {
          isEdit: !!this.props.match.params.featureId,
          isDisableNewButton: id ? true : false,
        },
        () => {
          this.props.handleShowDocumentPane(collapseBookmarkPane);
        }
      );
      this.props.readByInstructionRegisterId(id);
    } else {
      this.setState(
        {
          isEdit: false,
          isDisableNewButton: false,
        },
        () => {
          this.props.handleShowDocumentPane();
        }
      );
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  // 2. clear states in this method and save to state
  // 3. update redirect url
  createNew = (): void => {
    this.props.clearFormData();
    //this.props.setUid();
    let formData: any = {
      title: null,
      pbsInstructionFamily: [],
      pbsInstructionLink: [],

      // ....
    };
    this.setState({
      formData: formData,
    });
    history.push(
      `/CU/${getContractingUnit()}/project/${getProject()}/instruction-register/new`
    );
  };

  reloadListPaneItem = () => {
    //
    //this.props.resetIsChange();
    setTimeout(() => {
      this.props.filterInstructionRegisterListPaneData(this.state.filter);
    }, 100);
  };

  renderShortCutPane = () => {
    return (
      <ShortCutPane
        projectId={this.state.projectId}
        shortCutPaneFilters={this.props.shortcutPaneData}
        filter={this.state.filter}
        handelShortCutPaneFilter={(
          selectedItem: InstructionRegisterShortCutPaneItem
        ) => this.handelShortCutPaneFilter(selectedItem)}
        toggleOverlay={this.state.collapseBookmarkPane}
        resetLayout={this.state.resetLayout} isExpand={false} />
    );
  };

  getDocumentPaneView = () => {
    return (
      <DocumentPaneSection
        collapseBookmarkPane={this.state.collapseBookmarkPane}
        showProgress={this.props.showProgressBar}
        handleScroll={this.props.handleScroll}
        isEdit={this.props.isEdit}
        formData={this.state.formData}
        bookmarkList={this.bookmarkList}
        currentActiveSection={this.props.currentActiveSection}
        renderCommandBar={() => <CommandBarRight />}
      >
        <DocumentPane formData={this.props.formData}
          isNewIR={false}
        />
      </DocumentPaneSection>
    );
  };

  private getListPaneView = () => {
    return (
      <ListPaneSection
        activeClass={this.state.activeClass}
        titlePaneHeader={
          this.state.selectedShortCutItem &&
            this.state.selectedShortCutItem.name
            ? this.state.selectedShortCutItem.name!!
            : i18n.t('all')
        }
        showProgress={this.props.showProgressBar}
        renderCommandBar={() => <CommandBarLeft disable={false} />}
        renderShotcutPane={() => this.renderShortCutPane()}
      >
        <ListPane
          projectId={this.state.projectId}
          loadMsg={this.props.loadMsg}
          reloadListPaneItem={() => this.reloadListPaneItem()}
          filter={this.state.filter}
          listPaneItems={this.props.irList}
          isDataLoaded={this.props.isIRListLoaded}
          isChange={this.props.isChange}
          formData={this.state.formData}
          handelFilter={(filter: InstructionRegisterListPaneFilter) =>
            this.handleFilter(filter)
          }
          handleSelectedListPaneItem={(selectedListItemIds: [] | string[]) => {
            //this.handleSelectedListPaneItem(selectedListItemIds);
          }}
          reSizer={this.state.reSizer}
        />
      </ListPaneSection>
    );
  };

  handleFilter = (filter: InstructionRegisterListPaneFilter) => {
    if ((filter.title && filter.title.length >= 3) || filter.title === null) {
      this.setState(
        {
          filter: filter,
        },
        this.reloadListPaneItem
      );
    } else {
      this.setState({
        filter: filter,
      });
    }
  };

  handelShortCutPaneFilter = (
    selectedItem: InstructionRegisterShortCutPaneItem
  ) => {
    this.setState({
      selectedShortCutItem: selectedItem,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className={'ms-Grid'}>
          <SplitPane
            className={'SplitPane'}
            split="vertical"
            minSize={300}
            maxSize={screen.width / 2}
            defaultSize={
              this.props.showDetails ? screen.width / 2 : screen.width
            }
            resizerStyle={resizerStyle}
            style={{ height: 'calc(100vh - 129px)' }}
            size={this.props.reSizer}
            onDragStarted={() => {
              this.setState({
                isDrag: true,
                collapseBookmarkPane: true,
                // resetLayout:false,
              });
            }}
            onResizerDoubleClick={() => {
              this.setState({
                reSizer: this.props.reSizer === 300 ? screen.width / 2 : 300,
                isDrag: !this.state.isDrag,
                collapseBookmarkPane: !this.state.collapseBookmarkPane,
                resetLayout: !this.state.resetLayout,
              });
            }}
            onDragFinished={(newSize: number) => {
              this.setState({
                // isDrag:false,
                // resetLayout:false,
                reSizer: newSize,
                collapseBookmarkPane: newSize < screen.width / 2,
              });
            }}
            onChange={(newSize: number) => {
              if (newSize === screen.width / 2) {
                this.setState({
                  resetLayout: true,
                  collapseBookmarkPane: false,
                });
              }
            }}
          >
            <Pane className={'SplitPane'}>{this.getListPaneView()}</Pane>
            <Pane className={'SplitPane'}>
              {this.props.showDetails && (
                <div
                  className={`${this.props.animation(this.props.showDetails)}`}
                  style={{
                    display: this.props.showDetails ? 'block' : 'none',
                    transition: 'all 0.9s linear',
                  }}
                >
                  {!this.props.showPanel && (
                    <div>{this.getDocumentPaneView()}</div>
                  )}
                </div>
              )}
            </Pane>
          </SplitPane>
        </div>
        <Panel
          isOpen={this.props.showPanel}
          type={PanelType.smallFixedFar}
          onDismiss={this._hidePanel}
          isLightDismiss={true}
          headerText={t('corporateProductCatalogue')}
          className="custom-detail-panel"
          closeButtonAriaLabel={t('close')}
        >
          {this.getDocumentPaneView()}
        </Panel>

        <ConfirmationDialog
          hidden={true}
          title={t('areyousure')}
          subText={t('youwonTbeabletorevertthis')}
          onClickConfirmDialog={() => { }}
          onClickCancelDialog={() => { }}
          cancelButtonText={t('noIwonT')}
          confirmButtonText={t('yesDeleteit')}
        />
      </div>
    );
  }

  private _hidePanel = () => {
    this.setState({ showPanel: false, showDetails: false });
    history.push(`/project/${this.state.projectId}/instruction-register`);
  };

  private hideDocumentPane = () => {
    if (getContractingUnit()) {
      history.push(
        `/CU/${getContractingUnit()}/project/${getProject()}/instruction-register`
      );
    } else {
      history.push(`/project/${this.state.projectId}/instruction-register`);
    }
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    shortcutPaneData: state.ir?.irShortCutPaneData,
    isIRListLoaded: state.ir.isIRListLoaded,
    irList: state.ir.irList,
    irDropDowns: state.ir.irDropDowns,
    reloadListPane: state.ir.reloadListPane,
    formData: state.ir.formData,
    isEdit: state.ir.isEdit,
    showProgressBar: state.ir.showProgressBar,
  };
};

const mapDispatchToProps = {
  // readInstructionRegisterShortCutPaneData,
  filterInstructionRegisterListPaneData,
  readInstructionRegisterDropDownData,
  readByInstructionRegisterId,
  clearFormData,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(withMainUI(InstructionRegisterMainLayout)))
);

import {
  ActionButton,
  getTheme,
  Icon,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Separator,
  Stack,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { uPrinceTheme } from '../../../../theme';
import { CKEditorWrapper, DropDownAdaptater, TextFieldFF } from '../../../shared/fluentFinalForm';
import {
  InstructionRegister,
  InstructionRegisterDropDowns,
  pbsInstructionLink,
} from '../../../types/instructionRegister';
import HistoryBar from '../../../shared/historyBar/historyBar';
import { useTranslation } from 'react-i18next';

import DataGridWithModal, { ModalGridContext } from '../../../shared/dataGridModal/component';
import DocumentationModalContent from './modal/component';
import { v4 as uuid4 } from 'uuid';

const theme = getTheme();

const getIconName = (type: any) => {
  switch (type) {
    case 'PDF':
      return 'PDF';
    case 'Image':
      return 'FileImage';
    case 'URL':
      return 'Website';
    case 'Word Document':
      return 'WordDocument';
    case 'Excel Document':
      return 'ExcelDocument';
    case 'Power Point Document':
      return 'PowerPointDocument';
    default:
      return 'Website';
  }
};

const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };

const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  wrapper: {
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
});

const validate = (values: any) => {
  const errors: any = {};
  if (!values.name) {
    errors.name = 'Name Required';
  }

  if (!values.instructionType) {
    errors.instructionType = 'Type Required';
  }

  if (!values.pbsInstructionFamilyId) {
    errors.pbsInstructionFamilyId = 'Family Required';
  }
  return errors;
};

const DocumentPaneComponent = (props: {
  submit: any;
  dropDowns: InstructionRegisterDropDowns;
  saveFormData: any;
  createInstruction: any;
  uid: any;
  showProgress: boolean;
  formValues: any;
  formData: any;
  isExpand: boolean;
  setForm: (form: any) => void;
  isClear: boolean;
  hasChangeIntialFromData: boolean;
}) => {
  const [state, setstate] = useState<InstructionRegister | null>(null);
  const { t } = useTranslation();
  const onSubmit = async (values: any) => {
    props.saveFormData(values);
  };

  const formatInstructionFamily = (values: any) => {
    return values?.filter((item: any) => {
      return item.type === 'technical';
    });
  };

  const formatOtherInstructionFamily = (values: any) => {
    return values?.filter((item: any) => {
      return item.type === 'other';
    });
  };

  const getFileType = (type: any) => {
    let insLinkType = null;
    switch (type) {
      case 'FileImage':
        insLinkType = 'Image';
        break;
      case 'PDF':
        insLinkType = 'PDF';
        break;
      case 'WordDocument':
        insLinkType = 'Word Document';
        break;
      case 'ExcelDocument':
        insLinkType = 'Excel Document';
        break;
      case 'PowerPointDocument':
        insLinkType = 'Power Point Document';
        break;
      case 'Website':
        insLinkType = 'URL';
        break;
    }
    return insLinkType;
  };

  const instructionLinkColumns = [
    {
      key: 'column1',
      name: t('title'),
      fieldName: 'title',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('type'),
      fieldName: 'type',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: pbsInstructionLink) => {
        return (
          <div>
            <span>{getFileType(item.type)}</span>
          </div>
        );
      },
    },
    {
      key: 'column3',
      name: t('links'),
      fieldName: 'link',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: pbsInstructionLink) => {
        return (
          <div>
            <Icon
              style={{
                cursor: 'pointer',
                color: uPrinceTheme.palette.themePrimary,
              }}
              iconName={getIconName(getFileType(item.type))}
              onClick={(event) => {
                event.preventDefault();
                window.open(item.link!!);
              }}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setstate(props.formData);
  }, [props.formData]);

  const getInitialFormValues = (formData: any, formValue: any) => {
    if (location.pathname.split('/').pop() === 'new' && props.isClear) {
      return {
        pbsInstructionLink: [],
      };
    } else if (formData && formData?.id && !props.hasChangeIntialFromData) {
      return {
        headerTitle: formData?.headerTitle,
        id: formData?.id,
        instructionType: formData?.instructionType,
        instructionsDetails: formData?.instructionsDetails,
        isDeleted: formData?.isDeleted,
        isSaved: formData?.isSaved,
        name: formData?.name,

        pbsInstructionFamilyId: formData?.pbsInstructionFamily?.id,
        pbsInstructionFamilyName: formData?.pbsInstructionFamilyName,

        pbsInstructionLink: formatInstructionLinks(formData?.pbsInstructionLink),

        pbsProduct: formData?.pbsProduct,
        pbsProductId: formatPbsProductId(formData?.pbsProduct),
        sequenceCode: formData?.sequenceCode,
        title: formData?.title,
      };
    }
    return formValue;
  };

  const formatInstructionLinks = (instructionLinksArray: any) => {
    if (instructionLinksArray) {
      return instructionLinksArray.map((item: any) => {
        return {
          id: item.id,
          title: item.title,
          value: item.link,
          link: item.link,
          pbsInstructionId: item.pbsInstructionId,
          type: item.type,
        };
      });
    }
    return [];
  };

  const formatPbsProductId = (response: any) => {
    if (response) {
      return response.id;
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <div className='proj-detail-block'>
        <Form
          onSubmit={onSubmit}
          initialValues={getInitialFormValues(props.formData, props.formValues)}
          mutators={{
            setHeaderDocs: (args, state, utils) => {
              const field = state.fields['files'];
              field.change(args[0]);
            },
          }}
          validate={validate}
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            props.submit(handleSubmit);
            props.setForm(form);

            let headerFiles = values.header?.files;
            return (
              <form onSubmit={handleSubmit} noValidate>
                <div className='document-pane-card'>
                  <div className={'card-header'}>
                    <Link href='#' id='header'>
                      <Label>1. {t('instructionHeader')}</Label>
                    </Link>
                  </div>
                  <Separator />

                  {state?.sequenceCode && (
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          {<Field
                            name='title'
                            component={TextFieldFF}
                            lable={t('instructionTitle')}
                            disabled={true}
                          />}
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name='sequenceCode'
                            component={TextFieldFF}
                            lable={t('instructionID')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>
                  )}

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name='name'
                          component={TextFieldFF}
                          lable={t('instructionName')}
                          required={true}
                        />
                      </div>
                    </Stack.Item>

                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name='instructionType'
                          component={DropDownAdaptater}
                          options={[
                            { key: '100', text: t('technical') },
                            { key: '200', text: t('environment') },
                            { key: '300', text: t('safety') },
                            { key: '400', text: t('health') },
                          ]}
                          lable={t('instructionType')}
                          // placeholder="Technical"`
                          disabled={false}
                          required={true}
                          selectedKey={values.instructionType}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.halfWidth}`}>
                        <Field
                          name='pbsInstructionFamilyId'
                          component={DropDownAdaptater}
                          options={(values.instructionType === '100') ? formatInstructionFamily(props.dropDowns.pbsInstructionFamily) : formatOtherInstructionFamily(props.dropDowns.pbsInstructionFamily)}
                          lable={t('instructionFamily')}
                          // placeholder="Installation"
                          disabled={false}
                          required={true}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>
                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Separator />

                    <div className={`${classNames.fullWidth}`}>
                      <Label>{t('instructionDetails')}</Label>

                      <Field
                        name='instructionsDetails'
                        component={CKEditorWrapper}
                        lable={t('instructionDetails')}
                        placeholder='Instruction Details'
                        // disabled={false}
                      />
                    </div>
                  </Stack>

                  <div className={'card-footer'}>
                    <Separator />
                    <Stack
                      styles={stackStyles}
                      tokens={itemAlignmentsStackTokens}
                    >
                      <Stack.Item align='end' styles={stackFooterBarItemStyles}>
                        <ActionButton
                          className={classNames.actionButton}
                          iconProps={{ iconName: 'Save' }}
                          allowDisabledFocus
                          onClick={() => {
                            form.submit();
                          }}
                        >
                          <Label className={classNames.actionButton}>
                            {t('save')}
                          </Label>
                        </ActionButton>
                      </Stack.Item>
                    </Stack>
                  </div>
                </div>

                <div className='document-pane-card'>
                  <div className={'card-header'}>
                    <Link href='#' id='instruction-links'>
                      <Label>2. {t('links')}</Label>
                    </Link>
                  </div>
                  <Field name='pbsInstructionLink'>
                    {({ input }) => (
                      <DataGridWithModal
                        dataList={
                          values?.pbsInstructionLink && Array.isArray(values.pbsInstructionLink) ? values.pbsInstructionLink : []
                        }
                        // TODO: Change title according to Edit or Add
                        modalTitle={true ? t('uploadInstruction') : t('editInstruction')}
                        deleteDataGrid={async (selection: any) => {
                          const selectedList = selection.map((item: any) => {
                            return item;
                          });
                          const currentList: any[] = values.pbsInstructionLink;

                          const filtered: any[] = currentList.filter((e: any) => {
                            return selectedList.indexOf(e) < 0;
                          });
                          input.onChange(filtered);
                          form.submit();
                        }}
                        title={`2.1 ${t('instructionLinks')}`}
                        // readOnly={props.readOnly}
                        keyProp={'id'}
                        columns={instructionLinkColumns}
                        isLoaded={false}
                        readOnly={state?.sequenceCode ? false : true}
                        onDismiss={() => {
                          form.reset();
                        }}
                      >
                        <ModalGridContext.Consumer>
                          {(modalProps: any) => (
                            <DocumentationModalContent
                              documentation={modalProps.editItem}
                              disabled={false}
                              onChange={(item: { pbsInstructionId: any; id: any; }) => {
                                if (values && values.pbsInstructionLink && Array.isArray(values.pbsInstructionLink)) {
                                  const pbsInstructionLink = [];
                                  const currentList = values.pbsInstructionLink;

                                  let valueList = [];
                                  if (item?.pbsInstructionId) {
                                    // Update an existing item in the list
                                    const index = currentList.findIndex((object: {
                                      id: any;
                                    }) => object.id === item.id);
                                    if (index >= 0) {
                                      currentList[index] = item;
                                    }
                                    valueList = currentList;
                                    input.onChange(valueList);
                                    modalProps.updateEditItem(item);
                                    form.submit();
                                  } else {
                                    // Add a new item to the list
                                    pbsInstructionLink.push({
                                      ...item,
                                      id: uuid4(),
                                    });
                                    currentList.forEach((listItem: any) => {
                                      pbsInstructionLink.push(listItem);
                                    });
                                    valueList = pbsInstructionLink;
                                    input.onChange(valueList);
                                    handleSubmit();
                                  }
                                }
                                // Close the modal
                                modalProps.closeModal();
                              }}
                            />
                          )}
                        </ModalGridContext.Consumer>
                      </DataGridWithModal>
                    )}
                  </Field>

                </div>

                {props.formData?.history &&
                  props.formData?.history.createdDate && (
                    <div className='proj-detail-block' id='3'>
                      <div className='document-pane-card'>
                        <div className='marginTop marginBottom'>
                          <Link href='#' id='history'>
                            <Label>3. {t('history')} </Label>
                          </Link>

                          <HistoryBar
                            createdByUser={
                              props.formData.history.createdBy
                                ? props.formData.history.createdBy
                                : ''
                            }
                            updatedByUser={
                              props.formData.history.modifiedBy
                                ? props.formData.history.modifiedBy
                                : ''
                            }
                            createdDateTime={
                              props.formData.history.createdDate
                                ? props.formData.history.createdDate
                                : ''
                            }
                            updatedDateTime={
                              props.formData.history.modifiedDate
                                ? props.formData.history.modifiedDate
                                : ''
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};
export default DocumentPaneComponent;

import * as React from 'react';
import { forwardRef, useEffect } from 'react';
import { Dropdown as FDropdown, Field, makeStyles, Option, shorthands, useId } from '@fluentui/react-components';

const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    maxWidth: "400px",
    // width:'100%'
  },
});

const Dropdown = forwardRef(({ options,value,onChange,label,...rest}: any, ref: any) => {

  useEffect(() => {
    if(options){
      if(!value) {
        let selected = options?.find((opt: any) => opt?.isDefault);
        if(selected){
          onChange(selected);
        }

      }
    }
  }, [options]);


  const onOptionSelect= (ev:any, data:any) => {
    console.log({ data });
    if(data.selectedOptions && data.selectedOptions[0]){
      let selected = options?.find((opt: any) => opt?.key===data.selectedOptions[0]);
      onChange(selected);
    }
  };

  const getValue=()=>{
    if(value && value?.text){
      return value?.text
    }else if(typeof value==="string"){
      let selected = options?.find((opt: any) => opt?.key===value);
      return selected?.text
    }else{
      return null
    }
  }

  const dropdownId = useId("dropdown-default");
  const styles = useStyles();

  return (
    <div className={styles.root}>

      <input type={"hidden"} ref={ref} value={value} />
      <Field label={label} size='medium' style={{ width: '100%',fontWeight: 500}}>
      {/*<label id={dropdownId}>{label}</label>*/}
      <FDropdown
        aria-labelledby={dropdownId}
        {...rest}
        value={getValue()}
        onOptionSelect={onOptionSelect}
      >
        {options.map((option:any) => (
          <Option key={option?.key} value={option?.key} disabled={option?.disabled}>
            {option?.text}
          </Option>
        ))}
      </FDropdown>
      </Field>
    </div>
  );

});

export default Dropdown

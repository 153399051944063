export const LOAD_LOT_INVITATION_SHORTCUTPANE = 'uprince/LOAD_LOT_INVITATION_SHORTCUTPANE';
export const LOAD_LOT_INVITATION_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_LOT_INVITATION_SHORTCUTPANE_SUCCESS';
export const LOAD_LOT_INVITATION_SHORTCUTPANE_FAIL = 'uprince/LOAD_LOT_INVITATION_SHORTCUTPANE_FAIL';

export const LOAD_LOT_INVITATION_LSITPANE = 'uprince/LOAD_LOT_INVITATION_LSITPANE';
export const LOAD_LOT_INVITATION_LSITPANE_SUCCESS = 'uprince/LOAD_LOT_INVITATION_LSITPANE_SUCCESS';
export const LOAD_LOT_INVITATION_LSITPANE_FAIL = 'uprince/LOAD_LOT_INVITATION_LSITPANE_FAIL';

export const LOAD_LOT_INVITATION_DROPDOWN = 'uprince/LOAD_LOT_INVITATION_DROPDOWN';
export const LOAD_LOT_INVITATION_DROPDOWN_SUCCESS = 'uprince/LOAD_LOT_INVITATION_DROPDOWN_SUCCESS';
export const LOAD_LOT_INVITATION_DROPDOWN_FAIL = 'uprince/LOAD_LOT_INVITATION_DROPDOWN_FAIL';

export const ACCEPT_LOT_INVITATION = 'uprince/ACCEPT_LOT_INVITATION';
export const ACCEPT_LOT_INVITATION_SUCCESS = 'uprince/ACCEPT_LOT_INVITATION_SUCCESS';
export const ACCEPT_LOT_INVITATION_FAIL = 'uprince/ACCEPT_LOT_INVITATION_FAIL';

export const SUBSCRIBE_LOT = 'uprince/SUBSCRIBE_LOT';
export const SUBSCRIBE_LOT_SUCCESS = 'uprince/SUBSCRIBE_LOT_SUCCESS';
export const SUBSCRIBE_LOT_FAIL = 'uprince/SUBSCRIBE_LOT_FAIL';

export const DOWNLOAD_LOT_DOCUMENTS = 'uprince/DOWNLOAD_LOT_DOCUMENTS';
export const DOWNLOAD_LOT_DOCUMENTS_SUCCESS = 'uprince/DOWNLOAD_LOT_DOCUMENTS_SUCCESS';
export const DOWNLOAD_LOT_DOCUMENTS_FAIL = 'uprince/DOWNLOAD_LOT_DOCUMENTS_FAIL';

export const LOAD_LOT_INVITATION_BY_ID = 'uprince/LOAD_LOT_INVITATION_BY_ID';
export const LOAD_LOT_INVITATION_BY_ID_SUCCESS = 'uprince/LOAD_LOT_INVITATION_BY_ID_SUCCESS';
export const LOAD_LOT_INVITATION_BY_ID_FAIL = 'uprince/LOAD_LOT_INVITATION_BY_ID_FAIL';

export const LOAD_BOR_RESOURCES_BY_ID = 'uprince/LOAD_BOR_RESOURCES_BY_ID';
export const LOAD_BOR_RESOURCES_BY_ID_SUCCESS = 'uprince/LOAD_BOR_RESOURCES_BY_ID_SUCCESS';
export const LOAD_BOR_RESOURCES_BY_ID_FAIL = 'uprince/LOAD_BOR_RESOURCES_BY_ID_FAIL';

export const SET_LOT_INVITATION_UID = 'uprince/SET_LOT_INVITATION_UID';
export const CLEAR_LOT_INVITATION_UID = 'uprince/CLEAR_LOT_INVITATION_UID';
export const CLEAR_LOT_INVITATION_FORM_DATA = 'uprince/CLEAR_LOT_INVITATION_FORM_DATA';
export const RESET_LOT_INVITATION_IS_CHANGE = 'uprice/RESET_LOT_INVITATION_IS_CHANGE';


export const CLEAR_LOT_INVITATIONUID = 'uprice/CLEAR_LOT_INVITATIONUID';
export const SAVE_LOT_INVITATION_STATE_ATTR = 'uprice/SAVE_LOT_INVITATION_STATE_ATTR';

export const LOAD_LOT_SUBSCRIBE_BY_ID = 'uprince/LOAD_LOT_SUBSCRIBE_BY_ID';
export const LOAD_LOT_SUBSCRIBE_BY_ID_SUCCESS = 'uprince/LOAD_LOT_SUBSCRIBE_BY_ID_SUCCESS';
export const LOAD_LOT_SUBSCRIBE_BY_ID_FAIL = 'uprince/LOAD_LOT_SUBSCRIBE_BY_ID_FAIL';

export const READ_DOWNLOAD_ALL_DOCUMENT = 'uprince/READ_DOWNLOAD_ALL_DOCUMENT';
export const READ_DOWNLOAD_ALL_DOCUMENT_SUCCESS = 'uprince/READ_DOWNLOAD_ALL_DOCUMENT_SUCCESS';
export const READ_DOWNLOAD_ALL_DOCUMENT_FAIL = 'uprince/READ_DOWNLOAD_ALL_DOCUMENT_FAIL';
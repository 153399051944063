import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../../services/messageService';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useSelector } from 'react-redux';
import { Switch } from '@fluentui/react-components';
import { getCheckedString } from '../../../../types/wbs';
import _ from 'lodash';

const WBSCmdBarLeft = () => {
  const { t } = useTranslation();
  let cmdBarItems: any[] = [];


  /** Do Not change the value of defaultOpenItems here. It's handle in wbsCreateListPane component. Only Use it here */
  const defaultOpenItems = useSelector((state: any) => state.wbs.defaultOpenItems);
  const selectedProject = useSelector((state: any) => state.project.selectedProject);
  const wbsListPaneMsg = useSelector((state: any) => state.wbsTask.wbsListPaneMsg);

  const [expand, setExpand] = useState(false);

  useEffect(() => {
    const handleCollapse = async () => {
      try {
        if (defaultOpenItems && !_.isEmpty(defaultOpenItems)) {
          setExpand(true);
        } else {
          setExpand(false);
        }
      } catch (error) {
        console.error('Error fetching defaultOpenItems:', error);
      }
    };

    handleCollapse().then();
  }, [defaultOpenItems]);

  const checkedString = getCheckedString(expand);
  const onChangeSwitch = React.useCallback((ev) => {
    console.log('expanded >', ev.currentTarget.checked);
    messageService.sendMessage({ isProjectWbsExpand: { status: ev.currentTarget.checked } });
  }, [setExpand]);

  const getItems = () => {
    const generateNew = {
      key: 'new',
      text: t('selectTemplate'),
      iconProps: { iconName: 'Add' },
      onClick: () => {
        messageService.sendMessage({ generateNew: true });
      },
      disabled: (selectedProject?.wbsTemplateId === undefined || selectedProject?.wbsTemplateId),
    };

    cmdBarItems.push(generateNew);

    return cmdBarItems;
  };

  return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <CommandBar
      styles={{ root: { height: 26 } }}
      items={getItems()}
      ariaLabel='Use left and right arrow keys to navigate between commands'
    />
    <Switch
      checked={expand}
      label={checkedString}
      labelPosition='after'
      onChange={onChangeSwitch}
      style={{ marginRight: '10px' }}
      disabled={wbsListPaneMsg}
    />
  </div>;
};

export default WBSCmdBarLeft;

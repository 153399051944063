import React from 'react';
import { useTranslation } from 'react-i18next';
import { DirectionalHint, Label, Link, Stack, TooltipHost } from '@fluentui/react';
import { Depths } from '@fluentui/theme';
import { uPrinceTheme } from '../../../theme';
import { messageService } from '../../services/messageService';
import { PMOL_TYPE } from '../../types/projectMolecule';


const CollapseBookMarkPane = (props: { divClass: string; currentActiveSection: string, list: any, toggleBookmark: () => void, toggleBookmarkOut: () => void, isEdit: boolean, pmolType?: PMOL_TYPE, isVp?: boolean }) => {
  const { t } = useTranslation();

  const collapseDocumentPane = () => {
    messageService.sendMessage({ collapseDocumentPane: true });
  };

  const renderBookMarkList = () => {
    const bookMarkList = props.list
      .map((el: any) => {
        if (!props.isEdit && el.link == 'history') {
          return false;
        }
        return (
          <TooltipHost
            key={el.id + 'tt'}
            content={el.label}
            id="bmark"
            calloutProps={{ gapSpace: 0 }}
            directionalHint={DirectionalHint.rightCenter}
          >
            <Label
              key={el.id}
              className={`${
                props.currentActiveSection === el.id ? 'selected' : ''
              }`}
              style={{ borderLeft: props.currentActiveSection === el.id ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
            >
              {' '}
              <Link key={el.id + 'l'} href={`#${el.link}`}> {el.id}</Link>
            </Label>
          </TooltipHost>

        );
      });
    return bookMarkList;
  };

  return (
    <div>
      <div>
        <Stack
          className={props.isVp ? 'collapse-bookmark-panel-vp parent' : 'collapse-bookmark-panel parent'}
          verticalFill
          gap={30}
          styles={{
            root: {
              width: 40,
              textAlign: 'center',
              selectors: { '@media(max-width: 1440px)': { boxShadow: Depths.depth8 } },
            },
          }}
        >
          <TooltipHost
            content={t('Bookmark')}
            id="bmark"
            calloutProps={{ gapSpace: 0 }}
            directionalHint={DirectionalHint.rightCenter}
          >
            <i
              className="ms-Icon ms-Icon--SearchBookmark"
              aria-labelledby="bmark"
              aria-hidden="true"
              onClick={collapseDocumentPane}
              style={{ marginTop: 14, marginBottom: 14, fontSize: 22 }}
            ></i>
          </TooltipHost>
          <div className="inner-container">
            {renderBookMarkList()}
          </div>
        </Stack>

        <Stack
          className="bookmark-panel-responsive"
          verticalFill
          onClick={props.toggleBookmark}
          gap={30}
          styles={{
            root: {
              boxShadow: Depths.depth8,
              width: 40,
            },
          }}
        >
          <TooltipHost
            content={t('Bookmark')}
            id="bmark"
            calloutProps={{ gapSpace: 0 }}
            directionalHint={DirectionalHint.rightCenter}
          >
            <i
              className="ms-Icon ms-Icon--SearchBookmark"
              aria-labelledby="bmark"
              aria-hidden="true"
            ></i>
          </TooltipHost>
        </Stack>
      </div>
    </div>
  );
};

export default CollapseBookMarkPane;



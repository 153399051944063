import React from 'react';
import { connect } from 'react-redux';
import { messageService } from '../../../services/messageService';
import { PriceListValidationMessage, ProgressStatement, Resource } from '../../../types/progressStatement';
import { ActionButtonState } from '../../../types/corporateProductCatalogue';
import { withTranslation, WithTranslation } from 'react-i18next';
import UprinceLogger from '../../../shared/Logger/logger';
import { Selection } from '@fluentui/react/lib/DetailsList';
import _ from 'lodash';
import DataGrid from './dataGrid/component';
import ResourceModal from './modal/component';
import { ResourceTypes } from '../../../types/billOfResources';
import client from '../../../api';
import { CPC_SEARCH_PMOL_EP } from '../../../shared/endpoints';
import { getProject } from '../../../shared/util';

import {
  deletePriceList,
  readPriceList,
  readPsConsumableData,
  readPsLaboursData,
  readPsMaterialsData,
  readPsToolsData,
  readVATList,
  savePriceList,
  saveResource,
  saveVAT,
} from '../../../reducers/progressStatementReducer';
import { clearCpcData, getCpcById } from '../../../reducers/billOfResourcesReducer';
import { withRouter } from 'react-router';
import {
  Dropdown,
  FontWeights,
  getTheme,
  IconButton,
  IIconProps,
  Label,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  TextField,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../../theme';
import ConfirmationDialog from '../../../shared/confirmationDialog/confirmationDialog';

const theme = getTheme();

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    paddingTop: 20,
    overflowY: 'hidden',
    minWidth: '40vw',
    //minHeight: '32vh',
    minHeight: screen.width > 1280 ? '42vh' : '60vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'none',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const addIconWhite: IIconProps = {
  iconName: 'Save',
  styles: addWhiteIconButtonStyles,
};

export interface State {
  ////PMOL////
  selectedResourceTypeId: string | null;
  selectedCpcEnvironment: string | null;
  isOpen: boolean,
  serviceCoefficient: string | null,

  materials: Resource[];
  material: Resource;
  isMaterialModalVisible: boolean;
  isMaterialEdit: boolean;
  materialActionButtonState: ActionButtonState;
  materialSelectionDetails: {} | string;
  materialDeleteItems: [] | string[];
  toolCoefficient: string | null;

  tools: Resource[];
  tool: Resource;
  isToolsModalVisible: boolean;
  isToolEdit: boolean;
  toolActionButtonState: ActionButtonState;
  toolSelectionDetails: {} | string;
  toolDeleteItems: [] | string[];
  materialCoefficient: string | null;

  consumables: Resource[];
  consumable: Resource;
  isConsumablesModalVisible: boolean;
  isConsumableEdit: boolean;
  consumableActionButtonState: ActionButtonState;
  consumableSelectionDetails: {} | string;
  consumableDeleteItems: [] | string[];
  consumableCoefficient: string | null;

  labours: Resource[];
  labour: Resource;
  isLaboursModalVisible: boolean;
  isLabourEdit: boolean;
  labourActionButtonState: ActionButtonState;
  labourSelectionDetails: {} | string;
  labourDeleteItems: [] | string[];
  labourCoefficient: string | null;

  priceListValidation: PriceListValidationMessage;
  vat: any;
  confimationDialogVisibility: boolean;
  needReCalculate: boolean;
  isNewPS: boolean;
  /////
}

interface Props extends WithTranslation {
  formData: ProgressStatement;
  clearCpcData: any;
  getCpcById: any;
  cpcData: any;
  savePriceList: any;
  readPriceList: any;
  priceList: any;
  saveResource: any;
  deletePriceList: any;


  deleteMaterials: any;
  materialList: Resource[];
  createMaterialsStatus: boolean;
  readMaterialsStatus: boolean;


  deleteTools: any;
  tools: Resource[];
  createToolsStatus: boolean;
  readToolsStatus: boolean;


  deleteLabours: any;
  labours: Resource[];
  createLabourStatus: boolean;
  readLabourStatus: boolean;


  deleteConsumable: any;
  consumables: Resource[];
  createConsumableStatus: boolean;
  readConsumableStatus: boolean;

  readPsMaterialsData: () => void;
  readPsToolsData: () => void;
  readPsConsumableData: () => void;
  readPsLaboursData: () => void;
  teamMembers: [];
  readExtraTeamMembers: any;

  readOnly: boolean;
  read: boolean;
  readVATList: any;
  vatList: any[];
  saveVAT: (data: any) => void;
  isNewPS: boolean
}

class PriceList extends React.Component<Props, State> {
  subscription: any;
  private _materialsSelection: Selection;
  private _toolsSelection: Selection;
  private _labourSelection: Selection;
  private _consumableSelection: Selection;

  columns = [
    {
      key: 'column1',
      name: this.props.t('title'),
      fieldName: 'title',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: this.props.t('fixedPrice'),
      fieldName: 'fixedPrice',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column3',
      name: this.props.t('coefficient'),
      fieldName: 'coefficient',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      materials: [],
      isMaterialModalVisible: false,
      materialSelectionDetails: {},
      materialActionButtonState: { add: false, edit: true, remove: true },
      material: new Resource(),
      isMaterialEdit: false,
      materialDeleteItems: [],
      materialCoefficient: null,

      tools: [],
      tool: new Resource(),
      isToolsModalVisible: false,
      isToolEdit: false,
      toolActionButtonState: { add: false, edit: true, remove: true },
      toolSelectionDetails: {},
      toolDeleteItems: [],
      toolCoefficient: null,

      consumables: [],
      consumable: new Resource(),
      isConsumablesModalVisible: false,
      isConsumableEdit: false,
      consumableActionButtonState: { add: false, edit: true, remove: true },
      consumableSelectionDetails: {},
      consumableDeleteItems: [],
      consumableCoefficient: null,

      labours: [],
      labour: new Resource(),
      isLaboursModalVisible: false,
      isLabourEdit: false,
      labourActionButtonState: { add: false, edit: true, remove: true },
      labourSelectionDetails: {},
      labourDeleteItems: [],
      labourCoefficient: null,

      selectedCpcEnvironment: null,
      selectedResourceTypeId: null,
      serviceCoefficient: null,
      priceListValidation: {
        consumableErrorMsg: '',
        labourErrorMsg: '',
        materialErrorMsg: '',
        toolErrorMsg: '',
        serviceErrorMsg: '',
      },
      vat: null,
      confimationDialogVisibility: false,
      needReCalculate: false,
      isNewPS: false,
    };

    this._materialsSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_materialsSelection',
          this._getMaterialSelectionDetails(),
        );
        this.setState({
          materialSelectionDetails: this._getMaterialSelectionDetails(),
          materialActionButtonState: this._getMaterialActionButtonState(),
          material: this._materialEditDetails(),
          materialDeleteItems: this._resourceDeleteDetails(
            this._materialsSelection,
          ),
        });
      },
    });

    this._toolsSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log('_toolsSelection', this._getToolsSelectionDetails());
        this.setState({
          toolSelectionDetails: this._getToolsSelectionDetails(),
          toolActionButtonState: this._getToolsActionButtonState(),
          tool: this._toolsEditDetails(),
          toolDeleteItems: this._resourceDeleteDetails(this._toolsSelection),
        });
      },
    });

    this._labourSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_labourSelection',
          this._getLabourSelectionDetails(),
        );
        this.setState({
          labourSelectionDetails: this._getLabourSelectionDetails(),
          labourActionButtonState: this._getLabourActionButtonState(),
          labour: this._labourEditDetails(),
        });
      },
    });

    this._consumableSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_consumableSelection',
          this._getConsumableSelectionDetails(),
        );
        this.setState({
          consumableSelectionDetails: this._getConsumableSelectionDetails(),
          consumableActionButtonState: this._getConsumableActionButtonState(),
          consumable: this._consumableEditDetails(),
        });
      },
    });
  }

  componentDidMount() {

    this.getResources();
    this.props.readVATList();
    this.readPriceList();
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.openPriceList) {
          this.setState({
            isOpen: data.data.openPriceList,
          }, this.getResources);
        }
      }
    });
  };

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {

    if (prevProps.priceList != this.props.priceList) {
      if (this.props.priceList) {
        this.setPriceListResources();
      }
    }
    if (prevProps.formData != this.props.formData) {
      if (this.props.formData && this.props.formData.projectSequenceCode) {
        this.props.readPriceList(this.props.formData.projectSequenceCode);
      }
    }
  }

  setPriceListResources = () => {
    this.setState({
      materialCoefficient: this.props.priceList.materialCoefficient,
      toolCoefficient: this.props.priceList.toolCoefficient,
      consumableCoefficient: this.props.priceList.consumableCoefficient,
      labourCoefficient: this.props.priceList.labourCoefficient,
      serviceCoefficient: this.props.priceList.serviceCoefficient,
      vat: this.props.priceList.tax,
    });
  };

  onModalClose = () => {
    this.readPriceList();
    this.resetValidation();
    this.setState({
      isOpen: false,
    });
  };

  readPriceList = () => {
    if (getProject()) {
      this.props.readPriceList(getProject());
    } else if (this.props.formData && this.props.formData.projectSequenceCode) {
      this.props.readPriceList(this.props.formData.projectSequenceCode);
    }
  };

  onSavePriceList = () => {
    if (this.priceListValidate()) {
      let priceListData = {
        materialCoefficient: this.state.materialCoefficient,
        labourCoefficient: this.state.labourCoefficient,
        consumableCoefficient: this.state.consumableCoefficient,
        toolCoefficient: this.state.toolCoefficient,
        serviceCoefficient: this.state.serviceCoefficient,
      };
      this.props.savePriceList(priceListData).then(() => {
        this.onModalClose();
        if (this.state.needReCalculate && this.props.isNewPS) {
          messageService.sendMessage({ isReCalculate: true });
        }
      });
      this.props.saveVAT({ id: getProject(), vatId: this.state.vat?.id });

    }
  };

  onChangeInput = (field: string) => (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    if (newValue !== undefined && field) {
      this.setState(prevState => ({
        ...prevState,
        [field]: newValue,
      }));
      this.clearValidationOnChange(field);
    }

  };

  private onChangeDropDownInputInput = (field: string) => (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    item?: any,
  ) => {
    if (field === 'vat') {
      this.setState(prevState => ({
        ...prevState,
        [field]: { id: item.key, name: item.text },
      }), () => {
        this.clearValidationOnChange(field);
      });
    } else {
      this.setState(prevState => ({
        ...prevState,
        [field]: item.key,
      }), () => {
        this.clearValidationOnChange(field);
      });
    }

  };


  clearValidationOnChange(field: string) {
    let validation = this.state.priceListValidation;
    if (field === 'materialCoefficient') {
      validation.materialErrorMsg = '';
    } else if (field === 'toolCoefficient') {
      validation.toolErrorMsg = '';
    } else if (field === 'consumableCoefficient') {
      validation.consumableErrorMsg = '';
    } else if (field === 'labourCoefficient') {
      validation.labourErrorMsg = '';
    } else if (field === 'serviceCoefficient') {
      validation.serviceErrorMsg = '';
    }
    this.setState({
      priceListValidation: validation,
    });
  }

  resetValidation = () => {
    let validation = this.state.priceListValidation;
    validation.materialErrorMsg = '';
    validation.toolErrorMsg = '';
    validation.consumableErrorMsg = '';
    validation.labourErrorMsg = '';
    validation.serviceErrorMsg = '';
    this.setState({
      priceListValidation: validation,
    });
  };


  priceListValidate = (): boolean => {
    let isValid = true;
    let validationMsg = this.state.priceListValidation;

    if (!this.state.materialCoefficient) {
      isValid = false;
      validationMsg.materialErrorMsg = 'Material coefficient required' + this.state.materialCoefficient;
    } else {
      validationMsg.materialErrorMsg = '';
    }

    if (!this.state.toolCoefficient) {
      isValid = false;
      validationMsg.toolErrorMsg = 'Tool coefficient required';
    } else {
      validationMsg.toolErrorMsg = '';
    }

    if (!this.state.consumableCoefficient) {
      isValid = false;
      validationMsg.consumableErrorMsg = 'Consumable coefficient required';
    } else {
      validationMsg.consumableErrorMsg = '';
    }

    if (!this.state.labourCoefficient) {
      isValid = false;
      validationMsg.labourErrorMsg = 'Labour coefficient required';
    } else {
      validationMsg.labourErrorMsg = '';
    }


    if (!this.state.serviceCoefficient) {
      isValid = false;
      validationMsg.serviceErrorMsg = 'Service coefficient required';
    } else {
      validationMsg.serviceErrorMsg = '';
    }
    this.setState({
      priceListValidation: validationMsg,
    });
    return isValid;
  };

  onConfirmUpdate = () => {
    this.setState({ needReCalculate: true, confimationDialogVisibility: false }, () => {
      this.onSavePriceList();
    });
  };

  onCancelUpdate = () => {
    this.setState({ needReCalculate: false, confimationDialogVisibility: false }, () => {
      this.onSavePriceList();
    });
  };


  render() {
    return (
      <div>
        <Modal
          titleAriaId={'priceList'}
          isOpen={this.state.isOpen}
          onDismiss={() => {
            this.onModalClose();
          }}
          scrollableContentClassName="body"
          isBlocking={false}
          dragOptions={undefined}
        >
          <div className={contentStyles.header}>
            <span id={'priceList'}>Price List</span>
            <IconButton
              styles={cancelIconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel="Close popup modal"
              onClick={() => {
                this.onModalClose();
              }}
            />
          </div>
          <div className={contentStyles.subHeader}></div>
          <div className={contentStyles.body}>
            <div>
              <div>
                <Label>1.{this.props.t('internalProduct')}</Label>
              </div>

              <div className="proj-detail-block" id="14">
                <div>
                  <div>
                    <Label>{'1.1 ' + this.props.t('material')}</Label>
                  </div>
                  <div style={{ width: '50%' }}>
                    <TextField
                      label={this.props.t('materialCoefficient')}
                      value={
                        this.state.materialCoefficient
                          ? this.state.materialCoefficient
                          : ''
                      }
                      min={1}
                      required={true}
                      type={'number'}
                      onChange={this.onChangeInput('materialCoefficient')}
                      errorMessage={
                        this.state.priceListValidation.materialErrorMsg
                          ? this.state.priceListValidation.materialErrorMsg
                          : ''
                      }
                      disabled={!getProject()}
                    />
                  </div>
                </div>
                <div id={'extra-work-id-4-2'}>
                  <DataGrid
                    dataList={
                      this.props.materialList ? this.props.materialList : []
                    }
                    editDataGrid={() => this.openMaterialModal()}
                    openModel={() => {
                      this.openMaterialModal();
                    }}
                    selection={this._materialsSelection}
                    actionButtonState={this.state.materialActionButtonState}
                    title={this.props.t('materialItems')}
                    deleteDataGrid={() => {
                      this.handleMaterialsDelete();
                    }}
                    readOnly={!getProject()}
                    columns={this.columns}
                    isLoaded={this.props.readMaterialsStatus}
                  />
                </div>
              </div>

              <div className="proj-detail-block" id="15">
                <div>
                  <div>
                    <Label>{'1.2 ' + this.props.t('tools')}</Label>
                  </div>
                  <div style={{ width: '50%' }}>
                    <TextField
                      label={this.props.t('toolsCoefficient')}
                      value={
                        this.state.toolCoefficient
                          ? this.state.toolCoefficient
                          : ''
                      }
                      min={1}
                      required={true}
                      type={'number'}
                      onChange={this.onChangeInput('toolCoefficient')}
                      errorMessage={
                        this.state.priceListValidation.toolErrorMsg
                          ? this.state.priceListValidation.toolErrorMsg
                          : ''
                      }
                      disabled={!getProject()}
                    />
                  </div>
                </div>
                <div id={'extra-work-id-4-3'}>
                  <DataGrid
                    dataList={this.props.tools ? this.props.tools : []}
                    editDataGrid={() => this.openToolsModal()}
                    openModel={() => {
                      this.openToolsModal();
                    }}
                    selection={this._toolsSelection}
                    actionButtonState={this.state.toolActionButtonState}
                    title={this.props.t('toolsItems')}
                    deleteDataGrid={() => {
                      this.handleToolsDelete();
                    }}
                    readOnly={!getProject()}
                    columns={this.columns}
                    isLoaded={this.props.readToolsStatus}
                  />
                </div>
              </div>

              <div className="proj-detail-block" id="16">
                <div>
                  <div>
                    <Label>{'1.3 ' + this.props.t('consumables')}</Label>
                  </div>
                  <div style={{ width: '50%' }}>
                    <TextField
                      label={this.props.t('consumablesCoefficient')}
                      value={
                        this.state.consumableCoefficient
                          ? this.state.consumableCoefficient
                          : ''
                      }
                      min={1}
                      required={true}
                      type={'number'}
                      onChange={this.onChangeInput('consumableCoefficient')}
                      errorMessage={
                        this.state.priceListValidation.consumableErrorMsg
                          ? this.state.priceListValidation.consumableErrorMsg
                          : ''
                      }
                      disabled={!getProject()}
                    />
                  </div>
                </div>
                <div id={'extra-work-id-4-4'}>
                  <DataGrid
                    dataList={
                      this.props.consumables ? this.props.consumables : []
                    }
                    editDataGrid={() => this.openConsumableModal()}
                    openModel={() => {
                      this.openConsumableModal();
                    }}
                    selection={this._consumableSelection}
                    actionButtonState={this.state.consumableActionButtonState}
                    title={this.props.t('consumablesItems')}
                    deleteDataGrid={() => {
                      this.handleConsumableDelete();
                    }}
                    readOnly={!getProject()}
                    columns={this.columns}
                    isLoaded={this.props.readConsumableStatus}
                  />
                </div>
              </div>

              <div className="proj-detail-block" id="17">
                <div>
                  <div>
                    <Label>{'1.4 ' + this.props.t('labour')}</Label>
                  </div>
                  <div style={{ width: '50%' }}>
                    <TextField
                      label={this.props.t('labourCoefficient')}
                      value={
                        this.state.labourCoefficient
                          ? this.state.labourCoefficient
                          : ''
                      }
                      min={1}
                      required={true}
                      type={'number'}
                      onChange={this.onChangeInput('labourCoefficient')}
                      errorMessage={
                        this.state.priceListValidation.labourErrorMsg
                          ? this.state.priceListValidation.labourErrorMsg
                          : ''
                      }
                      disabled={!getProject()}
                    />
                  </div>
                </div>
                <div id={'extra-work-id-4-5'}>
                  <DataGrid
                    dataList={this.props.labours ? this.props.labours : []}
                    editDataGrid={() => this.openLabourModal()}
                    openModel={() => {
                      this.openLabourModal();
                    }}
                    selection={this._labourSelection}
                    actionButtonState={this.state.labourActionButtonState}
                    title={this.props.t('labourItems')}
                    deleteDataGrid={() => {
                      this.handleLaboursDelete();
                    }}
                    readOnly={!getProject()}
                    columns={this.columns}
                    isLoaded={this.props.readLabourStatus}
                  />
                </div>
              </div>
            </div>
            <div>
              <div>
                <Label>2.{this.props.t('externalProduct')}</Label>
              </div>
              <div style={{ width: '50%' }}>
                <TextField
                  label={this.props.t('serviceCoefficient')}
                  value={
                    this.state.serviceCoefficient
                      ? this.state.serviceCoefficient
                      : ''
                  }
                  min={1}
                  type={'number'}
                  required={true}
                  onChange={this.onChangeInput('serviceCoefficient')}
                  errorMessage={
                    this.state.priceListValidation.serviceErrorMsg
                      ? this.state.priceListValidation.serviceErrorMsg
                      : ''
                  }
                  disabled={!getProject()}
                />
              </div>
            </div>

            <div>
              <div>
                <Label>3.{this.props.t('vat')}</Label>
              </div>
              <div style={{ width: '50%' }}>
                <Dropdown
                  placeholder={this.props.t('vat')}
                  label={this.props.t('vat')}
                  selectedKey={
                    this.state.vat
                      ? this.state.vat.id
                      : ''
                  }
                  options={this.props.vatList}
                  //onRenderCaretDown={onRenderCaretDown}
                  onChange={this.onChangeDropDownInputInput('vat') as any}
                  disabled={!getProject()}
                />
              </div>
            </div>
          </div>
          <div className={contentStyles.footer}>
            <PrimaryButton
              iconProps={addIconWhite}
              text={this.props.t('save')}
              style={{ marginTop: 20, marginBottom: 30 }}
              onClick={() => {
                if (this.props.isNewPS) {
                  this.setState({ confimationDialogVisibility: true });
                } else {
                  this.onSavePriceList();
                }
              }}
            />
          </div>
        </Modal>

        <ConfirmationDialog
          hidden={!this.state.confimationDialogVisibility}
          title={this.props.t('confirm')}
          subText={this.props.t('priceHaveBeenUpdated')}
          onClickConfirmDialog={this.onConfirmUpdate}
          onClickCancelDialog={this.onCancelUpdate}
          confirmButtonText={this.props.t('yesReCalculate')}
        />

        <ResourceModal
          isOpen={this.state.isMaterialModalVisible}
          openResourceModal={() => this.openMaterialModal()}
          isEdit={this.state.isMaterialEdit}
          handleResourceSave={(resource: Resource) => {
            this.createMateris(resource);
          }}
          resource={this.state.material}
          title={
            this.state.isMaterialEdit
              ? this.props.t('editMaterial')
              : this.props.t('addMaterial')
          }
          resourceTypeId={ResourceTypes.materials.id}
          cpcOptions={this.cpcOptions}
          handleCpcChange={(selectedCpc: any) => {
            this.getCpcDetails(selectedCpc);
          }}
          clearCpcData={() => this.clearCpcData()}
          cpcData={this.props.cpcData}
          type={'Extra'}
        />

        <ResourceModal
          isOpen={this.state.isToolsModalVisible}
          openResourceModal={() => this.openToolsModal()}
          isEdit={this.state.isToolEdit}
          handleResourceSave={(resource: Resource) => {
            this.createTools(resource);
          }}
          resource={this.state.tool}
          title={
            this.state.isToolEdit
              ? this.props.t('editTool')
              : this.props.t('addTool')
          }
          resourceTypeId={ResourceTypes.tools.id}
          cpcOptions={this.cpcOptions}
          handleCpcChange={(selectedCpc: any) => {
            this.getCpcDetails(selectedCpc);
          }}
          clearCpcData={() => this.clearCpcData()}
          cpcData={this.props.cpcData}
          type={'Extra'}
        />
        <ResourceModal
          isOpen={this.state.isConsumablesModalVisible}
          openResourceModal={() => this.openConsumableModal()}
          isEdit={this.state.isConsumableEdit}
          handleResourceSave={(resource: Resource) => {
            this.createConsumables(resource);
          }}
          resource={this.state.consumable}
          title={
            this.state.isConsumableEdit
              ? this.props.t('editConsumable')
              : this.props.t('addConsumable')
          }
          resourceTypeId={ResourceTypes.consumable.id}
          cpcOptions={this.cpcOptions}
          handleCpcChange={(selectedCpc: any) => {
            this.getCpcDetails(selectedCpc);
          }}
          clearCpcData={() => this.clearCpcData()}
          cpcData={this.props.cpcData}
          type={'Extra'}
        />

        <ResourceModal
          isOpen={this.state.isLaboursModalVisible}
          openResourceModal={() => this.openLabourModal()}
          isEdit={this.state.isLabourEdit}
          handleResourceSave={(resource: Resource) => {
            this.createLabour(resource);
          }}
          resource={this.state.labour}
          title={
            this.state.isLabourEdit
              ? this.props.t('editLabour')
              : this.props.t('addLabour')
          }
          resourceTypeId={ResourceTypes.labour.id}
          cpcOptions={this.cpcOptions}
          handleCpcChange={(selectedCpc: any) => {
            this.getCpcDetails(selectedCpc);
          }}
          clearCpcData={() => this.clearCpcData()}
          cpcData={this.props.cpcData}
          type={'Extra'}
        />
      </div>
    );
  }

  getResources = () => {
    this.getMaterisList();
    this.getToolsList();
    this.getLabourList();
    this.getConsumablesList();

  };

  createTools = (resource: Resource) => {
    this.props.saveResource(resource).then(() => {
      this.getToolsList();
    });
  };

  createLabour = (resource: Resource) => {
    this.props.saveResource(resource).then(() => {
      this.getLabourList();
    });
  };

  createMateris = (resource: Resource) => {
    this.props.saveResource(resource).then(() => {
      this.getMaterisList();
    });
  };

  createConsumables = (resource: Resource) => {
    this.props.saveResource(resource).then(() => {
      this.getConsumablesList();
    });
  };


  getToolsList = () => {
    this.props.readPsToolsData();
  };

  getLabourList = () => {
    this.props.readPsLaboursData();
  };

  getMaterisList = () => {
    this.props.readPsMaterialsData();
  };

  getConsumablesList = () => {
    this.props.readPsConsumableData();
  };

  private openMaterialModal = (): void => {
    this.setState({
      isMaterialModalVisible: !this.state.isMaterialModalVisible,
      material: this._materialEditDetails(),
      selectedResourceTypeId: ResourceTypes.materials.id,
    });
  };

  private _materialEditDetails() {
    const selectionCount = this._materialsSelection.getSelectedCount();
    let material = new Resource();

    if (selectionCount == 1) {
      this.setState({
        isMaterialEdit: true,
      });

      let selectedMaterial = this._materialsSelection.getSelection()[0] as Resource;
      material = selectedMaterial;
      //assign
    } else {
      this.setState({
        isMaterialEdit: false,
      });
    }
    return material;
  }

  private _getMaterialSelectionDetails(): string | number | {} | [] {
    return this._materialsSelection.getSelection();
  }

  private _getMaterialActionButtonState(): ActionButtonState {
    const selectionCount = this._materialsSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    UprinceLogger.log('_vendorSelection', disabled);
    return disabled;
  }

  private _resourceDeleteDetails(selection: Selection) {
    let deleteList = [];
    let savedDeleteList = [];
    let selectedRisk = selection.getSelection() as Resource[];
    savedDeleteList = selectedRisk.filter((item: any) => {
      return item.id != null;
    });
    deleteList = savedDeleteList.map((item: any) => {
      return item.id;
    });
    return deleteList;
  }

  private handleMaterialsDelete = () => {
    const selectedList = this._materialsSelection
      .getSelection()
      .map((item: any) => {
        return item.id;
      });
    if (selectedList.length > 0) {
      this.props.deletePriceList(selectedList).then(() => {
        this.getMaterisList();
      });
      ;
    }
    this._materialsSelection.setAllSelected(false);
  };

  private openToolsModal = (): void => {
    this.setState({
      isToolsModalVisible: !this.state.isToolsModalVisible,
      tool: this._toolsEditDetails(),
      selectedResourceTypeId: ResourceTypes.tools.id,
    });
  };

  private _toolsEditDetails() {
    const selectionCount = this._toolsSelection.getSelectedCount();
    let tool = new Resource();

    if (selectionCount == 1) {
      this.setState({
        isToolEdit: true,
      });

      let selectedTool = this._toolsSelection.getSelection()[0] as Resource;
      tool = selectedTool;
      //assign
    } else {
      this.setState({
        isToolEdit: false,
      });
    }
    return tool;
  }

  private _getToolsSelectionDetails(): string | number | {} | [] {
    return this._toolsSelection.getSelection();
  }

  private _getToolsActionButtonState(): ActionButtonState {
    const selectionCount = this._toolsSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private handleToolsDelete = () => {
    const selectedList = this._toolsSelection
      .getSelection()
      .map((item: any) => {
        return item.id;
      });
    if (selectedList.length > 0) {
      this.props.deletePriceList(selectedList).then(() => {
        this.getToolsList();
      });
      ;
    }
    this._toolsSelection.setAllSelected(false);
  };

  private openLabourModal = (): void => {
    this.setState(
      {
        isLaboursModalVisible: !this.state.isLaboursModalVisible,
        labour: this._labourEditDetails(),
        selectedResourceTypeId: ResourceTypes.labour.id,
      },
    );
  };

  private _labourEditDetails() {
    const selectionCount = this._labourSelection.getSelectedCount();
    let labour = new Resource();

    if (selectionCount == 1) {
      this.setState({
        isLabourEdit: true,
      });

      let selectedLabour = this._labourSelection.getSelection()[0] as Resource;
      labour = selectedLabour;
      //assign
    } else {
      this.setState({
        isLabourEdit: false,
      });
    }
    return labour;
  }

  private _getLabourSelectionDetails(): string | number | {} | [] {
    return this._labourSelection.getSelection();
  }

  private _getLabourActionButtonState(): ActionButtonState {
    const selectionCount = this._labourSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private handleLaboursDelete = () => {
    const selectedList = this._labourSelection
      .getSelection()
      .map((item: any) => {
        return item.id;
      });
    if (selectedList.length > 0) {
      this.props.deletePriceList(selectedList).then(() => {
        this.getLabourList();
      });
      ;
    }
    this._labourSelection.setAllSelected(false);
  };

  private openConsumableModal = (): void => {
    this.setState({
      isConsumablesModalVisible: !this.state.isConsumablesModalVisible,
      consumable: this._consumableEditDetails(),
      selectedResourceTypeId: ResourceTypes.consumable.id,
    });
  };

  private _consumableEditDetails() {
    const selectionCount = this._consumableSelection.getSelectedCount();
    let consumable = new Resource();

    if (selectionCount == 1) {
      this.setState({
        isConsumableEdit: true,
      });

      let selectedConsumable = this._consumableSelection.getSelection()[0] as Resource;
      consumable = selectedConsumable;
      //assign
    } else {
      this.setState({
        isConsumableEdit: false,
      });
    }
    return consumable;
  }

  private _getConsumableSelectionDetails(): string | number | {} | [] {
    return this._consumableSelection.getSelection();
  }

  private _getConsumableActionButtonState(): ActionButtonState {
    const selectionCount = this._consumableSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private handleConsumableDelete = () => {
    const selectedList = this._consumableSelection
      .getSelection()
      .map((item: any) => {
        return item.id;
      });
    if (selectedList.length > 0) {
      this.props.deletePriceList(selectedList).then(() => {
        this.getConsumablesList();
      });
      ;
    }
    this._consumableSelection.setAllSelected(false);
  };

  private cpcOptions = (inputValue: any) =>
    new Promise((resolve) => {
      //setTimeout(() => {
      resolve(this.getCpcByName(inputValue));
      // }, 1000);
    });

  private getCpcByName = async (name: string) => {
    let filter = {
      resourceTypeId: this.state.selectedResourceTypeId,
      resourceFamilyId: null,
      resourceNumber: null,
      title: name,
      status: 1,
      sorter: {
        attribute: 'resourceNumber',
        order: 'asc',
      },
    };
    if (name.length >= 2) {
      let response = await client.post(
        CPC_SEARCH_PMOL_EP + getProject(),
        filter,
      );
      return this.formatCpcResponse(response);
    }
  };

  private formatCpcResponse = (response: any) => {
    let localOptions: { value: string; label: string }[] = [];
    let contractingUnitOptions: { value: string; label: string }[] = [];
    let centralOptions: { value: string; label: string }[] = [];
    let groupedOptions = [
      {
        label: this.props.t('project'),
        options: localOptions,
      },
      {
        label: this.props.t('contractingUnit'),
        options: contractingUnitOptions,
      },
      {
        label: this.props.t('Organization'),
        options: centralOptions,
      },
    ];
    if (response && response.data) {
      let localData = response.data.local;
      let contractingUnit = response.data.contractingUnit;
      let central = response.data.central;

      if (localData && _.isArray(localData)) {
        localOptions = localData.map((item: any) => {
          return {
            id: item.id,
            value: item.resourceNumber,
            label: item.title,
            environment: 'local',
          };
        });
      }
      if (contractingUnit && _.isArray(contractingUnit)) {
        contractingUnitOptions = contractingUnit.map((item: any) => {
          return {
            id: item.id,
            value: item.resourceNumber,
            label: item.title,
            environment: 'cu',
          };
        });
      }
      if (central && _.isArray(central)) {
        centralOptions = central.map((item: any) => {
          return {
            id: item.id,
            value: item.resourceNumber,
            label: item.title,
            environment: 'central',
          };
        });
      }
      groupedOptions = [
        {
          label: this.props.t('project'),
          options: localOptions,
        },
        {
          label: this.props.t('contractingUnit'),
          options: contractingUnitOptions,
        },
        {
          label: this.props.t('Organization'),
          options: centralOptions,
        },
      ];
    } else {
    }

    return groupedOptions;
  };

  private getCpcDetails = (selectedCpc: any) => {
    if (selectedCpc && selectedCpc.value) {
      this.setState({
        selectedCpcEnvironment: selectedCpc.environment,
      });
      this.props.getCpcById(selectedCpc);
    }
  };

  private clearCpcData = () => {
    this.props.clearCpcData();
  };

}


const mapStateToProps = (state: any, ownProps: any) => {
  return {
    materialList: state.ps.materials,
    tools: state.ps.tools,
    consumables: state.ps.consumables,
    labours: state.ps.labours,
    createMaterialsStatus: state.ps.createMaterialsStatus,
    readMaterialsStatus: state.ps.readMaterialsStatus,
    createToolsStatus: state.ps.createToolsStatus,
    readToolsStatus: state.ps.readToolsStatus,
    readConsumableStatus: state.ps.readConsumableStatus,
    createConsumableStatus: state.ps.createConsumableStatus,
    readLabourStatus: state.ps.readLabourStatus,
    createLabourStatus: state.ps.createLabourStatus,
    priceList: state.ps.priceList,

    cpcData: state.billOfResource.cpcData,
    vatList: state.ps.vatList,
  };
};
const mapDispatchToProps = {
  readPsMaterialsData,
  readPsToolsData,
  readPsConsumableData,
  readPsLaboursData,

  getCpcById,
  clearCpcData,
  savePriceList,
  readPriceList,
  saveResource,
  deletePriceList,
  readVATList,
  saveVAT,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(PriceList)),
);


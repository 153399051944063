import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';
import { saveProjectStateAttr } from '../../../reducers/projectReducer';
import { useDispatch } from 'react-redux';
import { projectInitialFormData } from '../../../types/project';

const CommandBarLeft = (props: { disable: boolean }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();


  const getItems = () => {
    return [
      {
        key: 'new',
        text: t('new'),
        iconProps: { iconName: 'Add' },
        onClick: () => {
          messageService.sendMessage({ isNewPROJECT: true.valueOf, resetListPaneSelection: true });
          // dispatch(saveProjectStateAttr('isNewProject', true));
          dispatch(saveProjectStateAttr('isClear', true));
          dispatch(saveProjectStateAttr('selectedProject', projectInitialFormData));
        },
        disabled: props.disable,
      },
    ];
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );
};

export default CommandBarLeft;

import * as React from 'react';
import { useEffect } from 'react';
import { mergeStyleSets } from '@fluentui/react';
// import WbsTaxonomy from './components/WbsTaxonomy';
import { messageService } from '../../../services/messageService';
import WbsCreateTaxonomy from './component/WbsCreateTaxonomy';

const classNames = mergeStyleSets({
  fullWidth: { width: '100%', marginTop: 20 },
  halfWidth: { width: '49%' },
});

interface Props {
  featureId: string | undefined;
}

const WbsCreateDocumentPane = (props: Props) => {
  const { featureId } = props;

  useEffect(() => {
    const subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        // if (data.data.saveWbsTask) {}
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <>
      <div className='document-pane-card' style={{ height: '100%', overflow:'hidden' }}>
        <div className={`${classNames.fullWidth}`}>
          <WbsCreateTaxonomy featureId={featureId} />
        </div>
      </div>
      {/*<div style={{width:500,height:600,overflow:'auto'}}>*/}
      {/*  <pre>  {JSON.stringify(values,null,2)}</pre>*/}
      {/*</div>*/}
    </>
  );
};

export default WbsCreateDocumentPane;
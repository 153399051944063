import { Checkbox, Icon, mergeStyleSets, PrimaryButton } from '@fluentui/react';
import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

type Options = {
  key: string, 
  text: string
}

interface HierarchyData {
  certifications: {
    socialSecurity: Options[]
    hse: Options[]
  }
}

const data: HierarchyData = {
  certifications: {
    socialSecurity: [
      { key: "24aa5219-b510-4aac-8024-a4d40c8a9060", text: "Limosa" },
      { key: "87c045e4-cfc5-4a9e-be7c-c4755880a7d7", text: "SSNI" },
      { key: "962621f9-19c7-4647-bc69-643bca88ab56", text: "A1" },
    ],
    hse: [
      { key: '53496196-865F-4684-B979-E4B68A8F36BD', text: 'VCA' }
    ]
  }
};

const CertificationTypeHierarchyComponent = ({ width, value, onApplyFilter }: {width: string, value: any, onApplyFilter: (value: any) => void}) => {
  const {t} = useTranslation();
  const dropdownRef = useRef<any>(null);
  const buttonRef: any = useRef(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [certificationTriggered, setCertificationTriggered] = useState<boolean>(false)
  const [activeCategory, setActiveCategory] = useState<string[]>([]);
  const [activeItem, setActiveItem] = useState<{key: string, text: string} | null>(null);

  const styles = mergeStyleSets({
    grandChildren: { 
      padding: '5px 10px', 
      cursor: 'pointer', 
      color: '#000', 
      textAlign: 'left', 
      paddingLeft: '60px',
      fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
      fontSize: '14px'
    },
    children: { 
      padding: '5px 10px', 
      cursor: 'pointer', 
      color: '#000', 
      textAlign: 'left', 
      paddingLeft: '30px',
      fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
      fontSize: '14px'
    },
    actionBtnContainer: {
      marginBottom: '10px', 
      display: 'flex', 
      flexDirection: 'row', 
      alignItems: 'center', 
      justifyContent: 'space-between',
      fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
      fontSize: '14px'
    },
    parent: { 
      padding: '5px 10px', 
      cursor: 'pointer', 
      color: '#000', 
      textAlign: 'left',
      fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
    },
    mainBtn: {
      width: width,
      backgroundColor: '#fff',
      color: '#000',
      border: '1px solid rgb(96, 94, 92)',
      cursor: 'pointer',
      boxSizing: 'border-box',
      height: '100%'
    },
    mainContainer: {
      position: 'relative',
      height: '100%'
    },
    actionBtn: {fontSize: '12px', padding: 0, width: '49.5%'},
    options: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  });
  

  // Handle click outside of the dropdown to close it
  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    if (visible) {
      document.addEventListener('mouseup', handleClickOutside);
      return () => {
        document.removeEventListener('mouseup', handleClickOutside);
      };
    }
  }, [visible]);

  useEffect(() => {
    if (!activeItem) {
      if (!certificationTriggered) {
        setActiveCategory([])
      }
    }
  }, [certificationTriggered, activeItem])

  // Parent
  const CertificationsHeader = ({ certificationTriggered, onClick }: any) => (
    <div 
      className={styles.parent}
      style={{ fontWeight: certificationTriggered ? 'bold' : 'normal' }}
      onClick={onClick}
    >
      {t("Certifications")}
    </div>
  );

  // Children
  const CategoryItem = ({ category, activeCategory, onClick }: any) => (
    <div
      key={category}
      className={styles.children}
      style={{ 
        backgroundColor: activeCategory === category ? '#333' : 'transparent', 
        fontWeight: !activeCategory?.includes(category) ? 'normal' : 'bold' 
      }}
      onClick={() => onClick(category)}
    >
      {category.charAt(0).toUpperCase() + category.slice(1)}
    </div>
  );

  // Grand Children
  const SubcategoryOptions = ({ items, activeItem, onItemClick }: any) => (
    <div>
      {items.map((item: any) => (
        <div key={item.key} className={styles.options}>
          <div className={styles.grandChildren}>
            {item.text}
          </div>
          <Checkbox 
            checked={activeItem === item}
            onChange={(event, checked) => onItemClick(item, checked)}
          />
        </div>
      ))}
    </div>
  );


  const renderSubcategories = () => {
    const handleCategoryClick = (category: any) => {
      setActiveCategory((prev: any) => {
        const isActive = prev.includes(category);
        if (isActive) {
          return prev.filter((c: any) => c !== category);
        } else {
          return [...prev, category];
        }
      });
    };
  
    const handleItemClick = (item: any, checked: boolean | undefined) => {
      if (checked) {
        setActiveItem(item);
        onApplyFilter(item)
      } else {
        setActiveItem(null);
        onApplyFilter(null)
      }
    };

    return (
      <div>
      {Object.keys(data?.certifications).map((category) => (
        <React.Fragment key={category}>
          <CategoryItem 
            category={category} 
            activeCategory={activeCategory} 
            onClick={handleCategoryClick} 
          />
          {category === 'socialSecurity' && activeCategory?.includes('socialSecurity') && (
            <SubcategoryOptions 
              items={data.certifications.socialSecurity} 
              activeItem={activeItem} 
              onItemClick={handleItemClick} 
            />
          )}
          {category === 'hse' && activeCategory?.includes('hse') && (
            <SubcategoryOptions 
              items={data.certifications.hse} 
              activeItem={activeItem} 
              onItemClick={handleItemClick} 
            />
          )}
        </React.Fragment>
      ))}
    </div>
    )
  };

  const ActionButton = ({onClickExpand, onClickCollapse}: {onClickExpand: () => void, onClickCollapse: () => void}) => {
    return (
      <div className={styles.actionBtnContainer}>
        <PrimaryButton
          text={t('Expand All')}
          onClick={onClickExpand}
          className={styles.actionBtn}
        />
        <PrimaryButton
          text={t('Collapse All')}
          onClick={onClickCollapse}
          className={styles.actionBtn}
        />
      </div>
    )
  }

  const renderDropdownContent = () => {
    return (
      <>
        <ActionButton 
          onClickExpand={() => {
            setCertificationTriggered(true);
            setActiveCategory(['socialSecurity', 'hse']);
          }}
          onClickCollapse={() => {
            setCertificationTriggered(false);
            setActiveCategory([])
          }}
        />
        <CertificationsHeader certificationTriggered={certificationTriggered} onClick={() => setCertificationTriggered(!certificationTriggered)}/>
        {certificationTriggered && <div>
          {renderSubcategories()}
          </div>}
      </>
    )
  };

  const renderBody = () => {
    if (buttonRef.current) {
      const buttonRect = buttonRef?.current?.getBoundingClientRect();
      const dropdownStyles: any = {
        position: 'absolute',
        top: `${buttonRect.bottom}px`, // Position dropdown just below the button
        left: `${buttonRect.left}px`, // Align dropdown with button
        width: `${buttonRect.width}px`,
        background: '#fff',
        // border: '1px solid #bebebe',
        color: '#000',
        padding: '10px',
        boxSizing: 'border-box',
        zIndex: 1000, // Ensure this is higher than other elements
        overflowY: 'auto',
        boxShadow: 'rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px'
      };

      return (
        <div style={dropdownStyles} ref={dropdownRef}>
          {renderDropdownContent()}
        </div>
      );
    }
    return null;
  }
  
  return (
    <div className={styles.mainContainer} style={{ width: width }} ref={dropdownRef}>
      <button
        onClick={() => setVisible(!visible)}
        type="button"
        className={styles.mainBtn}
        ref={buttonRef}
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <div style={{width: '95%'}}>
          {activeItem ? activeItem?.text : ""}
        </div>
        <div style={{width: '5%'}}>
          <Icon iconName='ChevronDown' />
        </div>
      </button>
      {visible && ReactDOM.createPortal(
        renderBody(),
        document.body // Render dropdown into body to avoid clipping issues
      )}
    </div>
  );
};


export default CertificationTypeHierarchyComponent
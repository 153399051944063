import React from 'react';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';
import { CommandBar } from '@fluentui/react';

const CommandBarLeft = (props: { disable: boolean }) => {
  const { t } = useTranslation();

  const newClick = () => {
    messageService.sendMessage({ isNewIR: true });
    // props.createNew(true);
};

  const getItems = () => {
    return [
      {
        // key: 'new',
        // text: t('new'),
        // iconProps: { iconName: 'Add' },
        // onClick: () => {
        //   messageService.sendMessage({ isNewIR: true });
        // },
        // disabled: props.disable,

        key: 'new',
        text: t('new'),
        iconProps: { iconName: 'Add' },
        onClick: () => {
          newClick();
        },
        disabled: false,
      },

      // {
      //   key: 'new',
      //   text: t('edit'),
      //   iconProps: { iconName: 'Edit' },
      //   onClick: () => {
      //     messageService.sendMessage({ isNewIR: true });
      //   },
      //   disabled: props.disable,
      // },
    ];
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );
};

export default CommandBarLeft;

import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  ActionButton,
  assign,
  Dropdown,
  FontWeights,
  getTheme,
  IBasePickerSuggestionsProps,
  Icon,
  IIconProps,
  IPersona,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  ITag,
  Label,
  Link,
  NormalPeoplePicker,
  Persona,
  Stack,
  TagPicker,
  Text,
  TextField,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { mergeStyles, mergeStyleSets } from '@fluentui/react/lib/Styling';
// import { TreeStructureDropdown } from '../../../shared/treeStructureDropdown/treeStructureDropdown';
import { uPrinceTheme } from '../../../../theme';
import Contact from '../../../types/contact';
import { AddressBookItem, Company } from '../../../types/addressBookItem';
import { BusinessCard } from '../headers/businessCard';
import { messageService } from '../../../services/messageService';
import { ActionButtonState } from '../../../types/corporateProductCatalogue';
import ContactGrid from '../contacts/component';
import HistoryBar from '../../../shared/historyBar/historyBar';
import i18n from '../../../../i18n';
import { CompetenceTaxonomy } from '../../../types/competency';
import { CertificationTaxonomy } from '../../../types/certification';
import OrganisationGrid from './grid/component';
import ContractorsTaxonomy from '../contractorsTaxonomy/contractorsTaxonomy';
import ENV from '../../../../env.json';
import CustomDropdown from '../../../shared/customDropdown/customDropdown';
import client from '../../../api';
import _ from 'lodash';

i18n;
// import InternetContactGrid from '../internet/component';

const theme = getTheme();
const addWhiteIconButtonStyles = {
  root: { color: uPrinceTheme.palette.white },
  rootHovered: { color: theme.palette.neutralDark },
};

const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    // minWidth: "30vw",
    // minHeight: "20vh",
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
});

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};
// Styles definition
const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    display: 'flex',
    flexGrow: 1,
    flexBasis: '50%',
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};

const stackItemStylestwo: IStackItemStyles = {
  root: {
    display: 'flex',
    flexBasis: '50%',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
  },
};

// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: i18n.t('Suggested People'),
  mostRecentlyUsedHeaderText: i18n.t('Suggested People'),
  noResultsFoundText: i18n.t('noresultsfound'),
  loadingText: i18n.t('loading'),
  showRemoveButtons: false,
  suggestionsAvailableAlertText: i18n.t('peoplePickerSuggestionsavailable'),
  suggestionsContainerAriaLabel: i18n.t('Suggested People'),
};
const addIcon: IIconProps = { iconName: 'Add' };
const limitedSearchAdditionalProps: IBasePickerSuggestionsProps = {
  resultsFooter: (props: any) => (
    <ActionButton
      className={classNames.actionButton}
      disabled={false}
      iconProps={addIcon}
      allowDisabledFocus
      onClick={() => {
        messageService.sendMessage({ isOpenNameModal: true });
      }}
    >
      <Label className={classNames.actionButton}>{i18n.t('new') as any}</Label>
    </ActionButton>
  ),
  // showForceResolve:false,
  showRemoveButtons: false,
  // searchForMoreText: i18n.t('Load all Results'),
  // resultsMaximumNumber: 10,
  searchingText: 'Searching...',
};

const editIcon: IIconProps = { iconName: 'Edit', styles: iconButtonStyles };

const tagSuggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: i18n.t('suggestedOrganisation'),
  mostRecentlyUsedHeaderText: i18n.t('suggestedOrganisation'),
  noResultsFoundText: i18n.t('noresultsfound'),
  loadingText: i18n.t('loading'),
  showRemoveButtons: true,
  suggestionsAvailableAlertText: i18n.t('peoplePickerSuggestionsavailable'),
  suggestionsContainerAriaLabel: i18n.t('suggestedOrganisation'),
};

const tagSearchAdditionalProps: IBasePickerSuggestionsProps = {
  suggestionsContainerAriaLabel: i18n.t('Suggested Organisation'),
  //searchForMoreText: i18n.t('Load all Results'),
  //resultsMaximumNumber: 10,
  searchingText: i18n.t('Searching') + '...',
  resultsFooter: (props: any) => (
    <ActionButton
      className={classNames.actionButton}
      disabled={false}
      iconProps={addIcon}
      allowDisabledFocus
      onClick={() => {
        messageService.sendMessage({ isOpenCompanyModal: true });
      }}
    >
      <Label className={classNames.actionButton}>{i18n.t('New') as any}</Label>
    </ActionButton>
  ),
};

const limitedSearchSuggestionProps: IBasePickerSuggestionsProps = assign(
  limitedSearchAdditionalProps,
  suggestionProps,
);

const tagSearchSuggestionProps: IBasePickerSuggestionsProps = assign(
  tagSearchAdditionalProps,
  tagSuggestionProps,
);

const DocumentPaneComponent = (props: {
  openInternetContactModel: () => void;
  openProfessionalInternetContactModal: () => void;
  openProfessionalPhoneContact: () => void;
  isOpenInternetModel: boolean;
  internetContacts: Contact[];
  professionalInternetContact: Contact[];
  professionalPhoneNumbers: Contact[];
  editInternetContact: () => void;
  internetContactSelection: any;
  internetContactSelectionDetails: {};
  internetContactActionButtonState: ActionButtonState;
  professionalnternetContactSelection: any;
  professionalnternetContactSelectionDetails: {};
  professionalInternetContactActionButtonState: ActionButtonState;
  professionalPhoneContactActionButtonState: ActionButtonState;
  professionalPhoneNumberSelection: any;
  professionalPhoneContactSelectionDetails: {};
  professionalInternetContactSelectionDetails: {};
  phoneNumberSelection: any;
  phoneNumbers: Contact[];
  editphoneContacts: () => void;
  phoneContactSelectionDetails: {};
  phoneContactActionButtonState: ActionButtonState;
  competenceSelection: any;
  competenceSelectionDetails: {};
  competenceActionButtonState: ActionButtonState;
  certificationSelection: any;
  certificationSelectionDetails: {};
  certificationActionButtonState: ActionButtonState;
  openPhoneContactModel: () => void;
  openCompetenceModal: () => void;
  openCertificationModal: () => void;
  handlePersonInputChange: any;
  handlePersonAddressInputChange: any;
  onFilterPersonChanged: any;
  validateInput: any;
  onInputChange: any;
  onCreateNewBusinessCardClick: any;
  onFilterCompanyChanged: any;
  countries: { key: string; text: string }[];
  handlePersonCountryInputChange: any;
  company: Company | null;
  addressBookItem: AddressBookItem;
  addressBookItemCopy: AddressBookItem;
  onCompanyItemSelected: any;
  onSaveCABEntry: any;
  onProfileImageChange: any;
  handlePersonCompanyInputChange: any;
  organisationInternetContactSelection: any;
  organisationalContact: Contact[];
  openOrganisatinalInternetContactModel: any;
  organisationPhoneContactSelection: any;
  organisationalPhoneContact: any;
  openOrganisatinalPhoneContactModel: any;
  organisationInternetContactSelectionDetails: {};
  organisationInternetContactActionButtonState: ActionButtonState;
  organisationPhoneContactSelectionDetails: {};
  organisationPhoneContactActionButtonState: ActionButtonState;
  editProfessionalInternetContactModel: () => void;
  editProfessionalPhoneContactModel: () => void;
  isNew: boolean;
  openCompanyModel: any;
  deleteProfessionalInternetContact: (type: string) => void;
  deleteProfessionalPhoneContact: (type: string) => void;
  deletePrivateInternetContact: (type: string) => void;
  deletePrivatePhoneContact: (type: string) => void;
  deleteOrganisationSettings: (type: string) => void;
  editCompanyModel: () => void;
  onRemoveCompanySuggestion: any;
  cabValidationMessage: {
    firstNameErrorMsg: string;
    surNameErrorMsg: string;
    fullNameErrorMsg: string;
  };
  onBlur: any;
  onPersonSelect: any;
  onRemovePersonSuggetion: any;
  competenceList: any;
  certificationList: any;
  readOnly: boolean;
  competencyTaxonomyList: CompetenceTaxonomy[];
  certificationTaxonomyList: CertificationTaxonomy[];
  handleContractorsTaxonomyChange: any;
  handlePersonNationalityChange?: any;
}) => {

  // const classNames = mergeStyleSets({

  // });
  const [nationality, setNationality] = useState<any>({ value: null, label: null});
  const { t } = useTranslation();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const onRenderCaretDown = (): JSX.Element => {
    return (
      <Icon
        iconName="ChevronDown"
        onClick={() => {

        }}
      />
    );
  };
  let selectedCompany: ITag[] | null = null;
  let selectedPerson: IPersona[] | null = null;
  if (props.company && props.company.name) {
    selectedCompany = [{ key: props.company.name, name: props.company.name }];
  }

  if (
    props.addressBookItem &&
    props.addressBookItem.person &&
    props.addressBookItem.person.firstName !== '' &&
    props.addressBookItem.person.firstName !== undefined
  ) {
    selectedPerson = [
      {
        key: props.addressBookItem.person.firstName,
        text: props.addressBookItem.person.firstName,
      },
    ];
  }

  useEffect(() => {
    if(props.addressBookItem.person?.nationality?.key){
      props.addressBookItem.person.nationalityId = props.addressBookItem.person?.nationality?.key;
      setNationality({value: props.addressBookItem.person?.nationality?.key, label: props.addressBookItem.person?.nationality?.text});
    };

  }, [props.addressBookItem.person?.nationality]);

  useEffect(() => {
    console.log('addressBookItemCopy',props.addressBookItemCopy)
    if (
      props.addressBookItemCopy &&
      props.addressBookItemCopy.person &&
      props.addressBookItemCopy.person.firstName !== '' &&
      props.addressBookItemCopy.person.firstName !== undefined
    ) {
      console.log('addressBookItemCopy',props.addressBookItemCopy.person.firstName)
      props.addressBookItem.person.firstName=props.addressBookItemCopy.person.firstName,
      selectedPerson = [
        {
          key: props.addressBookItemCopy.person.firstName,
          text: props.addressBookItemCopy.person.firstName,
        },
      ];
    }

  }, [props.addressBookItemCopy.person?.firstName]);



  const newItem = mergeStyles({ padding: '1px' });
  const existingItem = mergeStyles({ padding: '10px' });
  const onRenderSuggestionsItem = (props: any, itemProps: any): JSX.Element => {
    if (props.isNewItem) {
      return <div className={props.isNewItem ? newItem : existingItem} key={props.key}>
        <ActionButton iconProps={addIcon}>
          {props.text}
        </ActionButton>
      </div>;
    }
    return <div className={props.isNewItem ? newItem : existingItem} key={props.key}>
      <Persona
        {...props}
        size={1}
        hidePersonaDetails={false}
      />
    </div>;
  };

  const onRenderSuggestionsItemTagPicker = (props: any, itemProps: any): JSX.Element => {
    if (props.isNewItem) {
      return <div className={props.isNewItem ? newItem : existingItem} key={props.key}>
        <ActionButton iconProps={addIcon}>
          {props.name}
        </ActionButton>
      </div>;
    }
    return <div className={props.isNewItem ? newItem : existingItem} key={props.key}>
      {props.name}
    </div>;
  };

  const onPersonSelect = React.useCallback((item: any | undefined): any => {
    if (item) {
      if (item.isNewItem) {
        messageService.sendMessage({
          isOpenNameModal: true,
          personName: item.text,
        });
      } else {
        props.onPersonSelect(item);
      }
      return item;
    }
    return null;
  }, []);


  const onCompanyItemSelected = React.useCallback((item: any | undefined): any => {
    if (item) {
      if (item.isNewItem) {
        messageService.sendMessage({
          isOpenCompanyModal: true,
          companyName: item.name,
        });
      } else {
        props.onCompanyItemSelected(item);
      }
      return item;
    }
    return null;
  }, []);
  // 2222061492

  const nationalityOptions = (inputValue: any) =>
    new Promise((resolve) => {
      resolve(getNationalityByName(inputValue));
    });

  const getNationalityByName = async (name: string) => {
    let nationalities : any = [];
    if (name.length >= 2 || name==='') {
      const response = await client.post(
        'Ciaw/FilterNationality',
        {
          name: name,
        });
      return formatFilterResponse(response);
    }
    return nationalities;
  };

  const formatFilterResponse = (response:any) => {
    let options: { value: string; label: string }[] = [];
    if (response && response.data && response.data.result) {
      const data = response.data.result;

      if (data && _.isArray(data)) {
        options = data.map((item: any) => {
          return {
            value: item.key,
            label: item.text,
          };
        });
      }
      // Sort options by label in ascending order
      if(options.length>0){
        options.sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        })
      }
      return options;
    }
  };

  return (
    <div className="card-container" style={{ width: '100%' }} id={'productCatalogueForm'}>
      <div className="proj-detail-block" id="1">
        <div className="document-pane-card">
          <BusinessCard
            addressBookItem={props.addressBookItem}
            onProfileImageChange={props.onProfileImageChange}
          />
          <div className={'card-header'}>
            <Link href="#" id="business-card-header">
              <Label>1. {t('businessCard')} </Label>
            </Link>
          </div>
          <div className="proj-detail-content inner">
            <div className="marginTop">
              <div className={'card-body'}>
                <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <Label required={true}>{t('firstName')}</Label>
                      <NormalPeoplePicker
                        // eslint-disable-next-line react/jsx-no-bind

                        selectedItems={selectedPerson ? selectedPerson : []}
                        onResolveSuggestions={props.onFilterPersonChanged}
                        // eslint-disable-next-line react/jsx-no-bind
                        // onEmptyInputFocus={returnMostRecentlyUsed}
                        // getTextFromItem={getTextFromItem}
                        onChange={props.onRemovePersonSuggetion}
                        onItemSelected={onPersonSelect}
                        pickerSuggestionsProps={limitedSearchAdditionalProps}
                        className={'ms-PeoplePicker'}
                        key={'normal'}
                        itemLimit={1}
                        // eslint-disable-next-line react/jsx-no-bind
                        // onRemoveSuggestion={onRemoveSuggestion}
                        onValidateInput={props.validateInput}
                        removeButtonAriaLabel={'Remove'}
                        // inputProps={ {
                        //     onBlur: (ev: React.FocusEvent<HTMLInputElement>) => {},

                        //     onFocus: (ev: React.FocusEvent<HTMLInputElement>) => {}
                        // } }
                        inputProps={{
                          onBlur: () => console.log('onBlur called'),
                          onFocus: () => console.log('onFocus called'),
                          'aria-label': 'People Picker',
                        }}
                        onInputChange={props.onInputChange}
                        resolveDelay={300}
                        disabled={false}
                        onRenderSuggestionsItem={onRenderSuggestionsItem}
                      />

                      {props.cabValidationMessage.firstNameErrorMsg && (
                        <Text styles={{ root: { color: '#a4262c', fontSize: 12 } }}>
                          {props.cabValidationMessage.firstNameErrorMsg}
                        </Text>

                      )}
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        label={t('surname')}
                        errorMessage={props.cabValidationMessage.surNameErrorMsg}
                        required={true}
                        onChange={props.handlePersonInputChange('surname')}
                        value={
                          props.addressBookItem &&
                          props.addressBookItem.person &&
                          props.addressBookItem.person.surname
                            ? props.addressBookItem.person.surname
                            : ''
                        }
                      />
                    </div>
                  </Stack.Item>
                </Stack>

                <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>

                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        autoComplete="fullName"
                        label={t('fullName')}
                        required={true}
                        errorMessage={props.cabValidationMessage.fullNameErrorMsg}
                        value={
                          props.addressBookItem &&
                          props.addressBookItem.person &&
                          props.addressBookItem.person.fullName
                            ? props.addressBookItem.person.fullName
                            : ''
                        }
                        onChange={props.handlePersonInputChange('fullName')}
                        onBlur={() => {
                          props.onBlur();
                        }}
                      />
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        label={t('callName')}
                        value={
                          props.addressBookItem &&
                          props.addressBookItem.person &&
                          props.addressBookItem.person.callName
                            ? props.addressBookItem.person.callName
                            : ''
                        }
                        onChange={props.handlePersonInputChange('callName')}
                        onBlur={() => {
                          props.onBlur();
                        }}
                      />
                    </div>
                  </Stack.Item>
                </Stack>

                <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <Label>{t('organisation')}</Label>
                      <TagPicker
                        selectedItems={selectedCompany ? selectedCompany : []}
                        removeButtonAriaLabel="Remove"
                        onResolveSuggestions={props.onFilterCompanyChanged}
                        onItemSelected={onCompanyItemSelected}
                        onChange={props.onRemoveCompanySuggestion}
                        pickerSuggestionsProps={tagSearchSuggestionProps}
                        itemLimit={1}
                        disabled={false}
                        inputProps={{
                          onBlur: (ev: React.FocusEvent<HTMLInputElement>) =>
                            console.log(),
                          onFocus: (ev: React.FocusEvent<HTMLInputElement>) =>
                            console.log(),
                          'aria-label': 'Tag Picker',
                        }}
                        onRenderSuggestionsItem={onRenderSuggestionsItemTagPicker}
                      />
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        label={t('jobTitle')}
                        value={
                          props.addressBookItem &&
                          props.addressBookItem.personCompany &&
                          props.addressBookItem.personCompany.jobRole
                            ? props.addressBookItem.personCompany.jobRole
                            : ''
                        }
                        onBlur={() => {
                          props.onBlur();
                        }}
                        onChange={props.handlePersonCompanyInputChange('jobRole')}
                      />
                    </div>
                  </Stack.Item>
                </Stack>

                <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <Label>{t('nationality')}</Label>

                      <CustomDropdown
                        reference={''}
                        onChange={(value: any, label: any) => {
                          value ? setNationality({value: value, label: label}) : setNationality({value: null, label: null});
                          props.addressBookItem.person.nationalityId = value;
                        }}
                        selectedOption={nationality?.value ? nationality : null}
                        promiseOptions={nationalityOptions}
                        validationMessage={''}
                        required={false}
                      />
                    </div>
                  </Stack.Item>
                </Stack>

                <div>
                  <ContactGrid
                    contactSelection={props.professionalnternetContactSelection}
                    contacts={props.professionalInternetContact}
                    editContact={() => props.openProfessionalInternetContactModal()}
                    contactSelectionDetails={
                      props.professionalnternetContactSelectionDetails
                    }
                    contactActionButtonState={
                      props.professionalInternetContactActionButtonState
                    }
                    openModel={() => {
                      props.openProfessionalInternetContactModal();
                    }}
                    title={1.1 + ' ' + t('internet')}
                    deleteContact={() =>
                      props.deleteProfessionalInternetContact('internet')
                    }
                    readOnly={false}
                    valueHeader={t('accounts')}
                  />
                </div>

                <div>
                  <ContactGrid
                    contactSelection={props.professionalPhoneNumberSelection}
                    contacts={props.professionalPhoneNumbers}
                    editContact={() => props.openProfessionalPhoneContact()}
                    contactSelectionDetails={
                      props.professionalPhoneContactSelectionDetails
                    }
                    contactActionButtonState={
                      props.professionalPhoneContactActionButtonState
                    }
                    openModel={() => {
                      props.openProfessionalPhoneContact();
                    }}
                    title={1.2 + ' ' + t('phoneNumbers')}
                    deleteContact={() =>
                      props.deleteProfessionalPhoneContact('phone')
                    }
                    readOnly={false}
                    valueHeader={t('numbers')}
                  />
                </div>

                { /* <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    label={t('Email Address')}
                    value={
                      props.addressBookItem.person &&
                      props.addressBookItem.person.businessEmail
                        ? props.addressBookItem.person.businessEmail
                        : ''
                    }
                    onChange={props.handlePersonInputChange('businessEmail')}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    label={t('Mobile Number')}
                    type="mobile"
                    value={
                      props.addressBookItem.person &&
                      props.addressBookItem.person.businessPhone
                        ? props.addressBookItem.person.businessPhone
                        : ''
                    }
                    onChange={props.handlePersonInputChange('businessPhone')}
                  />
                </div>
              </Stack.Item>
            </Stack> */}
              </div>
            </div>
          </div>
        </div>
        <div className="proj-detail-block" id="2">
          <div className="document-pane-card">
            <div className={'card-header'}>

              <Link href="#" id="private-contact" className="marginTop">
                <Label>2. {t('contactDetails')} </Label>
              </Link>
            </div>

            <Label className="marginTop">2.1 {t('address')} </Label>

            <div className="proj-detail-content inner">
              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('street')}
                      onChange={props.handlePersonAddressInputChange('street')}
                      value={
                        props.addressBookItem &&
                        props.addressBookItem.person &&
                        props.addressBookItem.person.address &&
                        props.addressBookItem.person.address.street
                          ? props.addressBookItem.person.address.street
                          : ''
                      }
                      onBlur={() => {
                        props.onBlur();
                      }}
                    />
                  </div>
                </Stack.Item>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('streetNumber')}
                      onChange={props.handlePersonAddressInputChange(
                        'streetNumber',
                      )}
                      value={
                        props.addressBookItem &&
                        props.addressBookItem.person &&
                        props.addressBookItem.person.address &&
                        props.addressBookItem.person.address.streetNumber
                          ? props.addressBookItem.person.address.streetNumber
                          : ''
                      }
                      onBlur={() => {
                        props.onBlur();
                      }}
                    />
                  </div>
                </Stack.Item>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('mailBox')}
                      onChange={props.handlePersonAddressInputChange('mailBox')}
                      value={
                        props.addressBookItem &&
                        props.addressBookItem.person &&
                        props.addressBookItem.person.address &&
                        props.addressBookItem.person.address.mailBox
                          ? props.addressBookItem.person.address.mailBox
                          : ''
                      }
                      onBlur={() => {
                        props.onBlur();
                      }}
                    />
                  </div>
                </Stack.Item>
              </Stack>

              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('postalCode')}
                      onChange={props.handlePersonAddressInputChange(
                        'postalCode',
                      )}
                      value={
                        props.addressBookItem &&
                        props.addressBookItem.person &&
                        props.addressBookItem.person.address &&
                        props.addressBookItem.person.address.postalCode
                          ? props.addressBookItem.person.address.postalCode
                          : ''
                      }
                      onBlur={() => {
                        props.onBlur();
                      }}
                    />
                  </div>
                </Stack.Item>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('city')}
                      value={
                        props.addressBookItem &&
                        props.addressBookItem.person &&
                        props.addressBookItem.person.address &&
                        props.addressBookItem.person.address.city
                          ? props.addressBookItem.person.address.city
                          : ''
                      }
                      onChange={props.handlePersonAddressInputChange('city')}
                      onBlur={() => {
                        props.onBlur();
                      }}
                    />
                  </div>
                </Stack.Item>
              </Stack>

              <Stack
                horizontal
                wrap
                styles={stackItemStylestwo}
                tokens={stackTokens}
              >
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('region')}
                      value={
                        props.addressBookItem &&
                        props.addressBookItem.person &&
                        props.addressBookItem.person.address &&
                        props.addressBookItem.person.address.region
                          ? props.addressBookItem.person.address.region
                          : ''
                      }
                      onChange={props.handlePersonAddressInputChange('region')}
                      onBlur={() => {
                        props.onBlur();
                      }}
                    />
                  </div>
                </Stack.Item>

                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <Dropdown
                      placeholder={t('country')}
                      label={t('country')}
                      selectedKey={
                        props.addressBookItem &&
                        props.addressBookItem.person &&
                        props.addressBookItem.person.address &&
                        props.addressBookItem.person.address.countryId
                          ? props.addressBookItem.person.address.countryId
                          : -1
                      }
                      options={props.countries}
                      onRenderCaretDown={onRenderCaretDown}
                      onChange={props.handlePersonCountryInputChange('countryId')}
                    />
                  </div>
                </Stack.Item>
              </Stack>
            </div>

            <div className="marginTop">
              <ContactGrid
                contactSelection={props.internetContactSelection}
                contacts={props.internetContacts}
                editContact={() => props.openInternetContactModel()}
                contactSelectionDetails={props.internetContactSelectionDetails}
                contactActionButtonState={props.internetContactActionButtonState}
                openModel={() => {
                  props.openInternetContactModel();
                }}
                title={2.2 + ' ' + t('internet')}
                deleteContact={() => {
                  props.deletePrivateInternetContact('internet');
                }}
                readOnly={false}
                valueHeader={t('accounts')}
              />
            </div>
            <div className="marginTop">
              <ContactGrid
                contactSelection={props.phoneNumberSelection}
                contacts={props.phoneNumbers}
                editContact={() => props.openPhoneContactModel()}
                contactSelectionDetails={props.phoneContactSelectionDetails}
                contactActionButtonState={props.phoneContactActionButtonState}
                openModel={() => {
                  props.openPhoneContactModel();
                }}
                title={2.3 + ' ' + t('phoneNumbers')}
                deleteContact={() => {
                  props.deletePrivatePhoneContact('phone');
                }}
                readOnly={false}
                valueHeader={t('numbers')}
              />
            </div>
          </div>
        </div>

        <div className="proj-detail-block" id="3">
          <div className="document-pane-card">
            <div className={'card-header'}>
              <Link href="#" id="competencies" className="marginTop">
                <Label>3. {t('competencies')} </Label>
              </Link>
            </div>

            <div className="marginTop">
              <OrganisationGrid
                organisationSelection={props.competenceSelection}   //create a selection
                editOrganisation={() => props.openCompetenceModal()}
                organisationSelectionDetails={props.competenceSelectionDetails}
                organisationActionButtonState={props.competenceActionButtonState}
                openModel={() => props.openCompetenceModal()}
                title={3.1 + ' ' + t('competenciesSkill')}
                deleteDataGrid={() => {
                  props.deleteOrganisationSettings('competence');
                }}
                readOnly={props.readOnly}
                listItem={props.competenceList ? props.competenceList : []}
                taxonomyList={props.competencyTaxonomyList}
                isCertificate={false}
              />
            </div>
          </div>
        </div>

        <div className="proj-detail-block" id="4">
          <div className="document-pane-card">
            <div className={'card-header'}>
              <Link href="#" id="certifications" className="marginTop">
                <Label>4. {t('certifications')} </Label>
              </Link>
            </div>

            <div className="marginTop">
              <OrganisationGrid
                organisationSelection={props.certificationSelection}
                editOrganisation={() => props.openCertificationModal()}
                organisationSelectionDetails={props.certificationSelectionDetails}
                organisationActionButtonState={props.certificationActionButtonState}
                openModel={() => {
                  props.openCertificationModal();
                }}
                title={4.1 + ' ' + t('certificationsQualification')}
                deleteDataGrid={() => {
                  props.deleteOrganisationSettings('certification');
                }}
                readOnly={props.readOnly}
                listItem={props.certificationList ? props.certificationList : []}
                taxonomyList={props.certificationTaxonomyList}
                isCertificate={true}
              />
            </div>
          </div>
        </div>

        <div className="proj-detail-block" id="5">
          <div className="document-pane-card">
            <Link href="#" id="organisation-contact" className="marginTop">
              <Stack horizontal>

                <div className={'card-header'}>
                  <Stack.Item grow={12}>
                    <Label>5. {t('organisationDetails')} </Label>
                  </Stack.Item>
                </div>
                {((props.addressBookItem && props.addressBookItem.companyId) ||
                  (props.addressBookItem &&
                    props.addressBookItem.company &&
                    props.addressBookItem.company.name)) && (
                  <Stack.Item grow={6} styles={{ root: { flexDirection: 'row-reverse' } }}>
                    { /* {!props.isNew && ( */}
                    <ActionButton
                      style={{ float: 'right' }}
                      className={classNames.actionButton}
                      iconProps={editIcon}
                      allowDisabledFocus
                      onClick={() => {
                        props.editCompanyModel();
                      }}
                    >
                      <Label
                        className={classNames.actionButton}
                        style={{ fontSize: 14 }}
                      >
                        {t('edit')}
                      </Label>
                    </ActionButton>
                    { /* )} */}
                  </Stack.Item>
                )}
              </Stack>
            </Link>

            <div className="proj-detail-content inner">
              <Label>5.1 {t('general')} </Label>
              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      readOnly
                      label={t('name')}
                      value={
                        props.addressBookItem &&
                        props.addressBookItem.company &&
                        props.addressBookItem.company.name
                          ? props.addressBookItem.company.name
                          : ''
                      }
                    />
                  </div>
                </Stack.Item>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('vat')}
                      readOnly
                      value={
                        props.addressBookItem &&
                        props.addressBookItem.company &&
                        props.addressBookItem.company.vat
                          ? props.addressBookItem.company.vat
                          : ''
                      }
                    />
                  </div>
                </Stack.Item>
              </Stack>
              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('bankAccount')}
                      readOnly
                      value={
                        props.addressBookItem &&
                        props.addressBookItem.company &&
                        props.addressBookItem.company.bankAccount
                          ? props.addressBookItem.company.bankAccount
                          : ''
                      }
                    />
                  </div>
                </Stack.Item>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('accountancyCompanyNumber')}
                      readOnly
                      value={
                        props.addressBookItem &&
                        props.addressBookItem.company &&
                        props.addressBookItem.company.accountingNumber
                          ? props.addressBookItem.company.accountingNumber
                          : ''
                      }
                    />
                  </div>
                </Stack.Item>
              </Stack>

              <div className="marginTop">
                <Label>5.2 {t('address')} </Label>
                <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        label={t('street')}
                        readOnly
                        value={
                          props.addressBookItem &&
                          props.addressBookItem.company &&
                          props.addressBookItem.company.address &&
                          props.addressBookItem.company.address.street
                            ? props.addressBookItem.company.address.street
                            : ''
                        }
                      />
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        label={t('streetNumber')}
                        readOnly
                        value={
                          props.addressBookItem &&
                          props.addressBookItem.company &&
                          props.addressBookItem.company.address &&
                          props.addressBookItem.company.address.streetNumber
                            ? props.addressBookItem.company.address.streetNumber
                            : ''
                        }
                      />
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        label={t('mailBox')}
                        readOnly
                        value={
                          props.addressBookItem &&
                          props.addressBookItem.company &&
                          props.addressBookItem.company.address &&
                          props.addressBookItem.company.address.mailBox
                            ? props.addressBookItem.company.address.mailBox
                            : ''
                        }
                      />
                    </div>
                  </Stack.Item>
                </Stack>

                <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        label={t('postalCode')}
                        readOnly
                        value={
                          props.addressBookItem &&
                          props.addressBookItem.company &&
                          props.addressBookItem.company.address &&
                          props.addressBookItem.company.address.postalCode
                            ? props.addressBookItem.company.address.postalCode
                            : ''
                        }
                      />
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        label={t('city')}
                        readOnly
                        value={
                          props.addressBookItem &&
                          props.addressBookItem.company &&
                          props.addressBookItem.company.address &&
                          props.addressBookItem.company.address.city
                            ? props.addressBookItem.company.address.city
                            : ''
                        }
                      />
                    </div>
                  </Stack.Item>
                </Stack>

                <Stack
                  horizontal
                  wrap
                  styles={stackItemStylestwo}
                  tokens={stackTokens}
                >
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        label={t('region')}
                        readOnly
                        value={
                          props.addressBookItem &&
                          props.addressBookItem.company &&
                          props.addressBookItem.company.address &&
                          props.addressBookItem.company.address.region
                            ? props.addressBookItem.company.address.region
                            : ''
                        }
                      />
                    </div>
                  </Stack.Item>

                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <Dropdown
                        placeholder={t('country')}
                        label={t('country')}
                        disabled={true}
                        selectedKey={
                          props.addressBookItem &&
                          props.addressBookItem.company &&
                          props.addressBookItem.company.address &&
                          props.addressBookItem.company.address.countryId
                            ? props.addressBookItem.company.address.countryId
                            : ''
                        }
                        options={props.countries}
                        onRenderCaretDown={onRenderCaretDown}
                      />
                    </div>
                  </Stack.Item>
                </Stack>
              </div>
            </div>

            <div className="marginTop">
              <ContactGrid
                contactSelection={props.organisationInternetContactSelection}
                contacts={props.organisationalContact}
                editContact={() => props.editInternetContact()}
                contactSelectionDetails={
                  props.organisationPhoneContactSelectionDetails
                }
                contactActionButtonState={
                  props.organisationInternetContactActionButtonState
                }
                openModel={() => {
                  props.openOrganisatinalInternetContactModel();
                }}
                title={5.3 + ' ' + t('internet')}
                deleteContact={() => {
                }}
                readOnly={true}
                valueHeader={t('accounts')}
              />
            </div>
            <div className="marginTop">
              <ContactGrid
                contactSelection={props.organisationPhoneContactSelection}
                contacts={props.organisationalPhoneContact}
                editContact={() => props.editphoneContacts()}
                contactSelectionDetails={
                  props.organisationPhoneContactSelectionDetails
                }
                contactActionButtonState={
                  props.organisationPhoneContactActionButtonState
                }
                openModel={() => {
                  props.openOrganisatinalPhoneContactModel();
                }}
                title={5.4 + ' ' + t('phoneNumbers')}
                deleteContact={() => {
                }}
                readOnly={true}
                valueHeader={t('numbers')}
              />
            </div>

            {ENV.FEATURES_CARD_CONFIG.CAB_CONTRACTOR_TAXONOMY && <div className="marginTop">
              <div className="proj-detail-content inner">
                <Label>5.5. {t('lots')} </Label>
                <ContractorsTaxonomy
                  disabled={true}
                  label={t('contractorsTaxonomy')}
                  selectItemIds={Array.isArray(props.addressBookItem?.company?.contractorTaxonomyId) ? props.addressBookItem?.company?.contractorTaxonomyId : []}
                  onChange={(selectedNode: any) => {
                    props.handleContractorsTaxonomyChange(selectedNode?.id);
                  }}
                />
              </div>
            </div>}
          </div>
        </div>

        <div className="proj-detail-block" id="6">
          {!props.isNew &&
          props.addressBookItem &&
          props.addressBookItem.isSaved &&
          props.addressBookItem.history && (
            <div className="document-pane-card">
              <div className="proj-detail-content inner">

                <div className="marginTop marginBottom">
                  <div className={'card-header'}>
                    <Link href="#" id="history">
                      <Label>6. {t('history')} </Label>
                    </Link>
                  </div>
                  <HistoryBar
                    createdByUser={
                      props.addressBookItem.history.createdByUser
                        ? props.addressBookItem.history.createdByUser
                        : ''
                    }
                    updatedByUser={
                      props.addressBookItem.history.updatedByUser
                        ? props.addressBookItem.history.updatedByUser
                        : ''
                    }
                    createdDateTime={
                      props.addressBookItem.history.createdDateTime
                        ? props.addressBookItem.history.createdDateTime
                        : ''
                    }
                    updatedDateTime={
                      props.addressBookItem.history.updatedDateTime
                        ? props.addressBookItem.history.updatedDateTime
                        : ''
                    }
                  />
                </div>


                { /* <div className="marginTop">
              <PrimaryButton
              disabled={true}
                text={t('save')}
                style={{ marginTop: 20, marginBottom: 10 }}
                onClick={() => {
                  props.onSaveCABEntry();
                }}
              />
            </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentPaneComponent);

import * as React from 'react';
import i18n from '../../../../../../i18n';
import { RESOURCE_TYPE } from '../../../../../types/purchaseOrder';
import DataGrid from '../../../../../shared/dataGrid/component';
import { Selection } from '@fluentui/react';
import { ActionButtonState } from '../../../../../types/corporateProductCatalogue';
import CbcModal from './CbcModal';
import { useEffect } from 'react';
import { getPbsCbcResourcesByProductId, saveDayPlanningStateAttr } from '../../../../../reducers/dayPlanningReducer';
import { useDispatch, useSelector } from 'react-redux';

const CbcGrid = ({onChange,formData}:any) => {
  const [isOpenModal, setIsOpenModal]: any = React.useState<any>(false);
  const [selectionDetails, setSelectionDetails]: any = React.useState<any>(null);
  const [pmolCbcList, setPmolCbcList]: any = React.useState<any>([]);
  const dispatch = useDispatch();
  const {
    pbsCbcList,
  } = useSelector((state: any) => state.dplan);

  const cbcColumns = [
    {
      key: 'column1',
      name: i18n.t('cbc'),
      fieldName: 'title',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    },
    {
      key: 'column3',
      name: i18n.t('originalQuantity'),
      fieldName: 'cbcQuantity',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    },
    {
      key: 'column4',
      name: i18n.t('plannedQuantity'),
      fieldName: 'quantity',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    },
    {
      key: 'column5',
      name: i18n.t('unit'),
      fieldName: 'unit',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    }
  ];

  useEffect(()=>{
    if(formData?.pbsTaxonomy){
      getPbsCbc()
    }else{
      dispatch(saveDayPlanningStateAttr('pbsCbcList', []));
    }
  },[formData?.pbsTaxonomy])

  const getPbsCbc=()=>{
    const filter = { title: "",pbsId:formData?.pbsTaxonomy,projectSequenceId:formData?.slectedProject?.sequenceCode,contractingUnitId:formData?.slectedProject?.contractingUnitId };
    dispatch(getPbsCbcResourcesByProductId(filter))
  }

    useEffect(() => {
      console.log({pbsCbcList});
      setPmolCbcList(pbsCbcList);
    }, [pbsCbcList]);

    useEffect(() => {
     onChange(pmolCbcList);
    }, [pmolCbcList]);


  const openCbcModal=()=>{
    setIsOpenModal(!isOpenModal);
  }

  const _gridSelection = new Selection({
    onSelectionChanged: () => {
      setSelectionDetails(_gridSelection.getSelection());

    }
  });


  const cbcActionButtonState=()=> {
    const selectionCount = selectionDetails?.length;
    let disabled: ActionButtonState;
    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: !formData?.pbsTaxonomy,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  const onSave=(data:any)=>{
    setPmolCbcList((prevList:any) => [...prevList, data]);
  }
  const onEditSave=(data:any)=>{
    setPmolCbcList((prevList:any) =>
      prevList.map((item:any) => (item.key === data.key ? data : item))
    );
  }

  const handleCbcDelete=()=>{
    let ids=selectionDetails?.map((item:any)=>{
      return item?.key
    })
    setPmolCbcList((prevList:any) =>
      prevList.filter((item:any) => !ids.includes(item.key))
    );
  }

 return (
  <>
    <DataGrid
      dataList={pmolCbcList?pmolCbcList:[]}
      editDataGrid={() => openCbcModal()}
      openModel={() => {
        openCbcModal();
      }}
      selection={_gridSelection}
      actionButtonState={cbcActionButtonState()}
      title={'3 ' + i18n.t('cbc')}
      deleteDataGrid={() => {
        handleCbcDelete();
      }}
      readOnly={false}
      columns={cbcColumns}
      isLoaded={false}
      type={RESOURCE_TYPE.CBC}
      isClickable={true}
    />
    <CbcModal isOpen={isOpenModal} openModal={openCbcModal} formData={selectionDetails?selectionDetails[0]:{}} pbsData={formData} onSave={onSave} onEditSave={onEditSave}/>

  </>
 );

};

export default CbcGrid

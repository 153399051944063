import React, { Component } from 'react';
import UprinceNavigation from '../../shared/uprinceNavigation/cuProjectNavigation/component';
import { getContractingUnit, getProject } from '../../shared/util';
import StockMainLayout from './stockMainLayout';


interface Props {
  currentUser: any
}

interface State {
  isContractUnit: boolean;
  isProject: boolean;
}

export class Stock extends Component<Props, State> {
  render() {
    return (
      <UprinceNavigation
        currentUser={this.props.currentUser}
        selectedNavigationIndex={getProject() ? `/CU/${getContractingUnit()}/project/${getProject()}/stock` : `/CU/${getContractingUnit()}/stock`}
      >
        <StockMainLayout/>
      </UprinceNavigation>
    );
  }
}

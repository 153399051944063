import React, {useEffect} from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../services/authConfig";
import {IStackStyles, Label, PrimaryButton, Stack,IStackTokens,IStackItemStyles} from "@fluentui/react";
import i18n from "../../i18n";
import {uPrinceTheme} from "../../theme";
import {useDispatch} from "react-redux";
import {getThemeColor} from "../reducers/uprinceReducer";
import AuthService from "../services/auth.service";
import ENV from '../../env.json';

// Styles definition
const stackStyles: IStackStyles = {
    root: {
        width: '100%',
    },
};

const itemAlignmentsStackTokens: IStackTokens = {};
const stackItemStyles: IStackItemStyles = {
    root: {
        color: uPrinceTheme.palette.white,
    },
};

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
let isSafari = navigator.vendor.match(/apple/i) &&
             !navigator.userAgent.match(/crios/i) &&
             !navigator.userAgent.match(/fxios/i) &&
             !navigator.userAgent.match(/Opera|OPT\//);

// Tokens definition
const stackTokens: IStackTokens = {};

const Login = () => {
    let signInType;
    const { instance } = useMsal();
    const dispatch = useDispatch();
    const authService=new AuthService();

    useEffect(()=>{
        dispatch(getThemeColor());
    },[]);

    const handleLogin =(instance:any)=> {

    signInType = isSafari ? "redirect" : 'popup';
    if (signInType === "popup") {
        return instance.loginPopup(loginRequest).then((res:any)=>{}).catch(function (error:any) {
            console.log(error);
        });
    } else if (signInType === "redirect") {
        return instance.loginRedirect(loginRequest)
    }


        // instance.loginPopup(loginRequest).then((res:any)=>{

        // }).catch((error:any) => {
        //     console.error({error});
        // });
    };

    const getLoginHeaderText=()=>{
        const language =  window.navigator.language;
      if(language){
          switch (language) {
              case "en-US":
                  return "Sign in with your existing account";
              case "en":
                  return "Sign in with your existing account";
              case "nl":
                  return "Log in met je reeds bestaande account";
              case "nl-BE":
                  return "Log in met je reeds bestaande account";
              case "de":
                  return "Log in met je reeds bestaande account";
              default:
                  return "Sign in with your existing account";
          }

      }else{
          return "Sign in with your existing account";
      }
    };

    return (
        <div className={'ms-Grid'} dir="ltr" style={{ margin: 0, padding: 0 }}>
            <div className={'ms-Grid'} style={{ margin: 0, padding: 0 }}>
                <div
                    className={`ms-Grid-col ms-sm9 ms-md9 padding-left-0`}
                    style={{
                        transition: 'all 0.9s linear',
                        backgroundImage: 'url(' + ENV.IMG_LOGIN_BACKGROUND + ')',
                        backgroundAttachment: 'fixed',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        height: '100vh',
                        margin: 0,
                        padding: 0,
                    }}
                ></div>
                <div
                    className={`ms-Grid-col ms-sm3 ms-md3 padding-left-0`}
                    style={{
                        transition: 'all 0.9s linear',
                        height: '100vh',
                        margin: 0,
                        padding: 0,
                    }}
                >
                    <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                        <Stack styles={stackStyles} tokens={itemAlignmentsStackTokens}>
                            <Stack.Item align="center" styles={stackItemStyles}>
                    <span>
                      {' '}
                        <img
                            style={{ width: '14vw' }}
                            alt="UPrince"
                            className="login-logo"
                            src={ENV.IMG_LOGIN}
                        />
                    </span>
                            </Stack.Item>
                        </Stack>
                        <Stack styles={stackStyles} tokens={itemAlignmentsStackTokens}>
                            <Stack.Item align="center" styles={stackItemStyles}>
                    <span>
                      <Label style={{ fontSize: 18 }}>
                        {getLoginHeaderText()}
                      </Label>
                    </span>
                            </Stack.Item>
                        </Stack>
                    </Stack>
                    <div className="divider"></div>
                    <div style={{ marginTop: '13vh' }}>
                        <Stack
                            horizontal
                            wrap
                            styles={stackStyles}
                            tokens={stackTokens}
                        >
                            <Stack
                                styles={stackStyles}
                                tokens={itemAlignmentsStackTokens}
                            >
                                <Stack.Item align="center" styles={stackItemStyles}>
                      <span>
                        <PrimaryButton
                            onClick={() => handleLogin(instance)}
                            style={{ width: '16vw' }}
                            text={i18n.t('Login')}
                        />
                      </span>
                                </Stack.Item>
                            </Stack>
                        </Stack>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login

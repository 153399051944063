import React from 'react';
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import {Calendar, momentLocalizer, Views} from "react-big-calendar";
import moment from "moment/moment";
import {ICommandBarItemProps, Label} from "@fluentui/react";
import EventBody from "../../../projectDayPlanning/listPane/eventBody";
import TREventBody from "../../../timeRegistration/documentPane/trEventBody";
import CalenderToolBar from './calendarToolBar/component';
import {useTranslation} from "react-i18next";

moment.locale('en-GB');
const localizer = momentLocalizer(moment);

const CalendarComponent = (props: {
    executionDate: any,
    eventList: any,
    totalsData: any,
}) => {
    const {executionDate, eventList, totalsData} = props;

    const {t} = useTranslation();

    const DragAndDropCalendar = withDragAndDrop(Calendar as any);

    const resourceMap = [
        {resourceId: 1, resourceTitle: `${t('dayPlanning')}`},
        {resourceId: 2, resourceTitle: `${t('vts')}`},
        {resourceId: 3, resourceTitle: `${t('trApp')}`},
        {resourceId: 4, resourceTitle: `${t('HR')}`},
    ];

    const CustomResourceHeader = ({label, index, resource}: any) => {
        return <div
          style={{
              width: '100%',
              minHeight: '70px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
          }}>{resource?.resourceTitle}</div>
    };

    const CustomEvent = ({event}: any) => {
        const _overflowItems: ICommandBarItemProps[] = [
            {
                key: 'open', text: t('open'), onClick: () => {
                }, iconProps: {iconName: 'MoveToFolder'}
            },
            {
                key: 'create', text: t('createTransportPMOL'), onClick: () => {
                }, iconProps: {iconName: 'Add'}
            },
        ];

        if (event.resourceId === 1) {
            return (
              <EventBody pmol={event} overflowItems={_overflowItems}/>
            );
        } else if (event.resourceId === 2) {
            return (
              <TREventBody pmol={event} overflowItems={_overflowItems} isVTS={true}/>
            );
        } else if (event.resourceId === 3) {
            // console.log('event>>> ',event)
            return (
              <TREventBody pmol={event} overflowItems={_overflowItems} isVTS={false}/>
            );
        } else {
            return (
              <EventBody pmol={event} overflowItems={_overflowItems}/>
            );
        }
    };

    const tooltipAccessor = (event: any) => {
        return ""
    }

    return <>
        <DragAndDropCalendar
          defaultDate={executionDate ? new Date(executionDate) : new Date()}
          defaultView={Views.DAY}
          events={eventList ? eventList : []}
          localizer={localizer}
          // onEventDrop={moveEvent}
          // onEventResize={resizeEvent}
          // popup
          // resizable
          components={{
              toolbar: CalenderToolBar,
              resourceHeader: CustomResourceHeader,
              event: CustomEvent
          }}
          resources={resourceMap}
          resourceIdAccessor={(resource: any) => resource?.resourceId}
          resourceTitleAccessor={(resource: any) => resource?.resourceTitle}
          tooltipAccessor={tooltipAccessor}
          dayLayoutAlgorithm="no-overlap"
          formats={{
              eventTimeRangeFormat: ({start, end}, culture: any, localizer: any) =>
                localizer.format(start, 'HH:mm', culture) +
                ' - ' +
                localizer.format(end, 'HH:mm', culture),
              timeGutterFormat: (date, culture: any, localizer: any) =>
                localizer.format(date, 'HH:mm', culture),
          }}
        />
        <div style={{display: 'flex', marginLeft: 44, justifyContent: 'space-around'}}>
            <Label>{`${t('total')} ${totalsData?.total ? `: ${totalsData?.total}` : ''}`}</Label>
            <Label>{`${t('totalVTS')} ${totalsData?.totalVTS ? `: ${totalsData?.totalVTS}` : ''}`}</Label>
            <Label>{`${t('totalTrApp')} ${totalsData?.totalTrApp ? `: ${totalsData?.totalTrApp}` : ''}`}</Label>
            <Label>{`${t('totalHr')} ${totalsData?.totalHr ? `: ${totalsData?.totalHr}` : ''}`}</Label>
        </div>
    </>
}

export default CalendarComponent;
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
    ActionButton,
    DatePicker,
    defaultCalendarStrings,
    Dropdown,
    IStackStyles,
    IStackTokens,
    mergeStyleSets,
    Pivot,
    PivotItem,
    Stack,
} from '@fluentui/react';
import {DpViewMode} from '../../../types/myDayPlanning';
import {stringDateToDateObject} from '../../../shared/util';
import {saveThAutomationStateAttr} from '../../../reducers/thAutomationReducer';
import {hourlyDropdown, viewModeDp} from './commandBarDropdowns';

const classNames = mergeStyleSets({
    wrapper: {
        display: 'block',
        maxWidth: 200,
        minWidth: 120,
    },
    aligment: {
        margin: '5px 0 0 5px'
    },
    fullWidth: {width: '100%'},
    halfWidth: {width: '49%'}
});

const stackStyles: IStackStyles = {root: {padding: 0, marginBottom: 10}};

const stackTokens: IStackTokens = {childrenGap: 10};

const CommandBarLeft = (props: { disable: boolean, isTopPane?: boolean, onSelectDate?: any, refresh?: any }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {
        userInfo
    } = useSelector(
      (state: any) => state.uprince
    );
    const {
        isPmolViewMode
    } = useSelector(
      (state: any) => state.thAutomation
    );

    const {
        selectedResourceType,
        thAutomationViewMode,
        selectedDate,
        selectedBu,
        truckResourceViewMode,
        isTruckViewMode,
        thaStartTime,
        thaEndTime
    } = useSelector(
      (state: any) => state.thAutomation
    );

    const {planboardPersonId, planboardPersonName} = useSelector(
      (state: any) => state.mydPlan
    );

    return (
      props.isTopPane ?
        <div className={`${classNames.aligment}`}>
            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
                <Stack.Item>
                    <div className={`${classNames.wrapper}`} style={{marginTop: 10}}>
                        <Dropdown
                          placeholder={t('startTime')}
                          onChange={(ev, item) => {
                              dispatch(saveThAutomationStateAttr('thaStartTime', item?.key));
                          }}
                          selectedKey={thaStartTime}
                          disabled={false}
                          options={hourlyDropdown(thaStartTime, thaEndTime)[0]}
                        /></div>
                </Stack.Item>
                <Stack.Item>
                    <div className={`${classNames.wrapper}`} style={{marginTop: 10}}>
                        <Dropdown
                          placeholder={t('endTime')}
                          onChange={(ev, item) => {
                              dispatch(saveThAutomationStateAttr('thaEndTime', item?.key));
                          }}
                          selectedKey={thaEndTime}
                          disabled={false}
                          options={hourlyDropdown(thaStartTime, thaEndTime)[1]}
                        /></div>
                </Stack.Item>
                <Stack.Item>
                    <div className={`${classNames.wrapper}`} style={{marginTop: 10}}>
                        <Dropdown
                          placeholder={t('viewMode')}
                          onChange={(ev, item) => {
                              dispatch(saveThAutomationStateAttr('thAutomationViewMode', item?.key));
                          }}
                          selectedKey={thAutomationViewMode ? thAutomationViewMode : DpViewMode.OneHour}
                          disabled={false}
                          options={viewModeDp}
                        /></div>
                </Stack.Item>
                {props.onSelectDate && <Stack.Item>
                    <div className={`${classNames.wrapper}`}>
                        <div style={{width: 200, paddingLeft: 5}}>
                            <DatePicker
                                onSelectDate={props?.onSelectDate}
                                placeholder={t('selectadate')}
                                ariaLabel={t('projectCompletionDate')}
                                value={stringDateToDateObject(selectedDate)}
                                strings={defaultCalendarStrings}
                            />
                        </div>
                    </div>
                </Stack.Item>}
                {props.refresh && <Stack.Item>
                    <div className={`${classNames.wrapper}`}>
                        <div style={{width: 200, paddingLeft: 5}}>
                            <ActionButton iconProps={{iconName: 'Refresh'}} onClick={() => {
                                props.refresh()
                            }} allowDisabledFocus>
                                {t('refresh')}
                            </ActionButton>
                        </div>
                    </div>
                </Stack.Item>}
                <Stack.Item>
                    <div className={`${classNames.wrapper}`}>
                        <Pivot
                          selectedKey={isPmolViewMode ? 'pmol' : 'product'}
                          onLinkClick={(item?: PivotItem) => {
                              if (item) {
                                  if (item.props.itemKey! === 'pmol') {
                                      dispatch(saveThAutomationStateAttr('isPmolViewMode', true));
                                  } else if (item.props.itemKey! === 'product') {
                                      dispatch(saveThAutomationStateAttr('isPmolViewMode', false));
                                  }
                              }
                          }}
                        >
                            <PivotItem headerText={t('product')} itemKey={`product`}/>
                            <PivotItem headerText={t('pmol')} itemKey={`pmol`}/>

                        </Pivot>

                        {/*<div style={{width:200,paddingLeft:5}}>*/}
                        {/*  <Toggle checked={isPmolViewMode} label=""  onText="Pmol" offText="Product" onChange={_onChangeToggle} />*/}
                        {/*</div>*/}
                    </div>
                </Stack.Item>
            </Stack>
        </div> :
        <div className={`${classNames.aligment}`}>
            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
                <Stack.Item>
                    <div className={`${classNames.wrapper}`}>
                        <Dropdown
                          placeholder={t('viewMode')}
                          onChange={(ev, item) => {
                              dispatch(saveThAutomationStateAttr('truckResourceViewMode', item?.key));
                          }}
                          selectedKey={truckResourceViewMode ? truckResourceViewMode : DpViewMode.OneHour}
                          disabled={false}
                          options={viewModeDp}
                        /></div>
                </Stack.Item>
                <Stack.Item>
                    <div className={`${classNames.wrapper}`}>
                        <Pivot
                          selectedKey={isTruckViewMode ? 'truck' : 'pumpCar'}
                          onLinkClick={(item?: PivotItem) => {
                              if (item) {
                                  if (item.props.itemKey === 'truck') {
                                      dispatch(saveThAutomationStateAttr('isTruckViewMode', true));
                                  } else if (item.props.itemKey === 'pumpCar') {
                                      dispatch(saveThAutomationStateAttr('isTruckViewMode', false));
                                  }
                              }
                          }}
                        >
                            <PivotItem headerText={t('truck')} itemKey={`truck`}/>
                            <PivotItem headerText={t('pumpCar')} itemKey={`pumpCar`}/>
                        </Pivot>
                    </div>
                </Stack.Item>
            </Stack>
        </div>
    );
};

export default CommandBarLeft;

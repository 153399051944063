/* eslint-disable no-use-before-define */
import _ from 'lodash';
import {
  DELETE_QR_CODE,
  DELETE_QR_CODE_FAIL,
  DELETE_QR_CODE_SUCCESS,
  LOAD_CPC_VEHICLES,
  LOAD_CPC_VEHICLES_SUCCESS,
  LOAD_QR_CODE,
  LOAD_QR_CODE_FAIL,
  LOAD_QR_CODE_SUCCESS,
  LOAD_QR_CODES,
  LOAD_QR_CODES_FAIL,
  LOAD_QR_CODES_SUCCESS,
  LOAD_QR_DROPDOWNS,
  LOAD_QR_DROPDOWNS_FAIL,
  LOAD_QR_DROPDOWNS_SUCCESS,
} from '../actionTypes/qrCodeActionTypes';
import { DELETE_QRCODE_EP, QR_CODE_READ_EP } from '../shared/endpoints';
import { getTimeZone } from '../shared/util';
import { QRCodeLite } from '../types/qrCode';

const defaultState: any = {
  qrCodes: [],
  qrCode: null,
  isLoadingQRCode: false,
  isLoading: false,
  status: true,
  message: null,
  dropDownsData: [],
};

export default function qrCodeReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {
      case LOAD_QR_CODES:
        return { ...state, qrCodes: [], isLoading: true };
      case LOAD_QR_CODES_SUCCESS:
        return {
          ...state,
          isLoading: false,
          status: action.payload.data.status,
          message: action.payload.data.message,
          qrCodes: formatQRCodeData(action.payload.data.result),
        };
      case LOAD_QR_CODES_FAIL:
        return {
          ...state,
          isLoading: false,
          error: action.error.response.data.message,
        };

      case LOAD_QR_CODE:
        return { ...state, isLoadingQRCode: true, qrCode: null };
      case LOAD_QR_CODE_SUCCESS:
        return {
          ...state,
          isLoadingQRCode: false,
          qrCode: action.payload.data.result,
        };
      case LOAD_QR_CODE_FAIL:
        return {
          ...state,
          isLoadingQRCode: false,
          qrCode: null,
        };
      case LOAD_QR_DROPDOWNS:
        '';
        return { ...state, dropDownsData: [] };
      case LOAD_QR_DROPDOWNS_SUCCESS:
        return {
          ...state,
          dropDownsData: action.payload.data.result,
        };
      case LOAD_QR_DROPDOWNS_FAIL:
        return {
          ...state,
          dropDownsData: [],
        };
      case LOAD_CPC_VEHICLES:
        return { ...state, dropDownsData: [] };
      case LOAD_CPC_VEHICLES_SUCCESS:
        return {
          ...state,
          dropDownsData: action.payload.data.result,
        };
      default:
        return state;
    }
  }
}

export const getQRCodeByID = (id: string) => {
  return {
    types: [LOAD_QR_CODE, LOAD_QR_CODE_SUCCESS, LOAD_QR_CODE_FAIL],
    payload: {
      request: {
        // url: `QrCode/Read/${id}`,
        url: QR_CODE_READ_EP + id,
      },
    },
  };
};

export const getQRDropDownsData = () => {
  return {
    types: [
      LOAD_QR_DROPDOWNS,
      LOAD_QR_DROPDOWNS_SUCCESS,
      LOAD_QR_DROPDOWNS_FAIL,
    ],
    payload: { request: { url: 'ProjectDefinition/ReadProjectsForQr/-1' } },
  };
};

export const deleteQRCode = (id: string) => {
  return {
    types: [
      DELETE_QR_CODE,
      DELETE_QR_CODE_SUCCESS,
      DELETE_QR_CODE_FAIL,
    ],
    payload: {
      request: {
        method: 'DELETE',
        url: DELETE_QRCODE_EP + id,
      },
    },
  };
};

export const getFilterQRCodes = (
  type?: number,
  projectId?: string,
  vehicleNo?: string,
  location?: string,
  attribute?: string,
  order?: string,
) => {
  return {
    types: [LOAD_QR_CODES, LOAD_QR_CODES_SUCCESS, LOAD_QR_CODES_FAIL],
    payload: {
      request: {
        url: 'QRCode/QrCodeFilter',
        method: 'POST',
        data: {
          type: type === 0 ? 0 : type ? (type === -1 ? null : type) : null,
          projectId: projectId ? (projectId === '-1' ? null : projectId) : null,
          vehicleNo: vehicleNo ? vehicleNo : null,
          location: location ? location : null,
          offSet: getTimeZone(),
          date: null,
          sorter: {
            attribute: attribute,
            order: order,
          },
        },
      },
    },
  };
};

const formatQRCodeData = (qrCodes: any[]) => {
  let data: QRCodeLite[] = [];
  if (_.isArray(qrCodes)) {
    data = qrCodes.map((item: any) => {
      return {
        id: item.id,
        projectId: item.projectTitle ? item.projectTitle : '-',
        vehicleNo: item.vehicleNo ? item.vehicleNo : '-',
        travellerType: item.travellerType ? item.travellerType : '-',
        location: item.location ? item.location : '-',
        type: item.type,
        date: item.date,
        history: item.history,
      };
    });
  }
  return data;
};

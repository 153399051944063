import React, { Component } from 'react';
import DayPlanningMainLayout from './dayPlanningMainLayout';
import MeNavBar from '../../shared/uprinceNavigation/meNavigation/component';


interface Props {
  currentUser: any
}

interface State {
  isContractUnit: boolean;
  isProject: boolean;
}

export class MyDayPlanning extends Component<Props, State> {
  render() {
    return (
      <MeNavBar
        currentUser={this.props.currentUser}
        selectedNavigationIndex={'/me/day-planning'}
      >
        <DayPlanningMainLayout/>
      </MeNavBar>
    );
  }
}

export default (MyDayPlanning);

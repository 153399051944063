import React from 'react';
import { initializeIcons } from '@uifabric/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CpcShortCutPaneComponent } from './component';
import { ProductCatalogueFilter } from '../../../types/corporateProductCatalogue';
import history from '../../../history';
import { messageService } from '../../../services/messageService';
import { getContractingUnit, getProject } from '../../../shared/util';

initializeIcons();

interface Props extends WithTranslation {
  shortCutPaneFilters: [];
  handelCpcFilter: (cpcFilter: ProductCatalogueFilter) => void;
  cpcFilter: ProductCatalogueFilter;
}

interface State {
  divClass: string;
  selectedCodeType: number;
  currentActiveSection: string | null;
}

class ProductCatalogueShortCutPane extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { divClass: 'filter-panel-left', selectedCodeType: -1, currentActiveSection: null };
  }


  private handelCpcFilter = (id: string | null) => {
    // let cpcFilter=this.props.cpcFilter;
    let cpcFilter = {
      title: null,
      resourceTitle: null,
      resourceTypeId: id,
      resourceFamilyId: null,
      resourceNumber: null,
      status: null,
      sorter: {
        attribute: null,
        order: null,
      },
    };
    this.setState({
      currentActiveSection: id,
    });
    if (getContractingUnit() && !getProject()) {
      history.push({
        pathname: `/CU/${getContractingUnit()}/product-catalogue`,
        state: { cpcFilter: cpcFilter },
      });
    } else if (getContractingUnit() && getProject()) {
      history.push({
        pathname: `/CU/${getContractingUnit()}/project/${getProject()}/product-catalogue`,
        state: { cpcFilter: cpcFilter },
      });
    } else {
      history.push({
        pathname: '/product-catalogue',
        state: { cpcFilter: cpcFilter },
      });
    }

    messageService.sendMessage({
      resetCpcListSelection: true,
      cpcFilter: cpcFilter,
    });
    // this.props.handelCpcFilter(cpcFilter);
  };

  private resetCpcFilter = () => {
    let cpcFilter = {
      title: null,
      resourceTitle: null,
      resourceTypeId: null,
      resourceFamilyId: null,
      resourceNumber: null,
      status: null,
      sorter: {
        attribute: null,
        order: null,
      },
    };
    this.setState({
      currentActiveSection: null,
    });


    if (getContractingUnit() && !getProject()) {
      history.push({
        pathname: `/CU/${getContractingUnit()}/product-catalogue/`,
        state: { cpcFilter: cpcFilter },
      });

    } else if (getContractingUnit() && getProject()) {
      history.push({
        pathname: `/CU/${getContractingUnit()}/project/${getProject()}/product-catalogue`,
        state: { cpcFilter: cpcFilter },
      });

    } else {
      history.push({
        pathname: '/product-catalogue',
        state: { cpcFilter: cpcFilter },
      });
    }
    messageService.sendMessage({
      resetCpcListSelection: true,
      cpcFilter: cpcFilter,
    });
    //this.props.handelCpcFilter(cpcFilter);
  };

  componentDidMount() {
    if (this.state.divClass === 'filter-panel-left') {
      if (window.innerWidth > 1300) {
        messageService.sendMessage({ shortCutPaneExpanded: true });
      }
    } else {
      messageService.sendMessage({ shortCutPaneExpanded: false });
    }
  }

  private fromatShortCutPaneData = (shrotCutaPaneData: any) => {
    const { t } = this.props;
    let data: any[] = [];
    if (shrotCutaPaneData) {
      let setIcon = shrotCutaPaneData?.map((item: any) => {
        switch (item.name) {
          case 'Materials':
            return {
              ...item,
              icon: 'ms-Icon--ExpandMenu',
            };
          case 'Tools':
            return {
              ...item,
              icon: 'ms-Icon--DeveloperTools',
            };
          case 'Consumables':
            return {
              ...item,
              icon: 'ms-Icon--HintText',
            };
          case 'Human Resources':
            return {
              ...item,
              icon: 'ms-Icon--People',
            };
          default:
            return {
              ...item,
              icon: 'ms-Icon--GotoToday',
            };
        }
      });
      data.push(setIcon);
    }
    return data[0];
  };

  public render(): JSX.Element {
    return (
      <CpcShortCutPaneComponent
        currentActiveSection={this.state.currentActiveSection}
        toggleOverlay={this._toggleOverlay}
        shortCutPaneFilters={this.fromatShortCutPaneData(this.props.shortCutPaneFilters)}
        toggleOverlayFwd={this._toggleOverlayFwd}
        handelCpcFilter={(id: string | null) => this.handelCpcFilter(id)}
        resetCpcFilter={() => this.resetCpcFilter()}
        divClass={this.state.divClass}
      />
    );
  }

  private _toggleOverlay = (): void => {
    this.setState({ divClass: 'filter-panel-left panel-move' });
    messageService.sendMessage({ shortCutPaneExpanded: false });

  };
  private _toggleOverlayFwd = (): void => {
    this.setState({ divClass: 'filter-panel-left' });
    messageService.sendMessage({ shortCutPaneExpanded: true });
  };
}

export default withTranslation()(ProductCatalogueShortCutPane);

import React, { useEffect, useState } from 'react';
import {
  FontWeights,
  IButtonStyles,
  IconButton,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens, MaskedTextField,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useId } from '@uifabric/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { uPrinceTheme } from '../../../../../theme';
import { getTheme, ITheme } from '@fluentui/react/lib/Styling';
import { TimePicker } from '@fluentui/react/lib/TimePicker';
import { resizePmolOnProject, saveProjectDayPlanningStateAttr } from '../../../../reducers/projectDayPlanningReducer';
import moment from 'moment/moment';
import { formatDateTimeToISOString } from '../../../../shared/util';

const theme: ITheme = getTheme();

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: { color: theme.palette.neutralDark },
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    minWidth: 400,
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  fullWidth: { width: '100%' },
  footer: {
    flex: '1 1 auto',
    alignItems: 'end',
    fontWeight: FontWeights.semibold,
    padding: '12px 24px 0px 0px',
    textAlign: 'end',
  },
});

const stackStyles: IStackStyles = { root: { padding: 10, marginBottom: 10 } };

const stackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 'auto',
    flexGrow: 1,
  },
};

const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const PmolTimeChangeModel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const titleId = useId('title');

  const { selectedPmolData } = useSelector((state: any) => state.projectDPlan,);
  const [startTime, setStartTime] = useState<string | null>(selectedPmolData?.executionStartTime);
  const [endTime, setEndTime] = useState<string | null>(selectedPmolData?.executionEndTime);

  const maskFormat: { [key: string]: RegExp } = {H: /[0-2]/, h: /[0-9]/, m: /[0-5]/, M: /[0-9]/};

  const { isPmolTimeChangeModelOpen } = useSelector(
    (state: any) => state.projectDPlan,
  );

  useEffect(() => {
    setStartTime(selectedPmolData?.executionStartTime);
    setEndTime(selectedPmolData?.executionEndTime);
  }, [selectedPmolData]);

  let newPmolTimeData = {
    id: selectedPmolData?.id,
    pmolId: selectedPmolData?.projectMoleculeId,
    executionStartTime: startTime,
    executionEndTime: endTime,
    projectSequenceCode: selectedPmolData?.projectSequenceCode,
    contractingUnit: selectedPmolData?.contractingUinit,
    executionDate: formatDateTimeToISOString(selectedPmolData?.executionDate),
  };

  const onSubmit = async (values: any) => {
    Promise.all([]).then(() => {
      dispatch(saveProjectDayPlanningStateAttr('isPmolTimeChangeModelOpen', !isPmolTimeChangeModelOpen));
    });
  };

  const handleClose = () => {
    dispatch(saveProjectDayPlanningStateAttr('isPmolTimeChangeModelOpen', !isPmolTimeChangeModelOpen));
  };

  return <div>
    <Modal
      titleAriaId={titleId}
      isOpen={isPmolTimeChangeModelOpen}
      onDismiss={() => handleClose()}
      isBlocking={false}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <span id={titleId}>{t('updateTimes')}</span>
        <IconButton
          styles={cancelIconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel='Close popup modal'
          onClick={() => handleClose()}
        />
      </div>
      <div className={contentStyles.body}>
              <>
                <Stack
                  horizontal
                  wrap
                  styles={stackStyles}
                  tokens={stackTokens}
                >
                  <Stack.Item grow={1} styles={stackItemStyles}>
                    <MaskedTextField
                      label={t('startTime')}
                      value={startTime ?? selectedPmolData?.executionStartTime}
                      onChange={(e: any, newValue?: string) => {
                        const timeValidation: boolean = moment(newValue, 'HH:mm', true).isValid();
                        if (timeValidation && newValue) {
                          setStartTime(newValue)
                        } else {
                          setStartTime(null)
                        }
                      }}
                      mask="Hh:mM"
                      maskFormat={maskFormat}
                      maskChar="_"
                      autoFocus={true}
                    />
                  </Stack.Item>
                  <Stack.Item grow={1} styles={stackItemStyles}>
                    <MaskedTextField
                      label={t('endTime')}
                      value={endTime ?? selectedPmolData?.executionEndTime}
                      onChange={(e: any, newValue?: string) => {
                        const timeValidation: boolean = moment(newValue, 'HH:mm', true).isValid();
                        if (timeValidation && newValue) {
                          setEndTime(newValue)
                        } else {
                          setEndTime(null)
                        }
                      }}
                      mask="Hh:mM"
                      maskFormat={maskFormat}
                      maskChar="_"
                    />
                  </Stack.Item>
                </Stack>

                <div className={contentStyles.footer}>
                  <PrimaryButton
                    iconProps={saveIcon}
                    text={t('save')}
                    style={{ marginRight: 15 }}
                    onClick={() => {
                      Promise.all([
                        dispatch(resizePmolOnProject(newPmolTimeData)),
                      ]).then(() => {
                        handleClose();
                      });
                    }}
                    disabled={false}
                  />
                </div>
                {/*<pre>{JSON.stringify(newPmolTimeData, null, 2)}</pre>*/}
                {/*<pre>{JSON.stringify(selectedPmolData, null, 2)}</pre>*/}
              </>
      </div>
    </Modal>
  </div>;
};

export default PmolTimeChangeModel;
import * as React from 'react';
import { Stack } from '@fluentui/react';
import TitlePane from '../../../shared/titlePane/titlePane';
import { useSelector } from 'react-redux';
import DocumentPaneCommandBar from '../commandBar/DocumentPaneCommandBar';
import DocumentPane from './DocumentPane';
import { useLocation } from 'react-router-dom';
import ProgressBar from '../../../shared/progressBar/progressBar';

const DocumentPaneWrapper = () => {
  const { state }: any = useLocation();

  const formData = useSelector((state: any) => state.wbsTask.formData);
  const isLoadingDocPane = useSelector((state: any) => state.wbsTask.isLoadingDocPane);

  return (
    <>
      <Stack>
        <Stack.Item>
          {/* not modified cuz changes in future */}
          <TitlePane
            headerTitle={formData?.name}
            type={1}
            close={true}
            isFullScreen={true}
          />
        </Stack.Item>
        <Stack.Item>
          <Stack style={{ flexGrow: 1, borderLeft: '1px solid #ccc3c3' }}>
            <Stack
              className='project-list-header new-design'
              style={{
                backgroundColor: 'rgb(255, 255, 255)',
                paddingRight: 0,
              }}
              styles={{ root: { width: '100%', paddingRight: 0 } }}
            >

              <DocumentPaneCommandBar />
              {/*{isLoadingDocPane && <ProgressIndicator />}*/}
              <ProgressBar show={isLoadingDocPane} />
            </Stack>

            <Stack className={`main-layout-document-pane-stack`}>
              <div style={{ width: '100%', padding: 0 }}>
                <DocumentPane
                  isNewTask={{ pbsId: state?.wbsTaxonomyId, productId: state?.productId, issueId: state?.issueId }}
                />
              </div>
            </Stack>
          </Stack>
        </Stack.Item>
      </Stack>
    </>
  );

};

export default DocumentPaneWrapper;

export const SAVE_WBS_TASK_STATE_ATTR = 'uprice/SAVE_WBS_TASK_STATE_ATTR';

export const GET_WBS_TASK_LIST = 'uprice/GET_WBS_TASK_LIST';
export const GET_WBS_TASK_LIST_SUCCESS = 'uprice/GET_WBS_TASK_LIST_SUCCESS';
export const GET_WBS_TASK_LIST_FAIL = 'uprice/GET_WBS_TASK_LIST_FAIL';
export const SAVE_WBS_TASK_IS_FAV = 'uprice/SAVE_WBS_TASK_IS_FAV';
export const SAVE_WBS_TASK_IS_FAV_SUCCESS = 'uprice/SAVE_WBS_TASK_IS_FAV_SUCCESS';
export const SAVE_WBS_TASK_IS_FAV_FAIL = 'uprice/SAVE_WBS_TASK_IS_FAV_FAIL';
export const GET_WBS_GROUP_TASK_LIST = 'uprice/GET_WBS_GROUP_TASK_LIST';
export const GET_WBS_GROUP_TASK_LIST_SUCCESS = 'uprice/GET_WBS_GROUP_TASK_LIST_SUCCESS';
export const GET_WBS_GROUP_TASK_LIST_FAIL = 'uprice/GET_WBS_GROUP_TASK_LIST_FAIL';
export const GET_WBS_TASK_BY_ID = 'uprice/GET_WBS_TASK_BY_ID';
export const GET_WBS_TASK_BY_ID_SUCCESS = 'uprice/GET_WBS_TASK_BY_ID_SUCCESS';
export const GET_WBS_TASK_BY_ID_FAIL = 'uprice/GET_WBS_TASK_BY_ID_FAIL';

export const SAVE_WBS_TASK = 'uprice/SAVE_WBS_TASK';
export const SAVE_WBS_TASK_SUCCESS = 'uprice/SAVE_WBS_TASK_SUCCESS';
export const SAVE_WBS_TASK_FAIL = 'uprice/SAVE_WBS_TASK_FAIL';

export const GET_WBS_TAXONOMY = 'uprice/GET_WBS_TAXONOMY';
export const GET_WBS_TAXONOMY_SUCCESS = 'uprice/GET_WBS_TAXONOMY_SUCCESS';
export const GET_WBS_TAXONOMY_FAIL = 'uprice/GET_WBS_TAXONOMY_FAIL';

export const GET_WBS_TASK_DROPDOWN = 'uprice/GET_WBS_TASK_DROPDOWN';
export const GET_WBS_TASK_DROPDOWN_SUCCESS = 'uprice/GET_WBS_TASK_DROPDOWN_SUCCESS';
export const GET_WBS_TASK_DROPDOWN_FAIL = 'uprice/GET_WBS_TASK_DROPDOWN_FAIL';

export const GET_WBS_TASK_INSTRUCTION_LIST = 'uprice/GET_WBS_TASK_INSTRUCTION_LIST';
export const GET_WBS_TASK_INSTRUCTION_LIST_SUCCESS = 'uprice/GET_WBS_TASK_INSTRUCTION_LIST_SUCCESS';
export const GET_WBS_TASK_INSTRUCTION_LIST_FAIL = 'uprice/GET_WBS_TASK_INSTRUCTION_LIST_FAIL';

export const WBS_TASK_STATUS_UPDATE = 'uprice/WBS_TASK_STATUS_UPDATE';
export const WBS_TASK_STATUS_UPDATE_SUCCESS = 'uprice/WBS_TASK_STATUS_UPDATE_SUCCESS';
export const WBS_TASK_STATUS_UPDATE_FAIL = 'uprice/WBS_TASK_STATUS_UPDATE_FAIL';

export const SAVE_TASK_MODAL_DATE = 'uprice/SAVE_TASK_MODAL_DATE';
export const SAVE_TASK_MODAL_DATE_SUCCESS = 'uprice/SAVE_TASK_MODAL_DATE_SUCCESS';
export const SAVE_TASK_MODAL_DATE_FAIL = 'uprice/SAVE_TASK_MODAL_DATE_FAIL';


import { useTranslation } from 'react-i18next';
import {
  getTheme,
  IDragOptions,
  ContextualMenu,
  IIconProps,
  mergeStyleSets,
  FontWeights,
  Modal,
  IconButton,
  Label,
  TextField,
  PrimaryButton,
  Dropdown,
  Stack,
  IStackStyles,
  IStackItemStyles,
  IStackTokens,
  Icon,
  NormalPeoplePicker,
  IBasePickerSuggestionsProps,
  ValidationState,
  IPersona,
} from '@fluentui/react';
import React, { useState, useEffect, useRef } from 'react';
import { useId, useBoolean } from '@uifabric/react-hooks';
import _ from 'lodash';
import { uPrinceTheme } from '../../../../../theme';
import {
  Risk,
  RiskDropDown,
  ReadRisk,
} from '../../../../types/projectBreakdownStructure';
//to load risk data into modal
import{
  readRiskById
} from '../../../../reducers/projectBreakdownReducer';
import { useDispatch, useSelector } from 'react-redux';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import TextEditor from '../../../../shared/textEditor/textEditor';
import CustomDropdown from '../../../../shared/customDropdown/customDropdown';
import { getProject } from '../../../../shared/util';
import client from '../../../../api';
import { dispatch } from 'rxjs/internal/observable/pairs';//to load risk data into modal


const theme = getTheme();

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const classNames = mergeStyleSets({
  wrapper: {
    //height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: {
    maxWidth: 400,
  },
  textContent: {
    padding: '15px 10px',
  },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: {
    width: '100%',
  },
  editor: {
    width: '100%',
    height: '100%'
  },
});

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};


const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '40vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};


class RiskValidationMessages {
  nameValidationMsg: string = '';
}


const RiskModal = (props: {
  pbsId: any;
  isOpen: boolean;
  openRiskModal: () => void;
  isEdit: boolean;
  onFilterPersonChanged: any;
  createRisk: (risk: Risk) => void;
  //to load risk data into modal
  risk: any;
  riskValues: any;
}) => {
  const dispatch = useDispatch();//to load risk data into modal
  const { t } = useTranslation();
  const [isDraggable] = useBoolean(true);
  const titleId = useId('title');
  const dropdowns: RiskDropDown = useSelector(
    (state: any) => state.projectBreakdown.dropdowns
  );
  const [risk, setRisk] = useState(new ReadRisk());
  const [person, setPerson] = useState<IPersona[]>([]);
  const [hasEdit, setHasEdit] = useState(false);
  const [riskValidationMessages, setRiskValidationMsg] = useState<
    RiskValidationMessages
  >(new RiskValidationMessages());
  const [disableSave, setDisableSave] = useState(true);
  const ref: any = useRef();

  const onChangeInput = (field: string) => (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setRisk((prevState) => ({
      ...prevState,
      [field]: newValue,
    }));
    if (field === 'name' && newValue) {
        setDisableSave(false)
    } else if (field === 'name'  && !newValue) {
      setDisableSave(true)
    }
    setHasEdit(true);
    setRiskValidationMsg(new RiskValidationMessages);
  };

  const limitedSearchAdditionalProps: IBasePickerSuggestionsProps = {
    resultsMaximumNumber: 10,
    searchingText: t('searching'),
  };

  let selectedPerson: IPersona[] = [];

  if (props.risk && props.risk.person) {
    selectedPerson = [
      {
        key: props.risk.person.id,
        text: props.risk.person.fullName,
      },
    ];
  }


  useEffect(() => {
    if (props.isEdit) {
      // console.log("risks",props.isEdit);

      if (props.risk && props.risk.person) {
        setPerson([
          {
            key: props.risk && props.risk.person ? props.risk.person.id : '',
            text: props.risk && props.risk.person ? props.risk.person?.fullName : '',
          },
        ]);
      }
      selectedPerson.push(person);
      setRisk({
        id: props.risk.id,
        name: props.risk.name,
        riskDetails: props.risk.riskDetails,
        cabPersonId: props.risk.cabPersonId,
        riskTypeId: props.risk.riskType ? props.risk.riskType.key : null,
        riskStatusId: props.risk.riskStatus ? props.risk.riskStatus.key : null,
        title: props.risk.title,
        headerTitle: props.risk.headerTitle,
        sequenceCode: props.risk.sequenceCode,
        pbsRiskId:props.risk.pbsRiskId,
        riskId:props.risk.id, //to load risk data into modal
      });
      setDisableSave(false)
    } else {
      setPerson([]);
      //to load risk data into modal
      if(props.risk?.id) {
        // console.log("GGGGGGG",props.risk)
        setRisk(props.risk);

        setPerson((props.risk && props.risk.cabPersonId) ?

          [{
            key: props.risk.cabPersonId,
            text: props.risk.person?.fullName,
          }] : []);

      } else {
        setRisk(new ReadRisk());
      }
     
    setDisableSave(false)
    }
  }, [props.risk]);

  const validateRisk = (risk: Risk) => {
    let isValidName = validateRiskName(risk);
    return isValidName;
  };

  const validateRiskName = (risk: Risk) => {
    let isValidName = false;
    if (risk && risk.name) {
      isValidName = true;
      setRiskValidationState('nameValidationMsg', '');
    } else {
      isValidName = false;
      setRiskValidationState('nameValidationMsg', t('nameRequired'));
    }
    return isValidName;
  };

  const setRiskValidationState = (key: string, msg: string) => {
    setRiskValidationMsg((prevState) => ({
      ...prevState,
      [key]: msg,
    }));
  };

  const getTypes = () => {
    let data: any[] = [];
    if (dropdowns && dropdowns.riskTypes) {
      data = dropdowns.riskTypes.map((item: any) => {
        return {
          key: item.id,
          text: item.type,
        };
      });
    }

    return data;
  };

  const getStatus = () => {
    let data: any[] = [];
    if (dropdowns && dropdowns.riskStatus) {
      data = dropdowns.riskStatus.map((item: any) => {
        return {
          key: item.id,
          text: item.status,
        };
      });
    }

    return data;
  };

  const validateInput = (input: string): ValidationState => {
    if (input.indexOf('@') !== -1) {
      return ValidationState.valid;
    } else if (input.length > 1) {
      return ValidationState.warning;
    } else {
      return ValidationState.invalid;
    }
  };

  const onPersonSelected = (item: any) => {
    if (item && item.person) {
      selectedPerson = [
        {
          key: item.person.id,
          text: item.person.fullName,
        },
      ];
      setPerson(selectedPerson);
      setRisk((prevState) => ({
        ...prevState,
        cabPersonId: item.person.id,
      }));
    }
    return item;
  };

  const onInputChange = (input: string): string => {
    const outlookRegEx = /<.*>/g;
    const emailAddress = outlookRegEx.exec(input);

    if (emailAddress && emailAddress[0]) {
      return emailAddress[0].substring(1, emailAddress[0].length - 1);
    }

    return input;
  };

  const onRenderCaretDown = (): JSX.Element => {
    return (
      <Icon
        iconName="ChevronDown"
        onClick={() => {
          //alert('test');
        }}
      />
    );
  };

  //for existing risk selection using risk modal
  const riskOptions = (inputValue: any) =>
  new Promise((resolve) => {
      // setTimeout(() => {
      resolve(getRiskByName(inputValue));
      // }, 1000);
  });

  const getRiskByName = async(name:string) => {
    const filter = {
        title: name,
        pbsProductId: props.pbsId,
        typeId:null,
        stateId:null,
        personId:null,
        sortingModel: {
            attribute: 'title',
            order: 'asc'
        }
    };
    const header = getProject() ? { headers: { project: getProject() } } : undefined;
    if (name.length >= 2) {
        const response = await client.post(
            'Risk/RiskFilter',
            filter, header
        );
        return formatRiskResponse(response);
    }
};
const formatRiskResponse = (response: any) => {
  let options: { value: string; label: string }[] = [];
  if (response && response.data && response.data.result) {
      const data = response.data.result;

      if (data && _.isArray(data)) {
          options = data.map((item: any) => {
              return {
                  value: item.sequenceCode,
                  label: item.title
              };
          });
      }


      return options;
  }
};


  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          setRiskValidationMsg(new RiskValidationMessages());
          props.openRiskModal();
        }}
        scrollableContentClassName="body"
        isBlocking={false}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
      <span id={titleId}>{props.isEdit? t('editRisk'):t('addRisk')}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              setRiskValidationMsg(new RiskValidationMessages());
              props.openRiskModal();
              setRisk(new ReadRisk())
              setPerson([]);
            }}
          />
        </div>
        
        <div className={contentStyles.body}>
          <div className="proj-detail-content inner">
            <div className="ms-Grid-row">
              <Label>1. {t('riskDetails')} </Label>
              {props.risk.headerTitle  && (
                <Stack
                  horizontal
                  wrap
                  styles={stackStyles}
                  tokens={stackTokens}
                >
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        label={t('riskID')}
                        disabled={true}
                        readOnly={true}
                        value={
                          risk && risk.sequenceCode ? risk.sequenceCode : ''
                        }
                      />
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        label={t('riskTitle')}
                        disabled={true}
                        value={risk && risk.title ? risk.title : ''}
                        errorMessage={''}
                        //onChange={onChangeInput('vat')}
                      />
                    </div>
                  </Stack.Item>
                </Stack>
              )}
              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    {/* name field will not available for dropdown when editing */}
                        <Label required={true}>{t('name')}</Label>

                        <CustomDropdown
                          reference={''}
                          onChange={(value: any, label: string) => {
                            if (value) {
                              setRisk((prevState) => ({
                                ...prevState,
                                name: label,
                                riskId: value
                              }))

                              dispatch(readRiskById(value));
                              //call risk by id
                            } else {
                              setRisk((prevState) => ({
                                ...prevState,
                                name: null,
                                riskId: null
                              }))
                              //form.mutators.setCUCPC(null);
                            }
                          }}
                          //to load risk data into modal
                          selectedOption={(risk && risk?.id ? {
                            value: risk?.id,
                            label: risk?.title
                          } : null)}
                          promiseOptions={riskOptions}
                          validationMessage={riskValidationMessages.nameValidationMsg}
                          required={true}
                        />
                  </div>
                </Stack.Item>
                <br />

                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <Label required={false}>{t('owner')}</Label>
                    <NormalPeoplePicker
                      onChange={(items) => {
                        if (items && _.isArray(items) && items.length === 0) {
                          setPerson([]);
                          setRisk((prevState) => ({
                            ...prevState,
                            cabPersonId: null,
                          }));
                        }
                      }}
                      selectedItems={
                        person && person[0] && person[0] ? person : []
                      }
                      onResolveSuggestions={props.onFilterPersonChanged}
                      onItemSelected={onPersonSelected}
                      pickerSuggestionsProps={limitedSearchAdditionalProps}
                      className={'ms-PeoplePicker'}
                      key={'normal'}
                      itemLimit={1}
                      onValidateInput={validateInput}
                      removeButtonAriaLabel={'Remove'}
                      inputProps={{
                        onBlur: () =>
                         {},
                        onFocus: () =>
                         {},
                        'aria-label': 'People Picker',
                      }}
                      onInputChange={onInputChange}
                      resolveDelay={300}
                      disabled={false}
                    />
                  </div>
                </Stack.Item>
              </Stack>

              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <Dropdown
                      placeholder={t('type')}
                      label={t('type')}
                      options={getTypes()}
                      selectedKey={
                        risk && risk.riskType 
                          ? risk.riskType.key
                          : risk.riskTypeId
                      }
                      onRenderCaretDown={onRenderCaretDown}
                      onChange={(
                        event: React.FormEvent<HTMLDivElement>,
                        item: any
                      ) => {
                        setRisk((prevState) => ({
                          ...prevState,
                          riskTypeId: item.key,
                          riskType: {
                            key: item.key,
                            text: item.text,
                          },
                        }));
                      }}
                    />
                  </div>
                </Stack.Item>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <Dropdown
                      placeholder={t('status')}
                      label={t('status')}
                      options={getStatus()}
                      selectedKey={
                        risk && risk.riskStatus
                          ? risk.riskStatus.key
                          : risk.riskStatusId
                      }
                      onRenderCaretDown={onRenderCaretDown}
                      onChange={(
                        event: React.FormEvent<HTMLDivElement>,
                        item: any
                      ) => {
                        setRisk((prevState) => ({
                          ...prevState,
                          riskStatusId: item.key,
                          riskStatus: {
                            key: item.key,
                            text: item.text,
                          },
                        }));
                      }}
                    />
                  </div>
                </Stack.Item>
              </Stack>
              <Label style={{ marginLeft: 10, marginRight: 10 }}>
                {t('riskDetails')}
              </Label>
              <div style={{ marginLeft: 10, marginRight: 10, }}>

              
                
                <TextEditor
                  value={risk?risk.riskDetails:""}
                  onChange={(evt: any) => {
                    setRisk((prevState) => ({
                      ...prevState,
                      riskDetails:evt
                    }));
                  }}
                />
              </div>
            </div>
          </div>

          {/* <pre>{JSON.stringify(risk, 0, 2)}</pre> */}

        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={addIconWhite}
            text={t('save')}
            disabled={disableSave}
            style={{ marginTop: 20, marginBottom: 30 }}
            onClick={() => {
              if (validateRisk(risk)) {
                setRisk(new ReadRisk())
                setPerson([]);
                props.createRisk(risk);
                props.openRiskModal()
              }
            }}
          />

        {!props.isEdit && <PrimaryButton
              iconProps={addIconWhite}
              text={t('saveandNew')}
              style={{ marginTop: 20, marginBottom: 10, marginLeft:10}}
              onClick={() => {
                if (validateRisk(risk)) {
                  props.createRisk(risk);
                  setRisk(new ReadRisk())
                  setPerson([]);
                  setDisableSave(true)
                }
              }}
              disabled={disableSave}
          />}
        </div>
      </Modal>
     
    </div>
  );
};

export default RiskModal;

import * as React from 'react';
import UprinceNavigation from '../../shared/uprinceNavigation/cuProjectNavigation/component';
import { getContractingUnit, getProject } from '../../shared/util';
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';
import WbsIssueMainLayout from './WbsIssueMainLayout';

const WbsIssue = (props: { currentUser: any }) => {

  return (
    <>
      <UprinceNavigation
        currentUser={props.currentUser}
        selectedNavigationIndex={`/CU/${getContractingUnit()}/project/${getProject()}/wbs`}
      >
        <FluentProvider theme={teamsLightTheme}>
          <WbsIssueMainLayout />
        </FluentProvider>
      </UprinceNavigation>
    </>
  );

};

export default WbsIssue;

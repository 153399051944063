import React, { useEffect, useState } from 'react';
import SplitPane, { Pane } from 'react-split-pane';
import TitlePane from '../../shared/titlePane/titlePane';
import { Stack } from '@fluentui/react';
import ProgressBar from '../../shared/progressBar/progressBar';
import WbsCuTaskListPaneCommandBar from './commandBar/listCommandBar/component';
import WbsTaskListPane from '../wbsTask/listPane/WbsTaskListPane';
import WbsCuTaskShortcutPane from './shortcutPane/component';
import WbsCuTaskDocumentPaneWrapper from './documentPane/WbsCuTaskDocumentPaneWrapper';
import { useParams } from 'react-router';
import { ParamTypes } from '../../types/uPrince';
import { messageService } from '../../services/messageService';

const WbsCuTaskMainLayout = () => {
  const params = useParams();
  const { featureId }: ParamTypes = params;

  const [reSizer, setReSizer] = useState<number>(screen.width);
  const [showDocumentPane, setShowDocumentPane] = useState<boolean>(false);

  useEffect(() => {
    const subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.hideDocumentPane) {
          handleDocumentPaneCollapse(true);
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (featureId) {
      handleDocumentPaneCollapse(false);
    } else {
      handleDocumentPaneCollapse(true);
    }
  }, [params]);

  const handleDocumentPaneCollapse = (isCollapsed: boolean) => {
    if (isCollapsed) {
      setReSizer(screen.width);
      setShowDocumentPane(false);
    } else {
      setReSizer(screen.width / 2);
      setShowDocumentPane(true);
    }
  };

  const getListPaneView = () => {
    return <>
      <TitlePane
        headerTitle={'All'}
        type={1}
        isFullScreen={true}
      />
      <div style={{
        height: 'calc(100vh - 134px)',
        overflow: 'overlay',
      }}>
        <Stack horizontal>
          {renderShortCutPane()}
          <div className={'grid-righter full-height'}>
            <Stack
              className='project-list-header new-design'
              style={{
                backgroundColor: 'rgb(255, 255, 255)',
                paddingRight: 0,
              }}
              styles={{ root: { width: '100%', paddingRight: 0 } }}
            >
              <ProgressBar show={false} />
              <WbsCuTaskListPaneCommandBar />
            </Stack>
            {/*<Stack>*/}
            {/*  <WbsTaskFilterCard isCollapsed={!showDocumentPane} />*/}
            {/*</Stack>*/}
            <Stack style={{ height: 'calc(100vh - 184px)',alignSelf: 'center' }}>
              {/**
               *    cu and project level list pane component are the same
               */}
              <WbsTaskListPane />
            </Stack>
          </div>
        </Stack>
      </div>
    </>;
  };

  const renderShortCutPane = () => {
    return <WbsCuTaskShortcutPane />;
  };

  const getDocumentPaneView = () => {
    return <WbsCuTaskDocumentPaneWrapper />;
  };

  return (
    <div>
      <SplitPane
        className={'SplitPane'}
        minSize={(screen.width / 3)}
        maxSize={(screen.width / 3) * 2}
        defaultSize={screen.width}
        split='vertical'
        onChange={(size: any) => {
          setReSizer(size);
        }}
        size={reSizer}
        style={{ height: '85.5%' }}
      >
        <Pane className={''}
              style={{
                width: reSizer,
                height: '100%',
              }}>{getListPaneView()} </Pane>
        <Pane className={''}
              style={{
                marginLeft: 6,
                width: screen.width - reSizer,
                height: '100%',
              }}> {showDocumentPane && getDocumentPaneView()}</Pane>
      </SplitPane>
    </div>
  );
};

export default WbsCuTaskMainLayout;
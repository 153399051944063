import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';
import {
  CAPACITY_REQUEST,
  GOODDEERIS_PRODUCT_TYPE_ID,
  PRODUCT_TYPE_ID,
  PURCHASE_REQUEST,
  RESOURCE_TYPE_ID,
  RETURN_REQUEST,
  RFQ_REQUEST,
  SUPPLIER_PURCHASE_REQUEST,
} from '../../../types/purchaseOrder';
import {
  APPROVED_STATUS_ID,
  HANDED_OVER_STATUS_ID,
  IN_DEVELOPMENT_STATUS_ID,
  IN_REVIEW_ACCEPT_STATUS_ID,
  IN_REVIEW_FEEDBACK_STATUS_ID,
  IN_REVIEW_STATUS_ID,
} from '../../../types/projectMolecule';
import { getProject } from '../../../shared/util';
import { IButtonProps } from '@fluentui/react';
import { store } from '../../../../index';

const CommandBarRight = (props: {
  purchaseOrderType: null | string;
  selectedProject: null | string;
  formData: any;
}) => {
  const { t } = useTranslation();
  const newClick = (type?: string) => {
    messageService.sendMessage({ isNewPO: true, poRequestType: type });
    if (type === RETURN_REQUEST) {
      messageService.sendMessage({ purchaseOrderType: RESOURCE_TYPE_ID });
    } else if (type === CAPACITY_REQUEST) {
      messageService.sendMessage({ purchaseOrderType: GOODDEERIS_PRODUCT_TYPE_ID });
    }
    // props.createNew(true);
  };
  const saveFormData = () => {
    messageService.sendMessage({ savePOData: true });
  };
  const clonePo = () => {
    messageService.sendMessage({ clonePo: true });
  };
  const exportPoExcel = () => {
    messageService.sendMessage({ exportPoExcel: true });
  };
  const exportPricePoExcel = () => {
    messageService.sendMessage({ exportPricePoExcel: true });
  };
  const convertCrTOPo = () => {
    messageService.sendMessage({ convertCrTOPo: true });
  };

  const handleModalVisible = () => {
    if (props.formData?.type?.key && !props.purchaseOrderType) {
      if (props.formData?.type?.key === RESOURCE_TYPE_ID) {
        messageService.sendMessage({ isBorModalVisible: true });
      } else if (props.formData?.type?.key === PRODUCT_TYPE_ID || props.formData?.type?.key === GOODDEERIS_PRODUCT_TYPE_ID) {
        messageService.sendMessage({ isProductModalVisible: true });
      }
    } else {
      if (props.purchaseOrderType === RESOURCE_TYPE_ID) {
        messageService.sendMessage({ isBorModalVisible: true });
      } else if (props.purchaseOrderType === PRODUCT_TYPE_ID || props.purchaseOrderType === GOODDEERIS_PRODUCT_TYPE_ID) {
        messageService.sendMessage({ isProductModalVisible: true });
      }
    }
  };
  const handleApprove = () => {
    if (getProject()) {
      if (props.formData?.status?.key === IN_DEVELOPMENT_STATUS_ID) {
        messageService.sendMessage({ handleProjectSendPo: true });
      } else {
        messageService.sendMessage({ handlePMApprove: true });
      }
    } else {
      if (props.formData?.status?.key === IN_DEVELOPMENT_STATUS_ID) {
        messageService.sendMessage({ handleCuSendPo: true });
      } else if (props.formData?.status?.key === IN_REVIEW_ACCEPT_STATUS_ID) {
        messageService.sendMessage({ handleCUApprove: true });
      } else if (props.formData?.status?.key === IN_REVIEW_STATUS_ID) {
        messageService.sendMessage({ handleCUFeedback: true });
      } else if (props.formData?.status?.key === IN_REVIEW_FEEDBACK_STATUS_ID) {
        messageService.sendMessage({ handleCUFeedback: true });
      }
    }
  };

  const handleSendButtonDisabled = (): boolean => {
    if(store.getState().po?.formData?.requestType?.key === RFQ_REQUEST){
      return true
    }
    if (getProject()) {
      if (
        (props.formData?.status?.key &&
          props.formData?.status?.key === IN_DEVELOPMENT_STATUS_ID) ||
        props.formData?.status?.key === IN_REVIEW_FEEDBACK_STATUS_ID
      ) {
        if (props.formData?.sequenceId) {
          return false;
        }
        return true;
      }
      return true;
    }
    if (
      (props.formData?.status?.key &&
        props.formData?.status?.key === APPROVED_STATUS_ID) ||
      props.formData?.status?.key === HANDED_OVER_STATUS_ID
    ) {
      return true;
    } else if (props.formData?.status?.key === IN_DEVELOPMENT_STATUS_ID) {
      if (props.formData?.sequenceId) {
        return false;
      }
      return true;
    } else if (props.formData?.status?.key === undefined) {
      return true;
    }
    return false;

    // if(props.formData?.status?.key && props.formData?.status?.key===IN_DEVELOPMENT_STATUS_ID || props.formData?.status?.key===IN_REVIEW_STATUS_ID || props.formData?.status?.key && props.formData?.status?.key===IN_REVIEW_ACCEPT_STATUS_ID){
    //   return false
    // }else{
    //   return true
    // }
  };

  const handleBorButtonDisabled = (): boolean => {
    if(store.getState().po?.formData?.requestType?.key === RFQ_REQUEST){
      return true
    }
    if (props.purchaseOrderType && props.selectedProject) {
      if (props.formData?.isCu) {
        if (checkDeliveryPlanConfirm()) {
          return true;
        } else {
          if (props.formData?.status?.key === IN_DEVELOPMENT_STATUS_ID) {
            return false;
          } else if (!props.formData?.status?.key) {
            return false;
          }
        }
      } else {
        if (props.formData?.status?.key === IN_DEVELOPMENT_STATUS_ID) {
          return false;
        } else if (!props.formData?.status?.key) {
          return false;
        }
      }

      return true;
    }
    return true;
  };

  const checkDeliveryPlanConfirm = (): boolean => {
    let poResources = props.formData?.poResources;
    let allpoResources = poResources.materials.concat(poResources.consumable, poResources.tools, poResources.labour);
    if (allpoResources && allpoResources.length > 0) {
      let hasNull = allpoResources.filter(function(el: any) {
        return el.expectedDeliveryDate === null;
      });
      if (hasNull && hasNull.length >= 1) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const handleBorButtonName = (): string => {
    if (props.formData?.type?.key && !props.purchaseOrderType) {
      if (props.formData?.type?.key === PRODUCT_TYPE_ID) {
        return t('Product List');
      } else if (props.formData?.type?.key === GOODDEERIS_PRODUCT_TYPE_ID) {
        return t('Product List');
      } else {
        return t('BOR List');
      }

    } else if (props.purchaseOrderType) {
      if (props.purchaseOrderType === PRODUCT_TYPE_ID) {
        return t('Product List');
      } else if (props.purchaseOrderType === GOODDEERIS_PRODUCT_TYPE_ID) {
        return t('Product List');
      } else {
        return t('BOR List');
      }
    }
    return t('BOR List');
  };

  const handleSendButtonName = (): string => {
    if (getProject()) {
      if (
        (props.formData?.status?.key &&
          props.formData?.status?.key === IN_DEVELOPMENT_STATUS_ID) ||
        (props.formData?.status?.key &&
          props.formData?.status?.key === IN_REVIEW_STATUS_ID)
      ) {
        return t('send');
      } else if (props.formData?.status?.key) {
        return t('accept');
      }
      return t('send');
    }
    if (
      props.formData?.status?.key &&
      props.formData?.status?.key === IN_DEVELOPMENT_STATUS_ID
    ) {
      return t('send');
    } else if (
      (props.formData?.status?.key &&
        props.formData?.status?.key === IN_REVIEW_STATUS_ID) ||
      (props.formData?.status?.key &&
        props.formData?.status?.key === IN_REVIEW_FEEDBACK_STATUS_ID)
    ) {
      return t('feedback');
    } else if (
      (props.formData?.status?.key &&
        props.formData?.status?.key === IN_REVIEW_ACCEPT_STATUS_ID) ||
      (props.formData?.status?.key &&
        props.formData?.status?.key === APPROVED_STATUS_ID) ||
      (props.formData?.status?.key &&
        props.formData?.status?.key === HANDED_OVER_STATUS_ID)
    ) {
      return t('approve');
    }
    return t('send');
  };
  const handleSendButtonIcon = (): string => {
    if (getProject()) {
      if (
        (props.formData?.status?.key &&
          props.formData?.status?.key === IN_DEVELOPMENT_STATUS_ID) ||
        (props.formData?.status?.key &&
          props.formData?.status?.key === IN_REVIEW_STATUS_ID)
      ) {
        return 'send';
      } else if (props.formData?.status?.key) {
        return 'ActivateOrders';
      }
      return 'send';
    }
    if (
      props.formData?.status?.key &&
      props.formData?.status?.key === IN_DEVELOPMENT_STATUS_ID
    ) {
      return 'send';
    } else if (
      (props.formData?.status?.key &&
        props.formData?.status?.key === IN_REVIEW_STATUS_ID) ||
      (props.formData?.status?.key &&
        props.formData?.status?.key === IN_REVIEW_FEEDBACK_STATUS_ID)
    ) {
      return 'ActivateOrders';
    } else if (
      (props.formData?.status?.key &&
        props.formData?.status?.key === IN_REVIEW_ACCEPT_STATUS_ID) ||
      (props.formData?.status?.key &&
        props.formData?.status?.key === APPROVED_STATUS_ID) ||
      (props.formData?.status?.key &&
        props.formData?.status?.key === HANDED_OVER_STATUS_ID)
    ) {
      return 'ActivateOrders';
    }
    return 'send';
  };

  const handleSaveButtonDisabled = (): boolean => {
    if(store.getState().po?.formData?.requestType?.key === RFQ_REQUEST){
      return true
    }
    if (getProject()) {
      if (
        props.formData?.status?.key === IN_DEVELOPMENT_STATUS_ID ||
        !props.formData?.status?.key
      ) {
        return false;
      }
      return true;
    }
    if (
      props.formData?.status?.key === IN_REVIEW_STATUS_ID ||
      props.formData?.status?.key === IN_DEVELOPMENT_STATUS_ID ||
      props.formData?.status?.key === IN_REVIEW_FEEDBACK_STATUS_ID ||
      !props.formData?.status?.key
    ) {
      return false;
    } else if (props.formData?.status?.key === IN_DEVELOPMENT_STATUS_ID) {
      return false;
    }
    return true;
  };

  const handleDeliveryPlanButtonDisabled = (): boolean => {
    if(store.getState().po?.formData?.requestType?.key === RFQ_REQUEST){
      return true
    }

    if (props.formData?.requestType?.key === RETURN_REQUEST) {
      return true;
    } else if (props.formData?.requestType?.key === CAPACITY_REQUEST) {
      return true;
    } else {
      return false;
    }
    // if (!getProject()) {
    //     if (
    // props.formData?.status?.key === APPROVED_STATUS_ID ||
    // props.formData?.status?.key === HANDED_OVER_STATUS_ID
    //     ) {
    //         return false;
    //     }
    //     return true;
    // }

  };


  const convertCrTOPoButtonDisabled = (): boolean => {
    if (props.formData?.status?.key === APPROVED_STATUS_ID) {
      return false;
    } else {
      return true;
    }

  };

  const deliveryPlanClick = () => {
    //  messageService.sendMessage({ savePOData: true });
    messageService.sendMessage({ getDeliveryPlanModal: true });
    // props.createNew(true);
  };

  const handleCloneButtonDisabled = (): boolean => {
    if(store.getState().po?.formData?.requestType?.key === RFQ_REQUEST){
      return true
    }
    if(getProject()){
      return  !props.formData?.sequenceId
    }else{
      return  true
    }
  }

  const getItems = () => {
    const items = [];

    /* creating command bar buttons */
    const newBtn = {
      key: 'new',
      text: t('new'),
      iconProps: { iconName: 'Add' },
      split: true,
      subMenuProps: {
        items: [
          { key: 'purchaseRequest', text: t('purchaseRequest'), onClick: () => newClick(PURCHASE_REQUEST) },
          { key: 'returnRequest', text: t('returnRequest'), onClick: () => newClick(RETURN_REQUEST) },
          { key: 'capacityRequest', text: t('capacityRequest'), onClick: () => newClick(CAPACITY_REQUEST) },
        ],
      },
      disabled: false,
      onClick: () => newClick(PURCHASE_REQUEST),
    };
    const newBtnCu = {
      key: 'new',
      text: t('new'),
      iconProps: { iconName: 'Add' },
      disabled: false,
      onClick: () => newClick(SUPPLIER_PURCHASE_REQUEST),
    };
    const saveBtn = {
      key: 'save',
      name: t('save'),
      disabled: handleSaveButtonDisabled(),
      iconProps: { iconName: 'Save' },
      onClick: () => saveFormData(),
    };
    const sendBtn = {
      key: 'send',
      name: handleSendButtonName(),
      disabled: handleSendButtonDisabled(),
      iconProps: { iconName: handleSendButtonIcon() },
      onClick: () => handleApprove(),
    };
    const borBtn = {
      key: 'bor',
      name: handleBorButtonName(),
      //  name: (props.purchaseOrderType===RESOURCE_TYPE_ID  || props.formData?.type?.key===RESOURCE_TYPE_ID  || props.purchaseOrderType==null)?t('BOR List'): t('Product List'),
      iconProps: { iconName: 'Table' },
      disabled: handleBorButtonDisabled(),
      onClick: () => handleModalVisible(),
    };
    const deliveryPlanBtn = {
      key: 'deliveryPlan',
      name: t('deliveryPlan'),
      disabled: handleDeliveryPlanButtonDisabled(),
      iconProps: { iconName: 'AccountActivity' },
      onClick: () => deliveryPlanClick(),
    };
    const clone = {
      key: 'clone',
      name: t('clone'),
      disabled: handleCloneButtonDisabled(),
      iconProps: { iconName: 'Copy' },
      onClick: () => clonePo(),
    };
    const exportExcel = {
      key: 'excel',
      name: t('Download'),
      disabled: !props.formData?.sequenceId || props.purchaseOrderType === PRODUCT_TYPE_ID,
      iconProps: { iconName: 'download' },
      subMenuProps: {
        items: [
          { key: 'fullPo', text: t('fullPO'), onClick: () => exportPoExcel() },
          { key: 'priceQuotation', text: t('priceQuotation'), onClick: () => exportPricePoExcel() },
        ],
      },
    };

    const convertTOPo = {
      key: 'convertCrTOPo',
      name: t('convertTOPo'),
      disabled: convertCrTOPoButtonDisabled(),
      iconProps: { iconName: 'SyncStatus' },
      onClick: () => convertCrTOPo(),
    };
    /* pushing the buttons into the array */
    if (!getProject()) {
      items.push(newBtnCu);
    } else {
      items.push(newBtn);
    }
    items.push(saveBtn);
    items.push(sendBtn);
    items.push(borBtn);
    if (!getProject()) {
      if (!props.formData?.isCu) {
        // items.push(deliveryPlanBtn);
      }
      items.push(deliveryPlanBtn);
      items.push(exportExcel);
      items.push(clone);
    }
    if (getProject() && (props.formData?.requestType?.key === CAPACITY_REQUEST)) {
      items.push(convertTOPo);
    }

    return items;
  };

  const _overflowItems = [
    {
      key: 'excel',
      name: t('Download'),
      disabled: !props.formData?.sequenceId,
      iconProps: { iconName: 'download' },
      onClick: () => {
        exportPoExcel();
      },
    },
    {
      key: 'clone',
      name: t('clone'),
      disabled: !props.formData?.sequenceId,
      iconProps: { iconName: 'Copy' },
      onClick: () => {
        clonePo();
      },
    },
  ];
  const overflowProps: IButtonProps = { ariaLabel: 'More commands' };
  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
        overflowItems={!getProject() ? [] : []}
        overflowButtonProps={overflowProps}
      />
    </div>
  );
};

export default CommandBarRight;

import { formattedPayloadToDropdownList } from '../shared/util';
import {
  CREATE_WAREHOUSE_LOCATION_EP,
  CREATE_WAREHOUSE_TAXONOMY_NODE_EP,
  LOAD_WAREHOUSE_TAXONOMY_NODE_EP,
  READ_WAREHOUSE_BY_ID_EP,
  READ_WAREHOUSE_DROPDOWN_EP,
  READ_WAREHOUSE_LIST_EP,
  READ_WAREHOUSE_SHORTCUT_PANE_EP,
  WAREHOUSE_SAVE_EP,
} from '../shared/endpoints';
import {
  CLEAR_WAREHOUSE_FORM_DATA,
  CLEAR_WAREHOUSE_UID,
  CREATE_WAREHOUSE_LOCATION,
  CREATE_WAREHOUSE_LOCATION_FAIL,
  CREATE_WAREHOUSE_LOCATION_SUCCESS,
  CREATE_WAREHOUSE_TAXONOMY_NODE,
  CREATE_WAREHOUSE_TAXONOMY_NODE_FAIL,
  CREATE_WAREHOUSE_TAXONOMY_NODE_SUCCESS,
  LOAD_WAREHOUSE_BY_ID,
  LOAD_WAREHOUSE_BY_ID_FAIL,
  LOAD_WAREHOUSE_BY_ID_SUCCESS,
  LOAD_WAREHOUSE_DROPDOWN,
  LOAD_WAREHOUSE_DROPDOWN_FAIL,
  LOAD_WAREHOUSE_DROPDOWN_SUCCESS,
  LOAD_WAREHOUSE_LSITPANE,
  LOAD_WAREHOUSE_LSITPANE_FAIL,
  LOAD_WAREHOUSE_LSITPANE_SUCCESS,
  LOAD_WAREHOUSE_SHORTCUTPANE,
  LOAD_WAREHOUSE_SHORTCUTPANE_FAIL,
  LOAD_WAREHOUSE_SHORTCUTPANE_SUCCESS,
  LOAD_WAREHOUSE_TAXONOMY_NODE,
  LOAD_WAREHOUSE_TAXONOMY_NODE_FAIL,
  LOAD_WAREHOUSE_TAXONOMY_NODE_SUCCESS,
  LOAD_WAREHOUSE_TAXONOMY_NODE_WF,
  LOAD_WAREHOUSE_TAXONOMY_NODE_WF_FAIL,
  LOAD_WAREHOUSE_TAXONOMY_NODE_WF_SUCCESS,
  RESET_WAREHOUSE_IS_CHANGE,
  SAVE_WAREHOUSE,
  SAVE_WAREHOUSE_FAIL,
  SAVE_WAREHOUSE_SUCCESS,
  SET_WAREHOUSE_UID,
} from '../actionTypes/warehouseActionTypes';
import { v4 as uuidv4 } from 'uuid';
import warehouse from '../components/warehouse/warehouse';
import { Warehouse, WarehouseListPaneFilter } from '../types/warehouse';
import { LocationAddress } from '../types/projectDefinition';


const defaultState: any = {
  warehouseShortCutdata: [],
  listPaneItems: [],
  isWarehouseListLoaded: false,
  isLoading: false,
  isNew: false,
  isChange: false,
  isEdit: false,
  showProgressBar: false,
  warehouseDropDowns: {},
  formData: {},
  uid: null,
  warehouseLocationId: null,
  loadMsg: '',
  warehouseTaxonomy: [],
  projectWarehouseTaxonomy: [],
  whDropDownList: [],
};

export default function warehouseReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {
      case LOAD_WAREHOUSE_SHORTCUTPANE:
        return { ...state };
      case LOAD_WAREHOUSE_SHORTCUTPANE_SUCCESS:
        return {
          ...state,
          warehouseShortCutdata: action.payload.data.result,
        };
      case LOAD_WAREHOUSE_SHORTCUTPANE_FAIL:
        return { ...state };
      case LOAD_WAREHOUSE_LSITPANE:
        return { ...state, isWarehouseListLoaded: false };

      case LOAD_WAREHOUSE_LSITPANE_SUCCESS:
        return {
          ...state,
          isChange: false,
          isWarehouseListLoaded: true,
          // listPaneItems: listPaneDummydata(),
          listPaneItems: action.payload.data.result,
          loadMsg: !action.payload.data.status
            ? action.payload.data.message
            : '',
        };
      case LOAD_WAREHOUSE_LSITPANE_FAIL:
        return {
          ...state,
          isChange: false,
          isWarehouseListLoaded: true,
          listPaneItems: listPaneDummydata(),
          loadMsg: action.error.response.data.message,
        };
      case RESET_WAREHOUSE_IS_CHANGE:
        return {
          ...state,
          isChange: false,
        };

      case LOAD_WAREHOUSE_DROPDOWN:
        return { ...state, warehouseDropDowns: {} };
      case LOAD_WAREHOUSE_DROPDOWN_SUCCESS:
        return {
          ...state,
          warehouseDropDowns: action.payload.data.result,
          whDropDownList: formattedPayloadToDropdownList(action.payload.data.result, 'warehouse'),
        };
      case LOAD_WAREHOUSE_DROPDOWN_FAIL:
        return {
          ...state,
          warehouseDropDowns: {},
        };

      case LOAD_WAREHOUSE_TAXONOMY_NODE:
        return { ...state, warehouseTaxonomy: {} };
      case LOAD_WAREHOUSE_TAXONOMY_NODE_SUCCESS:
        return {
          ...state,
          warehouseTaxonomy: action.payload.data.result,
        };
      case LOAD_WAREHOUSE_TAXONOMY_NODE_WF_SUCCESS:
        return {
          ...state,
          projectWarehouseTaxonomy: action.payload.data.result,
        };
      case LOAD_WAREHOUSE_TAXONOMY_NODE_FAIL:
        return {
          ...state,
          warehouseTaxonomy: {},
        };

      case LOAD_WAREHOUSE_BY_ID:
        return { ...state, formData: {}, showProgressBar: true, isLoading: true };
      case LOAD_WAREHOUSE_BY_ID_SUCCESS:
        return {
          ...state,
          isEdit: true,
          formData: action.payload.data.result,
          showProgressBar: false,
          isLoading: false,
        };
      case LOAD_WAREHOUSE_BY_ID_FAIL:
        return {
          ...state,
          formData: {},
          showProgressBar: false,
          isLoading: false,
        };

      case SAVE_WAREHOUSE:
        return { ...state, showProgressBar: true };
      case SAVE_WAREHOUSE_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
        };
      case SAVE_WAREHOUSE_FAIL:
        return {
          ...state,
          showProgressBar: false,
        };

      case CREATE_WAREHOUSE_LOCATION:
        return { ...state, warehouseLocationId: null };
      case CREATE_WAREHOUSE_LOCATION_SUCCESS:
        return {
          ...state,
          warehouseLocationId: action.payload.data.result,
        };
      case CREATE_WAREHOUSE_LOCATION_FAIL:
        return {
          ...state,
          warehouseLocationId: null,
        };

      case SET_WAREHOUSE_UID:
        return {
          ...state,
          isEdit: false,
          uid: uuidv4(),
        };
      case CLEAR_WAREHOUSE_UID:
        return {
          ...state,
          uid: null,
          isChange: false,
        };
      case CLEAR_WAREHOUSE_FORM_DATA:
        return {
          ...state,
          uid: null,
          isNew: true,
          isEdit: false,
          isChange: false,
          formData: {},
        };

      default:
        return state;
    }
  }
}

export const readWarehouseShortCutPaneData = () => {
  return {
    types: [
      LOAD_WAREHOUSE_SHORTCUTPANE,
      LOAD_WAREHOUSE_SHORTCUTPANE_SUCCESS,
      LOAD_WAREHOUSE_SHORTCUTPANE_FAIL,
    ],
    payload: { request: { url: READ_WAREHOUSE_SHORTCUT_PANE_EP } },
  };
};

export const readWarehouseDropDownData = (projectSequenceCode?: string | null) => {
  return {
    types: [
      LOAD_WAREHOUSE_DROPDOWN,
      LOAD_WAREHOUSE_DROPDOWN_SUCCESS,
      LOAD_WAREHOUSE_DROPDOWN_FAIL,
    ],
    payload: { request: { method: 'GET', url: READ_WAREHOUSE_DROPDOWN_EP } },
  };
};


export const filterWarehouseListPaneData = (filter: WarehouseListPaneFilter) => {
  return {
    types: [
      LOAD_WAREHOUSE_LSITPANE,
      LOAD_WAREHOUSE_LSITPANE_SUCCESS,
      LOAD_WAREHOUSE_LSITPANE_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_WAREHOUSE_LIST_EP,
        data: filter,
      },
    },
  };
};


export const readByWarehouseId = (id: string) => {
  return {
    types: [
      LOAD_WAREHOUSE_BY_ID,
      LOAD_WAREHOUSE_BY_ID_SUCCESS,
      LOAD_WAREHOUSE_BY_ID_FAIL],
    payload: { request: { url: READ_WAREHOUSE_BY_ID_EP + id } },
  };
};

export const resetIsChange = () => {
  return { type: RESET_WAREHOUSE_IS_CHANGE };
};

export const saveFormData = (formData: Warehouse) => {
  return {
    types: [
      SAVE_WAREHOUSE,
      SAVE_WAREHOUSE_SUCCESS,
      SAVE_WAREHOUSE_FAIL],
    payload: {
      request: {
        url: WAREHOUSE_SAVE_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const createWarehouseLocation = (data: LocationAddress) => {
  return {
    types: [
      CREATE_WAREHOUSE_LOCATION,
      CREATE_WAREHOUSE_LOCATION_SUCCESS,
      CREATE_WAREHOUSE_LOCATION_FAIL],
    payload: {
      request: {
        url: CREATE_WAREHOUSE_LOCATION_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createTaxonomyNode = (newNode: any) => {
  return {
    types: [CREATE_WAREHOUSE_TAXONOMY_NODE, CREATE_WAREHOUSE_TAXONOMY_NODE_SUCCESS, CREATE_WAREHOUSE_TAXONOMY_NODE_FAIL],
    payload: {
      request: {
        url: CREATE_WAREHOUSE_TAXONOMY_NODE_EP,
        method: 'POST',
        data: newNode,
      },
    },
  };
};

export const readWarehouseTaxonomy = (data: any) => {
  return {
    types: [LOAD_WAREHOUSE_TAXONOMY_NODE, LOAD_WAREHOUSE_TAXONOMY_NODE_SUCCESS, LOAD_WAREHOUSE_TAXONOMY_NODE_FAIL],
    payload: {
      request: {
        url: LOAD_WAREHOUSE_TAXONOMY_NODE_EP,
        method: 'POST',
        data: data,
        // data:{wareHouseId:null}
      },
    },
  };
};

export const readWarehouseTaxonomyForProject = (data: any, project: string) => {
  return {
    types: [LOAD_WAREHOUSE_TAXONOMY_NODE_WF, LOAD_WAREHOUSE_TAXONOMY_NODE_WF_SUCCESS, LOAD_WAREHOUSE_TAXONOMY_NODE_WF_FAIL],
    payload: {
      request: {
        url: LOAD_WAREHOUSE_TAXONOMY_NODE_EP,
        method: 'POST',
        data: data,
        headers: { project: project },
        // data:{wareHouseId:null}
      },
    },
  };
};

export const setUid = () => {
  return { type: SET_WAREHOUSE_UID };
};

export const clearFormData = () => {
  return { type: CLEAR_WAREHOUSE_FORM_DATA };
};


const listPaneDummydata = () => {
  return [
    {
      'id': 'wh-001',
      'title': 'warehose dummy1',
      'type': 'Resources',
      'status': 'In Development',
      'typeId': null,
      'statusId': null,
      'sequenceId': 'WH-0441',
      'warehouseTaxonomy': 'warehouseTaxonomy 1',
      'warehouseTaxonomyId': '88bc4819-dn12-4c38-8a59-20da60ecb89e',
    },
    {
      'id': 'wh-002',
      'title': 'warehose dummy2',
      'type': 'Resources',
      'status': 'In Development',
      'typeId': null,
      'statusId': null,
      'sequenceId': 'WH-0431',
      'warehouseTaxonomy': null,
      'warehouseTaxonomyId': null,
    },
    {
      'id': 'wh-003',
      'title': 'warehose dummy3',
      'type': 'Resources',
      'status': 'In Development',
      'typeId': null,
      'statusId': null,
      'sequenceId': 'WH-0426',
      'warehouseTaxonomy': 'warehouseTaxonomy 3',
      'warehouseTaxonomyId': '88bc4819-qnmp-4c38-8a59-20da60ecb89e',
    },
    {
      'id': 'wh-004',
      'title': 'warehose dummy4',
      'type': 'Resources',
      'status': 'In Development',
      'typeId': null,
      'statusId': null,
      'sequenceId': 'WH-0431',
      'warehouseTaxonomy': 'warehouseTaxonomy 4',
      'warehouseTaxonomyId': '88bc4819-5968-4c38-8a59-20da60ecb89e',
    },
    {
      'id': 'wh-005',
      'title': 'warehose dummy5',
      'type': 'Resources',
      'status': 'In Development',
      'typeId': null,
      'statusId': null,
      'sequenceId': 'WH-0431',
      'warehouseTaxonomy': 'warehouseTaxonomy 5',
      'warehouseTaxonomyId': '88bc4819-dn12-4c38-8a59-20da60ecb89e',
    },
    {
      'id': 'wh-006',
      'title': 'warehose dummy6',
      'type': 'Resources',
      'status': 'In Development',
      'typeId': null,
      'statusId': null,
      'sequenceId': 'WH-0403',
      'warehouseTaxonomy': 'warehouseTaxonomy 6',
      'warehouseTaxonomyId': '88bc4819-qn12-4c38-8a59-20da60ecb89e',
    },
  ];
};

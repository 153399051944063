import {AnimationStyles, getTheme, ITheme, mergeStyles, Panel, PanelType, Stack} from '@fluentui/react';
import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import history from '../../history';
import {
  clearFormData,
  clearUid,
  deleteProduct,
  getById,
  getListPaneData,
  getResourcesListPaneData,
  getShortcutPaneFilterList,
  readDropdownData,
  resetFormData,
  resetIsChange,
  setReturnBorResources,
  setUid,
} from '../../reducers/billOfResourcesReducer';
import {messageService} from '../../services/messageService';
import BookMarkPane from '../../shared/bookMarkPane/bookMarkPane';
import ConfirmationDialog from '../../shared/confirmationDialog/confirmationDialog';
import ProgressBar from '../../shared/progressBar/progressBar';
import TitlePane from '../../shared/titlePane/titlePane';
import {excelExport, getContractingUnit, getProject} from '../../shared/util';
import {
  BillOfResource,
  BOR_TYPE,
  BorFormData,
  BorListPaneItem,
  FilterItem,
  ResourceFilterItem,
  ResourceListPaneItem,
  ShortCutPaneItem,
} from '../../types/billOfResources';
import '../style/style.css';
import CommandBarLeft from './commandBar/commandBarLeft';
import CommandBarRight from './commandBar/commandBarRight';
import DocumentPane from './documentPane/container';
import ListPane from './listPane/container';
import ShortCutPane from './shortCutPane/container';
import CollapseBookMarkPane from '../../shared/collapseBookMarkPane/bookMarkPane';
import SplitPane, {Pane} from 'react-split-pane';
import {PMOL_TYPE} from '../../types/projectMolecule';
import {saveVPStateAttr} from './../../reducers/visualPlanReducer';
import _ from 'lodash';
import {resizerStyle} from "../../shared/customStyle/commonStyles";

export interface State {
  selectedShortCutItem: ShortCutPaneItem;
  filter: FilterItem;
  resourceFilter: ResourceFilterItem;
  projectId: string | null;
  borId: string | null;
  formData: BillOfResource;
  isBorReturn: boolean

  isClone: boolean;
  redirect: boolean;
  loading: boolean;
  showPanel: boolean;
  width: number;
  activeClass: string;
  bookmarkDivClass: string;
  headerFilter: boolean;
  showDetails: boolean;
  currentActiveSection: string;
  isOpenNickNameModel: boolean;
  uploadedImages: string | null;
  autoSave: boolean;
  isEdit: boolean;
  selectedListPaneItemIds: [] | string[];
  //headerTitle:string|null,
  hiddenRemoveDialog: boolean;
  isDrag: boolean;
  resetLayout: boolean;
  collapseBookmarkPane: boolean;
  reSizer: number
  showProgressBar: boolean;
}

interface Props extends WithTranslation {
  match: any;
  resetFormData: () => void;
  getShortcutPaneFilterList: () => void;
  readDropdownData: () => void;
  shortCutPaneFilters: ShortCutPaneItem[];
  getListPaneData: (filterItem: FilterItem) => void;
  getResourcesListPaneData: (filterItem: ResourceFilterItem) => void;
  borListPaneItem: BorListPaneItem[];
  resourceListPaneItem: ResourceListPaneItem[];
  formData: BillOfResource;
  setUid: () => void;
  clearUid: () => void;
  clearFormData: () => void;
  resetIsChange: () => void;
  uid: string | null;
  getById: (id: string) => void;
  getExcelData: () => void;
  getOrganizationByName: (name: string) => void;
  deleteProduct: (idList: [] | string[]) => void;
  getDropdownData: () => void;
  savePicturesOfTheInstallation: (files: FileList | null) => void;
  uploadedImages: string | null;
  resourceTypes: [];
  resourceTypeFilter: [];
  resourceFamily: [];
  languageCodes: [];
  organizations: [];
  excelData: [];
  autoSave: boolean;
  isChange: boolean;
  isEdit: boolean;
  isDataLoaded?: boolean;
  loadMsg: string;
  location: any;
  showProgressBar: boolean;
  setReturnBorResources: any;
  saveVPStateAttr: any
}

const contentStyle = mergeStyles({
  height: 'calc(100vh - 250px)!important',
  display: 'flex',
  flexDirection: 'column',
  selectors: {
    '@media(max-width: 1100px)': {
      width: '97.4%',
      height: 'calc(100vh)',
      position: 'absolute',
    },
  },
});

const listStyle = mergeStyles({
  flex: '1 1 auto',
  width: '100%',
  height: 'auto',
  overflowX: 'hidden',
  overflowY: 'auto',
  paddingBottom: '30px',
  marginLeft: '0',
});

class BillOfResourcesMainLayout extends React.Component<Props, State> {
  _isMounted = false;
  isEdit = false;
  theme: ITheme = getTheme();
  subscription: any;
  //this.props.t
  private bookmarkList = [
    { id: 1, link: 'bor-detail', label: this.props.t('bORHeader') },
    { id: 2, link: 'bor-product', label: this.props.t('product') },
    { id: 3, link: 'bor-material', label: this.props.t('material') },
    { id: 4, link: 'bor-tools', label: this.props.t('tools') },
    { id: 5, link: 'bor-consumables', label: this.props.t('consumables') },
    { id: 6, link: 'bor-labour', label: this.props.t('labour') },
    {
      id: 7,
      link: 'history',
      label: this.props.t('history'),
    },
  ];
  private bookmarkListReturn = [
    { id: 1, link: 'bor-detail', label: this.props.t('bORHeader') },
    { id: 2, link: 'bor-material', label: this.props.t('material') },
    { id: 3, link: 'bor-tools', label: this.props.t('tools') },
    { id: 4, link: 'bor-consumables', label: this.props.t('consumables') },
    { id: 5, link: 'bor-labour', label: this.props.t('labour') },
    {
      id: 6,
      link: 'history',
      label: this.props.t('history'),
    },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedShortCutItem: { key: null, id: null, name: 'Bor', type: 'Bor' },
      filter: {
        borTitle: null,
        product: null,
        locationParent: null,
        utilityParent: null,
        sorter: {
          attribute: null,
          order: null,
        },
      },
      resourceFilter: {
        deliveryRequested: null,
        invoiced: null,
        purchased: null,
        required: null,
        resourceTitle: null,
        resourceTypeId: null,
        warf: null,
        sorter: {
          attribute: null,
          order: null,
        },
      },
      projectId: null,
      borId: null,

      hiddenRemoveDialog: true,
      isClone: false,
      selectedListPaneItemIds: [],
      isOpenNickNameModel: false,
      isEdit: false,
      autoSave: false,
      redirect: false,
      loading: false,
      width: window.innerWidth,
      showPanel: false,
      headerFilter: false,
      activeClass: 'grid-righter column-filter-activated',
      bookmarkDivClass: 'bookmark-panel parent collapse',
      currentActiveSection: '1',
      showDetails: false,
      uploadedImages: null,
      formData: BorFormData,
      isDrag: false,
      resetLayout: false,
      collapseBookmarkPane: false,
      reSizer: window.innerWidth,
      showProgressBar: false,
      isBorReturn: false,
    };

    this.updateDimensions = this.updateDimensions.bind(this);
    this._handleClick = this._handleClick.bind(this);
  }

  componentDidMount() {
    this.props.getShortcutPaneFilterList();
    this.props.readDropdownData();
    this.getListPaneData();
    this.handleUrlChange();
    this.props.saveVPStateAttr('slectedPBSForBor', null);

    this._isMounted = true;
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('load', this.updateDimensions);
    if (
      location.pathname.split('/').pop() === 'new' &&
      this.state.formData &&
      this.state.formData.id === null
    ) {
      this.createNew(true, BOR_TYPE.REGULAR);
    }
    this.setState(
      {
        formData: this.props.formData,
      },
      this.handleShowDocumentPane,
    );
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.hideDocumentPane) {
          this.hideDocumentPane();
        }

        if (data.data.vpBorPbsReset) {
          this.createNew(true, BOR_TYPE.REGULAR);
        }

        if (data.data.collapseDocumentPane) {
          this.setState({
            reSizer: (this.state.reSizer === 300) ? window.innerWidth / 2 : 300,
            isDrag: (!this.state.isDrag),
            collapseBookmarkPane: (!this.state.collapseBookmarkPane),
            resetLayout: (!this.state.resetLayout),
          });
        }

        if (data.data.shortCutPaneExpanded) {
          if (this.state.showDetails) {
            this.setState({
              reSizer: window.innerWidth / 2,
              isDrag: false,
              collapseBookmarkPane: false,
              resetLayout: true,
            });
          }
        }

        if (data.data.exportBorResource) {
          this.exportBorResource();
        }
        if (data.data.isBorReturn) {
          this.props.clearFormData();
          this.setState({
            isBorReturn: true,
          });
        }
        if (data.data.isNewBor) {
          this.props.clearFormData();
          this.setState({
            isBorReturn: false,
          });
        }
      }
    });
  }

  componentDidUpdate(prevProps: Props, _prevState: State) {
    if (this.props.match.url !== prevProps.match.url) {
      this.handleUrlChange(_prevState.collapseBookmarkPane);
    }
    if (prevProps.isEdit != this.props.isEdit) {
      if (this.props.isEdit) {
        this.setState({ isBorReturn: false });
      }
    }
    if (this.props.formData !== prevProps.formData && !_.isEmpty(this.props.formData)) {
      this.setState({
        formData: this.props.formData,
        isBorReturn: this.props.formData.borTypeId === BOR_TYPE.RETURN ? true : false,
      });
    }
    if (
      this.props.location &&
      this.props.location.state &&
      this.state.selectedShortCutItem !=
      this.props.location.state.borSelectedShortCutPaneItem
    ) {
      if (this.props.location.state.borSelectedShortCutPaneItem) {
        this.resetFilter();
        this.setResourceFilter(
          this.props.location.state.borSelectedShortCutPaneItem,
        );
        this.setState(
          {
            selectedShortCutItem: this.props.location.state
              .borSelectedShortCutPaneItem,
          },
          this.reloadListPaneItem,
        );
      }
    }
  }

  handleUrlChange(collapseBookmarkPane?: boolean) {
    let lastParam = this.props.match.url.split('/').pop();
    let projectId = this.props.match.params.id;
    let borId = this.props.match.params.borId
      ? this.props.match.params.borId
      : null;
    this.setState(
      {
        isEdit: !!this.props.match.params.id,
        projectId: projectId,
        borId: borId,
        resetLayout: collapseBookmarkPane ? false : true,
        collapseBookmarkPane: collapseBookmarkPane ? true : false,
      }, () => {
        this.handleShowDocumentPane(collapseBookmarkPane);
      });
    if (borId) {
      this.props.getById(borId);
    }
  }

  updateDimensions() {
    if (this._isMounted) {
      this.setState({
        width: window.innerWidth,
      });
      if (this.state.showDetails) {
        if (this.state.width > 1100) {
          this.setState({ showPanel: false });
        } else {
          this.setState({ showPanel: true });
        }
      }
    }
  }

  private handleShowDocumentPane = (collapseBookmarkPane?: boolean) => {
    let borId = this.props.match.params.borId
      ? this.props.match.params.borId
      : null;
    if (borId) {
      if (this.state.width > 1100) {
        this.setState({
          showPanel: false,
          showDetails: true,
          reSizer: collapseBookmarkPane ? 300 : window.innerWidth / 2,
        });
      } else {
        this.setState({
          showPanel: true,
          showDetails: true,
          reSizer: collapseBookmarkPane ? 300 : window.innerWidth / 2,
        });
      }
    } else if (location.pathname.split('/').pop() === 'new') {
      if (this.state.width > 1100) {
        this.setState({ showPanel: false, showDetails: true, reSizer: window.innerWidth / 2 });
      } else {
        this.setState({ showPanel: true, showDetails: true, reSizer: window.innerWidth / 2 });
      }
    } else {
      this.props.resetFormData();
      this.setState({ showPanel: false, showDetails: false, reSizer: window.innerWidth });
    }
  };

  private handleScroll = (e: any): void => {
    let element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
    }

    let h2Headings = document.getElementsByClassName('proj-detail-block');
    let item = h2Headings[0];
    let tt = 0;

    for (let i = 0; i < h2Headings.length; i++) {
      if (h2Headings[i].getBoundingClientRect().top <= 300) {
        if (tt <= h2Headings[i].getBoundingClientRect().top) {
          tt = h2Headings[i].getBoundingClientRect().top;
          item = h2Headings[i];

          var c = item.id;
          this.setState({ currentActiveSection: c });
        }
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.updateDimensions);
    this.props.resetFormData();
    this.subscription.unsubscribe();
  }

  exportBorResource = (): void => {

    this.setState({
      showProgressBar: true,
    });

    this.formatDataToExcel().then((excelData) => {
      if (excelData) {
        excelExport(
          excelData,
          // File name and Sheet name will change
          `${getProject()} _ReturnResources`,
          this.props.t('borResources'),
        );
      }
      setTimeout(() => {
        this.setState({
          showProgressBar: false,
        });
      }, 3000);
    });
    setTimeout(() => {
      this.setState({
        showProgressBar: false,
      });
    }, 3000);
  };

  formatDataToExcel = async () => {
    let borResource = this.props.resourceListPaneItem;
    let data = borResource.map((item: any) => {
      return {
        [this.props.t('resourceId')]: item.corporateProductCatalogId,
        [this.props.t('resourceType')]: item.resourceType,
        [this.props.t('resourceTitle')]: item.resourceTitle,
        [this.props.t('required')]: item.required,
        [this.props.t('purchased')]: item.purchased,
        [this.props.t('deliveryRequested')]: item.deliveryRequested,
        [this.props.t('warf')]: item.warf,
        [this.props.t('consumed')]: item.consumed,
        [this.props.t('return')]: item.return,
        [this.props.t('invoiced')]: item.invoiced,
      };
    });
    return data;
  };

  getListPaneData = () => {
    if (
      this.state.selectedShortCutItem &&
      this.state.selectedShortCutItem.type === 'Bor'
    ) {
      this.props.getListPaneData(this.state.filter);
    } else {
      this.props.getResourcesListPaneData(this.state.resourceFilter);
    }
  };

  private handleSelectedListPaneItem = (
    selectedListPaneItemIds: [] | string[],
  ) => {
    this.setState({
      selectedListPaneItemIds: selectedListPaneItemIds,
      isClone: false,
    });
  };

  savePicturesOfTheInstallation = (files: FileList | null) => {
    if (files) {
      this.props.savePicturesOfTheInstallation(files);
    }
  };

  private createNew = (_create: boolean, borTypeId: string): void => {
    this.props.clearFormData();
    this.props.setUid();
    let formData = {
      id: this.props.uid ? this.props.uid : null,
      borStatusId: '94282458-0b40-40a3-b0f9-c2e40344c8f1',
      itemId: null,
      borTitle: null,
      headerTitle: null,
      name: null,
      pbsProductId: null,
      locationParentId: null,
      utilityParentId: null,
      productItemType: null,
      historyLog: null,
      borTypeId: borTypeId,
      product: {
        id: null,
        productId: null,
        title: null,
        utilityParentId: null,
        locationParentId: null,
        locationTaxonomyParentId: null,
        utilityTaxonomyParentId: null,
        pbsProductItemType: null,
        pbsProductItemTypeId: null,
      },
      borResources: {
        labour: [],
        consumable: [],
        tools: [],
        materials: [],

      },
      startDate: null,
      endDate: null,
    };
    this.setState({
      formData: formData,
    });

    if (getContractingUnit()) {
      // history.push(
      //   `/CU/${getContractingUnit()}/project/${getProject()}/bill-Of-resources/new`
      // );
      history.push({
        pathname: `/CU/${getContractingUnit()}/project/${getProject()}/bill-Of-resources/new`,
        state: { type: borTypeId, isBorReturn: borTypeId === BOR_TYPE.RETURN },
      });
    } else {
      history.push(`/bill-Of-resources/new`);
    }
  };

  reloadListPaneItem = () => {
    //
    this.props.resetIsChange();
    setTimeout(() => {
      this.getListPaneData();
    }, 100);
    //this.props.getExcelData();
  };

  private getDocumentPaneView = () => {
    return (
      <div>
        <Stack
          className="project-list-header right-panel"
          style={{ backgroundColor: 'rgb(255, 255, 255)', paddingRight: 0 }}
          styles={{ root: { width: '100%', paddingRight: 0 } }}
        >
          <TitlePane
            close={true}
            headerTitle={
              this.state.formData ? this.state.formData.headerTitle : ''
            }
            collapse={true}
            isCollapse={this.state.collapseBookmarkPane}
          />
          <CommandBarRight
            createNew={(create: any, type: string) => this.createNew(create, type)}
            formData={this.state.formData}
            handleRemove={() => this._handleRemove()}
            isLoading={this.props.showProgressBar}
            selectedShortCutItem={this.state.selectedShortCutItem}
            isBorReturn={this.state.isBorReturn}
            setReturnBorResources={this.props.setReturnBorResources}
          />
          <ProgressBar show={this.props.showProgressBar}/>
        </Stack>

        <Stack
          className={`pmol project-detail-list ${(this.state.collapseBookmarkPane && window.innerWidth > 1440) ? 'full-inner-container' : 'inner-container'} cpc  card-container`}
          styles={{
            root: {
              width: '100%',
              height: '76vh',
            },
          }}
        >
          {/*doc*/}
          <Stack
            className={contentStyle}
            horizontalAlign="start"
            verticalAlign="start"
            padding={0}
            gap={0}
            styles={{
              root: {
                width: '100%',
                height: '100%',
              },
            }}
          >
            <Stack
              className={listStyle}
              styles={{
                root: {
                  width: '100%',
                  height: 'auto',
                },
              }}
              verticalFill
              gap={50}
              horizontalAlign="start"
              verticalAlign="start"
              onScroll={this.handleScroll}
            >
              <DocumentPane
                projectId={this.state.projectId}
                isEdit={this.state.isEdit}
                formData={this.state.formData}
                savePicturesOfTheInstallation={(files: FileList | null) =>
                  this.savePicturesOfTheInstallation(files)
                }
                autoSave={this.props.autoSave}
                uploadedImages={this.props.uploadedImages}
                resourceTypes={this.props.resourceTypes}
                resourceFamily={this.props.resourceFamily}
                languageCodes={this.props.languageCodes}
                organizations={this.props.organizations}
                handelGetOrganizationByName={(name: string) =>
                  this.handelGetOrganizationByName(name)
                }
                showProgress={this.state.showProgressBar}
                isBorReturn={this.state.isBorReturn}
              />
            </Stack>
            <BookMarkPane
              isEdit={this.props.isEdit}
              divClass={this.state.bookmarkDivClass}
              currentActiveSection={this.state.currentActiveSection}
              list={this.state.isBorReturn ? this.bookmarkListReturn : this.bookmarkList}
              toggleBookmark={this._toggleBookmark}
              toggleBookmarkOut={this._toggleBookmarkOut}
            />
            {/* {this.state.collapseBookmarkPane && window.innerWidth > 1440 ? <CollapseBookMarkPane
              isEdit={this.props.isEdit}
              divClass={this.state.bookmarkDivClass}
              currentActiveSection={this.state.currentActiveSection}
              list={this.state.isBorReturn ? this.bookmarkListReturn : this.bookmarkList}
              toggleBookmark={this._toggleBookmark}
              toggleBookmarkOut={this._toggleBookmarkOut}
              pmolType={PMOL_TYPE.REGULAR}
            /> : <BookMarkPane
              isEdit={this.props.isEdit}
              divClass={this.state.bookmarkDivClass}
              currentActiveSection={this.state.currentActiveSection}
              list={this.state.isBorReturn ? this.bookmarkListReturn : this.bookmarkList}
              toggleBookmark={this._toggleBookmark}
              toggleBookmarkOut={this._toggleBookmarkOut}
            />} */}
          </Stack>
        </Stack>
      </div>
    );
  };

  handelFilter = (filter: FilterItem) => {
    this.setState({
      filter: filter,
    });
  };
  handelResourceFilter = (filter: ResourceFilterItem) => {
    this.setState({
      resourceFilter: filter,
    });
  };

  resetFilter = () => {
    let resourcefilter: ResourceFilterItem = {
      deliveryRequested: null,
      invoiced: null,
      purchased: null,
      required: null,
      resourceTitle: null,
      resourceTypeId: null,
      warf: null,
      sorter: {
        attribute: null,
        order: null,
      },
    };
    let filter: FilterItem = {
      borTitle: null,
      product: null,
      locationParent: null,
      utilityParent: null,
      sorter: {
        attribute: null,
        order: null,
      },
    };

    this.setState({
      filter: filter,
      resourceFilter: resourcefilter,
    });
  };

  setResourceFilter = (selectedItem: ShortCutPaneItem) => {
    let filter: ResourceFilterItem = {
      deliveryRequested: null,
      invoiced: null,
      purchased: null,
      required: null,
      resourceTitle: null,
      resourceTypeId: null,
      warf: null,
      sorter: {
        attribute: null,
        order: null,
      },
    };
    if (selectedItem && selectedItem.type === 'AllResource') {
      filter.resourceTypeId = null;
    } else if (selectedItem && selectedItem.type === 'Resource') {
      filter.resourceTypeId = selectedItem.id;
    }

    this.setState({
      resourceFilter: filter,
    });
  };

  handelGetOrganizationByName = (name: string) => {
    this.props.getOrganizationByName(name);
  };

  handelShortCutPaneFilter = (selectedItem: ShortCutPaneItem) => {
    this.setState({
      selectedShortCutItem: selectedItem,
    });
  };

  render() {
    const { t } = this.props;
    const animation = mergeStyles(
      this.state.showDetails
        ? AnimationStyles.slideLeftIn400
        : AnimationStyles.slideRightIn400,
    );
    return (
      <div>
        <div className={'ms-Grid'}>
          <SplitPane
            className={'SplitPane'}
            split="vertical"
            minSize={300}
            maxSize={window.innerWidth / 2}
            defaultSize={(this.state.showDetails) ? window.innerWidth / 2 : window.innerWidth}
            resizerStyle={resizerStyle}
            style={{ height: 'calc(100vh - 129px)' }}
            size={this.state.reSizer}
            onDragStarted={() => {
              // this.setState({
              //   isDrag:true,
              //   collapseBookmarkPane:true,
              //   resetLayout:false,
              // })
            }}
            onResizerDoubleClick={() => {

              this.setState({
                reSizer: (this.state.reSizer === 300) ? window.innerWidth / 2 : 300,
                isDrag: (!this.state.isDrag),
                collapseBookmarkPane: (!this.state.collapseBookmarkPane),
                resetLayout: (!this.state.resetLayout),
              });
            }}
            onDragFinished={(newSize: number) => {
              this.setState({
                // isDrag:false,
                // resetLayout:false,
                reSizer: newSize,
                collapseBookmarkPane: (newSize < window.innerWidth / 2),
              });
            }}
            onChange={(newSize: number) => {
              if (newSize === window.innerWidth / 2) {
                this.setState({
                  resetLayout: true,
                  collapseBookmarkPane: false,
                });
              }
            }}
          >
            <Pane className={'SplitPane'}>
              <Stack horizontal>
                <ShortCutPane
                  projectId={this.state.projectId}
                  shortCutPaneFilters={this.props.shortCutPaneFilters}
                  filter={this.state.filter}
                  handelShortCutPaneFilter={(
                    selectedItem: ShortCutPaneItem,
                  ) => this.handelShortCutPaneFilter(selectedItem)}
                  toggleOverlay={this.state.collapseBookmarkPane}
                  resetLayout={this.state.resetLayout}
                />

                <div className={this.state.activeClass}>
                  <Stack
                    className="project-list-header right-panel"
                    style={{
                      backgroundColor: 'rgb(255, 255, 255)',
                      paddingRight: 0,
                    }}
                    styles={{ root: { width: '100%', paddingRight: 0 } }}
                  >
                    <TitlePane
                      headerTitle={
                        this.state.selectedShortCutItem
                          ? this.state.selectedShortCutItem.name
                          : ''
                      }
                    />
                    <CommandBarLeft
                      createNew={(create: boolean, type) => this.createNew(create, type)}
                      selectedShortCutItem={this.state.selectedShortCutItem}
                    />
                  </Stack>
                  {/*{JSON.stringify(this.props.loadMsg)}*/}
                  <Stack style={{ alignSelf: 'center' }}>
                    <ListPane
                      selectedShortCutItem={this.state.selectedShortCutItem}
                      projectId={this.state.projectId}
                      loadMsg={this.props.loadMsg}
                      resourceTypes={this.props.resourceTypeFilter}
                      reloadListPaneItem={() => this.reloadListPaneItem()}
                      filter={this.state.filter}
                      resourceFilter={this.state.resourceFilter}
                      borListPaneItem={this.props.borListPaneItem}
                      resourceListPaneItem={this.props.resourceListPaneItem}
                      isDataLoaded={this.props.isDataLoaded}
                      isChange={this.props.isChange}
                      formData={this.state.formData}
                      handelFilter={(filter: FilterItem) =>
                        this.handelFilter(filter)
                      }
                      handelResourceFilter={(filter: ResourceFilterItem) =>
                        this.handelResourceFilter(filter)
                      }
                      handleSelectedListPaneItem={(
                        selectedListItemIds: [] | string[],
                      ) => {
                        this.handleSelectedListPaneItem(selectedListItemIds);
                      }}
                      reSizer={this.state.reSizer}
                      showProgress={this.state.showProgressBar}
                    />
                    {/*LIST PANE*/}
                  </Stack>
                </div>
              </Stack>
            </Pane>
            <Pane className={'SplitPane'}>
              {this.state.showDetails &&
              <div
                className={`${animation}`}
                style={{
                  display: this.state.showDetails ? 'block' : 'none',
                  transition: 'all 0.9s linear',
                }}
              >
                {!this.state.showPanel && (
                  <div>{this.getDocumentPaneView()}</div>
                )}
              </div>}
            </Pane>
          </SplitPane>
        </div>
        <Panel
          isOpen={this.state.showPanel}
          type={PanelType.smallFixedFar}
          onDismiss={this._hidePanel}
          isLightDismiss={true}
          headerText={t('corporateProductCatalogue')}
          className="custom-detail-panel"
          closeButtonAriaLabel={t('close')}
        >
          {this.getDocumentPaneView()}
        </Panel>

        <ConfirmationDialog
          hidden={this.state.hiddenRemoveDialog}
          title={t('areyousure')}
          subText={t('youwonTbeabletorevertthis')}
          onClickConfirmDialog={this.onRemoveConfirm}
          onClickCancelDialog={this.onRemoveCancel}
          cancelButtonText={t('noIwonT')}
          confirmButtonText={t('yesDeleteit')}
        />
      </div>
    );
  }

  private _showPanel = () => {
    if (this.state.width <= 1100) {
      this.setState({ showPanel: true });
    }
  };

  private _hidePanel = () => {
    this.setState({ showPanel: false, showDetails: false });
    if (getContractingUnit()) {
      history.push(
        `/CU/${getContractingUnit()}/project/${getProject()}/bill-Of-resources`,
      );
    } else {
      history.push(`/bill-Of-resources`);
    }
  };

  private hideDocumentPane = () => {
    if (getContractingUnit()) {
      history.push(
        `/CU/${getContractingUnit()}/project/${getProject()}/bill-Of-resources`,
      );
    } else {
      history.push(`/bill-Of-resources`);
    }
  };

  private _handleClick(): void {
    if (this.state.headerFilter == false) {
      this.setState({
        activeClass: 'grid-righter column-filter-activated',
        headerFilter: true,
      });
    } else {
      this.setState({ activeClass: 'grid-righter', headerFilter: false });
    }
  }

  private _toggleBookmark = (): void => {
    this.setState({ bookmarkDivClass: 'bookmark-panel parent panel-on' });
  };

  private _toggleBookmarkOut = (): void => {
    this.setState({ bookmarkDivClass: 'bookmark-panel parent collapse' });
  };

  private openNickNameModel = (): void => {
    this.setState({ isOpenNickNameModel: !this.state.isOpenNickNameModel });
  };

  private _handleRemove = () => {
    this.setState({
      hiddenRemoveDialog: false,
    });
  };

  private onRemoveConfirm = () => {
    this.setState({
      hiddenRemoveDialog: true,
    });
    if (this.state.formData.itemId && this.state.formData.id) {
      this.props.deleteProduct([this.state.formData.id]);
      this.createNew(true, BOR_TYPE.REGULAR);
      if (getContractingUnit()) {
        history.push(
          `/CU/${getContractingUnit()}/project/${getProject()}/bill-Of-resources/new`,
        );
      } else {
        history.push(`/bill-Of-resources/new`);
      }
    }
  };

  private onRemoveCancel = () => {
    this.setState({
      hiddenRemoveDialog: true,
    });
  };

  private exportExcel = (): void => {
    if (this.props.excelData) {
      excelExport(
        this.props.excelData,
        this.props.t('corporateProductCatalogue'),
        this.props.t('corporateProductCatalogue'),
      );
    }
  };
}

const mapStateToProps = (state: any, _ownProps: any) => {
  return {
    shortCutPaneFilters: state.billOfResource.shortCutPaneFilters,
    borListPaneItem: state.billOfResource.borListPaneItem,
    resourceListPaneItem: state.billOfResource.resourceListPaneItem,
    loadMsg: state.billOfResource.loadMsg,
    isDataLoaded: state.billOfResource.isDataLoaded,
    formData: state.billOfResource.formData,
    isChange: state.billOfResource.isChange,
    isEdit: state.billOfResource.isEdit,
    showProgressBar: state.billOfResource.showProgressBar,
  };
};

const mapDispatchToProps = {
  getShortcutPaneFilterList,
  getListPaneData,
  getResourcesListPaneData,
  readDropdownData,
  setUid,
  clearUid,
  clearFormData,
  resetIsChange,
  getById,
  deleteProduct,
  resetFormData,
  setReturnBorResources,
  saveVPStateAttr,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(BillOfResourcesMainLayout)),
);

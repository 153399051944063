import React, { SyntheticEvent, useEffect, useRef } from 'react';
import styles from './vertical-scroll.module.css';

export const VerticalScroll: React.FC<{
  scroll: number;
  ganttHeight: number;
  ganttFullHeight: number;
  headerHeight: number;
  rtl: boolean;
  onScroll: (event: SyntheticEvent<HTMLDivElement>) => void;
}> = ({
        scroll,
        ganttHeight,
        ganttFullHeight,
        headerHeight,
        rtl,
        onScroll,
      }) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scroll;
    }
  }, [scroll]);

  return (
    <div
      style={{
        height: '100%',
        marginTop: -15,
        marginLeft: rtl ? '' : '-17px',
      }}
      className={styles.scroll}
      onScroll={onScroll}
      ref={scrollRef}
    >
      <div style={{ height: '100%', width: 1 }}/>
    </div>
  );
};

import {
  ActionButton,
  Checkbox, DetailsList, DetailsListLayoutMode,
  getTheme, IColumn,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Separator,
  Stack, Sticky, StickyPositionType,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { uPrinceTheme } from '../../../../theme';
import { readByStandardMailId, saveFormData } from '../../../reducers/standardMailReducer';
import { CKEditorWrapper, TextFieldFF } from '../../../shared/fluentFinalForm';
import FixedDecimalNumberFormatField from '../../../shared/fluentFinalForm/FixedDecimalNumberFormatField';
import HistoryBar from '../../../shared/historyBar/historyBar';
import { getProject } from '../../../shared/util';
import { StandardMail } from '../../../types/standardMail';
import { v4 as uuid4 } from 'uuid';
import { ReadRisk } from '../../../types/projectBreakdownStructure';
import { RiskTableColumn } from '../../../shared/tableColumn/tableColumn';


const theme = getTheme();

const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const stackEditbarItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 45,
    alignItems: 'flex-end',
  },
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };

const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '30%' },
  halfWidthName: { width: '49%' },
});

// const required = (value: any) => (value ? undefined : 'Required');

const validate = (values: { company: any; gender: any; email: any }) => {
  const errors: { company: string; gender: string; email: string } = {
    company: '',
    gender: '',
    email: '',
  };
  if (!values.company) {
    errors.company = 'Company Required';
  }
  if (!values.gender) {
    errors.gender = 'Gender Required';
  }
  if (!values.email) {
    errors.email = 'Email Required';
  }
  return errors;
};

const DocumentPaneComponent = (props: {
  submit: any;
  saveFormData: any;
  uid: any;
  showProgress: boolean;
  formValues: any;
  formData: any;
  isExpand: boolean;
  setForm: (form: any) => void;
  isClear: boolean;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [state, setstate] = useState<StandardMail | null>(null);

  const {
    formData, isClear, showProgressBar, smId,
  }: any = useSelector((smState: any) => smState.sm);

  const onSubmit = async (values: any) => {
    values.projectSequenceCode = getProject();
    if (!values.id) {
      values.id = uuid4();
    }
    if (values.isDefault === undefined) {
      values.isDefault = false;
    }
    dispatch(saveFormData(values));
  };

  useEffect(() => {
    setstate(formData);
  }, [formData]);

  useEffect(() => {
    if (smId) {
      dispatch(readByStandardMailId(smId));
    }
  }, [smId]);

  const getInitialFormValues = (formValue: any) => {
    if (location.pathname.split('/').pop() === 'new' && isClear) {
      return {};
    } else if (formData && formData?.id) {
      return { ...formData };
    }
    return formValue;
  };

  const rendercardFooter = (form: any) => {
    return (
      <div className={'card-footer'}>
        <Separator/>
        <Stack styles={stackStyles} tokens={itemAlignmentsStackTokens}>
          <Stack.Item align="end" styles={stackFooterBarItemStyles}>
            <ActionButton
              className={classNames.actionButton}
              iconProps={saveIcon}
              allowDisabledFocus
              onClick={() => {
                // alert(JSON.stringify(values.purchaseOrderType, 0, 2));
                form.submit();
              }}
              // disabled={ (props.dropDownOptions.isApproved) }
            >
              <Label className={classNames.actionButton}>{t('save')}</Label>
            </ActionButton>
          </Stack.Item>
        </Stack>
      </div>
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <div className="proj-detail-block">
        <Form
          onSubmit={onSubmit}
          initialValues={getInitialFormValues(props.formValues)}
          mutators={{
            setHeaderDocs: (args, state, utils) => {
              const field = state.fields['header.files'];
              field.change(args[0]);
            },
          }}
          validate={values => {
            const errors: any = {};
            if (!values?.name && !showProgressBar) {
              errors.name = t('nameRequired');
            }
            return errors;
          }}
          // validate={ validate }
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            props.submit(handleSubmit);
            props.setForm(form);

            let headerFiles = values.header?.files;
            return (
              <form onSubmit={handleSubmit} noValidate>
                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="header">
                      <Label>1. {t('mailHeader')}</Label>
                    </Link>
                  </div>
                  {state?.sequenceId && (
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="title"
                            component={TextFieldFF}
                            lable={t('title')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="sequenceId"
                            component={TextFieldFF}
                            lable={t('id')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>
                  )}

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="name"
                          component={TextFieldFF}
                          lable={t('name')}
                          autoComplete="off"
                        />
                      </div>
                    </Stack.Item>

                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div style={{ marginTop: 40 }} className={`${classNames.fullWidth}`}>
                        <Field name="isDefault">
                          {({ input, meta }) => (
                            <Checkbox
                              label={t('isDefault')}
                              disabled={false}
                              checked={values.isDefault ? true : false}
                              onChange={(event, value) => {
                                if (value) {
                                  input.onChange(value);
                                } else {
                                  input.onChange(false);
                                }
                              }}
                            />
                          )}
                        </Field>
                      </div>
                    </Stack.Item>

                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <div className={`${classNames.fullWidth}`}>
                      <Label> {t('header')}</Label>

                      <Field
                        name="mailHeader"
                        component={CKEditorWrapper}
                        disabled={false}
                      />
                    </div>
                  </Stack>
                  {rendercardFooter(form)}
                </div>

                <div className="document-pane-card">
                  <div className={'card-header'} style={{ marginBottom: 0 }}>
                    <Link href="#" id="request-tender">
                      <Label> 2. {t('requestToWriteInTender')}</Label>
                    </Link>
                  </div>

                  <div className='marginTop'>
                    <div style={{ padding: 0 }}>
                      <div className='ms-Grid-row'>
                        <Stack
                          horizontal
                          wrap
                          styles={stackStyles}
                          tokens={stackTokens}
                        >
                          <Stack.Item styles={stackItemStyles}>
                            <Label style={{ marginTop: 10 }}>2.1 {t('accept')}</Label>
                          </Stack.Item>
                          <div className={`${classNames.fullWidth}`}>
                            <Field
                              name='acceptTender'
                              component={CKEditorWrapper}
                              disabled={false}
                            />
                          </div>
                        </Stack>
                      </div>
                    </div>
                  </div>

                  <div className='marginTop'>
                    <div style={{ padding: 0 }}>
                      <div className='ms-Grid-row'>
                        <Stack
                          horizontal
                          wrap
                          styles={stackStyles}
                          tokens={stackTokens}
                        >
                          <Stack.Item styles={stackItemStyles}>
                            <Label style={{ marginTop: 10 }}>2.2 {t('download')}</Label>
                          </Stack.Item>
                          <div className={`${classNames.fullWidth}`}>
                            <Field
                              name='downloadTender'
                              component={CKEditorWrapper}
                              disabled={false}
                            />
                          </div>
                        </Stack>
                      </div>
                    </div>
                  </div>

                  <div className='marginTop'>
                    <div style={{ padding: 0 }}>
                      <div className='ms-Grid-row'>
                        <Stack
                          horizontal
                          wrap
                          styles={stackStyles}
                          tokens={stackTokens}
                        >
                          <Stack.Item styles={stackItemStyles}>
                            <Label style={{ marginTop: 10 }}>2.3 {t('subscribe')}</Label>
                          </Stack.Item>
                          <div className={`${classNames.fullWidth}`}>
                            <Field
                              name='subscribeTender'
                              component={CKEditorWrapper}
                              disabled={false}
                            />
                          </div>
                        </Stack>
                      </div>
                    </div>
                  </div>

                  {/*<Stack*/}
                  {/*  horizontal*/}
                  {/*  wrap*/}
                  {/*  styles={stackStyles}*/}
                  {/*  tokens={stackTokens}*/}
                  {/*>*/}
                  {/*  <div className={`${classNames.fullWidth}`}>*/}
                  {/*    <Field*/}
                  {/*      name="requestToWrittenInTender"*/}
                  {/*      component={CKEditorWrapper}*/}
                  {/*      disabled={false}*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*</Stack>*/}

                  {rendercardFooter(form)}
                </div>

                <div className="document-pane-card">
                  <div className={'card-header'} style={{ marginBottom: 20 }}>
                    <Link href="#" id="measuring-state-received">
                      <Label> 3. {t('measuringStateReceived')}</Label>
                    </Link>
                  </div>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <div className={`${classNames.fullWidth}`}>
                      <Field
                        name="measuringStateRecieved"
                        component={CKEditorWrapper}
                        disabled={false}
                      />
                    </div>
                  </Stack>
                  {rendercardFooter(form)}
                </div>

                <div className="document-pane-card">
                  <div className={'card-header'} style={{ marginBottom: 20 }}>
                    <div className={'card-header'} style={{ marginBottom: 20 }}>
                      <Link href="#" id="reminders">
                        <Label> 4. {t('reminders')}</Label>
                      </Link>
                    </div>
                    <Link href="#" id="reminder1">
                      <Label> {t('reminderOne')}</Label>
                    </Link>
                  </div>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >

                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <Label style={{ marginRight: 20 }}>{t('timeFrameTender')}</Label>
                      <div className={`${classNames.halfWidth}`}>
                        <Field
                          name="reminder1TimeFrameTender"
                          component={FixedDecimalNumberFormatField}
                          suffix={'%'}
                          disabled={false}
                        />
                      </div>
                    </Stack.Item>
                    <div className={`${classNames.fullWidth}`}>

                      <Field
                        name="reminder1"
                        component={CKEditorWrapper}
                        disabled={false}
                      />
                    </div>
                  </Stack>

                  <div className={'card-header'} style={{ marginBottom: 20 }}>
                    <Link href="#" id="reminder2">
                      <Label> {t('reminderTwo')}</Label>
                    </Link>
                  </div>
                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <Label style={{ marginRight: 20 }}> {t('timeFrameTender')}</Label>
                      <div className={`${classNames.halfWidth}`}>
                        <Field
                          name="reminder2TimeFrameTender"
                          component={FixedDecimalNumberFormatField}
                          suffix={'%'}
                          disabled={false}
                        />
                      </div>
                    </Stack.Item>
                    <div className={`${classNames.fullWidth}`}>

                      <Field
                        name="reminder2"
                        component={CKEditorWrapper}
                        disabled={false}
                      />
                    </div>
                  </Stack>

                  <div className={'card-header'} style={{ marginBottom: 20 }}>
                    <Link href="#" id="reminder2">
                      <Label> {t('reminderThree')}</Label>
                    </Link>
                  </div>
                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <Label style={{ marginRight: 20 }}> {t('timeFrameTender')}</Label>
                      <div className={`${classNames.halfWidth}`}>
                        <Field
                          name="reminder3TimeFrameTender"
                          component={FixedDecimalNumberFormatField}
                          suffix={'%'}
                        />
                      </div>
                    </Stack.Item>
                    <div className={`${classNames.fullWidth}`}>

                      <Field
                        name="reminder3"
                        component={CKEditorWrapper}
                        disabled={false}
                      />
                    </div>
                  </Stack>

                  <div className={'card-header'} style={{ marginBottom: 20 }}>
                    <Link href="#" id="reminder4">
                      <Label> {t('reminderFour')}</Label>
                    </Link>
                  </div>
                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <Label style={{ marginRight: 20 }}> {t('timeFrameTender')}</Label>
                      <div className={`${classNames.halfWidth}`}>
                        <Field
                          name="reminder4TimeFrameTender"
                          component={FixedDecimalNumberFormatField}
                          suffix={'%'}
                        />
                      </div>
                    </Stack.Item>
                    <div className={`${classNames.fullWidth}`}>

                      <Field
                        name="reminder4"
                        component={CKEditorWrapper}
                        disabled={false}
                      />
                    </div>
                  </Stack>
                  {rendercardFooter(form)}
                </div>

                <div className="document-pane-card">
                  <div className={'card-header'} style={{ marginBottom: 20 }}>
                    <Link href="#" id="tenderawarded">
                      <Label> 5. {t('tenderAwarded')}</Label>
                    </Link>
                  </div>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <div className={`${classNames.fullWidth}`}>
                      <Field
                        name="tenderWon"
                        component={CKEditorWrapper}
                        disabled={false}
                      />
                    </div>
                  </Stack>
                  <div className={'card-header'} style={{ marginBottom: 20 }}>
                    <Link href="#" id="tendernotawarded">
                      <Label> 6. {t('tenderNotAwarded')}</Label>
                    </Link>
                  </div>
                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <div className={`${classNames.fullWidth}`}>
                      <Field
                        name="tenderLost"
                        component={CKEditorWrapper}
                        disabled={false}
                      />
                    </div>
                  </Stack>
                  {rendercardFooter(form)}
                </div>

                <div className="document-pane-card">
                  <div className={'card-header'} style={{ marginBottom: 20 }}>
                    <Link href="#" id="outstanding-comments">
                      <Label> 7. {t('outStandingcomments')}</Label>
                    </Link>
                  </div>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <div className={`${classNames.fullWidth}`}>
                      <Field
                        name="outStandingComments"
                        component={CKEditorWrapper}
                        disabled={false}
                      />
                    </div>
                  </Stack>
                  {rendercardFooter(form)}
                </div>

                {props.formData.history && props.formData.history.createdDate && (
                  <div className="proj-detail-block" id="3">
                    <div className="document-pane-card">
                      <div className="marginTop marginBottom">
                        <Link href="#" id="history">
                          <Label>7. {t('history')} </Label>
                        </Link>

                        <HistoryBar
                          createdByUser={
                            props.formData.history.createdby
                              ? props.formData.history.createdby
                              : ''
                          }
                          updatedByUser={
                            props.formData.history.modifiedby
                              ? props.formData.history.modifiedby
                              : ''
                          }
                          createdDateTime={
                            props.formData.history.createdDate
                              ? props.formData.history.createdDate
                              : ''
                          }
                          updatedDateTime={
                            props.formData.history.modifiedDate
                              ? props.formData.history.modifiedDate
                              : ''
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* <pre>{ JSON.stringify(values, 0, 2) }</pre> */}
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};
export default DocumentPaneComponent;

import {
  DetailsListLayoutMode,
  DetailsRow,
  DetailsRowCheck,
  getTheme,
  IColumn,
  Icon,
  IDetailsFooterProps,
  IDetailsRowBaseProps,
  IDetailsRowCheckStyles,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../../theme';
import { POResourceColumn } from '../../../../shared/tableColumn/tableColumn';
import { RESOURCE_TYPE } from '../../../../types/purchaseOrder';

const theme = getTheme();

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const removeIcon: IIconProps = {
  iconName: 'Delete',
  styles: iconButtonStyles,
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const MCDataGrid = (props: {
  dataList: any[];
  title: string;
  link: string;
  id: string;
  columns: any[];
  isLoaded: boolean;
  isExpand: boolean;
  selection: any;
  setOpenPOResourceModal: () => void;
  resourceType: RESOURCE_TYPE
}) => {
  const { t } = useTranslation();
  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
      minHeight: (props.dataList && props.dataList.length > 1) ? 200 : 110,
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
  });

  useEffect(() => {
    changeColor();
  });

  const stackStyles: IStackStyles = { root: { padding: 0 } };
  const stackItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 40,
      alignItems: 'flex-start',
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 15,
    padding: 10,
  };

  const detailsRowCheckStyles: Partial<IDetailsRowCheckStyles> = { root: { visibility: 'hidden' } };

  const _onRenderCheckForFooterRow: IDetailsRowBaseProps['onRenderCheck'] = (props): JSX.Element => {
    return <DetailsRowCheck {...props} styles={detailsRowCheckStyles} selected={true}/>;
  };

  const _onRenderCheckForHeaderRow: IDetailsRowBaseProps['onRenderCheck'] = (props): JSX.Element => {
    return <DetailsRowCheck {...props} styles={detailsRowCheckStyles} selected={true}/>;
  };

  const _onRenderDetailsHeader = (detailsFooterProps: IDetailsFooterProps): JSX.Element => {
    return (
      <DetailsRow
        {...detailsFooterProps}
        columns={detailsFooterProps.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps.groupNestingDepth}
        selectionMode={SelectionMode.single}
        selection={detailsFooterProps.selection}
        onRenderItemColumn={_renderDetailsHeaderItemColumn}
        onRenderCheck={_onRenderCheckForHeaderRow}
      />
    );
  };

  const _renderDetailsHeaderItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] = (item, index, column) => {
    if (column && column.fieldName && (column.fieldName === 'pDocuments' || column.fieldName === 'cDocuments')) {
      return (
        <div style={{ width: 100 }}>
          <Label>&nbsp;</Label>
          {/* <Label>{t('documents')}</Label> */}
          <Icon
            iconName="WordDocument"
          />
        </div>
      );
    } else if (column && column.fieldName && (column.fieldName === 'pComments' || column.fieldName === 'cComments')) {
      return (
        <div className={'mcomments'} style={{ width: 400 }}>
          <Label>&nbsp;</Label>
          {/* <Label>{t('comments')}
                </Label> */}
          <Icon
            iconName="Comment"
          />
        </div>
      );
      return null;
    } else if (column && column.fieldName && (column.fieldName === 'borTitle')) {
      return (
        <div className={'costPerUnit'} style={{ width: 400 }}>
          <Label>&nbsp;</Label>
          <Label>{t('borTitle')}</Label>
        </div>
      );
      return null;
    } else if (column && column.fieldName && (column.fieldName === 'pTitle' || column.fieldName === 'cTitle')) {
      return (
        <div className={'costPerUnit'} style={{ width: 600 }}>
          <Label style={{ marginLeft: props.isExpand ? 120 : 40 }}>{t('reference')}</Label>
          <Label>{t('resourceTitle')}</Label>
        </div>
      );
      return null;
    } else if (column && column.fieldName && (column.fieldName === 'pQuantity' || column.fieldName === 'cQuantity')) {
      return (
        <div className={'totalCost1'} style={{ width: 200 }}>
          <Label>&nbsp;</Label>
          <Label>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('quantity')}
          </Label>
        </div>
      );
      return null;
    } else if (column && column.fieldName && (column.fieldName === 'pMou' || column.fieldName === 'cMou')) {
      return (
        <div className={'mmou'} style={{ width: 200 }}>
          <Label>&nbsp;</Label>
          <Label>
            {t('mou')}
          </Label>
        </div>
      );
      return null;
    } else if (column && column.fieldName && (column.fieldName === 'pUnitPrice' || column.fieldName === 'cUnitPrice')) {
      return (
        <div style={{ width: 100 }}>

          <Label style={{ marginLeft: props.isExpand ? 40 : 40 }}>{t('finance')}</Label>
          <Label style={{ marginLeft: (true) ? 1 : 1 }}>
            {t('unitPrice')}
          </Label>
        </div>
      );
      return null;
    } else if (column && column.fieldName && (column.fieldName === 'pTotalPrice' || column.fieldName === 'cTotalPrice')) {
      return (
        <div style={{ width: 100, textAlign: props.isExpand ? 'center' : 'left' }}>
          <Label>&nbsp;</Label>
          <Label>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('totalPrice')}
          </Label>
        </div>
      );
      return null;
    } else if (column && column.fieldName && (column.fieldName === 'requestedDate')) {
      return (
        <div style={{ width: 180, textAlign: props.isExpand ? 'center' : 'left' }}>
          <Label>&nbsp;</Label>
          <Label>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('requestedDeliveryDate')}
          </Label>
        </div>
      );
      return null;
    } else {
      return null;
    }

  };

  const changeColor = () => {
    let cquantityClass = document.getElementsByClassName('mcomments');
    for (let i = 0; i < cquantityClass.length; i++) {
      let parentElement = cquantityClass[i].parentElement;
      if (parentElement) {
        parentElement.style.borderRight = '1px solid rgb(243, 242, 241)';
      }
    }

    let totalCost1Class = document.getElementsByClassName('mmou');
    for (let i = 0; i < totalCost1Class.length; i++) {
      let parentElement = totalCost1Class[i].parentElement;
      if (parentElement) {
        parentElement.style.borderRight = '1px solid rgb(243, 242, 241)';
      }
    }

  };

  return (
    <div className={'document-pane-card purchase-order'}>
      <div className={'card-header'}>
        <div className="sub-header-title">
          <Stack
            horizontal
            horizontalAlign="space-between"
            styles={stackStyles}
            tokens={stackTokens}
          >
            <Stack.Item styles={stackItemStyles}>
              <Label
                id={props.link}
                style={{ marginTop: 10, fontSize: '1.1rem' }}
              >
                {props.id}. {props.title}
              </Label>
            </Stack.Item>

          </Stack>
        </div>
      </div>

      <div className={'card-body'}>
        <div className="proj-detail-block" id={props.id}>
          <div className="marginTop">
            <div style={{ padding: 12 }}>
              <div className="ms-Grid-row marginTop">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  <div className={classNames.wrapper}>
                    <div className={'scrollablePane'}>
                      <ShimmeredDetailsList
                        items={props.dataList}
                        columns={props.columns}
                        setKey="set"
                        enableShimmer={props.isLoaded}
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionPreservedOnEmptyClick={true}
                        checkboxVisibility={1}
                        onRenderItemColumn={(
                          item: any,
                          index?: number,
                          column?: IColumn,
                        ) =>
                          POResourceColumn(
                            props.resourceType,
                            item,
                            index,
                            column,
                          )
                        }
                        onRowDidMount={() => {
                          changeColor();
                        }}
                        selection={props.selection}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="Row checkbox"
                        onRenderDetailsHeader={(props) => _onRenderDetailsHeader(props!!)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'card-footer'}>
      </div>
    </div>
  );
};

export default MCDataGrid;

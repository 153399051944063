import * as React from 'react';
import WbsCreateProductDocumentPane from '../../wbsCreate/documentPane/WbsCreateProductDocumentPane';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';
import { useSelector } from 'react-redux';
import ProgressBar from '../../../shared/progressBar/progressBar';

const CreateProductDocumentPane = (props:{isAddNew:any}) => {
  const { t } = useTranslation();
  const showWbsProgressBar = useSelector((state: any) => state.wbsCreate.showWbsProgressBar);
  const getItems=()=>{
    return [ {
      key: 'save',
      text: t('save'),
      iconProps: { iconName: 'Save' },
      onClick: () => {
        messageService.sendMessage({ saveWbsProduct: true});
      },
      disabled: false,
    }];
  }

 return (
  <>
    <div style={{height:"100%",width:"100%",overflow:"hidden"}}>
      <div className={'wbs__command__bar'}>
        <CommandBar
          styles={{ root: { height: 26 } }}
          items={getItems()}
          ariaLabel='Use left and right arrow keys to navigate between commands'
        />
        <ProgressBar show={showWbsProgressBar}/>
      </div>
    <WbsCreateProductDocumentPane isNewProduct={props?.isAddNew}/>

    </div>
  </>
 );

};

export default CreateProductDocumentPane

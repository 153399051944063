import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomSortableTree from '../../../shared/treeStructureDropdown/customSortableTree';
import {
  ContextualMenu,
  FontWeights,
  getTheme,
  IconButton,
  IDragOptions,
  IIconProps,
  Label,
  mergeStyleSets,
  Modal,
  PrimaryButton,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../../theme';
import { useId } from '@uifabric/react-hooks';
import BreadcrumbBar from '../../../shared/breadcrumbBar/breadcrumbBar';
import { getBreadcrumbItemFromData } from '../../../shared/util';
import { messageService } from '../../../services/messageService';
import { ProjectBreakdown } from '../../../types/projectBreakdownStructure';


const TaxonomyField = (props: {
  treeData: any;
  onSelectItem: (id: string) => void;
  selectItemId: string | null;
  label?: string | null;
  treeLevel: any;
  onFilterTaxonomyNodeChanged: any;
  projectBreakdown?: ProjectBreakdown;
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const titleId = useId('title');
  const theme = getTheme();
  const dragOptions: IDragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
  };
  const cancelIcon: IIconProps = { iconName: 'Cancel' };
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
    header: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '2px 2px 2px 10px',
        backgroundColor: uPrinceTheme.palette.themePrimary,
        fontSize: 18,
        color: 'white',
      },
    ],
    footer: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '0px 24px 14px 24px',
        textAlign: 'end',
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 0px 24px',
      overflowY: 'hidden',
      paddingTop: 20,
      minWidth: screen.width > 1280 ? '40vw' : '50vw',
      minHeight: screen.width > 1280 ? '60vh' : '75vh',
      height: screen.width > 1280 ? '80vh' : '75vh',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
    subHeader: {
      flex: '1 1 auto',
      display: 'none',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      fontSize: 14,
      color: uPrinceTheme.palette.themePrimary,
      backgroundColor: '#FFF7F4',
      height: 40,
      marginLeft: 'auto',
      marginRight: '2px',
    },
  });
  const cancelIconButtonStyles = {
    root: {
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
      color: 'white',
    },
    rootHovered: { color: theme.palette.neutralDark },
  };
  const whiteIconButtonStyles = {
    root: { color: uPrinceTheme.palette.white },
    rootHovered: { color: theme.palette.neutralDark },
  };
  const cancelIconWhite: IIconProps = {
    iconName: 'Cancel',
    styles: whiteIconButtonStyles,
  };
  const onCreateNode = (newNode: any) => {
    messageService.sendMessage({ createNewNode: newNode });
  };

  const onAddNode = (selectedLevel: any) => {
    messageService.sendMessage({ selectedNodeLevel: selectedLevel });
  };


  const closeTaxonomyModal = () => {
    messageService.sendMessage({ closeTaxonomyModal: true });
  };


  return (
    <div className={''} style={{ paddingTop: 10 }}>

      <div className={'ms-Grid tree-view-field'}>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
            <Label>{props.label}</Label>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md11 ms-lg11">
            <BreadcrumbBar items={getBreadcrumbItemFromData(props.treeData, props.selectItemId)}/>

          </div>
          <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg1">
            <PrimaryButton
              text={t('help')}
              onClick={() => {
                setIsOpen(true);
              }}
            />
          </div>
        </div>
      </div>

      <Modal
        titleAriaId={titleId}
        isOpen={isOpen}
        onDismiss={() => {
          setIsOpen(false);
          closeTaxonomyModal();
        }}
        isBlocking={true}
        dragOptions={dragOptions}
      >
        <div className={contentStyles.header}>
          <span id={titleId}> {props.label} </span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              setIsOpen(false);
              closeTaxonomyModal();
            }}
          />
        </div>
        <div className={contentStyles.body}>
          <div>
            <div className="ms-Grid-row">
              <div className="cbcTaxonomy ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                <CustomSortableTree
                  label={''}
                  selectItemId={props.selectItemId}
                  treeData={props.treeData}
                  onSelectItem={(id: string) => {
                    props.onSelectItem(id);
                  }}
                  treeLevel={props.treeLevel}
                  onCreateNode={(newNode: any) => onCreateNode(newNode)}
                  onAddNode={(selectedLevel: any) => onAddNode(selectedLevel)}
                  onFilterTaxonomyNodeChanged={props.onFilterTaxonomyNodeChanged}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={cancelIconWhite}
            text={t('close')}
            style={{ marginTop: 10, marginBottom: 10, marginRight: 10 }}
            onClick={() => {
              setIsOpen(false);
              closeTaxonomyModal();
            }}
          />
        </div>
      </Modal>

    </div>
  );
};


export default TaxonomyField;

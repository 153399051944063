import React from 'react';
import { CommandBar } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';

const CommandBarLeft = (props: { disable: boolean }) => {
  const { t } = useTranslation();


  const getItems = () => {
    return [
      {
        key: 'new',
        text: t('new'),
        iconProps: { iconName: 'Add' },
        onClick: () => {
          messageService.sendMessage({ isNewRR: true });
        },
        disabled: props.disable,
      },
      // {
      //     key: 'Edit',
      //     text: t('Edit'),
      //     iconProps: { iconName: 'Edit' },
      //     onClick: () => {
      //          //messageService.sendMessage({ isNewRR: true });
      //     },
      //     disabled: props.disable
      // },
      // {
      //     key: 'Remove',
      //     text: t('Remove'),
      //     iconProps: { iconName: 'Delete' },
      //     onClick: () => {
      //          //messageService.sendMessage({ isNewRR: true });
      //     },
      //     disabled: props.disable
      // },
      // {
      //     key: 'Print',
      //     text: t('Print'),
      //     iconProps: { iconName: 'Print' },
      //     onClick: () => {
      //          //messageService.sendMessage({ isNewRR: true });
      //     },
      //     disabled: props.disable
      // },
    ];
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );
};

export default CommandBarLeft;

import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';

const CommandBarLeft = (props: {
  createNew: (create: boolean) => void,
  selectedItemIds: [] | string[],
  isDisableNewButton: boolean
}) => {
  const { t } = useTranslation();
  const newClick = () => {
    messageService.sendMessage({
      isNewInvoice: true,
    });
    props.createNew(true);
  };

  const getItems = () => {
    return [
      {
        key: 'new',
        text: t('new'),
        iconProps: { iconName: 'Add' },
        onClick: () => {
          newClick();
        },
        disabled: true,
        //disabled:props.isDisableNewButton
      },
    ];
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );
};

export default CommandBarLeft;

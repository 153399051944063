import React, { useEffect } from 'react';
import {
  DetailsListLayoutMode,
  FontIcon,
  IColumn,
  IStackStyles,
  IStackTokens,
  mergeStyles,
  mergeStyleSets,
  SelectionMode,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
} from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DragBox from '../../../shared/dnd/dragBox';
import { ProjectPlanResourceType } from '../../../types/myDayPlanning';


const iconClass = mergeStyles({
  fontSize: 14,
  height: 14,
  width: 14,
  margin: '0 5px',
});

const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 25vh)!important',
    position: 'relative',
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: {
    margin: 0,
  },
  row: {
    display: 'inline-block',
  },
  deepSkyBlue: [{ color: 'deepskyblue' }, iconClass],
  red: [{ color: 'red' }, iconClass],
});

const wrapStackTokens: IStackTokens = { childrenGap: 2 };

const stackStyles: IStackStyles = {
  root: {
    padding: 0
  },
};

const ToolList=()=>{
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    resourceList,
    isResourceDataLoaded,
  } = useSelector(
    (state: any) => state.projectDPlan
  );

  useEffect(()=>{
    // setTimeout(()=>{
    //   dispatch(saveProjectDayPlanningStateAttr('isResourceDataLoaded',true));
    // },1000);
    //
    // return () => {
    //   dispatch(saveProjectDayPlanningStateAttr('isResourceDataLoaded',false));
    // };
  },[]);

  const columns = [
    {
      key: 'column1',
      name: t('title'),
      fieldName: 'title',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    // {
    //   key: 'column2',
    //   name: t('quantity'),
    //   fieldName: 'availableQuantity',
    //   minWidth: 100,
    //   maxWidth: 200,
    //   isResizable: true,
    // },
  ];

  const resourceListColumn = (item: any, index?: number, column?: IColumn) => {
    if (column && column.fieldName && (column.fieldName === 'title')) {
      const fieldContent = item[column.fieldName];
      const toolName = item.resourceNumber&&item.title ? item.title.substring(item.resourceNumber.length+3).trim() : item.title;
      return fieldContent ? <span style={{ paddingLeft: 9 }} onClick={() => { }}  >
                <div key={Math.random()}>
                        <DragBox
                          // disabled={worker.absence ? true : !getProject()}
                          type={ProjectPlanResourceType.TOOL}
                          onClick={(item: any) => {}}
                          item={{ ...item}}
                          name={toolName}
                          icon={<FontIcon aria-label="Compass" iconName="Setting"  />}
                        />
                      </div>
            </span> : <span style={{ paddingLeft: 9 }}>-</span>;
    } else if (column && column.fieldName && (column.fieldName === 'availableQuantity')) {
      const fieldContent = item[column.fieldName];
      return fieldContent ? <span style={{ paddingLeft: 9 }} onClick={() => { }}>
        {/*<DragBox*/}
          {/*disabled={true}*/}
          {/*type={ProjectPlanResourceType.TOOL}*/}
          {/*onClick={(item: any) => {}}*/}
          {/*item={{ ...item}}*/}
          {/*name={`${item['consumeQuantity']?parseInt(item['consumeQuantity']):0}/${parseInt(fieldContent)}`}*/}
        {/*/>*/}
      </span> : <span style={{ paddingLeft: 9 }} onClick={() => { }}><DragBox
        disabled={true}
        type={ProjectPlanResourceType.TOOL}
        onClick={(item: any) => {}}
        item={{ ...item}}
        name={'-'}
      /></span>;
    }else if (column && column.fieldName) {
      const fieldContent = item[column.fieldName];
      return fieldContent ? <span style={{ paddingLeft: 9 }} onClick={() => { }}>{fieldContent}</span> : <span style={{ paddingLeft: 9 }}>-</span>;
    }

    return <span style={{ paddingLeft: 9 }}>-</span>;
  };

  return <div style={{height:'100%'}}>
    <ShimmeredDetailsList
      items={resourceList && resourceList?.tools?.length>0 ? resourceList.tools : []}
      columns={columns}
      setKey="set"
      enableShimmer={!isResourceDataLoaded}
      layoutMode={DetailsListLayoutMode.justified}
      selectionPreservedOnEmptyClick={true}
      checkboxVisibility={0}
      selectionMode={SelectionMode.none}
      ariaLabelForSelectionColumn="Toggle selection"
      ariaLabelForSelectAllCheckbox="Toggle selection for all items"
      checkButtonAriaLabel="Row checkbox"
      onRenderDetailsHeader={(headerProps, defaultRender) => {
        if (defaultRender !== undefined) {
          return (
            <Sticky
              stickyPosition={StickyPositionType.Header}
              isScrollSynced={true}
              stickyBackgroundColor="transparent"
            >
              <div>{defaultRender(headerProps)}</div>
            </Sticky>
          );
        } else {
          return <span></span>;
        }
      }}
      onRenderItemColumn={(
        item: any,
        index?: number,
        column?: IColumn,
      ) =>
        resourceListColumn(item, index, column)
      }
    />
  </div>
};

export default ToolList

import React from 'react';
import { connect } from 'react-redux';
import { DocumentPaneComponent } from './component';
import PriceList from '../priceList/priceList';
import { withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import _ from 'lodash';
import i18n from '../../../../i18n';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import history from '../../../history';
import { messageService } from '../../../services/messageService';
import { getProject, getSelectedCostList } from '../../../shared/util';
// import CbcModal from '../../projectMolecule/cbc/CbcModal';
import client from '../../../api';
import {
  Cost,
  CostFilter,
  ProgressStatement,
  ProgressStatementValidationMessage,
  psFormData,
  PSListPaneFilter,
} from '../../../types/progressStatement';
import {
  approvedPs,
  approvedPsCu,
  clearFormData as clearPsData,
  createPSResource,
  formattedCostList,
  readByPSId,
  saveFormData,
} from '../../../reducers/progressStatementReducer';
import moment from 'moment';
import {
  getAllProjectDropdowns,
  getProjectDefinition,
  getProjectHeaderDetails,
} from '../../../reducers/projectReducer';
import {
  getContractingUnits,
} from '../../../reducers/cuProjectReducer';
import CostModal from './costList/container';
// import PdfModal from '../pdf/pdfModal';
import { PENDING_DEVELOPMENT_STATUS_ID } from '../../../types/projectMolecule';
import { readCostListData } from './../../../reducers/progressStatementReducer';
import { IPersonaProps, ITag } from '@fluentui/react';
// import Axios from 'axios';

export interface State {
  formData: ProgressStatement;
  isDirty: boolean;
  redirect: boolean;
  loading: boolean;
  filter: PSListPaneFilter;
  isEdit: boolean;
  validationMessages: ProgressStatementValidationMessage;
  isCostModalOpen: boolean;
  showPrint: boolean;
  loadPrint: boolean;
  projectCostData: Cost[];
  isApproved: boolean;
  ledgerDropDowns: { key: string, text: string }[];
  contractingUnitsdropdown: { key: string, text: string }[];
  customerInformationdropdown: { key: string, text: string ,customerContactPersonId: string,customerContactPerson:string,
    customerContactPersonEmailId: string,
    customerContactPersonEmail: string }[];
  generalLedger: string | null;
  contractunit: string | null;
  customerName: string | null;
  customerContactPerson:string | null
  customerContactPersonEmail:string | null
  customerContactPersonId:string | null
  customerContactPersonEmailId:string | null,
  generalLedgerFromProject: string | null,
  contractunitFromProject: string | null,
  customerContactPersonEmailFrom: string | null,
  customerContactPersonFrom: string | null,
  customerInformationFromProject:string |null,
  resourceData: any;
  isNewPS: boolean;
  initialState: boolean
}

interface Props extends WithTranslation {
  formData: ProgressStatement;
  psDropDowns: any;
  projectHeader: [] | null;
  getProjectHeaderDetails: (id: string) => void;
  clearPsData: () => void;
  showProgressBar: boolean;
  autoSave: boolean;
  isEdit: boolean;
  projectId: string | null;
  isNew: boolean;
  uid: string | null;
  saveFormData: (formData: ProgressStatement) => any;
  readByPSId: any;
  createPSResource: (data: any) => any;
  isExpand: boolean;
  approvedPs: any;
  approvedPsCu: any;
  getAllProjectDropdowns: () => void;
  getContractingUnits:()=>void;
  ledgerDropDowns: { key: string; text: string }[];
  contractingUnitsdropdown: { key: string; text: string }[];
  customerInformationdropdown:{key:string;text:string,customerContactPersonId: string,customerContactPerson:string,
    customerContactPersonEmailId: string,
    customerContactPersonEmail: string }[];
  getProjectDefinition: (id: string) => void,
  selectedProject: any,
  readCostListData: any
}

class DocumentPane extends React.Component<Props, State> {
  subscription: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      isDirty: false,
      isEdit: false,
      redirect: false,
      loading: false,
      formData: psFormData,
      filter: new PSListPaneFilter(),
      validationMessages: {
        nameErrorMsg: '',
        typeErrorMsg: '',
        borErrorMsg: '',
        statusErrorMsg: '',
        ledgerValidationMsg: '',
      },
      isCostModalOpen: false,
      showPrint: false,
      loadPrint: false,
      projectCostData: [],
      isApproved: false,
      generalLedger: null,
      contractunit:null,
      ledgerDropDowns: [],
      contractingUnitsdropdown:[],
      customerInformationdropdown:[],
      resourceData: {},
      generalLedgerFromProject: null,
      contractunitFromProject:null,
      customerContactPersonEmailFrom:  null,
      customerContactPersonFrom: null,
      customerInformationFromProject:null,
      isNewPS: true,
      initialState: true,
      customerName :null,
      customerContactPerson:null, 
      customerContactPersonEmail:null, 
      customerContactPersonId:null, 
      customerContactPersonEmailId:null
      // contactPersonName:null,
      // contactPersonEmail: null
      
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.ledgerDropDowns !== this.props.ledgerDropDowns) {
      this.setState({ ledgerDropDowns: this.props.ledgerDropDowns });
    }
    if (prevProps.contractingUnitsdropdown !== this.props.contractingUnitsdropdown) {
      this.setState({ contractingUnitsdropdown: this.props.contractingUnitsdropdown });
    }
    if (prevProps.customerInformationdropdown !== this.props.customerInformationdropdown) {
      this.setState({ customerInformationdropdown: this.props.customerInformationdropdown });
    }
    if (prevProps.selectedProject !== this.props.selectedProject) {
      this.setState({ generalLedgerFromProject: this.props.selectedProject.generalLedgerId });
    }
    if (prevProps.selectedProject !== this.props.selectedProject) {
      this.setState({ contractunitFromProject: this.props.selectedProject.projectTeam.contractingUnitId });
      
    }
    
    if (this.state.initialState && _.isEmpty(this.props.formData)) {
      let formData:any = this.props.formData;
      formData.workPeriodFrom = this.getLastMonthStartOf();
      formData.workPeriodTo = this.getLastMonthEndOf();
      this.setState({customerName:formData.customerId})
      this.setState({ customerContactPerson: formData.customerContactPerson });
      this.setState({ customerContactPersonEmail: formData.customerContactPersonEmail});
      this.setState({ formData: this.props.formData, initialState: false });
    }

    if (prevProps.formData !== this.props.formData) {
      if (!this.state.isDirty) {
        if (!_.isEmpty(this.props.formData)) {
          if (this.props.formData?.projectSequenceCode) {
            this.props.getProjectDefinition(this.props.formData?.projectSequenceCode);
          }
          let formData = this.props.formData;
          let isNewPS = false;
          if (!formData.progressStatementId) {
            formData.workPeriodFrom = this.getLastMonthStartOf();
            formData.workPeriodTo = this.getLastMonthEndOf();
            isNewPS = true;
          }
          this.setState(
            {
              formData,
              projectCostData: this.props.formData.resources,
              generalLedger: this.props.formData?.generalLedgerId ? this.props.formData?.generalLedgerId : null,
              contractunit:this.props.formData?.contractingUnitId ? this.props.formData?.contractingUnitId : null,
              isNewPS: isNewPS,
              customerName:this.props.formData?.customerId? String(this.props.formData?.customerId) :null,
              customerContactPerson:this.props.formData?.customerContactPerson? String(this.props.formData?.customerContactPerson) :null,
              customerContactPersonEmail:this.props.formData?.customerContactPersonEmail? String(this.props.formData?.customerContactPersonEmail) :null
              //position:{lat:0.0,lon:0.0}, location:null
            },
            this.getPsData,
          );
        }
      } else {
        if (this.props.isNew && _.isEmpty(this.props.formData)) {
          let formData = this.state.formData;
          formData.workPeriodFrom = this.getLastMonthStartOf();
          formData.workPeriodTo = this.getLastMonthEndOf();
          this.setState({
            formData: formData,

          });
        }
      }
      if (this.props.formData.projectSequenceCode) {
        this.props.getProjectHeaderDetails(
          this.props.formData.projectSequenceCode,
        );
      }

      if (!getProject()) {
        this.setState({
          isApproved: true,
        });
      } else if (
        this.props.formData.projectStatusId != PENDING_DEVELOPMENT_STATUS_ID &&
        this.props.formData.progressStatementId
      ) {
        this.setState({
          isApproved: true,
        });
      } else {
        this.setState({
          isApproved: false,
        });
      }

      this.resetValidation();
    }

    if (prevState.formData !== this.state.formData) {
      if (!_.isEmpty(this.state.formData) && this.state.formData.id === null) {
        let formData = this.state.formData;
        formData.id = this.props.uid;
        //this.props.readMaterialsData(this.props.uid!!);
        this.setState(
          {
            formData,
          },
          this.getPsData,
        );
      }
    }

    if (
      prevProps.isNew != this.props.isNew ||
      prevProps.uid != this.props.uid
    ) {
      if (this.props.isNew) {
        let formData = this.state.formData;
        formData.id = this.props.uid;
        formData.workPeriodFrom = this.getLastMonthStartOf();
        formData.workPeriodTo = this.getLastMonthEndOf();
        this.setState({ formData });
      }
    } else {
    }
  }

  setGeneralLedger = (value: any) => {
    if (value) {
      this.setState({ generalLedger: value?.key });
    }
  };
  setContractunit = (value: any) => {
    if (value) {
      this.setState({ contractunit: value?.key });
    }
  };
  setCustomerName = (value: any) => {
    this.setState({ customerName: value?.key });
  };
  setcontactPersonName = (value: any) => {
    this.setState({ customerContactPerson: value?.customerContactPerson });
    this.setState({ customerContactPersonId: value?.customerContactPersonId });
  };
  setcontactPersonEmail = (value: any) => {
    this.setState({ customerContactPersonEmail: value?.customerContactPersonEmail });
    this.setState({ customerContactPersonEmailId: value?.customerContactPersonEmailId });
  };
  componentDidMount() {
    this.setState({ formData: psFormData });
    this.props.getAllProjectDropdowns();
    this.props.getContractingUnits()
    if (getProject()) {
      this.props.getProjectDefinition(getProject()!!);
    }
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewPs) {
          this.resetValidation();
          this.props.clearPsData();
          messageService.sendMessage({ resetCostList: true });
          //cancelAllRequest()
          this.clearPsData();
        }
        if (data.data.savePsFormData) {
          this.saveFormData();
        }
        if (data.data.psFilter) {
          this.setState({
            filter: data.data.psFilter,
          });
        }
        if (data.data.pdfGenerate) {
          if (getProject()) {
            this.setState({
              loadPrint: true,
            }, () => {
              this.saveFormData();
            });
          } else {
            this.setState({
              showPrint: true,
            });
          }

        }
        if (data.data.approvePs) {
          this.handleApprovePs();
        }
        if (data.data.approvePsCu) {
          this.handleApprovePsCu();
        }
        if (data.data.isReCalculate) {
          this.reCalCulateProjectCost();
        }
      }
    });
    if (getProject()) {
      let projectId = getProject();
      if (projectId) {
        this.props.getProjectHeaderDetails(projectId);
      }
    } else if (this.props.formData.projectSequenceCode) {
      this.props.getProjectHeaderDetails(
        this.props.formData.projectSequenceCode,
      );
    }
    if (this.props.formData.progressStatementId) {
      this.getPsData();
    }
    if (!this.props.formData.id) {
      if (this.props.isNew) {
        this.clearPsData();
      }
    }

    if (!this.state.formData.progressStatementId) {
      // if (
      //   !this.state.formData.workPeriodTo &&
      //   !this.state.formData.workPeriodFrom
      // ) {
      let formData = this.state.formData;
      formData.workPeriodFrom = this.getLastMonthStartOf();
      formData.workPeriodTo = this.getLastMonthEndOf();
      this.setState({
        formData,
      });
      // }
    }
  }

  approveButtonFlaskService = () => {
    
  }

  reCalCulateProjectCost = () => {
    this.props.readCostListData(new CostFilter()).then((data: any) => {
      if (data?.payload?.data?.statusCode === 200) {
        let projectCosts = formattedCostList(data?.payload?.data?.result);
        let res = [];
        if (_.isArray(projectCosts)) {
          res = projectCosts.filter(el => {
            return this.state.projectCostData.find(element => {
              return element.projectCostId === el.id;
            });
          });
          // res = projectCosts.filter(a => this.state.projectCostData.some(b => a.projectCostId === b.id));
          let formattedtRes = getSelectedCostList(res);
          let costData = [...new Set(formattedtRes)];
          this.setState({ isCostModalOpen: false, projectCostData: costData });
        }
        //return res;
      }
    });
  };

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  getLastMonthStartOf = () => {
    return moment().subtract(1, 'months').startOf('month').format().toString();
  };

  getLastMonthEndOf = () => {
    return moment().subtract(1, 'months').endOf('month').format().toString();
  };

  handleApprovePs = () => {
    if (this.state.formData.progressStatementId) {
      this.props.approvedPs(this.state.formData.id).then((res: any) => {
        this.props.readByPSId(this.state.formData.progressStatementId);
        this.handleApprovePsCu();
      });
    }
  };
  handleApprovePsCu = () => {
    if (this.state.formData.progressStatementId) {
      this.props.approvedPsCu(this.state.formData.id).then((res: any) => {
        this.props.readByPSId(this.state.formData.progressStatementId);
      });
    }
  };
  getPsData = () => {
    if (this.props.formData.progressStatementId) {
      messageService.sendMessage({ isLoadingPSData: true });
      Promise.all([
        //api call
      ]).then(() => {
        messageService.sendMessage({ isLoadingPSData: false });
      });
    }
  };

  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { psFilter: this.state.filter },
    });
  };

  openCostModal = () => {
    this.setState({ isCostModalOpen: !this.state.isCostModalOpen });
  };
  private getPersonByNameForQulity = async (name: string) => {
    let data: { key: string; text: string }[] = [];
    let response = await client.post(
      `CentralAddressBook/PersonFilterByName`,{fullName:name}
    );
    if (response && response.data && _.isArray(response.data.result)) {
      data=response.data.result;
    }
    console.log('daaaa',data)
    return data;
    
  };

  private onQualityFilterPersonChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return this.getPersonByNameForQulity(filterText);
    } else {
      return [];
    }
  };
  render() {
    // console.log('this.props.projectHeader', this.props.projectHeader)
    const { t } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <RouteLeavingGuard
          when={this.state.isDirty}
          navigate={(path) => this.handleDiscardChangesNavigate(path)}
          shouldBlockNavigation={(location) => {
            return true;
          }}
          handleConfirmation={(isConfirm: boolean) => {
            this.setState({
              isDirty: !isConfirm,
            });
          }}
        />
        <DocumentPaneComponent
          dropDownOptions={{
            types:
              this.props.psDropDowns && this.props.psDropDowns.type
                ? this.props.psDropDowns.type
                : [],
            status:
              this.props.psDropDowns && this.props.psDropDowns.status
                ? this.props.psDropDowns.status
                : [],
            customer:
              this.props.psDropDowns && this.props.psDropDowns.customer
                ? this.props.psDropDowns.customer
                : null,
            projectCompletionDate:
              this.props.psDropDowns &&
              this.props.psDropDowns.projectCompletionDate
                ? this.props.psDropDowns.projectCompletionDate
                : null,
            isApproved: this.state.isApproved,
          }}
          formData={this.state.formData}
          projectHeader={this.props.projectHeader}
          handleFormChange={(
            progressStatement: ProgressStatement,
            save: boolean,
          ) => {
            this.handleFormChange(progressStatement, save);
          }}
          saveFormData={() => this.saveFormData()}
          isEdit={this.props.isEdit}
          validationMessages={this.state.validationMessages}
          isLoading={this.props.showProgressBar}
          projectCostData={this.state.projectCostData}
          openCostModal={this.openCostModal}
          createPSResource={(data: any) =>
            this.props.createPSResource({
              psId: this.state.formData.id,
              resources: data.data,
              grandTotal: data.total,
              generalLedgerId: this.state.generalLedger ? this.state.generalLedger : this.state.generalLedgerFromProject,
              contractingUnitId: this.state.contractunit ? this.state.contractunit : this.state.contractunitFromProject,
              customerId:this.state.customerName ? this.state.customerName : '',
              customerContactPerson:this.state.customerContactPerson? this.state.customerContactPerson :null,
              customerContactPersonEmail:this.state.customerContactPersonEmail? this.state.customerContactPersonEmail : null,
              customerContactPersonId:this.state.customerContactPersonId? this.state.customerContactPersonId : null,
              customerContactPersonEmailId:this.state.customerContactPersonEmailId? this.state.customerContactPersonEmailId : null,
            }).then(() => {
              if (this.state.formData.progressStatementId) {
                this.props.readByPSId(this.state.formData.progressStatementId).then(() => {
                  this.props.readCostListData(new CostFilter());
                });
              }
            })
          }
          isExpand={this.props.isExpand}
          ledgerDropDowns={this.state.ledgerDropDowns}
          setGeneralLedger={(id) => this.setGeneralLedger(id)}
          setContractunit={(id) => this.setContractunit(id)}
          generalLedger={this.state.generalLedger ? this.state.generalLedger : this.state.generalLedgerFromProject}
          contractingUnitsdropdown={this.state.contractingUnitsdropdown}
          customerInformationdropdown={this.state.customerInformationdropdown}
          customerName={this.state.customerName ? this.state.customerName : this.state.customerName}
          contractunit={this.state.contractunit ? this.state.contractunit : this.state.contractunitFromProject}
          customerContactPerson={this.state.customerContactPerson ? this.state.customerContactPerson : this.state.customerContactPersonFrom}
          customerContactPersonEmail={this.state.customerContactPersonEmail ? this.state.customerContactPersonEmail : this.state.customerContactPersonEmailFrom}
          setResourceData={(data: any) => this.setResourceData(data)}
          setCustomerName={(id) => this.setCustomerName(id)}
          setcontactPersonName={(id) => this.setcontactPersonName(id)}
          setcontactPersonEmail={(id) => this.setcontactPersonEmail(id)}
          onQualityFilterPersonChanged={this.onQualityFilterPersonChanged}
        />
        <PriceList formData={this.state.formData} readOnly={false} isNewPS={this.state.isNewPS}/>
        
        <CostModal
          isOpen={this.state.isCostModalOpen}
          openModal={() => this.openCostModal()}
          onConfirmSelection={(list: Cost[]) =>
            this.onProjectCostSelected(list)
          }
          savedCostDataList = {this.state.projectCostData}
          psId={this.state.formData.progressStatementId}
          id={this.state.formData.id}
        />
       
        {/* <PdfModal
          hideModal={() => {
            this.setState({ showPrint: false });
          }}
          isModalOpen={this.state.showPrint}
          psData={this.state.formData}
          customer={
            this.props.psDropDowns && this.props.psDropDowns.customer
              ? this.props.psDropDowns.customer
              : null
          }
          project={this.props.projectHeader}
          customerName={this.state.customerName ? this.state.customerName : this.state.customerName}
          customerContactPersonEmail={this.state.customerContactPersonEmail ? this.state.customerContactPersonEmail : this.state.customerContactPersonEmailFrom}
          customerContactPerson={this.state.customerContactPerson ? this.state.customerContactPerson : this.state.customerContactPersonFrom}
        /> */}
      </div>
    );
  }

  onProjectCostSelected = (list: Cost[]) => {
    if (this.props.formData.resources && this.props.formData.resources.length && this.props.formData.resources.length > 0) {
      let costData = _.concat(this.props.formData.resources, list);
      costData = [...new Set(costData)];
      this.setState({ isCostModalOpen: false, projectCostData: costData });
    } else {
      let costData = _.concat(this.state.projectCostData, list);
      costData = [...new Set(costData)];
      this.setState({ isCostModalOpen: false, projectCostData: list });
    }

  };

  private handleFormChange = (formData: ProgressStatement, save: boolean) => {
    this.setState({
      isDirty: true,
    });
    this.setState({
      formData: formData,
    });
  };

  setResourceData = (data: any) => {
    this.setState({ resourceData: data });
  };
  
  private saveFormData = () => {
    if (this.validate()) {
      let formData = this.state.formData;
      formData.qualityProducerId=formData.qualityProducer
      formData.generalLedgerId = this.state.generalLedger ? this.state.generalLedger : this.state.generalLedgerFromProject;
      formData.contractingUnitId=this.state.contractunit ? this.state.contractunit : this.state.contractunitFromProject;
      formData.customerContactPerson = this.state.customerContactPerson ? this.state.customerContactPerson : null;
      formData.customerContactPersonEmail=this.state.customerContactPersonEmail ? this.state.customerContactPersonEmail : null;
      formData.customerId=this.state.customerName ? this.state.customerName : '',
      formData.customerContactPersonId=this.state.customerContactPersonId? this.state.customerContactPersonId : null,
      formData.customerContactPersonEmailId=this.state.customerContactPersonEmailId? this.state.customerContactPersonEmailId : null
      if (!formData.id) {
        formData.id = this.props.uid;
      }
      this.props.saveFormData(formData).then((res: any) => {
        let psId = (res.payload.data.result && res.payload.data.result.progressStatementId) ? res.payload.data.result.progressStatementId : null;
        formData.isSaved = true;
        this.setState(
          {
            formData,
            isNewPS: false,
          },
          () => {
            //
          },
        );
        if (psId) {
          //this.props.readByPSId(psId);
          this.props.createPSResource({
            psId: this.state.formData.id,
            resources: this.state.resourceData.data,
            grandTotal: this.state.resourceData.total ? this.state.resourceData.total : 0.00,
            generalLedgerId: this.state.generalLedger ? this.state.generalLedger : this.state.generalLedgerFromProject,
            contractingUnitId:this.state.contractunit ? this.state.contractunit : this.state.contractunitFromProject,
            customerId:this.state.customerName ? this.state.customerName : this.state.contractunitFromProject,
            customerContactPerson:this.state.customerContactPerson? this.state.customerContactPerson : this.state.customerContactPerson,
            customerContactPersonEmail:this.state.customerContactPersonEmail? this.state.customerContactPersonEmail : this.state.customerContactPersonEmail,
            customerContactPersonId:this.state.customerContactPersonId? this.state.customerContactPersonId : null,
            customerContactPersonEmailId:this.state.customerContactPersonEmailId? this.state.customerContactPersonEmailId : null
          }).then(() => {
            this.props.readByPSId(psId).then(() => {
              this.props.readCostListData(new CostFilter()).then(() => {
                setTimeout(() => {
                  if (this.state.loadPrint) {
                    this.setState({
                      showPrint: true,
                      loadPrint: false,
                    });
                  }
                }, 1000);
              });
            });
          });
        }
      });
      this.setState({
        isDirty: false,
      });
    }
  };

  private validate = (): boolean => {
    let validationMessages = this.state.validationMessages;
    let name = this.state.formData.name;
    let type = this.state.formData.projectTypeId;
    let status = this.state.formData.projectStatusId;
    let ledger = this.state.generalLedger ? this.state.generalLedger : this.state.generalLedgerFromProject;

    let isValid = true;
    if (_.isNil(name) || _.isEmpty(name)) {
      validationMessages.nameErrorMsg = i18n.t('nameRequired');
      isValid = false;
    } else {
      validationMessages.nameErrorMsg = '';
    }
    if (_.isNil(type) || _.isEmpty(type)) {
      validationMessages.typeErrorMsg = i18n.t('typeRequired');
      isValid = false;
    } else {
      validationMessages.typeErrorMsg = '';
    }
    if (_.isNil(status) || _.isEmpty(status)) {
      validationMessages.statusErrorMsg = i18n.t('statusRequired');
      isValid = false;
    } else {
      validationMessages.statusErrorMsg = '';
    }
    if (_.isNil(ledger) || _.isEmpty(ledger)) {
      validationMessages.ledgerValidationMsg = i18n.t('generalLedgerRequired');
      isValid = false;
    } else {
      validationMessages.ledgerValidationMsg = '';
    }

    this.setState({ validationMessages: validationMessages });
    return isValid;
  };

  private clearPsData = () => {
    let formData: ProgressStatement = {
      id: this.props.uid ? this.props.uid : null,
      progressStatementId: null,
      title: null,
      headerTitle: null,
      name: null,
      projectTypeId: null,
      projectType: null,
      projectStatusId: null,
      projectStatus: null,
      projectCostId: null,
      projectCompletionDate: null,
      workPeriodFrom: null,
      workPeriodTo: null,
      purchaseOrderNumber: null,
      projectSequenceCode: null,
      resources: [],
      historyLog: null,
      isSaved: false,
      date: null,
      totalAmount: null,
      isProjectFinished: false,
      generalLedgerId: null,
      contractingUnitId:null,
      invoiceComment: null,
      projectScopeStatusId: null,
      projectScope: null,
      customerName:null,
      customerContactPerson:null,
      customerContactPersonEmail:null,
      customerId:null,
      customerContactPersonId:null,
      customerContactPersonEmailId:null,
      qualityProducer:null,
      qualityProducerId:null
    };
    this.setState({
      formData,
      isNewPS: true,
    });
  };

  private resetValidation = () => {
    let validationMessages = this.state.validationMessages;
    validationMessages.typeErrorMsg = '';
    validationMessages.statusErrorMsg = '';
    validationMessages.nameErrorMsg = '';

    this.setState({
      validationMessages: validationMessages,
    });
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    psDropDowns: state.ps.psDropDowns,
    isEdit: state.ps.isEdit,
    isNew: state.ps.isNew,
    uid: state.ps.uid,
    projectHeader: state.project.projectHeader,
    showProgressBar: state.ps.showProgressBar,
    ledgerDropDowns: state.project.ledgerDropDowns,
    selectedProject: state.project.selectedProject,
    contractingUnitsdropdown:state.cuProject.contractingUnitsdropdown,
    customerInformationdropdown:state.project.customerteamlist
  };
};

const mapDispatchToProps = {
  saveFormData,
  readByPSId,
  clearPsData,
  getProjectHeaderDetails,
  createPSResource,
  approvedPs,
  approvedPsCu,
  getAllProjectDropdowns,
  getContractingUnits,
  getProjectDefinition,
  readCostListData,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DocumentPane)),
);

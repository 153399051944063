import { BrandVariants, createDarkTheme, createLightTheme } from '@fluentui/react-components';
import ENV from './env.json';

const theme = localStorage.getItem('themeColor');
const themeCol = (theme) ? JSON.parse(theme) : null;

const uPrinceFluentTheme: BrandVariants = {
  10: `${(themeCol) ? themeCol.black : '#000000'}`,
  20: `${(themeCol) ? themeCol.neutralDark : '#201f1e'}`,
  30: `${(themeCol) ? themeCol.neutralPrimary : '#323130'}`,
  40: `${(themeCol) ? themeCol.neutralPrimaryAlt : '#3b3a39'}`,
  50: `${(themeCol) ? themeCol.themeDarker : '#885e25'}`,
  60: `${(themeCol) ? themeCol.themeDark : '#b88032'}`,
  70: `${(themeCol) ? themeCol.themeDarkAlt : '#da983b'}`,
  80: `${(themeCol) ? themeCol.themePrimary : ENV.PRIMARY_COLOR}`,  //Primary
  90: `${(themeCol) ? themeCol.themeTertiary : '#f7ca8b'}`,
  100: `${(themeCol) ? themeCol.themeLight : ENV.PRIMARY_COLOR}`, //Dark primary
  110: `${(themeCol) ? themeCol.themeLighter : '#fdf1df'}`,
  120: `${(themeCol) ? themeCol.neutralQuaternaryAlt : '#e1dfdd'}`,
  130: `${(themeCol) ? themeCol.neutralLighter : '#f3f2f1'}`,
  140: `${(themeCol) ? themeCol.themeLighterAlt : '#fefbf7'}`,
  150: `${(themeCol) ? themeCol.neutralLighterAlt : '#faf9f8'}`,
  160: `${(themeCol) ? themeCol.white : '#ffffff'}`,
};

export const uPrinceFluentLightTheme = createLightTheme(uPrinceFluentTheme);
export const uPrinceFluentDarkTheme = createDarkTheme(uPrinceFluentTheme);
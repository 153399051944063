import * as React from 'react';
import {
  DetailsList,
  DetailsRow,
  DirectionalHint,
  IColumn,
  IDetailsListProps,
  IDetailsRowStyles,
  Label,
  Link as UiLink,
  mergeStyles,
  mergeStyleSets,
  Selection,
  SelectionMode,
  Stack,
  Sticky,
  StickyPositionType,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { Link } from 'react-router-dom';
import { Depths } from '@fluentui/theme';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CustomShimmer } from './customShimmer';
import _ from 'lodash';

const classNames = mergeStyleSets({
  wrapper: {
    // width:"700px!important",
    height: '100% !important',
    position: 'relative',
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: {
    margin: 0,
  },
  row: {
    display: 'inline-block',
  },
});

const contentStyle = mergeStyles({
  height: 'calc(100vh - 200px)!important',
  display: 'flex',
  flexDirection: 'column',
  selectors: {
    '@media(max-width: 1100px)': {
      width: '97.4%',
      height: 'calc(100vh)',
      position: 'absolute',
    },
  },
});

const listStyle = mergeStyles({
  flex: '1 1 auto',
  width: '100%',
  height: 'auto',
  overflowX: 'hidden',
  overflowY: 'auto',
  paddingBottom: '30px',
  marginLeft: '0',
});

export interface TimeClockListItem {
  value: string;
  startDateTime: string;
  elapedTime: string;
  activityType: string;
}

export interface State {
  items: TimeClockListItem[];
  selectionMode?: SelectionMode;
  redirect: boolean;
  selectionDetails: any;
  selectedTimeClockId: any;
  selectedTimeClock: any;
  showMsg: boolean;
  loadMsg: any;
  divClass: string;
  activeClass: boolean;
  currentActiveSection: any;
  company: any;
  date: any;
  endDateTime: any;
  person: any;
  totalTime: any;
  timeRegistrations: any;
  isDetailsLoaded?: boolean;
}

interface Props extends WithTranslation {
  selectedTimeClock: any;
  selectedTimeClockId: any;
  handleTimeClockLoad: any;
  detailsMsg: any;
  detailsMsgType: any;
  approvedMsg: any;
  approvedMsgType: any;
  isDetailsLoaded?: boolean;
}

class TimeClockShiftDetailsList extends React.Component<Props, State> {
  private _selection: Selection;
  private _allItems: TimeClockListItem[];
  private _columns: IColumn[];

  constructor(props: Props) {
    super(props);
    this._selection = new Selection({
      onSelectionChanged: () => {

      },
    });

    const { t } = this.props;
    this._allItems = [];
    for (let i = 0; i < 9; i++) {
      this._allItems.push({
        value: 'Item ' + i,
        startDateTime: 'time ' + i,
        elapedTime: 'time ' + i,
        activityType: 'activityType ' + i,
      });
    }

    this._columns = [
      {
        key: 'column1',
        name: t('time'),
        fieldName: 'startDateTime',
        minWidth: 200,
        maxWidth: 0,
        isResizable: true,
      },
      {
        key: 'column2',
        name: t('activityType'),
        fieldName: 'activityType',
        minWidth: 180,
        maxWidth: 0,
        isResizable: true,
      },
      {
        key: 'column3',
        name: t('elapsedTime'),
        fieldName: 'elapedTime',
        minWidth: 180,
        maxWidth: 0,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
      },
    ];

    this.state = {
      selectionMode: SelectionMode.single,
      showMsg: false,
      items: this._allItems,
      isDetailsLoaded: false,
      redirect: false,
      selectedTimeClock: {},
      selectionDetails: {},
      selectedTimeClockId: null,
      loadMsg: '',
      divClass: 'bookmark-panel parent',
      activeClass: false,
      currentActiveSection: 1,
      company: '',
      date: '',
      endDateTime: '...',
      person: '',
      totalTime: '',
      timeRegistrations: [
        { startDateTime: '', elapedTime: '', activityType: '' },
      ],
    };
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    const id = nextProps.selectedTimeClockId;
    const prevId = prevState.selectedTimeClockId;
    const showMsg = prevState.approvedMsg ? true : false;
    if (id && prevId != id) {
      nextProps.handleTimeClockLoad(id);
    }

    return {
      selectedTimeClockId: nextProps.selectedTimeClockId,
      selectedTimeClock: nextProps.selectedTimeClock,
      company: nextProps.selectedTimeClock
        ? nextProps.selectedTimeClock.company
        : '',
      date: nextProps.selectedTimeClock.date
        ? nextProps.selectedTimeClock.date
        : '',
      endDateTime: nextProps.selectedTimeClock.endDateTime
        ? nextProps.selectedTimeClock.endDateTime
        : '...',
      person: nextProps.selectedTimeClock.person
        ? nextProps.selectedTimeClock.person
        : '',
      timeRegistrations: nextProps.selectedTimeClock.person
        ? nextProps.selectedTimeClock.timeRegistrations
        : [],
      totalTime: nextProps.selectedTimeClock.person
        ? nextProps.selectedTimeClock.totalTime
        : '',
      showMsg: showMsg,
      isDetailsLoaded: nextProps.isDetailsLoaded,
    };
  }

  private handleScroll = (e: any): void => {
    let element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {

    }
    this.setState({
      activeClass: false,
    });

    let h2Headings = document.getElementsByClassName('proj-detail-block');
    let item = h2Headings[0];
    let tt = 0;

    for (let i = 0; i < h2Headings.length; i++) {
      if (h2Headings[i].getBoundingClientRect().top <= 200) {
        if (tt <= h2Headings[i].getBoundingClientRect().top) {
          tt = h2Headings[i].getBoundingClientRect().top;
          item = h2Headings[i];

          var c = item.id;
          this.setState({ currentActiveSection: c });
        }
      }
    }

    if (item.getAttribute('id')) {
      this.setState({ activeClass: true });
    }
  };

  renderShiftDetails = () => {
    const { t } = this.props;
    if (this.state.selectedTimeClockId && this.state.date) {
      return (
        <div>
          <Stack
            className={contentStyle}
            horizontalAlign="start"
            verticalAlign="start"
            padding={0}
            gap={0}
            styles={{
              root: {
                width: '100%',
                height: '100%',
              },
            }}
          >
            <Stack
              className={listStyle}
              styles={{
                root: {
                  width: '100%',
                  height: 'auto',
                },
              }}
              verticalFill
              gap={50}
              onScroll={this.handleScroll}
            >
              <div
                className="proj-detail-block "
                id="1"
              >

                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <UiLink href="#shift-header" id="shift-header" style={{ marginBottom: 30 }}>
                      <Label> {'1.'}
                        {' ' + this.state.date}
                        {' - ' + this.state.endDateTime + ' - '}
                        {' ' + !_.isEmpty(this.state.person) && !_.isEmpty(this.state.person.person) ? this.state.person.person.fullName!! : ' - ' +
                          ' '}
                        {(this.state.person.company) ? ' - ' + this.state.person.company.name : ''} </Label>
                    </UiLink>
                  </div>
                </div>

                <div
                  style={{ marginTop: 40 }}
                  className="proj-detail-block"
                  id="2"
                >
                  <div className="document-pane-card">
                    <div className={'card-header'}>
                      <UiLink href="#time-registration" id="time-registration">
                        <Label> {'2.'} {t('timeRegistration')} </Label>
                      </UiLink>
                    </div>

                    <div className={'card-body'}>
                      <div style={{ marginTop: 5 }}>
                        <div>
                          <div style={{ width: '100%' }} className={`wrapper-holder ${classNames.wrapper}`}>
                            {/*<ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>*/}
                            <DetailsList
                              items={this.state.timeRegistrations}
                              columns={this._columns}
                              setKey="set"
                              compact={false}
                              layoutMode={1}
                              selection={this._selection}
                              selectionPreservedOnEmptyClick={true}
                              checkboxVisibility={2}
                              onRenderRow={this._onRenderRow}
                              onRenderDetailsHeader={(headerProps, defaultRender) => {
                                if (defaultRender !== undefined) {
                                  return (
                                    <Sticky
                                      stickyPosition={StickyPositionType.Header}
                                      isScrollSynced={true}
                                      stickyBackgroundColor="transparent"
                                    >
                                      <div>{defaultRender(headerProps)}</div>
                                    </Sticky>
                                  );
                                } else {
                                  return <span></span>;
                                }
                              }}
                            />
                            <div style={{ marginTop: 20, marginBottom: 30 }}>
                              <UiLink href="#/project" id="group-id-1">
                                <Label>
                                  {t('totalTime')}: {this.state.totalTime}
                                </Label>
                              </UiLink>
                            </div>
                            {/*</ScrollablePane>*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </Stack>
            {this.renderBookmarkPane()}
          </Stack>
        </div>
      );
    } else {
      return (
        <div></div>
      );

    }
  };

  renderBookmarkPane = () => {
    const { t } = this.props;
    return (
      <div>
        <Stack
          className={this.state.divClass}
          verticalFill
          gap={30}
          styles={{
            root: {
              selectors: {
                '@media(max-width: 1440px)': {
                  boxShadow: Depths.depth8,
                },
              },
            },
          }}
        >
          <i
            title="Close"
            className="ms-Icon ms-Icon--ChromeClose"
            aria-hidden="true"
            onClick={this._toggleBookmarkOut}
          ></i>
          <Text className="title">{t('bookmark')}</Text>
          <div className="inner-container">
            <Label className={`${this.state.currentActiveSection == '1' ? 'selected' : ''}`}>
              <UiLink href="#shift-header">
                1. {t('shift')}
              </UiLink>
            </Label>
            <Label className={`${this.state.currentActiveSection == '2' ? 'selected' : ''}`}>
              <UiLink href="#time-registration">
                2. {t('timeRegistration')}
              </UiLink>
            </Label>
          </div>
        </Stack>

        <Stack
          className="bookmark-panel-responsive"
          verticalFill
          onClick={this._toggleBookmark}
          gap={30}
          styles={{
            root: {
              boxShadow: Depths.depth8,
            },
          }}
        >
          <TooltipHost
            content={t('bookmark')}
            id="bmark"
            calloutProps={{ gapSpace: 0 }}
            directionalHint={DirectionalHint.rightCenter}
          >
            <i
              className="ms-Icon ms-Icon--SearchBookmark"
              aria-labelledby="bmark"
              aria-hidden="true"
            ></i>
          </TooltipHost>
        </Stack>
      </div>
    );
  };

  renderShimmer = () => {
    return (
      <div>
        <Stack
          className={contentStyle}
          horizontalAlign="start"
          verticalAlign="start"
          padding={0}
          gap={0}
          styles={{
            root: {
              width: '100%',
              height: '100%',
            },
          }}
        >
          <Stack
            className={listStyle}
            styles={{
              root: {
                width: '100%',
                height: 'auto',
              },
            }}
            verticalFill
            gap={50}
            horizontalAlign="start"
            verticalAlign="start"
          >
            <div
              className="proj-detail-block"
              style={{ paddingTop: 24 }}
            >
              <CustomShimmer/>
            </div>

          </Stack>
          {this.renderBookmarkPane()}
        </Stack>
      </div>
    );
  };

  render() {
    if (this.state.isDetailsLoaded) {
      return <div>{this.renderShiftDetails()}</div>;
    } else {
      return <div>{this.renderShimmer()}</div>;
    }
  }

  private _onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      return (
        <Link
          style={{ textDecoration: 'none' }}
          to={'#'}
        >
          <DetailsRow {...props} styles={customStyles}/>
        </Link>
      );
    }
    return null;
  };

  private _toggleBookmark = (): void => {
    this.setState({ divClass: 'bookmark-panel parent panel-on' });
  };

  private _toggleBookmarkOut = (): void => {
    this.setState({ divClass: 'bookmark-panel parent' });
  };
}

export default withTranslation()(TimeClockShiftDetailsList);

import {
  Checkbox,
  DetailsListLayoutMode,
  DetailsRow,
  Dropdown,
  Fabric,
  IColumn,
  IDetailsFooterProps,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
  Text,
  TextField,
} from '@fluentui/react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../../../../i18n';
import { messageService } from '../../../../../services/messageService';
import { BorListPaneItem, ResourceListPaneItem, ResourceTypesDropdown } from '../../../../../types/billOfResources';
import { PoResourceFilterItem } from '../../../../../types/purchaseOrder';

export const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(72vh)!important',
    position: 'relative',
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: { margin: 0 },
  row: { display: 'inline-block' },
});

const ResourceListComponent = (props: {
  filter: PoResourceFilterItem;
  selection: any;
  columns: IColumn[];
  resourceListPaneItem: ResourceListPaneItem[];
  handleListPaneItemClick: (id: string) => void;
  handleFilterChange: (filter: PoResourceFilterItem) => void;
  isDataLoaded?: boolean;
  loadMsg: string;
  resetSelection: () => void;
  dropDownOptions: any;
}) => {
  const reloadListPane = (value: any, isTextField: boolean) => {
    if (isTextField) {
      if ((value && value.length >= 3) || value === null) {
        messageService.sendMessage({
          reloadResourceListPaneItem: true, // use on listPane/container
        });
      }
    } else {
      messageService.sendMessage({
        reloadResourceListPaneItem: true, // use on listPane/container
      });
    }
  };

  const _onRenderDetailsFooter = (
    detailsFooterProps: IDetailsFooterProps | undefined,
  ) => {
    return (
      <DetailsRow
        className="footer-as-filter"
        {...detailsFooterProps}
        columns={detailsFooterProps?.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps?.groupNestingDepth}
        selection={detailsFooterProps?.selection}
        onRenderItemColumn={_renderFilterItemColumn}
      />
    );
  };

  const _renderFilterItemColumn = (
    item?: BorListPaneItem,
    index?: number,
    column?: IColumn,
  ) => {
    switch (column?.key) {
      case 'column1': {
        return (
          <div>
            <TextField
              value={
                props.filter.borTitle ? props.filter.borTitle : ''
              }
              onChange={(event, value) => {
                if (value) {
                  props.filter.borTitle = value;
                } else {
                  props.filter.borTitle = null;
                }
                props.handleFilterChange(props.filter);
                reloadListPane(value, true);
              }}
            />
          </div>
        );
        break;
      }
      case 'column2': {
        return (
          <div>
            <Dropdown
              options={ResourceTypesDropdown}
              selectedKey={
                props.filter.resourceTypeId ? props.filter.resourceTypeId : '0'
              }
              onChange={(event, value) => {
                if (value) {
                  props.filter.resourceTypeId =
                    value.key != 0 ? value.key.toString() : null;
                } else {
                  props.filter.resourceTypeId = null;
                }
                props.handleFilterChange(props.filter);
                reloadListPane(value, false);
              }}
            />
          </div>
        );
        break;
      }
      case 'column3': {
        return (
          <div>
            <TextField
              value={
                props.filter.resourceFamily ? props.filter.resourceFamily : ''
              }
              onChange={(event, value) => {
                if (value) {
                  props.filter.resourceFamily = value;
                } else {
                  props.filter.resourceFamily = null;
                }
                props.handleFilterChange(props.filter);
                reloadListPane(value, true);
              }}
            />
          </div>
        );
        break;
      }
      case 'column4': {
        return (
          <div>
            <TextField
              value={
                props.filter.resourceTitle ? props.filter.resourceTitle : ''
              }
              onChange={(event, value) => {
                if (value) {
                  props.filter.resourceTitle = value;
                } else {
                  props.filter.resourceTitle = null;
                }
                props.handleFilterChange(props.filter);
                reloadListPane(value, true);
              }}
            />
          </div>
        );
        break;
      }
      case 'column5': {
        return (
          <div>
            <TextField
              value={
                props.filter.pbsTitle ? props.filter.pbsTitle : ''
              }
              onChange={(event, value) => {
                if (value) {
                  props.filter.pbsTitle = value;
                } else {
                  props.filter.pbsTitle = null;
                }
                props.handleFilterChange(props.filter);
                reloadListPane(value, true);
              }}
            />
          </div>
        );
        break;
      }
      default: {
        break;
      }
    }
  };

  const displayMessage = () => {
    return (
      <div style={{ paddingTop: 36, paddingLeft: 20 }}>
        <Text>{i18n.t(props.loadMsg)}</Text>
      </div>
    );
  };

  const onRenderCheckbox = (props: any) => {
    return (
      <div style={{ pointerEvents: 'none' }}>
        <Checkbox checked={props.checked} checkmarkIconProps={{ iconName: 'Add' }}/>
      </div>
    );
  };

  return (
    <Fabric>
      <div className={`wrapper-holder ${classNames.wrapper}`}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <ShimmeredDetailsList
            items={props.resourceListPaneItem}
            columns={props.columns}
            setKey="set"
            enableShimmer={!props.isDataLoaded}
            layoutMode={DetailsListLayoutMode.justified}
            selectionPreservedOnEmptyClick={true}
            checkboxVisibility={1}
            onRenderDetailsFooter={_onRenderDetailsFooter}
            selection={props.selection}
            selectionMode={SelectionMode.multiple}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            onRenderDetailsHeader={(headerProps, defaultRender) => {
              if (defaultRender !== undefined) {
                return (
                  <Sticky
                    stickyPosition={StickyPositionType.Header}
                    isScrollSynced={true}
                    stickyBackgroundColor="transparent"
                  >
                    <div>{defaultRender(headerProps)}</div>
                  </Sticky>
                );
              }
              return <span></span>;
            }}
            onRenderCheckbox={onRenderCheckbox}
          />
          {props.isDataLoaded && displayMessage()}
        </ScrollablePane>
      </div>
    </Fabric>
  );
};

const mapStateToProps = (state: any) => {
  return {
    toleranceStatesFilter: state.billOfResource.toleranceStatesFilter,
    productStatesFilter: state.billOfResource.productStatesFilter,
    itemTypesFilter: state.billOfResource.itemTypesFilter,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResourceListComponent);

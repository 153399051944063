import React, { Component } from 'react';
import { connect } from 'react-redux';
import Brand from '../../shared/brand/brand';
import ProjectBar from '../../shared/projectBar/projectBar';
import NavBar from '../../shared/navBar/navBar';
import ProjectNavBar from '../../shared/navBar/projectNavBar';
import BillOfResourcesMainLayout from './billOfResourcesMainLayout';
import { getContractingUnit, getProject } from '../../shared/util';

interface Props {
  currentUser: any;
}

interface State {
  isContractUnit: boolean;
}

export class BillOfResources extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isContractUnit: getContractingUnit() ? true : false };
  }

  render() {
    return (
      <div style={{ height: '100%' }}>
        <Brand/>
        <ProjectBar
          contractingUnit={
            this.props.currentUser && this.props.currentUser.contractingUnits[0]
              ? this.props.currentUser.contractingUnits[0]
              : null
          }
        />
        {this.state.isContractUnit &&
        <ProjectNavBar selectedKey={`/CU/${getContractingUnit()}/project/${getProject()}/bill-Of-resources`}
                       currentUser={this.props.currentUser}/>}
        {!this.state.isContractUnit && (
          <NavBar
            selectedKey={4}
            contractingUnit={
              this.props.currentUser &&
              this.props.currentUser.contractingUnits[0]
                ? this.props.currentUser.contractingUnits[0]
                : null
            }
            currentUser={this.props.currentUser}
          />
        )}
        <BillOfResourcesMainLayout/>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BillOfResources);

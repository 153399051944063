import React from 'react';
import {
  ChoiceGroup,
  DirectionalHint,
  IBasePickerSuggestionsProps,
  IChoiceGroupOption,
  IIconProps,
  IPersonaProps,
  IStackStyles,
  IStackTokens,
  ITag,
  Label,
  Link,
  Stack,
  TagPicker,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { getTheme, mergeStyles, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { Depths } from '@fluentui/theme';
import { TextField } from '@fluentui/react/lib/TextField';
import { withRouter } from 'react-router';
import { Dropdown, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import _ from 'lodash';
import CustomDatePicker from '../../shared/customDatePicker/customDatePicker';
import { WithTranslation } from 'react-i18next';
import HistoryBar from '../../shared/historyBar/historyBar';
import { CustomShimmer } from '../cuProjects/customShimmer';
import { formatDateTimeToISOString } from '../../shared/util';
import client from '../../api';
import { AddressBookItem } from '../../types/addressBookItem';
import i18n from '../../../i18n';
import { uPrinceTheme } from '../../../theme';
import DataGrid from '../../shared/dataGrid/component';
import { Selection } from '@fluentui/react/lib/DetailsList';
import { ActionButtonState } from '../../types/corporateProductCatalogue';
import TeamModal, { TeamMember } from './teams/modal/component';
import AddressLocationComponent from '../../shared/location/address/component';
import { LocationAddress, Position } from '../../types/projectDefinition';
import MapModal from './mapModal/component';
import { messageService } from '../../services/messageService';
import DefaultNumberFormat from '../../shared/fluentFinalForm/defaultNumberFormat';

const theme = getTheme();

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: '100%' },
};

const contentStyle = mergeStyles({
  height: 'calc(100vh - 200px)!important',
  display: 'flex',
  flexDirection: 'column',
  selectors: {
    '@media(max-width: 1100px)': {
      width: '97.4%',
      height: 'calc(100vh)',
      position: 'absolute',
    },
  },
});

const listStyle = mergeStyles({
  flex: '1 1 auto',
  width: '100%',
  height: 'auto',
  overflowX: 'hidden',
  overflowY: 'auto',
  paddingBottom: '30px',
  marginLeft: '0',
});

const classNames = mergeStyleSets({
  fullWidth: {
    width: '100%',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  bigScreenTree: {
    marginBottom: 30,
    height: '30vh',
  },
  smallScreenTree: {
    marginBottom: 30,
    height: '56vh',
  },
  textEditor: {
    height: window.innerWidth <= 1280 ? (window.innerWidth <= 1150) ? '60vh' : '50vh' : '30vh',
  },
});

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const tagSearchAdditionalProps: IBasePickerSuggestionsProps = {
  suggestionsContainerAriaLabel: i18n.t('suggestedOrganisation'),
  searchForMoreText: i18n.t('loadallResults'),
  resultsMaximumNumber: 10,
  searchingText: i18n.t('searching') + '...',
};

interface Props extends WithTranslation {
  handleActionBarSave: any;
  handleProjectFromChange: any;
  selectedProject: any;
  projectToleranceState: any;
  projectScopeStatus: any;
  projectManagementLevel: any;
  projectType: any;
  projectFromSubmit: any;
  handleProjectUpdateFormSubmit: any;
  projectTemplate: any;
  projectCurrency: any;
  selectedProjectId: any;
  saveMsgType: any;
  saveMsg: any;
  projectState: any;
  lastInsertProjectId: any;
  handleProjectLoad: any;
  handleResetForm: any;
  resetForm: any;
  submitProject: boolean;
  isDetailsLoaded?: boolean;
  saveBtnDisabled: boolean;
  userRoles: any[];
  contractingUnits: any[];
  deleteTeam: any;
  hideProgress: boolean;
  ledgerDropDowns: any[];
  handleProjectFilter: any
  //resetSelection:any
  //company:any
  // teamMembers:any[];
  //setTeamMembers:any,
  //refreshTeam:boolean
}


class ProjectForm extends React.Component<any, any> {

  private myRef = React.createRef<HTMLDivElement>();
  private _teamSelection: Selection;
  private t = this.props.t;
  private static isNewClick: boolean = false;
  state = {
    isDetailsLoaded: false,
    submitProject: false,
    resetForm: false,
    showMsg: false,
    onchange: false,
    isLoading: true,
    saveMsgType: '',
    saveMsg: '',
    focusedInput: '',
    divClass: 'bookmark-panel parent',
    activeClass: false,
    currentActiveSection: '1',
    selectedProject: {},
    lastInsertProjectId: null,
    selectedProjectId: 0,
    title: '',
    titleErrorMsg: '',
    contractingUnitErrorMsg: '',
    description: '',
    project_id: '0',
    owner: '',
    type: { key: '', text: '' },
    typeErrorMsg: '',
    customer: '',
    managementLevel: { key: '', text: '' },
    projectCurrency: { key: '', text: '' },
    managementLevelErrorMsg: '',
    projectState: { key: '', text: '' },
    projectStateErrorMsg: '',
    toleranceState: { key: '', text: '' },
    toleranceStateErrorMsg: '',
    scopeStatus: { key: '', text: '' },
    scopeStatusErrorMsg: '',
    template: { key: null, text: '' },
    templateErrorMsg: '',
    kpiId: '',
    projectId: '0',
    projectFinanceId: '',
    projectTimeId: '',
    label_1_name: 'risksPending',
    label_1_value: '0',
    label_2_name: 'correctiveActions',
    label_2_value: '0',
    label_3_name: 'issues',
    label_3_value: '0',
    saveBtnDisabled: false,
    totalBudget: '',
    totalBudgetErrorMsg: '',
    invoiced: '',
    invoicedErrorMsg: '',
    paid: '',
    paidErrorMsg: '',
    budgetLabour: '',
    budgetLabourErrorMsg: '',
    budgetMaterial: '',
    budgetMaterialErrorMsg: '',
    currency: '€',
    currencyErrorMsg: '',
    expectedEndDate: '',
    expectedEndDateErrorMsg: '',
    startDate: '',
    startDateErrorMsg: '',
    endDate: '',
    endDateErrorMsg: '',
    company: {
      key: '',
      name: '',
    },
    contractingUnitId: '',
    teamId: null,
    teamMembers: [],
    isOpenTeamModal: false,
    teamActionButtonState: { add: false, edit: true, remove: true },
    teamSelectionDetails: {},
    isMemberEdit: false,
    teamMember: new TeamMember(),
    editTeamIndex: -1,
    refresh: false,
    hideProgress: false,
    position: { lat: 0.0, lon: 0.0 },
    isMapOpen: false,
    location: {
      type: '',
      id: '',
      score: '',
      entityType: '',
      address: {
        municipality: '',
        countrySecondarySubdivision: '',
        countrySubdivision: '',
        countryCode: '',
        country: '',
        countryCodeISO3: '',
        freeformAddress: '',
      },
      position: {
        lat: 0.0,
        lon: 0.0,
      },
      viewport: {
        topLeftPoint: {
          lat: 0.0,
          lon: 0.0,
        },
        btmRightPoint: {
          lat: 0.0,
          lon: 0.0,
        },
      },
      boundingBox: {
        topLeftPoint: {
          lat: 0.0,
          lon: 0.0,
        },
        btmRightPoint: {
          lat: 0.0,
          lon: 0.0,
        },
      },
      dataSources: {
        geometry: {
          id: '',
        },
      },

    },
    travelConvertionErrorMsg: '',
    loadingConvertionMsg: '',
    toptionone: false,
    projectCostConversionCreateDto: {
      projectId: null,
      travelConversionOption: null,
      loadingConversionOption: null,
    },
    genaralLedgerId: null,
    genaralLedger: '',
  };

  private teamColumns = [
    {
      key: 'column1',
      name: i18n.t('name'),
      fieldName: 'cabPersonName',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: i18n.t('role'),
      fieldName: 'roleName',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column3',
      name: i18n.t('status'),
      fieldName: 'status',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: TeamMember) => {
        return (
          <span>
            {this.getStatus(item.status!!)}
          </span>
        );
      },
    },

  ];

  private travelConvertionOptions: IChoiceGroupOption[] = [
    { key: '100', text: i18n.t('none') },
    { key: '200', text: i18n.t('optionOne') },
    { key: '300', text: i18n.t('optionTwo') },
  ];

  private loadingConvertionOptions: IChoiceGroupOption[] = [
    { key: '100', text: i18n.t('none') },
    { key: '200', text: i18n.t('optionOne') },

  ];

  private stackStyles: IStackStyles = {
    root: {
      marginLeft: 20,
    },
  };

  private itemStyles: React.CSSProperties = {
    alignItems: 'center',
    display: 'flex',
    height: 50,
    justifyContent: 'space-between',
    width: 200,
  };

  private itemStylesv: React.CSSProperties = {
    alignItems: 'center',
    display: 'flex',
    height: 50,
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 250,
  };

  private numericalSpacingStackTokens: IStackTokens = {
    childrenGap: 30,
    padding: 10,
  };


  private getStatus = (state: string) => {
    if (state === '3') {
      return i18n.t('invitationPending');
    } else if (state === '1') {
      return i18n.t('notInvited');
    } else if (state === '2') {
      return i18n.t('invited');
    } else {
      return i18n.t('acccepted');
    }
  };

  constructor(props: any) {
    super(props);
    this._teamSelection = new Selection({
      onSelectionChanged: () => {
        if (this.state.teamMembers.length !== 0) {
          this.setState({
            teamSelectionDetails: this._getTeamSelectionDetails(),
            teamActionButtonState: this._getTeamActionButtonState(),
            teamMember: this._teamEditDetails(),
          });
        }
      },
    });
  }

  private _getTeamSelectionDetails(): string | number | {} | [] {
    return this._teamSelection.getSelection();
  }

  private _getTeamActionButtonState(): ActionButtonState {
    const selectionCount = this._teamSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }


  //window width
  componentDidMount() {

    let projectType =
      this.props.projectType.length > 0 &&
      this.props.projectType.filter((item: any) => item.isDefault == true)
        .length > 0
        ? this.props.projectType.filter(
        (item: any) => item.isDefault == true,
        )[0].key
        : '';

    let projectCurrency =
      this.props.projectCurrency.length > 0 &&
      this.props.projectCurrency.filter((item: any) => item.isDefault == true)
        .length > 0
        ? this.props.projectCurrency.filter(
        (item: any) => item.isDefault == true,
        )[0].key
        : '';

    let projectManagementLevel =
      this.props.projectManagementLevel.length > 0 &&
      this.props.projectManagementLevel.filter(
        (item: any) => item.isDefault == true,
      ).length > 0
        ? this.props.projectManagementLevel.filter(
        (item: any) => item.isDefault == true,
        )[0].key
        : '';

    let projectToleranceState =
      this.props.projectToleranceState.length > 0 &&
      this.props.projectToleranceState.filter(
        (item: any) => item.isDefault == true,
      ).length > 0
        ? this.props.projectToleranceState.filter(
        (item: any) => item.isDefault == true,
        )[0].key
        : '';

    this.setState({
      selectedProject: this.props.selectedProject,
      title: '',
      projectCurrency: { key: projectCurrency, text: '' },
      managementLevel: { key: projectManagementLevel, text: '' },
      toleranceState: { key: projectToleranceState, text: '' },
      scopeStatus: { key: '94282458-0b40-40a3-b0f9-c2e40344c8f1', text: '' },
      type: { key: projectType, text: '' },
      isLoading: false,
    });

    if (this.props.contractingUnits && this.props.contractingUnits.length > 0) {
      let company = { key: this.props.contractingUnits[0].key, name: this.props.contractingUnits[0].name };
      this.setState({
        company: company,
        contractingUnitId: company.key,
      });
    }
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    let company = { key: nextProps.contractingUnits[0].key, name: nextProps.contractingUnits[0].name };
    //let teamMembers = (nextProps.selectedProject.projectTeam && nextProps.selectedProject.projectTeam.teamRoleList && !nextProps.resetForm && !nextProps.resetForm?nextProps.selectedProject.projectTeam.teamRoleList:[])

    if (nextProps.resetForm) {

      nextProps.handleResetForm(false);
      ProjectForm.isNewClick = true;

      return {
        template: { key: null, text: '' },
        saveMsgType: nextProps.saveMsgType,
        saveMsg: nextProps.saveMsg,
        resetForm: false,
        onchange: false,
        isLoading: false,
        showMsg: false,
        //selectedProject: {},
        selectedProject: [],
        lastInsertProjectId: null,
        selectedProjectId: 0,
        title: '',
        titleErrorMsg: '',
        contractingUnitErrorMsg: '',
        description: '',
        project_id: '0',
        owner: '',
        typeErrorMsg: '',
        customer: '',
        managementLevelErrorMsg: '',
        projectStateErrorMsg: '',
        toleranceStateErrorMsg: '',
        scopeStatusErrorMsg: '',
        templateErrorMsg: '',
        kpiId: '',
        label_1_name: 'risksPending',
        label_1_value: '0',
        label_2_name: 'correctiveActions',
        label_2_value: '0',
        label_3_name: 'issues',
        label_3_value: '0',
        projectId: '0',
        projectFinanceId: '',
        projectTimeId: '',
        totalBudget: '',
        totalBudgetErrorMsg: '',
        invoiced: '',
        invoicedErrorMsg: '',
        paid: '',
        paidErrorMsg: '',
        budgetLabour: '',
        budgetLabourErrorMsg: '',
        budgetMaterial: '',
        budgetMaterialErrorMsg: '',
        currencyErrorMsg: '',
        expectedEndDate: '',
        expectedEndDateErrorMsg: '',
        startDate: '',
        startDateErrorMsg: '',
        endDate: '',
        endDateErrorMsg: '',
        isDetailsLoaded: nextProps.isDetailsLoaded,
        saveBtnDisabled: nextProps.saveBtnDisabled,
        teamId: null,
        teamMembers: [],
        contractingUnitId: company.key,
        location: {
          type: '',
          id: '',
          score: '',
          entityType: '',
          address: {
            municipality: '',
            countrySecondarySubdivision: '',
            countrySubdivision: '',
            countryCode: '',
            country: '',
            countryCodeISO3: '',
            freeformAddress: '',
          },
          position: {
            lat: 0.0,
            lon: 0.0,
          },
          viewport: {
            topLeftPoint: {
              lat: 0.0,
              lon: 0.0,
            },
            btmRightPoint: {
              lat: 0.0,
              lon: 0.0,
            },
          },
          boundingBox: {
            topLeftPoint: {
              lat: 0.0,
              lon: 0.0,
            },
            btmRightPoint: {
              lat: 0.0,
              lon: 0.0,
            },
          },
          dataSources: {
            geometry: {
              id: '',
            },
          },

        },
        position: { lat: 0.0, lon: 0.0 },
        projectCostConversionCreateDto: {
          projectId: null,
          travelConversionOption: null,
          loadingConversionOption: null,
        },
        genaralLedgerId: null,
        genaralLedger: '',
      };

    } else {
      const id = nextProps.selectedProjectId;
      const prevId = prevState.selectedProjectId;

      if (id && prevId != id) {
        ProjectForm.isNewClick = false;
        nextProps.handleProjectLoad(id);
        return {
          selectedProjectId: nextProps.selectedProjectId,
          selectedProject: nextProps.selectedProject,
          lastInsertProjectId: nextProps.lastInsertProjectId,
          projectId: nextProps.selectedProject.id,
          title: nextProps.selectedProject.name,
          description: nextProps.selectedProject.description,
          type: {
            key: nextProps.selectedProject.projectTypeId,
            text: nextProps.selectedProject.projectType,
          },
          managementLevel: {
            key: nextProps.selectedProject.projectManagementLevelId,
            text: nextProps.selectedProject.projectManagementLevel,
          },
          toleranceState: {
            key: nextProps.selectedProject.projectToleranceStateId,
            text: nextProps.selectedProject.projectToleranceState,
          },
          scopeStatus: {
            key: nextProps.selectedProject.projectScopeStatusId,
            text: nextProps.selectedProject.projectScopeStatus,
          },
          //genaralLedgerId:nextProps.selectedProject.genaralLederId,
          template: {
            key: nextProps.selectedProject.projectTemplateId,
            text: nextProps.selectedProject.projectTemplate,
          },
          label_1_name: typeof nextProps.selectedProject.projectKpi,
          saveMsgType: nextProps.saveMsgType,
          saveMsg: nextProps.saveMsg,
          resetForm: nextProps.resetForm,
          isLoading: false,
          onchange: false,
          titleErrorMsg: '',
          contractingUnitErrorMsg: '',
          typeErrorMsg: '',
          managementLevelErrorMsg: '',
          projectStateErrorMsg: '',
          toleranceStateErrorMsg: '',
          scopeStatusErrorMsg: '',
          templateErrorMsg: '',
          totalBudgetErrorMsg: '',
          invoicedErrorMsg: '',
          paidErrorMsg: '',
          budgetLabourErrorMsg: '',
          budgetMaterialErrorMsg: '',
          currencyErrorMsg: '',
          expectedEndDateErrorMsg: '',
          startDateErrorMsg: '',
          endDateErrorMsg: '',
          isDetailsLoaded: nextProps.isDetailsLoaded,
          saveBtnDisabled: nextProps.saveBtnDisabled,
          teamId: (nextProps.selectedProject.projectTeam && nextProps.selectedProject.projectTeam.id ? nextProps.selectedProject.projectTeam.id : null),
          // teamMembers:(nextProps.selectedProject.projectTeam && nextProps.selectedProject.projectTeam.teamRoleList?nextProps.selectedProject.projectTeam.teamRoleList:[]),
          teamMembers: (nextProps.selectedProject.projectTeam && nextProps.selectedProject.projectTeam.teamRoleList && !nextProps.resetForm && !nextProps.resetForm ? nextProps.selectedProject.projectTeam.teamRoleList : []),
          company: company,
          //company:nextProps.selectedProject.projectTeam?{key:nextProps.selectedProject.projectTeam.contractingUnitId, name:nextProps.selectedProject.projectTeam.contractingUnitName}:prevState.company,
          contractingUnitId: company.key,//nextProps.selectedProject.projectTeam && nextProps.selectedProject.projectTeam.contractingUnitId?nextProps.selectedProject.projectTeam.contractingUnitId:prevState.contractingUnitId,
          location: (nextProps.selectedProject.location ? nextProps.selectedProject.location : {
            type: '',
            id: '',
            score: '',
            entityType: '',
            address: {
              municipality: '',
              countrySecondarySubdivision: '',
              countrySubdivision: '',
              countryCode: '',
              country: '',
              countryCodeISO3: '',
              freeformAddress: '',
            },
            position: {
              lat: 0.0,
              lon: 0.0,
            },
            viewport: {
              topLeftPoint: {
                lat: 0.0,
                lon: 0.0,
              },
              btmRightPoint: {
                lat: 0.0,
                lon: 0.0,
              },
            },
            boundingBox: {
              topLeftPoint: {
                lat: 0.0,
                lon: 0.0,
              },
              btmRightPoint: {
                lat: 0.0,
                lon: 0.0,
              },
            },
            dataSources: {
              geometry: {
                id: '',
              },
            },

          }),
          position:
            nextProps.selectedProject.location &&
            nextProps.selectedProject.location.address &&
            nextProps.selectedProject.location.address.freeformAddress
              ? {
                lat: parseFloat(
                  nextProps.selectedProject.location.position.lat,
                ),
                lon: parseFloat(
                  nextProps.selectedProject.location.position.lon,
                ),
              }
              : nextProps.selectedProject &&
              nextProps.selectedProject.location &&
              nextProps.selectedProject.location.position &&
              !isNaN(parseFloat(nextProps.selectedProject.location.position.lat))
              ? {
                lat: parseFloat(
                  nextProps.selectedProject.location.position.lat,
                ),
                lon: parseFloat(
                  nextProps.selectedProject.location.position.lon,
                ),
              }
              : prevState.position,
          //projectCostConversionCreateDto:nextProps.selectedProject.projectCostConversionCreateDto
        };
      } else if (id == prevId && !prevState.onchange) {
        ProjectForm.isNewClick = false;
        let kpi = nextProps.selectedProject.projectKpi;
        let kpiId = '';
        let customLabelOne = 'risksPending';
        let customPropOne = '0';
        let customLabelTwo = 'correctiveActions';
        let customPropTwo = '0';
        let customLabelThree = 'issues';
        let customPropThree = '0';

        for (let key in kpi) {
          let value = kpi[key];
          if (key == 'id') {
            kpiId = value;
          } else if (key == 'customLabelOne') {
            customLabelOne = value;
          } else if (key == 'customPropOne') {
            customPropOne = value.toString();
          } else if (key == 'customLabelTwo') {
            customLabelTwo = value;
          } else if (key == 'customPropTwo') {
            customPropTwo = value.toString();
          } else if (key == 'customLabelThree') {
            customLabelThree = value;
          } else if (key == 'customPropThree') {
            customPropThree = value.toString();
          }
        }

        return {
          selectedProjectId: nextProps.selectedProjectId,
          selectedProject: nextProps.selectedProject,
          lastInsertProjectId: nextProps.lastInsertProjectId,
          title: nextProps.selectedProject.name,
          projectId: nextProps.selectedProject.id,
          description: nextProps.selectedProject.description,
          type: {
            key: nextProps.selectedProject.projectTypeId,
            text: nextProps.selectedProject.projectType,
          },
          managementLevel: {
            key: nextProps.selectedProject.projectManagementLevelId,
            text: nextProps.selectedProject.projectManagementLevel,
          },
          toleranceState: {
            key: nextProps.selectedProject.projectToleranceStateId,
            text: nextProps.selectedProject.projectToleranceState,
          },
          scopeStatus: {
            key: nextProps.selectedProject.projectScopeStatusId,
            text: nextProps.selectedProject.projectScopeStatus,
          },
          //generalLedgerId:nextProps.selectedProject.genaralLederId,
          template: {
            key: nextProps.selectedProject.projectTemplateId,
            text: nextProps.selectedProject.projectTemplate,
          },
          kpiId: kpiId,
          label_1_name: customLabelOne,
          label_1_value: customPropOne,
          label_2_name: customLabelTwo,
          label_2_value: customPropTwo,
          label_3_name: customLabelThree,
          label_3_value: customPropThree,
          saveMsgType: nextProps.saveMsgType,
          saveMsg: nextProps.saveMsg,
          resetForm: nextProps.resetForm,
          isLoading: false,
          projectFinanceId: nextProps.selectedProject.projectFinance
            ? nextProps.selectedProject.projectFinance.id
            : '',
          totalBudget: nextProps.selectedProject.projectFinance
            ? nextProps.selectedProject.projectFinance.totalBudget
            : '',
          invoiced: nextProps.selectedProject.projectFinance
            ? nextProps.selectedProject.projectFinance.invoiced
            : '',
          paid: nextProps.selectedProject.projectFinance
            ? nextProps.selectedProject.projectFinance.paid
            : '',
          budgetMaterial: nextProps.selectedProject.projectFinance
            ? nextProps.selectedProject.projectFinance.budgetMaterial
            : '',
          budgetLabour: nextProps.selectedProject.projectFinance
            ? nextProps.selectedProject.projectFinance.budgetLabour
            : '',
          projectTimeId: nextProps.selectedProject.projectTime
            ? nextProps.selectedProject.projectTime.id
            : '',
          endDate: nextProps.selectedProject.projectTime
            ? nextProps.selectedProject.projectTime.endDate
              ? new Date(nextProps.selectedProject.projectTime.endDate)
              : ''
            : '',
          expectedEndDate: nextProps.selectedProject.projectTime
            ? nextProps.selectedProject.projectTime.expectedEndDate
              ? new Date(nextProps.selectedProject.projectTime.expectedEndDate)
              : ''
            : '',
          startDate: nextProps.selectedProject.projectTime
            ? nextProps.selectedProject.projectTime.startDate
              ? new Date(nextProps.selectedProject.projectTime.startDate)
              : ''
            : '',
          isDetailsLoaded: nextProps.isDetailsLoaded,
          saveBtnDisabled: nextProps.saveBtnDisabled,
          teamId: (nextProps.selectedProject.projectTeam && nextProps.selectedProject.projectTeam.id ? nextProps.selectedProject.projectTeam.id : null),
          teamMembers: (nextProps.selectedProject.projectTeam && nextProps.selectedProject.projectTeam.teamRoleList && !nextProps.resetForm && !nextProps.resetForm ? nextProps.selectedProject.projectTeam.teamRoleList : []),
          //teamMembers:prevState.teamMembers != []?prevState.teamMembers:(nextProps.selectedProject.projectTeam && nextProps.selectedProject.projectTeam.teamRoleList?nextProps.selectedProject.projectTeam.teamRoleList:[]),
          company: company,
          // company: nextProps.selectedProject.projectTeam
          //   ? {
          //       key: nextProps.selectedProject.projectTeam.contractingUnitId,
          //       name: nextProps.selectedProject.projectTeam.contractingUnitName,
          //     }
          //   : prevState.company,
          //   contractingUnitId:company.key,
          // contractingUnitId:
          //   nextProps.selectedProject.projectTeam &&
          //   nextProps.selectedProject.projectTeam.contractingUnitId
          //     ? nextProps.selectedProject.projectTeam.contractingUnitId
          //     : prevState.contractingUnitId,
          contractingUnitId: company.key,
          location: nextProps.selectedProject.location
            ? nextProps.selectedProject.location
            : {
              type: '',
              id: '',
              score: '',
              entityType: '',
              address: {
                municipality: '',
                countrySecondarySubdivision: '',
                countrySubdivision: '',
                countryCode: '',
                country: '',
                countryCodeISO3: '',
                freeformAddress: '',
              },
              position: {
                lat: 0.0,
                lon: 0.0,
              },
              viewport: {
                topLeftPoint: {
                  lat: 0.0,
                  lon: 0.0,
                },
                btmRightPoint: {
                  lat: 0.0,
                  lon: 0.0,
                },
              },
              boundingBox: {
                topLeftPoint: {
                  lat: 0.0,
                  lon: 0.0,
                },
                btmRightPoint: {
                  lat: 0.0,
                  lon: 0.0,
                },
              },
              dataSources: {
                geometry: {
                  id: '',
                },
              },
            },
          position:
            nextProps.selectedProject.location &&
            nextProps.selectedProject.location.address &&
            nextProps.selectedProject.location.address.freeformAddress
              ? {
                lat: parseFloat(
                  nextProps.selectedProject.location.position.lat,
                ),
                lon: parseFloat(
                  nextProps.selectedProject.location.position.lon,
                ),
              }
              : nextProps.selectedProject &&
              nextProps.selectedProject.location &&
              nextProps.selectedProject.location.position &&
              !isNaN(parseFloat(nextProps.selectedProject.location.position.lat))
              ? {
                lat: parseFloat(
                  nextProps.selectedProject.location.position.lat,
                ),
                lon: parseFloat(
                  nextProps.selectedProject.location.position.lon,
                ),
              }
              : prevState.position,
          //hideProgress:nextProps.inProgress
          //projectCostConversionCreateDto:prevState.projectCostConversionCreateDto && prevState.projectCostConversionCreateDto.projectId? prevState.projectCostConversionCreateDto : nextProps.selectedProject.projectCostConversionCreateDto
        };
      } else if (prevState.onchange) {
        return {
          company: company.name,
          contractingUnitId: company.key,
          saveMsgType: nextProps.saveMsgType,
          saveMsg: nextProps.saveMsg,
          resetForm: nextProps.resetForm,
          isLoading: false,
          isDetailsLoaded: nextProps.isDetailsLoaded,
          saveBtnDisabled: nextProps.saveBtnDisabled,
          teamMembers: !ProjectForm.isNewClick ? ProjectForm.getTeamMembers(prevState, (nextProps.selectedProject.projectTeam && nextProps.selectedProject.projectTeam.teamRoleList && nextProps.selectedProjectId && !nextProps.resetForm ? nextProps.selectedProject.projectTeam.teamRoleList : [])) : [],
        };
      } else {
        return {
          company: company.name,
          contractingUnitId: company.key,
          saveMsgType: nextProps.saveMsgType,
          saveMsg: nextProps.saveMsg,
          resetForm: nextProps.resetForm,
          onchange: false,
          isLoading: false,
          isDetailsLoaded: nextProps.isDetailsLoaded,
          saveBtnDisabled: nextProps.saveBtnDisabled,
          //teamMembers:prevState.teamMembers
        };
      }
    }
  }

  static getTeamMembers(prevState: any, teamMembers: any) {
    if (prevState.teamMembers.length > teamMembers.length) {

      return prevState.teamMembers;
    } else {
      return teamMembers;
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.submitProject !== this.props.submitProject) {
      if (this.props.submitProject) {
        this.handleFormSubmit();
        this.props.handleActionBarSave(false);
      }
    }

    if (prevProps.selectedProject !== this.props.selectedProject) {
      this.setState({
        genaralLedger: this.props.selectedProject.generalLedgerId,
        projectCostConversionCreateDto: this.props.selectedProject.projectCostConversionCreateDto,
      });
    }

    if (prevProps.contractingUnits !== this.props.contractingUnits) {
      let company = { key: this.props.contractingUnits[0].key, name: this.props.contractingUnits[0].name };
      this.setState({
        company: company,
        contractingUnitId: company.key,
      });
    }
    if (prevProps.selectedProjectId !== this.props.selectedProjectId) {
      this._teamSelection.selectToKey('', true);
      this._teamSelection.setAllSelected(false);
    }

    if (prevProps.hideProgress != this.props.hideProgress) {
      this.setState({ hideProgress: this.props.hideProgress });
    }

  }


  private handleScroll = (e: any): void => {
    let element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {

    }
    this.setState({
      activeClass: false,
    });

    let h2Headings = document.getElementsByClassName('proj-detail-block');
    let item = h2Headings[0];
    let tt = 0;

    for (let i = 0; i < h2Headings.length; i++) {
      if (h2Headings[i].getBoundingClientRect().top <= 300) {
        if (tt <= h2Headings[i].getBoundingClientRect().top) {
          tt = h2Headings[i].getBoundingClientRect().top;
          item = h2Headings[i];

          var c = item.id;
          this.setState({ currentActiveSection: c });
        }
      }
    }

    if (item.getAttribute('id')) {
      this.setState({ activeClass: true });
    }
  };

  private handleFormSubmit = (): void => {
    this.setState({
      showMsg: true,
      saveMsg: '',
      onchange: true,
      saveBtnDisabled: true,
      hideProgress: true,
    });
    window.scrollTo(0, 0);
    //ProjectForm.saveClick = true
    this.myRef.current!.scrollTo(0, 0);
    if (this.validate()) {
      let type = { key: '', text: '' };
      let projectManagementLevel = { key: '', text: '' };
      let projectToleranceState = { key: '', text: '' };
      let projectScopeStatus = { key: '', text: '' };
      let projectTemplate = { key: null, text: '' };
      let projectCurrency = { key: '', text: '' };
      if (typeof this.state.type === 'object') {
        type = this.state.type;
      }
      if (typeof this.state.managementLevel === 'object') {
        projectManagementLevel = this.state.managementLevel;
      }
      if (typeof this.state.toleranceState === 'object') {
        projectToleranceState = this.state.toleranceState;
      }
      if (typeof this.state.scopeStatus === 'object') {
        projectScopeStatus = this.state.scopeStatus;
      }
      if (typeof this.state.template === 'object') {
        projectTemplate = this.state.template;
      }
      if (typeof this.state.projectCurrency === 'object') {
        projectCurrency = this.state.projectCurrency;
      }

      if (this.state.selectedProjectId == 0) {
        let data = {
          id: this.state.selectedProjectId,
          name: this.state.title,
          description: this.state.description,
          projectTypeId: type.key,
          projectManagementLevelId: projectManagementLevel.key,
          projectToleranceStateId: projectToleranceState.key,
          projectScopeStatusId: projectScopeStatus.key,
          projectTemplateId: projectTemplate.key,
          kpi: {
            customLabelOne: this.state.label_1_name
              ? this.state.label_1_name
              : i18n.t('risksPending'),
            customPropOne: this.state.label_1_value
              ? this.state.label_1_value
              : '0',
            customLabelTwo: this.state.label_2_name
              ? this.state.label_2_name
              : i18n.t('correctiveActions'),
            customPropTwo: this.state.label_2_value
              ? this.state.label_2_value
              : '0',
            customLabelThree: this.state.label_3_name
              ? this.state.label_3_name
              : i18n.t('issues'),
            customPropThree: this.state.label_3_value
              ? this.state.label_3_value
              : '0',
          },
          projectTime: {
            expectedEndDate: formatDateTimeToISOString(this.state.expectedEndDate),
            startDate: formatDateTimeToISOString(this.state.startDate),
            endDate: formatDateTimeToISOString(this.state.endDate),
            calendarTemplateId: null,
            projectId: this.state.selectedProjectId,
          },
          projectFinance: {
            totalBudget: this.state.totalBudget,
            invoiced: this.state.invoiced,
            paid: this.state.paid,
            budgetLabour: this.state.budgetLabour,
            budgetMaterial: this.state.budgetMaterial,
            currencyId: projectCurrency.key,
            projectId: this.state.selectedProjectId,
          },
          projectTeam: {
            id: this.state.teamId,
            contractingUnitId: this.state.contractingUnitId,
            projectId: null,
            teamRoleList: this.getTeamMembers(),
          },
          projectCostConversionCreateDto: {
            ...this.state.projectCostConversionCreateDto,
            projectId: this.state.selectedProjectId,
          },
          location: this.state.location,
          genaralLederId: this.state?.genaralLedger,
        };
        this.props.projectFromSubmit(data);
      } else {
        let data = {
          id: this.state.projectId,
          sequenceCode: this.state.selectedProjectId,
          name: this.state.title,
          description: this.state.description,
          projectTypeId: type.key,
          projectManagementLevelId: projectManagementLevel.key,
          projectToleranceStateId: projectToleranceState.key,
          projectScopeStatusId: projectScopeStatus.key,
          projectTemplateId: projectTemplate.key,
          kpi: {
            id: this.state.kpiId,
            customLabelOne: this.state.label_1_name
              ? this.state.label_1_name
              : 'risksPending',
            customPropOne: this.state.label_1_value
              ? this.state.label_1_value
              : '0',
            customLabelTwo: this.state.label_2_name
              ? this.state.label_2_name
              : 'correctiveActions',
            customPropTwo: this.state.label_2_value
              ? this.state.label_2_value
              : '0',
            customLabelThree: this.state.label_3_name
              ? this.state.label_3_name
              : 'issues',
            customPropThree: this.state.label_3_value
              ? this.state.label_3_value
              : '0',
            projectId: this.state.projectId,
          },
          projectTime: {
            id: this.state.projectTimeId,
            expectedEndDate: formatDateTimeToISOString(this.state.expectedEndDate),
            startDate: formatDateTimeToISOString(this.state.startDate),
            endDate: formatDateTimeToISOString(this.state.endDate),
            calendarTemplateId: null,
            projectId: this.state.projectId,
          },
          projectFinance: {
            id: this.state.projectFinanceId,
            totalBudget: this.state.totalBudget,
            invoiced: this.state.invoiced,
            paid: this.state.paid,
            budgetLabour: this.state.budgetLabour,
            budgetMaterial: this.state.budgetMaterial,
            currencyId: projectCurrency.key,
            projectId: this.state.projectId,
          },
          projectTeam: {
            id: this.state.teamId,
            contractingUnitId: this.state.contractingUnitId,
            projectId: null,
            teamRoleList: this.getTeamMembers(),
          },
          projectCostConversionCreateDto: {
            ...this.state.projectCostConversionCreateDto,
            projectId: this.state.projectId,
          },
          location: this.state.location,
          genaralLederId: this.state?.genaralLedger,
        };
        this.props.handleProjectUpdateFormSubmit(data);
      }
    } else {
      this.setState({
        saveBtnDisabled: false,
      });
    }
  };

  private getTeamMembers = () => {
    var teamMembers = this.state.teamMembers.map((member: TeamMember) => {
      return {
        id: member.id,
        cabPersonName: member.cabPersonName,
        roleName: member.roleName,
        cabPersonId: member.cabPersonId,
        roleId: member.roleId,
        isAccessGranted: member.isAccessGranted,
        status: member.status === '3' ? '1' : member.status,
        message: member.message,
      }; // Do transformation here
    });

    return teamMembers;
  };

  private validate = (): boolean => {
    let title = this.state.title;
    //let contractingUnit =(this.state.company)?this.state.company.key:null;
    let contractingUnit = (this.state.company) ? this.state.company : null;
    let managementLevel = this.state.managementLevel.key;
    let toleranceState = this.state.toleranceState.key;
    let scopeStatus = this.state.scopeStatus.key;
    let template = this.state.template.key;
    let type = this.state.type.key;
    let budgetLabour = this.state.budgetLabour ? parseFloat(this.state.budgetLabour) : 0;
    let budgetMaterial = this.state.budgetMaterial ? parseFloat(this.state.budgetMaterial) : 0;
    let totalBudget = this.state.totalBudget ? parseFloat(this.state.totalBudget) : 0;
    let invoiced = this.state.invoiced ? parseFloat(this.state.invoiced) : 0;
    let paid = this.state.paid ? parseFloat(this.state.paid) : 0;
    let calTotalBudget = budgetLabour + budgetMaterial;
    let isValid = true;
    if (_.isNil(title) || _.isEmpty(title)) {
      this.setState({ titleErrorMsg: i18n.t('nameRequired') });
      isValid = false;
    } else {
      this.setState({ titleErrorMsg: '' });
    }
    if (_.isNil(contractingUnit) || _.isEmpty(contractingUnit)) {
      this.setState({ contractingUnitErrorMsg: i18n.t('contractingUnitrequired') });
      isValid = false;
    } else {
      this.setState({ contractingUnitErrorMsg: '' });
    }
    if (_.isNil(managementLevel) || _.isEmpty(managementLevel)) {
      this.setState({ managementLevelErrorMsg: i18n.t('managementLevelrequired') });
      isValid = false;
    } else {
      this.setState({ managementLevelErrorMsg: '' });
    }
    if (_.isNil(toleranceState) || _.isEmpty(toleranceState)) {
      this.setState({ toleranceStateErrorMsg: i18n.t('toleranceStaterequired') });
      isValid = false;
    } else {
      this.setState({ toleranceStateErrorMsg: '' });
    }
    if (_.isNil(scopeStatus) || _.isEmpty(scopeStatus)) {
      this.setState({ scopeStatusErrorMsg: i18n.t('scopeStatusrequired') });
      isValid = false;
    } else {
      this.setState({ scopeStatusErrorMsg: '' });
    }

    if (_.isNil(template) || _.isEmpty(template)) {
      //  this.setState({ templateErrorMsg: 'Template required' });
      //isValid = false;
    } else {
      this.setState({ templateErrorMsg: '' });
    }
    if (_.isNil(type) || _.isEmpty(type)) {
      this.setState({ typeErrorMsg: i18n.t('typeRequired') });
      isValid = false;
    } else {
      this.setState({ typeErrorMsg: '' });
    }

    if (calTotalBudget > totalBudget && totalBudget > 0) {
      isValid = false;
      this.setState({
        totalBudgetErrorMsg:
          i18n.t('totalBudgetcanTbelessthanBudgetlabourandMaterial'),
        budgetLabourErrorMsg:
          i18n.t('budgetlabourandMaterialcanTbehigherthanTotalBudget'),
        budgetMaterialErrorMsg:
          i18n.t('budgetlabourandMaterialcanTbehigherthanTotalBudget'),
      });
    } else {
      this.setState({
        budgetLabourErrorMsg: '',
        budgetMaterialErrorMsg: '',
        totalBudgetErrorMsg: '',
      });
    }

    return isValid;
  };

  private checkBudgetValidation = (): void => {
    this.props.handleProjectFromChange();
    let budgetLabour = this.state.budgetLabour
      ? parseFloat(this.state.budgetLabour)
      : 0;
    let budgetMaterial = this.state.budgetMaterial
      ? parseFloat(this.state.budgetMaterial)
      : 0;
    let totalBudget = this.state.totalBudget
      ? parseFloat(this.state.totalBudget)
      : 0;
    let calTotalBudget = budgetLabour + budgetMaterial;
    let focusedInput = this.state.focusedInput;
    if (calTotalBudget > totalBudget && totalBudget > 0) {
      if (focusedInput == 'budgetLabour') {
        this.setState({
          budgetLabourErrorMsg:
            i18n.t('budgetlabourandMaterialcanTbehigherthanTotalBudget'),
        });
      } else if (focusedInput == 'budgetMaterial') {
        this.setState({
          budgetMaterialErrorMsg:
            i18n.t('budgetlabourandMaterialcanTbehigherthanTotalBudget'),
        });
      } else {
        this.setState({
          totalBudgetErrorMsg:
            i18n.t('totalBudgetcanTbelessthanBudgetlabourandMaterial'),
        });
      }
    } else {
      this.setState({
        budgetLabourErrorMsg: '',
        budgetMaterialErrorMsg: '',
        totalBudgetErrorMsg: '',
      });
    }
  };


  private renderProjectDetails() {
    //const { t } = this.props;
    return (
      <div className="proj-detail-content inner">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
            <TextField
              label={i18n.t('projectTitle')}
              value={this.props.selectedProject.title}
              disabled={true}
            />
          </div>
          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
            <TextField
              label={i18n.t('projectId')}
              value={this.props.selectedProject.sequenceCode}
              disabled={true}
            />
          </div>
        </div>
      </div>
    );
  }

  renderBookmarkPane() {
    const { t } = this.props;
    return (
      <div>
        <Stack
          className={this.state.divClass}
          verticalFill
          gap={30}
          styles={{
            root: {
              selectors: {
                '@media(max-width: 1440px)': {
                  boxShadow: Depths.depth8,
                },
              },
              zIndex: 100000,
            },
          }}
        >
          <i
            title="Close"
            className="ms-Icon ms-Icon--ChromeClose"
            aria-hidden="true"
            onClick={this._toggleBookmarkOut}
          ></i>
          <Text className="title">{t('bookmark')}</Text>
          <div className="inner-container">
            <Label
              className={`${
                this.state.currentActiveSection == '1' ? 'selected' : ''
              }`}
            >
              {' '}
              <Link href="#project-definition">1. {t('projectDefinition')}</Link>
            </Label>
            <Label
              className={`${
                this.state.currentActiveSection == '2' ? 'selected' : ''
              }`}
            >
              {' '}
              <Link href="#project-address">2. {t('address')}</Link>
            </Label>
            <Label
              className={`${
                this.state.currentActiveSection == '3' ? 'selected' : ''
              }`}
            >
              {' '}
              <Link href="#teams">3. {t('teams')}</Link>
            </Label>
            <Label
              className={`${
                this.state.currentActiveSection == '4' ? 'selected' : ''
              }`}
            >
              {' '}
              <Link href="#finance">4. {t('finance')}</Link>
            </Label>
            <Label
              className={`${
                this.state.currentActiveSection == '5' ? 'selected' : ''
              }`}
            >
              <Link href="#time">5. {t('date')}</Link>
            </Label>
            <Label
              className={`${
                this.state.currentActiveSection == '6' ? 'selected' : ''
              }`}
            >
              <Link href="#kpi">6. {t('kPIS')}</Link>
            </Label>
            <Label
              className={`${
                this.state.currentActiveSection == '7' ? 'selected' : ''
              }`}
            >
              <Link href="#cost">7. {t('projectCost')}</Link>
            </Label>
            {this.state.selectedProjectId != 0 &&
            <Label className={`${this.state.currentActiveSection == '8' ? 'selected' : ''}`}><Link
              href="#history">8. {t('history')}</Link></Label>}
          </div>
        </Stack>

        <Stack
          className="bookmark-panel-responsive"
          verticalFill
          onClick={this._toggleBookmark}
          gap={30}
          styles={{
            root: {
              boxShadow: Depths.depth8,
              zIndex: 10000,
            },
          }}
        >
          <TooltipHost
            content={i18n.t('bookmark')}
            id="bmark"
            calloutProps={{ gapSpace: 0 }}
            directionalHint={DirectionalHint.rightCenter}
          >
            <i
              className="ms-Icon ms-Icon--SearchBookmark"
              aria-labelledby="bmark"
              aria-hidden="true"
            ></i>
          </TooltipHost>
        </Stack>
      </div>
    );
  }

  shouldComponentUpdate(nextProps: any, nextState: any) {
    return true;
  }

  renderShimmer() {
    return (
      <div>
        <Stack
          className={contentStyle}
          horizontalAlign="start"
          verticalAlign="start"
          padding={0}
          gap={0}
          styles={{
            root: {
              width: '100%',
              height: '100%',
            },
          }}
        >
          <Stack
            className={listStyle}
            styles={{
              root: {
                width: '100%',
                height: 'auto',
              },
            }}
            verticalFill
            gap={50}
            horizontalAlign="start"
            verticalAlign="start"
            onScroll={this.handleScroll}
          >
            <div style={{ width: '100%' }}>
              <div className="proj-detail-block">
                <CustomShimmer/>
              </div>
            </div>
          </Stack>
          {this.renderBookmarkPane()}
        </Stack>
      </div>
    );
  }

  private onFilterCompanyChanged = (
    filterText: string,
    selectedItems?: ITag[],
    limitResults?: number,
  ): any => {
    if (filterText) {
      return this.getCompanyByName(filterText);
    } else {
      return [];
    }
  };


  private getCompanyByName = async (name: string) => {
    let response = await client.get(
      `ProjectTeam/ReadContractingUnit/${name}`,
    );

    return this.formatCompanyResponse(response);
  };

  private formatCompanyResponse = (response: any) => {
    let data: { key: string; text: string }[] = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: any) => {

        return {
          ...item,
          key: item.key,
        };
      });
    }
    return data;
  };

  private getPersonByName = async (name: string) => {
    let response = await client.get(
      `CentralAddressBook/ReadCabPersonList/${name}`,
    );
    return this.formatPersonResponse(response);
  };

  private formatPersonResponse = (response: any) => {
    let data: any[] = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: AddressBookItem) => {
        //if (!this.getTeamCabPersionIdList().includes(item.person.id)) {
        return {
          ...item,
          text: item.person ? item.person.fullName : '',
          image: item.person ? item.person.image : '',
          secondaryText: item.personCompany ? item.personCompany.jobRole : '',
          key: item.id,
        };
        //}
      });

      data = data.filter((cabPerson) => {
        return this.getTeamCabPersionIdList().indexOf(cabPerson.person.id) === -1;
      });

    }
    return data;
  };

  getTeamCabPersionIdList = () => {
    let idList = [];
    if (this.state.teamMembers) {
      idList = this.state.teamMembers.map((item: any) => {
        return item.cabPersonId;
      });
    }
    return idList;
  };

  private onFilterPersonChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[],
    limitResults?: number,
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return this.getPersonByName(filterText);
    } else {
      return [];
    }
  };

  private onCompanyItemSelected = (selectedItem?: ITag | undefined): any => {
    let selectedCompany = selectedItem as { key: string, name: string };
    this.state.contractingUnitErrorMsg = '';
    this.setState({
      contractingUnitId: selectedCompany.key,
      company: { key: selectedCompany.key, name: selectedCompany.name },
    });
    return selectedItem;
  };

  private onRemoveCompanySuggestion = (items: ITag[] | undefined): void => {
    if (items && _.isArray(items) && items.length === 0) {
      this.setState({ contractingUnitId: null, company: null, onchange: true });
    }
  };

  private onAddTeamMember = (member: TeamMember) => {
    let members: TeamMember[] = [];
    let currentList: TeamMember[] = this.state.teamMembers;
    ProjectForm.isNewClick = false;
    if (this.state.editTeamIndex === -1) {
      members.push(member);
      //members.push(member);
      currentList.forEach((item: any) => {
        members.push(item);
      });
      this.setState({ teamMembers: members, openTeamModal: false, onchange: true }, () => {
        this.handleFormSubmit();
      });
    } else {
      if (this.state.teamMembers.length >= this.state.editTeamIndex + 1) {
        currentList[this.state.editTeamIndex] = member;
      }
      this.setState({ teamMembers: currentList, openTeamModal: false, onchange: true }, () => {
        this.handleFormSubmit();
      });
    }
    messageService.sendMessage({ disableSave: true });
    this._teamSelection.selectToKey('', true);
  };

  private openTeamModal = () => {
    this.setState({
      isOpenTeamModal: !this.state.isOpenTeamModal,
      teamMember: this._teamEditDetails(),
    });
  };

  private _teamEditDetails() {
    const selectionCount = this._teamSelection.getSelectedCount();
    let teamMember = new TeamMember();

    if (selectionCount == 1 && !(this.state.teamMembers.length === 0)) {
      let index = this._teamSelection.getSelectedIndices();

      this.setState({
        isMemberEdit: true,
        editTeamIndex: index[0],
        teamActionButtonState: { add: true, edit: false, remove: false },
      });

      let selectedLabour = this._teamSelection.getSelection()[0] as TeamMember;
      teamMember = selectedLabour;
      //assign
    } else {
      this.setState({
        isMemberEdit: false,
        editTeamIndex: -1,
      });
    }
    return teamMember;
  }


  private deleteTeamMembers = () => {
    let indexes = this._teamSelection.getSelectedIndices();
    let selectedList = [];
    selectedList = this._teamSelection.getSelection().map((item: any) => {
      return item.id;
    });
    if (selectedList.length > 0) {
      let array = this.state.teamMembers;
      let removed = _.pullAt(array, indexes);
      this.setState({ teamMembers: [] }, () => {
        this.setState({ teamMembers: array, onchange: true }, () => {
          if (array.length === 0) {
            this.setState({ teamActionButtonState: { add: false, edit: true, remove: true }, isMemberEdit: false });

          }
        });
      });

      //this.forceUpdate()
      this.props.deleteTeam(selectedList).then(() => {
          this.props.handleProjectFilter();
          if (this.state.selectedProjectId !== 0) {
            this.props.handleProjectLoad(this.state.selectedProjectId);
          }
        },
      );
      // setTimeout(()=>{
      //   this.props.handleProjectLoad(this.state.selectedProjectId);
      // },3000)
    }
    //this._teamSelection.selectToKey('', true);
    this._teamSelection.selectToKey('', true);
    this._teamSelection.setAllSelected(false);
  };

  getUserRoles = () => {
    let userRoles: any[] = [];
    if (this.props.userRoles) {
      let pmKey = this.props.userRoles.filter((item: any) => item.text === 'Project Manager');

      let pmUsers = [];
      pmUsers = this.state.teamMembers?.filter((item: TeamMember) =>
        (pmKey.length > 0 && item.roleId === pmKey[0].key),
      );

      if (pmUsers && pmUsers.length > 0) {
        userRoles = this.props.userRoles.filter((item: any) => item.text != 'Project Manager');
      } else {
        userRoles = this.props.userRoles;
      }
    }
    return userRoles;
  };


  private getMembers = () => {
    if (this.state.teamMembers && this.state.teamMembers.length > 0) {
      return this.state.teamMembers;
    } else {
      return [];
    }
  };

  private setLocationAddress = (position: Position) => {
    this.setState({ position: { lat: position.lat, lon: position.lon }, onchange: true });
  };

  private setAddress = (address: LocationAddress) => {
    this.setState({ location: address });
  };

  _onChangeTravelConvertion = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    option?: IChoiceGroupOption | undefined,
  ): void => {
    this.setState((prevState: any) => ({
      ...prevState,
      projectCostConversionCreateDto: {
        ...prevState.projectCostConversionCreateDto,
        travelConversionOption: option?.key!!,
      },
    }));

  };

  _onChangeLoadingConvertion = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    option?: IChoiceGroupOption | undefined,
  ): void => {
    this.setState((prevState: any) => ({
      ...prevState,
      projectCostConversionCreateDto: {
        ...prevState.projectCostConversionCreateDto,
        loadingConversionOption: option?.key!!,
      },
    }));
  };

  public render(): JSX.Element {
    //let selectedCompany: ITag[] | null = null;
    let selectedCompany: any = null;

    if (this.state.company && this.state.contractingUnitId) {
      selectedCompany = [this.state.company];
    } else if (this.state.contractingUnitId === null) {
      selectedCompany = [];
    }
    const { t } = this.props;
    if (this.state.isDetailsLoaded) {
      return (
        <div ref={this.myRef}>
          <Stack
            className={contentStyle}
            horizontalAlign="start"
            verticalAlign="start"
            padding={0}
            gap={0}
            styles={{
              root: {
                width: '100%',
                height: '100%',
              },
            }}
          >
            <Stack
              className={listStyle}
              styles={{
                root: {
                  width: '100%',
                  height: 'auto',
                },
              }}
              verticalFill
              gap={50}
              horizontalAlign="start"
              verticalAlign="start"
              onScroll={this.handleScroll}
            >
              <div style={{ width: '100%' }} id={'projectForm'} className="card-container">
                {/*{ this.state.hideProgress &&(*/}
                {/*<ProgressIndicator styles={{ root: { marginTop: -8 } }} />*/}
                {/*)}*/}

                <div className="proj-detail-block" id="1">
                  <div className="document-pane-card">
                    <div className={'card-header'}>
                      <Link href="#" id="project-definition">
                        <Label>1. {t('projectDefinition')} </Label>
                      </Link>
                    </div>
                    {this.state.selectedProjectId != 0 &&
                    this.renderProjectDetails()}
                    <div className={'card-body'}>
                      <div className="proj-detail-content inner">
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <TextField
                              label={i18n.t('name')}
                              value={this.state.title}
                              required={true}
                              autoFocus
                              errorMessage={this.state.titleErrorMsg}
                              onChange={(event, value) => {
                                this.setState(
                                  {
                                    title: value,
                                    titleErrorMsg: '',
                                    onchange: true,
                                  },
                                  this.props.handleProjectFromChange,
                                );
                              }}
                            />
                          </div>
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"></div>
                        </div>
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <TextField
                              value={
                                this.state.description ? this.state.description : ''
                              }
                              label={i18n.t('description')}
                              multiline
                              rows={5}
                              onChange={(event, value) => {
                                this.setState(
                                  { description: value, onchange: true },
                                  this.props.handleProjectFromChange,
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            {this.props.projectType ? (
                              <Dropdown
                                placeholder={i18n.t('selectanoption')}
                                label={i18n.t('type')}
                                required={true}
                                errorMessage={this.state.typeErrorMsg}
                                selectedKey={
                                  this.state.type ? this.state.type.key : ''
                                }
                                options={this.props.projectType}
                                styles={dropdownStyles}
                                onChange={(event, value) => {
                                  this.setState(
                                    {
                                      type: value,
                                      typeErrorMsg: '',
                                      onchange: true,
                                    },
                                    this.props.handleProjectFromChange,
                                  );
                                }}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            {this.props.projectManagementLevel ? (
                              <Dropdown
                                placeholder={i18n.t('selectanoption')}
                                label={i18n.t('managementLevel')}
                                required={true}
                                errorMessage={this.state.managementLevelErrorMsg}
                                selectedKey={
                                  this.state.managementLevel
                                    ? this.state.managementLevel.key
                                    : ''
                                }
                                options={this.props.projectManagementLevel}
                                styles={dropdownStyles}
                                onChange={(event, value) => {
                                  this.setState(
                                    {
                                      managementLevel: value,
                                      managementLevelErrorMsg: '',
                                      onchange: true,
                                    },
                                    this.props.handleProjectFromChange,
                                  );
                                }}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            {this.props.projectToleranceState ? (
                              <Dropdown
                                placeholder={i18n.t('selectanoption')}
                                label={i18n.t('toleranceState')}
                                required={true}
                                errorMessage={this.state.toleranceStateErrorMsg}
                                options={this.props.projectToleranceState}
                                selectedKey={
                                  this.state.toleranceState
                                    ? this.state.toleranceState.key
                                    : ''
                                }
                                styles={dropdownStyles}
                                onChange={(event, value) => {
                                  this.setState(
                                    {
                                      toleranceState: value,
                                      toleranceStateErrorMsg: '',
                                      onchange: true,
                                    },
                                    this.props.handleProjectFromChange,
                                  );
                                }}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            {this.props.projectTemplate ? (
                              <Dropdown
                                placeholder={i18n.t('selectanoption')}
                                label={i18n.t('template')}
                                errorMessage={this.state.templateErrorMsg}
                                options={this.props.projectTemplate}
                                selectedKey={
                                  this.state.template ? this.state.template.key : ''
                                }
                                styles={dropdownStyles}
                                onChange={(event, value) => {
                                  this.setState(
                                    {
                                      template: value,
                                      templateErrorMsg: '',
                                      onchange: true,
                                    },
                                    this.props.handleProjectFromChange,
                                  );
                                }}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            {this.props.projectScopeStatus ? (
                              <Dropdown
                                placeholder={i18n.t('selectanoption')}
                                label={i18n.t('scopeStatus')}
                                required={true}
                                errorMessage={this.state.scopeStatusErrorMsg}
                                options={this.props.projectScopeStatus}
                                selectedKey={
                                  this.state.scopeStatus
                                    ? this.state.scopeStatus.key
                                    : ''
                                }
                                styles={dropdownStyles}
                                onChange={(event, value) => {
                                  this.setState(
                                    {
                                      scopeStatus: value,
                                      scopeStatusErrorMsg: '',
                                      onchange: true,
                                    },
                                    this.props.handleProjectFromChange,
                                  );
                                }}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="proj-detail-block" id="2">
                  <div className="document-pane-card">
                    <div className={'card-header'}>
                      <Link href="#" id="project-address">
                        <Label>2. {t('address')} </Label>
                      </Link>
                    </div>
                    <div className={'card-body'}>
                      <div className="proj-detail-content inner">
                        <div style={{ zIndex: 10000000, marginBottom: 50 }}>
                          <AddressLocationComponent
                            setLocationAddress={(address) => {
                              this.setLocationAddress(address.position);
                              this.setAddress(address);
                            }}
                            position={this.state.position}
                            openMapModal={() => this.openMapModal()}
                            address={
                              this.state.location &&
                              this.state.location?.address &&
                              this.state.location.address.freeformAddress
                                ? this.state.location.address.freeformAddress
                                : ''
                            }
                            lable={i18n.t('projectCoordinates')}
                            addressLable={i18n.t('projectAddress')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="proj-detail-block" id="3">
                  <div className="document-pane-card">
                    <div className={'card-header'}>
                      <Link href="#" id="teams">
                        <Label>3. {t('teams')}</Label>
                      </Link>
                    </div>
                    <div className={'card-body'}>
                      <div className="proj-detail-content inner">
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm ms-md6 ms-lg6">
                            {/* Selected company comes in two forms string and object project get by id ut comes as object and create new ot comes as string */}
                            <TextField
                              label={i18n.t('contractingUnit')}
                              value={
                                selectedCompany &&
                                selectedCompany[0] &&
                                selectedCompany[0].hasOwnProperty('name')
                                  ? selectedCompany[0].name
                                  : selectedCompany[0]
                              }
                              disabled={true}
                            />
                            <div style={{ display: 'none' }}>
                              <Label>{t('contractingUnit')}</Label>
                              <TagPicker
                                selectedItems={
                                  selectedCompany ? selectedCompany : []
                                }
                                removeButtonAriaLabel="Remove"
                                onResolveSuggestions={this.onFilterCompanyChanged}
                                onItemSelected={this.onCompanyItemSelected}
                                onChange={this.onRemoveCompanySuggestion}
                                pickerSuggestionsProps={tagSearchAdditionalProps}
                                itemLimit={1}
                                disabled={false}
                                inputProps={{
                                  onBlur: (
                                    ev: React.FocusEvent<HTMLInputElement>,
                                  ) => {
                                  },
                                  onFocus: (
                                    ev: React.FocusEvent<HTMLInputElement>,
                                  ) => {
                                  },
                                  'aria-label': 'Tag Picker',
                                }}
                              />

                              {this.state.contractingUnitErrorMsg && (
                                <Text
                                  styles={{
                                    root: { color: '#a4262c', fontSize: 12 },
                                  }}
                                >
                                  {this.state.contractingUnitErrorMsg}
                                </Text>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DataGrid
                              dataList={this.getMembers()}
                              editDataGrid={() => this.openTeamModal()}
                              openModel={() => this.openTeamModal()}
                              selection={this._teamSelection}
                              actionButtonState={this.state.teamActionButtonState}
                              title={i18n.t('team')}
                              deleteDataGrid={() => {
                                this.deleteTeamMembers();
                              }}
                              readOnly={false}
                              columns={this.teamColumns}
                              isLoaded={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="proj-detail-block" id="4">
                  <div className="document-pane-card">
                    <div className={'card-header'}>
                      <Link href="#" id="finance">
                        <Label>4. {t('finance')}</Label>
                      </Link>
                    </div>
                    <div className={'card-body'}>
                      <div className="proj-detail-content inner" style={{ marginBottom: 50 }}>
                        <Label className={'sub-header-title'}>{4.1 + ' ' + i18n.t('projectFinanceStatus')}</Label>
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <DefaultNumberFormat
                              label={i18n.t('invoiced')}
                              value={this.state.invoiced}
                              errorMessage={this.state.invoicedErrorMsg}
                              onChange={(value) => {
                                this.setState(
                                  {
                                    invoiced: value,
                                    invoicedErrorMsg: '',
                                    focusedInput: 'invoiced',
                                    onchange: true,
                                  },
                                  this.checkBudgetValidation,
                                );
                              }}
                            />
                          </div>
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <DefaultNumberFormat
                              label={i18n.t('paid')}
                              value={this.state.paid}
                              errorMessage={this.state.paidErrorMsg}
                              onChange={(value) => {
                                this.setState(
                                  {
                                    paid: value,
                                    paidErrorMsg: '',
                                    focusedInput: 'paid',
                                    onchange: true,
                                  },
                                  this.checkBudgetValidation,
                                );
                              }}
                            />
                          </div>
                        </div>
                        <Label className={'sub-header-title'}
                               style={{ marginTop: 10 }}>{4.2 + ' ' + i18n.t('projectFinanceBudget')}</Label>
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <TextField
                              min={0}
                              type={'number'}
                              label={i18n.t('totalBudget')}
                              value={this.state.totalBudget}
                              errorMessage={this.state.totalBudgetErrorMsg}
                              onChange={(event, value) => {
                                this.setState(
                                  {
                                    totalBudget: value,
                                    totalBudgetErrorMsg: '',
                                    focusedInput: 'totalBudget',
                                    onchange: true,
                                  },
                                  this.checkBudgetValidation,
                                );
                              }}
                            />
                          </div>
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            {this.props.projectCurrency ? (
                              <Dropdown
                                placeholder={i18n.t('selectanoption')}
                                label={i18n.t('currency')}
                                required={true}
                                errorMessage={this.state.currencyErrorMsg}
                                options={this.props.projectCurrency}
                                selectedKey={
                                  this.state.currency
                                    ? this.state.projectCurrency.key
                                    : ''
                                }
                                styles={dropdownStyles}
                                onChange={(event, value) => {
                                  this.setState(
                                    {
                                      currency: value,
                                      currencyErrorMsg: '',
                                      onchange: true,
                                    },
                                    this.props.handleProjectFromChange,
                                  );
                                }}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <TextField
                              min={0}
                              type={'number'}
                              label={i18n.t('budgetMaterial')}
                              value={this.state.budgetMaterial}
                              errorMessage={this.state.budgetMaterialErrorMsg}
                              onChange={(event, value) => {
                                this.setState(
                                  {
                                    budgetMaterial: value,
                                    budgetMaterialErrorMsg: '',
                                    focusedInput: 'budgetMaterial',
                                    onchange: true,
                                  },
                                  this.checkBudgetValidation,
                                );
                              }}
                            />
                          </div>
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <TextField
                              min={0}
                              type={'number'}
                              label={i18n.t('budgetLabour')}
                              value={this.state.budgetLabour}
                              errorMessage={this.state.budgetLabourErrorMsg}
                              onChange={(event, value) => {
                                this.setState(
                                  {
                                    budgetLabour: value,
                                    budgetLabourErrorMsg: '',
                                    focusedInput: 'budgetLabour',
                                    onchange: true,
                                  },
                                  this.checkBudgetValidation,
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="proj-detail-block" id="5">
                  <div className="document-pane-card">
                    <div className={'card-header'}>
                      <Link href="#" id="time">
                        <Label>5. {t('date')} </Label>
                      </Link>
                    </div>
                    <div className={'card-body'}>
                      <div className="proj-detail-content inner" style={{ marginBottom: 50 }}>
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <CustomDatePicker
                              setMinDate={null}
                              setMaxDate={
                                this.state.selectedProjectId
                                  ? null
                                  : this.state.expectedEndDate
                              }
                              label={i18n.t('startDate')}
                              setValue={this.state.startDate}
                              getValue={(date: Date) => {
                                this.setState(
                                  {
                                    startDate: date,
                                    startDateErrorMsg: '',
                                    onchange: true,
                                  },
                                  this.props.handleProjectFromChange,
                                );
                              }}
                            />
                          </div>
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <CustomDatePicker
                              setMinDate={this.state.startDate}
                              setMaxDate={this.state.expectedEndDate}
                              label={i18n.t('endDate')}
                              setValue={this.state.endDate}
                              getValue={(date: Date) => {
                                this.setState(
                                  {
                                    endDate: date,
                                    endDateErrorMsg: '',
                                    onchange: true,
                                  },
                                  this.props.handleProjectFromChange,
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <CustomDatePicker
                              setMinDate={this.state.startDate}
                              setMaxDate={null}
                              label={i18n.t('expectedEndDate')}
                              setValue={this.state.expectedEndDate}
                              getValue={(date: Date) => {
                                this.setState(
                                  {
                                    expectedEndDate: date,
                                    expectedEndDateErrorMsg: '',
                                    onchange: true,
                                  },
                                  this.props.handleProjectFromChange,
                                );
                              }}
                            />
                          </div>
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="proj-detail-block" id="6">
                  <div className="document-pane-card">
                    <div className={'card-header'}>
                      <Link href="#" id="kpi">
                        <Label>6. {t('kPIS')} </Label>
                      </Link>
                    </div>
                    <div className={'card-body'}>
                      <div className="proj-detail-content inner" style={{ marginBottom: 50 }}>
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <TextField
                              label={i18n.t('label1Name')}
                              value={
                                this.state.label_1_name
                                  ? i18n.t(this.state.label_1_name)
                                  : ''
                              }
                              onChange={(event, value) => {
                                this.setState(
                                  { label_1_name: value, onchange: true },
                                  this.props.handleProjectFromChange,
                                );
                              }}
                            />
                          </div>
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <TextField
                              type={'number'}
                              label={i18n.t('label1Value')}
                              value={
                                this.state.label_1_value
                                  ? this.state.label_1_value
                                  : ''
                              }
                              onChange={(event, value) => {
                                this.setState(
                                  { label_1_value: value, onchange: true },
                                  this.props.handleProjectFromChange,
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <TextField
                              label={i18n.t('label2Name')}
                              value={
                                this.state.label_2_name
                                  ? i18n.t(this.state.label_2_name)
                                  : ''
                              }
                              onChange={(event, value) => {
                                this.setState(
                                  { label_2_name: value, onchange: true },
                                  this.props.handleProjectFromChange,
                                );
                              }}
                            />
                          </div>
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <TextField
                              type={'number'}
                              label={i18n.t('label2Value')}
                              value={
                                this.state.label_2_value
                                  ? this.state.label_2_value
                                  : ''
                              }
                              onChange={(event, value) => {
                                this.setState(
                                  { label_2_value: value, onchange: true },
                                  this.props.handleProjectFromChange,
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <TextField
                              label={i18n.t('label3Name')}
                              value={
                                this.state.label_3_name
                                  ? i18n.t(this.state.label_3_name)
                                  : ''
                              }
                              onChange={(event, value) => {
                                this.setState(
                                  { label_3_name: value, onchange: true },
                                  this.props.handleProjectFromChange,
                                );
                              }}
                            />
                          </div>
                          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <TextField
                              type={'number'}
                              label={i18n.t('label3Value')}
                              value={
                                this.state.label_3_value
                                  ? this.state.label_3_value
                                  : ''
                              }
                              onChange={(event, value) => {
                                this.setState(
                                  { label_3_value: value, onchange: true },
                                  this.props.handleProjectFromChange,
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="proj-detail-block" id="7">
                  <div className="document-pane-card">
                    <div className={'card-header'}>
                      <Link href="#" id="cost">
                        <Label>7. {t('projectCost')}</Label>
                      </Link>
                    </div>
                    <div className={'card-body'}>
                      <div className="proj-detail-content inner" style={{ marginBottom: 30 }}>
                        {/* <Label className="marginTop">
                      6.1 {i18n.t('travelConvertion')}{' '}
                    </Label> */}
                        <Stack>
                          <div style={{ marginBottom: 50 }}>
                            <ChoiceGroup
                              options={this.travelConvertionOptions}
                              onChange={this._onChangeTravelConvertion}
                              label={7.1 + ' ' + i18n.t('travelConvertion')}
                              selectedKey={this.state.projectCostConversionCreateDto ? this.state.projectCostConversionCreateDto.travelConversionOption!! : ''}
                              required={false}
                              styles={{
                                flexContainer: {
                                  display: 'flex',
                                  selectors: {
                                    '.ms-ChoiceField': {
                                      marginLeft: 10,
                                      marginRight: 10,
                                      width: 250,
                                    },
                                  },
                                },
                                root: { padding: 10, marginLeft: 10 },
                              }}
                              disabled={false}
                            />
                            {!_.isEmpty(this.state.travelConvertionErrorMsg) && (
                              <Text
                                styles={{
                                  root: {
                                    color: '#a4262c',
                                    fontSize: 12,
                                    marginLeft: 100,
                                  },
                                }}
                              >
                                {this.state.travelConvertionErrorMsg}
                              </Text>
                            )}

                            <div>
                              <Stack
                                horizontal
                                styles={this.stackStyles}
                                tokens={this.numericalSpacingStackTokens}
                              >
                          <span style={this.itemStyles}>
                          <Label>{t('noConvertion')}</Label>

                          </span>
                                <span style={this.itemStyles}>
                          <Label style={{ marginLeft: 5 }}>{t('timeTowork')}</Label>
                          </span>
                                <span style={this.itemStylesv}>
                           <Label style={{ marginLeft: 10 }}>{t('timeTotravelTime')}</Label>
                           <Label style={{ marginLeft: 10 }}>{t('distanceToroutingCost')}</Label>
                          </span>
                              </Stack>
                            </div>

                          </div>
                        </Stack>
                        <Stack>
                          <div>
                            <ChoiceGroup
                              options={this.loadingConvertionOptions}
                              onChange={this._onChangeLoadingConvertion}
                              label={7.2 + ' ' + i18n.t('unloadingConvertion')}
                              selectedKey={this.state.projectCostConversionCreateDto ? this.state.projectCostConversionCreateDto.loadingConversionOption!! : ''}
                              required={false}
                              styles={{
                                flexContainer: {
                                  display: 'flex',
                                  selectors: {
                                    '.ms-ChoiceField': {
                                      marginLeft: 10,
                                      marginRight: 10,
                                      width: 200,
                                    },
                                  },
                                },
                                root: { padding: 10, marginLeft: 10 },
                              }}
                              disabled={false}
                            />
                            {!_.isEmpty(this.state.travelConvertionErrorMsg) && (
                              <Text
                                styles={{
                                  root: {
                                    color: '#a4262c',
                                    fontSize: 12,
                                    marginLeft: 100,
                                  },
                                }}
                              >
                                {this.state.travelConvertionErrorMsg}
                              </Text>
                            )}
                            <div>
                              <Stack
                                horizontal
                                styles={this.stackStyles}
                                tokens={this.numericalSpacingStackTokens}
                              >
                          <span style={this.itemStyles}>
                          <Label>{t('noConvertion')}</Label>

                          </span>
                                <span style={this.itemStyles}>
                          <Label style={{ marginLeft: -10 }}>{t('unloadingTowork')}</Label>
                          </span>
                              </Stack>
                            </div>
                          </div>
                        </Stack>
                        <div style={{ marginLeft: 18 }}>
                          <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">

                              <Dropdown
                                placeholder={i18n.t('selectanoption')}
                                label={7.3 + ' ' + i18n.t('generalLedger')}
                                errorMessage={''}
                                options={this.props.ledgerDropDowns}
                                selectedKey={
                                  this.state.genaralLedger
                                }
                                styles={dropdownStyles}
                                onChange={(event, value) => {
                                  this.setState(
                                    {
                                      genaralLedger: value?.key,
                                      // toleranceStateErrorMsg: '',
                                      onchange: true,
                                    }, () => {
                                      this.props.handleProjectFromChange;
                                    },
                                  );
                                }}
                              />

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                {/*history bar */}
                <div className="proj-detail-block" id="8">
                  {this.state.selectedProjectId != 0 && this.props.selectedProject.projectHistory && (
                    <div className="document-pane-card">
                      <div className={'card-header'}>
                        <Link href="#" id="history">
                          <Label>8. {t('history')} </Label>
                        </Link>
                      </div>
                      <div className="proj-detail-content inner marginBottom">

                        <HistoryBar
                          createdByUser={
                            this.props.selectedProject.projectHistory
                              .createdByUser
                          }
                          updatedByUser={
                            this.props.selectedProject.projectHistory
                              .updatedByUser
                          }
                          createdDateTime={
                            this.props.selectedProject.projectHistory
                              .createdDateTime
                          }
                          updatedDateTime={
                            this.props.selectedProject.projectHistory
                              .updatedDateTime
                          }
                        />

                      </div>


                      {/* <div className="proj-detail-block" style={{marginBottom:50}}>
                <PrimaryButton
                  disabled={this.state.saveBtnDisabled}
                  text={i18n.t('save')}
                  onClick={() => this.handleFormSubmit()}
                  allowDisabledFocus
                  style={{ marginTop: 20, marginBottom: 10 }}
                />
              </div> */}
                    </div>
                  )}
                </div>
              </div>

            </Stack>
            {this.renderBookmarkPane()}
          </Stack>
          <TeamModal
            isOpen={this.state.isOpenTeamModal}
            openModal={() => this.openTeamModal()}
            isEdit={this.state.isMemberEdit}
            onFilterPersonChanged={this.onFilterPersonChanged}
            createTeam={(member: TeamMember) => this.onAddTeamMember(member)}
            teamMember={this.state.teamMember}
            userRoles={this.getUserRoles()}
          />
          <MapModal
            isOpen={this.state.isMapOpen}
            openMapModal={() => this.openMapModal()}
            setLocationAddress={(pos: Position) => {
              this.setLocationAddress(pos);
            }}
            position={this.state.position}
          />
        </div>
      );
    } else {
      return <div>{this.renderShimmer()}</div>;
    }
  }

  openMapModal = () => {
    this.setState({ isMapOpen: !this.state.isMapOpen });
  };

  private _toggleBookmark = (): void => {
    this.setState({ divClass: 'bookmark-panel parent panel-on' });
  };

  private _toggleBookmarkOut = (): void => {
    this.setState({ divClass: 'bookmark-panel parent' });
  };
}

export default withRouter(ProjectForm);

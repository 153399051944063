import React, {useContext, useEffect, useState} from 'react';
import {AzureMapsContext, IAzureMapsContextProps} from 'react-azure-maps';
import {data, layer, source} from 'azure-maps-control';
import MapComponent from './mapComponent';
import {MapRouteData} from "../../../types/timeRegistration";

interface Props {
    defaultPoint: any,
    mapRouteData: MapRouteData,
};

// https://learn.microsoft.com/en-us/azure/azure-maps/tutorial-route-location
const dataSourceRef = new source.DataSource();
// const layerRef = new layer.SymbolLayer(dataSourceRef);
const symbolLayerRef = new layer.SymbolLayer(dataSourceRef, undefined, {
    iconOptions: {
        image: ['get', 'icon'],
        allowOverlap: true
    },
    textOptions: {
        textField: ['get', 'title'],
        offset: [0, 1.2]
    },
    filter: ['any', ['==', ['geometry-type'], 'Point'], ['==', ['geometry-type'], 'MultiPoint']] //Only render Point or MultiPoints in this layer.
});
const lineLayerRef = new layer.LineLayer(dataSourceRef, undefined, {
    // strokeColor: '#2272B9',
    strokeWidth: 5,
    lineJoin: 'round',
    lineCap: 'round'
});

const MapController = (props: Props) => {
    const {defaultPoint, mapRouteData} = props;

    // Here you use mapRef from context
    const {mapRef, isMapReady} = useContext<IAzureMapsContextProps>(AzureMapsContext);
    const [showTileBoundaries, setShowTileBoundaries] = useState(true);

    useEffect(() => {
        if (mapRef) {
            mapRef.setCamera({
                center: [defaultPoint.lat, defaultPoint.lon],  //center: [Latitude, Longitude]
                zoom: 6
            });
        }
    }, []);

    useEffect(() => {
        if (mapRef) {
            // Simple Style modification
            mapRef.setStyle({showTileBoundaries: !showTileBoundaries});
        }
    }, [showTileBoundaries]);

    useEffect(() => {
        if (mapRef) {
            if (mapRouteData.locations.length > 0) {
                const routeStartPoint = new data.Feature(new data.Point(mapRouteData.locations[0]), {
                    title: "Redmond",
                    icon: "pin-blue"
                });
                const routeEndPoint = new data.Feature(new data.Point(mapRouteData.locations.slice(-1)[0]), {
                    title: "Seattle",
                    icon: "pin-round-blue"
                });

                const points = new data.Feature(new data.LineString(mapRouteData.locations));

                dataSourceRef.add(points);
                mapRef.setCamera({
                    bounds: data.BoundingBox.fromData([routeStartPoint, routeEndPoint]),
                    padding: 80
                });

                // if (service) {
                //     const pipeLine = service.MapsURL.newPipeline(new service.MapControlCredential(mapRef));
                //     const routeURL = new service.RouteURL(pipeLine);
                //
                //     //Start and end point input to the routeURL
                //     const coordinates = [[routeStartPoint.geometry.coordinates[0], routeStartPoint.geometry.coordinates[1]], [routeEndPoint.geometry.coordinates[0], routeEndPoint.geometry.coordinates[1]]];
                //     //Make a search route request
                //     routeURL.calculateRouteDirections(service.Aborter.timeout(10000), coordinates).then((directions: any) => {
                //         //Get data features from response
                //         const data = directions.geojson.getFeatures();
                //         dataSourceRef.add(data);
                //     });
                // }
            } else {
                // Simple Camera options modification
                mapRef.setCamera({
                    center: [defaultPoint.lat, defaultPoint.lon],  //center: [Latitude, Longitude]
                    zoom: 6
                });
            }

            updateLineLayer();
        }
    }, [mapRouteData]);


    useEffect(() => {
        if (isMapReady && mapRef) {
            // Need to add source and layer to map on init and ready
            mapRef.sources.add(dataSourceRef);
            //Add a layer for rendering point data.
            mapRef.layers.add(symbolLayerRef);
            //Add a layer for rendering the route lines and have it render under the map 'labels'.
            mapRef.layers.add(lineLayerRef, 'labels');
        }
    }, [isMapReady]);

    const updateLineLayer = () => {
        const options = getInputOptions();

        //Update all the options in the line layer.
        lineLayerRef.setOptions(options);
    };

    const getInputOptions = () => {
        return {
            strokeColor: mapRouteData.color
        }
    };

    return (
      <>
          <MapComponent/>
      </>
    );
};

// Some styles
const styles = {
    buttonContainer: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridGap: '10px',
        gridAutoColumns: 'max-content',
        padding: '10px 0',
        alignItems: 'center',
    },
    button: {
        height: 35,
        width: 80,
        backgroundColor: '#68aba3',
    },
};

export default MapController;
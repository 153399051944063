import * as React from 'react';
import { useEffect } from 'react';
import { Tag, TagGroup, TagGroupProps } from '@fluentui/react-tags-preview';
import {
  Button,
  Input,
  Label,
  makeStyles,
  Popover,
  PopoverProps,
  PopoverSurface,
  PopoverTrigger,
} from '@fluentui/react-components';
import { AddCircle24Regular } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';

// Initial set of tags to be displayed
const initialTags = [
  { value: '1', children: 'Tag 1' },
  { value: '2', children: 'Tag 2' },
  { value: '3', children: 'Tag 3' },
];

// CSS styles
const useStyles = makeStyles({
  contentHeader: {
    marginTop: '0',
  },
});

/**
 * Tags Component
 *
 * This component allows users to manage tags, add new tags, and remove existing tags.
 *
 * @param {object} props - Component props
 * @param {array} props.value - An array of tags.
 * @param {function} props.onChange - A function to be called when tags are added or removed.
 */
const Tags = ({ value, onChange }: any) => {
  // Localization
  const { t } = useTranslation();

  // State variables
  const [visibleTags, setVisibleTags]: any = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [inputTag, setInputTag]: any = React.useState({ id: null, key: '', name: '' });

  /**
   * Remove a tag from the visible tags and trigger the onChange function to update the parent component's state.
   *
   * @param {object} _e - Event object
   * @param {object} dismissedTagValue - Value of the dismissed tag
   */
  const removeItem: TagGroupProps['onDismiss'] = (_e, { dismissedTagValue }) => {
    let tags = [...visibleTags].filter((tag: any) => tag.key !== dismissedTagValue);
    setVisibleTags(tags);
    onChange(formatTagForValue(tags));
  };

  // Functions for formatting tags
  const formatValueForTag = () => {
    if (value && value?.length > 0) {
      return value?.map((tag: any) => {
        return { ...tag, key: tag?.id ?? tag?.name };
      });
    } else {
      return [];
    }
  };
  const formatTagForValue = (tags: any) => {
    if (tags && tags?.length > 0) {
      return tags?.map((tag: any) => {
        return { id: tag?.id, name: tag?.name };
      });
    } else {
      return [];
    }
  };

  // useEffect(() => {
  //  setVisibleTags(formatValueForTag());
  // }, []);

  useEffect(() => {
    // onChange(formatTagForValue())
  }, [visibleTags]);

  useEffect(() => {
    setVisibleTags(formatValueForTag());
  }, [value]);


  const changeContent = () => setVisible(true);

  /**
   * Handle changes in the visibility of the popover.
   *
   * @param {object} _e - Event object
   * @param {object} data - Data object containing the 'open' property
   */
  const onOpenChange: PopoverProps['onOpenChange'] = (_e, data) => {
    if (data.open === false) {
      setVisible(false);
    }
  };

  return (
    // JSX code for rendering the component
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ marginTop: 10, marginRight: 10 }}><Label size='medium' weight='semibold'> {t('tags')} </Label></div>
        <TagGroup style={{ marginTop: 5, maxWidth: '100%', overflow: 'overlay' }} onDismiss={removeItem}
                  aria-label='Dismiss example'>
          {visibleTags.map((tag: any) => (
            <Tag dismissible dismissIcon={{ 'aria-label': 'remove' }} value={tag.key} key={tag.key}>
              {tag?.name}
            </Tag>
          ))}
        </TagGroup>

        <Popover open={visible} onOpenChange={onOpenChange}>
          <PopoverTrigger disableButtonEnhancement>
            <AddCircle24Regular onClick={() => setVisible(true)} style={{ marginTop: 10, marginLeft: 10 }} />
          </PopoverTrigger>

          <PopoverSurface>
            <div style={{ width: '100%' }}>
              <Input
                placeholder={t('tagName')}
                onChange={(_e, data) => {
                  setInputTag({ id: null, key: data?.value, name: data?.value });
                }}
              />
            </div>
            <div style={{ marginTop: 10, float: 'right' }}>
              <Button
                onClick={() => {
                  // visibleTags.push(inputTag);
                  setVisibleTags([...visibleTags, inputTag]);
                  setVisible(false);
                  onChange(formatTagForValue([...visibleTags, inputTag]));
                  setInputTag({ id: null, key: '', name: '' });
                }}
                disabled={inputTag && inputTag.name === ''}
              >
                {t('add')}
              </Button>
            </div>
          </PopoverSurface>
        </Popover>
      </div>
    </>
  );

};

export default Tags;

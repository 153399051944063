import {
  ActionButton,
  DetailsListLayoutMode,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  ShimmeredDetailsList,
  Stack,
  Sticky,
  StickyPositionType,
} from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../theme';
import { ActionButtonState } from '../../../types/corporateProductCatalogue';

const theme = getTheme();

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const removeIcon: IIconProps = {
  iconName: 'Delete',
  styles: iconButtonStyles,
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const DataGrid = (props: {
  dataList: any[];
  editDataGrid: () => void;
  openModel: (type: any) => void;
  title: string;
  link: string;
  id: string;
  readOnly: boolean;
  columns: any[];
  isLoaded: boolean;
  selection: any;
  actionButtonState: ActionButtonState;
  saveFormData: () => void;
  deleteDataGrid: () => void;
}) => {
  const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };
  const editIcon: IIconProps = { iconName: 'Edit', styles: iconButtonStyles };
  const { t } = useTranslation();
  const classNames = mergeStyleSets({
    wrapper: {
      //height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
      minHeight: (props.dataList && props.dataList.length > 1) ? 200 : 110,
    },
    pane: {
      maxWidth: 400,
    },
    textContent: {
      padding: '15px 10px',
    },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: {
        '@media(max-width: 1100px)': {
          textAlign: 'start',
        },
      },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
  });


  const stackStyles: IStackStyles = {
    root: {
      padding: 0,
    },
  };
  const stackItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 40,
      alignItems: 'flex-start',
    },
  };

  const stackEditbarItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 45,
      alignItems: 'flex-end',
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 15,
    padding: 10,
  };

  return (
    <div className={'document-pane-card'}>
      <div className={'card-header'}>
        <div className="sub-header-title">
          <Stack
            horizontal
            horizontalAlign="space-between"
            styles={stackStyles}
            tokens={stackTokens}
          >
            <Stack.Item styles={stackItemStyles}>
              {/*<Link style={{borderBottom:'none'}} href="#" id={props.link} >*/}
              {/*<Label>{props.id}. {props.title} </Label>*/}
              {/*</Link>*/}
              <Label
                id={props.link}
                style={{ marginTop: 10, fontSize: '1.1rem' }}
              >
                {props.id}. {props.title}
              </Label>
            </Stack.Item>
            {!props.readOnly && (
              <Stack.Item styles={stackEditbarItemStyles}>
                <ActionButton
                  className={classNames.actionButton}
                  disabled={props.actionButtonState.add}
                  iconProps={addIcon}
                  allowDisabledFocus
                  onClick={() => {
                    props.openModel('1');
                  }}
                >
                  <Label className={classNames.actionButton}>{t('add')} </Label>
                </ActionButton>
                <ActionButton
                  iconProps={editIcon}
                  disabled={props.actionButtonState.edit}
                  allowDisabledFocus
                  onClick={() => {
                    props.openModel('1');
                  }}
                >
                  <Label className={classNames.actionButton}> {t('edit')}</Label>
                </ActionButton>
                <ActionButton
                  iconProps={removeIcon}
                  disabled={props.actionButtonState.remove}
                  allowDisabledFocus
                  onClick={() => props.deleteDataGrid()}
                >
                  <Label className={classNames.actionButton}>
                    {' '}
                    {t('remove')}
                  </Label>
                </ActionButton>
              </Stack.Item>
            )}
          </Stack>
        </div>
      </div>

      <div className={'card-body'}>
        <div className="proj-detail-block" id={props.id}>
          <div className="marginTop">
            <div style={{ padding: 12 }}>
              <div className="ms-Grid-row marginTop">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  <div className={classNames.wrapper}>
                    <div className={'scrollablePane'}>
                      <ShimmeredDetailsList
                        items={props.dataList}
                        columns={props.columns}
                        setKey="set"
                        enableShimmer={props.isLoaded}
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionPreservedOnEmptyClick={true}
                        checkboxVisibility={1}
                        selection={props.selection}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="Row checkbox"
                        onRenderDetailsHeader={(headerProps, defaultRender) => {
                          if (defaultRender !== undefined) {
                            return (
                              <Sticky
                                stickyPosition={StickyPositionType.Header}
                                isScrollSynced={true}
                                stickyBackgroundColor="transparent"
                              >
                                <div>{defaultRender(headerProps)}</div>
                              </Sticky>
                            );
                          } else {
                            return <span></span>;
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'card-footer'}>
        {/*<Separator />*/}
        {/*<Stack styles={stackStyles} tokens={itemAlignmentsStackTokens}>*/}
        {/*<Stack.Item align="end" styles={stackFooterBarItemStyles}>*/}
        {/*<ActionButton*/}
        {/*className={classNames.actionButton}*/}
        {/*iconProps={saveIcon}*/}
        {/*allowDisabledFocus*/}
        {/*onClick={() => {*/}
        {/*props.saveFormData();*/}
        {/*}}*/}
        {/*>*/}
        {/*<Label className={classNames.actionButton}>{t('save')} </Label>*/}
        {/*</ActionButton>*/}
        {/*</Stack.Item>*/}
        {/*</Stack>*/}
      </div>
    </div>
  );
};

export default DataGrid;

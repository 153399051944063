import { useTranslation } from 'react-i18next';
import {
  ContextualMenu,
  FontWeights,
  getTheme,
  IconButton,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import _ from 'lodash';
import { BorResourcesItem } from '../../../../types/billOfResources';


import { uPrinceTheme } from '../../../../../theme';
import GroupDropdown from '../../../../shared/groupDropdown/groupDropdown';

const theme = getTheme();


const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
});

const stackStyles: IStackStyles = { root: { padding: 0 } };
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};


const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: { color: uPrinceTheme.palette.white },
  rootHovered: { color: theme.palette.neutralDark },
};

const addIconWhite: IIconProps = {
  iconName: 'Save',
  styles: addWhiteIconButtonStyles,
};
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    paddingTop: 20,
    overflowY: 'hidden',
    minWidth: '40vw',
    // minHeight: '32vh',
    minHeight: screen.width > 1280 ? '42vh' : '60vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'none',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: { color: theme.palette.neutralDark },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};


const RequiredModal = (props: {
  isEdit: boolean;
  resource: BorResourcesItem;
  isOpen: boolean;
  openModal: () => void;
  handleResourceSave: (quantity: string | number, cpc: any) => void;
  handleCpcChange: (selectedCpc: any) => void;
  title: string;
  type: any;
  cpcOptions: any;
  cpcData: any;
  clearCpcData: () => void;
  isBorReturn: boolean;
  //resourceType:string
}) => {
  const { t } = useTranslation();
  const [isDraggable] = useBoolean(true);
  const titleId = useId('title');

  const [quantity, setQuantity]: any = useState(props.resource ? props.resource.required : '');
  const [cpc, setCpc]: any = useState(props.resource && props.resource.resourceNumber ? {
    value: props.resource.resourceNumber,
    label: props.resource.title,
    environment: props.resource.environment,
  } : null);
  const [nameErrorMsg, setNameErrorMsg]: any = useState(null);
  const [quantityErrorMsg, setQuantityErrorMsg]: any = useState(null);


  const onChangeInput = () => (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setQuantity(newValue);
    setQuantityErrorMsg(null);
  };

  const onSave = () => {
    if (validate()) {
      props.handleResourceSave(quantity, cpc);
      onModalClose();
    } else {
      // alert(JSON.stringify(cpc));
    }
  };


  const validate = (): boolean => {
    let isValid = true;
    if (_.isNil(cpc) || _.isEmpty(cpc)) {
      setNameErrorMsg(t('titleRequired'));
      isValid = false;
    } else {
      setNameErrorMsg('');
    }
    if (!quantity) {
      // setQuantityErrorMsg(JSON.stringify(_.isEmpty(quantity)));
      setQuantityErrorMsg(t('quantityrequired'));
      isValid = false;
    } else {
      setQuantityErrorMsg('');
    }
    return isValid;
  };

  const resetValidation = () => {
    setNameErrorMsg(null);
    setQuantityErrorMsg(null);
  };

    useEffect(() => {
        if(cpc) {
            props.handleCpcChange(cpc);
        }
    }, [ cpc ]);

  useEffect(() => {
    if (!props.isEdit) {
      props.clearCpcData();
      setCpc(null);
      setQuantity('');
    }
  }, [props.isEdit]);

  useEffect(() => {
    if (props.resource) {
      if (props.resource.resourceNumber) {
        const resource = {
          value: props.resource.resourceNumber,
          label: props.resource.title,
          environment: props.resource.environment,
        };
        setCpc(resource);
      } else if (props.resource.id) {
        const resource = {
          value: props.resource.id,
          label: props.resource.title,
          environment: props.resource.environment,
        };
        setCpc(resource);
      }
      setQuantity(props.resource.required);
    }
  }, [props.resource]);

  const onModalClose = () => {
    if (!props.isEdit) {
      setQuantity('');
      setCpc(null);
    }
    props.openModal();
    resetValidation();
  };

  return (

    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          onModalClose();
        }}
        scrollableContentClassName="body"
        isBlocking={false}
        dragOptions={undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>{props.title}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              onModalClose();
            }}
          />
        </div>
        <div className={contentStyles.subHeader}>

        </div>
        <div className={contentStyles.body}>
          <div className="proj-detail-content inner">
            <div className="ms-Grid-row">
              { /* {JSON.stringify(props.resource)}*/}
              { /* {JSON.stringify(props.cpcData)}*/}
              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    { /* {JSON.stringify(cpc)}*/}
                    { /* {JSON.stringify(quantity)}*/}


                    {props.isEdit ?
                      <TextField
                        label={t('resourceTitle')}
                        value={(props.resource.title) ? props.resource.title : ''}
                        disabled={true}
                      /> :
                      <div><Label required={true}>{t('resourceTitle')} </Label>
                        <GroupDropdown
                          onChange={(key: string, text: string, environment: string, item: any) => {
                            if (key) {
                              const resource = { value: item.id, label: text, environment: environment, item: item };
                              setCpc(resource);
                              setNameErrorMsg(null);
                            } else {
                              props.clearCpcData();
                              setCpc(null);
                            }
                            // alert(key);
                          }}
                          selectedOption={(cpc) ? {
                            value: cpc.value,
                            label: cpc.label,
                          } : null}
                          promiseOptions={props.cpcOptions}
                          validationMessage={nameErrorMsg}
                          required={true}
                          reference={1}
                        />
                      </div>
                    }

                  </div>
                </Stack.Item>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('resourceType')}
                      value={(props.cpcData) ? props.cpcData.resourceType : ''}
                      readOnly={true}
                    />
                  </div>
                </Stack.Item>
              </Stack>

              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={!props.isBorReturn ? t('resourceRequiredQuantity') : t('returnQuantity')}
                      value={quantity}
                      errorMessage={quantityErrorMsg}
                      type={'number'}
                      onChange={onChangeInput()}
                    />
                  </div>
                </Stack.Item>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('basicunitofMeasureMOU')}
                      value={(props.cpcData) ? props.cpcData.basicUnitOfMeasure : ''}
                      readOnly={true}
                    />
                  </div>
                </Stack.Item>
              </Stack>
            </div>
          </div>
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={addIconWhite}
            text={t('save')}
            style={{ marginTop: 20, marginBottom: 30 }}
            onClick={() => {
              onSave();
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default RequiredModal;

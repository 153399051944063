import {
  ActionButton,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Separator,
  Stack,
} from '@fluentui/react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { uPrinceTheme } from '../../../../theme';
import CustomFileUploader from '../../../shared/fileUploader/customFileUploader';
import { CKEditorWrapper, DropDownAdaptater, TextFieldFF } from '../../../shared/fluentFinalForm';
import { formatDateTimeToISOString, getImages, getProject } from '../../../shared/util';
import { MileStones } from '../../../types/mileStones';
import HistoryBar from '../../../shared/historyBar/historyBar';
import { useTranslation } from 'react-i18next';
import PeoplePicker from '../../../shared/fluentFinalForm/PeoplePicker';
import TaxonomyField from '../../ProjectBreakdownStructure/taxonomy/taxonomy';
import CurrencyNumberFormatField from '../../../shared/fluentFinalForm/CurrencyNumberFormatField ';
import MachineTaxonomyField from './taxonomy/taxonomy';
import DatePickerRequiredAdapter from '../../../shared/fluentFinalForm/DatePickerRequiredAdapter';

const theme = getTheme();

const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };

const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
});

// const required = (value: any) => (value ? undefined : 'Required');

const validate = (values: { company: any; gender: any; email: any }) => {
  const errors: { company: string; gender: string; email: string } = {
    company: '',
    gender: '',
    email: '',
  };
  if (!values.company) {
    errors.company = 'Company Required';
  }
  if (!values.gender) {
    errors.gender = 'Gender Required';
  }
  if (!values.email) {
    errors.email = 'Email Required';
  }
  return errors;
};

const DocumentPaneComponent = (props: {
  submit: any;
  dropDowns: any;
  saveFormData: any;
  uid: any;
  showProgress: boolean;
  formValues: any;
  formData: any;
  isExpand: boolean;
  setForm: (form: any) => void;
  isClear: boolean;
  dropDownOptions: any;
  onFilterTaxonomyNodeChanged: any;
}) => {
  const { t } = useTranslation();
  const [state, setstate] = useState<MileStones | null>(null);

  const onSubmit = async (values: any) => {
    const sleep = (ms: number | undefined) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);
    values.projectSequenceCode = getProject();
    if (!values.id) {
      values.id = props.uid;
    }
    props.saveFormData(values);
  };

  useEffect(() => {
    setstate(props.formData);

  }, [props.formData]);


  const getInitialFormValues = (formData: any, formValues: any) => {
    if (location.pathname.split('/').pop() === 'new' && !formData?.sequenceId || props.isClear) {
      return {};
    } else if (formData && formData?.id) {
      return {
        id: formData?.id,
        sequenceId: formData?.sequenceId,
        title: formData?.title,
        name: formData?.name,
        type: formData?.type?.key,
        status: formData?.status?.key,
        abstract: formData?.abstract,
        fullDescription: formData?.fullDescription,
        utilityTaxonomy: formData?.utilityTaxonomy,
        locationTaxonomy: formData?.locationTaxonomy,
        machineTaxonomy: formData?.machineTaxonomy,
        accountable: formData?.accountable,
        accountableId: formData?.accountableId,
        responsible: formData?.responsible,
        responsibleId: formData?.responsibleId,
        requestedStartDate: formData?.requestedStartDate,
        requestedEndDate: formData?.requestedEndDate,
        expectedStartDate: formData?.expectedStartDate,
        expectedEndDate: formData?.expectedEndDate,
        actualStartDate: formData?.actualStartDate,
        actualEndDate: formData?.actualEndDate,
        expectedAmount: formData?.expectedAmount,
        actualAmount: formData?.actualAmount,
        comments: formData?.comments,
        files: formData?.files,
      };
    }
    return formValues;
  };

  return (
    <div style={{ width: '100%' }}>
      <div className="proj-detail-block">
        <Form
          onSubmit={onSubmit}
          initialValues={getInitialFormValues(props.formData, props.formValues)}
          mutators={{
            setHeaderDocs: (args, state, utils) => {
              const field = state.fields['files'];
              field.change(args[0]);
            },
            setAccountable: (args, state, utils) => {
              const field = state.fields.accountable;
              field.change(args[0]);
            },
            setResponsible: (args, state, utils) => {
              const field = state.fields.responsible;
              field.change(args[0]);
            },
          }}
          validate={values => {
            const errors: any = {};
            if (!values?.name) {
              errors.name = t('nameRequired');
            }
            if (!values?.type) {
              errors.type = t('typeRequired');
            }
            if (!values?.expectedStartDate) {
              errors.expectedStartDate = t('dateRequired');
            }
            return errors;
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            props.submit(handleSubmit);
            props.setForm(form);

            let headerFiles = values.header?.files;
            return (
              <form onSubmit={handleSubmit} noValidate>
                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="milestones-header">
                      <Label>1. {t('milestonesHeader')}</Label>
                    </Link>
                  </div>
                  {state?.sequenceId && (
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="title"
                            component={TextFieldFF}
                            lable={t('title')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="sequenceId"
                            component={TextFieldFF}
                            lable={t('milestonesId')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>
                  )}

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="name"
                          component={TextFieldFF}
                          lable={t('name')}
                          required={true}
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="type"
                          component={DropDownAdaptater}
                          options={props.dropDowns.types}
                          lable={t('type')}
                          placeholder={t('type')}
                          disabled={false}
                          required={true}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        {/* <Field
                          name="status"
                          component={DropDownAdaptater}
                          options={props.dropDowns.status}
                          lable={t('status')}
                          placeholder={t('status')}
                          disabled={false}
                          required={ true }
                          /> */}
                        <Field
                          name="expectedStartDate"
                          component={DatePickerRequiredAdapter}
                          lable={t('expectedDeliveryDate')}
                          parse={(value) =>
                            value && formatDateTimeToISOString(value)
                          }
                          format={(value) => (value ? new Date(value) : null)}
                          // disabled={ true }
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="abstract"
                          component={TextFieldFF}
                          lable={t('abstract')}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>
                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <div className={`${classNames.fullWidth}`}>
                      <Label>{t('fullDescription')}</Label>

                      <Field
                        name="fullDescription"
                        component={CKEditorWrapper}
                        lable={t('fullDescription')}
                        placeholder={t('fullDescription')}
                        disabled={false}
                      />
                    </div>
                  </Stack>

                  <div className={'card-footer'}>
                    <Separator/>
                    <Stack
                      styles={stackStyles}
                      tokens={itemAlignmentsStackTokens}
                    >
                      <Stack.Item align="end" styles={stackFooterBarItemStyles}>
                        <ActionButton
                          className={classNames.actionButton}
                          iconProps={saveIcon}
                          allowDisabledFocus
                          onClick={() => {
                            // alert(JSON.stringify(values.purchaseOrderType, 0, 2));
                            form.submit();
                          }}
                          // disabled={ (props.dropDownOptions.isApproved) }
                        >
                          <Label className={classNames.actionButton}>
                            {t('save')}
                          </Label>
                        </ActionButton>
                      </Stack.Item>
                    </Stack>
                  </div>
                </div>

                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="milestones-taxonomy">
                      <Label>2. {t('milestonesTaxonomy')}</Label>
                    </Link>
                    {/* <Label>2.1. { 'Customer' }</Label> */}
                  </div>

                  <div className={'card-body'}>
                    <div className="proj-detail-content inner">
                      <div className={'ms-Grid'} dir="ltr">
                        <div className="marginTop" style={{ paddingLeft: 10, paddingRight: 10 }}>
                          <Field name="utilityTaxonomy">
                            {({ input, meta }) => (
                              <TaxonomyField
                                label={t('utilityTaxonomy')}
                                treeData={props.dropDownOptions.utilityTaxonomy}
                                selectItemId={values?.utilityTaxonomy
                                  ? values?.utilityTaxonomy
                                  : ''}
                                onSelectItem={(id: string) => {
                                  input.onChange(id);
                                  return id;
                                }}
                                treeLevel={props.dropDownOptions.utilityTaxonomyLevel}
                                onFilterTaxonomyNodeChanged={props.onFilterTaxonomyNodeChanged}
                              />
                            )}
                          </Field>
                        </div>

                        <div className="marginTop" style={{ paddingLeft: 10, paddingRight: 10 }}>
                          <Field name="locationTaxonomy">
                            {({ input, meta }) => (
                              <TaxonomyField
                                label={t('locationTaxonomy')}
                                treeData={props.dropDownOptions.locationTaxonomy}
                                selectItemId={values?.locationTaxonomy
                                  ? values?.locationTaxonomy
                                  : ''}
                                onSelectItem={(id: string) => {
                                  input.onChange(id);
                                  return id;
                                }}
                                treeLevel={props.dropDownOptions.locationTaxonomyLevel}
                                onFilterTaxonomyNodeChanged={props.onFilterTaxonomyNodeChanged}
                              />
                            )}
                          </Field>
                        </div>

                        <div className="marginTop" style={{ paddingLeft: 10, paddingRight: 10 }}>
                          <Field name="machineTaxonomy">
                            {({ input, meta }) => (
                              <MachineTaxonomyField
                                label={t('machineTaxonomy')}
                                treeData={props.dropDownOptions.machineTaxonomy}
                                selectItemId={values?.machineTaxonomy
                                  ? values?.machineTaxonomy
                                  : ''}
                                onSelectItem={(id: string) => {
                                  input.onChange(id);
                                  return id;
                                }}
                                treeLevel={props.dropDownOptions.machineTaxonomyLevel}
                                onFilterTaxonomyNodeChanged={null}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={'card-footer'}>
                    <Separator/>
                    <Stack
                      styles={stackStyles}
                      tokens={itemAlignmentsStackTokens}
                    >
                      <Stack.Item align="end" styles={stackFooterBarItemStyles}>
                        <ActionButton
                          className={classNames.actionButton}
                          iconProps={saveIcon}
                          allowDisabledFocus
                          onClick={() => {
                            // alert(JSON.stringify(values.purchaseOrderType, 0, 2));
                            form.submit();
                          }}
                          // disabled={ (props.dropDownOptions.isApproved) }
                        >
                          <Label className={classNames.actionButton}>
                            {t('save')}
                          </Label>
                        </ActionButton>
                      </Stack.Item>
                    </Stack>
                  </div>
                </div>

                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="involved-parties">
                      <Label>3. {t('involvedParties')}</Label>
                    </Link>
                  </div>
                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field name="accountableId">
                          {({ input, meta }) => (
                            <PeoplePicker
                              label={t('accountable')}
                              selectedItem={
                                values?.accountableId
                                  ? [
                                    {
                                      key: values?.accountableId,
                                      text: values?.accountable,
                                    },
                                  ]
                                  : []
                              }
                              onChange={(items: any) => {
                                input.onChange;
                                return items;
                              }}
                              onPersonSelected={(item: any) => {
                                input.onChange(item.key);
                                form.mutators.setAccountable(item?.text);
                                return item;
                              }}
                            />
                          )}
                        </Field>
                         <Field
                          name="accountable"
                          component={'input'}
                          type={'text'}
                          disabled={true}
                          hidden
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field name="responsibleId">
                          {({ input, meta }) => (
                            <PeoplePicker
                              label={t('responsible')}
                              selectedItem={
                                values?.responsibleId
                                  ? [
                                    {
                                      key: values?.responsibleId,
                                      text: values?.responsible,
                                    },
                                  ]
                                  : []
                              }
                              onChange={(items: any) => {
                                input.onChange;
                                return items;
                              }}
                              onPersonSelected={(item: any) => {
                                input.onChange(item.key);
                                form.mutators.setResponsible(item?.text);
                                return item;
                              }}
                            />
                          )}
                        </Field>
                        <Field
                          name="responsible"
                          component={'input'}
                          type={'text'}
                          disabled={true}
                          hidden
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  <div className={'card-footer'}>
                    <Separator/>
                    <Stack
                      styles={stackStyles}
                      tokens={itemAlignmentsStackTokens}
                    >
                      <Stack.Item align="end" styles={stackFooterBarItemStyles}>
                        <ActionButton
                          className={classNames.actionButton}
                          iconProps={saveIcon}
                          allowDisabledFocus
                          onClick={() => {
                            // alert(JSON.stringify(values.purchaseOrderType, 0, 2));
                            form.submit();
                          }}
                          // disabled={ (props.dropDownOptions.isApproved) }
                        >
                          <Label className={classNames.actionButton}>
                            {t('save')}
                          </Label>
                        </ActionButton>
                      </Stack.Item>
                    </Stack>
                  </div>
                </div>

                {/* <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="delivery-details">
                      <Label>4. {t('deliveryDetails')}</Label>
                    </Link>
                  </div>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="requestedStartDate"
                          component={DatePickerRequiredAdapter}
                          lable={t('requestedStartDate')}
                          parse={(value) =>
                            value && formatDateTimeToISOString(value)
                          }
                          format={(value) => (value ? new Date(value) : null)}
                          // disabled={ true }
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="requestedEndDate"
                          component={DatePickerRequiredAdapter}
                          lable={t('requestedEndDate')}
                          parse={(value) =>
                            value && formatDateTimeToISOString(value)
                          }
                          format={(value) => (value ? new Date(value) : null)}
                          // disabled={ true }
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="expectedStartDate"
                          component={DatePickerRequiredAdapter}
                          lable={t('expectedStartDate')}
                          parse={(value) =>
                            value && formatDateTimeToISOString(value)
                          }
                          format={(value) => (value ? new Date(value) : null)}
                          // disabled={ true }
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="expectedEndDate"
                          component={DatePickerRequiredAdapter}
                          lable={t('expectedEndDate')}
                          parse={(value) =>
                            value && formatDateTimeToISOString(value)
                          }
                          format={(value) => (value ? new Date(value) : null)}
                          // disabled={ true }
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                   <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="actualStartDate"
                          component={DatePickerRequiredAdapter}
                          lable={t('actualStartDate')}
                          parse={(value) =>
                            value && formatDateTimeToISOString(value)
                          }
                          format={(value) => (value ? new Date(value) : null)}
                          // disabled={ true }
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="actualEndDate"
                          component={DatePickerRequiredAdapter}
                          lable={t('actualEndDate')}
                          parse={(value) =>
                            value && formatDateTimeToISOString(value)
                          }
                          format={(value) => (value ? new Date(value) : null)}
                          // disabled={ true }
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  <div className={'card-footer'}>
                    <Separator />
                    <Stack
                      styles={stackStyles}
                      tokens={itemAlignmentsStackTokens}
                    >
                      <Stack.Item align="end" styles={stackFooterBarItemStyles}>
                        <ActionButton
                          className={classNames.actionButton}
                          iconProps={saveIcon}
                          allowDisabledFocus
                          onClick={() => {
                            // alert(JSON.stringify(values.purchaseOrderType, 0, 2));
                            form.submit();
                          }}
                          // disabled={ (props.dropDownOptions.isApproved) }
                        >
                          <Label className={classNames.actionButton}>
                            {t('save')}
                          </Label>
                        </ActionButton>
                      </Stack.Item>
                    </Stack>
                  </div>
                </div> */}

                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="finance">
                      <Label>4. {t('finance')}</Label>
                    </Link>
                  </div>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="expectedAmount"
                          component={CurrencyNumberFormatField}
                          lable={t('expectedAmount')}
                          // disabled={ true }
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="actualAmount"
                          component={CurrencyNumberFormatField}
                          lable={t('actualAmount')}
                          // disabled={ true }
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  <div className={'card-footer'}>
                    <Separator/>
                    <Stack
                      styles={stackStyles}
                      tokens={itemAlignmentsStackTokens}
                    >
                      <Stack.Item align="end" styles={stackFooterBarItemStyles}>
                        <ActionButton
                          className={classNames.actionButton}
                          iconProps={saveIcon}
                          allowDisabledFocus
                          onClick={() => {
                            // alert(JSON.stringify(values.purchaseOrderType, 0, 2));
                            form.submit();
                          }}
                          // disabled={ (props.dropDownOptions.isApproved) }
                        >
                          <Label className={classNames.actionButton}>
                            {t('save')}
                          </Label>
                        </ActionButton>
                      </Stack.Item>
                    </Stack>
                  </div>
                </div>

                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="comments">
                      <Label>5. {t('comments')}</Label>
                    </Link>
                  </div>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <div className={`${classNames.fullWidth}`}>
                      <Label>{t('comments')}</Label>

                      <Field
                        name="comments"
                        component={CKEditorWrapper}
                        lable={t('comments')}
                        placeholder={t('comments')}
                        disabled={false}
                      />
                    </div>
                  </Stack>

                  <div className={'card-footer'}>
                    <Separator/>
                    <Stack
                      styles={stackStyles}
                      tokens={itemAlignmentsStackTokens}
                    >
                      <Stack.Item align="end" styles={stackFooterBarItemStyles}>
                        <ActionButton
                          className={classNames.actionButton}
                          iconProps={saveIcon}
                          allowDisabledFocus
                          onClick={() => {
                            // alert(JSON.stringify(values.purchaseOrderType, 0, 2));
                            form.submit();
                          }}
                          // disabled={ (props.dropDownOptions.isApproved) }
                        >
                          <Label className={classNames.actionButton}>
                            {t('save')}
                          </Label>
                        </ActionButton>
                      </Stack.Item>
                    </Stack>
                  </div>
                </div>

                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="documents">
                      <Label>6. {t('documents')}</Label>
                    </Link>
                  </div>

                  <Field name="files">
                    {({ input, meta }) => (
                       <div className="marginTop">
                      <CustomFileUploader
                          uploadedImages={values?.files
                            ? getImages(values?.files)
                            : []}
                          label={t('documents')}
                          uploadUrl={'PO/UploadPoDocuments'} //change this accordingly
                          onSaveSuccess={(response: any) => {
                            if (values.files &&
                              _.isArray(values.files)) {
                              values.files.push(response);
                            } else {
                              values.files = [];
                              values.files.push(response);
                            }
                            //input.onChange(values.files)
                            form.mutators.setHeaderDocs(values.files);
                          } }
                          disable={false} checkedItems={[]} productId={undefined}                      />
                      </div>
                    )}
                  </Field>

                  <div className={'card-footer'}>
                    <Separator/>
                    <Stack
                      styles={stackStyles}
                      tokens={itemAlignmentsStackTokens}
                    >
                      <Stack.Item align="end" styles={stackFooterBarItemStyles}>
                        <ActionButton
                          className={classNames.actionButton}
                          iconProps={saveIcon}
                          allowDisabledFocus
                          onClick={() => {
                            // alert(JSON.stringify(values.purchaseOrderType, 0, 2));
                            form.submit();
                          }}
                          // disabled={ (props.dropDownOptions.isApproved) }
                        >
                          <Label className={classNames.actionButton}>
                            {t('save')}
                          </Label>
                        </ActionButton>
                      </Stack.Item>
                    </Stack>
                  </div>
                </div>

                {props.formData && props.formData.history && props.formData.history.createdDate && (
                  <div className="proj-detail-block" id="3">
                    <div className="document-pane-card">
                      <div className="marginTop marginBottom">
                        <Link href="#" id="history">
                          <Label>7. {t('history')} </Label>
                        </Link>

                        <HistoryBar
                          createdByUser={
                            props.formData.history.createdBy
                              ? props.formData.history.createdBy
                              : ''
                          }
                          updatedByUser={
                            props.formData.history.modifiedBy
                              ? props.formData.history.modifiedBy
                              : ''
                          }
                          createdDateTime={
                            props.formData.history.createdDate
                              ? props.formData.history.createdDate
                              : ''
                          }
                          updatedDateTime={
                            props.formData.history.modifiedDate
                              ? props.formData.history.modifiedDate
                              : ''
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};
export default DocumentPaneComponent;

import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';
import { APPROVED_STATUS_ID, IN_REVIEW_STATUS_ID } from '../../../types/projectMolecule';

const CommandBarRight = (props: { formData: any, isLoading: boolean }) => {
  const { t } = useTranslation();
  const newClick = () => {
    messageService.sendMessage({ isNewWF: true });
    // props.createNew(true);
  };
  const saveFormData = () => {
    messageService.sendMessage({ saveFormData: true });
  };
  const handleRemove = () => {
    // props.handleRemove();
  };

  const handleWorkflowApprove = () => {
    messageService.sendMessage({ workflowApprove: true });
  };

  const printClick = () => {
    if (!props.isLoading) {
      setTimeout(function() {
        messageService.sendMessage({
          wFPdfGenerate: true,
        });
      }, 700);
    }

  };

  const getItems = () => {
    return [
      {
        key: 'new',
        text: t('new'),
        iconProps: { iconName: 'Add' },
        onClick: () => {
          newClick();
        },
        disabled: true,
      },
      {
        key: 'save',
        text: t('save'),
        iconProps: { iconName: 'Save' },
        onClick: () => {
          saveFormData();
        },
        disabled: (props.formData?.status?.key === APPROVED_STATUS_ID),
      },
      // {
      //     key: 'Remove',
      //     onClick: () => {handleRemove();},
      //     name: t('remove'),
      //     iconProps: { iconName: 'Delete' },
      //     disabled: true
      // },
      {
        key: 'print',
        text: t('print'),
        disabled: (!(!props.isLoading && props.formData.sequenceId)),
        iconProps: { iconName: 'Print' },
        onClick: () => printClick(),
      },
      {
        key: 'approve',
        text: t('approve'),
        iconProps: { iconName: 'ActivateOrders' },
        disabled: (!(props.formData?.sequenceId && props.formData?.status?.key === IN_REVIEW_STATUS_ID)),
        onClick: () => handleWorkflowApprove(),
      },
    ];
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );
};

export default CommandBarRight;

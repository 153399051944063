import React from 'react';
import { useSelector } from 'react-redux';
import PmolDocumentPane from './pmolContainer';
import { mergeStyles, Stack } from '@fluentui/react';
import TitlePane from '../../../shared/titlePane/titlePane';
import CommandBarRight from '../commandBar/commandBarRight';
import ProgressBar from '../../../shared/progressBar/progressBar';


const contentStyle = mergeStyles({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  selectors: {
    '@media(max-width: 1100px)': {
      width: '97.4%',
      height: 'calc(100vh)',
      position: 'absolute',
    },
  },
});

const listStyle = mergeStyles({
  flex: '1 1 auto',
  width: '100%',
  height: 'auto',
  overflowX: 'hidden',
  overflowY: 'auto',
  paddingBottom: '30px',
  marginLeft: '0',
});

const DocumentPane=()=>{
  const {
    selectedPmol,
    collapseBookmarkPane,
    showPmolProgressBar,
    horizontalReSizer
  } = useSelector(
    (state: any) => state.projectDPlan
  );

  return    <div>
    <Stack
      className="project-list-header right-panel"
      style={{ backgroundColor: 'rgb(255, 255, 255)', paddingRight: 0 }}
      styles={{ root: { width: '100%', paddingRight: 0 } }}
    >
      <TitlePane
        close={true}
        headerTitle={selectedPmol ? selectedPmol.title : ""}
        collapse={true}
        isCollapse={collapseBookmarkPane}
        type={1}
      />
      <CommandBarRight

      />
      <ProgressBar show={showPmolProgressBar} />
    </Stack>
    <div style={{ height: horizontalReSizer - 80, overflow: 'auto', backgroundColor: '#edebe9' }}>

      <Stack
        className={`project-detail-list ${(collapseBookmarkPane && window.innerWidth > 1440) ? 'four-pane-full-inner-container' : 'four-pane-full-inner-container'} cpc  card-container`}
        styles={{
          root: {
            width: '100%',
            height: '76vh',
          },
        }}
      >
        {/*doc*/}
        <Stack
          className={contentStyle}
          horizontalAlign="start"
          verticalAlign="start"
          padding={0}
          gap={0}
          styles={{
            root: {
              width: '100%',
              height: '100%',
            },
          }}
        >
          <PmolDocumentPane projectId={selectedPmol?.projectSequenceCode} />
        </Stack>
      </Stack>
    </div>
  </div>
};

export default DocumentPane

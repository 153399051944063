import { Callout } from '@fluentui/react';
import React from 'react';
import MenuNavigation from './../menuNavigation/component';

const MenuCard = (props: { isMenuCardVisible: boolean, className: any, target: any, onDissmissed: () => void, accessLevel: number, currentUser: any, organization: string }) => {
  return (
    <div>
      {props.isMenuCardVisible &&
      <Callout
        className={props.className}
        role="alertdialog"
        gapSpace={0}
        calloutWidth={1000}
        calloutMaxWidth={1000}
        target={`.${props.target}`}
        onDismiss={props.onDissmissed}
        setInitialFocus
      >

        <MenuNavigation accessLevel={props.accessLevel} currentUser={props.currentUser}
                        organization={props.organization}/>
      </Callout>
      }
    </div>
  );
};

export default MenuCard;

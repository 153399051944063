export const LOAD_WAREHOUSE_SHORTCUTPANE = 'uprince/LOAD_WAREHOUSE_SHORTCUTPANE';
export const LOAD_WAREHOUSE_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_WAREHOUSE_SHORTCUTPANE_SUCCESS';
export const LOAD_WAREHOUSE_SHORTCUTPANE_FAIL = 'uprince/LOAD_WAREHOUSE_SHORTCUTPANE_FAIL';

export const LOAD_WAREHOUSE_LSITPANE = 'uprince/LOAD_WAREHOUSE_LSITPANE';
export const LOAD_WAREHOUSE_LSITPANE_SUCCESS = 'uprince/LOAD_WAREHOUSE_LSITPANE_SUCCESS';
export const LOAD_WAREHOUSE_LSITPANE_FAIL = 'uprince/LOAD_WAREHOUSE_LSITPANE_FAIL';

export const LOAD_WAREHOUSE_DROPDOWN = 'uprince/LOAD_WAREHOUSE_DROPDOWN';
export const LOAD_WAREHOUSE_DROPDOWN_SUCCESS = 'uprince/LOAD_WAREHOUSE_DROPDOWN_SUCCESS';
export const LOAD_WAREHOUSE_DROPDOWN_FAIL = 'uprince/LOAD_WAREHOUSE_DROPDOWN_FAIL';

export const SAVE_WAREHOUSE = 'uprince/SAVE_WAREHOUSE';
export const SAVE_WAREHOUSE_SUCCESS = 'uprince/SAVE_WAREHOUSE_SUCCESS';
export const SAVE_WAREHOUSE_FAIL = 'uprince/SAVE_WAREHOUSE_FAIL';

export const LOAD_WAREHOUSE_BY_ID = 'uprince/LOAD_WAREHOUSE_BY_ID';
export const LOAD_WAREHOUSE_BY_ID_SUCCESS = 'uprince/LOAD_WAREHOUSE_BY_ID_SUCCESS';
export const LOAD_WAREHOUSE_BY_ID_FAIL = 'uprince/LOAD_WAREHOUSE_BY_ID_FAIL';

export const SET_WAREHOUSE_UID = 'uprince/SET_WAREHOUSE_UID';
export const CLEAR_WAREHOUSE_UID = 'uprince/CLEAR_WAREHOUSE_UID';
export const CLEAR_WAREHOUSE_FORM_DATA = 'uprince/CLEAR_WAREHOUSE_FORM_DATA';
export const RESET_WAREHOUSE_IS_CHANGE = 'uprice/RESET_WAREHOUSE_IS_CHANGE';


export const CREATE_WAREHOUSE_LOCATION = 'uprince/CREATE_WAREHOUSE_LOCATION';
export const CREATE_WAREHOUSE_LOCATION_SUCCESS = 'uprince/CREATE_WAREHOUSE_LOCATION_SUCCESS';
export const CREATE_WAREHOUSE_LOCATION_FAIL = 'uprince/CREATE_WAREHOUSE_LOCATION_FAIL';

export const CREATE_WAREHOUSE_TAXONOMY_NODE = 'uprince/CREATE_WAREHOUSE_TAXONOMY_NODE';
export const CREATE_WAREHOUSE_TAXONOMY_NODE_SUCCESS = 'uprince/CREATE_WAREHOUSE_TAXONOMY_NODE_SUCCESS';
export const CREATE_WAREHOUSE_TAXONOMY_NODE_FAIL = 'uprice/CREATE_WAREHOUSE_TAXONOMY_NODE_FAIL';

export const LOAD_WAREHOUSE_TAXONOMY_NODE = 'uprince/LOAD_WAREHOUSE_TAXONOMY_NODE';
export const LOAD_WAREHOUSE_TAXONOMY_NODE_SUCCESS = 'uprince/LOAD_WAREHOUSE_TAXONOMY_NODE_SUCCESS';
export const LOAD_WAREHOUSE_TAXONOMY_NODE_FAIL = 'uprice/LOAD_WAREHOUSE_TAXONOMY_NODE_FAIL';

export const LOAD_WAREHOUSE_TAXONOMY_NODE_WF = 'uprince/LOAD_WAREHOUSE_TAXONOMY_NODE_WF';
export const LOAD_WAREHOUSE_TAXONOMY_NODE_WF_SUCCESS = 'uprince/LOAD_WAREHOUSE_TAXONOMY_NODE_WF_SUCCESS';
export const LOAD_WAREHOUSE_TAXONOMY_NODE_WF_FAIL = 'uprice/LOAD_WAREHOUSE_TAXONOMY_NODE_WF_FAIL';

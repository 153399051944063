import React from 'react';
import { connect } from 'react-redux';
import DocumentPaneComponent from './component';
import { WithTranslation, withTranslation } from 'react-i18next';
import _ from 'lodash';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import history from '../../../history';
import { messageService } from '../../../services/messageService';
import { getProject } from '../../../shared/util';

import {
  getAllProjectDropdowns,
  getProjectDefinition,
  getProjectHeaderDetails,
} from '../../../reducers/projectReducer';
import { PurchaseOrder } from '../../../types/purchaseOrder';
import { readByStockId, saveFormData } from '../../../reducers/stockReducer';
import { v4 as uuidv4 } from 'uuid';
import { Selection } from '@fluentui/react/lib/DetailsList';
import { readWarehouseDropDownData, readWarehouseTaxonomy } from '../../../reducers/warehouseReducer';
import { WarehouseDropDowns } from '../../../types/warehouse';

export interface State {
  isDirty: boolean;
  redirect: boolean;
  loading: boolean;
  isClear: boolean;
  isEdit: boolean;
  uid: string | null;
  formData: any;
  filter: any
}

interface Props extends WithTranslation {
  projectHeader: any,
  stockDropDowns: any;
  saveFormData: (data: PurchaseOrder) => void;
  getAllProjectDropdowns: () => void;
  formData: any;
  isLoadingStock: boolean;
  stockID: string;
  readByStockId: (id: string) => void;
  warehouseDropDowns: WarehouseDropDowns;
  warehouseTaxonomy: any;
  readWarehouseDropDownData: () => void;
  readWarehouseTaxonomy: (data: any) => void;
  isNew: boolean;
  uid: string;
  isNewStock: boolean;
  getProjectHeaderDetails: (id: string) => void;
  dropDowns: any;
  isExpand: boolean;
}

class DocumentPane extends React.Component<Props, State> {
  subscription: any;
  submitExternal: any;
  form: any;
  private _historySelection: Selection;

  constructor(props: any) {
    super(props);
    this.state = {
      isDirty: false,
      isEdit: false,
      redirect: false,
      loading: false,
      formData: {},
      uid: null,
      isClear: false,
      filter: null,
    };

    this._historySelection = new Selection({
      onSelectionChanged: () => {


      },
    });

  }


  scrollIntoTop = () => {
    const topDiv = document.getElementById('topDiv');
    if (topDiv) {
      topDiv.scrollIntoView();
    }
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevProps.isNew !== this.props.isNew ||
      prevProps.uid !== this.props.uid
    ) {
      if (this.props.isNew) {
        const formData = this.state.formData;
        formData.id = this.props.uid;
        this.setState({ formData, isClear: false });
      }
    }

    if (prevProps.stockID !== this.props.stockID) {
      if (this.props.stockID) {
        this.props.readByStockId(this.props.stockID);
      }
    }

    if (prevProps.formData !== this.props.formData) {
      if (!this.state.isDirty) {
        if (!_.isEmpty(this.props.formData)) {
          const formData = this.props.formData;
          this.setState({ formData: formData, isClear: false });
        }
      } else {
        if (this.props.isNew && _.isEmpty(this.props.formData)) {
          const formData = this.state.formData;
          this.setState({ formData: formData, isClear: false });
        }
      }
    }
  }

  componentDidMount() {
    // get document pane dropdowns
    // this.props.getAllProjectDropdowns();
    this.props.readWarehouseDropDownData();
    //this.props.readWarehouseTaxonomy({ wareHouseId: null });
    this.setState({ uid: uuidv4(), isClear: this.props.isNewStock });

    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewStock) {
          if (getProject()) {
            this.setState({ uid: uuidv4(), isClear: true });
          } else {
            this.setState({ uid: uuidv4(), isClear: true });
          }

          if (this.form) {
            this.form.reset();
            this.form.resetFieldState('header.name');
            this.form.resetFieldState('header.cpcResourceTitle');
          }
          this.scrollIntoTop();
        }
        if (data.data.saveStockData) {
          // this.saveFormData();
          this.setState({ isClear: false });
          this.submitExternal();
        }

      }
    });
    if (getProject()) {
      const projectId = getProject();
      if (projectId) {
        this.props.getProjectHeaderDetails(projectId);
      }
    } else if (this.props.formData?.id) {
      this.props.getProjectHeaderDetails(this.props.formData?.id);
    }
    if (this.props.formData?.id) {

    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { psFilter: this.state.filter },
    });
  };

  setSubmitExternal = (submit: any) => {
    this.submitExternal = submit;
  };

  setForm = (form: any) => {
    this.form = form;
  };

  setFormClearState = (isClear: boolean) => {
    this.setState({ isClear: isClear });
  };


  render() {
    return (
      <div style={{ width: '100%' }}>
        <RouteLeavingGuard
          when={this.state.isDirty}
          navigate={(path) => this.handleDiscardChangesNavigate(path)}
          shouldBlockNavigation={() => {
            return true;
          }}
          handleConfirmation={(isConfirm: boolean) => {
            this.setState({ isDirty: !isConfirm });
          }}
        />
        <DocumentPaneComponent
          submit={(submit: any) => {
            this.setSubmitExternal(submit);
          }}
          dropDowns={{
            ...this.props.dropDowns,
            warehouseTaxonomy: this.props.warehouseTaxonomy?.length > 0 ? this.props.warehouseTaxonomy : [],
            whTaxonomyLevel: this.props.warehouseDropDowns?.whTaxonomyLevel ? this.props.warehouseDropDowns?.whTaxonomyLevel : [],
          }}
          projectHeader={this.props.projectHeader}
          saveFormData={this.props.saveFormData}
          uid={this.state.uid}
          formData={this.props.formData}
          showProgress={this.props.isLoadingStock}
          formValues={this.form?.getState()?.values}
          isClear={this.state.isClear}
          historySelection={this._historySelection}
          isExpand={this.props.isExpand}
          setForm={(form: any) => {
            this.setForm(form);
          }}
          setFormClearState={(isClear: boolean) => this.setFormClearState(isClear)}
        />

      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isEdit: state.ps.isEdit,
    isNew: state.ps.isNew,
    uid: state.ps.uid,
    projectHeader: state.project.projectHeader,
    showProgressBar: state.stock.showProgressBar,
    ledgerDropDowns: state.project.ledgerDropDowns,
    selectedProject: state.project.selectedProject,
    dropDowns: state.stock.stockDropDowns,
    isLoadingStock: state.stock.isLoadingStock,
    stockID: state.stock.stockID,
    warehouseDropDowns: state.warehouse.warehouseDropDowns,
    warehouseTaxonomy: state.warehouse.warehouseTaxonomy,
  };
};

const mapDispatchToProps = {
  saveFormData,
  getProjectHeaderDetails,
  getAllProjectDropdowns,
  getProjectDefinition,
  readByStockId,
  readWarehouseTaxonomy,
  readWarehouseDropDownData,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DocumentPane));

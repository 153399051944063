import { IChoiceGroupOption, IDropdownOption } from '@fluentui/react';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import client from '../../../api';
import { saveStateAttr } from '../../../reducers/uprinceReducer';
import { getCodeType } from '../../../shared/util';
import { getAllProjects } from './../../../reducers/projectReducer';
import QRCodeComponent from './component';
import { getQRDropDownsData } from '../../../reducers/qrCodeReducer';
import { messageService } from '../../../services/messageService';
import i18n from '../../../../i18n';

export interface State {
  isLoading: boolean;
  selectedCodeType: any;
  location: string | undefined | null;
  date: string | null;
  vehicleNo: string | undefined | null | number;
  personalId: string | null;
  project: any;
  codeValue: string | null;
  showCode: boolean;
  locationErrorMsg: string;
  projectErrorMsg: string;
  vehicalNoErrorMsg: string;
  disable: boolean;
  projectValid: boolean;
  locationValid: boolean;
  vehicalNovalid: boolean;
  codeGenerated: boolean;
  qrCode: any;
  divClass: string;
  currentActiveSection: number;
  projectTitle: string | null | undefined;
  role: string | null;
  roleValidationMsg: string;
  resetValue: boolean
}

interface Props {
  history?: any;
  match?: any;
  location?: any;
  getAllProjects?: any;
  allProjects?: any;
  onQRCodeGenerated: any;
  onChangeQRInputs: any;
  onQRCodeClick: any;
  saveStateAttr?: (attr: string, value: any) => void;
  getQRDropDownsData?: any
}

class NewQRCode extends Component<Props, State> {
  subscription: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      selectedCodeType: -1,
      location: null,
      date: null,
      vehicleNo: null,
      personalId: null,
      project: null,
      projectTitle: '-',
      codeValue: null,
      showCode: false,
      locationErrorMsg: '',
      projectErrorMsg: '',
      vehicalNoErrorMsg: '',
      disable: false,
      projectValid: false,
      locationValid: false,
      vehicalNovalid: false,
      codeGenerated: false,
      qrCode: {},
      divClass: 'bookmark-panel parent',
      currentActiveSection: 1,
      role: null,
      roleValidationMsg: '',
      resetValue: false,

    };
  }


  componentWillMount() {
    //this.props.getAllProjects();
    //this.props.getQRDropDownsData()
  }

  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data && data.data && data.data.isNewQrCode) {
          this.setState({
            resetValue: true,
          });
        }
      }
    });

    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data && data.data && data.data.isUpdateQR) {
          // this.setState({ needRefresh: false})
          this.createQRCode();
        } else if (data && data.data && data.data.isUpdateQR === false) {
          //this.setState({ needRefresh: true})
        }
      }
    });
  }

  componentWillReceiveProps(nextProps: any) {
    let refresh = false;
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.refresh
    ) {
      refresh = this.props.location.state.refresh;
    }
    if (
      nextProps.location &&
      nextProps.location.state &&
      nextProps.location.state.refresh != refresh
    ) {
      this.setState({
        disable: false,
        codeValue: null,
        showCode: false,
        selectedCodeType: -1,
        location: null,
        project: null,
        qrCode: {},
      });
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  onTypeChange = (
    event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption,
  ) => {
    if (this.props.location && this.props.location.state) {
      this.props.location.state.refresh = false;
    }
    this.setState(
      {
        selectedCodeType: item.key,
        vehicleNo: null,
        location: null,
        locationErrorMsg: '',
        projectErrorMsg: '',
        vehicalNoErrorMsg: '',
        qrCode: {},
        roleValidationMsg: '',
        role: null,
      },
      this.props.onChangeQRInputs(true),
    );
  };

  private toggleBookmark = (): void => {
    this.setState({ divClass: 'bookmark-panel parent panel-on' });
  };

  private toggleBookmarkOut = (): void => {
    this.setState({ divClass: 'bookmark-panel parent' });
  };

  onProjectChange = (
    event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption,
  ) => {
    this.setState({ project: item.key, location: null, projectErrorMsg: '', projectTitle: item.text });
  };

  setVehicalNo = (
    event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption,
  ) => {
    this.setState({ vehicleNo: item.key, vehicalNoErrorMsg: '' });
  };

  setLocation = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    this.setState({ location: newValue });
  };

  _onChange = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    option?: IChoiceGroupOption | undefined,
  ): void => {
    this.setState({ roleValidationMsg: '', role: option?.key!! });
  };

  onRefresh = () => {
    this.setState({
      disable: false,
      codeValue: null,
      showCode: false,
      selectedCodeType: -1,
      location: null,
      project: null,
      qrCode: {},
    });
  };

  validate = (): boolean => {
    //const { t } = this.props;
    let projectValid = false;
    let locationValid = false;
    let vehicaleNoValid = false;
    // if (!this.state.project) {
    //   this.setState({
    //     projectErrorMsg: t('Please Select project'),
    //     vehicalNoErrorMsg: '',
    //   });
    //   projectValid = false;
    // } else {
    //   this.setState({
    //     projectErrorMsg: '',
    //   });
    //   projectValid = true;
    // }
    if (this.state.selectedCodeType === 0 && !this.state.vehicleNo) {
      this.setState({ vehicalNoErrorMsg: i18n.t('pleaseentervehiclenumber') });
      if (this.state.selectedCodeType === 0 && !this.state.vehicleNo && !this.state.role) {
        this.setState({
          vehicalNoErrorMsg: i18n.t('pleaseentervehiclenumber'),
          roleValidationMsg: i18n.t('pleaseSelectRole'),
        });
      }
      vehicaleNoValid = false;
    } else if (this.state.selectedCodeType === 0 && !this.state.role) {
      this.setState({ roleValidationMsg: i18n.t('Please Select Role') });
      vehicaleNoValid = false;
    } else {
      this.setState({ vehicalNoErrorMsg: '' });
      vehicaleNoValid = true;
      locationValid = true;
    }
    if (
      (this.state.selectedCodeType === 2 ||
        this.state.selectedCodeType === 5 ||
        this.state.selectedCodeType === 4) &&
      !this.state.location
    ) {
      this.setState({ locationErrorMsg: i18n.t('Please enter location') });
      locationValid = false;
      //return false;
    } else {
      this.setState({ locationErrorMsg: '' });
      locationValid = true;
    }

    return locationValid && vehicaleNoValid;
  };

  createQRCode = () => {
    if (this.validate()) {
      this.setState({
        isLoading: true,
        qrCode: {
          date: moment().format('YYYY-MM-DD'),
          activityType: { type: getCodeType(this.state.selectedCodeType) },
          projectDefinition: { title: this.state.projectTitle },
          vehicleNo: this.state.vehicleNo,
          location: this.state.location,
          personalId: null,
        },
      });
      this.props.onQRCodeClick({
        date: moment().format('YYYY-MM-DD'),
        type: getCodeType(this.state.selectedCodeType),
        projectId: this.state.projectTitle,
        vehicleNo: this.state.vehicleNo,
        location: this.state.location,
        personalId: null,
      });
      client
        .post('/QRCode/Create', {
          date: moment().format('YYYY-MM-DD hh:mm a'),
          type: this.state.selectedCodeType,
          projectId: this.state.project,
          vehicleNo: this.state.vehicleNo,
          location: this.state.location,
          personalId: null,
          travellerType: this.state.role,
        })
        .then(
          (response: any) => {
            if (response.status === 200) {
              this.setState({
                showCode: true,
                codeValue: response.data.result,
                disable: true,
                codeGenerated: true,
                isLoading: false,
              });
              this.props.onQRCodeGenerated(this.state.selectedCodeType);
              this.props.onChangeQRInputs(false);
              let code = this.state.qrCode;
              code.id = response.data.result;
              messageService.sendMessage({ qrCode: code });
            }
          },

          (error: any) => {
            this.props.onChangeQRInputs(false);
            this.setState({ isLoading: false });
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              //alert(error.response.data.message);
            }
          },
        );
    }
  };

  render() {
    return (
      <div>
        <QRCodeComponent
          onTypeChange={this.onTypeChange}
          selectedCodeType={this.state.selectedCodeType}
          createQRCode={this.createQRCode}
          date={this.state.date}
          project={this.state.project}
          vehicleNo={this.state.vehicleNo}
          location={this.state.location}
          allProjects={this.props.allProjects}
          onProjectChange={this.onProjectChange}
          setLocation={this.setLocation}
          setVehicalNo={this.setVehicalNo}
          showCode={this.state.showCode}
          codeValue={this.state.codeValue}
          onRefresh={this.onRefresh}
          projectErrorMsg={this.state.projectErrorMsg}
          locationErrorMsg={this.state.locationErrorMsg}
          vehicalNoErrorMsg={this.state.vehicalNoErrorMsg}
          disable={this.state.disable}
          isLoading={this.state.isLoading}
          qrCode={this.state.qrCode}
          toggleBookmarkOut={this.toggleBookmarkOut}
          toggleBookmark={this.toggleBookmark}
          divClass={this.state.divClass}
          currentActiveSection={this.state.currentActiveSection}
          onChangeRole={this._onChange}
          roleValidationMsg={this.state.roleValidationMsg}
          resetValue={this.state.resetValue}
          setResetValue={(resetValue: boolean) => {
            this.setState({ resetValue });
          }}
        />
      </div>
    );
  }
}

// const getFormattedProjects = (projects: any[]) => {
//   let formattedProjects = projects.map(
//     (item: { id: string; title: string }) => {
//       return {
//         key: item.id,
//         text: item.title,
//       };
//     }
//   );
//   return formattedProjects;
// };

const mapStateToProps = (state: any, props: Props) => {
  //const projects = getFormattedProjects(state.qrCode.nonFilterProjects);
  return {
    allProjects: state.qrCode.dropDownsData,
  };
};

const mapDispatchToProps = {
  getAllProjects,
  saveStateAttr,
  getQRDropDownsData,
};

export default (
  connect(mapStateToProps, mapDispatchToProps)(NewQRCode)
);

import { useTranslation } from 'react-i18next';
import {
  getTheme,
  IDragOptions,
  ContextualMenu,
  IIconProps,
  mergeStyleSets,
  FontWeights,
  Modal,
  IconButton,
  ActionButton,
  Label,
  TextField,
  PrimaryButton,
  Stack,
  IStackStyles,
  IStackItemStyles,
  IStackTokens,
  Separator,
} from '@fluentui/react';
import React, { useState, useEffect, useRef } from 'react';
import { useId, useBoolean } from '@uifabric/react-hooks';
import _ from 'lodash';
import { uPrinceTheme } from '../../../../../theme';
import {
  Quality,
} from '../../../../types/projectBreakdownStructure';
import { useDispatch,useSelector } from 'react-redux';
import TextEditor from '../../../../shared/textEditor/textEditor';
import CustomDropdown from '../../../../shared/customDropdown/customDropdown';
import { getProject } from '../../../../shared/util';
import client from '../../../../api';
import { readQualityById } from '../../../../reducers/projectBreakdownReducer';
const theme = getTheme();
const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const classNames = mergeStyleSets({
  wrapper: {
    //height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: {
    maxWidth: 400,
  },
  textContent: {
    padding: '15px 10px',
  },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: {
    width: '100%',
  },
});

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };

const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '40vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};


class QualityValidationMessages {
  criteriaValidationMsg: string = '';
}

const QualityModal = (props: {
  pbsId: any;
  isOpen: boolean;
  openQualityModal: () => void;
  isEdit: boolean;
  createQuality: (quality: Quality) => void;
  quality: any;
}) => {

  const dispatch = useDispatch();//to load quality data into modal
  const { t } = useTranslation();
  const [isDraggable] = useBoolean(false);
  const titleId = useId('title');
  const ref: any = useRef();
  const [quality, setQuality] = useState(new Quality());
  const [qualityValidationMessages, setQualityValidationMsg] = useState<
    QualityValidationMessages
  >(new QualityValidationMessages());
  const [disableSave, setDisableSave] = useState(true);
  const [isValidCriteria, setIsValidCriteria] = useState(true);

  // const onChangeInput = (field: string) => (
  //   event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  //   newValue?: string
  // ) => {
  //   setQuality((prevState) => ({
  //     ...prevState,
  //     [field]: newValue,
  //   }));
  //   setQualityValidationMsg(new QualityValidationMessages())
  // };

  // const validaErrorOnvalueChange = (key: string) => {
  //   switch (key) {
  //     case 'criteria':
  //       validateQualityCriteria(quality);
  //       break;
  //   }
  // };

  useEffect(() => {
    //console.log("qua",props.quality);
    if (props.isEdit) {
      setQuality({
        id: props.quality.id,
        criteria: props.quality.criteria,
        tolerance: props.quality.tolerance,
        method: props.quality.method,
        skills: props.quality.skills,
        sequenceCode: props.quality.sequenceCode,
        title: props.quality.title,
        headerTitle: props.quality.headerTitle,
        name: props.quality.name,
        isDeleted: props.quality.isDeleted,
        isSaved: props.quality.isSaved,
        pbsQualityId: props.quality.pbsQualityId,
        uid: props.quality.uid,
      });
      setDisableSave(false)
    } else {
      setDisableSave(true)
      if(props.quality?.id) {
        //console.log("GGGGGGG",props.quality)
        setQuality(props.quality);
      setQualityValidationMsg(new QualityValidationMessages());
    }else{
      setQuality(new Quality());
    }
  }}, [props.quality]);

  useEffect(() => {
    if (quality && ref.current && ref.current.criteria != quality.criteria) {
      //validaErrorOnvalueChange('criteria');
    }
    ref.current = quality;
  }, [quality]);

  const validateQuality = (quality: Quality) => {
    let isValidName = validateQualityCriteria(quality);
    return isValidName;
  };

  const handleSaveBtnDisable=(isDisable=false) => {
    if(quality.criteria){
      setDisableSave(false);
    }else if(!quality.criteria){
      setDisableSave(true);
    }
    if(isDisable){
      setDisableSave(true);
    }
  };



  const validateQualityCriteria = (quality: Quality) => {
    let isValidName = false;
    if (quality && quality.criteria) {
      isValidName = true;
      setIsValidCriteria(true);
      setQualityValidationState('criteriaValidationMsg', '');
    } else {
      isValidName = false;
      setIsValidCriteria(false);
      console.log("isValidCriteria",isValidCriteria);
      setQualityValidationState('criteriaValidationMsg', t('criteriaRequired'));
    }
    return isValidName;
  };

  const setQualityValidationState = (key: string, msg: string) => {
    setQualityValidationMsg((prevState) => ({
      ...prevState,
      [key]: msg,
    }));
  };

    //for existing quality selection using quality modal
    const qualityOptions = (inputValue: any) =>
    new Promise((resolve) => {
        // setTimeout(() => {
        resolve(getQualityByName(inputValue));
        // }, 1000);
    });
  
    const getQualityByName = async(name:string) => {
      const filter = {
          title: name,
          pbsProductId: props.pbsId,
          sortingModel: {
              attribute: 'title',
              order: 'asc'
          }
      };
      const header = getProject() ? { headers: { project: getProject() } } : undefined;
      if (name.length >= 2) {
          const response = await client.post(
              'Quality/QualityFilter',
              filter, header
          );
          return formatQualityResponse(response);
      }
  };
  const formatQualityResponse = (response: any) => {
    let options: { value: string; label: string }[] = [];
    if (response && response.data && response.data.result) {
        const data = response.data.result;
  
        if (data && _.isArray(data)) {
            options = data.map((item: any) => {
                return {
                    value: item.sequenceCode,
                    label: item.title
                };
            });
        }
  
  
        return options;
    }
  };

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          props.openQualityModal();
          setTimeout(() => {
            setQualityValidationMsg(new QualityValidationMessages());
          }, 1000);
        }}
        scrollableContentClassName="body"
        isBlocking={false}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
      <span id={titleId}>{props.isEdit?t('editQuality'): t('addQuality')}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              props.openQualityModal();
              setTimeout(() => {
                setQualityValidationMsg(new QualityValidationMessages());
              }, 1000);
              setQuality(new Quality())
            }}
          />
        </div>
        {/* <div className={contentStyles.subHeader}>
          <ActionButton iconProps={addIcon} allowDisabledFocus>
            <Label className={contentStyles.actionButtonLabel}>
              {!props.isEdit ? t('add') : t('save')}
            </Label>
          </ActionButton>
        </div> */}
        <div className={contentStyles.body}>
          <div className="proj-detail-content inner">
            <div className="ms-Grid-row">
              <Label>1. {t('qualityDetails')} </Label>
              {props.quality.headerTitle && (
                <Stack
                  horizontal
                  wrap
                  styles={stackStyles}
                  tokens={stackTokens}
                >
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        label={t('qualityID')}
                        readOnly={true}
                        disabled={true}
                        value={
                          quality && quality.sequenceCode
                            ? quality.sequenceCode
                            : ''
                        }
                      />
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <TextField
                        label={t('qualityTitle')}
                        value={quality && quality.title ? quality.title : ''}
                        errorMessage={''}
                        readOnly={true}
                        disabled={true}
                      />
                    </div>
                  </Stack.Item>
                </Stack>
              )}

              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    {/* name field will not available for dropdown when editing */}
                    
                        <Label required={true}>{t('name')}</Label>
                        <CustomDropdown
                          reference={''}
                          //disabled={false}
                          onChange={(value: any, label: string) => {
                            if (value) {
                              setQuality((prevState) => ({
                                ...prevState,
                                name: label,
                                id: value
                              }))

                              dispatch(readQualityById(value));
                              //call risk by id
                            } else {
                              setQuality((prevState) => ({
                                ...prevState,
                                name: null,
                                id: null
                              }))
                              //form.mutators.setCUCPC(null);
                            }
                          }}
                          //to load risk data into modal
                          selectedOption={(quality && quality?.id ? {
                            value: quality?.id,
                            label: quality?.name
                          } : null)}
                          promiseOptions={qualityOptions}
                          // validationMessage={qualityValidationMessages.nameValidationMsg}
                          validationMessage={''}
                          required={true} 
                        />
                  </div>
                </Stack.Item>
              </Stack>
              <br />
          
              <Label style={{ marginLeft: 10, marginRight: 10 }} required>
                {t('qualityCriteria')}
              </Label>
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                
                <TextEditor
                  value={quality && quality.criteria ? quality.criteria : ''}
                  onChange={(evt: any) => {
                    setQuality((prevState) => ({
                      ...prevState,
                      criteria: evt,
                    }));
                    // if (!evt){
                    // handleSaveBtnDisable();
                    // } else {
                    //   handleSaveBtnDisable(false);
                    // } 
                    setQualityValidationMsg(new QualityValidationMessages())
                    setIsValidCriteria(true);
                  }}
                />
              {(!quality.criteria && !isValidCriteria) && <span style={{ marginLeft: 10, marginRight: 10, color: "#A4262C"}}>{t('qualityCriteriaRequired')}</span>}
              <Separator />
              </div>

            <Label style={{ marginLeft: 10, marginRight: 10 }}>
                {t('qualityTolerance')}
              </Label>
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                
                <TextEditor
                  value={
                    quality && quality.tolerance ? quality.tolerance : ''
                  }
                  onChange={(evt: any) => {
                    setQuality((prevState) => ({
                      ...prevState,
                      tolerance: evt,
                    }));
                    
                  }}
                />
              </div>

              <Label style={{ marginLeft: 10, marginRight: 10 }}>
                {t('qualityMethod')}
              </Label>
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                
                <TextEditor
                  value={quality && quality.method ? quality.method : ''}
                  onChange={(evt: any) => {
                    setQuality((prevState) => ({
                      ...prevState,
                      method: evt,
                    }));
                    
                  }}
                />
              </div>
              <Label style={{ marginLeft: 10, marginRight: 10 }}>
                {t('qualitySkillRequired')}
              </Label>
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                
                <TextEditor
                  value={quality && quality.skills ? quality.skills : ''}
                  onChange={(evt: any) => {
                    setQuality((prevState) => ({
                      ...prevState,
                      skills: evt,
                    }));
                    
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={addIconWhite}
            text={t('save')}
            style={{ marginTop: 20, marginBottom: 30 }}
            disabled={quality.name ? false : true}
            onClick={() => {
              if (validateQuality(quality)) {
                props.createQuality(quality);
                props.openQualityModal()
                setQuality(new Quality())
              }
            }}
          />
          {!props.isEdit && <PrimaryButton
              iconProps={addIconWhite}
              text={t('saveandNew')}
              style={{ marginTop: 20, marginBottom: 10, marginLeft:10}}
              onClick={() => {
                if (validateQuality(quality)) {
                  
                  props.createQuality(quality);
                  setQuality(new Quality());
                }
              }}
              disabled={quality.name ? false : true}
          />}
        </div>
        {/* <pre>{JSON.stringify(quality, 0, 2)}</pre> */}
      </Modal>
    </div>
  );
};

export default QualityModal;

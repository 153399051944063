import React from 'react';
import {TextField} from "@fluentui/react";
import {uPrinceTheme} from "../../../theme";

const styles = {
    textField: {
        fontSize: 30,
        height: 65,
        color: uPrinceTheme.palette.themePrimary,
    },
    textFieldLabel: {
        marginRight: 5,
        fontSize: 30,
        paddingBottom: 10,
        fontWeight: 600,
    },
    textFieldDiv: {
        height: 130,
    },
    textDisabled: {
        fontSize: 30,
        height: 65,
        width: '100%',
        color: uPrinceTheme.palette.themePrimary,
        background: 'rgb(247, 247, 247)',
        display: 'flex',
        alignItems: 'center',
    }
};

interface Props {
    value: string
    label: string
}

const DisabledTextField = (props: Props) => {
    const {value, label} = props;
    return <>
        <TextField
          value={value}
          disabled={true}
          style={styles.textField}
          onRenderLabel={() => {
              return (
                <div style={styles.textFieldLabel}>{label}</div>
              );
          }}
          onRenderInput={() => {
              return (
                <div style={styles.textDisabled}><span style={{paddingLeft: 8}}>{value}</span></div>
              );
          }}
        />
    </>
}

export default DisabledTextField;
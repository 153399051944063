import React, { useRef } from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';
import { BillOfResource, BOR_TYPE, ShortCutPaneItem } from '../../../types/billOfResources';
import * as XLSX from 'xlsx';
import groupArray from 'group-array';

const CommandBarRight = (props: {
  createNew: (create: boolean, type: string) => void;
  handleRemove: () => void;
  formData: BillOfResource;
  isLoading: boolean;
  selectedShortCutItem: ShortCutPaneItem;
  isBorReturn: boolean;
  setReturnBorResources: any
}) => {
  const { t } = useTranslation();
  const inputFileRef = useRef<HTMLInputElement>(null);

  const showFileDialog = () => {
    inputFileRef.current && inputFileRef.current.click();
  };

  const newClick = () => {
    messageService.sendMessage({ isNewBor: true });
    props.setReturnBorResources(null);
    props.createNew(true, BOR_TYPE.REGULAR);
  };
  const saveFormData = () => {
    messageService.sendMessage({ saveFormDataBor: true });
  };
  const handleRemove = () => {
    props.handleRemove();
  };
  const borReturn = () => {
    messageService.sendMessage({ isBorReturn: true });
    props.setReturnBorResources(null);
    props.createNew(true, BOR_TYPE.RETURN);
  };

  const convertExceltToJson = (workBook: any) => {
    let borResource = workBook[0].map((j: any) => {
      return {
        id: j[0] ? j[0] : null,
        resourceType: j[1] ? j[1] : null,
        title: j[2] ? j[2] : null,
        required: j[3] ? j[3] : null,
        purchased: j[4] ? j[4] : null,
        deliveryRequested: j[5] ? j[5] : null,
        warf: j[6] ? j[6] : null,
        consumed: j[7] ? j[7] : null,
        returned: j[8] ? j[8] : null,
        invoiced: j[9] ? j[9] : null,
        type: '1',
        isNew: true,
        resourceNumber: j[2] ? j[2].split(' ')[0] : null,
        date: new Date(),
      };
    });
    borResource.shift();
    borResource = groupArray(borResource, 'resourceType');

    const result = {
      materials: borResource[t('materials')],
      tools: borResource[t('tools')],
      consumable: borResource[t('consumables')],
      labour: borResource[t('humanResources')] ? borResource[t('humanResources')] : borResource[t('otherLabours')],
    };
    // if (!borResource.hasOwnProperty(t('materials')) ) {
    //     alert(t('youMightHaveDownloadFileOtherLang'));
    // }

    return result;
  };

  const handleFile = (e: any) => {
    const files: any = e.target.files;
    const f = files[0];
    const reader = new FileReader();
    const result: any = {};
    reader.onload = (d: any) => {
      const data = new Uint8Array(d.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      // if (!workbook.SheetNames.includes(t('borResources'))) {
      //      alert('Invalid File');
      // }
      workbook.SheetNames.forEach((sheetName: any, index: number) => {
        const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
        if (roa.length) {
          result[index] = roa;
        }
      });


      messageService.sendMessage({ returnResources: convertExceltToJson(result) });

      /* DO SOMETHING WITH workbook HERE */
    };
    reader.readAsArrayBuffer(f);
  };


  const getItems = () => {
    const items = [];

    const newbtn = {
      key: 'new',
      text: t('new'),
      iconProps: { iconName: 'Add' },
      onClick: () => {
        newClick();
      },
      disabled: props.isLoading,
      split: true,
      subMenuProps: {
        items: [
          { key: 'regular', text: t('regular'), onClick: () => newClick() },
          { key: 'return', text: t('return'), onClick: () => borReturn() },
        ],
      },
    };

    const saveBtn = {
      key: 'save',
      text: t('save'),
      iconProps: { iconName: 'Save' },
      onClick: () => {
        saveFormData();
      },
      disabled: props.isLoading,
    };

    const removebtn = {
      key: 'Remove',
      onClick: () => {
        handleRemove();
      },
      name: t('remove'),
      iconProps: { iconName: 'Delete' },
      disabled: true,
      // disabled: (!(props.formData.itemId) )
    };

    const uploadBtn = {
      key: 'Upload',
      onClick: () => showFileDialog(),
      name: t('upload'),
      iconProps: { iconName: 'Upload' },
      disabled: (props.formData.id === null),
    };

    items.push(newbtn);
    items.push(saveBtn);
    items.push(removebtn); // when visit the BOR for the first time selectedShortCutItem.id is null
    if (props.isBorReturn) {
      items.push(uploadBtn);
    }
    return items;
  };

  return (
    <div>
      <input
        style={{ display: 'none' }}
        ref={inputFileRef}
        type="file"
        accept=".xlsx" // ".png,.xlsx" multiple inputs
        multiple={false}
        onChange={(e: any) => {
          // alert('files uploader');
          handleFile(e);
          // uploadFiles(e.target.files);
        }}
      />
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );
};

export default CommandBarRight;

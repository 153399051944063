import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { EXPORT_EN, EXPORT_NL, Journal, PmolPDFData } from '../../../types/projectMolecule';
import { messageService } from '../../../services/messageService';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { FontWeights, getTheme, IconButton, IIconProps, Modal } from '@fluentui/react';
import i18n from '../../../../i18n';
import PdfExport from './pdfExport';
import { format24hDateTime, formatwithoutTime } from '../../../shared/util';
import { readPMOLPDFByID } from '../../../reducers/pmolReducer';
import _ from 'lodash';

const classNames = mergeStyleSets({
  tableBorder: {
    border: '1px solid black;',
    borderCollapse: 'collapse',
  },

});

const cancelIcon: IIconProps = { iconName: 'Cancel' };
const titleId = 'sdsd';
const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    width: '80vw',
    height: '80vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
});
const toggleStyles = { root: { marginBottom: '20px' } };
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export interface State {
  isModalOpen: boolean
  language: string
  pdfData: PmolPDFData | null;
}

export interface Props extends WithTranslation {
  formData: any;
  readPMOLPDFByID: (pmolId: string, lan: string) => any;
  address: any;
  projectHeader: any;
  journal: Journal;
  pmolId: string;
}


class PmolPDFExport extends React.Component<Props, State> {
  subscription: any;


  constructor(props: Props) {
    super(props);
    this.state = {
      isModalOpen: false,
      language: EXPORT_NL,
      pdfData: null,
    };
  }

  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.exportPmolNL) {
          this.getPmolPdfData(EXPORT_NL);
        }
        if (data.data.exportPmolEN) {
          this.getPmolPdfData(EXPORT_EN);
        }
      }
    });
  }


  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.formData != this.props.formData) {
      this.setPmolPdfData();
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  getPmolPdfData = (language: string) => {
    Promise.all([
      this.props.readPMOLPDFByID(this.props.pmolId, language),
    ]).then(() => {
      this.setState({
        isModalOpen: true,
        language: language,
      });
    });
  };

  removeTags = (str: string | null) => {
    if ((str === null) || (str === ''))
      return '';
    else
      str = str.toString();
    return str.replace(/(<([^>]+)>)/ig, '');
  };

  setPmolPdfData = () => {
    // if(this.props.formData.id){
    let pdfData: PmolPDFData = {
      id: this.props.formData.id,
      customerAddress: this.props?.address,
      contactPersonEmail: this.props.formData.customer?.contactPersonEmail ? this.props.formData.customer?.contactPersonEmail : null,
      customerContact: this.props.formData.customer?.contactPersonName ? this.props.formData.customer?.contactPersonName : null,
      customerName: this.props.formData.customer?.customerName ? this.props.formData.customer?.customerName : null,
      project: this.props.projectHeader?.title,
      title: this.props.formData?.header?.title,
      work: this.removeTags(this.props.journal.doneWork),
      remarks: '',
      signatureCustomer: this.props.formData?.stopHandshake?.stopHandshakes,
      labour: this.getLabourList(),
      material: this.getMaterialList(),
    };
    this.setState({
      pdfData,
    });

    // }

  };

  getLabourList = () => {
    let extraLabour = this.props.formData?.extraResource?.labour ? this.props.formData?.extraResource?.labour : [];
    let plannedLabour = this.props.formData?.plannedResource?.labour ? this.props.formData?.plannedResource?.labour : [];
    let allLabour = _.concat(plannedLabour, extraLabour);
    let allTeam: any[] = [];
    allLabour.forEach((item: any) => {

      if (item.team && item.team.length > 0) {
        allTeam = allTeam.concat(item.team);
      }
    });

    if (allTeam && allTeam.length > 0) {
      return allTeam.map((item: any, index: any) => {
        return {
          date: formatwithoutTime(this.props.formData?.header?.executionDate),
          technician: item?.cabPerson,
          from: format24hDateTime(this.props.formData?.header?.startDateTime),
          till: format24hDateTime(this.props.formData?.header?.endDateTime),
        };
        //    return  {date:formatwithoutTime(this.props.formData.id),technician:item?.cabPerson,from:format24hDateTime(this.props.formData.id),till:format24hDateTime(this.props.formData.id)}
      });
    } else {
      return null;
    }

  };

  getMaterialList = () => {
    let extraMaterials = this.props.formData?.extraResource?.material ? this.props.formData?.extraResource?.material : [];
    let plannedMaterials = this.props.formData?.plannedResource?.material ? this.props.formData?.plannedResource?.material : [];

    let allResource = _.concat(plannedMaterials, extraMaterials);

    if (allResource && allResource.length > 0) {
      return allResource.map((item: any, index: any) => {
        return {
          resourceNumber: item.resourceNumber,
          description: item.title,
          mou: item.unit,
          quantity: item.consumedQuantity,
        };
      });
    } else {
      return null;
    }

  };


  hideModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };


  render() {
    return (<div>
      <Modal
        titleAriaId={titleId}
        isOpen={this.state.isModalOpen}
        onDismiss={this.hideModal}
        isBlocking={false}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>{i18n.t('pojectMolecule')}</span>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={this.hideModal}
          />
        </div>
        <div className={contentStyles.body}>
          {this.state.pdfData &&
          <PdfExport language={this.state.language} pdfData={this.state.pdfData} customer={null} project={null}/>}
        </div>
      </Modal>

    </div>);
  }

}


const mapStateToProps = (state: any, ownProps: any) => {
  return {
    formData: state.pmol.formDataPdf,
  };
};

const mapDispatchToProps = {
  readPMOLPDFByID,
};


export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(PmolPDFExport)),
);

import {
  ActionButton,
  ContextualMenu,
  FontWeights,
  getTheme,
  IconButton,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Modal, PrimaryButton,
  Separator,
  Stack,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../../../../theme';
import React, { useEffect, useRef, useState } from 'react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { useTranslation } from 'react-i18next';
import { formatDateTimeToISOString } from '../../../../../shared/util';
import { Field, Form } from 'react-final-form';
import { DropDownAdaptater, TextFieldFF } from '../../../../../shared/fluentFinalForm';
import { ContractListModelDTO } from '../../../../../types/humanResource';
import DatePickerRequiredAdapter from '../../../../../shared/fluentFinalForm/DatePickerRequiredAdapter';
import { v4 as uuidv4 } from 'uuid';
import { uploadContractDoc } from '../../../../../reducers/humanResourceReducer';
import { useDispatch } from 'react-redux';
import DatePickerAdapter from '../../../../../shared/fluentFinalForm/DatePickerAdapter';

const theme = getTheme();

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
  divMarginTop: { marginTop: '20px' },
  divMarginBottom: { marginBottom: '10px' },
});

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '24px 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '40vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const stackStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    flexBasis: '50%',
    height: 50,
    padding: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 0,
};

const stackItemStyles: IStackItemStyles = {
  root: {
    padding: 0,
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const uploadIconWhite: IIconProps = {
  iconName: 'Upload',
  styles: addWhiteIconButtonStyles,
};

const ContractListModel = (props: {
  isOpen: boolean;
  isEdit: boolean;
  openContractListModel: () => void;
  title: string;
  contractSelectionDetails: ContractListModelDTO[];
  onChangeStartTime?: (index: number, value: any) => void;
  onChangeEndTime?: (index: number, value: any) => void;
  createContract: (contract: any) => any;
  contractTypes: any[];
  hrId: string;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isDraggable] = useBoolean(false);
  const titleId = useId('title');
  const [contract, setContract] = useState<ContractListModelDTO>({
    id: '',
    hrId: '',
    contractTypeId: '',
    startDate: '',
    endDate: '',
    url: '',
  });
  const inputFile: any = useRef(null);
  const [fileUrl, setFileUrl] = useState<any>('');

  const getInitialFormValues = (formData: any) => {
    if (formData && formData?.id) {
      return {
        id: formData.id,
        hrId: props.hrId,
        startDate: formData.startDate ?? '',
        endDate: formData.endDate ?? '',
        contractTypeId: formData.contractTypeId,
        url: fileUrl,
      };
    } else {
      return {
        ...formData,
        hrId: props.hrId,
      }
    }
  };

  const onSubmit = async (values: any) => {
    props.createContract(values);
    props.openContractListModel();
  };

  useEffect(() => {
    setContract(props.contractSelectionDetails[0]);
    setFileUrl(props.contractSelectionDetails[0]?.url);
  }, [props.contractSelectionDetails]);

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          props.openContractListModel();
        }}
        isBlocking={true}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>
            {props.isEdit
              ? t('edit') + ' ' + props.title
              : t('add') + ' ' + props.title}
          </span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              props.openContractListModel();
              setContract({
                id: '',
                hrId: '',
                startDate: '',
                endDate: '',
                contractTypeId: '',
                url: '',
              });
            }}
          />
        </div>

        <div className={contentStyles.body}>
          <div className="proj-detail-block">
            <Form
              onSubmit={onSubmit}
              initialValues={getInitialFormValues(contract)}
              mutators={{
                setEndDate: (args, state, utils) => {
                  const field = state.fields['endDate'];
                  field.change(args[0]);
                },
                setContractUrl: (args, state, utils) => {
                  const field = state.fields['url'];
                  field.change(args[0]);
                }
              }}
              validate={values => {
                const errors: any = {};
                if (!values?.startDate) {
                  errors.startDate = t('startDateRequired');
                }
                // if (!values?.endDate) {
                //   errors.endDate = t('endDateRequired');
                // }
                if (!values?.contractTypeId) {
                  errors.contractTypeId = t('contractTypeRequired');
                }
                return errors;
              }}
              render={({
                         handleSubmit,
                         form,
                         submitting,
                         pristine,
                         errors,
                         values,
                       }) => {
                return (
                  <form onSubmit={handleSubmit} noValidate>
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="startDate"
                            component={DatePickerRequiredAdapter}
                            lable={t('startDate')}
                            parse={(value) => {
                              if (value) {
                                if (values?.allDay) {
                                  form.mutators.setEndDate(formatDateTimeToISOString(value));
                                  return formatDateTimeToISOString(value);
                                } else {
                                  return formatDateTimeToISOString(value);
                                }
                              }
                            }
                            }
                            format={(value) => (value ? new Date(value) : null)}
                            errorMessage={
                              form.getState()?.errors?.startDate
                            }
                          />
                        </div>
                      </Stack.Item>

                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="endDate"
                            component={DatePickerAdapter}
                            lable={t('endDate')}
                            parse={(value) =>
                              value && formatDateTimeToISOString(value)
                            }
                            format={(value) => (value ? new Date(value) : null)}
                            minDate={
                              new Date(form.getFieldState('startDate')?.value)
                            }
                            disabled={false}
                            errorMessage={
                              form.getState()?.errors?.startDate
                            }
                            required={false}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>

                    <Separator/>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.halfWidth}`}>
                          <Field
                            name="contractTypeId"
                            component={DropDownAdaptater}
                            lable={t('contractType')}
                            options={props.contractTypes}
                            placeholder={t('contractType')}
                            required={true}
                            errorMessage={
                              form.getState()?.errors?.contractTypeId
                            }
                          />
                        </div>
                      </Stack.Item>
                    </Stack>

                    <Separator/>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >

                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'row' }}>
                            <Field
                              name="url"
                              component={TextFieldFF}
                              lable={t('contractUrl')}
                              disabled={false}
                              placeholder={t('contractUrl')}
                              required={false}
                              readOnly={true}
                              className={`${classNames.fullWidth}`}
                            />
                            <input
                              type="file"
                              id="file"
                              ref={inputFile}
                              hidden
                              onChange={(event: any) => {
                                const formData = new FormData();
                                if (event?.target?.files[0]) {
                                  formData.append('file', event.target.files[0]);
                                  formData.append('id', uuidv4());
                                  Promise.all([
                                    dispatch(uploadContractDoc(formData))
                                  ]).then((response: any) => {
                                    if (response[0]?.payload?.data?.result) {
                                      form.mutators.setContractUrl(response[0]?.payload?.data?.result);
                                      console.log('fileUrl : ', response[0]?.payload?.data?.result);
                                      setFileUrl(response[0]?.payload?.data?.result)
                                    } else {
                                      form.mutators.setContractUrl(null);
                                    }
                                  });
                                }
                              }}
                            />

                            <PrimaryButton
                              iconProps={uploadIconWhite}
                              text={t('upload')}
                              disabled={false}
                              style={{ marginTop: 10, marginLeft: 10 }}
                              onClick={() => {
                                inputFile?.current?.click();
                              }}
                            />
                          </div>
                        </div>
                      </Stack.Item>

                    </Stack>

                    <div className={'card-footer'}>
                      <Separator/>
                      <Stack
                        styles={stackStyles}
                        tokens={itemAlignmentsStackTokens}
                      >
                        <Stack.Item
                          align="end"
                          styles={stackFooterBarItemStyles}
                        >
                          <ActionButton
                            className={classNames.actionButton}
                            iconProps={saveIcon}
                            allowDisabledFocus
                            onClick={() => {
                              form.submit();
                              errors == null ? form.reset() : undefined;
                            }}
                          >
                            <Label className={classNames.actionButton}>
                              {t('save')}
                            </Label>
                          </ActionButton>
                        </Stack.Item>
                      </Stack>
                    </div>
                     {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
                  </form>
                );
              }}
            />
          </div>
        </div>

      </Modal>
    </div>
  );
};

export default ContractListModel;

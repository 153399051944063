import {
  LOAD_CONTACT_PREF,
  LOAD_CONTACT_PREF_FAIL,
  LOAD_CONTACT_PREF_SUCCESS,
  LOAD_FAIL,
  LOAD_JOB_ROLES,
  LOAD_JOB_ROLES_FAIL,
  LOAD_JOB_ROLES_SUCCESS,
  LOAD_SCCUESS,
  LOAD_SERVICE,
  SAVE_ORG_PRIMARY_CONTACT,
  SAVE_ORG_PRIMARY_CONTACT_FAIL,
  SAVE_ORG_PRIMARY_CONTACT_SUCCESS,
} from '../actionTypes/organizationType';

const defaultState: any = {
  localeList: [],
  roles: [],
};

export default function serviceReducer(state = defaultState, action: any) {
  switch (action.type) {
    case LOAD_SERVICE:
      return { ...state, loading: true };
    case LOAD_SCCUESS:
      // //console.log(
      //   'reducer locale.payload: pppp' + JSON.stringify(action.payload)
      // );
      return { ...state, loading: false, localeList: action.payload };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        error: 'Error while fetching locales',
      };
    case SAVE_ORG_PRIMARY_CONTACT:
      return { ...state, loading: true };
    case SAVE_ORG_PRIMARY_CONTACT_SUCCESS:
      return { ...state, loading: false, res: action.payload };
    case SAVE_ORG_PRIMARY_CONTACT_FAIL:
      return {
        ...state,
        loading: false,
        error: 'Error while saving contact',
      };
    case LOAD_JOB_ROLES:
      return { ...state, loading: true };
    case LOAD_JOB_ROLES_SUCCESS:
      return { ...state, loading: false, roles: action.payload.data.result };
    case LOAD_JOB_ROLES_FAIL:
      return {
        ...state,
        loading: false,
        error: 'Error while fetching locales',
      };

    case LOAD_CONTACT_PREF:
      return { ...state, loading: true };
    case LOAD_CONTACT_PREF_SUCCESS:
      return {
        ...state,
        loading: false,
        contactPreference: action.payload.data.result,
      };
    case LOAD_CONTACT_PREF_FAIL:
      return {
        ...state,
        loading: false,
        error: 'Error while fetching locales',
      };
    default:
      return state;
  }
}

export function saveService() {
  return {
    type: SAVE_ORG_PRIMARY_CONTACT,
    payload: {
      request: {
        url: 'UPrinceCustomerPrimaryContact/Create',
        method: 'POST',
        data: {
          name: 'mesith',
          phone: '0716426218',
          email: 'mesith@gmail.com',
        },
      },
    },
  };
}

export function getOrganizationJobRoles() {
  return {
    types: [LOAD_JOB_ROLES, LOAD_JOB_ROLES_SUCCESS, LOAD_JOB_ROLES_FAIL],
    payload: { request: { url: 'UPrinceCustomerJobRole/Read' } },
  };
}

export function getOrganizationContactPreference() {
  return {
    type: LOAD_CONTACT_PREF,
    payload: { request: { url: 'UPrinceCustomerContactPreference/Read' } },
  };
}

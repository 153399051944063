import React from 'react';
import {MessageBar, MessageBarType} from '@fluentui/react';

export interface State {
  showMsg: boolean;
  msg: string;
  msgType: string;
}

interface Props {
  showMsg: boolean;
  msg: string;
  msgType: string;
  handleCustomAlert: any;
  isMultiline?: boolean;
  width?: any;
}

class CustomAlert extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showMsg: false,
      msgType: '',
      msg: '',
    };
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    ////console.log("nextProps",nextProps.selectedProject);
    return {
      showMsg: nextProps.showMsg,
      msgType: nextProps.msgType,
      msg: nextProps.msg,
    };
  }

  private showMsg() {
    if (this.state.msg && this.state.showMsg) {
      if (this.state.msgType == 'success') {
        return (
            <MessageBar
                messageBarType={MessageBarType.success}
                isMultiline={this.props.isMultiline ?? false}
                onDismiss={this.resetMsg}
                dismissButtonAriaLabel="Close"
            >
              {this.state.msg}
            </MessageBar>
        );
      } else {
        return (
            <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={this.props.isMultiline ?? false}
                onDismiss={this.resetMsg}
                dismissButtonAriaLabel="Close"
            >
              {this.state.msg}
            </MessageBar>
        );
      }
    }
  }

  private resetMsg = () => {
    this.setState({
      showMsg: false,
      saveMsg: '',
      saveMsgType: '',
    });
    this.props.handleCustomAlert(false);
  };

  render() {
    if (this.props.width) {
      return <div style={{ width: this.props.width }}>{this.showMsg()}</div>;
    } else {
      return <span>{this.showMsg()}</span>;
    }

  }
}

export default CustomAlert;

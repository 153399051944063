import * as React from 'react';
import { useEffect, useState } from 'react';
import Input from '../../../shared/fluentUI9/Input/Input';
import { Icon, mergeStyleSets } from '@fluentui/react';
import { Field, Form } from 'react-final-form';
import { createForm } from 'final-form';
import TextEditor from '../../../shared/textEditor/textEditor';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { messageService } from '../../../services/messageService';
import Tags from '../../wbsTask/documentPane/components/Tags';
import PeopleTags from '../../wbsTask/documentPane/components/PeopleTags';
import Document from '../../wbsTask/documentPane/components/Document';
import { Divider, Label } from '@fluentui/react-components';
import TaskComponent from '../../../shared/TaskComponent/component';
import { createWbsProduct, getWbsProductById, readWbsTaxonomyByTemId } from '../../../reducers/wbsCreateReducer';
import WbsProductTaxonomy from './component/WbsCreateProductTaxonomy';
import { ParamTypes } from '../../../types/uPrince';
import { getContractingUnit, getProject } from '../../../shared/util';
import { getWbsTaxonomy, saveWbsTaskStateAttr } from '../../../reducers/wbsTaskReducer';
import ConversationComponent from '../../wbsTask/documentPane/components/ConversationComponent';
import { saveWbsStateAttr } from '../../../reducers/wbsReducer';
import history from '../../../history';
// import conversations from '../../wbsTask/documentPane/components/ConversationData.json';

const classNames = mergeStyleSets({
  fullWidth: { width: '100%', marginTop: 20 },
  halfWidth: { width: '49%' },
  addNewProductForm: {
    height: '100%',
    width: '99%',
    overflow: 'auto',
  },
  productForm: {
    width: 'auto',
  },
});

const WbsCreateProductDocumentPane = (props: { isNewProduct?: any }) => {
  const { t } = useTranslation();
  const params = useParams();
  const { featureId, templateId }: ParamTypes = params;
  const dispatch = useDispatch();

  const formData = useSelector((state: any) => state.wbsCreate.wbsProductFormData);
  // const showProgressBar = useSelector((state: any) => state.wbsCreate.showWbsProgressBar);
  // const reloadDocPane = useSelector((state: any) => state.wbsTask.reloadDocPane);
  const taskComponentRefresh = useSelector((state: any) => state.wbsTask.reloadListPane);

  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    const subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.saveWbsProduct) {
          formRef.current.submit();
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);


  useEffect(() => {
    setIsSaved(false);
  }, [props?.isNewProduct]);

  useEffect(() => {
    if (taskComponentRefresh) {
      dispatch(getWbsProductById(formData?.id));
      dispatch(saveWbsTaskStateAttr('reloadListPane', false));
    }
  }, [taskComponentRefresh]);

  const getPayloadId = (response: any) => {
    if (response && response[0]) {
      let payload = response[0]?.payload;

      return payload?.data?.result;
    }
    return null;
  };

  /**
   * @createWbsProduct save the product form on submit and reload the list pane and document pane
   * @param values  form values
   * @readWbsTaxonomyByTemId uses this time out of 200ms because it had a issue with the backend data fetching. ie: issue is from backend
   */

  const onSubmit = async (values: any) => {
    console.log({ values });
    let productId: string | undefined;
    let tempId: string | undefined;

    Promise.all([dispatch(createWbsProduct(values))]).then((res: any) => {
      console.log(res[0].payload.data.result);
      const result = res[0].payload.data.result;

      if (getProject()) {
        productId = values?.id ? values?.id : getPayloadId(res);
        dispatch(getWbsTaxonomy());
      } else {
        productId = templateId;
        tempId = featureId;

        setTimeout(() => {
          dispatch(readWbsTaxonomyByTemId(tempId));
        }, 200);
      }
      setIsSaved(true);
      if (productId) {
        dispatch(getWbsProductById(productId));
      } else {
        history.push({
          pathname: `/CU/${getContractingUnit()}/wbs-create/${featureId}/template/${result}`,
        });
      }
    });
  };

  const getInitialFormValues = () => {
    if (props?.isNewProduct && !isSaved) {
      // if (formRef?.current?.getState().values?.id) {
      //   return { ...formRef?.current?.getState().values };
      // }
      return {
        id: null,
        name: null,
        note: null,
        wbsTaxonomyId: props?.isNewProduct?.id,
        tags: [],
        toPerson: [],
        cc: [],
        tasks: [],
        documents: [],
        conversations: [],
        timeLine: [],
      };
    } else if (formData && formData?.id) {
      return { ...formData, productTitle: `${formData?.productId} - ${formData?.name}` };
    } else {
      return {
        id: null,
        name: null,
        note: null,
        wbsTaxonomyId: null,
        tags: [],
        toPerson: [],
        cc: [],
        tasks: [],
        documents: [],
        conversations: [],
        timeLine: [],
      };
    }
  };

  const formRef = React.useRef(createForm({ onSubmit: onSubmit }));

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={getInitialFormValues()}
        mutators={{
          setToPersons: (args, state, utils) => {
            const field = state.fields.toPerson;
            field.change(args[0]);
          },
          setCcPersons: (args, state, utils) => {
            const field = state.fields.cc;
            field.change(args[0]);
          },
        }}
        render={({
                   handleSubmit,
                   form,
                   submitting,
                   pristine,
                   values,
                 }) => {
          formRef.current = form;
          return (
            <form onSubmit={handleSubmit} noValidate
                  className={props?.isNewProduct ? classNames?.addNewProductForm : classNames?.productForm}>
              <div className='document-pane-card'>
                <Field
                  name='toPerson'
                  component={'input'}
                  type={'text'}
                  disabled={true}
                  hidden
                />
                <Field
                  name='cc'
                  component={'input'}
                  type={'text'}
                  disabled={true}
                  hidden
                />

                <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                  <div className={`${classNames.fullWidth}`}>
                    <Field name='productTitle'>
                      {({ input, meta }) => (
                        <Input
                          onChange={(value: any) => input.onChange(value)}
                          // placeholder={t('productName')}
                          // contentBefore={<Icon iconName={'Product'} />}
                          appearance='underline'
                          value={values?.productTitle}
                          disabled={true}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={`${classNames.fullWidth}`}>
                    <Field name='productId'>
                      {({ input, meta }) => (
                        <Input
                          onChange={(value: any) => input.onChange(value)}
                          // placeholder={t('productName')}
                          // contentBefore={<Icon iconName={'Product'} />}
                          appearance='underline'
                          value={values?.productId}
                          disabled={true}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <Field name='name'>
                  {({ input, meta }) => (
                    <Input
                      onChange={(value: any) => input.onChange(value)}
                      placeholder={t('productName')}
                      contentBefore={<Icon iconName={'Product'} />}
                      appearance='underline'
                      value={values?.name}
                      focus={true}
                    />
                  )}
                </Field>

                <div className={`${classNames.fullWidth} ck-small-toolbar`}>
                  <Label size='medium' weight='semibold'>{`${t('note')}`}</Label>
                  <Field name='note'>
                    {({ input, meta }) => (
                      <TextEditor
                        value={values?.note ?? ''}
                        onChange={(value: any) => input.onChange(value)}
                      />
                    )}
                  </Field>
                </div>

                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name='wbsTaxonomyId'
                  >
                    {({ input, meta }) => (
                      <WbsProductTaxonomy
                        disabled={false}
                        value={values?.wbsTaxonomyId ?? ''}
                        onChange={(value: any) => input.onChange(value)}
                      />
                    )}
                  </Field>
                </div>

                <div className={`${classNames.fullWidth}`}>
                  <Field name='tags'>
                    {({ input, meta }) => (
                      <Tags
                        value={values?.tags ?? []}
                        onChange={(value: any) => input.onChange(value)}
                      />
                    )}
                  </Field>
                </div>

                <div className={`${classNames.fullWidth}`}>
                  <Field name='peopleTags'>
                    {({ input, meta }) => (
                      <PeopleTags
                        to={values?.toPerson ? values?.toPerson : []}
                        cc={values?.cc ? values?.cc : []}
                        onToChange={(value: any) => {
                          if (value) {
                            // console.log("To",{value});
                            form.mutators.setToPersons(value);
                          }
                        }}
                        onCCChange={(value: any) => {
                          if (value) {
                            console.log('cc', { value });
                            form.mutators.setCcPersons(value);
                          }
                        }}
                      />
                    )}
                  </Field>
                </div>

                <div className={`${classNames.fullWidth}`}>
                  <Field name='tasks'>
                    {({ input, meta }) => (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>
                          <TaskComponent
                            tasks={values?.tasks ?? []}
                            onChange={() => form.submit()}
                            isDisabled={props?.isNewProduct && !isSaved}
                            isTaskDeleteEnabled={false}
                            onClickAddTask={() => {
                              if (window?.location?.pathname?.split('/')?.pop() != 'new') {
                                messageService.sendMessage({
                                  isOpenTaskModal: true,
                                  selectedTask: {
                                    id: null,
                                    pbsId: values?.id,
                                    productId: values?.productId,
                                    issueId: values?.id,
                                  },
                                });
                              }

                              messageService.sendMessage({ resetWbsTaskForm: true });
                              dispatch(saveWbsStateAttr('taxonomyFeatureId', featureId));
                            }}
                            onClickTitle={(item: any) => {
                              messageService.sendMessage({ isOpenTaskModal: true, selectedTask: item });
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </Field>
                </div>

                <div className={`${classNames.fullWidth}`}>
                  <Field name='documents'>
                    {({ input, meta }) => (
                      <Document
                        documents={values?.documents ?? []}
                        onChange={(value: any) => input.onChange(value)}
                      />
                    )}
                  </Field>
                </div>

                {getProject() && <div className={`${classNames.fullWidth}`}>
                  <Field name='conversations'>
                    {({ input, meta }) => (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex' }}>
                          <Label style={{ marginTop: 20 }} size='large'> {t('conversations')} </Label>
                        </div>
                        <div style={{ marginTop: 5, marginBottom: 5 }}><Divider /></div>
                        <div>
                          <ConversationComponent
                            conversations={values.conversations ?? []}
                          />
                        </div>
                      </div>
                    )}
                  </Field>
                </div>}

                {/*<div className={`${classNames.fullWidth}`}>*/}
                {/*  <Field name='timeLine'>*/}
                {/*    {({ input, meta }) => (*/}
                {/*      <div style={{ display: 'flex', flexDirection: 'column' }}>*/}
                {/*        <div style={{ display: 'flex' }}>*/}
                {/*          <Label style={{ marginTop: 20 }} size='large'> {t('timeLine')} </Label>*/}
                {/*        </div>*/}
                {/*        <div style={{ marginTop: 5, marginBottom: 5 }}><Divider /></div>*/}
                {/*        <div>*/}
                {/*          /!*<TimeLine />*!/*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*    )}*/}
                {/*  </Field>*/}
                {/*</div>*/}
              </div>
              {/*<div style={{ width: 500, height: 600, overflow: 'auto' }}>*/}
              {/*  <pre>  {JSON.stringify(values, null, 2)}</pre>*/}
              {/*</div>*/}
            </form>
          );
        }}
      />
    </>
  );

};

export default WbsCreateProductDocumentPane;

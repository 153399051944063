import * as XLSX from 'xlsx';
import moment from 'moment';

const download = async (url: string, name: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  a.click();

  window.URL.revokeObjectURL(url);
  return true;
};

function s2ab(s: string) {
  const buf = new ArrayBuffer(s.length);

  const view = new Uint8Array(buf);

  for (let i = 0; i !== s.length; ++i) {
    view[i] = s.charCodeAt(i) & 0xFF;
  }

  return buf;
}

const excelExportOld = async (dataX: []) => {

  const today = moment().format('YYYYMMDD');
  const FileName = today + ' - Shift.xlsx';
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataX);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Shift-Report');
  const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });
  const url = window.URL.createObjectURL(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }));
  let downloaded = await download(url, FileName);
  return true;
};

const excelExport = async (json: []) => {
  const today = moment().format('YYYYMMDD');
  const FileName = today + ' - Shift.xlsx';
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { skipHeader: false, dateNF: 'm/d/yyyy h:mm:ss' });
  /* choose columns (with header) which one you convert into text format*/
  var C1 = XLSX.utils.decode_col('A');
  var C2 = XLSX.utils.decode_col('B');
  var C3 = XLSX.utils.decode_col('F');
  var C4 = XLSX.utils.decode_col('G');
  var C5 = XLSX.utils.decode_col('H');
  var C6 = XLSX.utils.decode_col('I');
  var C7 = XLSX.utils.decode_col('J');
  var C8 = XLSX.utils.decode_col('K');


  var fmt = 'm/d/yyyy h:mm:ss'; /* for text format*/
  var fmt2 = '[hh]:mm:ss'; /* for text format*/
  /*also use these formats  " b Boolean, e Error, n Number, d Date, s Text, z Stu,"*/
  if (worksheet['!ref']) {
    var range = XLSX.utils.decode_range(worksheet['!ref']);
    for (var i = range.s.r + 1; i <= range.e.r; ++i) {

      /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
      var ref1 = XLSX.utils.encode_cell({ r: i, c: C1 });
      var ref2 = XLSX.utils.encode_cell({ r: i, c: C2 });
      var ref3 = XLSX.utils.encode_cell({ r: i, c: C3 });
      var ref4 = XLSX.utils.encode_cell({ r: i, c: C4 });
      var ref5 = XLSX.utils.encode_cell({ r: i, c: C5 });
      var ref6 = XLSX.utils.encode_cell({ r: i, c: C6 });
      var ref7 = XLSX.utils.encode_cell({ r: i, c: C7 });
      var ref8 = XLSX.utils.encode_cell({ r: i, c: C8 });
      /* if the particular row did not contain data for the column, the cell will not be generated */


      /* assign the `.z` Text format */
      worksheet[ref1].z = fmt;
      worksheet[ref2].z = fmt;
      worksheet[ref3].z = fmt;
      worksheet[ref4].z = fmt;
      worksheet[ref5].z = fmt;
      worksheet[ref6].z = fmt;
      worksheet[ref7].z = fmt2;
      worksheet[ref8].z = fmt2;
    }

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', bookSST: true, type: 'binary', cellDates: true });
    const url = window.URL.createObjectURL(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }));
    let downloaded = await download(url, FileName);
  }

  return true;
};

export default excelExport;

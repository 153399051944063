export const LOAD_CORPORATE_SCHEDULE_SHORTCUTPANE = 'uprince/LOAD_CORPORATE_SCHEDULE_SHORTCUTPANE';
export const LOAD_CORPORATE_SCHEDULE_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_CORPORATE_SCHEDULE_SHORTCUTPANE_SUCCESS';
export const LOAD_CORPORATE_SCHEDULE_SHORTCUTPANE_FAIL = 'uprince/LOAD_CORPORATE_SCHEDULE_SHORTCUTPANE_FAIL';

export const LOAD_CORPORATE_SCHEDULE_LSITPANE = 'uprince/LOAD_CORPORATE_SCHEDULE_LSITPANE';
export const LOAD_CORPORATE_SCHEDULE_LSITPANE_SUCCESS = 'uprince/LOAD_CORPORATE_SCHEDULE_LSITPANE_SUCCESS';
export const LOAD_CORPORATE_SCHEDULE_LSITPANE_FAIL = 'uprince/LOAD_CORPORATE_SCHEDULE_LSITPANE_FAIL';

export const LOAD_CORPORATE_SCHEDULE_DROPDOWN = 'uprince/LOAD_CORPORATE_SCHEDULE_DROPDOWN';
export const LOAD_CORPORATE_SCHEDULE_DROPDOWN_SUCCESS = 'uprince/LOAD_CORPORATE_SCHEDULE_DROPDOWN_SUCCESS';
export const LOAD_CORPORATE_SCHEDULE_DROPDOWN_FAIL = 'uprince/LOAD_CORPORATE_SCHEDULE_DROPDOWN_FAIL';

export const SAVE_CORPORATE_SCHEDULE = 'uprince/SAVE_CORPORATE_SCHEDULE';
export const SAVE_CORPORATE_SCHEDULE_SUCCESS = 'uprince/SAVE_CORPORATE_SCHEDULE_SUCCESS';
export const SAVE_CORPORATE_SCHEDULE_FAIL = 'uprince/SAVE_CORPORATE_SCHEDULE_FAIL';

export const LOAD_CORPORATE_SCHEDULE_BY_ID = 'uprince/LOAD_CORPORATE_SCHEDULE_BY_ID';
export const LOAD_CORPORATE_SCHEDULE_BY_ID_SUCCESS = 'uprince/LOAD_CORPORATE_SCHEDULE_BY_ID_SUCCESS';
export const LOAD_CORPORATE_SCHEDULE_BY_ID_FAIL = 'uprince/LOAD_CORPORATE_SCHEDULE_BY_ID_FAIL';

export const LOAD_BOR_RESOURCES_BY_ID = 'uprince/LOAD_BOR_RESOURCES_BY_ID';
export const LOAD_BOR_RESOURCES_BY_ID_SUCCESS = 'uprince/LOAD_BOR_RESOURCES_BY_ID_SUCCESS';
export const LOAD_BOR_RESOURCES_BY_ID_FAIL = 'uprince/LOAD_BOR_RESOURCES_BY_ID_FAIL';

export const SET_CORPORATE_SCHEDULE_UID = 'uprince/SET_CORPORATE_SCHEDULE_UID';
export const CLEAR_CORPORATE_SCHEDULE_UID = 'uprince/CLEAR_CORPORATE_SCHEDULE_UID';
export const CLEAR_CORPORATE_SCHEDULE_FORM_DATA = 'uprince/CLEAR_CORPORATE_SCHEDULE_FORM_DATA';
export const RESET_CORPORATE_SCHEDULE_IS_CHANGE = 'uprice/RESET_CORPORATE_SCHEDULE_IS_CHANGE';


export const CLEAR_CORPORATE_SCHEDULEUID = 'uprice/CLEAR_CORPORATE_SCHEDULEUID';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IColumn, Selection } from '@fluentui/react';
import history from '../../../history';

import ListPaneComponent from './component';
import { withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { readQualityDropDownData } from '../../../reducers/qualityReducer';
import { messageService } from '../../../services/messageService';
import { formData, Quality, QualityListItem, QualityListPaneFilter } from '../../../types/quality';
import { getContractingUnit, getProject } from '../../../shared/util';

export interface State {
  redirect: boolean;
  isChange: boolean;
  loading: boolean;
  filter: QualityListPaneFilter;
  announcedMessage: string;
  selectedListItemIds: [] | string[];
  selectedListItemSequenceCode: [] | string[];
  formData: Quality;
  selectedListItems: QualityListItem[];
}

export interface Props extends WithTranslation {
  listPaneItems: QualityListItem[];
  reloadListPaneItem: () => void;
  handelFilter: (filter: QualityListPaneFilter) => void;
  isDataLoaded?: boolean;
  isChange: boolean;
  filter: QualityListPaneFilter;
  resourceTypes: [];
  loadMsg: string;
  toleranceStatesFilter: [];
  productStatesFilter: [];
  itemTypesFilter: [];
  handleSelectedListPaneItem: (selectedItemIds: [] | string[]) => void;
  formData: Quality;
  projectId: string | null;
  // qlDropDowns: QualityDropDowns;
  reSizer: number;
  readQualityDropDownData: () => void;
}

class ListPane extends Component<Props, State> {
  private _Selection: Selection;
  private _columns: IColumn[];
  private _columnsReSizer: IColumn[];
  private _columnsCu: IColumn[];

  subscription: any;

  constructor(props: Props) {
    super(props);
    this._Selection = new Selection({
      onSelectionChanged: () => {
        this.setState(
          {
            selectedListItemIds: this._getSelectedIds(),
            selectedListItemSequenceCode: this._getselectedSequenceCode(),
            selectedListItems: this._getSelectedListItems(),
          },
          this.handleSelectedItem,
        );
      },
    });
    this.state = {
      selectedListItems: [],
      selectedListItemIds: [],
      selectedListItemSequenceCode: [],
      redirect: false,
      isChange: false,
      loading: false,
      announcedMessage: '',
      filter: new QualityListPaneFilter(),
      formData: formData,
    };

    this._columns = [

      {
        key: 'column1',
        name: this.props.t('qualityTitle'),   //field names related to api call
        fieldName: 'title',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },


      {
        key: 'column2',
        name: this.props.t('qualityCriteria'),
        fieldName: 'criteria',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        // onRender: (item: QualityListItem) => {
        //   let cleanText = item.criteria?item.criteria!!.replace(/<\/?[^>]+(>|$)/g, ""):'-';
        //   return <div>
        //     {cleanText}
        //   </div>;
        // },
      },
      {
        key: 'column3',
        name: this.props.t('qualityTolerance'),
        fieldName: 'tolerance',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        // onRender: (item: QualityListItem) => {
        //   let cleanText = item.tolerance?item.tolerance!!.replace(/<\/?[^>]+(>|$)/g, ""):'-';
        //   return <div>
        //     {cleanText}
        //   </div>;
        // },
      },
      {
        key: 'column4',
        name: this.props.t('qualityMethod'),
        fieldName: 'method',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column5',
        name: this.props.t('qualitySkillRequired'),
        fieldName: 'skills',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },

    ];

    this._columnsCu = [
      {
        key: 'column1',
        name: this.props.t('title'),
        fieldName: 'headerTitle',
        minWidth: 100,
        maxWidth: 120,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      // {
      //   key: 'column2',
      //   name: this.props.t('ID'),
      //   fieldName: 'id',
      //   minWidth: 100,
      //   maxWidth: 200,
      //   isResizable: true,
      //   onColumnClick: this._onColumnClick,
      // },
      {
        key: 'column2',
        name: this.props.t('qualityCriteria'),
        fieldName: 'criteria',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        // onColumnClick: this._onColumnClick,
        // onRender: (item: QualityListItem) => {
        //   let cleanText = item.criteria?item.criteria!!.replace(/<\/?[^>]+(>|$)/g, ""):'-';
        //   return <div>
        //     {cleanText}
        //   </div>;
        // },
      },
      {
        key: 'column3',
        name: this.props.t('qualityTolerance'),
        fieldName: 'tolerance',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column4',
        name: this.props.t('qualityMethod'),
        fieldName: 'method',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column5',
        name: this.props.t('qualitySkillRequired'),
        fieldName: 'skills',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
    ];

    this._columnsReSizer = [
      {
        key: 'column1',
        name: this.props.t('title'),
        fieldName: 'headerTitle',
        minWidth: 165,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
    ];

  }

  // static getDerivedStateFromProps(nextProps: any) {
  //   if (nextProps.isChange) {
  //     nextProps.reloadListPaneItem();
  //   }
  //   return {
  //     loading: false,
  //     filter: nextProps.filter,
  //     formData: nextProps.formData,
  //   };
  // }

  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewQL) {
          this._Selection.selectToKey('', true);
        }
        if (data.data.resetListPaneSelection) {
          this._Selection.setAllSelected(false);
          this.resetSelection();
        }
      }
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.isDataLoaded != this.props.isDataLoaded) {
      if (this.props.isDataLoaded) {
        this.resetSelection();
      }
    }
  }

  //------------ Get Listpane item's data to DocumentPane-------------//

  handleListPaneItemClick = (id: string) => {
    //this.props.getById(id);
    if (getContractingUnit() && !getProject()) {
      history.push(`/CU/${getContractingUnit()}/quality/${id}`);
    } else {
      history.push(`/CU/${getContractingUnit()}/project/${getProject()}/quality/${id}`);
    }
  };

  // handleListPaneItemClick = (id: string) => {
  //   //this.props.getById(id);
  //   if (getContractingUnit() && !getProject()) {
  //     history.push(`/CU/${getContractingUnit()}/quality/${id}`);
  //   }else{
  //     history.push(`/CU/${getContractingUnit()}/project/${getProject()}/quality/new`);
  //   }
  // };

  //  handleSelectedItem = () => {
  //    this.props.handleSelectedListPaneItem(this.state.selectedListItemIds);
  //    if (this.state.selectedListItemSequenceCode) {
  //      let lastId = this.state.selectedListItemSequenceCode.pop();
  //      if (lastId) {
  //        this.handleListPaneItemClick(lastId);
  //      }
  //    }
  //  };

  handleSelectedItem = () => {
    this.props.handleSelectedListPaneItem(this.state.selectedListItemIds);
    if (this.state.selectedListItemSequenceCode) {
      let lastId = this.state.selectedListItemSequenceCode.pop();
      if (lastId) {
        if (getContractingUnit() && !getProject()) {
          history.push(`/CU/${getContractingUnit()}/quality/${lastId}`);
        } else {
          history.push(`/CU/${getContractingUnit()}/project/${getProject()}/quality/${lastId}`);
        }
      }
    }
  };

  handleFilterChange = (filter: QualityListPaneFilter) => {
    this.props.handelFilter(filter);
  };

  handleFilterSortChange = () => {
    this.props.handelFilter(this.state.filter);
  };

  resetSelection = () => {
    if (
      this.state.formData &&
      this.state.formData.id &&
      this.props.isDataLoaded
    ) {
      this._Selection.setKeySelected(
        this.state.formData.id,
        true,
        false,
      );
    } else {
      this._Selection.selectToKey('', true);
    }
  };

  render() {
    return (
      <div>
        <ListPaneComponent
          listPaneItems={
            this.props.listPaneItems ? this.props.listPaneItems : []
          }
          selection={this._Selection}
          handleListPaneItemClick={(id: string) =>
            this.handleListPaneItemClick(id)
          }
          resetSelection={() => this.resetSelection()}
          formData={this.state.formData}
          columns={(getProject()) ? (this.props.reSizer === 300) ? this._columnsReSizer : this._columns : (this.props.reSizer === 300) ? this._columnsReSizer : this._columnsCu}
          filter={this.props.filter}
          loadMsg={this.props.loadMsg}
          handleFilterChange={(filter: QualityListPaneFilter) =>
            this.handleFilterChange(filter)
          }
          isDataLoaded={this.props.isDataLoaded}
          // dropDownOptions={this.props.qlDropDowns}
        />
      </div>
    );
  }

  private _getSelectedIds() {
    let deleteList: any = [];
    let SelectedItem = this._Selection.getSelection() as QualityListItem[];
    if (SelectedItem) {
      deleteList = SelectedItem.map((item: any) => {
        return item ? item.id : null;
      });
    }
    return deleteList;
  }

  private _getselectedSequenceCode() {
    let selectedList: any = [];
    let selectedItem = this._Selection.getSelection() as QualityListItem[];
    if (selectedItem) {
      selectedList = selectedItem.map((item: any) => {
        return item ? item.progressStatementId : null;
      });
    }
    return selectedList;
  }

  private _getSelectedListItems = () => {
    let SelectedListItems = this._Selection.getSelection() as QualityListItem[];

    return SelectedListItems;
  };

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn,
  ): void => {
    const newColumns: IColumn[] = this._columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key,
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        let filter = this.state.filter;

        let fieldName = currColumn.fieldName;

        let sortby = currColumn.isSortedDescending ? 'desc' : 'asc';
        filter.sortingModel.attribute = fieldName ? fieldName : null;
        filter.sortingModel.order = sortby ? sortby : null;
        this.setState(
          {
            filter: filter,
          },
          this.handleFilterSortChange,
        );
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    // toleranceStatesFilter:state.projectBreakdown.toleranceStatesFilter,
    // productStatesFilter: state.projectBreakdown.productStatesFilter,
    // itemTypesFilter: state.projectBreakdown.itemTypesFilter,
    isChange: state.ql.isChange,
    qlDropDowns: state.ql.qlDropDowns,
  };
};

const mapDispatchToProps = {
  readQualityDropDownData,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ListPane)),
);

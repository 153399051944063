export class PSListPaneFilter {
  id: string | null = null;
  progressStatementId: string | null = null;
  project: string | null = null;
  projectSequenceCode: string | null = null;
  title: string | null = null;
  totalAmount: string | null = null;
  status: string | number | null = null;
  date: string | Date | null = null;
  sorter: Sorter = new Sorter();
}

export interface PSShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
  value: number | null
}

class Sorter {
  attribute: string | null = null;
  order: string | null = null;
}

export interface PSShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
}

export class ListPaneItem {
  id: string | null = null;
  progressStatementId: string | null = null;
  name: string | null = null;
  title: string | null = null;
  date: string | null = null;
  type: string | null = null;
  status: string | null = null;
  typeId: string | null = null;
  statusId: string | null = null;
  project: string | null = null;
  projectSequenceCode: string | null = null;
  totalAmount: string | null = null;
}

export interface PSDropDown {
  status: DropDown[];
  type: DropDown[];
}

export interface DropDown {
  key: string;
  text: string;
}


export interface History {
  createdByUser: string | null;
  createdDateTime: string | null;
  revisionNumber: number | string | null;
  updatedByUser: string | null;
  updatedDateTime: string | null;
}


export interface ProgressStatement {
  id: string | null;
  progressStatementId: string | null;
  title: string | null;
  headerTitle: string | null;
  name: string | null;
  projectTypeId: string | null;
  projectType: string | null;
  projectStatusId: string | null;
  projectStatus: string | null;
  projectCostId: string | null;
  workPeriodFrom: string | Date | null;
  workPeriodTo: string | Date | null;
  projectCompletionDate: string | Date | null;
  purchaseOrderNumber: string | null;
  projectSequenceCode: string | null;
  historyLog: History | null;
  resources: Cost[],
  isSaved: boolean;
  date: string | null;
  totalAmount: number | null;
  isProjectFinished: boolean;
  generalLedgerId: string | null;
  invoiceComment: string | null;
  projectScopeStatusId: string | null;
  projectScope: string | null;
  contractingUnitId:string | null;
  customerName :string | null ;
  customerId:string|null;
  customerContactPerson:String | null
  customerContactPersonEmail:String | null;
  customerContactPersonId:String|null;
  customerContactPersonEmailId:String|null;
  qualityProducer:String|null;
  qualityProducerId:String | null
}

export interface ProjectCost {
  id: string;
  productTitle: string;
  borTitle: string;
  date?: any;
  resourceType?: any;
  pmolTitle: string;
  resourceNumber?: any;
  resourceTitle?: any;
  mou?: any;
  mouId?: any;
  consumedQuantity?: any;
  costMou?: any;
  spMou?: any;
  resourceTypeId: string;
  totalCost?: number;
  totalSales?: number;
  travelCost?: number;
  mouToBeInvoiced?: number;
  soldQuantity?: number;
  showcaseAsSelected?: boolean;
}

export interface Cost {
  id: string | null;
  psId: string | null;
  cpcId?: any;
  cpcResourceNumber: string;
  cpcTitle: string;
  consumedQuantity: number;
  costToMou?: any;
  totalCost: number | undefined;
  soldQuantity: number;
  spToMou?: any;
  consumedQuantityMou?: any;
  mouId?: any;
  mou?: any;
  status?: any;
  cpcResourceTypeId: string;
  cpcResourceType: string;
  projectCostId: string;
  resourceCategory: string;
  productTitle: string;
}


export const psFormData = {
  id: null,
  progressStatementId: null,
  title: null,
  headerTitle: null,
  name: null,
  projectTypeId: null,
  projectType: null,
  projectStatusId: null,
  projectStatus: null,
  projectCostId: null,
  workPeriodFrom: null,
  workPeriodTo: null,
  projectCompletionDate: null,
  purchaseOrderNumber: null,
  projectSequenceCode: null,
  historyLog: null,
  resources: [],
  isSaved: false,
  date: null,
  totalAmount: null,
  isProjectFinished: false,
  generalLedgerId: null,
  invoiceComment: null,
  projectScopeStatusId: null,
  projectScope: null,
  contractingUnitId:null,
  customerName :null,
  customerContactPerson:null,
  customerContactPersonEmail:null,
  customerId:null,
  customerContactPersonId:null,
  customerContactPersonEmailId:null,
  qualityProducer:null,
  qualityProducerId:null
};

export const psFilter = {
  title: null,
  project: null,
  totalAmount: null,
  date: null,
  status: null,
  sorter: {
    attribute: null,
    order: null,
  },
};

export interface ProgressStatementValidationMessage {
  nameErrorMsg: string | null;
  typeErrorMsg: string | null;
  borErrorMsg: string | null;
  statusErrorMsg: string | null;
  ledgerValidationMsg: string | null
}

export interface PriceListValidationMessage {
  materialErrorMsg: string | null;
  labourErrorMsg: string | null;
  consumableErrorMsg: string | null;
  toolErrorMsg: string | null;
  serviceErrorMsg: string | null;
}


export class Resource {
  id: string | null = null;
  cpcId: string | null = null;
  resourceTypeId: string | null = null;
  coefficient: number | string = 0;
  fixedPrice: number | string = 0;
  title: string | null = null;
  environment: string | null = null;
}

export class CostFilter {
  productTitle: string | null = null;
  productType: string | null = null;
  borTitle: string | null = null;
  resourceTitle: string | null = null;
  strDate: string | null = null;
  endDate: string | null = null;
  resourceTypeId: string | null = null;
  Psid: string | null = null;
  projectCostSortingModel: ProjectCostSortingModel = new ProjectCostSortingModel();
}

export class ProjectCostSortingModel {
  attribute: string | null = null;
  order: string | null = null;
}


export const PS_QUOTATION_TYPE_ID = '5bb656-f708-4a0d-9973-3d834ffe757d01';

import { Avatar, Link, Tooltip } from '@fluentui/react-components';
import React from 'react';
import { getDeliveryStatusColor, taskPieChartColor, WBS_TASK_STATUS } from '../../../types/wbs';
import {
  Attach20Filled,
  CalendarArrowCounterclockwise20Regular,
  CheckboxChecked20Regular,
  CheckboxUnchecked20Filled,
  Mail20Regular,
  Star20Filled,
  Star20Regular,
} from '@fluentui/react-icons/lib/fonts';
import moment from 'moment/moment';
import { saveWbsTasIsFavorite } from '../../../reducers/wbsTaskReducer';
import { useDispatch } from 'react-redux';
import { getProject } from '../../util';
import { Popover } from '@varld/popover';
import WbsStatusPopover from '../../../components/wbsTask/listPane/component/WbsStatusPopover';
import { messageService } from '../../../services/messageService';
import WbsTaskDatePickerModal from './WbsTaskDatePickerModal';
import WbsTaskModal from './WbsTaskModal';

// keep this snippet
// const CustomIcon = ({ icon }: { icon: string }) => (
//   <img
//     src={`https://res-1.cdn.office.net/files/fabric-cdn-prod_20221209.001/assets/brand-icons/product/svg/${icon}_24x1.svg`}
//     width='24'
//     height='24'
//     alt='Word product icon'
//   />
// );

// function getIcon(attachment: any) {
//   let fileType = attachment.attachmentType;
//   if (attachment.attachmentType === 'mail') {
//     return <Icon iconName='Mail' style={{ color: '#64879B' }} />;
//   } else if (fileType === 'doc' || fileType === 'docx') {
//     return <Icon iconName={'WordDocument'} />;
//   } else if (fileType === 'xls' || fileType === 'xlsx') {
//     return <Icon iconName={'ExcelDocument'} />;
//   } else if (fileType === 'ppt' || fileType === 'pptx') {
//     return <Icon iconName={'PowerPointDocument'} />;
//   } else if (fileType === 'txt') {
//     return <Icon iconName='TextDocument' style={{ color: '#f7d166' }} />;
//   } else if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') {
//     return <Icon iconName='FileImage' style={{ color: '#64879B' }} />;
//   } else if (fileType === 'zip' || fileType === 'rar') {
//     return <Icon iconName='ZipFolder' style={{ color: '#6C1775' }} />;
//   } else if (fileType === 'pdf' || fileType === 'PDF') {
//     return <Icon iconName='PDF' style={{ color: '#6C1775' }} />;
//   } else {
//     return <Icon iconName='FileTemplate' style={{ color: '#EEF0E2' }} />;
//   }
// }

interface Props {
  tasks: any;
  onChange: any;
  onClickTitle: (item: any) => void | undefined;
}

export default function TaskList(props: Props) {
  const { tasks } = props;
  const dispatch = useDispatch();

  const renderStatusPieChart = (status: string, deliveryStatus: string) => {
    return <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 22' fill='none' style={{ cursor: 'pointer' }}>
      <circle cx='11' cy='11' r='10' fill='#F5F5F5' stroke={getDeliveryStatusColor(deliveryStatus)} strokeWidth='2' />
      {getStatusChart(status)}
    </svg>;
  };

  const getStatusChart = (status: string) => {
    switch (status) {
      case WBS_TASK_STATUS.PENDING_DEVELOPMENT:
        return '';
      case WBS_TASK_STATUS.IN_DEVELOPMENT:
        return <path
          d='M11 2C12.1819 2 13.3522 2.23279 14.4442 2.68508C15.5361 3.13738 16.5282 3.80031 17.364 4.63604C18.1997 5.47177 18.8626 6.46392 19.3149 7.55585C19.7672 8.64778 20 9.8181 20 11L11 11V2Z'
          fill={taskPieChartColor.fillColor} />;
      case WBS_TASK_STATUS.IN_REVIEW:
        return <path
          d='M11 2C12.1819 2 13.3522 2.23279 14.4442 2.68508C15.5361 3.13738 16.5282 3.80031 17.364 4.63604C18.1997 5.47177 18.8626 6.46392 19.3149 7.55585C19.7672 8.64778 20 9.8181 20 11C20 12.1819 19.7672 13.3522 19.3149 14.4442C18.8626 15.5361 18.1997 16.5282 17.364 17.364C16.5282 18.1997 15.5361 18.8626 14.4441 19.3149C13.3522 19.7672 12.1819 20 11 20L11 11V2Z'
          fill={taskPieChartColor.fillColor} />;
      case WBS_TASK_STATUS.APPROVED:
        return <path
          d='M11 2C12.78 2 14.5201 2.52784 16.0001 3.51677C17.4802 4.50571 18.6337 5.91131 19.3149 7.55585C19.9961 9.20038 20.1743 11.01 19.8271 12.7558C19.4798 14.5016 18.6226 16.1053 17.364 17.364C16.1053 18.6226 14.5016 19.4798 12.7558 19.8271C11.01 20.1743 9.20038 19.9961 7.55585 19.3149C5.91131 18.6337 4.50571 17.4802 3.51677 16.0001C2.52784 14.5201 2 12.78 2 11H11V2Z'
          fill={taskPieChartColor.fillColor} />;
      case WBS_TASK_STATUS.HAND_OVER:
        return <circle cx='11' cy='11' r='9' fill={taskPieChartColor.fillColor} />;
      default:
        return '';
    }
  };

  const renderAsideContent = (props: any) => {
    const renderFavouriteIcon = () => {
      const FavouriteIcon = props.isFavourite ? Star20Filled : Star20Regular;
      return (
        <div className={'tree-aside-content'} style={{ minWidth: 30, cursor: 'pointer' }}>
          <FavouriteIcon onClick={(e) => onClickIsFav(e, props, !props.isFavourite)} />
        </div>
      );
    };

    return (
      <>
        {props.toPerson && props.toPerson.length > 0 &&
          <Tooltip content={renderToPersons(props.toPerson)} relationship='label'>
            <div className={'tree-aside-content'} style={{ minWidth: 30 }}>
              <Avatar size={20} aria-label='Guest' />
            </div>
          </Tooltip>}

        {renderFavouriteIcon()}

        {props?.totalInstructions != 0 && <div className={'tree-aside-content'}>
          <CheckboxUnchecked20Filled /> {`${props?.totalCheckedInstructions}/${props?.totalInstructions}`}
        </div>}

        {props.date &&
          <div
            className={'tree-aside-content date'}
            onClick={() => messageService.sendMessage({ isOpenTaskDateModal: true, selectedTask: props })}
          >
            <CalendarArrowCounterclockwise20Regular />{moment(props.date).format('D/MM')}
          </div>}

        {props.totalTask &&
          <div className={'tree-aside-content Component'}>
            <CheckboxChecked20Regular /> {`${props.completedTask}/${props.totalTask}`}
          </div>}

        <div className={'tree-aside-content'} style={{ minWidth: 40 }}><Attach20Filled /> {props.totalAttachment}</div>
        <div className={'tree-aside-content'} style={{ minWidth: 40 }}><Mail20Regular /> {props.totalMail}</div>
      </>
    );
  };


  const renderToPersons = (toPersons: any) => {
    return <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {toPersons?.map((person: any) => {
          return <span>{person?.personName}</span>;
        })}
      </div>
    </>;
  };

  const onClickIsFav = (e: any, item: any, status: boolean) => {
    e.stopPropagation();
    Promise.all([dispatch(saveWbsTasIsFavorite({ id: item?.id, isFavourite: status }))]).then(() => {
      props.onChange();
    });
  };

  const renderPopOver = (props: any) => {
    return <Popover popover={({ visible, open, close }) => {
      return (
        <div>
          <WbsStatusPopover
            visible={visible}
            props={{ ...props, status: props?.statusId }}
            close={close}
          />
        </div>
      );
    }}
    >
      <div style={{ display: 'flex', height: 20, width: 20 }}>
        {renderStatusPieChart(props?.statusId, props?.deliveryStatusId)}
      </div>
    </Popover>;
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: 20, gap: 1 }}>
        {tasks?.map((option: any) => (
          <div
            style={{
              display: 'flex',
              marginRight: 20,
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '95%',
              borderBottom: '1px solid #E6E6E6',
              padding: '5px 0',
            }}
            key={option.id as any}
          >
            <div style={{ alignSelf: 'flex-start', marginRight: 'auto' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ display: 'flex', height: 20, width: 20 }}>
                  {renderPopOver(option)}
                </div>
                <Link
                  onClick={() => props?.onClickTitle(option)}
                  appearance='subtle'
                  style={{ marginLeft: 10 }}
                >
                  {option.name}
                </Link>
              </div>
            </div>
            {getProject() && renderAsideContent(option)}
          </div>
        ))}
      </div>

      <WbsTaskModal />
      <WbsTaskDatePickerModal />
    </>
  );
}

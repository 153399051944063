import { ITextFieldStyleProps, ITextFieldStyles, TextField } from '@fluentui/react/lib/TextField';
import { ILabelStyleProps, ILabelStyles } from '@fluentui/react/lib/Label';
import * as React from 'react';
import { iconColors, uPrinceTheme } from '../../../theme';

const TextFieldWrapper: React.FC<any> = ({ input: { name, onChange, value, ...restInput }, meta, lable, disabled, ...rest }) => {
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  return (
    <TextField
      {...rest}
      {...restInput}
      name={name}
      label={lable}
      helperText={showError ? meta.error || meta.submitError : undefined}
      errorMessage={showError ? meta.error : ''}
      inputProps={restInput}
      onChange={onChange}
      value={value}
      style={{ color: rest.hasChanged && iconColors.iconActiveColor }}
      styles={disabled ? getDisabledStyles : rest.hasChanged && getStyles}
      disabled={disabled}
    />
  );
};

function getStyles(props: ITextFieldStyleProps): Partial<ITextFieldStyles> {
  return {
    fieldGroup: [
      { borderColor: iconColors.iconActiveColor },
    ],
    subComponentStyles: { label: getLabelStyles },
  };
}

function getLabelStyles(props: ILabelStyleProps): ILabelStyles {
  return { root: { color: iconColors.iconActiveColor } };
}

function getDisabledStyles(props: ITextFieldStyleProps): Partial<ITextFieldStyles>  {
  return {
    field: {
      color: uPrinceTheme.palette.themePrimary,
    },
    subComponentStyles: { label: getDisabledLabelStyles },
  };
}

function getDisabledLabelStyles(props: ILabelStyleProps): ILabelStyles {
  const { disabled } = props;
  return {
    root: disabled && {
      color: 'black',
    },
  };
}

export default TextFieldWrapper;

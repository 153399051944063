import React, { Component } from 'react';
import Brand from '../shared/brand/brand';
import ProjectBar from '../shared/projectBar/projectBar';
import NavBar from '../shared/navBar/navBar';
import { Redirect } from 'react-router';
import ContractingUnitMainLayout from './contractingUnit/contractingUnitMainLayout';

interface Props {
  currentUser:any;
  location?:any
}

interface State {
  isRoot:boolean
}

export default class Home extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { isRoot: false };
    }


    render() {
        if (this.props && this.props.location && this.props.location.state && this.props.location.state.isRoot) {
            return (
                <div>
                    <Brand />
                    <ProjectBar contractingUnit={ this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null } />
                    <NavBar selectedKey={ 0 } currentUser={ this.props.currentUser } />
                    <ContractingUnitMainLayout />
                </div>
            );
        } else if (this.props.currentUser && this.props.currentUser.contractingUnits[0]) {
            return <Redirect to={ `CU/${this.props.currentUser.contractingUnits[0].title}` } />;
        }
        return <Redirect to="/contracting-unit" />;
    }
}

import { FilterItem, ListPaneItem } from '../../../../types/projectBreakdownStructure';
import { withTranslation, WithTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { IColumn, Selection } from '@fluentui/react/lib/DetailsList';
import ListPaneComponent from './component';
import { getById, readDropdownData } from '../../../../reducers/projectBreakdownReducer';
import { getProductPoListPaneData } from '../../../../reducers/purchaseOrderReducer';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { CAPACITY_REQUEST, PO_PBS_TYPE } from '../../../../types/purchaseOrder';


export interface State {
  filter: FilterItem;
  announcedMessage: string;
  selectedListItemIds: [] | string[];
  selectedListItemSequenceCode: [] | string[];
  selectedListItems: ListPaneItem[];
}

export interface Props extends WithTranslation {
  isDataLoaded: boolean;
  isOpen: boolean;
  isChange: boolean;
  openModal: () => void;
  getProductPoListPaneData: (filterItem: FilterItem, project: string) => any;
  listPaneItem: ListPaneItem[];
  loadMsg: string;
  toleranceStatesFilter: [],
  productStatesFilter: [],
  itemTypesFilter: [],
  readDropdownData: () => void;
  setSelectedProducts: (products: any) => void,
  selectedProductIds: [];
  selectedProject: string
  poRequestType: any
}

class ProductModal extends Component<Props, State> {
  private _borSelection: Selection;
  private _columns: IColumn[];
  subscription: any;

  constructor(props: Props) {
    super(props);
    const { t } = this.props;
    this._borSelection = new Selection({
      onSelectionChanged: () => {
        this.setState(
          {
            selectedListItemIds: this._getSelectedIds(),
            selectedListItemSequenceCode: this._getselectedSequenceCode(),
            selectedListItems: this._getSelectedListItems(),
          },
          this.handleSelectedItem,
        );
      },
    });

    this.state = {
      selectedListItems: [],
      selectedListItemIds: [],
      selectedListItemSequenceCode: [],
      announcedMessage: '',
      filter: {
        title: null,
        pbsProductItemTypeId: null,
        pbsProductStatusId: null,
        pbsToleranceStateId: null,
        scope: null,
        taxonomy: null,
        sorter: {
          attribute: null,
          order: null,
        },
      },
    };

    this._columns = [
      {
        key: 'column1',
        name: this.props.t('title'),
        fieldName: 'title',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column2',
        name: this.props.t('type'),
        fieldName: 'pbsProductItemType',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column3',
        name: this.props.t('productstate'),
        fieldName: 'pbsProductStatus',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column4',
        name: this.props.t('productToleranceStatus'),
        fieldName: 'pbsToleranceState',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
    ];

  }

  componentDidMount() {
    //this.getListPaneData();
    //this.props.readDropdownData();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.isChange != this.props.isChange) {
      if (this.props.isChange) {
        this.getListPaneData();
      }
    }
    if (prevProps.isOpen != this.props.isOpen) {
      if (this.props.isOpen) {
        this.getListPaneData();
        this.props.readDropdownData();
      }
    }
  }

  render() {
    return (
      <div>
        <ListPaneComponent
          isOpen={this.props.isOpen}
          openModal={this.props.openModal}
          resetSelection={this.resetSelection}
          onConfirmSelection={this.onConfirmSelection}
          isDataLoaded={this.props.isDataLoaded}
          selection={this._borSelection}
          columns={this._columns}
          listPaneItem={this.props.listPaneItem}
          filter={this.state.filter}
          loadMsg={this.props.loadMsg}
          handleFilterChange={(filter: FilterItem) => {
            this.handleFilterChange(filter);
          }}
          dropDownOptions={
            {
              toleranceStatesFilter: this.props.toleranceStatesFilter,
              productStatesFilter: this.props.productStatesFilter,
              itemTypesFilter: this.props.itemTypesFilter,
            }
          }
        />
      </div>
    );
  }


  private getListPaneData = () => {
    let filter = this.state.filter;
    if (this.props.poRequestType === CAPACITY_REQUEST) {
      filter.pbsProductItemTypeId = PO_PBS_TYPE.GOODDEERIS;
    } else {
      filter.pbsProductItemTypeId = PO_PBS_TYPE.SUB;

    }

    this.props.getProductPoListPaneData(this.state.filter, this.props.selectedProject).then(() => {
      if (this.props.selectedProductIds && this.props.selectedProductIds.length > 0) {
        this.props.selectedProductIds.forEach((item: any) => {
          this._borSelection.setKeySelected(item?.productId, true, false);
        });
      }
      ;
    });
  };


  private handleSelectedItem = () => {

  };


  private resetSelection = () => {
    this.setState({
      filter: {
        title: null,
        pbsProductItemTypeId: null,
        pbsProductStatusId: null,
        pbsToleranceStateId: null,
        scope: null,
        taxonomy: null,
        sorter: {
          attribute: null,
          order: null,
        },
      },
    }, this.getListPaneData);
    this._borSelection.setAllSelected(false);
    this.props.setSelectedProducts([]);
  };

  private onConfirmSelection = () => {
    let selectedItem = this._borSelection.getSelection() as ListPaneItem[];
    console.log('_getSelectedIds', selectedItem);
    this.props.setSelectedProducts(selectedItem);
    this.props.openModal();
  };

  private handleListPaneItemClick = (id: string) => {

  };


  private handleFilterSortChange = () => {
    this.getListPaneData();
  };

  handleFilterChange = (filter: FilterItem) => {
    this.setState({
      filter,
    }, this.getListPaneData);
  };


  private _getSelectedIds() {
    let deleteList: any = [];
    let SelectedItem = this._borSelection.getSelection() as ListPaneItem[];
    if (SelectedItem) {
      deleteList = SelectedItem.map((item: any) => {
        return item ? item.id : null;
      });
    }
    return deleteList;
  }

  private _getselectedSequenceCode() {
    let deleteList: any = [];
    let SelectedItem = this._borSelection.getSelection() as ListPaneItem[];
    if (SelectedItem) {
      deleteList = SelectedItem.map((item: any) => {
        return item ? item.itemId : null;
      });
    }
    return deleteList;
  }

  private _getSelectedListItems = () => {
    let SelectedListItems = this._borSelection.getSelection() as ListPaneItem[];

    return SelectedListItems;
  };

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn,
  ): void => {
    const newColumns: IColumn[] = this._columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key,
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        let filter = this.state.filter;

        let fieldName = currColumn.fieldName;

        let sortby = currColumn.isSortedDescending ? 'desc' : 'asc';
        filter.sorter.attribute = fieldName ? fieldName : null;
        filter.sorter.order = sortby ? sortby : null;
        this.setState(
          {
            filter: filter,
          },
          this.handleFilterSortChange,
        );
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
  };

}


const mapStateToProps = (state: any, props: any) => {
  return {
    isChange: state.po.isChange,
    listPaneItem: state.po.productListPaneItem,
    loadMsg: state.po.loadMsgProduct,
    isDataLoaded: state.po.isDataLoadedProduct,
    toleranceStatesFilter: state.projectBreakdown.toleranceStatesFilter,
    productStatesFilter: state.projectBreakdown.productStatesFilter,
    itemTypesFilter: state.projectBreakdown.itemTypesFilter,
  };
};

const mapDispatchToProps = {
  getById,
  getProductPoListPaneData,
  readDropdownData,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProductModal)),
);

import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';

const CommandBarLeft = (props: { disable: boolean }) => {
  const { t } = useTranslation();


  const getItems = () => {
    return [
      // {
      //     key: 'new',
      //     text: t('new'),
      //     iconProps: { iconName: 'Add' },
      //     onClick: () => {
      //         messageService.sendMessage({ isNewOS: true });
      //     },
      //     disabled: props.disable
      // }
    ];
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );
};

export default CommandBarLeft;

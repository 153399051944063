import React, {useEffect} from 'react';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {GridView, ProjectPlanResourceType} from '../../../types/myDayPlanning';
import {useTranslation} from 'react-i18next';
import WorkerList from './workersList';
import {getTimeZone} from '../../../shared/util';
import {
    getProjectDayPlanningResourceList,
    saveProjectDayPlanningStateAttr
} from '../../../reducers/projectDayPlanningReducer';
import ToolList from './toolsList';
import moment from 'moment';
import OrgTeams from './orgTeams';

export const getFilters = (selectedDate: any, selectedCu?: any, selectedBu?: any, cabPersonId?: any) => {
    return {
        startDate: moment(selectedDate).format('YYYY-MM-DD'),
        endDate: moment(selectedDate).format('YYYY-MM-DD'),
        localDate: moment().format('YYYY-MM-DD'),
        offSet: getTimeZone(),
        type: GridView.DAY,
        contractingUnit: selectedCu,
        buId: selectedBu ?? null,
        cabPersonId: cabPersonId ?? null,
    }
};

const BottomListPane = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const store = useStore();
    const {
        buDropDown,
        horizontalReSizer,
        selectedResourceType,
        reloadBottomListPane,
        selectedCu,
        selectedBu,
    } = useSelector(
        (state: any) => state.projectDPlan
    );

    const {
        userInfo
    } = useSelector(
        (state: any) => state.uprince
    );

    const {
        planboardPersonId,
        selectedDate
    } = useSelector(
        (state: any) => state.mydPlan
    );

    const getSelectCuItem = (getId?: boolean) => {
        let contractingUnit = store.getState()?.projectDPlan?.selectedCu ? store.getState()?.projectDPlan?.selectedCu?.sequenceCode : userInfo?.contractingUnit?.sequnceCode;
        let contractingUnitId = store.getState()?.projectDPlan?.selectedCu ? store.getState()?.projectDPlan?.selectedCu?.key : userInfo?.contractingUnit?.id;
        if (getId) {
            return contractingUnitId
        } else {
            return contractingUnit
        }
    };


    // This logic is moved planboard shortcutPane
    // useEffect(() => {
    //     // subscribe to home component messages
    //     // const subscription = messageService.getMessage().subscribe((data: any) => {
    //     //   if (data) {
    //     //
    //     //   }
    //     // });
    //
    //     dispatch(getProjectDayPlanningResourceList(getFilters(
    //         selectedDate,
    //         getSelectCuItem(),
    //         buDropDown[0]?.key,
    //         planboardPersonId
    //     )));
    //
    //     // return unsubscribe method to execute when component unmounts
    //     return () => {
    //         // subscription.unsubscribe();
    //     }
    // }, []);

    useEffect(() => {
        if (reloadBottomListPane) {
            dispatch(getProjectDayPlanningResourceList(getFilters(selectedDate, selectedCu.sequenceCode, selectedBu, planboardPersonId)));
            dispatch(saveProjectDayPlanningStateAttr('reloadBottomListPane', false));
        }
    }, [reloadBottomListPane]);

    const renderList = () => {
        if (selectedResourceType === ProjectPlanResourceType.TOOL) {
            return <div style={{padding: 5}}><ToolList/></div>
        } else if (selectedResourceType === ProjectPlanResourceType.TEAM) {
            return <div style={{padding: 5}}><WorkerList/></div>
        } else {
            return <div style={{padding: 5}}><OrgTeams/></div>
        }
    };

    return <div className={'project-day_planing'} style={{
        width: '100%',
        height: screen.height - (horizontalReSizer + 365),
        overflow: selectedResourceType === ProjectPlanResourceType.ORG_TEAMS ? 'hidden' : 'overlay'
    }}>
        {renderList()}
    </div>
};


export default BottomListPane

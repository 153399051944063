import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import WbsTaxonomy from '../../../wbsTask/documentPane/components/WbsTaxonomy';
import Input from '../../../../shared/fluentUI9/Input/Input';
import { Icon } from '@fluentui/react';
import { DatePickerAdapter } from '../../../../shared/fluentFinalForm';
import { formatDateTimeToISOString, getProject } from '../../../../shared/util';
import Dropdown from '../../../../shared/fluentUI9/Dropdown/Dropdown';
import i18n from '../../../../../i18n';
import { Divider, Label } from '@fluentui/react-components';
import TextEditor from '../../../../shared/textEditor/textEditor';
import Tags from '../../../wbsTask/documentPane/components/Tags';
import PeopleTags from '../../../wbsTask/documentPane/components/PeopleTags';
import InstructionsCheckList from '../../../wbsTask/documentPane/components/InstructionsCheckList';
import Document from '../../../wbsTask/documentPane/components/Document';
import ConversationTaskComponent from '../../../wbsTask/documentPane/components/ConversationTaskComponent';
import TimeLine from '../../../wbsTask/documentPane/components/TimeLine';
import { getWbsTaskById, getWbsTaxonomy, saveTask, saveWbsTaskStateAttr } from '../../../../reducers/wbsTaskReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createForm } from 'final-form';
import { messageService } from '../../../../services/messageService';
import { WBS_TAXONOMY_LEVEL } from '../../../../types/wbs';
import ErrorLabel from '../../../../shared/fluentUI9/Label/ErrorLabel';
import _ from 'lodash';

interface Props {
  formIds: any;
  // isEdit: boolean;
}

const formValue = {
  id: null,
  toPerson: [],
  cc: [],
};

const WbsTaskModalDocumentPane = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formData = useSelector((state: any) => state.wbsTask.formData);
  const wbsTaskDropdowns = useSelector((state: any) => state.wbsTask.wbsTaskDropdowns);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  useEffect(() => {
    const subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.saveWbsTask) {
          formRef.current.submit();
        }
        if (data.data.resetWbsTaskForm) {
          formRef.current.restart(getInitialFormValues(null, formValue));
        }
      }
    });

    return () => {
      dispatch(saveWbsTaskStateAttr('formData', null));
      subscription.unsubscribe();
      console.log('WBS Task Modal Document Pane unmounted');
    };
  }, []);

  useEffect(() => {
    const handleIsEdit = async () => {
      setIsEdit(!!formData?.id);
    };

    handleIsEdit().then(null);
  }, [formData]);

  const onSubmit = async (values: any) => {
    const sleep = (ms: number | undefined) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);

    Promise.all([dispatch(saveTask(values))]).then((res) => {
      if (getProject()) {
        dispatch(getWbsTaskById({ id: values?.id ?? values?.id }));
        dispatch(getWbsTaxonomy());
      } else {
        // dispatch(readWbsTaxonomyByTemId(featureId));
      }
    });
  };

  const getInitialFormValues = (formData: any, formValue: any) => {
    if (isEdit) {
      if (formRef?.current?.getState().values?.id) {
        return { ...formRef?.current?.getState().values };
      }
      return { ...formData };
    } else {
      return {
        id: null,
        name: null,
        note: null,
        wbsTaxonomyId: props?.formIds?.wbsTaxonomyId ?? null,
        statusId: props?.formIds?.statusId ?? null,
        productId: props?.formIds?.productId ?? null,
        pbsId: props?.formIds?.pbsId ?? null,
        issueId: props?.formIds?.issueId ?? null,
        targetDate: '2023-11-15T18:30:00.000Z',
        tags: [],
        toPerson: [],
        cc: [],
        instructions: [],
        documents: [],
      };
    }

    // return formValue;
  };

  const formRef = React.useRef(createForm({ onSubmit: onSubmit }));

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={getInitialFormValues(formData, formValue)}
        mutators={{
          setToPersons: (args, state, utils) => {
            const field = state.fields.toPerson;
            field.change(args[0]);
          },
          setCcPersons: (args, state, utils) => {
            const field = state.fields.cc;
            field.change(args[0]);
          },
          setPbsIdPersons: (args, state, utils) => {
            const field = state.fields.pbsId;
            field.change(args[0]);
          },
          setProductIdPersons: (args, state, utils) => {
            const field = state.fields.productId;
            field.change(args[0]);
          },
        }}
        validate={values => {
          const errors: any = {};
          if (!values?.name) {
            errors.name = t('nameRequired');
          }
          if (!values?.wbsTaxonomyId) {
            errors.wbsTaxonomyId = t('productRequired');
          }
          return errors;
        }}
        render={({
                   handleSubmit,
                   form,
                   // submitting,
                   // pristine,
                   values,
                 }) => {
          formRef.current = form;
          return (
            <form onSubmit={handleSubmit} noValidate
                  className={isEdit ? 'task-form' : 'add-new-task-form'}
            >
              <div className='issue document-pane-card'>
                <Field
                  name='toPerson'
                  component={'input'}
                  type={'text'}
                  disabled={true}
                  hidden
                />
                <Field
                  name='cc'
                  component={'input'}
                  type={'text'}
                  disabled={true}
                  hidden
                />
                <Field
                  name='pbsId'
                  component={'input'}
                  type={'text'}
                  disabled={true}
                  hidden
                />
                <Field
                  name='productId'
                  component={'input'}
                  type={'text'}
                  disabled={true}
                  hidden
                />

                <div className='full-width'>
                  <Field name='wbsTaxonomyId'>
                    {({ input, meta }) => (
                      <>
                        <WbsTaxonomy
                          disabled={false}
                          formData={formData}
                          value={values?.wbsTaxonomyId ?? ''}
                          onChange={(node: any) => {
                            input.onChange(node?.id);
                            form.mutators.setPbsIdPersons(node?.pbsId);
                            form.mutators.setProductIdPersons(node?.productId);
                          }}
                          checkboxHandler={(wbsTaxonomyLevelId: string) => (wbsTaxonomyLevelId === WBS_TAXONOMY_LEVEL.PRODUCT || wbsTaxonomyLevelId === WBS_TAXONOMY_LEVEL.ISSUE)}
                        />
                        {meta.touched && meta.error && !meta.submitting && <ErrorLabel errMsg={meta.error} />}
                      </>
                    )}
                  </Field>
                </div>

                <Field name='name'>
                  {({ input, meta }) => (
                    <Input onChange={(value: any) => {
                      input.onChange(value);
                    }}
                           placeholder={t('taskName')}
                           contentBefore={<Icon iconName={'AlarmClock'} />}
                           appearance='underline'
                           value={values?.name}
                           errorMessage={meta.touched && meta.error && !meta.submitting ? meta.error : ''}
                           focus={true}
                    />
                  )}
                </Field>

                <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                  <div className='full-width'>
                    <Field
                      name='date'
                      component={DatePickerAdapter}
                      lable={t('dueDate')}
                      parse={value => value && formatDateTimeToISOString(value)}
                      format={value => (value ? new Date(value) : null)}
                      // minDate={new Date()}
                    />
                  </div>

                  <div className='full-width' style={{ paddingTop: 5 }}>
                    <Field name='statusId'>
                      {({ input, meta }) => (
                        <Dropdown
                          label={i18n.t('status')}
                          placeholder={i18n.t('select')}
                          options={wbsTaskDropdowns?.status ?? []}
                          value={values?.statusId}
                          onChange={(value: any) => {
                            if (value?.key) {
                              input.onChange(value?.key);
                            }
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className={`full-width ck-small-toolbar`}>
                  <Label>{`${t('note')}`}</Label>
                  <Field name='note'>
                    {({ input }) => (
                      <TextEditor
                        value={values?.note ? values?.note : ''}
                        onChange={(value: any) => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  </Field>
                </div>

                <div className={`full-width`}>
                  <Field name='tags'>
                    {({ input }) => (
                      <Tags
                        value={values?.tags && _.isArray(values?.tags) ? values?.tags : []}
                        onChange={(value: any) => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  </Field>
                </div>

                <div className={`full-width`}>
                  <Field name='peopleTags'>
                    {({}) => (
                      <PeopleTags
                        to={values?.toPerson ? values?.toPerson : []}
                        cc={values?.cc ? values?.cc : []}
                        onToChange={(value: any) => {
                          if (value) {
                            form.mutators.setToPersons(value);
                          }
                        }}
                        onCCChange={(value: any) => {
                          if (value) {
                            form.mutators.setCcPersons(value);
                          }
                        }}
                      />
                    )}
                  </Field>
                </div>

                <div className={`full-width`}>
                  <Field name='instructions'>
                    {({ input }) => (
                      <InstructionsCheckList
                        instructions={values?.instructions ? values?.instructions : []}
                        onChange={(value: any) => {
                          // console.log({value});
                          input.onChange(value);
                        }}
                        formData={values} // {id, pbsId, productId}
                      />
                    )}
                  </Field>
                </div>

                <div className={`full-width`}>
                  <Field name='documents'>
                    {({ input }) => (
                      <Document
                        documents={values?.documents ? values?.documents : []}
                        onChange={(value: any) => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  </Field>
                </div>

                {getProject() && <div className={`full-width`}>
                  <Field name='conversations'>
                    {({}) => (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex' }}>
                          <Label style={{ marginTop: 10 }} size='large'> {t('conversations')} </Label>
                        </div>
                        <div style={{ marginTop: 5, marginBottom: 10 }}><Divider /></div>
                        <div style={{ marginBottom: 10 }}>
                          <ConversationTaskComponent
                            conversations={values.conversations ?? []}
                          />
                        </div>
                      </div>
                    )}
                  </Field>
                </div>}


                <div className={`full-width`}>
                  <Field name='timeLine'>
                    {({ input }) => (
                      <TimeLine
                        value={values?.timeLine}
                        onChange={(value: any) => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  </Field>
                </div>
              </div>

              {/*<div style={{ width: 500, height: 600, overflow: 'auto' }}>*/}
              {/*  <pre>  {JSON.stringify(values, null, 2)}</pre>*/}
              {/*</div>*/}

            </form>
          );
        }}
      />
    </>
  );

};

export default WbsTaskModalDocumentPane;
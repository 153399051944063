import React from 'react';
import {mergeStyleSets} from '@fluentui/react';
import ThAutomationTaxonomy from "../topShortcutPane/thTaxonomy/thAutomationTaxonomy";
import {getConstructorsData, saveContractStateAttr} from "../../../../reducers/contractReducer";
import { useDispatch, useSelector } from 'react-redux';
import {useTranslation} from "react-i18next";


const BottomShortcutPane = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();


    const {
        horizontalReSizer,
    } = useSelector(
      (state: any) => state.thAutomation
    );


    const classNames = mergeStyleSets({
        wrapper: {
            height: screen.height - (horizontalReSizer + 360),
            position: 'relative'
        },
        selected: {
            fontWeight: 'bold',
            color: '#854114!important'
        },
        hr: {
            marginBottom: 15,
            borderTop: '1px solid #d2d2d2'
        },
        fullWidth: {width: '100%'},
        halfWidth: {width: '49%'}
    });

    return <div>
        <div className={`projectDayPlanning  filter-panel-left ${classNames.wrapper}`}>
            <div className="inner-container">
                <div style={{width: "100%"}}>
                    <ThAutomationTaxonomy
                      label={t('contractorLot')}
                      selectItemIds={null}
                      onChange={(selectedNode: any) => {
                          // input.onChange(selectedNode);
                          dispatch(getConstructorsData(selectedNode));
                          dispatch(saveContractStateAttr('taxonomyData', true));
                          // props.handleContractorsTaxonomyChange(selectedNode?.id)
                      }}
                      disabled={false}
                    />
                </div>
            </div>
        </div>
    </div>
};

export default BottomShortcutPane

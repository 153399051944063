import React, {Component} from 'react';
import MeNavBar from '../../shared/uprinceNavigation/meNavigation/component';
import ProjectDayPlanningMainLayout from './projectDayPlanningMainLayout';
import {getContractingUnit, getProject} from '../../shared/util';
import UprinceNavigation from '../../shared/uprinceNavigation/cuProjectNavigation/component';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {v4 as uuidv4} from 'uuid';

interface Props {
    currentUser: any
}

interface State {
    isContractUnit: boolean;
    isProject: boolean;
}

const dndId = uuidv4();

export class ProjectDayPlanningNew extends Component<Props, State> {
    render() {
        return <DndProvider key={dndId} backend={HTML5Backend}>
            {getProject() ?
                <UprinceNavigation
                    currentUser={this.props.currentUser}
                    selectedNavigationIndex={`/CU/${getContractingUnit()}/project/${getProject()}/project-day-planning`}
                >
                    <ProjectDayPlanningMainLayout/>
                </UprinceNavigation> :
                <MeNavBar
                    currentUser={this.props.currentUser}
                    selectedNavigationIndex={'/me/project-day-planning'}
                >
                    <ProjectDayPlanningMainLayout/>
                </MeNavBar>
            }
        </DndProvider>

    }
}

export default (ProjectDayPlanningNew);

import React, { Component } from 'react';
import UprinceNavigation from '../../shared/uprinceNavigation/cuProjectNavigation/component';
import CuInstructionRegisterMainLayout from './cuInstructionRegisterMainLayout';
import { getContractingUnit, getProject } from '../../shared/util';

interface Props {
  currentUser: any;
}

interface State {
  isContractUnit: boolean;
  isProject: boolean;
}
export class CuInstructionRegister extends Component<Props, State> {
  render() {
    return (
      <UprinceNavigation
        currentUser={this.props.currentUser}
        selectedNavigationIndex={`/CU/${getContractingUnit()}/cu-instruction-register`}
      >
        <CuInstructionRegisterMainLayout />
      </UprinceNavigation>
    );
  }
}

export default CuInstructionRegister;

import i18n from '../../i18n';

export interface ShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
}


export interface BorListPaneItem {
  key: string | null;
  id: string | null;
  itemId: string | null;
  name: string | null;
  borTitle: string | null;
  headerTitle: string | null;
  productId: string | null;
  product: string | null;
  locationParent: string | null;
  utilityParent: string | null;
}

export interface ResourceListPaneItem {
  key: string | null;
  id: string | null;
  resourceType: string | null;
  corporateProductCatalogId: string | null;
  required: string | null;
  consumed: string | null;
  purchased: string | null;
  deliveryRequested: string | null;
  warf: string | null;
  invoiced: string | null;
  resourceTitle: string | null;
}


export interface FilterItem {
  borTitle: string | null;
  product: string | null;
  locationParent: string | null;
  utilityParent: string | null;
  sorter: SortingModel;
}

export interface ResourceFilterItem {
  resourceTypeId: string | null;
  resourceTitle: string | null;
  required: string | null;
  purchased: string | null;
  deliveryRequested: string | null;
  warf: string | null;
  invoiced: string | null;
  sorter: SortingModel;
}

class SortingModel {
  attribute: string | null = null;
  order: string | null = null;
}


export interface History {
  createdByUser: string | null;
  createdDateTime: string | null;
  revisionNumber: number | string | null;
  updatedByUser: string | null;
  updatedDateTime: string | null;
}

export interface BillOfResource {
  id: string | null;
  headerTitle: string | null;
  itemId: string | null;
  name: string | null;
  borTitle: string | null;
  startDate: string | null;
  endDate: string | null;
  pbsProductId: string | null;
  productItemType: string | null;
  utilityParentId: string | null;
  locationParentId: string | null;
  historyLog: History | null;
  product: Product;
  borResources: BorResources;
  borStatusId: string | null;
  borTypeId: string | null;
}

export class Product {
  id: string | null = null;
  title: string | null = null;
  productId: string | null = null;
  pbsProductItemTypeId: string | null = null;
  pbsProductItemType: string | null = null;
  locationTaxonomyParentId: string | null = null;
  utilityTaxonomyParentId: string | null = null;
  utilityParentId: string | null = null;
  locationParentId: string | null = null;
}

export class BorResources {
  materials: any = [];
  tools: any = [];
  consumable: any = [];
  labour: any = [];
}

export class BorResourcesItem {
  id: string | null = null;
  date: string | Date | null = null;
  title: string | null = null;
  required: string | number | null = null;
  purchased: string | number | null = null;
  deliveryRequested: string | number | null = null;
  warf: string | number | null = null;
  consumed: string | number | null = null;
  invoiced: string | number | null = null;
  type: string | number | null = null;
  environment: string | null = null;
  isNew: boolean = false;
  resourceNumber: string | number | null = null;
  actualDeliveryDate?: string | null = null;
  expectedDeliveryDate?: string | null = null;
  requestedDeliveryDate?: string | null = null;
}

export interface BillOfResourceValidationMessage {
  nameErrorMsg: string | null;
  productTitleErrorMsg: string | null;
  statusErrorMsg: string | null;
}


export class Resource {
  resourceId: string | null = null;
  pbsProductId: string | null = null;
  coperateProductCatalogId: string | null = null;
  quantity: number | null = null;
  sequenceCode: string | null = null;
  cpcText: string | null = null;
  cpcKey: string | null = null;
  resourceFamilyTitle: string | null = null;
  unit: string | null = null;
  cpcTitle: string | null = null;
}

export class CreatePbsMaterial {
  pbsProductId: string | null = null;
  coperateProductCatalogId: string | null = null;
  quantity: number = 0;
}

export class Instruction {
  instructionsDetails: string | null = null;
  instructionType?: string | null = null;
  pbsProduct?: string | null = null;
  pbsProductId: string | null = null;
  pbsInstructionFamily: PbsInstructionFamily = new PbsInstructionFamily();
  pbsInstructionLink: PbsInstructionLink[] = [];
  id: string | null = null;
  sequenceCode: string | null = null;
  title: string | null = null;
  headerTitle: string | null = null;
  name: string | null = null;
  isDeleted: boolean | null = null;
  isSaved: boolean = false;
  //instructionFamilyId: string|null = null
  pbsInstructionFamilyId: string | null = null;
}

export class PbsInstructionLink {
  id: string | null = null;
  title: string | null = null;
  value: string | null = null;
  //link:string|null = null;
  pbsInstructionId: string | null = null;
  type: string | null = null;
}

export class PbsInstructionFamily {
  key: string | null = null;
  text: string | null = null;
}

export interface InstructionDropDown {
  key: string,
  text: string
}

export interface BORStatusUpdateRequest {
  borId: string;
  statusId: string;
}

export class BORResourceUpdateRequest {
  borId: string | null = null;
  required: string | number | null = null;
  borRequiredId: string | null = null;
}

export class InstructionLink {
  type: any = null;
  value: string | null = null;
  title: any = null;
  id?: any = null;
  pbsInstructionId?: any = null;
  link: string | null = null;
}


export class Competence {
  id: string | null = null;
  pbsProductId: string | null = null;
  skillId: string | null = null;
  skill: string | null = null;
  experienceId: string | null = null;
  experience: string | null = null;
}

export enum BOR_TYPE {
  REGULAR = '88282458-0b40-poa3-b0f9-c2e40344c888',
  RETURN = '6610e768-3e06-po02-b337-ee367a82ad66'
}

export const BorFormData = {
  id: null,
  borStatusId: '94282458-0b40-40a3-b0f9-c2e40344c8f1',
  itemId: null,
  borTitle: null,
  headerTitle: null,
  name: null,
  startDate: null,
  endDate: null,
  pbsProductId: null,
  locationParentId: null,
  utilityParentId: null,
  productItemType: null,
  historyLog: null,
  borTypeId: null,
  product: {
    id: null,
    productId: null,
    title: null,
    utilityParentId: null,
    locationParentId: null,
    locationTaxonomyParentId: null,
    utilityTaxonomyParentId: null,
    pbsProductItemType: null,
    pbsProductItemTypeId: null,
  },
  borResources: {
    labour: [],
    consumable: [],
    tools: [],
    materials: [{}],
  },
};

export const ResourceTypes = {
  labour: { id: 'c46c3a26-39a5-42cc-b07s-89655304eh6', name: i18n.t('humanResources') },
  consumable: { id: 'c46c3a26-39a5-42cc-m06g-89655304eh6', name: i18n.t('consumable') },
  tools: { id: 'c46c3a26-39a5-42cc-n9wn-89655304eh6', name: i18n.t('tools') },
  materials: { id: 'c46c3a26-39a5-42cc-n7k1-89655304eh6', name: i18n.t('materials') },
};
export const ResourceTypesDropdown = [
  { key: '0', text: i18n.t('all') },
  { key: ResourceTypes.labour.id, text: i18n.t(ResourceTypes.labour.name) },
  { key: ResourceTypes.consumable.id, text: ResourceTypes.consumable.name },
  { key: ResourceTypes.tools.id, text: ResourceTypes.tools.name },
  { key: ResourceTypes.materials.id, text: ResourceTypes.materials.name },
];

import { useTranslation } from 'react-i18next';
import {
  FontWeights,
  getTheme,
  IconButton,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import React, { useState } from 'react';
import { useId } from '@uifabric/react-hooks';
import { uPrinceTheme } from '../../../../theme';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  saveProjectDayPlanningStateAttr,
  updatePlanBoardsPmolTitle,
} from '../../../reducers/projectDayPlanningReducer';
import { Field, Form } from 'react-final-form';
import { TextFieldFF } from '../../../shared/fluentFinalForm';
import ProgressBar from '../../../shared/progressBar/progressBar';
import { saveStateAttr } from '../../../reducers/uprinceReducer';
import { messageService } from '../../../services/messageService';


const theme = getTheme();

const classNames = mergeStyleSets({
  wrapper: {
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: {
    maxWidth: 400,
  },
  textContent: {
    padding: '15px 10px',
  },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: {
    width: '100%',
  },
  editor: {
    width: '100%',
    height: '100%'
  },
});
const stackStyles: IStackStyles = {
  root: {
    padding: 0,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const addIconWhite: IIconProps = {
  iconName: 'Save',
  styles: addWhiteIconButtonStyles,
};
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '20vw',
    minHeight: '10vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};
class RiskValidationMessages {
  nameValidationMsg: string = '';
}

const PmolTitleModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const titleId = useId('title');
  const store = useStore();
  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);


  const {
    userInfo
  } = useSelector(
    (state: any) => state.uprince
  );

  const {
    isOpenTitlePopup,
    selectedPmolToRenamed
  } = useSelector(
    (state: any) => state.projectDPlan
  );



  const onSubmit = async (values: any) => {
    const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
    await sleep(300);
    dispatch(saveStateAttr('contractingUinit', selectedPmolToRenamed?.contractingUinit));
    dispatch(saveStateAttr('projectSequenceCode', selectedPmolToRenamed?.projectSequenceCode));
    setShowProgressBar(true);
    Promise.all([
      dispatch(updatePlanBoardsPmolTitle(values))
    ]).then(() => {
      setTimeout(() => {
        messageService.sendMessage({ reloadDayPlan: true });
        setShowProgressBar(false);
        onModalClose()
      }, 1000)
    });
  };


  const onModalClose=()=>{
    console.log({selectedPmolToRenamed})
    dispatch(saveProjectDayPlanningStateAttr('selectedPmolToRenamed', null));
    dispatch(saveProjectDayPlanningStateAttr('isOpenTitlePopup', false));
  }

  const getInitialFormValues = (formData: any, formValue: any) => {
    if (formData) {
      return {
        id: formData?.id,
        name: formData?.name,
        projectMoleculeId: formData?.projectMoleculeId,
        projectSequenceCode: formData?.projectSequenceCode,
        contractingUinit: formData?.contractingUinit,
      };
    }

    return formValue;
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        initialValues={getInitialFormValues(selectedPmolToRenamed , null)}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <div>
                <Modal
                  titleAriaId={titleId}
                  isOpen={isOpenTitlePopup}
                  onDismiss={() => {
                    onModalClose()
                  }}
                  scrollableContentClassName="body"
                  isBlocking={false}
                  dragOptions={undefined}
                >
                  <div className={contentStyles.header}>
                    <span id={titleId}>{t('pmol')}</span>
                    <IconButton
                      styles={cancelIconButtonStyles}
                      iconProps={cancelIcon}
                      ariaLabel="Close popup modal"
                      onClick={() => {
                        onModalClose()
                      }}
                    />
                  </div>
                  <ProgressBar show={showProgressBar} />

                  <div className={contentStyles.body}>
                    <div className="proj-detail-content inner">
                      <div className="ms-Grid-row">
                        <Stack
                          horizontal
                          wrap
                          styles={stackStyles}
                          tokens={stackTokens}
                        >
                          <Stack.Item grow={6} styles={stackItemStyles}>
                            <div className={`${classNames.fullWidth}`}>
                              <Field
                                name="name"
                                component={TextFieldFF}
                                lable={t('name')}
                                disabled={false}
                                autoFocus={true}
                              />
                            </div>
                          </Stack.Item>
                        </Stack>
                      </div>
                    </div>

                  </div>
                  <div className={contentStyles.footer}>
                    <PrimaryButton
                      iconProps={addIconWhite}
                      text={t('save')}
                      disabled={!values?.name}
                      style={{ marginTop: 10, marginBottom: 30 }}
                      onClick={() => { form.submit() }}
                    />
                  </div>
                </Modal>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

export default PmolTitleModal;
import * as React from 'react';

import FormHelperText from '@mui/material/FormHelperText';
import { TextField } from '@fluentui/react';


// eslint-disable-next-line react/prop-types
const InputWrapper: React.FC<any> = ({ input: { name, onChange, value, ...restInput }, meta, ...rest }) => {
  // eslint-disable-next-line react/prop-types
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  return (
    <>' '<TextField
      {...rest}
      // eslint-disable-next-line no-mixed-spaces-and-tabs
      name={name}
      error={showError}
      inputProps={restInput}
      onChange={onChange}
      value={value}
    />'
      '{showError &&
      <FormHelperText>
        {meta.error || meta.submitError}
      </FormHelperText>
      }' '</>
  );
};

export default InputWrapper;

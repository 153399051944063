import { IColumn } from '@fluentui/react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import React from 'react';
import { connect } from 'react-redux';
import ProgressBar from '../../../shared/progressBar/progressBar';
import {
  BillOfResource,
  BorListPaneItem,
  FilterItem,
  ResourceFilterItem,
  ResourceListPaneItem,
  ShortCutPaneItem,
} from '../../../types/billOfResources';
import BorListComponent from './borList/component';
import ResourceListComponent from './resourceList/component';

export const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 280px)!important',
    position: 'relative',
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: {
    margin: 0,
  },
  row: {
    display: 'inline-block',
  },
});

const ListPaneComponent = (props: {
  selectedShortCutItem: ShortCutPaneItem;
  filter: FilterItem;
  resourceFilter: ResourceFilterItem;
  borSelection: any;
  selection: any;
  borColumns: IColumn[];
  resourceColumns: IColumn[];
  borListPaneItem: BorListPaneItem[];
  resourceListPaneItem: ResourceListPaneItem[];
  resourceTypes: [];
  handleListPaneItemClick: (id: string) => void;
  handleFilterChange: (filter: FilterItem) => void;
  handleResourceFilterChange: (filter: ResourceFilterItem) => void;
  isDataLoaded?: boolean;
  loadMsg: string;
  resetSelection: () => void;
  formData: BillOfResource;
  dropDownOptions: any;
  showProgress: boolean;
}) => {
  return (
    <div>
      <ProgressBar show={props.showProgress}/>

      {props.selectedShortCutItem.type === 'Bor' ? (
        <BorListComponent
          borListPaneItem={props.borListPaneItem}
          selection={props.borSelection}
          handleListPaneItemClick={(id: string) =>
            props.handleListPaneItemClick(id)
          }
          resetSelection={() => props.resetSelection()}
          formData={props.formData}
          columns={props.borColumns}
          filter={props.filter}
          loadMsg={props.loadMsg}
          resourceTypes={props.resourceTypes}
          handleFilterChange={(filter: FilterItem) =>
            props.handleFilterChange(filter)
          }
          isDataLoaded={props.isDataLoaded}
          dropDownOptions={props.dropDownOptions}
        />
      ) : (
        <ResourceListComponent
          selectedShortCutItem={props.selectedShortCutItem}
          resourceListPaneItem={props.resourceListPaneItem}
          selection={props.selection}
          handleListPaneItemClick={(id: string) =>
            props.handleListPaneItemClick(id)
          }
          resetSelection={() => props.resetSelection()}
          formData={props.formData}
          columns={props.resourceColumns}
          filter={props.resourceFilter}
          loadMsg={props.loadMsg}
          resourceTypes={props.resourceTypes}
          handleFilterChange={(filter: ResourceFilterItem) =>
            props.handleResourceFilterChange(filter)
          }
          isDataLoaded={props.isDataLoaded}
          dropDownOptions={props.dropDownOptions}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    toleranceStatesFilter: state.billOfResource.toleranceStatesFilter,
    productStatesFilter: state.billOfResource.productStatesFilter,
    itemTypesFilter: state.billOfResource.itemTypesFilter,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ListPaneComponent);

import React, { createContext, useContext, useState } from 'react';

// Create a context to manage the expanded state
export const TableExpandContext = createContext<any>({});

// Create a provider to wrap the components using the context
const TableExpandProvider: any = ({ children }: any) => {
  const [isAllExpanded, setIsAllExpanded] = useState(false);

  return (
    <TableExpandContext.Provider value={{ isAllExpanded, setIsAllExpanded }}>
      {children}
    </TableExpandContext.Provider>
  );
};

// Custom hook to consume the context within components
const useTableExpand = () => {
  return useContext(TableExpandContext);
};

export { TableExpandProvider, useTableExpand };

import {
  CORPORATE_SCHEDULE_SAVE_EP,
  READ_CORPORATE_SCHEDULE_BY_ID_EP,
  READ_CORPORATE_SCHEDULE_LIST_EP,
} from '../shared/endpoints';
import {
  CLEAR_CORPORATE_SCHEDULE_FORM_DATA,
  CLEAR_CORPORATE_SCHEDULEUID,
  LOAD_CORPORATE_SCHEDULE_BY_ID,
  LOAD_CORPORATE_SCHEDULE_BY_ID_FAIL,
  LOAD_CORPORATE_SCHEDULE_BY_ID_SUCCESS,
  LOAD_CORPORATE_SCHEDULE_DROPDOWN,
  LOAD_CORPORATE_SCHEDULE_DROPDOWN_FAIL,
  LOAD_CORPORATE_SCHEDULE_DROPDOWN_SUCCESS,
  LOAD_CORPORATE_SCHEDULE_LSITPANE,
  LOAD_CORPORATE_SCHEDULE_LSITPANE_FAIL,
  LOAD_CORPORATE_SCHEDULE_LSITPANE_SUCCESS,
  LOAD_CORPORATE_SCHEDULE_SHORTCUTPANE,
  LOAD_CORPORATE_SCHEDULE_SHORTCUTPANE_FAIL,
  LOAD_CORPORATE_SCHEDULE_SHORTCUTPANE_SUCCESS,
  RESET_CORPORATE_SCHEDULE_IS_CHANGE,
  SAVE_CORPORATE_SCHEDULE,
  SAVE_CORPORATE_SCHEDULE_FAIL,
  SAVE_CORPORATE_SCHEDULE_SUCCESS,
  SET_CORPORATE_SCHEDULE_UID,
} from '../actionTypes/corporateScheduleActionTypes';
import { v4 as uuidv4 } from 'uuid';
import { CorporateSchedule, CorporateScheduleListPaneFilter } from '../types/corporateSchedule';


const defaultState: any = {
  csList: [],
  isCSListLoaded: false,
  isNew: false,
  isChange: false,
  isEdit: false,
  showProgressBar: false,
  csDropDowns: {},
  formData: {},
  uid: null,
  loadMsg: '',
  csShortCutPaneData: [],
  reloadListPane: false,
  borResources: {},
  isLoadingCorporateSchedule: false,
  csID: null,
};

export default function corporateScheduleReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {
      case LOAD_CORPORATE_SCHEDULE_SHORTCUTPANE:
        return { ...state };
      case LOAD_CORPORATE_SCHEDULE_SHORTCUTPANE_SUCCESS:
        return {
          ...state,
          csShortCutPaneData: action.payload.data.result,
        };
      case LOAD_CORPORATE_SCHEDULE_SHORTCUTPANE_FAIL:
        return { ...state, csShortCutPaneData: [] };
      case LOAD_CORPORATE_SCHEDULE_LSITPANE:
        return { ...state, isCSListLoaded: false };
      case LOAD_CORPORATE_SCHEDULE_LSITPANE_SUCCESS:
        return {
          ...state,
          isCSListLoaded: true,
          csList: action.payload.data.result,
          loadMsg: !action.payload.data.status
            ? action.payload.data.message
            : '',
        };
      case LOAD_CORPORATE_SCHEDULE_LSITPANE_FAIL:
        return {
          ...state,
          isCSListLoaded: true,
          csList: [],
          loadMsg: action.error.response.data.message,
        };
      case RESET_CORPORATE_SCHEDULE_IS_CHANGE:
        return {
          ...state,
          isChange: false,
        };

      case LOAD_CORPORATE_SCHEDULE_DROPDOWN:
        return { ...state, csDropDowns: {} };
      case LOAD_CORPORATE_SCHEDULE_DROPDOWN_SUCCESS:
        return { ...state, csDropDowns: action.payload.data.result };
      case LOAD_CORPORATE_SCHEDULE_DROPDOWN_FAIL:
        return {
          ...state,
          csDropDowns: {},
        };
      case LOAD_CORPORATE_SCHEDULE_BY_ID:
        return { ...state, formData: {}, showProgressBar: true, isLoadingCorporateSchedule: true };
      case LOAD_CORPORATE_SCHEDULE_BY_ID_SUCCESS:
        return {
          ...state,
          isEdit: true,
          formData: action.payload.data.result,
          showProgressBar: false,
          isLoadingCorporateSchedule: false,
        };
      case LOAD_CORPORATE_SCHEDULE_BY_ID_FAIL:
        return {
          ...state,
          isEdit: false,
          formData: {},
          showProgressBar: false,
          isLoadingCorporateSchedule: false,
        };

      case SAVE_CORPORATE_SCHEDULE:
        return { ...state, showProgressBar: true, reloadListPane: false, csID: null };
      case SAVE_CORPORATE_SCHEDULE_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          reloadListPane: true,
          csID: action.payload.data.result,
        };
      case SAVE_CORPORATE_SCHEDULE_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
          csID: null,
        };

      case SET_CORPORATE_SCHEDULE_UID:
        return {
          ...state,
          isEdit: false,
          uid: uuidv4(),
        };
      case CLEAR_CORPORATE_SCHEDULEUID:
        return {
          ...state,
          uid: null,
          isChange: false,
        };
      case CLEAR_CORPORATE_SCHEDULE_FORM_DATA:
        return {
          ...state,
          uid: null,
          isNew: true,
          isChange: false,
          isEdit: false,
          formData: {},
        };

      default:
        return state;
    }
  }
}

// export const readCorporateScheduleShortCutPaneData = () => {
//     return {
//         types: [
//             LOAD_CORPORATE_SCHEDULE_SHORTCUTPANE,
//             LOAD_CORPORATE_SCHEDULE_SHORTCUTPANE_SUCCESS,
//             LOAD_CORPORATE_SCHEDULE_SHORTCUTPANE_FAIL
//         ],
//         payload: { request: { url: READ_CORPORATE_SCHEDULE_SHORTCUT_PANE_EP } }
//     };
// };

// export const readCorporateScheduleDropDownData = () => {
//     return {
//         types: [
//             LOAD_CORPORATE_SCHEDULE_DROPDOWN,
//             LOAD_CORPORATE_SCHEDULE_DROPDOWN_SUCCESS,
//             LOAD_CORPORATE_SCHEDULE_DROPDOWN_FAIL
//         ],
//         payload: { request: { method: 'GET', url: READ_CORPORATE_SCHEDULE_DROPDOWN_EP } }
//     };
// };


export const filterCorporateScheduleListPaneData = (filter: CorporateScheduleListPaneFilter) => {
  return {
    types: [
      LOAD_CORPORATE_SCHEDULE_LSITPANE,
      LOAD_CORPORATE_SCHEDULE_LSITPANE_SUCCESS,
      LOAD_CORPORATE_SCHEDULE_LSITPANE_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_CORPORATE_SCHEDULE_LIST_EP,
        data: filter,
      },
    },
  };
};


export const readByCorporateScheduleId = (id: string) => {
  return {
    types: [
      LOAD_CORPORATE_SCHEDULE_BY_ID,
      LOAD_CORPORATE_SCHEDULE_BY_ID_SUCCESS,
      LOAD_CORPORATE_SCHEDULE_BY_ID_FAIL],
    payload: { request: { url: READ_CORPORATE_SCHEDULE_BY_ID_EP + id } },
  };
};

export const resetIsChange = () => {
  return { type: RESET_CORPORATE_SCHEDULE_IS_CHANGE };
};

export const saveFormData = (formData: CorporateSchedule) => {
  return {
    types: [
      SAVE_CORPORATE_SCHEDULE,
      SAVE_CORPORATE_SCHEDULE_SUCCESS,
      SAVE_CORPORATE_SCHEDULE_FAIL],
    payload: {
      request: {
        url: CORPORATE_SCHEDULE_SAVE_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const setUid = () => {
  return { type: SET_CORPORATE_SCHEDULE_UID };
};

export const clearFormData = () => {
  return { type: CLEAR_CORPORATE_SCHEDULE_FORM_DATA };
};

import { ChoiceGroup } from '@fluentui/react';
import * as React from 'react';


const RadioWrapper: React.FC<any> = ({
                                       input: { checked, value, name, onChange, ...restInput },
                                       meta,
                                       ...rest
                                     }) => (
  <ChoiceGroup
    {...rest}
    name={name}
    inputProps={restInput}
    onChange={onChange}
    checked={checked}
    value={value}

  />
);

export default RadioWrapper;

export interface LanguageKeys {
  id: string | null;
  key: string | null;
  value: string | null;
  languageId: string | null;
  language: {};
}

export interface Language {
  id: string | null | number;
  country: string | null;
  lang: string | null;
  code: string | null;
}

export class UprinceLanguage {
  id: string | null = null;
  country: string | null = null;
  lang: string | null = null;
  code: string | null = null;
}

export class Translation {
  languageId: any | null = null;
  key: string | null = null;
  value: string | null = null;
  id: string | null = null;
}

export interface ActionButtonState {
  add: boolean;
  edit: boolean;
  remove: boolean;
}

export interface LangKeysValidationMessage {

  keyErrorMsg: string | null;
  valueErrorMsg: string | null;
}

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {messageService} from '../../../../../services/messageService';
import {useTranslation} from 'react-i18next';
import {
    formatDateTimeToISOString,
    getCalenderLocalizer,
    projectUniqueColor,
    stringDateToDateObject,
} from '../../../../../shared/util';
import moment from 'moment';
import {Calendar, momentLocalizer, Views} from 'react-big-calendar';
import CustomToolbar from '../../../../../shared/kanban/customToolbar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { ThAutomationDeleteTypes, ThAutomationResourceType } from '../../../../../types/thAutomation';
import {
    deleteTruckFromDay, loadBottomPumpWithProduct,
    loadProductList,
    saveThAutomationStateAttr,
} from '../../../../../reducers/thAutomationReducer';
import {formatTimeForPmol} from '../../../../myDayPlanning/listPane/component';
import {FontIcon, IStackItemStyles, IStackStyles, IStackTokens, mergeStyleSets, Stack} from '@fluentui/react';
import CustomDragBox from '../../../../../shared/dnd/customDragBox';
import {DpViewMode} from '../../../../../types/myDayPlanning';


const stackStyles: IStackStyles = {root: {width: '100%'}};

const stackTokens: IStackTokens = {
    childrenGap: 0,
    padding: 0
};

const stackItemStyles: IStackItemStyles = {
    root: {
        display: 'flex',
        height: 28,
        width: 0,
        // flexGrow: 1
    }
};

const classNames = mergeStyleSets({
    fullWidth: {
        width: '100%'
    }
});

const PumpCarListPane = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const localizer = momentLocalizer(moment);
    const store = useStore();
    const DragAndDropCalendar = withDragAndDrop(Calendar as any);

    const [resourceMap, setResourceMap] = useState<any>([])
    const [eventList, setEventList] = useState<any>([])

    const {} = useSelector(
      (state: any) => state.projectDPlan
    );

    const {
        btmPumpData,
        selectedDate,
        horizontalReSizer,
        truckResourceViewMode,
    } = useSelector(
      (state: any) => state.thAutomation
    );

    useEffect(() => {
        getCalenderLocalizer();
        // subscribe to home component messages
        const subscription = messageService.getMessage().subscribe((data: any) => {
            if (data) {

            }
        });

        if (selectedDate) {
            reloadBtmPane();
        } else {
            dispatch(saveThAutomationStateAttr('selectedDate', new Date()))
        }

        // return unsubscribe method to execute when component unmounts
        return () => {
            subscription.unsubscribe();
        }
    }, []);

    useEffect(() => {
        if (selectedDate) {
            reloadBtmPane();
        }
    }, [selectedDate]);

    useEffect(() => {
        setDataToEventResource(btmPumpData);
    }, [btmPumpData]);

    const reloadBtmPane = () => {
        dispatch(loadBottomPumpWithProduct({
            date: formatDateTimeToISOString(store.getState()?.thAutomation?.selectedDate)
        }));
    };

    const setDataToEventResource = (data: any) => {
        if (data && data.length > 0) {
            let resource = data.map((item: any, index: any) => {
                return {
                    resourceId: 1 + index,
                    resourceTitle: item?.truckTitle,
                    id: item?.id,
                    truckCapacity: item?.truckCapacity,
                    timeSlots:item?.timeSlots
                };
            });

            setResourceMap(resource);
            getEventList(data);
        } else {
            setResourceMap([]);
        }
    };

    const getEventList = (data: any) => {
        let eventList: any[] = [];

        data.map((item: any, index: any) => {
            if (item && item?.product && item?.product.length > 0) {
                item.product.map((productItem: any) => {
                    if (productItem && productItem.id) {
                        eventList.push({
                            id: productItem?.id,
                            title: '',
                            start: formatTimeForPmol(productItem?.startTime, moment(productItem?.startTime).format('HH:mm')),
                            end: formatTimeForPmol(productItem?.endTime, moment(productItem?.endTime).format('HH:mm')),
                            resourceId: 1 + index,
                            productTitle: productItem?.productTitle,
                        });
                    }
                });
            }

            return eventList;
        });

        setEventList(eventList);
    };

    const handleTruckDelete = (resource: any) => {
        // console.log('resource> ', resource);
        //{resourceId: 3, resourceTitle: 'CAr01-0009 - truck 4', id: '7feff3ff-ca29-415e-97e9-48bd42d2bdf7'}
        dispatch(saveThAutomationStateAttr('isConfirmDeleteModalOpen',true));
        dispatch(saveThAutomationStateAttr('dataToDelete',{...resource,cpcId:resource?.id,deleteType:ThAutomationDeleteTypes.PUMP_DELETE_BOTTOM}));

    };

    const renderTimeSlots=(resource:any)=>{
        if(resource?.timeSlots && resource?.timeSlots.length>0){
            return resource?.timeSlots.map((item:any,index:number)=>{
                return <div style={{paddingLeft:'5px',fontSize:11,fontWeight:600}}> {item?.sTime}-{item?.eTime} {index != resource?.timeSlots.length - 1&&'/'}</div>
            })
        }else{
            return  <span style={{paddingLeft:'5px',fontSize:11,fontWeight:600}} >&nbsp;</span>
        }
    }

    const ResourceHeader = ({label, index, resource}: any) => (
      <CustomDragBox
        key={JSON.stringify(label)}
        item={resource}
        type={ThAutomationResourceType.PUMP}
        style={{fontSize: 12, width: '100%', minHeight: 44, display: 'flex', alignItems: 'center'}}
      >
          <div style={{display:'flex',flexDirection:'column',width:'100%'}} >
          <Stack
            horizontal
            wrap
            styles={stackStyles}
            tokens={stackTokens}
          >
              <Stack.Item grow={10} styles={stackItemStyles}>
                  <div className={classNames.fullWidth}>
                      {label}
                      {resource?.truckCapacity ? <span> : {resource?.truckCapacity} m3</span> : <div>&nbsp;</div>}
                  </div>
              </Stack.Item>
              <Stack.Item grow={1} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                      <FontIcon
                        aria-label="delete-truck"
                        iconName="Delete"
                        className={'delete-button'}
                        onClick={() => handleTruckDelete(resource)}
                      />
                  </div>
              </Stack.Item>
          </Stack>

                  <div style={{display:'flex',overflowY:'auto',minHeight:32 }}>
                      {renderTimeSlots(resource)}
                  </div>
          </div>
      </CustomDragBox>
    );

    const EventWrapper = (props: any) => {
        // Some data that you might have inserted into the event object
        const {event, children} = props;
        const customDiv = (
          <div style={{
              width: '100%',
              height: '100%',
              backgroundColor: `${projectUniqueColor(event?.productTitle)}`,
              outline: `2px  solid #000000`,
          }}/>
        );
        const eventDiv = React.cloneElement(children.props.children, {}, customDiv);
        // const wrapper = React.cloneElement(props.children, {}, eventDiv);
        return (<div>
              {eventDiv}
          </div>
        );
    };

    const renderListPane = () => {
        return <DragAndDropCalendar
          defaultView={Views.DAY}
          events={eventList}
          date={stringDateToDateObject(selectedDate)}
          // defaultDate={new Date()}
          onNavigate={(date) => {
              dispatch(saveThAutomationStateAttr('selectedDate', date))
          }}
          // min={moment(selectedDate).set({h: 8, m: 0}).format()}
          // max={moment(selectedDate).set({h: 18, m: 0}).format()}
          localizer={localizer}
          // onEventDrop={onEventDrop}
          // onEventResize={resizeEvent}
          resources={resourceMap}
          resourceIdAccessor={(projectList: any) => projectList.resourceId}
          resourceTitleAccessor={(projectList: any) => projectList.resourceTitle}
          selectable
          showMultiDayTimes={true}
          views={{day: true}}
          components={{
              resourceHeader: ResourceHeader,
              eventWrapper: EventWrapper,
              // event:CustomEvent,
              toolbar: CustomToolbar,
          }}
          step={truckResourceViewMode ? truckResourceViewMode : DpViewMode.OneHour}
          // dayLayoutAlgorithm="no-overlap"
          formats={{
              eventTimeRangeFormat: ({start, end}, culture: any, localizer: any) =>
                localizer.format(start, 'HH:mm', culture) +
                ' - ' +
                localizer.format(end, 'HH:mm', culture),
              timeGutterFormat: (date, culture: any, localizer: any) =>
                localizer.format(date, 'HH:mm', culture),
          }}
        />
    };

    return <div className={'th-automation-bottom'}
                style={{maxWidth: '100%', height: screen.height - (horizontalReSizer + 360), overflow: 'overlay'}}>
        {renderListPane()}
    </div>
};

export default PumpCarListPane

import { Dropdown, IColumn, TextField } from '@fluentui/react';
import _ from 'lodash';
import React from 'react';
import client from '../../../../api';
import DateRangePicker from '../../../../shared/customDatePicker/dateRangePicker';
import { PROJECT_FILTER_EP } from '../../../../shared/endpoints';
import { getRsourceDropDowns } from '../../../../shared/util';
import { CostFilter } from '../../../../types/progressStatement';

export const _renderDetailsFooterItemColumn = (
  productTitle: string,
  onChangeProduct: (value: string | null) => void,
  pbsTypes: [],
  productType: string,
  botTitle: string,
  resourceTitle: string,
  onChangeBORTitle: (value: string | null) => void,
  onChangeResourceTitle: (value: string | null) => void,
  resourceType: string,
  onChangeProductType: (value: string | null) => void,
  onChangeResourceType: (value: string | null) => void,
  onDateSelected: (date: any) => void,
  costFilter: CostFilter,
  t: any,
  item?: any,
  index?: number,
  column?: IColumn,
) => {
  const promiseOptions = (inputValue: any) =>
    new Promise((resolve) => {
      //setTimeout(() => {
      resolve(getByName(inputValue));
      // }, 1000);
    });


  const getByName = async (name: string) => {
    let filter = {
      title: name,
      projectTypeId: null,
      managementLevelId: null,
      toleranceStateId: null,
      toleranceState: null,
      sorter: {
        attribute: null,
        order: null,
      },
    };
    let response = await client.post(PROJECT_FILTER_EP, filter);
    return formatResponse(response);
  };

  const formatResponse = (response: any) => {
    let data: { value: string; label: string }[] = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: any) => {
        return {
          value: item.projectDefinitionId,
          label: item.title,
          sequenceCode: item.sequenceCode,
        };
      });
    }
    return data;
  };


  switch (column!.key) {
    case 'column1': {
      return (
        <div>
          <div>
            <TextField
              value={productTitle}
              placeholder={t('productTitle')}
              onChange={(event, value) => {

                onChangeProduct(value!!);
              }}
            />
          </div>
        </div>
      );
      break;
    }
    case 'column2': {
      return (
        <div>
          <Dropdown
            placeholder={t('productType')}
            options={pbsTypes}
            selectedKey={productType}
            onChange={(event, value) => {
              if (value) {
                onChangeProductType(value?.key!!.toString());
              }
            }}
          />

        </div>
      );
      break;
    }
    case 'column3': {
      return (
        <div>
          <TextField
            value={botTitle}
            placeholder={t('borTitle')}
            onChange={(event, value) => {
              onChangeBORTitle(value!!);
            }}
          />
        </div>
      );
      break;
    }
    case 'column5': {
      return (
        <div>
          <Dropdown
            placeholder={t('resourceType')}
            options={getRsourceDropDowns()}
            selectedKey={resourceType}
            onChange={(event, value) => {
              if (value) {
                onChangeResourceType(value?.key!!.toString());
              }
            }}
          />

        </div>
      );
      break;
    }
    case 'column6': {
      return (
        <div>
          <TextField
            value={resourceTitle}
            placeholder={t('resourceTitle')}
            onChange={(event, value) => {
              onChangeResourceTitle(value!!);
            }}
          />
        </div>
      );
      break;
    }
    case 'column11': {
      return (
        <div>
          <DateRangePicker
            placeholder={'dateFromto'}
            label={'selectDate'}
            onSelected={onDateSelected}
            value={{ toDate: costFilter.strDate, fromDate: costFilter.endDate }}
          />
        </div>
      );
      break;
    }

    default: {
      break;
    }
  }
};

/* eslint-disable react/no-unused-prop-types */
import {mergeStyles, Text} from '@fluentui/react';
import React, {useState} from 'react';
import AsyncSelect from 'react-select/async';
import {uPrinceTheme} from '../../../theme';

const customStyles = {
  control: (_: any, { selectProps: { width } }: any) => ({
    ..._,
    width: width,
    height: 34,
    fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
    minHeight: 34,
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 1000001 }),
};

export const customDropdownErrorMessage = mergeStyles({
  fontSize: 12,
  fontWeight: 400,
  color: 'rgb(164, 38, 44)',
  animationDuration: '0.367s',
  animationFillMode: 'both',
});

export const DropdownIndicator = (props: any) => {
  // eslint-disable-next-line no-unused-vars
  const { className, cx, getStyles, innerProps } = props;
  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...innerProps}
      style={{ fontSize: 12, paddingRight: 10, paddingTop: 4 }}
    >
      <i
        className={'filter-icon ms-Icon ms-Icon--ChevronDown filter-icon'}
        aria-hidden="true"
      ></i>
    </div>
  );
};

const CustomDropdown = (props: {
  // options: any;
  selectedOption: any;
  onChange: (key: string, text: string, values?: any) => void;
  promiseOptions: any;
  defaultInputValue?: string | null;
  required?: boolean;
  validationMessage: string | null;
  reference: any;
  onFocus?: () => void;
  key?: any;
  id?: any;
  disabled?: boolean;
  noHeight?: boolean;
}) => {
  const [height, setHeight] = useState(50);
  const [reference, setReference] = useState(props.reference);
  const onMenuOpen = () => {
    if(!props.noHeight){
     setHeight(350);
    }
  };
  const onMenuClose = () => {
    setHeight(50);
    setReference('');
  };

  const handleChange = (selectedOption: any, actionMeta: any) => {
    if (actionMeta.action === 'clear') {
      props.onChange('', '');
    } else {
      props.onChange(selectedOption.value, selectedOption.label, selectedOption);
    }
    onMenuClose();
  };


  return (
    <div style={{ height: height, transition: 'all 0.4s linear' }}>

      <AsyncSelect
        value={(props.selectedOption) ? props.selectedOption : { value: '', label: '' }}
        key={props.id ? props.id : props.key ? JSON.stringify(props.id) : null}
        components={{ DropdownIndicator }}
        className="reactSelectContainer"
        classNamePrefix="reactSelect"
        styles={customStyles}
        onChange={handleChange}
        required={props.required}
        isClearable={!!(props.selectedOption)}
        menuPlacement={'auto'}
        theme={(theme) => ({
          ...theme,
          borderRadius: 2,
          height: 34,
          minHeight: 34,
          colors: {
            ...theme.colors,
            primary25: uPrinceTheme.palette.themeLight,
            primary: uPrinceTheme.palette.themePrimary,
            neutral20: props.validationMessage
              ? 'rgb(164, 38, 44)'
              : uPrinceTheme.palette.neutralSecondary,
            neutral30: uPrinceTheme.palette.neutralSecondary,
          },
        })}
        onFocus={() => props?.onFocus !== undefined ? props?.onFocus() : {}}
        cacheOptions
        defaultOptions
        loadOptions={props.promiseOptions}
        onMenuOpen={() => onMenuOpen()}
        onMenuClose={() => onMenuClose()}
        onBlur={() => onMenuClose()}
        ref={reference === 1 ? input => input && input.focus() : input => {
        }}
        isDisabled={props.disabled}
      />
      {props.validationMessage && (
        <Text className={customDropdownErrorMessage}>{props.validationMessage}</Text>
      )}
    </div>
  );
};

export default CustomDropdown;

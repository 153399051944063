import {
  ActionButton,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Separator,
  Stack,
} from '@fluentui/react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../theme';
import client from '../../../api';
import CustomDropdown from '../../../shared/customDropdown/customDropdown';
import { DropDownAdaptater, TextFieldFF } from '../../../shared/fluentFinalForm';
import HistoryBar from '../../../shared/historyBar/historyBar';
import { getProject } from '../../../shared/util';
import { RESOURCE_TYPE } from '../../../types/purchaseOrder';
import TaxonomyField from '../../warehouse/documentPane/taxonomy/taxonomy';
import StockHistoryGrid from './history/component';
import { createForm } from 'final-form';
import ProjectHeader from '../../../shared/projectHeader/projectHeader';
import FixedDecimalNumberFormatField from '../../../shared/fluentFinalForm/FixedDecimalNumberFormatField';

const STOCK_AVAILABLE_STATUS = '94282458-0b40-40a3-b0f9-c2e40344c8f1';

const theme = getTheme();

const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };

const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
});


const DocumentPaneComponent = (props: {
  projectHeader: any,
  submit: any;
  dropDowns: any;
  saveFormData: any;
  uid: any;
  formData: any;
  showProgress: boolean,
  formValues: any,
  isClear: boolean,
  historySelection: any,
  isExpand: boolean,
  setForm: (form: any) => void;
  setFormClearState: (isClear: boolean) => void
}) => {
  const { t } = useTranslation();
  const [state, setstate] = useState<any | null>(null);
  const onSubmit = async (values: any) => {
    if (!values.header.id) {
      values.header.id = props.uid;
    }
    // values.IsDeleted = false;
    props.setFormClearState(false);
    props.saveFormData(values.header);
  };
  const formRef = React.useRef(createForm({ onSubmit: onSubmit }));
  useEffect(() => {
    setstate(props.formData);
  }, [props.formData]);

  const formatCpcResponse = (response: any) => {
    let options: { value: string; label: string }[] = [];
    if (response && response.data && response.data.result) {
      const data = response.data.result;

      if (data && _.isArray(data)) {
        options = data.map((item: any) => {
          return {
            value: item,
            label: item.title,
          };
        });
      }


      return options;
    }
  };

  const getCpcByName = async (name: RESOURCE_TYPE) => {
    const filter = {
      resourceTypeId: null,
      resourceFamilyId: null,
      resourceNumber: name,
      title: name,
      status: null,
      isStock: true,
      sorter: {
        attribute: 'title',
        order: 'asc',
      },
    };
    const header = getProject() ? { headers: { project: getProject() } } : undefined;
    if (name.length >= 2) {
      const response = await client.post(
        'CorporateProductCatalog/CpcFilter',
        filter, header,
      );
      return formatCpcResponse(response);
    }
  };

  const cpcOptions = (inputValue: any) =>
    new Promise((resolve) => {
      // setTimeout(() => {
      resolve(getCpcByName(inputValue));
      // }, 1000);
    });

  const getInitialFormValues = (formData: any, formValue: any) => {
    if (location.pathname.split('/').pop() === 'new' && props.isClear) {
      return { header: { status: STOCK_AVAILABLE_STATUS } };
    } else if (formData && formData?.id) {
      return {
        header: {
          id: formData?.id,
          title: formData?.title,
          sequenceId: formData?.sequenceId,
          name: formData?.name,
          availableQuantity: formData?.availableQuantity,
          quantityToBeDelivered: formData?.quantityToBeDelivered,
          averagePrice: formData?.averagePrice,
          cpcId: formData?.cpcType?.value,
          cpcResourceTitle: formData?.cpcType?.label,
          status: formData?.status?.key,
          type: formData?.type?.key,
          warehouseTaxonomyId: formData?.warehouseTaxonomyId,
          mouId: formData?.mouId,
          resourceType: formData?.resourceType?.key,
        },
      };
    }
    return formValue;
  };


  const required = (value: any) => (value ? undefined : t('required'));
  return (
    <div style={{ width: '100%' }}>
      <div>
        <Form
          form={formRef.current}
          onSubmit={onSubmit}
          initialValues={getInitialFormValues(props.formData, props.formValues)}
          validate={values => {
            const errors: any = { header: {} };
            if (!values?.header?.name && !props.showProgress) {
              errors.header.name = t('nameRequired');
            }
            // if (!values?.header?.cpcResourceTitle && !props.showProgress) {
            //     errors.myField = t('resouceRequired');
            // }
            return errors;
          }}
          mutators={{
            setCUCPC: async (args, state, utils) => {
              const fieldId = state.fields['header.cpcId'];
              const fieldTitle = state.fields['header.cpcResourceTitle'];
              const fieldmou = state.fields['header.mouId'];
              const fieldPrice = state.fields['header.averagePrice'];
              const resourceType = state.fields['header.resourceType'];
              if (args && args[0] && args[0].resourceNumber) {
                fieldId.change(args[0].id);
                fieldTitle.change(args[0].name);
                // fieldmou.change(args[0].mou);
                await client.get(
                  `CorporateProductCatalog/Read/${args[0]?.resourceNumber}`,
                  // { headers: { project: props.borResource.projectTitle } }
                ).then((response: any) => {
                  if (response.data.result && response.data?.result?.cpcBasicUnitOfMeasure) {
                    fieldmou.change(response.data.result.cpcBasicUnitOfMeasure?.text);
                  } else {
                    fieldmou.change(null);
                  }
                  if (response.data.result && response.data?.result?.inventoryPrice) {
                    fieldPrice.change(response.data.result.inventoryPrice);
                  } else {
                    fieldPrice.change(0);
                  }
                  if (response.data.result && response.data?.result?.resourceType) {
                    resourceType.change(response.data.result.resourceType?.key);
                  } else {
                    resourceType.change(null);
                  }
                });
              } else {
                fieldId.change(null);
                fieldTitle.change(null);
                resourceType.change(null);
              }
            },
            setWarehouseTaxonomyId: async (args, state, utils) => {
              const warehouseTaxonomyId = state.fields['header.warehouseTaxonomyId'];
              if (args && args[0] && args[0].id) {
                warehouseTaxonomyId.change(args[0].id);
              } else {
                warehouseTaxonomyId.change(null);
              }
            },
          }}
          // validate={ validate }
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            props.submit(handleSubmit);
            props.setForm(form);

            // const headerFiles = values.header?.files;
            return (
              <form onSubmit={handleSubmit} noValidate>

                <div className="proj-detail-block">
                  {getProject() && <ProjectHeader projectDetails={props.projectHeader}/>}
                </div>

                <div className="proj-detail-block" id="1">
                  <div className="document-pane-card">
                    <div className={'card-header'}>
                      <Link href="#" id="stock-header">
                        <Label>1. {t('stockHeader')}</Label>
                      </Link>
                    </div>
                    {state?.sequenceId && (
                      <Stack
                        horizontal
                        wrap
                        styles={stackStyles}
                        tokens={stackTokens}
                      >
                        <Stack.Item grow={6} styles={stackItemStyles}>
                          <div className={`${classNames.fullWidth}`}>
                            <Field
                              name="header.title"
                              component={TextFieldFF}
                              lable={t('title')}
                              disabled={true}
                            />
                          </div>
                        </Stack.Item>
                        <Stack.Item grow={6} styles={stackItemStyles}>
                          <div className={`${classNames.fullWidth}`}>
                            <Field
                              name="header.sequenceId"
                              component={TextFieldFF}
                              lable={t('ID')}
                              disabled={true}
                            />
                          </div>
                        </Stack.Item>
                      </Stack>
                    )}

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="header.name"
                            component={TextFieldFF}
                            lable={t('name')}
                            required={true}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Label required={true}>{t('resourceTitle')}</Label>
                          <Field name="header.cpcResourceTitle" validate={required}>
                            {({ input, meta }) => (
                              <CustomDropdown
                                reference={''}
                                onChange={(value: any, label: string) => {
                                  if (value) {
                                    const cpc = {
                                      id: value.id,
                                      name: label,
                                      mou: value.basicUnitOfMeasure,
                                      resourceNumber: value.resourceNumber,
                                    };
                                    form.mutators.setCUCPC(cpc);
                                    // meta.error = null;
                                    input.onChange(label);
                                  } else {
                                    form.mutators.setCUCPC(null);
                                  }
                                }}
                                selectedOption={(values && values?.header?.cpcResourceTitle ? {
                                  value: values?.header?.cpcId,
                                  label: values?.header?.cpcResourceTitle,
                                } : null)}
                                promiseOptions={cpcOptions}
                                validationMessage={meta.touched && meta.error ? meta.error : null}
                                required={true}
                              />
                            )}
                          </Field>
                        </div>
                        { /* <Field
                                                name="header.cpcResourceTitle"
                                                component={ 'input' }
                                                type={ 'hidden' }
                                                disabled={ false }
                                                hidden
                                            /> */}
                        <Field
                          name="header.cpcId"
                          component={'input'}
                          type={'hidden'}
                          disabled={false}
                          hidden
                        />
                      </Stack.Item>
                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="header.resourceType"
                            component={DropDownAdaptater}
                            options={props.dropDowns.resourceTypes}
                            lable={t('resourceType')}
                            placeholder={t('resourceType')}
                            disabled={true}

                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="header.type"
                            component={DropDownAdaptater}
                            options={props.dropDowns.types}
                            lable={t('type')}
                            placeholder={t('type')}
                            disabled={false}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="header.status"
                            component={DropDownAdaptater}
                            options={props.dropDowns.status}
                            lable={t('status')}
                            placeholder={t('status')}
                            disabled={false}
                            initialValue={STOCK_AVAILABLE_STATUS}

                          />
                        </div>
                      </Stack.Item>

                    </Stack>
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="header.availableQuantity"
                            component={FixedDecimalNumberFormatField}
                            options={props.dropDowns.types}
                            lable={t('availableQuantity')}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="header.quantityToBeDelivered"
                            component={FixedDecimalNumberFormatField}
                            lable={t('quantityToBeDelivered')}
                            disabled={false}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="header.mouId"
                            component={TextFieldFF}
                            lable={t('mou')}
                            disabled={true}
                            readOnly
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="header.averagePrice"
                            component={FixedDecimalNumberFormatField}
                            lable={t('averagePrice')}
                            disabled={true}
                            prefix="€"
                            readOnly
                            // parse={ value => value  }
                            // format={ value => (value ? formatPrice(value) : null) }
                          />
                        </div>
                      </Stack.Item>
                    </Stack>

                    <div className={`${classNames.fullWidth}`}>
                      <div style={{ zIndex: 10000000 }}>
                        <Field
                          name="header.warehouseTaxonomyId"
                          component={'input'}
                          type={'hidden'}
                          disabled={false}
                          hidden
                        />
                        <TaxonomyField
                          label={t('warehouseTaxonomy')}
                          treeData={props.dropDowns.warehouseTaxonomy}
                          selectItemId={formRef.current.getState().values?.header?.warehouseTaxonomyId ? formRef.current.getState().values?.header?.warehouseTaxonomyId : null}
                          onSelectItem={(id: string) => {
                            if (id) {
                              if (id === formRef.current.getState().values?.header?.warehouseTaxonomyId) {
                                form.mutators.setWarehouseTaxonomyId(null);
                              } else {
                                form.mutators.setWarehouseTaxonomyId({ id: id });
                              }
                            } else {
                              form.mutators.setWarehouseTaxonomyId(null);
                            }
                          }}
                          treeLevel={props.dropDowns.whTaxonomyLevel}
                          onFilterTaxonomyNodeChanged={() => {
                          }}
                          disabled={false}
                          isStock={true}
                        />
                      </div>
                    </div>

                    <div className={'card-footer'}>
                      <Separator/>
                      <Stack
                        styles={stackStyles}
                        tokens={itemAlignmentsStackTokens}
                      >
                        <Stack.Item align="end" styles={stackFooterBarItemStyles}>
                          <ActionButton
                            className={classNames.actionButton}
                            iconProps={saveIcon}
                            allowDisabledFocus
                            onClick={() => {
                              form.submit();
                            }}
                          >
                            <Label className={classNames.actionButton}>
                              {t('save')}
                            </Label>
                          </ActionButton>
                        </Stack.Item>
                      </Stack>
                    </div>
                  </div>
                </div>
                <div className="proj-detail-block" id="2">
                  <div className={`wrapper-holder ${classNames.wrapper}`}>
                    <StockHistoryGrid
                      dataList={props.formData?.stockHistory}
                      title={t('stockHistory')}
                      link={'stock-history'}
                      id={'2'}
                      columns={[]}
                      isLoaded={false}
                      selection={props.historySelection}
                      setOpenPOResourceModal={() => {
                      }}
                      resourceType={RESOURCE_TYPE.MATERIALS}
                      isExpand={props.isExpand}
                      unitPrice={props.formData?.averagePrice}
                    />
                  </div>
                </div>


                {
                  props.formData.history &&
                  props.formData.history.createdDate && (
                    <div className="proj-detail-block" id="3">
                      <div className="document-pane-card">
                        <div className="marginTop marginBottom">
                          <Link href="#" id="history">
                            <Label>3. {t('history')} </Label>
                          </Link>

                          <HistoryBar
                            createdByUser={
                              props.formData.history.createdBy
                                ? props.formData.history.createdBy
                                : ''
                            }
                            updatedByUser={
                              props.formData.history.modifiedBy
                                ? props.formData.history.modifiedBy
                                : ''
                            }
                            createdDateTime={
                              props.formData.history.createdDate
                                ? props.formData.history.createdDate
                                : ''
                            }
                            updatedDateTime={
                              props.formData.history.modifiedDate
                                ? props.formData.history.modifiedDate
                                : ''
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                { /* <pre>{ JSON.stringify(values, 0, 2) }</pre>  */}
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};
export default DocumentPaneComponent;

import { DirectionalHint, Label, Link, Stack, Text, TooltipHost } from '@fluentui/react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ShortCutPaneItem } from '../../../types/projectBreakdownStructure';
import { PMOLShortCutPaneItem } from '../../../types/projectMolecule';
import { StockShortCutPaneItem } from '../../../types/stock';
import { iconColors, uPrinceTheme } from '../../../../theme';

const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 14vh)!important',
    position: 'relative',
  },
  selected: {
    fontWeight: 'bold',
    color: '#854114!important',
  },
});

export const ShortCutPaneComponent = (props: {
  toggleOverlay: () => void;
  toggleOverlayFwd: () => void;
  divClass: string;
  isCollapse: boolean;
  shortCutPaneFilters: PMOLShortCutPaneItem[];
  currentActiveSection: string | null;
  handelShortCutPaneFilter: (selectedItem: StockShortCutPaneItem) => void;
  resetFilter: () => void;
  isExpand: boolean;
  type: string
}) => {
  const { t } = useTranslation();

  const setFilterOption = (selectedItem: StockShortCutPaneItem) => {
    props.handelShortCutPaneFilter(selectedItem);
  };

  const isSelectedItem = (currentActiveSection: any, item: any) => {
    if (currentActiveSection && (props.currentActiveSection === item.id) && props.type === item.type) {
      return true;
    } else if (!currentActiveSection && props.type === item.type && item.id === null) {
      return true;
    }
    return false;

  };

  const renderFilterItems = () => {
    if (props.shortCutPaneFilters) {
      return props.shortCutPaneFilters.map((item: any, index) => {
        let divider = false;
        if (index > 0) {
          const previousItem: ShortCutPaneItem =
            props.shortCutPaneFilters[index - 1];
          if (previousItem.type !== item.type) {
            divider = true;
          } else {
            divider = false;
          }
        } else {
          divider = true;
        }

        return (
          <div key={index + 'div'}
               style={{ marginLeft: !item.isMain && !props.isCollapse && !props.isExpand ? 20 : 0 }}>
            {divider && (
              <hr
                key={index + 'hr'}
                style={{ marginBottom: 15, borderTop: '1px solid #d2d2d2' }}
              />
            )}
            <Link
              key={index + 'link'}
              className={`${isSelectedItem(props.currentActiveSection, item) ? classNames.selected : ''
              }`}
              href="#"
              onClick={(evt) => {
                evt.preventDefault();
                setFilterOption(item);
              }}
            >
              <TooltipHost
                key={index + 't'}
                content={item.name}
                id="filter-opt-1"
                calloutProps={{ gapSpace: 0 }}
                directionalHint={DirectionalHint.rightCenter}
              >
                <i
                  key={index + 'i'}
                  className={`ms-Icon ${item.icon}`}
                  aria-labelledby="filter-opt-1"
                  aria-hidden="true"
                  style={
                    isSelectedItem(props.currentActiveSection, item)
                      ? { color: iconColors.iconActiveColor }
                      : { color: uPrinceTheme.palette.themePrimary }
                  }
                ></i>
              </TooltipHost>
              <i
                key={index + 'i2'}
                className={`filter-icon ms-Icon ${item.icon} filter-icon`}
                aria-hidden="true"
                style={
                  isSelectedItem(props.currentActiveSection, item)
                    ? { color: iconColors.iconActiveColor }
                    : { color: uPrinceTheme.palette.themePrimary }
                }
              ></i>
              <Text key={index}>{item.name} </Text>
            </Link>
          </div>
        );
      });
    }
    return <div></div>;
  };

  return (
    <div className={`${props.divClass} ${classNames.wrapper}`}>
      <div className="inner-container">
        <i
          onClick={props.toggleOverlay}
          className="ms-Icon ms-Icon--Back back-arrow"
          aria-hidden="true"
        ></i>
        <i
          onClick={props.toggleOverlayFwd}
          className="ms-Icon ms-Icon--Forward fwd-arrow"
          aria-hidden="true"
        ></i>

        <div className="filter-data filter-title">
          <TooltipHost
            content={t('projectBreakdownStructure')}
            // This is the important part!
            id="filter-opt-title"
            calloutProps={{ gapSpace: 0 }}
            directionalHint={DirectionalHint.rightCenter}
          >
            <Label
              aria-describedby={'filter-opt-title'}
              className={'short-cut-pane-label'}
            >
              {t('stock')}
            </Label>
          </TooltipHost>
        </div>

        <div className="filter-option-list">
          <Stack
            gap={15}
            verticalFill
            styles={{
              root: {
                width: '100%',
                verticalAlign: 'center',
              },
            }}
          >
            {renderFilterItems()}
          </Stack>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShortCutPaneComponent);

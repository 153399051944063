import * as React from 'react';
import { Shimmer, ShimmerElementType } from '@fluentui/react/lib/Shimmer';
import { Fabric } from '@fluentui/react/lib/Fabric';
import { mergeStyles } from '@fluentui/react/lib/Styling';

const wrapperClass = mergeStyles({
  padding: 2,
  selectors: {
    '& > .ms-Shimmer-container': {
      margin: '10px 0',
    },
  },
});

const shimmerTextArea = [
  { type: ShimmerElementType.line, height: 80, width: 80 },
];

const oneColumn = [
  { type: ShimmerElementType.line, height: 30, width: '49%' },
];

const twoColumn = [
  { type: ShimmerElementType.line, height: 30, width: '49%' },
  { type: ShimmerElementType.gap, width: '2%' },
  { type: ShimmerElementType.line, height: 30, width: '49%' },
];

export const CustomShimmer: React.FunctionComponent = () => {
  return (
    <Fabric className={wrapperClass}>
      <Shimmer height={20} width="100%"/>
      <p style={{ height: 20 }}>&nbsp;</p>
      <Shimmer shimmerElements={twoColumn}/>
      <p style={{ height: 10 }}>&nbsp;</p>
      <Shimmer shimmerElements={oneColumn} width="49%"/>
      <p style={{ height: 10 }}>&nbsp;</p>
      <Shimmer shimmerElements={shimmerTextArea}/>
      <p style={{ height: 10 }}>&nbsp;</p>
      <Shimmer shimmerElements={twoColumn}/>
      <p style={{ height: 2 }}>&nbsp;</p>
      <Shimmer shimmerElements={twoColumn}/>
      <p style={{ height: 30 }}>&nbsp;</p>
      <Shimmer shimmerElements={twoColumn}/>
      <p style={{ height: 2 }}>&nbsp;</p>
      <Shimmer shimmerElements={twoColumn}/>
      <p style={{ height: 30 }}>&nbsp;</p>
      <Shimmer shimmerElements={twoColumn}/>
      <p style={{ height: 2 }}>&nbsp;</p>
      <Shimmer shimmerElements={oneColumn} width="49%"/>
      <p style={{ height: 30 }}>&nbsp;</p>
      <Shimmer shimmerElements={twoColumn}/>
      <p style={{ height: 2 }}>&nbsp;</p>
      <Shimmer shimmerElements={twoColumn}/>
      <p style={{ height: 2 }}>&nbsp;</p>
      <Shimmer shimmerElements={twoColumn}/>
      <p style={{ height: 20 }}>&nbsp;</p>
      <Shimmer height={20} width="100%"/>
      <Shimmer height={20} width="100%"/>
      <Shimmer height={20} width="100%"/>
      <Shimmer height={20} width="100%"/>
      <Shimmer height={20} width="100%"/>
      <Shimmer height={20} width="100%"/>
      <Shimmer height={20} width="100%"/>

    </Fabric>
  );
};

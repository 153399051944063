import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  AccordionToggleEventHandler,
  Caption1,
  Card,
  CardHeader,
  CardPreview,
  Overflow,
  Text,
  tokens,
} from '@fluentui/react-components';
import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { ChevronDown20Regular, ChevronRight20Regular, CircleSmall20Filled } from '@fluentui/react-icons';

interface Props {
  conversations: any[];
}

export default function ConversationComponent(props: Props) {
  const { conversations } = props;

  const [openItem, setOpenItems] = React.useState<any>();

  const handleToggle = React.useCallback<AccordionToggleEventHandler>(
    (_, data: any) => {
      setOpenItems(data.openItems[0]);
    },
    [],
  );
  return (
    <Overflow overflowAxis='vertical'>
      {conversations && <div>
        <Accordion collapsible style={{ width: '100%' }} onToggle={handleToggle}>
          {conversations?.map((items: any) => (
            <Card appearance='subtle'>
              <AccordionItem key={items?.task} value={items?.task}>
                <AccordionHeader
                  expandIcon={!items?.conversations || _.isEmpty(items?.conversations) ? <CircleSmall20Filled /> :
                    items?.task === openItem ? <ChevronDown20Regular /> : <ChevronRight20Regular />}
                  style={{ fontSize: 24, flexDirection: 'row', flex: 1 }}
                >
                  <CardHeader
                    style={{ width: '100%' }}
                    header={<Text weight='semibold'>
                      {`${items?.task?.slice(0, 50)}${items?.task?.length > 50 ? '...' : ''}`}
                    </Text>}
                  />
                </AccordionHeader>
                <AccordionPanel>
                  <Accordion collapsible style={{ width: '100%' }}>
                    {items?.conversations?.map((conversation: any) => (
                      <Card appearance='subtle'>
                        <AccordionItem key={conversation?.id} value={conversation?.id}>
                          <AccordionHeader style={{ fontSize: 24, flexDirection: 'row', flex: 1 }}>
                            <CardHeader
                              style={{ width: '100%' }}
                              header={<Text weight='semibold'>
                                {`${conversation?.subject?.slice(0, 50)}${conversation?.subject?.length > 50 ? '...' : ''}`}
                              </Text>}
                              description={
                                <div>
                                  <Caption1 style={{ color: tokens.colorNeutralForeground3 }}>
                                    From: {conversation?.fromPersonName}
                                  </Caption1>
                                  <div style={{display: 'flex', gap: 10}}>
                                    {!_.isEmpty(conversation?.toPerson) && <Caption1 style={{ color: tokens.colorNeutralForeground3 }}>
                                      To: {conversation?.toPerson?.map((item: any) => item?.personName).join(', ')}
                                    </Caption1>}
                                    {!_.isEmpty(conversation?.cc) && <Caption1 style={{ color: tokens.colorNeutralForeground3 }}>
                                      CC: {conversation?.cc?.map((item: any) => item?.personName).join(', ')}
                                    </Caption1>}
                                  </div>
                                </div>
                              }
                              action={
                                //  this div is added because we cannot access CardHeader  action styles.
                                <div style={{
                                  width: 'max-content',
                                  height: '42px',
                                  display: 'flex',
                                  alignItems: 'flex-end',
                                }}>
                                  <Caption1 style={{ color: tokens.colorNeutralForeground3 }}>
                                    {moment(conversation?.dateTime).format('ddd DD/MM/YYYY hh:ss A')}
                                  </Caption1>
                                </div>
                              }
                            />
                          </AccordionHeader>
                          <AccordionPanel>
                            <CardPreview
                              // logo={<Mail24Regular />}
                              style={{ padding: '10px 40px', height: 150, overflow: 'overlay' }}
                            >
                              <div dangerouslySetInnerHTML={{ __html: conversation?.content }} />
                            </CardPreview>
                          </AccordionPanel>
                        </AccordionItem>
                      </Card>
                    ))}
                  </Accordion>
                </AccordionPanel>
              </AccordionItem>
            </Card>
          ))}
        </Accordion>
      </div>}
    </Overflow>
  );
}

// width: max-content;
// height: 100%;
// display: flex;
// align-items: flex-end;
export const LOAD_STANDARD_MAIL_SHORTCUTPANE = 'uprince/LOAD_STANDARD_MAIL_SHORTCUTPANE';
export const LOAD_STANDARD_MAIL_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_STANDARD_MAIL_SHORTCUTPANE_SUCCESS';
export const LOAD_STANDARD_MAIL_SHORTCUTPANE_FAIL = 'uprince/LOAD_STANDARD_MAIL_SHORTCUTPANE_FAIL';

export const LOAD_STANDARD_MAIL_LSITPANE = 'uprince/LOAD_STANDARD_MAIL_LSITPANE';
export const LOAD_STANDARD_MAIL_LSITPANE_SUCCESS = 'uprince/LOAD_STANDARD_MAIL_LSITPANE_SUCCESS';
export const LOAD_STANDARD_MAIL_LSITPANE_FAIL = 'uprince/LOAD_STANDARD_MAIL_LSITPANE_FAIL';

export const LOAD_STANDARD_MAIL_DROPDOWN = 'uprince/LOAD_STANDARD_MAIL_DROPDOWN';
export const LOAD_STANDARD_MAIL_DROPDOWN_SUCCESS = 'uprince/LOAD_STANDARD_MAIL_DROPDOWN_SUCCESS';
export const LOAD_STANDARD_MAIL_DROPDOWN_FAIL = 'uprince/LOAD_STANDARD_MAIL_DROPDOWN_FAIL';

export const SAVE_STANDARD_MAIL = 'uprince/SAVE_STANDARD_MAIL';
export const SAVE_STANDARD_MAIL_SUCCESS = 'uprince/SAVE_STANDARD_MAIL_SUCCESS';
export const SAVE_STANDARD_MAIL_FAIL = 'uprince/SAVE_STANDARD_MAIL_FAIL';

export const LOAD_STANDARD_MAIL_BY_ID = 'uprince/LOAD_STANDARD_MAIL_BY_ID';
export const LOAD_STANDARD_MAIL_BY_ID_SUCCESS = 'uprince/LOAD_STANDARD_MAIL_BY_ID_SUCCESS';
export const LOAD_STANDARD_MAIL_BY_ID_FAIL = 'uprince/LOAD_STANDARD_MAIL_BY_ID_FAIL';

export const LOAD_BOR_RESOURCES_BY_ID = 'uprince/LOAD_BOR_RESOURCES_BY_ID';
export const LOAD_BOR_RESOURCES_BY_ID_SUCCESS = 'uprince/LOAD_BOR_RESOURCES_BY_ID_SUCCESS';
export const LOAD_BOR_RESOURCES_BY_ID_FAIL = 'uprince/LOAD_BOR_RESOURCES_BY_ID_FAIL';

export const SET_STANDARD_MAIL_UID = 'uprince/SET_STANDARD_MAIL_UID';
export const CLEAR_STANDARD_MAIL_UID = 'uprince/CLEAR_STANDARD_MAIL_UID';
export const CLEAR_STANDARD_MAIL_FORM_DATA = 'uprince/CLEAR_STANDARD_MAIL_FORM_DATA';
export const RESET_STANDARD_MAIL_IS_CHANGE = 'uprice/RESET_STANDARD_MAIL_IS_CHANGE';


export const CLEAR_STANDARD_MAILUID = 'uprice/CLEAR_STANDARD_MAILUID';
export const SAVE_SM_STATE_ATTR = 'uprice/SAVE_SM_STATE_ATTR';

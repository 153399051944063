import React from 'react';
import { getPmolType } from '../../../types/myDayPlanning';


const EventWrapper = ({ event, children }: any) => {
  const newChildren = { ...children };
  const newChildrenProps = { ...newChildren.props };
  newChildrenProps.className = `${newChildrenProps.className}`;
  newChildren.props = { ...newChildrenProps };

  return <div className={`customEventWrapper  ${getPmolType(event)}`}>
    {newChildren}
  </div>;
};

export default EventWrapper

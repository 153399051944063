import React from 'react';
import { connect } from 'react-redux';
import DocumentPaneComponent from './component';
import { WithTranslation, withTranslation } from 'react-i18next';
import _ from 'lodash';
import i18n from '../../../../i18n';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import history from '../../../history';
import { messageService } from '../../../services/messageService';
import { getProject } from '../../../shared/util';

import {
  getProjectHeaderDetails,
  getAllProjectDropdowns,
  getProjectDefinition,
} from '../../../reducers/projectReducer';
import {
  InstructionRegister,
  formData,
} from '../../../types/instructionRegister';
import {
  saveFormData,
  readByInstructionRegisterId,
  uploadInstructionDoc
} from '../../../reducers/instructionRegisterReducer';
import { v4 as uuidv4 } from 'uuid';
import UprinceLogger from '../../../shared/Logger/logger';

export interface State {
  isDirty: boolean;
  redirect: boolean;
  loading: boolean;
  isClear: boolean;
  isEdit: boolean;
  isCostModalOpen: boolean;
  showPrint: boolean;
  isApproved: boolean;
  ledgerDropDowns: { key: string; text: string }[];
  generalLedger: string;
  resourceData: any;
  openPOResourceModal: boolean;
  uid: string | null;
}

interface Props extends WithTranslation {
  irDropDowns: any;
  saveFormData: (data: InstructionRegister) => void;
  getAllProjectDropdowns: () => void;
  test: string;
  formData: any;
  readByInstructionRegisterId: (id: string) => void;
  irId: any;
  isNewIR: boolean;
}

class DocumentPane extends React.Component<any, any> {
  subscription: any;
  submitExternal: any;
  form: any;
  constructor(props: any) {
    super(props);
    this.state = {
      isDirty: false,
      isEdit: false,
      redirect: false,
      loading: false,
      formData: formData,
      showPrint: false,
      isApproved: false,
      uid: null,
      isClear: false,
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevProps.isNew !== this.props.isNew ||
      prevProps.uid !== this.props.uid
    ) {
      if (this.props.isNew) {
        const formData = this.state.formData;
        formData.id = this.props.uid;
        this.setState({ formData, isClear: false });
      }
    } else {
    }

    if (prevProps.irId !== this.props.irId) {
      if (this.props.irId) {
        // this.props.saveInstructionStateAttr('uploadedInstructionLink', null);
        this.props.readByInstructionRegisterId(this.props.irId);
      }
    }

    if (prevProps.formData !== this.props.formData) {
      if (!this.state.isDirty) {
        if (!_.isEmpty(this.props.formData)) {
          const formData = this.props.formData;
          this.setState({ formData: formData, isClear: false });
        }
      } else {
        if (this.props.isNew && _.isEmpty(this.props.formData)) {
          const formData = this.state.formData;
          this.setState({ formData: formData, isClear: false });
        }
      }
    }
  }

  componentDidMount() {
    // get document pane dropdowns
   
    if (this.props.projectState?.length < 0) {
      this.props.getAllProjectDropdowns();
    }
    // this.setState({ uid: uuidv4(), isClear: true, this.props.isNewIR });
    this.setState({ uid: uuidv4(), isClear: true });

    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewIR) {
          if (getProject()) {
            this.setState({ uid: uuidv4(), isClear: true });
          } else {
            this.setState({ uid: uuidv4(), isClear: true });
          }

          if (this.form) {
            this.form.reset();
            this.form.resetFieldState('name');
            this.form.resetFieldState('instructionType');
            this.form.resetFieldState('pbsInstructionFamilyId');
            //   this.form.resetFieldState('header.cpcResourceTitle');
          }
        }
        if (data.data.saveIRData) {
          // this.saveFormData();
          this.submitExternal();
        }
        if (data.data.irFilter) {
          this.setState({ filter: data.data.ir });
        }
        if (data.data.pdfGenerate) {
          this.setState({ showPrint: true });
        }
      }
    });
    if (getProject()) {
      const projectId = getProject();
      if (projectId) {
        this.props.getProjectHeaderDetails(projectId);
      }
    } else if (this.props.formData?.id) {
      this.props.getProjectHeaderDetails(this.props.formData?.id);
    }
    if (this.props.formData?.id) {
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { psFilter: this.state.filter },
    });
  };

  setSubmitExternal = (submit: any) => {
    this.submitExternal = submit;
  };

  setForm = (form: any) => {
    this.form = form;
  };

  render() {
    return (
      <div style={{ width: '100%' }}>
        <RouteLeavingGuard
          when={this.state.isDirty}
          navigate={(path) => this.handleDiscardChangesNavigate(path)}
          shouldBlockNavigation={() => {
            return true;
          }}
          handleConfirmation={(isConfirm: boolean) => {
            this.setState({ isDirty: !isConfirm });
          }}
        />
        <DocumentPaneComponent
          submit={(submit: any) => {
            this.setSubmitExternal(submit);
          }}
          dropDowns={this.props.dropDowns}
          saveFormData={this.props.saveFormData}
          uid={this.state.uid}
          formData={this.props.formData}
          showProgress={this.props.isLoadingInstructionRegister}
          formValues={this.form?.getState()?.values}
          isClear={this.state.isClear}
          isExpand={this.props.isExpand}
          setForm={(form: any) => {
            this.setForm(form);
          }}
          createInstruction={undefined}
          hasChangeIntialFromData={false}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isEdit: state.ir.isEdit,
    isNew: state.ir.isNew,
    uid: state.ir.uid,
    projectHeader: state.project.projectHeader,
    showProgressBar: state.ir.showProgressBar,
    selectedProject: state.project.selectedProject,
    dropDowns: state.ir.irDropDowns,
    irId: state.ir.irId,
    projectState: state.project.projectState
  };
};

const mapDispatchToProps = {
  saveFormData,
  getProjectHeaderDetails,
  getAllProjectDropdowns,
  getProjectDefinition,
  readByInstructionRegisterId,
  uploadInstructionDoc,
};

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(DocumentPane));

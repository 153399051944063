import React from 'react';
import {
  DatePicker,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Stack,
  TextField,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../../theme';
import ClearableDropdown from '../../../../shared/clearableDropdown/clearableDropdown';
import { Invoice, InvoiceValidationMessage } from '../../../../types/invoice';
import { formatDateTimeToISOString } from '../../../../shared/util';
import CustomDatePicker from '../../../../shared/customDatePicker/customDatePicker';
import { ProgressStatement } from '../../../../types/progressStatement';


const theme = getTheme();

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

// Styles definition
const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};


const classNames = mergeStyleSets({
  fullWidth: {
    width: '100%',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  bigScreenTree: {
    marginBottom: 30,
    height: '30vh',
  },
  smallScreenTree: {
    marginBottom: 30,
    height: '56vh',
  },
  textEditor: {
    height: window.innerWidth <= 1280 ? '50vh' : '25vh',
  },
});

const stackFooterBarItemStyles: IStackItemStyles = {
  root: {
    height: 45,
  },
};
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };


const InvoiceInformation = (props: {
  isEdit: boolean,
  dropDownOptions: any,
  formData: Invoice;
  psFormData: ProgressStatement;
  saveFormData: () => void;
  handleFormChange: (
    invoice: Invoice,
    save: boolean,
  ) => void;
  validationMessages: InvoiceValidationMessage;
}) => {
  const { t } = useTranslation();
  // const options: IChoiceGroupOption[] = [
  //   { key: '0', text: t('inScope"'),checked:true},
  //   { key: '1', text: t('outofScope"') ,styles:{field: { marginLeft: "15px"}}},
  // ];
  //const [contractIsRequired, setcontractIsRequired] = useState(false);

  return (
    <div className={'document-pane-card'}>

      <div className={'card-header'}>
        <Link href="#" id="invoice-header">
          <Label>1. {t('invoiceInformation')} </Label>
        </Link>
      </div>

      <div className={'card-body'}>
        <div className="proj-detail-content inner">
          <div className="marginTop">
            {props.isEdit && <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    label={t('title')}
                    value={(props.formData.title) ? props.formData.title : ''}
                    disabled={true}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    label={t('invoiceId')}
                    value={(props.formData.invoiceId) ? props.formData.invoiceId : ''}
                    disabled={true}
                  />
                </div>
              </Stack.Item>
            </Stack>}
          </div>

          <div className="marginTop">
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    label={t('name')}
                    value={
                      props.formData.name
                        ? props.formData.name
                        : ''
                    }
                    errorMessage={
                      props.validationMessages.nameErrorMsg
                        ? props.validationMessages.nameErrorMsg
                        : ''
                    }
                    // required={true}
                    onChange={(event, value) => {
                      if (value) {
                        props.formData.name = value;
                        props.validationMessages.nameErrorMsg = '';
                      } else {
                        props.formData.name = null;
                      }
                      props.handleFormChange(
                        props.formData,
                        true,
                      );
                    }}
                    disabled={true}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <ClearableDropdown
                    placeholder={t('generalLedger')}
                    label={t('generalLedger')}
                    options={props.dropDownOptions.ledgerDropDowns}
                    // required={true}
                    errorMessage={
                      props.validationMessages.typeErrorMsg
                        ? props.validationMessages.typeErrorMsg
                        : ''
                    }
                    onChange={(event, value) => {
                      if (value) {
                        props.formData.projectTypeId = value.key.toString();
                      } else {
                        props.formData.projectTypeId = null;
                      }
                      props.validationMessages.typeErrorMsg = '';
                      props.handleFormChange(
                        props.formData,
                        true,
                      );
                    }}
                    selectedKey={
                      props.psFormData.generalLedgerId
                        ? props.psFormData.generalLedgerId
                        : ''
                    }
                    disabled={true}
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>


          <div className="marginTop">
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <DatePicker
                    label={t('projectCompletionDate')}
                    placeholder={t('selectadate')}
                    ariaLabel={t('projectCompletionDate')}
                    value={(props.dropDownOptions.projectCompletionDate) ? new Date(props.dropDownOptions.projectCompletionDate) : undefined}
                    disabled={true}
                  />

                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <ClearableDropdown
                    placeholder={t('status')}
                    label={t('status')}
                    options={props.dropDownOptions.status}
                    // required={true}
                    errorMessage={
                      props.validationMessages.statusErrorMsg
                        ? props.validationMessages.statusErrorMsg
                        : ''
                    }
                    onChange={(event, value) => {
                      if (value) {
                        props.formData.invoiceStatusId = value.key.toString();
                      } else {
                        props.formData.invoiceStatusId = null;
                      }
                      props.validationMessages.statusErrorMsg = '';
                      props.handleFormChange(
                        props.formData,
                        true,
                      );
                    }}
                    selectedKey={
                      props.formData.invoiceStatusId
                        ? props.formData.invoiceStatusId
                        : ''
                    }
                    disabled={true}
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>

          <div className="marginTop">
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <CustomDatePicker
                    setMinDate={null}
                    setMaxDate={null}
                    label={t('workPeriodfrom')}
                    setValue={(props.formData.workPeriodFrom) ? new Date(props.formData.workPeriodFrom) : null}
                    getValue={(date: Date) => {
                      props.formData.workPeriodFrom = formatDateTimeToISOString(date);
                      props.handleFormChange(
                        props.formData,
                        true,
                      );
                    }}
                    disabled={true}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <CustomDatePicker
                    setMinDate={null}
                    setMaxDate={null}
                    label={t('workPeriodto')}
                    setValue={(props.formData.workPeriodTo) ? new Date(props.formData.workPeriodTo) : null}
                    getValue={(date: Date) => {
                      props.formData.workPeriodTo = formatDateTimeToISOString(date);
                      props.handleFormChange(
                        props.formData,
                        true,
                      );
                    }}
                    disabled={true}
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>


        </div>
      </div>

      <div className={'card-footer'}>

      </div>

    </div>
  );
};


export default InvoiceInformation;

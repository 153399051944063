import { createForm } from 'final-form';
import {
  FontWeights,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  mergeStyleSets,
  PrimaryButton,
  Text,
} from '@fluentui/react';
import React, { useRef } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { uPrinceTheme } from '../../../../../../theme';
import PeoplePicker from '../../../../../shared/fluentFinalForm/PeoplePicker';

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 0px 14px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '40vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const addWhiteIconButtonStyles = {
  root: { color: uPrinceTheme.palette.white },
  rootHovered: { color: theme.palette.neutralDark },
};
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};
const uploadIconWhite: IIconProps = {
  iconName: 'Upload',
  styles: addWhiteIconButtonStyles,
};

const ContractorModal = (props: {
  constructorTeam: any;
  disabled: boolean;
  onChange: any;
  cabCompanyId: string;
  dataList: any[];
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { projectScopeStatus, userRoles }: any = useSelector(
    (state: any) => state.project,
  );
  const { uploadedInstructionLink }: any = useSelector(
    (state: any) => state.projectBreakdown,
  );
  const inputFile: any = useRef(null);

  const stackItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 50,
      width: 0,
      flexGrow: 1,
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
  };

  const stackStyles: IStackStyles = { root: { padding: 0 } };

  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
    fullWidth: { width: '100%' },
    halfWidth: { width: '49%' },
  });

  const onSubmit = async (values: any) => {
    const sleep = (ms: number | undefined) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);
    props.onChange(values);
  };

  const formRef = React.useRef(createForm({ onSubmit: onSubmit }));

  const getInitialvalues = (contractors: any) => {
    if (contractors) {
      return contractors;
    }
    return {
      id: null,
      cabPersonId: null,
      cabPersonName: null,
    };
  };

  return (
    <div className={contentStyles.body} style={{ minWidth: '20vw', minHeight: 'auto' }}>
      <div className="proj-detail-content inner">
        <div className="ms-Grid-row">
          <Form
            onSubmit={onSubmit}
            initialValues={getInitialvalues(props.constructorTeam)}
            mutators={{
              setCabPersonName: (args, state, utils) => {
                const field = state.fields.cabPersonName;
                field.change(args[0]);
              },
            }}
            validate={(values) => {
              const errors: any = {};
              if (!values?.cabPersonId) {
                errors.cabPersonId = t('personRequired');
              }
              if (Array.isArray(props.dataList) && !values.id) {
                const index = props.dataList.findIndex((object) => {
                  return object.cabPersonId === values.cabPersonId;
                });
                if (index !== -1) {
                  errors.cabPersonId = t('alreadyAdded');
                }
              }
              return errors;
            }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              // <form onSubmit={ handleSubmit }>
              <div>
                <FormSpy
                  onChange={(spyProps: any) => {
                  }}
                />
                <div>
                  <Field name="cabPersonId">
                    {({ input, meta }) => (
                      <>
                        <PeoplePicker
                          label={t('name')}
                          isDisabled={props.disabled}
                          selectedItem={
                            values?.cabPersonId
                              ? [
                                {
                                  key: values?.cabPersonName,
                                  text: values?.cabPersonName,
                                },
                              ]
                              : []
                          }
                          onChange={(items: any) => {
                            input.onChange;
                            return items;
                          }}
                          onPersonSelected={(item: any) => {
                            input.onChange(item.key);
                            form.mutators.setCabPersonName(item.text);
                            return item;
                          }}
                          endpoint={'Contractor/PersonFilter'}
                          companyId={props.cabCompanyId}
                          //filter={{fullName: values.cabPersonName, companyId: ''}}
                        />
                        {(meta.error || meta.submitError) &&
                        meta.touched && (
                          <Text
                            styles={{
                              root: { color: '#a4262c', fontSize: 12 },
                            }}
                          >
                            {meta.error || meta.submitError}
                          </Text>
                        )}
                      </>
                    )}
                  </Field>
                  <Field
                    name="cabPersonName"
                    component={'input'}
                    type={'text'}
                    disabled={true}
                    hidden
                  />
                </div>

                <div className={contentStyles.footer}>
                  <PrimaryButton
                    iconProps={addIconWhite}
                    text={t('save')}
                    disabled={submitting || pristine}
                    style={{ marginTop: 40, marginBottom: 10 }}
                    onClick={() => {
                      form.submit();
                    }}
                  />
                </div>
                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default ContractorModal;

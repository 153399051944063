import * as React from 'react';
import { WBS_TASK_GROUP_BY } from '../../../types/wbs';
import { Divider } from '@fluentui/react-components';
import { formatReadableDate } from '../../../shared/util';
import { formatTreeData } from '../../../reducers/wbsTaskReducer';
import i18n from 'i18next';

interface Props {
  groupList: any,
  renderTreeList: any,
  groupBy: any
}

const WbsTaskGroupListPane = (props: Props) => {
  const { groupList, renderTreeList, groupBy } = props;

  const renderTitle = (item: any) => {
    if (groupBy === WBS_TASK_GROUP_BY.DUE_DATE) {
      return formatReadableDate(item?.groupItem);
    } else {
      return item?.groupItem ?? i18n.t('tagNotAvailable');
    }
  };

  const formatData = (filterList: any) => {
    if (filterList && filterList?.length > 0) {
      return formatTreeData(filterList);
    } else {
      return [];
    }
  };

  return (
    <>
      <div style={{ height: 'auto' }}>
        {groupList && groupList.map((item: any, index: number) => {
          return <>
            <p style={{ height: index === 0 ? 2 : 10 }}></p>
            <Divider alignContent='start'>{renderTitle(item)}</Divider>
            <p style={{ height: 5 }}></p>
            {renderTreeList(formatData(item?.filterList))}
          </>;
        })}
      </div>
    </>
  );

};

export default WbsTaskGroupListPane;

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useId } from '@uifabric/react-hooks';
import { Checkbox, FontWeights, getTheme, ICheckboxStyles, IconButton, IIconProps, IStackItemStyles, IStackStyles, IStackTokens, Label, mergeStyleSets, Modal, PrimaryButton, Stack } from '@fluentui/react';
import { readCIAWSiteCodeById, readCIAWSiteproductCodeById, saveCIAWProductStateAttr, validateCiawSiteCode, validateCiawSiteproductCode } from '../../../../reducers/ciawReducer';
import { CiawSiteCode, CiawSiteproductCode } from '../../../../types/ciaw';
import { v4 as uuidv4 } from "uuid";
import { Field, Form } from 'react-final-form';
import { uPrinceTheme } from '../../../../../theme';
import ProgressBar from '../../../../shared/progressBar/progressBar';
import { TextFieldFF } from '../../../../shared/fluentFinalForm';
import TextEditor from '../../../../shared/textEditor/textEditor';

const theme = getTheme();

const classNames = mergeStyleSets({
  wrapper: {
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: {
    maxWidth: 400,
  },
  textContent: {
    padding: '15px 10px',
  },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: {
    width: '100%',
  },
  editor: {
    width: '100%',
    height: '100%'
  },
});

const checkboxStyles: ICheckboxStyles = {
  root: { marginBottom: 8 },
  label: { fontWeight: 500 },
  checkbox: {
    borderRadius: '50%',
    width: 20,
    height: 20,
  },
  checkmark: {
    borderRadius: '50%',
  },
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '30vw',
    minHeight: '10vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const stackStyles: IStackStyles = {
  root: {
    padding: 0,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const CiawLtpModal = ({show, setShow, formData}: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const titleId = useId('title');
  const store = useStore();

  const { isCiawSiteCodeProductModelOpen, ciawSiteProductCodeData, showProgressBar } = useSelector(
    (state: any) => state.ciaw
);

  const updatedVPPBS = store.getState()?.projectBreakdown?.updatedVPPBS

  useEffect(() => {
    dispatch(readCIAWSiteproductCodeById(store.getState()?.projectBreakdown?.updatedVPPBS?.id));
  }, [store.getState()?.projectBreakdown?.updatedVPPBS?.id]);

  const getInitialFormValues = (formData: any, formValue: any) => {
    if (formData && formData.id) {
      return {
        id: formData.id,
        ciawSiteCode: formData.ciawSiteCode,
        ciawSeverEntry: formData.ciawSeverEntry,
        pbsproductId: formData.pbsproductId,
        isCiawEnabled: formData.isCiawEnabled,
        isSocialSecurityWebsite: formData.isSocialSecurityWebsite,
        isCustomerPortal: formData.isCustomerPortal,
        customerPortalComments: formData.customerPortalComments,
        ciawSiteNotification: formData.ciawSiteNotification
      };
    }

    return formValue;
  };

  const setSiteCodeData = (values: any) => {
    const ciawSiteproductCode = new CiawSiteproductCode();
    ciawSiteproductCode.id = ciawSiteProductCodeData ? ciawSiteProductCodeData.id : uuidv4();
    ciawSiteproductCode.ciawSiteCode = values.ciawSiteCode;
    ciawSiteproductCode.ciawSeverEntry = 'TemporaryHardCodedOnFrontEnd'; // TODO: ciawSeverEntry is auto generated so, keep as it is!
    ciawSiteproductCode.pbsproductId = updatedVPPBS?.id;
    ciawSiteproductCode.isCiawEnabled = values.isCiawEnabled;
    ciawSiteproductCode.isSocialSecurityWebsite = values.isSocialSecurityWebsite;
    ciawSiteproductCode.isCustomerPortal = values.isCustomerPortal;
    ciawSiteproductCode.customerPortalComments = values.customerPortalComments;
    ciawSiteproductCode.ciawSiteNotification = values.ciawSiteNotification

    Promise.all([
      dispatch(validateCiawSiteproductCode(ciawSiteproductCode))
    ]).then((response: any) => {
      if (response[0].payload.data.result) {
        dispatch(saveCIAWProductStateAttr('isCiawSiteCodeProductModelOpen', false));
        dispatch(readCIAWSiteproductCodeById(updatedVPPBS?.id));
        setShow(false)
    }
    });
  };

  const onSubmit = async (values: any) => {
    const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
    await sleep(300);

    setSiteCodeData(values);
  };

  const CustomCheckbox = ({ input, label }: { input: any; label: any }) => (
    <Checkbox
      label={label}
      checked={input.value}
      boxSide="start"
      onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
          input.onChange(isChecked);
      }}
      styles={checkboxStyles}
    />
  );

  const handleSocialSecurityChange = (input: any, isChecked: boolean | undefined,form:any) => {
    form.mutators.setSocialSecurityChecked(isChecked || false);
    if (isChecked) {
      form.mutators.setCustomerPortalChecked(false);
      input.onChange(true);
    } else {
      input.onChange(false);
    }
  };

  const handleCustomerPortalChange = (input: any, isChecked: boolean | undefined,form:any) => {
    form.mutators.setCustomerPortalChecked(isChecked || false);
    if (isChecked) {
      form.mutators.setSocialSecurityChecked(false);
      input.onChange(true);
    } else {
      form.mutators.setCustomerPortalChecked(true);
      input.onChange(false);
    }
  };
  return (
    <div>
      <Form 
        onSubmit={onSubmit}
        initialValues={getInitialFormValues(ciawSiteProductCodeData, new CiawSiteCode())}
        validate={values => {
          const errors: any = {};
          if (!values?.ciawSiteCode && values.isCiawEnabled) {
            errors.ciawSiteCode = t('ciawSiteCodeRequired');
          }
          return errors;
        }}
        mutators={{
          setSocialSecurityChecked: (args, state, utils) => {
            const field = state.fields.isSocialSecurityWebsite;
            field.change(args[0]);
          },
          setCustomerPortalChecked: (args, state, utils) => {
            const field = state.fields.isCustomerPortal;
            field.change(args[0]);
          },
        }}
        render={({
          handleSubmit,
          form,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <div>
                <Modal
                  titleAriaId={titleId}
                  isOpen={show}
                  onDismiss={() => {
                    setShow(false)
                  }}
                  scrollableContentClassName="body"
                  isBlocking={false}
                  dragOptions={undefined}
                >
                  <div className={contentStyles.header}>
                    <span id={titleId}>{ciawSiteProductCodeData ? t('editCiaw') : t('addCiaw')}</span>
                    <IconButton
                      styles={cancelIconButtonStyles}
                      iconProps={cancelIcon}
                      ariaLabel="Close popup modal"
                      onClick={() => {
                        setShow(false)
                      }}
                    />
                  </div>
                  <ProgressBar show={showProgressBar} />
                  <div className={contentStyles.body}>
                    <div className="proj-detail-content inner" style={{ minHeight: '500px' }}>
                      <div className="ms-Grid-row">
                        <Stack
                          horizontal
                          wrap
                          styles={stackStyles}
                          tokens={stackTokens}
                        >
                          <Stack.Item grow={6} styles={stackItemStyles}>
                            <div className={`${classNames.fullWidth}`}>
                            {/* CIAW Site Notification */}
                              <div style={{marginBottom: '12px'}}>
                                <Label>{t('CIAW Site Notification')}</Label>
                                <Field
                                  name="ciawSiteNotification"
                                  component={TextFieldFF}
                                  // required={values.ciawSiteNotification}
                                />
                              </div>

                              {/* isCiawEnabled */}
                              <Field name='isCiawEnabled'>
                                {({ input, meta }) => (
                                  <Checkbox
                                    label={t("enableCIAW")}
                                    checked={values.isCiawEnabled}
                                    boxSide="end"
                                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
                                      if (isChecked) {
                                        input.onChange(true);
                                      } else {
                                        input.onChange(false);
                                      }
                                    }}
                                    styles={{
                                      root: { marginBottom: 8 },
                                      label: { fontWeight: 500 }
                                    }}
                                  />
                                )}
                              </Field>

                              {/* ciawSiteCode */}
                              <Field
                                name="ciawSiteCode"
                                component={TextFieldFF}
                                label={t('CIAW Site Code')}
                                disabled={!values.isCiawEnabled}
                                required={values.isCiawEnabled}
                              />

                              {/* Registration Portal */}
                              <Label style={{ marginTop: '12px' }}>{t('Registration Portal')}</Label>
                              <Field name="isSocialSecurityWebsite">
                                {({ input }) => (
                                  <CustomCheckbox
                                    input={{
                                      ...input,
                                      value: values.isSocialSecurityWebsite,
                                      onChange: (isChecked: boolean | undefined) => handleSocialSecurityChange(input, isChecked,form),
                                    }}
                                    label={t('Social Security Website')}
                                  />
                                )}
                              </Field>

                              {/* isCustomerPortal */}
                              <Field name="isCustomerPortal">
                                {({ input }) => (
                                  <CustomCheckbox
                                    input={{
                                      ...input,
                                      value: values.isCustomerPortal,
                                      onChange: (isChecked: boolean | undefined) => handleCustomerPortalChange(input, isChecked,form),
                                    }}
                                    label={t('Customer Portal')}
                                  />
                                )}
                              </Field>
                              
                              {/* Comments */}
                              <>
                                <Label>{t('Comments')}</Label>
                                <TextEditor
                                  value={values.customerPortalComments || ''}
                                  onChange={(value) => {
                                    if (!values.isDisabled) {
                                      if (value) {
                                        values.customerPortalComments = value;
                                      } else {
                                        values.customerPortalComments = null;
                                      }
                                    }
                                  }}
                                />
                              </>
                            </div>
                          </Stack.Item>
                        </Stack>
                      </div>
                    </div>
                  </div>
                  <div className={contentStyles.footer}>
                    <PrimaryButton
                      text={t('save')}
                      onClick={() => {
                        form.submit();
                      }}
                    />
                  </div>
                </Modal>
              </div>
            </form>
          )
        }}
      />
    </div>
  )
}

export default CiawLtpModal
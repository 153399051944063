import React, { Component } from 'react';
import MeNavBar from '../../shared/uprinceNavigation/meNavigation/component';
import { getContractingUnit, getProject } from '../../shared/util';
import UprinceNavigation from '../../shared/uprinceNavigation/cuProjectNavigation/component';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { v4 as uuidv4 } from 'uuid';
import ThAutomationMainLayout from './thAutomationMainLayout';

interface Props {
    currentUser: any
}

interface State {
    isContractUnit: boolean;
    isProject: boolean;
}
export class ThAutomation extends Component<Props, State> {
    render() {
        return <DndProvider key={uuidv4()} backend={HTML5Backend}>
            {getProject() ?
                <UprinceNavigation
                    currentUser={this.props.currentUser}
                    selectedNavigationIndex={`/CU/${getContractingUnit()}/project/${getProject()}/th-automation`}
                >
                    <ThAutomationMainLayout/>
                </UprinceNavigation> :
                <MeNavBar
                    currentUser={this.props.currentUser}
                    selectedNavigationIndex={'/me/th-automation'}
                >
                    <ThAutomationMainLayout/>
                </MeNavBar>
            }
        </DndProvider>

    }
}

export default (ThAutomation);

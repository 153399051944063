import {
  ActionButton,
  ContextualMenu,
  DetailsListLayoutMode,
  FontWeights,
  getTheme,
  IColumn,
  IconButton,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Modal,
  Selection,
  ShimmeredDetailsList,
  Stack,
  Sticky,
  StickyPositionType,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../theme';
import { ActionButtonState } from '../../types/corporateProductCatalogue';
import { ReadRisk } from '../../types/projectBreakdownStructure';
import { GridTableColumn } from '../tableColumn/tableColumn';
import { useBoolean } from '@uifabric/react-hooks';
import ProgressBar from '../progressBar/progressBar';

const theme = getTheme();

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};

export interface IDataGridContextInterface<T> {
  editItem: T;
  closeModal: () => void;
  openModal?: () => void;
  dataList: any[];
  updateEditItem?: (item: any) => void
}

export const ModalGridContext = React.createContext<IDataGridContextInterface<any>>({
  editItem: null,
  dataList: [],
  closeModal: () => {
  },
  openModal:() => {

  },
  updateEditItem: (item: any) => {
  },
});

const DataGridWithModal = (props: {
  dataList: any[];
  modalTitle: string;
  title: string;
  deleteDataGrid: (selection: any) => void;
  readOnly: boolean;
  columns: any[];
  isLoaded: boolean;
  children: any;
  onDismiss?: any;
  canSelectItem?: any;
  onItemInvoked?: any;
  showProgress?: any;
  keyProp?: string;
  isCompact?: boolean;
  selectionMode?: any;
  getEditItem?: any;
  openModal?: boolean;
  onSelectionChange?:any
}) => {
  const removeIcon: IIconProps = {
    iconName: 'Delete',
    styles: iconButtonStyles,
  };
  const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };
  const editIcon: IIconProps = { iconName: 'Edit', styles: iconButtonStyles };
  const { t } = useTranslation();
  const [isDraggable] = useBoolean(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [editItems, setEditItems] = useState<any[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [selectionState] = React.useState(new Selection());
  const [actionButtonState, setActionButtonState] = useState<{ add: boolean, edit: boolean, remove: boolean }>({
    add: false,
    edit: true,
    remove: true,
  });
  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      minHeight: (props.dataList && props.dataList.length > 1) ? 200 : 110,
      position: 'relative',
      maxHeight: 'inherit',
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
    borderNone: { border: 'none !important'},
    subHeaderStyle: { fontSize: '14px !important' }
  });

  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
    header: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '2px 2px 2px 10px',
        backgroundColor: uPrinceTheme.palette.themePrimary,
        fontSize: 18,
        color: 'white',
      },
    ],
    footer: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '0px 24px 14px 24px',
        textAlign: 'end',
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '10px 24px 0px 24px',
      overflowY: 'hidden',
      minWidth: '40vw',
      minHeight: '20vh',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 10 },
        'p:last-child': { marginBottom: 0 },
      },
    },
    subHeader: {
      flex: '1 1 auto',
      display: 'block',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      fontSize: 14,
      color: uPrinceTheme.palette.themePrimary,
      backgroundColor: '#FFF7F4',
      height: 40,
      marginLeft: 'auto',
      marginRight: '2px',
    },
  });

  const cancelIconButtonStyles = {
    root: {
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
      color: 'white',
    },
    rootHovered: { color: theme.palette.neutralDark },
  };

  const stackStyles: IStackStyles = { root: { padding: 0 } };
  const stackItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 40,
      alignItems: 'flex-start',
    },
  };

  const cancelIcon: IIconProps = { iconName: 'Cancel' };

  const stackEditbarItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 45,
      alignItems: 'flex-end',
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 15,
    padding: 10,
  };

  const _gridSelection = new Selection({
    onSelectionChanged: () => {
      // eslint-disable-next-line no-use-before-define
      setActionButtonState(getMaterialActionButtonState());
      if (props.onSelectionChange) {
        props.onSelectionChange( _gridSelection.getSelection())
      }
    },
    canSelectItem: (item: any, index?: number) => {
      return props.canSelectItem ? props.canSelectItem(item) : true;
    },
    getKey: (item: any, index: any) => {
      return item[props.keyProp!!];
    },
  });

  const getMaterialActionButtonState = (): ActionButtonState => {
    const selectionCount = _gridSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount === 1) {
      setEditItems(_gridSelection.getSelection());
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      setEditItems(_gridSelection.getSelection());
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      setEditItems([]);
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  };

  const _onItemInvoked = (item: any): void => {
    if (props.onItemInvoked) {
      setModalOpen(props.onItemInvoked(item));
    } else {
      setModalOpen(true);
    }
  };

  useEffect(() => {
    if (editItems[0] && props.keyProp) {
      const index = props.dataList.findIndex(n => n[props.keyProp!!] === editItems[0][props.keyProp!!]);
      if (props.dataList.length > index && index !== -1) {
        _gridSelection.setKeySelected(props.dataList[index][props.keyProp!!], true, false);
      }
    }
  }, [props.dataList]);

  useEffect(() => {
    if(props?.getEditItem){
      if(editItems[0]){
        props?.getEditItem(editItems[0]);
      } else{
        props?.getEditItem({});
      }
    }
  }, [editItems])

  useEffect(() => {
    if (props.openModal) {
      setModalOpen(true);
    }
  },[props?.openModal]);

  return (
    <div className="">
      <div className="marginTop">
        <div style={{ padding: 12 }}>
          <div className="ms-Grid-row">
            <div className="sub-header-title">
              <Stack
                horizontal
                horizontalAlign="space-between"
                styles={stackStyles}
                tokens={stackTokens}
              >   {props.title &&
              <Stack.Item styles={stackItemStyles}>
                <Link className={classNames.borderNone} href='#' id='dynamic-attributes-4-1'>
                  <Label className={classNames.subHeaderStyle} style={{ marginTop: 10 }}>{props.title}</Label>
                </Link>                
              </Stack.Item>
              }
                {!props.readOnly && (
                  <Stack.Item styles={stackEditbarItemStyles}>
                    <ActionButton
                      className={classNames.actionButton}
                      disabled={actionButtonState.add}
                      iconProps={addIcon}
                      allowDisabledFocus
                      onClick={() => {
                        setModalOpen(true);
                      }}
                    >
                      <Label className={classNames.actionButton}>{t('add')} </Label>
                    </ActionButton>
                    <ActionButton
                      iconProps={editIcon}
                      disabled={actionButtonState.edit}
                      allowDisabledFocus
                      onClick={() => {
                        setModalOpen(true);
                      }}
                    >
                      <Label className={classNames.actionButton}> {t('edit')}</Label>
                    </ActionButton>
                    <ActionButton
                      iconProps={removeIcon}
                      disabled={actionButtonState.remove}
                      allowDisabledFocus
                      onClick={() => {
                        props.deleteDataGrid(editItems);
                        // setRefresh(true)
                        // // _gridSelection.selectTokeyProp('', true);
                        // _gridSelection.setAllSelected(false);
                        // _gridSelection.selectTokeyProp('', true);
                        // _gridSelection.setAllSelected(false);

                      }}
                    >
                      <Label className={classNames.actionButton}>
                        {' '}
                        {t('remove')}
                      </Label>
                    </ActionButton>
                  </Stack.Item>
                )}
              </Stack>
            </div>
          </div>

          <div className="ms-Grid-row marginTop">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
              <div className={classNames.wrapper}>
                <div className={'scrollablePane'}>
                  <ShimmeredDetailsList
                    compact={props.isCompact}
                    items={props.dataList}
                    columns={props.columns}
                    setKey={Array.isArray(props.dataList) ? props.dataList.length.toString() : 'set'}
                    enableShimmer={props.isLoaded}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionPreservedOnEmptyClick={true}
                    checkboxVisibility={1}
                    selection={_gridSelection}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="Row checkbox"
                    onItemInvoked={_onItemInvoked}
                    selectionMode={props.selectionMode} //none = 0, single = 1, multiple = 2
                    onRenderItemColumn={(
                      item: ReadRisk,
                      index?: number,
                      column?: IColumn,
                    ) => GridTableColumn(item, index, column)}
                    onRenderDetailsHeader={(headerProps, defaultRender) => {
                      if (defaultRender !== undefined) {
                        return (
                          <Sticky
                            stickyPosition={StickyPositionType.Header}
                            isScrollSynced={true}
                            stickyBackgroundColor="transparent"
                          >
                            <div>{defaultRender(headerProps)}</div>
                          </Sticky>
                        );
                      }
                      return <span></span>;
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalGridContext.Provider value={{
        editItem: editItems[0],
        closeModal: () => {
          setModalOpen(false);
        },
        openModal: () => {
          setModalOpen(true);
        },
        dataList: props.dataList,
        updateEditItem: (item: any) => {
          setEditItems([item]);
        },
      }}>
        <Modal
          titleAriaId={''}
          isOpen={isModalOpen}
          onDismiss={() => {
            setModalOpen(false);
            if (props.onDismiss) {
              props.onDismiss();
            }
          }}
          isBlocking={true}
          dragOptions={isDraggable ? dragOptions : undefined}
        >
          <div className={contentStyles.header}>
                        <span>
                            {props.modalTitle}
                        </span>
            <IconButton
              styles={cancelIconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel="Close popup modal"
              onClick={() => {
                setModalOpen(false);
                if (props.onDismiss) {
                  props.onDismiss();
                }
              }}
            />
          </div>
          <div>
            <ProgressBar show={props.showProgress}/>
          </div>
          {
            props.children
          }
        </Modal>
      </ModalGridContext.Provider>
    </div>
  );
};

export default DataGridWithModal;

import React from 'react';
import {
  Dropdown,
  getTheme,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Stack,
  TextField,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../theme';
import ClearableDropdown from '../../../shared/clearableDropdown/clearableDropdown';
import { BillOfResource, BillOfResourceValidationMessage } from '../../../types/billOfResources';
import CustomDatePicker from '../../../shared/customDatePicker/customDatePicker';
import { formatDateTimeToISOString } from '../../../shared/util';

const theme = getTheme();


const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

// Styles definition
const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const classNames = mergeStyleSets({
  fullWidth: {
    width: '100%',
  },
  halfWidth: {
    width: '50%',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  bigScreenTree: {
    marginBottom: 30,
    height: '30vh',
  },
  smallScreenTree: {
    marginBottom: 30,
    height: '56vh',
  },
  textEditor: {
    height: window.innerWidth <= 1280 ? '50vh' : '25vh',
  },
});

const stackFooterBarItemStyles: IStackItemStyles = {
  root: {
    height: 45,
  },
};

const BorHeader = (props: {
  isEdit: boolean;
  dropDownOptions: any;
  billOfResource: BillOfResource;
  saveFormData: () => void;
  handleFormChange: (billOfResource: BillOfResource, save: boolean) => void;
  validationMessages: BillOfResourceValidationMessage;
  isBorReturn: boolean
}) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = React.useState<Date | null>(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = React.useState<Date | null>(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  //const [contractIsRequired, setcontractIsRequired] = useState(false);

  const borTypeOptions = [
    { key: 1, text: t('regular') },
    { key: 2, text: t('return') },
  ];

  return (
    <div className={'document-pane-card'}>
      <div className={'card-header'}>
        <Link href="#" id="bor-detail">
          <Label>1. {t('bORHeader')} </Label>
        </Link>
      </div>

      <div className={'card-body'}>
        <div className="proj-detail-content inner">
          <div className="marginTop">
            {props.isEdit && (
              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('borTitle')}
                      value={
                        props.billOfResource.borTitle
                          ? props.billOfResource.borTitle
                          : ''
                      }
                      disabled={true}
                    />
                  </div>
                </Stack.Item>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <TextField
                      label={t('borItemID')}
                      value={
                        props.billOfResource.itemId
                          ? props.billOfResource.itemId
                          : ''
                      }
                      disabled={true}
                    />
                  </div>
                </Stack.Item>
              </Stack>
            )}
          </div>

          <div className="marginTop">
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    label={t('name')}
                    value={
                      props.billOfResource.name ? props.billOfResource.name : ''
                    }
                    errorMessage={
                      props.validationMessages.nameErrorMsg
                        ? props.validationMessages.nameErrorMsg
                        : ''
                    }
                    required={true}
                    onChange={(event, value) => {
                      if (value) {
                        props.billOfResource.name = value;
                        props.validationMessages.nameErrorMsg = '';
                      } else {
                        props.billOfResource.name = null;
                      }
                      props.handleFormChange(props.billOfResource, true);
                    }}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Dropdown
                    placeholder={t('borType')}
                    label={t('borType')}
                    options={props.dropDownOptions.borType}
                    // errorMessage={
                    //   props.validationMessages.statusErrorMsg
                    //     ? props.validationMessages.statusErrorMsg
                    //     : ''
                    // }
                    // required={true}
                    disabled={true}
                    onChange={(event, value) => {
                      // if (value) {
                      //   props.billOfResource.borStatusId = value.key.toString();
                      // } else {
                      //   props.billOfResource.borStatusId = null;
                      // }
                      // props.validationMessages.statusErrorMsg = '';
                      // props.handleFormChange(props.billOfResource, true);
                    }}
                    selectedKey={
                      props.billOfResource.borTypeId
                        ? props.billOfResource.borTypeId
                        : ''
                    }
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>

          <div className="marginTop">
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.halfWidth}`}>
                  <ClearableDropdown
                    placeholder={t('status')}
                    label={t('status')}
                    options={props.dropDownOptions.borStatus}
                    errorMessage={
                      props.validationMessages.statusErrorMsg
                        ? props.validationMessages.statusErrorMsg
                        : ''
                    }
                    required={true}
                    onChange={(event, value) => {
                      if (value) {
                        props.billOfResource.borStatusId = value.key.toString();
                      } else {
                        props.billOfResource.borStatusId = null;
                      }
                      props.validationMessages.statusErrorMsg = '';
                      props.handleFormChange(props.billOfResource, true);
                    }}
                    selectedKey={
                      props.billOfResource.borStatusId
                        ? props.billOfResource.borStatusId
                        : ''
                    }
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>

          <div className="marginTop">
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <CustomDatePicker
                    setMinDate={null}
                    setMaxDate={(props.billOfResource.endDate) ? new Date(props.billOfResource.endDate) : null}
                    label={t('startDate')}
                    setValue={(props.billOfResource.startDate) ? new Date(props.billOfResource.startDate) : null}
                    getValue={(date: Date) => {
                      setStartDate(date);
                      props.billOfResource.startDate = formatDateTimeToISOString(date);
                      props.handleFormChange(
                        props.billOfResource,
                        true,
                      );
                    }}
                    disabled={false}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <CustomDatePicker
                    setMinDate={(props.billOfResource.startDate) ? new Date(props.billOfResource.startDate) : null}
                    setMaxDate={null}
                    label={t('endDate')}
                    setValue={(props.billOfResource.endDate) ? new Date(props.billOfResource.endDate) : null}
                    getValue={(date: Date) => {
                      setEndDate(date);
                      props.billOfResource.endDate = formatDateTimeToISOString(date);
                      props.handleFormChange(
                        props.billOfResource,
                        true,
                      );
                    }}
                    disabled={false}
                  />
                </div>
              </Stack.Item>
            </Stack>
            ‍
          </div>
        </div>
      </div>

      <div className={'card-footer'}>
        {/*<Separator />*/}
        <Stack styles={stackStyles} tokens={itemAlignmentsStackTokens}>
          <Stack.Item align="end" styles={stackFooterBarItemStyles}>
            {/*<ActionButton*/}
            {/*className={classNames.actionButton}*/}
            {/*iconProps={saveIcon}*/}
            {/*allowDisabledFocus*/}
            {/*onClick={() => {*/}
            {/*props.saveFormData();*/}
            {/*}}*/}
            {/*>*/}
            {/*<Label className={classNames.actionButton}>{t('save')} </Label>*/}
            {/*</ActionButton>*/}
          </Stack.Item>
        </Stack>
      </div>
    </div>
  );
};

export default BorHeader;

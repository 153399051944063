import * as React from 'react';
import {
  makeStyles,
  shorthands,
  useId,
  Input as FInput,
  Label,
  Dropdown,
  Option,
  Text,
  InputProps, Field,
} from '@fluentui/react-components';
import { Icon } from "@fluentui/react";
import { useEffect, useRef } from 'react';
const useStyles = makeStyles({
  root: {
    // Stack the label above the field
    display: "flex",
    flexDirection: "column",
    // Use 2px gap below the label (per the design system)
    ...shorthands.gap("2px"),
    // Prevent the example from taking the full width of the page (optional)
    //maxWidth: "400px",
    ...shorthands.margin("12px", 0, 0, 0),
  },
});
const Input = ({ onChange, placeholder,value,disabled,errorMessage,label,required, ...rest }: any) => {
//const Input = ({onChange,value?, placeholder?,icon?, disabled?, ...rest}:any) => {
  const styles = useStyles();
  const inputRef: any = useRef(null);
  const handelOnChange: InputProps["onChange"] = (ev, data) => {
    // setValue(data.value);
    onChange(data?.value)
  };

  useEffect(() => {
      if(rest?.focus){
        inputRef?.current?.focus();
      }
    }, [rest?.focus]);

 return (
  <>
    <div className={styles.root}>
      <Field
        // required={props?.required}
        style={{ width: "100%" }}
        // label={props?.label}
        validationMessage={errorMessage}
      >
        <Label required={required}>{label}</Label>
      <FInput
        {...rest}
        ref={inputRef}
        placeholder={placeholder}
        onChange={handelOnChange}
        disabled={disabled}
        value={value?value:""}
      />
      </Field>
    </div>
  </>
 );

};

export default Input

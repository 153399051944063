import React from 'react';
import {
  ActionButton,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Separator,
  Stack,
  TextField,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../theme';
import { useSelector } from 'react-redux';
import { BillOfResource, BillOfResourceValidationMessage, Product } from '../../../types/billOfResources';
import CustomDropdown from '../../../shared/customDropdown/customDropdown';
import TaxonomyField from './taxonomy';

const theme = getTheme();

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const itemAlignmentsStackStyles: IStackStyles = { root: { height: 100 } };

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

// Styles definition
const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const stackItemStylesColThree: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
  },
};

const classNames = mergeStyleSets({
  fullWidth: { width: '100%' },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  bigScreenTree: {
    marginBottom: 30,
    height: '30vh',
  },
  smallScreenTree: {
    marginBottom: 30,
    height: '56vh',
  },
  textEditor: { height: window.innerWidth <= 1280 ? '50vh' : '25vh' },
});

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };


const ProductHeader = (props: {
  isEdit: boolean,
  dropDownOptions: any,
  billOfResource: BillOfResource;
  saveFormData: () => void;
  getProductDetails: () => void;
  handleFormChange: (
    billOfResource: BillOfResource,
    save: boolean,
  ) => void;
  validationMessages: BillOfResourceValidationMessage;
  productOptions: any;
}) => {
  const { t } = useTranslation();
  const pbsForBor = useSelector((state: any) => state.vp.slectedPBSForBor);

  // useEffect(() => {
  //     if (pbsForBor && props.billOfResource) {
  //         console.log("1111111111")
  //         // const product = new Product();
  //         // product.productId = pbsForBor;
  //         // product.title = pbsForBor;
  //         // BorFormData.product = product;
  //         props.handleFormChange(
  //             {
  //                 ...BorFormData, product: {
  //                     ...BorFormData.product,
  //                     productId: pbsForBor,
  //                     title: pbsForBor
  //                 }
  //             },
  //             true
  //         );
  //         props.getProductDetails();
  //     }
  // }, [ pbsForBor ]);

  return (
    <div className={'document-pane-card'}>

      <div className={'card-header'}>
        <Link href="#" id="bor-product">
          <Label>2. {t('product')} </Label>
        </Link>
      </div>

      <div className={'card-body'}>
        <div className="proj-detail-content inner">

          <div className="marginTop">
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  {props.isEdit ? <div>
                    <TextField
                      label={t('productTitle')}
                      disabled={true}
                      value={
                        props.billOfResource.product
                          ? (props.billOfResource.product.title) ? props.billOfResource.product.title : ''
                          : ''
                      }
                    />
                  </div> : <div>
                    <Label required={true}>{t('productTitle')}</Label>
                    <CustomDropdown
                      reference={''}
                      // onChange={()=> props.handleVendorChange(props.vendor)}
                      onChange={(key: string, text: string) => {
                        if (key) {
                          if (props.billOfResource.product) {
                            props.billOfResource.product.title = text;
                            props.billOfResource.product.productId = key;
                          } else {
                            const product = new Product();
                            product.productId = key;
                            product.title = text;
                            props.billOfResource.product = product;
                          }
                          props.validationMessages.productTitleErrorMsg = '';
                        } else {
                          props.billOfResource.product = {
                            id: null,
                            productId: null,
                            title: null,
                            utilityParentId: null,
                            locationParentId: null,
                            locationTaxonomyParentId: null,
                            utilityTaxonomyParentId: null,
                            pbsProductItemType: null,
                            pbsProductItemTypeId: null,
                          };
                        }
                        props.handleFormChange(
                          props.billOfResource,
                          true,
                        );
                        props.getProductDetails();
                      }}
                      selectedOption={(props.billOfResource.product && props.billOfResource.product.productId) ? {
                        value: props.billOfResource.product.productId,
                        label: props.billOfResource.product.title,
                      } : (pbsForBor ? {
                        value: pbsForBor,
                        label: pbsForBor,
                      } : null)}
                      promiseOptions={props.productOptions}
                      validationMessage={props.validationMessages.productTitleErrorMsg}
                      required={true}
                    />
                  </div>}

                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <TextField
                    label={t('productItemType')}
                    disabled={true}
                    value={
                      props.billOfResource.product
                        ? (props.billOfResource.product.pbsProductItemType) ? props.billOfResource.product.pbsProductItemType : ''
                        : ''
                    }
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>

          <div className="marginTop">
            <TaxonomyField
              label={t('utilityTaxonomy')}
              treeData={props.dropDownOptions.utilityTaxonomy}
              selectItemId={props.billOfResource.product
                ? props.billOfResource.product.utilityTaxonomyParentId
                : ''}
            />
          </div>

          <div className="marginTop">
            <TaxonomyField
              label={t('locationTaxonomy')}
              treeData={props.dropDownOptions.locationTaxonomy}
              selectItemId={props.billOfResource.product
                ? props.billOfResource.product.locationTaxonomyParentId
                : ''}
            />
          </div>


        </div>
      </div>

      <div className={'card-footer'}>
        <Separator/>
        <Stack styles={stackStyles} tokens={itemAlignmentsStackTokens}>
          <Stack.Item align="end" styles={stackFooterBarItemStyles}>
            <ActionButton
              className={classNames.actionButton}
              iconProps={saveIcon}
              allowDisabledFocus
              onClick={() => {
                props.saveFormData();
              }}
            >
              <Label className={classNames.actionButton}>{t('save')} </Label>
            </ActionButton>
          </Stack.Item>
        </Stack>
      </div>

    </div>
  );
};


export default ProductHeader;

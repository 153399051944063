import { SAVE_WBS_CU_TASK_STATE_ATTR } from '../actionTypes/wbsCuTaskActionTypes';

const defaultState: any = {

};

export default function wbsCuTaskReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {

      case SAVE_WBS_CU_TASK_STATE_ATTR:
        const attr = Object.keys(action)[1];
        const value = Object.values(action)[1];
        return {
          ...state,
          [attr]: value,
        };

      default:
        return state;
    }
  }
}

export function saveWbsCuTaskStateAttr(attr: string, value: any) {
  return {
    type: SAVE_WBS_CU_TASK_STATE_ATTR,
    [attr]: value,
  };
}
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  DetailsListLayoutMode,
  DetailsRow,
  Dropdown,
  Fabric,
  IColumn,
  IDetailsFooterProps,
  IDetailsListProps,
  IDetailsRowStyles,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
  Text,
  TextField,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { FilterItem, ListPaneItem, ProjectBreakdown } from '../../../types/projectBreakdownStructure';
import SortableTreeListView from '../../../shared/sortableTreeListView/sortableTreeListView';
import { messageService } from '../../../services/messageService';
import { isCBC } from '../../../shared/util';
import groupArray from 'group-array';
import CustomAlert from '../../../shared/alert/customAlert';
import { savePbsStateAttr } from '../../../reducers/projectBreakdownReducer';
import PeoplePicker from '../../../shared/fluentFinalForm/PeoplePicker';
import _ from 'lodash';

export const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 280px)!important',
    position: 'relative',
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: {
    margin: 0,
  },
  row: {
    display: 'inline-block',
  },
});

const ListPaneComponent = (props: {
  filter: FilterItem;
  selection: any;
  columns: IColumn[];
  listPaneItem: ListPaneItem[];
  resourceTypes: [];
  handleListPaneItemClick: (id: string) => void;
  handleFilterChange: (filter: FilterItem) => void;
  isDataLoaded?: boolean;
  loadMsg: string;
  resetSelection: () => void;
  formData: ProjectBreakdown;
  dropDownOptions: any;
  cbcExcelUploaded: boolean;
}) => {
  const dispatch = useDispatch();
  const [qualityProducer, setQualityProducer] = useState<any[]>([]);

  // useEffect(() => {
  //     props.resetSelection();
  // }, [props.formData && props.isDataLoaded]);
  const _onRenderRow: IDetailsListProps['onRenderRow'] = (row) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (row) {
      return (
        <span
          style={{ textDecoration: 'none' }}
          onClick={() => {
            props.handleListPaneItemClick(row.item.productId);
          }}
        >
          <DetailsRow {...row} styles={customStyles}/>
        </span>
      );
    }
    return null;
  };

  const _onRenderDetailsFooter = (
    detailsFooterProps: IDetailsFooterProps | undefined,
  ) => {
    return (
      <DetailsRow
        className="footer-as-filter"
        {...detailsFooterProps}
        columns={detailsFooterProps?.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps?.groupNestingDepth}
        selection={detailsFooterProps?.selection}
        onRenderItemColumn={_renderFilterItemColumn}
        // onRenderCheck={_onRenderCheckForFooterRow}
      />
    );
  };

  const _renderFilterItemColumn = (
    item?: ListPaneItem,
    index?: number,
    column?: IColumn,
  ) => {
    switch (column?.key) {
      case 'column1': {
        return (
          <div>
            <TextField
              value={props.filter.title ? props.filter.title : ''}
              onChange={(event, value) => {
                if (value) {
                  props.filter.title = value;
                } else {
                  props.filter.title = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column2': {
        return (
          <div>
            <Dropdown
              placeholder={t('type')}
              options={props.dropDownOptions.itemTypesFilter}
              selectedKey={
                props.filter.pbsProductItemTypeId
                  ? props.filter.pbsProductItemTypeId
                  : '0'
              }
              onChange={(event, value) => {
                if (value) {
                  props.filter.pbsProductItemTypeId =
                    value.key != 0 ? value.key.toString() : null;
                } else {
                  props.filter.pbsProductItemTypeId = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column3': {
        return (
          <div>
            <Dropdown
              disabled
              placeholder={t('company')}
              options={props.dropDownOptions.company}
              selectedKey={
                props.filter.qualityProducerCompany
                  ? props.filter.qualityProducerCompany
                  : '0'
              }
              onChange={(event, value) => {
                if (value) {
                  props.filter.qualityProducerCompany =
                    value.key != 0 ? value.key.toString() : null;
                } else {
                  props.filter.qualityProducerCompany = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column4': {
        return (
          <div>
            <PeoplePicker
              label={null}
              isDisabled={false}
              selectedItem={qualityProducer}
              onChange={(items: any) => {
                if (items && _.isArray(items) && items.length === 0) {
                  setQualityProducer([]);
                  props.filter.qualityProducerId = null;
                  props.handleFilterChange(props.filter);
                } else {
                  setQualityProducer([]);
                  props.filter.qualityProducerId = null;
                  props.handleFilterChange(props.filter);
                }
              }}
              onPersonSelected={(items: any) => {
                if (items) {
                  props.filter.qualityProducerId = items.cabPersonId;
                  setQualityProducer([{ key: items.key, text: items.text }]);
                } else {
                  props.filter.qualityProducerId = null;
                  setQualityProducer([]);
                }
                props.handleFilterChange(props.filter);
                return items;
              }}
            />
          </div>
        );
        break;
      }
      case 'column5': {
        return (
          <div>
            <Dropdown
              placeholder={t('projectStatus')}
              options={props.dropDownOptions.productStatesFilter}
              selectedKey={
                props.filter.pbsProductStatusId
                  ? props.filter.pbsProductStatusId
                  : '0'
              }
              onChange={(event, value) => {
                if (value) {
                  props.filter.pbsProductStatusId =
                    value.key != 0 ? value.key.toString() : null;
                } else {
                  props.filter.pbsProductStatusId = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column6': {
        return (
          <div>
            <Dropdown
              placeholder={t('status')}
              options={props.dropDownOptions.toleranceStatesFilter}
              selectedKey={
                props.filter.pbsToleranceStateId
                  ? props.filter.pbsToleranceStateId
                  : '0'
              }
              onChange={(event, value) => {
                if (value) {
                  props.filter.pbsToleranceStateId =
                    value.key != 0 ? value.key.toString() : null;
                } else {
                  props.filter.pbsToleranceStateId = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      default: {
        break;
      }
    }
  };
  const { t } = useTranslation();
  const [filter, setFilter] = useState<any>(props.filter);
  const [listPaneItems, setListPaneItems]: any = useState<any[]>(
    props.listPaneItem,
  );

  const displayMessage = () => {
    return (
      <div style={{ paddingTop: 36, paddingLeft: 20 }}>
        <Text>{t(props.loadMsg)}</Text>
      </div>
    );
  };

  const customNodeRender = (node: any) => {
    return (
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          handleTitleClick(node);
        }}
        id={node.id}
        key={node.id}
        className={'lastNode'}
      >
        <table key={node.id + 'table'} style={{ width: '100%' }}>
          {' '}
          <tr>
            <td>{node.organization?.title}</td>
          </tr>
        </table>
      </div>
    );
  };

  const removeParentIdFromSelectedLevel = async () => {
    const list = props.listPaneItem.map((item: any) => {
      return {
        ...item,
        parentId:
          item.parentId == '0'

            ? null
            : item.parentId,
      };
    });
    return list;
  };

  useEffect(() => {
    if (isCBC()) {
      removeParentIdFromSelectedLevel().then((list: any) => {
        setListPaneItems(list);
      });
    }
  }, [props.listPaneItem]);

  const handleTitleClick = (node: any) => {
    messageService.sendMessage({ selectedOrgSetting: node });
    props.handleListPaneItemClick(node?.id);
  };


  const getGroups = () => {
    let groups,
      groups2: {
        count: any;
        key: any;
        name: any;
        startIndex: number;
        level: number;
        children: never[];
      }[] = [];
    if (props.listPaneItem) {
      let gp: any = groupArray(props.listPaneItem, 'id');
      // let gp2: any = groupArray(this.getNoCabCompaniesGrops(), 'name');
      let companyNames = Object.keys(gp).sort(function(a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });

      // let companyNames2 = Object.keys(gp2).sort(function (a, b) {
      //   return a.toLowerCase().localeCompare(b.toLowerCase());
      // });

      let x = 0;
      groups = companyNames.map((item: any, index: number) => {
        if (index != 0) {
          x = x + gp[companyNames[index - 1]].length;
        }
        return {
          count: gp[item].length,
          key: item + index,
          name: item,
          startIndex: x,
          level: 0,
          children: [],
        };
      });
      // let isOrgNameAvailable = this.state.cabFilter && this.state.cabFilter.organisation && this.state.cabFilter.organisation.length > 0
      // if (this.checkProperties(this.state.cabFilter) || isOrgNameAvailable) {
      //   let y = 0;
      //   groups2 = companyNames2.map((item: any, index: number) => {
      //     y = x;
      //     this.props.addressBook.push(
      //       new AddressBookItem(new Person(), null, new PersonCompany())
      //     );
      //     let c = {
      //       count: 1,
      //       key: item + index,
      //       name: item,
      //       startIndex: y + 1,
      //       level: 0,
      //       children: [],
      //     };

      //     groups.push(c);
      //     return c;
      //   });
      // }
    }
    return groups;
  };

  return (
    <Fabric>
      <div className={`org wrapper-holder ${classNames.wrapper} cbcTaxonomy`}>
        {isCBC() && !props.loadMsg && (
          <div style={{ marginTop: 10 }}>
            <div id={'topDiv'} style={{ position: 'absolute', width: '100%', zIndex: 100000 }}>
              <CustomAlert
                showMsg={props.cbcExcelUploaded}
                msg={t('excelUploadSuccess')}
                msgType={'success'}
                handleCustomAlert={() => {
                  dispatch(savePbsStateAttr('cbcExcelUploaded', false));
                }}
              />
              {/* <CustomAlert
                showMsg={(!props.cbcExcelUploaded) && props.isDataLoaded ? true : false}
                msg={t("excelUploadFailed")}
                msgType={'failed'}
                handleCustomAlert={() => {
                  dispatch(savePbsStateAttr('cbcExcelUploaded', false));
                }}
              /> */}
            </div>
            <SortableTreeListView
              treeData={listPaneItems}
              customNodeLevel={7}
              customNodeRender={(node: any) => customNodeRender(node)}
              handleTitleClick={(node: any) => {
                handleTitleClick(node);
              }}
            />
          </div>
        )}
        {isCBC() && props.isDataLoaded && displayMessage()}
        {!isCBC() && (
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
            <ShimmeredDetailsList
              items={props.listPaneItem}
              columns={props.columns}
              setKey="set"
              enableShimmer={!props.isDataLoaded}
              layoutMode={DetailsListLayoutMode.justified}
              selectionPreservedOnEmptyClick={true}
              checkboxVisibility={1}
              onRenderRow={_onRenderRow}
              onRenderDetailsFooter={_onRenderDetailsFooter}
              selection={props.selection}
              selectionMode={SelectionMode.single}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
              onRenderDetailsHeader={(headerProps, defaultRender) => {
                if (defaultRender !== undefined) {
                  return (
                    <Sticky
                      stickyPosition={StickyPositionType.Header}
                      isScrollSynced={true}
                      stickyBackgroundColor="transparent"
                    >
                      <div>{defaultRender(headerProps)}</div>
                    </Sticky>
                  );
                } else {
                  return <span></span>;
                }
              }}
            />

            {props.isDataLoaded && displayMessage()}
          </ScrollablePane>
        )}
      </div>
    </Fabric>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    toleranceStatesFilter: state.projectBreakdown.toleranceStatesFilter,
    productStatesFilter: state.projectBreakdown.productStatesFilter,
    itemTypesFilter: state.projectBreakdown.itemTypesFilter,
    cbcExcelUploaded: state.projectBreakdown.cbcExcelUploaded,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ListPaneComponent);

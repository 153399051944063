import React, { Component } from 'react';
import { getContractingUnit } from '../../shared/util';
import UprinceNavigation from '../../shared/uprinceNavigation/cuProjectNavigation/component';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { v4 as uuidv4 } from 'uuid';
import WbsCreateMainLayout from './wbsCreateMainLayout';
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';

interface Props {
    currentUser: any
}

interface State {
    isContractUnit: boolean;
    isProject: boolean;
}

const dndId = uuidv4();

export class WbsCreate extends Component<Props, State> {
    render() {
        return <DndProvider key={dndId} backend={HTML5Backend}>
            <UprinceNavigation
                currentUser={this.props.currentUser}
                selectedNavigationIndex={`/CU/${getContractingUnit()}/wbs-create`}
            >
                <FluentProvider theme={teamsLightTheme}>
                    <WbsCreateMainLayout/>
                </FluentProvider>
            </UprinceNavigation>
        </DndProvider>

    }
}

export default (WbsCreate);

import {IBasePickerSuggestionsProps, IPersonaProps, Label, NormalPeoplePicker} from '@fluentui/react';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import client from '../../api';
import {AddressBookItem, CABFilter} from '../../types/addressBookItem';
import {CAB_FILTER_EP} from '../endpoints';
import ENV from '../../../env.json';
import ValidationLabel from "../ValidationLabel/component";

const PeoplePicker: React.FC<any> = (props: {
    label: string,
    name: string,
    isDisabled: boolean,
    selectedItem: { key: string, text: string }[],
    onPersonSelected: any,
    onChange: any,
    endpoint?: string,
    companyId?: any,
    lotId?: any,
    isCheckCompany?:boolean
    parentId?: string,
    comId?: string,
    buId?: string,
    teamId?: string,
    placeHolder?: string,
    required?: boolean,
    validationMessage?: string,
}) => {
    const [state, setstate] = useState<{ key: string, text: string }[]>([]);
    const [isCleared, setIsClear] = useState<boolean>(false);
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const limitedSearchAdditionalProps: IBasePickerSuggestionsProps = {
        resultsMaximumNumber: 50,
        searchingText: 'Searching...'
    };
    const formatPersonResponse = (response: any) => {
        if (response && response.data) {
            if (response.data.result && _.isArray(response.data.result)) {
                const data = response.data.result.map((item: AddressBookItem) => {
                    return {
                        cabPersonId: item?.person?.id,
                        key: item.personCompany?.id,
                        companyId: item.companyId,
                        text: item.person?.fullName,
                        Organisation: item.company?.name,
                        email: item.personCompany?.email,
                        mobile: item.personCompany?.mobilePhone,
                        jobTitle: item.personCompany?.jobTitle,
                        secondaryText: item.personCompany ? item.personCompany?.jobRole : ''
                    };
                });
                return data;
            }
            return [];
        }
        return [];
    };
    const formatPersonCompanyResponse = (response: any) => {
        if (response && response.data) {
            if (response.data.result && _.isArray(response.data.result)) {
                const filterList = response.data.result.filter(
                    (item:any) => item && item.person && item.person.fullName && item.company && item.company.name && item.personCompany && item.personCompany.id
                );
                if(filterList && filterList.length>0){
                    const data = filterList.map((item: AddressBookItem) => {
                         return {
                                cabPersonId: item?.person?.id,
                                key: item.personCompany.id,
                                companyId: item.companyId,
                                text: item.person.fullName,
                                Organisation: item.company.name,
                                email: item.personCompany.email,
                                mobile: item.personCompany.mobilePhone,
                                jobTitle: item.personCompany.jobTitle,
                                secondaryText: item.personCompany ? item.personCompany.jobRole : ''
                            };
                    });
                    return data;
                }else{
                    return []
                }

            }
            return [];
        }
        return [];
    };

    const formatCompanyResponse = (response: any) => {
        if (response && response.data) {
            if (response.data.result && _.isArray(response.data.result)) {
                const data = response.data.result.map((item: any) => {
                    return {
                        key: item.cabPersonId,
                        text: item.cabPersonName,
                        Organisation: item.company.name,
                        companyId: item.companyId,
                        jobTitle: item.personCompany.jobTitle
                    };
                });
                return data;
            }
            return [];
        }
        return [];
    };

    useEffect(() => {
        if ((_.isArray(props.selectedItem) && !_.isEmpty(props.selectedItem))) {
            setIsClear(false);
        }
        if (_.isArray(props.selectedItem) && !_.isEmpty(props.selectedItem) && !isCleared) {
            if (!_.isEmpty(state) && state[0]?.text !== props.selectedItem[0]?.text) {
                // setstate(props.selectedItem);
                if (!isSelected) {   //upon removing this logic, bug is fixing.
                    setstate(props.selectedItem);
                } else if (isSelected && !isCleared) {
                    setstate(props.selectedItem);
                }
            } else {
                setstate(props.selectedItem);
            }
        } else {
            setstate([]);
        }
    }, [props.selectedItem]);

    const getPersonByName = async (name: string, type?: string) => {
        const cabFilter = new CABFilter();
        cabFilter.fullName = name;
        cabFilter.companyId = props.companyId;
        cabFilter.lotId = props.lotId;
        cabFilter.parentId = props.parentId;
        cabFilter.comId = props.comId;
        cabFilter.buId = props.buId;
        cabFilter.teamId = props.teamId;
        if (name.length >= 2) {
            if (props.endpoint) {
                const response = await client.post(props.endpoint, cabFilter);
                if (props.companyId || props.lotId) {
                    return formatCompanyResponse(response);
                } else if(props?.isCheckCompany){
                    return formatPersonCompanyResponse(response);
                } else if (props.comId || props.buId) {
                    return formatPersonResponse(response);
                } else {
                    return formatPersonResponse(response);
                }
            } else {
                const response = await client.post(CAB_FILTER_EP, cabFilter);
                return formatPersonResponse(response);
            }
        }
    };

    const onFilterPersonChanged = (
        filterText: string, selectedItems?: IPersonaProps[] | undefined
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
        if (filterText) {
            return getPersonByName(filterText);
        }
        return [];
    };

    return (
        <div>
            {props.label &&
                <Label disabled={false} required={props.required}>{props.label}</Label>
            }
            <NormalPeoplePicker
                onChange={(items) => {
                    if (items && _.isArray(items) && items.length === 0) {
                        setstate([]);
                        setIsClear(true);
                        setIsSelected(false);
                        props.onChange(null);
                    }
                    return items;
                }}
                selectedItems={state}
                onResolveSuggestions={(text): any => {
                    return onFilterPersonChanged(text);
                }}
                onItemSelected={(item: any): any => {
                    props.onPersonSelected(item);
                    const person = { key: item?.key, text: item?.text };
                    setstate([person]);
                    setIsClear(false);
                    setIsSelected(true);
                    return item;
                }}
                pickerSuggestionsProps={limitedSearchAdditionalProps}
                className={'ms-PeoplePicker'}
                key={'CustomerContact'}
                itemLimit={1}
                removeButtonAriaLabel={'Remove'}
                resolveDelay={300}
                disabled={props.isDisabled}
                styles={{root: {persona:{primaryText:{color: `${ENV.PRIMARY_COLOR}`}}}}}
                inputProps={
                    { placeholder: `${props.placeHolder ?? ""}` }
                }
            />
            {props.validationMessage && <ValidationLabel validationMessage={props.validationMessage}/>}
        </div>
    );
};

export default PeoplePicker;

import { Checkbox, FontWeights, getTheme, IconButton, IIconProps, Image, ITheme, Label, mergeStyleSets, Modal, TooltipHost } from '@fluentui/react'
import React, { useState } from 'react'
import { uPrinceTheme } from '../../../../../theme';
import { deleteImagePayload, PMOL_PBS_HEADER_SECTION_LIST } from '../../../../shared/util';
import client from '../../../../api';
import PreviewProperties from './PreviewProperties';
import { useTranslation } from 'react-i18next';
import PreviewComponent from '../../../../shared/fileUploader/CustomUploader/PreviewComponent';

const checkboxStyles = {
  checkbox: {
    backgroundColor: '#92E950',
    borderColor: '#92E950',
    color: '#92E950',
  },
  checkmark: {
    color: '#ffffff',
  },
  root: {
    // Additional styles if needed
  },
};

const theme: ITheme = getTheme();
const { palette, fonts } = theme;

const renameButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const classNames = mergeStyleSets({
  renameContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: '25%'
  },
  imagePreviewContainer: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: '100%',
    // height: '40%',
  },
  renameHeader: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    backgroundColor: uPrinceTheme.palette.themePrimary,
    fontSize: 18,
    color: 'white',
  },
  renameFooter: {
    flex: '1 1 auto',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '0px 24px 14px 24px',
    textAlign: 'end',
  },
  fileNameStyle: {
    color: '#FFFFFF',
    fontSize: fonts.small.fontSize,
    boxSizing: 'border-box',
  }
})

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: { color: theme.palette.neutralDark },
};

// const renameIcon: IIconProps = { iconName: 'Edit' };
const deleteIcon: IIconProps = { iconName: 'Delete' };
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const infoIcon: IIconProps = {iconName: 'Info'}

const PreviewImages = (props: {
  uploadedImages: any,
  selectedImages: any,
  selectedImageId: any,
  setSelectedImageId: any;
  setSelectedImages: any;
  selectedImageForPreview?: any,
  setSelectedImageForPreview?: any
  deleteImage: any;
  pmolId: any;
  renameSuccess: any;
  project: any;
  pmolDetails: {
    project: string | undefined;
    product: string | undefined,
    pmol: string | undefined;
    foreman: string | undefined;
    location: {
      freeFormAddress: string | undefined;
      lat: string | undefined;
      lon: string | undefined;
      id: any
    }
  },
  sectionHeading: any
}) => {
  const {t} = useTranslation();
  const [showPreview, setShowPreview] = useState(false);
  const [rotation, setRotation] = useState<number>(0); // New state for rotation
  const [isOpenImgRenameModal, setIsOpenImgRenameModal] = useState(false);
  const [selectedImageDetailsForRename, setSelectedImageDetailsForRename] = useState<any>(null);

  const handleCheckedImages = (event: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked: boolean | undefined, item: any) => {
    if (checked) {
      props.setSelectedImageId((prev: any) => [...prev, item.id]);
      props.setSelectedImages((prev: any) => [...prev, item])
    } else {
      props.setSelectedImageId((prev: any) => prev.filter((id: any) => id !== item.id));
      props.setSelectedImages((prev: any) => prev.filter((prevs: any) => prevs.id !== item.id))
    }
  };

  const getSectionType = () => {
    switch(props.sectionHeading) {
      case PMOL_PBS_HEADER_SECTION_LIST.EXTRAWORK:
        return "extraWork";
      case PMOL_PBS_HEADER_SECTION_LIST.JOURNALWORK:
        return "journal";
      case PMOL_PBS_HEADER_SECTION_LIST.HANDSHAKE:
        return "handShake";
      case PMOL_PBS_HEADER_SECTION_LIST.PRODUCT:
        return PMOL_PBS_HEADER_SECTION_LIST.PRODUCT;
      default:
        return null;
    }
  }

  const deleteImage = (id: string) => {
    let idList = [];
    idList.push(id);

    let payload : deleteImagePayload = {
      imageId: idList,
      type: getSectionType(),
      pmolId: props?.pmolId ? props?.pmolId : null,
      pbsId: null,
    }

    if(payload.imageId && payload.type){
      client.delete('Pmol/RemoveImage', { data: payload}).then(
        (response: any) => {
          if(props.deleteImage){
            props.deleteImage(id, payload?.type ? payload?.type : "");
          }
        },
        (error: any) => {
          alert("Something went wrong when deleting a image");
        },
      );
    }
  }

  const getFileName = (item: string) => {
    let decoded = decodeURIComponent(item);
    let fileName = decoded.substring(decoded.indexOf('M') + 1);

    return fileName;
  };

  const getShortFileName = (item: string) => {
    let shortFileName = ((getFileName(item)).substring(0, 12)) + '...';

    return shortFileName;
  };

  return (
    <div style={{width: '100%', margin: '8px 0 24px 0'}}>
      <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))', gap: '8px'}}>
        {props.uploadedImages?.map((each: any, index: number) => {
          return (
            <>
              <div key={index} style={{width: '150px', height: '200px', position: 'relative'}}>
                <div style={{position: 'absolute', zIndex: 1, right: 0, margin: '2px 0 2px 2px', width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                  <Label style={{background: each?.phase ? uPrinceTheme?.palette?.black : 'transparent', color: '#fff', padding: '2px 6px'}}>{each?.phase ? t(`${each.phase}`) : ""}</Label>

                  {each?.id && 
                    <Checkbox 
                      checked={each.isApproved ? true : props.selectedImageId?.includes(each?.id)} 
                      onChange={(event, checked) => {
                        if (!each.isApproved) {
                          handleCheckedImages(event, checked, each)
                        }
                      }}
                      styles={each?.isApproved ? checkboxStyles : undefined}
                    />
                  }
                </div>
                <Image
                  src={each.image}
                  width={'100%'}
                  height={'100%'}
                  onClick={() => {
                    setShowPreview(true);
                    props.setSelectedImageForPreview(index)
                    setRotation(0);
                  }}
                />
                <div style={{position: 'absolute', bottom: 0, width: '100%', background: '#00000050'}}>
                  {each?.id &&
                    <div style={{display: 'flex', alignItems: 'center', margin: 0, justifyContent: 'space-between', width: '100%'}}>
                      <IconButton
                        iconProps={deleteIcon}
                        styles={renameButtonStyles}
                        allowDisabledFocus
                        onClick={() => {
                          if(each?.id){
                            deleteImage(each.id);
                          }}
                        }
                      />
                      <TooltipHost content={getFileName(each?.image!!)} id={'onRenderCell'}>
                        <span className={classNames.fileNameStyle} >{getShortFileName(each?.image!!)}</span>
                      </TooltipHost>
                      <IconButton
                        iconProps={infoIcon}
                        styles={renameButtonStyles}
                        allowDisabledFocus
                        onClick={() => {
                          setIsOpenImgRenameModal(true);
                          setSelectedImageDetailsForRename((prev: any) => {
                            return {
                              selectedImageId: each.id,
                              selectedImageBlobUrl: each.image,
                              renameValue: getFileName(each?.image!!).split('.')[0],
                              renameValueExtention: getFileName(each?.image!!).split('.')[1],
                              ...each
                            }
                          })
                        }}
                      />
                    </div>
                  }
                </div>
              </div>
            </>
          )
        })}
      </div>

      {/* Modal for image preview */}
      <Modal
        isOpen={showPreview}
        onDismiss={() => 
          setShowPreview(false)
        }
        isBlocking={false}
        containerClassName={classNames.imagePreviewContainer}
      >
        <PreviewComponent 
          uploadedImages={props.uploadedImages} 
          selectedImageForPreview={props.selectedImageForPreview} 
          setSelectedImageForPreview={props.setSelectedImageForPreview}
          rotation={rotation}
          setRotation={setRotation}
        />
      </Modal>

      <Modal
        isOpen={isOpenImgRenameModal}
        onDismiss={() => 
          setIsOpenImgRenameModal(false)
        }
        isBlocking={true}
        containerClassName={classNames.renameContainer}      
      >
        <div className={classNames.renameHeader}>
          <span style={{padding:'8px'}}>{t('properties')}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel='Close popup modal'
            onClick={() => setIsOpenImgRenameModal(false)}
          />
        </div>
        <PreviewProperties 
          data={props.uploadedImages}
          sectionheading={props.sectionHeading}
          selectedImageDetailsForUpdate={selectedImageDetailsForRename}
          renameValueExtention={selectedImageDetailsForRename?.renameValueExtention}
          setIsOpenImgRenameModal={setIsOpenImgRenameModal}
          renameSuccess={props.renameSuccess}
          pmolDetails={props.pmolDetails}
        />
      </Modal>
    </div>
  )
}

export default PreviewImages
import React from 'react';
import {FontWeights, getTheme, IconButton, mergeStyleSets, Modal} from '@fluentui/react';
import {uPrinceTheme} from "../../../../../theme";
import ProgressBar from "../../../../shared/progressBar/progressBar";
import {PDPCustomModalProps} from "../../../../types/myDayPlanning";
import {useTranslation} from "react-i18next";
import {PrimaryButton} from "@fluentui/react/lib/Button";
import DynamicColorCheckbox from '../../../../shared/customCheckbox/dynamicColorCheckbox';

const theme = getTheme();

const primaryThemeColor = uPrinceTheme.palette.themePrimary;

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    actionButtonLabel: {
        color: primaryThemeColor,
    },
    header: [
        // eslint-disable-next-line deprecation/deprecation
        theme.fonts.xxLarge,
        {
            flex: '1 1 auto',
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '2px 2px 2px 10px',
            backgroundColor: primaryThemeColor,
            fontSize: 18,
            color: 'white',
        },
    ],
    footer: [
        // eslint-disable-next-line deprecation/deprecation
        theme.fonts.xxLarge,
        {
            display: 'flex',
            flexDirection: 'row-reverse',
            padding: '4px 12px 4px 0px',
        },
    ],
    body: {
        // flex: '4 4 auto',
        minWidth: '80vw',
        minHeight: '80vh',
        selectors: {
            p: {margin: '14px 0'},
            'p:first-child': {marginTop: 0},
            'p:last-child': {marginBottom: 0},
        },
        maxWidth: '90vw',
        maxHeight: '90vh',
        tr: {
            verticalAlign: 'top',
        },
        td: {
            verticalAlign: 'top',
            border: '1px solid gray',
        },
        table: {width: '100%', borderSpacing: 0},
        th: {
            // border: '1px solid black',
            // minWidth: screen.width * 0.30,
            color: 'black',
            background: 'white',
            border: '1px solid gray',
            padding: 8,
            width: 'auto',
        },
        b: {margin: 4},
    },
    subHeader: {
        flex: '1 1 auto',
        display: 'block',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '2px 2px 2px 10px',
        fontSize: 14,
        color: primaryThemeColor,
        backgroundColor: '#FFF7F4',
        height: 40,
        marginLeft: 'auto',
        marginRight: '2px',
    },
});

const cancelIconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
        color: 'white',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const PDPCustomModal = (props: PDPCustomModalProps) => {
    const {t} = useTranslation();

    const {
        title,
        list,
        isModalOpen,
        showProgressBar,
        handleClose,
        handleSave,
        selectedWorkers,
        handleOnChangeCheckbox,
        workerIdList,
    } = props;

    return <Modal
      titleAriaId={"titleId"}
      isOpen={isModalOpen}
      onDismiss={() => handleClose()}
      scrollableContentClassName="body"
      isBlocking={false}
      dragOptions={undefined}
      styles={{scrollableContent: {overflow: 'hidden'}}}
    >
        <div className={contentStyles.header}>
            <span>{title}</span>
            <IconButton
              styles={cancelIconButtonStyles}
              iconProps={{iconName: 'Cancel'}}
              ariaLabel="Close popup modal"
              onClick={() => handleClose()}
            />
        </div>

        <ProgressBar show={!!showProgressBar}/>
        <div className={`${contentStyles.body} `}>
            <table>
                <tr>
                    {list?.map((item: any) => {
                        return <th>{t(item?.name)}</th>
                    })}
                </tr>
                <tr>
                    {list?.map((item: any) => {
                        return <td>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '80vh',
                                flexWrap: 'wrap',
                            }}>
                                {item?.competenceList?.map((competency: any) => {
                                    return <div style={{
                                        width: 180,
                                        margin: 4,
                                        textAlign: 'start',
                                    }}>
                                        <b>{competency.name}</b>
                                        {competency?.worker?.map((workers: any) => {
                                            const getCompanyColors = () => {
                                                switch (workers?.checkboxStatus) {
                                                    case "1":   //Exist
                                                        return '#BEC1C0';
                                                    case "2":   //Absence
                                                        return '#F60404';
                                                    default:
                                                        return null;
                                                }
                                            }
                                            // const checkboxColor: IStyle = workers?.companyName === 'UPrince' ? '#FF0000' : null;

                                            return <DynamicColorCheckbox
                                              label={`${workers.name} ${workers.companyName ? `(${workers.companyName})` : null} `}
                                              checked={workerIdList?.includes(workers.id)}
                                              color={getCompanyColors()}
                                              onChange={(ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked: boolean | undefined) => {
                                                  handleOnChangeCheckbox(workers, checked);
                                              }}
                                            />
                                        })}
                                    </div>
                                })}
                            </div>
                        </td>
                    })}
                </tr>
            </table>
        </div>
        <div className={contentStyles.footer}>
            <PrimaryButton
              onClick={() => handleSave(selectedWorkers)}
              text={t('save')}
              disabled={false}
            />
        </div>
    </Modal>
};

export default PDPCustomModal;
import {
  CERTIFICATION_SAVE_EP,
  CREATE_CERTIFICATION_TAXONOMY_EP,
  READ_CERTIFICATION_BY_ID_EP,
  READ_CERTIFICATION_DROPDOWN_EP,
  READ_CERTIFICATION_LIST_EP,
  READ_CERTIFICATION_TAXANOMY_EP,
} from '../shared/endpoints';
import {
  CLEAR_CERTIFICATION_FORM_DATA,
  CLEAR_CERTIFICATIONUID,
  CREATE_CERTIFICATION_TAXONOMY,
  CREATE_CERTIFICATION_TAXONOMY_FAIL,
  CREATE_CERTIFICATION_TAXONOMY_SUCCESS,
  LOAD_CERTIFICATION_BY_ID,
  LOAD_CERTIFICATION_BY_ID_FAIL,
  LOAD_CERTIFICATION_BY_ID_SUCCESS,
  LOAD_CERTIFICATION_DROPDOWN,
  LOAD_CERTIFICATION_DROPDOWN_FAIL,
  LOAD_CERTIFICATION_DROPDOWN_SUCCESS,
  LOAD_CERTIFICATION_LSITPANE,
  LOAD_CERTIFICATION_LSITPANE_FAIL,
  LOAD_CERTIFICATION_LSITPANE_SUCCESS,
  LOAD_CERTIFICATION_SHORTCUTPANE,
  LOAD_CERTIFICATION_SHORTCUTPANE_FAIL,
  LOAD_CERTIFICATION_SHORTCUTPANE_SUCCESS,
  READ_CERTIFICATION_TAXONOMY,
  READ_CERTIFICATION_TAXONOMY_FAIL,
  READ_CERTIFICATION_TAXONOMY_SUCCESS,
  RESET_CERTIFICATION_IS_CHANGE,
  SAVE_CERT_STATE_ATTR,
  SAVE_CERTIFICATION,
  SAVE_CERTIFICATION_FAIL,
  SAVE_CERTIFICATION_SUCCESS,
  SET_CERTIFICATION_UID,
} from '../actionTypes/certificationActionTypes';
import { v4 as uuidv4 } from 'uuid';
import { POFilter } from '../types/purchaseOrder';
import { Certification, CertificationTaxonomyDTO } from '../types/certification';


const defaultState: any = {
  certList: [],
  isCERTListLoaded: false,
  isNew: false,
  isChange: false,
  isEdit: false,
  showProgressBar: false,
  certDropDowns: {},
  formData: {},
  uid: null,
  loadMsg: '',
  certShortCutPaneData: [],
  reloadListPane: false,
  borResources: {},
  isLoadingCertification: false,
  certificationTaxonomyList: [],
  certID: null,
};

export default function certificationReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {
      case LOAD_CERTIFICATION_SHORTCUTPANE:
        return { ...state };
      case LOAD_CERTIFICATION_SHORTCUTPANE_SUCCESS:
        return {
          ...state,
          certShortCutPaneData: action.payload.data.result,
        };
      case LOAD_CERTIFICATION_SHORTCUTPANE_FAIL:
        return { ...state, certShortCutPaneData: [] };
      case LOAD_CERTIFICATION_LSITPANE:
        return { ...state, isCERTListLoaded: false };
      case LOAD_CERTIFICATION_LSITPANE_SUCCESS:
        return {
          ...state,
          isCERTListLoaded: true,
          certList: action.payload.data.result,
          loadMsg: !action.payload.data.status
            ? action.payload.data.message
            : '',
        };
      case LOAD_CERTIFICATION_LSITPANE_FAIL:
        return {
          ...state,
          isCERTListLoaded: true,
          certList: [],
          loadMsg: action.error.response.data.message,
        };
      case RESET_CERTIFICATION_IS_CHANGE:
        return {
          ...state,
          isChange: false,
        };

      case LOAD_CERTIFICATION_DROPDOWN:
        return { ...state, certDropDowns: {} };
      case LOAD_CERTIFICATION_DROPDOWN_SUCCESS:
        return { ...state, certDropDowns: action.payload.data.result };
      case LOAD_CERTIFICATION_DROPDOWN_FAIL:
        return {
          ...state,
          certDropDowns: {},
        };
      case LOAD_CERTIFICATION_BY_ID:
        return { ...state, formData: {}, showProgressBar: true, isLoadingCertification: true };
      case LOAD_CERTIFICATION_BY_ID_SUCCESS:
        return {
          ...state,
          isEdit: true,
          formData: action.payload.data.result,
          showProgressBar: false,
          isLoadingCertification: false,
        };
      case LOAD_CERTIFICATION_BY_ID_FAIL:
        return {
          ...state,
          formData: {},
          showProgressBar: false,
          isLoadingCertification: false,
        };

      case SAVE_CERTIFICATION:
        return { ...state, showProgressBar: true, reloadListPane: false, certID: null };
      case SAVE_CERTIFICATION_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          reloadListPane: true,
          certID: action.payload.data.result,
        };
      case SAVE_CERTIFICATION_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
        };

      case SET_CERTIFICATION_UID:
        return {
          ...state,
          isEdit: false,
          uid: uuidv4(),
        };
      case CLEAR_CERTIFICATIONUID:
        return {
          ...state,
          uid: null,
          isChange: false,
        };
      case CLEAR_CERTIFICATION_FORM_DATA:
        return {
          ...state,
          uid: null,
          isNew: true,
          isChange: false,
          isEdit: false,
          formData: {},
        };
      case READ_CERTIFICATION_TAXONOMY:
        return { ...state, certificationTaxonomyList: [], isCERTListLoaded: false };
      case READ_CERTIFICATION_TAXONOMY_SUCCESS:
        return { ...state, certificationTaxonomyList: action.payload.data.result, isCERTListLoaded: true };
      case READ_CERTIFICATION_TAXONOMY_FAIL:
        return {
          ...state,
          certificationTaxonomyList: [], isCERTListLoaded: false,
        };

      case SAVE_CERT_STATE_ATTR:
        const attr = Object.keys(action)[1];
        const value = Object.values(action)[1];
        return {
          ...state,
          [attr]: value,
        };
      default:
        return state;
    }
  }
}

// export const readCertificationShortCutPaneData = () => {
//     return {
//         types: [
//             LOAD_CERTIFICATION_SHORTCUTPANE,
//             LOAD_CERTIFICATION_SHORTCUTPANE_SUCCESS,
//             LOAD_CERTIFICATION_SHORTCUTPANE_FAIL
//         ],
//         payload: { request: { url: READ_CERTIFICATION_SHORTCUT_PANE_EP } }
//     };
// };

export const readCertificationDropDownData = () => {
  return {
    types: [
      LOAD_CERTIFICATION_DROPDOWN,
      LOAD_CERTIFICATION_DROPDOWN_SUCCESS,
      LOAD_CERTIFICATION_DROPDOWN_FAIL,
    ],
    payload: { request: { method: 'GET', url: READ_CERTIFICATION_DROPDOWN_EP } },
  };
};


export const filterCertificationListPaneData = (filter: POFilter) => {
  return {
    types: [
      LOAD_CERTIFICATION_LSITPANE,
      LOAD_CERTIFICATION_LSITPANE_SUCCESS,
      LOAD_CERTIFICATION_LSITPANE_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_CERTIFICATION_LIST_EP,
        data: filter,
      },
    },
  };
};

export const readCertificationTaxanomyList = () => {
  return {
    types: [
      READ_CERTIFICATION_TAXONOMY,
      READ_CERTIFICATION_TAXONOMY_SUCCESS,
      READ_CERTIFICATION_TAXONOMY_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_CERTIFICATION_TAXANOMY_EP,
        data: {
          competenciesId: null,
          certificationId: null,
        },
      },
    },
  };
};


export const readByCertificationId = (id: string) => {
  return {
    types: [
      LOAD_CERTIFICATION_BY_ID,
      LOAD_CERTIFICATION_BY_ID_SUCCESS,
      LOAD_CERTIFICATION_BY_ID_FAIL],
    payload: { request: { url: READ_CERTIFICATION_BY_ID_EP + id } },
  };
};

export const resetIsChange = () => {
  return { type: RESET_CERTIFICATION_IS_CHANGE };
};

export const saveFormData = (formData: Certification) => {
  return {
    types: [
      SAVE_CERTIFICATION,
      SAVE_CERTIFICATION_SUCCESS,
      SAVE_CERTIFICATION_FAIL],
    payload: {
      request: {
        url: CERTIFICATION_SAVE_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const saveCertArttr = (attr: string, value: any) => {
  return {
    type: SAVE_CERT_STATE_ATTR,
    [attr]: value,
  };
};

export const createCertificationTaxonomy = (taxonomyData: CertificationTaxonomyDTO) => {
  return {
    types: [
      CREATE_CERTIFICATION_TAXONOMY,
      CREATE_CERTIFICATION_TAXONOMY_SUCCESS,
      CREATE_CERTIFICATION_TAXONOMY_FAIL],
    payload: {
      request: {
        url: CREATE_CERTIFICATION_TAXONOMY_EP,
        method: 'POST',
        data: taxonomyData,
      },
    },
  };
};

export const setUid = () => {
  return { type: SET_CERTIFICATION_UID };
};

export const clearFormData = () => {
  return { type: CLEAR_CERTIFICATION_FORM_DATA };
};

export class DropdownTranslation {
  id: string | null | number = null;
  name: string | null = null;
  label: string | null = null;
  languageCode: any | null = null;
}

export class CreateDropdownTranslation {
  name: string | null = null;
  label: string | null = null;
  languageCode: any | null = null;
}

export enum FeatureType {
  CPC = 1,
  PROJECT = 2,
  PBS = 3,
  PMOL = 4,
  BOR = 5,
  STOCK = 6,
  WF = 7,
  WH = 8
}

export enum DropdownType {
  CPC_MOU = 'cpcBasicUnitOfMeasure',
  CPC_RESOURCE_FAMILY = 'cpcResourceFamily',
  CPC_RESOURCE = 'cpcResourceType',
  CPC_MATERIAL = 'cpcMaterial',
  CPC_PRESSURE = 'cpcPressureClass',
  CPC_UNIT_SIZE_MEASURE = 'cpcUnitOfSizeMeasure',
  CPC_BRAND = 'cpcBrand',

  PROJECT_MANAGMENT = 'managementLevels',
  PROJECT_CURRENCY = 'currencies',
  PROJECT_TOLERANCE = 'toleranceStates',
  PROJECT_STATE = 'states',
  PROJECT_TYPE = 'types',
  PROJECT_TEMPLATE = 'templates',
  PROJECT_LEDGER = 'genaralLederNumber',
  PROJECT_ROLE = 'role',

  PBS_TOLERANCE_STATE = 'toleranceStates',
  PBS_PRODUCT_STATE = 'productStates',
  PBS_ITEM_TYPES = 'itemtypes',
  PBS_EXPERIENCE = 'experiences',
  PBS_SKILLS = 'skills',

  PMOL_STATUS = 'status',
  PMOL_TYPE = 'type',

  BOR_STATUS = 'borStatus',


  STOCK_RESOURCE_TYPE = 'resourceTypes',
  STOCK_TYPES = 'stockTypes',
  STOCK_STATUS = 'stockStatus',

  WF_RESOURCE_TYPE = 'wfResourceType',
  WF_TYPES = 'wfTypes',
  WF_STATUS = 'activityStatus',

  WH_TYPES = 'whTypes',
  WH_STATUS = 'whStatus',
  WH_TAXONOMY_LEVEL = 'whTaxonomyLevel',
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { DirectionalHint, Label, Link, Stack, Text, TooltipHost } from '@fluentui/react';
import { Depths } from '@fluentui/theme';
import { uPrinceTheme } from '../../../theme';
import { messageService } from '../../services/messageService';


const BookMarkPane = (props: { divClass: string; currentActiveSection: string, list: any, toggleBookmark: () => void, toggleBookmarkOut: () => void, isEdit: boolean }) => {
  const { t } = useTranslation();
  const collapseDocumentPane = () => {
    if (screen.width > 1440) {
      messageService.sendMessage({ collapseDocumentPane: true });
    } else {
      props.toggleBookmarkOut();
    }
  };

  const renderBookMarkList = () => {
    const bookMarkList = props.list
      .map((el: any) => {
        if (!props.isEdit && el.link === 'history') {
          return false;
        }

        if (el?.isSubHeader){
          return (
            <Link 
              key={el.id}
              className={`${
                props.currentActiveSection === el.id ? 'selected' : ''
              }`}
              style={{ marginLeft: '6px' ,borderLeft: props.currentActiveSection === el.id ? '8px solid ' + uPrinceTheme.palette.themePrimary : '' }}
              href={`#${el.link}`}>{el.id}. {el.label}
            </Link>
          );
        }
        return (
          <Label
            key={el.id}
            className={`${
              props.currentActiveSection === el.id ? 'selected' : ''
            }`}
            style={{ borderLeft: props.currentActiveSection === el.id ? '4px solid ' + uPrinceTheme.palette.themePrimary : '' }}
          >
            {' '}
            <Link href={`#${el.link}`}>{el.id}. {el.label}</Link>
          </Label>

        );
      });
    return bookMarkList;
  };

  return (
    <div>
      <div>
        <Stack
          className={props.divClass}
          verticalFill
          gap={30}
          styles={{ root: { selectors: { '@media(max-width: 1440px)': { boxShadow: Depths.depth8 } } } }}
        >
          <i
            title="Close"
            className="ms-Icon ms-Icon--ChromeClose"
            aria-hidden="true"
            onClick={collapseDocumentPane}
          ></i>
          <Text className="title">{t('bookmark')}</Text>
          <div className="inner-container">
            {renderBookMarkList()}
          </div>
        </Stack>

        <Stack
          className="bookmark-panel-responsive"
          verticalFill
          onClick={props.toggleBookmark}
          gap={30}
          styles={{ root: { boxShadow: Depths.depth8 } }}
        >
          <TooltipHost
            content={t('bookmark')}
            id="bmark"
            calloutProps={{ gapSpace: 0 }}
            directionalHint={DirectionalHint.rightCenter}
          >
            <i
              className="ms-Icon ms-Icon--SearchBookmark"
              aria-labelledby="bmark"
              aria-hidden="true"
            ></i>
          </TooltipHost>
        </Stack>
      </div>
    </div>
  );
};

export default BookMarkPane;



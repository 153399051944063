export interface DefaultCustomModalProps {
    children: any
    onRenderFooter: any
    isModalOpen: boolean
    title: string
    showProgressBar?: boolean
    handleClose: any
    styles?: any
}

export enum PROJECT_DEF_COLUMNS {
    TITLE = 'TITLE',
    CIAW_NUMBER = 'CIAW_NUMBER',
    CUSTOMER = 'CUSTOMER',
    COMPANY_NAME = 'COMPANY_NAME',
    PROJECT_MANAGER = 'PROJECT_MANAGER',
    SITE_MANAGER = 'SITE_MANAGER',
    PROJECT_STATUS = 'PROJECT_STATUS',
    PROJECT_ADDRESS = 'PROJECT_ADDRESS',
    SECTOR = 'SECTOR',
}

export const PROJECT_PLANNING_ROUTE='project-day-planning';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DirectionalHint, Link, mergeStyleSets, Stack, TooltipHost } from '@fluentui/react';
import { getContractingUnit, getProject } from '../../../shared/util';
import history from '../../../history';
import {
  AppsList20Regular,
  Clover20Regular,
  Document20Regular,
  Mail20Regular,
  Timer20Regular,
} from '@fluentui/react-icons';
import { useSelector } from 'react-redux';
import { Text } from '@fluentui/react-components';

const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 14vh)!important',
    position: 'relative',
  },
  selected: {
    fontWeight: 'bold',
    color: '#854114!important',
  },
});

const WbsCuTaskShortcutPane = () => {
  const { t } = useTranslation();
  const [currentActiveSection, setCurrentActiveSection] = useState<string | null>(null);
  const [divClass, setDivClass] = useState<any>('filter-panel-left');

  const wbsTaskDropdowns = useSelector((state: any) => state.wbsTask.wbsTaskDropdowns);

  const setFilterOption = (selectedItem: any) => {
    switch (selectedItem?.key) {
      case 'task':
        history.push({
          pathname: `/CU/${getContractingUnit()}/project/${getProject()}/wbs/task`,
          state: { groupBy: null, people: null },
        });
        break;

      case 'wbs':
        history.push({
          pathname: `/CU/${getContractingUnit()}/project/${getProject()}/wbs`,
          state: { groupBy: null },
        });
        break;

      default:
        // history.push({
        //   pathname: `/CU/${getContractingUnit()}/project/${getProject()}/wbs`,
        //   state: { groupBy: null },
        // });
    }
  };

  const toggleOverlay = () => {
    setDivClass('filter-panel-left panel-move');
  };

  const toggleOverlayFwd = () => {
    setDivClass('filter-panel-left');
  };

  const renderFilterIcon = (key: string) => {
    switch (key) {
      case 'task':
        return <Timer20Regular />;
      case 'wbs':
        return <Clover20Regular />;
      case 'mail':
        return <Mail20Regular />;
      case 'document':
        return <Document20Regular />;
      default:
        return <AppsList20Regular />;
    }
  };

  const renderFilterItems = (filters: any) => {
    if (filters) {
      return filters.map((item: any, index: number) => {
        let divider = false;
        if (index > 0) {
          const previousItem: any = filters[index - 1];
          if (previousItem.type !== item.type) {
            divider = true;
          } else {
            divider = false;
          }
        } else {
          divider = true;
        }

        return (
          <div key={item.id + 'div'}
               style={{ marginLeft: item?.subItem ? divClass === 'filter-panel-left' ? 20 : 0 : 0 }}>
            {divider && (
              <hr
                key={item.id + 'hr'}
                style={{ marginBottom: 15, borderTop: '1px solid #d2d2d2' }}
              />
            )}
            <Link
              key={item.id + 'link'}
              className={`${currentActiveSection === item.id ? classNames.selected : ''
              }`}
              href='#'
              onClick={() => setFilterOption(item)}
              disabled={item.disabled}
            >
              <TooltipHost
                key={item.id + 't'}
                content={item.name}
                id='filter-opt-1'
                calloutProps={{ gapSpace: 0 }}
                directionalHint={DirectionalHint.rightCenter}
              >
                {renderFilterIcon(item.key)}
              </TooltipHost>
              {renderFilterIcon(item.key)}
              <Text key={item.id} style={{paddingLeft: 8, fontWeight: 400}}>{item.name} </Text>
            </Link>
          </div>
        );
      });
    }
    return <div></div>;
  };


  const filterItems: any = [
    {
      key: 'all',
      id: 'all-key',
      type: 'all',
      name: t('all'),
      value: null,
      subItem: false,
    },
    // {
    //   key: 'task',
    //   id: 'task',
    //   type: 'wbs',
    //   name: t('task'),
    //   value: null,
    //   subItem: false,
    //   icon: 'ms-Icon--Stopwatch',
    // },
    // {
    //   key: 'mail',
    //   id: 'mail',
    //   type: 'wbs',
    //   name: t('mail'),
    //   value: null,
    //   subItem: false,
    //   icon: 'ms-Icon--Mail',
    //   disabled: true,
    // },
    // {
    //   key: 'document',
    //   id: 'document',
    //   type: 'wbs',
    //   name: t('document'),
    //   value: null,
    //   subItem: false,
    //   icon: 'ms-Icon--Document',
    //   disabled: true,
    // },
  ];

  return (
    <>
      <div className={`${divClass} ${classNames.wrapper}`}>
        <div className='inner-container'>
          <i
            onClick={toggleOverlay}
            className='ms-Icon ms-Icon--Back back-arrow'
            aria-hidden='true'
          ></i>
          <i
            onClick={toggleOverlayFwd}
            className='ms-Icon ms-Icon--Forward fwd-arrow'
            aria-hidden='true'
          ></i>

          <div className='filter-option-list' style={{}}>
            <Stack
              gap={10}
              verticalFill
              styles={{
                root: {
                  width: '100%',
                  verticalAlign: 'center',
                },
              }}
            >
              {renderFilterItems(filterItems)}
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};

export default WbsCuTaskShortcutPane;

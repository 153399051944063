import { Icon } from '@fluentui/react';
import React, { useState } from 'react';
import { Link, MemoryRouter as Router, Route } from 'react-router-dom';
import MenuPane from './../menuPane/component';
import { uPrinceTheme } from './../../../../theme';

const listStyle = {
  marginTop: 0,
  marginBottom: 0,
  listStyleType: 'none',
  height: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const iconStyle = {
  marginLeft: 15,
  size: 60,
  color: uPrinceTheme.palette.white,
  fontSize: 25,
  marginRight: 15,
  marginTop: 20,
  marginBottom: 20,
};

const iconSelectedStyle = {
  marginLeft: 15,
  size: 60,
  color: uPrinceTheme.palette.themePrimary,
  fontSize: 25,
  marginRight: 15,
  marginTop: 20,
  marginBottom: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const blackBackgroundStyle = { background: uPrinceTheme.palette.white };

const MenuNavigation = (props: { accessLevel: number, currentUser: any, organization: string }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const menuCategories = [{ link: '/', icon: 'InboxCheck' }, {
    link: '/two',
    icon: 'LowerBrightness',
  }, { link: '/three', icon: 'ButtonControl' }, { link: '/four', icon: 'CityNext2' }, {
    link: '/app',
    icon: 'Settings',
  }];
  const listItems = menuCategories.map((item, index) =>
    <div key={item.link} style={{ marginBottom: 0, backgroundColor: uPrinceTheme.palette.themePrimary }}>
      <li style={{
        ...listStyle,
        borderBottomWidth: 1,
        borderBottomColor: uPrinceTheme.palette.white,
        borderBottomStyle: 'solid', ...(selectedIndex === index ? blackBackgroundStyle : null),
      }} onClick={() => setSelectedIndex(index)}>
        <Link to={item.link} style={{ textDecoration: 'none', borderStyle: 'hidden', outlineWidth: '0' }}>
          <Icon iconName={item.icon} style={{ ...iconStyle, ...(selectedIndex === index ? iconSelectedStyle : null) }}/>
        </Link>
      </li>
      <div style={{}}></div>
    </div>,
  );
  return (
    <Router>
      <div style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}>
        <div style={{
          flexDirection: 'column',
          flexGrow: 1,
          width: 50,
          backgroundColor: uPrinceTheme.palette.themePrimary,
        }}>{listItems}</div>
        <div style={{ flexDirection: 'column', flexGrow: 50, flexWrap: 'nowrap' }}>

          <Route exact path="/" render={() => (
            <MenuPane stratergy={'PMOL'} accessLevel={props.accessLevel} currentUser={props.currentUser}
                      organization={props.organization}/>
          )}/>
          <Route exact path="/two" render={() => (
            <MenuPane stratergy={'two'} accessLevel={props.accessLevel} currentUser={props.currentUser}
                      organization={props.organization}/>
          )}/>
          <Route exact path="/three" render={() => (
            <MenuPane stratergy={'three'} accessLevel={props.accessLevel} currentUser={props.currentUser}
                      organization={props.organization}/>
          )}/>
          <Route exact path="/four" render={() => (
            <MenuPane stratergy={'four'} accessLevel={props.accessLevel} currentUser={props.currentUser}
                      organization={props.organization}/>
          )}/>
          <Route exact path="/app" render={() => (
            <MenuPane stratergy={'APP'} accessLevel={props.accessLevel} currentUser={props.currentUser}
                      organization={props.organization}/>
          )}/>
        </div>

      </div>

    </Router>

  );
};


export default MenuNavigation;


import React, { Component } from 'react';
import { AnimationStyles, mergeStyles, Panel, PanelType, Stack } from '@fluentui/react';
import FilterPanel from './filterPanel';
import TimeClockList from './timeClockList';
import TimeClockShiftDetailsList from './timeClockShiftDetailsList';
import history from '../../history';
import {
  getAllTimeClocks,
  getShiftExcelExportData,
  getShiftWorkflowState,
  getTimeClockById,
  optimizeDB,
  setShiftStatusApproved,
  setShiftStatusReject,
  updateShiftStatus,
} from '../../reducers/timeClockReducer';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import UprinceMessageBar from '../../shared/messageBar/messageBar';
import TimeClockCommandBarLeft from './timeClockCommandBarLeft';
import TimeClockCommandBarRight from './timeClockCommandBarRight';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getContractingUnit, getTimeZone } from '../../shared/util';
import ProgressBar from '../../shared/progressBar/progressBar';
import excelExport from './excelExport';
import TitlePane from '../../shared/titlePane/titlePane';
import i18n from '../../../i18n';
import { messageService } from '../../services/messageService';
import ConfirmationDialog from '../../shared/confirmationDialog/confirmationDialog';
import ShortCutPaneComponent from './shortcutPane/component'

export interface State {
  redirect: boolean;
  showPanel: boolean;
  showMsg: boolean;
  alertMsg: string;
  alertMsgType: string;
  headerFilter: boolean;
  activeClass: string;
  selectedTimeClockId: string;
  width: number;
  showTimeClockDetails: boolean;
  filterStartDateTime: string | Date | null;
  filterEndDateTime: string | Date | null;
  filterEmployee: string | null;
  filterTimeClockState: string | null;
  filterShiftDay: number | string | Date | null;
  sortingOrder: string | null;
  sortingAttribute: string | null;
  filterData: {};
  loadTimeClock: boolean;
  resetFilter: boolean;
  showProgressBar: boolean;
  optimizedSuccessed: boolean;
  collapseBookmarkPane: boolean;
  hiddenRemoveDialog: boolean;
  filterByWorkers: string | null;
}

interface Props extends WithTranslation {
  getAllTimeClocks: any;
  getShiftExcelExportData: any;
  updateShiftStatus: any;
  getTimeClockById: any;
  setShiftStatusApproved: any;
  setShiftStatusReject: any;
  allTimeClocks: any;
  timeClockState: any;
  getShiftWorkflowState: any;
  shiftExcelExportData: any;
  selectedTimeClock: any;
  saveTimeClock: any;
  updateTimeClock: any;
  updateTimeClockList: boolean;
  loadMsgType: string;
  detailsMsgType: string;
  saveMsg: string;
  loadMsg: string;
  detailsMsg: string;
  approvedMsgType: string;
  approvedMsg: string;
  rejectMsgType: string;
  rejectMsg: string;
  alertMsg: string;
  alertMsgType: string;
  isDataLoaded?: boolean;
  isDetailsLoaded?: boolean;
  optimizeDB: () => void;
  optimizedSuccessed: boolean;
}

class TimeClockMainLayout extends Component<Props, State> {
  subscription: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      hiddenRemoveDialog: true,
      resetFilter: false,
      redirect: false,
      showMsg: false,
      showPanel: false,
      alertMsgType: '',
      alertMsg: '',
      headerFilter: false,
      selectedTimeClockId: '',
      width: window.innerWidth,
      activeClass: 'grid-righter column-filter-activated',
      loadTimeClock: false,
      showTimeClockDetails: false,
      filterStartDateTime: null,
      filterEndDateTime: null,
      filterEmployee: null,
      filterTimeClockState: null,
      sortingAttribute: null,
      sortingOrder: null,
      filterShiftDay: 0,
      showProgressBar: false,
      optimizedSuccessed: false,
      filterData: {
        startDateTime: 0,
        userId: null,
        projectId: null,
        date: null,
        localDate: moment().toISOString(),
        offSet: getTimeZone(),
      },
      collapseBookmarkPane: false,
      filterByWorkers: null,
    };
    this.updateDimensions = this.updateDimensions.bind(this);

    this._handleClick = this._handleClick.bind(this);
  }

  componentDidMount() {
    // TODO: handleTimeClockFilter need to impliment correctly if the short cut pane did not chnaged to calender view only
    this.handleTimeClockFilter();
    //this.props.getShiftExcelExportData(this.state.filterData);
    this.props.getShiftWorkflowState();
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('load', this.updateDimensions);
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.hideDocumentPane) {
          this.hideDocumentPane();
        }
        if (data.data.collapseDocumentPane) {
          this.setState({
            collapseBookmarkPane: (!this.state.collapseBookmarkPane),
          });
        }

      }
    });
  }

  private hideDocumentPane = () => {
    if (getContractingUnit()) {
      history.push(`/CU/${getContractingUnit()}/time_clock`);
      this.setState({
        showTimeClockDetails: false,
      });
    }
  };

  private handleTimeClockListFiltering = (data: {
    filterStartDateTime: null;
    filterEndDateTime: null;
    filterEmployee: null;
    filterTimeClockState: null;
  }): void => {
    this.setState(
      {
        filterStartDateTime: data.filterStartDateTime,
        filterEndDateTime: data.filterEndDateTime,
        filterEmployee: data.filterEmployee,
        filterTimeClockState: data.filterTimeClockState,
      },
      this.handleTimeClockFilter,
    );
  };

  private handleTimeClockFilter = () => {
    let filterData = {
      startDate: this.state.filterStartDateTime,
      endDate: this.state.filterEndDateTime,
      userName: this.state.filterEmployee,
      statusId: this.state.filterTimeClockState,
      startDateTime:
        this.state.filterStartDateTime || this.state.filterEndDateTime
          ? null
          : this.state.filterShiftDay,
      userId: null,
      projectId: null,
      date: null,
      localDate: moment().toISOString(),
      offSet: getTimeZone(),
      sorter: {
        attribute: this.state.sortingAttribute,
        order: this.state.sortingOrder,
      },
    };
    this.props.getAllTimeClocks(filterData);
  };

  updateDimensions() {
    this.setState({
      width: window.innerWidth,
    });
    if (this.state.width > 1100 && this.state.selectedTimeClockId) {
      this.setState({ showPanel: false, showTimeClockDetails: true });
    } else {
      this.setState({ showTimeClockDetails: false });
    }

    this.setState({ headerFilter: false });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    this.subscription.unsubscribe();
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    const id = nextProps.match.params.id;
    const prevId = prevState.selectedTimeClockId;

    if (id && prevId != id) {
      return {
        selectedTimeClockId: nextProps.match.params.id,
        loadTimeClock: true,
      };
    } else {
      return {
        selectedTimeClockId: nextProps.match.params.id,
        loadTimeClock: false,
        optimizedSuccessed: nextProps.optimizedSuccessed,
      };
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.showMsg) {
      setTimeout(() => this.setState({ showMsg: false }), 3000);
    }

    if (
      prevProps.optimizedSuccessed != this.props.optimizedSuccessed &&
      this.props.optimizedSuccessed
    ) {
      this.handleTimeClockFilter();
    }
  }

  private handleTimeClockListUpdate = (): void => {
    this.handleTimeClockFilter();
  };

  private handleResetFilter = (resetFilter: boolean): void => {
    this.setState({
      resetFilter: resetFilter,
    });
  };

  private handleFilterShiftDay = (data: string | number | null, type: string): void => {
    // console.log('handleFilterShiftDay', data, type);
    if (type === 'workers') {
      history.push(`/CU/${getContractingUnit()}/human-resource`);
    } else if (type === 'filterByCiaw') {
      history.push(`/CU/${getContractingUnit()}/ciaw`);
    } else {
      if (getContractingUnit()) {
        history.push(`/CU/${getContractingUnit()}/time_clock`);
      } else {
        history.push(`/time_clock`);
      }
      this.setState(
        {
          filterByWorkers: null,
          resetFilter: true,
          filterShiftDay: data,
          filterStartDateTime: null,
          filterEndDateTime: null,
          filterEmployee: null,
          filterTimeClockState: null,
          showTimeClockDetails: false,
          sortingAttribute: null,
          sortingOrder: null,
        },
        this.getByFilter,
      );
    }
  };

  private getByFilter = () => {
    this.handleTimeClockFilter();
  };

  private handleTimeClockLoad = (id: {}): void => {
    if (this.state.width <= 1100) {
      this.setState({ showPanel: true, showTimeClockDetails: false });
    } else {
      this.setState({
        showTimeClockDetails: true,
        showPanel: false,
      });
    }
    this.props.getTimeClockById(id);
  };

  private handleShiftApprove = (id: {}): void => {
    this.props.setShiftStatusApproved(id);
    this.handleCustomAlert(true);
  };

  private handleShiftReject = (id: {}): void => {
    this.props.setShiftStatusReject(id);
    this.handleCustomAlert(true);
  };

  private handleCustomAlert = (showMsg: boolean) => {
    this.setState({
      showMsg: showMsg,
    });
  };

  private handleShiftListSorting = (attribute: string, order: string): void => {
    this.setState(
      {
        sortingAttribute: attribute,
        sortingOrder: order,
      },
      this.handleTimeClockFilter,
    );
  };

  // private handleDBOptimize = () => {
  //   this.props.optimizeDB();
  //   if (getContractingUnit()) {
  //     history.push(`/CU/${getContractingUnit()}/time_clock`);
  //   } else {
  //     history.push(`/time_clock`);
  //   }
  //   this.setState({
  //     showTimeClockDetails: false,
  //   });
  // };

  getHeaderTitle = () => {
    let title;
    if (this.state?.filterShiftDay !== null) {
      if (this.state?.filterShiftDay === 0) {
        title = i18n.t('today');
      } else if (this.state?.filterShiftDay === -14) {
        title = i18n.t('lastWeek');
      } else if (this.state?.filterShiftDay === -7) {
        title = i18n.t('thisWeek');
      } else if (this.state?.filterShiftDay === -1) {
        title = i18n.t('yesterday');
      }
    } else {
      title = i18n.t('All');
    }

    return title;
  };

  private handelLoadExcelExportData = () => {
    this.setState({
      showProgressBar: true,
    });
    let filterData = {
      startDate: this.state.filterStartDateTime,
      endDate: this.state.filterEndDateTime,
      userName: this.state.filterEmployee,
      statusId: this.state.filterTimeClockState,
      startDateTime:
        this.state.filterStartDateTime || this.state.filterEndDateTime
          ? null
          : this.state.filterShiftDay,
      userId: null,
      projectId: null,
      date: null,
      localDate: moment().toISOString(),
      offSet: getTimeZone(),
      sorter: {
        attribute: this.state.sortingAttribute,
        order: this.state.sortingOrder,
      },
    };
    this.props.getShiftExcelExportData(filterData).then((res: any) => {
      let isExported = this.handelExcelExport(filterData);
    });
  };

  handelExcelExport = async (filterData: any) => {
    const isExported = await excelExport(this.props.shiftExcelExportData);
    if (isExported) {
      this.setState({
        showProgressBar: false,
      });
      // this.props.updateShiftStatus(filterData);
      this.handleTimeClockFilter();
    }
  };

  renderDocumentPane = () => {
    return (
      <div>
        <Stack
          className="project-list-header right-panel"
          style={{ backgroundColor: 'rgb(255, 255, 255)', paddingRight: 0 }}
          styles={{ root: { width: '100%' } }}
        >
          <TitlePane close={true} headerTitle={
            this.props.selectedTimeClock?.person?.person?.fullName + ' - ' + this.props.selectedTimeClock?.person?.company?.name
          }/>
          <TimeClockCommandBarRight
            handleShiftApprove={(id: {}) => this.handleShiftApprove(id)}
            handleShiftReject={(id: {}) => this.handleShiftReject(id)}
            selectedTimeClock={this.props.selectedTimeClock}
            selectedTimeClockId={this.state.selectedTimeClockId}
          />
        </Stack>
        <div style={{ width: '80%' }}>
          {this.state.showMsg && this.props.alertMsg && (
            <UprinceMessageBar
              notificatonType={
                this.props.alertMsgType === 'success' ? 'success' : 'error'
              }
              message={this.props.alertMsg}
            />
          )}
        </div>

        <Stack className="project-detail-list inner-container withoutTitlePane card-container">
          <TimeClockShiftDetailsList
            isDetailsLoaded={this.props.isDetailsLoaded}
            selectedTimeClock={this.props.selectedTimeClock}
            selectedTimeClockId={this.state.selectedTimeClockId}
            detailsMsg={this.props.detailsMsg}
            detailsMsgType={this.props.detailsMsgType}
            approvedMsg={this.props.approvedMsg}
            approvedMsgType={this.props.approvedMsgType}
            handleTimeClockLoad={(id: {}) => this.handleTimeClockLoad(id)}
          />
        </Stack>
      </div>
    );
  };

  render() {
    const { t } = this.props;
    const animation = mergeStyles(
      this.state.showTimeClockDetails
        ? AnimationStyles.slideLeftIn400
        : AnimationStyles.slideRightIn400,
    );
    return (
      <div>
        <div className={'ms-Grid'} dir="ltr">
          <div className={'ms-Grid'} style={{ width: '100%' }}>
            <div className="ms-Grid-row">
              <div
                className={
                  this.state.showTimeClockDetails
                    ? 'ms-Grid-col ms-sm6 ms-md8 ms-lg6 padding-0 '
                    : 'ms-Grid-col ms-sm12 ms-md12 ms-lg12 padding-0 '
                }
              >
                <Stack horizontal>
                  <ShortCutPaneComponent />
                  {/* <FilterPanel
                    mainTitle={'Time Clock'}
                    handleFilterShiftDay={(data: string | number | null, type: string) =>
                      this.handleFilterShiftDay(data, type)
                    }
                    projectId={''}
                    shortCutPaneFilters={[]}
                    filter={[]}
                    handelShortCutPaneFilter={() => {
                    }}
                    toggleOverlay={() => {
                    }}
                    resetLayout={() => {
                    }}
                  /> */}
                  <div className={this.state.activeClass}>
                    <Stack
                      className="project-list-header right-panel"
                      style={{
                        backgroundColor: 'rgb(255, 255, 255)',
                        paddingRight: 0,
                      }}
                      styles={{ root: { width: '100%' } }}
                    >
                      <TitlePane
                        headerTitle={this.getHeaderTitle()}
                        isOldItem={true}
                      />
                      <TimeClockCommandBarLeft
                        handelLoadExcelExportData={() =>
                          this.handelLoadExcelExportData()
                        }
                        shiftExcelExportData={this.props.allTimeClocks}
                        handleDBOptimize={() => this._handleDBOptimize()}
                      />
                      <ProgressBar show={this.state.showProgressBar}/>
                    </Stack>
                    <Stack className="project-list-grid">
                      <TimeClockList
                        isDataLoaded={this.props.isDataLoaded}
                        resetFilter={this.state.resetFilter}
                        allTimeClocks={this.props.allTimeClocks}
                        loadMsg={this.props.loadMsg}
                        updateTimeClock={this.props.updateTimeClock}
                        handleTimeClockListUpdate={() =>
                          this.handleTimeClockListUpdate()
                        }
                        handleShiftListSorting={(
                          attribute: string,
                          order: string,
                        ) => this.handleShiftListSorting(attribute, order)}
                        handleTimeClockListFiltering={(data: any) =>
                          this.handleTimeClockListFiltering(data)
                        }
                        handleResetFilter={(resetFilter: boolean) =>
                          this.handleResetFilter(resetFilter)
                        }
                      />
                    </Stack>
                  </div>
                </Stack>
              </div>
              <div
                className={`ms-Grid-col ms-sm12 ms-md6 padding-left-0  ${animation}`}
                style={{
                  display: this.state.showTimeClockDetails ? 'block' : 'none',
                  transition: 'all 0.9s linear',
                }}
              >
                {this.renderDocumentPane()}
              </div>
            </div>
          </div>
          <Panel
            isOpen={this.state.showPanel}
            type={PanelType.smallFixedFar}
            onDismiss={this._hidePanel}
            isLightDismiss={true}
            headerText={this.props.t('Shift Details')}
            className="custom-detail-panel"
            closeButtonAriaLabel="Close"
          >
            {this.renderDocumentPane()}
          </Panel>

          <ConfirmationDialog
            hidden={this.state.hiddenRemoveDialog}
            title={t('areyousure')}
            subText={t('Are you sure you want to optimize the database?')}
            onClickConfirmDialog={this.onRemoveConfirm}
            onClickCancelDialog={this.onRemoveCancel}
            cancelButtonText={t('No, I won\'t!')}
            confirmButtonText={t('Yes, I want!')}
          />
        </div>
      </div>
    );
  }

  private onRemoveConfirm = () => {
    this.setState({
      hiddenRemoveDialog: true,
    });
    this.props.optimizeDB();
    if (getContractingUnit()) {
      history.push(`/CU/${getContractingUnit()}/time_clock`);
    } else {
      history.push(`/time_clock`);
    }
    this.setState({
      showTimeClockDetails: false,
    });
  };

  private onRemoveCancel = () => {
    this.setState({
      hiddenRemoveDialog: true,
    });
  };

  private _handleDBOptimize = () => {
    this.setState({
      hiddenRemoveDialog: false,
    });
  };

  private _hidePanel = () => {
    this.setState({ showPanel: false });
  };

  private _handleClick(): void {
    if (this.state.headerFilter == false) {
      this.setState({
        activeClass: 'grid-righter column-filter-activated',
        headerFilter: true,
      });
    } else {
      this.setState({ activeClass: 'grid-righter', headerFilter: false });
    }
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    loadMsg: state.timeClock.loadMsg,
    detailsMsgType: state.timeClock.detailsMsgType,
    timeClockState: state.timeClock.timeClockState,
    detailsMsg: state.timeClock.detailsMsg,
    approvedMsgType: state.timeClock.approvedMsgType,
    approvedMsg: state.timeClock.approvedMsg,
    rejectMsgType: state.timeClock.rejectMsgType,
    rejectMsg: state.timeClock.rejectMsg,
    allTimeClocks: state.timeClock.allTimeClocks,
    shiftExcelExportData: state.timeClock.shiftExcelExportData,
    selectedTimeClock: state.timeClock.selectedTimeClock,
    updateTimeClock: state.timeClock.updateTimeClock,
    alertMsgType: state.timeClock.alertMsgType,
    alertMsg: state.timeClock.alertMsg,
    isDataLoaded: state.timeClock.isDataLoaded,
    isDetailsLoaded: state.timeClock.isDetailsLoaded,
    optimizedSuccessed: state.timeClock.optimizedSuccessed,
  };
};

const mapDispatchToProps = {
  getAllTimeClocks,
  getShiftExcelExportData,
  updateShiftStatus,
  getTimeClockById,
  setShiftStatusApproved,
  setShiftStatusReject,
  getShiftWorkflowState,
  optimizeDB,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(TimeClockMainLayout)),
);

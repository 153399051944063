import * as React from 'react';
import { useEffect } from 'react';
import { Card, Field, Input, InputProps, makeStyles, shorthands } from '@fluentui/react-components';
import i18n from '../../../../../i18n';
import { Search24Regular } from '@fluentui/react-icons';
import Dropdown from '../../../../shared/fluentUI9/Dropdown/Dropdown';
import { getWbsGroupTaskList, getWbsTaskList, saveWbsTaskStateAttr } from '../../../../reducers/wbsTaskReducer';
import PeoplePicker from '../../../../shared/fluentFinalForm/PeoplePicker';
import { WBS_TASK_PEOPLE } from '../../../../types/wbs';
import history from '../../../../history';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles({
  card: {
    ...shorthands.margin('auto'),
    width: '98%',
    maxWidth: '100%',
    marginTop: '10px',
  },
});

interface Props {
  isCollapsed: boolean;
}

const WbsTaskFilterCard = (props: Props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { state }: any = useLocation();
  // const { featureId }: ParamTypes = useParams();

  const wbsTaskGroupFilters = useSelector((state: any) => state.wbsTask.wbsTaskGroupFilters);
  // const selectedTo = useSelector((state: any) => state.wbsTask.selectedTo);
  // const selectedCC = useSelector((state: any) => state.wbsTask.selectedCC);
  const wbsTaskDropdowns = useSelector((state: any) => state.wbsTask.wbsTaskDropdowns);
  const userInfo = useSelector((state: any) => state.uprince.userInfo);

  const [toPerson, setToPerson] = React.useState<any>([]);
  const [ccPerson, setCCPerson] = React.useState<any>([]);
  const [selectedTo, setSelectedTo] = React.useState<any>(null);
  const [selectedCC, setSelectedCC] = React.useState<any>(null);

  useEffect(() => {
    console.log({ state });
    const peopleFilterSelection = async () => {
      if (state?.people && state?.people === WBS_TASK_PEOPLE.MY_TASKS) {
        console.log('yes');
        setSelectedCC(null);
        setSelectedTo([{
          key: userInfo?.userId,
          text: userInfo?.userName,
        }]);
      } else {
        console.log('no');
        setSelectedCC(null);
        setSelectedTo(null);
      }
    };

    peopleFilterSelection().then();
  }, [state]);

  const getFilterData = () => {
    if (wbsTaskGroupFilters?.groupItem) {
      dispatch(getWbsGroupTaskList(wbsTaskGroupFilters));
    } else {
      dispatch(getWbsTaskList(wbsTaskGroupFilters));
    }
  };

  const onChange: InputProps['onChange'] = (ev, data) => {
    console.log({ data });
    dispatch(saveWbsTaskStateAttr('wbsTaskGroupFilters', {
      ...wbsTaskGroupFilters,
      search: data?.value ?? null,
      name: data?.value ?? null,
    }));
  };

  const onFilter = (event: any) => {
    if (event?.keyCode === 13) {
      getFilterData();
    }
  };

  const addAllItem = (options: any) => {
    const allItem = [{ key: null, id: null, text: i18n.t('all') }];
    return [...allItem, ...options];
  };

  return (
    <>
      <Card className={styles.card}>
        {props?.isCollapsed ?
          <div style={{ display: 'flex', justifyContent: 'start', gap: 5 }}>
            <Field>
              <Input
                value={wbsTaskGroupFilters?.search}
                onChange={onChange}
                onKeyDown={onFilter}
                placeholder={i18n.t('search')}
                contentBefore={<Search24Regular />}
              />
            </Field>
            <div>
              <Dropdown
                placeholder={i18n.t('dueDate')}
                options={wbsTaskDropdowns?.deliveryStatus ? addAllItem(wbsTaskDropdowns?.deliveryStatus) : []}
                value={wbsTaskGroupFilters?.deliveryStatusId}
                onChange={(value: any) => {
                  dispatch(saveWbsTaskStateAttr('wbsTaskGroupFilters', {
                    ...wbsTaskGroupFilters,
                    deliveryStatusId: value?.key,
                  }));
                }}
              />
            </div>
            <div>
              <Dropdown
                placeholder={i18n.t('status')}
                options={wbsTaskDropdowns?.status ? addAllItem(wbsTaskDropdowns?.status) : []}
                value={wbsTaskGroupFilters?.statusId}
                onChange={(value: any) => {
                  dispatch(saveWbsTaskStateAttr('wbsTaskGroupFilters', {
                    ...wbsTaskGroupFilters,
                    statusId: value?.key,
                  }));
                }}
              />
            </div>
            <div>
              <PeoplePicker
                label={''}
                placeHolder={i18n.t('to')}
                isDisabled={false}
                selectedItem={selectedTo}
                onChange={(item: any) => {
                  if (state?.people === WBS_TASK_PEOPLE.MY_TASKS) {
                    history.push({ state: { people: null } });
                  } else {
                    dispatch(saveWbsTaskStateAttr('wbsTaskGroupFilters', {
                      ...wbsTaskGroupFilters,
                      IsTo: false,
                      IsCc: !_.isEmpty(ccPerson),
                      isMyTask: null,
                      personId: ccPerson,
                    }));
                  }

                  setToPerson([]);

                  return item;
                }}
                onPersonSelected={(item: any) => {
                  if (item) {
                    console.log(item);
                    const isMyTask = !!item?.cabPersonId && !_.isEmpty(ccPerson);
                    const personIdArr = [item?.cabPersonId]?.concat(ccPerson);

                    dispatch(saveWbsTaskStateAttr('wbsTaskGroupFilters', {
                      ...wbsTaskGroupFilters,
                      IsTo: !!item?.cabPersonId,
                      IsCc: !_.isEmpty(ccPerson),
                      isMyTask: isMyTask ? false : null,
                      personId: personIdArr,
                    }));
                    setToPerson([item?.cabPersonId]);
                    setSelectedTo([{
                      key: item?.cabPersonId,
                      text: item?.text,
                    }]);
                  }

                  return item;
                }}
              />
            </div>
            <div>
              <PeoplePicker
                label={''}
                placeHolder={i18n.t('cc')}
                isDisabled={state?.people === WBS_TASK_PEOPLE.MY_TASKS}
                selectedItem={selectedCC}
                onChange={(item: any) => {
                  dispatch(saveWbsTaskStateAttr('wbsTaskGroupFilters', {
                    ...wbsTaskGroupFilters,
                    IsTo: !_.isEmpty(toPerson),
                    IsCc: false,
                    isMyTask: null,
                    personId: toPerson,
                  }));
                  setCCPerson([]);

                  return item;
                }}
                onPersonSelected={(item: any) => {
                  if (item) {
                    const isMyTask = !_.isEmpty(toPerson) && !!item?.cabPersonId;
                    const personIdArr = [item?.cabPersonId]?.concat(toPerson);

                    dispatch(saveWbsTaskStateAttr('wbsTaskGroupFilters', {
                      ...wbsTaskGroupFilters,
                      IsTo: !_.isEmpty(toPerson),
                      IsCc: !!item?.cabPersonId,
                      isMyTask: isMyTask ? false : null,
                      personId: personIdArr,
                    }));
                    setCCPerson([item?.cabPersonId]);
                    setSelectedCC([{
                      key: item?.cabPersonId,
                      text: item?.text,
                    }]);
                  }

                  return item;
                }}
              />
            </div>
          </div> :
          <div style={{ display: 'flex', justifyContent: 'start', gap: 5 }}>
            <Field style={{ width: '100%' }}>
              <Input
                value={wbsTaskGroupFilters?.search}
                onChange={onChange}
                onKeyDown={onFilter}
                placeholder={i18n.t('search')}
                contentBefore={<Search24Regular />}
              />
            </Field>
          </div>
        }
      </Card>
    </>
  );

};

export default WbsTaskFilterCard;
export class CertificationListPaneFilter {
  title: string | null = null;
  sorter: Sorter = new Sorter();
}

export interface CertificationShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
  value: number | null
}

class Sorter {
  attribute: string | null = null;
  order: string | null = null;
}

export class CertificationListItem {
  id: string | null = null;
  name: string | null = null;
  sequenceId: string | null = null;
  title: string | null = null;
}

export interface DropDown {
  key: string;
  text: string;
}

export interface CertificationDropDowns {
  certificationTaxonomyLevel: DropDown[];
}

export interface CountryDropDowns {
  CountryLevel: DropDown[];
}

export interface Certification {
  id: string | null;
  title: string;
  sequenceId: string
}

export interface History {
  createdByUser: string | null;
  createdDateTime: string | null;
  revisionNumber: number | string | null;
  updatedByUser: string | null;
  updatedDateTime: string | null;
}

export interface CertificationTaxonomy {
  id: string;
  title: string;
  cetificationId: string;
  parentId?: any;
  certificationTaxonomyLevelId: string;
  displayOrder: number;
  isChildren: boolean;
  parentCertificationId?: any;
  certification?: any;
}

export interface CertificationTaxonomyDTO {
  id: string;
  title: string;
  cetificationId: string;
  parentId: string;
  certificationTaxonomyLevelId: string;
  parentCertificationId: string;
}

export const formData = {
  id: null,
  title: 'string',
  sequenceId: 'string',
};


import * as React from 'react';
import { useEffect } from 'react';
import { Stack } from '@fluentui/react';
import TitlePane from '../../../shared/titlePane/titlePane';
import ProgressBar from '../../../shared/progressBar/progressBar';
import { useSelector } from 'react-redux';
import { WBS_TAXONOMY_LEVEL } from '../../../types/wbs';
import WbsCreateProductDocumentPane from '../../wbsCreate/documentPane/WbsCreateProductDocumentPane';
import DocumentPane from '../../wbsTask/documentPane/DocumentPane';
import WBSCmdBarRight from '../commandBar/wbsCmdBarRight/component';
import WbsCreateDocumentPane from '../../wbsCreate/documentPane/WbsCreateDocumentPane';
import _ from 'lodash';
import WbsIssueDocumentPane from '../../wbsIssue/documentPane/WbsIssueDocumentPane';

interface Props {
  wbsTaxonomyLevel: string;
}

const DocumentPaneWrapper = (props: Props) => {
  // const dispatch = useDispatch();

  // Redux state selectors
  const selectedProject = useSelector((state: any) => state.project.selectedProject);

  const projectWbsTemplateId = useSelector((state: any) => state.wbs.projectWbsTemplateId);
  const showWbsProgressBar = useSelector((state: any) => state.wbsCreate.showWbsProgressBar);
  const wbsProductFormData = useSelector((state: any) => state.wbsCreate.wbsProductFormData);

  const wbsListData = useSelector((state: any) => state.wbsCreate.wbsListData);

  const isLoadingDocPane = useSelector((state: any) => state.wbsTask.isLoadingDocPane);
  const formData = useSelector((state: any) => state.wbsTask.formData);

  const issueFormData = useSelector((state: any) => state.wbsIssue.formData);
  const showWbsIssueProgressBar = useSelector((state: any) => state.wbsIssue.showWbsIssueProgressBar);

  const [taskFormData, setTaskFormData] = React.useState<any>({});
  const [issueData, setIssueData] = React.useState<any>({});

  useEffect(() => {
    console.log('DocumentPaneWrapper mounted');
    // dispatch(getWbsProductById(templateId));

    return () => {
      console.log('DocumentPaneWrapper cleaned up');
    };
  }, []);

  useEffect(() => {
    if (formData?.id) {
      // console.log('task formData >> ',formData);
      const loadTaskFormData = async () => {
        setTaskFormData(formData);
      };

      loadTaskFormData().then();
    }
  }, [formData]);

  useEffect(() => {
    if (issueFormData?.id) {
      // console.log('issueFormData >> ',issueFormData);
      const loadIssueFormData = async () => {
        setIssueData(issueFormData);
      };

      loadIssueFormData().then();
    }
  }, [issueFormData]);

  const renderDocumentPane = () => {
    switch (props.wbsTaxonomyLevel) {
      case WBS_TAXONOMY_LEVEL.WBS:
        return <WbsCreateDocumentPane featureId={projectWbsTemplateId} />;
      case WBS_TAXONOMY_LEVEL.PRODUCT:
        return <WbsCreateProductDocumentPane />;
      case WBS_TAXONOMY_LEVEL.TASK:
        return <DocumentPane />;
      case WBS_TAXONOMY_LEVEL.ISSUE:
        return <WbsIssueDocumentPane isNew={null} />;
      default:
        return <></>;
    }
  };

  const renderDocumentPaneTitle = () => {
    let getParentTemplate;
    if (selectedProject && !_.isEmpty(selectedProject) && selectedProject.wbsTemplateId) {
      getParentTemplate = wbsListData.filter((item: any) => item?.id === selectedProject?.wbsTemplateId);
    }

    switch (props.wbsTaxonomyLevel) {
      case WBS_TAXONOMY_LEVEL.WBS:
        return getParentTemplate[0]?.title;
      case WBS_TAXONOMY_LEVEL.PRODUCT:
        return `${wbsProductFormData?.productId} - ${wbsProductFormData?.name}`;
      case WBS_TAXONOMY_LEVEL.TASK:
        return `${taskFormData?.name}`;
      case WBS_TAXONOMY_LEVEL.ISSUE:
        return `${issueData?.title ?? ""}`;
      default:
        return <></>;
    }
  };

  return (
    <>
      <Stack>
        <Stack.Item>
          {/* not modified cuz changes in future */}
          <TitlePane
            headerTitle={renderDocumentPaneTitle()}
            type={1}
            close={true}
            isFullScreen={true}
          />
        </Stack.Item>
        <Stack.Item>
          <Stack className={'grid-righter'}>
            <Stack
              className='project-list-header new-design'
              style={{
                backgroundColor: 'rgb(255, 255, 255)',
                paddingRight: 0,
              }}
              styles={{ root: { width: '100%', paddingRight: 0 } }}
            >
              <WBSCmdBarRight wbsTaxonomyLevel={props.wbsTaxonomyLevel} />
              <ProgressBar
                show={isLoadingDocPane || showWbsProgressBar || showWbsIssueProgressBar}
              />
            </Stack>

            <Stack className={`main-layout-document-pane-stack`}>
              <div style={{ width: '100%', padding: 0 }}>
                {renderDocumentPane()}
              </div>
            </Stack>
          </Stack>
        </Stack.Item>
      </Stack>
    </>
  );
};

export default DocumentPaneWrapper;
import {AnimationStyles, getTheme, ITheme, mergeStyles, Panel, PanelType, Stack} from '@fluentui/react';

import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {v4 as uuidv4} from 'uuid';
import history from '../../history';
import {withTranslation, WithTranslation} from 'react-i18next';
import CpcCommandBarLeft from './commandBar/cpcCommandBarLeft';
import CpcCommandBarRight from './commandBar/cpcCommandBarRight';
import {CpcDocumentPane} from './documentPane/container';
import {ProductCatalogueListPane} from './listPane/container';
import ProductCatalogueShortCutPane from './shortCutPane/container';
import {excelExport, getContractingUnit, getProject} from '../../shared/util';
import React from 'react';
import ProgressBar from '../../shared/progressBar/progressBar';
import {
  CorporateProductCatalogue,
  CorporateProductCatalogueListItem,
  ProductCatalogueFilter,
} from '../../types/corporateProductCatalogue';
import TitlePane from '../../shared/titlePane/titlePane';
import {
  deleteProductCatalogue,
  deleteProductCatalogueImage,
  deleteProductCatalogueNickName,
  deleteProductCatalogueVendor,
  getAllProductCatalogue,
  getCpcResourceFamily,
  getCpcShortcutPaneFilterList,
  getDropdownData,
  getExcelData,
  getOrganizationByName,
  getProductCatalogueById,
  getVehicleList,
  newProductCatalogue,
  savePicturesOfTheInstallation,
  saveProductCatalogue,
} from '../../reducers/productCatalogueReducer';
import {messageService} from '../../services/messageService';
import ConfirmationDialog from '../../shared/confirmationDialog/confirmationDialog';
import BookMarkPane from '../../shared/bookMarkPane/bookMarkPane';

export interface State {
  cpcListPaneTitle: string | null;
  showProgressBar: boolean;
  isClone: boolean;
  submitCpc: boolean;
  redirect: boolean;
  loading: boolean;
  showPanel: boolean;
  width: number;
  activeClass: string;
  bookmarkDivClass: string;
  headerFilter: boolean;
  showDetails: boolean;
  currentActiveSection: string;
  isOpenNickNameModel: boolean;
  allProductCatalogue: [];
  uploadedImages: string | null;
  productCatalogue: CorporateProductCatalogue;
  autoSave: boolean;
  isEdit: boolean;
  selectedCpcId: string | null | undefined;
  cpcFilter: ProductCatalogueFilter;
  cpcListItem: CorporateProductCatalogueListItem[];
  cpcPressureClass: [],
  selectedCpcItemIds: [] | string[];
  //headerTitle:string|null,
  hiddenRemoveDialog: boolean,
}

interface Props extends WithTranslation {
  getExcelData: () => void;
  newProductCatalogue: () => void;
  getOrganizationByName: (name: string) => void;
  getCpcResourceFamily: () => void;
  deleteProductCatalogueNickName: (idList: [] | string[]) => void;
  deleteProductCatalogueVendor: (idList: [] | string[]) => void;
  deleteProductCatalogueImage: (idList: [] | string[]) => void;
  deleteProductCatalogue: (idList: [] | string[]) => void;
  getAllProductCatalogue: (data: ProductCatalogueFilter) => void;
  getCpcShortcutPaneFilterList: () => void;
  saveProductCatalogue: (productCatalogue: CorporateProductCatalogue) => void;
  getProductCatalogueById: (id: string) => void;
  productCatalogue: CorporateProductCatalogue;
  getDropdownData: () => void;
  savePicturesOfTheInstallation: (files: FileList | null) => void;
  cpcListItem: CorporateProductCatalogueListItem[];
  uploadedImages: string | null;
  resourceTypes: [];
  resourceTypeFilter: [];
  resourceFamily: [];
  cpcBasicUnitOfMeasure: [];
  cpcPressureClass: [],
  cpcMaterial: [],
  cpcBrand: [],
  languageCodes: [];
  shortCutPaneFilters: [];
  organizations: [];
  cpcUnitOfSizeMeasure: [];
  excelData: [];
  autoSave: boolean;
  isChange: boolean;
  isDataLoaded?: boolean;
  loadMsg: string;
  cpcFilter: ProductCatalogueFilter;
  location: any;
  isCreateNewCpc: boolean;
  showProgressBar: boolean;
  getVehicleList: any;
  vehicleTrackingNumberList: [];
}

const contentStyle = mergeStyles({
  height: 'calc(100vh - 250px)!important',
  display: 'flex',
  flexDirection: 'column',
  selectors: {
    '@media(max-width: 1100px)': {
      width: '97.4%',
      height: 'calc(100vh)',
      position: 'absolute',
    },
  },
});

const listStyle = mergeStyles({
  flex: '1 1 auto',
  width: '100%',
  height: 'auto',
  overflowX: 'hidden',
  overflowY: 'auto',
  paddingBottom: '30px',
  marginLeft: '0',
});


class ProductCatalogueMainLayout extends React.Component<Props, State> {
  _isMounted = false;
  isEdit = false;
  theme: ITheme = getTheme();
  subscription: any;
  private bookmarkList = [
    {
      id: 1,
      link: 'corporate-product-header',
      label: this.props.t('corporateProductHeader'),
    },
    { id: 2, link: 'vendor', label: this.props.t('vendor') },
    {
      id: 3,
      link: 'history',
      label: this.props.t('history'),
    },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      cpcListPaneTitle: this.props.t('all'),
      showProgressBar: false,
      hiddenRemoveDialog: true,
      isClone: false,
      submitCpc: false,
      selectedCpcItemIds: [],
      cpcListItem: [],
      cpcFilter: {
        title: null,
        resourceTitle: null,
        resourceTypeId: null,
        resourceFamilyId: null,
        resourceNumber: null,
        status: null,
        sorter: {
          attribute: null,
          order: null,
        },
      },
      selectedCpcId: null,
      isOpenNickNameModel: false,
      isEdit: false,
      autoSave: false,
      redirect: false,
      loading: false,
      width: window.innerWidth,
      showPanel: false,
      headerFilter: false,
      activeClass: 'grid-righter column-filter-activated',
      bookmarkDivClass: 'bookmark-panel parent',
      currentActiveSection: '1',
      showDetails: false,
      allProductCatalogue: [],
      uploadedImages: null,
      productCatalogue: {
        id: null,
        title: null,
        headerTitle: null,
        resourceNumber: null,
        resourceTitle: null,
        resourceTypeId: null,
        resourceFamilyId: null,
        cpcBasicUnitOfMeasureId: null,
        inventoryPrice: null,
        minOrderQuantity: null,
        maxOrderQuantity: null,
        size: null,
        cpcUnitOfSizeMeasureId: null,
        wallThickness: null,
        cpcPressureClassId: null,
        cpcMaterialId: null,
        weight: null,
        status: 0,
        cpcImage: [],
        cpcResourceNickname: [],
        cpcVendor: [],
        cpcHistoryLog: null,
        cpcBrandId: null,
        resourceId: null,
        trackingNo: null,
      },
      cpcPressureClass: [],
    };

    this.updateDimensions = this.updateDimensions.bind(this);
    this._handleClick = this._handleClick.bind(this);
  }

  static getDerivedStateFromProps(nextProps: any) {
    let showDetails = (nextProps.match.params.cpcId || nextProps.match.url.includes('/product-catalogue/new'));
    if (nextProps?.productCatalogue && Object.keys(nextProps?.productCatalogue).length === 0) {
      return {
        autoSave: nextProps.autoSave,
        isEdit: !!(nextProps.match.params.cpcId),
        showDetails: (window.innerWidth > 1100) ? showDetails : false,
        selectedCpcId: nextProps.match.params.cpcId,
        cpcPressureClass: nextProps.cpcPressureClass,
      };
    } else {
      return {
        autoSave: nextProps.autoSave,
        productCatalogue: nextProps?.productCatalogue,
        isEdit: !!(nextProps.match.params.cpcId),
        showDetails: (window.innerWidth > 1100) ? showDetails : false,
        selectedCpcId: nextProps.match.params.cpcId,
        cpcPressureClass: nextProps.cpcPressureClass,
      };
    }
  }

  componentDidMount() {
    this.props.getCpcShortcutPaneFilterList();
    this.props.getAllProductCatalogue(this.state.cpcFilter);
    this.props.getDropdownData();
    this.props.getCpcResourceFamily();
    this.props.getVehicleList();
    //this.props.getExcelData();
    this._isMounted = true;
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('load', this.updateDimensions);

    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.cpcListPaneTitle) {
          this.setState({
            cpcListPaneTitle: data.data.cpcListPaneTitle,
          });
        }
        if (data.data.hideDocumentPane) {
          this.hideDocumentPane();
        }

        if (data.data.resetCpcListSelection) {
          // alert("dsfs");
          //this.resetProductCatalogueState();
        }
      }
    });
  }


  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.excelData != this.props.excelData) {
      if (this.props.excelData.length !== 0 && this.state.showProgressBar) {
        this.exportExcel();
      }
    }
    if (prevProps.isCreateNewCpc != this.props.isCreateNewCpc) {
      if (this.props.isCreateNewCpc && this.state.productCatalogue.resourceNumber) {
        //this.props.getProductCatalogueById(this.state.productCatalogue.resourceNumber);
      }
    }
    if (prevProps.showProgressBar != this.props.showProgressBar) {
      this.setState({
        showProgressBar: this.props.showProgressBar,
      });
    }

    if (this.props.location && this.props.location.state && this.state.cpcFilter != this.props.location.state.cpcFilter) {
      if (this.props.location.state.cpcFilter) {
        this.setState({
          cpcFilter: this.props.location.state.cpcFilter,
        }, this.reloadCpcListItem);
      }
    }
  }


  updateDimensions() {
    if (this._isMounted) {
      this.setState({
        width: window.innerWidth,
      });
      if (this.state.showDetails) {
        if (this.state.width > 1100) {
          this.setState({ showPanel: false, showDetails: true });
        } else {
          this.setState({ showPanel: true, showDetails: false });
        }
      }
    }
  }

  private handleScroll = (e: any): void => {
    let element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
    }

    let h2Headings = document.getElementsByClassName('proj-detail-block');
    let item = h2Headings[0];
    let tt = 0;

    for (let i = 0; i < h2Headings.length; i++) {
      if (h2Headings[i].getBoundingClientRect().top <= 300) {
        if (tt <= h2Headings[i].getBoundingClientRect().top) {
          tt = h2Headings[i].getBoundingClientRect().top;
          item = h2Headings[i];

          var c = item.id;
          //console.log(c);
          this.setState({ currentActiveSection: c });
        }
      }
    }
  };

  private handleSelectedCpcItem = (selectedCpcItemIds: [] | string[]) => {

    if (selectedCpcItemIds) {
      let ids = [...selectedCpcItemIds];
      this.setState({
        selectedCpcItemIds: ids,
        isClone: false,
        submitCpc: false,
      });
    }

  };

  private handleCloneCpcItem = () => {
    let uid = uuidv4();
    let cloneText = this.props.t('clone');
    let productCatalogue: any = this.state.productCatalogue;
    productCatalogue.id = uid;
    //productCatalogue.key=uid
    productCatalogue.status = 0;
    productCatalogue.title = null;
    productCatalogue.resourceTitle = productCatalogue.resourceTitle + `(${cloneText})`;
    productCatalogue.resourceNumber = null;
    this.setState({
      isClone: true,
      isEdit: false,
      productCatalogue: productCatalogue,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.updateDimensions);
    this.props.newProductCatalogue();
    this.subscription.unsubscribe();
  }

  savePicturesOfTheInstallation = (files: FileList | null) => {
    if (files) {
      this.props.savePicturesOfTheInstallation(files);
    }
  };

  resetProductCatalogueState = () => {
    let productCatalogue = {
      id: null,
      headerTitle: null,
      title: null,
      resourceNumber: null,
      resourceTitle: null,
      resourceTypeId: null,
      resourceFamilyId: null,
      cpcBasicUnitOfMeasureId: null,
      inventoryPrice: null,
      minOrderQuantity: null,
      maxOrderQuantity: null,
      size: null,
      cpcUnitOfSizeMeasureId: null,
      wallThickness: null,
      cpcPressureClassId: null,
      cpcMaterialId: null,
      weight: null,
      status: 0,
      cpcImage: [],
      cpcResourceNickname: [],
      cpcVendor: [],
      cpcHistoryLog: null,
      cpcBrandId: null,
      resourceId: null,
      trackingNo: null,
    };
    this.setState({
      productCatalogue: productCatalogue,
    });
  };

  private createNewProductCatalogue = (create: boolean): void => {
    this.props.newProductCatalogue();
    let productCatalogue = {
      id: null,
      headerTitle: null,
      title: null,
      resourceNumber: null,
      resourceTitle: null,
      resourceTypeId: null,
      resourceFamilyId: null,
      cpcBasicUnitOfMeasureId: null,
      inventoryPrice: null,
      minOrderQuantity: null,
      maxOrderQuantity: null,
      size: null,
      cpcUnitOfSizeMeasureId: null,
      wallThickness: null,
      cpcPressureClassId: null,
      cpcMaterialId: null,
      weight: null,
      status: 0,
      cpcImage: [],
      cpcResourceNickname: [],
      cpcVendor: [],
      cpcHistoryLog: null,
      cpcBrandId: null,
      resourceId: null,
      trackingNo: null,
    };
    if (this.state.width > 1100) {
      this.setState({
        showDetails: true,
        showPanel: false,
        productCatalogue: productCatalogue,
      });
    } else {
      this.setState({
        showDetails: true,
        showPanel: true,
        productCatalogue: productCatalogue,
      });
    }
    if (getContractingUnit() && !getProject()) {
      history.push(`/CU/${getContractingUnit()}/product-catalogue/new`);
    } else if (getContractingUnit() && getProject()) {
      history.push(`/CU/${getContractingUnit()}/project/${getProject()}/product-catalogue/new`);
    } else {
      history.push(`/product-catalogue/new`);
    }

  };

  getProductCatalogueById = (id: string) => {

    this.showDocumentPane();
    this.props.getProductCatalogueById(id);
  };

  reloadCpcListItem = () => {
    this.props.getAllProductCatalogue(this.state.cpcFilter);
    //this.props.getExcelData();
  };

  private handleActionBarSave = (save: boolean): void => {
    // alert("save start"+JSON.stringify(save));
    this.setState({
      submitCpc: save,
    });
  };

  showDocumentPane = () => {
    if (this.state.isEdit) {
      if (this.state.width > 1100) {
        this.setState({ showPanel: false, showDetails: true });
      } else {
        this.setState({ showPanel: true, showDetails: false });
      }
    }
  };

  private hideDocumentPane = () => {
    if (getContractingUnit() && !getProject()) {
      history.push(`/CU/${getContractingUnit()}/product-catalogue`);
    } else if (getContractingUnit() && getProject()) {
      history.push(`/CU/${getContractingUnit()}/project/${getProject()}/product-catalogue`);
    } else {
      history.push(`/product-catalogue`);
    }
  };

  private getDocumentPaneView = () => {
    return (
      <div>
        <Stack
          className="project-list-header right-panel"
          style={{ backgroundColor: 'rgb(255, 255, 255)', paddingRight: 0 }}
          styles={{ root: { width: '100%', paddingRight: 0 } }}
        >
          <TitlePane close={true} headerTitle={this.state.productCatalogue.headerTitle}/>
          <CpcCommandBarRight
            createNewProductCatalogue={(create: any) =>
              this.createNewProductCatalogue(create)
            }
            saveProductCatalogue={(save: boolean) => {
              this.handleActionBarSave(save);
            }}
            isLoading={this.state.showProgressBar}
          />
          <ProgressBar show={this.state.showProgressBar}/>
        </Stack>

        <Stack className="project-detail-list inner-container cpc "
               styles={{
                 root: {
                   width: '100%',
                   height: 'auto!important',
                   backgroundColor: '#edebe9!important',
                 },
               }}
        >
          {/*doc*/}
          <Stack
            className={contentStyle}
            horizontalAlign="start"
            verticalAlign="start"
            padding={0}
            gap={0}
            styles={{
              root: {
                width: '100%',
                height: '100%',
              },
            }}
          >
            <Stack
              className={listStyle}
              styles={{
                root: {
                  width: '100%',
                  height: 'auto',
                },
              }}
              verticalFill
              gap={50}
              horizontalAlign="start"
              verticalAlign="start"
              onScroll={this.handleScroll}
            >
              <CpcDocumentPane
                submitCpc={this.state.submitCpc}
                isEdit={this.state.isEdit}
                selectedCpcId={this.state.selectedCpcId}
                productCatalogue={this.state.productCatalogue}
                savePicturesOfTheInstallation={(files: FileList | null) =>
                  this.savePicturesOfTheInstallation(files)
                }
                saveProductCatalogue={(
                  productCatalogue: CorporateProductCatalogue,
                ) => {
                  //alert("save final");
                  this.setState({
                    submitCpc: false,
                  });
                  this.props.saveProductCatalogue(productCatalogue);
                }}
                deleteProductCatalogueNickName={(
                  deleteList: [] | string[],
                ) => {
                  this.props.deleteProductCatalogueNickName(deleteList);
                }}
                deleteProductCatalogueVendor={(
                  deleteList: [] | string[],
                ) => {
                  this.props.deleteProductCatalogueVendor(deleteList);
                }}
                deleteProductCatalogueImage={(
                  deleteList: [] | string[],
                ) => {
                  this.props.deleteProductCatalogueImage(deleteList);
                }}
                getProductCatalogueById={(id: string) => {
                  this.getProductCatalogueById(id);
                }}
                autoSave={this.props.autoSave}
                uploadedImages={this.props.uploadedImages}
                resourceTypes={this.props.resourceTypes}
                resourceFamily={this.props.resourceFamily}
                languageCodes={this.props.languageCodes}
                cpcBasicUnitOfMeasure={this.props.cpcBasicUnitOfMeasure}
                cpcPressureClass={this.props.cpcPressureClass}
                cpcMaterial={this.props.cpcMaterial}
                cpcBrand={this.props.cpcBrand}
                organizations={this.props.organizations}
                cpcUnitOfSizeMeasure={this.props.cpcUnitOfSizeMeasure}
                handelGetOrganizationByName={(name: string) => this.handelGetOrganizationByName(name)}
                changeSubmitCpc={(changeSubmitCpc: boolean) => {
                  //alert("save final");
                  this.setState({
                    submitCpc: changeSubmitCpc,
                  });
                }}
                vehicleTrackingNumberList={this.props.vehicleTrackingNumberList}
              />
            </Stack>
            <BookMarkPane
              isEdit={(this.state.isEdit && this.state.productCatalogue.status == 1)}
              divClass={this.state.bookmarkDivClass}
              currentActiveSection={this.state.currentActiveSection}
              list={this.bookmarkList}
              toggleBookmark={this._toggleBookmark}
              toggleBookmarkOut={this._toggleBookmarkOut}
            />
          </Stack>
        </Stack>
      </div>
    );
  };

  handelCpcFilter = (cpcFilter: ProductCatalogueFilter) => {
    if (cpcFilter.title != null && cpcFilter.title.length >= 3) {
      this.setState({
        cpcFilter: cpcFilter,
      }, this.reloadCpcListItem);
    } else if (cpcFilter.title == null) {
      this.setState({
        cpcFilter: cpcFilter,
      }, this.reloadCpcListItem);
    } else {
      this.setState({
        cpcFilter: cpcFilter,
      });
    }
  };

  handelGetOrganizationByName = (name: string) => {
    this.props.getOrganizationByName(name);
  };

  render() {
    const { t } = this.props;
    const animation = mergeStyles(
      this.state.showDetails
        ? AnimationStyles.slideLeftIn400
        : AnimationStyles.slideRightIn400,
    );
    return (
      <div>
        <div className={'ms-Grid'} dir="ltr">
          <div className={'ms-Grid'} style={{ width: '100%', height: '100%' }}>
            <div className="ms-Grid-row">

              <div
                className={
                  this.state.showDetails
                    ? `ms-Grid-col ms-sm6 ms-md6 ms-lg6 padding-0 `
                    : `ms-Grid-col ms-sm12 ms-md12 ms-lg12 padding-0 `
                }
              >
                <Stack horizontal>
                  <ProductCatalogueShortCutPane
                    shortCutPaneFilters={this.props.shortCutPaneFilters}
                    cpcFilter={this.state.cpcFilter}
                    handelCpcFilter={(cpcFilter: ProductCatalogueFilter) => this.handelCpcFilter(cpcFilter)}
                  />

                  <div className={this.state.activeClass}>
                    <Stack
                      className="project-list-header right-panel"
                      style={{ backgroundColor: 'rgb(255, 255, 255)', paddingRight: 0 }}
                      styles={{ root: { width: '100%', paddingRight: 0 } }}
                    >
                      <TitlePane
                        headerTitle={this.state.cpcListPaneTitle}
                        isOldItem={true}
                      />
                      <CpcCommandBarLeft
                        createNewProductCatalogue={(create: boolean) =>
                          this.createNewProductCatalogue(create)
                        }
                        exportExcel={() =>
                          this.exportExcel()
                        }
                        handleRemoveCpc={() =>
                          this._handleRemoveCpc()
                        }
                        handleCloneCpcItem={() =>
                          this.handleCloneCpcItem()
                        }
                        isClone={this.state.isClone}
                        cpcListItem={this.props.cpcListItem}
                        excelData={this.props.excelData}
                        selectedCpcItemIds={this.state.selectedCpcItemIds}
                      />
                      <ProgressBar show={this.state.showProgressBar}/>
                    </Stack>

                    <Stack style={{ alignSelf: 'center' }}>
                      <ProductCatalogueListPane
                        loadMsg={this.props.loadMsg}
                        resourceTypes={this.props.resourceTypeFilter}
                        cpcFilter={this.state.cpcFilter}
                        cpcListItem={this.props.cpcListItem}
                        isDataLoaded={this.props.isDataLoaded}
                        isChange={this.props.isChange}
                        productCatalogue={this.state.productCatalogue}
                        reloadCpcListItem={() => this.reloadCpcListItem()}
                        handelCpcFilter={(cpcFilter: ProductCatalogueFilter) => this.handelCpcFilter(cpcFilter)}
                        handleSelectedCpcItem={(selectedCpcItemIds: [] | string[]) => {
                          this.handleSelectedCpcItem(selectedCpcItemIds);
                        }}
                      />
                      {/*LIST PANE*/}
                    </Stack>
                  </div>
                </Stack>
              </div>

              <div
                className={`ms-Grid-col ms-sm12 ms-md6 padding-left-0 ${animation}`}
                style={{
                  display: this.state.showDetails ? 'block' : 'none',
                  transition: 'all 0.9s linear',
                }}
              >
                {!this.state.showPanel && (
                  <div>{this.getDocumentPaneView()}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Panel
          isOpen={this.state.showPanel}
          type={PanelType.smallFixedFar}
          onDismiss={this._hidePanel}
          isLightDismiss={true}
          headerText={t('productCatalogue')}
          className="custom-detail-panel"
          closeButtonAriaLabel={t('close')}
        >
          {this.getDocumentPaneView()}
        </Panel>

        <ConfirmationDialog
          hidden={this.state.hiddenRemoveDialog}
          title={t('areyousure')}
          subText={t('You won\'t be able to revert this!')}
          onClickConfirmDialog={this.onRemoveConfirm}
          onClickCancelDialog={this.onRemoveCancel}
          cancelButtonText={t('No, I won\'t!')}
          confirmButtonText={t('Yes, delete it!')}

        />
      </div>
    );
  }

  private _showPanel = () => {
    if (this.state.width <= 1100) {
      this.setState({ showPanel: true });
    }
  };

  private _hidePanel = () => {
    this.setState({ showPanel: false, showDetails: false });
    if (getContractingUnit() && !getProject()) {
      history.push(`/CU/${getContractingUnit()}/product-catalogue`);
    } else if (getContractingUnit() && getProject()) {
      history.push(`/CU/${getContractingUnit()}/project/${getProject()}/product-catalogue`);
    } else {
      history.push(`/product-catalogue`);
    }
  };

  private _handleClick(): void {
    if (this.state.headerFilter == false) {
      this.setState({
        activeClass: 'grid-righter column-filter-activated',
        headerFilter: true,
      });
    } else {
      this.setState({ activeClass: 'grid-righter', headerFilter: false });
    }
  }

  private _toggleBookmark = (): void => {
    this.setState({ bookmarkDivClass: 'bookmark-panel parent panel-on' });
  };

  private _toggleBookmarkOut = (): void => {
    this.setState({ bookmarkDivClass: 'bookmark-panel parent' });
  };

  private openNickNameModel = (): void => {
    this.setState({ isOpenNickNameModel: !this.state.isOpenNickNameModel });
  };

  private _handleRemoveCpc = () => {
    this.setState({
      hiddenRemoveDialog: false,
    });
  };

  private onRemoveConfirm = () => {
    this.setState({
      hiddenRemoveDialog: true,
    });
    this.props.deleteProductCatalogue(this.state.selectedCpcItemIds);
    this.createNewProductCatalogue(true);
    if (getContractingUnit() && !getProject()) {
      history.push(`/CU/${getContractingUnit()}/product-catalogue/new`);
    } else if (getContractingUnit() && getProject()) {
      history.push(`/CU/${getContractingUnit()}/project/${getProject()}/product-catalogue/new`);
    } else {
      history.push(`/product-catalogue/new`);
    }
  };
  private onRemoveCancel = () => {
    this.setState({
      hiddenRemoveDialog: true,
    });
  };

  private exportExcel = (): void => {
    this.setState({
      showProgressBar: true,
    });
    if (this.props.excelData.length !== 0) {
      excelExport(
        this.props.excelData,
        this.props.t('productCatalogue'),
        this.props.t('productCatalogue'),
      );
      this.setState({
        showProgressBar: false,
      });
    } else {
      this.props.getExcelData();
    }
  };

}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    cpcListItem: state.productCatalogue.cpcListItem,
    uploadedImages: state.productCatalogue.uploadedImages,
    resourceTypes: state.productCatalogue.resourceTypes,
    resourceTypeFilter: state.productCatalogue.resourceTypeFilter,
    resourceFamily: state.productCatalogue.resourceFamily,
    cpcBasicUnitOfMeasure: state.productCatalogue.cpcBasicUnitOfMeasure,
    cpcPressureClass: state.productCatalogue.cpcPressureClass,
    cpcMaterial: state.productCatalogue.cpcMaterial,
    cpcBrand: state.productCatalogue.cpcBrand,
    languageCodes: state.productCatalogue.languageCodes,
    productCatalogue: state.productCatalogue.productCatalogue,
    autoSave: state.productCatalogue.autoSave,
    isDataLoaded: state.productCatalogue.isDataLoaded,
    isChange: state.productCatalogue.isChange,
    shortCutPaneFilters: state.productCatalogue.shortCutPaneFilters,
    loadMsg: state.productCatalogue.loadMsg,
    organizations: state.productCatalogue.organizations,
    cpcUnitOfSizeMeasure: state.productCatalogue.cpcUnitOfSizeMeasure,
    excelData: state.productCatalogue.excelData,
    isCreateNewCpc: state.productCatalogue.isCreateNewCpc,
    showProgressBar: state.productCatalogue.showProgressBar,
    vehicleTrackingNumberList: state.productCatalogue.vehicleTrackingNumberList,
  };
};

const mapDispatchToProps = {
  getAllProductCatalogue,
  savePicturesOfTheInstallation,
  getDropdownData,
  saveProductCatalogue,
  getProductCatalogueById,
  newProductCatalogue,
  deleteProductCatalogueNickName,
  deleteProductCatalogueVendor,
  deleteProductCatalogueImage,
  getCpcShortcutPaneFilterList,
  getOrganizationByName,
  getCpcResourceFamily,
  getExcelData,
  deleteProductCatalogue,
  getVehicleList,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(ProductCatalogueMainLayout)),
);

import {
  ActionButton,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Stack,
} from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../theme';
import QRCode from 'qrcode.react';

const theme = getTheme();
const QrCodeBar = (props: { value: string; name: string | null }) => {
  const { t } = useTranslation();
  const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10, marginTop: 40 } };

  const stackItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 30,
      width: 0,
      flexGrow: 1,
    },
  };

  const stackItemStylesQr: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 30,
      width: 0,
      flexGrow: 1,
      justifyContent: 'flex-end',
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
  };

  const iconButtonStyles = {
    root: { color: uPrinceTheme.palette.themePrimary },
    rootHovered: { color: theme.palette.neutralDark },
  };

  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
    fullWidth: { width: '100%' },
    halfWidth: { width: '49%' },
  });

  const downloadIcon: IIconProps = {
    iconName: 'Download',
    styles: iconButtonStyles,
  };

  const downloadQR = () => {
    if (props.name) {
      const codeName = props.name;
      const canvas: any = document.getElementById(codeName);
      if (canvas) {
        const pngUrl = canvas
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream');
        const downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = `${codeName}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    }
  };

  return (
    <div>
      <Stack
        horizontal
        // wrap
        styles={stackStyles}
        tokens={stackTokens}
      >
        <Stack.Item styles={stackItemStyles} align="start">
          <div className={`${classNames.fullWidth}`}>
            <Label>{t('qRCode')}</Label>
          </div>
        </Stack.Item>
        <Stack.Item styles={stackItemStylesQr} align="end">
          {/* <div className={ `${classNames.fullWidth}` } > */}
          <ActionButton
            text={t('download')}
            className={classNames.actionButton}
            iconProps={downloadIcon}
            allowDisabledFocus
            onClick={() => {
              downloadQR();
            }}
          />
          {/* </div> */}
        </Stack.Item>
      </Stack>
      <div style={{ paddingLeft: 10 }}>
        <QRCode
          id={props.name ? props.name : ''}
          value={`{id:${props.value}}`}
          style={{ marginTop: 1, marginLeft: 5 }}
          size={75}
        />
      </div>
    </div>
  );
};

export default QrCodeBar;

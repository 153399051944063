import React from 'react';
import {mergeStyleSets} from '@fluentui/react';

const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 14vh)!important',
    position: 'relative'
  },
  selected: {
    fontWeight: 'bold',
    color: '#854114!important'
  },
  hr: {
    marginBottom: 15,
    borderTop: '1px solid #d2d2d2'
  },
  fullWidth: {width: '100%'},
  halfWidth: {width: '49%'}
});

const ResourceShortcutPane = () => {


  return <div>
    <div className={`projectDayPlanning  filter-panel-left ${classNames.wrapper}`}>
      <div className="inner-container">

      </div>
    </div>
  </div>
};

export default ResourceShortcutPane;
import { DirectionalHint, Label, Link, Stack, Text, TooltipHost } from '@fluentui/react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { iconColors, uPrinceTheme } from '../../../../theme';
import { POShortCutPaneItem } from '../../../types/purchaseOrder';
import { VPShortCutPaneItem } from '../../../types/visualPlan';
import { saveVPStateAttr } from '../../../reducers/visualPlanReducer';
import { RESOURCE_TYPE_IDS } from '../../../shared/util';

const classNames = mergeStyleSets({
  wrapper: {
    height: 'auto !important',
    position: 'relative',
    minHeight: '400px !important',
  },
  selected: {
    fontWeight: 'bold',
    color: '#854114!important',
  },
});

const allFilterItem: POShortCutPaneItem = {
  id: null,
  name: null,
  type: null,
  value: null,
};
export const ShortCutPaneComponent = (props: {
  toggleOverlay: () => void;
  toggleOverlayFwd: () => void;
  divClass: string;
  shortCutPaneFilters: any;
  currentActiveSection: string | null;
  handelShortCutPaneFilter: (selectedItem: VPShortCutPaneItem) => void;
  resetFilter: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedResourceTypeOrg: string = useSelector(
    (state: any) => state.vp.selectedResourceTypeOrg,
  );
  const setFilterOption = (selectedItem: VPShortCutPaneItem) => {
    if (selectedItem.type === 'all') {
      props.resetFilter();
    }
    dispatch(saveVPStateAttr('selectedPOVPshortcutpaneItems', [selectedItem.key]));
    props.handelShortCutPaneFilter(selectedItem);
  };
  const renderFilterItems = () => {
    if (props.shortCutPaneFilters && props.shortCutPaneFilters.requestTypes) {
      return props.shortCutPaneFilters?.requestTypes?.map((item: any, index: number) => {
        let divider = false;
        // if (index > 0) {
        //     const previousItem: ShortCutPaneItem =
        //       props.shortCutPaneFilters[index - 1];
        //     if (previousItem.type !== item.type) {
        //         divider = true;
        //     } else {
        //         divider = false;
        //     }
        // } else {
        //     divider = true;
        // }

        return (
          <div key={item.key + 'div'}>
            {divider && (
              <hr
                key={item.key + 'hr'}
                style={{ marginBottom: 15, borderTop: '1px solid #d2d2d2' }}
              />
            )}
            <Link
              key={item.key + 'link'}
              className={`${props.currentActiveSection === item.key
                ? classNames.selected
                : ''
              }`}
              href="#"
              onClick={() => {
                setFilterOption(item);
              }}
            >
              <TooltipHost
                key={item.key + 't'}
                content={item.name}
                id="filter-opt-1"
                calloutProps={{ gapSpace: 0 }}
                directionalHint={DirectionalHint.rightCenter}
              >
                <i
                  key={item.id + 'i'}
                  className="ms-Icon ms-Icon--GotoToday"
                  aria-labelledby="filter-opt-1"
                  aria-hidden="true"
                  style={
                    props.currentActiveSection === item.key
                      ? { color: iconColors.iconActiveColor }
                      : { color: uPrinceTheme.palette.themePrimary }
                  }
                ></i>
              </TooltipHost>
              <i
                key={item.id + 'i2'}
                className="filter-icon ms-Icon ms-Icon--GotoToday filter-icon"
                aria-hidden="true"
                style={
                  props.currentActiveSection === item.key
                    ? { color: iconColors.iconActiveColor }
                    : { color: uPrinceTheme.palette.themePrimary }
                }
              ></i>
              <Text key={item.key}>{item.text} </Text>
            </Link>
          </div>
        );
      });
    }
    return <div></div>;
  };

  return (
    <div className={`${props.divClass} ${classNames.wrapper}`}>
      <div className="inner-container">
        <i
          onClick={props.toggleOverlay}
          className="ms-Icon ms-Icon--Back back-arrow"
          aria-hidden="true"
        ></i>
        <i
          onClick={props.toggleOverlayFwd}
          className="ms-Icon ms-Icon--Forward fwd-arrow"
          aria-hidden="true"
        ></i>

        <div className="filter-data filter-title">
          <TooltipHost
            content={selectedResourceTypeOrg === RESOURCE_TYPE_IDS.LABOURS ? t('organisation') : t('warehouse')}
            // This is the important part!
            id="filter-opt-title"
            calloutProps={{ gapSpace: 0 }}
            directionalHint={DirectionalHint.rightCenter}
          >
            <Label
              aria-describedby={'filter-opt-title'}
              className={'short-cut-pane-label'}
            >
              {selectedResourceTypeOrg === RESOURCE_TYPE_IDS.LABOURS ? t('organisation') : t('warehouse')}
            </Label>
          </TooltipHost>
        </div>

        <div className="filter-option-list">
          <Stack
            gap={15}
            verticalFill
            styles={{
              root: {
                width: '100%',
                verticalAlign: 'center',
              },
            }}
          >
            {/* <Link
                            key={ 'All-link' }
                            className={ `${props.currentActiveSection === null ? classNames.selected : ''
                            }` }
                            href="#"
                            onClick={ () => {

                            } }
                        >
                            <TooltipHost
                                key={ 'All-link1' }
                                content={ t('PO') }
                                id="filter-opt-1"
                                calloutProps={ { gapSpace: 0 } }
                                directionalHint={ DirectionalHint.rightCenter }
                            >
                                <i
                                    key={ 'All-linki' }
                                    className="ms-Icon ms-Icon--ViewAll"
                                    aria-labelledby="filter-opt-1"
                                    aria-hidden="true"
                                    style={
                                        props.currentActiveSection === null
                                            ? { color: iconColors.iconActiveColor }
                                            : { color: uPrinceTheme.palette.themePrimary }
                                    }
                                ></i>
                            </TooltipHost>
                            <i
                                key={ 'All-linki2' }
                                className="filter-icon ms-Icon ms-Icon--ViewAll filter-icon"
                                aria-hidden="true"
                                style={
                                    props.currentActiveSection === null
                                        ? { color: iconColors.iconActiveColor }
                                        : { color: uPrinceTheme.palette.themePrimary }
                                }
                            ></i>
                            <Text key="all"> { t('all') }</Text>
                        </Link> */}
            {renderFilterItems()}
          </Stack>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShortCutPaneComponent);

import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Caption1,
  Card,
  CardHeader,
  CardPreview,
  Overflow,
  Text,
  tokens,
} from '@fluentui/react-components';
import React from 'react';
import moment from 'moment';
import _ from 'lodash';

interface Props {
  conversations: any[];
}

export default function ConversationTaskComponent(props: Props) {
  const { conversations } = props;

  return (
    <Overflow overflowAxis='vertical'>
      {conversations && <div>
        <Accordion collapsible style={{ width: '100%' }}>
          {conversations?.map((conversation: any) => (
            <Card appearance='subtle'>
              <AccordionItem key={conversation?.id} value={conversation?.id}>
                <AccordionHeader style={{ fontSize: 24, flexDirection: 'row', flex: 1 }}>
                  <CardHeader
                    style={{ width: '100%' }}
                    header={<Text weight='semibold'>
                      {`${conversation?.subject?.slice(0, 50)}${conversation?.subject?.length > 50 ? '...' : ''}`}
                    </Text>}
                    description={
                      <div>
                        <Caption1 style={{ color: tokens.colorNeutralForeground3 }}>
                          From: {conversation?.fromPersonName}
                        </Caption1>
                        <div style={{ display: 'flex', gap: 10 }}>
                          {!_.isEmpty(conversation?.toPerson) &&
                            <Caption1 style={{ color: tokens.colorNeutralForeground3 }}>
                              To: {conversation?.toPerson?.map((item: any) => item?.personName).join(', ')}
                            </Caption1>}
                          {!_.isEmpty(conversation?.cc) && <Caption1 style={{ color: tokens.colorNeutralForeground3 }}>
                            CC: {conversation?.cc?.map((item: any) => item?.personName).join(', ')}
                          </Caption1>}
                        </div>
                      </div>
                    }
                    action={
                      //  this div is added because we cannot access CardHeader  action styles.
                      <div style={{
                        width: 'max-content',
                        height: '42px',
                        display: 'flex',
                        alignItems: 'flex-end',
                      }}>
                        <Caption1 style={{ color: tokens.colorNeutralForeground3 }}>
                          {moment(conversation?.dateTime).format('ddd DD/MM/YYYY hh:ss A')}
                        </Caption1>
                      </div>
                    }
                  />
                </AccordionHeader>
                <AccordionPanel>
                  <CardPreview
                    // logo={<Mail24Regular />}
                    style={{ padding: '10px 40px', height: 150, overflow: 'overlay' }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: conversation?.content }} />
                  </CardPreview>
                </AccordionPanel>
              </AccordionItem>
            </Card>
          ))}
        </Accordion>
      </div>}
    </Overflow>
  );
}

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContextualMenu,
  FontWeights,
  getTheme,
  IDragOptions,
  IIconProps,
  Label,
  mergeStyleSets,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../../theme';
import { useBoolean, useId } from '@uifabric/react-hooks';
import BreadcrumbBar from '../../../shared/breadcrumbBar/breadcrumbBar';
import { getBreadcrumbItemFromData } from '../../../shared/util';


const TaxonomyField = (props: {
  treeData: any;
  selectItemId: string | null;
  label?: string | null;
}) => {
  const { t } = useTranslation();
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(true);
  const [isOpen, setIsOpen] = useState(false);
  const titleId = useId('title');
  const theme = getTheme();
  const dragOptions: IDragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
  };
  const cancelIcon: IIconProps = { iconName: 'Cancel' };
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    actionButtonLabel: {
      color: uPrinceTheme.palette.themePrimary,
    },
    header: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '2px 2px 2px 10px',
        backgroundColor: uPrinceTheme.palette.themePrimary,
        fontSize: 18,
        color: 'white',
      },
    ],
    footer: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '0px 24px 14px 24px',
        textAlign: 'end',
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 0px 24px',
      overflowY: 'hidden',
      paddingTop: 20,
      minWidth: screen.width > 1280 ? '40vw' : '50vw',
      minHeight: screen.width > 1280 ? '60vh' : '75vh',
      height: screen.width > 1280 ? '80vh' : '75vh',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
    subHeader: {
      flex: '1 1 auto',
      display: 'none',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      fontSize: 14,
      color: uPrinceTheme.palette.themePrimary,
      backgroundColor: '#FFF7F4',
      height: 40,
      marginLeft: 'auto',
      marginRight: '2px',
    },
  });
  const cancelIconButtonStyles = {
    root: {
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
      color: 'white',
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };
  const iconButtonStyles = {
    root: {
      color: uPrinceTheme.palette.themePrimary,
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };
  const whiteIconButtonStyles = {
    root: {
      color: uPrinceTheme.palette.white,
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };
  const cancelIconWhite: IIconProps = {
    iconName: 'Cancel',
    styles: whiteIconButtonStyles,
  };
  const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };
  const editIcon: IIconProps = { iconName: 'Edit', styles: iconButtonStyles };
  const classNames = mergeStyleSets({
    wrapper: {
      //height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
    },
    pane: {
      maxWidth: 400,
    },
    textContent: {
      padding: '15px 10px',
    },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: {
        '@media(max-width: 1100px)': {
          textAlign: 'start',
        },
      },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
  });


  return (
    <div className={''} style={{ paddingTop: 10 }}>
      <div className={'ms-Grid'}>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
            <Label>{props.label}</Label>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
            <BreadcrumbBar items={getBreadcrumbItemFromData(props.treeData, props.selectItemId)}/>
          </div>
        </div>
      </div>
    </div>
  );
};


export default TaxonomyField;

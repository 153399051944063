import {
    getTheme,
    IIconProps,
    IStackItemStyles,
    IStackStyles,
    IStackTokens,
    Label,
    Link,
    mergeStyleSets,
    Separator,
    Stack
} from '@fluentui/react';
import React, {useEffect, useState} from 'react';
import {Field, Form} from 'react-final-form';
import {useTranslation} from 'react-i18next';
import {uPrinceTheme} from '../../../../theme';
import {messageService} from '../../../services/messageService';
import HistoryBar from '../../../shared/historyBar/historyBar';
import {OSDetails} from '../../../types/organisationSetting';
import CustomSortableTree from '../taxonomy/customSortableTree';


const theme = getTheme();

const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

const stackItemStyles: IStackItemStyles = {
    root: {
        display: 'flex',
        height: 50,
        width: 0,
        flexGrow: 1
    }
};

const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10
};

const itemAlignmentsStackTokens: IStackTokens = {
    childrenGap: 3,
    padding: 10
};

const iconButtonStyles = {
    root: { color: uPrinceTheme.palette.themePrimary },
    rootHovered: { color: theme.palette.neutralDark }
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };

const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const classNames = mergeStyleSets({
    wrapper: {
        // height: '100vh',
        position: 'relative',
        maxHeight: 'inherit'
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
        padding: 5,
        cursor: 'pointer'
    },
    labelRightWrapper: {
        textAlign: 'end',
        selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } }
    },
    actionButton: {
        color: uPrinceTheme.palette.themePrimary,
        cursor: 'pointer'
    },
    labelRightIcon: {
        display: 'inline-block',
        verticalAlign: 'bottom',
        paddingBottom: 2,
        paddingRight: 5
    },
    fullWidth: { width: '100%' },
    halfWidth: { width: '49%' }
});

// const required = (value: any) => (value ? undefined : 'Required');

const validate = (values: any) => {

};

const DocumentPaneComponent = (props: {
    personRoleDropdowns: any;
    submit: any;
    // dropDowns:OrganisationSettingDropDowns;
    saveFormData:any;
    uid:any;
    showProgress:boolean;
    formValues:any;
    formData:any;
    isClear:boolean;
    isExpand: boolean;
    setForm:(form:any) => void;
    orgTaxonomyLevels:any[];
    selectedOrgSetting:any;
}) => {
    const { t } = useTranslation();
    const [ state, setstate ] = useState<OSDetails|null>(null);
    const [ searchString, setSearchString ]:any = useState(null);


    const onSubmit = async(values: any) => {
        if (!values.id) {
            values.id = props.uid;
        }
        props.saveFormData(values);
    };

    const onCreateNode = (newNode: any) => {
        messageService.sendMessage({ createNewNode: newNode });
    };

    const onAddNode = (selectedLevel: any) => {
        messageService.sendMessage({ selectedNodeLevel: selectedLevel });
    };


    useEffect(() => {
        setstate(props.formData);
    }, [ props.formData ]);

    useEffect(() => {
        let title=props.selectedOrgSetting?.organization?props.selectedOrgSetting.organization?.title:props.selectedOrgSetting?.title
        setSearchString(title);
    }, [ props.selectedOrgSetting ]);

    const getInitialFormValues = (formData: any, formValue: any) => {
        if (location.pathname.split('/').pop() === 'new' && props.isClear) {
            return { name: null };
        } else if (formData && formData?.id) {
            return {
                id: formData?.id,
                name: formData?.name,
                sequenceId: formData?.sequenceId,
                title: formData?.title,
                organizationTaxonomyId: formData?.organizationTaxonomyId
            };
        }
        return formValue;
    };

    return (
        <div style={ { width: '100%' } }>
            <div className="proj-detail-block">
                <Form
                    onSubmit={ onSubmit }
                    initialValues={ getInitialFormValues(props.formData, props.formValues) }
                    validate={ (values) => {
                        const errors: any = {};
                        if (!values?.name && !props.showProgress) {
                            errors.name = t('nameRequired');
                        }
                        return errors;
                    } }
                    render={ ({
                        handleSubmit,
                        form,
                        values
                    }) => {
                        props.submit(handleSubmit);
                        props.setForm(form);
                        return (
                            <form onSubmit={ handleSubmit } noValidate>
                                <div className="document-pane-card">
                                    <div className={ 'card-header' }>
                                        <Link href="#" id="organisation-taxonomy">
                                            <Label>1. { t('organisationSettingHeader') }</Label>
                                        </Link>
                                    </div>

                                    <Stack
                                        horizontal
                                        wrap
                                        styles={ stackStyles }
                                        tokens={ stackTokens }
                                    >
                                        <div className={ `${classNames.fullWidth} orgSetting` }>
                                            <div style={ { zIndex: 10000000 } }>
                                                <Field name="organizationTaxonomyId">
                                                    { ({ input, meta }) => (
                                                        <CustomSortableTree
                                                            label={ '' }
                                                            // treeData={ props.dropDowns.osList }
                                                            selectItemId={ values && values.organizationTaxonomyId ? values.organizationTaxonomyId : null }
                                                            onSelectItem={ (id: string) => {
                                                                if (id) {
                                                                    if (id === props.formData.organizationTaxonomyId) {
                                                                        input.onChange(null);
                                                                    } else {
                                                                        input.onChange(id);
                                                                    }
                                                                } else {
                                                                    input.onChange(null);
                                                                }
                                                            } }
                                                            treeLevel={ props.orgTaxonomyLevels }
                                                            onCreateNode={ (newNode:any) => onCreateNode(newNode) }
                                                            onAddNode={ (selectedLevel:any) => onAddNode(selectedLevel) }
                                                            onFilterTaxonomyNodeChanged={ () => {} }
                                                            searchString={searchString}
                                                            personRoleDropdowns={ props.personRoleDropdowns }
                                                        />
                                                    ) }
                                                </Field>
                                            </div>
                                        </div>
                                    </Stack>


                                    <div className={ 'card-footer' }>
                                        <Separator />
                                        <Stack
                                            styles={ stackStyles }
                                            tokens={ itemAlignmentsStackTokens }
                                        >
                                            <Stack.Item
                                                align="end"
                                                styles={ stackFooterBarItemStyles }
                                            >
                                            </Stack.Item>
                                        </Stack>
                                    </div>
                                </div>

                                {
                                    props.formData?.history &&
                                    props.formData?.history?.createdDate && (
                                        <div className="proj-detail-block" id="3">
                                            <div className="document-pane-card">
                                                <div className="marginTop marginBottom">
                                                    <Link href="#" id="history">
                                                        <Label>3. { t('history') } </Label>
                                                    </Link>

                                                    <HistoryBar
                                                        createdByUser={
                                                            props.formData?.history?.createdBy
                                                                ? props.formData?.history?.createdBy
                                                                : ''
                                                        }
                                                        updatedByUser={
                                                            props.formData?.history?.modifiedBy
                                                                ? props.formData.history.modifiedBy
                                                                : ''
                                                        }
                                                        createdDateTime={
                                                            props.formData?.history?.createdDate
                                                                ? props.formData.history.createdDate
                                                                : ''
                                                        }
                                                        updatedDateTime={
                                                            props.formData?.history?.modifiedDate
                                                                ? props.formData?.history?.modifiedDate
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) }
                            </form>
                        );
                    } }
                />
            </div>
        </div>
    );
};
export default DocumentPaneComponent;


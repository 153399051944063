import { Modal } from 'office-ui-fabric-react';
import React, { useEffect } from 'react';
import { cancelIconButtonStyles, contentStyles } from '../WbsCreateDocument';
import { IconButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../../services/messageService';
import Input from '../../../../shared/fluentUI9/Input/Input';
import { uPrinceFluentLightTheme } from '../../../../../fluentTheme';
import { FluentProvider } from '@fluentui/react-components';
import { Button } from '../../../../shared/fluentUI9/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { readWbsCreateListData, renameWbsTemplate } from '../../../../reducers/wbsCreateReducer';
import { WbsCreateListPaneFilter } from '../../../../types/wbs';

const RenameTemplateModal = (props: { template: any }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const showWbsProgressBar = useSelector((state: any) => (state.wbsCreate.showWbsProgressBar));

  const [isOpen, setIsOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [template, setTemplate] = React.useState<any>(null);

  useEffect(() => {

    const subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.renameTemplate) {
          setIsOpen((prevState: boolean) => !prevState);
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const templateTitle = async () => {
      setTemplate(props.template);
    };

    if (props?.template?.id) {
      templateTitle().then();
    }
  }, [props.template]);

  const handleModalClose = () => {
    setIsOpen((prevState: boolean) => !prevState);
    // setTemplate(null);
  };

  const handleRename = () => {
    Promise.all([dispatch(renameWbsTemplate(template))]).then(() => {
      handleModalClose();
      dispatch(readWbsCreateListData(new WbsCreateListPaneFilter()));
    });
  };

  return (
    <Modal
      titleAriaId={'titleId'}
      isOpen={isOpen}
      onDismiss={() => handleModalClose()}
      isBlocking={true}
      dragOptions={undefined}
    >
      <FluentProvider theme={uPrinceFluentLightTheme}>
        <div className={contentStyles.header}>
          <span id={'titleId'}>{t('renameTemplate')} </span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel='Close popup modal'
            onClick={() => handleModalClose()}
          />
        </div>

        <div className={contentStyles.body}>
          <div>
            <div className='ms-Grid-row'>
              <div className='ms-Grid-col ms-sm12 ms-md12 ms-lg12'>
                <Input
                  appearance='outline'
                  value={template?.name}
                  onChange={(value: any) => setTemplate((data: any) => {
                    return { ...data, name: value };
                  })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={contentStyles.footer} style={{ marginTop: 10 }}>
          <Button
            text={t('rename')}
            appearance={'primary'}
            shape={'square'}
            onClick={() => {
              handleRename();
            }}
          />
        </div>
      </FluentProvider>
    </Modal>
  );
};

export default RenameTemplateModal;
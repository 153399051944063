import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  DetailsListLayoutMode,
  DetailsRow,
  Fabric,
  IColumn,
  IDetailsFooterProps,
  IDetailsListProps,
  IDetailsRowStyles,
  Label,
  mergeStyleSets,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
  Text,
  TextField,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { AWARDED_STATUS_ID, ContractListItem, ContractListPaneFilter, CONTRACTOR } from '../../../types/contract';

export const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 25vh)!important',
    position: 'relative',
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: {
    margin: 0,
  },
  row: {
    display: 'inline-block',
  },
});

const ListPaneComponent = (props: {
  filter: ContractListPaneFilter;
  selection: any;
  columns: IColumn[];
  listPaneItems: ContractListItem[];
  handleListPaneItemClick: (id: string) => void;
  handleFilterChange: (filter: ContractListPaneFilter) => void;
  isDataLoaded?: boolean;
  loadMsg: string;
  resetSelection: () => void;
  dropDownOptions: any;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [filter, setFilter] = useState<ContractListPaneFilter>(props.filter);
  const [contractor, setContractor] = useState<any>(null);

  const { formData, isClear, contractDropDowns, reloadListPane, showProgressBar, contractId, loadMsg }: any = useSelector(
    (state: any) => state.contract,
  );

  const getDropdownWithDefualt = (options: any) => {
    if (options) {
      let defualtOption = [
        {
          key: '0',
          text: t('all'),
          isDefault: true,
        },
      ];
      Array.prototype.push.apply(defualtOption, options);
      return defualtOption;
    } else {
      return [];
    }
  };

  useEffect(() => {
    setFilter(props.filter);
    if (!props.filter.title) {
      setContractor([]);
    }
  }, [props.filter]);

  const _onRenderRow: IDetailsListProps['onRenderRow'] = (row) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (row) {
      return (
        <span
          style={{ textDecoration: 'none' }}
          onClick={() => {
            // chnage item id name
            props.handleListPaneItemClick(row.item.sequenceId);
            // dispatch(readByContractId(row.item.sequenceId));
          }}
        >
          <DetailsRow {...row} styles={customStyles}/>
        </span>
      );
    }
    return null;
  };

  const _onRenderDetailsFooter = (
    detailsFooterProps: IDetailsFooterProps | undefined,
  ) => {

    return (
      <DetailsRow
        className="footer-as-filter"
        {...detailsFooterProps}
        columns={detailsFooterProps?.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps?.groupNestingDepth}
        selection={detailsFooterProps?.selection}
        onRenderItemColumn={_renderFilterItemColumn}
        // onRenderCheck={_onRenderCheckForFooterRow}
      />
    );

  };


  const _renderFilterItemColumn = (
    item?: ContractListItem,
    index?: number,
    column?: IColumn,
  ) => {
    switch (column?.key) {
      case 'column1': {
        return (
          <div>
            <TextField
              value={filter?.title ? filter?.title.toString() : ''}
              onChange={(event, value) => {
                console.log('filter value>>>', value);
                if (value) {
                  //       setFilter((prevState) => ({
                  //     ...prevState,
                  //     title: value
                  // }));
                  props.filter.title = value;
                } else {
                  props.filter.title = null;
                }
                props.handleFilterChange(filter);
              }}
            />
          </div>
        );
        break;
      }
      // case 'column1': {
      //   return (
      //     <div>
      //       <PeoplePicker
      //           label={ null }
      //           isDisabled={ false }
      //           selectedItem={ contractor }
      //           onChange={ (items:any) => {
      //               if (items && _.isArray(items) && items.length === 0) {
      //                   setContractor([]);
      //                   props.filter.contractor = null;
      //                   props.handleFilterChange(props.filter);
      //               }else{
      //                   setContractor([]);
      //                   props.filter.contractor = null;
      //                   props.handleFilterChange(props.filter);
      //               }
      //           } }
      //           onPersonSelected={ (items:any) => {
      //               if (items) {
      //                   props.filter.contractor = items.key;
      //                   setContractor([ { key: items.key, text: items.text } ]);
      //               } else {
      //                   props.filter.contractor = null;
      //                   setContractor([]);
      //               }
      //               props.handleFilterChange(props.filter);
      //               return items;
      //           } }
      //       />
      //     </div>
      //   );
      //   break;
      // }
      // case 'column2': {
      //   return (
      //     <div>
      //       <CustomDatePicker
      //         setMinDate={null}
      //         setMaxDate={null}
      //         setValue={formatDate(props.filter.dateOfStatusChange, 1)}
      //         getValue={(date: Date) => {
      //           // onChangeEndDateTime(formatDate(date, 2));
      //           if (date) {
      //             props.filter.dateOfStatusChange = formatDate(date, 2);
      //           } else {
      //             props.filter.dateOfStatusChange = null;
      //           }
      //           props.handleFilterChange(props.filter);
      //         }}
      //       />
      //     </div>
      //   );
      //   break;
      // }
      // case 'column3': {
      //   return (
      //     <div>
      //       <Dropdown
      //         placeholder={t('status')}
      //         options={contractDropDowns.status}
      //         selectedKey={props.filter?.statusId ? props.filter?.statusId : '0'}
      //         onChange={(event, value) => {
      //           if (value) {
      //             props.filter.statusId =
      //               value.key != 0 ? value.key.toString() : null;
      //           } else {
      //             props.filter.statusId = null;
      //           }
      //           props.handleFilterChange(props.filter);
      //         }}
      //       />
      //     </div>
      //   );
      //   break;
      // }
      // case 'column4': {
      //   return (
      //     <div>
      //       <TextField
      //           value={ props.filter?.price ? props.filter?.price.toString() : '' }
      //           onChange={ (event, value) => {
      //               if (value) {
      //                   props.filter.price = parseInt(value, 10);
      //               } else {
      //                   props.filter.price = null;
      //               }
      //               props.handleFilterChange(props.filter);
      //           } }
      //       />
      //     </div>
      //   );
      //   break;
      // }
      default: {
        break;
      }
    }
  };

  const tableColumn = (item: any, index?: number, column?: IColumn) => {
    if (column && column.fieldName && column.fieldName === 'title' && item?.type != CONTRACTOR) {
      return <Label > &#62; {item[column.fieldName]}</Label>;
    } else if (column && column.fieldName && item?.type === CONTRACTOR && item?.statusId!=AWARDED_STATUS_ID) {
      const fieldContent = item[column.fieldName];
      return <span  style={{ paddingLeft: 2,color:'#8d8d8d' }} onClick={() => {

      }}>{fieldContent}</span>;
    } else if (column && column.fieldName) {
      const fieldContent = item[column.fieldName];
      return <span  style={{ paddingLeft: 2 }} onClick={() => {

      }}>{fieldContent}</span>;
    } else {
      return <span style={{ paddingLeft: 9 }}>-</span>;
    }
  };


  const displayMessage = () => {
    return (
      <div style={{ paddingTop: 36, paddingLeft: 20 }}>
        <Text>{t(loadMsg)}</Text>
      </div>
    );
  };
  return (
    <Fabric>
      <div className={`wrapper-holder ${classNames.wrapper}`}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <ShimmeredDetailsList
            items={props.listPaneItems}
            columns={props.columns}
            setKey="set"
            enableShimmer={!props.isDataLoaded}
            layoutMode={DetailsListLayoutMode.justified}
            selectionPreservedOnEmptyClick={true}
            checkboxVisibility={1}
            onRenderRow={_onRenderRow}
            onRenderDetailsFooter={_onRenderDetailsFooter}
            selection={props.selection}
            selectionMode={SelectionMode.single}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            onRenderItemColumn={(
              item: any,
              index?: number,
              column?: IColumn,
            ) =>
              tableColumn(
                item,
                index,
                column,
              )
            }
            onRenderDetailsHeader={(headerProps, defaultRender) => {
              if (defaultRender !== undefined) {
                return (
                  <Sticky
                    stickyPosition={StickyPositionType.Header}
                    isScrollSynced={true}
                    stickyBackgroundColor="transparent"
                  >
                    <div>{defaultRender(headerProps)}</div>
                  </Sticky>
                );
              } else {
                return <span></span>;
              }
            }}
            compact={true}
          />
          {props.isDataLoaded && displayMessage()}
        </ScrollablePane>
      </div>
    </Fabric>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    toleranceStatesFilter: state.projectBreakdown.toleranceStatesFilter,
    productStatesFilter: state.projectBreakdown.productStatesFilter,
    itemTypesFilter: state.projectBreakdown.itemTypesFilter,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ListPaneComponent);

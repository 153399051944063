import {
  FontWeights,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  mergeStyleSets,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import {Label} from 'office-ui-fabric-react';
import React, {useEffect, useRef} from 'react';
import {Field, Form, FormSpy} from 'react-final-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {uPrinceTheme} from '../../../../../theme';
import {readCertificationTaxanomyList} from '../../../../reducers/certificationReducer';
import {DatePickerAdapter, TextFieldFF} from '../../../../shared/fluentFinalForm';
import {formatDateTimeToISOString} from '../../../../shared/util';
import CertificationTaxonomy from '../../taxonomy/certificationTaxonomy';
import {v4 as uuidv4} from 'uuid';
import {uploadInstructionDoc} from '../../../../reducers/contractReducer';

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '40vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const addWhiteIconButtonStyles = {
  root: { color: uPrinceTheme.palette.white },
  rootHovered: { color: theme.palette.neutralDark },
};
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
});

const uploadIconWhite: IIconProps = {
  iconName: 'Upload',
  styles: addWhiteIconButtonStyles,
};

const CIAWModalContent = (props: {
  modalData: any;
  disabled: boolean;
  onChange: any;
  dataList: any[];
  lotContractorId: any;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputFile: any = useRef(null);

  const { certificationTaxonomyList }: any = useSelector(
    (state: any) => state.cert
  );

  useEffect(() => {
    dispatch(readCertificationTaxanomyList());

    return () => {

    }
  }, [])

  const onSubmit = async (values: any) => {
    if (!values?.id) {
      // values.isManual = true;
    }
    props.onChange(values);
  };

  const getInitialvalues = (modalData: any) => {
    if (modalData) {
      return {
        ...modalData,
        certificationType: modalData?.certificationTaxonomyId,
        expireDate: modalData?.endDate,
      };
    }
    return {
      validity: false,
      certificationTitle: null,
      certificationType: null,
      startDate: new Date(),
      expireDate: new Date(),
    };
  };

  return (
    <div className={contentStyles.body}>
      <div className="proj-detail-content inner">
        <div className="ms-Grid-row">
          <Form
            onSubmit={onSubmit}
            initialValues={getInitialvalues(props.modalData)}
            mutators={{
              setCertificationUrl: (args, state, utils) => {
                const field = state.fields.certificationUrl;
                field.change(args[0]);
              },
            }}
            validate={(values) => {
              const errors: any = {};
              // if (!values?.cabPersonId) {
              //   errors.cabPersonId = t('personRequired');
              // }
              // if (Array.isArray(props.dataList) && !values.id) {
              //   const index = props.dataList.findIndex((object) => {
              //     return object.cabPersonId === values.cabPersonId;
              //   });
              //   if (index !== -1) {
              //     errors.cabPersonId = t('alreadyAdded');
              //   }
              // }
              return errors;
            }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <div>
                <FormSpy
                  onChange={(spyProps: any) => {
                    if (props.modalData?.isAccessGranted !== spyProps.values?.isAccessGranted && props.onChange && spyProps.values?.isAccessGranted) {
                      // form.mutators.setBussinessEmail(values.tempEmail);
                    }
                  }}
                />

                <Stack
                  horizontal
                  wrap
                  styles={stackStyles}
                  tokens={stackTokens}
                >
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <Field name="certificationType">
                        {({ input, meta }) => (
                          <CertificationTaxonomy
                            label={t('certificationType')}
                            treeData={certificationTaxonomyList}
                            selectItemId={values?.certificationType}
                            onChange={(selectedNode: any) => {
                              if (selectedNode?.id) {
                                input.onChange(selectedNode?.id);
                                //   if (selectedNode.id == props.productCatalogue.resourceFamilyId) {
                                //     props.productCatalogue.resourceFamilyId = null;
                                //   } else {
                                //     props.productCatalogue.resourceFamilyId = selectedNode.id;
                                //   }
                                // } else {
                                //   props.productCatalogue.resourceFamilyId = null;
                              }
                            }}
                            disabled={true}
                          />
                        )}
                      </Field>
                    </div>
                  </Stack.Item>
                </Stack>

                <Stack
                  horizontal
                  wrap
                  styles={stackStyles}
                  tokens={stackTokens}
                >
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.halfWidth}`}>
                      <Field
                        name="certificationTitle"
                        component={TextFieldFF}
                        lable={t('certificationTitle')}
                        disabled={false}
                      />
                    </div>
                  </Stack.Item>
                </Stack>

                <Stack
                  horizontal
                  wrap
                  styles={stackStyles}
                  tokens={stackTokens}
                >
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'row' }}>
                        <Field
                          name="certificationUrl"
                          component={TextFieldFF}
                          lable={t('certificationUrl')}
                          disabled={false}
                          placeholder={t('certificationUrl')}
                          required={false}
                          readOnly={true}
                          className={`${classNames.fullWidth}`}
                        />
                        <input
                          type="file"
                          id="file"
                          ref={inputFile}
                          hidden
                          onChange={(event: any) => {
                            const formData = new FormData();
                            if (event?.target?.files[0]) {
                              formData.append('file', event.target.files[0]);
                              formData.append('id', uuidv4());
                              Promise.all([
                                dispatch(uploadInstructionDoc(formData))
                              ]).then((response: any) => {
                                if (response[0]?.payload?.data?.result) {
                                  form.mutators.setCertificationUrl(response[0]?.payload?.data?.result);
                                } else {
                                  form.mutators.setCertificationUrl(null);
                                }
                              });
                            }
                          }}
                        />

                        <PrimaryButton
                          iconProps={uploadIconWhite}
                          text={t('upload')}
                          disabled={false}
                          style={{ marginTop: 10, marginLeft: 10 }}
                          onClick={() => {
                            inputFile?.current?.click();
                          }}
                        />
                      </div>
                    </div>
                  </Stack.Item>
                </Stack>

                <Stack
                  wrap
                  styles={{ root: { padding: 0, marginTop: 30, marginLeft: 10 } }}
                >
                  <Label>{t('validityPeriod')}</Label>
                  <div style={{ height: 1, backgroundColor: '#f3ede6' }}></div>
                </Stack>
                <Stack
                  horizontal
                  wrap
                  styles={stackStyles}
                  tokens={stackTokens}
                >
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <Field
                        name="startDate"
                        component={DatePickerAdapter}
                        lable={t('startDate')}
                        parse={value => value && formatDateTimeToISOString(value)}
                        format={value => (value ? new Date(value) : null)}
                        disabled={false}
                      />
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <Field
                        name="expireDate"
                        component={DatePickerAdapter}
                        lable={t('endDate')}
                        parse={value => value && formatDateTimeToISOString(value)}
                        format={value => (value ? new Date(value) : null)}
                        disabled={false}
                        minDate={new Date(form.getFieldState('startDate')?.value)}
                      />
                    </div>
                  </Stack.Item>
                </Stack>

                <div className={contentStyles.footer}>
                  <PrimaryButton
                    iconProps={addIconWhite}
                    text={t('save')}
                    disabled={false}//submitting || pristine}
                    style={{ marginTop: 12, marginBottom: 8, marginRight: -14 }}
                    onClick={() => {
                      form.submit();
                    }}
                  />
                </div>
                {/* <pre>{JSON.stringify(values)}</pre> */}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default CIAWModalContent;
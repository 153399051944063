import { ActionButtonState } from '../../../types/corporateProductCatalogue';
import {
  ActionButton,
  DetailsList,
  DetailsListLayoutMode,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  SelectionMode,
  Stack,
  Sticky,
  StickyPositionType,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../../theme';
import React from 'react';
import Contact from '../../../types/contact';
import { useTranslation } from 'react-i18next';

const theme = getTheme();

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const ContactGrid = (props: {
  contacts: Contact[];
  editContact: () => void;
  openModel: () => void;
  contactSelection: any;
  contactSelectionDetails: {};
  contactActionButtonState: ActionButtonState;
  title: string;
  deleteContact: () => void;
  readOnly: boolean;
  valueHeader: string;

}) => {
  const removeIcon: IIconProps = {
    iconName: 'Delete',
    styles: iconButtonStyles,
  };
  const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };
  const editIcon: IIconProps = { iconName: 'Edit', styles: iconButtonStyles };
  const { t } = useTranslation();
  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
  });
  const columns = [
    {
      key: 'column1',
      name: t('Type'),
      fieldName: 'type',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: props.valueHeader,
      fieldName: 'value',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },

  ];

  const stackStyles: IStackStyles = { root: { padding: 0 } };
  const stackItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 40,
      alignItems: 'flex-start',
    },
  };

  const stackEditbarItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 45,
      alignItems: 'flex-end',
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 15,
    padding: 10,
  };

  return (
    <div style={{ padding: 12 }}>
      <div className="ms-Grid-row">
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={stackStyles}
          tokens={stackTokens}
        >
          <Stack.Item styles={stackItemStyles}>
            <Label style={{ marginTop: 10 }}>{props.title}</Label>
          </Stack.Item>
          {!props.readOnly && (
            <Stack.Item styles={stackEditbarItemStyles}>
              <ActionButton
                className={classNames.actionButton}
                disabled={props.contactActionButtonState.add}
                iconProps={addIcon}
                allowDisabledFocus
                onClick={() => {
                  props.openModel();
                }}
              >
                <Label className={classNames.actionButton}>{t('add')} </Label>
              </ActionButton>
              <ActionButton
                iconProps={editIcon}
                disabled={props.contactActionButtonState.edit}
                allowDisabledFocus
                onClick={() => {
                  props.editContact();
                }}
              >
                <Label className={classNames.actionButton}> {t('edit')}</Label>
              </ActionButton>
              <ActionButton
                iconProps={removeIcon}
                disabled={props.contactActionButtonState.remove}
                allowDisabledFocus
                onClick={() => props.deleteContact()}
              >
                <Label className={classNames.actionButton}>
                  {' '}
                  {t('remove')}
                </Label>
              </ActionButton>
            </Stack.Item>
          )}
        </Stack>
      </div>

      <div className="ms-Grid-row marginTop">
        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
          <div className={classNames.wrapper}>
            { /* <ScrollablePane>*/}
            <DetailsList
              items={props.contacts}
              columns={columns}
              setKey="set"
              selectionMode={props.readOnly ? SelectionMode.none : SelectionMode.multiple}
              layoutMode={DetailsListLayoutMode.justified}
              selectionPreservedOnEmptyClick={true}
              checkboxVisibility={1}
              selection={props.contactSelection}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
              onRenderDetailsHeader={(headerProps, defaultRender) => {
                if (defaultRender !== undefined) {
                  return (
                    <Sticky
                      stickyPosition={StickyPositionType.Header}
                      isScrollSynced={true}
                      stickyBackgroundColor="transparent"
                    >
                      <div>{defaultRender(headerProps)}</div>
                    </Sticky>
                  );
                }
                return <span></span>;
              }}
            />
            { /* </ScrollablePane>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactGrid;

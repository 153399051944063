import React, { Component } from 'react';
import LanguageMainLayout from './languageMainLayout';
import UprinceOrganizationNavigation from '../../shared/uprinceNavigation/organizationNavigation/component';

interface Props {
  currentUser: any;
  organization: any
}

interface State {
  isContractUnit: boolean
}

export class Language extends Component<Props, State> {
  render() {
    return (
      <UprinceOrganizationNavigation
        currentUser={this.props.currentUser}
        selectedNavigationIndex={'/translations-dashboard'}
      >
        <LanguageMainLayout/>
      </UprinceOrganizationNavigation>
    );
  }
}

export default Language;

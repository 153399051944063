export const LOAD_QR_CODES = 'uprince/LOAD_QR_CODES';
export const LOAD_QR_CODES_SUCCESS = 'uprince/LOAD_QR_CODES_SUCCESS';
export const LOAD_QR_CODES_FAIL = 'uprince/LOAD_QR_CODES_FAIL';

export const LOAD_QR_CODE = 'uprince/LOAD_QR_CODE';
export const LOAD_QR_CODE_SUCCESS = 'uprince/LOAD_QR_CODE_SUCCESS';
export const LOAD_QR_CODE_FAIL = 'uprince/LOAD_QR_CODE_FAIL';

export const LOAD_QR_DROPDOWNS = 'uprince/LOAD_QR_DROPDOWNS';
export const LOAD_QR_DROPDOWNS_SUCCESS = 'uprince/LOAD_QR_DROPDOWNS_SUCCESS';
export const LOAD_QR_DROPDOWNS_FAIL = 'uprince/LOAD_QR_DROPDOWNS_FAIL';

export const LOAD_CPC_VEHICLES = 'uprince/LOAD_CPC_VEHICLES';
export const LOAD_CPC_VEHICLES_SUCCESS = 'uprince/LOAD_CPC_VEHICLES_SUCCESS';
export const LOAD_CPC_VEHICLES_FAIL = 'uprince/LOAD_CPC_VEHICLES_FAIL';

export const DELETE_QR_CODE = 'uprince/DELETE_QR_CODE';
export const DELETE_QR_CODE_SUCCESS = 'uprince/DELETE_QR_CODE_SUCCESS';
export const DELETE_QR_CODE_FAIL = 'uprince/DELETE_QR_CODE_FAIL';



import React from 'react';
import {NavigateAction} from 'react-big-calendar';
import moment from 'moment';
import PmolDropBox from '../../../shared/dnd/pmolDropBox';
// import * as TdDropBox from '../../../shared/dnd/dropBox';
import {AcceptType, getPmolType} from '../../../types/myDayPlanning';
import {ICommandBarItemProps, mergeStyleSets,} from '@fluentui/react';
import i18n from '../../../../i18n';
import {clonePmolDayPlanning, saveMyDayPlanningStateAttr,} from '../../../reducers/myDayPlanningReducer';
import {connect} from 'react-redux';
import {DropBox} from '../../../shared/dnd/dropBox';
import {saveStateAttr} from '../../../reducers/uprinceReducer';
import {messageService} from '../../../services/messageService';
import EventBody from '../../projectDayPlanning/listPane/eventBody';
import './tableStyle.css';
import GridDragBox from '../../../shared/dnd/gridDragBox';
import DragBox from '../../../shared/dnd/dragBox';
import {updatePlanBoardsPmolStatus} from '../../../reducers/projectDayPlanningReducer';

const classNames = mergeStyleSets({
    tableContainer: {
        position: 'relative',
        // maxHeight: 630,
        width: 'auto',
        overflow: 'hidden',
        height: 'inherit'
    },
    weekDate: {
        zIndex: 1,
        background: 'white',
        borderTop: 'none !important',
        borderBottom: 'none !important',
        boxShadow: 'inset -1px 0px 0px #ddd, inset 1px 0px 0px #ddd',
        padding: '1 0'
    }
});

// registerIcons(SvgIcons);

interface MyWeekProps {
    date: Date
    events: any,
    resources: any,
    saveMyDayPlanningStateAttr: any,
    clonePmolDayPlanning: any,
    saveStateAttr: any,
    horizontalReSizer: any,
    updatePlanBoardsPmolStatus: any,
}

class CustomWeek extends React.Component<MyWeekProps> {
    static title = (date: Date) => {
        return `${moment(date).startOf('isoWeek').format('dddd MMM DD')}  -  ${moment(date).clone().endOf('isoWeek').format('dddd MMM DD')}`
    }

    static navigate = (date: Date, action: NavigateAction) => {
        switch (action) {
            case 'PREV':
                return moment(date).add(-3, 'day').toDate()

            case 'NEXT':
                return moment(date).add(3, 'day').toDate()

            default:
                return date
        }
    }

    static range = (date: Date) => {
        let start = date
        let end = moment(start).add(2, 'day')

        let current = start
        let range = []

        while (moment(current).isSameOrBefore(moment(end), 'day')) {
            range.push(current)
            current = moment(current).add(1, 'day').toDate()
        }

        return range
    }

    onClickPmol = (pmol: any) => {
        if (pmol.projectMoleculeId) {
            // alert('PMOL clicked')
            // if (getProject()) {
            //     history.push(`/CU/${getContractingUnit()}/project/${getProject()}/day-planning/pmol/` + pmol.projectMoleculeId);
            // } else {
            //     history.push({
            //         pathname: `/CU/${getContractingUnit()}/day-planning/pmol/` + pmol.projectMoleculeId,
            //         state: { selectedProject: pmol.projectSequenceCode },
            //     });
            // }
        }
    }

    getParentWidth = (element: string) => {
        let elem = document.getElementById(element)
        if (elem && elem.parentElement) {
            //return elem.parentElement.offsetWidth;
            return "100%"
        } else {
            return "100%"
        }
    };

    getPmol = (teamId: any, date: any) => {
        const onClickPbs = (pmol: any) => {
            if (pmol && pmol.productId) {
                const openUrl = `/CU/${pmol.contractingUinit}/project/${pmol.projectSequenceCode}/product/pbs/${pmol.productId}`;
                window.open(openUrl);
            }
        };

        let pmol = this.props.events.filter((pmol: any) => pmol.resourceId === teamId && moment(pmol.start).format('YYYYMMDD') === moment(date).format('YYYYMMDD'));

        if (pmol && pmol.length > 0) {
            return pmol.map((item: any) => {
                const _overflowItems: ICommandBarItemProps[] = [
                    {
                        key: 'openPbs',
                        text: i18n.t('openPbs'),
                        onClick: () => {
                            onClickPbs(item)
                        },
                        iconProps: {iconName: 'MoveToFolder'}
                    }
                ];

                return <div className={`customEventWrapper  ${getPmolType(item)}`} style={{margin: 5}}>
                    <div id={`${item?.projectMoleculeId + '' + teamId}`} className={`rbc-event`}>
                        {/*<div className="rbc-event-label">{this.formatPmolTime(item.start)} - {this.formatPmolTime(item.end)}</div>*/}
                        <div className="rbc-event-content" style={{paddingTop: 5, paddingBottom: 5}}>
                            <PmolDropBox
                              type={[AcceptType.TEAM, AcceptType.VEHICLE, AcceptType.TOOL]}
                              // onClick={(item: any) => this.onClickPmol(item)}
                              item={item}
                              // subItem={item?.pmolVehical}
                              // name={item?.projectSequenceCode + ' - ' + item?.title}
                              td={false}
                            >
                                <div style={{minWidth: 350, height: '100%'}} key={JSON.stringify(item.title)}>
                                    <GridDragBox item={item}>
                                        <EventBody pmol={item} overflowItems={_overflowItems} isMyCal={true}
                                                   handlePmolStatusChange={(data: any) => this.handlePmolStatusChange(data)}/>
                                    </GridDragBox>
                                </div>
                            </PmolDropBox>
                        </div>
                    </div>
                </div>;
            })
        }
    }

    formatPmolTime = (date: Date | string) => {
        return moment(date).format('HH:mm')
    }

    renderRow = (resources: any, date: any) => {
        // var currentDate = moment();
        var weekStart = moment(date).startOf('isoWeek');
        // var weekEnd = currentDate.clone().endOf('week');

        var days = [];
        for (let i = 0; i <= 6; i++) {
            days.push(moment(weekStart).add(i, 'days').format("YYYYMMDD"));
        }

        return days.map((day) => {
            return (
              <tr key={Math.random().toString()}>
                  <td className={`${classNames.weekDate}`}>{moment(day).format('dddd MMM D')}</td>
                  {resources && resources?.map((team: any) => {
                      return <DropBox
                        isTd={true}
                        item={team}
                        day={day}
                        type={AcceptType.PMOL}
                      >{this.getPmol(team.resourceId, day)}</DropBox>
                  })}
              </tr>
            );
        });
    }

    addLineBrake = (htmlString: any) => {
        const array = htmlString?.split(",");
        return array?.map((text: any) => {
            return <React.Fragment key={Math.random().toString()}>{text}<br/></React.Fragment>
        });
    }

    getTeamName = (htmlString: any) => {
        const array = htmlString?.split(",");
        return array?.pop();
    }

    getTeam = (teams: any, label: any) => {
        if (teams.length > 0) {
            let team: any = teams.find(
              (element: any) => element.resourceTitle === label
            );

            if (team) {
                return team;
            } else {
                return null;
            }
        }
        return null
    };

    getTeamMembers = (htmlString: any, resource: any) => {
        const array = htmlString?.split(",");
        return array?.map((text: any) => {
            const cabPerson = resource?.team?.find((team: any) => team?.name == text);
            const item = {
                cabPerson: cabPerson,  //needed for extract selected cabPersonId
                resource: resource
            };

            // return <React.Fragment>{text}<br /></React.Fragment>
            return <DragBox
              key={'team' + text}
              name={text}
              item={item}
              type={AcceptType.PERSON}
              style={{fontSize: 12}}
              onClick={() => {
              }}
            />
        });
    };

    handlePmolStatusChange = (data: any) => {
        this.props.updatePlanBoardsPmolStatus(data).then(() => { //this API need to be change
            messageService.sendMessage({reloadList: true});
        });
    };

    render() {
        return <div key={"topDivWeekTable" + Math.random().toString()} style={{height: 'inherit'}}>
            <div key={"weekDPTableKey" + Math.random().toString()} id={"weekDPTable"}
                 className={`dp ${classNames.tableContainer} rbc-agenda-view`}
                 style={{maxHeight: this.props.horizontalReSizer - 126}}>
                <table className="rbc-agenda-table" style={{height: 'inherit'}}>
                    <thead>
                    <tr>
                        <th style={{background: 'white', zIndex: 10, maxWidth: 80}} className="rbc-header">date</th>
                        {this.props.resources && this.props.resources?.map((team: any) => {
                            return (
                              <th id={'resource' + team?.resourceId} style={{background: 'white'}}
                                  className="rbc-header" key={Math.random().toString() + team?.resourceId}>
                                  <DropBox
                                    item={this.getTeam(this.props.resources, team.resourceTitle)}
                                    // day={null}
                                    type={[AcceptType.TEAM, AcceptType.VEHICLE, AcceptType.TOOL, AcceptType.PERSON]}
                                  >
                                      <div key={"topDivWeekTable" + team?.teamTitle}
                                           style={{
                                               minWidth: '100px',
                                               minHeight: '40px',
                                               display: 'flex',
                                               alignItems: 'flex-start'
                                           }}>
                                          <div key={"weekDPTableKey" + team?.teamTitle}
                                               style={{
                                                   padding: 6,
                                                   display: 'flex',
                                                   flexDirection: 'column',
                                                   alignItems: 'flex-start'
                                               }}>
                                              {team?.teamTitle} <br/>
                                              <span
                                                style={{fontSize: 12, fontWeight: 400}}>{team?.contractingUinit}</span>
                                              <br/>
                                          </div>
                                          <div key={'team' + team.resourceTitle}
                                               style={{
                                                   display: 'flex',
                                                   flexDirection: 'column',
                                                   marginLeft: 12
                                               }}>{this.getTeamMembers(team.resourceTitle, team)}</div>
                                      </div>
                                  </DropBox>
                              </th>
                            );
                        })}
                    </tr>
                    </thead>

                    <tbody>
                    {this.renderRow(this.props.resources, this.props.date)}
                    </tbody>
                </table>
            </div>
        </div>
    }
}

const mapStateToProps = (state: any) => {
    return {
        horizontalReSizer: state?.mydPlan?.horizontalReSizer,
    };
};

const mapDispatchToProps = {
    saveMyDayPlanningStateAttr,
    clonePmolDayPlanning,
    saveStateAttr,
    updatePlanBoardsPmolStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomWeek)

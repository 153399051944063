import React, { Component } from 'react';
import Brand from '../../brand/brand';
import MeNavBar from '../../navBar/meNavBar';
import ProjectBar from '../../projectBar/projectBar';

interface Props {
  currentUser: any;
  children: any;
  selectedNavigationIndex: string
}

interface State {
  isContractUnit: boolean;
  isProject: boolean;
}

class MeNavigation extends Component<Props, State> {
  render() {
    return (
      <div style={{ height: '100%' }}>
        <Brand/>
        <ProjectBar
          contractingUnit={this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null}/>
        <MeNavBar selectedKey={this.props.selectedNavigationIndex} currentUser={this.props.currentUser}/>
        {
          this.props.children
        }
      </div>
    );
  }
}

export default MeNavigation;

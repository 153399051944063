import React from 'react';
import {
  DetailsListLayoutMode,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  registerIcons,
  SelectionMode,
  ShimmeredDetailsList,
} from '@fluentui/react';
import i18n from '../../../i18n';
import {SvgIcons} from '../../shared/svgIcons/svgIcons';
import {store} from '../../../index';
import {formatPrice} from '../../shared/util';

registerIcons(SvgIcons);


const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const  RfqExternalProduct=()=>{
  const {rfqFormData}=store.getState()?.po;
  const classNames = mergeStyleSets({

    fullWidth: { width: '100%' },
    halfWidth: { width: '49%' },
    wrapper: {
      // height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
      minHeight: (rfqFormData.externalProduct && rfqFormData.externalProduct.length > 1) ? 200 : 110,
    },
  });
  const columns = [
    {
      key: 'column3',
      name: i18n.t('productTitle'),
      fieldName: 'title',
      isResizable: true,
    },
    // {
    //   key: 'column4',
    //   name: i18n.t('pmolTitle'),
    //   fieldName: 'headerTitle',
    //   minWidth: 150,
    //   maxWidth: 300,
    //   isResizable: true,
    // },

    // {
    //   key: 'column5',
    //   name: i18n.t('quantity'),
    //   fieldName: 'cQuantity',
    //   minWidth: 100,
    //   maxWidth: 300,
    //   isResizable: true,
    //   className: 'totalAmountCol',
    //   onRender: (item: any) => {
    //     return <div className="find me" style={{ maxWidth: 200, display: 'flex', justifyContent: 'flex-end' }}>
    //       {item?.cQuantity && <span>{formatPriceWithoutSymbol(item?.cQuantity)}</span>}
    //     </div>
    //   }
    // },

    // {
    //   key: 'column6',
    //   name: i18n.t('mou'),
    //   fieldName: 'mou',
    //
    //   isResizable: true,
    // },

    {
      key: 'column7',
      name: i18n.t('unitPrice') + ' (m'+'²)',
      fieldName: 'cUnitPrice',
      isResizable: true,
      className: 'totalAmountCol',
      onRender: (item: any) => {
        return <div>
          {item?.cUnitPrice && <span
            style={{
              fontWeight: 'bold',
              paddingRight: 6,
            }}
          >{formatPrice(item?.cUnitPrice)}</span>}
        </div>;
      },
      onRenderHeader: () => {
        return <div>
        {i18n.t('unitPrice') + ' (m'+'²)'}
      </div>
      }
    },

    // {
    //   key: 'column8',
    //   name: i18n.t('totalPrice'),
    //   fieldName: 'cTotalPrice',
    //   minWidth:100,
    //   maxWidth: 200,
    //   isResizable: true,
    //   className: 'totalAmountCol',
    //   onRender: (item: any) => {
    //     return <div className="find me" style={{ maxWidth: 200, display: 'flex', justifyContent: 'flex-end' }}>
    //       {item?.cTotalPrice && <span>{formatPrice(item?.cTotalPrice)}</span>}
    //     </div>
    //   }
    // },

    // {
    //     key: 'column9',
    //     name: t('requestedDeliveryDate'),
    //     fieldName: 'requestedDate',
    //     minWidth: props.isExpand ? 160 : 120,
    //     maxWidth: props.isExpand ? 160 : 120,
    //     isResizable: true
    // }
  ];

  return (
    <div>

      <div className={'card-header'}>
        <Link href="#" id="requestedProductDetail">
          <Label>4. {i18n.t('requestedProductDetail')} </Label>
        </Link>
      </div>
      <div className={'card-body'}>
        <div className="proj-detail-content inner">
          <div className={classNames.wrapper}>
            <div className={'scrollablePane'}>
              {/*<ScrollablePane styles={ { root: { height: 'auto' } } }>*/}
              <ShimmeredDetailsList
                items={rfqFormData.externalProduct ? rfqFormData.externalProduct : []}
                columns={columns as any}
                setKey="set"
                enableShimmer={false}
                layoutMode={DetailsListLayoutMode.justified}
                selectionPreservedOnEmptyClick={true}
                checkboxVisibility={1}
                selectionMode={SelectionMode.none}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
              />
              {/*</ScrollablePane>*/}
            </div>
          </div>

        </div>
      </div>

      <div className={'card-footer'}>
        <br/>
      </div>

    </div>
  );
};

export default RfqExternalProduct



import { Label, Link } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import HistoryBar from '../../../shared/historyBar/historyBar';
import ProjectHeader from '../../../shared/projectHeader/projectHeader';
import { Invoice, InvoiceValidationMessage } from '../../../types/invoice';
import InvoiceAmount from './invoiceAmount/invoiceAmount';
import CustomerInformation from './customerInformation/customerInformation';
import InvoiceInformation from './invoiceInformation/invoiceInformation';
import { ProgressStatement } from '../../../types/progressStatement';


// Tokens definition

// const theme = getTheme();

export const DocumentPaneComponent = (props: {
  projectHeader: any;
  dropDownOptions: any;
  isEdit: boolean;
  formData: Invoice;
  psFormData: ProgressStatement;
  saveFormData: () => void;
  handleFormChange: (invoice: Invoice, save: boolean) => void;
  validationMessages: InvoiceValidationMessage;
  // eslint-disable-next-line react/no-unused-prop-types
  isLoading: boolean;
  isExpand: boolean
}) => {
  const { t } = useTranslation();


  return (
    <div style={{ width: '100%' }} id={'ProjectBreakdownForm'}>
      <div className="proj-detail-block">
        <ProjectHeader projectDetails={props.projectHeader}/>
      </div>

      <div className="proj-detail-block" id="1">
        <InvoiceInformation
          dropDownOptions={props.dropDownOptions}
          isEdit={props.isEdit}
          formData={props.formData}
          psFormData={props.psFormData}
          saveFormData={() => props.saveFormData()}
          handleFormChange={(invoice: Invoice, save: boolean) =>
            props.handleFormChange(invoice, save)
          }
          validationMessages={props.validationMessages}
        />
      </div>

      <div className="proj-detail-block" id="2">
        <CustomerInformation
          dropDownOptions={props.dropDownOptions}
          isEdit={props.isEdit}
          formData={props.formData}
          saveFormData={() => props.saveFormData()}
          handleFormChange={(invoice: Invoice, save: boolean) =>
            props.handleFormChange(invoice, save)
          }
          validationMessages={props.validationMessages}
        />
      </div>

      <div className="proj-detail-block" id="1">
        <InvoiceAmount
          dropDownOptions={props.dropDownOptions}
          isEdit={props.isEdit}
          formData={props.formData}
          saveFormData={() => props.saveFormData()}
          handleFormChange={(invoice: Invoice, save: boolean) =>
            props.handleFormChange(invoice, save)
          }
          validationMessages={props.validationMessages}
        />
      </div>


      {props.isEdit &&
      props.formData.historyLog &&
      props.formData.historyLog.createdByUser && (
        <div className="proj-detail-block" id="22">
          <div className="document-pane-card">
            <div className="marginTop marginBottom">
              <Link href="#" id="history">
                <Label>4. {t('history')} </Label>
              </Link>
              <div className={'ms-Grid'} dir="ltr" style={{ marginBottom: 10 }}>
                <HistoryBar
                  createdByUser={
                    props.formData.historyLog.createdByUser
                      ? props.formData.historyLog.createdByUser
                      : ''
                  }
                  updatedByUser={
                    props.formData.historyLog.updatedByUser
                      ? props.formData.historyLog.updatedByUser
                      : ''
                  }
                  createdDateTime={
                    props.formData.historyLog.createdDateTime
                      ? props.formData.historyLog.createdDateTime
                      : ''
                  }
                  updatedDateTime={
                    props.formData.historyLog.updatedDateTime
                      ? props.formData.historyLog.updatedDateTime
                      : ''
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>

  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentPaneComponent);

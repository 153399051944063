import {
    FontWeights,
    getTheme,
    IIconProps,
    IStackItemStyles,
    IStackStyles,
    IStackTokens,
    mergeStyleSets,
    PrimaryButton,
    Stack,
} from '@fluentui/react';
import React, {useEffect, useRef} from 'react';
import {Field, Form, FormSpy} from 'react-final-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {uPrinceTheme} from '../../../../../../theme';
import {lotFileUpload, saveContractStateAttr} from '../../../../../reducers/contractReducer';
import {DropDownAdaptater, TextFieldFF} from '../../../../../shared/fluentFinalForm';
import {v4 as uuid4} from 'uuid';
import {OnChange} from 'react-final-form-listeners';
import {FileType, UPLOAD_URL} from '../../../../../types/contract';

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    actionButtonLabel: {color: uPrinceTheme.palette.themePrimary},
    header: [
        // eslint-disable-next-line deprecation/deprecation
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '2px 2px 2px 10px',
            backgroundColor: uPrinceTheme.palette.themePrimary,
            fontSize: 18,
            color: 'white',
        },
    ],
    footer: [
        // eslint-disable-next-line deprecation/deprecation
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '0px 24px 14px 24px',
            textAlign: 'end',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 0px 24px',
        overflowY: 'hidden',
        minWidth: '40vw',
        minHeight: '20vh',
        selectors: {
            p: {margin: '14px 0'},
            'p:first-child': {marginTop: 0},
            'p:last-child': {marginBottom: 0},
        },
    },
    subHeader: {
        flex: '1 1 auto',
        display: 'block',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '2px 2px 2px 10px',
        fontSize: 14,
        color: uPrinceTheme.palette.themePrimary,
        backgroundColor: '#FFF7F4',
        height: 40,
        marginLeft: 'auto',
        marginRight: '2px',
    },
});
const addWhiteIconButtonStyles = {
    root: {color: uPrinceTheme.palette.white},
    rootHovered: {color: theme.palette.neutralDark},
};
const addIconWhite: IIconProps = {
    iconName: 'Add',
    styles: addWhiteIconButtonStyles,
};
const uploadIconWhite: IIconProps = {
    iconName: 'Upload',
    styles: addWhiteIconButtonStyles,
};

const DocumentationModalContent = (props: {
    documentation: any;
    disabled: boolean;
    onChange: any;
    documentCategory: string;
}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    // const { projectScopeStatus, userRoles }: any = useSelector(
    //     (state: any) => state.project
    // );
    // const { uploadedInstructionLink }: any = useSelector(
    //     (state: any) => state.projectBreakdown
    // );

    const {contractDropDowns, uploadedInstructionLink, showProgressBar, formData}: any = useSelector(
      (state: any) => state.contract,
    );

    const inputFile: any = useRef(null);
    const ref: any = useRef(null);

    const stackItemStyles: IStackItemStyles = {
        root: {
            display: 'flex',
            height: 50,
            width: 0,
            flexGrow: 1,
        },
    };

    const stackTokens: IStackTokens = {
        childrenGap: 10,
        padding: 10,
    };

    const stackStyles: IStackStyles = {root: {padding: 0}};

    const classNames = mergeStyleSets({
        wrapper: {
            // height: '100vh',
            position: 'relative',
            maxHeight: 'inherit',
        },
        pane: {maxWidth: 400},
        textContent: {padding: '15px 10px'},
        actionIcon: {
            padding: 5,
            cursor: 'pointer',
        },
        labelRightWrapper: {
            textAlign: 'end',
            selectors: {'@media(max-width: 1100px)': {textAlign: 'start'}},
        },
        actionButton: {
            color: uPrinceTheme.palette.themePrimary,
            cursor: 'pointer',
        },
        labelRightIcon: {
            display: 'inline-block',
            verticalAlign: 'bottom',
            paddingBottom: 2,
            paddingRight: 5,
        },
        fullWidth: {width: '100%'},
        halfWidth: {width: '49%'},
        marginTop: {marginTop: 8},
    });

    const onSubmit = async (values: any) => {
        const sleep = (ms: number | undefined) =>
          new Promise((resolve) => setTimeout(resolve, ms));
        await sleep(300);
        if (!values.id) {
            values.id = uuid4();
        }
        props.onChange(values);
    };

    // const formRef = React.useRef(createForm({ onSubmit: onSubmit }));

    const getInitialvalues = (documentation: any) => {
        if (documentation) {
            return documentation;
        }
        return {
            id: null,
            lotId: null,
            title: null,
            typeId: null,
            // typeName: null,
            link: null,
        };
    };

    const getFileType = (type: string) => {
        let typeId;

        switch (type) {
            case 'image/jpeg':
                return typeId = FileType.IMAGE;

            case 'image/png':
                return typeId = FileType.IMAGE;

            case 'image/webp':
                return typeId = FileType.IMAGE;

            case 'application/pdf':
                return typeId = FileType.PDF;

            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': //word
                return typeId = FileType.WORD_DOCUMENT;

            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':   //excel
                return typeId = FileType.EXCEL_DOCUMENT;

            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':   //powerpoint
                return typeId = FileType.WORD_DOCUMENT;
            case 'application/x-zip-compressed':   //zip
                return typeId = FileType.ZIP;
            case 'application/zip':   //zip on mac
                return typeId = FileType.ZIP;

            default:
                return typeId = FileType.URL;   //URL
        }
    };

    useEffect(() => {
        if (uploadedInstructionLink) {
            ref.current.mutators.setDocumentLink(uploadedInstructionLink);
        }
    }, [uploadedInstructionLink]);

    return (
      <div className={contentStyles.body}>
          <div className="proj-detail-content inner">
              <div className="ms-Grid-row">
                  <Form
                    //form={formRef.current}
                    onSubmit={onSubmit}
                    initialValues={getInitialvalues(props.documentation)}
                    mutators={{
                        // setTypeName: (args, state, utils) => {
                        //     const field = state.fields.typeName;
                        //     field.change(args[0]);
                        // },
                        setDocumentLink: (args, state, utils) => {
                            const field = state.fields.link;
                            field.change(args[0]);
                        },
                        setDocumentType: (args, state, utils) => {
                            const field = state.fields.typeId;
                            field.change(args[0]);
                        },
                    }}
                    validate={(values) => {
                        const errors: any = {};

                        if (!values?.title && !showProgressBar) {
                            errors.title = t('titleRequired');
                        }
                        if (!values?.typeId && !showProgressBar) {
                            errors.typeId = t('typeRequired');
                        }
                        if (!values?.link && !showProgressBar) {
                            errors.link = t('linkRequired');
                        }
                        return errors;
                    }}
                    render={({handleSubmit, form, submitting, pristine, values}) => {
                        ref.current = form;
                        return (<div>
                              <FormSpy
                                onChange={(spyProps: any) => {
                                }}
                              />

                              <Stack
                                horizontal
                                wrap
                                styles={stackStyles}
                                tokens={stackTokens}
                              >
                                  <Stack.Item grow={6} styles={stackItemStyles}>
                                      <div className={`${classNames.fullWidth}`}>
                                          <Field
                                            name="title"
                                            component={TextFieldFF}
                                            lable={t('title')}
                                            required={true}
                                          />
                                      </div>
                                  </Stack.Item>
                                  <Stack.Item grow={6} styles={stackItemStyles}>
                                      <div className={`${classNames.fullWidth}`}>
                                          <Field
                                            name="typeId"
                                            component={DropDownAdaptater}
                                            options={contractDropDowns.fileType}
                                            lable={t('type')}
                                            placeholder={t('type')}
                                            required={true}
                                            disabled={true}
                                          />
                                          {/* <Field
                                                name="typeName"
                                                component={'input'}
                                                type={'text'}
                                                disabled={true}
                                                hidden /> */}
                                      </div>
                                  </Stack.Item>
                              </Stack>

                              <Stack
                                horizontal
                                wrap
                                styles={stackStyles}
                                tokens={stackTokens}
                              >
                                  <Stack.Item grow={6} styles={stackItemStyles}>
                                      <div className={`${classNames.fullWidth} ${classNames.marginTop}`}>
                                          <Field
                                            name="link"
                                            component={TextFieldFF}
                                            lable={t('link')}
                                            disabled={false}
                                            placeholder={t('copyURLhere')}
                                            required={true}
                                          />
                                          <OnChange name="link">
                                              {() => {
                                                  const sub = values?.link?.split('/');
                                                  if (sub[2] && sub[2] !== UPLOAD_URL) {
                                                      form.mutators.setDocumentType(getFileType(''));
                                                  }
                                              }}
                                          </OnChange>
                                          {/* <OnBlur name="link">
                                                {() => {
                                                    const sub = values?.link?.substring(0, 30)
                                                    if (sub && sub !== UPLOAD_URL) {
                                                        form.mutators.setDocumentType(getFileType(''));
                                                    }
                                                }}
                                            </OnBlur> */}
                                      </div>
                                  </Stack.Item>
                              </Stack>

                              {/* <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                                    <Stack.Item
                                        grow={6}
                                        styles={{
                                            root: {
                                                display: 'flex',
                                                height: 30,
                                                paddingBottom: 0,
                                                flexGrow: 1,
                                                width: 0,
                                                marginTop: 10
                                            },
                                        }}
                                    >
                                        <div className={`${classNames.fullWidth}`}>
                                            <TextField readOnly={true} borderless={true} value={t('or')} />
                                        </div>
                                    </Stack.Item>
                                </Stack> */}

                              <input
                                type="file"
                                id="file"
                                ref={inputFile}
                                hidden
                                onChange={(event: any) => {
                                    const data = new FormData();
                                    if (event?.target?.files[0]) {
                                        data.append('file', event.target.files[0]);
                                        data.append('id', uuid4());
                                        data.append('lotId', formData?.sequenceId);
                                        data.append('documentCategory', props?.documentCategory);
                                        dispatch(lotFileUpload(data));
                                        form.mutators.setDocumentType(getFileType(event.target.files[0].type));
                                    }
                                }}
                              />
                              <PrimaryButton
                                iconProps={uploadIconWhite}
                                text={t('upload')}
                                disabled={false}
                                style={{marginTop: 32, marginLeft: 10}}
                                onClick={() => {
                                    inputFile?.current?.click();
                                }}
                              />

                              <div className={contentStyles.footer}>
                                  <PrimaryButton
                                    iconProps={addIconWhite}
                                    text={t('save')}
                                    disabled={submitting || pristine}
                                    style={{marginTop: 38, marginBottom: 10}}
                                    onClick={() => {
                                        // props.onChange(values);
                                        form.submit();
                                        dispatch(saveContractStateAttr('uploadedInstructionLink', null));
                                    }}
                                  />
                              </div>
                              {/* <pre>{JSON.stringify(values, 0, 2)}</pre>  */}
                          </div>
                        );
                    }}
                  />
              </div>
          </div>
      </div>
    );
};

export default DocumentationModalContent;

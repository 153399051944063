export const LOAD_ORG_SETTING_SHORTCUTPANE = 'uprince/LOAD_ORG_SETTING_SHORTCUTPANE';
export const LOAD_ORG_SETTING_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_ORG_SETTING_SHORTCUTPANE_SUCCESS';
export const LOAD_ORG_SETTING_SHORTCUTPANE_FAIL = 'uprince/LOAD_ORG_SETTING_SHORTCUTPANE_FAIL';

export const LOAD_ORG_SETTING_LSITPANE = 'uprince/LOAD_ORG_SETTING_LSITPANE';
export const LOAD_ORG_SETTING_LSITPANE_SUCCESS = 'uprince/LOAD_ORG_SETTING_LSITPANE_SUCCESS';
export const LOAD_ORG_SETTING_LSITPANE_FAIL = 'uprince/LOAD_ORG_SETTING_LSITPANE_FAIL';

export const LOAD_ORG_SETTING_TAXONOMY = 'uprince/LOAD_ORG_SETTING_TAXONOMY';
export const LOAD_ORG_SETTING_TAXONOMY_SUCCESS = 'uprince/LOAD_ORG_SETTING_TAXONOMY_SUCCESS';
export const LOAD_ORG_SETTING_TAXONOMY_FAIL = 'uprince/LOAD_ORG_SETTING_TAXONOMY_FAIL';

export const LOAD_ORG_SETTING_DROPDOWN = 'uprince/LOAD_ORG_SETTING_DROPDOWN';
export const LOAD_ORG_SETTING_DROPDOWN_SUCCESS = 'uprince/LOAD_ORG_SETTING_DROPDOWN_SUCCESS';
export const LOAD_ORG_SETTING_DROPDOWN_FAIL = 'uprince/LOAD_ORG_SETTING_DROPDOWN_FAIL';

export const SAVE_ORG_SETTING = 'uprince/SAVE_ORG_SETTING';
export const SAVE_ORG_SETTING_SUCCESS = 'uprince/SAVE_ORG_SETTING_SUCCESS';
export const SAVE_ORG_SETTING_FAIL = 'uprince/SAVE_ORG_SETTING_FAIL';

export const LOAD_ORG_SETTING_BY_ID = 'uprince/LOAD_ORG_SETTING_BY_ID';
export const LOAD_ORG_SETTING_BY_ID_SUCCESS = 'uprince/LOAD_ORG_SETTING_BY_ID_SUCCESS';
export const LOAD_ORG_SETTING_BY_ID_FAIL = 'uprince/LOAD_ORG_SETTING_BY_ID_FAIL';

export const LOAD_BOR_RESOURCES_BY_ID = 'uprince/LOAD_BOR_RESOURCES_BY_ID';
export const LOAD_BOR_RESOURCES_BY_ID_SUCCESS = 'uprince/LOAD_BOR_RESOURCES_BY_ID_SUCCESS';
export const LOAD_BOR_RESOURCES_BY_ID_FAIL = 'uprince/LOAD_BOR_RESOURCES_BY_ID_FAIL';

export const READ_ORG_TAXONOMY_LEVELS = 'uprince/READ_ORG_TAXONOMY_LEVELS';
export const READ_ORG_TAXONOMY_LEVELS_SUCCESS = 'uprince/READ_ORG_TAXONOMY_LEVELS_SUCCESS';
export const READ_ORG_TAXONOMY_LEVELS_FAIL = 'uprince/READ_ORG_TAXONOMY_LEVELS_FAIL';

export const CREATE_ORG_TAXONOMY_LEVELS = 'uprince/CREATE_ORG_TAXONOMY_LEVELS';
export const CREATE_ORG_TAXONOMY_LEVELS_SUCCESS = 'uprince/CREATE_ORG_TAXONOMY_LEVELS_SUCCESS';
export const CREATE_ORG_TAXONOMY_LEVELS_FAIL = 'uprince/CREATE_ORG_TAXONOMY_LEVELS_FAIL';

export const DELETE_ORG_TAXONOMY_LEVELS = 'uprince/DELETE_ORG_TAXONOMY_LEVELS';
export const DELETE_ORG_TAXONOMY_LEVELS_SUCCESS = 'uprince/DELETE_ORG_TAXONOMY_LEVELS_SUCCESS';
export const DELETE_ORG_TAXONOMY_LEVELS_FAIL = 'uprince/DELETE_ORG_TAXONOMY_LEVELS_FAIL';

export const SET_ORG_SETTING_UID = 'uprince/SET_ORG_SETTING_UID';
export const CLEAR_ORG_SETTING_UID = 'uprince/CLEAR_ORG_SETTING_UID';
export const CLEAR_ORG_SETTING_FORM_DATA = 'uprince/CLEAR_ORG_SETTING_FORM_DATA';
export const RESET_ORG_SETTING_IS_CHANGE = 'uprice/RESET_ORG_SETTING_IS_CHANGE';


export const CLEAR_ORG_SETTINGUID = 'uprice/CLEAR_ORG_SETTINGUID';
export const SAVE_OS_STATE_ATTR = 'uprice/SAVE_OS_STATE_ATTR';

export const READ_ORG_PERSON_TYPE_DROPDOWN = 'uprice/READ_ORG_PERSON_TYPE_DROPDOWN';
export const READ_ORG_PERSON_TYPE_DROPDOWN_SUCCESS = 'uprice/READ_ORG_PERSON_TYPE_DROPDOWN_SUCCESS';
export const READ_ORG_PERSON_TYPE_DROPDOWN_FAIL = 'uprice/READ_ORG_PERSON_TYPE_DROPDOWN_FAIL';

export const SET_ORG_BU_IS_DEFAULT = 'uprice/SET_ORG_BU_IS_DEFAULT';
export const SET_ORG_BU_IS_DEFAULT_SUCCESS = 'uprice/SET_ORG_BU_IS_DEFAULT_SUCCESS';
export const SET_ORG_BU_IS_DEFAULT_FAIL = 'uprice/SET_ORG_BU_IS_DEFAULT_FAIL';

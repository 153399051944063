export const LOAD_CIAW_DATA = 'uprince/LOAD_CIAW_DATA';
export const LOAD_CIAW_DATA_SUCCESS = 'uprince/LOAD_CIAW_DATA_SUCCESS';
export const LOAD_CIAW_DATA_FAIL = 'uprince/LOAD_CIAW_DATA_FAIL';

export const SAVE_CIAW_STATE_ATTR = 'uprince/SAVE_CIAW_STATE_ATTR';
export const SAVE_CIAW_STATE_PRODUCT_ATTR = 'uprince/SAVE_CIAW_STATE_PRODUCT_ATTR';

export const UPDATE_CIAW_REGISTRATION_STATUS = 'uprince/UPDATE_CIAW_REGISTRATION_STATUS'
export const UPDATE_CIAW_REGISTRATION_STATUS_SUCCESS = 'uprince/UPDATE_CIAW_REGISTRATION_STATUS_SUCCESS'
export const UPDATE_CIAW_REGISTRATION_STATUS_FAIL = 'uprince/UPDATE_CIAW_REGISTRATION_STATUS_FAIL'

export const READ_CIAW_DROPDOWN_DATA = 'uprince/READ_CIAW_DROPDOWN_DATA';
export const READ_CIAW_DROPDOWN_DATA_SUCCESS = 'uprince/READ_CIAW_DROPDOWN_DATA_SUCCESS';
export const READ_CIAW_DROPDOWN_DATA_FAIL = 'uprince/READ_CIAW_DROPDOWN_DATA_FAIL';

export const READ_CIAW_BY_ID = 'uprince/READ_CIAW_BY_ID';
export const READ_CIAW_BY_ID_SUCCESS = 'uprince/READ_CIAW_BY_ID_SUCCESS';
export const READ_CIAW_BY_ID_FAIL = 'uprince/READ_CIAW_BY_ID_FAIL';

export const READ_CIAW_SITE_CODE_BY_ID = 'uprince/READ_CIAW_SITE_CODE_BY_ID';
export const READ_CIAW_SITE_CODE_BY_ID_SUCCESS = 'uprince/READ_CIAW_SITE_CODE_BY_ID_SUCCESS';
export const READ_CIAW_SITE_CODE_BY_ID_FAIL = 'uprince/READ_CIAW_SITE_CODE_BY_ID_FAIL';

export const READ_CIAW_SITE_PRODUCT_CODE_BY_ID = 'uprince/READ_CIAW_SITE_PRODUCT_CODE_BY_ID';
export const READ_CIAW_SITE_PRODUCT_CODE_BY_ID_SUCCESS = 'uprince/READ_CIAW_SITE_PRODUCT_CODE_BY_ID_SUCCESS';
export const READ_CIAW_SITE_PRODUCT_CODE_BY_ID_FAIL = 'uprince/READ_CIAW_SITE_PRODUCT_CODE_BY_ID_FAIL';

export const CREATE_PROJECT_CIAW_SITE = 'uprince/CREATE_PROJECT_CIAW_SITE';
export const CREATE_PROJECT_CIAW_SITE_SUCCESS = 'uprince/CREATE_PROJECT_CIAW_SITE_SUCCESS';
export const CREATE_PROJECT_CIAW_SITE_FAIL = 'uprince/CREATE_PROJECT_CIAW_SITE_FAIL';


export const CREATE_PRODUCT_CIAW_SITE = 'uprince/CREATE_PRODUCT_CIAW_SITE';
export const CREATE_PRODUCT_CIAW_SITE_SUCCESS = 'uprince/CREATE_PRODUCT_CIAW_SITE_SUCCESS';
export const CREATE_PRODUCT_CIAW_SITE_FAIL = 'uprince/CREATE_PRODUCT_CIAW_SITE_FAIL';

export const READ_CAB_CERTIFICATION_CIAW = 'uprince/READ_CAB_CERTIFICATION_CIAW';
export const READ_CAB_CERTIFICATION_CIAW_SUCCESS = 'uprince/READ_CAB_CERTIFICATION_CIAW_SUCCESS';
export const READ_CAB_CERTIFICATION_CIAW_FAIL = 'uprince/READ_CAB_CERTIFICATION_CIAW_FAIL';

export const SEND_CIAW = 'uprince/SEND_CIAW';
export const SEND_CIAW_SUCCESS = 'uprince/SEND_CIAW_SUCCESS';
export const SEND_CIAW_FAIL = 'uprince/SEND_CIAW_FAIL';

export const CANCEL_CIAW = 'uprince/CANCEL_CIAW';
export const CANCEL_CIAW_SUCCESS = 'uprince/CANCEL_CIAW_SUCCESS';
export const CANCEL_CIAW_FAIL = 'uprince/CANCEL_CIAW_FAIL';

export const CIAW_CANCEL_DROPDOWN = 'uprince/CIAW_CANCEL_DROPDOWN';
export const CIAW_CANCEL_DROPDOWN_SUCCESS = 'uprince/CIAW_CANCEL_DROPDOWN_SUCCESS';
export const CIAW_CANCEL_DROPDOWN_FAIL = 'uprince/CIAW_CANCEL_DROPDOWN_FAIL';
export const SEND_CIAW_WARNING_EMAIL = 'uprince/SEND_CIAW_WARNING_EMAIL';
export const SEND_CIAW_WARNING_EMAIL_SUCCESS = 'uprince/SEND_CIAW_WARNING_EMAIL_SUCCESS';
export const SEND_CIAW_WARNING_EMAIL_FAIL = 'uprince/SEND_CIAW_WARNING_EMAIL_FAIL';
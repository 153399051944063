import { getTheme, IImageProps, Image, ImageFit, Label, Link } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import HistoryBar from '../../../shared/historyBar/historyBar';
import { Cost, ProgressStatement, ProgressStatementValidationMessage } from '../../../types/progressStatement';
import AddLanguage from './addLanguage/addLanguage';
import { uPrinceTheme } from '../../../../theme';
import { ActionButtonState, Language, Translation, UprinceLanguage } from '../../../types/language';
//import Language from '../../../shared/languageItem/language';
import { getKeyWord } from '../../../reducers/languageReducer';
import { LangKeysGrid } from './grid/component';
import UprinceLogger from '../../../shared/Logger/logger';


// Tokens definition

const theme = getTheme();
const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export const DocumentPaneComponent = (props: {
  projectHeader: any;
  dropDownOptions: any;
  isEdit: boolean;
  formData: any;
  saveFormData: () => void;
  handleFormChange: (progressStatement: UprinceLanguage, save: boolean) => void;
  validationMessages: ProgressStatementValidationMessage;
  // eslint-disable-next-line react/no-unused-prop-types
  isLoading: boolean;
  projectCostData: Cost[],
  openCostModal: () => void,
  createPSResource: (data: any) => void
  isExpand: boolean
  openLanguageKeyModel: () => void;
  handleNickNameDelete: () => void;
  editNickName: (id: string) => void;
  nickNameSelection: any;
  nickNameSelectionDetails: {};
  langKeyActionButtonState: ActionButtonState;
  isOpenLanguageKeyModel: boolean;
  keyData: any;
  langSelection: any;
  createTranslation: (translation: Translation) => void;
  countries: { key: string; text: string }[];
  updateLanguage: (lang: Language) => void;
  lang: Language;
  onInputChange: (field: string, value: string) => void;
  countryCode: string
}) => {
  const { t } = useTranslation();
  const imageProps: IImageProps = {
    src: props.countryCode ? `https://www.countryflags.io/${props.countryCode}/shiny/64.png` : 'http://placehold.it/500x250',
    imageFit: ImageFit.none,
    width: 50,
    height: 50,
  };

  return (
    <div style={{ width: '100%' }} id={'ProjectBreakdownForm'} className={'grid-righter column-filter-activated'}>
      <div className="proj-detail-block">
        {/* <ProjectHeader projectDetails={ props.projectHeader } /> */}
        <div className={'document-pane-card'}>
          <div className={'card-body'}>
            <div className="proj-detail-content inner">
              <div className={'ms-Grid'} style={{ width: '100%', height: '100%' }}>
                <div className="ms-Grid-row" style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                  <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 ">
                    <Image
                      {...imageProps}
                      //   //src="http://placehold.it/100x100"
                      style={{ width: 50, height: 50 }}
                    />
                  </div>
                  <div style={{ marginLeft: 20 }} className="ms-Grid-col ms-sm10 ms-md10 ms-lg10 ">
                    <Label
                      style={{ fontSize: 17 }}
                      //disabled={true}
                    >
                      {props.formData && props.formData.country}
                    </Label>
                    <Label
                      style={{ fontSize: 14 }}
                      //disabled={true}
                    >
                      {props.formData && props.formData.lang}
                    </Label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="proj-detail-block" id="1">
        <div className={'document-pane-card'}>
          <AddLanguage
            dropDownOptions={props.dropDownOptions}
            isEdit={props.isEdit}
            formData={props.formData}
            saveFormData={() => props.saveFormData()}
            handleFormChange={(progressStatement: ProgressStatement, save: boolean) =>
              UprinceLogger.log('')
              //props.handleFormChange(progressStatement, save)
            }
            validationMessages={props.validationMessages}
            countries={props.countries}
            updateLanguage={(lang: Language) => props.updateLanguage(lang)}
            lang={props.lang}
            onInputChange={(field: string, value: string) => props.onInputChange(field, value)}
          />


          <div className="marginTop">
            <LangKeysGrid
              nickNameSelection={props.langSelection}
              langList={props.keyData}
              nickNameSelectionDetails={props.langSelection}
              langKeyActionButtonState={props.langKeyActionButtonState}
              openLanguageKeyModel={() => {
                props.openLanguageKeyModel();
              }}
              handleNickNameDelete={() => {
                props.handleNickNameDelete();
              }}
            />
          </div>
        </div>
      </div>


      {props.isEdit &&
      props.formData?.historyLog &&
      props.formData?.historyLog?.createdByUser && (
        <div className="proj-detail-block" id="22">
          <div className="document-pane-card">
            <div className="marginTop marginBottom">
              <Link href="#" id="history">
                <Label>4. {t('history')} </Label>
              </Link>

              <HistoryBar
                createdByUser={
                  props.formData.historyLog.createdByUser
                    ? props.formData.historyLog.createdByUser
                    : ''
                }
                updatedByUser={
                  props.formData.historyLog.updatedByUser
                    ? props.formData.historyLog.updatedByUser
                    : ''
                }
                createdDateTime={
                  props.formData.historyLog.createdDateTime
                    ? props.formData.historyLog.createdDateTime
                    : ''
                }
                updatedDateTime={
                  props.formData.historyLog.updatedDateTime
                    ? props.formData.historyLog.updatedDateTime
                    : ''
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>

  );
};


const mapStateToProps = (state: any, ownProps: any) => {
  return {
    //keyData:state.lang.keyData,
  };
};

const mapDispatchToProps = {
  getKeyWord,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentPaneComponent);

import {
  ActionButton,
  DetailsList,
  FontWeights,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  PrimaryButton,
  ScrollablePane,
  ScrollbarVisibility,
  Separator,
  Stack,
} from '@fluentui/react';
import React, { Component } from 'react';
import { CostTableColumn } from '../../../../shared/tableColumn/tableColumn';
import { QRCodeLite } from '../../../../types/qrCode';
import {
  ConstrainMode,
  DetailsListLayoutMode,
  DetailsRow,
  DetailsRowCheck,
  IColumn,
  IDetailsColumnStyles,
  IDetailsFooterProps,
  IDetailsListProps,
  IDetailsRowBaseProps,
  IDetailsRowCheckStyles,
  IDetailsRowStyles,
  Selection,
  SelectionMode,
} from '@fluentui/react/lib/DetailsList';
import groupArray from 'group-array';
import { Cost, ProgressStatement } from '../../../../types/progressStatement';
import _ from 'lodash';
import { uPrinceTheme } from '../../../../../theme';
import i18n from '../../../../../i18n';
import { getProject } from '../../../../shared/util';

const theme = getTheme();

const headerStyle: Partial<IDetailsColumnStyles> = {
  root: {
    background: '#e5e5e5',
    borderLeft: '1px #c8c8c8 solid',
  },
};

interface Props {
  projectCostData: Cost[],
  openCostModal: () => void,
  createPSResource: (data: any) => void,
  isExpand: boolean;
  formData: ProgressStatement;
  isApproved: boolean;
  setResourceData: (data: any) => void
}

interface State {
  data: Cost[];
  costData: any[];
  costData2: any[],
  costTableHeight: number | null
}

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const stackFooterBarItemStyles: IStackItemStyles = {
  root: {
    height: 45,
  },
};
const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

export class CostTable extends Component<Props, State> {
  private _selection: Selection;
  private _columns: IColumn[];
  private _columnsExpand: IColumn[];
  private data: any;

  constructor(props: Props) {
    super(props);
    this._selection = new Selection();

    this.state = {
      data: [],
      costData: [],
      costData2: [],
      costTableHeight: 200,
    };

    this._columns = [
      {
        key: 'column1',
        name: i18n.t('items'),
        fieldName: 'title',
        minWidth: this.props.isExpand ? 200 : 120,
        maxWidth: this.props.isExpand ? 200 : 120,
        isResizable: true,
        isRowHeader: true,
        data: 'string',
        isSortedDescending: false,
        isPadded: true,
        isSorted: true,
        styles: headerStyle,
      },
      {
        key: 'column2',
        name: i18n.t('consumed'),
        fieldName: 'cquantity',
        minWidth: this.props.isExpand ? 150 : 120,
        maxWidth: this.props.isExpand ? 150 : 120,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
      },
      {
        key: 'column3',
        name: i18n.t('cost/mou'),
        fieldName: 'costPerUnit',
        minWidth: this.props.isExpand ? 130 : 80,
        maxWidth: this.props.isExpand ? 130 : 80,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
      },
      {
        key: 'column4',
        name: i18n.t('total'),
        fieldName: 'totalCost1',
        minWidth: this.props.isExpand ? 130 : 80,
        maxWidth: this.props.isExpand ? 130 : 80,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
      },
      {
        key: 'column5',
        name: i18n.t('soldQuantity'),
        fieldName: 'soldQuantity',
        minWidth: this.props.isExpand ? 130 : 80,
        maxWidth: this.props.isExpand ? 130 : 80,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
      },
      {
        key: 'column6',
        name: i18n.t('sPMOU'),
        fieldName: 'spMou',
        minWidth: this.props.isExpand ? 130 : 80,
        maxWidth: this.props.isExpand ? 130 : 80,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
      },
      // {
      //     key: 'column7',
      //     name: 'Travel Cost',
      //     fieldName: 'travelCost',
      //     minWidth: 80,
      //     maxWidth: 80,
      //     isRowHeader: true,
      //     isResizable: true,
      // },
      {
        key: 'column8',
        name: i18n.t('totalSales'),
        fieldName: 'totaleSales',
        minWidth: this.props.isExpand ? 130 : 80,
        maxWidth: this.props.isExpand ? 130 : 80,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
        headerClassName: 'ps-cost-header',
      },
      {
        key: 'column9',
        name: i18n.t('comments'),
        fieldName: 'comments',
        minWidth: 130,
        maxWidth: 130,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
        // headerClassName: 'ps-cost-header',
      },
    ];

    this._columnsExpand = [
      {
        key: 'column1',
        name: i18n.t('items'),
        fieldName: 'title',
        minWidth: 350,
        maxWidth: 350,
        isResizable: true,
        isRowHeader: true,
        data: 'string',
        isSortedDescending: false,
        isPadded: true,
        isSorted: true,
        styles: headerStyle,
      },
      {
        key: 'column2',
        name: i18n.t('consumed'),
        fieldName: 'cquantity',
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
      },
      {
        key: 'column3',
        name: i18n.t('cost/mou'),
        fieldName: 'costPerUnit',
        minWidth: 130,
        maxWidth: 130,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
      },
      {
        key: 'column4',
        name: i18n.t('total'),
        fieldName: 'totalCost1',
        minWidth: 130,
        maxWidth: 130,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
      },
      {
        key: 'column5',
        name: i18n.t('soldQuantity'),
        fieldName: 'soldQuantity',
        minWidth: 130,
        maxWidth: 130,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
      },
      {
        key: 'column6',
        name: i18n.t('sPMOU'),
        fieldName: 'spMou',
        minWidth: 130,
        maxWidth: 130,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
      },
      {
        key: 'column8',
        name: i18n.t('totalSales'),
        fieldName: 'totaleSales',
        minWidth: 130,
        maxWidth: 130,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
        headerClassName: 'ps-cost-header',
      },
      // {
      //   key: 'column9',
      //   name: i18n.t('comments'),
      //   fieldName: 'comments',
      //   minWidth: this.props.isExpand ? 130 : 80,
      //   maxWidth: this.props.isExpand ? 130 : 80,
      //   isRowHeader: true,
      //   isResizable: true,
      //   styles: headerStyle,
      //   // headerClassName: 'ps-cost-header',
      // },
    ];

  }

  componentDidMount() {
    //this.getCostData()
    //this.getModifiedCostData()
    //this.getCostData()
  }

  getClassNames = () => {
    let minHeight: any = this.props.projectCostData?.length > 0 ? (this.props.projectCostData?.length === 1 ? 250 : this.props.projectCostData?.length * 130) : 200;
    let parentElement = document.getElementsByClassName('ms-Viewport');
    if (this.props.formData.progressStatementId) {
      if (parentElement) {
        let costTableElement = parentElement as HTMLCollectionOf<HTMLElement>;
        if (costTableElement.item(1)) {
          if (costTableElement.item(1)?.offsetHeight != undefined) {
            minHeight = costTableElement.item(1)?.offsetHeight;
          }
        }
      }
    }
    const classNames = mergeStyleSets({
      wrapper: {
        minHeight: 200,
        position: 'relative',
      },
      actionButton: {
        color: uPrinceTheme.palette.themePrimary,
        cursor: 'pointer',
      },
      labelRightIcon: {
        display: 'inline-block',
        verticalAlign: 'bottom',
        paddingBottom: 2,
        paddingRight: 5,
      },
      fullWidth: {
        width: '100%',
      },
      footer: [
        // eslint-disable-next-line deprecation/deprecation
        theme.fonts.xLargePlus,
        {
          flex: '1 1 auto',
          alignItems: 'center',
          fontWeight: FontWeights.semibold,
          padding: '0px 24px 14px 24px',
          textAlign: 'end',
        },
      ],
    });
    return classNames;
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.projectCostData != this.props.projectCostData) {
      this.setState({ data: this.props.projectCostData }, () => {
        //this.getModifiedCostData()
        this.getCostData();

      });
    }
  }

  getCostTableoffsetHeight = () => {
    let minHeight: any = this.props.projectCostData?.length > 0 ? (this.props.projectCostData?.length === 1 ? 250 : this.props.projectCostData?.length * 150) : 200;
    let parentElement = document.getElementsByClassName('ms-Viewport');
    if (parentElement) {
      let costTableElement = parentElement as HTMLCollectionOf<HTMLElement>;
      if (costTableElement.item(1)) {
        if (costTableElement.item(1)?.offsetHeight != undefined) {
          minHeight = costTableElement.item(1)?.offsetHeight;
        }
      }
    }

    this.setState({
      costTableHeight: minHeight,
    });
  };

  _onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {
      root: {
        selectors: {
          ':hover': {
            background: 'transparent',
          },
        },
      },
    };
    if (props) {
      return (
        <Link
          style={{ textDecoration: 'none' }}
          to={'' + props.item.value}
          onClick={() => {
            //onQRItemClick(props.item);
          }}
        >
          <DetailsRow {...props} styles={customStyles} key={props.item.product}/>
        </Link>
      );
    }
    return null;
  };

  onChangeComments = (index: number, rindex: number, value: any, resourceValue?: any) => {
    const newData = this.state.costData2.slice(); //copy the array
    let item = newData[index];  //execute the manipulations
    item.resource.CBC[rindex].comments = value;
    resourceValue?item.resource.CBC = resourceValue: item.resource.CBC = item.resource.CBC
    console.log('item', item)
    this.setState({ costData2: newData });
  }

  onChangeConsumedQuantity = (index: number, rindex: number, type: number, value: number, resourceValue?:any) => {

    const newData = this.state.costData2.slice(); //copy the array
    let item = newData[index];  //execute the manipulations
    switch (type) {
      case 1:
        item.resource.Materials[rindex].soldQuantity = value;
        resourceValue?item.resource.Materials = resourceValue: item.resource.Materials = item.resource.Materials;
        // item.resource.Materials[rindex].totalCost = (item.resource.Materials[rindex].soldQuantity*item.resource.Materials[rindex].spToMou)
        break;
      case 2:
        item.resource.Tools[rindex].soldQuantity = value;
        resourceValue?item.resource.Tools = resourceValue: item.resource.Tools = item.resource.Tools;
        // item.resource.Consumables[rindex].totalCost = (item.resource.Tools[rindex].soldQuantity*item.resource.Tools[rindex].spToMou)
        break;
      case 3:
        item.resource.Consumables[rindex].soldQuantity = value;
        resourceValue?item.resource.Consumables = resourceValue: item.resource.Consumables = item.resource.Consumables;
        // item.resource.Consumables[rindex].totalCost = (item.resource.Consumables[rindex].soldQuantity*item.resource.Consumables[rindex].spToMou)
        break;
      case 4:
        item.resource.Labours[rindex].soldQuantity = value;
        resourceValue?item.resource.Labours = resourceValue: item.resource.Labours = item.resource.Labours;
        // item.resource.Labours[rindex].totalCost = (item.resource.Labours[rindex].soldQuantity*item.resource.Labours[rindex].spToMou)
        break;
      case 5:
        item.resource.Service[rindex].soldQuantity = value;
        resourceValue?item.resource.Service = resourceValue: item.resource.Service = item.resource.Service;
        // item.resource.Labours[rindex].totalCost = (item.resource.Labours[rindex].soldQuantity*item.resource.Labours[rindex].spToMou)
        break;
      case 6:
        item.resource.CBC[rindex].soldQuantity = value;
        resourceValue?item.resource.CBC = resourceValue: item.resource.CBC = item.resource.CBC;
        // item.resource.Labours[rindex].totalCost = (item.resource.Labours[rindex].soldQuantity*item.resource.Labours[rindex].spToMou)
        break;
      default:
        break;
    }
    //   let resource = item['materials']
    //   let chnageItem = resource[rindex]
    //   chnageItem.consumed = value;
    //this.setState({costData2: newData})
    this.setState({ costData2: newData }, () => this.props.setResourceData(this.transFormDataToSave()));
  };

  onChangeMOUCost = (index: number, rindex: number, type: number, value: number,resourceValue?:any) => {

    const newData = this.state.costData2.slice(); //copy the array
    let item = newData[index];  //execute the manipulations
    switch (type) {
      case 1:
        item.resource.Materials[rindex].spToMou = this.toFixedTrunc(value, 2);
        resourceValue?item.resource.Materials = resourceValue: item.resource.Materials = item.resource.Materials;
        break;
      case 2:
        item.resource.Tools[rindex].spToMou = this.toFixedTrunc(value, 2);
        resourceValue?item.resource.Tools = resourceValue: item.resource.Tools = item.resource.Tools;
        break;
      case 3:
        item.resource.Consumables[rindex].spToMou = this.toFixedTrunc(value, 2);
        resourceValue?item.resource.Consumables = resourceValue: item.resource.Consumables = item.resource.Consumables;
        break;
      case 4:
        item.resource.Labours[rindex].spToMou = this.toFixedTrunc(value, 2);
        resourceValue?item.resource.Labours = resourceValue: item.resource.Labours = item.resource.Labours;
        break;
      case 5:
        item.resource.Service[rindex].spToMou = this.toFixedTrunc(value, 2);
        resourceValue?item.resource.Service = resourceValue: item.resource.Service = item.resource.Service;
        break;
      case 6:
        item.resource.CBC[rindex].spToMou = this.toFixedTrunc(value, 2);
        resourceValue?item.resource.CBC = resourceValue: item.resource.CBC = item.resource.CBC;
        break;
      default:
        break;
    }
    this.setState({ costData2: newData }, () => this.props.setResourceData(this.transFormDataToSave()));
  };

  toFixedTrunc(x: any, n: any) {
    const v = (typeof x === 'string' ? x : x.toString()).split('.');
    if (n <= 0) return v[0];
    let f = v[1] || '';
    if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
    while (f.length < n) f += '0';
    return `${v[0]}.${f}`;
  }

  onChangeTravelCost = (index: number, rindex: number, type: number, value: number) => {

    const newData = this.state.costData2.slice(); //copy the array
    let item = newData[index];  //execute the manipulations
    switch (type) {
      case 1:
        item.resource.Materials[rindex].travelCost = value;
        break;
      case 2:
        item.resource.Tools[rindex].travelCost = value;
        break;
      case 3:
        item.resource.Consumables[rindex].travelCost = value;
        break;
      case 4:
        item.resource.Labours[rindex].travelCost = value;
        break;
      default:
        break;
    }
    //this.setState({costData2: newData})
    this.setState({ costData2: newData }, () => this.props.setResourceData(this.transFormDataToSave()));
  };

  getCostMap = (object: any) => {
    const mp = new Map();
    Object.keys(object).forEach((k) => {
      mp.set(k, object[k]);
    });
    return mp;
  };

  getResourceCategory = (resourceId: string) => {
    switch (resourceId) {
      case 'c46c3a26-39a5-42cc-n7k1-89655304eh6':
        return 'Materials';
      case 'c46c3a26-39a5-42cc-n9wn-89655304eh6':
        return 'Tools';
      case 'c46c3a26-39a5-42cc-m06g-89655304eh6':
        return 'Consumables';
      case 'c46c3a26-39a5-42cc-b07s-89655304eh6':
        return 'Labours';
      case 'cbc3a26-cbc-cbc-cbc-89655304cbc':
        return 'CBC';
      default:
        return 'Service';
        break;
    }
  };

  getModifiedCostData = () => {
    let costListData = this.state.data.map(cost => ({
      ...cost,
      resourceCategory: this.getResourceCategory(cost.cpcResourceType),
      soldQuantity: cost.consumedQuantity,
      mouToBeInvoiced: cost.costToMou,
      travelCost: 0,
      totalSales: cost.totalCost,
    }));
    this.setState({ costData: costListData }, () => {
      this.getCostData();
    });
  };


  getResourcesSubTotal = (resource: any) => {
    let materials = resource && resource.hasOwnProperty('Materials') ? resource.Materials : [];
    let tools = resource && resource.hasOwnProperty('Tools') ? resource.Tools : [];
    let consumables = resource && resource.hasOwnProperty('Consumables') ? resource.Consumables : [];
    let labours = resource && resource.hasOwnProperty('Labours') ? resource.Labours : [];
    let services = resource && resource.hasOwnProperty('Service') ? resource.Service : [];
    let cbc = resource && resource.hasOwnProperty('CBC') ? resource.CBC : [];
    let allResources = _.concat(materials, tools, consumables, labours, services,cbc);
    let subTotal = allResources.reduce((n, { soldQuantity, spToMou }) => n + (soldQuantity * spToMou), 0);
    return subTotal;
  };

  getGrandTotal = () => {
    const userLanguage = navigator.language;
    var formatter = new Intl.NumberFormat(userLanguage, {
      style: 'currency',
      currency: 'EUR',
    });
    let subTotalArray = this.state.costData2.map(item => {
      return {
        subTotal: this.getResourcesSubTotal(item.resource),
      };
    });
    let grandTotal = subTotalArray.reduce((n, { subTotal }) => n + subTotal, 0);
    let grandTotal2 = formatter.format(grandTotal); /* $2,500.00 */
    return [grandTotal2, grandTotal];
  };

   updateSoldQuantity = (items: any[]) => {
    if (!Array.isArray(items)) {
        throw new Error('Items is not an array');
    }
  
    return items.map(item => ({
        ...item,
        soldQuantity: item.consumedQuantity
    }));
  }
  getCostData = () => {
    let formattedCostData = this.props.projectCostData.map(cost => ({
      ...cost,
      resourceCategory: this.getResourceCategory(cost.cpcResourceTypeId),
    }));
    //let costListData = this.props.projectCostData.map(cost=> ({ ...cost, resourceCategory:this.getResourceCategory(cost.cpcResourceTypeId) }))
    let products = groupArray(formattedCostData, 'productTitle');
    let productMap = this.getCostMap(products);
    let array = Array.from(productMap, ([product, resource]) => ({ product, resource }));

    let array2 = array.map(item => {
      return {
        product: item.product,
        resource: groupArray(this.updateSoldQuantity(item.resource), 'resourceCategory'),
      };
    });
    this.setState({ costData2: array2 }, () => {
      this.getCostTableoffsetHeight();
      this.props.setResourceData(this.transFormDataToSave());
    });
  };

  private _onRenderDetailsFooter(detailsFooterProps: IDetailsFooterProps): JSX.Element {
    return (
      <DetailsRow
        {...detailsFooterProps}
        columns={detailsFooterProps.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps.groupNestingDepth}
        selectionMode={SelectionMode.single}
        selection={detailsFooterProps.selection}
        onRenderItemColumn={this._renderDetailsFooterItemColumn}
        onRenderCheck={this._onRenderCheckForFooterRow}
      />
    );
  }

  private _onRenderDetailsHeader(detailsFooterProps: IDetailsFooterProps): JSX.Element {
    return (
      <DetailsRow
        {...detailsFooterProps}
        columns={detailsFooterProps.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps.groupNestingDepth}
        selectionMode={SelectionMode.single}
        selection={detailsFooterProps.selection}
        onRenderItemColumn={this._renderDetailsHeaderItemColumn}
        onRenderCheck={this._onRenderCheckForHeaderRow}
      />
    );
  }

  private _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] = (item, index, column) => {
    if (column && column.fieldName && column.fieldName === 'title') {
      return (
        <div>

          <b>{i18n.t('total') as any}</b>
        </div>
      );
    } else if (column && column.fieldName && column.fieldName === 'totaleSales') {
      return (
        <div style={{ marginLeft: 40, textAlign: 'right' }}>
          <b>{this.getGrandTotal()[0]}</b>
        </div>
      );
    }
    return <div></div>;
  };

  private _renderDetailsHeaderItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] = (item, index, column) => {
    if (column && column.fieldName && column.fieldName === 'title') {
      return (
        <div style={{ marginTop: 20 }}>
          <b>{i18n.t('items') as any}</b>
        </div>
      );
    } else if (column && column.fieldName && (column.fieldName === 'cquantity')) {
      return (
        <div className={'cquantity'} style={{ width: 400, paddingLeft: (this.props.isExpand) ? 30 : 2 }}>
          &nbsp;
          <Label>{i18n.t('consumedquantity') as any}
          </Label>
        </div>
      );
      return null;
    } else if (column && column.fieldName && (column.fieldName === 'costPerUnit')) {
      return (
        <div className={'costPerUnit'} style={{ width: 100, paddingLeft: (this.props.isExpand) ? 30 : 2 }}>
          {i18n.t('costEuro') as any}
          <Label>{i18n.t('costMOU') as any}</Label>
        </div>
      );
      return null;
    } else if (column && column.fieldName && (column.fieldName === 'totalCost1')) {
      return (
        <div className={'totalCost1'} style={{ width: 400, paddingLeft: (this.props.isExpand) ? 30 : 2 }}>
          &nbsp;
          <Label>
            {i18n.t('totalCost') as any}
          </Label>
        </div>
      );
      return null;
    } else if (column && column.fieldName && (column.fieldName === 'soldQuantity')) {
      return (
        <div style={{ width: 400 }}>
          &nbsp;
          <Label>
            {i18n.t('soldQuantity') as any}
          </Label>
        </div>
      );
      return null;
    } else if (column && column.fieldName && (column.fieldName === 'spMou')) {
      return (
        <div style={{ width: 400 }}>
          {i18n.t('tobeinvoicedEuro') as any}
          <Label style={{ marginLeft: (this.props.isExpand) ? 30 : 10 }}>
            {i18n.t('sPMOU') as any}
          </Label>
        </div>
      );
      return null;
    } else if (column && column.fieldName && (column.fieldName === 'totaleSales')) {
      return (
        <div style={{ width: 280, textAlign: this.props.isExpand ? 'center' : 'left' }}>
          &nbsp;
          <Label>
            {i18n.t('totalSales') as any}
          </Label>
        </div>
      );
      return null;
    } else if (column && column.fieldName && (column.fieldName === 'comments')) {
      return (
        <div style={{ width: 280, textAlign: this.props.isExpand ? 'center' : 'left' }}>
          &nbsp;
          <Label>
            {i18n.t('comments') as any}
          </Label>
        </div>
      )
    } 
    else {
      return null;
    }

  };

  detailsRowCheckStyles: Partial<IDetailsRowCheckStyles> = { root: { visibility: 'hidden' } };

  _onRenderCheckForFooterRow: IDetailsRowBaseProps['onRenderCheck'] = (props): JSX.Element => {
    return <DetailsRowCheck {...props} styles={this.detailsRowCheckStyles} selected={true}/>;
  };

  _onRenderCheckForHeaderRow: IDetailsRowBaseProps['onRenderCheck'] = (props): JSX.Element => {
    return <DetailsRowCheck {...props} styles={this.detailsRowCheckStyles} selected={true}/>;
  };


  transFormDataToSave = () => {
    let subResourceArray: any[] = this.state.costData2.map(item => {
      let materials = item.resource.hasOwnProperty('Materials') ? item.resource.Materials : [];
      let tools = item.resource.hasOwnProperty('Tools') ? item.resource.Tools : [];
      let consumables = item.resource.hasOwnProperty('Consumables') ? item.resource.Consumables : [];
      let labours = item.resource.hasOwnProperty('Labours') ? item.resource.Labours : [];
      let service = item.resource.hasOwnProperty('Service') ? item.resource.Service : [];
      let cbc = item.resource.hasOwnProperty('CBC') ? item.resource.CBC : [];
      let allResources = _.concat(materials, tools, consumables, labours, service,cbc);
      return allResources;
    });

    var merged = [].concat.apply([], subResourceArray);
    return { data: merged, total: this.getGrandTotal()[1] };
  };

   getUniqueValues = (item: any) =>{
    const uniqueValues = new Map();
    if(item!=null && item!=undefined){
      item.forEach((e : any) => {
        const key = `${e.productTitle}_${e.cpcResourceType}_${e.cpcResourceNumber}`;
        if (uniqueValues.has(key)) {
          uniqueValues.get(key).soldQuantity += e.soldQuantity;
          uniqueValues.get(key).consumedQuantity += e.consumedQuantity;
          // uniqueValues.get(key).costToMou = parseFloat(uniqueValues.get(key).costToMou) + parseFloat(e.costToMou);
          uniqueValues.get(key).spToMou += e.spToMou;
          uniqueValues.get(key).totalCost = parseFloat(uniqueValues.get(key).totalCost)+ parseFloat(e.totalCost);

          const rand = Math.floor(Math.random() * 1000) + 1;
          e.soldQuantity = 0;
          e.consumedQuantity = 0;
          e.spToMou = 0;
          e.totalCost = "0";
          uniqueValues.set(key+rand,{ ...e });

        }else {
          uniqueValues.set(key, { ...e });
        }
      });
    }
    return Array.from(uniqueValues.values());
  }

  getSaveData = () => {
    let subResourceArray: any[] = this.state.costData2.map(item => {
      let materiData = this.getUniqueValues(item.resource.Materials);
      let toolsData = this.getUniqueValues(item.resource.Tools);
      let consumablesData = this.getUniqueValues(item.resource.Consumables);
      let laboursData = this.getUniqueValues(item.resource.Labours);
      let serviceData = this.getUniqueValues(item.resource.Service);
      let cbcData = this.getUniqueValues(item.resource.CBC);
      let allResources = _.concat(materiData, toolsData, consumablesData, laboursData, serviceData,cbcData);
      return allResources;
    });

    var merged = [].concat.apply([], subResourceArray);
    return { data: merged, total: this.getGrandTotal()[1] };
  };

  changeColor = () => {
    let cquantityClass = document.getElementsByClassName('cquantity');
    for (let i = 0; i < cquantityClass.length; i++) {
      let parentElement = cquantityClass[i].parentElement;
      if (parentElement) {
        parentElement.style.borderLeft = '1px solid rgb(243, 242, 241)';
      }
    }

    let totalCost1Class = document.getElementsByClassName('totalCost1');
    for (let i = 0; i < totalCost1Class.length; i++) {
      let parentElement = totalCost1Class[i].parentElement;
      if (parentElement) {
        parentElement.style.borderRight = '1px solid rgb(243, 242, 241)';
      }
    }

  };


  render() {
        return (
      <div className={'document-pane-card progress-statement'}>
        <Link href="#" id="progress-statement" className="marginTop">
          <Stack horizontal>
            <Stack.Item grow={12}>
              <Label>4. {i18n.t('progressStatement') as any}</Label>
            </Stack.Item>

            <Stack.Item grow={6} styles={{ root: { flexDirection: 'row-reverse', alignItems: 'end' } }}>
              {(getProject() && !this.props.isApproved) ? (
                <PrimaryButton
                  text={i18n.t('projectCost')}
                  //disabled={disableSave}
                  style={{ marginTop: 0, marginBottom: 0, marginLeft: 0, float: 'right', marginRight: 20 }}
                  onClick={() => {
                    this.props.openCostModal();
                  }}
                />
              ) : <div></div>}
            </Stack.Item>

          </Stack>
        </Link>
        <div 
          className={`PScostTable wrapper-holder ${this.getClassNames().wrapper}`}
          style={{ height: (this.state.costTableHeight) ? this.state.costTableHeight : 200 }}
        >
          <ScrollablePane className={'costScrollablePane'} scrollbarVisibility={ScrollbarVisibility.auto}>
            <DetailsList
              items={this.state.costData2}
              columns={this.props.isExpand ? this._columnsExpand : this._columns}
              styles={{
                root: {
                  selectors: {
                    ':hover': {
                      background: 'transparent',
                    },
                  },
                },
              }}
              setKey="set"
              compact={false}
              constrainMode={ConstrainMode.horizontalConstrained}
              layoutMode={DetailsListLayoutMode.justified}
              selectionMode={0}
              selection={this._selection}
              selectionPreservedOnEmptyClick={true}
              checkboxVisibility={2}
              onRenderItemColumn={(
                item: QRCodeLite,
                index?: number,
                column?: IColumn,
              ) => CostTableColumn(item, index, column, this.onChangeConsumedQuantity, this.onChangeMOUCost, this.onChangeTravelCost, this.props.isApproved || _.isNil(getProject()), this.onChangeComments)}

              onItemInvoked={() => {
              }}
              onRenderRow={this._onRenderRow}
              onRenderDetailsFooter={(props) => this._onRenderDetailsFooter(props!!)}
              onRenderDetailsHeader={(props) => this._onRenderDetailsHeader(props!!)}
              onRowDidMount={() => {
                this.changeColor();
              }}
            />
          </ScrollablePane>
        </div>
        {/*<div className={classNames.footer}>*/}
        {/*<PrimaryButton*/}
        {/*disabled={(!(this.props.formData.isSaved || this.props.formData.progressStatementId))}*/}
        {/*text={i18n.t('save')}*/}
        {/*style={{ marginTop: 20, marginBottom: 30 }}*/}
        {/*onClick={() => this.props.createPSResource(this.transFormDataToSave()) }*/}
        {/*/>*/}
        {/*</div>*/}
        <div className={'card-footer'}>
          <Separator/>
          <Stack
            styles={stackStyles}
            tokens={itemAlignmentsStackTokens}>
            <Stack.Item align="end" styles={stackFooterBarItemStyles}>
              <ActionButton
                disabled={this.props.isApproved ? true : (!(this.props.formData.isSaved || this.props.formData.progressStatementId))}
                className={this.getClassNames().actionButton}
                iconProps={saveIcon}
                allowDisabledFocus
                onClick={() => {
                  this.props.createPSResource(this.getSaveData());
                }}
              >
                <Label className={this.getClassNames().actionButton}>{i18n.t('save') as any} </Label>
              </ActionButton>
            </Stack.Item>
          </Stack>
        </div>
      </div>
    );
  }
}

export default CostTable;

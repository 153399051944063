import React from 'react';
import {connect} from 'react-redux';
import DocumentPaneComponent from './component';
import {WithTranslation} from 'react-i18next';
import _ from 'lodash';
import i18n from '../../../../i18n';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import history from '../../../history';
import {messageService} from '../../../services/messageService';
import {getProject} from '../../../shared/util';

import {getProjectDefinition, getProjectHeaderDetails} from '../../../reducers/projectReducer';
import Absence, {
  AbsenceModalDTO,
  ContractListModelDTO,
  formData,
  HumanResource,
  WorkSchedule,
} from '../../../types/humanResource';
import {
  clearFormData,
  createAbsence,
  deleteAbsence, deleteContract,
  getAbsenceLeaveTypeDropdown,
  getContractTypeDropdown,
  readAbsenceByPersonId,
  readByAbsenceId,
  readByHumanResourceId, readContractListByPersonId,
  readHumanResourceRolesDropDownData,
  readHumanResourceTaxonomyIdByPersonId,
  readLabourHistoryByPersonId,
  saveFormData,
  createContract,
} from '../../../reducers/humanResourceReducer';
import {v4 as uuidv4} from 'uuid';
import Competence, {CompetenceTaxonomy} from '../../../types/competency';
import {ActionButtonState, ProductCatalogueFilter} from '../../../types/corporateProductCatalogue';
import {CertificationTaxonomy} from '../../../types/certification';
import {
  createOrgTaxonomy,
  deleteOrgTaxonomy,
  getTaxonomyList,
  readByOrganisationSettingId,
  readOrganizationSettingTaxonomyLevels,
} from '../../../reducers/organisationSettingReducer';
import CompetenceModal, {CompetenceModalDTO} from '../../addressBook/documentPane/modal/competenceModal';
import CertificationModal, {CertificationModalDTO} from '../../addressBook/documentPane/modal/certificationModal';
import {
  createCabCertification,
  createCabCompetencies,
  deleteCabCertification,
  deleteCabCompetencies,
  readCabCertification,
  readCabCompetencies,
  saveCabAtrr,
} from '../../../reducers/addressBookReducer';
import {readCertificationDropDownData, readCertificationTaxanomyList} from '../../../reducers/certificationReducer';
import {readCompetancyTaxonomyList, readCompetencyDropDownData} from '../../../reducers/competencyReducer';
import {Selection} from '@fluentui/react/lib/DetailsList';
import {
  filterCorporateScheduleListPaneData,
  readByCorporateScheduleId,
} from '../../../reducers/corporateScheduleReducer';
import AbsenceModal from './absenceModal/component';
import {OrganisationSettingListItem, OrganisationSettingListPaneFilter} from '../../../types/organisationSetting';
import {getAllProductCatalogue} from '../../../reducers/productCatalogueReducer';
import {readCUHRbyPersonId} from '../../../reducers/visualPlanReducer';
import ContractListModel from './contractListGrid/model/component';
import { useLocation } from 'react-router';

export interface State {
  isDirty: boolean;
  redirect: boolean;
  loading: boolean;
  isClear: boolean;
  isEdit: boolean;
  isCostModalOpen: boolean;
  showPrint: boolean;
  isApproved: boolean;
  ledgerDropDowns: { key: string; text: string }[];
  generalLedger: string;
  resourceData: any;
  openPOResourceModal: boolean;
  uid: string | null;
  userRoles: any[];
  competenceSelectionDetails: {} | string;
  absenceSelectionDetails: {} | string;
  contractSelectionDetails: {} | string;
  competenceActionButtonState: ActionButtonState;
  absenceActionButtonState: ActionButtonState;
  certificationSelectionDetails: {} | string;
  certificationActionButtonState: ActionButtonState;
  isOpenCompetenceModal: boolean;
  editCompetence: any;
  editAbsence: any;
  editCertification: any;
  isCompetenceEdit: boolean;
  workSchedule: WorkSchedule[];
  isOpenAbsenceModal: boolean;
  cpcFilter: ProductCatalogueFilter;
  cabPersonId: string | null;
  absencePersonId: string | null;
  absencePersonName: string | null;
  labourPerson: {} | string;
  isOpenContractListModal: boolean;
}

interface Props extends WithTranslation {
  hrDropDowns: any;
  saveFormData: (data: HumanResource) => void;
  test: string;
  formData: any;
  readByHumanResourceId: (id: string) => void;
  competencyTaxonomyList: CompetenceTaxonomy[];
  readHumanResourceTaxonomyIdByPersonId: (personId: string) => void;
  readLabourHistoryByPersonId: (personId: string) => void;
  orgTaxonomyLevels: any[];
  userRoles: any[];
  competence?: any;
  certificationTaxonomyList?: CertificationTaxonomy[];
  certificate?: any;
  dropDowns: any;
  getTaxonomyList: any;
  compDropDowns?: any;
  certDropDowns?: any;
  createCabCertification?: any;
  readCabCompetencies?: any;
  createCabCompetencies?: any;
  readCabCertification?: any;
  deleteCabCertification?: any;
  readCertificationTaxanomyList?: any;
  readCompetancyTaxonomyList?: any;
  readCertificationDropDownData?: any;
  readCompetencyDropDownData?: any;
  readByCorporateScheduleId: (id: string) => any;
  readOrganizationSettingTaxonomyLevels: any;
  osList: OrganisationSettingListItem[];
  createAbsence: (absence: any) => any;
  createContract: (contract: any) => any;
  readByAbsenceId?: any;
  hrRolesDropdown: any[];
  leaveTypes: any[];
  readHumanResourceRolesDropDownData?: any;
  getAbsenceLeaveTypeDropdown: any;
  getContractTypeDropdown: any;
  getAllProductCatalogue: (data: ProductCatalogueFilter) => void;
  cpcFilter: ProductCatalogueFilter;
  labourHistory: any;
  absenceData: any;
  readAbsenceByPersonId: any;
  saveCabAtrr: any;
  clearFormData: any;
  coporateScheduleTime: WorkSchedule[];
  createOrgTaxonomy: any;
  deleteOrgTaxonomy: any
  readByOrganisationSettingId: (id: string) => void;
  selectedLabour: string;
  readCUHRbyPersonId: any;
  isDisabled?: boolean;
  readContractByPersonId: any;
  selectedCertificateFromListPane: any;
  triggerCertificationSavedToTrue: any;
  isCertification: boolean
}

class DocumentPane extends React.Component<any, any> {
  private _absenceSelection: Selection;
  private _contractSelection: Selection;
  private _competenceSelection: Selection;
  private _certificationSelection: Selection;
  private _activityTaskSelection: Selection;

  subscription: any;
  submitExternal: any;
  form: any;

  constructor(props: any) {
    super(props);
    this.state = {
      labourPerson: {},
      absencePersonId: '',
      absencePersonName: '',
      isDirty: false,
      isEdit: false,
      redirect: false,
      loading: false,
      formData: formData,
      showPrint: false,
      isApproved: false,
      uid: null,
      isClear: false,
      userRoles: [],
      competenceSelectionDetails: {},
      absenceSelectionDetails: {},
      contractSelectionDetails: {},
      competenceActionButtonState: {
        add: false,
        edit: true,
        remove: true,
      },
      absenceActionButtonState: {
        add: false,
        edit: true,
        remove: true,
      },
      contractActionButtonState: {
        add: false,
        edit: true,
        remove: true,
      },
      certificationSelectionDetails: {},
      certificationActionButtonState: {
        add: false,
        edit: true,
        remove: true,
      },
      isOpenCompetenceModal: false,
      editCompetence: {
        type: '',
        value: '',
      },
      editAbsence: {
        type: '',
        value: '',
      },
      editCertification: {
        type: '',
        value: '',
      },
      isCompetenceEdit: false,
      workSchedule: [
        {
          id: uuidv4(),
          day: i18n.t('monday'),
          startTime: '',
          endTime: '',
          displayOrder: '1',
          personId: this.props.formData.personId,
        },
        {
          id: uuidv4(),
          day: i18n.t('tuesday'),
          startTime: '',
          endTime: '',
          displayOrder: '2',
          personId: this.props.formData.personId,
        },
        {
          id: uuidv4(),
          day: i18n.t('wednesday'),
          startTime: '',
          endTime: '',
          displayOrder: '3',
          personId: this.props.formData.personId,
        },
        {
          id: uuidv4(),
          day: i18n.t('thursday'),
          startTime: '',
          endTime: '',
          displayOrder: '4',
          personId: this.props.formData.personId,
        },
        {
          id: uuidv4(),
          day: i18n.t('friday'),
          startTime: '',
          endTime: '',
          displayOrder: '5',
          personId: this.props.formData.personId,
        },
        {
          id: uuidv4(),
          day: i18n.t('saturday'),
          startTime: '',
          endTime: '',
          displayOrder: '6',
          personId: this.props.formData.personId,
        },
        {
          id: uuidv4(),
          day: i18n.t('sunday'),
          startTime: '',
          endTime: '',
          displayOrder: '7',
          personId: this.props.formData.personId,
        },
      ],
      isOpenAbsenceModal: false,
      isOpenContractListModal: false,
      cpcFilter: {
        title: null,
        resourceTitle: null,
        resourceTypeId: null,
        resourceFamilyId: null,
        resourceNumber: null,
        status: null,
        sorter: {
          attribute: null,
          order: null,
        },
      },
      cabPersonId: null,
    };

    this._activityTaskSelection = new Selection({
      onSelectionChanged: () => {
      },
    });

    this._absenceSelection = new Selection({
      onSelectionChanged: () => {
        this.setState(
          {
            absenceSelectionDetails: this._getAbsenceSelectionDetails(),
            absenceActionButtonState: this._getAbsenceActionButtonState(),
            editAbsence: this._absenceEditDetails(),
          },
          () => {
          },
        );
      },
    });

    this._contractSelection = new Selection({
      onSelectionChanged: () => {
        this.setState(
          {
            contractSelectionDetails: this._getContractSelectionDetails(),
            contractActionButtonState: this._getContractActionButtonState(),
            editContract: this._contractEditDetails(),
          },
          () => {
          },
        );
      },
    });

    this._competenceSelection = new Selection({
      onSelectionChanged: () => {
        this.setState(
          {
            competenceSelectionDetails: this._getCompetenceSelectionDetails(),
            competenceActionButtonState: this._getCompetenceActionButtonState(),
            editCompetence: this._competenceEditDetails(),
          },
          () => {
          },
        );
      },
    });

    this._certificationSelection = new Selection({
      onSelectionChanged: () => {
        this.setState(
          {
            certificationSelectionDetails: this._getCertificationSelectionDetails(),
            certificationActionButtonState: this._getCertificationActionButtonState(),
            editCertification: this._certificationEditDetails(),
          },
          () => {
          },
        );
      },
    });
  }

  private _absenceEditDetails() {
    const selectionCount = this._absenceSelection.getSelectedCount();
    let absence = this.state.editAbsence;

    if (selectionCount == 1) {
      this.setState({
        isAbsenceEdit: true,
      });
      let selectedAbsence = this._absenceSelection.getSelection()[0] as Absence;
      absence = {
        type: selectedAbsence.type,
        value: selectedAbsence.value,
      };
    } else {
      this.setState({
        isAbsenceEdit: false,
      });
    }
    return absence;
  }

  private _contractEditDetails() {
    const selectionCount = this._contractSelection.getSelectedCount();
    let contract = this.state.editContract;

    if (selectionCount == 1) {
      this.setState({
        isContractEdit: true,
      });
      let selectedContract = this._contractSelection.getSelection()[0] as Absence;
      contract = {
        type: selectedContract.type,
        value: selectedContract.value,
      };
    } else {
      this.setState({
        isContractEdit: false,
      });
    }
    return contract;
  }

  private _competenceEditDetails() {
    const selectionCount = this._competenceSelection.getSelectedCount();
    let competence = this.state.editCompetence;

    if (selectionCount == 1) {
      this.setState({
        isCompetenceEdit: true,
      });
      let selectedCompetence =
        this._competenceSelection.getSelection()[0] as Competence;
      competence = { selectedCompetence };
    } else {
      this.setState({
        isCompetenceEdit: false,
      });
    }
    return competence;
  }

  private _certificationEditDetails() {
    const selectionCount = this._certificationSelection.getSelectedCount();
    let certification = this.state.editCertification;

    if (selectionCount == 1) {
      this.setState({
        isCertificationEdit: true,
      });
      let selectedCompetence = this._certificationSelection.getSelection()[0] as Competence;
      certification = {
        type: selectedCompetence.type,
        value: selectedCompetence.value,
      };
    } else {
      this.setState({
        isCertificationEdit: false,
      });
    }
    return certification;
  }

  private deleteLabourHistory = (type: string) => {
    if (type === 'absence') {
      const selectedList = this._absenceSelection
        .getSelection()
        .map((item: any) => {
          return item.id;
        });
      if (selectedList.length > 0) {
        this.props.deleteAbsence(selectedList).then(() => {
          if (this.state.formData.personId) {
            this.props.readAbsenceByPersonId(this.state.formData.personId);
          }
        });
      }
    }
  };

  private deleteContract = (type: string) => {
    if (type === 'contract') {
      const selectedList = this._contractSelection
        .getSelection()
        .map((item: any) => {
          return item.id;
        });
      if (selectedList.length > 0) {
        this.props.deleteContract(selectedList).then(() => {
          if (this.state.formData.id) {
            this.props.readContractListByPersonId(this.state.formData.id);
          }
        });
      }
    }
  };

  private deleteOrganisationSettings = (type: string) => {
    if (type === 'competence') {
      const selectedList = this._competenceSelection
        .getSelection()
        .map((item: any) => {
          return item.id;
        });
      if (selectedList.length > 0) {
        this.props.deleteCabCompetencies(selectedList).then(() => {
          if (this.state.formData.personId) {
            this.props.readCabCompetencies(this.state.formData.cabPersonId);
          }
        });
      }
    }
    if (type === 'certification') {
      const selectedList = this._certificationSelection
        .getSelection()
        .map((item: any) => {
          return item.id;
        });
      if (selectedList.length > 0) {
        this.props.deleteCabCertification(selectedList).then(() => {
          if (this.state.formData.personId) {
            this.props.readCabCertification(this.state.formData.cabPersonId);
          }
          this.props.triggerCertificationSavedToTrue();
        });
      }
    }
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.userRoles !== this.props.userRoles) {
      this.setState({ userRoles: this.props.userRoles });
    }
    if (
      prevProps.isNew !== this.props.isNew ||
      prevProps.uid !== this.props.uid
    ) {
      if (this.props.isNew) {
        const formData = this.state.formData;
        formData.id = this.props.uid;
        this.setState({ formData, isClear: false });
      }
    }

    if (prevProps.hrId !== this.props.hrId) {
      if (this.props.hrId) {
        this.props.readByHumanResourceId(this.props.hrId);
      }
    }

    if (prevState.formData.cabPersonId !== this.state.formData.cabPersonId) {
      if (this.state.formData.cabPersonId) {
        this.props.readCabCompetencies(this.state.formData.cabPersonId);
        this.props.readCabCertification(this.state.formData.cabPersonId);
        this.props.readLabourHistoryByPersonId(this.state.formData.cabPersonId);
      }
    }

    if (prevState.formData.sequenceId !== this.state.formData.sequenceId) {
      if (this.state.formData.sequenceId) {
        this.props.readAbsenceByPersonId(this.state.formData.personId);
        this.props.readContractListByPersonId(this.state.formData.id);
      }
    }

    if (prevProps.formData !== this.props.formData) {
      messageService.sendMessage({
        labourPerson: {
          ...this.props,
          key: this.props.formData?.personId,
          text: this.props.formData?.personName,
        },
      });
      if (!this.state.isDirty) {
        if (!_.isEmpty(this.props.formData)) {
          const formData = this.props.formData;
          this.setState({
            formData,
            workSchedule: this.props.formData.workSchedule,
          });
        }
      } else {
        if (this.props.isNew && _.isEmpty(this.props.formData)) {
          const formData = this.state.formData;
          this.setState({
            formData: formData,
            workSchedule: this.state.formData.workSchedule,
          });
        }
      }
    }
    // if (
    //   prevProps.organizationTaxonomyLevelId !==
    //   this.props.organizationTaxonomyLevelId
    // ) {
    //   if (this.props.organizationTaxonomyLevelId) {
    //     this.props.readByOrganisationSettingId(
    //       this.props.organizationTaxonomyLevelId
    //     );
    //   }
    // }
    // if (prevProps.formData.id !== this.props.formData.id) {
    //   if (this.props.formData.id) {
    //       this.props.readByCorporateScheduleId(this.props.formData.id);
    //   }
    // }

    if ((this.state.labourPerson?.cabPersonId !== prevState.labourPerson?.cabPersonId) && this.state.labourPerson?.key) {
      this.props.readCUHRbyPersonId(this.state.labourPerson.key).then((response: any) => {
        this.props.readByHumanResourceId(response.payload.data.result?.sequenceId);
        // if(response.payload.data.result){
        //   this.props.readByHumanResourceId(response.payload.data.result);
        // }
      });
    }
  }

  componentDidMount() {
    if (
      location.pathname.split('/').pop() === 'new' &&
      this.state.formData &&
      this.state.formData.id === null
    ) {
      this.newHumanResource();
    }
    this.props.readCertificationTaxanomyList();
    this.props.readCompetancyTaxonomyList();
    this.props.readCertificationDropDownData();
    this.props.readCompetencyDropDownData();

    // this.props.readOrganizationSettingTaxonomyLevels();
    this.props.getTaxonomyList(new OrganisationSettingListPaneFilter());

    this.props.readHumanResourceRolesDropDownData();
    this.props.getAbsenceLeaveTypeDropdown();
    this.props.getContractTypeDropdown();

    this.props.getAllProductCatalogue(this.state.cpcFilter);

    this.setState({
      uid: uuidv4(),
      isClear: this.props.isNewHR,
    });

    if (this.props.formData.cabPersonId && !this.props.isNewHR) {
      this.props.readCabCompetencies(this.props.formData.cabPersonId);
      this.props.readCabCertification(this.props.formData.cabPersonId);
      this.props.readLabourHistoryByPersonId(this.props.formData.cabPersonId);
      this.props.readAbsenceByPersonId(this.props.formData.personId);
      this.props.readContractListByPersonId(this.props.formData.id);
    }

    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewHR) {
          this.newHumanResource();
        }
        if (data.data.saveHRData) {
          // this.props.saveFormData();
          this.submitExternal();
        }
        if (data.data.hrFilter) {
          this.setState({ filter: data.data.hr });
        }
        if (data.data.pdfGenerate) {
          this.setState({ showPrint: true });
        }
        if (data.data.labourPerson) {
          this.setState({ labourPerson: data.data.labourPerson });
        }
        if (data.data.createNewNode) {
          this.props.createOrgTaxonomy(data.data.createNewNode);
        }
        if (data.data.deleteOrgTaxonmy) {
          this.props.deleteOrgTaxonomy(data.data.deleteOrgTaxonmy.id);
        }
        if (data.data.closeTaxonomyModal) {
          this.props.readOrganizationSettingTaxonomyLevels();
          this.props.getTaxonomyList(new OrganisationSettingListPaneFilter());
        }
      }
    });
    if (getProject()) {
      const projectId = getProject();
      if (projectId) {
        this.props.getProjectHeaderDetails(projectId);
      }
    } else if (this.props.formData?.id) {
      this.props.getProjectHeaderDetails(this.props.formData?.id);
    }
    if (this.props.formData?.id) {
    }

    // if (this.props.certificate) {
      // if (this.props.selectedCertificateFromListPane) {
      //   const filtered = this.props.certificate?.filter((e: any) => e.id === this.props.selectedCertificateFromListPane?.[0]?.id)
      //   this.setState({
      //     certificationSelectionDetails: filtered
      //   })
      // }
    // }

  }

  newHumanResource = () => {
    this.props.clearFormData();
    this.props.saveCabAtrr('competence', []);
    this.props.saveCabAtrr('certificate', []);
    if (this.form) {
      this.form.reset();
      // this.form.resetFieldState('name');
      // this.form.resetFieldState('title');
    }
    let formData: any = {
      id: null,
      sequenceId: null,
      title: null,
      name: null,
      personId: null,
      azureOid: null,
      organizationTaxonomyId: null,
      personName: null,
      role: null,
      // ....
    };
    this.setState({
      formData: formData,
      uid: uuidv4(),
      isClear: true,
      workSchedule: this.props.coporateScheduleTime,
    });
  };

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { psFilter: this.state.filter },
    });
  };

  setSubmitExternal = (submit: any) => {
    this.submitExternal = submit;
  };

  setForm = (form: any) => {
    this.form = form;
  };

  readHumanResourceTaxonomyIdByPersonId = (personId: string) => {
    this.props.readHumanResourceTaxonomyIdByPersonId(personId).then(() => {
      this.form.mutators.setTaxonomyId(this.props.organizationTaxonomyLevelId?.taxonomyId);
      this.form.mutators.setAzureOid(this.props.organizationTaxonomyLevelId?.azureOid);
    });
  };

  onChangeStartTime = (index: number, value: any) => {
    if (
      this.state.workSchedule &&
      !_.isEmpty(this.state.workSchedule) &&
      this.state.workSchedule.length > index
    ) {
      let workSchedule = this.state.workSchedule;
      let task = workSchedule[index];
      task.startTime = value;
      this.setState(
        (prevState: any) => ({
          workSchedule,
          isClear: false,
        }),
        () => {
        },
      );
    }
  };

  onChangeEndTime = (index: number, value: any) => {
    if (
      this.state.workSchedule &&
      !_.isEmpty(this.state.workSchedule) &&
      this.state.workSchedule.length > index
    ) {
      let workSchedule = this.state.workSchedule;
      let task = workSchedule[index];
      task.endTime = value;
      this.setState(
        (prevState: any) => ({
          workSchedule,
          isClear: false,
        }),
        () => {
        },
      );
    }
  };

  _setDefaultselection = (index: number) => {
    this._certificationSelection.setAllSelected(false);
    if (index === -1) {
      this._certificationSelection.toggleIndexSelected(0)
      this._certificationSelection.isIndexSelected(0);
    } else {
      this._certificationSelection.toggleIndexSelected(index);
    }
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <RouteLeavingGuard
          when={this.state.isDirty}
          navigate={(path) => this.handleDiscardChangesNavigate(path)}
          shouldBlockNavigation={() => {
            return true;
          }}
          handleConfirmation={(isConfirm: boolean) => {
            this.setState({ isDirty: !isConfirm });
          }}
        />
        <DocumentPaneComponent
          submit={(submit: any) => {
            this.setSubmitExternal(submit);
          }}
          saveFormData={this.props.saveFormData}
          uid={this.state.uid}
          formData={this.props.formData}
          showProgress={this.props.isLoadingHumanResource}
          formValues={this.form?.getState()?.values}
          isClear={this.state.isClear}
          isExpand={this.props.isExpand}
          setForm={(form: any) => {
            this.setForm(form);
          }}
          competencyTaxonomyList={this.props.competencyTaxonomyList}
          readHumanResourceTaxonomyIdByPersonId={(personId: string) => this.readHumanResourceTaxonomyIdByPersonId(personId)}
          hrRolesDropdown={this.props.hrRolesDropdown}
          leaveTypes={this.props.leaveTypes}
          openCompetenceModal={() => {
            this.openCompetenceModal();
          }}
          competenceSelection={this._competenceSelection}
          competenceSelectionDetails={this.state.competenceSelectionDetails}
          competenceActionButtonState={this.state.competenceActionButtonState}
          absenceSelection={this._absenceSelection}
          absenceSelectionDetails={this.state.absenceSelectionDetails}
          absenceActionButtonState={this.state.absenceActionButtonState}
          deleteLabourHistory={(type: string) => this.deleteLabourHistory(type)}
          deleteContract={(type: string) => this.deleteContract(type)}
          deleteOrganisationSettings={(type: string) => this.deleteOrganisationSettings(type)}
          competenceList={this.props.competence}
          certificationTaxonomyList={this.props.certificationTaxonomyList
            ? this.props.certificationTaxonomyList
            : []}
          openCertificationModal={() => {
            this.openCertificationModal();
          }}
          openAbsenceModal={() => {
            this.openAbsenceModal();
          }}
          certificationSelection={this._certificationSelection}
          certificationSelectionDetails={this.state.certificationSelectionDetails}
          certificationActionButtonState={this.state.certificationActionButtonState}
          certificationList={this.props.certificate}
          dropDowns={{ ...this.props.dropDowns, osList: this.props.osList }}
          orgTaxonomyLevels={this.props.orgTaxonomyLevels}
          workSchedule={this.state.workSchedule}
          activityTaskSelection={this._activityTaskSelection}
          onChangeStartTime={(index: number, value: string | number) =>
            this.onChangeStartTime(index, value)
          }
          onChangeEndTime={(index: number, value: string | number) =>
            this.onChangeEndTime(index, value)
          }
          labourHistory={this.props.labourHistory}
          absenceData={this.props.absenceData}
          labourPerson={this.state.labourPerson}
          isDisabled={this.props.isDisabled}
          openContractListModal={() => {
            this.openContractListModal();
          }}
          contractListData={this.props.contractListData}
          contractSelection={this._contractSelection}
          contractSelectionDetails={this.state.contractSelectionDetails}
          contractActionButtonState={this.state.contractActionButtonState}
          setDefaultCertificateSelection={(index: number) => this._setDefaultselection(index)}
          selectedCertificateFromListPane={this.props.selectedCertificateFromListPane}
          isCertification={this.props.isCertification}
        />

        <CompetenceModal
          isOpen={this.state.isOpenCompetenceModal}
          isEdit={this.state.isCompetenceEdit}
          openCompetenceModal={this.openCompetenceModal}
          title={i18n.t('competencies')}
          valueLable={i18n.t('Account')}
          organisationSelectionDetails={
            this.state.competenceSelectionDetails as CompetenceModalDTO[]
          }
          isChecking={this.state.isCheckingUniqContact}
          isUniq={this.state.isUniqContact}
          isQniqContact={this.props.isQniqContact}
          dropDowns={this.props.compDropDowns}
          competencyTaxonomyList={
            this.props.competencyTaxonomyList
              ? this.props.competencyTaxonomyList
              : []
          }
          competenciesTaxonomyId={this.state.competenciesTaxonomyId}
          setCompetenciesTaxonomyId={(
            competenciesTaxonomyId: string | null,
          ) => {
            this.setState({ competenciesTaxonomyId: competenciesTaxonomyId });
          }}
          createCabCompetencies={(competence: any) => {
            this.onCreateCabCompetencies(competence);
          }}
        />

        <CertificationModal
          isOpen={this.state.isOpenCertificationModal}
          isEdit={this.state.isCertificationEdit}
          openCertificationModal={this.openCertificationModal}
          title={i18n.t('certifications')}
          valueLable={i18n.t('Account')}
          organisationSelectionDetails={
            this.state.certificationSelectionDetails as CertificationModalDTO[]
          }
          isChecking={this.state.isCheckingUniqContact}
          isUniq={this.state.isUniqContact}
          isQniqContact={this.props.isQniqContact}
          dropDowns={this.props.certDropDowns}
          certificationTaxonomyList={
            this.props.certificationTaxonomyList
              ? this.props.certificationTaxonomyList
              : []
          }
          certificationTaxonomyId={this.state.certificationTaxonomyId}
          setCertificationTaxonomyId={(
            certificationTaxonomyId: string | null,
          ) => {
            this.setState({ certificationTaxonomyId: certificationTaxonomyId });
          }}
          createCabCertification={(certificate: any) => {
            this.onCreateCabCertification(certificate);
          }}
          triggerCertificationSavedToTrue={this.props.triggerCertificationSavedToTrue}
        />

        <AbsenceModal
          isOpen={this.state.isOpenAbsenceModal}
          isEdit={this.state.isCertificationEdit}
          openAbsenceModal={this.openAbsenceModal}
          title={i18n.t('absence')}
          labourHistorySelectionDetails={
            this.state.absenceSelectionDetails as AbsenceModalDTO[]
          }
          onChangeStartTime={(index: number, value: string | number) =>
            this.onChangeStartTime(index, value)
          }
          onChangeEndTime={(index: number, value: string | number) =>
            this.onChangeEndTime(index, value)
          }
          createAbsence={(absence: any) => {
            this.onCreateAbsence(absence);
          }}
          labourPerson={this.state.labourPerson}
          leaveTypes={this.props.leaveTypes}
        />

        <ContractListModel
          isOpen={this.state.isOpenContractListModal}
          isEdit={this.state.isCertificationEdit}
          openContractListModel={this.openContractListModal}
          title={i18n.t('contractList')}
          contractSelectionDetails={
            this.state.contractSelectionDetails as ContractListModelDTO[]
          }
          onChangeStartTime={(index: number, value: string | number) =>
            this.onChangeStartTime(index, value)
          }
          onChangeEndTime={(index: number, value: string | number) =>
            this.onChangeEndTime(index, value)
          }
          createContract={(contract: any) => {
            this.onCreateContract(contract);
          }}
          contractTypes={this.props.contractTypes}
          hrId={this.state.formData.id}
        />
      </div>
    );
  }

  // private GetCorporateShedule = () => {
  //   this.props.readByCorporateScheduleId('CS-0067').then((response: any) => {
  //     console.log(response.payload.data.result.corporateSheduleTime);
  //   })
  // };

  private onCreateAbsence = (absence: any) => {
    if (!absence.id) {
      absence.id = uuidv4();
    }
    this.props.createAbsence(absence).then(() => {
      this.props.readAbsenceByPersonId(absence.person);
    });
  };

  private onCreateContract = (contract: any) => {
    this.props.createContract(contract).then(() => {
      this.props.readContractListByPersonId(this.state.formData.id);
    });
  };

  private onCreateCabCompetencies = (competence: any) => {
    if (!competence.id) {
      competence.id = uuidv4();
    }
    competence.personId = this.state.formData.cabPersonId;
    this.props.createCabCompetencies(competence).then(() => {
      this.props.readCabCompetencies(competence.personId);
    });
  };

  private onCreateCabCertification = (certificate: any) => {
    if (!certificate.id) {
      certificate.id = uuidv4();
    }
    certificate.personId = this.state.formData.cabPersonId;
    this.props.createCabCertification(certificate).then(() => {
      this.props.readCabCertification(certificate.personId);
    });
  };

  private openCompetenceModal = (): void => {
    this.setState({
      isOpenCompetenceModal: !this.state.isOpenCompetenceModal,
    });
  };

  private openCertificationModal = (): void => {
    this.setState({
      isOpenCertificationModal: !this.state.isOpenCertificationModal,
      //   editCompetence: this._competenceEditDetails(),
    });
  };

  private openAbsenceModal = (): void => {
    this.setState({
      isOpenAbsenceModal: !this.state.isOpenAbsenceModal,
      //   editAbsence: this._absenceEditDetails(),
    });
  };

  private openContractListModal = (): void => {
    this.setState({
      isOpenContractListModal: !this.state.isOpenContractListModal,
    });
  };

  private _getAbsenceSelectionDetails(): string | number | {} | [] {
    return this._absenceSelection.getSelection();
  }

  private _getContractSelectionDetails(): string | number | {} | [] {
    return this._contractSelection.getSelection();
  }

  private _getCompetenceSelectionDetails(): string | number | {} | [] {
    return this._competenceSelection.getSelection();
  }

  private _getCertificationSelectionDetails(): string | number | {} | [] {
    return this._certificationSelection.getSelection();
  }

  private _getAbsenceActionButtonState(): ActionButtonState {
    const selectionCount = this._absenceSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }

    return disabled;
  }

  private _getContractActionButtonState(): ActionButtonState {
    const selectionCount = this._contractSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }

    return disabled;
  }

  private _getCompetenceActionButtonState(): ActionButtonState {
    const selectionCount = this._competenceSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }

    return disabled;
  }

  private _getCertificationActionButtonState(): ActionButtonState {
    const selectionCount = this._certificationSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }

    return disabled;
  }
}


const mapStateToProps = (state: any) => {
  return {
    isEdit: state.ps.isEdit,
    isNew: state.ps.isNew,
    uid: state.ps.uid,
    projectHeader: state.project.projectHeader,
    showProgressBar: state.hr.showProgressBar,
    selectedProject: state.project.selectedProject,
    // dropDowns: state.hr.hrDropDowns,
    compDropDowns: state.comp.compDropDowns,
    certDropDowns: state.cert.certDropDowns,
    competencyTaxonomyList: state.comp.competencyTaxonomyList,
    certificationTaxonomyList: state.cert.certificationTaxonomyList,
    organizationTaxonomyLevelId: state.hr.organizationTaxonomyLevelId,
    userRoles: state.project.userRoles,
    competence: state.addressBook.competence,
    certificate: state.addressBook.certificate,
    orgTaxonomyLevels: state.os.orgTaxonomyLevels,
    osList: state.os.taxonomyList,
    hrRolesDropdown: state.hr.hrRolesDropdown,
    labourHistory: state.hr.labourHistory,
    absenceData: state.hr.absenceData,
    hrId: state.hr.hrId,
    leaveTypes: state.hr.leaveTypes,
    selectedLabour: state.vp.selectedLabour,
    contractListData: state.hr.contractListData,
    contractTypes: state.hr.contractTypes,
  };
};

const mapDispatchToProps = {
  saveFormData,
  getProjectHeaderDetails,
  getProjectDefinition,
  readByHumanResourceId,
  readHumanResourceTaxonomyIdByPersonId,
  readHumanResourceRolesDropDownData,
  readLabourHistoryByPersonId,
  clearFormData,
  //cpc
  getAllProductCatalogue,
  //absence
  deleteAbsence,
  getAbsenceLeaveTypeDropdown,
  getContractTypeDropdown,
  createAbsence,
  readByAbsenceId,
  readAbsenceByPersonId,
  //corporate schedule
  filterCorporateScheduleListPaneData,
  readByCorporateScheduleId,
  //organization settings
  readOrganizationSettingTaxonomyLevels,
  readByOrganisationSettingId,
  getTaxonomyList,
  createOrgTaxonomy,
  deleteOrgTaxonomy,
  //competencies/certifications
  saveCabAtrr,
  createCabCertification,
  readCabCompetencies,
  deleteCabCompetencies,
  createCabCompetencies,
  readCabCertification,
  deleteCabCertification,
  readCertificationTaxanomyList,
  readCompetancyTaxonomyList,
  readCertificationDropDownData,
  readCompetencyDropDownData,
  readCUHRbyPersonId,
  //contract
  readContractListByPersonId,
  deleteContract,
  createContract,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentPane);

import * as React from 'react';
import UprinceNavigation from '../../shared/uprinceNavigation/cuProjectNavigation/component';
import { getContractingUnit, getProject } from '../../shared/util';
import WbsMainLayout from '../wbs/wbsMainLayout';
import WbsTaskMainLayout from './WbsTaskMainLayout';
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';

const WbsTask = (props:{currentUser:any}) => {

 return (
  <>
   <UprinceNavigation
    currentUser={props.currentUser}
    selectedNavigationIndex={`/CU/${getContractingUnit()}/project/${getProject()}/wbs`}
  >
    <FluentProvider theme={teamsLightTheme}>
      <WbsTaskMainLayout/>
    </FluentProvider>
  </UprinceNavigation>
  </>
 );

};

export default WbsTask

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  DetailsListLayoutMode,
  DetailsRow,
  Dropdown,
  Fabric,
  IColumn,
  Icon,
  IDetailsFooterProps,
  IDetailsListProps,
  IDetailsRowStyles,
  IDropdownOption,
  Modal,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
  Text,
  TextField,
  getTheme,
  FontWeights,
  IconButton,
  IIconProps,
  TooltipHost,
  ITooltipProps,
  TooltipDelay,
  DirectionalHint,
  ITooltipHostStyles
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { HumanResourceListItem, HumanResourceListPaneFilter } from '../../../types/humanResource';
import CertificationTypeHierarchyComponent from './HiererchyComponent';
import { formatDate } from '../../../shared/util';
import client from '../../../api';
import { LOAD_HR_CERTIFICATIONS_EP } from '../../../shared/endpoints';
import DocumentPreview from './DocumentPreview';
import { uPrinceTheme } from '../../../../theme';
import '../../../../app/components/style/style.css'
import { useId } from '@uifabric/react-hooks';
import CustomDatePicker from '../../../shared/customDatePicker/customDatePicker';

export const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 25vh)!important',
    position: 'relative',
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: {
    margin: 0,
  },
  row: {
    display: 'inline-block',
  },
  dateFilterContainer: {
    display: 'flex', 
    width: '100%', 
    justifyContent: 'space-between'
  },
  width80: {
    width: '80%'
  }
});

const theme = getTheme();
const contentStyles = mergeStyleSets({
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  modalContainer: {
    width: '80%',
    height: '75%'
  },
  previewContent: {
    width: '100%',
    textAlign: 'center'
  }
});
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: { color: theme.palette.neutralDark },
};

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

const ListPaneComponent = (props: {
  filter: HumanResourceListPaneFilter;
  selection: any;
  columns: IColumn[];
  listPaneItems: HumanResourceListItem[];
  handleListPaneItemClick: (id: string) => void;
  handleFilterChange: (filter: HumanResourceListPaneFilter) => void;
  isDataLoaded?: boolean;
  loadMsg: string;
  resetSelection: () => void;
  dropDownOptions: any;
  selectedList: any;
  selectedCertificateFromListPane: any;
  triggerCertificationSavedToFalse: any;
  isCertificationSaved: boolean;
  isCertification: boolean
}) => {
  const { t } = useTranslation();
  const isCertificate = props.isCertification
  const tooltipId = useId('tooltip');

  const [filter, setFilter] = useState<HumanResourceListPaneFilter>(props.filter);
  const [selKey, setSelKey] = useState<any>('null')

  const [selectedCertificateURL, setSelectedCertificateURL] = useState<any>(null)
  const [open, setOpen] = useState(false)

  const [certData, setCertData] = useState<any>([])
  const [certFilterData, setCertFilterData] = useState({
    "certificationId": null,
    "certificationTitle": null,
    "person": null,
    "expirationIndicator": null,
    "startDate": null,
    "endDate": null,
    "sorter": {
        "attribute": "fullname",
        "order": "asc"
    }
  })

  const onClickDocPreview = (item: any) => {
    if (item?.certificationUrl) {
      setSelectedCertificateURL(item?.certificationUrl)
      setOpen(true)
    } else {
      alert(`No Documents attached to this certification (${item.certificate}-${item.certificationTitle})`)
    }
  }

  const tooltipProps: ITooltipProps = {
    onRenderContent: () => (
      <p style={{fontWeight: 'bold'}}>{t('preview')}</p>
    ),
  };
  
  const certificationColumns: IColumn[] = [
    {
      key: 'column7',
      name: '',
      fieldName: 'preview',
      minWidth: 40,
      maxWidth: 40,
      onRender : (item, index, column) => {
        return (
          <div className={contentStyles.previewContent}>
            <TooltipHost
              tooltipProps={tooltipProps}
              delay={TooltipDelay.zero}
              id={tooltipId}
              directionalHint={DirectionalHint.rightCenter}
              styles={hostStyles}
            >
              <Icon iconName='EntryView' className='preview-button' style={{cursor: 'pointer'}} onClick={() => onClickDocPreview(item)}/>
            </TooltipHost>
          </div>
        )
      },
    },
    {
      key: 'column1',
      name: t('certificate'),
      fieldName: 'certificate',
      minWidth: 165,
      maxWidth: 200,
      isResizable: true,
      onColumnClick: _onColumnClick,
    },
    {
      key: 'column2',
      name: t('certiificateTitle'),
      fieldName: 'certificationTitle',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onColumnClick: _onColumnClick,
    },
    {
      key: 'column3',
      name: t('user'),
      fieldName: 'fullName',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onColumnClick: _onColumnClick,
    },
    {
      key: 'column4',
      name: t('startDate'),
      fieldName: 'startDate',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onColumnClick: _onColumnClick,
      // onRender: (item: any) => {
      //   const formatted = item?.startDate?.split('T')?.[0]
      //   return (
      //     <span>{formatted}</span>
      //   )
      // }
    },
    {
      key: 'column5',
      name: t('endDate'),
      fieldName: 'endDate',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onColumnClick: _onColumnClick,
      // onRender: (item: any) => {
      //   const formatted = item?.endDate?.split('T')?.[0]
      //   return (
      //     <span>{formatted}</span>
      //   )
      // }
    },
    {
      key: 'column6',
      name: t('expirationIndicator'),
      fieldName: 'expirationIndicator',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      // onColumnClick: this._onColumnClick,
    },
  ];
  const [sortedColumns, setSortedColumns] = useState<any>(certificationColumns)

  function _onColumnClick(
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void {
    ev.preventDefault();
    setCertFilterData(prevState => ({
      ...prevState,
      sorter: {
        attribute: `${column.fieldName}`,
        order: 'asc'
      }
    }));
  }
  
  useEffect(() => {
    if (isCertificate) {
      getAllCertificationsList(certFilterData)
    }
  }, [isCertificate, certFilterData, props.isCertificationSaved])
  
  useEffect(() => {
    if (props.isCertificationSaved) {
      props.triggerCertificationSavedToFalse();
      getAllCertificationsList(certFilterData);
    }
  }, [props.isCertificationSaved])

  const getAllCertificationsList = async (filters: any) => {
    await client.post(LOAD_HR_CERTIFICATIONS_EP, filters).then((res) => {
      if (res.status === 200) {
        const transformData = res?.data?.result?.map((each: any) => {
          return {
            ...each,
            startDate: each?.startDate?.split('T')?.[0],
            endDate: each?.endDate?.split('T')?.[0]
          }
        })
        setCertData(transformData)
      }
    }).catch((err) => {
      alert('Something went wrong while fetching certification list');
      setCertData([])
    })
  }

  useEffect(() => {
    setFilter(props.filter);
  }, [props.filter]);

  const _onRenderRow: IDetailsListProps['onRenderRow'] = (row) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (row) {
      return (
        <span
          style={{ textDecoration: 'none' }}
          onClick={() => {
            // chnage item id name
            if (isCertificate) {
              props.selectedCertificateFromListPane(row.item)
              props.handleListPaneItemClick(row.item.sequenceId);
            } else {
              props.handleListPaneItemClick(row.item.sequenceId);
            }
          }}
        >
          <DetailsRow {...row} styles={customStyles}/>
        </span>
      );
    }
    return null;
  };

  const _onRenderDetailsFooter = (
    detailsFooterProps: IDetailsFooterProps | undefined,
  ) => {

    return (
      <DetailsRow
        className="footer-as-filter"
        {...detailsFooterProps}
        columns={detailsFooterProps?.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps?.groupNestingDepth}
        selection={detailsFooterProps?.selection}
        onRenderItemColumn={isCertificate ? _renderFilterColumnForCertifications : _renderFilterItemColumn}
        // onRenderCheck={_onRenderCheckForFooterRow}
      />
    );
  };

  const experianIndicatorDropdownOptions: any = [
    {key: 'null', text: 'Not expired'},
    {key: 'Expire with in one week', text: 'Expire with in one week'},
    {key: 'Expire with in four weeks', text: 'Expire with in four weeks'},
    {key: 'Expire with in two Months', text: 'Expire with in two Months'},
    {key: 'Expired', text: 'Expired'},
  ]

  const onFilterCertifications = ( key: any, received: any) => {
    setCertFilterData(prevState => ({
      ...prevState,
      [key]: received === 'null' ? null : received
    }));
  }

  const _renderFilterColumnForCertifications = (item?: HumanResourceListItem,
    index?: number,
    column?: IColumn) => {
      switch (column?.fieldName) {
        case 'certificate': {
          return <CertificationTypeHierarchyComponent width={'100%'} value={""} onApplyFilter={(received) => onFilterCertifications('certificationId', [received.key])}/>
        }

        case 'certificationTitle': {
          return <TextField
                  onChange={(event, value) => onFilterCertifications('certificationTitle', value)}
                />
        }

        case 'fullName': {
          return <TextField
                  onChange={(event, value) => onFilterCertifications('person', value)}
                />
        }

        case 'startDate': {
          return (
            <div className={classNames.dateFilterContainer}>
              <div className={classNames.width80}>
                <CustomDatePicker
                  setMinDate={null}
                  setMaxDate={null}
                  setValue={formatDate(certFilterData?.startDate, 1)}
                  getValue={(date: Date) => {
                    const startDateSel = formatDate(date, 2)
                    setCertFilterData((prev: any) => ({
                      ...prev,
                      startDate: startDateSel
                    }));
                  }}
                />
              </div>
              <IconButton
                iconProps={cancelIcon}
                ariaLabel="Close popup modal"
                onClick={() => {
                  setCertFilterData((prev: any) => ({
                    ...prev,
                    startDate: null
                  }));
                }}
              />
            </div>
        )}

        case 'endDate': {
          return (
            <div className={classNames.dateFilterContainer}>
              <div className={classNames.width80}>
                <CustomDatePicker
                  setMinDate={null}
                  setMaxDate={null}
                  setValue={formatDate(certFilterData?.endDate, 1)}
                  getValue={(date: Date) => {
                    const endDateSel = formatDate(date, 2)
                    setCertFilterData((prev: any) => ({
                      ...prev,
                      endDate: endDateSel
                    }));
                  }}
                />
              </div>
              <IconButton
                iconProps={cancelIcon}
                ariaLabel="Close popup modal"
                onClick={() => {
                  setCertFilterData((prev: any) => ({
                    ...prev,
                    endDate: null
                  }));
                }}
              />
            </div>
        )}

        case 'expirationIndicator': {
          return <Dropdown 
                    options={experianIndicatorDropdownOptions} 
                    onChange={(event, value) => {
                      setSelKey(value?.key);
                      onFilterCertifications('expirationIndicator', value?.key)
                    }} selectedKey={selKey}
                  />
        }

        // case 'preview': {
        //   return null
        // }
      }
  }

  const _renderFilterItemColumn = (
    item?: HumanResourceListItem,
    index?: number,
    column?: IColumn,
  ) => {
    switch (column?.key) {
      case 'column1': {
        return (
          <div>
            <TextField
              value={filter?.fullName ? filter?.fullName.toString() : ''}
              onChange={(event, value) => {
                if (value) {
                  //       setFilter((prevState) => ({
                  //     ...prevState,
                  //     fullName: value
                  // }));
                  props.filter.fullName = value;
                } else {
                  props.filter.fullName = null;
                }
                props.handleFilterChange(filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column2': {
        return (
          <div>
            <TextField
              value={filter?.jobTitle ? filter?.jobTitle.toString() : ''}
              onChange={(event, value) => {
                if (value) {
                  //       setFilter((prevState) => ({
                  //     ...prevState,
                  //     jobTitle: value
                  // }));
                  props.filter.jobTitle = value;
                } else {
                  props.filter.jobTitle = null;
                }
                props.handleFilterChange(filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column3': {
        return (
          <div>
            <TextField
              value={filter?.organization ? filter?.organization.toString() : ''}
              onChange={(event, value) => {
                if (value) {
                  //       setFilter((prevState) => ({
                  //     ...prevState,
                  //     organization: value
                  // }));
                  props.filter.organization = value;
                } else {
                  props.filter.organization = null;
                }
                props.handleFilterChange(filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column4': {
        return (
          <div>
            <TextField
              value={filter?.email ? filter?.email.toString() : ''}
              onChange={(event, value) => {
                if (value) {
                  //       setFilter((prevState) => ({
                  //     ...prevState,
                  //     email: value
                  // }));
                  props.filter.email = value;
                } else {
                  props.filter.email = null;
                }
                props.handleFilterChange(filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column5': {
        return (
          <div>
            <TextField
              value={filter?.mobile ? filter?.mobile.toString() : ''}
              onChange={(event, value) => {
                if (value) {
                  //       setFilter((prevState) => ({
                  //     ...prevState,
                  //     mobile: value
                  // }));
                  props.filter.mobile = value;
                } else {
                  props.filter.mobile = null;
                }
                props.handleFilterChange(filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column6': {
        return (
          <div>
            <TextField
              value={filter?.status ? filter?.status.toString() : ''}
              onChange={(event, value) => {
                if (value) {
                  //       setFilter((prevState) => ({
                  //     ...prevState,
                  //     status: value
                  // }));
                  props.filter.status = value;
                } else {
                  props.filter.status = null;
                }
                props.handleFilterChange(filter);
              }}
            />
          </div>
        );
        break;
      }
      default: {
        break;
      }
    }
  };


  const displayMessage = () => {
    return (
      <div style={{ paddingTop: 36, paddingLeft: 20 }}>
        <Text>{t(props.loadMsg)}</Text>
      </div>
    );
  };
  return (
    <Fabric>
      <div className={`wrapper-holder ${classNames.wrapper}`}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <ShimmeredDetailsList
            items={isCertificate ? certData : props.listPaneItems}
            columns={isCertificate ? sortedColumns : props.columns}
            setKey="set"
            enableShimmer={!props.isDataLoaded}
            layoutMode={isCertificate ? DetailsListLayoutMode.fixedColumns : DetailsListLayoutMode.justified}
            selectionPreservedOnEmptyClick={true}
            className={isCertificate ? 'time-clock-details-list' : ''}
            checkboxVisibility={1}
            onRenderRow={_onRenderRow}
            onRenderDetailsFooter={_onRenderDetailsFooter}
            selection={props.selection}
            selectionMode={SelectionMode.single}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            onRenderDetailsHeader={(headerProps, defaultRender) => {
              if (defaultRender !== undefined) {
                return (
                  <Sticky
                    stickyPosition={StickyPositionType.Header}
                    isScrollSynced={true}
                    stickyBackgroundColor="transparent"
                  >
                    <div>{defaultRender(headerProps)}</div>
                  </Sticky>
                );
              } else {
                return <span></span>;
              }
            }}
          />
          {props.isDataLoaded && displayMessage()}
        </ScrollablePane>
        <Modal
          titleAriaId={''}
          isOpen={open}
          onDismiss={() => {
            setOpen(false);
          }}
          isBlocking={true}
          containerClassName={contentStyles.modalContainer}
          className='custom-preview-modal-for-certifications'
        >
          <div className={contentStyles.header}>
            <span>{t('preview')}</span>
            <IconButton
              styles={cancelIconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel="Close popup modal"
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
          <DocumentPreview url={selectedCertificateURL} />
        </Modal>
      </div>
    </Fabric>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    toleranceStatesFilter: state.projectBreakdown.toleranceStatesFilter,
    productStatesFilter: state.projectBreakdown.productStatesFilter,
    itemTypesFilter: state.projectBreakdown.itemTypesFilter,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ListPaneComponent);

import {
  ActionButton,
  ChoiceGroup,
  getTheme,
  IBasePickerSuggestionsProps,
  IChoiceGroupOption,
  IIconProps,
  IImageProps,
  Image,
  ImageFit,
  IPersona,
  IPersonaProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  NormalPeoplePicker,
  Separator,
  Stack,
  TextField,
} from '@fluentui/react';
import { createForm } from 'final-form';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { uPrinceTheme } from '../../../../theme';
import CustomFileUploader from '../../../shared/fileUploader/customFileUploader';
import { CKEditorWrapper, DatePickerAdapter, DropDownAdaptater, TextFieldFF } from '../../../shared/fluentFinalForm';
import { formatDateTimeToISOString, getImages, getProject } from '../../../shared/util';
import {
  CAPACITY_REQUEST,
  GOODDEERIS_PRODUCT_TYPE_ID,
  PODropDowns,
  PURCHASE_REQUEST,
  RESOURCE_TYPE_ID,
  RETURN_REQUEST,
} from '../../../types/purchaseOrder';
import Resources from './resources/resources';
import { messageService } from '../../../services/messageService';
import CustomDropdown from '../../../shared/customDropdown/customDropdown';
import client from '../../../api';
import { AddressBookItem, CABFilter } from '../../../types/addressBookItem';
import { CAB_FILTER_EP, PURCHASE_ORDER_SAVE_EP } from '../../../shared/endpoints';
import { useTranslation } from 'react-i18next';
import AddressLocationComponent from '../../../shared/location/address/component';
import { LocationAddress, Position } from '../../../types/projectDefinition';
import HistoryBar from '../../../shared/historyBar/historyBar';
import DatePickerRequiredAdapter from '../../../shared/fluentFinalForm/DatePickerRequiredAdapter';
import ProjectHeader from '../../../shared/projectHeader/projectHeader';
import TaxonomyField from './taxonomy/taxonomy';
import {
  IN_DEVELOPMENT_STATUS_ID,
  IN_REVIEW_FEEDBACK_STATUS_ID,
  IN_REVIEW_STATUS_ID,
} from '../../../types/projectMolecule';
import MinMaxNumberFormatField from '../../../shared/fluentFinalForm/MinMaxNumberFormatField';

const theme = getTheme();

const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
});

const DocumentPaneComponent = (props: {
  submit: any;
  purchaseOrderDropDowns: PODropDowns;
  saveFormData: any;
  uid: any;
  formData: any;
  materialSelection: any;
  toolSelection: any;
  consumableSelection: any;
  labourSelection: any;
  borResources: any;
  setOpenPOResourceModal: () => void;
  isExpand: boolean;
  setForm: (form: any) => void;
  showProgress: boolean;
  formValues: any;
  isClear: boolean;
  externalProducts: any[];
  setLocationAddress: (position: Position, address: LocationAddress) => void;
  position: Position;
  address: string;
  locationId: string;
  openMapModal: () => void;
  isDisabled: boolean;
  projectHeader: any;
  totalAmount: number;
  savePOHeader: (formValue: any) => void;
  projectList: [];
  getProjectAddressById: (sequanceCode: string) => void;
  getWarehouseTaxonomy: (onProjectLevel: boolean) => void;
  hasChangeIntialFromData: boolean;
  borResourcesCount: number;
  setHasSubmitForm: (val: boolean) => void;
  hasSubmit: boolean;
  poRequestType: string | null;
  submitRfqEdit: (data: any) => any
}) => {
  const { t } = useTranslation();
  const [poResurces, setPOresources] = useState(null);

  const onSubmit = async (values: any) => {
    const sleep = (ms: number | undefined) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);
    props.setHasSubmitForm(false);
    if (!values.header.projectSequenceCode) {
      values.header.projectSequenceCode = getProject()
        ? getProject()
        : props?.formData?.projectSequenceCode;
    }
    if (!values.header.id) {
      values.header.id = props.uid;
    }
    const formData = {
      ...values?.header,
      deliveryDate: formatDateTimeToISOString(values?.header?.deliveryDate),
      expectedDate: formatDateTimeToISOString(values?.header?.expectedDate),
      totalAmount: props.totalAmount,
      locationId: props.locationId,
      ...values?.involvedParties?.customer,
      ...values?.involvedParties?.supplier,
      poResources: props.borResources,
      externalProduct: props.externalProducts,
      customerOrderDate: formatDateTimeToISOString(values?.involvedParties?.customer?.customerOrderDate),
      supplierOrderDate: formatDateTimeToISOString(values?.involvedParties?.supplier?.supplierOrderDate)
    };
    if (getProject() && formData.poStatusId === IN_DEVELOPMENT_STATUS_ID) {
      // eslint-disable-next-line no-use-before-define
      if (getBorResourceCount() !== props.borResourcesCount) {
        // console.log('inside borResourcesCount')
        messageService.sendMessage({ borResourcesDif: true });
      } else {
        props.savePOHeader(formData);
      }
    } else {
      props.savePOHeader(formData);
    }
  };

  const getBorResourceCount = () => {
    const materials =
      props.borResources && props.borResources.materials
        ? props.borResources.materials
        : [];
    const tools =
      props.borResources && props.borResources.tools
        ? props.borResources.tools
        : [];
    const consumables =
      props.borResources && props.borResources.consumable
        ? props.borResources.consumable
        : [];
    const labours =
      props.borResources && props.borResources.labour
        ? props.borResources.labour
        : [];
    const allResources = _.concat(materials, tools, consumables, labours);
    return allResources.length;
  };

  const onPurchaseOrderTypeChange = (value: null | string) => {
    messageService.sendMessage({ purchaseOrderType: value });
  };

  const getProjectHeader = (value: null | string) => {
    messageService.sendMessage({ getProjectHeader: value });
  };

  const formRef = React.useRef(createForm({ onSubmit: onSubmit }));

  useEffect(() => {
    setPOresources(props.borResources);
  }, [props.borResources]);

  const organisationOptions = (inputValue: any) =>
    new Promise((resolve) => {
      // eslint-disable-next-line no-use-before-define
      resolve(getOrganisationByName(inputValue));
    });

  const getOrganisationByName = async (name: string) => {
    if (name.length >= 2) {
      const response = await client.get(
        `CentralAddressBook/ReadCabOrganizationList/${name}`,
      );
      return formatOrganisationResponse(response);
    } else if (name === '') {
      const response = await client.get(
        `CentralAddressBook/ReadCabOrganizationList/${name}`,
      );
      return formatOrganisationResponse(response);
    }
  };

  const formatOrganisationResponse = (response: any) => {
    if (response && response.data) {
      if (response.data.result && _.isArray(response.data.result)) {
        const titleNotEmptyList = response.data.result.filter(
          (item: { name: string | null }) => item.name !== null,
        );
        const data = titleNotEmptyList.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        return data;
      }
      return [];
    }
    return [];
  };

  const formatProjectResponse = (response: any) => {
    if (response) {
      if (_.isArray(response)) {
        const titleNotEmptyList = response.filter(
          (item: { name: string | null }) => item.name !== null,
        );
        const data = titleNotEmptyList.map((item: any) => {
          return {
            value: item.key,
            label: item.text,
          };
        });
        return data;
      }
      return [];
    }
    return [];
  };

  const getCustomerContactByName = async (name: string, type: string) => {
    const formData = formRef.current.getState().values;
    let organisation = null;
    if (type === 'supplier') {
      organisation =
        formData &&
        formData.involvedParties &&
        formData.involvedParties.supplier
          ? formData.involvedParties.supplier.supplierOrganisation
          : null;
    } else {
      organisation =
        formData &&
        formData.involvedParties &&
        formData.involvedParties.customer
          ? formData.involvedParties.customer.customerOrganisation
          : null;
    }
    const cabFilter = new CABFilter();
    cabFilter.organisation = organisation;
    cabFilter.fullName = name;
    if (name.length >= 2 && organisation) {
      const response = await client.post(CAB_FILTER_EP, cabFilter);
      // eslint-disable-next-line no-use-before-define
      return formatCustomerContactResponse(response);
    } else if (name === '' && organisation) {
      const response = await client.post(CAB_FILTER_EP, cabFilter);
      // eslint-disable-next-line no-use-before-define
      return formatCustomerContactResponse(response);
    }
  };

  const formatCustomerContactResponse = (response: any) => {
    if (response && response.data) {
      if (response.data.result && _.isArray(response.data.result)) {
        const data = response.data.result.map((item: AddressBookItem) => {
          return {
            key: item.personCompany.id,
            text: item.person.fullName,
            Organisation: item.company.name,
            email: item.personCompany.email,
            mobile: item.personCompany.mobilePhone,
            secondaryText: item.personCompany ? item.personCompany.jobRole : '',
          };
        });
        return data;
      }
      return [];
    }
    return [];
  };

  const limitedSearchAdditionalProps: IBasePickerSuggestionsProps = {
    resultsMaximumNumber: 10,
    searchingText: 'Searching...',
  };

  const onCustomerContactSelected = (item: any) => {
    if (item) {
      const customerContact = {
        id: item.key,
        name: item.text,
        mobile: item.mobile,
        email: item.email,
      };
      formRef.current.mutators.setCustomerContact(customerContact);
    }
    return item;
  };

  const selectedCustomerContactItems = () => {
    let item: IPersona[] = [];
    const formData = formRef.current.getState().values;
    const customerContactId =
      formData && formData.involvedParties && formData.involvedParties.customer
        ? formData.involvedParties.customer.customerContactId
        : null;
    const customerContact =
      formData && formData.involvedParties && formData.involvedParties.customer
        ? formData.involvedParties.customer.customerContact
        : null;
    if (customerContactId) {
      const customer = { key: customerContactId, text: customerContact };
      item = [customer];
    }

    return item;
  };

  const onCustomerContactFilterPersonChanged = (
    filterText: string,
    selectedItems?: IPersonaProps[] | undefined,
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return getCustomerContactByName(filterText, 'customer');
    }
    return [];
  };

  const onSupplierContactFilterPersonChanged = (
    filterText: string,
    selectedItems?: IPersonaProps[] | undefined,
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return getCustomerContactByName(filterText, 'supplier');
    }
    return [];
  };

  const selectedSupplierContactItems = () => {
    let item: IPersona[] = [];
    const formData = formRef.current.getState().values;
    const supplierContactId =
      formData && formData.involvedParties && formData.involvedParties.supplier
        ? formData.involvedParties.supplier.supplierContactId
        : null;
    const supplierContact =
      formData && formData.involvedParties && formData.involvedParties.supplier
        ? formData.involvedParties.supplier.supplierContact
        : null;
    if (supplierContactId) {
      const supplier = { key: supplierContactId, text: supplierContact };
      item = [supplier];
    }

    return item;
  };

  const onSupplierContactSelected = (item: any) => {
    if (item) {
      const supplierContact = {
        id: item.key,
        name: item.text,
        mobile: item.mobile,
        email: item.email,
      };
      formRef.current.mutators.setSupplierContact(supplierContact);
    }
    return item;
  };

  const getPosition = (position: any) => {
    if (typeof position?.lat === 'string') {
      return { lat: parseFloat(position?.lat), lon: parseFloat(position?.lon) };
    }
    return position;
  };

  const getInitialFormValues = (formData: any, formValue: any) => {
    if (location.pathname.split('/').pop() === 'new' && props.isClear) {
      return {
        header: {
          poRequestTypeId: props.poRequestType,
          poTypeId:
            props.poRequestType === RETURN_REQUEST ? RESOURCE_TYPE_ID : props.poRequestType === CAPACITY_REQUEST ? GOODDEERIS_PRODUCT_TYPE_ID : null,
          deliveryLocation: getProject() ? null : '1',
          poStatusId: formData?.id
            ? formData?.status?.key
            : props.purchaseOrderDropDowns.states &&
            _.isArray(props.purchaseOrderDropDowns.states) &&
            props.purchaseOrderDropDowns.states.length > 0
              ? props.purchaseOrderDropDowns.states[0].key
              : null,
        },
      };
    } else if (formData && formData?.id && !props.hasChangeIntialFromData) {
      return {
        header: {
          id: formData?.id,
          title: formData?.title,
          sequenceId: formData?.sequenceId,
          name: formData?.name,
          poRequestTypeId: formData?.requestType?.key,
          poTypeId: formData?.type?.key,
          poStatusId: formData?.id
            ? formData?.status?.key
            : props.purchaseOrderDropDowns.states &&
            _.isArray(props.purchaseOrderDropDowns.states) &&
            props.purchaseOrderDropDowns.states.length > 0
              ? props.purchaseOrderDropDowns.states[0].key
              : null,
          comments: formData?.comments,
          files: formData?.files,
          delivery: props.address,
          deliveryDate: formData?.deliveryDate
            ? new Date(formData?.deliveryDate)
            : null,
          expectedDate: formData?.expectedDate
            ? new Date(formData?.expectedDate)
            : null,
          deliveryLocation: !getProject() ? formData?.deliveryLocation : null,
          warehouseTaxonomyId: formData?.warehouseTaxonomyId
            ? formData?.warehouseTaxonomyId
            : null,
          projectSequenceCode: props?.formData?.projectSequenceCode
            ? props?.formData?.projectSequenceCode
            : getProject()
              ? getProject()
              : null,
          requestedProbability: formData?.requestedProbability,
          availableProbability: formData?.availableProbability,
        },
        involvedParties: {
          customer: {
            customerOrganisation: formData?.customerOrganisation,
            customerOrganisationId: formData?.customerOrganisationId,
            customerContact: formData?.customerContact?.customerContact,
            customerContactId: formData?.customerContactId,
            customerMobile: formData?.customerContact?.customerMobile,
            customerEmail: formData?.customerContact?.customerEmail,
            customerReference: formData?.customerReference,
            customerOrderDate: formData?.customerOrderDate ? new Date(formData?.customerOrderDate) : null
          },
          supplier: {
            supplierOrganisation: formData?.supplierOrganisation,
            supplierOrganisationId: formData?.supplierOrganisationId,
            supplierContact: formData?.supplierContact?.customerContact,
            supplierContactId: formData?.supplierContactId,
            supplierMobile: formData?.supplierContact?.customerMobile,
            supplierEmail: formData?.supplierContact?.customerEmail,
            supplierReference: formData?.supplierReference,
            supplierOrderDate: formData?.supplierOrderDate ? new Date(formData?.supplierOrderDate) : null
          },
        },
      };
    }
    return formValue;
  };

  const imageProps: IImageProps = {
    src:
      props.projectHeader && props.projectHeader.image
        ? props.projectHeader.image
        : 'http://placehold.it/500x250',
    imageFit: ImageFit.none,
    width: 50,
    height: 50,
  };
  const imagePropsNew: IImageProps = {
    src: 'http://placehold.it/500x250',
    imageFit: ImageFit.none,
    width: 50,
    height: 50,
  };

  const getPoRequestType = (): boolean => {
    if (props.formData.isCu) {
      return false;
    } else if (getProject()) {
      return true;
    } else if (props.formData?.requestType?.key && !props.formData.isCu) {
      return true;
    } else {
      return false;
    }
  };

  const requestedProbabilityIsDisabled = (isDisabled: boolean, values: any) => {
    if (getProject() && values?.header?.poStatusId === IN_DEVELOPMENT_STATUS_ID) {
      return false;
    } else {
      return isDisabled;
    }
  };
  const requestedProbabilityIsVisible = () => {
    return true;
  };
  const availableProbabilityIsDisabled = (isDisabled: boolean, values: any) => {
    if (!getProject() && values?.header?.poStatusId === IN_REVIEW_FEEDBACK_STATUS_ID) {
      return false;
    } else if (!getProject() && values?.header?.poStatusId === IN_REVIEW_STATUS_ID) {
      return false;
    } else {
      return isDisabled;
    }
  };
  const availableProbabilityIsVisible = (values: any) => {
    if (getProject() && values?.header?.poStatusId === IN_DEVELOPMENT_STATUS_ID) {
      return false;
    } else if (!getProject() && values?.header?.poStatusId === IN_DEVELOPMENT_STATUS_ID) {
      return false;
    } else {
      return true;
    }
  };

  const expectedDeliveryDateIsDisabled = (isDisabled: boolean, values: any) => {
    if (!getProject() && values?.header?.poStatusId === IN_REVIEW_FEEDBACK_STATUS_ID) {
      return false;
    } else if (!getProject() && values?.header?.poStatusId === IN_REVIEW_STATUS_ID) {
      return false;
    } else {
      return true;
    }
  };

  const expectedDeliveryDateSaveIsDisabled = (isDisabled: boolean, values: any) => {
    if (isDisabled) {
      if (!getProject() && values?.header?.poStatusId === IN_REVIEW_FEEDBACK_STATUS_ID) {
        return false;
      } else if (!getProject() && values?.header?.poStatusId === IN_REVIEW_STATUS_ID) {
        return false;
      } else {
        return true;
      }
    } else {
      isDisabled;
    }
  };

  const required = (value: any) => (value ? undefined : t('projectRequired'));

  const getProjectByName = async (name: string) => {
    // if (name.length >= 2) {
    const response = props.projectList.filter(
      // (element: { text: string; value: string }) => element.text.includes(name)
      (element: { text: string; value: string }) => element.text.toLowerCase().includes(name.toLowerCase()),
    );
    return formatProjectResponse(response).sort();
  };

  const projectOptions = (inputValue: any) =>
    new Promise((resolve) => {
      // setTimeout(() => {
      resolve(getProjectByName(inputValue));
      // }, 1000);
    });

  const getProjectTitle = (sequnceCode: string) => {
    //let project = {title:""}
    let project: any = props.projectList.find(
      (element: { text: string; key: string }) => element.key === sequnceCode,
    );
    if (project) {
      return project?.title;
      return;
    } else {
      return null;
    }
  };

  const poTypeDisabled = (values: any): boolean => {
    if (values?.header?.poRequestTypeId === RETURN_REQUEST) {
      return true;
    } else if (values?.header?.poRequestTypeId === CAPACITY_REQUEST) {
      return true;
    } else {
      return props.isDisabled || props.formData?.sequenceId;
    }
  };

  const getPoTypeOptions = (values: any) => {
    if (values?.header?.poRequestTypeId === PURCHASE_REQUEST) {
      return props.purchaseOrderDropDowns?.types?.filter((item: any) => item.key != GOODDEERIS_PRODUCT_TYPE_ID);
    }
    if (!getProject()) {
      if (values?.header?.poRequestTypeId !== CAPACITY_REQUEST) {
        return props.purchaseOrderDropDowns?.types?.filter((item: any) => item.key != GOODDEERIS_PRODUCT_TYPE_ID);
      } else {
        return props.purchaseOrderDropDowns.types;
      }
    } else {
      return props.purchaseOrderDropDowns.types;
    }
  };

  console.log('props.externalProducts', props.externalProducts)

  const rfqSubmit = async (values: any) => {
    const externalProduct = [
      {
        id: props?.externalProducts?.[0]?.id,
        headerTitle: props?.externalProducts?.[0]?.headerTitle,
        key: props?.externalProducts?.[0]?.key,
        name: props?.externalProducts?.[0]?.name,
        pbsProductItemType: props?.externalProducts?.[0]?.pbsProductItemType,
        pbsProductItemTypeId: props?.externalProducts?.[0]?.pbsProductItemTypeId,
        pbsProductStatus: props?.externalProducts?.[0]?.pbsProductStatus,
        pbsProductStatusId: props?.externalProducts?.[0]?.pbsProductStatusId,
        pbsToleranceState: props?.externalProducts?.[0]?.pbsToleranceState,
        pbsToleranceStateId: props?.externalProducts?.[0]?.pbsToleranceStateId,
        productId: props?.externalProducts?.[0]?.productId,
        title: props?.externalProducts?.[0]?.title,
        pDocuments: props?.externalProducts?.[0]?.pDocuments,
        cDocuments: props?.externalProducts?.[0]?.cDocuments,
        pComment: props?.externalProducts?.[0]?.pComment,
        cComment: props?.externalProducts?.[0]?.cComment,
        pQuantity: props?.externalProducts?.[0]?.pQuantity,
        cQuantity: props?.externalProducts?.[0]?.cQuantity,
        mou: props?.externalProducts?.[0]?.mou,
        pUnitPrice: props?.externalProducts?.[0]?.pUnitPrice,
        cUnitPrice: props?.externalProducts?.[0]?.cUnitPrice,
        pTotalPrice: props?.externalProducts?.[0]?.pTotalPrice,
        cTotalPrice: props?.externalProducts?.[0]?.cTotalPrice,
        cCrossReference: props?.externalProducts?.[0]?.cCrossReference,
        pCrossReference: props?.externalProducts?.[0]?.pCrossReference,
        projectTitle: props?.externalProducts?.[0]?.projectTitle,
        startDate: props?.externalProducts?.[0]?.startDate,
        ciaw: props?.externalProducts?.[0]?.ciaw,
        productAddress: props?.externalProducts?.[0]?.productAddress,
        qualityApproverName: props?.externalProducts?.[0]?.qualityApproverName,
        foremanName: props?.externalProducts?.[0]?.foremanName,
        foremanNumber: props?.externalProducts?.[0]?.foremanNumber,
        poHeaderId: props?.externalProducts?.[0]?.poHeaderId,
        isPo: props?.externalProducts?.[0]?.isPo,
        isUsed: props?.externalProducts?.[0]?.isUsed,
        usedPoId: props?.externalProducts?.[0]?.usedPoId,
        requestedDeliveryDate: props?.externalProducts?.[0]?.requestedDeliveryDate,
        expectedDeliveryDate: props?.externalProducts?.[0]?.expectedDeliveryDate
      }
    ]
    if (values?.header?.id && values?.header?.poRequestTypeId === "f4d6ba08-3937-44ca-a0a1-7cf33c03e290") {
      const formValues = {
        ...values?.header,
        deliveryDate: formatDateTimeToISOString(values?.header?.deliveryDate),
        expectedDate: formatDateTimeToISOString(values?.header?.expectedDate),
        totalAmount: props.totalAmount,
        locationId: props.locationId,
        ...values?.involvedParties?.customer,
        ...values?.involvedParties?.supplier,
        poResources: props.borResources,
        externalProduct: externalProduct,
        customerOrderDate: formatDateTimeToISOString(values?.involvedParties?.customer?.customerOrderDate),
        supplierOrderDate: formatDateTimeToISOString(values?.involvedParties?.supplier?.supplierOrderDate)
      }
      props.submitRfqEdit(formValues)
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <div>
        <Form
          form={formRef.current}
          onSubmit={onSubmit}
          keepDirtyOnReinitialize
          // initialValuesEqual={() => undefined}
          validate={(values) => {
            const errors: any = { header: {} };

            if (!values?.header?.name && !props.showProgress) {
              errors.header.name = t('nameRequired');
            }
            if (!values?.header?.poTypeId && !props.showProgress) {
              errors.header.poTypeId = t('typeRequired');
            }
            if (getProject()) {
              if (!values?.header?.poRequestTypeId && !props.showProgress) {
                errors.header.poRequestTypeId = t('typeRequired');
              }
            }
            if (
              !getProject() &&
              !values?.header?.projectSequenceCode &&
              !props.showProgress
            ) {
              errors.header.projectSequenceCode = t('projectRequired');
            }
            const x: any = formRef.current.getState().modified;
            if (
              !values?.header?.delivery &&
              Object.values(x).some((val) => val !== false)
            ) {
              // errors.header.delivery = t('deliveryLocationRequired');
            }
            if (!getProject()) {
              if (values?.header?.poStatusId === IN_DEVELOPMENT_STATUS_ID) {
                if (!values?.header?.deliveryLocation && !props.showProgress) {
                  errors.header.deliveryLocation = t(
                    'deliveryLocationRequired',
                  );
                }
              }
            }

            // if((values?.header?.poRequestTypeId !=CAPACITY_REQUEST)){
            if (!values?.header?.deliveryDate && !props.showProgress) {
              errors.header.deliveryDate = t('requestedDateRequired');
            }
            // }

            if (!props.address && !props.showProgress) {
              errors.header.delivery = t('deliveryLocationRequired');
            }

            return errors;
          }}
          initialValues={getInitialFormValues(props.formData, props.formValues)}
          mutators={{
            setHeaderDocs: (args, state: any, utils) => {
              const field = state.fields['header.files'];
              field.change(state.formState.values?.header.files);
            },
            setFormOrganisation: (args, state, utils) => {
              const customerOrganisation =
                state.fields['involvedParties.customer.customerOrganisation'];
              const customerOrganisationId =
                state.fields['involvedParties.customer.customerOrganisationId'];
              const customerOrderDate = state.fields['involvedParties.customer.customerOrderDate']
              if (args && args[0] && args[0].name) {
                customerOrganisation.change(args[0].name);
                customerOrganisationId.change(args[0].id);
                customerOrderDate.change(args[0].customerOrderDate)
              } else {
                customerOrganisation.change(null);
                customerOrganisationId.change(null);
                customerOrderDate.change(null);
              }
            },
            setCustomerContact: (args, state, utils) => {
              const customerContact =
                state.fields['involvedParties.customer.customerContact'];
              const customerContactId =
                state.fields['involvedParties.customer.customerContactId'];
              const customerMobile =
                state.fields['involvedParties.customer.customerMobile'];
              const customerEmail =
                state.fields['involvedParties.customer.customerEmail'];
              // const customerOrderDate = state.fields['involvedParties.customer.customerOrderDate']
              if (args && args[0] && args[0].name) {
                customerContact.change(args[0].name);
                customerContactId.change(args[0].id);
                customerMobile.change(args[0].mobile);
                customerEmail.change(args[0].email);
                // customerOrderDate.change(args[0].date)
              } else {
                customerContact.change(null);
                customerContactId.change(null);
                customerMobile.change(null);
                customerEmail.change(null);
                // customerOrderDate.change(null)
              }
            },
            setSupplierOrganisation: (args, state, utils) => {
              const supplierOrganisation =
                state.fields['involvedParties.supplier.supplierOrganisation'];
              const supplierOrganisationId =
                state.fields['involvedParties.supplier.supplierOrganisationId'];
              const supplierOrderDate =
                state.fields['involvedParties.supplier.supplierOrderDate'];
                if (args && args[0] && args[0].name) {
                supplierOrganisation.change(args[0].name);
                supplierOrganisationId.change(args[0].id);
                supplierOrderDate.change(args[0].supplierOrderDate)
              } else {
                supplierOrganisation.change(null);
                supplierOrganisationId.change(null);
                supplierOrderDate.change(null);
              }
            },
            setSupplierContact: (args, state, utils) => {
              const supplierContact =
                state.fields['involvedParties.supplier.supplierContact'];
              const supplierContactId =
                state.fields['involvedParties.supplier.supplierContactId'];
              const supplierMobile =
                state.fields['involvedParties.supplier.supplierMobile'];
              const supplierEmail =
                state.fields['involvedParties.supplier.supplierEmail'];
              // const supplierOrderDate =
              //   state.fields['involvedParties.supplier.supplierOrderDate'];
              if (args && args[0] && args[0].name) {
                supplierContact.change(args[0].name);
                supplierContactId.change(args[0].id);
                supplierMobile.change(args[0].mobile);
                supplierEmail.change(args[0].email);
                // supplierOrderDate.change(args[0].date)
              } else {
                supplierContact.change(null);
                supplierContactId.change(null);
                supplierMobile.change(null);
                supplierEmail.change(null);
                // supplierOrderDate.change(null)
              }
            },
            setWarehouseTaxonomyId: async (args, state, utils) => {
              const warehouseTaxonomyId =
                state.fields['header.warehouseTaxonomyId'];
              if (args && args[0] && args[0].id) {
                warehouseTaxonomyId.change(args[0].id);
              } else {
                warehouseTaxonomyId.change(null);
              }
            },
            setDeliveryLocation: async (args, state, utils) => {
              const deliveryLocation = state.fields['header.deliveryLocation'];
              if (args && args[0]) {
                deliveryLocation.change(args[0]);
              } else {
                deliveryLocation.change(null);
              }
            },
            setDelivery: (args, state, utils) => {
              const delivery = state.fields['header.delivery'];
              if (args && args[0]) {
                delivery.change(args[0]);
              } else {
                delivery.change(null);
              }
            },
          }}
          // validate={ validate }
          render={({ handleSubmit, form, submitting, pristine, values, dirty }) => {
            props.setForm(form);
            props.submit(handleSubmit);

            return (
              <form onSubmit={handleSubmit} noValidate>
                <div className="proj-detail-block">
                  {getProject() ? (
                    <ProjectHeader projectDetails={props.projectHeader}/>
                  ) : (
                    <div className="document-pane-card">
                      <div className={'card-body'}>
                        <div className="proj-detail-content inner">
                          <div
                            className={'ms-Grid'}
                            style={{ width: '100%', height: 100 }}
                          >
                            <div
                              className="ms-Grid-row"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 10,
                              }}
                            >
                              <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 ">
                                {values.header?.projectSequenceCode ? (
                                  <Image
                                    {...imageProps}
                                    // src="http://placehold.it/100x100"
                                    style={{
                                      width: 50,
                                      height: 50,
                                      borderRadius: 50 / 2,
                                    }}
                                  />
                                ) : (
                                  <Image
                                    {...imagePropsNew}
                                    // src="http://placehold.it/100x100"
                                    style={{
                                      width: 50,
                                      height: 50,
                                      borderRadius: 50 / 2,
                                    }}
                                  />
                                )}
                              </div>
                              <div
                                style={{ marginLeft: 20 }}
                                className="ms-Grid-col ms-sm10 ms-md10 ms-lg10 "
                              >
                                {/* <Field
                                                                    name="header.projectSequenceCode"
                                                                    component={ DropDownAdaptater }
                                                                    options={ props.projectList }
                                                                    placeholder={ t('project') }
                                                                    disabled={ props.isDisabled || props.formData?.sequenceId }
                                                                    required={ true }
                                                                    parse={ value => {
                                                                        // Do what you want with `value`
                                                                        getProjectHeader(value);
                                                                        props.getProjectAddressById(value);
                                                                        return value;
                                                                    } }
                                                                /> */}
                                {/* <Field name="header.projectSequenceCode" validate={ required }>
                                                        { ({ input, meta }) => ( */}
                                <Stack
                                  horizontal
                                  wrap
                                  styles={{
                                    root: { padding: 0, marginBottom: 0 },
                                  }}
                                  tokens={{
                                    childrenGap: 0,
                                    padding: 0,
                                  }}
                                >
                                  <Stack.Item grow={6} styles={stackItemStyles}>
                                    <div className={`${classNames.fullWidth}`}>
                                      <Label required={true}>{t('project')}</Label>
                                      {(!props.formData.sequenceId && props.projectList.length > 0) ?
                                        <Field
                                          name="header.projectSequenceCode"
                                          validate={required}
                                        >
                                          {({ input, meta }) => (
                                            <CustomDropdown
                                              reference={''}
                                              onChange={(
                                                key: string,
                                                label: string,
                                              ) => {
                                                if (key) {
                                                  getProjectHeader(key);
                                                  props.getProjectAddressById(
                                                    key,
                                                  );
                                                  input.onChange(key);
                                                } else {
                                                  //form.mutators.setFormOrganisation(null);
                                                  input.onChange(null);
                                                }
                                              }}
                                              selectedOption={
                                                values &&
                                                values?.header
                                                  ?.projectSequenceCode
                                                  ? {
                                                    value:
                                                    values?.header
                                                      ?.projectSequenceCode,
                                                    label: getProjectTitle(
                                                      values?.header
                                                        ?.projectSequenceCode,
                                                    ),
                                                  }
                                                  : null
                                              }
                                              promiseOptions={projectOptions}
                                              validationMessage={''}
                                              required={true}
                                            />
                                          )}
                                        </Field> : <Field
                                          name="header.projectSequenceCode"
                                          validate={required}
                                        >
                                          {({ input, meta }) => (
                                            <TextField
                                              placeholder={t('foreman')}
                                              value={getProjectTitle(
                                                values?.header
                                                  ?.projectSequenceCode,
                                              )}
                                              disabled={true}
                                            />
                                          )}
                                        </Field>}
                                    </div>
                                  </Stack.Item>
                                </Stack>
                                <Stack
                                  horizontal
                                  wrap
                                  styles={{
                                    root: { padding: 0, marginBottom: 0, marginTop: 10 },
                                  }}
                                  tokens={{
                                    childrenGap: 0,
                                    padding: 0,
                                  }}
                                >
                                  <Stack.Item
                                    grow={6}
                                    styles={{
                                      root: {
                                        display: 'flex',
                                        height: 30,
                                        width: 0,
                                        flexGrow: 1,
                                      },
                                    }}
                                  >
                                    {values.header?.projectSequenceCode ? (
                                      <Label
                                        style={{ fontSize: 14 }}
                                        disabled={true}
                                      >
                                        {props.projectHeader &&
                                        props.projectHeader.pmName}{' '}
                                        {props.projectHeader &&
                                        props.projectHeader.mobileNumber &&
                                        ' - ' +
                                        props.projectHeader.mobileNumber}
                                      </Label>
                                    ) : (
                                      false
                                    )}
                                  </Stack.Item>
                                </Stack>
                                {/* ) }
                                                    </Field> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="proj-detail-block" id="1">
                  <div className="document-pane-card">
                    <div className={'card-header'}>
                      <Link href="#" id="po-header">
                        <Label>1. {t('header')}</Label>
                      </Link>
                    </div>
                    {props.formData?.sequenceId && (
                      <Stack
                        horizontal
                        wrap
                        styles={stackStyles}
                        tokens={stackTokens}
                      >
                        <Stack.Item grow={6} styles={stackItemStyles}>
                          <div className={`${classNames.fullWidth}`}>
                            <Field
                              name="header.title"
                              component={TextFieldFF}
                              lable={t('title')}
                              disabled={true}
                            />
                          </div>
                        </Stack.Item>
                        <Stack.Item grow={6} styles={stackItemStyles}>
                          <div className={`${classNames.fullWidth}`}>
                            <Field
                              name="header.sequenceId"
                              component={TextFieldFF}
                              lable={t('ID')}
                              disabled={true}
                            />
                          </div>
                        </Stack.Item>
                      </Stack>
                    )}

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="header.name"
                            component={TextFieldFF}
                            lable={t('name')}
                            // validate={ titleRequired }
                            required={true}
                            disabled={props.isDisabled}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="header.poRequestTypeId"
                            component={DropDownAdaptater}
                            options={
                              props.purchaseOrderDropDowns.requestTypes
                            }
                            lable={t('type')}
                            placeholder={t('type')}
                            disabled={true}
                            required={false}
                          />
                        </div>
                        {/*{getPoRequestType() ? (*/}
                        {/**/}
                        {/*) : (*/}
                        {/*<div className={`${classNames.fullWidth}`}>*/}
                        {/*<Field*/}
                        {/*name="header.poTypeId"*/}
                        {/*component={DropDownAdaptater}*/}
                        {/*options={getPoTypeOptions(values)}*/}
                        {/*lable={t('purchaseOrderType')}*/}
                        {/*placeholder={t('purchaseOrderType')}*/}
                        {/*disabled={poTypeDisabled(values)}*/}
                        {/*required={true}*/}
                        {/*parse={(value) => {*/}
                        {/*// Do what you want with `value`*/}
                        {/*onPurchaseOrderTypeChange(value);*/}
                        {/*return value;*/}
                        {/*}}*/}
                        {/*/>*/}
                        {/*</div>*/}
                        {/*)}*/}
                      </Stack.Item>
                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="header.poStatusId"
                            component={DropDownAdaptater}
                            options={props.purchaseOrderDropDowns.states}
                            lable={t('status')}
                            placeholder={t('status')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="header.poTypeId"
                            component={DropDownAdaptater}
                            options={getPoTypeOptions(values)}
                            lable={t('selectionType')}
                            placeholder={t('selectionType')}
                            disabled={poTypeDisabled(values)}
                            required={true}
                            parse={(value) => {
                              // Do what you want with `value`
                              onPurchaseOrderTypeChange(value);
                              return value;
                            }}
                          />
                        </div>
                        {/*{getPoRequestType() ? (*/}
                        {/**/}
                        {/*) : (*/}
                        {/*false*/}
                        {/*)}*/}
                      </Stack.Item>
                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <div className={`${classNames.fullWidth}`}>
                        <Label>{t('comments')}</Label>

                        <Field
                          name="header.comments"
                          component={CKEditorWrapper}
                          disabled={props.isDisabled}
                        />
                      </div>
                    </Stack>

                    <Field
                      name="header.files"
                      component={'input'}
                      type={'hidden'}
                      disabled={false}
                      hidden
                    />

                    <CustomFileUploader
                      uploadedImages={values?.header?.files
                        ? getImages(values?.header?.files)
                        : []}
                      label={'Documents'}
                      uploadUrl={'PO/UploadPoDocuments'}
                      onSaveSuccess={(response: any) => {
                        if (values.header?.files &&
                          _.isArray(values.header.files)) {
                          values.header.files.push(response);
                        } else {
                          values.header.files = [];
                          values.header.files.push(response);
                        }

                        form.mutators.setHeaderDocs(values.header.files);
                      }}
                      disable={props.isDisabled} 
                      checkedItems={[]} 
                      productId={undefined}
                      isDragEnabled={true}
                    />

                    <div className={'card-footer'}>
                      <Separator/>
                      <Stack
                        styles={stackStyles}
                        tokens={itemAlignmentsStackTokens}
                      >
                        <Stack.Item
                          align="end"
                          styles={stackFooterBarItemStyles}
                        >
                          <ActionButton
                            className={classNames.actionButton}
                            iconProps={saveIcon}
                            allowDisabledFocus
                            onClick={() => {
                              // alert(JSON.stringify(values.purchaseOrderType, 0, 2));
                              props.setHasSubmitForm(true);
                              form.submit();
                            }}
                            disabled={props.isDisabled}
                          >
                            <Label className={classNames.actionButton}>
                              {t('save')}
                            </Label>
                          </ActionButton>
                        </Stack.Item>
                      </Stack>
                    </div>
                  </div>
                </div>

                <div className="proj-detail-block" id="2">
                  <div className="document-pane-card">
                    <div className={'card-header'}>
                      <Link href="#" id="po-delivery-details">
                        <Label>2. {t('deliveryDetails')}</Label>
                      </Link>
                      {props.formData.isCu !== false && values?.header?.poTypeId === RESOURCE_TYPE_ID && !getProject() ? (
                        <div>
                          <Stack
                            horizontal
                            wrap
                            styles={stackStyles}
                            tokens={stackTokens}
                          >
                            <Stack.Item grow={6} styles={stackItemStyles}>
                              <div className={`${classNames.fullWidth}`}>
                                <Field
                                  name="header.deliveryLocation"
                                  component={'input'}
                                  type={'hidden'}
                                  disabled={false}
                                  hidden
                                />
                                <div>
                                  <ChoiceGroup
                                    label={t('deliveryLocation')}
                                    styles={{
                                      flexContainer: {
                                        display: 'flex',
                                        selectors: {
                                          '.ms-ChoiceField': {
                                            marginLeft: 10,
                                            marginRight: 10,
                                          },
                                        },
                                      },
                                    }}
                                    selectedKey={
                                      values.header?.deliveryLocation
                                    }
                                    options={[
                                      { key: '1', text: t('contractingUnit') },
                                      { key: '2', text: t('project') },
                                      { key: '3', text: t('supplier') },
                                    ]}
                                    disabled={props.isDisabled}
                                    required={true}
                                    onChange={(
                                      ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                      option?: IChoiceGroupOption,
                                    ) => {
                                      form.mutators.setDeliveryLocation(
                                        option?.key,
                                      );
                                      if (option?.key === '1') {
                                        props.getWarehouseTaxonomy(false);
                                      } else if (option?.key === '2') {
                                        props.getWarehouseTaxonomy(true);
                                      } else {
                                        props.getWarehouseTaxonomy(false);
                                      }
                                    }}
                                  />
                                </div>
                                {/* <Field name="header.deliveryLocation" >*/}
                                {/* { ({ input, meta }:any) => (*/}
                                {/**/}
                                {/* ) }*/}
                                {/* </Field>*/}
                              </div>
                            </Stack.Item>
                          </Stack>
                          <div className={`${classNames.fullWidth}`}>
                            <div style={{ zIndex: 10000000 }}>
                              <Field
                                name="header.warehouseTaxonomyId"
                                component={'input'}
                                type={'hidden'}
                                disabled={false}
                                hidden
                              />
                              {values.header?.deliveryLocation !== '3' && (
                                <TaxonomyField
                                  label={t('warehouseTaxonomy')}
                                  treeData={
                                    props.purchaseOrderDropDowns
                                      .warehouseTaxonomy
                                  }
                                  selectItemId={
                                    formRef.current.getState().values?.header
                                      ?.warehouseTaxonomyId
                                      ? formRef.current.getState().values
                                        ?.header?.warehouseTaxonomyId
                                      : null
                                  }
                                  onSelectItem={(id: string) => {
                                    if (id) {
                                      if (
                                        id ===
                                        formRef.current.getState().values
                                          ?.header?.warehouseTaxonomyId
                                      ) {
                                        form.mutators.setWarehouseTaxonomyId(
                                          null,
                                        );
                                      } else {
                                        form.mutators.setWarehouseTaxonomyId({
                                          id: id,
                                        });
                                      }
                                    } else {
                                      form.mutators.setWarehouseTaxonomyId(
                                        null,
                                      );
                                    }
                                  }}
                                  treeLevel={
                                    props.purchaseOrderDropDowns.whTaxonomyLevel
                                  }
                                  onFilterTaxonomyNodeChanged={() => {
                                  }}
                                  disabled={props.isDisabled}
                                  isStock={true}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        false
                      )}

                      <div style={{ zIndex: 10000000, marginLeft: 10 }}>
                        <AddressLocationComponent
                          setLocationAddress={(address) => {
                            form.mutators.setDelivery(address);
                            props.setLocationAddress(address.position, address);
                          }}
                          position={getPosition(props.position)}
                          openMapModal={() => props.openMapModal()}
                          address={props.address}
                          disabled={props.isDisabled}
                          lable={t('deliveryCoordinates')}
                          addressLable={t('deliveryAddress')}
                          required={true}
                          errorMessage={
                            props.hasSubmit
                              ? form.getState()?.errors?.header?.delivery
                              : ''
                          }
                        />
                        <Field
                          name="header.delivery"
                          component={'input'}
                          type={'hidden'}
                          disabled={false}
                          hidden
                        />
                      </div>

                      <Stack
                        horizontal
                        wrap
                        styles={stackStyles}
                        tokens={stackTokens}
                      >
                        <Stack.Item grow={6} styles={stackItemStyles}>
                          <div className={`${classNames.fullWidth}`}>
                            <Field
                              name="header.deliveryDate"
                              component={DatePickerRequiredAdapter}
                              lable={t('requestedDeliveryDate')}
                              disabled={props.isDisabled}
                              minDate={new Date()}
                              errorMessage={
                                form.getState()?.errors?.header?.deliveryDate
                              }
                            />
                          </div>
                        </Stack.Item>
                        <Stack.Item grow={6} styles={stackItemStyles}>
                          <div className={`${classNames.fullWidth}`}>
                            <Field
                              name="header.expectedDate"
                              component={DatePickerAdapter}
                              lable={t('expectedDate')}
                              disabled={expectedDeliveryDateIsDisabled(props.isDisabled, values)}
                              minDate={new Date()}
                              errorMessage={
                                form.getState()?.errors?.header?.expectedDate
                              }
                            />
                          </div>
                        </Stack.Item>
                      </Stack>

                      {(values?.header?.poRequestTypeId === CAPACITY_REQUEST) && <Stack
                        horizontal
                        wrap
                        styles={stackStyles}
                        tokens={stackTokens}
                      >
                        <Stack.Item grow={6} styles={stackItemStyles}>
                          <div className={`${classNames.fullWidth}`}>
                            <Field
                              name="header.requestedProbability"
                              component={MinMaxNumberFormatField}
                              lable={t('requestedProbability')}
                              disabled={requestedProbabilityIsDisabled(props.isDisabled, values)}
                              suffix="%"
                              min={0}
                              max={100}
                            />
                          </div>
                        </Stack.Item>
                        <Stack.Item grow={6} styles={stackItemStyles}>
                          {availableProbabilityIsVisible(values) && <div className={`${classNames.fullWidth}`}>
                            <Field
                              name="header.availableProbability"
                              component={MinMaxNumberFormatField}
                              lable={t('availableProbability')}
                              disabled={availableProbabilityIsDisabled(props.isDisabled, values)}
                              suffix="%"
                              min={0}
                              max={100}
                            />
                          </div>}
                        </Stack.Item>
                      </Stack>}

                      <div className={'card-footer'}>
                        <Separator/>
                        <Stack
                          styles={stackStyles}
                          tokens={itemAlignmentsStackTokens}
                        >
                          <Stack.Item
                            align="end"
                            styles={stackFooterBarItemStyles}
                          >
                            <ActionButton
                              className={classNames.actionButton}
                              iconProps={saveIcon}
                              allowDisabledFocus
                              onClick={() => {
                                // alert(JSON.stringify(values.purchaseOrderType, 0, 2));
                                props.setHasSubmitForm(true);
                                form.submit();
                              }}
                              disabled={expectedDeliveryDateSaveIsDisabled(props.isDisabled, values)}
                            >
                              <Label className={classNames.actionButton}>
                                {t('save')}
                              </Label>
                            </ActionButton>
                          </Stack.Item>
                        </Stack>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="proj-detail-block" id="3">
                  <div className="document-pane-card">
                    <div className={'card-header'}>
                      <Link href="#" id="po-involved-parties">
                        <Label>3. {t('involvedParties')}</Label>
                      </Link>
                      <Label>3.1. {t('customer')}</Label>
                    </div>
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="involvedParties.customer.customerOrganisation"
                            component={'input'}
                            type={'text'}
                            disabled={props.isDisabled}
                            hidden
                          />
                          <Field
                            name="involvedParties.customer.customerOrganisationId"
                            component={'input'}
                            type={'text'}
                            disabled={props.isDisabled}
                            hidden
                          />
                          <Label disabled={props.isDisabled}>
                            {t('customerOrganisation')}
                          </Label>
                          {props.isDisabled ? (
                            <TextField
                              disabled={props.isDisabled}
                              value={
                                values &&
                                values.involvedParties &&
                                values.involvedParties.customer &&
                                values.involvedParties.customer
                                  .customerOrganisation
                                  ? values.involvedParties.customer
                                    .customerOrganisation
                                  : ''
                              }
                            />
                          ) : (
                            <CustomDropdown
                              reference={''}
                              // onChange={()=> props.handleVendorChange(props.vendor)}
                              onChange={(key: string, text: string) => {
                                if (key) {
                                  const customerOrganisation = {
                                    id: key,
                                    name: text,
                                  };
                                  form.mutators.setFormOrganisation(
                                    customerOrganisation,
                                  );
                                } else {
                                  form.mutators.setFormOrganisation(null);
                                }
                              }}
                              selectedOption={
                                values &&
                                values.involvedParties &&
                                values.involvedParties.customer &&
                                values.involvedParties.customer
                                  .customerOrganisationId
                                  ? {
                                    value:
                                    values.involvedParties.customer
                                      .customerOrganisationId,
                                    label:
                                    values.involvedParties.customer
                                      .customerOrganisation,
                                  }
                                  : null
                              }
                              promiseOptions={organisationOptions}
                              validationMessage={''}
                              required={true}
                            />
                          )}
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}
                             style={{ maxHeight: 100, position: 'relative', height: 100 }}>
                          <Field
                            name="involvedParties.customer.customerContact"
                            component={'input'}
                            type={'text'}
                            disabled={false}
                            hidden
                          />
                          <Field
                            name="involvedParties.customer.customerContactId"
                            component={'input'}
                            type={'text'}
                            disabled={false}
                            hidden
                          />
                          <Label disabled={props.isDisabled}>
                            {t('customerContact')}
                          </Label>
                          <NormalPeoplePicker
                            onChange={(items) => {
                              if (
                                items &&
                                _.isArray(items) &&
                                items.length === 0
                              ) {
                                form.mutators.setCustomerContact(null);
                              }
                            }}
                            selectedItems={selectedCustomerContactItems()}
                            onResolveSuggestions={
                              onCustomerContactFilterPersonChanged
                            }
                            onItemSelected={onCustomerContactSelected}
                            pickerSuggestionsProps={
                              limitedSearchAdditionalProps
                            }
                            className={'ms-PeoplePicker'}
                            key={'CustomerContact'}
                            itemLimit={1}
                            removeButtonAriaLabel={'Remove'}
                            resolveDelay={300}
                            disabled={props.isDisabled}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="involvedParties.customer.customerMobile"
                            component={TextFieldFF}
                            lable={t('customerMobileNumber')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="involvedParties.customer.customerEmail"
                            component={TextFieldFF}
                            lable={t('customerEmail')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="involvedParties.customer.customerReference"
                            component={TextFieldFF}
                            lable={t('customerReference')}
                            disabled={props.isDisabled}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="involvedParties.customer.customerOrderDate"
                            component={DatePickerAdapter}
                            lable={t('Customer Order Date')}
                            // disabled={props.isDisabled}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>

                    <Label>3.2. {t('supplier')}</Label>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="involvedParties.supplier.supplierOrganisation"
                            component={'input'}
                            type={'text'}
                            disabled={false}
                            hidden
                          />
                          <Field
                            name="involvedParties.supplier.supplierOrganisationId"
                            component={'input'}
                            type={'text'}
                            disabled={false}
                            hidden
                          />
                          <Label disabled={props.isDisabled}>
                            {t('supplierOrganisation')}
                          </Label>
                          {props.isDisabled ? (
                            <TextField
                              disabled={props.isDisabled}
                              value={
                                values &&
                                values.involvedParties &&
                                values.involvedParties.supplier &&
                                values.involvedParties.supplier
                                  .supplierOrganisationId
                                  ? values.involvedParties.supplier
                                    .supplierOrganisation
                                  : ''
                              }
                            />
                          ) : (
                            <CustomDropdown
                              reference={''}
                              // onChange={()=> props.handleVendorChange(props.vendor)}
                              onChange={(key: string, text: string) => {
                                if (key) {
                                  const supplierOrganisation = {
                                    id: key,
                                    name: text,
                                  };
                                  form.mutators.setSupplierOrganisation(
                                    supplierOrganisation,
                                  );
                                } else {
                                  form.mutators.setSupplierOrganisation(null);
                                }
                              }}
                              selectedOption={
                                values &&
                                values.involvedParties &&
                                values.involvedParties.supplier &&
                                values.involvedParties.supplier
                                  .supplierOrganisationId
                                  ? {
                                    value:
                                    values.involvedParties.supplier
                                      .supplierOrganisationId,
                                    label:
                                    values.involvedParties.supplier
                                      .supplierOrganisation,
                                  }
                                  : null
                              }
                              promiseOptions={organisationOptions}
                              validationMessage={''}
                              required={true}
                            />
                          )}
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}
                             style={{ maxHeight: 100, position: 'relative', height: 100 }}>
                          <Field
                            name="involvedParties.supplier.supplierContact"
                            component={'input'}
                            type={'text'}
                            disabled={false}
                            hidden
                          />
                          <Field
                            name="involvedParties.supplier.supplierContactId"
                            component={'input'}
                            type={'text'}
                            disabled={false}
                            hidden
                          />

                          <Label disabled={props.isDisabled}>
                            {t('supplierContact')}
                          </Label>

                          <NormalPeoplePicker
                            onChange={(items) => {
                              if (
                                items &&
                                _.isArray(items) &&
                                items.length === 0
                              ) {
                                form.mutators.setSupplierContact(null);
                              }
                            }}
                            selectedItems={selectedSupplierContactItems()}
                            onResolveSuggestions={
                              onSupplierContactFilterPersonChanged
                            }
                            onItemSelected={onSupplierContactSelected}
                            pickerSuggestionsProps={
                              limitedSearchAdditionalProps
                            }
                            className={'ms-PeoplePicker'}
                            key={'CustomerContact'}
                            itemLimit={1}
                            removeButtonAriaLabel={'Remove'}
                            resolveDelay={300}
                            disabled={props.isDisabled}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="involvedParties.supplier.supplierMobile"
                            component={TextFieldFF}
                            lable={t('supplierMobileNumber')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="involvedParties.supplier.supplierEmail"
                            component={TextFieldFF}
                            lable={t('supplierEmail')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="involvedParties.supplier.supplierReference"
                            component={TextFieldFF}
                            lable={t('supplierReference')}
                            disabled={props.isDisabled}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="involvedParties.supplier.supplierOrderDate"
                            component={DatePickerAdapter}
                            lable={t('Supplier Order Date')}
                            // disabled={props.isDisabled}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>

                    <div className={'card-footer'}>
                      <Separator/>
                      <Stack
                        styles={stackStyles}
                        tokens={itemAlignmentsStackTokens}
                      >
                        <Stack.Item
                          align="end"
                          styles={stackFooterBarItemStyles}
                        >
                          <ActionButton
                            className={classNames.actionButton}
                            iconProps={saveIcon}
                            allowDisabledFocus={false}
                            onClick={() => {
                              if (values?.header?.id && values?.header?.poRequestTypeId === "f4d6ba08-3937-44ca-a0a1-7cf33c03e290") {
                                rfqSubmit(values)
                              } else {
                                form.submit()
                              }
                              // alert(JSON.stringify(values.purchaseOrderType, 0, 2));
                            }}
                            disabled={false}
                          >
                            <Label className={classNames.actionButton}>
                              {t('save')}
                            </Label>
                          </ActionButton>
                        </Stack.Item>
                      </Stack>
                    </div>
                  </div>
                </div>

                <Resources
                  materialSelection={props.materialSelection}
                  toolSelection={props.toolSelection}
                  consumableSelection={props.consumableSelection}
                  labourSelection={props.labourSelection}
                  borResources={props.borResources}
                  setOpenPOResourceModal={() => props.setOpenPOResourceModal()}
                  isExpand={props.isExpand}
                  externalProducts={props.externalProducts}
                  // type={0}
                  type={values?.header?.poTypeId}
                  poStatusId={props?.formData?.status?.key}
                />
                {props.formData.history && props.formData.history.createdDate && (
                  <div className="proj-detail-block" id="5">
                    <div className="document-pane-card">
                      <div className="marginTop marginBottom">
                        <Link href="#" id="history">
                          <Label>5. {t('history')} </Label>
                        </Link>

                        <HistoryBar
                          createdByUser={
                            props.formData.history.createdBy
                              ? props.formData.history.createdBy
                              : ''
                          }
                          updatedByUser={
                            props.formData.history.modifiedBy
                              ? props.formData.history.modifiedBy
                              : ''
                          }
                          createdDateTime={
                            props.formData.history.createdDate
                              ? props.formData.history.createdDate
                              : ''
                          }
                          updatedDateTime={
                            props.formData.history.modifiedDate
                              ? props.formData.history.modifiedDate
                              : ''
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* <pre>{ JSON.stringify(values, 0, 2) }</pre> */}

                {/* <pre>{ JSON.stringify(values, 0, 2) }</pre> */}
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};
export default DocumentPaneComponent;

import React from 'react';
import {
  FontWeights,
  IButtonStyles,
  IconButton,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useId } from '@uifabric/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { createPBSScopeOfWork, getById, savePbsStateAttr } from '../../../reducers/projectBreakdownReducer';
import { Field, Form } from 'react-final-form';
import DefaultNumberFormat from '../../../shared/fluentFinalForm/defaultNumberFormat';
import ClearableDropdown from '../../../shared/clearableDropdown/clearableDropdown';
import { v4 as uuid4 } from 'uuid';
import { MOU_SQUARE_METER } from '../../../types/projectBreakdownStructure';
import { uPrinceTheme } from '../../../../theme';
import { getTheme, ITheme } from '@fluentui/react/lib/Styling';

const theme: ITheme = getTheme();

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: { color: theme.palette.neutralDark },
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
};
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    minWidth:'40vw',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  fullWidth: { width: '100%' },
  footer:  {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    }
});

const stackStyles: IStackStyles = { root: { padding: 10, marginBottom: 10 } };

const stackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const SquareMetersModal=()=>{
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const titleId = useId('title');

  const {
    isPbsSqModalOpen,
    formData
  } = useSelector(
    (state: any) => state.projectBreakdown
  );
  const {
    cpcBasicUnitOfMeasure
  } = useSelector(
    (state: any) => state.productCatalogue,
  );

  const onSubmit = async (values: any) => {
    if(!values?.id){
      values.id=uuid4()
    }
    Promise.all([
      dispatch(createPBSScopeOfWork({...values,productId:formData?.id,mouId:values?.mou?.key}))
    ]).then(() => {
      dispatch(getById(formData?.productId));
      dispatch(savePbsStateAttr('isPbsSqModalOpen',false))
    });

  };


  const getInitialFormValues = (formValue: any) => {
    if (formData && formData?.id && formData?.pbsScopeOfWork) {
      return { ...formData?.pbsScopeOfWork,mou:{key:MOU_SQUARE_METER,text:''} };
    }
    return {
      mou:{key:MOU_SQUARE_METER,text:''}
    };
  };

  return <div>
    <Modal
      titleAriaId={titleId}
      isOpen={isPbsSqModalOpen}
      onDismiss={()=>dispatch(savePbsStateAttr('isPbsSqModalOpen',!isPbsSqModalOpen))}
      isBlocking={false}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <span id={titleId}>{t('squareMeter')}</span>
        <IconButton
          styles={cancelIconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={()=>dispatch(savePbsStateAttr('isPbsSqModalOpen',!isPbsSqModalOpen))}
        />
      </div>
      <div className={contentStyles.body}>
        <Form
          onSubmit={onSubmit}
          initialValues={getInitialFormValues(formData)}
          validate={values => {
            const errors: any = {};
            if (!values?.mou) {
              errors.mou = t('basicUnitOfMeasureRequired');
            }
            return errors;
          }}
          render={({ handleSubmit,  form,values }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Field
                  name="id"
                  component={'input'}
                  type={'text'}
                  disabled={true}
                  hidden
                />
                <Stack
                  horizontal
                  wrap
                  styles={stackStyles}
                  tokens={stackTokens}
                >
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${contentStyles.fullWidth}`}>
                      <Field name="quantity">
                        {({ input, meta }) => (
                          <DefaultNumberFormat
                            label={t('quantity')}
                            value={values.quantity ? values.quantity : ''}
                            disabled={false}
                            onChange={(value) => {
                              if (value) {
                                input.onChange(value);
                              } else {
                                input.onChange(0);
                              }
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${contentStyles.fullWidth}`}>
                      <Field name="mou">
                        {({ input, meta }) => (
                          <ClearableDropdown
                            placeholder={t('cpcBasicunitofMeasure')}
                            label={t('cpcBasicunitofMeasure')}
                            options={cpcBasicUnitOfMeasure?cpcBasicUnitOfMeasure:[]}
                            errorMessage={meta.touched && meta.error ? meta.error : null}
                            selectedKey={
                              values?.mou
                                ? values.mou?.key
                                : ''
                            }
                            onChange={(event, value) => {
                              if (value) {
                                input.onChange(value);
                              } else {
                                input.onChange(null);
                              }
                            }}
                            disabled={true}
                            // required={ true }
                          />
                        )}
                      </Field>
                    </div>
                  </Stack.Item>
                </Stack>

                {/*<Stack*/}
                {/*  horizontal*/}
                {/*  wrap*/}
                {/*  styles={stackStyles}*/}
                {/*  tokens={stackTokens}*/}
                {/*>*/}
                {/*  <Stack.Item grow={6} styles={stackItemStyles}>*/}
                {/*    <div className={`${contentStyles.fullWidth}`}>*/}
                {/*      /!*<Field name="unitPrice">*!/*/}
                {/*      /!*  {({ input, meta }) => (*!/*/}
                {/*      /!*    <DefaultNumberFormat*!/*/}
                {/*      /!*      label={t('unitPrice')}*!/*/}
                {/*      /!*      value={values.unitPrice ? values.unitPrice : ''}*!/*/}
                {/*      /!*      disabled={false}*!/*/}
                {/*      /!*      onChange={(value) => {*!/*/}
                {/*      /!*        if (value) {*!/*/}
                {/*      /!*          input.onChange(value);*!/*/}
                {/*      /!*        } else {*!/*/}
                {/*      /!*          input.onChange(0);*!/*/}
                {/*      /!*        }*!/*/}
                {/*      /!*      }}*!/*/}
                {/*      /!*    />*!/*/}
                {/*      /!*  )}*!/*/}
                {/*      /!*</Field>*!/*/}
                {/*    </div>*/}
                {/*  </Stack.Item>*/}
                {/*  <Stack.Item grow={6} styles={stackItemStyles}>*/}
                {/*    <div className={`${contentStyles.fullWidth}`}>*/}
                {/*      /!*<Field name="totalPrice">*!/*/}
                {/*      /!*  {({ input, meta }) => (*!/*/}
                {/*      /!*    <DefaultNumberFormat*!/*/}
                {/*      /!*      label={t('totalPrice')}*!/*/}
                {/*      /!*      value={values.totalPrice ? values.totalPrice : ''}*!/*/}
                {/*      /!*      disabled={false}*!/*/}
                {/*      /!*      onChange={(value) => {*!/*/}
                {/*      /!*        if (value) {*!/*/}
                {/*      /!*          input.onChange(value);*!/*/}
                {/*      /!*        } else {*!/*/}
                {/*      /!*          input.onChange(0);*!/*/}
                {/*      /!*        }*!/*/}
                {/*      /!*      }}*!/*/}
                {/*      /!*    />*!/*/}
                {/*      /!*  )}*!/*/}
                {/*      /!*</Field>*!/*/}
                {/*    </div>*/}
                {/*  </Stack.Item>*/}
                {/*</Stack>*/}
                {/*<pre>{JSON.stringify(values)}</pre>*/}
                <div className={contentStyles.footer}>
                  <PrimaryButton
                    iconProps={saveIcon}
                    text={t('save')}
                    style={{ marginTop: 20, marginBottom: 10 }}
                    onClick={() => {
                      form.submit();
                    }}
                    disabled={false}
                  />
                </div>
              </form>
            );
          }}
        />
      </div>
    </Modal>
  </div>
}

export default SquareMetersModal
import { FC, useEffect, useRef, useState } from 'react';
import {
  DatePicker,
  FontWeights,
  getTheme,
  IconButton,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Modal,
  PrimaryButton,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { cancelIconButtonStyles } from '../wbsCreate/documentPane/WbsCreateDocument';
import ProgressBar from '../../shared/progressBar/progressBar';
import XLSX from 'xlsx';
import Axios from 'axios';
import ENV from '../../../env.json';
import { UPLOAD_PRODUCT_EXCEL } from '../../shared/endpoints';
import { getContractingUnit, getProject } from '../../shared/util';
import { Form } from 'react-final-form';
import { Dropdown, IDropdownOption, Stack } from 'office-ui-fabric-react';
import { analyzeCustomDocument } from '../../services/formRecognizerService';
import { useHistory } from 'react-router-dom';
import { Input } from '@fluentui/react-components';
import { useDispatch } from 'react-redux';
import { saveVPStateAttr } from '../../reducers/visualPlanReducer';
const theme = getTheme();
interface ProjectModalProps {
  isOpen: boolean;
  onCloseModel: any;
}
const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: {
    maxWidth: 400,
  },
  textContent: {
    padding: '15px 10px',
  },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: {
    width: '100%',
  },
});

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    display: 'flex',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '80vw',
    minHeight: '80vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
      preview: {
        width: '50%',
      },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};

const deleteIcon: IIconProps = { iconName: 'Delete' };

const modalStyles = {
  main: {
    height: '500px', // Customize the height
    width: '600px', // You can also adjust the width
  },
};

const dragAndDropStyles = mergeStyleSets({
  button: {
    height: '29px',
    marginLeft: '2px',
    padding: '0 4px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    background: 'black',
  },
});

type ExcelData = (string | number)[][];

const ProjectModal: FC<ProjectModalProps> = ({ isOpen, onCloseModel }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [excelFileName, setExceltFileName] = useState<string | null>('');
  const [pdfFileName, setPdfFileName] = useState<string | null>('');
  const { t } = useTranslation();
  const [isProgress, setIsProgress] = useState(false);
  const [numberOfRecords, setNumberOfRecords] = useState('');
  const [data, setData] = useState<ExcelData>([]);
  const [pdf, setPdf] = useState(null);
  const [toImport, setToImport] = useState<any>('0');
  const [fileUrl, setFileUrl] = useState<string | null>('');
  const options: IDropdownOption[] = [
    { key: '0', text: t('Syndi') },
    { key: '1', text: t('Vicky') },
  ];
  const [isPdf, setIsPdf] = useState(false);
  const productDetails = [
    {
      id: 'Ordre de travail',
      name: t('ordreDeTravail'),
      value: '',
    },
    {
      id: 'Powalco Id',
      name: t('powalcoId'),
      value: '',
    },
    {
      id: 'Type de flux chantier',
      name: t('typeDeFluxChantier'),
      value: '',
    },
    {
      id: 'autorisation',
      name: t('autorisation'),
      value: '',
    },
    {
      id: 'priorite',
      name: t('Priorite'),
      value: '',
    },
    {
      id: 'Date de transfert',
      name: t('dateDeTransfert'),
      value: '',
    },
    {
      id: 'Type Dossier',
      name: t('typeDossier'),
      value: '',
    },
    {
      id: 'Type Client',
      name: t('typeClient'),
      value: '',
    },
    {
      id: 'Type Batiment',
      name: t('typeBatiment'),
      value: '',
    },
    {
      id: 'Type Reseau',
      name: t('typeReseau'),
      value: '',
    },
    {
      id: 'necessaire',
      name: t('necessaire'),
      value: '',
    },
    {
      id: 'Nom du chantier',
      name: t('nomDuChantier'),
      value: '',
    },
    {
      id: 'Commune',
      name: t('commune'),
      value: '',
    },
    {
      id: 'Localite',
      name: t('localite'),
      value: '',
    },
    {
      id: 'code postal',
      name: t('codePostal'),
      value: '',
    },
    {
      id: 'Remarques sur chantier',
      name: t('remarquesSurChantier'),
      value: '',
    },
    {
      id: 'Demandeur',
      name: t('demandeur'),
      value: '',
    },
    {
      id: 'GSM',
      name: t('gsm'),
      value: '',
    },
    {
      id: 'mail',
      name: t('mail'),
      value: '',
    },
  ];
  const [products, setProducts] = useState<any>({});
  
  useEffect(() => {
    return () => {
      setToImport('0');
      setExceltFileName('');
      setPdfFileName('');
      setNumberOfRecords('');
      setFileUrl(null);
      setIsPdf(false);
    };
  }, []);

  const uploadToAPI = async (file: any) => {
    const formData = new FormData(); // Create a FormData object
    formData.append('file', file);
    const response = await Axios({
      method: 'POST',
      url: `${ENV.BASE_URL}/${UPLOAD_PRODUCT_EXCEL}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${localStorage?.getItem('accessToken')}`,
        Cu: getContractingUnit(),
        Project: getProject(),
        'Content-Type': 'multipart/form-data',
        'X-API-Key': 'ae43693a-1006-45c6-b48a-d0747240d2ed',
      },
    });
    setIsProgress(false);
    if (response.status === 200) {
      const { result } = response.data;
      setNumberOfRecords(result.numberOfRecords);
    }
  };

  const handleDropExcel = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault(); // Prevent default behavior (Prevent file from being opened)
    console.log('excel');
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      if (
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel'
      ) {
        setExceltFileName(file.name);
        handleExcelFileChange(file, true);
      } else {
        alert('Please upload a valid Excel file.');
      }
    }
  };

  const handleDropPdf = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      if (file.type === 'application/pdf') {
        setPdfFileName(file.name);
        handlePdfFileChange(file, true);
      } else {
        alert('Please upload a valid Excel file.');
      }
    }
  };

  const handlePdfFileChange = (file: any, isDropped?: boolean) => {
    setPdfFileName(file.name);
    setIsPdf(true);
    const uploadedFile = file;
    if (uploadedFile && uploadedFile.type === 'application/pdf') {
      setPdf(uploadedFile);
      const url = URL.createObjectURL(uploadedFile);
      setFileUrl(url);
    } else {
      alert('Please upload a valid PDF file.');
    }
    if (file) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async () => {
        const arrayBuffer = reader.result;
        try {
          const response: any = await analyzeCustomDocument(arrayBuffer);
          constructTheField(response.documents[0].fields);
        } catch (error) {
          console.error('Error during document analysis:', error);
        } finally {
        }
      };
    }
  };

  const constructTheField = (data: any) => {
    console.log(data);
    const keys = Object.keys(data);
    setProducts(data);
  };

  const handleExcelFileChange = (file: any, isDropped?: boolean) => {
    setIsPdf(false);
    setIsProgress(true);
    setExceltFileName(file.name);
    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        const binaryStr = event.target?.result; // Optional chaining to avoid null reference
        const workbook = XLSX.read(binaryStr, { type: 'binary' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData: any = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
        }) as ExcelData;
        setData(jsonData);
        await uploadToAPI(file);
      } catch (error) {
        console.error('Error reading Excel file:', error);
      }
    };
    if (file instanceof Blob) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsBinaryString(file.target.files[0]);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const modalClose = () => {
    setToImport('0');
    setExceltFileName('');
    setPdfFileName('');
    setNumberOfRecords('');
    setFileUrl(null);
    setIsPdf(false);
    onCloseModel();
    setData([]);
  };

  const onProductChange = (e: any, key: any) => {
    console.log(e.target.value);
    console.log(key);
    const tempProducts = { ...products };
    tempProducts[key].value = e.target.value;
    setProducts((prev: any) => ({ ...prev, tempProducts }));
  };

  const onCardClick = (type: string) => {
    type === 'pdf' ? setIsPdf(true) : setIsPdf(false);
  };

  const onImportChange = (
    event: React.FormEvent<HTMLDivElement>,
    option: any
  ) => {
    setToImport(option.key);
  };

  const deleteExcel = () => {
    setExceltFileName('');
    setData([]);
    setNumberOfRecords('');
  };

  const deletePdf = () => {
    setPdfFileName('');
    setPdf(null);
  };

  const dateToString = (date: any) => {
    if (!date) return '';
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // month is zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const stringToDate = (dateString: any) => {
    const [day, month, year] = dateString.split('/');
    return new Date(year, month - 1, day); // month is zero-based in Date object
  };

  const onSubmit = () => {
    if(toImport) {
      if(pdf && data.length) {
        onCloseModel();
        setTimeout(() => {
          dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
          dispatch(saveVPStateAttr('selectedVpProjectBarType', 'pbs'));
          dispatch(saveVPStateAttr('slectedPBSForBor', null));
        }, 1000);
        localStorage.setItem('product',JSON.stringify(products))
      }
      else {
        return alert('Please upload the documents')
      }
    } 
    else {
      
    }
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        render={({ form }) => {
          return (
            <form>
              <Modal
                titleAriaId={'Add Product'}
                isOpen={isOpen}
                scrollableContentClassName="body"
                isBlocking={true}
                dragOptions={undefined}
                // styles={modalStyles}
              >
                <div className={contentStyles.header}>
                  <span>{t('addProject')}</span>
                  <IconButton
                    styles={cancelIconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={() => {
                      onCloseModel();
                      modalClose();
                    }}
                  />
                </div>
                <ProgressBar show={isProgress} />
                <div className={contentStyles.body} style={{ height: '50vh' }}>
                  <div style={{ flexBasis: '70%' }} className="preview">
                    {isPdf ? (
                      <>
                        {pdf && (
                          <div>
                            <h2>Preview:</h2>
                            {fileUrl && (
                              <div style={{ marginTop: '20px' }}>
                                <iframe
                                  src={fileUrl}
                                  width={`100%`}
                                  height={'600px'}
                                  title="PDF Preview"
                                  style={{
                                    border: '1px solid #ccc',
                                    backgroundColor: 'white',
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    ) : data.length ? (
                      <div>
                        {data.length > 0 && (
                          <table
                            style={{
                              borderCollapse: 'collapse',
                              width: '100%',
                            }}
                          >
                            <thead>
                              <tr>
                                {data[0].map((header: any, index: any) => (
                                  <th
                                    key={index}
                                    style={{
                                      border: '1px solid #000',
                                      padding: '8px',
                                    }}
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {data.slice(1).map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                  {row.map((cell: any, cellIndex: any) => (
                                    <td
                                      style={{
                                        border: '1px solid #000',
                                        padding: '8px',
                                      }}
                                      key={cellIndex}
                                    >
                                      {cell}
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexBasis: '30%',
                      borderLeft: '1px solid #ddd',
                      padding: '0px 0px 0px 20px',
                      overflow: 'auto',
                    }}
                    className="upload"
                  >
                    <div style={{ width: '100%', height: '90%' }}>
                      <Stack>
                        <Stack.Item grow={6}>
                          <div className={`${classNames.fullWidth}`}>
                            <Dropdown
                              label={t('import')}
                              options={options}
                              onChange={onImportChange}
                              selectedKey={toImport}
                            />
                          </div>
                        </Stack.Item>
                      </Stack>
                      <Label>Attachment</Label>
                      <div className={`${classNames.fullWidth}`}>
                        <div>
                          {!excelFileName ? (
                            <>
                              <div
                                onDragOver={handleDragOver}
                                onDrop={handleDropExcel}
                                style={{
                                  border: '2px dashed #ccc',
                                  padding: '40px',
                                  textAlign: 'center',
                                  marginBottom: '20px',
                                }}
                              >
                                <p>Drag and drop your Excel file here</p>
                              </div>
                              <button
                                className="uploadButton"
                                onClick={() => {
                                  document.getElementById('fileInput')?.click();
                                }}
                              >
                                Upload File
                              </button>
                              <input
                                type="file"
                                id="fileInput"
                                accept=".xlsx, .xls"
                                onChange={(e) => {
                                  const file = e.target.files?.[0];
                                  if (file) {
                                    handleExcelFileChange(file);
                                  }
                                }}
                                style={{
                                  display: 'none',
                                }}
                              />
                            </>
                          ) : (
                            <div className="afterUploaded">
                              <div
                                className="card"
                                onClick={() => onCardClick('excel')}
                              >
                                <h3>Uploaded File</h3>
                                <div>
                                  <div className="uploadedExcel">
                                    <div
                                      style={{
                                        wordBreak: 'break-all',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {excelFileName}
                                    </div>
                                    <IconButton
                                      className="excelPlaceHolder"
                                      iconProps={deleteIcon}
                                      onClick={deleteExcel}
                                    />
                                  </div>
                                </div>
                              </div>
                              {numberOfRecords && (
                                <h3>Number Of Records : {numberOfRecords}</h3>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      {+toImport ? (
                        <div>
                          <Label>Attachment</Label>
                          <div className={`${classNames.fullWidth}`}>
                            <div>
                              {!pdfFileName ? (
                                <>
                                  <div
                                    onDragOver={handleDragOver}
                                    onDrop={handleDropPdf}
                                    style={{
                                      border: '2px dashed #ccc',
                                      padding: '40px',
                                      textAlign: 'center',
                                      marginBottom: '20px',
                                    }}
                                  >
                                    <p>Drag and drop your PDF file here</p>
                                  </div>
                                  <button
                                    className="uploadButton"
                                    onClick={() => {
                                      document
                                        .getElementById('filePdfInput')
                                        ?.click();
                                    }}
                                  >
                                    Upload File
                                  </button>
                                  <input
                                    type="file"
                                    id="filePdfInput"
                                    accept=".pdf"
                                    onChange={(e) => {
                                      const file = e.target.files?.[0];
                                      if (file) {
                                        handlePdfFileChange(file);
                                      }
                                    }}
                                    style={{
                                      display: 'none',
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <div className="afterUploaded">
                                    <div
                                      className="card"
                                      onClick={() => onCardClick('pdf')}
                                    >
                                      <h3>Uploaded File</h3>
                                      <div>
                                        <div className="uploadedExcel">
                                          <div
                                            style={{
                                              wordBreak: 'break-all',
                                              textAlign: 'center',
                                            }}
                                          >
                                            {pdfFileName}
                                          </div>
                                          <IconButton
                                            className="excelPlaceHolder"
                                            iconProps={deleteIcon}
                                            onClick={deletePdf}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="sideScroll">
                                    {Object.keys(products).map((key) => (
                                      <div style={{ padding: '10px 0px' }}>
                                        <div>{key}</div>
                                        {key === 'Date de transfert' ? (
                                          <DatePicker
                                            placeholder={t('selectadate')}
                                            ariaLabel={t(
                                              'projectCompletionDate'
                                            )}
                                            value={stringToDate(
                                              products[key].value
                                            )}
                                            formatDate={dateToString}
                                          />
                                        ) : (
                                          <Input
                                            key={key}
                                            className={`${classNames.fullWidth}`}
                                            style={{
                                              border: '1px solid #000',
                                              color: '#000',
                                              padding: '4px 8px',
                                              marginTop: '10px',
                                            }}
                                            value={products[key].value}
                                            onChange={(e) =>
                                              onProductChange(e, key)
                                            }
                                          />
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="save_btn">
                      <PrimaryButton
                        iconProps={addIconWhite}
                        text={t('Import')}
                        type="submit"
                        onClick={onSubmit}
                        style={{
                          marginTop: 20,
                          marginBottom: 30,
                          marginRight: 20,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div></div>
              </Modal>
            </form>
          );
        }}
      ></Form>
    </div>
  );
};

export default ProjectModal;

import React, { useEffect, useState } from 'react';
import SplitPane, { Pane } from 'react-split-pane';
import { Stack } from '@fluentui/react';
import './shortcutPane/wbs-shortcut-pane-styles.css';
import WbsShortcutPane from './shortcutPane/component';
import ProgressBar from '../../shared/progressBar/progressBar';
import { useDispatch, useSelector } from 'react-redux';
import WBSCmdBarLeft from './commandBar/wbsCmdBarLeft/component';
import TitlePane from '../../shared/titlePane/titlePane';
import './wbs-style-sheet.css';
import DocumentPaneWrapper from './documentPane/WbsDocumentPaneWrapper';
import { messageService } from '../../services/messageService';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ParamTypes } from '../../types/uPrince';
import { useParams } from 'react-router';
import GenerateTemplateModal from './listPane/component/GenerateTemplateModal';
import WbsListPaneComponent from './listPane/component';
import { getProject } from '../../shared/util';
import { getWbsCreateLevelData, readWbsCreateListData } from '../../reducers/wbsCreateReducer';
import { WbsCreateListPaneFilter } from '../../types/wbs';
import AddNewDocumentPaneWrapper from './documentPane/AddNewDocumentPaneWrapper';

const WbsMainLayout = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { featureId, templateId }: ParamTypes = useParams();
  const paths = window.location.pathname.split('/');
  const projectSequenceCode = getProject();

  const showWbsProgressBar = useSelector((state: any) => state.wbs.showWbsProgressBar);
  const selectedProject = useSelector((state: any) => state.project.selectedProject);

  const [reSizer, setReSizer] = useState<number>(screen.width);
  const [wbsTaxonomyDocumentPaneLevel, setWbsTaxonomyDocumentPaneLevel] = useState<string>('');
  const [isAddNew, setIsAddNew] = useState(null);

  useEffect(() => {
    dispatch(readWbsCreateListData(new WbsCreateListPaneFilter()));
    dispatch(getWbsCreateLevelData());

    const subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.hideDocumentPane) {
          hideDocumentPane(true);
          setIsAddNew(null);
        }
        if (data.data.showWbsDocumentPane) {
          setIsAddNew(null);
          hideDocumentPane(false);
        }
        if (data.data.showCreateWbsDocumentPane) {
          hideDocumentPane(false);
        }
        if (data.data.wbsTaxonomyLevel) {
          setWbsTaxonomyDocumentPaneLevel(data.data.wbsTaxonomyLevel);
        }
        if (data.data.isAddNew) {
          setIsAddNew(data.data.isAddNew);
        }
      }
    });

    return () => {
      subscription.unsubscribe();
      setIsAddNew(null);
    };
  }, []);

  useEffect(() => {
    // handleUrlChange();
  }, [location, featureId, templateId]);

  const hideDocumentPane = (isHide: boolean) => {
    if (isHide) {
      // setShowDetails(false);
      setReSizer(screen.width);
    } else {
      // setShowDetails(true);
      setReSizer(screen.width / 2);
    }
  };

  const handleUrlChange = () => {
    // if (paths[5] && paths[5] === 'template') {                                    //is inside the template
    //   if (templateId) {
    //     hideDocumentPane(false);
    //     dispatch(getWbsProductById(templateId));
    //   } else {
    //     hideDocumentPane(true);
    //     dispatch(readWbsTaxonomyByTemId(featureId));
    //   }
    //   dispatch(saveWbsCreateStateAttr('isTemplate', true));
    // } else {                                                                      //is on the template
    //   if (featureId) {
    //     hideDocumentPane(false);
    //     dispatch(readWbsTaxonomyByTemId(featureId));
    //   } else {
    //     hideDocumentPane(true);
    //   }
    //   dispatch(saveWbsCreateStateAttr('isTemplate', false));
    // }

    // if (featureId) {
    //   hideDocumentPane(false);
    //   dispatch(readWbsTaxonomyByTemId(featureId));
    //   dispatch(saveWbsCreateStateAttr('isEditWbsTemp', !!featureId));
    //   dispatch(saveWbsCreateStateAttr('isTemplate', false));
    // } else if (paths[5] === "template") {
    //   hideDocumentPane(true);
    //   dispatch(saveWbsCreateStateAttr('isTemplate', true));
    // } else {
    //   hideDocumentPane(true);
    //   dispatch(saveWbsCreateStateAttr('isTemplate', false));
    // }
  };

  const renderShortCutPane = () => {
    return <WbsShortcutPane />;
  };

  const getListPaneView = () => {
    return (
      <div>
        <TitlePane
          headerTitle={paths[paths.length - 1]}
          type={1}
          isFullScreen={false}
        />
        <div style={{
          height: '100vh',
          overflow: 'overlay',
        }}>
          <Stack horizontal>
            {renderShortCutPane()}

            <div className={'grid-righter'}>
              <Stack
                className='project-list-header new-design'
                style={{
                  backgroundColor: 'rgb(255, 255, 255)',
                  paddingRight: 0,
                }}
                styles={{ root: { width: '100%', paddingRight: 0 } }}
              >

                <WBSCmdBarLeft />
                <ProgressBar show={showWbsProgressBar} />
              </Stack>

              <Stack style={{ alignSelf: 'center' }}>
                <WbsListPaneComponent />
              </Stack>
            </div>
          </Stack>
        </div>
      </div>
    );
  };

  const getDocumentPaneView = () => {
    return <>
      {isAddNew ? <AddNewDocumentPaneWrapper isAddNew={isAddNew} /> :
        <DocumentPaneWrapper wbsTaxonomyLevel={wbsTaxonomyDocumentPaneLevel} />}
    </>;
  };

  return (
    <div>
      <SplitPane
        className={'SplitPane'}
        minSize={(screen.width / 3)}
        maxSize={(screen.width / 3) * 2}
        defaultSize={screen.width}
        split='vertical'
        onChange={(size: any) => {
          setReSizer(size);
        }}
        size={reSizer}
        style={{ height: '85.5%' }}
      >
        <Pane className={'Pane'}
              style={{
                width: reSizer,
                height: '100%',
              }}>{getListPaneView()} </Pane>
        <Pane className={'Pane'}
              style={{
                marginLeft: 6,
                width: screen.width - reSizer,
                height: '100%',
              }}> {getDocumentPaneView()}</Pane>
      </SplitPane>

      <GenerateTemplateModal />
    </div>
  );
};

export default WbsMainLayout;

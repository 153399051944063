import React from 'react';
import { initializeIcons } from '@uifabric/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ShortCutPaneComponent } from './component';
import history from '../../../history';
import { messageService } from '../../../services/messageService';
import { getContractingUnit, getProject } from '../../../shared/util';
import { Sorter, StockShortCutPaneItem, StockShortCutPaneMain, StockShotcutPaneFilter } from '../../../types/stock';
import _ from 'lodash';
import { withRouter } from 'react-router';

initializeIcons();

interface Props extends WithTranslation {
  shortCutPaneFilters: StockShortCutPaneMain;
  handelShortCutPaneFilter: (selectedItem: StockShotcutPaneFilter) => void;
  filter: StockShotcutPaneFilter;
  projectId: string | null;
  toggleOverlay: boolean;
  resetLayout: boolean;
  location: any;
}

interface State {
  isCollapse: boolean;
  divClass: string;
  selectedCodeType: number;
  currentActiveSection: string | null;
}

class ShortCutPane extends React.Component<any, any> {
  subscription: any;

  constructor(props: any) {
    super(props);
    this.state = {
      isCollapse: false,
      divClass: 'filter-panel-left',
      selectedCodeType: -1,
      currentActiveSection: 2,
      type: 'stock',
    };
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.selectedShortCutPaneItem) {
          this.setState({
            currentActiveSection: data.data.selectedShortCutPaneItem,
          });
        }
      }
    });
    if (this.state.divClass === 'filter-panel-left') {
      if (window.innerWidth > 1300) {
        messageService.sendMessage({ shortCutPaneExpanded: true });
      }
    } else {
      messageService.sendMessage({ shortCutPaneExpanded: false });
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.toggleOverlay !== prevProps.toggleOverlay) {
      if (this.props.toggleOverlay) {
        this.setState({ divClass: 'filter-panel-left panel-move' });
      } else {
        this.setState({ divClass: 'filter-panel-left' });
      }
    }

    if (this.props?.location?.state?.stockSCFilter?.id && (this.props?.location?.state?.stockSCFilter?.id !== this.state.currentActiveSection)) {

      this.setState({
        currentActiveSection: this.props?.location?.state?.stockSCFilter?.id,
        type: this.props?.location?.state?.key === 1 ? 'workflow' : (this.props?.location?.state?.key === 2 ? 'stock' : 'wharehouse'),
      });
    }

    if (this.props?.location?.state?.key === 1 && this.props?.location?.state?.stockSCFilter?.id === null && this.state.currentActiveSection) {
      this.setState({
        currentActiveSection: null,
        type: 'workflow',
      });
    }

    if (this.props?.location?.state?.key === 2 && !this.props?.location?.state?.stockSCFilter?.id && this.state.currentActiveSection) {
      this.setState({
        currentActiveSection: null,
        type: 'stock',
      });
    }

    if (this.props?.location?.state?.key === 3 && !this.props?.location?.state?.stockSCFilter?.id && this.state.currentActiveSection) {
      this.setState({
        currentActiveSection: null,
        type: 'warehouse',
      });
    }
  }


  private handelShortCutPaneFilter = (selectedItem: StockShortCutPaneItem) => {
    this.setState({
      currentActiveSection: selectedItem.id,
      type: selectedItem.type,
    });
    let filter: StockShotcutPaneFilter = new StockShotcutPaneFilter();
    filter.id = selectedItem.id;
    filter.name = selectedItem.name;

    if (selectedItem.type === 'workflow') {
      if (getProject()) {
        history.push({
          pathname: `/CU/${getContractingUnit()}/project/${getProject()}/stock-workflow`,
          state: { stockSCFilter: filter, key: 1 },
        });
      } else {
        history.push({
          pathname: `/CU/${getContractingUnit()}/stock-workflow`,
          state: { stockSCFilter: filter, key: 1 },
        });
      }

    }

    if (selectedItem.type === 'stock') {
      if (selectedItem.id === 2 || _.isEmpty(selectedItem.typeId)) {
        filter.resourceType = null;
      } else {
        filter.resourceType = selectedItem.typeId;
      }

      if (getProject()) {
        history.push({
          pathname: `/CU/${getContractingUnit()}/project/${getProject()}/stock`,
          state: { stockSCFilter: filter, key: 2 },
        });
      } else {
        history.push({
          pathname: `/CU/${getContractingUnit()}/stock`,
          state: { stockSCFilter: filter, key: 2 },
        });
      }

    }

    if (selectedItem.type === 'warehouse') {
      if (getProject()) {
        history.push({
          pathname: `/CU/${getContractingUnit()}/project/${getProject()}/stock-warehouse`,
          state: { stockSCFilter: filter, key: 3 },
        });
      } else {
        history.push({
          pathname: `/CU/${getContractingUnit()}/stock-warehouse`,
          state: { stockSCFilter: filter, key: 3 },
        });
      }
    }

    // history.push({
    //   pathname: `/CU/${getContractingUnit()}/project/${getProject()}/stock-warehouse`,
    //   state: { pmolFilter: filter },
    // });
    // messageService.sendMessage({
    //   pbsFilter: filter,
    // });
    this.props.handelShortCutPaneFilter(filter);
  };

  private resetFilter = () => {
    let filterItem: StockShotcutPaneFilter = {
      id: null,
      title: null,
      name: null,
      type: null,
      status: null,
      wharehouseTaxonomy: null,
      wareHouse: null,
      resourceType: null,
      sorter: new Sorter(),
      typeId: null,
    };
    this.setState({
      currentActiveSection: null,
    });

    this.props.handelShortCutPaneFilter(filterItem);
  };


  private fromatShortCutPaneData = (shrotCutaPaneData: any) => {
    const { t } = this.props;
    let data: any[] = [];
    if (shrotCutaPaneData && _.isArray(shrotCutaPaneData?.workFlow) && !_.isEmpty(shrotCutaPaneData?.workFlow)) {
      data.push({ id: 1, name: t('workFlow'), displayOrder: 0, type: 'workflow', isMain: true, icon: 'ms-Icon--Flow' });
      let workflowChilds = shrotCutaPaneData?.workFlow?.map((item: any) => {
        return {
          ...item,
          type: 'workflow',
          isMain: false,
          icon: 'ms-Icon--GotoToday',
        };
      });
      data.push(...workflowChilds);
      data.push({ id: 2, name: t('stock'), displayOrder: 0, type: 'stock', isMain: true, icon: 'ms-Icon--Archive' });
      let stockChilds = shrotCutaPaneData?.stock?.map((item: any) => {
        return {
          ...item,
          type: 'stock',
          isMain: false,
          icon: 'ms-Icon--GotoToday',
        };
      });
      data.push(...stockChilds);
      data.push({
        id: 3,
        name: t('warehouse'),
        displayOrder: 0,
        type: 'warehouse',
        isMain: true,
        icon: 'ms-Icon--Home',
      });
      let wareHouseChilds = shrotCutaPaneData?.warehouse?.map((item: any) => {
        return {
          ...item,
          type: 'warehouse',
          isMain: false,
          icon: 'ms-Icon--GotoToday',
        };
      });
      data.push(...wareHouseChilds);
    }
    return data;
  };

  public render(): JSX.Element {
    return (
      <ShortCutPaneComponent
        currentActiveSection={this.state.currentActiveSection}
        toggleOverlay={this._toggleOverlay}
        shortCutPaneFilters={this.fromatShortCutPaneData(this.props.shortCutPaneFilters)}
        toggleOverlayFwd={this._toggleOverlayFwd}
        handelShortCutPaneFilter={(selectedItem: StockShortCutPaneItem) =>
          this.handelShortCutPaneFilter(selectedItem)
        }
        resetFilter={() => this.resetFilter()}
        divClass={this.state.divClass}
        isCollapse={this.state.isCollapse}
        isExpand={this.props.isExpand}
        type={this.state.type}
      />
    );
  }

  private _toggleOverlay = (): void => {
    this.setState({ divClass: 'filter-panel-left panel-move', isCollapse: true });
    messageService.sendMessage({ shortCutPaneExpanded: false });
  };
  private _toggleOverlayFwd = (): void => {
    this.setState({ divClass: 'filter-panel-left', isCollapse: false });
    messageService.sendMessage({ shortCutPaneExpanded: true });
  };
}

export default withTranslation()(withRouter(ShortCutPane));

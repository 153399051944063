import React, { Component } from 'react';
import UprinceNavigation from '../../shared/uprinceNavigation/cuProjectNavigation/component';
import InstructionRegisterMainLayout from './instructionRegisterMainLayout';
import { getContractingUnit, getProject } from '../../shared/util';

interface Props {
  currentUser: any;
}

interface State {
  isContractUnit: boolean;
  isProject: boolean;
}
export class InstructionRegister extends Component<Props, State> {
  render() {
    return (
      <UprinceNavigation
        currentUser={this.props.currentUser}
        selectedNavigationIndex={`/CU/${getContractingUnit()}/project/${getProject()}/instruction-register`}
      >
        <InstructionRegisterMainLayout />
      </UprinceNavigation>
    );
  }
}

export default InstructionRegister;

/* eslint-disable no-use-before-define */
import _ from 'lodash';
import {
  DELETE_QR_CODE,
  DELETE_QR_CODE_FAIL,
  DELETE_QR_CODE_SUCCESS,
  LOAD_CPC_VEHICLES,
  LOAD_CPC_VEHICLES_SUCCESS,
  LOAD_QR_CODE,
  LOAD_QR_CODE_FAIL,
  LOAD_QR_CODE_SUCCESS,
  LOAD_QR_CODES,
  LOAD_QR_CODES_FAIL,
  LOAD_QR_CODES_SUCCESS,
  LOAD_QR_DROPDOWNS,
  LOAD_QR_DROPDOWNS_FAIL,
  LOAD_QR_DROPDOWNS_SUCCESS,
} from '../actionTypes/qrCodeActionTypes';
import { DELETE_QRCODE_EP, QR_CODE_READ_EP } from '../shared/endpoints';
import { QRCodeLite } from '../types/qrCode';

const defaultState: any = {
  listPaneItems: [],
  isDataLoaded: false,

  qrCode: null,
  isDataLoadedQRCode: false,
  status: true,
  message: null,
  dropDownsData: [],
};

export default function templateReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {
      case LOAD_QR_CODES:
        return { ...state, qrCodes: [], isDataLoaded: false };
      case LOAD_QR_CODES_SUCCESS:
        return {
          ...state,
          isDataLoaded: true,
          status: action.payload.data.status,
          message: action.payload.data.message,
          listPaneItems: formatQRCodeData(action.payload.data.result),
        };
      case LOAD_QR_CODES_FAIL:
        return {
          ...state,
          isDataLoaded: true,
          error: action.error.response.data.message,
        };

      case LOAD_QR_CODE:
        return { ...state, isDataLoadedQRCode: true, qrCode: null };
      case LOAD_QR_CODE_SUCCESS:
        return {
          ...state,
          isDataLoadedQRCode: false,
          qrCode: action.payload.data.result,
        };
      case LOAD_QR_CODE_FAIL:
        return {
          ...state,
          isDataLoadedQRCode: false,
          qrCode: null,
        };
      case LOAD_QR_DROPDOWNS:
        '';
        return { ...state, dropDownsData: [] };
      case LOAD_QR_DROPDOWNS_SUCCESS:
        return {
          ...state,
          dropDownsData: action.payload.data.result,
        };
      case LOAD_QR_DROPDOWNS_FAIL:
        return {
          ...state,
          dropDownsData: [],
        };
      case LOAD_CPC_VEHICLES:
        return { ...state, dropDownsData: [] };
      case LOAD_CPC_VEHICLES_SUCCESS:
        return {
          ...state,
          dropDownsData: action.payload.data.result,
        };
      default:
        return state;
    }
  }
  return null;
}

export const getQRCodeByID = (id: string) => {
  return {
    types: [LOAD_QR_CODE, LOAD_QR_CODE_SUCCESS, LOAD_QR_CODE_FAIL],
    payload: {
      request: {
        // url: `QrCode/Read/${id}`,
        url: QR_CODE_READ_EP + id,
      },
    },
  };
};

export const getQRDropDownsData = () => {
  return {
    types: [
      LOAD_QR_DROPDOWNS,
      LOAD_QR_DROPDOWNS_SUCCESS,
      LOAD_QR_DROPDOWNS_FAIL,
    ],
    payload: { request: { url: 'ProjectDefinition/ReadProjectsForQr/-1' } },
  };
};

export const deleteQRCode = (id: string) => {
  return {
    types: [
      DELETE_QR_CODE,
      DELETE_QR_CODE_SUCCESS,
      DELETE_QR_CODE_FAIL,
    ],
    payload: {
      request: {
        method: 'DELETE',
        url: DELETE_QRCODE_EP + id,
      },
    },
  };
};

export const getFilterQRCodes = (filter: any) => {
  return {
    types: [LOAD_QR_CODES, LOAD_QR_CODES_SUCCESS, LOAD_QR_CODES_FAIL],
    payload: {
      request: {
        url: 'QRCode/QrCodeFilter',
        method: 'POST',
        data: filter,
      },
    },
  };
};

const formatQRCodeData = (qrCodes: any[]) => {
  let data: QRCodeLite[] = [];
  if (_.isArray(qrCodes)) {
    data = qrCodes.map((item: any) => {
      return {
        id: item.id,
        projectId: item.projectTitle ? item.projectTitle : '-',
        vehicleNo: item.vehicleNo ? item.vehicleNo : '-',
        travellerType: item.travellerType ? item.travellerType : '-',
        location: item.location ? item.location : '-',
        type: item.type,
        date: item.date,
        history: item.history,
      };
    });
  }
  return data;
};

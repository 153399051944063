import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  createTableColumn,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  Input,
  InputProps,
  Label,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  TableColumnDefinition,
} from '@fluentui/react-components';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../history';
import { addAllItem, getContractingUnit, getProject } from '../../../shared/util';
import { ParamTypes } from '../../../types/uPrince';
import { useParams } from 'react-router';
import i18n from 'i18next';
import { readWbsIssueList, saveWbsIssueStateAttr } from '../../../reducers/wbsIssueReducer';
import { WbsIssueListPaneFilter } from '../../../types/wbsIssue';
import { Search24Regular } from '@fluentui/react-icons';
import Dropdown from '../../../shared/fluentUI9/Dropdown/Dropdown';
import PeoplePicker from '../../../shared/fluentFinalForm/PeoplePicker';

// type FileCell = {
//   label: string;
//   icon: JSX.Element;
// };

type Item = {
  id: string,
  title: string,
  productTitle: string,
  statusName: string,
  responsibleName: string,
  priorityName: string,
  severityName: string,
};

export const WbsIssueListComponent = ({ isCollapsed }: any) => {
  const dispatch = useDispatch();
  const { featureId }: ParamTypes = useParams();
  const refMap = React.useRef<Record<string, HTMLElement | null>>({});

  const wbsIssueList = useSelector((state: any) => state.wbsIssue.wbsIssueList);
  const reloadListPane = useSelector((state: any) => state.wbsIssue.reloadListPane);
  const wbsIssueFilter = useSelector((state: any) => state.wbsIssue.wbsIssueFilter);
  const wbsIssueDropdownData = useSelector((state: any) => state.wbsIssue.wbsIssueDropdownData);

  const [responsibleName, setResponsibleName] = useState(null);

  useEffect(() => {
    dispatch(readWbsIssueList(new WbsIssueListPaneFilter()));

    return () => {
      console.log('unmounting wbs issue list pane');
      dispatch(saveWbsIssueStateAttr('reloadListPane', false));
      dispatch(saveWbsIssueStateAttr('wbsIssueFilter', new WbsIssueListPaneFilter()));
    };
  }, []);

  useEffect(() => {
    reloadListPane && refreshListPane();
  }, [reloadListPane]);

  useEffect(() => {
    dispatch(readWbsIssueList(wbsIssueFilter));
  }, [wbsIssueFilter]);

  const handleSelectionChange = (e: any, data: any) => {
    const featureId = Array.from(data.selectedItems)[0];
    history.push(`/CU/${getContractingUnit()}/project/${getProject()}/wbs/issue/${featureId}`);
  };

  const handleSortChange = (e: any, sortState: any) => {
    dispatch(saveWbsIssueStateAttr('wbsIssueFilter', {
      ...wbsIssueFilter,
      sorter: {
        attribute: sortState?.sortColumn ?? null,
        order: sortState?.sortDirection === 'ascending' ? 'asc' : 'desc',
      },
    }));
  };

  // const handleFilterChange = (filter: WbsCreateListPaneFilter) => {
  //   // console.log(filter)
  //   if (filter.title !== '') {
  //     setFilter((prevState: any) => ({
  //       ...prevState,
  //       title: filter.title ?? null,
  //     }));
  //     dispatch(readWbsCreateListData(filter));
  //   }
  // };

  const refreshListPane = () => {
    dispatch(readWbsIssueList(new WbsIssueListPaneFilter()));
  };

  const onChangeTitle: InputProps['onChange'] = (ev, data) => {
    // console.log({ data });
    dispatch(saveWbsIssueStateAttr('wbsIssueFilter', {
      ...wbsIssueFilter,
      title: data?.value != '' ? data?.value : null,
    }));
  };

  const onChangeProduct: InputProps['onChange'] = (ev, data) => {
    // console.log({ data });
    dispatch(saveWbsIssueStateAttr('wbsIssueFilter', {
      ...wbsIssueFilter,
      productTitle: data?.value != '' ? data?.value : null,
    }));
  };

  const onFilter = (event: any) => {
    if (event?.keyCode === 13) {
      dispatch(readWbsIssueList(wbsIssueFilter));
    }
  };

  const columns: TableColumnDefinition<Item>[] = [
    createTableColumn<Item>({
      columnId: 'issueName',
      compare: (a, b) => {
        return a?.title?.localeCompare(b?.title);
      },
      renderHeaderCell: () => {
        return (
          <div className={'list-pane-column-header'}>
            <Label weight='semibold'>{i18n.t('issueName')}</Label>
            <Input
              value={wbsIssueFilter?.title}
              onChange={onChangeTitle}
              onKeyDown={onFilter}
              placeholder={i18n.t('issueName')}
              contentBefore={<Search24Regular onClick={(e: any) => e.stopPropagation()} />}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        );
      },
      renderCell: (item) => {
        return (
          <span>{item?.title ?? '-'}</span>
        );
      },
    }),
    createTableColumn<Item>({
      columnId: 'productTitle',
      compare: (a, b) => {
        return a?.productTitle?.localeCompare(b?.productTitle);
      },
      renderHeaderCell: () => {
        return (
          <div className={'list-pane-column-header'}>
            <Label weight='semibold'>{i18n.t('productTitle')}</Label>
            <Input
              value={wbsIssueFilter?.productTitle}
              onChange={onChangeProduct}
              onKeyDown={onFilter}
              placeholder={i18n.t('productTitle')}
              contentBefore={<Search24Regular onClick={(e: any) => e.stopPropagation()} />}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        );
      },
      renderCell: (item) => {
        return (
          <span>{item?.productTitle ?? '-'}</span>
        );
      },
    }),
    createTableColumn<Item>({
      columnId: 'status',
      compare: (a, b) => {
        return a?.statusName?.localeCompare(b?.statusName);
      },
      renderHeaderCell: () => {
        return (
          <div className={'list-pane-column-header'}>
            <Label weight='semibold'>{i18n.t('status')}</Label>
            <div onClick={(e: any) => e.stopPropagation()}>
              <Dropdown
                placeholder={i18n.t('status')}
                options={wbsIssueDropdownData?.status ? addAllItem(wbsIssueDropdownData?.status) : []}
                value={wbsIssueFilter?.status}
                onChange={(value: any) => {
                  dispatch(saveWbsIssueStateAttr('wbsIssueFilter', {
                    ...wbsIssueFilter,
                    status: value?.key,
                  }));
                }}
              />
            </div>
          </div>
        );
      },
      renderCell: (item) => {
        return (
          <span>{item?.statusName ?? '-'}</span>
        );
      },
    }),
    createTableColumn<Item>({
      columnId: 'priority',
      compare: (a, b) => {
        return a?.priorityName?.localeCompare(b?.priorityName);
      },
      renderHeaderCell: () => {
        return (
          <div className={'list-pane-column-header'}>
            <Label weight='semibold'>{i18n.t('priority')}</Label>
            <div onClick={(e: any) => e.stopPropagation()}>
              <Dropdown
                placeholder={i18n.t('priority')}
                options={wbsIssueDropdownData?.priority ? addAllItem(wbsIssueDropdownData?.priority) : []}
                value={wbsIssueFilter?.priority}
                onChange={(value: any) => {
                  dispatch(saveWbsIssueStateAttr('wbsIssueFilter', {
                    ...wbsIssueFilter,
                    priority: value?.key,
                  }));
                }}
                onClick={(e: any) => e.stopPropagation()}
              />
            </div>
          </div>
        );
      },
      renderCell: (item) => {
        return (
          <span>{item?.priorityName ?? '-'}</span>
        );
      },
    }),
    createTableColumn<Item>({
      columnId: 'severity',
      compare: (a, b) => {
        return a?.severityName?.localeCompare(b?.severityName);
      },
      renderHeaderCell: () => {
        return (
          <div className={'list-pane-column-header'}>
            <Label weight='semibold'>{i18n.t('severity')}</Label>
            <div onClick={(e: any) => e.stopPropagation()}>
              <Dropdown
                placeholder={i18n.t('severity')}
                options={wbsIssueDropdownData?.severity ? addAllItem(wbsIssueDropdownData?.severity) : []}
                value={wbsIssueFilter?.severity}
                onChange={(value: any) => {
                  dispatch(saveWbsIssueStateAttr('wbsIssueFilter', {
                    ...wbsIssueFilter,
                    severity: value?.key,
                  }));
                }}
                onClick={(e: any) => e.stopPropagation()}
              />
            </div>
          </div>
        );
      },
      renderCell: (item) => {
        return (
          <span>{item?.severityName ?? '-'}</span>
        );
      },
    }),
    createTableColumn<Item>({
      columnId: 'responsible',
      compare: (a, b) => {
        return a?.responsibleName?.localeCompare(b?.responsibleName);
      },
      renderHeaderCell: () => {
        return (
          <div className={'list-pane-column-header'}>
            <Label weight='semibold'>{i18n.t('responsible')}</Label>
            <div onClick={(e: any) => e.stopPropagation()}>
              <PeoplePicker
                label={''}
                placeHolder={i18n.t('responsible')}
                isDisabled={false}
                selectedItem={wbsIssueFilter?.responsible ? [{
                  key: wbsIssueFilter?.responsible,
                  text: responsibleName,
                }] : null}
                onChange={(item: any) => {
                  if (item?.cabPersonId) {
                    return item;
                  } else {
                    dispatch(saveWbsIssueStateAttr('wbsIssueFilter', {
                      ...wbsIssueFilter,
                      responsible: null,
                    }));
                    setResponsibleName(null);

                    return null;
                  }
                }}
                onPersonSelected={(item: any) => {
                  if (item) {
                    // console.log('people picker >', item);
                    dispatch(saveWbsIssueStateAttr('wbsIssueFilter', {
                      ...wbsIssueFilter,
                      responsible: item?.cabPersonId,
                    }));

                    setResponsibleName(item?.text);
                  }

                  return item;
                }}
              />
            </div>
          </div>
        );
      },
      renderCell: (item) => {
        return (
          <span>{item?.responsibleName ?? '-'}</span>
        );
      },
    }),
  ];

  const resizeColumns: TableColumnDefinition<Item>[] = [
    createTableColumn<Item>({
      columnId: 'issueName',
      compare: (a, b) => {
        return a?.title?.localeCompare(b?.title);
      },
      renderHeaderCell: () => {
        return (
          <div className={'list-pane-column-header'}>
            <Label weight='semibold'>{i18n.t('title')}</Label>
            <Input
              value={wbsIssueFilter?.title}
              onChange={onChangeTitle}
              onKeyDown={onFilter}
              placeholder={i18n.t('title')}
              contentBefore={<Search24Regular onClick={(e: any) => e.stopPropagation()} />}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        );
      },
      renderCell: (item) => {
        return (
          <span>{item?.title ?? '-'}</span>
        );
      },
    }),
  ];

  return (
    <div style={{ height: '100%', overflow: 'auto' }}>
      <DataGrid
        items={wbsIssueList ?? []}
        columns={isCollapsed ? columns : resizeColumns}
        sortable
        selectionMode='single'
        getRowId={(item) => item?.id}
        // resizableColumns   NOTE: if this is true, you must handle the size of the column width.
        // columnSizingOptions={columnSizingOptions}
        size='small'
        onSelectionChange={handleSelectionChange}
        onSortChange={handleSortChange}
        selectedItems={featureId ? [featureId] : []}
      >
        <DataGridHeader>
          <DataGridRow selectionCell={{ 'aria-label': 'Select all rows' }}>
            {({ renderHeaderCell, columnId }, dataGrid) =>
              dataGrid.resizableColumns ? (
                <Menu openOnContext>
                  <MenuTrigger>
                    <DataGridHeaderCell
                      ref={(el) => (refMap.current[columnId] = el)}
                    >
                      {renderHeaderCell()}
                    </DataGridHeaderCell>
                  </MenuTrigger>
                  <MenuPopover>
                    <MenuList>
                      <MenuItem
                        onClick={dataGrid.columnSizing_unstable.enableKeyboardMode(
                          columnId,
                        )}
                      >
                        Keyboard Column Resizing
                      </MenuItem>
                    </MenuList>
                  </MenuPopover>
                </Menu>
              ) : (
                <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
              )
            }
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody<Item>>
          {({ item, rowId }) => (
            <DataGridRow<Item>
              key={rowId}
              selectionCell={{ 'aria-label': 'Select row' }}
            >
              {({ renderCell }) => (
                <DataGridCell>{renderCell(item)}</DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
    </div>
  );
};

import React from 'react';
import { Cost, ProgressStatement, psFormData } from '../../../../types/progressStatement';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  approvedPs,
  approvedPsCu,
  clearFormData as clearPsData,
  createPSResource,
  readByPSId,
  saveFormData,
} from '../../../../reducers/progressStatementReducer';
import { getProjectHeaderDetails } from '../../../../reducers/projectReducer';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { messageService } from '../../../../services/messageService';
import InvoiceProgressStatementModal from './modal/component';
import _ from 'lodash';
import PdfModal from '../pdf/pdfModal';
import { Invoice } from '../../../../types/invoice';

export interface State {
  formData: ProgressStatement;
  showProgressStatement: boolean;
  projectCostData: Cost[];
  showPrint: boolean,
}

interface Props extends WithTranslation {
  formData: ProgressStatement;
  invoiceData: Invoice;
  progressStatementId: string | null,
  readByPSId: any;
  invoiceDropDowns: any;
  projectHeader: [] | null;
}


class InvoiceProgressStatement extends React.Component<Props, State> {
  subscription: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      formData: psFormData,
      showProgressStatement: false,
      showPrint: false,
      projectCostData: [],
    };
  }

  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.openProgressStatement) {
          this.openProgressStatement();
        }
        if (data.data.invoicePdfGenerate) {
          this.openProgressStatementPrint();
        }
      }
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.formData != this.props.formData) {
      if (!_.isEmpty(this.props.formData)) {
        const formData = this.props.formData;
        this.setState(
          {
            formData,
            projectCostData: this.props.formData.resources,
            // position:{lat:0.0,lon:0.0}, location:null
          });
      }
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  openProgressStatement = () => {
    if (this.props.progressStatementId) {
      this.props.readByPSId(this.props.progressStatementId).then(() => {
        this.setState({ showProgressStatement: true });
      });
    }
  };

  openProgressStatementPrint = () => {
    if (this.props.progressStatementId) {
      this.props.readByPSId(this.props.progressStatementId).then(() => {
        this.setState({ showPrint: true });
      });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <InvoiceProgressStatementModal
          isOpen={this.state.showProgressStatement}
          openModal={() => {
            this.setState({ showProgressStatement: !this.state.showProgressStatement });
          }}
          formData={this.props.formData}
          projectCostData={this.state.projectCostData}
        />
        <PdfModal
          hideModal={() => {
            this.setState({ showPrint: false });
          }}
          isModalOpen={this.state.showPrint}
          psData={this.state.formData}
          customer={this.props.invoiceDropDowns && this.props.invoiceDropDowns.customer ? this.props.invoiceDropDowns.customer : null}
          project={this.props.projectHeader}
          invoiceData={this.props.invoiceData}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    invoiceDropDowns: state.invoice.invoiceDropDowns,
    isEdit: state.ps.isEdit,
    isNew: state.ps.isNew,
    uid: state.ps.uid,
    projectHeader: state.project.projectHeader,
    showProgressBar: state.ps.showProgressBar,
    formData: state.ps.formData,
  };
};

const mapDispatchToProps = {
  saveFormData,
  readByPSId,
  clearPsData,
  getProjectHeaderDetails,
  createPSResource,
  approvedPs,
  approvedPsCu,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InvoiceProgressStatement)),
);

// Automatically generarted by template
export const LOAD_INSTRUCTION_REGISTER_SHORTCUTPANE = 'uprince/LOAD_INSTRUCTION_REGISTER_SHORTCUTPANE';
export const LOAD_INSTRUCTION_REGISTER_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_INSTRUCTION_REGISTER_SHORTCUTPANE_SUCCESS';
export const LOAD_INSTRUCTION_REGISTER_SHORTCUTPANE_FAIL = 'uprince/LOAD_INSTRUCTION_REGISTER_SHORTCUTPANE_FAIL';

export const LOAD_INSTRUCTION_REGISTER_LSITPANE = 'uprince/LOAD_INSTRUCTION_REGISTER_LSITPANE';
export const LOAD_INSTRUCTION_REGISTER_LSITPANE_SUCCESS = 'uprince/LOAD_INSTRUCTION_REGISTER_LSITPANE_SUCCESS';
export const LOAD_INSTRUCTION_REGISTER_LSITPANE_FAIL = 'uprince/LOAD_INSTRUCTION_REGISTER_LSITPANE_FAIL';

export const LOAD_INSTRUCTION_REGISTER_DROPDOWN = 'uprince/LOAD_INSTRUCTION_REGISTER_DROPDOWN';
export const LOAD_INSTRUCTION_REGISTER_DROPDOWN_SUCCESS = 'uprince/LOAD_INSTRUCTION_REGISTER_DROPDOWN_SUCCESS';
export const LOAD_INSTRUCTION_REGISTER_DROPDOWN_FAIL = 'uprince/LOAD_INSTRUCTION_REGISTER_DROPDOWN_FAIL';

export const SAVE_INSTRUCTION_REGISTER = 'uprince/SAVE_INSTRUCTION_REGISTER';
export const SAVE_INSTRUCTION_REGISTER_SUCCESS = 'uprince/SAVE_INSTRUCTION_REGISTER_SUCCESS';
export const SAVE_INSTRUCTION_REGISTER_FAIL = 'uprince/SAVE_INSTRUCTION_REGISTER_FAIL';

export const LOAD_INSTRUCTION_REGISTER_BY_ID = 'uprince/LOAD_INSTRUCTION_REGISTER_BY_ID';
export const LOAD_INSTRUCTION_REGISTER_BY_ID_SUCCESS = 'uprince/LOAD_INSTRUCTION_REGISTER_BY_ID_SUCCESS';
export const LOAD_INSTRUCTION_REGISTER_BY_ID_FAIL = 'uprince/LOAD_INSTRUCTION_REGISTER_BY_ID_FAIL';

export const LOAD_BOR_RESOURCES_BY_ID = 'uprince/LOAD_BOR_RESOURCES_BY_ID';
export const LOAD_BOR_RESOURCES_BY_ID_SUCCESS = 'uprince/LOAD_BOR_RESOURCES_BY_ID_SUCCESS';
export const LOAD_BOR_RESOURCES_BY_ID_FAIL = 'uprince/LOAD_BOR_RESOURCES_BY_ID_FAIL';

export const SET_INSTRUCTION_REGISTER_UID = 'uprince/SET_INSTRUCTION_REGISTER_UID';
export const CLEAR_INSTRUCTION_REGISTER_UID = 'uprince/CLEAR_INSTRUCTION_REGISTER_UID';
export const CLEAR_INSTRUCTION_REGISTER_FORM_DATA = 'uprince/CLEAR_INSTRUCTION_REGISTER_FORM_DATA';
export const RESET_INSTRUCTION_REGISTER_IS_CHANGE = 'uprice/RESET_INSTRUCTION_REGISTER_IS_CHANGE';

export const UPLOAD_INSTRUCTION_REGISTER_DOC = 'uprince/UPLOAD_INSTRUCTION_REGISTER_DOC';
export const UPLOAD_INSTRUCTION_REGISTER_DOC_SUCCESS = 'uprince/UPLOAD_INSTRUCTION_REGISTER_DOC_SUCCESS';
export const UPLOAD_INSTRUCTION_REGISTER_DOC_FAIL = 'uprince/UPLOAD_INSTRUCTION_REGISTER_DOC_FAIL';

export const UPLOAD_WBS_SHAREPOINT_DOC = 'uprince/UPLOAD_WBS_SHAREPOINT_DOC';
export const UPLOAD_WBS_SHAREPOINT_DOC_SUCCESS = 'uprince/UPLOAD_WBS_SHAREPOINT_DOC_SUCCESS';
export const UPLOAD_WBS_SHAREPOINT_DOC_FAIL = 'uprince/UPLOAD_WBS_SHAREPOINT_DOC_FAIL';

export const SAVE_INSTRUCTION_STATE_ATTR = 'uprice/SAVE_INSTRUCTION_STATE_ATTR';

// Added from projectBreakdownType.ts
export const LOAD_INSTRUCTION_DROPDOWN = 'uprince/LOAD_INSTRUCTION_DROPDOWN';
export const LOAD_INSTRUCTION_DROPDOWN_SUCCESS = 'uprince/LOAD_INSTRUCTION_DROPDOWN_SUCCESS';
export const LOAD_INSTRUCTION_DROPDOWN_FAIL = 'uprice/LOAD_INSTRUCTION_DROPDOWN_FAIL';

export const LOAD_TECH_INSTRUCTION = 'uprince/LOAD_TECH_INSTRUCTION';
export const LOAD_TECH_INSTRUCTION_SUCCESS = 'uprince/LOAD_TECH_INSTRUCTION_SUCCESS';
export const LOAD_TECH_INSTRUCTION_FAIL = 'uprice/LOAD_TECH_INSTRUCTION_FAIL';

export const UPLOAD_INSTRUCTION_DOC = 'uprince/UPLOAD_INSTRUCTION_DOC';
export const UPLOAD_INSTRUCTION_DOC_SUCCESS = 'uprince/UPLOAD_INSTRUCTION_DOC_SUCCESS';
export const UPLOAD_INSTRUCTION_DOC_FAIL = 'uprice/UPLOAD_INSTRUCTION_DOC_FAIL';

export const CREATE_TECH_INSTRUCTION = 'uprince/CREATE_TECH_INSTRUCTION';
export const CREATE_TECH_INSTRUCTION_SUCCESS = 'uprince/CREATE_TECH_INSTRUCTION_SUCCESS';
export const CREATE_TECH_INSTRUCTION_FAIL = 'uprice/CREATE_TECH_INSTRUCTION_FAIL';

export const DELETE_TECH_INSTRUCTION = 'uprince/DELETE_TECH_INSTRUCTION';
export const DELETE_TECH_INSTRUCTION_SUCCESS = 'uprince/DELETE_TECH_INSTRUCTION_SUCCESS';
export const DELETE_TECH_INSTRUCTION_FAIL = 'uprice/DELETE_TECH_INSTRUCTION_FAIL';

export const CREATE_SAFTY_INSTRUCTION = 'uprince/CREATE_SAFTY_INSTRUCTION';
export const CREATE_SAFTY_INSTRUCTION_SUCCESS = 'uprince/CREATE_SAFTY_INSTRUCTION_SUCCESS';
export const CREATE_SAFTY_INSTRUCTION_FAIL = 'uprice/CREATE_SAFTY_INSTRUCTION_FAIL';

export const LOAD_SAFTY_INSTRUCTION = 'uprince/LOAD_SAFTY_INSTRUCTION';
export const LOAD_SAFTY_INSTRUCTION_SUCCESS = 'uprince/LOAD_SAFTY_INSTRUCTION_SUCCESS';
export const LOAD_SAFTY_INSTRUCTION_FAIL = 'uprice/LOAD_SAFTY_INSTRUCTION_FAIL';

export const DELETE_SAFTY_INSTRUCTION = 'uprince/DELETE_SAFTY_INSTRUCTION';
export const DELETE_SAFTY_INSTRUCTION_SUCCESS = 'uprince/DELETE_SAFTY_INSTRUCTION_SUCCESS';
export const DELETE_SAFTY_INSTRUCTION_FAIL = 'uprice/DELETE_SAFTY_INSTRUCTION_FAIL';

export const CREATE_ENV_INSTRUCTION = 'uprince/CREATE_ENV_INSTRUCTION';
export const CREATE_ENV_INSTRUCTION_SUCCESS = 'uprince/CREATE_ENV_INSTRUCTION_SUCCESS';
export const CREATE_ENV_INSTRUCTION_FAIL = 'uprice/CREATE_ENV_INSTRUCTION_FAIL';

export const LOAD_ENV_INSTRUCTION = 'uprince/LOAD_ENV_INSTRUCTION';
export const LOAD_ENV_INSTRUCTION_SUCCESS = 'uprince/LOAD_ENV_INSTRUCTION_SUCCESS';
export const LOAD_ENV_INSTRUCTION_FAIL = 'uprice/LOAD_ENV_INSTRUCTION_FAIL';

export const DELETE_ENV_INSTRUCTION = 'uprince/DELETE_ENV_INSTRUCTION';
export const DELETE_ENV_INSTRUCTION_SUCCESS = 'uprince/DELETE_ENV_INSTRUCTION_SUCCESS';
export const DELETE_ENV_INSTRUCTION_FAIL = 'uprice/DELETE_ENV_INSTRUCTION_FAIL';

export const CREATE_HELTH_INSTRUCTION = 'uprince/CREATE_HELTH_INSTRUCTION';
export const CREATE_HELTH_INSTRUCTION_SUCCESS = 'uprince/CREATE_HELTH_INSTRUCTION_SUCCESS';
export const CREATE_HELTH_INSTRUCTION_FAIL = 'uprice/CREATE_HELTH_INSTRUCTION_FAIL';

export const LOAD_HELTH_INSTRUCTION = 'uprince/LOAD_HELTH_INSTRUCTION';
export const LOAD_HELTH_INSTRUCTION_SUCCESS = 'uprince/LOAD_HELTH_INSTRUCTION_SUCCESS';
export const LOAD_HELTH_INSTRUCTION_FAIL = 'uprice/LOAD_HELTH_INSTRUCTION_FAIL';

export const DELETE_HELTH_INSTRUCTION = 'uprince/DELETE_HELTH_INSTRUCTION';
export const DELETE_HELTH_INSTRUCTION_SUCCESS = 'uprince/DELETE_HELTH_INSTRUCTION_SUCCESS';
export const DELETE_HELTH_INSTRUCTION_FAIL = 'uprice/DELETE_HELTH_INSTRUCTION_FAIL';


// Added from projectMoleculeActionTypes.ts
export const LOAD_PMOL_TECH_INSTRUCTION = 'uprince/LOAD_PMOL_TECH_INSTRUCTION';
export const LOAD_PMOL_TECH_INSTRUCTION_SUCCESS = 'uprince/LOAD_PMOL_TECH_INSTRUCTION_SUCCESS';
export const LOAD_PMOL_TECH_INSTRUCTION_FAIL = 'uprice/LOAD_PMOL_TECH_INSTRUCTION_FAIL';

export const LOAD_PMOL_SAFTY_INSTRUCTION = 'uprince/LOAD_PMOL_SAFTY_INSTRUCTION';
export const LOAD_PMOL_SAFTY_INSTRUCTION_SUCCESS = 'uprince/LOAD_PMOL_SAFTY_INSTRUCTION_SUCCESS';
export const LOAD_PMOL_SAFTY_INSTRUCTION_FAIL = 'uprice/LOAD_PMOL_SAFTY_INSTRUCTION_FAIL';

export const LOAD_PMOL_ENV_INSTRUCTION = 'uprince/LOAD_PMOL_ENV_INSTRUCTION';
export const LOAD_PMOL_ENV_INSTRUCTION_SUCCESS = 'uprince/LOAD_PMOL_ENV_INSTRUCTION_SUCCESS';
export const LOAD_PMOL_ENV_INSTRUCTION_FAIL = 'uprice/LOAD_PMOL_ENV_INSTRUCTION_FAIL';

export const LOAD_PMOL_HELTH_INSTRUCTION = 'uprince/LOAD_PMOL_HELTH_INSTRUCTION';
export const LOAD_PMOL_HELTH_INSTRUCTION_SUCCESS = 'uprince/LOAD_PMOL_HELTH_INSTRUCTION_SUCCESS';
export const LOAD_PMOL_HELTH_INSTRUCTION_FAIL = 'uprice/LOAD_PMOL_HELTH_INSTRUCTION_FAIL';

export const LOAD_PMOL_ALL_INSTRUCTION = 'uprince/LOAD_PMOL_ALL_INSTRUCTION';
export const LOAD_PMOL_ALL_INSTRUCTION_SUCCESS = 'uprince/LOAD_PMOL_ALL_INSTRUCTION_SUCCESS';
export const LOAD_PMOL_ALL_INSTRUCTION_FAIL = 'uprice/LOAD_PMOL_ALL_INSTRUCTION_FAIL';


export const CLEAR_INSTRUCTION_REGISTERUID = 'uprice/CLEAR_INSTRUCTION_REGISTERUID';

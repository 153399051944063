import { Icon } from '@fluentui/react';
import { Label } from '@fluentui/react-components';
import { Edit24Filled } from '@fluentui/react-icons';
import React from 'react';
import EditDocumentContentType from './EditDocumentContentType';

// keep this snippet
// const CustomIcon = ({ icon }: { icon: string }) => (
//   <img
//     src={`https://res-1.cdn.office.net/files/fabric-cdn-prod_20221209.001/assets/brand-icons/product/svg/${icon}_24x1.svg`}
//     width='24'
//     height='24'
//     alt='Word product icon'
//   />
// );

function getIcon(attachment: any) {
  let fileType = attachment.attachmentType;
  if (attachment.attachmentType === 'mail') {
    return <Icon iconName="Mail" style={{ color: '#64879B' }} />;
  } else if (fileType === 'doc' || fileType === 'docx') {
    return <Icon iconName={'WordDocument'} />;
  } else if (fileType === 'xls' || fileType === 'xlsx') {
    return <Icon iconName={'ExcelDocument'} />;
  } else if (fileType === 'ppt' || fileType === 'pptx') {
    return <Icon iconName={'PowerPointDocument'} />;
  } else if (fileType === 'txt') {
    return <Icon iconName="TextDocument" style={{ color: '#f7d166' }} />;
  } else if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') {
    return <Icon iconName="FileImage" style={{ color: '#64879B' }} />;
  } else if (fileType === 'zip' || fileType === 'rar') {
    return <Icon iconName="ZipFolder" style={{ color: '#6C1775' }} />;
  } else if (fileType === 'pdf' || fileType === 'PDF') {
    return <Icon iconName="PDF" style={{ color: '#6C1775' }} />;
  } else {
    return <Icon iconName="FileTemplate" style={{ color: '#EEF0E2' }} />;
  }
}

interface Props {
  files: any;
  isEdit?: boolean;
  onEditClick?: any;
  isDelete?: boolean;
  onDeleteClick?: any;
}

export default function FilesList(props: Props) {
  const { files, isDelete, onDeleteClick } = props;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginTop: 20,
      }}
    >
      {files?.map((option: any) => (
        <div
          style={{
            display: 'flex',
            marginRight: 20,
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '95%',
          }}
          key={option.id as any}
        >
          <div style={{ alignSelf: 'flex-start', marginRight: 'auto' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div style={{ width: 20 }}>{getIcon(option)}</div>
              <Label style={{ marginLeft: 10 }}>{option.name}</Label>
            </div>
          </div>
          {/*<Icon onClick={onEditClick} iconName="Edit" style={{ color: "black", marginRight: 10, cursor: "pointer" }} />*/}
          {isDelete && (
            <Icon
              onClick={() => onDeleteClick(option)}
              iconName="Delete"
              style={{ color: 'black', cursor: 'pointer' }}
            />
          )}
          <EditDocumentContentType file={option}/>
        </div>
      ))}
    </div>
  );
}

import {
  COPY_WBS_TO_PROJECT,
  COPY_WBS_TO_PROJECT_FAIL,
  COPY_WBS_TO_PROJECT_SUCCESS,
  SAVE_WBS_STATE_ATTR,
} from '../actionTypes/wbsActionTypes';
import { COPY_WBS_TO_PROJECT_EP } from '../shared/endpoints';

const defaultState: any = {
  showWbsProgressBar: false,
  wbsTaskId: null,
  projectWbsTemplateId: null,
  defaultOpenItems: [],
  selectedTreeItem: null,
};

export default function wbsReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {

      case SAVE_WBS_STATE_ATTR:
        const attr = Object.keys(action)[1];
        const value = Object.values(action)[1];
        return {
          ...state,
          [attr]: value,
        };

      case COPY_WBS_TO_PROJECT:
        return {
          ...state,
          showWbsProgressBar: true,
        };
      case COPY_WBS_TO_PROJECT_SUCCESS:
        return {
          ...state,
          showWbsProgressBar: false,
        };
      case COPY_WBS_TO_PROJECT_FAIL:
        return {
          ...state,
          showWbsProgressBar: false,
        };

      default:
        return state;
    }
  }
}

export function saveWbsStateAttr(attr: string, value: any) {
  return {
    type: SAVE_WBS_STATE_ATTR,
    [attr]: value,
  };
}

export const copyWbsToProject = (templateId: string) => {
  return {
    types: [
      COPY_WBS_TO_PROJECT,
      COPY_WBS_TO_PROJECT_SUCCESS,
      COPY_WBS_TO_PROJECT_FAIL,
    ],
    payload: {
      request: {
        url: COPY_WBS_TO_PROJECT_EP + templateId,
        method: 'GET',
      },
    },
  };
};

import { Dropdown, Label } from 'office-ui-fabric-react';
import React, { useContext } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  filterVisualPlanListPaneData,
  readPurchaseAndDeliveryTaxonomyDataCU,
  readResourceTaxonmyDataVP,
  saveVPStateAttr,
} from '../../../reducers/visualPlanReducer';
import CustomDatePicker from '../../../shared/customDatePicker/customDatePicker';
import { ViewMode } from '../../../shared/ganttv2';
// import 'style.css';
import ModeSwitcher from '../../../shared/ganttViewSwitcher/viewSwitcher';
import { formatDateTimeToISOString, getSelectedFilterDates } from '../../../shared/util';
import { ViewModeContext } from '../../projectVisualPlan/productTaxonomyListPane/component';

export const ViewSwitcher: React.FC = () => {
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [searchTriggered, setSearchTriggered] = React.useState<boolean>(false);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [selectedMode, setSelectedViewMode] = React.useState<ViewMode>(ViewMode.QuaterYear);
  const vpFilterDropdown: any = useSelector(
    (state: any) => state.vp.vpFilterDropdown,
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { onViewModeChange, onViewListChange, isCheck } =
    useContext(ViewModeContext);
    

  const standerdFilterOptions = useSelector(
    (state: any) => state.vp.vpFilterDropdown,
  );

  const vpCUSelectedShortcutpaneType = useSelector(
    (state: any) => state.vp.vpCUSelectedShortcutpaneType,
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        height: 70,
        width: '100%',
        position: 'sticky',
        top: 0,
        zIndex: 100,
        borderBottom: '0.5px solid gray',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          flexGrow: 0.2,
          width: '100%',
        }}
      >
        <Form
          onSubmit={() => {
          }}
          initialValues={{
            startDate: startDate ? startDate : null,
            endDate: endDate ? endDate : null,
          }}
          mutators={{
            setHeaderDocs: (args, state, utils) => {
              const field = state.fields['header.files'];
              field.change(args[0]);
            },
            setDateRange: (args, state, utils) => {
              const from = state.fields['fromDate'];
              const to = state.fields['toDate'];
              from.change(getSelectedFilterDates(args)[0]);
              to.change(getSelectedFilterDates(args)[1]);
             
              if (vpCUSelectedShortcutpaneType !== 9) {
                dispatch( readPurchaseAndDeliveryTaxonomyDataCU({ fromDate: formatDateTimeToISOString(
                  getSelectedFilterDates(args)[0],
                ),
                toDate: formatDateTimeToISOString(
                  getSelectedFilterDates(args)[1],
                ),}));
              } else {
                dispatch(readResourceTaxonmyDataVP({
                  type: ['9'],
                  fromDate: formatDateTimeToISOString(
                    getSelectedFilterDates(args)[0],
                  ),
                  toDate: formatDateTimeToISOString(
                    getSelectedFilterDates(args)[1],
                  )
                }))
              }
            },
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => {

            return (
              <form onSubmit={handleSubmit} noValidate>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    height: 70,
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                      height: 70,
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        height: 70,
                        width: '100%',
                      }}
                    >
                       <div style={{ width: 180, marginLeft: 15 }}>
                        <Field name="date">
                          {({ input, meta }) => (
                            <Dropdown
                              placeholder={t('standardFilter')}
                              label={t('standardFilter')}
                              selectedKey={
                                values.date ? values.date : ''
                              }
                              // eslint-disable-next-line react/jsx-no-bind
                              onChange={(event, value) => {
                                let date = null;
                                if (value) {
                                  date = value.key;
                                  input.onChange(value.key);

                                } else {
                                  date = null;
                                  input.onChange(null);
                                }
                                form.mutators.setDateRange(value?.key);
                              //   dispatch(readPurchaseAndDeliveryTaxonomyDataCU({
                              //     toDate: formatDateTimeToISOString(date!!),
                              //     fromDate: formatDateTimeToISOString(startDate!!),
                              // }));
                              }}
                              options={
                                vpFilterDropdown
                                  ? vpFilterDropdown
                                  : []
                              }
                            />
                          )}
                        </Field>
                      </div>
                      <div className={'vpDatePicker180'} style={{ width: 180, marginLeft: 15 }}>
                        <Label style={{ width: 180 }}>{t('fromDate')}:</Label>
                        <Field name="fromDate">
                          {({ input, meta }) => (
                            <CustomDatePicker
                              setValue={values.fromDate ? values.fromDate : startDate}
                              getValue={(date: Date) => {
                                // form.mutators.setStandardFilter();
                                input.onChange(date);
                                setStartDate(date);
                                input.onChange(date);
                                let clDate = new Date(date);
                                let newDate = new Date(clDate.setDate(clDate.getDate() + 30));
                                setEndDate(newDate);
                                if (vpCUSelectedShortcutpaneType !== 9) {
                                  dispatch(readPurchaseAndDeliveryTaxonomyDataCU({
                                    fromDate: formatDateTimeToISOString(date!!),
                                    toDate: formatDateTimeToISOString(newDate!!),
                                  }));
                                } else {
                                  dispatch(readResourceTaxonmyDataVP({
                                    type: ['9'],
                                    fromDate: formatDateTimeToISOString(date!!),
                                    toDate: formatDateTimeToISOString(newDate!!),
                                  }))
                              }
                              }}
                            />
                          )}
                        </Field>
                      </div>

                      <div className={'vpDatePicker180'} style={{ width: 180, marginLeft: 15 }}>
                        <Label style={{ width: 180 }}>{t('toDate')}:</Label>
                        <Field name="toDate">
                          {({ input, meta }) => (
                            <CustomDatePicker
                              // setMinDate={new Date(form.getFieldState('fromDate')?.value)}
                              //setMaxDate={ maxDate }

                              // setValue={ (props.formData.executionDate) ? new Date(props.formData.executionDate) : null }
                              setValue={values.toDate ? values.toDate : endDate}
                              getValue={(date: Date) => {
                                // form.mutators.setStandardFilter();
                                input.onChange(date);
                                setEndDate(date);
                                if (vpCUSelectedShortcutpaneType !== 9) {
                                  dispatch(readPurchaseAndDeliveryTaxonomyDataCU({
                                    fromDate: formatDateTimeToISOString(startDate!!),
                                    toDate: formatDateTimeToISOString(date!!),
                                  }));
                                } else {
                                  dispatch(readResourceTaxonmyDataVP({
                                    type: ['9'],
                                    fromDate: formatDateTimeToISOString(startDate!!),
                                    toDate: formatDateTimeToISOString(date!!),
                                  }))
                                }
                                
                              }}
                            />
                          )}
                        </Field>
                      </div>
                      <ModeSwitcher
                         onViewModeChange={(t:any)=>{
                          onViewModeChange(t)
                          setSelectedViewMode(t)
                        }}
                        onViewListChange={onViewListChange}
                        isCheck={isCheck}
                        selectedViewMode={selectedMode}
                      />
                    </div>
                  </div>
                </div>

              </form>
            );
          }}
        />
      </div>
    </div>
  );
};

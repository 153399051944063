import * as React from 'react';
import { useRef } from 'react';
import { IImageProps, Image, ImageFit } from '@fluentui/react/lib/Image';
import { Label } from '@fluentui/react/lib/Label';
import { IStackItemStyles, IStackStyles, IStackTokens, Stack } from '@fluentui/react';
import { AddressBookItem } from '../../../types/addressBookItem';
import { useTranslation } from 'react-i18next';

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingLeft: 15,
  },
};

const numericalSpacingStackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    background: '#FFFFFF',
    marginBottom: 20,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

export const BusinessCard = (props: {
  addressBookItem: AddressBookItem;
  onProfileImageChange: any;
}) => {
  const { t } = useTranslation();
  const imageProps: IImageProps = {
    src:
      props.addressBookItem &&
      props.addressBookItem.person &&
      props.addressBookItem.person.image
        ? props.addressBookItem.person.image
        : 'http://placehold.it/500x250',
    imageFit: ImageFit.none,
    width: 50,
    height: 50,
  };
  const inputFile: any = useRef(null);
  return (
    <Stack
      horizontal
      wrap
      styles={stackStyles}
      tokens={numericalSpacingStackTokens}
    >
      <Stack.Item styles={stackItemStyles}>
        <div>
          <Image
            {...imageProps}
            // src="http://placehold.it/100x100"
            style={{ width: 50, height: 50, borderRadius: 50 / 2 }}
            onClick={() => inputFile?.current?.click()}
          />
          <input
            type="file"
            id="file"
            ref={inputFile}
            style={{ display: 'none' }}
            onChange={props.onProfileImageChange}
          />
        </div>
      </Stack.Item>
      <Stack.Item styles={stackItemStyles}>
        <Stack>
          <Label
            style={{ fontSize: 20 }}
            disabled={
              props.addressBookItem &&
              props.addressBookItem.person &&
              props.addressBookItem.person.fullName
                ? false
                : true
            }
          >
            {props.addressBookItem &&
            props.addressBookItem.person && props.addressBookItem.person.fullName
              ? props.addressBookItem.person.fullName
              : t('name')}
          </Label>
          <Label
            style={{ fontSize: 12 }}
            disabled={
              props.addressBookItem &&
              props.addressBookItem.personCompany &&
              props.addressBookItem.personCompany.email
                ? false
                : true
            }
          >
            {props.addressBookItem && props.addressBookItem.personCompany &&
            props.addressBookItem.personCompany.email
              ? props.addressBookItem.personCompany.email
              : t('email')}
          </Label>
        </Stack>
      </Stack.Item>
      <Stack.Item styles={stackItemStyles}>
        <Label
          style={{ fontSize: 20 }}
          disabled={
            props.addressBookItem &&
            props.addressBookItem.company &&
            props.addressBookItem.company.name
              ? false
              : true
          }
        >
          -
        </Label>
      </Stack.Item>
      <Stack.Item styles={stackItemStyles}>
        <Stack>
          <Label
            style={{ fontSize: 20 }}
            disabled={
              props.addressBookItem &&
              props.addressBookItem.company &&
              props.addressBookItem.company.name
                ? false
                : true
            }
          >
            {props.addressBookItem && props.addressBookItem.company && props.addressBookItem.company.name
              ? props.addressBookItem.company.name
              : t('company')}
          </Label>

          <Label
            style={{ fontSize: 12 }}
            disabled={
              props.addressBookItem &&
              props.addressBookItem.personCompany &&
              props.addressBookItem.personCompany.mobilePhone
                ? false
                : true
            }
          >
            {props.addressBookItem && props.addressBookItem.personCompany &&
            props.addressBookItem.personCompany.mobilePhone
              ? props.addressBookItem.personCompany.mobilePhone
              : t('mobile')}
          </Label>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

import React from 'react'
import { Label, Link } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import HistoryBar from '../../../../shared/historyBar/historyBar'

const History = (props: {
  createdByUser: any;
  updatedByUser: any;
  createdDateTime: any;
  updatedDateTime: any
}) => {
  const {t} = useTranslation()
  return (
    <div className="document-pane-card">
      <div className="marginTop marginBottom">
        <Link href="#" id="history">
          <Label>8. {t('history')} </Label>
        </Link>
        <HistoryBar
          createdByUser={props.createdByUser ? props.createdByUser : ''}
          updatedByUser={props.updatedByUser ? props.updatedByUser : ''}
          createdDateTime={props.createdDateTime ? props.createdDateTime : ''}
          updatedDateTime={props.updatedDateTime ? props.updatedDateTime : ''}
        />
      </div>
    </div>
  )
}

export default History
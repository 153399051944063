import React from 'react';
import { initializeIcons } from '@uifabric/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ShortCutPaneComponent } from './component';
import history from '../../../history';
import { messageService } from '../../../services/messageService';
import { getContractingUnit, getProject } from '../../../shared/util';
import { POFilter, POShortCutPaneItem } from '../../../types/purchaseOrder';

initializeIcons();

interface Props extends WithTranslation {
  shortCutPaneFilters: POShortCutPaneItem[];
  handelShortCutPaneFilter: (selectedItem: POShortCutPaneItem) => void;
  filter: POFilter;
  projectId: string | null;
  toggleOverlay: boolean;
  resetLayout: boolean
}

interface State {
  divClass: string;
  selectedCodeType: number;
  currentActiveSection: string | null;
}

class ShortCutPane extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      divClass: 'filter-panel-left',
      selectedCodeType: -1,
      currentActiveSection: null,
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.toggleOverlay !== prevProps.toggleOverlay) {
      if (this.props.toggleOverlay) {
        this.setState({ divClass: 'filter-panel-left panel-move' });
      } else {
        this.setState({ divClass: 'filter-panel-left' });
      }
    }
    // if(this.props.resetLayout !==prevProps.resetLayout){
    //   if(this.props.resetLayout){
    //     this.setState({ divClass: 'filter-panel-left' });
    //   }
    // }
  }

  componentDidMount() {
    if (this.state.divClass === 'filter-panel-left') {
      if (window.innerWidth > 1300) {
        messageService.sendMessage({ shortCutPaneExpanded: true });
      }
    } else {
      messageService.sendMessage({ shortCutPaneExpanded: false });
    }
  }


  private handelShortCutPaneFilter = (selectedItem: POShortCutPaneItem) => {
    this.setState({
      currentActiveSection: selectedItem.value,
    });
    let filter: POFilter = new POFilter();
    if (selectedItem.type === 'Resource') {
      filter.resource = selectedItem.value;
    }else if (selectedItem.type === 'RequestType') {
      filter.requestTypeId = selectedItem.value;
    } else {
      filter.statusId = selectedItem.value;
    }


    if (getContractingUnit() && !getProject()) {
      history.push({
        pathname: `/CU/${getContractingUnit()}/purchase-order`,
        state: { poFilter: filter },
      });
    } else {
      history.push({
        pathname: `/CU/${getContractingUnit()}/project/${getProject()}/purchase-order`,
        state: { poFilter: filter },
      });
    }

    this.props.handelShortCutPaneFilter(selectedItem);
  };

  private resetFilter = () => {
    let filterItem: POShortCutPaneItem = {
      id: null,
      name: null,
      type: null,
      value: null,
    };
    this.setState({
      currentActiveSection: null,
    });

    this.props.handelShortCutPaneFilter(filterItem);
  };

  private fromatShortCutPaneData = (shrotCutaPaneData: any) => {
    const { t } = this.props;
    let data: any[] = [];
    if (shrotCutaPaneData) {
      let setIcon = shrotCutaPaneData?.map((item: any) => {
        switch (item.name) {
          case 'Materials':
            return {
              ...item,
              icon: 'ms-Icon--ExpandMenu',
            };
          case 'Tools':
            return {
              ...item,
              icon: 'ms-Icon--DeveloperTools',
            };
          case 'Consumables':
            return {
              ...item,
              icon: 'ms-Icon--HintText',
            };
          case 'Human Resources':
            return {
              ...item,
              icon: 'ms-Icon--People',
            };
          default:
            return {
              ...item,
              icon: 'ms-Icon--GotoToday',
            };
        }
      });
      data.push(setIcon);
    }
    return data[0];
  };

  public render(): JSX.Element {
    return (
      <ShortCutPaneComponent
        currentActiveSection={this.state.currentActiveSection}
        toggleOverlay={this._toggleOverlay}
        shortCutPaneFilters={this.fromatShortCutPaneData(this.props.shortCutPaneFilters)}
        toggleOverlayFwd={this._toggleOverlayFwd}
        handelShortCutPaneFilter={(selectedItem: POShortCutPaneItem) => {
          this.handelShortCutPaneFilter(selectedItem);
        }}
        resetFilter={() => this.resetFilter()}
        divClass={this.state.divClass}
      />
    );
  }

  private _toggleOverlay = (): void => {
    this.setState({ divClass: 'filter-panel-left panel-move' });
    messageService.sendMessage({ shortCutPaneExpanded: false });
  };
  private _toggleOverlayFwd = (): void => {
    this.setState({ divClass: 'filter-panel-left' });
    messageService.sendMessage({ shortCutPaneExpanded: true });
  };
}

export default withTranslation()(ShortCutPane);

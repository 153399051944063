import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';
import { ProgressStatement } from '../../../types/progressStatement';

const CommandBarRight = (props: {
  createNew: (create: boolean) => void;
  saveProductCatalogue: (save: boolean) => void;
  handleRemove: () => void;
  formData: ProgressStatement
  isLoading: boolean
  isDisableNewButton: boolean
}) => {
  const { t } = useTranslation();
  const newClick = () => {
    messageService.sendMessage({
      isNewPs: true,
    });
    props.createNew(true);
  };
  const saveFormData = () => {
    messageService.sendMessage({
      savePsFormData: true,
    });
  };
  const handleRemove = () => {
    props.handleRemove();
  };

  const handlePmolApprove = () => {
    messageService.sendMessage({
      approvePmol: true,
    });
  };
  const printClick = () => {
    messageService.sendMessage({
      pdfGenerate: true,
    });
  };

  const handlePriceList = () => {
    messageService.sendMessage({
      openPriceList: true,
    });
  };

  const getItems = () => {
    return [
      {
        key: 'new',
        text: t('new'),
        iconProps: { iconName: 'Add' },
        onClick: () => {
          newClick();
        },
        //disabled:props.isDisableNewButton
      },
      {
        key: 'save',
        text: t('save'),
        iconProps: { iconName: 'Save' },
        onClick: () => {
          saveFormData();
        },
        disabled: props.isLoading,
      },

    ];
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );
};

export default CommandBarRight;

import { DeliveryPlanListPaneItem, PRODUCT_TYPE_ID, RESOURCE_TYPE_ID } from '../../../../types/purchaseOrder';
import { withTranslation, WithTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { IColumn, Selection } from '@fluentui/react/lib/DetailsList';
import { formatDate } from '../../../../shared/util';
import ListPaneComponent from './component';
import {
  cuDeliveryPlanStockUpdate,
  deliveryPlanProductCuStockUpdate,
  deliveryPlanProductStockUpdate,
  deliveryPlanStockUpdate,
  getCuDeliveryPlanListPaneData,
  getDeliveryPlanListPaneData,
  poBorUpdate,
  poCUBorUpdate,
  readByPurchaseOrderId,
} from '../../../../reducers/purchaseOrderReducer';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
  APPROVED_STATUS_ID,
  HANDED_OVER_STATUS_ID,
  IN_REVIEW_ACCEPT_STATUS_ID,
} from '../../../../types/projectMolecule';
import ProductListPaneComponent from './productListPaneComponent';


export interface State {
  announcedMessage: string;
  selectedListItemIds: [] | string[];
  selectedListItemSequenceCode: [] | string[];
  selectedListItems: DeliveryPlanListPaneItem[];
  deliveryPlanListPaneItem: DeliveryPlanListPaneItem[];
  deliveryPlanProductListPaneItem: DeliveryPlanListPaneItem[];
}

export interface Props extends WithTranslation {
  isDataLoaded: boolean;
  isDataLoadedProduct: boolean;
  isOpen: boolean;
  openModal: () => void;
  getDeliveryPlanListPaneData: (id: string) => any;
  getCuDeliveryPlanListPaneData: (id: string) => any;
  deliveryPlanStockUpdate: (data: any) => any;
  cuDeliveryPlanStockUpdate: (data: any) => any;
  poBorUpdate: (data: any) => any;
  poCUBorUpdate: (data: any) => any;
  deliveryPlanListPaneItem: DeliveryPlanListPaneItem[];
  deliveryPlanProductListPaneItem: any;
  deliveryPlanProductStockUpdate: (data: any) => any;
  deliveryPlanProductCuStockUpdate: (data: any) => any;
  loadMsg: string;
  loadMsgProduct: string;
  setSelectedBORIds: (ids: string[]) => void;
  formData: any,
  readByPurchaseOrderId: (id: string) => void;
  createAutoGeneratedPO: () => void
}

class DeliveryPlanModal extends Component<Props, State> {
  private _selection: Selection;
  private _columns: IColumn[];
  private _columnsCu: IColumn[];
  private _productColumns: IColumn[];
  private _productColumnsCu: IColumn[];
  subscription: any;

  constructor(props: Props) {
    super(props);
    const { t } = this.props;
    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState(
          {
            selectedListItemIds: this._getSelectedIds(),
            selectedListItemSequenceCode: this._getselectedSequenceCode(),
            selectedListItems: this._getSelectedListItems(),
          },
          this.handleSelectedItem,
        );
      },

      //canSelectItem:this.canSelectItem.bind(this)
    });

    this.state = {
      deliveryPlanListPaneItem: [],
      deliveryPlanProductListPaneItem: [],
      selectedListItems: [],
      selectedListItemIds: [],
      selectedListItemSequenceCode: [],
      announcedMessage: '',
    };

    this._columns = [
      {
        key: 'column1',
        name: t('borTitle'),
        fieldName: 'borTitle',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'column2',
        name: t('resourceType'),
        fieldName: 'resourcesTypeName',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'column3',
        name: t('resourceTitle'),
        fieldName: 'resourceTitle',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'column4',
        name: t('quantity'),
        fieldName: 'quantity',
        minWidth: 60,
        maxWidth: 100,
        isResizable: true,
        className: 'totalAmountCol',
      },
      {
        key: 'column5',
        name: t('mou'),
        fieldName: 'mou',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'column6',
        name: t('unitPrice'),
        fieldName: 'cUnitPrice',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        className: 'totalAmountCol',
      },
      {
        key: 'column7',
        name: t('stockAvailability'),
        fieldName: 'stockAvailability',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        className: 'totalAmountCol',
      },
      {
        key: 'column8',
        name: t('action'),
        fieldName: 'action',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
      },

      {
        key: 'column9',
        name: t('requestedDeliveryDate'),
        fieldName: 'requestedDeliveryDate',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
      },

      {
        key: 'column10',
        name: t('expectedDeliveryDate'),
        fieldName: 'expectedDeliveryDate',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
      },
    ];
    this._columnsCu = [
      {
        key: 'column1',
        name: t('borTitle'),
        fieldName: 'borTitle',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'column2',
        name: t('resourceType'),
        fieldName: 'resourcesTypeName',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'column3',
        name: t('resourceTitle'),
        fieldName: 'resourceTitle',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'column4',
        name: t('quantity'),
        fieldName: 'quantity',
        minWidth: 60,
        maxWidth: 100,
        isResizable: true,
        className: 'totalAmountCol',
      },
      {
        key: 'column5',
        name: t('mou'),
        fieldName: 'mou',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'column6',
        name: t('unitPrice'),
        fieldName: 'cUnitPrice',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        className: 'totalAmountCol',
      },
      {
        key: 'column7',
        name: t('requestedDeliveryDate'),
        fieldName: 'requestedDeliveryDate',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
      },

      {
        key: 'column8',
        name: t('expectedDeliveryDate'),
        fieldName: 'expectedDeliveryDate',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
      },
    ];

    this._productColumns = [
      {
        key: 'column1',
        name: t('productTitle'),
        fieldName: 'title',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'column2',
        name: t('totalPrice'),
        fieldName: 'cTotalPrice',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        className: 'totalAmountCol',
      },
      {
        key: 'column4',
        name: t('action'),
        fieldName: 'action',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
      },

      {
        key: 'column5',
        name: t('requestedDeliveryDate'),
        fieldName: 'requestedDeliveryDate',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
      },

      {
        key: 'column6',
        name: t('expectedDeliveryDate'),
        fieldName: 'expectedDeliveryDate',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
      },
    ];

    this._productColumnsCu = [
      {
        key: 'column1',
        name: t('productTitle'),
        fieldName: 'title',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'column2',
        name: t('totalPrice'),
        fieldName: 'cTotalPrice',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        className: 'totalAmountCol',
      },
      {
        key: 'column5',
        name: t('requestedDeliveryDate'),
        fieldName: 'requestedDeliveryDate',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
      },

      {
        key: 'column6',
        name: t('expectedDeliveryDate'),
        fieldName: 'expectedDeliveryDate',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
      },
    ];

  }

  componentDidMount() {
    //this.getListPaneData();
  }

  getExpectedDeliveryDate = (resourceItem: any) => {
    if (!resourceItem.expectedDeliveryDate) {
      if (this.props.formData.isCu) {
        return this.props.formData.deliveryDate;
      } else if (!resourceItem.isStock && !resourceItem.isPo) {
        return this.props.formData.deliveryDate;
      } else if (resourceItem.isStock) {
        return this.props.formData.deliveryDate;
      } else if (resourceItem.isPo) {
        return null;
      }
    } else {
      return resourceItem.expectedDeliveryDate;
    }
  };

  componentDidUpdate(prevProps: any, prevState: any) {

    if (prevProps.deliveryPlanListPaneItem !== this.props.deliveryPlanListPaneItem) {
      if (this.props.deliveryPlanListPaneItem) {
        const deliveryPlanListPaneItem = this.props.deliveryPlanListPaneItem;
        deliveryPlanListPaneItem.map((resourceItem: any, rindex: number) => {
          if (resourceItem.resources) {
            resourceItem.resources.map((resourceItem: any, rindex: number) => {
              resourceItem.requestedDeliveryDate = this.props.formData.deliveryDate;
              resourceItem.expectedDeliveryDate = this.getExpectedDeliveryDate(resourceItem);
            });
          }
        });
        this.setState({ deliveryPlanListPaneItem });
      }
    }
    if (prevProps.deliveryPlanProductListPaneItem !== this.props.deliveryPlanProductListPaneItem) {
      if (this.props.deliveryPlanProductListPaneItem) {
        const deliveryPlanProductListPaneItem = this.props.deliveryPlanProductListPaneItem;
        this.setState({ deliveryPlanProductListPaneItem });
      }
    }
    if (prevProps.formData !== this.props.formData) {
      if (this.props.isOpen) {
        this.getListPaneData();
      }
    }
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        this.getListPaneData();
      }
    }
  }

  private canSelectItem(item: any): boolean {
    return item.id !== '503363cb-c5e3-43c9-af04-f6258a17a543';
  }

  _onChangeTest = (deliveryPlanListPaneItem: DeliveryPlanListPaneItem[]) => {
    this.setState({
      deliveryPlanListPaneItem,
    });
  };
  _onChangeProduct = (deliveryPlanProductListPaneItem: any) => {
    this.setState({
      deliveryPlanProductListPaneItem,
    });
  };

  isDisabledUnitPrice = (): boolean => {
    if (this.props.formData?.status?.key === IN_REVIEW_ACCEPT_STATUS_ID) {
      return true;
    } else if (this.props.formData?.status?.key === APPROVED_STATUS_ID) {
      return true;
    } else if (this.props.formData?.status?.key === HANDED_OVER_STATUS_ID) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    return (
      <div>
        {this.props.formData?.type?.key === RESOURCE_TYPE_ID && <ListPaneComponent
          isOpen={this.props.isOpen}
          openModal={this.props.openModal}
          resetSelection={this.resetSelection}
          onConfirmSelection={this.onConfirmSelection}
          _onChangeTest={(deliveryPlanListPaneItem: DeliveryPlanListPaneItem[]) => (this._onChangeTest(deliveryPlanListPaneItem))}
          isDataLoaded={this.props.isDataLoaded}
          selection={this._selection}
          columns={this.props.formData?.isCu ? this._columnsCu : this._columns}
          listPaneItem={this.state.deliveryPlanListPaneItem}
          loadMsg={this.props.loadMsg}
          IsDisabled={this.props.formData?.status?.key === HANDED_OVER_STATUS_ID}
          IsDisabledUnitPrice={this.isDisabledUnitPrice()}
          IsDisabledExpectedDelivery={false}
          isCu={this.props.formData.isCu}
          requestedDate={this.props.formData.deliveryDate}
        />}
        {this.props.formData?.type?.key === PRODUCT_TYPE_ID && <ProductListPaneComponent
          isOpen={this.props.isOpen}
          openModal={this.props.openModal}
          resetSelection={this.resetProductSelection}
          onConfirmSelection={this.onProductConfirmSelection}
          _onChangeProduct={(deliveryPlanProductListPaneItem: any) => (this._onChangeProduct(deliveryPlanProductListPaneItem))}
          isDataLoaded={true}
          selection={this._selection}
          columns={this.props.formData?.isCu ? this._productColumnsCu : this._productColumns}
          listPaneItem={this.state.deliveryPlanProductListPaneItem}
          loadMsg={this.props.loadMsgProduct}
          IsDisabledTotalPrice={this.isDisabledUnitPrice()}
          IsDisabled={this.props.formData?.status?.key === HANDED_OVER_STATUS_ID}
          formData={this.props.formData}
          isCu={this.props.formData.isCu}
        />}
      </div>
    );
  }


  private getListPaneData = () => {
    if (this.props.formData?.type?.key === RESOURCE_TYPE_ID) {
      if (this.props.formData && this.props.formData.id && this.props.formData.isCu) {
        this.props.getCuDeliveryPlanListPaneData(this.props.formData.id);
      } else if (this.props.formData && this.props.formData.id) {
        this.props.getDeliveryPlanListPaneData(this.props.formData.id);
      }
    } else {
      if (this.props.formData && this.props.formData.id) {
        this.setState({
          deliveryPlanProductListPaneItem: this.props.deliveryPlanProductListPaneItem,
        });
      }
    }


  };


  private handleSelectedItem = () => {

  };


  private resetSelection = () => {
    if (this.props.formData && this.props.formData.id && this.props.formData.isCu) {
      this.props.getCuDeliveryPlanListPaneData(this.props.formData?.id).then(() => {
        this.setState({
          deliveryPlanListPaneItem: this.props.deliveryPlanListPaneItem,
        });
      });
    } else if (this.props.formData && this.props.formData.id) {
      this.props.getDeliveryPlanListPaneData(this.props.formData?.id).then(() => {
        this.setState({
          deliveryPlanListPaneItem: this.props.deliveryPlanListPaneItem,
        });
      });
    }

  };

  private resetProductSelection = () => {
    this.setState({
      deliveryPlanProductListPaneItem: [],
    }, () => {
      this.setState({ deliveryPlanProductListPaneItem: this.props.deliveryPlanProductListPaneItem });
    });
  };

  private onConfirmSelection = async () => {
    let borResources: any = [];
    let cuBorResources: any = [];
    let poBorResourcesUpdate: any = [];
    let ProjectSequenceId = this.props.formData?.projectSequenceCode;
    let SequenceId = this.props.formData?.sequenceId;
    let hasResourceFromPO = false;
    this.state.deliveryPlanListPaneItem.map((resourceItem: any, rindex: number) => {
      console.log('resourceItem', resourceItem);
      if (resourceItem.resources) {
        resourceItem.resources.map((resourceItem: any, rindex: number) => {
          if (resourceItem.isPo) {
            hasResourceFromPO = true;
          }
          if (resourceItem.requestedDeliveryDate) {
            resourceItem.requestedDeliveryDate = formatDate(resourceItem.requestedDeliveryDate, 2);
          }
          if (resourceItem.expectedDeliveryDate) {
            resourceItem.expectedDeliveryDate = formatDate(resourceItem.expectedDeliveryDate, 2);
          }
          borResources.push({
            poResourceId: resourceItem.id,
            isStock: resourceItem.isStock,
            requestedDeliveryDate: resourceItem.requestedDeliveryDate,
            expectedDeliveryDate: resourceItem.expectedDeliveryDate,
            cUnitPrice: resourceItem.cUnitPrice,
            cTotalPrice: resourceItem.cTotalPrice,
          });
          cuBorResources.push({
            poResourceId: resourceItem.id,
            requestedDeliveryDate: resourceItem.requestedDeliveryDate,
            expectedDeliveryDate: resourceItem.expectedDeliveryDate,
            cUnitPrice: resourceItem.cUnitPrice,
            cTotalPrice: resourceItem.cTotalPrice,
          });
          poBorResourcesUpdate.push({
            poResourceId: resourceItem.id,
            isStock: resourceItem.isStock,
            requestedDeliveryDate: resourceItem.requestedDeliveryDate,
            expectedDeliveryDate: resourceItem.expectedDeliveryDate,
            cUnitPrice: resourceItem.cUnitPrice,
            cTotalPrice: resourceItem.cTotalPrice,
            borId: resourceItem.borId,
            cpcId: resourceItem.corporateProductCatalogId,
            ccpcId: resourceItem.ccpcId,
            resourceType: resourceItem.resourcesType,
          });
        });
      }
    });

    if (this.props.formData.isCu) {
      this.props.cuDeliveryPlanStockUpdate({
        projectSequenceId: ProjectSequenceId,
        sequenceId: SequenceId,
        poResourceStockDtoList: cuBorResources,
      }).then(() => {
        let sequanceId = this.props.formData?.sequenceId;
        if (hasResourceFromPO) {
          this.props.createAutoGeneratedPO();
        }
        this.props.poCUBorUpdate({
          projectSequenceId: ProjectSequenceId,
          sequenceId: SequenceId,
          poResourceStockDtoList: poBorResourcesUpdate,
        });
        this.props.readByPurchaseOrderId(sequanceId);
        this.props.openModal();
      });
    } else {
      this.props.deliveryPlanStockUpdate({
        projectSequenceId: ProjectSequenceId,
        sequenceId: SequenceId,
        poResourceStockDtoList: borResources,
      }).then(() => {
        let sequanceId = this.props.formData?.sequenceId;
        if (hasResourceFromPO) {
          this.props.createAutoGeneratedPO();
        }
        if (this.props.formData?.status?.key === APPROVED_STATUS_ID) {
          this.props.poBorUpdate({
            projectSequenceId: ProjectSequenceId,
            sequenceId: SequenceId,
            poResourceStockDtoList: poBorResourcesUpdate,
          });
        }
        this.props.readByPurchaseOrderId(sequanceId);
        this.props.openModal();
      });
    }


  };

  private onProductConfirmSelection = async () => {
    let products: any = [];
    let productsCu: any = [];
    let ProjectSequenceId = this.props.formData?.projectSequenceCode;
    let SequenceId = this.props.formData?.sequenceId;
    let hasProductFromPO = false;
    this.state.deliveryPlanProductListPaneItem.map((item: any, rindex: number) => {
      if (item.isPo) {
        hasProductFromPO = true;
      }
      if (item.requestedDeliveryDate) {
        item.requestedDeliveryDate = formatDate(item.requestedDeliveryDate, 2);
      }
      if (item.expectedDeliveryDate) {
        item.expectedDeliveryDate = formatDate(item.expectedDeliveryDate, 2);
      }

      products.push({
        poProductId: item.id,
        isPo: item.isPo,
        cTotalPrice: item.cTotalPrice,
        requestedDeliveryDate: item.requestedDeliveryDate,
        expectedDeliveryDate: item.expectedDeliveryDate,
      });
      productsCu.push({
        poProductId: item.id,
        cTotalPrice: item.cTotalPrice,
        requestedDeliveryDate: item.requestedDeliveryDate,
        expectedDeliveryDate: item.expectedDeliveryDate,
      });

    });

    // console.log({
    //      projectSequenceId:ProjectSequenceId,
    //      sequenceId:SequenceId,
    //      poProductIsPoDtoList:products
    //    })

    if (this.props.formData.isCu) {

      this.props.deliveryPlanProductCuStockUpdate({
        projectSequenceId: ProjectSequenceId,
        sequenceId: SequenceId,
        poProductIsPoDtoList: productsCu,
      }).then(() => {
        let sequanceId = this.props.formData?.sequenceId;
        if (hasProductFromPO) {
          this.props.createAutoGeneratedPO();
        }
        this.props.readByPurchaseOrderId(SequenceId);
        this.props.openModal();
      });
    } else {
      this.props.deliveryPlanProductStockUpdate({
        projectSequenceId: ProjectSequenceId,
        sequenceId: SequenceId,
        poProductIsPoDtoList: products,
      }).then(() => {
        let sequanceId = this.props.formData?.sequenceId;
        if (hasProductFromPO) {
          this.props.createAutoGeneratedPO();
        }
        this.props.readByPurchaseOrderId(SequenceId);
        this.props.openModal();
      });
    }


  };


  private _getSelectedIds() {
    let deleteList: any = [];
    let SelectedItem = this._selection.getSelection() as DeliveryPlanListPaneItem[];
    if (SelectedItem) {
      deleteList = SelectedItem.map((item: any) => {
        return item ? item.id : null;
      });
    }
    return deleteList;
  }

  private _getselectedSequenceCode() {
    let deleteList: any = [];
    let SelectedItem = this._selection.getSelection() as DeliveryPlanListPaneItem[];
    if (SelectedItem) {
      deleteList = SelectedItem.map((item: any) => {
        return item ? item.itemId : null;
      });
    }
    return deleteList;
  }


  private _getSelectedListItems = () => {
    let SelectedListItems = this._selection.getSelection() as DeliveryPlanListPaneItem[];

    return SelectedListItems;
  };


}


const mapStateToProps = (state: any, props: any) => {
  return {
    deliveryPlanListPaneItem: state.po.deliveryPlanListPaneItem,
    loadMsg: state.po.loadMsgDeliveryPlan,
    isDataLoaded: state.po.isDataLoadedDeliveryPlan,
    deliveryPlanProductListPaneItem: state.po.deliveryPlanProductListPaneItem,
    loadMsgProduct: state.po.loadMsgProductDeliveryPlan,
    isDataLoadedProduct: state.po.isLoadingPO,
  };
};

const mapDispatchToProps = {
  getDeliveryPlanListPaneData,
  getCuDeliveryPlanListPaneData,
  deliveryPlanStockUpdate,
  cuDeliveryPlanStockUpdate,
  poBorUpdate,
  poCUBorUpdate,
  deliveryPlanProductStockUpdate,
  deliveryPlanProductCuStockUpdate,
  readByPurchaseOrderId,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DeliveryPlanModal)),
);

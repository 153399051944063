import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Divider, Label } from '@fluentui/react-components';
import i18n from 'i18next';
import TaskList from './component/TaskList';

interface Props {
  tasks: any;
  onChange: any;
  onClickAddTask?: any;
  isDisabled?: boolean;
  isTaskDeleteEnabled?: boolean;
  onClickTitle?: (item: any) => void;
}

/**
 * Task Component
 *
 * This component need to developed.
 *
 * @returns {JSX.Element} - The rendered Task component.
 */
const TaskComponent = (props: Props): JSX.Element => {
  const { tasks, onChange, isDisabled, onClickAddTask } = props;

  const [task, setTask] = useState([]);

  useEffect(() => {
    setTaskList();
  }, [tasks]);

  const setTaskList = () => {
    setTask(tasks);
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
          <Label style={{ marginTop: 20 }} size='large'>{i18n.t('task')}</Label>
          <Button
            size='small'
            disabled={isDisabled}
            onClick={onClickAddTask}
          >
            {i18n.t('addTask')}
          </Button>
        </div>
        <div style={{ marginTop: 5, marginBottom: 5 }}><Divider /></div>
        <TaskList
          tasks={task}
          onChange={onChange}
          onClickTitle={(item: any) => props?.onClickTitle && props?.onClickTitle(item)}
        />
      </div>
    </>
  );

};

export default TaskComponent;

import * as React from 'react';
import { useState } from 'react';

import {
  ActionButton,
  IBasePicker,
  IBasePickerSuggestionsProps,
  IIconProps,
  IInputProps,
  ITag,
  Label,
  mergeStyles,
  mergeStyleSets,
  TagPicker,
  Text,
} from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import i18n from '../../../i18n';
import { uPrinceTheme } from '../../../theme';
import { assign } from 'lodash-es';


const rootClass = mergeStyles({
  maxWidth: 500,
});

const classNames = mergeStyleSets({
  wrapper: {
    //height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: {
    maxWidth: 400,
  },
  textContent: {
    padding: '15px 10px',
  },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: {
    width: '100%',
  },
});
const errorMessage = mergeStyles({
  fontSize: 12,
  fontWeight: 400,
  color: 'rgb(164, 38, 44)',
  animationDuration: '0.367s',
  animationFillMode: 'both',
});


const inputProps: IInputProps = {
  onBlur: (ev: React.FocusEvent<HTMLInputElement>) => console.log('onBlur called'),
  onFocus: (ev: React.FocusEvent<HTMLInputElement>) => console.log('onFocus called'),
  'aria-label': 'Tag picker',
};

const addIcon: IIconProps = { iconName: 'Add' };


const testTags: ITag[] = [
  'black',
  'black1',
  'black2',
  'black3',
  'black4',
  'black44',
  'black444',
  'black55',
  'blue5555',
  'brown567',
  'cyan',
  'green',
  'magenta',
  'mauve',
  'orange',
  'pink',
  'purple',
  'red',
  'rose',
  'violet',
  'white',
  'yellow',
].map(item => ({ key: item, name: item }));


const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
  if (!tagList || !tagList.length || tagList.length === 0) {
    return false;
  }
  return tagList.some(compareTag => compareTag.key === tag.key);
};
//(filter: string, selectedItems?: ITag[] | undefined) => ITag[] | PromiseLike<ITag[]>
const filterSuggestedTags = (filter: string, selectedItems?: ITag[] | undefined): ITag[] => {
  return filter
    ? testTags.filter(
      tag => tag.name.toLowerCase().indexOf(filter.toLowerCase()) === 0 && !listContainsTagList(tag, selectedItems),
    )
    : [];
};

const filterSelectedTags = (filterText: string, tagList: ITag[]): ITag[] => {
  return filterText ? testTags.filter(tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0) : [];
};


const getTextFromItem = (item: ITag) => item.name;


const new_items = [
  {
    key: 'create_new',
    iconProps: { iconName: 'Add' },
    text: 'create new',
  },
];

const CustomTagPicker = (props: {
  label?: string | null;
  required?: boolean;
  validationMessage: string | null;
  onFilterTagChanged: any;
  onItemSelected: (item: any) => any;
}) => {
  // All pickers extend from BasePicker specifying the item type.
  const picker = React.useRef<IBasePicker<ITag>>(null);
  const [tagPicker, { toggle: toggleIsTagPickerVisible }] = useBoolean(false);
  const [inputValue, setInputValue]: any = useState('');
  const onItemSelected = React.useCallback((item: ITag | undefined): ITag | null => {
    if (item) {
      if (picker.current && listContainsTagList(item, picker.current.items)) {
        return null;
      }
      props.onItemSelected(item);
      return item;
    } else {
      return null;
    }

  }, []);

  const onInputChange = (input: string): any => {
    setInputValue(input);
    return input;
  };


  const tagSuggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: 'Suggested',
    mostRecentlyUsedHeaderText: 'Suggested',
    noResultsFoundText: i18n.t('noresultsfound'),
    loadingText: i18n.t('loading'),
    showRemoveButtons: false,
  };

  const tagSearchAdditionalProps: IBasePickerSuggestionsProps = {
    suggestionsContainerAriaLabel: 'Suggested',
    // searchForMoreText: i18n.t('loadallResults'),
    // resultsMaximumNumber: 10,
    // searchingText: i18n.t('searching') + '...',
    // resultsFooter: (props: any) => (
    //   <ActionButton
    //     className={classNames.actionButton}
    //     disabled={false}
    //     iconProps={addIcon}
    //     allowDisabledFocus
    //     onClick={() => {
    //       messageService.sendMessage({ addNewNodeName: inputValue });
    //     }}
    //   >
    //     <Label className={classNames.actionButton}>{i18n.t('new')}</Label>
    //   </ActionButton>
    // ),
  };

  const newItem = mergeStyles({ padding: '1px' });
  const existingItem = mergeStyles({ padding: '10px' });

  const tagSearchSuggestionProps: IBasePickerSuggestionsProps = assign(
    tagSearchAdditionalProps,
    tagSuggestionProps,
  );

  const onRenderSuggestionsItem = (props: any, itemProps: any): JSX.Element => {
    if (props.isNewItem) {
      return <div className={props.isNewItem ? newItem : existingItem} key={props.key}>
        <ActionButton iconProps={addIcon}>
          {props.name}
        </ActionButton>
      </div>;
    } else {
      return <div className={props.isNewItem ? newItem : existingItem} key={props.key}>
        {props.name}
      </div>;
    }
  };

  return (
    <div className={rootClass}>
      <Label required={props.required}>{props.label}</Label>
      <TagPicker
        removeButtonAriaLabel="Remove"
        onResolveSuggestions={props.onFilterTagChanged}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={tagSearchSuggestionProps}
        itemLimit={1}
        disabled={tagPicker}
        inputProps={inputProps}
        onItemSelected={onItemSelected}
        onInputChange={onInputChange}
        onRenderSuggestionsItem={onRenderSuggestionsItem}
      />

      {props.validationMessage && (
        <Text className={errorMessage}>{props.validationMessage}</Text>
      )}
    </div>
  );

};

export default CustomTagPicker;

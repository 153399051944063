import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {WithTranslation, withTranslation} from 'react-i18next';
import {messageService} from '../../../services/messageService';
import {
  deleteProductCatalogue,
  deleteProductCatalogueImage,
  deleteProductCatalogueNickName,
  deleteProductCatalogueVendor,
  getAllProductCatalogue,
  getCpcResourceFamily,
  getCpcShortcutPaneFilterList,
  getDropdownData,
  getExcelData,
  getOrganizationByName,
  getProductCatalogueById,
  newProductCatalogue,
  savePicturesOfTheInstallation,
  saveProductCatalogue,
} from '../../../reducers/productCatalogueReducer';
import {
  CorporateProductCatalogue,
  CorporateProductCatalogueListItem,
  ProductCatalogueFilter,
} from '../../../types/corporateProductCatalogue';
import {mergeStyles, Stack} from '@fluentui/react';
import {CpcDocumentPane} from '../../productCatalogue/documentPane/container';
import {getProject} from '../../../shared/util';


const listStyle = mergeStyles({
  flex: '1 1 auto',
  width: '100%',
  height: 'auto',
  overflowX: 'hidden',
  overflowY: 'auto',
  paddingBottom: '30px',
  marginLeft: '0',
});


export interface State {
  cpcListPaneTitle: string | null;
  showProgressBar: boolean;
  isClone: boolean;
  submitCpc: boolean;
  redirect: boolean;
  loading: boolean;
  showPanel: boolean;
  width: number;
  activeClass: string;
  bookmarkDivClass: string;
  headerFilter: boolean;
  showDetails: boolean;
  currentActiveSection: string;
  isOpenNickNameModel: boolean;
  allProductCatalogue: [];
  uploadedImages: string | null;
  productCatalogue: CorporateProductCatalogue;
  autoSave: boolean;
  isEdit: boolean;
  selectedCpcId: string | null | undefined;
  cpcFilter: ProductCatalogueFilter;
  cpcListItem: CorporateProductCatalogueListItem[];
  cpcPressureClass: [],
  selectedCpcItemIds: [] | string[];
  //headerTitle:string|null,
  hiddenRemoveDialog: boolean,
}

interface Props extends WithTranslation {
  cpcId: string | null
  getExcelData: () => void;
  newProductCatalogue: () => void;
  getOrganizationByName: (name: string) => void;
  getCpcResourceFamily: () => void;
  deleteProductCatalogueNickName: (idList: [] | string[]) => void;
  deleteProductCatalogueVendor: (idList: [] | string[]) => void;
  deleteProductCatalogueImage: (idList: [] | string[]) => void;
  deleteProductCatalogue: (idList: [] | string[]) => void;
  getAllProductCatalogue: (data: ProductCatalogueFilter) => void;
  getCpcShortcutPaneFilterList: () => void;
  saveProductCatalogue: (productCatalogue: CorporateProductCatalogue) => void;
  getProductCatalogueById: (id: string) => void;
  productCatalogue: CorporateProductCatalogue;
  getDropdownData: () => void;
  savePicturesOfTheInstallation: (files: FileList | null) => void;
  cpcListItem: CorporateProductCatalogueListItem[];
  uploadedImages: string | null;
  resourceTypes: [];
  resourceTypeFilter: [];
  resourceFamily: [];
  cpcBasicUnitOfMeasure: [];
  cpcPressureClass: [],
  cpcMaterial: [],
  cpcBrand: [],
  languageCodes: [];
  shortCutPaneFilters: [];
  organizations: [];
  cpcUnitOfSizeMeasure: [];
  excelData: [];
  autoSave: boolean;
  isChange: boolean;
  isDataLoaded?: boolean;
  loadMsg: string;
  cpcFilter: ProductCatalogueFilter;
  location: any;
  isCreateNewCpc: boolean;
  showProgressBar: boolean;
}

class CPCDocumentPane extends React.Component<Props, State> {
  subscription: any;
  private bookmarkList = [
    {
      id: 1,
      link: 'corporate-product-header',
      label: this.props.t('corporateProductHeader'),
    },
    { id: 2, link: 'vendor', label: this.props.t('vendor') },
    {
      id: 3,
      link: 'history',
      label: this.props.t('history'),
    },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      cpcListPaneTitle: this.props.t('all'),
      showProgressBar: false,
      hiddenRemoveDialog: true,
      isClone: false,
      submitCpc: false,
      selectedCpcItemIds: [],
      cpcListItem: [],
      cpcFilter: {
        title: null,
        resourceTitle: null,
        resourceTypeId: null,
        resourceFamilyId: null,
        resourceNumber: null,
        status: null,
        sorter: {
          attribute: null,
          order: null,
        },
      },
      selectedCpcId: null,
      isOpenNickNameModel: false,
      isEdit: false,
      autoSave: false,
      redirect: false,
      loading: false,
      width: window.innerWidth,
      showPanel: false,
      headerFilter: false,
      activeClass: 'grid-righter column-filter-activated',
      bookmarkDivClass: 'bookmark-panel parent',
      currentActiveSection: '1',
      showDetails: false,
      allProductCatalogue: [],
      uploadedImages: null,
      productCatalogue: {
        id: null,
        title: null,
        headerTitle: null,
        resourceNumber: null,
        resourceTitle: null,
        resourceTypeId: null,
        resourceFamilyId: null,
        cpcBasicUnitOfMeasureId: null,
        inventoryPrice: null,
        minOrderQuantity: null,
        maxOrderQuantity: null,
        size: null,
        cpcUnitOfSizeMeasureId: null,
        wallThickness: null,
        cpcPressureClassId: null,
        cpcMaterialId: null,
        weight: null,
        status: 0,
        cpcImage: [],
        cpcResourceNickname: [],
        cpcVendor: [],
        cpcHistoryLog: null,
        cpcBrandId: null,
        resourceId: null,
        trackingNo: null,
      },
      cpcPressureClass: [],
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.cpcId !== prevProps.cpcId) {
      if (this.props.cpcId)
        this.getProductCatalogueById(this.props.cpcId);
    }
    if (this.props.productCatalogue !== prevProps.productCatalogue) {
      this.setState({
        productCatalogue: this.props.productCatalogue,
      });
    }
  }


  componentDidMount() {
    this.props.getDropdownData();
    this.props.getCpcResourceFamily();
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.saveDPLANData) {
          messageService.sendMessage({ savePmolFormData: true });
        }
      }
    });
    this.setState({
      productCatalogue: this.props.productCatalogue,
    });
    if (this.props.cpcId) {
      this.getProductCatalogueById(this.props.cpcId);
    }
  }


  componentWillUnmount() {
    this.subscription.unsubscribe();
  }


  savePicturesOfTheInstallation = (files: FileList | null) => {
    if (files) {
      this.props.savePicturesOfTheInstallation(files);
    }
  };

  getProductCatalogueById = (id: string) => {
    this.props.getProductCatalogueById(id);
  };


  handelGetOrganizationByName = (name: string) => {
    this.props.getOrganizationByName(name);
  };

  private handleScroll = (e: any): void => {
    let element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
    }

    let h2Headings = document.getElementsByClassName('proj-detail-block');
    let item = h2Headings[0];
    let tt = 0;

    for (let i = 0; i < h2Headings.length; i++) {
      if (h2Headings[i].getBoundingClientRect().top <= 300) {
        if (tt <= h2Headings[i].getBoundingClientRect().top) {
          tt = h2Headings[i].getBoundingClientRect().top;
          item = h2Headings[i];

          var c = item.id;
          //console.log(c);
          this.setState({ currentActiveSection: c });
        }
      }
    }
  };


  render() {
    const { t } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <Stack
          className={listStyle}
          styles={{
            root: {
              width: '100%',
              height: 'auto',
            },
          }}
          verticalFill
          gap={50}
          horizontalAlign="start"
          verticalAlign="start"
          onScroll={this.handleScroll}
        >
          <CpcDocumentPane
            submitCpc={this.state.submitCpc}
            isEdit={true}
            selectedCpcId={this.state.selectedCpcId}
            productCatalogue={this.state.productCatalogue}
            savePicturesOfTheInstallation={(files: FileList | null) =>
              this.savePicturesOfTheInstallation(files)
            }
            saveProductCatalogue={(
              productCatalogue: CorporateProductCatalogue,
            ) => {
              //alert("save final");
              this.setState({
                submitCpc: false,
              });
              this.props.saveProductCatalogue(productCatalogue);
            }}
            deleteProductCatalogueNickName={(
              deleteList: [] | string[],
            ) => {
              this.props.deleteProductCatalogueNickName(deleteList);
            }}
            deleteProductCatalogueVendor={(
              deleteList: [] | string[],
            ) => {
              this.props.deleteProductCatalogueVendor(deleteList);
            }}
            deleteProductCatalogueImage={(
              deleteList: [] | string[],
            ) => {
              this.props.deleteProductCatalogueImage(deleteList);
            }}
            getProductCatalogueById={(id: string) => {
              this.getProductCatalogueById(id);
            }}
            autoSave={this.props.autoSave}
            uploadedImages={this.props.uploadedImages}
            resourceTypes={this.props.resourceTypes}
            resourceFamily={this.props.resourceFamily}
            languageCodes={this.props.languageCodes}
            cpcBasicUnitOfMeasure={this.props.cpcBasicUnitOfMeasure}
            cpcPressureClass={this.props.cpcPressureClass}
            cpcMaterial={this.props.cpcMaterial}
            cpcBrand={this.props.cpcBrand}
            organizations={this.props.organizations}
            cpcUnitOfSizeMeasure={this.props.cpcUnitOfSizeMeasure}
            handelGetOrganizationByName={(name: string) => this.handelGetOrganizationByName(name)}
            changeSubmitCpc={(changeSubmitCpc: boolean) => {
              //alert("save final");
              this.setState({
                submitCpc: changeSubmitCpc,
              });
            }}
            disabled={!getProject()}
          />
        </Stack>
        {/*<BookMarkPane*/}
        {/*isEdit={true}*/}
        {/*divClass={this.state.bookmarkDivClass}*/}
        {/*currentActiveSection={this.state.currentActiveSection}*/}
        {/*list={this.bookmarkList}*/}
        {/*toggleBookmark={this._toggleBookmark}*/}
        {/*toggleBookmarkOut={this._toggleBookmarkOut}*/}
        {/*/>*/}
      </div>
    );
  }

  private _toggleBookmark = (): void => {
    this.setState({ bookmarkDivClass: 'bookmark-panel parent panel-on' });
  };

  private _toggleBookmarkOut = (): void => {
    this.setState({ bookmarkDivClass: 'bookmark-panel parent' });
  };

}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    cpcListItem: state.productCatalogue.cpcListItem,
    uploadedImages: state.productCatalogue.uploadedImages,
    resourceTypes: state.productCatalogue.resourceTypes,
    resourceTypeFilter: state.productCatalogue.resourceTypeFilter,
    resourceFamily: state.productCatalogue.resourceFamily,
    cpcBasicUnitOfMeasure: state.productCatalogue.cpcBasicUnitOfMeasure,
    cpcPressureClass: state.productCatalogue.cpcPressureClass,
    cpcMaterial: state.productCatalogue.cpcMaterial,
    cpcBrand: state.productCatalogue.cpcBrand,
    languageCodes: state.productCatalogue.languageCodes,
    productCatalogue: state.productCatalogue.productCatalogue,
    autoSave: state.productCatalogue.autoSave,
    isDataLoaded: state.productCatalogue.isDataLoaded,
    isChange: state.productCatalogue.isChange,
    shortCutPaneFilters: state.productCatalogue.shortCutPaneFilters,
    loadMsg: state.productCatalogue.loadMsg,
    organizations: state.productCatalogue.organizations,
    cpcUnitOfSizeMeasure: state.productCatalogue.cpcUnitOfSizeMeasure,
    excelData: state.productCatalogue.excelData,
    isCreateNewCpc: state.productCatalogue.isCreateNewCpc,
    showProgressBar: state.productCatalogue.showProgressBar,
  };
};

const mapDispatchToProps = {
  getAllProductCatalogue,
  savePicturesOfTheInstallation,
  getDropdownData,
  saveProductCatalogue,
  getProductCatalogueById,
  newProductCatalogue,
  deleteProductCatalogueNickName,
  deleteProductCatalogueVendor,
  deleteProductCatalogueImage,
  getCpcShortcutPaneFilterList,
  getOrganizationByName,
  getCpcResourceFamily,
  getExcelData,
  deleteProductCatalogue,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CPCDocumentPane)),
);

import React, { Component } from 'react';
import ProjectMainLayout from './projectMainLayout';
import { getContractingUnit } from '../../shared/util';
import UprinceNavigationProject from '../../shared/uprinceNavigation/cuProjectNavigation/projectNavCU';
import UprinceNavigation from '../../shared/uprinceNavigation/cuProjectNavigation/component';


interface Props {
  currentUser:any
}

interface State {
  isContractUnit:boolean;
  isProject:boolean;
}
export class Project extends Component<Props, State> {
    render() {
        return (
            <UprinceNavigation
                currentUser={ this.props.currentUser }
                selectedNavigationIndex={ `/CU/${getContractingUnit()}` }
            >
                <ProjectMainLayout />
            </UprinceNavigation>
        );
    }
}

export default (Project);

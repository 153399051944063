import * as React from 'react';
import { useEffect, useState } from 'react';
import Tree from '../../../shared/tree/Tree';
import { AddCircle24Regular } from '@fluentui/react-icons';
import { registerIcons } from '@fluentui/react';
import { SvgIcons } from '../../../shared/svgIcons/svgIcons';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { renderIconBefore, WBS_ADD_ICON_MENU, WBS_TAXONOMY_LEVEL } from '../../../types/wbs';
import { getWbsTaxonomy, saveWbsTaskStateAttr } from '../../../reducers/wbsTaskReducer';
import { messageService } from '../../../services/messageService';
import { useTranslation } from 'react-i18next';
import { getWbsProductById, saveWbsCreateStateAttr } from '../../../reducers/wbsCreateReducer';
import { saveWbsStateAttr } from '../../../reducers/wbsReducer';
import { Menu, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components';
import { getPerentFromTreeData } from '../../../shared/util';
import { getWbsIssueById } from '../../../reducers/wbsIssueReducer';
import { store } from '../../../../index';

registerIcons(SvgIcons);

const WbsListPaneComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  // const { state }: any = location;
  // const { featureId }: ParamTypes = useParams();
  const { t } = useTranslation();

  const defaultOpenItems = useSelector((state: any) => state.wbs.defaultOpenItems);
  const wbsTaxonomy = useSelector((state: any) => state.wbsTask.wbsTaxonomy);
  const wbsListPaneMsg = useSelector((state: any) => state.wbsTask.wbsListPaneMsg);
  const reloadWbsProjectListPane = useSelector((state: any) => state.wbsCreate.wbsListPaneMsg);
  const wbsListData = useSelector((state: any) => state.wbsCreate.wbsListData);
  const selectedProject = useSelector((state: any) => state.project.selectedProject);

  // const [flatTreeItems, setFlatTreeItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getWbsTaxonomy());
    const subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.reloadWbsListPane) {
          reloadListPane();
        }
        if (data.data.isProjectWbsExpand) {
          handleWebsTaxonomyExpanded(data.data.isProjectWbsExpand?.status);
        }
      }
    });

    return () => {
      dispatch(saveWbsStateAttr('projectWbsTemplateId', null));
      dispatch(saveWbsStateAttr('defaultOpenItems', []));
      dispatch(saveWbsStateAttr('wbsTaxonomy', []));
      console.log('cleaned up WbsListPaneComponent');
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (reloadWbsProjectListPane) {
      reloadListPane();
      dispatch(saveWbsCreateStateAttr('wbsListPaneMsg', false));
    }
  }, [reloadWbsProjectListPane]);

  const reloadListPane = () => {
    dispatch(getWbsTaxonomy());
  };

  const onClickTreeItem = (item: any) => {
    let selectedParentIds = getPerentFromTreeData(wbsTaxonomy, item?.id);
    selectedParentIds.push(item?.id);
    dispatch(saveWbsStateAttr('defaultOpenItems', selectedParentIds));
  };

  const onClickTitle = (item: any) => {
    // console.log('onClickTitle', item);
    // setDefaultOpenItems(item);
    const wbsTaxonomyLevelId = item.wbsTaxonomyLevelId;
    const id = item.id;

    switch (wbsTaxonomyLevelId) {
      case WBS_TAXONOMY_LEVEL.WBS:
        messageService.sendMessage({ showWbsDocumentPane: true, wbsTaxonomyLevel: WBS_TAXONOMY_LEVEL.WBS });
        dispatch(saveWbsStateAttr('projectWbsTemplateId', item?.templateId));
        break;
      case WBS_TAXONOMY_LEVEL.PRODUCT:
        messageService.sendMessage({ showWbsDocumentPane: true, wbsTaxonomyLevel: WBS_TAXONOMY_LEVEL.PRODUCT });
        dispatch(getWbsProductById(id));
        break;
      case WBS_TAXONOMY_LEVEL.TASK:
        messageService.sendMessage({ showWbsDocumentPane: true, wbsTaxonomyLevel: WBS_TAXONOMY_LEVEL.TASK });
        dispatch(saveWbsStateAttr('wbsTaskId', id));
        break;
      case WBS_TAXONOMY_LEVEL.ISSUE:
        messageService.sendMessage({ showWbsDocumentPane: true, wbsTaxonomyLevel: WBS_TAXONOMY_LEVEL.ISSUE });
        dispatch(getWbsIssueById(id));
        break;
    }
  };

  const setDefaultOpenItems = (item: any) => {
    let selectedParentIds = getPerentFromTreeData(wbsTaxonomy, item?.id);
    selectedParentIds.push(item?.id);
    dispatch(saveWbsStateAttr('defaultOpenItems', selectedParentIds));
  };

  const onClickMenuItem = (item: any, type: any) => {
    // setDefaultOpenItems(item);
    switch (type) {
      case WBS_ADD_ICON_MENU.WBS:
        messageService.sendMessage({ showCreateWbsDocumentPane: true, isAddNew: { ...item, createNew: type } });
        break;
      case WBS_ADD_ICON_MENU.PRODUCT:
        dispatch(saveWbsCreateStateAttr('wbsProductFormData', {}));
        messageService.sendMessage({ showCreateWbsDocumentPane: true, isAddNew: { ...item, createNew: type } });
        break;
      case WBS_ADD_ICON_MENU.TASK:
        dispatch(saveWbsTaskStateAttr('formData', {}));
        messageService.sendMessage({ showCreateWbsDocumentPane: true, isAddNew: { ...item, createNew: type } });
        break;
      case WBS_ADD_ICON_MENU.ISSUE:
        messageService.sendMessage({ showCreateWbsDocumentPane: true, isAddNew: { ...item, createNew: type } });
        break;

      default:
        break;
    }
  };

  const handleWebsTaxonomyExpanded = (isWbsExpand: boolean) => {
    setIsLoading(true);
    if (isWbsExpand) {
      let Ids = store?.getState()?.wbsTask?.wbsTaxonomy?.map((item: any) => {
        return item?.id;
      });

      Promise.all([dispatch(saveWbsStateAttr('defaultOpenItems', Ids))]).then(() => {
        setIsLoading(false);
      });
    } else {
      Promise.all([dispatch(saveWbsStateAttr('defaultOpenItems', []))]).then(() => {
        setIsLoading(false);
      });
    }
  };

  const onOpenChange = (event: any, data: any) => {
    console.log(data);
    if (!data?.open) {
      dispatch(saveWbsStateAttr('defaultOpenItems', null));
    } else {
      dispatch(saveWbsStateAttr('defaultOpenItems', data?.openItems));
    }
  };

  const RenderWbsListAddIcon = ({ item }: any) => (
    <>
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <AddCircle24Regular />
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            <MenuItem onClick={() => {
              onClickMenuItem(item, WBS_ADD_ICON_MENU.WBS);
            }}>{t('wbs')} </MenuItem>
            <MenuItem onClick={() => {
              onClickMenuItem(item, WBS_ADD_ICON_MENU.PRODUCT);
            }}>{t('product')}</MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
    </>
  );

  const RenderProductListAddIcon = ({ item }: any) => (
    <>
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <AddCircle24Regular />
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            <MenuItem onClick={() => {
              onClickMenuItem(item, WBS_ADD_ICON_MENU.TASK);
            }}>{t('task')} </MenuItem>
            <MenuItem onClick={() => {
              onClickMenuItem(item, WBS_ADD_ICON_MENU.ISSUE);
            }}>{t('issue')}</MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
    </>
  );

  const renderAsideContent = (props: any) => {
    return <div onClick={(e) => {
      e.stopPropagation();
    }}>
      {props?.wbsTaxonomyLevelId === WBS_TAXONOMY_LEVEL.WBS && <RenderWbsListAddIcon item={props} />}
      {(props?.wbsTaxonomyLevelId === WBS_TAXONOMY_LEVEL.PRODUCT) && <RenderProductListAddIcon item={props} />}
      {/*<AddCircle24Regular onClick={()=>{onClickAddProductIcon(props)}} />*/}
    </div>;
  };

  return (
    <>
      <div style={{ padding: 10, height: 'calc(100vh - 210px)', overflow: 'auto' }}>
        {wbsListPaneMsg ?
          <span>{t(wbsListPaneMsg) + '...'}</span> :
          !isLoading && <Tree
            flatTreeItems={wbsTaxonomy}
            iconBefore={renderIconBefore}
            stopPropagation={true}
            onClickTreeItem={onClickTreeItem}
            onClickTitle={onClickTitle}
            actions={renderAsideContent}
            defaultOpenItems={defaultOpenItems}
            onOpenChange={onOpenChange}
          />
        }
        {/*<div>{JSON.stringify(flatTreeItems)}</div>*/}
      </div>
    </>
  );

};

export default WbsListPaneComponent;

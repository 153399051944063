import {
  ContextualMenu,
  Dropdown,
  FontWeights,
  getTheme,
  IconButton,
  IDragOptions,
  IIconProps,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  ProgressIndicator,
  TextField,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../../../theme';
import React, { useEffect, useState } from 'react';
import { useBoolean, useConstCallback, useId } from '@uifabric/react-hooks';
import { PickListItem } from '../../../../types/pickListItem';
import Contact from '../../../../types/contact';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const theme = getTheme();

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const saveIconWhite: IIconProps = {
  iconName: 'Save',
  styles: addWhiteIconButtonStyles,
};

const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const dropdownStyles = { dropdown: { width: 300, marginTop: 20 } };

const ContactModal = (props: {
  isOpen: boolean;
  isEdit: boolean;
  openContactModel: () => void;
  options: PickListItem[];
  allOptions: PickListItem[];
  onAddContact: (contact: Contact, saveAndNew: boolean) => void;
  title: string;
  valueLable: string;
  contact: { type: string; value: string };
  isChecking: boolean;
  isUniq: number;
  isQniqContact: any;
}) => {
  const { t } = useTranslation();
  const [isDraggable] = useBoolean(false);
  const titleId = useId('title');
  let valuec = props.contact.value;
  if (props.contact && props.contact.value) {
    valuec = props.contact.value;
  }
  const [contact, setContact] = useState({
    type: '',
    value: '',
  });

  const [isUniq, setIsUniq] = useState(true);
  const [saveClicked, setSaveClicked] = useState(false);
  const [buttonType, setButtonType] = useState(0);
  const [number, setNumber] = useState(0);
  const [hasChanged, setHasChanged] = useState(false);
  const [isProgress, setProgress] = useState(false);
  const [previousValue, setPreviousValue] = useState('');

  const [errorMsg, setError] = useState({
    typeError: '',
    valueError: '',
  });

  useEffect(() => {

    if (props.isUniq === 2 && contact.value && props.isOpen && saveClicked) {
      setSaveClicked(false);
      setHasChanged(false);
      setProgress(false);
      if (buttonType === 1) {
        props.onAddContact(contact, false);
        setContact({ type: '', value: '' });
      } else if (buttonType === 2) {
        props.onAddContact(contact, props.options.length === 1 ? false : true);
        setContact({ type: '', value: '' });
      }
    }
    if (props.isUniq !== 1 && props.contact.value && !hasChanged && props.isEdit) {
      setContact({ type: props.contact.type, value: props.contact.value });
      setPreviousValue(props.contact.value);
    } else if (props.contact.value && props.isEdit && !contact.value) {
      setContact({ type: props.contact.type, value: props.contact.value });
    }

    if (!props.isEdit && !saveClicked) {
      setContact({ type: '', value: '' });
      setError((prevState) => ({
        ...prevState,
        typeError: '',
        valueError: '',
      }));
    }

    if (props.isUniq === 0) {
      setIsUniq(true);
      setNumber(0);
      setHasChanged(false);
    } else if (props.isUniq === 1 && number !== 1 && saveClicked) {
      setIsUniq(false);
      setProgress(false);
      setError((prevState) => ({
        ...prevState,
        valueError: t('alreadyinuse'),
      }));
      setNumber(1);
    } else {
      setIsUniq(true);
      setNumber(1);
    }
  }, [props.contact, props.isUniq, props.isEdit]);

  const onChangeContactNumber = useConstCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string,
    ) => {
      setHasChanged(true);
      setContact((prevState) => ({
        ...prevState,
        value: newValue!,
      }));

      setError((prevState) => ({
        ...prevState,
        valueError: '',
      }));
    },
  );

  const hasActualChange = () => {
    if (contact.value === previousValue) {
      return true;
    }

    return false;
  };

  const validateContact = (contact: Contact) => {
    let isValid = true;
    //const { t } = useTranslation();
    let isNumber = props.valueLable === t('number');
    //isValid = checkValidContact(isNumber, contact.value, contact.type);
    if (_.isEmpty(contact.type) || _.isNil(contact.type)) {
      isValid = false;
      setError((prevState) => ({
        ...prevState,
        typeError: t('Contact Type Required'),
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        typeError: '',
      }));
    }

    if (_.isEmpty(contact.value) || _.isNil(contact.value)) {
      isValid = false;
      setError((prevState) => ({
        ...prevState,
        valueError: isNumber ? t('numberRequired') : t('accountRequired'),
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        valueError: '',
      }));
    }

    if (contact.type === t('email')) {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (contact.value === '' || contact.value === null) {
        isValid = false;
        setError((prevState) => ({
          ...prevState,
          valueError: t('pleaseentervalidemail'),
        }));
      } else if (reg.test(contact.value) === false) {
        setError((prevState) => ({
          ...prevState,
          valueError: t('pleaseentervalidemail'),
        }));
        isValid = false;
      } else {
        isValid = true;
      }
    }

    if (isNumber) {
      let reg = /^\d*$/;
      if (contact.value === '' || contact.value === null) {
        isValid = false;
        setError((prevState) => ({
          ...prevState,
          valueError: t('pleaseentervalidnumber'),
        }));
      } else if (reg.test(contact.value) === false) {
        setError((prevState) => ({
          ...prevState,
          valueError: t('pleaseentervalidnumber'),
        }));
        isValid = false;
      } else {
        isValid = true;
      }
    }

    return isValid;
  };
  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          props.openContactModel();
          setTimeout(() => {
            setError({ typeError: '', valueError: '' });
          }, 1000);
        }}
        isBlocking={true}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>{props.isEdit ? t('edit') + ' ' + props.title : t('add') + ' ' + props.title}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              props.openContactModel();
              setTimeout(() => {
                setHasChanged(false);
                setIsUniq(true);
                setContact({ type: '', value: '' });
                setError({ typeError: '', valueError: '' });
              }, 1000);
            }}
          />
        </div>
        {/* <div className={contentStyles.subHeader}>
          <ActionButton
            iconProps={props.isEdit ? saveIcon : addIcon}
            allowDisabledFocus
          >
            <Label className={contentStyles.actionButtonLabel}>

              {props.isEdit ? t('edit') : t('add')}
            </Label>
          </ActionButton>
        </div> */}
        {isProgress && (
          <ProgressIndicator styles={{ root: { marginTop: -10 } }}/>
        )}
        <div className={contentStyles.body}>
          <div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                <Dropdown
                  placeholder={t('contactType')}
                  ariaLabel={t('required')}
                  options={props.isEdit ? props.allOptions : props.options}
                  required={true}
                  disabled={props.isEdit}
                  selectedKey={contact.type}
                  errorMessage={errorMsg.typeError}
                  onChange={(
                    event: React.FormEvent<HTMLDivElement>,
                    item: any,
                  ) => {
                    setContact((prevState) => ({
                      ...prevState,
                      type: item.text,
                    }));
                    setError((prevState) => ({
                      ...prevState,
                      typeError: '',
                    }));
                  }}
                  styles={dropdownStyles}
                />
              </div>
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                <TextField
                  ariaLabel={t('required')}
                  required={true}
                  errorMessage={
                    errorMsg.valueError
                  }
                  label={props.valueLable}
                  onChange={onChangeContactNumber}
                  value={contact.value}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={props.isEdit ? saveIconWhite : addIconWhite}
            text={props.isEdit ? t('save') : t('save')}
            disabled={!props.isEdit ? false : hasActualChange()}
            style={{ marginTop: 20, marginBottom: 10 }}
            onClick={() => {
              if (validateContact(contact)) {
                setSaveClicked(true);
                setButtonType(1);
                setProgress(true);
                //setHasChanged(false)
                let type = '';
                if (contact.type === t('email')) {
                  type = 'email';
                } else if (contact.type === t('skype')) {
                  type = 'skype';
                } else if (contact.type === t('whatsApp')) {
                  type = 'wahtsApp';
                } else if (contact.type === t('mobile')) {
                  type = 'mobilePhone';
                } else if (contact.type === t('phone')) {
                  type = 'landPhone';
                } else {
                  type = contact.type;
                }
                props.isQniqContact({ [type]: contact.value });
              }
            }}
          />

          {!props.isEdit && props.options.length > 1 && (
            <PrimaryButton
              iconProps={props.isEdit ? saveIconWhite : addIconWhite}
              text={t('saveandNew')}
              style={{ marginTop: 20, marginBottom: 10, marginLeft: 10 }}
              onClick={() => {
                if (validateContact(contact)) {
                  setButtonType(2);
                  setProgress(true);
                  setSaveClicked(true);
                  let type = '';
                  if (contact.type === t('email')) {
                    type = 'email';
                  } else if (contact.type === t('skype')) {
                    type = 'skype';
                  } else if (contact.type === t('whatsApp')) {
                    type = 'wahtsApp';
                  } else if (contact.type === t('mobile')) {
                    type = 'mobilePhone';
                  } else if (contact.type === t('phone')) {
                    type = 'landPhone';
                  } else {
                    type = contact.type;
                  }
                  props.isQniqContact({ [type]: contact.value });
                }
              }}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ContactModal;

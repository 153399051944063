import {IDropdownOption} from "@fluentui/react";
import i18n from "../../../../i18n";
import {DpViewMode} from "../../../types/myDayPlanning";

export const viewModeDp: IDropdownOption[] = [
    {
        key: DpViewMode.OneHour,
        text: i18n.t('oneHour'),
    },
    {
        key: DpViewMode.TwoHour,
        text: i18n.t('twoHours'),
    },
    {
        key: DpViewMode.FourHour,
        text: i18n.t('fourHours'),
    },
    {
        key: DpViewMode.SixHour,
        text: i18n.t('sixHours'),
    },
    {
        key: DpViewMode.EightHour,
        text: i18n.t('eightHours'),
    },
    {
        key: DpViewMode.TwelveHour,
        text: i18n.t('twelveHours'),
    }
];

export const hourlyDropdown = (startTime?: any, endTime?: any) => {
    const startTimeDropdownOptions = [];
    const endTimeDropdownOptions = [];

    for (let i = 0; i < 24; i++) {
        const hour = i.toString().padStart(2, '0');
        const key = i;
        const text = `${hour}:00`;
        const endTimeValidation: boolean = i <= startTime;
        const startTimeValidation: boolean = i >= endTime;

        startTimeDropdownOptions.push({key, text, disabled: startTimeValidation});
        endTimeDropdownOptions.push({key, text, disabled: endTimeValidation});
    }

    return [startTimeDropdownOptions, endTimeDropdownOptions];
}

export const LOAD_CONTRACT_SHORTCUTPANE = 'uprince/LOAD_CONTRACT_SHORTCUTPANE';
export const LOAD_CONTRACT_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_CONTRACT_SHORTCUTPANE_SUCCESS';
export const LOAD_CONTRACT_SHORTCUTPANE_FAIL = 'uprince/LOAD_CONTRACT_SHORTCUTPANE_FAIL';

export const LOAD_CONTRACT_LSITPANE = 'uprince/LOAD_CONTRACT_LSITPANE';
export const LOAD_CONTRACT_LSITPANE_SUCCESS = 'uprince/LOAD_CONTRACT_LSITPANE_SUCCESS';
export const LOAD_CONTRACT_LSITPANE_FAIL = 'uprince/LOAD_CONTRACT_LSITPANE_FAIL';

export const LOAD_CONTRACT_DROPDOWN = 'uprince/LOAD_CONTRACT_DROPDOWN';
export const LOAD_CONTRACT_DROPDOWN_SUCCESS = 'uprince/LOAD_CONTRACT_DROPDOWN_SUCCESS';
export const LOAD_CONTRACT_DROPDOWN_FAIL = 'uprince/LOAD_CONTRACT_DROPDOWN_FAIL';

export const SAVE_CONTRACT = 'uprince/SAVE_CONTRACT';
export const SAVE_CONTRACT_SUCCESS = 'uprince/SAVE_CONTRACT_SUCCESS';
export const SAVE_CONTRACT_FAIL = 'uprince/SAVE_CONTRACT_FAIL';

export const SAVE_CONTRACTOR_WORKFLOW = 'uprince/SAVE_CONTRACTOR_WORKFLOW';
export const SAVE_CONTRACTOR_WORKFLOW_SUCCESS = 'uprince/SAVE_CONTRACTOR_WORKFLOW_SUCCESS';
export const SAVE_CONTRACTOR_WORKFLOW_FAIL = 'uprince/SAVE_CONTRACTOR_WORKFLOW_FAIL';

export const LOAD_CONTRACT_BY_ID = 'uprince/LOAD_CONTRACT_BY_ID';
export const LOAD_CONTRACT_BY_ID_SUCCESS = 'uprince/LOAD_CONTRACT_BY_ID_SUCCESS';
export const LOAD_CONTRACT_BY_ID_FAIL = 'uprince/LOAD_CONTRACT_BY_ID_FAIL';

export const LOAD_BOR_RESOURCES_BY_ID = 'uprince/LOAD_BOR_RESOURCES_BY_ID';
export const LOAD_BOR_RESOURCES_BY_ID_SUCCESS = 'uprince/LOAD_BOR_RESOURCES_BY_ID_SUCCESS';
export const LOAD_BOR_RESOURCES_BY_ID_FAIL = 'uprince/LOAD_BOR_RESOURCES_BY_ID_FAIL';

export const SET_CONTRACT_UID = 'uprince/SET_CONTRACT_UID';
export const CLEAR_CONTRACT_UID = 'uprince/CLEAR_CONTRACT_UID';
export const CLEAR_CONTRACT_FORM_DATA = 'uprince/CLEAR_CONTRACT_FORM_DATA';
export const RESET_CONTRACT_IS_CHANGE = 'uprice/RESET_CONTRACT_IS_CHANGE';

export const LOAD_CONTRACTOR_LIST = 'uprince/LOAD_CONTRACTOR_LIST';
export const LOAD_CONTRACTOR_LIST_SUCCESS = 'uprince/LOAD_CONTRACTOR_LIST_SUCCESS';
export const LOAD_CONTRACTOR_LIST_FAIL = 'uprince/LOAD_CONTRACTOR_LIST_FAIL';

export const CLEAR_CONTRACTUID = 'uprice/CLEAR_CONTRACTUID';
export const SAVE_CONTRACT_STATE_ATTR = 'uprice/SAVE_CONTRACT_STATE_ATTR';

export const LOAD_CONSTRUCTOR_BY_ID = 'uprince/LOAD_CONSTRUCTOR_BY_ID';
export const LOAD_CONSTRUCTOR_BY_ID_SUCCESS = 'uprince/LOAD_CONSTRUCTOR_BY_ID_SUCCESS';
export const LOAD_CONSTRUCTOR_BY_ID_FAIL = 'uprince/LOAD_CONSTRUCTOR_BY_ID_FAIL';

//taxonomy
export const GET_CONSTRUCTOR_DATA = 'uprince/GET_CONSTRUCTOR_DATA';
export const GET_CONSTRUCTOR_DATA_SUCCESS = 'uprince/GET_CONSTRUCTOR_DATA_SUCCESS';
export const GET_CONSTRUCTOR_DATA_FAIL = 'uprince/GET_CONSTRUCTOR_DATA_FAIL';

export const DELETE_CONSTRUCTOR = 'uprince/DELETE_CONSTRUCTOR';
export const DELETE_CONSTRUCTOR_SUCCESS = 'uprince/DELETE_CONSTRUCTOR_SUCCESS';
export const DELETE_CONSTRUCTOR_FAIL = 'uprince/DELETE_CONSTRUCTOR_FAIL';

export const CONTRACTOR_LOT_EXCEL_UPLOAD = 'uprince/CONTRACTOR_LOT_EXCEL_UPLOAD';
export const CONTRACTOR_LOT_EXCEL_UPLOAD_SUCCESS = 'uprince/CONTRACTOR_LOT_EXCEL_UPLOAD_SUCCESS';
export const CONTRACTOR_LOT_EXCEL_UPLOAD_FAIL = 'uprince/CONTRACTOR_LOT_EXCEL_UPLOAD_FAIL';

export const CONTRACTOR_SEND_INVITATION = 'uprince/CONTRACTOR_SEND_INVITATION';
export const CONTRACTOR_SEND_INVITATION_SUCCESS = 'uprince/CONTRACTOR_SEND_INVITATION_SUCCESS';
export const CONTRACTOR_SEND_INVITATION_FAIL = 'uprince/CONTRACTOR_SEND_INVITATION_FAIL';

export const CONTRACTOR_PUBLISH_TENDER = 'uprince/CONTRACTOR_PUBLISH_TENDER';
export const CONTRACTOR_PUBLISH_TENDER_SUCCESS = 'uprince/CONTRACTOR_PUBLISH_TENDER_SUCCESS';
export const CONTRACTOR_PUBLISH_TENDER_FAIL = 'uprince/CONTRACTOR_PUBLISH_TENDER_FAIL';

export const CONTRACTOR_AWARD_TENDER = 'uprince/CONTRACTOR_AWARD_TENDER';
export const CONTRACTOR_AWARD_TENDER_SUCCESS = 'uprince/CONTRACTOR_AWARD_TENDER_SUCCESS';
export const CONTRACTOR_AWARD_TENDER_FAIL = 'uprince/CONTRACTOR_AWARD_TENDER_FAIL';

export const SEND_INVITATION_TO_CONTRACTOR = 'uprince/SEND_INVITATION_TO_CONTRACTOR';
export const SEND_INVITATION_TO_CONTRACTOR_SUCCESS = 'uprince/SEND_INVITATION_TO_CONTRACTOR_SUCCESS';
export const SEND_INVITATION_TO_CONTRACTOR_FAIL = 'uprince/SEND_INVITATION_TO_CONTRACTOR_FAIL';

export const LOT_FILE_UPLOAD = 'uprince/LOT_FILE_UPLOAD';
export const LOT_FILE_UPLOAD_SUCCESS = 'uprince/LOT_FILE_UPLOAD_SUCCESS';
export const LOT_FILE_UPLOAD_FAIL = 'uprince/LOT_FILE_UPLOAD_FAIL';

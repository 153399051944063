import { DirectionalHint, Link, Stack, Text, TooltipHost } from '@fluentui/react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { iconColors, uPrinceTheme } from '../../../../theme';
import { ORGSettingShortCutPaneItem } from '../../../types/organisationSetting';

const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 14vh)!important',
    position: 'relative',
  },
  selected: {
    fontWeight: 'bold',
    color: '#854114!important',
  },
});

// const allFilterItem: PMOLShortCutPaneItem = {
//     key: 'all',
//     id: null,
//     type: 'all',
//     name: 'All',
//     value: null
// };
export const ShortCutPaneComponent = (props: {
  toggleOverlay: () => void;
  toggleOverlayFwd: () => void;
  divClass: string;
  isCollapse: boolean;
  shortCutPaneFilters: ORGSettingShortCutPaneItem[];
  currentActiveSection: string | null;
  handelShortCutPaneFilter: (selectedItem: ORGSettingShortCutPaneItem) => void;
  isExpand: boolean;
  type: string;
  selectedShortCutItem: any
}) => {
  const { t } = useTranslation();

  const setFilterOption = (selectedItem: ORGSettingShortCutPaneItem) => {
    props.handelShortCutPaneFilter(selectedItem);
  };

  const isSelectedItem = (currentActiveSection: any, item: any) => {
    if (props.selectedShortCutItem && (props.selectedShortCutItem === item.id)) {
      return true;
    }
    return false;
  };

  const renderFilterItems = () => {
    if (props.shortCutPaneFilters) {
      return props.shortCutPaneFilters.map((item: any, index) => {
        let divider = false;
        if (index > 0) {
          const previousItem: ORGSettingShortCutPaneItem =
            props.shortCutPaneFilters[index - 1];
          if (previousItem.type !== item.type) {
            divider = true;
          } else {
            divider = false;
          }
        } else {
          divider = true;
        }

        return (
          <div key={index + 'div'}
               style={{ marginLeft: !item.isMain && !props.isCollapse && !props.isExpand ? 20 : 0 }}>
            {divider && (
              <hr
                key={index + 'hr'}
                style={{ marginBottom: 15, borderTop: '1px solid #d2d2d2' }}
              />
            )}
            <Link
              key={index + 'link'}
              className={`${isSelectedItem(props.currentActiveSection, item) ? classNames.selected : ''
              }`}
              href="#"
              onClick={(evt) => {
                evt.preventDefault();
                setFilterOption(item);
              }}
            >
              <TooltipHost
                key={index + 't'}
                content={item.name}
                id="filter-opt-1"
                calloutProps={{ gapSpace: 0 }}
                directionalHint={DirectionalHint.rightCenter}
              >
                <i
                  key={index + 'i'}
                  className={`ms-Icon ${item.icon}`}
                  aria-labelledby="filter-opt-1"
                  aria-hidden="true"
                  style={
                    isSelectedItem(props.currentActiveSection, item)
                      ? { color: iconColors.iconActiveColor }
                      : { color: uPrinceTheme.palette.themePrimary }
                  }
                ></i>
              </TooltipHost>
              <i
                key={index + 'i2'}
                className={`filter-icon ms-Icon ${item.icon} filter-icon`}
                aria-hidden="true"
                style={
                  isSelectedItem(props.currentActiveSection, item)
                    ? { color: iconColors.iconActiveColor }
                    : { color: uPrinceTheme.palette.themePrimary }
                }
              ></i>
              <Text key={index}>{item.name} </Text>
            </Link>
          </div>
        );
      });
    }
    return <div></div>;
  };

  return (
    <div className={`${props.divClass} ${classNames.wrapper}`}>
      <div className="inner-container">
        <i
          onClick={props.toggleOverlay}
          className="ms-Icon ms-Icon--Back back-arrow"
          aria-hidden="true"
        ></i>
        <i
          onClick={props.toggleOverlayFwd}
          className="ms-Icon ms-Icon--Forward fwd-arrow"
          aria-hidden="true"
        ></i>

        <div className="filter-data filter-title">

        </div>

        <div className="filter-option-list">
          <Stack
            gap={15}
            verticalFill
            styles={{
              root: {
                width: '100%',
                verticalAlign: 'center',
              },
            }}
          >
            { /* <Link
                            key={ 'All-link' }
                            className={ `${
                                props.currentActiveSection === null ? classNames.selected : ''
                            }` }
                            href="#"
                            onClick={ (evt) => {
                                evt.preventDefault();
                                setFilterOption(allFilterItem);
                            } }
                        >
                            <TooltipHost
                                key={ 'All-link1' }
                                content={ t('all') }
                                id="filter-opt-1"
                                calloutProps={ { gapSpace: 0 } }
                                directionalHint={ DirectionalHint.rightCenter }
                            >
                                <i
                                    key={ 'All-linki' }
                                    className="ms-Icon ms-Icon--ViewAll"
                                    aria-labelledby="filter-opt-1"
                                    aria-hidden="true"
                                ></i>
                            </TooltipHost>
                            <i
                                key={ 'All-linki2' }
                                className="filter-icon ms-Icon ms-Icon--ViewAll filter-icon"
                                aria-hidden="true"
                            ></i>
                            <Text key="all"> { t('all') }</Text>
                        </Link> */}
            {renderFilterItems()}
          </Stack>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShortCutPaneComponent);

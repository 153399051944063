import React, { useState } from 'react';
import {AcceptType} from "../../../types/myDayPlanning";
import EventBody from "../../projectDayPlanning/listPane/eventBody";
import * as TdDropBox from "../../../shared/dnd/dropBox";
import {ICommandBarItemProps} from "@fluentui/react";
import {PMOL_ACTIVITY_TYPES} from "../../../types/projectMolecule";
import {useDispatch, useSelector} from "react-redux";
import {clonePmolDayPlanning, saveMyDayPlanningStateAttr} from "../../../reducers/myDayPlanningReducer";
import {saveStateAttr} from "../../../reducers/uprinceReducer";
import {updatePlanBoardsPmolStatus} from "../../../reducers/projectDayPlanningReducer";
import { messageService } from '../../../services/messageService';
import { getContractingUnit, isTeamPlanboardMyEnv } from '../../../shared/util';
import Axios from 'axios';
import ENV from '../../../../env.json'
import { DELETE_PMOL } from '../../../shared/endpoints';
import i18n from '../../../../i18n';
import ProgressBar from '../../../shared/progressBar/progressBar';

function CustomEvent({event}: any) {
    const dispatch = useDispatch();
    const [showProgress, setShowProgress] = useState<boolean>(false)

    const selectedDate = useSelector((state: any) => state?.mydPlan?.selectedDate);

    const openPmol = (pmol: any) => {
        dispatch(saveMyDayPlanningStateAttr('selectedMyDpPmol', pmol));
        dispatch(saveStateAttr('contractingUinit', pmol?.contractingUinit));
        dispatch(saveStateAttr('projectSequenceCode', pmol?.projectSequenceCode));
    };

    const createPmol = (pmol: any, type: string) => {
        if (pmol?.projectMoleculeId) {
            let data = {
                ...pmol,
                pmolId: pmol?.projectMoleculeId,
                typeId: type
            };

            Promise.all([
                dispatch(clonePmolDayPlanning(data))
            ]).then(() => {
                //TODO:  reloadListPaneItem();
            });
        }
    };

    const copyPmol = (pmol: any) => {
        dispatch(saveMyDayPlanningStateAttr('pmolCopyState', {
            event: pmol,
            isCopyModalOpen: true
        }));
    };

    const deletePmol = async (pmol: any) => {
        try {
            setShowProgress(true)
            const getPmolByid = await Axios({
                method: 'GET',
                url: `${ENV.BASE_URL}/Pmol/ReadPmolId/${pmol?.projectMoleculeId}`,
                headers: {
                    "Authorization": `Bearer ${localStorage?.getItem('accessToken')}`,
                    "Cu": getContractingUnit(),
                    "Project": pmol?.projectSequenceCode
                }
            })
            if (getPmolByid?.status === 200) {
                const pmolDetails = getPmolByid?.data?.result?.header
                if (pmolDetails?.statusId === "d60aad0b-2e84-482b-ad25-618d80d49477") {
                    const reqDel = await Axios({
                        method: 'POST',
                        url: `${ENV.BASE_URL}/${DELETE_PMOL}`,
                        headers: {
                            "Authorization": `Bearer ${localStorage?.getItem('accessToken')}`,
                            "Cu": getContractingUnit(),
                            "Project": pmol?.projectSequenceCode
                        },
                        data: {
                            id: pmol?.id
                        }
                    })
                    // const requestDelete = await client.post(DELETE_PMOL, {id: pmol?.id})
                    if (reqDel?.status === 200) {
                        setShowProgress(false);
                        messageService.sendMessage({ reloadList: true });
                    } else {
                        alert("Something went wrong when deleting this PMOL")
                    }
                } else {
                    alert('Only PMOL statuses marked as Pending Development are eligible for deletion')
                }
            }
        } catch (error) {
            console.log('error', error);
            setShowProgress(false);
        } finally {
            setShowProgress(false);
        }
    }

    const handlePmolStatusChange = (data: any) => {
        Promise.all([
            dispatch(updatePlanBoardsPmolStatus(data))
        ]).then(() => {
            if(isTeamPlanboardMyEnv()){
                messageService.sendMessage({ reloadList: true });
            }
        });
    };

    const _overflowItems: ICommandBarItemProps[] = [
        {key: 'open', text: i18n.t('open'), onClick: () => openPmol(event), iconProps: {iconName: 'MoveToFolder'}},
        {
            key: 'create',
            text: i18n.t('createTransportPMOL'),
            onClick: () => createPmol(event, PMOL_ACTIVITY_TYPES.TRAVEL),
            iconProps: {iconName: 'Add'}
        },
        {key: 'copy', text: i18n.t('copy'), onClick: () => copyPmol(event), iconProps: {iconName: 'Copy'}},
        {key: 'delete', text: i18n.t('delete'), onClick: () => deletePmol(event), iconProps: {iconName: 'Delete'}},
    ];

    return <TdDropBox.DropBox
        item={event}
        day={selectedDate}
        type={[AcceptType.TEAM, AcceptType.VEHICLE, AcceptType.TOOL]}
        isPmol={true}
    >
        <ProgressBar show={showProgress}/>
        <EventBody pmol={event} overflowItems={_overflowItems} isDp={true}
                   handlePmolStatusChange={(data: any) => handlePmolStatusChange(data)}/>
    </TdDropBox.DropBox>
}

export default CustomEvent;
import React, { Component } from 'react';
import Brand from '../shared/brand/brand';
import NavBar from '../shared/navBar/navBar';
import ProjectBar from '../shared/projectBar/projectBar';
import TimeClockMainLayout from './timeClock/timeClockMainLayout';

export interface State {
  redirect: boolean
}

export default class TimeClock extends Component<any, State> {

  constructor(props: {}) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  render() {
    return (
      <div>
        <Brand/>
        <ProjectBar
          contractingUnit={this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null}/>
        <NavBar selectedKey={2}/>
        <TimeClockMainLayout/>
      </div>
    );
  }
}

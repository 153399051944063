import { DirectionalHint, getTheme, IStackTokens, mergeStyleSets, TooltipHost } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../theme';
import { messageService } from '../../../services/messageService';
import { format24hDateTime } from '../../../shared/util';
import { BillOfResource, BorResources, BorResourcesItem, ResourceTypes } from '../../../types/billOfResources';
import DataGrid from './DataGrid';

const theme = getTheme();

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const classNames = mergeStyleSets({
  wrapper: {
    minHeight: 230,
    position: 'relative',
  },
  fullWidth: {
    width: '100%',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  bigScreenTree: {
    marginBottom: 30,
    height: '30vh',
  },
  smallScreenTree: {
    marginBottom: 30,
    height: '56vh',
  },
  textEditor: {
    height: window.innerWidth <= 1280 ? '50vh' : '25vh',
  },
});

const Resources = (props: {
  billOfResource: BillOfResource;
  dataList: BorResources;
  editDataGrid: any;
  isLoaded: any;
  cpcOptions: any;
  isEdit: boolean;
  cpcData: any;
  saveFormData: () => void;
  actionButtonState: any
  materialSelection: any
  openMaterialModal: any
  toolSelection: any
  openToolsModal: any
  consumableSelection: any
  openConsumableModal: any
  labourSelection: any
  openLabourModal: any
  handleMaterialDelete: any
  handleToolsDelete: any
  handleConsumableDelete: any
  handleLabourDelete: any
  isBorReturn: boolean
}) => {
  const { t } = useTranslation();
  const [isOpenRequired, setIsOpenRequired]: any = useState(false);
  const [type, setType]: any = useState(null);
  const [resourceType, setResourceType]: any = useState(null);
  const [modalTitle, setModalTitle]: any = useState('');
  const resource: BorResourcesItem = {
    id: null,
    type: null,
    invoiced: null,
    warf: null,
    purchased: null,
    deliveryRequested: null,
    consumed: null,
    date: null,
    title: null,
    required: null,
    environment: null,
    isNew: false,
    resourceNumber: null,
  };
  const [borResource, setBorResource]: any = useState(resource);
  const resourcesColumns = [
    {
      key: 'column1',
      name: t('date'),
      fieldName: 'date',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: BorResourcesItem) => {
        if (item.date) {
          return <span>{format24hDateTime(item.date)}</span>;
        } else {
          return <span></span>;
        }
      },
    },
    {
      key: 'column2',
      name: t('title'),
      fieldName: 'title',
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: 'column3',
      name: !props.isBorReturn ? t('required') : t('return'),
      fieldName: !props.isBorReturn ? 'required' : 'returned',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        if (item.isNew) {
          return (
            <span>
              {item.type === '0' ? '-' : ''}
              {!props.isBorReturn ? item.required : item.returned}
            </span>
          );
        } else {
          return <span>{!props.isBorReturn ? item.required : item.returned}</span>;
        }
      },
    },
    {
      key: 'column4',
      name: t('purchased'),
      fieldName: 'purchased',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column5',
      name: t('deliveryRequested'),
      fieldName: 'deliveryRequested',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },

    {
      key: 'column6',
      name: t('warf'),
      fieldName: 'warf',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column7',
      name: t('consumed'),
      fieldName: 'consumed',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column8',
      name: t('invoiced'),
      fieldName: 'invoiced',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column9',
      name: t('requestedDeliveryDate'),
      fieldName: 'requestedDeliveryDate',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: BorResourcesItem) => {

        return (
          <span>
             <TooltipHost
               content={item.requestedDeliveryDate ? format24hDateTime(item.requestedDeliveryDate) : '-'}
               id="filter-opt-2"
               calloutProps={{ gapSpace: 0 }}
               directionalHint={DirectionalHint.rightCenter}
             >
                   {item.requestedDeliveryDate ? format24hDateTime(item.requestedDeliveryDate) : '-'}
                </TooltipHost>

            </span>
        );

      },
    },
    {
      key: 'column10',
      name: t('expectedDeliveryDate'),
      fieldName: 'expectedDeliveryDate',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: BorResourcesItem) => {

        return (
          <span>
             <TooltipHost
               content={item.expectedDeliveryDate ? format24hDateTime(item.expectedDeliveryDate) : '-'}
               id="filter-opt-2"
               calloutProps={{ gapSpace: 0 }}
               directionalHint={DirectionalHint.rightCenter}
             >
                   {item.expectedDeliveryDate ? format24hDateTime(item.expectedDeliveryDate) : '-'}
                </TooltipHost>

            </span>
        );

      },
    },
    {
      key: 'column11',
      name: t('actualDeliveryDate'),
      fieldName: 'actualDeliveryDate',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: BorResourcesItem) => {

        return (
          <span>
             <TooltipHost
               content={item.actualDeliveryDate ? format24hDateTime(item.actualDeliveryDate) : '-'}
               id="filter-opt-2"
               calloutProps={{ gapSpace: 0 }}
               directionalHint={DirectionalHint.rightCenter}
             >
                   {item.actualDeliveryDate ? format24hDateTime(item.actualDeliveryDate) : '-'}
                </TooltipHost>

            </span>
        );

      },
    },
  ];

  useEffect(() => {
    if (resourceType) {
      messageService.sendMessage({ gridResourceType: resourceType }); //catch on Bor document pane container
    }
  }, resourceType);

  useEffect(() => {
    if (props.cpcData) {
      borResource.id = props.cpcData.id;
      borResource.title = props.cpcData.title;
      setBorResource(borResource);
    }
  }, [props.cpcData]);

  const openRequiredModal = (type: any, resourceType: any) => {
    let resourceTypeName = getResourceTypeName(resourceType);
    let typeName = resourceTypeName;
    if (resourceTypeName) {
      //typeName= `${resourceTypeName[0].toUpperCase()}${resourceTypeName.slice(1)}`;
    }
    let title = '';
    if (type === '1') {
      title = t('add') + ' ' + typeName;
      setModalTitle(title);
    } else if (type === '0') {
      title = t('remove') + ' ' + typeName;
      setModalTitle(title);
    }
    messageService.sendMessage({
      isRequiredModalOpen: true,
      requiredModalTitle: title,
      requiredModalType: type,
      requiredResourceType: resourceType,
    }); //catch on Bor document pane container
    setType(type);
    setResourceType(resourceType);
    // setIsOpenRequired(!isOpenRequired)
  };

  const handleResourceSave = (quantity: string | number) => {
    //if(props.cpcData) {
    borResource.type = type;
    borResource.required = quantity;
    //borResource.id = props.cpcData.id;
    //borResource.title = props.cpcData.resourceTitle;
    setBorResource(borResource);
    messageService.sendMessage({
      addNewBorResources: true,
      borResourcesItem: borResource,
      gridResourceType: resourceType,
    }); //catch on Bor document pane container
    //}
  };

  const handleResourceChange = (selectedCpc: any) => {
    // messageService.sendMessage({
    //   selectedCpcId: selectedCpc,
    //
    // }); //catch on Bor document pane container
  };

  const getResourceTypeName = (id: any) => {
    switch (id) {
      case ResourceTypes.materials.id: {
        return t('material');
      }
      case ResourceTypes.tools.id: {
        return t('tools');
      }
      case ResourceTypes.consumable.id: {
        return t('consumable');
      }
      case ResourceTypes.labour.id: {
        return t('labour');
      }
      default: {
        return '';
      }
    }
  };
  return (
    <div>
      <div className={`wrapper-holder ${classNames.wrapper}`}>
        {/*{JSON.stringify(props.material)}*/}
        {/*<ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>*/}
        <DataGrid
          dataList={(props.billOfResource.itemId) ? (props.dataList.materials ? props.dataList.materials : []) : []}
          editDataGrid={() => props.editDataGrid()}
          deleteDataGrid={() => props.handleMaterialDelete()}
          openModel={(type: any) => {
            props.openMaterialModal();
          }}
          saveFormData={() => props.saveFormData()}
          title={t('material')}
          link={'bor-material'}
          id={props.isBorReturn ? '2' : '3'}
          readOnly={!props.billOfResource.itemId}
          columns={resourcesColumns}
          isLoaded={false}
          actionButtonState={props.actionButtonState.materialActionButtonState}
          selection={props.materialSelection}
        />
        {/*</ScrollablePane>*/}
      </div>
      <div className={`wrapper-holder ${classNames.wrapper}`}>
        {/*<ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>*/}
        <DataGrid
          dataList={(props.billOfResource.itemId) ? (props.dataList.tools ? props.dataList.tools : []) : []}
          editDataGrid={() => props.editDataGrid()}
          deleteDataGrid={() => props.handleToolsDelete()}
          openModel={(type: any) => {
            props.openToolsModal();
          }}
          saveFormData={() => props.saveFormData()}
          title={t('tools')}
          link={'bor-tools'}
          id={props.isBorReturn ? '3' : '4'}
          readOnly={!props.billOfResource.itemId}
          columns={resourcesColumns}
          isLoaded={false}
          actionButtonState={props.actionButtonState.toolsActionButtonState}
          selection={props.toolSelection}
        />
        {/*</ScrollablePane>*/}
      </div>

      <div className={`wrapper-holder ${classNames.wrapper}`}>
        {/*/!*<ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>*!/*/}
        <DataGrid
          dataList={(props.billOfResource.itemId) ? (props.dataList.consumable ? props.dataList.consumable : []) : []}
          editDataGrid={() => props.editDataGrid()}
          deleteDataGrid={() => props.handleConsumableDelete()}
          openModel={(type: any) => {
            props.openConsumableModal();
          }}
          saveFormData={() => props.saveFormData()}
          title={t('consumable')}
          link={'bor-consumables'}
          id={props.isBorReturn ? '4' : '5'}
          readOnly={
            props.billOfResource.product &&
            props.billOfResource.product.productId == null
          }
          columns={resourcesColumns}
          isLoaded={false}
          actionButtonState={props.actionButtonState.consumableActionButtonState}
          selection={props.consumableSelection}
        />
        {/*/!*</ScrollablePane>*!/*/}
      </div>

      <div className={`wrapper-holder ${classNames.wrapper}`}>
        {/*/!*<ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>*!/*/}
        <DataGrid
          dataList={(props.billOfResource.itemId) ? (props.dataList.labour ? props.dataList.labour : []) : []}
          editDataGrid={() => props.editDataGrid()}
          deleteDataGrid={() => props.handleLabourDelete()}
          openModel={(type: any) => {
            props.openLabourModal();
          }}
          saveFormData={() => props.saveFormData()}
          title={t('labour')}
          link={'bor-labour'}
          id={props.isBorReturn ? '5' : '6'}
          readOnly={
            props.billOfResource.product &&
            props.billOfResource.product.productId == null
          }
          columns={resourcesColumns}
          isLoaded={false}
          actionButtonState={props.actionButtonState.labourActionButtonState}
          selection={props.labourSelection}
        />
        {/*/!*</ScrollablePane>*!/*/}
      </div>
    </div>
  );
};

export default Resources;

import React, { Component } from 'react';
import { IImageProps, Image, ImageFit, IStackTokens, mergeStyleSets } from '@fluentui/react';
import { uPrinceTheme } from '../../../theme';
import LotSubcribeMainLayout from './lotSubcribeMainLayout';
import LotSubMainLayout from './lotSubMainLayout';

const stackTokens: IStackTokens = { childrenGap: 40 };

const styles = {
  hoverText: {
    alignSelf: 'center',
    backgroundColor: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
    textDecoration: 'none',
    opacity: '0.5',
    transition: 'all 0.2s',
    '&:hover': {
      background: uPrinceTheme.palette.themePrimary,
    },
  },
  Waypoint: {
    alignSelf: 'center',
    color: uPrinceTheme.palette.themePrimary,
    fontSize: '22px',
    cursor: 'pointer',
    textDecoration: 'none',
    marginRight: '15px',
    marginLeft: '10px',
    transition: 'all 0.2s',
    '&:hover': { background: uPrinceTheme.palette.themeSecondary },
  },
  logo: {
    width: '30%',
    height: '30%',
    marginTop: '20px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
};

const classNames = mergeStyleSets({
  brand: {
    width: '100%',
    height: '40px',
    backgroundColor: uPrinceTheme.palette.themePrimary, //'rgb(231, 48, 56)',
  },
  projectBar: {
    width: '100%',
    height: '44px',
    backgroundColor: '#e7e7e7e7 ',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  navBar: {
    width: '100%',
    height: '45px',
    backgroundColor: 'white ',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: ' 0.1px solid #EAECEE',
  },
  buttonArea: {
    textAlign: 'center',
    margin: '0 0px',
    minWidth: 32,
    height: 32,
  },
  navicon: {
    marginLeft: '10px',
    size: '40px',
    color: 'black',
    fontSize: '25px',
    marginRight: '15px',
  },
  body: {
    hight: '100%',
    display: 'block',
    marginLeft: '20%',
    marginRight: '20%',
    backgroundColor: 'white',
    padding: '40px',
    minHeight: 'calc(100vh - 120px)',
  },
  bg: {
    backgroundColor: '#e7e7e7e7',
    minHeight: '100vh',
  },
  spacing: {
    marginTop: '20px',
    marginBottom: '20px',
  },
});

interface Props {
  currentUser: any
}

interface State {
  isContractUnit: boolean;
  isProject: boolean;
}

export class LotSubcribe extends Component<Props, State> {
  render() {
    const imageProps: Partial<IImageProps> = {
      imageFit: ImageFit.centerContain,
      width: 40,
      height: 40,
    };

    return (
      // <UprinceNavigation
      //     currentUser={this.props.currentUser}
      //     selectedNavigationIndex={ `/inv` }
      //     // selectedNavigationIndex={`/CU/${getContractingUnit()}`}
      // >
      <div className={classNames.bg}>
        {/* Brand */}
        <div className={classNames.brand}>
          <Image
            {...imageProps}
            className="brandicon"
            src={
              localStorage.getItem('logoUrl')
                ? localStorage.getItem('logoUrl')!!
                : ''
            }
            // style={styles.hoverText}
            alt='Example of the image fit value "centerContain" on an image wider than the frame.'
          />
        </div>
        {/* <LotSubcribeMainLayout/> */}
        <LotSubMainLayout/>
      </div>
      // </UprinceNavigation>
    );
  }
}

export default LotSubcribe;

import React, {Component} from 'react';
import {getContractingUnit} from '../../shared/util';
import UprinceNavigation from '../../shared/uprinceNavigation/cuProjectNavigation/component';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {v4 as uuidv4} from 'uuid';
import CuMyCalendarMainLayout from './cuMyCalendarMainLayout';

interface Props {
    currentUser: any
}

interface State {
    isContractUnit: boolean;
    isProject: boolean;
}

export class CuMyCalendar extends Component<Props, State> {
    render() {
        return <DndProvider key={uuidv4()} backend={HTML5Backend}>
            <UprinceNavigation
              currentUser={this.props.currentUser}
              selectedNavigationIndex={`/CU/${getContractingUnit()}/cu-my-calendar`}
            >
                <CuMyCalendarMainLayout/>
            </UprinceNavigation>
        </DndProvider>

    }
}

export default (CuMyCalendar);

import {
  DetailsList,
  FontWeights,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  ScrollablePane,
  ScrollbarVisibility,
} from '@fluentui/react';
import React, { Component } from 'react';
import { CostTableColumn } from '../../../../../shared/tableColumn/tableColumn';
import { QRCodeLite } from '../../../../../types/qrCode';
import {
  ConstrainMode,
  DetailsListLayoutMode,
  DetailsRow,
  DetailsRowCheck,
  IColumn,
  IDetailsColumnStyles,
  IDetailsFooterProps,
  IDetailsListProps,
  IDetailsRowBaseProps,
  IDetailsRowCheckStyles,
  IDetailsRowStyles,
  Selection,
  SelectionMode,
} from '@fluentui/react/lib/DetailsList';
import groupArray from 'group-array';
import { ProgressStatement } from '../../../../../types/progressStatement';
import { Cost } from '../../../../../types/invoice';
import _ from 'lodash';
import { uPrinceTheme } from '../../../../../../theme';
import i18n from '../../../../../../i18n';

const theme = getTheme();

const headerStyle: Partial<IDetailsColumnStyles> = {
  root: {
    background: '#e5e5e5',
    borderLeft: '1px #c8c8c8 solid',
  },
};

interface Props {
  projectCostData: Cost[],
  openCostModal: () => void,
  createPSResource: (data: any) => void,
  isExpand: boolean;
  formData: ProgressStatement;
  isApproved: boolean
}

interface State {
  data: Cost[];
  costData: any[];
  costData2: any[]
}

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const stackFooterBarItemStyles: IStackItemStyles = {
  root: {
    height: 45,
  },
};
const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

export class InvoiceProgressStatementCostTable extends Component<Props, State> {
  private _selection: Selection;
  private _columns: IColumn[];
  private _columnsExpand: IColumn[];
  private data: any;

  constructor(props: Props) {
    super(props);
    this._selection = new Selection();

    this.state = {
      data: [],
      costData: [],
      costData2: [],
    };

    this._columns = [
      {
        key: 'column1',
        name: i18n.t('items'),
        fieldName: 'title',
        minWidth: this.props.isExpand ? 200 : 120,
        maxWidth: this.props.isExpand ? 200 : 120,
        isResizable: true,
        isRowHeader: true,
        data: 'string',
        isSortedDescending: false,
        isPadded: true,
        isSorted: true,
        styles: headerStyle,
      },
      {
        key: 'column2',
        name: i18n.t('consumed'),
        fieldName: 'cquantity',
        minWidth: this.props.isExpand ? 150 : 80,
        maxWidth: this.props.isExpand ? 150 : 80,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
      },
      {
        key: 'column3',
        name: i18n.t('cost/mou'),
        fieldName: 'costPerUnit',
        minWidth: this.props.isExpand ? 130 : 80,
        maxWidth: this.props.isExpand ? 130 : 80,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
      },
      {
        key: 'column4',
        name: i18n.t('total'),
        fieldName: 'totalCost1',
        minWidth: this.props.isExpand ? 130 : 80,
        maxWidth: this.props.isExpand ? 130 : 80,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
      },
      {
        key: 'column5',
        name: i18n.t('soldQuantity'),
        fieldName: 'soldQuantity',
        minWidth: this.props.isExpand ? 130 : 80,
        maxWidth: this.props.isExpand ? 130 : 80,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
      },
      {
        key: 'column6',
        name: i18n.t('sPMOU'),
        fieldName: 'spMou',
        minWidth: this.props.isExpand ? 130 : 80,
        maxWidth: this.props.isExpand ? 130 : 80,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
      },
      // {
      //     key: 'column7',
      //     name: 'Travel Cost',
      //     fieldName: 'travelCost',
      //     minWidth: 80,
      //     maxWidth: 80,
      //     isRowHeader: true,
      //     isResizable: true,
      // },
      {
        key: 'column8',
        name: i18n.t('totalSales'),
        fieldName: 'totaleSales',
        minWidth: this.props.isExpand ? 130 : 80,
        maxWidth: this.props.isExpand ? 130 : 80,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
      },
    ];

    this._columnsExpand = [
      {
        key: 'column1',
        name: i18n.t('items'),
        fieldName: 'title',
        minWidth: 300,
        maxWidth: 300,
        isResizable: true,
        isRowHeader: true,
        data: 'string',
        isSortedDescending: false,
        isPadded: true,
        isSorted: true,
        styles: headerStyle,
      },
      {
        key: 'column2',
        name: i18n.t('consumed'),
        fieldName: 'cquantity',
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
      },
      {
        key: 'column3',
        name: i18n.t('cost/mou'),
        fieldName: 'costPerUnit',
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
      },
      {
        key: 'column4',
        name: i18n.t('total'),
        fieldName: 'totalCost1',
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
      },
      {
        key: 'column5',
        name: i18n.t('soldQuantity'),
        fieldName: 'soldQuantity',
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
      },
      {
        key: 'column6',
        name: i18n.t('sPMOU'),
        fieldName: 'spMou',
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
      },
      {
        key: 'column8',
        name: i18n.t('totalSales'),
        fieldName: 'totaleSales',
        minWidth: 130,
        maxWidth: 130,
        isRowHeader: true,
        isResizable: true,
        styles: headerStyle,
      },
    ];

  }

  componentDidMount() {
    //this.getCostData()
    //this.getModifiedCostData()
    //this.getCostData()
    if (this.props.projectCostData) {
      this.setState({ data: this.props.projectCostData }, () => {
        //this.getModifiedCostData()
        this.getCostData();
      });
    }
  }

  getClassNames = () => {
    const classNames = mergeStyleSets({
      wrapper: {
        minHeight: '80vh',
        position: 'relative',
      },
      actionButton: {
        color: uPrinceTheme.palette.themePrimary,
        cursor: 'pointer',
      },
      labelRightIcon: {
        display: 'inline-block',
        verticalAlign: 'bottom',
        paddingBottom: 2,
        paddingRight: 5,
      },
      fullWidth: {
        width: '100%',
      },
      footer: [
        // eslint-disable-next-line deprecation/deprecation
        theme.fonts.xLargePlus,
        {
          flex: '1 1 auto',
          alignItems: 'center',
          fontWeight: FontWeights.semibold,
          padding: '0px 24px 14px 24px',
          textAlign: 'end',
        },
      ],
    });
    return classNames;
  };


  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.projectCostData != this.props.projectCostData) {
      this.setState({ data: this.props.projectCostData }, () => {
        //this.getModifiedCostData()
        this.getCostData();
      });
    }
  }

  _onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {
      root: {
        selectors: {
          ':hover': {
            background: 'transparent',
          },
        },
      },
    };
    if (props) {
      return (
        <Link
          style={{ textDecoration: 'none' }}
          to={'' + props.item.value}
          onClick={() => {
            //onQRItemClick(props.item);
          }}
        >
          <DetailsRow {...props} styles={customStyles}/>
        </Link>
      );
    }
    return null;
  };

  onChangeConsumedQuantity = (index: number, rindex: number, type: number, value: number) => {

    const newData = this.state.costData2.slice(); //copy the array
    let item = newData[index];  //execute the manipulations
    switch (type) {
      case 1:
        item.resource.Materials[rindex].soldQuantity = value;
        break;
      case 2:
        item.resource.Tools[rindex].soldQuantity = value;
        break;
      case 3:
        item.resource.Consumables[rindex].soldQuantity = value;
        break;
      case 4:
        item.resource.Labours[rindex].soldQuantity = value;
        break;
      default:
        break;
    }
    //   let resource = item['materials']
    //   let chnageItem = resource[rindex]
    //   chnageItem.consumed = value;
    this.setState({ costData2: newData });
  };

  onChangeMOUCost = (index: number, rindex: number, type: number, value: number) => {

    const newData = this.state.costData2.slice(); //copy the array
    let item = newData[index];  //execute the manipulations
    switch (type) {
      case 1:
        item.resource.Materials[rindex].spToMou = this.toFixedTrunc(value, 2);
        break;
      case 2:
        item.resource.Tools[rindex].spToMou = this.toFixedTrunc(value, 2);
        break;
      case 3:
        item.resource.Consumables[rindex].spToMou = this.toFixedTrunc(value, 2);
        break;
      case 4:
        item.resource.Labours[rindex].spToMou = this.toFixedTrunc(value, 2);
        break;
      default:
        break;
    }
    this.setState({ costData2: newData });
  };

  toFixedTrunc(x: any, n: any) {
    const v = (typeof x === 'string' ? x : x.toString()).split('.');
    if (n <= 0) return v[0];
    let f = v[1] || '';
    if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
    while (f.length < n) f += '0';
    return `${v[0]}.${f}`;
  }

  onChangeTravelCost = (index: number, rindex: number, type: number, value: number) => {

    const newData = this.state.costData2.slice(); //copy the array
    let item = newData[index];  //execute the manipulations
    switch (type) {
      case 1:
        item.resource.Materials[rindex].travelCost = value;
        break;
      case 2:
        item.resource.Tools[rindex].travelCost = value;
        break;
      case 3:
        item.resource.Consumables[rindex].travelCost = value;
        break;
      case 4:
        item.resource.Labours[rindex].travelCost = value;
        break;
      default:
        break;
    }
    this.setState({ costData2: newData });
  };

  getCostMap = (object: any) => {
    const mp = new Map();
    Object.keys(object).forEach((k) => {
      mp.set(k, object[k]);
    });
    return mp;
  };

  getResourceCategory = (resourceId: string) => {
    switch (resourceId) {
      case 'c46c3a26-39a5-42cc-n7k1-89655304eh6':
        return 'Materials';
      case 'c46c3a26-39a5-42cc-n9wn-89655304eh6':
        return 'Tools';
      case 'c46c3a26-39a5-42cc-m06g-89655304eh6':
        return 'Consumables';
      case 'c46c3a26-39a5-42cc-b07s-89655304eh6':
        return 'Labours';
      default:
        return 'Service';
        break;
    }
  };

  getModifiedCostData = () => {
    let costListData = this.state.data.map(cost => ({
      ...cost,
      resourceCategory: this.getResourceCategory(cost.cpcResourceType),
      soldQuantity: cost.consumedQuantity,
      mouToBeInvoiced: cost.costToMou,
      travelCost: 0,
      totalSales: cost.totalCost,
    }));
    this.setState({ costData: costListData }, () => {
      this.getCostData();
    });
  };


  getResourcesSubTotal = (resource: any) => {
    let materials = resource && resource.hasOwnProperty('Materials') ? resource.Materials : [];
    let tools = resource && resource.hasOwnProperty('Tools') ? resource.Tools : [];
    let consumables = resource && resource.hasOwnProperty('Consumables') ? resource.Consumables : [];
    let labours = resource && resource.hasOwnProperty('Labours') ? resource.Labours : [];
    let services = resource && resource.hasOwnProperty('Service') ? resource.Service : [];
    let allResources = _.concat(materials, tools, consumables, labours, services);
    let subTotal = allResources.reduce((n, { soldQuantity, spToMou }) => n + (soldQuantity * spToMou), 0);
    return subTotal;
  };

  getGrandTotal = () => {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'EUR',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });


    let subTotalArray = this.state.costData2.map(item => {
      return {
        subTotal: this.getResourcesSubTotal(item.resource),
      };
    });
    let grandTotal = subTotalArray.reduce((n, { subTotal }) => n + subTotal, 0);
    let grandTotal2 = formatter.format(grandTotal); /* $2,500.00 */
    return [grandTotal2, grandTotal];
  };


  getCostData = () => {

    let costListData = this.props.projectCostData.map(item => {
      let zero = 0;
      item.costToMou = parseFloat(item.costToMou).toFixed(2);
      item.totalCost = item.totalCost ? item.totalCost.toFixed(2) : zero.toFixed(2);
      return {
        ...item,
        resourceCategory: this.getResourceCategory(item.cpcResourceTypeId),
      };
    });
    let products = groupArray(costListData, 'productTitle');
    let productMap = this.getCostMap(products);
    let array = Array.from(productMap, ([product, resource]) => ({ product, resource }));

    let array2 = array.map(item => {
      return {
        product: item.product,
        resource: groupArray(item.resource, 'resourceCategory'),
      };
    });
    this.setState({ costData2: array2 });
  };

  private _onRenderDetailsFooter(detailsFooterProps: IDetailsFooterProps): JSX.Element {
    return (
      <DetailsRow
        {...detailsFooterProps}
        columns={detailsFooterProps.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps.groupNestingDepth}
        selectionMode={SelectionMode.single}
        selection={detailsFooterProps.selection}
        onRenderItemColumn={this._renderDetailsFooterItemColumn}
        onRenderCheck={this._onRenderCheckForFooterRow}
      />
    );
  }

  private _onRenderDetailsHeader(detailsFooterProps: IDetailsFooterProps): JSX.Element {
    return (
      <DetailsRow
        {...detailsFooterProps}
        columns={detailsFooterProps.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps.groupNestingDepth}
        selectionMode={SelectionMode.single}
        selection={detailsFooterProps.selection}
        onRenderItemColumn={this._renderDetailsHeaderItemColumn}
        onRenderCheck={this._onRenderCheckForHeaderRow}
      />
    );
  }

  private _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] = (item, index, column) => {
    if (column && column.fieldName && column.fieldName === 'title') {
      return (
        <div>

          <b>{i18n.t('total') as any}</b>
        </div>
      );
    } else if (column && column.fieldName && column.fieldName === 'totaleSales') {
      return (
        <div style={{ marginLeft: 40, textAlign: 'right' }}>
          <b>{this.getGrandTotal()[0]}</b>
        </div>
      );
    }
    return <div></div>;
  };

  private _renderDetailsHeaderItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] = (item, index, column) => {
    if (column && column.fieldName && column.fieldName === 'title') {
      return (
        <div style={{ marginTop: 20 }}>
          <b>{i18n.t('items') as any}</b>
        </div>
      );
    } else if (column && column.fieldName && (column.fieldName === 'cquantity')) {
      return (
        <div className={'cquantity'} style={{ width: 400 }}>
          &nbsp;
          <Label>{i18n.t('consumedquantity') as any}
          </Label>
        </div>
      );
      return null;
    } else if (column && column.fieldName && (column.fieldName === 'costPerUnit')) {
      return (
        <div className={'costPerUnit'} style={{ width: 100 }}>
          {i18n.t('costEuro') as any}
          <Label>{i18n.t('cost/mou') as any}</Label>
        </div>
      );
      return null;
    } else if (column && column.fieldName && (column.fieldName === 'totalCost1')) {
      return (
        <div className={'totalCost1'} style={{ width: 400 }}>
          &nbsp;
          <Label>
            {i18n.t('totalCost') as any}
          </Label>
        </div>
      );
      return null;
    } else if (column && column.fieldName && (column.fieldName === 'soldQuantity')) {
      return (
        <div style={{ width: 400 }}>
          &nbsp;
          <Label>
            {i18n.t('soldQuantity') as any}
          </Label>
        </div>
      );
      return null;
    } else if (column && column.fieldName && (column.fieldName === 'spMou')) {
      return (
        <div style={{ width: 400 }}>

          {i18n.t('toBeinvoicedEuro') as any}
          <Label style={{ marginLeft: (this.props.isExpand) ? 30 : 10 }}>
            {i18n.t('sPMOU') as any}
          </Label>
        </div>
      );
      return null;
    } else if (column && column.fieldName && (column.fieldName === 'totaleSales')) {
      return (
        <div style={{ width: 100, textAlign: 'center' }}>
          &nbsp;
          <Label>
            {i18n.t('totalSales') as any}
          </Label>
        </div>
      );
      return null;
    } else {
      return null;
    }

  };

  detailsRowCheckStyles: Partial<IDetailsRowCheckStyles> = { root: { visibility: 'hidden' } };

  _onRenderCheckForFooterRow: IDetailsRowBaseProps['onRenderCheck'] = (props): JSX.Element => {
    return <DetailsRowCheck {...props} styles={this.detailsRowCheckStyles} selected={true}/>;
  };

  _onRenderCheckForHeaderRow: IDetailsRowBaseProps['onRenderCheck'] = (props): JSX.Element => {
    return <DetailsRowCheck {...props} styles={this.detailsRowCheckStyles} selected={true}/>;
  };


  transFormDataToSave = () => {
    let subResourceArray: any[] = this.state.costData2.map(item => {
      let materials = item.resource.hasOwnProperty('Materials') ? item.resource.Materials : [];
      let tools = item.resource.hasOwnProperty('Tools') ? item.resource.Tools : [];
      let consumables = item.resource.hasOwnProperty('Consumables') ? item.resource.Consumables : [];
      let labours = item.resource.hasOwnProperty('Labours') ? item.resource.Labours : [];
      let allResources = _.concat(materials, tools, consumables, labours);
      return allResources;
    });

    var merged = [].concat.apply([], subResourceArray);
    return { data: merged, total: this.getGrandTotal()[1] };
  };

  changeColor = () => {
    let cquantityClass = document.getElementsByClassName('cquantity');
    for (let i = 0; i < cquantityClass.length; i++) {
      let parentElement = cquantityClass[i].parentElement;
      if (parentElement) {
        parentElement.style.borderLeft = '1px solid rgb(243, 242, 241)';
      }
    }

    let totalCost1Class = document.getElementsByClassName('totalCost1');
    for (let i = 0; i < totalCost1Class.length; i++) {
      let parentElement = totalCost1Class[i].parentElement;
      if (parentElement) {
        parentElement.style.borderRight = '1px solid rgb(243, 242, 241)';
      }
    }

  };

  render() {

    return (
      <div>
        <div className={`wrapper-holder ${this.getClassNames().wrapper}`}>
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
            <DetailsList
              items={this.state.costData2}
              columns={this.props.isExpand ? this._columnsExpand : this._columns}
              styles={{
                root: {
                  selectors: {
                    ':hover': {
                      background: 'transparent',
                    },
                  },
                },
              }}
              setKey="set"
              compact={false}
              constrainMode={ConstrainMode.horizontalConstrained}
              layoutMode={DetailsListLayoutMode.justified}
              selectionMode={0}
              selection={this._selection}
              selectionPreservedOnEmptyClick={true}
              checkboxVisibility={2}
              onRenderItemColumn={(
                item: QRCodeLite,
                index?: number,
                column?: IColumn,
              ) => CostTableColumn(item, index, column, this.onChangeConsumedQuantity, this.onChangeMOUCost, this.onChangeTravelCost, true)}

              onItemInvoked={() => {
              }}
              onRenderRow={this._onRenderRow}
              onRenderDetailsFooter={(props) => this._onRenderDetailsFooter(props!!)}
              onRenderDetailsHeader={(props) => this._onRenderDetailsHeader(props!!)}
              onRowDidMount={() => {
                this.changeColor();
              }}
            />
          </ScrollablePane>
        </div>
      </div>
    );
  }
}

export default InvoiceProgressStatementCostTable;

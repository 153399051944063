import { getTheme, ITheme } from '@fluentui/react/lib/Styling';
import { FontWeights, IIconProps, mergeStyleSets } from '@fluentui/react';
import { uPrinceTheme } from '../../../../theme';

const theme: ITheme = getTheme();

/* Top Node Modal Start */
export const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    paddingTop: 20,
    // minWidth: screen.width > 1280?"40vw":"50vw",
    // minHeight: screen.width > 1280?"60vh":"75vh",
    // height: screen.width > 1280?"80vh":"75vh",
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'none',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});

export const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: { color: theme.palette.neutralDark },
};

export const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
    width: 17,
    minWidth: 17,
    height: 15,
    paddingRight: 1,
    paddingLeft: 1,
    paddingTop: 1,
    paddingBottom: 1,
  },
  rootHovered: { color: theme.palette.neutralDark },
};

export const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};
/* Top Node Modal End */

/* WBS Create Taxonomy Start */
export const editWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
    width: 17,
    minWidth: 17,
    height: 15,
    paddingRight: 1,
    paddingLeft: 1,
    paddingTop: 1,
    paddingBottom: 1,
  },
  rootHovered: { color: theme.palette.neutralDark },
};

export const editIconWhite: IIconProps = {
  iconName: 'Edit',
  styles: editWhiteIconButtonStyles
};

/* WBS Create Taxonomy End */
import {
  ActionButton,
  ChoiceGroup, CommandButton, FocusTrapZone, IChoiceGroupOption, IChoiceGroupOptionProps,
  IComboBoxStyles, Icon, IContextualMenuProps, IIconProps, IRenderFunction,
  Toggle,
} from '@fluentui/react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useExpand from '../../hooks/useExpand';
import { ViewMode } from '../ganttv2';
import { getProject } from '../util';
import { uPrinceTheme } from '../../../theme';
import { ViewModeContext } from '../../components/projectVisualPlan/productTaxonomyListPane/component';
import { ViewModeContextLTP } from '../../components/cuProjectVisualPlan/productTaxonomyListPane/component';
import { useDispatch, useSelector } from 'react-redux';
import { saveVPStateAttr } from '../../reducers/visualPlanReducer';

const basicStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 300 } };

const ModeSwitcherWithRadioButtons = ({ onViewModeChange, selectedViewMode, expandToggleEnabled = true, showGanttForProjectPlanning, showGanttForLTP }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {isExpand, setExpand, setIsApplyExpandContext} = useExpand();
  const [mode, setMode] = React.useState<any>(getProject() ? ViewMode.Week: ViewMode.Month );
  const { onShowGanttChartChange, showGanttChart } = useContext(ViewModeContext);
  const { onShowGanttChartChangeLTP, showGanttChartLTP } = useContext(ViewModeContextLTP);
  const showMoreLTPFilters = useSelector((state: any) => state.vp.showMoreLTPFilters);
  const getItemIcon = (key: any) => {
    let icon = '';
    switch (key) {
      case ViewMode.QuarterDay:
        icon = 'QuadColumn';
        break;
      case ViewMode.HalfDay:
        icon = 'DoubleColumn';
        break;
      case ViewMode.Day:
        icon = 'EventDate';
        break;
      case ViewMode.Week:
        icon = 'CalendarWorkWeek';
        break;
      case ViewMode.Month:
        icon = 'Calendar';
        break;
      case ViewMode.QuaterYear:
        icon = 'Source';
        break;
    }
    return icon;
  };

  const onRenderField: IRenderFunction<IChoiceGroupOption & IChoiceGroupOptionProps> = (props: any) => {
    return <div style={{
      width: 40,
      height: 40,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'end',
      alignItems: 'center',
      cursor: 'pointer'
    }}
    >
      <span style={{ fontSize: 10, textAlign: 'center', marginBottom: 2 }}>{props.text}</span>
      <Icon
        style={{
          fontSize: 23,
          color: props.checked ? uPrinceTheme.palette.themePrimary : 'black',
        }}
        iconName={getItemIcon(props?.key)}
      />
    </div>;
  };

  const iconOptions: IChoiceGroupOption[] = [
    { key: ViewMode.QuarterDay, text: t('quaterOfDay'), onRenderField },
    { key: ViewMode.HalfDay, text: t('halfOfDay'), onRenderField },
    { key: ViewMode.Day, text: t('day'), onRenderField },
    { key: ViewMode.Week, text: t('week'), onRenderField },
    { key: ViewMode.Month, text: t('month'), onRenderField },
    { key: ViewMode.QuaterYear, text: t('quater'), onRenderField },
  ];

// USE THIS IF YOU WANT TO USE RADIO BUTTONS
  // const options: IChoiceGroupOption[] = [
  //   { key: ViewMode.QuarterDay, text: t('quaterOfDay') },
  //   { key: ViewMode.HalfDay, text: t('halfOfDay'), styles:{field: { marginLeft: "10px"}} },
  //   { key: ViewMode.Day, text: t('day'), styles:{field: { marginLeft: "10px"}} },
  //   { key: ViewMode.Week, text: t('week'), styles:{field: { marginLeft: "10px"}} },
  //   { key: ViewMode.Month, text: t('month'), styles:{field: { marginLeft: "10px"}} },
  //   { key: ViewMode.QuaterYear, text: t('quater'), styles:{field: { marginLeft: "10px"}} },
  // ];

  const renderGanttChartToggle = ( marginTop: number, marginLeft: number) => (
    <div style={{ marginTop, marginLeft }}>
      <Toggle
        onText={t('Gantt Chart')}
        offText={t('Gantt Chart')}
          onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
          onShowGanttChartChange(!checked);
          onShowGanttChartChangeLTP(!checked);
        }}
      />
    </div>
  );  
  
  return (
    <div style={{ display: screen.width<2540? 'contents':'flex', flexDirection: 'row', height:70 }}>
        <div style={{ height: 42, paddingLeft: 10 }}>
          <ChoiceGroup
            selectedKey={selectedViewMode}
            options={iconOptions} // USE THIS IF YOU WANT TO USE RADIO BUTTONS: options={options}
            onChange={(
              ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
              option?: IChoiceGroupOption,
            ) => {
              onViewModeChange(option?.key);
              setMode(option?.key)
            }}
            // label={t('viewMode')}
            styles={{ flexContainer: { display: 'flex', marginTop: 10 }} }
          />
        </div>

    {screen.width<2540 && expandToggleEnabled && showGanttForLTP &&
      <div style={{ marginTop: 16, marginLeft: 20 }}>
        <span style={{fontSize:10, textAlign: 'center', marginBottom:2, fontFamily: 'sans-serif', fontWeight: 400}}>
          {t('More options')}
        </span>
        <Toggle
          onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
            showMoreLTPFilters ? dispatch(saveVPStateAttr('showMoreLTPFilters', false)):dispatch(saveVPStateAttr('showMoreLTPFilters', true));
          }}
        />
      </div>}

      {expandToggleEnabled && showGanttForLTP && showMoreLTPFilters &&
        <div style={{ marginTop: 12, marginLeft: 20 }}>
          <Toggle
            onText={t('collapse')}
            offText={t('expand')}
            onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
              setIsApplyExpandContext(true);
              setExpand(!checked);
            }}
          />
          {screen.width>=2540 && renderGanttChartToggle(0,0)}
        </div>
      }

      {screen.width<2540 && showMoreLTPFilters && expandToggleEnabled && showGanttForLTP && renderGanttChartToggle(12,6)}

      {expandToggleEnabled && showGanttForProjectPlanning &&
          <div style={{ marginTop: screen.width<2540 ?12:26, marginLeft: 20 }}>
            <Toggle
              onText={t('collapse')}
              offText={t('expand')}
              onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                setIsApplyExpandContext(true);
                setExpand(!checked);
              }}
            />

            {screen.width<2540 && renderGanttChartToggle(0,0)}
          </div>
       }

      {screen.width>=2540 && expandToggleEnabled && showGanttForProjectPlanning && renderGanttChartToggle(26,6)}

    </div>
  );
};

export default ModeSwitcherWithRadioButtons;

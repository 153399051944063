export const SAVE_TH_AUTOMATION_STATE_ATTR = 'uprice/SAVE_TH_AUTOMATION_STATE_ATTR';

export const LOAD_PRODUCT_LIST= 'uprice/LOAD_PRODUCT_LIST';
export const LOAD_PRODUCT_LIST_SUCCESS= 'uprice/LOAD_PRODUCT_LIST_SUCCESS';
export const LOAD_PRODUCT_LIST_FAIL= 'uprice/LOAD_PRODUCT_LIST_FAIL';

export const ASSIGN_TRUCK_TO_PRODUCT= 'uprice/ASSIGN_TRUCK_TO_PRODUCT';
export const ASSIGN_TRUCK_TO_PRODUCT_SUCCESS= 'uprice/ASSIGN_TRUCK_TO_PRODUCT_SUCCESS';
export const ASSIGN_TRUCK_TO_PRODUCT_FAIL= 'uprice/ASSIGN_TRUCK_TO_PRODUCT_FAIL';

export const LOAD_BOTTOM_TRUCK_WITH_PRODUCT= 'uprice/LOAD_BOTTOM_TRUCK_WITH_PRODUCT';
export const LOAD_BOTTOM_TRUCK_WITH_PRODUCT_SUCCESS= 'uprice/LOAD_BOTTOM_TRUCK_WITH_PRODUCT_SUCCESS';
export const LOAD_BOTTOM_TRUCK_WITH_PRODUCT_FAIL= 'uprice/LOAD_BOTTOM_TRUCK_WITH_PRODUCT_FAIL';

export const DELETE_TRUCK_FROM_DAY= 'uprice/DELETE_TRUCK_FROM_DAY';
export const DELETE_TRUCK_FROM_DAY_SUCCESS= 'uprice/DELETE_TRUCK_FROM_DAY_SUCCESS';
export const DELETE_TRUCK_FROM_DAY_FAIL= 'uprice/DELETE_TRUCK_FROM_DAY_FAIL';

export const DELETE_DELIVERY_PRODUCT_FROM_DAY= 'uprice/DELETE_DELIVERY_PRODUCT_FROM_DAY';
export const DELETE_DELIVERY_PRODUCT_FROM_DAY_SUCCESS= 'uprice/DELETE_DELIVERY_PRODUCT_FROM_DAY_SUCCESS';
export const DELETE_DELIVERY_PRODUCT_FROM_DAY_FAIL= 'uprice/DELETE_DELIVERY_PRODUCT_FROM_DAY_FAIL';

export const UPDATE_PRODUCT_TIME= 'uprice/UPDATE_PRODUCT_TIME';
export const UPDATE_PRODUCT_TIME_SUCCESS= 'uprice/UPDATE_PRODUCT_TIME_SUCCESS';
export const UPDATE_PRODUCT_TIME_FAIL= 'uprice/UPDATE_PRODUCT_TIME_FAIL';

export const LOAD_BOTTOM_PUMP_WITH_PRODUCT= 'uprice/LOAD_BOTTOM_PUMP_WITH_PRODUCT';
export const LOAD_BOTTOM_PUMP_WITH_PRODUCT_SUCCESS= 'uprice/LOAD_BOTTOM_PUMP_WITH_PRODUCT_SUCCESS';
export const LOAD_BOTTOM_PUMP_WITH_PRODUCT_FAIL= 'uprice/LOAD_BOTTOM_PUMP_WITH_PRODUCT_FAIL';

export const ASSIGN_PUMP_TO_PRODUCT= 'uprice/ASSIGN_PUMP_TO_PRODUCT';
export const ASSIGN_PUMP_TO_PRODUCT_SUCCESS= 'uprice/ASSIGN_PUMP_TO_PRODUCT_SUCCESS';
export const ASSIGN_PUMP_TO_PRODUCT_FAIL= 'uprice/ASSIGN_PUMP_TO_PRODUCT_FAIL';

export const LOAD_TH_RESOURCE_FAMILY = 'uprince/LOAD_TH_RESOURCE_FAMILY';
export const LOAD_TH_RESOURCE_FAMILY_SUCCESS = 'uprince/LOAD_TH_RESOURCE_FAMILY_SUCCESS';
export const LOAD_TH_RESOURCE_FAMILY_FAIL = 'uprice/LOAD_TH_RESOURCE_FAMILY_FAIL';

export const GET_SMART_BOARD_COLOR_CODES = 'uprince/GET_SMART_BOARD_COLOR_CODES';
export const GET_SMART_BOARD_COLOR_CODES_SUCCESS = 'uprince/GET_SMART_BOARD_COLOR_CODES_SUCCESS';
export const GET_SMART_BOARD_COLOR_CODES_FAIL = 'uprice/GET_SMART_BOARD_COLOR_CODES_FAIL';

import React from 'react';
import { connect } from 'react-redux';
import DocumentPaneComponent from './component';
import { WithTranslation, withTranslation } from 'react-i18next';
import _ from 'lodash';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import history from '../../../history';
import { messageService } from '../../../services/messageService';

import {
  getAllProjectDropdowns,
  getProjectDefinition,
  getProjectHeaderDetails,
} from '../../../reducers/projectReducer';
import { CorporateSchedule, CorporateScheduleTime, formData } from '../../../types/corporateSchedule';
import { readByCorporateScheduleId, saveFormData } from '../../../reducers/corporateScheduleReducer';
import { v4 as uuidv4 } from 'uuid';
import { Selection } from '@fluentui/react';

export interface State {
  isDirty: boolean;
  redirect: boolean;
  loading: boolean;
  isClear: boolean;
  isEdit: boolean;
  isCostModalOpen: boolean;
  showPrint: boolean;
  isApproved: boolean;
  ledgerDropDowns: { key: string; text: string }[];
  generalLedger: string;
  resourceData: any;
  openPOResourceModal: boolean;
  uid: string | null;
  corporateSheduleTime: CorporateScheduleTime[];
}

interface Props extends WithTranslation {
  csDropDowns: any[];
  saveFormData: (data: CorporateSchedule) => void;
  getAllProjectDropdowns: () => void;
  formData: any;
  uid: string;
  showProgressBar: boolean;
  dropDowns: any[];
  isNew: boolean;
  isNewCS: boolean;
  isExpand: boolean;
  csID: string;
  readByCorporateScheduleId: (id: string) => void
}

class DocumentPane extends React.Component<Props, any> {
  subscription: any;
  submitExternal: any;
  form: any;
  private _activityTaskSelection: Selection;

  constructor(props: Props) {
    super(props);
    this.state = {
      isDirty: false,
      isEdit: false,
      redirect: false,
      loading: false,
      formData: formData,
      showPrint: false,
      isApproved: false,
      uid: null,
      isClear: false,
      corporateSheduleTime: [
        {
          id: uuidv4(),
          day: this.props.t('monday'),
          startTime: '',
          endTime: '',
          displayOrder: '1',
          corporateSheduleId: this.props.uid,
        },
        {
          id: uuidv4(),
          day: this.props.t('tuesday'),
          startTime: '',
          endTime: '',
          displayOrder: '2',
          corporateSheduleId: this.props.uid,
        },
        {
          id: uuidv4(),
          day: this.props.t('wednesday'),
          startTime: '',
          endTime: '',
          displayOrder: '3',
          corporateSheduleId: this.props.uid,
        },
        {
          id: uuidv4(),
          day: this.props.t('thursday'),
          startTime: '',
          endTime: '',
          displayOrder: '4',
          corporateSheduleId: this.props.uid,
        },
        {
          id: uuidv4(),
          day: this.props.t('friday'),
          startTime: '',
          endTime: '',
          displayOrder: '5',
          corporateSheduleId: this.props.uid,
        },
        {
          id: uuidv4(),
          day: this.props.t('saturday'),
          startTime: '',
          endTime: '',
          displayOrder: '6',
          corporateSheduleId: this.props.uid,
        },
        {
          id: uuidv4(),
          day: this.props.t('sunday'),
          startTime: '',
          endTime: '',
          displayOrder: '7',
          corporateSheduleId: this.props.uid,
        },
      ],
    };

    this._activityTaskSelection = new Selection({
      onSelectionChanged: () => {
      },
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevProps.isNew !== this.props.isNew ||
      prevProps.uid !== this.props.uid
    ) {
      if (this.props.isNew) {
        const formData = this.state.formData;
        formData.id = this.props.uid;
        this.setState({
          formData,
          isClear: false,
        });
      }
    }

    if (prevProps.formData !== this.props.formData) {
      if (!this.state.isDirty) {
        if (!_.isEmpty(this.props.formData)) {
          const formData = this.props.formData;
          this.setState({
            formData,
            isClear: false,
            corporateSheduleTime: this.props.formData.corporateSheduleTime,
          });
        }
      } else {
        if (this.props.isNew && _.isEmpty(this.props.formData)) {
          const formData = this.state.formData;
          this.setState({
            formData,
            isClear: false,
            corporateSheduleTime: this.state.formData.corporateSheduleTime,
          });
        }
      }
    }
    if (prevProps.csID !== this.props.csID) {
      if (this.props.csID) {
        this.props.readByCorporateScheduleId(this.props.csID);
      }
    }
  }

  componentDidMount() {
    // get document pane dropdowns
    //this.props.getAllProjectDropdowns();
    this.setState({ uid: uuidv4(), isClear: this.props.isNewCS });

    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewCS) {
          this.newCorporateSchedule();
        }
        if (data.data.saveCSData) {
          //   this.saveFormData();
          this.submitExternal();
          this.setState({ isClear: false });
        }
        if (data.data.csFilter) {
          this.setState({ filter: data.data.cs });
        }
        //   if (data.data.pdfGenerate) {
        //       this.setState({ showPrint: true });
        //   }
      }
    });


    // if (this.props.isNew) {
    //   this.newCorporateSchedule();
    // }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { csFilter: this.state.filter },
    });
  };

  newCorporateSchedule = () => {
    if (this.form) {
      this.form.reset();
      this.form.resetFieldState('name');
      //this.form.resetFieldState('title');
    }

    this.setState({
      //formData,
      uid: uuidv4(),
      isClear: true,
      corporateSheduleTime: [
        {
          id: uuidv4(),
          day: this.props.t('monday'),
          startTime: '',
          endTime: '',
          displayOrder: '1',
          corporateSheduleId: this.props.uid,
        },
        {
          id: uuidv4(),
          day: this.props.t('tuesday'),
          startTime: '',
          endTime: '',
          displayOrder: '2',
          corporateSheduleId: this.props.uid,
        },
        {
          id: uuidv4(),
          day: this.props.t('wednesday'),
          startTime: '',
          endTime: '',
          displayOrder: '3',
          corporateSheduleId: this.props.uid,
        },
        {
          id: uuidv4(),
          day: this.props.t('thursday'),
          startTime: '',
          endTime: '',
          displayOrder: '4',
          corporateSheduleId: this.props.uid,
        },
        {
          id: uuidv4(),
          day: this.props.t('friday'),
          startTime: '',
          endTime: '',
          displayOrder: '5',
          corporateSheduleId: this.props.uid,
        },
        {
          id: uuidv4(),
          day: this.props.t('saturday'),
          startTime: '',
          endTime: '',
          displayOrder: '6',
          corporateSheduleId: this.props.uid,
        },
        {
          id: uuidv4(),
          day: this.props.t('sunday'),
          startTime: '',
          endTime: '',
          displayOrder: '7',
          corporateSheduleId: this.props.uid,
        },
      ],
    });
  };

  setSubmitExternal = (submit: any) => {
    this.submitExternal = submit;
  };

  setForm = (form: any) => {
    this.form = form;
  };

  onChangeStartTime = (index: number, value: any) => {
    if (
      this.state.corporateSheduleTime &&
      !_.isEmpty(this.state.corporateSheduleTime) &&
      this.state.corporateSheduleTime.length > index
    ) {
      let corporateSheduleTime = this.state.corporateSheduleTime;
      let task = corporateSheduleTime[index];
      task.startTime = value;
      this.setState(
        (prevState: any) => ({
          corporateSheduleTime,
          isClear: false,
        }),
        () => {
        },
      );
    }
  };

  onChangeEndTime = (index: number, value: any) => {
    if (
      this.state.corporateSheduleTime &&
      !_.isEmpty(this.state.corporateSheduleTime) &&
      this.state.corporateSheduleTime.length > index
    ) {
      let corporateSheduleTime = this.state.corporateSheduleTime;
      let task = corporateSheduleTime[index];
      task.endTime = value;
      this.setState(
        (prevState: any) => ({
          corporateSheduleTime,
          isClear: false,
        }),
        () => {
        },
      );
    }
  };

  render() {
    return (
      <div style={{ width: '100%' }}>
        <RouteLeavingGuard
          when={this.state.isDirty}
          navigate={(path) => this.handleDiscardChangesNavigate(path)}
          shouldBlockNavigation={() => {
            return true;
          }}
          handleConfirmation={(isConfirm: boolean) => {
            this.setState({ isDirty: !isConfirm });
          }}
        />
        <DocumentPaneComponent
          submit={(submit: any) => {
            this.setSubmitExternal(submit);
          }}
          activityTaskSelection={this._activityTaskSelection}
          dropDowns={this.props.dropDowns}
          saveFormData={this.props.saveFormData}
          uid={this.state.uid}
          formData={this.props.formData}
          showProgress={this.props.showProgressBar}
          formValues={this.form?.getState()?.values}
          isClear={this.state.isClear}
          isExpand={this.props.isExpand}
          onChangeStartTime={(index: number, value: string | number) =>
            this.onChangeStartTime(index, value)
          }
          onChangeEndTime={(index: number, value: string | number) =>
            this.onChangeEndTime(index, value)
          }
          setForm={(form: any) => {
            this.setForm(form);
          }}
          corporateSheduleTime={this.state.corporateSheduleTime}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isEdit: state.cs.isEdit,
    isNew: state.cs.isNew,
    uid: state.cs.uid,
    projectHeader: state.project.projectHeader,
    showProgressBar: state.cs.showProgressBar,
    selectedProject: state.project.selectedProject,
    dropDowns: state.cs.csDropDowns,
    csID: state.cs.csID,
  };
};

const mapDispatchToProps = {
  saveFormData,
  getProjectHeaderDetails,
  getAllProjectDropdowns,
  getProjectDefinition,
  readByCorporateScheduleId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(DocumentPane));

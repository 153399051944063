import React, { Component } from 'react';
import { connect } from 'react-redux';
import Brand from '../../shared/brand/brand';
import ProjectBar from '../../shared/projectBar/projectBar';
import CUNavBar from '../../shared/navBar/cuNavBar';
import ProductCatalogueMainLayout from '../productCatalogue/productCatalogueMainLayout';
import { getContractingUnit } from '../../shared/util';


export class CUProductCatalogue extends Component<any, any> {
  render() {
    return (
      <div style={{ height: '100%' }}>
        <Brand/>
        <ProjectBar/>
        <CUNavBar selectedKey={`/CU/${getContractingUnit()}/product-catalogue`} currentUser={this.props.currentUser}/>
        <ProductCatalogueMainLayout/>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CUProductCatalogue);

import {IColumn, Icon, mergeStyleSets} from '@fluentui/react';
import moment from 'moment';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {uPrinceTheme} from '../../theme';
import ENV from '../../env.json';
import {useSelector} from "react-redux";

export class ContractListPaneFilter {
  title: string | null = null;
  dateOfStatusChange: string | Date | null = null;
  statusId: string | null = null;
  price: number | null = null;
  sorter: Sorter = new Sorter();
}

export interface ContractShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
  value: number | null
}

class Sorter {
  attribute: string | null = null;
  order: string | null = null;
}

export class ContractListItem {
  id: string | null = null;
  title: string | null = null;
  status: string | null = null;
  price: string | null = null;
  statusId: string | null = null;
  sequenceId: string | null = null;
  contractId: string | null = null;
  contractSequenceId: string | null = null;
  type: string | null = null;
  statusChangeDate: string | null | undefined = null;
}

export interface DropDown {
  key: string;
  text: string;
}

export interface ContractDropDowns {
  status: DropDown[];
  types: DropDown[];
}

export interface Contract {
  id: string | null;
  title: string;
  sequenceId: string
}

export interface ContractDetails {
  id: string | null;
  sequenceId: string | null;
  title: string | null;
  name: string | null;
}

export interface History {
  createdByUser: string | null;
  createdDateTime: string | null;
  revisionNumber: number | string | null;
  updatedByUser: string | null;
  updatedDateTime: string | null;
}

export const formData = {
  id: null,
  title: 'string',
  sequenceId: 'string',
};

export type ContractorTeamDTO = {
  id: string | null;
  person: string | undefined;
  role: string | null;
  company: string | null;
};

export type DocumentationDTO = {
  id: string | null;
  title: string | null;
  family: string | null;
  links: string | null;
};

export class DocumentationLink {
  id: string | null = null;
  link?: string | null = null;
  lotId: string | null = null;
  title: string | null = null;
  typeId: string | null = null;
  typeName: string | null = null;
}

export class ConstructorList {
  id: string | null = null;
  contractorLot: string | null = null;
  contractorTeamList: null[] = [];
}

export enum Status {
  In_Preparation = 'd60aad0b-2e84-482b-cowf-618d80d49477',
  In_Tendering = '94282458-0b40-40a3-cowf-c2e40344c8f1',
  In_Price_Comparison = '7143ff01-d173-4a20-cowf-cacdfecdb84c',
  In_Execution = '8e8c4e8d-7bcb-487d-cowf-6b91c89fc3da',
  In_Commisioning = '487d7e8d-8e8c-bcb4-cowf-6b91c89fc3da'
};

export enum ItemType {
  Lot = '2210e768-msms-Item-Lot1-ee367a82ad22'
};

export enum FileType {
  PDF = 'oo10e768-msms-po02-Lot1-ee367a82adooo',
  IMAGE = 'oo10e768-msms-po02-Lot2-ee367a82adooo',
  URL = '2210e768-msms-po02-Lot3-ee367a82ad22',
  WORD_DOCUMENT = '2210e768-msms-po02-Lot4-ee367a82ad22',
  EXCEL_DOCUMENT = 'kkkke768-msms-po02-Lot5-ee367a82ad22',
  ZIP = '2210e768-msms-po02-Lot5-ee367a82ad22'
};

export const contractInitialFormData = {
  id: null,
  name: '',
  productItemTypeId: ItemType.Lot,
  statusId: Status.In_Preparation,
  division: null,
  expectedNumberOfQuotes: '',
  contractTaxonomyId: '',
  tenderBudget: '',
  customerBudget: '',
  contractorList: {
    id: null,
    contractorLot: '',
    contractorTeamList: [],
  },
  lotDocumentation: {
    technicalInstructions: [],
    tenderDocuments: [],
    provisionalAcceptance: [],
    finalDelivery: [],
  },
  technicalDocumentation: {
    technicalDocList: [],
  },
  publishTender: {
    tenderStatus: false,
    tenderItems: [],
  },
  // tenderAwarding: [],
  timetableLot: [],
  // startDate: undefined,
  // endDate: undefined
};

export const constructorInitialFormData = {
  id: null,
  division: null,
  typeId: null,
  contractor: null,
  price: null,
  accreditationAndSupplier: {
    contractorSupplierList: [],
    contractorAccreditation: [],
  },
  timeTable: [],
};

export const teamsColumns = () => {
  const { t } = useTranslation();
  let teamsColumns = [];

  teamsColumns = [
    {
      key: 'column1',
      name: t('name'),
      fieldName: 'cabPersonName',
      minWidth: 120,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('company'),
      fieldName: 'company',
      minWidth: 120,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('role'),
      fieldName: 'roleName',
      minWidth: 120,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: 'column4',
      name: t('invitationMail'),
      fieldName: 'invitationMail',
      minWidth: 120,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: any) => {
        if (item?.invitationMail) {
          return t('sent');
        } else {
          return t('notSent');
        }
      },
    },
  ];

  return teamsColumns;
};

export const timeTableColumns = () => {
    const {t} = useTranslation();
    let timeTableColumns: IColumn[] = [];
    const selectedLotType = useSelector((state: any) => state?.contract?.selectedLotType);

    if (selectedLotType === CONTRACT) {
        timeTableColumns = [
            {
                key: 'column1',
                name: t('dateTime'),
                fieldName: 'dateTime',
                minWidth: 165,
                maxWidth: 200,
                isResizable: true,
                isSorted: false,
                onRender: (item: any) => {
                    let formatDate;
                    if (item?.dateTime) {
                        formatDate = moment.utc(item?.dateTime).local().format('DD/MM/YY  HH:mm');
                    } else {
                        formatDate = '';
                    }

                    return <span>{formatDate}</span>;
                },
            },
            {
                key: 'column2',
                name: t('status'),
                fieldName: 'statusName',
                minWidth: 165,
                maxWidth: 200,
                isResizable: true,
                isSorted: false,
            },
        ];
    } else {
        timeTableColumns = [
            {
                key: 'column1',
                name: t('dateTime'),
                fieldName: 'dateTime',
                minWidth: 165,
                maxWidth: 200,
                isResizable: true,
                isSorted: false,
                onRender: (item: any) => {
                    let formatDate;
                    if (item?.dateTime) {
                        formatDate = moment.utc(item?.dateTime).local().format('DD/MM/YY  HH:mm');
                    } else {
                        formatDate = '';
                    }

                    return <span>{formatDate}</span>;
                },
            },
            {
                key: 'column2',
                name: t('status'),
                fieldName: 'statusName',
                minWidth: 165,
                maxWidth: 200,
                isResizable: true,
                isSorted: false,
            },
            {
                key: 'column3',
                name: t('subscriptionComment'),
                fieldName: 'subscriptionComment',
                minWidth: 165,
                maxWidth: 200,
                isResizable: true,
                isSorted: false,
            },
        ];
    }

    return timeTableColumns;
};

export const publishTenderColumns = () => {
  const { t } = useTranslation();
  let publishTenderColumns: IColumn[] = [];

  publishTenderColumns = [
    {
      key: 'column1',
      name: t('tenderText'),
      fieldName: 'tenderText',
      minWidth: 165,
      maxWidth: 200,
      isResizable: true,
      isSorted: true,
    },
    {
      key: 'column2',
      name: t('tenderStatus'),
      fieldName: 'tenderStatus',
      minWidth: 165,
      maxWidth: 200,
      isResizable: true,
      isSorted: true,
    },
  ];

  return publishTenderColumns;
};

export const awardColumns = (statusId: string) => {
  const { t } = useTranslation();
  let awardColumns = [];

  awardColumns = [
    {
      key: 'column1',
      name: t('contractor'),
      fieldName: 'companyName',
      minWidth: 120,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('price'),
      fieldName: 'totalBAFO',
      minWidth: 120,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: any) => {
        let formatter = new Intl.NumberFormat(window.navigator.language, {
          style: 'currency',
          currency: 'EUR',
        });
        return <div className="find me" style={{ maxWidth: 200, display: 'flex', justifyContent: 'flex-end' }}>
          {item?.totalBAFO && <span>{formatter.format(item?.totalBAFO)}</span>}
        </div>
      }
    },
    {
      key: 'column3',
      name: t('awardNotAward'),
      fieldName: 'isWinner',
      minWidth: 120,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: any) => {
        if (statusId === Status.In_Execution) {
          if (item?.isWinner) {
            return t('awarded');
          }
          return t('notAwarded');
        } else {
          return t('-');
        }
      },
    },
  ];

  return awardColumns;
};

export const accreditationsColumns = () => {
  const { t } = useTranslation();
  let accreditationsColumns = [];

  accreditationsColumns = [
    {
      key: 'column1',
      name: t('contractor'),
      fieldName: 'cabPersonName',
      minWidth: 120,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('skill'),
      fieldName: 'skill',
      minWidth: 120,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('experience'),
      fieldName: 'experienceName',
      minWidth: 120,
      maxWidth: 300,
      isResizable: true,
    },
  ];

  return accreditationsColumns;
};

export const supplierListColumns = () => {
  const { t } = useTranslation();
  let supplierListColumns = [];

  supplierListColumns = [
    {
      key: 'column1',
      name: t('contractor'),
      fieldName: 'cabPersonName',
      minWidth: 120,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('fileName'),
      fieldName: 'fileName',
      minWidth: 120,
      maxWidth: 300,
      isResizable: true,
    },
  ];

  return supplierListColumns;
};

export const documentationColumns = () => {
  const { t } = useTranslation();
  let documentationColumns = [];

  const getIconName = (item: any) => {
    switch (item?.typeName) {
      case 'pdf':
        return 'PDF';
      case 'Image':
        return 'FileImage';
      case 'URL':
        return 'Website';
      case 'Word Document':
        return 'WordDocument';
      case 'Excel':
        return 'ExcelDocument';
      case 'ZIP':
        return 'ZipFolder';
      default:
        return 'Website';
    }
  };

  const style = mergeStyleSets({
    inLine: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridGap: '2px',
      gridAutoRows: 'minMax(10px, auto)',
    },
  });

  documentationColumns = [
    {
      key: 'column1',
      name: t('title'),
      fieldName: 'title',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('type'),
      fieldName: 'typeName',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('links'),
      fieldName: 'link',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: DocumentationLink) => {
        return (
          <div>
            <Icon
              style={{ cursor: 'pointer', color: uPrinceTheme.palette.themePrimary }}
              iconName={getIconName(item)}
              onClick={(event) => {
                event.preventDefault();
                window.open(item.link!!);
              }}
            />
          </div>
        );
      },
    },
  ];

  return documentationColumns;
};

export const CONTRACTOR = 'Contractor';
export const CONTRACT = 'Contract';
export const AWARDED_STATUS_ID = 'nnnnad0b-2e84-con1-ad25-Lot0d49477';

export const UPLOAD_URL = ENV.UPLOAD_URL;

export enum ContractorStatus {
  Added_to_Contract = '4010e768-3e06-added-b337-Lota82addb'
};

import React from 'react';
import { Link } from 'react-router-dom';
import { uPrinceTheme } from '../../theme';

class NotFoundPage extends React.Component {
  render() {
    return (
      <div>
        <p style={{ textAlign: 'center', color: uPrinceTheme.palette.themePrimary, fontSize: 24 }}> Uprince</p>
        <p style={{ textAlign: 'center', color: '#000000', fontSize: 18 }}>Page not found</p>
        <p style={{ textAlign: 'center' }}>
          <Link to="/">Go to Home </Link>
        </p>
      </div>
    );
  }
}

export default NotFoundPage;

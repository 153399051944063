import React, {useRef,} from 'react';
import {useDrag} from 'react-dnd';

const GridDragBox = (props: {
  children: any;
  item: any
  disabled?: boolean
}) => {
  const [dropped, setDropped] = React.useState(false)
  const nonDragRef = useRef<HTMLDivElement>(null);

  const [collectedProps, dragSourceRef, dragPreviewRef] = useDrag({
    type: 'PMOL',
    item: { type: 'PMOL', dragItem: props.item },
    end: (item: any, monitor: { getDropResult: () => any; }) => {
      const dropResult = monitor.getDropResult()

      if (item && dropResult) {
        setDropped(true)
      }
    }
  })

  return (
    dropped ? <div></div> : <div
      ref={props.disabled ? nonDragRef : dragSourceRef}
      key={Math.random()}
    >
      {props.children}
    </div>
  )
}

export default GridDragBox;

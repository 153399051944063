import React from 'react';
import {
  ContextualMenu, FontIcon,
  FontWeights,
  getTheme,
  IconButton,
  IDragOptions,
  IIconProps,
  mergeStyleSets,
  Modal,
  PrimaryButton,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../../theme';
import { useBoolean, useId } from '@uifabric/react-hooks';
import i18n from '../../../../i18n';
import { PDFViewer } from '@react-pdf/renderer';
import PdfTemplate from './pdfTemplate';
import { store } from '../../../../index';
import { useDispatch } from 'react-redux';
// import PdfTemplate from './pdfTemplate_new';

const theme = getTheme();
const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const styles = {

  acceptButton: {
    padding: 30,
    fontSize: 30,
    textAlign: 'center',
    alignItems: 'center',
    paddingTop: 30,
    marginTop: 30,
    marginRight: 30,
  },

  cancelButton: {
    fontSize: 30,
  },

  text: {
    fontSize: 30,
  }
};

const isMobile = screen.width < 768;

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };

const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};

const downloadIconWhite: IIconProps = {
  iconName: 'Download',
  styles: addWhiteIconButtonStyles,
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    paddingTop: 20,
    minWidth: '45vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'none',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },

  saveButton : {
    textAlign: 'center',
    marginBottom: 40,
  }
});
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const PdfModal=(props:{isOpenPdfModal:boolean,onCloseModal:any,onSave:any,signature:any,fullName:any})=>{
  const dispatch = useDispatch();
  const [isDraggable] = useBoolean(false);
  const titleId = useId('title');

  const onCloseModal=()=>{
    props.onCloseModal();
  }
  const onSave=()=>{
    props.onSave();
    props.onCloseModal();
  }

  return     <div>
    <Modal
      titleAriaId={titleId}
      isOpen={props.isOpenPdfModal}
      onDismiss={() => {
        onCloseModal()
      }}
      scrollableContentClassName="body"
      isBlocking={true}
      dragOptions={isDraggable ? dragOptions : undefined}
      styles={ isMobile ? {
        main: {
          maxWidth: '100%',
          width: '100%',
          height: '100%',
        }
      } : undefined
      }
    >
      <div className={contentStyles.header}>
        <span style={isMobile ? styles.text : undefined} id={titleId}> {i18n.t('acceptAndSign')}</span>
        <IconButton
          styles={cancelIconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={() => {
            onCloseModal()
          }}
          style={isMobile ? { padding: 30 } : undefined}
          onRenderIcon={(props) => {
            return (
              <div style={isMobile ? styles.cancelButton : undefined}>
                <FontIcon
                  {...props}
                  aria-label="Warning"
                  iconName="Cancel"
                />
              </div>
            );
          }}
        />
      </div>
      <div className={contentStyles.body}>
        {JSON.stringify(store.getState().po?.openPdfModal)}
        <div className="proj-detail-content inner">
          <PDFViewer style={isMobile ? { width: '100%', height: 'calc(100vh - 400px)' } : { width: '100%', height: '70vh' }}>
            {PdfTemplate(props.signature,props.fullName)}
          </PDFViewer>
        </div>
      </div>
      <div className={isMobile ? contentStyles.saveButton : contentStyles.footer}>
        <PrimaryButton
          iconProps={addIconWhite}
          text={i18n.t('sendEmail')}
          style={isMobile ? styles.acceptButton as any : { marginTop: 20, marginBottom: 30, marginRight: 20 }}
          onClick={() => {
            onSave()
          }}
          disabled={false}
          onRenderIcon={(props) => {
            return (
              <div style={isMobile ? styles.cancelButton : undefined}>
                <FontIcon
                  aria-label="Add"
                  iconName="Add"
                />
              </div>
            );
          }}
        />
      </div>
    </Modal>
  </div>
}

export default PdfModal
import React, { useEffect, useState } from 'react';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';
import { useStore } from 'react-redux';
import { APPROVED_STATUS_ID, HANDED_OVER_STATUS_ID, IN_DEVELOPMENT_STATUS_ID, IN_REVIEW_STATUS_ID, PENDING_DEVELOPMENT_STATUS_ID } from '../../../types/projectMolecule';
import { isTeamPlanboardMyEnv } from '../../../shared/util';
import { GET_PMOL_PRODUCT_HISTORY_INTERVENTIONS } from '../../../shared/endpoints';
import client from '../../../api';
import { FontWeights, getTheme, IconButton, IIconProps, mergeStyleSets, Modal, Spinner, SpinnerSize } from '@fluentui/react';
import { uPrinceTheme } from '../../../../theme';

const theme = getTheme()

const contentStyles = mergeStyleSets({
    header: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '2px 2px 2px 10px',
        backgroundColor: uPrinceTheme.palette.themePrimary,
        fontSize: 18,
        color: 'white',
      },
    ],
    modalContainer: {
      width: '80%',
      height: '75%'
    },
    previewContent: {
      width: '100%',
      textAlign: 'center'
    }
  });
  const cancelIcon: IIconProps = { iconName: 'Cancel' };
  const cancelIconButtonStyles = {
    root: {
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
      color: 'white',
    },
    rootHovered: { color: theme.palette.neutralDark },
  };
  
  const styles = mergeStyleSets({
    iframe: {
      width: '100%',
      height: '100%'
    },
    centered: {
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center',
      height: '100%'
    },
  })

const CommandBarRight = () => {
    const { t } = useTranslation();
    const newClick = () => {
        messageService.sendMessage({ isNewMYDP: true });
        // props.createNew(true);
    };
    const saveFormData = () => {
        messageService.sendMessage({ saveMYDPData: true });
    };
    const handleRemove = () => {
        // props.handleRemove();
    };

    const [historyDataRetrieve, sethistoryDataretrieve] = useState<any>(null)
    const [showPreview, setShowPreview] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false);
    const [disableConditionForHistory, setDisableConditionForHistory] = useState(true)
    
    const store = useStore();
    const isApprovedPmol = (store.getState()?.pmol?.formData?.statusId == APPROVED_STATUS_ID || store.getState()?.pmol?.formData?.statusId == HANDED_OVER_STATUS_ID)
    const isLoading = (store.getState()?.pmol?.showProgressBar)

    useEffect(() => {
        if (store.getState()?.pmol?.formData?.id) {
            getHistoryInfoPmol(store.getState()?.pmol?.formData?.id)
        }
    }, [store.getState()?.pmol?.formData?.id])
    
    const getHistoryInfoPmol = async (pmolId: any) => {
        const payload = {
            id: pmolId,
            PbsId: null
          }
          if (payload.id) {
            try {
              setLoading(true)
              const response = await client.post(GET_PMOL_PRODUCT_HISTORY_INTERVENTIONS, payload)
              if (response.status === 200) {
                sethistoryDataretrieve(response?.data?.result)
                setDisableConditionForHistory(false)
              }
            } catch (error) {
              console.log('error', error)
              setDisableConditionForHistory(true)
            } finally {
              setLoading(false)
            }
          }
    }
    
    const isApproveEnable = () => {
        const pmolStatus = store.getState()?.pmol?.formData?.statusId;
        const approvedStatuses = [IN_REVIEW_STATUS_ID, IN_DEVELOPMENT_STATUS_ID, PENDING_DEVELOPMENT_STATUS_ID];
        return approvedStatuses.includes(pmolStatus);
    }

    const getItems = () => {
        return [
            {
                key: 'new',
                text: t('new'),
                iconProps: { iconName: 'Add' },
                split: true,
                subMenuProps: {
                    items: [
                        { key: 'regular',
                            text: t('clientName'),
                            iconProps: { iconName: 'Add' },
                            // onClick: () => handleRegular()
                        },
                        {
                            key: 'subContract',
                            text: t('subContract'),
                            iconProps: { iconName: 'Add' },
                            // onClick: () => handleSubContract(),
                        },
                    ],
                },
                disabled: true,
                // onClick: () => handleRegular(),
            },
            {
                key: 'save',
                text: t('save'),
                iconProps: { iconName: 'Save' },
                onClick: () => { saveFormData(); },
                disabled: isApprovedPmol || isLoading,
            },
            {
                key: 'saveAndClose',
                text: t('saveAndClose'),
                iconProps: { iconName: 'SaveAndClose' },
                onClick: () => {
                    saveFormData();
                    messageService.sendMessage({ hideDocumentPane: true, documentPaneType: 1 });
                },
                disabled: isApprovedPmol || isLoading,
            },
            {
                key: 'Remove',
                onClick: () => { handleRemove(); },
                name: t('remove'),
                iconProps: { iconName: 'Delete' },
                disabled: true
            },
            {
                key: 'approve',
                text: t('approve'),
                iconProps: { iconName: 'ActivateOrders' },
                onClick: () => {
                    messageService.sendMessage({ approvePmol: true });
                    if(isTeamPlanboardMyEnv()){
                        messageService.sendMessage({ reloadList: true });
                    }
                },
                disabled: !isApproveEnable() || isLoading,
            },
            {
                key: 'export',
                text: t('export'),
                iconProps: { iconName: 'Download' },
                split: true,
                subMenuProps: {
                    items: [
                        {
                            key: 'exportNl',
                            text: t('exportDutch'),
                            iconProps: { iconName: 'Download' },
                            onClick: () => {
                                messageService.sendMessage({ exportPmolNL: true });
                            },
                        },
                        {
                            key: 'exportEn',
                            text: t('exportEnglish'),
                            iconProps: { iconName: 'Download' },
                            onClick: () => {
                                messageService.sendMessage({ exportPmolEN: true });
                            },
                        },
                    ],
                },
                onClick: () => {
                    messageService.sendMessage({ exportPmolNL: true });
                },
            },
            {
                key: 'history',
                text: t('history'),
                iconProps: { iconName: 'ClockIcon' },
                onClick: () => {
                    if (historyDataRetrieve) {
                        setShowPreview(true)
                    }
                },
                disabled: disableConditionForHistory,
            },
        ];
    };

    return (
        <div>
            <CommandBar
                items={getItems()}
                ariaLabel="Use left and right arrow keys to navigate between commands"
            />
            <Modal
                titleAriaId={''}
                isOpen={showPreview}
                onDismiss={() => {
                setShowPreview(false);
                }}
                isBlocking={true}
                containerClassName={contentStyles.modalContainer}
                className='custom-preview-modal-for-certifications'
            >
                <div className={contentStyles.header}>
                <span>{t('preview')}</span>
                <IconButton
                    styles={cancelIconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={() => {
                    setShowPreview(false);
                    }}
                />
                </div>
                {loading ? 
                <div className={styles.centered}><Spinner size={SpinnerSize.large} /></div> 
                : <iframe src={historyDataRetrieve} title="PDF Preview" className={styles.iframe} />
                }
            </Modal>
        </div>
    );
};

export default CommandBarRight;

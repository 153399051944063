import React, { Component } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import './projectBar.css';
import history from '../../history';
import { getAllProjectsWaypoints } from '../../reducers/projectReducer';
import { getLastSeenProjects, saveWayPointStatus } from '../../reducers/cuProjectReducer';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getContractingUnit, getProject } from '../util';
import { iconColors, uPrinceTheme } from '../../../theme';
import AuthService from '../../services/auth.service';
import { saveStateAttr } from './../../reducers/uprinceReducer';
import { messageService } from '../../services/messageService';
import _ from 'lodash';
import { TooltipHost } from '@fluentui/react';
import { saveMyDayPlanningStateAttr } from '../../reducers/myDayPlanningReducer';

const pivotStyle = { root: { float: 'left' }, icon: { color: iconColors.waypointIconColor } };

interface Props {
  getAllProjectsWaypoints: any;
  wayPoints: any;
  getLastSeenProjects: any;
  contractingUnit: any;
  saveStateAttr: any
  selectedProject: any;
  saveWayPointStatus: any;
  wayPointsShouldUpdate: boolean;

  saveMyDayPlanningStateAttr: any;
}

const styles = {
  hoverWaypoint: {
    alignSelf: 'center',
    color: uPrinceTheme.palette.themeDarker,
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: '22px',
    marginRight: '15px',
    transition: 'all 0.2s',
    marginLeft: '10px',
    '&:hover': { background: uPrinceTheme.palette.themeSecondary },
  },
  Waypoint: {
    alignSelf: 'center',
    color: uPrinceTheme.palette.themePrimary,
    fontSize: '22px',
    cursor: 'pointer',
    textDecoration: 'none',
    marginRight: '15px',
    marginLeft: '10px',
    transition: 'all 0.2s',
    '&:hover': { background: uPrinceTheme.palette.themeSecondary },
  },
};

class ProjectBar extends Component<Props, any> {
  authService: AuthService;
  subscription: any;

  constructor(props: Props) {
    super(props);
    this.authService = new AuthService();
    this.state = {
      projectCard: '',
      wayPoints: [],
      hover: false,
    };
  }

  componentDidMount() {
    if (!getProject() && _.isEmpty(this.props.wayPoints)) {
      this.props.getLastSeenProjects();
    }
    // this.props.getLastSeenProjects();
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.waypointRefresh) {
          // this.props.getLastSeenProjects();
        }
      }
    });
    // this.checkIsLogged()
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.wayPointsShouldUpdate !== prevProps.wayPointsShouldUpdate && this.props.wayPointsShouldUpdate) {
      this.props.getLastSeenProjects();
      setTimeout(() => {
        this.props.saveWayPointStatus(false);
      }, 500);

    }
    // if (getProject() && this.props.selectedProject && this.props.selectedProject?.sequenceCode !== getProject()) {
    //     this.props.getLastSeenProjects();
    // } else if ( Array.isArray(this.props.selectedProject) && !_.isEmpty(this.props.selectedProject) && this.props.selectedProject?.sequenceCode !== prevProps.selectedProject?.sequenceCode)  {
    //     this.props.getLastSeenProjects();
    // }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  onIconClick = () => {
    if (this.props.contractingUnit) {
      history.push(`/CU/${this.props.contractingUnit.title}`);
    }
  };

  handelRedirect = (item: any) => {
    history.replace(item);
    // window.location.reload();
  };

  renderProjectCard = () => {
    const size = 9;
    const ProjectCard = this.props.wayPoints
      .slice(0, size)
      .map((el: any) => {
        const url = `/CU/${el.contractingUnitId}/project/${el.sequenceCode}/project-planning`;
        return (
          <PivotItem
            key={el.sequenceCode}
            itemKey={url}
            headerText={el.sequenceCode}
            itemIcon="FabricFolderFill"
            onRenderItemLink={() =>
              <TooltipHost
                content={<span>{el.projectName}<br></br>{el.projectManager}</span>}
              >
                {
                  <div style={{display: 'flex'}}>
                    <Icon iconName="FabricFolderFill" style={{ paddingRight: "4px", color: iconColors.waypointIconColor }} />
                    {el.sequenceCode}
                  </div>
                }
              </TooltipHost>}

          ></PivotItem>
        );
      });
    return (
      <Pivot
        styles={pivotStyle}
        selectedKey={getProject() ? `/CU/${getContractingUnit()}/project/${getProject()}/project-planning` : null}
        onLinkClick={(item) => {
          item ? this.handelRedirect(item.props.itemKey) : null;
            this.props.saveMyDayPlanningStateAttr('selectedDate', undefined);
        }}
      >
        {ProjectCard}
      </Pivot>
    );
  };

  render() {
    return (
      <div className="projectBar" style={{ height: 44 }}>
        <Icon
          iconName="POISolid"

          onClick={() => {
            this.onIconClick();
          }}
          onMouseEnter={() => {
            this.setState({ hover: true });
          }}
          onMouseLeave={() => {
            this.setState({ hover: false });
          }}
          style={{ ...(this.state.hover ? styles.hoverWaypoint : styles.Waypoint) }}
        />
        <div>
          { /* {JSON.stringify(this.props.allProjects)}*/}
          {this.props.wayPoints && this.props.wayPoints.length > 0
            ? this.renderProjectCard()
            : ''}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    wayPoints: state.cuProject.wayPoints,
    selectedProject: state.project.selectedProject,
    wayPointsShouldUpdate: state.cuProject.wayPointsShouldUpdate,
    organisation: state.uprince.organisation,
  };
};

const mapDispatchToProps = {
  getAllProjectsWaypoints,
  getLastSeenProjects,
  saveStateAttr,
  saveWayPointStatus,
  saveMyDayPlanningStateAttr,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectBar),
);



import * as React from 'react';
import { useEffect, useState } from 'react';
import { PrimaryButton } from '@fluentui/react';
import DefaultCustomModal from '../../customeModal/defaultCustomModal';
import i18n from 'i18next';
import { useBoolean } from '@fluentui/react-hooks';
import { messageService } from '../../../services/messageService';
import { addIconWhite } from '../../../components/wbsCreate/documentPane/WbsCreateDocument';
import DatePickerF9 from '../../fluentUI9/Datepicker/DatePicker';
import { useDispatch } from 'react-redux';
import { saveTaskModalDate } from '../../../reducers/wbsTaskReducer';

const WbsTaskDatePickerModal = () => {
  const dispatch = useDispatch();

  const [isOpen, { setTrue: openModal, setFalse: closeModal, toggle: setModalVisible }] = useBoolean(false);
  const [taskFormData, setTaskFormData] = useState<any>();

  useEffect(() => {

    const subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isOpenTaskDateModal) {
          openModal();
        }
        if (data.data.selectedTask) {
          setTaskFormData(data.data.selectedTask);
        }
      }
    });

    return () => {
      console.log('Component date picker modal unmounted');
      subscription.unsubscribe();
    };
  }, []);

  const onRenderFooter = () => (
    <PrimaryButton
      iconProps={addIconWhite}
      text={i18n.t('save')}
      disabled={!taskFormData?.id}
      onClick={() => {
        Promise.all([dispatch(saveTaskModalDate({
          taskId: taskFormData?.id,
          date: taskFormData?.date,
        }))]).then((res: any) => {
          // console.log(res);
          if (res[0]?.payload?.data?.result) {
            closeModal();
          }
        });
      }}
    />
  );

  return (
    <>
      <DefaultCustomModal
        isModalOpen={isOpen}
        title={taskFormData?.name ?? ''}
        onRenderFooter={onRenderFooter}
        handleClose={closeModal}
      >
        <div style={{ padding: '10px 0 0 0', margin: '0 0 16px 0' }}>
          <DatePickerF9
            label={i18n.t('date')}
            placeholder={i18n.t('date')}
            selectedDate={taskFormData?.date}
            onChange={(date: any) => {
              // console.log(date);
              const newTaskFormData = { ...taskFormData, date };
              // console.log(newTaskFormData);
              setTaskFormData(newTaskFormData);

              return date;
            }}
            // errMsg={meta.touched && meta.error && !meta.submitting ? meta.error : null}
          />

          {/*<div style={{ width: 500, height: 600, overflow: 'auto' }}>*/}
          {/*  <pre>  {JSON.stringify(taskFormData, null, 2)}</pre>*/}
          {/*</div>*/}
        </div>
      </DefaultCustomModal>
    </>
  );

};

export default WbsTaskDatePickerModal;
import {ITextFieldStyles, TextField} from '@fluentui/react/lib/TextField';
import {ILabelStyleProps, ILabelStyles} from '@fluentui/react/lib/Label';
import * as React from 'react';
import {iconColors, uPrinceTheme} from '../../../theme';

const MultiLineTextFieldWrapper: React.FC<any> = (
  {
      input: {name, onChange, value, ...restInput},
      meta,
      label,
      disabled,
      rows,
      ...rest
  }) => {
    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

    return (
      <TextField
        {...rest}
        {...restInput}
        name={name}
        label={label}
        helperText={showError ? meta.error || meta.submitError : undefined}
        errorMessage={showError ? meta.error : ''}
        inputProps={restInput}
        onChange={onChange}
        value={value}
        style={{color: rest.hasChanged && iconColors.iconActiveColor}}
        styles={disabled ? getDisabledStyles : rest.hasChanged && getStyles}
        disabled={disabled}
        multiline
        rows={rows}
      />
    );
};

function getStyles(): Partial<ITextFieldStyles> {   //props: ITextFieldStyleProps
    return {
        fieldGroup: [
            {borderColor: iconColors.iconActiveColor},
        ],
        subComponentStyles: {label: getLabelStyles},
    };
}

function getLabelStyles(): ILabelStyles {   //props: ILabelStyleProps
    return {root: {color: iconColors.iconActiveColor}};
}

function getDisabledStyles(): Partial<ITextFieldStyles> {   //props: ITextFieldStyleProps
    return {
        field: {
            color: uPrinceTheme.palette.themePrimary,
        },
        subComponentStyles: {label: getDisabledLabelStyles},
    };
}

function getDisabledLabelStyles(props: ILabelStyleProps): ILabelStyles {
    const {disabled} = props;
    return {
        root: disabled && {
            color: 'black',
        },
    };
}

export default MultiLineTextFieldWrapper;
import React, { Component } from 'react';
import CiawMainLayout from './ciawMainLayout';
import { getContractingUnit } from '../../shared/util';
import UprinceNavigation from '../../shared/uprinceNavigation/cuProjectNavigation/component';

interface Props {
  currentUser: any
}

interface State {
  isContractUnit: boolean;
  isProject: boolean;
}
export class Ciaw extends Component<Props, State> {
  render() {
    return <UprinceNavigation
                currentUser={this.props.currentUser}
                selectedNavigationIndex={`/CU/${getContractingUnit()}/time_clock`}
            >
                <CiawMainLayout />
            </UprinceNavigation>
  }
}

import { Dropdown, IDropdownOption } from '@fluentui/react';
import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// import 'style.css';
import { ViewMode } from '../../../shared/ganttv2';
import { RESOURCE_TYPE_IDS } from '../../../shared/util';
import { saveVPStateAttr } from '../../../reducers/visualPlanReducer';
import ModeSwitcher from '../../../shared/ganttViewSwitcher/viewSwitcher';

type ViewSwitcherProps = {
  isChecked: boolean;
  onViewListChange: (isChecked: boolean) => void;
  onViewModeChange: (viewMode: ViewMode) => void;
  renderFilterComponent: any;
};
export const ViewSwitcher: React.FC<ViewSwitcherProps> = ({
  isChecked,
  onViewListChange,
  onViewModeChange,
  renderFilterComponent,
}) => {
  const [selectedKeys, setSelectedKeys] = React.useState<any[]>([]);
  const [selectedMode, setSelectedViewMode] = React.useState<ViewMode>(
    ViewMode.Day
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const resourceTypeDropDowns: any = useSelector(
    (state: any) => state.po.purchaseOrderDropDowns
  );

  const selectedResKeys: any = useSelector(
    (state: any) => state.vp.selectedResourceTypeOrg
  );

  const cpcDropDowns: any = useSelector(
    (state: any) => state.productCatalogue.resourceTypes
  );

  const onSubmit = async (values: any) => {};

  useEffect(() => {
    setSelectedKeys([getResourceTypeOptions()[0]])
  }, []);

  const sortOption = [
    { key: 1, text: t('department') },
    { key: 2, text: t('jobRole') },
  ];

  const getResourceTypeOptions = () => {
    return cpcDropDowns.filter((el: any) => {
      return (
        el.key === RESOURCE_TYPE_IDS.LABOURS ||
        el.key === RESOURCE_TYPE_IDS.TOOLS
      );
    });
  };


  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        height: 70,
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          flexGrow: 0.2,
        }}
      >
        <Form
          onSubmit={onSubmit}
          initialValues={{}}
          mutators={{
            setHeaderDocs: (args, state, utils) => {
              const field = state.fields['header.files'];
              field.change(args[0]);
            },
          }}
          // validate={ validate }
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            // props.submit(handleSubmit);
            return (
              <form onSubmit={handleSubmit} noValidate>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    height: 70,
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                      height: 70,
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        height: 70,
                        width: '100%',
                      }}
                    >
                      <div style={{ width: 180, marginLeft: 20 }}>
                        <Field name="resourceType">
                          {({ input, meta }) => (
                            <Dropdown
                              placeholder={t('resourceType')}
                              label={t('resourceType')}
                              selectedKey={selectedKeys[0]?.key}
                              // eslint-disable-next-line react/jsx-no-bind
                              onChange={(
                                event: React.FormEvent<HTMLDivElement>,
                                item: IDropdownOption | undefined
                              ) => {
                                if (item) {
                                  setSelectedKeys(
                                    item.selected
                                      ? [...selectedKeys, item.key as string]
                                      : selectedKeys.filter(
                                          (key) => key !== item.key
                                        )
                                  );
                                  input.onChange(
                                    item.selected
                                      ? [...selectedKeys, item.key as string]
                                      : selectedKeys.filter(
                                          (key) => key !== item.key
                                        )
                                  );
                                  dispatch(
                                    saveVPStateAttr(
                                      'selectedResourceTypeOrgProject',
                                      item.key
                                    )
                                  );
                                  dispatch(
                                    saveVPStateAttr(
                                      'showToolsOrLabourDocumentPane',
                                      false
                                    )
                                  );
                                }
                              }}
                              //multiSelect
                              options={getResourceTypeOptions()}
                            />
                          )}
                        </Field>
                      </div>

                      <ModeSwitcher
                        onViewModeChange={(t: any) => {
                          onViewModeChange(t);
                          setSelectedViewMode(t);
                        }}
                        onViewListChange={onViewListChange}
                        isCheck={isChecked}
                        selectedViewMode={selectedMode}
                      />
                    </div>
                  </div>
                </div>

                {/* <pre>{ JSON.stringify(values, 0, 2) }</pre> */}
              </form>
            );
          }}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          flexGrow: 0.6,
        }}
      >
        <div className="ViewContainer"></div>
      </div>
    </div>
  );
};

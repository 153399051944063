import {
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Stack,
} from '@fluentui/react';
import React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { uPrinceTheme } from '../../../../../theme';
import { DatePickerAdapter } from '../../../../shared/fluentFinalForm';
import { formatDateTimeToISOString } from '../../../../shared/util';

const ProjectTimeCard = (props: { projectTime: any, disabled: boolean, onChange: any, footer: any }) => {
  const { t } = useTranslation();
  const { projectScopeStatus }: any = useSelector((state: any) => state.project);


  const stackItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 50,
      width: 0,
      flexGrow: 1,
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
  };

  const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
    fullWidth: { width: '100%' },
    halfWidth: { width: '49.6%' },
  });

  const onSubmit = async (values: any) => {

  };

  const getInitialvalues = () => {

  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={props.projectTime}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        // <form onSubmit={ handleSubmit }>
        <div>
          <FormSpy
            onChange={(spyProps: any) => {
              if (props.projectTime !== spyProps.values && props.onChange) {
                props.onChange(spyProps.values);
              }
            }}
          />
          <div className="document-pane-card">
            <div className={'card-header'}>
              <Link href="#" id="project-time">
                <Label>6. {t('date')}</Label>
              </Link>
            </div>
            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  {/* <Field
                                        name="startDate"
                                        component={ DatePickerAdapter }
                                        lable={ t('startDate') }
                                        disabled={ false }
                                        parse={ (value) => value && new Date(value) }
                                        format={ (value) => (value ? new Date(value) : null) }
                                    /> */}
                  <Field
                    name="startDate"
                    component={DatePickerAdapter}
                    lable={t('startDate')}
                    disabled={false}
                    parse={value => value && formatDateTimeToISOString(value)}
                    format={value => (value ? new Date(value) : null)}
                    isClearable={true}
                    onDateClear={() => {
                      form.change('startDate', null);
                    }}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <FormSpy
                    subscription={
                      {
                        /* ... */
                      }
                    }
                    render={({ form }) => (
                      <Field
                        name="endDate"
                        component={DatePickerAdapter}
                        lable={t('endDate')}
                        disabled={false}
                        parse={value => value && formatDateTimeToISOString(value)}
                        format={value => (value ? new Date(value) : null)}
                        minDate={
                          new Date(form.getFieldState('startDate')?.value)
                        }
                        isClearable={true}
                        onDateClear={() => {
                          form.change('endDate', null);
                        }}
                      ></Field>
                    )}
                  />
                </div>
              </Stack.Item>
            </Stack>
            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.halfWidth}`}>
                  <FormSpy
                    subscription={
                      {
                        /* ... */
                      }
                    }
                    render={({ form }) => (
                      <Field
                        name="expectedEndDate"
                        component={DatePickerAdapter}
                        lable={t('expectedEndDate')}
                        disabled={false}
                        parse={value => value && formatDateTimeToISOString(value)}
                        format={(value) =>
                          value ? new Date(value) : null
                        }
                        minDate={
                          new Date(form.getFieldState('startDate')?.value)
                        }
                        isClearable={true}
                        onDateClear={() => {
                          form.change('expectedEndDate', null);
                        }}
                      ></Field>
                    )}
                  />
                </div>
              </Stack.Item>

            </Stack>
            <Stack
              horizontal
              wrap
              styles={stackStyles}
              tokens={stackTokens}
            >
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="tenderStartDate"
                    component={DatePickerAdapter}
                    lable={t('tenderStartDate')}
                    disabled={false}
                    parse={value => value && formatDateTimeToISOString(value)}
                    format={(value) => (value ? new Date(value) : null)}
                    isClearable={true}
                    onDateClear={() => {
                      form.change('tenderStartDate', null);
                    }}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <FormSpy
                    subscription={
                      {
                        /* ... */
                      }
                    }
                    render={({ form }) => (
                      <Field
                        name="tenderEndDate"
                        component={DatePickerAdapter}
                        lable={t('tenderEndDate')}
                        disabled={false}
                        parse={value => value && formatDateTimeToISOString(value)}
                        format={(value) =>
                          value ? new Date(value) : null
                        }
                        minDate={
                          new Date(form.getFieldState('tenderStartDate')?.value)
                        }
                        isClearable={true}
                        onDateClear={() => {
                          form.change('tenderEndDate', null);
                        }}
                      ></Field>
                    )}
                  />
                </div>
              </Stack.Item>
            </Stack>
            {props.footer}
          </div>
        </div>
      )}
    />


  );
};

export default ProjectTimeCard;

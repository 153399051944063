import React from 'react';
import {Dropdown, IDropdownOption, TextField} from "@fluentui/react";
import DefaultCustomModal from "../../../../shared/customeModal/defaultCustomModal";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

interface Props {
    isCancelOpen: boolean
    onRenderFooter: () => {}
    handleClose: () => void
    handleLeaveReasonDropDown: (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => void
    showErrorMsg: boolean
    handleOnChangeConfirm: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void
}

function CancelCiawModal(props: Props) {
    const {t} = useTranslation();

    const {
        isCancelOpen,
        onRenderFooter,
        handleClose,
        showErrorMsg,
        handleLeaveReasonDropDown,
        handleOnChangeConfirm,
    } = props;

    const {cancelLeaveType} = useSelector(
      (state: any) => state.ciaw
    );

    return <>
        <DefaultCustomModal
          isModalOpen={!isCancelOpen}
          title={t('cancelCiaw')}
          onRenderFooter={onRenderFooter}
          handleClose={handleClose}
        >
            <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <Dropdown
                  label={t('reason')}
                  placeholder={t('selectReason')}
                  options={cancelLeaveType}
                  onChange={handleLeaveReasonDropDown}
                  required={true}
                  errorMessage={showErrorMsg ? t('pleaseSelectTheReason') : undefined}
                />
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span style={{margin: "0 0 4px 0"}}>Type <b>YES</b> if you want to cancel the CIAW!</span>
                    <TextField
                      onChange={handleOnChangeConfirm}
                    />
                </div>
            </div>
        </DefaultCustomModal>
    </>
}

export default CancelCiawModal;
import {
  ActionButton,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Separator,
  Stack,
} from '@fluentui/react'; // fluent component for UI
// fluent component for UI
import { Field, Form } from 'react-final-form'; // final form components
import { uPrinceTheme } from '../../../../theme';
import { CKEditorWrapper, TextFieldFF } from '../../../shared/fluentFinalForm';
import HistoryBar from '../../../shared/historyBar/historyBar';
import { useTranslation } from 'react-i18next';
import { Quality } from '../../../types/projectBreakdownStructure';
import { QLDropDowns } from '../../../types/quality';
import React, {
  useEffect,
  useState, //react hooks
} from 'react';

const theme = getTheme();

const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };

const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
});

const validate = (values: any) => {
  const errors: any = {};
  if (!values.name || values.name.trim() === '') {
    errors.name = 'Name Required';
  }
  return errors;
};

const DocumentPaneComponent = (props: {
  submit: any;
  dropDowns: QLDropDowns;
  saveFormData: any;
  createQuality: any;
  uid: any;
  showProgress: boolean;
  formValues: any;
  formData: any;
  isExpand: boolean;
  setForm: (form: any) => void;
  isClear: boolean;
  hasChangeIntialFromData: boolean;
}) => {
  const { t } = useTranslation();
  const [state, setstate] = useState<Quality | null>(null);
  const onSubmit = async (values: any) => {
    props.saveFormData(values);
  };

  useEffect(() => {
    setstate(props.formData);
  }, [props.formData]);

  //-------------------Get data to document pane-----------------//

  const getInitialFormValues = (formData: any, formValue: any) => {
    if (location.pathname.split('/').pop() === 'new' && props.isClear) {
      return {
        // requiredDateAndTime: new Date(),
        // executedDateAndTime: new Date(),
        // isFinished: false
      };
    } else if (formData && formData?.id && !props.hasChangeIntialFromData) {
      return {
        id: formData?.id,
        sequenceCode: formData?.sequenceCode,
        title: formData?.title,
        name: formData?.name,
        criteria: formData?.criteria,
        tolerance: formData?.tolerance,
        method: formData?.method,
        skills: formData?.skills,

        typeId: formData?.type?.key,
        resourceType: formData?.cpcType?.key,
        requesterId: formData?.requesterId,
        requester: formData?.requester,
        executorId: formData?.executorId,
        executor: formData?.executor,
        requiredDateAndTime: new Date(formData?.requestedDateAndTime),
        executedDateAndTime: formData?.executedDateAndTime
          ? new Date(formData?.executedDateAndTime)
          : null,
        effortEstimate: formData?.effortEstimate,
        effortCompleted: formData?.effortCompleted,
        status: formData?.status?.key,
        files: formData?.files,
        task: formData?.task,
        isFinished: formData?.isFinish,
        destination: formData?.destination,
        source: formData?.source,
        comment: formData?.comment,
      };
    }
    return formValue;
  };

  return (
    <div style={{ width: '100%' }}>
      <div className="proj-detail-block">
        <Form
          onSubmit={onSubmit}
          //initialValues={{}}
          initialValues={getInitialFormValues(props.formData, props.formValues)}
          mutators={{
            setHeaderDocs: (args, state, utils) => {
              const field = state.fields['files'];
              field.change(args[0]);
            },
            setProducerName: (args, state, utils) => {
              const field = state.fields['requesterName'];
              {
                /* for saving risk data */
              }
              field.change(args[0]);
            },
          }}
          validate={validate}
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            props.submit(handleSubmit);
            props.setForm(form);

            let headerFiles = values.header?.files;
            return (
              <form onSubmit={handleSubmit} noValidate>
                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="header">
                      <Label>1. {t('qualityHeader')}</Label>
                    </Link>
                  </div>

                  {values?.sequenceCode && (
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="title"
                            component={TextFieldFF}
                            lable={t('qualityTitle')}
                            //required={true}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="sequenceCode"
                            component={TextFieldFF}
                            lable={t('qualityID')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>
                  )}

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.halfWidth}`}>
                        <Field
                          name="name"
                          component={TextFieldFF}
                          lable={t('name')}
                          required={true}
                          //disabled={ true }
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  <div className={'card-footer'}>
                    <Separator />
                    <Stack
                      styles={stackStyles}
                      tokens={itemAlignmentsStackTokens}
                    >
                      <Stack.Item align="end" styles={stackFooterBarItemStyles}>
                        <ActionButton
                          className={classNames.actionButton}
                          iconProps={saveIcon}
                          allowDisabledFocus
                          onClick={() => {
                            // alert(JSON.stringify(values.purchaseOrderType, 0, 2));
                            //------------------  Create Quality ---------------------------//
                            //props.createQuality();
                            form.submit();
                          }}
                          // disabled={ (props.dropDownOptions.isApproved) }
                        >
                          <Label className={classNames.actionButton}>
                            {t('save')}
                          </Label>
                        </ActionButton>
                      </Stack.Item>
                    </Stack>
                  </div>
                </div>

                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="quality-details">
                      <Label>2. {t('qualityDetails')}</Label>
                    </Link>
                  </div>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <div className={`${classNames.fullWidth}`}>
                      <Label>{t('qualityCriteria')}</Label>

                      <Field
                        name="criteria"
                        component={CKEditorWrapper}
                        lable={t('criteria')}
                        //placeholder="Quality Criteria"
                        disabled={false}
                      />
                    </div>
                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <div className={`${classNames.fullWidth}`}>
                      <Label>{t('qualityTolerance')}</Label>

                      <Field
                        name="tolerance"
                        component={CKEditorWrapper}
                        lable={t('tolerance')}
                        //placeholder="Quality Tolarance"
                        disabled={false}
                      />
                    </div>
                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <div className={`${classNames.fullWidth}`}>
                      <Label>{t('qualityMethod')}</Label>

                      <Field
                        name="method"
                        component={CKEditorWrapper}
                        lable={t('method')}
                        //placeholder="Quality Method"
                        disabled={false}
                      />
                    </div>
                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <div className={`${classNames.fullWidth}`}>
                      <Label>{t('qualitySkillRequired')}</Label>

                      <Field
                        name="skills"
                        component={CKEditorWrapper}
                        lable={t('skills')}
                        placeholder="Quality Skill Required"
                        disabled={false}
                      />
                    </div>
                  </Stack>

                  <div className={'card-footer'}>
                    <Separator />
                    <Stack
                      styles={stackStyles}
                      tokens={itemAlignmentsStackTokens}
                    >
                      <Stack.Item align="end" styles={stackFooterBarItemStyles}>
                        <ActionButton
                          className={classNames.actionButton}
                          iconProps={saveIcon}
                          allowDisabledFocus
                          onClick={() => {
                            form.submit();
                          }}
                        >
                          <Label className={classNames.actionButton}>
                            {t('save')}
                          </Label>
                        </ActionButton>
                      </Stack.Item>
                    </Stack>
                  </div>
                </div>

                {props.formData?.qualityHistoryLogDto &&
                  props.formData?.qualityHistoryLogDto.createdDateTime && (
                    <div className="proj-detail-block" id="3">
                      <div className="document-pane-card">
                        <div className="marginTop marginBottom">
                          <Link href="#" id="history">
                            <Label>3. {t('history')} </Label>
                          </Link>

                          <HistoryBar
                            createdByUser={
                              props.formData.qualityHistoryLogDto.createdByUser
                                ? props.formData.qualityHistoryLogDto
                                    .createdByUser
                                : ''
                            }
                            updatedByUser={
                              props.formData.qualityHistoryLogDto.updatedByUser
                                ? props.formData.qualityHistoryLogDto
                                    .updatedByUser
                                : ''
                            }
                            createdDateTime={
                              props.formData.qualityHistoryLogDto
                                .createdDateTime
                                ? props.formData.qualityHistoryLogDto
                                    .createdDateTime
                                : ''
                            }
                            updatedDateTime={
                              props.formData.qualityHistoryLogDto
                                .updatedDateTime
                                ? props.formData.qualityHistoryLogDto
                                    .updatedDateTime
                                : ''
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}

                {/* <pre>{ JSON.stringify(values, 0, 2) }</pre> */}
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};
export default DocumentPaneComponent;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Image, Page, PDFDownloadLink, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer';
import pdfLogo from './../../../../../images/pdfLogo.png';

import moment from 'moment';
import { PrimaryButton } from '@fluentui/react';
import i18n from '../../../../../i18n';
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
  },
  image: {
    width: '100%',
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 11,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  table: {
    fontSize: 11,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#020202',
  },
  tableNoBoarder: {
    fontSize: 11,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 0,
    borderColor: 'none',
  },
  tableBody: {
    display: 'flex', // error TS2322: Type '"table"' is not assignable to type '"none" | "flex" | undefined'.
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 'auto',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    borderBottomWidth: 0,
  },
  tableCol: {
    width: '60%',
    textAlign: 'left',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    borderRightColor: '#020202',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    paddingLeft: 4,
    paddingRight: 1,
    fontSize: 10,
  },
  tableCol2: {
    width: '40%',
    textAlign: 'right',
    backgroundColor: 'transparent',
    borderRightColor: '#020202',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    paddingRight: 4,
    paddingLeft: 1,
    fontSize: 10,
  },
  tableColLeft2: {
    width: '40%',
    textAlign: 'left',
    backgroundColor: 'transparent',
    borderRightColor: '#020202',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    paddingRight: 1,
    paddingLeft: 1,
    fontSize: 10,
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 10,
  },
});

const itemTableWidth = {
  col: '60%',
  col2: '20%',
  col3: '20%',
  col4: '13%',
  col5: '13%',
};
const backgroundColor = {
  color1: '#ffffff',
  color2: '#ffffff',
};

const formatDateTimeToLocal = (date: moment.MomentInput) => {
  const formatted = moment.utc(date).local().format('DD/MM/YYYY');
  return formatted;
};


const PdfGenerate = (props: { wfData: any, customer: any, project: any }) => {

  const { t } = useTranslation();

  const formatter = new Intl.NumberFormat(window.navigator.language, {
    style: 'currency',
    currency: 'EUR',
  });

  return (
    <Document>
      <Page wrap style={styles.body}>
        <Text style={styles.header} fixed>
          {/*~ Created with react-pdf ~*/}
        </Text>

        <View style={{ width: '100%', flexDirection: 'row' }}>
          <View style={{ width: '50%', fontSize: 12 }}>
            <Text> GODDEERIS nv</Text>
            <Text> Ovenhoek 26</Text>
            <Text> 8800 Roeselare</Text>
            <Text> BTW: BE 0405 566 896</Text>
          </View>
          <View style={{ width: '50%' }}>
            <Image
              style={styles.image}
              src={pdfLogo}
            />
          </View>
        </View>

        <Table>
          <TableRow>
            <TableCol style={{ backgroundColor: '#ffffff', border: true }}><Text> {t('project')}:</Text></TableCol>
            <TableColLeft2 style={{
              backgroundColor: '#ffffff',
              border: true,
              isLastCell: true,
            }}><Text>{props.project?.title}&nbsp;</Text></TableColLeft2>
          </TableRow>

          <TableRow>
            <TableCol style={{
              backgroundColor: '#ffffff',
              isLastRow: true,
              border: true,
            }}><Text> {t('billOfResources')}:</Text></TableCol>
            <TableColLeft2 style={{
              backgroundColor: '#ffffff',
              border: true,
              isLastRow: true,
              isLastCell: true,
            }}><Text>{props.wfData?.borTitle}&nbsp;</Text></TableColLeft2>
          </TableRow>

        </Table>

        <Table borderWidth={0}>
          <TableRow style={{ backgroundColor: '#ffffff', border: false }}>
            <TableCol style={{ backgroundColor: '#ffffff', border: true, width: itemTableWidth.col, isLastCell: true }}><Text> {t('description')}</Text></TableCol>
            <TableCol2 style={{
              backgroundColor: '#ffffff',
              border: true,
              width: itemTableWidth.col2,
              isLastCell: true,
            }}><Text>{t('quantity')}</Text></TableCol2>
            <TableCol2 style={{
              backgroundColor: '#ffffff',
              border: true,
              width: itemTableWidth.col3,
              isLastCell: true,
            }}><Text>{t('mou')}</Text></TableCol2>
          </TableRow>
          {/*item start*/}
          {renderWFItem(props.wfData.tasks)}
          {/*item end*/}
        </Table>

        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed/>
      </Page>
    </Document>
  );

};


const renderWFItem = (data: any) => {
  if (data) {
    return data.map((item: any) => {
      console.log('renderWFItem', item);
      return (
        <View key={item.id + 'psm'} style={{ paddingTop: 8 }}>
          <TableRow style={{ backgroundColor: '#ffffff', border: false, borderColor: '#020202', borderBottomWidth: 1 }}>
            <TableCol style={{
              backgroundColor: '#ffffff',
              border: false,
              width: itemTableWidth.col,
            }}><Text> {item.corporateProductCatalog?.text}</Text></TableCol>
            <TableCol2 style={{
              backgroundColor: '#ffffff',
              border: false,
              width: itemTableWidth.col2,
            }}><Text>{item.quantity}&nbsp;</Text></TableCol2>
            <TableCol2
              style={{ backgroundColor: '#ffffff', border: false, width: itemTableWidth.col3 }}><Text>{item.mou}</Text></TableCol2>
          </TableRow>
        </View>
      );
    });
  }
};


const Table = (props: any) => {
  return (
    <View style={props.borderWidth === 0 ? styles.tableNoBoarder : styles.table}>
      {props.children}
    </View>
  );
};
const TableRow = (props: any) => {
  if (props.style) {
    if (props.style.backgroundColor) {
      styles.tableRow.backgroundColor = props.style.backgroundColor;
    }
    if (props.style.border) {
      styles.tableRow.borderColor = props.style.borderColor;
      styles.tableRow.borderBottomWidth = props.style.borderBottomWidth ? props.style.borderBottomWidth : 2;
    } else {
      styles.tableRow.borderBottomWidth = 0;
    }
  }
  return (
    <View wrap={false} style={styles.tableRow}>
      {props.children}
    </View>
  );
};
const TableCol = (props: any) => {
  if (props.style) {
    if (props.style.backgroundColor) {
      styles.tableCol.backgroundColor = props.style.backgroundColor;
    }
    if (props.style.border) {
      if (props.style.isLastRow) {
        styles.tableCol.borderBottomWidth = 0;
        styles.tableCol.borderRightWidth = (props.style.isLastCell) ? 0 : 1;
      } else {
        styles.tableCol.borderBottomWidth = 1;
        styles.tableCol.borderRightWidth = (props.style.isLastCell) ? 0 : 1;
      }
    } else {
      styles.tableCol.borderColor = 'none';
      styles.tableCol.borderBottomWidth = 0;
      styles.tableCol.borderRightWidth = 0;
    }

    if (props.style.width) {
      styles.tableCol.width = props.style.width;
    } else {
      styles.tableCol.width = '30%';
    }
  }
  return (
    <View style={styles.tableCol}>
      {props.children}
    </View>
  );
};

const TableCol2 = (props: any) => {
  if (props.style) {
    if (props.style.backgroundColor) {
      styles.tableCol2.backgroundColor = props.style.backgroundColor;
    }
    if (props.style.border) {
      if (props.style.isLastRow) {
        styles.tableCol2.borderBottomWidth = 0;
        styles.tableCol2.borderRightWidth = (props.style.isLastCell) ? 0 : 1;
      } else {
        styles.tableCol2.borderBottomWidth = 1;
        styles.tableCol2.borderRightWidth = (props.style.isLastCell) ? 0 : 1;
      }

    } else {
      styles.tableCol.borderColor = 'none';
      styles.tableCol2.borderBottomWidth = 0;
      styles.tableCol2.borderRightWidth = 0;
    }
    if (props.style.width) {
      styles.tableCol2.width = props.style.width;
    } else {
      styles.tableCol2.width = '70%';
    }
  }
  return (
    <View style={styles.tableCol2}>
      {props.children}
    </View>
  );
};

const TableColLeft2 = (props: any) => {
  if (props.style) {
    if (props.style.backgroundColor) {
      styles.tableColLeft2.backgroundColor = props.style.backgroundColor;
    }
    if (props.style.border) {
      if (props.style.isLastRow) {
        styles.tableColLeft2.borderBottomWidth = 0;
        styles.tableColLeft2.borderRightWidth = (props.style.isLastCell) ? 0 : 1;
      } else {
        styles.tableColLeft2.borderBottomWidth = 1;
        styles.tableColLeft2.borderRightWidth = (props.style.isLastCell) ? 0 : 1;
      }

    } else {
      styles.tableCol.borderColor = 'none';
      styles.tableColLeft2.borderBottomWidth = 0;
      styles.tableColLeft2.borderRightWidth = 0;
    }
    if (props.style.width) {
      styles.tableColLeft2.width = props.style.width;
    } else {
      styles.tableColLeft2.width = '70%';
    }
  }
  return (
    <View style={styles.tableColLeft2}>
      {props.children}
    </View>
  );
};

const ref = React.createRef<HTMLDivElement>();

const PdfExport = (props: { wfData: any, customer: any, project: any }) => (
  <div className="App">
    <div style={{ float: 'right' }}>
      <PDFDownloadLink document={<PdfGenerate wfData={props.wfData} customer={props.customer} project={props.project}/>}
                       fileName={props.wfData.title + moment().format('YYYYMMDDHHMMSS')}>
        {({ blob, url, loading, error }) => (loading ? 'Loading document...' :
          <PrimaryButton text={i18n.t('download')}/>)}
      </PDFDownloadLink>
    </div>

    {/*<br/>*/}
    {/*<PdfGenerate/>*/}
    <PDFViewer style={{ width: '100%', height: '80vh' }}>
      <PdfGenerate wfData={props.wfData} customer={props.customer} project={props.project}/>
    </PDFViewer>
  </div>
);
export default PdfExport;



import * as React from 'react';
import { useEffect, useState } from 'react';
import TaxonomyGrid from '../../../../shared/taxonomyField/taxonomyGrid';
import TaxonomyField from '../../../../shared/taxonomyField/taxonomyField';
import { Checkbox } from 'office-ui-fabric-react';
import { useDispatch, useSelector } from 'react-redux';
import { getPerentFromTreeData, getProject } from '../../../../shared/util';
import { renderIconBefore, WBS_TAXONOMY_LEVEL } from '../../../../types/wbs';

/**
 * WbsProductTaxonomy Component
 *
 * This component allows users to select a product taxonomy from a tree structure. It provides checkboxes for selection and handles the state of selected items.
 *
 * @param {object} props - Component props
 * @param {string} props.value - The currently selected taxonomy item's ID.
 * @param {function} props.onChange - A callback function to be called when the selection changes.
 * @param {boolean} props.disabled - A flag to indicate if the component should be disabled.
 */
const WbsProductTaxonomy = ({ value, onChange, disabled }: any) => {
  const dispatch = useDispatch();

  // Redux state selectors
  const wbsTaxonomy = useSelector((state: any) => state.wbsTask.wbsTaxonomy);
  const wbsTemplateTaxonomyData = useSelector((state: any) => state.wbsCreate.wbsTemplateTaxonomyData);

  // State variables
  const [selectedParentIds, setSelectedParentIds] = useState<any>([]);
  const [selectItemIds, setSelectItemId] = useState([value]);
  const [treeData, setTreeData] = useState([]);

  // const getTaxonomy = () => {
  //   dispatch(getWbsTaxonomy());
  // };

  useEffect(() => {

    // Component unmount cleanup
    return () => {
      console.log('Product Taxonomy unmounted');
    };
  }, []);

  useEffect(() => {
    // Update component state when the selected item changes
    setSelectItemId([value]);
    setSelectedParentIds(getPerentFromTreeData(wbsTemplateTaxonomyData, value));
    setTreeData(getProject() ? wbsTaxonomy : wbsTemplateTaxonomyData);
  }, [value, wbsTemplateTaxonomyData]);

  /**
   * Handle a checkbox click for a taxonomy item.
   *
   * @param {object} rowInfo - Information about the clicked taxonomy item.
   */
  const handleNodeCheckboxClick = (rowInfo: any) => {
    // console.log({ rowInfo });
    if (rowInfo?.node?.id) {
      onChange(rowInfo?.node?.id);
    }
  };

  /**
   * Render checkboxes or indeterminate checkboxes for taxonomy items.
   *
   * @param {object} rowInfo - Information about the taxonomy item to render.
   * @returns {JSX.Element} - The rendered checkbox or indeterminate checkbox.
   */
  const renderButtons = (rowInfo: any) => {
    // const isParent =false;
    const isParent = selectedParentIds.includes(rowInfo.node.id);
    const isSelected = selectItemIds.includes(rowInfo.node.id);
    if (rowInfo.node.wbsTaxonomyLevelId === WBS_TAXONOMY_LEVEL.WBS) {
      if (isParent) {
        return (
          <div
            key={rowInfo.node.id}
            className={'Indeterminate'}
            style={{ paddingTop: 6 }}
          >
            <span
              style={{ display: 'none' }}>{JSON.stringify(isParent)}</span> {/* if u remove this part parent selection not working properly   */}
            <Checkbox
              className='btn btn-outline-success'
              style={{ verticalAlign: 'middle' }}
              defaultIndeterminate
              onChange={() => handleNodeCheckboxClick(rowInfo)}
              disabled={true}
            />
          </div>
        );
      } else {
        return (
          <div
            key={rowInfo.node.id}
            className={'Indeterminate'}
            style={{ paddingTop: 6 }}
          >
            <Checkbox
              className='btn btn-outline-success'
              style={{ verticalAlign: 'middle' }}
              checked={isSelected}
              onChange={() => handleNodeCheckboxClick(rowInfo)}
              disabled={true}
            />
          </div>
        );
      }
    }
  };

  const renderTitle = (rowInfo: any) => {
    return (
      <div style={{ display: 'flex', gap: 5 }}>
        {renderIconBefore(rowInfo.node)}
        {rowInfo.node.title}
      </div>
    );
  };

  return (
    <>
      <TaxonomyField treeData={treeData ?? []} selectItemId={value ?? ''} disabled={disabled}>
        <TaxonomyGrid
          treeData={treeData ?? []}
          renderButtons={renderButtons}
          renderTitle={renderTitle}
        />
      </TaxonomyField>
    </>
  );

};

export default WbsProductTaxonomy;

import React, { useEffect, useState } from 'react';
import DefaultCustomModal from '../../customeModal/defaultCustomModal';
import i18n from 'i18next';
import { useBoolean } from '@fluentui/react-hooks';
import { messageService } from '../../../services/messageService';
import '../../../components/wbsIssue/documentPane/component/Wbs-Task-Modal-Style-Sheet.css';
import { PrimaryButton } from '@fluentui/react';
import { addIconWhite } from '../../../components/wbsCreate/documentPane/WbsCreateDocument';
import { useDispatch, useSelector } from 'react-redux';
import WbsTaskModalDocumentPane from '../../../components/wbsIssue/documentPane/component/WbsTaskModalDocumentPane';
import { getWbsTaskById, saveWbsTaskStateAttr } from '../../../reducers/wbsTaskReducer';
import { TASK_STATUS } from '../../../types/wbsTask';

const WbsTaskModal = () => {
  const dispatch = useDispatch();

  const newWbsTaskId = useSelector((state: any) => state.wbsTask.newWbsTaskId);
  const isLoadingDocPane = useSelector((state: any) => state.wbsTask.isLoadingDocPane);

  const [isOpen, { setTrue: openModal, setFalse: closeModal, toggle: setModalVisible }] = useBoolean(false);
  const [formIds, setFormIds] = useState<any>();

  useEffect(() => {

    const subscription = messageService.getMessage().subscribe((data: any) => {
      dispatch(saveWbsTaskStateAttr('formData', null));

      if (data) {
        if (data.data.isOpenTaskModal) {
          openModal();
        }
        if (data.data.selectedTask) {
          // console.log(data.data.selectedTask);
          const taskId = data.data.selectedTask ? data.data.selectedTask.id : null;
          taskId && getTaskFormData(taskId);

          setFormIds({
            wbsTaxonomyId: data.data.selectedTask?.pbsId ?? null,
            statusId: TASK_STATUS.PENDING_DEVELOPMENT,
            productId: data.data.selectedTask?.productId ?? null,
            pbsId: data.data.selectedTask?.pbsId ?? null,
            issueId: data.data.selectedTask?.issueId ?? null,
          })
        }
      }
    });

    return () => {
      subscription.unsubscribe();
      console.log('WBS Task Modal unmounted');
    };
  }, []);

  useEffect(() => {
    if (newWbsTaskId) {
      closeModal();
      messageService.sendMessage({ saveWbsIssue: true });
    }
  }, [newWbsTaskId]);

  const getTaskFormData = async (id: string) => {
    dispatch(getWbsTaskById({ id: id }));
  };

  const onRenderFooter = () => (
    <PrimaryButton
      iconProps={addIconWhite}
      text={i18n.t('save')}
      disabled={false} //{submitting || pristine}
      style={{ marginTop: 38, marginBottom: 10 }}
      onClick={() => {
        messageService.sendMessage({ saveWbsTask: true });
      }}
    />
  );

  return (
    <DefaultCustomModal
      onRenderFooter={onRenderFooter}
      isModalOpen={isOpen}
      title={i18n.t('task')}
      handleClose={closeModal}
      showProgressBar={isLoadingDocPane}
    >
      <div className='document-pane-body'>
        <WbsTaskModalDocumentPane
          formIds={formIds}
        />
      </div>
    </DefaultCustomModal>
  );
};

export default WbsTaskModal;

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import history from '../../../../history';
import { getContractingUnit } from '../../../../shared/util';
import { messageService } from '../../../../services/messageService';

const WbsCuTaskListPaneCommandBar = () => {
  const { t } = useTranslation();

  const getItems = () => {
    return [
      {
        key: 'new',
        text: t('new'),
        iconProps: { iconName: 'Add' },
        onClick: () => {
          if (window?.location?.pathname?.split('/')?.pop() != 'new') {
            history.push({
              pathname: `/CU/${getContractingUnit()}/wbs-cu-task/new`,
            });
          }
          messageService.sendMessage({
            resetWbsTaskForm: true,
          });
        },
        disabled: true,
      },
    ];
  };

  return <div>
    <CommandBar
      styles={{ root: { height: 26 } }}
      items={getItems()}
      ariaLabel='Use left and right arrow keys to navigate between commands'
    />
  </div>;

};

export default WbsCuTaskListPaneCommandBar;
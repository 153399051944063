import {
  CREATE_WBS_NODE,
  CREATE_WBS_NODE_FAIL,
  CREATE_WBS_NODE_SUCCESS,
  CREATE_WBS_PRODUCT,
  CREATE_WBS_PRODUCT_FAIL,
  CREATE_WBS_PRODUCT_SUCCESS,
  CREATE_WBS_TEMPLATE,
  CREATE_WBS_TEMPLATE_FAIL,
  CREATE_WBS_TEMPLATE_SUCCESS,
  FETCH_SHAREPOINT_CONTENT_TYPES,
  FETCH_SHAREPOINT_CONTENT_TYPES_FAIL,
  FETCH_SHAREPOINT_CONTENT_TYPES_SUCCESS,
  FETCH_WBS_DOCUMNT_COLUMNS,
  FETCH_WBS_DOCUMNT_COLUMNS_FAIL,
  FETCH_WBS_DOCUMNT_COLUMNS_SUCCESS,
  GET_WBS_CREATE_LEVEL_DATA,
  GET_WBS_CREATE_LEVEL_DATA_FAIL,
  GET_WBS_CREATE_LEVEL_DATA_SUCCESS,
  GET_WBS_PRODUCT_BY_ID,
  GET_WBS_PRODUCT_BY_ID_FAIL,
  GET_WBS_PRODUCT_BY_ID_SUCCESS,
  GET_WBS_TAXONOMY_BY_TEMPLATE_ID,
  GET_WBS_TAXONOMY_BY_TEMPLATE_ID_FAIL,
  GET_WBS_TAXONOMY_BY_TEMPLATE_ID_SUCCESS,
  READ_WBS_CREATE_LIST_DATA,
  READ_WBS_CREATE_LIST_DATA_FAIL,
  READ_WBS_CREATE_LIST_DATA_SUCCESS,
  READ_WBS_TAXONOMY_LIST,
  READ_WBS_TAXONOMY_LIST_FAIL,
  READ_WBS_TAXONOMY_LIST_SUCCESS,
  RENAME_WBS_TEMPLATE,
  RENAME_WBS_TEMPLATE_FAIL,
  RENAME_WBS_TEMPLATE_SUCCESS,
  SAVE_WBS_CREATE_STATE_ATTR,
  UPDATE_SHAREPOINT_CONTENT_TYPE,
  UPDATE_SHAREPOINT_CONTENT_TYPE_FAIL,
  UPDATE_SHAREPOINT_CONTENT_TYPE_SUCCESS,
  UPDATE_WBS_TAXONOMY_PARENT_NODE,
  UPDATE_WBS_TAXONOMY_PARENT_NODE_FAIL,
  UPDATE_WBS_TAXONOMY_PARENT_NODE_SUCCESS,
} from '../actionTypes/wbsCreateActionTypes';
import {
  CREATE_WBS_NODE_EP,
  CREATE_WBS_PRODUCT_EP,
  CREATE_WBS_TEMPLATE_EP,
  GET_WBS_CREATE_LEVEL_DATA_EP,
  GET_WBS_PRODUCT_BY_ID_EP,
  GET_WBS_TAXONOMY_BY_TEMPLATE_ID_EP,
  READ_WBS_CREATE_LIST_DATA_EP,
  READ_WBS_TAXONOMY_LIST_EP,
  RENAME_WBS_TEMPLATE_EP,
  SHAREPOINT_DOCUMENT_CONTENT_TYPES,
  SHAREPOINT_DOCUMENT_CONTENT_TYPE_COLUMN_EP,
  SHAREPOINT_DOCUMENT_CONTENT_TYPE_UPDATE_EP,
  UPDATE_WBS_TAXONOMY_PARENT_NODE_EP,
} from '../shared/endpoints';
import { NodeData, WbsCreateListPaneFilter } from '../types/wbs';

const defaultState: any = {
  showWbsProgressBar: false,
  wbsListData: [],
  wbsCreateFormData: {},
  isWbsCreateClear: false,
  isWbsListDataLoaded: false,
  wbsLevelsData: [],
  wbsTaxonomyList: null,
  isTaxonomyDataLoaded: false,
  isTemplateCreated: false,
  isNodeCreated: false,
  templateId: null,
  wbsTemplateTaxonomyData: [],
  isTemplate: false,
  reloadListPane: false,
  reloadDocPane: false,
  wbsProductFormData: null,
  reloadWbsProjectListPane: false,
  showTempMsg: false,
  selectedNodeId: null,
  wbsDocumentMeta: null,
  isDocumentMetaUpdating:false
};

export default function wbsCreateReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {
      case SAVE_WBS_CREATE_STATE_ATTR:
        const attr = Object.keys(action)[1];
        const value = Object.values(action)[1];
        return {
          ...state,
          [attr]: value,
        };

      case READ_WBS_CREATE_LIST_DATA:
        return {
          ...state,
          wbsListData: [],
          isWbsListDataLoaded: false,
          showWbsProgressBar: true,
        };
      case READ_WBS_CREATE_LIST_DATA_SUCCESS:
        return {
          ...state,
          wbsListData: action.payload.data.result,
          isWbsListDataLoaded: true,
          showWbsProgressBar: false,
        };
      case READ_WBS_CREATE_LIST_DATA_FAIL:
        return {
          ...state,
          wbsListData: [],
          isWbsListDataLoaded: false,
          showWbsProgressBar: false,
        };

      case GET_WBS_CREATE_LEVEL_DATA:
        return {
          ...state,
          wbsLevelsData: [],
        };
      case GET_WBS_CREATE_LEVEL_DATA_SUCCESS:
        return {
          ...state,
          wbsLevelsData: action.payload.data.result,
        };
      case GET_WBS_CREATE_LEVEL_DATA_FAIL:
        return {
          ...state,
          wbsLevelsData: [],
        };

      case CREATE_WBS_NODE:
        return {
          ...state,
          showWbsProgressBar: true,
          isNodeCreated: false,
          reloadWbsProjectListPane: false,
        };
      case CREATE_WBS_NODE_SUCCESS:
        return {
          ...state,
          showWbsProgressBar: false,
          isNodeCreated: true,
          reloadWbsProjectListPane: true,
        };
      case CREATE_WBS_NODE_FAIL:
        return {
          ...state,
          showWbsProgressBar: false,
          isNodeCreated: false,
          reloadWbsProjectListPane: false,
        };

      case READ_WBS_TAXONOMY_LIST:
        return {
          ...state,
          wbsTaxonomyList: null,
          isTaxonomyDataLoaded: false,
          showWbsProgressBar: true,
        };
      case READ_WBS_TAXONOMY_LIST_SUCCESS:
        return {
          ...state,
          wbsTaxonomyList: action.payload.data.result,
          isTaxonomyDataLoaded: true,
          showWbsProgressBar: false,
        };
      case READ_WBS_TAXONOMY_LIST_FAIL:
        return {
          ...state,
          wbsTaxonomyList: null,
          isTaxonomyDataLoaded: true,
          showWbsProgressBar: false,
        };

      case CREATE_WBS_TEMPLATE:
        return {
          ...state,
          isTemplateCreated: false,
          showWbsProgressBar: true,
        };
      case CREATE_WBS_TEMPLATE_SUCCESS:
        return {
          ...state,
          isTemplateCreated: true,
          showWbsProgressBar: false,
          templateId: action.payload.data.result,
        };
      case CREATE_WBS_TEMPLATE_FAIL:
        return {
          ...state,
          isTemplateCreated: false,
          showWbsProgressBar: false,
          templateId: null,
        };

      case GET_WBS_TAXONOMY_BY_TEMPLATE_ID:
        return {
          ...state,
          showWbsProgressBar: true,
          showTempMsg: false,
        };
      case GET_WBS_TAXONOMY_BY_TEMPLATE_ID_SUCCESS:
        return {
          ...state,
          showWbsProgressBar: false,
          wbsTemplateTaxonomyData: action.payload.data.result,
          showTempMsg: true,
        };
      case GET_WBS_TAXONOMY_BY_TEMPLATE_ID_FAIL:
        return {
          ...state,
          showWbsProgressBar: false,
          wbsTemplateTaxonomyData: [],
          showTempMsg: true,
        };

      case GET_WBS_PRODUCT_BY_ID:
        return {
          ...state,
          showWbsProgressBar: true,
          reloadWbsProjectListPane: false,
        };
      case GET_WBS_PRODUCT_BY_ID_SUCCESS:
        return {
          ...state,
          showWbsProgressBar: false,
          wbsProductFormData: action.payload.data.result,
          reloadWbsProjectListPane: true,
        };
      case GET_WBS_PRODUCT_BY_ID_FAIL:
        return {
          ...state,
          showWbsProgressBar: false,
          wbsProductFormData: null,
          reloadWbsProjectListPane: false,
        };

      case CREATE_WBS_PRODUCT:
        return {
          ...state,
          showWbsProgressBar: true,
        };
      case CREATE_WBS_PRODUCT_SUCCESS:
        return {
          ...state,
          showWbsProgressBar: false,
        };
      case CREATE_WBS_PRODUCT_FAIL:
        return {
          ...state,
          showWbsProgressBar: false,
        };

      case RENAME_WBS_TEMPLATE:
        return {
          ...state,
          showWbsProgressBar: true,
        };
      case FETCH_WBS_DOCUMNT_COLUMNS_SUCCESS:
        return {
          ...state,
          wbsDocumentMeta: action.payload.data.result,
        };
      case RENAME_WBS_TEMPLATE_SUCCESS:
        return {
          ...state,
          showWbsProgressBar: false,
        };
      case UPDATE_SHAREPOINT_CONTENT_TYPE:
          return {
            ...state,
            isDocumentMetaUpdating: true,
          };
      case UPDATE_SHAREPOINT_CONTENT_TYPE_SUCCESS:
          return {
            ...state,
            isDocumentMetaUpdating: false,
          };
      case UPDATE_SHAREPOINT_CONTENT_TYPE_FAIL:
          return {
            ...state,
            isDocumentMetaUpdating: false,
          };
      case RENAME_WBS_TEMPLATE_FAIL:
        return {
          ...state,
          showWbsProgressBar: false,
        };

      default:
        return state;
    }
  }
}

export function saveWbsCreateStateAttr(attr: string, value: any) {
  return {
    type: SAVE_WBS_CREATE_STATE_ATTR,
    [attr]: value,
  };
}

export const readWbsCreateListData = (filter: WbsCreateListPaneFilter) => {
  return {
    types: [
      READ_WBS_CREATE_LIST_DATA,
      READ_WBS_CREATE_LIST_DATA_SUCCESS,
      READ_WBS_CREATE_LIST_DATA_FAIL,
    ],
    payload: {
      request: {
        url: READ_WBS_CREATE_LIST_DATA_EP,
        method: 'POST',
        data: filter,
      },
    },
  };
};

export const getWbsCreateLevelData = () => {
  return {
    types: [
      GET_WBS_CREATE_LEVEL_DATA,
      GET_WBS_CREATE_LEVEL_DATA_SUCCESS,
      GET_WBS_CREATE_LEVEL_DATA_FAIL,
    ],
    payload: {
      request: {
        url: GET_WBS_CREATE_LEVEL_DATA_EP,
        method: 'GET',
      },
    },
  };
};

export const saveNewWbsNode = (newNode: NodeData) => {
  return {
    types: [CREATE_WBS_NODE, CREATE_WBS_NODE_SUCCESS, CREATE_WBS_NODE_FAIL],
    payload: {
      request: {
        url: CREATE_WBS_NODE_EP,
        method: 'POST',
        data: newNode,
      },
    },
  };
};

export const readWbsTaxonomyList = (id: string) => {
  return {
    types: [
      READ_WBS_TAXONOMY_LIST,
      READ_WBS_TAXONOMY_LIST_SUCCESS,
      READ_WBS_TAXONOMY_LIST_FAIL,
    ],
    payload: {
      request: {
        url: READ_WBS_TAXONOMY_LIST_EP + id,
        method: 'GET',
      },
    },
  };
};

export const createWbsTemplate = (data: { id: string; name: string }) => {
  return {
    types: [
      CREATE_WBS_TEMPLATE,
      CREATE_WBS_TEMPLATE_SUCCESS,
      CREATE_WBS_TEMPLATE_FAIL,
    ],
    payload: {
      request: {
        url: CREATE_WBS_TEMPLATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const readWbsTaxonomyByTemId = (id: string | undefined) => { //TODO: Stop unnecessary API calls
  return {
    types: [
      GET_WBS_TAXONOMY_BY_TEMPLATE_ID,
      GET_WBS_TAXONOMY_BY_TEMPLATE_ID_SUCCESS,
      GET_WBS_TAXONOMY_BY_TEMPLATE_ID_FAIL,
    ],
    payload: {
      request: {
        url: GET_WBS_TAXONOMY_BY_TEMPLATE_ID_EP + id,
        method: 'GET',
      },
    },
  };
};

export const getWbsProductById = (id: string | undefined) => {
  return {
    types: [
      GET_WBS_PRODUCT_BY_ID,
      GET_WBS_PRODUCT_BY_ID_SUCCESS,
      GET_WBS_PRODUCT_BY_ID_FAIL,
    ],
    payload: {
      request: {
        url: GET_WBS_PRODUCT_BY_ID_EP + id,
        method: 'GET',
      },
    },
  };
};

export const createWbsProduct = (data: any) => {
  return {
    types: [
      CREATE_WBS_PRODUCT,
      CREATE_WBS_PRODUCT_SUCCESS,
      CREATE_WBS_PRODUCT_FAIL,
    ],
    payload: {
      request: {
        url: CREATE_WBS_PRODUCT_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const renameWbsTemplate = (data: any) => {
  return {
    types: [
      RENAME_WBS_TEMPLATE,
      RENAME_WBS_TEMPLATE_SUCCESS,
      RENAME_WBS_TEMPLATE_FAIL,
    ],
    payload: {
      request: {
        url: RENAME_WBS_TEMPLATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getWBSDocumentFieldsAndcolumns = (data: any) => {
  return {
    types: [
      FETCH_WBS_DOCUMNT_COLUMNS,
      FETCH_WBS_DOCUMNT_COLUMNS_SUCCESS,
      FETCH_WBS_DOCUMNT_COLUMNS_FAIL,
    ],
    payload: {
      request: {
        url: SHAREPOINT_DOCUMENT_CONTENT_TYPE_COLUMN_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getSharepointContentTypes = (data: any) => {
  return {
    types: [
      FETCH_SHAREPOINT_CONTENT_TYPES,
      FETCH_SHAREPOINT_CONTENT_TYPES_SUCCESS,
      FETCH_SHAREPOINT_CONTENT_TYPES_FAIL,
    ],
    payload: {
      request: {
        url: SHAREPOINT_DOCUMENT_CONTENT_TYPES,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const updateContentTypeValues = (data: any) => {
  return {
    types: [
      UPDATE_SHAREPOINT_CONTENT_TYPE,
      UPDATE_SHAREPOINT_CONTENT_TYPE_SUCCESS,
      UPDATE_SHAREPOINT_CONTENT_TYPE_FAIL,
    ],
    payload: {
      request: {
        url: SHAREPOINT_DOCUMENT_CONTENT_TYPE_UPDATE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const updateWbsTaxonomyParent = (data: any) => {
  return {
    types: [
      UPDATE_WBS_TAXONOMY_PARENT_NODE,
      UPDATE_WBS_TAXONOMY_PARENT_NODE_SUCCESS,
      UPDATE_WBS_TAXONOMY_PARENT_NODE_FAIL,
    ],
    payload: {
      request: {
        url: UPDATE_WBS_TAXONOMY_PARENT_NODE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

import { IColumn, Selection } from '@fluentui/react/lib/DetailsList';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import history from '../../../history';
import { getById } from '../../../reducers/billOfResourcesReducer';
import { messageService } from '../../../services/messageService';
import { getContractingUnit, getProject } from '../../../shared/util';
import {
  BillOfResource,
  BorFormData,
  BorListPaneItem,
  FilterItem,
  ResourceFilterItem,
  ResourceListPaneItem,
  ShortCutPaneItem,
} from '../../../types/billOfResources';
import ListPaneComponent from './component';

export interface State {
  redirect: boolean;
  isChange: boolean;
  loading: boolean;
  filter: FilterItem;
  resourceFilter: ResourceFilterItem;
  announcedMessage: string;
  selectedListItemIds: [] | string[];
  selectedListItemSequenceCode: [] | string[];
  formData: BillOfResource;
  selectedListItems: BorListPaneItem[];
}

export interface Props extends WithTranslation {
  selectedShortCutItem: ShortCutPaneItem;
  borListPaneItem: BorListPaneItem[];
  resourceListPaneItem: ResourceListPaneItem[];
  reloadListPaneItem: () => void;
  handelFilter: (filter: FilterItem) => void;
  handelResourceFilter: (filter: ResourceFilterItem) => void;
  isDataLoaded?: boolean;
  isChange: boolean;
  filter: FilterItem;
  resourceFilter: ResourceFilterItem;
  resourceTypes: [];
  loadMsg: string;
  toleranceStatesFilter: [];
  productStatesFilter: [];
  itemTypesFilter: [];
  handleSelectedListPaneItem: (selectedItemIds: [] | string[]) => void;
  formData: BillOfResource;
  getById: (id: string) => void;
  projectId: string | null;
  reSizer: number;
  showProgress: boolean;
}

class ListPane extends Component<Props, State> {
  private _borSelection: Selection;
  private _Selection: Selection;
  private _borColumns: IColumn[];
  private _resourceColumns: IColumn[];
  private _columnsReSizer: IColumn[];
  subscription: any;

  constructor(props: Props) {
    super(props);
    const { t } = this.props;
    this._borSelection = new Selection({
      onSelectionChanged: () => {
        this.setState(
          {
            selectedListItemIds: this._getSelectedIds(),
            selectedListItemSequenceCode: this._getselectedSequenceCode(),
            selectedListItems: this._getSelectedListItems(),
          },
          this.handleSelectedItem,
        );
      },
    });

    this._Selection = new Selection({
      onSelectionChanged: () => {
        this.setState(
          {
            selectedListItemIds: this._getSelectedIds(),
            selectedListItemSequenceCode: this._getselectedSequenceCode(),
            selectedListItems: this._getSelectedListItems(),
          },
          this.handleSelectedItem,
        );
      },
    });
    this.state = {
      selectedListItems: [],
      selectedListItemIds: [],
      selectedListItemSequenceCode: [],
      redirect: false,
      isChange: false,
      loading: false,
      announcedMessage: '',
      filter: {
        borTitle: null,
        product: null,
        locationParent: null,
        utilityParent: null,
        sorter: {
          attribute: null,
          order: null,
        },
      },
      resourceFilter: {
        deliveryRequested: null,
        invoiced: null,
        purchased: null,
        required: null,
        resourceTitle: null,
        resourceTypeId: null,
        warf: null,
        sorter: {
          attribute: null,
          order: null,
        },
      },
      formData: BorFormData,
    };

    this._borColumns = [
      {
        key: 'column1',
        name: t('borTitle'),
        fieldName: 'borTitle',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        onColumnClick: this._borOnColumnClick,
      },
      {
        key: 'column2',
        name: t('product'),
        fieldName: 'product',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._borOnColumnClick,
      },
      {
        key: 'column3',
        name: t('utilityTaxonomy'),
        fieldName: 'utilityParent',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._borOnColumnClick,
      },
      {
        key: 'column4',
        name: t('location'),
        fieldName: 'locationParent',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._borOnColumnClick,
      },
    ];

    this._resourceColumns = [
      {
        key: 'column1',
        name: t('resourceType'),
        fieldName: 'resourceType',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        onColumnClick: this._resourceOnColumnClick,
      },
      {
        key: 'column2',
        name: t('resourceTitle'),
        fieldName: 'resourceTitle',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._resourceOnColumnClick,
      },
      {
        key: 'column3',
        name: t('required'),
        fieldName: 'required',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._resourceOnColumnClick,
      },
      {
        key: 'column4',
        name: t('purchased'),
        fieldName: 'purchased',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._resourceOnColumnClick,
      },
      {
        key: 'column5',
        name: t('deliveryRequested'),
        fieldName: 'deliveryRequested',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._resourceOnColumnClick,
      },
      {
        key: 'column6',
        name: t('warf'),
        fieldName: 'warf',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._resourceOnColumnClick,
      },
      {
        key: 'column7',
        name: t('consumed'),
        fieldName: 'consumed',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._resourceOnColumnClick,
      },
      {
        key: 'column8',
        name: t('return'),
        fieldName: 'return',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._resourceOnColumnClick,
      },
      {
        key: 'column9',
        name: t('invoiced'),
        fieldName: 'invoiced',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._resourceOnColumnClick,
      },
    ];

    this._columnsReSizer = [
      {
        key: 'column1',
        name: t('borTitle'),
        fieldName: 'borTitle',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        onColumnClick: this._borOnColumnClick,
      },
    ];
  }

  static getDerivedStateFromProps(nextProps: any) {
    if (nextProps.isChange) {
      nextProps.reloadListPaneItem();
    }
    return {
      loading: false,
      filter: nextProps.filter,
      resourceFilter: nextProps.resourceFilter,
      formData: nextProps.formData,
    };
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewBor) {
          this._borSelection.selectToKey('', true);
          this._Selection.selectToKey('', true);
        }
        if (data.data.reloadBorListPaneItem) {
          this.props.reloadListPaneItem();
        }
        if (data.data.reloadResourceListPaneItem) {
          this.props.reloadListPaneItem();
        }
        if (data.data.resetBorFilter) {
          this.resetFilter();
          this.props.reloadListPaneItem();
        }
        if (data.data.resetListPaneSelection) {
          this._borSelection.setAllSelected(false);
          this._Selection.setAllSelected(false);
          this.resetSelection();
        }
        if (data.data.isBorReturn) {
          this._borSelection.selectToKey('', true);
          this._Selection.selectToKey('', true);
        }
      }
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.isDataLoaded != this.props.isDataLoaded) {
      if (this.props.isDataLoaded) {
        this.resetSelection();
      }
    }
  }

  handleListPaneItemClick = (id: string) => {
    //this.props.getById(id);
    //history.push(`/bill-Of-resources/` + id);
    if (getContractingUnit()) {
      history.push(
        `/CU/${getContractingUnit()}/project/${getProject()}/bill-Of-resources/${id}`,
      );
    } else {
      history.push(`/bill-Of-resources/${id}`);
    }
  };

  handleSelectedItem = () => {
    this.props.handleSelectedListPaneItem(this.state.selectedListItemIds);
    if (this.state.selectedListItemSequenceCode) {
      let lastId = this.state.selectedListItemSequenceCode.pop();
      // if(lastId){
      //   history.push(`/bill-Of-resources/` + lastId);
      //   //history.push(`/projects/project/${this.props.projectId}/product/pbs/` + lastId);
      // }
      if (lastId) {
        if (getContractingUnit()) {
          history.push(
            `/CU/${getContractingUnit()}/project/${getProject()}/bill-Of-resources/${lastId}`,
          );
        } else {
          history.push(`/bill-Of-resources/${lastId}`);
        }
      }
    }
  };

  handleFilterChange = (filter: FilterItem) => {
    this.props.handelFilter(filter);
  };

  handleResourceFilterChange = (filter: ResourceFilterItem) => {
    this.props.handelResourceFilter(filter);
  };

  resetFilter = () => {
    let filter: FilterItem = {
      borTitle: null,
      product: null,
      locationParent: null,
      utilityParent: null,
      sorter: {
        attribute: null,
        order: null,
      },
    };
    let resourcefilter: ResourceFilterItem = {
      deliveryRequested: null,
      invoiced: null,
      purchased: null,
      required: null,
      resourceTitle: null,
      resourceTypeId: null,
      warf: null,
      sorter: {
        attribute: null,
        order: null,
      },
    };

    this.props.handelFilter(filter);
    this.props.handelResourceFilter(resourcefilter);
  };

  handleFilterSortChange = () => {
    this.props.handelFilter(this.state.filter);
    messageService.sendMessage({
      reloadBorListPaneItem: true,
    });
  };

  resetSelection = () => {
    if (
      this.state.formData &&
      this.state.formData.itemId &&
      this.props.isDataLoaded
    ) {
      this._borSelection.setKeySelected(
        this.state.formData.itemId,
        true,
        false,
      );
      this._Selection.setKeySelected(this.state.formData.itemId, true, false);
    } else {
      this._borSelection.selectToKey('', true);
      this._Selection.selectToKey('', true);
    }
  };

  render() {
    return (
      <div>
        <ListPaneComponent
          selectedShortCutItem={this.props.selectedShortCutItem}
          borListPaneItem={this.props.borListPaneItem}
          resourceListPaneItem={this.props.resourceListPaneItem}
          borSelection={this._borSelection}
          selection={this._borSelection}
          handleListPaneItemClick={(id: string) =>
            this.handleListPaneItemClick(id)
          }
          resetSelection={() => this.resetSelection()}
          formData={this.state.formData}
          borColumns={(this.props.reSizer === 300) ? this._columnsReSizer : this._borColumns}
          resourceColumns={(this.props.reSizer === 300) ? this._columnsReSizer : this._resourceColumns}
          filter={this.props.filter}
          resourceFilter={this.props.resourceFilter}
          loadMsg={this.props.loadMsg}
          resourceTypes={this.props.resourceTypes}
          handleFilterChange={(filter: FilterItem) =>
            this.handleFilterChange(filter)
          }
          handleResourceFilterChange={(filter: ResourceFilterItem) =>
            this.handleResourceFilterChange(filter)
          }
          isDataLoaded={this.props.isDataLoaded}
          dropDownOptions={{
            toleranceStatesFilter: this.props.toleranceStatesFilter,
            productStatesFilter: this.props.productStatesFilter,
            itemTypesFilter: this.props.itemTypesFilter,
          }}
          showProgress={this.props.showProgress}
        />
      </div>
    );
  }

  private _getSelectedIds() {
    let deleteList: any = [];
    let SelectedItem = this._borSelection.getSelection() as BorListPaneItem[];
    if (SelectedItem) {
      deleteList = SelectedItem.map((item: any) => {
        return item ? item.id : null;
      });
    }
    return deleteList;
  }

  private _getselectedSequenceCode() {
    let deleteList: any = [];
    let SelectedItem = this._borSelection.getSelection() as BorListPaneItem[];
    if (SelectedItem) {
      deleteList = SelectedItem.map((item: any) => {
        return item ? item.itemId : null;
      });
    }
    return deleteList;
  }

  private _getSelectedListItems = () => {
    let SelectedListItems = this._borSelection.getSelection() as BorListPaneItem[];

    return SelectedListItems;
  };

  private _borOnColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn,
  ): void => {
    const newColumns: IColumn[] = this._borColumns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key,
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        let filter = this.state.filter;

        let fieldName = currColumn.fieldName;

        let sortby = currColumn.isSortedDescending ? 'desc' : 'asc';
        filter.sorter.attribute = fieldName ? fieldName : null;
        filter.sorter.order = sortby ? sortby : null;
        this.setState(
          {
            filter: filter,
          },
          this.handleFilterSortChange,
        );
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
  };

  private _resourceOnColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn,
  ): void => {
    const newColumns: IColumn[] = this._resourceColumns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key,
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        let filter = this.state.filter;

        let fieldName = currColumn.fieldName;

        let sortby = currColumn.isSortedDescending ? 'desc' : 'asc';
        filter.sorter.attribute = fieldName ? fieldName : null;
        filter.sorter.order = sortby ? sortby : null;
        this.setState(
          {
            filter: filter,
          },
          this.handleFilterSortChange,
        );
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
  };
}

const mapStateToProps = (state: any, props: any) => {
  return {
    toleranceStatesFilter: state.billOfResource.toleranceStatesFilter,
    productStatesFilter: state.billOfResource.productStatesFilter,
    itemTypesFilter: state.billOfResource.itemTypesFilter,
    isChange: state.billOfResource.isChange,
  };
};

const mapDispatchToProps = {
  getById,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ListPane)),
);

export const LOAD_HUMAN_RESOURCE_SHORTCUTPANE = 'uprince/LOAD_HUMAN_RESOURCE_SHORTCUTPANE';
export const LOAD_HUMAN_RESOURCE_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_HUMAN_RESOURCE_SHORTCUTPANE_SUCCESS';
export const LOAD_HUMAN_RESOURCE_SHORTCUTPANE_FAIL = 'uprince/LOAD_HUMAN_RESOURCE_SHORTCUTPANE_FAIL';

export const LOAD_HUMAN_RESOURCE_LSITPANE = 'uprince/LOAD_HUMAN_RESOURCE_LSITPANE';
export const LOAD_HUMAN_RESOURCE_LSITPANE_SUCCESS = 'uprince/LOAD_HUMAN_RESOURCE_LSITPANE_SUCCESS';
export const LOAD_HUMAN_RESOURCE_LSITPANE_FAIL = 'uprince/LOAD_HUMAN_RESOURCE_LSITPANE_FAIL';

export const LOAD_HUMAN_RESOURCE_DROPDOWN = 'uprince/LOAD_HUMAN_RESOURCE_DROPDOWN';
export const LOAD_HUMAN_RESOURCE_DROPDOWN_SUCCESS = 'uprince/LOAD_HUMAN_RESOURCE_DROPDOWN_SUCCESS';
export const LOAD_HUMAN_RESOURCE_DROPDOWN_FAIL = 'uprince/LOAD_HUMAN_RESOURCE_DROPDOWN_FAIL';

export const LOAD_HR_CERTIFICATIONS_LISTPANE = 'uprince/LOAD_HR_CERTIFICATIONS_LISTPANE';
export const LOAD_HR_CERTIFICATIONS_LISTPANE_SUCCESS = 'uprince/LOAD_HR_CERTIFICATIONS_LISTPANE_SUCCESS';
export const LOAD_HR_CERTIFICATIONS_LISTPANE_FAIL = 'uprince/LOAD_HR_CERTIFICATIONS_LISTPANE_FAIL';

export const SAVE_HUMAN_RESOURCE = 'uprince/SAVE_HUMAN_RESOURCE';
export const SAVE_HUMAN_RESOURCE_SUCCESS = 'uprince/SAVE_HUMAN_RESOURCE_SUCCESS';
export const SAVE_HUMAN_RESOURCE_FAIL = 'uprince/SAVE_HUMAN_RESOURCE_FAIL';

export const LOAD_HUMAN_RESOURCE_BY_ID = 'uprince/LOAD_HUMAN_RESOURCE_BY_ID';
export const LOAD_HUMAN_RESOURCE_BY_ID_SUCCESS = 'uprince/LOAD_HUMAN_RESOURCE_BY_ID_SUCCESS';
export const LOAD_HUMAN_RESOURCE_BY_ID_FAIL = 'uprince/LOAD_HUMAN_RESOURCE_BY_ID_FAIL';

export const LOAD_BOR_RESOURCES_BY_ID = 'uprince/LOAD_BOR_RESOURCES_BY_ID';
export const LOAD_BOR_RESOURCES_BY_ID_SUCCESS = 'uprince/LOAD_BOR_RESOURCES_BY_ID_SUCCESS';
export const LOAD_BOR_RESOURCES_BY_ID_FAIL = 'uprince/LOAD_BOR_RESOURCES_BY_ID_FAIL';

export const SET_HUMAN_RESOURCE_UID = 'uprince/SET_HUMAN_RESOURCE_UID';
export const CLEAR_HUMAN_RESOURCE_UID = 'uprince/CLEAR_HUMAN_RESOURCE_UID';
export const CLEAR_HUMAN_RESOURCE_FORM_DATA = 'uprince/CLEAR_HUMAN_RESOURCE_FORM_DATA';
export const RESET_HUMAN_RESOURCE_IS_CHANGE = 'uprice/RESET_HUMAN_RESOURCE_IS_CHANGE';

export const READ_HUMAN_RESOURCE_TAXONOMY_ID_BY_PERSON_ID = 'uprince/READ_HUMAN_RESOURCE_TAXONOMY_ID_BY_PERSON_ID';
export const READ_HUMAN_RESOURCE_TAXONOMY_ID_BY_PERSON_ID_SUCCESS = 'uprince/READ_HUMAN_RESOURCE_TAXONOMY_ID_BY_PERSON_ID_SUCCESS';
export const READ_HUMAN_RESOURCE_TAXONOMY_ID_BY_PERSON_ID_FAIL = 'uprince/READ_HUMAN_RESOURCE_TAXONOMY_ID_BY_PERSON_ID_FAIL';

export const READ_LABOUR_HISTORY_BY_PERSON_ID = 'uprince/READ_LABOUR_HISTORY_BY_PERSON_ID';
export const READ_LABOUR_HISTORY_BY_PERSON_ID_SUCCESS = 'uprince/READ_LABOUR_HISTORY_BY_PERSON_ID_SUCCESS';
export const READ_LABOUR_HISTORY_BY_PERSON_ID_FAIL = 'uprince/READ_LABOUR_HISTORY_BY_PERSON_ID_FAIL';

export const GET_HUMAN_RESOURCE_ROLES_DROPDOWN = 'uprince/GET_HUMAN_RESOURCE_ROLES_DROPDOWN';
export const GET_HUMAN_RESOURCE_ROLES_DROPDOWN_SUCCESS = 'uprince/GET_HUMAN_RESOURCE_ROLES_DROPDOWN_SUCCESS';
export const GET_HUMAN_RESOURCE_ROLES_DROPDOWN_FAIL = 'uprince/GET_HUMAN_RESOURCE_ROLES_DROPDOWN_FAIL';

export const CLEAR_HUMAN_RESOURCEUID = 'uprice/CLEAR_HUMAN_RESOURCEUID';

export const READ_ABSENCE = 'uprince/READ_ABSENCE';
export const READ_ABSENCE_SUCCESS = 'uprince/READ_ABSENCE_SUCCESS';
export const READ_ABSENCE_FAIL = 'uprince/READ_ABSENCE_FAIL';

export const READ_ABSENCE_LIST_BY_PERSON = 'uprince/READ_ABSENCE_LIST_BY_PERSON';
export const READ_ABSENCE_LIST_BY_PERSON_SUCCESS = 'uprince/READ_ABSENCE_LIST_BY_PERSON_SUCCESS';
export const READ_ABSENCE_LIST_BY_PERSON_FAIL = 'uprince/READ_ABSENCE_LIST_BY_PERSON_FAIL';

export const CREATE_ABSENCE = 'uprince/CREATE_ABSENCE';
export const CREATE_ABSENCE_SUCCESS = 'uprince/CREATE_ABSENCE_SUCCESS';
export const CREATE_ABSENCE_FAIL = 'uprince/CREATE_ABSENCE_FAIL';

export const DELETE_ABSENCE = 'uprince/DELETE_ABSENCE';
export const DELETE_ABSENCE_SUCCESS = 'uprince/DELETE_ABSENCE_SUCCESS';
export const DELETE_ABSENCE_FAIL = 'uprince/DELETE_ABSENCE_FAIL';

export const GET_ABSENCE_LEAVETYPE_DROPDOWN = 'uprince/GET_ABSENCE_LEAVETYPE_DROPDOWN';
export const GET_ABSENCE_LEAVETYPE_DROPDOWN_SUCCESS = 'uprince/GET_ABSENCE_LEAVETYPE_DROPDOWN_SUCCESS';
export const GET_ABSENCE_LEAVETYPE_DROPDOWN_FAIL = 'uprince/GET_ABSENCE_LEAVETYPE_DROPDOWN_FAIL';

export const GET_CONTRACT_TYPE_DROPDOWN = 'uprince/GET_CONTRACT_TYPE_DROPDOWN';
export const GET_CONTRACT_TYPE_DROPDOWN_SUCCESS = 'uprince/GET_CONTRACT_TYPE_DROPDOWN_SUCCESS';
export const GET_CONTRACT_TYPE_DROPDOWN_FAIL = 'uprince/GET_CONTRACT_TYPE_DROPDOWN_FAIL';

export const CREATE_CONTRACT = 'uprince/CREATE_CONTRACT';
export const CREATE_CONTRACT_SUCCESS = 'uprince/CREATE_CONTRACT_SUCCESS';
export const CREATE_CONTRACT_FAIL = 'uprince/CREATE_CONTRACT_FAIL';

export const UPLOAD_CONTRACT_DOC= 'uprince/UPLOAD_CONTRACT_DOC';
export const UPLOAD_CONTRACT_DOC_SUCCESS = 'uprince/UPLOAD_CONTRACT_DOC_SUCCESS';
export const UPLOAD_CONTRACT_DOC_FAIL = 'uprince/UPLOAD_CONTRACT_DOC_FAIL';

export const SAVE_ACTIVE_STATE_ATTR = 'uprice/SAVE_ACTIVE_STATE_ATTR';
export const SAVE_CU_HR_STATE_ATTRIBUTE = 'uprince/SAVE_CU_HR_STATE_ATTRIBUTE';

export const DELETE_HUMAN_RESOURCE = 'uprince/DELETE_HUMAN_RESOURCE';
export const DELETE_HUMAN_RESOURCE_SUCCESS = 'uprince/DELETE_HUMAN_RESOURCE_SUCCESS';
export const DELETE_HUMAN_RESOURCE_FAIL = 'uprince/DELETE_HUMAN_RESOURCE_FAIL';

export const LOAD_ASSIGNED_HUMAN_RESOURCES = 'uprince/LOAD_ASSIGNED_HUMAN_RESOURCES';
export const LOAD_ASSIGNED_HUMAN_RESOURCES_SUCCESS = 'uprince/LOAD_ASSIGNED_HUMAN_RESOURCES_SUCCESS';
export const LOAD_ASSIGNED_HUMAN_RESOURCES_FAIL = 'uprince/LOAD_ASSIGNED_HUMAN_RESOURCES_FAIL';

export const READ_CONTRACT_LIST_BY_PERSON = 'uprince/READ_CONTRACT_LIST_BY_PERSON';
export const READ_CONTRACT_LIST_BY_PERSON_SUCCESS = 'uprince/READ_CONTRACT_LIST_BY_PERSON_SUCCESS';
export const READ_CONTRACT_LIST_BY_PERSON_FAIL = 'uprince/READ_CONTRACT_LIST_BY_PERSON_FAIL';

export const DELETE_CONTRACT = 'uprince/DELETE_CONTRACT';
export const DELETE_CONTRACT_SUCCESS = 'uprince/DELETE_CONTRACT_SUCCESS';
export const DELETE_CONTRACT_FAIL = 'uprince/DELETE_CONTRACT_FAIL';
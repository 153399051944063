import { DirectionalHint, Label, Link, Stack, Text, TooltipHost } from '@fluentui/react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { iconColors, uPrinceTheme } from '../../../../../theme';
import { ShortCutPaneItem } from '../../../../types/billOfResources';

const classNames = mergeStyleSets({
  wrapper: {
    height: '(80vh)!important',
    position: 'relative',
  },
  selected: {
    fontWeight: 'bold',
    color: '#854114!important',
  },
});

export const ShortCutPaneComponent = (props: {
  shortCutPaneFilters: ShortCutPaneItem[];
  currentActiveSection: string | null;
  handelShortCutPaneFilter: (selectedItem: ShortCutPaneItem) => void;
  resetFilter: () => void;
}) => {
  const { t } = useTranslation();

  const setFilterOption = (selectedItem: ShortCutPaneItem) => {
    if (selectedItem.type === 'all') {
      props.resetFilter();
    }
    props.handelShortCutPaneFilter(selectedItem);
  };
  const renderFilterItems = () => {
    if (props.shortCutPaneFilters) {
      return props.shortCutPaneFilters.map((item: any, index) => {
        let divider = false;
        if (index > 0) {
          const previousItem: ShortCutPaneItem =
            props.shortCutPaneFilters[index - 1];
          if (previousItem.type !== item.type) {
            divider = true;
          } else {
            divider = false;
          }
        } else {
          divider = true;
        }
        if (item.type != 'Bor') {
          return (
            <div key={item.id + 'div'}>
              {divider && index !== 0 && (
                <hr
                  key={item.id + 'hr'}
                  style={{ marginBottom: 15, borderTop: '1px solid #d2d2d2' }}
                />
              )}
              <Link
                key={item.id + 'link'}
                className={`${
                  props.currentActiveSection === item.id ? classNames.selected : ''
                }`}
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  setFilterOption(item);
                }}
              >
                <TooltipHost
                  key={item.id + 't'}
                  content={item.name}
                  id="filter-opt-1"
                  calloutProps={{ gapSpace: 0 }}
                  directionalHint={DirectionalHint.rightCenter}
                >
                  <i
                    key={item.id + 'i'}
                    className="ms-Icon ms-Icon--GotoToday"
                    aria-labelledby="filter-opt-1"
                    aria-hidden="true"
                    style={props.currentActiveSection === item.id ? { color: iconColors.iconActiveColor } : { color: uPrinceTheme.palette.themePrimary }}
                  ></i>
                </TooltipHost>
                <i
                  key={item.id + 'i2'}
                  className="filter-icon ms-Icon ms-Icon--GotoToday filter-icon"
                  aria-hidden="true"
                  style={props.currentActiveSection === item.id ? { color: iconColors.iconActiveColor } : { color: uPrinceTheme.palette.themePrimary }}

                ></i>
                <Text key={item.id}>{item.name} </Text>
              </Link>
            </div>
          );
        }
      });
    }
  };

  return (
    <div className={` filter-panel-left ${classNames.wrapper}`} style={{ height: '80vh' }}>
      <div className="inner-container">
        <div className="filter-data filter-title">
          <TooltipHost
            content={t('billOfResources')}
            // This is the important part!
            id="filter-opt-title"
            calloutProps={{ gapSpace: 0 }}
            directionalHint={DirectionalHint.rightCenter}
          >
            <Label
              aria-describedby={'filter-opt-title'}
              className={'short-cut-pane-label'}
            >
              {t('billOfResources')}
            </Label>
          </TooltipHost>
        </div>

        <div className="filter-option-list">
          <Stack
            gap={15}
            verticalFill
            styles={{
              root: {
                width: '100%',
                verticalAlign: 'center',
              },
            }}
          >
            {renderFilterItems()}
          </Stack>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShortCutPaneComponent);

import {
  AnimationStyles,
  ContextualMenuItemType,
  FontWeights,
  getTheme,
  mergeStyles,
  mergeStyleSets,
  PrimaryButton,
} from '@fluentui/react';
import {Icon, IIconProps} from 'office-ui-fabric-react/lib/Icon';
import {Pivot, PivotItem} from 'office-ui-fabric-react/lib/Pivot';
import React from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {iconColors, uPrinceTheme} from '../../../theme';
import history from '../../history';
import {messageService} from '../../services/messageService';
import {AccessLevel, MY_ENV_FEATURES} from '../../types/permissions';
import MenuCard from '../menuCard/menu/component';
import {getContractingUnit, hasPermissions} from '../util';
import './navBar.css';
import ENV from '../../../env.json';

const theme = getTheme();

const pivotStyle = {
  root: {
    float: 'left',
    selectors: { '& .is-selected i': { color: iconColors.iconActiveColor } },
  },
  icon: { color: uPrinceTheme.palette.themePrimary },
  linkIsSelected: { selectors: { ':before': { marginBottom: '0px' } } },
};

class MeNavBar extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      redirect: false,
      redirectTo: '',
      isCalloutVisible: false,
      showNewButton: false,
    };
  }

  subscription: any;
  addWhiteIconButtonStyles = {
    root: { color: uPrinceTheme.palette.white },
    rootHovered: { color: theme.palette.neutralDark },
  };
  addIconWhite: IIconProps = {
    iconName: 'Add',
    styles: this.addWhiteIconButtonStyles,
  };
  styles = mergeStyleSets({
    buttonArea: {
      textAlign: 'center',
      margin: '0 0px',
      minWidth: 32,
      height: 32,
    },
    newButtonArea: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    callout: { maxWidth: 300 },
    header: { padding: '18px 24px 12px' },
    title: [
      uPrinceTheme.fonts.xLarge,
      {
        margin: 0,
        fontWeight: FontWeights.semilight,
      },
    ],
    inner: {
      height: '100%',
      padding: '0 24px 20px',
    },
    actions: {
      position: 'relative',
      marginTop: 20,
      width: '100%',
      whiteSpace: 'nowrap',
    },
    subtext: [
      uPrinceTheme.fonts.small,
      {
        margin: 0,
        fontWeight: FontWeights.semilight,
      },
    ],
    link: [
      uPrinceTheme.fonts.medium,
      { color: uPrinceTheme.palette.neutralPrimary },
    ],
  });

  menuProps = () => ({
    shouldFocusOnMount: true,
    items: [
      {
        key: 'newItem', text: 'New', onClick: () => {
        },
      },
      { key: 'divider_1', itemType: ContextualMenuItemType.Divider },
      {
        key: 'rename', text: 'Rename', onClick: () => {
        },
      },
      {
        key: 'edit', text: 'Edit', onClick: () => {
        },
      },
      {
        key: 'properties', text: 'Properties', onClick: () => {
        },
      },
    ],
  });

  handelRedirect = (item: any) => {
    history.push(item);
  };

  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data && data.data && data.data.shortCutPaneExpanded) {
          this.setState({ showNewButton: true });
        } else if (
          data.data.hasOwnProperty('shortCutPaneExpanded') &&
          !data.data.shortCutPaneExpanded
        ) {
          this.setState({ showNewButton: false });
        }
      }
    });
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  onIconClick = () => {
    if (this.props.contractingUnit) {
      history.push(`/CU/${this.props.contractingUnit.title}`);
    }
  };

  toggleIsCalloutVisible = () => {
    this.setState({ isCalloutVisible: !this.state.isCalloutVisible });
  };

  render() {
    const animation = mergeStyles(
      this.state.showNewButton
        ? AnimationStyles.slideRightIn400
        : AnimationStyles.slideLeftIn400,
    );
    let cu = '';
    if (
      window.location.pathname &&
      window.location.pathname.split('/').length > 0
    ) {
      cu = window.location.pathname.split('/')[2];
    }
    const { t } = this.props;
    const hasCUPermission = hasPermissions(
      this.props.currentUser?.groups,
      this.props.organisation,
      getContractingUnit()?.replace('-', ''),
    ).hasContractingUnitPermission;
    const isProjectManager = hasPermissions(
      this.props.currentUser?.groups,
      this.props.organisation,
      getContractingUnit()?.replace('-', ''),
    ).isProjectManager;
    return (
      <div className="navBar">
        <div className={this.styles.buttonArea}>
          <Icon
            iconName="ViewAll"
            className="navicon"
            onClick={() => {
              if (!ENV.DISABLE_MENU_CARD) {
                this.toggleIsCalloutVisible();
              }
            }}
          />
        </div>
        {this.state.showNewButton && ENV.ENABLE_NAV_BAR_BUTTON && (
          <div>
            <PrimaryButton
              iconProps={this.addIconWhite}
              text={t('new')}
              menuProps={this.menuProps()}
              style={{
                marginTop: 5,
                marginBottom: 5,
                marginRight: 35,
                height: 30,
              }}
              onClick={() => {
              }}
            />
          </div>
        )}
        {!ENV.ENABLE_NAV_BAR_BUTTON && (
          <div
            style={{
              marginTop: 5,
              marginBottom: 5,
              marginRight: 130,
              height: 30,
            }}
          ></div>
        )}
        <div>
          <Pivot
            selectedKey={this.props.selectedKey}
            initialSelectedIndex={
              !hasPermissions(this.props.currentUser?.groups).isProjectManager
                ? 0
                : this.props.selectedKey
            }
            styles={pivotStyle}
            onLinkClick={(item) => {
              item ? this.handelRedirect(item.props.itemKey) : null;
            }}
          >
            {/* {ENV.MY_ENV_FEATURES.includes(MY_ENV_FEATURES.DAY_PLANNING) && (
            <PivotItem
              itemKey={'/me/visualPlan'}
              headerText={t('visualPlan')}
              itemIcon="BarChartHorizontal"
            ></PivotItem>
            )} */}
            {ENV.MY_ENV_FEATURES.includes(MY_ENV_FEATURES.DAY_PLANNING) && (
            <PivotItem
              itemKey={'/me/project-planning'}
              headerText={t('projectPlaning')}
              itemIcon="StackedBarChart"
            ></PivotItem>
            )}

            {ENV.MY_ENV_FEATURES.includes(MY_ENV_FEATURES.DAY_PLANNING) && (
              <PivotItem
                itemKey={'/me/day-planning'}
                headerText={t('myCalendar')}
                itemIcon="CalendarSettings"
              ></PivotItem>
            )}
            { ENV.MY_ENV_FEATURES.includes(MY_ENV_FEATURES.DAY_PLANNING_NEW) && (
              <PivotItem
                itemKey={'/me/day-planning-new'}
                headerText={t('teamPlanboard')}
                itemIcon="ColumnRightTwoThirdsEdit"
              ></PivotItem>
            )}
            {ENV.MY_ENV_FEATURES.includes(MY_ENV_FEATURES.PROJECT_DAY_PLANNING) && (
              <PivotItem
                itemKey={'/me/project-day-planning'}
                headerText={t('projectPlanboard')}
                itemIcon="QuadColumn"
              ></PivotItem>)}
            {ENV.MY_ENV_FEATURES.includes(MY_ENV_FEATURES.TH_AUTOMATION) && (
              <PivotItem
                itemKey={'/me/th-automation'}
                headerText={t('thAutomation')}
                itemIcon="QuadColumn"
              ></PivotItem>)}
          </Pivot>
        </div>
        <MenuCard
          isMenuCardVisible={this.state.isCalloutVisible}
          className={this.styles.callout}
          target={this.styles.buttonArea}
          onDissmissed={() => this.toggleIsCalloutVisible()}
          accessLevel={AccessLevel.CONTRACTING_UNIT}
          currentUser={this.props.currentUser}
          organization={this.props.organization}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return { organisation: state.uprince.organisation };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(MeNavBar));

import React from 'react';
import {Checkbox, IStyle} from "@fluentui/react";
import {uPrinceTheme} from "../../../theme";

interface Props {
    label: string
    checked: boolean
    color: IStyle
    onChange: any
}

export default function DynamicColorCheckbox(props: Props) {
    const {label, checked, color, onChange} = props;
    const primaryThemeColor = uPrinceTheme.palette.themePrimary;

    return (
      <Checkbox
        label={label}
        checked={checked}
        styles={{
            root: [
                {margin: "2px 4px"},
                !checked && {
                    '.ms-Checkbox-checkbox': {
                        borderColor: color ?? '#000000',
                    },
                    '.ms-Checkbox-text': {
                        color: color ?? '#000000',
                    },
                    ':hover .ms-Checkbox-checkbox': {
                        backgroundColor: 'white',
                        borderColor: color ?? '#000000',
                    },
                    ':hover .ms-Checkbox-checkmark': {
                        backgroundColor: 'white',
                        color: color ?? '#605e5c',
                        opacity: 1,
                    },
                    ':hover .ms-Checkbox-text': {
                        color: color ?? '#000000',
                    },
                },
                checked && {
                    '.ms-Checkbox-checkbox': {
                        backgroundColor: color ?? primaryThemeColor,
                        borderColor: color ?? primaryThemeColor,
                    },
                    '.ms-Checkbox-text': {
                        color: color ?? '#000000',
                    },
                    ':hover .ms-Checkbox-checkbox': {
                        filter: 'brightness(0.90)',
                        backgroundColor: color ?? primaryThemeColor,
                        borderColor: color ?? primaryThemeColor,
                    },
                    ':hover .ms-Checkbox-checkmark': {
                        color: 'white',
                        opacity: 1,
                    },
                    ':hover .ms-Checkbox-text': {
                        color: color ?? '#000000',
                    },
                }
            ]
        }}
        onChange={onChange}
      />
    );
}
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Breadcrumb,
  ContextualMenu,
  DetailsListLayoutMode,
  FontWeights,
  getTheme,
  IColumn,
  IconButton,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import { ReadRisk } from '../../types/projectBreakdownStructure';
import { uPrinceTheme } from '../../../theme';
import { useId } from '@uifabric/react-hooks';
import { getBreadcrumbItemFromData } from '../util';
import { Sticky, StickyPositionType } from '@fluentui/react/lib/Sticky';


const breadcrumbStyles = {
  root: {
    fontSize: 14,
    marginTop: -6,
  },
  item: { fontSize: 14 },
  list: { fontSize: 14 },
  listItem: { fontSize: 14 },
  chevron: { fontSize: 14 },
  overflow: { fontSize: 14 },
  overflowButton: { fontSize: 14 },
  itemLink: { fontSize: 14 },
};
const stackStyles: IStackStyles = { root: { padding: 0 } };
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 40,
    alignItems: 'flex-start',
  },
};
const stackTokens: IStackTokens = {
  childrenGap: 15,
  padding: 10,
};

const stackEditbarItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 45,
    alignItems: 'center',
  },
};


const TaxonomyActionGrid = (props: {
  treeData: any;
  dataList: any[];
  label?: string | null;
  readOnly?: boolean;
  children: any;
  columns: any;
  isLoaded: any;
  breadcrumbColumn: string;
  disabled: boolean;
  onCloseClick?: () => void
}) => {
  const { t } = useTranslation();
  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      minHeight: (props.dataList && props.dataList.length > 1) ? 200 : 110,
      position: 'relative',
      maxHeight: 'inherit',
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
  });
  const titleId = useId('title');
  const theme = getTheme();
  const dragOptions: IDragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
  };
  const cancelIcon: IIconProps = { iconName: 'Cancel' };
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
    header: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '2px 2px 2px 10px',
        backgroundColor: uPrinceTheme.palette.themePrimary,
        fontSize: 18,
        color: 'white',
      },
    ],
    footer: [
      // eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '0px 24px 14px 24px',
        textAlign: 'end',
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 0px 24px',
      overflowY: 'hidden',
      paddingTop: 20,
      minWidth: screen.width > 1280 ? '40vw' : '50vw',
      minHeight: screen.width > 1280 ? '60vh' : '75vh',
      height: screen.width > 1280 ? '80vh' : '75vh',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
    subHeader: {
      flex: '1 1 auto',
      display: 'none',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      fontSize: 14,
      color: uPrinceTheme.palette.themePrimary,
      backgroundColor: '#FFF7F4',
      height: 40,
      marginLeft: 'auto',
      marginRight: '2px',
    },
  });
  const cancelIconButtonStyles = {
    root: {
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
      color: 'white',
    },
    rootHovered: { color: theme.palette.neutralDark },
  };
  const whiteIconButtonStyles = {
    root: { color: uPrinceTheme.palette.white },
    rootHovered: { color: theme.palette.neutralDark },
  };
  const cancelIconWhite: IIconProps = {
    iconName: 'Cancel',
    styles: whiteIconButtonStyles,
  };

  const [isOpen, setIsOpen] = useState(false);


  const TaxonomyTableColumn = (item: any, index?: number, column?: IColumn) => {
    if (column && column.fieldName && column.fieldName === props.breadcrumbColumn) {
      return <Breadcrumb
        items={getBreadcrumbItemFromData(
          props.treeData,
          item[column.fieldName],
        )}
        ariaLabel="With last item rendered as heading"
        overflowAriaLabel="More links"
        styles={breadcrumbStyles}
      />;
    } else if (column && column.fieldName) {
      const fieldContent = item[column.fieldName];
      return <span style={{ paddingLeft: 9 }} onClick={() => {

      }}>{fieldContent}</span>;
    } else {
      return <span style={{ paddingLeft: 9 }}>-</span>;
    }
  };

  return (
    <div className="">
      <div className="marginTop">
        <div style={{ padding: 12 }}>
          <div className="ms-Grid-row">
            <div className="sub-header-title">
              <Stack
                horizontal
                horizontalAlign="space-between"
                styles={stackStyles}
                tokens={stackTokens}
              >   {props.label &&
              <Stack.Item styles={stackItemStyles}>
                <Label style={{ marginTop: 10 }}>{props.label}</Label>
              </Stack.Item>
              }
                {!props.readOnly && (
                  <Stack.Item styles={stackEditbarItemStyles}>
                    <PrimaryButton
                      disabled={props.disabled}
                      text={t('help')}
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    />
                  </Stack.Item>
                )}
              </Stack>
            </div>
          </div>

          <div className="ms-Grid-row marginTop">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
              <div className={classNames.wrapper}>
                <div className={'scrollablePane'}>
                  {/* TODO: this gives glitch issue in CAB with React 18 update */}
                  <ShimmeredDetailsList
                    items={props.dataList}
                    columns={props.columns}
                    setKey={Array.isArray(props.dataList) ? props.dataList.length.toString() : 'set'}
                    enableShimmer={!props.isLoaded}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionPreservedOnEmptyClick={true}
                    checkboxVisibility={2}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="Row checkbox"
                    selectionMode={SelectionMode.none}
                    onRenderItemColumn={(
                      item: ReadRisk,
                      index?: number,
                      column?: IColumn,
                    ) => TaxonomyTableColumn(item, index, column)}
                    onRenderDetailsHeader={(headerProps: any, defaultRender: any) => {
                      if (defaultRender !== undefined) {
                        return (
                          <Sticky
                            stickyPosition={StickyPositionType.Header}
                            isScrollSynced={true}
                            stickyBackgroundColor="transparent"
                          >
                            <div>{defaultRender(headerProps)}</div>
                          </Sticky>
                        );
                      }
                      return <span></span>;
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <Modal
              titleAriaId={titleId}
              isOpen={isOpen}
              onDismiss={() => {
                setIsOpen(false);
              }}
              isBlocking={true}
              dragOptions={dragOptions}
            >
              <div className={contentStyles.header}>
                <span id={titleId}> {props.label} </span>
                <IconButton
                  styles={cancelIconButtonStyles}
                  iconProps={cancelIcon}
                  ariaLabel="Close popup modal"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                />
              </div>
              <div className={contentStyles.body}>
                <div className="full-height">
                  <div className="ms-Grid-row full-height">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 full-height">
                      {props.children}
                    </div>
                  </div>
                </div>
              </div>
              <div className={contentStyles.footer}>
                <PrimaryButton
                  iconProps={cancelIconWhite}
                  text={t('close')}
                  style={{ marginTop: 10, marginBottom: 10, marginRight: 10 }}
                  onClick={() => {
                    if (props.onCloseClick) {
                      props.onCloseClick();
                    }
                    setIsOpen(false);
                  }}
                />
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaxonomyActionGrid;

import React, { Component } from 'react';
import UprinceNavigation from '../../shared/uprinceNavigation/cuProjectNavigation/component';
import CompetencyMainLayout from './competencyMainLayout';
import { getContractingUnit } from '../../shared/util';
import UprinceOrganizationNavigation from '../../shared/uprinceNavigation/organizationNavigation/component';


interface Props {
  currentUser: any
}

interface State {
  isContractUnit: boolean;
  isProject: boolean;
}

export class Competency extends Component<Props, State> {
  render() {
    return (
      (getContractingUnit()) ? <UprinceNavigation
        currentUser={this.props.currentUser}
        selectedNavigationIndex={`/CU/${getContractingUnit()}/organisation-settings`}
      >
        <CompetencyMainLayout/>
      </UprinceNavigation> : <UprinceOrganizationNavigation
        currentUser={this.props.currentUser}
        selectedNavigationIndex={'/organisation-settings'}
      >
        <CompetencyMainLayout/>
      </UprinceOrganizationNavigation>
    );
  }
}

export default (Competency);

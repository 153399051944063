import React from 'react';
import { initializeIcons } from '@uifabric/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ShortCutPaneComponent } from './component';
import history from '../../../history';
import { messageService } from '../../../services/messageService';
import { getContractingUnit } from '../../../shared/util';
import { ORGSettingShortCutPaneItem } from '../../../types/organisationSetting';
import _ from 'lodash';
import {
  readOrganisationSettingShortCutPaneData,
  saveOSStateAttr,
} from '../../../reducers/organisationSettingReducer';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

initializeIcons();

interface Props extends WithTranslation {
  readOrganisationSettingShortCutPaneData: any;
  resetLayout: boolean;
  shortcutPaneData: ORGSettingShortCutPaneItem[];
  saveOSStateAttr: (type: string, value: any) => void;
  selectedShortCutItem: string | null
  toggleOverlay: boolean;
  isExpand: boolean;
}

interface State {
  isCollapse: boolean;
  type: string | null;
  divClass: string;
  selectedCodeType: number;
  currentActiveSection: string | null;
}

class ShortCutPane extends React.Component<Props, State> {
  subscription: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      divClass: 'filter-panel-left',
      selectedCodeType: -1,
      currentActiveSection: null,
      isCollapse: false,
      type: null,
    };
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.selectedShortCutPaneItem) {
          this.setState({
            currentActiveSection: data.data.selectedShortCutPaneItem,
          });
        }
      }
    });
    if (this.state.divClass === 'filter-panel-left') {
      if (window.innerWidth > 1300) {
        messageService.sendMessage({ shortCutPaneExpanded: true });
      }
    } else {
      messageService.sendMessage({ shortCutPaneExpanded: false });
    }

    this.props.readOrganisationSettingShortCutPaneData();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.toggleOverlay !== prevProps.toggleOverlay) {
      if (this.props.toggleOverlay) {
        this.setState({ divClass: 'filter-panel-left panel-move' });
      } else {
        this.setState({ divClass: 'filter-panel-left' });
      }
    }
  }


  private handelShortCutPaneFilter = (selectedItem: ORGSettingShortCutPaneItem) => {
    this.props.saveOSStateAttr('selectedShortCutItem', selectedItem.id);
    this.setState({
      currentActiveSection: selectedItem.id,
      type: selectedItem.type,
    });

    if (selectedItem.type === 'organisation') {
      if (getContractingUnit()) {
        history.push({
          pathname: `/CU/${getContractingUnit()}/organisation-settings`,
          state: { selectedItem: selectedItem, key: 2 },
        });
      } else {
        history.push({
          pathname: `/organisation-settings`,
          state: { selectedItem: selectedItem, key: 2 },
        });
      }
    }
    if (selectedItem.type === 'competencies') {
      if (getContractingUnit()) {
        history.push({
          pathname: `/CU/${getContractingUnit()}/organisation-settings/competencies`,
          state: { selectedItem: selectedItem, key: 2 },
        });
      } else {
        history.push({
          pathname: `/organisation-settings/competencies`,
          state: { selectedItem: selectedItem, key: 2 },
        });
      }
    }
    if (selectedItem.type === 'certifications') {
      if (getContractingUnit()) {
        history.push({
          pathname: `/CU/${getContractingUnit()}/organisation-settings/certifications`,
          state: { selectedItem: selectedItem, key: 2 },
        });
      } else {
        history.push({
          pathname: `/organisation-settings/certifications`,
          state: { selectedItem: selectedItem, key: 2 },
        });
      }
    }
    if (selectedItem.type === 'corporateSchedule') {
      if (getContractingUnit()) {
        history.push({
          pathname: `/CU/${getContractingUnit()}/organisation-settings/corporate-schedule`,
          state: { selectedItem: selectedItem, key: 2 },
        });
      } else {
        history.push({
          pathname: `/organisation-settings/corporate-schedule`,
          state: { selectedItem: selectedItem, key: 2 },
        });
      }
    }

  };


  private fromatShortCutPaneData = (shrotCutaPaneData: any) => {
    const { t } = this.props;
    let data: any[] = [];
    if (shrotCutaPaneData && _.isArray(shrotCutaPaneData?.organisation) && !_.isEmpty(shrotCutaPaneData?.organisation)) {
      data.push({
        id: 1,
        name: t('organisation'),
        displayOrder: 0,
        type: 'organisation',
        isMain: true,
        icon: 'ms-Icon--Flow',
      });
      let organisationChilds = shrotCutaPaneData?.organisation?.map((item: any) => {
        return {
          ...item,
          type: 'organisation',
          isMain: false,
          icon: 'ms-Icon--GotoToday',
        };
      });
      data.push(...organisationChilds);
      data.push({
        id: 2,
        name: t('competencies'),
        displayOrder: 0,
        type: 'competencies',
        isMain: true,
        icon: 'ms-Icon--Archive',
      });
      let competenciesChilds = shrotCutaPaneData?.competencies?.map((item: any) => {
        return {
          ...item,
          type: 'competencies',
          isMain: false,
          icon: 'ms-Icon--GotoToday',
        };
      });
      //data.push(...competenciesChilds)
      data.push({
        id: 3,
        name: t('certifications'),
        displayOrder: 0,
        type: 'certifications',
        isMain: true,
        icon: 'ms-Icon--Certificate',
      });
      let certificationsChilds = shrotCutaPaneData?.certifications?.map((item: any) => {
        return {
          ...item,
          type: 'certifications',
          isMain: false,
          icon: 'ms-Icon--GotoToday',
        };
      });
      //data.push(...certificationsChilds)
      data.push({
        id: 4,
        name: t('corporateSchedule'),
        displayOrder: 0,
        type: 'corporateSchedule',
        isMain: true,
        icon: 'ms-Icon--TimeEntry',
      });
      let corporateScheduleChilds = shrotCutaPaneData?.corporateSchedule?.map((item: any) => {
        return {
          ...item,
          type: 'corporateSchedule',
          isMain: false,
          icon: 'ms-Icon--GotoToday',
        };
      });
      //data.push(...corporateScheduleChilds)
    }
    return data;
  };

  public render(): JSX.Element {
    return (
      <ShortCutPaneComponent
        currentActiveSection={this.state.currentActiveSection}
        toggleOverlay={this._toggleOverlay}
        shortCutPaneFilters={this.fromatShortCutPaneData(this.props.shortcutPaneData)}
        toggleOverlayFwd={this._toggleOverlayFwd}
        handelShortCutPaneFilter={(selectedItem: any) =>
          this.handelShortCutPaneFilter(selectedItem)
        }
        divClass={this.state.divClass}
        isCollapse={this.state.isCollapse}
        isExpand={this.props.isExpand}
        type={'organisation'}
        selectedShortCutItem={this.props.selectedShortCutItem}
      />
    );
  }

  private _toggleOverlay = (): void => {
    this.setState({ divClass: 'filter-panel-left panel-move', isCollapse: true });
    messageService.sendMessage({ shortCutPaneExpanded: false });
  };
  private _toggleOverlayFwd = (): void => {
    this.setState({ divClass: 'filter-panel-left', isCollapse: false });
    messageService.sendMessage({ shortCutPaneExpanded: true });
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    shortcutPaneData: state.os?.osShortCutPaneData,
    selectedShortCutItem: state.os?.selectedShortCutItem,
  };
};

const mapDispatchToProps = {
  readOrganisationSettingShortCutPaneData,
  saveOSStateAttr,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(((ShortCutPane))),
  ));

import React from 'react';
import { CommandBar } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';

const CommandBarRight = () => {
    const { t } = useTranslation();
    const newClick = () => {
        messageService.sendMessage({ isNewIR: true });
        // props.createNew(true);
    };
    const saveFormData = () => {
        messageService.sendMessage({ saveIRData: true });
    };
    const handleRemove = () => {
        // props.handleRemove();
    };

    const getItems = () => {
        return [
            {
                key: 'new',
                text: t('new'),
                iconProps: { iconName: 'Add' },
                onClick: () => {newClick();},
                disabled: false
            },
            {
                key: 'save',
                text: t('save'),
                iconProps: { iconName: 'Save' },
                onClick: () => {saveFormData();},
                disabled: false
            },
        ];
    };

    return (
        <div>
            <CommandBar
                items={ getItems() }
                ariaLabel="Use left and right arrow keys to navigate between commands"
            />
        </div>
    );
};

export default CommandBarRight;

import { Stack } from '@fluentui/react';
import React from 'react';
import ProgressBar from '../../shared/progressBar/progressBar';

const ListPaneSection = (props: {
  activeClass: string;
  titlePaneHeader: string;
  showProgress: boolean;
  renderCommandBar: any;
  renderShotcutPane: any;
  children: any;
}) => {
  return (
    <Stack horizontal>
      {props.renderShotcutPane()}

      <div className={props.activeClass}>
        <ProgressBar show={props.showProgress}/>

        <Stack style={{ alignSelf: 'center' }}>
          {props.children}
          { /* LIST PANE*/}
        </Stack>
      </div>
    </Stack>
  );
};

export default ListPaneSection;

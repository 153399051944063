import {
  DetailsListLayoutMode,
  DetailsRow,
  DetailsRowCheck,
  getTheme,
  IColumn,
  Icon,
  IDetailsFooterProps,
  IDetailsRowBaseProps,
  IDetailsRowCheckStyles,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { iconColors, uPrinceTheme } from '../../../../../theme';
import { RESOURCE_TYPE } from '../../../../types/purchaseOrder';
import { getProject } from '../../../../shared/util';

const theme = getTheme();

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const removeIcon: IIconProps = {
  iconName: 'Delete',
  styles: iconButtonStyles,
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const RFQDataGrid = (props: {
  dataList: any[];
  title: string;
  link: string;
  id: string;
  columns: any[];
  isLoaded: boolean;
  selection: any;
  setOpenPOResourceModal: () => void;
  resourceType: RESOURCE_TYPE;
  isExpand: boolean;
}) => {
  const { t } = useTranslation();
  const iconWidth = 30;
  const iconExpandWidth = 40;
  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
      minHeight: (props.dataList && props.dataList.length > 1) ? 200 : 110,
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
  });

  const columns = [
    {
      key: 'column3',
      name: t('productTitle'),
      fieldName: 'title',
      minWidth: props.isExpand ? 150 : 150,
      maxWidth: props.isExpand ? 300 : 200,
      isResizable: true,
    },
    {
      key: 'column4',
      name: t('pmolTitle'),
      fieldName: 'headerTitle',
      minWidth: props.isExpand ? 150 : 150,
      maxWidth: props.isExpand ? 300 : 200,
      isResizable: true,
    },
    {
      key: 'column5',
      name: t('quantity'),
      fieldName: 'cQuantity',
      minWidth: props.isExpand ? 100 : 100,
      maxWidth: props.isExpand ? 300 : 200,
      isResizable: true,
    },
    {
      key: 'column6',
      name: t('mou'),
      fieldName: 'mou',
      minWidth: props.isExpand ? 100 : 100,
      maxWidth: props.isExpand ? 300 : 200,
      isResizable: true,
    },
    {
      key: 'column7',
      name: t('unitPrice'),
      fieldName: 'cUnitPrice',
      minWidth: props.isExpand ? 100 : 100,
      maxWidth: props.isExpand ? 300 : 200,
      isResizable: true,
    },
    {
      key: 'column8',
      name: t('totalPrice'),
      fieldName: 'cTotalPrice',
      minWidth: props.isExpand ? 100 : 100,
      maxWidth: props.isExpand ? 100 : 100,
      isResizable: true,
    },
    // {
    //     key: 'column9',
    //     name: t('requestedDeliveryDate'),
    //     fieldName: 'requestedDate',
    //     minWidth: props.isExpand ? 160 : 120,
    //     maxWidth: props.isExpand ? 160 : 120,
    //     isResizable: true
    // }
  ];

  useEffect(() => {
    changeColor();
  });
  const stackStyles: IStackStyles = { root: { padding: 0 } };
  const stackItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 40,
      alignItems: 'flex-start',
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 15,
    padding: 10,
  };

  const detailsRowCheckStyles: Partial<IDetailsRowCheckStyles> = { root: { visibility: 'hidden' } };

  const _onRenderCheckForFooterRow: IDetailsRowBaseProps['onRenderCheck'] = (props): JSX.Element => {
    return <DetailsRowCheck {...props} styles={detailsRowCheckStyles} selected={true}/>;
  };

  const _onRenderCheckForHeaderRow: IDetailsRowBaseProps['onRenderCheck'] = (props): JSX.Element => {
    return <DetailsRowCheck {...props} styles={detailsRowCheckStyles} selected={true}/>;
  };

  const _onRenderDetailsHeader = (detailsFooterProps: IDetailsFooterProps): JSX.Element => {
    return (
      <DetailsRow
        {...detailsFooterProps}
        columns={detailsFooterProps.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps.groupNestingDepth}
        selectionMode={SelectionMode.single}
        selection={detailsFooterProps.selection}
        onRenderItemColumn={_renderDetailsHeaderItemColumn}
        onRenderCheck={_onRenderCheckForHeaderRow}
      />
    );
  };

  const _renderDetailsHeaderItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] = (item, index, column) => {
   if (column && column.fieldName && (column.fieldName === 'title')) {
      return (
        <div className={'costPerUnit'} style={{ width: 400 }}>
          <Label>&nbsp;</Label>
          <Label>{t('productTitle')}</Label>
        </div>
      );
      return null;
    } else if (column && column.fieldName && (column.fieldName === 'headerTitle')) {
      return (
        <div className={'costPerUnit'} style={{ width: 600 }}>
          <Label style={{ marginLeft: props.isExpand ? 120 : 40 }}>{t('reference')}</Label>
          <Label>{t('pmolTitle')}</Label>
        </div>
      );
      return null;
    } else if (column && column.fieldName && (column.fieldName === 'cQuantity')) {
      return (
        <div className={'totalCost1'} style={{ width: 200 }}>
          <Label>&nbsp;</Label>
          <Label>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('quantity')}
          </Label>
        </div>
      );
      return null;
    } else if (column && column.fieldName && (column.fieldName === 'mou')) {
      return (
        <div className={'mmou'} style={{ width: 200 }}>
          <Label>&nbsp;</Label>
          <Label>
            {t('mou')}
          </Label>
        </div>
      );
      return null;
    } else if (column && column.fieldName && (column.fieldName === 'cUnitPrice')) {
      return (
        <div style={{ width: 100 }}>

          <Label style={{ marginLeft: props.isExpand ? 40 : 40 }}>{t('finance')}</Label>
          <Label style={{ marginLeft: (true) ? 1 : 1 }}>
            {t('unitPrice')}
          </Label>
        </div>
      );
      return null;
    } else if (column && column.fieldName && (column.fieldName === 'cTotalPrice')) {
      return (
        <div style={{ width: 100, textAlign: props.isExpand ? 'center' : 'left' }}>
          <Label>&nbsp;</Label>
          <Label>
          {t('totalPrice')}
          </Label>
        </div>
      );
      return null;
    }  else {
      return null;
    }

  };

  const changeColor = () => {
    let cquantityClass = document.getElementsByClassName('mcomments');
    for (let i = 0; i < cquantityClass.length; i++) {
      let parentElement = cquantityClass[i].parentElement;
      if (parentElement) {
        parentElement.style.borderRight = '1px solid rgb(243, 242, 241)';
      }
    }

    let tresourceTitle = document.getElementsByClassName('productTitle');
    for (let i = 0; i < tresourceTitle.length; i++) {
      let parentElement = tresourceTitle[i].parentElement;
      if (parentElement) {
        parentElement.style.borderRight = '1px solid rgb(243, 242, 241)';
      }
    }
    let totalCost1Class = document.getElementsByClassName('mmou');
    for (let i = 0; i < totalCost1Class.length; i++) {
      let parentElement = totalCost1Class[i].parentElement;
      if (parentElement) {
        parentElement.style.borderRight = '1px solid rgb(243, 242, 241)';
      }
    }

  };

  const customColumn = (item: any, index?: number, column?: IColumn) => {
    var formatter = new Intl.NumberFormat(window.navigator.language, {
      style: 'currency',
      currency: 'EUR',
    });
    if (column && column.fieldName && column.fieldName === 'title') {
      return <div onClick={(e) => {
        e.preventDefault();
      }}>{item[column.fieldName]}</div>;
    } else if (column && column.fieldName && (column.fieldName === 'pComment' || column.fieldName === 'cComment')) {
      let fieldContent = item[column.fieldName];
      if (column.fieldName === 'cComment' && !getProject()) {
        fieldContent = item['pComment'];
        if (!fieldContent) {
          fieldContent = item['cComment'];
        }
      }
      return fieldContent ? <div className={'mcomments'}><Icon
        iconName="Comment"
        style={{ color: iconColors.iconActiveColor }}
      /></div> : <div className={'mcomments'}><span style={{ paddingLeft: 9 }}>-</span></div>;
    } else if (column && column.fieldName && (column.fieldName === 'pDocuments' || column.fieldName === 'cDocuments')) {
      const fieldContent = item[column.fieldName];
      return fieldContent && fieldContent.length > 0 ? <Icon
        style={{ color: iconColors.iconActiveColor }}
        iconName="WordDocument"
      /> : <span style={{ paddingLeft: 9 }}>-</span>;
      ;
    } else if (column && column.fieldName && (column.fieldName === 'pTotalPrice' || column.fieldName === 'cTotalPrice')) {
      const fieldContent = item[column.fieldName];
      return fieldContent ? <div style={{ textAlign: 'right' }}><span style={{ paddingLeft: 9 }} onClick={() => {

        }}>{formatter.format(fieldContent)}</span></div> :
        <div style={{ textAlign: 'right' }}><span style={{ paddingLeft: 9, textAlign: 'right' }}>-</span></div>;
    } else if (column && column.fieldName) {
      const fieldContent = item[column.fieldName];
      return fieldContent ? <span style={{ paddingLeft: 9 }} onClick={() => {

      }}>{fieldContent}</span> : <span style={{ paddingLeft: 9 }}>-</span>;
    } else {
      return <span style={{ paddingLeft: 9 }}>-</span>;
    }
  };

  return (
    <div className={'document-pane-card  purchase-order'}>
      <div className={'card-header'}>
        <div className="sub-header-title">
          <Stack
            horizontal
            horizontalAlign="space-between"
            styles={stackStyles}
            tokens={stackTokens}
          >
            <Stack.Item styles={stackItemStyles}>
              <Label
                id={props.link}
                style={{ marginTop: 10, fontSize: '1.1rem' }}
              >
                {props.id}. {props.title}
              </Label>
            </Stack.Item>

          </Stack>
        </div>
      </div>

      <div className={'card-body'}>
        <div className="proj-detail-block" id={props.id}>
          <div className="marginTop">
            <div style={{ padding: 12 }}>
              <div className="ms-Grid-row marginTop">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  <div className={classNames.wrapper}>
                    <div className={'scrollablePane'}>
                      {/*<ScrollablePane styles={ { root: { height: 'auto' } } }>*/}
                      <ShimmeredDetailsList
                        items={props.dataList}
                        columns={columns}
                        setKey="set"
                        enableShimmer={props.isLoaded}
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionPreservedOnEmptyClick={true}
                        checkboxVisibility={1}
                        onItemInvoked={() => {
                          props.setOpenPOResourceModal();
                        }}
                        selectionMode={SelectionMode.none}
                        onRenderItemColumn={(
                          item: any,
                          index?: number,
                          column?: IColumn,
                        ) =>
                          customColumn(
                            item,
                            index,
                            column,
                          )
                        }
                        selection={props.selection}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="Row checkbox"
                        // onRenderDetailsHeader={(props) => _onRenderDetailsHeader(props!!)}
                        onRowDidMount={() => {
                          changeColor();
                        }}
                      />
                      {/*</ScrollablePane>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'card-footer'}>

      </div>
    </div>
  );
};

export default RFQDataGrid;

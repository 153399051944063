import * as React from 'react';
import { useEffect } from 'react';
import { Checkbox, Divider, Label } from '@fluentui/react-components';
import AddInstruction from './addInstruction';
import { useDispatch, useSelector } from 'react-redux';
import { saveWbsTaskStateAttr } from '../../../../reducers/wbsTaskReducer';

const InstructionsCheckList = ({ instructions, onChange, formData }: any) => {
  const dispatch = useDispatch();
  const wbsTaskInstructionList = useSelector((state: any) => state.wbsTask.wbsTaskInstructionList);

  useEffect(() => {
    if (wbsTaskInstructionList && wbsTaskInstructionList?.length > 0) {
      // console.log({ wbsTaskInstructionList }, { instructions }, wbsTaskInstructionList?.length);
      onChange(wbsTaskInstructionList);
      dispatch(saveWbsTaskStateAttr('wbsTaskInstructionList', []));
    }
  }, [wbsTaskInstructionList]);

  const toggleIsCheckedById = (id: any) => {
    let updated = instructions.map((item: any) => {
      if (item.id === id) {
        // Toggle the isChecked value
        return { ...item, isChecked: !item.isChecked };
      }
      return item;
    });
    onChange(updated);
  };

  const renderInstructionsRow = () => (instructions.map((item: any, index: number) => (
    <Checkbox
      key={item?.id + '' + index}
      checked={item?.isChecked}
      onChange={() => toggleIsCheckedById(item?.id)}
      label={item?.title}
    />
  )));

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
          <Label style={{ marginTop: 20 }} size='large'>
            CheckList/ Instructions
          </Label>
          <AddInstruction
            taskId={formData?.id}
            isEdit={formData?.id}
            pbsId={formData?.pbsId}
            productId={formData?.productId}
          />
        </div>
        <div style={{ marginTop: 5, marginBottom: 5 }}><Divider /></div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {renderInstructionsRow()}
        </div>
      </div>
    </>
  );

};

export default InstructionsCheckList;

export class InstructionRegisterListPaneFilter {
  name: string | null = null;
  title: string | null = null;
  instructionType: string | null = null;
  pbsInstructionFamilyId: string | null = null;

  sorter: Sorter = new Sorter();
  // type: string | number | string[] | number[] | null | undefined;
  // family: any;
}

// {
//   "title": "string",
//   "instructionType": "string",
//   "pbsInstructionFamilyId": "string",
//   "sorter": {
//     "attribute": "string",
//     "order": "string"
//   }
// }

export interface InstructionRegisterShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
  value: number | null
}

class Sorter {
  attribute: string | null = null;
  order: string | null = null;
}

export class InstructionRegisterListItem {
  id: string | null = null;
  name: string | null = null;
  sequenceId: string | null = null;
  title: string | null = null;
  // pbsInstructionLink: pbsInstructionLink[] = [];
}


export class Instruction {
  instructionsDetails: string | null = null;
  instructionType?: string | null = null;
  pbsProduct?: string | null = null;
  pbsProductId: string | null = null;
  // pbsInstructionFamily: PbsInstructionFamily = new PbsInstructionFamily();
  // pbsInstructionLink: PbsInstructionLink[] = [];
  id: string | null = null;
  sequenceCode: string | null = null;
  title: string | null = null;
  headerTitle: string | null = null;
  name: string | null = null;
  isDeleted: boolean | null = null;
  isSaved: boolean = false;
  instructionFamilyId: string | null = null;
  pbsInstructionFamilyId: string | null = null;
}


export interface DropDown {
  key: string;
  text: string;
}

export interface IRDropDowns {
  status: DropDown[];
  types: DropDown[];
}

export interface InstructionRegisterDropDowns {
  pbsInstructionFamily: any;
  pbsInstructionFamilyId: any;
  status: DropDown[];
  types: DropDown[];
}

export interface InstructionRegister {
  id: string | null;
  title: string;
  sequenceId: string;

  instructionsDetails: string;
  instructionType?: string;
  pbsProduct?: string;
  pbsProductId: string;
  sequenceCode: string;
  headerTitle: string;
  name: string;
  isDeleted: boolean;
  isSaved: boolean;
  instructionFamilyId: string;
  pbsInstructionFamilyId: string;
}

export interface IRDetails {
  id: string | null;
  sequenceId: string | null;
  title: string | null;
  name: string | null;
}

export class pbsInstructionLink {
  id: string | null = null;
  title: string | null = null;
  value?: string | null = null;
  link?: string | null = null;
  pbsInstructionId: string | null = null;
  type: string | null = null;
}

export interface History {
  createdByUser: string | null;
  createdDateTime: string | null;
  revisionNumber: number | string | null;
  updatedByUser: string | null;
  updatedDateTime: string | null;
}

export const formData = {
  id: null,
  title: 'string',
  sequenceId: 'string',

  instructionsDetails: 'string',
  instructionType: 'string',
  pbsProduct: 'string',
  pbsProductId: 'string',
  sequenceCode: 'string',
  headerTitle: 'string',
  name: 'string',
  isDeleted: false,
  isSaved: false,
  instructionFamilyId: 'string',
  pbsInstructionFamilyId: 'string',
};


import React from 'react';
import { connect } from 'react-redux';
import DocumentPaneComponent from './component';
import { WithTranslation, withTranslation } from 'react-i18next';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import history from '../../../history';
import { messageService } from '../../../services/messageService';
import { getProject } from '../../../shared/util';

import {
  getAllProjectDropdowns,
  getProjectDefinition,
  getProjectHeaderDetails,
} from '../../../reducers/projectReducer';
import { formData, StandardMail } from '../../../types/standardMail';
import { readByStandardMailId, saveFormData } from '../../../reducers/standardMailReducer';
import { v4 as uuidv4 } from 'uuid';

export interface State {
  isDirty: boolean;
  redirect: boolean;
  loading: boolean;
  isClear: boolean;
  isEdit: boolean;
  isCostModalOpen: boolean;
  showPrint: boolean;
  isApproved: boolean;
  ledgerDropDowns: { key: string; text: string }[];
  generalLedger: string;
  resourceData: any;
  openPOResourceModal: boolean;
  uid: string | null;
}

interface Props extends WithTranslation {
  smDropDowns: any;
  saveFormData: (data: StandardMail) => void;
  getAllProjectDropdowns: () => void;
  test: string;
  formData: any;
  readByStandardMailId: (id: string) => void
}

class DocumentPane extends React.Component<any, any> {
  subscription: any;
  submitExternal: any;
  form: any;

  constructor(props: any) {
    super(props);
    this.state = {
      isDirty: false,
      isEdit: false,
      redirect: false,
      loading: false,
      formData: formData,
      showPrint: false,
      isApproved: false,
      uid: null,
      isClear: false,
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    //   if (
    //       prevProps.isNew !== this.props.isNew ||
    //   prevProps.uid !== this.props.uid
    //   ) {
    //       if (this.props.isNew) {
    //           const formData = this.state.formData;
    //           formData.id = this.props.uid;
    //           this.setState({ formData, isClear: false });
    //       }
    //   } else {
    //   }

    //   if (prevProps.smId !== this.props.smId) {
    //         if (this.props.smId) {
    //             this.props.readByStandardMailId(this.props.smId);
    //         }
    //     }

    //   if (prevProps.formData !== this.props.formData) {
    //       if (!this.state.isDirty) {
    //           if (!_.isEmpty(this.props.formData)) {
    //               const formData = this.props.formData;
    //               this.setState({ formData });
    //           }
    //       } else {
    //           if (this.props.isNew && _.isEmpty(this.props.formData)) {
    //               const formData = this.state.formData;
    //               this.setState({ formData: formData });
    //           }
    //       }
    //   }
  }

  componentDidMount() {
    // get document pane dropdowns
    //this.props.getAllProjectDropdowns();
    this.setState({ uid: uuidv4(), isClear: this.props.isNewSM });

    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {

        if (data.data.isNewSM) {
          //    if (getProject()) {
          //       this.setState({ uid: uuidv4(), isClear: true });
          //   } else {
          //       this.setState({ uid: uuidv4(), isClear: true });
          //   }

          if (this.form) {
            this.form.reset();
            this.form.resetFieldState('name');
            //   this.form.resetFieldState('header.cpcResourceTitle');
          }
        }
        if (data.data.saveSMData) {
          // this.saveFormData();
          this.submitExternal();
        }
        if (data.data.smFilter) {
          this.setState({ filter: data.data.sm });
        }

      }
    });
    if (getProject()) {
      const projectId = getProject();
      if (projectId) {
        this.props.getProjectHeaderDetails(projectId);
      }
    } else if (this.props.formData?.id) {
      this.props.getProjectHeaderDetails(this.props.formData?.id);
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { psFilter: this.state.filter },
    });
  };

  setSubmitExternal = (submit: any) => {
    this.submitExternal = submit;
  };

  setForm = (form: any) => {
    this.form = form;
  };


  render() {
    return (
      <div style={{ width: '100%' }}>
        <RouteLeavingGuard
          when={this.state.isDirty}
          navigate={(path) => this.handleDiscardChangesNavigate(path)}
          shouldBlockNavigation={() => {
            return true;
          }}
          handleConfirmation={(isConfirm: boolean) => {
            this.setState({ isDirty: !isConfirm });
          }}
        />
        <DocumentPaneComponent
          submit={(submit: any) => {
            this.setSubmitExternal(submit);
          }}
          saveFormData={this.props.saveFormData}
          uid={this.state.uid}
          formData={this.props.formData}
          showProgress={this.props.isLoadingStandardMail}
          formValues={this.form?.getState()?.values}
          isClear={this.state.isClear}
          isExpand={this.props.isExpand}
          setForm={(form: any) => {
            this.setForm(form);
          }}
        />

      </div>
    );
  }

}

const mapStateToProps = (state: any) => {
  return {
    isEdit: state.ps.isEdit,
    isNew: state.ps.isNew,
    uid: state.ps.uid,
    projectHeader: state.project.projectHeader,
    showProgressBar: state.sm.showProgressBar,
    selectedProject: state.project.selectedProject,
    dropDowns: state.sm.smDropDowns,
  };
};

const mapDispatchToProps = {
  saveFormData,
  getProjectHeaderDetails,
  getAllProjectDropdowns,
  getProjectDefinition,
  readByStandardMailId,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DocumentPane));

import React, { useEffect, useState } from 'react'
import { FontWeights, getTheme, IColumn, IconButton, IIconProps, Label, Link, mergeStyleSets, Modal, Selection } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import GroupByDataGridForAssetRegisters from './groupByDataGrid';
import client from '../../../api';
import { CREATE_ASSET_REGISTER_ITEM, DELETE_ASSET_ITEM_EP, GET_ASSET_REGISTERS_AXH_BY_PRODUCT_EP, GET_ASSET_REGISTERS_AXH_EP, LINK_ASSET_REGISTER_TO_PRODUCT_FROM_CU_EP, UPDATE_ASSET_REGISTER_AXH_EP } from '../../../shared/endpoints';
import { uPrinceTheme } from '../../../../theme';
import AssetRegisterForm from './assetRegisterForm';
import ProgressBar from '../../../shared/progressBar/progressBar';
import { getContractingUnit, getProject } from '../../../shared/util';
import { AssetRegister } from '../../../types/projectBreakdownStructure';
import Axios from 'axios';
import ENV from '../../../../env.json'

const theme = getTheme();

const contentStyles = mergeStyleSets({
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  modalContainer: {
    width: '28%'
  },
  assetTitleButton: {
    backgroundColor: 'transparent', 
    border: 0, 
    color: 'Highlight',
    textDecoration: 'underline',
    cursor: 'pointer',
    fontWeight: 'bold',
    // fontSize: 16
  }
});

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: { color: theme.palette.neutralDark },
};

export interface AssetItemFormTypes {
  name: string | null;
  description: string;
  status: string;
  state: string;
  type: string | null;
  kind: string | null;
  fluvius: number;
  measuring: number | null;
  yard: number | null;
  progressStatement: number | null;
  counteroffer: number;
  invoice: number | null;
  externalId: null;
}

const AssetRegisters = ({productInfo}: any) => {
  const { t } = useTranslation();
  const accessToken = localStorage.getItem('accessToken')
  const projectSequenceCode = getProject()
  const initialFormValues: AssetItemFormTypes = {
    name: null, //combination type & kind
    description: "", //leave empty
    status: "D60AAD0B-2E84-482B-AD25-618D80D49477", //use this value as default
    state: "004EB795-8BBA-47E8-9049-D14774AB0B18", //default
    type: null,
    kind: null,
    fluvius: 0,
    measuring: null,
    yard: null,
    progressStatement: null,
    counteroffer: 0,
    invoice: null,
    externalId: null
  }
  // const projectBreakdown = useSelector(
  //   (state: any) => state.projectBreakdown.formData
  // );
  const [showProgress, setShowProgress] = useState<boolean>(false)
  const [assetRegisterDataCU, setAssetRegisterDataCU] = useState<AssetRegister[]>([]);
  const [assetRegisterDataProduct, setAssetRegisterDataProduct] = useState<AssetRegister[]>([])

  const [assetItemOptions, setAssetItemOptions] = useState<any>([])

  const [openAssetRegModal, setOpenAssetRegModal] = useState<boolean>(false);

  const [selectedAssetRegister, setSelectedAssetRegister] = useState<any>(null);
  const [selectedAssetItem, setSelectedAssetItem] = useState<any>(null)

  // for row selection
  const [selected, setSelected] = useState<any>(null);

  const [formData, setFormData] = useState<AssetItemFormTypes>(initialFormValues)

  const cancelIcon: IIconProps = { iconName: 'Cancel' };

  useEffect(() => {
    if (productInfo && productInfo?.id) {
      getAssetRegisters()
      getAssetRegistersByProduct(productInfo?.id)
    } 
  }, [productInfo, productInfo?.id])
  
  useEffect(() => {
    if (selectedAssetRegister) {
      getAssertItemOptions(selectedAssetRegister)
    }
  }, [selectedAssetRegister])

  useEffect(() => {
    if (selected) {
      if (selected?.length > 0) {
        setSelectedAssetItem(selected?.[0]?.id);
        setSelectedAssetRegister(selected?.[0]?.registerId);
      }
    }
  }, [selected])

  useEffect(() => {
    if (selectedAssetItem) {
      const populateFormData = assetRegisterDataCU?.filter((e: any) => e.id === selectedAssetRegister)
      const item = populateFormData?.[0]?.assetItems?.filter((e: any) => e.id === selectedAssetItem)
      if (item) {
        setFormData({
          name: item?.[0]?.name,
          description: item?.[0]?.description ? item?.[0]?.description : "",
          status: "D60AAD0B-2E84-482B-AD25-618D80D49477",
          state: "004EB795-8BBA-47E8-9049-D14774AB0B18",
          type: item?.[0]?.type,
          kind: item?.[0]?.kind,
          fluvius: item?.[0]?.fluvius,
          measuring: item?.[0]?.measuring,
          yard: item?.[0]?.yard,
          progressStatement: item?.[0]?.progressStatement,
          counteroffer: item?.[0]?.counteroffer,
          invoice: item?.[0]?.invoice,
          externalId: item?.[0]?.externalId
        })
      }
    }
  }, [assetRegisterDataCU, selectedAssetItem])

  // useEffect(() => {
  //   if (!openAssetRegModal) {
  //     // if (selected?.length > 0) {
  //     //   setFormData(initialFormValues);
  //     // } else {
  //     //   setFormData(initialFormValues)
  //     //   setSelectedAssetItem(null);
  //     //   setSelectedAssetRegister(null)
  //     // }
  //     setFormData(initialFormValues)
  //     setSelectedAssetItem(null);
  //     setSelectedAssetRegister(null)
  //   }
  // }, [openAssetRegModal])
  
  const getAssertItemOptions = (key?: any) => {
    const asset = assetRegisterDataCU?.filter((each: any) => each.id === key)
    const options = asset?.[0]?.assetItems?.map((e: any) => {
      return {
        key: e.id,
        text: e.name
      }
    })

    setAssetItemOptions(options)
  }

  const getAssetRegisters = async () => {
    try {
      const response = await client.get(GET_ASSET_REGISTERS_AXH_EP)
      if (response?.status === 200) {
        setAssetRegisterDataCU(response?.data?.result)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const getAssetRegistersByProduct = async (productId: any) => {
    try {
      const response = await client.get(`${GET_ASSET_REGISTERS_AXH_BY_PRODUCT_EP}/${productId}`)
      if (response?.status === 200) {
        setAssetRegisterDataProduct(response?.data?.result)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const OnDeleteAssetItem = async () => {
    if (selected?.length > 0) {
      const assetsToRemove = selected?.map((e: any) => e.id)
      const payload: any = JSON.stringify({
        AssetItemId: assetsToRemove,
        AssetItemType: "AxH",
        PbsId: productInfo?.id,
        ProjectSequenceId: projectSequenceCode
      })
      let config: any = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${ENV.BASE_URL}/${DELETE_ASSET_ITEM_EP}`,
        headers: { 
          'CU': getContractingUnit(), 
          'Project': projectSequenceCode, 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + accessToken, 
        },
        data : payload
      };
      try {
        const response = await Axios.request(config)
        if (response?.status === 200) {
          getAssetRegistersByProduct(productInfo?.id)
        }
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  const onClickAssetItem = (item: any) => {
    setSelectedAssetItem(item?.id);
    setSelectedAssetRegister(item?.registerId);
    setOpenAssetRegModal(true)
  }
  
  const columns: IColumn[] = [
    {
      key: 'column1',
      name: t('assetRegister'),
      fieldName: 'kind',
      minWidth: 160,
      maxWidth: 300,
      isResizable: true,
      onRender: (item) => {
        return (
          <button className={contentStyles.assetTitleButton} key={`${item.key}`} onClick={() => onClickAssetItem(item)}>
            {item.type}&nbsp;{item.kind}
          </button>
        )
      }
    },
    // {
    //   key: 'column2',
    //   name: t('assetItemTitle'),
    //   fieldName: 'kind',
    //   minWidth: 120,
    //   maxWidth: 300,
    //   isResizable: true,
    //   onRender: (item) => {
    //     return (
    //       <button className={contentStyles.assetTitleButton} key={`${item.key}`} onClick={() => onClickAssetItem(item)}>
    //         {item.type}&nbsp;{item.kind}
    //       </button>
    //     )
    //   }
    // },
    {
      key: 'column3',
      name: t('assetItemType'),
      fieldName: 'type',
      minWidth: 120,
      maxWidth: 300,
      isResizable: true,
      onRender: (item) => { 
        return (
          <div key={`${item.key}`}>
            AxH
          </div>
        )
      }
    },
    {
      key: 'column4',
      name: t('#'), //
      fieldName: 'measuring',
      minWidth: 60,
      maxWidth: 60,
      isResizable: true,
    },
    {
      key: 'column5',
      name: t('mou'), //
      fieldName: 'type',
      minWidth: 60,
      maxWidth: 60,
      isResizable: true,
      onRender: (item) => { 
        return (
          <div key={`${item.key}`}>
            M
          </div>
        )
      }
    },
  ]

  const onClickAdd = () => {
    setSelectedAssetItem(null)
    if (selected?.length === 0) {
      setSelectedAssetRegister(null)
    }
    setFormData(initialFormValues);
    setOpenAssetRegModal(true)
  }

  const onClickEdit = () => {
    setOpenAssetRegModal(true) 
  }

  const assetRegisterOptions = () => {
    return assetRegisterDataCU.map((e: AssetRegister) => {
      return {
        value: e.id,
        label: e.name
      }
    })
  }

  // SUBMIT ACTION
  const constructPayloadToLink = (selectedAssetItem: any, productId: any) => ({
    AssetItemId: selectedAssetItem,
    AssetItemType: "AxH",
    PbsId: productId,
    ProjectSequenceId: projectSequenceCode
  });
  
  const constructPayloadToUpdate = (val: any): AssetItemFormTypes => ({
    name: val.name || `${val.type} ${val.kind}`,
    description: val.description,
    status: "D60AAD0B-2E84-482B-AD25-618D80D49477",
    state: "004EB795-8BBA-47E8-9049-D14774AB0B18",
    type: val.type || "",
    kind: val.kind || "",
    fluvius: val.fluvius ? parseInt(val.fluvius) : 0,
    measuring: val.measuring ? parseInt(val.measuring) : 0,
    yard: val.yard ? parseInt(val.yard) : 0,
    progressStatement: val.progressStatement ? parseInt(val.progressStatement) : 0,
    counteroffer: val.counteroffer,
    invoice: val.invoice ? parseInt(val.invoice) : 0,
    externalId: val.externalId
  });

  const submitForm = async (val: any) => {
    const payloadToLink = constructPayloadToLink(selectedAssetItem, productInfo?.id);
    const payloadToUpdate = constructPayloadToUpdate(val);
    try {
      setShowProgress(true);
      const requestToLink = await client.post(`${LINK_ASSET_REGISTER_TO_PRODUCT_FROM_CU_EP}`, payloadToLink)
      if (requestToLink.status === 200) {
        const response = await client.post(`${UPDATE_ASSET_REGISTER_AXH_EP}/${selectedAssetItem}`, payloadToUpdate)
        if (response.status === 200) {
          setFormData(initialFormValues)
          getAssetRegisters();
          getAssetRegistersByProduct(productInfo?.id);
          if (selected.length === 0) {
            setSelectedAssetRegister(null)
          }
          // setSelectedAssetRegister(null);
          setSelectedAssetItem(null)
          setOpenAssetRegModal(false);
        }
      }
    } catch (error) {
      console.log('error while saving UPDATE_ASSET_REGISTER_AXH_EP', error)
    } finally {
      setShowProgress(false)
    }
  }

  const createNewAssetItemFromPeoplePicker = async (sel: any) => {
    const payload: AssetItemFormTypes = {
      name: sel?.text,
      description: "",
      type: "",
      kind: "",
      status: initialFormValues?.status,
      state: initialFormValues.state,
      fluvius: 0,
      measuring: 0,
      yard: 0,
      progressStatement: 0,
      counteroffer: 0,
      invoice: 0,
      externalId: null
    }
    try {
      setShowProgress(true)
      const response = await client.post(`${CREATE_ASSET_REGISTER_ITEM}/${selectedAssetRegister}`, payload)
      if (response.status === 200) {
        getAssetRegisters();
        setSelectedAssetItem(response.data.result);
        setFormData(payload)
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setShowProgress(false)
    }
  }

  const assetItemSelection = new Selection({
    onSelectionChanged: () => {
      const sel = assetItemSelection?.getSelection();
      setSelected(sel)
    },
    items: selected
  })

  return (
    <div className={'document-pane-card'}>
      <div className={'card-header'}>
        <Link href="#" id="dynamic-attributes">
          <Label>4. {t('assetRegisters')} </Label>
        </Link>
      </div>

      <div className={'card-body'}>
        <div className="proj-detail-content inner">
          <GroupByDataGridForAssetRegisters 
            columns={columns}
            data={assetRegisterDataProduct ? assetRegisterDataProduct : []}
            onClickAdd={onClickAdd}
            onClickEdit={onClickEdit}
            onClickRemove={projectSequenceCode ? OnDeleteAssetItem : () => {}}
            assetItemSelection={assetItemSelection}
            selected={selected}
          />
        </div>
      </div>

      {/* Asset register modal  */}
      <div>
        <Modal
          titleAriaId={''}
          isOpen={openAssetRegModal}
          onDismiss={() => {
            setFormData(initialFormValues)
            setOpenAssetRegModal(false);
          }}
          isBlocking={true}
          containerClassName={contentStyles.modalContainer}
        >
          <div className={contentStyles.header}>
            <span>{t('assetRegisters')}</span>
            <IconButton
              styles={cancelIconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel="Close popup modal"
              onClick={() => {
                setOpenAssetRegModal(false);
              }}
            />
          </div>
          <ProgressBar show={showProgress}/>
          {/* Form appears here  */}
          <AssetRegisterForm
            data={assetRegisterDataCU} 
            handleSubmit={(val) => {
              projectSequenceCode && submitForm(val)
            }}
            assetRegisterOptions={assetRegisterOptions()}
            assetRegisterItemOptions={assetItemOptions}
            initialValue={formData}
            selectedAssertRegister={selectedAssetRegister}
            selectedAssertItemRegister={selectedAssetItem}
            onChangeAssertRegisterField={(value) => setSelectedAssetRegister(value)}
            onChangeAssertItemField={(value) => {
              if (value) {
                setSelectedAssetItem(value)
              } else {
                setSelectedAssetItem(value)
                setFormData(initialFormValues)
              }
            }}
            createNewAssetItemFromPeoplePicker={(sel) => createNewAssetItemFromPeoplePicker(sel)}
            // createNewAssetItem={() => {
            //   setSelectedAssetItem(null);
            //   setFormData({
            //     name: null, //combination type & kind
            //     description: "", //leave empty
            //     status: "D60AAD0B-2E84-482B-AD25-618D80D49477", //use this value as default
            //     state: "004EB795-8BBA-47E8-9049-D14774AB0B18", //default
            //     type: null,
            //     kind: null,
            //     fluvius: 0,
            //     measuring: null,
            //     yard: null,
            //     progressStatement: null,
            //     counteroffer: 0,
            //     invoice: null,
            //     externalId: null
            //   })
            //   setOpenAssetRegItemModal(true)
            // }}
          />
        </Modal>
      </div>
    </div>
  )
}

export default AssetRegisters
import { AnimationStyles, mergeStyles } from '@fluentui/react';
import React from 'react';
import { messageService } from '../../services/messageService';

export interface WithMainUIProps {
  loading: boolean;
  match: any;
}

export interface WithMainUIState {
  width: number;
  showPanel: boolean;
  activeClass: string;
  bookmarkDivClass: string;
  currentActiveSection: string;
  showDetails: boolean;
  isDisableNewButton: boolean;
  isDrag: boolean;
  resetLayout: boolean;
  collapseBookmarkPane: boolean;
  reSizer: number;
}

function withMainUI<T extends WithMainUIProps>(
  Component: React.ComponentType<T>,
) {
  return class extends React.Component<any, any> {
    _isMounted = false;
    subscription: any;

    constructor(props: any) {
      super(props);
      this.state = {
        width: window.innerWidth,
        showPanel: false,
        activeClass: 'grid-righter column-filter-activated',
        bookmarkDivClass: 'bookmark-panel parent',
        currentActiveSection: '1',
        showDetails: false,
        isDisableNewButton: false,
        isDrag: false,
        resetLayout: false,
        collapseBookmarkPane: false,
        reSizer: screen.width,
      };
    }

    handleShowDocumentPane = (collapseBookmarkPane?: boolean) => {
      let id = this.props.match.params.featureId;
      if (id) {
        if (this.state.width > 1100) {
          this.setState({
            showPanel: false,
            showDetails: true,
            reSizer: collapseBookmarkPane ? 300 : window.innerWidth / 2,
          });
        } else {
          this.setState({
            showPanel: true,
            showDetails: true,
            reSizer: collapseBookmarkPane ? 300 : window.innerWidth / 2,
          });
        }
      } else if (location.pathname.split('/').pop() === 'new') {
        if (this.state.width > 1100) {
          this.setState({
            showPanel: false,
            showDetails: true,
            reSizer: window.innerWidth / 2,
          });
        } else {
          this.setState({
            showPanel: true,
            showDetails: true,
            reSizer: window.innerWidth / 2,
          });
        }
      } else {
        //this.props.resetFormData();
        this.setState({
          showPanel: false,
          showDetails: false,
          reSizer: screen.width,
        });
      }
    };

    updateDimensions() {
      if (this._isMounted) {
        this.setState({
          width: window.innerWidth,
        });
        if (this.state.showDetails) {
          if (this.state.width > 1100) {
            this.setState({ showPanel: false });
          } else {
            this.setState({ showPanel: true });
          }
        }
      }
    }

    getAnimation = (state: boolean) => {
      return mergeStyles(
        state
          ? AnimationStyles.slideLeftIn400
          : AnimationStyles.slideRightIn400,
      );
    };

    handleScroll = (e: any): void => {
      let element = e.target;
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      }

      let h2Headings = document.getElementsByClassName('proj-detail-block');
      let item = h2Headings[0];
      let tt = 0;

      for (let i = 0; i < h2Headings.length; i++) {
        if (h2Headings[i].getBoundingClientRect().top <= 300) {
          if (tt <= h2Headings[i].getBoundingClientRect().top) {
            tt = h2Headings[i].getBoundingClientRect().top;
            item = h2Headings[i];

            var c = item.id;
            this.setState({ currentActiveSection: c });
          }
        }
      }
    };

    componentDidUpdate() {
      //this.handleShowDocumentPane()
    }

    componentWillUnmount() {
      this._isMounted = false;
      window.removeEventListener('resize', this.updateDimensions);
      //this.subscription.unsubscribe();
    }

    componentDidMount() {
      this._isMounted = true;
      window.addEventListener('resize', this.updateDimensions);
      window.addEventListener('load', this.updateDimensions);
      this.subscription = messageService.getMessage().subscribe((data: any) => {
        if (data) {
          if (data.data.hideDocumentPane) {

          }
          if (data.data.collapseDocumentPane) {
            this.setState({
              reSizer: (this.state.reSizer === 300) ? window.innerWidth / 2 : 300,
              isDrag: (!this.state.isDrag),
              collapseBookmarkPane: (!this.state.collapseBookmarkPane),
              resetLayout: (!this.state.resetLayout),
            });
          }
          if (data.data.shortCutPaneExpanded) {
            if (this.state.showDetails) {
              this.setState({
                reSizer: window.innerWidth / 2,
                isDrag: false,
                collapseBookmarkPane: false,
                resetLayout: true,
              });
            }
          }
        }
      });
    }

    render() {
      return (
        <Component
          {...(this.props as T)}
          handleShowDocumentPane={this.handleShowDocumentPane}
          updateDimensions={() => this.updateDimensions()}
          showDetails={this.state.showDetails}
          showPanel={this.state.showPanel}
          reSizer={this.state.reSizer}
          collapseBookmarkPane={this.state.collapseBookmarkPane}
          handleScroll={(e: any) => this.handleScroll(e)}
          currentActiveSection={this.state.currentActiveSection}
          animation={(state: boolean) => this.getAnimation(state)}
        />
      );
    }
  };
}

export default withMainUI;

import { getTheme, Label, Link } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import HistoryBar from '../../../shared/historyBar/historyBar';
import {
  BillOfResource,
  BillOfResourceValidationMessage,
  BorResources,
  BorResourcesItem,
} from '../../../types/billOfResources';
import { ActionButtonState } from '../../../types/corporateProductCatalogue';
import BorHeader from '../bor/borHeader';
import ProductHeader from '../product/productHeader';
import Resources from '../resources/resources';

const theme = getTheme();

export const DocumentPaneComponent = (props: {
  dropDownOptions: any;
  productOptions: any;
  cpcOptions: any;
  isEdit: boolean;
  billOfResource: BillOfResource;
  borResources: BorResources;
  saveFormData: () => void;
  getProductDetails: () => void;
  handleFormChange: (billOfResource: BillOfResource, save: boolean) => void;
  validationMessages: BillOfResourceValidationMessage;
  isBorReturn: boolean

  material: BorResourcesItem;
  materials: BorResourcesItem[];
  openMaterialModal: () => void;
  materialActionButtonState: ActionButtonState;
  materialSelection: any;
  tools: BorResourcesItem[];
  toolsActionButtonState: ActionButtonState;
  openToolsModal: () => void;
  toolSelection: any;
  handleMaterialDelete: () => void;
  handleToolsDelete: () => void;
  materialLoading: boolean;
  toolsLoading: boolean;
  labours: BorResourcesItem[];
  labourActionButtonState: ActionButtonState;
  openLabourModal: () => void;
  labourSelection: any;
  labourLoading: boolean;
  handleLabourDelete: () => void;
  openConsumableModal: () => void;
  consumableSelection: any;
  consumableActionButtonState: ActionButtonState;
  consumables: BorResourcesItem[];
  consumalbeLoading: boolean;
  handleConsumableDelete: () => void;
  cpcData: any;
}) => {
  const { t } = useTranslation();
  return (
    <div style={{ width: '100%' }} id={'BorForm'}>
      <div className="proj-detail-block">
        { /* <ProjectHeader*/}
        { /* projectDetails={ props.billOfResource} />*/}
      </div>

      <div className="proj-detail-block" id="1">
        <BorHeader
          dropDownOptions={props.dropDownOptions}
          isEdit={props.isEdit}
          billOfResource={props.billOfResource}
          saveFormData={() => props.saveFormData()}
          handleFormChange={(billOfResource: BillOfResource, save: boolean) =>
            props.handleFormChange(billOfResource, save)
          }
          validationMessages={props.validationMessages}
          isBorReturn={props.isBorReturn}
        />
      </div>

      {!props.isBorReturn &&
      <div className="proj-detail-block" id="2">
        <ProductHeader
          dropDownOptions={props.dropDownOptions}
          isEdit={props.isEdit}
          billOfResource={props.billOfResource}
          saveFormData={() => props.saveFormData()}
          getProductDetails={() => props.getProductDetails()}
          handleFormChange={(billOfResource: BillOfResource, save: boolean) =>
            props.handleFormChange(billOfResource, save)
          }
          validationMessages={props.validationMessages}
          productOptions={props.productOptions}
        />
      </div>
      }

      <div>
        <Resources
          cpcOptions={props.cpcOptions}
          dataList={props.billOfResource.borResources}
          editDataGrid={() => {
          }}
          isLoaded={{
            materialLoading: props.materialLoading,
            toolsLoading: props.toolsLoading,
            consumalbeLoading: props.consumalbeLoading,
            labourLoading: props.labourLoading,
          }}
          isEdit={props.isEdit}
          cpcData={props.cpcData}
          billOfResource={props.billOfResource}
          saveFormData={() => props.saveFormData()}
          actionButtonState={{
            materialActionButtonState: props.materialActionButtonState,
            toolsActionButtonState: props.toolsActionButtonState,
            consumableActionButtonState: props.consumableActionButtonState,
            labourActionButtonState: props.labourActionButtonState,
          }}
          materialSelection={props.materialSelection}
          openMaterialModal={props.openMaterialModal}
          handleMaterialDelete={props.handleMaterialDelete}
          toolSelection={props.toolSelection}
          openToolsModal={props.openToolsModal}
          handleToolsDelete={props.handleToolsDelete}
          consumableSelection={props.consumableSelection}
          openConsumableModal={props.openConsumableModal}
          handleConsumableDelete={props.handleConsumableDelete}
          labourSelection={props.labourSelection}
          openLabourModal={props.openLabourModal}
          handleLabourDelete={props.handleLabourDelete}
          isBorReturn={props.isBorReturn}
        />
      </div>

      {props.isEdit &&
      props.billOfResource.historyLog &&
      props.billOfResource.historyLog.createdByUser && (
        <div className="proj-detail-block" id="8">
          <div className="document-pane-card">
            <div className="marginTop marginBottom">
              <Link href="#" id="history">
                <Label>7. {t('history')} </Label>
              </Link>
              <div className={'ms-Grid'} dir="ltr" style={{ marginBottom: 10 }}>
                <HistoryBar
                  createdByUser={
                    props.billOfResource.historyLog.createdByUser
                      ? props.billOfResource.historyLog.createdByUser
                      : ''
                  }
                  updatedByUser={
                    props.billOfResource.historyLog.updatedByUser
                      ? props.billOfResource.historyLog.updatedByUser
                      : ''
                  }
                  createdDateTime={
                    props.billOfResource.historyLog.createdDateTime
                      ? props.billOfResource.historyLog.createdDateTime
                      : ''
                  }
                  updatedDateTime={
                    props.billOfResource.historyLog.updatedDateTime
                      ? props.billOfResource.historyLog.updatedDateTime
                      : ''
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentPaneComponent);

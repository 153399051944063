import React, { useEffect, useState } from 'react';
import TaxonomyField from '../../../../shared/taxonomyField/taxonomyField';
import { useDispatch, useSelector } from 'react-redux';
import { getContractorsData } from '../../../../reducers/addressBookReducer';
import TaxonomyGrid from '../../../../shared/taxonomyField/taxonomyGrid';
import { getPerentFromTreeData } from '../../../../shared/util';
import { Checkbox } from '@fluentui/react';

const LotContractorsTaxonomy = (props: {
  label?: string
  selectItemId: string | null
  onChange?: any
  disabled?: boolean
}) => {
  const dispatch = useDispatch();

  const contractorsData = useSelector(
    (state: any) => state.addressBook.contractors,
  );
  const [selectedParentIds, setSelectedParentIds]: any = useState([]);
  const [selectItemId, setselectItemId]: any = useState(props.selectItemId);
  // const [selectItemId, setselectItemId]: any = useState("88bc4819-hn12-4c38-8a59-20da60ecb89e");

  useEffect(() => {
    dispatch(getContractorsData());
  }, []);

  useEffect(() => {
    if (selectItemId) {
      setSelectedParentIds(
        getPerentFromTreeData(contractorsData, selectItemId),
      );
    } else {
      setSelectedParentIds([]);
    }
  }, [contractorsData, selectItemId]);

  useEffect(() => {
    setselectItemId(props.selectItemId);
  }, [props.selectItemId]);

  const renderButtons = (rowInfo: any) => {
    const isParent = selectedParentIds.includes(rowInfo.node.id);
    if (isParent) {
      return (
        <div
          key={rowInfo.node.id}
          className={'Indeterminate'}
          style={{ paddingTop: 6 }}
        >
          <span
            style={{ display: 'none' }}>{JSON.stringify(isParent)}</span> {/* if u remove this part parent selection not working properly   */}
          <Checkbox
            className="btn btn-outline-success"
            styles={{root: { verticalAlign: 'middle'} }}
            defaultIndeterminate
            onChange={() => handleNodeCheckboxClick(rowInfo)}
          />
        </div>
      );
    } else {
      return (
        <div
          key={rowInfo.node.id}
          className={'Indeterminate'}
          style={{ paddingTop: 6 }}
        >
          <Checkbox
            className="btn btn-outline-success"
            styles={{root: { verticalAlign: 'middle'} }}
            checked={rowInfo.node.id === selectItemId}
            onChange={() => handleNodeCheckboxClick(rowInfo)}
          />
        </div>
      );
    }

  };

  const handleNodeCheckboxClick = (rowInfo: any) => {
    props.onChange(rowInfo?.node);
  };

  return (
    <div>
      <TaxonomyField label={props.label} treeData={contractorsData} selectItemId={selectItemId}
                     disabled={props.disabled}>
        <TaxonomyGrid
          treeData={contractorsData}
          renderButtons={renderButtons}
        />
      </TaxonomyField>
    </div>
  );
};


export default LotContractorsTaxonomy;

import {
  ACCEPT_INVITATION_EP,
  DOWNLOAD_LOT_DOCUMENTS_EP,
  READ_DOWNLOAD_ALL_DOCUMENT_EP,
  READ_LOT_INVITATION_BY_ID_EP,
  READ_LOT_SUBSCRIBE_BY_ID_EP,
  SUBSCRIBE_LOT_EP,
} from '../shared/endpoints';
import {
  ACCEPT_LOT_INVITATION,
  ACCEPT_LOT_INVITATION_FAIL,
  ACCEPT_LOT_INVITATION_SUCCESS,
  DOWNLOAD_LOT_DOCUMENTS,
  DOWNLOAD_LOT_DOCUMENTS_FAIL,
  DOWNLOAD_LOT_DOCUMENTS_SUCCESS,
  LOAD_LOT_INVITATION_BY_ID,
  LOAD_LOT_INVITATION_BY_ID_FAIL,
  LOAD_LOT_INVITATION_BY_ID_SUCCESS,
  LOAD_LOT_SUBSCRIBE_BY_ID,
  LOAD_LOT_SUBSCRIBE_BY_ID_FAIL,
  LOAD_LOT_SUBSCRIBE_BY_ID_SUCCESS,
  READ_DOWNLOAD_ALL_DOCUMENT,
  READ_DOWNLOAD_ALL_DOCUMENT_FAIL,
  READ_DOWNLOAD_ALL_DOCUMENT_SUCCESS,
  RESET_LOT_INVITATION_IS_CHANGE,
  SAVE_LOT_INVITATION_STATE_ATTR,
  SUBSCRIBE_LOT,
  SUBSCRIBE_LOT_FAIL,
  SUBSCRIBE_LOT_SUCCESS,
} from '../actionTypes/lotInvitationActionTypes';

const defaultState: any = {
  lotInvList: [],
  isLOTINVListLoaded: false,
  isNew: false,
  isChange: false,
  isEdit: false,
  showProgressBar: false,
  lotInvDropDowns: {},
  formData: {},
  uid: null,
  loadMsg: '',
  lotInvShortCutPaneData: [],
  reloadListPane: false,
  borResources: {},
  isLoadingLotInvitation: false,
  acceptResult: null,
  subscribeResult: null,
  approve: false,
  downloadLinks: [],
  subscribeFormData: {},
};

export default function lotInvitationReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {

      case LOAD_LOT_INVITATION_BY_ID:
        return { ...state, formData: {}, showProgressBar: true, isLoadingLotInvitation: true };
      case LOAD_LOT_INVITATION_BY_ID_SUCCESS:
        return {
          ...state,
          isEdit: true,
          formData: action.payload.data.result,
          showProgressBar: false,
          isLoadingLotInvitation: false,
        };
      case LOAD_LOT_INVITATION_BY_ID_FAIL:
        return {
          ...state, formData: {}, showProgressBar: false, isLoadingLotInvitation: false,
        };

      case LOAD_LOT_SUBSCRIBE_BY_ID:
        return { ...state, subscribeFormData: {}, showProgressBar: true, isLoadingLotInvitation: true };
      case LOAD_LOT_SUBSCRIBE_BY_ID_SUCCESS:
        return {
          ...state,
          isEdit: true,
          subscribeFormData: action.payload.data.result,
          showProgressBar: false,
          isLoadingLotInvitation: false,
        };
      case LOAD_LOT_SUBSCRIBE_BY_ID_FAIL:
        return {
          ...state, subscribeFormData: {}, showProgressBar: false, isLoadingLotInvitation: false,
        };

      case ACCEPT_LOT_INVITATION:
        return {
          ...state, showProgressBar: true, acceptResult: null,
        };
      case ACCEPT_LOT_INVITATION_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
          acceptResult: action.payload.data.result,
        };
      case ACCEPT_LOT_INVITATION_FAIL:
        return {
          ...state, showProgressBar: false, acceptResult: null,
        };

      case SUBSCRIBE_LOT:
        return {
          ...state, showProgressBar: true, subscribeResult: null,
        };
      case SUBSCRIBE_LOT_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
          subscribeResult: action.payload.data.result,
        };
      case SUBSCRIBE_LOT_FAIL:
        return {
          ...state, showProgressBar: false, subscribeResult: null,
        };

      case DOWNLOAD_LOT_DOCUMENTS:
        return {
          ...state,
          downloadLinks: [],
          showProgressBar: true,
        };
      case DOWNLOAD_LOT_DOCUMENTS_SUCCESS:
        return {
          ...state,
          downloadLinks: action.payload.data.result,
          showProgressBar: false,
        };
      case DOWNLOAD_LOT_DOCUMENTS_FAIL:
        return {
          ...state,
          downloadLinks: [],
          showProgressBar: false,
        };

      // eslint-disable-next-line no-case-declarations
      case SAVE_LOT_INVITATION_STATE_ATTR:
        const attr = Object.keys(action)[1];
        const value = Object.values(action)[1];
        return {
          ...state,
          [attr]: value,
        };

      case READ_DOWNLOAD_ALL_DOCUMENT:
        return {
          ...state,
          downloadLinks: [],
          showProgressBar: true,
        };
      case READ_DOWNLOAD_ALL_DOCUMENT_SUCCESS:
        return {
          ...state,
          downloadLinks: action.payload.data.result,
          showProgressBar: false,
        };
      case READ_DOWNLOAD_ALL_DOCUMENT_FAIL:
        return {
          ...state,
          downloadLinks: [],
          showProgressBar: false,
        };

      default:
        return state;
    }
  }
}

export const readByLotInvitationId = (id: string) => {
  return {
    types: [
      LOAD_LOT_INVITATION_BY_ID,
      LOAD_LOT_INVITATION_BY_ID_SUCCESS,
      LOAD_LOT_INVITATION_BY_ID_FAIL,
    ],
    payload: { request: { url: READ_LOT_INVITATION_BY_ID_EP + id } },
  };
};

export const resetIsChange = () => {
  return { type: RESET_LOT_INVITATION_IS_CHANGE };
};

export const acceptLotInvitation = (data: any) => {
  return {
    types: [
      ACCEPT_LOT_INVITATION,
      ACCEPT_LOT_INVITATION_SUCCESS,
      ACCEPT_LOT_INVITATION_FAIL,
    ],
    payload: {
      request: {
        url: ACCEPT_INVITATION_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const subscribeLot = (data: any) => {
  return {
    types: [
      SUBSCRIBE_LOT,
      SUBSCRIBE_LOT_SUCCESS,
      SUBSCRIBE_LOT_FAIL,
    ],
    payload: {
      request: {
        url: SUBSCRIBE_LOT_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const downloadLotDocuments = (data: any) => {
  return {
    types: [
      DOWNLOAD_LOT_DOCUMENTS,
      DOWNLOAD_LOT_DOCUMENTS_SUCCESS,
      DOWNLOAD_LOT_DOCUMENTS_FAIL,
    ],
    payload: {
      request: {
        url: DOWNLOAD_LOT_DOCUMENTS_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export function saveLotInvitationStateAttr(attr: string, value: any) {
  return {
    type: SAVE_LOT_INVITATION_STATE_ATTR,
    [attr]: value,
  };
}

export const readByLotSubscribeId = (id: string) => {
  return {
    types: [
      LOAD_LOT_SUBSCRIBE_BY_ID,
      LOAD_LOT_SUBSCRIBE_BY_ID_SUCCESS,
      LOAD_LOT_SUBSCRIBE_BY_ID_FAIL,
    ],
    payload: { request: { url: READ_LOT_SUBSCRIBE_BY_ID_EP + id } },
  };
};

export const getDownloadAllDocumentDownload = (formData: any) => {
  return {
    types: [
      READ_DOWNLOAD_ALL_DOCUMENT,
      READ_DOWNLOAD_ALL_DOCUMENT_SUCCESS,
      READ_DOWNLOAD_ALL_DOCUMENT_FAIL,
    ],
    payload: {
      request: {
        url: READ_DOWNLOAD_ALL_DOCUMENT_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};
import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';
import { PMOL_TYPE } from '../../../types/projectMolecule';

const CommandBarLeft = (props: {
  createNew: (create: boolean, type?: PMOL_TYPE) => void,
  handleCloneItem: () => void;
  isClone: boolean;
  selectedItemIds: [] | string[];
}) => {
  const { t } = useTranslation();
  const handleRegular = () => {
    messageService.sendMessage({ isNewPmol: true, pmolType: PMOL_TYPE.REGULAR, pmolTypeFromRead: PMOL_TYPE.REGULAR });
    props.createNew(true, PMOL_TYPE.REGULAR);
  };
  const handleSubContract = () => {
    messageService.sendMessage({ isNewPmol: true, pmolType: PMOL_TYPE.SUB, pmolTypeFromRead: PMOL_TYPE.SUB });
    props.createNew(true, PMOL_TYPE.SUB);
  };

  const getItems = () => {
    return [
      {
        key: 'new',
        text: t('new'),
        iconProps: { iconName: 'Add' },
        split: true,
        subMenuProps: {
          items: [
            { key: 'regular', text: t('clientName'), iconProps: { iconName: 'Add' }, onClick: () => handleRegular() },
            {
              key: 'subContract',
              text: t('subcontractor'),
              iconProps: { iconName: 'Add' },
              onClick: () => handleSubContract(),
            },
          ],
        },
        disabled: (false),
        onClick: () => handleRegular(),
      },
      {
        key: 'Clone',
        name: t('clone'),
        disabled: (props.selectedItemIds.length !== 1 && !props.isClone),
        iconProps: { iconName: 'Copy' },
        onClick: () => {
          props.handleCloneItem();
        },
      },
    ];
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );
};

export default CommandBarLeft;

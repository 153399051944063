import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import {Calendar, momentLocalizer, Views} from 'react-big-calendar';
import moment from 'moment';
import CustomToolbar from '../../../../shared/kanban/customToolbar';
import {useDispatch, useSelector, useStore} from 'react-redux';
import * as TdDropBox from '../../../../shared/dnd/dropBox';
import {getPmolType, ProjectPlanResourceType} from '../../../../types/myDayPlanning';
import {messageService} from '../../../../services/messageService';
import {
  addMultipleMembersToPmol,
  assignPmolToProject,
  assignTeamToPmol,
  assignTeamToProject,
  assignVehicleToPmol,
  assignVehicleToProject,
  clonePmolDayPlanning,
  filterProjectDayPlanningProjectList,
  removeLabourPdp,
  resizePmolOnProject,
  saveProjectDayPlanningStateAttr,
  updatePlanBoardsPmolStatus,
} from '../../../../reducers/projectDayPlanningReducer';
import {
  formatDateTimeToISOString,
  getCalenderLocalizer,
  MSG_AUTO_CLOSE_TIME,
  stringDateToDateObject,
} from '../../../../shared/util';
import {useTranslation} from 'react-i18next';
import {ICommandBarItemProps, Link, Panel, PanelType, registerIcons, TooltipHost,} from '@fluentui/react';
import SelectCompetencyModal from './selectCompetencyModal';
import {PMOL_ACTIVITY_TYPES} from '../../../../types/projectMolecule';
import {SvgIcons} from '../../../../shared/svgIcons/svgIcons';
import {saveStateAttr} from '../../../../reducers/uprinceReducer';
import CommandBarLeft from '../../commandBar/commandBarLeft';
import EventBody from '../eventBody';
import CIAWModal from '../ciawModal';
import CustomAlert from '../../../../shared/alert/customAlert';
import ConfirmationDialog from '../../../../shared/confirmationDialog/confirmationDialog';
import RFQModal from '../rfqModal';
import {
  assignPlanningPbs,
  assignPlanningProject,
  saveMyDayPlanningStateAttr,
} from '../../../../reducers/myDayPlanningReducer';
import PmolTimeChangeModel from '../../documentPane/models/pmolTimeChangeModel';
import PmolTitleModal from '../pmolTitleModal';
import LabourTooltip from '../ LabourTooltip';
import PrintModal from '../../documentPane/models/printModal';
import NewProjectModal from '../../documentPane/models/newProjectModal';
import {uPrinceTheme} from '../../../../../theme';
import CreatePmolModal from '../../../dayPlanning/pmolListPane/component/newPmol';
import {saveDayPlanningStateAttr} from '../../../../reducers/dayPlanningReducer';
import {savePMOLStateAttr} from '../../../../reducers/pmolReducer';
import CopyPmolModal from "../../../../shared/PlanboardComponents/CopyPmolModal/component";


// const theme = getTheme();


registerIcons(SvgIcons);

const TopListPane=()=>{
  // Create a custom localizer using moment.js
  const localizer = momentLocalizer(moment);

  const dispatch = useDispatch();
  const store = useStore();
  const { t } = useTranslation();
  const {
    // selectedDate,
    horizontalReSizer,
    projectList,
    pmolList,
    projectDpViewMode,
    buDropDown,
    showFullScreen,
    loadMsgRfq,
    isConfirmDeleteModalOpen,
    selectedWorker,
    selectedPmol,
    ppbPmolTypeId,
    ppbJobStatus,
    ppbSiteManagerId,
    ppbProjectSequenceCode,
    selectedCu,
    isPmolTimeUpdated,
    showProgressBar
  } = useSelector(
    (state: any) => state.projectDPlan
  );

  // Using the same selected date for both plan boards -> Common SelectedDate from myDayPlanning(teamPlanBoard)
  const {
    selectedDate,
    planboardPersonId,
    isNewProjectCreated
  } = useSelector(
    (state: any) => state.mydPlan
  );

  const {
    userInfo
  } = useSelector(
    (state: any) => state.uprince
  );
  const {
    isChange,
    isEdit
  } = useSelector(
    (state: any) => state.pmol
  );

  const { createNewPmolSuccess } = useSelector((state: any) => state.dplan);
  const clonePmolId = useSelector((state: any) => state?.pmol?.clonePmolId);

  const [eventList, setEventList]: any = useState([]);
  // const [mouseOffset, setMouseOffset]: any = useState(null);
  const [showMsg, setShowMsg]: any = useState(false);
  const [isCompetencyModalOpen, setIsCompetencyModalOpen] = useState<boolean>(false);
  const [selectedPmolState, setSelectedPmolState] = useState<any>(null);
  const [resourceCategory, setResourceCategory] = useState<string>("");
  const [scrollToTime, setScrollToTime] = React.useState<any>(null);

  const DragAndDropCalendar = withDragAndDrop(Calendar as any);
  const divRef = useRef<HTMLDivElement>(null);


  const handleClick = (props: any) => {
    if (props?.target?.getAttribute('data-pmol')) {
      let pmol = JSON.parse(props?.target?.getAttribute('data-pmol'))
      openPmol({ event: pmol })
    }

  }

  const getSelectCuItem = (getId: boolean) => {
    let contractingUnit = selectedCu ? selectedCu?.sequenceCode : userInfo?.contractingUnit?.sequnceCode;
    let contractingUnitId = selectedCu ? selectedCu?.key : userInfo?.contractingUnit?.id;

    if (getId) {
      return contractingUnitId
    } else {
      return contractingUnit
    }
  };

  const setStaringTime = () => {
    if(projectDpViewMode && projectDpViewMode<=30){
        const top = projectDpViewMode==7.5?'800':projectDpViewMode==15?'400':'200';
        const element = document.getElementsByClassName('rbc-time-content')[0];
        element.scrollLeft = 0;
        element.scrollTop = parseInt(top);
        localStorage.setItem('lastScrollLeft', '0');
        localStorage.setItem('lastScrollTop', top);
        document.getElementsByClassName('rbc-time-content')?.[0].removeEventListener('scroll', handleCalenderScroll);
        document.getElementsByClassName('rbc-time-content')?.[0].addEventListener('scroll', handleCalenderScroll);
    }    
  }

  useEffect(()=>{
    setStaringTime();
    getCalenderLocalizer();
    document.addEventListener("mousedown", handleClick);
    dispatch(saveProjectDayPlanningStateAttr('isCIAWgenerated', false));
    // subscribe to home component messages
    const subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.dndItem) {
          if (data.data.dndItem && data.data.dndItem.type === ProjectPlanResourceType.PMOL) {
            assignToPmolProject({...data.data.dndItem?.dragItem,offset:data.data.dndItem?.offset});
          } else if (data.data.dndItem && data.data.dndItem.type === ProjectPlanResourceType.TEAM) {
            if (data.data.dndItem.isPmol) {
              assignTeamPmol({...data.data.dndItem?.dragItem,offset:data.data.dndItem?.offset}, data.data.dndItem?.dropItem);
            } else {
              assignTeamProject({...data.data.dndItem?.dragItem,offset:data.data.dndItem?.offset}, data.data.dndItem?.dropItem);
            }
          } else if (data.data.dndItem && data.data.dndItem.type === ProjectPlanResourceType.PBS) {
              assignPBSProject({...data.data.dndItem?.dragItem,offset:data.data.dndItem?.offset}, data.data.dndItem?.dropItem);
          } else if (data.data.dndItem && data.data.dndItem.type === ProjectPlanResourceType.PROJECT) {
              assignProject({...data.data.dndItem?.dragItem,offset:data.data.dndItem?.offset}, data.data.dndItem?.dropItem);
          } else if (data.data.dndItem && data.data.dndItem.type === ProjectPlanResourceType.VEHICLE) {
            if (data.data.dndItem.isPmol) {
              assignVehiclePmol({...data.data.dndItem?.dragItem,offset:data.data.dndItem?.offset}, data.data.dndItem?.dropItem);
            } else {
              assignVehicleProject({...data.data.dndItem?.dragItem,offset:data.data.dndItem?.offset}, data.data.dndItem?.dropItem);
            }
          } else if (data.data.dndItem && data.data.dndItem.type === ProjectPlanResourceType.TOOL) {
            if (data.data.dndItem.isPmol) {
              assignToolPmol({...data.data.dndItem?.dragItem,offset:data.data.dndItem?.offset}, data.data.dndItem?.dropItem);
            } else {
              assignToolProject({...data.data.dndItem?.dragItem,offset:data.data.dndItem?.offset}, data.data.dndItem?.dropItem);
            }
          } else if (data.data.dndItem && data.data.dndItem.type === ProjectPlanResourceType.ORG_TEAMS) {
            if (data.data.dndItem.isPmol) {
              assignOrgTeamPmol({...data.data.dndItem?.dragItem,offset:data.data.dndItem?.offset}, data.data.dndItem?.dropItem);
            } else {
              // assignToolProject(data.data.dndItem?.dragItem, data.data.dndItem?.dropItem);
            }
          } else {
            //  console.log('else',data.data.dndItem)
          }
        }

        if (data.data.pmolDndItem) {
          // console.log('pmolDndItem',data.data.pmolDndItem)
        }
        if(data.data.fullScreen){
          dispatch(saveProjectDayPlanningStateAttr('showFullScreen', true))
        }
        if(data.data.reloadDayPlan){
          reloadListPanes();
        }

        if (data.data.showAllInScreen) {
            console.log(data.data.showAllInScreen)
            const div = divRef.current;
            if(div){
              if (div.classList.contains("show-all-in-screen")) {
                div.classList.remove("show-all-in-screen");
              } else {
                div.classList.add("show-all-in-screen");
              }
            }
        }
      }
    });

    // INITIAL LOAD LOGIC IS APPLIED ON THE PLANBOARD SHORTCUT PANE
      // dispatch(filterProjectDayPlanningProjectList({
      //   executionDate: formatDateTimeToISOString(selectedDate),
      //   contractingUnit: getSelectCuItem(false) ? [getSelectCuItem(false)] : [],
      //   buId: buDropDown ? buDropDown[0]?.key : null,
      //   cabPersonId: planboardPersonId ? planboardPersonId : null,
      //   projectSequenceCode: ppbProjectSequenceCode && ppbProjectSequenceCode.value ? ppbProjectSequenceCode.value : null,
      //   pmolTypeId: ppbPmolTypeId ?? null,
      //   siteManagerId: ppbSiteManagerId ?? null
      // }));

    const interval = setInterval(() => {
      reloadFullScreen();
    }, 300000);

    document.getElementsByClassName('rbc-time-content')?.[0]?.addEventListener('scroll', handleCalenderScroll);
    // return unsubscribe method to execute when component unmounts
    return () => {
      subscription.unsubscribe();
      dispatch(saveProjectDayPlanningStateAttr('projectList', []));
      dispatch(saveProjectDayPlanningStateAttr('pmolList', []));
      document.removeEventListener("mousedown", handleClick);
      dispatch(saveProjectDayPlanningStateAttr('isRfqGenerated', false));
      clearInterval(interval);
      document.getElementsByClassName('rbc-time-content')?.[0]?.removeEventListener('scroll', handleCalenderScroll);
      localStorage.setItem('lastScrollPotion','0');
      dispatch(saveMyDayPlanningStateAttr('pmolCopyState', {event: {}, isCopyModalOpen: false}));
    }
  }, []);

  useEffect(() => {    
        setStaringTime();
        const sDate = new Date(selectedDate);
        const scrollTime = new Date(sDate.getFullYear(), sDate.getMonth(), sDate.getDate(), 5, 0, 0);
        setScrollToTime(scrollTime);
  },[selectedDate, projectDpViewMode])

  useEffect(()=>{
    if(isPmolTimeUpdated){
        reloadListPanes();
    }
  }, [isPmolTimeUpdated])

  useEffect(()=>{
    if(isNewProjectCreated){
        reloadListPanes();
    }
  }, [isNewProjectCreated])

  const handleCalenderScroll=(event:any)=>{
    const rbcTimeContent = document.getElementsByClassName('rbc-time-content')[0];
    localStorage.setItem('lastScrollPotion',rbcTimeContent?.scrollLeft.toString())
    console.log(event);
    localStorage.setItem('lastScrollLeft', rbcTimeContent?.scrollLeft.toString());
    localStorage.setItem('lastScrollTop', rbcTimeContent?.scrollTop.toString());
  }


  const handleAutoScroll = (offset?:any) => {
      document.getElementsByClassName('rbc-time-content')?.[0].removeEventListener('scroll', handleCalenderScroll);
      document.getElementsByClassName('rbc-time-content')?.[0].addEventListener('scroll', handleCalenderScroll);
      const lastScrollLeft = localStorage.getItem('lastScrollLeft');
      const lastScrollTop = localStorage.getItem('lastScrollTop');

      if(selectedPmol?.projectMoleculeId){
        const re = document.getElementById("resource"+selectedPmol?.resourceId);
        let lastScrollPotion=localStorage.getItem('lastScrollPotion');
        let scrolledTo=lastScrollPotion?parseInt(lastScrollPotion):0;
        console.log({re})
        const elementHeader = document.getElementsByClassName('rbc-time-header')[0];
        const element = document.getElementsByClassName('rbc-time-content')[0];
        if(element && re){
          console.log('re offsetLeft',re.offsetLeft)
          console.log('re lastScrollPotion',scrolledTo)
          elementHeader.scrollLeft=(re.offsetLeft?(re.offsetLeft-446):0)
          element.scrollLeft = lastScrollLeft ? parseInt(lastScrollLeft) : 0;
          element.scrollTop = lastScrollTop ? parseInt(lastScrollTop) : 800;
        }
      }else{
        let lastScrollPotion=localStorage.getItem('lastScrollPotion');
        let scrolledTo=lastScrollPotion?parseInt(lastScrollPotion):0;
        const elementHeader = document.getElementsByClassName('rbc-time-header')[0];
        const elementBody = document.getElementsByClassName('rbc-time-content')[0];
        elementHeader.scrollLeft=(scrolledTo);
        elementBody.scrollLeft = lastScrollLeft ? parseInt(lastScrollLeft) : 0;
        elementBody.scrollTop = lastScrollTop ? parseInt(lastScrollTop) : 800;
      }    
    // console.log(`handleAutoScroll offset:${JSON.stringify(offset)} `)
    // if(offset && offset.x){
    //   const elementHeader = document.getElementsByClassName('rbc-time-header')[0];
    //   const element = document.getElementsByClassName('rbc-time-content')[0];
    //   if(element && offset){
    //      elementHeader.scrollLeft=(offset.x)
    //      element.scrollLeft=(offset.x)
    //   }
    // }


  };

  useLayoutEffect(() => {
    handleAutoScroll();
  });

  useEffect(()=>{
    if(isEdit){
      document.getElementsByClassName('rbc-time-content')?.[0].removeEventListener('scroll', handleCalenderScroll);
      document.getElementsByClassName('rbc-time-content')?.[0].addEventListener('scroll', handleCalenderScroll);
      setTimeout(()=>{
        handleAutoScroll();
      },1000)
    }
  },[isEdit])

  useEffect(()=>{
    document.getElementsByClassName('rbc-time-content')?.[0].removeEventListener('scroll', handleCalenderScroll);
    document.getElementsByClassName('rbc-time-content')?.[0].addEventListener('scroll', handleCalenderScroll);
    // setTimeout(()=>{handleAutoScroll()},1000)
  },[eventList])

  useEffect(() => {
    if (pmolList) {
      setEventList(getPmolList())
    }
  }, [pmolList]);


  useEffect(()=>{
    if(isChange){
      reloadListPanes();
    }
    return () => {
      dispatch(savePMOLStateAttr('isChange', false));
    }
  },[isChange]);

  useEffect(()=>{
    if (planboardPersonId) {
      reloadListPanes();
    }
  },[planboardPersonId]);

  useEffect(()=>{
    if (ppbSiteManagerId || ppbPmolTypeId || ppbProjectSequenceCode || ppbJobStatus) {
      reloadListPanes();
    }
  },[ppbSiteManagerId, ppbPmolTypeId, ppbProjectSequenceCode, ppbJobStatus]);

  useEffect(() => {
    if (createNewPmolSuccess) {
      reloadListPanes();
      dispatch(saveProjectDayPlanningStateAttr('reloadBottomListPane', true));
    }
    return () => {
      dispatch(saveDayPlanningStateAttr('createNewPmolSuccess', false));
    }
  }, [createNewPmolSuccess]);

  useEffect(()=>{
    if(loadMsgRfq){
      setShowMsg(true)
      setTimeout(()=>{
        setShowMsg(false)
        dispatch(saveProjectDayPlanningStateAttr('loadMsgRfq',''))
      },MSG_AUTO_CLOSE_TIME)
    }
  },[loadMsgRfq]);

  useEffect(() => {
    if(clonePmolId){
      reloadListPanes();
      dispatch(saveProjectDayPlanningStateAttr('reloadBottomListPane', true));
    }

    return () => {
      dispatch(savePMOLStateAttr('clonePmolId', null));
    }
  },[clonePmolId]);

  const reloadListPanes = (item?:any) => {
    let Bu = store.getState()?.projectDPlan?.selectedBu;
    // let Cu = store.getState()?.projectDPlan?.selectedCu;

    // handleAutoScroll(item?.offset)
    Promise.all([
      dispatch(filterProjectDayPlanningProjectList({
        executionDate: formatDateTimeToISOString(store.getState()?.mydPlan?.selectedDate),
        contractingUnit: getSelectCuItem(false) ? [getSelectCuItem(false)] : [],
        buId: Bu,
        cabPersonId: planboardPersonId ? planboardPersonId : null,
        projectSequenceCode: ppbProjectSequenceCode && ppbProjectSequenceCode.value ? ppbProjectSequenceCode.value : null,
        pmolTypeId: ppbPmolTypeId ?? null,
        siteManagerId: ppbSiteManagerId ?? null,
        isJobDone: ppbJobStatus?ppbJobStatus:null,
      })),
    ]).then(() => {
      // handleAutoScroll(item?.offset)
    });

    // dispatch(getProjectDayPlanningResourceList(getFilters(store.getState()?.mydPlan?.selectedDate, getSelectCuItem(false), Bu, planboardPersonId)))

  };


  const reloadFullScreen = () => {
    if(store.getState()?.projectDPlan?.showFullScreen){
      let Bu = store.getState()?.projectDPlan?.selectedBu
      dispatch(filterProjectDayPlanningProjectList({
        executionDate: formatDateTimeToISOString(store.getState()?.mydPlan?.selectedDate),
        contractingUnit: getSelectCuItem(false) ? [getSelectCuItem(false)] : [],
        buId: Bu,
        cabPersonId: planboardPersonId ? planboardPersonId : null,
        projectSequenceCode: ppbProjectSequenceCode && ppbProjectSequenceCode.value ? ppbProjectSequenceCode.value : null,
        pmolTypeId: ppbPmolTypeId ?? null,
        siteManagerId: ppbSiteManagerId ?? null,
        isJobDone: ppbJobStatus?ppbJobStatus:null,
      }));
    }
  };



  const assignToPmolProject = (dragItem: any) => {
    let data = {
      id: dragItem?.id,
      pmolId: dragItem?.projectMoleculeId,
      projectSequenceCode: dragItem?.projectSequenceCode,
      contractingUnit: dragItem?.contractingUinit,
      executionDate: formatDateTimeToISOString(store.getState()?.mydPlan?.selectedDate)
    };
    Promise.all([
      dispatch(assignPmolToProject(data))
    ]).then(() => {
      reloadListPanes(dragItem);
      dispatch(saveProjectDayPlanningStateAttr('reloadBottomListPane', true));
    });
  };


  const assignTeamProject = (dragItem: any, dropItem: any) => {
    if (dropItem?.sequenceCode) {
      let data = {
        personId: dragItem?.id,
        cabPersonCompanyId: dragItem?.cabPersonCompanyId,
        projectSequenceCode: dropItem?.sequenceCode,
        sequenceId: dragItem?.sequenceId,
        contractingUnit: dropItem?.contractingUnitId,
        executionDate: formatDateTimeToISOString(store.getState()?.mydPlan?.selectedDate)
      };

      Promise.all([
        dispatch(assignTeamToProject(data))
      ]).then(() => {
        reloadListPanes(dragItem);
        dispatch(saveProjectDayPlanningStateAttr('reloadBottomListPane', true));
      });
    }
  };

  const assignTeamPmol = (dragItem: any, dropItem: any) => {
    if (dropItem?.event?.projectMoleculeId) {

      const currentTeamMembers = dropItem?.event?.team.map((item: any) => {
        return {
          personId: item?.id,
          cabPersonCompanyId: item?.cabPersonCompanyId,
        }
      });

      let newTeamMemberAsArrayObject = [
        {
          personId: dragItem?.id,
          cabPersonCompanyId: dragItem?.cabPersonCompanyId,
        }
      ];

      let data = {
        // personId: dragItem?.id, //
        // cabPersonCompanyId: dragItem?.cabPersonCompanyId, //
        sequenceId: dragItem?.sequenceId, //
        projectSequenceCode: dropItem?.event?.projectSequenceCode,
        contractingUnit: dropItem?.event?.contractingUinit,
        projectMoleculeId: dropItem?.event?.projectMoleculeId,
        pmolId: dropItem?.event?.id,
        executionDate: formatDateTimeToISOString(store.getState()?.mydPlan?.selectedDate),
        team: currentTeamMembers.concat(newTeamMemberAsArrayObject),
      };
      Promise.all([
        // dispatch(assignTeamToPmol(data))
        dispatch(addMultipleMembersToPmol(data))
      ]).then(() => {
        reloadListPanes(dragItem);
        dispatch(saveProjectDayPlanningStateAttr('reloadBottomListPane', true));
      });
    }
  };

  const assignPBSProject = (dragItem: any, dropItem: any) => {

    let data = {
      teamId: null,
      id: dragItem?.id,
      weekStartDate: formatDateTimeToISOString(store.getState()?.mydPlan?.selectedDate),
      weekEndDate: formatDateTimeToISOString(store.getState()?.mydPlan?.selectedDate),
      executionDate: formatDateTimeToISOString(store.getState()?.mydPlan?.selectedDate),
      projectSequenceCode: dragItem?.projectSequenceCode,
      contractingUnit: dropItem?.contractingUnitId,
      buId: store.getState()?.projectDPlan?.selectedBu,
    };

    // console.log("data", data);
    Promise.all([
      dispatch(assignPlanningPbs(data))
    ]).then(() => {
      reloadListPanes(dropItem);
      dispatch(saveMyDayPlanningStateAttr('reloadFilters', true));
      dispatch(saveProjectDayPlanningStateAttr('reloadBottomListPane', true));
    });
  };

  const assignProject = (dragItem: any, dropItem: any) => {

    let data = {
      teamId: null,
      executionDate: formatDateTimeToISOString(store.getState()?.mydPlan?.selectedDate),
      projectSequenceCode: dragItem?.projectSequenceCode,
      contractingUnit: selectedCu?.sequenceCode,
      weekStartDate: formatDateTimeToISOString(store.getState()?.mydPlan?.selectedDate),
      weekEndDate: formatDateTimeToISOString(store.getState()?.mydPlan?.selectedDate),
    };

    Promise.all([
      dispatch(assignPlanningProject(data))
    ]).then(() => {
      reloadListPanes(dropItem);
      dispatch(saveMyDayPlanningStateAttr('reloadFilters', true));
      dispatch(saveProjectDayPlanningStateAttr('reloadBottomListPane', true));
    });
  };

  const assignOrgTeamPmol = (dragItem: any, dropItem: any) => {
    if (dropItem?.event?.projectMoleculeId) {
      let data = {
        personId: dragItem?.cabPersonId,
        cabPersonCompanyId: dragItem?.cabPersonCompanyId,
        projectSequenceCode: dropItem?.event?.projectSequenceCode,
        contractingUnit: dropItem?.event?.contractingUinit,
        projectMoleculeId: dropItem?.event?.projectMoleculeId,
        pmolId: dropItem?.event?.id,
        executionDate: formatDateTimeToISOString(store.getState()?.mydPlan?.selectedDate)
      };
      Promise.all([
        dispatch(assignTeamToPmol(data))
      ]).then(() => {
        reloadListPanes(dragItem);
        dispatch(saveProjectDayPlanningStateAttr('reloadBottomListPane', true));
      });
    }
  };

  const assignVehicleProject = (dragItem: any, dropItem: any) => {
    if (dropItem?.sequenceCode) {
      let data = {
        cpcId: dragItem?.coperateProductCatalogId,
        resourceNumber: dragItem?.resourceNumber,
        cuId: dragItem?.cuId,
        projectSequenceCode: dropItem?.sequenceCode,
        contractingUnit: dropItem?.contractingUnitId,
        executionDate: formatDateTimeToISOString(store.getState()?.mydPlan?.selectedDate),
        isVehicle: true
      };

      Promise.all([
        dispatch(assignVehicleToProject(data))
      ]).then(() => {
        reloadListPanes();
        dispatch(saveProjectDayPlanningStateAttr('reloadBottomListPane', true));
      });
    }
  };

  const assignVehiclePmol = (dragItem: any, dropItem: any) => {
    if (dropItem?.event?.projectMoleculeId) {
      let data = {
        cpcId: dragItem?.coperateProductCatalogId,
        resourceNumber: dragItem?.resourceNumber,
        cuId: dragItem?.cuId,
        projectSequenceCode: dropItem?.event?.projectSequenceCode,
        contractingUnit: dropItem?.event?.contractingUinit,
        pmolId: dropItem?.event?.id,
        executionDate: formatDateTimeToISOString(store.getState()?.mydPlan?.selectedDate),
        isVehicle: true
      };
      Promise.all([
        dispatch(assignVehicleToPmol(data))
      ]).then(() => {
        reloadListPanes();
        dispatch(saveProjectDayPlanningStateAttr('reloadBottomListPane', true));
      });
    }
  };

  const assignToolProject = (dragItem: any, dropItem: any) => {
    if (dropItem?.sequenceCode) {
      let data = {
        cpcId: dragItem?.coperateProductCatalogId,
        resourceNumber: dragItem?.resourceNumber,
        cuId: dragItem?.cuId,
        projectSequenceCode: dropItem?.sequenceCode,
        contractingUnit: dropItem?.contractingUnitId,
        executionDate: formatDateTimeToISOString(store.getState()?.mydPlan?.selectedDate),
        isVehicle: false
      };

      Promise.all([
        dispatch(assignVehicleToProject(data))
      ]).then(() => {
        reloadListPanes();
        dispatch(saveProjectDayPlanningStateAttr('reloadBottomListPane', true));
      });
    }
  };

  const assignToolPmol = (dragItem: any, dropItem: any) => {
    if (dropItem?.event?.projectMoleculeId) {
      let data = {
        cpcId: dragItem?.coperateProductCatalogId,
        resourceNumber: dragItem?.resourceNumber,
        cuId: dragItem?.cuId,
        projectSequenceCode: dropItem?.event?.projectSequenceCode,
        contractingUnit: dropItem?.event?.contractingUinit,
        pmolId: dropItem?.event?.id,
        executionDate: formatDateTimeToISOString(store.getState()?.mydPlan?.selectedDate),
        isVehicle: false
      };
      Promise.all([
        dispatch(assignVehicleToPmol(data))
      ]).then(() => {
        reloadListPanes();
        dispatch(saveProjectDayPlanningStateAttr('reloadBottomListPane', true));
      });
    }
  };

  const onEventDrop = ({ event, start, end, resourceId }: any) => {
    if (event?.resourceId === resourceId) {
      const data = {
        id: event?.id,
        pmolId: event?.projectMoleculeId,
        executionStartTime: moment(start).format("HH:mm"),
        executionEndTime: moment(end).format("HH:mm"),
        projectSequenceCode: event?.projectSequenceCode,
        contractingUnit: event?.contractingUinit,
        executionDate: formatDateTimeToISOString(selectedDate)
      };

      Promise.all([
        updateEvent(event, start, end)
      ]).then(() => {
        dispatch(resizePmolOnProject(data))
        setTimeout(()=>{
          reloadListPanes();
          dispatch(saveProjectDayPlanningStateAttr('reloadBottomListPane', true));
        },1500)
      });
    } else {

    }
  };

  const updateEvent = (event: any, start: any, end: any) => {
    const idx = eventList.indexOf(event);
    const updatedEvent = { ...event, start, end };

    const nextEvents = [...eventList];
    nextEvents.splice(idx, 1, updatedEvent);

    setEventList(nextEvents)

    return event; //ignore this return
  };

  const resizeEvent = ({ event, start, end }: any) => {
    let data = {
      id: event?.id,
      pmolId: event?.projectMoleculeId,
      executionStartTime: moment(start).format("HH:mm"),
      executionEndTime: moment(end).format("HH:mm"),
      projectSequenceCode: event?.projectSequenceCode,
      contractingUnit: event?.contractingUinit,
      executionDate: formatDateTimeToISOString(selectedDate)
    };

    Promise.all([
      updateEvent(event, start, end)
    ]).then(() => {
      dispatch(resizePmolOnProject(data))
      setTimeout(()=>{
        reloadListPanes();
        dispatch(saveProjectDayPlanningStateAttr('reloadBottomListPane', true));
      },1500)
    });

  };

  const selectSlotEvent = ({start, end, resourceId, slots, action}: any) => {
    const planBoardType = 'project';
    let resourceSelector

    // TODO: Need to check this logic
    // if (resources) {
    //   resourceSelector = resources.find((item: any) => item.resourceId === resourceId);
    // } else {
    //   resourceSelector = {
    //     resourceId: 0,
    //     resourceTitle: userInfo?.userName,
    //     team: [{id: userInfo?.userId, name: userInfo?.userName}],
    //     teamId: null,
    //     teamTitle: null,
    //   }
    // }
    dispatch(saveDayPlanningStateAttr("myCalendarSlotData", {start, end, resourceId, slots, action, planBoardType}));
    dispatch(saveDayPlanningStateAttr("resourceSelectorData", resourceSelector));
  };

  const formatTimeForPmol = (date: string, time: any) => {
    const time24Hour = moment(time, 'hh.mm').format('HH:mm');

    let formatted = moment(date).format('YYYY/MM/DD');
    let dateonly = (formatted === 'Invalid date') ? '' : formatted.toString();
    if (dateonly && time24Hour) {
      let t = time24Hour ?? '';
      let dateTime = dateonly + ' ' + t;
      return moment(dateTime).toDate();
    } else {
      return moment.utc(date).local().toDate();
    }
  };

  const getPmolList = () => {
    if (pmolList && pmolList.length > 0) {
      return pmolList.map((pmol: any) => {
        return {
          ...pmol,
          resourceId: pmol?.projectSequenceCode,
          start: formatTimeForPmol(pmol?.executionDate, pmol?.executionStartTime),
          end: formatTimeForPmol(pmol?.executionDate, pmol?.executionEndTime),
        }
      })
    } else {
      return []
    }
  };

  const getProjectList = () => {
    let emptyColumn = [{ resourceId: 0, resourceTitle: `${t('newProject')}`, color: '#fafafa' }];
    if (projectList && projectList.length > 0) {
      // return emptyColumn.concat(projectList)
      return  projectList
    } else {
      return emptyColumn
    }
  };

  const resourceHeader = ({ label, index, resource }: any) => (
    <div
      id={'resource'+resource?.resourceId}
      key={JSON.stringify(label)}
      className="rbc-customeHeader"
      style={{
        width: '100%',
        height: '70px',
        backgroundColor: resource?.siteManager ? resource?.siteManager?.bgColor : resource?.color, //Background color project title
        whiteSpace: 'pre-wrap'
      }}>
      {resource?.resourceId != 0 && resource?.siteLeader && <TooltipHost
        content={resource?.siteLeader}
        id={resource?.resourceId}
        calloutProps={{
          gapSpace: 0,
          target: `#${'name' + (resource?.resourceId)}`,
        }}
      >
        {/*DISABLED THIS ON A REQUEST*/}
        {/*<div*/}
        {/*id={'name' + (resource?.resourceId)}*/}
        {/*className="personSquare"*/}
        {/*style={{*/}
        {/*  backgroundColor: resource?.siteManager?.bgColor ? resource?.siteManager?.bgColor : resource?.color,  //Background color site manager initial*/}
        {/*  border: '0.5px solid #ffffff',*/}
        {/*  boxShadow: '1px 1px #888888',*/}
        {/*  color: resource?.siteManager?.fontColor ? resource?.siteManager?.fontColor : '#000'  //Font color site manager initial*/}
        {/*}}>{getInitials(resource?.siteLeader)}*/}
        {/*</div>*/}
      </TooltipHost>}
      <TdDropBox.DropBox
        item={resource}
        day={selectedDate}
        type={resource?.resourceId === 0
          ? [ProjectPlanResourceType.PMOL, ProjectPlanResourceType.PBS, ProjectPlanResourceType.PROJECT]
          : [ProjectPlanResourceType.TEAM, ProjectPlanResourceType.VEHICLE, ProjectPlanResourceType.PMOL, ProjectPlanResourceType.TOOL, ProjectPlanResourceType.PBS, ProjectPlanResourceType.PROJECT]}
      >
        <div
          style={{
            width: '100%',
            minHeight: '70px',
            color: resource?.siteManager?.fontColor ? resource?.siteManager?.fontColor : '#000' //Font color project title
          }}>
          <Link
            style={{color:resource?.siteManager?.fontColor?resource?.siteManager?.fontColor:uPrinceTheme.palette.themePrimary}}
            to={'#'}
            onClick={() => {
              // const allProjects = store.getState()?.project?.allProjects;
              // const allProjectSequenceCodes = allProjects?.map((project: { sequenceCode: any; }) => project.sequenceCode);
              const contractingUnit = store.getState()?.projectDPlan?.selectedCu ? store.getState()?.projectDPlan?.selectedCu?.sequenceCode : userInfo?.contractingUnit?.sequnceCode;
              const selectedKey = `/CU/${contractingUnit}/project/${resource?.resourceId}/project-planning?from-plan-board=true`;

              // if (allProjectSequenceCodes.includes(resource?.resourceId)) {
                window.open(selectedKey);
              // }
            }}
          >
            {resource.resourceTitle}
          </Link>

          <div style={{display:'flex'}}>
            {resource?.resourceId !== 0 && resource?.labourCpc && resource?.labourCpc.length>0? <LabourTooltip team={resource?.labourCpc}/>:false}
            {/*{resource?.resourceId !== 0 && <LabourTooltip team={[{name:'Pradeep Madusanka'},{name:'Nimesh Madusanka'},{name:'Costha Madusanka'}]}/>}*/}
          </div>
        </div>
      </TdDropBox.DropBox>
    </div>
  );

  const EventWrapper = ({ event, children }: any) => {
    const newChildren = { ...children };
    const newChildrenProps = { ...newChildren.props };
    newChildrenProps.className = `${newChildrenProps.className}`;
    newChildren.props = { ...newChildrenProps };
    return <div className={`customEventWrapper  ${getPmolType(event)}`}>
      {newChildren}
    </div>;
  };


  const openPmol = (pmol: any) => {
    // let x = pmol?.mouseEvent.clientX;
    // let y = pmol?.mouseEvent.clientY;
    // let offset = {x,y};
    // setMouseOffset(offset);
    dispatch(saveStateAttr('contractingUinit', pmol?.event?.contractingUinit));
    dispatch(saveStateAttr('projectSequenceCode', pmol?.event?.projectSequenceCode));
    dispatch(saveProjectDayPlanningStateAttr('selectedPmol', pmol?.event))
  };

  const createPmol = (pmol: any, type: string) => {
    if (pmol?.projectMoleculeId) {
      let data = {
        ...pmol,
        pmolId: pmol?.projectMoleculeId,
        typeId: type
      };
      Promise.all([
        dispatch(clonePmolDayPlanning(data)),
      ]).then(() => {
        reloadListPanes();
        dispatch(saveProjectDayPlanningStateAttr('reloadBottomListPane', true));
      });


    }
  };

  const copyPmol = (pmol: any) => {
    dispatch(saveMyDayPlanningStateAttr('pmolCopyState', {
      event: pmol?.event,
      isCopyModalOpen: true
    }));
  };

  const customEvent = (event: any) => {
    const _overflowItems: ICommandBarItemProps[] = [
      { key: 'open', text: 'Open', onClick: (ev) => openPmol({...event,mouseEvent:ev}), iconProps: { iconName: 'MoveToFolder' } },
      { key: 'create', text: t('createTransportPMOL'), onClick: () => createPmol(event?.event, PMOL_ACTIVITY_TYPES.TRAVEL), iconProps: { iconName: 'Add' } },
      {key: 'copy', text: t('copy'), onClick: () => copyPmol(event), iconProps: {iconName: 'Copy'}},
    ];
    return <TdDropBox.DropBox
      item={event}
      day={selectedDate}
      type={[ProjectPlanResourceType.TEAM, ProjectPlanResourceType.VEHICLE, ProjectPlanResourceType.TOOL, ProjectPlanResourceType.ORG_TEAMS]}
      isPmol={true}
    >
        <EventBody
          pmol={event?.event}
          overflowItems={_overflowItems}
          handlePmolStatusChange={(data: any) => handlePmolStatusChange(data)}
          handleCompetencyModal={(resourceType: any) => {
            setIsCompetencyModalOpen((isOpen: boolean) => !isOpen);
            setResourceCategory(resourceType);
            setSelectedPmolState(event?.event ?? null);
          }}
        />
    </TdDropBox.DropBox>;
  };

  // const renderTeamMembers = (teams: any) => {
  //   if (teams && teams.length > 0) {
  //     return <div>
  //       <Separator />
  //       {
  //         teams.map((emp: any) => {
  //           const examplePersona: IPersonaProps = {
  //             imageUrl: '',
  //             text: emp.name,
  //             secondaryText: emp.role,
  //             styles: {
  //               root: {
  //                 color: '#10100f',
  //               },
  //               primaryText: {
  //                 color: '#10100f',
  //                 fontSize: 10,
  //                 '&:hover': {
  //                   color: '#10100f',
  //                 },
  //               },
  //               secondaryText: {
  //                 color: '#10100f',
  //                 fontSize: 9,
  //               },
  //               textContent: {
  //                 paddingBottom: 10
  //               },
  //             },
  //           };
  //           return <span style={{ paddingBottom: 10 }} key={emp.id}>
  //             <Persona
  //               {...examplePersona}
  //               size={PersonaSize.size40}
  //               presence={PersonaPresence.none}
  //               imageAlt={emp.name}
  //               coinSize={23}
  //             />
  //           </span>
  //         })
  //       }
  //     </div>
  //   } else {
  //     return <div> </div>;
  //   }
  // };

  // const handleNullValue = (value: any) => {
  //   return value ? value : 0
  // };

  // const renderVehicle = (vehicles: any, isTool: boolean) => {
  //   if (vehicles && vehicles.length > 0) {
  //     return <div>
  //       <Separator />
  //       {
  //         vehicles.map((vehicle: any) => {
  //           const exampleVehicle: IPersonaProps = {
  //             imageUrl: '',
  //             text: vehicle.title,
  //             styles: {
  //               root: {
  //                 color: '#10100f',
  //               },
  //               primaryText: {
  //                 color: '#10100f',
  //                 fontSize: 10,
  //               },
  //               textContent: {
  //                 paddingBottom: 10
  //               },
  //             },
  //           };
  //           return <span style={{ paddingBottom: 10, display: 'flex' }} key={useId()} id={useId('cpcItem')}>
  //             <Persona
  //               {...exampleVehicle}
  //               size={PersonaSize.size24}
  //               presence={PersonaPresence.none}
  //               imageAlt={vehicle.title}
  //               coinSize={23}
  //             />
  //             {isTool && <div style={{ padding: 6, fontSize: '10px', color: '#10100f', fontWeight: 400, whiteSpace: 'nowrap' }} >
  //               {`${handleNullValue(parseInt(vehicle.allocatedQuantity))}/${handleNullValue(parseInt(vehicle.requiredQuantity))}`}
  //             </div>}
  //           </span>
  //         })
  //       }
  //     </div>
  //   } else {
  //     return <div> </div>;
  //   }
  //
  // };

  const onSelectDateFromToolBar=(date:any)=>{
    dispatch(saveMyDayPlanningStateAttr('selectedDate',date))
  }

  const onSelectDate = React.useCallback((date: Date | null | undefined): void => {
    console.log({date});
    Promise.all([
      dispatch(saveMyDayPlanningStateAttr('selectedDate',date)), // Using SelectedDate from myDayPlanning(teamPlanBoard)
    ]).then(() => {
      // THIS LOGIC IS IMPLEMENTED IN PLANBOARD SHORTCUT PANE
      // if(date){
      //   dispatch(filterProjectDayPlanningProjectList({
      //     executionDate: formatDateTimeToISOString(date),
      //     contractingUnit: getSelectCuItem(false) ? [getSelectCuItem(false)] : [],
      //     buId: store.getState()?.projectDPlan?.selectedBu,
      //     cabPersonId: planboardPersonId ? planboardPersonId : null,
      //     pmolTypeId: ppbPmolTypeId ?? null,
      //     siteManagerId: ppbSiteManagerId ?? null,
      //     projectSequenceCode: ppbProjectSequenceCode && ppbProjectSequenceCode.value ? ppbProjectSequenceCode.value : null,
      //   }));
      //   dispatch(getProjectDayPlanningResourceList(getFilters(date,getSelectCuItem(false),store.getState()?.projectDPlan?.selectedBu, planboardPersonId)));
      // }
    });
  }, []);

  const tooltipAccessor = () => {
    return ""
  };

  const TimeGutterHeader = () => (
    <div style={{width:'100%',wordWrap:'break-word',fontSize:10,fontWeight:'bold'}}>
      <TdDropBox.DropBox
        item={{ resourceId: 0, resourceTitle: `${t('newProject')}`, color: '#fafafa' }}
        day={selectedDate}
        type={[ProjectPlanResourceType.PMOL, ProjectPlanResourceType.PBS, ProjectPlanResourceType.PROJECT]}
      >
        {t('newProject')}
      </TdDropBox.DropBox>
    </div>
  );


  const renderListPane=()=>{
    return <div ref={divRef}  style={{height : "100%", width : "100%",filter:showProgressBar?'blur(0px)':'blur(0px)'}} className={`plan-board-calender-with-borders`}>
    <DragAndDropCalendar
    defaultView={Views.DAY}
    events={eventList}
    date={stringDateToDateObject(selectedDate)}
    defaultDate={new Date()}
    onNavigate={(date) => { onSelectDate(date) }}
    localizer={localizer}
    onEventDrop={onEventDrop}
    onEventResize={resizeEvent}
    resources={getProjectList()}
    resourceIdAccessor={(projectList:any) => projectList.resourceId}
    resourceTitleAccessor={(projectList:any) => projectList.resourceTitle}
    onSelectSlot={selectSlotEvent}
    selectable
    showMultiDayTimes={true}
    views={{ day: true}}
    components={{
      resourceHeader:resourceHeader,
      eventWrapper:EventWrapper,
      event:customEvent,
      toolbar: (props)=>CustomToolbar({...props,showAllButton:true,selectedDate,onSelectDateFromToolBar}),
      timeGutterHeader: TimeGutterHeader,
    }}
    step={projectDpViewMode}
    dayLayoutAlgorithm="no-overlap"
    formats={{
      eventTimeRangeFormat: ({ start, end }, culture: any, localizer: any) =>
        localizer.format(start, 'HH:mm', culture) +
        ' - ' +
        localizer.format(end, 'HH:mm', culture),
      timeGutterFormat: (date, culture: any, localizer: any) =>
        localizer.format(date, 'HH:mm', culture),
    }}
    tooltipAccessor={tooltipAccessor}
    scrollToTime={scrollToTime} //For set focus 5:00 - project planning
  />
</div>
  }

  const onRemoveConfirm = () => {
    const payload = {
      projectSequenceCode: selectedWorker?.projectSequenceId,
      cu: selectedCu?.sequenceCode,
      teamRoleId: selectedWorker?.teamRoleId,
      cabPersonId: selectedWorker?.id,
      pmolId: selectedWorker?.pmolId,
    };

    Promise.all([
      dispatch(removeLabourPdp(payload))
    ]).then(() => {
      reloadListPanes();
      dispatch(saveProjectDayPlanningStateAttr('isConfirmDeleteModalOpen', true));
      dispatch(saveProjectDayPlanningStateAttr('reloadBottomListPane', true));
    });
  };

  const onRemoveCancel = () => {
    dispatch(saveProjectDayPlanningStateAttr('isConfirmDeleteModalOpen', true));
  };

  const handlePmolStatusChange = (data: any) => {
    Promise.all([
      dispatch(updatePlanBoardsPmolStatus(data))
    ]).then(() => {
      reloadListPanes();
      dispatch(saveProjectDayPlanningStateAttr('reloadBottomListPane', true));
    });
  };

  const handleCompetencyModalSave = (workers: any) => {
    switch (resourceCategory) {
      case "ppbWorkersId":
        handleWorkersModalSave(workers);
        break;
      case "ppbVehicleId":
        handleVehicleModalSave(workers);
        break;
      case "ppbToolId":
        handleToolsModalSave(workers);
        break;

      default:
        break;

    }
  };

  const handleWorkersModalSave = (workers: any): void => {
    if (workers) {
      const teamListArr: any[] = workers.map((item: any) => {
        return {
          personId: item?.id,
          cabPersonCompanyId: item?.cabPersonCompanyId
        }
      });

      const data = {
        projectSequenceCode: selectedPmolState?.projectSequenceCode,
        contractingUnit: selectedPmolState?.contractingUinit,
        projectMoleculeId: selectedPmolState?.projectMoleculeId,
        pmolId: selectedPmolState?.id,
        executionDate: formatDateTimeToISOString(selectedDate),
        team: teamListArr,
      }
      Promise.all([
        dispatch(addMultipleMembersToPmol(data))
      ]).then((res: any) => {
        if(res){
          reloadListPanes();
          setIsCompetencyModalOpen(false);
          setResourceCategory("");
          dispatch(saveProjectDayPlanningStateAttr('reloadBottomListPane', true));
        }
      });
    }
  };

  const handleVehicleModalSave = (workers: any): void => {
    console.log(workers);
  }

  const handleToolsModalSave = (workers: any): void => {
    console.log(workers);

  };

  return <div>
    <div className={'pdp'} style={{ width: '100%', height: '100%',paddingTop:5 }}>
      <CustomAlert
        showMsg={showMsg}
        msg={loadMsgRfq}
        msgType={'failed'}
        isMultiline={true}
        handleCustomAlert={() => {
          setShowMsg(false)
        }}
      />
      <div className={'project-day_planing'} style={{ width: '100%', height: horizontalReSizer - 80, overflow: 'overlay' }}> {renderListPane()}</div>
      <Panel
        isOpen={showFullScreen}
        type={PanelType.custom}
        customWidth="100vw"
        onDismiss={() => { dispatch(saveProjectDayPlanningStateAttr('showFullScreen', false)) }}
        isLightDismiss={true}
        headerText={t('projectPlanboard')}
        className="custom-detail-panel pdp"
        closeButtonAriaLabel={t('close')}
      >
        <div style={{ display: 'flex' }}>
          <CommandBarLeft onSelectDate={onSelectDate} disable={false} isTopPane={true} refresh={() => { reloadFullScreen() }} />
        </div>

        <div className={'project-day_planing'} style={{ width: '100%', height: screen.height - 250, overflow: 'overlay', paddingLeft: 5 }}> {renderListPane()}</div>
      </Panel>
    </div>

    <CIAWModal />
    <RFQModal />
    <PmolTitleModal/>
    <PrintModal />
    <NewProjectModal />
    <CreatePmolModal fCode={"project-day-planning"}/>

    <ConfirmationDialog
      hidden={isConfirmDeleteModalOpen}
      title={t('areyousure')}
      subText={`${t('Are you sure you want to remove ') + selectedWorker?.name}?`}
      onClickConfirmDialog={onRemoveConfirm}
      onClickCancelDialog={onRemoveCancel}
      cancelButtonText={t('notRemove')}
      confirmButtonText={t('remove')}
    />
    <PmolTimeChangeModel />

    <SelectCompetencyModal
      isModalOpen={isCompetencyModalOpen}
      handleClose={() => setIsCompetencyModalOpen(false)}
      handleSave={(workers: any) => handleCompetencyModalSave(workers)}
      pmol={selectedPmolState}
      resourceCategory={resourceCategory}
    />

    <CopyPmolModal/>
  </div>
};


export default TopListPane

import { useTranslation } from 'react-i18next';
import {
  ContextualMenu,
  DetailsList,
  FontWeights,
  getTheme,
  IBasePickerSuggestionsProps,
  IconButton,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link as UiLink,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  SelectionMode,
  Sticky,
  StickyPositionType,
  Text,
} from '@fluentui/react';
import React from 'react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import _ from 'lodash';
import { uPrinceTheme } from '../../../../../../theme';
import i18n from '../../../../../../i18n';

const theme = getTheme();

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const classNames = mergeStyleSets({
  wrapper: {
    //height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: {
    maxWidth: 400,
  },
  textContent: {
    padding: '15px 10px',
  },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: {
    width: '100%',
  },
});

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};

const bankItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 0,
    flexGrow: 1,
    width: 0,
  },
};

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };
const addIconWhite: IIconProps = {
  iconName: 'Save',
  styles: addWhiteIconButtonStyles,
};
const closeIconWhite: IIconProps = {
  iconName: 'Cancel',
  styles: addWhiteIconButtonStyles,
};
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    paddingTop: 20,
    overflowY: 'hidden',
    minWidth: '70vw',
    //minHeight: '32vh',
    minHeight: screen.width > 1280 ? '60vh' : '60vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'none',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const toggleStyles = { root: { marginBottom: '20px' } };
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};


const EmployeeShiftModal = (props: {
  isOpen: boolean;
  openModal: any
  title: string
  shiftDetails: any,
  selection: any,
}) => {
  const { t } = useTranslation();
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
  const titleId = useId('title');
  const theme = getTheme();
  const limitedSearchAdditionalProps: IBasePickerSuggestionsProps = {
    resultsMaximumNumber: 10,
    searchingText: 'Searching...',
  };


  const columns = [
    {
      key: 'column1',
      name: t('time'),
      fieldName: 'startDateTime',
      minWidth: 200,
      maxWidth: 0,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('activityType'),
      fieldName: 'activityType',
      minWidth: 180,
      maxWidth: 0,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('elapsedTime'),
      fieldName: 'elapedTime',
      minWidth: 180,
      maxWidth: 0,
      isRowHeader: true,
      isResizable: true,
      isPadded: true,
    },
  ];


  const onModalClose = () => {
    props.openModal();
  };

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          onModalClose();
        }}
        scrollableContentClassName="body"
        isBlocking={false}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>{props.title}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              onModalClose();
            }}
          />
        </div>
        <div className={contentStyles.subHeader}>

        </div>
        <div className={contentStyles.body}>
          <div className="proj-detail-content inner">
            <div className="ms-Grid-row">
              {props.shiftDetails ? <div
                className="proj-detail-block "
                id="1"
              >
                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <UiLink href="#shift-header" id="shift-header" style={{ marginBottom: 30 }}>
                      <Label>
                        {' ' + props.shiftDetails?.date}
                        {props.shiftDetails?.endDateTime ? ' - ' + props.shiftDetails?.endDateTime + ' - ' : ' - ... - '}
                        {' ' + !_.isEmpty(props.shiftDetails?.person) && !_.isEmpty(props.shiftDetails?.person?.person) ? props.shiftDetails?.person?.person?.fullName!! : ' - ' +
                          ' '}
                        {(props.shiftDetails?.person?.company) ? ' - ' + props.shiftDetails?.person?.company?.name : ''} </Label>
                    </UiLink>
                  </div>
                </div>

                <div
                  style={{ marginTop: 40 }}
                  className="proj-detail-block"
                  id="2"
                >
                  <div className="document-pane-card">
                    <div className={'card-header'}>
                      <UiLink href="#time-registration" id="time-registration">
                        <Label>  {t('timeRegistration')} </Label>
                      </UiLink>
                    </div>

                    <div className={'card-body'}>
                      <div style={{ marginTop: 5 }}>
                        <div>
                          <div style={{ width: '100%' }} className={`wrapper-holder ${classNames.wrapper}`}>
                            {/*<ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>*/}
                            <DetailsList
                              items={props.shiftDetails?.timeRegistrations ? props.shiftDetails?.timeRegistrations : []}
                              columns={columns}
                              setKey="set"
                              compact={false}
                              layoutMode={1}
                              selection={props.selection}
                              selectionMode={SelectionMode.none}
                              selectionPreservedOnEmptyClick={true}
                              checkboxVisibility={2}
                              onRenderDetailsHeader={(headerProps, defaultRender) => {
                                if (defaultRender !== undefined) {
                                  return (
                                    <Sticky
                                      stickyPosition={StickyPositionType.Header}
                                      isScrollSynced={true}
                                      stickyBackgroundColor="transparent"
                                    >
                                      <div>{defaultRender(headerProps)}</div>
                                    </Sticky>
                                  );
                                } else {
                                  return <span></span>;
                                }
                              }}
                            />
                            <div style={{ marginTop: 20, marginBottom: 30 }}>
                              <UiLink href="#/project" id="group-id-1">
                                <Label>
                                  {t('totalTime')}: {props.shiftDetails?.totalTime}
                                </Label>
                              </UiLink>
                            </div>
                            {/*</ScrollablePane>*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> : <div>
                <Text>{i18n.t('noShiftsAvailable')}</Text>
              </div>}

            </div>
          </div>
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={closeIconWhite}
            text={t('close')}
            style={{ marginTop: 20, marginBottom: 30 }}
            onClick={() => {
              onModalClose();
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default EmployeeShiftModal;

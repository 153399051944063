import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../../services/messageService';
import { WBS_TAXONOMY_LEVEL } from '../../../../types/wbs';
import i18n from 'i18next';
import { useSingleAndDoubleClick } from '../../../../hooks/useSingleAndDoubleClick';

const WBSCmdBarRight = ({ wbsTaxonomyLevel }: any) => {
  const { t } = useTranslation();
  let items: any[] = [];

  const getItems = () => {
    const productSave = {
      key: 'save',
      text: t('save'),
      iconProps: { iconName: 'Save' },
      onClick: () => {
        messageService.sendMessage({ saveWbsProduct: true });
      },
      disabled: false,
    };
    const productRenew = {
      key: 'renew',
      text: t('renew'),
      iconProps: { iconName: 'Add' },
      onClick: () => {
        messageService.sendMessage({ reNewWbsCreate: true });
      },
      disabled: true,
    };
    const productDelete = {
      key: 'delete',
      name: t('delete'),
      iconProps: { iconName: 'Delete' },
      onClick: () => {
        messageService.sendMessage({ deleteWbsCreate: true });
      },
      disabled: true,
    };

    const taskSave = {
      key: 'save',
      text: t('save'),
      iconProps: { iconName: 'Save' },
      onClick: () => {
        messageService.sendMessage({ saveWbsTask: true });
      },
      disabled: false,
    };

    const issueSave = {
      key: 'save',
      text: i18n.t('save'),
      iconProps: { iconName: 'Save' },
      onClick: useSingleAndDoubleClick(
        () => messageService.sendMessage({ saveWbsIssue: true }),
        () => messageService.sendMessage({ saveWbsIssue: true })),
      disabled: false,
    };

    if (wbsTaxonomyLevel === WBS_TAXONOMY_LEVEL.TASK) {
      items.push(taskSave);
    } else if (wbsTaxonomyLevel === WBS_TAXONOMY_LEVEL.PRODUCT) {
      items.push(productSave);
      // items.push(productRenew);
      items.push(productDelete);
    } else if (wbsTaxonomyLevel === WBS_TAXONOMY_LEVEL.ISSUE) {
      items.push(issueSave);
    } else {
    }

    return items;
  };

  return <div>
    <CommandBar
      styles={{ root: { height: 26 } }}
      items={getItems()}
      ariaLabel='Use left and right arrow keys to navigate between commands'
    />
  </div>;
};

export default WBSCmdBarRight;

import { useTranslation } from 'react-i18next';
import {
  getTheme,
  IIconProps,
  mergeStyleSets,
  FontWeights,
  Modal,
  IconButton,
  PrimaryButton,
  Stack,
  IStackStyles,
  IStackItemStyles,
  IStackTokens,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useId } from '@uifabric/react-hooks';
import _ from 'lodash';
import { uPrinceTheme } from '../../../../theme';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { formatDateTimeToISOString, getContractingUnit, getProject } from '../../../shared/util';
import { saveProjectDayPlanningStateAttr } from '../../../reducers/projectDayPlanningReducer';
import { Field, Form } from 'react-final-form';
import { DatePickerAdapter, TextFieldFF } from '../../../shared/fluentFinalForm';
import PeoplePicker from '../../../shared/fluentFinalForm/PeoplePicker';
import history from '../../../history';
import ProgressBar from '../../../shared/progressBar/progressBar';

const theme = getTheme();

const classNames = mergeStyleSets({
  wrapper: {
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: {
    maxWidth: 400,
  },
  textContent: {
    padding: '15px 10px',
  },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: {
    width: '100%',
  },
  editor: {
    width: '100%',
    height: '100%'
  },
});
const stackStyles: IStackStyles = {
  root: {
    padding: 0,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const addIconWhite: IIconProps = {
  iconName: 'Next',
  styles: addWhiteIconButtonStyles,
};
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '40vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};
class RiskValidationMessages {
  nameValidationMsg: string = '';
}

const CIAWModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const titleId = useId('title');
  const store = useStore();
  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);

  const { isCIAWgenerated, generatedCIAW } = useSelector(
    (state: any) => state.projectDPlan
  );

  const { contractingUnits } = useSelector(
    (state: any) => state.cuProject
  );

  const {
    selectedProject,
  } = useSelector(
    (state: any) => state.project
  );

  const {
    userInfo
  } = useSelector(
    (state: any) => state.uprince
  );

  const getCu = (item: any) => {
    let cu: any = contractingUnits.find(
      (element: any) => element.sequenceCode === item
    );

    if (cu) {
      return cu;
    } else {
      return null;
    }
  };

  const onSubmit = async (values: any) => {
    const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
    await sleep(300);
    setShowProgressBar(true);

    let cu: any = null;
    let bu: any = null;
    if (getProject()) {
      cu = getContractingUnit() ? getCu(getContractingUnit()) : store.getState()?.projectDPlan?.selectedCu ? store.getState()?.projectDPlan?.selectedCu : { ...userInfo?.contractingUnit, sequenceCode: userInfo?.contractingUnit?.sequnceCode };
      bu = selectedProject?.projectClassification?.projectClassificationBuisnessUnit ? selectedProject?.projectClassification?.projectClassificationBuisnessUnit : null;
    } else {
      cu = store.getState()?.projectDPlan?.selectedCu ? store.getState()?.projectDPlan?.selectedCu : { ...userInfo?.contractingUnit, sequenceCode: userInfo?.contractingUnit?.sequnceCode };
      bu = store.getState()?.projectDPlan?.selectedBu ? store.getState()?.projectDPlan?.selectedBu?.key : null;
    }

    setTimeout(() => {
      setShowProgressBar(false);
      history.push(`/CU/${cu?.sequenceCode}/ciaw`);
    }, 1000)
  };

  useEffect(() => {

    return () => {

    }
  }, []);

  const getInitialFormValues = (formData: any, formValue: any) => {
    if (formData) {
      return {
        date: formatDateTimeToISOString(formData?.date),
        requestedPerson: formData?.requestedBy,
        ciawRequested: formData?.ciawRequested,
        ciawApproved: formData?.ciawApproved,
      };
    }

    return formValue;
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        initialValues={getInitialFormValues(generatedCIAW, null)}
        mutators={{
          // setContractingUnit: (args, state, utils) => {
          //   const field = state.fields.contractingUnit;
          //   field.change(args[0]);
          // },
          // setBu: (args, state, utils) => {
          //   const field = state.fields.businessUnit;
          //   field.change(args[0]);
          // },
          // setProjectSequenceCode: (args, state, utils) => {
          //   const field = state.fields.projectSequenceCode;
          //   field.change(args[0]);
          // },
        }}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <div>
                <Modal
                  titleAriaId={titleId}
                  isOpen={isCIAWgenerated}
                  onDismiss={() => {
                    dispatch(saveProjectDayPlanningStateAttr('isCIAWgenerated', false));
                  }}
                  scrollableContentClassName="body"
                  isBlocking={false}
                  dragOptions={undefined}
                >
                  <div className={contentStyles.header}>
                    <span id={titleId}>{t('generateCIAW')}</span>
                    <IconButton
                      styles={cancelIconButtonStyles}
                      iconProps={cancelIcon}
                      ariaLabel="Close popup modal"
                      onClick={() => {
                        dispatch(saveProjectDayPlanningStateAttr('isCIAWgenerated', false));
                      }}
                    />
                  </div>
                  <ProgressBar show={showProgressBar} />

                  <div className={contentStyles.body}>
                    <div className="proj-detail-content inner">
                      <div className="ms-Grid-row">
                        <Stack
                          horizontal
                          wrap
                          styles={stackStyles}
                          tokens={stackTokens}
                        >
                          <Stack.Item grow={6} styles={stackItemStyles}>
                            <div className={`${classNames.fullWidth}`}>
                              <Field
                                name="date"
                                component={DatePickerAdapter}
                                lable={t('date')}
                                disabled={true}
                                parse={value => value && formatDateTimeToISOString(value)}
                                format={value => (value ? new Date(value) : null)}
                              />
                            </div>
                          </Stack.Item>
                          <Stack.Item grow={6} styles={stackItemStyles}>
                            <div className={`${classNames.fullWidth}`}>
                              <Field name="requestedPerson">
                                {({ input, meta }) => (
                                  <PeoplePicker
                                    //name="producer"
                                    label={t('requestedBy')}
                                    isDisabled={true}
                                    selectedItem={
                                      [
                                        {
                                          key: values?.requestedPerson?.requestedPerson,
                                          text: values?.requestedPerson?.requestedPersonName,
                                        }
                                      ]
                                    }
                                    onChange={(items: any) => {
                                      // form.resetFieldState('cabPersonId');
                                      // input.onChange('');
                                      return items;
                                    }}
                                    onPersonSelected={(item: any) => {
                                      input.onChange(item.cabPersonId);
                                      form.mutators.setCabPersonName(
                                        item.text,
                                      );
                                      return item;
                                    }}
                                  />
                                )}
                              </Field>
                            </div>
                          </Stack.Item>
                        </Stack>

                        <Stack
                          horizontal
                          wrap
                          styles={stackStyles}
                          tokens={stackTokens}
                        >
                          <Stack.Item grow={6} styles={stackItemStyles}>
                            <div className={`${classNames.fullWidth}`}>
                              <Field
                                name="ciawRequested"
                                component={TextFieldFF}
                                lable={t('noOfCiawRequested')}
                                disabled={true}
                              />
                            </div>
                          </Stack.Item>
                          <Stack.Item grow={6} styles={stackItemStyles}>
                            <div className={`${classNames.fullWidth}`}>
                              <Field
                                name="ciawApproved"
                                component={TextFieldFF}
                                lable={t('noOfCiawApproved')}
                                disabled={true}
                              />
                            </div>
                          </Stack.Item>
                        </Stack>
                      </div>
                    </div>

                    {/* <pre>{JSON.stringify(risk, 0, 2)}</pre> */}

                  </div>
                  <div className={contentStyles.footer}>
                    <PrimaryButton
                      iconProps={addIconWhite}
                      text={t('goToCIAW')}
                      disabled={false}
                      style={{ marginTop: 20, marginBottom: 30 }}
                      onClick={() => { form.submit() }}
                    />
                  </div>
                </Modal>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

export default CIAWModal;
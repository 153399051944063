import React from 'react';
import {Gantt} from './../../../shared/ganttv2/components/gantt/gantt';
import {walk} from '@nosferatu500/react-sortable-tree';
import {Task, ViewMode} from '../../../shared/ganttv2';
import {ViewSwitcher} from './view-switcher';
import _ from 'lodash';
import {Text} from '@fluentui/react';
import {useDispatch, useSelector} from 'react-redux';
import {formatVPurchaseTaxonomyBarData, saveVPStateAttr,} from '../../../reducers/visualPlanReducer';
import {useTranslation} from 'react-i18next';
import ProgressBar from '../../../shared/progressBar/progressBar';
import {ChartType} from '../../../shared/ganttv2/types/public-types';

import {ExpandProvider} from '../../../hooks/expandContext';
import {initTasks} from '../listPane/helper';
import {ViewModeContext} from '../../projectVisualPlan/productTaxonomyListPane/component';

// export const ViewModeContextz = React.createContext({ onViewModeChange: (viewMode:ViewMode) => {}, onViewListChange: (isCheck:boolean) => {}, isCheck: true });

// Init
const PurchaseAndDeliveryComponentCU = () => {
  const [view, setView] = React.useState<ViewMode>(ViewMode.QuaterYear);
  const [tasks, setTasks] = React.useState<Task[]>(initTasks());
  const [isChecked, setIsChecked] = React.useState(true);
  const [showGanttChart, setShowGanttChart] = React.useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();


  const reduxBarTasks: any[] = useSelector(
    (state: any) => state.vp.vpPurchaseCUTaxonomyBarList,
  );

  const listTasks: any[] = useSelector(
    (state: any) => state.vp.vpPurchaseCUTaxonomyList,
  );

  const interactBarTasks: any[] = useSelector(
    (state: any) => state.vp.vpPurchaseCUTaxonomyInteractBarList,
  );

  const isDataLoaded: boolean = useSelector(
    (state: any) => state.vp.purchaseCUTaxanomyDataLoaded
);


  let columnWidth = 60;
  if (view === ViewMode.Month) {
      columnWidth = 300;
  } else if (view === ViewMode.Week) {
      columnWidth = 250;
  } else if (view === ViewMode.QuaterYear) {
      columnWidth = 350;
  }

  const handleTaskChange = (task: any) => {

  };

  const handleTaskDelete = (task: Task) => {
    const conf = window.confirm('Are you sure about ' + task.name + ' ?');
    if (conf) {
      setTasks(tasks.filter((tsk: any) => tsk.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task: Task) => {
    // setTasks(tasks.map(tsk => (tsk.id === task.id ? task : t)));
    // console.log('On progress change Id:' + task.id);
  };

  const handleDblClick = (task: Task) => {
    // dispatch(saveVPStateAttr('showPODocumentPane', true));
    // dispatch(saveVPStateAttr('selectedPO', task.poId));
  };

  const handleSelect = (task: Task, isSelected: boolean) => {
    // props.handleBarTaskItemClick(task.poId);
  };

  const handleExpanderClick = (task: Task) => {
    // setTasks(tasks.map(tsk => (tsk.id === task.id ? task : t)));
  };

  const stringToArr = (string1: any) => {
    if (string1) {
      const x = string1.trim().split(',');
      x.shift();
      return x;
    }
    return [];
  };

  const getNodeKey = ({ treeIndex }: any) => treeIndex;

  const sumNodeValues = (treeData: any) => {
    let indexss = '';
    const callback = ({ node }: any) => {
        indexss += ',' + node.id;
      }
    ;

    walk({
      treeData,
      getNodeKey,
      callback,
      ignoreCollapsed: true,
    });

    return indexss;
  };

  const onVisibilityChanged = (data: any) => {
    const treeTotal1 = sumNodeValues(data);
    if (stringToArr(treeTotal1).length <= interactBarTasks.length) {
      // const newTasks = reduxBarTasks.filter(f => stringToArr(treeTotal1).some((item:any) => item === f.id));
      const newTasks = interactBarTasks.filter(item => stringToArr(treeTotal1).includes(item.id));
      dispatch(saveVPStateAttr('vpPurchaseCUTaxonomyBarList', newTasks));
    } else {
      const newTasks = reduxBarTasks.filter(item => stringToArr(treeTotal1).includes(item.id));
      dispatch(saveVPStateAttr('vpPurchaseCUTaxonomyInteractBarList', newTasks));
    }
  };

  const onViewModeChange = (viewMode:ViewMode) => {
    setView(viewMode);
  };

  const onvisibilityToggled = (data:any) => {

  }


  return (
    <div>
       <ViewModeContext.Provider value={ {
                onViewModeChange: (viewMode:ViewMode) => {onViewModeChange(viewMode);},
                onViewListChange: (isCheck:boolean) => setIsChecked(isCheck),
                isCheck: isChecked,
                onShowGanttChartChange: (showGanttChart: boolean) => {},
                showGanttChart: showGanttChart
            } }>
              <ExpandProvider>
      <ViewSwitcher

      />
      {!isDataLoaded &&
      <ProgressBar show={true}/>
      }
      {isDataLoaded && _.isEmpty(interactBarTasks) &&
      <Text style={{ marginLeft: 20 }}>{t('noData')}</Text>
      }
      {isDataLoaded && !_.isEmpty(interactBarTasks) &&
      <div id="poChart">
        <Gantt
                tasks={(listTasks && _.isArray(listTasks) && !_.isEmpty(listTasks) && isDataLoaded) && !_.isEmpty(interactBarTasks) ? interactBarTasks : tasks}
                // tasks={ tasks }
                listTasks={(listTasks && _.isArray(listTasks) && !_.isEmpty(listTasks) && isDataLoaded) ? listTasks : tasks}
                viewMode={view}
                onDateChange={handleTaskChange}
                onDelete={handleTaskDelete}
                onProgressChange={handleProgressChange}
                onDoubleClick={handleDblClick}
                onSelect={handleSelect}
                onExpanderClick={handleExpanderClick}
                listCellWidth={isChecked ? '155px' : ''}
                columnWidth={columnWidth}
                onVisibilityChanged={onVisibilityChanged}
                onVisibilityToggle={onvisibilityToggled}
                isInitialCollaps={true}
                canDrag={({ node, path, treeIndex }: any) => {
                  return false;
                } }
                searchQuery={''}
                searchMethod={() => {
                } }
                searchFocusOffset={0}
                searchFinishCallback={(matches) => {
                } }
                onlyExpandSearchedNodes={true}
                rtl={false}

                ganttHeight={560}

                canDrop={({ node, nextParent }: any) => {

                  return false;
                } }
                onMoveNode={(_path, _node) => {
                } }
                chartType={ChartType.POTAXONOMY}
                onTreeDataChange={(treeData: any) => {
                  dispatch(saveVPStateAttr('vpPurchaseCUTaxonomyBarList', [...formatVPurchaseTaxonomyBarData(treeData)[0]]));
                  dispatch(saveVPStateAttr('vpPurchaseCUTaxonomyInteractBarList', [...formatVPurchaseTaxonomyBarData(treeData)[1]]));
                } } selectedScreen={''}        />
      </div>}
      </ExpandProvider>
      </ViewModeContext.Provider>
    </div>
  );
};

export default PurchaseAndDeliveryComponentCU;

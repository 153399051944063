import React, {useEffect, useRef, useState} from 'react';
import {CommandBar} from '@fluentui/react/lib/CommandBar';
import {useTranslation} from 'react-i18next';
import {CorporateProductCatalogueListItem} from '../../../types/corporateProductCatalogue';
import {messageService} from '../../../services/messageService';
import {PBS_TYPE} from '../../../types/projectBreakdownStructure';
import {getListPaneData, savePbsStateAttr, uploadCBCExcelFile} from '../../../reducers/projectBreakdownReducer';
import {isCBC} from '../../../shared/util';
import * as XLSX from 'xlsx';
import _ from 'lodash';
import {getFlatDataFromTree} from '@nosferatu500/react-sortable-tree';
import {useDispatch, useSelector} from 'react-redux';
import ConfirmationDialog from '../../../shared/confirmationDialog/confirmationDialog';

const CommandBarLeft = (props: {
  createNew: (create: boolean, type?: PBS_TYPE) => void;
  exportExcel: () => void;
  handleRemoveCpc: () => void;
  handleCloneItem: () => void;
  isClone: boolean;
  selectedItemIds: [] | string[],
  cpcListItem: CorporateProductCatalogueListItem[],
  excelData: [],
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isdialogVisible, setIsDialogVisible]: any = useState(false);
  const [cbc, setCBC]: any = useState<any[]>([]);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const handleRegular = () => {
    messageService.sendMessage({ isNewPbs: true, pbsTypeFromRead: PBS_TYPE.REGULAR, pbsType: PBS_TYPE.REGULAR });
    props.createNew(true, PBS_TYPE.REGULAR);
  };
  const handleSubContract = () => {
    messageService.sendMessage({ isNewPbs: true, pbsTypeFromRead: PBS_TYPE.SUB, pbsType: PBS_TYPE.SUB });
    props.createNew(true, PBS_TYPE.SUB);
    dispatch(savePbsStateAttr('cbcExcelUploaded', false));
  };

  const { cbcExcelUploaded }: any = useSelector((state: any) => state.projectBreakdown);

  useEffect(() => {
    if (cbcExcelUploaded) {
      setTimeout(() => {
        dispatch(savePbsStateAttr('cbcExcelUploaded', false));
      },5000);
      dispatch(getListPaneData({
        title: null,
        pbsProductItemTypeId: null,
        pbsProductStatusId: null,
        pbsToleranceStateId: null,
        scope: null,
        taxonomy: null,
        sorter: {
          attribute: null,
          order: null,
        },
      }));
    }
  }, [cbcExcelUploaded]);

  const onUploadConfirm = () => {
    setIsDialogVisible(false);
    if (Array.isArray(cbc) && !_.isEmpty(cbc)) {
      dispatch(uploadCBCExcelFile(cbc));
    }
  };

  const onUploadCancel = () => {
    setIsDialogVisible(false);
  };

  const handleFile = (e: any) => {
    const files: any = e.target.files;
    const f = files[0];
    const reader = new FileReader();
    const result: any = {};
    setCBC([]);
    reader.onload = (d: any) => {
      const data = new Uint8Array(d.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      workbook.SheetNames.forEach((sheetName: any, index: number) => {
        const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
        if (roa.length) {
          result[index] = roa;
        }
      });
      // if (!workbook.SheetNames.includes('Samenv meetstaat')) {
      //   return;
      // }
      let lot = Array.isArray(result[0][3]) && result[0][3][0].includes('Lot') ? result[0][3][1] : null;
      const result2 = result['0']?.filter((item: any) => {
        return Array.isArray(item) && !_.isEmpty(item);
      });
      const result3 = result2.splice(0, 4);
      const result4 = result2?.filter((item: any) => {
        // remove last .
        if (item[0]  && !isNaN(item[0].split('.')?.[0])) { // && item[0].includes('.') this part has been removed
          item[0] = item[0].slice(item[0].length - 1) === '.' ? item[0].slice(0, -1) : item[0];
          return item;
        }

      });

      const uniqueObjects:any=[];
      result4.forEach((item:any)=>{
        let isDuplicate = uniqueObjects.find((obj:any) => {
          return obj[0] === item[0]
        });

        if(isDuplicate){
          if(item && item[8]){
            let objIndex = uniqueObjects.findIndex(((obj:any) => obj[0] == isDuplicate[0]));

            uniqueObjects[objIndex] = item
          }
        }else{
          uniqueObjects.push(item)
        }
      });

      const result5 = uniqueObjects?.map((item: any) => {
        // remove last .

        return item[0];

      });



      let children = uniqueObjects.reduce((r: any, s: any) => {
        s[0].split('.').reduce((q: any, _: any, i: any, a: any) => {
          let measurementCode=null;
          let title = s[1];
          let quantity = s[12] ? s[12] : null;
          //let measurementCode = s[7] ? s[7] : null;
          let key1= s[2] ? s[2] : null;
          let value1= s[3] ? s[3] : null;
          let key2= s[4] ? s[4] : null;
          let value2= s[5] ? s[5] : null;
          let key3= s[6] ? s[6] : null;
          let value3= s[7] ? s[7] : null;
          let key4= s[8] ? s[8] : null;
          let value4= s[9] ? s[9] : null;
          let key5= s[10] ? s[10] : null;
          let value5= s[11] ? s[11] : null;
          let mou = s[13] ? s[13] : null;
          let articleNo = a.slice(0, i + 1).join('.');
          let temp = (q.children = q.children || []).find((o: any) => o.articleNo === articleNo);
          if (!temp) q.children.push(temp = { id: articleNo, articleNo, title, quantity, mou, measurementCode, key1,value1,key2,value2,key3,value3,key4,value4,key5,value5  });
          return temp;
        }, r);
        return r;
      }, { children: [] }).children;

      const fData = getFlatDataFromTree({
        treeData: [...children],
        getNodeKey: (node: any) => node.id,
        ignoreCollapsed: false,
      });

      let flatData = fData.map((item: any) => {
        return {
          id: item?.node?.id,
          title: item?.node?.title,
          quantity: result5.includes(item?.node.id) ? item?.node.quantity : '0',
          parentId: item?.parentNode?.id ? item?.parentNode?.id : 0,
          contractor: lot,
          measurementCode: item?.node?.measurementCode,
          key1: item?.node?.key1,
          value1: item?.node?.value1,
          key2: item?.node?.key2,
          value2: item?.node?.value2,
          key3: item?.node?.key3,
          value3: item?.node?.value3,
          key4: item?.node?.key4,
          value4: item?.node?.value4,
          key5: item?.node?.key5,
          value5: item?.node?.value5,
          mou: item?.node?.mou,
        };
      });
      setIsDialogVisible(true);
      setCBC(flatData);
    };
    reader.readAsArrayBuffer(f);
  };

  const showFileDialog = () => {
    inputFileRef.current && inputFileRef.current.click();
  };

  // const getNodeKey = ({ treeIndex }: any) => treeIndex;
  // const removeUnavailableNodes = (treeData:any, itemsArray:any)=> {
  //     let total = 0;
  //     const callback = ({ node }:any) => itemsArray.includes(node.id)? node.quantity = node.quantity: node.quantity = 0 ;

  //     walk({
  //       treeData,
  //       getNodeKey,
  //       callback,
  //       ignoreCollapsed: false
  //     });

  //     return total;
  //   }

  const getItems = () => {

    const pbsItems = [
      {
        key: 'new',
        text: t('new'),
        iconProps: { iconName: 'Add' },
        split: true,
        subMenuProps: {
          items: [
            { key: 'regular', text: t('clientName'), iconProps: { iconName: 'Add' }, onClick: () => handleRegular() },
            {
              key: 'subContract',
              text: t('subcontractor'),
              iconProps: { iconName: 'Add' },
              onClick: () => handleSubContract(),
            },
          ],
        },
        disabled: (false),
        onClick: () => handleRegular(),
      },
      {
        key: 'Clone',
        name: t('clone'),
        disabled: (props.selectedItemIds.length != 1 && !props.isClone),
        iconProps: { iconName: 'Copy' },
        onClick: () => {
          props.handleCloneItem();
        },
      },
    ];

    const cbcItems = [
      {
        key: 'new',
        text: t('new'),
        iconProps: { iconName: 'Add' },
        disabled: false,
        onClick: () => handleSubContract(),
      },
      {
        key: 'Clone',
        name: t('clone'),
        disabled: (props.selectedItemIds.length != 1 && !props.isClone),
        iconProps: { iconName: 'Copy' },
        onClick: () => {
          props.handleCloneItem();
        },
      },
      {
        key: 'Upload',
        onClick: () => showFileDialog(),
        name: t('upload'),
        iconProps: { iconName: 'Upload' },
        disabled: false,
      },
    ];

    if (isCBC()) {
      return cbcItems;
    } else {
      return pbsItems;
    }

  };

  return (
    <div>
      <input
        style={{ display: 'none' }}
        ref={inputFileRef}
        type="file"
        accept=".xlsx" // ".png,.xlsx" multiple inputs
        multiple={false}
        onChange={(e: any) => {
          handleFile(e);
        }}
      />
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />

      <ConfirmationDialog
        hidden={!isdialogVisible}
        title={t('areyousure')}
        subText={cbc.length + ' ' + t('cbcExelUploadConfirm')}
        onClickConfirmDialog={onUploadConfirm}
        onClickCancelDialog={onUploadCancel}
        cancelButtonText={t('cancel')}
        confirmButtonText={t('yesUpload')}
      />
    </div>
  );
};

export default CommandBarLeft;

import React from 'react';
import { connect } from 'react-redux';
import { DocumentPaneComponent } from './component';
import { Selection } from '@fluentui/react/lib/DetailsList';
import UprinceLogger from '../../../shared/Logger/logger';
import { withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import _ from 'lodash';
import i18n from '../../../../i18n';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import history from '../../../history';
import { messageService } from '../../../services/messageService';
import { getProject } from '../../../shared/util';
import {
  Cost,
  ProgressStatement,
  ProgressStatementValidationMessage,
  PSListPaneFilter,
} from '../../../types/progressStatement';
import {
  clearFormData as clearPsData,
  createPSResource,
  readByPSId,
  saveFormData,
} from '../../../reducers/progressStatementReducer';

import { getProjectHeaderDetails } from '../../../reducers/projectReducer';
import {
  ActionButtonState,
  LangKeysValidationMessage,
  Language,
  LanguageKeys,
  Translation,
  UprinceLanguage,
} from '../../../types/language';
import {
  addLanguage,
  clearLanguageData,
  createTranslation,
  getKeyWord,
  updateLanguage,
  updateTranslation,
} from '../../../reducers/languageReducer';
import { LanguageKeyModel } from './modal/component';
import { getCountryList } from './../../../reducers/addressBookReducer';
//import * as countryImg from "../../organization/location/country.json";
import countries from '../../organization/location/country.json';


export interface State {
  formData: any;
  isDirty: boolean;
  redirect: boolean;
  loading: boolean;
  filter: PSListPaneFilter;
  isEdit: boolean;
  validationMessages: ProgressStatementValidationMessage;
  isCostModalOpen: boolean;
  showPrint: boolean;
  projectCostData: Cost[];
  langKeyActionButtonState: ActionButtonState;
  isOpenLanguageKeyModel: boolean;
  langKeys: LanguageKeys;
  langKeysIsEdit: boolean;
  nickNameValidationMessages: LangKeysValidationMessage;
  keyData: any;
  langSelectionDetails: Translation;
  editTranslation: Translation;
  countries: { key: string; text: string }[];
  lang: Language;
  countryCode: string

}

interface Props extends WithTranslation {
  formData: any;
  psDropDowns: any;
  projectHeader: [] | null;
  getProjectHeaderDetails: (id: string) => void;
  clearPsData: () => void;
  showProgressBar: boolean;
  autoSave: boolean;
  isEdit: boolean;
  projectId: string | null;
  isNew: boolean;
  uid: string | null;
  saveFormData: (formData: ProgressStatement) => any;
  readByPSId: any;
  createPSResource: (data: any) => void;
  isExpand: boolean;
  getKeyWord: (id: any) => void;
  keyData: any;
  createTranslation: any;
  updateTranslation: any;
  getCountryList?: any;
  countries: { key: string; text: string }[] | [];
  updateLanguage: (lang: Language) => void;
  addLanguage: (lang: Language) => void;
  clearLanguageData: () => void;
}


class DocumentPane extends React.Component<Props, State> {
  private _langSelection: Selection;
  subscription: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      isDirty: false,
      isEdit: false,
      redirect: false,
      loading: false,
      formData: null,
      filter: new PSListPaneFilter(),
      validationMessages: {
        nameErrorMsg: '',
        typeErrorMsg: '',
        borErrorMsg: '',
        statusErrorMsg: '',
        ledgerValidationMsg: '',
      },
      isCostModalOpen: false,
      showPrint: false,
      projectCostData: [],
      langKeyActionButtonState: { add: false, edit: true, remove: true },
      isOpenLanguageKeyModel: false,
      langKeys: {
        id: null,
        key: null,
        value: null,
        languageId: null,
        language: {},
      },
      langKeysIsEdit: false,
      nickNameValidationMessages: {
        keyErrorMsg: '',
        valueErrorMsg: '',
      },
      keyData: null,
      langSelectionDetails: new Translation(),
      countries: [],
      lang: {
        id: null,
        country: null,
        lang: null,
        code: null,
      },
      editTranslation: new Translation(),
      countryCode: '',
    };

    this._langSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log('_langSelection', this._getLangSelectionDetails());
        this.setState({
          langSelectionDetails: this._getLangSelectionDetails(),
          langKeyActionButtonState: this._getLangActionButtonState(),
          editTranslation: this._translationEditDetails(),
          // risk: this._riskEditDetails(),
          // riskDeleteItems: this._riskDeleteDetails(),
          //cpcVendorDeleteUnSavedItems: this._vendorDeleteUnSavedDetails(),
        }, () => {
        });
      },
    });


  }

  // static getDerivedStateFromProps(
  //   nextProps: Props,
  //   prevState: {
  //     countries: any;

  //   }
  // ) {
  //   return {
  //     countries: nextProps.countries,
  //   }
  // }

  private _translationEditDetails() {
    const selectionCount = this._langSelection.getSelectedCount();
    let editTranslation = this.state.langSelectionDetails;

    if (selectionCount == 1) {
      this.setState({
        isEdit: true,
      });
      UprinceLogger.log(
        '_internetContactSelection',
        this._langSelection.getSelection(),
      );
      editTranslation = this._langSelection.getSelection()[0] as Translation;

    } else {
      this.setState({
        isEdit: false,
      });
    }
    return editTranslation;
  }

  private _getLangSelectionDetails(): any {
    return this._langSelection.getSelection();
  }

  private _getLangActionButtonState(): ActionButtonState {
    const selectionCount = this._langSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    UprinceLogger.log('_vendorSelection', disabled);
    return disabled;
  }


  componentDidUpdate(prevProps: Props, prevState: State) {

    if (
      prevProps.isNew != this.props.isNew ||
      prevProps.uid != this.props.uid
    ) {
      if (this.props.isNew) {
        let formData = this.state.formData;
        formData.id = this.props.uid;
        this.setState({ formData });
      }
    } else {
    }

    if (prevProps.formData != this.props.formData) {
      this.countryImageFilter(this.props.formData.country);
      this.setState(
        {
          lang: {
            id: this.props.formData.id,
            country: this.props.formData.country,
            lang: this.props.formData.lang,
            code: this.props.formData.code,
          },
        }, () => {
        },
      );

      if (!this.state.isDirty) {
        if (!_.isEmpty(this.props.formData)) {
          let langId = this.props.formData.id;
          //let formData = this.props.formData;
          this.props.getKeyWord(langId);
          this.setState(
            {

              formData: this.props.formData,
              //projectCostData:this.props.formData.resources
              //position:{lat:0.0,lon:0.0}, location:null
            },
          );

        }
      } else {
        if (this.props.isNew && _.isEmpty(this.props.formData)) {
          let formData = this.state.formData;
          this.setState({
            formData: formData,
          });
        }
      }
      this.resetValidation();

    }

    if (prevState.formData !== this.state.formData) {
      if (!_.isEmpty(this.state.formData) && this.state.formData.id === null) {
        let formData = this.state.formData;
        formData.id = this.props.uid;
        //this.props.readMaterialsData(this.props.uid!!);
        this.setState(
          {
            formData,
          },
          //this.getPsData
        );
      }
    }
  }

  reloadTranslationKeywords = () => {
    if (!_.isEmpty(this.props.formData)) {
      let langId = this.props.formData.id;
      this.props.getKeyWord(langId);
    }
  };

  componentDidMount() {
    this.props.getCountryList();
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewPs) {
          this.resetValidation();
          this.props.clearPsData();
          this.props.clearLanguageData();
        }
        if (data.data.savePsFormData) {
          this.saveFormData();
        }
        if (data.data.psFilter) {
          this.setState({
            filter: data.data.psFilter,
          });
        }
        if (data.data.pdfGenerate) {
          this.setState({
            showPrint: true,
          });
        }

      }

    });
    if (getProject()) {
      let projectId = getProject();
      if (projectId) {
        this.props.getProjectHeaderDetails(projectId);
      }
    }
    // if (this.props.formData.progressStatementId) {
    //   this.getPsData();
    // }
    this.props.getKeyWord('');
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }


  // getPsData = () => {
  //   if (this.props.formData.progressStatementId) {
  //     messageService.sendMessage({isLoadingPSData:true})
  //     Promise.all([
  //       //api call

  //     ]).then(() => {
  //       messageService.sendMessage({isLoadingPSData:false})
  //     })


  //   }
  // };

  countryImageFilter = (country: string) => {
    const countryCode: any = countries.filter(img => img.name === country);

    if (_.isArray(countryCode) && !_.isEmpty(countryCode)) {
      this.setState({ countryCode: countryCode[0].code }, () => {
      });
    } else {
      this.setState({ countryCode: '' }, () => {
      });
    }

  };

  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { psFilter: this.state.filter },
    });
  };

  openCostModal = () => {
    this.setState({ isCostModalOpen: !this.state.isCostModalOpen });
  };

  render() {
    const { t } = this.props;

    return (
      <div style={{ width: '100%' }}>
        <RouteLeavingGuard
          when={this.state.isDirty}
          navigate={(path) => this.handleDiscardChangesNavigate(path)}
          shouldBlockNavigation={(location) => {
            return true;
          }}
          handleConfirmation={(isConfirm: boolean) => {
            this.setState({
              isDirty: !isConfirm,
            });
          }}
        />
        <DocumentPaneComponent
          dropDownOptions={{
            types: this.props.psDropDowns && this.props.psDropDowns.type ? this.props.psDropDowns.type : [],
            status: this.props.psDropDowns && this.props.psDropDowns.status ? this.props.psDropDowns.status : [],
            customer: this.props.psDropDowns && this.props.psDropDowns.customer ? this.props.psDropDowns.customer : null,
            projectCompletionDate: this.props.psDropDowns && this.props.psDropDowns.projectCompletionDate ? this.props.psDropDowns.projectCompletionDate : null,
          }}
          formData={this.state.formData}
          projectHeader={this.props.projectHeader}
          handleFormChange={(
            progressStatement: UprinceLanguage,
            save: boolean,
          ) => {
            this.handleFormChange(progressStatement, save);
          }}
          saveFormData={() => this.saveFormData()}
          isEdit={this.props.isEdit}
          validationMessages={this.state.validationMessages}
          isLoading={this.props.showProgressBar}
          projectCostData={this.state.projectCostData}
          openCostModal={this.openCostModal}
          createPSResource={(data: any) => this.props.createPSResource({
            psId: this.state.formData.id,
            resources: data.data,
            grandTotal: data.total,
          })
          }
          isExpand={this.props.isExpand}
          openLanguageKeyModel={() => {
            this.openLanguageKeyModel();
          }}
          isOpenLanguageKeyModel={this.state.isOpenLanguageKeyModel}
          handleNickNameDelete={() => {
          }}
          editNickName={(id: string) => {
          }}
          nickNameSelection={''}
          nickNameSelectionDetails={''}
          langKeyActionButtonState={this.state.langKeyActionButtonState}
          keyData={this.props.keyData}
          langSelection={this._langSelection}
          createTranslation={(translation: Translation) => this.props.createTranslation(translation)}
          countries={this.state.countries}
          updateLanguage={(lang: Language) => this.props.updateLanguage(lang)}
          lang={this.state.lang}
          onInputChange={(field: string, value: string) => this.onInputChange(field, value)}
          countryCode={this.state.countryCode}
        />

        <LanguageKeyModel
          isOpen={this.state.isOpenLanguageKeyModel}
          isEdit={this.state.isEdit}
          openLanguageKeyModel={this.openLanguageKeyModel}
          handleNickNameValidation={(
            nickNameValidationMessage: LangKeysValidationMessage,
          ) => {
            //this.handleNickNameValidation(nickNameValidationMessage);
          }}

          nickNameValidationMessages={this.state.nickNameValidationMessages}
          createTranslation={(translation: Translation) => this.props.createTranslation(translation)}
          languageId={this.props.formData?.id!!}
          reloadTranslationKeywords={this.reloadTranslationKeywords}
          translation={this.state.editTranslation}
          updateTranslation={(translation: Translation) => this.props.updateTranslation(translation)}
        />


      </div>
    );
  }

  private onInputChange = (field: string, value: string) => {
    this.setState((prevState) => ({
      lang: {
        ...prevState.lang,
        [field]: value,

      },

    }));
  };

  // private saveLanguageKeys = (langKeys:LanguageKeys,type: number) => {
  //   if (this.langKeyValidate()) {
  //     let productCatalogue = this.state.productCatalogue;
  //     let cpcResourceNickname = this.state.productCatalogue.cpcResourceNickname;
  //     let updatedItem = [this.state.cpcNickName];
  //     let cpcNickName = this.state.cpcNickName;
  //     if (productCatalogue.cpcResourceNickname != null) {
  //       if (cpcResourceNickname && this.state.cpcNickNameIsEdit) {
  //         //cpcResourceNickname = cpcResourceNickname.filter((item) => item.id !== this.state.cpcNickName.id);
  //         //cpcResourceNickname.push(this.state.cpcNickName);
  //         if (
  //           productCatalogue.status === 1 &&
  //           cpcNickName.uid &&
  //           cpcNickName.id === null
  //         ) {
  //           cpcResourceNickname = cpcResourceNickname.map(
  //             (obj) => updatedItem.find((o) => o.uid === obj.uid) || obj
  //           );
  //         } else {
  //           cpcResourceNickname = cpcResourceNickname.map(
  //             (obj) => updatedItem.find((o) => o.id === obj.id) || obj
  //           );
  //         }

  //         productCatalogue.cpcResourceNickname = cpcResourceNickname;

  //         this.setState(
  //           {
  //             productCatalogue: productCatalogue,
  //             isOpenNickNameModel: false,
  //           },
  //           this.autoSaveProductCatalogue
  //         );
  //       } else {
  //         if (productCatalogue.status === 1 && !cpcNickName.id) {
  //           cpcNickName.uid = Date.now().toString();
  //         }
  //         productCatalogue.cpcResourceNickname.push(this.state.cpcNickName);
  //         this.setState(
  //           {
  //             productCatalogue: productCatalogue,
  //             isOpenNickNameModel: type !== 1,
  //             cpcNickName: {
  //               id: null,
  //               uid: null,
  //               language: null,
  //               nickName: null,
  //               localeCode: null,
  //             },
  //           },
  //           this.autoSaveProductCatalogue
  //         );
  //       }
  //       if (productCatalogue.status === 1) {
  //         this.setState({
  //           isDirty: true,
  //         });
  //       }
  //     }
  //   }
  // };


  private langKeyValidate = (): boolean => {
    let nickNamevalidationMessages = this.state.nickNameValidationMessages;
    let key = this.state.langKeys.key;
    let value = this.state.langKeys.value;
    let isValid = true;
    if (_.isNil(key) || _.isEmpty(key)) {
      nickNamevalidationMessages.keyErrorMsg = i18n.t('key Required');
      isValid = false;
    } else {
      nickNamevalidationMessages.keyErrorMsg = '';
    }
    if (_.isNil(value) || _.isEmpty(value)) {
      nickNamevalidationMessages.valueErrorMsg = i18n.t(
        'Value Required',
      );
      isValid = false;
    } else {
      nickNamevalidationMessages.valueErrorMsg = '';
    }

    this.setState({ nickNameValidationMessages: nickNamevalidationMessages });
    return isValid;
  };

  private openLanguageKeyModel = (): void => {
    if (this.state.langKeysIsEdit) {
      this.setState({
        isOpenLanguageKeyModel: !this.state.isOpenLanguageKeyModel,
        // cpcNickName: this._nickNameEditDetails(),
      });
    } else {
      this.setState({
        isOpenLanguageKeyModel: !this.state.isOpenLanguageKeyModel,
        langKeys: {
          id: null,
          key: null,
          value: null,
          languageId: null,
          language: {},

        },
      });
    }
  };

  onProjectCostSelected = (list: Cost[]) => {
    // let costData = _.concat(this.state.projectCostData, list);
    // costData = [...new Set(costData)];
    // this.setState({ isCostModalOpen: false, projectCostData: list });
  };

  private handleFormChange = (formData: UprinceLanguage, save: boolean) => {
    this.setState({
      isDirty: true,
    });
    this.setState({
      formData: formData,
    });
  };

  private saveFormData = () => {
    if (this.state.lang.id) {
      this.props.updateLanguage(this.state.lang);
      this.setState({
        isDirty: false,


      });
    } else {
      let lang = this.state.lang;
      lang.id = 0;
      this.props.addLanguage(lang);
    }
    messageService.sendMessage({
      newListItem: true,
    });
  };


  // private validate = (): boolean => {
  //   let validationMessages = this.state.validationMessages;
  //   let name = this.state.formData.name;
  //   let type = this.state.formData.projectTypeId;
  //   let status = this.state.formData.projectStatusId;

  //   let isValid = true;
  //   if (_.isNil(name) || _.isEmpty(name)) {
  //     validationMessages.nameErrorMsg = i18n.t('nameRequired');
  //     isValid = false;
  //   } else {
  //     validationMessages.nameErrorMsg = '';
  //   }
  //   if (_.isNil(type) || _.isEmpty(type)) {
  //     validationMessages.typeErrorMsg = 'Type Required';
  //     isValid = false;
  //   } else {
  //     validationMessages.typeErrorMsg = '';
  //   }
  //   if (_.isNil(status) || _.isEmpty(status)) {
  //     validationMessages.statusErrorMsg = 'Status Required';
  //     isValid = false;
  //   } else {
  //     validationMessages.statusErrorMsg = '';
  //   }

  //   this.setState({ validationMessages: validationMessages });
  //   return isValid;
  // };

  private clearPsData = () => {
    // let formData:Translation = {
    //   //id: this.props.uid ? this.props.uid : null,
    //   // progressStatementId:null,
    //   // title:null,
    //   // headerTitle: null,
    //   // name:null,
    //   // projectTypeId:null,
    //   // projectType:null,
    //   // projectStatusId:null,
    //   // projectStatus:null,
    //   // projectCostId:null,
    //   // projectCompletionDate:null,
    //   // workPeriodFrom:null,
    //   // workPeriodTo:null,
    //   // purchaseOrderNumber:null,
    //   // resources:[],
    //   // historyLog:null,
    //   // isSaved:false,
    //   // date:null,
    //   // isProjectFinished:false,
    // };
    this.setState({
      //formData,
    });
  };

  private resetValidation = () => {
    let validationMessages = this.state.validationMessages;
    validationMessages.typeErrorMsg = '';
    validationMessages.statusErrorMsg = '';
    validationMessages.nameErrorMsg = '';

    this.setState({
      validationMessages: validationMessages,
    });
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    psDropDowns: state.ps.psDropDowns,
    isEdit: state.ps.isEdit,
    isNew: state.ps.isNew,
    uid: state.ps.uid,
    projectHeader: state.project.projectHeader,
    showProgressBar: state.ps.showProgressBar,
    keyData: state.lang.keyData,
    countries: state.addressBook.countries,
    formData: state.lang.formData,

  };
};

const mapDispatchToProps = {
  saveFormData,
  readByPSId,
  clearPsData,
  getProjectHeaderDetails,
  createPSResource,
  getKeyWord,
  createTranslation,
  updateTranslation,
  getCountryList,
  updateLanguage,
  addLanguage,
  clearLanguageData,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DocumentPane)),
);

import {
  ActionButton,
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  getTheme,
  IColumn,
  IDetailsFooterProps,
  IDetailsListProps,
  IDetailsRowStyles,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Stack,
  Sticky,
  StickyPositionType,
  TextField,
} from '@fluentui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../../theme';
import { ActionButtonState } from '../../../../types/corporateProductCatalogue';

const theme = getTheme();
const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

export const LangKeysGrid = (props: {
  langList: any | null;
  openLanguageKeyModel: () => void;
  handleNickNameDelete: () => void;
  nickNameSelection: any;
  nickNameSelectionDetails: {};
  langKeyActionButtonState: ActionButtonState;
  dropdownKeysList: any;
}) => {
  const { t } = useTranslation();
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const removeIcon: IIconProps = {
    iconName: 'Delete',
    styles: iconButtonStyles,
  };
  const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };
  const editIcon: IIconProps = { iconName: 'Edit', styles: iconButtonStyles };
  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
  });

  const stackStyles: IStackStyles = { root: { padding: 0 } };
  const stackItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 40,
      alignItems: 'flex-start',
    },
  };

  const stackEditbarItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 45,
      alignItems: 'flex-end',
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 15,
    padding: 10,
  };
  const columns = [
    {
      key: 'column1',
      name: t('key'),
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('value'),
      fieldName: 'label',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    // { key: 'column3', name: '', fieldName: 'id', minWidth: 100, maxWidth: 200, isResizable: true,
    //   // onRender: (item: NickNameList) => {
    //   //   return <span><Icon className={classNames.actionIcon} iconName="Edit" onClick={() => {
    //   //     props.editNickName(item.id);
    //   //   }}
    //   //   /><Icon iconName="Delete"/></span>
    //   // },
    // },
  ];
  // const scrollablePaneStyles: Partial<IScrollablePaneStyles> = {
  //   root: classNames.pane,
  // };

  const _onRenderRow: IDetailsListProps['onRenderRow'] = (row) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (row) {
      return (
        <span
          style={{ textDecoration: 'none' }}
          onClick={() => {
            //props.handleListPaneItemClick(row.item.code, row.item.id);
          }}
        >
          <DetailsRow {...row} styles={customStyles}/>
        </span>
      );
    }
    return null;
  };

  const _onRenderDetailsFooter = (
    detailsFooterProps: IDetailsFooterProps | undefined,
  ) => {
    return (
      <DetailsRow
        className="footer-as-filter"
        {...detailsFooterProps}
        columns={detailsFooterProps?.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps?.groupNestingDepth}
        selection={detailsFooterProps?.selection}
        onRenderItemColumn={_renderFilterItemColumn}
        // onRenderCheck={_onRenderCheckForFooterRow}
      />
    );
  };

  const _renderFilterItemColumn = (
    item?: any,
    index?: number,
    column?: IColumn,
  ) => {
    switch (column?.key) {
      case 'column1': {
        return (
          <div>
            <TextField
              value={searchKey}
              onChange={(event, value) => {
                if (value) {
                  setSearchKey(value);
                } else {
                  setSearchKey('');
                }
              }}
            />
          </div>
        );
        break;
      }
      //   case 'column2': {
      //     return (
      //       <div>
      //         <TextField
      //           value={''}
      //           onChange={(event, value) => {
      //             if (value) {
      //               setSearchValue(value)
      //             } else {
      //               setSearchValue('')
      //             }
      //           }}
      //         />
      //       </div>
      //     );
      //     break;
      //   }

      default: {
        break;
      }
    }
  };

  const getKeyValues = () => {
    let items = props.dropdownKeysList ? props.dropdownKeysList : [];
    if (searchKey !== '') {
      items = items.filter((item: any) =>
        item?.name.toUpperCase().includes(searchKey.toUpperCase()),
      );
    }
    return items;
  };

  const _onItemInvoked = (item: any): void => {
    //alert(`Item invoked: ${item.value}`);
    props.openLanguageKeyModel();
  };

  return (
    <div style={{ padding: 12 }}>
      <div className="ms-Grid-row marginTop">
        <div className="sub-header-title">
          <Stack
            horizontal
            horizontalAlign="space-between"
            styles={stackStyles}
            tokens={stackTokens}
          >
            <Stack.Item styles={stackItemStyles}>
              <Label style={{ marginTop: 10 }}>{t('translation')}</Label>
            </Stack.Item>

            <Stack.Item styles={stackEditbarItemStyles}>
              <ActionButton
                className={classNames.actionButton}
                disabled={props.langKeyActionButtonState.add}
                iconProps={addIcon}
                allowDisabledFocus
                onClick={() => {
                  props.openLanguageKeyModel();
                }}
              >
                <Label className={classNames.actionButton}>{t('add')} </Label>
              </ActionButton>
              <ActionButton
                iconProps={editIcon}
                disabled={props.langKeyActionButtonState.edit}
                allowDisabledFocus
                onClick={() => {
                  props.openLanguageKeyModel();
                }}
              >
                <Label className={classNames.actionButton}> {t('edit')}</Label>
              </ActionButton>
              <ActionButton
                iconProps={removeIcon}
                disabled={props.langKeyActionButtonState.remove}
                allowDisabledFocus
                onClick={() => {
                  props.handleNickNameDelete();
                }}
              >
                <Label className={classNames.actionButton}>
                  {' '}
                  {t('remove')}
                </Label>
              </ActionButton>
            </Stack.Item>
          </Stack>
        </div>
      </div>

      <div className="ms-Grid-row marginTop">
        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
          <div className={classNames.wrapper}>
            {/* <ScrollablePane>*/}
            <DetailsList
              items={getKeyValues()}
              // items={props.dropdownKeysList}
              // items={[{'key':"val",'value':"vale"},{'key':"ffff",'value':"hhh"}]}
              columns={columns}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              selectionPreservedOnEmptyClick={true}
              checkboxVisibility={1}
              onRenderRow={_onRenderRow}
              onRenderDetailsFooter={_onRenderDetailsFooter}
              selection={props.nickNameSelection}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
              onItemInvoked={_onItemInvoked}
              onRenderDetailsHeader={(headerProps, defaultRender) => {
                if (defaultRender !== undefined) {
                  return (
                    <Sticky
                      stickyPosition={StickyPositionType.Header}
                      isScrollSynced={true}
                      stickyBackgroundColor="transparent"
                    >
                      <div>{defaultRender(headerProps)}</div>
                    </Sticky>
                  );
                }
                return <span></span>;
              }}
            />
            {/* </ScrollablePane>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

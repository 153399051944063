import React, {useEffect, useState} from 'react';
import TaxonomyField from '../../../shared/taxonomyField/taxonomyField';
import {useDispatch} from 'react-redux';
import TaxonomyGrid from '../../../shared/taxonomyField/taxonomyGrid';
import {getPerentFromTreeData} from '../../../shared/util';
import {
  Checkbox,
  FontWeights,
  getTheme,
  IconButton,
  IIconProps,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  TextField
} from '@fluentui/react';
import {uPrinceTheme} from '../../../../theme';
import {useId} from '@uifabric/react-hooks';
import i18n from '../../../../i18n';
import {Field, Form} from 'react-final-form';
import {createCpcResourceFamily, getCpcResourceFamily} from '../../../reducers/productCatalogueReducer';
import {v4 as uuidv4} from 'uuid';

const theme = getTheme();
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const CERTIFICATION_TAXONOMY_CERT_ID = 'oo10e768-3e06-po02-b337-ee367a82adoo';

const editWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
    width: 17,
    minWidth: 17,
    height: 15,
    paddingRight: 1,
    paddingLeft: 1,
    paddingTop: 1,
    paddingBottom: 1
  },
  rootHovered: { color: theme.palette.neutralDark }
};

const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
    width: 17,
    minWidth: 17,
    height: 15,
    paddingRight: 1,
    paddingLeft: 1,
    paddingTop: 1,
    paddingBottom: 1
  },
  rootHovered: { color: theme.palette.neutralDark }
};

const editIconWhite: IIconProps = {
  iconName: 'Edit',
  styles: editWhiteIconButtonStyles
};

const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch'
  },
  actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white'
    }
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end'
    }
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    paddingTop: 20,
    // minWidth: screen.width > 1280?"40vw":"50vw",
    // minHeight: screen.width > 1280?"60vh":"75vh",
    // height: screen.width > 1280?"80vh":"75vh",
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 }
    }
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'none',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px'
  }
});

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white'
  },
  rootHovered: { color: theme.palette.neutralDark }
};

const CertificationTaxonomy = (props: {
  label?: string
  selectItemId: string | null
  onChange?: any
  disabled?: boolean
  treeData: any
}) => {
  const dispatch = useDispatch();
  const titleId = useId('title');

  // const { resourceFamily } = useSelector(
  //   (state: any) => state.productCatalogue,
  // );

  const [selectedParentIds, setSelectedParentIds]: any = useState([]);
  const [selectItemId, setselectItemId]: any = useState(props.selectItemId);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [selectedNode, setSelectedNode] = useState<any>(null);
  const [selectedPath, setSelectedPath] = useState<any>(null);
  const [treeData, setTreeData] = useState<any>([]);

  {/* Add/Edit Modal */ }
  const onSubmit = async (values: any) => {
    const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
    await sleep(300);

    // const path = selectedPath;

    const data = {
      id: values?.id,
      label: values?.name,
      languageCode: null,
      cpcResourceFamilyId: values?.cpcResourceFamilyId,
      parentId: values?.parentId
    }

    // const updateTree = changeNodeAtPath({
    //   treeData,
    //   path,
    //   getNodeKey: ({ treeIndex }) => treeIndex,
    //   newNode: {
    //     title: values?.name,
    //     parentId: values?.parentId
    //   }
    // });

    Promise.all([
      dispatch(createCpcResourceFamily(data))
    ]).then(() => {
      // setTreeData(updateTree);
      dispatch(getCpcResourceFamily());

      setIsEdit(false);
      setIsNew(false);
      setIsOpen(false);
    });
  };

  const getInitialFormValues = (formData: any) => {
    if (isNew) {
      return {
        id: uuidv4(),
        cpcResourceFamilyId: uuidv4(),
        parentId: formData?.id,
      };
    } else if (formData && formData?.id) {
      return {
        id: null,
        name: formData?.title,
        cpcResourceFamilyId: formData?.id,
        parentId: formData?.parentId
      };
    }
    return null;
  };
  {/* Add/Edit Modal */ }

  useEffect(() => {
    if (selectItemId) {
      setSelectedParentIds(
        getPerentFromTreeData(props.treeData, selectItemId),
      );
    } else {
      setSelectedParentIds([]);
    }
  }, [props.treeData, selectItemId]);

  useEffect(() => {
    setselectItemId(props.selectItemId);
  }, [props.selectItemId]);

  useEffect(() => {
    setTreeData(props.treeData);
  }, [props.treeData]);

  const renderButtons = (rowInfo: any) => {
    const isParent = selectedParentIds.includes(rowInfo.node.id);
    const certId = rowInfo?.node?.certificationTaxonomyLevelId;

    if (certId == CERTIFICATION_TAXONOMY_CERT_ID) {
      return ([
        isParent
          ? <div
            key={rowInfo.node.id}
            className={'Indeterminate'}
            style={{ paddingTop: 6 }}
          >
            <span
              style={{ display: 'none' }}>{JSON.stringify(isParent)}</span> {/* if u remove this part parent selection not working properly   */}
            <Checkbox
              className="btn btn-outline-success"
              styles={{ root: { verticalAlign: 'middle' } }}
              defaultIndeterminate
              checked={rowInfo.node.id === selectItemId}
              onChange={() => handleNodeCheckboxClick(rowInfo)}
            />
          </div>
          : <div
            key={rowInfo.node.id}
            className={'Indeterminate'}
            style={{ paddingTop: 6 }}
          >
            <Checkbox
              className="btn btn-outline-success"
              styles={{ root: { verticalAlign: 'middle' } }}
              checked={rowInfo.node.id === selectItemId}
              onChange={() => handleNodeCheckboxClick(rowInfo)}
            />
          </div>
        // <div>
        //   <PrimaryButton
        //     style={{
        //       width: 22,
        //       minWidth: 22,
        //       height: 22,
        //       padding: 5,
        //       marginTop: 5,
        //       marginRight: 3
        //     }}
        //     iconProps={editIconWhite}
        //     ariaLabel="Close popup modal"
        //     onClick={() => handleNodeEdit(rowInfo)}
        //   >
        //   </PrimaryButton>
        // </div>,
        // <div>
        //   <PrimaryButton
        //     style={{
        //       width: 22,
        //       minWidth: 22,
        //       height: 22,
        //       padding: 5,
        //       marginTop: 5,
        //       marginRight: 3
        //     }}
        //     iconProps={addIconWhite}
        //     ariaLabel="Close popup modal"
        //     onClick={() => handleNodeAdd(rowInfo)}
        //   >
        //   </PrimaryButton>
        // </div>
      ])
    };
  };

  const handleNodeCheckboxClick = (rowInfo: any) => {
    const { node, path } = rowInfo;
    setSelectedPath(path);
    setSelectedNode(node);
    props.onChange(node);
  };

  const handleNodeEdit = (rowInfo: any) => {
    const { node, path } = rowInfo;
    setIsEdit(true);
    setIsOpen(true);
    setSelectedNode(node);
    setSelectedPath(path);
    // props.onChange(rowInfo?.node);
  };

  const handleNodeAdd = (rowInfo: any) => {
    const { node, path } = rowInfo;
    setIsNew(true);
    setIsOpen(true);
    setSelectedNode(node);
    setSelectedPath(path);
    // props.onChange(rowInfo?.node);
  };

  return (
    <div>
      <TaxonomyField label={props.label} treeData={props.treeData} selectItemId={selectItemId}
        disabled={props.disabled}>
        <TaxonomyGrid
          treeData={treeData}
          renderButtons={renderButtons}
        />
      </TaxonomyField>

      {/* Add/Edit Modal */}
      <Modal
        titleAriaId={titleId}
        isOpen={isOpen}
        onDismiss={() => {
          setIsOpen(false);
          // resetValues();
        }}
        isBlocking={true}
        dragOptions={undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>{(isEdit) ? i18n.t('editNode') : i18n.t('addNode')} </span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              setIsEdit(false);
              setIsNew(false);
              setIsOpen(false);
              // resetValues();
            }}
          />
        </div>
        <Form
          onSubmit={onSubmit}
          initialValues={getInitialFormValues(selectedNode)}
          validate={(values) => {
            const errors: any = {};

            if (!values?.name) {
              errors.name = i18n.t('nameRequired');
            }

            return errors;
          }}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
          }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <div className={contentStyles.body}>
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                      <Field name="name">
                        {({ input, meta }) => (
                          <TextField
                            autoComplete="off"
                            label={i18n.t('name')}
                            value={values?.name}
                            required={true}
                            onChange={(event, value) => {
                              input.onChange(value);
                              // setEditNodeName(value);
                              // setNewNodeName(value);
                              // resetValidation();
                            }}
                            // errorMessage={meta?.error?.errors?.name}
                            disabled={false}
                          />)}
                      </Field>
                    </div>
                  </div>
                </div>

                <div className={contentStyles.footer}>
                  <PrimaryButton
                    iconProps={addIconWhite}
                    text={isEdit ? i18n.t('save') : i18n.t('add')}
                    style={{ marginTop: 10, marginBottom: 10, marginRight: 10 }}
                    onClick={() => {
                      form.submit();
                    }}
                  />
                </div>
                {/* <pre>{JSON.stringify(values)}</pre> */}
              </form>
            )
          }}
        />
      </Modal >
      {/* Add/Edit Modal */}
    </div >
  );
};


export default CertificationTaxonomy;

import React, {useRef,} from 'react';
import {useDrag} from 'react-dnd';
import moment from 'moment';

const CustomDragBox = (props: {
    children?: any;
    item: any;
    type?: any;
    icon?: any;
    disabled?: boolean;
    style?: any;
    key?: any;
}) => {
    const [dropped, setDropped] = React.useState(false)
    const nonDragRef = useRef<HTMLDivElement>(null);

    const formatDate = (item: any) => {
        return moment(item?.day).format('DD/MM/YYYY')
    };

    const handleNullValue = (value: any) => {
        return value ? value : 0
    };

    const [collectedProps, dragSourceRef] = useDrag({
        type: props.type ?? formatDate(props.item),
        item: {
            type: props.type ?? formatDate(props.item),
            dragItem: props.item
        },
        end: (item: any, monitor: { getDropResult: () => any; }) => {   // end: (item, monitor) => {
            const dropResult = monitor.getDropResult()

            if (item && dropResult) {
                setDropped(true)
            }
        }
    });

    return (
        dropped ? <div></div> : <div
            className={props.disabled ? 'noneDragBox' : 'dragBox'}
            ref={props.disabled ? nonDragRef : dragSourceRef}
            key={props.key}
            style={props.style}
        >
            {props?.children}
        </div>
    )
}

export default CustomDragBox;

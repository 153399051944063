import i18n from '../../i18n';
import { format24hDateTime, formatDateTimeToLocalExcel } from '../shared/util';
import { OPTIMIZE_DB_EP, WORK_FLOW_STATE_EP } from '../shared/endpoints';
import UprinceLogger from '../shared/Logger/logger';
import moment from 'moment';

export const LOAD_SHIFT = 'uprince/LOAD_SHIFT';
export const LOAD_SHIFT_SUCCESS = 'uprince/LOAD_SHIFT_SUCCESS';
export const LOAD_SHIFT_FAIL = 'uprice/LOAD_SHIFT_FAIL';

export const LOAD_SHIFT_BY_ID = 'uprince/LOAD_SHIFT_BY_ID';
export const LOAD_SHIFT_BY_ID_SUCCESS = 'uprince/LOAD_SHIFT_BY_ID_SUCCESS';
export const LOAD_SHIFT_BY_ID_FAIL = 'uprice/LOAD_SHIFT_BY_ID_FAIL';

export const LOAD_SHIFT_APPROVED = 'uprince/LOAD_SHIFT_APPROVED';
export const LOAD_SHIFT_APPROVED_SUCCESS = 'uprince/LOAD_SHIFT_APPROVED_SUCCESS';
export const LOAD_SHIFT_APPROVED_FAIL = 'uprice/LOAD_SHIFT_APPROVED_FAIL';

export const LOAD_SHIFT_REJECT = 'uprince/LOAD_SHIFT_REJECT';
export const LOAD_SHIFT_REJECT_SUCCESS = 'uprince/LOAD_SHIFT_REJECT_SUCCESS';
export const LOAD_SHIFT_REJECT_FAIL = 'uprice/LOAD_SHIFT_REJECT_FAIL';

export const LOAD_SHIFT_EXCEL = 'uprince/LOAD_SHIFT_EXCEL';
export const LOAD_SHIFT_EXCEL_SUCCESS = 'uprince/LOAD_SHIFT_EXCEL_SUCCESS';
export const LOAD_SHIFT_EXCEL_FAIL = 'uprice/LOAD_SHIFT_EXCEL_FAIL';

export const LOAD_WORKFLOW_STATE = 'uprince/LOAD_WORKFLOW_STATE';
export const LOAD_WORKFLOW_STATE_SUCCESS = 'uprince/LOAD_WORKFLOW_STATE_SUCCESS';
export const LOAD_WORKFLOW_STATE_FAIL = 'uprice/LOAD_WORKFLOW_STATE_FAIL';

export const UPDATE_WORKFLOW_STATUS = 'uprince/UPDATE_WORKFLOW_STATUS';
export const UPDATE_WORKFLOW_STATUS_SUCCESS = 'uprince/UPDATE_WORKFLOW_STATUS_SUCCESS';
export const UPDATE_WORKFLOW_STATUS_FAIL = 'uprice/UPDATE_WORKFLOW_STATUS_FAIL';

export const OPTIMIZE_DB = 'uprince/OPTIMIZE_DB';
export const OPTIMIZE_DB_SUCCESS = 'uprince/OPTIMIZE_DB_SUCCESS';
export const OPTIMIZE_DB_FAIL = 'uprice/OPTIMIZE_DB_FAIL';

const defaultState: any = {
  allTimeClocks: [],
  selectedTimeClock: [],
  shiftExcelExportData: [],
  timeClockState: [],
  filterTimeClockState: [],
  updateTimeClock: false,
  updateTimeClockList: false,
  loadMsgType: '',
  loadMsg: '',
  detailsMsgType: '',
  detailsMsg: '',
  approvedMsgType: '',
  approvedMsg: '',
  rejectMsgType: '',
  rejectMsg: '',
  alertMsg: '',
  alertMsgType: '',
  loading: false,
  isDataLoaded: false,
  isDetailsLoaded: true,
  optimizedSuccessed: false,
};

const timeClockReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case LOAD_SHIFT:
      return { ...state, loading: true, allTimeClocks: [], updateTimeClock: false, isDataLoaded: false };
    case LOAD_SHIFT_SUCCESS:
      return {
        ...state,
        loading: false,
        isDataLoaded: true,
        loadMsg: (!action.payload.data.status) ? action.payload.data.message : '',
        alertMsg: '',
        alertMsgType: 'success',
        allTimeClocks: formattedPayloadToListPaneTable(action.payload),
        updateTimeClock: false,
      };
    case LOAD_SHIFT_FAIL:
      return {
        ...state,
        allTimeClocks: [],
        isDataLoaded: true,
        updateTimeClock: false,
        loadMsg: action.error.response.data.message,
        loadMsgType: 'error',
        loading: false,
        error: 'Error while fetching Time Clock',
      };
    case LOAD_SHIFT_BY_ID:
      return { ...state, loading: false, isDetailsLoaded: false };
    case LOAD_SHIFT_BY_ID_SUCCESS:
      UprinceLogger.log('LOAD_SHIFT_BY_ID_SUCCESS', action.payload);
      return {
        ...state,
        loading: false,
        isDetailsLoaded: true,
        detailsMsg: '',
        detailsMsgType: '',
        alertMsg: '',
        alertMsgType: '',
        selectedTimeClock: formattedPayloadToDocumentPaneTable(action.payload),
      };
    case LOAD_SHIFT_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        isDetailsLoaded: false,
        selectedTimeClock: [],
        error: 'Error while fetching Time Clock',
        detailsMsg: '',
        detailsMsgType: 'error',
      };

    case LOAD_SHIFT_APPROVED:
      return { ...state, loading: false };
    case LOAD_SHIFT_APPROVED_SUCCESS:
      return {
        ...state,
        loading: false,
        approvedMsg: '',
        approvedMsgType: '',
        updateTimeClock: true,
        alertMsg: action.payload.data.message,
        alertMsgType: (action.payload.data.status) ? 'success' : 'error',
      };
    case LOAD_SHIFT_APPROVED_FAIL:
      return {
        ...state,
        loading: false,
        error: 'Error while fetching Time Clock',
        approvedMsg: action.error.response.data.message,
        approvedMsgType: 'error',
        alertMsg: action.error.response.data.message,
        alertMsgType: 'error',
      };

    case LOAD_WORKFLOW_STATE:
      return { ...state, loading: false };
    case LOAD_WORKFLOW_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        timeClockState: formattedPayloadToDropdown(action.payload),
        filterTimeClockState: formattedPayloadToFilterDropdown(action.payload),
      };
    case LOAD_WORKFLOW_STATE_FAIL:
      return {
        ...state,
        loading: false,
        error: 'Error while fetching Time Clock',
        approvedMsg: action.error.response.data.message,
        approvedMsgType: 'error',
        alertMsg: action.error.response.data.message,
        alertMsgType: 'error',
      };

    case LOAD_SHIFT_REJECT:
      return { ...state, loading: false };
    case LOAD_SHIFT_REJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        rejectMsg: '',
        rejectMsgType: '',
        updateTimeClock: true,
        alertMsg: action.payload.data.message,
        alertMsgType: (action.payload.data.status) ? 'success' : 'error',
      };
    case LOAD_SHIFT_REJECT_FAIL:
      return {
        ...state,
        loading: false,
        error: 'Error while fetching Time Clock',
        rejectMsg: action.error.response.data.message,
        rejectMsgType: 'error',
        alertMsg: action.error.response.data.message,
        alertMsgType: 'error',
      };

    case LOAD_SHIFT_EXCEL:
      return { ...state, loading: false };
    case LOAD_SHIFT_EXCEL_SUCCESS:
      return {
        ...state,
        loading: false,
        shiftExcelExportData: formattedPayloadToExcel(action.payload),
      };
    case LOAD_SHIFT_EXCEL_FAIL:
      return {
        ...state,
        loading: false,
        error: 'Error while fetching Time Clock',
        approvedMsg: '',
        approvedMsgType: 'error',
      };

    case OPTIMIZE_DB:
      return { ...state, loading: true, optimizedSuccessed: false };
    case OPTIMIZE_DB_SUCCESS:
      return {
        ...state,
        loading: false,
        optimizedSuccessed: true,
      };
    case OPTIMIZE_DB_FAIL:
      return {
        ...state,
        loading: false,
        optimizedSuccessed: false,
      };

    default:
      return state;
  }
};

export const getAllTimeClocks = (data: {}) => {
  return {
    types: [LOAD_SHIFT, LOAD_SHIFT_SUCCESS, LOAD_SHIFT_FAIL],
    payload: {
      request: {
        url: 'Shift/FilterV2',
        //url: 'Shift/Filter',
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getTimeClockById = (id: any) => {
  return {
    types: [LOAD_SHIFT_BY_ID, LOAD_SHIFT_BY_ID_SUCCESS, LOAD_SHIFT_BY_ID_FAIL],
    payload: {
      request: {
        url: 'Shift/ReadShiftDetails/' + id,
        method: 'GET',
      },
    },
  };
};

export const getShiftExcelExportData = (data: {}) => {
  return {
    types: [LOAD_SHIFT_EXCEL, LOAD_SHIFT_EXCEL_SUCCESS, LOAD_SHIFT_EXCEL_FAIL],
    payload: {
      request: {
        url: 'Shift/ReadShiftsForExcel',
        method: 'POST',
        data: data,
      },
    },
  };
};

export const updateShiftStatus = (data: {}) => {
  return {
    types: [UPDATE_WORKFLOW_STATUS, UPDATE_WORKFLOW_STATUS_SUCCESS, UPDATE_WORKFLOW_STATUS_FAIL],
    payload: {
      request: {
        url: 'Shift/ReadShiftsForExcel',
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getShiftWorkflowState = (data: {}) => {
  return {
    types: [
      LOAD_WORKFLOW_STATE,
      LOAD_WORKFLOW_STATE_SUCCESS,
      LOAD_WORKFLOW_STATE_FAIL,
    ],
    payload: {
      request: {
        //url: 'WorkflowState/Read',
        url: WORK_FLOW_STATE_EP,
        method: 'GET',
        data: data,
      },
    },
  };
};

export const setShiftStatusApproved = (id: any) => {
  return {
    types: [
      LOAD_SHIFT_APPROVED,
      LOAD_SHIFT_APPROVED_SUCCESS,
      LOAD_SHIFT_APPROVED_FAIL,
    ],
    payload: {
      request: {
        url: 'Shift/Approve/' + id,
        method: 'PUT',
      },
    },
  };
};

export const setShiftStatusReject = (id: any) => {
  return {
    types: [
      LOAD_SHIFT_REJECT,
      LOAD_SHIFT_REJECT_SUCCESS,
      LOAD_SHIFT_REJECT_FAIL,
    ],
    payload: {
      request: {
        url: 'Shift/Reject/' + id,
        method: 'PUT',
      },
    },
  };
};

export const optimizeDB = () => {
  return {
    types: [
      OPTIMIZE_DB,
      OPTIMIZE_DB_SUCCESS,
      OPTIMIZE_DB_FAIL,
    ],
    payload: {
      request: {
        url: OPTIMIZE_DB_EP,
        method: 'DELETE',
      },
    },
  };
};

const formattedPayloadToExcel = (response: any) => {
  if (response.data.result) {
    let data = response.data.result.map((item: any) => {
      return {
        'Shift Start Date/Time': (item.shiftStartDateTime) ? (formatDateTimeToLocalExcel(item.shiftStartDateTime)) : null,
        'Shift End Date/Time': (item.shiftEndDateTime) ? (formatDateTimeToLocalExcel(item.shiftEndDateTime)) : null,
        'Employee': item.employee,
        'Employer': item.employer,
        'Activity  Type': item.activityType,
        'Activity Start Date/Time': (item.activityStartTime) ? (formatDateTimeToLocalExcel(item.activityStartTime)) : null,
        'Nearest 15 minutes - Start Date/Time': (item.startDateTimeRoundNearest) ? (formatDateTimeToLocalExcel(item.startDateTimeRoundNearest)) : null,
        'Activity End Date/Time': (item.activityEndTime) ? (formatDateTimeToLocalExcel(item.activityEndTime)) : null,
        'Nearest 15 minutes - End Date/Time': (item.endDateTimeRoundNearest) ? (formatDateTimeToLocalExcel(item.endDateTimeRoundNearest)) : null,
        'Total time': item.totalTime,
        //'Nearest 15 minutes - Total time': item.totalTimeRoundNearest,
        'Nearest 15 minutes - Total time': item.totalTimeRoundNearestDateFormat ? moment(item.totalTimeRoundNearestDateFormat).format('HH:mm:ss') : null,
        'Distance': item.distance,
        //'Qr title': item.qrTitle,
        'Project title': item.projectTitle,
        'Location': item.location,
        'Vehicle number': item.vehicleNo,
        'Role': item.travellerType,
      };
    });
    return data;
  } else {
    return [];
  }

};

const getNameWithCompany = (item: any) => {
  let name = '';
  let company = '';
  if (item && item.user && item.user.person) {
    name = item.user.person.fullName;
  }

  if (item && item.user && item.user.company) {
    name = name;
    company = item.user.company.name;
  }
  return name + ` ( ${company} )`;
};

const formattedPayloadToListPaneTable = (response: any) => {
  if (response.data.result) {
    let data = response.data.result.map((item: any) => {
      //console.log("response123",item.id);
      return {
        value: item.id,
        startDateTime: format24hDateTime(item.startDateTime),
        endDateTime: (item.endDateTime) ? format24hDateTime(item.endDateTime) : '-',
        userName: item.user,
        //userName: getNameWithCompany(item),//item && item.user && item.user.person?item.user.person.fullName:''+"-"+ item && item.user && item.user.company?item.user.company.name:'',
        status: item.status,
      };
    });
    return data;
  } else {
    return [];
  }

};

const formattedPayloadToDocumentPaneTable = (response: any) => {
  if (response.data.result) {
    let timeRegistrations = response.data.result.timeRegistrations.map(
      (item: any) => {
        return {
          startDateTime: format24hDateTime(item.startDateTime),
          elapedTime: (item.elapedTime) ? item.elapedTime : '-',
          // activityType: item.activityType.type,
          activityType: item.activityType.type,
        };
      },
    );

    let details = {
      company: response.data.result && response.data.result.cabPerson && response.data.result.cabPerson.company ? response.data.result.cabPerson.company.name : '',
      isShiftEnded: response.data.result.isShiftEnded,
      date: format24hDateTime(response.data.result.date),
      endDateTime: (response.data.result.endDateTime) ? format24hDateTime(response.data.result.endDateTime) : null,
      person: response.data.result.cabPerson,
      totalTime: response.data.result.totalTime,
      timeRegistrations: timeRegistrations,
      statusId: response.data.result.workflowState.id,
    };
    return details;
  } else {
    return [];
  }

};

const formattedPayloadToDropdown = (response: any) => {
  if (response.data.result) {
    let data = response.data.result.map((item: any) => {
      return {
        key: item.id,
        text: item.state,
        isDefault: false,
      };
    });
    return data;
  } else {
    return [];
  }
};

const formattedPayloadToFilterDropdown = (response: any) => {
  if (response) {
    let data = response.data.result.map((item: any) => {
      return {
        key: item.id,
        text: item.state,
        isDefault: false,
      };
    });
    let defualtOption = [
      {
        key: '0',
        text: i18n.t('all'),
        isDefault: true,
      },
    ];
    Array.prototype.push.apply(
      defualtOption,
      data,
    );
    return defualtOption;
  } else {
    return [];
  }
};

export default timeClockReducer;

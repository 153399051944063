import React from 'react';
import {
  ActionButton,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Separator,
  Stack,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../../theme';
import { ProgressStatement, ProgressStatementValidationMessage } from '../../../../types/progressStatement';
import TextEditor from '../../../../shared/textEditor/textEditor';

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const theme = getTheme();

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

// Styles definition
const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};


const classNames = mergeStyleSets({
  fullWidth: {
    width: '100%',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  bigScreenTree: {
    marginBottom: 30,
    height: '30vh',
  },
  smallScreenTree: {
    marginBottom: 30,
    height: '56vh',
  },
  textEditor: {
    height: window.innerWidth <= 1280 ? '50vh' : '25vh',
  },
  customerLabel: {
    paddingTop: 4,
    paddingBottom: 0,
  },
});

const stackFooterBarItemStyles: IStackItemStyles = {
  root: {
    height: 45,
  },
};
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };


const InvoiceComments = (props: {
  isDisabled: boolean,
  isEdit: boolean,
  formData: ProgressStatement;
  saveFormData: () => void;
  handleFormChange: (
    progressStatement: ProgressStatement,
    save: boolean,
  ) => void;
  validationMessages: ProgressStatementValidationMessage;
}) => {
  const { t } = useTranslation();


  return (
    <div className={'document-pane-card'}>

      <div className={'card-header'}>
        <Link href="#" id="ps-invoice-comments">
          <Label>3. {t('invoiceComments')} </Label>
        </Link>
      </div>

      <div className={'card-body'}>
        <div className="proj-detail-content inner">

          <div className="marginTop">
            <div className={'ms-Grid'} style={{ width: '100%', height: '100%', padding: 16 }}>
              <div className="ms-Grid-row">
                <div className={`${classNames.fullWidth}`}>
                  <Label>{t('comments')}</Label>
                  <TextEditor
                    readOnly={props.isDisabled}
                    value={
                      props.formData.invoiceComment
                        ? props.formData.invoiceComment
                        : ''
                    }
                    onChange={(value) => {
                      if (!props.isDisabled) {
                        if (value) {
                          props.formData.invoiceComment = value;
                        } else {
                          props.formData.invoiceComment = null;
                        }
                      }
                      // props.handleFormChange(
                      //   props.formData,
                      //   false
                      // );
                    }}/>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className={'card-footer'}>
        <Separator/>
        <Stack
          styles={stackStyles}
          tokens={itemAlignmentsStackTokens}>
          <Stack.Item align="end" styles={stackFooterBarItemStyles}>
            <ActionButton
              disabled={props.isDisabled ? true : (!props.formData.progressStatementId)}
              className={classNames.actionButton}
              iconProps={saveIcon}
              allowDisabledFocus
              onClick={() => {
                props.saveFormData();
              }}
            >
              <Label className={classNames.actionButton}>{t('save')} </Label>
            </ActionButton>
          </Stack.Item>
        </Stack>
      </div>

    </div>
  );
};


export default InvoiceComments;

export const LOAD_PMOL_SHORTCUTPANE = 'uprince/LOAD_PMOL_SHORTCUTPANE';
export const LOAD_PMOL_SHORTCUTPANE_SUCCESS = 'uprince/LOAD_PMOL_SHORTCUTPANE_SUCCESS';
export const LOAD_PMOL_SHORTCUTPANE_FAIL = 'uprince/LOAD_PMOL_SHORTCUTPANE_FAIL';

export const LOAD_PMOL_LSITPANE = 'uprince/LOAD_PMOL_LSITPANE';
export const LOAD_PMOL_LSITPANE_SUCCESS = 'uprince/LOAD_PMOL_LSITPANE_SUCCESS';
export const LOAD_PMOL_LSITPANE_FAIL = 'uprince/LOAD_PMOL_LSITPANE_FAIL';

export const LOAD_PMOL_DROPDOWN = 'uprince/LOAD_PMOL_DROPDOWN';
export const LOAD_PMOL_DROPDOWN_SUCCESS = 'uprince/LOAD_PMOL_DROPDOWN_SUCCESS';
export const LOAD_PMOL_DROPDOWN_FAIL = 'uprince/LOAD_PMOL_DROPDOWN_FAIL';

export const LOAD_PMOL_DATA = 'uprince/LOAD_PMOL_DATA';
export const LOAD_PMOL_DATA_SUCCESS = 'uprince/LOAD_PMOL_DATA_SUCCESS';
export const LOAD_PMOL_DATA_FAIL = 'uprince/LOAD_PMOL_DATA_FAIL';

export const SAVE_PMOL = 'uprince/SAVE_PMOL';
export const SAVE_PMOL_SUCCESS = 'uprince/SAVE_PMOL_SUCCESS';
export const SAVE_PMOL_FAIL = 'uprince/SAVE_PMOL_FAIL';

export const LOAD_PMOL_BY_ID = 'uprince/LOAD_PMOL_BY_ID';
export const LOAD_PMOL_BY_ID_SUCCESS = 'uprince/LOAD_PMOL_BY_ID_SUCCESS';
export const LOAD_PMOL_BY_ID_FAIL = 'uprince/LOAD_PMOL_BY_ID_FAIL';


export const SET_PMOL_UID = 'uprince/SET_PMOL_UID';
export const CLEAR_PMOL_UID = 'uprince/CLEAR_PMOL_UID';
export const CLEAR_PMOL_FORM_DATA = 'uprince/CLEAR_PMOL_FORM_DATA';

export const SET_PLANNED_WORK_FETCHED = 'uprince/SET_PLANNED_WORK_FETCHED';
export const SET_EXTRA_WORK_FETCHED = 'uprince/SET_EXTRA_WORK_FETCHED';

export const SAVE_JOURNAL = 'uprince/SAVE_JOURNAL';
export const SAVE_JOURNAL_SUCCESS = 'uprince/SAVE_JOURNAL_SUCCESS';
export const SAVE_JOURNAL_FAIL = 'uprince/SAVE_JOURNAL_FAIL';

export const LOAD_PMOL_JOURNAL = 'uprince/LOAD_PMOL_JOURNAL';
export const LOAD_PMOL_JOURNAL_SUCCESS = 'uprince/LOAD_PMOL_JOURNAL_SUCCESS';
export const LOAD_PMOL_JOURNAL_FAIL = 'uprince/LOAD_PMOL_JOURNAL_FAIL';
export const LOAD_RESOURCE_BY_BOR_ID = 'uprince/LOAD_RESOURCE_BY_BOR_ID';
export const LOAD_RESOURCE_BY_BOR_ID_SUCCESS = 'uprince/LOAD_RESOURCE_BY_BOR_ID_SUCCESS';
export const LOAD_RESOURCE_BY_BOR_ID_FAIL = 'uprince/LOAD_RESOURCE_BY_BOR_ID_FAIL';


export const LOAD_PMOL_MATERIALS = 'uprince/LOAD_PMOL_MATERIALS';
export const LOAD_PMOL_MATERIALS_SUCCESS = 'uprince/LOAD_PMOL_MATERIALS_SUCCESS';
export const LOAD_PMOL_MATERIALS_FAIL = 'uprince/LOAD_PMOL_MATERIALS_FAIL';

export const LOAD_PMOL_TOOLS = 'uprince/LOAD_PMOL_TOOLS';
export const LOAD_PMOL_TOOLS_SUCCESS = 'uprince/LOAD_PMOL_TOOLS_SUCCESS';
export const LOAD_PMOL_TOOLS_FAIL = 'uprince/LOAD_PMOL_TOOLS_FAIL';

export const LOAD_PMOL_CONSUMABLE = 'uprince/LOAD_PMOL_CONSUMABLE';
export const LOAD_PMOL_CONSUMABLE_SUCCESS = 'uprince/LOAD_PMOL_CONSUMABLE_SUCCESS';
export const LOAD_PMOL_CONSUMABLE_FAIL = 'uprince/LOAD_PMOL_CONSUMABLE_FAIL';

export const LOAD_PMOL_LABOURS = 'uprince/LOAD_PMOL_LABOURS';
export const LOAD_PMOL_LABOURS_SUCCESS = 'uprince/LOAD_PMOL_LABOURS_SUCCESS';
export const LOAD_PMOL_LABOURS_FAIL = 'uprince/LOAD_PMOL_LABOURS_FAIL';

export const LOAD_PMOL_PLANNED_WORK_RESOURCES = 'uprince/LOAD_PMOL_PLANNED_WORK_RESOURCES';
export const LOAD_PMOL_PLANNED_WORK_RESOURCES_SUCCESS = 'uprince/LOAD_PMOL_PLANNED_WORK_RESOURCES_SUCCESS';
export const LOAD_PMOL_PLANNED_WORK_RESOURCES_FAIL = 'uprince/LOAD_PMOL_PLANNED_WORK_RESOURCES_FAIL';

export const SAVE_MATERIALS = 'uprince/SAVE_MATERIALS';
export const SAVE_MATERIALS_SUCCESS = 'uprince/SAVE_MATERIALS_SUCCESS';
export const SAVE_MATERIALS_FAIL = 'uprince/SAVE_MATERIALS_FAIL';

export const SAVE_TOOLS = 'uprince/SAVE_TOOLS';
export const SAVE_TOOLS_SUCCESS = 'uprince/SAVE_TOOLS_SUCCESS';
export const SAVE_TOOLS_FAIL = 'uprince/SAVE_TOOLS_FAIL';

export const SAVE_CONSUMABLE = 'uprince/SAVE_CONSUMABLE';
export const SAVE_CONSUMABLE_SUCCESS = 'uprince/SAVE_CONSUMABLE_SUCCESS';
export const SAVE_CONSUMABLE_FAIL = 'uprince/SAVE_CONSUMABLE_FAIL';

export const SAVE_LABOURS = 'uprince/SAVE_LABOURS';
export const SAVE_LABOURS_SUCCESS = 'uprince/SAVE_LABOURS_SUCCESS';
export const SAVE_LABOURS_FAIL = 'uprince/SAVE_LABOURS_FAIL';

export const DELETE_PMOL_MATERIALS = 'uprince/DELETE_PMOL_MATERIALS';
export const DELETE_PMOL_MATERIALS_SUCCESS = 'uprince/DELETE_PMOL_MATERIALS_SUCCESS';
export const DELETE_PMOL_MATERIALS_FAIL = 'uprince/DELETE_PMOL_MATERIALS_FAIL';

export const DELETE_PMOL_TOOLS = 'uprince/SAVE_TOOLS';
export const DELETE_PMOL_TOOLS_SUCCESS = 'uprince/DELETE_PMOL_TOOLS_SUCCESS';
export const DELETE_PMOL_TOOLS_FAIL = 'uprince/DELETE_PMOL_TOOLS_FAIL';

export const DELETE_PMOL_CONSUMABLE = 'uprince/DELETE_PMOL_CONSUMABLE';
export const DELETE_PMOL_CONSUMABLE_SUCCESS = 'uprince/DELETE_PMOL_CONSUMABLE_SUCCESS';
export const DELETE_PMOL_CONSUMABLE_FAIL = 'uprince/DELETE_PMOL_CONSUMABLE_FAIL';

export const DELETE_PMOL_LABOURS = 'uprince/DELETE_PMOL_LABOURS';
export const DELETE_PMOL_LABOURS_SUCCESS = 'uprince/DELETE_PMOL_LABOURS_SUCCESS';
export const DELETE_PMOL_LABOURS_FAIL = 'uprince/DELETE_PMOL_LABOURS_FAIL';

export const LOAD_LOCATION_BY_PROJECT = 'uprince/LOAD_LOCATION_BY_PROJECT';
export const LOAD_LOCATION_BY_PROJECT_SUCCESS = 'uprince/LOAD_LOCATION_BY_PROJECT_SUCCESS';
export const LOAD_LOCATION_BY_PROJECT_FAIL = 'uprince/LOAD_LOCATION_BY_PROJECT_FAIL';

export const CREATE_LOCATION = 'uprince/CREATE_LOCATION';
export const CREATE_LOCATION_SUCCESS = 'uprince/CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_FAIL = 'uprince/CREATE_LOCATION_FAIL';

export const START_HANDSHAKE = 'uprince/START_HANDSHAKE';
export const START_HANDSHAKE_SUCCESS = 'uprince/START_HANDSHAKE_SUCCESS';
export const START_HANDSHAKE_FAIL = 'uprince/START_HANDSHAKE_FAIL';

export const UPLOAD_SIGNATURE = 'uprince/UPLOAD_SIGNATURE';
export const UPLOAD_SIGNATURE_SUCCESS = 'uprince/UPLOAD_SIGNATURE_SUCCESS';
export const UPLOAD_SIGNATURE_FAIL = 'uprince/UPLOAD_SIGNATURE_FAIL';

export const STOP_HANDSHAKE = 'uprince/STOP_HANDSHAKE';
export const STOP_HANDSHAKE_SUCCESS = 'uprince/STOP_HANDSHAKE_SUCCESS';
export const STOP_HANDSHAKE_FAIL = 'uprince/STOP_HANDSHAKE_FAIL';

export const READ_STOP_HANDSHAKE = 'uprince/READ_STOP_HANDSHAKE';
export const READ_STOP_HANDSHAKE_SUCCESS = 'uprince/READ_STOP_HANDSHAKE_SUCCESS';
export const READ_STOP_HANDSHAKE_FAIL = 'uprince/READ_STOP_HANDSHAKE_FAIL';


export const GET_BY_BOR = 'uprince/GET_BY_BOR';
export const GET_BY_PMOL = 'uprince/GET_BY_PMOL';


export const LOAD_PMOL_EXTRA_WORK_MATERIALS = 'uprince/LOAD_PMOL_EXTRA_WORK_MATERIALS';
export const LOAD_PMOL_EXTRA_WORK_MATERIALS_SUCCESS = 'uprince/LOAD_PMOL_EXTRA_WORK_MATERIALS_SUCCESS';
export const LOAD_PMOL_EXTRA_WORK_MATERIALS_FAIL = 'uprince/LOAD_PMOL_EXTRA_WORK_MATERIALS_FAIL';

export const LOAD_PMOL_EXTRA_WORK_TOOLS = 'uprince/LOAD_PMOL_EXTRA_WORK_TOOLS';
export const LOAD_PMOL_EXTRA_WORK_TOOLS_SUCCESS = 'uprince/LOAD_PMOL_EXTRA_WORK_TOOLS_SUCCESS';
export const LOAD_PMOL_EXTRA_WORK_TOOLS_FAIL = 'uprince/LOAD_PMOL_EXTRA_WORK_TOOLS_FAIL';

export const LOAD_PMOL_EXTRA_WORK_CONSUMABLE = 'uprince/LOAD_PMOL_EXTRA_WORK_CONSUMABLE';
export const LOAD_PMOL_EXTRA_WORK_CONSUMABLE_SUCCESS = 'uprince/LOAD_PMOL_EXTRA_WORK_CONSUMABLE_SUCCESS';
export const LOAD_PMOL_EXTRA_WORK_CONSUMABLE_FAIL = 'uprince/LOAD_PMOL_EXTRA_WORK_CONSUMABLE_FAIL';

export const LOAD_PMOL_EXTRA_WORK_LABOURS = 'uprince/LOAD_PMOL_EXTRA_WORK_LABOURS';
export const LOAD_PMOL_EXTRA_WORK_LABOURS_SUCCESS = 'uprince/LOAD_PMOL_EXTRA_WORK_LABOURS_SUCCESS';
export const LOAD_PMOL_EXTRA_WORK_LABOURS_FAIL = 'uprince/LOAD_PMOL_EXTRA_WORK_LABOURS_FAIL';

export const LOAD_PMOL_EXTRA_WORK_RESOURCES = 'uprince/LOAD_PMOL_EXTRA_WORK_RESOURCES';
export const LOAD_PMOL_EXTRA_WORK_RESOURCES_SUCCESS = 'uprince/LOAD_PMOL_EXTRA_WORK_RESOURCES_SUCCESS';
export const LOAD_PMOL_EXTRA_WORK_RESOURCES_FAIL = 'uprince/LOAD_PMOL_EXTRA_WORK_RESOURCES_FAIL';


export const SAVE_EXTRA_WORK_MATERIALS = 'uprince/SAVE_EXTRA_WORK_MATERIALS';
export const SAVE_EXTRA_WORK_MATERIALS_SUCCESS = 'uprince/SAVE_EXTRA_WORK_MATERIALS_SUCCESS';
export const SAVE_EXTRA_WORK_MATERIALS_FAIL = 'uprince/SAVE_EXTRA_WORK_MATERIALS_FAIL';

export const SAVE_EXTRA_WORK_TOOLS = 'uprince/SAVE_EXTRA_WORK_TOOLS';
export const SAVE_EXTRA_WORK_TOOLS_SUCCESS = 'uprince/SAVE_EXTRA_WORK_TOOLS_SUCCESS';
export const SAVE_EXTRA_WORK_TOOLS_FAIL = 'uprince/SAVE_EXTRA_WORK_TOOLS_FAIL';

export const SAVE_EXTRA_WORK_CONSUMABLE = 'uprince/SAVE_EXTRA_WORK_CONSUMABLE';
export const SAVE_EXTRA_WORK_CONSUMABLE_SUCCESS = 'uprince/SAVE_EXTRA_WORK_CONSUMABLE_SUCCESS';
export const SAVE_EXTRA_WORK_CONSUMABLE_FAIL = 'uprince/SAVE_EXTRA_WORK_CONSUMABLE_FAIL';

export const SAVE_EXTRA_WORK_LABOURS = 'uprince/SAVE_EXTRA_WORK_LABOURS';
export const SAVE_EXTRA_WORK_LABOURS_SUCCESS = 'uprince/SAVE_EXTRA_WORK_LABOURS_SUCCESS';
export const SAVE_EXTRA_WORK_LABOURS_FAIL = 'uprince/SAVE_EXTRA_WORK_LABOURS_FAIL';

export const READ_PMOL_EXTRA_WORK = 'uprince/READ_PMOL_EXTRA_WORK';
export const READ_PMOL_EXTRA_WORK_SUCCESS = 'uprince/READ_PMOL_EXTRA_WORK_SUCCESS';
export const READ_PMOL_EXTRA_WORK_FAIL = 'uprince/READ_PMOL_EXTRA_WORK_FAIL';

export const SAVE_HANDSHAKE_DOCS = 'uprince/SAVE_HANDSHAKE_DOCS';
export const SAVE_HANDSHAKE_DOCS_SUCCESS = 'uprince/SAVE_HANDSHAKE_DOCS_SUCCESS';
export const SAVE_HANDSHAKE_DOCS_FAIL = 'uprince/SAVE_HANDSHAKE_DOCS_FAIL';

export const SAVE_PMOL_EXTRA_WORK = 'uprince/SAVE_PMOL_EXTRA_WORK';
export const SAVE_PMOL_EXTRA_WORK_SUCCESS = 'uprince/SAVE_PMOL_EXTRA_WORK_SUCCESS';
export const SAVE_PMOL_EXTRA_WORK_FAIL = 'uprince/SAVE_PMOL_EXTRA_WORK_FAIL';

export const LOAD_PMOL_TECH_INSTRUCTION = 'uprince/LOAD_PMOL_TECH_INSTRUCTION';
export const LOAD_PMOL_TECH_INSTRUCTION_SUCCESS = 'uprince/LOAD_PMOL_TECH_INSTRUCTION_SUCCESS';
export const LOAD_PMOL_TECH_INSTRUCTION_FAIL = 'uprice/LOAD_PMOL_TECH_INSTRUCTION_FAIL';

export const LOAD_PMOL_SAFTY_INSTRUCTION = 'uprince/LOAD_PMOL_SAFTY_INSTRUCTION';
export const LOAD_PMOL_SAFTY_INSTRUCTION_SUCCESS = 'uprince/LOAD_PMOL_SAFTY_INSTRUCTION_SUCCESS';
export const LOAD_PMOL_SAFTY_INSTRUCTION_FAIL = 'uprice/LOAD_PMOL_SAFTY_INSTRUCTION_FAIL';

export const LOAD_PMOL_ENV_INSTRUCTION = 'uprince/LOAD_PMOL_ENV_INSTRUCTION';
export const LOAD_PMOL_ENV_INSTRUCTION_SUCCESS = 'uprince/LOAD_PMOL_ENV_INSTRUCTION_SUCCESS';
export const LOAD_PMOL_ENV_INSTRUCTION_FAIL = 'uprice/LOAD_PMOL_ENV_INSTRUCTION_FAIL';

export const LOAD_PMOL_HELTH_INSTRUCTION = 'uprince/LOAD_PMOL_HELTH_INSTRUCTION';
export const LOAD_PMOL_HELTH_INSTRUCTION_SUCCESS = 'uprince/LOAD_PMOL_HELTH_INSTRUCTION_SUCCESS';
export const LOAD_PMOL_HELTH_INSTRUCTION_FAIL = 'uprice/LOAD_PMOL_HELTH_INSTRUCTION_FAIL';

export const LOAD_PMOL_DETAILS = 'uprince/LOAD_PMOL_DETAILS';
export const LOAD_PMOL_DETAILS_SUCCESS = 'uprince/LOAD_PMOL_DETAILS_SUCCESS';
export const LOAD_PMOL_DETAILS_FAIL = 'uprice/LOAD_PMOL_DETAILS_FAIL';

export const LOAD_PMOL_ALL_INSTRUCTION = 'uprince/LOAD_PMOL_ALL_INSTRUCTION';
export const LOAD_PMOL_ALL_INSTRUCTION_SUCCESS = 'uprince/LOAD_PMOL_ALL_INSTRUCTION_SUCCESS';
export const LOAD_PMOL_ALL_INSTRUCTION_FAIL = 'uprice/LOAD_PMOL_ALL_INSTRUCTION_FAIL';


export const CLONE_PMOL = 'uprince/CLONE_PMOL';
export const CLONE_PMOL_SUCCESS = 'uprince/CLONE_PMOL_SUCCESS';
export const CLONE_PMOL_FAIL = 'uprice/CLONE_PMOL_FAIL';

//Load Team Members
export const LOAD_PMOL_LABOUR_TEAM_MEMBERS = 'uprince/LOAD_PMOL_LABOUR_TEAM_MEMBERS';
export const LOAD_PMOL_LABOUR_TEAM_MEMBERS_SUCCESS = 'uprince/LOAD_PMOL_LABOUR_TEAM_MEMBERS_SUCCESS';
export const LOAD_PMOL_LABOUR_TEAM_MEMBERS_FAIL = 'uprice/LOAD_PMOL_LABOUR_TEAM_MEMBERS_FAIL';
//Load Team Members

export const LOAD_PMOL_LABOUR_EXTRA_TEAM_MEMBERS = 'uprince/LOAD_PMOL_LABOUR_EXTRA_TEAM_MEMBERS';
export const LOAD_PMOL_LABOUR_EXTRA_TEAM_MEMBERS_SUCCESS = 'uprince/LOAD_PMOL_LABOUR_EXTRA_TEAM_MEMBERS_SUCCESS';
export const LOAD_PMOL_LABOUR_EXTRA_TEAM_MEMBERS_FAIL = 'uprice/LOAD_PMOL_LABOUR_EXTRA_TEAM_MEMBERS_FAIL';

export const DELETE_PMOL_LABOUR_TEAM_MEMBERS = 'uprince/DELETE_PMOL_LABOUR_TEAM_MEMBERS';
export const DELETE_PMOL_LABOUR_TEAM_MEMBERS_SUCCESS = 'uprince/DELETE_PMOL_LABOUR_TEAM_MEMBERS_SUCCESS';
export const DELETE_PMOL_LABOUR_TEAM_MEMBERS_FAIL = 'uprice/DELETE_PMOL_LABOUR_TEAM_MEMBERS_FAIL';

export const READ_PMOL_LOCATION = 'uprince/READ_PMOL_LOCATION';
export const READ_PMOL_LOCATION_SUCCESS = 'uprince/READ_PMOL_LOCATION_SUCCESS';
export const READ_PMOL_LOCATION_FAIL = 'uprince/READ_PMOL_LOCATION_FAIL';

export const READ_PMOL_RISK = 'uprince/READ_PMOL_RISK';
export const READ_PMOL_RISK_SUCCESS = 'uprince/READ_PMOL_RISK_SUCCESS';
export const READ_PMOL_RISK_FAIL = 'uprice/READ_PMOL_RISK_FAIL';

export const LOAD_PMOL_QUALITY = 'uprince/LOAD_PMOL_QUALITY';
export const LOAD_PMOL_QUALITY_SUCCESS = 'uprince/LOAD_PMOL_QUALITY_SUCCESS';
export const LOAD_PMOL_QUALITY_FAIL = 'uprice/LOAD_PMOL_QUALITY_FAIL';

export const APPROVED_PMOL = 'uprince/APPROVED_PMOL';
export const APPROVED_PMOL_SUCCESS = 'uprince/APPROVED_PMOL_SUCCESS';
export const APPROVED_PMOL_FAIL = 'uprice/APPROVED_PMOL_FAIL';

export const FINISHED_PMOL = 'uprince/FINISHED_PMOL';
export const FINISHED_PMOL_SUCCESS = 'uprince/FINISHED_PMOL_SUCCESS';
export const FINISHED_PMOL_FAIL = 'uprice/FINISHED_PMOL_FAIL';

export const SHOW_PROGRESSBAR_PMOL = 'uprice/SHOW_PROGRESSBAR_PMOL';
export const HIDE_PROGRESSBAR_PMOL = 'uprice/HIDE_PROGRESSBAR_PMOL';

export const LOAD_PMOL_PDF_DETAILS = 'uprince/LOAD_PMOL_PDF_DETAILS';
export const LOAD_PMOL_PDF_DETAILS_SUCCESS = 'uprince/LOAD_PMOL_PDF_DETAILS_SUCCESS';
export const LOAD_PMOL_PDF_DETAILS_FAIL = 'uprice/LOAD_PMOL_PDF_DETAILS_FAIL';

export const CREATE_PMOL_SERVICE = 'uprince/CREATE_PMOL_SERVICE';
export const CREATE_PMOL_SERVICE_SUCCESS = 'uprince/CREATE_PMOL_SERVICE_SUCCESS';
export const CREATE_PMOL_SERVICE_FAIL = 'uprice/CREATE_PMOL_SERVICE_FAIL';

export const READ_PMOL_SERVICE = 'uprince/READ_PMOL_SERVICE';
export const READ_PMOL_SERVICE_SUCCESS = 'uprince/READ_PMOL_SERVICE_SUCCESS';
export const READ_PMOL_SERVICE_FAIL = 'uprice/READ_PMOL_SERVICE_FAIL';
export const SAVE_PMOL_STATE_ATTR = 'uprice/SAVE_PMOL_STATE_ATTR';

export const UPDATE_PMOL_LABOUR_END_TIME = 'uprince/UPDATE_PMOL_LABOUR_END_TIME';
export const UPDATE_PMOL_LABOUR_END_TIME_SUCCESS = 'uprince/UPDATE_PMOL_LABOUR_END_TIME_SUCCESS';
export const UPDATE_PMOL_LABOUR_END_TIME_FAIL = 'uprince/UPDATE_PMOL_LABOUR_END_TIME_FAIL';

export const CREATE_PMOL_CLONE_FOR_MULTIPLE_DAYS = 'uprince/CREATE_PMOL_CLONE_FOR_MULTIPLE_DAYS';
export const CREATE_PMOL_CLONE_FOR_MULTIPLE_DAYS_SUCCESS = 'uprince/CREATE_PMOL_CLONE_FOR_MULTIPLE_DAYS_SUCCESS';
export const CREATE_PMOL_CLONE_FOR_MULTIPLE_DAYS_FAIL = 'uprince/CREATE_PMOL_CLONE_FOR_MULTIPLE_DAYS_FAIL';



export const READ_CBC_TO_PMOL = 'uprince/READ_CBC_TO_PMOL';
export const READ_CBC_TO_PMOL_SUCCESS = 'uprince/READ_CBC_TO_PMOL_SUCCESS';
export const READ_CBC_TO_PMOL_FAIL = 'uprice/READ_CBC_TO_PMOL_FAIL';
export const READ_PMOL_CBC_CONTRACTOR = 'uprince/READ_PMOL_CBC_CONTRACTOR';
export const READ_PMOL_CBC_CONTRACTOR_SUCCESS = 'uprince/READ_PMOL_CBC_CONTRACTOR_SUCCESS';
export const READ_PMOL_CBC_CONTRACTOR_FAIL = 'uprice/READ_PMOL_CBC_CONTRACTOR_FAIL';

export const SAVE_PMOL_CBC_TO_PBS = 'uprince/SAVE_PMOL_CBC_TO_PBS';
export const SAVE_PMOL_CBC_TO_PBS_SUCCESS = 'uprince/SAVE_PMOL_CBC_TO_PBS_SUCCESS';
export const SAVE_PMOL_CBC_TO_PBS_FAIL = 'uprice/SAVE_PMOL_CBC_TO_PBS_FAIL';

export const REMOVE_PMOL_CBC_TO_PBS = 'uprince/REMOVE_PMOL_CBC_TO_PBS';
export const REMOVE_PMOL_CBC_TO_PBS_SUCCESS = 'uprince/REMOVE_PMOL_CBC_TO_PBS_SUCCESS';
export const REMOVE_PMOL_CBC_TO_PBS_FAIL = 'uprice/REMOVE_PMOL_CBC_TO_PBS_FAIL';

export const GET_PBS_CONTRACTOR = 'uprince/GET_PBS_CONTRACTOR';
export const GET_PBS_CONTRACTOR_SUCCESS = 'uprince/GET_PBS_CONTRACTOR_SUCCESS';
export const GET_PBS_CONTRACTOR_FAIL = 'uprice/GET_PBS_CONTRACTOR_FAIL';

import React, { useEffect } from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { messageService } from '../../../../services/messageService';
import { Switch } from '@fluentui/react-components';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import i18n from '../../../../../i18n';
import { getCheckedString } from '../../../../types/wbs';

/**
 * WBSCreateCmdBarLeft component represents the left section of the command bar in the Work Breakdown Structure (WBS) creation interface.
 * It includes functionality for expanding/collapsing the WBS and creating new templates.
 *
 * @param {boolean} isTemplate - Indicates whether the current WBS is a template.
 * @returns {JSX.Element} - Rendered React component.
 */
const WBSCreateCmdBarLeft = ({ isTemplate }: { isTemplate: boolean }): JSX.Element => {

  /** Do Not change the value of defaultOpenItems here. It's handle in wbsCreateListPane component. Only Use it here */
  const defaultOpenItems = useSelector((state: any) => state.wbs.defaultOpenItems);
  const wbsTemplateTaxonomyData = useSelector((state: any) => state.wbsCreate.wbsTemplateTaxonomyData);

  const [expand, setExpand] = React.useState(false);

  useEffect(() => {
    const handleCollapse = async () => {
      try {
        if (defaultOpenItems && !_.isEmpty(defaultOpenItems)) {
          setExpand(true);
        } else {
          setExpand(false);
        }
      } catch (error) {
        console.error('Error fetching defaultOpenItems:', error);
      }
    };

    handleCollapse().then();
  }, [defaultOpenItems]);

  const checkedString = getCheckedString(expand);
  const onChangeSwitch = React.useCallback((ev) => {
    messageService.sendMessage({ isWbsExpand: { status: ev.currentTarget.checked } });
  }, [setExpand]);

  const getItems = () => {
    const newBtn = {
      key: 'newWbs',
      text: i18n.t('createTemplate'),
      iconProps: { iconName: 'Add' },
      onClick: () => {
        messageService.sendMessage({ isNewWbs: true });
      },
      disabled: false,
    };

    return !isTemplate ? [newBtn] : [];
  };

  return <div style={{ display: 'flex', flexDirection: `${isTemplate ? 'row-reverse' : 'row'}` }}>
    <CommandBar
      styles={{ root: { height: 26 } }}
      items={getItems()}
      ariaLabel='Use left and right arrow keys to navigate between commands'
    />
    {isTemplate && <div style={{ display: 'flex' }}>
      <Switch
        checked={expand}
        label={checkedString}
        labelPosition='after'
        onChange={onChangeSwitch}
        disabled={!(wbsTemplateTaxonomyData && !_.isEmpty(wbsTemplateTaxonomyData))}
      />
    </div>
    }
  </div>;
};

export default WBSCreateCmdBarLeft;

import { combineReducers } from 'redux';
import organizationReducer from './organizationReducer';
import projectReducer from './projectReducer';
import uprinceResucer from './uprinceReducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import timeClockReducer from './timeClockReducer';
import qrCodeReducer from './qrCodeReducer';
import productCatalogueReducer from './productCatalogueReducer';
import addressBookReducer from './addressBookReducer';
import projectBreakdownReducer from './projectBreakdownReducer';
import cuProjectReducer from './cuProjectReducer';
import billOfResourcesReducer from './billOfResourcesReducer';
import pmolReducer from './pmolReducer';
import psReducer from './progressStatementReducer';
import invoiceReducer from './invoiceReducer';
import languageReducer from './languageReducer';
import ciawReducer from './ciawReducer';
// import templateReducer from './templateReducer';
import warehouseReducer from './warehouseReducer';
import purchaseRequestReducer from './purchaseOrderReducer';
import dropdownTranslationReducer from './dropdownTranslationReducer';
import workflowReducer from './workFlowReducer';
import stockReducer from './stockReducer';
import visualPlanReducer from './visualPlanReducer';
import organisationSettingReducer from './organisationSettingReducer';
import competencyReducer from './competencyReducer';
import certificationReducer from './certificationReducer';
import corporateScheduleReducer from './corporateScheduleReducer';
import humanResourceReducer from './humanResourceReducer';
import dayPlanningReducer from './dayPlanningReducer';
import mileStonesReducer from './mileStonesReducer';
import riskRegisterReducer from './riskRegisterReducer';
import qualityReducer from './qualityReducer';
import instructionRegisterReducer from './instructionRegisterReducer';
import organisationCBCReducer from './organisationCBCReducer';
import contractReducer from './contractReducer';
import standardMailReducer from './standardMailReducer';
import lotInvitationReducer from './lotInvitationReducer';
import myDayPlanningReducer from './myDayPlanningReducer';
import projectDayPlanningReducer from './projectDayPlanningReducer';
import timeRegistrationReducer from './timeRegistrationReducer';
import thAutomationReducer from './thAutomationReducer';
import burnDownChartReducer from './bdcReducer';
import cuMyCalReducer from './cuMyCalendarReducer';
import wbsReducer from './wbsReducer';
import wbsCreateReducer from './wbsCreateReducer';
import wbsTaskReducer from './wbsTaskReducer';
import wbsCuTaskReducer from './wbsCuTaskReducer';
import wbsIssueReducer from './wbsIssueReducer';

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  whitlist: ['uprince'],
};

const uprincePersistConfig = {
  key: 'uprince',
  storage: storage,
  blacklist: ['somethingTemporary'],
};

const rootReducer = combineReducers({
  organization: organizationReducer,
  project: projectReducer,
  timeClock: timeClockReducer,
  qrCode: qrCodeReducer,
  productCatalogue: productCatalogueReducer,
  addressBook: addressBookReducer,
  projectBreakdown: projectBreakdownReducer,
  cuProject: cuProjectReducer,
  billOfResource: billOfResourcesReducer,
  pmol: pmolReducer,
  ps: psReducer,
  invoice: invoiceReducer,
  lang: languageReducer,
  // temp: templateReducer,
  warehouse: warehouseReducer,
  po: purchaseRequestReducer,
  dropdownTranslation: dropdownTranslationReducer,
  wf: workflowReducer,
  stock: stockReducer,
  vp: visualPlanReducer,
  os: organisationSettingReducer,
  comp: competencyReducer,
  cert: certificationReducer,
  cs: corporateScheduleReducer,
  hr: humanResourceReducer,
  dplan: dayPlanningReducer,
  ms: mileStonesReducer,
  rr: riskRegisterReducer,
  ql: qualityReducer,
  ir: instructionRegisterReducer,
  cbc: organisationCBCReducer,
  contract: contractReducer,
  sm: standardMailReducer,
  mydPlan: myDayPlanningReducer,
  projectDPlan: projectDayPlanningReducer,
  lotInvitation: lotInvitationReducer,
  ciaw: ciawReducer,
  timeRegistration: timeRegistrationReducer,
  thAutomation: thAutomationReducer,
  bdc: burnDownChartReducer,
  cuMyCal: cuMyCalReducer,
  wbs: wbsReducer,
  wbsCreate: wbsCreateReducer,
  wbsTask: wbsTaskReducer,
  wbsCuTask: wbsCuTaskReducer,
  wbsIssue: wbsIssueReducer,
  uprince: persistReducer(uprincePersistConfig, uprinceResucer),

});

export default persistReducer(rootPersistConfig, rootReducer);

import {
  Breadcrumb,
  DetailsListLayoutMode,
  DetailsRow,
  Dropdown,
  Fabric,
  IColumn,
  IDetailsFooterProps,
  IDetailsListProps,
  IDetailsRowStyles,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
  Text,
  TextField,
} from '@fluentui/react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getBreadcrumbItemFromData } from '../../../shared/util';
import { ListPaneItem, ReadRisk } from '../../../types/projectBreakdownStructure';
import { StockDetails, StockShotcutPaneFilter } from '../../../types/stock';

export const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 25vh)!important',
    position: 'relative',
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: { margin: 0 },
  row: { display: 'inline-block' },
});

const breadcrumbStyles = {
  root: {
    fontSize: 14,
    marginTop: -6,
  },
  item: { fontSize: 14 },
  list: { fontSize: 14 },
  listItem: { fontSize: 14 },
  chevron: { fontSize: 14 },
  overflow: { fontSize: 14 },
  overflowButton: { fontSize: 14 },
  itemLink: { fontSize: 14 },
};

const ListPaneComponent = (props: {
  filter: StockShotcutPaneFilter;
  selection: any;
  columns: IColumn[];
  listPaneItems: ListPaneItem[];
  handleListPaneItemClick: (id: string) => void;
  handleFilterChange: (filter: StockShotcutPaneFilter) => void;
  isDataLoaded?: boolean;
  loadMsg: string;
  resetSelection: () => void;
  formData: StockDetails;
  dropDownOptions: any;
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<StockShotcutPaneFilter>(props.filter);
  const getDropdownWithDefualt = (options: any) => {
    if (options) {
      const defualtOption = [
        {
          key: '0',
          text: t('all'),
          isDefault: true,
        },
      ];
      Array.prototype.push.apply(defualtOption, options);
      return defualtOption;
    }
    return [];
  };

  useEffect(() => {
    setFilter(props.filter);
  }, [props.filter]);

  const _onRenderRow: IDetailsListProps['onRenderRow'] = (row) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (row) {
      return (
        <span
          style={{ textDecoration: 'none' }}
          onClick={() => {
            // chnage item id name
            props.handleListPaneItemClick(row.item.sequenceId);
          }}
        >
                    <DetailsRow {...row} styles={customStyles}/>
                </span>
      );
    }
    return null;
  };

  const _onRenderDetailsFooter = (
    detailsFooterProps: IDetailsFooterProps | undefined,
  ) => {
    return (
      <DetailsRow
        className="footer-as-filter"
        {...detailsFooterProps}
        columns={detailsFooterProps?.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps?.groupNestingDepth}
        selection={detailsFooterProps?.selection}
        onRenderItemColumn={_renderFilterItemColumn}
        // onRenderCheck={_onRenderCheckForFooterRow}
      />
    );
  };


  const _renderFilterItemColumn = (
    item?: ListPaneItem,
    index?: number,
    column?: IColumn,
  ) => {
    switch (column?.key) {
      case 'column1': {
        return (
          <div>
            <TextField
              value={filter?.title ? filter?.title.toString() : ''}
              onChange={(event, value) => {
                if (value) {
                  // setFilter((prevState) => ({
                  //     ...prevState,
                  //     title: value
                  // }));'
                  props.filter.title = value;
                } else {
                  props.filter.title = null;
                }
                props.handleFilterChange(filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column2': {
        return (
          <div>
            <Dropdown
              placeholder={t('type')}
              options={getDropdownWithDefualt(props.dropDownOptions?.types)}
              selectedKey={props.filter?.type ? props.filter?.type : '0'}
              onChange={(event, value) => {
                if (value) {
                  props.filter.type =
                    value.key !== '0' ? value.key.toString() : null;
                } else {
                  props.filter.type = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column3': {
        return (
          <div>
            <Dropdown
              placeholder={t('status')}
              options={getDropdownWithDefualt(props.dropDownOptions?.status)}
              selectedKey={props.filter?.status ? props.filter?.status : '0'}
              onChange={(event, value) => {
                if (value) {
                  props.filter.status =
                    value.key !== '0' ? value.key.toString() : null;
                } else {
                  props.filter.status = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      case 'column5': {
        return (
          <div>
            <TextField
              value={props.filter?.wareHouse ? props.filter?.wareHouse : ''}
              onChange={(event, value) => {
                if (value) {
                  props.filter.wareHouse = value;
                } else {
                  props.filter.wareHouse = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
        break;
      }
      
      default: {
        break;
      }
    }
  };

  const onRenderItemColumn = (item: any, index?: number, column?: IColumn, warehouseTaxonomy?: any) => {
    if (column && column.fieldName && column.fieldName === 'warehouseTaxonomy') {
      return <span>
                <div style={{ height: 20 }}>
                    { /* {JSON.stringify(item['wareHouseTaxonomyId'])}*/}
                  <Breadcrumb
                    items={getBreadcrumbItemFromData(warehouseTaxonomy, item.wareHouseTaxonomyId)}
                    ariaLabel="With last item rendered as heading"
                    overflowAriaLabel="More links"
                    styles={breadcrumbStyles}
                  />
                </div></span>;
    } else if (column && column.fieldName) {
      return <span>{item[column.fieldName]}</span>;
    }
    return <span style={{ paddingLeft: 9 }}>-</span>;
  };

  const displayMessage = () => {
    return (
      <div style={{ paddingTop: 36, paddingLeft: 20 }}>
        <Text>{t(props.loadMsg)}</Text>

      </div>
    );
  };
  return (
    <Fabric>
      <div className={`wrapper-holder ${classNames.wrapper}`}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <ShimmeredDetailsList
            items={props.listPaneItems}
            columns={props.columns}
            setKey="set"
            enableShimmer={!props.isDataLoaded}
            layoutMode={DetailsListLayoutMode.justified}
            selectionPreservedOnEmptyClick={true}
            checkboxVisibility={1}
            onRenderRow={_onRenderRow}
            onRenderDetailsFooter={_onRenderDetailsFooter}
            selection={props.selection}
            selectionMode={SelectionMode.single}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            onRenderItemColumn={(
              item: ReadRisk,
              index?: number,
              column?: IColumn,
            ) => onRenderItemColumn(item, index, column, props.dropDownOptions.warehouseTaxonomy)}
            onRenderDetailsHeader={(headerProps, defaultRender) => {
              if (defaultRender !== undefined) {
                return (
                  <Sticky
                    stickyPosition={StickyPositionType.Header}
                    isScrollSynced={true}
                    stickyBackgroundColor="transparent"
                  >
                    <div>{defaultRender(headerProps)}</div>
                  </Sticky>
                );
              }
              return <span></span>;
            }}
          />
          {props.isDataLoaded && displayMessage()}
        </ScrollablePane>
      </div>
    </Fabric>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    toleranceStatesFilter: state.projectBreakdown.toleranceStatesFilter,
    productStatesFilter: state.projectBreakdown.productStatesFilter,
    itemTypesFilter: state.projectBreakdown.itemTypesFilter,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ListPaneComponent);

import React, { useEffect, useState } from 'react';
import {
  ActionButton,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Separator,
  Stack,
  Text,
  TextField,
  Dropdown
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../../theme';
import { ProgressStatement, ProgressStatementValidationMessage } from '../../../../types/progressStatement';

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const theme = getTheme();

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const dropdownStyles = { dropdown: { width: '100%', height: '50%' } };

// Styles definition
const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};


const classNames = mergeStyleSets({
  fullWidth: {
    width: '100%',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  bigScreenTree: {
    marginBottom: 30,
    height: '30vh',
  },
  smallScreenTree: {
    marginBottom: 30,
    height: '56vh',
  },
  textEditor: {
    height: window.innerWidth <= 1280 ? '50vh' : '25vh',
  },
  customerLabel: {
    paddingTop: 4,
    paddingBottom: 0,
  },
});

const stackFooterBarItemStyles: IStackItemStyles = {
  root: {
    height: 45,
  },
};
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };


const CustomerInformation = (props: {
  isEdit: boolean,
  dropDownOptions: any,
  formData: ProgressStatement;
  saveFormData: () => void;

  handleFormChange: (
    progressStatement: ProgressStatement,
    save: boolean,
  ) => void;
  validationMessages: ProgressStatementValidationMessage;
  customerInformationdropdown: { key: string, text: string ,customerContactPerson: string,
    customerContactPersonId: string,
    customerContactPersonEmailId: string,
    customerContactPersonEmail: string, }[];
  customerName: any
  customerContactPerson:any
  customerContactPersonEmail:any
  setCustomerName: (id: any) => void;
  setcontactPersonName:(id: any) => void;
  setcontactPersonEmail:(id: any) => void;
}) => {
  const { t } = useTranslation();
  const customerDetails = {
    customerName: null,
    contactPersonName: null,
    contactPersonEmail: null,
    purchaseOrderNumber: null,
  };
  const [customer, setCustomer]: any = useState(customerDetails);

  useEffect(() => {
    if (props.customerInformationdropdown) {
     
      setCustomer(props.customerInformationdropdown);
      if(props.customerName == null){
        props?.setCustomerName(props.customerInformationdropdown[0])
        props?.setcontactPersonName(props.customerInformationdropdown[0])
        props?.setcontactPersonEmail(props.customerInformationdropdown[0])
      }
      
    }
  }, [props.dropDownOptions]);
  return (
    <div className={'document-pane-card'}>

      <div className={'card-header'}>
        <Link href="#" id="ps-customer-information">
          <Label>2. {t('customerInformation')} </Label>
        </Link>
      </div>

      <div className={'card-body'}>
        <div className="proj-detail-content inner">

          <div className="marginTop">
            <div className={'ms-Grid'} style={{ width: '100%', height: '100%', padding: 16 }}>
              <div className="ms-Grid-row">
                <div className={`ms-Grid-col ms-sm12 ms-md12 padding-left-0`}>
                  <div className={`${classNames.fullWidth}`} style={{ fontSize: 10 }}>
                    {/* <Label
                      className={`${classNames.customerLabel}`}>{t('customerName')} : <Text> {customer.customerName} </Text></Label>
                    <Label
                      className={`${classNames.customerLabel}`}>{t('customerContactPersonName')} : <Text>  {customer.contactPersonName}</Text></Label>
                    <Label
                      className={`${classNames.customerLabel}`}>{t('customerContactPersonEmail')} : <Text>  {customer.contactPersonEmail} </Text></Label> */}
                  </div>
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className={`ms-Grid-col ms-sm12 ms-md6 padding-left-0`}>
                  <div className={`${classNames.fullWidth}`}>
                    <Dropdown
                      placeholder={t('selectanoption')}
                      label={t('customerName')}
                      options={props.customerInformationdropdown}
                      selectedKey={
                        props.customerName
                      }
                      disabled={props.dropDownOptions.isApproved}
                      styles={dropdownStyles}
                      onChange={(value:any, values:any) => {
                        if (values) {
                          props.setCustomerName(values)
                          props.setcontactPersonName(values)
                          props.setcontactPersonEmail(values)
                        } else {
                          props.setCustomerName(null)
                          props.setcontactPersonName('')
                          props.setcontactPersonEmail('')
                        }
                       }}
                    />
                    <TextField
                      disabled={true}
                      label={t('customerContactPersonName')}
                      value={ props.customerContactPerson
                          ? props.customerContactPerson
                          : '' }
                    />
                    <TextField
                      disabled={true}
                      label={t('customerContactPersonEmail')}
                      value={
                        props.customerContactPersonEmail
                          ? props.customerContactPersonEmail
                          : ''
                      }
                     
                    />
                    <TextField
                      disabled={props.dropDownOptions.isApproved}
                      label={t('customerPurchaseordernumber')}
                      value={
                        props.formData.purchaseOrderNumber
                          ? props.formData.purchaseOrderNumber
                          : ''
                      }
                      onChange={(event, value) => {
                        if (value) {
                          props.formData.purchaseOrderNumber = value;
                          //props.validationMessages.nameErrorMsg="";
                          setCustomer((prevState: any) => ({
                            ...prevState,
                            purchaseOrderNumber: value,
                          }));
                        } else {
                          props.formData.purchaseOrderNumber = null;
                          setCustomer((prevState: any) => ({
                            ...prevState,
                            purchaseOrderNumber: null,
                          }));
                        }
                        props.handleFormChange(
                          props.formData,
                          true,
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className={'card-footer'}>
        <Separator />
        <Stack
          styles={stackStyles}
          tokens={itemAlignmentsStackTokens}>
          <Stack.Item align="end" styles={stackFooterBarItemStyles}>
            <ActionButton
              disabled={props.dropDownOptions.isApproved ? true : (!(props.formData.isSaved || props.formData.progressStatementId))}
              className={classNames.actionButton}
              iconProps={saveIcon}
              allowDisabledFocus
              onClick={() => {
                props.saveFormData();
              }}
            >
              <Label className={classNames.actionButton}>{t('save')} </Label>
            </ActionButton>
          </Stack.Item>
        </Stack>
      </div>

    </div>
  );
};


export default CustomerInformation;

export class MileStonesListPaneFilter {
  typeId: string[] | null = [];
  title: string | null = null;
  startDate: any = null;
  endDate: any = null;
  sorter: Sorter = new Sorter();
}

export interface MileStonesShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
  value: number | null
}

class Sorter {
  attribute: string | null = null;
  order: string | null = null;
}

export class MileStonesListItem {
  id: string | null = null;
  name: string | null = null;
  sequenceId: string | null = null;
  title: string | null = null;
}

export interface DropDown {
  key: string;
  text: string;
}

export interface MileStonesDropDowns {
  status: DropDown[];
  types: DropDown[];
}

export interface MileStones {
  id: string | null;
  title: string;
  sequenceId: string;
  name: string | null;
  type: string | null;
  status: string | null;
  abstract: string | null;
  fullDescription: string | null;
  utilityTaxonomy: string | null;
  locationTaxonomy: string | null;
  machineTaxonomy: string | null;
  accountable: string | null;
  responsible: string | null;
  requestedStartDate: string | null;
  requestedEndDate: string | null;
  expectedStartDate: string | null;
  expectedEndDate: string | null;
  actualStartDate: string | null;
  actualEndDate: string | null;
  expectedAmount: string | null;
  actualAmount: string | null;
  comments: string | null;
  files: any[];
}

export interface MSTONEDetails {
  id: string | null;
  sequenceId: string | null;
  title: string | null;
  name: string | null;
}

export interface History {
  createdByUser: string | null;
  createdDateTime: string | null;
  revisionNumber: number | string | null;
  updatedByUser: string | null;
  updatedDateTime: string | null;
}

export const formData = {
  id: null,
  title: 'string',
  sequenceId: 'string',
  name: 'string',
  type: null,
  status: null,
  abstract: 'string',
  fullDescription: 'string',
  utilityTaxonomy: null,
  locationTaxonomy: null,
  machineTaxonomy: null,
  accountable: null,
  responsible: null,
  requestedStartDate: null,
  requestedEndDate: null,
  expectedStartDate: null,
  expectedEndDate: null,
  actualStartDate: null,
  actualEndDate: null,
  expectedAmount: null,
  actualAmount: null,
  comments: 'string',
  files: [],
};


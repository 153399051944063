import React from 'react';
import {useTranslation} from 'react-i18next';
import {saveProjectDayPlanningStateAttr} from "../../../reducers/projectDayPlanningReducer";
import {useDispatch} from "react-redux";
import {PrimaryButton} from "@fluentui/react";
import './command-bar-style.css'

interface CommandBarLeftProps {
    isMyCalendarTop: boolean;
}

const CommandBarLeft = (props: CommandBarLeftProps) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    if (props.isMyCalendarTop) {
        return (
          <div className={"btn-wrapper"}>
              <PrimaryButton text={t('newProject')} disabled={true} onClick={
                  () => {
                      dispatch(saveProjectDayPlanningStateAttr('isNewProjectModalOpen', true))
                  }
              }/>
          </div>
        );
    } else {
        return (
          <div className={"btn-wrapper"}></div>
        );
    }
};

export default CommandBarLeft;

import { History } from './history';

export class AddressBookItem {
  id: string | null = null;
  person: Person = new Person();
  companyId: string | null = null;
  contractorTaxonomyId: any[] = [];
  personCompany: PersonCompany = new PersonCompany();
  isSaved: boolean = false;
  company: Company = new Company();
  history: History | null = new History();

  constructor(
    person: Person,
    companyId: string | null,
    personCompany: PersonCompany,
  ) {
  }
}

export class PersonCompany {
  id: string | null = null;
  jobRole: string | null = null;
  email: string | null = null;
  mobilePhone: string | null = null;
  landPhone: string | null = null;
  whatsapp: string | null = null;
  skype: string | null = null;
  joinedDate: string | null = null;
  resignedDate: string | null = null;
  personId: string | null = null;
  companyId: string | null = null;
  jobTitle: string | null = null;
  mobileNumber: string | null = null;
}

export class Company {
  id: string | null = null;
  name: string = '';
  vat: string = '';
  bankAccount: string = '';
  address: Address = new Address();
  email: string | null = null;
  landPhone: string | null = null;
  mobilePhone: string | null = null;
  whatsApp: string | null = null;
  skype: string | null = null;
  accountingNumber: string | null = null;
  key: string = '';
  isSaved: boolean = true;
  contractorTaxonomyId: any = null;
}

export class Person {
  id: string | null = null;
  firstName: string = '';
  surname: string | null = null;
  fullName: string | null = null;
  callName: string | null = null;
  image: string | null = null;
  address: Address = new Address();
  businessEmail: string | null = null;
  businessPhone: string | null = null;
  email: string | null = null;
  landPhone: string | null = null;
  mobilePhone: string | null = null;
  salutationId: string | null = null;
  whatsapp: string | null = null;
  skype: string | null = null;
  nationalityId: string | null = null;
  nationality: NationalityDropDownData = new NationalityDropDownData();
}

export class Address {
  id: string | null = null;
  street: string | null = null;
  streetNumber: string | null = null;
  mailBox: string | null = null;
  postalCode: string | null = null;
  city: string | null = null;
  region: string | null = null;
  countryId: string | null = null;
}

export class CABFilter {
  fullName: string | null = null;
  organisation: string | null = null;
  mobileNumber: string | null = null;
  email: string | null = null;
  jobTitle: string | null = null;
  isSaved: boolean | null = null;
  companyId?: string | null = null;
  lotId?: string | null = null;
  cabPersonSortingModel: CabPersonSortingModel = new CabPersonSortingModel();
  parentId?: string|null = null;
  comId?: string|null = null;
  buId?: string|null = null;
  teamId?: string|null = null;
}

class CabPersonSortingModel {
  attribute: string | null = null;
  order: string | null = null;
}

export class UniqContact {
  email: string | null = null;
  landPhone: string | null = null;
  mobilePhone: string | null = null;
  skype: string | null = null;
  whatsApp: string | null = null;
}

export class NationalityDropDownData {
  key: string = '';
  text: string = '';
}

import React, { useEffect, useState } from 'react';
import {
  ActionButton,
  DirectionalHint,
  getTheme,
  IBasePickerSuggestionsProps,
  ICalloutProps,
  IIconProps,
  IPersona,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  ITooltipHostStyles,
  Label,
  Link,
  mergeStyleSets,
  NormalPeoplePicker,
  Separator,
  Stack,
  TooltipDelay,
  TooltipHost,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../theme';
import _ from 'lodash';
import {
  ProjectBreakdown,
  ProjectBreakdownValidationMessage,
} from '../../../types/projectBreakdownStructure';
import { Tooltip } from 'office-ui-fabric-react';

const theme = getTheme();

// Styles definition
const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
    width: '50%',
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};
// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const hostStyles: Partial<ITooltipHostStyles> = {root: {display: 'inline-block'}};

const calloutProps: Partial<ICalloutProps> = {
  styles: {
    root: {
      // maxWidth: 1500, // Adjust this value as needed
      whiteSpace: 'normal', // Allows text to wrap
    },
  },
};

const classNames = mergeStyleSets({
  fullWidth: {
    width: '100%',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  bigScreenTree: {
    marginBottom: 30,
    height: '30vh',
  },
  smallScreenTree: {
    marginBottom: 30,
    height: '56vh',
  },
  textEditor: {
    height: window.innerWidth <= 1280 ? '50vh' : '25vh',
  },
  borderNone: { border: 'none !important'},
  subHeaderStyle: { fontSize: '14px !important' }
});

const itemAlignmentsStackTokens: IStackTokens = {
  padding: 0,
};

// Styles definition
const stackStylesFooter: IStackStyles = {
  root: {
    padding: 0,
    margin: 0,
  },
};
const stackItemStylesFooter: IStackItemStyles = {
  root: {
    padding: 0,
    margin: 0,
  },
};

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const QualityResponsible = (props: {
  onQualityFilterPersonChanged: any;
  isEdit: boolean;
  projectBreakdown: ProjectBreakdown;
  saveFormData: () => void;
  handleFormChange: (projectBreakdown: ProjectBreakdown, save: boolean) => void;
  validationMessages: ProjectBreakdownValidationMessage;
  readOnly: boolean;
}) => {
  const { t } = useTranslation();
  let selectedPerson: IPersona[] = [];

  const [qualityProducer, setQualityProducer] = useState<IPersona[]>([]);
  const [qualityReviewer, setQualityReviewer] = useState<IPersona[]>([]);
  const [qualityApprover, setQualityApprover] = useState<IPersona[]>([]);
  const [qualityProducerDetails, setQualityProducerDetails] = useState<any[]>([]);
  const [qualityReviewerDetails, setQualityReviewerDetails] = useState<any[]>([]);
  const [qualityApproverDetails, setqualityApproverDetails] = useState<any[]>([]);

  useEffect(() => {
    if (props.projectBreakdown.qualityProducerId) {
      setQualityProducer([
        {
          key: props.projectBreakdown.qualityProducerId,
          text: props.projectBreakdown.qualityProducer ? `${props.projectBreakdown.qualityProducer}${props.projectBreakdown.qualityProducerCompany ? ` (${props.projectBreakdown.qualityProducerCompany})` : ''}`: '',
        },
      ]);
    } else {
      setQualityProducer([]);
    }
    if (props.projectBreakdown.qualityReviewerId) {
      setQualityReviewer([
        {
          key: props.projectBreakdown.qualityReviewerId,
          text: props.projectBreakdown.qualityReviewer ? `${props.projectBreakdown.qualityReviewer}${props.projectBreakdown.qualityReviewerCompany ? ` (${props.projectBreakdown.qualityReviewerCompany})` : ''}`: '',
        },
      ]);
    } else {
      setQualityReviewer([]);
    }
    if (props.projectBreakdown.qualityApproverId) {
      setQualityApprover([
        {
          key: props.projectBreakdown.qualityApproverId,
          text: props.projectBreakdown.qualityApprover ? `${props.projectBreakdown.qualityApprover}${props.projectBreakdown.qualityApproverCompany ? ` (${props.projectBreakdown.qualityApproverCompany})` : ''}`: '',
        },
      ]);
    } else {
      setQualityApprover([]);
    }

    if(props.projectBreakdown?.qualityProducerEmailAddress || props.projectBreakdown?.qualityProducerMobilePhoneNumber){
      setQualityProducerDetails([
        {
          emailId: props.projectBreakdown?.qualityProducerEmailAddress,
          mobileNumber: props.projectBreakdown?.qualityProducerMobilePhoneNumber
        }
      ])
    } else {
      setQualityProducerDetails([]);
    }

    if(props.projectBreakdown?.qualityReviewerEmailAddress || props.projectBreakdown?.qualityReviewerMobilePhoneNumber){
      setQualityReviewerDetails([
        {
          emailId: props.projectBreakdown?.qualityReviewerEmailAddress,
          mobileNumber: props.projectBreakdown?.qualityReviewerMobilePhoneNumber
        }
      ])
    } else {
      setQualityReviewerDetails([]);
    }

    if(props.projectBreakdown?.qualityApproverEmailAddress || props.projectBreakdown?.qualityApproverMobilePhoneNumber){
      setqualityApproverDetails([
        {
          emailId: props.projectBreakdown?.qualityApproverEmailAddress,
          mobileNumber: props.projectBreakdown?.qualityApproverMobilePhoneNumber
        }
      ])
    } else {
      setqualityApproverDetails([]);
    }


  }, [props.projectBreakdown]);

  const onQualityProducerSelected = (item: any) => {
    if (item) {
      if(item.emailAddress || item.mobilePhoneNumber){
        setQualityProducerDetails([{
            emailId: item?.emailAddress,
            mobileNumber: item?.mobilePhoneNumber
          }])
        }
      setQualityProducer([item]);
      props.projectBreakdown.qualityProducerId = item.key;
      props.projectBreakdown.qualityProducer = item.text;
      props.handleFormChange(props.projectBreakdown, true);
    }
    return item;
  };

  const onQualityReviewerSelected = (item: any) => {
    if (item) {      
      if(item.emailAddress || item.mobilePhoneNumber){
        setQualityReviewerDetails([{
          emailId: item?.emailAddress,
          mobileNumber: item?.mobilePhoneNumber
        }])
      }
      setQualityReviewer([item]);
      props.projectBreakdown.qualityReviewerId = item.key;
      props.projectBreakdown.qualityReviewer = item.text;
      props.handleFormChange(props.projectBreakdown, true);
    }
    return item;
  };

  const onQualityApproverSelected = (item: any) => {
    if (item) {      
      if(item.emailAddress || item.mobilePhoneNumber){
        setqualityApproverDetails([{
            emailId: item?.emailAddress,
            mobileNumber: item?.mobilePhoneNumber
          }])
      }
      setQualityApprover([item]);
      props.projectBreakdown.qualityApproverId = item.key;
      props.projectBreakdown.qualityApprover = item.text;
      props.handleFormChange(props.projectBreakdown, true);
    }
    return item;
  };

  const onSaveQualityResponsible = () => {
    props.saveFormData();
  };

  const limitedSearchAdditionalProps: IBasePickerSuggestionsProps = {
    resultsMaximumNumber: 10,
    searchingText: 'Searching...',
  };

  const ToolTipDataForEmailAndMobileNumber = (personDetails: any) => {
    const data = personDetails?.map((info: any) => {
      return (<>
      {info?.emailId?<><span>{info?.emailId}</span><br></br></>:''}
      {info?.mobileNumber?<><span>{info?.mobileNumber}</span><br></br></>:''}
      </>)
    })
    return data;
  }

  const [qpShowTooltip, setQpShowTooltip] = useState(false);
  const [qrShowTooltip, setQrShowTooltip] = useState(false);
  const [qaShowTooltip, setQaShowTooltip] = useState(false);
  const [tooltipContent, setTooltipContent] = useState<any>(null); // Track tooltip content
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 }); // Track tooltip position

  const handleMouseOver = (event: React.MouseEvent, tooltipContent: any) => {
    setTooltipContent(tooltipContent?.length>0?ToolTipDataForEmailAndMobileNumber(tooltipContent): null);
    setTooltipPosition({ x: event.clientX, y: event.clientY });    
  };

  return (
    <div className={''}>
      <div className={''}>
        <div className="sub-header-title">
          <Link className={classNames.borderNone} href='#' id='pbs-product-header-1-2'>
            <Label className={classNames.subHeaderStyle}>1.2 {t('qualityResponsible')} </Label>
          </Link>
        </div>
      </div>
      <div className={''}>
        <div className="proj-detail-content inner">
          <div>
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div
                  className={`${classNames.fullWidth}`}
                  style={{ maxHeight: 100, position: 'relative', height: 100 }}
                >
                  <Label>{t('qualityProducer')}</Label>
                        {qpShowTooltip && tooltipContent && (
                          <div
                            style={{
                              position: 'fixed',
                              top: tooltipPosition.y + 10, // Slightly offset the tooltip from the mouse position
                              left: tooltipPosition.x + 10,
                              padding: '5px',
                              backgroundColor: '#333',
                              color: '#fff',
                              borderRadius: '5px',
                              zIndex: 1000,
                            }}
                          >
                            {tooltipContent}
                          </div>
                       )}
                      <div
                        key={'qualityProducer'}
                        onMouseOver={(event) => {
                          setQpShowTooltip(true);
                          handleMouseOver(event, qualityProducerDetails)
                        }}
                        onMouseOut={ () => { setQpShowTooltip(false)}}
                          >
                          <NormalPeoplePicker
                            onChange={(items) => {
                              if (items && _.isArray(items) && items.length === 0) {
                                setQualityProducer([]);
                                setQualityProducerDetails([]);
                                props.projectBreakdown.qualityProducerId = null;
                                props.handleFormChange(props.projectBreakdown, true);
                              }
                            }}
                            selectedItems={
                              qualityProducer &&
                              qualityProducer[0] &&
                              qualityProducer[0]
                                ? qualityProducer
                                : []
                            }
                            onResolveSuggestions={props.onQualityFilterPersonChanged}
                            onItemSelected={onQualityProducerSelected}
                            pickerSuggestionsProps={limitedSearchAdditionalProps}
                            className={'ms-PeoplePicker'}
                            key={'qualityProducer'}
                            itemLimit={1}
                            removeButtonAriaLabel={'Remove'}
                            resolveDelay={300}
                            disabled={props.readOnly}
                          />
                      </div>
                </div>
              </Stack.Item>
            </Stack>
          </div>
          <div>
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div
                  className={`${classNames.fullWidth}`}
                  style={{ maxHeight: 100, position: 'relative', height: 100 }}
                >
                  <Label>{t('qualityReviewer')}</Label>
                      {qrShowTooltip && tooltipContent && (
                          <div
                            style={{
                              position: 'fixed',
                              top: tooltipPosition.y + 10, // Slightly offset the tooltip from the mouse position
                              left: tooltipPosition.x + 10,
                              padding: '5px',
                              backgroundColor: '#333',
                              color: '#fff',
                              borderRadius: '5px',
                              zIndex: 1000,
                            }}
                          >
                          {tooltipContent}
                        </div>
                        )}
                    <div
                        key={'qualityReviewer'}
                        onMouseOver={(event) => {
                          setQrShowTooltip(true);
                          handleMouseOver(event, qualityReviewerDetails)
                        }}
                        onMouseOut={ () => { setQrShowTooltip(false)}}
                          >
                          <NormalPeoplePicker
                            onChange={(items) => {
                              if (items && _.isArray(items) && items.length === 0) {
                                setQualityReviewer([]);
                                setQualityReviewerDetails([]);
                                props.projectBreakdown.qualityReviewerId = null;
                                props.handleFormChange(props.projectBreakdown, true);
                              }
                            }}
                            selectedItems={
                              qualityReviewer &&
                              qualityReviewer[0] &&
                              qualityReviewer[0]
                                ? qualityReviewer
                                : []
                            }
                            onResolveSuggestions={props.onQualityFilterPersonChanged}
                            onItemSelected={onQualityReviewerSelected}
                            pickerSuggestionsProps={limitedSearchAdditionalProps}
                            className={'ms-PeoplePicker'}
                            key={'qualityReviewer'}
                            itemLimit={1}
                            removeButtonAriaLabel={'Remove'}
                            resolveDelay={300}
                            disabled={props.readOnly}
                          />
                      </div>
                </div>
              </Stack.Item>
            </Stack>
          </div>
          <div>
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div
                  className={`${classNames.fullWidth}`}
                  style={{ maxHeight: 100, position: 'relative', height: 100 }}
                >
                  <Label>{t('qualityApprover')}</Label>
                  {qaShowTooltip && tooltipContent && (
                          <div
                            style={{
                              position: 'fixed',
                              top: tooltipPosition.y + 10, // Slightly offset the tooltip from the mouse position
                              left: tooltipPosition.x + 10,
                              padding: '5px',
                              backgroundColor: '#333',
                              color: '#fff',
                              borderRadius: '5px',
                              zIndex: 1000,
                              display:tooltipContent?'inline-block':'none'
                            }}
                          >
                          {tooltipContent}
                        </div>
                        )}
                      <div
                        key={'qualityApprover'}
                        onMouseOver={(event) => {
                          setQaShowTooltip(true);
                          handleMouseOver(event, qualityApproverDetails)
                        }}
                        onMouseOut={ () => { setQaShowTooltip(false)}}
                          >
                          <NormalPeoplePicker
                            onChange={(items) => {
                              if (items && _.isArray(items) && items.length === 0) {
                                setQualityApprover([]);
                                setqualityApproverDetails([]);
                                props.projectBreakdown.qualityApproverId = null;
                                props.handleFormChange(props.projectBreakdown, true);
                              }
                            }}
                            selectedItems={
                              qualityApprover &&
                              qualityApprover[0] &&
                              qualityApprover[0]
                                ? qualityApprover
                                : []
                            }
                            onResolveSuggestions={props.onQualityFilterPersonChanged}
                            onItemSelected={onQualityApproverSelected}
                            pickerSuggestionsProps={limitedSearchAdditionalProps}
                            className={'ms-PeoplePicker'}
                            key={'qualityApprover'}
                            itemLimit={1}
                            removeButtonAriaLabel={'Remove'}
                            resolveDelay={300}
                            disabled={props.readOnly}
                          />
                      </div>
                </div>
              </Stack.Item>
            </Stack>
          </div>
        </div>
      </div>
      <div className={'card-footer'}>
        <Separator />
        <Stack
          reversed
          styles={stackStylesFooter}
          tokens={itemAlignmentsStackTokens}
        >
          <Stack.Item align="end" styles={stackItemStylesFooter}>
            <ActionButton
              className={classNames.actionButton}
              iconProps={saveIcon}
              disabled={props.readOnly}
              allowDisabledFocus
              onClick={() => {
                onSaveQualityResponsible();
              }}
            >
              <Label className={classNames.actionButton}>{t('save')} </Label>
            </ActionButton>
          </Stack.Item>
        </Stack>
      </div>
    </div>
  );
};

export default QualityResponsible;

import { Depths } from '@fluentui/theme';
import _ from 'lodash';
import {
  ChoiceGroup,
  DirectionalHint,
  Dropdown,
  IChoiceGroupOption,
  IStackStyles,
  Label,
  Link,
  mergeStyles,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import QRCode from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import client from '../../../api';
import { READ_CPC_VEHICLES } from '../../../shared/endpoints';
import SearchableDropdown from '../../../shared/searchableDropdown/searchableDropdown';
import { getQRName } from '../../../shared/util';

// const theme: ITheme = getTheme();
// const { palette, semanticColors, fonts } = theme;
const stackStyles: IStackStyles = { root: {} };

const dropdownStyles = { dropdown: { width: 300, marginTop: 20 } };

const contentStyle = mergeStyles({
  height: 'calc(100vh - 200px)!important',

  display: 'flex',
  flexDirection: 'column',
  selectors: {
    '@media(max-width: 1100px)': {
      width: '97.4%',
      height: 'calc(100vh)',
      position: 'absolute',
    },
  },
});

const NewQRCodeComponent = ({
                              onTypeChange,
                              selectedCodeType,
                              createQRCode,
                              location,
                              onProjectChange,
                              setLocation,
                              setVehicalNo,
                              showCode,
                              codeValue,
                              projectErrorMsg,
                              locationErrorMsg,
                              vehicalNoErrorMsg,
                              disable,
                              isLoading,
                              qrCode,
                              toggleBookmarkOut,
                              toggleBookmark,
                              divClass,
                              currentActiveSection,
                              onChangeRole,
                              roleValidationMsg,
                              resetValue,
                              setResetValue,
                            }: any) => {
  const { t } = useTranslation();
  const codeTypes = [
    { key: 4, text: t('start') },
    { key: 0, text: t('travel') },
    { key: 1, text: t('work') },
    { key: 6, text: t('break') },
    { key: 2, text: t('unload') },
    { key: 5, text: t('stop') },
  ];

  const options: IChoiceGroupOption[] = [
    { key: '1', text: t('driver') },
    { key: '2', text: t('passenger') },
  ];

  const [project, setProject] = useState(null);
  const [vehicaleNo, setVehicle] = useState(null);

  useEffect(() => {
    if (resetValue) {
      setProject(null);
      setVehicle(null);
      setResetValue(false);
    }
  }, [resetValue]);

  const formatResponse = (response: any) => {
    let data: { value: string; label: string }[] = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: any) => {
        return {
          value: item.key,
          label: item.text,
          sequenceCode: item.sequenceCode,
        };
      });
    }
    return data;
  };

  const formatVehicleResponse = (response: any) => {
    let data: { value: string; label: string }[] = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: any) => {
        return {
          value: item.key,
          label: item.text,
          sequenceCode: item.key,
        };
      });
    }
    return data;
  };

  const getByName = async (name: string) => {
    let projects = [];
    const response = await client.post(
      'ContractingUnitTenants/GetProjectsByUserProjectFilter', {
        title: name,
        projectTypeId: null,
        managementLevelId: null,
        toleranceStateId: null,
        toleranceState: null,
        sorter: {
          attribute: null,
          order: null,
        },
      },
    );

    if (response && response.status === 200 && response.data && response.data.result) {
      projects = response.data.result.map((item: any) => {
        return {
          value: item.projectDefinitionId,
          label: item.title,
          sequenceCode: item.sequenceCode,
        };
      });
    }
    // const response = await client.get(
    //     `ProjectDefinition/ReadProjectsForQr/${name}`
    // );
    return projects;
    // return formatResponse(response);
  };

  const searchVehicles = async (name: string) => {
    if (name) {
      const response = await client.get(READ_CPC_VEHICLES + name);
      return formatVehicleResponse(response);
    } else {
      return [];
    }
  };

  const promiseOptions = (inputValue: any) =>
    new Promise((resolve) => {
      // setTimeout(() => {
      resolve(getByName(inputValue));
      // }, 1000);
    });

  const cpcPromiseOptions = (inputValue: any) =>
    new Promise((resolve) => {
      // setTimeout(() => {
      resolve(searchVehicles(inputValue));
      // }, 1000);
    });

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Stack
        className={contentStyle}
        horizontalAlign="start"
        verticalAlign="start"
        padding={0}
        gap={0}
        styles={{
          root: {
            width: '100%',
            height: '100%',
          },
        }}
      >

        <div className="document-pane-card" style={{ marginTop: '1rem' }}>
          <div className="proj-detail-block" id="5">
            <div className={'card-header'}>
              <Link href="#" id="qrCode" style={{ marginTop: 5 }}>
                <Label>{1 + '. ' + t('qRCode')}</Label>
              </Link>
            </div>
          </div>

          <Stack horizontal horizontalAlign="space-between" styles={stackStyles}>
            <Dropdown
              style={{ marginBottom: selectedCodeType === 1 || selectedCodeType === 0 || selectedCodeType === 2 || selectedCodeType === 4 || selectedCodeType === 5 || selectedCodeType === 6 ? 0 : 30 }}
              placeholder={t('activityType')}
              ariaLabel="Required"
              options={codeTypes}
              required={true}
              disabled={disable}
              defaultSelectedKey={selectedCodeType}
              onChange={onTypeChange}
              styles={dropdownStyles}
            />
          </Stack>

          {(selectedCodeType === 1 ||
            selectedCodeType === 0 ||
            selectedCodeType === 2 ||
            selectedCodeType === 4 ||
            selectedCodeType === 5 ||
            selectedCodeType === 6) && (
            <div style={{ width: 300 }}>
              <Label>{t('project')}</Label>
              <SearchableDropdown
                onChange={(item: any) => {
                  if (item) {
                    const selectedItem = { key: item.value, text: item.label };
                    onProjectChange(null, selectedItem);
                    setProject(item);
                  } else {
                    const selectedItem = { key: null, text: null };
                    onProjectChange(null, selectedItem);
                    setProject(null);
                  }
                }}
                selectedOption={project}
                promiseOptions={promiseOptions}
                validationMessage={projectErrorMsg ? projectErrorMsg : ''}
                required={false}
                disabled={disable}
              />
            </div>
          )}

          {selectedCodeType === 0 && (
            <div style={{ width: '80%' }} className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                <div style={{ width: 300 }}>
                  <Label required={true}>{t('vehicleNumber')}</Label>

                  <SearchableDropdown
                    onChange={(item: any) => {
                      if (item) {
                        const selectedItem = {
                          key: item.value,
                          text: item.label,
                        };
                        setVehicalNo(null, selectedItem);
                        setVehicle(item);
                      } else {
                        const selectedItem = { key: null, text: null };
                        setVehicalNo(null, selectedItem);
                        setVehicle(null);
                      }
                    }}
                    selectedOption={vehicaleNo}
                    promiseOptions={cpcPromiseOptions}
                    validationMessage={vehicalNoErrorMsg ? vehicalNoErrorMsg : ''}
                    required={false}
                    disabled={disable}
                  />
                </div>
              </div>
              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                <ChoiceGroup
                  options={options}
                  onChange={onChangeRole}
                  label="Role"
                  required={true}
                  style={{ marginLeft: 120 }}
                  disabled={disable}
                />
                {!_.isEmpty(roleValidationMsg) && (
                  <Text
                    styles={{ root: { color: '#a4262c', fontSize: 12, marginLeft: 100 } }}
                  >
                    {roleValidationMsg}
                  </Text>
                )}
              </div>
            </div>
          )}

          {(selectedCodeType === 4 ||
            selectedCodeType === 2 ||
            selectedCodeType === 5) && (
            <TextField
              label={t('location')}
              required={true}
              onChange={setLocation}
              value={location}
              errorMessage={locationErrorMsg}
              disabled={disable}
              styles={{ root: { width: 300 } }}
            />
          )}
          {showCode && (
            <QRCode className="marginBottom"
                    id={getQRName(qrCode)}
                    value={`{id:${codeValue}}`}
                    style={{ marginTop: 20, marginLeft: 5 }}
                    size={200}
            />
          )}

          {selectedCodeType !== -1 && !showCode && (
            <PrimaryButton
              text={t('save')}
              allowDisabledFocus
              style={{ marginTop: 20, marginBottom: 30 }}
              onClick={() => {
                createQRCode();
                // setProject(null)
                // setVehicle(null)
              }}
            />
          )}

          {isLoading && (
            <Spinner
              size={SpinnerSize.large}
              style={{
                flex: 1,
                width: '100%',
                height: '100%',
                alignSelf: 'center',
                marginTop: 0,
              }}
            />
          )}
        </div>

        <div>
          <Stack
            className={divClass}
            verticalFill
            gap={30}
            styles={{ root: { selectors: { '@media(max-width: 1440px)': { boxShadow: Depths.depth8 } } } }}
          >
            <i
              title={t('close')}
              className="ms-Icon ms-Icon--ChromeClose"
              aria-hidden="true"
              onClick={toggleBookmarkOut}
            ></i>
            <Text className="title">{t('bookmark')}</Text>
            <div className="inner-container">
              <Label
                className={`${currentActiveSection === 1 ? 'selected' : ''}`}
              >
                <Link href="#qrCode">1. {t('qRCode')}</Link>
              </Label>
            </div>
          </Stack>

          <Stack
            className="bookmark-panel-responsive"
            verticalFill
            onClick={toggleBookmark}
            gap={30}
            styles={{ root: { boxShadow: Depths.depth8 } }}
          >
            <TooltipHost
              content={t('bookmark')}
              id="bmark"
              calloutProps={{ gapSpace: 0 }}
              directionalHint={DirectionalHint.rightCenter}
            >
              <i
                className="ms-Icon ms-Icon--SearchBookmark"
                aria-labelledby="bmark"
                aria-hidden="true"
              ></i>
            </TooltipHost>
          </Stack>
        </div>
      </Stack>
    </div>
  );
};

export default NewQRCodeComponent;

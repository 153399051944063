import React from 'react';
import { connect } from 'react-redux';
import DocumentPaneComponent from './component';
import { WithTranslation } from 'react-i18next';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import history from '../../../history';
import { messageService } from '../../../services/messageService';
import { getProject } from '../../../shared/util';

import {
  getAllProjectDropdowns,
  getProjectHeaderDetails,
  saveProjectStateAttr,
} from '../../../reducers/projectReducer';
import { formData, Project } from '../../../types/project';
import { v4 as uuidv4 } from 'uuid';
import { getContractingUnits } from '../../../reducers/cuProjectReducer';
import CIAWSiteCodeModal from "./ciawModel/component";
import ProjectSquareMetersModal from './squareMeters/projectSquareMetersModal';

export interface State {
  isDirty: boolean;
  redirect: boolean;
  loading: boolean;
  isClear: boolean;
  isEdit: boolean;
  isCostModalOpen: boolean;
  showPrint: boolean;
  isApproved: boolean;
  ledgerDropDowns: { key: string; text: string }[];
  generalLedger: string;
  resourceData: any;
  openPOResourceModal: boolean;
  uid: string | null;
}

interface Props extends WithTranslation {
  projectDropDowns: any;
  saveFormData: (data: Project) => void;
  getAllProjectDropdowns: () => void;
  test: string;
  formData: any;
  readByProjectId: (id: string) => void;
  getContractingUnits: () => void;
}

class DocumentPane extends React.Component<any, any> {
  subscription: any;
  submitExternal: any;
  form: any;

  constructor(props: any) {
    super(props);
    this.state = {
      isDirty: false,
      isEdit: false,
      redirect: false,
      loading: false,
      formData: formData,
      showPrint: false,
      isApproved: false,
      uid: null,
      isClear: false,
      isMapOpen: false,
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    //   if (
    //       prevProps.isNew !== this.props.isNew ||
    //   prevProps.uid !== this.props.uid
    //   ) {
    //       if (this.props.isNew) {
    //           const formData = this.state.formData;
    //           formData.id = this.props.uid;
    //           this.setState({ formData, isClear: false });
    //       }
    //   } else {
    //   }

    //   if (prevProps.projectId !== this.props.projectId) {
    //       if (this.props.projectId) {
    //           this.props.readByProjectId(this.props.projectId);
    //       }
    //   }

    //   if (prevProps.formData !== this.props.formData) {
    //       if (!this.state.isDirty) {
    //           if (!_.isEmpty(this.props.formData)) {
    //               const formData = this.props.formData;
    //               this.setState({ formData });
    //           }
    //       } else {
    //           if (this.props.isNew && _.isEmpty(this.props.formData)) {
    //               const formData = this.state.formData;
    //               this.setState({ formData: formData });
    //           }
    //       }
    //   }
  }

  componentDidMount() {
    // get document pane dropdowns
    this.props.getAllProjectDropdowns();
    this.props.getContractingUnits();
    this.setState({ uid: uuidv4(), isClear: this.props.isNewPROJECT });

    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewPROJECT) {
          if (getProject()) {
            this.setState({ uid: uuidv4(), isClear: true });
          } else {
            this.setState({ uid: uuidv4(), isClear: true });
          }

          if (this.form) {
            this.form.reset();
            this.form.resetFieldState('name');
          }
        }
        if (data.data.savePROJECTData) {
          // this.saveFormData();
          this.submitExternal();
        }
        //   if (data.data.projectFilter) {
        //       this.setState({ filter: data.data.project });
        //   }
        //   if (data.data.pdfGenerate) {
        //       this.setState({ showPrint: true });
        //   }
      }
    });
    //   if (getProject()) {
    //       const projectId = getProject();
    //       if (projectId) {
    //           this.props.getProjectHeaderDetails(projectId);
    //       }
    //   } else if (this.props.formData?.id) {
    //       this.props.getProjectHeaderDetails(this.props.formData?.id);
    //   }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { psFilter: this.state.filter },
    });
  };

  setSubmitExternal = (submit: any) => {
    this.submitExternal = submit;
  };

  setForm = (form: any) => {
    this.form = form;
  };


  render() {
    return (
      <div style={{ width: '100%' }}>
        <RouteLeavingGuard
          when={this.state.isDirty}
          navigate={(path) => this.handleDiscardChangesNavigate(path)}
          shouldBlockNavigation={() => {
            return true;
          }}
          handleConfirmation={(isConfirm: boolean) => {
            this.setState({ isDirty: !isConfirm });
          }}
        />
        <DocumentPaneComponent
          submit={(submit: any) => {
            this.setSubmitExternal(submit);
          }}
          saveFormData={this.props.saveFormData}
          uid={this.state.uid}
          formData={this.props.formData}
          formValues={this.form?.getState()?.values}
          isClear={this.state.isClear}
          // isExpand={this.props.isExpand}
          setForm={(form: any) => {
            this.setForm(form);
          }}
        />
        <CIAWSiteCodeModal/>

        <ProjectSquareMetersModal />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isEdit: state.ps.isEdit,
    isNew: state.ps.isNew,
    uid: state.ps.uid,
    projectHeader: state.project.projectHeader,
    showProgressBar: state.project.showProgressBar,
    selectedProject: state.project.selectedProject,
    dropDowns: state.project.projectDropDowns,
    contractingUnits: state.cuProject.contractingUnits,
  };
};

const mapDispatchToProps = {
  // saveFormData,
  getProjectHeaderDetails,
  getAllProjectDropdowns,
  saveProjectStateAttr,
  getContractingUnits,
  // readByProjectId
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentPane);

import { Dropdown, IColumn, TextField } from '@fluentui/react';
import _ from 'lodash';
import React from 'react';
import client from '../../../api';
import { PROJECT_FILTER_EP } from '../../../shared/endpoints';
import i18n from '../../../../i18n';


export const _renderDetailsFooterItemColumn = (
  selectedType: any,
  onChangeType: (value: number | string | null) => void,
  projects: any,
  project: any,
  onChangeProject: (value: number | string | null) => void,
  vehicleNo: string | null,
  onChangeVehicleNo: (value: string) => void,
  location: string | null,
  onChangeLocation: (value: string) => void,
  t: any,
  item?: any,
  index?: number,
  column?: IColumn,
) => {

  const promiseOptions = (inputValue: any) =>
    new Promise((resolve) => {
      //setTimeout(() => {
      resolve(getByName(inputValue));
      // }, 1000);
    });

  const getByName = async (name: string) => {
    let filter = {
      title: name,
      projectTypeId: null,
      managementLevelId: null,
      toleranceStateId: null,
      toleranceState: null,
      sorter: {
        attribute: null,
        order: null,
      },
    };
    let response = await client.post(PROJECT_FILTER_EP, filter);
    return formatResponse(response);
  };

  const formatResponse = (response: any) => {
    let data: { value: string; label: string }[] = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: any) => {
        return {
          value: item.projectDefinitionId,
          label: item.title,
          sequenceCode: item.sequenceCode,
        };
      });
    }
    return data;
  };

  const codeTypes = [
    { key: -1, text: i18n.t('all') },
    { key: 4, text: i18n.t('start') },
    { key: 0, text: i18n.t('travel') },
    { key: 1, text: i18n.t('work') },
    { key: 6, text: i18n.t('break') },
    { key: 2, text: i18n.t('unload') },
    { key: 5, text: i18n.t('stop') },
  ];

  if (!selectedType && selectedType !== 0) {
    selectedType = -1;
  }
  switch (column!.key) {
    case 'column1': {
      return (
        <div>
          <div>
            <Dropdown
              placeholder=""
              options={codeTypes}
              selectedKey={selectedType}
              onChange={(event, value) => {
                onChangeType(value ? value.key : null);
              }}
              styles={{}}
            />
          </div>
        </div>
      );
      break;
    }
    case 'column2': {
      return (
        <div>
          <Dropdown
            placeholder=""
            options={projects ? projects : []}
            selectedKey={project ? project : '-1'}
            onChange={(event, value) => {
              onChangeProject(value ? value.key : null);
            }}
            styles={{}}
          />
        </div>
      );
      break;
    }
    case 'column3': {
      return (
        <div>
          <TextField
            value={vehicleNo ? vehicleNo : ''}
            onChange={(event, value) => {
              onChangeVehicleNo(value!);
            }}
          />
        </div>
      );
      break;
    }

    case 'column4': {
      return (
        <div>
          <TextField
            value={location ? location : ''}
            onChange={(event, value) => {
              onChangeLocation(value!);
            }}
          />
        </div>
      );
      break;
    }
    default: {
      break;
    }
  }
};

import React, {useEffect} from 'react';
import {Calendar, defaultCalendarStrings, FirstWeekOfYear, mergeStyleSets} from '@fluentui/react';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {getCalenderString, getFirstDayOfWeekByLanguage, stringDateToDateObject} from '../../../../shared/util';
import {useTranslation} from 'react-i18next';
import {saveThAutomationStateAttr} from '../../../../reducers/thAutomationReducer';

const classNames = mergeStyleSets({
    wrapper: {
        height: 'calc(100vh - 14vh)!important',
        position: 'relative'
    },
    selected: {
        fontWeight: 'bold',
        color: '#854114!important'
    },
    hr: {
        marginBottom: 15,
        borderTop: '1px solid #d2d2d2'
    },
    fullWidth: {width: '100%'},
    halfWidth: {width: '49%'}
});

const TopShortcutPane = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const store = useStore();

    const {
        selectedDate,
        horizontalReSizer,
    } = useSelector(
        (state: any) => state.thAutomation
    );

    useEffect(() => {
        dispatch(saveThAutomationStateAttr('draggedEvent', null));

    }, []);

    const onSelectDate = React.useCallback((date: Date, dateRangeArray: Date[] | undefined): void => {
        Promise.all([
            dispatch(saveThAutomationStateAttr('selectedDate', date)),
        ]).then(() => {

        });
    }, []);

    return <div>
        <div className={`projectDayPlanning  filter-panel-left ${classNames.wrapper}`}>
            <div className="inner-container">
                <Calendar
                    showWeekNumbers
                    showMonthPickerAsOverlay
                    highlightSelectedMonth
                    showGoToToday={false}
                    firstDayOfWeek={getFirstDayOfWeekByLanguage()}
                    onSelectDate={onSelectDate}
                    value={stringDateToDateObject(selectedDate)}
                    // Calendar uses English strings by default. For localized apps, you must override this prop.
                    strings={getCalenderString()}
                    firstWeekOfYear={FirstWeekOfYear.FirstFourDayWeek}
                />

                <div style={{height: 1, backgroundColor: '#f3ede6'}}></div>

                {/*<div className="filter-option-list" style={{marginTop: '1px'}}>*/}
                {/*    <Stack*/}
                {/*      gap={15}*/}
                {/*      verticalFill*/}
                {/*      styles={{*/}
                {/*          root: {*/}
                {/*              marginTop: 2,*/}
                {/*              width: '100%',*/}
                {/*              verticalAlign: 'center',*/}
                {/*              height: horizontalReSizer - 470 > 1 ? horizontalReSizer - 470 : 1,*/}
                {/*              overflow: 'auto'*/}
                {/*          }*/}
                {/*      }}*/}
                {/*    >*/}
                {/*    </Stack>*/}
                {/*</div>*/}
            </div>
        </div>
    </div>
};

export default TopShortcutPane

import * as React from 'react';
import TextEditor from '../textEditor/textEditor';


const CKEditorWrapper: React.FC<any> = ({ input: { name, onChange, value, ...restInput }, disabled, meta, lable, ...rest }) => {
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  return (
    // <TextField
    //     { ...rest }
    //     name={ name }
    //     label={lable}
    //     helperText={ showError ? meta.error || meta.submitError : undefined }
    //     errorMessage={ showError ? meta.error:'' }
    //     inputProps={ restInput }
    //     onChange={ onChange }
    //     value={ value }
    // />

    <TextEditor
      value={value}
      onChange={onChange}
      readOnly={disabled}
    />
  );
};

export default CKEditorWrapper;


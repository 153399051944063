import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import PDPCustomModal from "./pdpCustomModal";
import {
    getBuPersonWithCompetence,
    getToolsWithResource,
    getVehicleWithResource
} from "../../../../reducers/projectDayPlanningReducer";
import {formatDateTimeToISOString} from "../../../../shared/util";

interface Props {
    isModalOpen: boolean
    handleClose?: () => void
    handleSave?: (workers: any) => void
    pmol: any
    resourceCategory: any
}

function SelectCompetencyModal(props: Props) {
    const dispatch = useDispatch();
    const [selectedWorkers, setSelectedWorkers] = useState<any>([]);
    const [selectedWorkersIdList, setSelectedWorkersIdList] = useState<any>([]);

    const {
        isModalOpen,
        handleClose,
        handleSave,
        pmol,
        resourceCategory
    } = props;

    const {
        personCompetenceList,
        showProgressBar,
        selectedBu,
    } = useSelector(
      (state: any) => state.projectDPlan
    );

    const {
        selectedDate,
    } = useSelector(
      (state: any) => state.mydPlan
    );

    useEffect(() => {
        let curTeam = pmol?.team?.map((item: any) => {
            return item.id
        });
        setSelectedWorkers(pmol?.team?pmol?.team:[]);
        setSelectedWorkersIdList(curTeam?curTeam:[]);

        if (isModalOpen) {
            switch (resourceCategory) {
                case "ppbWorkersId":
                    dispatch(getBuPersonWithCompetence({
                        cabPersonId: curTeam?curTeam:[],
                        date: formatDateTimeToISOString(selectedDate),
                        buId: selectedBu ?? null,
                    }));
                    break;
                case "ppbVehicleId":
                    dispatch(getVehicleWithResource({
                        cabPersonId: curTeam,
                        date: formatDateTimeToISOString(selectedDate),
                        buId: selectedBu ?? null,
                    }));
                    break;
                case "ppbToolId":
                    dispatch(getToolsWithResource({
                        cabPersonId: curTeam,
                        date: formatDateTimeToISOString(selectedDate),
                        buId: selectedBu ?? null,
                    }));
                    break;

                default:
                    break;

            }
        }
    }, [isModalOpen]);

    const handleOnChangeCheckbox = (worker: any, checked: boolean | undefined) => {
        if (resourceCategory === "ppbWorkersId") {
            handleWorkersCheckBoxOnChange(worker, checked);
        } else if (resourceCategory === "ppbVehicleId") {
            handleVehiclesCheckBoxOnChange(worker, checked);
        } else if (resourceCategory === "ppbToolId") {
            handleToolsCheckBoxOnChange(worker, checked);
        } else {
        }
    }

    const handleWorkersCheckBoxOnChange = (worker: any, checked: boolean | undefined) => {
        let checkList = selectedWorkersIdList.slice();
        let teamList = selectedWorkers.slice();
        let findCheckList = checkList.indexOf(worker.id);
        let findTeamList = teamList.map((item: any) => item.id).indexOf(worker.id);

        if (findCheckList > -1) {
            checkList.splice(findCheckList, 1);
        } else {
            checkList.push(worker.id);
        }
        setSelectedWorkersIdList(checkList);

        if (findTeamList > -1) {
            teamList.splice(findTeamList, 1);
        } else {
            teamList.push(worker);
        }
        setSelectedWorkers(teamList);
    };

    const handleVehiclesCheckBoxOnChange = (worker: any, checked: boolean | undefined) => {
        // let checkList = selectedWorkersIdList.slice();
        // let teamList = selectedWorkers.slice();
        // let findCheckList = checkList.indexOf(worker.id);
        // let findTeamList = teamList.map((item: any) => item.id).indexOf(worker.id);
        //
        // if (findCheckList > -1) {
        //     checkList.splice(findCheckList, 1);
        // } else {
        //     checkList.push(worker.id);
        // }
        // setSelectedWorkersIdList(checkList);
        //
        // if (findTeamList > -1) {
        //     teamList.splice(findTeamList, 1);
        // } else {
        //     teamList.push(worker);
        // }
        // setSelectedWorkers(teamList);
    };

    const handleToolsCheckBoxOnChange = (worker: any, checked: boolean | undefined) => {
        // let checkList = selectedWorkersIdList.slice();
        // let teamList = selectedWorkers.slice();
        // let findCheckList = checkList.indexOf(worker.id);
        // let findTeamList = teamList.map((item: any) => item.id).indexOf(worker.id);
        //
        // if (findCheckList > -1) {
        //     checkList.splice(findCheckList, 1);
        // } else {
        //     checkList.push(worker.id);
        // }
        // setSelectedWorkersIdList(checkList);
        //
        // if (findTeamList > -1) {
        //     teamList.splice(findTeamList, 1);
        // } else {
        //     teamList.push(worker);
        // }
        // setSelectedWorkers(teamList);
    };

    return <div>
        <PDPCustomModal
          title={pmol?.title ?? ""}
          list={personCompetenceList ? personCompetenceList.team : []}
          isModalOpen={isModalOpen}
          handleClose={handleClose}
          handleSave={handleSave}
          showProgressBar={showProgressBar}
          selectedWorkers={selectedWorkers}
          handleOnChangeCheckbox={handleOnChangeCheckbox}
          workerIdList={selectedWorkersIdList}
        />
    </div>
}

export default SelectCompetencyModal;
import { useTranslation } from 'react-i18next';
import {
  ActionButton,
  ContextualMenu,
  FontWeights,
  getTheme,
  IconButton,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { uPrinceTheme } from '../../../../../theme';
import { Competence } from '../../../../types/projectBreakdownStructure';
import ClearableDropdown from '../../../../shared/clearableDropdown/clearableDropdown';

const theme = getTheme();
const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const classNames = mergeStyleSets({
  wrapper: {
    //height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: {
    maxWidth: 400,
  },
  textContent: {
    padding: '15px 10px',
  },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: {
    width: '100%',
  },
});

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };

const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    paddingTop: 20,
    minWidth: '45vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'none',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};


const CompetenceModal = (props: {
  isOpen: boolean;
  openCompetenceModal: () => void;
  isEdit: boolean;
  createCompetence: (competence: Competence) => any;
  competence: Competence;
  dropDownOptions: any;
  pbsId: string | null
}) => {

  const { t } = useTranslation();
  const [isDraggable] = useBoolean(false);
  const titleId = useId('title');
  const [competence, setCompetence] = useState(new Competence());
  const [disableSave, setDisableSave] = useState(true);


  const onChangeInput = (field: string) => (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: any,
  ) => {
    if (newValue) {
      setCompetence((prevState) => ({
        ...prevState,
        pbsProductId: props.pbsId,
        [field]: newValue.key,
      }));
    } else {
      setCompetence((prevState) => ({
        ...prevState,
        pbsProductId: props.pbsId,
        [field]: null,
      }));
      handleSaveBtnDisable(competence, true);
    }
  };

  const handleSaveBtnDisable = (item: any, isDisable = false) => {
    if (item.skillId && item.experienceId) {
      setDisableSave(false);
    } else if (!item.skillId && !item.experienceId) {
      setDisableSave(true);
    }
    if (isDisable) {
      setDisableSave(true);
    }
  };


  useEffect(() => {
    setCompetence(props.competence);
    handleSaveBtnDisable(props.competence);
  }, [props.competence]);

  useEffect(() => {
    handleSaveBtnDisable(competence);
  }, [competence]);

  const createCompetence = () => {
    props.createCompetence(competence);
    if (!props.isEdit) {
      setCompetence(props.competence);
    }
  };

  const resetCompetence = () => {
    setCompetence(props.competence);
  };

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          props.openCompetenceModal();
          setTimeout(() => {
            setCompetence(props.competence);
          }, 1000);
        }}
        scrollableContentClassName="body"
        isBlocking={true}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}> {!props.isEdit ? t('add') : t('edit')} {t('competencies')}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              props.openCompetenceModal();
              setCompetence(props.competence);
            }}
          />
        </div>
        <div className={contentStyles.subHeader}>
          <ActionButton iconProps={addIcon} allowDisabledFocus>
            <Label className={contentStyles.actionButtonLabel}>
              {!props.isEdit ? t('add') : t('save')}
            </Label>
          </ActionButton>
        </div>
        <div className={contentStyles.body}>
          <div className="proj-detail-content inner">
            <div className="ms-Grid-row">
              <Label>01. {t('competencies')} </Label>
            </div>
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <ClearableDropdown
                    placeholder={t('productDevelopmentSkillsrequired')}
                    label={t('productDevelopmentSkillsrequired')}
                    options={props.dropDownOptions.pbsSkills}
                    onChange={onChangeInput('skillId')}
                    selectedKey={(competence.skillId)
                      ? competence.skillId
                      : ''}
                  />
                </div>
              </Stack.Item>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <ClearableDropdown
                    placeholder={t('productDevelopmentSkillsexperience')}
                    label={t('productDevelopmentSkillsexperience')}
                    options={props.dropDownOptions.pbsExperience}
                    onChange={onChangeInput('experienceId')}
                    selectedKey={competence.experienceId
                      ? competence.experienceId
                      : ''}
                  />
                </div>
              </Stack.Item>
            </Stack>
          </div>
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={addIconWhite}
            text={t('save')}
            style={{ marginTop: 20, marginBottom: 30 }}
            onClick={() => {
              createCompetence();
              props.openCompetenceModal();
            }}
            disabled={disableSave}
          />
          {!props.isEdit && <PrimaryButton
            iconProps={addIconWhite}
            text={t('saveandNew')}
            style={{ marginTop: 20, marginBottom: 10, marginLeft: 10 }}
            onClick={() => {
              createCompetence();
              resetCompetence();
            }}
            disabled={disableSave}
          />}
        </div>
      </Modal>
    </div>
  );
};

export default CompetenceModal;

export class OrganisationSettingListPaneFilter {
  organizationTaxonomyLevelId: string | null = null;
  personId?: string | null = null
  sorter: Sorter = new Sorter();
}

export interface ORGSettingShortCutPaneItem {
  key: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
  value: number | null
  levelId: number | string | null
}

class Sorter {
  attribute: string | null = null;
  order: string | null = null;
}

export class OrganisationSettingListItem {
  id: string | null = null;
  name: string | null = null;
  sequenceId: string | null = null;
  title: string | null = null;
}

export interface DropDown {
  key: string;
  text: string;
}

export interface OrganisationSettingDropDowns {
  status: DropDown[];
  type: DropDown[];
  osList: OrganisationSettingListItem[];
}

export interface History {
  createdByUser: string | null;
  createdDateTime: string | null;
  revisionNumber: number | string | null;
  updatedByUser: string | null;
  updatedDateTime: string | null;
}

export interface OSDetails {
  title: string;
  sequenceId: string
}

export class OSListPaneItem {
  id: string | null = null;
  name: string | null = null;
}

export enum OrganisationLevel {
  BU = "ttkab9fe-po57-4088-82a9-d27008688bbb",
  PERSON = "aqwab9fe-po57-4088-82a9-d27008688mvk"
}

export enum RoleId {
  FOREMAN = "2210e768-msms-Item-team1-ee367a82ad22",
  WORKER = "2210e768-msms-Item-team2-ee367a82ad22"
}
import {
  APPROVE_WORK_FLOW_EP,
  APPROVE_WORK_FLOW_PROJECT_EP,
  READ_WORK_FLOW_BY_ID_EP,
  READ_WORK_FLOW_DROPDOWN_EP,
  READ_WORK_FLOW_LIST_EP,
  READ_WORK_FLOW_SHORTCUT_PANE_EP,
  WORK_FLOW_SAVE_EP,
} from '../shared/endpoints';
import {
  APPROVE_WORK_FLOW,
  APPROVE_WORK_FLOW_FAIL,
  APPROVE_WORK_FLOW_SUCCESS,
  CLEAR_WORK_FLOW_FORM_DATA,
  CLEAR_WORK_FLOWUID,
  LOAD_WORK_FLOW_BY_ID,
  LOAD_WORK_FLOW_BY_ID_FAIL,
  LOAD_WORK_FLOW_BY_ID_SUCCESS,
  LOAD_WORK_FLOW_DROPDOWN,
  LOAD_WORK_FLOW_DROPDOWN_FAIL,
  LOAD_WORK_FLOW_DROPDOWN_SUCCESS,
  LOAD_WORK_FLOW_LSITPANE,
  LOAD_WORK_FLOW_LSITPANE_FAIL,
  LOAD_WORK_FLOW_LSITPANE_SUCCESS,
  LOAD_WORK_FLOW_SHORTCUTPANE,
  LOAD_WORK_FLOW_SHORTCUTPANE_FAIL,
  LOAD_WORK_FLOW_SHORTCUTPANE_SUCCESS,
  RESET_WORK_FLOW_IS_CHANGE,
  SAVE_WORK_FLOW,
  SAVE_WORK_FLOW_FAIL,
  SAVE_WORK_FLOW_SUCCESS,
  SET_WORK_FLOW_UID,
} from '../actionTypes/workFlowActionTypes';
import { v4 as uuidv4 } from 'uuid';
import { POFilter } from '../types/purchaseOrder';
import { WorkFlow } from '../types/workFlow';
import { formattedPayloadToDropdownList, getProject } from '../shared/util';


const defaultState: any = {
  wfList: [],
  isWFListLoaded: false,
  isNew: false,
  isChange: false,
  isEdit: false,
  showProgressBar: false,
  wfDropDowns: {},
  formData: {},
  uid: null,
  loadMsg: '',
  wfShortCutPaneData: [],
  reloadListPane: false,
  borResources: {},
  isLoadingWorkFlow: false,
  wfId: null,
  wfDropDownList: [],
};

export default function workFlowReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {
      case LOAD_WORK_FLOW_SHORTCUTPANE:
        return { ...state };
      case LOAD_WORK_FLOW_SHORTCUTPANE_SUCCESS:
        return {
          ...state,
          wfShortCutPaneData: action.payload.data.result,
        };
      case LOAD_WORK_FLOW_SHORTCUTPANE_FAIL:
        return { ...state, wfShortCutPaneData: [] };
      case LOAD_WORK_FLOW_LSITPANE:
        return { ...state, isWFListLoaded: false };
      case LOAD_WORK_FLOW_LSITPANE_SUCCESS:
        return {
          ...state,
          isWFListLoaded: true,
          wfList: action.payload.data.result,
          loadMsg: !action.payload.data.status
            ? action.payload.data.message
            : '',
        };
      case LOAD_WORK_FLOW_LSITPANE_FAIL:
        return {
          ...state,
          isWFListLoaded: true,
          wfList: [],
          loadMsg: action.error.response.data.message,
        };
      case RESET_WORK_FLOW_IS_CHANGE:
        return {
          ...state,
          isChange: false,
        };

      case LOAD_WORK_FLOW_DROPDOWN:
        return { ...state, wfDropDowns: {} };
      case LOAD_WORK_FLOW_DROPDOWN_SUCCESS:
        return {
          ...state,
          wfDropDowns: action.payload.data.result,
          wfDropDownList: formattedPayloadToDropdownList(action.payload.data.result, 'wf'),
        };
      case LOAD_WORK_FLOW_DROPDOWN_FAIL:
        return {
          ...state,
          wfDropDowns: {},
        };
      case LOAD_WORK_FLOW_BY_ID:
        return { ...state, formData: {}, showProgressBar: true, isLoadingWorkFlow: true };
      case LOAD_WORK_FLOW_BY_ID_SUCCESS:
        return {
          ...state,
          isEdit: true,
          formData: action.payload.data.result,
          showProgressBar: false,
          isLoadingWorkFlow: false,
        };
      case LOAD_WORK_FLOW_BY_ID_FAIL:
        return {
          ...state,
          formData: {},
          showProgressBar: false,
          isLoadingWorkFlow: false,
        };

      case SAVE_WORK_FLOW:
        return { ...state, showProgressBar: true, reloadListPane: false, wfId: null };
      case SAVE_WORK_FLOW_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
          reloadListPane: true,
          wfId: action.payload.data.result,
        };
      case SAVE_WORK_FLOW_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
        };

      case APPROVE_WORK_FLOW:
        return { ...state, showProgressBar: true, reloadListPane: false };
      case APPROVE_WORK_FLOW_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: true,
        };
      case APPROVE_WORK_FLOW_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
        };

      case SET_WORK_FLOW_UID:
        return {
          ...state,
          isEdit: false,
          uid: uuidv4(),
        };
      case CLEAR_WORK_FLOWUID:
        return {
          ...state,
          uid: null,
          isChange: false,
        };
      case CLEAR_WORK_FLOW_FORM_DATA:
        return {
          ...state,
          uid: null,
          isNew: true,
          isChange: false,
          formData: {},
        };

      default:
        return state;
    }
  }
}

export const readWorkFlowShortCutPaneData = () => {
  return {
    types: [
      LOAD_WORK_FLOW_SHORTCUTPANE,
      LOAD_WORK_FLOW_SHORTCUTPANE_SUCCESS,
      LOAD_WORK_FLOW_SHORTCUTPANE_FAIL,
    ],
    payload: { request: { url: READ_WORK_FLOW_SHORTCUT_PANE_EP } },
  };
};

export const readWorkFlowDropDownData = () => {
  return {
    types: [
      LOAD_WORK_FLOW_DROPDOWN,
      LOAD_WORK_FLOW_DROPDOWN_SUCCESS,
      LOAD_WORK_FLOW_DROPDOWN_FAIL,
    ],
    payload: { request: { method: 'GET', url: READ_WORK_FLOW_DROPDOWN_EP } },
  };
};


export const filterWorkFlowListPaneData = (filter: POFilter) => {
  return {
    types: [
      LOAD_WORK_FLOW_LSITPANE,
      LOAD_WORK_FLOW_LSITPANE_SUCCESS,
      LOAD_WORK_FLOW_LSITPANE_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_WORK_FLOW_LIST_EP,
        data: filter,
      },
    },
  };
};


export const approveWorkflow = (data: any) => {
  return {
    types: [
      APPROVE_WORK_FLOW,
      APPROVE_WORK_FLOW_SUCCESS,
      APPROVE_WORK_FLOW_FAIL,
    ],
    payload: {
      request: {
        method: 'PUT',
        url: getProject() ? APPROVE_WORK_FLOW_PROJECT_EP + data?.sequenceId : APPROVE_WORK_FLOW_EP + data?.sequenceId,
      },
    },
  };
};


export const readByWorkFlowId = (id: string) => {
  return {
    types: [
      LOAD_WORK_FLOW_BY_ID,
      LOAD_WORK_FLOW_BY_ID_SUCCESS,
      LOAD_WORK_FLOW_BY_ID_FAIL],
    payload: { request: { url: READ_WORK_FLOW_BY_ID_EP + id } },
  };
};

export const resetIsChange = () => {
  return { type: RESET_WORK_FLOW_IS_CHANGE };
};

export const saveFormData = (formData: WorkFlow) => {
  return {
    types: [
      SAVE_WORK_FLOW,
      SAVE_WORK_FLOW_SUCCESS,
      SAVE_WORK_FLOW_FAIL],
    payload: {
      request: {
        url: WORK_FLOW_SAVE_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const setUid = () => {
  return { type: SET_WORK_FLOW_UID };
};

export const clearFormData = () => {
  return { type: CLEAR_WORK_FLOW_FORM_DATA };
};

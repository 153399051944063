import React, { Component } from 'react';
import UprinceNavigation from '../../shared/uprinceNavigation/cuProjectNavigation/component';
import VisualPlanMainLayout from './visualPlanMainLayout';
import { getContractingUnit } from '../../shared/util';


interface Props {
  currentUser: any
}

interface State {
  isContractUnit: boolean;
  isProject: boolean;
}

class VisualPlan extends Component<Props, State> {
  render() {
    return (
      <UprinceNavigation
        currentUser={this.props.currentUser}
        selectedNavigationIndex={`/CU/${getContractingUnit()}/visual-plan`}
      >
        <VisualPlanMainLayout/>

      </UprinceNavigation>
    );
  }
}

export default VisualPlan;

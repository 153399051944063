import React from 'react';
import { connect } from 'react-redux';
import { DocumentPaneComponent } from './component';
import { withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import _ from 'lodash';
import i18n from '../../../../i18n';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import history from '../../../history';
import { messageService } from '../../../services/messageService';
import { getProject } from '../../../shared/util';
import {
  Invoice,
  invoiceFormData,
  InvoiceListPaneFilter,
  InvoiceValidationMessage,
} from '../../../types/invoice';
import {
  approvedInvoiceCu,
  clearFormData as clearInvoiceData,
  inReviewInvoiceCu,
  readByInvoiceId,
  saveFormData,
} from '../../../reducers/invoiceReducer';
import {
  getAllProjectDropdowns,
  getProjectHeaderDetails,
} from '../../../reducers/projectReducer';
import InvoiceProgressStatement from './progressStatement/invoiceProgressStatement';
import { ProgressStatement } from '../../../types/progressStatement';
import { readByPSId } from '../../../reducers/progressStatementReducer';

export interface State {
  formData: Invoice;
  isDirty: boolean;
  redirect: boolean;
  loading: boolean;
  filter: InvoiceListPaneFilter;
  isEdit: boolean;
  validationMessages: InvoiceValidationMessage;
  isCostModalOpen: boolean;
  showPrint: boolean;
}

interface Props extends WithTranslation {
  formData: Invoice;
  psFormData: ProgressStatement;
  invoiceDropDowns: any;
  projectHeader: [] | null;
  getProjectHeaderDetails: (id: string) => void;
  clearInvoiceData: () => void;
  showProgressBar: boolean;
  autoSave: boolean;
  isEdit: boolean;
  projectId: string | null;
  isNew: boolean;
  uid: string | null;
  saveFormData: (formData: Invoice) => any;
  readByInvoiceId: any;
  createInvoiceResource: (data: any) => void;
  isExpand: boolean;
  approvedInvoiceCu: any;
  ledgerDropDowns: any;
  readByPSId: any;
  getAllProjectDropdowns: () => void;
  inReviewInvoiceCu: any;
  projectState: any;
}

class DocumentPane extends React.Component<Props, State> {
  subscription: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      isDirty: false,
      isEdit: false,
      redirect: false,
      loading: false,
      formData: invoiceFormData,
      filter: new InvoiceListPaneFilter(),
      validationMessages: {
        nameErrorMsg: '',
        typeErrorMsg: '',
        borErrorMsg: '',
        statusErrorMsg: '',
      },
      isCostModalOpen: false,
      showPrint: false,
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevProps.isNew != this.props.isNew ||
      prevProps.uid != this.props.uid
    ) {
      if (this.props.isNew) {
        let formData = this.state.formData;
        formData.id = this.props.uid;
        this.setState({ formData });
      }
    } else {
    }

    if (prevProps.formData != this.props.formData) {
      if (!this.state.isDirty) {
        if (!_.isEmpty(this.props.formData)) {
          //let formData = this.props.formData;
          this.setState(
            {
              formData: this.props.formData,
              //position:{lat:0.0,lon:0.0}, location:null
            },
            this.getInvoiceData
          );
        }
      } else {
        if (this.props.isNew && _.isEmpty(this.props.formData)) {
          let formData = this.state.formData;
          this.setState({
            formData: formData,
          });
        }
      }
      this.resetValidation();
      if (this.props.formData.projectSequenceCode) {
        this.props.getProjectHeaderDetails(
          this.props.formData.projectSequenceCode
        );
      }
      this.getPsData();
    }

    if (prevState.formData !== this.state.formData) {
      if (!_.isEmpty(this.state.formData) && this.state.formData.id === null) {
        let formData = this.state.formData;
        formData.id = this.props.uid;
        //this.props.readMaterialsData(this.props.uid!!);
        this.setState(
          {
            formData,
          },
          this.getInvoiceData
        );
      }
    }
  }

  componentDidMount() {
    this.getPsData();
    if (this.props.projectState?.length < 0) {
      this.props.getAllProjectDropdowns();
    }
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewInvoice) {
          this.resetValidation();
          this.props.clearInvoiceData();
          //cancelAllRequest()
          this.clearInvoiceData();
        }
        if (data.data.saveInvoiceFormData) {
          this.saveFormData();
        }
        if (data.data.invoiceFilter) {
          this.setState({
            filter: data.data.invoiceFilter,
          });
        }
        if (data.data.pdfGenerate) {
          this.setState({
            showPrint: true,
          });
        }
        if (data.data.approveInvoiceCu) {
          this.handleApproveInvoiceCu();
        }

        if (data.data.reviewInvoiceCu) {
          this.handleReviewInvoiceCu();
        }
      }
    });

    if (this.props.formData.projectSequenceCode) {
      this.props.getProjectHeaderDetails(
        this.props.formData.projectSequenceCode
      );
    }

    if (getProject()) {
      let projectId = getProject();
      if (projectId) {
        this.props.getProjectHeaderDetails(projectId);
      }
    }
    if (this.props.formData.invoiceId) {
      this.getInvoiceData();
    }

    if (!this.props.formData.id) {
      if (this.props.isNew) {
        let formData = this.state.formData;
        formData.id = this.props.uid;
        this.setState({ formData });
      }
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  handleReviewInvoiceCu = () => {
    if (this.state.formData.invoiceId) {
      this.props.inReviewInvoiceCu(this.state.formData.id).then((res: any) => {
        this.props.readByInvoiceId(this.state.formData.invoiceId);
      });
    }
  };

  handleApproveInvoiceCu = () => {
    if (this.state.formData.invoiceId) {
      this.props.approvedInvoiceCu(this.state.formData.id).then((res: any) => {
        this.props.readByInvoiceId(this.state.formData.invoiceId);
        messageService.sendMessage({ reloadList: true });
      });
    }
  };

  getInvoiceData = () => {
    if (this.props.formData.invoiceId) {
      messageService.sendMessage({ isLoadingInvoiceData: true });
      Promise.all([
        //api call
      ]).then(() => {
        messageService.sendMessage({ isLoadingInvoiceData: false });
      });
    }
  };

  getPsData = () => {
    if (this.props.formData.invoiceId) {
      this.props.readByPSId(this.props.formData.psId).then(() => {});
    }
  };

  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { invoiceFilter: this.state.filter },
    });
  };

  openCostModal = () => {
    this.setState({ isCostModalOpen: !this.state.isCostModalOpen });
  };

  render() {
    const { t } = this.props;

    return (
      <div style={{ width: '100%' }}>
        <RouteLeavingGuard
          when={this.state.isDirty}
          navigate={(path) => this.handleDiscardChangesNavigate(path)}
          shouldBlockNavigation={(location) => {
            return true;
          }}
          handleConfirmation={(isConfirm: boolean) => {
            this.setState({
              isDirty: !isConfirm,
            });
          }}
        />
        <DocumentPaneComponent
          dropDownOptions={{
            types:
              this.props.invoiceDropDowns && this.props.invoiceDropDowns.type
                ? this.props.invoiceDropDowns.type
                : [],
            status:
              this.props.invoiceDropDowns && this.props.invoiceDropDowns.status
                ? this.props.invoiceDropDowns.status
                : [],
            customer:
              this.props.invoiceDropDowns &&
              this.props.invoiceDropDowns.customer
                ? this.props.invoiceDropDowns.customer
                : null,
            projectCompletionDate:
              this.props.invoiceDropDowns &&
              this.props.invoiceDropDowns.projectCompletionDate
                ? this.props.invoiceDropDowns.projectCompletionDate
                : null,
            ledgerDropDowns: this.props.ledgerDropDowns
              ? this.props.ledgerDropDowns
              : null,
          }}
          formData={this.state.formData}
          psFormData={this.props.psFormData}
          projectHeader={this.props.projectHeader}
          handleFormChange={(invoice: Invoice, save: boolean) => {
            this.handleFormChange(invoice, save);
          }}
          saveFormData={() => this.saveFormData()}
          isEdit={this.props.isEdit}
          validationMessages={this.state.validationMessages}
          isLoading={this.props.showProgressBar}
          isExpand={this.props.isExpand}
        />
        <InvoiceProgressStatement
          progressStatementId={this.state.formData.psId}
          invoiceData={this.state.formData}
        />
      </div>
    );
  }

  private handleFormChange = (formData: Invoice, save: boolean) => {
    this.setState({
      isDirty: true,
    });
    this.setState({
      formData: formData,
    });
  };

  private saveFormData = () => {
    if (this.validate()) {
      let formData = this.state.formData;
      this.props.saveFormData(formData).then((res: any) => {
        formData.isSaved = true;
        this.setState(
          {
            formData,
          },
          () => {
            //
          }
        );
      });
      this.setState({
        isDirty: false,
      });
    }
  };

  private validate = (): boolean => {
    let validationMessages = this.state.validationMessages;
    let name = this.state.formData.name;
    let type = this.state.formData.projectTypeId;
    let status = this.state.formData.invoiceStatusId;

    let isValid = true;
    if (_.isNil(name) || _.isEmpty(name)) {
      validationMessages.nameErrorMsg = i18n.t('nameRequired');
      isValid = false;
    } else {
      validationMessages.nameErrorMsg = '';
    }
    if (_.isNil(type) || _.isEmpty(type)) {
      validationMessages.typeErrorMsg = 'Type Required';
      isValid = false;
    } else {
      validationMessages.typeErrorMsg = '';
    }
    if (_.isNil(status) || _.isEmpty(status)) {
      validationMessages.statusErrorMsg = 'Status Required';
      isValid = false;
    } else {
      validationMessages.statusErrorMsg = '';
    }

    this.setState({ validationMessages: validationMessages });
    return isValid;
  };

  private clearInvoiceData = () => {
    let formData: Invoice = {
      id: this.props.uid ? this.props.uid : null,
      invoiceId: null,
      title: null,
      headerTitle: null,
      name: null,
      projectTypeId: null,
      projectType: null,
      invoiceStatusId: null,
      projectStatus: null,
      projectCostId: null,
      projectCompletionDate: null,
      workPeriodFrom: null,
      workPeriodTo: null,
      purchaseOrderNumber: null,
      projectSequenceCode: null,
      totalExcludingTax: null,
      totalIncludingTax: null,
      psId: null,
      historyLog: null,
      isSaved: false,
      date: null,
      isProjectFinished: false,
    };
    this.setState({
      formData,
    });
  };

  private resetValidation = () => {
    let validationMessages = this.state.validationMessages;
    validationMessages.typeErrorMsg = '';
    validationMessages.statusErrorMsg = '';
    validationMessages.nameErrorMsg = '';

    this.setState({
      validationMessages: validationMessages,
    });
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    invoiceDropDowns: state.invoice.invoiceDropDowns,
    isEdit: state.invoice.isEdit,
    isNew: state.invoice.isNew,
    uid: state.invoice.uid,
    projectHeader: state.project.projectHeader,
    showProgressBar: state.invoice.showProgressBar,
    psFormData: state.ps.formData,
    ledgerDropDowns: state.project.ledgerDropDowns,
    projectState: state.project.projectState
  };
};

const mapDispatchToProps = {
  saveFormData,
  readByInvoiceId,
  clearInvoiceData,
  getProjectHeaderDetails,
  approvedInvoiceCu,
  readByPSId,
  getAllProjectDropdowns,
  inReviewInvoiceCu,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DocumentPane))
);

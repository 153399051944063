import React, { Component } from 'react';
import Brand from '../../shared/brand/brand';
import ProjectBar from '../../shared/projectBar/projectBar';
import '../component.css';
import QRCodeMainLayout from '../qrCode/qrCodeMainLayout';
import CUNavBar from '../../shared/navBar/cuNavBar';
import { getContractingUnit } from '../../shared/util';

const ORGANIZATION_CALLBACK_ENUMS = {
  PARTNER_PROFILE: 'PARTNER_PROFILE',
  LOCATION: 'LOCATION',
  COMPANY_PROFILE: 'COMPANY_PROFILE',
  CONTACT_PREFERENCE: 'CONTACT_PREFERENCE',
};

export interface State {
  component: string;
}

class CuQrcode extends Component<any, State> {
  constructor(props: {}) {
    super(props);
    this.state = { component: '' };
  }

  render() {
    return (
      <div style={{ height: '100%' }}>
        <Brand/>
        <ProjectBar
          contractingUnit={this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null}/>
        <CUNavBar selectedKey={`/CU/${getContractingUnit()}/qr-code`} currentUser={this.props.currentUser}/>
        <QRCodeMainLayout/>
      </div>
    );
  }
}

export default CuQrcode;
export { ORGANIZATION_CALLBACK_ENUMS };

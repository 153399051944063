import {
  FontWeights,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  mergeStyleSets,
  PrimaryButton,
  Stack,
  Text,
} from '@fluentui/react';
import React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../../theme';
import { TextFieldFF } from '../../../../shared/fluentFinalForm';
import PeoplePicker from '../../../../shared/fluentFinalForm/PeoplePicker';

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '40vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const addWhiteIconButtonStyles = {
  root: { color: uPrinceTheme.palette.white },
  rootHovered: { color: theme.palette.neutralDark },
};
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};

const TeamsModalContent = (props: {
  teamMember: any;
  disabled: boolean;
  onChange: any;
  dataList: any[];
  lotContractorId: any;
}) => {
  const { t } = useTranslation();

  // const { projectScopeStatus, userRoles }: any = useSelector(
  //   (state: any) => state.project
  // );

  const stackItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 50,
      width: 0,
      flexGrow: 1,
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
  };

  const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
    fullWidth: { width: '100%' },
    halfWidth: { width: '49%' },
  });

  const onSubmit = async (values: any) => {
    if (!values?.id) {
      values.isManual = true;
    }
    props.onChange(values);
  };

  const getInitialvalues = (teamMember: any) => {
    if (teamMember) {
      return teamMember;
    }
    return {
      id: null,
      cabPersonId: null,
      roleName: null,
      invitationMail: false,
      isManual: false,
      lotContractorId: props.lotContractorId,
    };
  };

  return (
    <div className={contentStyles.body}>
      <div className="proj-detail-content inner">
        <div className="ms-Grid-row">
          <Form
            onSubmit={onSubmit}
            initialValues={getInitialvalues(props.teamMember)}
            mutators={{
              setCabPersonName: (args, state, utils) => {
                const field = state.fields.cabPersonName;
                field.change(args[0]);
              },
              // setTempEmail: (args, state, utils) => {
              //   const fieldTempemail = state.fields.tempEmail;
              //   fieldTempemail.change(args[0]);
              // },
              // setRoleName: (args, state, utils) => {
              //   const field = state.fields.roleName;
              //   field.change(args[0]);
              // },
              setCabCompany: (args, state, utils) => {
                const field = state.fields.company;
                field.change(args[0]);
              },
              setCabCompanyId: (args, state, utils) => {
                const field = state.fields.companyId;
                field.change(args[0]);
              },
              setCabJobTitle: (args, state, utils) => {
                const field = state.fields.roleName;
                field.change(args[0]);
              },
            }}
            validate={(values) => {
              const errors: any = {};
              if (!values?.cabPersonId) {
                errors.cabPersonId = t('personRequired');
              }
              if (Array.isArray(props.dataList) && !values.id) {
                const index = props.dataList.findIndex((object) => {
                  return object.cabPersonId === values.cabPersonId;
                });
                if (index !== -1) {
                  errors.cabPersonId = t('alreadyAdded');
                }
              }
              return errors;
            }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              // <form onSubmit={ handleSubmit }>
              <div>
                <FormSpy
                  onChange={(spyProps: any) => {
                    if (props.teamMember?.isAccessGranted !== spyProps.values?.isAccessGranted && props.onChange && spyProps.values?.isAccessGranted) {
                      // form.mutators.setBussinessEmail(values.tempEmail);
                    }
                  }}
                />

                <Stack
                  horizontal
                  wrap
                  styles={stackStyles}
                  tokens={stackTokens}
                >
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.halfWidth}`}>
                      <Field name="cabPersonId">
                        {({ input, meta }) => (
                          <>
                            <PeoplePicker
                              label={t('name')}
                              isDisabled={props.disabled}
                              selectedItem={
                                values?.cabPersonId
                                  ? [
                                    {
                                      key: values?.cabPersonId,
                                      text: values?.cabPersonName,
                                    },
                                  ]
                                  : []
                              }
                              onChange={(items: any) => {
                                // form.resetFieldState('cabPersonId');
                                if (items == null && !values?.id) {
                                  form.mutators.setCabPersonName('');
                                  form.mutators.setCabCompany('');
                                  form.mutators.setCabCompanyId('');
                                  form.mutators.setCabJobTitle('');
                                  input.onChange('');
                                }
                                input.onChange;
                                return items;
                              }}
                              autoComplete={'off'}
                              onPersonSelected={(item: any) => {
                                input.onChange(item.key);
                                form.mutators.setCabPersonName(item.text);
                                form.mutators.setCabCompany(item?.Organisation);
                                form.mutators.setCabCompanyId(item?.companyId);
                                form.mutators.setCabJobTitle(item?.jobTitle);
                                return item;
                              }}
                              // endpoint={''Contractor/LotPersonFilter''}
                              endpoint={values?.companyId ? 'Contractor/PersonFilter' : 'Contractor/LotPersonFilter'}
                              companyId={values?.companyId}
                              lotId={props.lotContractorId}
                            />
                            {(meta.error || meta.submitError) &&
                            meta.touched && (
                              <Text
                                styles={{
                                  root: { color: '#a4262c', fontSize: 12 },
                                }}
                              >
                                {meta.error || meta.submitError}
                              </Text>
                            )}
                          </>
                        )}
                      </Field>
                      <Field
                        name="cabPersonName"
                        component={'input'}
                        type={'text'}
                        disabled={true}
                        hidden
                      />
                      <Field
                        name="companyId"
                        component={'input'}
                        type={'text'}
                        disabled={true}
                        hidden
                      />
                    </div>
                  </Stack.Item>

                </Stack>

                <Stack
                  horizontal
                  wrap
                  styles={stackStyles}
                  tokens={stackTokens}
                >
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <Field
                        name="roleName"
                        component={TextFieldFF}
                        lable={t('role')}
                        disabled={true}
                      />
                    </div>
                  </Stack.Item>
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}`}>
                      <Field
                        name="company"
                        component={TextFieldFF}
                        lable={t('company')}
                        disabled={true}
                        initialValue={values.company}
                      />
                    </div>
                  </Stack.Item>
                </Stack>

                <div className={contentStyles.footer}>
                  <PrimaryButton
                    iconProps={addIconWhite}
                    text={t('save')}
                    disabled={submitting || pristine}
                    style={{ marginTop: 40, marginBottom: 10 }}
                    onClick={() => {
                      form.submit();
                    }}
                  />
                </div>
                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamsModalContent;

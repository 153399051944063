import * as React from 'react';
import { DefaultButton } from '@fluentui/react/lib/Button';
import {
  TooltipHost,
  TooltipDelay,
  DirectionalHint,
  ITooltipProps,
  ITooltipHostStyles,
  FontIcon, Persona, IPersonaSharedProps, PersonaSize, Label,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';



const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

const LabourTooltip = (props:{team:any}) => {
  // Use useId() to ensure that the ID is unique on the page.
  // (It's also okay to use a plain string and manually ensure uniqueness.)
  const tooltipId = useId('tooltip');

  const tooltipProps: ITooltipProps = {
    onRenderContent: () => (
      <div style={{display:'flex',flexDirection:'column',gap:2,  margin: 10, padding: 0 }}>
        {props.team.map((labour:any)=>{
          // return  <Persona  text={labour?.name} size={PersonaSize.size24} />
          return <Label>{labour?.title}</Label>
        })}
      </div>
    ),
  };

  return (
    <TooltipHost
      tooltipProps={tooltipProps}
      delay={TooltipDelay.zero}
      id={tooltipId}
      directionalHint={DirectionalHint.bottomCenter}
      styles={hostStyles}
    >
      <FontIcon
        aria-label="People"
        iconName="People"
        style={{margin: 5, cursor: 'pointer'}}
        aria-describedby={tooltipId}
      />
    </TooltipHost>
  );
};

export default LabourTooltip